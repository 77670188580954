import { Checkbox } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import ButtonStyle from './ButtonStyle';



const PanelExportPdfAndExcel = ({ onClickCancelModal, exportDataToPdf, exportDataToCsv, pageSheetTypeName }) => {



   const [isIncludeDrawingDetail, setIsIncludeDrawingDetail] = useState(true);


   return (
      <div style={{ padding: 20 }}>
         <div style={{ display: 'flex', marginBottom: 10 }}>
            {(pageSheetTypeName === 'page-spreadsheet' ? ['Export To CSV']
               : ['Export To CSV', 'Export To PDF']).map((btn, i) => (
                  <ButtonStyle
                     key={i}
                     colorText='black'
                     marginRight={10}
                     borderColor={colorType.grey1}
                     background={colorType.grey4}
                     onClick={() => {
                        if (btn === 'Export To CSV') {
                           if (pageSheetTypeName === 'page-spreadsheet') {
                              exportDataToCsv(onClickCancelModal, true);
                           } else {
                              exportDataToCsv(onClickCancelModal, isIncludeDrawingDetail);
                           };
                        } else if (btn === 'Export To PDF') {
                           exportDataToPdf(onClickCancelModal, isIncludeDrawingDetail);
                        };
                     }}
                     name={btn}
                     marginBottom={10}
                  />
               ))}
         </div>

         {pageSheetTypeName === 'page-rfa' && (
            <div>
               <CheckboxStyled
                  onChange={() => setIsIncludeDrawingDetail(!isIncludeDrawingDetail)}
                  checked={isIncludeDrawingDetail}
               >Including Drawing Detail</CheckboxStyled>
            </div>
         )}


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
            <ButtonStyle
               colorText='white'
               borderColor={colorType.grey1}
               background={colorType.primary}
               onClick={onClickCancelModal}
               name={'Close'}
            />
         </div>
      </div>
   );
};
export default PanelExportPdfAndExcel;


const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      border: none;
      background: ${colorType.primary}
   }
`;



