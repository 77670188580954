import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
// import '../../scss/PageCss.scss'
import TrimbleViewer from './TrimbleViewer'
import { trackingUserUsing } from '../../module_BimApp/function/AdminFunction'

class TrimblePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        trackingUserUsing(this.props.userInfo.email,this.props.location.state.projectId,'Trimble Connect', 'None')
    }


    render() {
        return (
            <>

                <TrimbleViewer
                    trimbleFileId={this.props.location.state.trimbleFileId}
                    trimbleVersionId={this.props.location.state.trimbleVersionId}
                />


            </>
        )
    }

}

TrimblePage.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,
    userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        // isAdmin: state.user.role === "admin",
        isAdmin: state.user.email === "admin@wohhup.com",
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
    }
}
export default connect(mapStateToProps)(TrimblePage)