import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox } from 'rsuite';

import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import { Popconfirm } from 'antd';
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,
} from 'react-table'
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { ListValueFilter } from '../function/TableFunction'
import { checkWohhupUser } from '../function/AdminFunction'
import moment from 'moment'
function TableListItem({ columns, data,
  getRowProps,
  getCellProps,
  email,
  folderId,
  isAdmin, permission,
  handleOpenControlVersionPanel,
  handleOpenControlSharePanel,
  handleOpenControlSettingPanel,
  handleDeleteItem,
  handleTranslateObject,
  roleUploader,
  isProjectAdmin, onChangeHeader,
  folderName,
  company,
  role
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter.bind(this, onChangeHeader),
    }),
    []
  )
  const [loading, setLoading] = React.useState(false)

  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'functionItem',
            groupByBoundary: true,
            collapse: true,
            minWidth: 120,
            width: 120,
            maxWidth: 120,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div></div>
            ),
            Cell: ({ row }) => (

              <ButtonGroup>
                {folderName !== 'rfa-folder-model' && <Popconfirm placement="right" title={'Are you sure to delete this item?'} value={row.original}
                  onConfirm={() => deleteItem(row.original)} okText="Yes" cancelText="No">
                  <Tooltip title="Delete Item" placement="top">
                    <IconButton size="xs" icon={<Icon icon="trash-o" />} disabled={(!isAdmin && !isProjectAdmin)} loading={loading} ></IconButton>
                  </Tooltip>
                </Popconfirm>}
                <Popconfirm placement="right" title=" Are you sure to translate item ?"
                  onConfirm={() => translateItem(row.original)} okText="Yes" cancelText="No" >
                  <Tooltip title="Translate Item" placement="top">
                    <IconButton size="xs" icon={<Icon icon="exchange" />}
                      disabled={(!isAdmin && !roleUploader) || row.original.status === 'success'}       ></IconButton>
                  </Tooltip>
                </Popconfirm>
                {/* {folderName!=='rfa-folder-model'&&        <Tooltip title="Change Version" placement="top">
                  <IconButton size="xs" icon={<Icon icon="square-o" />} disabled={(!isAdmin && !roleUploader)}
                    onClick={() => showOpenControlVersionPanel(row.original)}  ></IconButton>
                </Tooltip>} */}
                <Tooltip title="Share Item" placement="top">
                  <IconButton size="xs" icon={<Icon icon="share" />}
                    // disabled={checkWohhupUser(email) ? false : (company === 'Woh Hup Private Ltd' || role === 'Outsource') ? false : true}
                    color={row.original.isShare && 'blue'} onClick={() => showOpenControlSharePanel(row.original)}  ></IconButton>
                </Tooltip>
                <Tooltip title="Settings" placement="top">
                  <IconButton size="xs" icon={<Icon icon="cog" />} disabled={(!isAdmin && !isProjectAdmin)}
                    onClick={() => showOpenControlSettingPanel(row.original)}  ></IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
          ...columns,
        ]
      })

    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      pageIndex, pageSize
    }
  } = instance

  const showOpenControlVersionPanel = (value) => {
    let data = {
      itemId: value.item.itemId, objectIdDefault: value.item.objectIdDefault,
      itemName: value.item.itemName
    }
    handleOpenControlVersionPanel(data)
  }
  const showOpenControlSharePanel = (value) => {
    // if (value.isTrimble) return
    let data = { itemId: value.item.itemId, objectIdDefault: value.item.objectIdDefault, itemName: value.item.itemName, isTrimble: value.isTrimble }
    handleOpenControlSharePanel(data)
  }
  const showOpenControlSettingPanel = (value) => {
    let data = {
      itemId: value.item.itemId, objectIdDefault: value.item.objectIdDefault, itemName: value.item.itemName,
      smartSheetId: value.smartSheetId, manningScheduleId: value.manningScheduleId, folderSmartSheetId: value.folderSmartSheetId,
      imgItem: value.img, itemDisplayName: value.itemDisplayName, typeFile: value.typeFile
    }
    handleOpenControlSettingPanel(data)
  }
  const deleteItem = (value) => {
    let data = {
      itemId: value.item.itemId, itemName: value.item.itemName, folderId: folderId, email: email, isTrimble: value.isTrimble, trimbleFileId: value.trimbleFileId
    }
    handleDeleteItem(data)

  }
  const translateItem = (value) => {
    if (value.isTrimble) return
    let data = {
      itemId: value.item.itemId, objectName: value.item.objectIdDefault, status: value.status,
      itemName: value.item.itemName, itemDisplayName: value.itemDisplayName
    }
    handleTranslateObject(data)
  }
  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { name, value }) => {
    setPageSize(value)
  }
  return (
    <div>

      <div style={{ paddingLeft: 25 }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle', display: data.length !== 0 ? 'inline-block' : 'none' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />
      </div>
      <div style={{ position: 'absolute', top: 105, overflow: 'auto', bottom: 0, width: '100%' }}>
        <Table celled selectable size='large'
          {...getTableProps()} >
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    zIndex: 100,
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center',
                    width: column.id === 'functionItem' ? 120 :
                      column.id === 'version' ? 50 : column.id === 'typeFile' ? 60 :
                        (column.id === 'createdDate' || column.id === 'uploadDate') ? 100 :
                          column.id === 'Status' ? 100 :
                            column.id === 'userUpload' ? 300 : 'auto'

                  }}>
                    <span {...column.getSortByToggleProps()}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDownwardOutlinedIcon />
                          : <ArrowUpwardOutlinedIcon />
                        : ''}
                      {column.render('Header')}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{ textAlign: cell.column.id === 'itemDisplayName' ? 'left' : 'center', verticalAlign: 'middle' }} >
                        {cell.column.id === 'Status'
                          ? cell.render('Aggregated')
                          : cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    </div>
  )
}

export default TableListItem