import { Checkbox, Icon, message, Modal, Tooltip } from 'antd';
import base64url from 'base64url';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiUpdateRowsHistory, apiSendEmailDevTest, apiFetchPublicUrl, apiUpdateOrCreateRows, apiFetchDataThisProject, apiFetchRowHistoryThisProject, apiFetchDataMultiForm, apiSendEmail, apiFetchOneDataMultiForm, apiDeleteRowsHistory, apiFetchOneDataByRefMultiForm, apiFetchDataProjectByDiscipline, apiFetchDataMultiByDisciplineForm, apiFetchDataHistoryRowsByCurrentVersion } from '../../../api';
import { colorTextRow, colorType, EDIT_DURATION_MIN } from '../../../constants';
import { checkFormIfRepliedOrNot, checkIfItIsRowLevelRfaHeader, checkIfThereIsEmailFailure, compareDatesForm, getCompanyNameShort, getRefStringWithVersion, getTotalStatusInRfa, mongoObjectId, replaceBreakLine, sortTotalStatusRfaOrder } from '../../../utils';
import { classify } from '../../../utils/classify';
import ButtonColumnTag from '../generalComponents/ButtonColumnTag';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import { getInfoValueFromRefDataForm } from './CellForm';
import { getFileNameFromLinkResponse } from './PanelSetting';
import _ from 'lodash';




const preLink = window.location.origin.includes('https://idd.wohhup.com') ? 'https://idd.wohhup.com/dms-viewer/'
   : window.location.origin.includes('https://test.bql-app.com') ? 'https://test.bql-app.com/dms-viewer/'
      : 'http://localhost:3000/dms-viewer/';


const CellRFA = (props) => {

   const { rowData, cellData, column, buttonPanelFunction, stateRow, getSheetRows, stateProject, setLoading, commandAction } = props;

   const { rowsRfaAll, rowsRfaAllInit, modeFilter, rfaData } = stateRow;

   const {
      roleTradeCompany, company, email, isBothSideActionUser, pageSheetTypeName, companies, token, projectId, projectName, publicSettings
   } = stateProject.allDataOneSheet;

   const { overdueLeadConsultantOnly } = publicSettings;

   const [btnShown, setBtnShown] = useState(false);

   const [activeBtn, setActiveBtn] = useState('All');

   const [modalContent, setModalContent] = useState(null);

   const [modalPickConsultantForAdmin, setModalPickConsultantForAdmin] = useState(false);

   const [infoRfa, setInfoRfa] = useState({});

   const [dataForDetailVersionTable, setDataForDetailVersionTable] = useState({});
   const [isDrawingDetailTableDms, setIsDrawingDetailTableDms] = useState(null);

   const [replyStatus, setReplyStatus] = useState(null);
   const [replyCompany, setReplyCompany] = useState(null);
   const [replyDate, setReplyDate] = useState(null);

   const [overdueCount, setOverdueCount] = useState();

   const [arrayButtonCell, setArrayButtonCell] = useState([]);
   const [arrayButtonAlwaysVisibleCell, setArrayButtonAlwaysVisibleCell] = useState([]);

   const [modalDownloadDrawingShown, setModalDownloadDrawingShown] = useState(false);


   const [modalEmailContent, setModalEmailContent] = useState(false);

   const [modalConfirmResendFailedEmailOrRollback, setModalConfirmResendFailedEmailOrRollback] = useState(null);

   const [modalIssueForConstructionDrawings, setModalIssueForConstructionDrawings] = useState(false);

   const isRowLevelRfaHeader = checkIfItIsRowLevelRfaHeader(rowData);

   let statusAllDwgInThisRfa;
   if (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && isColumnWithReplyData(column.key)) {

      statusAllDwgInThisRfa = getStatusAllDwgInThisRfa(rfaData, rowData, column.key, companies) || {};
   };

   let isItInFilterStatusMode;
   if (isRowLevelRfaHeader && column.key === 'Due Date') {
      if (modeFilter.find(x => x.header === 'Status')) {
         isItInFilterStatusMode = true;
      };
   };


   // const [rfaSubjectText, setRfaSubjectText] = useState();
   let rfaSubjectText;
   if (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && column.key === 'Drawing Number') {
      const rowChild = (rowData.children || [])[0] || {};
      const rfaFound = rfaData.find(x => {
         return getRefStringWithVersion(x, 'rfa') === rowChild['RFA Ref'];
      }) || {};

      rfaSubjectText = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'emailTitle');
   };


   useEffect(() => {

      if (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && (column.key === 'RFA Ref' || column.key === 'Due Date' || column.key === 'Drawing Number')) {

         const rfaRef = getVersionFromActiveBtn(rowData, activeBtn);

         const rfaDataInfo = rfaData.find(item => rfaRef === getRefStringWithVersion(item, 'rfa')) || {};
         setInfoRfa(rfaDataInfo);

         let allRowsChildren = [];
         if (activeBtn === 'All') {
            allRowsChildren = rowsRfaAllInit.filter(r => {
               return r['rfaNumber'] === rowData['rfaNumber'] && !r['row'];
            });
         } else if (activeBtn) {
            allRowsChildren = rowsRfaAllInit.filter(r => {
               return r['rfaNumber'] === rowData['rfaNumber'] && r['RFA Ref'] === rfaRef;
            });
         };


         // in case in consolidate,there are drawings from different rfa version.
         const rowFoundThisRfa = allRowsChildren.find(r => r['RFA Ref'] === getRefStringWithVersion(rfaDataInfo, 'rfa')) || {};


         if (column.key === 'RFA Ref') {
            const consultantMustReplyArray = getInfoValueFromRefDataForm(rfaDataInfo, 'submission', 'rfa', 'consultantMustReply') || [];

            let cellButtonArr = [];

            if (checkIfThereIsEmailFailure(email, rfaDataInfo, 'rfa')) {
               cellButtonArr = [...cellButtonArr, 'btn-resend-email'];
            };


            if (isBothSideActionUser && allRowsChildren.find(row => !row['RFA Ref'])) {
               cellButtonArr = [...cellButtonArr, 'btn-resubmit'];
            };


            if (
               (
                  roleTradeCompany.role === 'Consultant' && consultantMustReplyArray.indexOf(company) !== -1 &&
                  !getInfoValueFromRefDataForm(rowFoundThisRfa, 'reply', 'rfa', 'status', company) &&
                  checkIfEditTimeRfaIsOver(rfaDataInfo, null, EDIT_DURATION_MIN, 'check-if-submission-edit-is-over') <= 0
               ) ||
               (
                  isBothSideActionUser &&
                  consultantMustReplyArray.find(cmp => !getInfoValueFromRefDataForm(rowFoundThisRfa, 'reply', 'rfa', 'status', cmp)) &&
                  !(_.isEmpty(rowFoundThisRfa)) // in case create all new drawing revision in RFA but not submit yet
               )
            ) {
               cellButtonArr = [...cellButtonArr, 'btn-reply'];
            };

            setArrayButtonCell(['btn-download', 'btn-email-content']);
            setArrayButtonAlwaysVisibleCell(cellButtonArr);

         } else if (column.key === 'Due Date') {

            const isNotRepliedYet = checkFormIfRepliedOrNot({ refType: 'rfa', row: rowFoundThisRfa, overdueLeadConsultantOnly, rfaData: rfaDataInfo });

            if (isNotRepliedYet) {
               const compare = compareDatesForm(getInfoValueFromRefDataForm(rfaDataInfo, 'submission', 'rfa', 'due'));
               setOverdueCount(compare);
            };
         };


      } else if (!rowData.treeLevel) {

         const rfaDataInfo = rfaData.find(item => {
            return rowData['RFA Ref'] === getRefStringWithVersion(item, 'rfa');
         }) || {};

         setInfoRfa(rfaDataInfo);

         if (column.key === 'RFA Ref') {

            let cellButtonArr = [];
            const isEditTimeSubmissionIsOver = checkIfEditTimeRfaIsOver(rfaDataInfo, null, EDIT_DURATION_MIN, 'check-if-submission-edit-is-over');
            const isUserTheRfaCreator = getInfoValueFromRefDataForm(rfaDataInfo, 'submission', 'rfa', 'user') === email;



            if (isBothSideActionUser && pageSheetTypeName === 'page-rfa') {
               // if (isUserTheRfaCreator) { // user who did not create form also can edit, just in case DC resign or change project
                  cellButtonArr = [...cellButtonArr, 'btn-edit'];
               // };

               if (rowData['rfaNumber'] && !rowData['RFA Ref']) { // Create new version but not submit new RFA yet
                  cellButtonArr = [...cellButtonArr, 'btn-rollback-previous-version'];
               };
            };

            const drawingLink = getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'drawing');
            if (drawingLink && (isEditTimeSubmissionIsOver <= 0 || isUserTheRfaCreator)) {
               cellButtonArr = [...cellButtonArr, 'btn-drawing'];
            };

            const dwfxLink = getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'dwfxLink');
            if (dwfxLink && (isEditTimeSubmissionIsOver <= 0 || isUserTheRfaCreator)) {
               cellButtonArr = [...cellButtonArr, 'btn-3d-model'];
            };
            setArrayButtonCell(cellButtonArr);

         } else if (isColumnWithReplyData(column.key)) {
            const { replyStatus: replyStatusData, replyCompany: replyCompanyData, replyDate: replyDateData } = getConsultantReplyData(rfaDataInfo, rowData, column.key);

            setReplyStatus(replyStatusData);
            setReplyCompany(replyCompanyData);
            setReplyDate(convertReplyOrSubmissionDate(replyDateData));

            let cellButtonArr = [];

            const isEditTimeReplyIsOver = checkIfEditTimeRfaIsOver(rfaDataInfo, replyCompanyData, EDIT_DURATION_MIN, 'check-if-reply-edit-is-over');
            const isUserTheRfaCreator = getInfoValueFromRefDataForm(rfaDataInfo, 'reply', 'rfa', 'user', replyCompanyData) === email;

            if (
               pageSheetTypeName === 'page-rfa' &&
               // isUserTheRfaCreator &&
               // (roleTradeCompany.role === 'Consultant' || (isBothSideActionUser && replyStatusData))
               // Allow DC edit reply by consultant (in case DC mess up Review Status Settings - wrong order)

               replyStatusData &&
               (
                  // (roleTradeCompany.role === 'Consultant' && isUserTheRfaCreator) || // user who did not create form also can edit, just in case staff resign or change project
                  (roleTradeCompany.role === 'Consultant' && replyCompanyData === company) || 
                  isBothSideActionUser
               )
            ) {
               cellButtonArr = [...cellButtonArr, 'btn-edit'];
            };

            const drawingLink = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'drawing', replyCompanyData);

            if (replyCompanyData && drawingLink && (isEditTimeReplyIsOver <= 0 || isUserTheRfaCreator)) {
               cellButtonArr = [...cellButtonArr, 'btn-drawing'];
            };

            const commentText = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'comment', replyCompanyData);

            if (replyCompanyData && commentText && (isEditTimeReplyIsOver <= 0 || isUserTheRfaCreator)) {
               cellButtonArr = [...cellButtonArr, 'btn-comment'];
            };

            setArrayButtonCell(cellButtonArr);


         } else if (column.key === 'Due Date') {

            const isNotRepliedYet = checkFormIfRepliedOrNot({ refType: 'rfa', row: rowData, overdueLeadConsultantOnly, rfaData: rfaDataInfo });

            if (isNotRepliedYet) {
               const compare = compareDatesForm(moment(rowData['Consultant Reply (T)'], 'DD/MM/YY'));
               setOverdueCount(compare);
            };
         } else if (column.key.includes('Version ')) { // DRAWING DETAIL TABLE AT DMS...
            const versionIndex = column.key.slice(8, column.key.length);
            const infoData = rowData['Info'];
            const dwgData = rowData[versionIndex];

            if (isColumnWithReplyData(infoData)) {

               let companyName = '';
               for (const key in dwgData) {
                  if (key.includes('reply-rfa-status-')) {
                     companyName = key.slice(17, key.length);
                  };
               };

               if (companyName) {

                  const dataStatus = getInfoValueFromRefDataForm(dwgData, 'reply', 'rfa', 'status', companyName);
                  const dataDate = getInfoValueFromRefDataForm(dwgData, 'reply', 'rfa', 'date', companyName);

                  setReplyStatus(dataStatus);
                  setReplyCompany(companyName);
                  setReplyDate(convertReplyOrSubmissionDate(dataDate));
                  setDataForDetailVersionTable(dwgData || {});

                  setIsDrawingDetailTableDms('drawing-detail-consultant-row');

                  let cellButtonArr = [];


                  const drawingLink = getInfoValueFromRefDataForm(dwgData, 'reply', 'rfa', 'drawing', companyName);
                  if (drawingLink) {
                     cellButtonArr = [...cellButtonArr, 'btn-drawing'];
                  };
                  const commentText = getInfoValueFromRefDataForm(dwgData, 'reply', 'rfa', 'comment', companyName);
                  if (commentText) {
                     cellButtonArr = [...cellButtonArr, 'btn-comment'];
                  };
                  setArrayButtonCell(cellButtonArr);
               };

            } else if (infoData === 'RFA Ref') {

               setDataForDetailVersionTable(dwgData || {});
               setIsDrawingDetailTableDms('drawing-detail-rfa-row');

               let cellButtonArr = [];

               const drawingLink = getInfoValueFromRefDataForm(dwgData, 'submission', 'rfa', 'drawing');
               if (drawingLink) {
                  cellButtonArr = [...cellButtonArr, 'btn-drawing'];
               };
               const dwfxLink = getInfoValueFromRefDataForm(dwgData, 'submission', 'rfa', 'dwfxLink');
               if (dwfxLink) {
                  cellButtonArr = [...cellButtonArr, 'btn-3d-model'];
               };
               setArrayButtonCell(cellButtonArr);
            } else if (infoData === 'Issue For Construction') {
               setDataForDetailVersionTable(dwgData || {});
               setIsDrawingDetailTableDms('drawing-detail-issueForConstruction-row');

               const issueForConstruction = getInfoValueFromRefDataForm(dwgData, 'submission', 'dt', 'issueForConstruction');
               if (issueForConstruction) {
                  const drawingLinkIssueForConstruction = getInfoValueFromRefDataForm(dwgData, 'submission', 'dt', 'drawingLinkIssueForConstruction') || [];
                  if (drawingLinkIssueForConstruction.length > 0) {
                     setArrayButtonCell(['btn-issue-dt']);
                  };

               };
            };

         } else if (column.key === 'Issue For Construction') {
            const issueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction');
            if (issueForConstruction) {
               const drawingLinkIssueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'drawingLinkIssueForConstruction') || [];
               if (drawingLinkIssueForConstruction.length > 0) {
                  setArrayButtonCell(['btn-issue-dt']);
               };
            };
         };
      };
   }, [activeBtn]);





   const onClickRfaVersion = (btn) => {
      const rfaCode = rowData['rfaNumber'];
      const rowsNotThisRFA = rowsRfaAll.filter(r => r.rfaNumber !== rfaCode);
      const rowsThisRFAFiltered = rowsRfaAllInit.filter(r => {
         return r.rfaNumber === rfaCode && (
            btn === '0' ? r['RFA Ref'] === rfaCode
               : btn === 'All' ? !r['row']
                  : r['RFA Ref'] === rfaCode + btn
         );
      });

      getSheetRows({
         rowsRfaAll: [...rowsNotThisRFA, ...rowsThisRFAFiltered]
      });
      setActiveBtn(btn);
   };


   const onClickCellButton = async (btnName) => {
      try {
         if (btnName === 'btn-reply') {
            if (isBothSideActionUser) {
               setModalPickConsultantForAdmin(true);
            } else {
               buttonPanelFunction('form-reply-RFA');
               getSheetRows({
                  currentRefToAddNewOrReplyOrEdit: {
                     currentRefData: infoRfa,
                     formRefType: 'form-reply-RFA',
                     isFormEditting: false
                  }
               });
            };
         } else if (btnName === 'btn-email-content') {

            setModalEmailContent(true);

         } else if (btnName === 'btn-rollback-previous-version') {

            setModalConfirmResendFailedEmailOrRollback('modal-rollback-previous-version');

         } else if (btnName === 'btn-resend-email') {

            setModalConfirmResendFailedEmailOrRollback('modal-resend-email');


         } else if (btnName === 'btn-resubmit') {

            const { children: rowsChildren } = rowData;

            if (rowsChildren.find(row => !row['RFA Ref'])) {
               if (isBothSideActionUser) {
                  buttonPanelFunction('option-email-or-not-for-admin');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        tempRefData: infoRfa
                     },
                  });
               } else {
                  buttonPanelFunction('form-resubmit-RFA');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        currentRefData: infoRfa,
                        formRefType: 'form-resubmit-RFA',
                        isFormEditting: false
                     }
                  });
               };
            } else {
               message.info('There is no drawing left in this RFA to resubmit!');
            };
         } else if (btnName === 'btn-download') {

            setModalDownloadDrawingShown(true);


         } else if (btnName === 'btn-issue-dt') {

            setModalIssueForConstructionDrawings(true);


         } else if (btnName === 'btn-drawing') {

            let dwgLink, drawingFileType;
            // let rowDataClone;
            if (column.key === 'RFA Ref') {
               dwgLink = getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'drawing');
               drawingFileType = getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'drawingFileType');
               // rowDataClone = { ...rowData };
            } else if (isColumnWithReplyData(column.key)) {
               dwgLink = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'drawing', replyCompany);
               drawingFileType = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'drawingFileType', replyCompany);
               // rowDataClone = { ...rowData };
            } else if (column.key.includes('Version ') && isDrawingDetailTableDms === 'drawing-detail-consultant-row') {
               dwgLink = getInfoValueFromRefDataForm(dataForDetailVersionTable, 'reply', 'rfa', 'drawing', replyCompany);
            } else if (column.key.includes('Version ') && isDrawingDetailTableDms === 'drawing-detail-rfa-row') {
               dwgLink = getInfoValueFromRefDataForm(dataForDetailVersionTable, 'submission', 'rfa', 'drawing');
            };

            if (dwgLink) {

               let linkToOpen;
               if (!drawingFileType || drawingFileType === 'drawing') {
                  linkToOpen = preLink + base64url(dwgLink);

               } else if (drawingFileType === 'document') {
                  const res = await apiFetchPublicUrl({ key: dwgLink, expire: 1000 });
                  linkToOpen = res.data;
               };
               window.open(linkToOpen, '_blank');

            };

         } else if (btnName === 'btn-comment') {

            const rowDataInput = isDrawingDetailTableDms === 'drawing-detail-consultant-row' ? dataForDetailVersionTable : rowData;

            setModalContent(
               <div>
                  <div style={{ fontWeight: 'bold' }}>{getInfoValueFromRefDataForm(infoRfa, 'reply', 'rfa', 'user', replyCompany) || ''}</div>
                  <div style={{ fontWeight: 'bold' }}>{getInfoValueFromRefDataForm(rowDataInput, 'reply', 'rfa', 'status', replyCompany) || ''}</div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{replaceBreakLine(getInfoValueFromRefDataForm(rowDataInput, 'reply', 'rfa', 'comment', replyCompany) || '')}</div>
               </div>
            );
         } else if (btnName === 'btn-3d-model') {

            const rowDataInput = isDrawingDetailTableDms === 'drawing-detail-rfa-row' ? dataForDetailVersionTable : rowData;

            const isEditTimeSubmissionIsOver = checkIfEditTimeRfaIsOver(rowDataInput, null, EDIT_DURATION_MIN, 'check-if-submission-edit-is-over');
            if (isEditTimeSubmissionIsOver > 0) {
               return message.warn('3D model is uploading, please wait ...');
            };
            const dwg3DLink = getInfoValueFromRefDataForm(rowDataInput, 'submission', 'rfa', 'dwfxLink');

            if (dwg3DLink) {

               const extension = (/(?:\.([^.]+))?$/).exec(dwg3DLink)[1];

               let linkToOpen = '';
               if (extension === 'pdf' || extension === 'dwfx' || extension === 'dwf') {

                  linkToOpen = preLink + base64url(dwg3DLink);

               } else {
                  const res = await apiFetchPublicUrl({ key: dwg3DLink, expire: 1000 });
                  linkToOpen = res.data;
               };
               window.open(linkToOpen, '_blank');
            };

         } else if (btnName === 'btn-edit') {

            let actionType, dataSendNoEmail, emailTo;
            let objConsultantNameToReplyByBothSideActionUser = {};
            if (isColumnWithReplyData(column.key)) {
               actionType = 'form-reply-RFA';
               dataSendNoEmail = getInfoValueFromRefDataForm(infoRfa, 'reply', 'rfa', 'dateSendNoEmail', replyCompany);
               emailTo = getInfoValueFromRefDataForm(infoRfa, 'reply', 'rfa', 'emailTo', replyCompany) || [];
               if (isBothSideActionUser) {
                  objConsultantNameToReplyByBothSideActionUser = {
                     consultantNameToReplyByBothSideActionUser: replyCompany
                  };
               };
            } else if (column.key === 'RFA Ref') {
               actionType = rowData['RFA Ref'] !== rowData['rfaNumber'] ? 'form-resubmit-RFA' : 'form-submit-RFA';
               dataSendNoEmail = getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'dateSendNoEmail');
               emailTo = getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'emailTo') || [];
            };

            buttonPanelFunction(actionType);
            getSheetRows({
               currentRefToAddNewOrReplyOrEdit: {
                  currentRefData: infoRfa,
                  formRefType: actionType,
                  isFormEditting: true,
                  withNoEmailSent: (dataSendNoEmail || !(emailTo.length > 0)) ? true : false, // some old submission has no dataSendNoEmail
                  ...objConsultantNameToReplyByBothSideActionUser
               },
            });
         };
      } catch (err) {
         console.log(err);
      };
   };




   const onClickDownload = (checkBoxDownload) => {

      message.info('Downloading zip file, please wait ...!', 2);

      const { children: rowsChildren } = rowData;

      let arrayCompanyDownloadLink = [];
      Object.keys(checkBoxDownload).forEach(cmp => {
         if (checkBoxDownload[cmp]) {
            if (cmp === 'submission') {
               rowsChildren.forEach(row => {
                  const dwgLink = getInfoValueFromRefDataForm(row, 'submission', 'rfa', 'drawing');
                  if (dwgLink) {
                     arrayCompanyDownloadLink.push({
                        folder: 'Submission',
                        dwgLink
                     });
                  };
               });
            } else if (cmp === '3d-model-file-download') {
               rowsChildren.forEach(row => {
                  const dwgLink = getInfoValueFromRefDataForm(row, 'submission', 'rfa', 'dwfxLink');
                  if (dwgLink) {
                     arrayCompanyDownloadLink.push({
                        folder: 'Submission',
                        dwgLink
                     });
                  };
               });
            } else {
               rowsChildren.forEach(row => {
                  const dwgLink = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'drawing', cmp);
                  if (dwgLink) {
                     arrayCompanyDownloadLink.push({
                        folder: `Reply - ${cmp}`,
                        dwgLink
                     });
                  };
               });
            };
         };
      });

      const zipFilename = `${rowData.id} - ${activeBtn === 'All' ? 'Consolidated' : activeBtn}.zip`;

      if (arrayCompanyDownloadLink.length > 0) {
         const downloadZipFile = () => {
            const zip = new JSZip();
            let count = 0;

            arrayCompanyDownloadLink.forEach(async ({ dwgLink, folder }) => {

               try {
                  const res = await apiFetchPublicUrl({ key: dwgLink, expire: 1000 });
                  const publicLink = res.data;
                  const fileName = getFileNameFromLinkResponse(dwgLink);

                  const file = await JSZipUtils.getBinaryContent(publicLink);
                  zip.file(`${folder}/${fileName}`, file, { binary: true, createFolders: true });
                  count++;
                  if (count === arrayCompanyDownloadLink.length) {
                     zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFilename);
                     });
                  };
               } catch (err) {
                  message.warn('There might be out-of-date downloads link in this RFA, please try download drawing one by one !', 2);
                  console.log(err);
               };
            });
         };
         downloadZipFile();
      } else {
         message.info('There is no drawings to download!', 2);
      };
   };




   const confirmResendFailedEmail = async () => {

      try {

         const thisFormInfo = await apiFetchOneDataMultiForm('rfa', { token, projectId, rowId: infoRfa['id'] });

         const { data: infoRfaFromDB, rfaRef: rfaRefFromDB, revision: revisionFromDB } = thisFormInfo.data;

         let typeEmail;
         let companySubmitOrReply;
         for (const key in infoRfaFromDB) {
            if (key.includes('-rfa-emailFailToSend-') && infoRfaFromDB[key]) {
               const parts = key.split('-');
               const company = parts[parts.length - 1];
               const typeSubmit = parts[0];
               const userWhoCreateThis = infoRfaFromDB[`${typeSubmit}-rfa-user-${company}`];
               if (userWhoCreateThis === email) {
                  typeEmail = infoRfaFromDB[key];
                  companySubmitOrReply = company;
               };
            };
         };

         if (!typeEmail || !companySubmitOrReply) {
            message.warn('This RFA has no pending email to send', 2);
         } else {

            setModalConfirmResendFailedEmailOrRollback(null);
            setLoading(true); // To Reload the page...

            const infoRfaForm = {
               _id: infoRfa['id'],
               rfaRef: rfaRefFromDB,
               revision: revisionFromDB,
               data: infoRfaFromDB
            };

            const data = {
               projectId, company: companySubmitOrReply, projectName,
               formSubmitType: 'rfa',
               type: typeEmail,
               rowIds: rowData['children'].map(x => x['id']),
               emailSender: email,
               isFormEditting: false,
               infoRfaForm
            };

            // const emailText = await apiSendEmailDevTest({ token, data });
            // console.log('emailText', emailText.data.emailContent);

            await apiSendEmail({
               token, data,
               momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
               isInstant: true,
            });

            const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW

            commandAction({ type: 'reload-data-view-rfa', data: res.data });
         };


      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };




   const confirmRollbackVersionThisDrawing = async () => {

      try {

         setModalConfirmResendFailedEmailOrRollback(null);
         setLoading(true); // To Reload the page...

         const { headers } = publicSettings;

         const resHistory = await apiFetchDataHistoryRowsByCurrentVersion({ token, projectId, email, row: rowData['id'] });
         
         const allRowWithThisPreviousVersion = resHistory.data;


         const allRfaPreviously = [... new Set(allRowWithThisPreviousVersion.map(x => x['RFA Ref']))].map(rfaText => {
            if (rfaText === rowData['rfaNumber']) {
               return '0';
            } else {
               return rfaText.slice(rowData['rfaNumber'].length, rfaText.length);
            };
         }).sort();

         const previousVersion = allRfaPreviously[allRfaPreviously.length - 1];

         if (previousVersion) {
            let rowOutput = {
               _id: rowData['id'],
               data: {}
            };
            const rowWithThisPreviousVersion = allRowWithThisPreviousVersion.find(dwg => {
               return (
                  previousVersion === '0'
                     ? dwg['RFA Ref'] === rowData['rfaNumber']
                     : dwg['RFA Ref'] === rowData['rfaNumber'] + previousVersion
               ) && dwg['row'] === rowData['id'];
            });

            for (const key in rowWithThisPreviousVersion) {
               const hdFound = headers.find(hd => hd.text === key);
               if (hdFound) {
                  rowOutput['data'][hdFound.key] = rowWithThisPreviousVersion[key];
               } else if (key.includes('reply-rfa-') || key.includes('submission-rfa-')) {
                  rowOutput['data'][key] = rowWithThisPreviousVersion[key];
               };
            };

            await apiUpdateOrCreateRows({ token, projectId, rows: [rowOutput] });
            await apiDeleteRowsHistory({ token, projectId, email, rowsHistoryIdsArray: [rowWithThisPreviousVersion['id']] });
         };

         const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW

         commandAction({ type: 'reload-data-view-rfa', data: res.data });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };



   const cellStyle1 = pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && column.key === 'Drawing Number'
      ? {}
      : { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };



   return (
      <>
         {(pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && isColumnWithReplyData(column.key) && statusAllDwgInThisRfa) ? (

            <div style={{ display: 'flex', width: '100%', height: '100%' }}>

               <div style={{ width: 50, textAlign: 'center', paddingTop: 5, fontWeight: 'bold' }}>{statusAllDwgInThisRfa['company']}</div>

               {sortTotalStatusRfaOrder(statusAllDwgInThisRfa['data'] || {}).map((stt, i) => (
                  <div
                     key={i}
                     style={{
                        width: `${100 * statusAllDwgInThisRfa['data'][stt] / getTotalStatusInRfa(statusAllDwgInThisRfa['data'])}%`,
                        background: stt === 'Consultant reviewing' ? 'white' : colorTextRow[stt],
                        textAlign: 'center',
                        paddingTop: 5,
                        fontWeight: 'bold',
                     }}
                  >{statusAllDwgInThisRfa['data'][stt]}</div>
               ))}

            </div>
         ) : (
            <div
               style={{
                  width: '100%', height: '100%', position: 'relative', padding: 5,
                  ...cellStyle1,
                  color: ((isColumnWithReplyData(column.key)) && replyStatus) ? 'white'
                     : (!isItInFilterStatusMode && column.key === 'Due Date' && overdueCount < 0) ? colorTextRow['Overdue']
                        : (!isItInFilterStatusMode && column.key === 'Due Date' && overdueCount >= 0 && overdueCount < 3) ? colorTextRow['Due in the next 1 - 3 days']
                           : (!isItInFilterStatusMode && column.key === 'Due Date' && overdueCount >= 3) ? 'black'
                              : 'black',

                  background: ((isColumnWithReplyData(column.key)) && replyStatus) ? colorTextRow[replyStatus]
                     : (column.key.includes('Version ') && replyStatus) ? colorTextRow[replyStatus]
                        : 'transparent',
                  fontWeight: (
                     (column.key === 'RFA Ref' && rowData.treeLevel) ||
                     (!isItInFilterStatusMode && column.key === 'Due Date' && overdueCount)
                  ) ? 'bold' : 'normal'
               }}
               onMouseOver={() => {
                  if (!btnShown) setBtnShown(true);
               }}
               onMouseLeave={() => {
                  if (btnShown) setBtnShown(false);
               }}
            >
               {(pageSheetTypeName === 'page-rfa' && rowData.treeLevel && !isRowLevelRfaHeader && column.key === 'RFA Ref')
                  ? rowData.title
                  : (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && column.key === 'RFA Ref')
                     ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                           <span style={{ marginRight: 5 }}>{rowData['rfaNumber']}</span>
                           <div style={{ display: 'flex' }}>
                              {[...rowData['btn'].sort(), 'All'].map(btn => (
                                 <Tooltip key={btn} placement='top' title={btn === 'All' ? 'Consolidate latest drawings' : btn}>
                                    <ButtonRFA
                                       onClick={() => onClickRfaVersion(btn)}
                                       isActive={btn === activeBtn}
                                    >{btn}</ButtonRFA>
                                 </Tooltip>
                              ))}
                           </div>

                           {arrayButtonAlwaysVisibleCell.map((btn, i) => (
                              <Tooltip key={btn} placement='top' title={getTooltipRfaText(btn)}>
                                 <Icon
                                    type={getButtonRfaType(btn)}
                                    style={{
                                       fontSize: 16, transform: 'translateY(1.5px)',
                                       position: 'absolute', right: getOffsetRight(i),
                                       top: 0, height: 17, width: 17,
                                    }}
                                    onClick={() => onClickCellButton(btn)}
                                 />
                              </Tooltip>
                           ))}
                        </div>
                     ) : (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && column.key === 'Drawing Number') ? (
                        <div style={{
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           fontWeight: 'bold',
                           width: 650, // TEMPORARY FIXED WIDTH

                        }}>{rfaSubjectText}</div>

                     ) : (pageSheetTypeName === 'page-rfa' && isRowLevelRfaHeader && column.key === 'Due Date') ?

                        moment(getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'due')).format('DD/MM/YY')

                        : (!rowData.treeLevel)
                           ? getCellDataRfaView({
                              rowData, header: column.key, replyCompany, replyStatus, replyDate,
                              isDrawingDetailTableDms, dataForDetailVersionTable, cellData, infoRfa, companies
                           }) : null}





               {btnShown && (
                  <>
                     {arrayButtonCell.map((btn, i) => (
                        <Tooltip key={btn} placement='top' title={getTooltipRfaText(btn)}>
                           <Icon
                              type={getButtonRfaType(btn)}
                              style={{
                                 color: replyStatus ? 'white' : 'black', fontSize: 16,
                                 cursor: 'pointer', position: 'absolute',
                                 right: getOffsetRightHoverBtn(i, arrayButtonAlwaysVisibleCell.length),
                                 top: 6, height: 17, width: 17
                              }}
                              onClick={() => onClickCellButton(btn)}
                           />
                        </Tooltip>
                     ))}
                  </>
               )}



               {modalContent && (
                  <ModalStyledSetting
                     title={'Drawing Comment'}
                     visible={modalContent !== null ? true : false}
                     footer={null}
                     onCancel={() => {
                        setModalContent(null);
                        setBtnShown(false);
                     }}
                     destroyOnClose={true}
                     centered={true}
                     width='40%'
                  >
                     <div style={{ overflowY: 'auto', maxHeight: 600 }}>
                        {modalContent}
                     </div>
                  </ModalStyledSetting>
               )}



               {modalDownloadDrawingShown && (
                  <ModalStyledSetting
                     title={'Download Drawings'} footer={null}
                     visible={modalDownloadDrawingShown}
                     onCancel={() => {
                        setModalDownloadDrawingShown(false);
                     }}
                     destroyOnClose={true}
                     centered={true}
                  >
                     <CheckBoxDownloadDrawings
                        rowData={rowData} infoRfa={infoRfa}
                        onClickCancelModal={() => {
                           setModalDownloadDrawingShown(false);
                        }}
                        onClickDownload={(checkBoxDownload) => {
                           onClickDownload(checkBoxDownload);
                           setModalDownloadDrawingShown(false);
                        }}
                     />
                  </ModalStyledSetting>
               )}





               {modalIssueForConstructionDrawings && (
                  <ModalStyledSetting
                     title={'Drawings Issue For Construction'}
                     visible={modalIssueForConstructionDrawings}
                     footer={null}
                     onCancel={() => {
                        setModalIssueForConstructionDrawings(false);
                        setBtnShown(false);
                     }}
                     destroyOnClose={true}
                     centered={true}
                  >
                     <ListDrawingsIssueForConstruction
                        rowData={rowData}
                        stateProject={stateProject}
                        dataForDetailVersionTable={dataForDetailVersionTable}
                        isDrawingDetailTableDms={isDrawingDetailTableDms}
                     />
                  </ModalStyledSetting>
               )}





               {modalPickConsultantForAdmin && (
                  <ModalStyledSetting
                     title={'Choose Consultant To Reply'}
                     visible={modalPickConsultantForAdmin}
                     footer={null}
                     onCancel={() => setModalPickConsultantForAdmin(false)}
                     destroyOnClose={true}
                     centered={true}
                     width={window.innerWidth * 0.3}
                  >
                     <FormPickConsultantToReplyForAdmin
                        applyChooseConsultantToReplyForBothSideUser={(consultantName) => {
                           setModalPickConsultantForAdmin(false);
                           buttonPanelFunction('option-email-or-not-for-admin');
                           getSheetRows({
                              currentRefToAddNewOrReplyOrEdit: {
                                 tempRefData: infoRfa,
                                 tempConsultantToReply: consultantName,
                              },
                           });
                        }}
                        onClickCancelModal={() => setModalPickConsultantForAdmin(false)}
                        infoRfa={infoRfa}
                        rowData={rowData}
                     />
                  </ModalStyledSetting>
               )}

            </div>
         )}



         {modalEmailContent && (
            <ModalStyledSetting
               title={'Email Content'}
               visible={modalEmailContent}
               footer={null}
               onCancel={() => {
                  setModalEmailContent(false);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width={'60%'}
            >
               <EmailContentBox infoRfa={infoRfa} refType={'rfa'} />
            </ModalStyledSetting>
         )}


         {modalConfirmResendFailedEmailOrRollback && (
            <ModalStyledSetting
               title={modalConfirmResendFailedEmailOrRollback === 'modal-resend-email' ? 'Resend Failed Email' : 'Rollback To Previous Version'}
               visible={modalConfirmResendFailedEmailOrRollback === null ? false : true}
               footer={null}
               onCancel={() => {
                  setModalConfirmResendFailedEmailOrRollback(null);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width={'60%'}
            >
               <div style={{ color: 'black' }}>Are you sure to {modalConfirmResendFailedEmailOrRollback === 'modal-resend-email' ? 'resend failed email' : 'rollback to previous version'} ?</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey3}`, marginTop: 10 }}>
                  <ButtonGroupComp
                     onClickCancel={() => setModalConfirmResendFailedEmailOrRollback(null)}
                     onClickApply={() => {
                        if (modalConfirmResendFailedEmailOrRollback === 'modal-resend-email') {
                           confirmResendFailedEmail();
                        } else if (modalConfirmResendFailedEmailOrRollback === 'modal-rollback-previous-version') {
                           confirmRollbackVersionThisDrawing();
                        };
                     }}
                     newTextBtnApply={modalConfirmResendFailedEmailOrRollback === 'modal-resend-email' ? 'Resend Email' : 'Rollback To Previous Version'}
                  />
               </div>
            </ModalStyledSetting>
         )}

      </>
   );
};

export default CellRFA;


const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      /* border: none; */
      /* background: ${colorType.primary} */
   }
`;

const ButtonRFA = styled.div`
   &:hover {
      cursor: pointer;
   };
   border-radius: 0;
   border: 1px solid grey;
   background: ${props => props.isActive ? colorType.yellow : 'white'};
   min-width: 21px;
   margin-right: 3px;
   
   text-align: center;
   transition: 0.3s;
`;
const ModalStyledSetting = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 20px;
      justify-content: center;
   }
`;


const getArrayCompanyReplied = (rowsChildren, infoRfa) => {
   let arrayCompanyReplied = [];
   rowsChildren.forEach(row => {
      const consultantMustReplyArray = getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'consultantMustReply') || [];
      consultantMustReplyArray.forEach(cmp => {
         if (getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', cmp)) {
            arrayCompanyReplied.push(cmp);
         };
      });
   });
   arrayCompanyReplied = ['submission', ...[...new Set(arrayCompanyReplied)]];

   return arrayCompanyReplied;
};

const getCheckBoxDownloadInit = (listConsultants) => {
   const objInitCheckboxDownload = { 'submission': false };
   listConsultants.forEach(cmp => {
      objInitCheckboxDownload[cmp] = false;
   });
   return objInitCheckboxDownload;
};


const CheckBoxDownloadDrawings = ({ rowData, onClickCancelModal, onClickDownload, infoRfa }) => {

   const { children: rowsChildren } = rowData;

   let arrayCompanyReplied = getArrayCompanyReplied(rowsChildren, infoRfa);
   arrayCompanyReplied = [...arrayCompanyReplied, '3d-model-file-download']

   const [checkBoxDownload, setCheckBoxDownload] = useState(getCheckBoxDownloadInit(arrayCompanyReplied));

   return (
      <div>
         {arrayCompanyReplied.map((item, i) => (
            <div key={i}>
               <CheckboxStyled
                  style={{ margin: 7 }}
                  onChange={() => {
                     const checkBoxState = { ...checkBoxDownload };
                     checkBoxState[item] = !checkBoxState[item];
                     setCheckBoxDownload(checkBoxState);
                  }}
                  checked={checkBoxDownload[item]}
               >
                  {item === 'submission' ? 'Submission drawings' : item === '3d-model-file-download' ? 'Submission 3D / DWG' : `Commented drawings - ${item}`}
               </CheckboxStyled>
            </div>
         ))}

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey3}`, marginTop: 10 }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => {
                  onClickDownload(checkBoxDownload);
               }}
               newTextBtnApply='Download Drawings'
            />
         </div>
      </div>
   );
};





export const getConsultantReplyData = (rfaDataInfo, rowData, header) => {
   let replyStatus, replyCompany, replyDate;
   let listConsultantMustReply = getInfoValueFromRefDataForm(rfaDataInfo, 'submission', 'rfa', 'consultantMustReply');

   if (!listConsultantMustReply || listConsultantMustReply.length === 0) return { replyStatus, replyCompany, replyDate };

   const listArrayLead = listConsultantMustReply.filter((x, i) => i === 0);
   const listArrayRemaining = listConsultantMustReply.filter((x, i) => i > 0);
   listConsultantMustReply = [...listArrayLead, ...listArrayRemaining.sort()];

   if (!listConsultantMustReply || listConsultantMustReply.length === 0) return { replyStatus, replyCompany, replyDate };
   const consultantHeaderNumber = parseInt(header.slice(12, header.length - 1));
   const consultantNameOfThisCell = listConsultantMustReply[consultantHeaderNumber - 1];
   return {
      replyStatus: rowData[`reply-rfa-status-${consultantNameOfThisCell}`],
      replyCompany: consultantNameOfThisCell,
      replyDate: convertReplyOrSubmissionDate(rowData[`reply-rfa-date-${consultantNameOfThisCell}`])
   };
};


const getCellDataRfaView = ({ rowData, header, replyCompany, replyStatus, replyDate, isDrawingDetailTableDms, dataForDetailVersionTable, cellData, infoRfa, companies }) => {

   if (header === 'Submission Date') {
      return rowData['Drg To Consultant (A)'];

   } else if (header === 'Requested By') {
      return getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'requestedBy');

   } else if (header === 'Due Date') {
      return rowData['Consultant Reply (T)'];

   } else if (isColumnWithReplyData(header)) {
      const cmpFound = companies.find(cmp => cmp['company'] === replyCompany) || {};
      const nameDisplay = cmpFound['shortName'] || replyCompany;
      return replyStatus ? (
         <>
            <span style={{ fontWeight: 'bold' }}>{nameDisplay}</span>
            <span>{` - (${replyDate})`}</span>
         </>
      ) : nameDisplay;

   } else if (
      header.includes('Version ') &&
      isDrawingDetailTableDms === 'drawing-detail-consultant-row' &&
      replyStatus
   ) {
      const cmpFound = companies.find(cmp => cmp['company'] === replyCompany) || {};
      const nameDisplay = cmpFound['shortName'] || replyCompany;

      return (
         <span style={{ float: 'left', paddingLeft: 3, fontWeight: 'bold' }}>
            {nameDisplay} - ({replyDate})
         </span>
      );
   } else if (
      header.includes('Version ') &&
      isDrawingDetailTableDms === 'drawing-detail-rfa-row'
   ) {
      return (
         <span style={{ float: 'left', paddingLeft: 3, fontWeight: 'bold' }}>
            {dataForDetailVersionTable.rfaRef}
         </span>
      );

   } else if (
      header.includes('Version ') &&
      isDrawingDetailTableDms === 'drawing-detail-issueForConstruction-row'
   ) {
      return (
         <span style={{ float: 'left', paddingLeft: 3, fontWeight: 'bold' }}>
            {getInfoValueFromRefDataForm(dataForDetailVersionTable, 'submission', 'dt', 'issueForConstruction')}
         </span>
      );

   } else if (header === 'Issue For Construction') {
      const dtRef = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction') || '';
      const allParts = dtRef.split('/');

      let lengthDeduce = 0;
      if (allParts[0] && allParts[1]) {
         lengthDeduce = (allParts[0] + '/' + allParts[1] + '/').length;
      };

      return (
         <span style={{ float: 'right', marginRight: 23 }}>{dtRef.slice(lengthDeduce, dtRef.length)}</span>
      );
   } else if (header === 'IFC Rev') {
      return getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'ifcVersion');
   } else if (header === 'IFC Date') {
      const dateIssuance = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'date');
      return dateIssuance ? moment(dateIssuance).format('DD/MM/YY') : '';

   } else {
      return cellData;
   };
};



export const isColumnWithReplyData = (header) => {
   return header === 'Consultant (1)' ||
      header === 'Consultant (2)' ||
      header === 'Consultant (3)' ||
      header === 'Consultant (4)' ||
      header === 'Consultant (5)' ||
      header === 'Consultant (6)' ||
      header === 'Consultant (7)'
};



export const convertReplyOrSubmissionDate = (date) => {

   if (typeof date === 'string' && date.length === 8) {
      return date;
   } else if (
      (typeof date === 'string' && date.length > 8) ||
      moment.isMoment(date) ||
      (typeof date === 'object')
   ) {
      return moment(date).format('DD/MM/YY');
   };
};


export const checkIfEditTimeRfaIsOver = (obj, company, editTimeAllowed, type) => {

   if (type === 'check-if-submission-edit-is-over') {
      const dateNoSendEmailSubmission = getInfoValueFromRefDataForm(obj, 'submission', 'rfa', 'dateSendNoEmail');
      const dateSubmission = getInfoValueFromRefDataForm(obj, 'submission', 'rfa', 'date');
      const date = dateNoSendEmailSubmission || dateSubmission;

      if (typeof date === 'string' && date.length > 8) {
         const duration = moment.duration(moment(new Date()).diff(date)).asMinutes();
         return editTimeAllowed - duration;
      } else {
         return -1;
      };
   } else if (type === 'check-if-reply-edit-is-over') {

      const dateNoSendEmailReply = getInfoValueFromRefDataForm(obj, 'reply', 'rfa', 'dateSendNoEmail', company);
      const dateReply = getInfoValueFromRefDataForm(obj, 'reply', 'rfa', 'date', company);
      const date = dateNoSendEmailReply || dateReply;

      if (typeof date === 'string' && date.length > 8) {
         const duration = moment.duration(moment(new Date()).diff(date)).asMinutes();
         return editTimeAllowed - duration;
      } else {
         return -1;
      };
   };
};


const getOffsetRight = (index) => {
   return 5 + index * 22;
};


const getOffsetRightHoverBtn = (index, arrayButtonAlwaysVisibleCellLength) => {
   return arrayButtonAlwaysVisibleCellLength * 22 + 5 + (index) * 22 + 5;
};


const FormPickConsultantToReplyForAdmin = ({
   applyChooseConsultantToReplyForBothSideUser,
   onClickCancelModal,
   infoRfa,
   rowData
}) => {

   const { children: rowsChildren } = rowData;

   const rowsAdditionalAfterSubmit = rowsChildren.filter(x => x['Status'] === 'Consultant reviewing');
   let oneChildrenRow;
   if (rowsAdditionalAfterSubmit.length > 0) {
      oneChildrenRow = rowsAdditionalAfterSubmit[0];
   } else {
      oneChildrenRow = rowsChildren[0];
   };

   const consultantMustReplyArray = getInfoValueFromRefDataForm(infoRfa, 'submission', 'rfa', 'consultantMustReply') || [];
   let arrayConsultantsNotReplyYet = [];
   consultantMustReplyArray.forEach(cmp => {
      const replyStatus = getInfoValueFromRefDataForm(oneChildrenRow, 'reply', 'rfa', 'status', cmp);

      const isEditTimeSubmissionIsOver = checkIfEditTimeRfaIsOver(infoRfa, null, EDIT_DURATION_MIN, 'check-if-submission-edit-is-over');

      if (!replyStatus && isEditTimeSubmissionIsOver <= 0) {
         arrayConsultantsNotReplyYet = [...arrayConsultantsNotReplyYet, cmp];
      };
   });


   const [list, setList] = useState(arrayConsultantsNotReplyYet.map(cst => ({
      id: mongoObjectId(),
      header: cst,
      mode: 'hidden'
   })));

   const onClickApply = () => {
      const consultantToReply = list.find(x => x.mode === 'shown');
      if (!consultantToReply) {
         return message.warn('Please choose consultant to reply!');
      };
      applyChooseConsultantToReplyForBothSideUser(consultantToReply.header);
   };

   const setMode = (item) => {
      list.forEach(tag => {
         if (tag.id === item.id) {
            tag.mode = 'shown';
         } else {
            tag.mode = 'hidden';
         };
      });
      setList([...list]);
   };

   return (
      <div style={{ width: '100%', height: '100%' }}>
         <PanelStyled>
            {list.length > 0 && (
               <div style={{ fontSize: 12, paddingLeft: 20 }}>Click to select consultant to reply.</div>
            )}
            <div style={{ width: '100%', paddingTop: 20 }}>
               {list.length > 0 ? list.map((tag, i) => (
                  <ButtonColumnTag
                     key={i}
                     tag={tag}
                     setMode={setMode}
                     actionType='admin-pick-consultant-to-reply'
                  />
               )) : 'Woh Hup is submitting the form, please wait!'}

            </div>

         </PanelStyled>
         <div style={{ marginTop: 10, padding: 10, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={onClickApply}
            />
         </div>

      </div>
   );
};


const PanelStyled = styled.div`
   max-height: 60vh;
   width: 100%;
   border-bottom: 1px solid ${colorType.grey4};
`;


const getTooltipRfaText = (btnName) => {
   let result = 'No Tooltip';
   if (btnName === 'btn-reply') {
      result = 'Reply To This RFA';
   } else if (btnName === 'btn-resubmit') {
      result = 'Resubmit This RFA';
   } else if (btnName === 'btn-resend-email') {
      result = 'Resend Email';
   } else if (btnName === 'btn-rollback-previous-version') {
      result = 'Rollback To Previous Version';

   } else if (btnName === 'btn-email-content') {
      result = 'View Email Content';
   } else if (btnName === 'btn-download') {
      result = 'Download All Drawings';

   } else if (btnName === 'btn-drawing') {
      result = 'Open Drawing File';
   } else if (btnName === 'btn-issue-dt') {
      result = 'Open Drawings Issue For Construction';
   } else if (btnName === 'btn-comment') {
      result = 'See Note';
   } else if (btnName === 'btn-3d-model') {
      result = 'Open 3D / CAD';
   } else if (btnName === 'btn-edit') {
      result = 'Edit';
   };
   return result;
};


const getButtonRfaType = (btnName) => {
   let result = 'xxx';
   if (btnName === 'btn-reply') {
      result = 'form';
   } else if (btnName === 'btn-resubmit') {
      result = 'plus-square';
   } else if (btnName === 'btn-resend-email') {
      result = 'mail';
   } else if (btnName === 'btn-rollback-previous-version') {
      result = 'rollback';
   } else if (btnName === 'btn-email-content') {
      result = 'project';
   } else if (btnName === 'btn-download') {
      result = 'download';
   } else if (btnName === 'btn-drawing') {
      result = 'file';
   } else if (btnName === 'btn-issue-dt') {
      result = 'file';
   } else if (btnName === 'btn-comment') {
      result = 'message';
   } else if (btnName === 'btn-3d-model') {
      result = 'shake';
   } else if (btnName === 'btn-edit') {
      result = 'edit';
   };
   return result;
};






const getStatusAllDwgInThisRfa = (rfaData, rowData, header, companies) => {

   const companyIndex = parseInt(header.slice(12, header.length - 1));

   const rowChild = (rowData.children || [])[0] || {};

   const rfaDataInfo = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rowChild['RFA Ref']) || {};

   const allRowsChildren = rowData.children;
   let consultantMustReplyArray = getInfoValueFromRefDataForm(rfaDataInfo, 'submission', 'rfa', 'consultantMustReply') || [];

   const listArrayLead = consultantMustReplyArray.filter((x, i) => i === 0);
   const listArrayRemaining = consultantMustReplyArray.filter((x, i) => i > 0);
   consultantMustReplyArray = [...listArrayLead, ...listArrayRemaining.sort()];

   const companyThisCell = consultantMustReplyArray[companyIndex - 1];
   if (companyThisCell) {
      const cmpFoundInList = companies.find(cm => cm['company'] === companyThisCell) || {};
      const allRowsStatus = {
         company: cmpFoundInList['shortName'] ? cmpFoundInList['shortName'].substr(0, 3) : getCompanyNameShort(companyThisCell),
         data: {}
      };
      allRowsChildren.forEach(row => {
         const statusLead = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', companyThisCell);
         if (statusLead) {
            allRowsStatus['data'][statusLead] = (allRowsStatus['data'][statusLead] || 0) + 1;
         } else {
            allRowsStatus['data']['Consultant reviewing'] = (allRowsStatus['data']['Consultant reviewing'] || 0) + 1;
         };
      });
      return allRowsStatus;
   };
};





const getVersionFromActiveBtn = (rowData, activeBtn) => {

   const rfaNumber = rowData.id;
   const allBtn = rowData['btn'];

   const lastBtn = allBtn[allBtn.length - 1];

   let rfaRef;
   if (activeBtn === '0') {
      rfaRef = rfaNumber;
   } else if (activeBtn === 'All') {
      rfaRef = rfaNumber + (lastBtn === '0' ? '' : lastBtn);
   } else if (activeBtn) { // A, B, C, ....
      rfaRef = rfaNumber + activeBtn;
   };
   return rfaRef;
};


export const EmailContentBox = (props) => {

   const { infoRfa, refType, rowData } = props;

   const infoFormData = refType === 'rfa' ? infoRfa : rowData;

   const consultantMustReplyArray = getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'consultantMustReply')

   return (
      <div style={{
         maxHeight: window.innerHeight * 0.7,
         padding: 7,
         overflowY: 'scroll',
         color: 'black'
      }}>
         <EmailCompany infoFormData={infoFormData} refType={refType} />
         <>
            {consultantMustReplyArray.map(cmp => (
               <EmailCompany
                  key={cmp}
                  consultantName={cmp}
                  infoFormData={infoFormData}
                  refType={refType}
               />
            ))}
         </>
      </div>


   );
};

const EmailCompany = (props) => {

   const { infoFormData, consultantName, refType } = props;

   const user = consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'user', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'user');
   const requestedBy = consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'requestedBy', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'requestedBy');
   const rfaNumber = getRefStringWithVersion(infoFormData, refType);
   const emailTitle = consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'emailTitle', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'emailTitle');
   const date = consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'date', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'date');
   const emailContent = refType === 'rfa' ? (consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'emailAdditionalNotes', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'emailAdditionalNotes'))
      : (consultantName ? getInfoValueFromRefDataForm(infoFormData, 'reply', refType, 'description', consultantName) : getInfoValueFromRefDataForm(infoFormData, 'submission', refType, 'description'));

   const arrayEmailContent = (emailContent || '').split('/\r?\n/');

   return date ? (
      <div style={{ paddingBottom: 10, marginBottom: 15, borderBottom: `1px solid ${colorType.grey3}` }}>
         <div style={{ display: 'flex', marginBottom: 5 }}>
            <span style={{ marginRight: 7, fontWeight: 'bold' }}>Company: </span>
            <span>{consultantName || 'Woh Hup Private Ltd'}</span>
         </div>
         {!consultantName && (
            <>
               <div style={{ display: 'flex', marginBottom: 5 }}>
                  <span style={{ marginRight: 7, fontWeight: 'bold' }}>{refType.toUpperCase()} Ref: </span>
                  <span>{rfaNumber}</span>
               </div>
               <div style={{ display: 'flex', marginBottom: 5 }}>
                  <span style={{ marginRight: 7, fontWeight: 'bold' }}>Subject: </span>
                  {emailTitle}
               </div>
            </>
         )}

         <div style={{ display: 'flex', marginBottom: 5 }}>
            <span style={{ marginRight: 7, fontWeight: 'bold' }}>{consultantName ? 'Reply' : 'Submission'} Date: </span>
            <span>{date ? moment(date).format('Do MMM YYYY') : ''}</span>
         </div>
         <div style={{ display: 'flex', marginBottom: 5 }}>
            <span style={{ marginRight: 7, fontWeight: 'bold' }}>Sender: </span>
            <span>{user}</span>
         </div>

         {!consultantName && (
            <>
               <div style={{ display: 'flex', marginBottom: 5 }}>
                  <span style={{ marginRight: 7, fontWeight: 'bold' }}>Requested By: </span>
                  <span>{requestedBy}</span>
               </div>
            </>
         )}

         <div style={{ display: 'flex', marginBottom: 5 }}>
            <span style={{ marginRight: 7, fontWeight: 'bold' }}>Note: </span>
            <div>
               {arrayEmailContent.map((text, i) => (
                  <div key={i}>{text}</div>
               ))}
            </div>

         </div>
      </div>
   ) : <></>;
};



export const ListDrawingsIssueForConstruction = ({ stateProject, rowData, dataForDetailVersionTable, isDrawingDetailTableDms }) => {

   const { projectId, token } = stateProject.allDataOneSheet;

   const rowDataInput = isDrawingDetailTableDms === 'drawing-detail-issueForConstruction-row' ? dataForDetailVersionTable : rowData;
   const drawingLinkIssueForConstruction = getInfoValueFromRefDataForm(rowDataInput, 'submission', 'dt', 'drawingLinkIssueForConstruction') || [];
   const dtIssueForConstructionNumber = getInfoValueFromRefDataForm(rowDataInput, 'submission', 'dt', 'issueForConstruction') || '';

   const refTextParts = dtIssueForConstructionNumber.split('/');
   const lastPart = refTextParts[refTextParts.length - 1];
   let refNumber, revision;
   if (lastPart.length === 4) {
      refNumber = dtIssueForConstructionNumber;
      revision = '0';
   } else if (lastPart.length === 5) {
      refNumber = dtIssueForConstructionNumber.slice(0, dtIssueForConstructionNumber.length - 1);
      revision = lastPart.slice(4, 5);
   };


   const [dtData, setDtData] = useState(null);

   useEffect(() => {
      const fetchDataFormThisRef = async () => {
         try {
            const thisFormInfo = await apiFetchOneDataByRefMultiForm('dt', { token, projectId, refNumber, revision });
            setDtData(thisFormInfo.data);
         } catch (err) {
            console.log(err);
         };
      };
      fetchDataFormThisRef();
   }, []);


   return dtData ? (
      <div style={{ color: 'black', maxHeight: window.innerHeight * 0.8, overflowY: 'scroll' }}>

         <div style={{ paddingBottom: 10, marginBottom: 15 }}>
            <div style={{ display: 'flex', marginBottom: 5 }}>
               <span style={{ marginRight: 7, fontWeight: 'bold' }}>DT Ref: </span>
               <span>{dtIssueForConstructionNumber}</span>
            </div>
            <div style={{ display: 'flex', marginBottom: 5 }}>
               <span style={{ marginRight: 7, fontWeight: 'bold' }}>Date: </span>
               <span>{moment(getInfoValueFromRefDataForm(dtData['data'] || {}, 'submission', 'dt', 'date')).format('Do MMM YYYY')}</span>
            </div>
            <div style={{ display: 'flex', marginBottom: 5 }}>
               <span style={{ marginRight: 7, fontWeight: 'bold' }}>Description: </span>
               <span>{getInfoValueFromRefDataForm(dtData['data'] || {}, 'submission', 'dt', 'description')}</span>
            </div>
            <div style={{ display: 'flex', marginBottom: 5 }}>
               <span style={{ marginRight: 7, fontWeight: 'bold' }}>Herewith: </span>
               <span>{getInfoValueFromRefDataForm(dtData['data'] || {}, 'submission', 'dt', 'herewithForDt')}</span>
            </div>
            <div style={{ display: 'flex', marginBottom: 5 }}>
               <span style={{ marginRight: 7, fontWeight: 'bold' }}>Transmitted For: </span>
               <span>{getInfoValueFromRefDataForm(dtData['data'] || {}, 'submission', 'dt', 'transmittedForDt')}</span>
            </div>
         </div>

         <div style={{ marginTop: 15, fontWeight: 'bold' }}>Drawing List</div>
         {drawingLinkIssueForConstruction.map((link, i) => {
            const fileName = /[^/]*$/.exec(link)[0];
            return (
               <FileLinkName
                  key={i}
                  onClick={() => {
                     const linkToOpen = preLink + base64url(link);
                     window.open(linkToOpen, '_blank');
                  }}
                  style={{ cursor: 'pointer', margin: 5, padding: 5, color: colorType.hyperlink }}
               >{fileName}</FileLinkName>
            );

         })}
      </div>
   ) : (
      <div style={{ height: 124, display: 'flex', justifyContent: 'center', paddingTop: 35 }}>
         <Icon type='loading' style={{ fontSize: 30 }} />
      </div>
   );
};


const FileLinkName = styled.div`
   &:hover {
      background-color: #f1f2f6;
   };
   transition: 0.3s;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
`;