import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { add, update } from '../../api/block';

import { Button, Input, Form, notification, Select, InputNumber } from 'antd';
const { Option } = Select;
const _ = require('lodash');

function BlockUnitForm(props) {
  const { record, isAdd, id, parentId, levels, unitTypes } = props;
  const { setVisible, getDatas } = props;
  const projectId = useSelector((state) => state.project.projectId);

  const [selectedBlock, setSelectedBlock] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        let payload = {
          ...values,
          projectId,
        };

        if (isAdd) {
          try {
            await add(payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: error?.response?.data?.message,
            });
          }
        } else {
          try {
            await update(id, payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: error?.response?.data?.message,
            });
          }
        }
      }
    });
  }

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        blockName: '',
        levelId: '',
        unitName: '',
        noOfSample: 1,
        remark: '',
        idUnitType: null,
      });
    } else {
      props.form.setFieldsValue({
        blockName: record.blockName,
        levelId: record.levelId,
        unitName: record.unitName,
        remark: record.remark,
        idUnitType: record.idUnitType,
      });
    }
  }, [isAdd, record]);

  const { getFieldDecorator } = props.form;

  function getOptLevels() {
    var optLevels = _.clone(levels);

    if (!isAdd && Array.isArray(optLevels)) {
      optLevels = optLevels.filter((x) => x.value != id);
    }

    if (Array.isArray(optLevels)) {
      optLevels = optLevels.filter((x) => x.block == selectedBlock);
    }

    return (
      Array.isArray(optLevels) &&
      optLevels.map((x) => (
        <Option key={x.id} value={x.id}>
          {x.name}
        </Option>
      ))
    );
  }

  function getOptBlocks() {
    let blocks = _.uniq(levels.map((x) => x.block));

    console.log('levels', levels);
    console.log('blocks', blocks);
    return (
      Array.isArray(blocks) &&
      blocks.map((x) => (
        <Option key={x} value={x}>
          {x}
        </Option>
      ))
    );
  }

  function getOptUnitTypes() {
    var opt = _.clone(unitTypes);

    if (!isAdd && Array.isArray(opt)) {
      opt = opt.filter((x) => x.value != id);
    }

    return (
      Array.isArray(opt) &&
      opt.map((x) => (
        <Option key={x.id} value={x.id}>
          {x.name}
        </Option>
      ))
    );
  }

  return (
    <div>
      <Form
        className='ant-form-custom'
        onSubmit={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label='Block Name'>
          {getFieldDecorator('blockName', {
            rules: [{ required: true, message: 'Please input block name!' }],
          })(
            <Select
              onChange={(e) => {
                console.log('e', e);
                setSelectedBlock(e);
              }}
            >
              {getOptBlocks()}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='Level'>
          {getFieldDecorator('levelId', {
            rules: [{ required: false, message: 'Choose Level!' }],
          })(<Select>{getOptLevels()}</Select>)}
        </Form.Item>

        <Form.Item label='Unit Name'>
          {getFieldDecorator('unitName', {
            rules: [{ required: true, message: 'Please input unit name!' }],
          })(<Input placeholder='Unit Name' />)}
        </Form.Item>

        <Form.Item label='Unit Type'>
          {getFieldDecorator('idUnitType', {
            rules: [{ required: false, message: 'Choose Unit Type!' }],
          })(<Select>{getOptUnitTypes()}</Select>)}
        </Form.Item>

        <Form.Item label='Remark'>
          {getFieldDecorator('remark', {
            rules: [{ required: false }],
          })(<Input placeholder='Remark' />)}
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'PositionAntForm' })(BlockUnitForm);
