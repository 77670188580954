import React from 'react'
import { message } from 'antd'
import _ from 'lodash'
export const handleBeforeCopyAndCut = (hotInstance, coords) => {
    let plugin = hotInstance.getPlugin('nestedRows').dataManager
    // if (coords[0].startRow !== coords[0].endRow) {
    //     let startRow = plugin.getDataObject(coords[0].startRow);
    //     let endRow = plugin.getDataObject(coords[0].endRow);
    //     if (startRow.itemIds || endRow.itemIds) {
    //         message.warning(`Can't copy this row, because data from model`)
    //         return false
    //     }
    // }
    // let row = plugin.getDataObject(coords[0].startRow);
    // if (row.itemIds) {
    //     message.warning(`Can't copy this row, because data from model`)
    //     return false
    // }

    //  if (coords[0].startCol !== coords[0].endCol) {
    //     message.warning(`Only can copy one column`)
    //     return false
    // }
    if (coords[0].startRow === coords[0].endRow) {
        return true
    }
    message.warning(`Only can copy one row`)
    return false
}

export const unflatten = (array, rootId = null, dataKey = 'id', parentKey = 'parentId') => {
    const tree = [];
    const childrenMap = {};

    const length = array.length;
    for (let i = 0; i < length; i++) {
        const item = { ...array[i] };
        const id = item[dataKey];
        const parentId = item[parentKey];

        if (Array.isArray(item.__children)) {
            childrenMap[id] = item.__children.concat(childrenMap[id] || []);
        } else if (!childrenMap[id]) {
            childrenMap[id] = [];
        }
        item.__children = childrenMap[id];

        if (parentId !== undefined && parentId !== rootId) {
            if (!childrenMap[parentId]) childrenMap[parentId] = [];
            childrenMap[parentId].push(item);
            // childrenMap[parentId].sort(sortIndex)
            // _.forEach(childrenMap[parentId], (v, k) => {
            //     v.index = k
            // })
        } else {
            tree.push(item);
        }
    }

    return tree;
}

export const sortIndex = (a, b) => {

    if (a.index < b.index) {
        return -1;
    }
    if (a.index > b.index) {
        return 1;
    }
    return 0;
}
export function getLastNode( array) {
    var temp = [];
    var queue = array;
    while (queue.length > 0) {
       var node = queue.shift();
       if (node.objects && node.objects.length!== 0) {
          node.objects.forEach(v=>{
            queue.push(v);
          })
       }
       else {
          temp.push(node.objectid);
       }
    }
    return temp;
 }