import React, { useState, useEffect,useRef } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { InputPicker ,AutoComplete } from 'rsuite'
// import { AutoComplete } from 'antd';
let tempData =''
function CellAutoComplete  ( props) {
  const [data, setData] = useState(props.value)
const ref = useRef()
  useEffect(() => {
    // setData(props.value)
    tempData =props.value
   let el = document.getElementById("contil-autocomplete")
   if(el) el.focus();
    return ()=>{
      props.node.setDataValue(props.column.colId, tempData)
      props.api.stopEditing()
    }
  }, [])
useEffect(()=>{
if(ref.current){
  ref.current.focus()
}
},[ref.current])
  const handleChangeValue = (e) => {
    setData(e)
    tempData = e
  }


  return (
    <div
      style={{ width: '200px', height:'inherit', position: 'fixed' , paddingTop:1, paddingLeft:1 }}
    >
      <AutoComplete  id='contil-autocomplete' appearance='default' data={props.manpower[props.column.colId]} block size='xs'  value={data}  onChange={handleChangeValue} />

    </div>
  )
}

CellAutoComplete.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(CellAutoComplete);





