import axios from './axiosClient';

async function search(payload) {
  return await axios.get('/api/worker/cost', { params: payload });
}

async function deletes(payload) {
  return await axios.delete('/api/worker/cost', { data: { data: payload } });
}

async function add(payload) {
  return await axios.post('/api/worker/cost', payload);
}

async function addMulti(payload) {
  return await axios.post('/api/worker/cost/add-multi', { data: payload });
}

async function update(id, payload) {
  return await axios.put(`/api/worker/cost/${id}`, payload);
}

export { search, deletes, add, update, addMulti };
