import { Icon, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import styled from 'styled-components';
import { apiFetchDataMultiByDisciplineForm, apiFetchDataProjectByDiscipline } from '../../../api';
import { colorType } from '../../../constants';
import { getHeaderWidth, getRefStringWithVersion } from '../../../utils';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { getDataForRfaSheet } from '../pageSpreadsheet/PanelSetting';
import ButtonGroupComp from './ButtonGroupComp';


const { Option } = Select;


const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const TableDrawingRfaForMultiForm = ({ refTypeTable, onClickCancelModalPickDrawing, onClickApplyModalPickRfaDrawings, onClickApplyModalPickRfaOrRfamLinkToQrOrQrm, dwgsImportFromRFA, stateProject }) => {


   const { role, company, projectId, email, token, pageSheetTypeName } = stateProject.allDataOneSheet;

   const [rowsTableInput, setRowsTableInput] = useState(null);

   const isForQrOrQrm = pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm';


   useEffect(() => {
      const fetchAllRfaDrawings = async () => {
         try {

            let rowsSorted = [];

            if (refTypeTable === 'rfa' && !isForQrOrQrm) { 

               const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true, isToFetchAllData: true }); // TEST_APPY_TAB_VIEW
               const { dataDmsFetched: { rows }, dataRowHistoryDmsFetched } = res.data;

               const { rowsDataRFA } = getDataForRfaSheet({ rowsAllAndHistory: [...rows, ...dataRowHistoryDmsFetched] });

               rowsSorted = rowsDataRFA
                  .filter(row => row['Drawing Number'] && row['RFA Ref'])
                  .sort((a, b) => a['RFA Ref'] > b['RFA Ref'] ? 1 : -1);

            } else {
               const res = await apiFetchDataMultiByDisciplineForm(refTypeTable, { token, projectId, email, isToFetchAllData: true });
               const { dataMultiFormFetched } = res.data;
               rowsSorted = dataMultiFormFetched.sort((a, b) => getRefStringWithVersion(a, refTypeTable) > getRefStringWithVersion(b, refTypeTable) ? 1 : -1);
            };

            rowsSorted.forEach(row => {
               let arrConsultants = ['Submission'];

               for (const key in row) {
                  if (key.includes(`reply-${refTypeTable}-status-`) && row[key]) {
                     const index = key.indexOf('-status-') + 8;
                     const consultantName = key.slice(index, key.length);
                     arrConsultants.push(consultantName);
                  };
               };

               if (arrConsultants.length > 0) {
                  row['Replied Consultant'] = arrConsultants;
               };
            });

            setRowsTableInput(rowsSorted);


         } catch (err) {
            console.log(err);
         };
      };
      fetchAllRfaDrawings();
   }, []);



   const [selectedRows, setSelectedRows] = useState(dwgsImportFromRFA || []);


   const generateColumnsRFA = () => {
      return [
         {
            key: 'index',
            dataKey: 'index',
            title: '',
            width: 50,
            cellRenderer: (props) => {
               const { rowIndex } = props;
               return (
                  <div>
                     {rowIndex + 1}
                  </div>
               );
            }
         },
         ...getHeaderArrayByType(refTypeTable, isForQrOrQrm).map(column => ({
            key: column,
            dataKey: column,
            title: column,
            resizable: true,
            width: column === 'Drawing Number' ? 220 : column === 'Issue For Construction' ? 180 : getHeaderColumnWidth(column, isForQrOrQrm),
            cellRenderer: column === 'Replied Consultants'
               ? <CellConsultants selectedRows={selectedRows} />
               : column === 'Issue For Construction' ? ({ rowData }) => {
                  const issueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction') || '';
                  return (
                     <div style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: 'black'
                     }}>{issueForConstruction}</div>
                  );
               } : (refTypeTable === 'rfa' && !isForQrOrQrm) ? null
                  : (refTypeTable === 'rfa' && isForQrOrQrm) ? ({ rowData }) => {
                     return (
                        <div style={{
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           color: 'black'
                        }}>{getRefStringWithVersion(rowData, 'rfa')}</div>
                     );
                  }
                     : ({ rowData }) => {
                        const text = column === `${refTypeTable.toUpperCase()} Ref` ? getRefStringWithVersion(rowData, refTypeTable)
                           : column === 'Subject' ? getInfoValueFromRefDataForm(rowData, 'submission', refTypeTable, 'emailTitle')
                              : '';
                        return (
                           <div style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              color: 'black'
                           }}>{text}</div>
                        );
                     }
         }))
      ];
   };
   const rowClassName = ({ rowData }) => {
      if (selectedRows.find(x => x.id === rowData.id)) {
         return 'row-selected-rfa';
      };
   };


   const rowEventHandlers = {
      onClick: (props) => {
         const { rowKey, rowData } = props;

         const rowFoundSelected = selectedRows.find(x => x.id === rowKey);

         if (rowFoundSelected) {
            setSelectedRows(selectedRows.filter(row => row.id !== rowKey));
         } else {
            setSelectedRows([...selectedRows, rowData]);
         };
      },
   };

   return (
      <div style={{
         width: '100%',
         height: window.innerHeight * 0.85 - 20,
         margin: '0 auto',
         padding: 10,
         textAlign: 'center',
      }}>
         {rowsTableInput ? (
            <>
               <div style={{ width: '100%', height: window.innerHeight * 0.8 - 100 }}>
                  <TableStyled
                     fixed
                     columns={generateColumnsRFA()}
                     data={rowsTableInput || []}
                     rowHeight={28}
                     rowEventHandlers={rowEventHandlers}
                     rowClassName={rowClassName}
                  />
               </div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
                  <ButtonGroupComp
                     onClickCancel={onClickCancelModalPickDrawing}
                     onClickApply={() => {
                        if (selectedRows.length === 0) {
                           return message.info('Please select drawings', 2);
                        } else {

                           if (!isForQrOrQrm) {
                              // filter all rows with consultants picked
                              onClickApplyModalPickRfaDrawings(selectedRows.filter(row => {
                                 const consultantsSelected = row.consultantsSelected || [];
                                 return consultantsSelected.length > 0;
                              }));
                           } else {
                              const allRefName = selectedRows.map(row => {
                                 if (row['rfaRef']) {
                                    return getRefStringWithVersion(row, 'rfa');
                                 } else if (row['rfamRef']) {
                                    return getRefStringWithVersion(row, 'rfam');
                                 };
                              });
                              onClickApplyModalPickRfaOrRfamLinkToQrOrQrm([...new Set(allRefName)]);
                           };
                        };
                     }}
                  />
               </div>
            </>
         ) : (
            <div style={{ height: window.innerHeight * 0.7, display: 'flex', justifyContent: 'center', paddingTop: window.innerHeight * 0.3 }}>
               <Icon type='loading' style={{ fontSize: 30 }} />
            </div>
         )}
      </div>
   );
};

export default TableDrawingRfaForMultiForm;



const getHeaderColumnWidth = (hd, isForQrOrQrm) => {
   if (hd === 'Drawing Number') return 350;
   if (hd === 'Subject') return 450;
   if (hd === 'Replied Consultants') return 800;
   if (hd.includes(' Ref')) return isForQrOrQrm ? 1200 : 300;
   else return 300;
};


const getHeaderArrayByType = (refTypeTable, isForQrOrQrm) => {
   if (refTypeTable === 'rfa') return isForQrOrQrm ? [
      'RFA Ref'
   ] : [
      'RFA Ref',
      'Drawing Number',
      'Issue For Construction',
      'Replied Consultants'
   ];
   else return isForQrOrQrm ? [
      `${refTypeTable.toUpperCase()} Ref`
   ] : [
      `${refTypeTable.toUpperCase()} Ref`,
      'Subject',
      'Replied Consultants',
   ]
};


const CellConsultants = ({ rowData, selectedRows }) => {

   const repliedArray = rowData['Replied Consultant'] || [];

   const rowFound = selectedRows.find(r => r.id === rowData.id);

   const [arrayPicked, setArrayPicked] = useState(rowFound ? (rowFound.consultantsSelected || []) : []);


   const onClickCompany = (btn) => {

      const consultantsSelected = rowFound.consultantsSelected || [];

      if (consultantsSelected.indexOf(btn) !== -1) {
         rowFound.consultantsSelected = (rowFound.consultantsSelected || []).filter(x => x !== btn);
         setArrayPicked(arrayPicked.filter(x => x !== btn));
      } else {
         rowFound.consultantsSelected = [
            ...(rowFound.consultantsSelected || []),
            btn
         ];
         setArrayPicked([
            ...arrayPicked,
            btn
         ]);
      };
   };

   return (
      <div style={{ display: 'flex' }}>
         {rowFound && repliedArray.map(btn => (
            <div
               key={btn}
               onClick={(e) => {
                  e.stopPropagation();
                  onClickCompany(btn);
               }}
               style={{
                  color: 'black',
                  border: '1px solid black',
                  cursor: 'pointer',
                  background: arrayPicked.indexOf(btn) !== -1 ? 'yellow' : 'transparent',
                  marginRight: 7,
                  padding: '0 5px'
               }}
            >
               {btn}
            </div>
         ))}
      </div>
   );
};


const TableStyled = styled(Table)`

   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .row-with-rfa-locked {
      background-color: ${colorType.lockedCell}
   };

   
   .BaseTable__row-cell-text {
      color: black
   };

   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };

   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;
