import React from 'react';
import { Image, Segment } from 'semantic-ui-react'
import { message, Icon, Upload, Row, Col } from 'antd';
import { Modal, Loader, Button, InputPicker } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import {
  TextField
} from '@material-ui/core';


class DialogSettingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingImg: false,
      smartSheetId: '',
      manningScheduleId: '',
      folderSmartSheetId: '',
      fileNameCurrent: '',
      imgItem: null,
      folderIdToMove: '',
      isShowMoveControl: false,
      typeFile: 'none',
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.onShow) {
      axios.post(`/api/folders`, {
        projectId: this.props.projectId, role: this.props.role,
        permission: this.props.permission, email: this.props.email, endPoint: localStorage.getItem('endPoint')
      })
        .then(res => {
          let temp = []
          _.forEach(res.data, v => {
            if (v.permission && v.folder.folderId !== this.props.folderId) {
              temp.push({ label: v.folderName, value: v.folder.folderId })
            }
          })
          this.setState({ listFolder: temp })
        })
        .catch(res => {
          this.setState({ listFolder: [] })
        })
      this.setState({
        smartSheetId: nextProps.smartSheetId,
        manningScheduleId: nextProps.manningScheduleId,
        folderSmartSheetId: nextProps.folderSmartSheetId,
        fileNameCurrent: nextProps.itemDisplayName,
        imgItem: nextProps.imgItem,
        loadingImg: nextProps.loadingImg,
        typeFile:nextProps.typeFile
      })
    }
  }
  handleClosePanel = () => {
    this.props.onClose()
    this.setState({ isShowMoveControl: false })
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size < 2 * 1024 * 1024;
    if (!isLt2M) {
      message.error('Image must smaller than 200KB!');
    }
    return isJpgOrPng && isLt2M;
  }
  handleSaveSetting = () => {
    this.setState({ loading: true })
    axios.post(`/api/items/savesettings`, {
      itemId: this.props.itemId,
      smartSheetIdCurrent: this.state.smartSheetId, manningScheduleIdCurrent: this.state.manningScheduleId,
      folderSmartSheetIdCurrent: this.state.folderSmartSheetId, fileNameCurrent: this.state.fileNameCurrent , typeFile: this.state.typeFile
    })
      .then(res => {
        this.setState({ loading: false })
        message.success(`Save settings successful`)
        window.location.reload()
        this.props.onClose()
      })
      .catch(err => {
        this.setState({ loading: false })
        message.error(`Save settings failed`)
        this.props.onClose()
      })
  }

  changeSmartSheetId = (e) => {
    this.setState({ smartSheetId: e.target.value })
  }
  changeManningScheduleId = (e) => {
    this.setState({ manningScheduleId: e.target.value })
  }
  changeFolderSmartSheetid = (e) => {
    this.setState({ folderSmartSheetId: e.target.value })
  }
  changeFileName = (e) => {
    this.setState({ fileNameCurrent: e.target.value })
  }
  handleMoveFile = () => {
    this.setState({ loading: true })
    axios.post(`/api/items/move-item`, {
      itemId: this.props.itemId, itemName: this.props.itemName,
      folderToMoveId: this.state.folderIdToMove, folderId: this.props.folderId,
    })
      .then(res => {
        this.setState({ loading: false })
        if (res.data === 'Existing a file in this folder') {
          message.warn(res.data)
        } else {
          window.location.reload()
          this.props.onClose()
        }
      })
      .catch(err => {
        this.setState({ loading: false })
        message.error(`Move file failed`)
        this.props.onClose()
      })
  }
  handleFolderForMove = (value) => {
    this.setState({ folderIdToMove: value })
  }
  handleMoveControl = () => {
    this.setState({ isShowMoveControl: !this.state.isShowMoveControl })
  }
  handleChangeTypeFile = (value) => {
    this.setState({ typeFile: value })
  }
  render() {
    const parameterUpload = {
      name: "avatar",
      listType: "picture-card",
      showUploadList: false,
      customRequest: ({ onSuccess, onError, file }) => {
        if (file !== null) {
          this.props.handleUploadImg({ file: file, itemId: this.props.itemId })
        }
      },

    }
    return (
      <div>
        <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} size='sm' style={{ top: '5%' }}>
          <Modal.Header>
            <Modal.Title>Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <Segment color='blue'>
              <Row gutter={8}>
                <Col span={6} >
                  <Upload {...parameterUpload} beforeUpload={this.beforeUpload} >
                    {this.state.imgItem ? <Image src={"data:image/png;base64," + this.state.imgItem} style={{ backgroundSize: "contain" }} />
                      : <div>
                        <Icon type={this.state.loadingImg ? 'loading' : 'plus'} />
                        <div className="ant-upload-text">Upload</div>
                      </div>}
                  </Upload>
                </Col>
                <Col span={18} >
                  <TextField style={{ width: '100%' }} label="File Name" defaultValue={this.props.itemDisplayName} margin="dense" disabled={false}
                    variant="outlined" onChange={this.changeFileName.bind(this)}
                  />
                  <TextField style={{ width: '100%' }} label="SmartSheet Id" defaultValue={this.state.smartSheetId} margin="dense"
                    variant="outlined" onChange={this.changeSmartSheetId.bind(this)}
                  />
                  <TextField style={{ width: '100%' }} label="Manpower Schedule Id" defaultValue={this.state.manningScheduleId} margin="dense"
                    variant="outlined" onChange={this.changeManningScheduleId.bind(this)}
                  />
                  <TextField style={{ width: '100%' }} label="Folder SmartSheet Id" defaultValue={this.state.folderSmartSheetId} margin="dense"
                    variant="outlined" onChange={this.changeFolderSmartSheetid.bind(this)}
                  />
                  <InputPicker block data={[
                    { key: 'ARCHITECTURAL', label: 'ARCHITECTURAL', value: 'architectural' },
                    { key: 'STRUCTURAL', label: 'STRUCTURAL', value: 'structural' },
                    { key: 'MEP', label: 'MEP', value: 'MEP' },
                    { key: 'TEMPORARY WORK', label: 'TEMPORARY WORK', value: 'temporaryWork' },
                    { key: 'CIVIL', label: 'CIVIL', value: 'civil' },
                    { key: 'REBAR', label: 'REBAR', value: 'rebar' },
                    { key: 'PRECAST', label: 'PRECAST', value: 'precast' },
                    { key: 'PPVC', label: 'PPVC', value: 'ppvc' },
                    { key: 'COORDINATION', label: 'COORDINATION', value: 'coordination' },
                    { key: 'DRONE DATA', label: 'DRONE DATA', value: 'droneData' },
                    { key: '4D', label: '4D', value: '4d' },
                    { key: '5D', label: '5D', value: '5d' },
                    { key: 'None', label: 'NONE', value: 'none' },
                  ]} onChange={this.handleChangeTypeFile} value={this.state.typeFile} cleanable={false} />
                </Col>
              </Row>
            </Segment>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClosePanel} color="ghost">
              Cancel
        </Button>
            <Button onClick={this.handleSaveSetting} color="primary">
              Save
        </Button>
          </Modal.Footer>
        </Modal>



      </div>

    );
  }
}


export default DialogSettingItem