import React, { useState, useEffect } from 'react';
import axios from '../../module_Worker/api/axiosClient';
import { Table, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import TreeModel from './TreeModelProgress';
import {
  Loader,
  Input,
  IconButton,
  Checkbox,
  InputPicker,
  Icon,
  CheckPicker,
  ButtonGroup,
} from 'rsuite';

import {
  getElementdbIdsFromModel,
  getForgeToken,
  getForgeTokenInternal,
} from '../../../components/module_BimApp/function/ForgeFunction';

const { Column } = Table;
const Autodesk = window.Autodesk;


function ProgressReport(props) {
  const [viewerCurrent, setViewerCurrent] = useState(null);
  const [tables, setTables] = useState([]);

  let treeModel = React.createRef();
  function handleOpenTreeModel() {}

  useEffect(() => {
    getSheets();
    launchViewer();
  }, []);

  async function getSheets() {
    let res = await axios.get(`/api/dataentry/get-project-tables`, {
      params: {
        projectId: props.location.state.projectId,
      },
    });
    let tbs = res.map((x) => ({
      key: x.id,
      text: x.name,
      value: x.name,
      statusColor: x.columns
        .find((y) => y.id == 'Status')
        ?.valueArray.map((y) => ({
          name: x.name + '_' + y,
          sheet: x.name,
          color: getRandomColor(x.name + '_' + y),
          status:y,
        })),
    }));

    setTables(tbs);
  }

  function launchViewer() {
    getForgeToken().then((token) => {
      var options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
          extensions: [
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            // "Autodesk.AEC.Hypermodeling",
          ],
        };
        let viewer = new Autodesk.Viewing.GuiViewer3D(
          document.getElementById('forgeViewer'),
          config3d
        );
        viewer.start();
        setViewerCurrent(viewer);
      });
    });
  }

  function getRandomColor(key) {
    try {
      let colors = JSON.parse(localStorage.getItem('COLOR-PROGRESS-REPORT'));
      if (_.isArray(colors)) {
        let item = colors.find((x) => x.name == key);
        if (item != null) {
          return item.color;
        }
      }
    } catch (error) {}

    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <div className='progress-report'>
      <div
        id='forgeViewer'
        style={{ position: 'relative', height: '100%', width: '100%' }}
      >
        <IconButton
          style={{ zIndex: '102', float: 'left', top: '0', borderRadius: 0 }}
          size='sm'
          appearance='ghost'
          color='blue'
          icon={<Icon icon='detail' />}
          onClick={handleOpenTreeModel}
        />
        <TreeModel
          ref={treeModel}
          tables={tables}
          open={true}
          viewer={viewerCurrent}
          projectId={props.location.state.projectId}
          projectName={props.location.state.projectName}
          userInfo={props.userInfo}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  };
}
export default connect(mapStateToProps)(ProgressReport);
