import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { add, update } from '../../api/level';
import { addAvatar, getAvatar } from '../../api/employee';

import {
  Button,
  Input,
  Form,
  notification,
  Switch,
  Select,
  Upload,
  Icon,
} from 'antd';
const { Option } = Select;
const _ = require('lodash');

function PositionForm(props) {
  const { record, isAdd, id, parentId, levels } = props;
  const { setVisible, getDatas } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [isMaster, setIsMaster] = useState(false);
  const projectId = useSelector((state) => state.project.projectId);

  function isLevel() {
    return !parentId;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        let payload = {
          ...values,
          type: isLevel() ? 1 : 2,
          projectId,
          parentId,
        };

        if (file) {
          try {
            let res = await addAvatar({
              file: file,
              data: {
                id: 'key-plan-' + id,
                projectId,
                fileName: file.name,
              },
            });
            let fileId = res;
            payload = { ...payload, keyPlan: fileId };
          } catch (error) {}
        }

        if (isAdd) {
          try {
            await add(payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: error?.response?.data?.message,
            });
          }
        } else {
          try {
            await update(id, payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            notification['error']({
              message: 'Error',
              description: error?.response?.data?.message,
            });
          }
        }
      }
    });
  }

  async function getImageLink(file) {
    let src = null;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }

    setImageUrl(src);
  }

  async function handleImport(file) {
    setFile(file);
    getImageLink(file);

    return false;
  }

  const uploadButton = (
    <div>
      <Icon type={'plus'} />
      <div className='ant-upload-text'>Upload</div>
    </div>
  );

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        block: '',
        name: '',
        remark: '',
        isMaster: true,
        keyPlan: '',
        masterId: null,
      });
      setIsMaster(true);
      setImageUrl('');
      setFile(null);
    } else {
      props.form.setFieldsValue({
        block: record.block,
        name: record.name,
        remark: record.remark,
        isMaster: record.isMaster,
        keyPlan: record.keyPlan,
        masterId: record.masterId,
      });

      setIsMaster(record.isMaster);
      getImageFromId(record);
      setFile(null);
    }
  }, [isAdd, record]);

  async function getImageFromId(record) {
    try {
      if (_.isNil(record.keyPlan)) {
        setImageUrl('');
      } else {
        let res = await getAvatar({ key: record.keyPlan, time: 60 });

        setImageUrl(res);
      }
    } catch (error) {}
  }

  const { getFieldDecorator } = props.form;

  function handleOnChange(e) {
    setIsMaster(e);
  }

  function getOptLevels() {
    var optLevels = _.clone(levels);

    if (!isAdd && Array.isArray(optLevels)) {
      optLevels = optLevels.filter((x) => x.value != id);
    }

    return (
      Array.isArray(optLevels) &&
      optLevels.map((x) => (
        <Option key={x.value} value={x.value}>
          {x.label}
        </Option>
      ))
    );
  }

  function handleOnChangeSelectedKeyPlan(e) {
    let selectedLevel = levels.find((x) => x.value == e);
    if (selectedLevel) {
      getImageFromId(selectedLevel);
      setFile(null);
    }
  }

  return (
    <div>
      <Form
        className='ant-form-custom'
        onSubmit={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item label='Block'>
          {getFieldDecorator('block', {
            rules: [{ required: true, message: 'Please input block!' }],
          })(<Input placeholder='block' />)}
        </Form.Item>
        <Form.Item label='Name'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input name!' }],
          })(<Input placeholder='Name' />)}
        </Form.Item>

        <Form.Item label='Remark'>
          {getFieldDecorator('remark', {
            rules: [{ required: false }],
          })(<Input placeholder='Remark' />)}
        </Form.Item>

        {isLevel() && [
          <Form.Item label='Master Story'>
            {getFieldDecorator(
              'isMaster',
              { valuePropName: 'checked' },
              {
                rules: [{ required: true }],
              }
            )(<Switch onChange={handleOnChange} />)}
          </Form.Item>,
          <Form.Item label='Key Plan Similar to'>
            {getFieldDecorator('masterId', {
              rules: [{ required: false, message: 'Choose Master Level!' }],
            })(
              <Select
                disabled={isMaster}
                onChange={handleOnChangeSelectedKeyPlan}
              >
                {getOptLevels()}
              </Select>
            )}
          </Form.Item>,

          <Form.Item label='Key Plan'>
            {/* <ImgCrop rotate> */}
            <Upload
              disabled={!isMaster}
              name='avatar'
              listType='picture-card'
              className='key-plan-uploader float-right  w-100'
              showUploadList={false}
              accept='image/*'
              beforeUpload={handleImport}
            >
              {imageUrl ? (
                <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>,
        ]}

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'PositionAntForm' })(PositionForm);
