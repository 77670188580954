import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { search, add, deletes, update } from '../../api/level';
import {
  Table,
  Divider,
  Icon,
  Modal,
  notification,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import PositionForm from '../Forms/PositionForm';
import TopActionBar from '../Common/TopActionBar';
import AvatarKeyPlan from '../Common/AvatarKeyPlan';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';

const { Column } = Table;

LevelZone.propTypes = {
  onSubmit: PropTypes.func,
};

LevelZone.defaultProps = {
  onSubmit: null,
};

function LevelZone(props) {
  const [rows, setRows] = useState([]);
  const [levels, setLevels] = useState([]);
  const [id, setId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const [dataGroups, setDataGroups] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10000,
  });

  useEffect(() => {
    getDatas();
  }, [searchRequest]);

  const getDatas = async () => {
    let request = { ...searchRequest, projectId };
    let res = await search(request);

    if (_.isNil(res.data?.items)) {
      setDatas([]);
      setDataGroups([]);
    } else {
      setDatas(res.data.items);

      let parents = res.data.items.filter(
        (x) => x.parentId == null && x.type == 1
      );

      parents.forEach((x) => {
        if (!x.isMaster && x.masterId) {
          let master = parents.find((m) => m.id == x.masterId);
          if (master) {
            x.keyPlan = master.keyPlan;
          }
        }
      });

      var levelOpts = parents
        .filter((x) => x.isMaster)
        .map((x) => ({
          value: x.id,
          label: x.name,
          keyPlan: x.keyPlan,
        }));

      setLevels(levelOpts);
      let zones = res.data.items.filter((x) => x.parentId != null);

      let g = parents.map((x) => {
        let children = zones
          .filter((z) => z.parentId == x.id)
          .map((z) => ({
            ...z,
            zone: z.name,
          }));
        return {
          ...x,
          isGroup: true,
          children: children,
        };
      });

      setDataGroups(g);
    }
  };

  async function onChange(current, pageSize) {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values };
          if (isAdd) {
            try {
              await add(payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }
    await getDatas();
  };

  const handleShowDialogEdit = (record) => {
    setId(record.id);
    if (record.type == 1) {
      setParentId(null);
    } else {
      setParentId(record.parentId);
    }
    setRecord(record);
    setIsAdd(false);
    setVisible(true);
  };

  function handleShowDialogAdd(parentId = null) {
    setParentId(parentId);
    setIsAdd(true);
    setVisible(true);
  }

  return (
    <div>
      <Row gutter={16}>
        <Col lg={24} xs={24}>
          <div>
            <div className='d-flex flex-row  mb-2'>
              <TopActionBar
                className='mr-2'
                handleSearch={handleSearch}
                handleShowDialogAdd={() => handleShowDialogAdd(null)}
                // handleDeleteItems={handleDeleteItems}
              />
              ,
            </div>

            <Table
              loading={_.isNil(datas)}
              className='antTable'
              dataSource={dataGroups}
              bordered
              rowKey={(record) => record.id}
              // rowSelection={rowSelection}
              scroll={{ y: 500 }}
              // pagination={false}
            >
              <Column
                title='Block'
                dataIndex='block'
                key='block'
                width='150px'
                render={(text, record) => record.isGroup == true && record.block}
              />
              <Column
                title='Level'
                dataIndex='name'
                key='name'
                width='150px'
                render={(text, record) => record.isGroup == true && record.name}
              />
              <Column title='Zone' dataIndex='zone' key='zone' width='150px' />

              <Column title='Description' dataIndex='remark' key='remark' />

              <Column
                width='600px'
                title='Key Plan'
                dataIndex='keyPlan'
                key='keyPlan'
                render={(text, record) =>
                  record.isGroup == true && (
                    <AvatarKeyPlan imageId={record.keyPlan} />
                  )
                }
              />
              <Column
                width='150px'
                title='Action'
                key='action'
                render={(text, record) => (
                  <span>
                    {record.isGroup == true && (
                      <Icon
                        onClick={() => handleShowDialogAdd(record.id)}
                        style={{ fontSize: '20px', color: '#08c' }}
                        type='plus'
                      />
                    )}

                    <Divider type='vertical' />
                    <Icon
                      onClick={() => handleShowDialogEdit(record)}
                      style={{ fontSize: '20px', color: '#08c' }}
                      type='edit'
                    />

                    <Divider type='vertical' />

                    <Popconfirm
                      title='Are you sure delete this?'
                      onConfirm={() => handleDeleteItems([record.id])}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Icon
                        style={{ fontSize: '20px', color: '#08c' }}
                        type='delete'
                      />
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>

            <Modal
              title='Level Zone'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <PositionForm
                levels={levels}
                id={id}
                parentId={parentId}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LevelZone;
