import React from 'react';
import { Tooltip, Typography } from 'antd';
import { Icon, SelectPicker } from 'rsuite';
import _ from 'lodash'
import { roles } from './Role'
import {checkWohhupUser} from '../function/AdminFunction'
const { Text } = Typography


export default (contractor, cbTrade, cbContractor, cbRole, cbAccess, isAdmin) => {
  return ([
    // { Header: 'Name', accessor: 'userName', id: 'userName', units: false, filter: "includes", alignLocal: 'left', unableGroup: true, },
    { Header: 'Email', accessor: 'email', id: 'email', units: false, filter: "includes", alignLocal: 'left', unableGroup: true, },
    {
      Header: 'Company', accessor: 'company', id: 'company', units: false, filter: "includes", alignLocal: 'left', widthLocal: 200,
      Cell: ({ cell: { row, value } }) => {
        let temp = []
        let nameCompany = ''
        if (!row.isGrouped) {
          if ( checkWohhupUser(row.original.email) ) {
            let index = _.findIndex(contractor, v => { return v.isMainContractor })
            if (index >= 0) {
              nameCompany = contractor[index].company
            }
          } else {
            _.forEach(contractor, v => {
              // if (!v.isMainContractor)
              temp.push(v)
            })
          }
        }
        return (
          !row.isGrouped ? ( checkWohhupUser(row.original.email) ?
            <Tooltip title={row.original.company} mouseEnterDelay={1}>
              <Text>{nameCompany}</Text>
            </Tooltip>
            :
            <SelectPicker
              size='xs'
              appearance="subtle"
              cleanable={false}
              data={temp}
              block
              onChange={cbContractor.bind(this, row, temp)}
              value={row.original.company}
            />) : value
        )
      },
      // Aggregated: ({ row, value }) => {
      //   return (
      //     `${value} Unique Names`
      //   )

      // }
    },
    {
      Header: 'Contract Name', accessor: 'contractor', id: 'contractor', units: false, filter: "includes", alignLocal: 'left', widthLocal: 200,
      Cell: ({ cell: { row, value } }) => {
        let nameContractor = ''
        if (!row.isGrouped) {
          if (checkWohhupUser(row.original.email)) {
            let index = _.findIndex(contractor, v => { return v.isMainContractor })
            if (index >= 0) {
              nameContractor = contractor[index].contractor
            }
          } else {
            let index = _.findIndex(contractor, v => { return v.contractor === row.original.contractor })
            if (index >= 0) {
              nameContractor = contractor[index].contractor
            }
          }
        }
        return (
          !row.isGrouped ? (checkWohhupUser(row.original.email) ?
            <Tooltip title={nameContractor} mouseEnterDelay={1}>
              <Text>{nameContractor}</Text>
            </Tooltip>
            :
            <Tooltip title={nameContractor} mouseEnterDelay={1}>
              <Text>{nameContractor}</Text>
            </Tooltip>) : value
        )
      }
    },


    // {
    //   Header: 'Trade', accessor: 'trade', id: 'trade', units: false, filter: "includes",
    //   Cell: ({ cell: { row, value } }) => {
    //     let temp = []
    //     if (row.original.email.includes('@wohhup.com')) {
    //       let index = _.findIndex(contractor, v => { return v.isMainContractor })
    //       if (index >= 0) {
    //         if (contractor[index].isARC) temp.push({ key: 'ARC', label: 'ARC', value: 'ARC' })
    //         if (contractor[index].isSTR) temp.push({ key: 'STR', label: 'STR', value: 'STR' })
    //         if (contractor[index].isMEP) temp.push({ key: 'MEP', label: 'MEP', value: 'MEP' })
    //         if (contractor[index].isPrecast) temp.push({ key: 'Precast', label: 'Precast', value: 'Precast' })
    //       }
    //     } else {
    //       let index = _.findIndex(contractor, v => { return v.value === row.original.contractor })
    //       if (index >= 0) {
    //         if (contractor[index].isARC) temp.push({ key: 'ARC', label: 'ARC', value: 'ARC' })
    //         if (contractor[index].isSTR) temp.push({ key: 'STR', label: 'STR', value: 'STR' })
    //         if (contractor[index].isMEP) temp.push({ key: 'MEP', label: 'MEP', value: 'MEP' })
    //         if (contractor[index].isPrecast) temp.push({ key: 'Precast', label: 'Precast', value: 'Precast' })
    //       }
    //     }

    //     return (
    //       <CheckPicker 
    //         size='xs'
    //         appearance="subtle"
    //         cleanable={false}
    //         data={temp}
    //         block
    //         onChange={cbTrade.bind(this, row)}
    //         defaultValue={value}
    //       />
    //     )
    //   }
    // },
    {
      Header: 'Role', accessor: 'role', id: 'role', units: false, filter: "includes", alignLocal: 'left', widthLocal: 300,
      Cell: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped ? (row.original.email !== 'admin@wohhup.com') &&
            <SelectPicker
              size='xs'
              appearance="subtle"
              cleanable={false}
              data={roles}
              style={{ width: 300 }}
              onChange={cbRole.bind(this, row)}
              value={row.original.role}
            />
            : value

        )
      }
    },

    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Upload'} mouseEnterDelay={2}>
    //         <Icon icon='upload' style={{ color: 'black' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'upload', id: 'upload', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessUpload')} defaultChecked={value} />
    //     )
    //   }
    // },
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Bim App'} mouseEnterDelay={2}>
    //         <Icon icon='cube' style={{ color: 'red' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'bimApp', id: 'bimApp', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && (
    //         checkWohhupUser(row.original.email) ?
    //           <input type='checkbox' checked={checkWohhupUser(row.original.email)} disabled={checkWohhupUser(row.original.email)}
    //           />
    //           :
    //           <input type='checkbox' defaultChecked={value}
    //             onChange={cbAccess.bind(this, row, 'isAccessBimApp')} />)
    //     )
    //   }
    // },
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Data Entry'} mouseEnterDelay={2}>
    //         <Icon icon='table' style={{ color: 'purple' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'dataEntry', id: 'dataEntry', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessDataEntry')} defaultChecked={value} />
    //     )
    //   }
    // },
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Worker Allocation'} mouseEnterDelay={2}>
    //         <Icon icon='group' style={{ color: 'orange' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'workerAllocation', id: 'workerAllocation', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessWorkerAllocation')} disabled={!isAdmin}
    //       defaultChecked={value} />
    //     )
    //   }
    // },
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Drawing Management System'} mouseEnterDelay={2}>
    //         <Icon icon='dashboard' style={{ color: 'blue' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'dashboardDrawing', id: 'dashboardDrawing', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessDashboardDrawing')} defaultChecked={value} />
    //     )
    //   }
    // },
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Civil-CIP'} mouseEnterDelay={2}>
    //         <Icon icon='charts-line' style={{ color: 'green' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'gantt', id: 'gantt', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       (!row.isGrouped && row.original.email !== 'admin@wohhup.com') && <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessGantt')} defaultChecked={value} />
    //     )
    //   }
    // },
  ])
}