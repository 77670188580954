import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  CardActionArea, CardActions, CardContent, CardMedia,
  IconButton, Tooltip
} from '@material-ui/core';
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { Label } from 'semantic-ui-react'
import { Popconfirm, Icon, Popover, Typography } from 'antd';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@material-ui/icons/SwapHorizontalCircleTwoTone';
import FilterNoneTwoToneIcon from '@material-ui/icons/FilterNoneTwoTone';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import { WH_Icon_Item } from '../../../image'
import { checkWohhupUser } from '../function/AdminFunction'

const useStyles = makeStyles({
  card: {
    maxWidth: 300,
    minWidth: 300,
    minHeight: 250,
    maxHeight: 250,
    margin: 10
  },
  media: {
    height: 150,
  },
  buton: {
    height: 100,
  }
});
const { Text } = Typography;
export default function CardItems(props) {
  const classes = useStyles();

  const deleteItem = () => {
    let data = {
      itemId: props.revision.itemId, itemName: props.revision.itemName, folderId: props.folder.folderId, email: props.email, isTrimble: props.folder.isTrimble
    }
    props.handleDeleteItem(data)
  }
  function translateObject() {
    if (props.folder.isTrimble) return
    let data = {
      itemId: props.revision.itemId, objectName: props.revision.objectIdDefault, status: props.status, itemName: props.revision.itemName,
      itemDisplayName: props.itemDisplayName
    }
    props.handleTranslateObject(data)
  }

  const handleClickOpenVersion = () => {
    if (props.folder.isTrimble) return
    let data = { itemId: props.revision.itemId, objectIdDefault: props.revision.objectIdDefault, itemName: props.revision.itemName }
    props.handleOpenControlVersionPanel(data)
  }
  const handleClickOpenShareView = () => {
    let data = { itemId: props.revision.itemId, objectIdDefault: props.revision.objectIdDefault, itemName: props.revision.itemName, isTrimble: props.folder.isTrimble }
    props.handleOpenControlSharePanel(data)
  }
  const handleClickOpenSetting = () => {
    let data = {
      itemId: props.revision.itemId, objectIdDefault: props.revision.objectIdDefault, itemName: props.revision.itemName,
      smartSheetId: props.smartSheetId, manningScheduleId: props.manningScheduleId, folderSmartSheetId: props.folderSmartSheetId,
      imgItem: props.image, itemDisplayName: props.itemDisplayName, typeFile: props.typeFile
    }
    props.handleOpenControlSettingPanel(data)
  }




  return (
    <div>
      <Card className={classes.card}>
        {props.status === 'success' ?
          <Link to={{
            pathname: props.revision.isTrimble ? `/projects/project/folder/view-trimble` : `/projects/project/folder/view`,
            state: {
              userCreatedProject: props.userCreatedProject,
              id: props.revision.objectIdDefault,
              itemId: props.revision.itemId,
              itemName: props.revision.itemName,
              itemDisplayName: props.itemDisplayName,
              folderId: props.folder.folderId,
              folderName: props.folder.folderName,
              projectId: props.folder.projectId,
              projectName: props.folder.projectName,
              sharePointId: props.folder.sharePointId,
              issueKey: props.folder.issueKey,
              trimbleKey: props.folder.trimbleKey,
              smartSheetId: props.smartSheetId,
              manningScheduleId: props.manningScheduleId,
              folderSmartSheetId: props.folderSmartSheetId,
              version: props.revision.version,
              objectKey: props.revision.objectKey,
              listAdmin: props.folder.listAdmin,
              typeProject: props.folder.typeProject,
              typeFile: props.revision.typeFile,
              isDetail: true,
              isTrimble: props.folder.isTrimble,
              trimbleFileId: props.folder.trimbleFileId, trimbleVersionId: props.folder.trimbleVersionId,
              useRfa: props.folder.useRfa,
            }
          }}>
            <CardActionArea value={props.revision.itemName}>
              <CardMedia
                className={classes.media} image={props.image ? ("data:image/png;base64," + props.image)
                  // :'https://dealhock.com/wp-content/themes/deals/images/store-default.png'
                  : WH_Icon_Item
                }
                title={props.revision.itemName}>
                <Popover placement="bottomRight"
                  content={
                    <div>
                      <Text mark style={{ color: 'black' }}>User Upload:</Text>
                      <Text > {' '}{props.item.userUpload}</Text>
                      <br />
                      <Text mark style={{ color: 'black' }}>Version:</Text>
                      <Text > {' '}{props.revision.version}</Text>
                      <br />
                      <Text mark style={{ color: 'black' }}>Created Date:</Text>
                      <Text > {' '}{props.item.createdDate}</Text>
                      <br />
                      <Text mark style={{ color: 'black' }}>Uploaded Date:</Text>
                      <Text > {' '}{props.item.uploadDate}</Text>
                    </div>
                  }>
                  <Label color={'green'}
                    attached='top right'>V{props.revision.version}</Label>
                </Popover>

              </CardMedia>
              <CardContent style={{ backgroundSize: "contain", overflow: "hidden", wordBreak: "break-all", maxHeight: "54px" }} >
                <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "12px" }} >
                  {props.isDisplayName ? props.itemDisplayName : props.revision.itemName}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link> :
          <CardActionArea value={props.revision.itemName}>
            <CardMedia
              className={classes.media} image={props.image ? ("data:image/png;base64," + props.image)
                // :'https://dealhock.com/wp-content/themes/deals/images/store-default.png'
                : WH_Icon_Item
              }
              title={props.revision.itemName}>
              <Popover placement="bottomRight"
                content={
                  <div>
                    <Text mark style={{ color: 'black' }}>User Upload:</Text>
                    <Text > {' '}{props.item.userUpload}</Text>
                    <br />
                    <Text mark style={{ color: 'black' }}>Version:</Text>
                    <Text > {' '}{props.revision.version}</Text>
                    <br />
                    <Text mark style={{ color: 'black' }}>Created Date:</Text>
                    <Text > {' '}{props.item.createdDate}</Text>
                    <br />
                    <Text mark style={{ color: 'black' }}>Uploaded Date:</Text>
                    <Text > {' '}{props.item.uploadDate}</Text>
                  </div>
                }>
                <Label color={props.status === 'no translate' ? 'orange' : (props.status === 'in progress' ? 'blue' : "red")}
                  attached='top right'>V{props.revision.version}</Label>
              </Popover>

            </CardMedia>
            <CardContent style={{ backgroundSize: "contain", overflow: "hidden", wordBreak: "break-all", maxHeight: "54px" }} >
              <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "12px" }} >
                {props.revision.itemName}
              </Typography>
            </CardContent>
          </CardActionArea>}

        <CardActions style={{ padding: "0px", backgroundColor: '#efefef' }}>

          <div>
            <Popconfirm
              title=" Are you sure to delete item ?" onConfirm={deleteItem} okText="Yes" cancelText="No"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Tooltip title="Delete Item">
                <IconButton disabled={(!props.isadmin && !props.roleUploader)} >
                  <DeleteForeverTwoToneIcon style={{ fontSize: 25 }} /></IconButton>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title=" Are you sure to translate item ?" onConfirm={translateObject} okText="Yes" cancelText="No"
              icon={<Icon type="warning" style={{ color: 'orange' }} />}
            >
              <Tooltip title="Translate Item">
                <IconButton disabled={(!props.isadmin && !props.roleUploader) || props.status === 'success'} style={{ color: props.status === 'no translate' && 'orange' }}>
                  <SwapHorizontalCircleTwoToneIcon style={{ fontSize: 25 }} /></IconButton>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Change Version">
              <IconButton aria-label="Version" onClick={handleClickOpenVersion} disabled={(!props.isadmin && !props.roleUploader)} >
                <FilterNoneTwoToneIcon style={{ fontSize: 20 }} /></IconButton>
            </Tooltip>
            <Tooltip title="Share Item">
              <IconButton aria-label="Share Item" onClick={handleClickOpenShareView}
                // disabled={checkWohhupUser(props.email) ? false : (props.company === 'Woh Hup Private Ltd' || props.role === 'Outsource') ? false : true}
                style={{ color: props.item.isShare && 'blue' }}>
                <ShareTwoToneIcon style={{ fontSize: 20 }} /></IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton aria-label="Settings" onClick={handleClickOpenSetting} disabled={(!props.isadmin && !props.roleUploader)} >
                <SettingsApplicationsTwoToneIcon style={{ fontSize: 25 }} /> </IconButton>
            </Tooltip>
            {/* <Tooltip title="Test">
              <IconButton aria-label="Test" onClick={initOverlay} >
              <WifiOffIcon style={{ fontSize: 20 }} color="primary" /> </IconButton>                          
            </Tooltip> */}
          </div>

        </CardActions>
      </Card>
      <React.Suspense>

      </React.Suspense>

    </div>

  );
}
