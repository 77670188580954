import React from 'react';
import { IconButton, Modal, Icon } from 'rsuite';
import _ from 'lodash'
import { Table, Typography } from 'antd';
import { Button, Checkbox, Dropdown, } from 'semantic-ui-react'
const { Text } = Typography;

const DialogProductivityUnitDetail = ((props) => {
    const {
        show, close, data
    } = props;
    const columns = [
        {
            title: "Activity",
            dataIndex: "activity",
            key: "activity",
            align: "left",
            width: 100,
        },
        {
            title: "Duration (hours)",
            dataIndex: "duration",
            key: "duration",
            align: "left",
            // width: 5,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            align: "left",
            width: 90,
        },
        {
            title: "Worker",
            dataIndex: "worker",
            key: "worker",
            align: "left",
            width: 100,
        },
        {
            title: "Foreman",
            dataIndex: "supervisor",
            key: "supervisor",
            align: "right",
            width: 100,
        }]
    return (
        <Modal show={show} onHide={close} size='xs' dialogClassName="productivity-modal" 
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <Modal.Header>
                <Modal.Title >Productivity detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Text strong>Block: <span style={{ color: 'blue' }}>{data.block ? data.block : ''}</span>, Unit: <span style={{ color: 'blue' }}>{data.unit ? data.unit : ''}</span></Text><br />
                <Text strong>Total: <span style={{ color: 'blue' }}>{data.total ? data.total : 0} hours</span></Text><br />
                <br />
                <Table
                    dataSource={data.listUnitDetail ? data.listUnitDetail : []}
                    columns={columns}
                    // size="small"
                    // scroll={{ y: "37.5vh" }}
                    pagination={false}
                    rowKey="Unit"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} color="blue">
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    );
})

export default DialogProductivityUnitDetail