import React from 'react';
import { IconButton, Modal, Icon } from 'rsuite';
import _ from 'lodash'
import { Typography } from 'antd';
import { Button, Checkbox, Dropdown, } from 'semantic-ui-react'
import { Func_Export_Unit_Handed_Over } from '../../module_QaQc/function/func_export_to_excel';
const { Text } = Typography;

const DialogUnitHandedOverDownload = ((props) => {
    const {
        show, cancel, downloadExcel
    } = props;

    return (
        <Modal show={show} size='xs' onHide={cancel} className='unit-modal'
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <Modal.Header>
                <Modal.Title style={{ color: '#1890ff' }}>Export non-accepted items for</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button color="blue" onClick={e => downloadExcel('current')}>
                    Current month
                </Button>
                <Button color="blue" onClick={e => downloadExcel('all')}>
                    All
                </Button>
                <Button onClick={cancel} color="blue">
                    Cancel
                </Button>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={close} color="blue">
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
    );
})

export default DialogUnitHandedOverDownload