/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Upload, Progress, Drawer, message, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import styles from "../../../../scss/module_DoInv/doinv_upload_list.scss";

const { Dragger } = Upload;
const { confirm } = Modal;

const ImportPdfScanForm = ({ current, setGeneralState }) => {
   const [listFiles, setlistFiles] = useState([]);
   const [percent, setPercent] = useState(0);
   const [alertMsg, setAlertMsg] = useState();
   const [infoProgress, setInfoProgress] = useState();
   const [temp, setTemp] = useState([]);
   const [visible, setVisible] = useState(true);
   // const [visibleConfirm, setVisibleConfirm] = useState(false);
   // const [confirmValue, setConfirmValue] = useState(1);

   const handleApply = async (e) => {
      try {
         if (temp.length > 75) {
            window.alert("No of files exceeded 75. Please reduce it first.");
            return;
         }
         if (temp.length === 0) {
            alert("Ay oh, nothing to save, select file first lah!");
            return;
         }

         const increase = 90 / temp.length;
         var progress = 10;
         setInfoProgress("Record data in table.");
         const projectId = localStorage.getItem("fp_project_id");
         // apply data to RDS
         const listOK = temp.filter((a) => a.checks === "ok");

         const listExist = temp.filter((a) => a.checks !== "ok");
         if (listOK.length > 0) {
            await axios.post("/api/do_inv/s3rds/multi", listOK);
         }

         setPercent(10);
         setInfoProgress("Upload pdf to cloud");
         // upload file to S3
         const files = listFiles.map(async (file) => {
            const pj_fille_name = projectId + "_" + current + "_" + file.name;
            const key = `${projectId}/${current}/${pj_fille_name}`;
            return new Promise((data, err1) => {
               axios
                  .get("/api/do_inv/s3/putlink", {
                     params: { fileName: key.toLowerCase(), formType: current },
                  })
                  .then((res) => {
                     const signedrequesturl = res.data.signedRequest;
                     axios
                        .put(signedrequesturl, file.originFileObj, {
                           headers: {
                              "Content-Type": "application/pdf",
                           },
                        })
                        .then((result) => {
                           progress = progress + increase;
                           setPercent(progress);
                           if (progress === 100) {
                              setInfoProgress("Done");
                              message.success("File has uploaded.");
                           }
                        })
                        .catch((err) => {
                           console.log(err);
                        });
                  });
            });
         });
         let res = await Promise.all();
      } catch (error) {
         console.log("error when upload s3 and rds: ", error);
      }
   };

   let c = 0;

   const checkFileExist = (files) => {
      let temp1 = [];
      const projectId = localStorage.getItem("fp_project_id");

      let listFileName = files.map((file) => (projectId + "_" + current + "_" + file.name).toLowerCase());
      axios
         .post("/api/do_inv/s3rds/check_exist_multi", {
            listFileName: JSON.stringify(listFileName),
         })
         .then((res) => {
            if (typeof res.data.data !== "undefined" && res.data.message !== "All file not exist!") {
               let founded_files = res.data.data.items.map((a) => a.s3_key);
               //map back to list tempdata
               files.forEach((file) => {
                  const fn = (projectId + "_" + current + "_" + file.name).toLowerCase();
                  if (founded_files.includes(String(fn))) {
                     file.status = "error";

                     let entity = {
                        id: uuidv4(),
                        form_type: current,
                        s3_key: fn,
                        project_id: projectId,
                        checks: "exist",
                     };
                     temp1.push(entity);
                  } else {
                     file.status = "ok";
                     let entity = {
                        id: uuidv4(),
                        form_type: current,
                        s3_key: fn,
                        project_id: projectId,
                        checks: "ok",
                     };
                     temp1.push(entity);
                  }
               });
               setAlertMsg(
                  "Got file already exist on server (red color). If not delete, it will be overwritted existing."
               );
            } else {
               files.forEach((file) => {
                  const fn = (projectId + "_" + current + "_" + file.name).toLowerCase();
                  let entity = {
                     id: uuidv4(),
                     form_type: current,
                     s3_key: fn,
                     project_id: projectId,
                     checks: "ok",
                  };
                  temp1.push(entity);
                  setAlertMsg();
               });
            }
            setTemp(temp1);
            setlistFiles(files);
            setInfoProgress(`Finish checking! There are ${files.length} files selected.`);
            if (files.length > 75) {
               setAlertMsg(
                  "To get best peformance, no of files upload for each time limit at 75 files. Please reduce no of file"
               );
            }
            return files;
         });

      c = 0;
   };

   const handleOnClose = () => {
      setVisible(false);
      setGeneralState(0);
      setlistFiles([]);
      setTemp([]);
      setAlertMsg();
   };

   const props = {
      multiple: true,

      onChange(info) {
         setInfoProgress("Processing file");
         let listFiles = [...info.fileList];
         c = c + 1;
         if (c === listFiles.length) {
            let files = checkFileExist(listFiles);
            info.fileList = files;
         }
      },

      onRemove: (file) => {
         const index = listFiles.indexOf(file);
         const newFileList = listFiles.slice();
         newFileList.splice(index, 1);
         checkFileExist(newFileList);
         return setlistFiles(newFileList);
      },
      beforeUpload: (file) => {
         setlistFiles([...listFiles, file]);
         return false;
      },
      listFiles,
   };

   return (
      <div>
         <Drawer
            title="Import pdf files (max 75 files)"
            placement="right"
            width={325}
            closable={false}
            maskClosable={false}
            onClose={() => handleOnClose()}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
         >
            <Dragger {...props} fileList={listFiles} accept=".pdf" style={{ maxHeight: "150px" }}>
               <p className="ant-upload-drag-icon">
                  <InboxOutlined />
               </p>
               <p className="ant-upload-text">Click or drag file here to upload</p>
               <p className="ant-upload-hint">Accepted .pdf format.</p>
            </Dragger>
            <div className="extract-data-footer">
               <p style={{ color: "red" }}>{alertMsg}</p>
               <a style={infoProgress === "Done" ? { color: "green" } : { color: "black" }}>{infoProgress}</a>
               <Progress percent={percent} showInfo={false} />
               <br />
               <br />

               <Button
                  block
                  type="primary"
                  onClick={() => {
                     handleApply();
                  }}
               >
                  Upload Files To System
               </Button>
               <br />

               <br />
               <Button
                  block
                  type="default"
                  onClick={() => {
                     handleOnClose();
                  }}
               >
                  Close
               </Button>
            </div>
         </Drawer>

         {/* <Modal
        visible={visibleConfirm}
        closable={false}
        title="Take note!"
        footer={[
          <Button
            key="0"
            onClick={() => {
              setConfirmValue(0);
              setVisibleConfirm(0);
            }}
          >
            Yes
          </Button>,
          <Button
            key="1"
            type="primary"
            onClick={() => {
              setConfirmValue(1);
              setVisibleConfirm(0);
            }}
          >
            No
          </Button>,
          <Button
            key="2"
            onClick={() => {
              setConfirmValue(2);
              setVisibleConfirm(0);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <p>
          There is file with same name <b>has already exist </b> in the system.{" "}
        </p>
        <p>
          <b>Do you want to overwrite it? </b>
        </p>
      </Modal> */}
      </div>
   );
};

export default ImportPdfScanForm;
