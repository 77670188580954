import { Icon, Input, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { checkIfThereIsRowInsideTreeNode, getHeaderWidth, getRefStringWithVersion } from '../../../utils';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { convertFlattenArraytoTree1 } from '../pageSpreadsheet/FormDrawingTypeOrder';
import ButtonGroupComp from './ButtonGroupComp';



const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const TableDrawingDmsToIssueForConstruction = ({
   onClickCancelModalPickDrawingToIssue,
   onClickApplyModalPickDrawingToIssue,
   dataInputDmsTable,
   stateProject,
   activityType,
   dataDmsDrawingLink, listDrawingToMigrate
}) => {


   const { drawingTypeTree: drawingTypeTreeInput, rowsAllOutputAllVersion, linkedFormTypeName } = dataInputDmsTable;

   const { publicSettings: { headers } } = stateProject.allDataOneSheet;

   const drawingTypeTree = (drawingTypeTreeInput || []).map(x => ({ ...x }));

   const renderColumnLinkDwg = (headersInput, linkedFormTypeName) => {

      let headers = [];

      if (linkedFormTypeName === 'rfa') {
         const listHidden = [
            'Block/Zone', 'Level', 'Unit/CJ', 'Drg Type', 'Use For', 'Coordinator In Charge', 'Modeller'
         ];
         headers = headersInput.map(x => x['text']).filter(x => {
            return listHidden.indexOf(x) === -1 && !(x.includes('(T)')) && !(x.includes('(A)')) && !(x.includes('Progress'));
         });
         headers.splice(3, 0, ...['Issue For Construction', 'IFC Rev', 'IFC Date']);

      } else {
         headers = [
            `${linkedFormTypeName.toUpperCase()} Ref`,
            'Issue For Construction',
            'IFC Rev',
            'Subject'
         ];
      };


      return [
         {
            key: 'index',
            dataKey: 'index',
            title: '',
            width: 50,
            cellRenderer: ({ rowIndex }) => <div>{rowIndex + 1}</div>
         },
         ...headers.map(column => ({
            key: column,
            dataKey: column,
            title: column,
            resizable: true,
            width: column === 'Drawing Name' ? 230
               : column === 'Issue For Construction' ? 200
                  : column === 'IFC Rev' ? 60
                     : column === 'IFC Date' ? 85
                        : column === `${linkedFormTypeName.toUpperCase()} Ref` ? 250
                           : column === 'Subject' ? 1000
                              : getHeaderWidth(column),
            cellRenderer: (props) => {
               const { rowData } = props;
               return (
                  <div style={{ color: 'black', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                     {(rowData['treeLevel'] && column === 'Drawing Number') ? rowData['title'] : (
                        column === 'Issue For Construction' ? getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction')
                           : column === 'IFC Rev' ? getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'ifcVersion')
                              : (column === 'IFC Date' && getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'date')) ? moment(getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'date')).format('DD/MM/YY')
                                 : column === 'Subject' ? getInfoValueFromRefDataForm(rowData, 'submission', linkedFormTypeName, 'emailTitle')
                                    : (linkedFormTypeName !== 'rfa' && column === `${linkedFormTypeName.toUpperCase()} Ref`) ? getRefStringWithVersion(rowData, linkedFormTypeName)
                                       : rowData[column]
                     )}
                  </div>
               );
            }
         }))
      ];
   };

   useEffect(() => {
      setExpandedRows(drawingTypeTree.map(node => node['id']));
   }, [dataInputDmsTable]);


   const [expandedRows, setExpandedRows] = useState([]);

   const [selectedRows, setSelectedRows] = useState(activityType === 'migrate-old-dt-dms' ? listDrawingToMigrate : []);


   const rowClassName = ({ rowData }) => {
      if (rowData['treeLevel']) {
         return 'row-drawing-type';
      };
      if (selectedRows.find(x => x.id === rowData.id)) {
         return 'row-selected-rfa';
      };
   };

   const onRowExpand = (props) => {
      const { rowKey, expanded } = props;
      let arr = [...expandedRows];
      if (expanded) {
         arr.push(rowKey);
      } else {
         arr = arr.filter(id => id !== rowKey);
      };
      setExpandedRows(arr);
   };
   const rowEventHandlers = {
      onClick: (props) => {
         const { rowData } = props;
         if (
            (!rowData['treeLevel'] && rowData['Drawing Number'] && linkedFormTypeName === 'rfa') ||
            (linkedFormTypeName && linkedFormTypeName !== 'rfa' && !rowData['treeLevel'])
         ) {
            if (activityType === 'link-new-dt-dms') {
               setSelectedRows([rowData]);
            } else if (activityType === 'migrate-old-dt-dms') {
               if (selectedRows.find(r => r['id'] === rowData['id'])) {
                  setSelectedRows(selectedRows.filter(r => r['id'] !== rowData['id']));
               } else {
                  setSelectedRows([...selectedRows, rowData]);
               };
            };
         };
      },
   };
   const ExpandIcon = (props, i) => {
      const { expanding, expandable, onExpand, depth } = props;
      const indent = (depth * 17).toString() + 'px';
      return (
         <div
            style={{
               marginLeft: indent, paddingLeft: expandable ? 10 : 13 + 10,
               paddingRight: 3, background: 'transparent',
            }}
         >
            {expandable && (
               <Icon
                  type={expanding ? 'plus-square' : 'minus-square'}
                  style={{ color: 'black', transform: 'translate(0, -4px)' }}
                  onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     onExpand(expanding);
                  }}
               />
            )}
         </div>
      );
   };
   const expandIconProps = (props) => {
      const { expanded } = props;
      return ({ expanding: !expanded });
   };

   const [searchValue, setSearchValue] = useState('');


   return (
      <div>
         {(rowsAllOutputAllVersion && rowsAllOutputAllVersion.length > 0) ? (
            <>

               {linkedFormTypeName === 'rfa' && (
                  <div style={{ display: 'flex', paddingLeft: 25, paddingTop: 15 }}>
                     <div style={{ color: 'black' }}>Quick Search</div>
                     <InputStyled
                        placeholder='Input Search...'
                        style={{ width: 300, marginLeft: 25 }}
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                     />
                  </div>
               )}

               <div style={{
                  width: '100%',
                  height: window.innerHeight * 0.85 - 20,
                  margin: '0 auto',
                  padding: 25,
                  textAlign: 'center',
               }}>
                  <div style={{ width: '100%', height: window.innerHeight * 0.8 - 100 }}>
                     <TableStyled
                        fixed
                        columns={renderColumnLinkDwg(headers, linkedFormTypeName)}
                        data={getDataTableInput({ rowsAllOutputAllVersion, drawingTypeTree, searchValue, linkedFormTypeName })}
                        rowHeight={28}
                        rowEventHandlers={rowEventHandlers}
                        rowClassName={rowClassName}

                        expandedRowKeys={expandedRows}

                        expandColumnKey={'Drawing Number'}

                        expandIconProps={expandIconProps}
                        components={{ ExpandIcon }}
                        overscanRowCount={0}
                        onRowExpand={onRowExpand}
                     />
                  </div>
                  <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
                     <ButtonGroupComp
                        onClickCancel={onClickCancelModalPickDrawingToIssue}
                        onClickApply={() => {

                           if (activityType === 'link-new-dt-dms') {
                              onClickApplyModalPickDrawingToIssue(selectedRows[0]);
                           } else if (activityType === 'migrate-old-dt-dms') {
                              onClickApplyModalPickDrawingToIssue(selectedRows);
                           };
                        }}
                     />
                  </div>
               </div>
            </>
         ) : (
            <div style={{ height: window.innerHeight * 0.7, display: 'flex', justifyContent: 'center', paddingTop: window.innerHeight * 0.3 }}>
               <Icon type='loading' style={{ fontSize: 30 }} />
            </div>
         )}
      </div>
   );
};

export default TableDrawingDmsToIssueForConstruction;



const getDataTableInput = ({ rowsAllOutputAllVersion: data, drawingTypeTree, searchValue: valueSearch, linkedFormTypeName }) => {

   if (linkedFormTypeName === 'rfa') {
      let rowsAllOutput = [...data];

      if (valueSearch) {
         rowsAllOutput = rowsAllOutput.filter(row => {
            let isSearch = false;
            for (const key in row) {
               if (
                  (key === 'Drawing Number' || key === 'Drawing Name' || key === 'RFA Ref') && row[key]
               ) {
                  if (row[key].toLowerCase().includes(valueSearch.toLowerCase())) {
                     isSearch = true;
                  };
               };
            };
            return isSearch;
         });
      };

      let dataOutput = [];
      drawingTypeTree.forEach(node => {
         let newItem = { ...node };
         const checkResult = checkIfThereIsRowInsideTreeNode(newItem, drawingTypeTree, rowsAllOutput, 'page-spreadsheet');
         if (checkResult) {
            const rowChildren = rowsAllOutput.filter(r => r['_parentRow'] === node['id']);
            if (rowChildren.length > 0) {
               newItem['children'] = rowChildren;
            };
            dataOutput.push(newItem);
         };
      });
      return convertFlattenArraytoTree1(dataOutput);
   } else {
      return data.sort((b, a) => (getRefStringWithVersion(b, linkedFormTypeName) > getRefStringWithVersion(a, linkedFormTypeName) ? 1 : -1));
   };
};



const TableStyled = styled(Table)`

   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .row-drawing-type {
      background-color: ${colorType.grey4};
      color: black;
      font-weight: bold;
   };

   .BaseTable__row-cell-text {
      color: black
   };

   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };

   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;

const InputStyled = styled(Input)`
   transform: translateY(-5px);
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   }
`;
