import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { Table, Pagination } from 'antd';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
const { Column } = Table;

function CostCodeTableDetail(props) {
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    getDatas();
  }, [props.datas]);

  useEffect(() => {
    if (props.exportExcel > 0) {
      exportSummaryByCostDetail();
    }
  }, [props.exportExcel]);

  const getDatas = async () => {
    if (_.isNil(props.datas)) {
      await setDatas([]);
    } else {
      let dts = props.datas.map((x) => {
        let dt = {
          ...x,
          amount: roundNumber(x.totalAmount),
          totalManMonth: roundNumber(x.totalManMonth),
          totalHours: roundNumber(x.totalHours),
        };

        return dt;
      });

      let dic = _.groupBy(dts, 'trade');
      let keys = Object.keys(dic);
      keys.sort();
      let group = keys.map((key) => ({
        key: key,
        trade: dic[key][0].trade,
        totalHours: roundNumber(_.sumBy(dic[key], 'totalHours')),
        amount: roundNumber(_.sumBy(dic[key], 'amount')),
        totalManMonth: roundNumber(_.sumBy(dic[key], 'totalManMonth')),
        isGroup: true,
        children: dic[key],
      }));

      await setDatas(group);
    }
  };

  function renderCash(cash) {
    return (
      <div>
        <b>$</b>
        <p className='float-right'>{Math.round(cash * 10) / 10}</p>
      </div>
    );
  }

  function renderRound2Number(h) {
    return <p>{Math.round(h * 10) / 10}</p>;
  }

  function getCostCodeFilter() {
    if (_.isArray(datas)) {
      let items = datas.map((x) => ({
        text: x.costCode ? x.costCode : '',
        value: x.costCode ? x.costCode : '',
      }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  async function exportSummaryByCostDetail() {
    const workbook = new ExcelJS.Workbook();
    let excelName = 'Summary by cost code trade detail';
    const worksheet = workbook.addWorksheet(
      'Summary by cost code trade detail'
    );

    worksheet.columns = columns.map((x) => ({
      header: x.title,
      key: x.dataIndex,
    }));

    worksheet.properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false,
    };

    let rowCount = 0;
    let dataExcel = datas;

    dataExcel.forEach((r) => {
      const newRows = worksheet.addRow(r);
      rowCount++;
      if (r.children && _.isArray(r.children)) {
        r.children.forEach((c) => {
          let sRow = worksheet.addRow(c);
          sRow.outlineLevel = 1;
          rowCount++;
        });
      }
    });

    let borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    for (let i = 1; i <= rowCount + 1; i++) {
      for (let j = 1; j < 15; j++) {
        worksheet.getCell(i, j).border = borderStyles;
      }
    }

    worksheet.columns.forEach(function (column, i) {
      if (i !== 0) {
        var maxLength = 0;
        column['eachCell']({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      }
    });

    worksheet.columns[0].width = 20;
    worksheet.columns[1].width = 20;

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + '.xlsx');
  }

  function roundNumber(cash) {
    try {
      return Math.round(cash * 10) / 10;
    } catch (error) {
      return '';
    }
  }

  let columns = [
    {
      title: 'Trade',
      dataIndex: 'trade',
      key: 'trade',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Cost Code',
      dataIndex: 'costCode',
      key: 'costCode',
      filters: getCostCodeFilter(),
      onFilter: (value, record) => record.costCode.indexOf(value) === 0,
      fixed: 'left',
      width: 110,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 110,
    },
    {
      title: 'Total Hours',
      dataIndex: 'totalHours',
      key: 'totalHours',
      render: renderRound2Number,
      width: 100,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: renderCash,
      width: 100,
    },

    {
      title: 'Man-Mth',
      dataIndex: 'totalManMonth',
      key: 'totalManMonth',
      render: renderRound2Number,
      width: 100,
    },
  ];

  return (
    <div>
      <Table
        loading={_.isNil(datas)}
        className='antTable'
        dataSource={datas}
        rowKey={(record) => record.idCostCode}
        columns={columns}
        scroll={{ x: 1700, y: 500 }}
        bordered
        size='small'
      ></Table>
    </div>
  );
}

export default CostCodeTableDetail;
