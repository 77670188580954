import React from "react";
import Excel from "exceljs";
import * as fs from "file-saver";
import dayjs from "dayjs";
import _ from "lodash";

function numToSheetColumn(num) {
   var s = "",
      t;

   while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = ((num - t) / 26) | 0;
   }
   return s || undefined;
}

const formatHeader = (ws, arrayCol, row, rowHeight, iStart, iEnd, fontName, fontSize, horAlightment, fillColor) => {
   for (let i = iStart; i < iEnd + 1; i++) {
      const col_name = numToSheetColumn(i);
      ws.getColumn(col_name).width = arrayCol[i - iStart].width;
      ws.getRow(row).height = rowHeight;
      const cell_name = col_name + row;
      var cell = ws.getCell(cell_name);
      cell.value = arrayCol[i - iStart].value;
      cell.font = {
         name: fontName,
         size: fontSize,
      };
      cell.alignment = {
         vertical: "middle",
         horizontal: horAlightment,
         wrapText: true,
      };
      cell.border = {
         top: { style: "thin" },
         left: { style: "thin" },
         bottom: { style: "thin" },
         right: { style: "thin" },
      };
      cell.fill = {
         type: "pattern",
         pattern: "solid",
         fgColor: { argb: fillColor },
         bgColor: { argb: fillColor },
      };
   }
};

const formatRow = (ws, arrayCell, row, rowHeight, iStart, iEnd, fontName, fontSize, bold, fillColor, hairBorder) => {
   for (let i = iStart; i < iEnd + 1; i++) {
      const col_name = numToSheetColumn(i);
      ws.getRow(row).height = rowHeight;
      const cell_name = col_name + row;
      const j = i - iStart;
      var cell = ws.getCell(cell_name);
      cell.value = arrayCell[j].value;
      cell.font = {
         name: fontName,
         size: fontSize,
         bold: bold,
      };
      cell.alignment = {
         vertical: "middle",
         horizontal: arrayCell[j].hor_alight,
         wrapText: true,
      };
      if (hairBorder) {
         cell.border = {
            top: { style: "hair" },
            left: { style: "thin" },
            bottom: { style: "hair" },
            right: { style: "thin" },
         };
      } else {
         cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
         };
      }

      if (arrayCell[j].numFmt !== undefined) {
         cell.numFmt = arrayCell[j].numFmt;
      }
      if (fillColor !== "na") {
         cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: fillColor },
            bgColor: { argb: fillColor },
         };
      }
   }
};

export const Func_Export_Conc_DO_To_Excel = async (data, filter_data) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("Conc DO");

   worksheet.columns = [
      { header: "DO Number", key: "do_number", width: 15 },
      { header: "do_date", key: "do_date", width: 15 },
      { header: "Volume", key: "volume", width: 10 },
      { header: "Grade", key: "grade", width: 15 },
      { header: "Product", key: "product", width: 25 },
      { header: "Supplier", key: "supplier", width: 12 },
      { header: "Use for", key: "use_for", width: 18 },
      { header: "Pour Name", key: "pour_name", width: 18 },
      { header: "Order by", key: "order_by", width: 18 },
      { header: "Remark", key: "remark", width: 35 },
   ];

   if (filter_data != undefined) {
      if (filter_data.length > 0) {
         filter_data.forEach((d) => {
            let row = worksheet.addRow(d);
         });
      } else {
         data.forEach((d) => {
            let row = worksheet.addRow(d);
         });
      }
   }

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Con_DO.xlsx");
   });
};

export const Func_Export_Conc_Inv_To_Excel = async (data, supplier, duration) => {
   const workbook = new Excel.Workbook();
   const ws = workbook.addWorksheet("Conc Invoice");

   //get some conmon info
   const project_id = localStorage.getItem("fp_project_id");
   const user_name = "USER 1";

   //project and invoice ifo
   ws.getCell("A1").value = "PROJECT :";
   ws.getCell("B1").value = "SP3"; //project_id.toUpperCase();
   ws.getCell("A2").value = "SUBJECT :";
   ws.getCell("B2").value = "SUPPLY OF READYMIXED CONCRETE.";
   ws.getCell("A3").value = "SUPPLIER :";
   ws.getCell("B3").value = supplier;
   ws.getCell("A4").value = "DURATION :";
   ws.getCell("B4").value = duration;
   ws.getCell("A5").value = "PAYMENT :";
   //format
   ["A1", "B1", "A2", "B2", "A3", "B3", "A4", "B4", "A5", "B5"].map((c) => {
      let c1 = ws.getCell(c);
      c1.font = {
         name: "Century",
         size: 11,
      };
      c1.alignment = { vertical: "middle", horizontal: "left" };
   });

   ws.getCell("J1").value = "Exported by:";
   ws.getCell("K1").value = user_name;
   ws.getCell("J2").value = "Date:";
   ws.getCell("K2").value = dayjs().format("DD/MM/YYYY");
   //format
   ["J1", "K1", "J2", "K2"].map((c) => {
      let c1 = ws.getCell(c);
      c1.font = {
         name: "Century",
         size: 9,
      };
      c1.alignment = { vertical: "middle", horizontal: "left" };
   });

   const header = [
      { width: 14, value: "DATE" },
      { width: 12.5, value: "INVOICE NO." },
      { width: 13, value: "DO. NO" },
      { width: 32, value: "PRODUCT" },
      { width: 13, value: "VOLUME (m3)" },
      { width: 13, value: "UNIT PRICE ($)" },
      { width: 15, value: "AMOUNT CLAIMED ($)" },
      {
         width: 18,
         value: "GST ON AMOUNT CLAIMED ($)",
      },
      { width: 15, value: "PAY AMOUNT ($)" },
      {
         width: 18,
         value: "GST ON AMOUNT CERTIFIED ($)",
      },
      { width: 22, value: "REMARK" },
   ];

   formatHeader(ws, header, 7, 45, 1, 11, "Century", 10, "center", "96C8FB");

   //fill in invoice data
   //carry normal row first so that the border can easy format
   for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const i_row = i + 8;
      const row = ws.getRow(i_row);

      if (!d.do_number.includes("nos")) {
         const row_data = [
            { value: d.date, hor_alight: "center" },
            {},
            { value: d.do_number, hor_alight: "left" },
            { value: d.product, hor_alight: "left" },
            { value: d.volume, hor_alight: "right", numFmt: "#,##0.00" },
            { value: d.unit_price, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
            {},
         ];
         formatRow(ws, row_data, i_row, 18, 1, 11, "Arial", 9, false, "na", true);
      }
   }

   for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const i_row = i + 8;
      const row = ws.getRow(i_row);

      if (d.do_number.includes("nos")) {
         const row_data = [
            { value: d.date, hor_alight: "center" },
            { value: d.inv_number, hor_alight: "left" },
            {},
            { value: d.product, hor_alight: "left" },
            { value: d.volume, hor_alight: "right", numFmt: "#,##0.00" },
            {},
            { value: d.amount_claim, hor_alight: "right", numFmt: "$0.00" },
            { value: d.amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim, hor_alight: "right", numFmt: "$ #,##0.00" },
            { value: d.amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
            {},
         ];
         formatRow(ws, row_data, i_row, 18, 1, 11, "Arial", 9, true, "ccffcc", false);
      }
   }

   //format total row
   const dataSum = data.filter((e) => e.do_number.includes("nos"));
   const total_Volume = dataSum.reduce((acc, curr) => acc + curr.volume, 0);
   const total_amount_claim = dataSum.reduce((acc, curr) => acc + curr.amount_claim, 0);

   const row_data = [
      { value: "TOTAL", hor_alight: "left" },
      {},
      {},
      {},
      { value: total_Volume, hor_alight: "right", numFmt: "#,##0.00" },
      {},
      { value: total_amount_claim, hor_alight: "right", numFmt: "$ #,##0.00" },
      { value: total_amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
      { value: total_amount_claim, hor_alight: "right", numFmt: "$ #,##0.00" },
      { value: total_amount_claim * 0.07, hor_alight: "right", numFmt: "$ #,##0.00" },
      {},
   ];
   const lastrow = 8 + data.length;
   formatRow(ws, row_data, lastrow, 25, 1, 11, "Arial", 10, true, "b8cce4", false);

   //EXPORT SUMMARY BY PRODUCT

   const headerP = [
      { width: 32, value: "PRODUCT" },
      { width: 14, value: "TOTAL VOL (m3)." },
      { width: 22, value: "REMARK" },
   ];
   // formatHeader(ws, headerP, 38, 45, 1, 3, "Century", 10, "center", "96C8FB");
   formatHeader(ws, headerP, 7, 45, 13, 15, "Century", 10, "center", "96C8FB");

   const data_only_Do = data.filter((e) => !e.do_number.includes("nos"));
   let listProduct = _(data_only_Do)
      .groupBy("product")
      .map((objs, key) => ({
         product: key,
         volume: _.sumBy(objs, "volume"),
      }))
      .value();

   for (let i = 0; i < listProduct.length; i++) {
      const d = listProduct[i];
      const i_row = i + 8;
      const row = ws.getRow(i_row);

      const row_data = [
         { value: d.product, hor_alight: "left" },
         { value: d.volume, hor_alight: "right", numFmt: "#,##0.00" },
         {},
      ];
      formatRow(ws, row_data, i_row, 18, 13, 15, "Arial", 9, false, "na", true);
   }

   //format total row

   const row_data_p = [
      { value: "TOTAL", hor_alight: "left" },
      { value: total_Volume, hor_alight: "right", numFmt: "#,##0.00" },
      {},
   ];
   const lastrow_p = 8 + listProduct.length;
   formatRow(ws, row_data_p, lastrow_p, 25, 13, 15, "Arial", 10, true, "b8cce4", false);

   //export file
   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, `Conc_Invoice_${dayjs().format("HHMMss_DD_MM_YYYY")}.xlsx`);
   });
};

export const Func_Export_Rebar_DO_To_Excel = async (data) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("Rebar DO");

   worksheet.columns = [
      { header: "DO Number", key: "do_number", width: 15 },
      { header: "do_date", key: "do_date", width: 15 },
      { header: "Volume", key: "volume", width: 15 },
      { header: "PO No", key: "po_number", width: 15 },
      { header: "Pour Name", key: "pour_name", width: 15 },
      { header: "Product", key: "product", width: 30 },
      { header: "Weight_qty", key: "weight_qty", width: 15 },
      { header: "Unit", key: "unit", width: 10 },
      { header: "Supplier", key: "supplier", width: 20 },

      { header: "Remark", key: "remark", width: 35 },
   ];

   data.forEach((d) => {
      worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Rebar_DO.xlsx");
   });
};

export const Func_Export_Rebar_Inv_To_Excel = async (data) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("Rebar DO");

   worksheet.columns = [
      { header: "Inv Number", key: "inv_number", width: 15 },
      { header: "inv_date", key: "inv_date", width: 15 },
      { header: "DO Number", key: "do_number", width: 15 },
      { header: "do_date", key: "do_date", width: 15 },
      { header: "Product", key: "product", width: 30 },
      { header: "Weight_qty", key: "weight_qty", width: 15 },
      { header: "Unit", key: "unit", width: 10 },
      { header: "Supplier", key: "supplier", width: 20 },
      { header: "Unit Price", key: "unit_price", width: 15 },
      { header: "Amount Claim", key: "amount_claim", width: 20 },
      { header: "Pour Name", key: "pour_name", width: 15 },
      { header: "Remark", key: "remark", width: 35 },
   ];

   data.forEach((d) => {
      worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Rebar_Inv.xlsx");
   });
};
