import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import _ from 'lodash'

import { Row, Col, Modal, Checkbox, Tabs, message, Tooltip, Button } from 'antd';
import { Icon, IconButton, Input, Loader, InputPicker , Divider} from 'rsuite';


const { TabPane } = Tabs;

function DialogSetupInformationProject(props) {


  const [listContractor, setListContractor] = useState([])
  const [newValueContractor, setNewValueContractor] = useState('')
  const [newValueCompany, setNewValueCompany] = useState('')
  const [newFullNameValue, setNewFullNameValue] = useState('')
  const [newShortNameValue, setNewShortNameValue] = useState('')
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)
    axios.post(`/api/projects/get-information-project`, { projectId: props.projectId, token: props.userInfo.token })
      .then(res => {
        setListContractor(res.data.listContractor)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const handleClose = () => {
    props.close()
  }





  const handleRemoveContractor = (key) => {
    if (listContractor[key]) {
      listContractor.splice(key, 1)
      setListContractor(listContractor.slice(0))
    }
  }
  const handleChangeNewContractorValue = (value) => {
    setNewValueContractor(value)
  }
  const handleChangeNewCompanyValue = (value) => {
    setNewValueCompany(value)
  }
  const handleChangeFullNameValue = (value) => {
    setNewFullNameValue(value)
  }
  const handleChangeShortNameValue = (value) => {
    setNewShortNameValue(value)
  }
  const handleAddNewContractorValue = () => {
    if (newValueCompany === '') return
    let index = _.findIndex(listContractor, v => { return v.company === newValueCompany })
    if (index < 0) {
      listContractor.push({
        contractor: newValueContractor, color: "", isMainContractor: false, fullName: newFullNameValue, shortName: newShortNameValue,
        isARC: false, isSTR: false, isMEP: false, isPrecast: false, company: newValueCompany, companyType: ''
      })
      setListContractor(listContractor.slice(0))
    }
  }

  const handleChangeColor = (item, e) => {
    item.color = e.target.value
    setListContractor(listContractor.slice(0))
  }
  const handleRemoveColor = (item) => {
    item.color = ""
    setListContractor(listContractor.slice(0))
  }
  const handleChangeMainContractor = (item, name, e) => {
    if (name == '') {
      _.forEach(listContractor, v => {
        v.isMainContractor = false
        if (v.company === 'Woh Hup Private Ltd') {
          v.company = ''
          v.companyType = ''
          v.fullName = ''
        }
      })
      item.isLeadConsultant = false
      item.isMainContractor = true
      item.company = 'Woh Hup Private Ltd'
      item.fullName = 'Woh Hup Private Ltd'
      item.shortName = 'Woh Hup'
      item.companyType = 'Main con'
      item.isMEP = true
      item.isARC = true
      item.isPrecast = true
      item.isSTR = true
    } else if (name == 'ARC') {
      item.isARC = e.target.checked
    } else if (name == 'STR') {
      item.isSTR = e.target.checked
    } else if (name == 'MEP') {
      item.isMEP = e.target.checked
    } else if (name == 'PRECAST') {
      item.isPrecast = e.target.checked
    }
    setListContractor(listContractor.slice(0))
  }


  const handleSaveData = () => {

    let index = _.findIndex(listContractor, v => { return v.company === '' || v.companyType === '' || v.value === '' || !v.company || !v.companyType })
    if (index >= 0) {
      message.warning('Please, key in all fields')
      return
    }
    setLoading(true)
    axios.post(`/api/projects/set-user-role`, { projectId: props.projectId, listContractor: listContractor, token: props.userInfo.token })
      .then(() => {
        props.close()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const handleChangeContractor = (item, value) => {
    let temp = listContractor.slice(0)
    item.company = value
    setListContractor(temp)
  }
  const handleChangeCompanyType = (item, value) => {
    let temp = listContractor.slice(0)
    if (value === 'Main con') {
      if (item.isMainContractor)
        item.companyType = value
    } else {
      if (value !== 'Consultant')
        item.isLeadConsultant = false
      item.companyType = value
    }
    setListContractor(temp)
  }

  const handleChangeFullName = (item, value) => {

    item.fullName = value
    setListContractor(listContractor.slice(0))
  }
  const handleChangeShortName = (item, value) => {
    item.shortName = value
    setListContractor(listContractor.slice(0))
  }
  
  return (
    <>
      {/* <Modal
        title="Setting"
        visible={true}
        onOk={handleSaveData}
        onCancel={handleClose}
        style={{ height: 'calc(100% - 200px)' }}
        width='95%'
      >
        {loading && <Loader center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
     
      </Modal> */}

      {loading && <Loader center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000000 }} />}
      <Row gutter={[8, 8]}>
        <Col span={22} >
          <Col span={6}>
            <Input size='sm' block placeholder='New Company Name'
              onChange={handleChangeNewCompanyValue} />
          </Col>
          <Col span={4}>
            <Input size='sm' block placeholder='New Full Name'
              onChange={handleChangeFullNameValue} />
          </Col>
          <Col span={4}>
            <Input size='sm' block placeholder='New Short Name'
              onChange={handleChangeShortNameValue} />
          </Col>
          <Col span={4}>
            <Input size='sm' block placeholder='New Contract Name'
              onChange={handleChangeNewContractorValue} />
          </Col>
        </Col>
        <Col span={1} >
          <IconButton size="sm" onClick={handleAddNewContractorValue}
            color={'green'} icon={<Icon icon='plus' />} />
        </Col>
        <Col span={1} >
          <IconButton size="sm" onClick={handleSaveData}
            color={'blue'} icon={<Icon icon='save' />} />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={4} >
          Company
        </Col>
        <Col span={4} >
          Name
        </Col>
        <Col span={3} >
          Short Name
        </Col>
        <Col span={3} >
          Contract Name
        </Col>
        <Col span={3} >
          Company Type
        </Col>
        <Col span={1} >
          ARC
        </Col>
        <Col span={1} >
          STR
        </Col>
        <Col span={1} >
          MEP
        </Col>
        <Col span={2} >
          PRECAST
        </Col>
        <Col span={2} >
          Options
        </Col>
      </Row>
      <Divider style={{marginBottom:5, marginTop:5 }}/>
      {listContractor.map((i, k) =>
        <Row gutter={[8, 8]}>
          <Col span={4} >
            <Input size='sm' value={i.company} block onChange={handleChangeContractor.bind(this, i)}
              disabled={true}
            />
          </Col>
          <Col span={4} >
            <Input size='sm' value={i.fullName} block disabled={i.isMainContractor}
              onChange={handleChangeFullName.bind(this, i)}
            />
          </Col>
          <Col span={3} >
            <Input size='sm' value={i.shortName} block disabled={i.isMainContractor}
              onChange={handleChangeShortName.bind(this, i)}
            />
          </Col>
          <Col span={3} >
            <Input size='sm' value={i.contractor} block
              disabled={true}
            />
          </Col>
          <Col span={3} >
            <InputPicker size='sm' value={i.companyType} block onChange={handleChangeCompanyType.bind(this, i)} cleanable={false}
              data={[
                { key: 'Main Con', label: 'Main Con', value: 'Main con' },
                { key: 'Sub Con', label: 'Sub Con', value: 'Sub-con' },
                { key: 'Consultant', label: 'Consultant', value: 'Consultant' },
                { key: 'Client', label: 'Client', value: 'Client' }
              ]}
            />
          </Col>
          <Col span={1} >
            <Checkbox checked={i.isARC} onChange={handleChangeMainContractor.bind(this, i, 'ARC')} />
          </Col>
          <Col span={1} >
            <Checkbox checked={i.isSTR} onChange={handleChangeMainContractor.bind(this, i, 'STR')} />
          </Col>
          <Col span={1} >
            <Checkbox checked={i.isMEP} onChange={handleChangeMainContractor.bind(this, i, 'MEP')} />
          </Col>
          <Col span={2} >
            <Checkbox checked={i.isPrecast} onChange={handleChangeMainContractor.bind(this, i, 'PRECAST')} />
          </Col>
          <Col span={2} >
            <Tooltip title='Is is Wohhup?'>
              <Checkbox checked={i.isMainContractor} onChange={handleChangeMainContractor.bind(this, i, '')} />
            </Tooltip>
            {/* <Tooltip title='Is is Lead Consultant?'>
              <Checkbox checked={i.isLeadConsultant} onChange={handleChangeLeadConsultant.bind(this, i, '')} disabled={i.companyType !== 'Consultant'} />
              </Tooltip> */}

            <input type='color' style={{ top: 5, position: 'relative', height: 20 }} value={i.color}
              onChange={handleChangeColor.bind(this, i)} />
            <IconButton size="sm" onClick={handleRemoveColor.bind(this, i)} icon={<Icon icon='paint-brush' />} />
            <IconButton size="sm" onClick={handleRemoveContractor.bind(this, k)} color={'red'} icon={<Icon icon='trash-o' />} />
          </Col>
        </Row>
      )}

    </>
  )
}

DialogSetupInformationProject.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(DialogSetupInformationProject)