import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { valueFormatterForDouble } from '../common';

function ToAcountTable(props) {
  const {
    allHolidays,
    cutOffDate,
    dataTimeSheet,
    currentMonthTimeSheet,
    tableHeight,
  } = props;
  const [rowDatas, setRowDatas] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (_.isArray(dataTimeSheet)) {
      GetDataTable();
    }
  }, [dataTimeSheet]);

  function GetDataTable() {
    let dataForTable = [];

    let dayDatas = [];

    dataTimeSheet.forEach((worker) => {
      if (_.isArray(worker.timeWorkingInDays)) {
        worker.timeWorkingInDays.forEach((dayData) => {
          dayDatas.push(dayData);
        });
      }
    });

    let groupByTrade = _.groupBy(dayDatas, 'occupation');

    let trades = Object.keys(groupByTrade);

    let columns = [
      {
        headerName: 'Trade',
        field: 'trade',
        rowGroup: true,
        pinned: 'left',
        hide: true,
      },
      {
        headerName: 'Code',
        field: 'code',
        filter: true,
      },
      {
        headerName: 'Worker Name',
        field: 'name',
        filter: true,
      },
    ];

    let startMoment = moment(currentMonthTimeSheet, 'MM-YYYY').subtract(
      1,
      'months'
    );

    startMoment = startMoment.set('date', cutOffDate + 1);
    let endMoment = moment(currentMonthTimeSheet, 'MM-YYYY');
    endMoment.set('date', cutOffDate);

    let tempStartMoment = moment(startMoment);

    let columnDays = [];
    while (!tempStartMoment.isAfter(endMoment)) {
      let daystring = tempStartMoment.format('DD-MM-YY');
      columnDays.push(daystring);

      tempStartMoment = tempStartMoment.add(1, 'days');
    }

    columns.push({
      headerName: 'POINTS',
      field: 'totalPoints',
      aggFunc: 'sum',
      valueFormatter: valueFormatterForDouble,
    });

    trades.forEach((trade, index) => {
      let groupByWorkerId = _.groupBy(groupByTrade[trade], 'idWorker');
      Object.keys(groupByWorkerId).forEach((workerId) => {
        let worker = groupByWorkerId[workerId][0];
        let rowData = {
          no: index,
          code: worker.employeeCode,
          name: worker.name,
          trade,
        };

        let totalPoints = 0.0;

        let totalDays = 0;

        columnDays.forEach((columnDay) => {
          let sumPoint = _.sumBy(
            groupByWorkerId[workerId].filter(
              (item) => item.dayYearString == columnDay
            ),
            'point'
          );

          if (sumPoint > 0) {
            totalDays++;
          }

          rowData[columnDay] = !_.isNaN(sumPoint) ? sumPoint : 0;
          totalPoints += !_.isNaN(sumPoint) ? sumPoint : 0;
        });

        rowData.totalPoints = totalPoints;
        rowData.totalDays = totalDays;
        dataForTable.push(rowData);
      });
    });

    dataForTable.forEach((x) => {});
    setRowDatas(dataForTable);
    setColumnDefs(columns);
  }

  const autoGroupColumnDef = {
    cellRendererParams: {
      footerValueGetter: (params) => {
        console.log(' params.node.level ', params.node.level);
        const isRootLevel = params.node.level === -1;
        if (isRootLevel) {
          return 'Grand Total';
        }
        return `Sub Total ( ${params.value})`;
      },
    },
  };

  return (
    <div
      className='ag-theme-alpine'
      style={{ height: tableHeight, width: 800, boxSizing: 'border-box' }}
    >
      <AgGridReact
        className='ag-theme-balham'
        reactUi='true'
        rowData={rowDatas}
        columnDefs={columnDefs}
        pagination={true}
        // autoGroupColumnDef={autoGroupColumnDef}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      ></AgGridReact>
    </div>
  );
}

export default ToAcountTable;
