import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {
  Card
} from "reactstrap";
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import { Popconfirm, Icon } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPublicUrl } from '../function/AdminFunction'
import { WHLogoText } from '../../../image'

const useStyles = makeStyles({
  card: {
    maxWidth: 300,
    minWidth: 300,
    minHeight: 250,
    maxHeight: 250,
    margin: 10
  },
  media: {
    height: 140,
  },
  buton: {
    height: 100,
  }
});



function CardProjects(props) {
  const classes = useStyles();
  const [imgProject, setImgProject] = useState('')

  useEffect(() => {
    if (!props.loadingImg) {
      if (props.image !== '') {
        getPublicUrl(props.image)
          .then(res => {
            setImgProject(res)
          })
          .catch(err => {
            setImgProject('')
          })
      }
    }
  }, [props.loadingImg])


  function deleteProject() {
    let data = {
      projectId: props.project.projectId
    }
    props.handleDeleteProject(data)
  }


  const handleClickOpenSetting = () => {
    let data = {
      smartSheetId: props.smartSheetId, imgProject: props.image, projectId: props.project.projectId,
      sharePointId: props.sharePointId, userCreatedProject: props.userCreatedProject, issueKey: props.issueKey, trimbleKey: props.trimbleKey, useRfa: props.useRfa,
      typeProject: props.typeProject, projectDisplayName: props.projectDisplayName, projectName: props.project.projectName
    }

    props.handleOpenControlSettingPanel(data)
  }



  return (

    <div>
      <Card className={classes.card}>
        <Link to={{
          pathname: `/projectdetail`,
          state: {
            userCreatedProject: props.userCreatedProject,
            projectId: props.project.projectId,
            projectName: props.project.projectName,
            sharePointId: props.sharePointId,
            issueKey: props.issueKey,
            typeProject: props.typeProject,
            trimbleKey: props.trimbleKey,
            useRfa: props.useRfa,
            isDetail: true
          }
        }}>
          <CardActionArea value={props.project.projectName}>
            <CardMedia
              className={classes.media}
              image={imgProject !== '' ? imgProject
                : WHLogoText}

              title={props.projectDisplayName ? props.projectDisplayName : props.project.projectName}
              style={{ backgroundSize: "contain" }} />
            <CardContent style={{ backgroundSize: "contain", overflow: "hidden", wordBreak: "break-all", maxHeight: "54px" }}>
              <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "15px" }} >
                {props.projectDisplayName ? props.projectDisplayName : props.project.projectName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>

        <CardActions style={{ padding: "0px", backgroundColor: "#efefef" }} >
          <div>
            <Popconfirm
              title=" Are you sure to delete project ?" onConfirm={deleteProject} okText="Yes" cancelText="No"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Tooltip title="Delete">
                <IconButton aria-label="Delete Project" disabled={!props.isadmin}>
                  <DeleteForeverTwoToneIcon style={{ fontSize: 30 }} /></IconButton>
              </Tooltip>
            </Popconfirm>

            <Tooltip title="Settings">
              <IconButton aria-label="Settings" onClick={handleClickOpenSetting} disabled={!props.isadmin}>
                <SettingsApplicationsTwoToneIcon style={{ fontSize: 30 }} /> </IconButton>
            </Tooltip>

          </div>
        </CardActions>
      </Card>
      <React.Suspense>

      </React.Suspense>

    </div>

  );
}

CardProjects.propTypes = {
  changeProject: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    userInfo: state.user
  }
}

export default connect(mapStateToProps, null)(CardProjects);









