import axios from './axiosClient';

async function search(payload) {
  return await axios.get('/api/worker/unit-type', { params: payload });
}

async function update(payload) {
  return await axios.put(`/api/worker/unit-type`, payload);
}

export { search, update };
