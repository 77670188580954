import axios from "./axiosClient";

async function search(payload) {
    return await axios.get("/api/worker/block", { params: payload });
}

async function deletes(payload) {
    return await axios.delete("/api/worker/block", { data: { data: payload } });
}

async function add(payload) {
    return await axios.post("/api/worker/block", payload);
}

async function update(id, payload) {
    return await axios.put(`/api/worker/block/${id}`, payload);
}

export {
    search,
    add,
    update,
    deletes
}