const rebarInvTempColumns = [
  {
    type: "text",
    data: "inv_number",
    title: "Inv No",
  },

  {
    type: "date",
    data: "inv_date",
    title: "Inv Date",
    dateFormat: "DD-MM-YYYY",
    correctFormat: true,
    datePickerConfig: {
      dateFormat: "DD-MM-YYYY",
    },
  },

  {
    type: "text",
    data: "do_number",
    title: "DO No",
  },

  {
    type: "date",
    data: "do_date",
    title: "Do Date",
    dateFormat: "DD-MM-YYYY",
    correctFormat: true,
    datePickerConfig: {
      dateFormat: "DD-MM-YYYY",
    },
  },
  {
    type: "numeric",
    data: "volume",
    title: "Volume",
  },

  {
    type: "text",
    data: "pour_name",
    title: "Pour Name",
  },

  {
    type: "text",
    data: "product",
    title: "Product",
  },

  {
    // type: "text",
    title: "Supplier",
    data: "supplier",
    editor: "select",
    selectOptions: ["ALLIANCE", "PAN U", "ISLAND"],
  },

  {
    type: "numeric",
    data: "unit_price",
    title: "Unit price",
    numericFormat: {
      pattern: "$ 0,0.0",
    },
  },

  {
    type: "numeric",
    data: "amount_claim",
    title: "Amount",
    numericFormat: {
      pattern: "$ 0,0.0",
    },
  },
  {
    type: "numeric",
    data: "amount_gst",
    title: "Amt + 7% GST",
    numericFormat: {
      pattern: "$ 0,0.0",
    },
  },
  {
    type: "text",
    data: "checks",
    title: "Checks",
    readOnly: true,
  },
  {
    type: "text",
    data: "remark",
    title: "Remark",
  },
];

export default rebarInvTempColumns;
