import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Row, Col, Input, Modal, Upload, Button, message, Form, Tooltip, Divider } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { uploadFileByPutLink } from '../../../functions/GeneralFunction';
import { v4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';


const { Option } = Select;

const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';

export function ModalUploadFileUpD(props) {
  const [data, setData] = useState({
    consultantName: null,
    group: null,
    folderName: null
  })
  const [gridApi, setGridApi] = useState()
  const [consultants, setConsultants] = useState([])
  const [files, setFiles] = useState([])
  const [isCanEdit, setIsCanEdit] = useState(true)

  const [groups, setGroups] = useState([])
  const [folderNames, setFolderNames] = useState([])
  useEffect(() => {
    if (props.companies) {
      let temp = []
      _.forEach(props.companies, v => {
        if (v.companyType === "Consultant" && v.shortName) {
          temp.push(v)
        }
        if (v.company === props.company && v.shortName) {
          let clone = { ...data }
          clone.consultantName = v.shortName
          // setIsCanEdit(false)
        }
      })
      temp.sort((optionA, optionB) => (optionA.shortName ?? '').toLowerCase().localeCompare((optionB.shortName ?? '').toLowerCase()))
      setConsultants(temp)
    }

  }, [])


  const handleOk = async (e) => {
    if (!data.consultantName || !data.group || !data.folderName) {
      message.warning('Need to fill all')
      return
    }
    uploadFile()
  }
  const handleUploadFile = (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        if (files.length >= 10) {
          message.warning('Maximum is 10 files')
        }
        let clone = [...files]
        let index = _.findIndex(clone, v => v.fileName === info.file.name)
        if (index >= 0) {
          clone[index].file = info.file
        } else {
          clone.push({ fileName: info.file.name, remark: '', file: info.file })
        }
        // clone.fileName = info.file.name
        // console.log(info.file)
        // setData(clone)
        setFiles(clone)
      }
    }
  }
  const uploadFile = async () => {
    let temp = []
    gridApi.api.forEachLeafNode(v => {
      temp.push(v.data)
    })
    if (temp.length === 0) return
    for (let i in temp) {
      try {
        props.setIsLoading(true)
        let fileId = v4()
        const versionId = v4()
        let res = await axios({
          url: '/api/consultant-upload-file/get-by-name-group-folder',
          method: 'get',
          params: {
            consultantName: data.consultantName,
            group: data.group,
            folderName: data.folderName,
            fileName: files[i].fileName,
            token: props.token,
            projectId: props.storeInitProject.projectId,
          }
        })
        if (res.data) {
          fileId = res.data.fileId
        }
        await uploadFileByPutLink(`${props.pathDir}/${fileId}/${versionId}/${files[i].fileName}`, files[i].file.type, typeBucketName, files[i].file)
        await axios({
          url: '/api/consultant-upload-file',
          method: 'post',
          data: {
            consultantName: data.consultantName,
            group: data.group,
            folderName: data.folderName,
            remark: files[i].remark,
            fileName: files[i].fileName,
            token: props.token,
            projectId: props.storeInitProject.projectId,
            fileId, versionId
          }
        })
      } catch (ex) {
        message.warning(`Cannot upload file ${files[i].fileName}`)
      }
      message.info(`Upload file ${_.toNumber(i) + 1}/${files.length}`)
    }
    window.location.reload()
    setTimeout(() => {
      props.setIsLoading(false)
    }, 1500);
  }


  const handleCancel = () => {
    props.setClose()
  }
  const handleChangeInput = (type, e) => {
    let clone = { ...data }
    clone[type] = e.target.value
    setData(clone)
  }
  const handleSelect = (type, e) => {
    let clone = { ...data }
    clone[type] = e
    setData(clone)
  }
  const handleSelectConsultant = (e) => {
    let tempGroups = []
    let tempFolderNames = []
    props.files.forEach(v => {
      if (v.consultantName === e) {
        if (!tempGroups.includes(v.group) && v.group) {
          tempGroups.push(v.group)
        }
        if (!tempFolderNames.includes(v.folderName) && v.folderName) {
          tempFolderNames.push(v.folderName)
        }
      }
    })
    let clone = { ...data }
    clone.consultantName = e
    tempGroups.sort((optionA, optionB) => (optionA ?? '').toLowerCase().localeCompare((optionB ?? '').toLowerCase()))
    tempFolderNames.sort((optionA, optionB) => (optionA ?? '').toLowerCase().localeCompare((optionB ?? '').toLowerCase()))
    setData(clone)
    setGroups(tempGroups)
    setFolderNames(tempFolderNames)
  }
  const beforeUpload = (file) => {
    if (file.size > 200 * 1000 * 1000) {
      message.warning('Cannot upload file larger 200MB')
      return false
    }
    return true
  }

  const handleDeleteFile = (e) => {
    if (!window.confirm('Are you want to delete?')) return
    let clone = [...files]
    let index = _.findIndex(clone, v => { return v.fileName == e.fileName })
    clone.splice(index, 1)
    setFiles(clone)
  }
  const addGroupItem = (e) => {
    e.preventDefault();
    let name = window.prompt('Please input', '')
    if (name) {
      let index = groups.findIndex(v => { return v === name })
      if (index >= 0) {
        message.warning('This name is existing')
        return
      }
      setGroups([...groups, name]);
    }

  };

  const addFolderItem = (e) => {
    e.preventDefault();
    let name = window.prompt('Please input', '')
    if (name) {
      let index = folderNames.findIndex(v => { return v === name })
      if (index >= 0) {
        message.warning('This name is existing')
        return
      }
      setFolderNames([...folderNames, name]);
    }

  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: false,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);
  const columnDefs = [
    { field: 'fileName', headerName: 'Name', width: 100, minWidth: 200, },
    {
      field: 'remark', headerName: 'Remark', editable: true, wrapText: true,
      autoHeight: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellStyle: params => {
        return { whiteSpace: 'break-spaces', lineHeight: '25px' };
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      lockPosition: 'right',
      suppressMovable: true, minWidth: 100, maxWidth: 100,
      cellRendererSelector: params => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          }
        }
        return undefined;
      }
    },
  ]
  const handleAction = (params) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
        <Button size='small' type='danger' onClick={handleDeleteFile.bind(this, params.data)}
        >Delete</Button>
      </div>
    )
  }
  const onGridReady = useCallback((params) => {
    setGridApi(params)
  }, []);
  return (
    <Modal title="Upload File" visible={true} onOk={handleOk} onCancel={handleCancel} width={'80%'}>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }} >
        <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}   >
          <span style={{ fontWeight: 'bold', width: 150 }}>Consultant Name</span>
          <Select showSearch placeholder='Consultant Name' value={data.consultantName} style={{ width: '100%' }} disabled={!isCanEdit}
            onChange={handleSelectConsultant.bind(this)}
            filterOption={(input, option) =>
              (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
            }
          >
            {consultants.map(v =>
              <Select.Option key={v.shortName} value={v.shortName} >{v.shortName}</Select.Option>
            )}

          </Select>

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}   >
          <span style={{ fontWeight: 'bold', width: 150 }}>Group Name</span>
          {/* <Input placeholder='Group Name' onChange={handleChangeInput.bind(this, 'group')} value={data.group}
          /> */}
          <Select showSearch placeholder='Group Name' value={data.group} style={{ width: '100%' }} 
            onChange={handleSelect.bind(this, 'group')}
            filterOption={(input, option) =>
              (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0', }} />
                <div style={{ display: 'flex', flexDirection: 'row', gap: 5, padding: 5 }} onMouseDown={e => e.preventDefault()} >
                  <Button type="text" onClick={addGroupItem} disabled={!data.consultantName} block>
                    Add item
                  </Button>
                </div>
              </>
            )}
          >
            {groups.map(v =>
              <Select.Option key={v} value={v} >{v}</Select.Option>
            )}

          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}   >
          <span style={{ fontWeight: 'bold', width: 150 }}>Folder Name</span>
          {/* <Input placeholder='Folder Name' onChange={handleChangeInput.bind(this, 'folderName')} value={data.folderName}
          /> */}
          <Select showSearch placeholder='Folder Name' value={data.folderName} style={{ width: '100%' }} 
            onChange={handleSelect.bind(this, 'folderName')}
            filterOption={(input, option) =>
              (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0', }} />
                <div style={{ display: 'flex', flexDirection: 'row', gap: 5, padding: 5 }} onMouseDown={e => {
                  e.preventDefault()
                }}>
                  <Button type="text" onClick={addFolderItem} disabled={!data.consultantName} block >
                    Add item
                  </Button>
                </div>
              </>
            )}
          >
            {folderNames.map(v =>
              <Select.Option key={v} value={v} >{v}</Select.Option>
            )}

          </Select>
        </div>
      </div>
      <p />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 25, alignItems: 'center' }}  >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }} >
          <div style={{ display: 'flex', gap: 5 }} >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }} >
              {/* <span style={{ fontWeight: 'bold' }} >Select file to upload</span> */}
              <Upload customRequest={handleUploadFile} showUploadList={false} beforeUpload={beforeUpload} style={{ width: '100%' }} multiple >
                <Button type='primary' style={{ width: '100%' }}
                >Select file to upload</Button>
              </Upload>
            </div>
          </div>
        </div>
      </div>
      <p />


      <div className='dms ag-theme-alpine' style={{
        height: '500px',
        width: '100%',
      }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={files}
          columnDefs={columnDefs}
          // rowHeight={35}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          groupDefaultExpanded={3}
          suppressContextMenu={true}
        ></AgGridReact>


      </div>



    </Modal>
  );
}


