import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Input, InputNumber, DateRangePicker, Icon, IconButton, Divider, DatePicker } from 'rsuite'
import { message, Typography, Collapse, notification } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { constants } from '../../moudle_Manpower/constants'
import { timelineData } from '../function/generateTimeline';
import { v4 } from 'uuid'
import axios from 'axios';
const { Panel } = Collapse;
const { Text } = Typography
function DialogEditMultiValues(props) {
  const [data, setData] = useState([
    // { startDate: new Date(), endDate: new Date().setDate(new Date().getDate() + 1), projectName: '', power: 0 }
  ])
  const [userData, setUserData] = useState({
    id: '',
    client: '',
    lead: '',
    name: '',
    status: '',
    scope: '',
    jobTitle: '',
    department: ''
  })
  const [listDeleted, setListDeleted] = useState([])
  const [listAdded, setListAdded] = useState([])
  const [listUpdated, setListUpdated] = useState([])
  const [key, setKey] = useState([])
  useEffect(() => {
    if (props.openEditMultiValuesDialog.node.data.timelines) {
      let timelines = JSON.parse(props.openEditMultiValuesDialog.node.data.timelines)
      if (timelines.length !== 0) {
        let temp = []
        let tempKey=[]
        _.forEach(timelines, v => {
          temp.push({
            id: v.id, startDate: moment(new Date(v.startDate), 'DD/MM/YYYY').toDate(), endDate: moment(new Date(v.endDate), 'DD/MM/YYYY').toDate(),
            data: v.data
          })
          tempKey.push(v.id)
        })
        setData(temp)
        setKey(tempKey)
      }
      userData.id = props.openEditMultiValuesDialog.node.data.id
      userData.userId = props.openEditMultiValuesDialog.node.data.userId
      userData.name = props.openEditMultiValuesDialog.node.data.name
      setUserData(Object.assign({}, userData))
    }

  }, [])
  const handleOk = () => {
    let added = []
    let updated = []
    let deleted = []
    _.map(data, i => {
      delete i.isNew
      if (listAdded.includes(i.id)) {
        added.push(i)
      }
      if (listDeleted.includes(i.id)) {
        deleted.push(i)
      } else if (listUpdated.includes(i.id)) {
        updated.push(i)
      }
    })
    props.setManpowerData({ loading: true })
    axios.post('/api/manpower/update-timeline', { userId: userData.userId, userId: userData.id, added, deleted, updated, token: props.userInfo.token })
      .then(res => {
        let currentData = props.openEditMultiValuesDialog.node.data
        let newData = {
          userId: userData.userId, id: userData.id, client: currentData.client, lead: currentData.lead, status: currentData.status, name: userData.name, scope: currentData.scope, updatedBy: currentData.updatedBy,
          updatedAt: currentData.updatedAt, jobTitle: currentData.jobTitle, department: currentData.department, timelines: JSON.stringify(res.data)
        }
        timelineData(newData, res.data, props.typeTimeline)
        props.openEditMultiValuesDialog.node.setData(newData)
        props.setManpowerData({ loading: false })
        notification.success({
          message: 'Updated',
          placement: 'bottomRight'
        })
        handleClose()
      })
      .catch(err => {
        props.setManpowerData({ loading: false })
        notification.error({
          message: 'Cannot update',
          placement: 'bottomRight'
        })
      })
  }
  const handleAddDataTimeline = (item, e) => {
    if (!key.includes(item.id)) {
      key.push(item.id)
      setKey(key.slice(0))
    }
    let id =v4()
    item.data.push({ id, projectName: '', power: '' })
    if (!listUpdated.includes(item.id) && !item.isNew) {
      listUpdated.push(item.id)
      setListUpdated(listUpdated.slice(0))
    }
    setData(data.slice(0))
    e.stopPropagation()
  }
  const handleRemoveDataTimeline = (item, child, e) => {
    if (!window.confirm('Are you sure to remove?')) {
      e.stopPropagation()
      return
    }
    let index = _.findIndex(item.data, i => { return i.id === child.id })
    if (index >= 0) {
      if (!listUpdated.includes(item.id) && !item.isNew) {
        listUpdated.push(item.id)
        setListUpdated(listUpdated.slice(0))
      }
      item.data.splice(index, 1)
      setData(data.slice(0))
    }
    e.stopPropagation()
  }
  const handleChangeProject = (item, child, e) => {
    child.projectName = e
    if (!listUpdated.includes(item.id) && !item.isNew) {
      listUpdated.push(item.id)
      setListUpdated(listUpdated.slice(0))
    }
    setData(data.slice(0))
  }
  const handleChangePower = (item, child, e) => {
    child.power = e
    if (!listUpdated.includes(item.id) && !item.isNew) {
      listUpdated.push(item.id)
      setListUpdated(listUpdated.slice(0))
    }
    setData(data.slice(0))
  }
  const handleChangeDate = (item, type, e) => {
    if (!e) return
    if (type === 'start') {
      let startDate = moment(e)
      let endDate =moment(item.endDate)
      if (endDate.isBefore(startDate)) {
        notification.warning({
          message: 'End date need to before Start date',
          placement: 'bottomRight'
        })
        return
      }
      item.startDate = new Date(e)
    } else {
      let startDate = moment(item.startDate)
      let endDate =moment(e)
      if (endDate.isBefore(startDate)) {
        notification.warning({
          message: 'End date need to before Start date',
          placement: 'bottomRight'
        })
        return
      }
      item.endDate = new Date(e)
    }
    if (!listUpdated.includes(item.id) && !item.isNew) {
      listUpdated.push(item.id)
      setListUpdated(listUpdated.slice(0))
    }
    setData(data.slice(0))

  }
  const handleAdd = () => {
    let id = v4()
    let id1 = v4()
    let startDate = data.length === 0 ? new Date() : new Date(new Date(data[data.length - 1].endDate).setDate(data[data.length - 1].endDate.getDate() + 1))
    let endDate = data.length === 0 ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date(new Date(data[data.length - 1].endDate).setDate(data[data.length - 1].endDate.getDate() + 2))
    data.push({ id, startDate, endDate, isNew: true, ownerId: userData.id, data: [
      { id:id1, projectName: '', power: '' }
    ] })
    key.push(id)
    listAdded.push(id)
    setListAdded(listAdded.slice(0))
    setData(data.slice(0))
    setKey(key.slice(0))
  }
  const handleRemoveTimeline = (item, e) => {
    if (!window.confirm('Are you sure to remove?')) {
      e.stopPropagation()
      return
    }
    let index = _.findIndex(data, i => { return i.id === item.id })
    if (index >= 0) {
      if (!item.isNew) {
        listDeleted.push(item.id)
        setListDeleted(listDeleted.slice(0))
      }
      data.splice(index, 1)
      setData(data.slice(0))
    }
    e.stopPropagation()
  }
  const handleClose = () => {
    props.setManpowerData({ openEditMultiValuesDialog: { isOpen: false, data: null } })
  }
  const callbackChange = (k) => {
    setKey(k)
  }
  const handleStopCollapse = (e) => {
    console.log(e)
    e.stopPropagation()
  }
  return (
    <>
      <Modal show={true} onHide={handleClose} size='md' style={{ top: 0 }} backdrop >
        <Modal.Header>
          <Modal.Title>Edit Cells</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ height: '100%' }} >
            <div style={{ display: 'flex' }} >
              <div style={{ width: '50%', padding: 5 }} >
                <Text strong>  Id: </Text>
                <Input style={{ borderRadius: 0 }} size='sm' value={userData.id} disabled />
              </div>
              <div style={{ width: '50%', padding: 5 }}  >
                <Text strong>  User name: </Text>
                <Input style={{ borderRadius: 0 }} size='sm' value={userData.name} disabled />
              </div>
            </div>


            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }} >
              <Text strong >  Timeline: </Text>
              <Button onClick={handleAdd} >Add</Button>
            </div>

            <Collapse onChange={callbackChange} activeKey={key}  >
              {data.map((i, k) =>
                <Panel collapsible={'disabled'} header={
                  <div onClick={handleStopCollapse} style={{display:'contents'}}>
                    {/* <DateRangePicker style={{ borderRadius: 0 }} size='sm' value={[i.startDate, i.endDate]} format='DD/MM/YYYY' placement='right'
                      onChange={handleChangeDate.bind(this, i)} cleanable={false} /> */}
                    <Text strong style={{ marginRight: 2 }}>Start: </Text>
                    <DatePicker style={{ borderRadius: 0 }} size='sm' value={i.startDate} format='DD/MM/YYYY' placement='right'
                      onChange={handleChangeDate.bind(this, i, 'start')} cleanable={false} />
                    <Divider vertical />
                    <Text strong style={{ marginRight: 2 }}>End: </Text>
                    <DatePicker style={{ borderRadius: 0 }} size='sm' value={i.endDate} format='DD/MM/YYYY' placement='right'
                      onChange={handleChangeDate.bind(this, i, 'end')} cleanable={false} />
                  </div>
                } key={i.id} extra={
                  <div>
                    <IconButton size='xs' appearance='ghost' color='blue' icon={<Icon icon='plus' />} style={{ borderRadius: 0, marginRight: 5 }}
                      onClick={handleAddDataTimeline.bind(this, i)}
                    />
                    <IconButton size='xs' appearance='ghost' color='red' icon={<Icon icon='trash' />} style={{ borderRadius: 0 }}
                      onClick={handleRemoveTimeline.bind(this, i)}
                    />
                  </div>

                } >
                  {i.data.map(v =>
                    <div style={{ display: 'flex', padding: 5 }}>
                      <Input style={{ borderRadius: 0 }} size='sm' placeholder='Project Name' value={v.projectName} onChange={handleChangeProject.bind(this, i, v)} />
                      <InputNumber style={{ borderRadius: 0 }} size='sm' placeholder='%' value={v.power} onChange={handleChangePower.bind(this, i, v)} max={100} min={0} />
                      <IconButton
                        style={{ borderRadius: 0 }}
                        size='sm'
                        appearance={'default'}
                        color='red'
                        icon={<Icon icon='trash' />}
                        onClick={handleRemoveDataTimeline.bind(this, i, v)}
                      />
                    </div>
                  )}

                </Panel>
              )}

            </Collapse>


          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} color="ghost"> Cancel</Button>
          <Button onClick={handleOk} color="primary" >OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}




DialogEditMultiValues.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
  manpower: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(DialogEditMultiValues);






