import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { Button, Icon, IconButton, ButtonGroup, Input, TagPicker, Divider, Loader, Modal ,Tag} from 'rsuite';
import { Typography, message, Tooltip, Collapse, List, Card, Skeleton, Empty } from 'antd';
import { Intent, Position, Toaster } from "@blueprintjs/core";
import _ from 'lodash'
import * as semantic from 'semantic-ui-react';
import Zoom from 'react-medium-image-zoom'
import { Menu, Item, Separator, MenuProvider, animation, IconFont } from 'react-contexify';
import { v4 as uuidv4 } from 'uuid';
import { getPublicUrl, socket ,trackingUserUsing} from '../function/AdminFunction'
import { setupForgeExtensionBeforeLoaded, getAllModelsElementdbIdsWithCondition, handleSetColorElement } from '../function/ForgeFunction'
import { convertVector4ColorToHex } from '../function/TableFunction'
import * as antd from 'antd'
import {DialogDetailView} from '../dialog'
import base64url from 'base64url'
import { Scrollbars } from 'react-custom-scrollbars';
const { TextArea, } = antd.Input;
const { Text, Title } = Typography
const { Panel } = Collapse;
const { Meta } = Card;
const Autodesk = window.Autodesk;
let viewPort = []
let elementColor = []
let count = 0
let keyActive = []
let markup = null
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
function SideBarViewPoint(props) {
  const [loading, setLoading] = useState(false)
  const [loadingCard, setLoadingCard] = useState(false)
  const [displayPanel, setDisplayPanel] = useState(false)
  const [displayAddNewPanel, setDisplayAddNewPanel] = useState(false)
  const [displayAddNewViewPointPanel, setDisplayAddNewViewPointPanel] = useState(false)
  const [group, setGroup] = useState([])
  const [viewpoint, setViewpoint] = useState('')
  const [itemDetail, setItemDetail] = useState({})
  const [displayDetailViewpoint, setDisplayDetailViewpoint] = useState(false)
  const [activeKey, setActiveKey] = useState(-1)
  const toaster = useRef()

  useEffect(() => {
    trackingUserUsing(props.email,props.projectId,'BIM App','Save View')
    socket.on('realtime-group-viewpoint', realTimeGroup)
    return () => {
      socket.removeListener('realtime-group-viewpoint', realTimeGroup)
    }
  },[])

  const realTimeGroup = (data) => {
    let tempData = group.slice(0)
    let index = _.findIndex(tempData, e => { return e.viewpointId === data.item.viewpointId })
    if (data.status === 'new-group') {
      if (index >= 0) {
        tempData.splice(index, 1, data.item)
      } else {
        tempData.push(data.item)
      }
      setGroup(tempData)
    } else if (data.status === 'delete-group') {
      if (index >= 0) {
        tempData.splice(index, 1)
      }
      setGroup(tempData)
    } else if (data.status === 'new-viewpoint' || data.status === 'delete-viewpoint') {
      if (index >= 0) {
        tempData[index] = data.item
        let temp = tempData[index]
        controlViewpoint(tempData, temp)
      }
    }

  }
  useEffect(() => {
    viewPort = []
    elementColor = []
    count = 0
    keyActive = []
    setLoading(true)
    setDisplayPanel(props.displayPanel)
    axios.get("/api/viewpoint/get-group-viewpoint", { params: { projectId: props.projectId } })
      .then(res => {
        setLoading(false)
        let temp = []
        _.forEach(res.data, item => {
          if (item.userCreated === props.email) {
            temp.push(item)
          } else {
            let check = false
            _.forEach(item.viewpoints, v => {
              if (v.listShare.includes(props.email)) {
                check = true
                return false
              }
            })
            if (check) {
              temp.push(item)
            }
          }
        })
        setGroup(temp)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const handleCloseViewPanel = () => {
    props.onDisplayPanelChange('viewPointPanel', false)
  }
  const handleCloseAddNewPanel = (data) => {
    if (data) {
      let temp = group.slice(0)
      temp.push(data)
      setGroup(temp)
    }
    setDisplayAddNewPanel(false)
  }
  const handleOpenAddNewPanel = () => {
    setDisplayAddNewPanel(true)
  }
  const handleAddNewViewPointPanel = (item, e) => {
    setDisplayAddNewViewPointPanel(true)
    setViewpoint(item)
    e.stopPropagation();
  }
  const handleDeleteGroup = (item, e) => {
    e.stopPropagation();
    setLoading(true)
    if (!window.confirm('Are you sure to delete this group?')) return
    axios.post(`/api/viewpoint/delete-group-viewpoint`, { projectId: props.projectId, viewpointId: item.viewpointId })
      .then(() => {
        let temp = group.slice(0)
        let index = _.findIndex(temp, e => { return e.viewpointId === item.viewpointId })
        if (index >= 0) {
          temp.splice(index, 1)
          setGroup(temp)
        }
        setLoading(false)
        message.success(`Deleted group`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't delete group.`);
      })
    // e.stopPropagation();
  }
  const handleClosAddNewViewPointPanel = (data) => {
    if (data) {
      let tempData = group.slice(0)
      let index = _.findIndex(tempData, e => { return e.viewpointId === data.viewpointId })
      if (index >= 0) {
        tempData[index] = data
        let temp = tempData[index]
        controlViewpoint(tempData, temp)
      }

    }
    setDisplayAddNewViewPointPanel(false)
  }
  const handleOpenGroup = (key) => {
    setActiveKey(key)
    if (keyActive.includes(key) || !key) return
    keyActive.push(key)
    let tempData = group.slice(0)
    let temp = tempData[key]
    controlViewpoint(tempData, temp)
  }
  const controlViewpoint = (tempData, temp) => {
    setLoadingCard(true)
    let count = temp.viewpoints.length
    if (count === 0) {
      setLoadingCard(false)
      return
    }
    let list = []
    _.forEach(temp.viewpoints, item => {
      let share = false
      if (temp.userCreated !== props.email && item.userCreated !== props.email) {
        if (!item.listShare.includes(props.email)) {
          count--
          if (count === 0)
            temp.viewpoints = list
          return
        } else {
          share = true
        }
      }
      if (temp.userCreated === props.email && item.userCreated !== props.email) {
        share = true
      }
      getPublicUrl(item.img)
        .then(res => {
          item.url = res
          item.share = share
          list.push(item)
          count--
          if (count === 0) {
            temp.viewpoints = list
            setGroup(tempData)
            setLoadingCard(false)
          }
        })
        .catch(err => {
          item.url = err
          item.share = share
          list.push(item)
          count--
          if (count === 0) {
            temp.viewpoints = list
            setGroup(tempData)
            setLoadingCard(false)
          }
        })
    })
  }
  const handleDeleteViewPoint = (e) => {
    if (e.props.item.userCreated === props.email) {
      if (!window.confirm('Are you sure to delete this viewpoint?')) return
      axios.post(`/api/viewpoint/delete-viewpoint`, { viewpointId: e.props.viewpoint.viewpointId, identify: e.props.item.identify, email: props.email })
        .then(res => {
          let tempData = group.slice(0)
          let index = _.findIndex(tempData, v => { return v.viewpointId === res.data.viewpointId })
          if (index >= 0) {
            tempData[index] = res.data
            let temp = tempData[index]
            controlViewpoint(tempData, temp)
          }
        })
        .catch(() => {
          message.error(`Can't delete viewpoint.`);
        })
    }
    else {
      message.warning(`You can't delete this view, because you wasn't created it`)
    }
  }
  const handleDetailViewPoint = (viewpoint, item) => {
    setViewpoint(viewpoint)
    setItemDetail(item)
    setDisplayDetailViewpoint(true)
    setDisplayPanel(false)
  }
  const handleCloseDetailViewPoint = (data) => {
    if (data) {
      let tempData = group.slice(0)
      let index = _.findIndex(tempData, e => { return e.viewpointId === data.viewpointId })
      if (index >= 0) {
        tempData[index] = data
        let temp = tempData[index]
        controlViewpoint(tempData, temp)
      }
    }
    setDisplayDetailViewpoint(false)
    setDisplayPanel(true)
  }
  const handleOpenViewpoint = (check, e) => {
    let path = base64url(`${props.projectId}/${props.userCreatedProject}/${e.props.viewpoint.viewpointId}/${e.props.item.identify}/${check}`)
    window.open(`${window.location.origin}/view/${path}`)
  }

  //#region restore
  const restoreSaveView = (check, e) => {
    try {
      let section = props.viewer.getExtension('Autodesk.Section')
      section.activate();
      section.deactivate();
    } catch { }
    try {
      let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
      markupCore.unloadMarkupsAllLayers()
      markupCore.hide()
      props.onChangeDockDisplay('markUp2dEdit', false)
    } catch { }
    let data = e.props.item
    if (data.mainModel.version !== props.version) {
      toaster.current.show({
        timeout: 2000,
        icon: 'warning-sign',
        intent: Intent.WARNING,
        message: (
          <Text strong style={{ color: 'white' }}>
            {`Issue created at version ${data.mainModel.version}`}
          </Text>
        )
      })
    }
    let document = props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let node = props.viewer.impl.model.getDocumentNode()
    let loadOptions = {
      globalOffset: props.viewer.impl.model.myData.globalOffset,
      applyRefPoint: true,
      modelNameOverride: data.mainModel.name,
      isAEC: true,
      guid: data.mainModel.guid,
      viewableID: data.mainModel.viewableID,
      itemId: data.mainModel.itemId,
      version: data.mainModel.version,
      acmSessionId: document.acmSessionId
    }
    if (data.guid === node.data.guid) {
      handleOtherModel(data, check)
      return false
    } else {
      let viewables = document.getRoot().search({ 'type': 'geometry', 'guid': data.guid }, true)
      if (viewables.length === 0)
        viewables = document.getRoot().search({ 'type': 'geometry', 'viewableID': data.viewableID }, true)
      if (viewables.length === 0 && data.viewName)
        viewables = document.getRoot().search({ 'type': 'geometry', 'name': data.viewName }, true)
      if (viewables.length !== 0) {
        props.viewer.loadDocumentNode(document, viewables[0], loadOptions)
          .then(() => {
            setupForgeExtensionBeforeLoaded(props.viewer, props.viewerPage)
            handleOtherModel(data, check)
          })
          .catch(() => {
            message.warning(`Load model failed`)
          })
      } else {
        handleOtherModel(data, check)
        message.warning(`View isn't existing in file, use current view`)
      }
      return false
    }
  }
  const handleOtherModel = (data, check) => {
    viewPort = data.viewPort
    markup = data.markup
    elementColor = data.attachElementColor
    count = data.otherModel.length
    const models = props.viewer.impl.modelQueue().getModels()
    if (data.otherModel.length > 0) {
      let tempModeled = []
      _.forEach(models, v => {
        if (v.myData.urn !== props.viewer.impl.model.myData.urn) {
          let index = _.findIndex(data.otherModel, e => { return e.itemId === v.myData.loadOptions.itemId && e.version === v.myData.loadOptions.version && e.guid === v.myData.loadOptions.guid })
          if (index < 0)
            props.viewer.unloadModel(v)
          else
            tempModeled.push(index)
        }
      })
      _.forEach(data.otherModel, (v, k) => {
        if (tempModeled.includes(k)) {
          count--
          if (count === 0) {
            handleSetColorElement(data.attachElementColor, props.viewer, viewPort, markup, props.viewerPage)
          }
          return
        }
        axios.post(`/api/issue/get-model`, { item: v, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: props.viewer.impl.model.myData.globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                viewableID: res.data.viewableID,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              var documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), _onDocumentLoadFailure);
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(() => {
            count--
            message.error(`Can't load model.`);
          })
      })
    } else {
      _.forEach(models, v => {
        if (v.myData.urn !== props.viewer.impl.model.myData.urn) {
          props.viewer.unloadModel(v)
        }
      })
      handleSetColorElement(data.attachElementColor, props.viewer, viewPort, markup, props.viewerPage)
    }
  }
  const _onDocumentLoadFailure = (viewerErrorCode) => {
    console.log(viewerErrorCode)
  }
  const _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      props.viewer.loadModel(svfUrl, loadOptions, _onLoadModelSuccess, _onLoadModelError)
    } else {
      message.warning(`Can't find view in file ${loadOptions.modelNameOverride} version ${loadOptions.version}`)
    }
  }
  const _onLoadModelSuccess = () => {
    if (count === 0) {
      handleSetColorElement(elementColor, props.viewer, viewPort, markup, props.viewerPage)
    }
  }
  const _onLoadModelError = () => {

  }
  //#endregion

  return (
    <>
      <div style={{
        width: 400, height: props.isProject ? 'calc(100% - 55.78px)' : 'calc(100% - 53.78px)', position: 'absolute', left: props.isProject ? 0 : 40, background: 'white', zIndex: 100, padding: 10,
        display: displayPanel ? 'block' : 'none', borderRight: props.isProject && '1px solid', top: props.isProject && 55
      }}>
        <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}>
          {props.viewer &&
            <ButtonGroup style={{ bottom: 2 }} >
              <Tooltip title="New Group">
                <IconButton size="xs" onClick={handleOpenAddNewPanel} icon={<Icon icon="plus-square" />} />
              </Tooltip>
            </ButtonGroup>}
          <Title level={4} style={{ display: 'contents' }}>   Views</Title>
          <Icon icon='close' size='xs' style={{ float: 'right', cursor: 'pointer' }} onClick={handleCloseViewPanel} />
        </div>
        <Divider style={{ margin: '5px 0' }} />
        <div style={{ padding: '0px 0px 0px 0px', width: '100%', height: 'calc(100% - 36px)' }}>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            {group.length !== 0 ? <Collapse activeKey={activeKey} expandIconPosition='left' bordered={true} onChange={handleOpenGroup} accordion={true}>
              {group.map((v, k) =>
                <Panel header={v.groupName} key={k} className='custom-collapse-viewpoint'
                  extra={
                    <ButtonGroup>
                      <IconButton size="xs" disabled={v.userCreated !== props.email}
                        icon={<Icon icon="trash-o" />} onClick={handleDeleteGroup.bind(this, v)} />
                      <IconButton size="xs"
                        icon={<Icon icon="plus-square-o" />} onClick={handleAddNewViewPointPanel.bind(this, v)} />
                    </ButtonGroup>
                  }>
                  {v.viewpoints.length !== 0 ? <List
                    grid={{ gutter: [8, 8], column: 3 }}
                    dataSource={v.viewpoints}
                    renderItem={item => {
                      return (
                        <List.Item style={{ marginBottom: '0px' }}>
                          <MenuProvider id={item.mainModel.itemId === props.itemId ? "viewpoint_menu" : "other_viewpoint_menu"} animation={animation.zoom} data={{ viewpoint: v, item: item }}>
                            <Skeleton loading={loadingCard} active paragraph={{ rows: 1 }}>
                              <Card bodyStyle={{ padding: 6 }}
                                hoverable
                                style={{ width: 120, height: loadingCard && 81.85 }}
                                cover={
                                  <div style={{ height: 56, width: 'fit-content', background: '#e0e0e0' }}>
                                    <Zoom portalEl={document.getElementById('zoom-issue-image')} transitionDuration={0} zoomMargin={0}>
                                      <semantic.Image src={item.url} rounded />
                                    </Zoom>
                                  </div>

                                }
                              >

                                <Meta onDoubleClick={handleDetailViewPoint.bind(this, v, item)}
                                  title={

                                    <Tooltip title={item.title}>
                                      {item.share && <Icon icon='share' style={{ float: 'left', position: 'relative', top: 7, color: item.share ? 'blue' : '#acacac', marginRight: 3 }} />}

                                      <Text strong style={{ fontSize: 12, color: item.mainModel.itemId !== props.itemId && '#c6ccd2' }} >
                                        {item.title}</Text>

                                    </Tooltip>
                                  } />


                              </Card>
                            </Skeleton>
                          </MenuProvider>
                        </List.Item>
                      )
                    }}
                  /> :
                    <Text strong>There are no views in this group</Text>}
                </Panel>
              )}
            </Collapse> :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

          </Scrollbars>
        </div>

      </div>

      {/* <div style={{ marginTop: 5 }}>
            <ControlGroup fill={true} vertical={false}>
              <HTMLSelect options={["All", "Group Name", "Title", "Description", "Share to me"]} />
              <InputGroup placeholder="Search" />
              <BlueprintButton icon="search" intent="primary"
              />
            </ControlGroup>
          </div> */}

      <Menu id='viewpoint_menu' style={{ zIndex: 10000 }}>
        <Item onClick={restoreSaveView.bind(this, true)}>
          <IconFont className="fas fa-check" />Load View
                </Item>
        <Item onClick={restoreSaveView.bind(this, false)}>
          <IconFont className="fas fa-check-double" />Load View With Original Version
                </Item>
        <Separator />
        <Item onClick={handleDeleteViewPoint} >
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
      <Menu id='other_viewpoint_menu' style={{ zIndex: 10000 }}>
        <Item onClick={handleOpenViewpoint.bind(this, true)}>
          <IconFont className="fas fa-check" />Load View
                </Item>
        <Item onClick={handleOpenViewpoint.bind(this, false)}>
          <IconFont className="fas fa-check-double" />Load View With Original Version
                </Item>
        <Separator />
        <Item onClick={handleDeleteViewPoint} >
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
      {displayAddNewPanel &&
        <DialogAddNewGroup
          projectId={props.projectId}
          itemId={props.itemId}
          open={displayAddNewPanel}
          handleClose={handleCloseAddNewPanel}
          email={props.email}
        />}
      {displayAddNewViewPointPanel &&
        <DialogAddNewViewPoint
          projectId={props.projectId}
          itemId={props.itemId}
          open={displayAddNewViewPointPanel}
          handleClose={handleClosAddNewViewPointPanel}
          email={props.email}
          userCreatedProject={props.userCreatedProject}
          viewer={props.viewer}
          viewpoint={viewpoint}
        />}
      {displayDetailViewpoint &&
        <DialogDetailView
          projectId={props.projectId}
          itemId={props.itemId}
          open={displayDetailViewpoint}
          handleClose={handleCloseDetailViewPoint}
          email={props.email}
          userCreatedProject={props.userCreatedProject}
          viewer={props.viewer}
          itemDetail={itemDetail}
          viewpoint={viewpoint}
          restoreSaveView={restoreSaveView}
          handleOpenViewpoint={handleOpenViewpoint}
          handleDeleteViewPoint={handleDeleteViewPoint}
          newTab={false}
        />}
      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />
    </>

  )
}

export default SideBarViewPoint

function DialogAddNewGroup(props) {
  const [loading, setLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const handleClose = () => {
    props.handleClose(null)
  }
  const handleChangeGroupName = (value) => {
    setGroupName(value)
  }
  const handleAddNewGroup = () => {
    setLoading(true)
    axios.post(`/api/viewpoint/add-group-viewpoint`, { projectId: props.projectId, groupName: groupName, email: props.email })
      .then(res => {
        props.handleClose(res.data)
        message.success(`Create new group`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't create new issue.`);
      })
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' backdrop='static' style={{ top: 60 }}>
      <Modal.Header>
        <Modal.Title>Add New Group ViewPoint</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Input placeholder='Group Name' style={{ width: '100%' }} size='sm' onChange={handleChangeGroupName} value={groupName} />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleAddNewGroup} loading={loading}> Add</Button>
        <Button appearance="default" onClick={handleClose} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
function DialogAddNewViewPoint(props) {
  const [loading, setLoading] = useState(false)
  const [listMember, setListMember] = useState([])
  const [listShareViewPoint, setListShareViewPoint] = useState([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = []
        res.data.members.sort()
        res.data.members.splice(0, 0, props.userCreatedProject)
        _.forEach(res.data.members, v => {
          if (v !== props.email)
            temp.push({
              label: v,
              value: v,
              role: 'user'
            })
        })
        setListMember(temp)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])
  const handleClose = () => {
    props.handleClose(null)
  }
  const handleAssignChange = (value) => {
    setListShareViewPoint(value)
  }
  const handleAddNewViewPoint = () => {
    if (title === '' || description === '') {
      message.warning('Please, input data')
      return
    }
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let markupGeneratedForLeaflet = null
    try {
      markupGeneratedForLeaflet = markupCore.generateData()
    } catch { }
    setLoading(true)
    let guid = uuidv4()
    let data = {}
    let node = props.viewer.impl.model.getDocumentNode()
    const models = props.viewer.impl.modelQueue().getModels()
    data.identify = guid
    data.guid = node.data.guid
    data.viewableID = node.data.viewableID
    data.viewName = node.data.name
    data.viewPort = props.viewer.getState()
    data.title = title
    data.description = description
    data.listShare = listShareViewPoint
    data.dateCreated = 0
    data.userCreated = props.email
    data.dateModified = 0
    data.userModified = props.email
    data.comments = []
    data.markup = markupGeneratedForLeaflet
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        data.mainModel = {
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        }
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    data.otherModel = tempOtherModel

    let count = models.length
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count--
      let count1 = temp.length
      _.forEach(temp, id => {
        // let isHide = instanceTree.isNodeHidden(id)
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            data.attachElementColor = tempElement
            let screenshot = new Image();
            screenshot.src = props.viewer.canvas.toDataURL("image/png");
            screenshot.onload = () => {
              let canvas = document.getElementById('snapshot');
              canvas.width = props.viewer.canvas.clientWidth;
              canvas.height = props.viewer.canvas.clientHeight;
              let ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

              try {
                let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                markupCore.renderToCanvas(ctx, () => {
                  data.img = canvas.toDataURL()
                  handleAddNewViewpoint(data)
                });
              } catch {
                data.img = screenshot.src
                handleAddNewViewpoint(data)
              }
            }
          }
        })
      })
    })

  }
  const handleAddNewViewpoint = (data) => {
    axios.post(`/api/viewpoint/add-new-viewpoint`, { projectId: props.projectId, viewpointId: props.viewpoint.viewpointId, data: data })
      .then(res => {
        props.handleClose(res.data)
        message.success(`Create new view`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't create new view`);
      })
  }
  const handleChangeTitle = (value) => {
    setTitle(value)
  }
  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' backdrop='static' style={{ top: 60 }} className='dialog-share-project'>
      {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
      <Modal.Header>
        <Modal.Title>Add New ViewPoint</Modal.Title>
      </Modal.Header>
      <Divider className='custom-divider' />
      <Modal.Body >

        <Text strong> Title</Text><br />
        <Input placeholder='Title' style={{ width: '100%' }} size='sm' onChange={handleChangeTitle} value={title} /><br />
        <Text strong> Description</Text><br />
        <TextArea placeholder='Description' onChange={handleChangeDescription} size='small' autoSize={{ minRows: 3 }} value={description} /><br />
        {/* <Input placeholder='Description' style={{ width: '100%' }} size='sm' onChange={handleChangeDescription} value={description} /> */}
        <Text strong> Share with: </Text><br />
        <TagPicker data={listMember} style={{ width: '100%', marginBottom: '1em' }} onChange={handleAssignChange} placement='rightEnd' size="sm" groupBy="role"
          renderMenu={menu => {
            if (loading) {
              return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                  <Icon icon="spinner" spin /> Loading...
                </p>
              );
            }
            return menu;
          }}
          renderMenuItem={(label, item) => {
            return (
              <div>
                {/* <i className={item.role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} */}
                <Icon icon={item.role === 'user'?'user':'group'} /> {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                {/* <i className={item.children[0].role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} - (
                {item.children.length}) */}
                <Icon icon={item.children[0].role === 'user'?'user':'group'} />  {label} - (
                {item.children.length})
              </div>
            );
          }}
          renderValue={(values, items, tags) => {
            return items.map((tag, index) => (
              <Tag style={{ color: tag.role === 'user' ? '#8ab4f8' : '#ff7171' }}>
                    <Icon icon={tag.role === 'user'?'user':'group'} /> {tag.label}
                </Tag> 
            ));
          }}
        />


      </Modal.Body>
      <Divider className='custom-divider' />
      <Modal.Footer>
        <Button appearance="primary" onClick={handleAddNewViewPoint} loading={loading}> Add</Button>
        <Button appearance="default" onClick={handleClose} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
