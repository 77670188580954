import React from 'react';
import { Button } from 'antd';

export default function CellButtonDoNumber(params, props) {
  if (params && params.node && params.node.data) {
    let doData = params.node.data.doData?.doList;
    if (doData !== null && Array.isArray(doData) && doData.length > 0) {
      return (
        <Button
          onClick={() => {
            props.setShowDoNumberList();
            props.setCurrentDoDataList(doData);
          }}
          type="link"
        >
          {doData.map((x) => x.doNumber).join()}
        </Button>
      );
    }
    //
  }
  return '';
}
