import axios from "axios";

export default {
  user: {
    login: credentials =>
    axios.post("/api/auth", { credentials }).then(res => res.data.user),
    loginAzure: credentials =>
    axios.post("/api/auth/azure", { credentials }).then(res => res.data.user),
    signup: user =>
    axios.post("/api/users", { user }).then(res => res.data.user),
    confirm: token =>
    axios
      .post("/api/auth/confirmation", { token })
      .then(res => res.data.user),
    resetPasswordRequest: email =>
    axios.post("/api/auth/reset_password_request", { email }),
    validateToken: token => axios.post("/api/auth/validate_token", { token }),
    resetPassword: data => axios.post("/api/auth/reset_password", { data })
  },
  project:{
    fetchAllProject: (email) => axios.get(`/api/projects?q=${Buffer.from(email).toString('base64')}`)
    .then(res  => (
      res.data)
    ),
    fetchAllFolder: (projectId, role) => axios.post(`/api/folders`,{projectId,role})
    .then(res  => (
      res.data)
    ),
    fetchAllItem: (folderId) => axios.get(`/api/projects/items?q=${folderId}`).then(res  => (
      res.data)
    ),
    createBucket: (bucketKey) => axios.post(`/api/projects/oss/buckets`,{bucketKey}).then(
      res  => (
      res.data)
    ),
  }
}