import { Col, Row, Skeleton, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiFetchDataAllProjectInDatabase, apiFetchRowHistoryManyProject } from '../api';
import { convertDataFromDB, getRandomIntInclusive, getUserRoleTradeCompany } from '../utils';
import CardPanelProject from './CardPanelProject';
import ChartBarDrawingLate from './ChartBarDrawingLate';
import ChartBarStack from './ChartBarStack';
import PanelAllChartProject from './PanelAllChartProject';



const { TabPane } = Tabs;



const PageDashboard = ({ projectsArray, token, role, company }) => {

   const roleTradeCompany = getUserRoleTradeCompany(role, company);


   const [dataDB, setDataDB] = useState(null);
   const [loading, setLoading] = useState(false);


   const dummyProductivity = createDummyProductivity();


   useEffect(() => {
      const loadData = async () => {
         setLoading(true);
         try {

            const sheetIds = projectsArray.map(x => x.id);

            let [resRows, resDB] = await Promise.all([
               apiFetchRowHistoryManyProject({ token, sheetIds }),
               apiFetchDataAllProjectInDatabase({ token, sheetIds })
            ]);
            setDataDB(convertDataFromDB(resDB.data, resRows.data, projectsArray));

            setLoading(false);
         } catch (err) {
            console.log(err);
            setLoading(false);
         };
      };
      loadData();
   }, []);




   return (
      <div style={{ marginTop: 10 }}>
         <Row justify='space-around' style={{ margin: '10px 0 5px 0' }}>
            {dataDB && dataDB.projectSplit.length > 1 && (
               <div style={{
                  padding: 15,
                  margin: 12,
                  boxShadow: '5px 15px 24px 5px #d2dae2',
                  border: 'none',
                  marginBottom: 15,
                  borderRadius: 20, overflow: 'hidden',
               }}>
                  <Tabs onChange={() => { }} type='card'>
                     {dataDB.projectComparison.map((item, i) => {
                        return (
                           <TabPane tab={item.name} key={item.name}>
                              <ChartBarDrawingLate data={item.data} title='No Of Drawing Late Construction' />
                              <ChartBarDrawingLate data={item.data} title='No Of Drawing Late Approval' />
                              <ChartBarStack data={item.data} title='Drawing Status' />
                              <ChartBarStack data={item.data} title='Productivity - (days per drawing)' dummyProductivity={dummyProductivity} />
                           </TabPane>
                        );
                     })}
                  </Tabs>
               </div>
            )}
         </Row>


         {!loading && dataDB ? (
            <div style={{ padding: '0 12px' }}>
               {dataDB.projectSplit.map((project, i) => {
                  const { dataProject, projectName, projectId } = project;

                  let isProjectEmpty = false;
                  const overallFound = dataProject.find(x => x.panel === 'OVERALL');
                  const { dataInfo } = overallFound;
                  const rows = dataInfo && dataInfo.rows;
                  if (rows && rows.length === 0) isProjectEmpty = true;


                  return !isProjectEmpty && (
                     <CardPanelProject
                        key={i}
                        title={projectName.toUpperCase()}
                        key={projectName}
                        projectsCount={dataDB.projectSplit.length}
                     >
                        <PanelAllChartProject
                           isThisPanelInAllProjectsDashboard={true}
                           propsFromAllProjectsDashboard={{ projectName, projectId, dataProject, roleTradeCompany }}
                        />
                     </CardPanelProject>
                  );
               })}
            </div>

         ) : <SkeletonCard />}


      </div>
   );
};

export default PageDashboard;




const SkeletonCard = () => {
   return (
      <div style={{ padding: '0 12px' }}>
         <CardPanelProject title='Project loading ...'>
            <div style={{ padding: '0 3px' }}>
               <Skeleton paragraph={{ rows: 14 }} active />
            </div>
         </CardPanelProject>
      </div>
   );
};




export const ChartPanel = ({ title, children, panel }) => {

   const xxl = panel !== 'OVERALL' ? (
      title === 'Summary of submissions' ? 4
         : title === 'No of drawing to resubmit' ? 6
            : title === 'Drawing Status' ? 5
               : title === 'Status Legend' ? 5
                  : title === 'Status of drawing per revision' ? 6
                     : title === 'Drawing by coordinator' ? 7
                        : title === 'Drawing by modeller' ? 11
                           : 4
   ) : (
      title === 'Summary of submissions' ? 7
         : title === 'No of drawing to resubmit' ? 9
            : title === 'Drawing Status' ? 7
               : title === 'Status of drawing per trade' ? 7
                  : title === 'Status Legend' ? 7
                     : 6
   );


   const xl = panel !== 'OVERALL' ? (
      title === 'Summary of submissions' ? 8
         : title === 'No of drawing to resubmit' ? 10
            : title === 'Drawing Status' ? 8
               : title === 'Status Legend' ? 7
                  : title === 'Status of drawing per revision' ? 9
                     : title === 'Drawing by coordinator' ? 9
                        : title === 'Drawing by modeller' ? 15
                           : 6
   ) : (
      title === 'Summary of submissions' ? 8
         : title === 'No of drawing to resubmit' ? 10
            : title === 'Drawing Status' ? 8
               : title === 'Status of drawing per trade' ? 9
                  : title === 'Status Legend' ? 7
                     : 6
   );


   return (
      <Col style={{ marginBottom: 10, padding: 5 }} xs={24} md={12} xl={xl} xxl={xxl}>
         <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }}>{title}</div>
         {children}
      </Col>
   );
};




const createDummyProductivity = () => {
   const arr = Array.from(Array(20).keys());
   let obj = {};
   arr.forEach((item, i) => {
      obj[i] = {
         'Consultant review and reply': getRandomIntInclusive(3, 7),
         'Create update drawing': getRandomIntInclusive(3, 5),
         'Create update model': getRandomIntInclusive(2, 5),
      };
   });
   return obj;
};




