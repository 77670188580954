import { combineReducers } from 'redux';
import user from "./reducers/user";
import {  project , folder, item,redirect} from "./reducers/app";
import { excel } from "./reducers/excel";
import { dataentry } from "./reducers/dataentry";
import { manpower } from "./reducers/manpower";
import { pdfViewer } from "./reducers/pdf";
import dms_cell from "./reducers/dms/dms_cell";
import dms_project from "./reducers/dms/dms_project";
import dms_row from "./reducers/dms/dms_row";



export default combineReducers({
    user,
    dataentry,
    manpower,
    pdfViewer,
    folder,
    item,
    excel,
    project,
    dms_cell, dms_row, dms_project,redirect
});