import axios from "axios";
// import PageDashboardProject from './PageDashboardProject';
// import { Button, Result } from 'antd';
// import { Loader } from 'rsuite';
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import PanelAllChartProject from "../../module_DMS/dashboard/PanelAllChartProject";


const DashboardDrawingProject = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState('500')
  const [loadSheet, setLoadSheet] = useState(false)
  const [dataUser, setDataUser] = useState({ company: null, role: null })

  useEffect(() => {
    setLoading(true)
    if (!props.projectId) {
      props.history.push('/')
      return
    }
    axios.post('/api/users/get-me-information', { token: props.userInfo.token })
      .then(async res => {
        if (res.data.user.email !== props.userInfo.email) {
          setLoading(false)
          setError(true)
          setErrorCode('500')
          return
        }
        let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === props.location.state.projectId })
        if (res.data.user.email !== 'admin@wohhup.com') {
          if (index < 0 || !res.data.userInfo.contractors[index].isAccessDashboardDrawing) {
            setLoading(false)
            setError(true)
            setErrorCode('400')
            return
          }
        }
        let role = undefined
        let company = undefined
        if (index >= 0) {
          role = res.data.userInfo.contractors[index].role
          company = res.data.userInfo.contractors[index].company
        }

        setDataUser({ company, role })

      })
      .catch(() => {
        setLoading(false)
      })
  }, [])



  return (

    <>
      <PanelAllChartProject
        token={props.userInfo.token}
        callback={props.callback}
        company={dataUser.company}
      />
    </>
  );
};


DashboardDrawingProject.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    userInfo: state.user,
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
  };
}
export default connect(mapStateToProps)(DashboardDrawingProject)