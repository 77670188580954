import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import ConfirmEmailMessage from '../../messages/ConfirmEmailMessage'
import { ButtonUploadFile } from '../../module_BimApp/buttons';
import axios from "axios";
import { Empty, BackTop, message, Tooltip, Typography, Popover } from 'antd';
import { Icon, IconButton, ButtonGroup, Loader } from 'rsuite';
import { Card, Image, Dropdown } from 'semantic-ui-react'
import { WHLogoGif } from '../../../image'
import _ from 'lodash'
import {
  sortItemName, sortItemCreatedDate, sortItemUpdatedDate, sortItemStatus,
  sortDescendingItemName, sortDescendingItemCreatedDate, sortDescendingItemUpdatedDate, sortDescendingItemStatus
} from '../../../components/module_BimApp/function/PageFunction'
import {
  getForgeToken
} from '../../../components/module_BimApp/function/ForgeFunction'
import { TableListItem } from '../../module_BimApp/tables'

import {
  DialogDownloadFile,
  DialogSettingItem,
  DialogShareItem,
  DialogVersionItem
} from '../../module_BimApp/dialog'
import progress from 'request-progress';
import request from 'request'
import { saveAs } from 'file-saver';
import ResultError from '../../messages/ResultError'
import { socket, trackingUser } from '../../../components/module_BimApp/function/AdminFunction'
import { SidebarViewPoint } from '../../module_BimApp/sidebar'
import { Intent, Position, Toaster, ProgressBar, Classes } from "@blueprintjs/core";
import classNames from "classnames"
import { ColumnItem } from '../../module_BimApp/columns'
import { CardItems } from '../../module_BimApp/cards'
import { storeFolderDetail } from '../../../actions/app/app'
const { Text } = Typography

let sortByOptions = [
  { key: 'Name', text: 'Name', value: 'Name', },
  { key: 'Created Date', text: 'Created Date', value: 'Created Date', },
  { key: 'Updated Date', text: 'Updated Date', value: 'Updated Date', },
  { key: 'Status', text: 'Status', value: 'Status', },
]
let interval = null
class FolderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingTableViewPoint: false,
      items: [],

      iconUpDOwn: true,
      sortByValue: 'Name',

      isListCard: false,

      displayDialogDownloadItem: false,
      displayDialogShareItem: false,
      displayDialogSettingItem: false,
      itemId: null,
      itemName: null,
      itemDisplayName: null,
      objectIdDefault: null,
      smartSheetId: '',
      manningScheduleId: '',
      folderSmartSheetId: '',
      typeFile: 'none',
      imgItem: null,
      loadingImg: false,
      listDownload: {},
      progressPercent: 0,
      error: false,

      isDisplayName: true,
      saveIssue: null,
      listSaveIssue: [],
      allListSaveIssue: [],
      loadingIssue: false,
      displayTableIssue: false,
      displayInfoIssue: false,
      pageCurrent: 0,
      disableLoadMore: false,
      loadingMore: false,
      displayFindIssuePanel: false,

      isAssignToMe: false,

      displaySidebarViewpoint: false,

      displayTableComment: false,
      displayInfoComment: false,
      listSaveComment: [],
      allListSaveComment: [],
      isAssignToMeComment: false,
      saveComment: null,
      showStatus: 'All',

      roleUploader: false,
      company: '',
      role: '',
      isTrimble: false
    }
    this.toaster = React.createRef()
  }
  componentDidCatch = () => {
    this.setState({ error: true })
  }
  componentWillMount = () => {
    storeFolderDetail({
      folderId: this.props.location.state.folderId,
      folderName: this.props.location.state.folderName,
    })
    if ($('#topNavigation').is(":hidden")) {
      $('#topNavigation').show()
    }
    axios.post(`/api/items`, {
      folderId: this.props.location.state.folderId,
      email: this.props.userInfo.email,
      endPoint: localStorage.getItem('endPoint')
    })
      .then(res => {
        // _.forEach(res.data, v => {
        //   v.uploadDate = moment(v.uploadDate).format('DD-MM-YYYY HH:mm')
        //   v.createdDate = moment(v.createdDate).format('DD-MM-YYYY HH:mm')
        // })
        res.data.sort(sortItemName)
        this.setState({
          items: res.data,
          loading: false
        }, () => {
          // this.saveStateToLocalStorage() 
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
    socket.on('auto-translate', this.handleRealtimeAutoTranslate)
    socket.on('translate-file', this.handleRealtimeTranslateFile)
    socket.on('success-translate', this.handleRealtimeSuccessTranslate)
    socket.on('progress-upload', this.handleRealtimeProgressUpload)
    socket.on('success-upload', this.handleRealtimeSuccessUpload)
    socket.on('error-upload', this.handleRealtimeErrorUpload)
    socket.on('realtime-item', this.handleRealtimeItem)
  }
  componentDidMount = async () => {
    try {
      trackingUser(this.props.userInfo.email, this.props.location.state.projectName, this.props.location.state.folderName)
    } catch { }
    let index = _.findIndex(this.props.userInfo.contractors, v => { return v.projectId === this.props.location.state.projectId })
    if (index >= 0) {
      let role = this.props.userInfo.contractors[index].role
      let company = this.props.userInfo.contractors[index].company
      let result = await axios.get(`/api/projects/permission-for-role`, { params: { projectId: this.props.location.state.projectId, token: this.props.userInfo.token } })
      .catch(err => { })
      let index1 = _.findIndex(result.data, i => { return i.role === role })
      this.setState({
        roleUploader:index1>=0?result.data[index1].isAccessUpload ?result.data[index1].isAccessUpload :false: false,
        role, company
      })
    }
  };
  componentWillUnmount = () => {
    socket.removeListener('auto-translate', this.handleRealtimeAutoTranslate)
    socket.removeListener('translate-file', this.handleRealtimeTranslateFile)
    socket.removeListener('success-translate', this.handleRealtimeSuccessTranslate)
    socket.removeListener('progress-upload', this.handleRealtimeProgressUpload)
    socket.removeListener('success-upload', this.handleRealtimeSuccessUpload)
    socket.removeListener('error-upload', this.handleRealtimeErrorUpload)
    socket.removeListener('realtime-item', this.handleRealtimeItem)
  }
  // saveStateToLocalStorage = () => {
  //   localStorage.setItem(`${this.props.userInfo.email}-${this.props.location.state.folderId}-items`, JSON.stringify(this.state));
  // };
  // restoreStateFromLocalStorage = () => {
  //   const state = JSON.parse(localStorage.getItem(`${this.props.userInfo.email}-${this.props.location.state.folderId}-items`));
  //   this.setState(state);
  // };
  //#region handle socket
  handleRealtimeAutoTranslate = (data) => {
    try {
      if (data.folderId === this.props.location.state.folderId) {
        if (data.user === this.props.userInfo.email) {
          if (interval) {
            window.clearInterval(interval)
          }
          this.handleGetManifest(data.item);
          interval = setInterval(() => {
            this.handleGetManifest(data.item);
          }, 5000);
        }
      }
    } catch {
      console.log('realtime-auto-translate-err')
    }
  }
  handleRealtimeErrorUpload = (data) => {
    try {
      if (data.folderId === this.props.location.state.folderId) {
        if (data.user === this.props.userInfo.email) {
          this.toaster.current.clear()
          this.toaster.current.show({
            icon: 'tick',
            intent: 'danger',
            message: data.message
          }, "error")
          window.location.reload()
        }
      }
    } catch {
      console.log('realtime-success-upload-err')
    }
  }
  handleRealtimeTranslateFile = (data) => {
    try {
      if (data.user === this.props.userInfo.email) {
        // message.info(`${data.itemName} file translate ${data.progress}%.`)
        this.toaster.current.show({
          icon: "swap-horizontal",
          message: (
            <>
              <Text strong >{data.itemName} </Text>
              <ProgressBar
                className={classNames("docs-toast-progress", {
                  [Classes.PROGRESS_NO_STRIPES]: data.progress >= 100,
                })}
                intent={data.progress < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                value={data.progress / 100}
              />
            </>
          ),
          timeout: data.progress < 100 ? 0 : 100,
        }, data.itemName + "translate")
      }
    } catch {
      console.log('realtime-translate-file-err')
    }
  }
  handleRealtimeSuccessTranslate = (data) => {
    try {
      if (data.folderId === this.props.location.state.folderId) {
        if (data.users.includes(this.props.userInfo.email)) {
          let temp = this.state.items.slice()
          _.forEach(temp, (v, k) => {
            if (v.item.itemId === data.itemId) {
              let tempItem = v
              tempItem.status = data.status
              temp.splice(k, 1)
              temp.splice(k, 0, tempItem)
              return false
            }
          })
          if (data.status === 'success') {
            let message = ''
            if (data.user === this.props.userInfo.email) {
              message = `${data.itemName} file translate successful`
            } else {
              message = `User: ${data.user} was translated file ${data.itemName} success`
            }
            this.toaster.current.show({
              icon: 'tick',
              intent: 'success',
              message: message
            }, "translate")
          }
          else if (data.status === 'failed') {
            let message = ''
            if (data.user === this.props.userInfo.email) {
              message = `${data.itemName} file translate failed`
            } else {
              message = `User: ${data.user} was translated file ${data.itemName} failed`
            }
            this.toaster.current.show({
              icon: 'error',
              intent: 'danger',
              message: message
            }, "translate")
          }
          this.setState({ items: temp })
        }
      }
    } catch {
      console.log('realtime-success-translate-err')
    }
  }
  handleRealtimeProgressUpload = (data) => {
    try {
      if (data.user === this.props.userInfo.email) {
        this.setState({ progressPercent: data.progress })
        //setLoad(data.progress)
        // message.info(`${data.fileName} upload ${data.progress}%`)
        this.toaster.current.show({
          icon: "cloud-upload",
          message: (
            <>
              <Text strong >{data.fileName} </Text>
              <ProgressBar
                className={classNames("docs-toast-progress", {
                  [Classes.PROGRESS_NO_STRIPES]: data.progress >= 100,
                })}
                intent={data.progress < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                value={data.progress / 100}
              />
            </>
          ),
          timeout: data.progress < 100 ? 0 : 2000,
        }, data.fileName + "upload")
      }
    } catch {
      console.log('realtime-progress-upload-err')
    }
  }
  handleRealtimeSuccessUpload = (data) => {
    try {
      if (data.folderId === this.props.location.state.folderId) {
        if (data.users.includes(this.props.userInfo.email)) {
          this.handleCallbackUploadFile(data)
          let message = ''
          if (data.user === this.props.userInfo.email) {
            message = `${data.fileName} upload success`
          } else {
            message = `User: ${data.user} was upload ${data.fileName}`
          }
          this.toaster.current.show({
            icon: 'tick',
            intent: 'success',
            message: message
          }, data.fileName + "upload")
        }
      }
    } catch {
      console.log('realtime-success-upload-err')
    }
  }
  handleRealtimeItem = (data) => {
    try {
      if (data.folderId === this.props.location.state.folderId) {
        if (data.users.includes(this.props.userInfo.email)) {
          if (data.status === 'delete-item') {
            if (data.user !== this.props.userInfo.email) {
              let temp = this.state.items.slice(0)
              let index = _.findIndex(temp, e => { return e.item.itemId === data.itemId })
              if (index >= 0) {
                temp.splice(index, 1)
                this.setState({ items: temp })
                message.warning(`User:${data.user} deleted item ${data.itemName}`)
              }
            }
          } else if (data.status === 'default-version') {
            if (data.user !== this.props.userInfo.email) {
              let temp = this.state.items.slice(0)
              let index = _.findIndex(temp, e => { return e.item.itemId === data.item.item.itemId })
              if (index >= 0) {
                data.item.createdDate = data.item.createdDate// moment(data.item.createdDate).format('DD-MM-YYYY HH:mm')
                data.item.uploadDate = data.item.uploadDate// moment(data.item.uploadDate).format('DD-MM-YYYY HH:mm')
                temp.splice(index, 1)
                temp.splice(index, 0, data.item)
                this.setState({ items: temp })
                message.success(`User:${data.user} change default version ${data.item.item.itemName}`)
              }
            }
          }
        }
      }
    } catch {
      console.log('realtime-item-err')
    }
  }
  //#endregion

  //#region sidebar viewpoint
  handleTableViewPoint = () => {
    if (!this.state.displaySidebarViewpoint) {
      this.setState({
        displayTableIssue: false,
        displayInfoIssue: false,
        displaySidebarViewpoint: true,
      })
    } else {
      this.setState({
        displayTableIssue: false,
        displayInfoIssue: false,
        displaySidebarViewpoint: false,
      })
    }

  }
  callbackDisplayPanel = () => {
    this.setState({ displaySidebarViewpoint: false })
  }
  //#endregion

  //#region sort
  handleSortBy = (e, { value }) => {
    let temp = this.state.items
    if (this.state.iconUpDOwn) {
      if (value === 'Name') {
        temp.sort(sortItemName)
      } else if (value === 'Created Date') {
        temp.sort(sortItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortItemUpdatedDate)
      } else if (value === 'Status') {
        temp.sort(sortItemStatus)
      }
    } else {
      if (value === 'Name') {
        temp.sort(sortDescendingItemName)
      } else if (value === 'Created Date') {
        temp.sort(sortDescendingItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortDescendingItemUpdatedDate)
      } else if (value === 'Status') {
        temp.sort(sortDescendingItemStatus)
      }
    }
    this.setState({ items: temp, sortByValue: value })
  }
  handleUpDown = () => {
    let temp = this.state.items
    this.setState({ iconUpDOwn: !this.state.iconUpDOwn }, () => {
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortItemName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        } else if (this.state.sortByValue === 'Status') {
          temp.sort(sortItemStatus)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingItemName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        } else if (this.state.sortByValue === 'Status') {
          temp.sort(sortDescendingItemStatus)
        }
      }
      this.setState({ items: temp })
    })
  }
  //#endregion 

  //#region cb
  handleOpenControlVersionPanel = (data) => {
    this.setState({
      displayDialogDownloadItem: true, itemId: data.itemId, objectIdDefault: data.objectIdDefault, itemName: data.itemName
    })
  }
  handleCloseControlVersionPanel = () => {
    this.setState({ displayDialogDownloadItem: false })
  }
  handleOpenControlSharePanel = (data) => {
    this.setState({
      displayDialogShareItem: true, itemId: data.itemId, objectIdDefault: data.objectIdDefault, itemName: data.itemName, isTrimble: data.isTrimble
    })
  }
  handleCloseSharePanel = () => {
    this.setState({ displayDialogShareItem: false })
  }
  handleOpenControlSettingPanel = (data) => {
    this.setState({
      displayDialogSettingItem: true, itemId: data.itemId, objectIdDefault: data.objectIdDefault, itemName: data.itemName,
      smartSheetId: data.smartSheetId, manningScheduleId: data.manningScheduleId, folderSmartSheetId: data.folderSmartSheetId,
      imgItem: data.imgItem, itemDisplayName: data.itemDisplayName, typeFile: data.typeFile
    })
  }
  handleCloseSettingPanel = () => {
    this.setState({ displayDialogSettingItem: false })
  }
  handleFileSmaller100MB = (file) => {
    this.toaster.current.show({
      icon: "cloud-upload",
      message: (
        <>
          <Text strong >{file.name} </Text>
          <ProgressBar
            className={classNames("docs-toast-progress", {
              [Classes.PROGRESS_NO_STRIPES]: false,
            })}
            intent={Intent.PRIMARY}
            value={null}
          />
        </>
      ),
      timeout: 0,
    }, file.name + "upload")
  }
  handleChangeVersion = (data) => {
    var valueVersion = data.id.replace('V', '');
    axios.post(`/api/items/defaultversion`, {
      objectId: data.value, version: valueVersion, itemId: this.state.itemId,
      folderId: this.props.location.state.folderId, email: this.props.userInfo.email
    })
      .then(res => {
        let temp = this.state.items.slice()
        _.forEach(temp, (v, k) => {
          if (v.item.itemId === res.data.item.itemId) {
            let tempItem = v
            tempItem.item = res.data.item
            tempItem.status = res.data.status
            tempItem.createdDate = res.data.createdDate//moment(res.data.createdDate).format('DD-MM-YYYY HH:mm')
            tempItem.uploadDate = res.data.uploadDate//moment(res.data.uploadDate).format('DD-MM-YYYY HH:mm')
            tempItem.userUpload = res.data.userUpload
            tempItem.version = res.data.version
            temp.splice(k, 1)
            temp.splice(k, 0, tempItem)
            return false
          }
        })
        this.setState({ objectIdDefault: res.data.item.objectIdDefault, items: temp })
        message.success(`${this.state.itemName} change version successful.`);
      })
      .catch(() => {
        message.error(`${this.state.itemName} change version failed.`);
      })
  }
  handleDeleteItem = (value) => {
    if (value.isTrimble) {
      axios.get(`/api/trimble/oauth/trimble-refresh-token`, { params: { token: this.props.userInfo.token } })
        .then(resToken => {
          axios.delete(`https://app31.connect.trimble.com/tc/api/2.0/files/${value.trimbleFileId}`, {
            headers: {
              'Authorization': `Bearer ${resToken.data.access_token}`,
            }
          })
            .then((res) => {
              axios.delete(`/api/items/deleteitem`, { data: value })
                .then(res => {
                  let temp = this.state.items.slice(0)
                  let index = _.findIndex(temp, e => { return e.item.itemId === res.data.itemId })
                  if (index >= 0) {
                    temp.splice(index, 1)
                    this.setState({ items: temp })
                    message.warning(`Deleted item ${res.data.itemName}`)
                  }
                  // window.location.reload()
                })
            })
            .catch(err => {
              message.warning(`Can't delete item`)
            })
        })
        .catch(err => {
          message.warning(`Can't get token`)
        })

    } else {
      axios.delete(`/api/items/deleteitem`, { data: value })
        .then(res => {
          let temp = this.state.items.slice(0)
          let index = _.findIndex(temp, e => { return e.item.itemId === res.data.itemId })
          if (index >= 0) {
            temp.splice(index, 1)
            this.setState({ items: temp })
            message.warning(`Deleted item ${res.data.itemName}`)
          }
          // window.location.reload()
        })
    }

  }
  handleCallbackUploadFile = (data) => {
    if (data.status === 'up-item') {
      let temp = this.state.items.slice(0)
      let index = _.findIndex(temp, e => { return e.item.itemId === data.item.item.itemId })
      if (index <= 0) {
        temp.push(data.item)
        if (this.state.iconUpDOwn) {
          if (this.state.sortByValue === 'Name') {
            temp.sort(sortItemName)
          } else if (this.state.sortByValue === 'Created Date') {
            temp.sort(sortItemCreatedDate)
          } else if (this.state.sortByValue === 'Updated Date') {
            temp.sort(sortItemUpdatedDate)
          }
        } else {
          if (this.state.sortByValue === 'Name') {
            temp.sort(sortDescendingItemName)
          } else if (this.state.sortByValue === 'Created Date') {
            temp.sort(sortDescendingItemCreatedDate)
          } else if (this.state.sortByValue === 'Updated Date') {
            temp.sort(sortDescendingItemUpdatedDate)
          }
        }
        data.item.uploadDate = data.item.uploadDate//moment(data.item.uploadDate).format('DD-MM-YYYY HH:mm')
        data.item.createdDate = data.item.createdDate//moment(data.item.createdDate).format('DD-MM-YYYY HH:mm')
        this.setState({ items: temp })
      }
    } else if (data.status === 'up-version') {
      let temp = this.state.items.slice(0)
      let index = _.findIndex(temp, e => { return e.item.itemId === data.item.item.itemId })
      if (index >= 0) {
        data.item.uploadDate = data.item.uploadDate// moment(data.item.uploadDate).format('DD-MM-YYYY HH:mm')
        data.item.createdDate = data.item.createdDate//moment(data.item.createdDate).format('DD-MM-YYYY HH:mm')
        temp.splice(index, 1)
        temp.splice(index, 0, data.item)
        this.setState({ items: temp })
      }
    }
  }
  handleTranslateObject = (value) => {

    if (interval) {
      window.clearInterval(interval)
    }
    this.toaster.current.show({
      icon: 'swap-horizontal',
      message: `${value.itemName} starting translate ...`
    }, value.itemName + "translate")

    getForgeToken()
      .then(token => {
        axios.get('https://developer.api.autodesk.com/modelderivative/v2/designdata/' + value.objectName + '/manifest', {
          headers: {
            'Authorization': `Bearer ${token.access_token}`,
          }
        })
          .then(res => {
            interval = setInterval(() => {
              this.handleGetManifest(value);
            }, 5000);
          })
          .catch(err => {
            axios.post(`/api/forge/modelderivative/jobs`, {
              itemId: value.itemId, objectName: value.objectName,
              itemDisplayName: value.itemDisplayName, user: this.props.userInfo.email,
              status: value.status, socketId: localStorage.getItem('whapp5d-socketid')
            },
              { timeout: 60 * 10 * 1000 })
              .then(res => {
                if (res.data.status === 'success') {
                  let temp = this.state.items.slice()
                  _.forEach(temp, (v, k) => {
                    if (v.item.itemId === value.itemId) {
                      let tempItem = v
                      tempItem.status = res.data.status
                      temp.splice(k, 1)
                      temp.splice(k, 0, tempItem)
                      return false
                    }
                  })
                  if (res.data.status === 'success') {
                    this.toaster.current.show({
                      icon: 'tick',
                      intent: 'success',
                      message: `${value.itemName} file translate successful`
                    }, value.itemName + "translate")
                  }
                  else if (res.data.status === 'failed') {
                    this.toaster.current.show({
                      icon: 'error',
                      intent: 'danger',
                      message: `${value.itemName} file translate failed`
                    }, value.itemName + "translate")
                  }
                  this.setState({ items: temp })
                } else {
                  interval = setInterval(() => {
                    this.handleGetManifest(value);
                  }, 5000);
                }
              })
              .catch(() => {
                message.error(`${value.itemName} file translate failed.`);
              })
          })
      })

  }
  handleGetManifest = (value) => {
    getForgeToken()
      .then(token => {
        axios.get('https://developer.api.autodesk.com/modelderivative/v2/designdata/' + value.objectName + '/manifest', {
          headers: {
            'Authorization': `Bearer ${token.access_token}`,
          }
        })
          .then(res => {
            this.handleResolveData(value, res);
          })
          .catch(err => {
            console.log(err)
          })
      })
  }
  handleResolveData = async (value, res) => {
    if (res.data.status === 'success') {
      if (interval) {
        window.clearInterval(interval)
      }
      await axios.post(`/api/forge/modelderivative/jobs`, {
        itemId: value.itemId, objectName: value.objectName,
        itemDisplayName: value.itemDisplayName, user: this.props.userInfo.email,
        status: value.status, socketId: localStorage.getItem('whapp5d-socketid')
      })
      let temp = this.state.items.slice()
      _.forEach(temp, (v, k) => {
        if (v.item.itemId === value.itemId) {
          let tempItem = v
          tempItem.status = res.data.status
          temp.splice(k, 1)
          temp.splice(k, 0, tempItem)
          return false
        }
      })
      if (res.data.status === 'success') {
        this.toaster.current.show({
          icon: 'tick',
          intent: 'success',
          message: `${value.itemName} file translate successful`
        }, "translate")
      }
      else if (res.data.status === 'failed') {
        this.toaster.current.show({
          icon: 'error',
          intent: 'danger',
          message: `${value.itemName} file translate failed`
        }, "translate")
      }
      this.setState({ items: temp })
    }
    else {
      var reg = res.data.progress.split("%")
      let progress = _.isNumber(_.toNumber(reg[0])) ? _.toNumber(reg[0]) : 100;
      this.toaster.current.show({
        icon: "swap-horizontal",
        message: (
          <>
            <Text strong >{value.itemName} </Text>
            <ProgressBar
              className={classNames("docs-toast-progress", {
                [Classes.PROGRESS_NO_STRIPES]: progress >= 100,
              })}
              intent={progress < 100 ? Intent.PRIMARY : Intent.SUCCESS}
              value={progress / 100}
            />
          </>
        ),
        timeout: progress < 100 ? 0 : 100,
      }, "translate")
    }
  }

  handleSetShareView = (data) => {
    axios.post(`/api/items/setshareview`, { isShare: data.isShare, itemId: data.itemId, shareOptions: data.shareOptions })
      .then(res => {
        let temp = this.state.items.slice()
        _.forEach(temp, (v, k) => {
          if (v.item.itemId === data.itemId) {
            let tempItem = v
            tempItem.isShare = res.data.isShare
            temp.splice(k, 1)
            temp.splice(k, 0, tempItem)
            if (res.data.isShare)
              message.success(`${data.itemName} turn on share view successful.`);
            else
              message.warning(`${data.itemName} turn off share view successful.`);
            return false
          }
        })
        this.setState({ items: temp })
      })
      .catch(() => {
        message.error(`${data.itemName} share view failed.`);
      })
  }
  handleUploadImg = (value) => {
    this.setState({ loadingImg: true })
    let data = new FormData()
    data.append('file', value.file)
    data.append('itemId', value.itemId)
    axios.post(`/api/items/upimgitem`, data, {
      onUploadProgress: () => {
        // setLoad(
        //     (ProgressEvent.loaded / ProgressEvent.total*100),
        // )
      },
    })
      .then(res => {
        let temp = this.state.items.slice()
        _.forEach(temp, (v, k) => {
          if (v.item.itemId === value.itemId) {
            let tempItem = v
            tempItem.img = res.data
            temp.splice(k, 1)
            temp.splice(k, 0, tempItem)
            return false
          }
        })
        this.setState({ loadingImg: false, items: temp, imgItem: res.data })
        message.success(`Upload image successful`)
      })
      .catch(() => {
        this.setState({ loadingImg: false })
        message.error(`Upload image failed`)
      })
  }
  handleDownloadVersion = (data) => {
    let _this = this
    axios.post(`/api/items/downloadversion`, { itemId: data.itemId, objectId: data.objectId, name: data.name })
      .then(res => {
        message.success('Downloading...')
        _this.setState(prevState => ({
          listDownload: {
            ...prevState.listDownload,
            [data.name]: 0
          }
        }))
        $('#download-modal-bottom').show(1000)
        var options = {
          method: 'GET',
          url: `https://developer.api.autodesk.com/oss/v2/buckets/${res.data.bucketKey}/objects/${res.data.objectKey}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${res.data.token}`,
          },
          encoding: null
        }
        progress(request(options, function (error, response, body) {
          if (error) throw new Error(error)
          _this.setState(prevState => ({
            listDownload: {
              ...prevState.listDownload,
              [data.name]: 100
            }
          }))
          const buffer = Buffer.from(body);
          var blob = new Blob([buffer], { type: "application/octet-stream" });
          saveAs(blob, res.data.name)
        })
        ).on('progress', function (state) {
          var percent = Math.round(state.size.transferred / res.data.size * 100)
          _this.setState(prevState => ({
            listDownload: {
              ...prevState.listDownload,
              [data.name]: percent
            }
          }))
        })
          .on('error', function () { })
          .on('end', function () { })
      })
      .catch(() => {
        message.success('Download version failed')
      })
  }
  //#endregion

  handleDisplayCard = () => {
    this.setState({ isListCard: !this.state.isListCard })
  }

  render() {
    var { isConfirmed, isAdmin, userInfo } = this.props;

    const tools = () => {
      return (
        <ButtonGroup size="md" style={{ float: 'right', paddingRight: 25 }}>

          <Tooltip title={'Show Views'}>
            <IconButton icon={<Icon icon="camera" />} active={this.state.displaySidebarViewpoint} color={this.state.displaySidebarViewpoint && 'red'}
              loading={this.state.loadingIssue} onClick={this.handleTableViewPoint} />
          </Tooltip>
          <Tooltip title={this.state.isDisplayName ? 'Show Display Name' : 'Show File Name'}>
            <IconButton icon={<Icon icon={this.state.isDisplayName ? "file" : 'file-o'} />} color={this.state.isDisplayName && 'blue'} onClick={this.handleDisplayName} />
          </Tooltip>
          <Tooltip title={'Show List'}>
            <IconButton icon={<Icon icon="list" />} active={!this.state.isListCard} onClick={this.handleDisplayCard} />
          </Tooltip>
          <Tooltip title={'Show Card'}>
            <IconButton icon={<Icon icon="th" />} active={this.state.isListCard} onClick={this.handleDisplayCard} />
          </Tooltip>
          <Popover placement='leftTop' content={
            this.props.location.state.listAdmin.map(i =>
              <Text>{i}</Text>)
          } title="Project Admin">
            <IconButton icon={<Icon icon='question-circle' />} />
          </Popover>
        </ButtonGroup>)
    }
    return (
      <React.Suspense fallback={
        <Loader center content="Loading..." speed="fast" size="md" vertical />
      }>

        {!this.state.error && (isConfirmed && (isAdmin || this.state.roleUploader)) && this.props.location.state.folderName !== 'rfa-folder-model' ?
          <ButtonUploadFile key={this.props.location.state.folderId} project={this.props.location.state} user={userInfo.email}
            handleCallbackUploadFile={this.handleCallbackUploadFile} handleFileSmaller100MB={this.handleFileSmaller100MB}
            trimbleKey={this.props.location.state.trimbleKey} items={this.state.items}
            progressPercent={this.state.progressPercent} /> : <div></div>}
        {this.state.error
          ? <ResultError />
          : !isConfirmed ? <ConfirmEmailMessage /> : (
            this.state.loading
              ?
              <Image src={WHLogoGif} style={{ position: 'absolute', top: 'calc(50% - 32px)', left: 'calc(50% - 32px)' }} />
              :
              (this.state.items.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ top: 'calc(50% - 20px)', position: 'absolute', left: 'calc(50% - 32px)' }} /> :
                this.state.isListCard ?
                  <div>
                    <div style={{ marginTop: 10, marginLeft: 25 }}>
                      Sort by{' '}
                      <Dropdown
                        inline onChange={this.handleSortBy}
                        options={sortByOptions} value={this.state.sortByValue}
                        defaultValue={sortByOptions[0].value}
                      />
                      <IconButton onClick={this.handleUpDown} size='xs' icon={<Icon icon={this.state.iconUpDOwn ? 'arrow-down2' : 'arrow-up2'} />} />
                      {tools()}

                    </div>
                    <Card.Group style={{ margin: "5px" }}>
                      {this.state.items.map(i =>
                        <LazyLoad >
                          <CardItems key={i.item.itemName} revision={i.item} item={i}
                            folder={this.props.location.state} image={i.img} status={i.status}
                            smartSheetId={i.smartSheetId} folderSmartSheetId={i.folderSmartSheetId} manningScheduleId={i.manningScheduleId}
                            isadmin={isAdmin} itemDisplayName={i.itemDisplayName} permission={userInfo.permission} email={userInfo.email}
                            userCreatedProject={this.props.location.state.userCreatedProject} isDisplayName={this.state.isDisplayName}
                            roleUploader={this.state.roleUploader} typeFile={i.typeFile} company={this.state.company}
                            role={this.state.role}

                            handleOpenControlVersionPanel={this.handleOpenControlVersionPanel}
                            handleOpenControlSharePanel={this.handleOpenControlSharePanel}
                            handleOpenControlSettingPanel={this.handleOpenControlSettingPanel}
                            handleDeleteItem={this.handleDeleteItem}
                            handleTranslateObject={this.handleTranslateObject}
                            folderName={this.props.location.state.folderName}
                          />
                        </LazyLoad>
                      )}

                    </Card.Group>
                  </div>
                  :
                  <div>
                    <div style={{ marginTop: 10, marginLeft: 25 }}>
                      {tools()}
                    </div>

                    <TableListItem
                      columns={ColumnItem(this, this.handleOpenControlVersionPanel)} data={this.state.items}
                      getRowProps={() => ({})} getCellProps={() => ({})}
                      folderId={this.props.location.state.folderId} email={userInfo.email}
                      permission={userInfo.permission} isAdmin={isAdmin}
                      userCreatedProject={this.props.location.state.userCreatedProject}
                      handleOpenControlVersionPanel={this.handleOpenControlVersionPanel}
                      handleOpenControlSharePanel={this.handleOpenControlSharePanel}
                      handleOpenControlSettingPanel={this.handleOpenControlSettingPanel}
                      handleDeleteItem={this.handleDeleteItem}
                      handleTranslateObject={this.handleTranslateObject}
                      roleUploader={this.state.roleUploader}
                      role={this.state.role}
                      isProjectAdmin={this.props.location.state.listAdmin.includes(this.props.userInfo.email)}
                      folderName={this.props.location.state.folderName}
                      company={this.state.company}
                    />
                  </div>

              )
          )}
        <DialogDownloadFile listDownload={this.state.listDownload} />
        <BackTop style={{ bottom: 80, right: 30 }} />







        {this.state.displaySidebarViewpoint &&
          <SidebarViewPoint displayPanel={this.state.displaySidebarViewpoint} viewer={null}
            onDisplayPanelChange={this.callbackDisplayPanel} itemId={null} projectId={this.props.location.state.projectId}
            email={this.props.userInfo.email} userCreatedProject={this.props.location.state.userCreatedProject} version={null}
            viewerPage={null} userRole={this.props.userRole} isProject={true}
          />}

        <DialogVersionItem onShow={this.state.displayDialogDownloadItem} onClose={this.handleCloseControlVersionPanel}
          onChangeVersion={this.handleChangeVersion} handleDownloadVersion={this.handleDownloadVersion}
          itemId={this.state.itemId} objectIdDefault={this.state.objectIdDefault} history={this.props.history}
          itemName={this.state.itemName}
          location={this.props.location}
        />
        <DialogShareItem onShow={this.state.displayDialogShareItem} onClose={this.handleCloseSharePanel}
          handleAddPasswordShareView={this.handleAddPasswordShareView}
          handleSetShareView={this.handleSetShareView} permission={userInfo.permission} isAdmin={isAdmin}
          itemId={this.state.itemId} objectIdDefault={this.state.objectIdDefault}
          roleUploader={this.state.roleUploader} isTrimble={this.state.isTrimble}
          itemName={this.state.itemName} email={this.props.userInfo.email} company={this.state.company} role={this.state.role} />
        <DialogSettingItem onShow={this.state.displayDialogSettingItem} onClose={this.handleCloseSettingPanel}
          handleUploadImg={this.handleUploadImg} folderId={this.props.location.state.folderId}
          itemId={this.state.itemId} objectIdDefault={this.state.objectIdDefault} itemName={this.state.itemName}
          smartSheetId={this.state.smartSheetId} folderSmartSheetId={this.state.folderSmartSheetId} manningScheduleId={this.state.manningScheduleId}
          typeFile={this.state.typeFile}
          imgItem={this.state.imgItem} loadingImg={this.state.loadingImg}
          projectId={this.props.location.state.projectId} role={this.props.userInfo.role} permission={userInfo.permission}
          email={this.props.userInfo.email} itemDisplayName={this.state.itemDisplayName} />

        <Toaster ref={this.toaster} position={Position.TOP} canEscapeKeyClear={false} />
      </React.Suspense>
    );
  }
}

FolderPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  storeFolderDetail: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    //isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  }
}
export default connect(mapStateToProps, { storeFolderDetail })(FolderPage)


