import { Button, Divider, Icon, message, Modal, Tooltip, notification, Select } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { applyActionOnCell, copyTempData, getCellModifiedTemp, overwriteCellReducer, overwriteCellsModified, setCellActive } from '../../../../../actions/dms/dms_cell';
import { fetchDataOneSheet, overwriteProjectReducer, setUserData } from '../../../../../actions/dms/dms_project';
import { getSheetRows, overwriteRowReducer } from '../../../../../actions/dms/dms_row';
import { apiFetchAllRowsFileThisType, apiFetchDataProjectByDiscipline, apiFetchPublicSettingThisProject, apiGetAllCollectionAdmin, apiFetchDataMultiByDisciplineForm, apiFetchDataThisProject, apiFetchRowHistoryThisProject, apiFetchDataMultiForm, apiDeleteRowsHistory, apiDeleteMultiFormRows, apiUpdateOrCreateRows } from '../../../api';
import { arrayStatusDrawingText, colorTextRow, colorType, navBarDmsHeight, offsetHeight, sideBarWidth, tradeArrayForm, arrayTextStatusQrQrm, arrayTextStatusRfc } from '../../../constants';
import { checkIfItIsRowLevelRfaHeader, checkIfThereIsRowInsideTreeNode, debounceFnc, filterRowsOutput, genId, getActionName, getDisciplineOfRefString, getHeaderUploadViewWidth, getHeaderWidth, getHeaderWidthForRFAView, getItemKeyNameForm, getOutputRowsWithTreeNodeFinal,getOutputRowsWithTreeNodeFinalPM, getRefStringWithVersion, getUserRoleTradeCompany, groupByHeaders, mongoObjectId, randomColorRange, randomColorRangeStatus, checkIfThereIsEmailFailure, trimAndFilterAllCompanySameShortName, getOverdueRfaInfo, getOverdueMultiFormInfo, getTabArrayNameForPage, getMessageEmailFailed, getListFormEmailFailed, trimListGroupSpace } from '../../../utils';
import Cell, { columnLocked, rowLocked } from '../pageSpreadsheet/Cell';
import CellForm, {getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import CellFormPM  from '../pageSpreadsheet/CellFormPM';
import CellIndex from '../pageSpreadsheet/CellIndex';
import CellRFA, { isColumnWithReplyData } from '../pageSpreadsheet/CellRFA';
import { convertFlattenArraytoTree1, getTreeFlattenOfNodeInArray } from '../pageSpreadsheet/FormDrawingTypeOrder';
import PanelFunction, { getPanelPosition } from '../pageSpreadsheet/PanelFunction';
import PanelSetting, { getDataForMultiFormSheet,getDataForMultiFormSheetPM, getDataForRfaSheet, getDataForRowsUploadFile, getKeyTextForSheet, updatePreRowParentRowToState, _processRowsChainNoGroupFnc1 } from '../pageSpreadsheet/PanelSetting';
import CellHeader from './CellHeader';
import { sortFnc } from './FormSort';
import IconTable from './IconTable';
import InputSearch from './InputSearch';
import LoadingIcon from './LoadingIcon';
import ViewTemplateSelect from './ViewTemplateSelect';
import ButtonAdminUploadData from '../pageSpreadsheet/ButtonAdminUploadData';
import CellUploadFile from '../pageSpreadsheet/CellUploadFile';
import PanelFunctionUploadFile from '../pageSpreadsheet/PanelFunctionUploadFile';
import IconTableFoldRow from './IconTableFoldRow';
import { socket } from '../../../../module_BimApp/function/AdminFunction';
import PanelTreeQuickFilter from './PanelTreeQuickFilter';
const { Option } = Select;




const Table = forwardRef((props, ref) => {
   const { projectIsAppliedRfaView, pageSheetTypeName } = props;

   const offsetHeightBottomTab = (pageSheetTypeName === 'page-authorities-submission' || pageSheetTypeName === 'page-rfc') ? 0 : 35;

   return (
      <AutoResizer>
         {({ width }) => {
            return <BaseTable
               {...props}
               ref={ref}
               width={window.innerWidth - (projectIsAppliedRfaView ? sideBarWidth : 0)}
               height={window.innerHeight - offsetHeight - offsetHeightBottomTab}
            />
         }}
      </AutoResizer>
   );
});

const getAuthoritiesPage = (authoritiesType) => {
   return authoritiesType.toLowerCase().replace(/\s/g, '');
};



let previousDOMCell = null;
let currentDOMCell = null;
let isTyping = false;
let addedEvent = false;



const ComponentDmsOverall = (props) => {

   let {
      localState, history,
      token, company,
      companies: companiesInput, projectIsAppliedRfaView, listUser: listUserInput, listGroup: listGroupInput, projectNameShort, pageSheetTypeName,

      stateCell, stateRow, stateProject,
      applyActionOnCell, copyTempData, getCellModifiedTemp, overwriteCellReducer, overwriteCellsModified, setCellActive,
      getSheetRows, overwriteRowReducer,
      fetchDataOneSheet, overwriteProjectReducer, setUserData,
      infoFromStore, currentWindow,
      uploadTypeArray
   } = props;


   let { email, role, projectId, projectName, isAdmin } = infoFromStore;

   let companies = trimAndFilterAllCompanySameShortName(companiesInput);

   company = (company || '').trim();


   let isSubconCanSubmit = false;
   if (companies.find(x => x['companyType'] === 'Sub-con' && x['shortName'] && x['company'] === company)) {
      isSubconCanSubmit = true;
      isAdmin = false;
      role = 'Sub-Con';
   };

   if (isAdmin) {
      company = 'Woh Hup Private Ltd';
      role = 'Document Controller';
   };

   const isAppliedTabForView = true;

   let authoritiesType, pageUploadType;
   if (pageSheetTypeName === 'page-authorities-submission' && uploadTypeArray.length > 0) {
      authoritiesType = localState.authoritiesType || uploadTypeArray[0];
      pageUploadType = getAuthoritiesPage(authoritiesType || uploadTypeArray[0]);
   };


   const roleTradeCompany = getUserRoleTradeCompany(role, company);

   listGroupInput = trimListGroupSpace(listGroupInput);

   const listUser = [...new Set(listUserInput)];
   const listGroup = [...new Set(listGroupInput)];

   const isBothSideActionUser = roleTradeCompany.role === 'Document Controller' && roleTradeCompany.company === 'Woh Hup Private Ltd';

   const { cellActive } = stateCell;

   const isUserAbleToUploadFiles = roleTradeCompany.company === 'Woh Hup Private Ltd' && (roleTradeCompany.role === 'Document Controller' || roleTradeCompany.role.includes('Coordinator'));

   const propsForProjectState = {
      email, projectId, projectName, role, token, company, companies, roleTradeCompany,
      projectIsAppliedRfaView, listUser, listGroup, projectNameShort, isAdmin, isBothSideActionUser, pageSheetTypeName,
      authoritiesType, pageUploadType, isUserAbleToUploadFiles, isSubconCanSubmit
   };


   const tableRef = useRef();

   const handlerBeforeUnload = (e) => {

      if (window.location.pathname === '/dms-test') {
         e.preventDefault();
         e.returnValue = '';

      } else if (
         window.location.pathname === '/dms/spreadsheet' ||
         window.location.pathname === '/dms/rfa' || window.location.pathname === '/dms/rfam' || window.location.pathname === '/dms/rfi' ||
         window.location.pathname === '/dms/cvi' || window.location.pathname === '/dms/dt' || window.location.pathname === '/dms/mm' ||
         // window.location.pathname === '/dms/eri' || window.location.pathname === '/dms/lr' || window.location.pathname === '/dms/sm' ||
         window.location.pathname === '/dms/qr' || window.location.pathname === '/dms/qrm' || window.location.pathname === '/dms/rfc'
      ) {
         saveSettingsBeforeClose();
      };
   };
   useEffect(() => {
      if (!addedEvent) {
         window.onbeforeunload = handlerBeforeUnload;
         addedEvent = true;
      };
   }, []);


   const saveSettingsBeforeClose = () => {


      const refType = pageSheetTypeName === 'page-spreadsheet' ? 'dms' : getKeyTextForSheet(pageSheetTypeName);

      if (stateProject && stateProject.allDataOneSheet) {

         const userSettings = stateProject.allDataOneSheet['userSettings'] || {};

         const allViewDisciplineFilter = userSettings['allViewDisciplineFilter'] || {};
         const allViewDisciplineTab = userSettings['allViewDisciplineTab'] || {};
         let allFormExpandRows = userSettings['allFormExpandRows'] || {};


         allViewDisciplineFilter[refType] = window.dms_disciplineFilterThisPage || {};
         allViewDisciplineTab[refType] = window.dms_disciplineTabThisPage || null;
         allFormExpandRows = {
            ...allFormExpandRows,
            ...window.dms_allFormExpandRows
         };

         const userSettingsUpdate = {
            allFormExpandRows,
            allViewDisciplineFilter,
            allViewDisciplineTab,
         };


         const payload = { token, projectId, email, userSettings: userSettingsUpdate };
         const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
         navigator.sendBeacon('/api/sheet/update-setting-user', blob);
      }
   };



   const getCurrentDOMCell = () => {
      isTyping = true;
      setCellActive(currentDOMCell);
   };
   const setPosition = (e) => { // just set position => highlight cell, not active
      if (previousDOMCell) {
         previousDOMCell.cell.classList.remove('cell-current');
      };
      currentDOMCell = e;
      currentDOMCell.cell.classList.add('cell-current');
      previousDOMCell = e;
      isTyping = false;
   };
   useEffect(() => {
      window.addEventListener('keydown', EventKeyDown);
      return () => window.removeEventListener('keydown', EventKeyDown);
   }, []);
   const EventKeyDown = (e) => {
      if (e.key === 'Control') return;
      if (e.key === 'ArrowUp') {
         if (isTyping || !currentDOMCell) return;
         let cellTop = currentDOMCell.cell.parentElement.offsetTop;
         if (currentDOMCell.rowIndex === 0) return;

         currentDOMCell.cell.classList.remove('cell-current');
         let index;
         currentDOMCell.cell.parentElement.childNodes.forEach((dv, i) => {
            if (dv === currentDOMCell.cell) index = i;
         });
         if (!currentDOMCell.cell.parentElement.previousSibling) return; // Scroll out of sight of cell selected
         currentDOMCell.cell = currentDOMCell.cell.parentElement.previousSibling.childNodes[index];
         currentDOMCell.cell.classList.add('cell-current');
         currentDOMCell.rowIndex = currentDOMCell.rowIndex - 1;

         e.preventDefault();
         if (cellTop <= tableRef.current._scroll.scrollTop) {
            tableRef.current.scrollToTop(currentDOMCell.cell.parentElement.offsetTop);
         };

      } else if (e.key === 'ArrowRight') {
         if (isTyping || !currentDOMCell) return;

         if (currentDOMCell.columnIndex < tableRef.current.leftTable.props.columns.length - 1) {
            currentDOMCell.cell.classList.remove('cell-current');
            currentDOMCell.cell = currentDOMCell.cell.nextSibling;
            currentDOMCell.cell.classList.add('cell-current');
            currentDOMCell.columnIndex = currentDOMCell.columnIndex + 1;

         } else if (
            currentDOMCell.columnIndex >= tableRef.current.leftTable.props.columns.length &&
            currentDOMCell.columnIndex < tableRef.current.columnManager._columns.length - 1
         ) {
            let cellRight = currentDOMCell.cell.offsetLeft + currentDOMCell.cell.offsetWidth;
            let innerTableWidth = window.innerWidth - 15;

            e.preventDefault();
            if (cellRight > innerTableWidth - 100) {
               tableRef.current.scrollToLeft(cellRight - innerTableWidth + 100);
            };

            currentDOMCell.cell.classList.remove('cell-current');
            currentDOMCell.cell = currentDOMCell.cell.nextSibling;
            currentDOMCell.cell.classList.add('cell-current');
            currentDOMCell.columnIndex = currentDOMCell.columnIndex + 1;
         };

      } else if (e.key === 'ArrowDown') {

         if (isTyping || !currentDOMCell) return;

         if (currentDOMCell.rowIndex >= Object.keys(tableRef.current._depthMap).length - 1) return;
         let cellTop = currentDOMCell.cell.parentElement.offsetTop;

         currentDOMCell.cell.classList.remove('cell-current');
         let index;
         currentDOMCell.cell.parentElement.childNodes.forEach((dv, i) => {
            if (dv === currentDOMCell.cell) index = i;
         });
         if (!currentDOMCell.cell.parentElement.nextSibling) return; // Scroll out of sight of cell selected
         currentDOMCell.cell = currentDOMCell.cell.parentElement.nextSibling.childNodes[index];
         currentDOMCell.cell.classList.add('cell-current');
         currentDOMCell.rowIndex = currentDOMCell.rowIndex + 1;

         e.preventDefault();
         if (
            cellTop < tableRef.current._scroll.scrollTop || cellTop > tableRef.current._scroll.scrollTop + 490
         ) {
            tableRef.current.scrollToTop(currentDOMCell.cell.parentElement.offsetTop - 520);
         };
      } else if (e.key === 'ArrowLeft') {
         if (isTyping || !currentDOMCell) return;

         if (currentDOMCell.columnIndex > 1 &&
            currentDOMCell.columnIndex < tableRef.current.leftTable.props.columns.length
         ) {
            currentDOMCell.cell.classList.remove('cell-current');
            currentDOMCell.cell = currentDOMCell.cell.previousSibling;
            currentDOMCell.cell.classList.add('cell-current');
            currentDOMCell.columnIndex = currentDOMCell.columnIndex - 1;

         } else if (
            currentDOMCell.columnIndex > tableRef.current.leftTable.props.columns.length &&
            currentDOMCell.columnIndex < tableRef.current.columnManager._columns.length
         ) {
            let cellLeft = currentDOMCell.cell.offsetLeft;
            let innerTableWidth = window.innerWidth - 15;

            currentDOMCell.cell.classList.remove('cell-current');
            currentDOMCell.cell = currentDOMCell.cell.previousSibling;
            currentDOMCell.cell.classList.add('cell-current');;
            currentDOMCell.columnIndex = currentDOMCell.columnIndex - 1;
            
            e.preventDefault();

            if (cellLeft <= tableRef.current.columnManager._cached.columnsWidth - innerTableWidth + 820) {
               tableRef.current.scrollToLeft(cellLeft - 820 - currentDOMCell.cell.offsetWidth);
            };
         };

      } else if (e.key === 'Enter') {
         if (!currentDOMCell) return;
         if (isTyping) isTyping = false;
         else {
            isTyping = true;
            setCellActive(currentDOMCell);
         };
      } else if (e.key === 'c' && e.ctrlKey) {
         if (isTyping || !currentDOMCell) return;
         copyTempData(currentDOMCell.cell.innerText);
      } else {
         if (isTyping || !currentDOMCell) return;
         applyActionOnCell({ currentDOMCell, e });
      };
   };
   const onScroll = () => {
      if (cellActive) setCellActive(null);
   };




   // useEffect(() => console.log('STATE-CELL...', stateCell), [stateCell]);
   // useEffect(() => console.log('STATE-ROW...', stateRow), [stateRow]);
   // useEffect(() => console.log('STATE-PROJECT...', stateProject), [stateProject]);



   const rowEventHandlers = {
      onDoubleClick: (props) => {
         const { rowKey, rowData } = props;

         // console.log('ROW-DATA: ', rowData);

         setBtnSetFoldAllRows('-');

         if (rowData.children && rowData.children.length > 0) {

            let expanded = expandedRows.indexOf(rowKey) === -1;
            let arr = [...expandedRows];
            if (expanded) {
               arr.push(rowKey);
            } else {
               arr = arr.filter(id => id !== rowKey);
            };
            setExpandedRows(arr);
         };
      },
   };

   const [cursor, setCursor] = useState(null);
   const [panelType, setPanelType] = useState(null);
   const [panelSettingType, setPanelSettingType] = useState(null);
   const [panelFunctionVisible, setPanelFunctionVisible] = useState(false);
   const [panelSettingVisible, setPanelSettingVisible] = useState(false);


   const buttonPanelFunction = (btn) => {
      let { rowsAll, rowsUpdatePreRowOrParentRow, rowsSelected, rowsSelectedToMove } = stateRow;

      setPanelFunctionVisible(false);
      setCellHistoryFound(null);

      if (btn === 'Move Drawings') {
         if (stateRow.rowsSelected.length > 0) {
            getSheetRows({ rowsSelectedToMove: [...rowsSelected] });
         } else {
            const row = rowsAll.find(x => x.id === panelType.cellProps.rowData.id);
            getSheetRows({ rowsSelectedToMove: [row] });
         };
      } else if (btn === 'Paste Drawings') {
         const { rowData } = panelType.cellProps;
         if (
            rowsSelectedToMove.find(x => x.id === rowData.id) ||
            (rowData.treeLevel && rowsSelectedToMove.find(x => x._parentRow === rowData.id))
         ) {
            getSheetRows({ rowsSelectedToMove: [], rowsSelected: [] });
         } else {
            rowsSelectedToMove.forEach(row => {
               const rowBelowPrevious = rowsAll.find(r => r._preRow === row.id);
               if (rowBelowPrevious) {
                  rowBelowPrevious._preRow = row._preRow;
                  updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelowPrevious);
               };
            });
            if (rowData.treeLevel) {
               const lastRowOfBranch = rowsAll.find(r => r._parentRow === rowData.id && !rowsAll.find(x => x._preRow === r.id));
               rowsSelectedToMove.forEach((row, i) => {
                  row._preRow = i === 0 ? (lastRowOfBranch ? lastRowOfBranch.id : null) : rowsSelectedToMove[i - 1].id;
                  row._parentRow = rowData.id;
                  updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
               });
            } else {
               const rowBelowNext = rowsAll.find(r => r._preRow === rowData.id);
               if (rowBelowNext) {
                  rowBelowNext._preRow = stateRow.rowsSelectedToMove[stateRow.rowsSelectedToMove.length - 1].id;
                  updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelowNext);
               };
               rowsSelectedToMove.forEach((row, i) => {
                  row._preRow = i === 0 ? rowData.id : rowsSelectedToMove[i - 1].id;
                  row._parentRow = rowData._parentRow;
                  updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
               });
            };
            const rowsOutput = _processRowsChainNoGroupFnc1([...rowsAll]);

            getSheetRows({
               rowsAll: rowsOutput,
               rowsUpdatePreRowOrParentRow,
               rowsSelectedToMove: [],
               rowsSelected: [],
               modeFilter: [],
               modeSort: {}
            });
         };

      } else {
         setCellActive(null);
         copyTempData(null);
         applyActionOnCell(null);

         setPanelSettingType(btn);
         setPanelSettingVisible(true);

         if (pageSheetTypeName === 'page-spreadsheet') {

            const objAttr = (btn === 'Date Automation' || btn === 'Move Drawings') ? {} : { rowsSelected: [] };

            getSheetRows({
               ...objAttr,
               rowsSelectedToMove: [],
            });
         } else if (pageSheetTypeName === 'page-authorities-submission') {
            // do nothing...
         };
      };
      setCellActive(null);
      if (currentDOMCell) currentDOMCell.cell.classList.remove('cell-current');
      currentDOMCell = null;
   };
   const onMouseDownColumnHeader = (e, header) => {
      // setCursor({ x: e.clientX, y: e.clientY });
      // setPanelType({ type: 'column', header });
      // setPanelFunctionVisible(true);
   };
   const onRightClickCell = (e, cellProps) => {
      setCursor({ x: e.clientX, y: e.clientY });
      setPanelType({ type: 'cell', cellProps });
      setPanelFunctionVisible(true);
   };
   const commandAction = (update) => {
      if (
         update.type === 'add-view-templates' || update.type === 'sort-data' || update.type === 'filter-by-columns'
      ) {
         getSheetRows(update.data);

      } else if (
         update.type === 'insert-drawings' || update.type === 'insert-drawings-by-folder' ||
         update.type === 'duplicate-drawings' || update.type === 'delete-drawing' ||
         update.type === 'drawing-data-automation' || update.type === 'create-new-drawing-revisions'
      ) {
         getSheetRows({
            ...update.data,
            modeFilter: [],
            modeSort: {},
            rowsSelected: [],
         });

      } else if (update.type === 'reset-filter-sort') {
         getSheetRows(update.data);
         setCellSearchFound(null);

         if (pageSheetTypeName !== 'page-spreadsheet') {
            setSearchInputShown(false);
         };

      } else if (update.type === 'reorder-columns' || update.type === 'drawing-colorized') {
         setUserData({ ...stateProject.userData, ...update.data });

      } else if (update.type === 'drawing-folder-organization') {
         getSheetRows(update.data);
         // setExpandedRows(getRowsKeyExpanded(update.data.drawingTypeTree, stateRow.viewTemplateNodeId)); // TEMP_HIDE_TEMPLATE
         setExpandedRows(getRowsKeyExpanded(update.data.drawingTypeTree, null));

      } else if (update.type === 'group-columns') {
         getSheetRows(update.data);

      } else if (update.type === 'highlight-cell-history') {
         setCellHistoryFound(update.data);
         setUserData({ ...stateProject.userData, nosColumnFixed: stateProject.userData.nosColumnFixed + 1 });
         setUserData({ ...stateProject.userData, nosColumnFixed: stateProject.userData.nosColumnFixed });


      } else if (update.type === 'reload-data-view-rfa') {

         const { dataDmsFetched, dataRowHistoryDmsFetched, dataRfaFetched } = update.data;

         getSheetRows(getInputDataInitially({
            sheetData: dataDmsFetched,
            rowsHistoryData: dataRowHistoryDmsFetched,
            rfaData: dataRfaFetched,
         }, pageSheetTypeName));

         fetchDataOneSheet({ ...dataDmsFetched, ...propsForProjectState });


         overwriteCellsModified({});
         setCellActive(null);
         setLoading(false);
         setSearchInputShown(true);

      } else if (update.type === 'reload-data-view-multi-form') {

         const { dataMultiFormFetched: rowsData, userSettings } = update.data;

         getSheetRows(getInputDataInitially({ rowsData, userSettings }, pageSheetTypeName));

         overwriteCellsModified({});
         setCellActive(null);
         setLoading(false);
         setSearchInputShown(true);


      } else if (update.type === 'reload-data-view-upload-files') {

         const projectSetting = update.data.publicSettings[0];
         const folderTreeFormUpload = projectSetting.folderTreeFormUpload || {};
         const folderTreeThisTypeUpload = folderTreeFormUpload[pageUploadType] || [];

         const dataInput = getInputDataInitially({ rowsFile: update.data.rowsAllUploadFiles, folderTree: folderTreeThisTypeUpload }, pageSheetTypeName);
         getSheetRows(dataInput);

         fetchDataOneSheet({
            ...propsForProjectState
         });
         setExpandedRows(folderTreeThisTypeUpload.map(x => x.id));

         overwriteCellsModified({});
         setCellActive(null);
         setLoading(false);

         setCellSearchFound(null);
         setCellHistoryFound(null);


      } else if (update.type === 'save-data-successfully') {
         message.success('Save Data Successfully', 1.5);
      } else if (update.type === 'save-data-failure') {
         message.error('Network Error', 1.5);
         setLoading(false);
      } else if (update.type === 'reload-data-from-server') {

         const { dataDmsFetched, dataRfaFetched } = update.data;


         setExpandedRows(getRowsKeyExpanded(
            dataDmsFetched.publicSettings.drawingTypeTree,
            // sheetData.userSettings ? sheetData.userSettings.viewTemplateNodeId : null // TEMP_HIDE_TEMPLATE
            null
         ));

         getSheetRows(getInputDataInitially({ sheetData: dataDmsFetched, rfaData: dataRfaFetched }, pageSheetTypeName));

         fetchDataOneSheet({
            ...dataDmsFetched,
            ...propsForProjectState
         });
         setUserData(getHeadersData(dataDmsFetched));


         overwriteCellsModified({});
         setCellActive(null);
         setLoading(false);

         setCellSearchFound(null);
         setCellHistoryFound(null);

      };

      if (update.type !== 'save-data-failure') {
         resetAllPanelInitMode();
      };
   };
   const resetAllPanelInitMode = () => {
      setPanelSettingVisible(false);
      setPanelSettingType(null);
      setPanelType(null);
   };


   const [adminFncInitPanel, setAdminFncInitPanel] = useState(false);
   const [adminFncBtn, setAdminFncBtn] = useState(null);
   const adminFncServerInit = (btn) => {
      setAdminFncInitPanel(true);

      setAdminFncBtn(btn);
   };

   const adminFnc = async (btn) => {
      try {
         if (btn === 'delete-all-collections') {

            await Promise.all([
        
            ]);

            message.info('DONE...Delete All Data In Every DB Collections');
         };
      } catch (err) {
         console.log(err);
      };
   };


   useEffect(() => {
      socket.on('dms-socket-email-fail', (data) => {

         const { emailSender, isSuccess, formSubmitType, type } = data;
         if (email === emailSender) {
            if (isSuccess) {
               // message.success(`${formSubmitType.toUpperCase()} successfully send email...`, 2);
            } else {
               // notification.warn({
               //    message: 'Email failed to send out',
               //    placement: 'bottomRight',
               //    bottom: 5,
               //    description: type === 'forward-email-to-subcon' ? 'DT form failed to forward email, please refresh the page and try again.'
               //       : `${formSubmitType.toUpperCase()} failed to send email, please refresh the page and click mail button at ${formSubmitType.toUpperCase()} cell to resend`,
               //    style: {
               //       width: 600,
               //       marginLeft: -210,
               //       color: 'black',
               //       fontWeight: 'bold'
               //    },
               //    duration: 0,
               // });
            };
         };
      });
   }, []);



   const [pageTabDiscipline, setPageTabDiscipline] = useState(null);
   const [arrayTabDiscipline, setArrayTabDiscipline] = useState([]);

   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const fetchOneProject = async () => {
         try {
            setLoading(true);


            if (pageSheetTypeName === 'page-spreadsheet') {

               let res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isIncludedRfaFetched: true }); // TEST_APPY_TAB_VIEW

               const { dataDmsFetched, dataRfaFetched } = res.data;

               setExpandedRows(getRowsKeyExpanded(
                  dataDmsFetched.publicSettings.drawingTypeTree,
                  // dataDmsFetched.userSettings ? dataDmsFetched.userSettings.viewTemplateNodeId : null // TEMP_HIDE_TEMPLATE
                  null
               ));

               setArrayTabDiscipline(['ARCHI', 'C&S', 'M&E', 'PRECAST', 'ARCHI (SUBCON)', 'C&S (SUBCON)', 'M&E (SUBCON)', 'PRECAST (SUBCON)', 'ALL']);

               const allViewDisciplineTab = (dataDmsFetched.userSettings || {})['allViewDisciplineTab'] || {};
               const allViewDisciplineFilter = (dataDmsFetched.userSettings || {})['allViewDisciplineFilter'] || {};


               const tabName = pageTabDiscipline || allViewDisciplineTab['dms'] || 'ALL';
               window.dms_disciplineTabThisPage = tabName;
               window.dms_disciplineFilterThisPage = window.dms_disciplineFilterThisPage || allViewDisciplineFilter['dms'] || {};

               getSheetRows(getInputDataInitially({ sheetData: dataDmsFetched, rfaData: dataRfaFetched }, pageSheetTypeName));
               fetchDataOneSheet({
                  ...dataDmsFetched,
                  ...propsForProjectState
               });
               setUserData(getHeadersData(dataDmsFetched));


            } else if (pageSheetTypeName === 'page-rfa') {

               // isNoNeedSortRows must be TRUE =>>> in tab discipline => can not sort order... =>> missing row
               const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW

               const { dataDmsFetched, dataRowHistoryDmsFetched, dataRfaFetched } = res.data;

               // CHECK EMAIL FAILED
               const listFormFailToSendEmail = getListFormEmailFailed(dataRfaFetched, email, 'rfa');
               if (listFormFailToSendEmail.length > 0) {
                  notification.warn(getMessageEmailFailed(listFormFailToSendEmail, 'rfa'));
               };


               const userSettings = dataDmsFetched.userSettings || {};
               const publicSettings = dataDmsFetched.publicSettings || {};


               const allViewDisciplineTab = userSettings['allViewDisciplineTab'] || {};
               const allViewDisciplineFilter = userSettings['allViewDisciplineFilter'] || {};
               const allFormExpandRows = userSettings['allFormExpandRows'] || {};



               let disciplineFormData = publicSettings['disciplineForm'] || [];
               disciplineFormData = getTabArrayNameForPage(disciplineFormData, 'rfa');

               setArrayTabDiscipline([...disciplineFormData.sort(), 'ALL']);

               const tabName = pageTabDiscipline || allViewDisciplineTab['rfa'] || 'ALL';
               window.dms_disciplineTabThisPage = tabName;
               window.dms_disciplineFilterThisPage = window.dms_disciplineFilterThisPage || allViewDisciplineFilter['rfa'] || {};

               const expandedRowsArrayIds = (window.dms_allFormExpandRows || {})['rfa'] || allFormExpandRows['rfa'] || {};
               window.dms_allFormExpandRows = window.dms_allFormExpandRows || allFormExpandRows;
               setExpandedRows(expandedRowsArrayIds[tabName] || []);



               getSheetRows(getInputDataInitially({
                  sheetData: dataDmsFetched,
                  rowsHistoryData: dataRowHistoryDmsFetched,
                  rfaData: dataRfaFetched,
               }, pageSheetTypeName));

               fetchDataOneSheet({ ...dataDmsFetched, ...propsForProjectState });

               setSearchInputShown(true);

            } else if (pageSheetTypeName === "page-eri" ) 
            {
              const refType = "pm";
              const res = await apiFetchDataMultiByDisciplineForm(refType, {
                token,
                projectId,
                email,
              }); // TEST_APPY_TAB_VIEW
              const {
                dataMultiFormFetched: rowsData,
                userSettings,
                publicSettings,
              } = res.data;
              const allFormExpandRows = userSettings["allFormExpandRows"] || {};
              const allViewDisciplineTab =
                userSettings["allViewDisciplineTab"] || {};
              const allViewDisciplineFilter =
                userSettings["allViewDisciplineFilter"] || {};
              let disciplineFormData = publicSettings["disciplineForm"] || [];
              disciplineFormData = getTabArrayNameForPage(
                disciplineFormData,
                refType
              );
              setArrayTabDiscipline([...disciplineFormData.sort(), "ALL"]);
    
              const tabName =
                pageTabDiscipline || allViewDisciplineTab['eri'] || "ALL";
              window.dms_disciplineTabThisPage = tabName;
              window.dms_disciplineFilterThisPage =
                window.dms_disciplineFilterThisPage ||
                allViewDisciplineFilter['eri'] ||
                {};
    
              const expandedRowsArrayIds = (window.dms_allFormExpandRows || {})['eri'] ||allFormExpandRows['eri'] ||{};
              window.dms_allFormExpandRows =
                window.dms_allFormExpandRows || allFormExpandRows;
    
              setExpandedRows(expandedRowsArrayIds[tabName] || []);
    
              getSheetRows(
                getInputDataInitially({ rowsData, userSettings }, pageSheetTypeName)
              );
    
              fetchDataOneSheet({
                publicSettings,
                userSettings,
                ...propsForProjectState,
              });
              setSearchInputShown(true);
            } else if(pageSheetTypeName === "page-lr"){
              const refType = "pm";
              const res = await apiFetchDataMultiByDisciplineForm(refType, {
                token,
                projectId,
                email,
              }); // TEST_APPY_TAB_VIEW
              const {
                dataMultiFormFetched: rowsData,
                userSettings,
                publicSettings,
              } = res.data;
              const allFormExpandRows = userSettings["allFormExpandRows"] || {};
              const allViewDisciplineTab =
                userSettings["allViewDisciplineTab"] || {};
              const allViewDisciplineFilter =
                userSettings["allViewDisciplineFilter"] || {};
              let disciplineFormData = publicSettings["disciplineForm"] || [];
              disciplineFormData = getTabArrayNameForPage(
                disciplineFormData,
                refType
              );
              setArrayTabDiscipline([...disciplineFormData.sort(), "ALL"]);
    
              const tabName =
                pageTabDiscipline || allViewDisciplineTab['lr'] || "ALL";
              window.dms_disciplineTabThisPage = tabName;
              window.dms_disciplineFilterThisPage =
                window.dms_disciplineFilterThisPage ||
                allViewDisciplineFilter['lr'] ||
                {};
    
              const expandedRowsArrayIds = (window.dms_allFormExpandRows || {})['lr'] ||allFormExpandRows['lr'] ||{};
              window.dms_allFormExpandRows =
                window.dms_allFormExpandRows || allFormExpandRows;
    
              setExpandedRows(expandedRowsArrayIds[tabName] || []);
    
              getSheetRows(
                getInputDataInitially({ rowsData, userSettings }, pageSheetTypeName)
              );
    
              fetchDataOneSheet({
                publicSettings,
                userSettings,
                ...propsForProjectState,
              });
              setSearchInputShown(true);
            }else if( pageSheetTypeName === "page-sm")
            {
              const refType = "pm";
              const res = await apiFetchDataMultiByDisciplineForm(refType, {
                token,
                projectId,
                email,
              }); // TEST_APPY_TAB_VIEW
              const {
                dataMultiFormFetched: rowsData,
                userSettings,
                publicSettings,
              } = res.data;
              const allFormExpandRows = userSettings["allFormExpandRows"] || {};
              const allViewDisciplineTab =
                userSettings["allViewDisciplineTab"] || {};
              const allViewDisciplineFilter =
                userSettings["allViewDisciplineFilter"] || {};
              let disciplineFormData = publicSettings["disciplineForm"] || [];
              disciplineFormData = getTabArrayNameForPage(
                disciplineFormData,
                refType
              );
              setArrayTabDiscipline([...disciplineFormData.sort(), "ALL"]);
    
              const tabName =
              pageTabDiscipline || allViewDisciplineTab['sm'] || "ALL";
            window.dms_disciplineTabThisPage = tabName;
            window.dms_disciplineFilterThisPage =
              window.dms_disciplineFilterThisPage ||
              allViewDisciplineFilter['sm'] ||
              {};
    
            const expandedRowsArrayIds = (window.dms_allFormExpandRows || {})['sm'] ||allFormExpandRows['sm'] ||{};
            window.dms_allFormExpandRows =
              window.dms_allFormExpandRows || allFormExpandRows;
    
              setExpandedRows(expandedRowsArrayIds[tabName] || []);
    
              getSheetRows(
                getInputDataInitially({ rowsData, userSettings }, pageSheetTypeName)
              );
    
              fetchDataOneSheet({
                publicSettings,
                userSettings,
                ...propsForProjectState,
              });
              setSearchInputShown(true);
            }else if (
               pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' ||
               pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' ||
               pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
            ) {

               if (pageSheetTypeName === 'page-rfc') {
                  console.log('companies_input_dms', companiesInput.map(x => ({ ...x })));
               };

               const refType = getKeyTextForSheet(pageSheetTypeName);

               const res = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email }); // TEST_APPY_TAB_VIEW

               const { dataMultiFormFetched: rowsData, userSettings, publicSettings } = res.data;


               // CHECK EMAIL FAILED
               const listFormFailToSendEmail = getListFormEmailFailed(rowsData, email, refType);
               if (listFormFailToSendEmail.length > 0) {
                  notification.warn(getMessageEmailFailed(listFormFailToSendEmail, refType));
               };

               const allFormExpandRows = userSettings['allFormExpandRows'] || {};
               const allViewDisciplineTab = userSettings['allViewDisciplineTab'] || {};
               const allViewDisciplineFilter = userSettings['allViewDisciplineFilter'] || {};


               let disciplineFormData = publicSettings['disciplineForm'] || [];
               disciplineFormData = getTabArrayNameForPage(disciplineFormData, refType);
               setArrayTabDiscipline([...disciplineFormData.sort(), 'ALL']);


               const tabName = pageTabDiscipline || allViewDisciplineTab[refType] || 'ALL';
               window.dms_disciplineTabThisPage = tabName;
               window.dms_disciplineFilterThisPage = window.dms_disciplineFilterThisPage || allViewDisciplineFilter[refType] || {};


               const expandedRowsArrayIds = (window.dms_allFormExpandRows || {})[refType] || allFormExpandRows[refType] || {};
               window.dms_allFormExpandRows = window.dms_allFormExpandRows || allFormExpandRows;

               setExpandedRows(expandedRowsArrayIds[tabName] || []);


               getSheetRows(getInputDataInitially({ rowsData, userSettings }, pageSheetTypeName));

               fetchDataOneSheet({ publicSettings, userSettings, ...propsForProjectState });
               setSearchInputShown(true);

            } else if (pageSheetTypeName === 'page-authorities-submission') {

               if (authoritiesType && pageUploadType) {

                  let [resSettings, res] = await Promise.all([
                     apiFetchPublicSettingThisProject({ token, projectId }),
                     apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
                  ]);

                  const projectSetting = resSettings.data[0];
                  const folderTreeFormUpload = projectSetting.folderTreeFormUpload || {};
                  const folderTreeThisTypeUpload = folderTreeFormUpload[pageUploadType] || [];

                  getSheetRows(getInputDataInitially({ rowsFile: res.data, folderTree: folderTreeThisTypeUpload }, pageSheetTypeName));

                  fetchDataOneSheet({
                     ...propsForProjectState
                  });
                  setExpandedRows(folderTreeThisTypeUpload.map(x => x.id));
               };
            };

            setLoading(false);
         } catch (err) {
            console.log(err);
         };
      };
      fetchOneProject();


      return () => {
         overwriteRowReducer();
         overwriteCellReducer();
         overwriteProjectReducer();
         if (
            pageSheetTypeName === 'page-spreadsheet' ||
            pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' ||
            pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' ||
            // pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm' ||
            pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
         ) {
            saveSettingsBeforeClose();
         };



         // CHECK IF CHANGE PAGE (NOT TAB) =>>> NEED TO RESET BELOW
         const { location: { pathname } } = history;
         const pathnameParts = pathname.split('/');
         const nextPage = pathnameParts[pathnameParts.length - 1];
         const currentPage = pageSheetTypeName === 'page-spreadsheet' ? 'spreadsheet' : getKeyTextForSheet(pageSheetTypeName);

         if (currentPage !== nextPage) {
            window.dms_tempAllFormDataForDtMigrate = null;
            window.dms_tempDmsDataRfaSubmission = null;
            window.dms_disciplineFilterThisPage = null;
            window.dms_allFormExpandRows = null;
            window.dms_disciplineTabThisPage = null;
         };
      };
   }, [pageUploadType, pageTabDiscipline]);








   // TEMP_HIDE_TEMPLATE
   // const updateExpandedRowIdsArray = (viewTemplateNodeId) => {
   //    setExpandedRows(getRowsKeyExpanded(stateRow.drawingTypeTree, viewTemplateNodeId));
   // };


   const [expandedRows, setExpandedRows] = useState([]);
   const [btnSetFoldAllRows, setBtnSetFoldAllRows] = useState('-');
   const onClickChangeRowsFold = () => {

      const { drawingTypeTree } = stateRow;

      let iconsArrayBtn = pageSheetTypeName === 'page-rfa' ? ['0', '1', '2', '3'] : ['0', '1', '2'];
      let nextBtn;
      if (btnSetFoldAllRows === '-') {
         nextBtn = iconsArrayBtn[0];
      } else {
         let indexOfThisBtn = iconsArrayBtn.indexOf(btnSetFoldAllRows);
         if (indexOfThisBtn !== iconsArrayBtn.length - 1) {
            nextBtn = iconsArrayBtn[indexOfThisBtn + 1];
         } else {
            nextBtn = '0';
         };
      };
      if (nextBtn) {
         setBtnSetFoldAllRows(nextBtn);
         const filteredNodesId = drawingTypeTree
            .filter(node => node['treeLevel'] < parseInt(nextBtn) + 2)
            .map(x => x.id);

         setExpandedRows(filteredNodesId);
      };
   };

   useEffect(() => {
      window.expandedRows = expandedRows;

      if (window.dms_disciplineTabThisPage) { // do not trigger this when first time go to page

         const tabName = window.dms_disciplineTabThisPage;

         const refType = pageSheetTypeName === 'page-spreadsheet' ? 'dms' : getKeyTextForSheet(pageSheetTypeName);
         const allFormExpandRows = window.dms_allFormExpandRows || {};


         if (typeof allFormExpandRows[refType] === 'object' && !(allFormExpandRows[refType] instanceof Array)) { // Make sure is an object not
            allFormExpandRows[refType] = allFormExpandRows[refType];
         } else {
            allFormExpandRows[refType] = {};
         };

         allFormExpandRows[refType][tabName] = expandedRows;
         window.dms_allFormExpandRows = allFormExpandRows;
      };


   }, [expandedRows]);

   const [cellSearchFound, setCellSearchFound] = useState(null);
   const [cellHistoryFound, setCellHistoryFound] = useState(null);

   const onRowExpand = (props) => {
      const { rowKey, expanded } = props;
      let arr = [...expandedRows];
      if (expanded) {
         arr.push(rowKey);
      } else {
         arr = arr.filter(id => id !== rowKey);
      };
      setExpandedRows(arr);
   };
   const ExpandIcon = (props, i) => {
      const { expanding, expandable, onExpand, depth } = props;
      const indent = (depth * 17).toString() + 'px';

      return (
         <div
            style={{
               marginLeft: indent, paddingLeft: expandable ? 10 : 13 + 10,
               paddingRight: 3, background: 'transparent',
            }}
         >
            {expandable && (
               <Icon
                  type={expanding ? 'plus-square' : 'minus-square'}
                  style={{ color: 'black', transform: 'translate(0, -4px)' }}
                  onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     setBtnSetFoldAllRows('-');
                     onExpand(expanding);
                  }}
               />
            )}
         </div>
      );
   };
   const expandIconProps = (props) => {
      const { expanded } = props;
      return ({ expanding: !expanded });
   };

   const rowClassName = (props) => {
      const { rowsSelected, modeGroup, drawingTypeTree } = stateRow;
      const { rowData } = props;

      if (rowData['_rowLevel'] <= 0 && rowData['count'] && pageSheetTypeName === 'page-spreadsheet') {
         return 'row-drawing-type';
      } else if (rowData.treeLevel) {
         if (pageSheetTypeName === 'page-spreadsheet') {
            return 'row-drawing-type';
         } else {
            const isRowLevelRfaHeader = checkIfItIsRowLevelRfaHeader(rowData);
            if (!isRowLevelRfaHeader) {
               return 'row-drawing-type-dark';
            } else {
               return 'row-drawing-type';
            };
         };
      };

      let isRowLocked;
      if (pageSheetTypeName === 'page-spreadsheet') {
         isRowLocked = rowLocked(roleTradeCompany, rowData, modeGroup, drawingTypeTree);
         if (isRowLocked) {
            return 'row-locked';
         };
      };

      if (rowsSelected && rowsSelected.find(x => x.id === rowData.id)) {
         return 'row-selected';
      };

      if (stateProject.userData) {
         const { colorization } = stateProject.userData;
         const valueArr = colorization.value;
         const value = rowData[colorization.header];
         if (colorization !== null && colorization.header !== 'No Colorization' &&
            valueArr && valueArr.length > 0 && valueArr.indexOf(value) !== -1
         ) {
            if (rowData[colorization.header]) {
               return `colorization-${colorization.header.replace(/\s/g, '').replace(/,/g, '')}-${rowData[colorization.header].replace(/\s/g, '').replace(/,/g, '')}-styled`;
            };
         };
      };
   };


   const [searchInputShown, setSearchInputShown] = useState(false);
   const searchGlobal = debounceFnc((textSearch) => {

      if (pageSheetTypeName === 'page-spreadsheet') {
         let searchDataObj = {};
         if (textSearch) {
            stateRow.rowsAll.forEach(row => {
               let obj = {};
               Object.keys(row).forEach(key => {
                  if (
                     key !== 'id' && key !== '_preRow' && key !== '_parentRow' &&
                     !key.includes('submission-') && !key.includes('reply-') && key !== 'rfaNumber' &&
                     row[key] &&
                     row[key].toString().toLowerCase().includes(textSearch.toLowerCase())
                  ) {
                     obj[row.id] = [...obj[row.id] || [], key];
                  };
               });
               if (Object.keys(obj).length > 0) searchDataObj = { ...searchDataObj, [row.id]: obj[row.id] };
            });
         };
         setCellSearchFound(searchDataObj);
         setUserData({ ...stateProject.userData, nosColumnFixed: stateProject.userData.nosColumnFixed + 1 });
         setUserData({ ...stateProject.userData, nosColumnFixed: stateProject.userData.nosColumnFixed });

         getSheetRows({ modeSearch: { searchDataObj, isFoundShownOnly: 'show all' } });

      } else if (pageSheetTypeName === 'page-rfa') {

         const arrayKeySearchRow = ['RFA Ref', 'Rev', 'Drawing Number', 'Drawing Name'];
         const arrayKeySearchRfa = ['requestedBy', '-date-', '-due-'];

         let searchDataObj = {};
         if (textSearch) {
            const { rowsRfaAll, rfaData } = stateRow;
            (rowsRfaAll || []).forEach(row => {
               let obj = {};

               Object.keys(row).forEach(key => {
                  if (arrayKeySearchRow.find(x => key === x) && row[key]) {
                     if (row[key].toString().toLowerCase().includes(textSearch.toLowerCase())) {
                        obj[row.id] = [...obj[row.id] || [], key];
                     };
                  };
               });

               const rfaFound = rfaData.find(rfa => row['RFA Ref'] === getRefStringWithVersion(rfa, 'rfa'));
               if (rfaFound) {
                  Object.keys(rfaFound).forEach(key => {
                     if (arrayKeySearchRfa.find(x => key.includes(x)) && rfaFound[key]) {
                        let value = rfaFound[key];
                        if (key.includes('-date-') || key.includes('-due-')) {
                           value = moment(rfaFound[key]).format('DD/MM/YY');
                        };

                        if (value.toString().toLowerCase().includes(textSearch.toLowerCase())) {
                           obj[row.id] = [...obj[row.id] || [], key];
                        };
                     };
                  });
               };

               if (Object.keys(obj).length > 0) {
                  searchDataObj = { ...searchDataObj, [row.id]: obj[row.id] };
               };
            });
         };
         setCellSearchFound(searchDataObj);
         setNosColumnFixedRfaView(1);
         setNosColumnFixedRfaView(0);

         getSheetRows({ modeSearch: { searchDataObj, isFoundShownOnly: 'show found only' } });

      } else if (
         pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' ||
         pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm' ||
         pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'qrm' ||
         pageSheetTypeName === 'page-rfc'
      ) {

         const refType = getKeyTextForSheet(pageSheetTypeName);

         const additionalField = (refType === 'rfi' || refType === 'dt')
            ? ['emailTitle']
            : [];

         const arrayKeySearch = [
            'description', 'requestedBy', '-date-', '-due-', 'contractSpecification', 'proposedSpecification', 'submissionType',
            'conversationAmong', 'herewithForDt', 'transmittedForDt', 'RFA Ref', `${refType}Ref`,
            'dateConversation', 'timeConversation',
            ...additionalField
         ];

         let searchDataObj = {};
         if (textSearch) {
            (stateRow.rowsFormAll || []).forEach(row => {
               let obj = {};

               Object.keys(row).forEach(key => {
                  if (arrayKeySearch.find(x => key.includes(x)) && row[key]) {
                     let value = row[key];
                     if (key.includes('-date-') || key.includes('-due-') || key.includes('dateConversation')) {
                        value = moment(row[key]).format('DD/MM/YY')
                     } else if (key.includes('timeConversation')) {
                        value = moment(row[key]).format('HH:mm');
                     };

                     if (value.toString().toLowerCase().includes(textSearch.toLowerCase())) {
                        obj[row.id] = [...obj[row.id] || [], key];
                     };
                  };
               });

               if (Object.keys(obj).length > 0) {
                  searchDataObj = { ...searchDataObj, [row.id]: obj[row.id] };
               };
            });
         };
         setCellSearchFound(searchDataObj);
         setNosColumnFixedRfaView(1);
         setNosColumnFixedRfaView(0);

         getSheetRows({ modeSearch: { searchDataObj, isFoundShownOnly: 'show all' } });

      } else if (pageSheetTypeName === 'page-authorities-submission') {

         const arrayKeySearch = ['fileName', 'createdAt', 'email'];

         let searchDataObj = {};

         if (textSearch) {
            (stateRow.rowsFileAll || []).forEach(row => {
               let obj = {};
               Object.keys(row).forEach(key => {
                  if (arrayKeySearch.indexOf(key) !== -1 && row[key]) {

                     let value = row[key];

                     if (key === 'createdAt') {
                        value = moment(row[key]).format('DD/MM/YY');
                     };
                     if (value.toString().toLowerCase().includes(textSearch.toLowerCase())) {
                        obj[row.id] = [
                           ...obj[row.id] || [],
                           key === 'fileName' ? 'File Name'
                              : key === 'createdAt' ? 'Date'
                                 : 'Uploaded By'
                        ];
                     };
                  };
               });
               if (Object.keys(obj).length > 0) {
                  searchDataObj = { ...searchDataObj, [row.id]: obj[row.id] };
               };
            });
         };
         setCellSearchFound(searchDataObj);
         setNosColumnFixedRfaView(1);
         setNosColumnFixedRfaView(0);

         getSheetRows({ modeSearch: { searchDataObj, isFoundShownOnly: 'show all' } });
      };
   }, 400);


   const clearAllFilterSortSearchGroup = debounceFnc(() => {
      setCellHistoryFound(null);
      getSheetRows({ modeFilter: [], modeSort: {}, modeSearch: {}, modeGroup: [] });

      if (pageSheetTypeName === 'page-spreadsheet' || pageSheetTypeName === 'page-authorities-submission') {
         setSearchInputShown(false);
      };
      setCellSearchFound(null);
   }, 1);



   const renderColumns = (arr, nosColumnFixed) => {

      let headersObj = [{
         key: 'Index', dataKey: 'Index', title: '', width: 50,
         frozen: pageSheetTypeName === 'page-spreadsheet' ? Column.FrozenDirection.LEFT : undefined,
         style: { padding: 0 },
         cellRenderer: (
            <CellIndex
               setCellActive={setCellActive}
               getSheetRows={getSheetRows}
               stateRow={stateRow}
               stateProject={stateProject}
            />
         ),
      }];

      let AdditionalHeadersForProjectRFA = [];
      if (projectIsAppliedRfaView && pageSheetTypeName === 'page-spreadsheet') {
         AdditionalHeadersForProjectRFA = [...headersConsultantWithNumber];
      };
      let headerArrayForTable = [...arr, ...AdditionalHeadersForProjectRFA];
      headerArrayForTable = headerArrayForTable.filter(hd => hd !== 'Drawing');


      if (projectIsAppliedRfaView && pageSheetTypeName === 'page-spreadsheet') {
         const indexOfConstructionIssuanceDate = headerArrayForTable.indexOf('Construction Issuance Date');
         if (indexOfConstructionIssuanceDate !== -1) {
            headerArrayForTable.splice(indexOfConstructionIssuanceDate + 1, 0, ...['Issue For Construction', 'IFC Rev', 'IFC Date']); // TEMP_FIX_ADD_ISSUSE_FOR_CONSTRUCTION_COLUMN
         };
      };



      headerArrayForTable.forEach((hd, index) => {
         headersObj.push({
            key: hd, dataKey: hd, title: hd,
            width: pageSheetTypeName === 'page-authorities-submission' ? getHeaderUploadViewWidth(hd)
               : pageSheetTypeName !== 'page-spreadsheet' ? getHeaderWidthForRFAView(hd, pageSheetTypeName)
                  : getHeaderWidth(hd),
            resizable: true,
            frozen: index < nosColumnFixed ? Column.FrozenDirection.LEFT : undefined,
            headerRenderer: (
               <CellHeader
                  onMouseDownColumnHeader={onMouseDownColumnHeader}
               />
            ),
            cellRenderer: (
               pageSheetTypeName === 'page-rfa' ||
               (pageSheetTypeName === 'page-spreadsheet' && projectIsAppliedRfaView && (
                  isColumnWithReplyData(hd) ||
                  hd === 'RFA Ref' ||
                  hd === 'Issue For Construction' ||
                  hd === 'IFC Rev' ||
                  hd === 'IFC Date'
               ))
            ) ? (
               <CellRFA
                  buttonPanelFunction={buttonPanelFunction}
                  getSheetRows={getSheetRows}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  commandAction={commandAction}
                  setLoading={setLoading}
               />
            ) : (pageSheetTypeName === 'page-spreadsheet' || (pageSheetTypeName === 'page-authorities-submission' && hd === 'Drawing Number')) ? (
               <Cell
                  onRightClickCell={onRightClickCell}
                  getCellModifiedTemp={getCellModifiedTemp}
                  setCellActive={setCellActive}
                  stateCell={stateCell}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  getSheetRows={getSheetRows}

                  setPosition={setPosition}
                  getCurrentDOMCell={getCurrentDOMCell}
               />
            ) : (pageSheetTypeName === 'page-authorities-submission') ? (
               <CellUploadFile
                  stateRow={stateRow}
                  stateProject={stateProject}
                  getSheetRows={getSheetRows}
                  onRightClickCell={onRightClickCell}
                  commandAction={commandAction}
                  setLoading={setLoading}
                  buttonPanelFunction={buttonPanelFunction}
               />
            ) : (
               <CellForm
                  getSheetRows={getSheetRows}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  buttonPanelFunction={buttonPanelFunction}
                  commandAction={commandAction}
                  setLoading={setLoading}
               />
            ),
            className: (props) => {
               const { rowData, column: { key }, rowIndex, columnIndex } = props;
               const { id } = rowData;
               const refType = getKeyTextForSheet(pageSheetTypeName);
               let keyItem = key;
               if (getItemKeyNameForm(key)) {
                  keyItem = `submission-${refType}-${getItemKeyNameForm(key)}-Woh Hup Private Ltd`;
               } else if (key === `${refType.toUpperCase()} Ref`) {
                  keyItem = refType === 'rfa' ? 'RFA Ref' : `${refType}Ref`;
               };


               return [
                  (cellSearchFound && id in cellSearchFound && cellSearchFound[id].indexOf(keyItem) !== -1) ? 'cell-found' : '',
                  (cellHistoryFound && cellHistoryFound.find(cell => cell.rowId === id && cell.header === key)) ? 'cell-history-highlight' : '',
                  (columnLocked(roleTradeCompany, rowData, stateRow.modeGroup, key, projectIsAppliedRfaView, pageSheetTypeName) && rowData._rowLevel === 1) ? 'cell-locked' : ''
               ].join(' ');
            }
         });
      });
      return headersObj;
   };
   const renderColumnsPM = (arr, nosColumnFixed) => {
      let headersObj = [
        {
          key: "Index",
          dataKey: "Index",
          title: "",
          width: 50,
          frozen:
            pageSheetTypeName === "page-spreadsheet"
              ? Column.FrozenDirection.LEFT
              : undefined,
          style: { padding: 0 },
          cellRenderer: (
            <CellIndex
              setCellActive={setCellActive}
              getSheetRows={getSheetRows}
              stateRow={stateRow}
              stateProject={stateProject}
            />
          ),
        },
      ];
  
      arr.forEach((hd, index) => {
        headersObj.push({
          key: hd,
          dataKey: hd,
          title: hd,
          width: getHeaderWidth(hd),
          resizable: true,
          frozen:
            index < nosColumnFixed ? Column.FrozenDirection.LEFT : undefined,
          headerRenderer: (
            <CellHeader onMouseDownColumnHeader={onMouseDownColumnHeader} />
          ),
          cellRenderer: (
             <CellFormPM
               getSheetRows={getSheetRows}
               stateRow={stateRow}
               stateProject={stateProject}
               buttonPanelFunction={buttonPanelFunction}
               commandAction={commandAction}
               setLoading={setLoading}
             />
          ),
  
          className: (props) => {
            const {
              rowData,
              column: { key },
              rowIndex,
              columnIndex,
            } = props;
            const { id } = rowData;
            const refType = "pm";
            let keyItem = key;
  
            if (pageSheetTypeName === "page-eri") {
              if (key === "DCA ERI Number") {
                keyItem = "pmRef";
              } else if (key === "E-mail") {
                keyItem = "submission-pm-dateSendThisForm-Woh Hup Private Ltd";
              } else if (key === "Subject") {
                keyItem = "submission-pm-subject-Woh Hup Private Ltd";
              } else if (key === "Response Date") {
                keyItem =
                  "submission-pm-dateResponseForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Acknowledged Send On") {
                keyItem =
                  "submission-pm-dateAcknowledgeSendForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Status") {
                keyItem = "submission-pm-tradeForStatusPM-Woh Hup Private Ltd";
              }
            } else if (pageSheetTypeName === "page-lr") {
              if (key === "DCA ERI Number") {
                keyItem = "pmRef";
              } else if (key === "E-mail") {
                keyItem = "submission-pm-dateSendThisForm-Woh Hup Private Ltd";
              } else if (key === "Subject") {
                keyItem = "submission-pm-subject-Woh Hup Private Ltd";
              } else if (key === "Response Date") {
                keyItem =
                  "submission-pm-dateResponseForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Acknowledged Send On") {
                keyItem =
                  "submission-pm-dateAcknowledgeSendForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Status") {
                keyItem = "submission-pm-tradeForStatusPM-Woh Hup Private Ltd";
              }
            } else if (pageSheetTypeName === "page-sm") {
              if (key === "DCA ERI Number") {
                keyItem = "pmRef";
              } else if (key === "E-mail") {
                keyItem = "submission-pm-dateSendThisForm-Woh Hup Private Ltd";
              } else if (key === "Subject") {
                keyItem = "submission-pm-subject-Woh Hup Private Ltd";
              } else if (key === "Response Date") {
                keyItem =
                  "submission-pm-dateResponseForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Acknowledged Send On") {
                keyItem =
                  "submission-pm-dateAcknowledgeSendForSubmitForm-Woh Hup Private Ltd";
              } else if (key === "Status") {
                keyItem = "submission-pm-tradeForStatusPM-Woh Hup Private Ltd";
              }
            }
  
            const value = [
              cellSearchFound &&
              id in cellSearchFound &&
              cellSearchFound[id].indexOf(keyItem) !== -1
                ? "cell-found"
                : "",
              cellHistoryFound &&
              cellHistoryFound.find(
                (cell) => cell.rowId === id && cell.header === key
              )
                ? "cell-history-highlight"
                : "",
              columnLocked(
                roleTradeCompany,
                rowData,
                stateRow.modeGroup,
                key,
                projectIsAppliedRfaView,
                pageSheetTypeName
              ) && rowData._rowLevel === 1
                ? "cell-locked"
                : "",
            ].join(" ");
            return value;
          },
        });
      });
      return headersObj;
    };
   const [nosColumnFixedRfaView, setNosColumnFixedRfaView] = useState(0);


   const onClickquickFilter = (filterType, item) => {

      const { drawingTypeTree } = stateRow;

      let modeFilter = stateRow['modeFilter'];

      const isIncludedParent = modeFilter.find(x => x['isIncludedParent']) ? [] : [{ isIncludedParent: 'included' }];

      if (modeFilter.find(x => x['header'] === 'Activity By Date') && filterType === 'Activity By Date') {
         modeFilter = modeFilter.filter(x => x['header'] !== 'Activity By Date');

      } else if (filterType === 'Status') {
         modeFilter = modeFilter.filter(x => x['header'] !== 'Status').filter(x => x['header'] && !x['header'].includes('Overdue '));

      } else if (filterType.includes('Overdue ')) {
         modeFilter = modeFilter.filter(x => x['header'] && !x['header'].includes('Overdue ')).filter(x => x['header'] !== 'Status');
      };

      getSheetRows({
         modeFilter: [
            ...modeFilter,
            { id: mongoObjectId(), header: filterType, value: item },
            ...isIncludedParent
         ]
      });

      let filteredNodesId = [];
      if (pageSheetTypeName !== 'page-spreadsheet') {
         setBtnSetFoldAllRows('2');
         filteredNodesId = drawingTypeTree
            .filter(node => node['treeLevel'] < 4)
            .map(x => x.id);
      } else {
         filteredNodesId = drawingTypeTree.map(x => x.id);
      };
      setExpandedRows(filteredNodesId);
   };


   const downloadDataFromDB = async () => {
      try {
         const resDB = await apiGetAllCollectionAdmin({ token });

         const fileName = `database_${moment().format('DD/MM/YY')}`;

         const json = JSON.stringify(resDB.data);
         const blob = new Blob([json], { type: 'application/json' });
         const href = await URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = href;
         link.download = fileName + '.json';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);


         message.info('DONE...Download DB', 3);
      } catch (err) {
         console.log(err)
      };
   };


   const [rightDrawerVisible, setRightDrawerVisible] = useState(false);
   const [dataTableFinal, setDataTableFinal] = useState([]);
   const [allDataTableInfo, setAllDataTableInfo] = useState({});


   useEffect(() => {
      if (!loading && stateRow) {

         const dataArranged = arrangeDrawingTypeFinal({ stateRow, stateProject, pageSheetTypeName });

         if (pageSheetTypeName === 'page-spreadsheet') {
            const { rowsTableInTreeFormat, isInGroupMode, drawingTypeTreeTable, rowsTable, modeFilter } = dataArranged;
            if (isInGroupMode) {
               setDataTableFinal(rowsTableInTreeFormat);
            } else {
               if (drawingTypeTreeTable.length === 0) {
                  setDataTableFinal(rowsTable);
               } else {
                  let dataOutput = [];
                  drawingTypeTreeTable.forEach(item => {
                     let newItem = { ...item };
                     const checkResult = checkIfThereIsRowInsideTreeNode(newItem, drawingTypeTreeTable, rowsTable, pageSheetTypeName); // CHECK UNTIL THE LOWEST CHILD TO CHECK IF CONTAIN ROWS ????
                     if (checkResult || modeFilter.length === 0) { // IN CASE NO FILTER AND THERE IS SOME FOLDER NOT ADD ANY DRAWING YET...
                        const rowChildren = rowsTable.filter(row => row._parentRow === newItem.id);
                        if (rowChildren.length > 0) {
                           newItem.children = rowChildren;
                        };
                        dataOutput.push(newItem);
                     };
                  });
                  const output = convertFlattenArraytoTree1(dataOutput);
                  setDataTableFinal(output);
               };
               setAllDataTableInfo(dataArranged);
            };

         }else if( pageSheetTypeName === "page-eri" || pageSheetTypeName === "page-lr" ||pageSheetTypeName === "page-sm")
         {
            const { drawingTypeTreeTable, rowsTable } = dataArranged;
            const dataOutputFinal = getOutputRowsWithTreeNodeFinalPM(drawingTypeTreeTable,rowsTable,pageSheetTypeName
            );
            const outputMultiForm = convertFlattenArraytoTree1(dataOutputFinal);
            setDataTableFinal(outputMultiForm);
            setAllDataTableInfo(dataArranged);
         } 
         else {
            const { drawingTypeTreeTable, rowsTable } = dataArranged;
            const dataOutputFinal = getOutputRowsWithTreeNodeFinal(drawingTypeTreeTable, rowsTable, pageSheetTypeName);
            const outputMultiForm = convertFlattenArraytoTree1(dataOutputFinal);
            setDataTableFinal(outputMultiForm);
            setAllDataTableInfo(dataArranged);
         };
      };
   }, [stateRow, loading]);


   function getColumns() {
      let rs =
        (pageSheetTypeName === "page-eri" ||
        pageSheetTypeName === "page-lr" ||
        pageSheetTypeName === "page-sm")
          ? renderColumnsPM(
              pageSheetTypeName === "page-authorities-submission"
                ? headersFormUploadFile
                : (projectIsAppliedRfaView &&
                  pageSheetTypeName !== "page-spreadsheet")
                ? headersAllFormViewArray
                : stateProject.userData.headersShown,
              pageSheetTypeName === "page-authorities-submission"
                ? 0
                : (projectIsAppliedRfaView &&
                  pageSheetTypeName !== "page-spreadsheet")
                ? nosColumnFixedRfaView
                : stateProject.userData.nosColumnFixed
            )
          : renderColumns(
              pageSheetTypeName === "page-authorities-submission"
                ? headersFormUploadFile
                : (projectIsAppliedRfaView &&
                  pageSheetTypeName !== "page-spreadsheet")
                ? headersAllFormViewArray
                : stateProject.userData.headersShown,
              pageSheetTypeName === "page-authorities-submission"
                ? 0
                : (projectIsAppliedRfaView &&
                  pageSheetTypeName !== "page-spreadsheet")
                ? nosColumnFixedRfaView
                : stateProject.userData.nosColumnFixed
            );
      return rs;
    }


   const headersAllFormViewArray = getHeadersForm(pageSheetTypeName);


   if ((role === 'Consultant' || role === 'Client') && pageSheetTypeName === 'page-spreadsheet') {
      return <div>There is no data display for 'Client' and 'Consultant'</div>
   } else if (role === 'Document Controller' && company !== 'Woh Hup Private Ltd') {
      return <div>'Document Controller' must come from 'Woh Hup Private Ltd'</div>
   };



   return (
      <div
         style={{ color: 'black' }}
         onContextMenu={(e) => e.preventDefault()}
      >
         {isAppliedTabForView && arrayTabDiscipline.length > 0 && (pageSheetTypeName !== 'page-rfc') && (
            <div style={{ position: 'fixed', bottom: 0, left: projectIsAppliedRfaView ? 54 : 0, display: 'flex', zIndex: 999, background: colorType.grey4, width: '100%', paddingTop: 3 }}>
               {arrayTabDiscipline.map((tabName, i) => {
                  return (
                     <ButtonTab
                        key={tabName}
                        tabName={tabName}
                        setPageTabDiscipline={setPageTabDiscipline}
                        index={i}
                        pageSheetTypeName={pageSheetTypeName}
                     />
                  );
               })}
            </div>
         )}




         <div style={{
            width: currentWindow.width,
            height: navBarDmsHeight,
            position: 'relative', display: 'flex',
            padding: 7, paddingRight: 0,
            background: colorType.grey4
         }}>

            {!pageUploadType && stateRow && !loading && (
               <>
                  {pageSheetTypeName === 'page-spreadsheet' && (
                     <>
                        <IconTable type='save' onClick={() => buttonPanelFunction('save-ICON')} />
                        <DividerRibbon />
                        <IconTable type='layout' onClick={() => buttonPanelFunction('reorderColumn-ICON')} />
                        <IconTable type='apartment' onClick={() => buttonPanelFunction('group-ICON')} modeGroup={stateRow.modeGroup} />
                        <IconTable type='sort-ascending' onClick={() => buttonPanelFunction('sort-ICON')} modeSort={stateRow.modeSort} />
                     </>
                  )}

                  <IconTable type='filter' onClick={() => buttonPanelFunction('filter-ICON')} modeFilter={stateRow.modeFilter} />


                  {searchInputShown
                     ? <InputSearch
                        searchGlobal={searchGlobal}
                        stateRow={stateRow}
                        stateProject={stateProject}
                        getSheetRows={getSheetRows}
                     />
                     : <IconTable type='search' onClick={() => setSearchInputShown(true)} />
                  }



                  {stateRow.modeGroup && stateRow.modeGroup.length > 0 ? (
                     <IconTable type='swap' onClick={() => buttonPanelFunction('swap-ICON-1')} />
                  ) : (
                     <IconTable type='swap' onClick={() => buttonPanelFunction('swap-ICON-2')} />
                  )}

                  <IconTable type='retweet' onClick={clearAllFilterSortSearchGroup} />



                  {pageSheetTypeName === 'page-spreadsheet' && (
                     <>
                        <DividerRibbon />

                        {window.dms_disciplineTabThisPage === 'ALL' && <IconTable type='folder-add' onClick={() => buttonPanelFunction('addDrawingType-ICON')} />}
                        {window.dms_disciplineTabThisPage === 'ALL' && isBothSideActionUser && <IconTable type='upload' onClick={() => buttonPanelFunction('import-excel-ICON')} />}

                        <IconTable type='highlight' onClick={() => buttonPanelFunction('colorized-ICON')} />
                        <DividerRibbon />
                        <IconTable type='history' onClick={() => buttonPanelFunction('history-ICON')} />
                        <IconTable type='heat-map' onClick={() => buttonPanelFunction('color-cell-history-ICON')} />

                        {/* <IconTable type='plus' onClick={() => buttonPanelFunction('viewTemplate-ICON')} /> */}

                        {/* <ViewTemplateSelect
                           updateExpandedRowIdsArray={updateExpandedRowIdsArray}
                           stateRow={stateRow}
                           getSheetRows={getSheetRows}
                           stateProject={stateProject}
                           setUserData={setUserData}
                        /> */}

                        <DividerRibbon />
                     </>
                  )}


                  {pageSheetTypeName !== 'page-spreadsheet' && <DividerRibbon />}

                  <IconTable type='export' onClick={() => buttonPanelFunction('export-data')} />


                  {pageSheetTypeName !== 'page-spreadsheet' && (
                     <>
                        <IconTable type='history' onClick={() => buttonPanelFunction('history-ICON')} />
                        <IconTableFoldRow
                           btnSetFoldAllRows={btnSetFoldAllRows}
                           onClickChangeRowsFold={onClickChangeRowsFold}
                        />
                        <DividerRibbon />
                     </>
                  )}


                  {(projectIsAppliedRfaView && isBothSideActionUser && pageSheetTypeName === 'page-rfa') && (
                     <>
                        {/* <IconTable type='file-text' onClick={() => buttonPanelFunction('add-discipline-ICON')} /> */}
                        {/* <IconTable type='font-colors' onClick={() => buttonPanelFunction('add-authorities-ICON')} /> */}
                        <IconTable type='font-colors' onClick={() => buttonPanelFunction('project-settings-ICON')} />
                     </>
                  )}



                  {projectIsAppliedRfaView && (
                     (
                        (
                           pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' ||
                            pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm' ||
                           pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
                        ) && isBothSideActionUser
                     ) || (pageSheetTypeName === 'page-rfc' && isSubconCanSubmit)
                  ) && (
                        <>
                           <IconTable
                              type='plus-square'
                              pageSheetTypeName={pageSheetTypeName}
                              onClick={() => buttonPanelFunction('option-email-or-not-for-admin')}
                           />
                           <DividerRibbon />
                        </>
                     )}


                  {projectIsAppliedRfaView && allDataTableInfo.refStatisticOverdue && (
                     <>
                        {(
                           pageSheetTypeName === 'page-rfa' ||
                           pageSheetTypeName === 'page-rfam' ||
                           pageSheetTypeName === 'page-rfi' ||
                           pageSheetTypeName === 'page-qr' ||
                           pageSheetTypeName === 'page-qrm' ||
                           pageSheetTypeName === 'page-rfc'
                        ) && (
                              <>
                                 <DataStatisticRibbon
                                    onClickquickFilter={onClickquickFilter}
                                    stateRow={stateRow}
                                    pageSheetTypeName={pageSheetTypeName}
                                    refStatisticOverdue={allDataTableInfo.refStatisticOverdue}
                                 />
                                 <DividerRibbon />
                              </>
                           )}
                     </>
                  )}


                  {isAdmin && (
                     <div style={{ display: 'flex' }}>
                        {/* <IconTable type='delete' onClick={() => adminFncServerInit('delete-all-collections')} />
                        <ButtonAdminUploadData stateProject={stateProject} /> */}
                     </div>
                  )}

                  {email === 'truongbaolam0209@gmail.com' && (
                     <Button style={{ marginLeft: 5 }} onClick={downloadDataFromDB} size={'small'}>DB</Button>
                  )}

                  <div style={{ position: 'absolute', display: 'flex', right: 5 }}>

                     {(pageSheetTypeName !== 'page-spreadsheet') && (
                        <>
                           <QuickFilterByDate
                              onClickquickFilter={onClickquickFilter}
                              stateRow={stateRow}
                              pageSheetTypeName={pageSheetTypeName}
                           />
                           <DividerRibbon />
                        </>
                     )}


                     {(pageSheetTypeName === 'page-spreadsheet' || pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') &&
                        projectIsAppliedRfaView &&
                        allDataTableInfo.refStatisticRepliedStatus && (
                           <DrawingStatusStatisticRight
                              onClickquickFilter={onClickquickFilter}
                              stateRow={stateRow}
                              pageSheetTypeName={pageSheetTypeName}
                              refStatisticRepliedStatus={allDataTableInfo.refStatisticRepliedStatus}
                           />
                        )}

                  </div>
               </>
            )}


            {(pageSheetTypeName === 'page-authorities-submission' && pageUploadType) && (
               <>
                  {isUserAbleToUploadFiles && (
                     <>
                        <IconTable type='save' onClick={() => buttonPanelFunction('save-ICON')} />
                        <IconTable type='folder-add' onClick={() => buttonPanelFunction('addUploadFolder-ICON')} />
                     </>
                  )}

                  <>
                     {searchInputShown
                        ? <InputSearch
                           searchGlobal={searchGlobal}
                           stateRow={stateRow}
                           stateProject={stateProject}
                           getSheetRows={getSheetRows}
                        />
                        : <IconTable type='search' onClick={() => setSearchInputShown(true)} />
                     }
                  </>
                  <IconTable type='retweet' onClick={clearAllFilterSortSearchGroup} />

                  <div style={{ position: 'absolute', display: 'flex', top: 3, right: 22, fontSize: 25, color: colorType.primary }}>{authoritiesType}</div>
               </>
            )}

         </div>


         {(!loading && stateRow) ? (
            <TableStyled
               style={{ marginLeft: projectIsAppliedRfaView ? sideBarWidth : 0 }}
               dataForStyled={{ stateProject, randomColorRange, randomColorRangeStatus, cellSearchFound, cellHistoryFound, pageSheetTypeName }}
               ref={tableRef}
               fixed
               projectIsAppliedRfaView={projectIsAppliedRfaView}
               pageSheetTypeName={pageSheetTypeName}

               columns={getColumns()}


               data={dataTableFinal}
               expandedRowKeys={expandedRows}

               expandColumnKey={
                  pageSheetTypeName === 'page-authorities-submission' ? 'File Name'
                     : (projectIsAppliedRfaView && pageSheetTypeName !== 'page-spreadsheet') ? pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase() + ' Ref'
                        : stateProject.userData.headersShown[0]}

               expandIconProps={expandIconProps}
               components={{ ExpandIcon }}
               rowHeight={30}
               overscanRowCount={0}
               onScroll={onScroll}
               rowClassName={rowClassName}
               onRowExpand={onRowExpand}
               rowEventHandlers={rowEventHandlers}
            />
         ) : <LoadingIcon />}


         {(stateRow && (pageSheetTypeName === 'page-spreadsheet' || pageSheetTypeName === 'page-authorities-submission')) && (
            <ModalStyleFunction
               visible={panelFunctionVisible}
               footer={null}
               onCancel={() => {
                  getSheetRows({ rowsSelected: [], rowsSelectedToMove: [] });

                  setPanelFunctionVisible(false);
               }}
               destroyOnClose={true}
               style={{
                  position: 'fixed',
                  left: cursor && getPanelPosition(cursor).x,
                  top: cursor && getPanelPosition(cursor).y
               }}
               mask={false}
               width={250}
            >
               {pageSheetTypeName === 'page-spreadsheet' ? (
                  <PanelFunction
                     panelType={panelType}
                     buttonPanelFunction={buttonPanelFunction}
                     stateRow={stateRow}
                     stateProject={stateProject}
                  />
               ) : (
                  <PanelFunctionUploadFile
                     panelType={panelType}
                     buttonPanelFunction={buttonPanelFunction}
                     stateRow={stateRow}
                     stateProject={stateProject}
                  />

               )}

            </ModalStyleFunction>
         )}


         <ModalStyledSetting
            title={stateRow && stateRow.modeGroup && stateRow.modeGroup.length > 0 ? 'Quit Grouping Mode' : getActionName(panelSettingType)}
            visible={panelSettingVisible}
            footer={null}
            onCancel={() => {
               if (
                  panelSettingType !== 'form-submit-RFA' &&
                  panelSettingType !== 'form-resubmit-RFA' &&
                  panelSettingType !== 'form-reply-RFA' &&
                  panelSettingType !== 'addDrawingType-ICON' &&
                  panelSettingType !== 'addUploadFolder-ICON' &&
                  panelSettingType !== 'form-submit-multi-type' &&
                  panelSettingType !== 'form-resubmit-multi-type' &&
                  panelSettingType !== 'form-reply-multi-type' &&
                  panelSettingType !== 'history-ICON' &&
                  panelSettingType !== 'color-cell-history-ICON' &&
                  panelSettingType !== 'import-excel-ICON'
               ) {
                  resetAllPanelInitMode();
               };
            }}
            destroyOnClose={true}
            centered={true}
            width={
               (panelSettingType === 'addDrawingType-ICON' || panelSettingType === 'addUploadFolder-ICON') ? currentWindow.width * 0.85 :
                  (
                     (panelSettingType && (panelSettingType.includes('form-submit-') || panelSettingType.includes('form-resubmit-') || panelSettingType.includes('form-reply-')))) ? currentWindow.width * 0.9 :
                     panelSettingType === 'pickTypeTemplate-ICON' ? currentWindow.width * 0.6 :
                        panelSettingType === 'View Drawing Revision' ? currentWindow.width * 0.8 :
                           panelSettingType === 'filter-ICON' ? currentWindow.width * 0.5 :
                              panelSettingType === 'history-ICON' ? currentWindow.width * 0.8 :
                                 panelSettingType === 'import-excel-ICON' ? currentWindow.width * 0.9 :
                                    panelSettingType === 'project-settings-ICON' ? currentWindow.width * 0.8 :
                                       550
            }
         >
            <PanelSetting
               panelType={panelType}
               panelSettingType={panelSettingType}
               commandAction={commandAction}
               onClickCancelModal={() => {
                  resetAllPanelInitMode();
                  getSheetRows({ currentRefToAddNewOrReplyOrEdit: null });
               }}
               setLoading={setLoading}
               buttonPanelFunction={buttonPanelFunction}
               allDataTableInfo={allDataTableInfo}
            />
         </ModalStyledSetting>


         {(
            pageSheetTypeName === 'page-spreadsheet' ||
            pageSheetTypeName === 'page-mm' ||
            pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' ||
            pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt' ||
            pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' // TEMPORARILY NO QUICK TREE FOR RFC

         ) && window.dms_disciplineTabThisPage !== 'ALL' && stateRow && stateProject && stateProject.allDataOneSheet && stateRow.drawingTypeTree && ( // need to check drawingTypeTree, SWITCH from authority page to RFA, RFAM => ERROR
               <>
                  <RightDrawerButton
                     onClickRightDrawerButton={() => setRightDrawerVisible(true)}
                     stateRow={stateRow}
                  />
                  <PanelTreeQuickFilter
                     rightDrawerVisible={rightDrawerVisible}
                     onCloseDrawer={(nodeIdIsolated) => {

                        getSheetRows({ treeNodeIdFilterView: nodeIdIsolated });
                        setRightDrawerVisible(false);

                        const disciplineFilterThisPageUpdate = window.dms_disciplineFilterThisPage || {};
                        const pageTabDiscipline = window.dms_disciplineTabThisPage || null;
                        disciplineFilterThisPageUpdate[pageTabDiscipline] = nodeIdIsolated;
                        window.dms_disciplineFilterThisPage = disciplineFilterThisPageUpdate;
                     }}
                     stateRow={stateRow}
                     stateProject={stateProject}
                     pageTabDiscipline={pageTabDiscipline}
                  />
               </>
            )}



         {adminFncInitPanel && (
            <Modal
               title={adminFncBtn + ' ... ... sure ???'}
               visible={adminFncInitPanel}
               onOk={() => {
                  adminFnc(adminFncBtn);
                  setAdminFncInitPanel(false)
               }}
               onCancel={() => setAdminFncInitPanel(false)}
            ></Modal>
         )}


      </div>
   );
};





const mapStateToProps = (state) => {
   return {
      stateCell: state.dms_cell,
      stateRow: state.dms_row,
      stateProject: state.dms_project,
   };
};


export default connect(mapStateToProps, {
   setCellActive, getCellModifiedTemp, overwriteCellsModified, copyTempData, applyActionOnCell, overwriteCellReducer,
   getSheetRows, overwriteRowReducer,
   fetchDataOneSheet, setUserData, overwriteProjectReducer
})(ComponentDmsOverall);



const DividerRibbon = () => {
   return (
      <Divider type='vertical' style={{
         height: 32,
         marginLeft: 8,
         marginRight: 8,
         background: 'white'
      }} />
   );
};


const ButtonTab = ({ tabName, setPageTabDiscipline, index, pageSheetTypeName }) => {

   const currentTabDiscipline = window.dms_disciplineTabThisPage;

   const [isHover, setIsHover] = useState(false);

   const isDms = pageSheetTypeName === 'page-spreadsheet';

   return (
      <Button
         key={tabName}
         style={{
            borderRadius: 0,
            paddingLeft: isDms ? 17 : 25,
            paddingRight: isDms ? 17 : 25,
            border: `1px solid ${colorType.grey3}`,
            borderBottom: 'none',
            borderLeft: index > 0 ? 'none' : `1px solid ${colorType.grey3}`,
            background: (currentTabDiscipline === tabName || isHover) ? colorType.primary : 'white',
            color: (currentTabDiscipline === tabName || isHover) ? 'white' : colorType.primary,
            fontWeight: 'bold',
            boxShadow: 'none'
         }}
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
         onClick={() => {
            if (window.dms_disciplineTabThisPage !== tabName) {
               setPageTabDiscipline(tabName);
               window.dms_disciplineTabThisPage = tabName;
            };
         }}
      >
         {tabName}
      </Button>
   );
};


const RightDrawerButton = ({ onClickRightDrawerButton, stateRow }) => {

   const { treeNodeIdFilterView } = stateRow;

   return (
      <Tooltip placement={'left'} title={'Quick Filter Tree'}>
         <div
            style={{
               position: 'fixed',
               right: -20,
               top: window.innerHeight * 0.5,
               zIndex: 100,
               padding: 10,
               paddingTop: 5,
               background: treeNodeIdFilterView ? colorType.primary : 'white',
               borderRadius: 10,
               paddingRight: 30,
               cursor: 'pointer',
               border: `1px solid ${colorType.primary}`,
               boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
            }}
            onClick={onClickRightDrawerButton}
         >
            <Icon type='left' style={{ color: treeNodeIdFilterView ? 'white' : colorType.primary }} />
         </div>
      </Tooltip>
   );
};



const TableStyled = styled(Table)`
   .BaseTable__row-cell.spreadsheet-cell.cell-selected {
      background-color: ${colorType.cellHighlighted};
   };
   .cell-active {
      background-color: ${colorType.yellow};
   };

   .cell-locked {
      background-color: ${colorType.lockedCell};
   };
   .row-locked {
      background-color: ${colorType.lockedCell};
   };
   .row-drawing-type {
      background-color: ${colorType.grey3};
   };
   .row-drawing-type-dark {
      background-color: ${colorType.grey3a};
   };
   .row-selected {
      background-color: ${colorType.cellHighlighted};
   };
   
   
   ${({ dataForStyled }) => {
      const { stateProject, randomColorRange, randomColorRangeStatus } = dataForStyled;
      if (stateProject.userData) {
         let { colorization } = stateProject.userData;
         const value = colorization.value || [];

         let res = [];
         value.map(n => {
            let color = stateProject.userData.colorization.header === 'Status' ?
               randomColorRangeStatus[n] :
               randomColorRange[value.indexOf(n)];
            if (n) {
               res.push(`.colorization-${stateProject.userData.colorization.header.replace(/\s/g, '').replace(/,/g, '')}-${n.replace(/\s/g, '').replace(/,/g, '')}-styled {
                  background-color: ${color};
            }`);
            };
         });
         const output = [...new Set(res)].join('\n');

         return output;
      };
   }}

   ${({ dataForStyled }) => {
      const { cellSearchFound } = dataForStyled;

      if (cellSearchFound) return `.cell-found { background-color: #7bed9f; }`;
   }}

   ${({ dataForStyled }) => {
      const { cellHistoryFound } = dataForStyled;
      if (cellHistoryFound) return `.cell-history-highlight { background-color: #f6e58d; }`;
   }}

   ${({ dataForStyled }) => {
      const { pageSheetTypeName } = dataForStyled;
      if (pageSheetTypeName === 'page-spreadsheet') {
         return `.BaseTable__table .BaseTable__body {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
         }`
      };
   }}


   .BaseTable__header-row {
      background: ${colorType.primary};
   };
   .cell-current {
      background-color: ${colorType.cellHighlighted}
   };

   
   .BaseTable__header-cell {
      padding: 5px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.primary};
      color: white
   }
   .BaseTable__row-cell {
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
      padding: 0;
   };

   .BaseTable__table-main .BaseTable__row-cell:last-child {
      padding-right: 0;
   };
`;
const ModalStyleFunction = styled(Modal)`
   .ant-modal-close, .ant-modal-header {
      display: none;
   }
   .ant-modal-body {
      padding: 0;
   }
`;
const ModalStyledSetting = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;


export const getInputDataInitially = (data, pageSheetTypeName) => {

   if (pageSheetTypeName === 'page-rfa') {
      const { sheetData, rowsHistoryData, rfaData } = data;

      const { rows, publicSettings } = sheetData;
      const { drawingTypeTree } = publicSettings;

      const pageTabDiscipline = window.dms_disciplineTabThisPage;
      const treeNodeIdFilterView = pageTabDiscipline !== 'ALL' ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline] : null;

      const { rowsDataRFA, treeViewRFA } = getDataForRfaSheet({ rowsAllAndHistory: [...rows, ...rowsHistoryData] });

      return {

         modeFilter: [],
         modeSearch: {},
         modeGroup: [],

         drawingTypeTree: treeViewRFA,
         drawingTypeTreeDmsView: drawingTypeTree,
         rowsAll: rows,
         rowsRfaAll: rowsDataRFA.filter(r => !r['row']),
         rowsRfaAllInit: rowsDataRFA,
         rfaData,
         currentRefToAddNewOrReplyOrEdit: null,
         loading: false,
         treeNodeIdFilterView
      };

   } else if (pageSheetTypeName === 'page-spreadsheet') {

      const { sheetData, rfaData } = data;

      const { rows, publicSettings, userSettings } = sheetData;

      const { drawingTypeTree } = publicSettings;

      let rowsAllOutput = getOutputRowsAllSorted(drawingTypeTree, rows);
      const { treeNodesToAdd, rowsToAdd, rowsUpdatePreOrParent } = rearrangeRowsNotMatchTreeNode(rows, rowsAllOutput, drawingTypeTree);


      const pageTabDiscipline = window.dms_disciplineTabThisPage;
      const treeNodeIdFilterView = pageTabDiscipline !== 'ALL' ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline] : null;


      // let viewTemplates = [];
      // let viewTemplateNodeId = null; // TEMP_HIDE_TEMPLATE
      let modeFilter = [];
      let modeSort = {};
      if (userSettings) {
         // viewTemplates = userSettings.viewTemplates || [];
         // viewTemplateNodeId = userSettings.viewTemplateNodeId || null;
         modeFilter = userSettings.modeFilter || [];
         modeSort = userSettings.modeSort || {};
      };

      return {
         modeFilter,
         modeSort,
         modeSearch: {},
         modeGroup: [],

         rowsAll: [...rowsAllOutput, ...rowsToAdd], // HANDLE_ROWS_CAN_NOT_MATCH_PARENT
         rowsVersionsToSave: [],

         // viewTemplates, // TEMP_HIDE_TEMPLATE
         // viewTemplateNodeId, // TEMP_HIDE_TEMPLATE
         treeNodeIdFilterView,


         drawingTypeTree: [...drawingTypeTree, ...treeNodesToAdd], // HANDLE_ROWS_CAN_NOT_MATCH_PARENT
         drawingTypeTreeInit: drawingTypeTree,
         drawingsTypeDeleted: [],
         drawingsTypeNewIds: [...treeNodesToAdd.map(x => x.id)], // HANDLE_ROWS_CAN_NOT_MATCH_PARENT

         rowsDeleted: [],
         idRowsNew: [],
         rowsUpdatePreRowOrParentRow: { ...rowsUpdatePreOrParent }, // HANDLE_ROWS_CAN_NOT_MATCH_PARENT
         rowsUpdateSubmissionOrReplyForNewDrawingRev: [],

         rowsSelected: [],
         rowsSelectedToMove: [],

         rfaData

      };
   }else if (pageSheetTypeName === "page-eri") {
      const refType = "pm";
      const { rowsData } = data;
      const pageTabDiscipline = pageSheetTypeName === "page-eri" ? "ERI" : "ALL";
      const treeNodeIdFilterView =
        pageTabDiscipline !== "ALL"
          ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline]
          : null;
  
      const { treeView } = getDataForMultiFormSheetPM(
        rowsData.filter((x) => x["pmRef"].includes("ERI")),
        pageSheetTypeName
      );
  
      let outputRowsRef = [];
  
      const listRef = [
        ...new Set(
          rowsData
            .filter((x) => x["pmRef"].includes("ERI"))
            .map((x) => x[`${refType}Ref`])
        ),
      ];
  
      listRef.forEach((ref) => {
        const rowsThisRef = rowsData.filter((r) => r[`${refType}Ref`] === ref);
        const arrayVersion = [...new Set(rowsThisRef.map((x) => x.revision))];
        const latestVersion = arrayVersion.sort()[arrayVersion.length - 1];
        const rowFound = rowsThisRef.find((x) => x.revision === latestVersion);
        outputRowsRef.push(rowFound);
      });
      return {
        modeFilter: [],
        modeSearch: {},
        modeGroup: [],
        drawingTypeTree: treeView,
        rowsFormAll: outputRowsRef,
        rowsFormAllInit: rowsData,
  
        loading: false,
        treeNodeIdFilterView,
      };
   } else if (pageSheetTypeName === "page-lr") {
      const refType = "pm";
      const { rowsData } = data;
      const pageTabDiscipline = pageSheetTypeName === "page-lr" ? "LR" : "ALL";
      const treeNodeIdFilterView =
        pageTabDiscipline !== "ALL"
          ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline]
          : null;
  
      const { treeView } = getDataForMultiFormSheetPM(
        rowsData.filter((x) => x["pmRef"].includes("LR")),
        pageSheetTypeName
      );
  
      let outputRowsRef = [];
  
      const listRef = [
        ...new Set(
          rowsData
            .filter((x) => x["pmRef"].includes("LR"))
            .map((x) => x[`${refType}Ref`])
        ),
      ];
  
      listRef.forEach((ref) => {
        const rowsThisRef = rowsData.filter((r) => r[`${refType}Ref`] === ref);
        const arrayVersion = [...new Set(rowsThisRef.map((x) => x.revision))];
        const latestVersion = arrayVersion.sort()[arrayVersion.length - 1];
        const rowFound = rowsThisRef.find((x) => x.revision === latestVersion);
        outputRowsRef.push(rowFound);
      });
      return {
        modeFilter: [],
        modeSearch: {},
        modeGroup: [],
        drawingTypeTree: treeView,
        rowsFormAll: outputRowsRef,
        rowsFormAllInit: rowsData,
  
        loading: false,
        treeNodeIdFilterView,
      };
   } else if (pageSheetTypeName === "page-sm") {
      const refType = "pm";
      const { rowsData } = data;
      const pageTabDiscipline = pageSheetTypeName === "page-sm" ? "SM" : "ALL";
      const treeNodeIdFilterView =
        pageTabDiscipline !== "ALL"
          ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline]
          : null;
  
      const { treeView } = getDataForMultiFormSheetPM(
        rowsData.filter((x) => x["pmRef"].includes("SM")),
        pageSheetTypeName
      );
  
      let outputRowsRef = [];
  
      const listRef = [
        ...new Set(
          rowsData
            .filter((x) => x["pmRef"].includes("SM"))
            .map((x) => x[`${refType}Ref`])
        ),
      ];
  
      listRef.forEach((ref) => {
        const rowsThisRef = rowsData.filter((r) => r[`${refType}Ref`] === ref);
        const arrayVersion = [...new Set(rowsThisRef.map((x) => x.revision))];
        const latestVersion = arrayVersion.sort()[arrayVersion.length - 1];
        const rowFound = rowsThisRef.find((x) => x.revision === latestVersion);
        outputRowsRef.push(rowFound);
      });
      return {
        modeFilter: [],
        modeSearch: {},
        modeGroup: [],
        drawingTypeTree: treeView,
        rowsFormAll: outputRowsRef,
        rowsFormAllInit: rowsData,
  
        loading: false,
        treeNodeIdFilterView,
      };
   } else if (
      pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' ||
      pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
   ) {

      const refType = getKeyTextForSheet(pageSheetTypeName);

      const { rowsData } = data;

      const pageTabDiscipline = window.dms_disciplineTabThisPage;
      const treeNodeIdFilterView = pageTabDiscipline !== 'ALL' ? (window.dms_disciplineFilterThisPage || {})[pageTabDiscipline] : null;

      const { treeView } = getDataForMultiFormSheet(rowsData, pageSheetTypeName);

      let outputRowsRef = [];

      const listRef = [... new Set(rowsData.map(x => x[`${refType}Ref`]))];

      listRef.forEach(ref => {
         const rowsThisRef = rowsData.filter(r => r[`${refType}Ref`] === ref);
         const arrayVersion = [...new Set(rowsThisRef.map(x => x.revision))];
         const latestVersion = arrayVersion.sort()[arrayVersion.length - 1];
         const rowFound = rowsThisRef.find(x => x.revision === latestVersion);
         outputRowsRef.push(rowFound);
      });

      return {
         modeFilter: [],
         modeSearch: {},
         modeGroup: [],
         drawingTypeTree: treeView,
         rowsFormAll: outputRowsRef,
         rowsFormAllInit: rowsData,

         loading: false,
         treeNodeIdFilterView
      };


   } else if (pageSheetTypeName === 'page-authorities-submission') {

      const { rowsData, rowsDataInit, treeView } = getDataForRowsUploadFile(data);

      return {
         modeFilter: [],
         modeSearch: {},
         modeGroup: [],
         folderTree: treeView,
         rowsFileAll: rowsData,
         rowsFileAllInit: rowsDataInit,
         loading: false,
         rowsSelected: [],

         rowsSelectedToMove: [] // no use but need to add here because some column use Cell
      };
   };
};




const arrangeDrawingTypeFinal = ({ stateRow, stateProject, pageSheetTypeName }) => {


   const {
      rowsAll, drawingTypeTree, viewTemplateNodeId,
      modeFilter, modeGroup, modeSort, modeSearch,

      rowsRfaAll, rowsFormAll, rfaData,
      treeNodeIdFilterView,
      rowsFileAll, folderTree
   } = stateRow;


   const refType = getKeyTextForSheet(pageSheetTypeName);

   if (pageSheetTypeName === 'page-spreadsheet') {

      const { publicSettings: { overdueLeadConsultantOnly } } = stateProject.allDataOneSheet;

      let drawingTypeTreeTemplate = [];
      let rowsAllInTemplate = [];
      const templateNode = drawingTypeTree.find(x => x.id === viewTemplateNodeId);

      if (templateNode) {
         const nodeArray = getTreeFlattenOfNodeInArray(drawingTypeTree, templateNode);
         if (nodeArray.length > 1) {
            drawingTypeTreeTemplate = nodeArray.filter(x => x.id !== templateNode.id);
            rowsAllInTemplate = rowsAll.filter(x => drawingTypeTreeTemplate.find(tr => tr.id === x._parentRow));
         } else {
            // const parent = nodeArray[0];
            // return rowsAll.filter(x => x._parentRow === parent.id);
            rowsAllInTemplate = rowsAll.filter(x => nodeArray.find(tr => tr.id === x._parentRow));
         };
      } else {

         drawingTypeTreeTemplate = drawingTypeTree.map(x => ({ ...x }));

         if (treeNodeIdFilterView) {
            const nodeFound = drawingTypeTreeTemplate.find(x => x.id === treeNodeIdFilterView);
            if (nodeFound) {
               const nodeArray = getTreeFlattenOfNodeInArray(drawingTypeTree, nodeFound);
               drawingTypeTreeTemplate = nodeArray.map(x => ({ ...x }));
            };
         };

         rowsAllInTemplate = rowsAll.map(x => ({ ...x }));
      };


      if (Object.keys(modeSearch).length === 2) {
         const { isFoundShownOnly, searchDataObj } = modeSearch;
         if (isFoundShownOnly === 'show found only') {
            rowsAllInTemplate = rowsAllInTemplate.filter(row => row.id in searchDataObj);
         };
      };


      if (modeFilter.length > 0) {
         rowsAllInTemplate = filterRowsOutput({ modeFilter, pageSheetTypeName, rfaData, rowsCurrentInView: rowsAllInTemplate, overdueLeadConsultantOnly });
         if (Object.keys(modeSort).length !== 3 && modeFilter.find(x => x.isIncludedParent === 'not included')) {
            // return rowsAllInTemplate;
            drawingTypeTreeTemplate = [];
         };
      };


      if (Object.keys(modeSort).length === 3) {
         const { isIncludedParent: isIncludedParentSort, column: columnSort, type: typeSort } = modeSort;
         if (isIncludedParentSort === 'included') {
            const listParentIds = [...new Set(rowsAllInTemplate.map(x => x._parentRow))];
            let rowsSortedOutput = [];
            listParentIds.forEach(parentId => {
               let subRows = rowsAllInTemplate.filter(x => x._parentRow === parentId);
               if (typeSort === 'ascending') {
                  subRows = sortFnc(subRows, columnSort, true);
               } else if (typeSort === 'descending') {
                  subRows = sortFnc(subRows, columnSort, false);
               };
               rowsSortedOutput = [...rowsSortedOutput, ...subRows];
            });
            rowsAllInTemplate = [...rowsSortedOutput];

            // if (modeFilter.find(x => x.isIncludedParent === 'not included')) return rowsAllInTemplate;
            if (modeFilter.find(x => x.isIncludedParent === 'not included')) {
               drawingTypeTreeTemplate = [];
            };

         } else if (isIncludedParentSort === 'not included') {
            if (typeSort === 'ascending') {
               rowsAllInTemplate = sortFnc(rowsAllInTemplate, columnSort, true);
            } else if (typeSort === 'descending') {
               rowsAllInTemplate = sortFnc(rowsAllInTemplate, columnSort, false);
            };
            // return rowsAllInTemplate;
            drawingTypeTreeTemplate = [];
         };
      };


      if (modeGroup.length > 0) {
         const { rows } = groupByHeaders(rowsAllInTemplate, modeGroup);
         // return rows;
         return {
            rowsTableInTreeFormat: rows,
            isInGroupMode: true
         };
      };


      const refStatisticRepliedStatus = getStatisticRepliedStatus(rowsAllInTemplate, pageSheetTypeName, rfaData);

      return {
         drawingTypeTreeTable: drawingTypeTreeTemplate,
         rowsTable: rowsAllInTemplate,
         refStatisticRepliedStatus,
         modeFilter
      };



   } else if (
      pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' ||
      pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm' ||
      pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
   ) {

      const { publicSettings: { overdueLeadConsultantOnly } } = stateProject.allDataOneSheet;

      let rowsAllFinalMultiForm = pageSheetTypeName === 'page-rfa' ? [...rowsRfaAll] : [...rowsFormAll];

      if (Object.keys(modeSearch).length === 2) {
         const { isFoundShownOnly, searchDataObj } = modeSearch;
         if (isFoundShownOnly === 'show found only') {
            rowsAllFinalMultiForm = rowsAllFinalMultiForm.filter(row => row.id in searchDataObj);
         };
      };

      rowsAllFinalMultiForm = filterRowsOutput({ modeFilter, pageSheetTypeName, rfaData, rowsCurrentInView: rowsAllFinalMultiForm, overdueLeadConsultantOnly });

      let drawingTypeTreeOutput = drawingTypeTree.map(x => ({ ...x }));
      if (treeNodeIdFilterView) {
         const nodeFound = drawingTypeTree.find(x => x.id === treeNodeIdFilterView);
         if (nodeFound) {
            const nodeArray = getTreeFlattenOfNodeInArray(drawingTypeTree, nodeFound);
            drawingTypeTreeOutput = nodeArray.map(x => ({ ...x }));
         };
      };


      let refStatisticOverdue;
      if (pageSheetTypeName === 'page-rfa') {
         rowsAllFinalMultiForm = rowsAllFinalMultiForm.filter(r => drawingTypeTreeOutput.find(node => node['id'] === r['rfaNumber'])).sort((a, b) => a['Drawing Number'] > b['Drawing Number'] ? 1 : -1);
         refStatisticOverdue = getOverdueRfaInfo({ rowsAllFinalMultiForm, rfaData, overdueLeadConsultantOnly });
      } else {
         rowsAllFinalMultiForm = rowsAllFinalMultiForm.filter(r => drawingTypeTreeOutput.find(node => node['id'] === r['parentId'])).sort((a, b) => a[`${refType}Ref`] > b[`${refType}Ref`] ? 1 : -1);
         refStatisticOverdue = getOverdueMultiFormInfo({ rowsAllFinalMultiForm, pageSheetTypeName, overdueLeadConsultantOnly });
      };


      let refStatisticRepliedStatus;
      if (pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') {
         refStatisticRepliedStatus = getStatisticRepliedStatus(rowsAllFinalMultiForm, pageSheetTypeName, rfaData);
      };

      return {
         drawingTypeTreeTable: drawingTypeTreeOutput,
         rowsTable: rowsAllFinalMultiForm,
         refStatisticOverdue,
         refStatisticRepliedStatus
      };

   } else if (pageSheetTypeName === 'page-authorities-submission') {

      let rowOutput = [...rowsFileAll];

      if (Object.keys(modeSearch).length === 2) {
         const { isFoundShownOnly, searchDataObj } = modeSearch;
         if (isFoundShownOnly === 'show found only') {
            rowOutput = rowsFileAll.filter(row => row.id in searchDataObj);
         };
      };

      return {
         drawingTypeTreeTable: folderTree,
         rowsTable: rowOutput
      };
   };
};





export const getRowsKeyExpanded = (drawingTypeTree, viewTemplateNodeId) => {
   const templateNode = drawingTypeTree.find(x => x.id === viewTemplateNodeId);
   if (templateNode && viewTemplateNodeId) {
      const drawingTypeTreeTemplate = getTreeFlattenOfNodeInArray(drawingTypeTree, templateNode).filter(x => x.id !== templateNode.id);
      return drawingTypeTreeTemplate.map(x => x.id);
   } else {
      return drawingTypeTree.map(x => x.id);
   };
};
export const getHeadersData = (projectData) => {

   const { publicSettings, userSettings } = projectData;
   const { headers } = publicSettings;


   let headersShown, headersHidden, colorization, nosColumnFixed;

   if (!userSettings || Object.keys(userSettings).length === 0) {
      headersShown = headers.map(hd => hd.text);
      headersHidden = [];
      colorization = {};
      nosColumnFixed = 0;
   } else {
      headersShown = (userSettings.headersShown || headers.map(x => x.key)).map(hd => headers.find(h => h.key === hd).text);
      headersHidden = (userSettings.headersHidden || []).map(hd => headers.find(h => h.key === hd).text);
      colorization = userSettings.colorization || {};
      nosColumnFixed = userSettings.nosColumnFixed || 0;
   };

   return {
      headersShown,
      nosColumnFixed,
      headersHidden,
      colorization,
   };
};

export const getOutputRowsAllSorted = (drawingTypeTree, rowsAll) => {

   const drawingTypeTreeClone = drawingTypeTree.map(x => ({ ...x }));
   const treeTemp = convertFlattenArraytoTree1(drawingTypeTreeClone);

   if (treeTemp.length === 0) {
      return rowsAll;
   } else {
      let rowsOutput = [];
      const getIndex = (arr) => {
         arr.forEach(i => {
            if (i.children.length > 0) {
               getIndex(i.children);
            } else if (i.children.length === 0) {
               const rows = rowsAll.filter(r => r._parentRow === i.id);
               rowsOutput = [...rowsOutput, ...rows];
            };
         });
      };
      getIndex(treeTemp);
      return rowsOutput;
   };
};


const rearrangeRowsNotMatchTreeNode = (rows, rowsArranged, drawingTypeTree) => {
   const rowsToArrange = rows.filter(x => !rowsArranged.find(r => r.id === x.id));
   if (rowsToArrange.length === 0) {
      return { treeNodesToAdd: [], rowsToAdd: [], rowsUpdatePreOrParent: {} };
   };

   const parentIdsArr = [...new Set(rowsToArrange.map(x => x._parentRow))];

   let treeNodesToAdd = [];
   let rowsToAdd = [];
   let rowsUpdatePreOrParent = {}
   parentIdsArr.map(id => {
      const nodeInTree = drawingTypeTree.find(x => x.id === id);
      if (nodeInTree) {
         const newId = mongoObjectId();
         treeNodesToAdd.push({
            title: 'New Drawing Type',
            id: newId,
            parentId: nodeInTree.id,
            treeLevel: nodeInTree.treeLevel + 1,
            expanded: true,
         });
         const rowsFound = rowsToArrange.filter(x => x._parentRow === id);
         rowsFound.forEach((r, i) => {
            r._parentRow = newId;
            r._preRow = i === 0 ? null : rowsFound[i - 1].id;
            updatePreRowParentRowToState(rowsUpdatePreOrParent, r);
         });
         rowsToAdd = [...rowsToAdd, ...rowsFound];
      };
   });
   return {
      treeNodesToAdd,
      rowsToAdd,
      rowsUpdatePreOrParent
   };
};




export const headersConsultantWithNumber = [
   'Consultant (1)',
   'Consultant (2)',
   'Consultant (3)',
   'Consultant (4)',
   'Consultant (5)',
   'Consultant (6)',
   'Consultant (7)',
];




export const getHeadersForm = (pageSheetTypeName) => {

   if (pageSheetTypeName === 'page-rfa') {
      return [
         'RFA Ref',
         'Rev',
         'Drawing Number',
         'Drawing Name',
         'Requested By',
         'Submission Date',
         'Due Date',
         ...headersConsultantWithNumber,
         'Issue For Construction',
         'IFC Rev',
         'IFC Date'
      ];
   } else if (pageSheetTypeName === 'page-cvi') {
      return [
         'CVI Ref',
         'Subject',
         'Description',
         'Requested By',
         'Conversation Among',
         'Submission Date',
         'Conversation Date',
         'Cost Implication',
         'Time Extension',
         'Issue For Construction',
         'IFC Rev',
         'IFC Date',
         'Received By'
      ];
   } else if (pageSheetTypeName === 'page-rfam') {
      return [
         'RFAM Ref',
         'Subject',
         'Description',
         'Contract Specification',
         'Proposed Specification',
         'Requested By',
         'Submission Date',
         'Submission Type',
         'Due Date',
         ...headersConsultantWithNumber,
         'Issue For Construction',
         'IFC Rev',
         'IFC Date'
      ];
   } else if (pageSheetTypeName === 'page-rfi') {
      return [
         'RFI Ref',
         'Subject',
         'Description',
         'Requested By',
         'Submission Date',
         'Due Date',
         ...headersConsultantWithNumber,
         'Issue For Construction',
         'IFC Rev',
         'IFC Date'
      ];
   } else if (pageSheetTypeName === 'page-dt') {
      return [
         'DT Ref',
         'Subject',
         'Transmitted For',
         'Attachment Type',
         'Description',
         'Requested By',
         'Submission Date',
         'Received By',
         'Tags'
      ];
   } else if (pageSheetTypeName === 'page-mm') {
      return [
         'MM Ref',
         'Subject',
         'Submission Date',
         'Meeting Date',
         'Description',
      ];

   }else if (pageSheetTypeName === "page-eri") {
      return [
        "DCA ERI Number",
        "E-mail",
        "Subject",
        "Response Date",
        "Acknowledged Send On",
        "Status",
      ];
    } else if (pageSheetTypeName === "page-lr") {
      return [
        "S/N", 
        "To", 
        "Date", 
        "WHOFS Reference",
        "Description", 
        "Remark"];
    } else if (pageSheetTypeName === "page-sm") {
      return [
        "SM No.",
        "Trade",
        "Description",
        "Raised By",
        "Date Issued",
        "Date Cleared",
        "Status",
      ];
    } else if (pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') {
      return [
         pageSheetTypeName === 'page-qr' ? 'QR Ref' : 'QRM Ref',
         'Description',
         'Requested By',
         pageSheetTypeName === 'page-qr' ? 'RFA Info' : 'RFAM Info',
         'Submission Date',
         'Due Date',
         ...headersConsultantWithNumber,
      ];

   } else if (pageSheetTypeName === 'page-rfc') {
      return [
         'RFC Ref',
         'Description',
         'Requested By',
         'Attachment Type',
         'Submission Date',
         'Due Date',
         ...headersConsultantWithNumber,
      ];

   } else {
      return [];
   };
};


export const headersFormUploadFile = [
   'File Name',
   'Drawing Number',
   'Date',
   'Size',
   'Uploaded By',
];


const DataStatisticRibbon = ({ onClickquickFilter, stateRow, pageSheetTypeName, refStatisticOverdue }) => {

   const { modeFilter } = stateRow;

   const { Overall: refStatisticsAll } = refStatisticOverdue;

   const refType = getKeyTextForSheet(pageSheetTypeName);

   const isDropdown = window.innerWidth < 1100;
   const [dropdownValue, setDropdownValue] = useState('Due Date');
   useEffect(() => {
      if (modeFilter.length === 0) {
         setDropdownValue('Due Date');
      };
   }, [modeFilter]);

   return !isDropdown ? (
      <>
         {Object.keys(refStatisticsAll).map((item, i) => {
            return (
               <Tooltip key={i} placement={'top'} title={item}>
                  <div
                     style={{
                        marginLeft: 4, marginTop: 7,
                        display: 'flex',
                        cursor: 'pointer',
                        paddingLeft: 5, paddingRight: 3, height: 20, borderRadius: 3,
                        background: modeFilter.find(x => x['header'] === `Overdue ${refType.toUpperCase()}` && x.value === item) ? colorType.grey1 : 'transparent'
                     }}
                     onClick={() => onClickquickFilter(`Overdue ${refType.toUpperCase()}`, item)}
                  >
                     <span style={{ fontWeight: 'bold', marginRight: 2, fontSize: 12 }}>{refStatisticsAll[item]}</span>
                     <span style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: item === 'Due in the next 4 - 14 days' ? 'black'
                           : item === 'Replied' ? 'grey'
                              : colorTextRow[item]
                     }}>{
                           item === 'Due in the next 1 - 3 days' ? 'Due 1-3'
                              : item === 'Due in the next 4 - 14 days' ? 'Due 4-14'
                                 : item
                        }</span>
                  </div>
               </Tooltip>
            )
         })}
      </>
   ) : (

      <Select
         style={{ width: 120 }}
         onChange={(item) => {
            onClickquickFilter(`Overdue ${refType.toUpperCase()}`, item);
            setDropdownValue(item);
         }}
         value={modeFilter.length === 0 ? 'Due Date' : dropdownValue}
      >
         {Object.keys(refStatisticsAll).map((item, i) => (
            <Option value={item} key={i} style={{ fontWeight: 'bold', color: item === 'Due in the next 4 - 14 days' ? 'black' : colorTextRow[item] }}>
               {refStatisticsAll[item]} - {
                  item === 'Due in the next 1 - 3 days' ? 'Due 1-3'
                     : item === 'Due in the next 4 - 14 days' ? 'Due 4-14'
                        : item
               }
            </Option>
         ))}
      </Select>
   );
};


const DrawingStatusStatisticRight = ({ onClickquickFilter, stateRow, pageSheetTypeName, refStatisticRepliedStatus }) => {

   const { modeFilter, rfaData } = stateRow;

   const rowsToFilter = pageSheetTypeName === 'page-spreadsheet' ? stateRow.rowsAll
      : pageSheetTypeName === 'page-rfa' ? stateRow.rowsRfaAll
         : stateRow.rowsFormAll


   const isDropdown = window.innerWidth < 1315;
   const [dropdownValue, setDropdownValue] = useState('Status');
   useEffect(() => {
      if (modeFilter.length === 0) {
         setDropdownValue('Status');
      };
   }, [modeFilter]);

   const statusArray = pageSheetTypeName === 'page-rfi' ? ['replied', 'Consultant reviewing'] : (pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') ? [...arrayTextStatusQrQrm, 'Consultant reviewing']
      : pageSheetTypeName === 'page-rfc' ? [...arrayTextStatusRfc, 'Consultant reviewing']
         : arrayStatusDrawingText;

   return !isDropdown ? (
      <div style={{ display: 'flex', marginRight: 1, transform: 'translate(0, 5px)' }}>
         {rowsToFilter && statusArray.map(btn => (
            <Tooltip placement='bottomRight' title={btn === 'replied' ? 'Replied' : btn} key={btn}>
               <div
                  style={{
                     display: 'flex', paddingLeft: 5, paddingRight: 5, marginRight: 5, cursor: 'pointer', height: 20, borderRadius: 3,
                     background: modeFilter.find(x => x['header'] === 'Status' && x.value === btn) ? colorType.grey1 : 'transparent'
                  }}
                  onClick={() => onClickquickFilter('Status', btn)}
               >
                  <div style={{ width: 8, height: 8, marginRight: 3, background: btn === 'Consultant reviewing' ? 'white' : colorTextRow[btn], transform: 'translate(0, 6px)' }} />
                  <div style={{ fontSize: 12, paddingTop: 1 }}>
                     <span style={{ fontWeight: 'bold', marginRight: 3 }}>{refStatisticRepliedStatus[btn]}</span>
                     {btn === 'Approved with Comment, no submission Required' ? 'AC'
                        : btn === 'Approved with comments, to Resubmit' ? 'AC, R'
                           : btn === 'Approved for Construction' ? 'AP'
                              : btn === 'Consultant reviewing' ? 'UR'
                                 : btn === 'Reviewed And No Further Comments' ? 'RA'
                                    : btn === 'Reviewed With Comments No Submission Required' ? 'RC'
                                       : btn === 'For Record Only' ? 'RO'
                                          : btn === 'Received For Info' ? 'RI'
                                             : btn === 'replied' ? 'RA'
                                                : 'RR'}
                  </div>
               </div>
            </Tooltip>
         ))}
      </div>
   ) : (
      <Select
         style={{ width: 120 }}
         onChange={(btn) => {
            onClickquickFilter('Status', btn);
            setDropdownValue(btn);
         }}
         value={modeFilter.length === 0 ? 'Status' : dropdownValue}
      >
         {statusArray.map((btn, i) => (
            <Option value={btn} key={i} style={{ fontWeight: 'bold', color: colorTextRow[btn] }}>
               {<span style={{ fontWeight: 'bold', marginRight: 3 }}>{refStatisticRepliedStatus[btn]}</span>} - {
                  btn === 'Approved with Comment, no submission Required' ? 'AC'
                     : btn === 'Approved with comments, to Resubmit' ? 'AC, R'
                        : btn === 'Approved for Construction' ? 'AP'
                           : btn === 'Consultant reviewing' ? 'UR'
                              : btn === 'Reviewed And No Further Comments' ? 'RA'
                                 : btn === 'Reviewed With Comments No Submission Required' ? 'RC'
                                    : btn === 'For Record Only' ? 'RO'
                                       : btn === 'Received For Info' ? 'RI'
                                          : btn === 'replied' ? 'RA'
                                             : 'RR'}
            </Option>
         ))}
      </Select>
   );
};


const QuickFilterByDate = ({ stateRow, onClickquickFilter }) => {

   const { modeFilter, rfaData } = stateRow;

   const arrayDateBtn = ['Today', '3 Days', '7 Days', '14 Days', 'This Month'];

   const isDropdown = window.innerWidth < 1500;
   const [dropdownValue, setDropdownValue] = useState('Date Range');
   useEffect(() => {
      if (modeFilter.length === 0) {
         setDropdownValue('Date Range');
      };
   }, [modeFilter]);

   return !isDropdown ? (
      <div style={{ display: 'flex', transform: 'translate(0, 5px)' }}>
         {arrayDateBtn.map((btn, i) => {

            const foundFilter = modeFilter.find(x => x['header'] === 'Activity By Date');
            const foundFilterSameItem = modeFilter.find(x => x['header'] === 'Activity By Date' && x.value === btn);

            return (
               <Tooltip placement='bottomRight' title={btn === 'All' ? 'All Forms'
                  : btn === 'Today' ? 'Forms Submited Or Replied Today'
                     : btn === '3 Days' ? 'Forms Submited Or Replied Last 3 Days'
                        : btn === '7 Days' ? 'Forms Submited Or Replied Last 7 Days'
                           : btn === '14 Days' ? 'Forms Submited Or Replied Last 14 Days'
                              : 'Forms Submited Or Replied This Month'
               } key={i}>
                  <div
                     style={{
                        display: 'flex', paddingLeft: 5, paddingRight: 5, marginRight: 5, cursor: 'pointer', height: 20, borderRadius: 3,
                        background: ((!foundFilter && btn === 'All') || foundFilterSameItem) ? colorType.grey1 : 'transparent'
                     }}
                     onClick={() => onClickquickFilter('Activity By Date', btn)}
                  >{btn}</div>
               </Tooltip>
            )
         })}
      </div>
   ) : (
      <Select
         style={{ width: 120 }}
         onChange={(btn) => {
            onClickquickFilter('Activity By Date', btn);
            setDropdownValue(btn);
         }}
         value={modeFilter.length === 0 ? 'Date Range' : dropdownValue}
      >
         {arrayDateBtn.map((btn, i) => (
            <Option value={btn} key={i}>{btn}</Option>
         ))}
      </Select>
   );
};


export const getDrawingStatusByConsultant = (row, pageSheetTypeName, rfaData) => {

   const refType = getKeyTextForSheet(pageSheetTypeName);

   if (pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-spreadsheet') {
      const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']);
      if (rfaFound) {
         const consultantLead = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];
         if (consultantLead) {
            return getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', consultantLead) || 'Consultant reviewing';
         };
      };
   } else {
      const consultantLead = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [])[0];
      if (consultantLead) {
         return getInfoValueFromRefDataForm(row, 'reply', refType, 'status', consultantLead) || 'Consultant reviewing';
      };
   };
};


export const getStatisticRepliedStatus = (rows, pageSheetTypeName, rfaData) => {
   let refStatisticRepliedStatus = {};

   const statusArray = pageSheetTypeName === 'page-rfi' ? ['replied', 'Consultant reviewing'] : (pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') ? [...arrayTextStatusQrQrm, 'Consultant reviewing']
      : pageSheetTypeName === 'page-rfc' ? [...arrayTextStatusRfc, 'Consultant reviewing']
         : arrayStatusDrawingText;


   statusArray.forEach(statusText => {
      refStatisticRepliedStatus[statusText] = rows.filter(row => {
         return getDrawingStatusByConsultant(row, pageSheetTypeName, rfaData) === statusText;
      }).length;
   });
   return refStatisticRepliedStatus;
};





