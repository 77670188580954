import React from 'react'
import { Loader } from 'semantic-ui-react'
import Chart from 'react-google-charts';
import { generateColumnChart, getDataModelForChart } from './ManningScheduleFunction'
import _ from 'lodash'
import Draggable from 'react-draggable';
var listData = null;

export default class ManningScheduleContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listMark: [],
      data: []
    }

  }

  componentWillUnmount() {
    listData = null
  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.openDock) {
      let listCombine = await getDataModelForChart(nextProps)
      this.setState({ listMark: listCombine }, async () => {
        listData = await generateColumnChart(listCombine, [nextProps.smartSheetId, nextProps.manningScheduleId])
        let temp = []
        let header = ['Time']
        listData.head.forEach(item => {
          header.push(item)
        })
        temp.push(header)
        listData.data.forEach(item => {
          temp.push(_.values(item))
        })
        this.setState({ data: temp })
      })
      var domEl = document.getElementById('custom-dock-manpower-schedule')
      new ResizeObserver(() => {
        try {
          this.chartWrapper.draw();
        } catch{ }
      }).observe(domEl);    
    }
  }

  handleCloseDock = () => {
    this.props.onChangeDockDisplay('manningScheduleDock', false)
  }

  render() {
    const { data } = this.state
    const { viewer } = this.props
    const chartEvents = [
      {
        eventName: "select",
        callback({ chartWrapper }) {
          try {
            var row = chartWrapper.getChart().getSelection()[0].row
            var column = chartWrapper.getChart().getSelection()[0].column
            viewer.clearSelection();
            if (column === 1) {
              viewer.select(listData[row].id1);
            } else if (column === 2) {
              let array = []
              for (var i = 0; i <= row; i++) {
                array.push(listData[i].id1);
              }
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            } else if (column === 3) {
              viewer.select(listData[row].id2);
            } else if (column === 4) {
              let array = []
              for (var i = 0; i <= row; i++) {
                array.push(listData[i].id2);
              }
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            }

          } catch{ }
        }
      }
    ];

    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 350, width: 350 }}
          id='custom-dock-manpower-schedule' >
          <div className='custom-dock-panel-title'>Manpower Schedule</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{
              width: "100%", height: '100%', overflow: 'auto', position: 'absolute'
            }}>
              {data.length === 0
                ? <Loader style={{ top: "45%" }} active inline='centered' >Loading</Loader> :
                <Chart
                  width={'99%'}
                  height={'99%'}
                  chartType="ComboChart"
                  getChartWrapper={chartWrapper => {
                    this.chartWrapper = chartWrapper
                    chartWrapper.draw();
                  }}
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    isStacked: true,
                    pointSize: 7,
                    legend: { position: 'top', alignment: 'start' },
                    backgroundColor: 'white',
                    vAxis: {
                      gridlines: {
                        count: 0
                      }
                    },
                    hAxis: {
                      slantedText: true, slantedTextAngle: 45
                    },
                    seriesType: 'bars',
                    series: {
                      //  0:{targetAxisIndex:1}, 
                      //  1: {targetAxisIndex:1 } ,
                      // 2:{type: 'line' },
                      //   3: { type: 'line' }
                    },
                    // colors: ['#0000ff', '#000000','#0000ff', '#000000'],
                    bar: { groupWidth: '20%' },

                  }}
                  chartEvents={chartEvents}
                />
              }
            </div>
          </div>
          <div className='custom-dock-panel-footer'/>
        </div>
      </Draggable>

    )
  }
}