// import PageDashboard from './PageDashboard';
import { Button, Result } from 'antd';
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Loader } from 'rsuite';
import PageDashboard from '../../module_DMS/dashboard/PageDashboard';

const DashboardDrawing = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState('500')
  const [loadSheet, setLoadSheet] = useState(false)
  const [dataUser, setDataUser] = useState(null)

  useEffect(() => {
    setLoading(true)
    axios.post('/api/users/get-me-information', { token: props.userInfo.token })
      .then(res => {
        if (res.data.user.email !== props.userInfo.email) {
          setLoading(false)
          setError(true)
          setErrorCode('500')
          return
        }
        if (res.data.user.email !== 'admin@wohhup.com') {
          if (!res.data.user.permission.roleDashboard) {
            // setLoading(false)
            // setError(true)
            // setErrorCode('400')
            // return
          }
        }
        // let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === props.location.state.projectId })
        // if (res.data.user.email !== 'admin@wohhup.com') {
        //   if (index < 0 || !res.data.userInfo.contractors[index].isAccessDashboardDrawing) {
        //     setLoading(false)
        //     setError(true)
        //     setErrorCode('400')
        //     return
        //   }
        // }
        let temp = []
        _.forEach(res.data.user.projects, v => {
          temp.push({ name: v.projectName, id: v.projectId })
        })
        setDataUser(temp)
        // let role = undefined
        // if (index >= 0) {
        //   role = res.data.userInfo.contractors[index].role
        // }
        // setDataUser({
        //   email: res.data.user.email,
        //   role: res.data.user.role === 'admin' ? 'document controller' : role ? role : 'viewer', userName: res.data.userInfo.userName
        // })
        setLoadSheet(true)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])



  return (

    <>
      {!error ?
        loadSheet ?
          dataUser !== null &&
          <PageDashboard
            projectsArray={dataUser}
            token={props.userInfo.token}
          />

          :
          <Loader center content="Loading..." speed="fast" size="md" backdrop vertical style={{ zIndex: 100000 }} />
        :
        <Result
          status={errorCode === '400' ? "warning" : 'error'}
          title={errorCode}
          subTitle={errorCode === '400' ? "Please, contact admin project to access." :
          <>
          <p>Sorry, we are not able to connect to server at moment. Please try again later.</p>
          <p>You can contact to our web admin at phan_manhquyet@wohhup.com for feedback or any urgent issue. He is also can reach at number +6596969942.</p>
        </>}
          extra={[
            <Button type="primary" key="console" onClick={() => props.history.push("/")}>Back Home</Button>,

          ]}
        >
        </Result>}
    </>
  );
};


DashboardDrawing.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    userInfo: state.user,
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
  };
}
export default connect(mapStateToProps)(DashboardDrawing)