import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Statistic, Space } from "antd";

const SideMenu = ({ current, setcurrent, filterMode, statistic, setData, setTempData, setStatistic }) => {
   useEffect(() => {
      // setStaticData(getdata);
   }, [statistic, filterMode]);

   const HandleClick = (e) => {
      if (current !== e.key) {
         setData([]);
         setTempData([]);
         setStatistic([]);
      }

      setcurrent(e.key);
   };

   return (
      <div className="doinv-sidebar">
         <h6 style={{ color: "yellow", textAlign: "center" }}>Do-Invoice</h6>
         <Menu defaultSelectedKeys={["conc_do"]} onClick={(e) => HandleClick(e)} theme="dark" className="left-menu">
            <Menu.Item key="sum">Summary</Menu.Item>
            {/* <Menu.Item key="po">PO</Menu.Item> */}
            <Menu.Item key="conc_do">Conc DO</Menu.Item>
            <Menu.Item key="conc_inv">Conc Inv</Menu.Item>
            <Menu.Item key="rebar_do">Rebar DO</Menu.Item>
            <Menu.Item key="rebar_inv">Rebar Inv</Menu.Item>
            {/* <Menu.Item key="cubetest">Cube test</Menu.Item> */}
         </Menu>

         <div className="side-menu-statistic">
            {/* <Space direction="vertical" size={10} style={{ width: 100 }} wrap> */}
            {typeof statistic != "undefined"
               ? statistic.map((d) => {
                    return (
                       <div>
                          <Statistic
                             title={d.name}
                             value={d.value}
                             valueStyle={{ color: "darkblue", fontSize: 18 }}
                             prefix={typeof d.prefix !== "undefined" ? d.prefix : null}
                             style={{ width: 100 }}
                          />
                          <br></br>
                       </div>
                    );
                 })
               : null}
            <br></br>
            {/* </Space> */}
         </div>
      </div>
   );
};

export default SideMenu;
