import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import {
   apiGetDataForCharts,
   apiGetDataForChartsCost,
   getTemplateExcel,
   apiGetTimeSheetWorkers,
   apiGetSummaryCostCode,
   apiGetSummaryCostCode2,
   searchHoliday,
   forceUpdateData,
} from "../api/dash-board";

import {
   Row,
   Col,
   DatePicker,
   Select,
   Result,
   Button,
   Checkbox,
   Radio,
   Tabs,
   Tag,
   Typography,
   notification,
} from "antd";

import TimeStackedBarChart from "./Charts/TimeStackedBarChart";
import TimePieChartPercent from "./Charts/TimePieChartPercent";
import TimePieChartCost from "./Charts/TimePieChartCost";
import WorkerTimeSheetTable from "./Tables/WorkerTimeSheetTable";
import ShiftDataTable from "./Tables/ShiftDataTable";
import CostCodeTableDetail from "./Tables/CostCodeTableDetail";
import CostCodeTradeTableDetail from "./Tables/CostCodeTradeTableDetail";
import CostCodeTableSummary from "./Tables/CostCodeTableSummary";
import WagesTable from "./Tables/WagesTable";
import SummaryTable from "./Tables/SummaryTable";
import PointsTable from "./Tables/PointsTable";
import ToHrTable from "./Tables/ToHrTable";
import ToAcountTable from "./Tables/ToAcountTable";
import TimeCardTable from "./Tables/TimeCardTable";
import { connect, useSelector } from "react-redux";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from "file-saver";
import stream from "stream";
const { Title } = Typography;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

function DashBoard(props) {
   const [id, setId] = useState(null);
   const [intReload, setIntReload] = useState(0);
   const [tabIndex, setTabIndex] = useState(1);
   const [updatedAt, setUpdatedAt] = useState("");
   const [allHolidays, setAllHolidays] = useState([]);
   const [height, setHeight] = useState(500);
   const [timeTypeForBarChart, setTimeTypeForBarChart] = useState("month");
   const [dataStackedCost, setDataStackedCost] = useState(null);
   const [dataStackedTime, setDataStackedTime] = useState(null);
   const [currentMonthTimeSheet, setCurrentMonthTimeSheet] = useState(moment().format("MM-YYYY"));
   const [dataTimeSheet, setDataTimeSheet] = useState(null);
   const [cutOffDate, setCutOffDate] = useState(22);
   const [selectedChartYear, setSelectedChartYear] = useState(moment().year());
   const [selectedChartMonthYear, setSelectedChartMonthYear] = useState(moment().format("MM-YYYY"));
   const [exportExcelCostTradeDetail, setExportExcelCostTradeDetail] = useState(0);
   const [exportExcelCostDetail, setExportExcelCostDetail] = useState(0);
   const [exportExcelCostcodeSummary, setExportExcelCostcodeSummary] = useState(0);
   const [dataCharts, setDataCharts] = useState({});
   const [dataChartsCost, setDataChartsCost] = useState({});
   const [includeOutsourceWorker, setIncludeOutsourceWorker] = useState(false);
   const [dataCostCodes, setDataCostCodes] = useState({
      costCodeDatasByName: null,
      costCodeDatasById: null,
   });
   const [dataCostCodesAll, setDataCostCodesAll] = useState({
      costCodeDatasByName: null,
      costCodeDatasById: null,
   });

   const projectId = useSelector((state) => {
      return state.project.projectId;
   });

   const projectName = useSelector((state) => {
      return state.project.projectName;
   });

   const [timeDateCostCode, setTimeDateCostCode] = useState([moment().startOf("year"), moment().endOf("year")]);

   useEffect(() => {
      searchHoliday().then((r) => {
         setAllHolidays(r.data?.items);
      });
   }, []);

   useEffect(() => {
      if (projectId && currentMonthTimeSheet) {
         getDatasTimeSheet();
      }
   }, [currentMonthTimeSheet]);

   useEffect(() => {
      if (projectId) {
         getDataForCharts();
         getDataForChartsCost();
         getCurrentMonthCutOffDate();
         getDatasTimeSheet();
         getDataCostCodes();
         apiGetSummaryCostCode2(projectId, includeOutsourceWorker).then((resAll) => {
            if (_.isNil(resAll.data?.metadata)) {
               setDataCostCodes({
                  costCodeDatasByName: null,
                  costCodeDatasById: null,
               });
            } else {
               setDataCostCodesAll(resAll.data.metadata);
            }
         });
      }
   }, [projectId, includeOutsourceWorker, intReload]);

   useEffect(() => {
      let dtTime = getDataStackedPileChartByTime();
      let dtCost = getDataStackedPileChartByTime(dataChartsCost);

      setDataStackedTime(dtTime);
      setDataStackedCost(dtCost);
   }, [timeTypeForBarChart, selectedChartMonthYear, dataChartsCost]);

   useEffect(() => {
      if (projectId) {
         getDataCostCodes();
      }
   }, [projectId, includeOutsourceWorker, timeDateCostCode]);

   async function getDataCostCodes() {
      let request = {
         projectId,
         includeOutsourceWorker,
         start: timeDateCostCode[0].format("YYYY-MM-DD"),
         end: timeDateCostCode[1].format("YYYY-MM-DD"),
      };

      apiGetSummaryCostCode(request).then((res) => {
         if (_.isNil(res)) {
            setDataCostCodes({
               costCodeDatasByName: null,
               costCodeDatasById: null,
            });
         } else {
            setDataCostCodes(res);
         }
      });
   }

   async function getDatasTimeSheet() {
      let month = moment(currentMonthTimeSheet, "MM-YYYY").month() + 1;
      let year = moment(currentMonthTimeSheet, "MM-YYYY").year();
      setDataTimeSheet(null);
      let res = await apiGetTimeSheetWorkers(projectId, `${year}-${month}`, includeOutsourceWorker);

      if (_.isNil(res.data)) {
         setDataTimeSheet([]);
      } else {
         setDataTimeSheet(res.data.metadata);
      }
   }

   async function getDataForCharts() {
      let res = await apiGetDataForCharts(projectId, includeOutsourceWorker);
      try {
         let mm = moment(new Date(res.data.updatedAt));
         setUpdatedAt(mm.format("DD-MM-YY HH:mm"));
      } catch (error) {}

      setDataCharts(res.data.metadata);
   }

   async function getDataForChartsCost() {
      let res = await apiGetDataForChartsCost(projectId, includeOutsourceWorker);
      setDataChartsCost(res.data.metadata);
      setCutOffDate(res.data.metadata.cutOffDate);
   }

   function getCurrentMonthCutOffDate() {
      let month = moment().subtract(1, "month").format("MMM");

      let s = `(from ${cutOffDate}-${month})`;
      return s;
   }
   function getTomorrowOfCurrentMonthCutOffDate() {
      let month = moment().subtract(1, "month").format("MMM");

      let s = `(from ${cutOffDate + 1}-${month})`;
      return s;
   }

   function formatCurrency(number) {
      try {
         if (number) {
            let formatter = new Intl.NumberFormat("en-US", {
               style: "currency",
               currency: "USD",
            });

            let str = formatter.format(number);
            return str.substring(0, str.length - 1);
         }
      } catch {
         return "";
      }
   }

   function onChangeMonthPickerTimeSheet(date, dateString) {
      setCurrentMonthTimeSheet(dateString);
   }

   function onChangeMonthPickerChart(date, dateString) {
      setSelectedChartMonthYear(dateString);
   }

   async function exportTimeSheet() {
      let buff = await getTemplateExcel();
      const buffer = Buffer.from(buff);
      const readStream = new stream.PassThrough();
      readStream.end(buffer);
      const wb = await new ExcelJS.Workbook().xlsx.read(readStream);

      let excelName = "a";

      let startMoment = moment(currentMonthTimeSheet, "MM-YYYY")
         .subtract(1, "months")
         .set("date", cutOffDate + 1);
      let start = startMoment.format("MM/DD/YYYY");

      let currentMonthMoment = moment(currentMonthTimeSheet, "MM-YYYY");
      let endMoment = currentMonthMoment.set("date", cutOffDate);

      let allHolidayDateString = allHolidays.map((x) => moment(x.date).format("DD-MM-YY"));
      let colorHomeLeave = "FF99FF";
      let colorAbsent = "FF0000";
      let colorCourse = "FFC000";
      let colorSunday = "ACB9CA";
      let colorMc = "00FF00";
      let colorPulicHoliday = "C6E0B4";

      let offDayColors = [
         {
            name: "Medical (MC)",
            value: colorMc,
         },
         {
            name: "Course / Training",
            value: colorCourse,
         },
         {
            name: "Home Leave",
            value: colorHomeLeave,
         },
         {
            name: "Absent",
            value: colorAbsent,
         },
      ];

      //wage
      if (true) {
         let columnHolidays = [];
         let columnSundays = [];
         let columnCourses = [];
         let columnMcs = [];
         let worksheet = wb.getWorksheet("Wage");
         let rowIndex = 8;

         let delta = endMoment.daysInMonth() + (startMoment.daysInMonth() - cutOffDate - 1);

         var row4 = worksheet.getRow(4);
         excelName = `WORKERS LIST SUMMARY FORM - PAYROLL MONTH OF ${endMoment.format("MMM")}`;
         row4.getCell(9).value = excelName;

         var row6 = worksheet.getRow(6);
         row6.getCell(13).value = start;

         let groupByOccupation = _.groupBy(dataTimeSheet, "occupation");
         let occupations = Object.keys(groupByOccupation);

         occupations.sort();

         occupations.forEach((occupation) => {
            let timeSheetsInOccupation = groupByOccupation[occupation];

            timeSheetsInOccupation.forEach((worker, index) => {
               if (index == 0) {
                  //add occupation row
                  worksheet.duplicateRow(rowIndex, 1, true);

                  let rowOc = worksheet.getRow(rowIndex);
                  rowOc.getCell(1).value = occupation;
                  rowIndex++;
               }

               let startMomentTemp = moment(startMoment);
               if (index != dataTimeSheet.length - 1) {
                  worksheet.duplicateRow(rowIndex, 1, true);
               }

               let row = worksheet.getRow(rowIndex);
               row.getCell(1).value = index + 1;
               row.getCell(2).value = worker.employeeCode;
               row.getCell(3).value = worker.name;
               row.getCell(9).value = {
                  formula: `SUMIF($M$7:$GB$7,$I$7,M${rowIndex}:GB${rowIndex})`,
                  result: 7,
               };

               row.getCell(10).value = {
                  formula: `SUMIF($M$7:$GB$7,$J$7,M${rowIndex}:GB${rowIndex})`,
                  result: 7,
               };

               row.getCell(11).value = {
                  formula: `SUMIF($M$7:$GB$7,$K$7,M${rowIndex}:GB${rowIndex})`,
                  result: 7,
               };

               row.getCell(12).value = {
                  formula: `SUMIF($M$7:$GB$7,$L$7,M${rowIndex}:GB${rowIndex})`,
                  result: 7,
               };

               worker.timeWorkingInDays.forEach((x, dayIndex) => {
                  row6.getCell(13 + dayIndex * 4).value = startMomentTemp.format("DD/MM/YYYY");

                  row.getCell(13 + dayIndex * 4).value = x.rate1;
                  row.getCell(14 + dayIndex * 4).value = 0; //ph
                  row.getCell(15 + dayIndex * 4).value = 0;
                  row.getCell(16 + dayIndex * 4).value = 0;
                  if (!x.isAfterCutOff) {
                     row.getCell(15 + dayIndex * 4).value = x.rate15;
                     row.getCell(16 + dayIndex * 4).value = x.rate20;
                  }

                  //check is sunday
                  if (startMomentTemp.isoWeekday() === 7) {
                     columnSundays.push(13 + dayIndex * 4);
                     columnSundays.push(14 + dayIndex * 4);
                     columnSundays.push(15 + dayIndex * 4);
                     columnSundays.push(16 + dayIndex * 4);
                  }

                  //public holiday
                  if (x.dayType == 3) {
                     row.getCell(13 + dayIndex * 4).value = 0;
                     row.getCell(14 + dayIndex * 4).value = x.phWidthRate;

                     columnHolidays.push(13 + dayIndex * 4);
                     columnHolidays.push(14 + dayIndex * 4);
                     columnHolidays.push(15 + dayIndex * 4);
                     columnHolidays.push(16 + dayIndex * 4);
                  }

                  //set training  or mc
                  if (x.isOffDayHasToPay) {
                     let isMc = x.offDayType == "Medical (MC)";

                     let isTraining = x.offDayType == "Course / Training";

                     if (isMc) {
                        setCellColor(row.getCell(13 + dayIndex * 4), colorMc);
                        setCellColor(row.getCell(14 + dayIndex * 4), colorMc);
                        setCellColor(row.getCell(15 + dayIndex * 4), colorMc);
                        setCellColor(row.getCell(16 + dayIndex * 4), colorMc);
                     }

                     if (isTraining) {
                        setCellColor(row.getCell(13 + dayIndex * 4), colorCourse);
                        setCellColor(row.getCell(14 + dayIndex * 4), colorCourse);
                        setCellColor(row.getCell(15 + dayIndex * 4), colorCourse);
                        setCellColor(row.getCell(16 + dayIndex * 4), colorCourse);
                     }
                  }

                  //check is after today
                  if (!startMomentTemp.isAfter(moment())) {
                     //check is off day
                     if (x.isDayOff) {
                        setCellColor(row.getCell(13 + dayIndex * 4), colorHomeLeave);
                        setCellColor(row.getCell(14 + dayIndex * 4), colorHomeLeave);
                        setCellColor(row.getCell(15 + dayIndex * 4), colorHomeLeave);
                        setCellColor(row.getCell(16 + dayIndex * 4), colorHomeLeave);

                        x.shiftTrades.forEach((shift) => {
                           let offDayColor = offDayColors.find((offDayItem) => offDayItem.name == shift.costCodeName);
                           if (offDayColor) {
                              //set color
                              setCellColor(row.getCell(13 + dayIndex * 4), offDayColor.value);
                              setCellColor(row.getCell(14 + dayIndex * 4), offDayColor.value);
                              setCellColor(row.getCell(15 + dayIndex * 4), offDayColor.value);
                              setCellColor(row.getCell(16 + dayIndex * 4), offDayColor.value);
                           }
                        });

                        //default unpaid leave
                     }
                  }

                  startMomentTemp = startMomentTemp.add(1, "days");
               });
               rowIndex++;
            });
         });

         worksheet.getCell(`I${rowIndex}`).value = {
            formula: `Sum(I8:I${rowIndex - 1})`,
            result: 7,
         };

         worksheet.getCell(`J${rowIndex}`).value = {
            formula: `Sum(J8:J${rowIndex - 1})`,
            result: 7,
         };

         worksheet.getCell(`K${rowIndex}`).value = {
            formula: `Sum(K8:K${rowIndex - 1})`,
            result: 7,
         };

         worksheet.getCell(`L${rowIndex}`).value = {
            formula: `Sum(L8:L${rowIndex - 1})`,
            result: 7,
         };

         columnHolidays = _.uniq(columnHolidays);
         for (var i = 8; i < rowIndex; i++) {
            let row = worksheet.getRow(i);
            columnHolidays.forEach((c) => {
               setCellColor(row.getCell(c), colorPulicHoliday);
            });

            columnSundays.forEach((c) => {
               setCellColor(row.getCell(c), colorSunday);
            });
         }

         //Delete columns
         let lastColumnIndex = 16 + delta * 4 + 1;
         worksheet.spliceColumns(lastColumnIndex, 100);
      }

      //Points
      if (true) {
         let worksheet = wb.getWorksheet("Points");
         let rowIndex = 5;
         let startMoment = moment(currentMonthTimeSheet, "MM-YYYY")
            .subtract(1, "months")
            .set("date", cutOffDate + 1);

         let start = startMoment.format("DD/MM/YYYY");
         let end = endMoment.format("DD/MM/YYYY");

         let delta = cutOffDate + (startMoment.daysInMonth() - cutOffDate) + 1;

         var row3 = worksheet.getRow(3);
         row3.height = 70;
         var row4 = worksheet.getRow(4);

         let tempStartMoment = moment(startMoment);
         let tempStartCoumnDate = 4;

         worksheet.getCell(
            "E1"
         ).value = `WORKERS LIST SUMMARY FORM - PAYROLL MONTH OF MAY 2021 (${start} TO  ${end} INCENTIVE POINTS)`;
         while (!tempStartMoment.isAfter(endMoment)) {
            //compute points for this day
            let daystring = tempStartMoment.format("DD-MM-YY");

            row3.getCell(tempStartCoumnDate).value = daystring;

            tempStartCoumnDate++;
            tempStartMoment = tempStartMoment.add(1, "days");
         }

         let grandTotalPoints = 0.0;

         dataTimeSheet.forEach((worker, index) => {
            if (index != dataTimeSheet.length - 1) {
               worksheet.duplicateRow(rowIndex, 1, true);
            }
            let row = worksheet.getRow(rowIndex);
            row.getCell(1).value = index + 1;
            row.getCell(2).value = worker.employeeCode;
            row.getCell(3).value = worker.name;

            let totalPoints = 0.0;
            worker.timeWorkingInDays.forEach((x, dayIndex) => {
               if (!x.isAfterCutOff) {
                  row.getCell(4 + dayIndex).value = x.point;
                  totalPoints += x.point;

                  //set color off day
                  //check is off day
                  if (x.isDayOff) {
                     setCellColor(row.getCell(4 + dayIndex), colorHomeLeave);

                     x.shiftTrades.forEach((shift) => {
                        let offDayColor = offDayColors.find((offDayItem) => offDayItem.name == shift.costCodeName);
                        if (offDayColor) {
                           //set color
                           setCellColor(row.getCell(4 + dayIndex), offDayColor.value);
                        }
                     });

                     //default unpaid leave
                  }

                  //set color sunday
                  if (moment(x.dayYearString, "DD-MM-YY").weekday() === 0) {
                     let cell = row.getCell(4 + dayIndex);
                     let cellRow3 = row3.getCell(4 + dayIndex);
                     let cellRow4 = row4.getCell(4 + dayIndex);
                     setCellColor(cell);
                     setCellColor(cellRow3);
                     setCellColor(cellRow4);
                  }

                  //set color public holiday
                  if (allHolidayDateString.some((m) => x.dayYearString == m)) {
                     setCellColor(row.getCell(4 + dayIndex), colorPulicHoliday);
                  }

                  //set training  or mc
                  if (x.isOffDayHasToPay) {
                     let isMc = x.offDayType == "Medical (MC)";

                     let isTraining = x.offDayType == "Course / Training";

                     if (isMc) {
                        setCellColor(row.getCell(4 + dayIndex), colorMc);
                     }

                     if (isTraining) {
                        setCellColor(row.getCell(4 + dayIndex), colorCourse);
                     }
                  }
               }
            });

            row.getCell(36).value = totalPoints;
            grandTotalPoints += totalPoints;
            rowIndex++;
         });

         worksheet.getRow(rowIndex + 2).getCell(36).value = grandTotalPoints;

         let lastColumnIndex = 4 + delta;
         if (lastColumnIndex + 1 <= 35) {
            worksheet.spliceColumns(lastColumnIndex, 35 - lastColumnIndex);
         }
      }

      //Summary sheet
      if (true) {
         let worksheet = wb.getWorksheet("Summary");
         let rowIndex = 24;
         let startMoment = moment(currentMonthTimeSheet, "MM-YYYY")
            .subtract(1, "months")
            .set("date", cutOffDate + 1);

         var row3 = worksheet.getRow(3);
         row3.height = 70;
         var row4 = worksheet.getRow(4);

         worksheet.getCell("F10").value = projectName;
         worksheet.getCell(
            "F18"
         ).value = `WORKERS LIST SUMMARY FORM - PAYROLL MONTH OF FEBRUARY 2022 (OT - ${startMoment.format(
            "DD-MM-YYYY"
         )} TO ${endMoment.format("DD-MM-YYYY")}`;

         let dayDatas = [];
         dataTimeSheet.forEach((worker) => {
            if (_.isArray(worker.timeWorkingInDays)) {
               worker.timeWorkingInDays.forEach((dayData) => {
                  dayDatas.push(dayData);
               });
            }
         });

         let groupByTrade = _.groupBy(dayDatas, "occupation");

         let trades = Object.keys(groupByTrade);

         trades.sort();
         trades.forEach((trade, index) => {
            worksheet.duplicateRow(rowIndex, 1, true);

            let itemInGroups = groupByTrade[trade];
            let totalWorker = _.uniq(itemInGroups.map((x) => x.idWorker)).length;
            let totalNormalHours = _.sumBy(itemInGroups, "rate1");

            let totalPublicHolidayHours = _.sumBy(itemInGroups, "ph");

            //check after cut off
            let total15Hours = _.sumBy(itemInGroups, "rate15");

            let total20Hours = _.sumBy(itemInGroups, "rate20");

            worksheet.getCell(`A${rowIndex}`).value = index + 1;
            worksheet.getCell(`B${rowIndex}`).value = trade;
            worksheet.getCell(`F${rowIndex}`).value = totalWorker;
            worksheet.getCell(`G${rowIndex}`).value = totalNormalHours;
            worksheet.getCell(`H${rowIndex}`).value = totalPublicHolidayHours;
            worksheet.getCell(`I${rowIndex}`).value = total15Hours;
            worksheet.getCell(`J${rowIndex}`).value = total20Hours;

            rowIndex++;
         });

         worksheet.getCell(`B${rowIndex}`).value = "Grand Total";

         ["F", "G", "H", "I", "J"].forEach((column) => {
            worksheet.getCell(`${column}${rowIndex}`).value = {
               formula: `SUM(${column}${24}:${column}${rowIndex - 1})`,
               result: 7,
            };
         });

         for (let i = 1; i < 16; i++) {
            setCellColor(worksheet.getRow(rowIndex).getCell(i));
         }
      }

      //To Accounts
      if (true) {
         let worksheet = wb.getWorksheet("TO ACCOUNTS");
         let rowIndex = 9;
         let startMoment = moment(currentMonthTimeSheet, "MM-YYYY")
            .subtract(1, "months")
            .set("date", cutOffDate + 1);

         worksheet.getCell("A6").value = projectName;
         worksheet.getCell("A7").value = `PERIOD: ${endMoment.format(
            "MMM-YY"
         )} ( Incentive Points  - ${startMoment.format("DD-MM-YYYY)")} TO ${endMoment.format("DD-MM-YYYY")}`;

         let dayDatas = [];

         dataTimeSheet.forEach((worker) => {
            if (_.isArray(worker.timeWorkingInDays)) {
               worker.timeWorkingInDays.forEach((dayData) => {
                  dayDatas.push(dayData);
               });
            }
         });

         let groupByTrade = _.groupBy(dayDatas, "occupation");

         let trades = Object.keys(groupByTrade);
         //assign trade

         trades.sort();

         let indexWorker = 1;
         trades.forEach((trade) => {
            let groupByWorkerId = _.groupBy(groupByTrade[trade], "idWorker");

            let tradeCell = worksheet.getCell(`A${rowIndex}`);
            tradeCell.value = trade;

            setCellBoldUnderlineAndLeft(tradeCell, true, true, "left");
            rowIndex++;
            Object.keys(groupByWorkerId).forEach((workerId) => {
               let workerName = groupByWorkerId[workerId][0].name;
               let employeeCode = groupByWorkerId[workerId][0].code;
               worksheet.duplicateRow(rowIndex, 1, false);

               //index
               worksheet.getCell(`A${rowIndex}`).value = indexWorker;
               setCellBoldUnderlineAndLeft(worksheet.getCell(`A${rowIndex}`), false, false, "center");

               //worker code
               worksheet.getCell(`B${rowIndex}`).value = employeeCode;

               //worker name
               worksheet.getCell(`C${rowIndex}`).value = workerName;

               let sumPoint = _.round(_.sumBy(groupByWorkerId[workerId], "point"), 2);

               worksheet.getCell(`D${rowIndex}`).value = !_.isNaN(sumPoint) ? sumPoint : 0;

               rowIndex++;
               indexWorker++;
            });
            worksheet.duplicateRow(rowIndex, 1, false);
         });

         worksheet.getCell(`B${rowIndex}`).value = "Grand Total";

         ["D"].forEach((column) => {
            worksheet.getCell(`${column}${rowIndex}`).value = {
               formula: `SUM(${column}${9}:${column}${rowIndex - 1})`,
               result: 7,
            };
         });

         for (let i = 1; i < 5; i++) {
            setCellColor(worksheet.getRow(rowIndex).getCell(i));
         }
      }

      //To Hr
      if (true) {
         let worksheet = wb.getWorksheet("TO HR");
         let rowIndex = 7;
         let startMoment = moment(currentMonthTimeSheet, "MM-YYYY")
            .subtract(1, "months")
            .set("date", cutOffDate + 1);

         let dayDatas = [];

         dataTimeSheet.forEach((worker) => {
            if (_.isArray(worker.timeWorkingInDays)) {
               worker.timeWorkingInDays.forEach((dayData) => {
                  dayDatas.push(dayData);
               });
            }
         });

         let groupByTrade = _.groupBy(dayDatas, "occupation");

         let trades = Object.keys(groupByTrade);

         trades.sort();

         //set date
         let row6 = worksheet.getRow(6);

         let tempStartCoumnDate = 6;
         let maxColumnIndex = 6 + 30;
         {
            let tempStartMoment = moment(startMoment);

            while (!tempStartMoment.isAfter(endMoment)) {
               row6.getCell(tempStartCoumnDate).value = tempStartMoment.format("DD-MMM-YY");
               tempStartCoumnDate++;
               tempStartMoment = tempStartMoment.add(1, "days");
            }

            maxColumnIndex = tempStartCoumnDate;

            row6.getCell(tempStartCoumnDate).value = "TOTAL INCENTIVE POINTS";
            row6.getCell(tempStartCoumnDate + 1).value = "TOTAL DAYS WORKED";
         }

         //assign trade

         let indexWorker = 1;
         trades.forEach((trade) => {
            let groupByWorkerId = _.groupBy(groupByTrade[trade], "idWorker");

            let tradeCell = worksheet.getCell(`A${rowIndex}`);
            tradeCell.value = trade;
            worksheet.duplicateRow(rowIndex, 1, true);

            // setCellBoldUnderlineAndLeft(tradeCell, true, true, 'left');
            rowIndex++;
            Object.keys(groupByWorkerId).forEach((workerId) => {
               let supervisors = groupByWorkerId[workerId]
                  .map((x) => x.supervisor)
                  .filter((x) => _.isString(x) && x.length > 0);

               let workerName = groupByWorkerId[workerId][0].name;
               let supervisor = supervisors.length > 0 ? supervisors[0] : "";
               let employeeCode = groupByWorkerId[workerId][0].code;
               let workerDesignation = groupByWorkerId[workerId][0].workerDesignation;
               worksheet.duplicateRow(rowIndex, 1, true);

               //index
               worksheet.getCell(`A${rowIndex}`).value = indexWorker;

               //worker code
               worksheet.getCell(`B${rowIndex}`).value = employeeCode;

               //worker name
               worksheet.getCell(`C${rowIndex}`).value = workerName;
               worksheet.getCell(`D${rowIndex}`).value = supervisor;
               worksheet.getCell(`E${rowIndex}`).value = workerDesignation;

               {
                  let tempStartMoment = moment(startMoment);
                  tempStartCoumnDate = 6;
                  let rowAtIndex = worksheet.getRow(rowIndex);
                  while (!tempStartMoment.isAfter(endMoment)) {
                     //compute points for this day
                     let daystring = tempStartMoment.format("DD-MM-YY");
                     let sumPoint = _.round(
                        _.sumBy(
                           groupByWorkerId[workerId].filter((item) => item.dayYearString == daystring),
                           "point"
                        ),
                        2
                     );

                     let shifts = groupByWorkerId[workerId].filter((item) => item.dayYearString == daystring);

                     rowAtIndex.getCell(tempStartCoumnDate).value = !_.isNaN(sumPoint) ? sumPoint : 0;

                     let isDayOff = shifts.some((x) => x.isDayOff == true);

                     //check is after today
                     if (!tempStartMoment.isAfter(moment())) {
                        //check is off day
                        if (isDayOff) {
                           setCellColor(rowAtIndex.getCell(tempStartCoumnDate), colorHomeLeave);

                           shifts.forEach((shift) => {
                              let offDayColor = offDayColors.find(
                                 (offDayItem) => offDayItem.name == shift.costCodeName
                              );
                              if (offDayColor) {
                                 setCellColor(rowAtIndex.getCell(tempStartCoumnDate), offDayColor.value);
                              }
                           });
                        }
                     }

                     //sun day
                     if (tempStartMoment.isoWeekday() === 7) {
                        setCellColor(rowAtIndex.getCell(tempStartCoumnDate), colorSunday);
                     }

                     //set color public holiday
                     if (allHolidayDateString.some((m) => daystring == m)) {
                        setCellColor(rowAtIndex.getCell(tempStartCoumnDate), colorPulicHoliday);
                     }

                     let isOffDayHasToPay = shifts.some((x) => x.isOffDayHasToPay == true);
                     //set training  or mc
                     if (isOffDayHasToPay) {
                        let isMc = shifts.some((x) => x.offDayType == "Medical (MC)");

                        let isTraining = shifts.some((x) => x.offDayType == "Course / Training");

                        if (isMc) {
                           setCellColor(rowAtIndex.getCell(tempStartCoumnDate), colorMc);
                        }

                        if (isTraining) {
                           setCellColor(rowAtIndex.getCell(tempStartCoumnDate), colorCourse);
                        }
                     }

                     tempStartCoumnDate++;

                     tempStartMoment = tempStartMoment.add(1, "days");
                  }

                  let columnAddress = worksheet
                     .getRow(rowIndex)
                     .getCell(maxColumnIndex - 1)
                     ._address.replace(/[0-9]/g, "");
                  worksheet.getRow(rowIndex).getCell(maxColumnIndex).value = {
                     formula: `SUM(F${rowIndex}:${columnAddress}${rowIndex})`,
                     result: 7,
                  };

                  worksheet.getRow(rowIndex).getCell(maxColumnIndex + 1).value = {
                     formula: `COUNTIF(F${rowIndex}:${columnAddress}${rowIndex},">0")`,
                     result: 7,
                  };
               }
               rowIndex++;
               indexWorker++;
            });
            worksheet.duplicateRow(rowIndex, 1, false);
         });

         worksheet.getCell(`B${rowIndex}`).value = "Grand Total";
         worksheet.getCell(`A${rowIndex}`).value = "";
         worksheet.getCell(`A${rowIndex + 1}`).value = "";

         for (let columnIndex = 6; columnIndex < maxColumnIndex + 2; columnIndex++) {
            let columnAddress = worksheet.getRow(rowIndex).getCell(columnIndex)._address.replace(/[0-9]/g, "");
            worksheet.getRow(rowIndex).getCell(columnIndex).value = {
               formula: `SUM(${columnAddress}${8}:${columnAddress}${rowIndex - 1})`,
               result: 7,
            };
         }

         for (let i = 1; i < maxColumnIndex + 2; i++) {
            setCellColor(worksheet.getRow(rowIndex).getCell(i));
         }

         let lastColumnIndex = tempStartCoumnDate + 2;
         if (lastColumnIndex + 1 <= 38) {
            worksheet.spliceColumns(lastColumnIndex, 39 - lastColumnIndex);
         }
      }

      const buf = await wb.xlsx.writeBuffer();

      saveAs(new Blob([buf]), excelName + ".xlsx");
   }

   function setCellBoldUnderlineAndLeft(cell, isBold = true, isUnderLine = true, justify = "left") {
      cell.font = {
         name: "Arial",
         family: 2,
         size: 11,
         underline: isUnderLine,
         //bold: isBold
      };

      cell.alignment = { vertical: "middle", horizontal: justify };
   }

   function setCellColor(cell, color = "ACB9CA") {
      cell.style = {
         ...cell.style,
         fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: color },
         },
      };
   }

   function isShowMoney() {
      return props.permissionType === 1;
   }

   function handleOnChangeIncludeOutSource(e) {
      setIncludeOutsourceWorker(e.target.checked);
   }

   function onChangeDateCostCode(date, dateString) {
      setTimeDateCostCode(date);
   }

   function getDataStackedPileChartByTime(data = null) {
      if (data == null) {
         data = dataCharts;
      }

      let dts = null;
      if (data) {
         if (timeTypeForBarChart == "month") {
            dts = _.isArray(data.dataStackedPileChartByMonthsInYear)
               ? data.dataStackedPileChartByMonthsInYear.filter((x) => x.year == selectedChartYear)
               : [];
         }

         if (timeTypeForBarChart == "year") {
            dts = _.isArray(data.dataStackedPileChartByAllYears) ? data.dataStackedPileChartByAllYears : [];
         }

         if (timeTypeForBarChart == "day") {
            let month = moment(selectedChartMonthYear, "MM-YYYY").format("MM-YY");

            let item = data.dataStackedPileChartByMonthsInYear.find((x) => x.monthString == month);

            dts = item?.children;
         }
      }

      if (dts) {
         let roundDts = dts.map((x) => ({
            ...x,
            NT: _.round(x.NT),
            BP: _.round(x.BP),
            OT: _.round(x.OT),
            ICP: _.round(x.ICP),
         }));

         return roundDts;
      }

      return null;
   }

   function getYears() {
      if (dataCharts && _.isArray(dataCharts.dataStackedPileChartByMonthsInYear)) {
         let years = _.uniq(dataCharts.dataStackedPileChartByMonthsInYear.map((x) => x.year));

         return years;
      }

      return [moment().year()];
   }

   let colorHomeLeave = "#FF99FF";
   let colorAbsent = "#FF0000";
   let colorCourse = "#FFC000";
   let colorSunday = "#ACB9CA";
   let colorMc = "#00FF00";
   let colorPulicHoliday = "#C6E0B4";

   let colors = [
      {
         name: "Sunday",
         value: colorSunday,
      },
      {
         name: "Public Holiday",
         value: colorPulicHoliday,
      },
      {
         name: "Medical (MC)",
         value: colorMc,
      },
      {
         name: "Course / Training",
         value: colorCourse,
      },
      {
         name: "Home Leave",
         value: colorHomeLeave,
      },
      {
         name: "Absent",
         value: colorAbsent,
      },
   ];

   return (
      <div class="card-box">
         {props.permissionType < 3 ? (
            <div>
               <div className="">
                  <Radio.Group
                     value={tabIndex}
                     buttonStyle="solid"
                     onChange={(e) => {
                        setTabIndex(e.target.value);
                     }}
                  >
                     <Radio.Button value={1}>Chart</Radio.Button>
                     <Radio.Button value={2}>Summary Cost Code</Radio.Button>
                     <Radio.Button value={3}>Time Sheet</Radio.Button>
                     <Radio.Button value={4}>Wages,Points</Radio.Button>
                     <Radio.Button value={5}>Time-cards</Radio.Button>
                  </Radio.Group>

                  <Checkbox value={includeOutsourceWorker} className="ml-2" onChange={handleOnChangeIncludeOutSource}>
                     Included Outsouce Worker
                  </Checkbox>

                  <span className="ml-2 float-right">
                     (Data updated as : {updatedAt}). Click
                     <span
                        style={{
                           color: "#1890ff",
                           cursor: "pointer",
                           fontWeight: "bold",
                        }}
                        className="ml-2 mr-2 "
                        onClick={() => {
                           forceUpdateData({
                              projectId: projectId,
                              isForceCalculate: true,
                           }).then((r) => {
                              setIntReload(intReload + 1);
                              notification.success({
                                 message: "Info!",
                                 description: "Reload lasted data has been successfully!",
                                 duration: 5,
                              });
                           });
                        }}
                     >
                        here
                     </span>
                     to reload lastest.
                  </span>
               </div>

               {tabIndex == 1 && (
                  <div>
                     <Row gutter={[20, 20]}>
                        <Col xxl={24} xl={24} lg={24}>
                           <div className="card-box">
                              <div className="d-flex flex-row">
                                 {isShowMoney() && [
                                    <Col>
                                       <h6 className="h6-title">{formatCurrency(dataChartsCost?.total)}</h6>
                                       <p>Cummulative Workers' salary</p>
                                    </Col>,
                                    <Col>
                                       <h6 className="h6-title">{formatCurrency(dataChartsCost?.currentMonthValue)}</h6>
                                       <p>Est worker salary {getTomorrowOfCurrentMonthCutOffDate()}</p>
                                    </Col>,
                                 ]}

                                 <div class="vl"></div>

                                 <Col>
                                    <h6 className="h6-title">{dataCharts.totalManMonth}</h6>
                                    <p>Man-months</p>
                                 </Col>

                                 <Col>
                                    <h6 className="h6-title">{dataCharts.lastManMonth}</h6>
                                    <p>Man-months {getTomorrowOfCurrentMonthCutOffDate()}</p>
                                 </Col>

                                 <div class="vl"></div>

                                 <Col>
                                    <h6 className="h6-title">{dataChartsCost.workerCount}</h6>
                                    <p>Workers</p>
                                 </Col>

                                 <Col>
                                    <h6 className="h6-title">{dataChartsCost.supervisorCount}</h6>
                                    <p>Engineers / Foreman</p>
                                 </Col>
                              </div>

                              <hr></hr>
                              <div className="d-flex flex-row">
                                 <div className="d-flex flex-column  align-items-center">
                                    <TimePieChartPercent dataPieChart={dataCharts.dataPieChart} />
                                    <h4>Working time type</h4>
                                 </div>

                                 <div className="d-flex flex-column flex-grow-1">
                                    <div className=" flex flex-row ">
                                       <Radio.Group
                                          onChange={(e) => {
                                             setTimeTypeForBarChart(e.target.value);
                                          }}
                                          value={timeTypeForBarChart}
                                          buttonStyle="solid"
                                       >
                                          <Radio.Button value="day">Day</Radio.Button>
                                          <Radio.Button value="month">Month</Radio.Button>
                                          <Radio.Button value="year">Year</Radio.Button>
                                       </Radio.Group>

                                       {timeTypeForBarChart === "day" && (
                                          <MonthPicker
                                             className="ml-2"
                                             value={moment(selectedChartMonthYear, "MM-YYYY")}
                                             format="MM-YYYY"
                                             onChange={onChangeMonthPickerChart}
                                          />
                                       )}
                                       {timeTypeForBarChart === "month" && (
                                          <Select
                                             value={selectedChartYear}
                                             style={{ width: 120 }}
                                             className="ml-2"
                                             onChange={(e) => {
                                                setSelectedChartYear(e);
                                             }}
                                          >
                                             {getYears().map((x, index) => (
                                                <Option key={index} value={x}>
                                                   {x}
                                                </Option>
                                             ))}
                                          </Select>
                                       )}

                                       <span style={{ color: "red" }} className=" float-right mr-2">
                                          Note: data for each month calculated from cut off date {cutOffDate}nd of the
                                          month. Example: Month August will be calculated 23 Jul to 22 Aug
                                       </span>
                                    </div>
                                    <Row gutter={[24, 24]}>
                                       <Col span={12} xl={24} lg={24}>
                                          <div className="d-flex flex-column align-items-center">
                                             <TimeStackedBarChart
                                                timeTypeForBarChart={timeTypeForBarChart}
                                                dataStackedPileChart={dataStackedTime}
                                             />
                                             <h4>Time (hours)</h4>
                                          </div>
                                       </Col>
                                       <Col span={12} xl={24} lg={24}>
                                          {isShowMoney() && (
                                             <div className="d-flex flex-column  align-items-center">
                                                <TimeStackedBarChart
                                                   timeTypeForBarChart={timeTypeForBarChart}
                                                   dataStackedPileChart={dataStackedCost}
                                                />
                                                <h4>Cost ($)</h4>
                                             </div>
                                          )}
                                       </Col>
                                    </Row>
                                 </div>
                              </div>
                           </div>
                        </Col>
                     </Row>
                  </div>
               )}
               {tabIndex == 2 && (
                  <div>
                     {isShowMoney() && [
                        <Row gutter={[20, 20]}>
                           <Col>
                              <div className="card-box">
                                 <div className="d-flex align-items-center flex-row justify-content-between">
                                    <h4 className="mb-4 mt-4">Summary by cost code</h4>
                                    <Button
                                       type="primary"
                                       className="mr-4"
                                       onClick={() => setExportExcelCostcodeSummary(exportExcelCostcodeSummary + 1)}
                                    >
                                       Export
                                    </Button>
                                 </div>
                                 <CostCodeTableSummary
                                    exportExcel={exportExcelCostcodeSummary}
                                    dateData={dataCostCodesAll.dateData}
                                    datas={dataCostCodesAll.costCodeDatasById}
                                 />
                              </div>
                           </Col>
                        </Row>,

                        <Row gutter={[24, 24]}>
                           <Col>
                              <div className="card-box">
                                 <div className="d-flex align-items-center flex-row justify-content-between">
                                    <h4 className="mb-4 mt-4">Summary by cost code detail</h4>
                                    <div>
                                       <Button
                                          type="primary"
                                          className="mr-4"
                                          onClick={() => setExportExcelCostDetail(exportExcelCostDetail + 1)}
                                       >
                                          Export
                                       </Button>

                                       <span className=" text-center mr-1">Search : </span>
                                       <RangePicker
                                          value={timeDateCostCode}
                                          format={"DD-MM-YYYY"}
                                          onChange={onChangeDateCostCode}
                                       />
                                    </div>
                                 </div>
                                 <CostCodeTableDetail
                                    exportExcel={exportExcelCostDetail}
                                    datas={dataCostCodes.costCodeDatasById}
                                 />

                                 <div className="d-flex align-items-center flex-row justify-content-between">
                                    <h4 className="mb-4 mt-4">Summary by trade detail</h4>
                                    <Button
                                       type="primary"
                                       className="mr-4"
                                       onClick={() => setExportExcelCostTradeDetail(exportExcelCostTradeDetail + 1)}
                                    >
                                       Export
                                    </Button>
                                 </div>

                                 <CostCodeTradeTableDetail
                                    exportExcel={exportExcelCostTradeDetail}
                                    datas={dataCostCodes.costCodeDatasById}
                                 />
                              </div>
                           </Col>
                        </Row>,
                        ,
                     ]}
                  </div>
               )}
               {tabIndex == 3 && (
                  <div>
                     {isShowMoney() && (
                        <Row gutter={[20, 20]}>
                           <Col>
                              <div className="card-box">
                                 <div className="d-flex align-items-center flex-row justify-content-between">
                                    <h4 className="mb-4 mt-4 mr-4">Export worker time sheet by duration</h4>
                                    <div>
                                       <Button type="primary" className="mr-4" onClick={exportTimeSheet}>
                                          Export
                                       </Button>
                                       <span>Select Month : </span>
                                       <MonthPicker
                                          defaultValue={moment(currentMonthTimeSheet, "MM-YYYY")}
                                          format="MM-YYYY"
                                          onChange={onChangeMonthPickerTimeSheet}
                                       />
                                    </div>
                                 </div>

                                 <WorkerTimeSheetTable datas={dataTimeSheet} month={currentMonthTimeSheet} />
                              </div>
                           </Col>
                        </Row>
                     )}

                     <Row gutter={[24, 24]}>
                        <Col>
                           <div className="card-box">
                              <ShiftDataTable />
                           </div>
                        </Col>
                     </Row>
                  </div>
               )}

               {tabIndex === 4 && (
                  <div>
                     <Row gutter={[20, 20]}>
                        <Col>
                           <div className="card-box" style={{ height: "100%" }}>
                              <div className="d-flex align-items-center flex-row justify-content-between">
                                 <h4>To Account</h4>
                                 <div>
                                    <Button type="primary" className="mr-4" onClick={exportTimeSheet}>
                                       Export Full Time Sheet
                                    </Button>
                                    <span>Select Month : </span>
                                    <MonthPicker
                                       defaultValue={moment(currentMonthTimeSheet, "MM-YYYY")}
                                       format="MM-YYYY"
                                       onChange={onChangeMonthPickerTimeSheet}
                                    />
                                 </div>
                              </div>

                              <div className="d-flex flex-row mt-2">
                                 <ToAcountTable
                                    tableHeight={height}
                                    allHolidays={allHolidays}
                                    dataTimeSheet={dataTimeSheet}
                                    cutOffDate={cutOffDate}
                                    currentMonthTimeSheet={currentMonthTimeSheet}
                                 />

                                 <div style={{ maxWidth: 500, marginLeft: 50 }}>
                                    <h3>Legends :</h3>
                                    <Row gutter={[24, 24]}>
                                       {colors.map((color) => (
                                          <Col span={12} key={color.value}>
                                             <div className="d-flex flex-row items-center">
                                                <div
                                                   style={{
                                                      width: 100,
                                                      height: 30,
                                                      background: color.value,
                                                   }}
                                                ></div>
                                                <p className="ml-2">{color.name}</p>
                                             </div>
                                          </Col>
                                       ))}
                                    </Row>
                                 </div>
                              </div>

                              <h4 className="mt-2">Summary</h4>
                              <SummaryTable
                                 tableHeight={height}
                                 allHolidays={allHolidays}
                                 dataTimeSheet={dataTimeSheet}
                                 cutOffDate={cutOffDate}
                                 currentMonthTimeSheet={currentMonthTimeSheet}
                              />
                              <h4>Wages</h4>
                              <WagesTable
                                 tableHeight={height}
                                 allHolidays={allHolidays}
                                 dataTimeSheet={dataTimeSheet}
                                 cutOffDate={cutOffDate}
                                 currentMonthTimeSheet={currentMonthTimeSheet}
                              />
                              <h4 className="mt-2">Points</h4>
                              <PointsTable
                                 tableHeight={height}
                                 allHolidays={allHolidays}
                                 dataTimeSheet={dataTimeSheet}
                                 cutOffDate={cutOffDate}
                                 currentMonthTimeSheet={currentMonthTimeSheet}
                              />
                              <h4 className="mt-2">To HR</h4>
                              <ToHrTable
                                 tableHeight={height}
                                 allHolidays={allHolidays}
                                 dataTimeSheet={dataTimeSheet}
                                 cutOffDate={cutOffDate}
                                 currentMonthTimeSheet={currentMonthTimeSheet}
                              />
                           </div>
                        </Col>
                     </Row>
                  </div>
               )}

               {tabIndex === 5 && (
                  <div style={{ height: "85vh" }}>
                     <TimeCardTable
                        projectId={projectId}
                        tableHeight={height}
                        allHolidays={allHolidays}
                        dataTimeSheet={dataTimeSheet}
                        cutOffDate={cutOffDate}
                        currentMonthTimeSheet={currentMonthTimeSheet}
                     />
                  </div>
               )}
            </div>
         ) : (
            <Result
               status="403"
               title="403"
               subTitle="Sorry, you are not authorized to access this page."
               extra={
                  <Button type="primary" href="/">
                     Back Home
                  </Button>
               }
            />
         )}
      </div>
   );
}

export default DashBoard;
