import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Loader } from 'rsuite';
import { message } from 'antd';
import _ from 'lodash'
import {TableRebar} from '../tables'
import Dock from 'react-dock'
import {
  getAllElementdbIdsOneModel, convertUnits
} from '../function/ForgeFunction'
import {
  formatNumber, getSubRowsTotal
} from '../function/TableFunction'
import { trackingUserUsing } from '../function/AdminFunction'

const columns = [
  { Header: 'Name', accessor: 'name', id: 'name', units: false, filter: "includes", },
  { Header: 'Marking', accessor: 'marking', id: 'marking', units: false, filter: "includes", },
  { Header: 'Grade', accessor: 'grade', id: 'grade', units: false, filter: "includes", },
  { Header: 'Size', accessor: 'size', id: 'size', units: false, filter: "includes", },
  { Header: 'Total Number', accessor: 'totalNumber', id: 'totalNumber', units: false, filter: "includes", aggregate: 'sum' },
  { Header: 'Shape', accessor: 'shape', id: 'shape', units: false, filter: "includes", },
  { Header: 'a', accessor: 'a', id: 'a', units: false, filter: "includes", },
  { Header: 'b', accessor: 'b', id: 'b', units: false, filter: "includes", },
  { Header: 'c', accessor: 'c', id: 'c', units: false, filter: "includes", },
  { Header: 'd', accessor: 'd', id: 'd', units: false, filter: "includes", },
  { Header: 'e', accessor: 'e', id: 'e', units: false, filter: "includes", },
  { Header: 'f', accessor: 'f', id: 'f', units: false, filter: "includes", },
  {
    Header: 'Length', accessor: 'length', id: 'length', units: false, filter: "includes", aggregate: 'sum',
    Cell: ({ cell: { value } }) => `${formatNumber(value)}`,
    Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`
  },
  {
    Header: 'Total Weight', accessor: 'totalWeight', id: 'totalWeight', units: false, filter: "includes", aggregate: 'sum',
    Cell: ({ cell: { value } }) => `${formatNumber(value)}`,
    Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`,
    Footer: info => {
      const total = React.useMemo(
        () => getSubRowsTotal(info.flatRows, info.column.id),
        [info.rows]
      )
      return <> {total.toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</>
    },
  },
]
let unit = ''
function BottomBarRebar(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [dataFull, setDataFull] = useState([])
  const [maxForgeViewer, setMaxForgeViewer] = useState(500)
  const [modifyForgeViewer, setModifyForgeViewer] = useState(0)
  const [heightDock, setHeightDock] = useState(500)
  const [activeButtonIsolateRows, setActiveButtonIsolateRows] = useState(false)
  useEffect(() => {
    trackingUserUsing(props.email,props.projectId,'BIM App','Rebar Quantities')
    setLoading(true)
    props.viewer.model.getProperties(props.viewer.model.getData().instanceTree.getRootId(), (modelAProperty) => {
      let check = false
      unit = ''
      _.forEach(modelAProperty.properties, property => {
        if (property.displayName === "IFC Application Name") {
          if (property.displayValue.includes('Tekla Structures')) {
            check = true
          }
        } else if (property.displayName === "Unit") {
          unit = property.displayValue.toLowerCase()
        }
      })
      if (!check) {
        message.warning('This file is not export from Tekla')
        $('#splitForgeViewer').height('calc(100% - 53.78px)')
        props.viewer.resize()
        props.onDisplayPanelChange('rebarPanel', false)
        props.onDisabledBtnChange('btnRebarTable', false)
      } else {
        let allDbIds = getAllElementdbIdsOneModel(props.viewer)
        let temp = []
        _.forEach(allDbIds, id => {
          temp.push(checkParentForTekla(props.viewer, id))
        })
        temp = temp.concat(allDbIds)
        temp = _.uniq(temp)
        listElementTable(props.viewer, temp);
        let height = $('#splitForgeViewer').height()
        setMaxForgeViewer(height)
        setModifyForgeViewer(height - heightDock)
        props.onDisabledBtnChange('btnRebarTable', true)
      }
    });

  }, [])
  useEffect(() => {
    if (props.displayPanel && modifyForgeViewer !== 0) {
      $('#splitForgeViewer').height(modifyForgeViewer)
      if (props.viewer)
        props.viewer.resize()
    }

  }, [modifyForgeViewer])

  const listElementTable = (viewer, dbIds) => {
    let dataRebarTable = [];
    let count = dbIds.length;
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, (modelAProperty) => {
        let temp = {}
        let check = false
        let category = ''
        _.forEach(modelAProperty.properties, property => {
          if (property.displayCategory === "Element" && property.displayName === "Category" &&
            (property.displayValue === "Structural Rebar")) {
            category = property.displayValue
            check = true
            return false
          } else if (property.displayCategory === "Item" && property.displayName === "Type" &&
            (property.displayValue === "Specialty Equipment")) {
            category = property.displayValue
            check = true
            return false
          }
        })
        if (check) {
          temp.id = modelAdbId
          if (category === "Structural Rebar") {
            let weight =0
            let totalWeight =0
            _.forEach(modelAProperty.properties, property => {         
              let displayValue = property.displayValue
              if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                displayValue = convertUnits(property.units, 'mm', 1, property.displayValue, property.type, null)
              if (property.displayName === "a") {
                temp.a = formatNumber(displayValue)
              } else if (property.displayName === "b") {
                temp.b = formatNumber(displayValue)
              } else if (property.displayName === "c") {
                temp.c = formatNumber(displayValue)
              } else if (property.displayName === "d") {
                temp.d = formatNumber(displayValue)
              } else if (property.displayName === "e") {
                temp.e = formatNumber(displayValue)
              } else if (property.displayName === "f") {
                temp.f = formatNumber(displayValue)
              } else if (property.displayCategory === "Element" && property.displayName === "Name") {
                temp.name = displayValue === '' ? 'Unknown' : displayValue
              } else if (property.displayName === "Grade") {
                temp.grade = displayValue
              } else if (property.displayName === "Size") {
                temp.size = displayValue
              } else if (property.displayName === "Number of bars in group" || property.displayName === "Total Number") {
                temp.totalNumber = displayValue
              } else if (property.displayName === "Shape") {
                temp.shape = displayValue
              } else if (property.displayName === "Total Weight" || property.displayName.includes('Weight total')) {
                let split = displayValue.split(' ')
                totalWeight = _.toNumber(split[0])
              } else if (property.displayName === "Weight") {
                let split = displayValue.split(' ')
                weight = _.toNumber(split[0])
              } else if (property.displayName === "Length") {
                temp.length = displayValue
              }else if (property.displayName.includes("Group position")) {
                temp.marking = displayValue
              }
            })
            temp.totalWeight = totalWeight!==0?totalWeight:weight

          } else if (category === 'Specialty Equipment') {
            _.forEach(modelAProperty.properties, property => {
              let displayValue = property.displayValue
              if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                displayValue = convertUnits(property.units, 'mm', 1, property.displayValue, property.type, null)
              if (property.displayCategory === "Element" && property.displayName === "Name") {
                temp.name = displayValue === '' ? 'Unknown' : displayValue
              } else if (property.displayCategory === "Element" && (property.displayName === "IfcDescription" || property.displayName === 'ObjectTypeOverride')) {
                temp.size = displayValue
                temp.totalNumber = 1
              }
            })
          }
          temp.color = ''
          dataRebarTable.push(temp)
        }
        count--;
        if (count === 0) {
          setLoading(false)
          setData(dataRebarTable)
          setDataFull(dataRebarTable)
        }
      });
    });
  }
  const checkParentForTekla = (viewer, id) => {
    let instanceTree = viewer.model.getData().instanceTree;
    let temp = []
    let queue = [];
    queue.push(id);
    while (queue.length > 0) {
      let node = queue.shift();
      let idChild = instanceTree.getNodeParentId(node)
      if (idChild) {
        queue.push(idChild);
        temp.push(idChild)
      }
      else {
        return temp[1]
      }
    }
  }
  const sizeChange = (e) => {
    if (props.displayPanel) {
      setHeightDock(e)
      setModifyForgeViewer(maxForgeViewer - e)
    }
  }
  const onActiveTable = () => {
    $('#splitForgeViewer').height('calc(100% - 53.78px)')
    props.viewer.resize()
    props.viewer.showAll()
    let models = props.viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      props.viewer.clearThemingColors(model)
    })
    props.onDisplayPanelChange('rebarPanel', false)
    props.onDisabledBtnChange('btnRebarTable', false)
  }
  const onIsolateRows = (d) => {
    let temp = []
    if (!activeButtonIsolateRows) {
      _.forEach(data, v => {
        if (d.includes(v.id))
          temp.push(v)
      })
      setActiveButtonIsolateRows(true)
      setData(temp)
    } else {
      setData(dataFull)
      setActiveButtonIsolateRows(false)
    }

  }
  return (
    <>
      <Dock position={'bottom'} isVisible={props.displayPanel} dimMode={'none'} fluid={false} zIndex={200} size={heightDock}
        onSizeChange={sizeChange}  >
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <TableRebar columns={columns} data={data}
          viewer={props.viewer}
          onActiveTable={onActiveTable}
          getRowProps={() => ({})}
          getCellProps={() => ({})}
          activeButtonIsolateRows={activeButtonIsolateRows}
          onIsolateRows={onIsolateRows}
        />
      </Dock>

    </>
  );
}

export default (BottomBarRebar)

