import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";

const StaticInfo = ({ static_report }) => {

   return (
      <div>
         {static_report !== undefined ? (
            <div
               style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
               }}
            >
               {static_report.map((item) => {
                  return (
                     <div
                        style={{
                           borderRadius: "2px",
                           padding: "5px",
                           backgroundColor: "#F8F9F9",
                           border: "1px solid rgba(0, 0, 0, 0.05)",
                           justifyContent: "center",
                           display: "flex",
                           flexDirection: "column",
                           flexGrow: 1,
                           textAlign: "center",
                        }}
                     >
                        <h5
                           style={{
                              height: "45px",
                              color: "blue",
                              fontWeight: "bold",
                              padding: "0px",
                           }}
                           className="statistic-text"
                        >
                           {item.value}
                        </h5>

                        <p style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
                           {item.color != undefined ? (
                              <div
                                 style={{
                                    float: "left",
                                    width: "26px",
                                    height: "16px",
                                    marginTop: "2px",
                                    backgroundColor: item.color,
                                    border: item.border,
                                 }}
                              ></div>
                           ) : null}

                           <p style={{ paddingLeft: "5px", float: "left" }}> {item.key}</p>
                        </p>

                     </div>
                  );
               })}
            </div>
         ) : // </Row>
         null}
      </div>
      // </div>
   );
};

export default StaticInfo;
