import React, {  } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import { message, Icon, Upload } from 'antd';
import { Button } from 'rsuite';
import _ from 'lodash'

class UsersControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      dataViewer: null,
      loadingViewer: false
    }

  }
  componentWillMount = () => {
    this.setState({ loading: true , loadingViewer: true})
    axios.get(`/api/trimble/oauth/trimble-refresh-token`, { params: { token: this.props.userInfo.token } })
      .then(res => {
        axios.get(`https://app31.connect.trimble.com/tc/api/2.0/users/me`, {
          headers: {
            'Authorization': `Bearer ${res.data.access_token}`,
          }
        })
          .then(res => {
            console.log(res)
            this.setState({ data: res.data }, () => {
              this.props.handleLoading(false)
              this.setState({ loading: false })
            })
          })
          .catch(err => {
            this.props.handleLoading(false)
            this.setState({ loading: false })
          })
      })
      .catch(err => {
        message.warning(`Can't get token upload`)
        this.props.handleLoading(false)
        this.setState({ loading: false })
      })
    //!viewer
    axios.get(`/api/trimble/oauth/trimble-viewer-refresh-token`, { params: { token: this.props.userInfo.token } })
      .then(res => {
        axios.get(`https://app31.connect.trimble.com/tc/api/2.0/users/me`, {
          headers: {
            'Authorization': `Bearer ${res.data.access_token}`,
          }
        })
          .then(res => {
            console.log(res)
            this.setState({ dataViewer: res.data }, () => {
              this.props.handleLoading(false)
              this.setState({ loadingViewer: false })
            })
          })
          .catch(err => {
            this.props.handleLoading(false)
            this.setState({ loadingViewer: false })
          })
      })
      .catch(err => {
        message.warning(`Can't get token viewer`)
        this.props.handleLoading(false)
        this.setState({ loadingViewer: false })
      })
    
  }

  handleLoginTrimble = () => {
    this.props.handleLoading(true)
    this.setState({ loading: true })
    axios.get(`/api/trimble/oauth/url`)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => {
        message.warning(`Can't get link`)
      })
  }
  handleLogoutTrimble = () => {
    this.props.handleLoading(true)
    this.setState({ loading: true })
    axios.get(`/api/trimble/oauth/logout`, { params: { token: this.props.userInfo.token } })
      .then(res => {
        this.setState({ data: null }, () => {
          this.props.handleLoading(false)
          this.setState({ loading: false })
        })
      })
      .catch(err => {
        message.warning(`Can't logout`)
      })
  }

  handleLoginTrimbleViewer = () => {
    this.props.handleLoading(true)
    this.setState({ loading: true })
    axios.get(`/api/trimble/oauth/url`)
      .then(res => {
        window.location.href = res.data
      })
      .catch(err => {
        message.warning(`Can't get link`)
      })
  }
  handleLogoutTrimbleViewer = () => {
    this.props.handleLoading(true)
    this.setState({ loadingViewer: true })
    axios.get(`/api/trimble/oauth/logout-viewer`, { params: { token: this.props.userInfo.token } })
      .then(res => {
        this.setState({ dataViewer: null }, () => {
          this.props.handleLoading(false)
          this.setState({ loadingViewer: false })
        })
      })
      .catch(err => {
        message.warning(`Can't logout`)
      })
  }
  customRequest = async ({ onSuccess, onError, file }) => {
    if (file !== null) {
      this.setState({ loading: true })
      var reader = new FileReader();
      reader.onload = (e) => {
        var text = reader.result;
        console.log(JSON.parse(text))
        axios.post(`/api/trimble/oauth/save-token`, { token: this.props.userInfo.token, tokenTrimble: JSON.parse(text) })
          .then(res => {
            this.props.handleLoading(false)
            this.setState({ loading: false })
          })
          .catch(err => {
            message.warning(`Can't logout`)
            this.setState({ loading: false })
          })
      }

      reader.readAsText(file);
    }
  }
  render() {
    return (
      <>
        <Upload name='file' multiple={false} showUploadList={false} customRequest={this.customRequest}  >
          <Button loading={this.state.loading}>
            <Icon type="upload" /> Open *.json for Upload
           </Button>
        </Upload>
        {this.state.data ? <Button color="red" onClick={this.handleLogoutTrimble} loading={this.state.loading} >
          {`Logout TrimbleConnect upload ${this.state.data.email}`}</Button> :
          <Button color="blue" onClick={this.handleLoginTrimble} loading={this.state.loading}>
            Login TrimbleConnect Upload</Button>}
        <br />
        {this.state.dataViewer ? <Button color="red" onClick={this.handleLogoutTrimbleViewer} loading={this.state.loadingViewer} >
        {`Logout TrimbleConnect viewer ${this.state.dataViewer.email}`}</Button> :
          <Button color="blue" onClick={this.handleLoginTrimbleViewer} loading={this.state.loadingViewer}>Login TrimbleConnect Viewer</Button>}


      </>

    );
  }
}

UsersControl.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email,
    email: state.user.email,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(UsersControl)
