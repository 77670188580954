import React, { useState, useEffect } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { Func_Export_QM_Unit } from "../../function/func_export_to_excel";

const TableGroupByUnit = ({ data, rangeSelected }) => {
   var lastMonth = dayjs().subtract("1", "month").format("MMM");
   const [checkHighFail, setCheckHighFail] = useState(true);
   const [temp_data, setTempData] = useState();
   let highFailData = [];
   if (data !== undefined) {
      highFailData = data.filter((e) => e.percent >= 8);
   }

   useEffect(() => {
      {
         let temp_d = [];
         if (data !== undefined) {
            if (rangeSelected == "All") {
               temp_d = data;
            }

            if (rangeSelected == "<0.75") {
               temp_d = data.filter((e) => e.ms !== "NA" && e.ms < 0.75);
            }
            if (rangeSelected == "0.75-1.0") {
               temp_d = data.filter((e) => e.ms != "NA" && e.ms >= 0.75 && e.ms < 1);
            }

            if (rangeSelected == "1.0-1.25") {
               temp_d = data.filter((e) => e.ms != "NA" && e.ms >= 1.0 && e.ms < 1.25);
            }

            if (rangeSelected == "1.25-1.5") {
               temp_d = data.filter((e) => e.ms != "NA" && e.ms >= 1.25 && e.ms < 1.5);
            }

            if (rangeSelected == ">=1.5") {
               temp_d = data.filter((e) => e.ms != "NA" && e.ms >= 1.5);
            }

            setTempData(temp_d);
         }
      }
   }, [data, rangeSelected]);

   const [columnDefs] = useState([
      { field: "project_code", headerName: "Project", width: 100, filter: true, sortable: true },
      { field: "Unit", headerName: "Unit", width: 125, filter: true, sortable: true },
      { field: "qmwork", headerName: "QM", width: 75, filter: true, sortable: true },
      { field: "reconwork", headerName: "Recon", width: 100, filter: true, sortable: true },
      { field: "bcwork", headerName: "Backcharge", width: 125, filter: true, sortable: true },
      { field: "otherwork", headerName: "Other", width: 100, filter: true, sortable: true },
      { field: "total", headerName: "Total Hours", width: 100, filter: true, sortable: true },
      { field: "no_of_sample", headerName: "No of sample", width: 125, filter: true, sortable: true },
      { field: "ms", headerName: "Man-day /Sample", width: 125, filter: true, sortable: true },
      { field: "msQR", headerName: "M/S (QM+Recon)", width: 150, filter: true, sortable: true },
   ]);

   return (
      <div style={{ minHeight: "30vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Unit
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_QM_Unit(data)}
            >
               <Icon type="download" />
            </Button>
            {/* <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Items {">"} 8%
            </Checkbox> */}
         </h5>
         <div className="ag-theme-alpine" style={{ height: "43vh", width: "100%" }}>
            <AgGridReact rowData={temp_data} columnDefs={columnDefs}></AgGridReact>
         </div>
      </div>
   );
};

export default TableGroupByUnit;
