/* eslint-disable */


import { formatNumber } from '../function/TableFunction'
import CustomPropertiesLeft from './ShareViewExtensions/CustomPropertiesLeft'
// import './dataTables.rowsGroup'
const Autodesk = window.Autodesk;
const THREE = window.THREE;
let customPanel = null
const listProperties = ['Type Name', 'Mark', 'Length', 'Area', 'Volume', 'Comments', 'Structural Material', 'Type Comments', 'MASTER SCHEDULE ID', 'DETAIL SCHEDULE ID']
var viewer = null;

class WHExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options)
    Autodesk.Viewing.Extension.call(this, viewer, options);
  }
  load() {
    return true;
  };
  unload() {
    if (this.subToolbar) {
      this.viewer.toolbar.removeControl(this.subToolbar);
      this.subToolbar = null;
      customPanel = null
    }
  };


  onToolbarCreated = function (toolbar) {
    viewer = this.viewer;




    var button4 = new Autodesk.Viewing.UI.Button('btn-docbrowser');
    button4.addClass('btn-docbrowser');
    button4.addClass('fas');
    button4.addClass('fa-folder-open');
    button4.addClass('fa-2x');
    button4.setToolTip('Document Browser');

    var button5 = new Autodesk.Viewing.UI.Button('btn-markup2d');
    button5.addClass('btn-markup2d');
    button5.addClass('fas');
    button5.addClass('fa-highlighter');
    button5.addClass('fa-2x');
    button5.setToolTip('Markup');

    var button6 = new Autodesk.Viewing.UI.Button('btn-custom-properties');
    button6.addClass('btn-custom-properties');
    button6.addClass('fas');
    button6.addClass('fa-info-circle');
    button6.addClass('fa-2x');
    button6.setToolTip('Custom Properties');
    button6.onClick = (e) => {
      if (customPanel === null) {
        customPanel = new CustomPropertiesLeft(viewer, { id: 'sharePropertyLeftExtensionPanel', title: 'Custom Properties' })
        //
        this.x = 0
        this.y = 0
        this.element = $('.canvas-wrap').children().last();
        this.container = viewer.canvas.parentElement;
        this.rect = this.container.getBoundingClientRect();
        this.cursor = this.element.css('cursor')
        this.div = document.createElement('div')
        this.div.style.zIndex = 100
        this.div.style.position = 'absolute'
        this.div.style.display = 'none'
        this.div.style.color = 'red'
        this.div.style.fontSize = '12px'
        this.div.innerHTML = ""
        document.getElementById('forgeViewer').appendChild(this.div);
        viewer.canvas.addEventListener('mousemove', onHoverInformation);
        //
        let div = $('#check-box-custom-properties')
        if (div.length !== 0) {
          $('#check-box-custom-properties').change((e) => {
            console.log(e.target.checked)
            onSelectionBind()
          })
        }
        customPanel.addEventListener(customPanel.closer, "click", () => {
          setActiveDock(button6, customPanel);
          viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, onSelectionBind);
          viewer.canvas.removeEventListener('mousemove', onHoverInformation);
          document.getElementById('forgeViewer').removeChild(this.div);
        })
      }
      customPanel.setVisible(!customPanel.isVisible());
      setActiveDock(button6, customPanel);
    };
    //var options = { collapsible: true, alignVertically: false }
    //var toolbar = new Autodesk.Viewing.UI.ToolBar('wh-toolbar',options);

    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('wh-custom-toolbar');
    // if (viewer.impl.model.is3d())
    //     this.subToolbar.addControl(button3);
    // this.subToolbar.addControl(button4);
    this.subToolbar.addControl(button5);
    this.subToolbar.addControl(button6);
    viewer.toolbar.addControl(this.subToolbar);

    const onHoverInformation = (event) => {
      this.x = event.clientX - this.rect.x;
      this.y = event.clientY - this.rect.y;
      var hitTest = this.viewer.hitTest(this.x, this.y, true);
      if (hitTest !== null) {
        try {
          this.div.style.top = this.y - 5 + "px"
          this.div.style.left = this.x + 30 + "px"
          this.div.style.backgroundColor = 'white'
          this.div.style.padding='5px'
          let tempText = ''
          viewer.impl.model.getProperties(hitTest.dbId, (modelAProperty) => {
            _.forEach(modelAProperty.properties, property => {
              if (property.displayCategory === 'Dimensions') {
                if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                tempText = tempText + `<span>${property.displayName}: ${formatNumber(property.displayValue, 3)}</span> <br/>`
                else
                tempText = tempText + `<span>${property.displayName}: ${property.displayValue}</span> <br/>`
              }
            })
            this.div.style.display = 'block'
            this.div.innerHTML = tempText
          })
        }
        catch { this.div.style.display = 'none' }
      } else {
        this.div.style.display = 'none'
      }
    }
  };

}

const setActiveDock = (button, dock) => {
  if (dock.isVisible()) {
    button.container.classList.remove('inactive');
    button.container.classList.add('active');
    onSelectionBind()
    viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, onSelectionBind);
  }
  else {
    button.container.classList.remove('active');
    button.container.classList.add('inactive');
    customPanel = null
    viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, onSelectionBind);
  }
}

const onSelectionBind = () => {
  var currSelection = viewer.impl.selector.getAggregateSelection()
  let ext = viewer.impl.model.myData.loadOptions.modelNameOverride.split('.')
  if (ext[ext.length - 1].toLowerCase() === 'ifc') {
    if (currSelection.length !== 0) {
      customPanel.removeAllProperties()
      let id = checkParent(currSelection[0].selection[0])
      currSelection[0].model.getProperties(id, (modelAProperty) => {
        let temp = []
        let check = false
        _.forEach(modelAProperty.properties, property => {
          if (property.displayCategory === "Element" && property.displayName === "Category" && property.displayValue === "Structural Rebar") {
            check = true
            return false
          }
        })
        if (check) {
          _.forEach(modelAProperty.properties, property => {
            if (property.displayName === "a") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayName === "b") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayName === "c") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayName === "d") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayName === "e") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayName === "f") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            } else if (property.displayCategory === "Element" && property.displayName === "Name") {
              customPanel.setTitle(property.displayValue)
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Grade") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Size") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Number of bars in group") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Shape") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Weight total in group") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: '' })
            } else if (property.displayName === "Length") {
              temp.push({ displayName: property.displayName, displayValue: property.displayValue, units: 'ok' })
            }
          })
        }

        _.forEach(temp, property => {
          if (property.units === '') {
            customPanel.addProperty(property.displayName, property.displayValue, property.displayCategory);
          } else {
            customPanel.addProperty(property.displayName, formatNumber(property.displayValue, 3), property.displayCategory);
          }
        })
      })
    } else {
      customPanel.setTitle('Custom Properties')
      customPanel.removeAllProperties()
    }
  } else {
    let count = 0

    let temp = []
    if (currSelection.length !== 0) {
      let check = true
      let div = $('#check-box-custom-properties')
      if (div.length !== 0) {
        check = div[0].checked
      }
      customPanel.removeAllProperties()
      let countItem = currSelection[0].selection.length
      let title = ''
      let countObject = 0
      let tempData = {}
      _.forEach(currSelection[0].selection, v => {
        currSelection[0].model.getProperties(v, function (modelAProperty) {
          countObject++
          let name = modelAProperty.name.split(" [")
          if (title === '')
            title = name[0]
          else if (title !== '' && name[0] !== title)
            title = `${countObject} Objects`
          _.forEach(modelAProperty.properties, property => {
            if (property.displayName !== 'Category' && property.displayName !== 'CategoryId'
              && property.displayName !== 'viewable_in' && property.displayName !== 'parent' && property.displayCategory !== '__internalref__'
              && property.displayName !== 'label') {
              if (check) {
                if (property.displayName.includes('WH') || property.displayName.includes('Constraint') || listProperties.includes(property.displayName)) {
                  if (!tempData[property.displayCategory + '&&&' + property.displayName]) {
                    if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3)) {
                      tempData[property.displayCategory + '&&&' + property.displayName] =
                        { name: property.displayName, value: 0, category: property.displayCategory, check: true, units: true }
                    } else {
                      tempData[property.displayCategory + '&&&' + property.displayName] =
                        { name: property.displayName, value: property.displayValue, category: property.displayCategory, check: true, units: true }
                    }
                  }
                  if (property.displayCategory === 'Identity Data') {
                    if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                      tempData[property.displayCategory + '&&&' + property.displayName].value = tempData[property.displayCategory + '&&&' + property.displayName].value + property.displayValue
                    else {
                      if (countObject > 1) {
                        if (property.displayValue === '')
                          tempData[property.displayCategory + '&&&' + property.displayName].check = false
                        else
                          tempData[property.displayCategory + '&&&' + property.displayName].check =
                            (tempData[property.displayCategory + '&&&' + property.displayName].value === property.displayValue)
                      }
                      tempData[property.displayCategory + '&&&' + property.displayName].units = false
                    }
                    count++
                  } else if (property.displayCategory === 'Dimensions') {
                    if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                      tempData[property.displayCategory + '&&&' + property.displayName].value = tempData[property.displayCategory + '&&&' + property.displayName].value + property.displayValue
                    else {
                      if (countObject > 1) {
                        if (property.displayValue === '')
                          tempData[property.displayCategory + '&&&' + property.displayName].check = false
                        else
                          tempData[property.displayCategory + '&&&' + property.displayName].check =
                            (tempData[property.displayCategory + '&&&' + property.displayName].value === property.displayValue)
                      }
                      tempData[property.displayCategory + '&&&' + property.displayName].units = false
                    }
                    // temp.splice(count, 0, property)
                    count++
                  } else {
                    if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                      tempData[property.displayCategory + '&&&' + property.displayName].value = tempData[property.displayCategory + '&&&' + property.displayName].value + property.displayValue
                    else {
                      if (countObject > 1) {
                        if (property.displayValue === '')
                          tempData[property.displayCategory + '&&&' + property.displayName].check = false
                        else
                          tempData[property.displayCategory + '&&&' + property.displayName].check =
                            (tempData[property.displayCategory + '&&&' + property.displayName].value === property.displayValue)
                      }
                      tempData[property.displayCategory + '&&&' + property.displayName].units = false
                    }
                    //temp.push(property)
                  }
                }
              } else {
                if (!tempData[property.displayCategory + '&&&' + property.displayName]) {
                  if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3)) {
                    tempData[property.displayCategory + '&&&' + property.displayName] =
                      { name: property.displayName, value: 0, category: property.displayCategory, check: true, units: true }
                  } else {
                    tempData[property.displayCategory + '&&&' + property.displayName] =
                      { name: property.displayName, value: property.displayValue, category: property.displayCategory, check: true, units: true }
                  }
                }
                if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                  tempData[property.displayCategory + '&&&' + property.displayName].value = tempData[property.displayCategory + '&&&' + property.displayName].value + property.displayValue
                else {
                  if (countObject > 1) {
                    if (property.displayValue === '')
                      tempData[property.displayCategory + '&&&' + property.displayName].check = false
                    else
                      tempData[property.displayCategory + '&&&' + property.displayName].check =
                        (tempData[property.displayCategory + '&&&' + property.displayName].value === property.displayValue)
                  }
                  tempData[property.displayCategory + '&&&' + property.displayName].units = false
                }
                // temp.push(property)
              }

            }
          })
          countItem--
          if (countItem === 0) {
            let temp = _.values(tempData)
            temp.sort((a, b) => {

              if (a.category.includes('Identity Data')) {
                return -1;
              }
              if (b.category.includes('Identity Data')) {
                return 1;
              }
              return 0;
            })
            customPanel.setTitle(title)
            _.forEach(temp, property => {
              if (!property.check) return
              if (!property.units) {
                customPanel.addProperty(property.name, property.value, property.category);
              } else {
                customPanel.addProperty(property.name, formatNumber(property.value, 3), property.category);
              }
            })
          }

        })
      })
      // currSelection[0].model.getProperties(currSelection[0].selection[0], function (modelAProperty) {
      //   let name = modelAProperty.name.split(" [")
      //   customPanel.setTitle(name[0])
      //   _.forEach(modelAProperty.properties, property => {
      //     if (property.displayName !== 'Category' && property.displayName !== 'CategoryId'
      //       && property.displayName !== 'viewable_in' && property.displayName !== 'parent' && property.displayCategory !== '__internalref__'
      //       && property.displayName !== 'label') {
      //       if (check) {
      //         if (property.displayName.includes('WH') || property.displayName.includes('Constraint')
      //           || listProperties.includes(property.displayName)) {
      //           if (property.displayCategory === 'Identity Data') {
      //             temp.splice(0, 0, property)
      //             count++
      //           } else if (property.displayCategory === 'Dimensions') {
      //             temp.splice(count, 0, property)
      //             count++
      //           } else {
      //             temp.push(property)
      //           }
      //           // if (property.units === '') {

      //           //     customPanel.addProperty(property.displayName, property.displayValue, property.displayCategory);
      //           // } else {
      //           //     customPanel.addProperty(property.displayName, formatNumber(property.displayValue, 3), property.displayCategory);
      //           // }
      //         }
      //       } else {
      //         temp.push(property)
      //       }

      //     }
      //   })

      //   _.forEach(temp, property => {
      //     if (property.units === '') {
      //       customPanel.addProperty(property.displayName, property.displayValue, property.displayCategory);
      //     } else {
      //       customPanel.addProperty(property.displayName, formatNumber(property.displayValue, 3), property.displayCategory);
      //     }
      //   })
      // })
    } else {
      customPanel.setTitle('Custom Properties')
      customPanel.removeAllProperties()
    }
  }

}

const checkParent = (id) => {
  let instanceTree = viewer.model.getData().instanceTree;
  let temp = []
  let queue = [];
  queue.push(id);
  while (queue.length > 0) {
    let node = queue.shift();
    let idChild = instanceTree.getNodeParentId(node)
    if (idChild) {
      queue.push(idChild);
      temp.push(idChild)
    }
    else {
      return temp[1]

    }
  }
}
// const setActiveDockAndShowAll = (button, dock) => {
//     if (dock.isVisible()) {
//         button.container.classList.remove('inactive');
//         button.container.classList.add('active');
//         allRebardbId.forEach(item => {
//             viewer.show(item)
//         })
//     }
//     else {
//         button.container.classList.remove('active');
//         button.container.classList.add('inactive');
//     }
// }
// const setActiveDockAndEventSelect = function (button, dock) {
//     if (dock.isVisible()) {
//         button.container.classList.remove('inactive');
//         button.container.classList.add('active');
//         viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
//     }
//     else {
//         button.container.classList.remove('active');
//         button.container.classList.add('inactive');
//         viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
//     }
// }
// function setActiveDock(button, dock) {
//     if (dock.isVisible()) {
//         button.container.classList.remove('inactive');
//         button.container.classList.add('active');
//     }
//     else {
//         button.container.classList.remove('active');
//         button.container.classList.add('inactive');
//     }
// }


Autodesk.Viewing.theExtensionManager.registerExtension('WHExtension', WHExtension);
export default 'WHExtension'