import React from 'react';
import { Image, Dropdown } from 'semantic-ui-react'
import { IconButton, Modal, Icon } from 'rsuite';
import _ from 'lodash'
import { Typography } from 'antd';
import { WHLogoText } from '../../../image'
import { getPublicUrl } from './../function/AdminFunction'
const { Text } = Typography;


const options =
  [
    {
      key: '1.0.0', text: '1.0.0', value: '1.0.0', date: 'Publish Date: 19/10/2021',
      changeLog: [

      ]
    },
  ]

class DialogAboutAddIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishDate: options[0].date,
      changeLog: options[0].changeLog,
    }
  }
  handleClosePanel = () => {
    this.props.closeDialog(false)
  }
  handleChangeVeriosn = (e, { value }) => {
    let index = _.findIndex(options, v => { return v.value === value })
    this.setState({
      publishDate: options[index].date,
      changeLog: options[index].changeLog
    })
  }
  handleTutorialLink = () => {
    getPublicUrl('tutorial/WH-Web 4D-5D  App Guideline.pdf')
      .then(res => {
        window.open(res)
      })
      .catch(err => { })
  }
  handleAddinLink = () => {
    getPublicUrl('tutorial/Wohhup Installer.exe')
      .then(res => {
        window.open(res)
      })
      .catch(err => { })
  }
  render() {
    return (
      <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} style={{ paddingTop: '3%' }} size='xs'>
        <Modal.Header>
          <Modal.Title>About Web Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Image src={WHLogoText} size='medium' rounded floated='left' />
          <Text strong>Develop by Bim team</Text>
          <Text link style={{ float: "right" }} >
            <a style={{color:'blue'}} target="_blank" rel="noopener noreferrer" onClick={this.handleAddinLink} >Download</a>{' '}/{' '}
            <a  style={{color:'blue'}}  target="_blank" rel="noopener noreferrer" onClick={this.handleTutorialLink}  >Tutorial</a>
          </Text><br />
          <Text >Changelog:</Text><br />
          {this.state.changeLog.map(v =>
            <>
              <Text type="secondary">{v}</Text><br />
            </>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Text style={{ float: "left" }} type="secondary">{this.state.publishDate}</Text>
          <Text strong >Version{' '}</Text>
          <Dropdown className='custom-version-changelog'
            inline style={{ color: 'rgba(0, 0, 0, 0.45)', fontWeight: '0px !important' }}
            options={options}
            defaultValue={options[0].value}
            onChange={this.handleChangeVeriosn}
          />
          {/* <Text type="secondary" >  1.0.3</Text> */}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DialogAboutAddIn