import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Loader, InputPicker, Checkbox } from 'rsuite';
import _ from 'lodash'
import {  Row, Col, message, Modal, Button, } from 'antd';
// import FontJson from '../fonts/helvetiker_bold.typeface.json'
import { Font, TextGeometry} from 'threejs-full-es6'
import axios from "axios";
const THREE = window.THREE
let font=null
function DialogGridView(props) {
  const [loading] = useState(false)
  const [defaultLevel, setDefaultLevel] = useState(true)
  const [listLevel, setListLevel] = useState([])
  const [level, setLevel] = useState(null)
 

  const handleClose = () => {
    props.onChangeDisplay('dialogGridView', false)
  }
  const handleOk = () => {
    if (defaultLevel) {
      props.viewer.overlays.removeScene('grid');
      props.viewer.overlays.addScene('grid');
      drawGrid(font)
      props.onChangeDisplay('dialogGridView', false)
    } else {
      if (level) {
        props.viewer.overlays.removeScene('grid');
        props.viewer.overlays.addScene('grid');
        let basePoint = props.aecModelData.refPointTransformation[11] + level.elevation - props.viewer.impl.model.myData.globalOffset.z
        drawGrid(font, basePoint)
        props.onChangeDisplay('dialogGridView', false)
      } else {
        message.warning('Please, pick at least one level')
      }
    }
  }
  const handleRemove = () => {
    if (props.viewer.overlays.hasScene('grid')) {
      props.viewer.overlays.removeScene('grid');
    }
    props.onChangeDisplay('dialogGridView', false)
  }
  useEffect(() => {
    try {
      if (props.aecModelData.grids.length === 0) {
        message.warning(`Grid isn't available in model`)
        props.onChangeDisplay('dialogGridView', false)
      } else {
        axios.get('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_bold.typeface.json')
        .then(res=>{
          font = new Font(res.data)
          let levels = []
          _.forEach(props.aecModelData.levels, v => {
            levels.push({ key: v.name, label: v.name, value: v })
          })
          setListLevel(levels)
        })
        .catch(err=> {
          props.onChangeDisplay('dialogGridView', false)
        })
    
       
      }
    } catch {
      props.onChangeDisplay('dialogGridView', false)
    }

  }, [])

  const createText = async (params) => {
    const material = new THREE.MeshPhongMaterial({
      specular: new THREE.Color(params.color),
      side: THREE.DoubleSide,
      reflectivity: 0.0,
      color: params.color
    })

    const materials = props.viewer.impl.getMaterials()

    materials.addMaterial(
      params.color.toString(),
      material,
      true)

    const geometry = new TextGeometry(params.text, params)


    const text = new THREE.Mesh(
      geometry, material)

    text.position.set(
      params.position.x,
      params.position.y,
      params.position.z)

    props.viewer.overlays.addMesh(text, 'grid');

    props.viewer.impl.sceneUpdated(true)
  }
  const drawGrid = (font, height = 0) => {

    //get AEC data from the document

    //get grids data
    const grids = props.aecModelData.grids

    const linesMaterial = new THREE.LineBasicMaterial({
      color: 0xff0000,
      linewidth: 10
    })
    const circleMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0.7 });

    //draw each grid one by one
    _.forEach(grids,grid => {
      //label
      const segments = grid.segments
      //draw each segment one by one

      _.forEach(segments,seg => {
        //start point
        const start = seg.points.start
        //end point
        const end = seg.points.end

        const midPoint = seg.points.midPoint

        if(midPoint){
          // const curve = new CatmullRomCurve3(
          //   [new THREE.Vector3(start[0], start[1], height + start[2]),
          //   new THREE.Vector3(midPoint[0], midPoint[1], height + midPoint[2]),
          //   new THREE.Vector3(end[0], end[1], height + end[2])
          //   ]);
          //   const points = curve.getPoints( 50 )
          //   const geometry = new BufferGeometry().setFromPoints( points )
          //   const line = new Line( geometry, linesMaterial )
          //   props.viewer.overlays.addMesh(line, 'grid');

          // const lineGeo = new THREE.Geometry()
          // lineGeo.vertices.push(new THREE.Vector3(start[0], start[1], height + start[2]))
          // lineGeo.vertices.push(new THREE.Vector3(midPoint[0], midPoint[1], height + midPoint[2]))
          // lineGeo.vertices.push(new THREE.Vector3(end[0], end[1], height + end[2]))
          // const line = new THREE.Line(lineGeo, linesMaterial)
          // props.viewer.overlays.addMesh(line, 'grid');
          return
        }else{
          const lineGeo = new THREE.Geometry()
          lineGeo.vertices.push(new THREE.Vector3(start[0], start[1], height + start[2]))
          lineGeo.vertices.push(new THREE.Vector3(end[0], end[1], height + end[2]))
          const line = new THREE.Line(lineGeo, linesMaterial)
          props.viewer.overlays.addMesh(line, 'grid');
        }

        //grid circle
        var circleGeo = new THREE.CircleGeometry(3, 32);
        var circle = new THREE.Mesh(circleGeo, circleMaterial);
        props.viewer.overlays.addMesh(circle, 'grid');
        //transform the circle to the position of max point of bounding box of this grid.
        circle.position.set(grid.boundingBox[3], grid.boundingBox[4], height + 0.1);

        //draw text
        createText({
          //intensionally to adjust the position of the text
          //will need to improve to make it more elegant
          position: { x: grid.boundingBox[3] - 1, y: grid.boundingBox[4] - 1, z: height + 0.2 },
          bevelEnabled: true,
          curveSegments: 24,
          bevelThickness: 0.1,
          color: 'red',
          text: grid.label,
          bevelSize: 0.1,
          height: 0.01,
          size: 2,
          font: font
        })
      })
    })
    props.viewer.impl.sceneUpdated(true)
  }
  const handleDefaultLevel = (value) => {
    setDefaultLevel(value)
  }
  const handleLevel = (value) => {
    setLevel(value)
  }
  return (
    <Modal
      title="Grid"
      visible={props.openPanel}
      onCancel={handleClose}
      footer={
        <>
          <Button onClick={handleRemove} type='danger'>
            Remove
        </Button>
          <Button onClick={handleOk} >
            Create
        </Button>

        </>
      }
      width={'30%'}
    >
      {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
      <Row>
        <Col span={16}>
          <InputPicker data={listLevel} block onChange={handleLevel} value={level} />
        </Col>
        <Col span={8}>
          <Checkbox checked={defaultLevel} onChange={handleDefaultLevel} >Default</Checkbox>
        </Col>
      </Row>


    </Modal>
  );
}
DialogGridView.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(DialogGridView)
