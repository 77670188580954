import { Icon, Input, message, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiFetchPublicSettingThisProject } from '../../../api';
import { colorType, disciplineType } from '../../../constants';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';


const { Option } = Select;


const PanelAddFormDiscipline = ({ stateProject, onClickCancelModal, onClickApply }) => {

   const { allDataOneSheet: { projectId, token } } = stateProject;


   useEffect(() => {
      const fetchData = async () => {
         try {
            const res = await apiFetchPublicSettingThisProject({ token, projectId });
            const publicSettings = res.data[0];

            setDisArray((publicSettings.disciplineForm || []).sort());

         } catch (err) {
            console.log(err);
         };
      };
      fetchData();
   }, []);

   const [disArray, setDisArray] = useState();
   const [disciplinePicked, setDisciplinePicked] = useState();
   const [suffixDiscipline, setSuffixDiscipline] = useState('');




   const addThisDiscipline = () => {
      if (!disciplinePicked || !suffixDiscipline) {
         return message.warn('Please key-in discipline and sub-discipline!');
      };

      let suffix = suffixDiscipline.replace(/ /g, '');


      const suffixParts = suffix.split('/');
      let isCorrectFormat = true;
      suffixParts.forEach(part => {
         const foundDuplicated = disciplineType.find(dis => dis.key.toLowerCase() === part.toLowerCase());
         if (foundDuplicated) {
            isCorrectFormat = false;
         };
      });
      if (!isCorrectFormat) {
         return message.warn('Sub-discipline can not be named as discipline!');
      };
      

      const allParts = suffix.toUpperCase().split('/').filter(x => x);

      let outputSuffixDiscipline = ''
      allParts.forEach((part, i) => {
         outputSuffixDiscipline += (i === 0 ? `${part}` : `/${part}`);
      });

      const newDisciplineToAdd = disciplinePicked + '/' + outputSuffixDiscipline;
      if (disArray.indexOf(newDisciplineToAdd) !== -1) {
         return message.warn('This discipline has been already existed, please choose a new one!');
      };


      setDisArray([newDisciplineToAdd, ...disArray]);
      setSuffixDiscipline('');
   };


   const removeThisDiscipline = (item) => {
      if (disArray.indexOf(item) !== -1) {
         setDisArray(disArray.filter(x => x !== item));
      };
   };


   return (
      <div style={{ padding: 10, color: 'black' }}>
         <div style={{ display: 'flex', marginBottom: 15 }}>
            <SelectStyled
               defaultValue='Select Discipline...'
               value={disciplinePicked}
               style={{ marginRight: 5, width: 70 }}
               onChange={(value) => setDisciplinePicked(value)}
            >
               {disciplineType.map(item => (
                  <Option key={item.key} value={item.key}>{item.key}</Option>
               ))}
            </SelectStyled>
            <div style={{ marginRight: 5, paddingTop: 6 }}>/</div>
            <InputStyled
               style={{ width: 200, marginRight: 10 }}
               onChange={(e) => setSuffixDiscipline(e.target.value)}
               value={suffixDiscipline}
            />
            <ButtonStyle
               marginRight={5}
               name='Add Discipline'
               onClick={addThisDiscipline}
            />


         </div>

         <div style={{
            width: '100%', marginBottom: 15, borderBottom: `1px solid ${colorType.grey4}`,
            overflowY: disArray && 'auto', whiteSpace: 'pre-wrap', height: window.innerHeight * 0.5,
            // maxHeight: window.innerHeight * 0.5
         }}>
            {disArray ? (
               <>
                  {disArray.map(item => (
                     <div key={item} style={{ display: 'flex', marginBottom: 6 }}>

                        <Tooltip title='Remove This Discipline'>
                           <IconStyled>
                              <Icon
                                 type='delete'
                                 style={{ transform: 'translate(0, -6px)', color: colorType.grey2, fontSize: 12 }}
                                 onClick={() => removeThisDiscipline(item)}
                              />
                           </IconStyled>
                        </Tooltip>
                        <div style={{ marginLeft: 8 }}>{item}</div>
                     </div>
                  ))}
               </>
            ) : (
               <div style={{ height: window.innerHeight * 0.5 }}></div>
            )}


         </div>

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => onClickApply({ disciplineForm: disArray })}
               newTextBtnApply={'Save'}
            />
         </div>
      </div>

   );
};

export default PanelAddFormDiscipline;


const SelectStyled = styled(Select)`
   .ant-select-selection {
      border-radius: 0;
   }
`;




const InputStyled = styled(Input)`
   color: black;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;

const IconStyled = styled.div`
   margin-left: 7px;
   width: 18px; 
   height: 18px; 
   border: 1px solid ${colorType.grey1};
   text-align: center;
   &:hover {
      background-color: ${colorType.grey4};
      cursor: pointer;
   };
`;