import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/app/auth";

import { Navbar, Nav, NavbarBrand, NavbarToggler, Collapse, } from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import Breadcrumbs from './Breadcrumbs'
import { WHLogoText } from '../../image'
import { Button, Dropdown, Header } from 'semantic-ui-react'
import { Popover, Icon, List, Spin, Avatar, message, Badge, Popconfirm, Alert, Typography, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import moment from "moment";
import { DialogAboutWeb, DialogUser, DialogAboutAddIn } from '../module_BimApp/dialog'

import { browserDetection, socket, getPublicUrl, bucketS3, checkWohhupUser } from '../../components/module_BimApp/function/AdminFunction'
import decode from 'jwt-decode';
import { storageUser } from '../../components/module_BimApp/function/PageFunction'
import { WHLogo } from '../../image'
import Push from 'push.js'
import { Divider, ButtonGroup, IconButton } from 'rsuite';
import * as rsuite from 'rsuite'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import stc from 'string-to-color'
message.config({
  maxCount: 3,
});
const { Text } = Typography
class TopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      hasMore: true,
      data: [],
      unReadCount: 0,
      countCurrent: 10,

      openDialogAboutWeb: false,
      openDialogAddin: false,
      isEdge: false,
      avatar: '',
      img: null,
      openDialogUser: false,
      openNotification: false,
      endInfinityLoad: false,

      isOffice: false,
    }

  }



  toggle = () => this.setState({ isOpen: !this.state.isOpen })


  componentDidMount() {
    // navigator.serviceWorker.addEventListener('controllerchange', function () {
    //   if (refreshing) return;
    //   window.location.reload();
    //   refreshing = true;
    // });
    // try{

    // }catch{}
    // window.Office.initialize = (e) => {  
    //   if(this.props.isConfirmed){
    //     this.props.history.replace("/excel")
    //     this.setState({ isOffice: true },()=>{

    //     })

    //   }
    // };
    const payload = decode(this.props.user.token)
    socket.on('notification-issue', this.handleRealtimeNotificationIssue)
    socket.on('notification-comment', this.handleRealtimeNotificationComment)
    socket.on('notification-folder', this.handleRealtimeNotificationFolder)
    socket.on('notification-viewpoint', this.handleRealtimeNotificationViewpoint)
    this.refreshToken(payload)
    this.fetchData()
    this.setAvatar(payload)
    this.setState({
      isEdge: browserDetection() === 'msie' ? true : false,
      //checkPathName: window.location.pathname !== '/drawing-dashboard' 
    })
  }

  componentWillUnmount = () => {
    socket.removeListener('notification-issue', this.handleRealtimeNotificationIssue)
    socket.removeListener('notification-comment', this.handleRealtimeNotificationComment)
    socket.removeListener('notification-folder', this.handleRealtimeNotificationFolder)
    socket.removeListener('notification-viewpoint', this.handleRealtimeNotificationViewpoint)
  }
  //#region handle socket

  handleRealtimeNotificationIssue = (data) => {
    if (data.user === this.props.user.email) {
      this.fetchData()
      Push.create(data.title, {
        body: data.message,
        icon: WHLogo,
        timeout: 10000,
        onClick: function () {
          window.open(data.link)
          this.close()
        }
      });
    }
  }
  handleRealtimeNotificationComment = (data) => {
    if (data.user === this.props.user.email) {
      this.fetchData()
      Push.create(data.title, {
        body: data.message,
        icon: WHLogo,
        timeout: 10000,
        onClick: function () {
          window.open(data.link)
          this.close()
        }
      });
    }
  }
  handleRealtimeNotificationFolder = (data) => {
    if (data.user === this.props.user.email) {
      this.fetchData()
      Push.create(data.title, {
        body: data.message,
        icon: WHLogo,
        timeout: 10000,
      });
    }
  }
  handleRealtimeNotificationViewpoint = (data) => {
    if (data.user === this.props.user.email) {
      this.fetchData()
      Push.create(data.title, {
        body: data.message,
        icon: WHLogo,
        timeout: 10000,
        // onClick: function () {
        //   window.open(data.link)
        //   this.close()
        // }
      });
    }
  }
  //#endregion

  refreshToken = (payload) => {
    var current_time = new Date().getTime() / 1000;
    if (payload.exp < current_time) {
      // if (payload.email === 'admin@wohhup.com') {
      //   if (moment() < moment('13-07-2020', 'DD-MM-YYYY')) {
      //     this.props.logout(payload.email)
      //     return
      //   }
      // }
      // const payload = decode(localStorage.bookwormJWT)
      let userInfo = null
      message.info('Refreshing token...')
      if (localStorage.userWh5Dapp && payload.email !== 'admin@wohhup.com' && checkWohhupUser(payload.email)) {
        userInfo = {}
        let user = JSON.parse(localStorage.userWh5Dapp)
        userInfo.userName = user.displayName
        userInfo.title = user.jobTitle
        userInfo.company = 'Woh Hup Private Ltd'
        userInfo.phone = user.mobilePhone === null ? '' : user.mobilePhone
      }
      axios.post("/api/auth/refresh_token", { token: this.props.user.token, email: payload.email, user: userInfo })
        .then(res => {
          // localStorage.bookwormJWT = res.data.user.token
          storageUser(res.data.user)
        })
        .catch(() => {
          this.props.logout(payload.email)
        })
    }
  }
  setAvatar = (payload) => {
    if (payload.email === 'admin@wohhup.com' || !checkWohhupUser(payload.email)) {
      let temp = payload.imgUrl
      if (payload.imgUrl.includes(bucketS3))
        temp = payload.imgUrl.split(`${bucketS3}/`)[1]
      getPublicUrl(temp)
        .then(res => {
          this.setState({ avatar: 'Ad', img: res })
        })
        .catch(() => {
          this.setState({ avatar: 'Ad', img: '' })
        })
      return
    }
    if (localStorage.userWh5Dapp) {
      if (checkWohhupUser(payload.email)) {
        try {
          window.client
            .api('/me/photo/$value')
            .get((err1, res1) => {
              if (err1) return
              const url = window.URL || window.webkitURL;
              const blobUrl = url.createObjectURL(res1);
              this.setState({ avatar: '', img: blobUrl })
            })
        } catch {
          this.setState({ avatar: 'UN', img: '' })
        }
      } else {
        try {
          let user = JSON.parse(localStorage.userWh5Dapp)
          let split = user.displayName.split(' ')
          let first = split[0].slice(0, 1)
          let second = ''
          if (split.length > 1)
            second = split[split.length - 1].slice(0, 1)
          let temp = payload.imgUrl
          if (payload.imgUrl.includes(bucketS3))
            temp = payload.imgUrl.split(`${bucketS3}/`)[1]
          getPublicUrl(temp)
            .then(res => {
              this.setState({ avatar: second === '' ? (first).toUpperCase() : (first + second).toUpperCase(), img: res })
            })
            .catch(() => {
              this.setState({ avatar: second === '' ? (first).toUpperCase() : (first + second).toUpperCase(), img: '' })
            })
        } catch {
          this.setState({ avatar: 'UN', img: '' })
        }
      }
    }
  }
  fetchData = (open = false) => {
    axios.post('/api/users/get-notification', { email: this.props.user.email, count: this.state.countCurrent })
      .then(res => {
        var temp = []
        let countTemp = 0
        let check = open ? false : res.data.length === this.state.data.length
        res.data.forEach(item => {
          if (item.status === 'Un-Read')
            countTemp = countTemp + 1

          let date = item.time
          let idTemp = JSON.stringify(item.message + item.title + item.time)
          let a = moment(Date.now())
          let b = moment(item.time)
          if (a.diff(b, 'seconds') < 60)
            item.time = a.diff(b, 'seconds') + " sec"
          else if (a.diff(b, 'minutes') < 60)
            item.time = a.diff(b, 'minutes') + " min"
          else if (a.diff(b, 'hours') < 24)
            item.time = a.diff(b, 'hours') + " h"
          else
            item.time = a.diff(b, 'days') + " d"

          let iconTemp = 'info';
          let colorTemp = '#1890ff';
          if (item.title.includes('Delete')) {
            iconTemp = 'delete'
            colorTemp = '#f44336'
          } else if (item.title === 'Add User') {
            iconTemp = 'user-add'
            colorTemp = '#21ba45'
          } else if (item.title === 'Remove User') {
            iconTemp = 'user-delete'
            colorTemp = '#f44336'
          } else if (item.title === 'Add Folder') {
            iconTemp = 'folder-add'
            colorTemp = '#21ba45'
          }
          else if (item.title === 'Upload version' || item.title === 'Upload Item') {
            iconTemp = 'file-add'
            colorTemp = '#21ba45'
          } else if (item.title.includes('Comment')) {
            iconTemp = 'message'
            colorTemp = '#ff9800'
          }
          else if (item.title.includes('Remove Attach File')) {
            iconTemp = 'paper-clip'
            colorTemp = '#f44336'
          }
          else if (item.title.includes('Attach File')) {
            iconTemp = 'paper-clip'
            colorTemp = '#ff9800'
          }
          else if (item.title.includes('Issue')) {
            iconTemp = 'warning'
            colorTemp = '#ff9800'
          }
          else if (item.title.includes('Comment Markup')) {
            iconTemp = 'warning'
            colorTemp = '#ff9800'
          }
          else if (item.title.includes('Viewpoint')) {
            iconTemp = 'camera'
            colorTemp = '#ff9800'
          }
          else if (item.title.includes('Add') || item.title.includes('Upload')) {
            iconTemp = 'plus-circle'
            colorTemp = '#21ba45'
          }

          temp.push({
            id: idTemp, message: item.message, title: item.title, time: item.time, status: item.status, date: date
            , icon: iconTemp, color: colorTemp, link: item.link
          })
        })
        this.setState({ data: temp, unReadCount: countTemp, loading: false, endInfinityLoad: check })
        if (navigator.setExperimentalAppBadge) {
          navigator.setExperimentalAppBadge(countTemp);
        } else if (window.ExperimentalBadge) {
          window.ExperimentalBadge.set(countTemp);
        }
      })
      .catch()
  }


  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({ loading: true });
    if (data.length > 100 || this.state.endInfinityLoad) {
      // message.warning('Infinite List loaded all');
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.setState({ countCurrent: this.state.countCurrent + 10 }, function () {
      this.fetchData()
    })

  };
  handleNotification = () => {
    this.setState({ openNotification: !this.state.openNotification }, () => {
      if (this.state.openNotification)
        this.fetchData(true);
    })
  }

  handleDeleteNotification = () => {
    axios.post('/api/users/delete-notification', { email: this.props.user.email })
      .then(() => {
        this.setState({ data: [] })
      })
      .catch()
  }
  handleListItem = (e) => {
    axios.post('/api/users/read-notification', { email: this.props.user.email, data: e.currentTarget.id })
      .then(() => {
        this.fetchData()
      })
      .catch()
  }
  closeDialogAboutWeb = () => {
    this.setState({ openDialogAboutWeb: false })
  }
  closeDialogAddin = () => {
    this.setState({ openDialogAddin: false })
  }
  closeDialogUser = () => {
    this.setState({ openDialogUser: false })
  }
  handleAdminPage = () => {
    const payload = decode(this.props.user.token)
    if (payload.email === 'admin@wohhup.com')
      window.location = window.location.origin + "/adminpage"
  }
  handleOpenIssue = (e) => {
    if (!e.props.item.mainModel) return
    axios.post('/api/issue/check-item', { token: this.props.user.token, itemId: e.props.item.mainModel.itemId })
      .then(res => {
        if (res.data) {
          window.open(e.props.item.link)
          axios.post('/api/users/read-notification', { email: this.props.user.email, data: e.props.item.id })
            .then(() => {
              this.fetchData()
            })
            .catch()
        } else {
          message.warning(`Model was deleted`)
        }
      })
      .catch(err => {
        message.warning(`Can't open issue`)
      })
  }

  handleHelp = () => {
    window.open('https://wohhup-my.sharepoint.com/:b:/p/bao_quylan/EUhSXQiZQpBCnnZVJzfXD1MBOF5IehGP2QepG3SVB2N84A?e=n20FAg')
  }
  handleSwitchPrefab = () => {
    let token = localStorage.bookwormJWT
    axios.get('/api/auth/redirect_token', { params: { token: this.props.user.token } })
      .then(res => {
        if (!window.confirm('Is localhost?')) {
          if (window.location.origin.includes('idd.wohhup.com'))
            window.location.replace(`https://digicheck.wohhup.com/idd_redirect/${res.data.token}`)
          else
            window.location.replace(`https://prefab.bql-app.com/idd_redirect/${res.data.token}`)
        } else {
          window.location.replace(`http://localhost:3007/idd_redirect/${res.data.token}`)
        }
      })

  }
  render() {
    const { user, logout, isAdmin } = this.props;
    const options = isAdmin ? [
      { key: 'username', text: user.email, icon: 'user', onClick: (() => this.setState({ openDialogUser: true })) },
      // { key: 'qrscanner', text: 'QR Scanner', icon: 'qrcode',onClick:(() => { window.location =window.location .origin+ "/pc"})},
      { key: 'Admin', text: 'Admin Page', icon: 'settings', onClick: (() => this.handleAdminPage()) },
      { key: 'Prefab', text: 'Switch to Admin Prefab', icon: 'settings', onClick: (() => this.handleSwitchPrefab()) },
      { key: 'AddIn', text: 'Download Add-In', icon: 'download', onClick: (() => this.setState({ openDialogAddin: true })) },
      { key: 'About', text: 'About', icon: 'question circle', onClick: (() => this.setState({ openDialogAboutWeb: true })) },
      { key: 'sign-out', text: 'Sign Out', icon: 'sign out', onClick: (() => logout(user)) }
    ] :
      user.email.includes('@wohhup.com') ?
        [
          { key: 'username', text: user.email, icon: 'user', onClick: (() => this.setState({ openDialogUser: true })) },
          // { key: 'qrscanner', text: 'QR Scanner', icon: 'qrcode',onClick:(() => { window.location =window.location .origin+ "/pc"})},
          { key: 'AddIn', text: 'Download Add-In', icon: 'download', onClick: (() => this.setState({ openDialogAddin: true })) },
          { key: 'About', text: 'About', icon: 'question circle', onClick: (() => this.setState({ openDialogAboutWeb: true })) },
          { key: 'sign-out', text: 'Sign Out', icon: 'sign out', onClick: (() => logout(user)) }]
        :
        [
          { key: 'username', text: user.email, icon: 'user', onClick: (() => this.setState({ openDialogUser: true })) },
          // { key: 'qrscanner', text: 'QR Scanner', icon: 'qrcode',onClick:(() => { window.location =window.location .origin+ "/pc"})},
          { key: 'About', text: 'About', icon: 'question circle', onClick: (() => this.setState({ openDialogAboutWeb: true })) },
          { key: 'sign-out', text: 'Sign Out', icon: 'sign out', onClick: (() => logout(user)) }]
    const trigger = (
      // <Image avatar src={gravatarUrl(user.email, { size: 40 })} />
      <span>
        <Avatar style={{ backgroundColor: stc(this.state.avatar), verticalAlign: 'middle' }} src={this.state.img} />
      </span>
    )
    const notificationContent = (item) => {
      return (
        <List.Item id={item.id} style={{ cursor: 'pointer' }} onClick={this.handleListItem.bind(this)}>
          <List.Item.Meta
            avatar={
              <Avatar size="large" style={{ backgroundColor: item.color }} >
                <Icon type={item.icon} style={{ verticalAlign: item.icon === 'warning' ? '0.367mm' : '-0.333mm', fontSize: '25px' }} />
              </Avatar>
            }
            title={
              <div style={{ height: 21.6 }}>
                {item.status === 'Un-Read' ? <rsuite.Icon icon='circle' style={{
                  marginRight: 5,
                  color: 'red'
                }} /> : <div
                  style={{
                    marginRight: 5,
                    color: 'red'
                  }} />}
                <Text style={{ position: 'absolute', width: 'calc(100% - 100px)' }} ellipsis={true}>
                  {`${item.title}`}</Text>
              </div>
            }
            description={
              <Tooltip title={item.message} placement="right">
                <Text type="secondary" style={{ position: 'absolute', width: 'calc(100% - 100px)' }} ellipsis={true}>{item.message}</Text>
              </Tooltip>

            }
          />
          <Divider className='divide-vertical-list-view-point' vertical />
          <Tooltip title={moment(item.date).format('DD-MM-YYYY HH:mm')} placement="right">
            <Text ellipsis={true} style={{ width: 35, textAlignLast: 'center' }}> {item.time}</Text>
          </Tooltip>
        </List.Item>
      )
    }
    return (
      <div style={{ zIndex: '200', position: 'sticky', top: 0, backgroundColor: 'white', boxShadow: '0 1px 1px' }} id='topNavigationRoot'>
        <Navbar id='topNavigation' light expand="sm" color="faded" >
          <NavbarBrand tag={RouterNavLink} activeClassName="active" to="/">
            <img style={{ maxWidth: '100px', maxHeight: '100px' }}
              src={WHLogoText}
            />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Breadcrumbs />

            <Nav className="ml-auto" navbar>
              <div>

                {!this.props.excelPage && ((this.props.user.permission.roleDashboard || this.props.isAdmin) &&
                  <ButtonGroup style={{ marginLeft: 2 }}>
                    <IconButton size="sm" onClick={this.props.handleChangeUrl.bind(this, '/projects')} icon={<rsuite.Icon icon='cube' />}
                      color={this.props.pathName.includes('/projects') && 'blue'} />
                    <IconButton size='sm' onClick={this.props.handleChangeUrl.bind(this, '/drawing-dashboard')} icon={<rsuite.Icon icon='dashboard' />}
                      color={this.props.pathName === '/drawing-dashboard' && 'blue'} />

                  </ButtonGroup>
                  //  <rsuite.Toggle size="lg" checkedChildren="Bim" unCheckedChildren="Dashboard" onChange={this.handleChangeDashBoard}
                  //   size='md' style={{ top: 5 }} checked={this.state.checkPathName} />
                )}
                <Button circular basic color="twitter" icon='question circle outline' style={{ boxShadow: 'none', marginRight: 0 }} onClick={this.handleHelp} />
                {!this.props.excelPage && <Popover placement="bottomRight" visible={this.state.openNotification}
                  title={
                    <div style={{ width: '100%', backgroundColor: '#f0f0f0' }}>
                      <Header as='h6' style={{ position: 'absolute' }}>Notification</Header>
                      <Popconfirm
                        title=" Are you sure to delete all notification ?" onConfirm={this.handleDeleteNotification}
                        okText="Yes" cancelText="No" placement="bottomRight"
                        icon={<Icon type="question-circle" style={{ color: 'red' }} />}
                      >
                        <Icon type="delete" style={{ float: "right" }} />
                      </Popconfirm>

                    </div>
                  }
                  content={
                    <div style={{ height: 400, width: 400, overflow: 'auto' }}>
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={this.handleInfiniteOnLoad}
                        hasMore={!this.state.loading && this.state.hasMore}
                        useWindow={false}
                      >
                        <List size="small" style={{ marginRight: 5 }}
                          dataSource={this.state.data}
                          renderItem={item => (
                            item.link ?
                              <MenuProvider id="notification_menu" animation={animation.zoom} data={{ item: item }}>
                                {notificationContent(item)}
                              </MenuProvider>
                              :
                              notificationContent(item)
                          )}
                        >
                          {this.state.loading && this.state.hasMore && (
                            <div className="demo-loading-container">
                              <Spin />
                            </div>
                          )}
                        </List>
                      </InfiniteScroll>
                      <Menu id='notification_menu' style={{ zIndex: 10000 }}>
                        <Item onClick={this.handleOpenIssue}>
                          <IconFont className="fas fa-check" />View Issue
                        </Item>
                      </Menu>
                    </div>
                  }
                  trigger="click">
                  <Badge count={this.state.unReadCount} style={{ top: 2, right: 5 }}>
                    <Button circular basic color="twitter" icon='bell outline' onClick={this.handleNotification} style={{ boxShadow: 'none' }} />
                  </Badge>

                </Popover>}
                {<Dropdown style={{ top: 0, marginLeft: 5 }}
                  trigger={trigger}
                  options={options}
                  pointing='top right'
                  icon={null}
                />}
              </div>

            </Nav>
          </Collapse>
        </Navbar>
        <div id="notification">A new version of this app is available <a style={{ color: '#01b9ff', textDecoration: 'underline', cursor: 'pointer' }} id="reload">Reload</a></div>
        {this.state.openDialogAboutWeb && <DialogAboutWeb onShow={this.state.openDialogAboutWeb} isAdmin={this.props.isAdmin} closeDialog={this.closeDialogAboutWeb} />}
        {this.state.openDialogAddin && <DialogAboutAddIn onShow={this.state.openDialogAddin} isAdmin={this.props.isAdmin} closeDialog={this.closeDialogAddin} />}
        {this.state.isEdge &&
          <Alert
            message="Error"
            description="This browser not support, please use other browser"
            type="error"
            showIcon
          />}
        {this.state.openDialogUser && <DialogUser onShow={this.state.openDialogUser} closeDialog={this.closeDialogUser} />}
      </div>

    );
  }
}

TopNavigation.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  excelPage: PropTypes.bool.isRequired,
  // projects:   PropTypes.arrayOf(PropTypes.object).isRequired,
  // folders:   PropTypes.arrayOf(PropTypes.object).isRequired,
  // breadcrumbs:   PropTypes.arrayOf(PropTypes.object).isRequired,
}
function mapStateToProps(state) {
  return {
    user: state.user,
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    store: state.store,
    excelPage: state.excel.excelPage,
    isConfirmed: !!state.user.confirmed,
    // projects: state.projects  ,
    // folders: state.folders  ,
    // breadcrumbs : state.breadcrumbs,
  };
}

export default connect(mapStateToProps, { logout: actions.logout })(TopNavigation)

