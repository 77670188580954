import React from 'react';
import $ from 'jquery'
import { Dropdown, Header } from 'semantic-ui-react'
import { Modal, Loader, Button, Icon } from 'rsuite';
import { List, Typography, InputNumber, message } from 'antd';
import _ from 'lodash'
import axios from 'axios'

import {
  extractDataFromSmartSheet, getAndGroupElementAreaMethod, getAndGroupElementCountMethod, getAndGroupElementVolumeMethod ,getAndGroupElementLengthMethod
} from '../function/DialogFunction';
import { TableStatusForClaim } from '../../module_BimApp/tables'
import { View2DStatus } from '../../module_BimApp/dock_forge'
import Dock from 'react-dock'
import { ColumnStatusForClaim } from '../../module_BimApp/columns'
import { trackingUserUsing } from '../function/AdminFunction'
const { Text } = Typography;


class BottomBarStatusForClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPanel: false,
      columnsStatus: [],
      dataStatus: [],
      statusOptions: [],
      listSheetId: [],
      sheetReportId: null,
      displayTable: false,
      loading: false,
      openEditPanel: false,
      activeBtnView2D: false,
      activeBtnGroupStatus: false,
      claimType: null,
      contractType: 'LTA Civil Project',

      heightDock: 500,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,

    }
  }


  componentWillMount = () => {

  }
  componentWillUnmount = () => {
  }
  componentWillReceiveProps = (nextProps) => {
    if (!nextProps.modelLoaded) {
      if (this.props.folderSmartSheetId !== null && this.props.folderSmartSheetId !== undefined && this.props.folderSmartSheetId !== "" && nextProps.openPanel) {
        trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'Status for claim')
        this.setState({ listSheetId: [], sheetReportId: null, claimType: null, statusOptions: [], loading: true }, () => {
          axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: this.props.folderSmartSheetId })
            .then(res => {
              var tempListSheet = []
              res.data.forEach(item => {
                tempListSheet.push({ key: item.name, text: item.name, value: item.id })
              })
              this.setState({ listSheetId: tempListSheet }, () => {
                this.restoreStateFromLocalStorage()
              })
            })
            .catch(err => { })
        })

        // ! callback when table display
        if (this.state.displayTable) {
          this.setState({
            displayTable: false, activeBtnView2D: false
          }, () => {
            $('#splitForgeViewer').height(this.state.maxForgeViewer)
            nextProps.viewer.resize()
            nextProps.onDisabledBtnChange('btnStatusForClaim', false)
          })
        }
      }
    } else {
      this.setState({ loading: true })
    }

  }


  handleChangeClaimType = (e, { value }) => {
    this.setState({ claimType: value })
  }

  handleApplyReport = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.setState({ loading: true })
    let statusData = JSON.stringify(this.state.statusOptions)
    axios.post(`/api/versions/save-status-percent`, { itemId: this.props.itemId, smartSheetId: this.state.sheetReportId, status: statusData })
      .then(res => {
        message.success('Saved status')
      })
      .catch(err => {
        message.success('Saved status')
      })
    axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.sheetReportId })
      .then(async res => {
        let tempListModel = {}
        const models = this.props.viewer.impl.modelQueue().getModels();
        _.forEach(models, (model) => {
          tempListModel[model.myData.loadOptions.modelNameOverride] = model
        })
        var sheetData = await extractDataFromSmartSheet(res.data)
        let dataStatus = null
        let columns = null
        if (this.state.claimType === 'Area percentage method') {
          dataStatus = await getAndGroupElementAreaMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentArea'] : ['code5D', 'masterID', 'detailID', 'status', 'percentArea']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Count quantity method') {
          dataStatus = await getAndGroupElementCountMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status'] : ['code5D', 'masterID', 'detailID', 'status']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Volume percentage method') {
          dataStatus = await getAndGroupElementVolumeMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'percentVolume']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Length percentage method') {
          dataStatus = await getAndGroupElementLengthMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          console.log(dataStatus)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentLength'] : ['code5D', 'masterID', 'detailID', 'status', 'percentLength']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        }
        this.setState({
          displayTable: true, dataStatus: dataStatus,
          maxForgeViewer: $('#splitForgeViewer').height(),
          modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock,
          columnsStatus: columns,
          loading: false, openEditPanel: false
        }, () => {
          $('#splitForgeViewer').height(this.state.modifyForgeViewer)
          this.props.viewer.resize()
        })
        this.props.onChangeDisplay('dialogStatusForClaim', false)
        this.props.onDisabledBtnChange('btnStatusForClaim', true)
        this.saveStateToLocalStorage({ sheetReportId: this.state.sheetReportId, claimType: this.state.claimType, contractType: this.state.contractType })
      })
  }
  saveStateToLocalStorage = (data) => {
    localStorage.setItem(`whapp5d-statusForClaim-${this.props.email}-${this.props.itemId}`, JSON.stringify(data));
  };
  restoreStateFromLocalStorage = () => {
    const state = JSON.parse(localStorage.getItem(`whapp5d-statusForClaim-${this.props.email}-${this.props.itemId}`));
    if (state !== null && state !== undefined) {
      this.setState(state, () => {
        axios.post(`/api/smartsheet/get-column-status`, { smartSheetId: this.state.sheetReportId })
          .then(res => {
            this.matchStatusStorage(res.data)
          })
          .catch(err => {
            this.setState({ loading: false })
          })
      })
    } else {
      this.setState({ loading: false })
    }
  }
  handleClosePanel = () => {
    this.props.onChangeDisplay('dialogStatusForClaim', false)
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.setState({ displayTable: false })
  }
  handleChangeSheetReport = (e, { value }) => {
    this.setState({ sheetReportId: value, loading: true })
    axios.post(`/api/smartsheet/get-column-status`, { smartSheetId: value })
      .then(res => {
        this.matchStatusStorage(res.data)

      })
      .catch(err => { })
  }
  matchStatusStorage = (data) => {
    let _this = this
    axios.get(`/api/versions/get-status-percent?q=${this.props.itemId}`)
      .then(res => {
        if (res.data.length !== 0) {
          let index = _.findIndex(res.data, function (o) { return o.smartSheetId === _this.state.sheetReportId })
          if (index >= 0) {
            let tempStatus = JSON.parse(res.data[index].status)
            let temp = []
            _.forEach(data, v => {
              let index = _.findIndex(tempStatus, function (o) { return o.name === v })
              if (index >= 0)
                temp.push({ name: v, value: tempStatus[index].value, color: tempStatus[index].color })
              else
                temp.push({ name: v, value: 0, color: "#999999" })
            })
            this.setState({ statusOptions: temp, loading: false })
          } else {
            let temp = []
            _.forEach(data, v => {
              temp.push({ name: v, value: 0, color: "#999999" })
            })
            this.setState({ statusOptions: temp, loading: false })
          }
        } else {
          let temp = []
          _.forEach(data, v => {
            temp.push({ name: v, value: 0, color: "#999999" })
          })
          this.setState({ statusOptions: temp, loading: false })
        }
      })
  }
  handleColorStatus = (item, e) => {
    let index = _.findIndex(this.state.statusOptions, function (o) { return o.name === item.name })
    let temp = this.state.statusOptions
    temp.splice(index, 1)
    temp.splice(index, 0, { name: item.name, value: item.value, color: e.target.value })
    this.setState({ statusOptions: temp })
  }
  handleChangePercent = (item, value) => {
    let index = _.findIndex(this.state.statusOptions, function (o) { return o.name === item.name })
    let temp = this.state.statusOptions
    temp.splice(index, 1)
    temp.splice(index, 0, { name: item.name, value: value, color: item.color })
    this.setState({ statusOptions: temp })
  }
  //#region //?edit table
  handleEditReport = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.setState({ loading: true })
    let statusData = JSON.stringify(this.state.statusOptions)
    axios.post(`/api/versions/save-status-percent`, { itemId: this.props.itemId, smartSheetId: this.state.sheetReportId, status: statusData })
      .then(res => {
        message.success('Saved status')
      })
      .catch(err => {
        message.success('Saved status')
      })
    axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.sheetReportId })
      .then(async res => {
        let tempListModel = {}
        const models = this.props.viewer.impl.modelQueue().getModels();
        _.forEach(models, (model) => {
          tempListModel[model.myData.loadOptions.modelNameOverride] = model
        })
        var sheetData = await extractDataFromSmartSheet(res.data, this.state.statusOptions)
        let dataStatus = null
        let columns = null
        if (this.state.claimType === 'Area percentage method') {
          dataStatus = await getAndGroupElementAreaMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentArea'] : ['code5D', 'masterID', 'detailID', 'status', 'percentArea']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Count quantity method') {
          dataStatus = await getAndGroupElementCountMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ? ['masterID', 'detailID', 'status'] : ['code5D', 'masterID', 'detailID', 'status']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Volume percentage method') {
          dataStatus = await getAndGroupElementVolumeMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'percentVolume']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        }  else  if (this.state.claimType === 'Length percentage method') {
          dataStatus = await getAndGroupElementLengthMethod(this.props.viewer, sheetData, this.state.statusOptions, this.state.contractType)
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentLength'] : ['code5D', 'masterID', 'detailID', 'status', 'percentLength']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } 
        this.setState({
          columnsStatus: columns,
          loading: false, openEditPanel: false,
          dataStatus: dataStatus,
        })
      })
  }
  handleEditStatus = () => {
    this.setState({ openEditPanel: true })
  }
  handleCloseEditPanel = () => {
    this.setState({ openEditPanel: false })
  }
  sizeChange = (e, da) => {
    if (this.state.displayTable) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
      })
    }
  }
  onChangeHeader = (id, text) => {
    let temp = []
    _.forEach(this.state.columnsStatus, v => {
      if (id == v.id) {
        v.filter = text
        temp.push(v)
      } else {
        temp.push(v)
      }
    })
    this.setState({ columnsStatus: temp })
  }
  onActiveTable = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.setState({
      displayTable: false, statusOptions: []
    }, () => {
      $('#splitForgeViewer').height(this.state.maxForgeViewer)
      this.props.viewer.resize()
      this.props.onDisabledBtnChange('btnStatusForClaim', false)
    })
  }
  //#endregion 

  handleOpenView2D = () => {
    if (!this.state.activeBtnView2D) {
      this.setState({ activeBtnView2D: true })
    }
    else {
      this.setState({ activeBtnView2D: false })
    }
  }
  onChangeDockDisplay = (value) => {
    this.setState({ activeBtnView2D: value })
  }
  handleGroupStatus = () => {
    let columns = []
    this.setState({ activeBtnGroupStatus: !this.state.activeBtnGroupStatus }, () => {
      if (this.state.activeBtnGroupStatus) {
        if (this.state.claimType === 'Area percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'totalArea', 'totalVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'totalArea', 'totalVolume']
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Count quantity method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'totalVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'totalVolume']
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Volume percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'totalArea', 'totalVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'totalArea', 'totalVolume']
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        }else     if (this.state.claimType === 'Length percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'totalLength', 'totalVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'totalLength', 'totalVolume']
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        }
      } else {
        if (this.state.claimType === 'Area percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentArea'] : ['code5D', 'masterID', 'detailID', 'status', 'percentArea']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Count quantity method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status'] : ['code5D', 'masterID', 'detailID', 'status']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } else if (this.state.claimType === 'Volume percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentVolume'] : ['code5D', 'masterID', 'detailID', 'status', 'percentVolume']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        }else  if (this.state.claimType === 'Length percentage method') {
          let tempStatusOptions = this.state.contractType === 'LTA Civil Project' ?
            ['masterID', 'detailID', 'status', 'percentLength'] : ['code5D', 'masterID', 'detailID', 'status', 'percentLength']
          _.forEach(this.state.statusOptions, v => {
            tempStatusOptions.push(v.name)
          })
          tempStatusOptions.push('percent')
          columns = ColumnStatusForClaim(tempStatusOptions, this.state.contractType)
        } 
      }
      this.setState({ columnsStatus: columns })
    })

  }
  handleChangeContractType = (e, data) => {
    this.setState({ contractType: data.value })
  }
  render() {
    const claimOptions = [
      { key: 'Area percentage method', text: 'Area percentage method', value: 'Area percentage method', },
      { key: 'Length percentage method', text: 'Length percentage method', value: 'Length percentage method', },
      { key: 'Count quantity method', text: 'Count quantity method', value: 'Count quantity method' },
      { key: 'Volume percentage method', text: 'Volume percentage method', value: 'Volume percentage method' }
    ]
    const contractTypeOpt = [
      { key: 'LTA Civil Project', text: 'LTA Civil Project', value: 'LTA Civil Project', },
      { key: 'Building Project', text: 'Building Project', value: 'Building Project', },
    ]
    return (
      <div>
        <Modal backdrop="static" show={this.props.openPanel} onHide={this.handleClosePanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Status For Claim</Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyCompareVersion' >
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <Dropdown onChange={this.handleChangeContractType}
              placeholder='Contract Type'
              fluid style={{ marginBottom: 5 }}
              selection value={this.state.contractType}
              options={contractTypeOpt}
            />
            <Dropdown
              placeholder='Smartsheet Report' fluid selection
              style={{ marginBottom: 5 }}
              onChange={this.handleChangeSheetReport} value={this.state.sheetReportId}
              options={this.state.listSheetId}
            />
            <Dropdown
              placeholder='Claim Type' fluid selection
              style={{ marginBottom: 5 }}
              onChange={this.handleChangeClaimType} value={this.state.claimType}
              options={claimOptions}
            />
            <br />
            {this.state.statusOptions.length !== 0 &&
              < List size="small" bordered style={{ overflow: 'auto' }}
                dataSource={this.state.statusOptions}
                renderItem={item =>
                  <List.Item>
                    <Text strong>{item.name}</Text>
                    <input type='color' style={{ position: 'absolute', right: this.state.contractType === 'LTA Civil Project' ? 100 : 0 }} value={item.color}
                      onChange={this.handleColorStatus.bind(this, item)} />
                    {this.state.contractType === 'LTA Civil Project' && <InputNumber style={{ right: 0, position: 'absolute' }}
                      value={item.value} min={0} max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      onChange={this.handleChangePercent.bind(this, item)}
                    />}
                  </List.Item>
                }
              />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleApplyReport.bind(this)} color='blue' disabled={this.state.sheetReportId === null || this.state.claimType === null
              || this.state.claimType === undefined}
              loading={this.state.loading}>
              Apply
            </Button>
            <Button onClick={this.handleClosePanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" show={this.state.openEditPanel} onHide={this.handleCloseEditPanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Edit Report</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical />}
            {this.state.statusOptions.length !== 0 &&
              < List size="small" bordered bordered style={{ overflow: 'auto' }}
                dataSource={this.state.statusOptions}
                renderItem={item =>
                  <List.Item>
                    <Text strong>{item.name}</Text>
                    <input type='color' style={{ position: 'absolute', right: this.state.contractType === 'LTA Civil Project' ? 100 : 0 }} value={item.color}
                      onChange={this.handleColorStatus.bind(this, item)} />
                    {this.state.contractType === 'LTA Civil Project' &&
                      <InputNumber style={{ right: 0, position: 'absolute' }}
                        value={item.value} min={0} max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={this.handleChangePercent.bind(this, item)}
                      />}
                  </List.Item>
                }
              />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleEditReport.bind(this)} color='blue' loading={this.state.loading}>
              Apply
            </Button>
            <Button onClick={this.handleCloseEditPanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Dock position={'bottom'} isVisible={this.state.displayTable} dimMode={'none'} fluid={false} zIndex={200} size={this.state.heightDock}
          onSizeChange={this.sizeChange}  >
          <TableStatusForClaim columns={this.state.columnsStatus} data={this.state.dataStatus}
            viewer={this.props.viewer}
            getRowProps={row => ({})}
            getCellProps={rows => ({})}
            onChangeHeader={this.onChangeHeader}
            onActiveTable={this.onActiveTable}
            handleEditStatus={this.handleEditStatus}
            handleOpenView2D={this.handleOpenView2D}
            activeBtnView2D={this.state.activeBtnView2D}
            handleGroupStatus={this.handleGroupStatus}
            activeBtnGroupStatus={this.state.activeBtnGroupStatus}
            contractType={this.state.contractType}
          />
        </Dock>
        <View2DStatus viewer={this.props.viewer}
          openDock={this.state.activeBtnView2D} onChangeDockDisplay={this.onChangeDockDisplay} />
        <div style={{
          zIndex: '1', position: 'fixed', top: this.props.heightNavigation === '0px' ? '0px' : '53.78px', left: '45px',
          display: this.state.displayTable ? "block" : "none"
        }}>
          {this.state.statusOptions.length !== 0 && this.state.statusOptions.map(v =>
            <Header style={{ fontSize: 'small', margin: 0 }}>
              <Icon icon='circle' style={{ paddingRight: '10px', color: v.color }}></Icon>
              {this.state.contractType === 'LTA Civil Project' ? `${v.name}: ${v.value}%` : `${v.name}`}</Header>)}

        </div>
      </div>

    );
  }
}


export default BottomBarStatusForClaim