import { PDF_VIEWER } from "../types";


const init = {
    tab: 'view',
    feature: '',
    pdf: null,
    image: null,
    color:'#000000',
    isHand:true,
    isSelection: false,
    strokeWidth:1,
    scale:1,
}

export function pdfViewer(state = init, action = {}) {
    switch (action.type) {
        case PDF_VIEWER:
            return { ...state, ...action.data };
        default:
            return state;
    }
}
