import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Button, Icon, Radio } from "antd";
import { fabric } from "fabric";
import _ from "lodash";
import { format } from 'date-fns';
import DialogUnitHandedOver from "../../../module_BimApp/dialog/DialogUnitHandedOver";
import { Func_Export_Unit_Handed_Over } from "../../function/func_export_to_excel";
import DialogUnitHandedOverDownload from "../../../module_BimApp/dialog/DialogUnitHandedOverDownload";

const ChartBlockUnitStatus = ({ data }) => {
   const [canvas, setCanvas] = useState();
   const [listBlocks, setListBlock] = useState();
   const [currentBlock, setCurrentBlock] = useState();
   const [totalWidth, setTotalWidth] = useState(0);
   const [showAction, setShowAction] = useState(false);
   const [apartment, setApartment] = useState({});
   const [showDownload, setShowDownload] = useState(false);
   const [dataDownload, setDataDownload] = useState({});

   const totalHeight = 425;
   useLayoutEffect(() => {
      const w = document.getElementById("chartUnitBlock").offsetWidth;
      setTotalWidth(w);
      const initCanvas = () => new fabric.Canvas("c");
      setCanvas(initCanvas());
   }, []);

   useEffect(() => {
      getData();
   }, [data, currentBlock, totalWidth]);

   useEffect(() => {
      window.addEventListener("resize", handleResize, false);
   }, []);

   const handleResize = () => {
      const w = document.getElementById("chartUnitBlock").offsetWidth;
      setTotalWidth(w);
   };

   const getData = () => {
      if (data !== undefined) {
         let list_block = [];
         const listblock_temp = _.sortBy(_.map(_.uniqBy(data, "block"), "block"));
         listblock_temp.forEach((e) => {
            let blk = new Object();
            blk.name = e;
            const no_total = data.filter((x) => x.block === e).length;
            blk.noTotal = no_total;

            const no_completed = data.filter((x) => (x.status === 99 || x.status == 98) && x.block === e).length;
            blk.noCompleted = no_completed;
            list_block.push(blk);
         });

         setListBlock(list_block);

         if (currentBlock !== undefined) {
            let list_filter_block = data.filter((e) => e.block == currentBlock);
            const list_level = _.sortBy(_.map(_.uniqBy(list_filter_block, "level"), "level"));
            const list_unit = _.sortBy(_.map(_.uniqBy(list_filter_block, "unit"), "unit"));

            if (
               list_filter_block !== undefined &&
               list_unit !== undefined &&
               list_filter_block !== undefined &&
               totalWidth !== 0
            ) {
               drawGrid(list_unit, list_level, list_filter_block);
            }
         } else {
            setCurrentBlock(list_block[0].name);
            let list_filter_block = data.filter((e) => e.block == list_block[0].name);
            const list_level = _.sortBy(_.map(_.uniqBy(list_filter_block, "level"), "level"));
            const list_unit = _.sortBy(_.map(_.uniqBy(list_filter_block, "unit"), "unit"));

            if (
               list_filter_block !== undefined &&
               list_unit !== undefined &&
               list_filter_block !== undefined &&
               totalWidth !== 0
            ) {
               drawGrid(list_unit, list_level, list_filter_block);
            }
         }
      }
   };
   const popUp = (data) => {
      let info = {
         block: data.block,
         unit: data.level + '-' + data.unit,
         handedOverDate: data.date ? format(new Date(data.date), 'dd MMM yyyy') : null,
         qMRepresentative: data.qmRepresentative ? data.qmRepresentative : null,
         archiRepresentative: data.archiRepresentative ? data.archiRepresentative : null,
         notAcceptedItems: data.listItemsNotAcceptted ? data.listItemsNotAcceptted : []
      }
      setApartment(info)
      setShowAction(true)

   }

   function drawGrid(listUnit, listLevel, blockdata) {
      canvas.clear();
      canvas.setWidth(totalWidth);
      canvas.setHeight(totalHeight);
      const cellSizeWidth = Math.floor((totalWidth - 25) / (listUnit.length + 1));
      const cellSizeHeight = Math.floor((totalHeight - 50) / listLevel.length);
      const gridWidth = totalWidth - 75;
      const gridHeight = totalHeight;
      const xPos = 25;
      const yPos = 25;

      var bkgndrect = new fabric.Rect({
         width: gridWidth + 0,
         height: gridHeight + 0,
         stroke: "transparent",
         fill: "transparent",
         selectable: false,
      });

      // var rect = new fabric.Rect({
      //    left: 0,
      //    top: 0,
      //    width: gridWidth,
      //    height: gridHeight,
      //    stroke: "#000000",
      //    fill: "#cccccc",
      //    selectable: false,
      // });

      var gridGroup = new fabric.Group([bkgndrect], {
         left: xPos,
         top: yPos,
         selectable: false,
         subTargetCheck: true
      });

      canvas.add(gridGroup);

      //legend:
      // var rect1 = new fabric.Rect({
      //    left: 0,
      //    top: -gridHeight / 2,
      //    width: 50,
      //    height: 60,
      //    stroke: "#1f618d",
      //    fill: "red",
      //    selectable: false,
      // });

      // gridGroup.add(rect1);

      for (var i = 0; i < listUnit.length; i++) {
         for (let j = 0; j < listLevel.length; j++) {
            const find_unit = blockdata.find((x) => x.unit === listUnit[i] && x.level === listLevel[j]);

            if (find_unit !== null && find_unit !== undefined) {
               var rect = new fabric.Rect({
                  left: -gridWidth / 2 + 0.5 * cellSizeWidth + i * cellSizeWidth + 5,
                  top: gridHeight / 2 - 1.5 * cellSizeHeight - j * cellSizeHeight - 40,
                  width: cellSizeWidth - 4,
                  height: cellSizeHeight - 4,
                  strokeWidth: 2.5, //find_unit.status == 99 || find_unit.status == 98 ? 2.5 : 1,
                  stroke: find_unit.status == 99 ? "#1f618d" : find_unit.status == 98 ? "#a93226" : "#cccccc",
                  fill: find_unit.bcaInspected == true ? "#82ca2d" : "#cccccc",
                  selectable: false,
                  // i,
                  // data: find_unit,
               });
               gridGroup.add(rect)
               let score = find_unit.score ? find_unit.score : 0
               if (score > 0) {
                  var text = new fabric.Text(String(score), {
                     left: -gridWidth / 2 + 0.5 * cellSizeWidth + i * cellSizeWidth + 15,
                     top: gridHeight / 2 - 1.5 * cellSizeHeight - j * cellSizeHeight - 30,
                     width: cellSizeWidth - 4,
                     height: cellSizeHeight - 4,
                     fill: score < 93 ? "#e70a0a" : "#000000",
                     fontSize: 14,
                     selectable: false,
                     data: find_unit,
                  });
                  gridGroup.add(text)
                  text.on("mousedown", (options) => {
                     console.log('on canvas mousedown', options.target ? options.target.type : '');
                     popUp(find_unit)
                  });
               }
               rect.on("mousedown", (options) => {
                  console.log('on canvas mousedown', options.target ? options.target.type : '');
                  popUp(find_unit)
               });
               
            }
         }
      }

      // Unit
      var text = new fabric.Text(String("Unit->"), {
         left: -(gridWidth / 2) - 20,
         top: gridHeight / 2 - 50,
         fill: "darkblue",
         fontSize: 14,
         selectable: false,
      });
      gridGroup.add(text);

      for (var i = 0; i < listUnit.length; i++) {
         var text = new fabric.Text(String(listUnit[i]), {
            left: -(gridWidth / 2) + 0.825 * cellSizeWidth + i * cellSizeWidth,
            top: gridHeight / 2 - 50,
            fontSize: 14,
            selectable: false,
         });
         gridGroup.add(text);
      }

      // Level
      var text = new fabric.Text(String("Level"), {
         left: -(gridWidth / 2) - 20,
         top: 0,
         angle: -90,
         fill: "darkblue",
         fontSize: 14,
         selectable: false,
      });
      gridGroup.add(text);

      for (var i = 0; i < listLevel.length; i++) {
         var text = new fabric.Text(String(listLevel[i]), {
            left: -(gridWidth / 2) - 0,
            top: +(gridHeight / 2) - 1.9 * cellSizeHeight - i * cellSizeHeight - 12,
            fontSize: 14,
            textAlign: "right",
            selectable: false,
         });
         gridGroup.add(text);
      }

      canvas.renderAll();
   }

   const handleChangeBlock = (e) => {
      canvas.clear();
      setCurrentBlock(e.target.value);
   };
   const closeActionModal = (e) => {
      setShowAction(false)
   }
   const cancelExportExcel = () => {
      setShowDownload(false)
   }
   const exportExcel = () => {
      setShowDownload(true)
   }
   const downloadExcel = (exportType) => {
      Func_Export_Unit_Handed_Over(exportType, data)
      setShowDownload(false)
   }

   return (
      <div id="chartUnitBlock" style={{ marginLeft: "15px", marginRight: "15px" }}>
         <div>
            {listBlocks !== undefined ? (
               <Radio.Group
                  defaultValue={listBlocks[0].name}
                  buttonStyle="solid"
                  onChange={(e) => handleChangeBlock(e)}
               >
                  {listBlocks.map((p) => (
                     <Radio.Button value={p.name} key={p.name}>
                        {`Blk ${p.name.toUpperCase()} (${p.noCompleted}/${p.noTotal}) `}
                     </Radio.Button>
                  ))}
               </Radio.Group>
            ) : null}
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => exportExcel()}
            >
               <Icon type="download" />
            </Button>
         </div>
         <canvas style={{ margin: "0 auto", border: "2px solid" }} id="c" />
         <DialogUnitHandedOver
            show={showAction}
            close={closeActionModal}
            data={apartment}
         />
         <DialogUnitHandedOverDownload
            show={showDownload}
            cancel={cancelExportExcel}
            downloadExcel={downloadExcel}
         />
      </div>
   );
};

export default ChartBlockUnitStatus;
