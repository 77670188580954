import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery'
import { Col, Row, Button, Checkbox, Typography, message, Radio, Divider } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import { Document, Page, pdfjs, Outline } from 'react-pdf/dist/umd/entry.webpack';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { saveAs } from 'file-saver';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import testPng from './test.png'
import _ from 'lodash';
import { fabric } from "fabric";
import { renderView, checkWohhupUser } from '../module_BimApp/function/AdminFunction'
import ToolbarPDFViewer from './ToolbarPDFViewer'
import { setPDFData } from '../../actions/app/pdf'
import * as THREE from 'threejs-full-es6'
import { ProgressBar } from "@blueprintjs/core";
import ContextMenu from './tools/ContextMenu';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const { Text } = Typography
const ButtonGroup = Button.Group;


let isStartDrawing = false
let currentCanvas = null
let fabricObject = null
window.listCanvas = {}
let options = {
  // borderColor: 'red',
  // cornerColor: 'green',
  // cornerSize: 6,
  // transparentCorners: false,
  selectable: false
}
let countPage = 0
let loaded = false
let holdCtrl = false
let scaleLocal =1
let scaleMultiplierW = 1
let scaleMultiplierH = 1
let originalW= 0
let originalH= 0
function PDFViewer(props) {
  const refPdf = useRef();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [percent, setPercent] = useState(0)
  const [positionMenu, setPositionMenu] = useState({ left: 0, top: 0 })
  const [activeObject, setActiveObject] = useState([])
  const [visibleContextMenu, setVisibleContextMenu] = useState(false)
  const onDocumentLoadSuccess = ({ numPages }) => {
    countPage = 0
    loaded = false
    scaleLocal= 1
    window.listCanvas = {}
    setNumPages(numPages);
    setPageNumber(0);
  }
  const onDocumentLoadError = (err) => {
    console.log(err);
  }
  useEffect(() => {
    isStartDrawing = false
    currentCanvas = null
    fabricObject = null
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('keydown', handleKeyDown) 
    }
  }, [])
  useEffect(() => {
    document.addEventListener('contextmenu', handleMenuContext)
    return () => {
      document.removeEventListener('contextmenu', handleMenuContext)
    }
  }, [activeObject])
  useEffect(() => {
    let el = document.getElementById('pdf-viewer')
    el.addEventListener('wheel', handleZoomPdf)
    return () => {
      el.removeEventListener('wheel', handleZoomPdf)
    }
  }, [props.pdfViewer.scale])
  const handleKeyUp = (e) => {
    holdCtrl = false
    if (e.keyCode === 46) {
      _.forEach(window.listCanvas, v => {
        _.forEach(v.getActiveObjects(), i => {
          v.remove(i)
        })
      })
    }
  }
  const handleKeyDown=(e)=>{
    if(e.keyCode === 17){
      holdCtrl = true
    }
  }
  const handleZoomPdf=(e)=>{
    if(holdCtrl){
      e.preventDefault();
      scaleLocal += e.deltaY * -0.01
      scaleLocal = Math.min(Math.max(1, scaleLocal), 3);
      // if (scaleLocal<0){
      //   props.setPDFData({ scale: scale})
      // }else{
      //   props.setPDFData({ scale: props.pdfViewer.scale  >=3? 3: props.pdfViewer.scale + 0.25 })
      // }
      props.setPDFData({ scale: scaleLocal})
    }
  }
  const handleMenuContext = (e) => {
    if (activeObject && activeObject.length !== 0) {
      setPositionMenu({ left: e.clientX, top: e.clientY })
      setVisibleContextMenu(true)
      e.preventDefault();
    }

  }
  useEffect(() => {
    _.forEach(window.listCanvas, v => {
      _.forEach(v.getActiveObjects(), i => {
        if (i.type == 'group') {
          i._objects[0].set('stroke', props.pdfViewer.color)
          i._objects[0].set('strokeWidth', props.pdfViewer.strokeWidth)
          i._objects[1].set('width', props.pdfViewer.strokeWidth * 5)
          i._objects[1].set('height', props.pdfViewer.strokeWidth * 5 + 5)
          i._objects[1].set('fill', props.pdfViewer.color)
        } else {
          i.set('stroke', props.pdfViewer.color)
          i.set('strokeWidth', props.pdfViewer.strokeWidth)
        }
      })
      v.renderAll();
    })
  }, [props.pdfViewer.color, props.pdfViewer.strokeWidth])

  useEffect(() => {
    if (props.pdfViewer.feature !== '') {
      props.setPDFData({ isHand: false, isSelection: false })
      _.forEach(window.listCanvas, v => {
        if (props.pdfViewer.feature === 'freehand') v.isDrawingMode = true;
        else v.isDrawingMode = false;
        _.forEach(v.getObjects(), i => {
          i.selectable = false
        })
        v.renderAll();
      })
    } else {
      props.setPDFData({ isHand: props.pdfViewer.isHand, isSelection: props.pdfViewer.isSelection })
      _.forEach(window.listCanvas, v => {
        v.isDrawingMode = false;
        _.forEach(v.getObjects(), i => {
          i.selectable = true
        })
        v.renderAll();
      })
    }
  }, [props.pdfViewer.feature])
  useEffect(() => {
    _.forEach(window.listCanvas, v => {
      v.selection = props.pdfViewer.isSelection
    })
  }, [props.pdfViewer.isSelection])
  useEffect(() => {
    _.forEach(window.listCanvas, v => {
      v.on('mouse:down', handleClickOn);
      v.on('selection:updated', selectUpdate);
      v.on('selection:created', selectStart);
      v.on('selection:cleared', selectClear);
    })
    return () => {
      _.forEach(window.listCanvas, v => {
        v.off('mouse:down', handleClickOn);
        v.off('selection:updated', selectUpdate);
        v.off('selection:created', selectStart);
        v.off('selection:cleared', selectClear);
      })
    }
  }, [props.pdfViewer.feature, props.pdfViewer.color, props.pdfViewer.strokeWidth,props.pdfViewer.scale])
  // useEffect(() => {
  //   window.listCanvas = {}
  // }, [props.url])
  const selectStart = (e) => {
    setActiveObject(e.selected)
  }
  const selectUpdate = (e) => {
    setActiveObject(e.selected)
  }
  const selectClear = () => {
    setActiveObject([])
    setVisibleContextMenu(false)
  }
  const handleClickOn = (event) => {
    currentCanvas = window.listCanvas[event.e.target.id]
    if (!event.target && !isStartDrawing) {
      let clone = Object.assign({
        top: event.absolutePointer.y,
        left: event.absolutePointer.x,
        selectable: false,
        // scaleX: 1*scaleMultiplierW,
        // scaleY: 1*scaleMultiplierH
      }, options)
      if (props.pdfViewer.feature === 'stamp') {
        fabric.Image.fromURL(props.pdfViewer.image, function (img) {
          img.top = event.absolutePointer.y
          img.left = event.absolutePointer.x
          img.scale(0.5)
          img.borderColor = 'red'
          img.cornerColor = 'green'
          img.cornerSize = 6
          img.transparentCorners = false
          currentCanvas.add(img);
        });
      } else if (props.pdfViewer.feature === 'text') {
        clone.stroke = props.pdfViewer.color
        clone.fontSize = 16
        clone.selectable = true
        var text40 = new fabric.Textbox("Insert Text", clone);
        currentCanvas.add(text40);
      } else if (props.pdfViewer.feature === 'freehand') {
        currentCanvas.isDrawingMode = true;
        currentCanvas.freeDrawingBrush.selectable = false
        currentCanvas.freeDrawingBrush.width = props.pdfViewer.strokeWidth;
        currentCanvas.freeDrawingBrush.color = props.pdfViewer.color;
      } else if (props.pdfViewer.feature === 'rectangular') {
        clone.stroke = props.pdfViewer.color
        clone.width = 5
        clone.height = 5
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.objectCaching = false
        fabricObject = new fabric.Rect(clone);
        currentCanvas.add(fabricObject);
        isStartDrawing = true
        currentCanvas.on("mouse:move", handleDraw)
      } else if (props.pdfViewer.feature === 'circle') {
        clone.stroke = props.pdfViewer.color
        clone.radius = 5
        clone.width = 5
        clone.height = 5
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.objectCaching = false
        fabricObject = new fabric.Circle(clone);
        currentCanvas.add(fabricObject);
        isStartDrawing = true
        currentCanvas.on("mouse:move", handleDraw)
      } else if (props.pdfViewer.feature === 'polyline') {
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.selectable = false
        clone.objectCaching = false
        fabricObject = new fabric.Polyline([
          { x: event.absolutePointer.x, y: event.absolutePointer.y, },
          { x: event.absolutePointer.x + 1, y: event.absolutePointer.y + 1, }
        ], clone);
        currentCanvas.add(fabricObject);
        isStartDrawing = true
        currentCanvas.on("mouse:move", handleDraw)
        currentCanvas.on("mouse:dblclick", handleDrawDbl)
      } else if (props.pdfViewer.feature === 'line' || props.pdfViewer.feature === 'arrow') {
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.selectable = false
        clone.objectCaching = false
        fabricObject = new fabric.Polyline([
          { x: event.absolutePointer.x, y: event.absolutePointer.y, },
          { x: event.absolutePointer.x + 1, y: event.absolutePointer.y + 1, }
        ], clone);
        currentCanvas.add(fabricObject);
        isStartDrawing = true
        currentCanvas.on("mouse:move", handleDraw)
      }
    } else if (fabricObject && isStartDrawing) {
      let clone = Object.assign({}, options)
      if (props.pdfViewer.feature === 'line' || props.pdfViewer.feature === 'arrow') {
        let points = fabricObject.get("points");
        let tempPoint = [points[0], points[1]]
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.originY = "top"
        clone.originX = "left"
        clone.name = 'line'
        clone.selectable = false
        clone.objectCaching = false
        let object = null
        if (props.pdfViewer.feature === 'line') {
          object = new fabric.Polyline(tempPoint, clone);
        } else {
          var angle = Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);
          angle = THREE._Math.radToDeg(angle) + 90
          var triangle = new fabric.Triangle({
            width: props.pdfViewer.strokeWidth * 5,
            height: props.pdfViewer.strokeWidth * 5 + 5,
            fill: props.pdfViewer.color,
            left: points[1].x,
            top: points[1].y,
            angle: angle,
            centeredRotation: true,
            originY: 'center',
            originX: 'center',
            name: 'triangle'
          });
          let clone1 = Object.assign({
            selectable: false,
            name: 'group'
          }, options)
          let line = new fabric.Polyline(tempPoint, clone);
          object = new fabric.Group([line, triangle], clone1);
        }
        currentCanvas.add(object);
        currentCanvas.remove(fabricObject)
        fabricObject = null
        isStartDrawing = false
        currentCanvas.off("mouse:move", handleDraw)
      } else if (props.pdfViewer.feature === 'polyline') {
        let points = fabricObject.get("points");
        points.push({ x: event.absolutePointer.x, y: event.absolutePointer.y });
        fabricObject.set({
          points: points
        });
      } else if (props.pdfViewer.feature === 'rectangular') {
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.left = fabricObject.left
        clone.top = fabricObject.top
        clone.width = fabricObject.width
        clone.height = fabricObject.height
        let object = new fabric.Rect(clone);
        currentCanvas.add(object);
        currentCanvas.remove(fabricObject)
        fabricObject = null
        isStartDrawing = false
        currentCanvas.off("mouse:move", handleDraw)
      } else if (props.pdfViewer.feature === 'circle') {
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.left = fabricObject.left
        clone.top = fabricObject.top
        clone.width = fabricObject.width
        clone.height = fabricObject.height
        // clone.aCoords =fabricObject.aCoords
        clone.radius = fabricObject.radius
        // clone. oCoords= fabricObject.oCoords
        // clone. lineCoords =fabricObject.lineCoords
        let object = new fabric.Circle(clone);
        currentCanvas.add(object);
        currentCanvas.remove(fabricObject)
        fabricObject = null
        isStartDrawing = false
        currentCanvas.off("mouse:move", handleDraw)
      }
      currentCanvas.renderAll();
      currentCanvas.discardActiveObject()
    } else {
      let poly = event.target
      if (poly.type === 'polyline') {
        var lastControl = poly.points.length - 1;
        poly.cornerStyle = 'circle';
        poly.cornerColor = 'rgba(0,0,255,0.5)';
        poly.controls = poly.points.reduce(function (acc, point, index) {
          acc['p' + index] = new fabric.Control({
            positionHandler: polygonPositionHandler,
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index
          });
          return acc;
        }, {})
      }
    }
  }

  const handleDraw = (event) => {
    if (isStartDrawing && fabricObject && currentCanvas) {
      if (props.pdfViewer.feature === 'polyline' || (props.pdfViewer.feature === 'line') || (props.pdfViewer.feature === 'arrow')) {
        let points = fabricObject.get("points");
        points[points.length - 1].x = event.absolutePointer.x
        points[points.length - 1].y = event.absolutePointer.y
        fabricObject.set({
          points: points,
        });
        fabricObject.setCoords();
        currentCanvas.renderAll();
      } else if (props.pdfViewer.feature === 'rectangular') {
        let width = fabricObject.left
        let height = fabricObject.top
        let nWidth = event.absolutePointer.x - width
        let nHeight = event.absolutePointer.y - height
        fabricObject.set({
          width: nWidth,
          height: nHeight,
        });
        currentCanvas.renderAll();
      } else if (props.pdfViewer.feature === 'circle') {
        let left = fabricObject.left
        let top = fabricObject.top
        let nLeft = event.absolutePointer.x
        let nTop = event.absolutePointer.y
        let nWidth = event.absolutePointer.x - left
        let nHeight = event.absolutePointer.y - top
        let radius = Math.sqrt(((nLeft - left) * (nLeft - left) + (nTop - top) * (nTop - top)))
        fabricObject.set({
          radius: radius / 3,
          // width: nWidth,
          // height: nHeight,
        });
        currentCanvas.renderAll();
      }

    }
  }
  const handleDrawDbl = () => {
    if (currentCanvas) {
      currentCanvas.off("mouse:move", handleDraw)
      currentCanvas.off("mouse:dblclick", handleDrawDbl)
      let clone = Object.assign({}, options)
      if (props.pdfViewer.feature === 'polyline') {
        let points = fabricObject.get("points");
        points.pop()
        points.pop()
        clone.stroke = props.pdfViewer.color
        clone.strokeWidth = props.pdfViewer.strokeWidth
        clone.fill = '#ffffff00'
        clone.originY = "top"
        clone.originX = "left"
        clone.selectable = false
        clone.objectCaching = false
        let object = new fabric.Polyline(points, clone);
        currentCanvas.add(object);
      }
      currentCanvas.remove(fabricObject)
      fabricObject = null
      isStartDrawing = false
    }
  }
  useEffect(() => {
    if (window.listCanvas.length !== 0) {
      _.forEach(window.listCanvas, (v, k) => {
        v.setZoom(1);
        v.setHeight(originalH);
        v.setWidth(originalW);
        v.renderAll();
        let split = k.split('-')
        let canvasViewer = document.getElementById(`pdf-viewer-idd-page-${split[split.length - 1]}`)
         scaleMultiplierW = canvasViewer.clientWidth / v.width;
         scaleMultiplierH = canvasViewer.clientHeight / v.height;
        // var objects = v.getObjects();
        // for (var i in objects) {
        //     objects[i].scaleX = objects[i].scaleX * scaleMultiplierW
        //     objects[i].scaleY = objects[i].scaleY *scaleMultiplierH
        //     objects[i].left = objects[i].left * scaleMultiplierW
        //     objects[i].top = objects[i].top * scaleMultiplierH
        //     objects[i].setCoords();
        // }
        v.discardActiveObject();
        v.setHeight(canvasViewer.clientHeight);
        v.setWidth(canvasViewer.clientWidth);
        v.setZoom(scaleMultiplierW);
 
        v.renderAll();
      })
    }
  }, [props.pdfViewer.scale])
  return (
    <>

      <div style={{ height: '100%', width: '100%' }} id='pdf-viewer'>
        <ToolbarPDFViewer
          {...props}

        />

        <div style={{ width: '100%', height: 'calc(100% - 62px)', padding: 10, backgroundColor: '#f1f3f5' }}>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            <Document
              onItemClick={(e, d) => {
                console.log(e, d)
              }}
              file={props.url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              options={{
                standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
                scale: 10
              }}
              ref={refPdf}
              onSourceSuccess={() => {

              }}
              onLoadProgress={(e) => {
                setPercent(e.loaded/e.total)
                console.log(e)
              }}
              loading={
                <ProgressBar value={percent} />
              }
            >
              {Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    onClick={(e, d) => {
                      // let canvas =listCanvas[d._pageIndex]
                      // console.log('d', e, d)
                    }}
                    className={'pdf-viewer-idd'}
                    onRenderSuccess={(ref, d) => {
                      if (!loaded) {
                        if (countPage === numPages - 1) {
                          loaded = true
                          window.listCanvas.sort()
                          props.onLoaded()
                        }
                        countPage++
                        let canvasViewer = document.getElementById(`pdf-viewer-idd-page-${index}`)
                        let canvas = document.createElement('canvas')
                        canvas.id = `pdf-drawing-idd-page-${index}`
                        canvas.className = `pdf-drawing-idd-page`
                        canvasViewer.parentElement.appendChild(canvas)
                        canvas.width = canvasViewer.clientWidth //_.toNumber(canvasViewer.style.width.replace('px',''))
                        canvas.height = canvasViewer.clientHeight // _.toNumber(canvasViewer.style.height.replace('px',''))
                        canvas.style.zIndex = 10
                        // canvas.style.position = 'absolute !important'
                        var fabric1 = new fabric.Canvas(`pdf-drawing-idd-page-${index}`,);
                        fabric1.selection = false
                        let el = fabric1.upperCanvasEl;
                        el.id = `temp-pdf-drawing-idd-page-${index}`
                        window.listCanvas[`temp-pdf-drawing-idd-page-${index}`] = fabric1
                        originalW= canvasViewer.clientWidth
                        originalH= canvasViewer.clientHeight
                      } 
                    }}
                    canvasRef={(ref, d) => {
                      if (ref) {
                        if (!ref.classList.contains('pdf-signature-test'))
                          ref.classList.add('pdf-signature-test')
                        ref.id = `pdf-viewer-idd-page-${index}`
                      }
                    }}
                    scale={props.pdfViewer.scale}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                )
              )}
            </Document>
          </Scrollbars>

        </div>

      </div>
      <ContextMenu
        left={positionMenu.left}
        top={positionMenu.top}
        activeObject={activeObject}
        visible={visibleContextMenu}
      />
    </>

  )

}

PDFViewer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  pdfViewer: PropTypes.object.isRequired,
  setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    pdfViewer: state.pdfViewer
  };
}

export default connect(mapStateToProps, { setPDFData })(PDFViewer)


function actionHandler(eventData, transform, x, y) {
  var polygon = transform.target,
    currentControl = polygon.controls[polygon.__corner],
    mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
    polygonBaseSize = polygon._getNonTransformedDimensions(),
    size = polygon._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
      y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
    };
  polygon.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
    y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
  return fabric.util.transformPoint(
    { x: x, y: y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
}
function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint({
        x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
        y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
      }, fabricObject.calcTransformMatrix()),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = fabricObject._getNonTransformedDimensions(),
      newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
      newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  }
}