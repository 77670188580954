
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, IconButton } from 'rsuite'
import { message, Typography } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import {
  unloadForgeExtension, setupForgeExtensionBeforeLoadedIssuePage, getAllModelsElementdbIdsWithCondition
} from '../../../components/module_BimApp/function/ForgeFunction'
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction'
import { Intent, Position, Toaster, } from "@blueprintjs/core";
import {DialogIssueInformation} from '../../module_BimApp/dialog'
import IssueViewer from './IssueViewer'
import base64url from 'base64url'
import {Markup2D} from '../../module_BimApp/dock_forge'
const { Text } = Typography

const Autodesk = window.Autodesk;
let mainLoadOptions = {}
let version;
let elementColor = null
let viewPort = null
let count = 0
function IssuePage(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)

  const [displayInfoViewpoint, setDisplayInfoViewpoint] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)
  const [objectId, setObjectId] = useState(null)
  const [modelLoaded, setModelLoaded] = useState(false)
  const [itemId, setItemId] = useState('')
  const toaster = useRef()

  useEffect(() => {
    axios.post(`/api/issue/get-model`, { item: props.issue.mainModel, isLastVersion: props.check })
      .then(res => {
        if (res.data.status === 'success') {
          mainLoadOptions = {
            applyRefPoint: true,
            modelNameOverride: res.data.name,
            isAEC: true,
            guid: res.data.guid,
            viewableID: res.data.viewableID,
            itemId: res.data.itemId,
            version: res.data.version
          };
          version = res.data.version
          setItemId(res.data.itemId)
          if (props.issue.mainModel.version !== res.data.version) {
            let path = base64url(`${props.projectId}/${props.userCreatedProject}/${props.issue.id}/${false}`)
            toaster.current.show({
              timeout: 0,
              action: {
                href: `${window.location.origin}/issue/${path}`,
                text: <strong>Reload</strong>,
              },
              icon: 'warning-sign',
              intent: Intent.WARNING,
              message: (
                <Text strong style={{ color: 'white' }}>
                  {`Issue created at version ${props.issue.mainModel.version}. Are you want to load original version?`}
                </Text>
              )
            })
          }
          if (props.check) {
            setObjectId(res.data.objectId)
          } else {
            setObjectId(props.issue.mainModel.urn)
          }
        }
        else {
          message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
        }
      })
      .catch(err => {
        message.error(`Can't load model.`);
      })
  }, [])


  const handleCloseInfoViewPointPanel = () => {
    setDisplayInfoViewpoint(false)
  }
  const restoreSaveView = (check, e) => {
    try {
      let section = viewer.getExtension('Autodesk.Section')
      section.activate()
      section.deactivate();
    } catch { }
    let data = e.props.item
    if (data.mainModel.version !== version) {
      toaster.current.show({
        timeout: 0,
        icon: 'warning-sign',
        intent: Intent.WARNING,
        message: (
          <Text strong style={{ color: 'white' }}>
            {`Issue created at version ${data.mainModel.version}`}
          </Text>
        )
      })
    }
    let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let node = viewer.impl.model.getDocumentNode()
    let loadOptions = {
      globalOffset: globalOffset,
      applyRefPoint: true,
      modelNameOverride: data.mainModel.name,
      isAEC: true,
      guid: data.mainModel.guid,
      viewableID: data.mainModel.viewableID,
      itemId: data.mainModel.itemId,
      version: data.mainModel.version,
      acmSessionId: document.acmSessionId
    }
    if (data.guid === node.data.guid) {
      handleOtherModel(data, check)
      return false
    } else {
      let viewables = document.getRoot().search({ 'type': 'geometry', 'guid': data.guid }, true)
      if (viewables.length !== 0) {
        viewer.loadDocumentNode(document, viewables[0], loadOptions)
          .then(i => {
            unloadForgeExtension(viewer, false)
            handleOtherModel(data, check)
          })
          .catch(r => {
            message.warning(`Load model failed`)
          })
      } else {
        message.error(`View isn't existing in file`)
      }
      return false
    }
  }
  const handleOtherModel = (data, check) => {
    viewPort = data.viewPort
    elementColor = data.attachElementColor
    count = data.otherModel.length
    if (data.otherModel.length > 0) {
      const models = viewer.impl.modelQueue().getModels()
      let tempModeled = []
      _.forEach(models, v => {
        if (v.myData.urn !== viewer.impl.model.myData.urn) {
          let index = _.findIndex(data.otherModel, e => { return e.itemId === v.myData.loadOptions.itemId && e.version === v.myData.loadOptions.version && e.guid === v.myData.loadOptions.guid })
          if (index < 0)
            viewer.unloadModel(v)
          else
            tempModeled.push(index)
        }
      })
      _.forEach(data.otherModel, (v, k) => {
        if (tempModeled.includes(k)) {
          count--
          if (count === 0) {
            handleSetColorElement(data.attachElementColor)
          }
          return
        }
        axios.post(`/api/issue/get-model`, { item: v, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                viewableID: res.data.viewableID,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              var documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), () => { });
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(err => {
            count--
            message.error(`Can't load model.`);
          })
      })
    } else {
      handleSetColorElement(data.attachElementColor)
    }
  }
  const _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      viewer.loadModel(svfUrl, loadOptions, _onLoadModelSuccess, () => { })
    }
  }
  const _onLoadModelSuccess = () => {
    if (count === 0) {
      handleSetColorElement(elementColor)
    }
  }
  const handleSetColorElement = (elements) => {
    let models = viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      viewer.clearThemingColors(model)
    })
    let isAllLoaded = viewer.isLoadDone({ onlyModels: models })
    if (isAllLoaded) {
      setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
      let countModel = models.length
      let tempElement = {}
      _.forEach(models, async model => {
        let instanceTree = model.getData().instanceTree;
        let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
        countModel--
        let count1 = temp.length
        _.forEach(temp, id => {
          model.getProperties(id, (modelAProperty) => {
            tempElement[modelAProperty.externalId] = id
            count1--
            if (count1 === 0) {
              _.forEach(elements, async el => {
                let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                if (index >= 0) {
                  if (tempElement[el.guid]) {
                    let color = convertHexColorToVector4(el.color)
                    viewer.setThemingColor(tempElement[el.guid], color, models[index])
                  }
                }
              })
              setModelLoaded(false)
              viewer.restoreState(viewPort)
              setDisplayInfoViewpoint(true)
            }
          })
        })
      })
    } else {
      viewer.waitForLoadDone({ onlyModels: models })
        .then(res => {
          setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
          let countModel = models.length
          let tempElement = {}
          _.forEach(models, async model => {
            let instanceTree = model.getData().instanceTree;
            let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
            countModel--
            let count1 = temp.length
            _.forEach(temp, id => {
              model.getProperties(id, (modelAProperty) => {
                tempElement[modelAProperty.externalId] = id
                count1--
                if (count1 === 0) {
                  _.forEach(elements, async el => {
                    let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                    if (index >= 0) {
                      if (tempElement[el.guid]) {
                        let color = convertHexColorToVector4(el.color)
                        viewer.setThemingColor(tempElement[el.guid], color, models[index])
                      }
                    }
                  })
                  setModelLoaded(false)
                  viewer.restoreState(viewPort)
                  setDisplayInfoViewpoint(true)
                }
              })
            })
          })
        })
    }
  }

  const handleCloseViewer = () => {
    props.close()
  }
  const handleOpenDialogIssueInformation = (viewer, globalOffset) => {
    setViewer(viewer)
    setGlobalOffset(globalOffset)
    setDisplayInfoViewpoint(true)
  }
  const handleOpenDialogIssueInformationBtn = () => {
    setDisplayInfoViewpoint(true)
  }
  const handleChangeMarkup2DPanel = () => {
    setMarkUp2d(!markUp2d)
  }
  return (
    <>
      <IconButton style={{ zIndex: '11', float: "right", backgroundColor: "red", top: '0', color: 'white', borderRadius: 0 }} size='sm' onClick={handleCloseViewer}
        icon={<Icon icon="close" />} ></IconButton>
      <IconButton style={{ zIndex: '11', float: "left", top: '0', color: 'white', borderRadius: 0 }} size='sm' onClick={handleOpenDialogIssueInformationBtn} color='blue'
        icon={<Icon icon='detail' />} ></IconButton>
      <div style={{ position: 'absolute', zIndex: 2, width: '100%', height: 'calc(100% - 53.78px)', backgroundColor: 'lightgray' }} >
        {objectId && <IssueViewer {...props}
          handleOpenDialogIssueInformation={handleOpenDialogIssueInformation}
          objectId={objectId}
          mainLoadOptions={mainLoadOptions}
          handleChangeMarkup2DPanel={handleChangeMarkup2DPanel}
          markUp2d={markUp2d}
        />}
      </div>

      {displayInfoViewpoint &&
        <DialogIssueInformation
          listSaveView={props.listSaveView}
          open={displayInfoViewpoint}
          handleClose={handleCloseInfoViewPointPanel}
          viewer={viewer}
          viewPoint={props.issue}
          email={props.email}
          projectId={props.projectId}
          userCreatedProject={props.userCreatedProject}
          isProject={false}
          newTab={false}
          itemId={itemId}
          restoreSaveView={restoreSaveView}
          tagsOption={props.tagsOption}
        />
      }
      {markUp2d && <Markup2D openDock={markUp2d} viewer={viewer}
        onChangeDockDisplay={handleChangeMarkup2DPanel} />}
      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />
    </>

  )
}

IssuePage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(IssuePage)