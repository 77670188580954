import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Select, List, Typography } from 'antd';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import axios from '../../module_Worker/api/axiosClient';

async function apiGetDataTable(payload) {
  return await axios.get('/api/dataentry/get-sheet5d-data-for-archi', {
    params: payload,
  });
}

const gridStyle = {
  height: 'calc(100vh - 55px)',
  width: '100%',
  overflowX: 'hidden',
};
function ArchiSummary(props) {
  const [datas, setDatas] = useState([]);

  const [columnDefs] = useState([
    { field: 'zone', headerName: 'Zone', width: 100 },
    // { field: 'subZone', headerName: 'Sub Zone' },
    // { field: 'level', headerName: 'Level' },
    { field: 'breakdown1', headerName: 'Breakdown 1', width: 120 },
    { field: 'breakdown2', headerName: 'Breakdown 2', width: 120 },
    { field: 'breakdown3', headerName: 'Breakdown 3', width: 120 },
    { field: 'bctItems', headerName: 'BCT Items', width: 120 },
    { field: 'code', headerName: 'New 5d Code', width: 100 },
    { field: 'length', headerName: 'Length (m)', width: 100 },
    { field: 'area', headerName: 'Area (m2)', width: 100 },
    { field: 'volume', headerName: 'Volume (m3)', width: 100 },
    { field: 'count', headerName: 'Nos', width: 100 },
    { field: 'unit', headerName: 'Unit', width: 100 },
    { field: 'progress', headerName: 'Progress (%)', width: 100 },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: false,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  useEffect(() => {
    console.log('start');
    getData();
  }, []);

  async function getData() {
    console.log('props', props);
    let res = await apiGetDataTable({
      projectId: props.location.state.projectId,
    });

    let datas = res;
    let groupByZone = _.groupBy(datas, 'Zone');
    let tableData = [];

    Object.keys(groupByZone).forEach((key) => {
      let items = groupByZone[key];

      let groupByCode = _.groupBy(items, 'CODE');

      Object.keys(groupByCode).forEach((codeKey) => {
        let itemsByCode = groupByCode[codeKey];
        let first = itemsByCode[0];

        let keyQuantity = first.units;
        if (keyQuantity == 'Nos') {
          keyQuantity = 'Count';
        }

        let sumArea = sum(itemsByCode.map((x) => x.Area));
        let sumLength = sum(itemsByCode.map((x) => x.Length));
        let sumVolume = sum(itemsByCode.map((x) => x.Volume));
        let sumNos = sum(itemsByCode.map((x) => x.Count));

        let doneItems = itemsByCode.filter((x) => x.Status == 'Completed');
        let quantityDones = sum(doneItems.map((x) => x[keyQuantity]));

        let allQuantity = sum(itemsByCode.map((x) => x[keyQuantity]));

        let progress = (quantityDones * 100) / allQuantity;

        let item = {
          zone: key,
          code: codeKey,
          breakdown3: first.breakdown3,
          breakdown2: first.breakdown2,
          breakdown1: first.breakdown1,
          unit: first.units,
          length: sumLength,
          area: sumArea,
          volume: sumVolume,
          count: sumNos,
          progress,
        };

        tableData.push(item);
      });
    });

    setDatas(tableData);

    console.log('tableData', tableData);
  }

  function sum(list) {
    if (Array.isArray(list)) {
      return _.sum(list.filter((x) => _.isNumber(x)));
    }

    return 0;
  }

  return (
    <div className='ag-theme-alpine' style={gridStyle}>
      <AgGridReact
        defaultColDef={defaultColDef}
        rowData={datas}
        columnDefs={columnDefs}
        headerHeight={25}
        rowHeight={25}
      ></AgGridReact>
    </div>
  );
}

export default ArchiSummary;
