import React from 'react';
import { Image, Radio, Divider, Grid, Segment } from 'semantic-ui-react'
import { message, Typography } from 'antd';
import { Modal, Loader } from 'rsuite';
import * as rsuite from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import {
  Button, DialogContentText,
  IconButton, Tooltip, FormControl, Input, InputLabel, InputAdornment, Checkbox, TextField
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import {checkWohhupUser} from '../../function/AdminFunction'

const { Text } = Typography
class DialogShareItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: '',
      isShare: false,
      imgQrCode: null,
      havePassword: false,
      showPassword: false,
      password: '',
      shareOptions: {
        CIS: false,
        PPVC: false,
        None: false
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.onShow) {
      this.setState({ loading: true })
      axios.post(`/api/items/generateqrcode`, { itemId: nextProps.itemId, objectId: nextProps.objectIdDefault, hostName: window.location.origin })
        .then(res => (
          this.setState({
            url: res.data.url,
            imgQrCode: res.data.image,
            isShare: res.data.item.isShare,
            havePassword: res.data.havePassword,
            password: res.data.item.passwordHash,
            loading: false,
            shareOptions: res.data.shareOptions
          })
        ))
        .catch(err => {
          this.setState({ loading: false })
        })
    }
  }
  handleClosePanel = () => {
    this.props.onClose()
  }

  setPasswordItem = (event, data) => {
    this.setState({ havePassword: data.checked })
  }
  addPasswordShareView = (event) => {

    if (this.state.password.length === 0 && this.state.havePassword) {
      message.warning('Password must have at least 1 characters')
    } else {
      this.setState({ loading: true })

      axios.post(`/api/items/addpasswordshareview`, { password: this.state.password, itemId: this.props.itemId, isAddPassword: this.state.havePassword })
        .then(res => {
          this.setState({ password: res.data.passwordHash, loading: false })
          message.success(`${this.props.itemName} set password successful.`);
        })
        .catch(err => {
          this.setState({ loading: false })
          message.error(`${this.props.itemName}  set password failed.`);
        })
    }


  }
  setShareView = (event, value) => {
    this.setState({ loading: true })
    let data = {
      isShare: value, itemId: this.props.itemId, itemName: this.props.itemName, shareOptions: this.state.shareOptions
    }
    this.props.handleSetShareView(data)
  }
  handleChange = prop => event => {
    this.setState({ password: event.target.value })
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChangeOptionShare = (name, e, check) => {
    this.setState(prevState => {
      let shareOptions = Object.assign({}, prevState.shareOptions);
      _.forEach(shareOptions, (v, k) => {
        if (name === k)
          shareOptions[k] = check;
        else
          shareOptions[k] = !check;
      })
      return { shareOptions };
    }, () => {
      this.setState({ loading: true })
      let data = {
        isShare: this.state.isShare, itemId: this.props.itemId, itemName: this.props.itemName, shareOptions: this.state.shareOptions
      }
      this.props.handleSetShareView(data)
    })

  }
  render() {

    return (
      <div>
        <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} size='sm' style={{ top: '5%' }}>
          <Modal.Header>
            <Tooltip title={'Share File'} placement='bottom'>
              <Checkbox onChange={this.setShareView} //disabled={(!this.props.isAdmin && !this.props.roleUploader)}
                              // disabled={checkWohhupUser(this.props.email) ? false : (this.props.company === 'Woh Hup Private Ltd' || this.props.role === 'Outsource' ) ? false : true}
                icon={<ShareTwoToneIcon style={{ fontSize: 25 }} />}
                checkedIcon={<ShareTwoToneIcon style={{ fontSize: 25, color: 'blue' }} />} checked={this.state.isShare} />
            </Tooltip>
            <Modal.Title style={{ display: 'inline', verticalAlign: 'middle' }}>  Share </Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyShare'>
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <TextField style={{ width: '100%' }}
              label="Link" value={this.state.url} defaultValue="Link"
              margin="dense" variant="outlined"
            />
            {/* <TextField style={{ width: '100%' }}
              label="Urn" value={this.props.objectIdDefault} defaultValue="Urn"
              margin="dense" variant="outlined"
            /> */}
            <Segment placeholder>
              <Grid columns={2} stackable>
                <Grid.Column>
                  <DialogContentText >
                    <CopyToClipboard text={this.state.url}>
                      <Button style={{ left: '30%' }}>Copy link</Button>
                    </CopyToClipboard>
                  </DialogContentText>
                  <Image src={this.state.imgQrCode} size='small' centered />
                  <br></br>
                  <Button style={{ left: '25%' }} href={this.state.imgQrCode} download > Dowload Image</Button>
                </Grid.Column>
                <Grid.Column verticalAlign='middle' >
                  <Radio toggle label=' Set Password' checked={this.state.havePassword} disabled={(!this.props.isAdmin && !this.props.roleUploader)}
                    onChange={this.setPasswordItem} ></Radio>
                  <FormControl disabled={(!this.props.isAdmin && !this.props.roleUploader)}>
                    <InputLabel htmlFor="adornment-password">Password</InputLabel>
                    <Input
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange('password')}
                      disabled={!this.state.havePassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <br />
                  <Button style={{ float: 'right' }} onClick={this.addPasswordShareView} color="primary" disabled={(!this.props.isAdmin && !this.props.roleUploader)}>
                    {!this.state.havePassword ? "Remove Password" : "Add Password"}
                  </Button>
                  {!this.props.isTrimble &&
                    <>
                      <br />   <br />   <br />
                      <Text strong> Share Options: </Text>
                      <rsuite.Checkbox checked={this.state.shareOptions.None} disabled={(!this.props.isAdmin && !this.props.roleUploader)}
                        onChange={this.handleChangeOptionShare.bind(this, 'None')}>None</rsuite.Checkbox>
                      <rsuite.Checkbox checked={this.state.shareOptions.CIS} disabled={(!this.props.isAdmin && !this.props.roleUploader)}
                        onChange={this.handleChangeOptionShare.bind(this, 'CIS')}>CIS</rsuite.Checkbox>
                      <rsuite.Checkbox checked={this.state.shareOptions.PPVC} disabled={(!this.props.isAdmin && !this.props.roleUploader)}
                        onChange={this.handleChangeOptionShare.bind(this, 'PPVC')}>PPVC</rsuite.Checkbox>
                    </>}

                </Grid.Column>
              </Grid>

              <Divider vertical>And</Divider>
            </Segment>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>



      </div>

    );
  }
}


export default DialogShareItem