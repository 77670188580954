import moment from 'moment'
import _ from 'lodash'

export const handleModulePPVCAndPrecast = (moduleData, typeProject, projectKey) => {
  let actionMinDate = null;
  let actionMaxDate = null;
  let dataPPVC = []
  let tempData = []
  let currentData = {
    castingCompletedData: [],
    deliveredToFitouYardData: [],
    completedFitoutData: [],
    devliveredToSiteData: [],
    installOnSiteData: [],

    castingCompletedDataTargetCount: 0,
    deliveredToFitouYardDataTargetCount: 0,
    completedFitoutTargetCount: 0,
    devliveredToSiteDataTargetCount: 0,
    installOnSiteDataTargetCount: 0
  };
  // console.log(moduleData)
  _.forEach(moduleData, item => {
    let check = true
    if (item.actions && item.actions.length > 0) {
      if (item.code === 'PDD-T4-L2-C1-4PW1-01') {
        console.log(item)
      }
      let tempItem = null
      let temp = {
        dbId: item.dbId,
        mark: item.code,
        modifiedDate: null,
        status: [],
        listTime: [],
        infoData: {
          name: item.code,
          siteName: item.siteName,
          length: item.length ? item.length : "N/A",
          height: item.height ? item.height : "N/A",
          width: item.width ? item.width : "N/A",
          volume: item.volume ? item.volume : "N/A",
          teklaVolume: item.teklaVolume ? item.teklaVolume : "N/A",
          rebarWeightKg: item.rebarWeightKg ? item.rebarWeightKg : "N/A",
          carcassWeight: item.carcassWeight ? item.carcassWeight : "N/A",
          ppvcWeight: item.ppvcWeight ? item.ppvcWeight : "N/A",
          status: '',
          castingDate: '',
          deliveryDate: '',
          installDate: ''
        }
      }
      let checkDeliveryFitOut = true
      let currentSiteCode = ''
      _.forEach(item.actions, action => {
        if (action.completedDate) {
          let completedDate = new Date(action.completedDate)
          //#region //? get time min max
          if (actionMinDate) {
            if (actionMinDate > completedDate) {
              actionMinDate = completedDate;
            }
          } else {
            actionMinDate = completedDate;
          }
          if (actionMaxDate) {
            if (actionMaxDate < completedDate) {
              actionMaxDate = completedDate;
            }
          } else {
            actionMaxDate = completedDate;
          }
          //#endregion
          if (typeProject === 'PPVC/PBU') {
            if (action.actionName.includes('Start Cast at')) {
              currentSiteCode = action.currentSiteCode
            }
            if (action.actionName === 'Casting Completed') {
        
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
                currentData.castingCompletedData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Casting Completed'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.castingDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Casting Completed')
                check = false
                tempItem = {
                  content: item.code, status: 'Casting Completed',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
          
            }
            else if (action.actionName.includes('Received Delivery at') && checkDeliveryFitOut && currentSiteCode !== action.currentSiteCode) {//&&projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()
       
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Fit-out Yard' })
                currentData.deliveredToFitouYardData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Delivered to Fit-out Yard'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Delivered to Fit-out Yard')
                check = false
                checkDeliveryFitOut = false
                tempItem = {
                  content: item.code, status: 'Delivered to Fit-out Yard',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
           
            }
            else if (action.actionName.includes('Ready for Delivery from') && currentSiteCode !== action.currentSiteCode) {
      
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Fit Out Completed' })
                currentData.completedFitoutData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Fit Out Completed'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Fit Out Completed')
                check = false
                tempItem = {
                  content: item.code, status: 'Fit Out Completed',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
            
            }
            else if (action.actionName.includes('Received Delivery at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
        
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
                currentData.devliveredToSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Delivered to Site'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.deliveryDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Delivered to Site')
                check = false
                tempItem = {
                  content: item.code, status: 'Delivered to Site',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
      
            }
            else if (action.actionName.includes('Module Installed on')) {
           
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
                currentData.installOnSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Installed on Site'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Installed on Site')
                check = false
                tempItem = {
                  content: item.code, status: 'Installed on Site',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
          
            }
          } else if (typeProject === 'PreCast') {
            if ((action.actionName.includes('Delivering to') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Move into') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Ready for Delivery') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Received Delivery') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase())
              || action.actionName === 'Casting Completed') {
       
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
                currentData.castingCompletedData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Casting Completed'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.castingDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Casting Completed')
                check = false
                tempItem = {
                  content: item.code, status: 'Casting Completed',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
         
            }
            else if ((action.actionName.includes('Received Delivery at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())
              || (action.actionName.includes('Move into at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())) {
       
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
                currentData.devliveredToSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Delivered to Site'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.deliveryDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Delivered to Site')
                check = false
                tempItem = {
                  content: item.code, status: 'Delivered to Site',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }

            }
            else if (action.actionName.includes('Module Installed on') || action.actionName ==='Completed') {
         
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
                currentData.installOnSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Installed on Site'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.installDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Installed on Site')
                check = false
                tempItem = {
                  content: item.code, status: 'Installed on Site',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }

            }
          }

        }
      })
      if (temp.modifiedDate !== null) {
        if (_.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' }) >= 0) {
          let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
          if (index < 0) {
            currentData.devliveredToSiteData.push({
              dbId: item.dbId,
              mark: item.code,
              completedDate: temp.modifiedDate,
            })
            temp.status.splice(temp.listTime[temp.listTime.length - 2], 0, 'Delivered to Site')
            temp.listTime.splice(temp.listTime[temp.listTime.length - 2], 0, { time: temp.listTime[temp.listTime.length - 1].time, status: 'Delivered to Site' })
            tempItem = {
              content: item.code, status: 'Delivered to Site',
              startPlan: '', endPlan: '',
              startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
            }
          }
        }
        if (typeProject === 'PPVC/PBU') {
          if (_.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
            if (index < 0) {
              currentData.completedFitoutData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: temp.modifiedDate,
              })
              temp.status.splice(temp.listTime[temp.listTime.length - 3], 0, 'Fit Out Completed')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 3], 0, { time: temp.listTime[temp.listTime.length - 2].time, status: 'Fit Out Completed' })
              tempItem = {
                content: item.code, status: 'Fit Out Completed',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
              }
            }
          }

          if (_.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
            if (index < 0) {
              currentData.deliveredToFitouYardData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: temp.modifiedDate,
              })
              temp.status.splice(temp.listTime[temp.listTime.length - 4], 0, 'Delivered to Fit-out Yard')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 4], 0, { time: temp.listTime[temp.listTime.length - 3].time, status: 'Delivered to Fit-out Yard' })
              tempItem = {
                content: item.code, status: 'Delivered to Fit-out Yard',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
              }
            }
          }
        }
        dataPPVC.push(temp)
        if (tempItem) {
          tempData.push(tempItem)
        }
      }
    }
    if (check) {
      dataPPVC.push({
        dbId: item.dbId,
        mark: item.code,
        modifiedDate: null,
        status: [""],
        infoData: {
          name: item.code,
          length: item.length ? item.length : "N/A",
          height: item.height ? item.height : "N/A",
          width: item.width ? item.width : "N/A",
          volume: item.volume ? item.volume : "N/A",
          carcassWeight: item.carcassWeight ? item.carcassWeight : "N/A",
          ppvcWeight: item.ppvcWeight ? item.ppvcWeight : "N/A",
          status: '',
          castingDate: '',
          deliveryDate: ''
        }
      })
    }
  })
  return ({ dataPPVC, actionMinDate, actionMaxDate, tempData })
}

export function timelineChangeProgress(timelinepanel, items, e, _this, startName, endName, timeline, check = 'progressChart') {
  if (timeline !== null)
    var time = timelinepanel.getCustomTime(timeline)
  let date = moment(e.time)
  let dateMin = moment(e.time).startOf("days")
  let dateMax = moment(e.time).endOf("days")
  let dateLimitMin = moment(_this.state.actionMinDate).startOf('month')
  let dateLimitMax = moment(_this.state.actionMaxDate).endOf('month')
  let dateMinCurrent = moment(timelinepanel.getCustomTime(startName))
  let dateMaxCurrent = moment(timelinepanel.getCustomTime(endName))
  if (e.id === startName) {
    if (dateMin >= dateLimitMax.startOf('day')) {
      timelinepanel.setCustomTime(dateLimitMax.startOf('day'), startName)
      if (timeline !== null)
        timelinepanel.setCustomTime(dateLimitMax.startOf('day'), timeline)
      let item = items.get(1)
      item.end = dateLimitMax.startOf('day')
      items.update(item)
      _this.setState({ timeValue: { min: dateLimitMax.startOf('day'), max: _this.state.timeValue.max }, currentTime: dateLimitMax.startOf('day') }, () => { excuteFuncton(check, _this) })
    } else if (dateMin > dateLimitMin.startOf('day')) {
      if (dateMin >= dateMaxCurrent.startOf('day')) {
        timelinepanel.setCustomTime(dateMaxCurrent.startOf('day'), startName)
        if (timeline !== null)
          timelinepanel.setCustomTime(dateMaxCurrent.startOf('day'), timeline)
        let item = items.get(1)
        item.end = dateMaxCurrent.startOf('day')
        items.update(item)
        _this.setState({ timeValue: { min: dateMaxCurrent.startOf('day'), max: _this.state.timeValue.max }, currentTime: dateMaxCurrent.startOf('day') }, () => { excuteFuncton(check, _this) })
      } else {
        timelinepanel.setCustomTime(dateMin, startName)
        if (timeline !== null && time <= dateMinCurrent.startOf('day'))
          timelinepanel.setCustomTime(dateMin, timeline)
        let item = items.get(1)
        item.end = dateMin
        items.update(item)
        _this.setState({
          timeValue: { min: dateMin, max: _this.state.timeValue.max },
          currentTime: (timeline !== null && time <= dateMinCurrent.startOf('day')) ? dateMin : time
        }, () => { excuteFuncton(check, _this) })
      }
    } else if (dateMin <= dateLimitMin.startOf('day')) {
      timelinepanel.setCustomTime(dateLimitMin.startOf('day'), startName)
      let item = items.get(1)
      item.end = dateLimitMin.startOf('day')
      items.update(item)
      _this.setState({ timeValue: { min: dateLimitMin.startOf('day'), max: _this.state.timeValue.max } }, () => { excuteFuncton(check, _this) })
    }
  } else if (e.id === endName) {
    if (dateMax <= dateLimitMin.endOf("days")) {
      timelinepanel.setCustomTime(dateLimitMin.endOf("days"), endName)
      if (timeline !== null)
        timelinepanel.setCustomTime(dateLimitMin.endOf("days"), timeline)
      let item = items.get(2)
      item.start = dateLimitMin.endOf("days")
      items.update(item)
      _this.setState({ timeValue: { min: _this.state.timeValue.min, max: dateLimitMin.endOf("days") }, currentTime: dateLimitMin.endOf("days") }, () => { excuteFuncton(check, _this) })
    } else if (dateMax < dateLimitMax.endOf("days")) {
      if (dateMax <= dateMinCurrent.endOf('day')) {
        timelinepanel.setCustomTime(dateMinCurrent.endOf('day'), endName)
        if (timeline !== null)
          timelinepanel.setCustomTime(dateMinCurrent.endOf('day'), timeline)
        let item = items.get(2)
        item.start = dateMinCurrent.endOf('day')
        items.update(item)
        _this.setState({ timeValue: { min: _this.state.timeValue.min, max: dateMinCurrent.endOf('day') }, currentTime: dateMinCurrent.endOf('day') }, () => { excuteFuncton(check, _this) })
      } else {
        timelinepanel.setCustomTime(dateMax, endName)
        if (timeline !== null && time >= dateMaxCurrent.endOf('day'))
          timelinepanel.setCustomTime(dateMax, timeline)
        let item = items.get(2)
        item.start = dateMax
        items.update(item)
        _this.setState({
          timeValue: { min: _this.state.timeValue.min, max: dateMax },
          currentTime: (timeline !== null && time >= dateMaxCurrent.endOf('day')) ? dateMax : time
        }, () => { excuteFuncton(check, _this) })
      }
    } else if (dateMax >= dateLimitMax.endOf("days")) {
      timelinepanel.setCustomTime(dateLimitMax.endOf("days"), endName)
      let item = items.get(2)
      item.start = dateLimitMax.endOf('day')
      items.update(item)
      _this.setState({ timeValue: { min: _this.state.timeValue.min, max: dateLimitMax.endOf('day') } }, () => { excuteFuncton(check, _this) })
    }
  } else if (e.id === timeline) {
    if (date >= dateMaxCurrent) {
      timelinepanel.setCustomTime(dateMaxCurrent, timeline)
      _this.setState({ currentTime: dateMaxCurrent }, () => { excuteFuncton(check, _this) })
    } else if (date <= dateMinCurrent) {
      timelinepanel.setCustomTime(dateMinCurrent, timeline)
      _this.setState({ currentTime: dateMinCurrent }, () => { excuteFuncton(check, _this) })
    } else {
      timelinepanel.setCustomTime(date, timeline)
      _this.setState({ currentTime: date }, () => { excuteFuncton(check, _this) })
    }
  }
}

function excuteFuncton(check, _this) {
  if (check === 'summaryPPVC' || check === 'archiSummary')
    _this.generateData()
  else if (check === 'progressChart')
    _this.showElementByDateRange()
}

export function setDateByPanel(timelinepanel, items, name, date, _this, startName, endName, check = 'progressChart') {
  if (name === 'min') {
    let dateLimitMin = moment(date).startOf('day')
    timelinepanel.setCustomTime(dateLimitMin, startName)
    let item = items.get(1)
    item.end = dateLimitMin
    items.update(item)
    _this.setState({
      timeValue: { min: dateLimitMin, max: _this.state.timeValue.max }
    }, () => {
      if (check === 'summaryPPVC' || check === 'archiSummary')
        _this.generateData()
      else if (check === 'progressChart')
        _this.showElementByDateRange()
    })
  }
  else {
    let dateLimitMax = moment(date).endOf('day')
    timelinepanel.setCustomTime(dateLimitMax, endName)
    let item = items.get(2)
    item.start = dateLimitMax
    items.update(item)
    _this.setState({
      timeValue: { min: _this.state.timeValue.min, max: dateLimitMax }
    }, () => {
      if (check === 'summaryPPVC' || check === 'archiSummary')
        _this.generateData()
      else
        _this.showElementByDateRange()
    })
  }

}

export function setDateStartEndByPanel(timelinepanel, items, start, end, _this, startName, endName) {
  let dateLimitMin = moment(start).startOf('day')
  timelinepanel.setCustomTime(dateLimitMin, startName)
  var item = items.get(1)
  item.end = dateLimitMin
  items.update(item)

  let dateLimitMax = moment(end).endOf('day')
  timelinepanel.setCustomTime(dateLimitMax, endName)
  var item = items.get(2)
  item.start = dateLimitMax
  items.update(item)
  _this.setState({
    timeValue: { min: dateLimitMin, max: dateLimitMax }
  }, () => {
    _this.generateData()
  })

}


export const handleModulePPVCAndPrecastBackup = (moduleData, typeProject, projectKey) => {
  let actionMinDate = null;
  let actionMaxDate = null;
  let dataPPVC = []
  let tempData = []
  let currentData = {
    castingCompletedData: [],
    deliveredToFitouYardData: [],
    completedFitoutData: [],
    devliveredToSiteData: [],
    installOnSiteData: [],

    castingCompletedDataTargetCount: 0,
    deliveredToFitouYardDataTargetCount: 0,
    completedFitoutTargetCount: 0,
    devliveredToSiteDataTargetCount: 0,
    installOnSiteDataTargetCount: 0
  };
  _.forEach(moduleData, item => {
    let check = true
    if (item.actions && item.actions.length > 0) {
      if (item.code === 'PDD-T4-L2-C1-4PW1-01') {
        console.log(item)
      }
      let tempItem = null
      let temp = {
        dbId: item.dbId,
        mark: item.code,
        modifiedDate: null,
        status: [],
        listTime: [],
        infoData: {
          name: item.code,
          length: item.length ? item.length : "N/A",
          height: item.height ? item.height : "N/A",
          width: item.width ? item.width : "N/A",
          volume: item.volume ? item.volume : "N/A",
          rebarWeightKg: item.rebarWeightKg ? item.rebarWeightKg : "N/A",
          carcassWeight: item.carcassWeight ? item.carcassWeight : "N/A",
          ppvcWeight: item.ppvcWeight ? item.ppvcWeight : "N/A",
          status: '',
          castingDate: '',
          deliveryDate: ''
        }
      }
      let checkDeliveryFitOut = true
      let currentSiteCode = ''
      _.forEach(item.actions, action => {
        if (action.completedDate) {
          let completedDate = new Date(action.completedDate)
          //#region //? get time min max
          if (actionMinDate) {
            if (actionMinDate > completedDate) {
              actionMinDate = completedDate;
            }
          } else {
            actionMinDate = completedDate;
          }
          if (actionMaxDate) {
            if (actionMaxDate < completedDate) {
              actionMaxDate = completedDate;
            }
          } else {
            actionMaxDate = completedDate;
          }
          //#endregion
          if (typeProject === 'PPVC/PBU') {
            if (action.actionName.includes('Start Cast at')) {
              currentSiteCode = action.currentSiteCode
            }
            if (action.actionName === 'Casting Completed') {
              currentData.castingCompletedData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Casting Completed'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.infoData.castingDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Casting Completed')
              check = false
              tempItem = {
                content: item.code, status: 'Casting Completed',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
            else if (action.actionName.includes('Received Delivery at') && checkDeliveryFitOut && currentSiteCode !== action.currentSiteCode) {//&&projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()
              currentData.deliveredToFitouYardData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Fit-out Yard' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Delivered to Fit-out Yard'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Delivered to Fit-out Yard')
              check = false
              checkDeliveryFitOut = false
              tempItem = {
                content: item.code, status: 'Delivered to Fit-out Yard',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
            else if (action.actionName.includes('Ready for Delivery from') && currentSiteCode !== action.currentSiteCode) {
              currentData.completedFitoutData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Fit Out Completed' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Fit Out Completed'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Fit Out Completed')
              check = false
              tempItem = {
                content: item.code, status: 'Fit Out Completed',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
            else if (action.actionName.includes('Received Delivery at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
              currentData.devliveredToSiteData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Delivered to Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.infoData.deliveryDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Delivered to Site')
              check = false
              tempItem = {
                content: item.code, status: 'Delivered to Site',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
            else if (action.actionName.includes('Module Installed on')) {
              currentData.installOnSiteData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Installed on Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Installed on Site')
              check = false
              tempItem = {
                content: item.code, status: 'Installed on Site',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
          } else if (typeProject === 'PreCast') {
            if ((action.actionName.includes('Delivering to') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Move into') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Ready for Delivery') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Received Delivery') && projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase())
              || action.actionName === 'Casting Completed') {
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
      

              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
                currentData.castingCompletedData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Casting Completed'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.castingDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Casting Completed')
                check = false
                tempItem = {
                  content: item.code, status: 'Casting Completed',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
            
            }
            else if ((action.actionName.includes('Received Delivery at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())
              || (action.actionName.includes('Move into at') && projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())) {
        
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
                currentData.devliveredToSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                })
                temp.modifiedDate = completedDate
                temp.infoData.status = 'Delivered to Site'
                temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.infoData.deliveryDate = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
                temp.status.push('Delivered to Site')
                check = false
                tempItem = {
                  content: item.code, status: 'Delivered to Site',
                  startPlan: '', endPlan: '',
                  startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
                }
              }
             
            }
            else if (action.actionName.includes('Module Installed on')) {
      
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                // temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
              }
              currentData.installOnSiteData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: completedDate,
              })
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Installed on Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Installed on Site')
              check = false
              tempItem = {
                content: item.code, status: 'Installed on Site',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(completedDate).format('DD-MM-YYYY')
              }
            }
          }

        }
      })
      if (temp.modifiedDate !== null) {
        if (_.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' }) >= 0) {
          let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
          if (index < 0) {
            currentData.devliveredToSiteData.push({
              dbId: item.dbId,
              mark: item.code,
              completedDate: temp.modifiedDate,
            })
            temp.status.splice(temp.listTime[temp.listTime.length - 2], 0, 'Delivered to Site')
            temp.listTime.splice(temp.listTime[temp.listTime.length - 2], 0, { time: temp.listTime[temp.listTime.length - 1].time, status: 'Delivered to Site' })
            tempItem = {
              content: item.code, status: 'Delivered to Site',
              startPlan: '', endPlan: '',
              startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
            }
          }
        }
        if (typeProject === 'PPVC/PBU') {
          if (_.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
            if (index < 0) {
              currentData.completedFitoutData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: temp.modifiedDate,
              })
              temp.status.splice(temp.listTime[temp.listTime.length - 3], 0, 'Fit Out Completed')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 3], 0, { time: temp.listTime[temp.listTime.length - 2].time, status: 'Fit Out Completed' })
              tempItem = {
                content: item.code, status: 'Fit Out Completed',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
              }
            }
          }

          if (_.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
            if (index < 0) {
              currentData.deliveredToFitouYardData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: temp.modifiedDate,
              })
              temp.status.splice(temp.listTime[temp.listTime.length - 4], 0, 'Delivered to Fit-out Yard')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 4], 0, { time: temp.listTime[temp.listTime.length - 3].time, status: 'Delivered to Fit-out Yard' })
              tempItem = {
                content: item.code, status: 'Delivered to Fit-out Yard',
                startPlan: '', endPlan: '',
                startActual: '', endActual: moment(temp.modifiedDate).format('DD-MM-YYYY')
              }
            }
          }
        }
        dataPPVC.push(temp)
        if (tempItem) {
          tempData.push(tempItem)
        }
      }
    }
    if (check) {
      dataPPVC.push({
        dbId: item.dbId,
        mark: item.code,
        modifiedDate: null,
        status: [""],
        infoData: {
          name: item.code,
          length: item.length ? item.length : "N/A",
          height: item.height ? item.height : "N/A",
          width: item.width ? item.width : "N/A",
          volume: item.volume ? item.volume : "N/A",
          carcassWeight: item.carcassWeight ? item.carcassWeight : "N/A",
          ppvcWeight: item.ppvcWeight ? item.ppvcWeight : "N/A",
          status: '',
          castingDate: '',
          deliveryDate: ''
        }
      })
    }
  })
  return ({ dataPPVC, actionMinDate, actionMaxDate, tempData })
}