import { FETCH_DATA_ONE_SHEET, OVERWRITE_PROJECT_REDUCER, SET_USER_DATA } from '../../types';


const stateInit = {
   allDataOneSheet: null,
   userData: null
};


export default function (state = stateInit, action) {
   const { type, payload } = action;

   switch (type) {
      case FETCH_DATA_ONE_SHEET:
         return {
            ...state,
            allDataOneSheet: payload
         };

      case SET_USER_DATA:
         return {
            ...state,
            userData: payload
         };

      case OVERWRITE_PROJECT_REDUCER:
         return stateInit;

      default:
         return state;
   };
};
