import { Icon, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';






const IconTableFoldRow = (props) => {

   const { type, onClick, isActivityTable, pageSheetTypeName, modeGroup, modeFilter, modeSort, btnSetFoldAllRows, onClickChangeRowsFold } = props;



   return (
      <Tooltip placement={'top'} title={'Fold/Unfold Header Rows'}>
         <DivStyled>
            <div 
               style={{ border: '1px solid black', borderRadius: 5, height: 25, textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', fontSize: 15 }}
               onClick={onClickChangeRowsFold}
            >{btnSetFoldAllRows}</div>
         </DivStyled>
      </Tooltip>
   );
};


export default IconTableFoldRow;





const DivStyled = styled.div`
   &:hover {
      background-color: ${colorType.grey1}
   };
   transition: 0.2s;
   border-radius: 5px;
   width: 31px;
   height: 32px;
   padding: 3px;

   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

`;


