import Axios from 'axios';





const apiSendEmailDevTest = async (payload) => {
   return await Axios.post('/api/sheet/send-email-all-form', payload);
};
const apiSendEmailPM = async (payload) => {
   return await Axios.post('/api/sheet/send-email-pm', payload);
};






const apiMoveFilesS3 = async (payload) => {
   return await Axios.post('/api/sheet/move-files-s3', payload);
};
const apiCheckFilesS3 = async (payload) => {
   return await Axios.post('/api/sheet/check-files-s3', payload);
};
const apiDeleteFileS3 = async (payload) => {
   return await Axios.post('/api/sheet/delete-file-s3', payload);
};


const apiGetAllCollectionAdmin = async (payload) => {
   return await Axios.get('/api/sheet/get-all-collections', { params: payload });
};





const apiFetchDataThisProject = async (payload) => {
   return await Axios.get('/api/sheet', { params: payload });
};
const apiFetchDataProjectByDiscipline = async (payload) => {
   // console.log('CHECK DMS===>>>dms_disciplineTabThisPage', window.dms_disciplineTabThisPage);
   const pageTabDiscipline = window.dms_disciplineTabThisPage;
   return await Axios.get('/api/sheet/get-project-data-by-discipline', { params: { ...payload, pageTabDiscipline } });
};
const apiFetchRowsById = async (payload) => {
   return await Axios.post('/api/sheet/find-rows-by-ids', payload);
};

const apiFetchRowHistoryThisProject = async (payload) => {
   return await Axios.get('/api/row/history', { params: payload });
};

const apiFetchDataMultiForm = async (refType, payload) => {
   return await Axios.get(`/api/row-multi-form/${refType}`, { params: payload });
};
const apiFetchDataMultiByDisciplineForm = async (refType, payload) => {
   // console.log('CHECK MULTI FORM===>>>disciplineTabThisPage', window.dms_disciplineTabThisPage);
   const pageTabDiscipline = window.dms_disciplineTabThisPage;
   return await Axios.get(`/api/row-multi-form/get-project-data-by-discipline/${refType}`, { params: { ...payload, pageTabDiscipline } });
};
const apiFetchOneDataMultiForm = async (refType, payload) => {
   return await Axios.get(`/api/row-multi-form/one-form/${refType}`, { params: payload });
};
const apiFetchOneDataByRefMultiForm = async (refType, payload) => {
   return await Axios.get(`/api/row-multi-form/one-form-by-ref/${refType}`, { params: payload });
};
const apiFetchManyDataByRefMultiForm = async (refType, payload) => {
   return await Axios.get(`/api/row-multi-form/forms-by-ref/${refType}`, { params: payload });
};


const apiFetchAllSettingsThisProject = async (payload) => {
   return await Axios.get('/api/settings/get-all-settings-this-project', { params: payload });
};
const apiFetchPublicSettingThisProject = async (payload) => {
   return await Axios.get('/api/settings/get-public-setting-this-project', { params: payload });
};
const apiFetchUserSetting = async (payload) => {
   return await Axios.get('/api/settings/get-user-setting', { params: payload });
};


const apiFetchRowHistoryManyProject = async (payload) => {
   return await Axios.post('/api/row/history/find-row-histories-many-project', payload);
};
const apiFetchDataAllProjectInDatabase = async (payload) => {
   return await Axios.post('/api/sheet/find-many', payload);
};
const apiFetchCellHistoryThisProject = async (payload) => {
   return Axios.get('/api/cell/history', { params: payload });
};
const apiFetchCellHistoryByIds = async (payload) => {
   return Axios.get('/api/cell/history/by-ids', { params: payload });
};
const apiFetchDataHistoryOneCell = async (payload) => {
   return await Axios.get('/api/cell/history/one-cell', { params: payload });
};
const apiFetchDataHistoryOneRow = async (payload) => {
   return await Axios.get('/api/row/history/one-row', { params: payload });
};
const apiFetchDataHistoryRowsById = async (payload) => {
   return await Axios.get('/api/row/history/find-rows', { params: payload });
};
const apiFetchDataHistoryRowsByCurrentVersion = async (payload) => {
   return await Axios.get('/api/row/history/find-rows-by-current-version', { params: payload });
};


const apiUpdateOrCreateRows = async (payload) => {
   return await Axios.post('/api/sheet/update-rows', payload);
};

const apiUpdateRowsHistory = async (payload) => {
   return await Axios.post('/api/row/history/update-rows-history', payload);
};

const apiCreateRowsHistory = async (payload) => {
   return await Axios.post('/api/row/history', payload);
};

const apiUpdateOrCreateRowsMultiForm = async (refType, payload) => {
   return await Axios.post(`/api/row-multi-form/save-rows/${refType}`, payload);
};

const apiUpdateOrCreateCellHistory = async (payload) => {
   return await Axios.post('/api/cell/history/', payload);
};
const apiUpdateSettingPublic = async (payload) => {
   return await Axios.post('/api/sheet/update-setting-public', payload);
};
const apiUpdateSettingUser = async (payload) => {
   return await Axios.post('/api/sheet/update-setting-user', payload);
};

const apiDeleteRowsHistory = async (payload) => {
   return await Axios.post('/api/row/history/delete-rows-history', payload);
};
const apiDeleteRows = async (payload) => {
   return await Axios.post('/api/sheet/delete-rows', payload);
};

const apiDeleteMultiFormRows = async (refType, payload) => {
   return await Axios.post(`/api/row-multi-form/delete/${refType}`, payload);
};


const apiFetchPublicUrl = async (payload) => {
   const newPayload = window.location.origin.includes('https://idd.wohhup.com')
      ? { ...payload, type: 'idd-dms' }
      : { ...payload, type: 'wh-idd-dms' };

   return await Axios.get('/api/issue/get-public-url', { params: newPayload });
};





const apiUploadFiles = async (payload) => {
   return await Axios.post('/api/drawing/set-dms-files', payload);
};
const apiUploadFileBlob = async (payload) => {
   return await Axios.post('/api/drawing/set-dms-file', payload);
};
const apiUploadFilesRfa = async (payload) => {
   return await Axios.post('/api/drawing/set-drawing-files', payload);
};


const apiClientGetKeyUpload = async (payload) => {
   return await Axios.get('/api/drawing/client-get-key-file', { params: payload });
};
const apiClientUploadFile = async ({ signedrequesturl, file }) => {

   const { type, originFileObj } = file;

   return await Axios.put(signedrequesturl, originFileObj, {
      headers: {
         'Content-Type': type || 'application/octet-stream',
      },
   });
};





const apiSendEmail = async (payload) => {
   return await Axios.post('/api/rfa/mail', payload);
};







const apiFetchAllRowsFileThisType = async (payload) => {
   return await Axios.get('/api/row-upload-file', { params: payload });
};
const apiCreateOrUpdateRowsUploadFile = async (payload) => {
   return await Axios.post('/api/row-upload-file/save-rows', payload);
};
const apiDeleteRowsUploadFile = async (payload) => {
   return await Axios.post('/api/row-upload-file/delete', payload);
};



export {
   apiFetchDataThisProject,
   apiFetchRowsById,
   apiFetchRowHistoryThisProject,
   apiFetchDataMultiForm,
   apiFetchAllSettingsThisProject,
   apiFetchRowHistoryManyProject,
   apiFetchDataAllProjectInDatabase,
   apiUpdateOrCreateRows,
   apiUpdateRowsHistory,
   apiCreateRowsHistory,
   apiDeleteRowsHistory,
   apiDeleteRows,
   apiFetchPublicUrl,
   apiUploadFiles,
   apiUploadFileBlob,
   apiUploadFilesRfa,
   apiUpdateOrCreateRowsMultiForm,
   apiSendEmail,
   apiFetchCellHistoryThisProject,
   apiUpdateOrCreateCellHistory,
   apiFetchDataHistoryOneCell,
   apiFetchDataHistoryRowsById,
   apiFetchDataHistoryOneRow,
   apiUpdateSettingPublic,
   apiUpdateSettingUser,
   apiFetchPublicSettingThisProject,


   apiGetAllCollectionAdmin,
   apiMoveFilesS3,
   apiCheckFilesS3,

   apiSendEmailDevTest,
   apiSendEmailPM,
   apiDeleteMultiFormRows,


   apiFetchAllRowsFileThisType,
   apiCreateOrUpdateRowsUploadFile,
   apiDeleteFileS3,
   apiDeleteRowsUploadFile,
   apiFetchUserSetting,

   apiFetchOneDataMultiForm,



   apiClientGetKeyUpload,
   apiClientUploadFile,

   apiFetchOneDataByRefMultiForm,




   apiFetchDataProjectByDiscipline,
   apiFetchDataMultiByDisciplineForm,
   apiFetchDataHistoryRowsByCurrentVersion,
   apiFetchManyDataByRefMultiForm,
   apiFetchCellHistoryByIds
};

