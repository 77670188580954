import React, { useState, useEffect } from "react";
import { LineChart, Line, ResponsiveContainer, ReferenceLine, XAxis, YAxis, Legend, CartesianGrid } from "recharts";

const ChartSxFPerMonth = ({ values, labels, sxfSelected }) => {
   const [barProps, setBarProps] = useState(
      labels.reduce(
         (a, { key }) => {
            a[key] = false;
            return a;
         },
         { hover: null }
      )
   );

   useEffect(() => {
      {
         if (!barProps[sxfSelected]) {
            setBarProps({ ...barProps, hover: sxfSelected });
         }
      }
   }, [sxfSelected]);

   return (
      <div>
         <h6>Total By Month and By SxF Category </h6>
         <ResponsiveContainer width="100%" minHeight={"25vh"}>
            <LineChart data={values} margin={{ top: 15, right: 5, left: 5, bottom: 20 }}>
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <XAxis dataKey="name" />
               <YAxis />

               <Legend
                  verticalAlign="bottom"
                  height={26}
                  align="left"
                  iconType="rect"
                  iconSize={11}
                  wrapperStyle={{
                     left: 45,
                  }}
                  formatter={(value, entry) => <span style={{ fontSize: "11" }}>{value.split(" ")[0]}</span>}
               />

               {labels.map((label, index) => (
                  <Line
                     connectNulls
                     type="monotone"
                     key={index}
                     dataKey={label.key}
                     stroke={label.color}
                     fill={label.color}
                     hide={barProps[label.key] === true}
                     fillOpacity={Number(barProps.hover === label.key || !barProps.hover ? 1 : 0.2)}
                     strokeOpacity={Number(barProps.hover === label.key || !barProps.hover ? 1 : 0.2)}
                     strokeWidth={Number(barProps.hover === label.key ? 2 : 1)}
                  />
               ))}
            </LineChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartSxFPerMonth;
