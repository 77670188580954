import React from 'react';
import _ from 'lodash'
// import DateTimeToolkit from '../AutodeskExtensions/Viewer/DateTimeToolkit';

import axios from "axios";
import "react-input-range/lib/css/index.css";
// import '../../scss/SideBar.scss'
// import '../../scss/FogreCustom.scss'
import Draggable from 'react-draggable';
import { Empty, Row, Col, message, Tag, Form, Typography } from 'antd';
import { Loader, Modal, IconButton, ButtonGroup, Icon, DatePicker, DateRangePicker } from 'rsuite';
import { Button, Dropdown } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
//!
import { convertHexColorToVector4 } from '../../function/TableFunction'
import { getAllElementdbIdsOneModel } from '../../function/ForgeFunction'
import { timelineChangeProgress, setDateByPanel, setDateStartEndByPanel } from '../../function/ProgressFunction'
import moment from 'moment'
import { DraggableAreasGroup } from 'react-draggable-tags'
import * as Timeline from "vis-timeline/standalone";



const timeline = Timeline
const group = new DraggableAreasGroup();


class SoldReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionMinDate: null,
      actionMaxDate: null,
      timeValue: {
        min: null,
        max: null,
      },
      openDock: false,
      loading: false,
      listSheetId: [],
      openDateControl: false,
      smartSheetId: '',
      instantData: null,
      isShowList: false,
      tableDetail: []
    };
    this.smartSheetData = []
    this.timelinePanel = null
  }
  componentWillMount = () => {
    this.setState({ loading: true, openDock: true })
    let actionMinDate = null;
    let actionMaxDate = null;
    axios.post("/api/smartsheet/getsheet", { smartSheetId: this.props.smartSheetId })
      .then(res => {
        this.smartSheetData = []
        _.forEach(res.data, row => {
          let rowContent = {
            targetDate: null,
            actualDate: null,
            targetEnd: '',
            actualEnd: '',
            unitType: '',
            unitNumber: '',
            status: [],
            statusCurrent: '',
            dbId: []
          }
          if (row.cells[0].value !== undefined && row.cells[0].value !== ''
            && row.cells[1].value !== undefined && row.cells[1].value !== ''
            && row.cells[2].value !== undefined && row.cells[2].value !== ''
            && row.cells[5].value !== undefined && row.cells[5].value !== ''
            && row.cells[7].value !== undefined && row.cells[7].value !== '') {
            let completedDate = new Date(row.cells[7].value)
            if (actionMinDate) {
              if (actionMinDate > completedDate) {
                actionMinDate = completedDate;
              }
            } else {
              actionMinDate = completedDate;
            }
            if (actionMaxDate) {
              if (actionMaxDate < completedDate) {
                actionMaxDate = completedDate;
              }
            } else {
              actionMaxDate = completedDate;
            }
            let targetDate = moment(row.cells[7].value, 'YYYY-MM-DD')
            let actualDate = moment(row.cells[7].value, 'YYYY-MM-DD')
            rowContent.targetDate = targetDate;
            rowContent.actualDate = actualDate;
            rowContent.unitType = row.cells[0].value
            rowContent.unitNumber = row.cells[1].value
            rowContent.statusCurrent = row.cells[5].value
            this.smartSheetData.push(rowContent);
          }
        })
        this.setState({
          actionMaxDate: actionMaxDate, actionMinDate: actionMinDate, loading: false,
          timeValue: {
            min: moment(actionMinDate.getTime()).startOf('months'),
            max: moment(actionMaxDate.getTime()).endOf('months'),
          }
        }, () => {
          this.matchSmartSheetWithModel()
        })
      })
      .catch(() => this.setState({ loading: false }))

  }

  componentWillUnmount = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.model)
  }

  handleCloseDock = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.model)
    this.props.onChangeDockDisplay('soldReportDock', false)
  }
  //#region //!generate timeline
  matchSmartSheetWithModel = () => {
    let temp = getAllElementdbIdsOneModel(this.props.viewer);
    let tempData = []
    let count = temp.length
    _.forEach(temp, id => {
      this.props.viewer.model.getProperties(id, (modelAProperty) => {
        let unitNumber = ''
        let unitType = ''
        _.forEach(modelAProperty.properties, (v) => {
          if (v.displayName === 'Unit Type') {
            if (v.displayValue !== '')
              unitType = v.displayValue
          }
          else if (v.displayName === 'Unit Number') {
            if (v.displayValue !== '')
              unitNumber = v.displayValue
          }
        })
        if (unitNumber !== '' && unitType !== '')
          tempData.push({ dbId: id, mark: unitNumber, type: unitType });
        count--
        if (count === 0) {
          _.forEach(this.smartSheetData, item => {
            let modelItem = _.filter(tempData, x => x.mark.toLowerCase() === item.unitNumber.toLowerCase() &&
              x.type.toLowerCase() === item.unitType.toLowerCase())
            if (modelItem.length) {
              _.forEach(modelItem, v => {
                item.dbId.push(v.dbId);
              })

            }
          })
          this.generateTimeline()
          this.generateData()
        }
      })

    })
  }
  generateTimeline = () => {
    let _this = this
    if (this.timelinePanel !== null)
      this.timelinePanel.destroy();
    var container = document.getElementById('timeline-sold-report');
    this.groups = new timeline.DataSet();
    this.items = new timeline.DataSet([
      {
        id: 1,
        start: moment(new Date(0, 1, 1)).startOf('month'),
        end: moment(this.state.actionMinDate).startOf('month'),
        type: "background",
        className: "custom-background-start",
      },
      {
        id: 2,
        start: moment(this.state.actionMaxDate).endOf('month'),
        end: moment(new Date(2500, 1, 1)).startOf('month'),
        type: "background",
        className: "custom-background-end"
      }
    ]);
    var options = {
      width: '100%', height: '100%', zoomKey: 'ctrlKey',
      min: moment(this.state.actionMinDate).startOf('month').subtract(1, 'months'), // lower limit of visible range
      max: new Date(2500, 1, 1), // upper limit of visible range 
      zoomMin: 1000 * 60 * 60 * 24 * 17,
      // zoomMax: 1000 * 60 * 60 * 24 * 31 * 12 *2
    };
    this.timelinePanel = new timeline.Timeline(container, this.items, this.groups, options);
    this.timelinePanel.on('rangechanged', moveTimelineToCurrentDate);
    function moveTimelineToCurrentDate(e) {
      if (!e.byUser) {
        _this.timelinePanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
          moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
        _this.timelinePanel.off('rangechanged', moveTimelineToCurrentDate)
      }
    }
    this.timelinePanel.addCustomTime(moment(this.state.actionMinDate).startOf('month'), 'custom-timeline-start-sold-report')
    this.timelinePanel.addCustomTime(moment(this.state.actionMaxDate).endOf('month'), 'custom-timeline-end-sold-report')
    this.timelinePanel.on('timechange', (e) => {
      timelineChangeProgress(this.timelinePanel, this.items, e, this, 'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report', null, 'archiSummary')
    })
    this.timelinePanel.on('doubleClick', function (e) {
      let check = true
      if (e.customTime === 'custom-timeline-start-sold-report') {
        _this.setState({ openPortalStart: true, openPortalEnd: false, openDock: false, openPieChart: false })
        check = false
      } else if (e.customTime === 'custom-timeline-end-sold-report') {
        _this.setState({ openPortalStart: false, openPortalEnd: true, openDock: false, openPieChart: false })
        check = false
      }
      if (check)
        _this.timelinePanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
          moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
    });

  }
  generateData = () => {
    let tempData = {
      'Sold': {},
    }
    _.forEach(this.smartSheetData, v => {
      if (this.state.timeValue.min <= v.actualDate && this.state.timeValue.max >= v.actualDate) {
        _.forEach(tempData, (item, k) => {
          if (!tempData['Sold'][v.unitNumber])
            tempData['Sold'][v.unitNumber] = []
          tempData['Sold'][v.unitNumber].push(v)
        })
      }
    })
    this.setState({ instantData: tempData }, () => {
      this.showModelColor()
    })
  }
  showModelColor = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.model)
    // let colorLiv = convertHexColorToVector4("#FDA4A4")
    // _.forEach(this.smartSheetData, item => {
    //   _.forEach(item.dbId, id => {
    //     this.props.viewer.setThemingColor(id, colorLiv)
    //   })

    // })
    if (this.state.instantData) {
      let colorLiv = convertHexColorToVector4("#FDA4A4")
      _.forEach(this.state.instantData, item => {
        _.forEach(_.flatMapDeep(item), v => {
          _.forEach(v.dbId,id=>{
            this.props.viewer.setThemingColor(id, colorLiv)
          })
        })
      })
    }
  }
  //#endregion

  //!
  //#region //? handle date panel
  handleClosePortal = () => {
    this.setState({ openPortalStart: false, openPortalEnd: false, openDock: true, openPieChart: true, openDateControl: false })
  }
  handleDateTimeline = (name, date) => {
    setDateByPanel(this.timelinePanel, this.items, name, date, this, 'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report', 'archiSummary')
    this.handleClosePortal()
  }
  disabledMinDate = (current) => {
    let temp1 = moment(this.state.actionMinDate.getTime()).startOf('months').startOf('day')
    return current < temp1
  }
  disabledMaxDate = (current) => {
    let temp1 = moment(this.state.actionMaxDate.getTime()).endOf('months').endOf("day")
    return current > temp1
  }
  //#endregion
  //#region //? setting panel
  handleCloseSettingPanel = () => {
    this.setState({ openSettingPanel: false, loadingSetting: false, levels: [], openDock: true, openPieChart: true }, () => {
      this.setState({ levels: this.state.tempLevels })
    })
  }
  handleApplySetting = () => {
    let temp = []
    _.forEach(this.state.levels, (v, k) => {
      temp.push({ id: v.id, content: v.content, date: v.date, sort: k, elevation: v.elevation })
    })
    this.setState({ levels: [] }, () => {
      this.setState({ levels: temp }, () => {
        axios.post(`/api/versions/save-level-date`, { itemId: this.props.itemId, level: temp })
          .then(() => {
            this.setState({ openSettingPanel: false, loadingSetting: false, openDock: true })
          })
          .catch(() => message.error(`Save level failed.`))
      })
    })
  }
  handleOpenSettingPanel = () => {
    this.setState({ openSettingPanel: true, loadingSetting: true, openDock: false, openPieChart: false }, () => {
      this.getLevelDataFromDB()
    })
  }
  handleDateSetting = (item, e) => {
    let temp = this.state.levels.slice(0)
    let index = _.findIndex(this.state.levels, function (o) { return o.id === item.id })
    temp.splice(index, 1)
    temp.splice(index, 0, { id: item.id, content: item.content, date: e, sort: item.sort, elevation: item.elevation })
    this.setState({ levels: [] }, () => {
      this.setState({ levels: temp })
    })
  }
  //#endregion


  handleDateControl = () => {
    this.setState({ openDock: false, openDateControl: true })
  }
  handleDate = (name) => {
    console.log(moment(this.state.actionMaxDate).format('DD-MM-YYYY'))
    console.log(moment())
    if (name === 'Yesterday progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinePanel, this.items,
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"), this,
          'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report')
      } else
        message.warning('Out range')
    } else if (name === '7 days progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinePanel, this.items,
          moment().subtract(7, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"), this,
          'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report')
      } else
        message.warning('Out range')
    } else if (name === 'This month progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinePanel, this.items,
          moment().startOf('months').startOf("day"),
          moment().endOf('months').endOf("day"), this,
          'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report')
      } else
        message.warning('Out range')
    }
    this.handleClosePortal()
  }
  handleRangeDate = (value) => {
    setDateStartEndByPanel(this.timelinePanel, this.items,
      moment(value[0]).startOf("day"),
      moment(value[1]).endOf("day"), this,
      'custom-timeline-start-sold-report', 'custom-timeline-end-sold-report')
    this.handleClosePortal()
  }
  handleDetail = () => {
    this.setState({ detail: !this.state.detail })
  }
  handleTable = (data) => {
    let temp = []
    _.forEach(data, v => {
      temp.push(v)
    })
    this.setState({ isShowList: true, tableDetail: temp })
  }
  handleCloseDockTable = () => {
    this.setState({ isShowList: false })
  }
  render() {
    return (
      <>
        <div>
          <Draggable
            axis="both" bounds='body'
            handle=".custom-dock-panel-title"
            defaultPosition={{ x: 420, y: 0 }}
            scale={1}
          >
            <div className='custom-forge-dock' style={{ display: this.state.openDock ? 'inline' : 'none', height: 250, width: '70%' }}
              id='custom-sold-report-dock' >
              <div className='custom-dock-panel-title'>Sold Report</div>
              <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
              <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
                <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                  {this.state.loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
                    !(this.state.instantData !== null) ?
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                        <span style={{ color: 'white' }}>
                          No data
                    </span>} />
                      :
                      <div style={{ height: 'calc(100% - 100px)' }}  >
                        <Form style={{
                          color: "white", padding: '15px 10px 10px 10px', backgroundColor: "rgba(97, 97, 97, 0.075)",
                          position: 'fixed', width: '100%', margin: 0, height: '100%'
                        }}>
                          <Row gutter={[24, 24]} type="flex" justify="space-between">
                            {/* <Col span={2} >#   </Col> */}
                            <Col span={24} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                              <Tag color="blue" style={{ fontSize: 'medium' }}>
                                Sold
                        </Tag>
                            </Col>
                            {/* <Col span={6} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="green" style={{ fontSize: 'medium' }}>
                              Master Bath
                            </Tag>
                          </Col>
                          <Col span={6} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="yellow" style={{ fontSize: 'medium' }}>
                              Bath 2
                        </Tag>
                          </Col>
                          <Col span={6} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="yellow" style={{ fontSize: 'medium' }}>
                              Bath 3
                      </Tag>
                          </Col> */}

                          </Row>
                          <Row gutter={[24, 24]} type="flex" justify="space-between">
                            {/* <Col span={2} >Actual</Col> */}
                            {Object.keys(this.state.instantData).map((key, i) =>
                              <Col span={24} style={{ textAlign: '-webkit-center' }}
                                onClick={this.handleTable.bind(this, _.flatMapDeep(this.state.instantData[key]))}>
                                {
                                  _.values(this.state.instantData[key]).length === 0 ?
                                    0 : _.flatMapDeep(this.state.instantData[key]).length}
                              </Col>
                            )}
                          </Row>
                        </Form>
                      </div>
                  }
                  <div id='timeline-sold-report' style={{
                    backgroundColor: 'white',
                    color: "#000", position: 'absolute !important', width: '100%', bottom: 0, margin: 0, height: 100
                  }}>
                    <ButtonGroup style={{ right: '10px', position: 'absolute', zIndex: '1' }}>
                      <Tooltip title={'Set Date'} placement="top">
                        <IconButton icon={<Icon icon={'table'} />} size="xs"
                          onClick={this.handleDateControl} />
                      </Tooltip>


                    </ButtonGroup>
                  </div>
                </div>
              </div>
              <div className='custom-dock-panel-footer' />
            </div>
          </Draggable>
          {/* {this.state.isShowList && <PPVCTable tableDetail={this.state.tableDetail}
          openDock={this.state.isShowList} handleCloseDock={this.handleCloseDockTable}
          columns={[
            { title: 'Unit Type', field: 'unitType' },
            { title: 'Unit Number', field: 'unitNumber' },
            { title: 'Location', field: 'location' },
            { title: 'Target End', field: 'targetEnd' },
            { title: 'Acutal End', field: 'actualEnd' },
          ]} />} */}
          <Modal backdrop="static" show={this.state.openPortalStart} onHide={this.handleClosePortal} size='xs'>
            <Modal.Header>
              <Modal.Title>Set start date</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <DatePicker placeholder="Start Date" size='small' onChange={this.handleDateTimeline.bind(this, 'min')} format={'DD-MM-YYYY'}
                value={moment(new Date(this.state.timeValue.min), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
                disabledDate={this.disabledMinDate} showToday={false} />
            </Modal.Body>
          </Modal>
          <Modal backdrop="static" show={this.state.openPortalEnd} onHide={this.handleClosePortal} size='xs'>
            <Modal.Header>
              <Modal.Title>Set end date</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <DatePicker placeholder="End Date" size='small' onChange={this.handleDateTimeline.bind(this, 'max')} format={'DD-MM-YYYY'}
                value={moment(new Date(this.state.timeValue.max), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
                disabledDate={this.disabledMaxDate} showToday={false} />
            </Modal.Body>
          </Modal>
          <Modal backdrop="static" show={this.state.openDateControl} onHide={this.handleClosePortal} size='xs'>
            <Modal.Header>
              <Modal.Title>Set date</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <Button color='blue' fluid onClick={this.handleDate.bind(this, 'Yesterday progress')}>
                Yesterday progress
            </Button> <br />
              <Button color='blue' fluid onClick={this.handleDate.bind(this, '7 days progress')}>
                7 days progress
            </Button> <br />
              <Button color='blue' fluid onClick={this.handleDate.bind(this, 'This month progress')}>
                This month progress
            </Button> <br />
              <DateRangePicker placeholder="Range Date" size='small' onChange={this.handleRangeDate} format={'DD-MM-YYYY'}
                value={[moment(new Date(this.state.timeValue.min), 'DD-MM-YYYY').startOf('month'),
                moment(new Date(this.state.timeValue.max), 'DD-MM-YYYY').endOf('month')]}
                // disabledDate={ allowedRange(moment(new Date(this.state.actionMinDate), 'DD-MM-YYYY').startOf('month').startOf('day').format('DD-MM-YYYY'),
                // moment(new Date(this.state.actionMaxDate), 'DD-MM-YYYY').endOf('month').endOf('day').format('DD-MM-YYYY'))}
                style={{ width: '100%' }} cleanable={false}
                showToday={false} />
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }


}
export default SoldReport;