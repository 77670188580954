import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/app/auth";
import decode from 'jwt-decode';
import { Result, Button, message } from 'antd';
import { Icon, Loader } from 'rsuite'
import axios from 'axios'
// import { logPageView } from '../../Function/PageFuction'
// import {Cover} from '../cover/index'


class NoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  componentWillMount = () => {
    if (window.location.href.includes('trimbleconnect-callback?code=')) {
      axios.get('/api/trimble/oauth/trimble-callback/' + window.location.search ,)
        .then(res => {
          message.success('Success')
          this.props.history.push("/")
        })
        .catch(err => {
          this.setState({ loading: false })
          message.warning(`Can't login trimble`)
        })
    } else if (window.location.href.includes('callback/oauth?code=')) {
      axios.get(`/api/forge/oauth/callback/${window.location.search}&email=${this.props.userInfo.email}`,)
        .then(res => {
          message.success('Success')
          this.props.history.push("/")
        })
        .catch(err => {
          this.setState({ loading: false })
          message.warning(`Can't login trimble`)
        })
    } else {
      this.setState({ loading: false })
    }
  }
  handleGoHome = () => {
    this.props.history.push("/")
  }
  render() {

    return (
      <>
        {this.state.loading ?
          <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
          <Result
            status="error"
            title="404"
            subTitle="Page Not Found."
            extra={[
              <Button type="primary" key="console" onClick={this.handleGoHome}>Back Home</Button>,

            ]}
          >

          </Result>}

      </>

    );
  }
}

// const HomePage = () => 

NoPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userInfo: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    userInfo: state.user
  };
}

export default connect(mapStateToProps)(NoPage);
