import axios from './axiosClient';

const dataForChart = 'dataForChart';
const dataForChartCost = 'dataForChartCost';
const dataSummaryCostCode = 'dataSummaryCostCode';
let endPointLamda =
  'https://02aso3ff1e.execute-api.ap-southeast-1.amazonaws.com/Prod';
// let endPointLamda =
//   'https://vp4qmgekt7.execute-api.ap-southeast-1.amazonaws.com/Prod';

//  endPointLamda="https://localhost:60282"

// async function apiGetDataForCharts(payload) {
//   return await axios.get('/api/worker/worker-shift/data-for-charts', {
//     params: payload,
//   });
// }

async function apiGetDataForCharts(projectId, isAll = true) {
  let payload = {
    projectId: projectId,
    key: dataForChart + `${isAll ? '' : 'Wh'}`,
  };
  return await axios.get('/api/worker/dashboard-data', { params: payload });
}

async function apiGetDataForChartsCost(projectId, isAll = true) {
  let payload = {
    projectId: projectId,
    key: dataForChartCost + `${isAll ? '' : 'Wh'}`,
  };
  return await axios.get('/api/worker/dashboard-data', { params: payload });
}

async function apiGetTimeSheetWorkers(projectId, month, isAll = true) {
  let payload = {
    projectId: projectId,
    key: `dicTimeSheet${isAll ? '' : 'Wh'}-${month}`,
  };
  return await axios.get('/api/worker/dashboard-data', { params: payload });
}

async function apiGetSummaryCostCode(payload) {
  // return await axios.get("/api/worker/worker-shift/summary-cost-code", {
  //   params: payload,
  // });

  console.log('post', payload);
  return await axios.post(
    `${endPointLamda}/api/worker/get-cost-code-data-by-start-end`,
    payload
  );
}

async function apiGetSummaryCostCode2(projectId, isAll = true) {
  let payload = {
    projectId: projectId,
    key: `dataSummaryCostCode${isAll ? '' : 'Wh'}`,
  };
  return await axios.get(`https://idd.wohhup.com/api/worker/dashboard-data`, { params: payload });
}


async function apiGetSummaryCostCodeAndUnit(projectId, isAll = true) {
  let payload = {
    projectId: projectId,
    key: `dataSummaryCostCode${isAll ? '' : 'Wh'}`,
  };
  return await axios.get(`https://idd.wohhup.com/api/worker/dashboard-data`, { params: payload });
}

async function getTemplateExcel(payload) {
  return await axios.get(
    'https://raw.githack.com/Woh-Hup/Resource/main/file/worker-allocation/template.xlsx',
    //'http://localhost:3000/template.xlsx',
    { responseType: 'arraybuffer' }
  );
}

async function searchHoliday(payload) {
  return await axios.get('/api/worker/holiday', { params: payload });
}

async function forceUpdateData(payload) {
  return await axios.get(
    `${endPointLamda}/api/worker`,
    //"https://vp4qmgekt7.execute-api.ap-southeast-1.amazonaws.com/Prod/api/worker",
    //"https://localhost:60282/api/worker",
    { params: payload }
  );
}

async function apiGetDataOccupations() {
  return await axios.get('/api/worker/dashboard-data/get-occupations');
}

export {
  apiGetDataForCharts,
  apiGetTimeSheetWorkers,
  apiGetSummaryCostCode,
  apiGetSummaryCostCode2,
  apiGetDataForChartsCost,
  getTemplateExcel,
  searchHoliday,
  forceUpdateData,
  apiGetDataOccupations
};
