import React, { useEffect, useState } from "react";
import { Row, Col, Radio, DatePicker, Tooltip, Tabs } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const Toolbar = () => {
   const [rangeSelected, setRangeSelected] = useState();
   const [data_productivity_range, set_data_productivity_range] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [tempData, setTempData] = useState();
   const [filterMode, setFilterMode] = useState(0);

   let a = 3;
   const handleChangeDate = (fm, dateString) => {
      var customParseFormat = require("dayjs/plugin/customParseFormat");
      dayjs.extend(customParseFormat);
      var isBetween = require("dayjs/plugin/isBetween");
      dayjs.extend(isBetween);
      var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
      dayjs.extend(isSameOrAfter);

      const now = dayjs();

      const y = now.year();
      const m = now.month();
      const d = now.day();
      let data = [];

      switch (fm) {
         case 1:
            const sd1 = dayjs([y, m + 1, 1]).format("DD/MM/YYYY");
            data = data.filter((e) => e.month == m);
            set_date_start(sd1);
            set_date_end(null);

            break;
         case 2:
            let sd2 = dayjs([y, m, 1]).format("DD/MM/YYYY");
            const de = dayjs(sd2).daysInMonth();
            let ed2 = dayjs([y, m, de]).format("DD/MM/YYYY");
            // data = data.filter((e) => e.created_at >= sd2 && e.created_at <= ed2);
            data = data.filter((e) => e.month == m - 1);
            set_date_start(sd2);
            set_date_end(ed2);
            break;

         case 4:
            if (
               dateString[0] !== undefined &&
               dateString[0] !== "" &&
               dateString[1] !== undefined &&
               dateString[1] !== ""
            ) {
               data = data.filter((e) =>
                  dayjs(e.date, "DD/MM/YYYY").isBetween(dateString[0], dateString[1], "day", "[]")
               );
               set_date_start(dateString[0]);
               set_date_end(dateString[1]);
            }
            break;
      }
   };

   return (
      <div className="qaqc_dashboard_toolbar">
         <ButtonGroup style={{ float: "right", marginTop: "7px" }}>
            <Radio.Group
               defaultValue={filterMode}
               buttonStyle="solid"
               value={filterMode}
               onChange={(e) => {
                  setFilterMode(e.target.value);
               }}
            >
               <div>
                  <Tooltip title="Shown data for all time.">
                     <Radio.Button
                        value={0}
                        onClick={() => {
                           handleChangeDate(0, null);
                        }}
                     >
                        All time
                     </Radio.Button>
                  </Tooltip>

                  <Tooltip title="Shown data for current month">
                     <Radio.Button
                        value={1}
                        onClick={() => {
                           handleChangeDate(1, null);
                        }}
                     >
                        {dayjs().format("MMM-YY")}
                     </Radio.Button>
                  </Tooltip>
                  <Tooltip title="Shown data of last month">
                     <Radio.Button
                        value={2}
                        onClick={() => {
                           handleChangeDate(2, null);
                        }}
                     >
                        {dayjs().subtract(1, "month").format("MMM-YY")}
                     </Radio.Button>
                  </Tooltip>

                  <Tooltip title="Shown DO for selected months.">
                     <Radio.Button value={4} style={{ float: "right", width: 220 }}>
                        Select range of date
                        {filterMode === 4 ? (
                           <RangePicker
                              size={"default"}
                              style={{ float: "right", marginRight: -15, width: 220 }}
                              onChange={(date, dateString) => {
                                 handleChangeDate(4, dateString);
                                 setFilterMode(4);
                              }}
                           />
                        ) : null}
                     </Radio.Button>
                  </Tooltip>
               </div>
               {/* </Space> */}
            </Radio.Group>
         </ButtonGroup>
      </div>
   );
};

export default Toolbar;
