import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { connect } from 'react-redux';
import _ from 'lodash'

import { message, Button, List, Divider, Typography, Row, Col, Modal } from 'antd';
import {
  Icon, Input, Loader, Avatar, InputPicker, Button as ButtonRs, Modal as ModalRs
} from 'rsuite';
import { Segment } from 'semantic-ui-react';
import { TableSetupPermissionRole } from '../../tables'
import { ColumnSetupPermissionRole, roles } from '../../columns'
import { Scrollbars } from 'react-custom-scrollbars';
import { renderView, checkWohhupUser } from '../../function/AdminFunction'
import validator from 'validator';
import CompanyControl from './CompanyControl'
const { Text } = Typography
let groupLocal = { name: '', members: [] }
let listUserLocal = null
function DialogPermission(props) {

  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  useEffect(() => {
    setLoading(true)
    axios.get(`/api/projects/permission-for-role`, { params: { projectId: props.projectId, token: props.userInfo.token } })
      .then(res => {
        let temp = []
        _.forEach(roles, v => {
          let index = _.findIndex(res.data, i => { return i.role === v.label })
          if (index >= 0) {
            temp.push(res.data[index])
          } else {
            temp.push({ role: v.label })
          }
        })
        let col = ColumnSetupPermissionRole(cbAccess, props.isAdmin)
        setData(temp)
        setColumns(col)
        setLoading(false)
      })
      .catch(err => {
        handleClose()
      })

  }, [])
  const handleClose = () => {
    props.close()
  }
  const cbAccess = (row, permission, e) => {
    console.log(e.target.checked, permission)
    setLoading(true)
    axios.post(`/api/projects/set-permission-for-role`, { projectId: props.projectId, role: row.original.role, permission, permissionValue: e.target.checked, token: props.userInfo.token })
      .then(res => {
        let temp = []
        _.forEach(roles, v => {
          let index = _.findIndex(res.data, i => { return i.role === v.label })
          if (index >= 0) {
            temp.push(res.data[index])
          } else {
            temp.push({ role: v.label })
          }
        })
        setData(temp)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  return (
    <>
      <Modal
        title='Permission'
        visible={props.open}
        onCancel={handleClose}
        centered={true}
        bodyStyle={{ padding: 5 }}
        width='50%'
        footer={[
          <></>
        ]}
      >
        {loading && <Loader center content="Loading..." speed="fast" size="md" backdrop vertical style={{ zIndex: 100000 }} />}
        <div style={{ height: '80vh', width: '100%', position: 'relative' }}>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>

            <TableSetupPermissionRole
              columns={columns}
              data={data}
              defaultPageSize={1000000000}
              // typeTable='userControl'
              userCreatedProject={props.userCreatedProject}


              isDoubleClick={false}
            // callbackDoubleClick={callbackDoubleClick}
            />
          </Scrollbars>
        </div>

      </Modal>

    </>
  )
}
DialogPermission.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(DialogPermission)



