import React from 'react'
import $ from 'jquery';
import { SelectPicker } from 'rsuite';
import _ from 'lodash'

import Draggable from 'react-draggable';
import {
  setupForgeExtensionBeforeLoaded
} from '../../function/ForgeFunction'
import { message } from 'antd';

export default class DocumentBrowserContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      viewCurrent: null,
      viewAll: [],
      document: null
    };

  }

  componentWillMount = () => {
    if (this.props.openDock) {
      this.setState({viewCurrent:null})
      let temp = []
      let view3d = this.props.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
      let view2d = this.props.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)
      let viewAll = view3d.concat(view2d)
      // console.log(viewAll)
      _.forEach(viewAll, v => {
        if (v.data.size >= 0)
          temp.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v })
      })
      this.setState({
        data: temp, viewCurrent: this.props.viewer.impl.model.getDocumentNode().data.guid,
        document: this.props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument,
        viewAll: viewAll
      })
    }
  }
  handleChangeView = (value) => {
    var _this = this
    var loadOptions = {
      globalOffset: this.props.viewer.impl.model.myData.globalOffset,
      // applyRefPoint: true,
      modelNameOverride: this.props.viewer.impl.model.myData.loadOptions.modelNameOverride,
      isAEC: true,
      guid: this.props.viewer.impl.model.myData.loadOptions.guid,
      viewableID: this.props.viewer.impl.model.myData.loadOptions.viewableID,
      itemId: this.props.viewer.impl.model.myData.loadOptions.itemId,
      version: this.props.viewer.impl.model.myData.loadOptions.version,
  
    };
    _.forEach(this.state.viewAll, v => {
      if (v.data.guid === value) {
        this.props.viewer.loadDocumentNode(this.state.document, v, loadOptions)
          .then(() => {
            setupForgeExtensionBeforeLoaded(_this.props.viewer, _this.props.viewerPage)
          })
          .catch(()=>  this.props.onChangeViewerModel(false)) //! disable side nav
        this.props.onChangeViewerModel(true)//! enable side nav
        return false
      }
    })
    this.setState({viewCurrent:value})
    this.handleCloseDock() // ? handle close document broswer and button acitve
    // ! when status for claim display
    if(_this.props.viewerPage.state.disabledBtn.btnStatusForClaim){
      message.warning('You need to setup for this view')
      this.props.onChangeDialogDisplay('dialogStatusForClaim', true)
    }
  
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay('documentDockMain', false)
    let btn =$('#btn-docbrowser')
    btn.removeClass("active")
    btn.addClass("inactive")
  }
  render() {
    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 0, y: 0 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none' }} id='custom-main-documentDock' >
          <div className='custom-dock-panel-title'>Document Browser</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
              <SelectPicker
                data={this.state.data}
                style={{ width: '100%' }}
                value={this.state.viewCurrent}
                groupBy="group"
                placeholder="Select View"
                cleanable={false}
                onChange={this.handleChangeView}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
                    </div>
                  );
                }}
                renderMenuGroup={(label, item) => {
                  return (
                    <div>
                      <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
                </div>
                  );
                }}
                renderValue={(value, item) => {
                  return (
                    <div>
                      <span style={{ color: '#000000' }}>
                        <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} />{item.group === '3D' ? " View3D" : " Sheet"} :
              </span>{' '}
                      {item.label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className='custom-dock-panel-footer'/>
        </div>




      </Draggable>

    )
  }
}