import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {
  Card
} from "reactstrap";
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';
import { Popconfirm, Icon } from 'antd';

import {WH_Icon_NoAccess,WH_Icon_Folder} from '../../../image'

const useStyles = makeStyles({
  card: {
    maxWidth: 300,
    minWidth: 300,
    minHeight: 250,
    maxHeight: 250,
    margin: 10
  },
  media: {
    height: 140,
  },
  buton: {
    height: 100,
  }
});


export default function CardFolders(props) {
  const classes = useStyles();



  const deleteFolder = () => {
    let data = {
      projectId: props.project.projectId, folderId: props.folder.folderId, folderName: props.folder.folderName, email: props.email
    }
    props.handleDeleteFolder(data)
  }
  const handleClickOpenSetRole = () => {
    let data = { folderId: props.folder.folderId, folderName: props.folder.folderName }
    props.handleClickOpenSetRolePanel(data)
  }

  return (
    <div>
      <Card className={classes.card}>
        {(props.permission || props.roleUploader) ?
          <Link to={{
            pathname: `/projects/project/folder`,
            state: {
              userCreatedProject: props.userCreatedProject,
              folderId: props.folder.folderId,
              folderName: props.folder.folderName,
              projectId: props.project.projectId,
              projectName: props.project.projectName,
              sharePointId: props.project.sharePointId,
              issueKey: props.project.issueKey,
              trimbleKey: props.project.trimbleKey,
              listAdmin:props.listAdmin,
              typeProject: props.project.typeProject ,
              useRfa: props.project.useRfa,
              isDetail: true
            }
          }}>
            <CardActionArea value={props.folder.folderName}>
              <CardMedia
                className={classes.media}
                image={(props.permission || props.roleUploader) ? WH_Icon_Folder :
                  WH_Icon_NoAccess}
                title={props.folder.folderName}
                style={{ backgroundSize: "contain" }} />
              <CardContent style={{ backgroundSize: "contain", overflow: "hidden", wordBreak: "break-all", maxHeight: "60px" }}>
                <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "60px", fontSize: "15px" }} >
                  {props.folder.folderName}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
          :
          <CardActionArea value={props.folder.folderName}>
            <CardMedia
              className={classes.media}
              image={props.permission || props.roleUploader ? WH_Icon_Folder :
                WH_Icon_NoAccess}
              title={props.folder.folderName}
              style={{ backgroundSize: "contain" }} />
            <CardContent style={{ backgroundSize: "contain", overflow: "hidden", wordBreak: "break-all", maxHeight: "54px" }}>
              <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "15px" }} >
                {props.folder.folderName}
              </Typography>
            </CardContent>
          </CardActionArea>
        }

        <CardActions style={{ padding: "0px", backgroundColor: "#efefef" }}>

          <div>
            <Popconfirm
              title=" Are you sure to delete folder ?" onConfirm={deleteFolder} okText="Yes" cancelText="No"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Tooltip title="Delete">
                <IconButton aria-label="Delete Folder" disabled={(!props.isadmin && !props.isProjectAdmin)}>
                  <DeleteForeverTwoToneIcon style={{ fontSize: 30 }} /></IconButton>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Settings" disabled={(!props.isadmin && !props.isProjectAdmin)}>
              <IconButton aria-label="Settings" onClick={handleClickOpenSetRole} >
                <SettingsApplicationsTwoToneIcon style={{ fontSize: 30 }} /> </IconButton>
            </Tooltip>
          </div>

        </CardActions>
      </Card>
      <React.Suspense>

      </React.Suspense>

    </div>

  );
}
