import React from 'react';
import $ from 'jquery';
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Empty, Collapse, Row, Col, Typography, Icon, Input, message, List, Tooltip, Switch, Popconfirm } from 'antd';
import { Button, Dropdown } from 'semantic-ui-react'
import { Drawer, Loader, Modal, IconButton, DatePicker, Popover, Whisper, CheckTreePicker, ButtonGroup } from 'rsuite';
import * as rsuite from 'rsuite'
import _ from 'lodash'
// import '../scss/SideBar.scss'
import {
  formatNumber
} from '../function/TableFunction'
import moment from 'moment'
import {
  getAllElementdbIdsOneModel
} from '../function/ForgeFunction'
import {
  workerInstance
} from '../function/AdminFunction'
// import customModelWorkerFile from '../../customModel.worker.js'
// import worker from 'workerize-loader!../../workers/customModelworker'; // eslint-disable-line import/no-webpack-loader-syntax
// const workerInstance = worker()

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;
const Autodesk = window.Autodesk;
const THREE = window.THREE;


class SidebarCustomProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameElement: '',
      defaultKey: [],
      data: {},
      loading: false,
      openAddParameterPanel: false,
      openRemoveParameterPanel: false,
      parameterGroup: '',
      parameterType: 'Text',
      parameterName: null,
      parameterValue: null,

      customModel: [],
      isEdited: false,
      decimalCount: 3,
      objectPicked: [],
      localModelSaved: {},
      isShowAllProperty: false,
      removeParameter: []
    };
  }
  componentWillMount = () => {
    if (this.props.displayPanel) {
      this.setState({ loading: true })
      axios.get("/api/customproperty/get-custom-model", { params: { itemId: this.props.itemId } })
        .then(async res => {
          let tempModel = res.data.model
          let tempData = await this.customModelData(tempModel, this.props.viewer)
          this.setState({ localModelSaved: tempData.data, loading: false, isEdited: tempData.isEdited }, () => {
            this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
            var currSelection = this.props.viewer.getSelection();
            if (currSelection.length === 1) {
              this.customProperty(this.props.viewer, currSelection[0])
              this.setState({ dbIdSelected: currSelection[0] })
            } else if (currSelection.length > 1) {
              this.customPropertyElements(this.props.viewer, currSelection)
              this.setState({ dbIdSelected: currSelection })
            }
          })
        })
        .catch(err => {
          this.setState({ loading: false })
        })
    }


  }
  componentWillUnmount = () => {
    this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
  }
  customModelData = (tempModel, viewer) => {
    return new Promise(resolve => {
      let tempCustomProperty = []
      if (Object.keys(tempModel).length > 0) {
        _.forEach(tempModel[Object.keys(tempModel)[0]]['Custom Property'], v => {
          tempCustomProperty.push({
            displayName: v.displayName,
            displayDefaultValue: v.type === 'Date' ? 'N/A' : v.type === 'Number' ? 0 : '',
            displayNewValue: null,
            type: v.type,
            defaultParameter: false,
            tempHistory: null,
            userCreated: v.userCreated,
            history: []
          })
        })
      }
      let dbIds = getAllElementdbIdsOneModel(viewer)
      let tempData = {}
      let count = dbIds.length
      let isEdited = false
      _.forEach(dbIds, (modelAdbId) => {
        viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
          let temp = {}
          let isModify = false
          let category = ''
          if (!tempModel[modelAProperty.externalId]) {
            temp = { 'Custom Property': _.clone(tempCustomProperty) }
            isModify = tempCustomProperty.length !== 0
            isEdited = tempCustomProperty.length !== 0
          } else {
            temp = tempModel[modelAProperty.externalId]
          }
          _.forEach(modelAProperty.properties, property => {
            if (property.displayName === 'Category') {
              category = property.displayValue
            }
            if (property.displayName !== 'Category' && property.displayName !== 'CategoryId' && property.displayName !== 'viewable_in' &&
              property.displayName !== 'parent' && property.displayCategory !== '__internalref__' && property.displayName !== 'label') {
              if (!temp[property.displayCategory]) {
                temp[property.displayCategory] = []
              }
              temp[property.displayCategory].push({
                displayName: property.displayName,
                displayDefaultValue: property.displayValue,
                displayNewValue: null,
                type: _.isDate(property.displayValue) ? 'Date' : _.isNumber(property.displayValue) ? 'Number' : 'Text',
                defaultParameter: true,
                tempHistory: null,
                userCreated: null,
                history: []
              })
            }
          })
          tempData[modelAProperty.externalId] = { isModify: isModify, property: temp, category: category }
          count--
          if (count === 0) {
            resolve({ data: tempData, isEdited: isEdited })
          }
        })
      })
    })
  }



  onSelectionBinded = () => {
    var currSelection = this.props.viewer.getSelection();
    if (currSelection.length === 1) {
      if (!_.isArray(this.state.dbIdSelected) && this.state.dbIdSelected === currSelection[0]) return
      // if (this.state.isEdited) {
      //   if (!window.confirm('Object still not sync to database. Are you sure to leave?')) {
      //     this.props.viewer.select(this.state.dbIdSelected)
      //     return
      //   }
      // }
      this.setState({ dbIdSelected: currSelection[0] })
      this.customProperty(this.props.viewer, currSelection[0])
    } else if (currSelection.length > 1) {
      // if (this.state.isEdited) {
      //   if (!window.confirm('Object still not sync to database. Are you sure to leave?')) {
      //     this.props.viewer.select(this.state.dbIdSelected)
      //     return
      //   }
      // }
      this.setState({ dbIdSelected: currSelection })
      this.customPropertyElements(this.props.viewer, currSelection)
    } else if (currSelection.length === 0) {
      // if (this.state.isEdited) {
      //   if (!window.confirm('Object still not sync to database. Are you sure to leave?')) {
      //     this.props.viewer.select(this.state.dbIdSelected)
      //     return
      //   }
      // }
      this.setState({
        nameElement: '', data: {}, dbIdSelected: null

      })
    }
  }
  customProperty = (viewer, element) => {
    let _this = this
    this.setState({ loading: true })
    viewer.model.getProperties(element, function (modelAProperty) {
      let name = modelAProperty.name
      let temp = {}
      let tempKey = []
      if (_this.state.localModelSaved[modelAProperty.externalId]) {
        temp = _this.state.localModelSaved[modelAProperty.externalId].property
        _.forEach(temp, (item, k) => {
          tempKey.push(k)
        })
        _this.setState({ nameElement: name, data: temp, defaultKey: tempKey, loading: false, guid: modelAProperty.externalId })
      }
    })
  }
  customPropertyElements = (viewer, elements) => {
    let _this = this
    this.setState({ loading: true })
    let count = elements.length
    let temp = {}
    let tempKey = []
    let tempCategoryObjects = {}
    let tempGuid = []
    _.forEach(elements, (element, key) => {
      viewer.model.getProperties(element, function (modelAProperty) {
        if (_this.state.localModelSaved[modelAProperty.externalId]) {
          let name = modelAProperty.name
          let tempForSimilar = {}
          tempGuid.push(modelAProperty.externalId)
          let category = _this.state.localModelSaved[modelAProperty.externalId].category
          if (!tempCategoryObjects[category])
            tempCategoryObjects[category] = []
          tempCategoryObjects[category].push({ label: name, value: element, group: category })
          _.forEach(_this.state.localModelSaved[modelAProperty.externalId].property, (property, k) => {
            if (key === 0) {
              _.forEach(property, v => {
                if (!temp[k]) {
                  temp[k] = []
                }
                temp[k].push({
                  displayName: v.displayName,
                  displayDefaultValue: v.displayDefaultValue,
                  displayNewValue: v.displayNewValue,
                  type: v.type,
                  defaultParameter: v.defaultParameter,
                  history: [],
                  userCreated: null,
                  tempHistory: null
                })
              })
            } else {
              let flatten = _.flatMap(temp)
              _.forEach(property, v => {
                let index = _.findIndex(flatten, (o) => { return o.displayName === v.displayName })
                if (index >= 0) {
                  if (!tempForSimilar[k]) {
                    tempForSimilar[k] = []
                    tempKey.push(k)
                  }
                  tempForSimilar[k].push({
                    displayName: v.displayName,
                    displayDefaultValue: v.displayDefaultValue === flatten[index].displayDefaultValue ? flatten[index].displayDefaultValue :
                      v.type === 'Date' ? null : v.type === 'Number' ? 0 : '',
                    displayNewValue: v.displayNewValue === flatten[index].displayNewValue ? flatten[index].displayNewValue :
                      v.type === 'Date' ? null : v.type === 'Number' ? 0 : '',
                    type: v.type,
                    defaultParameter: v.defaultParameter,
                    history: [],
                    userCreated: null,
                    tempHistory: null
                  })
                }
              })
            }
          })
          if (key !== 0)
            temp = tempForSimilar
          count--
          if (count === 0) {
            let tempObjects = []
            _.forEach(tempCategoryObjects, (item, k) => {
              let tempId = []
              _.forEach(item, v => {
                tempId.push(v.value)
              })
              tempObjects.push({ label: k, value: tempId, children: item })
            })
            _this.setState({
              nameElement: `Objects (${elements.length})`, data: tempForSimilar, defaultKey: tempKey, loading: false, guid: tempGuid,
              checkPickerDataObjects: tempObjects, objectPicked: elements
            })
          }
        }
      })
    })
  }
  handleChangeParameterValue = (index, key, value) => {
    let temp = this.state.data
    let check = true
    let type = temp[key][index].type
    let tempValue = ''
    let isEdited = false
    if (type === 'Number') {
      let tempTrim = value.replace(',', '')
      tempValue = _.toNumber(tempTrim)
      if (_.isNaN(tempValue)) {
        check = false
      }
    } else if (type === 'Text') {
      tempValue = value
    }
    if (check) {
      if (_.isArray(this.state.guid)) {
        _.forEach(this.state.guid, id => {
          if (this.state.localModelSaved[id]) {
            if (this.state.localModelSaved[id].property[key][index].displayDefaultValue !== tempValue) {
              this.state.localModelSaved[id].property[key][index].tempHistory = {
                oldValue: this.state.localModelSaved[id].property[key][index].displayDefaultValue,
                newValue: tempValue,
                date: moment().format('DD-MM-YYYY HH:mm'),
                userEdited: this.props.email
              }
              this.state.localModelSaved[id].property[key][index].displayNewValue = tempValue
              isEdited = true
            }
            this.state.localModelSaved[id].isModify = true
          }
        })
        this.setState({ data: temp, isEdited: isEdited }, () => {
          var currSelection = this.props.viewer.getSelection()
          this.customPropertyElements(this.props.viewer, currSelection)
        })
      } else {
        if (temp[key][index].displayDefaultValue !== tempValue) {
          temp[key][index].tempHistory = {
            oldValue: temp[key][index].displayDefaultValue,
            newValue: tempValue,
            date: moment().format('DD-MM-YYYY HH:mm'),
            userEdited: this.props.email
          }
          temp[key][index].displayNewValue = tempValue
          isEdited = true
        }
        this.state.localModelSaved[this.state.guid].isModify = true
        this.setState({ data: temp, isEdited: isEdited })
      }
    } else {
      message.warn(`Can't change value because type of parameter is ${type}`)
    }
  }
  handleChangeParameterDate = (index, key, date) => {
    let temp = this.state.data
    let isEdited = false
    if (_.isArray(this.state.guid)) {
      _.forEach(this.state.guid, id => {
        if (this.state.localModelSaved[id]) {
          if (this.state.localModelSaved[id].property[key][index].displayDefaultValue !== moment(date).format('DD-MM-YYYY')) {
            this.state.localModelSaved[id].property[key][index].tempHistory = {
              oldValue: this.state.localModelSaved[id].property[key][index].displayDefaultValue,
              newValue: moment(date).format('DD-MM-YYYY'),
              date: moment().format('DD-MM-YYYY HH:mm'),
              userEdited: this.props.email
            }
            this.state.localModelSaved[id].property[key][index].displayNewValue = moment(date).format('DD-MM-YYYY')
            isEdited = true
          }
          this.state.localModelSaved[id].isModify = true
        }
      })
      this.setState({ data: temp, isEdited: isEdited }, () => {
        var currSelection = this.props.viewer.getSelection()
        this.customPropertyElements(this.props.viewer, currSelection)
      })
    } else {
      if (temp[key][index].displayNewValue !== moment(date).format('DD-MM-YYYY')) {
        temp[key][index].tempHistory = {
          oldValue: temp[key][index].displayDefaultValue,
          newValue: moment(date).format('DD-MM-YYYY'),
          date: moment().format('DD-MM-YYYY HH:mm'),
          userEdited: this.props.email
        }
        temp[key][index].displayNewValue = moment(date).format('DD-MM-YYYY')
        isEdited = true
      }
      this.state.localModelSaved[this.state.guid].isModify = true
      this.setState({ data: temp, parameterValue: date, isEdited: isEdited })
    }
  }
  handleCloseViewPanel = () => {

    this.props.onDisplayPanelChange('customPropertyPanel', false)
  }


  //#region //? add parameter panel
  handleAddParameter = () => {
    let temp = this.state.data
    _.forEach(this.state.localModelSaved, item => {
      let index = _.find(item.property[this.state.parameterGroup], (o, k) => { return o.displayName === this.state.parameterName })
      if (!index) {
        item.property[this.state.parameterGroup].push({
          displayName: this.state.parameterName,
          displayDefaultValue: this.state.parameterType === 'Date' ? null : this.state.parameterType === 'Number' ? 0 : '',
          displayNewValue: this.state.parameterType === 'Date' ? null : this.state.parameterType === 'Number' ? 0 : '',
          type: this.state.parameterType,
          defaultParameter: false,
          userCreated: this.props.email,
          tempHistory: null,
          history: []
        })
        item.isModify = true
      } else {
        message.warn(`Parameter [${this.state.parameterName}] was already existing`)
        return false
      }
    })
    this.setState({ data: temp, openAddParameterPanel: false, isEdited: true }, () => {
      var currSelection = this.props.viewer.getSelection();
      if (currSelection.length === 1) {
        this.customProperty(this.props.viewer, currSelection[0])
        this.setState({ dbIdSelected: currSelection[0] })
      } else if (currSelection.length > 1) {
        this.customPropertyElements(this.props.viewer, currSelection)
        this.setState({ dbIdSelected: currSelection })
      }
    })
  }
  handleOpenAddParameterPanel = (key) => {
    this.setState({ openAddParameterPanel: true, parameterGroup: key, parameterName: null, parameterType: 'Text' })
  }
  handleCloseAddParameterPanel = () => {
    this.setState({ openAddParameterPanel: false })
  }
  handleChangeParameterType = (e, { value }) => {
    this.setState({ parameterType: value })
  }
  handleChangeParameterName = (e) => {
    this.setState({ parameterName: e.target.value })
  }
  //#endregion

  //#region //? setting
  handleChangeDecimal = (e) => {
    let temp = e.target.value.replace(/[^0-9]/g, '')
    this.setState({ decimalCount: temp > 4 ? 4 : temp })
  }
  handleShowAllProperty = () => {
    this.setState({ isShowAllProperty: !this.state.isShowAllProperty })
  }
  handleSyncModel = () => {
    this.setState({ loading: true })
    this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
    axios.get("/api/customproperty/get-custom-model", { params: { itemId: this.props.itemId } })
      .then(res => {
        workerInstance.addEventListener('message', this.callbackWebWorker)
        workerInstance.addEventListener('error', this.callbackFailWebWorker)
        workerInstance.calcModel(this.state.localModelSaved, res.data.model)

      })
      .catch(err => {
        message.error('Sync file failed')
        this.setState({ loading: false })
        this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
      })

  }
  callbackFailWebWorker = (e) => {
    workerInstance.removeEventListener('message', this.callbackWebWorker)
    message.error('Sync file failed')
    this.setState({ loading: false })
  }
  callbackWebWorker = (e) => {
    workerInstance.removeEventListener('message', this.callbackWebWorker)
    if (Object.keys(e.data).length === 0) {
      message.error('Sync file failed')
      this.setState({ loading: false })
      return
    }
    axios.post('/api/customproperty/save-custom-model', { itemId: this.props.itemId, model: e.data })
      .then(async res => {
        let tempData = await this.customModelData(res.data.model, this.props.viewer)
        this.setState({ localModelSaved: tempData.data, loading: false, isEdited: tempData.isEdited }, () => {
          message.success('Sync file success')
          var currSelection = this.props.viewer.getSelection();
          if (currSelection.length === 1) {
            this.customProperty(this.props.viewer, currSelection[0])
          } else if (currSelection.length > 1) {
            this.customPropertyElements(this.props.viewer, currSelection)
          } else {
            this.setState({ nameElement: '', data: {}, dbIdSelected: null })
          }
          this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
        })
      })
      .catch(err => {
        message.error('Sync file failed')
        this.setState({ loading: false })
        this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionBinded)
      })
  }
  //#endregion

  // handleSaveElement = () => {
  //   let _this = this
  //   let temp = this.state.localModelSaved
  //   if (!_.isArray(this.state.dbIdSelected)) {
  //     this.props.viewer.impl.model.getProperties(this.state.dbIdSelected, function (modelAProperty) {
  //       temp[modelAProperty.externalId] = _this.state.data
  //       _this.setState({ localModelSaved: temp, isEdited: false })
  //     })
  //   }
  // }

  handleObjectPicked = (value) => {
    if (value === null)
      value = []
    this.setState({ objectPicked: value }, () => {
      this.props.viewer.select(value)
    })
  }

  handleOpenRemoveParameterPanel = () => {
    let temp = _.values(this.state.localModelSaved)
    this.setState({ openRemoveParameterPanel: true, removeParameter: temp[0].property['Custom Property'] })
  }
  handleCloseRemoveParameterPanel = () => {
    this.setState({ openRemoveParameterPanel: false })
  }
  handleRemoveParameter = (name) => {
    // let temp = this.state.localModelSaved
    // _.forEach(temp, item => {
    //   let index = _.findIndex(item.property['Custom Property'], o=>{ return o.displayName ===name })
    //   item.property['Custom Property'].splice(index,1)
    // })
    this.setState({ loading: true })
    axios.post('/api/customproperty/delete-custom-model', { itemId: this.props.itemId, parameterName: name })
      .then(async res => {
        let tempData = await this.customModelData(res.data.model, this.props.viewer)
        this.setState({ localModelSaved: tempData.data, loading: false, isEdited: tempData.isEdited, openRemoveParameterPanel: false }, () => {
          message.success('Delete parameter success')
          var currSelection = this.props.viewer.getSelection();
          if (currSelection.length === 1) {
            this.customProperty(this.props.viewer, currSelection[0])
          } else if (currSelection.length > 1) {
            this.customPropertyElements(this.props.viewer, currSelection)
          } else {
            this.setState({ nameElement: '', data: {}, dbIdSelected: null })
          }
        })
      })
      .catch(err => {
        message.error('Delete file failed')
        this.setState({ loading: false })
      })
  }
  render() {
    const parameterType = [
      { key: 'Text', text: 'Text', value: 'Text', },
      { key: 'Number', text: 'Number', value: 'Number', },
      { key: 'Date', text: 'Date', value: 'Date', },
    ]
    const content = (k) => {
      return (
        <Panel header={k} key={k}  >
          {this.state.data[k].map((i, index) =>
            <Row type="flex" justify="space-around" align="middle" style={{ height: '100%' }}>
              <Col style={{ backgroundColor: i.defaultParameter ? '#e6e6e6' : '#64a9ff', paddingLeft: 2, height: 30 }} span={10}>
                <Paragraph className='custom-parameter-name' style={{ top: 5, position: 'absolute', width: 'calc(100% - 35px)' }} strong
                  title={i.displayName} ellipsis >{i.displayName}</Paragraph>
                {i.history.length > 0 &&
                  <Whisper trigger="click" placement={'leftStart'}
                    speaker={
                      <Popover title={i.userCreated === null ? 'Default Parameter' : `Parameter Created by ${i.userCreated}`} >
                        <List size="small" style={{ marginRight: 5 }} size='small'
                          dataSource={i.history}
                          renderItem={item => (
                            <List.Item id={item.id} >
                              <List.Item.Meta
                                title={<Text>{`${item.oldValue === null ? 'N/A' : item.oldValue} -> ${item.newValue === null ? 'N/A' : item.newValue}`} </Text>}
                                description={
                                  <Text>{`Edited by ${item.userEdited} at ${item.date} `} </Text>} />
                              <div>{item.time}</div>
                            </List.Item>
                          )}
                        >

                        </List>
                      </Popover>
                    }>
                    <IconButton icon={< rsuite.Icon icon="history" />} style={{ float: 'right', top: 3, marginRight: 2 }} size='xs' />
                  </Whisper>
                }
              </Col>
              <Col style={{ paddingLeft: 2 }} span={14}>
                {i.type === 'Date'
                  ? <DatePicker className={i.displayNewValue === null ? 'custom-property-date-old' : 'custom-property-date-new'} onChange={this.handleChangeParameterDate.bind(this, index, k)}
                    title={i.tempHistory === null ? i.displayDefaultValue :
                      `${i.tempHistory.oldValue === null ? 'N/A' : i.tempHistory.oldValue} -> ${i.tempHistory.newValue === null ? 'N/A' : i.tempHistory.newValue}`}
                    format='DD-MM-YYYY' appearance="subtle" size='xs' placement="leftStart" toggleComponentClass={rsuite.Button}
                    value={(i.displayDefaultValue !== null || i.displayNewValue !== null) && moment(i.displayNewValue === null ? i.displayDefaultValue : i.displayNewValue, 'DD-MM-YYYY')} cleanable={false} block />
                  : i.defaultParameter ?
                    <Paragraph title={i.tempHistory === null ? i.displayDefaultValue : `${i.tempHistory.oldValue} -> ${i.tempHistory.newValue}`}
                      ellipsis className='custom-parameter-name'
                      style={{ color: i.displayNewValue === null ? 'black' : 'red', paddingLeft: 8 }} >
                      {_.isNumber(i.displayDefaultValue) ? formatNumber(i.displayNewValue === null ? i.displayDefaultValue : i.displayNewValue, this.state.decimalCount) :
                        i.displayNewValue === null ? i.displayDefaultValue : i.displayNewValue}</Paragraph>
                    :
                    <Paragraph title={i.tempHistory === null ? i.displayDefaultValue : `${i.tempHistory.oldValue} -> ${i.tempHistory.newValue}`}
                      ellipsis className='custom-parameter-name'
                      style={{ color: i.displayNewValue === null ? 'black' : 'red', paddingLeft: 8 }}
                      editable={{ onChange: this.handleChangeParameterValue.bind(this, index, k) }} >
                      {_.isNumber(i.displayDefaultValue) ? formatNumber(i.displayNewValue === null ? i.displayDefaultValue : i.displayNewValue, this.state.decimalCount) :
                        i.displayNewValue === null ? i.displayDefaultValue : i.displayNewValue}</Paragraph>}
              </Col>
            </Row>
          )}
        </Panel>

      )
    }
    return (
      <div>
        <Drawer backdrop={false} show={this.props.displayPanel} onHide={this.handleCloseViewPanel} keyboard={false}
          placement='right' size='xs'>
          <Drawer.Header>

            <Drawer.Title >
              <ButtonGroup size="sm">

                {/* <IconButton icon={< rsuite.Icon icon="save" />} size='sm' onClick={this.handleSaveElement.bind(this)} color={this.state.isEdited && 'blue'} disabled={!this.state.isEdited} /> */}
                {_.isArray(this.state.dbIdSelected) &&
                  <Whisper trigger="click" placement={'leftStart'}
                    speaker={
                      <Popover title={'Settings'} trigger="click">
                        <Tooltip placement="top" title={'Hold Crrl to pick'}>
                          <CheckTreePicker data={this.state.checkPickerDataObjects} style={{ width: 300 }} size="xs" placeholder="Objects was picked"
                            onChange={this.handleObjectPicked} maxHeight={320} placement='bottomEnd'
                            cascade={true} value={this.state.objectPicked}
                          />
                        </Tooltip>
                      </Popover>
                    }>
                    <IconButton icon={< rsuite.Icon icon="list" />} size='sm' />
                  </Whisper>
                }
              </ButtonGroup>

              {' '}
              <Paragraph ellipsis title={this.state.nameElement === '' ? 'No Element' : this.state.nameElement} style={{ display: 'inline' }}>
                {this.state.nameElement === '' ? 'No Element' : this.state.nameElement} </Paragraph>

            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body id='customPropertyDrawer'>
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            {Object.keys(this.state.data).length !== 0 ?
              <Collapse defaultActiveKey={this.state.defaultKey} >
                {Object.keys(this.state.data).map(k =>
                  this.state.isShowAllProperty ? content(k) : k === 'Custom Property' && content(k)
                )}
              </Collapse> :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    Please, select at least one object
                  </span>
                } />}
          </Drawer.Body>
          <Drawer.Footer style={{ marginBottom: 5, position: 'absolute', bottom: 0, right: 5 }}>
            <ButtonGroup size="md">
              <IconButton icon={< rsuite.Icon icon="minus-square-o" />} size='md' onClick={this.handleOpenRemoveParameterPanel} />
              <IconButton icon={< rsuite.Icon icon="plus-square-o" />} size='md' onClick={this.handleOpenAddParameterPanel.bind(this, 'Custom Property')} />
              <Popconfirm title="Are you sure want to sync ?"
                onConfirm={this.handleSyncModel.bind(this)} okText="Yes" cancelText="No" placement='topRight'
              >
                <IconButton icon={< rsuite.Icon icon="cloud" />} size='md' color={this.state.isEdited && 'blue'} disabled={!this.state.isEdited} />
              </Popconfirm>

              <Whisper trigger="click" placement={'leftEnd'}
                speaker={
                  <Popover title={'Settings'} trigger="click">
                    <Input type={'number'} addonBefore="Decimal" value={this.state.decimalCount} min={0} max={4} step={1} pattern="[0-9]{10}"
                      onChange={this.handleChangeDecimal} />
                    <p />
                    <Switch checkedChildren={<Text strong>Show All Property</Text>} unCheckedChildren={<Text style={{ color: 'white' }} strong>Show Custom Property</Text>}
                      checked={this.state.isShowAllProperty}
                      onChange={this.handleShowAllProperty} />
                  </Popover>
                }>
                <IconButton icon={< rsuite.Icon icon="cog" />} size='md' />
              </Whisper>
            </ButtonGroup>

          </Drawer.Footer>
        </Drawer>
        <Modal backdrop="static" show={this.state.openAddParameterPanel} onHide={this.handleCloseAddParameterPanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Add New Parameter to {this.state.parameterGroup}</Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyCompareVersion' >
            <Dropdown
              placeholder='Parameter Type' fluid selection
              onChange={this.handleChangeParameterType}
              options={parameterType} defaultValue={parameterType[0].value}
            />
            <p />
            <Input addonBefore="Parameter Name" value={this.state.parameterName} onChange={this.handleChangeParameterName} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleAddParameter.bind(this)} color='blue' disabled={this.state.parameterName === null}
              loading={this.state.loading}>
              Apply
            </Button>
            <Button onClick={this.handleCloseAddParameterPanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" show={this.state.openRemoveParameterPanel} onHide={this.handleCloseRemoveParameterPanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Remove Parameter from {this.state.parameterGroup}</Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyCompareVersion' >
            < List size="small" bordered style={{ overflow: 'auto' }}
              dataSource={this.state.removeParameter}
              renderItem={item =>
                <List.Item>
                  <Text strong>{item.displayName}</Text>
                  <Popconfirm title="Are you sure want to delete ?"
                    onConfirm={this.handleRemoveParameter.bind(this, item.displayName)} okText="Yes" cancelText="No" placement='topRight'
                  >
                    <IconButton icon={< rsuite.Icon icon="trash" />} style={{ float: 'right' }} color='red' size='sm' />
                  </Popconfirm>
                </List.Item>
              }
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={this.handleAddParameter.bind(this)} color='blue' disabled={this.state.parameterName === null}
              loading={this.state.loading}>
              Apply
            </Button>
            <Button onClick={this.handleCloseAddParameterPanel.bind(this)} color='red'>
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>

    );
  }
}

SidebarCustomProperty.propTypes = {
  email: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}
export default connect(mapStateToProps)(SidebarCustomProperty)

