import React from 'react';
import stc from 'string-to-color'
import { Tooltip, Avatar, Typography, Select } from 'antd';
import { Icon } from 'rsuite';
import { sliceString } from '../function/DialogFunction'
import { handleReactTableFilterDate, handleReactTableFilterArrayString } from '../function/TableFunction'
import _ from 'lodash'
import moment from 'moment';
import { MenuProvider, animation } from 'react-contexify';


const { Text } = Typography

export default  (itemId, check = false) => {
  return ([
    {
      Header: 'Label', accessor: 'id', id: 'id', units: false, aggregate: 'count',
      unableGroup: true, filter: "includes", widthLocal: 50, alignFilter: 'bottomLeft',  minWidthLocal: 50, maxWidthLocal: 50,
      Cell: ({ cell: { row, value } }) => {
        return (
          <>
            {!row.isGrouped ?
              ((row.original.mainModel.itemId === itemId || check) ?
                <MenuProvider id="issue_menu" animation={animation.zoom} data={{ item: row.original }}>
                  <Text style={{ cursor: 'pointer', fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{' '}{value}</Text>
                </MenuProvider> :
                <Text style={{ cursor: 'pointer',  fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{' '}{value}</Text>)
              : value
            }
          </>
        )
      },
    },
    {
      Header: 'Priority', accessor: 'priority', id: 'priority', units: false, aggregate: 'count', filter: "includes",
      widthLocal: 40, alignLocal: 'left',  minWidthLocal: 40, maxWidthLocal: 40,
      Cell: ({ cell: { row, value } }) => {
        return (
          (!_.isNumber(value)) &&
          <>
            <Icon icon='exclamation-triangle' style={{
              color: value === 'Very urgent' ? 'red' :
                value === 'Urgent' ? 'orange' : value === 'Normal' ? '#f4d90b' : 'blue'
            }} />
            <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{' '}{sliceString(value, 6)}</Text>
          </>
        )
      },
    },
    {
      Header: 'Discipline', accessor: 'discipline', id: 'discipline', units: false, aggregate: 'count',
      widthLocal: 50, alignLocal: 'left', type: 'array',
      Cell: ({ cell: { row, value } }) => {
        let temp = _.isArray(value) && value.join(', ')
        return (
          _.isArray(value) &&
          <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{sliceString(temp)}</Text>

        )
      },
      filter: handleReactTableFilterArrayString
    },
    {
      Header: 'Title', accessor: 'titleViewPoint', id: 'titleViewPoint', units: false, filter: "includes", widthLocal: 150,
      unableGroup: true, alignLocal: 'left',
      Cell: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped &&
          <Tooltip title={value} mouseEnterDelay={2}>
            <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{sliceString(value)}</Text>
          </Tooltip>
        )
      }
    },
    {
      Header: 'Description', accessor: 'descriptionViewPoint', id: 'descriptionViewPoint', units: false, aggregate: 'count', filter: "includes",
      unableGroup: true, alignLocal: 'left',
      Cell: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped &&
          <Tooltip title={(
            <Text style={{ whiteSpace: 'pre-wrap', color: 'white' }}> {value}</Text>
          )} >
            <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }} >{sliceString(value, 60)}</Text>
          </Tooltip>
        )
      }
    },
    {
      Header: 'Tag', accessor: 'tags', id: 'tags', units: false,  type: 'array', unableGroup: true, alignLocal: 'left',
      widthLocal: 130,  minWidthLocal: 130, maxWidthLocal: 130,
      Cell: ({ cell: { row, value } }) => {
        return (
          ((value && value.length !== 0) ?
            <Tooltip title={value.join(', ')} placement='left'>
              <Select mode="tags" style={{ width: '100%' }} placeholder="Tags" value={value} maxTagCount={1} className='columnIssueTag' disabled
                size={'small'} maxTagTextLength={20} removeIcon={() => <></>} showArrow={false} />
            </Tooltip>
            : <></>)

        )
      },
      filter: handleReactTableFilterArrayString
    },
    {
      Header: 'Completion', accessor: 'percent', id: 'percent', units: false, filter: "includes", widthLocal: 40,
      Cell: ({ cell: { row, value } }) => {
        return (
          value !== null &&
          <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{value}{' '}%</Text>
        )
      },
    },
    {
      Header: 'Status', accessor: 'status', id: 'status', units: false, filter: "includes", widthLocal: 40,
      Cell: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped &&
          <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{value}</Text>
        )
      }
    },
    {
      Header: 'By', accessor: 'userCreated', id: 'userCreated', units: false, aggregate: 'count', filter: "includes",
      minWidthLocal: 25, maxWidthLocal: 45, widthLocal: 45,
      Cell: ({ cell: { row, value } }) => {
        let shortCut = ''
        if (!row.isGrouped) {
          let split = value.split('_')
          if (split.length > 1) {
            shortCut = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase()
          } else {
            shortCut = value.charAt(0).toUpperCase()
          }
        } else {
          shortCut = value.charAt(0).toUpperCase()
        }

        return (
          !_.isNumber(value) &&
          <Tooltip title={value} >
            <Avatar style={{ verticalAlign: 'middle', fontSize: 10, backgroundColor: stc(value) }} size="small">
              {shortCut}
            </Avatar>
          </Tooltip>
        )
      },
      Aggregated: ({ cell: { row, value } }) => {
        let shortCut = ''
        if (!row.isGrouped) {
          let split = value.split('_')
          if (split.length > 1) {
            shortCut = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase()
          } else {
            shortCut = value.charAt(0).toUpperCase()
          }
        }

        return (
          !_.isNumber(value) &&
          <Tooltip title={value} >
            <Avatar style={{ verticalAlign: 'middle', fontSize: 10, backgroundColor: stc(value) }} size="small">
              {shortCut}
            </Avatar>
          </Tooltip>
        )
      }
    },
    {
      Header: 'Share', accessor: 'shareWith', id: 'shareWith', units: false,
      widthLocal: 60, minWidthLocal: 60, maxWidthLocal: 60, type: 'array',alignLocal: 'left',
      Cell: ({ cell: { row, value } }) => {
        let temp = _.isArray(value) && value.join(', ')
        return (
          _.isArray(value) &&
          <Tooltip title={temp} >
            <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }}>{sliceString(temp,10)}</Text>
          </Tooltip>


        )
      },
      filter: handleReactTableFilterArrayString
    },
    {
      Header: 'To', accessor: 'listAssignViewPoint', id: 'listAssignViewPoint', units: false, aggregate: 'count',
      widthLocal: 200, minWidthLocal: 200, maxWidthLocal: 200, whiteSpace: 'break-spaces', type: 'array',
      Cell: ({ cell: { value } }) => {
        let temp = []
        let nameExtend = []
        _.forEach(value, (v1, k) => {
          let index = _.findIndex(temp, o => { return o.name === v1 })
          if (index < 0) {
            let split = v1.split('_')
            if (k <= 5) {
              if (split.length > 1) {
                temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1 })
              } else {
                temp.push({ shortCut: v1.charAt(0).toUpperCase(), name: v1 })
              }
            } else {
              nameExtend.push(v1)
            }
          }
        })
        if (nameExtend.length !== 0) {
          temp.push({ shortCut: '...', name: nameExtend.join(', ') })
        }
        return (<div>
          {temp.map(v =>
            <Tooltip title={v.name}>
              <Avatar style={{ verticalAlign: 'middle', fontSize: 12, backgroundColor: v.shortCut === '...' ? 'red' : stc(v.name) }} size="small">
                {v.shortCut}
              </Avatar>
            </Tooltip>
          )}
        </div>)
      },
      Aggregated: ({ cell: { row, value } }) => {
        let temp = []
        _.forEach(value, v1 => {
          let index = _.findIndex(temp, o => { return o.name === v1 })
          if (index < 0) {
            let split = v1.split('_')
            if (split.length > 1) {
              temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1 })
            } else {
              temp.push({ shortCut: v1.charAt(0).toUpperCase(), name: v1 })
            }
          }

        })
        return (<div>
          {temp.map(v =>
            <Tooltip title={v.name}>
              <Avatar style={{ verticalAlign: 'middle', fontSize: 12, backgroundColor: stc(v.name) }} size="small">
                {v.shortCut}
              </Avatar>
            </Tooltip>
          )}
        </div>)
      },
      filter: handleReactTableFilterArrayString
    },
    {
      Header: 'Due date', accessor: 'dateViewPoint', id: 'dateViewPoint', units: false, widthLocal: 100, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        let check = value < Date.now()
        return (
          <Text style={{
            color: row.original && (row.original.status === 'Close' ? 'green' : check ? 'red' : 'green'),
            fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600'
          }}>
            {moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      Aggregated: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped && <Text strong >
            {moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    {
      Header: 'Last Modified', accessor: 'dateModified', id: 'dateModified', units: false, widthLocal: 100, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text style={{ fontWeight: (row.original && row.original.unReadIssue) ? '800' : '600' }} >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      Aggregated: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped && <Text >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
  ])
}