import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import { Func_Export_Violation_Summary_By_Sub_To_Excel } from "../../function/func_export_to_excel";

const TableSubSummary = ({ data, setSubSelected }) => {
   const columns = [
      {
         title: "Sub-con",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 140,
         ellipsis: true,
      },

      {
         title: "nos",
         dataIndex: "total",
         key: "total",
         align: "right",
         width: 40,
      },

      {
         title: "demerit point",
         dataIndex: "demerit_point",
         key: "demerit_point",
         align: "right",
         width: 60,
      },

      {
         title: "withholding amt($)",
         dataIndex: "withholding_amount",
         key: "withholding_amount",
         align: "right",
         width: 75,
      },
   ];

   return (
      <div style={{ minHeight: "50vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Sub-Contractor
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_Violation_Summary_By_Sub_To_Excel(data, "")}
            >
               <Icon type="download" />
            </Button>
         </h5>
         <Table
            dataSource={data}
            columns={columns}
            size="small"
            scroll={{ y: "50vh" }}
            pagination={false}
            rowClassName={(record, index) => (record.percent >= 9 ? "antd_table_row_red" : null)}
            onRow={(record, rowIndex) => {
               return {
                  onClick: (event) => {
                     // console.log(record.name);
                     setSubSelected(record.name);
                  }, // click row
               };
            }}
            rowKey="name"
         />
      </div>
   );
};

export default TableSubSummary;
