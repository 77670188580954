import React, { useState, useEffect } from 'react';
import { searchSupervisor, deletes, addMulti } from '../../api/employee';
import { search as searchNationality } from '../../api/nationality';
import { searchAllEmailByProject } from '../../api/common';
import {
  Table,
  Divider,
  Icon,
  Pagination,
  Modal,
  notification,
  Row,
  Col,
  Avatar,
  Input,
  Button,
  Popconfirm,
} from 'antd';
import SupervisorForm from '../Forms/SupervisorForm';
import TopActionBar from '../Common/TopActionBar';
import { connect, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import AvatarEmployee from '../Common/AvatarEmployee';
const { Column } = Table;

function Supervisor(props) {
  const [fileExcel, setFileExcel] = useState(null);
  const [searchText, setSearchText] = useState();
  const [searchInput, setSearchInput] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const [rows, setRows] = useState([]);
  const [emails, setEmails] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [id, setId] = useState(null);
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const [nationalities, setNationalities] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10,
    projectId,
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    total: 1,
  });

  useEffect(() => {
    getDatas();
  }, [searchRequest, projectId]);

  useEffect(() => {
    const getEmailsInProject = async () => {
      let res = await searchAllEmailByProject(projectId);

      let mails = res.members;
      try {
        mails.sort();
        await setEmails(mails);
      } catch {}
    };

    getEmailsInProject();
  }, [projectId]);

  useEffect(() => {
    const getNationality = async () => {
      let res = await searchNationality({ pageSize: 1000 });
      await setNationalities(res.data?.items);
    };

    getNationality();
  }, []);

  const getDatas = async () => {
    let res = await searchSupervisor(searchRequest);

    if (_.isNil(res.data?.items)) {
      await setDatas([]);
    } else {
      await setDatas(_.orderBy(res.data?.items, 'name'));
    }
    await setPagination({
      pageSize: res.data.pageSize,
      showSizeChanger: true,
      total: res.data.totalCount,
    });
  };

  const onChange = async (current, pageSize) => {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }
    setSelectedRowKeys([]);
    await getDatas();
  };

  const onSelectedRowKeysChange = (selectedRowKeys, rows) => {
    setRows(rows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleShowDialogEdit = (record) => {
    setId(record.id);
    setRecord(record);
    setIsAdd(false);
    setVisible(true);
  };

  function handleShowDialogAdd() {
    setIsAdd(true);
    setVisible(true);
  }

  function getFilters(key) {
    if (_.isArray(datas)) {
      let items = datas
        .filter((x) => x)
        .map((x) => ({
          text: x[key],
          value: x[key],
        }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  function getColumnSearchProps(dataIndex) {
    let a = {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearchByKey(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearchByKey(selectedKeys, confirm, dataIndex)}
            icon='search'
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type='search'
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex] &&
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ?? text.toString()}
          />
        ) : (
          text
        ),
    };

    return a;
  }

  function handleSearchByKey(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  function handleImport(file) {
    setFileExcel(file);
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;
      let workbook = await wb.xlsx.load(buffer);
      let datas = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex != 1) {
            let e = {
              employeeCode: row.values[1],
              employerCode: row.values[2],
              name: row.values[3],
              workPermitFinNo: row.values[4],
              company: row.values[5],
              phoneNumber: row.values[6],
              dateOnInSiteJoin: row.values[7],
              designation: row.values[8],
              nationality: row.values[9],
              age: row.values[9],
              termination: row.values[10],
              employeeType: 2,
            };

            if (e.employeeCode) {
              datas.push(e);
            }
          }
        });
      });

      await addMulti({
        projectId,
        employees: datas,
      });
      await getDatas();
    };

    return false;
  }

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: getFilters('name'),
      onFilter: (value, record) => record.employeeCode.indexOf(value) === 0,
      ...getColumnSearchProps('name'),
    },
    // {
    //   title: 'Employer Code',
    //   dataIndex: 'employerCode',
    //   key: 'employerCode',
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Report to',
      dataIndex: 'bossEmail',
      key: 'bossEmail',
      ...getColumnSearchProps('bossEmail'),
    },
    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    // {
    //   title: 'Age',
    //   dataIndex: 'age',
    //   key: 'age',
    //   width: 80,
    // },
    {
      title: 'Designation',
      key: 'designation',
      dataIndex: 'designation',
      filters: getFilters('designation'),
      onFilter: (value, record) => record.designation.indexOf(value) === 0,
      width: 140,
    },
    // {
    //   width: 80,
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "status",
    //   render: (record) => (
    //     <span>{record.status == 1 ? "Active" : "Unactive"}</span>
    //   ),
    // },
    {
      width: '120px',
      title: 'Picture',
      key: 'picture',
      render: (record) => <AvatarEmployee imageId={record.avatar} />,
    },
    {
      width: '120px',
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Icon
            onClick={() => handleShowDialogEdit(record)}
            style={{ fontSize: '20px', color: '#08c' }}
            type='edit'
          />

          <Divider type='vertical' />
          <Popconfirm
            title='Are you sure delete this supervisor?'
            onConfirm={() => handleDeleteItems([record.id])}
            okText='Yes'
            cancelText='No'
          >
            <Icon style={{ fontSize: '20px', color: '#08c' }} type='delete' />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <div>
            <TopActionBar
              handleSearch={handleSearch}
              handleShowDialogAdd={handleShowDialogAdd}
              handleDeleteItems={handleDeleteItems}
            />
            <Table
              loading={_.isNil(datas)}
              scroll={{ x: 1800, y: 500 }}
              className='antTable'
              dataSource={datas}
              bordered
              columns={columns}
              rowKey={(record) => record.id}
              pagination={false}
            ></Table>

            <Pagination
              className='mt-2 float-right'
              onChange={onChange}
              onShowSizeChange={onChange}
              total={pagination.total}
              showSizeChanger
            />

            <Modal
              title='Supervisor'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <SupervisorForm
                emails={emails}
                nationalities={nationalities}
                id={id}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Supervisor;
