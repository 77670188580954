
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Icon, Sidenav, Nav, IconStack, Loader } from 'rsuite';
import { message, Tooltip, Typography } from 'antd'
import Split from 'react-split'
import axios from "axios";
import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded, showErrorLoadDocument, setupForgeExtensionBeforeLoadedIssuePage,
  getForgeToken, replaceSpinner} from '../../../components/module_BimApp/function/ForgeFunction'
import _ from 'lodash'
import Dock from 'react-dock'
import { ColumnComment } from '../../module_BimApp/columns'
import {TableComment} from '../../module_BimApp/tables'

import {DialogCommentInformation} from '../../module_BimApp/dialog'
import {Markup2D} from '../../module_BimApp/dock_forge'
import base64url from 'base64url'
import { Intent, Position, Toaster, } from "@blueprintjs/core";

const Autodesk = window.Autodesk;
const { Text, Paragraph } = Typography

let documentId = ''
let projectId = ''
let userCreatedProject = ''
let label = ''
let check = false
let mainLoadOptions = {}
let count = 0
let elementColor = null
let viewPort = null
let version
function IssuePage(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [issue, setIssue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)

  const [disabledBtn, setDisabledBtn] = useState(false)
  const [modelLoaded, setModelLoaded] = useState(false)
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [versionModel, setVersionModel] = useState('')
  const [displayPanel, setDisplayPanel] = useState(false)
  const [displayInfoComment, setDisplayInfoComment] = useState(false)
  const [listComment, setListComment] = useState([])
  const [allListComment, setAllListComment] = useState([])
  const [guid, setGuid] = useState()
  const [isAssignToMe, setIsAssignToMe] = useState(false)
  const [comment, setComment] = useState()

  const [showStatus, setShowStatus] = useState('All')
  const [listVersion, setListVersion] = useState([])
  const toaster = useRef()
  useEffect(e => {
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    let params = base64url.decode(window.location.pathname.split('/')[2])
    let temp = params.split('/')
    projectId = temp[0]
    userCreatedProject = temp[1]
    label = temp[2]
    check = temp[3] === 'true' ? true : false
    setLoading(true)
    setModelLoaded(true)
    axios.post("/api/comment/get-comment-from-project", { projectId: projectId, label: label, email: props.email })
      .then(res => {
        setIssue(res.data.issue)
        setLoading(false)
      })
      .catch(err => {

      })
  }, [])
  useEffect(e => {
    if (issue) {
      axios.post(`/api/comment/get-model`, { item: issue.mainModel, isLastVersion: check })
        .then(res => {
          if (res.data.status === 'success') {
            mainLoadOptions = {
              applyRefPoint: true,
              modelNameOverride: res.data.name,
              isAEC: true,
              guid: res.data.guid,
              viewableID: res.data.viewableID,
              itemId: res.data.itemId,
              version: res.data.version
            };
            version = res.data.version
            setItemId(res.data.itemId)
            setVersionModel(res.data.version)
            setItemName(res.data.name)
            if (issue.mainModel.version !== res.data.version) {
              let path = base64url(`${projectId}/${userCreatedProject}/${label}/${false}`)
              toaster.current.show({
                timeout: 0,
                action: {
                  href: `${window.location.origin}/issue/${path}`,
                  text: <strong>Reload</strong>,
                },
                icon: 'warning-sign',
                intent: Intent.WARNING,
                message: (
                  <Text strong style={{ color: 'white' }}>
                    {`Issue created at version ${issue.mainModel.version}. Are you want to load original version?`}
                  </Text>
                )
              })
            }
            if (check) {
              launchViewer(res.data.objectId)
            } else {
              launchViewer(issue.mainModel.urn)
            }
          }
          else {
            message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
          }
        })
        .catch(err => {
          message.error(`Can't load model.`);
        })
    }
  }, [issue])

  const launchViewer = (urn) => {
    getForgeToken().then(token => {
      let options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d)
        viewer.start();
        setViewer(viewer)
        documentId = 'urn:' + urn;
      });
    })
  }
  useEffect(e => {
    if (viewer)
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess.bind(this, viewer), onDocumentLoadFailure);
  }, [viewer])
  const onDocumentLoadSuccess = (viewer, doc) => {

    doc.downloadAecModelData()
    let viewables = doc.getRoot().search({ 'type': 'geometry', 'guid': mainLoadOptions.guid }, true)
    if (viewables.length === 0) {
      message.warning(`View isn't existing in file, load default view`)
      viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true);
    }
    if (viewables.length !== 0) {
      viewer.loadDocumentNode(doc, viewables[0], mainLoadOptions)
        .then(i => {
          unloadForgeExtension(viewer, false)
          viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, geometryLoaded)
        })
        .catch(err => {
          showErrorLoadDocument('forgeViewer', 'Load model failed', 0)
        })
      replaceSpinner();
    } else {
      showErrorLoadDocument('forgeViewer', `File isn't contain any view`, 0)
    }
  }
  const geometryLoaded = () => {
    viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, geometryLoaded)
    viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, loadMarkUpExt)
    setModelLoaded(false)
    setupForgeExtensionAfterLoaded(viewer, null, false)
    setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)


  }
  const loadMarkUpExt = (e) => {
    if (e.extensionId === 'Autodesk.Viewing.MarkupsCore') {
      viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, loadMarkUpExt)
      let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
      setLoading(loading)
      setDisplayPanel(true)
      setGuid(viewer.impl.model.getDocumentNode().data.guid)
      axios.get("/api/comment/get-comment-unread", { params: { email: props.email } })
        .then(res => {
          let unReadData = res.data
          axios.get("/api/comment/get-item-comments", {
            params: {
              itemId: itemId, projectId: projectId
            }
          })
            .then(res => {
              if (res.data.length !== 0) {
                markupCore.show();
                let temp = []
                let tempListVersion = []
                _.forEach(res.data, v => {
                  let data = v.comment
                  if (!data.priority) {
                    data.priority = 'Low'
                  }
                  tempListVersion.push(data.mainModel.version)
                  if (viewer.impl.model.getDocumentNode().data.guid !== data.guid) return
                  data.checked = true
                  let index = _.findIndex(unReadData, e => { return e.id === data.id })
                  if (index >= 0) {
                    data.unReadIssue = !unReadData[index].issueTime ? true : data.dateModified > unReadData[index].issueTime
                    data.unReadComment = !unReadData[index].commentTime ? (data.comments.length === 0 ? false : true) :
                      (data.comments.length === 0 ? false : data.comments[data.comments.length - 1].datetime > unReadData[index].commentTime)
                  } else {
                    data.unReadIssue = true
                    data.unReadComment = data.comments.length === 0 ? false : true
                  }
                  temp.push(data)
                  markupCore.loadMarkups(data.markup, data.id)
                })
                tempListVersion = _.uniq(tempListVersion)
                setListVersion(tempListVersion.sort())
                setListComment(temp)
                setAllListComment(temp)
                setLoading(false)
              } else {
                setListComment([])
                setAllListComment([])
                setLoading(false)
              }
            })
            .catch(() => {

            })
        })
        .catch(() => {
          setListComment([])
          setAllListComment([])
          setLoading(false)
        })
    }

  }

  const onDocumentLoadFailure = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
  }



  const dragSplit = () => {
  }
  const handleInfoViewPoint = () => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    markupCore.show();
    _.forEach(listComment, v => {
      if (v.checked) {
        markupCore.loadMarkups(v.markup, v.id)
      } else {
        markupCore.unloadMarkups(v.id)
      }

    })
    setDisplayPanel(true)
  }
  const handleCloseInfoViewPointPanel = (data) => {
    if (data) {
      setListComment(data)
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    } else {
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    }
  }


  const handleChangeMarkup2DPanel = () => {
    setMarkUp2d(!markUp2d)
  }

  const handleCloseViewPanel = () => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    markupCore.hide();
    _.forEach(listComment, v => {
      markupCore.unloadMarkups(v.id)
    })
    let btn = $('#btn-markup2d')
    btn.removeClass("active")
    setDisplayPanel(false)
  }
  const handleInfoViewPointPanel = (item) => {
    setComment(item)
    setDisplayInfoComment(true)
    setDisplayPanel(false)
  }
  const handleDeleteViewPoint = (item) => {
    if (!window.confirm('Are you sure to delete this comment?')) return
    setLoading(true)
    let temp1 = []
    let tempData = item.id
    _.forEach(listComment, (v) => {
      if (v.id !== item.id) {
        temp1.push(v)
      }
    })
    let temp2 = []
    _.forEach(allListComment, (v) => {
      if (v.id !== item.id) {
        temp2.push(v)
      }
    })
    axios.post(`/api/comment/delete-comment`, { id: tempData, email: props.email })
      .then(() => {
        setListComment(temp1)
        setAllListComment(temp2)
        setLoading(false)
        message.success(`Deleted viewpoint.`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't delete viewpoint.`);
      })

  }
  const handleChangeMarkup = (item, e) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let temp = []
    item.checked = e
    _.forEach(listComment, v => {
      if (v.checked) {
        temp.push(v)
      }
    })
    if (temp.length === 0) {
      markupCore.hide();
    } else {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        } else {
          markupCore.unloadMarkups(v.id)
        }

      })
    }
    setListComment(listComment.slice(0))
  }
  const handleAssignToMe = () => {
    setIsAssignToMe(!isAssignToMe)
  }
  useEffect(() => {
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = [props.email]
        let tempNeed =[]
        _.forEach(res.data.groupUser, v => {
          _.forEach(v.members, member => {
            if (member.name === props.email) {
              temp.push(v.name)
            }
          })
        })
        _.forEach(allListComment, v => {
          let checkAssign = false
          let checkStatus =false
          if(isAssignToMe){
            _.forEach(v.listAssignViewPoint, user => {
              if (temp.includes(user)) {
                checkAssign = true
               return false
              }
            })
          }else{
            checkAssign = true
          }
          if(showStatus ==='All'){
            checkStatus= true
          }else{
            if (showStatus !== 'Close') {
              if (v.status.toLowerCase() !== 'close') {
                checkStatus= true
              }
            } else {
              if (v.status.toLowerCase() === showStatus.toLowerCase()) {
                checkStatus= true
              }
            }
          }
          if(checkAssign &&  checkStatus){
            tempNeed.push(v)
          }
        })
        setListComment(tempNeed)
      })
      .catch(() => {
        setIsAssignToMe(false)
      })
  }, [isAssignToMe,  showStatus])

  const handleAllCheck = (check) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    _.forEach(listComment, v => {
      if (v.checked)
        markupCore.unloadMarkups(v.id)
      if (v.guid === viewer.impl.model.getDocumentNode().data.guid) {
        v.checked = check
      }
    })
    if (check) {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    } else {
      markupCore.hide()
    }

    setListComment(listComment.slice(0))
  }
  const handleVersionMarkUp = (version) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')

    _.forEach(listComment, v => {
      if (v.checked) {
        v.checked = false
        markupCore.unloadMarkups(v.id)
      }
      if (v.guid === viewer.impl.model.getDocumentNode().data.guid &&  version.includes(v.mainModel.version)) {
        v.checked = true
      }

    })
    if(version.length===0){
      markupCore.hide()
    }else{
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    }

    setListComment(listComment.slice(0))
  }
  const handleShowStatus = () => {
    setShowStatus(showStatus !== 'All' ? showStatus !== 'Close' ? 'Close' : 'All' : 'Open')
  }
  const handleSearch = (value) => {
    if(value===''){
      setListComment(allListComment.slice(0))
    }else{
      let temp =[]
      _.forEach(allListComment, v => {
        if (v.descriptionViewPoint.includes(value)) {
          temp.push(v)
        }
      })
      setListComment(temp)
    }
  }
  return (
    <div>
      <Sidenav style={{ position: 'absolute', zIndex: '100', height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`, backgroundColor: 'white', width: '40px' }}
        expanded={false}
      >
        <Sidenav.Body>
          <Nav >
            <Tooltip title="Comment" placement="right">
              <Nav.Item eventKey="1" style={{ height: '40px', width: '40px' }}
                icon={<IconStack style={{
                  left: '11px', top: '10px', height: '40px', display: 'contents', color:
                    !modelLoaded && '#636363'
                }}  >
                  <Icon icon="file-o" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                  <Icon icon="comment" stack="1x" style={{ fontSize: 10, top: 7 }} />
                  <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                </IconStack>}
                disabled={modelLoaded} onClick={handleInfoViewPoint} />
            </Tooltip>
          </Nav>

        </Sidenav.Body>
      </Sidenav>
      <Split id='splitForgeViewer'
        sizes={[50, 50]} minSize={100} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
        style={{
          position: "absolute", height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`,
          width: "calc(100% - 40px)", display: 'flex', justifyItems: 'center', alignItems: 'center', left: '40px'
        }} onDrag={dragSplit}
      >
        <div id='content3D' style={{ height: '100%', width: "100%" }}>
          <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
          <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
          <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }}>  </div>
        </div>
        <div id='content2D' style={{ height: '100%', width: "100%" }}>
          <div id="forgeViewer2D" style={{ position: "relative", height: '100%', width: "100%" }}></div>
        </div>
      </Split>

      {displayPanel && <Dock position={'left'} isVisible={displayPanel} dimMode={'none'} fluid={false} zIndex={200} defaultSize={650}>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <TableComment
          columns={ColumnComment()} data={listComment}
          getRowProps={() => ({})} getCellProps={() => ({})}

          handleInfoViewPoint={handleInfoViewPointPanel}
          onActiveTable={handleCloseViewPanel}
          handleDeleteViewPoint={handleDeleteViewPoint}
          handleChangeMarkup={handleChangeMarkup}
          handleAssignToMe={handleAssignToMe}
          handleAllCheck={handleAllCheck}
          isAssignToMe={isAssignToMe}

          viewer={viewer}
          isProject={false}
          newTab={true}

          itemId={props.itemId}
          guid={guid}

          showStatus={showStatus}
          handleShowStatus={handleShowStatus}
          handleVersionMarkUp={handleVersionMarkUp}
          listVersion={listVersion}
          handleSearch={handleSearch}
        />

      </Dock>}

      {displayInfoComment &&
        <DialogCommentInformation
          open={displayInfoComment}
          handleClose={handleCloseInfoViewPointPanel}
          viewer={viewer}
          viewPoint={comment}
          email={props.email}
          projectId={projectId}
          userCreatedProject={userCreatedProject}
          listSaveView={listComment}
          // handleAttachFile={this.handleAttachFile}
          itemId={props.itemId}
          isProject={false}
          newTab={true}

        />
      }
      {markUp2d && <Markup2D openDock={markUp2d} viewer={viewer}
        onChangeDockDisplay={handleChangeMarkup2DPanel} />}

      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />


    </div>
  );
}

IssuePage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(IssuePage)