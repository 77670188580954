import React from 'react';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { message } from 'antd';
import { Button, Modal, Loader } from 'rsuite'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";


const styles = theme => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 100,
  },
});




class ButtonMenuProject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      open: false,
      hidden: false,
      openDialogAdd: false,
    }

  }

  //setting button
  handleClick = () => { this.setState({ open: (open => !open) }) };
  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({ open: true })
    }
  };
  handleClose = () => { this.setState({ open: false }) };

  //setting dialog add
  handleCloseDialogAdd = () => { this.setState({ openDialogAdd: false }) };
  handleClickOpenDialogAdd = () => { this.setState({ openDialogAdd: true }) }
  _onChange = (e) => { this.setState({ projectName: e.target.value }) }
  createProject = () => {
    this.setState({ loading: true })
    axios.post(`/api/projects/addproject`, {
      project: { projectName: this.state.projectName },
      user: this.props.user
    })
      .then(() => {
        window.location.reload()
        // message.success(`Add project: [${this.state.projectName}] successful`)
        // this.props.changeProject(res.data)
        // this.setState({openDialogAdd: false})
      })
      .catch(err => {
        message.error(err.response.data.errors.projectId)
        this.setState({ openDialogAdd: false, loading: false })
      })
  };



  render() {
    const { classes } = this.props;
    const actions = [
      { icon: <AddIcon />, name: 'Add Project', click: this.handleClickOpenDialogAdd },
    ];
    return (
      <>
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={this.state.hidden}
          icon={<SpeedDialIcon />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onFocus={this.handleOpen}
          open={this.state.open}>

          {actions.map(action =>
            <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen onClick={action.click}
            />
          )}
        </SpeedDial>
        {/* 
        <Dialog fullWidth={true} maxWidth={'xs'} fullHeight={true}
          open={this.state.openDialogAdd} onClose={this.handleCloseDialogAdd} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create Project</DialogTitle>
          <DialogContent>
            <DialogContentText> Input your project name</DialogContentText>

          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog> */}

        <Modal show={this.state.openDialogAdd} onHide={this.handleCloseDialogAdd} size='xs' style={{ top: 60 }}>
          <Modal.Header>
            <Modal.Title>Create Project</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <TextField onChange={this._onChange} autoFocus margin="dense" id="name" label="Project Name" type="text" ref='inputText' fullWidth />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseDialogAdd} color="ghost"> Cancel</Button>
            <Button onClick={this.createProject} color="primary" loading={this.state.loading} disabled={this.state.projectName===''}>OK</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
ButtonMenuProject.propTypes = {
  // createProject: PropTypes.func.isRequired,
  changeProject: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    userInfo: state.user
  }
}

export default withStyles(styles)(connect(mapStateToProps, null)(ButtonMenuProject));

// const styles  = theme => ({
//     fab: {
//         position: 'fixed',
//         bottom: theme.spacing(2),
//         right: theme.spacing(2),
//         zIndex: 100,
//       },
// });

    // handleClickOpen = ()=> {this.setState({open:  true})}
    // handleClose= ()=>{this.setState({open:  false})}
    // _onChange = (e)=>{this.state.projectName = e.target.value}   

        // return (
        //     <div>
        //        <Fab color="primary" aria-label="add" className={classes.fab}  label= 'Add' onClick ={this.handleClickOpen}>
        //         <AddIcon />
        //       </Fab>     
        //       <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        //         <DialogTitle id="form-dialog-title">Create Porject</DialogTitle>
        //         <DialogContent>
        //           <DialogContentText> Folder name must be of the form [-_.a-z0-9]{3,128}</DialogContentText>
        //           <TextField onChange={this._onChange} autoFocus margin="dense" id="name" label="Project Name" type="text" ref='inputText' fullWidth/>
        //         </DialogContent>
        //         <DialogActions>
        //           <Button onClick={this.handleClose} color="primary"> Cancel</Button>
        //           <Button onClick={this.createProject} color="primary">OK</Button>
        //         </DialogActions>
        //       </Dialog>
        //     </div>
        // );