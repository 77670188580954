import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setRedirectData } from '../../actions/app/app'
import axios from 'axios'
import { message } from 'antd'

function RedirectPage(props) {

    useEffect(() => {
        if (props.match?.params?.type && props.match?.params?.url) {
            let url = atob(props.match?.params?.url)
            localStorage.setItem('idd_redirect_type',props.match?.params?.type )
            localStorage.setItem('idd_redirect_url',url )
            if (props.isAuthenticated) {
                axios.get("/api/auth/redirect_token", { params:{ token: props.token} })
                    .then(res => {
                        console.log(res)
                       window.location.replace(`${url}/idd_redirect/${res.data.token}`)
                    })
                    .catch(() => {
                        props.history.push('/')
                    })
            } else {
                props.history.push('/')
            }
        } else {
            props.history.push('/')
        }
    }, [])
    return (
        <div>

        </div>
    )
}

RedirectPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    setRedirectData: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.email,
        email: state.user.email,
        token: state.user.token,
    };
}
export default connect(mapStateToProps, { setRedirectData: setRedirectData })(RedirectPage);