import React from 'react';
import _ from 'lodash'
import ReactTable from './ReactTable'
function TableUsage(props) {


  return (
    
     <ReactTable
     {...props}
     haveFooter ={false}
     id={'tableUsage'}
   />
  )
}

export default TableUsage