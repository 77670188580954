import { Checkbox, Icon, Select, Tooltip, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { generateShortUid, getRefStringWithVersion, mongoObjectId } from '../../../utils';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { getConsultantReplyData, isColumnWithReplyData } from '../pageSpreadsheet/CellRFA';
import { getKeyTextForSheet } from '../pageSpreadsheet/PanelSetting';
import ButtonGroupComp from './ButtonGroupComp';
import ButtonStyle from './ButtonStyle';



const { Option } = Select;

const { TextArea } = Input;



const ListRequirementForm = ({ listGeneralData, setListGeneralData, formRefType, refType }) => {


   // if (!formRefType) return <></>; // TEMP_FIX render issue, this comp rerender after submit success
   // let isFormReply = formRefType.includes('-reply-'); // do not use include =>>> later formRefType undefined


   let isFormReply = formRefType === 'form-reply-multi-type' || formRefType === 'form-reply-RFA';


   const widthTotalTable = window.innerWidth * 0.9 - 80;
   const widthIndexColumn = 40;
   const widthRemoveColumn = 40;
   const widthColumnText = isFormReply ? ((widthTotalTable - widthIndexColumn - widthRemoveColumn) * 0.5) : (widthTotalTable - widthIndexColumn - widthRemoveColumn);
   const borderColorTable = `1px solid ${colorType.grey3}`;

   const styleColumnIndex = {
      borderRight: borderColorTable, width: widthIndexColumn, textAlign: 'center'
   };
   const styleColumnText = {
      borderRight: borderColorTable, width: widthColumnText, textAlign: 'center'
   };
   const styleColumnRemove = {
      borderRight: borderColorTable, width: widthRemoveColumn, textAlign: 'center'
   };

   const headerStyled = {
      borderBottom: borderColorTable,
      padding: 10,
      fontSize: 13
   };


   return (

      <div style={{ marginBottom: 30, borderTop: `1px solid ${colorType.grey4}`, paddingTop: 20 }}>

         <div style={{ fontSize: 14, color: 'black', fontWeight: 'bold', marginBottom: 15 }}>{refType.toUpperCase()} General Notes (Optional)</div>

         {!isFormReply && (
            <ButtonStyle
               name='Add Notes'
               onClick={() => {
                  setListGeneralData([
                     ...listGeneralData,
                     {
                        id: generateShortUid(),
                        submission: '',
                     }
                  ]);
               }}
            />
         )}



         <table style={{ marginTop: 10 }}>
            <thead>
               <tr style={{
                  border: borderColorTable,
               }}>
                  <th style={{ ...styleColumnIndex, ...headerStyled }}>No</th>
                  <th style={{ ...styleColumnText, ...headerStyled }}>Submission Notes</th>
                  {isFormReply ? (
                     <th style={{ ...styleColumnText, ...headerStyled }}>Review</th>
                  ) : (
                     <th style={{ ...styleColumnRemove, ...headerStyled }}></th>
                  )}

               </tr>
            </thead>
            <tbody>
               {listGeneralData.map((itemList, i) => {
                  const listDataClone = listGeneralData.map(x => ({ ...x }));
                  const itemFound = listDataClone.find(x => x.id === itemList.id);

                  return (

                     <tr
                        key={i}
                        style={{
                           borderBottom: borderColorTable,
                           borderLeft: borderColorTable,
                           borderRight: borderColorTable,
                        }}
                     >
                        <td style={{ ...styleColumnIndex }}>{i + 1}</td>
                        <td style={{ ...styleColumnText, textAlign: 'left' }}>
                           {isFormReply ? (
                              <AreaListStyled
                                 style={{ width: widthColumnText }}
                                 value={itemFound['submission']}
                                 disabled={true}
                              />
                           ) : (
                              <AreaListStyled
                                 style={{ width: widthColumnText }}
                                 onChange={(e) => {
                                    itemFound['submission'] = e.target.value;
                                    setListGeneralData(listDataClone);
                                 }}
                                 value={itemFound['submission']}
                              />
                           )}

                        </td>


                        {isFormReply ? (
                           <td style={{ ...styleColumnText, textAlign: 'left' }}>
                              <AreaListStyled
                                 style={{ width: widthColumnText }}
                                 onChange={(e) => {
                                    itemFound['reply'] = e.target.value;
                                    setListGeneralData(listDataClone);
                                 }}
                                 value={itemFound['reply']}
                              />
                           </td>
                        ) : (
                           <td style={{ ...styleColumnRemove }}>
                              <Icon
                                 type='close'
                                 onClick={() => setListGeneralData(listDataClone.filter(x => x.id !== itemList.id))}
                              />
                           </td>
                        )}

                     </tr>

                  );
               })}
            </tbody>
         </table>


      </div>
   );
};
export default ListRequirementForm;

const AreaListStyled = styled(TextArea)`
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   border-bottom: none;
   border-radius: 0;
   background: #F5F5F5;
   &:focus {
      outline: none;
      box-shadow: none;
   };

   &:disabled {
      background: #F5F5F5;
      color: black;
      overflow: hidden;
   }
`;

