import React from 'react';
import _ from 'lodash'

import 'chartjs-plugin-datalabels';

import axios from "axios";
import Draggable from 'react-draggable';
import { message, } from 'antd';
import { Loader, Whisper, Popover, Modal, IconButton, Button, ButtonGroup, Icon, DatePicker } from 'rsuite';
import Tooltip from '@material-ui/core/Tooltip';
//!
import { convertHexColorToVector4 } from '../../../function/TableFunction'
import { getAllElementdbIdsOneModel } from '../../../function/ForgeFunction'
import { timelineChangeProgress, setDateByPanel ,handleModulePPVCAndPrecast} from '../../../function/ProgressFunction'
import PieChartPPVC from './PieChartPrecast'
import PPVCTable from '../PPVCTable'
import moment from 'moment'
import * as Timeline from "vis-timeline/standalone";
import { trackingUserUsing } from '../../../function/AdminFunction'


const timeline = Timeline

class ProgressPrecastChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllElement: false,
      timeValue: {
        min: new Date().getTime(),
        max: new Date().getTime()
      },
      isShowList: false,
      openDock: false,
      //!
      generatedData: null,
      loading: false,
      isRunTimeline: false,
      openPortalEnd: false,
      openPortalStart: false,
      modeDislpayTimeLine: 'M',
      tableDetail: [],
      openPieChart: false,
      dbIds: [],
      currentTime: null,
      actionMinDate: null,
      actionMaxDate: null,
    };
    this.dataPPVC = []
    this.modelData = {
      dbIds: [],
      data: []
    }
    this.timelinepanel = null
    this.groups = null
    this.items = null
  }
  componentWillMount = () => {
    trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'Progress Precast')
    this.getModules()
  }


  componentWillReceiveProps = () => {
    // if (nextProps.openDock && onceTime) {
    //   onceTime = false

    //   this.setState({ loading: true, actionMaxDate: null, actionMinDate: null, showAllElement: true, generatedData: null }, () => {

    //   })

    // } else if (!nextProps.openDock && !onceTime && !this.state.openPortalEnd && !this.state.openPortalStart) {
    //   onceTime = true
    //   nextProps.viewer.showAll()
    //   nextProps.viewer.clearThemingColors(nextProps.viewer.model)
    //   this.setState({ loading: false, isShowList: false, openPieChart: false })
    // }
  }
  //#region Get data
  getModules = () => {
    this.setState({ loading: true, openDock: this.props.openDock })
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          this.moduleData = res.data.result;
          this.getModelData();
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }
  getModelData = () => {
    this.setState({ dbIds: getAllElementdbIdsOneModel(this.props.viewer) }, () => {
      if (this.state.dbIds.length > 0) {
        let count = this.state.dbIds.length
        _.forEach(this.state.dbIds, id => {
          this.props.viewer.model.getProperties(id, (modelAProperty) => {
            _.forEach(modelAProperty.properties, (v) => {
              if(this.props.projectKey==='pdd'){
                if (v.displayName === 'PCAPP-Module Code') {
                  if (v.displayValue !== '')
                    this.modelData.data.push({ dbId: id, mark: v.displayValue });
                }
              }else{
                if (v.displayName === 'Mark') {
                  if (v.displayValue !== '') {
                    this.modelData.data.push({ dbId: id, mark: v.displayValue });
                  }
                }
              }
           
            })
            count--
            if (count === 0) {
              axios.get('/api/customproperty/get-custom-ppvc', { params: { projectKey: this.props.projectKey } })
                .then(async res => {
                  this.combineData(res.data.ppvc)
                })
                .catch(() => {
                  message.error('Sync file failed')
                  this.setState({ loading: false, date: null, newCode: '' })
                })
            }
          })

        })

      }
    })

  }
  //#endregion

  //#region Combine Module and Model data
  combineData = (ppvc) => {
    _.forEach(this.moduleData, item => {
      let modelItem = this.modelData.data.find(x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        item.dbId = modelItem.dbId;     
      } else {
        item.dbId = ''        
      }
      let index = _.findIndex(ppvc, (o) => { return o.code.toLowerCase() === item.code.toLowerCase() })
      if (index >= 0) {
        if (ppvc[index].newCode !== '' &&  ppvc[index].date) {
          item.actions.push({
            actionName: `Module Installed on ${this.props.projectKey}`,
            completedDate: moment(ppvc[index].date, 'DD-MM-YYYY').toDate(),
            currentSiteCode: this.props.projectKey,
            currentSiteStorage: null
          })
        }
      }
    });
    this.analyzeMainData();
  }
  //#endregion

  //#region Analyze Module data
  analyzeMainData = () => {
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(this.moduleData, this.props.typeProject, this.props.projectKey);
    this.dataPPVC = dataPPVC
    this.dataPPVC = this.dataPPVC.sort(function (a, b) {
      return a.modifiedDate - b.modifiedDate;
    });
    this.setState({
      currentTime: moment(new Date(actionMinDate.getTime())).startOf('month').add(1, 'months'),
      actionMinDate: new Date(actionMinDate.getTime()),
      actionMaxDate: new Date(actionMaxDate.getTime()),
      timeValue: {
        min: moment(actionMinDate.getTime()).startOf('months'),
        max: moment(actionMaxDate.getTime()).endOf('months'),
      }
    }, () => {
      this.generateTimelineChart()
      this.showElementByDateRange()
      this.setState({ loading: false })
    })
  }

  playAnimation = async () => {
    if (this.state.isRunTimeline) {
      this.setState({ isRunTimeline: false })
    } else {
      let dateInterval = prompt("Please enter Date Interval", "10");
      let dateInt = parseInt(dateInterval);
      if (dateInt) {
        message.success("Start playing..")
        this.setState({ isRunTimeline: true }, async () => {
          const endTimeline = this.state.timeValue.max
          const date = new Date(this.state.timeValue.min)
          // let time = this.timelinepanel.getCustomTime('custom-timeline-progress-ppvc')
          while (this.state.isRunTimeline) {
            date.setDate(date.getDate() + dateInt);
            if (date.getTime() >= endTimeline) {
              this.setState({ currentTime: endTimeline }, () => {
                this.timelinepanel.setCustomTime(endTimeline, 'custom-timeline-progress-ppvc')
                this.showElementByDateRange()
              })
              this.setState({ isRunTimeline: false })
              break
            }
            else {
              this.setState({ currentTime: moment(date.getTime()) }, () => {
                this.timelinepanel.setCustomTime(moment(date.getTime()), 'custom-timeline-progress-ppvc')
                this.showElementByDateRange()
              })
            }
            await timer(2000);
          }
        })
      }
    }
    function timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    }
  }
  showElementByDateRange = () => {
    this.props.viewer.showAll()
    this.props.viewer.clearThemingColors(this.props.viewer.model)
    if (!this.state.showAllElement) {
      _.forEach(this.state.dbIds, v => {
        this.props.viewer.hide(v)
      })
    }
    let colorCasted = convertHexColorToVector4("#1890ff")
    let colorDelivered = convertHexColorToVector4("#f39c12")
    let colorInstalled = convertHexColorToVector4("#722ed1")


    _.forEach(this.dataPPVC, v => {
      if (v.infoData.status !== "" && v.modifiedDate !== null) {
        _.forEach(v.listTime, list => {
          if (this.state.timeValue.min.valueOf() <= list.time && this.state.currentTime.valueOf() >= list.time) {
            if (list.status === 'Casting Completed') {
              this.props.viewer.setThemingColor(v.dbId, colorCasted)
              this.props.viewer.show(v.dbId)
            } else if (list.status === 'Delivered to Site') {
              this.props.viewer.setThemingColor(v.dbId, colorDelivered)
              this.props.viewer.show(v.dbId)
            } else if (list.status === 'Installed on Site') {
              this.props.viewer.setThemingColor(v.dbId, colorInstalled)
              this.props.viewer.show(v.dbId)
            }
          }
        })

      }
    })
  }
  //#endregion
  //!
  generateTimelineChart = () => {
    let _this = this
    if (this.timelinepanel !== null)
      this.timelinepanel.destroy()
    var container = document.getElementById('timeline-progress-ppvc');
    this.groups = new timeline.DataSet();
    this.groups.add({ id: 0, content: "Casting Completed" });
    this.groups.add({ id: 3, content: "Delivered to Site" });
    this.groups.add({ id: 4, content: "Installed on Site" });


    this.items = new timeline.DataSet(this.generateItemforTimeline('M', true))
    var options = {
      width: '100%', height: '100%', horizontalScroll: true, zoomKey: 'ctrlKey', verticalScroll: true, stack: false,
      stackSubgroups: false, tooltip: { followMouse: true, overflowMethod: 'cap' },
      min: new Date(0, 1, 1), // lower limit of visible range
      max: new Date(2500, 1, 1),
      zoomMin: 1000 * 60 * 60 * 24 * 17,
    };
    this.timelinepanel = new timeline.Timeline(container, this.items, this.groups, options)
    this.timelinepanel.on('rangechanged', moveTimelineToCurrentDate);
    function moveTimelineToCurrentDate(e) {
      if (!e.byUser) {
        _this.timelinepanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
          moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
        _this.timelinepanel.off('rangechanged', moveTimelineToCurrentDate)
      }
    }
    this.timelinepanel.addCustomTime(moment(this.state.actionMinDate).startOf('month'), 'custom-timeline-start-progress-ppvc')
    this.timelinepanel.addCustomTime(moment(this.state.actionMaxDate).endOf('month'), 'custom-timeline-end-progress-ppvc')
    this.timelinepanel.addCustomTime(this.state.currentTime, 'custom-timeline-progress-ppvc')
    this.timelinepanel.on('timechange', (e) => {
      timelineChangeProgress(this.timelinepanel, this.items, e, this, 'custom-timeline-start-progress-ppvc', 'custom-timeline-end-progress-ppvc', 'custom-timeline-progress-ppvc')
    })
    this.timelinepanel.on('doubleClick', function (e) {
      if (e.what == 'item') {
        _this.handleTableDetail(_this.items.get(e.item))
      } else {
        let check = true
        if (e.customTime === 'custom-timeline-start-progress-ppvc') {
          _this.setState({ openPortalStart: true, openPortalEnd: false, openPieChart: false, openDock: false, })
          // _this.props.onChangeDockDisplay('progressDock', false)
          check = false
        } else if (e.customTime === 'custom-timeline-end-progress-ppvc') {
          _this.setState({ openPortalStart: false, openPortalEnd: true, openPieChart: false, openDock: false, })
          // _this.props.onChangeDockDisplay('progressDock', false)
          check = false
        }
        if (check)
          _this.timelinepanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
            moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
      }
    });
    this.timelinepanel.on('select', function (properties) {
      var item = _this.items.get(properties.items[0])
      let temp = []
      _.forEach(item.dbIds, v => {
        temp.push(v.id)
      })
      _this.props.viewer.select(temp)
    })

  }
  generateItemforTimeline = (check, first = false) => {
    let temp = {}
    _.forEach(this.dataPPVC, v => {
      if (v.infoData.status !== "" && v.modifiedDate !== null) {
        _.forEach(v.listTime, list => {
          let date = ''
          if (check === 'D')
            date = moment(list.time).format('YYYY-MM-DD')
          else if (check === 'M')
            date = moment(list.time).format('YYYY-MM')
          else if (check === 'Y')
            date = moment(list.time).format('YYYY')
          if (!temp[date])
            temp[date] = []
          temp[date].push({ id: v.dbId, status: list.status, info: v.infoData })
        })

      }
    })
    let tempn = [{
      id: 1,
      start: moment(new Date(0, 1, 1)).startOf('month'),
      end: first ? moment(this.state.actionMinDate).startOf('month') : this.state.timeValue.min,
      type: "background",
      className: "custom-background-start",
    },
    {
      id: 2,
      start: first ? moment(this.state.actionMaxDate).endOf('month') : this.state.timeValue.max,
      end: moment(new Date(2500, 1, 1)).startOf('month'),
      type: "background",
      className: "custom-background-end"
    }]
    let listStatus = ['Casting Completed', 'Delivered to Fit-out Yard', 'Fit Out Completed', 'Delivered to Site', 'Installed on Site']
    let count = 3
    _.forEach(temp, (item, key) => {
      _.forEach(listStatus, (v1, k1) => {
        let d = _.filter(item, (o) => { return o.status.includes(v1) })
        if (check === 'D')
          tempn.push({ id: count, start: moment(key).startOf('day'), end: moment(key).endOf('day'), content: d.length.toString(), group: k1, className: v1.replace(/\s/g, ""), dbIds: d })
        else if (check === 'M')
          tempn.push({ id: count, start: moment(key).startOf('month'), end: moment(key).endOf('month'), content: d.length.toString(), group: k1, className: v1.replace(/\s/g, ""), dbIds: d })
        else if (check === 'Y')
          tempn.push({ id: count, start: moment(key).startOf('year'), end: moment(key).endOf('year'), content: d.length.toString(), group: k1, className: v1.replace(/\s/g, ""), dbIds: d })
        count++
      })
    })
    return tempn
  }

  handleClosePCList = () => {
    this.setState({ isShowList: false })
  }
  handleShowPCList = () => {
    this.setState({ isShowList: true })
  }
  //!
  handleCloseDock = () => {
    this.props.viewer.showAll()
    //this.props.viewer.clearThemingColors(this.props.viewer.model)
    this.props.onChangeDockDisplay('progressPrecastDock', false)
    this.setState({ isShowList: false, openPieChart: false })
  }
  handleDateTimeline = (name, date) => {
    setDateByPanel(this.timelinepanel, this.items, name, date, this, 'custom-timeline-start-progress-ppvc', 'custom-timeline-end-progress-ppvc')
    this.handleClosePortal()
  }
  disabledMinDate = (current) => {
    let temp1 = moment(this.state.actionMinDate.getTime()).startOf('months')
    return current < temp1
  }
  disabledMaxDate = (current) => {
    let temp1 = moment(this.state.actionMaxDate.getTime()).endOf('months')
    return current > temp1
  }
  handleClosePortal = () => {
    this.setState({ openPortalStart: false, openPortalEnd: false, openDock: true })
    this.props.onChangeDockDisplay('progressPrecastDock', true)
  }
  handleDisplayTimeLine = (value) => {
    this.setState({ modeDislpayTimeLine: value }, () => {
      this.items.clear()
      this.items.add(this.generateItemforTimeline(this.state.modeDislpayTimeLine))
    })
  }
  handleTableDetail = (data) => {
    let temp = []
    _.forEach(data.dbIds, v => {
      temp.push(v.info)
    })
    this.setState({ tableDetail: temp, isShowList: true })
  }
  handlePieChart = () => {
    this.setState({ openPieChart: !this.state.openPieChart })
  }
  handleCloseDockPieChart = () => {
    this.setState({ openPieChart: false })
  }
  handleTableDetailAll = () => {
    let temp = []
    _.forEach(this.dataPPVC, item => {
      temp.push(item.infoData)
    })
    this.setState({ tableDetail: temp, isShowList: true })
  }
  handleDisplayElement = () => {
    this.setState({ showAllElement: !this.state.showAllElement }, () => {
      this.showElementByDateRange()
    })
  }
  handleCloseDockTable = () => {
    this.setState({ isShowList: false })
  }
  render() {
    return (
      <div>
        {this.state.openPieChart &&
          <PieChartPPVC
            dataPPVC={this.dataPPVC}
            openPieChart={this.state.openPieChart}
            viewer={this.props.viewer}
            handleCloseDockPieChart={this.handleCloseDockPieChart}
            timeValue={this.state.timeValue}
          />}
        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 420, y: 0 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.state.openDock ? 'inline' : 'none', height: 250, width: '50%' }}
            id='custom-progress-barchart' >
            <div className='custom-dock-panel-title'>Progress</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
            <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
              <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
                <div id='timeline-progress-ppvc' style={{
                  backgroundColor: 'white',
                  color: "#000", position: 'absolute !important', width: '100%', bottom: 0, margin: 0, height: '100%'
                }}>
                  <ButtonGroup style={{ right: '5px', position: 'absolute', zIndex: '1' }} size='xs'>
                    <Whisper placement={'top'} trigger="click" speaker={
                      <Popover >
                        <ButtonGroup size='xs'>
                          <Tooltip placement={'top'} title='Day' >
                            <Button onClick={this.handleDisplayTimeLine.bind(this, 'D')} >D</Button>
                          </Tooltip>
                          <Tooltip placement={'top'} title='Moth' >
                            <Button onClick={this.handleDisplayTimeLine.bind(this, 'M')}>M</Button>
                          </Tooltip>
                          <Tooltip placement={'top'} title='Year' >
                            <Button onClick={this.handleDisplayTimeLine.bind(this, 'Y')}>Y</Button>
                          </Tooltip>
                        </ButtonGroup>
                      </Popover>
                    }>
                      <Button >
                        {this.state.modeDislpayTimeLine}</Button>
                    </Whisper>
                    <Tooltip title={'PPVC List'} placement="top">
                      <IconButton icon={<Icon icon={'list'} style={{ backgroundColor: this.state.isShowList && '#8dc1f7' }} />}
                        onClick={this.handleTableDetailAll} />
                    </Tooltip>
                    {/* <Tooltip title={'Pie Chart'} placement="top">
                      <IconButton icon={<Icon icon={'pie-chart'} style={{ backgroundColor: this.state.openPieChart && '#7dff7d' }} />}
                        onClick={this.handlePieChart} />
                    </Tooltip> */}
                    <Tooltip title={this.state.showAllElement ? 'Show all element' : 'Just element was actived'} placement="top">
                      <IconButton color={this.state.showAllElement ? 'blue' : 'red'} icon={<Icon icon={this.state.showAllElement ? 'eye' : 'eye-slash'} />}
                        onClick={this.handleDisplayElement} />
                    </Tooltip>
                    <Tooltip title={!this.state.isRunTimeline ? 'play' : 'pause'} placement="top">
                      <IconButton color={!this.state.isRunTimeline ? 'blue' : 'red'} icon={<Icon icon={!this.state.isRunTimeline ? 'play' : 'pause'} />}
                        onClick={this.playAnimation} />
                    </Tooltip>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable>
        {this.state.isShowList && <PPVCTable tableDetail={this.state.tableDetail}
          openDock={this.state.isShowList} handleCloseDock={this.handleCloseDockTable}
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Length', field: 'length' },
            { title: 'Height', field: 'height' },
            { title: 'Width', field: 'width' },
            { title: 'Volume', field: 'volume' },
            { title: 'Tekla Volume', field: 'teklaVolume' },
            { title: 'Rebar Weight', field: 'rebarWeightKg' },
            // { title: 'Status', field: 'status' },
            // { title: 'Date', field: 'date' },
            { title: 'Site Name', field: 'siteName' },
            { title: 'Casting Date', field: 'castingDate' },
            { title: 'Delivery Date', field: 'deliveryDate' },
            { title: 'Install Date', field: 'installDate' },
          ]} />}

        <Modal backdrop="static" show={this.state.openPortalStart} onHide={this.handleClosePortal} size='xs'>
          <Modal.Header>
            <Modal.Title>Set start date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <DatePicker placeholder="Start Date" size='small' onChange={this.handleDateTimeline.bind(this, 'min')} format={'DD-MM-YYYY'}
              value={moment(new Date(this.state.timeValue.min), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
              allowClear={false} disabledDate={this.disabledMinDate} showToday={false} />
          </Modal.Body>
        </Modal>
        <Modal backdrop="static" show={this.state.openPortalEnd} onHide={this.handleClosePortal} size='xs'>
          <Modal.Header>
            <Modal.Title>Set end date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <DatePicker placeholder="End Date" size='small' onChange={this.handleDateTimeline.bind(this, 'max')} format={'DD-MM-YYYY'}
              value={moment(new Date(this.state.timeValue.max), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
              allowClear={false} disabledDate={this.disabledMaxDate} showToday={false} />
          </Modal.Body>
        </Modal>
      </div>
    )

  }



}
export default ProgressPrecastChart;