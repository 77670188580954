import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Row, Col, Radio, DatePicker, message } from "antd";
import _ from "lodash";
import ChartSxFPerMonth from "./observation/ChartSxFPerMonth";
import ChartSxFPerProject from "./observation/ChartSxFPerProject";
import ChartSxFSummary from "./observation/ChartSxFSummary";
import TableSubConSummary from "./observation/TableSubConSummary";
import ChartAllSxFPerMonth from "./observation/ChartAllSxFPerMonth";
import StaticInfo from "./_common_component/StaticInfo";

import listColor from "../function/listColor";
const { RangePicker } = DatePicker;
const ObservationReport = (current) => {
   const [originData, setOriginData] = useState();
   const [tempData, setTempData] = useState();
   const [countItems, setCountItems] = useState();
   const [countByMonth, setCountByMonth] = useState();
   const [listProject, setListProject] = useState([]);
   const [listSxF, setListSxF] = useState();
   const [lineLabels, setLineLables] = useState();
   const [sxfSelected, setSxFSelected] = useState();
   const [currentProject, setCurrentProject] = useState();
   const [current_sxf, setCurrenSxF] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [static_report, setStatic] = useState();
   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            // await axios.post("/api/qaqc/getdata/jotform-observation");
            // message.success("Latest data from Jotform has been loaded!");

            const res = await axios.get("/api/qaqc/dashboard/observation");
            const temp_d = res.data.data;
            setOriginData(temp_d);
            setTempData(temp_d);
            setCurrentProject("ALL PROJECTS");
            setCurrenSxF("All SxF");

            let projects = [...new Set(temp_d.map((obj) => obj.project_code))];
            projects.sort();
            projects.splice(0, 0, "ALL PROJECTS");
            setListProject(projects);

            Digest_Data_All_Project(temp_d, "ALL PROJECTS", "All SxF");
            get_Static_for_Dashboard(temp_d);
         };

         fetchdata();
      }
   }, []);

   const get_Static_for_Dashboard = (temp_d) => {
      let static_info = [];

      let s = new Object();
      s.key = "reports";
      s.value = temp_d.length;
      static_info.push(s);

      s = new Object();
      s.key = "draft";
      s.value = temp_d.filter((e) => e["Status of Observation:"] === "Draft").length;
      static_info.push(s);

      s = new Object();
      s.key = "accepted by QA";
      s.value = temp_d.filter((e) => e["Status of Observation:"] === "Accepted by QA").length;
      static_info.push(s);

      s = new Object();
      s.key = "accepted by PM";
      s.value = temp_d.filter((e) => e["Status of Observation:"] === "Accepted by PM").length;
      static_info.push(s);

      setStatic(static_info);
   };

   const Digest_Data_All_Project = (temp_d, current_project, current_discipline) => {
      // temp_d.forEach((e) => {
      //    e.YYYYMM = dayjs(e.created_at).format("YYYYMM");
      //    e.MMYY = dayjs(e.created_at).format("MM/YY");
      //    e.sxf = `${e.Severity}x${e.Frequency}`;
      // });

      //GET DATA FOR TABLE AND BAR CHART
      var group_sxf = _.groupBy(temp_d, "sxf");

      let list_count_by_sxf = [];
      _.map(group_sxf, (value, key) => {
         let t = new Object();
         t.name = value[0].sxf;

         const list_sxf = temp_d.filter((e) => e.sxf === value[0].sxf);

         t.total = list_sxf.length;

         //get list of all project from data
         let projects = [...new Set(temp_d.map((obj) => obj.project_code))];
         //get percent for each project
         projects.forEach((p) => {
            const list_sxf_project = list_sxf.filter((e) => e.project_code === p);

            const total_project = list_sxf_project.length;

            const project_count_name = `${p}_count`;
            t[project_count_name] = total_project;
         });
         // }
         list_count_by_sxf.push(t);
      });
      list_count_by_sxf = list_count_by_sxf.filter((e) => e.total > 0);
      list_count_by_sxf = _.sortBy(list_count_by_sxf, ["name"]);

      //GET DATA FOR LINE CHART
      var groups = _.groupBy(temp_d, "yyyymm");
      groups = _.sortBy(groups, "yyyymm");
      let list_count_by_months = [];
      _.map(groups, (value, key) => {
         let t = new Object();
         t.name = value[0].MMYY;
         const per_month = temp_d.filter((e) => e.MMYY === value[0].MMYY);
         t.All = per_month.length;
         //then group by trade
         var per_trade = _.groupBy(per_month, "sxf");
         _.map(per_trade, (val, key) => {
            t[key] = val.length;
         });

         list_count_by_months.push(t);
      });

      //LIST TRADE
      const list_sxf = list_count_by_sxf.map((item) => item.name);
      list_sxf.sort();
      list_sxf.splice(0, 0, "All SxF");
      let line_labels = [];
      list_sxf.map((item, index) => {
         const t = { key: item, color: listColor[index] };
         line_labels.push(t);
      });

      setCountItems(list_count_by_sxf);
      setCountByMonth(list_count_by_months);
      setListSxF(list_sxf);
      setLineLables(line_labels);
      setSxFSelected(list_sxf[0]);
      return;
   };

   const handleChangeProject = (e) => {
      const current_project = e.target.value;
      setCurrentProject(current_project);
      let data = [];
      if (current_project === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project_code === current_project);
      }

      if (current_sxf !== "All SxF") {
         data = data.filter((e) => e.sxf === current_sxf);
      }

      if (date_start !== undefined && date_start !== "" && date_end !== undefined && date_end !== "") {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_project);
      get_Static_for_Dashboard(data);
   };

   const handleChangeSXF = (e) => {
      const current_sxf = e.target.value;
      setCurrenSxF(current_sxf);
      let data = [];

      if (currentProject === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project_code === currentProject);
      }

      if (current_sxf !== "All SxF") {
         data = data.filter((e) => e.sxf === current_sxf);
      }
      if (date_start !== undefined && date_start !== "" && date_end !== undefined && date_end !== "") {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_sxf);
   };

   const handleChangeDate = (dateString) => {
      set_date_start(dateString[0]);
      set_date_end(dateString[1]);
      let data = [];
      if (currentProject === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project_code === currentProject);
      }

      if (current_sxf !== "All SxF") {
         data = data.filter((e) => e.discipline === current_sxf);
      }

      if (dateString[0] !== undefined && dateString[0] !== "" && dateString[1] !== undefined && dateString[1] !== "") {
         data = data.filter((e) => e.created_at >= dateString[0] && e.created_at <= dateString[1]);
      }
      setTempData(data);
      Digest_Data_All_Project(data, currentProject);
   };

   return (
      <div style={{ margin: "10px 15px", height: "100%" }}>
         <Row>
            <Col span={17} style={{ left: "-10px" }}>
               <div className="qaqc_dashboard_card">
                  <Row gutter={10}>
                     <Radio.Group
                        defaultValue="ALL PROJECTS"
                        buttonStyle="solid"
                        onChange={(e) => handleChangeProject(e)}
                     >
                        {listProject !== undefined
                           ? listProject.map((p) => (
                                <Radio.Button value={p} key={p}>
                                   {p.toUpperCase()}
                                </Radio.Button>
                             ))
                           : null}
                     </Radio.Group>

                     <RangePicker
                        size={"default"}
                        style={{ float: "right", marginRight: 7, width: 220 }}
                        onChange={(date, dateString) => handleChangeDate(dateString)}
                     />
                  </Row>
                  <Row gutter={10} style={{ marginTop: "5px" }}>
                     <Radio.Group defaultValue="All SxF" buttonStyle="solid" onChange={(e) => handleChangeSXF(e)}>
                        {listSxF !== undefined
                           ? listSxF.map((p) => (
                                <Radio.Button value={p} key={p}>
                                   {p}
                                </Radio.Button>
                             ))
                           : null}
                     </Radio.Group>
                  </Row>
               </div>
               <br></br>
               <Row className="qaqc_dashboard_card">
                  <ChartSxFSummary
                     current_project={currentProject}
                     projects={listProject}
                     data={countItems}
                     sxfSelected={sxfSelected}
                     setSxFSelected={setSxFSelected}
                  />
               </Row>
               <br></br>
               <Row className="qaqc_dashboard_card">
                  {countByMonth !== undefined ? <ChartAllSxFPerMonth values={countByMonth} /> : null}
               </Row>
               <br></br>
               <Row className="qaqc_dashboard_card">
                  {countByMonth !== undefined && lineLabels !== undefined ? (
                     <ChartSxFPerMonth values={countByMonth} labels={lineLabels} sxfSelected={sxfSelected} />
                  ) : null}
               </Row>
            </Col>
            <Col
               span={7}
               style={{
                  top: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  height: "100%",
               }}
            >
               <div className="qaqc_dashboard_card">
                  <StaticInfo static_report={static_report} />
               </div>
               <br></br>
               <div className="qaqc_dashboard_card">
                  <TableSubConSummary data={tempData} className="qaqc_dashboard_card" />
               </div>

               <br></br>
               {currentProject === "ALL PROJECTS" ? (
                  <div className="qaqc_dashboard_card">
                     <ChartSxFPerProject
                        current_project={currentProject}
                        projects={listProject}
                        data={countItems}
                        sxfSelected={sxfSelected}
                        setSxFSelected={setSxFSelected}
                        className="qaqc_dashboard_card"
                     />
                  </div>
               ) : null}
            </Col>
         </Row>
      </div>
   );
};

export default ObservationReport;
