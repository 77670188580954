import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, IconButton, Modal, SelectPicker, Button, Input, ButtonGroup, Divider } from 'rsuite';
import { Typography, Col, Row, Icon as IconAntd, message, Tooltip } from 'antd';
import $ from 'jquery';

import _ from 'lodash'
import axios from "axios";
// import '../scss/SideBar.scss'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import { Tree } from "@blueprintjs/core";
import { convertHexColorToVector4 } from '../function/TableFunction'
import { getAllChildTreeNode } from '../function/ForgeFunction'
import { Scrollbars } from 'react-custom-scrollbars';
import {Iconsvg} from '../../../image'
const { Text, Title } = Typography
const Autodesk = window.Autodesk;


let ext = ''
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
class SideBarPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openPanel: false,
      data: [],
      viewCurrent: null,
      document: null,
      dataTarget: null,
      isModelLoaded: false,
      modelLoaded: null,
      openPanelPinModel: false,
      testData: [
        {
          id: props.projectId,
          hasCaret: true,
          icon: "diagram-tree",
          label: props.projectName,
          type: 'project'
        }
      ],
      widthDock: 500,
      role:''
    }
    this.onceTime = true
  }
  componentWillReceiveProps = (pre, old) => {
    if (pre.viewer && this.onceTime) {
      this.onceTime = false
      pre.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, e => {
        this.checkModelLoaded(e)
      })
      pre.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, e => {
        this.checkModelLoaded(e)
      })
    }
  }
  checkModelLoaded = (e) => {
    if(!e.model.myData) return
    if (e.model.myData.loadOptions.itemId === this.props.itemId) {
      if (e.model.is2d() && ext === 'rvt') {
        this.props.onDisplayPanelChange('treeViewPanel', false)
        this.props.onDisabledBtnChange('btnTreeview', true)
      } else if (e.model.is3d()) {
        this.props.onDisabledBtnChange('btnTreeview', false)
        let models = this.props.viewer.impl.modelQueue().getModels()
        let nodes = getAllChildTreeNode(this.state.testData[0].childNodes)
        _.forEach(nodes, (node, k) => {
          if (node.itemId !== this.props.itemId) {
            node.open = false
          }
          node.color = "" 
          node.icon = this.generateNodeIcon(node) 
          node.label = this.generateNodeLabel(node)
        })
      }
    } else {
      let models = this.props.viewer.impl.modelQueue().getModels()
      let nodes = getAllChildTreeNode(this.state.testData[0].childNodes)
      _.forEach(nodes, (node, k) => {
        let index = _.findIndex(models, e => { return e.myData.loadOptions.modelNameOverride === node.text })
        if (index >= 0) {
          node.open = true
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        } else {
          node.open = false
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        }
      })
    }
  }
  // getAllChildTreeNode(root) {
  //   var temp = [];
  //   var queue = [];
  //   _.forEach(root, item => {
  //     queue.push(item);
  //   })
  //   while (queue.length > 0) {
  //     var node = queue.shift();
  //     if (node.childNodes) {
  //       let childNode = []
  //       _.forEach(node.childNodes, (v, k) => {
  //         childNode.push(v)
  //       })
  //       queue = queue.concat(childNode)
  //     } else {
  //       if (node.type === 'item')
  //         temp.push(node);
  //     }
  //   }
  //   return temp
  // }

  componentDidMount = () => {
    let split = this.props.itemName.split('.')
    ext = split[split.length - 1].toLowerCase()
    let role = ''
    if (this.props.userInfo.email === 'admin@wohhup.com') {
      role = 'admin'
    } else {
      let index = _.findIndex(this.props.userInfo.contractors, v => { return v.projectId === this.props.projectId })
      if (index >= 0) {
        role = this.props.userInfo.contractors[index].role
        this.setState({  role })
      }
    }
    $("#color-picker-model").change(e => {
      let color = convertHexColorToVector4(e.target.value)
      let node = this.state.nodeColor
      let models = this.props.viewer.impl.modelQueue().getModels()
      _.forEach(models, v => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          this.props.viewer.setThemingColor(v.getRootId(), color, v, true);
          node.color = e.target.value
          node.label = this.generateNodeLabel(node)
          return false;
        }
      })
      this.setState({ testData: this.state.testData })
    })
  }
  //#region //! load model
  handleOpenView = (node) => {
    if (node.status === "pending") {
      const models = this.props.viewer.impl.modelQueue().getModels();
      _.forEach(models, (v, k) => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          //this.props.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, true))
          this.props.viewer.unloadModel(v)
          this.uploadNodeModel(node, true)
          // this.props.listModel.splice(k, 1);
          return false;
        }
      })
    } else if (node.open) {
      node.status = "pending"
      node.icon = this.generateNodeIcon(node)
      this.setState({
        isModelLoaded: true, testData: this.state.testData
      }, () => {
        let objectId = node.objectId;
        Autodesk.Viewing.Document.load('urn:' + objectId,
          this._onDocumentLoadSuccess.bind(this, node),
          this._onDocumentLoadFailure.bind(this, node));
      })
    }
    else if (!node.open) {
      node.status = "pending"
      node.icon = this.generateNodeIcon(node)
      this.setState({
        isModelLoaded: false, testData: this.state.testData
      }, () => {
        var objectId = node.objectId;
        Autodesk.Viewing.Document.load('urn:' + objectId, this._onDocumentLoadSuccess.bind(this, node),
          this._onDocumentLoadFailure.bind(this, node));
      })
    }
  }
  uploadNodeModel = (node, check, svfUrl, loadOptions) => {
    //this.props.viewer.removeEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this))
    if (check) {
      node.status = ""
      node.open = false
      node.color = ""
      node.icon = this.generateNodeIcon(node)
      this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
    } else {
      node.status = "pending"
      node.color = ""
      node.icon = this.generateNodeIcon(node)
      this.props.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, node), this._onLoadModelError.bind(this, node))
      this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
    }
  }

  _onDocumentLoadFailure = (node, viewerErrorCode) => {
    node.status = ""
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  }
  _onDocumentLoadSuccess = (node, doc) => {
    let temp = []
    let models = this.props.viewer.impl.modelQueue().getModels()
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    _.forEach(view3d, v => {
      if (v.data.size >= 0)
        temp.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v })
    })
    if (temp.length === 1) {
      if (node.open) {
        _.forEach(models, (v, k) => {
          if (v.myData.loadOptions.modelNameOverride === node.text) {
            // this.props.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, true))
            this.props.viewer.unloadModel(v)
            // this.props.listModel.splice(k, 1);
            this.uploadNodeModel(node, true)
            return false;
          }
        })
      } else {
        let svfUrl = doc.getViewablePath(temp[0].obj);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: node.text,
          isAEC: true,
          guid: temp[0].obj.data.guid,
          viewableID: temp[0].obj.data.viewableID,
          itemId: node.itemId,
          version: node.version,
          acmSessionId: doc.acmSessionId
        };
        this.props.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, node), this._onLoadModelError.bind(this, node))
        this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false })
      }
    } else {
      if (node.open) {
        _.forEach(models, v => {
          if (v.myData.loadOptions.modelNameOverride === node.text) {
            let guid = v.myData.loadOptions.guid
            this.setState({ data: temp, openPanel: true, document: doc, viewCurrent: guid, modelLoaded: v, nodeCurrent: node })
            return false;
          }
        })
      }
      else
        this.setState({ data: temp, openPanel: true, document: doc, nodeCurrent: node })
    }
  };
  _onLoadModelSuccess = (node, modelCurrent) => {
    node.status = ""
    node.open = true
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  };
  _onLoadModelError = (node, viewerErrorCode) => {
    node.status = ""
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  }
  //#endregion

  //#region //! pin model
  handlePanelPinModel = (e) => {
    let node = e.props.node
    if (this.props.itemId === node.itemId) {
      message.warning(`Can't pin main model`)
    } else {
      this.setState({ openPanelPinModel: true, nodePin: node })
    }

  }
  handleClosePinPanel = () => {
    this.setState({ openPanelPinModel: false })
  }

  //#endregion


  handleCloseSidebar = (e) => {
    this.props.onDisplayPanelChange('treeViewPanel', false)
  }

  handleClosePanel = (data, check) => {
    if (data === null) {
      let node = this.state.nodeCurrent
      node.status = ""
      node.icon = (
        <ButtonGroup >
          <IconButton size="xs" onClick={this.handleOpenView.bind(this, node)} color={node.open && "blue"} icon={
            <Icon icon={node.status === "pending" ? "spinner" : !node.open ? "eye-slash" : "eye"} spin={node.status === 'pending' ? true : false} />} />
        </ButtonGroup>
      )
      this.setState({ openPanel: false, openPanelPinModel: false, testData: this.state.testData })
    } else if (!check) {
      this.props.viewer.unloadModel(data.model)
      this.uploadNodeModel(data.node, true)
      // this.props.listModel.splice(data.key, 1);
    } else if (check) {
      if (data.node.open) {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId
        };
        //this.props.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, false))
        this.props.viewer.unloadModel(this.state.modelLoaded)
        this.uploadNodeModel(data.node, false, svfUrl, loadOptions)
      } else {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId
        };
        this.props.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, data.node), this._onLoadModelError.bind(this, data.node))
        this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
      }
    }
  }


  //#region tree
  handleNodeClick = (node, e) => {
    console.log(node)
  }
  handleNodeCollapse = (node, e) => {
    node.isExpanded = false;
    this.setState({ testData: this.state.testData })
  }
  handleChangeColorMode = (node, e) => {
    // let button =document.getElementById("color-picker-model")
    // button.style.top= `${e.clientY -53.78}px`
    // button.style.left= `${e.clientX-40}px`
    $("#color-picker-model").css('top', e.clientY - 53.78)
    $("#color-picker-model").css('left', e.clientX - 40)
    setTimeout(() => {
      $("#color-picker-model").click();
      this.setState({ nodeColor: node })
    }, 1);

  }
  handelClearColorModel = (e) => {
    let node = e.props.node
    let models = this.props.viewer.impl.modelQueue().getModels()
    _.forEach(models, v => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        node.color = "";
        node.label = this.generateNodeLabel(node)
        this.props.viewer.clearThemingColors(v)
        return false;
      }
    })
    this.setState({ testData: this.state.testData })
  }
  handleNodeExpand = (node, e) => {
    if (!node.isLoaded) {
      node.secondaryLabel = (
        <Icon icon="spinner" spin />
      )
      node.disabled = true
      this.setState({ testData: this.state.testData })
      axios.get("/api/versions/project-tree", { params: { id: node.id, type: node.type, role: this.state.role , token: this.props.userInfo.token , fileExt:ext.toLowerCase()} })
        .then(res => {
          res.data.sort(this.sortLabel)
          // let index = _.findIndex(res.data, e => { return e.itemId === this.props.itemId })
          // if (index >= 0)
          //   res.data.splice(index, 1)
          _.forEach(res.data, v => {
            if (v.type === "item") {
              let split = v.text.split('.')
              let extension = split[split.length - 1].toLowerCase()
              v.type = extension === 'fbx' ? 'item' : extension === ext ? 'item' : 'no support'
            }
            if (v.type === "item") {
              v.status = ""
              v.color = ""
              v.open = this.props.itemId === v.itemId ? true : false
              v.label = this.generateNodeLabel(v)
              v.icon = (
                this.props.itemId === v.itemId ?
                  <ButtonGroup  >
                    <IconButton size="xs" onClick={this.handleOpenView.bind(this, v)} color={"blue"} icon={<Icon icon="eye" />} disabled />
                  </ButtonGroup>
                  :
                  this.generateNodeIcon(v)
              )
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              )
            } else if (v.type === 'no translate') {
              v.label = (
                <>
                  <IconButton size="xs" disabled={true}
                    icon={<Icon icon={"lock"} />} />
                  <Text style={{ color: "orange" }} >  {' '}  {v.text} </Text>
                </>
              )
              v.icon = (
                <ButtonGroup  >
                  <IconButton size="xs" icon={<Icon icon={"lock"} />} disabled={true} />
                </ButtonGroup>
              )
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              )
            } else if (v.type === 'no support') {
              v.label = (
                <>
                  <IconButton size="xs" disabled={true}
                    icon={<Icon icon={"lock"} />} />
                  <Text  >  {' '}  {v.text} </Text>
                </>
              )
              v.icon = (
                <ButtonGroup  >
                  <IconButton size="xs" icon={<Icon icon={"lock"} />} disabled={true} />
                </ButtonGroup>
              )
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              )
            }
          })
          node.secondaryLabel = (
            <Icon icon="refresh" />
          )
          node.childNodes = res.data
          node.isExpanded = true;
          node.disabled = false
          node.isLoaded = true
          this.setState({ testData: this.state.testData }, () => {
            let models = this.props.viewer.impl.modelQueue().getModels()
            let nodes = getAllChildTreeNode(this.state.testData[0].childNodes)
            _.forEach(nodes, (node, k) => {
              let index = _.findIndex(models, e => { return e.myData.loadOptions.modelNameOverride === node.text })
              if (index >= 0) {
                node.open = true
                node.icon = this.generateNodeIcon(node)
                node.label = this.generateNodeLabel(node)
              } else {
                node.open = false
                node.icon = this.generateNodeIcon(node)
                node.label = this.generateNodeLabel(node)
              }
            })
            this.setState({ testData: this.state.testData })
          })
        })
        .catch(err => {
          node.disabled = false
          node.secondaryLabel = ''
        })
    } else {
      node.isExpanded = true;
      this.setState({ testData: this.state.testData })
    }
  }
  generateNodeIcon = (node) => {
    return (
      <ButtonGroup  >
        <IconButton size="xs" onClick={this.handleOpenView.bind(this, node)} color={node.open && "blue"} disabled={node.itemId === this.props.itemId} icon={
          <Icon icon={node.status === "pending" ? "spinner" : !node.open ? "eye-slash" : "eye"} spin={node.status === 'pending' ? true : false} />} />
      </ButtonGroup>
    )
  }
  generateNodeLabel = (node) => {
    return (
      <MenuProvider id="treeview_menu" animation={animation.zoom} data={{ node: node }}>
        <IconButton size="xs" onClick={this.handleChangeColorMode.bind(this, node)} style={{ backgroundColor: node.color !== '' && node.color }}
          icon={
            node.color === "" ?
              <IconAntd component={Iconsvg} style={{ top: 4, left: 4, position: 'absolute' }} /> :
              <Icon />} />
        <Text  >  {' '}  {node.text} </Text>
      </MenuProvider>

    )
  }
  sortLabel = (a, b) => {

    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
  //#endregion
  sizeChange = (e) => {
    if (e > 200 && e < 800)
      this.setState({ widthDock: e })
    else if (e < 200)
      this.setState({ widthDock: 400 })
    else if (e > 800)
      this.setState({ widthDock: 800 })
  }
  render() {
    return (
      <>
        <div style={{
          width: 400, height: 'calc(100% - 53.78px)', position: 'absolute', left: 40, background: 'white', zIndex: 1, padding: 10,
          display: this.props.displayPanel ? 'block' : 'none'
        }}>
          <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}>
            <Title level={4} style={{ display: 'contents' }}>   Tree View</Title>
            <Icon icon='close' size='xs' style={{ float: 'right', cursor: 'pointer' }} onClick={this.handleCloseSidebar} />
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div style={{ padding: '0px 0px 0px 0px', width: '100%', height: 'calc(100% - 36px)' }}>
            <Scrollbars
              renderView={renderView}
              autoHide autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}>
              <Tree
                contents={this.state.testData}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              // className={Classes.ELEVATION_0}
              />
            </Scrollbars>
          </div>

        </div>


        <Menu id='treeview_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handlePanelPinModel}>
            <IconFont className="fas fa-thumbtack" />Pin Model
                </Item>
          <Item onClick={this.handelClearColorModel}>
            <IconFont className="fas fa-eraser" />Reset Color
                </Item>
        </Menu>
        {this.state.openPanel &&
          <SelectViewPanel
            open={this.state.openPanel}
            close={this.handleClosePanel}
            data={this.state.data}
            viewCurrent={this.state.viewCurrent}
            viewer={this.props.viewer}
            isModelLoaded={this.state.isModelLoaded}
            nodeCurrent={this.state.nodeCurrent}
            modelLoaded={this.state.modelLoaded}
          />}
        {this.state.openPanelPinModel &&
          <PinModelPanel
            viewer={this.props.viewer}
            open={this.state.openPanelPinModel}
            close={this.handleClosePinPanel}
            nodeCurrent={this.state.nodePin}
          />}
      </>
    );
  }
}
SideBarPanel.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(SideBarPanel)


function SelectViewPanel(props) {

  const [viewableSelected, setViewableSelected] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    props.close(null)
  }
  const handleChangeView = (value, e) => {
    _.forEach(props.data, v => {
      if (v.value === value) {
        setViewableSelected(v.obj)
        return false
      }
    })
  }
  const handleUnloadModel = () => {
    setLoading(true)
    let models = props.viewer.impl.modelQueue().getModels()
    let node = props.nodeCurrent
    _.forEach(models, (v, k) => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        props.close({ key: k, node: props.nodeCurrent, model: v }, false)
        setLoading(false)
        return false;
      }
    })
  }
  const handleViewable = () => {
    props.close({ viewableSelected: viewableSelected, node: props.nodeCurrent, model: props.modelLoaded }, true)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' overflow={true} backdrop="static">
      <Modal.Header>
        <Modal.Title>List Viewables</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <SelectPicker
          data={props.data}
          style={{ width: '100%' }}
          defaultValue={props.viewCurrent}
          groupBy="group"
          placeholder="Select View"
          cleanable={false}
          onChange={handleChangeView}
          renderMenuItem={(label, item) => {
            return (
              <div>
                <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
              </div>
            );
          }}
          renderValue={(value, item) => {
            return (
              <div>
                <span style={{ color: '#000000' }}>
                  <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} />{item.group === '3D' ? " View3D" : " Sheet"} :
              </span>{' '}
                {item.label}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {props.isModelLoaded &&
          <Button onClick={handleUnloadModel} color="red" loading={loading}>
            Unload
            </Button>}
        <Button onClick={handleViewable} appearance="primary" loading={loading}
          disabled={viewableSelected === null}>
          Load
            </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
            </Button>
      </Modal.Footer>
    </Modal>
  )
}

function PinModelPanel(props) {

  const [coordinateData, setCoordinateData] = useState()
  const [modelGetCoordinate, setModelGetCoordinate] = useState()
  const [itemId, setItemId] = useState()
  const [loading, setLoading] = useState(false)
  const [disableSavePosition, setDisableSavePosition] = useState(true)
  useEffect(e => {
    let models = props.viewer.impl.modelQueue().getModels()
    let model = _.find(models, e => { return e.myData.loadOptions.modelNameOverride === props.nodeCurrent.text })
    if (model) {
      let instanceTree = model.getData().instanceTree
      let rootId = instanceTree.getRootId()
      let fragProxy = props.viewer.impl.getFragmentProxy(model, rootId)
      fragProxy.getAnimTransform()
      // console.log(fragProxy)
      setCoordinateData(props.nodeCurrent.coordinate)
      setModelGetCoordinate(model)
      setItemId(props.nodeCurrent.itemId)
    } else {
      message.warning(`You have to load model first`)
      props.close();
    }
  }, [])
  const handleClose = () => {
    props.close();
  }
  const handleGetCurrentPosition = () => {
    let instanceTree = modelGetCoordinate.getData().instanceTree
    let rootId = instanceTree.getRootId()
    let fragProxy = props.viewer.impl.getFragmentProxy(modelGetCoordinate, rootId)
    fragProxy.getAnimTransform()
    let tempCoordinateData = {
      position: { x: fragProxy.position.x, y: fragProxy.position.y, z: fragProxy.position.z },
      rotation: { x: fragProxy.quaternion._x, y: fragProxy.quaternion._y, z: fragProxy.quaternion._z, w: fragProxy.quaternion._w }
    }
    setCoordinateData(tempCoordinateData)
    setDisableSavePosition(false)
  }
  const handleSavePosition = () => {
    axios.post("/api/items/save-coordinate", { itemId: itemId, coordinate: coordinateData })
      .then(res => {
        // let instanceTree = this.state.modelGetCoordinate.getData().instanceTree
        // let rootId = instanceTree.getRootId()
        // let fragProxy = this.props.viewer.impl.getFragmentProxy(this.state.modelGetCoordinate, rootId)

        let fragId = modelGetCoordinate.getFragmentList().fragments.packIds
        _.forEach(fragId, id => {
          let fragProxy = props.viewer.impl.getFragmentProxy(modelGetCoordinate, id)
          fragProxy.getAnimTransform()
          fragProxy.position = res.data.position
          fragProxy.quaternion = res.data.rotation
          fragProxy.updateAnimTransform()
        })
        props.viewer.impl.sceneUpdated(true)
        setDisableSavePosition(true)
        props.close();

      })
      .catch(err => {
        setDisableSavePosition(true)
        props.close();
      })
  }
  const handleLoadPosition = () => {

    let fragIdsArray = []

    let fragCount = modelGetCoordinate.getFragmentList().fragments.fragId2dbId.length

    for (let fragId = 0; fragId < fragCount; ++fragId) {

      fragIdsArray.push(fragId)
    }
    //let fragId = modelGetCoordinate.getFragmentList().fragments.fragId2dbId
    _.forEach(fragIdsArray, id => {
      let fragProxy = props.viewer.impl.getFragmentProxy(modelGetCoordinate, id)
      fragProxy.getAnimTransform()
      fragProxy.position = coordinateData.position
      fragProxy.quaternion = coordinateData.rotation
      fragProxy.updateAnimTransform()
    })
    // let instanceTree = this.state.modelGetCoordinate.getData().instanceTree
    // let rootId = instanceTree.getRootId()
    // let fragProxy = this.props.viewer.impl.getFragmentProxy(this.state.modelGetCoordinate, rootId)
    props.viewer.impl.sceneUpdated(true)
    setDisableSavePosition(true)
    props.close();
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' overflow={true} backdrop="static">
      <Modal.Header>
        <Modal.Title>Pin Model</Modal.Title>
      </Modal.Header>
      <Modal.Body id='sidebar-tree' >
        {coordinateData &&
          <>
            <Row gutter={[8, 8]} type="flex" justify="space-between">
              <Col span={4} >  </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Text strong style={{ color: 'red' }} > X</Text>
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Text strong style={{ color: 'green' }}> Y</Text>
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Text strong style={{ color: 'blue' }}> Z</Text>
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Text strong style={{ color: 'black' }}> W</Text>
              </Col>
            </Row>
            <Row gutter={[8, 8]} type="flex" justify="space-between">
              <Col span={4}>Position   </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.position.x} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.position.y} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.position.z} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>

              </Col>
            </Row>
            <Row gutter={[8, 8]} type="flex" justify="space-between">
              <Col span={4} >Rotation   </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.rotation.x} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.rotation.y} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.rotation.z} disabled={true} />
              </Col>
              <Col span={5} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                <Input type='number' value={coordinateData.rotation.w} disabled={true} />
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleGetCurrentPosition} appearance="primary" loading={loading}  >
          Get Current Position
            </Button>
        <Button onClick={handleSavePosition} appearance="primary" loading={loading}
          disabled={disableSavePosition}>
          Save
            </Button>
        <Button onClick={handleLoadPosition} appearance="primary" loading={loading}>
          Load
            </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
            </Button>
      </Modal.Footer>
    </Modal>
  )
}

