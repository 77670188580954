import React from 'react';
import { Image, Dropdown } from 'semantic-ui-react';
import { IconButton, Modal, Icon } from 'rsuite';
import _ from 'lodash';
import { Typography } from 'antd';
import { WHLogoText } from '../../../image';
import { getPublicUrl } from './../function/AdminFunction';
const { Text } = Typography;


const options =
  [
    {
      key: '4.2.9', text: '4.2.9', value: '4.2.9', date: 'Publish Date:04/01/2023',
      changeLog: [
       `+ Update`,
      ]
    },
    {
      key: '4.2.8', text: '4.2.8', value: '4.2.8', date: 'Publish Date:29/10/2022',
      changeLog: [
       `+ Unit handed over with additional status for handed over with condition`,
       `+ total handed over included the condition`
      ]
    },
    {
      key: '4.2.7', text: '4.2.7', value: '4.2.7', date: 'Publish Date:16/10/2022',
      changeLog: [
       `+ Add in QAQC dashboard for Handy project with BCA inspection score, Unit hand-over, productivity score`,
       `+ Bug fix for worker mobile app when user cannot save block, unit`
      ]
    },
    {
      key: '4.2.6', text: '4.2.6', value: '4.2.6', date: 'Publish Date:04/10/2022',
      changeLog: [
       `+ Improve permission, allow New Document controller take over the old DC who not active`,
      ]
    },
    {
      key: '4.2.5', text: '4.2.5', value: '4.2.5', date: 'Publish Date:30/08/2022',
      changeLog: [
       `+ Update DMS: Download bunch of RFA dwg/dwfx`,
       `+ Update DMS: DMS prevent key in duplicated drawing number and name`,
       `+ Update DMS: DT add tags to form submission`
      ]
    },
    {
      key: '4.2.4', text: '4.2.4', value: '4.2.4', date: 'Publish Date:09/08/2022',
      changeLog: [
       `+ Update QAQC app : Fix issue when common check  count unnecessary item`,
       `+ Update QAQC app : Remove setup not display item got total count <5`,
      ]
    },
    {
      key: '4.2.3', text: '4.2.3', value: '4.2.3', date: 'Publish Date:07/08/2022',
      changeLog: [
       `+ Update Worker app : Fix bug that user cant add supervisor.`,
       `+ Update Worker app : Add Filter for table unit.`,
      ]
    },
    {
      key: '4.2.2', text: '4.2.2', value: '4.2.2', date: 'Publish Date:03/08/2022',
      changeLog: [
       `+ Update worker app : Remove some columns in the employee table which are the personal information column.`,
       `+ Update worker app : Add block data in the block-level-zone tab`,
      ]
    },
    {
      key: '4.2.1', text: '4.2.1', value: '4.2.1', date: 'Publish Date:30/07/2022',
      changeLog: [
       `+ Improve : Update worker app : fix bug can't create block unit.`,
      ]
    },
    {
      key: '4.2.0', text: '4.2.0', value: '4.2.0', date: 'Publish Date:02/07/2022',
      changeLog: [
       `+ Improve : Update Worker app : Add block unit in web app and mobile worker app.`,
       `+ Improve : 5d Table fix function open model and select model`,
       `+ Improve : Add TABLE_FORECAST  that shows the number of concrete volume which will be needed in the next 2 months`,
       `+ Improve : Add Dashboard for QM worker check in QAQC`
      ]
    },
    {
      key: '4.1.9', text: '4.1.9', value: '4.1.9', date: 'Publish Date:27/06/2022',
      changeLog: [
       `+ Improve : Add in "forecast quantity" in 5D`,
       `+ Improve : Fix issue when have multi download scanned pdf file of DO.`
      ]
    },
    {
      key: '4.1.8', text: '4.1.8', value: '4.1.8', date: 'Publish Date:19/06/2022',
      changeLog: [
       `+ Improve : Add QM item for QAQC dashboard`,
       `+ Improve : Minor improve on UI for Common Check and Critical Check`
      ]
    },
    {
      key: '4.1.7', text: '4.1.7', value: '4.1.7', date: 'Publish Date:17/06/2022',
      changeLog: [
       `+ Improve : Add export excel button 5d table_test`,
       `+ Improve : Add date filter  5d`,
       `+ Improve : Add button get actual concrete volume , do number`

      ]
    },
    {
      key: '4.1.6', text: '4.1.6', value: '4.1.6', date: 'Publish Date:06/06/2022',
      changeLog: [
        `+ Improve : Export excel DMS`,

      ]
    },
    {
      key: '4.1.5', text: '4.1.5', value: '4.1.5', date: 'Publish Date:15/05/2022',
      changeLog: [
        `+ Improve : Add 2 columns to 5d tables (Do No, Sub-con)`,
        `+ Improve : Update casting report functionality for the new 5D table which is using Ag Grid`

      ]
    },
    {
      key: '4.1.4', text: '4.1.4', value: '4.1.4', date: 'Publish Date:09/05/2022',
      changeLog: [
        `+ Improve : Worker app :  Using lambda function to calculate dashboard data.`,
        `+ Improve : 5D :  Fix export-casting report`

      ]
    },
    {
      key: '4.1.3', text: '4.1.3', value: '4.1.3', date: 'Publish Date:02/05/2022',
      changeLog: [
        '+ Improve: DMS: fix bugs',

      ]
    },
    {
      key: '4.1.2', text: '4.1.2', value: '4.1.2', date: 'Publish Date:16/04/2022',
      changeLog: [
        '+ Improve: DMS Mobile: Add discipline screen for all form',
        '+ Improve: DMS Web: Prevent delete Dms drawings with RFA, prevent delete Dms folder contains drawings with RFA'
      ]
    },
    {
      key: '4.1.1', text: '4.1.1', value: '4.1.1', date: 'Publish Date:04/04/2022',
      changeLog: [
        '+ Improve : Worker app :  Add wage table and point table in the dashboard',
      ]
    },
    {
      key: '4.1.0', text: '4.1.0', value: '4.1.0', date: 'Publish Date:31/03/2022',
      changeLog: [
        '+ Improve: DMS: Set same status/comment reply for all drawings in RFA',
        '+ Improve: DMS: Default email CC list for all form',
        `+ Improve: Worker app :  Add night shift in table Payrate`,
        '+ Improve: Worker app :  Add sunday in trade Offday'
      ]
    },
    {
      key: '4.0.9', text: '4.0.9', value: '4.0.9', date: 'Publish Date:25/03/2022',
      changeLog: [
        '+ Improve: Do-Invoice: Add function download multiple DO scan form (max at 100 files)',
        `+ Improve: Worker app: shown outstanding number of days has not submit. The list of date which has not submit also shown at first screen`,
        '+ Improve: Worker app: Fix and improve the Excel export based on comment from QS'
      ]
    },
    {
      key: '4.0.8', text: '4.0.8', value: '4.0.8', date: 'Publish Date:24/03/2022',
      changeLog: [
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '4.0.7', text: '4.0.7', value: '4.0.7', date: 'Publish Date:11/03/2022',
      changeLog: [
        '+ Add: overall dashboard for QAQC',
        '+ Improve: UI for QAQC dashboard',
        '+ Improve: Column "description" of DO table, column not auto expand width',
        '+ Improve: Worker Allocation-> status archi for site engineer',
        '+ Improve: DMS -> new review status for consultants',
      ]
    },
    {
      key: '4.0.6', text: '4.0.6', value: '4.0.6', date: 'Publish Date:04/03/2022',
      changeLog: [
        '+ Improve: Worker Allocation',
        '+ Improve: DMS',
      ]
    },
    {
      key: '4.0.5', text: '4.0.5', value: '4.0.5', date: 'Publish Date:28/02/2022',
      changeLog: [
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '4.0.4', text: '4.0.4', value: '4.0.4', date: 'Publish Date:18/02/2022',
      changeLog: [
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '4.0.3', text: '4.0.3', value: '4.0.3', date: 'Publish Date:11/02/2022',
      changeLog: [
        '+ Improve: Worker Allocation',
        '+ Improve: DMS',
      ]
    },
    {
      key: '4.0.2', text: '4.0.2', value: '4.0.2', date: 'Publish Date:28/01/2022',
      changeLog: [
        '+ Improve: DMS',
      ]
    },
    {
      key: '4.0.1', text: '4.0.1', value: '4.0.1', date: 'Publish Date:24/01/2022',
      changeLog: [
        '+ Improve: QAQC',
        '+ Improve: DMS download',
      ]
    },
    {
      key: '4.0.0', text: '4.0.0', value: '4.0.0', date: 'Publish Date:15/01/2022',
      changeLog: [
        '+ Improve: Archi Table 5D',
        '+ Improve: DMS',
      ]
    },
    {
      key: '3.4.7', text: '3.4.7', value: '3.4.7', date: 'Publish Date: 31/12/2021',
      changeLog: [
        '+ Add: Table 5D test',
        '+ Improve: Table 5D',
        '+ Improve: DMS',
      ]
    },
    {
      key: '3.4.6', text: '3.4.6', value: '3.4.6', date: 'Publish Date: 24/12/2021',
      changeLog: [
        '+ Improve: Table 5D',
        '+ Improve: DMS',
      ]
    },
    {
      key: '3.4.5', text: '3.4.5', value: '3.4.5', date: 'Publish Date: 17/12/2021',
      changeLog: [
        '+ Improve: Precast API for PDD',
        '+ Improve: Table 5D',
        '+ Improve: DMS',
        '+ Improve: DO',
      ]
    },
    {
      key: '3.4.4', text: '3.4.4', value: '3.4.4', date: 'Publish Date: 10/12/2021',
      changeLog: [
        '+ Add: Dashboard for Admin',
        '+ Improve: nwd, nwc extract quantities',
        '+ Improve: fix bugs',
        '+ Improve: Table 5D',
        '+ Improve: DMS',
        '+ Fixed: forger viewer for mobile',
      ]
    },
    {
      key: '3.4.3', text: '3.4.3', value: '3.4.3', date: 'Publish Date: 03/12/2021',
      changeLog: [
        '+ Improve: fix bugs',
        '+ Improve: EXCEL, UI, rollback drawing new version but no resubmission, DT link DMS drawings',
        '+ Improve: Table 5D add column',
        '+ Fixed: forger viewer for mobile',
      ]
    },
    {
      key: '3.4.2', text: '3.4.2', value: '3.4.2', date: 'Publish Date: 26/11/2021',
      changeLog: [
        '+ Add: Wohhup Add-In panel',
        '+ Improve: fix bugs',
        '+ Improve: DMS',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.4.1', text: '3.4.1', value: '3.4.1', date: 'Publish Date: 19/11/2021',
      changeLog: [
        '+ Improve: QA/QC (beta)',
        '+ Improve: fix bugs',
        '+ Improve: DMS',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.4.0', text: '3.4.0', value: '3.4.0', date: 'Publish Date: 22/10/2021',
      changeLog: [
        '+ Add: QA/QC (beta)',
        '+ Improve: fix bugs',
        '+ Improve: DMS',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.3.9', text: '3.3.9', value: '3.3.9', date: 'Publish Date: 14/10/2021',
      changeLog: [
        '+ Improve: fix bugs',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.3.8', text: '3.3.8', value: '3.3.8', date: 'Publish Date: 08/10/2021',
      changeLog: [
        '+ Improve: fix bugs',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.3.7', text: '3.3.7', value: '3.3.7', date: 'Publish Date: 04/10/2021',
      changeLog: [
        '+ Improve: fix bugs',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.3.6', text: '3.3.6', value: '3.3.6', date: 'Publish Date: 01/10/2021',
      changeLog: [
        '+ Improve: fix bugs',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation',
      ]
    },
    {
      key: '3.3.5', text: '3.3.5', value: '3.3.5', date: 'Publish Date: 27/09/2021',
      changeLog: [
        '+ Add: DMS platform for mobile version (beta)',
        '+ Improve: 5D platform (new function)',
        '+ Improve: Dataentry (beta)',
        '+ Improve: Worker Allocation (beta)',
      ]
    },
    {
      key: '3.3.4', text: '3.3.4', value: '3.3.4', date: 'Publish Date: 17/09/2021',
      changeLog: [
        '+ Improve: 5D platform (table style)',
        '+ Improve: Dataentry (beta)',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation (beta)',
      ]
    },
    {
      key: '3.3.3', text: '3.3.3', value: '3.3.3', date: 'Publish Date: 09/09/2021',
      changeLog: [
        '+ Add: 5D platform',
        '+ Improve: Dataentry (beta)',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation (beta)',
        '+ Improve: Worker Allocation (beta) mobile',
      ]
    },
    {
      key: '3.3.2', text: '3.3.2', value: '3.3.2', date: 'Publish Date: 02/09/2021',
      changeLog: [
        '+ Add: Form parse platform',
        '+ Improve: Dataentry (beta)',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation (beta)',
      ]
    },
    {
      key: '3.3.1', text: '3.3.1', value: '3.3.1', date: 'Publish Date: 20/08/2021',
      changeLog: [
        '+ Add: mobile version with domain: m-idd.wohhup.com',
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation (beta)',
      ]
    },
    {
      key: '3.3.0', text: '3.3.0', value: '3.3.0', date: 'Publish Date: 16/08/2021',
      changeLog: [
        '+ Improve: DMS platform',
        '+ Improve: Worker Allocation (beta)',
      ]
    },
    {
      key: '3.2.9', text: '3.2.9', value: '3.2.9', date: 'Publish Date: 05/08/2021',
      changeLog: [
        '+ Add: Idd App Mobile (Beta)',
        '+ Improve: DMS platform',
        '+ Update: Forge Viewer for DMS',
      ]
    },
    {
      key: '3.2.8', text: '3.2.8', value: '3.2.8', date: 'Publish Date: 30/07/2021',
      changeLog: [
        '+ Add: Worker Allocation (Beta)',
        '+ Improve: DMS platform',
      ]
    },
    {
      key: '3.2.7', text: '3.2.7', value: '3.2.7', date: 'Publish Date: 27/07/2021',
      changeLog: [
        '+ Update: Data Entry (Beta)',
        '+ Update: RFAM/RFI/CVI/DT/MM',
      ]
    },
    {
      key: '3.2.6', text: '3.2.6', value: '3.2.6', date: 'Publish Date: 07/07/2021',
      changeLog: [
        '+ Update: RFAM/RFI/CVI/DT (Beta) ',
      ]
    },
    {
      key: '3.2.5', text: '3.2.5', value: '3.2.5', date: 'Publish Date: 25/06/2021',
      changeLog: [
        '+ Add: RFAM/RFI/CVI/DT (Beta) ',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.2.4', text: '3.2.4', value: '3.2.4', date: 'Publish Date: 07/06/2021',
      changeLog: [
        '+ Update: DMS Platform',
        '+ Update: BIM Platform',
        '+ Update: Coordination Platform',
        '+ Update: function set role',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.2.3', text: '3.2.3', value: '3.2.3', date: 'Publish Date: 31/05/2021',
      changeLog: [
        '+ Update: DMS Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.2.2', text: '3.2.2', value: '3.2.2', date: 'Publish Date: 27/05/2021',
      changeLog: [
        '+ Update: Trimble Connect Viewer',
        '+ Update: DMS Platform',
        '+ Update: Bim App Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.2.1', text: '3.2.1', value: '3.2.1', date: 'Publish Date: 21/05/2021',
      changeLog: [
        '+ Update: Trimble Connect Viewer',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.2.0', text: '3.2.0', value: '3.2.0', date: 'Publish Date: 17/05/2021',
      changeLog: [
        '+ Update: DMS Platform',
        '+ Update: Bim App Platform',
        '+ Update: function upload file',
        '+ Update: function permission for folder',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.9', text: '3.1.9', value: '3.1.9', date: 'Publish Date: 14/05/2021',
      changeLog: [
        '+ Update: DMS Platform',
        '+ Update: Bim App Platform',
        '+ Update: function upload file + manage versions',
        '+ Update: function export dwfx',
        '+ Update: function extract quantities',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.8', text: '3.1.8', value: '3.1.8', date: 'Publish Date: 07/05/2021',
      changeLog: [
        '+ Update: domain',
        '+ Update: DMS Platform',
        '+ Update: Bim App Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.7', text: '3.1.7', value: '3.1.7', date: 'Publish Date: 05/05/2021',
      changeLog: [
        '+ Update: function Save View',
        '+ Update: function Date Automation',
        '+ Update: Coordination Platform',
        '+ Update: Bim App Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.6', text: '3.1.6', value: '3.1.6', date: 'Publish Date: 03/05/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: DMS Platform',
        '+ Update: Bim App Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.5', text: '3.1.5', value: '3.1.5', date: 'Publish Date: 27/04/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: Bim App Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.4', text: '3.1.4', value: '3.1.4', date: 'Publish Date: 26/04/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: DMS Platform',
        '+ Update: function PPVC/Precast',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.3', text: '3.1.3', value: '3.1.3', date: 'Publish Date: 14/04/2021',
      changeLog: [
        '+ Update: disable CIP Platform',
        '+ Update: function PPVC/Precast',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.2', text: '3.1.2', value: '3.1.2', date: 'Publish Date: 09/04/2021',
      changeLog: [
        '+ Update: function Timeline + 4D Comparison',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.1', text: '3.1.1', value: '3.1.1', date: 'Publish Date: 08/04/2021',
      changeLog: [
        '+ Add: Trimble Connect (Admin test)',
        '+ Update: PPVC for PDD',
        '+ Update: Coordination',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.1.0', text: '3.1.0', value: '3.1.0', date: 'Publish Date: 07/04/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.0.9', text: '3.0.9', value: '3.0.9', date: 'Publish Date: 31/03/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: Drawing management system (beta)',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.0.8', text: '3.0.8', value: '3.0.8', date: 'Publish Date: 23/03/2021',
      changeLog: [
        '+ Update: Coordination Platform',
        '+ Update: Drawing management system (beta)',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.0.7', text: '3.0.7', value: '3.0.7', date: 'Publish Date: 18/03/2021',
      changeLog: [
        '+ Add: Coordination Platform (Admin beta)',
        '+ Add: Data Entry (Admin beta)',
        '+ Update: function PPVC/Precast',
        '+ Update: fix bugs',
      ]
    },
    {
      key: '3.0.5', text: '3.0.5', value: '3.0.5', date: 'Publish Date: 08/03/2021',
      changeLog: [
        '+ Update: Drawing management system (beta)',
      ]
    },
    {
      key: '3.0.4', text: '3.0.4', value: '3.0.4', date: 'Publish Date: 04/03/2021',
      changeLog: [
        '+ Update: Drawing management system (beta)',
      ]
    },
    {
      key: '3.0.3', text: '3.0.3', value: '3.0.3', date: 'Publish Date: 01/03/2021',
      changeLog: [
        '+ Update: UI for project dashboard',
        '+ Update: User role',
        '+ Update: Setting Company',
        '+ Update: Drawing management system (beta)',
      ]
    },
    {
      key: '3.0.2', text: '3.0.2', value: '3.0.2', date: 'Publish Date: 17/02/2021',
      changeLog: [
        '+ Update: Drawing management system (beta)',
        '+ Update: Civil-CIP system (beta)',
      ]
    },
    {
      key: '3.0.1', text: '3.0.1', value: '3.0.1', date: 'Publish Date: 15/01/2021',
      changeLog: [
        '+ Add: Function Show Gird',
        '+ Update: Drawing management system (beta)',
        '+ Update: Civil-CIP system (beta)',
        '+ Update: BIM App Dashboard',
        '+ Update: function Custom Properties',
      ]
    },
    {
      key: '3.0.0', text: '3.0.0', value: '3.0.0', date: 'Publish Date: 30/12/2020',
      changeLog: [
        '+ Add: Drawing management system (beta)',
        '+ Add: Civil-CIP system (beta)',
      ]
    },
    {
      key: '2.1.6', text: '2.1.6', value: '2.1.6', date: 'Publish Date: 28/12/2020',
      changeLog: [
        '+ Update: BIM App Dashboard',
      ]
    },
    {
      key: '2.1.4', text: '2.1.4', value: '2.1.4', date: 'Publish Date: 10/12/2020',
      changeLog: [
        '+ Update: improve function PPVC/Precast',
        '+ Update: fix function Pin Model',
        '+ Update: improve function Issue/Comment',
      ]
    },
    {
      key: '2.1.3', text: '2.1.3', value: '2.1.3', date: 'Publish Date: 07/12/2020',
      changeLog: [
        '+ Update: improve hotkey ',
        '+ Update: improve function Compare Version with 2D view',
        '+ Update: improve function Progress PPVC/Precast',
      ]
    },
    {
      key: '2.1.2', text: '2.1.2', value: '2.1.2', date: 'Publish Date: 26/11/2020',
      changeLog: [
        '+ Add: hot key for Measure, Section Plan, Elevation',
        '+ Add: trigger email for Comment',
        '+ Update: improve function Split View',
        '+ Update: improve function Progress PPVC/Precast',
        '+ Update: improve function Comment + Issue',
      ]
    },
    {
      key: '2.1.1', text: '2.1.1', value: '2.1.1', date: 'Publish Date: 09/11/2020',
      changeLog: [
        '+ Add: function export DWF/DWFX for Precast Project',
        '+ Add: function Split View',
        '+ Update: all UI for Sidebar',
        '+ Update: fix function Progress PPVC',
        '+ Update: improve function Comment',
        '+ Update: function Elevation Tool with keyword "T" to create a mark-up',
      ]
    },
    {
      key: '2.1.0', text: '2.1.0', value: '2.1.0', date: 'Publish Date: 27/10/2020',
      changeLog: [
        '+ Add: function MDL Report + Sold Report',
        '+ Update: fix function Extract Quantities for svf2',
        '+ Update: fix function TreeView for svf2',
        '+ Update: fix function Summary + Progress + Pie Chart Precast',
      ]
    },
    {
      key: '2.0.9', text: '2.0.9', value: '2.0.9', date: 'Publish Date: 23/10/2020',
      changeLog: [
        '+ Update: switch Translate file to SVF2',
        '+ Update: Viewer to SVF2',
        '+ Update: function Summary + Progress + Pie Chart Precast',
        '+ Update: fix some bugs',
        '+ Update: function Animation',
        '+ Update: function Rebar Schedule + Rebar Classification',
      ]
    },
    {
      key: '2.0.8', text: '2.0.8', value: '2.0.8', date: 'Publish Date: 21/10/2020',
      changeLog: [
        '+ New: function Animation',
        '+ Update: function Rebar Schedule + Rebar Classification',
        '+ Update: New UI for Document Browser',
      ]
    },
    {
      key: '2.0.7', text: '2.0.7', value: '2.0.7', date: 'Publish Date: 16/10/2020',
      changeLog: [
        '+ Update: function Rebar Schedule + Rebar Classification',
      ]
    },
    {
      key: '2.0.6', text: '2.0.6', value: '2.0.6', date: 'Publish Date: 15/10/2020',
      changeLog: [
        '+ Update: fix function PPVC Summary and PPVC Progress',
        '+ Update: function PPVC Pie Chart',
      ]
    },
    {
      key: '2.0.5', text: '2.0.5', value: '2.0.5', date: 'Publish Date: 01/10/2020',
      changeLog: [
        '+ Add: function PPVC Progress to SharePage',
        '+ Add: function Rebar Schedule and Rebar Filter',
        '+ Update: separate function PPVC Summary and PPVC Pie Chart',
        '+ Update: function PPVC Pie Chart',
        '+ Update: function ShareView (fix url)',
        '+ Update: function Treeview for multi-format',
      ]
    },
    {
      key: '2.0.4', text: '2.0.4', value: '2.0.4', date: 'Publish Date: 04/09/2020',
      changeLog: [
        '+ Add: function Comment',
        '+ Update: function Markup: Dimension',
        '+ Update: improve function Timeline',
        '+ Update: improve function Export DWFX',
        '+ Update: Add-in Revit-Cloud',
      ]
    },
    {
      key: '2.0.3', text: '2.0.3', value: '2.0.3', date: 'Publish Date: 21/08/2020',
      changeLog: [
        '+ Add: function Comment',
        '+ Update: improve function Markup for DWF/DWFX',
        '+ Update: function Extract Quantities',
      ]
    },
    {
      key: '2.0.2', text: '2.0.2', value: '2.0.2', date: 'Publish Date: 11/08/2020',
      changeLog: [
        '+ Add: function Compare Quantities',
        '+ Update: function Document Browser',
        '+ Update: function Filter Set for DWF/DWFX + export/import filter',
        '+ Update: change upload size to 900Mb',
        '+ Update: fix bug + improve function issue + export excel',
        '+ Update: notification email',
      ]
    },
    {
      key: '2.0.1', text: '2.0.1', value: '2.0.1', date: 'Publish Date: 22/07/2020',
      changeLog: [
        '+ Add: function Filter Set',
        '+ Update: fix bug function issue',
        '+ Update: fix bug function views',
        '+ Update: function Select Model + Select Category for *.rvt',
        '+ Update: improve performance web',
      ]
    },
    {
      key: '2.0.0', text: '2.0.0', value: '2.0.0', date: 'Publish Date: 14/07/2020',
      changeLog: [
        '+ Add: function Issue',
        '+ Add: function View',
        '+ Update: Improve function notification',
        '+ Update: UI Homepage',
        '+ Update: function Elevation tool + Select Model + Select Category',
        '+ Update: Improve function Tree view + UI',
      ]
    },
    {
      key: '1.1.2', text: '1.1.2', value: '1.1.2', date: 'Publish Date: 16/05/2020',
      changeLog: [
        '+ Update: Improve function Upload File + UI',
        '+ Update: Improve function Translate File + UI',
        '+ Update: function Compare version + UI',
      ]
    },
    {
      key: '1.1.1', text: '1.1.1', value: '1.1.1', date: 'Publish Date: 08/05/2020',
      changeLog: [
        '+ Update: Improve function Upload File + UI',
        '+ Update: Improve function Translate File + UI',
        '+ Update: Improve function Tracking User: External Users',
      ]
    },
    {
      key: '1.1.0', text: '1.1.0', value: '1.1.0', date: 'Publish Date: 03/05/2020',
      changeLog: [
        '+ Add: function Report: Archi Summary',
        '+ Update: fix function Status for Claim: Building Project',
      ]
    },
    {
      key: '1.0.9', text: '1.0.9', value: '1.0.9', date: 'Publish Date: 28/04/2020',
      changeLog: [
        '+ Update: function Status for Claim: Building Project',
      ]
    },
    {
      key: '1.0.8', text: '1.0.8', value: '1.0.8', date: 'Publish Date: 22/04/2020',
      changeLog: [
        '+ Add: function Custom properties in Share view',
        '+ Update: function Timeline in Share view: show master schedule',
      ]
    },
    {
      key: '1.0.7', text: '1.0.7', value: '1.0.7', date: 'Publish Date: 18/04/2020',
      changeLog: [
        '+ Update: function Timeline: extrude object (no need)',
        '+ Update: function Export DWF with contract type',
        '+ Update: function Summary PPVC: tooltip, display value,...',
        '+ Update: function Share View: add function PPVC Information',
        '+ Remove: function Share link Safety Report',
      ]
    },
    {
      key: '1.0.6', text: '1.0.6', value: '1.0.6', date: 'Publish Date: 16/04/2020',
      changeLog: [
        '+ Update: fix Share View',
        '+ Update: Change login to Microsoft Account',
      ]
    },
    {
      key: '1.0.5', text: '1.0.5', value: '1.0.5', date: 'Publish Date: 14/04/2020',
      changeLog: [
        '+ Update: function Share View',
        '+ Update: function Timeline',
        '+ Update: function Summary PPVC',
      ]
    },
    {
      key: '1.0.4', text: '1.0.4', value: '1.0.4', date: 'Publish Date: 13/04/2020',
      changeLog: [
        '+ New: add function Transform Tools',
        '+ Update: function Share View',
        '+ Update: UI HomePage',
        '+ Update: UI Dialog About',
      ]
    },
    {
      key: '1.0.3', text: '1.0.3', value: '1.0.3', date: 'Publish Date: 10/04/2020',
      changeLog: [
        '+ New: Add function Share Link Report',
      ]
    },
    {
      key: '1.0.2', text: '1.0.2', value: '1.0.2', date: 'Publish Date: 09/04/2020',
      changeLog: [
        '+ New: Add function PPVC Status Installation',
        '+ New: Add function Share Link Report',
        '+ Update: Share View with fucntion PPVC, CIS',
        '+ Update: View 2d for function Status for Claim',
        '+ Update: Export DWF with file name'
      ]
    }
  ]

class DialogAboutWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishDate: options[0].date,
      changeLog: options[0].changeLog,
    };
  }
  handleClosePanel = () => {
    this.props.closeDialog(false);
  };
  handleChangeVeriosn = (e, { value }) => {
    let index = _.findIndex(options, (v) => {
      return v.value === value;
    });
    this.setState({
      publishDate: options[index].date,
      changeLog: options[index].changeLog,
    });
  };
  handleUploadTutorial = () => {};
  handleTutorialLink = () => {
    getPublicUrl('tutorial/WH Revit Addin User Tutorials.pdf')
      .then((res) => {
        window.open(res);
      })
      .catch((err) => {});
  };
  render() {
    return (
      <Modal
        show={this.props.onShow}
        onHide={this.handleClosePanel.bind(this)}
        style={{ paddingTop: '3%' }}
        size='xs'
      >
        <Modal.Header>
          <Modal.Title>Wohhup Add-in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={WHLogoText} size='medium' rounded floated='left' />
          <Text strong>Develop by Bim team</Text>
          {/* {this.props.isAdmin &&
            <>
              <Upload  name= 'file' multiple= {false} showUploadList={false}
                customRequest={handleCustomRequest} fileList={attachFile} >
                <IconButton icon={<Icon icon='upload' />} onClick={this.handleUploadTutorial} size='xs' />
              </Upload>
            </>

          } */}
          <Text link style={{ float: 'right' }}>
            <a
              style={{ color: 'blue' }}
              target='_blank'
              rel='noopener noreferrer'
              onClick={this.handleTutorialLink}
            >
              Tutorial
            </a>
          </Text>
          <br />
          <Text>Changelog:</Text>
          <br />
          {this.state.changeLog.map((v) => (
            <>
              <Text type='secondary'>{v}</Text>
              <br />
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Text style={{ float: 'left' }} type='secondary'>
            {this.state.publishDate}
          </Text>
          <Text strong>Version </Text>
          <Dropdown
            className='custom-version-changelog'
            inline
            style={{
              color: 'rgba(0, 0, 0, 0.45)',
              fontWeight: '0px !important',
            }}
            options={options}
            defaultValue={options[0].value}
            onChange={this.handleChangeVeriosn}
          />
          {/* <Text type="secondary" >  1.0.3</Text> */}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DialogAboutWeb;
