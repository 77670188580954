import { v4 } from 'uuid';

export const pipeDwall = [
  {
    id: v4(),
    name: 'Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Pile marking',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Sizes',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Design / COL',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Toe',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Blind bore Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Penetration Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Socket Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: `Design / Con'c Vol (m3)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Rebar (tons)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Subcon / COL',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Toe',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Blind bore Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Penetration Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Socket Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: `Subcon / Data Cast`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Status',
    type: 'text',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 75,
  },

  {
    id: v4(),
    name: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['On-going', 'Completed'],
    width: 55,
  },
  {
    id: v4(),
    name: `Remark`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    width: 150,
  },

  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
];
export const slab5D = [
  {
    id: v4(),
    name: 'Pour name',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
    parameter: "POUR_NAME"
  },
  {
    id: v4(),
    name: 'Cost code',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 95,
  },
  {
    id: v4(),
    name: 'Qtt tracking code',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },

  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },

  {
    id: v4(),
    name: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['REBAR', 'FORMWORK', 'CASTED'],
    width: 65,
  },
  {
    id: v4(),
    name: `Lean Con'c Area (m2)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Lean Con'c Area Actual (m2)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Fwk Area Design (m2)',
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Fwk Area Actual (m2)',
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Design (m3)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Actual (m3)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Design (m3) by Engineer`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Engineer design vol lean concrete/others`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Rebar Weight Design (kg)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Rebar Weight Actual (kg)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Casting report',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Do No.',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Sub-con',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'CJ NAME',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Remark`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    width: 150,
  },
];
export const archi5D = [
  {
    id: v4(),
    name: 'Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    parameter: 'WH_Zone',
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Sub Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    parameter: 'WH_Sub Zone',
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Level',
    type: 'text',
    parameter: 'WH_Level',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Room Id',
    type: 'text',
    parameter: 'ROOM NUMBER',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Category',
    parameter: 'WH_Group',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D Code',
    type: 'text',
    parameter: 'CODE',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D_CODE_BASE',
    type: 'text',
    parameter: '5D_CODE_BASE',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D_CODE_MIDLE',
    parameter: '5D_CODE_MIDLE',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),

    name: '5D_CODE_FINAL',
    parameter: '5D_CODE_FINAL',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },

  {
    id: v4(),
    name: 'Item',
    parameter: 'Item',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Cost Id',
    parameter: 'Cost Id',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Length',
    parameter: 'Length',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Volume',
    parameter: 'Volume',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Area',
    parameter: 'Area',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Count',
    parameter: 'Count',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'UNIT',
    parameter: 'UNIT',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Status',
    parameter: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['Yes', 'No'],
    width: 65,
  },
  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },

  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
];
