import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { Func_Export_Summary_By_Trade_To_Excel } from "../../function/func_export_to_excel";

const TableSubConSummary = ({ data, setTradeSelected }) => {
   var data_sub = [];
   if (data !== undefined) {
      var group_sub = _.groupBy(data, "Attention (Subcontractor):");

      _.map(group_sub, (value, key) => {
         let t = new Object();
         t.name = value[0]["Attention (Subcontractor):"];

         const list_same_sub = data.filter((e) => e.sxf === value[0].sxf);

         t.total = list_same_sub.length;

         //get list of all project from data
         let projects = [...new Set(data.map((obj) => obj.project_code))];
         //get percent for each project
         let per_pjt = "";
         projects.forEach((p) => {
            const list_sxf_project = list_same_sub.filter((e) => e.project_code === p);

            const total_project = list_sxf_project.length;
            if (total_project > 0) {
               per_pjt = per_pjt + p + ":" + total_project + " ";
            }
         });
         t.pjt_breakdown = per_pjt;
         // }
         data_sub.push(t);
      });
      data_sub = _.sortBy(data_sub, ["total"]);
   }

   const columns = [
      {
         title: "Sub-Contractor",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 125,
         ellipsis: true,
      },

      {
         title: "nos",
         dataIndex: "total",
         key: "total",
         align: "right",
         width: 50,
      },
      {
         title: "Per project",
         dataIndex: "pjt_breakdown",
         key: "pjt_breakdown",
         align: "right",
         width: 150,
      },
   ];

   return (
      <div style={{ minHeight: "32vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Trade
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               // onClick={(e) => Func_Export_Summary_By_Trade_To_Excel(data)}
            >
               <Icon type="download" />
            </Button>
            {/* <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               // onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Items {">"} 9%
            </Checkbox> */}
         </h5>
         <Table
            dataSource={data_sub}
            columns={columns}
            size="small"
            scroll={{ y: "25vh" }}
            pagination={false}
            // rowClassName={(record, index) => (record.percent >= 9 ? "antd_table_row_red" : null)}
            // onRow={(record, rowIndex) => {
            //    return {
            //       onClick: (event) => {
            //          // console.log(record.name);
            //          setTradeSelected(record.name);
            //       }, // click row
            //    };
            // }}
            rowKey="name"
         />
      </div>
   );
};

export default TableSubConSummary;
