import React, { useEffect, useState } from 'react';
import { IconButton, Icon, ButtonGroup, Loader, InputPicker, Button } from 'rsuite';
import { Typography, message, Select, Popover, Input, List } from 'antd';
import _ from 'lodash'
import axios from 'axios'
import Draggable from 'react-draggable';
import {
  getAllElementdbIdsOneModel, getLeafFragIds, getChildNodeOneModel
} from '../../function/ForgeFunction'
import { ControlGroup, Button as BlueprintButton } from "@blueprintjs/core";

import { Scrollbars } from 'react-custom-scrollbars';
import { v4 } from 'uuid';
import TWEEN from '@tweenjs/tween.js';
import { trackingUserUsing } from '../../function/AdminFunction'
import { DraggableAreasGroup } from 'react-draggable-tags'
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { Text } = Typography;

const THREE = window.THREE

// let _transformControlTx = null
// let _transformMesh = null
// let _selection = null
// let _selectedFragProxyMap = {}
// let _isDragging = false
// let _hitPoint = null
let countAnimation = 0
let limitCount = 0
const renderView = ({ style, ...props }) => {

  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
let distance = 20
let time = 600
function AnimationDock(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [animationName, setAnimationName] = useState('')
  const [] = useState(false)
  const [listDbId, setListDbId] = useState({})
  const [listAnimation, setListAnimation] = useState([])
  const [animationSelected, setAnimationSelected] = useState(null)
  useEffect(() => {
    trackingUserUsing(props.email,props.projectId,'BIM App','Animation')
    setLoading(true)
    axios.get('/api/animation/get-list-animation', { params: { itemId: props.itemId, projectId: props.projectId } })
      .then(res => {
        let tempList = []
        _.forEach(res.data, v => {
          tempList.push({ label: v.name, value: v.guid, animation: v.animation })
        })
        setListAnimation(tempList)
        let temp = {}
        let tempDbIds = getAllElementdbIdsOneModel(props.viewer)
        let count = tempDbIds.length
        _.forEach(tempDbIds, dbId => {
          props.viewer.getProperties(dbId, (result) => {
            let externalId = result.externalId;
            temp[externalId] = dbId
            count--
            if (count === 0) {

              setListDbId(temp)
              setLoading(false)
            }
          });
        })
      })
      .catch(() => {
        message.warning(`Can't get data`)
        handleCloseDock();
      })
  }, [])

  const handleCloseDock = () => {
    props.viewer.showAll()
    // let models = props.viewer.impl.modelQueue().getModels()
    // _.forEach(models, model => {
    //   props.viewer.clearThemingColors(model)
    // })
    let fragIdsArray = []

    let fragCount = props.viewer.impl.model.getFragmentList().fragments.fragId2dbId.length

    for (let fragId = 0; fragId < fragCount; ++fragId) {

      fragIdsArray.push(fragId)
    }
    fragIdsArray.forEach((fragId) => {
      let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
      fragProxy.position = null
      fragProxy.updateAnimTransform()
    })
    props.viewer.impl.sceneUpdated(true)

    props.onChangeDockDisplay('animationDock', false)
  }
  const handleNameAnimation = (e) => {
    setAnimationName(e.target.value)
  }
  const handleSaveStep = () => {
    if (animationName === '') {
      message.warning('You need to key in name')
      return
    } else {
      data.push({ id: v4(), name: animationName })
      setAnimationName('')
      setData(data.slice(0))
    }
  }
  const handleRemoveStep = (item) => {
    if (!window.confirm(`Are you want remove it`)) return
    let index = _.findIndex(data, o => { return o.id === item.id })
    if (index >= 0) {
      data.splice(index, 1)
      setData(data.slice(0))
    }
  }
  const handlePlayAnimation = () => {
    _.forEach(data, item => {
      item.isActive = false
      _.forEach(item.dbIds, externalId => {
        if (!listDbId[externalId]) return
        props.viewer.hide(listDbId[externalId], props.viewer.impl.model)
        let fragIds = getLeafFragIds(props.viewer.impl.model, listDbId[externalId])
        let direction = item.direction
        let position = {
          x: direction === 'E' ? distance : direction === 'W' ? -distance : 0,
          y: direction === 'N' ? distance : direction === 'S' ? -distance : 0,
          z: direction === 'T' ? distance : direction === 'B' ? -distance : 0,
        }
        _.forEach(fragIds, fragId => {
          let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
          fragProxy.getAnimTransform()
          fragProxy.position = new THREE.Vector3(position.x, position.y, position.z)
          fragProxy.updateAnimTransform()
        })
      })
    })
    props.viewer.impl.sceneUpdated(true)
    countAnimation = 0
    limitCount = data.length
    setData(data.slice(0))
    animateTween(data[countAnimation])
  }
  const animateTween = (item) => {
    data[countAnimation].isActive = true
    setData(data.slice(0))
    if (item.dbIds && item.dbIds.length !== 0) {
      _.forEach(item.dbIds, externalId => {
        props.viewer.show(listDbId[externalId], props.viewer.impl.model)
      })
      let direction = item.direction
      let tween = new TWEEN.Tween({
        x: direction === 'E' ? distance : direction === 'W' ? -distance : 0,
        y: direction === 'N' ? distance : direction === 'S' ? -distance : 0,
        z: direction === 'T' ? distance : direction === 'B' ? -distance : 0,
      });
      tween
        .to({ x: 0, y: 0, z: 0 }, time)
        .easing(TWEEN.Easing.Linear.None)
        .onUpdate(function (e) {
          requestAnimationFrame(updateTween)
          _.forEach(item.dbIds, externalId => {
            if (!listDbId[externalId]) return
            let fragIds = getLeafFragIds(props.viewer.impl.model, listDbId[externalId])
            _.forEach(fragIds, fragId => {
              let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
              fragProxy.getAnimTransform()
              fragProxy.position = new THREE.Vector3(e.x, e.y, e.z)
              fragProxy.updateAnimTransform()
            })
          })
          props.viewer.impl.sceneUpdated(true)
        })
        .onComplete(function () {
          // data[countAnimation].isActive = false
          setData(data.slice(0))
          countAnimation++
          if (limitCount > countAnimation)
            animateTween(data[countAnimation])
          else {
            requestAnimationFrame(updateTween)
          }
        })
        .start();
      requestAnimationFrame(updateTween)
    } else {
      data[countAnimation].isActive = false
      setData(data.slice(0))
      countAnimation++
      if (limitCount > countAnimation)
        animateTween(data[countAnimation])
      else {
        requestAnimationFrame(updateTween)
      }
    }

  }
  const updateTween = () => {
    TWEEN.update();
  }

  const handleSaveDirection = (item, name) => {
    setLoading(true)
    let _selection = props.viewer.getSelection()
    if (_selection.length !== 0) {
      let childNode = getChildNodeOneModel(props.viewer, _selection)
      let count = childNode.length
      let temp = []
      let position = null
      _.forEach(childNode, dbId => {
        let fragIds = getLeafFragIds(props.viewer.impl.model, dbId)
        _.forEach(fragIds, fragId => {
          let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
          fragProxy.getAnimTransform()
          position = fragProxy.position
          if (position !== null) {
            return false
          }
        })
        props.viewer.getProperties(dbId, (result) => {
          let externalId = result.externalId;
          temp.push(externalId)
          count--
          if (count === 0) {
            item.dbIds = temp
            item.direction = name
            setLoading(false)
          }
        });
      })
    } else if (item.dbIds && item.dbIds.length !== 0) {
      item.direction = name
      setLoading(false)
    } else {
      setLoading(false)
    }
    setData(data.slice(0))
  }


  const handleCreateNewAnimation = () => {
    let name = window.prompt('Please enter animation name')
    if (name !== null && name !== "") {
      axios.post('/api/animation/create-animation', { itemId: props.itemId, projectId: props.projectId, name: name, user: props.email })
        .then(res => {
          listAnimation.push({ value: res.data.guid, label: res.data.name, animation: res.data.animation })
          setListAnimation(listAnimation.slice(0))
        })
        .catch(() => {
          message.warning(`Can't create animation`)
        })
    }
  }
  const handleSaveAnimation = () => {
    if (!animationSelected) return
    if (!window.confirm(`Are you want save it`)) return
    let index = _.findIndex(listAnimation, v => { return v.value === animationSelected })
    if (index >= 0) {
      axios.post('/api/animation/save-animation', {
        itemId: props.itemId, projectId: props.projectId, user: props.email, name: listAnimation[index].label,
        animation: data, guid: animationSelected
      })
        .then(() => {
          message.warning(`Saved animation`)
        })
        .catch(() => {
          message.warning(`Can't save animation`)
        })
    } else {
      message.warning(`Can't save animation`)
    }
  }
  const handleListAnimation = (value) => {
    let index = _.findIndex(listAnimation, v => { return v.value === value })
    if (index >= 0) {
      setData(listAnimation[index].animation)
    } else {
      setData([])
    }
    setAnimationSelected(value)
  }
  const handleDeletedAnimation = () => {
    if (!animationSelected) return
    if (!window.confirm(`Are you want delete it`)) return
    let index = _.findIndex(listAnimation, v => { return v.value === animationSelected })
    if (index >= 0) {
      axios.post('/api/animation/delete-animation', { itemId: props.itemId, projectId: props.projectId, guid: animationSelected })
        .then(() => {
          listAnimation.splice(index, 1)
          setAnimationSelected(null)
          message.warning(`Deleted animation`)
        })
        .catch(() => {
          message.warning(`Can't save animation`)
        })
    } else {
      message.warning(`Can't save animation`)
    }
  }
  const handleItemAnimation = (d) => {
    let temp = []
    let check = true
    _.forEach(data, item => {
      item.isActive = false
      if (check)
        temp.push(item)
      _.forEach(item.dbIds, externalId => {
        if (!listDbId[externalId]) return
        props.viewer.hide(listDbId[externalId], props.viewer.impl.model)
        let fragIds = getLeafFragIds(props.viewer.impl.model, listDbId[externalId])
        let direction = item.direction
        let position = {
          x: direction === 'E' ? distance : direction === 'W' ? -distance : 0,
          y: direction === 'N' ? distance : direction === 'S' ? -distance : 0,
          z: direction === 'T' ? distance : direction === 'B' ? -distance : 0,
        }
        _.forEach(fragIds, fragId => {
          let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
          fragProxy.getAnimTransform()
          fragProxy.position = new THREE.Vector3(position.x, position.y, position.z)
          fragProxy.updateAnimTransform()
        })
      })
      if (item.id === d.id) {
        check = false
        // return false
      }
    })
    props.viewer.impl.sceneUpdated(true)
    countAnimation = 0
    limitCount = temp.length
    setData(data.slice(0))
    animateTween(temp[countAnimation])
  }
  const handleSettingAnimation = () => {
    let number = window.prompt('Please enter animation distance', distance)
    if (number !== null && number !== "") {
      number = _.toNumber(number)
      if (_.isNumber(number)) {
        distance = number
      }
    }
    let tempTime = window.prompt('Please enter animation time (ms)', time)
    if (tempTime !== null && tempTime !== "") {
      tempTime = _.toNumber(tempTime)
      if (_.isNumber(tempTime)) {
        time = tempTime
      }
    }
  }
  const handlePlayOneStep = (item) => {
    _.forEach(data, v => {
      v.isActive = false
      if (item.id === v.id)
        v.isActive = true
    })
    setData(data.slice(0))
    if (item.dbIds && item.dbIds.length !== 0) {
      _.forEach(item.dbIds, externalId => {
        props.viewer.show(listDbId[externalId], props.viewer.impl.model)
      })
      let direction = item.direction
      let tween = new TWEEN.Tween({
        x: direction === 'E' ? distance : direction === 'W' ? -distance : 0,
        y: direction === 'N' ? distance : direction === 'S' ? -distance : 0,
        z: direction === 'T' ? distance : direction === 'B' ? -distance : 0,
      });
      tween
        .to({ x: 0, y: 0, z: 0 }, time)
        .easing(TWEEN.Easing.Linear.None)
        .onUpdate(function (e) {
          requestAnimationFrame(updateTween)
          _.forEach(item.dbIds, externalId => {
            if (!listDbId[externalId]) return
            let fragIds = getLeafFragIds(props.viewer.impl.model, listDbId[externalId])
            _.forEach(fragIds, fragId => {
              let fragProxy = props.viewer.impl.getFragmentProxy(props.viewer.impl.model, fragId)
              fragProxy.getAnimTransform()
              fragProxy.position = new THREE.Vector3(e.x, e.y, e.z)
              fragProxy.updateAnimTransform()
            })
          })
          props.viewer.impl.sceneUpdated(true)
        })
        .onComplete(function () {
          setData(data.slice(0))
          requestAnimationFrame(updateTween)
        })
        .start();
      requestAnimationFrame(updateTween)
    }
  }
  return (
    <Draggable
      axis="both" bounds='body'
      handle=".custom-dock-panel-title"
      defaultPosition={{ x: 40, y: 30 }}
      scale={1}
    >

      <div className='custom-forge-dock' style={{ display: props.openDock ? 'inline' : 'none', height: 400, width: 300, backgroundColor: '#ffffff00' }}
        id='custom-animation-dock' >
        <div className='custom-dock-panel-title'>Animation</div>
        <div className='custom-dock-panel-close' onClick={handleCloseDock}>x</div>
        <div className='custom-dock-panel-body' style={{ backgroundColor: '#ffffff00' }}>
          <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
            <Scrollbars
              renderView={renderView}
              autoHide autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}>
              {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
              <ControlGroup fill={true} vertical={false}>
                <InputPicker placeholder='Select animation' size='sm' style={{ width: '100%' }} data={listAnimation}
                  onChange={handleListAnimation} value={animationSelected} />
                <BlueprintButton icon='plus' onClick={handleCreateNewAnimation} />
                <BlueprintButton icon='saved' onClick={handleSaveAnimation} disabled={!animationSelected} />
                <BlueprintButton icon='trash' intent='danger' onClick={handleDeletedAnimation} disabled={!animationSelected} />

              </ControlGroup>


              {animationSelected && <ControlGroup fill={true} vertical={false}>
                <Input placeholder='Enter Name' onChange={handleNameAnimation} value={animationName} />
                <BlueprintButton icon='database' onClick={handleSaveStep} />
                <BlueprintButton icon='play' onClick={handlePlayAnimation} />
                <BlueprintButton icon='settings' onClick={handleSettingAnimation} />
              </ControlGroup>}

              <div className="List">
              <div className="main-drag">
                  <DraggableArea
                    isList
                    tags={data}
                    render={({ tag }) => (
                      <List.Item style={{
                        cursor: 'pointer', paddingBottom: 2, paddingTop: 2, background: tag.isActive ? '#CFE2F3' : 'white',
                        border: '2px #a9a9a9 solid', borderRadius: 5, marginBottom: 2.5, marginTop: 2.5
                      }} onDoubleClick={handleItemAnimation.bind(this, tag)}>
                        <Text>{tag.name}</Text>
                        <ButtonGroup>
                          <Popover
                            content={<>
                              <Button size='xs' color={tag.direction === 'T' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'T')} >T</Button>
                              <Button size='xs' color={tag.direction === 'B' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'B')} >B</Button>
                              <Button size='xs' color={tag.direction === 'N' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'N')} >N</Button>
                              <Button size='xs' color={tag.direction === 'S' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'S')} >S</Button>
                              <Button size='xs' color={tag.direction === 'W' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'W')} >W</Button>
                              <Button size='xs' color={tag.direction === 'E' && 'blue'} onClick={handleSaveDirection.bind(this, tag, 'E')} >E</Button>
                            </>}
                            trigger="click"
                          >
                            <IconButton size='xs' icon={<Icon icon='arrows' />} />
                          </Popover>
                          <IconButton size='xs' icon={<Icon icon='play' />} onClick={handlePlayOneStep.bind(this, tag)} />
                          <IconButton size='xs' icon={<Icon icon='close' />} color='red' onClick={handleRemoveStep.bind(this, tag)} />
                        </ButtonGroup>

                      </List.Item>
                    )}
                    onChange={data => setData(data.slice(0))}
                  />
                </div>
              </div>
              {/* <List
                loading={loading}
                bordered
                size="small"
                dataSource={data}
                renderItem={item => {
                  return (
                    <List.Item style={{
                      cursor: 'pointer', paddingBottom: 2, paddingTop: 2, background: item.isActive ? '#CFE2F3' : 'white',
                      border: '2px gray solid', borderRadius: 5
                    }} onClick={handleItemAnimation.bind(this,item)}>
                      <Text>{item.name}</Text>
                      <ButtonGroup>
                        <Button size='xs' color={item.start && 'blue'} onClick={handleSaveStart.bind(this, item)} >S</Button>
                        <Button size='xs' color={item.end && 'blue'} onClick={handleSaveEnd.bind(this, item)} >E</Button>
                        <IconButton size='xs' icon={<Icon size='1px' icon='close' />} color='red' onClick={handleRemoveStep.bind(this, item)} />
                      </ButtonGroup>

                    </List.Item>

                  )
                }}
              /> */}
            </Scrollbars>

          </div>
        </div>
        <div className='custom-dock-panel-footer' />
      </div>
    </Draggable>


  );
}


export default AnimationDock

