import _ from 'lodash'
import { getAllElementdbIdsOneModel, getAllObject } from './ForgeFunction'
import axios from "axios";
import groupArray from 'group-array'
import multisort from 'multisort'
import moment from 'moment'
import { fromExcelDate, toExcelDate } from 'js-excel-date-convert'
import { message } from 'antd';

export async function calculateQTT(dataForge) {
  await window.Excel.run(async context => {

    let sheetCIP = context.workbook.worksheets.getItemOrNullObject("CIP");
    let cipTable = sheetCIP.tables.getItem("cipTable");

    let sheetProductivity = context.workbook.worksheets.getItemOrNullObject("Productivity");
    let productivityTable = sheetProductivity.tables.getItem("productivityTable");

    let sheetFactor = context.workbook.worksheets.getItemOrNullObject("Factor");
    let factorTable = sheetFactor.tables.getItem("factorTable");

    let sheetDuctFactor = context.workbook.worksheets.getItemOrNullObject("Duct Factor");
    let ductFactorTable = sheetDuctFactor.tables.getItem("ductFactorTable");

    await context.sync()
    if (cipTable.isNull ||productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return
    let rangeCIP = cipTable.getDataBodyRange().load("values");
    let rangeProductivity = productivityTable.getDataBodyRange().load("values");
    let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
    let rangeFactor = factorTable.getDataBodyRange().load("values");


    return context.sync()
      .then(() => {
        let all = []
        let data = rangeCIP.values.slice(0)
        _.forEach(data, (v, idx) => {
          if (v[4] !== '' && v[6] !== '' && v[7] !== '') {
            all.push({
              index: idx, cipRoomNumber: v[4], cipContract: v[6], cipActivity: v[7]
            })
          }
        })

        let dic = []
        _.forEach(dataForge, item => {
          let tempObj = getAllObject(item.object)
          _.forEach(tempObj, (v, k) => {
            let index = _.findIndex(item.properties, function (o) { return o.objectid === v.objectid });
            if (index >= 0) {
              let tempProps = {}
              _.forEach(item.properties[index].properties, p => {
                _.forEach(p, (o, k) => {
                  tempProps[k] = o
                })
              })
              item.properties[index].properties = tempProps
              dic.push(item.properties[index])
            }
          })
        })
        let group = groupArray(dic, 'properties.CIP_Room Number', 'properties.CIP_Contract', 'properties.CIP_Activity', 'properties.CIP_Installation Type', 'properties.CIP_Material', 'properties.CIP_Unit',
          'properties.CIP_Connection Type', 'properties.CIP_Method of Installation', 'properties.CIP_Lookup');
        window.sharedData = group

        _.forEach(all, g => {       
          let qtt = getQTT(group, g.cipRoomNumber, g.cipContract, g.cipActivity, rangeFactor.values, rangeDuctFactor.values)
          if(Object.keys(qtt).length ===0) return
          let end = ''
          let lookupTable = []
          _.forEach(rangeProductivity.values, o => {
            if (o[0] === '') return
            if (o[0] === g.cipActivity) {
              end = 'start'
              lookupTable.push({
                activity: o[0], installation: o[1], material: o[4], unit: o[6], connection: o[5], method: o[3],
                min: o[o.length - 2], max: o[o.length - 1], productivityValue: o[o.length - 3]
              })
            } else if (end === 'start') {
              end = 'end'
            } else if (end === 'end') {
              return false
            }
          })
          window.tempLookupTable = lookupTable.splice(0)
          let clone = Object.assign({}, qtt);
          _.forEach(qtt, (installationValue, installationKey) => {
            _.forEach(installationValue, (materialValue, materialKey) => {
              _.forEach(materialValue, (unitValue, unitKey) => {
                _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
                  _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                    _.forEach(methodValue, (lookupValue, lookupKey) => {
                      let result = {}
                      _.forEach(lookupValue, (v, k) => {
                        var numb = v.value;
                        numb = +numb.toFixed(3);
                        result[k] = numb
                      })
                      methodValue[lookupKey] = result

                      let result1 = {}
                      _.forEach(lookupValue, (v, k) => {
                        result1[k] = 1
                      })
                      _.forEach(lookupValue, (v, k) => {
                        _.forEach(window.tempLookupTable, o => {
                          if (o.activity ===g. cipActivity && o.installation === installationKey && o.material === materialKey && o.unit === unitKey && o.connection === connectionTypeKey && o.method === methodKey) {
                            if (lookupKey === 'Size') {
                              if (o.min <= v.lookup && v.lookup <= o.max) {
                                let numb = o.productivityValue * v.factor
                                numb = +numb.toFixed(3);
                                result1[k] = numb
                              }
                            } else {
                              let numb = o.productivityValue * v.factor
                              numb = +numb.toFixed(3);
                              result1[k] = numb
                            }
                          }
                        })
                      })
                      clone[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = result1
                    })
                  })
                })
              })
            })
          })
          let indexStart = `P${g.index + 2}:P${g.index + 2}`
          let indexEnd = `Q${g.index + 2}:Q${g.index + 2}`
          sheetCIP.getRange(indexStart).values = [[JSON.stringify(qtt)]]
          sheetCIP.getRange(indexEnd).values = [[JSON.stringify(clone)]]
        })

        return context.sync();
      })

  })
}
function getQTT(group, cipRoomNumber, cipContract, cipActivity, rangeFactor, rangeDuctFactor) {
  let check = true
  let tempInstallation = {}
  _.forEach(group, (roomNameValue, roomNameKey) => {
    if (roomNameKey === cipRoomNumber && check) {
      _.forEach(roomNameValue, (contractValue, contractKey) => {
        if (contractKey === cipContract && check) {
          _.forEach(contractValue, (activityValue, activityKey) => {
            if (activityKey === cipActivity && check) {
              _.forEach(activityValue, (installationValue, installationKey) => {
                tempInstallation[installationKey] = {}
                _.forEach(installationValue, (materialValue, materialKey) => {
                  tempInstallation[installationKey][materialKey] = {}
                  _.forEach(materialValue, (unitValue, unitKey) => {
                    tempInstallation[installationKey][materialKey][unitKey] = {}
                    _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
                      tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey] = {}
                      _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                        tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey] = {}
                        _.forEach(methodValue, (lookupValue, lookupKey) => {
                          tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = {}
                          let dictionary = {}
                          _.forEach(lookupValue, (object) => {
                            let factor = 1
                            let ductFactor = 1
                            if (object.properties['CIP_Installation Height']) {
                              let offset = _.toNumber(object.properties['CIP_Installation Height']) / 1000
                              _.forEach(rangeFactor, f => {
                                if (f[0] <= offset)
                                  factor = f[1]
                              })
                            }
                            if (cipActivity === 'Duct Work Installation') {
                              if (object.properties['CIP_DuctWork Classification']) {
                                _.forEach(rangeDuctFactor, f => {
                                  if (f[0] === object.properties['CIP_DuctWork Classification'])
                                    ductFactor = f[1]
                                })
                              }
                            }
                            if (lookupKey === 'Name') {
                              if (object.properties['Type Name']) {
                                let number = 0
                                if (!dictionary[object.properties['Type Name']])
                                  dictionary[object.properties['Type Name']] = { lookup: number, value: 0, factor: factor * ductFactor }
                                if (unitKey === 'per m2') {
                                  if (object.properties['Area']) {
                                    let split = object.properties['Area'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per m3') {
                                  if (object.properties['Volume']) {
                                    let split = object.properties['Volume'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per kg') {
                                  if (object.properties['CIP_Weight']) {
                                    let split = object.properties['CIP_Weight'].split(' ')
                                    let toNumber = _.toNumber(split[0])
                                    if (_.isNumber(toNumber))
                                      dictionary[object.properties['Type Name']].value = dictionary[object.properties['Type Name']].value + toNumber
                                  }
                                } else if (unitKey === 'per item') {
                                  dictionary[object.properties['Type Name']].value += 1
                                }

                              }
                            } else if (lookupKey === 'Size' && unitKey === 'per item') {
                              let value = object.properties[lookupKey]
                              let x = value.replace(/x/g, " ")
                              let symbol = x.replace(/-/g, " ")
                              let split = symbol.split(' ')
                              let number = 0
                              _.forEach(split, lookNumb => {
                                let tempLookNum = _.toNumber(lookNumb)
                                if (_.isNumber(tempLookNum)) {
                                  if (number <= tempLookNum)
                                    number = tempLookNum
                                }
                              })
                              if (!dictionary[value])
                                dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                              dictionary[value].value += 1
                            } else {
                              if (object.properties[lookupKey]) {
                                let number = 0
                                let value = object.properties[lookupKey]
                                if (unitKey === 'per meter') {
                                  let split = value.split('x')
                                  _.forEach(split, v => {
                                    let subSplit = v.split(' ')
                                    let tempNumber = _.toNumber(subSplit[0])
                                    if (_.isNaN(tempNumber))
                                      tempNumber = 0
                                    if (tempNumber > number)
                                      number = tempNumber
                                  })
                                  if (!dictionary[value]) {
                                    dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                                  }
                                } else {
                                  if (!dictionary[value]) {
                                    dictionary[value] = { lookup: number, value: 0, factor: factor * ductFactor }
                                  }
                                }
                                if (unitKey === 'per meter') {
                                  if (object.properties['Length']) {
                                    let split = object.properties['Length'].split(' ')
                                    let length = _.toNumber(split[0])
                                    dictionary[value].value = dictionary[value].value + (length === 0 ? length : length / 1000)
                                  }
                                } else if (unitKey === 'per m2') {
                                  if (object.properties['Area']) {
                                    let split = object.properties['Area'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per m3') {
                                  if (object.properties['Volume']) {
                                    let split = object.properties['Volume'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per kg') {
                                  if (object.properties['CIP_Weight']) {
                                    let split = object.properties['CIP_Weight'].split(' ')
                                    dictionary[value].value = dictionary[value].value + _.toNumber(split[0])
                                  }
                                } else if (unitKey === 'per item') {
                                  let number = dictionary[value].value
                                  dictionary[value].value = number + 1
                                }
                              }
                            }
                          })
                          tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = dictionary
                        })
                      })
                    })
                  })
                })
              })
            } else if (!check) {
              return false
            }
          })
        } else if (!check) {
          return false
        }
      })

    } else if (!check) {
      return false
    }
  })

  return tempInstallation
}
export async function pushExcelToServer(email) {
  await window.Excel.run(async context => {



    let sheetProductivity = context.workbook.worksheets.getItemOrNullObject("Productivity");
    let productivityTable = sheetProductivity.tables.getItem("productivityTable");

    let sheetFactor = context.workbook.worksheets.getItemOrNullObject("Factor");
    let factorTable = sheetFactor.tables.getItem("factorTable");

    let sheetDuctFactor = context.workbook.worksheets.getItemOrNullObject("Duct Factor");
    let ductFactorTable = sheetDuctFactor.tables.getItem("ductFactorTable");

    let sheet = context.workbook.worksheets.getItemOrNullObject("Project Data");
    let expensesTable = sheet.tables.getItem("projectDataTable");


    await context.sync()
    if (expensesTable.isNull || productivityTable.isNull || factorTable.isNull || ductFactorTable.isNull) return 'Error'
    let range = expensesTable.getDataBodyRange().load("values");
    let rangeProductivity = productivityTable.getDataBodyRange().load("values");
    let rangeDuctFactor = ductFactorTable.getDataBodyRange().load("values");
    let rangeFactor = factorTable.getDataBodyRange().load("values");
    // Read the range address
    range.load("values");
    rangeProductivity.load("values");
    rangeDuctFactor.load("values");
    rangeFactor.load("values");
    await context.sync()

    let data = range.values.slice(0)
    let temp = []
    let projectId = ''
    _.forEach(data, (v, k) => {
      if (v[0] === 'Project') {
        projectId = v[v.length - 1]
      } else if (v[0] === 'File' && v[0] !== '') {
        temp.push(v[v.length - 1])
      }
    })
    let resList = [];
    _.forEach(temp, async v => {
      let response = axios.get('/api/items/get-excel-default-version', { params: { projectId: projectId, itemId: v } })
      resList.push(response);
    })
    let itemFiles = await Promise.all(resList);
    resList = [];
    window.excelFileCount = itemFiles.length
    _.forEach(itemFiles, v => {
      axios.get(`/api/forge/modelderivative/metadatas/${v.data.objectId}/{3D}/${email}`)
    })
  })
}


export async function arrangeSchedule(cb) {
  await window.Excel.run(async context => {

    let sheetCIP = context.workbook.worksheets.getItemOrNullObject("CIP");
    let cipTable = sheetCIP.tables.getItem("cipTable");
    await context.sync()
    if (cipTable.isNull) return
    let rangeCIP = cipTable.getDataBodyRange().load("values");
    return context.sync()
      .then(() => {
        let all = []
        let data = rangeCIP.values.slice(0)
        _.forEach(data, (v, idx) => {
          if (v[0] !== '' && v[2] !== '' && v[4] !== '' && v[6] !== '' && v[7] !== '' && v[20] !== '' && v[20] !== 0) {
            all.push({
              index: idx, 'Level Gantt': v[0], 'CAT': v[2], 'Room Name': v[4], 'Contractor': v[6], 'Task': v[7], degree: v[23], averageHeight: _.toNumber(v[18]),
              time: Math.ceil(v[20]), start_date: v[21] === '' ? '' : moment(fromExcelDate(v[21])), end_date: v[22] === '' ? '' : moment(fromExcelDate(v[22]))
            })
          } else if (v[0] !== '' && v[2] !== '' && v[4] !== '' && v[6] !== '' && v[7] === 'Site testing') {
            all.push({
              index: idx, 'Level Gantt': v[0], 'CAT': v[2], 'Room Name': v[4], 'Contractor': v[6], 'Task': v[7], degree: v[23], averageHeight: -1,
              time: Math.ceil(v[20]), start_date: v[21] === '' ? '' : moment(fromExcelDate(v[21])), end_date: v[22] === '' ? '' : moment(fromExcelDate(v[22]))
            })
          }
        })
        let temp = []
        let group = groupArray(all, 'Room Name');
        _.forEach(group, (obj, roomKey) => {
          if (roomKey === 'B2050') {
            multisort(obj, ['degree', '~averageHeight'])
            let date = null
            _.forEach(obj, v => {
              if (v.end_date && v.degree.startsWith('D1')) {
                date = v.end_date
              }
            })
            _.forEach(obj, v => {
              if (v.degree.startsWith('D1')) return
              if (v.Task === 'Site testing') {
                let start = date.clone().add(1, 'days')
                v['start_date'] = start.toDate();
                let end = start.clone().add(1, 'days')
                v['end_date'] = end.toDate();
                temp.push(v)
                date = end
              } else {
                let start = date.clone().add(1, 'days')
                v['start_date'] = start.toDate();
                let end = start.clone().add(v.time, 'days')
                v['end_date'] = end.toDate();
                temp.push(v)
                date = end
              }
            })
            // console.log(obj)
          }
        })
        _.forEach(temp, (v) => {
          let indexStart = `V${v.index + 2}:V${v.index + 2}`
          let indexEnd = `W${v.index + 2}:W${v.index + 2}`
          sheetCIP.getRange(indexStart).values = [[toExcelDate(v.start_date)]]
          sheetCIP.getRange(indexEnd).values = [[toExcelDate(v.end_date)]]
        })
        message.success('Ok')
        cb(false)
        return context.sync();
      })

  })
}

export async function extractInfo(cb) {
  await window.Excel.run(async context => {

    let sheetCIP = context.workbook.worksheets.getItemOrNullObject("CIP");
    let cipTable = sheetCIP.tables.getItem("cipTable");
    if (window.sharedData) {
      await context.sync()
      if (cipTable.isNull) return
      let rangeCIP = cipTable.getDataBodyRange().load("values");
      context.sync()
        .then(async () => {
          let cipData = matchData(rangeCIP, window.sharedData)
          rangeCIP.values = cipData;
          message.success('Ok')
          cb(false)
          return await context.sync();
        })
    } else {
      let sheet = context.workbook.worksheets.getItemOrNullObject("Project Data");
      let expensesTable = sheet.tables.getItem("projectDataTable");

      await context.sync()
      if (expensesTable.isNull || cipTable.isNull) return
      let range = expensesTable.getDataBodyRange().load("values");
      let rangeCIP = cipTable.getDataBodyRange().load("values");

      context.sync()
        .then(async () => {
          let data = range.values.slice(0)
          let temp = []
          let projectId = ''
          _.forEach(data, (v, k) => {
            if (v[0] === 'Project') {
              projectId = v[v.length - 1]
            } else if (v[0] === 'File' && v[0] !== '') {
              temp.push(v[v.length - 1])
            }
          })
          let resList = [];
          _.forEach(temp, async v => {
            let response = axios.get('/api/items/get-excel-default-version', { params: { projectId: projectId, itemId: v } })
            resList.push(response);
          })
          let itemFiles = await Promise.all(resList);
          resList = [];
          _.forEach(itemFiles, v => {
            let response = axios.get(`/api/forge/modelderivative/metadatas/${v.data.objectId}/{3D}`)
            resList.push(response);
          })

          let response = await Promise.all(resList);
          let all = []
          _.forEach(response, item => {
            let tempObj = getAllObject(item.data.object)
            _.forEach(tempObj, (v, k) => {
              let index = _.findIndex(item.data.properties, function (o) { return o.objectid === v.objectid });
              if (index >= 0) {
                let tempProps = {}
                _.forEach(item.data.properties[index].properties, p => {
                  _.forEach(p, (o, k) => {
                    tempProps[k] = o
                  })
                })
                item.data.properties[index].properties = tempProps
                all.push(item.data.properties[index])
              }
            })
          })
          // console.log(all)
          let group = groupArray(all, 'properties.CIP_Room Number', 'properties.CIP_Contract', 'properties.CIP_Activity', 'properties.CIP_Installation Type', 'properties.CIP_Material', 'properties.CIP_Unit',
            'properties.CIP_Connection Type', 'properties.CIP_Method of Installation', 'properties.CIP_Lookup');
          window.sharedData = group
          let cipData = matchData(rangeCIP, window.sharedData)
          rangeCIP.values = cipData;
          message.success('Ok')
          cb(false)
          return await context.sync();
        })
    }

  })
}

const matchData = (rangeCIP, group) => {
  let check = true
  let cipData = rangeCIP.values.slice(0)

  _.forEach(cipData, v => {
    if (v[0] === '' || v[2] === '' || v[4] === '' || v[5] === '') return
    let tempInstallation = {}
    let tempAverageHeight = {}
    let averageHeight = 0
    let count = 0
    _.forEach(group, (roomNameValue, roomNameKey) => {
      if (roomNameKey === v[4] && check) {
        _.forEach(roomNameValue, (contractValue, contractKey) => {
          if (contractKey === v[6] && check) {
            _.forEach(contractValue, (activityValue, activityKey) => {
              if (activityKey === v[7] && check) {
                _.forEach(activityValue, (installationValue, installationKey) => {
                  tempInstallation[installationKey] = {}
                  tempAverageHeight[installationKey] = {}
                  _.forEach(installationValue, (materialValue, materialKey) => {
                    tempInstallation[installationKey][materialKey] = {}
                    tempAverageHeight[installationKey][materialKey] = {}
                    _.forEach(materialValue, (unitValue, unitKey) => {
                      tempInstallation[installationKey][materialKey][unitKey] = {}
                      tempAverageHeight[installationKey][materialKey][unitKey] = {}
                      _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
                        tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey] = {}
                        tempAverageHeight[installationKey][materialKey][unitKey][connectionTypeKey] = {}
                        _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                          tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey] = {}
                          tempAverageHeight[installationKey][materialKey][unitKey][connectionTypeKey][methodKey] = {}
                          _.forEach(methodValue, (lookupValue, lookupKey) => {
                            tempInstallation[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = ''


                            _.forEach(lookupValue, (object) => {
                              if (object.properties['CIP_Installation Height']) {
                                averageHeight = averageHeight + _.toNumber(object.properties['CIP_Installation Height']) / 1000
                                count++
                              }
                            })
                            // var numb = averageHeight / count
                            // numb = +numb.toFixed(3);
                            // tempAverageHeight[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey] = numb

                          })
                        })
                      })
                    })
                  })
                })
              } else if (!check) {
                return false
              }
            })
          } else if (!check) {
            return false
          }
        })
      } else if (!check) {
        return false
      }
    })
    // v[9] = tempInstallation.length === 0 ? '' : JSON.stringify(tempInstallation)
    // v[10] = tempMaterial.length === 0 ? '' : JSON.stringify(tempMaterial)
    // v[11] = tempUnit.length === 0 ? '' : JSON.stringify(tempUnit)
    // v[12] = tempConnection.length === 0 ? '' : JSON.stringify(tempConnection)
    // v[13] = tempMethod.length === 0 ? '' : JSON.stringify(tempMethod)
    v[14] = Object.keys(tempInstallation).length === 0 ? '' : JSON.stringify(tempInstallation)
    //v[18] =  Object.keys(tempAverageHeight).length === 0 ? '' : JSON.stringify(tempAverageHeight)
    let numb = averageHeight / count
    numb = +numb.toFixed(3);
    v[18] = numb
  })
  return cipData
}

export async function shareDataGantt(token) {
  await window.Excel.run(async context => {
    let sheet = context.workbook.worksheets.getItemOrNullObject("CIP");
    let expensesTable = sheet.tables.getItem("cipTable");
    await context.sync()
    if (expensesTable.isNull) return
    let range = expensesTable.getDataBodyRange().load("values");
    range.load("values");
    await context.sync()
    let data = range.values.slice(0)
    axios.post('/api/gantt/trigger-gantt', { data: data, token: token })
    return data
  })
}

export function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
export async function paseValueCIP() {
  await window.Excel.run(async context => {
    const range = context.workbook.getSelectedRange();


    window.cipParseValue = ''
    // Read the range address
    range.load("values");
    range.load("address");
    await context.sync()
    console.log(`The range address was ${range.address}.`);
    let data = range.values.slice(0)
    if (data.length === 1) {
      if (data[0].length === 2) {
        let parse = JSON.parse(data[0][0])
        let parse1 = JSON.parse(data[0][1])
        if (_.isObject(parse) && _.isObject(parse1)) {
          let temp = []
          _.forEach(parse, (installationValue, installationKey) => {
            _.forEach(installationValue, (materialValue, materialKey) => {
              _.forEach(materialValue, (unitValue, unitKey) => {
                _.forEach(unitValue, (connectionTypeValue, connectionTypeKey) => {
                  _.forEach(connectionTypeValue, (methodValue, methodKey) => {
                    _.forEach(methodValue, (lookupValue, lookupKey) => {
                      _.forEach(lookupValue, (object, key) => {
                        let factor = 1
                        if (parse1[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey][key])
                          factor = parse1[installationKey][materialKey][unitKey][connectionTypeKey][methodKey][lookupKey][key]
                        temp.push({ installationKey, materialKey, unitKey, connectionTypeKey, methodKey, lookupKey, name: key, value: object, factor })
                      })
                    })
                  })
                })
              })
            })
          })
          window.cipParseValue = temp
          return parse
        }
      }
    }
    return ''
  })
}

export function createProjectDataTable(data) {
  window.Excel.run(context => {
    context.workbook.worksheets.getItemOrNullObject("Project Data").delete();
    const sheet = context.workbook.worksheets.add("Project Data");
    let expensesTable = sheet.tables.add(`A1:C1`, true /*hasHeaders*/);
    expensesTable.name = "projectDataTable";

    expensesTable.getHeaderRowRange().values = [["Type", "Name", "Id"]];
    expensesTable.rows.add(null /*add rows to the end of the table*/, data);

    if (window.Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
      sheet.getUsedRange().format.autofitColumns();
      sheet.getUsedRange().format.autofitRows();
      sheet.getUsedRange().getColumn(2).columnHidden = true;
    }

    sheet.activate();
    window.sharedData = undefined
    return context.sync();
  })
}

export async function pushDataViewerToExcel(viewer) {

  try {
    await window.Excel.run(async context => {
      /**
       * Insert your Excel code here
       */
      const range = context.workbook.getSelectedRange();

      // Read the range address
      range.load("address");
      range.load("values");

      // Update the fill color
      // range.format.fill.color = "yellow";

      context.sync()
        .then(() => {
          let data = range.values.slice(0)
          // console.log(`The range address was ${range.address}.`);
          let temp = []
          _.forEach(data, v => {
            temp.push(v[0])
          })
          let tempDbIds = getAllElementdbIdsOneModel(viewer)
          let tempValues = {}
          let count = tempDbIds.length
          _.forEach(tempDbIds, dbId => {
            viewer.getProperties(dbId, (result) => {
              let category = ''
              let value = 0
              _.forEach(result.properties, property => {
                if (property.displayName === 'Category') {
                  category = property.displayValue
                  if (property.displayValue.includes('Revit')) {
                    category = property.displayValue.replace('Revit ', '')
                  }
                }
                else if (property.displayName === 'Length') {
                  value = property.displayValue
                }
              })
              if (temp.includes(category) && _.isNumber(value)) {
                if (!tempValues[category])
                  tempValues[category] = 0
                tempValues[category] = tempValues[category] + value
              }
              count--
              if (count === 0) {
                console.log(tempValues);
                _.forEach(data, v => {
                  if (tempValues[v[0]])
                    v[v.length - 1] = tempValues[v[0]]
                })
                range.values = data;
                range.format.autofitColumns();
                context.sync();
              }
            })
          })
        })



    });
  } catch (error) {
    console.error(error);
  }
}