const rebarDOTempColumns = [
  {
    type: "text",
    data: "do_number",
    title: "DO No",
  },

  {
    type: "date",
    data: "do_date",
    title: "Do Date",
    dateFormat: "DD-MM-YYYY",
    correctFormat: true,
    datePickerConfig: {
      dateFormat: "DD-MM-YYYY",
    },
  },

  {
    type: "text",
    data: "product",
    title: "Product",
  },

  {
    type: "numeric",
    data: "weight_qty",
    title: "Weight/Qty",
  },

  {
    // type: "text",
    title: "Supplier",
    data: "supplier",
    editor: "select",
    selectOptions: ["NASTEEL", "SUPERBEND", "LEE MESH"],
  },

  {
    type: "text",
    data: "remark",
    title: "Remark",
  },
];

export default rebarDOTempColumns;
