
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { message } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded, showErrorLoadDocument, setupForgeExtensionBeforeLoadedIssuePage,
  getForgeToken, replaceSpinner, getAllModelsElementdbIdsWithCondition
} from '../../../components/module_BimApp/function/ForgeFunction'
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction'
import { Position, Toaster, } from "@blueprintjs/core";

const Autodesk = window.Autodesk;

let documentId;
let elementColor = null
let viewPort = null
let count = 0
function IssueViewer(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [markUp2d, setMarkUp2d] = useState(false)
  // const [modelLoaded, setModelLoaded] = useState(false)
  const toaster = useRef()

  useEffect(() => {

      launchViewer(props.objectId)
    return () => {
      if (viewer) {
        viewer.tearDown()
        viewer.finish()
        $("#forgeViewer").empty();
      }
    }
  }, [])
  const launchViewer = (urn) => {
    getForgeToken().then(token => {
      let options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
          "Autodesk.AEC.LevelsExtension",
          "Autodesk.AEC.Minimap3DExtension",
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d)
        viewer.start();
        setViewer(viewer)
        documentId = 'urn:' + urn;
      });
    })
  }
  useEffect(e => {
    if (viewer)
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess.bind(this, viewer), onDocumentLoadFailure);
  }, [viewer])
  const onDocumentLoadSuccess = (viewer, doc) => {
    doc.downloadAecModelData()
    let viewables = doc.getRoot().search({ 'type': 'geometry', 'guid': props.mainLoadOptions.guid }, true)
    if (viewables.length === 0) {
      message.warning(`View isn't existing in file, load default view`)
      viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true);
    }
    if (viewables.length !== 0) {
      viewer.loadDocumentNode(doc, viewables[0], props.mainLoadOptions)
        .then(i => {
          unloadForgeExtension(viewer, false)
          viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, geometryLoaded)
        })
        .catch(err => {
          showErrorLoadDocument('forgeViewer', 'Load model failed', 0)
        })
      replaceSpinner();
    } else {
      showErrorLoadDocument('forgeViewer', `File isn't contain any view`, 0)
    }
  }
  const geometryLoaded = () => {
    viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, geometryLoaded)
    setupForgeExtensionAfterLoaded(viewer, null, false)
    setGlobalOffset(viewer.impl.model.myData.globalOffset)
  }
  const onDocumentLoadFailure = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
  }
  useEffect(e => {
    if (!viewer) return
    if (!globalOffset && viewer.impl.model.myData.loadOptions.fileExt !== 'rvt') return
    elementColor = props.issue.attachElementColor
    viewPort = props.issue.viewPort
    count = props.issue.otherModel.length
    if (props.issue.otherModel.length > 0) {
      _.forEach(props.issue.otherModel, (v, k) => {
        axios.post(`/api/issue/get-model`, { item: v, isLastVersion: props.check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), (viewerErrorCode) => { console.log(viewerErrorCode) });
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(err => {
            message.error(`Can't load model.`);
            count--
          })
      })
    } else {
      handleSetColorElement(props.issue.attachElementColor)
    }
  }, [globalOffset])
  const _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      viewer.loadModel(svfUrl, loadOptions, _onLoadModelSuccess, () => { })
    }
  }
  const _onLoadModelSuccess = () => {
    if (count === 0) {
      handleSetColorElement(elementColor)
    }
  }

  const handleSetColorElement = (elements) => {
    let models = viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      viewer.clearThemingColors(model)
    })
    let isAllLoaded = viewer.isLoadDone({ onlyModels: models })
    if (isAllLoaded) {
      setupForgeExtensionBeforeLoadedIssuePage(viewer, props.handleChangeMarkup2DPanel, props.markUp2d)
      let countModel = models.length
      let tempElement = {}
      _.forEach(models, async model => {
        let instanceTree = model.getData().instanceTree;
        let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
        countModel--
        let count1 = temp.length
        _.forEach(temp, id => {
          model.getProperties(id, (modelAProperty) => {
            tempElement[modelAProperty.externalId] = id
            count1--
            if (count1 === 0) {
              _.forEach(elements, async el => {
                let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                if (index >= 0) {
                  if (tempElement[el.guid]) {
                    let color = convertHexColorToVector4(el.color)
                    viewer.setThemingColor(tempElement[el.guid], color, models[index])
                  }
                }
              })
              if (props.setModelLoaded) props.setModelLoaded(false)
              viewer.restoreState(viewPort)
              props.handleOpenDialogIssueInformation(viewer, globalOffset)
            }
          })
        })
      })
    } else {
      viewer.waitForLoadDone({ onlyModels: models })
        .then(res => {
          setupForgeExtensionBeforeLoadedIssuePage(viewer, props.handleChangeMarkup2DPanel, props.markUp2d)
          let countModel = models.length
          let tempElement = {}
          _.forEach(models, async model => {
            let instanceTree = model.getData().instanceTree;
            let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
            countModel--
            let count1 = temp.length
            _.forEach(temp, id => {
              model.getProperties(id, (modelAProperty) => {
                tempElement[modelAProperty.externalId] = id
                count1--
                if (count1 === 0) {
                  _.forEach(elements, async el => {
                    let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                    if (index >= 0) {
                      if (tempElement[el.guid]) {
                        let color = convertHexColorToVector4(el.color)
                        viewer.setThemingColor(tempElement[el.guid], color, models[index])
                      }
                    }
                  })
                  if (props.setModelLoaded) props.setModelLoaded(false)
                  viewer.restoreState(viewPort)
                  props.handleOpenDialogIssueInformation(viewer, globalOffset)
                }
              })
            })
          })
        })
    }
  }

  return (
    <>
      <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
      <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
      <div style={{ zIndex: 1000, width: '100%', height: '100%' }} id='forgeViewer' />
      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />

    </>

  )
}

IssueViewer.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(IssueViewer)