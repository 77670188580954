import React, {  } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import MaterialTable from 'material-table';
import { message, Popconfirm, Icon, Typography, Collapse } from 'antd';
import { Modal, Button, Divider, Input } from 'rsuite';
import { Tooltip, IconButton, Checkbox } from '@material-ui/core';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import _ from 'lodash'
const { Text } = Typography
const { Panel } = Collapse;
class UsersControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'Email', field: 'email' },
      
        {
          title: 'Confirm', field: 'comfirm', align: 'center',
          render: rowData => <Checkbox checked={rowData.comfirm} onChange={this.handleChange(rowData.email, 'checkedComfirm')} color="primary"
            value="checkedComfirm" />
        },
        {
          title: 'Action', field: 'action',align: 'center',
          render: rowData =>
            rowData.email === 'admin@wohhup.com' ?
              <Tooltip title="Delete">
                <IconButton onClick={this.handleChangePasswordPanel.bind(this, rowData.email)} >
                  <EditOutlinedIcon style={{ fontSize: 30 }} color="primary" /></IconButton>
              </Tooltip>
              :
              <Popconfirm
                title=" Are you sure to delete user ?" onConfirm={this.deleteUser(rowData.email)} okText="Yes" cancelText="No"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              >
                <Tooltip title="Delete">
                  <IconButton disabled={rowData.email === 'admin@wohhup.com'}>
                    <DeleteOutlineRoundedIcon style={{ fontSize: 30 }} color="error" /></IconButton>
                </Tooltip>
              </Popconfirm>

        },
      ],
      data: [],
      displayPanel: false,
      newPassword: '',
      repeatNewPassword: '',
      oldPassword: '',
      loading: false,
      email: '',
    }

  }
  componentWillMount = () => {
    axios.get(`/api/users/getusers`)
      .then(res => {
        this.setState({ data: res.data })
        this.props.handleLoading(false)

      })
      .catch(err => {
        this.props.handleLoading(false)
        console.log(err)
      })
  }

  handleChangePasswordPanel = (emailCurrent) => {
    this.setState({ displayPanel: true, email: emailCurrent })
  }
  handleClose = () => {
    this.setState({ displayPanel: false })
  }
  handleChangeNewPasswordValue = (value) => {
    this.setState({ newPassword: value })
  }
  handleChangeRepeatNewPasswordValue = (value) => {
    this.setState({ repeatNewPassword: value })
  }
  handleChangeOldPasswordValue = (value) => {
    this.setState({ oldPassword: value })
  }
  handleChangePassword = () => {
    if (this.state.newPassword === this.state.repeatNewPassword) {
      if (!window.confirm('Are you sure to change password?')) return
      axios.post(`/api/users/change-password`, { email: this.state.email, newPassword: this.state.newPassword, oldPassword: this.state.oldPassword })
        .then(() => {
          message.success(`Password changed successful.`)
        })
        .catch(() => {
          message.warning(`Can't change password`)
        })
    } else {
      message.warning(`New password isn't match`)
    }
  }
  handleChange = (emailCurrent, permissionCurrent) => () => {
    axios.post(`/api/users/setrole`, { email: emailCurrent, permission: permissionCurrent })
      .then(res => {
        message.success(`${emailCurrent} changed successful.`)
        this.setState({ data: res.data })

      })
      .catch(() => {
        message.error(`${emailCurrent} changed failed.`)
      })
  };
  deleteUser = (emailCurrent) => () => {
    axios.delete(`/api/users/delete`, { data: { email: emailCurrent } })
      .then(() => {
        // message.success(`${emailCurrent} was successful.`)
        // this.setState({data: res.data})

        window.location.reload()
      })
  }

  render() {
    return (
      <>
        <MaterialTable
          title="Users Control"
          columns={this.state.columns}
          data={this.state.data}
          options={{
            headerStyle: {
              backgroundColor: '#40a9ff',
              color: '#FFF',
              fontSize: 'large',
              textAlign: 'center',
            },
            paging: true,
            pageSizeOptions: [5,20,100,1000]
          }}
        />
        <Modal show={this.state.displayPanel} onHide={this.handleClose} size='xs' backdrop='static' style={{ top: 60 }} className='dialog-share-project'>
          <Modal.Header>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Divider className='custom-divider' />
          <Modal.Body >
            <Text strong> New Password</Text><br />
            <Input type="password"  placeholder='New Password' style={{ width: '100%' }} size='sm' onChange={this.handleChangeNewPasswordValue} 
            value={this.state.newPassword} /><br />
            <Text strong> Repeat New Password</Text><br />
            <Input type="password" placeholder='Repeat New Password' style={{ width: '100%' }} size='sm' onChange={this.handleChangeRepeatNewPasswordValue}
             value={this.state.repeatNewPassword} /><br />
            <Text strong> Old Password</Text><br />
            <Input type="password"  placeholder='Old Password' style={{ width: '100%' }} size='sm' onChange={this.handleChangeOldPasswordValue}
             value={this.state.oldPassword} /><br />
          </Modal.Body>
          <Divider className='custom-divider' />
          <Modal.Footer>
            <Button appearance="primary" onClick={this.handleChangePassword} loading={this.state.loading}> Change</Button>
            <Button appearance="default" onClick={this.handleClose} > Cancel</Button>
          </Modal.Footer>
        </Modal>



       
      </>

    );
  }
}

UsersControl.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email,
    email: state.user.email,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(UsersControl)
