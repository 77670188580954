import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { Progress, Dropdown, Header } from 'semantic-ui-react';
import {
  Button,
  Modal,
  Row,
  Col,
  notification,
  Checkbox,
  Upload,
  Icon,
  Select,
} from 'antd';
import { socket } from '../../../components/module_BimApp/function/AdminFunction';
import axios from '../../module_Worker/api/axiosClient';
import ExportCastingReportPdf from './ExportCastingReportPdf';
import _ from 'lodash';
import PDFMerger from 'pdf-merger-js/browser';
import moment from 'moment';

import { Input } from 'antd';
const { Option } = Select;
const { TextArea } = Input;

// API
async function apiGetDOByPourName(payload) {
  return await axios.post(
    '/api/do_inv/common/get-concrete-rebar-dos-by-pour-name',
    payload
  );
}

async function apiExportCastingReportToPdf(payload) {
  return await axios.post(
    '/api/forge/da4revit/v1/revit/export-image-by-pour-name',
    payload
  );
}

async function apiDownloadFileByLink(payload) {
  return await axios.get(payload, { responseType: 'blob' });
}

async function apiGetLinkDownloadConcreteDo(payload) {
  return await axios.get('/api/do_inv/s3/getlink', { params: payload });
}

async function apiGetEmailCcList(payload) {
  return await axios.get('/api/casting-report/get-email-cc-list', {
    params: payload,
  });
}

async function apiUploadFileCastingReport(payload) {
  let form_data = new FormData();

  for (let key in payload.data) {
    form_data.append(key, payload.data[key]);
  }
  if (payload.file !== null) {
    form_data.append('file', payload.file);
  }

  return await axios.post(
    '/api/casting-report/upload-casting-report-pdf',
    form_data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

//Components
CastingReport.propTypes = {
  onSubmit: PropTypes.func,
  isVisible: PropTypes.bool,
};

CastingReport.defaultProps = {
  onSubmit: null,
  isVisible: false,
};

function CastingReport(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [statusText, setStatusText] = useState(
    'Step 1/2 : Export Images 3d view , 2d view.'
  );

  const [messageCastingReportNumber, setMessageCastingReportNumber] =
    useState('');
  const [progressBarStatus, setProgressBarStatus] = useState(0);
  const [dataConcreteDO, _setDataConcreteDO] = useState([]);
  const [currentData, _setCurrentData] = useState({});
  const [workItemId, _setWorkItemId] = useState(null);
  const [includeConcreteDoPdfs, _setIncludeConcreteDoPdfs] = useState(true);
  const [castingReportNumber, _setCastingReportNumber] = useState('0001');
  const [pourLocation, _setPourLocation] = useState('');
  const [engineerInCharge, _setEngineerInCharge] = useState('');
  const [note, _setNote] = useState('');
  const [emails, setEmails] = useState([]);
  const [selectedEmailCcs, setSelectedEmailCcs] = useState([]);
  const [selectedPourNames, setSelectedPourNames] = useState([]);
  const [fileList, _setFileList] = useState([]);
  const [addinVersion, setAddinVersion] = useState('2022');

  const addinVersions = [
    { key: '2020', text: 'Revit 2020', value: '2020' },
    { key: '2022', text: 'Revit 2022', value: '2022' },
  ];

  const workItemIdRef = React.useRef(workItemId);
  const dataConcreteDORef = React.useRef(dataConcreteDO);
  const currentDataRef = React.useRef(currentData);
  const noteRef = React.useRef(note);
  const castingReportNumberRef = React.useRef(castingReportNumber);
  const includeConcreteDoPdfsRef = React.useRef(includeConcreteDoPdfs);
  const pourLocationRef = React.useRef(pourLocation);
  const engineerInChargeRef = React.useRef(engineerInCharge);
  const fileListRef = React.useRef(fileList);

  useEffect(() => {
    setStatus(0);
  }, [isModalVisible]);

  useEffect(() => {
    getEmailCcs();
  }, [props.email, props.projectId, isModalVisible]);

  useEffect(() => {
    if (castingReportNumber && _.isString(castingReportNumber)) {
      let checkData = checkReportNumber(castingReportNumber);
      if (checkData.error && checkData.error?.length > 0) {
        setMessageCastingReportNumber(checkData.error);
      } else {
        setMessageCastingReportNumber('');
      }
    }
  }, [castingReportNumber]);

  async function getEmailCcs() {
    let res = await apiGetEmailCcList({
      email: props.email,
      projectId: props.projectId,
    });

    if (_.isArray(res) && res.length > 0) {
      let last = res[res.length - 1];

      let arEmails = [];
      res.forEach((r) => {
        arEmails = arEmails.concat(r.emailCcs);
      });

      arEmails = _.uniq(arEmails);
      arEmails.sort();

      setEmails(arEmails);

      setSelectedEmailCcs(last.emailCcs);

      console.log('arEmails', arEmails);
    }
  }

  function setIncludeConcreteDoPdfs(data) {
    includeConcreteDoPdfsRef.current = data;
    _setIncludeConcreteDoPdfs(data);
  }

  function setFileList(data) {
    fileListRef.current = data;
    _setFileList(data);
  }

  function handleChangeEmailCC(value) {
    if (_.isArray(value)) {
      let last = value[value.length - 1];
      if (validateEmail(last) || value.length == 0 || true) {
        setSelectedEmailCcs(value);
      } else {
        notification['error']({
          message: 'Error!',
          description: 'Email is not valid , please enter another email!',
        });
      }
    }
  }

  function handleChangeSelectedPours(value) {
    if (_.isArray(value)) {
      setSelectedPourNames(value);
    }
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function getTotalDesignConcrete() {
    if (props.reportData && props.reportData.all) {
      let concretes = props.reportData.all
        .filter((x) => selectedPourNames.includes(x.pourName))
        .map((x) => x.concreteVolumnDesign);

      if (concretes.length > 0) {
        return _.round(_.sum(concretes), 2);
      }
    }

    return 0;
  }

  function setNote(data) {
    noteRef.current = data;
    _setNote(data);
  }

  function setCastingReportNumber(data) {
    castingReportNumberRef.current = data;
    _setCastingReportNumber(data);
  }

  function setWorkItemId(data) {
    workItemIdRef.current = data;
    _setWorkItemId(data);
  }

  function setCurrentData(data) {
    currentDataRef.current = data;
    _setCurrentData(data);
  }

  function setDataConcreteDO(data) {
    dataConcreteDORef.current = data;
    _setDataConcreteDO(data);
  }

  function setPourLocation(data) {
    pourLocationRef.current = data;
    _setPourLocation(data);
  }

  function setEngineerInCharge(data) {
    engineerInChargeRef.current = data;
    _setEngineerInCharge(data);
  }

  useEffect(() => {
    if (
      props.reportData &&
      props.reportData.current &&
      props.reportData.current.pourName
    ) {
      setSelectedPourNames(props.reportData.selectedPourName);
      setCurrentData(props.reportData.current);
      let number = checkReportNumber(null).numberString;
      setCastingReportNumber(number);
      //get new
    }
  }, [props.projectId, props.reportData]);

  useEffect(() => {
    if (props.reportData && selectedPourNames && selectedPourNames.length > 0) {
      getDatas();
    }
  }, [selectedPourNames]);

  function checkReportNumber(currentNumber) {
    if (props.reportData && _.isArray(props.reportData.all)) {
      if (props.reportData.current.castingReportNumber) {
        return {
          error: null,
          numberString: props.reportData.current.castingReportNumber,
        };
      }

      let numberStrings = props.reportData.all
        .map((x) => x.castingReportNumber)
        .filter((x) => x != props.reportData.current.castingReportNumber);

      let numbers = numberStrings
        .map((x) => parseInt(x))
        .filter((x) => _.isNumber(x));

      numbers.sort();
      let number = 1;
      if (numbers.length > 0) {
        number = numbers[numbers.length - 1] + 1;
      }

      let numberString = String(number).padStart(4, '0');

      if (currentNumber && numberStrings.includes(currentNumber)) {
        return {
          error: `Casting report number : ${currentNumber} already exist!`,
          numberString,
        };
      }

      return {
        error: null,
        numberString,
      };
    }
    return {
      error: null,
      numberString: '',
    };
  }

  useEffect(() => {
    if (props.showCastingReport > 0) {
      setIsModalVisible(true);
      setFileList([]);
    }
  }, [props.showCastingReport]);

  useEffect(() => {
    socket.on('Workitem-Notification' + props.email, handleExportReportSocket);

    apiGetEmailCcList({
      email: props.email,
      projectId: props.projectId,
    });

    return () => {
      socket.removeListener('realtime-excel', handleExportReportSocket);
    };
  }, [props.email]);

  async function handleExportReportSocket(data) {
    if (data && data.WorkitemId === workItemIdRef.current) {
      if (data.Status == 'completed') {
        setStatus(4);
        notification.success({
          message: 'Info!',
          description: 'Export Casting Report Successfully !',
          duration: 5,
        });
      } else {
        setStatus(-1);
        notification.error({
          message: 'Export Casting Report Error',
          description: 'Error',
          duration: 5,
        });
      }
    }
  }

  async function getDatas() {
    let doDatasRequest = {
      project_id: props.projectId,
      pourNames: selectedPourNames,
    };

    let res = await apiGetDOByPourName(doDatasRequest);
    if (res && res.data) {
      setDataConcreteDO(
        res.data.concreteDoDatas.map((x) => ({
          key: x.id,
          product: x.product,
          quantity: x.volume,
          doNumber: x.do_number,
          date: x.do_date,
          product: x.product,
          volume: x.volume,
          remark: x.remark,
          orderBy: x.order_by,
          keyDownload: `${props.projectId}/${'conc_do'}/${
            x.s3_key
          }`.toLowerCase(),
          pourName: x.pour_name,
        }))
      );
    }
  }

  function formatDateToSetRevitParams(date) {
    if (date) {
      try {
        return moment(date, 'DD/MM/YYYY').format('DD/MM/YY');
      } catch (error) {
        return '';
      }
    }

    return '';
  }

  async function handleOk() {
    //sort email cc

    if (!props.reportData.current.view2d && !props.reportData.current.view2d) {
      notification['error']({
        message: 'Error!',
        description: 'View 2d and view 3d must be required',
      });

      return;
    }

    let ids = props.reportData.all
      .filter((x) => selectedPourNames.includes(x.pourName))
      .map((x) => x.id);

    let payload = {
      inputRvt: props.reportData.inputRvtCurrent,
      appBundleName:
        addinVersion == '2022'
          ? 'ExportImagesCastingReport_2022Activity'
          : 'ExportImagesCastingReport_2020Activity',
      inputJson: {
        View2d: props.reportData.current.view2d,
        View3d: props.reportData.current.view3d,
        PourName: props.reportData.current.pourName,
        PourNames: selectedPourNames,
        PourDate:
          props.reportData.current.actualDateStart +
          '->' +
          props.reportData.current.actualDateFinish,
        CastedList: props.reportData.all
          .filter((x) => x.status === 'CASTED')
          .map((x) => ({
            Name: x.pourName,
            Date:
              formatDateToSetRevitParams(x.actualDateStart) +
              '->' +
              formatDateToSetRevitParams(x.actualDateFinish),
          })),
        RebarList: props.reportData.all
          .filter((x) => x.status === 'REBAR')
          .map((x) => ({
            Name: x.pourName,
            Date: formatDateToSetRevitParams(x.actualDateStart) + '->' + 'N/A',
          })),
        FormworkList: props.reportData.all
          .filter((x) => x.status === 'FORMWORK')
          .map((x) => ({
            Name: x.pourName,
            Date: formatDateToSetRevitParams(x.actualDateStart) + '->' + 'N/A',
          })),
        OtherList: props.reportData.all
          .filter((x) => _.isEmpty(x.status) || _.isNil(x.status))
          .map((x) => ({
            Name: x.pourName,
            Date:
              formatDateToSetRevitParams(x.planDateStart) +
              '->' +
              formatDateToSetRevitParams(x.planDateFinish),
          })),
      },
      castingReportData: {
        id: currentDataRef.current.id,
        ids,
        email: props.email,
        cc: selectedEmailCcs,
        note: noteRef.current,
        reportNumber: castingReportNumberRef.current,
        projectName: props.projectName,
        projectId: props.projectId,
        pourName: selectedPourNames.join(),
        castingDate: currentDataRef.current?.castingDate,
        engineer: engineerInChargeRef.current,
        pourLocation: pourLocationRef.current,
        dataConcreteDO: dataConcreteDORef.current,
        concreteVolumeDesign: getTotalDesignConcrete(),
        concreteVolumeActual: _.sumBy(dataConcreteDORef.current, 'volume'),
        drawingKey: null,
      },
      createdBy: props.email,
    };

    if (selectedEmailCcs && selectedEmailCcs.length > 0) {
      let ccEmails = _.uniq(selectedEmailCcs);
      ccEmails.sort();
      ccEmails = ccEmails.map((x) => x.toLowerCase());

      payload.castingReportData.cc = ccEmails;
    }

    if (fileList.length > 0) {
      const merger = new PDFMerger();
      await Promise.all(fileList.map(async (file) => await merger.add(file)));

      const mergedPdf = await merger.saveAsBlob();

      let payloadUploadToS3 = {
        data: {
          projectId: props.projectId,
        },
        file: mergedPdf,
      };

      let uploadFileResKey = await apiUploadFileCastingReport(
        payloadUploadToS3
      );
      payload.castingReportData.drawingKey = uploadFileResKey;
    }

    try {
      let res = await apiExportCastingReportToPdf(payload);

      setWorkItemId(res.WorkitemId);
      setStatus(1);
    } catch (error) {
      setStatus(-1);
    }
  }

  async function downloadAllPdfs() {
    let arPromise = dataConcreteDORef.current.map((x) =>
      downloadFilePdfByKey(x)
    );

    let res = await Promise.all(arPromise);
    return res;
  }

  async function downloadFilePdfByKey(x) {
    try {
      let res = await apiGetLinkDownloadConcreteDo({
        file_name: x.keyDownload,
      });
      let fileRes = await apiDownloadFileByLink(res.signedRequest);
      return {
        pourName: x.pourName,
        dataFile: fileRes,
      };
    } catch (error) {
      return {
        pourName: x.pourName,
        dataFile: null,
      };
    }
  }

  function handleCancel(e) {
    setIsModalVisible(false);
  }

  function setStatus(status = 0) {
    if (status === 1) {
      setStatusText('Step 1/3 : Export Images 3d view , 2d view.');
      setProgressBarStatus(33);
    } else if (status === 2) {
      setStatusText('Step 2/3 : Export Casting Report Pdf');
      setProgressBarStatus(50);
    } else if (status === 3) {
      setStatusText(
        'Step 3/3 Merge Concrete DO pdf files and upload to server!'
      );
      setProgressBarStatus(100);
    } else if (status === 4) {
      setStatusText('Export Casting Report Pdf Successfully!');
      setProgressBarStatus(100);
    } else if (status === 0) {
      setStatusText('Step 1/2 : Export Images 3d view , 2d view.');
      setProgressBarStatus(0);
    } else if (status === -1) {
      setStatusText('Export Casting Report Pdf unsuccessfully!');
      setProgressBarStatus(0);
    } else if (status === -2) {
      setStatusText('');
      setProgressBarStatus(0);
    }
  }

  const debounceNote = useCallback(
    _.debounce((nextValue) => setNote(nextValue), 1000),
    []
  );

  const debounceCastingReportNumber = useCallback(
    _.debounce((nextValue) => setCastingReportNumber(nextValue), 1),
    []
  );

  function handleNoteChange(e) {
    let value = e.target?.value;

    debounceNote(value);
  }

  function handleCastingReportNumberChange(e) {
    let value = e.target?.value;

    setCastingReportNumber(value);
  }

  function onChangeIncludeConcreteDo(e) {
    setIncludeConcreteDoPdfs(e.target.checked);
  }

  function handleImport(e) {
    setFileList([...fileList, e]);
    return false;
  }

  function handleRemove(file) {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  function optPourNames() {
    if (
      props.reportData &&
      props.reportData.all &&
      _.isArray(props.reportData.all)
    ) {
      return props.reportData.all.map((x) => {});
    }
  }

  return (
    <div>
      <Modal
        width={1000}
        title="Export Casting Report"
        onCancel={() => handleCancel()}
        visible={isModalVisible}
        footer={
          <div className="">
            {progressBarStatus > 0 && (
              <div>
                <Header as="h3" style={{ textAlign: 'left' }}>
                  {statusText}
                </Header>
                <Progress
                  style={{ height: 25 }}
                  percent={progressBarStatus}
                  indicating
                />
              </div>
            )}
            <div className="d-flex flex-row justify-content-end">
              <Dropdown
                onChange={(e, data) => {
                  console.log('e', data);
                  setAddinVersion(data.value);
                }}
                placeholder="Addin version"
                value={addinVersion}
                fluid
                style={{
                  marginRight: 5,
                  width: '180px',
                  height: '33px',
                  minHeight: '33px',
                }}
                selection
                options={addinVersions}
                defaultValue={addinVersion}
              />
              <Button key="submit" type="primary" onClick={handleOk}>
                Export Casting Report
              </Button>
              <Button key="back" onClick={handleCancel}>
                Close
              </Button>
            </div>
          </div>
        }
      >
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={10}>
                <Row gutter={16}>
                  <Col span={8}>
                    <p>Report Number</p>
                  </Col>
                  <Col span={16}>
                    <Input
                      maxLength={4}
                      value={castingReportNumber}
                      onChange={handleCastingReportNumberChange}
                      allowClear={true}
                      className=" mb-2"
                    />
                    <span className="text-danger">
                      {messageCastingReportNumber}
                    </span>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <p>Pour Location</p>
                  </Col>
                  <Col span={16}>
                    <Input
                      value={pourLocation}
                      onChange={(e) => setPourLocation(e.target.value)}
                      allowClear={true}
                      className=" mb-2"
                    />
                    <span className="text-danger"></span>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <p>Engineer in Charge</p>
                  </Col>
                  <Col span={16}>
                    <Input
                      value={engineerInCharge}
                      onChange={(e) => setEngineerInCharge(e.target.value)}
                      allowClear={true}
                      className=" mb-2"
                    />
                    <span className="text-danger"></span>
                  </Col>
                </Row>
              </Col>

              <Col span={7}>
                <div className="">
                  <Upload
                    accept=".pdf"
                    multiple={false}
                    fileList={fileList}
                    beforeUpload={handleImport}
                    onRemove={handleRemove}
                  >
                    <Button>
                      <Icon type="upload" />
                      Shop drawing use for castings
                    </Button>
                  </Upload>
                </div>
                <div className=" mt-2">
                  <Checkbox
                    checked={includeConcreteDoPdfs}
                    onChange={onChangeIncludeConcreteDo}
                  >
                    Included DO form in this report
                  </Checkbox>
                </div>
              </Col>

              <Col span={7}>
                <div>
                  <p>Explanation Of wastage</p>
                  <TextArea
                    style={{ maxHeight: 100 }}
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    onChange={handleNoteChange}
                    allowClear={true}
                    className=" mb-2"
                  />
                </div>
              </Col>
            </Row>

            <div className=" mt-1"></div>

            <Row gutter={16}>
              <Col span={3}>
                <p>Email CC :</p>
              </Col>
              <Col span={21}>
                <Select
                  mode="tags"
                  style={{ width: '100%', marginLeft: '12px' }}
                  value={selectedEmailCcs}
                  onChange={handleChangeEmailCC}
                  tokenSeparators={[',']}
                >
                  {emails && emails.map((x) => <Option key={x}>{x}</Option>)}
                </Select>
                <span className="text-danger"></span>
              </Col>
            </Row>
            <div className=" mt-2"></div>
            <Row gutter={16}>
              <Col span={3}>
                <p>Pour Elements :</p>
              </Col>
              <Col span={21}>
                <Select
                  mode="tags"
                  value={selectedPourNames}
                  onChange={handleChangeSelectedPours}
                  style={{ width: '100%', marginLeft: '12px' }}
                  tokenSeparators={[',']}
                >
                  {_.isArray(props.reportData?.all) &&
                    props.reportData.all.map((x) => (
                      <Option key={x.pourName}>{x.pourName}</Option>
                    ))}
                </Select>
                <span className="text-danger"></span>
              </Col>
            </Row>

            <PDFViewer width={950} height={400} className="mt-2">
              <ExportCastingReportPdf
                pdfContent={{
                  note: note,
                  projectName: props.projectName,
                  reportNumber: castingReportNumber,
                  pourName: selectedPourNames.join(),
                  castingDate: props.reportData?.current?.castingDate,
                  pourDescription: '',
                  png3d: '',
                  png2d: '',
                  dataConcreteDO,
                  concreteVolumeDesign: getTotalDesignConcrete(),
                }}
              />
            </PDFViewer>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default CastingReport;
