import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UsersControl,RealTimeControl,DashBoardControl,AddinControl,TrimbleControl,ProjectControl} from '../admin'
import { Layout, Menu, Icon } from 'antd';
import { Loader } from 'rsuite';
import { socket } from '../../components/module_BimApp/function/AdminFunction'
const { Sider } = Layout;

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      loading: false,
      key: '3',
      dataUser: []
    }

  }
  componentWillMount() {
    socket.on("server-send-list-users", this.handleRealtimeServerSendListUsers)
  }
  handleRealtimeServerSendListUsers = (data) => {
    this.setState({ dataUser: data })
  }

  componentWillUnmount = () => {
    socket.removeListener("server-send-list-users", this.handleRealtimeServerSendListUsers)
  }

  handleFoldMenu = () => { this.setState({ collapsed: !this.state.collapsed }) };
  handleSelectMenu = ({ key }) => {
    if (key !== 'end')
      this.setState({ key: key, loading: this.state.key !== key })
  }
  handleLoading = (value) => { this.setState({ loading: value }) }

  render() {
    return (
      <div>
        {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 50 }} />}
        <Layout style={{ height: 'calc(100vh - 53.78px)' }}>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ backgroundColor: '#fff', zIndex: 100 }}>
            <Menu mode="vertical" onClick={this.handleSelectMenu} defaultSelectedKeys={this.state.key} >
              <Menu.Item key='1'>
                <Icon type="user" />
                <span style={{ verticalAlign: 'middle' }}>Users Control</span>
              </Menu.Item>
              <Menu.Item key='2' >
                <Icon type='project' />
                <span style={{ verticalAlign: 'middle' }}>Projects Control</span>
              </Menu.Item>
              <Menu.Item key='3' >
                <Icon type="area-chart" />
                <span style={{ verticalAlign: 'middle' }}>Real Time</span>
              </Menu.Item>
              <Menu.Item key='4' >
                <Icon type="dashboard" />
                <span style={{ verticalAlign: 'middle' }}>DashBoard</span>
              </Menu.Item>
              <Menu.Item key='5' >
                <Icon type="heat-map" />
                <span style={{ verticalAlign: 'middle' }}>Add-in</span>
              </Menu.Item>
              <Menu.Item key='6' >
                <Icon type='login' />
                <span style={{ verticalAlign: 'middle' }}>TrimbleConnect</span>
              </Menu.Item>
              <Menu.Item key='end' style={{ position: 'absolute', bottom: 0, width: '100%' }} onClick={this.handleFoldMenu}>
                <Icon type={this.state.collapsed ? "menu-unfold" : "menu-fold"} />
                <span style={{ verticalAlign: 'middle' }}>Collapse Menu</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>

            {this.state.key === '1' &&
              <UsersControl handleLoading={this.handleLoading} />}
            {this.state.key === '2' &&
              <ProjectControl handleLoading={this.handleLoading} />}
            {this.state.key === '3' &&
              <RealTimeControl handleLoading={this.handleLoading} dataUser={this.state.dataUser} />}
            {this.state.key === '4' &&
              <DashBoardControl handleLoading={this.handleLoading} dataUser={this.state.dataUser} />}
            {this.state.key === '5' &&
              <AddinControl handleLoading={this.handleLoading} />}
            {this.state.key === '6' &&
              <TrimbleControl handleLoading={this.handleLoading} />}
          </Layout>
        </Layout>

      </div>


    );
  }
}

AdminPage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {

    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(AdminPage)