import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import decode from "jwt-decode";
import App from "./App";
import { userLoggedIn, logout } from "./actions/app/auth";
import * as serviceWorker from "./serviceWorker";
import {
   trackingUser,
   initMicrosoft,
   controlUrl,
   checkWohhupUser,
} from "./components/module_BimApp/function/AdminFunction";
import { store } from "./store";
import "./scss/module_BimApp/style.scss";
import "./scss/module_DMS/style.scss";
import "./scss/module_Worker/style.scss";
import "./scss/module_DoInv/style.scss";
import "./scss/module_QaQc/style.scss";
import "./scss/module_QaQc/Dialog.scss";
import "./scss/module_DataEntry/style.scss";
import "./scss/module_Manpower/style.scss";
import "semantic-ui-css/semantic.min.css";
import "react-contexify/dist/ReactContexify.min.css";
import "rsuite/dist/styles/rsuite-default.min.css";
import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-medium-image-zoom/dist/styles.css";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import "react-base-table/styles.css";
import "handsontable/dist/handsontable.full.css";
import { LicenseManager } from '@ag-grid-enterprise/core'
// import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { isMobile } from "react-device-detect";

LicenseManager.setLicenseKey('CompanyName=Woh Hup (Private) Limited,LicensedApplication=Phan Manh Quyet and Bao Quy Lan EXT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-025389,ExpiryDate=14_February_2023_[v2]_MTY3NjMzMjgwMDAwMA==33c01eb89aa03aca91c9624c438584f1')
initMicrosoft();
// Check if the API is supported.
if ("setExperimentalAppBadge" in navigator) {
   console.log("setExperimentalAppBadge");
}
// Check if the previous API surface is supported.
if ("ExperimentalBadge" in window) {
   console.log("ExperimentalBadge");
}

// controlUrl()
if (localStorage.bookwormJWT) {
   const payload = decode(localStorage.bookwormJWT);
   if (
      payload.email === "admin@wohhup.com" ||
      !checkWohhupUser(payload.email)
   ) {
      trackingUser(payload.email);
      const user = {
         token: localStorage.bookwormJWT,
         email: payload.email,
         role: payload.role,
         confirmed: payload.confirmed,
         projects: payload.projects,
         permission: payload.permission,
         contractors: payload.contractors,
      };
      store.dispatch(userLoggedIn(user));
   } else if (localStorage.userWh5Dapp) {
      if (
         payload.email ===
         JSON.parse(localStorage.userWh5Dapp).mail.toLowerCase()
      ) {
         trackingUser(payload.email);
         const user = {
            token: localStorage.bookwormJWT,
            email: payload.email,
            role: payload.role,
            confirmed: payload.confirmed,
            projects: payload.projects,
            permission: payload.permission,
            imgUrl: payload.imgUrl,
            contractors: payload.contractors,
         };
         store.dispatch(userLoggedIn(user));
      } else {
         logout(payload.email);
      }
   }
}

ReactDOM.render(
   <BrowserRouter>
      <Switch>
         <Provider store={store}>
            <Route component={App} />
         </Provider>
      </Switch>
   </BrowserRouter>,
   document.getElementById("root")
);
serviceWorker.register();

//serviceWorker.unregister();

// Notification.requestPermission()
// .then(async permission=>{
//   if (permission === 'granted'){
//     // message.success('Permission was granted');
//   }else{
//     message.warning('Permission wasn\'t granted. Allow a retry.');
//   }
// })
