import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import _ from 'lodash'
import axios from "axios";
import { Message, Button, Form, Header } from 'semantic-ui-react'
import Split from 'react-split'
import { Icon, Sidenav, Nav, Dropdown, Loader, IconStack } from 'rsuite';
import Tooltip from '@material-ui/core/Tooltip';
import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded,
  getForgeToken, onSelectionRelative
  , replaceSpinner
} from '../../../components/module_BimApp/function/ForgeFunction'
import {BottomBarTimelineLeftSharePage} from '../../module_BimApp/sidebar'

import {
  ProgressPPVCChart,
  PieChartPPVC,
  SummaryPPVC,
  PPVCInformation,
  ProgressPrecastChart,
  SummaryPrecast,
  PieChartPrecast,
  Button_WH_ShareLeft,
  Button_WH_ShareRight
} from '../../module_BimApp/dock_forge'


import { message } from 'antd';
import { trackingUser, storeHistory } from '../../../components/module_BimApp/function/AdminFunction'
import {DialogReport} from '../../module_BimApp/dialog'
import TrimbleViewer from '../TrimbleConnect/TrimbleViewer'
const Autodesk = window.Autodesk;

class ShareViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      password: '',
      inputPassword: '',
      confirmPassword: false,
      isShare: false,
      viewerCurrent: null,
      viewerCurrent2D: null,
      aecModelData: null,

      itemId: null,
      itemName: null,
      folderSmartSheetId: '',
      shareOptions: null,
      projectKey: '',
      typeProject: '',
      openView2D: false,
      smartSheetId: '',
      displayPanel: {
        timelinePanel: false,
      },
      openDialog: {
        dialogReport: false,
      },
      openCustomDockForge: {
        progressDock: false,
        summaryPPVCDock: false,
        ppvcInformationDock: false,
        pieChartPPVCDock: false,

        summaryPrecastDock: false,
        pieChartPrecastDock: false,
        progressPrecastDock: false,
      },
      modelLoaded: true,
      link: {
        linkWeeklyReport: '',
        linkDeliveryVsStatus: '',
        linkSitePhoto: '',
      },
      isTrimble: false,
      trimbleFileId:'',
      trimbleVersionId:''
    }
    this.globalOffset = { x: 0, y: 0, z: 0 }
    this.onceTime = true
  }
  componentWillMount = () => {
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    this.setState({ loading: true })
    axios.post("/api/items/shareview", { token: this.props.match.params.token })
      .then(res => {
        this.setState({
          id: res.data.objectId,
          password: res.data.password,
          confirmPassword: res.data.password.length === 0 ? true : false,
          isShare: res.data.isShare,
          loading: false,
          shareOptions: res.data.shareOptions,
          itemId: res.data.itemId,
          itemName: res.data.itemName,
          projectName: res.data.projectName,
          folderName: res.data.folderName,
          folderSmartSheetId: res.data.folderSmartSheetId,
          smartSheetId: res.data.smartSheetId,
          projectKey: res.data.projectKey,
          typeProject: res.data.typeProject,
          isTrimble: res.data.isTrimble,
          trimbleFileId:res.data.trimbleFileId,
          trimbleVersionId:res.data.trimbleVersionId
        }, () => {
          if (this.state.confirmPassword) {
            trackingUser('External User', this.state.projectName, this.state.folderName, this.state.itemName)
            storeHistory('External User', this.state.itemId, this.state.itemName)
            if (!this.state.isTrimble) {
              $('#content2D').hide();
              $('#content3D').width('100%');
              $('.gutter-horizontal').hide();
              this.launchViewer(this.state.id)

              axios.get("/api/items/get-link-report", { params: { itemId: this.state.itemId, auth: false } })
                .then(res => {
                  this.setState({
                    link: res.data.link,
                  })
                })
                .catch(() => { })
            }

          }
        });
      })
      .catch(() => this.setState({ loading: false }))
  }
  handleView2D = () => {
    const { id } = this.state;
    var _this = this;
    this.setState({ openView2D: !this.state.openView2D }, () => {
      if (!this.state.openView2D) {
        $('#content2D').hide();
        $('#content3D').width('100%');
        $('.gutter-horizontal').hide();
        this.state.viewerCurrent.resize();
        this.state.viewerCurrent2D.tearDown()
        this.state.viewerCurrent2D.finish()
        this.setState({ viewerCurrent2D: null })
        $("#forgeViewer2D").empty();
        this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (e) => onSelectionRelative(e, this.state.viewerCurrent2D, _this.state.viewerCurrent));
      } else {
        $('#content2D').show();
        $('#content3D').width('50%');
        $('.gutter-horizontal').show();
        this.state.viewerCurrent.resize();
        this.launchViewer2D(id)
      }
    })
  }
  launchViewer = (urn) => {
    getForgeToken().then(token => {
      let options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
          "Autodesk.AEC.LevelsExtension",
          "Autodesk.AEC.Minimap3DExtension",
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d)
        viewer.start();
        let documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess, this.onDocumentLoadFailure);
        this.setState({ viewerCurrent: viewer })
      });
    })
  }

  onDocumentLoadSuccess = (doc) => {

    doc.downloadAecModelData().then(res => {
      this.setState({ aecModelData: res })
    })
    let viewer = this.state.viewerCurrent
    let viewables = doc.getRoot().getDefaultGeometry();
    viewer.loadDocumentNode(doc, viewables).then(() => {
      unloadForgeExtension(viewer, true)
      viewer.loadExtension('Autodesk.DocumentBrowser');
      viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
        setupForgeExtensionAfterLoaded(viewer, null, true)
        if (this.onceTime) {
          this.onceTime = false
          if (this.state.shareOptions.CIS)
            this.handleTogglePanel('timelinePanel')
          else if (this.state.shareOptions.PPVC) {
            if (this.state.typeProject === 'PPVC/PBU') {
              this.handleDock('summaryPPVCDock')
              this.handleDockPieChart('pieChartPPVCDock')
            } else if (this.state.typeProject === 'PreCast') {
              this.handleDock('summaryPrecastDock')
              this.handleDockPieChart('pieChartPrecastDock')
            }

          }
        }
        this.globalOffset = viewer.impl.model.myData.globalOffset
        viewer.loadExtension(Button_WH_ShareRight)
        this.setState({ modelLoaded: false })
      })

    });
    replaceSpinner();
  }
  onDocumentLoadFailure = (viewerErrorCode) => {
    var viewerError = document.createElement('div');
    viewerError.id = 'viewer-error-share';
    viewerError.innerHTML = '<span>Could not load document.</span>';
    document.getElementById('forgeViewer').appendChild(viewerError);
    console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
  }
  launchViewer2D = (urn) => {
    getForgeToken().then(token => {
      var options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
          "Autodesk.AEC.LevelsExtension",
          "Autodesk.AEC.Minimap3DExtension",
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer2D'), config3d)
        viewer.start();
        var documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess2D, this.onDocumentLoadFailure2D);
        this.setState({ viewerCurrent2D: viewer })
      });
    })
  }

  onDocumentLoadSuccess2D = (doc) => {
    var viewer = this.state.viewerCurrent2D
    var _this = this
    var viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true);
    if (viewables.length === 0) {
      var viewerError = document.createElement('div');
      viewerError.id = 'viewer-error-share';
      viewerError.innerHTML = '<span>No Sheet In File</span>';
      document.getElementById('forgeViewer2D').appendChild(viewerError);
    }
    viewer.loadDocumentNode(doc, viewables[0]).then(() => {
      unloadForgeExtension(viewer, true)
      viewer.loadExtension('Autodesk.DocumentBrowser');
      viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
        setupForgeExtensionAfterLoaded(viewer, null, true)
        viewer.loadExtension(Button_WH_ShareLeft)
        try {
          _this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (e) => onSelectionRelative(e, _this.state.viewerCurrent2D, _this.state.viewerCurrent));
        } catch { }
        _this.state.viewerCurrent.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (e) => onSelectionRelative(e, _this.state.viewerCurrent2D, _this.state.viewerCurrent));
      })

    });
    replaceSpinner();
  }
  onDocumentLoadFailure2D = () => {
    var viewerError = document.createElement('div');
    viewerError.id = 'viewer-error-share';
    viewerError.innerHTML = '<span>Could not load document.</span>';
    document.getElementById('forgeViewer2D').appendChild(viewerError);
  }

  onSelectionRelative = (e) => {
    try {
      var currSelection = e.target.getSelection();
      if (this.state.viewerCurrent2D.clientContainer.id === e.target.clientContainer.id) {
        if (currSelection.length === 0) {
          this.state.viewerCurrent.clearSelection();
          return
        }
        else {
          this.state.viewerCurrent.select(currSelection);
          return
        }
      }
      else {
        if (currSelection.length === 0) {
          this.state.viewerCurrent2D.clearSelection();
          return
        }
        else {
          this.state.viewerCurrent2D.select(currSelection);
          return
        }
      }
    }
    catch { }
  };

  inputPassword = (event, data) => {
    this.setState({ inputPassword: data.value })
  }
  validPassword = () => {
    if (this.state.inputPassword === this.state.password)
      this.setState({ confirmPassword: true }, () => {
        trackingUser('External User', this.state.projectName, this.state.folderName, this.state.itemName)
        storeHistory('External User', this.state.itemId, this.state.itemName)
        if (!this.state.isTrimble) {
          $('#content2D').hide();
          $('#content3D').width('100%');
          $('.gutter-horizontal').hide();
          this.launchViewer(this.state.id)
          axios.get("/api/items/get-link-report", { params: { itemId: this.state.itemId, auth: false } })
            .then(res => {
              this.setState({
                link: res.data.link,
              })
            })
            .catch(() => { })
        }
       
      });
  }
  dragSplit = () => {
    try {
      this.state.viewerCurrent.resize()
      this.state.viewerCurrent2D.resize()
    } catch { }

  }
  handleClickLinkReport = (name) => {
    if (name !== 'linkQSReport')
      window.open(this.state.link[name])
    else {
      let pass = window.prompt('Password', '')
      if (pass === null) return
      axios.get("/api/items/auth-password-report", { params: { itemId: this.state.itemId, password: pass } })
        .then(res => {
          window.open(res.data.link)
        })
        .catch(err => {
          message.warning(err.response.data.error)
        })
    }
  }

  handleTogglePanel = (name) => {
    let temp = this.state.displayPanel
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    this.setState({ displayName: temp }, () => {
      if (name === 'timelinePanel' && this.state.openView2D) {
        if (this.state.openView2D) {
          $('#content2D').hide();
          $('#content3D').width('100%');
          $('.gutter-horizontal').hide();
          this.state.viewerCurrent.resize();
          this.state.viewerCurrent2D.tearDown()
          this.state.viewerCurrent2D.finish()
          $("#forgeViewer2D").empty();
          this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (e) => onSelectionRelative(e, this.state.viewerCurrent2D, this.state.viewerCurrent));
          this.setState({ viewerCurrent2D: null, openView2D: false })
        }
      }
    })


  }
  handleDock = (name) => {
    let temp = this.state.openCustomDockForge
    if (this.state.typeProject === 'PPVC/PBU') {
      if (name === 'ppvcInformationDock' && temp.summaryPPVCDock) {
        temp['ppvcInformationDock'] = true
        temp['summaryPPVCDock'] = true
      } else {
        _.forEach(temp, (v, k) => {
          if (k === name) {
            temp[k] = !temp[k]
          } else {
            temp[k] = false
          }
        })
      }
    } else if (this.state.typeProject === 'PreCast') {
      if (name === 'ppvcInformationDock' && temp.summaryPPVCDock) {
        temp['ppvcInformationDock'] = true
        temp['summaryPrecastDock'] = true
      } else {
        _.forEach(temp, (v, k) => {
          if (k === name) {
            temp[k] = !temp[k]
          } else {
            temp[k] = false
          }
        })
      }
    } else {
      _.forEach(temp, (v, k) => {
        if (k === name) {
          temp[k] = !temp[k]
        } else {
          temp[k] = false
        }
      })
    }
    this.setState({ openCustomDockForge: temp })
  }
  handleDockPieChart = (name) => {
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      }
    })
    this.setState({ openCustomDockForge: temp })
  }
  callbackDisableBtn = (name, value) => {
    let temp = this.state.disabledBtn
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ disabledBtn: temp })
  }
  callbackDisplayDock = (name, value) => {
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ openCustomDockForge: temp })
  }
  handleDialog = (name) => {
    let temp = this.state.openDialog
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    this.setState({ openDialog: temp })
  }
  callbackDisplayDialog = (name, value) => {
    let temp = this.state.openDialog
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ openDialog: temp })
  }
  render() {

    const { isAuthenticated } = this.props
    return (
      <div>
        {this.state.loading ? <Loader center content="Loading..." speed="fast" size="md" vertical />
          :
          !this.state.isShare ?
            <Message
              error
              header='Action Forbidden'
              content='This file is not share'
            />
            : (this.state.confirmPassword ? (
              !this.state.isTrimble ?
                <>
                  <Sidenav style={{ position: 'absolute', zIndex: '100', height: `calc(100% - ${isAuthenticated ? '53.78px' : '0px'})`, backgroundColor: 'white', width: '40px' }}
                    expanded={false}
                  // activeKey={this.state.activeKey}
                  // onSelect={this.handleSelect}
                  >

                    <Sidenav.Body>
                      <Nav >
                        {this.state.shareOptions.CIS &&
                          <Tooltip title="Schedule" placement="right">
                            <Nav.Item eventKey="1" style={{ height: '40px', width: '40px' }} loading={true}
                              icon={<IconStack style={{ display: 'contents' }}  >
                                <Icon icon="clock-o" stack="1x" style={{ top: 6 }} />
                                <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                              </IconStack>}
                              disabled={this.state.modelLoaded} onClick={this.handleTogglePanel.bind(this, 'timelinePanel', 'Timeline')} />
                          </Tooltip>
                        }
                        {this.state.shareOptions.PPVC &&
                          <>
                            <Tooltip title={this.state.typeProject === 'PPVC/PBU' ? 'PPVC Summary' : this.state.typeProject === 'PreCast' && 'Precast Summary'} placement="right">
                              <Nav.Item eventKey="2" style={{ height: '40px', width: '40px' }}
                                icon={
                                  <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                    <Icon icon="tasks" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                    <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                                  </IconStack>}
                                disabled={this.state.modelLoaded || this.state.smartSheetId === '' || this.state.projectKey === ''}
                                onClick={this.handleDock.bind(this, this.state.typeProject === 'PPVC/PBU' ? 'summaryPPVCDock' : this.state.typeProject === 'PreCast' && 'summaryPrecastDock')} />
                            </Tooltip>
                            <Tooltip title={this.state.typeProject === 'PPVC/PBU' ? 'PPVC PieChart' : this.state.typeProject === 'PreCast' && 'Precast PieChart'} placement="right">
                              <Nav.Item eventKey="2" style={{ height: '40px', width: '40px' }}
                                icon={
                                  <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                    <Icon icon='pie-chart' stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                    <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                                  </IconStack>}
                                disabled={this.state.modelLoaded || this.state.smartSheetId === '' || this.state.projectKey === ''}
                                onClick={this.handleDockPieChart.bind(this, this.state.typeProject === 'PPVC/PBU' ? 'pieChartPPVCDock' : this.state.typeProject === 'PreCast' && 'pieChartPrecastDock')} />
                            </Tooltip>
                            <Tooltip title={this.state.typeProject === 'PPVC/PBU' ? 'PPVC Progress' : this.state.typeProject === 'PreCast' && 'Precast Progress'} placement="right">
                              <Nav.Item eventKey="2" style={{ height: '40px', width: '40px' }}
                                icon={
                                  <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                    <Icon icon='trend' stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                    <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                                  </IconStack>}
                                disabled={this.state.modelLoaded || this.state.smartSheetId === '' || this.state.projectKey === ''}
                                onClick={this.handleDock.bind(this, this.state.typeProject === 'PPVC/PBU' ? 'progressDock' : this.state.typeProject === 'PreCast' && 'progressPrecastDock')} />
                            </Tooltip>
                            <Tooltip title="PPVC Information" placement="right">
                              <Nav.Item eventKey="3"
                                style={{ backgroundColor: this.state.openCustomDockForge.ppvcInformationDock && '#90cbff', height: '40px', width: '40px' }}
                                onClick={this.handleDock.bind(this, 'ppvcInformationDock')}
                                disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                                icon={<IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                  <Icon icon="id-info" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                  <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                                </IconStack>} />
                            </Tooltip>
                            <Tooltip title="Report" placement="right">
                              <Nav.Item eventKey="2" style={{ height: '40px', width: '40px' }}
                                icon={
                                  <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                    <Icon icon='stack-exchange' stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                    <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                                  </IconStack>}
                                disabled={this.state.modelLoaded || this.state.smartSheetId === '' || this.state.projectKey === ''}
                                onClick={this.handleDialog.bind(this, 'dialogReport')} />
                            </Tooltip>
                          </>}
                        <Tooltip title="Link Report" placement="right">
                          <Dropdown placement="rightStart" eventKey="7" style={{
                            height: '40px', width: '40px'
                          }} disabled={this.state.modelLoaded}
                            icon={
                              <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                                <Icon icon="file-o" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                                <Icon icon="share" stack="1x" style={{ fontSize: 10, top: 7 }} />
                                <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                              </IconStack>} >
                            <Dropdown.Item style={{ height: '40px' }} eventKey="7-1" onClick={this.handleClickLinkReport.bind(this, 'linkWeeklyReport')}
                              icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkWeeklyReport === ''}>
                              Weekely Report</Dropdown.Item>
                            <Dropdown.Item style={{ height: '40px' }} eventKey="7-2" onClick={this.handleClickLinkReport.bind(this, 'linkDeliveryVsStatus')}
                              icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkDeliveryVsStatus === ''}
                            >Delivery Schudule VS Status</Dropdown.Item>
                            <Dropdown.Item style={{ height: '40px' }} eventKey="7-3" onClick={this.handleClickLinkReport.bind(this, 'linkSitePhoto')}
                              icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkSitePhoto === ''}
                            >Site Photos/Videos</Dropdown.Item>
                            <Dropdown.Item style={{ height: '40px' }} eventKey="7-4" onClick={this.handleClickLinkReport.bind(this, 'linkQSReport')}
                              icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />}>
                              QS Report</Dropdown.Item>
                          </Dropdown>
                        </Tooltip>
                      </Nav>
                    </Sidenav.Body>

                  </Sidenav>

                  <Split id='splitForgeViewer'
                    ref={this.pdfViewerRef}
                    sizes={[50, 50]} minSize={100} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
                    style={{
                      position: "absolute", height: `calc(100% - ${isAuthenticated ? '53.78px' : '0px'})`,
                      width: "calc(100% - 40px)", display: 'flex', justifyItems: 'center', alignItems: 'center', left: '40px'
                    }} onDrag={this.dragSplit}
                  >
                    <div id='content3D' style={{ height: '100%', width: "100%" }}>
                      <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
                      <Header id='dateTimeline4DR' style={{ zIndex: '5', position: 'fixed', display: 'none', marginTop: 2 }} as='h5' />
                      <div style={{ position: 'relative', width: '100%' }}>
                        <Header id='showScheduleType3D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, left: 'calc(50% - 73px)', color: 'red' }} as='h3' />
                      </div>
                      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        <Header id='showVolumePer3D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, top: '50%', color: 'red' }} as='h4' />
                      </div>
                      <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }}>  </div>
                    </div>
                    <div id='content2D' style={{ height: '100%', width: "100%" }}>
                      <div style={{ zIndex: '5', position: 'fixed', marginTop: 2 }}>
                        <Header id='dateTimeline4DL' style={{ marginBottom: 2, display: 'none' }} as='h5' />
                        <Header style={{ fontSize: 'small', margin: 0 }}>
                          <Icon icon='circle' style={{ paddingRight: '10px', color: '#ff0000' }}></Icon>
                Delay </Header>
                        <Header style={{ fontSize: 'small', margin: 0 }}>
                          <Icon icon='circle' style={{ paddingRight: '10px', color: '#0095ff' }}></Icon>
                Early </Header>
                      </div>
                      <div style={{ position: 'relative', width: '100%' }}>
                        <Header id='showScheduleType2D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, left: 'calc(50% - 73px)', color: 'red' }} as='h3' />
                      </div>
                      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        <Header id='showVolumePer2D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, top: '50%', color: 'red' }} as='h4' />
                      </div>
                      <div id="forgeViewer2D" style={{ position: "relative", height: '100%', width: "100%" }}></div>
                    </div>
                  </Split>



                  {this.state.displayPanel.timelinePanel && <BottomBarTimelineLeftSharePage key={'1'} itemId={this.state.itemId}
                    displayPanel={this.state.displayPanel.timelinePanel} viewer={this.state.viewerCurrent} viewer2D={this.state.viewerCurrent2D}
                    folderSmartSheetId={this.state.folderSmartSheetId} onDisabledBtnChange={this.callbackDisableBtn}
                    heightNavigation={'53.78px'} model2DLoaded={this.state.model2DLoaded} handleView2D={this.handleView2D} projectKey={this.state.projectKey}
                    typeProject={this.state.typeProject}
                  />}
                  {/* <ProgressPPVCChart viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                  itemId={this.state.itemId} onChangeDockDisplay={this.callbackDisplayDock} openDock={this.state.openCustomDockForge.progressDock} /> */}
                  {this.state.openCustomDockForge.summaryPPVCDock &&
                    <>
                      <SummaryPPVC viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                        itemId={this.state.itemId} smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.state.typeProject}
                        openDock={this.state.openCustomDockForge.summaryPPVCDock} aecModelData={this.state.aecModelData} globalOffset={this.globalOffset}
                        permission={{
                          roleAdmin: false,
                          roleQS: false,
                          roleEngineer: false,
                          roleSubCon: false,
                          roleClient: false,
                          roleViewer: true,
                          roleUploader: false
                        }} userRole={""} />

                    </>
                  }

                  {this.state.openCustomDockForge.summaryPrecastDock &&
                    <>
                      <SummaryPrecast viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                        itemId={this.state.itemId} smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.state.typeProject}
                        openDock={this.state.openCustomDockForge.summaryPrecastDock} aecModelData={this.state.aecModelData} globalOffset={this.globalOffset}
                        permission={{
                          roleAdmin: false,
                          roleQS: false,
                          roleEngineer: false,
                          roleSubCon: false,
                          roleClient: false,
                          roleViewer: true,
                          roleUploader: false
                        }} userRole={""} />

                    </>
                  }
                  {this.state.openCustomDockForge.pieChartPPVCDock &&
                    <PieChartPPVC viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                      itemId={this.state.itemId} smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
                      openDock={this.state.openCustomDockForge.pieChartPPVCDock} typeProject={this.state.typeProject}
                    />
                  }
                  {this.state.openCustomDockForge.pieChartPrecastDock &&
                    <PieChartPrecast viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                      itemId={this.state.itemId} smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
                      openDock={this.state.openCustomDockForge.pieChartPrecastDock} typeProject={this.state.typeProject}
                    />
                  }
                  {this.state.openCustomDockForge.progressDock &&
                    <ProgressPPVCChart viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                      itemId={this.state.itemId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.state.typeProject}
                      openDock={this.state.openCustomDockForge.progressDock} />}
                  {this.state.openCustomDockForge.progressPrecastDock &&
                    <ProgressPrecastChart viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                      itemId={this.state.itemId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.state.typeProject}
                      openDock={this.state.openCustomDockForge.progressPrecastDock} />}
                  {this.state.openCustomDockForge.ppvcInformationDock &&
                    <PPVCInformation viewer={this.state.viewerCurrent} projectKey={this.state.projectKey}
                      itemId={this.state.itemId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.state.typeProject}
                      openDock={this.state.openCustomDockForge.ppvcInformationDock} />}

                  <DialogReport openPanel={this.state.openDialog.dialogReport} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
                    folderSmartSheetId={this.state.folderSmartSheetId} smartSheetId={this.state.smartSheetId} isClient={true} />
                </>
                :
                <TrimbleViewer
                  trimbleFileId={this.state.trimbleFileId}
                  trimbleVersionId={this.state.trimbleVersionId}
                />
            )
              : <div className="container" style={{ height: "100%" }}>
                <div className="row align-items-center" style={{ height: "100%" }}>
                  <div className="col col-xs-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
                    <div className="card">
                      <h2 className="card-header">You have to input password to view file</h2>
                      <div className="card-body">
                        <Form >
                          <Form.Input icon='lock'
                            iconPosition='left'
                            label='Password'
                            type='password'
                            onChange={this.inputPassword} />
                          <Button onClick={this.validPassword}>Submit </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

      </div>
    );
  }
}

ShareViewPage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(ShareViewPage)