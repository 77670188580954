import React, { useState, useEffect } from "react";
import axios from "../../module_Worker/api/axiosClient";
import _ from "lodash";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { connect, useSelector } from "react-redux";
import { Button, Table, Upload, notification, Spin } from "antd";

import { Icon } from "rsuite";

import { HotTable } from "@handsontable/react";

// API
async function apiPostDataImport(payload, projectId) {
  return await axios.post("/api/archi-library/data", {
    data: payload,
    projectId: projectId,
  });
}

async function apiGetData(projectId) {
  return await axios.get(
    "/api/archi-library/get-data-by-project-id/" + projectId
  );
}

async function apiReloadActual(projectId) {
  return await axios.put(
    "/api/archi-library/reload-actual-data/" + projectId
  );
}

async function apiSaveData(payload) {
  return await axios.put("/api/archi-library/save-data", payload);
}

let columns = [
  { data: "breakdown1", width: 125, },
  { data: "breakdown2", width: 200 },
  { data: "breakdown3", width: 200 },
  { data: "units", width: 50, type: 'dropdown', source: ['Area', 'Length', 'Volume', 'Nos'] },
  { data: "code", width: 50, },
  { data: "revit", width: 50, },
];

function ArchiLibrary(props) {
  const [fileExcel, setFileExcel] = useState(null);
  const [hotData, setHotData] = useState([]);
  const [removeRowIds, setRemoveRowIds] = useState([]);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [loadingTip, setLoadingTip] = useState("");
  const hotTableRef = React.createRef();

  const [columnHeaders, setColumnHeaders] = useState([
    [
      "Breakdown 1",
      "Breakdown 2",
      "Breakdown 3",
      "Units",
      "5D Code",
      "Revit",
    ],
  ]);

  const [dataScheme, setDataScheme] = useState({
    breakdown1: "breakdown1",
    breakdown2: "breakdown2",
    breakdown3: "breakdown3",
    units: "units",
    code: "code",
    revit: "revit",

  });

  const contextMenuHotTable = {
    items: {
      row_above: {
        name: "Insert rows above",
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt("How many rows you want to add", "1");
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: uuidv4(),
                    projectId: props.location.state.projectId,
                    isAdd: true,
                  };
                  let copy = Object.assign({}, item);
                  copy.row_status = "add";
                  temp.splice(selection[selection.length - 1].end.row, 0, item);
                  start++;
                }
                setHotData(temp);
              }
            }
          }
        },
      },
      row_below: {
        name: "Insert rows below",
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt("How many rows you want to add", "1");
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: uuidv4(),
                    projectId: props.location.state.projectId,
                    isAdd: true,
                  };

                  let copy = Object.assign({}, item);
                  copy.row_status = "add";
                  temp.splice(
                    selection[selection.length - 1].end.row + start,
                    0,
                    item
                  );
                  start++;
                }

                setHotData(temp);
              }
            }
          }
        },
      },
      remove_row: {
        name: "Remove rows",
        disabled: () => {
          return false;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            if (!window.confirm("Are you want to delete it")) return;

            let indexs = [];
            _.forEach(selection, (v) => {
              let start = v.start.row;
              while (start <= v.end.row) {
                indexs.push(start);
                start++;
              }
            });

            let temp = hotData.slice(0);

            let tempData = temp
              .slice(0)
              .filter((x, index) => !indexs.includes(index));

            let removeIds = temp
              .slice(0)
              .filter((x, index) => indexs.includes(index))
              .map((x) => x.id);

            setRemoveRowIds([...removeRowIds, ...removeIds]);

            setHotData(tempData);
          }
        },
      },
    },
  };

  useEffect(() => {
    getData();
  }, []);

  function handleImport(file) {
    setFileExcel(file);
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;
      let workbook = await wb.xlsx.load(buffer);
      let datas = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex != 1) {
            let block = row.values[1];
            console.log("block", block);
            let level = row.values[2];
            let description = row.values[3];
            let concreteVolume = _.isNumber(row.values[4]) ? row.values[4] : 0;
            let rebarQuantity = _.isNumber(row.values[5]) ? row.values[5] : 0;
            let ratio = _.isNumber(row.values[6]) ? row.values[6] : 0;
            let mesh = _.isNumber(row.values[7]) ? row.values[7] : 0;

            if (
              !_.isEmpty(level) &&
              !_.isEmpty(description) &&
              (concreteVolume > 0 || rebarQuantity > 0 || ratio > 0 || mesh > 0)
            ) {
              datas.push({
                block,
                level,
                description,
                concreteVolume,
                rebarQuantity,
                ratio,
                mesh,
              });
            }
          }
        });
      });

      if (!_.isArray(datas) || datas.length < 1) {
        notification["error"]({
          message: "Import Error",
          description: "There is no data in the excel !",
        });
      }

      try {
        await apiPostDataImport(datas, props.location.state.projectId);
        await getData();
      } catch (error) {
        // notification['error']({
        //   message: 'Import Error',
        //   description: error.response.message,
        // });
      }
    };

    return false;
  }

  async function getData() {
    setIsLoadingSpin(true);
    setLoadingTip("Refreshing table...");

    let res = await apiGetData(props.location.state.projectId);
    let list = [];
    if (res.length > 0) {
      list = res.map(x => ({
        ...x,
        isUpdate: true
      }));
    }
    else {
      list = [{
        breakdown1: "breakdown1",
        breakdown2: "breakdown2",
        breakdown3: "breakdown3",
        units: "units",
        code: "code",
        revit: "revit",
        isUpdate: false
      }]
    }

    setHotData(list);
    setIsLoadingSpin(false);
    setLoadingTip("");
  }

  async function handleReloadActual() {
    setIsLoadingSpin(true);
    setLoadingTip("Get Actual Data...");
    await apiReloadActual(props.location.state.projectId);
    await getData();
  }

  async function handleSave() {

    let temp = hotData.map(x => ({
      breakdown1: x.breakdown1,
      breakdown2: x.breakdown2,
      breakdown3: x.breakdown3,
      units: x.units,
      code: x.code,
      revit: x.revit,
      isUpdate: x.isUpdate,
      id: x.id
    }))

    let payload = {
      removeRowIds,
      add: temp.filter((x) => !x.isUpdate).map(x => ({
        ...x,
        id: uuidv4(),
        projectId: props.location.state.projectId
      })),
      update: temp.filter((x) => x.isUpdate),
    };

    payload.add.forEach((x) => {
      delete x["isParrent"];
      delete x["rowType"];
    });

    payload.update.forEach((x) => {
      delete x["isParrent"];
      delete x["rowType"];
    });

    setIsLoadingSpin(true);
    setLoadingTip("Save Table...");
    await apiSaveData(payload);

    await getData();

    setIsLoadingSpin(false);
  }

  async function handleExport() {
    const workbook = new ExcelJS.Workbook();
    let excelName = "Tracking Concrete Rebar Quantity";
    const worksheet = workbook.addWorksheet("Tracking");

    let row7 = worksheet.getRow(7);
    row7.height = 30;

    for (let columnIndex = 1; columnIndex < 12; columnIndex++) {
      worksheet.getColumn(columnIndex).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }

    worksheet.getCell("A2").value =
      "Project : " + props.location.state.projectName;
    worksheet.getCell("A7").value = "Area/Block";
    worksheet.getCell("B7").value = "Item";
    worksheet.getCell("C7").value = "Description";
    worksheet.getCell("D7").value = "Concrete";
    worksheet.getCell("E7").value = "Rebar";
    worksheet.getCell("F7").value = "Ratio";
    worksheet.getCell("G7").value = "Mesh";
    worksheet.getCell("H7").value = "Concrete";
    worksheet.getCell("I7").value = "Rebar";
    worksheet.getCell("J7").value = "Ratio";
    worksheet.getCell("K7").value = "Mesh";

    worksheet.getCell("D8").value = "m3";
    worksheet.getCell("E8").value = "kg";
    worksheet.getCell("F8").value = "kg/m3";
    worksheet.getCell("G8").value = "kg";
    worksheet.getCell("H8").value = "m3";
    worksheet.getCell("I8").value = "kg";
    worksheet.getCell("J8").value = "kg/m3";
    worksheet.getCell("K8").value = "kg";

    let borderStyles = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    let i = 9;

    let gByLevel = _.groupBy(
      hotData.filter((x) => !x.isParrent),
      "level"
    );

    let keys = Object.keys(gByLevel);
    keys.sort();
    keys.forEach((key) => {
      let items = gByLevel[key];
      items.forEach((item) => {
        worksheet.getCell(`A${i}`).value = item.block;
        worksheet.getCell(`B${i}`).value = item.level;
        worksheet.getCell(`C${i}`).value = item.description;
        worksheet.getCell(`D${i}`).value = item.concreteVolume;
        worksheet.getCell(`E${i}`).value = item.rebarQuantity;
        worksheet.getCell(`F${i}`).value = item.ratio;
        worksheet.getCell(`G${i}`).value = item.mesh;
        worksheet.getCell(`H${i}`).value = item.concreteVolumeActual;
        worksheet.getCell(`I${i}`).value = item.rebarQuantityActual;
        worksheet.getCell(`J${i}`).value = item.ratioActual;
        worksheet.getCell(`K${i}`).value = item.meshActual;
        i++;
      });
      //Summary row
      worksheet.getCell(`C${i}`).value = "Sub-Total";
      worksheet.getCell(`D${i}`).value = _.sumBy(items, "concreteVolume");
      worksheet.getCell(`E${i}`).value = _.sumBy(items, "rebarQuantity");
      worksheet.getCell(`F${i}`).value = _.sumBy(items, "ratio");
      worksheet.getCell(`G${i}`).value = _.sumBy(items, "mesh");
      worksheet.getCell(`H${i}`).value = _.sumBy(items, "concreteVolumeActual");
      worksheet.getCell(`I${i}`).value = _.sumBy(items, "rebarQuantityActual");
      worksheet.getCell(`J${i}`).value = _.sumBy(items, "ratioActual");
      worksheet.getCell(`K${i}`).value = _.sumBy(items, "meshActual");

      for (var columnIndex = 1; columnIndex < 12; columnIndex++) {
        worksheet.getRow(i).getCell(columnIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d0e0" },
        };
      }

      i++;
    });

    //Grand total

    let dataFiltered = hotData.filter((x) => !x.isParrent);
    worksheet.getCell(`C${i}`).value = "Grand Total Qty";
    worksheet.getCell(`D${i}`).value = _.sumBy(dataFiltered, "concreteVolume");
    worksheet.getCell(`E${i}`).value = _.sumBy(dataFiltered, "rebarQuantity");
    worksheet.getCell(`F${i}`).value = _.sumBy(dataFiltered, "ratio");
    worksheet.getCell(`G${i}`).value = _.sumBy(dataFiltered, "mesh");
    worksheet.getCell(`H${i}`).value = _.sumBy(
      dataFiltered,
      "concreteVolumeActual"
    );
    worksheet.getCell(`I${i}`).value = _.sumBy(
      dataFiltered,
      "rebarQuantityActual"
    );
    worksheet.getCell(`J${i}`).value = _.sumBy(dataFiltered, "ratioActual");
    worksheet.getCell(`K${i}`).value = _.sumBy(dataFiltered, "meshActual");

    for (var columnIndex = 1; columnIndex < 12; columnIndex++) {
      worksheet.getRow(i).getCell(columnIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC500" },
      };
    }

    for (let index = 7; index <= i; index++) {
      for (let j = 1; j < 12; j++) {
        worksheet.getCell(index, j).border = borderStyles;
      }
    }

    worksheet.getColumn("A").width = 20;
    worksheet.getColumn("B").width = 20;
    worksheet.getColumn("C").width = 30;
    worksheet.getColumn("D").width = 10;
    worksheet.getColumn("E").width = 10;
    worksheet.getColumn("F").width = 10;
    worksheet.getColumn("G").width = 10;
    worksheet.getColumn("H").width = 10;
    worksheet.getColumn("I").width = 10;
    worksheet.getColumn("J").width = 10;
    worksheet.getColumn("K").width = 10;
    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + ".xlsx");
  }

  const hotSettings = {
    colHeaders: true,
    rowHeaders: true,
    rowHeights: 24,
    rowHeaderWidth: 40,
    height: 750,
    columns: columns,
    nestedHeaders: columnHeaders,
    stretchH: "all",
    // multiColumnSorting: true,
    currentRowClassName: "currentRow",
    dropdownMenu: [
      "filter_by_condition",
      "filter_by_value",
      "filter_action_bar",
    ],
    filters: true,
    search: true,
    licenseKey: "non-commercial-and-evaluation",
  };

  return (
    <div id='archi-library' className="table-hand-some">
      <div className='mb-2 mt-2 ml-2'>
        {/* <Upload
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          beforeUpload={handleImport}
          showUploadList={false}
          className='mr-2'
        >
          <Button type='primary'>
            <Icon type='upload' /> Import
          </Button>
        </Upload>
        <Button type='primary' className='mr-2' onClick={handleExport}>
          Export
        </Button> */}

        <Button type='primary' className='mr-2' onClick={handleSave}>
          Save
        </Button>

        <Button type='primary' className='mr-2' onClick={handleSave}>
          Export Excel
        </Button>
      </div>

      <Spin tip={loadingTip} size='large' spinning={isLoadingSpin}>
        <HotTable
          ref={hotTableRef}
          data={hotData}
          settings={hotSettings}
          dataSchema={dataScheme}
          contextMenu={contextMenuHotTable}
          className='quantiy-tracking-table'
          cells={(row, col, prop) => {
            const cellProperties = {};
            if (hotData[row] && hotData[row].rowType == 1) {
              cellProperties.readOnly = true;
              cellProperties.className = "cell-group2";
            } else if (hotData[row] && hotData[row].rowType == 2) {
              cellProperties.readOnly = true;
              cellProperties.className = "cell-group1";
              cellProperties.className = "className: 'custom-cell";
            } else {
              // cellProperties.className = "custom-cell-inv-no";
            }

            return cellProperties;
          }}
        />
      </Spin>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  };
}

export default connect(mapStateToProps)(ArchiLibrary);
