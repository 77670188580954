import React from 'react'
import _ from 'lodash'
import Draggable from 'react-draggable';
import { Row, Col, Typography } from 'antd';
import { formatNumber } from '../../function/TableFunction';
const { Text } = Typography;

export default class CompareDock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

  }

  componentWillMount = () => {

  }
  componentWillUnmount = () => {
    // try {
    //   this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionRelative);
    //   onceTime = true
    // } catch{ }

  }
  componentWillReceiveProps = () => {
    // if (nextProps.openDock && onceTime) {
    //   onceTime = false
    //   this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionRelative1);
    // } else if (!nextProps.openDock) {
    //   onceTime = true
    //   // this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionRelative1);
    // }
  }



  handleCloseDock = () => {
    this.props.onChangeDockDisplay(false)
  }
  render() {
    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 150, width: 350 , }}
          id='custom-compare-version' >
          <div className='custom-dock-panel-title'>{this.props.data.length ===0?'Compare Element':this.props.data[0].element}</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body' style={{ backgroundColor:'#ffffff00' }}>
            <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
              <Row style={{ backgroundColor:'white'}}>
                <Col  span={10}>
                  <Text title={'Parameter Name'} ellipsis >Parameter Name</Text></Col>
                <Col  span={7}>
                  <Text title={'New Value'} ellipsis >New Value
                  </Text>
                </Col>
                <Col  span={7}>
                  <Text title={'Old Value'} ellipsis  >
                    Old Value</Text>
                </Col>
              </Row>
              {this.props.data.map((i) =>
                <Row style={{ backgroundColor: i.status === 'new' ? '#7ff78e' : '#f7bc7f' }} align="middle">
                  <Col   span={10}>
                    <Text title={i.displayName} ellipsis >{i.name}</Text></Col>
                  <Col  span={7}>
                    <Text title={_.isNumber(i.newProperties) ? formatNumber(i.newProperties) : i.newProperties} ellipsis >
                      {_.isNumber(i.newProperties) ? formatNumber(i.newProperties) : i.newProperties}</Text>
                  </Col>
                  <Col  span={7}>
                    <Text title={_.isNumber(i.oldProperties) ? formatNumber(i.oldProperties) : i.oldProperties} ellipsis  >
                      {_.isNumber(i.oldProperties) ? formatNumber(i.oldProperties) : i.oldProperties}</Text>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <div className='custom-dock-panel-footer'/>
        </div>
      </Draggable>

    )
  }
}