import React from 'react';
import { Loader } from 'rsuite';
import { Dropdown,Button } from 'semantic-ui-react'
import { Modal, } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import {
  getAllElementdbIdsOneModel
} from '../function/ForgeFunction'
import { message } from 'antd';

const THREE = window.THREE
class DialogHightlightNA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dbIds: [],
      sheetId: null,
      listSheetId: [],
      listTempNoMS: [],
      listTempNoDS: [],
      listTempNo: [],
      listCombine: [],
      loading: false
    }
  }
  componentWillMount = () => {
    if (this.props.openPanel) {
      this.setState({loading: true})
      let model = this.props.viewer.impl.modelQueue().getModels()
      if (model.length === 1) {
        let listTempNoMS = []; let listTempNoDS = []; let listTempNo = []
        let listTempDS = []
        let listTempMS = []
        let dbIds = getAllElementdbIdsOneModel(this.props.viewer)
        this.setState({ dbIds: dbIds }, () => {
          var count = this.state.dbIds.length;
          _.forEach(this.state.dbIds, (modelAdbId) => {
            this.props.viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
              var tempValueMark = ''
              var tempValueMarkMS = ''
              for (var i in modelAProperty.properties) {
                if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
                  tempValueMark = modelAProperty.properties[i].displayValue
                } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
                  tempValueMarkMS = modelAProperty.properties[i].displayValue
                }
                if (i === (modelAProperty.properties.length - 1).toString()) {
                  if (tempValueMarkMS === '' && tempValueMark === '') {
                    listTempNo.push(modelAdbId)
                  }  if (tempValueMarkMS === '') {
                    listTempNoMS.push(modelAdbId)
                  }  if (tempValueMark === '') {
                    listTempNoDS.push(modelAdbId)
                  }
                  if (tempValueMark !== '') {
                    if (!listTempDS[tempValueMark])
                      listTempDS[tempValueMark] = []
                    listTempDS[tempValueMark].push(modelAdbId)
                  }
                  if (tempValueMarkMS !== '') {
                    if (!listTempMS[tempValueMarkMS])
                      listTempMS[tempValueMarkMS] = []
                    listTempMS[tempValueMarkMS].push(modelAdbId)
                  }
                }
              }
              count--;
              if (count === 0) {
                var listCombine = _.assignIn(listTempMS, listTempDS)
                this.setState({
                  listCombine: listCombine,
                  listTempNoMS: listTempNoMS,
                  listTempNoDS: listTempNoDS,
                  listTempNo: listTempNo,
                  loading:false
                })
              }
            })
          })
        })
        axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: this.props.folderSmartSheetId })
          .then(res => {
            var tempListSheet = []
            res.data.forEach(item => {
              tempListSheet.push({ key: item.name, text: item.name, value: { id: item.id, name: item.name } })
            })
            this.setState({ listSheetId: tempListSheet })
          })
          .catch(() => {
            message.warning('Cannot get list of sheets')
           })
      } else {
        message.warning('Function just use for one model')
      }
    }
  }
  handleClosePanel = () => {
    this.props.onChangeDisplay('dialogHighlightNA',false)
    this.setState({sheetId: null})
  }
  hightlightNotDetailId = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.state.listTempNoDS.forEach(item => {
      this.props.viewer.setThemingColor(item, new THREE.Vector4(181 / 255, 204 / 255, 24 / 255, 1));
    })
    this.handleClosePanel()
  }
  hightlightNotMasterId = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    this.state.listTempNoMS.forEach(item => {
      this.props.viewer.setThemingColor(item, new THREE.Vector4(0 / 255, 181 / 255, 173 / 255, 1));
    })
    this.handleClosePanel()
  }
  hightlightNotMatch = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.sheetId })
      .then(res => {
        var tempDataSheet = res.data
        var groups = []
        for (var i = 0; i < tempDataSheet.length; i++) {
          let temp1 = {
            id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value,
            start: tempDataSheet[i].cells[1].value, end: tempDataSheet[i].cells[2].value,
            nestedGroups: [], parentId: tempDataSheet[i].parentId
          }
          for (var j = 0; j < tempDataSheet.length; j++) {
            if (i !== j) {
              if (tempDataSheet[i].id === tempDataSheet[j].parentId) {
                temp1.nestedGroups.push(tempDataSheet[j].id)
              }
            }
          }
          if (temp1.nestedGroups.length === 0) {
            if (temp1.parentId === null || temp1.parentId === undefined) {

            } else {
              groups.push(temp1.content)
            }
          }
        }
        var listId = []
        for (var key in this.state.listCombine) {
          if (_.includes(groups, key)) {
            this.state.listCombine[key].forEach(item => {
              listId.push(item)
            })
          }
        }
        for (var key in this.state.listCombine) {
          this.state.listCombine[key].forEach(item => {
            if (!_.includes(listId, item))
              this.props.viewer.setThemingColor(item, new THREE.Vector4(165 / 255, 103 / 255, 63 / 255, 1));
          })
        }
        // this.state.listTempNo.forEach(item=>{
        //     this.props.viewer.setThemingColor(item, new THREE.Vector4(165 / 255, 103 / 255, 63 / 255, 1));
        // })
        this.handleClosePanel()
      })
      .catch(() => {
        message.error('Error')
        this.handleClosePanel()
      })
  }
  handleChangeSheetReport = (e, { value }) => {
    this.setState({ sheetId: value.id })
  }
  render() {
    return (
      <Modal show={this.props.openPanel} onHide={this.handleClosePanel.bind(this)} size="xs"  style={{top:'3%'}}>
        <Modal.Header>
          <Modal.Title>Highlight Missing Information</Modal.Title>
        </Modal.Header>
        <Modal.Body id='modalBodyHighlightElement'>
          <div style={{ textAlign: '-webkit-center' }} >
          {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical />}
          <Dropdown
              placeholder='Smartsheet'
              fluid
              selection
              onChange={this.handleChangeSheetReport}
              options={this.state.listSheetId}
            />
            <br />
            <Button color="olive" onClick={this.hightlightNotDetailId.bind(this)} fluid >Element has not assign "DETAIL SCHEDULE ID"</Button>
            <br/>
            <Button color="teal" onClick={this.hightlightNotMasterId.bind(this)} fluid >Element has not assign "MASTER SCHEDULE ID"</Button>
            <br/>
            <Button color="brown" onClick={this.hightlightNotMatch.bind(this)} fluid 
            disabled={this.state.sheetId === null}>{this.state.sheetId===null?'Please, select smartsheet':'Smartsheet task name not match with "SCHEDULE ID"'}</Button>
            <br/>  

            {/* <Tooltip title='Element has not assign "DETAIL SCHEDULE ID"' placement="top" >
            <Button color="blue" onClick={this.handleGoToStart.bind(this)} fluid >Go To Start</Button>
              <IconButton style={{ backgroundColor: 'olive', color: 'white', marginRight: 20 }} size="lg" icon={<Icon icon="paint-brush" />} onClick={this.hightlightNotDetailId.bind(this)} />
            </Tooltip>
            <Tooltip title='Element has not assign "MASTER SCHEDULE ID"' placement="top">
              <IconButton style={{ backgroundColor: 'teal', color: 'white', marginRight: 20 }} size="lg" icon={<Icon icon="paint-brush" />} onClick={this.hightlightNotMasterId.bind(this)} />
            </Tooltip>
            <Tooltip title={this.state.sheetId===null?'Please, select smartsheet':'Smartsheet task name not match with "SCHEDULE ID"'} placement="top">
              <IconButton style={{ backgroundColor: 'brown', color: 'white' }} size="lg" icon={<Icon icon="paint-brush" />} disabled={this.state.sheetId === null}
              onClick={this.hightlightNotMatch.bind(this)} />
            </Tooltip> */}
        
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}


export default DialogHightlightNA