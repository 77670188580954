import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { HotTable } from "@handsontable/react";
import { func_process_rebar_inv_data } from "../../function/func_process_data";

const TableRebarInv = ({
   tableRef,
   data,
   filterMode,
   generalState,
   setData,
   setStatistic,
}) => {
   const [project_id, set_project_id] = useState();
   const [user_role, set_user_role] = useState();

   useEffect(() => {
      const role = localStorage.getItem("fp_user_role");
      set_user_role(role);
      const pid = localStorage.getItem("fp_project_id");
      set_project_id(pid);
      // make sure working on concrete INV form
      const fetchdata = async () => {
         var res = await axios.get(
            "/api/do_inv/common/get_data_by_date_range",
            {
               params: {
                  currentFilterMode: filterMode,
                  project_id: pid,
                  current: "rebar_inv",
               },
            }
         );
         const dt = res.data.data.items;
         const res_f = await func_process_rebar_inv_data(dt, pid);
         if (res_f !== undefined) {
            setData(res_f.processed_data);
            setStatistic(res_f.staticInfo);
         }
      };
      fetchdata();
   }, [filterMode, generalState]);

   const columns = [
      {
         title: "Inv No",
         data: "inv_number",
         type: "text",
      },
      {
         title: "Do No",
         data: "do_number",
         type: "text",
      },
      {
         title: "Date",
         data: "date",
         type: "date",
         dateFormat: "DD-MM-YYYY",
         datePickerConfig: {
            dateFormat: "DD-MM-YYYY",
         },
      },
      {
         title: "Product",
         data: "product",
         type: "text",
      },
      {
         title: "Weight/Qty",
         data: "weight_qty",
         allowInvalid: false,
         type: "numeric",
      },
      {
         title: "Unit",
         data: "unit",
         type: "text",
      },

      {
         title: "Supplier",
         data: "supplier",
         editor: "select",
         selectOptions: ["NASTEEL", "SUPERBEND", "LEE MESH"],
      },
      {
         title: "Unit price",
         data: "unit_price",
         type: "numeric",
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },

      {
         title: "Amount claim",
         data: "amount_claim",
         type: "numeric",
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },
      {
         title: "Amount +7% GST",
         data: "amount_gst",
         type: "numeric",
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },

      {
         title: "Pdf file",
         data: "s3_key_check",
         renderer: "html",
         readOnly: true,
      },
      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 750,
      columns: columns,
      colWidths: [90, 90, 100, 200, 100, 70, 120, 90, 100, 115, 75, 150],
      stretchH: "last",
      // multiColumnSorting: true,
      currentRowClassName: "currentRow",
      dropdownMenu: [
         "filter_by_condition",
         "filter_by_value",
         "filter_action_bar",
      ],
      filters: true,
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      if (tableRef.current !== null && row === 0 && col === 0) {
         const hotTable = tableRef.current.hotInstance;
         const temp_data = hotTable.getData();
         for (let i = 0; i < temp_data.length; i++) {
            if (temp_data[i][1] != null) {
               if (temp_data[i][1].includes("nos")) {
                  for (let j = 0; j < 12; j++) {
                     if (j !== 2) {
                        hotTable.setCellMeta(i, j, "readOnly", true);
                     }
                     hotTable.setCellMeta(
                        i,
                        j,
                        "className",
                        "custom-cell-group-head"
                     );
                  }
               } else {
                  hotTable.setCellMeta(i, 0, "className", "custom-cell-inv-no");
               }
            }
         }
      }
   };

   const handleOnCellMouseUp = (evens, coors, TD) => {
      if (
         tableRef.current !== null &&
         coors.col === 10 &&
         TD.innerText !== "" &&
         TD.innerText !== null
      ) {
         const temp_data = tableRef.current.hotInstance.getData();
         const file_name_seach =
            project_id + "_rebar_inv_" + temp_data[coors.row][0] + ".pdf";
         axios
            .get("/api/do_inv/s3/getlink", {
               params: { file_name: file_name_seach.toLowerCase() },
            })
            .then((res) => {
               const signedrequesturl = res.data.signedRequest;
               window.open(signedrequesturl);
            });
      }
   };

   const onBeforeHotChange = (changes) => {
      changes.forEach(([row, column, oldValue, newValue]) => {
         if (column === "date") {
            const hotTable = tableRef.current.hotInstance;
            const cellprop = hotTable.getCellMeta(row, 2);
            if (cellprop.className === "custom-cell-group-head") {
               const inv_name = hotTable.getDataAtCell(row, 0);
               data.forEach((e, index) => {
                  if (e.inv_number === inv_name && e.row_type !== "inv") {
                     const new_info = [
                        index,
                        "inv_date",
                        e.inv_date,
                        dayjs(newValue).format("DD-MM-YYYY"),
                     ];
                     changes.push(new_info);
                  }
               });
            } else {
               const new_info = [
                  row,
                  "do_date",
                  oldValue,
                  dayjs(newValue).format("DD-MM-YYYY"),
               ];
               changes.push(new_info);
            }

            //format the first cell
            var cell = hotTable.getCell(0, 0);
            cell.readOnly = true;
            cell.className = "custom-cell-group-head";
         }
      });
      // dispatch(fp_dataUpdate(changes));
      const newData = _.cloneDeep(data);
      changes.forEach(([row, column, oldValue, newValue]) => {
         newData[row].change = true;
         newData[row][column] = newValue;
      });
      setData(newData);
      return false;
   };

   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={data}
            afterRenderer={addClassesToRows}
            afterOnCellMouseUp={handleOnCellMouseUp}
            beforeChange={onBeforeHotChange}
         />
      </div>
   );
};

export default TableRebarInv;
