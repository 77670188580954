import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import axios from "axios";
import printJS from 'print-js'
import _ from 'lodash'
import Split from 'react-split'
import { Icon, Sidenav, Nav, Dropdown, IconStack } from 'rsuite';
import { Header } from 'semantic-ui-react'
import { trackingUserUsing } from '../../module_BimApp/function/AdminFunction'



///sidebar
import {
  SideBarTreeView,
  SideBarIssue,
  SideBarSelectParameter,
  BottomBarTimelineLeft,
  BottomBarCFA,
  SidebarCustomProperty,
  SidebarDesignAutomation,
  SidebarViewPoint,
  SideBarViewable,
  SideBarComment,
  BottomBarRebar,
} from '../../module_BimApp/sidebar'


///dialog
import {
  DialogHighlightNA,
  DialogReport,
  DialogScheduleSaved,
  DialogCompare,
  DialogCompareQuantities,
  DialogStatusForClaim,
  DialogShareReport,
  DialogOverlay,
  DialogGridView
} from '../../module_BimApp/dialog'

///customdock
import {
  ProgressPPVCChart,
  ProgressPrecastChart,
  SummaryPPVC,
  SummaryPrecast,
  PieChartPPVC,
  PieChartPrecast,
  PPVCInformation,
  StatusInstallation,
  ConcreteHistogram,
  ManningSchedule,
  HyperModeling,
  RebarFilter,
  AnimationDock,
  DocumentBrowserMain,
  DocumentBrowserSub,
  Markup2D,
  Markup2DEdit
} from '../../module_BimApp/dock_forge'

// import ArchiSummary from '../../js/Report/ArchiSummary';

import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded,
  getForgeToken, onSelectionRelative
  , showErrorLoadDocument, setupForgeExtensionBeforeLoaded, setupForgeExtensionBeforeLoadedSub,
} from '../../../components/module_BimApp/function/ForgeFunction'
import { RebarIcon } from '../../../image'

import { trackingUser } from '../../../components/module_BimApp/function/AdminFunction'

import { message, Tooltip, Icon as IconAntd } from 'antd';
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
// import { pushDataViewerToExcel } from '../../../function/ExcelFunction'
// import listElementTable, { generateTable } from '../../js/Rebar/RebarSchedulePanel/ScheduleRebarFunction';

const Autodesk = window.Autodesk;
const THREE = window.THREE;
let viewer = null
let selection1Locked = false
let selection2Locked = false

///

class ViewerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '0',
      viewerCurrent: null,
      viewerCurrent2D: null,
      allDbIds: [],
      modelLoaded: true,
      model2DLoaded: false,
      openView2D: false,
      aecModelData: null,
      isRebarFile: false,

      displayPanel: {
        treeViewPanel: false,
        issuePanel: false,
        saveViewPanel: false,
        timelinePanel: false,
        selectParameterPanel: false,
        cfaPanel: false,
        customPropertyPanel: false,
        designAutomationPanel: false,
        viewPointPanel: false,
        viewablePanel: false,
        commentPanel: false,
        rebarPanel: false
      },
      openDialog: {
        dialogHighlightNA: false,
        dialogReport: false,
        dialogCompare: false,
        dialogSaveSchedule: false,
        dialogStatusForClaim: false,
        dialogShareReport: false,
        dialogOverlay: false,
        dialogCompareQuantities: false,
        dialogGridView: false
      },
      disabledBtn: {
        btnSelectParameter: false,
        btnStatusForClaim: false,
        btn4DComparison: true,
        btnTreeview: false,
        btnRebarTable: false,
      },
      openCustomDockForge: {
        documentDockMain: false,
        documentDockSub: false,
        concreteHistogramDock: false,
        manningScheduleDock: false,

        summaryPPVCDock: false,
        pieChartPPVCDock: false,
        progressDock: false,
        summaryPrecastDock: false,
        pieChartPrecastDock: false,
        progressPrecastDock: false,
        statusInstallationDock: false,
        ppvcInformationDock: false,

        hyperModelingDock: false,
        markUp2d: false,
        markUp2dEdit: false,
        rebarFilterDock: false,
        animationDock: false,
        //archiSummaryDock: false,
      },
      link: {
        linkWeeklyReport: '',
        linkDeliveryVsStatus: '',
        linkSitePhoto: '',
      },
      buttonToggleNavigation: false,
      heightNavigation: '53.78px',
      splitView: false,


    }
  }

  // static viewer = null;
  componentWillMount() {
    try {
      trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'Forge Viewer')
    } catch (err) {
      console.log(err)
    }

    axios.get("/api/items/get-link-report", { params: { itemId: this.props.itemId, auth: false } })
      .then(res => {
        this.setState({
          link: res.data.link,
        })
      })
      .catch(err => { })
  }
  componentDidMount = () => {
    viewer = null;
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    this.launchViewer(this.props.objectId);

  }
  componentWillUnmount = () => {
    try {
      message.destroy()
      viewer.tearDown()
      viewer.finish()
      viewer = null
      $("#forgeViewer").empty();
    } catch { }
  }

  launchViewer = (urn) => {
    getForgeToken().then(token => {
      var options = {
        // env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: "Autodesk.MemoryLimited" },
          extensions: [
            "Autodesk.AEC.LevelsExtension",
            "Autodesk.AEC.Minimap3DExtension",
            "Autodesk.AEC.Hypermodeling",

            // "Autodesk.AEC.SheetSyncExtension",
          ],
        };
        viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d);
        ViewerPage.viewer = viewer
        viewer.start();
        let documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess, this.onDocumentLoadFailure);
      });


      // Autodesk.Viewing.Initializer({ env: 'Local' }, async function () {

      //   const viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'));

      //   viewer.start();

      //   viewer.setTheme('light-theme');
      //   viewer.loadExtension('Autodesk.DocumentBrowser')
      //   viewer.loadExtension('Autodesk.DWF').then((e) => {

      //     viewer.loadModel('https://glcdn.githack.com/baoquylan/resource/-/raw/master/pdf/1625137809742_821A_W_S_KCDE_5D_COLUMN.dwf');

      //   });

      // });
    })
  }

  onDocumentLoadSuccess = (doc) => {
    try {
      this.setState({ viewerCurrent: viewer });
      doc.downloadAecModelData().then(res => { this.setState({ aecModelData: res }) })
      var viewables = doc.getRoot().getDefaultGeometry();
      var loadOptions = {
        applyRefPoint: true,
        modelNameOverride: this.props.itemName,
        isAEC: true,
        itemId: this.props.itemId,
        guid: viewables.data.guid,
        viewableID: viewables.data.viewableID,
        version: this.props.version,
        //applyScaling: 'mm'
        // acmSessionId: doc.acmSessionId
      };
      window.typeFile = this.props.typeFile
      let ext = this.props.itemName.split('.')
      if (ext[ext.length - 1].toLowerCase() === 'ifc') {
        if (this.props.typeFile === 'rebar') {
          viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
        }
      } else {
        viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
      }
      viewer.loadDocumentNode(doc, viewables, loadOptions).then(i => {
        if (this.props.lastVersion) {
          message.warning(`Current version: ${this.props.version} vs Last version: ${this.props.lastVersion}`, 0)
        }
        unloadForgeExtension(viewer)
        setupForgeExtensionBeforeLoaded(viewer, this)
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (e) => {
          console.log(viewer.model.isSVF2())
          setupForgeExtensionAfterLoaded(viewer, this)
          this.setState({ modelLoaded: false })

        });
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.handleFunction);
      });

    } catch { }

  }



  handleFunction = () => {
    viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.handleFunction);
    if (this.props.typeFile === 'ppvc') {
      this.state.openCustomDockForge['pieChartPPVCDock'] = true
      this.state.openCustomDockForge['summaryPPVCDock'] = true
      this.setState({ openCustomDockForge: this.state.openCustomDockForge })
    } else if (this.props.typeFile === 'precast') {
      this.state.openCustomDockForge['pieChartPrecastDock'] = true
      this.state.openCustomDockForge['summaryPrecastDock'] = true
      this.setState({ openCustomDockForge: this.state.openCustomDockForge })
    } else if (this.props.typeFile === '4d') {
      this.state.displayPanel.timelinePanel = true
      this.setState({ openCustomDockForge: this.state.openCustomDockForge })
    }
  }
  onDocumentLoadFailure = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
  }

  //#region //? split view
  handleView2D = () => {
    this.setState({ openView2D: !this.state.openView2D }, () => {
      if (!this.state.openView2D) {
        $('#content2D').hide();
        $('#content3D').width('100%');
        $('.gutter-horizontal').hide();
        this.state.viewerCurrent.resize();
        this.state.viewerCurrent2D.tearDown()
        this.state.viewerCurrent2D.finish()
        this.setState({ viewerCurrent2D: null })
        $("#forgeViewer2D").empty();
        this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
        this.state.viewerCurrent2D.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
      } else {
        $('#content2D').show();
        $('#content3D').width('50%');
        $('.gutter-horizontal').show();
        this.state.viewerCurrent.resize();
        this.launchViewer2D(this.props.objectId)
      }
    })



  }
  launchViewer2D = (urn) => {
    let viewerError = document.getElementById('viewer-error');
    if (viewerError) {
      viewerError.parentNode.removeChild(viewerError);
    }
    getForgeToken().then(token => {
      var options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer2D'));
        viewer.start();
        let documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess2D.bind(this, viewer), this.onDocumentLoadFailure2D);
      });
    })
  }
  onDocumentLoadSuccess2D = (viewer, doc) => {
    this.setState({ viewerCurrent2D: viewer }, () => {
      let viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true);
      if (viewables.length === 0 || viewables === null || viewables === undefined || this.state.splitView)
        viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '2d' }, true);
      if (viewables.length === 0) {
        $('#content2D').hide();
        $('#content3D').width('100%');
        $('.gutter-horizontal').hide();
        this.state.viewerCurrent.resize();
        this.state.viewerCurrent2D.tearDown()
        this.state.viewerCurrent2D.finish()
        this.setState({ viewerCurrent2D: null })
        $("#forgeViewer2D").empty();
        message.warning(`File isn't include 2d view`)
        return
      }
      let loadOptions = {
        applyRefPoint: true,
        modelNameOverride: this.props.itemName,
        isAEC: true,
      };
      viewer.loadDocumentNode(doc, viewables[0], loadOptions).then(i => {
        unloadForgeExtension(viewer)
        setupForgeExtensionBeforeLoadedSub(viewer, this)
        if (viewer.impl.model.is3d())
          viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.callback2DView)
        else
          viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.callback2DView)
      });
    })
  }
  callback2DView = () => {
    if (this.state.viewerCurrent2D.impl.model.is3d())
      this.state.viewerCurrent2D.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.callback2DView)
    else
      this.state.viewerCurrent2D.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.callback2DView)
    setupForgeExtensionAfterLoaded(this.state.viewerCurrent2D, this, true)
    this.setState({ model2DLoaded: true })
    this.state.viewerCurrent.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
    this.state.viewerCurrent2D.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
  }
  handleSelect2D = (e) => {
    if (this.state.splitView) {
      try {
        let currSelection = e.target.getSelection();
        if (e.target.clientContainer.id === 'forgeViewer') {
          if (selection2Locked) {
            selection2Locked = false
            return
          }
          selection1Locked = true
          if (currSelection.length === 0) {
            this.state.viewerCurrent2D.clearSelection();
            // this.state.viewerCurrent2D.showAll()
            this.state.viewerCurrent2D.fitToView(null, this.state.viewerCurrent2D.impl.model, false)
            return
          }
          else {
            this.state.viewerCurrent2D.select(currSelection);
            // this.state.viewerCurrent2D.isolate(currSelection, this.state.viewerCurrent2D.impl.model)
            this.state.viewerCurrent2D.fitToView(currSelection, this.state.viewerCurrent2D.impl.model, false)
            return
          }
        } else {
          if (selection1Locked) {
            selection1Locked = false
            return
          }
          selection2Locked = true
          if (currSelection.length === 0) {
            this.state.viewerCurrent.clearSelection();
            this.state.viewerCurrent.showAll()
            this.state.viewerCurrent.fitToView(null, this.state.viewerCurrent.impl.model, false)
            return
          }
          else {
            this.state.viewerCurrent.select(currSelection);
            //this.state.viewerCurrent.isolate(currSelection, this.state.viewerCurrent.impl.model)
            this.state.viewerCurrent.fitToView(currSelection, this.state.viewerCurrent.impl.model, false)
            return
          }
        }
      }
      catch { }
    } else {
      onSelectionRelative(e, this.state.viewerCurrent2D, this.state.viewerCurrent)
    }
  }

  onDocumentLoadFailure2D = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer2D', 'Could not load document', viewerErrorCode)
  }

  dragSplit = () => {
    this.state.viewerCurrent2D.resize()
    this.state.viewerCurrent.resize()
  }

  //#endregion

  //#region 
  exportPDF = () => {
    viewer.getScreenShot(
      297 * 15,
      207 * 15,
      img => {
        printJS(img, 'image')
      }
    );
  }


  //#endregion

  //#region //?general
  handleSelect = (eventKey) => {
    this.setState({ activeKey: eventKey })
  }
  handleTogglePanel = (name, functionName) => {
    if (this.props.folderName)
      trackingUser(this.props.email, this.props.projectName, this.props.folderName, this.props.itemName, functionName)
    let temp = this.state.displayPanel
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    this.setState({ displayPanel: temp }, () => {
      if (name === 'timelinePanel' && this.state.openView2D) {
        if (this.state.openView2D) {
          $('#content2D').hide();
          $('#content3D').width('100%');
          $('.gutter-horizontal').hide();
          this.state.viewerCurrent.resize();
          this.state.viewerCurrent2D.tearDown()
          this.state.viewerCurrent2D.finish()
          $("#forgeViewer2D").empty();
          this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
          this.setState({ viewerCurrent2D: null, openView2D: false })
        }
      }
    })


  }
  handleDialog = (name) => {
    let temp = this.state.openDialog
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    this.setState({ openDialog: temp })
  }
  handleToggleDock = (name) => {
    let temp = this.state.openCustomDockForge
    if (name === 'ppvcInformationDock' && temp.summaryPPVCDock) {
      temp['ppvcInformationDock'] = true
      temp['summaryPPVCDock'] = true
    } else {
      _.forEach(temp, (v, k) => {
        if (k === name) {
          temp[k] = !temp[k]
        } else {
          temp[k] = false
        }
      })
    }
    this.setState({ openCustomDockForge: temp })
  }
  handleDockPieChart = (name) => {
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      }
    })
    this.setState({ openCustomDockForge: temp })
  }
  //#endregion

  //#region //?callback
  callbackDisplayPanel = (name, value) => {
    let temp = this.state.displayPanel
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ displayPanel: temp })
  }
  callbackDisplayDialog = (name, value) => {
    let temp = this.state.openDialog
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ openDialog: temp })
  }
  callbackDisableBtn = (name, value) => {
    let temp = this.state.disabledBtn
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ disabledBtn: temp })
  }
  callbackDisplayDock = (name, value) => {
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ openCustomDockForge: temp })
  }
  callbackModelLoaded = (newValue) => { this.setState({ modelLoaded: newValue }) }


  //#endregion

  handleNavigation = () => {
    if ($('#topNavigation').is(":visible")) {
      $('#topNavigation').hide()
      this.setState({ buttonToggleNavigation: true, heightNavigation: '0px' }, () => {
        try {
          this.state.viewerCurrent.resize()
          if (this.state.viewerCurrent2D)
            this.state.viewerCurrent2D.resize()
        } catch { }
      })
    }
    else {
      $('#topNavigation').show()
      this.setState({ buttonToggleNavigation: false, heightNavigation: '53.78px' }, () => {
        try {
          this.state.viewerCurrent.resize()
          if (this.state.viewerCurrent2D)
            this.state.viewerCurrent2D.resize()
        } catch { }
      })

    }
  }
  handleClickLinkReport = (name) => {
    if (name !== 'linkQSReport')
      window.open(this.state.link[name])
    else {
      let pass = window.prompt('Password', '')
      if (pass === null) return
      axios.get("/api/items/auth-password-report", { params: { itemId: this.props.itemId, password: pass } })
        .then(res => {
          window.open(res.data.link)
        })
        .catch(err => {
          message.warning('Wrong password')
        })
    }
  }

  exportPrecastExcel = () => {
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          if (res.data.result.length === 0) return
          let tempHeader = []
          let tempData = []
          let firstItem = res.data.result[0]
          _.forEach(Object.keys(firstItem), v => {
            tempHeader.push(v)
          })
          let first = true
          _.forEach(res.data.result, v => {
            // v.actions = JSON.stringify(v.actions)
            tempData.push(v)
            _.forEach(v.actions, ac => {
              if (first) {
                first = false
                _.forEach(Object.keys(ac), header => {
                  tempHeader.push(header)
                })
              }
              tempData.push(ac)
            })

          })
          var ws = XLSX.utils.json_to_sheet(tempData, { header: tempHeader });
          var workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, ws, this.props.projectKey);
          var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' }
          var wbout = XLSX.write(workbook, wopts)
          saveAs(new Blob([wbout], { type: "application/octet-stream" }), "Precast.xlsx");
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }

  handleSplitView = () => {
    this.setState({ openView2D: !this.state.openView2D, splitView: !this.state.openView2D }, () => {
      try {
        if (!this.state.openView2D) {
          $('#content2D').hide();
          $('#content3D').width('100%');
          $('.gutter-horizontal').hide();
          this.state.viewerCurrent.resize();
          this.state.viewerCurrent2D.tearDown()
          this.state.viewerCurrent2D.finish()
          this.setState({ viewerCurrent2D: null })
          $("#forgeViewer2D").empty();
          this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.handleSelect2D);
        } else {
          $('#content2D').show();
          $('#content3D').width('50%');
          $('.gutter-horizontal').show();
          this.state.viewerCurrent.resize();
          this.launchViewer2D(this.props.objectId)
        }
      } catch { }

    })

  }

  render() {

    let { userRole, permission } = this.props;
    let ext = this.props.itemName.split('.')
    return (
      <div>
        <div id='zoom-issue-image' />
        <Sidenav style={{
          position: 'absolute', zIndex: '100', height: `calc(100% - ${this.state.heightNavigation})`, backgroundColor: 'white', width: '40px',
          borderRight: '1px solid gray'
        }}
          expanded={false}
          activeKey={this.state.activeKey}
          onSelect={this.handleSelect}
        >
          <Sidenav.Body>
            <Nav >
              {this.props.useFunction.tools &&
                <Tooltip title="Tools" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="12" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                    icon={
                      <IconStack style={{ display: 'contents' }}  >
                        <Icon icon="gear" stack="1x" style={{ top: 6 }} />
                        <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                      </IconStack>
                    }>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="1-1" icon={<Icon icon="file-pdf-o" />} onClick={this.exportPDF} >Save PDF</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="2-3" icon={<Icon icon="related-map" />} onClick={this.handleDialog.bind(this, 'dialogCompare')} >
                      Compare Version</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="1-2" icon={<Icon icon="project" />}
                      onClick={this.handleTogglePanel.bind(this, 'treeViewPanel', 'Tree View')} >Tree View</Dropdown.Item>
                    {/* {userRole === 'admin' &&
                    <Dropdown.Item style={{ height: '40px' }} eventKey="1-3" icon={<Icon icon="detail" />}
                      onClick={this.handleTogglePanel.bind(this, 'customPropertyPanel', 'Custom Property')} >Custom Property</Dropdown.Item>} */}
                    {/* <Dropdown.Item style={{ height: '40px' }} eventKey="1-4" icon={<Icon icon="link" />}
                      onClick={this.handleToggleDock.bind(this, 'hyperModelingDock')} >Link 2D to 3D</Dropdown.Item> */}
                    <Dropdown.Item style={{ height: '40px' }} eventKey="1-5" icon={<Icon icon='filter' />}
                      disabled={ext[ext.length - 1].toLowerCase() !== 'rvt' && ext[ext.length - 1].toLowerCase() !== 'wfx'}
                      onClick={this.handleDialog.bind(this, 'dialogOverlay')} > Filter Set</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="1-5" icon={<Icon icon='bookmark-o' />}
                      onClick={this.handleToggleDock.bind(this, 'animationDock')} > Animation</Dropdown.Item>
                    <Dropdown.Item eventKey="1-7" icon={<Icon icon='object-ungroup' />} onClick={this.handleSplitView}
                    >Split View</Dropdown.Item>
                    {/* <Dropdown.Item style={{ height: '40px' }} eventKey="1-8" icon={<Icon icon='th' />}
                      onClick={this.handleDialog.bind(this, 'dialogGridView')} > Grid</Dropdown.Item> */}
                    {/* <Dropdown.Item style={{ height: '40px' }} eventKey="1-4" icon={<Icon icon='folder-open-o' />}
                    onClick={this.handleTogglePanel.bind(this, 'viewablePanel')} >Document Browser</Dropdown.Item> */}
                    {/* <Dropdown.Item eventKey="1-6" icon={<Icon icon="views-authorize" />}
                    onClick={this.handleTogglePanel.bind(this, 'issuePanel', 'Save Viewpoint')} >Issues</Dropdown.Item> */}
                    {/* <Dropdown.Item eventKey="1-7" icon={<Icon icon="calculator" />} 
                  onClick={this.handleTogglePanel.bind(this,'cfaPanel','CFA')} >CFA</Dropdown.Item> */}
                  </Dropdown>
                </Tooltip>}
              {this.props.useFunction.saveView &&
                <Tooltip title="Views" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Nav.Item eventKey="6" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                    icon={
                      <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents', color: !this.state.modelLoaded && '#636363' }}  >
                        <Icon icon="camera" stack="1x" style={{ top: 6 }} />
                        <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                      </IconStack>}
                    onClick={this.handleTogglePanel.bind(this, 'viewPointPanel', 'Save Viewpoint')} />
                </Tooltip>
              }
              {this.props.useFunction.coordination && <Tooltip title="Issues" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                <Dropdown placement="rightStart" eventKey="3" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                  icon={
                    <IconStack style={{
                      left: '11px', top: '10px', height: '40px', display: 'contents', color:
                        !this.state.modelLoaded && '#636363'
                    }}  >
                      <Icon icon="file-o" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                      <Icon icon="exclamation-triangle" stack="1x" style={{ fontSize: 10, top: 7 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>
                  }>
                  {(this.props.useFunction.coordination.includes('issue')) && <Dropdown.Item eventKey="3-1" icon={<Icon icon="exclamation-triangle" />}
                    onClick={this.handleTogglePanel.bind(this, 'issuePanel', 'Issues')}
                    disabled={this.state.modelLoaded
                      || this.props.issueKey === '' || !this.props.issueKey} >Issue</Dropdown.Item>}
                  {(this.props.useFunction.coordination.includes('comment')) && <Dropdown.Item eventKey="3-1" icon={<Icon icon="comment" />}
                    onClick={this.handleTogglePanel.bind(this, 'commentPanel', 'Comments')}
                    disabled={this.state.modelLoaded} >Comment</Dropdown.Item>}
                </Dropdown>
              </Tooltip>}
              {this.props.useFunction.quantities &&
                <Tooltip title="Quantities" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="3" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                    icon={
                      <IconStack style={{ display: 'contents' }}  >
                        <Icon icon="usd" stack="1x" style={{ top: 6, left: -5 }} />
                        <Icon icon="usd" stack="1x" style={{ top: 6, left: 4 }} />
                        <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                      </IconStack>
                    }>
                    {(this.props.useFunction.quantities.includes('quantities')) &&
                      <Dropdown.Item eventKey="3-1" icon={<Icon icon="slack" />}
                        onClick={this.handleTogglePanel.bind(this, 'selectParameterPanel', 'Extract Quantities')}
                        disabled={this.state.disabledBtn.btnSelectParameter} >Extract Quantities</Dropdown.Item>}
                    {(this.props.useFunction.quantities.includes('compare quantities'))
                      && <Dropdown.Item style={{ height: '40px' }} eventKey="2-3" icon={<Icon icon="related-map" />}
                        onClick={this.handleDialog.bind(this, 'dialogCompareQuantities')} >
                        Compare Quantities</Dropdown.Item>}
                    {/* <Dropdown.Item eventKey="3-3" icon={<Icon icon="frame" />} onClick={this.handleDialog.bind(this,'dialogSaveSchedule')} >Schedule Saved</Dropdown.Item> */}
                    {(this.props.useFunction.quantities.includes('status fof claim'))
                      && <Dropdown.Item style={{ height: '40px' }} eventKey="3-3" icon={<Icon icon="try" />} onClick={this.handleDialog.bind(this, 'dialogStatusForClaim')}
                        disabled={this.state.disabledBtn.btnStatusForClaim}>Status for Claim</Dropdown.Item>}
                  </Dropdown>
                </Tooltip>}
              {(this.props.useFunction.planing)
                &&
   
                <Tooltip title={"Planning"} placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="2" style={{ height: '40px', width: '40px' }} disabled={(this.state.modelLoaded)}
                    icon={<IconStack style={{ display: 'contents' }}  >
                      <Icon icon="clock-o" stack="1x" style={{ top: 6 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>}>
                    {(this.props.useFunction.planing.includes('timeline'))
                      && <Dropdown.Item eventKey="2-4" icon={<Icon icon="calendar" />} disabled={(this.props.folderSmartSheetId === "")}
                        onClick={this.handleTogglePanel.bind(this, 'timelinePanel', 'Timeline')} >Timeline</Dropdown.Item>}
                    {(this.props.useFunction.planing.includes('compare 4d'))
                      && <Dropdown.Item eventKey="2-5" icon={<Icon icon="web" />}
                        disabled={(this.props.folderSmartSheetId === "" || this.state.disabledBtn.btn4DComparison)}
                        onClick={this.handleView2D} >4D Comparison</Dropdown.Item>}
                    {/* <Dropdown.Item eventKey="2-6" icon={<Icon icon="bar-chart" />}
                    onClick={this.handleToggleDock.bind(this, 'concreteHistogramDock')} >Concrete Histogram</Dropdown.Item>
                  <Dropdown.Item eventKey="2-7" icon={<Icon icon="universal-access" />} disabled={(this.props.smartSheetId === "")}
                    onClick={this.handleToggleDock.bind(this, 'manningScheduleDock')} >Manpower Histogram</Dropdown.Item> */}
                    {(this.props.useFunction.planing.includes('highlight missing information'))
                      && <Dropdown.Item eventKey="2-8" icon={<Icon icon="bolt" />} onClick={this.handleDialog.bind(this, 'dialogHighlightNA')} >
                        Highlight Missing Information</Dropdown.Item>}
                    {(this.props.useFunction.planing.includes('report'))
                      && <Dropdown.Menu placement="rightStart" eventKey="12" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                        title='Report' icon={<Icon icon="stack-exchange" />}>
                        <Dropdown.Item eventKey="2-9" icon={<Icon icon="export" />} onClick={this.handleDialog.bind(this, 'dialogReport')} >
                          Standard Progress Report </Dropdown.Item>
                
                          <Dropdown.Item style={{ height: '40px' }} eventKey="2-4" icon={<Icon icon="export" />}
                            onClick={this.handleTogglePanel.bind(this, 'designAutomationPanel', 'Add-in')} > Standard Layout with DWFX Exported </Dropdown.Item>
                      </Dropdown.Menu>}
                  </Dropdown>
                </Tooltip>}

              {(this.props.useFunction.precast)
                && <Tooltip title="PPVC/ Precast monitoring" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="4" title='PPVC/ Precast monitoring' style={{
                    height: '40px', width: '40px',
                    backgroundColor: (this.state.openCustomDockForge.ppvcInformationDock || this.state.openCustomDockForge.statusInstallationDock) && '#90cbff'
                  }} disabled={this.state.modelLoaded}
                    icon={<IconStack style={{ display: 'contents' }}  >
                      <Icon icon="cubes" stack="1x" style={{ top: 5.5 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>} >
                    {this.props.typeProject === 'PPVC/PBU' &&
                      <>
                        <Dropdown.Item style={{ height: '40px' }} eventKey="4-1"
                          disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon="bar-chart" />}
                          onClick={this.handleToggleDock.bind(this, 'progressDock')}>Progress PPVC</Dropdown.Item>
                        <Dropdown.Item eventKey="6-2"
                          disabled={(this.state.modelLoaded)}
                          onClick={this.handleDockPieChart.bind(this, 'pieChartPPVCDock')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon='pie-chart' />} >Pie Chart PPVC</Dropdown.Item>
                        <Dropdown.Item eventKey="4-2"
                          disabled={(this.state.modelLoaded || this.props.projectKey === '' || this.props.smartSheetId === '')}
                          onClick={this.handleToggleDock.bind(this, 'summaryPPVCDock')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon="tasks" />} >Summary PPVC</Dropdown.Item>
                      </>}
                    {this.props.typeProject === 'PreCast' &&
                      <>
                        <Dropdown.Item style={{ height: '40px' }} eventKey="4-1"
                          disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon="bar-chart" />}
                          onClick={this.handleToggleDock.bind(this, 'progressPrecastDock')}>Progress Precast</Dropdown.Item>
                        <Dropdown.Item eventKey="6-2"
                          disabled={(this.state.modelLoaded)}
                          onClick={this.handleDockPieChart.bind(this, 'pieChartPrecastDock')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon='pie-chart' />} >Pie Chart Precast</Dropdown.Item>
                        <Dropdown.Item eventKey="4-2"
                          disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                          onClick={this.handleToggleDock.bind(this, 'summaryPrecastDock')}
                          icon={<Icon style={{ left: '10px', top: '10px' }} icon="tasks" />} >Summary Precast</Dropdown.Item>
                        {(this.props.isInternalFunction && this.props.isWohhupUser)
                          && <Dropdown.Item eventKey="4-4" onClick={this.handleToggleDock.bind(this, 'statusInstallationDock')}
                            disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                            style={{ backgroundColor: this.state.openCustomDockForge.statusInstallationDock && '#90cbff' }}
                            icon={<Icon style={{ left: '10px', top: '10px' }} icon="id-info" />} >Precast Status Installation</Dropdown.Item>}
                      </>}

                    {/* <Dropdown.Item eventKey="6-2"
                    disabled={(this.state.modelLoaded || this.props.folderSmartSheetId === '')}
                    onClick={this.handleToggleDock.bind(this, 'archiSummaryDock')}
                    icon={<Icon style={{ left: '10px', top: '10px' }} icon="tasks" />} >Archi Summary</Dropdown.Item> */}

                    <Dropdown.Item divider />
                    <Dropdown.Item eventKey="4-4" onClick={this.handleToggleDock.bind(this, 'ppvcInformationDock')}
                      disabled={(this.state.modelLoaded || this.props.projectKey === '')}
                      style={{ backgroundColor: this.state.openCustomDockForge.ppvcInformationDock && '#90cbff' }}
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="id-info" />} >PPVC/Precast Information</Dropdown.Item>

                    <Dropdown.Item eventKey="6-5" onClick={this.exportPrecastExcel}
                      icon={<Icon style={{ left: '11px', top: '10px' }} icon="trash-o" />} >PPVC/Precast Export Excel</Dropdown.Item>
                  </Dropdown>
                </Tooltip>}
              {(this.props.useFunction.rebar)
                && <Tooltip title="Rebar" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="7" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                    icon={
                      <IconStack style={{ display: 'contents' }}  >
                        <IconAntd component={RebarIcon} style={{ top: 13, left: 11, position: 'absolute' }} />
                        <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                      </IconStack>
                    }>

                    <Dropdown.Item eventKey="7-1" icon={<Icon icon="table" />} disabled={this.state.btnRebarTable}
                      onClick={this.handleTogglePanel.bind(this, 'rebarPanel', 'Schedule Rebar')}
                    >Export BBS</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="5-1" icon={<Icon icon="filter" />}
                      onClick={this.handleToggleDock.bind(this, 'rebarFilterDock')}  >Rebar Classification</Dropdown.Item>

                  </Dropdown>
                </Tooltip>
              }
              {(this.props.useFunction.shareLink)
                && <Tooltip title="Share Link Report" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                  <Dropdown placement="rightStart" eventKey="5" style={{
                    height: '40px', width: '40px'
                  }} disabled={this.state.modelLoaded}
                    icon={
                      <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                        <Icon icon="file-o" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                        <Icon icon="share" stack="1x" style={{ fontSize: 10, top: 7 }} />
                        <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                      </IconStack>
                    } >
                    <Dropdown.Item style={{ height: '40px' }} eventKey="5-1" onClick={this.handleClickLinkReport.bind(this, 'linkWeeklyReport')}
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkWeeklyReport === ''}>
                      Weekly Report</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="5-2" onClick={this.handleClickLinkReport.bind(this, 'linkDeliveryVsStatus')}
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkDeliveryVsStatus === ''}
                    >Delivery Schedule VS Status</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="5-3" onClick={this.handleClickLinkReport.bind(this, 'linkSitePhoto')}
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />} disabled={this.state.link.linkSitePhoto === ''}
                    >Site Photos/Videos</Dropdown.Item>
                    <Dropdown.Item style={{ height: '40px' }} eventKey="5-4" onClick={this.handleClickLinkReport.bind(this, 'linkQSReport')}
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="file-pdf-o" />}>
                      QS Report</Dropdown.Item>
                    {(userRole === 'admin' || permission.roleUploader) && <Dropdown.Item style={{ height: '40px' }} eventKey="7-4"
                      icon={<Icon style={{ left: '10px', top: '10px' }} icon="cog" />}
                      onClick={this.handleDialog.bind(this, 'dialogShareReport')}>Setting Report</Dropdown.Item>}
                  </Dropdown>
                </Tooltip>}
              <Tooltip title="Document Browser" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1} >
                <Nav.Item eventKey="6" style={{ height: '40px', width: '40px' }} 
                  icon={
                    <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents', color: !this.state.modelLoaded && '#636363' }}  >
                      <Icon icon='folder-open-o' stack="1x" style={{ top: 6 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>}
                  onClick={this.handleTogglePanel.bind(this, 'viewablePanel')} />
              </Tooltip>

              {/* {this.state.isOffice && <Tooltip title="Excel Add-in" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1}>
                <Nav.Item eventKey="6" style={{ height: '40px', width: '40px' }} disabled={this.state.modelLoaded}
                  icon={
                    <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents' }}  >
                      <Icon icon="file-excel-o" stack="1x" style={{ top: 6 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>}
                  onClick={pushDataViewerToExcel.bind(this, this.state.viewerCurrent)} />
              </Tooltip>} */}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <Split id='splitForgeViewer' className='splitForgeViewer'
          sizes={[50, 50]} minSize={100} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
          style={{
            position: "absolute", height: `calc(100% - ${this.state.heightNavigation})`,
            width: "calc(100% - 40px)", left: "40px", display: 'flex', justifyItems: 'center', alignItems: 'center'
          }} onDrag={this.dragSplit}
        >
          <div id='content3D' style={{ height: '100%', width: "100%" }}>
            <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
            <input id="color-picker-model" type='color' style={{ opacity: 0, position: 'absolute' }} />
            <input id="color-picker-rebar-filter" type='color' style={{ opacity: 0, position: 'absolute' }} />
            <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
            <Header id='dateTimeline4DR' style={{ zIndex: '5', position: 'fixed', display: 'none', marginTop: 2 }} as='h5' />
            <div style={{ position: 'relative', width: '100%' }}>
              <Header id='showScheduleType3D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, left: 'calc(50% - 73px)', color: 'red' }} as='h3' />
            </div>

            <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }}>  </div>
          </div>
          <div id='content2D' style={{ height: '100%', width: "100%" }}>
            {this.state.displayPanel.timelinePanel && <div style={{ zIndex: '5', position: 'fixed', marginTop: 2 }}>
              <Header id='dateTimeline4DL' style={{ marginBottom: 2, display: 'none' }} as='h5' />
              <Header style={{ fontSize: 'small', margin: 0 }}>
                <Icon icon='circle' style={{ paddingRight: '10px', color: '#ff0000' }}></Icon>
                Delay </Header>
              <Header style={{ fontSize: 'small', margin: 0 }}>
                <Icon icon='circle' style={{ paddingRight: '10px', color: '#0095ff' }}></Icon>
                Early </Header>
            </div>}
            <div style={{ position: 'relative', width: '100%' }}>
              <Header id='showScheduleType2D' style={{ zIndex: '5', position: 'absolute', display: 'none', marginTop: 2, left: 'calc(50% - 73px)', color: 'red' }} as='h3' />
            </div>
            <div id="forgeViewer2D" style={{ position: "relative", height: '100%', width: "100%" }} />
          </div>
        </Split>



        <SideBarTreeView key={'1'} displayPanel={this.state.displayPanel.treeViewPanel} projectName={this.props.projectName}
          projectId={this.props.projectId} userRoleCurrent={userRole} viewer={this.state.viewerCurrent}
          onDisplayPanelChange={this.callbackDisplayPanel} itemId={this.props.itemId} itemName={this.props.itemName}
          objectId={this.props.objectId} onDisabledBtnChange={this.callbackDisableBtn} />
        <SideBarSelectParameter key={'4'} displayPanel={this.state.displayPanel.selectParameterPanel}
          viewer={this.state.viewerCurrent} onDisplayPanelChange={this.callbackDisplayPanel}
          heightNavigation={this.state.heightNavigation} projectId={this.props.projectId}
          email={this.props.email} itemId={this.props.itemId} onDisabledBtnChange={this.callbackDisableBtn}
          itemName={this.props.itemName} version={this.props.version} />
        <SidebarDesignAutomation key={'8'}
          displayPanel={this.state.displayPanel.designAutomationPanel} viewer={this.state.viewerCurrent} onDisplayPanelChange={this.callbackDisplayPanel}
          objectKey={this.props.objectKey} projectId={this.props.projectId} aecModelData={this.state.aecModelData}
          folderSmartSheetId={this.props.folderSmartSheetId} itemId={this.props.itemId} itemName={this.props.itemName} isTesting={this.props.isTesting}
          itemDisplayName={this.props.itemDisplayName} projectKey={this.props.projectKey} typeProject={this.props.typeProject} />
        {this.state.displayPanel.cfaPanel &&
          <BottomBarCFA key={'6'}
            displayPanel={this.state.displayPanel.cfaPanel} viewer={this.state.viewerCurrent} onDisplayPanelChange={this.callbackDisplayPanel}
            heightNavigation={this.state.heightNavigation} />}
        {this.state.displayPanel.timelinePanel &&
          <BottomBarTimelineLeft key={'5'} itemId={this.props.itemId} projectId={this.props.projectId} email={this.props.email}
            displayPanel={this.state.displayPanel.timelinePanel} viewer={this.state.viewerCurrent} viewer2D={this.state.viewerCurrent2D}
            smartSheetId={this.props.smartSheetId} onDisplayPanelChange={this.callbackDisplayPanel}
            folderSmartSheetId={this.props.folderSmartSheetId} onDisabledBtnChange={this.callbackDisableBtn}
            heightNavigation={this.state.heightNavigation} model2DLoaded={this.state.model2DLoaded} projectKey={this.props.projectKey}
            typeProject={this.props.typeProject} />}
        {(this.state.displayPanel.issuePanel && this.props.issueKey !== '') &&
          <SideBarIssue key={'2'} displayPanel={this.state.displayPanel.issuePanel}
            projectId={this.props.projectId} userRole={userRole} viewer={this.state.viewerCurrent}
            itemId={this.props.itemId} userCreatedProject={this.props.userCreatedProject}
            email={this.props.email} viewerPage={this} projectKey={this.props.issueKey}
            objectId={this.props.objectId} onDisplayPanelChange={this.callbackDisplayPanel}
            version={this.props.version} />}
        {this.state.displayPanel.customPropertyPanel &&
          <SidebarCustomProperty key={'7'}
            displayPanel={this.state.displayPanel.customPropertyPanel} viewer={this.state.viewerCurrent} onDisplayPanelChange={this.callbackDisplayPanel}
            heightNavigation={this.state.heightNavigation} itemId={this.props.itemId} />}
        {this.state.displayPanel.viewPointPanel &&
          <SidebarViewPoint displayPanel={this.state.displayPanel.viewPointPanel} viewer={this.state.viewerCurrent}
            onDisplayPanelChange={this.callbackDisplayPanel} itemId={this.props.itemId} projectId={this.props.projectId}
            email={this.props.email} userCreatedProject={this.props.userCreatedProject} version={this.props.version}
            viewerPage={this} userRole={userRole} onChangeDockDisplay={this.callbackDisplayDock}
          />}
        {this.state.displayPanel.viewablePanel && <SideBarViewable displayPanel={this.state.displayPanel.viewablePanel} viewer={this.state.viewerCurrent}
          onDisplayPanelChange={this.callbackDisplayPanel}
          viewerPage={this} onChangeDialogDisplay={this.callbackDisplayDialog} onChangeViewerModel={this.callbackModelLoaded}
        />}
        {(this.state.displayPanel.commentPanel) &&
          <SideBarComment key={'2'} displayPanel={this.state.displayPanel.commentPanel}
            projectId={this.props.projectId} userRole={userRole} viewer={this.state.viewerCurrent}
            itemId={this.props.itemId} userCreatedProject={this.props.userCreatedProject}
            email={this.props.email} viewerPage={this} projectKey={this.props.issueKey}
            objectId={this.props.objectId} onDisplayPanelChange={this.callbackDisplayPanel}
            version={this.props.version} onChangeDockDisplay={this.callbackDisplayDock} />}
        {this.state.displayPanel.rebarPanel &&
          <BottomBarRebar key={'7'}
            displayPanel={this.state.displayPanel.rebarPanel} viewer={this.state.viewerCurrent} email={this.props.email} projectId={this.props.projectId}
            onDisplayPanelChange={this.callbackDisplayPanel} onDisabledBtnChange={this.callbackDisableBtn}
          />}

        <DialogStatusForClaim openPanel={this.state.openDialog.dialogStatusForClaim} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
          folderSmartSheetId={this.props.folderSmartSheetId} itemId={this.props.itemId} onDisabledBtnChange={this.callbackDisableBtn} projectId={this.props.projectId}
          email={this.props.email} objectId={this.props.objectId} modelLoaded={this.state.modelLoaded} heightNavigation={this.state.heightNavigation} />
        {this.state.openDialog.dialogSaveSchedule &&
          <DialogScheduleSaved openPanel={this.state.openDialog.dialogSaveSchedule} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
            itemId={this.props.itemId} email={this.props.email} version={this.props.version} heightNavigation={this.state.heightNavigation}
            itemName={this.props.itemName} />}
        <DialogReport openPanel={this.state.openDialog.dialogReport} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
          folderSmartSheetId={this.props.folderSmartSheetId} smartSheetId={this.props.smartSheetId} isClient={false} />
        {this.state.openDialog.dialogCompare &&
          <DialogCompare openPanel={this.state.openDialog.dialogCompare} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
            itemId={this.props.itemId} id={this.props.objectId} onChangeDisplayComparisonModel={this.callbackModelLoaded}
            heightNavigation={this.state.heightNavigation} />}
        {this.state.openDialog.dialogCompareQuantities &&
          <DialogCompareQuantities openPanel={this.state.openDialog.dialogCompareQuantities} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
            itemId={this.props.itemId} id={this.props.objectId} onChangeDisplayComparisonModel={this.callbackModelLoaded}
            heightNavigation={this.state.heightNavigation} />}
        {this.state.openDialog.dialogHighlightNA &&
          <DialogHighlightNA openPanel={this.state.openDialog.dialogHighlightNA} viewer={this.state.viewerCurrent} onChangeDisplay={this.callbackDisplayDialog}
            folderSmartSheetId={this.props.folderSmartSheetId} />}
        {this.state.openDialog.dialogShareReport &&
          <DialogShareReport openPanel={this.state.openDialog.dialogShareReport} onChangeDisplay={this.callbackDisplayDialog}
            itemId={this.props.itemId} />}
        {this.state.openDialog.dialogOverlay &&
          <DialogOverlay openPanel={this.state.openDialog.dialogOverlay} onChangeDisplay={this.callbackDisplayDialog}
            viewer={this.state.viewerCurrent} itemId={this.props.itemId} projectId={this.props.projectId} />}
        {(this.state.openDialog.dialogGridView && this.state.aecModelData) &&
          <DialogGridView openPanel={this.state.openDialog.dialogGridView} onChangeDisplay={this.callbackDisplayDialog}
            viewer={this.state.viewerCurrent} aecModelData={this.state.aecModelData} />}
        {/* <Icon icon={this.state.buttonToggleNavigation ? "down" : "up"} style={{
          top: 0, left: 'calc(50% - 25px)', zIndex: 5, position: 'relative',
          paddingLeft: 10, paddingRight: 10, alignItems: 'center', flexWrap: 'wrap-reverse'
        }} onClick={this.handleNavigation} /> */}


        {this.state.openCustomDockForge.documentDockMain &&
          <DocumentBrowserMain openDock={this.state.openCustomDockForge.documentDockMain} viewer={this.state.viewerCurrent} onChangeDockDisplay={this.callbackDisplayDock}
            viewerPage={this} onChangeDialogDisplay={this.callbackDisplayDialog} onChangeViewerModel={this.callbackModelLoaded} />}
        {this.state.openCustomDockForge.documentDockSub &&
          <DocumentBrowserSub openDock={this.state.openCustomDockForge.documentDockSub} viewer={this.state.viewerCurrent2D} onChangeDockDisplay={this.callbackDisplayDock}
            viewerPage={this} splitView={this.state.splitView} />}
        {this.state.openCustomDockForge.concreteHistogramDock &&
          <ConcreteHistogram openDock={this.state.openCustomDockForge.concreteHistogramDock} viewer={this.state.viewerCurrent} onChangeDockDisplay={this.callbackDisplayDock}
            folderSmartSheetId={this.props.folderSmartSheetId} itemId={this.props.itemId} />}
        {this.state.openCustomDockForge.manningScheduleDock &&
          <ManningSchedule openDock={this.state.openCustomDockForge.manningScheduleDock} viewer={this.state.viewerCurrent} onChangeDockDisplay={this.callbackDisplayDock}
            smartSheetId={this.props.smartSheetId} manningScheduleId={this.props.manningScheduleId} />}

        {this.state.openCustomDockForge.hyperModelingDock &&
          <HyperModeling openDock={this.state.openCustomDockForge.hyperModelingDock} viewer={this.state.viewerCurrent}
            onChangeDockDisplay={this.callbackDisplayDock} aecModelData={this.state.aecModelData} />}
        {this.state.openCustomDockForge.markUp2d &&
          <Markup2D openDock={this.state.openCustomDockForge.markUp2d} viewer={this.state.viewerCurrent}
            onChangeDockDisplay={this.callbackDisplayDock} viewerPage={this} />}
        {this.state.openCustomDockForge.markUp2dEdit &&
          <Markup2DEdit openDock={this.state.openCustomDockForge.markUp2dEdit} viewer={this.state.viewerCurrent}
            onChangeDockDisplay={this.callbackDisplayDock} viewerPage={this} />}
        {this.state.openCustomDockForge.rebarFilterDock &&
          <RebarFilter openDock={this.state.openCustomDockForge.rebarFilterDock} viewer={this.state.viewerCurrent} email={this.props.email} projectId={this.props.projectId}
            onChangeDockDisplay={this.callbackDisplayDock} />}
        {this.state.openCustomDockForge.animationDock &&
          <AnimationDock viewer={this.state.viewerCurrent} projectId={this.props.projectId}
            itemId={this.props.itemId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.animationDock} email={this.props.email}
          />}

        {this.state.openCustomDockForge.summaryPPVCDock &&
          <SummaryPPVC viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} smartSheetId={this.props.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.summaryPPVCDock} aecModelData={this.state.aecModelData}
            permission={permission} userRole={userRole} typeProject={this.props.typeProject} />}
        {this.state.openCustomDockForge.pieChartPPVCDock &&
          <PieChartPPVC viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} smartSheetId={this.props.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.pieChartPPVCDock} typeProject={this.props.typeProject}
          />}
        {this.state.openCustomDockForge.progressDock &&
          <ProgressPPVCChart viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} typeProject={this.props.typeProject} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} onChangeDockDisplay={this.callbackDisplayDock} openDock={this.state.openCustomDockForge.progressDock} />}

        {this.state.openCustomDockForge.summaryPrecastDock &&
          <SummaryPrecast viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} smartSheetId={this.props.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.summaryPrecastDock} aecModelData={this.state.aecModelData} typeProject={this.props.typeProject}
            permission={permission} userRole={userRole} />}
        {this.state.openCustomDockForge.pieChartPrecastDock &&
          <PieChartPrecast viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} smartSheetId={this.props.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.pieChartPrecastDock} typeProject={this.props.typeProject}
          />}
        {this.state.openCustomDockForge.progressPrecastDock &&
          <ProgressPrecastChart viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} typeProject={this.props.typeProject} email={this.props.email} projectId={this.props.projectId}
            itemId={this.props.itemId} onChangeDockDisplay={this.callbackDisplayDock} openDock={this.state.openCustomDockForge.progressPrecastDock} />}

        {this.state.openCustomDockForge.statusInstallationDock &&
          <StatusInstallation viewer={this.state.viewerCurrent} projectKey={this.props.projectKey}
            itemId={this.props.itemId} onChangeDockDisplay={this.callbackDisplayDock}
            openDock={this.state.openCustomDockForge.statusInstallationDock} />}
        {this.state.openCustomDockForge.ppvcInformationDock &&
          <PPVCInformation viewer={this.state.viewerCurrent} projectKey={this.props.projectKey} itemName={this.props.itemName}
            itemId={this.props.itemId} onChangeDockDisplay={this.callbackDisplayDock} typeProject={this.props.typeProject}
            openDock={this.state.openCustomDockForge.ppvcInformationDock} />}
        {/* {this.state.openCustomDockForge.archiSummaryDock &&
          <ArchiSummary openDock={this.state.openCustomDockForge.archiSummary} viewer={this.state.viewerCurrent}
            onChangeDockDisplay={this.callbackDisplayDock} aecModelData={this.state.aecModelData} folderSmartSheetId={this.props.folderSmartSheetId}/>} */}
      </div>
    )
  }

}

ViewerPage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(ViewerPage)