import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import $ from 'jquery'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Excel from 'exceljs';
import moment from 'moment';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox, DatePicker, Divider } from 'rsuite';
import { Table, Pagination, Dropdown, Input } from 'semantic-ui-react'
import { Input as InputAnt } from 'antd';
import Tooltip from '@material-ui/core/Tooltip';
import { matchSorter } from "match-sorter";
import {
   useAsyncDebounce
} from 'react-table'
const THREE = window.THREE;
const { Search } = InputAnt;


//#region function
export function ListValueFilter(onChangeHeader, {
   column: { filterValue = [], preFilteredRows, setFilter, id, units, filter, type, index }
}) {
   const [colorIcon, setColorIcon] = React.useState('#bfbfbf');
   const [list, setList] = React.useState(filterValue);
   const data = React.useMemo(() => {
      let dataNeed = []
      let rowValue = []
      preFilteredRows.forEach(row => {
         let value = row.values[id]
         if (value) {
            if (type === 'array') {
               if (row.values[id].length === 0) {
                  rowValue.push("")
               } else {
                  _.forEach(row.values[id], r => {
                     rowValue.push(r)
                  })
               }

            } else {
               if (type === 'date') {
                  value = moment(row.values[id]).format('DD-MM-YYYY')
               }
               rowValue.push(value)
            }
         }
      })
      let intersection = _.uniq(rowValue);
      intersection.sort();
      intersection.forEach(v => {
         dataNeed.push({ label: v, value: v })
      })
      return dataNeed
   }, [id, preFilteredRows])
   const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
         min = Math.min(row.values[id], min)
         max = Math.max(row.values[id], max)
      })
      return [min, max]
   }, [id, preFilteredRows])
   const handleFilter = (value) => {
      try {
         if (filter === 'between' && onChangeHeader) {
            onChangeHeader(id, 'includes')
         }
         let temp = []
         if (value.length !== 0) {
            value.forEach(v => {
               temp.push(v)
            })
            setList(temp)
         } else {
            setList([])
         }
         setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
         setFilter(temp.length === 0 ? undefined : temp)

      } catch { }
   }
   const handleCheckAll = (value, checked) => {
      try {
         if (filter === 'between' && onChangeHeader) {
            onChangeHeader(id, 'includes')
         }
         let temp = []
         if (checked)
            data.forEach(v => {
               temp.push(v.value)
            })
         setList(temp)
         setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
         setFilter(temp.length === 0 ? undefined : temp)
      } catch { }
   }
   useEffect(() => {
      if (filterValue.length === 2)
         setColorIcon((filterValue[0] !== undefined || filterValue[1] !== undefined) ? 'black' : '#bfbfbf')
      else if (filterValue.length === 0)
         setColorIcon('#bfbfbf')
   }, [filterValue]);
   return (
      <div>
         <Whisper placement={([0, 1, 2].includes(index) ? 'bottomLeft' : 'bottomRight')} trigger="click" speaker={
            <Popover trigger="click" >
               <Tooltip title="Hold Ctrl Key to Search or Pick multi-value" placement="bottom">
                  <CheckPicker value={filter === 'includes' ? list : (type === 'array' || type === 'date') ? list : []} data={data} style={{ width: '200px' }} onChange={handleFilter} size="xs"
                     renderExtraFooter={() => (
                        <div style={{ padding: '10px 2px', borderTop: '1px solid #e5e5e5' }}>
                           <Checkbox inline
                              indeterminate={(filter === 'includes' ? list : []).length < data.length && (filter === 'includes' ? list : []).length > 0}
                              checked={(filter === 'includes' ? list : []).length === data.length}
                              onChange={handleCheckAll}
                              style={{ color: 'blue' }}>
                              Check all
                       </Checkbox>
                        </div>
                     )} />
               </Tooltip>

               <br />

               {units &&
                  <div style={{ marginTop: 5, display: 'flex', }} >
                     <Tooltip title="Min Value" placement="bottom">
                        <Input type='number' step={0.01}
                           value={filter === 'between' ? filterValue[0] : ''}
                           onChange={e => {
                              if (filter === 'includes' && onChangeHeader) {
                                 filterValue = []
                                 onChangeHeader(id, 'between')
                              }
                              const val = e.target.value
                              let temp = [parseFloat(val) ? parseFloat(val) : undefined, filterValue[1]]
                              setFilter(temp)
                           }}
                           style={{ width: '100px', marginRight: '0.5rem', }}
                           placeholder={`(${min})`} size='small'
                        />
                     </Tooltip>
                     <Tooltip title="Max Value" placement="bottom">
                        <Input type='number' step={0.01}
                           value={filter === 'between' ? filterValue[1] : ''}
                           onChange={e => {
                              if (filter === 'includes' && onChangeHeader) {
                                 filterValue = []
                                 onChangeHeader(id, 'between')
                              }
                              const val = e.target.value
                              let temp = [filterValue[0], parseFloat(val) ? parseFloat(val) : undefined]
                              setFilter(temp)
                           }}
                           style={{ width: '100px', marginLeft: '0.5rem' }}
                           placeholder={`(${max})`} size='small'
                        />
                     </Tooltip>

                  </div>

               }
               <Button appearance="link" onClick={() => {
                  filterValue = []
                  setList([])
                  setFilter(undefined)
               }}>Reset</Button>
            </Popover>
         }>
            <Icon icon="filter" style={{ right: 0, top: 0, position: 'absolute', padding: 5, color: colorIcon }} />
         </Whisper>

      </div>
   )
}

export function MinMaxFilter({
   column: { filterValue = [], preFilteredRows, setFilter, id, filter, index },
}) {
   const [colorIcon, setColorIcon] = React.useState('#bfbfbf');
   const [list, setList] = React.useState(filterValue);
   const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
         min = Math.min(row.values[id], min)
         max = Math.max(row.values[id], max)
      })
      return [min, max]
   }, [id, preFilteredRows])

   const handleFilter = (name, value) => {
      try {
         let temp = [list[0] ? list[0] : null, list[1] ? list[1] : null]
         if (value !== null || value !== undefined) {
            temp[name === 'min' ? 0 : 1] = name === 'min' ? moment(value).startOf('day').valueOf() : moment(value).endOf('day').valueOf()
            setList(temp)
         } else {
            temp[name === 'min' ? 0 : 1] = null
            setList(temp)
         }
         setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
         setFilter(temp)

      } catch { }
   }

   React.useEffect(() => {
      if (filterValue.length === 2)
         setColorIcon((filterValue[0] !== undefined || filterValue[1] !== undefined) ? 'black' : '#bfbfbf')
      else if (filterValue.length === 0)
         setColorIcon('#bfbfbf')
   });
   return (
      <div>
         <Whisper placement={([0, 1, 2].includes(index) ? 'bottomLeft' : 'bottomRight')} trigger="click" speaker={
            <Popover >

               <div style={{ marginTop: 5, display: 'flex', }} >
                  <Tooltip title="Hold Ctrl Key to Pick Min Date" placement="bottom">
                     <DatePicker style={{ width: '120px', marginRight: '0.5rem' }} size='xs' cleanable={false} defaultValue={moment(min)} oneTap format='DD-MM-YYYY'
                        value={filterValue[0] ? moment(filterValue[0]) : moment(min).startOf('da')}
                        onChange={handleFilter.bind(this, 'min')} disabledDate={date =>
                           date.valueOf() < min ? true : false
                        } />
                  </Tooltip>
                  <Tooltip title="Hold Ctrl Key to Pick Max Date" placement="bottom">
                     <DatePicker style={{ width: '120px', marginRight: '0.5rem', }} size='xs' cleanable={false} defaultValue={moment(max)} oneTap format='DD-MM-YYYY'
                        value={filterValue[1] ? moment(filterValue[1]) : moment(max)}
                        onChange={handleFilter.bind(this, 'max')} disabledDate={date =>
                           date.valueOf() > max ? true : false
                        } />
                  </Tooltip>

               </div>
               <Button appearance="link" onClick={() => {
                  filterValue = []
                  setList([])
                  setFilter(undefined)
               }}>Reset</Button>
            </Popover>
         }>
            <Icon icon="filter" style={{ right: 0, top: 0, position: 'absolute', padding: 5, color: colorIcon }} />
         </Whisper>

      </div>
   )
}

export function GlobalFilter({
   preGlobalFilteredRows,
   globalFilter,
   setGlobalFilter,
}) {
   const count = preGlobalFilteredRows.length
   const [value, setValue] = React.useState(globalFilter)
   const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
   }, 200)

   return (
      <Search
         placeholder={`${count} records...`}
         className='react-table-global-search'
         onSearch={value => {
            // setValue(value);
            setGlobalFilter(value || undefined)
         }}
         onChange={e => {
            setValue(e.target.value);
            //onChange(e.target.value);
         }}
         style={{ width: '100%', fontSize: "1.1rem", border: 'none', fontFamily: 'none', color: 'blue' }}
         value={value || ""}
         size='small'
      />
      // <InputAnt
      //     value={value || ""}
      //     onChange={e => {
      //       setValue(e.target.value);
      //       onChange(e.target.value);
      //     }}
      //     placeholder={`${count} records...`}

      //   />
   )
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
   return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

export function EditableCell({
   cell: { value: initialValue },
   row,
   column: { id, canEdit },
   handleUpdateData, // This is a custom function that we supplied to our table instance
   handleOpenPanelEditType,
 }) {
   // We need to keep and update the state of the cell normally
   const [value, setValue] = React.useState(initialValue)

   const onChange = e => {
     setValue(e.target.value)
   }

   // We'll only update the external data when the input is blurred
   const onBlur = () => {
     // if(parseFloat(e.target.value) !== parseFloat(value))
     var nodes = getSubRowsTableWithoutElementId(row.subRows)
     // handleUpdateData(row.index, id, )
     handleUpdateData(nodes, id, value)
   }
   const changeFollowLevel = () => {
     handleOpenPanelEditType(row, value)
   }


   React.useEffect(() => {
     setValue(initialValue)
   }, [initialValue])

   return canEdit ? <div style={{ display: 'inline' }}>
     <Input type="number" transparent size='small' value={value} onChange={onChange} onBlur={onBlur} />
     <IconButton style={{ float: 'right' }} size="xs" icon={<Icon icon="edit" />} onClick={changeFollowLevel}></IconButton>
   </div>
     : <Input transparent size='small' disabled={true} value={value} onChange={onChange} onBlur={onBlur} />
 }

export const handleReactTableFilterArrayString = (rows, id, filterValue) => {
   return rows.filter((row) => {
      const rowValue = row.values[id];
      let check = false
      if (filterValue.length === 0) {
         check = true
      } else {
         if (rowValue) {
            if (rowValue.length !== 0) {
               _.forEach(rowValue, v => {
                  if (filterValue.includes(v)) {
                     check = true
                     return false
                  }
               })
            } else {
               if (filterValue.includes("")) {
                  check = true
               }
            }
         }
      }

      return check;
   });
}
export const handleReactTableFilterDate = (rows, id, filterValue) => {
   return rows.filter((row) => {
      const rowValue = row.values[id];
      let check = false
      if (filterValue.length === 0) {
         check = true
      }else{
         if (rowValue) {
            if (rowValue.length !== 0) {
               let date = moment(rowValue).format('DD-MM-YYYY')
               if (filterValue.includes(date)) check = true
            }
     
         }
      }
     
      return check;
   });
}

export function sortTagGroup(a, b) {
   let nameA = a.toUpperCase();
   let nameB = b.toUpperCase();

   if (nameA < nameB) {
      return -1;
   }
   if (nameA > nameB) {
      return 1;
   }
   return 0;
}
export function getSubRowsTableWithElementId(subRows) {
   var temp = {};
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.subRows.length !== 0) {
         let childNode = []
         _.forEach(node.subRows, (v, k) => {
            childNode.push(v)
         })
         queue = queue.concat(childNode)
      } else {
         let splice = node.original['ElementId'].split('//')
         if (!temp[[splice[1]]])
            temp[splice[1]] = []
         temp[splice[1]].push(parseInt(splice[0]));
      }
   }
   return temp
}
export function getSubRowsCount(subRows) {
   var temp = [];
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.subRows.length !== 0) {
         let childNode = []
         _.forEach(node.subRows, (v, k) => {
            childNode.push(v)
         })
         queue = queue.concat(childNode)
      } else {
         let splice = node.original['ElementId'].split('//')
         temp.push(splice[0]);
      }

   }
   return temp.length
}
export function getSubRowsTotal(subRows, id) {
   var temp = 0;
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.original !== undefined) {
         if (node.subRows.length !== 0) {
            let childNode = []
            _.forEach(node.subRows, (v, k) => {
               childNode.push(v)
            })
            queue = queue.concat(childNode)
         } else {
            if (node.original !== undefined) {
               if (node.values[id])
                  temp = temp + node.values[id];
            }

         }
      }
   }
   return temp
}
export function getSubRowsTotalCFA(subRows, id, id1, id2) {
   var temp = {}
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.original !== undefined) {
         if (node.subRows.length !== 0) {
            let childNode = []
            _.forEach(node.subRows, (v, k) => {
               childNode.push(v)
            })
            queue = queue.concat(childNode)
         } else {
            if (node.original !== undefined) {
               if (id === 'CFA') {
                  if (!temp[node.original.WH_Level + node.original.WH_Zone])
                     temp[node.original.WH_Level + node.original.WH_Zone] = node.original.CFA
               }
               else
                  if (!temp[node.original.WH_Level + node.original.WH_Zone])
                     temp[node.original.WH_Level + node.original.WH_Zone] = node.original['Average Slab']
            }
         }
      }
   }
   let result = 0
   _.forEach(temp, v => {
      result = result + v
   })
   return result
}
export function getSubRowsCountWithOutElementId(subRows) {
   var temp = [];
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.subRows.length !== 0) {
         let childNode = []
         _.forEach(node.subRows, (v, k) => {
            childNode.push(v)
         })
         queue = queue.concat(childNode)
      } else {
         temp.push(node);
      }

   }
   return temp.length
}

export function getSubRowsDifferentTotal(subRows, id) {
   var temp = 0;
   var queue = [];
   subRows.forEach(item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.groupByID === 'Compare Status') {
         temp = temp + node.values[id];
      }
   }
   return temp
}


export function formatNumber(amount, decimalCount = 2, decimal = ".", thousands = ",") {
   try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
   } catch (e) {
      console.log(e)
   }
}
export function getSubRowsTableWithoutElementId(subRows) {
   var temp = [];
   var queue = [];
   _.forEach(subRows, (item, key) => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.subRows.length !== 0) {
         let childNode = []
         _.forEach(node.subRows, (v, k) => {
            childNode.push(v)
         })
         queue = queue.concat(childNode)
      } else {
         temp.push(node)
      }
   }
   return temp
}
export function getRandomColor() {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
}
export function convertHexColorToVector4(value, transparent = 1) {
   let R = hexToR(value) / 255;
   let G = hexToG(value) / 255;
   let B = hexToB(value) / 255;
   return new THREE.Vector4(R, G, B, transparent)
}
export function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
export function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
export function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
function cutHex(h) { return (h.charAt(0) === "#") ? h.substring(1, 7) : h }

export function componentToHex(c) {
   var hex = c.toString(16);
   return hex.length == 1 ? "0" + hex : hex;
}

export function convertVector4ColorToHex(vector4) {

   return "#" + componentToHex(vector4.x * 255) + componentToHex(vector4.y * 255) + componentToHex(vector4.z * 255);
}

export function guid(format = 'xxxxxxxxxxxx') {
   var d = new Date().getTime();
   return format.replace(
      /[xy]/g,
      function (c) {
         var r = (d + Math.random() * 16) % 16 | 0;
         d = Math.floor(d / 16);
         return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16)
      })
}
export function exportTableToExcel(name) {
   var workbook = XLSX.utils.table_to_book(document.getElementById(name));
   var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' }

   var wbout = XLSX.write(workbook, wopts)

   saveAs(new Blob([wbout], { type: "application/octet-stream" }), "Schedule.xlsx");
}
export function exportTableToExcelGroup_Test(flatColumns, flatRows) {
   var tempSheet = []
   var tempHeaderCheck = {}
   var tempHeader = []
   _.forEach(flatColumns, (item, key) => {
      if (item.id !== 'ElementId' && item.id !== 'selection' && item.id !== 'setcolor') {
         tempHeader.push(item.id)
         tempHeaderCheck[item.id] = item.aggregate
      }
   })
   tempSheet.push(tempHeader)
   var wsrows = [];
   wsrows.push({ level: 0 })
   let maxChild = 10;
   _.forEach(flatRows, (item, key) => {
      if (item.original !== undefined) {
         let temp = _.pick(item.original, tempHeader)
         let rows = _.values(temp)
         tempSheet.push(rows)
         wsrows.push({ level: 7 })
      } else {
         wsrows.push({ level: 7 - item.depth })
         if (maxChild > 7 - item.depth)
            maxChild = 7 - item.depth

         let rows = []
         _.forEach(item.cells, async (cell, k) => {
            if (cell.column.id !== 'ElementId' && cell.column.id !== 'selection' && cell.column.id !== 'setcolor') {
               var value = await cell.render('Aggregated')
               rows.push(value)
            }
         })
         tempSheet.push(rows)
      }
   })
   wsrows.forEach((item, i) => {
      if (i !== 0)
         item.level = item.level - maxChild
   })
   var workbook = XLSX.utils.book_new();
   var ws = XLSX.utils.aoa_to_sheet(tempSheet);
   XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
   ws['!rows'] = wsrows
   var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' }
   var wbout = XLSX.write(workbook, wopts)
   saveAs(new Blob([wbout], { type: "application/octet-stream" }), "Schedule.xlsx");
}
export async function exportTableToExcelAllData(flatColumns, flatRows) {
   var workbook = new Excel.Workbook();
   var sheet = workbook.addWorksheet('Schedule');
   var tempHeader = []
   _.forEach(flatColumns, (item, key) => {
      if (item.id !== 'ElementId' && item.id !== 'selection' && item.id !== 'setcolor') {
         tempHeader.push(item.id)
      }
   })
   sheet.addRows([tempHeader]);
   _.forEach(flatRows, (item, key) => {
      let temp = _.pick(item.original, tempHeader)
      let rows = [_.values(temp)]
      sheet.addRows(rows);
   })
   const buf = await workbook.xlsx.writeBuffer()
   saveAs(new Blob([buf]), 'Schedule.xlsx')
}
export function handleCollapse(flatRows) {
   (flatRows.map(d => {
      try {
         if (d.isExpanded)
            d.toggleExpanded()
      } catch { }

   }))
}
export function handleExpand(flatRows, level, groupBy) {
   flatRows.map(d => {
      try {
         if (d.isGrouped) {
            if (!d.isExpanded || d.isExpanded === undefined) {
               d.toggleExpanded(true)
            }

         }
      } catch { }
   })
   let temp = []
   for (let i = 0; i <= level; i++) {
      temp.push(groupBy[i])
   }
   flatRows.map(d => {
      try {
         if (d.isGrouped) {
            if (temp.includes(d.groupByID)) {
               d.toggleExpanded(true)
            }
            else {
               d.toggleExpanded(false)
            }
         }

      } catch { }

   })
}

export function selectMultiModelWithElementId(viewer, row, tempListModel) {
   viewer.clearSelection()
   if (row.isGrouped) {
      var ds = getSubRowsTableWithElementId(row.subRows)
      let tempSelect = []
      _.forEach(ds, (value, key) => {
         tempSelect.push({ model: tempListModel[key], ids: value })
      })
      if (Object.keys(tempListModel).length === 1) {
         viewer.select(tempSelect[0].ids)
         // viewer.select([0].ids)
      }
      else
         viewer.impl.selector.setAggregateSelection(tempSelect)
   } else {
      let splice = row.original['ElementId'].split('//')
      if (Object.keys(tempListModel).length === 1)
         viewer.select(parseInt(splice[0]))
      else
         viewer.impl.selector.setAggregateSelection([{ model: tempListModel[splice[1]], ids: [parseInt(splice[0])] }])
   }
}
export function selectModelWithoutElementId(viewer, row) {
   viewer.clearSelection()
   if (row.isGrouped) {
      var ds = getSubRowsTableWithoutElementId(row.subRows)
      let tempSelect = []
      _.forEach(ds, (value) => {
         tempSelect.push(parseInt(value.original.id))
      })
      viewer.select(tempSelect)
   } else {
      viewer.select(parseInt(row.original.id))
   }
}
export function selectModelArrayIdWithoutElementId(viewer, row) {
   viewer.clearSelection()
   if (row.isGrouped) {
      var ds = getSubRowsTableWithoutElementId(row.subRows)
      let tempSelect = []
      _.forEach(ds, (value) => {
         tempSelect = tempSelect.concat(value.original.ids)
      })
      viewer.select(tempSelect)
   } else {
      viewer.select(row.original.ids)
   }
}
export function colorSetWithElementId(name, rows, groupBy, viewer, tempListModel) {
   try {
      _.forEach(tempListModel, (v, k) => {
         viewer.clearThemingColors(v);
      })
      let nameInput = `#${name} tbody`
      let dataInput = $(nameInput).find("input");
      let n = 0;
      _.forEach(rows, (row, index) => {
         if (groupBy[groupBy.length - 1] === (row.groupByID)) {
            let color = getRandomColor();
            row.values['setcolor'] = color
            dataInput[n].value = color
            let R = hexToR(color) / 255;
            let G = hexToG(color) / 255;
            let B = hexToB(color) / 255;
            let colorDelay = new THREE.Vector4(R, G, B, 1)
            if (row.isGrouped) {
               var ds = getSubRowsTableWithElementId(row.subRows)
               _.forEach(ds, (value, key) => {
                  _.forEach(value, (v, k) => {
                     viewer.setThemingColor(v, colorDelay, tempListModel[key]);
                  })
               })
            } else {
               let splice = row.original['ElementId'].split('//')
               viewer.setThemingColor(splice[0], colorDelay, tempListModel[splice[1]]);
            }
            n++
         }

      }
      )
   }
   catch { }
}
export function colorSetWithoutElementId(name, rows, groupBy, viewer, tempListModel) {
   try {
      _.forEach(tempListModel, (v, k) => {
         viewer.clearThemingColors(v);
      })
      let nameInput = `#${name} tbody`
      let dataInput = $(nameInput).find("input");
      let n = 0;
      _.forEach(rows, (row, index) => {
         if (groupBy[groupBy.length - 1] === (row.groupByID)) {
            let color = getRandomColor();
            row.values['setcolor'] = color
            dataInput[n].value = color
            let colorDelay = convertHexColorToVector4(color)
            if (row.isGrouped) {
               var ds = getSubRowsTableWithoutElementId(row.subRows)
               _.forEach(ds, (value, key) => {
                  viewer.setThemingColor(value.original.id, colorDelay, viewer.impl.model, true);
               })
            } else {
               viewer.setThemingColor(row.original.id, colorDelay, viewer.impl.model, true);
            }
            n++
         }

      }
      )
   }
   catch { }
}

export function colorIndividualWithElementId(e, row, viewer, tempListModel) {
   let R = hexToR(e.target.value) / 255;
   let G = hexToG(e.target.value) / 255;
   let B = hexToB(e.target.value) / 255;
   let colorDelay = new THREE.Vector4(R, G, B, 1)
   e.target.defaultValue = e.target.value
   if (row.isGrouped) {
      var ds = getSubRowsTableWithElementId(row.subRows)
      _.forEach(ds, (value, key) => {
         _.forEach(value, (v, k) => {
            viewer.setThemingColor(v, colorDelay, tempListModel[key]);
         })
      })
   } else {
      let splice = row.original['ElementId'].split('//')
      viewer.setThemingColor(splice[0], colorDelay, tempListModel[splice[1]]);
   }
}
export function colorIndividualWithoutElementId(e, row, viewer) {
   let colorDelay = convertHexColorToVector4(e.target.value)
   e.target.defaultValue = e.target.value
   if (row.isGrouped) {
      var ds = getSubRowsTableWithoutElementId(row.subRows)
      _.forEach(ds, (value, key) => {
         viewer.setThemingColor(value.original.id, colorDelay, viewer.impl.model, true);
      })
   } else {
      viewer.setThemingColor(row.original.id, colorDelay, viewer.impl.model, true);
   }
}
export function getAllDetailScheduleID(row, value) {
   let temp = []
   if (row.groupByID === 'masterID')
      _.forEach(row.subRows, v => {
         temp.push(v.original.detailID)
      })
   else if (row.groupByID === 'detailID')
      _.forEach(row.subRows, v => {
         temp.push(v.original.masterID)
      })
   return row.groupByID === 'status' ? value : temp.join(' / ')
}
export function getAllStatusTask(row) {
   let temp = []
   if (row.groupByID === 'masterID') {
      _.forEach(row.subRows, v => {
         temp.push(v.original.status)
      })
      return temp.join(' / ')
   } else if (row.groupByID === 'detailID') {
      _.forEach(row.subRows, v => {
         temp.push(v.original.status)
      })
      return temp[0]
   } else if (row.groupByID === 'code5D') {
      _.forEach(row.subRows, v => {
         temp.push(v.original.status)
      })
      return temp.join(' / ')
   }
}
//#endregion