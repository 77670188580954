// import { BrowserRouter as Router, useRouteMatch } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, message } from "antd";
import _ from "lodash";

import SideMenu from "../../module_DoInv/components/SideMenu";
import ButtonsOnTop from "../../module_DoInv/components/ButtonsOnTop";
//function component
import Dashboard from "../../module_DoInv/components/Dashboard";
import ExtractDataConcDO from "../../module_DoInv/components/ExtractData/ExtractDataConcDO";
import ExtractDataConcInv from "../../module_DoInv/components/ExtractData/ExtractDataConcInv";
import ImportExcelConcDO from "../../module_DoInv/components/ImportFile/ImportExcelConcDO";
import ExtractDataRebarInv from "../../module_DoInv/components/ExtractData/ExtractDataRebarInv";
import ImportExcelInv from "../../module_DoInv/components/ImportFile/ImportExcelInv";
import ImportPdfScanForm from "../../module_DoInv/components/ImportFile/ImportPdfScanForm";
import FilterDataDrawer from "../../module_DoInv/components/FilterDataDrawer";
//table component
import TablePO from "../../module_DoInv/components/Tables/TablePO";
import TableConcDO from "../../module_DoInv/components/Tables/TableConcDO";
import TableConcDOTemp from "../../module_DoInv/components/Tables/TableConcDOTemp";
import TableConcInv from "../../module_DoInv/components/Tables/TableConcInv";
import TableConcInvTemp from "../../module_DoInv/components/Tables/TableConcInvTemp";
import TableConcCompare from "../../module_DoInv/components/Tables/TableConcCompare";
import TableRebarDO from "../../module_DoInv/components/Tables/TableRebarDO";
import TableRebarInv from "../../module_DoInv/components/Tables/TableRebarInv";
import TableRebarInvTemp from "../../module_DoInv/components/Tables/TableRebarInvTemp";

function DoInvPage() {
   //  const userRole = useSelector((state) => state.user.role);

   const projectId = useSelector((state) => state.project.projectId);
   let userRole = useSelector((state) => {
      if (state.user.role === "admin") {
         return "admin";
      }

      let contractors = state.user.contractors;
      if (projectId && _.isArray(contractors)) {
         let contractor = contractors.find((x) => x.projectId === projectId);
         if (contractor) {
            return contractor.role;
         }
      }

      return "";
   });

   //  const userRole = "QS"; //useSelector((state)=>state.users.)
   //  const projectid = "SP3"; //useSelector((state)=>state.projects.sharePointId)

   const listEditAll = ["QS", "Project Admin", "admin"];
   const listEditLimit = ["Production", "Planning Engineer"];
   const tableRef = useRef(null);
   const [data, setData] = useState([]);
   const [filter_data, setFilterData] = useState([]);
   const [tempData, setTempData] = useState([]);
   const [statistic, setStatistic] = useState([]);
   const [current, setcurrent] = useState("conc_do");
   const [generalState, setGeneralState] = useState(0);
   const [filterMode, setFilterMode] = useState("3");
   const [shownAdvanceFilter, setShownAdvanceFilter] = useState(false);
   const [forceReRender, setForceReRender] = useState(true);

   useEffect(() => {
      localStorage.removeItem("fp_project_id");
      // localStorage.removeItem("fp_user_role");
      localStorage.setItem("fp_project_id", projectId);
      // userRole = "QS";
      let role = "edit_none";
      if (listEditAll.includes(userRole)) {
         role = "edit_all";
      }
      if (listEditLimit.includes(userRole)) {
         role = "edit_limit";
      }

      // role = "edit_all";
      localStorage.setItem("fp_user_role", role);
   }, []);

   return (
      <div>
         <SideMenu
            current={current}
            setcurrent={setcurrent}
            filterMode={filterMode}
            statistic={statistic}
            setData={setData}
            setTempData={setTempData}
            setStatistic={setStatistic}
         />
         {/* <Route path={`${path}/fp`}> */}

         <div className="form-parser">
            {current !== "sum" ? (
               <div className="button-row-on-top">
                  <ButtonsOnTop
                     tableRef={tableRef}
                     data={data}
                     filter_data={filter_data}
                     current={current}
                     filterMode={filterMode}
                     generalState={generalState}
                     forceReRender={forceReRender}
                     setFilterMode={setFilterMode}
                     setShownAdvanceFilter={setShownAdvanceFilter}
                     setGeneralState={setGeneralState}
                     setData={setData}
                     setForceReRender={setForceReRender}
                  />
               </div>
            ) : null}
            <div className="table-data-holder">
               <Row>
                  <Col span={24}>{current === "sum" ? <Dashboard current={current} /> : null}</Col>

                  <Col span={current !== "sum" && (generalState === 0 || generalState === 10) ? 24 : 20}>
                     {/* <TableData /> */}
                     <div>
                        {current === "po" && (generalState === 0 || generalState > 30) ? (
                           <TablePO
                              tableRef={tableRef}
                              data={data}
                              filter_data={filter_data}
                              filterMode={filterMode}
                              generalState={generalState}
                              forceReRender={forceReRender}
                              setData={setData}
                              setFilterData={setFilterData}
                              setStatistic={setStatistic}
                           />
                        ) : null}

                        {current === "conc_do" && (generalState === 0 || generalState > 30) ? (
                           <TableConcDO
                              tableRef={tableRef}
                              data={data}
                              filter_data={filter_data}
                              filterMode={filterMode}
                              generalState={generalState}
                              forceReRender={forceReRender}
                              setData={setData}
                              setFilterData={setFilterData}
                              setStatistic={setStatistic}
                           />
                        ) : null}
                        {current === "conc_do" && generalState > 0 && generalState < 10 ? (
                           <TableConcDOTemp
                              tableRef={tableRef}
                              generalState={generalState}
                              tempData={tempData}
                              setTempData={setTempData}
                           />
                        ) : null}

                        {current === "conc_inv" && (generalState === 0 || generalState > 30) ? (
                           <TableConcInv
                              tableRef={tableRef}
                              data={data}
                              filterMode={filterMode}
                              generalState={generalState}
                              forceReRender={forceReRender}
                              setData={setData}
                              setStatistic={setStatistic}
                           />
                        ) : null}

                        {current === "conc_inv" && generalState > 0 && generalState < 10 ? (
                           <TableConcInvTemp
                              tableRef={tableRef}
                              generalState={generalState}
                              tempData={tempData}
                              setTempData={setTempData}
                           />
                        ) : null}

                        {current.includes("conc") && generalState === 10 ? (
                           <TableConcCompare
                              current={current}
                              filterMode={filterMode}
                              generalState={generalState}
                              setStatistic={setStatistic}
                           />
                        ) : null}

                        {current === "rebar_do" && (generalState === 0 || generalState > 30) ? (
                           <TableRebarDO
                              tableRef={tableRef}
                              data={data}
                              filterMode={filterMode}
                              generalState={generalState}
                              setData={setData}
                              setStatistic={setStatistic}
                           />
                        ) : null}

                        {current === "rebar_inv" && (generalState === 0 || generalState > 30) ? (
                           <TableRebarInv
                              tableRef={tableRef}
                              data={data}
                              filterMode={filterMode}
                              generalState={generalState}
                              setData={setData}
                              setStatistic={setStatistic}
                           />
                        ) : null}

                        {current === "rebar_inv" && generalState > 0 && generalState < 10 ? (
                           <TableRebarInvTemp tableRef={tableRef} tempData={tempData} setTempData={setTempData} />
                        ) : null}
                     </div>
                  </Col>

                  {current === "conc_do" && generalState === 1 ? (
                     <Col span={4}>
                        <ExtractDataConcDO
                           current={current}
                           generalState={generalState}
                           setGeneralState={setGeneralState}
                           setTempData={setTempData}
                        />
                     </Col>
                  ) : null}

                  {current === "conc_do" && generalState === 2 ? (
                     <Col span={4}>
                        <ImportExcelConcDO
                           tempData={tempData}
                           generalState={generalState}
                           setGeneralState={setGeneralState}
                           setTempData={setTempData}
                        />
                     </Col>
                  ) : null}

                  {current === "conc_inv" && generalState === 1 ? (
                     <Col span={4}>
                        <ExtractDataConcInv
                           current={current}
                           generalState={generalState}
                           setGeneralState={setGeneralState}
                           setTempData={setTempData}
                        />
                     </Col>
                  ) : null}

                  {current === "conc_inv" && generalState === 2 ? (
                     <Col span={4}>
                        <ImportExcelInv
                           tempData={tempData}
                           current={current}
                           generalState={generalState}
                           setGeneralState={setGeneralState}
                           setTempData={setTempData}
                        />
                     </Col>
                  ) : null}

                  {/* {current === "rebar_do" && generalState === 1 ? message.warn({ content: "in developement, stay tune." }) : null}

            {current === "rebar_do" && generalState === 2 ? message.warn({ content: "in developement, stay tune." }) : null} */}

                  {current === "rebar_inv" && generalState === 1 ? (
                     <Col span={4}>
                        <ExtractDataRebarInv
                           tempData={tempData}
                           generalState={generalState}
                           setGeneralState={setGeneralState}
                           setTempData={setTempData}
                        />
                     </Col>
                  ) : null}

                  {current === "rebar_inv" && generalState === 2 ? (
                     <Col span={4}>
                        <ImportExcelInv />
                     </Col>
                  ) : null}
               </Row>
               {generalState === 32 ? <ImportPdfScanForm current={current} setGeneralState={setGeneralState} /> : null}

               {shownAdvanceFilter ? (
                  <FilterDataDrawer
                     current={current}
                     shownAdvanceFilter={shownAdvanceFilter}
                     setShownAdvanceFilter={setShownAdvanceFilter}
                     setData={setData}
                     setStatistic={setStatistic}
                  />
               ) : null}
            </div>
         </div>
         {/* </Route> */}
      </div>
   );
}

export default DoInvPage;
