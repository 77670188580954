import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox } from 'rsuite';

import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,
} from 'react-table'
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getSubRowsTableWithoutElementId } from '../function/TableFunction'
function TableHistory({ columns, data,
  getRowProps,
  getCellProps,
  handleOpenControlInformationPanel,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter,
    }),
    []
  )
  function ListValueFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id, units, filter },
  }) {
    const [colorIcon, setColorIcon] = React.useState('#bfbfbf');
    const [list, setList] = React.useState(filterValue);
    const data = React.useMemo(() => {
      let dataNeed = []
      let rowValue = []
      preFilteredRows.forEach(row => {
        rowValue.push(row.values[id])
      })
      let intersection = _.uniq(rowValue);
      intersection.sort();
      intersection.forEach(v => {
        dataNeed.push({ label: v, value: v })
      })
      return dataNeed
    }, [id, preFilteredRows])

    const handleFilter = (value, e) => {
      try {
        let temp = []
        if (value !== null || value !== undefined) {
          value.forEach(v => {
            temp.push(v)
          })
          setList(temp)
        } else {
          setList([])
        }
        setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
        setFilter(temp)

      } catch{ }
    }
    const handleCheckAll = (value, checked) => {
      try {
        let temp = []
        if (checked)
          data.forEach(v => {
            temp.push(v.value)
          })
        setList(temp)
        setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
        setFilter(temp)

      } catch{ }

    }
    React.useEffect(() => {
      if (filterValue.length === 2)
        setColorIcon((filterValue[0] !== undefined || filterValue[1] !== undefined) ? 'black' : '#bfbfbf')
      else if (filterValue.length === 0)
        setColorIcon('#bfbfbf')
    });
    return (
      <div>
        <Whisper placement='bottomRight' trigger="click" speaker={
          <Popover >
            <Tooltip title="Hold Ctrl Key to Search or Pick multi-value" placement="bottom">
              <CheckPicker defaultValue={filter === 'includes' ? list : []} data={data} style={{ width: '200px' }} onChange={handleFilter} size="xs"
                renderExtraFooter={() => (
                  <div style={{ padding: '10px 2px', borderTop: '1px solid #e5e5e5' }}>
                    <Checkbox inline
                      indeterminate={(filter === 'includes' ? list : []).length < data.length && (filter === 'includes' ? list : []).length > 0}
                      checked={(filter === 'includes' ? list : []).length === data.length}
                      onChange={handleCheckAll}
                      style={{ color: 'blue' }}>
                      Check all
                        </Checkbox>
                  </div>
                )} />
            </Tooltip>
            <br />
            <Button appearance="link" onClick={() => {
              filterValue = []
              setList([])
              setFilter(undefined)
            }}>Reset</Button>
          </Popover>
        }>
          <Icon icon="filter" style={{ right: 0, top: 0, position: 'absolute', padding: 5, color: colorIcon }} />
        </Whisper>

      </div>
    )
  }
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'functionItem',
            groupByBoundary: true,
            collapse: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div></div>
            ),
            Cell: ({ row }) => (

              <ButtonGroup>
                <Tooltip title="Information" placement="top">
                  <IconButton size="xs" icon={<Icon icon="info" />} onClick={() => showOpenControlInformationPanel(row)}></IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
          ...columns,
        ]
      })

    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    footerGroups,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      pageIndex, pageSize, groupBy
    }
  } = instance

  const showOpenControlInformationPanel = (row) => {
    let temp = []
    if (row.isGrouped) {
      var ds = getSubRowsTableWithoutElementId(row.subRows)
      _.forEach(ds, item => {
        _.forEach(item.original.users, v => {
          let index = _.findIndex(temp, o => { return o.email === v.email })
          if (index < 0)
            temp.push(_.clone(v))
          else
            temp[index].view = temp[index].view + v.view
        })
      })
    } else {
      temp = row.original.users
    }
    handleOpenControlInformationPanel(temp)
  }

  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { name, value }) => {
    setPageSize(value)
  }
  const columnCount = columns.length
  const listGroup = ['projectName', 'folderName']
  return (
    <div>

      <div style={{ float: 'right', paddingRight: 15 }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle', display: data.length !== 0 ? 'inline-block' : 'none' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />
      </div>
      <div style={{ position: 'absolute', top: 40, overflow: 'auto', bottom: 0, width: '100%' }}>
        <Table celled selectable
          {...getTableProps()} >
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    zIndex: 100,
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center',
                    width: column.id === 'itemName' ? 500 :  column.id === 'view' ? 30 :
                      column.id === 'functionItem' ? 20 : (listGroup.includes(column.id) ? 120 : 'auto')
                  }}>
                    {!column.isGrouped && (listGroup.includes(column.id) && column.toggleGroupBy())}
                    {/* <span {...column.getSortByToggleProps()}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDownwardOutlinedIcon />
                          : <ArrowUpwardOutlinedIcon />
                        : ''}                  
                    </span> */}
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{
                        textAlign: 'center', verticalAlign: 'middle',
                        overflowX: 'hidden', textOverflow: 'ellipsis',
                        whiteSpace: cell.column.id === 'users' &&'break-spaces'
                      }}
                        title={cell.column.id === 'projectName' ? cell.row.values.projectName :
                          cell.column.id === 'folderName' ? cell.row.values.folderName :
                            cell.column.id === 'itemName' && cell.row.values.itemName}>
                        {cell.isGrouped ? (
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.subRows.length !== 0 &&
                                ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                  (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" style={{ float: 'left' }} /> :
                                    <KeyboardArrowRightIcon fontSize="small" style={{ float: 'left' }} />))
                              }
                            </span>{' '}
                            {cell.render('Cell')}

                          </>
                        ) : cell.isAggregated ? (

                          cell.render('Aggregated')
                        ) : cell.isRepeatedValue ? null : (
                          cell.render('Cell')

                        )}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer>
            {footerGroups.map(group => (
              <Table.Row {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  column.id !== 'view' ?
                    <Table.HeaderCell style={{ textAlign: 'left', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px', fontWeight: 'bold' }} {...column.getFooterProps()} >
                    </Table.HeaderCell>
                    :
                    <Table.HeaderCell style={{ textAlign: 'center', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px' }} {...column.getFooterProps()} >
                      {column.render('Footer')}</Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>
        </Table>

      </div>

    </div>
  )
}

export default TableHistory