export const CONFIG = {
  CUT_OFF_DATE: 'CUT_OFF_DATE',
};

export const DESIGNATIONS = [
  'GW',
  'PRELIM',
  'STRUCTURE',
  'ARCHI',
  'MEP',
  'QAQC',
  'QM',
  'SAFETY',
  'DLP',
  'COVID-19',
  'OFF DAY',
];

export const OCCUPATIONS = [
  'ELECTRICIAN (EL)',
  'OFFICE BOY',
  'QC INSPECTOR',
  'GENERAL WORKERS',
  'CHAINMAN (CH)',
  'SAFETY WORKER (EHS) ',
  'METAL SCAFFOLD ERECTORS',
  'RIGGER & SIGNELMAN',
];
