import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Select, Row, Col, Input, Modal, Upload, Button, message, Form } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { updateTableAsync, uploadFileByPutLink } from '../../../../functions/GeneralFunction';
import { v4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';
import base64url from 'base64url';
import { getPublicUrl } from '../../../../../module_BimApp/function/AdminFunction';
import DateCell from '../../cell/DateCell';
import { getUserAndProjectInfoInit } from '../../../../utils';

const { Option } = Select;
const getRouteToNode = (rowNode) => {
  if (!rowNode.parent) {
      return [];
  }
  return [
      ...getRouteToNode(rowNode.parent),
      rowNode.key ? rowNode.key : rowNode.data.employeeName,
  ];
};
const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';
export function ModalFileVersionSubUpD({file, ...props}) {
  const [data, setData] = useState([])
  const [gridApi, setGridApi] = useState()
  const { projectIsAppliedRfaView, history, localState, pageSheetTypeName, storeInitProject, storeInitUser } = props;
  const { email, role, projectId, projectName, isAdmin } = getUserAndProjectInfoInit(storeInitProject, storeInitUser);
  useEffect(() => {
    run()
  }, [])

  const run = async () => {
    try {
      props.setIsLoading(true)
      let res = await axios({
        url: '/api/subcon-upload-file/getAllVersion',
        method: 'GET',
        params: {
          token: props.token,
          _id: file.data._id,
        }
      })
      if (res.data) {
        setData(res.data)
      }
      props.setIsLoading(false)
    } catch (ex) {
      message.warning(ex.message)
      props.setIsLoading(false)
    }
  }



  const handleCancel = () => {
    props.setClose()
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: false,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);
  const columnDefs = [
    {
      field: 'remark', headerName: 'Remark', wrapText: true,
      autoHeight: true,
      cellStyle: params => {
        return { whiteSpace: 'break-spaces', lineHeight: '25px' };
      },
    },
    { field: 'version', headerName: 'Version', minWidth: 100, maxWidth: 100 },
    {
      field: 'updatedAt', headerName: 'Date', width: 100, maxWidth: 150,
      cellRendererSelector: params => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: DateCell,
          }
        }
        return undefined;
      }
    },
    {
      field: 'updatedBy', headerName: 'By', width: 100 ,maxWidth: 180
    },
    {
      headerName: 'Action',
      field: 'action',
      minWidth: 200, maxWidth: 200,
      // lockVisible: !(user.isAdmin || roleForAddEditModule.includes(role)),
      lockPosition: 'right',
      suppressMovable: true,
      // hide: !(user.isAdmin || roleForAddEditModule.includes(role)),
      // suppressToolPanel: !(user.isAdmin || roleForAddEditModule.includes(role)),
      cellRendererSelector: params => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          }
        }
        return undefined;
      }
    },
  ]
  const handleView = (e) => {
    let key = `${props.pathDir}/${file.parent.data._id}/${e._id}/${file.data.name}`
    console.log(key)
    let linkToOpen = `${window.location.origin}/dms-viewer/${base64url(key)}`;
    window.open(linkToOpen, '_blank');
  }
  const handleDownload = async (e) => {
    let key = `${props.pathDir}/${file.parent.data._id}/${e._id}/${file.data.name}`
    let url = await getPublicUrl(key, 10000, typeBucketName)
    window.open(url, '_blank');
  }
  const handleAction = (params) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
        <Button size='small' type='primary' onClick={handleView.bind(this, params.data)}  >View</Button>
        <Button size='small' type='primary' onClick={handleDownload.bind(this, params.data)}  >Download</Button>
        {(role === 'Document Controller' || role === 'Consultant' || isAdmin) && <Button size='small' type='danger' onClick={handleDelete.bind(this, params.data)} >Delete</Button>}
      </div>
    )
  }

  const handleDelete = async (e) => {
    try {
      if (!window.confirm('Are you want to delete?')) return
      props.setIsLoading(true)
      let res = await axios({
        url: '/api/subcon-upload-file/version',
        method: 'delete',
        params: {
          token: props.token,
          fileId: file.data._id,
          versionId: e._id,
          projectId: props.storeInitProject.projectId,
        }
      })
      if (res.data) {
        await updateTableAsync(gridApi.api, { remove: [e] })
        gridApi.api.redrawRows()
      }
      props.setIsLoading(false)
    } catch (ex) {
      message.warning(ex.message)
      props.setIsLoading(false)
    }
  }
  const onGridReady = useCallback((params) => {
    setGridApi(params)
  }, []);
  return (
    <Modal title="Version File" visible={true} onCancel={handleCancel} width={'80%'} footer={null} >



      <div className='dms ag-theme-alpine' style={{ width: '100%', height: 'calc(100vh - 100px)' }} >
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={data}
          columnDefs={columnDefs}
          // headerHeight={25}
          rowHeight={35}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>

    </Modal>
  );
}


