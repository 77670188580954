/* eslint-disable */

// import './dataTables.rowsGroup'
const Autodesk = window.Autodesk;
const THREE = window.THREE;


var viewer = null;

class WHExtension_Sub extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options)
        Autodesk.Viewing.Extension.call(this, viewer, options);
    }
    load() {
     
        return true;
    };
    unload() {
    
        if (this.subToolbar) {
            this.viewer.toolbar.removeControl(this.subToolbar);
            this.subToolbar = null;
        }
    };

  

    onToolbarCreated = function (toolbar) {
        viewer = this.viewer;


        // var button3 = new Autodesk.Viewing.UI.Button('btn-windowselection-sub');
        // button3.onClick = function (e) {
        //     // button3.container.classList.remove('inactive');
        //     // button3.container.classList.add('active');
        //     // SelectionWindow(viewer, 'btn', button3)
        //     printSheet(viewer)
        // };
        // button3.addClass('btn-windowselection-sub');
        // button3.addClass('fas');
        // button3.addClass('fa-mouse-pointer');
        // button3.addClass('fa-2x');
        // button3.setToolTip('Window Selection');

        var button4 = new Autodesk.Viewing.UI.Button('btn-docbrowser-sub');
        button4.addClass('btn-docbrowser-sub');
        button4.addClass('fas');
        button4.addClass('fa-folder-open');
        button4.addClass('fa-2x');
        button4.setToolTip('Document Browser Sub');
        //var options = { collapsible: true, alignVertically: false }
        //var toolbar = new Autodesk.Viewing.UI.ToolBar('wh-toolbar',options);

        // SubToolbar
        this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('wh-custom-toolbar-sub');
        // if (viewer.impl.model.is2d())
        //     this.subToolbar.addControl(button3);
        this.subToolbar.addControl(button4);
        viewer.toolbar.addControl(this.subToolbar);
    };
}


Autodesk.Viewing.theExtensionManager.registerExtension('WHExtension_Sub', WHExtension_Sub);
export default 'WHExtension_Sub'