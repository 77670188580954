import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FolderFilled } from '@ant-design/icons'

export default function SizeCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;


    return (
        <div  style={{display:'flex', alignItems:'center' , gap:5}} >
           <span> {`${(cellValue /1024/1024).toFixed(2)} MB`}</span> 
        </div>
    );
};