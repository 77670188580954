import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { valueFormatterForDouble } from '../common';

const defaultColumn = [
  {
    headerName: 'No.',
    field: 'no',
    filter: true,
    pinned: 'left',
    width: 80,
  },
  {
    headerName: 'Code',
    field: 'code',
    filter: true,
    pinned: 'left',
    width: 100,
  },
  {
    headerName: 'Worker Name',
    field: 'name',
    filter: true,
    pinned: 'left',
    width: 150,
  },
  {
    headerName: 'Site Code',
    field: 'siteCode',
    pinned: 'left',
    width: 120,
  },
  {
    headerName: 'DDT Levy',
    field: 'ddtLevy',
    filter: true,
    pinned: 'left',
    width: 100,
  },
  {
    headerName: 'Adj. Wages',
    pinned: 'left',
    children: [
      { headerName: 'NT', field: 'adjWagesNt', pinned: 'left', width: 80 },
      { headerName: '1.5', field: 'adjWages15', pinned: 'left', width: 80 },
      { headerName: '2', field: 'adjWages2', pinned: 'left', width: 80 },
    ],
  },
  {
    headerName: 'Total Hrs',
    pinned: 'left',
    children: [
      {
        headerName: 'NH',
        field: 'totalHoursNh',
        pinned: 'left',
        width: 80,
        aggFunc: 'sum',
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: '1PH',
        field: 'totalHoursPh',
        pinned: 'left',
        width: 80,
        aggFunc: 'sum',
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: '1.5',
        field: 'totalHours15',
        pinned: 'left',
        width: 80,
        aggFunc: 'sum',
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: '2',
        field: 'totalHours20',
        pinned: 'left',
        width: 80,
        aggFunc: 'sum',
        valueFormatter: valueFormatterForDouble,
      },
    ],
  },
];

function WagesTable(props) {
  const {
    allHolidays,
    cutOffDate,
    dataTimeSheet,
    currentMonthTimeSheet,
    tableHeight,
  } = props;
  const [rowDatas, setRowDatas] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (_.isArray(dataTimeSheet)) {
      GetDataTable();
    }
  }, [dataTimeSheet]);

  function getCellStyle(params) {
    return _.has(params.data, `${params.colDef.field}DayType`) &&
      params.data[`${params.colDef.field}DayType`]
      ? {
          backgroundColor: params.data[`${params.colDef.field}DayType`],
        }
      : null;
  }

  function GetDataTable() {
    let dataForTable = [];

    let columns = [...defaultColumn];

    let startMoment = moment(currentMonthTimeSheet, 'MM-YYYY')
      .subtract(1, 'months')
      .set('date', cutOffDate + 1);

    let colorHomeLeave = '#FF99FF';
    let colorAbsent = '#FF0000';
    let colorCourse = '#FFC000';
    let colorMc = '#00FF00';

    let offDayColors = [
      {
        name: 'Medical (MC)',
        value: colorMc,
      },
      {
        name: 'Course / Training',
        value: colorCourse,
      },
      {
        name: 'Home Leave',
        value: colorHomeLeave,
      },
      {
        name: 'Absent',
        value: colorAbsent,
      },
    ];

    let isFirstAddColumns = false;
    dataTimeSheet.forEach((worker, index) => {
      let rowData = {
        no: index,
        code: worker.employeeCode,
        name: worker.name,
        totalHoursNh: 0,
        totalHoursPh: 0,
        totalHours15: 0,
        totalHours20: 0,
      };

      let startMomentTemp = moment(startMoment);

      worker.timeWorkingInDays.forEach((x, dayIndex) => {
        let rowColumnByDayPrefix = `D${startMomentTemp.format('YYYYMMDD')}`;

        if (!isFirstAddColumns) {
          columns.push({
            headerName: startMomentTemp.format('DD-MM-YYYY'),
            children: [
              {
                headerName: 'NH',
                field: `${rowColumnByDayPrefix}nh`,
                width: 65,
                cellStyle: getCellStyle,
                aggFunc: 'sum',
                valueFormatter: valueFormatterForDouble,
              },

              {
                headerName: '1 (PH)',
                field: `${rowColumnByDayPrefix}1ph`,
                width: 65,
                cellStyle: getCellStyle,
                aggFunc: 'sum',
                valueFormatter: valueFormatterForDouble,
              },
              {
                headerName: '1.5',
                field: `${rowColumnByDayPrefix}15`,
                width: 65,
                cellStyle: getCellStyle,
                aggFunc: 'sum',
                valueFormatter: valueFormatterForDouble,
              },
              {
                headerName: '2',
                field: `${rowColumnByDayPrefix}20`,
                width: 65,
                cellStyle: getCellStyle,
                aggFunc: 'sum',
                valueFormatter: valueFormatterForDouble,
              },
            ],
          });
        }

        rowData[`${rowColumnByDayPrefix}nh`] = x.rate1;
        rowData[`${rowColumnByDayPrefix}1ph`] = 0;
        rowData[`${rowColumnByDayPrefix}15`] = 0;
        rowData[`${rowColumnByDayPrefix}20`] = 0;
        rowData[`${rowColumnByDayPrefix}nhDayType`] = null;
        rowData[`${rowColumnByDayPrefix}1phDayType`] = null;
        rowData[`${rowColumnByDayPrefix}15DayType`] = null;
        rowData[`${rowColumnByDayPrefix}20DayType`] = null;

        if (!x.isAfterCutOff) {
          rowData[`${rowColumnByDayPrefix}15`] = x.rate15;
          rowData[`${rowColumnByDayPrefix}20`] = x.rate20;
        }

        if (x.isDayOff) {
          rowData[`${rowColumnByDayPrefix}nhDayType`] = colorHomeLeave;
          rowData[`${rowColumnByDayPrefix}1phDayType`] = colorHomeLeave;
          rowData[`${rowColumnByDayPrefix}15DayType`] = colorHomeLeave;
          rowData[`${rowColumnByDayPrefix}20DayType`] = colorHomeLeave;

          x.shiftTrades.forEach((shift) => {
            let offDayColor = offDayColors.find(
              (offDayItem) => offDayItem.name == shift.costCodeName
            );
            if (offDayColor) {
              rowData[`${rowColumnByDayPrefix}nhDayType`] = offDayColor.value;
              rowData[`${rowColumnByDayPrefix}1phDayType`] = offDayColor.value;
              rowData[`${rowColumnByDayPrefix}15DayType`] = offDayColor.value;
              rowData[`${rowColumnByDayPrefix}20DayType`] = offDayColor.value;
            }
          });
        }

        if (x.isOffDayHasToPay) {
          console.log(' x.shiftTrades isOffDayHasToPay', x.shiftTrades);

          let isMc = x.offDayType == 'Medical (MC)';

          let isTraining = x.offDayType == 'Course / Training';
          if (isMc) {
            rowData[`${rowColumnByDayPrefix}nhDayType`] = colorMc;
            rowData[`${rowColumnByDayPrefix}1phDayType`] = colorMc;
            rowData[`${rowColumnByDayPrefix}15DayType`] = colorMc;
            rowData[`${rowColumnByDayPrefix}20DayType`] = colorMc;
          }

          if (isTraining) {
            rowData[`${rowColumnByDayPrefix}nhDayType`] = colorCourse;
            rowData[`${rowColumnByDayPrefix}1phDayType`] = colorCourse;
            rowData[`${rowColumnByDayPrefix}15DayType`] = colorCourse;
            rowData[`${rowColumnByDayPrefix}20DayType`] = colorCourse;
          }
        }

        //check is sunday
        if (startMomentTemp.isoWeekday() === 7) {
          rowData[`${rowColumnByDayPrefix}nhDayType`] = '#acb9ca';
          rowData[`${rowColumnByDayPrefix}1phDayType`] = '#acb9ca';
          rowData[`${rowColumnByDayPrefix}15DayType`] = '#acb9ca';
          rowData[`${rowColumnByDayPrefix}20DayType`] = '#acb9ca';
        }

        //public holiday
        if (x.dayType == 3) {
          rowData[`${rowColumnByDayPrefix}nh`] = 0;
          rowData[`${rowColumnByDayPrefix}1ph`] = x.phWidthRate;
          rowData[`${rowColumnByDayPrefix}nhDayType`] = '#c6e0b4';
          rowData[`${rowColumnByDayPrefix}1phDayType`] = '#c6e0b4';
          rowData[`${rowColumnByDayPrefix}15DayType`] = '#c6e0b4';
          rowData[`${rowColumnByDayPrefix}20DayType`] = '#c6e0b4';
        }

        rowData.totalHoursNh += rowData[`${rowColumnByDayPrefix}nh`];

        rowData.totalHoursPh += rowData[`${rowColumnByDayPrefix}1ph`];

        rowData.totalHours15 += rowData[`${rowColumnByDayPrefix}15`];

        rowData.totalHours20 += rowData[`${rowColumnByDayPrefix}20`];
        startMomentTemp = startMomentTemp.add(1, 'days');
      });

      isFirstAddColumns = true;

      dataForTable.push(rowData);
    });

    setRowDatas(dataForTable);

    console.log('dataForTable wages', dataForTable);
    setColumnDefs(columns);
  }

  return (
    <div
      className='ag-theme-alpine'
      style={{ height: tableHeight, boxSizing: 'border-box' }}
    >
      <AgGridReact
        className='ag-theme-balham'
        reactUi='true'
        rowData={rowDatas}
        columnDefs={columnDefs}
        pagination={true}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      ></AgGridReact>
    </div>
  );
}

export default WagesTable;
