import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { search, add, deletes, update, addMulti } from '../../api/costCode';
import {
  Table,
  Divider,
  Icon,
  Pagination,
  Modal,
  Switch,
  notification,
  Row,
  Button,
  Col,
  Upload,
  Popconfirm,
} from 'antd';
import CostCodeForm from '../Forms/CostCodeForm';
import TopActionBar from '../Common/TopActionBar';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';

const { Column } = Table;

CostCode.propTypes = {
  onSubmit: PropTypes.func,
};

CostCode.defaultProps = {
  onSubmit: null,
};

function CostCode(props) {
  const [rows, setRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [id, setId] = useState(null);
  const [record, setRecord] = useState(null);
  const [fileExcel, setFileExcel] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const [dataGroups, setDataGroups] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10000,
  });

  const [pagination, setPagination] = useState({
    pageSize: 10000,
    showSizeChanger: true,
    total: 1,
  });

  useEffect(() => {
    getDatas();
  }, [searchRequest]);

  const getDatas = async () => {
    let request = { ...searchRequest, projectId };
    let res = await search(request);

    if (_.isNil(res.data?.items)) {
      setDatas([]);
      setDataGroups([]);
    } else {
      setDatas(res.data.items);

      let dic = _.groupBy(res.data.items, 'type');
      let keys = Object.keys(dic);
      keys.sort();
      let group = keys.map((key) => ({
        key: key,
        type: dic[key][0].type,
        isGroup: true,
        children: _.orderBy(dic[key], ['name', 'description']),
      }));

      setDataGroups(group);
    }

    await setPagination({
      pageSize: res.data.pageSize,
      showSizeChanger: true,
      total: res.data.totalCount,
    });
  };

  async function onChange(current, pageSize) {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values };
          if (isAdd) {
            try {
              await add(payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }
    setSelectedRowKeys([]);
    await getDatas();
  };

  const onSelectedRowKeysChange = (selectedRowKeys, rows) => {
    setRows(rows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleShowDialogEdit = (record) => {
    setId(record.id);
    setRecord(record);
    setIsAdd(false);
    setVisible(true);
  };

  function handleShowDialogAdd() {
    setIsAdd(true);
    setVisible(true);
  }

  function handleImport(file) {
    setFileExcel(file);
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;
      let workbook = await wb.xlsx.load(buffer);
      let datas = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex != 1) {
            let type = row.values[1];
            let name = row.values[2];
            let description = row.values[3];
            let remark = row.values[4];
            let isShowOnMobile = row.values[5] && row.values[5].toLowerCase == 'true';

            if (!_.isEmpty(name)) {
              datas.push({
                name,
                type,
                description,
                remark,
                projectId,
                isShowOnMobile,
              });
            }
          }
        });
      });

      if (!_.isArray(datas) || datas.length < 1) {
        notification['error']({
          message: 'Import Error',
          description: 'There is no data in the excel !',
        });
      }

      try {
        await addMulti(datas);
        notification['success']({
          message: 'Import',
          description: 'Import cost code successfully!',
        });
        await getDatas();
      } catch (error) {
        notification['error']({
          message: 'Import Error',
          description: error.response.message,
        });
      }
    };

    return false;
  }

  async function onChangeShowOnMobile(e, item) {

    let entity = {
      name: item.name,
      remark: item.remark,
      description: item.description,
      isShowOnMobile: e,
      projectId: item.projectId,
      type: item.type,
    };

    await update(item.id, entity);
    await getDatas();
  }

  return (
    <div>
      <Row gutter={16}>
        <Col lg={24} xs={24}>
          <div>
            <div className='d-flex flex-row  mb-2'>
              <TopActionBar
                className='mr-2'
                handleSearch={handleSearch}
                handleShowDialogAdd={handleShowDialogAdd}
                // handleDeleteItems={handleDeleteItems}
              />
              <Upload
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                beforeUpload={handleImport}
              >
                <Button>
                  <Icon type='upload' /> Import
                </Button>
              </Upload>
              ,
            </div>

            <Table
              loading={_.isNil(datas)}
              className='antTable'
              dataSource={dataGroups}
              bordered
              rowKey={(record) => record.id}
              // rowSelection={rowSelection}
              scroll={{ y: 500 }}
              // pagination={false}
            >
              <Column title='Trade' dataIndex='type' key='type' width='150px' />
              <Column
                title='Cost Code'
                dataIndex='name'
                key='name'
                width='150px'
              />

              <Column
                title='Description'
                dataIndex='description'
                key='description'
              />
              <Column title='Remark' dataIndex='remark' key='remark' />
              <Column
                title='Show On Mobile'
                dataIndex='isShowOnMobile'
                key='isShowOnMobile'
                width='150px'
                render={(text, record) =>
                  record.isGroup !== true && (
                    <span>
                      <Switch
                        onChange={(e) => onChangeShowOnMobile(e, record)}
                        checked={record.isShowOnMobile === true}
                      />
                    </span>
                  )
                }
              />

              <Column
                title='Hours'
                dataIndex='hours'
                key='hours'
                width='120px'
              />

              <Column
                width='120px'
                title='Action'
                key='action'
                render={(text, record) =>
                  record.isGroup !== true && (
                    <span>
                      <Icon
                        onClick={() => handleShowDialogEdit(record)}
                        style={{ fontSize: '20px', color: '#08c' }}
                        type='edit'
                      />

                      <Divider type='vertical' />

                      <Popconfirm
                        title='Are you sure delete this cost code?'
                        onConfirm={() => handleDeleteItems([record.id])}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Icon
                          style={{ fontSize: '20px', color: '#08c' }}
                          type='delete'
                        />
                      </Popconfirm>
                    </span>
                  )
                }
              />
            </Table>

            <Modal
              title='Cost Code'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <CostCodeForm
                id={id}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CostCode;
