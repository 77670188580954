import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Icon,
  IconButton,
  Modal,
  SelectPicker,
  Button,
  ButtonGroup,
  Divider,
} from 'rsuite';
import { Typography, message, Tooltip, List, DatePicker, Checkbox } from 'antd';

import _ from 'lodash';
import axios from 'axios';
import { MenuProvider, animation } from 'react-contexify';
import { Tree } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  getAllChildTreeNode,
  unloadForgeExtension2,
} from '../../../components/module_BimApp/function/ForgeFunction';

import { Dropdown, Header, Progress } from 'semantic-ui-react';
import { socket } from '../../module_BimApp/function/AdminFunction';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
async function apiExportPdf(payload) {
  return await axios.post(
    '/api/forge/da4revit/v1/revit/export-pdf-progress-report',
    payload
  );
}

async function apiGetData(projectId) {
  return await axios.get(
    "/api/archi-library/get-data-by-project-id/" + projectId
  );
}

const { Text, Title } = Typography;
const Autodesk = window.Autodesk;

const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className='box' style={{ ...style, ...viewStyle }} {...props} />;
};

let workingItem = null;
let tempLinkDownload = null;
class SideBarPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      castColor: '#45fc03',
      castDate: [moment().startOf('month'), moment().endOf('month')],
      inputRvtCurrent: null,
      progressBarExecute: 0,
      statusText: '',
      linkDownload: '#',
      statusOptions: [],
      useColor: true,
      paperSize: 'A1',
      addinVersion: '2022',
      loading: false,
      openPanel: false,
      data: [],
      viewName: '',
      table: ['Pile,Dwall', 'Slab 5D'],
      selectedViews: [],
      viewCurrent: null,
      document: null,
      dataTarget: null,
      isModelLoaded: false,
      modelLoaded: null,
      testData: [
        {
          id: props.projectId,
          hasCaret: true,
          icon: 'diagram-tree',
          label: props.projectName,
          type: 'project',
        },
      ],
      role: '',
      archiLibs: []
    };
    this.treeModel = React.createRef();
    this.handleGetTreeNode = this.handleGetTreeNode.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidMount = () => {
    let role = '';
    if (this.props.userInfo.email === 'admin@wohhup.com') {
      role = 'admin';
    } else {
      let index = _.findIndex(this.props.userInfo.contractors, (v) => {
        return v.projectId === this.props.projectId;
      });
      if (index >= 0) {
        role = this.props.userInfo.contractors[index].role;
        this.setState({ role });
      }
    }

    socket.on('Workitem-Notification', this.handleRealtimeWorkitem);

    this.getArchiLib();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tables.length > 0 && this.props.tables != prevProps.tables) {
      this.setState({ table: this.props.tables.map((x) => x.value) });
    }

    if (this.state.table != prevState.table) {
      let status = this.props.tables
        .filter((x) => this.state.table.includes(x.value))
        .map((x) => x.statusColor)
        .flat();

      this.setState({ statusOptions: status });
    }
  }

  componentWillUnmount = () => {
    workingItem = null;
    tempLinkDownload = '';
    socket.removeListener('Workitem-Notification', this.handleRealtimeWorkitem);
  };


  async getArchiLib() {
    let res = await apiGetData(this.props.projectId);
    if (_.isArray(res.data)) {
      let codes = res.data.map(x => ({
        value: x.code,
        text: x.breakdown3,
        key: x.id
      }))

      this.setState({ archiLibs: codes })
    }
    else {
      this.setState({ archiLibs: [] })
    }
  }

  handleRealtimeWorkitem = (data) => {
    if (workingItem === null || data.WorkitemId !== workingItem) {
      return;
    } else if (data.WorkitemId === workingItem) {
      let status = data.Status.toLowerCase();
      if (
        status === 'completed' ||
        status === 'failed' ||
        status === 'cancelled'
      ) {
        workingItem = null;
      }

      this.setState({ progressBarExecute: 100 });
      axios({
        url: data.ExtraInfo,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (this.state.addinVersion == '2022') {
          link.setAttribute('download', 'Progress Report.pdf');
        } else {
          link.setAttribute('download', 'Progress Report.dwf');
        }

        document.body.appendChild(link);
        link.click();
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.viewer) {
      if (this.props.itemIds !== nextProps.itemIds) {
        const models = this.props.viewer.impl.modelQueue().getModels();
        _.forEach(models, (v) => {
          this.props.viewer.unloadModel(v);
        });
        let treeNode = this.handleGetTreeNode();
        _.forEach(treeNode, (node) => {
          node.status = '';
          node.open = false;
          node.color = '';
          node.icon = this.generateNodeIcon(node);
        });
        this.setState(
          {
            openPanel: false,
            viewCurrent: null,
            modelLoaded: null,
            loading: false,
            testData: this.state.testData,
          },
          () => {
            _.forEach(nextProps.itemIds, (v) => {
              let index = _.findIndex(treeNode, (o) => {
                return o.type === 'item' && o.itemId == v.itemId;
              });
              if (index >= 0) {
                treeNode[index].globalOffset = v.globalOffset;
                Autodesk.Viewing.Document.load(
                  'urn:' + v.objectId,
                  this._onDocumentLoadSuccess.bind(this, treeNode[index], true),
                  this._onDocumentLoadFailure.bind(this, treeNode[index], true)
                );
              } else {
                v.isNotTreeNode = true;
                Autodesk.Viewing.Document.load(
                  'urn:' + v.objectId,
                  this._onDocumentLoadSuccess.bind(this, v, true),
                  this._onDocumentLoadFailure.bind(this, v, true)
                );
              }
            });
          }
        );
      }
    }
  }
  handleColorStatus = (item, e) => {
    let temp = [...this.state.statusOptions];
    let selected = temp.find((x) => x.name == item.name);
    selected.color = e.target.value;
    this.setState({ statusOptions: temp });
  };
  //#region //! load model
  handleOpenView = (node) => {
    const models = this.props.viewer.impl.modelQueue().getModels();
    if (models.length !== 0) {
      let split1 = node.text.split('.');
      let extension1 = split1[split1.length - 1].toLowerCase();
      let split2 = models[0].myData.loadOptions.modelNameOverride.split('.');
      let extension2 = split2[split2.length - 1].toLowerCase();
      if (extension1 !== extension2) {
        message.warning(`Only load mode same format`);
        return;
      }
    }
    if (node.status === 'pending') {
      _.forEach(models, (v, k) => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          this.props.viewer.unloadModel(v);
          this.uploadNodeModel(node, true);
          return false;
        }
      });
    } else if (node.open) {
      node.status = 'pending';
      node.icon = this.generateNodeIcon(node);
      this.setState(
        { isModelLoaded: true, testData: this.state.testData },
        () => {
          Autodesk.Viewing.Document.load(
            'urn:' + node.objectId,
            this._onDocumentLoadSuccess.bind(this, node, false),
            this._onDocumentLoadFailure.bind(this, node, false)
          );
        }
      );
    } else if (!node.open) {
      node.status = 'pending';
      node.icon = this.generateNodeIcon(node);
      this.setState(
        { isModelLoaded: false, testData: this.state.testData },
        () => {
          Autodesk.Viewing.Document.load(
            'urn:' + node.objectId,
            this._onDocumentLoadSuccess.bind(this, node, false),
            this._onDocumentLoadFailure.bind(this, node, false)
          );
        }
      );
    }
  };
  uploadNodeModel = (node, check, svfUrl, loadOptions) => {
    if (check) {
      node.status = '';
      node.open = false;
      node.color = '';
      node.icon = this.generateNodeIcon(node);
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
        testData: this.state.testData,
      });
    } else {
      node.status = 'pending';
      node.color = '';
      node.icon = this.generateNodeIcon(node);
      this.props.viewer.loadModel(
        svfUrl,
        loadOptions,
        this._onLoadModelSuccess.bind(this, node, false),
        this._onLoadModelError.bind(this, node, false)
      );
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
        testData: this.state.testData,
      });
    }
  };
  _onDocumentLoadFailure = (node, isSaved = false, viewerErrorCode) => {
    if (!isSaved) {
      node.status = '';
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  _onDocumentLoadSuccess = (node, isSaved = false, doc) => {
    unloadForgeExtension2(this.props.viewer);

    if (isSaved) {
      var viewables = null;
      let view3d = doc
        .getRoot()
        .search({ type: 'geometry', progress: 'complete' }, true);

      let index = _.findIndex(view3d, (e) => {
        return e.data.guid === node.viewGuid;
      });
      if (index >= 0) {
        viewables = view3d[index];
      } else {
        viewables = doc.getRoot().getDefaultGeometry();
      }
      let svfUrl = doc.getViewablePath(viewables);

      let loadOptions = {
        globalOffset: node.globalOffset,
        applyRefPoint: true,
        modelNameOverride: node.text,
        isAEC: true,
        guid: viewables.data.guid,
        viewableID: viewables.data.viewableID,
        itemId: node.itemId,
        version: node.versionCurrent,
        acmSessionId: doc.acmSessionId,
      };

      this.props.viewer.loadModel(
        svfUrl,
        loadOptions,
        this._onLoadModelSuccess.bind(this, node, node.isNotTreeNode),
        this._onLoadModelError.bind(this, node, node.isNotTreeNode)
      );
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
      });
    } else {
      let temp = [];
      let models = this.props.viewer.impl.modelQueue().getModels();
      let view3d = doc
        .getRoot()
        .search({ type: 'geometry', progress: 'complete' }, true);

      _.forEach(view3d, (v) => {
        temp.push({
          label: v.data.name,
          value: v.data.guid,
          group: v.data.role.toUpperCase(),
          obj: v,
        });
      });
      console.log('temp2', temp);
      if (temp.length === 1) {
        if (node.open) {
          _.forEach(models, (v, k) => {
            if (v.myData.loadOptions.modelNameOverride === node.text) {
              this.props.viewer.unloadModel(v);
              this.uploadNodeModel(node, true);
              return false;
            }
          });
        } else {
          let svfUrl = doc.getViewablePath(temp[0].obj);
          let loadOptions = {
            globalOffset: this.props.viewer.impl.model
              ? this.props.viewer.impl.model.myData.globalOffset
              : null,
            applyRefPoint: true,
            modelNameOverride: node.text,
            isAEC: true,
            guid: temp[0].obj.data.guid,
            viewableID: temp[0].obj.data.viewableID,
            itemId: node.itemId,
            version: node.version,
            acmSessionId: doc.acmSessionId,
          };
          this.props.viewer.loadModel(
            svfUrl,
            loadOptions,
            this._onLoadModelSuccess.bind(this, node, false),
            this._onLoadModelError.bind(this, node, false)
          );
          this.setState({
            openPanel: false,
            viewCurrent: null,
            modelLoaded: null,
            loading: false,
          });
        }
      } else {
        if (node.open) {
          console.log('temp', temp);
          _.forEach(models, (v) => {
            if (v.myData.loadOptions.modelNameOverride === node.text) {
              let guid = v.myData.loadOptions.guid;
              this.setState({
                data: temp,
                openPanel: true,
                document: doc,
                viewCurrent: guid,
                modelLoaded: v,
                nodeCurrent: node,
              });
              return false;
            }
          });
        } else
          this.setState({
            data: temp,
            openPanel: true,
            document: doc,
            nodeCurrent: node,
          });
      }
    }
  };
  _onLoadModelSuccess = (node, isSaved, modelCurrent) => {
    this.props.viewer
      .waitForLoadDone({
        onlyModels: this.props.viewer.impl.modelQueue().getModels(),
      })
      .then((res) => {
        let settings = document.getElementById('toolbar-levelsTool');
        if (settings) settings.style.display = 'none';
        // this.props.callbackLoadDone()
      });
    if (!isSaved) {
      node.status = '';
      node.open = true;
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  _onLoadModelError = (node, isSaved, viewerErrorCode) => {
    if (!isSaved) {
      node.status = '';
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  //#endregion

  handleCloseSidebar = (e) => {
    this.props.close();
  };

  //#region tree
  handleNodeCollapse = (node, e) => {
    node.isExpanded = false;
    this.setState({ testData: this.state.testData });
  };
  handleNodeExpand = (node, e) => {
    if (!this.props.viewer) return;
    if (!node.isLoaded) {
      node.secondaryLabel = <Icon icon='spinner' spin />;
      node.disabled = false;
      this.setState({ testData: this.state.testData });
      const models = this.props.viewer.impl.modelQueue().getModels();
      console.log("models", models)
      axios
        .get('/api/versions/project-tree', {
          params: {
            id: node.id,
            type: node.type,
            role: 'Document Controller',
            token: this.props.userInfo.token,
            isFilter: true,
          },
        })
        .then((res) => {
          res.data.sort(this.sortLabel);
          _.forEach(res.data, (v) => {
            if (v.typeFile !== '5d') return;
            let extension = '';
            if (v.type === 'item') {
              let split = v.text.split('.');
              extension = split[split.length - 1].toLowerCase();
              //v.type = extension === 'fbx' ? 'item' : extension === ext ? 'item' : 'no support'
            }
            if (v.type === 'item') {
              let index = _.findIndex(models, (o) => {
                return o.myData.loadOptions.itemId === v.itemId;
              });
              v.status = '';
              v.color = '';
              v.open = index >= 0 ? true : false;
              v.label = this.generateNodeLabel(v);
              v.icon = this.generateNodeIcon(v);
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            } else if (v.type === 'no translate') {
              v.label = (
                <>
                  <Text style={{ color: 'orange' }}> {v.text} </Text>
                </>
              );
              v.icon = (
                <ButtonGroup>
                  <IconButton
                    size='xs'
                    icon={<Icon icon={'lock'} />}
                    disabled={false}
                  />
                </ButtonGroup>
              );
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            } else if (v.type === 'no support') {
              v.label = (
                <>
                  <IconButton
                    size='xs'
                    disabled={false}
                    icon={<Icon icon={'lock'} />}
                  />
                  <Text> {v.text} </Text>
                </>
              );
              v.icon = (
                <ButtonGroup>
                  <IconButton
                    size='xs'
                    icon={<Icon icon={'lock'} />}
                    disabled={false}
                  />
                </ButtonGroup>
              );
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            }
          });
          node.secondaryLabel = <Icon icon='refresh' />;
          node.childNodes = res.data;
          node.isExpanded = true;
          node.disabled = false;
          node.isLoaded = true;
          this.setState({ testData: this.state.testData });
        })
        .catch((err) => {
          node.disabled = false;
          node.secondaryLabel = '';
        });
    } else {
      node.isExpanded = true;
      this.setState({ testData: this.state.testData });
    }
  };
  generateNodeIcon = (node) => {
    return (
      <ButtonGroup>
        <IconButton
          size='xs'
          onClick={this.handleOpenView.bind(this, node)}
          color={node.open && 'blue'}
          disabled={node.itemId === this.props.itemId}
          icon={
            <Icon
              icon={
                node.status === 'pending'
                  ? 'spinner'
                  : !node.open
                    ? 'eye-slash'
                    : 'eye'
              }
              spin={node.status === 'pending' ? true : false}
            />
          }
        />
      </ButtonGroup>
    );
  };
  generateNodeLabel = (node) => {
    return (
      <MenuProvider
        id='treeview_menu'
        animation={animation.zoom}
        data={{ node: node }}
      >
        <Text> {node.text} </Text>
      </MenuProvider>
    );
  };
  sortLabel = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  getLoadOptions(data, is2d = true) {
    let loadOptions2d = {
      globalOffset: this.props.viewer.impl.model
        ? this.props.viewer.impl.model.myData.globalOffset
        : null,
      applyRefPoint: true,
      modelNameOverride: data.node.text,
      isAEC: true,
    };

    let loadOptions3d = {
      globalOffset: this.props.viewer.impl.model
        ? this.props.viewer.impl.model.myData.globalOffset
        : null,
      applyRefPoint: true,
      modelNameOverride: data.node.text,
      isAEC: true,
      guid: data.viewableSelected.data.guid,
      viewableID: data.viewableSelected.data.viewableID,
      itemId: data.node.itemId,
      version: data.node.version,
      acmSessionId: this.state.document.acmSessionId,
    };

    return is2d ? loadOptions2d : loadOptions3d;
  }
  //#endregion
  handleClosePanel = (data, check) => {
    if (data === null) {
      let node = this.state.nodeCurrent;
      node.status = '';
      node.icon = (
        <ButtonGroup>
          <IconButton
            size='xs'
            onClick={this.handleOpenView.bind(this, node)}
            color={node.open && 'blue'}
            icon={
              <Icon
                icon={
                  node.status === 'pending'
                    ? 'spinner'
                    : !node.open
                      ? 'eye-slash'
                      : 'eye'
                }
                spin={node.status === 'pending' ? true : false}
              />
            }
          />
        </ButtonGroup>
      );
      this.setState({ openPanel: false, testData: this.state.testData });
    } else if (!check) {
      this.props.viewer.unloadModel(data.model);
      this.uploadNodeModel(data.node, true);
    } else if (check) {
      this.setState({
        viewName: data.viewableSelected.data.name,
        selectedViews: [data.viewableSelected.data.name],
      });

      axios
        .post('/api/items/get-item-version', {
          itemId: data.node.itemId,
          version: data.node.version,
          viewGuid: '',
        })
        .then((v) => {
          let temp = [];
          let dt = v.data;
          let index = _.findIndex(dt.revisions, (o) => {
            return o.version === dt.versionCurrent;
          });

          if (index >= 0) {
            dt.objectId = dt.revisions[index].objectId;
            temp.push(dt);
          }

          //get first model
          let first = temp[0];

          let currentRevision = first.revisions[first.revisions.length - 1];
          let inputRvtCurrent = `https://developer.api.autodesk.com/oss/v2/buckets/${currentRevision.bucketKey.toLowerCase()}/objects/${currentRevision.objectKey
            }`;

          this.setState({ inputRvtCurrent: inputRvtCurrent });
        });

      if (data.node.open) {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model
            ? this.props.viewer.impl.model.myData.globalOffset
            : null,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId,
        };

        loadOptions = this.getLoadOptions(
          data,
          data.viewableSelected?.data?.role == '2d'
        );

        this.props.viewer.unloadModel(this.state.modelLoaded);
        this.uploadNodeModel(data.node, false, svfUrl, loadOptions);
      } else {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model
            ? this.props.viewer.impl.model.myData.globalOffset
            : null,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId,
        };

        loadOptions = this.getLoadOptions(
          data,
          data.viewableSelected?.data?.role == '2d'
        );

        this.props.viewer.loadModel(
          svfUrl,
          loadOptions,
          this._onLoadModelSuccess.bind(this, data.node, false),
          this._onLoadModelError.bind(this, data.node, false)
        );
        this.setState({
          openPanel: false,
          viewCurrent: null,
          modelLoaded: null,
          loading: false,
          testData: this.state.testData,
        });
      }
    }
  };
  handleGetTreeNode = () => {
    return getAllChildTreeNode(this.state.testData);
  };

  handleChangePaperSize = (e, data) => {
    this.setState({ paperSize: data.value });
  };
  handleChangeAddinVersion = (e, data) => {
    this.setState({ addinVersion: data.value });
  };

  handleChangeTable = (e, data) => {
    this.setState({ table: data.value });
  };
  handleChangeViews = (e, data) => {
    this.setState({ selectedViews: data.value });
  };

  async handleOk() {
    this.setState({ progressBarExecute: 1 });
    let payload = {
      inputRvt: this.state.inputRvtCurrent,
      appBundleName:
        this.state.addinVersion == '2022'
          ? 'ExportToPdfReportProgress_2022Activity'
          : 'ExportToPdfReportProgress_2020Activity',
      inputJson: {
        UseColor: this.state.useColor,
        View: this.state.viewName,
        Views: this.state.selectedViews,
        PageSize: this.state.paperSize,
        Colors: this.state.useColor ? this.state.statusOptions : [],
        Version:
          this.state.addinVersion == '2022'
            ? 'ExportToPdfReportProgress_2022Activity'
            : 'ExportToPdfReportProgress_2020Activity',
        CastColor: this.state.castColor,
        CastStartDate: this.state.castDate[0].format(dateFormat),
        CastEndDate: this.state.castDate[1].format(dateFormat),
      },
      projectId: this.props.projectId,
      tables: this.state.table,
    };

    localStorage.setItem(
      'COLOR-PROGRESS-REPORT',
      JSON.stringify(this.state.statusOptions)
    );

    try {
      let res = await apiExportPdf(payload);
      workingItem = res.data.WorkitemId;
      this.setState({ progressBarExecute: 50 });
    } catch (error) { }
  }


  isHasArchiTable() {
    return this.state.table.includes("Archi");
  }
  render() {



    const paperSize = [
      { key: 'A0', text: 'A0', value: 'A0' },
      { key: 'A1', text: 'A1', value: 'A1' },
      { key: 'A2', text: 'A2', value: 'A2' },
      { key: 'A3', text: 'A3', value: 'A3' },
      { key: 'A4', text: 'A4', value: 'A4' },
    ];

    const addinVersions = [
      { key: '2020', text: 'Revit 2020', value: '2020' },
      { key: '2022', text: 'Revit 2022', value: '2022' },
    ];

    let allViews = this.state.data.map((x, index) => ({
      key: index,
      text: `[${x.group}]-${x.label}`,
      value: x.label,
      type: x.group,
    }));

    return (
      <>
        <div
          id='dataentry-treemodel'
          style={{
            width: 430,
            height: '100%',
            position: 'absolute',
            background: 'white',
            zIndex: 103,
            padding: 10,
            display: this.props.open ? 'block' : 'none',
          }}
        >
          <div
            style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}
          >
            <Title level={4} style={{ display: 'contents' }}>
              Progress Report
            </Title>
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div
            style={{
              padding: '0px 0px 0px 0px',
              width: '390px',
              height: 'calc(22%)',
            }}
          >
            <Scrollbars
              renderView={renderView}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}
            >
              <Tree
                ref={this.treeModel}
                contents={this.state.testData}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              />
            </Scrollbars>
          </div>

          <Divider style={{ margin: '5px 0' }} />

          <div
            style={{
              padding: '0px 0px 0px 0px',
              height: 'calc(50%)',
              overflowY: 'auto',
            }}
          >
            <label>Views :</label>
            <Dropdown
              onChange={this.handleChangeViews}
              placeholder='Views'
              value={this.state.selectedViews}
              fluid
              style={{ marginBottom: 5 }}
              selection
              multiple
              options={allViews}
              defaultValue={this.state.selectedViews}
            />
            <label>Tables :</label>
            <Dropdown
              onChange={this.handleChangeTable}
              placeholder='Table'
              value={this.state.table}
              fluid
              style={{ marginBottom: 5 }}
              selection
              multiple
              options={this.props.tables}
              defaultValue={this.state.table}
            />
            <label>Revit Version :</label>
            <Dropdown
              onChange={this.handleChangeAddinVersion}
              placeholder='Addin version'
              value={this.state.addinVersion}
              fluid
              style={{ marginBottom: 5 }}
              selection
              options={addinVersions}
              defaultValue={this.state.addinVersion}
            />
            <label>Paper Size :</label>
            <Dropdown
              onChange={this.handleChangePaperSize}
              placeholder='Paper Size'
              value={this.state.paperSize}
              fluid
              style={{ marginBottom: 5 }}
              selection
              options={paperSize}
              defaultValue={this.state.paperSize}
            />

            <label>Select range and color for casted element in:</label>
            <List
              size='small'
              bordered
              style={{ overflow: 'auto', marginBottom: 5 }}
              dataSource={['#eb4034']}
              renderItem={(item) => (
                <List.Item>
                  <RangePicker
                    onChange={(e) => {
                      this.setState({ castDate: e });
                    }}
                    size='small'
                    value={this.state.castDate}
                    style={{ marginRight: 5 }}
                    format={dateFormat}
                  />
                  <input
                    type='color'
                    style={{ position: 'absolute', right: 10 }}
                    value={this.state.castColor}
                    onChange={(e) => {
                      this.setState({ castColor: e.target.value });
                    }}
                  />
                </List.Item>
              )}
            />
            <Checkbox
              checked={this.state.useColor}
              onChange={(e) => this.setState({ useColor: e.target.checked })}
            >
              Use Color Code :
            </Checkbox>
            {this.state.statusOptions.length !== 0 && this.state.useColor && (
              <List
                size='small'
                bordered
                style={{ overflow: 'auto' }}
                dataSource={this.state.statusOptions}
                renderItem={(item) => (
                  <List.Item>
                    <Text strong>{item.name}</Text>
                    <input
                      type='color'
                      style={{ position: 'absolute', right: 10 }}
                      value={item.color}
                      onChange={this.handleColorStatus.bind(this, item)}
                    />
                  </List.Item>
                )}
              />
            )}
            {/* 
            {this.isHasArchiTable() &&
              [
                <label>Activity :</label>,
                <Dropdown
                  onChange={(e) => {
                    console.log("e", e)
                  }}
                  placeholder='Choose Activity Code'
                  value={this.state.ArchiFixedCode}
                  fluid
                  style={{ marginBottom: 5 }}
                  selection
                  options={this.state.archiLibs}
                />
              ]} */}

          </div>

          <div className='mt-2' style={{ textAlignLast: 'center' }}>
            {this.state.progressBarExecute !== 0 && (
              <Progress
                id='configProgressBar'
                percent={this.state.progressBarExecute}
                indicating
                style={{ width: '95%', left: '2.5%', marginBottom: 5 }}
              />
            )}

            <Header as='h5' style={{ marginTop: 0 }}>
              {this.state.statusText}
            </Header>
            {this.state.statusText === 'Completed' && (
              <a href={this.state.linkDownload} target='_blank'>
                <Header
                  style={{ display: this.state.showLinkDownload }}
                  as='h5'
                  color='blue'
                >
                  DOWNLOAD
                </Header>
              </a>
            )}
          </div>

          <div>
            <ButtonGroup>
              {
                <Button
                  color='blue'
                  onClick={this.handleOk}
                  disabled={
                    _.isEmpty(this.state.viewName) ||
                    _.isEmpty(this.state.inputRvtCurrent)
                  }
                  loading={this.state.loadingBtn}
                >
                  <Icon icon='play' /> Execute
                </Button>
              }
            </ButtonGroup>
          </div>
        </div>

        {this.state.openPanel && (
          <SelectViewPanel
            open={this.state.openPanel}
            close={this.handleClosePanel}
            data={this.state.data}
            viewCurrent={this.state.viewCurrent}
            viewer={this.props.viewer}
            isModelLoaded={this.state.isModelLoaded}
            nodeCurrent={this.state.nodeCurrent}
            modelLoaded={this.state.modelLoaded}
          />
        )}
      </>
    );
  }
}
SideBarPanel.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
  };
}
export default SideBarPanel;

function SelectViewPanel(props) {
  const [viewableSelected, setViewableSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.close(null);
  };
  const handleChangeView = (value, e) => {
    _.forEach(props.data, (v) => {
      if (v.value === value) {
        setViewableSelected(v.obj);
        return false;
      }
    });
  };
  const handleUnloadModel = () => {
    setLoading(true);
    let models = props.viewer.impl.modelQueue().getModels();
    let node = props.nodeCurrent;
    _.forEach(models, (v, k) => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        props.close({ key: k, node: props.nodeCurrent, model: v }, false);
        setLoading(false);
        return false;
      }
    });
  };
  const handleViewable = () => {
    props.close(
      {
        viewableSelected: viewableSelected,
        node: props.nodeCurrent,
        model: props.modelLoaded,
      },
      true
    );
  };
  return (
    <Modal
      show={props.open}
      onHide={handleClose}
      size='xs'
      overflow={true}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>List Viewables</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectPicker
          data={props.data}
          style={{ width: '100%' }}
          defaultValue={props.viewCurrent}
          groupBy='group'
          placeholder='Select View'
          cleanable={false}
          onChange={handleChangeView}
          renderMenuItem={(label, item) => {
            return (
              <div>
                <i
                  className={
                    item.group === '3D'
                      ? 'rs-icon rs-icon-coincide'
                      : 'rs-icon rs-icon-newspaper-o'
                  }
                />{' '}
                {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                <i
                  className={
                    label === '3D'
                      ? 'rs-icon rs-icon-coincide'
                      : 'rs-icon rs-icon-newspaper-o'
                  }
                />{' '}
                {label} - ({item.children.length})
              </div>
            );
          }}
          renderValue={(value, item) => {
            return (
              <div>
                <span style={{ color: '#000000' }}>
                  <i
                    className={
                      item.group === '3D'
                        ? 'rs-icon rs-icon-coincide'
                        : 'rs-icon rs-icon-newspaper-o'
                    }
                  />
                  {item.group === '3D' ? ' View3D' : ' Sheet'} :
                </span>{' '}
                {item.label}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {props.isModelLoaded && (
          <Button onClick={handleUnloadModel} color='red' loading={loading}>
            Unload
          </Button>
        )}
        <Button
          onClick={handleViewable}
          appearance='primary'
          loading={loading}
          disabled={viewableSelected === null}
        >
          Load
        </Button>
        <Button onClick={handleClose} appearance='subtle'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
