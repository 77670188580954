
import { Badge, message, Modal, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { apiFetchDataThisProject, apiFetchRowHistoryThisProject } from '../api';
import { pieChartColors2 } from '../constants';
import { convertDataFromDB, createDummyRecords, getUserAndProjectInfoInit, getUserRoleTradeCompany, inputStackData } from '../utils';
import ChartBarDrawing from './ChartBarDrawing';
import ChartPieDrawing from './ChartPieDrawing';
import ChartProgress from './ChartProgress';
import FormPivot from './FormPivot';
import { ChartPanel } from './PageDashboard';
import TableDrawingList from './TableDrawingList';




const { TabPane } = Tabs;

const arrBreak = Array.from(Array(17).keys());




const PanelAllChartProject = (props) => {

   let { isThisPanelInAllProjectsDashboard, propsFromAllProjectsDashboard } = props;

   const [loading, setLoading] = useState(false);

   const [initData, setInitData] = useState(
      isThisPanelInAllProjectsDashboard
         ? {
            projectName: propsFromAllProjectsDashboard.projectName,
            projectId: propsFromAllProjectsDashboard.projectId,
            dataProject: propsFromAllProjectsDashboard.dataProject,
            roleTradeCompany: propsFromAllProjectsDashboard.roleTradeCompany,
         }
         : {}
   );

   // const mounted = useRef(false);
   // useEffect(() => {
   //    mounted.current = true;
   //    return () => mounted.current = false;
   // });


   useEffect(() => {

      const fetchProjectDataWithHistory = async () => {

         if (!isThisPanelInAllProjectsDashboard) {

            const { storeInitProject, storeInitUser, company, token, callback } = props;

            // window.time.startDMSAPI = Date.now();

            let { email, role, projectId, projectName } = getUserAndProjectInfoInit(storeInitProject, storeInitUser);

            setLoading(true);

            try {

               let [resRowsHistory, resProject] = await Promise.all([
                  apiFetchRowHistoryThisProject({ token, projectId }),
                  apiFetchDataThisProject({ token, projectId, email, isNoNeedSortRows: true })
               ]);
               // window.time.endDMSAPI = Date.now();
               // console.log(`Start: ${new Date(window.time.startDMSAPI)} | End: ${new Date(window.time.endDMSAPI)} | DMS API Time: `, window.time.endDMSAPI - window.time.startDMSAPI)
               
               const resProjectData = resProject.data;

               if (resProjectData.rows && resProjectData.rows.length > 0) {
                  // window.time.startDMSLogic = Date.now()
                  const result = convertDataFromDB(
                     [resProjectData],
                     [{ histories: resRowsHistory.data, projectId }],
                     [{ name: projectName, id: projectId }]
                  );

                  const dataThisProjectSorted = result.projectSplit.find(item => item.projectId === projectId);

                  setInitData({
                     projectName,
                     projectId,
                     roleTradeCompany: getUserRoleTradeCompany(role, company),
                     dataProject: dataThisProjectSorted.dataProject,
                  });

                  // window.time.endDMSLogic = Date.now()
                  // console.log(`Start: ${new Date(window.time.startDMSLogic)} | End: ${new Date(window.time.endDMSLogic)} | DMS Logic Time: `, window.time.endDMSLogic - window.time.startDMSLogic)
                  callback(true);
               } else {
                  callback(false);
               };

               // if (mounted.current) {
               //    setInitData({});
               // };


               setLoading(false);
            } catch (err) {
               setLoading(false);
               message.warn('Network Error!');
               console.log(err);
            };
         };
      };

      fetchProjectDataWithHistory();

      // return () => {
      //    setInitData({});
      // };
   }, []);






   const [drawingTableData, setDrawingTableData] = useState(null);

   const openDrawingTable = ({
      panel, type, category, categorySub1,
      isShowSelectedOnly, headersGroup, progressRows
   }) => {

      const dataProject = initData.dataProject;

      const { dataInfo } = dataProject.find(x => x.panel === panel);
      const { headers } = dataInfo;

      let dataTable;
      if (
         type === 'Bar Drawing Rev' || type === 'Bar Drawing Modeller' ||
         type === 'Bar Drawing Coordinator' || type === 'Bar Drawing Resubmit' || type === 'Bar Drawing Trade'
      ) {
         if (categorySub1) {
            dataTable = dataInfo[type][category][categorySub1];
         } else {
            const allDrawingsInCategory = dataInfo[type][category];
            let arr = [];
            Object.keys(allDrawingsInCategory).forEach(stt => {
               arr = [...arr, ...allDrawingsInCategory[stt]];
            });
            dataTable = arr;
         };
      } else if (type === 'Pie Drawing Status') {
         dataTable = dataInfo[type][category];
      } else if (type === 'Pivot Table All') {
         dataTable = dataInfo.rows;
      } else if (type === 'Pivot Table Group') {
         dataTable = dataInfo.rows;
      } else if (type === 'Progress Late') {
         dataTable = progressRows;
      };

      setDrawingTableData({
         dataTable,
         headers,
         headersGroup: headersGroup || [],
         isShowSelectedOnly,
         tableInfo: {
            panel,
            type,
            category,
            categorySub1
         }
      });
   };


   return (
      <div>
         {initData.dataProject && (
            <TabsStyled type='card'>
               {initData.dataProject.map(item => {
                  let tradeForView;
                  if (item.panel !== 'OVERALL' && item.panel !== 'SUBCON') {
                     tradeForView = item.panel.slice(5, item.panel.length);
                  };
                  let canViewPanelModellerAndCoordinator = false;

                  const { projectName, roleTradeCompany } = initData;

                  if (
                     (roleTradeCompany.trade === tradeForView) ||
                     (tradeForView && roleTradeCompany.role.includes('Manager') && roleTradeCompany.role.toLowerCase().includes(tradeForView.toLowerCase())) ||
                     roleTradeCompany.role === 'Project Manager'
                  ) {
                     canViewPanelModellerAndCoordinator = true;
                  };

                  return (
                     <TabPane tab={item.panel} key={item.panel}>

                        <ChartProgress
                           title='Summary of submissions'
                           data={item}
                           openDrawingTable={openDrawingTable}

                        />

                        <ChartBarDrawing
                           title='No of drawing to resubmit'
                           type='resubmit'
                           data={item}
                           openDrawingTable={openDrawingTable}

                        />

                        <ChartPanel title='Sorted table by category' panel={item.panel}>
                           <FormPivot
                              data={item}
                              openDrawingTable={openDrawingTable}
                              projectName={projectName}

                              dataRecordedDummy={createDummyRecords()}
                           />
                        </ChartPanel>


                        {(window.innerWidth >= 1600 && item.panel === 'OVERALL') && arrBreak.map((n, i) => <br key={i} />)}

                        <ChartPieDrawing
                           title='Drawing Status'
                           data={item}
                           openDrawingTable={openDrawingTable}

                        />


                        <ChartPanel title='Status Legend' panel={item.panel}>
                           <div style={{ paddingTop: 25 }}>
                              {inputStackData.map(item => (
                                 <div key={item} style={{ display: 'flex' }}>
                                    <StyledBadge
                                       size='small'
                                       color={pieChartColors2[item]}
                                       text={item}
                                    />
                                 </div>
                              ))}
                           </div>
                        </ChartPanel>


                        {item.panel === 'OVERALL' ? (
                           <ChartBarDrawing
                              title='Status of drawing per trade'
                              type='trade'
                              data={item}
                              openDrawingTable={openDrawingTable}

                           />
                        ) : (
                           <>
                              {(window.innerWidth >= 1600 && item.panel !== 'OVERALL') && arrBreak.map((n, i) => <br key={i} />)}

                              <ChartBarDrawing
                                 title='Status of drawing per revision'
                                 type='rev'
                                 data={item}
                                 openDrawingTable={openDrawingTable}

                              />

                              {canViewPanelModellerAndCoordinator && (
                                 <>
                                    <ChartBarDrawing
                                       title='Drawing by coordinator'
                                       type='coordinator'
                                       data={item}
                                       openDrawingTable={openDrawingTable}

                                    />
                                    <ChartBarDrawing
                                       title='Drawing by modeller'
                                       type='modeller'
                                       data={item}
                                       openDrawingTable={openDrawingTable}

                                    />
                                 </>
                              )}

                           </>
                        )}
                     </TabPane>
                  )
               })}
            </TabsStyled>
         )}


         {drawingTableData && (
            <Modal
               title={'Drawing List'}
               visible={drawingTableData ? true : false}
               footer={false}
               onCancel={() => setDrawingTableData(null)}
               width={0.9 * window.innerWidth}
               bodyStyle={{ paddingTop: 0 }}
               style={{ paddingTop: 0, top: 30 }}
            >
               <TableDrawingList
                  data={drawingTableData}
               />
            </Modal>
         )}

      </div>
   );
};


const mapStateToProps = (state) => {
   return {
      storeInitProject: state.project,
      storeInitUser: state.user,
   };
};

export default connect(mapStateToProps)(PanelAllChartProject);


const StyledBadge = styled(Badge)`
   .ant-badge-status-dot {
      width: 15px;
      height: 15px;
      border-radius: 0;
   }
`;



const TabsStyled = styled(Tabs)`
   .ant-tabs-top-bar {
      margin-bottom: 5px;
   }
`;













