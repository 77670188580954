import axios from "axios";
import _ from "lodash";
import { message } from "antd";

export const func_save_data = async (current, save_data) => {
   const project_id = localStorage.getItem("fp_project_id");
   let got_delete_row = false;
   try {
      switch (current) {
         case "conc_do":
            //update those changed data
            var dt = _.cloneDeep(save_data);
            var datat = dt.filter((item) => item.change === true);

            var datas = datat.filter((item) => item.inv_number !== null && item.do_number !== null);
            //remove unnessary properties
            if (datas != undefined && datas.length > 0) {
               datas.forEach((element) => {
                  delete element.change;
                  delete element.checks;
                  const key = `${project_id}_${"conc_do"}_${element.do_number}.pdf`;
                  element.s3_key = key.toLowerCase();
               });

               await axios.put("/api/do_inv/common/update_multi_do", {
                  data: datas,
                  current: current,
               });
               //filter item change and set the change to false
               datas.forEach((e) => {
                  save_data.find((e1) => {
                     if (e1.id == e.id) {
                        e1.change = false;
                     }
                  });
               });
               // setData(save_data);
               message.success("Data has saved");
            }

            //delete those remove data
            var datar = datat.filter(
               (item) => item.do_number === null && item.volume === null && item.product === null
            );
            //remove unnessary properties
            if (datar != undefined && datar.length > 0) {
               const data_array = Array.from(datar);
               const array_id = data_array.map((e) => e.id);
               if (array_id !== undefined || array_id.length > 0) {
                  await axios.delete("/api/do_inv/common/multi", {
                     data: { array_id: array_id, current: current },
                  });

                  //remove item from data list
                  const array_removed = [];
                  array_id.forEach((e) => {
                     save_data.find((e1, index) => {
                        if (e1.id == e) {
                           array_removed.push(index);
                        }
                     });
                  });
                  if (array_removed.length > 0) {
                     while (array_removed.length) {
                        save_data.splice(array_removed.pop(), 1);
                     }
                     // setData(data);
                  }
                  got_delete_row = true;
                  message.info("There is item was removed from list.");
                  message.success("Data has saved");
               }
            }
            return { save_data, got_delete_row };

         case "conc_inv":
            //update those changed data
            var dt = _.cloneDeep(save_data);
            var datat = dt.filter((item) => item.row_type !== "inv" && item.change === true);
            var datas = datat.filter((item) => item.inv_number !== null && item.do_number !== null);
            //remove unnessary properties
            if (datas != undefined && datas.length > 0) {
               delete datas.form_type;
               datas.forEach((element) => {
                  element.do_date = element.date;
                  delete element.date;
                  delete element.change;
                  delete element.checks;
                  const key = `${project_id}_${"conc_do"}_${element.do_number}.pdf`;
                  element.s3_key = key.toLowerCase();
               });
               const data_array = Array.from(datas);
               await axios.put("/api/do_inv/common/update_multi_do", {
                  data: data_array,
                  current: current,
               });
               //filter item change and set the change to false
               datas.forEach((e) => {
                  save_data.find((e1) => {
                     if (e1.id == e.id) {
                        e1.change = false;
                     }
                  });
               });
               message.success("Data has saved");
            }

            //delete those remove data
            var datar = datat.filter((item) => item.inv_number === null && item.do_number === null);
            //remove unnessary properties
            if (datar != undefined && datar.length > 0) {
               const data_array = Array.from(datar);
               const array_id = data_array.map((e) => e.id);
               if (array_id !== undefined || array_id.length > 0) {
                  await axios.delete("/api/do_inv/common/multi", {
                     data: { array_id: array_id, current: current },
                  });

                  //remove item from data list
                  const array_removed = [];
                  array_id.forEach((e) => {
                     save_data.find((e1, index) => {
                        if (e1.id == e) {
                           array_removed.push(index);
                        }
                     });
                  });

                  if (array_removed.length > 0) {
                     while (array_removed.length) {
                        save_data.splice(array_removed.pop(), 1);
                     }
                  }

                  got_delete_row = true;
                  message.info("There is item was removed from list.");
                  message.success("Data has saved");
               }
            }
            return { save_data, got_delete_row };

         case "rebar_do":
            //update those changed data
            var dt = _.cloneDeep(save_data);
            var datat = dt.filter((item) => item.change === true);

            var datas = datat.filter((item) => item.inv_number !== null && item.do_number !== null);
            //remove unnessary properties
            if (datas != undefined && datas.length > 0) {
               datas.forEach((element) => {
                  delete element.change;
                  delete element.checks;
                  const key = `${project_id}_${"rebar_do"}_${element.do_number}.pdf`;
                  element.s3_key = key.toLowerCase();
               });

               await axios.put("/api/do_inv/common/update_multi_do", {
                  data: datas,
                  current: current,
               });
               //filter item change and set the change to false
               datas.forEach((e) => {
                  save_data.find((e1) => {
                     if (e1.id == e.id) {
                        e1.change = false;
                     }
                  });
               });
               message.success("Data has saved");
            }

            //delete those remove data
            var datar = datat.filter(
               (item) => item.do_number === null && item.volume === null && item.product === null
            );
            //remove unnessary properties
            if (datar != undefined && datar.length > 0) {
               const data_array = Array.from(datar);
               const array_id = data_array.map((e) => e.id);
               if (array_id !== undefined || array_id.length > 0) {
                  await axios.delete("/api/do_inv/common/multi", {
                     data: { array_id: array_id, current: current },
                  });

                  //remove item from data list
                  const array_removed = [];
                  array_id.forEach((e) => {
                     save_data.find((e1, index) => {
                        if (e1.id == e) {
                           array_removed.push(index);
                        }
                     });
                  });
                  if (array_removed.length > 0) {
                     while (array_removed.length) {
                        save_data.splice(array_removed.pop(), 1);
                     }
                  }
                  got_delete_row = true;
                  message.info("There is item was removed from list.");
                  message.success("Data has saved");
               }
            }
            return { save_data, got_delete_row };
         // break;

         case "rebar_inv":
            //update those changed data
            var dt = _.cloneDeep(save_data);
            var datat = dt.filter((item) => item.row_type !== "inv" && item.change === true);

            var datas = datat.filter((item) => item.inv_number !== null && item.do_number !== null);
            //remove unnessary properties
            if (datas != undefined && datas.length > 0) {
               delete datas.form_type;
               delete datas.total_weight;
               datas.forEach((element) => {
                  element.do_date = element.date;
                  delete element.date;
                  delete element.change;
                  delete element.checks;
                  const key = `${project_id}_${"rebar_inv"}_${element.inv_number}.pdf`;
                  element.s3_key = key.toLowerCase();
               });
               const data_array = Array.from(datas);
               await axios.put("/api/do_inv/common/update_multi_do", {
                  data: data_array,
                  current: current,
               });

               //filter item change and set the change to false
               datas.forEach((e) => {
                  save_data.find((e1) => {
                     if (e1.id == e.id) {
                        e1.change = false;
                     }
                  });
               });
               message.success("Data has saved");
            }

            //delete those remove data
            var datar = datat.filter((item) => item.inv_number === null && item.do_number === null);
            //remove unnessary properties
            if (datar != undefined && datar.length > 0) {
               const data_array = Array.from(datar);
               const array_id = data_array.map((e) => e.id);
               if (array_id !== undefined || array_id.length > 0) {
                  await axios.delete("/api/do_inv/common/multi", {
                     data: { array_id: array_id, current: current },
                  });

                  //remove item from data list
                  const array_removed = [];
                  array_id.forEach((e) => {
                     save_data.find((e1, index) => {
                        if (e1.id == e) {
                           array_removed.push(index);
                        }
                     });
                  });
                  if (array_removed.length > 0) {
                     while (array_removed.length) {
                        save_data.splice(array_removed.pop(), 1);
                     }
                  }
               }
               got_delete_row = true;
               message.info("There is item was removed from list.");
               message.success("Data has saved");
            }
            return { save_data, got_delete_row };

         default:
            break;
      }
   } catch (error) {
      console.log("error at process data: ", error);
   }
};
