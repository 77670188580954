
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { message} from 'antd'
import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded, showErrorLoadDocument, setupForgeExtensionBeforeLoadedIssuePage,
  getForgeToken, replaceSpinner
} from '../../../components/module_BimApp/function/ForgeFunction'
import _ from 'lodash'
import {  Position, Toaster, } from "@blueprintjs/core";

const Autodesk = window.Autodesk;

let documentId = ''

function CommentViewer(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [loading, setLoading] = useState(false)

  const toaster = useRef()

  useEffect(() => {
    if (props.check) {
      launchViewer(props.objectId)
    } else {
      launchViewer(props.issue.mainModel.urn)
    }
    return () => {
      if (viewer) {
        viewer.tearDown()
        viewer.finish()
        $("#forgeViewer").empty();
      }
    }
  }, [])

  const launchViewer = (urn) => {
    getForgeToken().then(token => {
      let options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d)
        viewer.start();
        setViewer(viewer)
        documentId = 'urn:' + urn;
      });
    })
  }
  useEffect(e => {
    if (viewer)
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess.bind(this, viewer), onDocumentLoadFailure);
  }, [viewer])
  const onDocumentLoadSuccess = (viewer, doc) => {

    doc.downloadAecModelData()
    let viewables = doc.getRoot().search({ 'type': 'geometry', 'guid': props.mainLoadOptions.guid }, true)
    if (viewables.length === 0) {
      message.warning(`View isn't existing in file, load default view`)
      viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true);
    }
    if (viewables.length !== 0) {
      viewer.loadDocumentNode(doc, viewables[0], props.mainLoadOptions)
        .then(i => {
          unloadForgeExtension(viewer, false)
          viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, geometryLoaded)
        })
        .catch(err => {
          showErrorLoadDocument('forgeViewer', 'Load model failed', 0)
        })
      replaceSpinner();
    } else {
      showErrorLoadDocument('forgeViewer', `File isn't contain any view`, 0)
    }
  }
  const geometryLoaded = () => {
    viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, geometryLoaded)
    viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, loadMarkUpExt)

    setupForgeExtensionAfterLoaded(viewer, null, false)
    setupForgeExtensionBeforeLoadedIssuePage(viewer, props.handleChangeMarkup2DPanel, props.markUp2d)


  }
  const loadMarkUpExt = (e) => {
    if (e.extensionId === 'Autodesk.Viewing.MarkupsCore') {
      viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, loadMarkUpExt)
      setLoading(loading)
      props.handleOpenDialogCommentInformation(viewer,globalOffset,e)     
    }
  }

  const onDocumentLoadFailure = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
  }


  return (
    <>
      <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
      <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
      <div style={{ zIndex: 1000, width: '100%', height: '100%' }} id='forgeViewer' />
      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />


    </>
  );
}

CommentViewer.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(CommentViewer)