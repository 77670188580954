import React, { useState, useEffect } from 'react';
import { search, add, deletes, update } from '../../api/levy';
import { Table, Icon, Pagination, notification, DatePicker, Button, Input, InputNumber, Form, Divider } from 'antd';
const moment = require('moment');
const { MonthPicker } = DatePicker;
const { Column } = Table;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber className="w-100" />;
        }
        if (this.props.inputType === 'month') {
            return <MonthPicker className="w-100" />;
        }
        return <Input />;
    };



    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: this.props.inputType === 'month' ? moment(record[dataIndex]) : record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

function LevyForm(props) {
    const [levy, setLevy] = useState(600);
    const [date, setDate] = useState("");
    const [editingKey, setEditingKey] = useState('');
    const [datas, setDatas] = useState([]);
    const [searchRequest, setSearchRequest] = useState({
        filterBy: "",
        pageIndex: 1,
        pageSize: 10,
    });

    const [pagination, setPagination] = useState({
        pageSize: 10,
        showSizeChanger: true,
        total: 1,
    });

    useEffect(() => {
        getDatas();
    }, [searchRequest, props.idEmployee]);

    const getDatas = async () => {

        let res = await search({ ...searchRequest, idEmployee: props.idEmployee });
        await setDatas(res.data?.items);
        await setPagination({
            pageSize: res.data.pageSize, showSizeChanger: true, total: res.data.totalCount
        });
    }

    const onChange = async (current, pageSize) => {
        await setSearchRequest({
            ...searchRequest, pageIndex: current,
            pageSize: pageSize,
        })
    }


    async function handleAddLevy() {
        try {
            let payload = { levy, date, idEmployee: props.idEmployee };
            await add(payload);
            await getDatas();
        } catch (error) {
            notification['error']({
                message: 'Error',
                description:
                    error?.response?.data?.message,
            });
        }

    };

    async function handleUpdateLevy(form, key) {
        form.validateFields(async (error, row) => {
            if (error) {
                return;
            }
            let payload = { ...row, date: row.date.format("YYYY-MM") };
            try {
                await update(key, payload);
                setEditingKey('');
                await getDatas();
            } catch (error) {
                notification['error']({
                    message: 'Error',
                    description:
                        error?.response?.data?.message,
                });
            }

        });

    }

    const handleSearch = async (e) => {
        await setSearchRequest({
            ...searchRequest,
            filterBy: e
        })
        await getDatas();
    }

    const handleDeleteItems = async (ids = null) => {
        await deletes(ids);
        await getDatas();
    }

    function edit(key) {
        setEditingKey(key);
    }

    function cancel() {
        setEditingKey('');
    };

    function isEditing(record) {
        return record.id === editingKey;
    }

    let columns = [
        {
            title: 'Levy',
            dataIndex: 'levy',
            editable: true,
            inputType: "number"
        },
        {
            title: 'Date',
            dataIndex: 'date',
            editable: true,
            inputType: "month"
        },
        {
            title: 'Action',
            width: "120px",
            dataIndex: 'action',
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <EditableContext.Consumer>
                            {
                                form => (
                                    <div>
                                        <Icon disabled={editingKey !== ''} onClick={() => handleUpdateLevy(form, record.id)}
                                            style={{ fontSize: '20px', color: '#08c' }} type="check" />
                                        <Divider type="vertical" />
                                        <Icon disabled={editingKey !== ''} onClick={() => cancel(record.id)}
                                            style={{ fontSize: '20px', color: '#08c' }} type="close" />
                                    </div>

                                )
                            }
                        </EditableContext.Consumer>

                    </span>
                ) : (

                    <span>
                        <Icon disabled={editingKey !== ''} onClick={() => edit(record.id)}
                            style={{ fontSize: '20px', color: '#08c' }} type="edit" />

                        <Divider type="vertical" />
                        <Icon onClick={() =>
                            handleDeleteItems([record.id])
                        } style={{ fontSize: '20px', color: '#08c' }} type="delete" />
                    </span>

                );
            },
        }
    ];


    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const aColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    function onChangeLevy(value) {
        setLevy(value);
    }

    function onChangeMonth(date, dateString) {
        setDate(dateString);
    }

    return (

        <div>
            <div className="mb-2">
                <InputNumber
                    className="mr-2"
                    defaultValue={600}
                    min={0}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={onChangeLevy}
                />

                <MonthPicker onChange={onChangeMonth} className="mr-2" placeholder="Select Month" />

                <Button onClick={() => handleAddLevy()} type="primary" >
                    Add Levy
                </Button>
            </div>

            <EditableContext.Provider value={props.form}>
                <Table
                    components={components}
                    bordered
                    rowClassName="editable-row"
                    dataSource={datas}
                    columns={aColumns}
                    pagination={false}
                    rowKey="id"
                />
            </EditableContext.Provider>

            <Pagination className="mt-2" onChange={() => onChange()} onShowSizeChange={() => onChange()} total={pagination.total} showSizeChanger />

        </div >

    );
}

const LevyAntForm = Form.create()(LevyForm);

export default LevyAntForm;