import React, { useEffect, useState } from 'react';
import moment from 'moment';


export default function DateCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;


    return (
        <div  >
            {cellValue && moment(cellValue).format('DD/MM/YYYY HH:mm')}
        </div>
    );
};