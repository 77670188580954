import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';

import Split from 'react-split';
import {
  Tooltip,
  List,
  Icon as AntIcon,
  message,
  Button,
  Modal,
  Row,
  Col,
  Input as AntInput,
  Result,
  Typography,
  Avatar,
  Divider,
  Badge,
  notification,
  Popover,
} from 'antd';

import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import { v4 } from 'uuid';

import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import {
  Loader,
  Input,
  IconButton,
  Checkbox,
  InputPicker,
  Icon,
  CheckPicker,
  ButtonGroup,
  TagPicker,
  Button as ButtonRs,
} from 'rsuite';
import { DraggableAreasGroup } from 'react-draggable-tags';

import { roles } from '../../module_BimApp/columns';
import {
  getPublicUrl,
  socket,
  checkWohhupUser,
} from '../../../components/module_BimApp/function/AdminFunction';

import {
  getElementdbIdsFromModel,
  getForgeToken,
  getForgeTokenInternal,
} from '../../../components/module_BimApp/function/ForgeFunction';
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction';
import DataEntryTable from './DataEntryTableModelQuantity';
import groupArray from 'group-array';
import TreeModel from './DataEntryTreeModel';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import CastingReport from '../Report/CastingReport';
import CastingReportHistory from '../Report/CastingReportHistory';
import {
  handleBeforeCopyAndCut,
  unflatten,
  getLastNode,
  sortIndex,
} from '../../../components/module_BimApp/function/DataEntryFunction';
import stc from 'string-to-color';
import multisort from 'multisort';

import moment from 'moment';
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { TextArea, Search } = AntInput;
const { Text } = Typography;
const Autodesk = window.Autodesk;
const THREE = window.THREE;
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
};

Handsontable.renderers.registerRenderer(
  'header.dataentry',
  function (instance, TD, row, col, prop, value, cellProperties) {
    // TD.classList.add('truncated')
    TD.style.whiteSpace = 'nowrap';
    TD.style.overflow = 'hidden';
    TD.style.textOverflow = 'ellipsis';
    TD.innerHTML = value;
  }
);
Handsontable.renderers.registerRenderer(
  'cell.readonly.dataentry',
  function (instance, TD, row, col, prop, value, cellProperties) {
    TD.style.backgroundColor = '#fff8c5';
    TD.innerHTML = value;
  }
);
Handsontable.renderers.registerRenderer(
  'cell.forge.dataentry',
  function (instance, TD, row, col, prop, value, cellProperties) {
    TD.style.backgroundColor = '#ddefff';
    TD.innerHTML = value;
  }
);

function myBtns(instance, TD, row, col, prop, value, cellProperties) {
  if (value !== '' && value !== null && value !== undefined) {
    let rowPhysical = instance.toPhysicalRow(row);
    let rowData = instance.getSourceDataAtRow(rowPhysical);
    TD.innerHTML = `<button class="truncated" onclick="(function(){    
      window.open(${value})
      return false;
  })();return false;" type='button'> Open : ${rowData.castingReportNumber}</button>`;
  } else {
    TD.innerHTML = value;
  }
}

// TD.innerHTML = `<button class="truncated" id='${value}' onclick="(function(){
//   window.open(${value})
//   return false;
// })();return false;" type='button'>Open PDF</button>`;

let historySheet = [];
const indexStatus = {
  'Pile, Dwall': 8,
  'Slab 5D': 17,
};
let ongoingColor = convertHexColorToVector4('#80ff80');
let completeColor = convertHexColorToVector4('#93C47D');
let fwColor = convertHexColorToVector4('#02FBFB');
let leanConcrete = convertHexColorToVector4('#ffff80');
const statusColor = {
  CASTED: completeColor,
  CAST: completeColor,
  REBAR: ongoingColor,
  FORMWORK: fwColor,
  'LEAN CONCRETE': leanConcrete,
  'On-going': ongoingColor,
};
class DataEntryPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      leftOpen: true,
      headerSidebar: 'Browser',

      headerMain: '',
      selectedRowIndexs: [],
      listSheet: null,
      loading: false,
      dataUser: {},
      dialogSettingColumn: false,
      sheetId: null,
      sheetName: '',
      currentSheet: null,
      selectSheet: null,
      dialogTemplateSheet: false,

      error: false,
      errorCode: '500',
      viewerCurrent: null,
      isViewer: false,
      dataTable: [],
      columnTableForge: [],
      displayTreeView: false,
      displayNewSheet: false,
      mainParameter: '',
      mainName: '',
      mainIndex: 0,
      allParameter: [],
      itemIds: [],

      columns: [],
      hideColumns: [],
      dataSchema: {},
      dataHotTable: [],
      listUserUseSheet: [],
      cellDataCurrent: null,
      isFilter: false,
      showCastingReport: 0,
      showCastingReportHistory: 0,
      exportReportData: {},
      currentCastingReportHistory: [],
      isShowLegend: false,
      isCollapse: false,
      currentStatus: [],
      isCorrespond: false,
      isIsolate: false,

      groupValue: null,
    };
    this.split1 = React.createRef();
    this.split2 = React.createRef();
    this.treeModel = React.createRef();
    this.hotTable = React.createRef();
    this.result = [];
    this.countFlatten = 0;
    this.tempDataForTable = [];
  }
  componentWillMount = () => {
    socket.on(
      'realtime-dataentry-changedata-for5d',
      this.handleRealtimeDataEntryChangeData
    );
    socket.on(
      'realtime-dataentry-entersheet-for5d',
      this.handleRealtimeDataEntryEnterSheet
    );
  };
  componentWillUnmount = () => {
    socket.removeListener(
      'realtime-dataentry-changedata-for5d',
      this.handleRealtimeDataEntryChangeData
    );
    socket.removeListener(
      'realtime-dataentry-entersheet-for5d',
      this.handleRealtimeDataEntryEnterSheet
    );
  };
  handleRealtimeDataEntryChangeData = (data) => {
    try {
      if (
        this.state.selectSheet.id === data.item.sheetId &&
        data.email !== this.props.userInfo.email &&
        this.props.location.state.projectId === data.item.projectId
      ) {
        message.info({
          content: `User: ${data.email} is editing table`,
          key: 'dataEntryFor5D',
          duration: 2,
        });
      }
    } catch {
      console.log('realtime-dataentry-changedata-for5d');
    }
  };

  handleRealtimeDataEntryEnterSheet = (data) => {
    try {
      if (
        this.state.selectSheet.id === data.item.sheetId &&
        data.email !== this.props.userInfo.email &&
        this.props.location.state.projectId === data.item.projectId
      ) {
        let temp = [];
        let nameExtend = [];
        this.state.listUserUseSheet.push(data.email);
        _.forEach(this.state.listUserUseSheet, (v1, k) => {
          let index = _.findIndex(temp, (o) => {
            return o.name === data.email;
          });
          if (index < 0) {
            let split = v1.split('_');
            if (k <= 5) {
              if (split.length > 1) {
                temp.push({
                  shortCut:
                    split[0].charAt(0).toUpperCase() +
                    split[1].charAt(0).toUpperCase(),
                  name: v1,
                });
              } else {
                temp.push({ shortCut: v1.charAt(0).toUpperCase(), name: v1 });
              }
            } else {
              nameExtend.push(v1);
            }
          }
        });
        if (nameExtend.length !== 0) {
          temp.push({ shortCut: '...', name: nameExtend.join(', ') });
        }
        this.setState({ listUserUseSheet: temp });
      }
    } catch {
      console.log('realtime-dataentry-entersheet-for5d');
    }
  };
  componentDidMount = () => {
    console.log('this.props', this.props);
    this.setState({ loading: true });
    axios
      .post('/api/users/get-me-information', {
        token: this.props.userInfo.token,
      })
      .then((res) => {
        if (res.data.user.email !== this.props.userInfo.email) {
          this.setState({ loading: false, error: true, errorCode: '500' });
          return;
        }
        let index = _.findIndex(res.data.userInfo.contractors, (v) => {
          return v.projectId === this.props.location.state.projectId;
        });
        if (res.data.user.email !== 'admin@wohhup.com') {
          // if (index < 0 || !res.data.userInfo.contractors[index].isAccessDataEntry) {
          //   this.setState({ loading: false, error: true, errorCode: '400' })
          //   return
          // }
        }
        let role = undefined;
        let company = undefined;
        if (index >= 0) {
          role = res.data.userInfo.contractors[index].role;
          company = res.data.userInfo.contractors[index].company;
        }

        axios
          .get(`/api/dataentry/get-project-tables`, {
            params: {
              projectId: this.props.location.state.projectId,
              token: this.props.userInfo.token,
            },
          })
          .then((res2) => {
            let temp = [];
            _.forEach(res2.data, (v) => {
              if (v.type === 'for5d') {
                v.active = false;
                // v.name="Archi"
                temp.push(v);
              }
            });
            this.setState({ listSheet: temp }, () => {
              axios
                .post(`/api/projects/get-information-project`, {
                  projectId: this.props.location.state.projectId,
                  token: this.props.userInfo.token,
                })
                .then((res1) => {
                  this.setState({
                    dataUser: {
                      email: res.data.user.email,
                      role:
                        res.data.user.role === 'admin'
                          ? 'Document Controller'
                          : role
                          ? role
                          : 'viewer',
                      userName: res.data.userInfo.userName,
                      company:
                        res.data.user.role === 'admin'
                          ? 'Woh Hup Private Ltd'
                          : checkWohhupUser(res.data.user.email)
                          ? 'Woh Hup Private Ltd'
                          : company,
                    },
                    loading: false,
                  });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  toggleSidebar = (event) => {
    this.setState({ leftOpen: !this.state.leftOpen });
    setTimeout(() => {
      this.hotTable.current.hotInstance.refreshDimensions();
      if (this.state.viewerCurrent) this.state.viewerCurrent.resize();
    }, 1000);
  };

  handleFunctionSidebar = (value) => {
    this.setState({ headerSidebar: value });
  };
  handleSheetOpen = (item) => {
    this.setState(
      {
        loading: true,
        selectSheet: item,
        isViewer: false,
        listUserUseSheet: [],
        groupColumns: [],
        groupValue: null,
      },
      async () => {
        try {
          message.destroy();
          this.state.viewerCurrent.tearDown();
          this.state.viewerCurrent.finish();
          $('#forgeViewer').empty();
        } catch {}
        if (this.split1.current) this.split1.current.split.setSizes([100, 0]);

        axios
          .get(`/api/dataentry/get-sheet5d-data`, {
            params: {
              projectId: this.props.location.state.projectId,
              sheetId: item.id,
              sheetName: item.name,
              token: this.props.userInfo.token,
            },
          })
          .then((res) => {
            console.log('res', res);
            console.log('item', item);
            historySheet = [];
            let hideColumns = [];
            let columns = [];
            let groupColumns = [];
            let dataSchema = {
              id: v4(),
              externalIds: '',
              itemIds: '',
              globalOffset: '',
            };
            let mainParameter = '';
            let mainName = '';
            let mainIndex = '';
            let currentStatus = [];
            let columnMapping = {};
            _.forEach(item.columns, (v, k) => {
              columnMapping[v.name] = v.type;
              let readOnly = true;
              if (v.roleCanEdit.includes(this.state.dataUser.role)) {
                readOnly = false;
              }
              if (
                !v.roleCanView.includes(this.state.dataUser.role) ||
                [
                  `Subcon / COL`,
                  'Subcon / Toe',
                  'Subcon / Blind bore Length (m)',
                  'Subcon / Penetration Length (m)',
                  'Subcon / Socket Length (m)',
                  `Subcon / Data Cast`,
                  'Subcon / Status',
                ].includes(v.name)
              ) {
                hideColumns.push(k);
              }
              if (v.name === 'Casting report') {
                columns.push({
                  title: v.name,
                  data: v.name,
                  type: v.type,
                  readOnly,
                  skipColumnOnPaste: false,
                  width: v.width,
                });
                groupColumns.push({ label: v.name, value: v.name });
              } else if (v.type === 'dropdown') {
                if (v.name === 'Status') {
                  currentStatus = v.valueArray;
                }
                columns.push({
                  title: v.name,
                  data: v.name,
                  type: 'dropdown',
                  source: v.valueArray,
                  className: 'truncated',
                  readOnly,
                  skipColumnOnPaste: false,
                  width: v.width,
                });
                groupColumns.push({ label: v.name, value: v.name });
              } else {
                columns.push({
                  title: v.name,
                  data: v.name,
                  type: v.type,
                  className: 'truncated',
                  readOnly,
                  skipColumnOnPaste: false,
                  width: v.width,
                });
                groupColumns.push({ label: v.name, value: v.name });
              }
              if (v.isMatching) {
                mainParameter = v.parameter;
                mainName = v.name;
                mainIndex = k;
              }
              dataSchema[v.name] = null;
            });
            hideColumns.push(
              columns.length,
              columns.length + 1,
              columns.length + 2
            );
            columns.push(
              {
                title: 'externalIds',
                data: 'externalIds',
                type: 'text',
                className: 'truncated',
                skipColumnOnPaste: false,
                width: 80,
              },
              {
                title: 'itemIds',
                data: 'itemIds',
                type: 'text',
                className: 'truncated',
                skipColumnOnPaste: false,
                width: 80,
              },
              {
                title: 'globalOffset',
                data: 'globalOffset',
                type: 'text',
                className: 'truncated',
                skipColumnOnPaste: false,
                width: 80,
              }
            );
            let temp = this.state.listSheet.splice(0);
            _.forEach(temp, (v) => {
              v.active = false;
            });
            item.active = true;
            this.setState(
              {
                headerMain: item.name,
                listSheet: temp,
                columns,
                sheetName: item.name,
                sheetId: item.id,
                loading: false,
                groupColumns,
                hideColumns,
                mainParameter,
                mainName,
                isFilter: false,
                mainIndex,
                leftOpen: false,
                currentStatus,
              },
              () => {
                _.forEach(res.data, (item) => {
                  _.forEach(item, (v, k) => {
                    if (item[k] === null) {
                      if (columnMapping[k] === 'numeric') item[k] = 0;
                      else item[k] = '';
                    }
                  });
                });
                this.handleAdjustData(res.data);
                console.log('');
                this.tempDataForTable = this.result;
                this.setState({
                  dataHotTable: this.result,
                  columnMapping: columnMapping,
                });
                this.hotTable.current.hotInstance.resumeRender();
                setTimeout(() => {
                  this.hotTable.current.hotInstance.refreshDimensions();
                  if (this.state.viewerCurrent)
                    this.state.viewerCurrent.resize();
                }, 1000);
              }
            );
          })
          .catch(() => {
            message.warning(`Can't get sheet data`);
            this.setState({ loading: false });
          });
      }
    );
  };
  handleAdjustData = (data) => {
    // if (this.state.sheetName === 'Slab 5D') {
    //   this.result = data
    //   return
    // }
    let newRes = {};
    this.result = [];
    this.countFlatten = 0;
    if (this.state.sheetName === 'Pile, Dwall') {
      // _.forEach(data, v => {
      //   if (!newRes[v.Zone])
      //     newRes[v.Zone] = []
      //   newRes[v.Zone].push(v)
      // })
      let newRes = data;
      if (this.state.groupValue)
        newRes = groupArray(data, this.state.groupValue);
      this.handleFlattenObject(newRes, this.state.groupValue);
    } else if (this.state.sheetName === 'Slab 5D') {
      // _.forEach(data, v => {
      //   if (!newRes[v['Pour name']])
      //     newRes[v['Pour name']] = []
      //   newRes[v['Pour name']].push(v)
      // })
      let newRes = data;
      if (this.state.groupValue)
        newRes = groupArray(data, this.state.groupValue);
      this.handleFlattenObject(newRes, this.state.groupValue);
    } else if (this.state.sheetName === 'Archi') {
      let newRes = data;
      if (this.state.groupValue)
        newRes = groupArray(data, this.state.groupValue);
      this.handleFlattenObject(newRes, this.state.groupValue);
    }
  };

  handleFlattenObject = (data, array, parent = null) => {
    if (_.isArray(data)) {
      let temp = this.result.concat(data);
      this.result = temp;
      this.countFlatten = 0;
    } else {
      _.forEach(data, (v, k) => {
        if (typeof v === 'object') {
          //sum quantity here
          if (this.state.selectSheet?.name === 'Archi') {
            console.log('v', v);
            this.result.push({
              id: v4(),
              [array]: k,
              isAutoGroup: true,
              readOnly: true,
              Length: _.round(
                _.sum(v.map((x) => x.Length).filter((x) => _.isNumber(x))),
                2
              ),
              Volume: _.round(
                _.sum(v.map((x) => x.Volume).filter((x) => _.isNumber(x))),
                2
              ),
              Area: _.round(
                _.sum(v.map((x) => x.Area).filter((x) => _.isNumber(x))),
                2
              ),
              Count: _.sum(v.map((x) => x.Count).filter((x) => _.isNumber(x))),
            }); // [parent?parent:array[this.countFlatten]]: k
          } else {
            this.result.push({
              id: v4(),
              [array]: k,
              isAutoGroup: true,
              readOnly: true,
            }); // [parent?parent:array[this.countFlatten]]: k
          }

          this.countFlatten++;
          this.handleFlattenObject(v, array);
        }
      });
    }
  };
  handleSettingColumn = (e) => {
    if (
      this.props.isAdmin ||
      this.state.dataUser.role === 'Document Controller'
    )
      this.setState({
        dialogSettingColumn: !this.state.dialogSettingColumn,
        currentSheet: e.props.item,
      });
  };
  handleCloseSettingColumn = () => {
    this.setState({ dialogSettingColumn: false });
  };

  //#region
  launchViewer = () => {
    getForgeToken().then((token) => {
      var options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
          extensions: [
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            // "Autodesk.AEC.Hypermodeling",
          ],
        };
        let viewer = new Autodesk.Viewing.GuiViewer3D(
          document.getElementById('forgeViewer'),
          config3d
        );
        viewer.start();
        this.setState({ viewerCurrent: viewer });
      });
    });
  };

  handleForgeViewer = () => {
    this.setState(
      {
        isViewer: !this.state.isViewer,
        dataTable: [],
        dataHotTable: this.tempDataForTable,
      },
      () => {
        try {
          message.destroy();
          this.state.viewerCurrent.tearDown();
          this.state.viewerCurrent.finish();
          $('#forgeViewer').empty();
          this.setState({ viewerCurrent: null, isCollapse: true });
          setTimeout(() => {
            this.hotTable.current.hotInstance.refreshDimensions();
          }, 1000);
        } catch {}
        if (!this.state.isViewer) {
          this.split1.current.split.setSizes([100, 0]);
        } else {
          this.split1.current.split.setSizes([60, 40]);
          this.launchViewer();
          this.setState({ displayTreeView: true, isCollapse: true });
          setTimeout(() => {
            this.hotTable.current.hotInstance.refreshDimensions();
          }, 1000);
        }
      }
    );
  };
  dragSplit = () => {
    try {
      this.state.viewerCurrent.resize();
      this.hotTable.current.hotInstance.refreshDimensions();
    } catch {}
  };

  //#endregion

  handleNewSheet = () => {
    if (this.props.isAdmin) this.setState({ displayNewSheet: true });
  };
  handleCloseNewSheet = (data) => {
    if (data) {
      this.setState({ displayNewSheet: false, listSheet: data });
    } else {
      this.setState({ displayNewSheet: false });
    }
  };

  handleOpenTreeModel = () => {
    this.setState({ displayTreeView: true });
  };
  handleCloseTreeView = () => {
    this.setState({ displayTreeView: false });
  };

  handleModelData = () => {
    console.log('handleModelData');
    return new Promise((resolve, reject) => {
      let parameter = [];
      let listCheckTotal = {};
      let columnTableForge = [
        {
          key: 'checkBox',
          dataKey: 'checkBox',
          title: 'checkBox',
          resizable: false,
          width: 75,
          checkBox: false,
        },
      ];
      _.forEach(this.state.selectSheet.columns, (v) => {
        if (v.parameter !== '' && v.parameter) {
          if (v.isMatching)
            columnTableForge.splice(1, 0, {
              key: v.parameter,
              dataKey: v.parameter,
              title: v.parameter,
              resizable: true,
              width: 150,
              sortable: true,
            });
          else
            columnTableForge.push({
              key: v.parameter,
              dataKey: v.parameter,
              title: v.parameter,
              resizable: true,
              width: 150,
              sortable: true,
            });
          parameter.push(v.parameter);
          listCheckTotal[v.parameter] = v.type === 'numeric';
        }
      });
      if (parameter.length !== 0) {
        let tempModels = [];
        let count = 0;
        let models = this.state.viewerCurrent.impl.modelQueue().getModels();
        _.forEach(models, (v) => {
          let dbIds = getElementdbIdsFromModel(v);
          count += dbIds.length;
          tempModels.push({ model: v, dbIds });
        });
        let temp = [];
        let allParameter = [];
        _.forEach(tempModels, (v) => {
          _.forEach(v.dbIds, (id) => {
            v.model.getProperties(id, (modelAProperty) => {
              let data = {
                id: id,
                itemId:
                  v.model.myData.loadOptions.itemId +
                  ' ' +
                  v.model.myData.loadOptions.version +
                  ' ' +
                  v.model.myData.loadOptions.guid,
                externalId: modelAProperty.externalId,
              };
              _.forEach(parameter, (p) => {
                data[p] = p === 'checkBox' ? false : '';
              });
              _.forEach(modelAProperty.properties, (o) => {
                if (parameter.includes(o.displayName)) {
                  data[o.displayName] = _.isNumber(o.displayValue)
                    ? +o.displayValue.toFixed(2)
                    : o.displayValue;
                }
                if (allParameter.includes(o.displayName)) {
                  allParameter.push(o.displayName);
                }
              });
              temp.push(data);
              count--;
              if (count === 0) {
                if (this.state.mainParameter !== null) {
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );
                  this.setState(
                    {
                      dataTable: tempData,
                      allDataTable: tempData,
                      columnTableForge,
                      allParameter,
                    },
                    () => {
                      resolve();
                    }
                  );
                }
              }
            });
          });
        });
      } else {
        reject();
        message.warning('Please, setup parameter');
      }
    });
  };
  handleForgeData = (data, mainParameter, listCheckTotal) => {
    if (this.state.selectSheet?.name === 'Archi') {
      let temp = [];
      data.forEach((x) => {
        if (!_.isEmpty(x['ROOM NUMBER'])) {
          if (!_.isEmpty(x['5D_CODE_BASE'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_BASE'],
            });
          }

          if (!_.isEmpty(x['5D_CODE_MIDLE'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_MIDLE'],
            });
          }

          if (!_.isEmpty(x['5D_CODE_FINAL'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_FINAL'],
            });
          }
        }
      });

      data = temp.map((x) => ({
        ...x,
        Item:
          x.CODE +
          x['ROOM NUMBER'] +
          x['WH_Zone'] +
          x['WH_Sub Zone'] +
          x['WH_Level'],
        Volume: _.isNumber(x.Volume) ? x.Volume : 0.0,
        Area: _.isNumber(x.Area) ? x.Area : 0.0,
        Length: _.isNumber(x.Length) ? x.Length : 0.0,
        Count: 1,
      }));

      // mainParameter = "combineCodeRoomNumber";
    }

    let t = {};
    _.forEach(data, (v) => {
      if (v[mainParameter] === '') return;
      if (!t[v[mainParameter]]) t[v[mainParameter]] = [];
      t[v[mainParameter]].push(v);
    });
    // let t = groupArray(data, mainParameter);
    console.log('t', t);
    let tempData = [];
    _.forEach(t, (v, k) => {
      let tempTotal = { id: v4(), itemIds: [] };
      _.forEach(v, (i1) => {
        _.forEach(i1, (i2, k2) => {
          if (['externalId', 'id'].includes(k2)) return;
          if (k2 === 'itemId') {
            if (!tempTotal.itemIds.includes(i2)) tempTotal.itemIds.push(i2);
          } else if (_.isNumber(i2)) {
            if (listCheckTotal[k2]) {
              if (!tempTotal[k2]) tempTotal[k2] = 0;
              tempTotal[k2] = tempTotal[k2] + i2;
              tempTotal[k2] = +tempTotal[k2].toFixed(2);
            } else {
              if (!tempTotal[k2]) tempTotal[k2] = i2;
              if (tempTotal[k2] !== 'var')
                tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
            }
          } else {
            if (!tempTotal[k2]) tempTotal[k2] = i2;
            if (tempTotal[k2] !== 'var')
              tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
          }
        });
      });
      tempTotal.children = v;
      tempData.push(tempTotal);
    });
    return tempData;
  };

  handleShowElement = () => {
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    let listCheckTotal = {};
    let parameter = [];
    _.forEach(this.state.selectSheet.columns, (v) => {
      if (v.parameter !== '' && v.parameter) {
        parameter.push(v.parameter);
        listCheckTotal[v.parameter] = v.type === 'numeric';
      }
    });
    if (parameter.length !== 0) {
      let tempModels = [];
      let count = 0;
      _.forEach(models, (v) => {
        let dbIds = v.selector.getSelection();
        count += dbIds.length;
        tempModels.push({ model: v, dbIds });
      });
      let temp = [];
      let allParameter = [];
      if (count === 0) {
        this.setState({ dataTable: this.state.allDataTable });
      }

      _.forEach(tempModels, (v) => {
        _.forEach(v.dbIds, (id) => {
          v.model.getProperties(id, (modelAProperty) => {
            let data = {
              id: id,
              itemId:
                v.model.myData.loadOptions.itemId +
                ' ' +
                v.model.myData.loadOptions.version +
                ' ' +
                v.model.myData.loadOptions.guid,
              externalId: modelAProperty.externalId,
            };
            _.forEach(parameter, (p) => {
              data[p] = p === 'checkBox' ? false : '';
            });
            _.forEach(modelAProperty.properties, (o) => {
              if (parameter.includes(o.displayName)) {
                data[o.displayName] = _.isNumber(o.displayValue)
                  ? +o.displayValue.toFixed(2)
                  : o.displayValue;
              }
              if (allParameter.includes(o.displayName)) {
                allParameter.push(o.displayName);
              }
            });
            temp.push(data);
            count--;
            if (count === 0) {
              if (temp.length !== 0) {
                if (this.state.mainParameter !== null) {
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );
                  this.setState({ dataTable: tempData });
                }
              }
            }
          });
        });
      });
    }
  };

  handleAssign = async () => {
    if (!window.confirm('Are you want to override data')) return;
    if (this.state.dataTable.length === 0) {
      message.warning('Please, reload data');
      return;
    }
    let tempMatching = {};
    _.forEach(this.state.selectSheet.columns, (v, k) => {
      if (v.parameter) {
        tempMatching[v.parameter] = v.name;
      }
    });

    if (this.state.mainParameter) {
      let data = this.hotTable.current.hotInstance.getSourceData();
      _.forEach(data, (v) => {
        if (v.__children) delete v.__children;
      });
      let tempData = [];
      let clone = data.slice(0);
      _.forEach(this.state.dataTable, (v) => {
        if (v.checkBox) {
          let matching = v[this.state.mainParameter];
          let externalIds = [];
          let itemIds = [];
          let globalOffset = this.state.viewerCurrent.impl.model
            ? JSON.stringify(
                this.state.viewerCurrent.impl.model.myData.globalOffset
              )
            : null;
          let index = _.findIndex(clone, (o) => {
            return o[this.state.mainName] == matching;
          });
          _.forEach(v.children, (i) => {
            externalIds.push(i.externalId);
            if (!itemIds.includes(i.itemId)) itemIds.push(i.itemId);
          });
          if (index >= 0) {
            let data = clone[index];
            _.forEach(v, (i, k) => {
              if (tempMatching[k]) data[tempMatching[k]] = i;
            });
            data.externalIds = JSON.stringify(externalIds);
            data.itemIds = JSON.stringify(itemIds);
            data.globalOffset = globalOffset;
            tempData.push(data);
            let copy = Object.assign({}, data);
            copy.row_status = 'update';
            historySheet.push(copy);
          } else {
            let data = {
              id: v4(),
              itemIds: JSON.stringify(itemIds),
              externalIds: JSON.stringify(externalIds),
              globalOffset,
              projectId: this.props.location.state.projectId,
              sheetId: this.state.selectSheet.id,
            };
            _.forEach(v, (i, k) => {
              if (tempMatching[k]) data[tempMatching[k]] = i;
            });
            clone.push(data);
            tempData.push(data);
            let copy = Object.assign({}, data);
            copy.row_status = 'add';
            historySheet.push(copy);
          }
        }
      });
      this.setState({ dataHotTable: clone, loading: false });
    }
  };

  handleDeleteSheet = (e) => {
    if (this.props.isAdmin) {
      let idx = e.props.idx;
      let item = e.props.item;
      if (!window.confirm('Are you want to delete it')) return;
      this.setState({ loading: true });
      axios
        .delete(`/api/dataentry/delete-sheet5d`, {
          data: {
            projectId: this.props.location.state.projectId,
            sheetId: item.id,
            sheetName: item.name,
            token: this.props.userInfo.token,
          },
        })
        .then((res) => {
          let temp = this.state.listSheet;
          temp.splice(idx, 1);
          this.setState({
            listSheet: temp,
            loading: false,
            dataHotTable: [],
            columns: [],
          });
        })
        .catch(() => {
          message.warning(`Can't delete sheet`);
          this.setState({ loading: false });
        });
    }
  };
  handleColorStatus = async (value) => {
    if (!this.state.viewerCurrent) {
      message.warning('Please, open model');
      return;
    }
    if (this.state.dataTable.length === 0) {
      // message.warning('Please, reload data')
      // return
      await this.handleModelData();
    }
    if (this.state.dataTable.length === 0) {
      message.warning('Please, check sheet setup');
      return;
    }
    // let mainParameter = null
    // let mainName = null
    // _.forEach(this.state.selectSheet.columns, v => {
    //   if (v.parameter) {
    //     if (v.isMatching) {
    //       mainParameter = v.parameter
    //       mainName = v.name
    //     }

    //   }
    // })
    let tempModel = {};
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      if (value === null) this.state.viewerCurrent.clearThemingColors(v);
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });
    let data = value === null ? this.tempDataForTable : value; //this.hotTable.current.hotInstance.getSourceData()
    let clone = data.slice(0);
    let ongoingColor = convertHexColorToVector4('#F6B26B');
    let completeColor = convertHexColorToVector4('#93C47D');
    let fwColor = convertHexColorToVector4('#02FBFB');
    _.forEach(clone, (v) => {
      if (v.Status !== '' && v.Status !== undefined && v.Status !== null) {
        let index = _.findIndex(this.state.allDataTable, (o) => {
          return o[this.state.mainParameter] === v[this.state.mainName];
        });
        if (index >= 0) {
          let t = {};
          _.forEach(this.state.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          // let t = groupArray(this.state.allDataTable[index].children, 'itemId')
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              let color = statusColor[v.Status];
              if (color)
                _.forEach(i, (el) => {
                  this.state.viewerCurrent.setThemingColor(
                    el.id,
                    color,
                    tempModel[k]
                  );
                });
            }
          });
        }
      }
    });
  };
  handleOpenModelInRow = async (row) => {
    // if (this.state.viewerCurrent === null) return
    let clone = row;
    let resList = [];
    let globalOffset = JSON.parse(clone.globalOffset);
    let itemIds = JSON.parse(clone.itemIds);
    itemIds.forEach((v) => {
      let split = v.split(' ');
      const response = axios.post('/api/items/get-item-version', {
        itemId: split[0],
        version: split[1],
        viewGuid: split[2],
      });
      resList.push(response);
    });
    let res = await Promise.all(resList);
    let temp = [];
    _.forEach(res, (v) => {
      let data = v.data;
      let index = _.findIndex(data.revisions, (o) => {
        return o.version === data.versionCurrent;
      });
      if (index >= 0) {
        data.objectId = data.revisions[index].objectId;
        data.globalOffset = globalOffset;
        temp.push(data);
      }
    });

    this.setState({ itemIds: temp });
  };
  highLightRowsRelatedToSelectedElements(externalIds) {
    let colCount = this.hotTable.current.hotInstance.countCols();

    for (let index = 0; index < this.state.dataHotTable.length; index++) {
      const dataRow = this.state.dataHotTable[index];

      let isInclude = false;
      externalIds.forEach((id) => {
        if (
          dataRow.externalIds &&
          dataRow.externalIds.length > 0 &&
          dataRow.externalIds.includes(id)
        ) {
          isInclude = true;
        }
      });

      if (isInclude) {
        for (var i = 0; i < colCount; i++) {
          let classes =
            this.hotTable.current.hotInstance.getCellMeta(index, i).className ||
            '';

          this.hotTable.current.hotInstance.setCellMeta(
            index,
            i,
            'className',
            classes + ' ' + 'area highlight'
          );
        }
      } else {
        for (var i = 0; i < colCount; i++) {
          let classes =
            this.hotTable.current.hotInstance.getCellMeta(index, i).className ||
            '';

          classes = classes.replace('area', '');
          classes.replace('highlight', '');
          this.hotTable.current.hotInstance.setCellMeta(
            index,
            i,
            'className',
            classes
          );
        }
      }
    }

    this.hotTable.current.hotInstance.render();
  }
  handleSelectObjectInRow = (rows, check) => {
    if (this.state.dataTable.length === 0) {
      message.warning('Please, reload data');
      return;
    }
    console.log('check', check);
    let tempIds = {};
    let tempModel = {};
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });
    if (this.state.selectSheet?.name === 'Archi') {
      _.forEach(rows, (v) => {
        console.log('v', v);
        let index = _.findIndex(this.state.allDataTable, (o) => {
          console.log('o', o);
          if (!_.isEmpty(o['5D_CODE_BASE'])) {
            let uniqCode =
              o['5D_CODE_BASE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_MIDLE'])) {
            let uniqCode =
              o['5D_CODE_MIDLE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_FINAL'])) {
            let uniqCode =
              o['5D_CODE_FINAL'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          return false;
        });

        if (index >= 0) {
          let t = {};
          _.forEach(this.state.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              if (!tempIds[k]) tempIds[k] = [];
              _.forEach(i, (el) => {
                tempIds[k].push(el.id);
              });
            }
          });
        }
      });
    } else {
      _.forEach(rows, (v) => {
        if (v.Status !== '' && v.Status !== undefined) {
          let index = _.findIndex(this.state.allDataTable, (o) => {
            return o[this.state.mainParameter] === v[this.state.mainName];
          });
          if (index >= 0) {
            let t = {};
            _.forEach(this.state.allDataTable[index].children, (j) => {
              if (!t[j.itemId]) t[j.itemId] = [];
              t[j.itemId].push(j);
            });
            _.forEach(t, (i, k) => {
              if (tempModel[k]) {
                if (!tempIds[k]) tempIds[k] = [];
                _.forEach(i, (el) => {
                  tempIds[k].push(el.id);
                  // tempId.push({ model: models[index], ids: externalId })
                  // this.state.viewerCurrent.select(el.id, tempModel[k])
                });
              }
            });
          }
        }
      });
    }

    // if (models.length === 1)
    let selection = [];
    this.state.viewerCurrent.showAll();
    this.state.viewerCurrent.clearSelection();
    _.forEach(tempIds, (v, k) => {
      if (check) {
        if (this.state.isIsolate) {
          this.state.viewerCurrent.isolate(v, tempModel[k]);
        }
      }
      selection.push({ model: tempModel[k], ids: v });
    });
    if (!this.state.isIsolate) {
      this.state.viewerCurrent.setAggregateSelection(selection);
    }
  };
  callbackRemoveDataTable = () => {
    this.setState({ dataTable: [] });
  };
  callbackLoadDone = () => {
    this.handleColorStatus(null);
  };
  contextMenuHotTable = {
    items: {
      commentsAddEdit: {},
      // 'commentsRemove': {},
      // 'commentsReadOnly': {},
      // 'separator2': Handsontable.plugins.ContextMenu.SEPARATOR,
      // 'freeze_column': {
      //   disabled: () => {
      //     return this.state.columns.length === 0;
      //   },
      // },
      // 'unfreeze_column': {
      //   disabled: () => {
      //     return this.state.columns.length === 0;
      //   },
      // },
      //'separator3': Handsontable.plugins.ContextMenu.SEPARATOR,
      cell_history: {
        name: 'Cell History',
        disabled: () => {
          let selection = this.hotTable.current.hotInstance.getSelected();
          if (selection.length !== 0) {
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0][0]
            );
            let item =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
            if (!item || item.isAutoGroup) return true;
          } else return true;
          return false;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0].start.row
            );
            let colPhysical =
              this.hotTable.current.hotInstance.toPhysicalColumn(
                selection[0].start.col
              );
            let cell = this.hotTable.current.hotInstance.getCellMeta(
              rowPhysical,
              colPhysical
            );
            let item =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
            if (!item.isAutoGroup)
              this.setState({
                cellDataCurrent: { prop: cell.prop, cell: item },
                dialogHistory: true,
              });
          }
        },
      },
      separator3: Handsontable.plugins.ContextMenu.SEPARATOR,
      row_above: {
        name: 'Insert rows above',
        // disabled: () => {
        //   let plugin = this.hotTable.current.hotInstance.getPlugin('nestedRows').dataManager
        //   let sel = this.hotTable.current.hotInstance.getSelectedLast()
        //   let level = plugin.getRowLevel(sel[0])
        //   return level !== 0;
        // },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt('How many rows you want to add', '1');
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = this.tempDataForTable.slice(0); //this.state.dataHotTable.slice(0)
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: v4(),
                    projectId: this.props.location.state.projectId,
                    sheetId: this.state.selectSheet.id,
                  };
                  let copy = Object.assign({}, item);
                  copy.row_status = 'add';
                  historySheet.push(copy);
                  temp.splice(selection[selection.length - 1].end.row, 0, item);
                  start++;
                }
                this.setState({ dataHotTable: temp, loading: false }, () => {
                  this.tempDataForTable = temp;
                  this.hotTable.current.hotInstance.resumeRender();
                });
              }
            }
          }
        },
      },
      row_below: {
        name: 'Insert rows below',
        disabled: () => {
          return this.state.columns.length === 0;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt('How many rows you want to add', '1');
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = this.tempDataForTable.slice(0); //this.state.dataHotTable.slice(0)
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: v4(),
                    projectId: this.props.location.state.projectId,
                    sheetId: this.state.selectSheet.id,
                  };
                  let copy = Object.assign({}, item);
                  copy.row_status = 'add';
                  historySheet.push(copy);
                  temp.splice(
                    selection[selection.length - 1].end.row + start,
                    0,
                    item
                  );
                  start++;
                }
                this.setState({ dataHotTable: temp, loading: false }, () => {
                  this.tempDataForTable = temp;
                  this.hotTable.current.hotInstance.resumeRender();
                });
              }
            }
          }
        },
      },
      separator: Handsontable.plugins.ContextMenu.SEPARATOR,
      remove_row: {
        name: 'Remove rows',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelected();
          let check = false;
          _.forEach(sel, (v) => {
            let start = v[0];
            while (start <= v[2]) {
              let rowPhysical =
                this.hotTable.current.hotInstance.toPhysicalRow(start);
              let row =
                this.hotTable.current.hotInstance.getSourceDataAtRow(
                  rowPhysical
                );
              if (!row) {
                check = true;
                start++;
                break;
              } else {
                if (row.isAutoGroup) {
                  check = true;
                  start++;
                  break;
                } else start++;
              }
            }
            if (check) return false;
          });
          return check;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            if (!window.confirm('Are you want to delete it')) return;
            let tempData = this.tempDataForTable.slice(0); //this.hotTable.current.hotInstance.getSourceData()
            let temp = _.filter(tempData, (v) => {
              return !v.isAutoGroup;
            });
            _.forEach(selection, (v) => {
              let start = v.start.row;
              while (start <= v.end.row) {
                let rowPhysical =
                  this.hotTable.current.hotInstance.toPhysicalRow(start);
                let item =
                  this.hotTable.current.hotInstance.getSourceDataAtRow(
                    rowPhysical
                  );
                let index = _.findIndex(historySheet, (v) => {
                  return v.id == item.id;
                });
                if (index >= 0) {
                  if (historySheet[index].row_status === 'add')
                    historySheet.splice(index, 1);
                  else historySheet[index].row_status = 'delete';
                } else {
                  let copy = Object.assign({}, item);
                  copy.row_status = 'delete';
                  historySheet.push(copy);
                }
                let index1 = _.findIndex(temp, (v) => {
                  return v.id === item.id;
                });
                if (index1 >= 0) temp.splice(index1, 1);
                _.forEach(temp, (v) => {
                  if (v.__children) delete v.__children;
                });
                start++;
              }
            });
            this.handleAdjustData(temp);
            this.setState({ dataHotTable: this.result, loading: false }, () => {
              this.tempDataForTable = this.result;
              this.hotTable.current.hotInstance.resumeRender();
            });
          }
        },
      },
      separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
      open_model: {
        name: 'Open Model',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelectedLast();
          let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
            sel[0]
          );
          let row =
            this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
          if (!row || !this.state.viewerCurrent) return true;
          else return !row.itemIds;
        },
        callback: (key, selection, clickEvent) => {
          // Callback for specific option
          if (selection.length !== 0) {
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0].start.row
            );
            this.handleOpenModelInRow(
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical)
            );
          }
        },
      },
      select_object: {
        name: 'Select Object',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelectedLast();
          let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
            sel[0]
          );
          let row =
            this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
          if (this.state.viewerCurrent) {
            let models = this.state.viewerCurrent.impl.modelQueue().getModels();
            if (models.length === 0) return true;
          }
          if (!row || !this.state.viewerCurrent || row.isAutoGroup) return true;
          else return !row.itemIds;
        },
        callback: (key, selection, clickEvent) => {
          // Callback for specific option
          if (selection.length !== 0) {
            let rows = [];
            _.forEach(selection, (v) => {
              for (let i = v.start.row; i <= v.end.row; i++) {
                let rowPhysical =
                  this.hotTable.current.hotInstance.toPhysicalRow(i);
                let row =
                  this.hotTable.current.hotInstance.getSourceDataAtRow(
                    rowPhysical
                  );
                if (row.itemIds) rows.push(row);
              }
            });
            this.handleSelectObjectInRow(rows);
          }
        },
      },
      casting_report: {
        name: 'Casting Report',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelectedLast();
          let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
            sel[0]
          );
          let row =
            this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
          if (!row) return true;
          else return !row.itemIds;
        },
        callback: async (key, selection, clickEvent) => {
          // Callback for specific option
          console.log('selection', selection);

          if (selection.length !== 0) {
            //get selected Rows
            let selectedPourName = [];
            selection.forEach((s) => {
              for (let i = s.start.row; i <= s.end.row; i++) {
                let rowPhysical =
                  this.hotTable.current.hotInstance.toPhysicalRow(i);
                let r =
                  this.hotTable.current.hotInstance.getSourceDataAtRow(
                    rowPhysical
                  );

                selectedPourName.push(r['Pour name']);
              }
            });
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0].start.row
            );
            let row =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
            let table = this.tempDataForTable;
            let itemIds = JSON.parse(row.itemIds);
            let resList = [];
            itemIds.forEach((v) => {
              let split = v.split(' ');
              const response = axios.post('/api/items/get-item-version', {
                itemId: split[0],
                version: split[1],
                viewGuid: split[2],
              });
              resList.push(response);
            });

            let res = await Promise.all(resList);

            let temp = [];
            _.forEach(res, (v) => {
              let data = v.data;
              let index = _.findIndex(data.revisions, (o) => {
                return o.version === data.versionCurrent;
              });
              if (index >= 0) {
                data.objectId = data.revisions[index].objectId;
                temp.push(data);
              }
            });

            //get first model
            let first = temp[0];
            // let currentRevision = first.revisions.find(x => x.version === first.versionCurrent);
            let currentRevision = first.revisions[first.revisions.length - 1];

            let inputRvtCurrent = `https://developer.api.autodesk.com/oss/v2/buckets/${currentRevision.bucketKey.toLowerCase()}/objects/${
              currentRevision.objectKey
            }`;

            this.setState({
              showCastingReport: this.state.showCastingReport + 1,
            });

            let dt = {
              selectedPourName,
              current: {
                view2d: row['2D View Name'],
                view3d: row['3D View Name'],
                pourName: row['Pour name'],
                castingDate: row['Actual date finish'],
                formworkAreaDesign: row['Fwk Area Design (m2)'],
                formworkAreaActual: row['Fwk Area Actual (m2)'],
                leanConcreteAreaDesign: row["Lean Con'c Area (m2)"],
                leanConcreteAreaActual: row["Lean Con'c Area Actual (m2)"],
                rebarWeightActual: row['Rebar Weight Actual (kg)'],
                rebarWeightDesign: row['Rebar Weight Design (kg)'],
                concreteVolumeDesign: row["Con'c Volume Design (m3)"],
                concreteVolumeActual: row["Con'c Volume Actual (m3)"],
                status: row['Status'],
                id: row['id'],
                planDateStart: row['Plan date start'],
                planDateFinish: row['Plan date finish'],
                actualDateFinish: row['Actual date finish'],
                actualDateStart: row['Actual date start'],
                castingReportNumber: row.castingReportNumber,
              },
              all: table.map((x) => ({
                view2d: x['2D View Name'],
                view3d: x['3D View Name'],
                pourName: x['Pour name'],
                formworkAreaDesign: x['Fwk Area Design (m2)'],
                formworkAreaActual: x['Fwk Area Actual (m2)'],
                leanConcreteAreaDesign: x["Lean Con'c Area (m2)"],
                leanConcreteAreaActual: x["Lean Con'c Area Actual (m2)"],
                rebarWeightActual: x['Rebar Weight Actual (kg)'],
                rebarWeightDesign: x['Rebar Weight Design (kg)'],
                concreteVolumnDesign: x["Con'c Volume Design (m3)"],
                concreteVolumnActual: x["Con'c Volume Actual (m3)"],
                status: x['Status'],
                id: x['id'],
                planDateStart: x['Plan date start'],
                planDateFinish: x['Plan date finish'],
                actualDateFinish: x['Actual date finish'],
                actualDateStart: x['Actual date start'],
                castingReportNumber: x.castingReportNumber,
              })),
              inputRvtCurrent,
            };

            this.setState({ exportReportData: dt });
          }
        },
      },
      casting_report_history: {
        name: 'Casting Report History',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelectedLast();
          let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
            sel[0]
          );
          let row =
            this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
          if (!row || !_.isArray(row.castingReportHistory)) return true;
          else return !row.itemIds;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0].start.row
            );
            let row =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);

            this.setState({
              showCastingReportHistory: this.state.showCastingReportHistory + 1,
            });
            if (_.isArray(row.castingReportHistory)) {
              this.setState({
                currentCastingReportHistory: row.castingReportHistory.reverse(),
              });
            } else {
              this.setState({ currentCastingReportHistory: [] });
            }
          }
        },
      },
      casting_report_remove: {
        name: 'Remove Casting Report',
        disabled: () => {
          let sel = this.hotTable.current.hotInstance.getSelectedLast();
          let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
            sel[0]
          );
          let row =
            this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
          if (!row || _.isNil(row['Casting report'])) return true;
          else return !row.itemIds;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
              selection[0].start.row
            );
            let row =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
            let tempData = _.clone(this.state.dataHotTable);
            let rowInTemple = tempData.find((x) => x.id == row.id);
            if (rowInTemple) {
              rowInTemple.castingReportNumber = null;
              rowInTemple['Casting report'] = null;
              this.setState({ dataHotTable: tempData });
            }
            axios
              .put('/api/casting-report/remove-casting-report/' + row.id)
              .then((r) => {
                notification.success({
                  message: 'Info!',
                  description: 'Remove Casting Report Successfully !',
                  duration: 5,
                });
              });
          }
        },
      },
    },
  };
  handleCompareVersion = () => {
    this.setState({ loading: true });
    let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3'];
    let parameter = [];
    let listCheckTotal = {};
    let tempMatching = {};
    let tempMatchingIndex = {};
    _.forEach(this.state.selectSheet.columns, (v, k) => {
      if (v.parameter !== '' && v.parameter) {
        parameter.push(v.parameter);
        listCheckTotal[v.parameter] = v.type === 'numeric';
        tempMatching[v.parameter] = v.name;
        tempMatchingIndex[v.parameter] = k;
      }
    });
    let data = this.hotTable.current.hotInstance.getSourceData();
    console.log(this.hotTable.current.hotInstance.getData());
    let clone = data.slice(0);
    let temp = [];
    _.forEach(clone, (v) => {
      if (v.itemIds && v.itemIds !== '') {
        let parse = JSON.parse(v.itemIds);
        _.forEach(parse, (o) => {
          let split = o.split(' ');
          let index = _.findIndex(temp, (i) => {
            return i.itemId === split[0];
          });
          if (index < 0) {
            temp.push({ itemId: split[0], version: split[1], guid: split[2] });
          }
        });
      }
    });
    axios
      .post(`/api/dataentry/get-items`, {
        projectId: this.props.location.state.projectId,
        data: temp,
        token: this.props.userInfo.token,
      })
      .then((res) => {
        getForgeTokenInternal().then(async (token) => {
          let getList = [];
          let count = res.data.length;
          _.map(res.data, async (v) => {
            try {
              let url = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata`;
              const config = {
                headers: {
                  Authorization: 'Bearer ' + token.access_token,
                },
              };
              let response = await axios.get(url, config);
              if (response.data.data.metadata.length !== 0) {
                let index1 = _.findIndex(response.data.data.metadata, (o) => {
                  return o.guid === v.guid;
                });
                let guid = '';
                if (index1 >= 0) {
                  guid = v.guid;
                } else {
                  guid = response.data.data.metadata[0].guid;
                }
                let url1 = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata/${guid}?forceget=true`;
                let response1 = await axios.get(url1, config);
                let result = getLastNode(response1.data.data.objects);

                let url2 = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata/${guid}/properties?forceget=true`;
                let response2 = await axios.get(url2, config);
                _.forEach(response2.data.data.collection, (v) => {
                  if (result.includes(v.objectid)) getList.push(v);
                });
                count--;
                if (count === 0) {
                  let temp = [];
                  const commentsPlugin =
                    this.hotTable.current.hotInstance.getPlugin('comments');
                  _.forEach(getList, (i) => {
                    let data = {};
                    _.forEach(i.properties, (e) => {
                      _.forEach(e, (o, k) => {
                        if (parameter.includes(k)) {
                          let split = o.split(' ');
                          let value = o;
                          if (split.length === 2) {
                            if (tempUnit.includes(split[1]))
                              value = parseFloat(split[0]);
                          }
                          let num = _.toNumber(value);
                          data[k] = listCheckTotal[k]
                            ? _.isNumber(num)
                              ? +num.toFixed(2)
                              : +value
                            : value;
                        }
                      });
                    });
                    if (Object.keys(data).length !== 0) temp.push(data);
                  });
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );
                  let listRow = [];
                  this.setState({ loading: false });
                  _.forEach(clone, (v, k) => {
                    if (v.__children) delete v.__children;
                    if (
                      v.itemIds !== '' &&
                      v.itemIds !== undefined &&
                      v.itemIds !== null
                    ) {
                      let index = _.findIndex(tempData, (o) => {
                        return (
                          o[this.state.mainParameter] ===
                          v[tempMatching[this.state.mainParameter]]
                        );
                      });
                      if (index >= 0) {
                        let newData = tempData[index];
                        _.forEach(parameter, (i) => {
                          if (newData[i] !== v[tempMatching[i]]) {
                            listRow.push({ row: k, col: tempMatchingIndex[i] });
                            const classes =
                              this.hotTable.current.hotInstance.getCellMeta(
                                k,
                                tempMatchingIndex[i]
                              ).className || '';
                            if (_.isNumber(newData[i])) {
                              if (newData[i] < v[tempMatching[i]]) {
                                this.hotTable.current.hotInstance.setCellMeta(
                                  k,
                                  tempMatchingIndex[i],
                                  'className',
                                  classes + ' ' + 'bimapp_change_down'
                                );
                              } else {
                                this.hotTable.current.hotInstance.setCellMeta(
                                  k,
                                  tempMatchingIndex[i],
                                  'className',
                                  classes + ' ' + 'bimapp_change_up'
                                );
                              }
                            } else {
                              this.hotTable.current.hotInstance.setCellMeta(
                                k,
                                tempMatchingIndex[i],
                                'className',
                                classes + ' ' + 'bimapp_change'
                              );
                            }
                            let td = this.hotTable.current.hotInstance.getCell(
                              k,
                              tempMatchingIndex[i]
                            );
                            td.title = newData[i];
                          }
                        });
                      }
                    }
                  });
                  this.setState({ isShowLegend: false });
                  this.hotTable.current.hotInstance.render();
                  message.success(`Compare sheet data`);
                }
              }
            } catch (err) {
              this.setState({ loading: false });
              console.log(err);
            }
          });
        });
      })
      .catch(() => {
        message.warning(`Can't save sheet data`);
        this.setState({ loading: false });
      });
  };
  handleExportExcel = () => {
    if (this.state.dataHotTable.length !== 0) {
      if (!window.confirm('Are you want to export table')) return;
      let exportPlugin =
        this.hotTable.current.hotInstance.getPlugin('exportFile');
      exportPlugin.downloadFile('csv', {
        exportHiddenRows: true, // default false
        exportHiddenColumns: false, // default false
        columnHeaders: true, // default false
        fileExtension: 'csv',
        filename: 'Data Entry 5D_[YYYY]-[MM]-[DD]',
        mimeType: 'text/csv',
      });
    }
  };
  handleClearFilter = () => {
    let plugin = this.hotTable.current.hotInstance.getPlugin('Filters');
    plugin.clearConditions();
    plugin.filter();
    this.hotTable.current.hotInstance.resumeRender();
  };
  handleCellHistory = () => {
    // let settings = this.hotTable.current.hotInstance.getSettings()
    // let plugin = this.hotTable.current.hotInstance.getPlugin('nestedRows').dataManager
    // let sel = this.hotTable.current.hotInstance.getSelectedLast()
    // this.setState({ cellDataCurrent:null },()=>{
    //   this.hotTable.current.hotInstance.updateSettings(settings);
    // })
  };
  handleSaveData = () => {
    this.setState({ loading: true });
    axios
      .get(`/api/dataentry/get-sheet5d-data`, {
        params: {
          projectId: this.props.location.state.projectId,
          sheetId: this.state.selectSheet.id,
          sheetName: this.state.selectSheet.name,
          token: this.props.userInfo.token,
        },
      })
      .then((res) => {
        let temp = { add: [], update: [], delete: [] };
        // let data = this.hotTable.current.hotInstance.getSourceData()
        // _.forEach(res.data, (v, k) => {
        //   v.index = k
        // })
        _.forEach(historySheet, (v, k) => {
          if (this.state.sheetName === 'Pile, Dwall') {
            if (
              v['Zone'] === '' ||
              v['Zone'] === undefined ||
              v['Pile marking'] === '' ||
              v['Pile marking'] === undefined ||
              v.isAutoGroup
            )
              return;
          } else if (this.state.sheetName === 'Slab 5D') {
            if (
              v['Pour name'] === '' ||
              v['Pour name'] === undefined ||
              v.isAutoGroup
            )
              return;
          }
          let index = _.findIndex(res.data, (o) => {
            return o.id === v.id;
          });
          delete v.__children;
          delete v.sheetId;
          delete v.castingReportHistory;
          if (!v.createdAt) {
            v.createdAt = Date.now();
            v.updatedAt = Date.now();
          }
          let copy = Object.assign({}, v);
          copy.user = this.props.userInfo.email;
          copy.updatedAt = Date.now();
          delete copy.row_status;
          delete copy.itemIds;
          delete copy.externalIds;
          delete copy.row_history;
          delete copy.globalOffset;
          delete copy.index;
          delete copy._id;
          delete copy.id;
          delete copy.parentId;
          delete copy.__v;
          if (!v.row_history) v.row_history = [];
          if (index >= 0) {
            if (v.row_status === 'add' || v.row_status === 'update') {
              delete v.row_status;
              let tempData = {};
              _.forEach(copy, (o, k) => {
                if (!res.data[index][k] || res.data[index][k] !== o) {
                  if (res.data[index][k] === undefined && o === '') return;
                  tempData[k] = o;
                }
              });
              if (v.row_history.length >= 1000) {
                v.row_history.splice(v.row_history.length - 1, 1);
              }
              v.row_history.push(tempData);
              temp.update.push(v);
            } else if (v.row_status === 'delete') {
              delete v.row_status;
              temp.delete.push(v);
            }
          } else {
            if (v.row_status === 'add' || v.row_status === 'update') {
              delete v.row_status;
              delete v['Do No.'];
              v.row_history.push(copy);
              temp.add.push(v);
            }
          }
        });

   
        console.log("5d.jFG")
        axios
          .post(`/api/dataentry/save-sheet5d-data`, {
            projectId: this.props.location.state.projectId,
            sheet: this.state.selectSheet,
            data: temp,
            token: this.props.userInfo.token,
          })
          .then((res) => {
            historySheet = [];
            message.success(`Save sheet data`);
            _.forEach(res.data, (item) => {
              _.forEach(item, (v, k) => {
                if (item[k] === null) {
                  if (this.state.columnMapping[k] === 'numeric') item[k] = 0;
                  else item[k] = '';
                }
              });
            });
            this.handleAdjustData(res.data);
            this.tempDataForTable = this.result;
            this.setState({ dataHotTable: this.result, loading: false });
            this.hotTable.current.hotInstance.resumeRender();
          })
          .catch(() => {
            message.warning(`Can't save sheet data`);
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        message.warning(`Can't get sheet data`);
        this.setState({ loading: false });
      });
  };
  handleCollapse = (value) => {
    this.setState(
      { isCollapse: value, dataHotTable: this.tempDataForTable },
      () => {
        if (this.split2.current) {
          if (this.state.isCollapse) {
            this.split2.current.split.setSizes([100, 0]);
          } else {
            this.split2.current.split.setSizes([50, 50]);
          }
        }
        try {
          this.state.viewerCurrent.resize();
        } catch {}
      }
    );
  };
  handleOpenPdf = (evens, coors, TD) => {
    if (TD.innerText === '' || TD.innerText === null) return;
    if (
      this.state.sheetName === 'Slab 5D' &&
      this.state.columns[coors.col].title == 'Casting report'
    ) {
      let rowPhysical = this.hotTable.current.hotInstance.toPhysicalRow(
        coors.row
      );
      let item =
        this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);

      getPublicUrl(item['Casting report'])
        .then((res) => {
          window.open(res);
        })
        .catch((err) => {});
    }
  };
  handleCorrespond = (e, checked) => {
    this.setState({ isCorrespond: checked });
  };
  handleCorrespondSelect = (row, column, row2, column2, selectionLayerLeve) => {
    if (column === 0) {
      if (this.state.isCorrespond) {
        if (!this.state.viewerCurrent) return;
        let models = this.state.viewerCurrent.impl.modelQueue().getModels();
        if (models.length === 0) return;
        let sel = this.hotTable.current.hotInstance.getSelected();
        console.log(sel);
        let temp = [];
        _.forEach(sel, (v) => {
          let start = v[0];
          while (start <= v[2]) {
            let rowPhysical =
              this.hotTable.current.hotInstance.toPhysicalRow(start);
            let row =
              this.hotTable.current.hotInstance.getSourceDataAtRow(rowPhysical);
            if (row) {
              temp.push(row);
            }
            start++;
          }
        });
        this.handleSelectObjectInRow(temp, true);
      }
    } else {
      if (this.state.viewerCurrent) this.state.viewerCurrent.clearSelection();
    }
  };
  handleGroupRow = () => {};
  handleChangeGroup = (value) => {
    this.setState({ groupValue: value }, () => {
      let temp = [];
      _.forEach(this.tempDataForTable, (v) => {
        if (!v.isAutoGroup) temp.push(v);
      });
      this.handleAdjustData(temp);
      this.tempDataForTable = this.result;
      this.setState({ dataHotTable: this.result });
      this.hotTable.current.hotInstance.resumeRender();
    });
  };
  handleAddColumnSheet = (e) => {
    if (this.props.isAdmin) {
      this.setState({
        displayAddColumnSheet: true,
        currentSheet: e.props.item,
      });
    }
  };
  handleCloseColumnSheet = () => {
    this.setState({ displayAddColumnSheet: false });
  };
  render() {
    let leftOpen = this.state.leftOpen ? 'open' : 'closed';
    return (
      <div id="data-entry">
        {!this.state.error ? (
          this.state.listSheet ? (
            this.state.dataUser !== null && (
              <div id="layout-dataentry">
                <div id="left-dataentry" className={leftOpen}>
                  <div className="icon" onClick={this.toggleSidebar}>
                    &equiv;
                  </div>
                  <div className={`sidebar ${leftOpen}`}>
                    <div className="header">
                      <h4 className="title">{this.state.headerSidebar}</h4>
                      <div
                        className="icon arrowclose"
                        onClick={this.toggleSidebar}
                      >
                        &lsaquo;
                      </div>
                    </div>
                    <div className="content">
                      <div
                        style={{
                          background: '#003059',
                          height: '100%',
                          width: '42px',
                          position: 'absolute',
                        }}
                      >
                        <Tooltip placement="right" title="Folder">
                          <div
                            className={`icon function ${
                              this.state.headerSidebar === 'Browser' && 'active'
                            }`}
                            onClick={this.handleFunctionSidebar.bind(
                              this,
                              'Browser'
                            )}
                          >
                            &#128449;
                          </div>
                        </Tooltip>
                        <Tooltip placement="right" title="Recent">
                          <div
                            className={`icon function ${
                              this.state.headerSidebar === 'Recent Files' &&
                              'active'
                            }`}
                            onClick={this.handleFunctionSidebar.bind(
                              this,
                              'Recent Files'
                            )}
                          >
                            &#128343;
                          </div>
                        </Tooltip>
                        <Tooltip placement="right" title="Bookmark">
                          <div
                            className={`icon function ${
                              this.state.headerSidebar === 'Bookmark' &&
                              'active'
                            }`}
                            onClick={this.handleFunctionSidebar.bind(
                              this,
                              'Bookmark'
                            )}
                          >
                            &#128970;
                          </div>
                        </Tooltip>
                      </div>
                      <Scrollbars
                        renderView={renderView}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        thumbMinSize={30}
                        universal={true}
                      >
                        {this.state.headerSidebar === 'Browser' && (
                          <MenuProvider
                            id={'data_entry_side_menu'}
                            animation={animation.zoom}
                          >
                            <div
                              style={{
                                left: '42px',
                                position: 'relative',
                                width: 'calc(100% - 45px)',
                                height: '100vh',
                              }}
                            >
                              <List
                                size="small"
                                dataSource={this.state.listSheet}
                                renderItem={(item, idx) => (
                                  <div
                                    className={`list-sheet ${
                                      item.active && 'active'
                                    }`}
                                  >
                                    <MenuProvider
                                      id={'data_entry_file_menu'}
                                      animation={animation.zoom}
                                      data={{ item: item, idx: idx }}
                                    >
                                      <List.Item
                                        style={{
                                          color: 'white',
                                          wordBreak: 'unset',
                                          whiteSpace: 'nowrap',
                                          borderBottom: 'none',
                                        }}
                                        onClick={this.handleSheetOpen.bind(
                                          this,
                                          item
                                        )}
                                      >
                                        <AntIcon type="file" />
                                        <div
                                          style={{
                                            width: 'calc(100% - 40px)',
                                            overflowX: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                        <AntIcon type="more" />
                                      </List.Item>
                                    </MenuProvider>
                                  </div>
                                )}
                              />
                            </div>
                          </MenuProvider>
                        )}
                      </Scrollbars>
                    </div>
                  </div>
                </div>

                <div id="main-dataentry">
                  <div className="header">
                    <div style={{ marginTop: 5, marginLeft: 5, float: 'left' }}>
                      {this.state.listUserUseSheet.map((v) => (
                        <Tooltip title={v.name} placement="bottom">
                          <Avatar
                            style={{
                              verticalAlign: 'middle',
                              fontSize: 12,
                              backgroundColor:
                                v.shortCut === '...' ? 'red' : stc(v.name),
                            }}
                            size="small"
                          >
                            {v.shortCut}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </div>

                    <h4 className={`title ${'left-' + leftOpen}`}>
                      {this.state.headerMain}
                      {this.state.headerMain == 'Archi' && (
                        <a
                          onClick={() => {
                            this.props.history.push({
                              pathname: `/for5d/archi-input`,
                              state: {
                                ...this.props.location.state,
                              },
                            });
                          }}
                        >
                          -&gt; Switch to Status view
                        </a>
                      )}
                    </h4>

                    {this.state.sheetId && (
                      <IconButton
                        size="xs"
                        color={!this.state.isViewer ? 'blue' : 'red'}
                        icon={
                          this.state.isViewer ? (
                            <Icon icon="close" />
                          ) : (
                            <Icon>3D</Icon>
                          )
                        }
                        onClick={this.handleForgeViewer}
                        style={{
                          marginTop: 5,
                          marginRight: 5,
                          float: 'right',
                          borderRadius: 0,
                        }}
                      />
                    )}
                  </div>
                  <div className="content">
                    {this.state.loading && (
                      <Loader
                        backdrop
                        center
                        content="Loading..."
                        speed="fast"
                        size="md"
                        vertical
                        style={{ zIndex: 10000 }}
                      />
                    )}
                    <Split
                      ref={this.split1}
                      id="splitForgeViewer"
                      className="splitForgeViewer"
                      minSize={[600, 0]}
                      sizes={[100, 0]}
                      gutterSize={2}
                      gutterAlign="center"
                      direction="horizontal"
                      cursor="col-resize"
                      style={{
                        height: 'calc(100% - 50px)',
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                      onDrag={this.dragSplit}
                    >
                      <div
                        id="content3D"
                        style={{ height: '100%', width: '100%' }}
                      >
                        <div style={{ padding: 5 }}>
                          <Tooltip placement="bottom" title="Save">
                            <IconButton
                              style={{ borderRadius: 0 }}
                              size="sm"
                              appearance="ghost"
                              color=""
                              icon={<Icon icon="save" />}
                              loading={this.state.loading}
                              onClick={this.handleSaveData}
                              disabled={this.state.dataHotTable.length === 0}
                            />
                          </Tooltip>

                          <Divider type="vertical" />
                          <Tooltip placement="top" title="Export Excel">
                            <IconButton
                              style={{ borderRadius: 0 }}
                              size="sm"
                              appearance="ghost"
                              color="blue"
                              icon={<Icon icon="file-excel-o" />}
                              loading={this.state.loading}
                              onClick={this.handleExportExcel}
                              disabled={this.state.dataHotTable.length === 0}
                            />
                          </Tooltip>

                          <Divider type="vertical" />
                          <Tooltip placement="top" title="Colorize Status">
                            <IconButton
                              style={{ borderRadius: 0 }}
                              size="sm"
                              appearance="ghost"
                              color=""
                              icon={<Icon icon="paint-brush" />}
                              loading={this.state.loading}
                              onClick={this.handleColorStatus.bind(this, null)}
                              disabled={this.state.dataHotTable.length === 0}
                            />
                          </Tooltip>
                          <Tooltip placement="top" title="Compare Version">
                            <IconButton
                              style={{ borderRadius: 0 }}
                              size="sm"
                              appearance="ghost"
                              color="blue"
                              icon={<Icon icon="compass" />}
                              loading={this.state.loading}
                              onClick={this.handleCompareVersion}
                              disabled={this.state.dataHotTable.length === 0}
                            />
                          </Tooltip>
                          <Tooltip placement="top" title="Reset Filter">
                            <IconButton
                              style={{ borderRadius: 0 }}
                              size="sm"
                              appearance="ghost"
                              color="blue"
                              icon={<Icon icon="filter" />}
                              loading={this.state.loading}
                              onClick={this.handleClearFilter}
                              disabled={this.state.dataHotTable.length === 0}
                            />
                          </Tooltip>

                          <Popover
                            size="small"
                            placement="bottomLeft"
                            trigger="click"
                            content={
                              <>
                                {/* <ButtonRs color='blue' onClick={this.handleGroupRow} >Group</ButtonRs> */}
                                <InputPicker
                                  data={this.state.groupColumns}
                                  style={{ width: 200 }}
                                  size="sm"
                                  placement="right"
                                  value={this.state.groupValue}
                                  onChange={this.handleChangeGroup}
                                />
                              </>
                            }
                          >
                            <Tooltip placement="top" title="Group">
                              <IconButton
                                style={{ borderRadius: 0 }}
                                size="sm"
                                appearance="ghost"
                                color="blue"
                                icon={<Icon icon="object-group" />}
                                loading={this.state.loading}
                                disabled={this.state.dataHotTable.length === 0}
                              />
                            </Tooltip>
                          </Popover>

                          <Search
                            placeholder="Search"
                            disabled={this.state.dataHotTable.length === 0}
                            onChange={(e) => {
                              const search =
                                this.hotTable.current.hotInstance.getPlugin(
                                  'search'
                                );
                              const queryResult = search.query(e.target.value);
                              this.hotTable.current.hotInstance.render();
                            }}
                            onSearch={(value) => {
                              if (value !== '') {
                                const data =
                                  this.hotTable.current.hotInstance.getData();
                                const search =
                                  this.hotTable.current.hotInstance.getPlugin(
                                    'search'
                                  );
                                const hiddenRowsPlugin =
                                  this.hotTable.current.hotInstance.getPlugin(
                                    'hiddenRows'
                                  );
                                const hiddenColumnsPlugin =
                                  this.hotTable.current.hotInstance.getPlugin(
                                    'hiddenColumns'
                                  );
                                _.forEach(data, (v, k) => {
                                  hiddenRowsPlugin.hideRow(k);
                                });
                                hiddenRowsPlugin.hideRows();
                                const queryResult = search.query(value);
                                _.forEach(queryResult, (v) => {
                                  if (!hiddenColumnsPlugin.isHidden(v.col)) {
                                    hiddenRowsPlugin.showRow(v.row);
                                  }
                                });
                                this.hotTable.current.hotInstance.render();
                              } else {
                                const data =
                                  this.hotTable.current.hotInstance.getData();
                                const hiddenRowsPlugin =
                                  this.hotTable.current.hotInstance.getPlugin(
                                    'hiddenRows'
                                  );
                                _.forEach(data, (v, k) => {
                                  hiddenRowsPlugin.showRow(k);
                                });
                                this.hotTable.current.hotInstance.render();
                              }
                            }}
                            style={{ width: 200, top: 3, borderRadius: 0 }}
                          />
                          <Divider type="vertical" />
                          <Checkbox
                            inline
                            checked={this.state.isCorrespond}
                            disabled={this.state.dataHotTable.length === 0}
                            onChange={this.handleCorrespond.bind(this)}
                          >
                            Correspond with 3D
                          </Checkbox>
                          <Checkbox
                            inline
                            checked={this.state.isIsolate}
                            disabled={
                              this.state.dataHotTable.length === 0
                                ? true
                                : this.state.isCorrespond
                                ? false
                                : true
                            }
                            onChange={(e, checked) =>
                              this.setState({ isIsolate: checked })
                            }
                          >
                            Isolate selected object
                          </Checkbox>
                          {/* <Divider type='vertical' />
                        <IconButton style={{ borderRadius: 0 }} size='sm' appearance='ghost'
                          color='blue' icon={<Icon icon='history' />} loading={this.state.loading} onClick={this.handleCellHistory} disabled={this.state.dataHotTable.length === 0} /> */}

                          {this.state.isShowLegend && (
                            <div
                              style={{
                                display: 'inline',
                                float: 'right',
                                position: 'relative',
                                top: 5,
                                right: 5,
                              }}
                            >
                              <Badge color="#ffc75e" text="Text value change" />
                              <Divider type="vertical" />
                              <Badge color="#5eff6c" text="Number increase" />
                              <Divider type="vertical" />
                              <Badge color="#ff5e5e" text="Number reduce" />
                            </div>
                          )}
                        </div>
                        <div style={{ height: 'calc(100% - 46px)' }}>
                          <HotTable
                            id="dataentry_5d"
                            ref={this.hotTable}
                            data={this.state.dataHotTable}
                            dataSchema={this.state.dataSchema}
                            // preventOverflow={'horizontal'}
                            dragToScroll={false}
                            // nestedRows={true}
                            search={{
                              searchResultClass: 'search-result-custom',
                            }}
                            stretchH="all"
                            rowHeights={24}
                            rowHeaderWidth={40}
                            rowHeaders={(index) => {
                              if (this.state.columns) return index + 1;
                            }}
                            // afterGetRowHeader={(index, TH) => {
                            //   if (index >= 0 && TH) {
                            //     let plugin = this.hotTable.current.hotInstance.getPlugin('nestedRows').dataManager
                            //     let row = plugin.getDataObject(index);
                            //     if (row.itemIds) {
                            //       //this.hotTable.current.hotInstance.setCellMeta(index, this.state.mainIndex, 'className', 'checking');
                            //       TH.style.backgroundColor = '#ddefff'
                            //     }
                            //   }
                            // }}
                            hiddenRows={true}
                            colHeaders={true}
                            columns={this.state.columns}
                            colWidths={(index) => {
                              let w = this.state.columns[index].width;
                              return w;
                            }}
                            afterGetColHeader={(index, TH) => {
                              if (
                                this.state.columns.length !== 0 &&
                                TH &&
                                index >= 0
                              ) {
                                TH.children[0].title =
                                  this.state.columns[index].title;
                              }
                            }}
                            hiddenColumns={{
                              columns: this.state.hideColumns,
                            }}
                            cells={(row, col, prop) => {
                              const cellPrp = {};
                              if (row !== -1) {
                                if (this.state.columns[col]) {
                                  let rowPhysical =
                                    this.hotTable.current.hotInstance.toPhysicalRow(
                                      row
                                    );
                                  let item =
                                    this.hotTable.current.hotInstance.getSourceDataAtRow(
                                      rowPhysical
                                    );
                                  //  let classes =  this.hotTable.current.hotInstance.getCellMeta(row,col).className || ''
                                  if (item) {
                                    if (
                                      this.state.columns[col].readOnly ||
                                      item.isAutoGroup
                                    ) {
                                      cellPrp.className =
                                        'cell-readonly-dataentry';
                                      //return cellPrp.renderer = 'cell.readonly.dataentry';
                                      cellPrp.readOnly = true;
                                      return cellPrp;
                                    } else if (row % 2 === 0) {
                                      // cellPrp.className = 'cell-even-dataentry'
                                      if (prop === 'Casting report') {
                                        cellPrp.renderer = myBtns;
                                        // cellPrp.readOnly = true
                                      }
                                    } else if (row % 2 !== 0) {
                                      // cellPrp.className = 'cell-odd-dataentry'
                                      if (prop === 'Casting report') {
                                        cellPrp.renderer = myBtns;
                                        // cellPrp.readOnly = true
                                      }
                                    }
                                  }
                                }
                              }

                              return cellPrp;
                            }}
                            dropdownMenu={[
                              'filter_by_value',
                              'filter_by_condition',
                              'filter_action_bar',
                            ]}
                            filters={true}
                            manualColumnResize={true}
                            comments={true}
                            height="100%"
                            width="100%"
                            licenseKey="non-commercial-and-evaluation"
                            autoColumnSize={true}
                            bindRowsWithHeaders={true}
                            fillHandle={false}
                            // columnSorting={true}
                            // manualColumnMove={true}
                            manualColumnFreeze={true}
                            // persistentState={true}
                            afterOnCellMouseUp={this.handleOpenPdf}
                            afterSelectionEnd={this.handleCorrespondSelect}
                            beforeChange={(change, source) => {
                              if (source === '') {
                                return;
                              }
                              let temp = [];
                              _.forEach(change, (v) => {
                                if (v[2] === null || v[2] === undefined)
                                  v[2] = '';
                                if (v[2] !== v[3])
                                  if (!temp.includes(v[0])) {
                                    temp.push(v[0]);
                                    if (v[1] === 'Status') {
                                      if (
                                        !this.state.currentStatus.includes(v[3])
                                      )
                                        v[3] = '';
                                    }
                                  }
                              });
                            }}
                            afterChange={(change, source) => {
                              if (source === 'loadData') {
                                return;
                              }
                              let temp = [];
                              let tempStatus = [];
                              _.forEach(change, (v) => {
                                if (v[2] === null || v[2] === undefined)
                                  v[2] = '';
                                if (v[2] !== v[3])
                                  if (!temp.includes(v[0])) {
                                    temp.push(v[0]);
                                    if (v[1] === 'Status') {
                                      tempStatus.push(v[0]);
                                    }
                                  }
                              });
                              if (temp.length === 0) return;

                              let tempItemStatus = [];
                              _.forEach(temp, (v) => {
                                let rowPhysical =
                                  this.hotTable.current.hotInstance.toPhysicalRow(
                                    v
                                  );
                                let item =
                                  this.hotTable.current.hotInstance.getSourceDataAtRow(
                                    rowPhysical
                                  );
                                if (tempStatus.includes(v))
                                  tempItemStatus.push(item);
                                let copy = Object.assign({}, item);
                                let index = _.findIndex(historySheet, (o) => {
                                  return o.id === copy.id;
                                });
                                if (index >= 0) {
                                  copy.row_status = historySheet[index]
                                    .row_status
                                    ? historySheet[index].row_status
                                    : 'update';
                                  historySheet.splice(index, 1, copy);
                                } else {
                                  copy.row_status = 'update';
                                  historySheet.push(copy);
                                }
                              });
                              this.tempDataForTable =
                                this.hotTable.current.hotInstance.getData();
                              if (
                                tempItemStatus.length !== 0 &&
                                this.state.viewerCurrent
                              )
                                this.handleColorStatus(tempItemStatus);
                            }}
                            beforeCopy={(data, coords) => {
                              handleBeforeCopyAndCut(
                                this.hotTable.current.hotInstance,
                                coords
                              );
                            }}
                            contextMenu={this.contextMenuHotTable}
                          />
                        </div>
                      </div>
                      <div
                        id="content2D"
                        style={{
                          height: '100%',
                          width: '100%',
                          zIndex: 1000,
                          backgroundColor: 'white',
                        }}
                      >
                        {this.state.isViewer && (
                          <>
                            <Split
                              ref={this.split2}
                              minSize={[300, 0]}
                              sizes={[100, 0]}
                              gutterSize={5}
                              gutterAlign="center"
                              direction="vertical"
                              cursor="col-resize"
                              style={{
                                height: '100%',
                                width: '100%',
                                justifyItems: 'center',
                                alignItems: 'center',
                                borderTop: '2px #d8d8d8 solid',
                              }}
                              onDrag={this.dragSplit}
                            >
                              <div style={{ height: '100%', width: '100%' }}>
                                <div
                                  id="forgeViewer"
                                  style={{
                                    position: 'relative',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      zIndex: '102',
                                      float: 'left',
                                      top: '0',
                                      borderRadius: 0,
                                    }}
                                    size="sm"
                                    appearance="ghost"
                                    color="blue"
                                    icon={<Icon icon="detail" />}
                                    onClick={this.handleOpenTreeModel}
                                  />
                                  <TreeModel
                                    ref={this.treeModel}
                                    open={this.state.displayTreeView}
                                    close={this.handleCloseTreeView}
                                    viewer={this.state.viewerCurrent}
                                    highLightRowsRelatedToSelectedElements={this.highLightRowsRelatedToSelectedElements.bind(
                                      this
                                    )}
                                    projectId={
                                      this.props.location.state.projectId
                                    }
                                    projectName={
                                      this.props.location.state.projectName
                                    }
                                    userInfo={this.props.userInfo}
                                    itemIds={this.state.itemIds}
                                    callbackRemoveDataTable={
                                      this.callbackRemoveDataTable
                                    }
                                    callbackLoadDone={this.callbackLoadDone}
                                  />
                                  {this.state.isCollapse && (
                                    <IconButton
                                      style={{
                                        zIndex: '102',
                                        bottom: 0,
                                        borderRadius: 0,
                                        position: 'absolute',
                                        right: 0,
                                      }}
                                      size="sm"
                                      appearance="ghost"
                                      color="blue"
                                      icon={<Icon icon="plus" />}
                                      onClick={this.handleCollapse.bind(
                                        this,
                                        false
                                      )}
                                    />
                                  )}
                                </div>
                              </div>

                              <div style={{ height: '100%', width: '100%' }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                  <div style={{ margin: 2 }}>
                                    <ButtonGroup>
                                      <Tooltip
                                        placement="bottom"
                                        title="Reload Data"
                                      >
                                        <IconButton
                                          color="blue"
                                          appearance="ghost"
                                          style={{ borderRadius: 0 }}
                                          size="sm"
                                          icon={<Icon icon="reload" />}
                                          onClick={this.handleModelData}
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        placement="bottom"
                                        title="Assign Data"
                                      >
                                        <IconButton
                                          color="blue"
                                          appearance="ghost"
                                          style={{ borderRadius: 0 }}
                                          size="sm"
                                          icon={<Icon icon="file-upload" />}
                                          onClick={this.handleAssign}
                                        />
                                      </Tooltip>

                                      {this.state.dataTable.length !== 0 && (
                                        <>
                                          {/* <Tooltip placement='bottom' title='Colorize' >
                                          <IconButton color='blue' style={{ borderRadius: 0 }} size="xs" icon={<Icon icon='paint-brush' />} onClick={this.handleColorStatus} />
                                        </Tooltip> */}
                                          <Tooltip
                                            placement="bottom"
                                            title="Show Element"
                                          >
                                            <IconButton
                                              color="blue"
                                              style={{ borderRadius: 0 }}
                                              appearance="ghost"
                                              size="sm"
                                              icon={<Icon icon="link" />}
                                              onClick={this.handleShowElement}
                                            />
                                          </Tooltip>
                                        </>
                                      )}
                                    </ButtonGroup>
                                    <ButtonGroup style={{ float: 'right' }}>
                                      <Tooltip
                                        placement="bottom"
                                        title="Collapse"
                                      >
                                        <IconButton
                                          color="red"
                                          appearance="ghost"
                                          style={{ borderRadius: 0 }}
                                          size="sm"
                                          icon={<Icon icon="minus" />}
                                          onClick={this.handleCollapse.bind(
                                            this,
                                            true
                                          )}
                                        />
                                      </Tooltip>
                                    </ButtonGroup>
                                  </div>
                                  <div
                                    style={{
                                      height: 'calc(100% - 40px)',
                                      width: '100%',
                                    }}
                                  >
                                    <DataEntryTable
                                      data={this.state.dataTable}
                                      viewer={this.state.viewerCurrent}
                                      columns={this.state.columnTableForge}
                                      mainParameter={this.state.mainParameter}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Split>
                          </>
                        )}
                      </div>
                    </Split>
                    :
                    <div
                      style={{
                        position: 'relative',
                        top: 'calc(50% - 30px)',
                        textAlignLast: 'center',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <Loader
              center
              content="Loading..."
              speed="fast"
              size="md"
              backdrop
              vertical
              style={{ zIndex: 100000 }}
            />
          )
        ) : (
          <Result
            status={this.state.errorCode === '400' ? 'warning' : 'error'}
            title={this.state.errorCode}
            subTitle={
              this.state.errorCode === '400' ? (
                'Please, contact admin project to access.'
              ) : (
                <>
                  <p>
                    Sorry, we are not able to connect to server at moment.
                    Please try again later.
                  </p>
                  <p>
                    You can contact to our web admin at
                    phan_manhquyet@wohhup.com for feedback or any urgent issue.
                    He is also can reach at number +6596969942.
                  </p>
                </>
              )
            }
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => this.props.history.push('/')}
              >
                Back Home
              </Button>,
            ]}
          ></Result>
        )}

        <Menu id="data_entry_file_menu" style={{ zIndex: 10000 }}>
          <Item onClick={this.handleSettingColumn}>
            <IconFont className="fas fa-edit" />
            Setting Columns
          </Item>
          {this.props.isAdmin && (
            <Item onClick={this.handleAddColumnSheet}>
              <IconFont className="fas fa-plus" />
              Add Columns
            </Item>
          )}
          {this.props.isAdmin && (
            <Item onClick={this.handleDeleteSheet}>
              <IconFont className="fas fa-trash" />
              Delete Sheet
            </Item>
          )}
        </Menu>

        {this.props.isAdmin && (
          <Menu id="data_entry_side_menu" style={{ zIndex: 10000 }}>
            <Item onClick={this.handleNewSheet}>
              <IconFont className="fas fa-plus-square" />
              Add Sheet
            </Item>
          </Menu>
        )}

        {this.state.dialogHistory && (
          <DialogHistory
            close={() => {
              this.setState({ dialogHistory: false });
            }}
            open={this.state.dialogHistory}
            cellDataCurrent={this.state.cellDataCurrent}
          />
        )}

        {this.state.dialogSettingColumn && (
          <DialogSettingColumns
            close={this.handleCloseSettingColumn}
            open={this.state.dialogSettingColumn}
            sheet={this.state.currentSheet}
            token={this.props.userInfo.token}
            projectId={this.props.location.state.projectId}
            isAdmin={this.props.isAdmin}
          />
        )}
        {this.state.displayNewSheet && (
          <DialogTemplateSheet
            close={this.handleCloseNewSheet}
            open={this.state.displayNewSheet}
            token={this.props.userInfo.token}
            listSheet={this.state.listSheet}
            projectId={this.props.location.state.projectId}
          />
        )}
        {this.state.displayAddColumnSheet && (
          <DialogAddColumnSheet
            close={this.handleCloseColumnSheet}
            open={this.state.displayAddColumnSheet}
            token={this.props.userInfo.token}
            sheet={this.state.currentSheet}
            projectId={this.props.location.state.projectId}
          />
        )}
        <CastingReport
          email={this.props.userInfo.email}
          showCastingReport={this.state.showCastingReport}
          reportData={this.state.exportReportData}
          projectName={this.props.location.state.projectName}
          projectId={this.props.location.state.projectId}
        />

        <CastingReportHistory
          showCastingReportHistory={this.state.showCastingReportHistory}
          currentCastingReportHistory={this.state.currentCastingReportHistory}
        />
      </div>
    );
  }
}

DataEntryPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  excelPage: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  };
}
export default connect(mapStateToProps)(DataEntryPage);

let pipeDwall = [
  {
    id: v4(),
    name: 'Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Pile marking',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Sizes',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Design / COL',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Toe',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Blind bore Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Penetration Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Socket Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: `Design / Con'c Vol (m3)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Design / Rebar (tons)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Subcon / COL',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Toe',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Blind bore Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Penetration Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Socket Length (m)',
    type: 'numeric',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: `Subcon / Data Cast`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },
  {
    id: v4(),
    name: 'Subcon / Status',
    type: 'text',
    roleCanEdit: ['Document Controller', 'Sub-Con'],
    roleCanView: ['Document Controller', 'Sub-Con'],
    disabled: true,
    width: 45,
  },

  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 75,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 75,
  },

  {
    id: v4(),
    name: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['On-going', 'Completed'],
    width: 55,
  },
  {
    id: v4(),
    name: `Remark`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    width: 150,
  },

  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
];
let slab5D = [
  {
    id: v4(),
    name: 'Pour name',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Cost code',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 95,
  },
  {
    id: v4(),
    name: 'Qtt tracking code',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },

  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },

  {
    id: v4(),
    name: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['REBAR', 'FORMWORK', 'CASTED'],
    width: 65,
  },
  {
    id: v4(),
    name: `Lean Con'c Area (m2)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Lean Con'c Area Actual (m2)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Fwk Area Design (m2)',
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: 'Fwk Area Actual (m2)',
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Design (m3)`,
    type: 'numeric',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Actual (m3)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Con'c Volume Design (m3) by Engineer`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Rebar Weight Design (kg)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Rebar Weight Actual (kg)`,
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 65,
  },

  {
    id: v4(),
    name: 'Casting report',
    type: 'text',
    roleCanEdit: ['Document Controller', 'QS'],
    roleCanView: ['Document Controller', 'QS'],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Remark`,
    type: 'text',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    width: 150,
  },
];

let archi5D = [
  {
    id: v4(),
    name: 'Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    parameter: 'WH_Zone',
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Sub Zone',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    parameter: 'WH_Sub Zone',
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Level',
    type: 'text',
    parameter: 'WH_Level',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Room Id',
    type: 'text',
    parameter: 'ROOM NUMBER',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Category',
    parameter: 'WH_Group',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D Code',
    type: 'text',
    parameter: 'CODE',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D_CODE_BASE',
    type: 'text',
    parameter: '5D_CODE_BASE',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: '5D_CODE_MIDLE',
    parameter: '5D_CODE_MIDLE',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),

    name: '5D_CODE_FINAL',
    parameter: '5D_CODE_FINAL',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },

  {
    id: v4(),
    name: 'Item',
    parameter: 'Item',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Cost Id',
    parameter: 'Cost Id',
    type: 'text',
    roleCanEdit: [],
    roleCanView: [],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Length',
    parameter: 'Length',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Volume',
    parameter: 'Volume',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Area',
    parameter: 'Area',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Count',
    parameter: 'Count',
    type: 'numeric',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'UNIT',
    parameter: 'UNIT',
    type: 'text',
    roleCanEdit: ['Document Controller'],
    roleCanView: ['Document Controller'],
    disabled: true,
    width: 125,
  },
  {
    id: v4(),
    name: 'Status',
    parameter: 'Status',
    type: 'dropdown',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'Production'],
    disabled: true,
    valueArray: ['Yes', 'No'],
    width: 65,
  },
  {
    id: v4(),
    name: 'Plan date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Plan date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Planning Engineer'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Planning Engineer'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date start',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },
  {
    id: v4(),
    name: 'Actual date finish',
    type: 'date',
    roleCanEdit: ['Document Controller', 'Production'],
    roleCanView: ['Document Controller', 'QS', 'Sub-Con', 'Production'],
    disabled: true,
    width: 70,
  },

  {
    id: v4(),
    name: `2D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `3D View Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
  {
    id: v4(),
    name: `Model Name`,
    type: 'text',
    roleCanEdit: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    roleCanView: [
      'Document Controller',
      'WH Archi Manager',
      'WH Archi Coordinator',
      'WH Archi Modeller',
      'WH C&S Manager',
      'WH C&S Design Engineer',
      'WH C&S Modeller',
      'WH M&E Manager',
      'WH M&E Coordinator',
      'WH M&E Modeller',
      'WH PRECAST Manager',
      'WH PRECAST Coordinator',
      'WH PRECAST Modeller',
    ],
    disabled: true,
    width: 65,
  },
];

class DialogHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      //cell: this.props.cellDataCurrent.cell
    };
    this.hotTable = React.createRef();
  }

  componentDidMount = () => {
    console.log(this.props.cellDataCurrent);
    let temp = [];
    // let clone = Object.assign({},this.props)   //this.props.cellDataCurrent.cell.row_history.slice(0)
    _.forEach(this.props.cellDataCurrent.cell.row_history, (v) => {
      let clone = Object.assign({}, v);
      clone.updatedAt = moment(clone.updatedAt).format('DD/MM/YYYY HH:mm');
      temp.push(clone);
    });
    let columns = [
      {
        title: 'User',
        data: 'user',
        type: 'text',
        className: 'truncated',
        readOnly: true,
      },
      {
        title: this.props.cellDataCurrent.prop,
        data: this.props.cellDataCurrent.prop,
        type: 'text',
        className: 'truncated',
        readOnly: true,
      },
      {
        title: 'Update At',
        data: 'updatedAt',
        type: 'date',
        className: 'truncated',
        readOnly: true,
        width: 120,
      },
    ];
    this.setState({ columns, data: temp });
  };
  handleClose = () => {
    this.props.close();
  };

  render() {
    return (
      <>
        <Modal
          title={'Cell History'}
          visible={this.props.open}
          onCancel={this.handleClose}
          onOk={this.handleClose}
          width={'420px'}
          confirmLoading={this.state.loading}
        >
          <div>
            <HotTable
              id="dataentry_5d"
              ref={this.hotTable}
              data={this.state.data}
              dropdownMenu={[
                'filter_by_value',
                'filter_by_condition',
                'filter_action_bar',
              ]}
              filters={true}
              // height='100%'
              width="100%"
              licenseKey="non-commercial-and-evaluation"
              colHeaders={true}
              rowHeaders={true}
              colWidths={100}
              rowHeights={22}
              manualColumnResize={true}
              columns={this.state.columns}
              bindRowsWithHeaders={true}
              fillHandle={false}
              cells={(row, col, prop) => {
                const cellPrp = {};
                cellPrp.className = 'cell-readonly-dataentry';
                return cellPrp;
              }}
            />
          </div>
        </Modal>
      </>
    );
  }
}

class DialogSettingColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      columnName: '',
      columnType: '',
      roleCanView: [],
      roleCanEdit: [],
      openSetting: props.open,
      valueArray: '',
      item: null,
      loading: false,
      indeterminate: false,
      checkAll: false,
    };
  }

  componentDidMount = () => {
    let temp = Object.assign({}, this.props.sheet);
    this.setState({ columns: temp.columns ? temp.columns.slice(0) : [] });
  };
  handleClose = () => {
    this.props.close();
  };
  handleChangeColumnName = (value) => {
    this.setState({ columnName: value });
  };
  handleChangeColumnType = (value) => {
    this.setState({ columnType: value });
  };
  handleChangeRoleCanView = (value) => {
    this.setState({ roleCanView: value });
  };
  handleChangeRoleCanEdit = (value) => {
    this.setState({ roleCanEdit: value });
  };
  handleAddColumn = () => {
    if (!this.state.columnName || !this.state.columnType) {
      message.warning(`Please, key in all field`);
      return;
    }
    let temp = this.state.columns;
    let index = _.findIndex(temp, (v) => {
      return v.id === this.state.columnName;
    });
    if (index >= 0) {
      message.warning('Column name is existing');
    } else {
      temp.push({
        id: this.state.columnName,
        name: this.state.columnName,
        type: this.state.columnType,
        disabled: true,
        roleCanEdit: this.state.roleCanEdit,
        roleCanView: this.state.roleCanView,
        valueArray: [],
      });
      this.setState({
        columns: temp,
        columnName: '',
        columnType: '',
        roleCanEdit: [],
        roleCanView: [],
      });
    }
  };
  handleEditColumn = (item) => {
    let temp = this.state.columns;
    item.disabled = !item.disabled;
    this.setState({ columns: temp });
  };
  handleDeleteColumn = (item) => {
    if (!window.confirm('Are you want to delete it')) return;
    let temp = this.state.columns;
    let index = _.findIndex(temp, (v) => {
      return v.name === item.name;
    });
    if (index >= 0) {
      temp.splice(index, 1);
      this.setState({ columns: temp });
    }
  };
  handleEditColumnName = (item, value) => {
    let temp = this.state.columns;
    item.name = value;
    this.setState({ columns: temp });
  };
  handleEditColumnParameter = (item, value) => {
    let temp = this.state.columns;
    item.parameter = value;
    this.setState({ columns: temp });
  };
  handleEditColumnWidth = (item, value) => {
    let temp = this.state.columns;
    item.width = value;
    this.setState({ columns: temp });
  };
  handleEditColumnIsTotal = (item, value) => {
    let temp = this.state.columns;
    item.isTotal = !item.isTotal;
    this.setState({ columns: temp });
  };
  handleEditColumnType = (item, value) => {
    let temp = this.state.columns;
    item.type = value;
    this.setState({ columns: temp });
  };
  handleEditRoleCanView = (item, value) => {
    let temp = this.state.columns;
    item.roleCanView = value;
    this.setState({
      columns: temp,
      indeterminate:
        item['roleCanView'].length > 0 &&
        item['roleCanView'].length < roles.length,
      checkAll: item['roleCanView'].length === roles.length,
    });
  };
  handleEditRoleCanEdit = (item, value) => {
    let temp = this.state.columns;
    item.roleCanEdit = value;
    this.setState({
      columns: temp,
      indeterminate:
        item['roleCanEdit'].length > 0 &&
        item['roleCanEdit'].length < roles.length,
      checkAll: item['roleCanEdit'].length === roles.length,
    });
  };
  handleOpenDropDown = (tag) => {
    let value = tag.valueArray ? tag.valueArray.join('\n') : '';
    this.setState({ openDropDown: true, item: tag, valueArray: value });
  };
  handleChangeTextArea = (e) => {
    this.setState({ valueArray: e.target.value });
  };
  handleChangeDropDownValue = () => {
    let data = this.state.valueArray.split(/\n/);
    let temp = [];
    _.forEach(data, (v) => {
      if (!temp.includes(v) && v !== '') temp.push(v);
    });
    this.state.item.valueArray = temp;
    this.setState({ openDropDown: false, valueArray: '' });
  };
  handleOk = () => {
    this.setState({ loading: true });
    let temp = Object.assign({}, this.props.sheet);
    temp.columns = this.state.columns;
    axios
      .post(`/api/dataentry/edit-project-sheet`, {
        projectId: this.props.projectId,
        sheet: temp,
        token: this.props.token,
      })
      .then((res1) => {
        this.props.sheet.columns = this.state.columns;
        this.props.close();
      })
      .catch(() => {
        message.warning(`Can't save sheet`);
        this.setState({ loading: false });
      });
  };
  handleMatchingParameter = (item, e) => {
    let temp = this.state.columns;
    item.isMatching = !item.isMatching;
    this.setState({ columns: temp });
  };
  handleCheckAll = (tag, role, value, checked) => {
    const nextValue = checked ? roles : [];
    let temp = this.state.columns;
    let tempRoles = [];
    _.forEach(nextValue, (v) => {
      tempRoles.push(v.value);
    });
    tag[role] = tempRoles;
    this.setState({ columns: temp, checkAll: checked, indeterminate: false });
  };
  handleOpenCheckbox = (tag, role) => {
    this.setState({
      indeterminate: tag[role].length > 0 && tag[role].length < roles.length,
      checkAll: tag[role].length === roles.length,
    });
  };
  render() {
    return (
      <>
        <Modal
          title={'Setting Column'}
          visible={this.state.openSetting}
          onCancel={this.handleClose}
          onOk={this.handleOk}
          width={'95%'}
          confirmLoading={this.state.loading}
        >
          <Row gutter={[8, 8]}>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong>Name</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong> Column Type</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong> Parameter</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={3}>
              <Text strong> Role can view</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={3}>
              <Text strong>Role can edit</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={2}>
              <Text strong>Width</Text>
            </Col>
            {/* <Col style={{ textAlign: 'center' }} span={1}>
              <Text strong>isTotal</Text>
            </Col> */}
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong>Options</Text>
            </Col>
          </Row>
          {this.props.isAdmin ? (
            <div className="List">
              <div className="main-drag">
                <DraggableArea
                  onChange={(columns) => this.setState({ columns })}
                  isList
                  tags={this.state.columns}
                  render={({ tag }) => (
                    <div className="row">
                      <Col span={4}>
                        <Input
                          block
                          value={tag.name}
                          appearance="default"
                          size="sm"
                          onChange={this.handleEditColumnName.bind(this, tag)}
                          disabled={true}
                        />
                      </Col>
                      <Col span={4}>
                        <InputPicker
                          block
                          placeholder="New Contract Name"
                          appearance="default"
                          size="sm"
                          disabled={this.props.isAdmin ? false : tag.disabled}
                          cleanable={false}
                          value={tag.type}
                          onChange={this.handleEditColumnType.bind(this, tag)}
                          data={[
                            { key: 'Text', label: 'Text', value: 'text' },
                            {
                              key: 'Number',
                              label: 'Number',
                              value: 'numeric',
                            },
                            { key: 'Date', label: 'Date', value: 'date' },
                            {
                              key: 'CheckBox',
                              label: 'CheckBox',
                              value: 'checkbox',
                            },
                            {
                              key: 'DropDown',
                              label: 'DropDown',
                              value: 'dropdown',
                            },
                          ]}
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          block
                          value={tag.parameter}
                          appearance="default"
                          size="sm"
                          placeholder="Parameter"
                          onChange={this.handleEditColumnParameter.bind(
                            this,
                            tag
                          )}
                          disabled={tag.disabled}
                        />
                      </Col>
                      <Col span={3}>
                        <CheckPicker
                          block
                          placeholder="Role Can View"
                          appearance="default"
                          size="sm"
                          value={tag.roleCanView}
                          onEnter={this.handleOpenCheckbox.bind(
                            this,
                            tag,
                            'roleCanView'
                          )}
                          disabled={tag.disabled}
                          cleanable={false}
                          onChange={this.handleEditRoleCanView.bind(this, tag)}
                          data={roles}
                          renderExtraFooter={() => (
                            <div
                              style={{
                                padding: '10px 2px',
                                borderTop: '1px solid #e5e5e5',
                              }}
                            >
                              <Checkbox
                                inline
                                indeterminate={this.state.indeterminate}
                                checked={this.state.checkAll}
                                onChange={this.handleCheckAll.bind(
                                  this,
                                  tag,
                                  'roleCanView'
                                )}
                                style={{ color: 'blue' }}
                              >
                                Check all
                              </Checkbox>
                            </div>
                          )}
                        />
                      </Col>
                      <Col span={3}>
                        <CheckPicker
                          block
                          placeholder="Role Can Edit"
                          appearance="default"
                          size="sm"
                          value={tag.roleCanEdit}
                          onEnter={this.handleOpenCheckbox.bind(
                            this,
                            tag,
                            'roleCanEdit'
                          )}
                          disabled={tag.disabled}
                          cleanable={false}
                          onChange={this.handleEditRoleCanEdit.bind(this, tag)}
                          data={roles}
                          renderExtraFooter={() => (
                            <div
                              style={{
                                padding: '10px 2px',
                                borderTop: '1px solid #e5e5e5',
                              }}
                            >
                              <Checkbox
                                inline
                                indeterminate={this.state.indeterminate}
                                checked={this.state.checkAll}
                                onChange={this.handleCheckAll.bind(
                                  this,
                                  tag,
                                  'roleCanEdit'
                                )}
                                style={{ color: 'blue' }}
                              >
                                Check all
                              </Checkbox>
                            </div>
                          )}
                        />
                      </Col>
                      <Col span={2}>
                        <Input
                          block
                          value={tag.width}
                          appearance="default"
                          size="sm"
                          placeholder="Width"
                          onChange={this.handleEditColumnWidth.bind(this, tag)}
                          disabled={tag.disabled}
                        />
                      </Col>
                      {/* <Col span={1}>
                    <Checkbox size="sm" onChange={this.handleEditColumnIsTotal.bind(this, tag)} checked={tag.isTotal}
                      disabled={tag.disabled} />
                  </Col> */}
                      <Col span={1}>
                        {tag.type === 'dropdown' && (
                          <IconButton
                            size="sm"
                            onClick={this.handleOpenDropDown.bind(this, tag)}
                            color={
                              tag.valueArray &&
                              tag.valueArray.length !== 0 &&
                              'blue'
                            }
                            disabled={tag.disabled || tag.type !== 'dropdown'}
                            icon={<Icon icon="task" />}
                          />
                        )}
                      </Col>
                      <Col span={1}>
                        <Checkbox
                          size="sm"
                          onChange={this.handleMatchingParameter.bind(
                            this,
                            tag
                          )}
                          checked={tag.isMatching}
                          disabled={tag.disabled}
                        />
                      </Col>
                      <Col span={1}>
                        <IconButton
                          size="sm"
                          onClick={this.handleEditColumn.bind(this, tag)}
                          color={!tag.disabled && 'blue'}
                          icon={
                            <Icon
                              icon={`${!tag.disabled ? 'check' : 'edit'}`}
                            />
                          }
                        />
                      </Col>

                      <Col span={1}>
                        <IconButton
                          size="sm"
                          onClick={this.handleDeleteColumn.bind(this, tag)}
                          color={'red'}
                          icon={<Icon icon="trash" />}
                        />
                      </Col>
                    </div>
                  )}
                ></DraggableArea>
              </div>
            </div>
          ) : (
            this.state.columns.map((tag) => (
              <Row gutter={[8]}>
                <Col span={4}>
                  <Input
                    block
                    value={tag.name}
                    appearance="default"
                    size="sm"
                    onChange={this.handleEditColumnName.bind(this, tag)}
                    disabled={true}
                  />
                </Col>
                <Col span={4}>
                  <InputPicker
                    block
                    placeholder="New Contract Name"
                    appearance="default"
                    size="sm"
                    disabled={this.props.isAdmin ? false : tag.disabled}
                    cleanable={false}
                    value={tag.type}
                    onChange={this.handleEditColumnType.bind(this, tag)}
                    data={[
                      { key: 'Text', label: 'Text', value: 'text' },
                      { key: 'Number', label: 'Number', value: 'numeric' },
                      { key: 'Date', label: 'Date', value: 'date' },
                      { key: 'CheckBox', label: 'CheckBox', value: 'checkbox' },
                      { key: 'DropDown', label: 'DropDown', value: 'dropdown' },
                    ]}
                  />
                </Col>
                <Col span={4}>
                  <Input
                    block
                    value={tag.parameter}
                    appearance="default"
                    size="sm"
                    placeholder="Parameter"
                    onChange={this.handleEditColumnParameter.bind(this, tag)}
                    disabled={tag.disabled}
                  />
                </Col>
                <Col span={3}>
                  <CheckPicker
                    block
                    placeholder="Role Can View"
                    appearance="default"
                    size="sm"
                    value={tag.roleCanView}
                    onEnter={this.handleOpenCheckbox.bind(
                      this,
                      tag,
                      'roleCanView'
                    )}
                    disabled={tag.disabled}
                    cleanable={false}
                    onChange={this.handleEditRoleCanView.bind(this, tag)}
                    data={roles}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          padding: '10px 2px',
                          borderTop: '1px solid #e5e5e5',
                        }}
                      >
                        <Checkbox
                          inline
                          indeterminate={this.state.indeterminate}
                          checked={this.state.checkAll}
                          onChange={this.handleCheckAll.bind(
                            this,
                            tag,
                            'roleCanView'
                          )}
                          style={{ color: 'blue' }}
                        >
                          Check all
                        </Checkbox>
                      </div>
                    )}
                  />
                </Col>
                <Col span={3}>
                  <CheckPicker
                    block
                    placeholder="Role Can Edit"
                    appearance="default"
                    size="sm"
                    value={tag.roleCanEdit}
                    onEnter={this.handleOpenCheckbox.bind(
                      this,
                      tag,
                      'roleCanEdit'
                    )}
                    disabled={tag.disabled}
                    cleanable={false}
                    onChange={this.handleEditRoleCanEdit.bind(this, tag)}
                    data={roles}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          padding: '10px 2px',
                          borderTop: '1px solid #e5e5e5',
                        }}
                      >
                        <Checkbox
                          inline
                          indeterminate={this.state.indeterminate}
                          checked={this.state.checkAll}
                          onChange={this.handleCheckAll.bind(
                            this,
                            tag,
                            'roleCanEdit'
                          )}
                          style={{ color: 'blue' }}
                        >
                          Check all
                        </Checkbox>
                      </div>
                    )}
                  />
                </Col>
                <Col span={2}>
                  <Input
                    block
                    value={tag.width}
                    appearance="default"
                    size="sm"
                    placeholder="Width"
                    onChange={this.handleEditColumnWidth.bind(this, tag)}
                    disabled={tag.disabled}
                  />
                </Col>
                {/* <Col span={1}>
                <Checkbox size="sm" onChange={this.handleEditColumnIsTotal.bind(this, tag)} checked={tag.isTotal}
                  disabled={tag.disabled} />
              </Col> */}
                <Col span={1}>
                  {tag.type === 'dropdown' && (
                    <IconButton
                      size="sm"
                      onClick={this.handleOpenDropDown.bind(this, tag)}
                      color={
                        tag.valueArray && tag.valueArray.length !== 0 && 'blue'
                      }
                      disabled={tag.disabled || tag.type !== 'dropdown'}
                      icon={<Icon icon="task" />}
                    />
                  )}
                </Col>
                <Col span={1}>
                  <Checkbox
                    size="sm"
                    onChange={this.handleMatchingParameter.bind(this, tag)}
                    checked={tag.isMatching}
                    disabled={tag.disabled}
                  />
                </Col>
                <Col span={1}>
                  <IconButton
                    size="sm"
                    onClick={this.handleEditColumn.bind(this, tag)}
                    color={!tag.disabled && 'blue'}
                    icon={<Icon icon={`${!tag.disabled ? 'check' : 'edit'}`} />}
                  />
                </Col>

                <Col span={1}>
                  <IconButton
                    size="sm"
                    onClick={this.handleDeleteColumn.bind(this, tag)}
                    color={'red'}
                    icon={<Icon icon="trash" />}
                  />
                </Col>
              </Row>
            ))
          )}

          {/* <div className="List">
           <div className="main-drag">
              <DraggableArea
                isList
                tags={this.state.columns}
                render={({ tag }) => (
                  <div className='row' >
            
                  </div>
                )}
              // onChange={(columns) => this.setState({ columns })}
              />
            </div>
          </div> */}
        </Modal>

        <Modal
          title={'Setting Column'}
          visible={this.state.openDropDown}
          onCancel={() => {
            this.setState({ openDropDown: false, valueArray: '' });
          }}
          onOk={this.handleChangeDropDownValue}
          width={'50%'}
          confirmLoading={false}
        >
          <TextArea
            placeholder="Key in value for dropdown"
            value={this.state.valueArray}
            autoSize={{ minRows: 2, maxRows: 100 }}
            onChange={this.handleChangeTextArea}
          />
        </Modal>
      </>
    );
  }
}

class DialogTemplateSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: '',
      loading: false,
    };
  }

  handleClose = () => {
    this.props.close();
  };

  handleOk = () => {
    if (this.state.template === '') {
      message.warning('Please, choose a template');
      return;
    }
    let tempColumns = [];
    if (this.state.template === 'Pile, Dwall') {
      tempColumns = pipeDwall;
    } else if (this.state.template === 'Slab 5D') {
      tempColumns = slab5D;
    } else if (this.state.template === 'Archi') {
      tempColumns = archi5D;
    }
    _.forEach(tempColumns, (v) => {
      v.id = v.name;
    });
    let name = this.state.template;
    let sheet = {
      id: this.props.projectId + '_for5D_' + this.state.template,
      name: name,
      type: 'for5d',
      columns: tempColumns,
    };
    let temp = this.props.listSheet;
    let index = _.findIndex(temp, (o) => {
      return o.name === name;
    });
    if (index >= 0) {
      message.warning('File is existing');
    } else {
      this.setState({ loading: true });
      axios
        .post(`/api/dataentry/set-project-sheet`, {
          projectId: this.props.projectId,
          sheet,
          token: this.props.token,
        })
        .then((res1) => {
          temp.push(sheet);
          this.props.close(temp);
        })
        .catch(() => {
          message.warning(`Can't save sheet`);
          this.setState({ loading: false });
        });
    }
  };
  handleChangeTemplate = (value) => {
    this.setState({ template: value });
  };

  render() {
    return (
      <>
        <Modal
          title={'Template Sheet'}
          visible={true}
          onCancel={this.handleClose}
          onOk={this.handleOk}
          width={'30%'}
          confirmLoading={this.state.loading}
        >
          <InputPicker
            block
            placeholder="Template Sheet"
            appearance="default"
            size="sm"
            value={this.state.template}
            cleanable={false}
            data={[
              {
                key: 'Pile, Dwall',
                label: 'Pile, Dwall',
                value: 'Pile, Dwall',
              },
              { key: 'Slab 5D', label: 'Slab 5D', value: 'Slab 5D' },
              { key: 'Archi', label: 'Archi', value: 'Archi' },
            ]}
            onChange={this.handleChangeTemplate}
          />
          <br />
        </Modal>
      </>
    );
  }
}

class DialogAddColumnSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnName: '',
      columnType: '',
      loading: false,
    };
  }

  handleClose = () => {
    this.props.close();
  };

  handleOk = () => {
    if (this.state.columnName === '' || this.state.columnType === '') {
      return;
    }
    this.setState({ loading: true });
    axios
      .post(`/api/dataentry/add-column-project-sheet`, {
        projectId: this.props.projectId,
        sheet: this.props.sheet,
        token: this.props.token,
        columnName: this.state.columnName,
        columnType: this.state.columnType,
      })
      .then((res1) => {
        this.props.close();
      })
      .catch(() => {
        message.warning(`Can't save column sheet`);
        this.setState({ loading: false });
      });
  };
  handleChangeColumnType = (value) => {
    this.setState({ columnType: value });
  };
  handleChangeColumnName = (value) => {
    this.setState({ columnName: value });
  };
  render() {
    return (
      <>
        <Modal
          title={'Add Column Sheet'}
          visible={this.props.open}
          onCancel={this.handleClose}
          onOk={this.handleOk}
          width={'30%'}
          confirmLoading={this.state.loading}
        >
          <Col span={12}>
            <Input
              block
              value={this.state.columnName}
              appearance="default"
              size="sm"
              placeholder="Column Name"
              onChange={this.handleChangeColumnName.bind(this)}
            />
          </Col>
          <Col span={12}>
            <InputPicker
              block
              placeholder="Column Type"
              appearance="default"
              size="sm"
              cleanable={false}
              value={this.state.columnType}
              onChange={this.handleChangeColumnType.bind(this)}
              data={[
                { key: 'Text', label: 'Text', value: 'text' },
                { key: 'Number', label: 'Number', value: 'numeric' },
                { key: 'Date', label: 'Date', value: 'date' },
                { key: 'CheckBox', label: 'CheckBox', value: 'checkbox' },
                { key: 'DropDown', label: 'DropDown', value: 'dropdown' },
              ]}
            />
          </Col>
          <br />
        </Modal>
      </>
    );
  }
}
// this.setState({
//   listSheet: [
//     { id: base64url.encode(this.props.location.state.projectId + 'Pile, Dwall'), name: 'Pile, Dwall', type: 'for5d', columns: pipeDwall },
//     // {id:base64url.encode(this.props.location.state.projectId+'CBP, SBP, GMP'),name:'CBP, SBP, GMP'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Earth work'),name:'Earth work'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Slab, beam'),name:'Slab, beam'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Slab, beam'),name:'Slab, beam'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Column'),name:'Column'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Wall'),name:'Wall'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Units'),name:'Units'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Common Areas'),name:'Common Areas'},
//     // {id:base64url.encode(this.props.location.state.projectId+'Precast, PPVC'),name:'Precast, PPVC'},
//   ]
// })
