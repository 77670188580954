import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Position, Toaster } from "@blueprintjs/core";
import axios from "axios";
import { Upload, Icon, Typography, message, Checkbox, Input, Row, Col, Button } from 'antd';
import { InputPicker } from 'rsuite'
import { Progress } from 'reactstrap';
import _ from 'lodash'
import * as semantic from 'semantic-ui-react'
import { useState } from 'react';
import {
  getForgeToken, getForgeTokenInternal
} from '../function/ForgeFunction'
import { eachLimit } from 'async'
const { Dragger } = Upload;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { Text } = Typography
const { TextArea } = Input;
const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100,
  },
}));
let chunkCounter = 0
function ButtonUploadFile(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoad] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [file, setFile] = useState(null)
  const [typeFile, setTypeFile] = useState('none')
  const [isTranslate, setIsTranslate] = useState(false)
  const [revisionNote, setRevisionNote] = useState('')
  const [isIFC, setIsIFC] = useState(false)
  const [isTrimbleConnect, setIsTrimbleConnect] = useState(false)
  const [isNewFile, setIsNewFile] = useState(true)
  const toaster = useRef()
  // function beforeUpload(file) {
  //   let split = file.name.split('.')
  //   let name = split[split.length - 1].toLowerCase()
  //   const isRvtorIFC = name === 'rvt' || name === 'ifc' ||
  //     name === 'dwf' || name === 'dwfx' || name === 'fbx' || name === 'nwc'
  //     || name === 'nwd'|| name === 'nwf'|| name === 'dwf'
  //   if (!isRvtorIFC) {
  //     message.error('You can only upload RVT/IFC/DWF/DWFx/FBX/NWC/NWD file!');
  //   } 
  //   return isRvtorIFC;
  // }

  useEffect(() => {
    setLoad(props.progressPercent)
  }, [props.progressPercent])

  const handleUpload = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: ({ file }) => {
      handleUploadFile(file)
    },
  };
  const handleUploadFile = (file) => {
    if (file !== null) {
      setUploading(true)
      if (!isTrimbleConnect) {
        var data = new FormData()
        data.append('file', file)
        data.append('folderName', props.project.folderName)
        data.append('folderId', props.project.folderId)
        data.append('projectId', props.project.projectId)
        data.append('projectName', props.project.projectName)
        data.append('revisionNote', revisionNote)
        data.append('isTranslate', isTranslate)
        data.append('typeFile', typeFile)
        data.append('user', props.user)
        data.append('socketId', localStorage.getItem('whapp5d-socketid'))
        if (data !== undefined || data !== null) {
          if (file.size < (100 * 1024 * 1024)) {
            props.handleFileSmaller100MB(file)
          }
          axios.post(`/api/items/add-item`, data, {
            timeout: 60 * 10 * 1000,
            onUploadProgress: ProgressEvent => {
              setLoad(
                (ProgressEvent.loaded / ProgressEvent.total * 100),
              )
              if ((ProgressEvent.loaded / ProgressEvent.total * 100) === 100) {
                setOpen(false)
              }
            }
          })
            .then(() => {
              // this.props.handleCallbackUploadFile(res.data)
              // console.log('upload')
              // window.location.reload()
            })
            .catch(err => {
              try {
                message.error(err.response.data.errors.itemId)
              }
              catch { }
              finally {
                message.error('Upload file was failed')
              }
            })
        }
      } else {
        if (props.trimbleKey) {
          axios.get(`/api/trimble/oauth/trimble-refresh-token`, { params: { token: props.userInfo.token } })
            .then(resToken => {
              var data = new FormData()
              data.append('files', file)
              data.append('parentType', 'FOLDER')
              data.append('socketId', localStorage.getItem('whapp5d-socketid'))
              if (data !== undefined || data !== null) {
                axios.post(`https://app31.connect.trimble.com/tc/api/2.0/files?parentId=${props.trimbleKey}`, data, {
                  headers: {
                    'Authorization': `Bearer ${resToken.data.access_token}`,
                  },
                  timeout: 60 * 10 * 1000,
                  onUploadProgress: ProgressEvent => {
                    setLoad(
                      (ProgressEvent.loaded / ProgressEvent.total * 100),
                    )
                    if ((ProgressEvent.loaded / ProgressEvent.total * 100) === 100) {
                      setOpen(false)
                    }
                  }
                })
                  .then((res) => {
                    if (res.data.length === 0) return
                    axios.post('/api/items/add-item-trimble', {
                      user: props.user, folderName: props.project.folderName, folderId: props.project.folderId,
                      projectId: props.project.projectId, projectName: props.project.projectName, fileName: res.data[0].name,
                      trimbleFileId: res.data[0].id, trimbleVersionId: res.data[0].versionId, revisionNote
                    })
                      .then(res1 => {
                        console.log(res)
                        window.location.reload()
                      })

                  })
                  .catch(err => {
                    try {
                      message.error(err.response.data.errors.itemId)
                    }
                    catch { }
                    finally {
                      message.error('Upload file was failed')
                    }
                  })
              }
            })
            .catch(err => {
              message.warning(`Can't get token`)
            })
        } else {
          message.warning(`Please, setup trimble key`)
        }
      }

    }
  }
  const beforeUpload = (file) => {
    if (file.size > 1500 * 1000 * 1000) {
      message.warning('Cannot upload file larger 1.5GB')
    } else {
      let split = file.name.split('.')
      if (split[split.length - 1].toLowerCase() === 'ifc' || split[split.length - 1].toLowerCase() === 'ifczip') {
        if (!props.trimbleKey) {
          message.warning('Please, setup trimble key if you want upload to trimble connect')
          setIsTrimbleConnect(false)
        } else {
          setIsTrimbleConnect(true)
        }
        setTypeFile('rebar')
        setIsIFC(true)
      } else {
        setTypeFile('none')
        setIsIFC(false)
        setIsTrimbleConnect(false)
      }
      let index = _.findIndex(props.items, v => {
        return v.item.itemName === file.name
      })
      if (index >= 0) {
        setTypeFile(props.items[index].typeFile)
        console.log(props.items[index])
        setIsTrimbleConnect(props.items[index].isTrimble)
        setIsNewFile(false)
      } else {
        setIsNewFile(true)
      }
      setFile(file)
    }
    return false
  }
  const handleClickOpen = () => { setOpen(true); }
  const handleClose = () => { setOpen(false); }
  const handleChangeTypeFile = (value) => {
    setTypeFile(value)
  }
  const handleChangeTranslate = (e) => {
    setIsTranslate(e.target.checked)
  }
  const handleChangeRevisionNote = (e) => {
    if (revisionNote.length < 200)
      setRevisionNote(e.target.value)
    else
      message.warning('Revision note is limited 200 character')
  }
  const handleChangeTrimble = (e) => {
    setIsTrimbleConnect(e.target.checked)
  }
  const chunkSize = 38 * 1000 * 1000;
  const testUpload = async () => {
    try {
      setUploading(true)
      var numberofChunks = Math.ceil(file.size / chunkSize);
      let start = 0;
      let end = 0
      chunkCounter = 1
      let data = []
      while (chunkCounter <= numberofChunks) {
        chunkCounter++;
        console.log("created chunk: ", chunkCounter);
        end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        console.log("start " + start + "-" + end);
        start = end
        data.push(chunk)
      }

      let projectId = props.project.projectId.toLowerCase()
      let fileOriginalName = file.name
      let fileName = Date.now() + '-' + file.name
      let token = await getForgeTokenInternal()
      let urlGet = `https://developer.api.autodesk.com/oss/v2/buckets/${projectId}/objects/${fileName}/signeds3upload`
      let urlPost = `https://developer.api.autodesk.com/oss/v2/buckets/${projectId}/objects/${fileName}/signeds3upload`
      let res = await axios.get(urlGet, {
        params: { parts: numberofChunks ,minutesExpiration:60},
        headers: {
          'Authorization': `Bearer ${token.access_token}`,
        }
      })
      let upload = []
      _.forEach(res.data.urls, (v, k) => {
        upload.push(axios.put(v, data[k]))
      })
      let count = 0
      eachLimit(upload, 3,
        (task, callback) => {
          task.then((res) => {
            setLoad((count / numberofChunks * 95))
            count++
            callback()
          }, (err) => {
            console.log('error')
            console.log(err)
            callback(err)
          })
        },
        async (err) => {
          if (err) {
            console.log(err)
            chunkCounter = 1
            setUploading(false)
            return
          }
          let token = await getForgeTokenInternal()
          const item = await axios.post(urlPost,
            {
              uploadKey: res.data.uploadKey ,size: file.size
            },
            {
              headers: {
                'Authorization': `Bearer ${token.access_token}`,
                'Content-Type': 'application/json'
              },
            }
          )
          await axios.post('/api/items/add-item-client',
            {
              'folderName': props.project.folderName,
              'folderId': props.project.folderId,
              'projectId': props.project.projectId,
              'projectName': props.project.projectName,
              'revisionNote': revisionNote,
              'isTranslate': isTranslate,
              'typeFile': typeFile,
              'user': props.user,
              'object': item.data,
              'fileName': fileOriginalName
            },
          )
          chunkCounter = 1
          setLoad(0)
          setUploading(false)
          setOpen(false)
        })
    } catch {
      setUploading(false)
    }

  }

  return (
    <div className={classes.root}>
      <Fab aria-label={'Upload File'} className={classes.fab} color={'secondary'} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Upload File"}

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

            <Dragger {...handleUpload} disabled={uploading || loaded > 0} beforeUpload={beforeUpload} showUploadList={false}
              accept='.rvt,.nwc,.nwd,.nwf,.ifc,.dwf,.dwfx,.fbx,.dxf,.dwg,.skp,.ifczip,.pdf,.dgn'>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              {file ?
                <>
                  <p className="ant-upload-text">{`Current file: ${file.name}`}</p>
                  <p className="ant-upload-text" style={{ color: 'red' }}>Click or drag file to this area to re-upload</p>
                </>
                :
                <p className="ant-upload-text">Click or drag file to this area to upload</p>}
              <p className="ant-upload-hint">
                Support for .rvt,.nwc,.nwd,.nwf,.ifc,.dwf,.dwfx,.fbx,.dxf,.dwg,.skp,.ifczip,.pdf,.dgn format
              </p>
            </Dragger>
          </DialogContentText>
          <div>
            <Row gutter={[8, 8]}>
              <Col span={10}>
                <Text strong> Translate file after upload</Text>
              </Col>
              <Col span={14}>
                <Checkbox checked={isTranslate} onChange={handleChangeTranslate}
                  disabled={file === null ? true : isTrimbleConnect ? true : false} />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={10}>
                <Text strong> Model file type:</Text>
              </Col>
              <Col span={14}>
                <InputPicker block data={[
                  { key: 'ARCHITECTURAL', label: 'ARCHITECTURAL', value: 'architectural' },
                  { key: 'STRUCTURAL', label: 'STRUCTURAL', value: 'structural' },
                  { key: 'MEP', label: 'MEP', value: 'MEP' },
                  { key: 'TEMPORARY WORK', label: 'TEMPORARY WORK', value: 'temporaryWork' },
                  { key: 'CIVIL', label: 'CIVIL', value: 'civil' },
                  { key: 'REBAR', label: 'REBAR', value: 'rebar' },
                  { key: 'PRECAST', label: 'PRECAST', value: 'precast' },
                  { key: 'PPVC', label: 'PPVC', value: 'ppvc' },
                  { key: 'COORDINATION', label: 'COORDINATION', value: 'coordination' },
                  { key: 'DRONE DATA', label: 'DRONE DATA', value: 'droneData' },
                  { key: '4D', label: '4D', value: '4d' },
                  { key: '5D', label: '5D', value: '5d' },
                  { key: 'None', label: 'NONE', value: 'none' },
                ]} cleanable={false} size='xs' onChange={handleChangeTypeFile} value={typeFile} disabled={file === null} />
              </Col>
            </Row>
            {(isIFC && props.trimbleKey) && <Row gutter={[8, 8]}>
              <Col span={10}>
                <Text strong> Use Trimble Connect viewer</Text>
              </Col>
              <Col span={14}>
                <Checkbox checked={isTrimbleConnect} onChange={handleChangeTrimble} disabled={file === null ? true : isNewFile ? false : true} />
              </Col>
            </Row>}
            <Text strong> Revision note:</Text>
            <TextArea
              value={revisionNote} onChange={handleChangeRevisionNote} disabled={file === null}
              autoSize={{ minRows: 3 }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} type='default'>
            Cancel
          </Button>
          <Button onClick={testUpload.bind(this, file)} type="primary" disabled={file === null} loading={uploading || loaded > 0}>
            Upload
          </Button>
        </DialogActions>



        <Progress max="100" color="success" value={loaded} >{Math.round(loaded, 2)}%</Progress>
      </Dialog>
      {loaded !== 0 && < semantic.Progress percent={loaded} attached='top' color='red' />}
      <Toaster ref={toaster} position={Position.TOP} canEscapeKeyClear={false} >
        {/* <Toast 
           icon= "cloud-upload"
           message= {(
             <>
             <Text strong >data.progress </Text>
             <p/>
             <ProgressBar
               className={classNames("docs-toast-progress", {
                 [Classes.PROGRESS_NO_STRIPES]: 50 >= 100,
               })}
               intent={50 < 100 ? Intent.PRIMARY : Intent.SUCCESS}
               value={50/ 100}
             />
             </>
           )}
           timeout= {0}
        /> */}
      </Toaster>
    </div>
  );
}

ButtonUploadFile.propTypes = {
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(ButtonUploadFile)