import axios from "./axiosClient";

async function search(payload) {
  return await axios.get("/api/worker/worker-allocation", { params: payload });
}

async function searchAllSupervisor(payload) {
  return await axios.get("/api/worker/worker-allocation/all-supervisor", {
    params: payload,
  });
}

async function deletes(payload) {
  return await axios.delete("/api/worker/worker-allocation", { data: payload });
}

async function add(payload) {
  return await axios.post("/api/worker/worker-allocation", payload);
}

async function update(id, payload) {
  return await axios.put(`/api/worker/worker-allocation/${id}`, payload);
}

async function setSupervisorForWorker(id, payload) {
  return await axios.put(
    `/api/worker/worker-allocation/${id}/set-supervisor`,
    payload
  );
}

async function setTradeForWorker(id, payload) {
  return await axios.put(
    `/api/worker/worker-allocation/${id}/set-trade-for-worker`,
    payload
  );
}

async function setMultiSupervisorForWorker(payload) {
  return await axios.put(`/api/worker/worker-allocation/multi-supervisor`, {
    data: payload,
  });
}

async function getAllWorkerShifts(id, payload) {
  return await axios.get(
    `/api/worker/worker-allocation/${id}/all-worker-shifts-by-supervisor`,
    { params: payload }
  );
}

async function getSubmitStatusForRangeDays(payload) {
  return await axios.get(`/api/worker/worker-allocation/get-submit-status`, {
    params: payload,
  });
}

async function getAllShiftsByAllocations(payload) {
  return await axios.post(
    `/api/worker/worker-shift/all-shifts-by-allocations`,
    payload
  );
}

async function updateShiftsToWorker(payload) {
  return await axios.put(`/api/worker/worker-shift/update-shifts-to-worker`, {
    data: payload,
  });
}

async function setSummits(payload, isSubmit) {
  return await axios.post(
    `/api/worker/worker-allocation/set-summits?isSubmit=${isSubmit}`,
    { data: payload }
  );
}

async function updateIsWorkOverLunch(payload) {
  return await axios.put(
    `/api/worker/worker-allocation/update-is-work-over-lunch`,
    payload
  );
}
async function updateIsDayOff(payload) {
  return await axios.put(
    `/api/worker/worker-allocation/update-is-day-off`,
    payload
  );
}

export {
  setSummits,
  search,
  deletes,
  add,
  update,
  searchAllSupervisor,
  setSupervisorForWorker,
  setMultiSupervisorForWorker,
  getSubmitStatusForRangeDays,
  getAllShiftsByAllocations,
  setTradeForWorker,
  updateShiftsToWorker,
  updateIsWorkOverLunch,
  updateIsDayOff,
};
