import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Row, Col, Input, Modal, Upload, Button, message, Form, Tooltip, Divider } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { uploadFileByPutLink } from '../../../../functions/GeneralFunction';
import { v4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';


const { Option } = Select;
const getRouteToNode = (rowNode) => {
  if (!rowNode.parent) {
      return [];
  }
  return [
      ...getRouteToNode(rowNode.parent),
      rowNode.key ? rowNode.key : rowNode.data.employeeName,
  ];
};
const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';

export function ModalUploadFileSubUpD({ pathDir, file, setClose, setIsLoading, ...props }) {

  const [gridApi, setGridApi] = useState()
  const [files, setFiles] = useState([])




  const handleOk = async (e) => {
    uploadFile()
  }
  const handleUploadFile = (info) => {
    if (info.file !== null) {
      if (info.file.percent < 101) {
        return;
      }
      if (info.file.percent === undefined) {
        if (files.length >= 10) {
          message.warning('Maximum is 10 files')
        }
        let clone = [...files]
        let index = _.findIndex(clone, v => v.fileName === info.file.name)
        if (index >= 0) {
          clone[index].file = info.file
        } else {
          clone.push({ fileName: info.file.name, remark: '', file: info.file })
        }
        setFiles(clone)
      }
    }
  }
  console.log(file)
  const uploadFile = async () => {
    if (files.length === 0) return
    setIsLoading(true)
  
    for (let i in files) {
      try {
        const res = await axios({
          url: '/api/subcon-upload-file/addFile',
          method: 'post',
          data: {
            remark: files[i].remark,
            name: files[i].fileName,
            token: props.token,
            projectId: props.storeInitProject.projectId,
            _id: file._id,
            size: files[i].file.size
          }
        })
        await uploadFileByPutLink(`${pathDir}/${file._id}/${res.data.version._id}/${files[i].fileName}`, files[i].file.type, typeBucketName, files[i].file)
        // const route = getRouteToNode(file)
        // gridApi.api.applyServerSideTransaction({
        //     route: route.slice(0, route.length - 1),
        //     add: [{...res.data.file, versionId: res.data.version._id, size:res.data.version.size, size }]
        // })

      } catch (ex) {
        message.warning(`Cannot upload file ${files[i].fileName}`)
      }
      message.info(`Upload file ${_.toNumber(i) + 1}/${files.length}`)
    }
    // handleCancel()
    window.location.reload()
    setTimeout(() => {
      setIsLoading(false)
    }, 5000);
  }


  const handleCancel = () => {
    setClose()
  }


  const beforeUpload = (file) => {
    if (file.size > 200 * 1000 * 1000) {
      message.warning('Cannot upload file larger 200MB')
      return false
    }
    return true
  }

  const handleDeleteFile = (e) => {
    if (!window.confirm('Are you want to delete?')) return
    let clone = [...files]
    let index = _.findIndex(clone, v => { return v.fileName == e.fileName })
    clone.splice(index, 1)
    setFiles(clone)
  }



  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: false,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
    };
  }, []);
  const columnDefs = [
    { field: 'fileName', headerName: 'Name', width: 100, minWidth: 200, },
    {
      field: 'remark', headerName: 'Remark', editable: true, wrapText: true,
      autoHeight: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellStyle: params => {
        return { whiteSpace: 'break-spaces', lineHeight: '25px' };
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      lockPosition: 'right',
      suppressMovable: true, minWidth: 100, maxWidth: 100,
      cellRendererSelector: params => {
        const isGroup = params.node.group;
        if (!isGroup) {
          return {
            component: handleAction,
          }
        }
        return undefined;
      }
    },
  ]
  const handleAction = (params) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
        <Button size='small' type='danger' onClick={handleDeleteFile.bind(this, params.data)}
        >Delete</Button>
      </div>
    )
  }
  const onGridReady = useCallback((params) => {
    setGridApi(params)
  }, []);
  return (
    <Modal title="Upload File" visible={true} onOk={handleOk} onCancel={handleCancel} width={'80%'}>

      <p />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 25, alignItems: 'center' }}  >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }} >
          <div style={{ display: 'flex', gap: 5 }} >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }} >
              {/* <span style={{ fontWeight: 'bold' }} >Select file to upload</span> */}
              <Upload customRequest={handleUploadFile} showUploadList={false} beforeUpload={beforeUpload} style={{ width: '100%' }} multiple >
                <Button type='primary' style={{ width: '100%' }}
                >Select file to upload</Button>
              </Upload>
            </div>
          </div>
        </div>
      </div>
      <p />


      <div className='dms ag-theme-alpine' style={{
        height: '500px',
        width: '100%',
      }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={files}
          columnDefs={columnDefs}
          // rowHeight={35}
          onGridReady={onGridReady}
          groupDisplayType='multipleColumns'
          groupDefaultExpanded={3}
          suppressContextMenu={true}
        ></AgGridReact>


      </div>



    </Modal>
  );
}


