import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { Button, notification, Spin } from 'antd';

import { HotTable } from '@handsontable/react';

import { search, update } from '../../api/unitType';

let columns = [
  { data: 'name', width: 125 },
  { data: 'noOfBedRoom', width: 200, type: 'numeric', allowEmpty: false },
  { data: 'noOfSample', width: 200, type: 'numeric', allowEmpty: false },
  { data: 'area', width: 50, type: 'numeric', allowEmpty: false },
];

function TableUnitType(props) {
  const [fileExcel, setFileExcel] = useState(null);
  const [hotData, setHotData] = useState([]);
  const [removeRowIds, setRemoveRowIds] = useState([]);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [loadingTip, setLoadingTip] = useState('');
  const hotTableRef = React.createRef();

  const [columnHeaders, setColumnHeaders] = useState([
    ['Unit Type', 'No Of Bed Room', 'No Of Sample', 'Area'],
  ]);

  const [dataScheme, setDataScheme] = useState({
    name: 'sample',
    noOfBedRoom: 2,
    noOfSample: 2,
    area: 50,
  });

  const contextMenuHotTable = {
    items: {
      row_above: {
        name: 'Insert rows above',
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt('How many rows you want to add', '1');
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: uuidv4(),
                    projectId: props.projectId,
                    isAdd: true,
                  };
                  let copy = Object.assign({}, item);
                  copy.row_status = 'add';
                  temp.splice(selection[selection.length - 1].end.row, 0, item);
                  start++;
                }
                setHotData(temp);
              }
            }
          }
        },
      },
      row_below: {
        name: 'Insert rows below',
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt('How many rows you want to add', '1');
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    projectId: props.projectId,
                    isAdd: true,
                  };

                  let copy = Object.assign({}, item);
                  copy.row_status = 'add';
                  temp.splice(
                    selection[selection.length - 1].end.row + start,
                    0,
                    item
                  );
                  start++;
                }

                setHotData(temp);
              }
            }
          }
        },
      },
      remove_row: {
        name: 'Remove rows',
        disabled: () => {
          return false;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            if (!window.confirm('Are you want to delete it')) return;

            let indexs = [];
            _.forEach(selection, (v) => {
              let start = v.start.row;
              while (start <= v.end.row) {
                indexs.push(start);
                start++;
              }
            });

            let temp = hotData.slice(0);

            let tempData = temp
              .slice(0)
              .filter((x, index) => !indexs.includes(index));

            let removeIds = temp
              .slice(0)
              .filter((x, index) => indexs.includes(index))
              .map((x) => x.id);

            setRemoveRowIds([...removeRowIds, ...removeIds]);

            setHotData(tempData);
          }
        },
      },
    },
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setIsLoadingSpin(true);
    setLoadingTip('Refreshing table...');

    let res = await search({
      filterBy: '',
      pageIndex: 1,
      pageSize: 10000,
      projectId: props.projectId,
    });

    let list = [];
    if (res.data.items.length > 0) {
      list = res.data.items.map((x) => ({
        ...x,
        isUpdate: true,
      }));
    } else {
      list = [
        {
          id: uuidv4(),
          name: 'A1',
          noOfBedRoom: 2,
          noOfSample: 2,
          area: 50,
        },
      ];
    }

    setHotData(list);
    setIsLoadingSpin(false);
    setLoadingTip('');
  }

  function getNumber(number) {
    if (_.isNumber(number)) {
      return number;
    }

    return 0;
  }

  async function handleSave() {
    let temp = hotData.map((x) => ({
      ...x,
    }));

    let payload = {
      removeRowIds,
      adds: temp
        .filter((x) => !x.isUpdate)
        .map((x) => ({
          id: x.id,
          area: getNumber(x.area),
          noOfBedRoom: getNumber(x.noOfBedRoom),
          noOfSample: getNumber(x.noOfSample),
          name: x.name,
          projectId: props.projectId,
        })),
      updates: temp
        .filter((x) => x.isUpdate)
        .map((x) => ({
          id: x.id,
          area: getNumber(x.area),
          noOfBedRoom: getNumber(x.noOfBedRoom),
          noOfSample: getNumber(x.noOfSample),
          name: x.name,
          projectId: props.projectId,
        })),
    };

    payload.adds.forEach((add) => {
      delete add.isUpdate;
      delete add.isAdd;
    });

    payload.updates.forEach((add) => {
      delete add.isUpdate;
      delete add.isAdd;
    });

    setIsLoadingSpin(true);
    setLoadingTip('Save Table...');
    await update(payload);

    await getData();

    setIsLoadingSpin(false);
  }

  async function handleExport() {
    const workbook = new ExcelJS.Workbook();
    let excelName = 'Tracking Concrete Rebar Quantity';
    const worksheet = workbook.addWorksheet('Tracking');

    let row7 = worksheet.getRow(7);
    row7.height = 30;

    for (let columnIndex = 1; columnIndex < 12; columnIndex++) {
      worksheet.getColumn(columnIndex).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    }

    worksheet.getCell('A2').value =
      'Project : ' + props.location.state.projectName;
    worksheet.getCell('A7').value = 'Area/Block';
    worksheet.getCell('B7').value = 'Item';
    worksheet.getCell('C7').value = 'Description';
    worksheet.getCell('D7').value = 'Concrete';
    worksheet.getCell('E7').value = 'Rebar';
    worksheet.getCell('F7').value = 'Ratio';
    worksheet.getCell('G7').value = 'Mesh';
    worksheet.getCell('H7').value = 'Concrete';
    worksheet.getCell('I7').value = 'Rebar';
    worksheet.getCell('J7').value = 'Ratio';
    worksheet.getCell('K7').value = 'Mesh';

    worksheet.getCell('D8').value = 'm3';
    worksheet.getCell('E8').value = 'kg';
    worksheet.getCell('F8').value = 'kg/m3';
    worksheet.getCell('G8').value = 'kg';
    worksheet.getCell('H8').value = 'm3';
    worksheet.getCell('I8').value = 'kg';
    worksheet.getCell('J8').value = 'kg/m3';
    worksheet.getCell('K8').value = 'kg';

    let borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    let i = 9;

    let gByLevel = _.groupBy(
      hotData.filter((x) => !x.isParrent),
      'level'
    );

    let keys = Object.keys(gByLevel);
    keys.sort();
    keys.forEach((key) => {
      let items = gByLevel[key];
      items.forEach((item) => {
        worksheet.getCell(`A${i}`).value = item.block;
        worksheet.getCell(`B${i}`).value = item.level;
        worksheet.getCell(`C${i}`).value = item.description;
        worksheet.getCell(`D${i}`).value = item.concreteVolume;
        worksheet.getCell(`E${i}`).value = item.rebarQuantity;
        worksheet.getCell(`F${i}`).value = item.ratio;
        worksheet.getCell(`G${i}`).value = item.mesh;
        worksheet.getCell(`H${i}`).value = item.concreteVolumeActual;
        worksheet.getCell(`I${i}`).value = item.rebarQuantityActual;
        worksheet.getCell(`J${i}`).value = item.ratioActual;
        worksheet.getCell(`K${i}`).value = item.meshActual;
        i++;
      });
      //Summary row
      worksheet.getCell(`C${i}`).value = 'Sub-Total';
      worksheet.getCell(`D${i}`).value = _.sumBy(items, 'concreteVolume');
      worksheet.getCell(`E${i}`).value = _.sumBy(items, 'rebarQuantity');
      worksheet.getCell(`F${i}`).value = _.sumBy(items, 'ratio');
      worksheet.getCell(`G${i}`).value = _.sumBy(items, 'mesh');
      worksheet.getCell(`H${i}`).value = _.sumBy(items, 'concreteVolumeActual');
      worksheet.getCell(`I${i}`).value = _.sumBy(items, 'rebarQuantityActual');
      worksheet.getCell(`J${i}`).value = _.sumBy(items, 'ratioActual');
      worksheet.getCell(`K${i}`).value = _.sumBy(items, 'meshActual');

      for (var columnIndex = 1; columnIndex < 12; columnIndex++) {
        worksheet.getRow(i).getCell(columnIndex).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c5d0e0' },
        };
      }

      i++;
    });

    //Grand total

    let dataFiltered = hotData.filter((x) => !x.isParrent);
    worksheet.getCell(`C${i}`).value = 'Grand Total Qty';
    worksheet.getCell(`D${i}`).value = _.sumBy(dataFiltered, 'concreteVolume');
    worksheet.getCell(`E${i}`).value = _.sumBy(dataFiltered, 'rebarQuantity');
    worksheet.getCell(`F${i}`).value = _.sumBy(dataFiltered, 'ratio');
    worksheet.getCell(`G${i}`).value = _.sumBy(dataFiltered, 'mesh');
    worksheet.getCell(`H${i}`).value = _.sumBy(
      dataFiltered,
      'concreteVolumeActual'
    );
    worksheet.getCell(`I${i}`).value = _.sumBy(
      dataFiltered,
      'rebarQuantityActual'
    );
    worksheet.getCell(`J${i}`).value = _.sumBy(dataFiltered, 'ratioActual');
    worksheet.getCell(`K${i}`).value = _.sumBy(dataFiltered, 'meshActual');

    for (var columnIndex = 1; columnIndex < 12; columnIndex++) {
      worksheet.getRow(i).getCell(columnIndex).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC500' },
      };
    }

    for (let index = 7; index <= i; index++) {
      for (let j = 1; j < 12; j++) {
        worksheet.getCell(index, j).border = borderStyles;
      }
    }

    worksheet.getColumn('A').width = 20;
    worksheet.getColumn('B').width = 20;
    worksheet.getColumn('C').width = 30;
    worksheet.getColumn('D').width = 10;
    worksheet.getColumn('E').width = 10;
    worksheet.getColumn('F').width = 10;
    worksheet.getColumn('G').width = 10;
    worksheet.getColumn('H').width = 10;
    worksheet.getColumn('I').width = 10;
    worksheet.getColumn('J').width = 10;
    worksheet.getColumn('K').width = 10;
    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + '.xlsx');
  }

  const hotSettings = {
    colHeaders: true,
    rowHeaders: true,
    rowHeights: 40,
    rowHeaderWidth: 40,
    columnHeaderHeight: 51,
    height: 750,
    columns: columns,
    nestedHeaders: columnHeaders,
    stretchH: 'all',
    // multiColumnSorting: true,
    currentRowClassName: 'currentRow',
    dropdownMenu: [
      'filter_by_condition',
      'filter_by_value',
      'filter_action_bar',
    ],
    filters: true,
    search: true,
    licenseKey: 'non-commercial-and-evaluation',
  };

  return (
    <div>
      <div className='mb-3 mt-2 ml-2'>
        <Button type='primary' className='mr-2' onClick={handleSave}>
          Save
        </Button>

        <Button type='primary' className='mr-2' onClick={handleSave}>
          Export Excel
        </Button>
      </div>

      <Spin tip={loadingTip} size='large' spinning={isLoadingSpin}>
        <HotTable
          height={500}
          ref={hotTableRef}
          data={hotData}
          settings={hotSettings}
          dataSchema={dataScheme}
          contextMenu={contextMenuHotTable}
        />
      </Spin>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  };
}

export default connect(mapStateToProps)(TableUnitType);
