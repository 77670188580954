/* eslint-disable */
import React from 'react';
import $ from 'jquery';
import _ from 'lodash'
import moment from 'moment';
import axios from 'axios'
import { TagPicker, Icon, Button, Checkbox, IconButton, Modal, Input, Divider, Tag } from 'rsuite';

import { message, Tabs, Typography } from 'antd';
import { Header, Dropdown } from 'semantic-ui-react'
import Dock from 'react-dock'
import { TableSchedule } from '../tables'
import {
  sortTagGroup
} from '../function/TableFunction'
import {
  ColumnSchedule,
} from '../columns'
import {
  getAllModelsElementdbIdsWithCondition
} from '../function/ForgeFunction'
import {
  GetElementAndParameterRvt, listElementTableRVT,
  listElementTableDWFX, GetElementAndParameterDWFX,
  getListParameter, getListParameterMultiCategory
} from '../function/ScheduleFunction'
import Tooltip from '@material-ui/core/Tooltip';
import { DraggableAreasGroup } from 'react-draggable-tags'
// import '../scss/SideBar.scss'
import {
  workerInstance, trackingUserUsing
} from '../function/AdminFunction'
import { Scrollbars } from 'react-custom-scrollbars';
const { TabPane } = Tabs;
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { Text, Title } = Typography
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}

class SideBarViewPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCategory: false,
      loading: false,
      listCategory: [],
      listParameter: [],
      indeterminate: false,
      checkAll: false,

      value: [],

      listCategorySelected: [],
      listParameterSelected: [],


      displayPanelSchedule: false,
      columnsPanelSchedule: [],
      dataPanelSchedule: [],
      keyTab: '1',
      heightDock: 500,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,
      heightNavigation: this.props.heightNavigation,

      checkCombineParameter: true,
      listFullData: [],
      activeButtonIsolateRows: false,

      openPanelSaveSchedule: false,
      groupSchedule: '',
      saveScheduleName: '',
      lodingSaveSchedule: false,
      tableGroupBy: [],
    };
    this.listParameterSelectedId = []
    this.dataGeneralTable = []
    this.dataGeneralTableGroup = {}
    this.storeParameterUnits = {}

    this.allDbIds = []
    this.allValue = null;
    this.tempListModel = {}
    this.valueParameter = [];
    this.preventDoubleClick = true
    this.disHeight = 0
  }

  componentDidMount = () => {

  }
  componentWillReceiveProps = (nextProps) => {
    if (this.state.displayPanelSchedule) {

      window.onresize = this.changeSizeScreen
      if (nextProps.heightNavigation !== this.props.heightNavigation)
        this.setState({
          maxForgeViewer: this.state.maxForgeViewer + (nextProps.heightNavigation === '0px' ? 53.78 : -53.78),
        }, () => {
          $('#splitForgeViewer').height(this.state.maxForgeViewer - this.state.heightDock)
        })
    }
    if (nextProps.displayPanel) {
      trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'Extract Quantities')
      this.preventDoubleClick = true
    }
  }
  changeSizeScreen = () => { //!
    let _this = this
    var maxHeight = window.screen.height,
      maxWidth = window.screen.width,
      curHeight = window.innerHeight,
      curWidth = window.innerWidth;

    if (maxWidth === curWidth && maxHeight === curHeight) {
      if (_this.state.displayPanelSchedule) {
        this.disHeight = maxHeight - 53.78 - _this.state.maxForgeViewer
        _this.setState({
          maxForgeViewer: maxHeight - 53.78,
          modifyForgeViewer: $('#splitForgeViewer').height($('#splitForgeViewer').height() + this.disHeight)
        }, () => {
          $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
          _this.props.viewer.resize()
        })
      }
    } else {
      if (_this.state.displayPanelSchedule) {
        _this.setState({
          maxForgeViewer: curHeight - 53.78,
          modifyForgeViewer: $('#splitForgeViewer').height($('#splitForgeViewer').height() - this.disHeight)
        }, () => {
          $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
          _this.props.viewer.resize()
        })
      }
    }
  }
  handleChangeCategory = async (value) => {
    try {
      let split = this.props.itemName.split(".")
      let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3']
      this.listParameterSelectedId = []
      this.setState({
        listCategorySelected: value === null ? [] : value,
        listParameterSelected: [],
        listParameter: [],
        indeterminate: false,
        checkAll: false,
      });
      this.valueParameter = value
      var temp = await getListParameter(value, split, tempUnit)
      if (value.length === 1) { //one category
        this.allValue = temp.map(item => item.value)
        this.setState({ listParameter: temp })
      }
      else { //more one category
        let combineParameter = []
        var n = 0
        value.forEach(item => {
          combineParameter[n] = []
          item.property.forEach(i => {
            combineParameter[n].push(i.displayName)
          })
          n++
        })
        var flatten = _.flatten(combineParameter, true)
        var intersection = null;
        if (this.state.checkCombineParameter)
          intersection = _.filter(flatten, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
        else
          intersection = _.uniq(flatten);
        var tempToValue = {}
        value.forEach(item => {
          combineParameter[item] = []
          item.property.forEach(i => {
            if (_.includes(intersection, i.displayName)) {
              if (!tempToValue[i.displayName])
                tempToValue[i.displayName] = i
            }
          })
        })

        var tempToCheck = _.values(tempToValue)
        var tempDupicate = await getListParameterMultiCategory(tempToCheck, split, tempUnit)

        this.allValue = tempDupicate.map(item => item.value)
        this.setState({ listParameter: tempDupicate, })
      }
    }
    catch { }
  }
  handleChangeParameter = (value) => {
    this.listParameterSelectedId = []
    var temp = []
    if (value === null) {
      this.setState({ listParameterSelected: [] })
    }
    else if (value !== null) {
      value.forEach(item => {
        this.listParameterSelectedId.push(item.displayName)
        temp.push({ id: item.displayName, displayName: item.displayName, units: item.units })
      })
      this.setState({
        listParameterSelected: value,
        indeterminate: value.length > 0 && value.length < this.allValue.length,
        checkAll: value.length === this.allValue.length,
      });
    }

  }
  handleCheckAll = (value, checked) => {
    const nextValue = checked ? this.allValue : [];
    this.listParameterSelectedId = []
    _.forEach(nextValue, v => {
      this.listParameterSelectedId.push(v.displayName)
    })
    this.setState({
      listParameterSelected: nextValue,
      indeterminate: false,
      checkAll: checked,
    });

  }
  openSchedule = async () => {
    // var canvas = this.props.viewer.canvas;
    if (Object.keys(this.listParameterSelectedId).length !== 0) {
      if (this.preventDoubleClick) {
        this.preventDoubleClick = false
        // console.log('ads')
        this.setState({ loading: true }, async () => {
          this.listElementTable(this.allDbIds, this.generateTable)
        })
      }
    } else {
      message.error('You have to least one parameter');
    }
  }

  listElementTable = async (allDbIds) => {
    let split = this.props.itemName.split(".")
    let tempData = null;
    let tempCategoryId = []
    _.forEach(this.state.listCategorySelected, parameter => {
      tempCategoryId.push(parameter.id)
    })
    if (split[split.length - 1].toLowerCase() === 'rvt' || split[split.length - 1].toLowerCase() === 'ifc'|| split[split.length - 1].toLowerCase() === 'nwc'|| split[split.length - 1].toLowerCase() === 'nwd') {
      tempData = await listElementTableRVT(allDbIds, tempCategoryId, this.listParameterSelectedId)
    } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
      tempData = await listElementTableDWFX(allDbIds, tempCategoryId, this.listParameterSelectedId)
    }
    this.dataGeneralTableGroup = tempData.dataGeneralTableGroup
    this.storeParameterUnits = tempData.storeParameterUnits
    workerInstance.addEventListener('message', this.callbackWebWorker)
    workerInstance.addEventListener('error', this.callbackFailWebWorker)
    workerInstance.generateTable(this.dataGeneralTableGroup, this.storeParameterUnits, this.listParameterSelectedId, this.state.listParameterSelected)

  }


  callbackFailWebWorker = () => {
    workerInstance.removeEventListener('message', this.callbackWebWorker)
    message.error('Load Schedule failed')
    this.setState({ loading: false })
  }
  callbackWebWorker = (e) => {
    workerInstance.removeEventListener('message', this.callbackWebWorker)
    if (Object.keys(e.data).length === 0) {
      message.error('Load Schedule failed')
      this.setState({ loading: false })
      return
    }
    let columns = ColumnSchedule(e.data.listParameterSort)
    this.setState({
      columnsPanelSchedule: columns, dataPanelSchedule: e.data.dataPanelSchedule, listFullData: e.data.listFullData,
      activeButtonIsolateRows: false,
      loading: false,
      displayPanelSchedule: true,
      maxForgeViewer: $('#splitForgeViewer').height(),
      modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock
    }, () => {
      $('#splitForgeViewer').height(this.state.modifyForgeViewer)
      this.props.viewer.resize()
      this.props.onDisplayPanelChange('selectParameterPanel', false)
      this.props.onDisabledBtnChange('btnSelectParameter', true)
    })
    _.forEach(this.tempListModel, (v) => {
      this.props.viewer.clearThemingColors(v);
    })
  }

  handleChangeTab = (key) => {
    if (key === '1') {
      this.handleAllElement()
    } else if (key === '2') {
      this.handleActiveElement()
    } else if (key === '3') {
      this.handleSelectedElement()
    }
    this.setState({ keyTab: key, listCategorySelected: [], listParameterSelected: [] })
  }
  handleAllElement = () => {
    this.setState({
      loadingCategory: true,
      listCategory: [],
      listParameter: [],
      listCategorySelected: [],
      listParameterSelected: []
    });
    this.tempListModel = {}
    this.allDbIds = []
    let split = this.props.itemName.split(".")
    let extension = split[split.length - 1].toLowerCase()

    const models = this.props.viewer.impl.modelQueue().getModels();
    _.forEach(models, (model) => {
      let instanceTree = model.getData().instanceTree;
      let temp = getAllModelsElementdbIdsWithCondition(instanceTree, this.props.viewer, model, 'all');
      if (extension === 'nwc' || extension === 'nwd' || extension === 'nwf') {
        let temp1 = []
        _.forEach(temp, v => {
          temp1.push(instanceTree.getNodeParentId(v))
        })
        temp = temp1
      }
      this.allDbIds.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
    })
    this.handleGetAllCategoryAndParameter()

  }
  handleActiveElement = () => {
    this.setState({
      loadingCategory: true,
      listParameter: [],
      listCategory: [],
      listCategorySelected: [],
      listParameterSelected: []
    });
    this.tempListModel = {}
    this.allDbIds = []
    let split = this.props.itemName.split(".")
    let extension = split[split.length - 1].toLowerCase()

    const models = this.props.viewer.impl.modelQueue().getModels();
    _.forEach(models, (model) => {
      let instanceTree = model.getData().instanceTree;
      let temp = getAllModelsElementdbIdsWithCondition(instanceTree, this.props.viewer, model, 'active');
      if (extension === 'nwc' || extension === 'nwd' || extension === 'nwf') {
        let temp1 = []
        _.forEach(temp, v => {
          temp1.push(instanceTree.getNodeParentId(v))
        })
        temp = temp1
      }
      this.allDbIds.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
    })
    this.handleGetAllCategoryAndParameter()

  }
  handleSelectedElement = () => {
    this.setState({
      loadingCategory: true,
      listParameter: [],
      listCategory: [],
      listCategorySelected: [],
      listParameterSelected: []
    });
    this.tempListModel = {}
    this.allDbIds = []
    let split = this.props.itemName.split(".")
    let extension = split[split.length - 1].toLowerCase()

    var DBids = this.props.viewer.impl.selector.getAggregateSelection();
    if (DBids.length !== 0) {
      const models = this.props.viewer.impl.modelQueue().getModels();
      _.forEach(models, (model, index) => {
        let instanceTree = model.getData().instanceTree;
        if (extension === 'nwc' || extension === 'nwd' || extension === 'nwf') {
          this.allDbIds.push({ dbIds: instanceTree.getNodeParentId(DBids[index].selection), model: model, modelName: model.myData.loadOptions.modelNameOverride })
        }else{
          this.allDbIds.push({ dbIds: DBids[index].selection, model: model, modelName: model.myData.loadOptions.modelNameOverride })
        }

      })
      this.handleGetAllCategoryAndParameter()
    } else {
      this.setState({ loadingCategory: false })
    }
  }
  handleGetAllCategoryAndParameter = async () => {
    let count = 0;
    this.allDbIds.forEach((item) => {
      count = count + item.dbIds.length
      this.tempListModel[item.modelName] = item.model
    })
    let split = this.props.itemName.split(".")
    if (split[split.length - 1].toLowerCase() === 'rvt' || split[split.length - 1].toLowerCase() === 'ifc' || split[split.length - 1].toLowerCase() === 'nwc'|| split[split.length - 1].toLowerCase() === 'nwd') {
      let listTempCategory = await GetElementAndParameterRvt(this.allDbIds, count)
      this.setState({ loadingCategory: false, listCategory: listTempCategory })
    } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
      let listTempCategory = await GetElementAndParameterDWFX(this.allDbIds, count)
      this.setState({ loadingCategory: false, listCategory: listTempCategory })
    }
  }

  onActiveTable = () => {
    let _this = this
    this.setState({
      displayPanelSchedule: !this.state.displayPanelSchedule,
    }, () => {
      if (!_this.state.displayPanelSchedule) {
        $('#splitForgeViewer').height('calc(100% - 53.78px)')
        _this.props.viewer.resize()
      }
      this.props.onDisplayPanelChange('selectParameterPanel', true)
      this.props.onDisabledBtnChange('btnSelectParameter', false)
    })

  }
  onChangeHeader = (id, text) => {
    let temp = []
    _.forEach(this.state.columnsPanelSchedule, v => {
      if (id == v.id) {
        v.filter = text
        temp.push(v)
      } else {
        temp.push(v)
      }
    })
    this.setState({ columnsPanelSchedule: temp })
    // console.log(this.state.columnsPanelSchedule)
  }
  onIsolateRows = (data) => {
    let temp = []
    if (!this.state.activeButtonIsolateRows) {
      _.forEach(this.state.dataPanelSchedule, v => {
        if (data.includes(v.ElementId))
          temp.push(v)
      })
      this.setState({ dataPanelSchedule: temp, activeButtonIsolateRows: true })
    } else {
      this.setState({ dataPanelSchedule: this.state.listFullData, activeButtonIsolateRows: false })
    }

  }
  sizeChange = (e) => {
    if (this.state.displayPanelSchedule) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
      })
    }
  }
  refreshParameter = () => {
    var _this = this
    if (_this.state.keyTab === '1') {
      _this.handleAllElement()
    } else if (_this.state.keyTab === '2') {
      _this.handleActiveElement()
    } else if (_this.state.keyTab === '3') {
      _this.handleSelectedElement()
    }
  }
  handleCloseSidebar = () => {
    this.setState({
      listCategorySelected: [],
      listParameterSelected: [],
      loading: false,
      loadingCategory: false,
      // listCategory: [],
    }, () => {
      this.props.onDisplayPanelChange('selectParameterPanel', false)
    })

  }
  handleCombineParameter = (value, checked) => {
    try {
      let split = this.props.itemName.split(".")
      let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3']
      this.listParameterSelectedId = []
      this.setState({
        listParameterSelected: [],
        listParameter: [],
        value: [],
        indeterminate: false,
        checkAll: false,
        checkCombineParameter: checked
      }, () => {
        let combineParameter = []
        var n = 0
        this.valueParameter.forEach(item => {
          combineParameter[n] = []
          item.property.forEach(i => {
            combineParameter[n].push(i.displayName)
          })
          n++
        })
        _.forEach(combineParameter, (v, k) => {
          combineParameter[k] = _.uniq(v);
        })

        var flatten = _.flatten(combineParameter, true)

        let intersection = null
        if (this.state.checkCombineParameter)
          intersection = _.filter(flatten, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
        else
          intersection = _.uniq(flatten);

        var tempToValue = {}
        this.valueParameter.forEach(item => {
          combineParameter[item] = []
          item.property.forEach(i => {
            if (_.includes(intersection, i.displayName)) {
              if (!tempToValue[i.displayName])
                tempToValue[i.displayName] = i
            }
          })
        })

        var tempToCheck = _.values(tempToValue)
        var tempDupicate = []

        for (let i = 0; i < tempToCheck.length - 1; i++) {
          if (tempToCheck[i].displayName !== 'Category' && tempToCheck[i].displayName !== 'CategoryId'
            && tempToCheck[i].displayName !== 'viewable_in' && tempToCheck[i].displayName !== 'parent' && tempToCheck[i].displayCategory !== '__internalref__'
            && tempToCheck[i].displayName !== 'label') {
            if (split[split.length - 1].toLowerCase() === 'rvt' || split[split.length - 1].toLowerCase() === 'ifc'|| split[split.length - 1].toLowerCase() === 'nwc'|| split[split.length - 1].toLowerCase() === 'nwd') {
              tempDupicate.push({
                label: tempToCheck[i].displayName, value: { displayName: tempToCheck[i].displayName, units: tempToCheck[i].units }
                , group: tempToCheck[i].displayCategory
              })
            } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
              if (tempToCheck[i].units !== null) {
                tempDupicate.push({
                  label: tempToCheck[i].displayName, value: { displayName: tempToCheck[i].displayName, units: tempToCheck[i].units }
                  , group: tempToCheck[i].displayCategory
                })
              } else {
                let splitValue = tempToCheck[i].displayValue.split(" ")
                if (splitValue.length === 2) {
                  if (tempUnit.includes(splitValue[1])) {
                    tempDupicate.push({
                      label: tempToCheck[i].displayName, value: { displayName: tempToCheck[i].displayName, units: splitValue[1] }
                      , group: tempToCheck[i].displayCategory
                    })
                  } else {
                    tempDupicate.push({
                      label: tempToCheck[i].displayName, value: { displayName: tempToCheck[i].displayName, units: '' }
                      , group: tempToCheck[i].displayCategory
                    })
                  }
                } else {
                  tempDupicate.push({
                    label: tempToCheck[i].displayName, value: { displayName: tempToCheck[i].displayName, units: '' },
                    group: tempToCheck[i].displayCategory
                  })
                }
              }
            }
          }
        }
        this.allValue = tempDupicate.map(item => item.value)
        this.setState({
          listParameter: tempDupicate,
        })
      })
    }
    catch { }
  }
  handleClosePanel = () => {
    this.setState({
      displayPanelSchedule: true,
      maxForgeViewer: $('#splitForgeViewer').height(),
      modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock,
      openPanelSaveSchedule: false
    }, () => {
      $('#splitForgeViewer').height('calc(100% - 53.78px)')
      this.props.viewer.resize()
    })
  }
  //#region  saveschedule
  handleSaveSchedule = () => {
    if (this.state.saveScheduleName === '') return;
    let _this = this
    this.setState({ lodingSaveSchedule: true })
    let id = btoa(guid() + this.props.email)
    let today = moment(Date.now()).format('DD-MM-YYYY')
    let node = this.props.viewer.impl.model.getDocumentNode()
    let tempCategoryId = []
    _.forEach(this.state.listCategorySelected, parameter => {
      tempCategoryId.push(parameter.id)
    })
    var obj = {
      category: tempCategoryId,
      parameter: this.listParameterSelectedId,
      parameterSelected: this.state.listParameterSelected,
      groupBy: this.state.tableGroupBy,
      viewPoint: this.props.viewer.getState()
    }
    let table = JSON.stringify(obj)

    var screenshot = new Image();
    screenshot.src = this.props.viewer.canvas.toDataURL("image/jpg");
    screenshot.onload = function () {
      var canvas = document.getElementById('snapshot');
      canvas.width = _this.props.viewer.canvas.clientWidth;
      canvas.height = _this.props.viewer.canvas.clientHeight;
      var ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);
      try {
        _this.props.markupCore.renderToCanvas(ctx, function () {
          var myImage = canvas.toDataURL();
          let data1 = {
            id: id,
            itemId: _this.props.itemId,
            version: _this.props.version,
            scheduleName: _this.state.saveScheduleName,
            groupSchedule: _this.state.groupSchedule,
            table: table,
            userCreated: _this.props.email,
            dateCreated: today,
            share: false,
            guid: node.data.guid,
            modelName: _this.props.viewer.impl.model.myData.loadOptions.modelNameOverride,
            urn: _this.props.viewer.impl.model.myData.urn,
            blobUrl: myImage
          }
          axios.post(`/api/versions/storageschedule`, { itemId: _this.props.itemId, data: data1 })
            .then(() => {
              _this.setState({
                openPanelSaveSchedule: false, lodingSaveSchedule: false, groupSchedule: '', saveScheduleName: '',
                displayPanelSchedule: true,
                maxForgeViewer: $('#splitForgeViewer').height(),
                modifyForgeViewer: $('#splitForgeViewer').height() - _this.state.heightDock,
                openPanelSaveSchedule: false
              }, () => {
                $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
                _this.props.viewer.resize()
              })
              message.success(`Storage schedule successful.`);
            })
            .catch(() => {
              _this.setState({
                openPanelSaveSchedule: false, lodingSaveSchedule: false, groupSchedule: '', saveScheduleName: '',
                displayPanelSchedule: true,
                maxForgeViewer: $('#splitForgeViewer').height(),
                modifyForgeViewer: $('#splitForgeViewer').height() - _this.state.heightDock,
                openPanelSaveSchedule: false
              }, () => {
                $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
                _this.props.viewer.resize()
              })
              message.error(`Storage schedule failed.`);
            })
        });
      } catch {
        let data1 = {
          id: id,
          itemId: _this.props.itemId,
          version: _this.props.version,
          scheduleName: _this.state.saveScheduleName,
          groupSchedule: _this.state.groupSchedule,
          table: table,
          userCreated: _this.props.email,
          dateCreated: today,
          share: false,
          guid: node.data.guid,
          modelName: _this.props.viewer.impl.model.myData.loadOptions.modelNameOverride,
          urn: _this.props.viewer.impl.model.myData.urn,
          blobUrl: screenshot.src
        }
        axios.post(`/api/versions/storageschedule`, { itemId: _this.props.itemId, data: data1 })
          .then(() => {
            _this.setState({
              openPanelSaveSchedule: false, lodingSaveSchedule: false, groupSchedule: '', saveScheduleName: '',
              displayPanelSchedule: true,
              maxForgeViewer: $('#splitForgeViewer').height(),
              modifyForgeViewer: $('#splitForgeViewer').height() - _this.state.heightDock,
              openPanelSaveSchedule: false
            }, () => {
              $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
              _this.props.viewer.resize()
            })
            message.success(`Storage schedule successful.`);
          })
          .catch(() => {
            _this.setState({
              openPanelSaveSchedule: false, lodingSaveSchedule: false, groupSchedule: '', saveScheduleName: '',
              displayPanelSchedule: true,
              maxForgeViewer: $('#splitForgeViewer').height(),
              modifyForgeViewer: $('#splitForgeViewer').height() - _this.state.heightDock,
              openPanelSaveSchedule: false
            }, () => {
              $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
              _this.props.viewer.resize()
            })
            message.error(`Storage schedule failed.`);
          })
      }
    };

    function guid(format = 'xxxxxxxxxxxx') {
      var d = new Date().getTime();
      return format.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16)
        })
    }
  }
  handleOpenSaveSchedule = (groupBy) => {
    let _this = this
    this.setState({
      displayPanelSchedule: !this.state.displayPanelSchedule,
      openPanelSaveSchedule: true,
      tableGroupBy: groupBy
    }, () => {
      if (!_this.state.displayPanelSchedule) {
        $('#splitForgeViewer').height(_this.state.maxForgeViewer)
        _this.props.viewer.resize()
      }
    })
  }
  handleScheduleName = (value) => {
    this.setState({ saveScheduleName: value })
  }
  handleChangeGroupSchedule = (e, { value }) => {
    this.setState({ groupSchedule: value })
  }
  //#endregion
  handleOpenTagCategory = () => {
    if (this.state.listCategory.length === 0)
      message.warning('Please, click refresh button to get data')
  }
  render() {
    const groupScheduleOptions = [
      { key: 'QS', text: 'QS', value: 'QS', },
      { key: 'Bim', text: 'Bim', value: 'Bim', },]
    const tabContent = () => {
      return (
        <div>
          <TagPicker data={this.state.listCategory} style={{ width: '100% ' }} size="sm" placeholder="Category" onChange={this.handleChangeCategory}
            value={this.state.listCategorySelected} onOpen={this.handleOpenTagCategory} maxHeight={320} placement='rightStart'
            renderMenu={menu => {
              if (this.state.loadingCategory) {
                return (
                  <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Icon icon="spinner" spin /> Loading...
                  </p>
                );
              }
              return menu;
            }} />
          <TagPicker data={this.state.listParameter} style={{ width: '100%' }} size="sm" placeholder="Parameter" value={this.state.value}
            value={this.state.listParameterSelected} maxHeight={320} placement='rightStart'
            onChange={this.handleChangeParameter} groupBy="group"
            sort={isGroup => {
              if (isGroup) {
                return (a, b) => {
                  return sortTagGroup(a.groupTitle, b.groupTitle);
                };
              }
              return (a, b) => {
                return sortTagGroup(a.value.displayName, b.value.displayName);
              };
            }}
            renderExtraFooter={() => (
              <div style={{ padding: '10px 2px', borderTop: '1px solid #e5e5e5' }}>
                <Checkbox inline indeterminate={this.state.indeterminate} checked={this.state.checkAll} onChange={this.handleCheckAll} style={{ color: 'blue' }} >
                  Check all
                </Checkbox>
              </div>
            )} />
          {this.state.listParameterSelected.length !== 0 &&
            <div className="CrossArea">
              <div className="square left">
                <DraggableArea
                  tags={this.state.listParameterSelected}
                  render={({ tag }) => (
                    <Tag color="blue">  {tag.displayName}</Tag>
                  )}
                  onChange={listParameterSelected => this.setState({ listParameterSelected })}
                />
              </div>
            </div>}
        </div>
      )
    }
    return (
      <>
        <div style={{
          width: 350, height: 'calc(100% - 53.78px)', position: 'absolute', left: 40, background: 'white', zIndex: 1, padding: 10,
          display: this.props.displayPanel ? 'block' : 'none'
        }}>
          <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}>
            <Tooltip title="Refresh" placement="bottom">
              <IconButton style={{ float: "left", marginBottom: '5px' }} size="sm" onClick={this.refreshParameter.bind(this)} loading={this.state.loadingCategory}
                icon={<Icon icon="refresh" />} ></IconButton>
            </Tooltip>
            <Title level={4} style={{ display: 'contents' }}>  Extract Quantities</Title>
            <Icon icon='close' size='xs' style={{ float: 'right', cursor: 'pointer' }} onClick={this.handleCloseSidebar} />
          </div>

          <Divider style={{ margin: '5px 0' }} />
          <div style={{ padding: '0px 0px 0px 0px', width: '100%', height: 'calc(100% - 125px)' }}>
            <Scrollbars
              renderView={renderView}
              autoHide autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}>
              <Tabs defaultActiveKey={'1'} onChange={this.handleChangeTab} type="card" size='small'>
                <TabPane tab="All" key="1">
                  {tabContent()}
                </TabPane>
                <TabPane tab="Active" key="2">
                  {tabContent()}
                </TabPane>
                <TabPane tab="Selected" key="3">
                  {tabContent()}
                </TabPane>
              </Tabs>
            </Scrollbars>
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <Checkbox checked={this.state.checkCombineParameter}
            onChange={this.handleCombineParameter} disabled={this.state.listCategorySelected.length <= 1}>
            Shown only combine parameter
          </Checkbox>
          <Button style={{ float: 'right' }} appearance="primary" onClick={this.openSchedule} loading={this.state.loading}
            disabled={this.state.loading}>Apply</Button>
        </div>
        {/* <Drawer backdrop={false} show={this.props.displayPanel} onHide={this.handleCloseSidebar} keyboard={false}
          placement='left' size='xs'>
          <Drawer.Header>
            <Drawer.Title>
              <Tooltip title="Refresh" placement="bottom">
                <IconButton style={{ float: "left", marginBottom: '5px' }} size="sm" onClick={this.refreshParameter.bind(this)} loading={this.state.loadingCategory}
                  icon={<Icon icon="refresh" />} ></IconButton>
              </Tooltip>
              <Header style={{ display: 'inline', marginLeft: '20px', verticalAlign: '-webkit-baseline-middle' }} as='h3'>Extract Quantities</Header>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body id='drawerBodySideBarSelectParameter'>
            <div style={{ overflow: 'auto', height: 'calc(100% - 40px)' }}>
              <Tabs defaultActiveKey={'1'} onChange={this.handleChangeTab} type="card" size='small'>
                <TabPane tab="All" key="1">
                  {tabContent()}
                </TabPane>
                <TabPane tab="Active" key="2">
                  {tabContent()}
                </TabPane>
                <TabPane tab="Selected" key="3">
                  {tabContent()}
                </TabPane>
              </Tabs>
            </div>
            <Checkbox style={{ boxShadow: '0px -1px 0px 0px' }} checked={this.state.checkCombineParameter}
              onChange={this.handleCombineParameter} disabled={this.state.listCategorySelected.length <= 1}>
              Shown only combine parameter
               </Checkbox>
          </Drawer.Body>
          <Drawer.Footer style={{ marginBottom: 5 }}>
            <Button style={{ float: 'right' }} appearance="primary" onClick={this.openSchedule} loading={this.state.loading}
              disabled={this.state.loading}>Apply</Button>
          </Drawer.Footer>
        </Drawer> */}
        <Dock position={'bottom'} isVisible={this.state.displayPanelSchedule} dimMode={'none'} fluid={false} zIndex={200} size={this.state.heightDock}
          onSizeChange={this.sizeChange} >
          <TableSchedule columns={this.state.columnsPanelSchedule} data={this.state.dataPanelSchedule}
            viewer={this.props.viewer}
            tempListModel={this.tempListModel}
            onActiveTable={this.onActiveTable}
            onChangeHeader={this.onChangeHeader}
            onIsolateRows={this.onIsolateRows}
            activeButtonIsolateRows={this.state.activeButtonIsolateRows}
            email={this.props.email}
            itemIdCurrent={this.props.itemId}
            version={this.props.version}
            handleOpenSaveSchedule={this.handleOpenSaveSchedule}
          />
        </Dock>
        <Modal show={this.state.openPanelSaveSchedule} onHide={this.handleClosePanel.bind(this)} size='xs'>
          <Modal.Header>
            <Modal.Title>Save Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Header as='h5' style={{ fontSize: 'small', marginTop: 10 }}>Group (required)</Header>
            <Dropdown
              placeholder='Select Group'
              fluid onChange={this.handleChangeGroupSchedule}
              selection
              options={groupScheduleOptions}
            />
            <Header as='h5' style={{ fontSize: 'small', marginTop: 10 }}>Schedule Name (required)</Header>
            <Input style={{ width: '100%' }} onChange={this.handleScheduleName} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleSaveSchedule.bind(this)} color='blue' loading={this.state.lodingSaveSchedule}
              disabled={this.state.groupSchedule === '' || this.state.saveScheduleName === ''}>
              Save
            </Button>
            <Button onClick={this.handleClosePanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (SideBarViewPanel)


