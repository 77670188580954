import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { connect } from 'react-redux';
import $ from 'jquery';
import { List, message, Typography, Tooltip, Upload, Popover, Select } from 'antd';
import * as antd from 'antd'
import { Button, Icon, IconButton, ButtonGroup, Drawer, Input, DatePicker, TagPicker, InputPicker, Divider, Loader, Checkbox, CheckboxGroup, Radio, RadioGroup,Tag } from 'rsuite';
import * as sematic from 'semantic-ui-react'

import _ from 'lodash'
import moment from 'moment';
// import '../scss/SideBar.scss'

import { Menu, Item, Separator, MenuProvider, animation, IconFont } from 'react-contexify';
import Dock from 'react-dock'
import { ColumnIssue } from '../columns'
import {TableIssue} from '../tables'
import { setupForgeExtensionBeforeLoaded, getAllModelsElementdbIdsWithCondition, getExternalId, checkExternalId, handleSetColorElement } from '../function/ForgeFunction'
import { convertVector4ColorToHex, convertHexColorToVector4 } from '../function/TableFunction'
import { socket ,getPublicUrl,checkWohhupUser, trackingUserUsing} from '../function/AdminFunction'
import { Button as BlueprintButton, ControlGroup, InputGroup, HTMLSelect, Intent, Position, Toaster } from "@blueprintjs/core";
import {DialogIssueInformation} from '../dialog'
import {Issue_Icon} from '../../../image'
import Zoom from 'react-medium-image-zoom'
import base64url from 'base64url'
import { v4 as uuidv4 } from 'uuid';
// import '../scss/CustomComponent.scss'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { Scrollbars } from 'react-custom-scrollbars';

const { TextArea, } = antd.Input;
const { Text, Title, Paragraph } = Typography
const { Option } = Select;

const Autodesk = window.Autodesk;
const THREE = window.THREE;
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}

const getModifiedWorldBoundingBox = (fragIds, fragList) => {

  var fragbBox = new THREE.Box3();
  var nodebBox = new THREE.Box3();

  fragIds.forEach(function (fragId) {

    fragList.getWorldBounds(fragId, fragbBox);
    nodebBox.union(fragbBox);
  });

  return nodebBox;
}
const _createMarkups = (viewer, list, _this) => {
  const $viewer = $('div#forgeViewer>div.adsk-viewing-viewer');
  $('#custom-markup-issue').remove();
  $viewer.append(`<div id="custom-markup-issue"> </div>`)
  let customIssue = $('#custom-markup-issue')
  _.forEach(list, v => {
    const pos = viewer.worldToClient(v.point);
    const color = v.issue.priority === 'Very urgent' ? 'red' :
      v.issue.priority === 'Urgent' ? 'orange' : v.issue.priority === 'Normal' ? '#f4d90b' : 'blue'
    const $label = $(` 
    <button class="markup-issue" data-id="${v.id}" >
      <img class="markup-issue-img" src=${Issue_Icon} style="background-color: ${color}" />
    </button>         
  `);
    $label.css('left', Math.floor(pos.x) + 10 /* arrow image width */ + 'px');
    $label.css('top', Math.floor(pos.y) + 10 /* arrow image height */ + 'px');
    $label.click(onClickLabel.bind(this, viewer, v.point, v.issue, _this))
    customIssue.append($label);
  })
}

const onClickLabel = (viewer, point, issue, _this) => {
  const pos = viewer.worldToClient(point);
  let customIssuePanel = $('#markup-issue-info-panel')
  customIssuePanel.css('display', 'flex')
  customIssuePanel.css('left', Math.floor(pos.x) + 10 - 50 + 'px');
  customIssuePanel.css('top', Math.floor(pos.y) + 5 - 130 + 'px');
  getPublicUrl(issue.img)
    .then(res => {
      _this.setState({
        markUpIssue: {
          id: issue.id,
          img: res,
          priority: issue.priority,
          description: issue.descriptionViewPoint,
          title: issue.titleViewPoint
        }
      })
    })
    .catch(err => {
      _this.setState({
        markUpIssue: {
          id: issue.id,
          img: err,
          priority: issue.priority,
          description: issue.descriptionViewPoint,
          title: issue.titleViewPoint
        }
      })
    })

}
class SideBarIssuePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingMore: false,
      viewPortName: '',
      listSaveView: [],
      allListSaveView: [],
      displayPanel: false,
      displayNewIssuePanel: false,
      displayFindIssuePanel: false,
      listMember: [],
      placement: 'left',

      displayInfoViewpoint: false,
      pageCurrent: 0,
      disableLoadMore: false,
      viewPoint: null,
      linkIssue: false,
      markUpIssue: {
        id: '',
        priority: '',
        img: '',
        description: ''
      },
      loadingMarkup: false,
      inputSearch: '',
      isOtherFile: false,
      filterName: 'All',
      isAssignToMe: false,
      tableData: [],
      tagsOption: [],
      companyType:''
    }
    this.toaster = React.createRef()
  }
  componentWillMount = () => {
    socket.on('realtime-issue', this.handleRealtimeIssue)
    socket.on('realtime-delete-issue', this.handleRealtimeDeleteIssue)
    socket.on('realtime-comment', this.handleNewComment)
  }


  componentWillUnmount = () => {
    try {
      let models = this.props.viewer.impl.modelQueue().getModels()
      _.forEach(models, model => {
        this.props.viewer.clearThemingColors(model)
      })
      this.props.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this._updateMarkups);
      socket.removeListener('realtime-issue', this.handleRealtimeIssue)
      socket.removeListener('realtime-delete-issue', this.handleRealtimeDeleteIssue)
      socket.removeListener('realtime-comment', this.handleNewComment)
      $('#custom-markup-issue').remove();
      $('#markup-issue-info-panel').css('display', 'none')
      this.setState({ loadingMarkup: false })
    } catch { }

  }
  componentDidMount = () => {
    trackingUserUsing(this.props.email,this.props.projectId,'BIM App','Issue')
    this.setState({ displayPanel: true, loading: true, loadingMore: false })
    axios.post('/api/users/get-me-information', { token: this.props.userInfo.token })
    .then(async res => {
      if (res.data.user.email !== this.props.userInfo.email) return
      let company = null
      let companyType = null
      if (res.data.userInfo.contractors.length !== 0) {
        let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === this.props.projectId })
        if (res.data.user.email !== 'admin@wohhup.com') {
          company = res.data.userInfo.contractors[index].company
        }
        let detailCompanies = await axios.post(`/api/projects/get-information-project`, {
          projectId: this.props.projectId,
          token: this.props.userInfo.token
        })
        let index1 = _.findIndex(detailCompanies.data.listContractor, v => { return v.company === company })
        if (index1 >= 0) {
          companyType = detailCompanies.data.listContractor[index1].companyType
        }
      }
      this.setState({ companyType }, () => {
        this.handleLoadIssue()
      })

    })
    .catch(err => {
      console.log(err)
    })

  }
  handleLoadIssue=()=>{
    axios.get("/api/issue/get-issue-unread", { params: { email: this.props.email } })
      .then(res => {
        let unReadData = res.data
        axios.get("/api/issue/get-item-issues", {
          params: {
            itemId: this.props.itemId, projectId: this.props.projectId
          }
        })
          .then(res => {
            if (res.data.length !== 0) {
              let temp = []
              let tagsOption = []
              _.forEach(res.data, v => {
                if (v.deleted) return
                let data = v.issue
                let index = _.findIndex(unReadData, e => { return e.id === data.id })
                if (index >= 0) {
                  data.unReadIssue = !unReadData[index].issueTime ? true : data.dateModified > unReadData[index].issueTime
                  data.unReadComment = !unReadData[index].commentTime ? (data.comments.length === 0 ? false : true) :
                    (data.comments.length === 0 ? false : data.comments[data.comments.length - 1].datetime > unReadData[index].commentTime)
                } else {
                  data.unReadIssue = true
                  data.unReadComment = data.comments.length === 0 ? false : true
                }
                data.shareWith = data.shareWith ? (_.isArray(data.shareWith) ? data.shareWith : ['Wohhup']) : ['Wohhup']
                data.tags = data.tags ? data.tags : []
                if (this.props.userInfo.email === 'admin@wohhup.com') {
                  temp.push(data)
                } else if (data.listAssignViewPoint.includes(this.props.userInfo.email)) {
                  temp.push(data)
                } else if (checkWohhupUser(this.props.userInfo.email) ) {
                  if (data.shareWith.includes('Wohhup'))
                    temp.push(data)
                } else if (data.shareWith.includes(this.state.companyType)) {
                  temp.push(data)
                }
                tagsOption = tagsOption.concat(data.tags ? data.tags : [])
              })
              this.setState({
                listSaveView: temp,
                allListSaveView: temp,
                loading: false,
                loadingMore: false,
                tagsOption: _.uniq(tagsOption)
              })
            } else {
              this.setState({
                listSaveView: [],
                allListSaveView: [],
                loading: false,
                loadingMore: false
              })
            }
          })
          .catch(() => {
            this.setState({
              listSaveView: [],
              allListSaveView: [],
              loading: false,
              loadingMore: false
            })
          })
      })
      .catch(() => {
        this.setState({
          listSaveView: [],
          allListSaveView: [],
          loading: false,
          loadingMore: false
        })
      })
  }
  //#region handle socket
  handleRealtimeIssue = (data) => {
    try {
      if (data.item.issue.userModified === this.props.userInfo.email) return
      let check = false
      data.item.issue.shareWith = data.item.issue.shareWith ? (_.isArray(data.item.issue.shareWith) ? data.item.issue.shareWith : ['Wohhup']) : ['Wohhup']
      if (this.props.userInfo.email === 'admin@wohhup.com') {
        check = true
      } else if (data.item.issue.listAssignViewPoint.includes(this.props.userInfo.email)) {
        check = true
      } else if (checkWohhupUser(this.props.userInfo.email) ) {
        if (data.item.issue.shareWith.includes('Wohhup'))
          check = true
      } else if (data.item.issue.shareWith.includes(this.state.companyType)) {
        check = true
      }

      let clone = Object.assign({}, data);
      let tempAllListSaveView = this.state.allListSaveView.slice(0)
      let tempTableData = this.state.tableData.slice(0)
      let tempListSaveView = this.state.listSaveView.slice(0)
      let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.issue.id })
      if (index >= 0) {
        if (check)
          tempAllListSaveView.splice(index, 1, data.item.issue)
        else
          tempAllListSaveView.splice(index, 1)
      } else {
        tempAllListSaveView.splice(0, 0, data.item.issue)
      }
      index = _.findIndex(tempListSaveView, e => { return e.id === data.item.issue.id })
      if (index >= 0) {
        if (check) {
          tempListSaveView.splice(index, 1, data.item.issue)
          tempTableData.splice(index, 1, clone.item.issue)
        }
        else
          tempListSaveView.splice(index, 1)
      } else {
        tempListSaveView.splice(0, 0, data.item.issue)
        tempTableData.splice(0, 0, clone.item.issue)
      }
      data.item.issue.unReadIssue = true
      this.setState({
        listSaveView: tempListSaveView,
        allListSaveView: tempAllListSaveView,
        tableData: tempTableData,
        tagsOption: _.uniq(this.state.tagsOption.concat(data.item.issue.tags ? data.item.issue.tags : []))
      })
    } catch { }
  }
  handleRealtimeDeleteIssue = (data) => {
    let tempAllListSaveView = this.state.allListSaveView.slice(0)
    let tempListSaveView = this.state.listSaveView.slice(0)
    let tempTableData = this.state.tableData.slice(0)
    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.issue.id })
    if (index >= 0) {
      tempAllListSaveView.splice(index, 1)
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.item.issue.id })
    if (index >= 0) {
      tempListSaveView.splice(index, 1)
    }
    this.setState({
      listSaveView: tempListSaveView,
      allListSaveView: tempAllListSaveView,
      tableData: tempTableData
    })
  }
  //#endregion

  //#region handle restore view state
  restoreSaveView = (check, e) => {
    try {
      let section = this.props.viewer.getExtension('Autodesk.Section')
      section.activate()
      section.deactivate();
    } catch { }
    let data = e.props.item
    if (data.mainModel.version !== this.props.version) {
      this.toaster.current.show({
        timeout: 0,
        icon: 'warning-sign',
        intent: Intent.WARNING,
        message: (
          <Text strong style={{ color: 'white' }}>
            {`Issue created at version ${data.mainModel.version}`}
          </Text>
        )
      })
    }
    let document = this.props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let node = this.props.viewer.impl.model.getDocumentNode()
    let loadOptions = {
      globalOffset: this.props.viewer.impl.model.myData.globalOffset,
      applyRefPoint: true,
      modelNameOverride: data.mainModel.name,
      isAEC: true,
      guid: data.mainModel.guid,
      viewableID: data.mainModel.viewableID,
      itemId: data.mainModel.itemId,
      version: data.mainModel.version,
      acmSessionId: document.acmSessionId
    }
    if (data.guid === node.data.guid) {
      // this.props.viewer.restoreState(v.viewPort)  
      this.handleOtherModel(data, check)
      return false
    } else {
      let viewables = document.getRoot().search({ 'type': 'geometry', 'guid': data.guid }, true)
      if (viewables.length !== 0) {
        this.props.viewer.loadDocumentNode(document, viewables[0], loadOptions)
          .then(() => {
            setupForgeExtensionBeforeLoaded(this.props.viewer, this.props.viewerPage)
            this.handleOtherModel(data, check)
          })
          .catch(() => {
            message.warning(`Load model failed`)
          })
      } else {
        message.error(`View isn't existing in file`)
      }
      return false
    }
  }
  handleOtherModel = (data, check) => {
    this.viewPort = data.viewPort
    this.elementColor = data.attachElementColor
    this.count = data.otherModel.length
    const models = this.props.viewer.impl.modelQueue().getModels()
    if (data.otherModel.length > 0) {
      let tempModeled = []
      _.forEach(models, v => {
        if (v.myData.urn !== this.props.viewer.impl.model.myData.urn) {
          let index = _.findIndex(data.otherModel, e => { return e.itemId === v.myData.loadOptions.itemId && e.version === v.myData.loadOptions.version && e.guid === v.myData.loadOptions.guid })
          if (index < 0)
            this.props.viewer.unloadModel(v)
          else
            tempModeled.push(index)
        }
      })
      _.forEach(data.otherModel, (v, k) => {
        if (tempModeled.includes(k)) {
          this.count--
          if (this.count === 0) {
            handleSetColorElement(data.attachElementColor, this.props.viewer, this.viewPort)
          }
          return
        }
        axios.post(`/api/issue/get-model`, { item: v, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = this.props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: this.props.viewer.impl.model.myData.globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                viewableID: res.data.viewableID,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              var documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, this._onDocumentLoadSuccess.bind(this, loadOptions), this._onDocumentLoadFailure);
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              this.count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              this.count--
            }
          })
          .catch(() => {
            this.count--
            message.error(`Can't load model.`);
          })
      })
    } else {
      _.forEach(models, v => {
        if (v.myData.urn !== this.props.viewer.impl.model.myData.urn) {
          this.props.viewer.unloadModel(v)
        }
      })
      handleSetColorElement(data.attachElementColor, this.props.viewer, this.viewPort)
    }
  }
  _onDocumentLoadFailure = (viewerErrorCode) => {
    console.log(viewerErrorCode)
  }
  _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      this.props.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess, this._onLoadModelError)
    } else {
      message.warning(`Can't find view in file ${loadOptions.modelNameOverride} version ${loadOptions.version}`)
    }
  }
  _onLoadModelSuccess = () => {
    if (this.count === 0) {
      handleSetColorElement(this.elementColor, this.props.viewer, this.viewPort)
    }
  }
  _onLoadModelError = () => {

  }
  //#endregion

  handleCloseViewPanel = () => {
    this.setState({
      displayPanel: 'none',
      widthPanel: 0
    })
    this.props.onDisplayPanelChange('issuePanel', false)
  }

  handleDeleteViewPoint = (e) => {
    if (!window.confirm('Are you sure to delete this view?')) return
    this.setState({ loading: true })
    let temp1 = []
    let tempData = e.props.item.id
    _.forEach(this.state.listSaveView, (v) => {
      if (v.id !== e.props.item.id) {
        temp1.push(v)
      }
    })
    let temp2 = []
    _.forEach(this.state.allListSaveView, (v) => {
      if (v.id !== e.props.item.id) {
        temp2.push(v)
      }
    })
    axios.post(`/api/issue/delete-issue`, { label: tempData, email: this.props.email })
      .then(() => {
        this.setState({ listSaveView: temp1, allListSaveView: temp2, loading: false })
        message.success(`Deleted viewpoint.`);
      })
      .catch(() => {
        this.setState({ loading: false })
        message.error(`Can't delete viewpoint.`);
      })

  }

  //#region //!new view point panel
  handleNewViewPointPanel = () => {
    this.setState({ displayNewIssuePanel: true, displayInfoViewpoint: false, displayPanel: false })
  }
  handleCloseNewViewPointPanel = (data) => {
    if (data) {
      let clone = Object.assign({}, data);
      clone.dateViewPoint = moment(clone.dateViewPoint).format('DD-MM-YYYY')
      clone.dateModified = moment(clone.dateModified).format('DD-MM-YYYY')
      // if (check) {

      // } else {
      //   let tempListSaveView = this.state.listSaveView.slice(0)
      //   let tempAllSaveView = this.state.allListSaveView.slice(0)
      //   let tempTableData = this.state.tableData.slice(0)
      //   tempListSaveView.splice(0, 0, data)
      //   tempAllSaveView.splice(0, 0, data)
      //   tempTableData.splice(0, 0, clone)
      //   this.setState({
      //     listSaveView: tempListSaveView,
      //     allListSaveView: tempAllSaveView,
      //     tableData:tempTableData,
      //     displayNewIssuePanel: false,
      //     displayPanel: true,
      //   }, () => {
      //     if (this.state.linkIssue) {
      //       this.generateMarkupIssue()
      //     }
      //   })
      // }
      let tempListSaveView = this.state.listSaveView.slice(0)
      let tempAllSaveView = this.state.allListSaveView.slice(0)
      let tempTableData = this.state.tableData.slice(0)
      tempListSaveView.splice(0, 0, data)
      tempAllSaveView.splice(0, 0, data)
      tempTableData.splice(0, 0, clone)
      this.setState({
        listSaveView: tempListSaveView,
        allListSaveView: tempAllSaveView,
        tableData: tempTableData,
        displayNewIssuePanel: false,
        displayPanel: true,
      }, () => {
        if (this.state.linkIssue) {
          this.generateMarkupIssue()
        }
      })
    } else {
      this.setState({ displayNewIssuePanel: false, displayPanel: true })
    }
  }
  //#endregion

  //#region //!info view point panel
  handleInfoViewPointPanel = (item, check) => {
    let temp = []
    _.forEach(item.listAssignViewPoint, v => {
      temp.push({ label: v, value: v })
    })
    item.listDataAssigneViewPoint = temp
    this.setState({
      viewPoint: item,
      displayPanel: false,
      displayNewIssuePanel: false,
      displayInfoViewpoint: true,
      isOtherFile: check
    })
  }
  handleCloseInfoViewPointPanel = (data) => {
    if (data) {
      this.setState({ listSaveView: data, displayInfoViewpoint: false, displayPanel: true }, () => {
        if (this.state.linkIssue) {
          this.generateMarkupIssue()
        }
      })
    } else {
      this.setState({
        displayInfoViewpoint: false, displayPanel: true
      })
    }
  }

  handleNewComment = (data) => {
    if (data.user === this.props.userInfo.email) return
    let tempAllListSaveView = this.state.allListSaveView.slice(0)
    let tempTableData = this.state.tableData.slice(0)
    let tempListSaveView = this.state.listSaveView.slice(0)
    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempAllListSaveView[index].unReadComment = true
      tempAllListSaveView[index].comments = data.item
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempListSaveView[index].unReadComment = true
      tempListSaveView[index].comments = data.item
      if (tempTableData.length > 0) {
        tempTableData[index].unReadComment = true
        tempTableData[index].comments = data.item
      }

    }
    this.setState({
      listSaveView: tempListSaveView,
      allListSaveView: tempAllListSaveView,
      tableData: tempTableData,
    })
  }
  //#endregion

  handleSwitchTable = () => {

    this.setState({ placement: this.state.placement === 'left' ? 'bottom' : 'left' }, () => {
      if (this.state.placement === 'bottom') {
        let temp = this.state.listSaveView.slice()
        // _.forEach(temp, v => {
        //   v.dateViewPoint = moment(v.dateViewPoint).format('DD-MM-YYYY')
        //   v.dateModified = moment(v.dateModified).format('DD-MM-YYYY')
        // })
        this.setState({ tableData: temp })
      }
    })
  }

  //#region //!find issue panel
  handleFindIssuePanel = () => {
    this.setState({ displayFindIssuePanel: true })
  }
  handleCloseFindIssuePanel = (data) => {
    if (data !== null) {
      this.setState(() => ({
        displayFindIssuePanel: false,
        displayNewIssuePanel: false,
        displayPanel: false,
        displayInfoViewpoint: true,
        viewPoint: data,
      }))
    } else {
      this.setState({ displayFindIssuePanel: false })
    }
  }
  //#endregion
  handleLinkIssue = () => {
    this.setState({ linkIssue: !this.state.linkIssue }, () => {
      this.generateMarkupIssue()
    })
  }
  //#region //!markup
  generateMarkupIssue = () => {
    let color = {
      'Very urgent': convertHexColorToVector4('#ff0000'),
      'Urgent': convertHexColorToVector4('#ffa500'),
      'Normal': convertHexColorToVector4('#f4d90b'),
      'Low': convertHexColorToVector4('#0000ff')
    }
    this.setState({ loadingMarkup: true })
    if (this.state.linkIssue) {
      const models = this.props.viewer.impl.modelQueue().getModels()
      let tempSelection = {}
      let count = 0
      _.forEach(this.state.listSaveView, async v => {
        if (v.mainModel.itemId !== this.props.itemId) return
        count = count + v.attachElement.length
      })
      if (count === 0) {
        this.setState({ loadingMarkup: false })
        return
      }
      _.forEach(this.state.listSaveView, async v => {
        if (v.mainModel.itemId !== this.props.itemId) return
        if (v.attachElement.length !== 0) {
          _.forEach(v.attachElement, async model => {
            let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === model.itemId })
            if (index >= 0) {
              let instanceTree = models[index].getData().instanceTree;
              let tempIds = getAllModelsElementdbIdsWithCondition(instanceTree, this.props.viewer, models[index], 'all');
              let externalId = await checkExternalId(tempIds, models[index], model.guid)
              if (!tempSelection[v.id])
                tempSelection[v.id] = []
              tempSelection[v.id].push({ model: models[index], ids: externalId, issue: v })
            }
            count--
            if (count === 0) {
              let listPoint = {}
              _.forEach(tempSelection, (issue, issueId) => {
                let box = new THREE.Box3();
                _.forEach(issue, item => {
                  _.forEach(item.ids, id => {
                    var fragIds = []
                    this.props.viewer.setThemingColor(id, color[item.issue.priority], item.model)
                    item.model.getData().instanceTree.enumNodeFragments(id, (fragId) => {
                      fragIds.push(fragId)
                    }, false)
                    var bBox = getModifiedWorldBoundingBox(
                      fragIds,
                      item.model.getFragmentList()
                    );
                    box = box.union(bBox)
                  })
                })
                var point = new THREE.Vector3((box.max.x + box.min.x) / 2, (box.max.y + box.min.y) / 2, box.max.z)
                listPoint[issueId] = { point: point, id: issueId, issue: issue[0].issue }

                console.log(box)
              })

              this.setState({ listPoint: listPoint, loadingMarkup: false }, () => {
                _createMarkups(this.props.viewer, this.state.listPoint, this)
              })
              this.props.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this._updateMarkups.bind());
            }
          })
        }
      })
    } else {
      this.props.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this._updateMarkups);
      $('#custom-markup-issue').remove();
      $('#markup-issue-info-panel').css('display', 'none')
      this.setState({ loadingMarkup: false })
    }
  }
  _updateMarkups = (e) => {
    // const rect = this.props.viewer.canvas.parentElement.getBoundingClientRect();
    // let x = e.clientX - rect.x;
    // let y = e.clientY - rect.y;
    let customIssue = $('#custom-markup-issue')
    let temp = []
    let tempGroup = []
    $('#markup-issue-info-panel').css('display', 'none')
    for (const label of $('#custom-markup-issue .markup-issue')) {
      const $label = $(label);
      const id = $label.data('id');
      if (this.state.listPoint[id]) {
        const p = this.state.listPoint[id].point;
        const pos = this.props.viewer.worldToClient(p)
        let distance = e.camera.position.distanceTo(p)
        if (distance <= 1000) {
          $label.css('left', Math.floor(pos.x) + 10 /* arrow image width */ + 'px');
          $label.css('top', Math.floor(pos.y) + 10 /* arrow image height */ + 'px');
          $label.css('display', 'flex');
          if (distance > 500) {
            temp.push({ label: $label, id: id, point: p, client: pos })
          }
        } else {
          $label.css('display', 'none');
        }
      } else {
        tempGroup.push(id)
        $label.remove()
      }
    }
    let exclude = []
    _.forEach(temp, v => {
      if (exclude.includes(v.id)) return
      let filter = _.filter(temp, e => { return e.point.distanceTo(v.point) < 200 })
      let tempPoint = new THREE.Vector3(0, 0, 0)
      let tempId = ''
      _.forEach(filter, (i, k) => {
        tempPoint = k === 0 ? i.point : new THREE.Vector3((tempPoint.x + i.point.x) / 2, (tempPoint.y + i.point.y) / 2, (tempPoint.z + i.point.z) / 2)
        tempId = tempId + k !== 0 ? '_' : '' + i.id
        i.label.css('display', 'none');
        exclude.push(i.id)
      })
      if (filter.length <= 1) return
      const $label = $(` 
      <button class="markup-issue" data-id="${tempId}" >
        ${filter.length}
      </button>         
      `)
      const pos = this.props.viewer.worldToClient(tempPoint)
      $label.css('left', Math.floor(pos.x) + 10 /* arrow image width */ + 'px');
      $label.css('top', Math.floor(pos.y) + 10 /* arrow image height */ + 'px');
      // $label.click(onClickLabel.bind(this, viewer, v.point, v.issue, _this))
      customIssue.append($label);
    })
  }
  //#endregion

  handleInputSearch = (e) => {
    this.setState({ inputSearch: e.target.value })
  }

  handleFilter = (e) => {
    this.setState({ filterName: e.target.value, inputSearch: '' }, () => {
      if (this.state.filterName === "Assigned to me") {
        axios.get(`/api/projects/members?q=${this.props.projectId}`)
          .then(res => {
            let temp = [this.props.email]
            let tempListSaveView = []
            let tempListViewExpand = []
            _.forEach(res.data.groupUser, v => {
              _.forEach(v.members, member => {
                if (member.name === this.props.email) {
                  temp.push(v.name)
                }
              })
            })
            _.forEach(this.state.allListSaveView, v => {
              _.forEach(v.listAssignViewPoint, user => {
                if (temp.includes(user)) {
                  tempListSaveView.push(v)
                }
              })
            })
            _.forEach(this.state.allListSaveViewExpand, v => {
              _.forEach(v.listAssignViewPoint, user => {
                if (temp.includes(user)) {
                  tempListViewExpand.push(v)
                }
              })
            })
            this.setState({ listSaveView: tempListSaveView, isAssignToMe: true })
          })
          .catch(() => {

          })
      } else {
        this.setState({ listSaveView: this.state.allListSaveView })
      }
    })
  }
  handleFindIssue = () => {
    this.setState({ loading: true })
    if (this.state.inputSearch !== '') {
      let tempListSaveView = []
      if (this.state.filterName === 'All' || this.state.filterName === 'Label') {
        _.forEach(this.state.allListSaveView, v => {
          if (v.id.toLowerCase().includes(this.state.inputSearch.toLowerCase())) {
            tempListSaveView.push(v)
          }
        })
      } else if (this.state.filterName === 'Title') {
        _.forEach(this.state.allListSaveView, v => {
          if (v.titleViewPoint.toLowerCase().includes(this.state.inputSearch.toLowerCase())) {
            tempListSaveView.push(v)
          }
        })
      } else if (this.state.filterName === 'Description') {
        _.forEach(this.state.allListSaveView, v => {
          if (v.descriptionViewPoint.toLowerCase().includes(this.state.inputSearch.toLowerCase())) {
            tempListSaveView.push(v)
          }
        })

      }
      this.setState({ listSaveView: tempListSaveView, loading: false })
    } else {
      this.setState({ listSaveView: this.state.allListSaveView, loading: false })
    }
  }
  handleAssignToMe = () => {
    this.setState({ isAssignToMe: !this.state.isAssignToMe }, () => {
      if (this.state.isAssignToMe) {
        axios.get(`/api/projects/members?q=${this.props.projectId}`)
          .then(res => {
            let temp = [this.props.email]
            let tempListSaveView = []
            _.forEach(res.data.groupUser, v => {
              _.forEach(v.members, member => {
                if (member.name === this.props.email) {
                  temp.push(v.name)
                }
              })
            })
            _.forEach(this.state.allListSaveView, v => {
              _.forEach(v.listAssignViewPoint, user => {
                if (temp.includes(user)) {
                  tempListSaveView.push(v)
                }
              })
            })
            this.setState({ listSaveView: tempListSaveView })
          })
          .catch(() => {
            this.setState({ isAssignToMe: false })
          })
      } else {
        this.setState({ listSaveView: this.state.allListSaveView })
      }
    })

  }
  handleOpenIssue = (check, e) => {
    axios.post('/api/issue/check-item', { token: this.props.userInfo.token, itemId: e.props.item.mainModel.itemId })
      .then(res => {
        if (res.data) {
          let path = base64url(`${this.props.projectId}/${this.props.userCreatedProject}/${e.props.item.id}/${check}`)
          window.open(`${window.location.origin}/issue/${path}`)
        } else {
          message.warning(`Model was deleted`)
        }
      })
      .catch(err => {
        message.warning(`Can't open issue`)
      })

  }
  listIssue = (data) => {
    return (
      <List
        loading={this.state.loading}
        bordered
        size="small"
        dataSource={data}
        renderItem={item => {
          let today = moment(Date.now())
          let dateViewPoint = moment(item.dateViewPoint)
          let dateModified = moment(item.dateModified)
          return (
            <MenuProvider id={item.mainModel.itemId === this.props.itemId ? 'issue_menu' : 'other_issue_menu'} animation={animation.zoom} data={{ item: item }}>
              <List.Item key={item.id} style={{
                cursor: 'pointer', backgroundColor: item.status === "Close" ? '#f5fff5' : dateViewPoint < today && '#ffe8ed',
                borderLeft: item.unReadIssue && '2px black solid'
              }}>
                <>
                  <List.Item.Meta style={{ overflow: 'hidden', height: 75 }}
                    value={item.id} onDoubleClick={this.handleInfoViewPointPanel.bind(this, item, item.mainModel.itemId === this.props.itemId ? false : true)}
                    title={
                      <>

                        <Icon icon='exclamation-triangle' style={{
                          marginRight: 5,
                          color: item.priority === 'Very urgent' ? 'red' :
                            item.priority === 'Urgent' ? 'orange' : item.priority === 'Normal' ? '#f4d90b' : 'blue'
                        }} />
                        <Tooltip title={item.id} placement='top'>
                          <Text style={{ position: 'absolute', width: 'calc(100% - 360px)', color: item.mainModel.itemId !== this.props.itemId && '#c6ccd2' }} ellipsis={true}>
                            {item.id}</Text>
                        </Tooltip>


                        <div style={{ left: 110, display: 'inline', position: 'absolute', color: item.mainModel.itemId !== this.props.itemId ? '#c6ccd2' : '#878787' }}>
                          <ButtonGroup style={{ marginRight: 3 }} >
                            <Button size="xs" disabled={item.mainModel.itemId !== this.props.itemId} color={item.discipline.includes("Archi") && 'blue'} >A</Button>
                            <Button size="xs" disabled={item.mainModel.itemId !== this.props.itemId} color={item.discipline.includes("C&S") && 'blue'} >S</Button>
                            <Button size="xs" disabled={item.mainModel.itemId !== this.props.itemId} color={item.discipline.includes("M&E") && 'blue'} >M</Button>
                          </ButtonGroup>
                          <Divider vertical className='custom-divider-issue' />
                          <Icon style={{ color: item.unReadComment && 'red' }} icon='reply'>{' '}{`(${item.comments.length})`}</Icon>
                          <Divider vertical className='custom-divider-issue' />
                          <Icon icon='link' style={{ transform: 'translateY(1px)' }}>{' '}{`(${item.attachFile.length})`}</Icon>
                          <Divider vertical className='custom-divider-issue' />
                          <Tooltip title={dateModified.format('DD-MM-YYYY HH:mm')} placement='top'>
                            <Icon icon='clock-o' style={{ fontSize: 16, transform: 'translateY(2px)' }}>
                              {' '}{dateModified.format('DD-MM-YYYY') === today.format('DD-MM-YYYY')
                                ? dateModified.format('HH:mm') : dateModified.format('DD-MM-YYYY')}</Icon>
                          </Tooltip>
                        </div>

                      </>
                    }
                    description={
                      <>
                        <Text code disabled={item.mainModel.itemId === this.props.itemId} style={{ color: item.mainModel.itemId !== this.props.itemId ? '#c6ccd2' : 'black', marginRight: 3 }}>Title: </Text>
                        <Tooltip title={item.titleViewPoint} placement="right" style={{ zIndex: 120000 }} mouseEnterDelay={2}>
                          <Text style={{ position: 'absolute', width: 'calc(100% - 170px)', color: item.mainModel.itemId !== this.props.itemId ? '#c6ccd2' : 'gray' }} ellipsis={true}>
                            {` ${item.titleViewPoint}`}</Text>
                        </Tooltip>
                        <br />
                        <Text code disabled={item.mainModel.itemId === this.props.itemId} style={{ color: item.mainModel.itemId !== this.props.itemId ? '#c6ccd2' : 'black', marginRight: 3 }}>Description:</Text>
                        <Tooltip title={(
                          <Text style={{ whiteSpace: 'pre-wrap', color: 'white' }}> {item.descriptionViewPoint}</Text>
                        )} placement="right" mouseEnterDelay={2}>
                          <Text type="secondary" style={{
                            position: 'absolute', width: 'calc(100% - 170px)',
                            color: item.mainModel.itemId !== this.props.itemId ? '#c6ccd2' : 'gray'
                          }} ellipsis={true}>
                            {` ${item.descriptionViewPoint}`}</Text>
                        </Tooltip>
                      </>
                    }
                  />
                  <Divider className='divide-vertical-list-view-point' vertical />
                </>

                <Text style={{ color: item.percent === 100 ? 'green' : 'red', width: 35, textAlignLast: 'center' }}> {item.percent}%</Text>

              </List.Item>
              <Divider className='divide-list-view-point' />
            </MenuProvider>
          )
        }
        }
      >

      </List>
    )
  }

  handleExportExcel() {
    // var workbook = XLSX.utils.table_to_book(document.getElementById(name));
    let tempHeader = []

    _.forEach(this.state.allListSaveView[0], (v, k) => {
      // if (!['comments', 'dateCreated', 'img', 'dateModified', 'guid', 'mainModel', 'otherModel', 'attachFile', 'attachElement', 'viewableID', 'camera',
      //   'attachElementColor', 'attachView', 'unReadIssue', 'unReadComment'].includes(k))

      if (k === 'id')
        tempHeader.push('Id')
      else if (k === 'titleViewPoint')
        tempHeader.push('Title')
      else if (k === 'descriptionViewPoint')
        tempHeader.push('Description')
      else if (k === 'dateViewPoint')
        tempHeader.push('Due Date')
      else if (k === 'listAssignViewPoint')
        tempHeader.push('Assignees')
      else if (k === 'status')
        tempHeader.push('Status')
      else if (k === 'percent')
        tempHeader.push('Completion')
      else if (k === 'type')
        tempHeader.push('Type')
      else if (k === 'priority')
        tempHeader.push('Priority')
      else if (k === 'userCreated')
        tempHeader.push('Created')
      else if (k === 'userModified')
        tempHeader.push('Modified')
      else if (k === 'discipline')
        tempHeader.push('Discipline')
    })
    let tempData = []
    _.forEach(this.state.allListSaveView, (item) => {
      let v = Object.assign({}, item)
      tempData.push({
        Id: v.id, Title: v.titleViewPoint, Description: v.descriptionViewPoint,
        Discipline: v.discipline.join(', '),
        'Due Date': moment(v.dateViewPoint).format('DD-MM-YYYY'), 'Assignees': v.listAssignViewPoint.join(', '),
        Status: v.status, Completion: v.percent, Type: v.type, Priority: v.priority,
        Created: `${moment(v.dateCreated).format('DD-MM-YYYY')} by ${v.userCreated}`,
        Modified: `${moment(v.dateModified).format('DD-MM-YYYY')} by ${v.userModified}`,
      })
    })
    var ws = XLSX.utils.json_to_sheet(tempData, { header: tempHeader });
    const wb = XLSX.utils.book_new();
    // var wbout = XLSX.write(wb, ws, 'Issue')
    XLSX.utils.book_append_sheet(wb, ws, 'test');
    // XLSX.writeFile(wb, "Issue.xlsx");

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelBuffer], { type: "application/octet-stream" }), "Issue.xlsx");
  }
  render() {


    return (
      <div>
        {this.state.placement === 'left' &&
          <div style={{
            width: 450, height: 'calc(100% - 53.78px)', position: 'absolute', left: 40, background: 'white', zIndex: 1, padding: 10,
            display: this.state.displayPanel ? 'block' : 'none'
          }}>
            <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}>
              <ButtonGroup style={{ bottom: '4px' }}>
                <Tooltip title="New Issue">
                  <IconButton size="xs" onClick={this.handleNewViewPointPanel.bind(this)} loading={this.state.loading}
                    icon={<Icon icon="plus-square" />} ></IconButton>
                </Tooltip>
                <Tooltip title="Switch Table">
                  <IconButton size="xs" onClick={this.handleSwitchTable.bind(this)} loading={this.state.loading}
                    icon={<Icon icon="exchange" />} ></IconButton>
                </Tooltip>
                <Tooltip title="Highlight Issue">
                  <IconButton size="xs" onClick={this.handleLinkIssue.bind(this)} loading={this.state.loading || this.state.loadingMarkup} color={this.state.linkIssue && 'blue'}
                    icon={<Icon icon="link" />} ></IconButton>
                </Tooltip>
                <Tooltip title="Export Issue">
                  <IconButton size="xs" onClick={this.handleExportExcel.bind(this)} loading={this.state.loading || this.state.loadingMarkup}
                    disabled={this.state.allListSaveView.length === 0}
                    icon={<Icon icon="export" />} ></IconButton>
                </Tooltip>
              </ButtonGroup>
              <Title level={4} style={{ display: 'contents' }}>  Issue</Title>
              <Icon icon='close' size='xs' style={{ float: 'right', cursor: 'pointer' }} onClick={this.handleCloseViewPanel} />
            </div>
            <div style={{ marginTop: 5 }}>
              <ControlGroup fill={true} vertical={false}>
                <HTMLSelect options={["All", "Label", "Title", "Description", "Assigned to me"]} onChange={this.handleFilter} value={this.state.filterName} />
                <InputGroup placeholder="Search" onChange={this.handleInputSearch} value={this.state.inputSearch} disabled={this.state.filterName === 'Assigned to me'} />
                <BlueprintButton icon="search" intent="primary" disabled={this.state.filterName === 'Assigned to me'}
                  onClick={this.handleFindIssue} />
              </ControlGroup>
            </div>
            <Divider style={{ margin: '5px 0' }} />
            <div style={{ padding: '0px 0px 0px 0px', width: '100%', height: 'calc(100% - 71px)' }}>
              <Scrollbars
                renderView={renderView}
                autoHide autoHideTimeout={1000}
                autoHideDuration={200}
                thumbMinSize={30}
                universal={true}>
                <div style={{ overflow: 'auto' }}>
                  {this.listIssue(this.state.listSaveView, "issue_menu")}
                </div>
              </Scrollbars>
            </div>
          </div>
        }
        {this.state.placement === 'bottom' &&
          <Dock position={'bottom'} isVisible={this.state.displayPanel} dimMode={'none'} fluid={false} zIndex={200} defaultSize={500}>
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <TableIssue
              columns={ColumnIssue(this.props.itemId)} data={this.state.tableData}
              getRowProps={() => ({})} getCellProps={() => ({})}
              handleNewViewPointPanel={this.handleNewViewPointPanel}
              handleSwitchTable={this.handleSwitchTable}
              onActiveTable={this.handleCloseViewPanel}
              handleInfoViewPoint={this.handleInfoViewPointPanel}
              loadingMore={this.state.loadingMore}
              disableLoadMore={this.state.disableLoadMore}
              handleLoadMore={this.handleLoadMore}
              handleFindIssuePanel={this.handleFindIssuePanel}
              handleAssignToMe={this.handleAssignToMe}
              isAssignToMe={this.state.isAssignToMe}
              isProject={false}
              typeTable={'issue'}
            />

          </Dock>}
        <Menu id='issue_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.restoreSaveView.bind(this, true)}>
            <IconFont className="fas fa-check" />View Issue
                </Item>
          <Item onClick={this.restoreSaveView.bind(this, false)}>
            <IconFont className="fas fa-check-double" />View Issue With Original Version
                </Item>
          <Separator />
          <Item onClick={this.handleDeleteViewPoint} disabled={this.props.userRole !== 'admin'}>
            <IconFont className="fas fa-trash" />Delete
                  </Item>
        </Menu>

        <Menu id='other_issue_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handleOpenIssue.bind(this, true)} >
            <IconFont className="fas fa-check" />View Issue
                </Item>
          <Item onClick={this.handleOpenIssue.bind(this, false)}>
            <IconFont className="fas fa-check-double" />View Issue With Original Version
                </Item>
          <Separator />
          <Item onClick={this.handleDeleteViewPoint} disabled={this.props.userRole !== 'admin'}>
            <IconFont className="fas fa-trash" />Delete
                  </Item>
        </Menu>
        {this.state.displayNewIssuePanel &&
          <NewViewPointPanel
            open={this.state.displayNewIssuePanel}
            handleClose={this.handleCloseNewViewPointPanel}
            viewer={this.props.viewer}
            itemId={this.props.itemId}
            listSaveView={this.state.listSaveView}
            email={this.props.email}
            version={this.props.version}
            projectId={this.props.projectId}
            userCreatedProject={this.props.userCreatedProject}
            projectKey={this.props.projectKey}
            tagsOption={this.state.tagsOption}
          />}

        {this.state.displayInfoViewpoint &&
          <DialogIssueInformation
            open={this.state.displayInfoViewpoint}
            handleClose={this.handleCloseInfoViewPointPanel}
            viewer={this.props.viewer}
            viewPoint={this.state.viewPoint}
            email={this.props.email}
            projectId={this.props.projectId}
            userCreatedProject={this.props.userCreatedProject}
            listSaveView={this.state.listSaveView}
            // handleAttachFile={this.handleAttachFile}
            itemId={this.props.itemId}
            isProject={this.state.isOtherFile}
            newTab={false}
            restoreSaveView={this.restoreSaveView}
            handleOpenIssue={this.handleOpenIssue}
            tagsOption={this.state.tagsOption}
          />
        }
        {/* {this.state.displayFindIssuePanel &&
          <DialogFindIssue
            open={this.state.displayFindIssuePanel}
            handleClose={this.handleCloseFindIssuePanel}
            projectKey={this.props.projectKey}
            listSaveView={this.state.listSaveView}
            itemId={this.props.itemId}
          />} */}
        <div id='markup-issue-info-panel' style={{ display: 'none' }}>
          <div style={{ height: 100, width: 195 }}>
            <Zoom>
              <sematic.Image src={this.state.markUpIssue.img} rounded />
            </Zoom>
            <div style={{ marginBottom: 5 }}>
              <Icon icon='exclamation-triangle' style={{
                color: this.state.markUpIssue.priority === 'Very urgent' ? 'red' :
                  this.state.markUpIssue.priority === 'Urgent' ? 'orange' : this.state.markUpIssue.priority === 'Normal' ? '#f4d90b' : 'blue'
              }} />
              <Tooltip title={this.state.markUpIssue.id} placement="right" style={{ zIndex: 120000 }} >
                <Text strong ellipsis={true} style={{ position: 'absolute' }}>{' '}{this.state.markUpIssue.id}</Text>
              </Tooltip>
            </div>
            <Tooltip title={this.state.markUpIssue.title} placement="right" style={{ zIndex: 120000 }}>
              <Text strong ellipsis={true} style={{ width: '100%' }}>{this.state.markUpIssue.title}</Text>
            </Tooltip>
            <br />
            <Tooltip title={this.state.markUpIssue.description} placement="right" style={{ zIndex: 120000 }}>
              <Text ellipsis={true} style={{ width: '100%' }}>{this.state.markUpIssue.description}</Text>
            </Tooltip>
          </div>

        </div>
        <Toaster ref={this.toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />
      </div>

    );
  }
}
SideBarIssuePanel.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  }
}
export default connect(mapStateToProps)(SideBarIssuePanel)


const typeOptions = [
  { label: 'Clash', text: 'Clash', value: 'Clash' },
  { label: 'Issue', text: 'Issue', value: 'Issue' },
  { label: 'Remark', text: 'Remark', value: 'Remark' },
  { label: 'Inquiry', text: 'Inquiry', value: 'Inquiry' },
  { label: 'Solution', text: 'Solution', value: 'Solution' },
  { label: 'Design Change', text: 'Design Change', value: 'Design Change' },
  { label: 'Underdefined', text: 'Underdefined', value: 'Underdefined' }
]
const priorityOptions = [
  { label: 'Very urgent', text: 'Very urgent', value: 'Very urgent', color: 'red' },
  { label: 'Urgent', text: 'Urgent', value: 'Urgent', color: 'orange' },
  { label: 'Normal', text: 'Normal', value: 'Normal', color: '#f4d90b' },
  { label: 'Low', text: 'Low', value: 'Low', color: 'blue' },
]

function NewViewPointPanel(props) {
  const [loading, setLoading] = useState(false)
  const [loadingAttachView, setLoadingAttachView] = useState(false)
  const [blobUrl, setBlobUrl] = useState('')
  const [viewPort, setViewPort] = useState(null)
  const [dateViewPoint, setDateViewPoint] = useState(moment(Date.now()).add(14, "days"))
  const [titleViewPoint, setTitleViewPoint] = useState('')
  const [descriptionViewPoint, setDescriptionViewPoint] = useState('')
  const [type, setType] = useState('Clash')
  const [priority, setPriority] = useState('Very urgent')
  const [listAssignViewPoint, setListAssignViewPoint] = useState([])
  const [listMember, setListMember] = useState([])
  const [mainModel, setMainModel] = useState({})
  const [otherModel, setOtherModel] = useState([])
  const [attachElement, setAttachElement] = useState([])
  const [discipline, setDiscipline] = useState([])
  const [attachFile, setAttachFile] = useState([])
  const [loadingAttach, setLoadingAttach] = useState(false)
  const [attachElementColor, setAttachElementColor] = useState([])
  const [attachView, setAttachView] = useState([])
  const [descriptionImageMore, setDescriptionImageMore] = useState('')
  const [displayDescriptionImageMore, setDisplayDescriptionImageMore] = useState(false)
  const [shareWith, setShareWith] = useState(['Wohhup'])
  const [tags, setTags] = useState([])
  useEffect(() => {
    setLoading(true)
    handleChangeViewPort()
    //? member
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = []
        res.data.members.sort()
        res.data.members.splice(0, 0, props.userCreatedProject)
        _.forEach(res.data.members, v => {
          if (v !== props.email)
            temp.push({
              label: v,
              value: v,
              role: 'user'
            })
        })
        _.forEach(res.data.groupUser, v => {
          temp.push({
            label: v.name,
            value: v.name,
            role: 'group'
          })

        })
        setListMember(temp)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  // useEffect(() => {
  //   let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
  //   var markupGeneratedForLeaflet = markupCore.generateData()
  //   console.log(markupGeneratedForLeaflet)
  //   markupCore.show()
  //   markupCore.loadMarkups(markupGeneratedForLeaflet, "layerName")
  //   if (props.viewer.model.loader.isPdfLoader) {
  //     let origin = Autodesk.Viewing.PDFUtils.leafletToPdfWorld(props.viewer, new THREE.Vector3(0, 0, 0))
  //     let one = Autodesk.Viewing.PDFUtils.leafletToPdfWorld(props.viewer, new THREE.Vector3(1, 1, 1))
  //     let svg = markupCore.svg
  //     svg.firstChild.setAttribute("transform", `translate(${origin.x}, ${origin.y}) scale(${one.x - origin.x}, ${one.y - origin.y})`)
  //   }
  //   if (props.viewer.model.loader.isLeafletLoader) {
  //     let origin = Autodesk.Viewing.PDFUtils.leafletToPdfWorld(props.viewer, new THREE.Vector3(0,0,0))
  //     let one = Autodesk.Viewing.PDFUtils.leafletToPdfWorld(props.viewer, new THREE.Vector3(1,1,1))
  //     let svg = markupCore.svg
  //     svg.firstChild.setAttribute("transform", `scale(${1 / (one.x - origin.x)}, ${1 / (one.y - origin.y)}) translate(${-origin.x}, ${-origin.y})`)
  //   }
  //   markupCore.show();
  // },[])
  const handleClose = () => {
    props.handleClose(null)
  }
  const handleChangeViewPort = () => {
    let screenshot = new Image();
    screenshot.src = props.viewer.canvas.toDataURL("image/png");
    screenshot.onload = () => {
      let canvas = document.getElementById('snapshot');
      canvas.width = props.viewer.canvas.clientWidth;
      canvas.height = props.viewer.canvas.clientHeight;
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);
      try {
        let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
        markupCore.renderToCanvas(ctx, function () {
          setBlobUrl(canvas.toDataURL())
          setViewPort(props.viewer.getState())
        });
      } catch {
        setBlobUrl(screenshot.src)
        setViewPort(props.viewer.getState())
      }
    };
    handleGetColorElement()
    handleModelCapture()
  }

  const handleGetColorElement = () => {
    let models = props.viewer.impl.modelQueue().getModels()
    let count = models.length
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count--
      let count1 = temp.length
      _.forEach(temp, id => {
        // let isHide = instanceTree.isNodeHidden(id)
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            setAttachElementColor(tempElement)
          }
        })
      })
    })
  }
  const handleModelCapture = () => {
    const models = props.viewer.impl.modelQueue().getModels()
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        setMainModel({
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    setOtherModel(tempOtherModel)
  }
  const handleTileChange = (value) => {
    setTitleViewPoint(value)
  }
  const handleDescriptionChange = (e) => {
    setDescriptionViewPoint(e.target.value)
  }
  const handleDateChange = (date) => {
    setDateViewPoint(date)
  }
  const handleAssignChange = (value) => {
    setListAssignViewPoint(value)
  }
  const handleViewPointType = (value) => {
    setType(value)
  }
  const handlePriority = (value) => {
    setPriority(value)
  }
  const handleSaveViewPoint = () => {
    if (shareWith.length === 0) {
      message.warning('You have to share issue with at least one company type')
      return
    }
    if (listAssignViewPoint.length === 0)
      if (!window.confirm(`Issue aren't assign anyone. Are you sure to continue ?`)) return
    if (titleViewPoint !== '' && descriptionViewPoint !== '' && dateViewPoint !== null && type !== ''
      && priority !== '') {

      let found = _.find(props.listSaveView, e => { return e.titleViewPoint === titleViewPoint })
      if (found === undefined) {
        setLoading(true)
        let date = moment(dateViewPoint).valueOf()
        let today = moment(Date.now()).format('DD-MM-YYYY')
        let node = props.viewer.impl.model.getDocumentNode()
        // let markupGeneratedForLeaflet = null
        // try{
        //   markupGeneratedForLeaflet  = markupCore.generateData()
        // }catch{}
        let data = {
          id: '',
          titleViewPoint: titleViewPoint,
          descriptionViewPoint: descriptionViewPoint,
          dateViewPoint: date,
          listAssignViewPoint: listAssignViewPoint,
          viewPort: viewPort,
          status: 'Open',
          percent: 0,
          type: type,
          priority: priority,
          userCreated: props.email,
          dateCreated: today,
          img: blobUrl,
          dateModified: today,
          userModified: props.email,
          mainModel: mainModel,
          otherModel: otherModel,
          guid: node.data.guid,
          viewableID: node.data.viewableID,
          attachFile: [],
          attachElement: attachElement,
          discipline: discipline,
          comments: [],
          camera: { globalOffset: props.viewer.model.getData().globalOffset, up: props.viewer.getCamera().up },
          attachElementColor: attachElementColor,
          attachView: attachView,
          shareWith: shareWith,
          tags: tags,
          viewName: node.data.name,
          // modelName: props.viewer.impl.model.myData.loadOptions.modelNameOverride,
          // urn: props.viewer.impl.model.myData.urn,
          // version: props.version,
        }
        let path = `${window.location.origin}`
        if (attachFile.length === 0) {
          axios.post(`/api/issue/save-new-issue`, { projectId: props.projectId, itemId: props.itemId, projectKey: props.projectKey, issue: data, baseUrl: path })
            .then(res => {
              res.data.unReadIssue = true
              res.data.unReadComment = data.comments.length === 0 ? false : true
              if (listAssignViewPoint.includes(props.email)) {
                setLoading(false)
                props.handleClose(res.data, true)
              }
              else {
                props.handleClose(res.data, false)
                setLoading(false)
              }
              axios.post("/api/issue/set-issue-unread", { label: res.data.id, email: props.email }).catch(() => { })
              message.success(`Create new issue.`);
            })
            .catch(() => {
              setLoading(false)
              message.error(`Can't create new issue.`);
            })
        } else {
          let formData = new FormData()
          _.forEach(attachFile, f => {
            formData.append('files', f.file)
          })
          formData.append('projectId', props.projectId)
          formData.append('itemId', props.itemId)
          formData.append('projectKey', props.projectKey)
          formData.append('issue', JSON.stringify(data))
          formData.append('baseUrl', path)
          axios.post(`/api/issue/save-new-issue-with-files`, formData, { timeout: 60 * 10 * 1000 })
            .then(res => {
              if (listAssignViewPoint.includes(props.email)) {
                setLoading(false)
                props.handleClose(res.data, true)
              }
              else {
                props.handleClose(res.data, false)
                setLoading(false)
              }
              message.success(`Create new issue.`);
            })
            .catch(() => {
              setLoading(false)
              message.error(`Can't create new issue.`);
            })
        }

      }
      else {
        setLoading(false)
        message.error(`Title issue was exist`);
      }
    }
    else {
      setLoading(false)
      message.error(`You need input`);
    }
  }
  const handleGetAttachElement = () => {
    const models = props.viewer.impl.modelQueue().getModels()
    let tempSelection = []
    let count = attachElement.length
    _.forEach(attachElement, async model => {
      let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === model.itemId })
      if (index >= 0) {
        let instanceTree = models[index].getData().instanceTree;
        let tempIds = getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, models[index], 'all');
        let externalId = await checkExternalId(tempIds, models[index], model.guid)
        tempSelection.push({ model: models[index], ids: externalId })
      }
      count--
      if (count === 0) {
        props.viewer.impl.selector.setAggregateSelection(tempSelection)
      }
    })

    // props.viewer.impl.selector.setAggregateSelection()
  }
  const handleSetAttachElement = () => {
    if (attachElement.length !== 0)
      if (!window.confirm('Are you sure to override elements?')) return
    let temp = []
    var currSelection = props.viewer.impl.selector.getAggregateSelection()
    let count = currSelection.length
    _.forEach(currSelection, async item => {
      let guid = await getExternalId(item)
      temp.push({ itemId: item.model.myData.loadOptions.itemId, guid: guid })
      count--
      if (count === 0) {
        setAttachElement(temp)
      }
    })
  }

  const handleCustomRequest = ({ file }) => {
    if (file !== null) {
      setLoadingAttach(true)
      let temp = attachFile.slice()
      let index = _.findIndex(temp, e => { return e.name === file.name })
      if (index < 0) {
        temp.push({ uid: temp.length + 1, name: file.name, url: '', status: 'done', file: file })
      } else {
        temp[index].file = file
      }
      setAttachFile(temp)
      setLoadingAttach(false)
    }
  }
  const handleRemoveFile = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setLoadingAttach(true)
    let temp = attachFile.slice()
    let index = _.findIndex(temp, e => { return e.name === file.name })
    if (index >= 0)
      temp.splice(index, 1)
    setAttachFile(temp)
    setLoadingAttach(false)
  }
  const beforeUpload = (file) => {
    const isImgIssue = file.name.toLowerCase().includes(props.projectKey.toLowerCase())
    if (isImgIssue) {
      message.warning('Please change name your image');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.warning('Image must smaller than 20MB!');
    }
    return isLt2M && !isImgIssue;
  }
  const handleChangeDiscipline = (value) => {
    setDiscipline(value)
  }
  const handleScreenShot = () => {
    setLoadingAttachView(true)
    setDisplayDescriptionImageMore(false)
    let guid = uuidv4()
    let data = {}
    let tempData = attachView.slice()
    let node = props.viewer.impl.model.getDocumentNode()
    const models = props.viewer.impl.modelQueue().getModels()
    data.identify = guid
    data.guid = node.data.guid
    data.viewableID = node.data.viewableID
    data.viewName = node.data.name
    data.viewPort = props.viewer.getState()
    data.userCreated = props.email
    data.description = descriptionImageMore
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        data.mainModel = {
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        }
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    data.otherModel = tempOtherModel

    let count = models.length
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count--
      let count1 = temp.length
      _.forEach(temp, id => {
        // let isHide = instanceTree.isNodeHidden(id)
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            data.attachElementColor = tempElement
            let screenshot = new Image();
            screenshot.src = props.viewer.canvas.toDataURL("image/png");
            screenshot.onload = () => {
              let canvas = document.getElementById('snapshot');
              canvas.width = props.viewer.canvas.clientWidth;
              canvas.height = props.viewer.canvas.clientHeight;
              let ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

              try {
                let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                markupCore.renderToCanvas(ctx, () => {
                  data.img = canvas.toDataURL()
                  tempData.push(data)
                  setAttachView(tempData)
                  setLoadingAttachView(false)
                  setDescriptionImageMore('')
                });
              } catch {
                data.img = screenshot.src
                tempData.push(data)
                setAttachView(tempData)
                setLoadingAttachView(false)
                setDescriptionImageMore('')

              }
            }
          }
        })
      })
    })
  }
  const handleDeleteAttachView = (e) => {
    let temp = attachView.slice()
    let index = _.findIndex(temp, v => { return v.guid === e.props.item.guid })
    if (index >= 0) {
      temp.splice(index, 1)
      setAttachView(temp)
    }
  }
  const handleDescriptionImageMore = (e) => {
    setDescriptionImageMore(e.target.value)
  }
  const handleChangeImageMorePanel = (visible) => {
    setDisplayDescriptionImageMore(visible)
  }
  const handleChangeShareWith = (value) => {
    setShareWith(value)
  }
  const handleChangeTags = (value) => {
    setTags(value)
  }
  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleClose} keyboard={false}
        placement='left' size='xs' className='drawerBimAppPanel'>
        <Drawer.Header>
          <Drawer.Title>
            <Title style={{ display: 'inline', marginLeft: '5px' }} level={4} >New Issue</Title>
          </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            <div style={{ position: 'relative', height: 'fit-content', width: 'fit-content' }}>
              <sematic.Image src={blobUrl} rounded />
              <IconButton style={{ top: 0, position: 'absolute' }} size="xs" icon={<Icon icon="refresh" />} onClick={handleChangeViewPort}></IconButton>
            </div>
            <div style={{ position: 'relative' }}>
              <Text strong>Title (required)</Text>
              <Input style={{ width: '100%', marginBottom: '1em' }} onChange={handleTileChange} size="sm" />

              <Text strong>Description (required)</Text>
              {/* <Input componentClass="textarea" style={{ minWidth: '100%', resize: 'auto', marginBottom: '1em' }} rows={3}
          onChange={this.handleDescriptionChange} size="sm" /> */}
              <TextArea onChange={handleDescriptionChange} size='small' style={{ marginBottom: '1em' }} autoSize={{ minRows: 3 }} />

              <Text strong>Discipline</Text>
              <CheckboxGroup
                inline
                value={discipline}
                onChange={handleChangeDiscipline}
                style={{ marginBottom: '1em' }}
              >
                <Checkbox value={"Archi"}>Archi</Checkbox>
                <Checkbox value={"C&S"}>{`C&S`}</Checkbox>
                <Checkbox value={'M&E'}>{`M&E`}</Checkbox>
              </CheckboxGroup>

              <Text strong>Due date</Text>
              <DatePicker style={{ width: '100%', marginBottom: '1em' }} format="DD-MM-YYYY" onChange={handleDateChange} size="sm" placement='rightEnd'
                value={dateViewPoint} cleanable={false}
                ranges={[
                  {
                    label: '1D',
                    value: moment(Date.now()).add(1, 'day')
                  },
                  {
                    label: '1W',
                    value: moment(Date.now()).add(1, 'week')
                  },
                  {
                    label: '2W',
                    value: moment(Date.now()).add(2, 'weeks')
                  },
                  {
                    label: '4W',
                    value: moment(Date.now()).add(4, 'weeks')
                  },
                ]} />

              <Text strong>Type</Text>
              <InputPicker
                data={typeOptions} size='sm' cleanable={false}
                placeholder="Select Type" onChange={handleViewPointType}
                defaultValue={type} placement='rightEnd'
                style={{ marginBottom: '1em' }} block
                renderValue={(value) => {
                  return (
                    <div style={{ color: 'black' }}>
                      {value}
                    </div>
                  );
                }}
              />

              <Text strong>Priority</Text>
              <InputPicker
                data={priorityOptions} size='sm' cleanable={false}
                placeholder="Select Priority" onChange={handlePriority}
                value={priority} placement='rightEnd'
                style={{ marginBottom: '1em' }} block
                renderMenuItem={(label, item) => {
                  return (
                    <div >
                      <i className="rs-icon rs-icon-exclamation-triangle" style={{ color: item.color }} /> {label}
                    </div>
                  );
                }}
                renderValue={(value, item) => {
                  return (
                    <div >
                      <i className="rs-icon rs-icon-exclamation-triangle" style={{ color: item.color }} /> {value}
                    </div>
                  );
                }}
              />

              <Text strong>Share with</Text>
              <br />
              <CheckboxGroup
                inline
                value={shareWith}
                onChange={handleChangeShareWith}
                style={{ marginBottom: '1em' }}
              >
                <Checkbox value={"Wohhup"}>Wohhup</Checkbox>
                <Checkbox value={"Sub-con"}>{`Sub-con`}</Checkbox>
                <Checkbox value={'Consultant'}>{`Consultant`}</Checkbox>
                <Checkbox value={'Client'}>{`Client`}</Checkbox>
              </CheckboxGroup>
              {/* <RadioGroup inline
                onChange={handleChangeShareWith}
                value={shareWith}
                style={{ marginBottom: '1em' }}>
                <Radio value="All">All</Radio>
                <Radio value="Wohhup">Wohhup</Radio>
                <Radio value="Wohhup & Sub-con">Wohhup & Sub-con</Radio>
              </RadioGroup> */}

              <Text strong >Tags</Text>
              <Select mode="tags" style={{ width: '100%', marginBottom: '1em' }} placeholder="Tags" onChange={handleChangeTags} value={tags}   >
                {props.tagsOption.map((tag, k) =>
                  <Option key={tag.toString()}>{tag.toString()}</Option>)}
              </Select>

              <Text strong>Assignees</Text>
              <TagPicker data={listMember} style={{ width: '100%', marginBottom: '1em' }} onChange={handleAssignChange} placement='rightEnd' size="sm" groupBy="role"
                renderMenu={menu => {
                  if (loading) {
                    return (
                      <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Icon icon="spinner" spin /> Loading...
                      </p>
                    );
                  }
                  return menu;
                }}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      {/* <i className={item.role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} */}
                      <Icon icon={item.role === 'user'?'user':'group'} /> {label}
                    </div>
                  );
                }}
                renderMenuGroup={(label, item) => {
                  return (
                    <div>
                      {/* <i className={item.children[0].role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} - (
                      {item.children.length}) */}
                      <Icon icon={item.children[0].role === 'user'?'user':'group'} />  {label} - (
                      {item.children.length})
                    </div>
                  );
                }}
                renderValue={(values, items, tags) => {
                  return items.map((tag, index) => (
                    <Tag style={{ color: tag.role === 'user' ? '#8ab4f8' : '#ff7171' }}>
                          <Icon icon={tag.role === 'user'?'user':'group'} /> {tag.label}
                      </Tag> 
                  ));
                }}
              />

              <Text strong> Attachments</Text>
              <Upload name='file'
                multiple={false}
                showUploadList={true}
                className='upload-attachments'
                customRequest={handleCustomRequest} fileList={attachFile} onRemove={handleRemoveFile} beforeUpload={beforeUpload} >
                <Button appearance="ghost" block loading={loadingAttach}>
                  <Icon icon="link" /> Upload
                </Button>
              </Upload>

              <Text strong> Attach View</Text>
              <Popover visible={displayDescriptionImageMore} onVisibleChange={handleChangeImageMorePanel}
                content={
                  <>
                    <TextArea size='small' autoSize={{ minRows: 3 }} style={{ width: 400 }} onChange={handleDescriptionImageMore} value={descriptionImageMore}
                      disabled={loadingAttachView} />
                  </>
                }
                title={
                  <>
                    <Text strong>Description</Text>
                    <IconButton style={{ float: 'right' }} size="xs" loading={loadingAttachView}
                      icon={<Icon icon="plus-circle" />} color='blue' onClick={handleScreenShot}></IconButton>
                  </>
                } trigger="click">
                <Button appearance="ghost" size='sm' block style={{ marginBottom: 5 }} loading={loadingAttachView}>
                  <Icon icon="camera" /> Screenshot
            </Button>
              </Popover>

              {attachView.length !== 0 ?
                <div style={{ padding: 7 }}>
                  <List
                    grid={{ gutter: 8, column: 3 }}

                    dataSource={attachView}
                    renderItem={item => (
                      <List.Item style={{ marginBottom: '0px' }}>
                        <MenuProvider id="attachView_menu" animation={animation.zoom} data={{ item: item }}>
                          <Zoom wrapStyle={{ border: '1px #000000 solid', borderRadius: '2px' }}>
                            <sematic.Image style={{ width: '100%' }} src={item.img} rounded />
                          </Zoom>
                        </MenuProvider>
                      </List.Item>
                    )}
                  />
                </div> :
                <Paragraph>No Data</Paragraph>
              }

              <Text style={{ marginTop: '1em' }} strong>{`Attach Element (${attachElement.length})`}</Text>
              <ButtonGroup justified>
                <Button appearance="ghost" size='sm' disabled={attachElement.length === 0} onClick={handleGetAttachElement}>
                  <Icon icon="download2" /> Get
            </Button>
                <Button appearance="ghost" size='sm' onClick={handleSetAttachElement}>
                  <Icon icon="upload2" /> Set
            </Button>
              </ButtonGroup>





            </div>

          </Scrollbars>

        </Drawer.Body>
        <Divider />
        <Drawer.Footer style={{ marginBottom: 5 }}>
          <Button onClick={handleSaveViewPoint} appearance="primary" loading={loading} >
            Create
    </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
    </Button>
        </Drawer.Footer>
      </Drawer>

      <Menu id='attachView_menu' style={{ zIndex: 10000 }}>
        <Item onClick={handleDeleteAttachView}>
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
    </>
  )
}



