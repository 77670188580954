import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Button } from "antd";
import {
   ComposedChart,
   Bar,
   LabelList,
   Cell,
   ResponsiveContainer,
   XAxis,
   YAxis,
   Tooltip,
   Label,
   CartesianGrid,
} from "recharts";

const ChartProductivityRange = ({ current_project, projects, data, rangeSelected, setRangeSelected }) => {
   const [activeIndex, setActiveIndex] = useState();
   const activeItem = "";

   useEffect(() => {
      {
         setActiveIndex(null);
      }
   }, [rangeSelected]);

   const handleClick = useCallback(
      (entry, index) => {
         setActiveIndex(index);
         if (entry !== undefined) {
            setRangeSelected(entry.name);
         }
      },
      [setActiveIndex]
   );

   const handleClearSelection = () => {
      setRangeSelected("All");
   };

   return (
      <div style={{ minHeight: "450px" }}>
         <h5 style={{ textAlign: "left" }}>Number of units per productivity range </h5>
         <Button
            size={"small"}
            onClick={() => {
               handleClearSelection();
            }}
         >
            Clear selection
         </Button>
         <ResponsiveContainer width="100%" minHeight={"420px"}>
            <ComposedChart
               data={data}
               margin={{
                  top: 15,
                  right: 10,
                  left: -25,
                  bottom: 5,
               }}
            >
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               {/* <Tooltip cursor={false} content={<CustomTooltip />} /> */}
               <XAxis
                  dataKey="name"
                  tickLine={false}
                  height={70}
                  dx={-10}
                  dy={10}
                  interval={0}
                  // minTickGap={-200}
               ></XAxis>
               <Label
                  value="Fail items (%)"
                  // offset={0}
                  position="top"
                  style={{
                     fontWeight: 700,
                     fontSize: "16px",
                  }}
               />
               <YAxis type="number" domain={[0, "dataMax + 10"]} />

               <Bar dataKey="count" onClick={handleClick}>
                  <LabelList dataKey="count" position="top" fontSize="11px" fill="black" />
                  {data
                     ? data.map((entry, index) => (
                          <Cell
                             fill="#1F618D"
                             strokeWidth={index === activeIndex || data[index].name === rangeSelected ? 2 : 0}
                             stroke="Black"
                             cursor="pointer"
                             key={index}
                          />
                       ))
                     : null}
               </Bar>
            </ComposedChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartProductivityRange;
