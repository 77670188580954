export const USER_LOGGED_IN ="USER_LOGGED_IN";
export const USER_LOGGED_OUT ="USER_LOGGED_OUT";


export const EXCEL_ITEMS ='EXCEL_ITEMS'
export const DATAENTRY_ITEMS ='DATAENTRY_ITEMS'
export const DATAENTRY_ITEMS_LOADING ='DATAENTRY_ITEMS_LOADING'
export const DATAENTRY_ITEMS_OPEN_HISTORY ='DATAENTRY_ITEMS_OPEN_HISTORY'

export const MANPOWER_ITEMS ='MANPOWER_ITEMS'
export const MANPOWER_ITEMS_LOADING ='MANPOWER_ITEMS_LOADING'

export const PDF_VIEWER ='PDF_VIEWER'

export const PROJECT_DETAIL ="PROJECT_DETAIL";
export const FOLDER_DETAIL ="FOLDER_DETAIL";
export const ITEM_DETAIL ="ITEM_DETAIL";
export const APP_REDIRECT ="APP_REDIRECT";

export const FETCH_DATA_ONE_SHEET = 'FETCH_DATA_ONE_SHEET';
export const SET_USER_DATA = 'SET_USER_DATA';
export const GET_CELL_MODIFIED_TEMP = 'GET_CELL_MODIFIED_TEMP';
export const OVERWRITE_CELLS_MODIFIED = 'OVERWRITE_CELLS_MODIFIED';
export const SET_CELL_ACTIVE = 'SET_CELL_ACTIVE';
export const COPY_TEMP_DATA = 'COPY_TEMP_DATA';
export const APPLY_ACTION_ON_CELL = 'APPLY_ACTION_ON_CELL';
export const GET_SHEET_ROWS = 'GET_SHEET_ROWS';
export const OVERWRITE_CELL_REDUCER = 'OVERWRITE_CELL_REDUCER';
export const OVERWRITE_ROW_REDUCER = 'OVERWRITE_ROW_REDUCER';
export const OVERWRITE_PROJECT_REDUCER = 'OVERWRITE_PROJECT_REDUCER';



