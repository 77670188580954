import React, { useState, useCallback, useEffect } from "react";

import {
   ComposedChart,
   Bar,
   LabelList,
   Cell,
   Line,
   ResponsiveContainer,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
   Label,
   CartesianGrid,
} from "recharts";

const ChartSubSummary = ({ current_project, projects, data, subSelected, setSubSelected }) => {
   const [activeIndex, setActiveIndex] = useState();
   const activeItem = "";

   useEffect(() => {
      {
         setActiveIndex(null);
      }
   }, [subSelected]);

   const handleClick = useCallback(
      (entry, index) => {
         setActiveIndex(index);
         if (entry !== undefined) {
            setSubSelected(entry.name);
         }
      },
      [setActiveIndex]
   );

   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div style={{ backgroundColor: "rgba(255, 255, 0, 0.35)" }}>
               <p style={{ fontWeight: "bold", color: "blue" }}>{payload[0].payload["name"]}</p>
               {/* <p style={{ color: "blue" }}>{`${payload[0].value}%`}</p> */}
               <p
                  style={{ color: "blue" }}
               >{`Last month_No:${payload[0].payload["total_no_current_month"]}/${payload[0].payload["total_current_month"]}`}</p>
               <p
                  style={{ color: "blue" }}
               >{`All_No:${payload[0].payload["total_no"]}/${payload[0].payload["total"]}`}</p>
            </div>
         );
      }

      return null;
   };

   return (
      <div>
         <br />
         <h6>Total By Each Sub-con </h6>
         <ResponsiveContainer width="100%" minHeight={"25vh"}>
            <ComposedChart
               data={data}
               margin={{
                  top: 15,
                  right: 5,
                  left: 5,
                  bottom: 20,
               }}
            >
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <Tooltip cursor={false} content={<CustomTooltip />} />
               <XAxis
                  dataKey="short_name"
                  tickLine={false}
                  angle={-90}
                  height={70}
                  dx={-10}
                  dy={25}
                  interval={0}
                  // minTickGap={-200}
               ></XAxis>
               <Label
                  value="Fail items (%)"
                  // offset={0}
                  position="top"
                  style={{
                     fontWeight: 700,
                     fontSize: "16px",
                  }}
               />
               <YAxis type="number" domain={[0, "dataMax + 10"]} />

               <Bar dataKey="total" onClick={handleClick}>
                  <LabelList dataKey="total" position="top" fontSize="11px" fill="black" />
                  {data
                     ? data.map((entry, index) => (
                          <Cell
                             fill="#82ca2d"
                             strokeWidth={index === activeIndex || data[index].name === subSelected ? 2 : 0}
                             stroke="Black"
                             cursor="pointer"
                             key={index}
                          />
                       ))
                     : null}
               </Bar>
            </ComposedChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartSubSummary;
