import React from 'react';
import { Button, Checkbox, Dropdown, } from 'semantic-ui-react'
import { Modal, Loader } from 'rsuite';
import { DatePicker } from 'antd';
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment';

import {
  ArchiSummary,
  MDLReport,
  SoldReport
} from '../../module_BimApp/dock_forge'

const { RangePicker } = DatePicker;


class DialogReportPile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPanel: false,
      // listTempNoMS :[],
      // listTempNoDS :[],
      // listTempNo :[],
      listTempDS: {},
      alreadyLoad: true,
      startReport: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      endReport: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      checkHighlightElement: true,

      reportType: 'archiSummaryDock',
      listSheetReport: [],
      smartSheetId: null,
      sheerReportId: null,
      openCustomDockForge: {
        archiSummaryDock: false,
        mdlReportDock: false,
        soldReportDock: false
      }
    }

  }


  componentWillMount = () => {

  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.folderSmartSheetId !== null && nextProps.folderSmartSheetId !== undefined && nextProps.folderSmartSheetId !== "" && nextProps.openPanel) {
      let temp = this.state.openCustomDockForge
      _.forEach(temp, (v, k) => {
        temp[k] = false
      })
      this.setState({ loading: true, openPanel: nextProps.openPanel, openCustomDockForge: temp })
      axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: nextProps.folderSmartSheetId })
        .then(res => {
          var tempListSheet = []
          res.data.forEach(item => {
            if (!this.props.isClient|| item.name.includes('(External)')) {//item.name.contains('Report')
              tempListSheet.push({ key: item.name, text: item.name, value: { id: item.id, name: item.name } })
            }
          })
          let d = new Date();
          d.setMonth(d.getMonth() - 1);
          let tempStartDate = moment(d).format('DD-MM-YYYY')
          this.setState({ listSheetId: tempListSheet, startReport: tempStartDate, loading: false, openPanel: nextProps.openPanel })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
    // if (this.state.alreadyLoad && nextProps.openPanel) {
    //   this.setState({ alreadyLoad: false })
    //   var listTempDS = {}
    //   var count = this.props.allDbIds.length;
    //   this.props.allDbIds.forEach((modelAdbId) => {
    //     this.props.viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
    //       var tempValueMark = ''
    //       var tempSize = ''
    //       // var tempValueMarkMS = ''
    //       var name = modelAProperty.name.split(" [")[0]
    //       // if (name === 'PILE') {

    //       // }
    //       for (var i in modelAProperty.properties) {
    //         if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
    //           tempValueMark = modelAProperty.properties[i].displayValue
    //         }
    //         else if (modelAProperty.properties[i].displayName === 'PILE DIAMETER') {
    //           tempSize = modelAProperty.properties[i].displayValue
    //         }
    //         if (i === (modelAProperty.properties.length - 1).toString()) {
    //           if (tempValueMark !== '') {
    //             if (!listTempDS[tempValueMark])
    //               listTempDS[tempValueMark] = []
    //             listTempDS[tempValueMark].push({ id: modelAdbId, name: name, size: tempSize })
    //           }
    //         }
    //       }
    //       count--;
    //       if (count === 0) {
    //         this.setState({ listTempDS: listTempDS })
    //       }
    //     })
    //   })
    // }
  }


  handleApplyReport = () => {
    // this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === this.state.reportType) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    this.setState({ openCustomDockForge: temp, openPanel: false })
    this.props.onChangeDisplay('dialogReport', false)
    // axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.smartSheetId })
    //   .then(async res => {
    //     var tempDataSheet = res.data
    //     if (this.state.reportType === 'Report Pile') {
    //       var tempData = await reportPile(tempDataSheet, this.props.viewer, this.state.listTempDS, this.state.checkHighlightElement, this.state.startReport, this.state.endReport)
    //       if (reportPilePanel === null) {
    //         reportPilePanel = new ReportPilePanel(this.props.viewer, { id: 'reportPileExtensionPanel', title: 'Report Pile' })
    //       }
    //       reportPilePanel.setVisible(true, tempData.data, tempData.total);
    //     } else if (this.state.reportType === 'Report Concrete') {
    //       var tempData = await reportConcrete(tempDataSheet, this.props.viewer, this.state.listTempDS, this.state.checkHighlightElement, this.state.startReport, this.state.endReport)
    //       if (reportConcretePanel === null) {
    //         reportConcretePanel = new ReportConcretePanel(this.props.viewer, { id: 'reportConcretextensionPanel', title: 'Report Concrete' })
    //       }
    //       reportConcretePanel.setVisible(true, tempData.data, tempData.total);
    //     }
    //     this.setState({ openPanel: false, reportType: null, smartSheetId: null })
    //     this.props.onChangeDisplay('dialogReport', false)
    //   })

  }
  handleClosePanel = () => {
    this.setState({ openPanel: false })
    this.props.onChangeDisplay('dialogReport', false)
  }
  handleChangeReportType = (e, { value }) => {
    this.setState({ reportType: value })
  }
  handleChangeSheetReport = (e, { value }) => {
    this.setState({ smartSheetId: value.id })
  }
  onChange = (dates) => {
    this.setState({
      startReport: dates[0].format("DD-MM-YYYY"),
      endReport: dates[1].format("DD-MM-YYYY")
    })
  }
  handleHighlightElement = (value, checked) => {
    this.setState({ checkHighlightElement: checked })
  }
  callbackDisplayDock = (name, value) => {
    let temp = this.state.openCustomDockForge
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    this.setState({ openCustomDockForge: temp })
  }
  render() {
    const reportOptions = [
      {
        key: 'MDL Report',
        text: 'MDL Report',
        value: 'mdlReportDock',
      },
      {
        key: 'Sold Report',
        text: 'Sold Report',
        value: 'soldReportDock',
      },
      {
        key: 'Archi Summary',
        text: 'Archi Summary',
        value: 'archiSummaryDock',
      },
    ]
    return (
      <div>
        <Modal backdrop={'static'} show={this.state.openPanel} onHide={this.handleClosePanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Report</Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyCompareVersion'>
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <Dropdown
              placeholder='Report Type'
              fluid
              selection value={this.state.reportType}
              onChange={this.handleChangeReportType}
              options={reportOptions}
            />
            <br />
            <Dropdown
              placeholder='Smartsheet Report'
              fluid
              selection
              onChange={this.handleChangeSheetReport}
              options={this.state.listSheetId}
            />
            <br />
            {(this.state.reportType !== 'archiSummaryDock' && this.state.reportType !== 'mdlReportDock'&& this.state.reportType !== 'soldReportDock') &&
              <div>
                <RangePicker style={{ width: '100%' }} format={'DD-MM-YYYY'}
                  value={[moment(this.state.startReport, 'DD-MM-YYYY'), moment(this.state.endReport, 'DD-MM-YYYY')]}
                  onChange={this.onChange} />
                <br />
                <br />
                <Checkbox label='Highlight those completed but not within duration selected' checked={this.state.checkHighlightElement}
                  onChange={this.handleHighlightElement} />
              </div>
            }
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={this.handleApplyReport.bind(this)} color='blue' disabled={this.state.reportType === null || this.state.smartSheetId === null}>
              Apply
            </Button>
            <Button onClick={this.handleClosePanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.openCustomDockForge.archiSummaryDock && <ArchiSummary openDock={this.state.openCustomDockForge.archiSummaryDock} viewer={this.props.viewer}
          smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock} />}

        {this.state.openCustomDockForge.mdlReportDock && <MDLReport openDock={this.state.openCustomDockForge.mdlReportDock} viewer={this.props.viewer}
          smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock} />}

        {this.state.openCustomDockForge.soldReportDock && <SoldReport openDock={this.state.openCustomDockForge.soldReportDock} viewer={this.props.viewer}
          smartSheetId={this.state.smartSheetId} onChangeDockDisplay={this.callbackDisplayDock} />}

      </div>

    );
  }
}


export default DialogReportPile