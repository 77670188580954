import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon, Select } from 'antd'
import _ from 'lodash';

import { setPDFData } from '../../../../actions/app/pdf'
const { Option } = Select;
const scale = [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
function InputScale(props) {

    function handleChange(value) {
        console.log(`selected ${value}`);
        props.setPDFData({ scale: value })
    }

    return (
        <>
            <Button size='small' className='idd-app-pdf-feature' onClick={() => {
                props.setPDFData({ scale: props.pdfViewer.scale  <=1? 1: props.pdfViewer.scale - 0.25 })
            }} >
                <Icon type="minus-circle" />
            </Button>
            <Select value={props.pdfViewer.scale} size='small' style={{ width: 80 }} onChange={handleChange}>
                {scale.map(i =>
                    <Option value={i}>{`${i * 100}%`}</Option>
                )}
            </Select>

            <Button size='small' className='idd-app-pdf-feature'onClick={() => {
                props.setPDFData({ scale: props.pdfViewer.scale  >=3? 3: props.pdfViewer.scale + 0.25 })
            }} >
                <Icon type="plus-circle" />
            </Button>

        </>
    )
}

InputScale.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(InputScale)