import { Checkbox, DatePicker, Icon, Input, message, Modal, Select, TimePicker, Tooltip, Upload, Button, Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import styled from 'styled-components';
import { arrayStatusDrawingText, arrayTextStatusQrQrm, arrayTextStatusRfc, colorType, EDIT_DURATION_MIN, versionTextArray } from '../../../constants';
import { checkIfStringContainNumberOnly, debounceFnc, generateShortUid, getCompanyNameFromLink, getConsultantStatusReviewByCompany, getDisciplineOfRefString, getRefStringWithVersion, mongoObjectId, validateEmailInput } from '../../../utils';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { getGroupCompanyForBothSideActionUserSubmitWithoutEmail, getSuggestedNewRefNumber } from '../pageSpreadsheet/PanelAddNewRFA';
import { getDataForMultiFormSheet, getKeyTextForSheet } from '../pageSpreadsheet/PanelSetting';
import ButtonGroupComp from './ButtonGroupComp';
import ButtonStyle from './ButtonStyle';
import TableDrawingRfaForMultiForm from './TableDrawingRfaForMultiForm';
import IconTable from './IconTable';
import PrintPdf from './PrintPdf';
import TableDrawingDmsToIssueForConstruction from './TableDrawingDmsToIssueForConstruction';
import { apiFetchDataProjectByDiscipline, apiFetchDataMultiByDisciplineForm } from '../../../api';
import { getOutputRowsAllSorted } from './ComponentDmsOverall';
import _ from 'lodash';
import ListRequirementForm from './ListRequirementForm';



const { Option } = Select;
const { TextArea } = Input;


const extractConsultantName = (name) => {
   const indexOfSplitString = name.indexOf('_%$%_');
   return name.slice(0, indexOfSplitString === -1 ? -99999 : indexOfSplitString);
};

const checkIfMatchWithInputCompanyFormat = (item, listConsultants) => {
   let result = false;
   listConsultants.forEach(cm => {
      if (cm.company === extractConsultantName(item)) {
         result = true;
      };
   });
   return result;
};



const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};



const PanelAddNewMultiForm = ({ onClickCancelModal, onClickApplySendFormToSignature, getSheetRows, stateRow, stateProject, submissionMultiFormUpdateOrForwardEmail }) => {




   const {
      roleTradeCompany: { role, company: companyUser }, companies, listUser, email,
      listGroup: listGroupOutput, projectName, projectNameShort: projectNameShortText, pageSheetTypeName, isBothSideActionUser,
      publicSettings, token, projectId, isSubconCanSubmit
   } = stateProject.allDataOneSheet;

   const projectNameShort = projectNameShortText || 'NO-PROJECT-NAME';

   const {
      rowsAll, loading, currentRefToAddNewOrReplyOrEdit,
      rowsFormAllInit
   } = stateRow;

   const { currentRefData, formRefType, isFormEditting, withNoEmailSent, consultantNameToReplyByBothSideActionUser } = currentRefToAddNewOrReplyOrEdit || {};

   const refType = getKeyTextForSheet(pageSheetTypeName);
   const refKey = refType + 'Ref';


   const currentTab = window.dms_disciplineTabThisPage;

   const disciplineForm = (publicSettings.disciplineForm || []).filter(dis => {
      const disParts = dis.split('/');
      const firstPartDis = disParts[0];

      if (firstPartDis !== currentTab && currentTab !== 'ALL') return false;

      let isSubconTradeFound = false;
      if (refType === 'rfc') {
         const subconShortName = disParts[1];

         if (isSubconCanSubmit) { // SUBCON
            const shortNameCompanyThisUser = (companies.find(x => x['company'] === companyUser) || {})['shortName'];
            isSubconTradeFound = shortNameCompanyThisUser === subconShortName;
         } else { // WH DC migrate for all subcon
            isSubconTradeFound = companies.find(x => x['shortName'] === subconShortName);
         };
      };

      return (refType === 'mm' && (firstPartDis === 'TEC' || firstPartDis === 'ICE' || firstPartDis === 'PRO')) ||
         (refType === 'rfc' && isSubconTradeFound) ||
         ((refType !== 'mm' && refType !== 'rfc') && firstPartDis !== 'TEC' && firstPartDis !== 'ICE' && firstPartDis !== 'PRO');
   }).sort();









   const company = (formRefType === 'form-reply-multi-type' && isBothSideActionUser && consultantNameToReplyByBothSideActionUser)
      ? consultantNameToReplyByBothSideActionUser
      : companyUser;

   const listConsultants = companies.filter(x => {
      if (pageSheetTypeName === 'page-rfc') {
         return x.companyType === 'Main con';
      } else {
         return x.companyType === 'Consultant' || ((x.companyType.toLowerCase() === 'sub-con' || x.companyType === 'Client') && pageSheetTypeName === 'page-dt');
      };
   });


   let listGroup = ((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent)
      ? getGroupCompanyForBothSideActionUserSubmitWithoutEmail(listGroupOutput, listConsultants)
      : listGroupOutput;

   let listRecipient = ((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent)
      ? getGroupCompanyForBothSideActionUserSubmitWithoutEmail(listGroupOutput, listConsultants)
      : [...listUser, ...listGroupOutput];


   if (pageSheetTypeName === 'page-rfc') {
      listGroup = listGroup.filter(x => !x.includes('_%$%_') || x.includes('Woh Hup Private Ltd_%$%_'));
      listRecipient = listRecipient.filter(x => !x.includes('_%$%_') || x.includes('Woh Hup Private Ltd_%$%_'));
   } else {
      listGroup = listGroup.filter(x => !x.includes('Woh Hup Private Ltd_%$%_'));
      listRecipient = listRecipient.filter(x => !x.includes('Woh Hup Private Ltd_%$%_'));
   };


   const companyReviewStatus = publicSettings.companyReviewStatus;
   let consultantStatus, consultantStatusObj;
   if (formRefType === 'form-reply-multi-type' && currentRefData) {
      const consultantStatusReviewByCompanyObj = getConsultantStatusReviewByCompany({ arrayStatusDrawingText, refType, currentRefData, company, companyReviewStatus });
      consultantStatus = consultantStatusReviewByCompanyObj['consultantStatus'];
      consultantStatusObj = consultantStatusReviewByCompanyObj['consultantStatusObj'];
   };

   
   const [tradeForFirstTimeSubmit, setTradeForFirstTimeSubmit] = useState('');

   const [refNumberSuffixFirstTimeSubmit, setRefNumberSuffixFirstTimeSubmit] = useState('');
   const [refNewVersionResubmitSuffix, setRefNewVersionResubmitSuffix] = useState('');

   const [consultantReplyStatus, setConsultantReplyStatus] = useState('');

   const [submissionType, setSubmissionType] = useState('');

   const [dateReplyForSubmitForm, setDateReplyForSubmitForm] = useState(null);

   const [dateConversation, setDateConversation] = useState(null);
   const [timeConversation, setTimeConversation] = useState(null);

   const [tablePickDrawingRfaSubmitted, setTablePickDrawingRfaSubmitted] = useState(false);

   const [nosColumnFixed, setNosColumnFixed] = useState(1);

   const [formReplyUpload, setFormReplyUpload] = useState({});
   const [filesPdfDrawing, setFilesPdfDrawing] = useState({});
   const [dwgsImportFromRFA, setDwgsImportFromRFA] = useState([]);

   const [formsToLinkQrOrQrm, setFormsToLinkQrOrQrm] = useState([]);

   const [dataInputForTable, setDataInputForTable] = useState([]);


   const [listRecipientTo, setListRecipientTo] = useState([]);
   const [listRecipientCc, setListRecipientCc] = useState([]);

   const [listConsultantMustReply, setListConsultantMustReply] = useState([]);
   const [requestedBy, setRequestedBy] = useState('');
   const [signaturedBy, setSignaturedBy] = useState('');
   const [recipientName, setRecipientName] = useState('');

   const [textEmailTitle, setTextEmailTitle] = useState('');
   const [conversationAmong, setConversationAmong] = useState('');

   const [description, setDescription] = useState('');
   const [listTagForDtForm, setListTagForDtForm] = useState([]);
   const [contractSpecification, setContractSpecification] = useState('');
   const [proposedSpecification, setProposedSpecification] = useState('');

   const [modalConfirmsubmitOrCancel, setModalConfirmsubmitOrCancel] = useState(null);

   const [isCostImplication, setIsCostImplication] = useState(true);
   const [isTimeExtension, setIsTimeExtension] = useState(true);

   const [herewithForDt, setHerewithForDt] = useState('');
   const [transmittedForDt, setTransmittedForDt] = useState('');

   const [dateSendThisForm, setDateSendThisForm] = useState(null);


   const [contractDrawingNo, setContractDrawingNo] = useState('');

   const [dataDmsDrawingLink, setDataDmsDrawingLink] = useState({});
   const [arrayIdsDmsDrawingLinkPrevious, setArrayIdsDmsDrawingLinkPrevious] = useState([]);


   // Bottom left button
   const [panelUpdateForm, setPanelUpdateForm] = useState(null);
   const [listEmailForwardTo, setListEmailForwardTo] = useState([]);
   const [listEmailForwardCc, setListEmailForwardCc] = useState([]);
   const [panelConfirmChange, setPanelConfirmChange] = useState(false);


   const [listGeneralData, setListGeneralData] = useState(formRefType === 'form-reply-multi-type' ? null : [
      {
         id: generateShortUid(),
         submission: '',
      },
   ]);

   

   let isButtonDeleteFormShown = false;
   let isButtonForwardEmailShown = false;

   if (isFormEditting && formRefType !== 'form-reply-multi-type') {
      // let isNoRepliedYet = true;
      // for (const key in currentRefData) {
      //    if (key.includes(`reply-${refType}-status`) && currentRefData[key]) {
      //       isNoRepliedYet = false;
      //    };
      // };
      // if (isNoRepliedYet) {
      //    isButtonDeleteFormShown = true;
      // };


      // allow to delete Ref already replied as long as it is the latest revision
      const allVersionThisRef = rowsFormAllInit.filter(row => row[`${refType}Ref`] === currentRefData[`${refType}Ref`]).map(x => x['revision']).sort();
      const lastVersion = allVersionThisRef[allVersionThisRef.length - 1];
      if (currentRefData['revision'] === lastVersion) {
         isButtonDeleteFormShown = true;
      };
      if (refType === 'dt' && getInfoValueFromRefDataForm(currentRefData, 'submission', 'dt', 'linkSignedOffFormSubmit')) {
         isButtonForwardEmailShown = true;
      };
   };


   useEffect(() => {
      setDataInputForTable(getInputForTable({ filesPdfDrawing, dwgsImportFromRFA, formReplyUpload, dataDmsDrawingLink }));
   }, [filesPdfDrawing, dwgsImportFromRFA, formReplyUpload, dataDmsDrawingLink]);




   const [linkedFormTypeName, setLinkedFormTypeName] = useState('upload-only');
   const [linkedPreviousFormTypeNameForEditCase, setLinkedPreviousFormTypeNameForEditCase] = useState(null);

   useEffect(() => {

      if (_.isEmpty(filesPdfDrawing) && _.isEmpty(dataDmsDrawingLink) && _.isEmpty(dataInputDmsTable) && dwgsImportFromRFA.length === 0) {
         // EDIT FORM, => still need to show existing files
      } else {
         // Change link form type => reset
         setDataDmsDrawingLink({});
         setFilesPdfDrawing({});
         setDwgsImportFromRFA([]);
         setDataInputDmsTable({});
      }

   }, [linkedFormTypeName]);



   useEffect(() => {
      let linkDrawingsData = [];
      let linkDrawingsRfaData = [];
      let linkFormReplyData = [];

      let linkRfamAttached = [];
      let linkRfiAttached = [];
      let linkCviAttached = [];

      if (formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') {
         linkDrawingsData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkDrawings') || [];
         linkDrawingsRfaData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkDrawingsRfa') || [];

         linkRfamAttached = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkRfamAttached') || [];
         linkRfiAttached = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkRfiAttached') || [];
         linkCviAttached = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkCviAttached') || [];

      } else if (formRefType === 'form-reply-multi-type') {
         linkFormReplyData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'linkFormReply', company);
         linkDrawingsData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'linkDocumentsReply', company) || [];

         if (linkFormReplyData && !(linkFormReplyData instanceof Array)) {
            linkFormReplyData = [linkFormReplyData];
         } else if (!linkFormReplyData) {
            linkFormReplyData = [];
         };
      };

      if ((formRefType === 'form-resubmit-multi-type' || formRefType === 'form-reply-multi-type') && !isFormEditting) {
         linkDrawingsData = [];
         linkDrawingsRfaData = [];
         linkFormReplyData = [];

         linkRfamAttached = [];
         linkRfiAttached = [];
         linkCviAttached = [];
      };

      setDataInputForTable([
         ...linkFormReplyData.map(dwgLink => {
            return {
               id: mongoObjectId(),
               'File Name': /[^/]*$/.exec(dwgLink)[0],
               'Type': 'Form',
            };
         }),
         ...linkDrawingsData.map(dwgLink => {
            return {
               id: mongoObjectId(),
               'File Name': /[^/]*$/.exec(dwgLink)[0],
               'Type': 'Drawing',
            };
         }),
         ...linkDrawingsRfaData.map(dwgLink => {
            const dwgFileName = /[^/]*$/.exec(dwgLink)[0];
            return {
               id: mongoObjectId(),
               'Drawing Number': dwgFileName,
               rfaNumber: true,
               checkIfEditForm: true,
               companySubmitThisDwg: getCompanyNameFromLink(dwgLink)
            };
         }),
         ...linkRfamAttached.map(dwgLink => {
            const dwgFileName = /[^/]*$/.exec(dwgLink)[0];
            return {
               id: mongoObjectId(),
               'Drawing Number': dwgFileName,
               rfamRef: true,
               checkIfEditForm: true,
               companySubmitThisDwg: getCompanyNameFromLink(dwgLink)
            };
         }),
         ...linkRfiAttached.map(dwgLink => {
            const dwgFileName = /[^/]*$/.exec(dwgLink)[0];
            return {
               id: mongoObjectId(),
               'Drawing Number': dwgFileName,
               rfiRef: true,
               checkIfEditForm: true,
               companySubmitThisDwg: getCompanyNameFromLink(dwgLink)
            };
         }),
         ...linkCviAttached.map(dwgLink => {
            const dwgFileName = /[^/]*$/.exec(dwgLink)[0];
            return {
               id: mongoObjectId(),
               'Drawing Number': dwgFileName,
               cviRef: true,
               checkIfEditForm: true,
               companySubmitThisDwg: getCompanyNameFromLink(dwgLink)
            };
         }),
      ]);
   }, []);



   useEffect(() => {
      if (formRefType === 'form-submit-multi-type') {
         if (!isFormEditting) {
            setDateReplyForSubmitForm(moment(moment().add(14, 'days').format('DD/MM/YY'), 'DD/MM/YY'));
            if ((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent) {
               setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
            };

         } else {
            const dateSendNoEmail = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'dateSendNoEmail');

            if (dateSendNoEmail) {
               const listConsultantMustReply = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];
               setListRecipientTo(listConsultantMustReply.map(cmp => `${cmp}_%$%_`));
               setDateSendThisForm(moment(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'date')).format('DD/MM/YY'), 'DD/MM/YY'));
            } else {
               setListRecipientTo([...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo') || [])]);
            };

            const listRecipientCcData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailCc') || [];
            setListRecipientCc([...new Set(listRecipientCcData)]);

            const listConsultantMustReplyData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];
            setListConsultantMustReply(listConsultantMustReplyData);

            const requestedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'requestedBy') || '';
            setRequestedBy(requestedByData);

            const signaturedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'signaturedBy') || '';
            setSignaturedBy(signaturedByData);

            const recipientNameData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'recipientName') || '';
            setRecipientName(recipientNameData);

            const textEmailTitleData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTitle') || '';
            setTextEmailTitle(textEmailTitleData);

            const descriptionData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'description') || '';
            setDescription(descriptionData);

            const listFormData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listForm') || [];
            setFormsToLinkQrOrQrm(listFormData);


            setListTagForDtForm(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listTagForDtForm') || []);
            

            setConversationAmong(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'conversationAmong'));
            setContractSpecification(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'contractSpecification'));
            setProposedSpecification(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'proposedSpecification'));
            setIsCostImplication(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'isCostImplication'));
            setIsTimeExtension(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'isTimeExtension'));
            setHerewithForDt(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'herewithForDt'));
            setTransmittedForDt(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'transmittedForDt'));
            setSubmissionType(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'submissionType'));

            if (pageSheetTypeName === 'page-rfam') {
               setContractDrawingNo(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'contractDrawingNo'));
            };

            if (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-mm') {
               setTimeConversation(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'timeConversation')));
               setDateConversation(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'dateConversation')));
            };


            setDateReplyForSubmitForm(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'due')));

            setTradeForFirstTimeSubmit(getDisciplineOfRefString(currentRefData[refKey]));
            setRefNumberSuffixFirstTimeSubmit(/[^/]*$/.exec(currentRefData[refKey])[0]);


            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes } = generalNoteInfoData;
               setListGeneralData(listGeneralNotes);
            };

            if (pageSheetTypeName === 'page-dt') {
               const listIdIssueConstructionPrevious = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listDrawingIssueForConstruction') || [];
               if (listIdIssueConstructionPrevious.length > 0) {
                  setArrayIdsDmsDrawingLinkPrevious(listIdIssueConstructionPrevious);
               };

               const linkedFormTypeNamePrevious = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkedFormTypeName');
               if (linkedFormTypeNamePrevious) {
                  setLinkedPreviousFormTypeNameForEditCase(linkedFormTypeNamePrevious);
               };
            };
         };

      } else if (formRefType === 'form-resubmit-multi-type') {

         if (!isFormEditting) {


            setDateReplyForSubmitForm(moment(moment().add(14, 'days').format('DD/MM/YY'), 'DD/MM/YY'));

            const listConsultantMustReplyData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];

            if ((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent) {
               setListRecipientTo(listConsultantMustReplyData.map(cmp => `${cmp}_%$%_`));
               setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
            } else {
               const listEmailToPreviousVersion = [...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo') || [])];
               if (listEmailToPreviousVersion.length === 0) { // previous submission is migrate =>>> no email TO list
                  const listEmailToAdd = listGroup.filter(item => {
                     return listConsultantMustReplyData.find(cmp => item.includes(`${cmp}_%$%_`));
                  });
                  setListRecipientTo(listEmailToAdd);
               } else {
                  setListRecipientTo(listEmailToPreviousVersion);
               };
            };

            const listRecipientCcData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailCc') || [];
            setListRecipientCc([...new Set(listRecipientCcData)]);

            const requestedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'requestedBy') || '';
            setRequestedBy(requestedByData);

            const signaturedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'signaturedBy') || '';
            setSignaturedBy(signaturedByData);
            const recipientNameData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'recipientName') || '';
            setRecipientName(recipientNameData);


            setListConsultantMustReply(listConsultantMustReplyData);

            const textEmailTitleData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTitle') || '';
            setTextEmailTitle((refType === 'dt' ? 'Reissue - ' : 'Resubmit - ') + textEmailTitleData);

            const versionTextIndex = versionTextArray.indexOf(currentRefData.revision);
            const versionTextNext = versionTextArray[versionTextIndex + 1];
            setRefNewVersionResubmitSuffix(versionTextNext);

            if (pageSheetTypeName === 'page-rfam') {
               setContractDrawingNo(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'contractDrawingNo'));
            };

         } else {

            const dateSendNoEmail = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'dateSendNoEmail');

            if (dateSendNoEmail) {
               const listConsultantMustReply = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];
               setListRecipientTo(listConsultantMustReply.map(cmp => `${cmp}_%$%_`));
               setDateSendThisForm(moment(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'date')).format('DD/MM/YY'), 'DD/MM/YY'));
            } else {
               setListRecipientTo([...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo') || [])]);
            };


            const listRecipientCcData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailCc') || [];
            setListRecipientCc([...new Set(listRecipientCcData)]);

            const listConsultantMustReplyData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];
            setListConsultantMustReply(listConsultantMustReplyData);

            const requestedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'requestedBy') || '';
            setRequestedBy(requestedByData);

            const signaturedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'signaturedBy') || '';
            setSignaturedBy(signaturedByData);

            const recipientNameData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'recipientName') || '';
            setRecipientName(recipientNameData);

            const textEmailTitleData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTitle') || '';
            setTextEmailTitle(textEmailTitleData);

            const descriptionData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'description') || '';
            setDescription(descriptionData);

            const listFormData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listForm') || [];
            setFormsToLinkQrOrQrm(listFormData);

            setListTagForDtForm(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listTagForDtForm') || []);
            setConversationAmong(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'conversationAmong'));
            setContractSpecification(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'contractSpecification'));
            setProposedSpecification(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'proposedSpecification'));
            setIsCostImplication(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'isCostImplication'));
            setIsTimeExtension(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'isTimeExtension'));
            setHerewithForDt(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'herewithForDt'));
            setTransmittedForDt(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'transmittedForDt'));
            setSubmissionType(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'submissionType'));


            

            if (pageSheetTypeName === 'page-rfam') {
               setContractDrawingNo(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'contractDrawingNo'));
            };


            if (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-mm') {
               setTimeConversation(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'timeConversation')));
               setDateConversation(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'dateConversation')));
            };

            setDateReplyForSubmitForm(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'due')));
            setRefNewVersionResubmitSuffix(currentRefData.revision);


            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes } = generalNoteInfoData;
               setListGeneralData(listGeneralNotes);
            };


            if (pageSheetTypeName === 'page-dt') {
               const listIdIssueConstructionPrevious = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listDrawingIssueForConstruction') || [];
               if (listIdIssueConstructionPrevious.length > 0) {
                  setArrayIdsDmsDrawingLinkPrevious(listIdIssueConstructionPrevious);
               };
               const linkedFormTypeNamePrevious = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkedFormTypeName');
               if (linkedFormTypeNamePrevious) {
                  setLinkedPreviousFormTypeNameForEditCase(linkedFormTypeNamePrevious);
               };
            };
         };


      } else if (formRefType === 'form-reply-multi-type') {
         if (!isFormEditting) {

            const signaturedByData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'signaturedBy');

            setListRecipientTo([getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'user')]);
            setListRecipientCc([...new Set([
               ...((refType !== 'qr' && refType !== 'qrm' && refType !== 'rfc') ? [signaturedByData] : []),
               ...(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo') || []),
               ...(getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailCc') || []),
            ])]);


            setTextEmailTitle('Reply - ' + getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTitle'));

            if (isBothSideActionUser && withNoEmailSent) {
               setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
            };

            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes, companiesToReply } = generalNoteInfoData;
               if (companiesToReply.indexOf(company) !== -1) {
                  const listGeneralNotesReply = listGeneralNotes.map(x => ({ ...x, reply: '' }));
                  setListGeneralData(listGeneralNotesReply);
               };
            };


         } else {

            const statusData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'status', company) || '';
            setConsultantReplyStatus(statusData);

            const dateSendNoEmail = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'dateSendNoEmail', company);
            if (dateSendNoEmail) {
               setDateSendThisForm(moment(moment(getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'date', company)), 'DD/MM/YY'));
            } else {
               const listRecipientToData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'emailTo', company) || [];
               setListRecipientTo([...new Set(listRecipientToData)]);

               const listRecipientCcData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'emailCc', company) || [];
               setListRecipientCc([...new Set(listRecipientCcData)]);

               const textEmailTitleData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'emailTitle', company) || '';
               setTextEmailTitle(textEmailTitleData);
            };

            const descriptionData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'description', company) || '';
            setDescription(descriptionData);


            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes, companiesToReply } = generalNoteInfoData;
               if (companiesToReply.indexOf(company) !== -1) {
                  const generalNoteReplyData = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'generalNoteReply', company) || [];
                  const listGeneralNotesReply = listGeneralNotes.map(x => {
                     const replyFound = generalNoteReplyData.find(rp => rp.id === x.id) || {};
                     return { ...x, reply: replyFound['reply'] || '' };
                  });
                  setListGeneralData(listGeneralNotesReply);
               };
            };
         };
      };
   }, []);


   useEffect(() => {
      if (tradeForFirstTimeSubmit && formRefType === 'form-submit-multi-type') {

         if (!isFormEditting) {
            let filterRows = rowsFormAllInit.filter(r => {
               const disciplineTrade = getDisciplineOfRefString(r[refKey]);
               return disciplineTrade === tradeForFirstTimeSubmit;
            });
            const suggestedNewRefNumberString = getSuggestedNewRefNumber(filterRows, refType);
            setRefNumberSuffixFirstTimeSubmit(suggestedNewRefNumberString);


            filterRows.sort((b, a) => (a[refKey] > b[refKey] ? 1 : -1));

            const rowFoundTo = filterRows.find(r => {
               return getInfoValueFromRefDataForm(r, 'submission', refType, 'emailTo') &&
                  getInfoValueFromRefDataForm(r, 'submission', refType, 'emailTo').length > 0;
            });
            if (rowFoundTo) {
               const consultantMustReplyArray = getInfoValueFromRefDataForm(rowFoundTo, 'submission', refType, 'consultantMustReply') || [];

               if ((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent) {
                  setListRecipientTo(consultantMustReplyArray.map(cmp => `${cmp}_%$%_`));
                  setListConsultantMustReply(consultantMustReplyArray);
               } else {
                  const listEmailTo = getInfoValueFromRefDataForm(rowFoundTo, 'submission', refType, 'emailTo') || [];
                  setListRecipientTo(listEmailTo);
                  const listConsultantExtractedFromEmailList = listEmailTo.filter(x => extractConsultantName(x)).map(x => extractConsultantName(x));
                  let output = []; // if previously there are 2 group from same company, it will duplicate this in consultantMustReply =>> need to counter check
                  listConsultantExtractedFromEmailList.forEach(item => {
                     if (output.indexOf(item) === -1) {
                        output.push(item);
                     };
                  });
                  setListConsultantMustReply(output);
               };
            };

            const rowFoundCc = filterRows.find(r => {
               return getInfoValueFromRefDataForm(r, 'submission', refType, 'emailCc') &&
                  getInfoValueFromRefDataForm(r, 'submission', refType, 'emailCc').length > 0;
            });
            if (rowFoundCc) {
               setListRecipientCc(getInfoValueFromRefDataForm(rowFoundCc, 'submission', refType, 'emailCc') || []);
            };

         } else {
            if (checkIfRefIsDuplicated()) {
               message.info(`This ${refType.toUpperCase()} number has already existed, please choose a new number!`);
               setRefNumberSuffixFirstTimeSubmit('');
            };
         };
      };
   }, [tradeForFirstTimeSubmit]);



   useEffect(() => {
      if (!loading) {
         setModalConfirmsubmitOrCancel(null);
      };
   }, [loading]);



   const checkIfRefIsDuplicated = () => {
      const arr = [...new Set(rowsFormAllInit.map(r => getRefStringWithVersion(r, refType)))];

      if (formRefType === 'form-submit-multi-type') {
         const newRefToSubmit = `${refType.toUpperCase()}/${projectNameShort}/${tradeForFirstTimeSubmit}/${refNumberSuffixFirstTimeSubmit}`;

         return (
            (refNumberSuffixFirstTimeSubmit.length !== 4) ||
            (!checkIfStringContainNumberOnly(refNumberSuffixFirstTimeSubmit)) ||
            (!isFormEditting && arr.indexOf(newRefToSubmit) !== -1) ||
            (isFormEditting && arr.indexOf(newRefToSubmit) !== -1 && newRefToSubmit !== currentRefData[refKey])
         );

      } else if (formRefType === 'form-resubmit-multi-type') {

         const newRefToSubmit = currentRefData[refKey] + refNewVersionResubmitSuffix;
         return (
            (!isFormEditting && arr.indexOf(newRefToSubmit) !== -1) ||
            (isFormEditting && arr.indexOf(newRefToSubmit) !== -1 && newRefToSubmit !== (currentRefData[refKey] + currentRefData.revision))
         );
      };
   };


   const onBlurInputRefNameCreateNew = () => {
      if (checkIfRefIsDuplicated()) {
         message.info(`This ${refType.toUpperCase()} number has already existed, please choose a new number!`);
         setRefNumberSuffixFirstTimeSubmit('');
         setRefNewVersionResubmitSuffix('');
      } else {
         if (formRefType === 'form-submit-multi-type') {
            let regExp = /[a-zA-Z]/g;
            if (regExp.test(refNumberSuffixFirstTimeSubmit)) {
               message.info('Please key in number only!');
               setRefNumberSuffixFirstTimeSubmit('');
            };
         } else if (formRefType === 'form-resubmit-multi-type') {
            if (versionTextArray.indexOf(refNewVersionResubmitSuffix) === -1) {
               message.info('Please key in letter only!');
               setRefNewVersionResubmitSuffix('');
            };
         };
      };
   };



   const refUpload = useRef();
   const onChangeUploadPdfDrawing = (info) => {
      if (info.fileList) {
         let fileListStateRef = refUpload.current.state.fileList;
         info.fileList.forEach(file => {
            if (!fileListStateRef.find(x => x.name === file.name)) {
               fileListStateRef.push(file);
            };
         });
         let output = {};
         fileListStateRef.forEach(file => {
            output = { ...output, [file.name]: file };
         });
         setFilesPdfDrawing(output);
      };
   };
   const onChangeUploadFormReply = (info) => {
      if (info.fileList) {
         let output = {};
         info.fileList.forEach(file => {
            output = { [file.name]: file };
         });
         setFormReplyUpload(output);
      };
   };


   const onClickTagRecipientTo = (email, isRemoveTag) => {
      if (formRefType !== 'page-mm' && formRefType !== 'form-reply-multi-type') {
         let outputListConsultantMustReply = [...listConsultantMustReply];
         const consultantName = extractConsultantName(email);
         const originConsultant = listConsultants.find(x => x.company === consultantName);
         outputListConsultantMustReply = outputListConsultantMustReply.filter(x => x !== consultantName);

         if (originConsultant && !isRemoveTag) {
            outputListConsultantMustReply.unshift(originConsultant.company);
         };
         setListConsultantMustReply(outputListConsultantMustReply);
      };
   };



   const onClickApplyDoneFormRef = (typeButton) => {

      const isSubmitOrResubmitForm = formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type';

      let refToSaveVersion, refToSave;

      if (formRefType === 'form-submit-multi-type') {
         if (!tradeForFirstTimeSubmit) return message.info('Please fill in trade!', 2);
         if (!refNumberSuffixFirstTimeSubmit) return message.info('Please fill in ref number!', 2);

         refToSaveVersion = '0';
         refToSave = `${refType.toUpperCase()}/${projectNameShort}/${tradeForFirstTimeSubmit}/${refNumberSuffixFirstTimeSubmit}`;

      } else if (formRefType === 'form-resubmit-multi-type') {
         refToSaveVersion = refNewVersionResubmitSuffix;
         refToSave = currentRefData[refKey];
      };



      if (projectNameShort === 'NO-PROJECT-NAME') {
         return message.info(`Please update project abbreviation name for ${refType.toUpperCase()} number!`, 2);
      } else if (
         ((formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') && !(textEmailTitle || '').trim()) ||
         (formRefType === 'form-reply-multi-type' && !(textEmailTitle || '').trim() && !withNoEmailSent)
      ) {
         return message.info('Please fill in email title!', 2);

      } else if (!(description || '').trim()) {
         return message.info('Please fill in description!', 2);

      } else if (isSubmitOrResubmitForm && (!(contractSpecification || '').trim() || !(proposedSpecification || '').trim()) && pageSheetTypeName === 'page-rfam') {
         return message.info('Please fill in description!', 2);

      } else if ((!listRecipientTo || listRecipientTo.length === 0) && !withNoEmailSent) {
         return message.info('Please fill in recipient!', 2);

      } else if (isSubmitOrResubmitForm && !dateReplyForSubmitForm) {
         return message.info('Please fill in expected reply date!', 2);

      } else if (withNoEmailSent && !dateSendThisForm) {
         return message.info('Please fill in submission date!', 2);

      } else if (isSubmitOrResubmitForm && listConsultantMustReply.length === 0 && pageSheetTypeName !== 'page-mm') {
         return message.info('Please fill in consultant lead!', 2);

      } else if (isSubmitOrResubmitForm && !(requestedBy || '').trim() && pageSheetTypeName !== 'page-mm') {
         return message.info('Please fill in person requested!', 2);

      } else if (isSubmitOrResubmitForm && !(recipientName || '').trim() && pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') {
         return message.info('Please fill in recipient name!', 2);

      } else if ((!refToSave || !refToSaveVersion) && isSubmitOrResubmitForm) {
         return message.info('Please fill in necessary info!', 2);

      } else if (isSubmitOrResubmitForm && !signaturedBy && pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') {
         return message.info('Please fill in signatured by!', 2);

      } else if (isSubmitOrResubmitForm && !submissionType && pageSheetTypeName === 'page-rfam') {
         return message.info('Please fill in submission type!', 2);

      } else if (isSubmitOrResubmitForm && !(conversationAmong || '').trim() && pageSheetTypeName === 'page-cvi') {
         return message.info('Please fill in conversation among!', 2);

      } else if (isSubmitOrResubmitForm && (!dateConversation || !timeConversation) && (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-mm')) {
         return message.info('Please fill in date and time conversation!', 2);

      } else if (isSubmitOrResubmitForm && !herewithForDt && (pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-rfc')) {
         return message.info('Please fill in herewith!', 2);

      } else if (isSubmitOrResubmitForm && !transmittedForDt && pageSheetTypeName === 'page-dt') {
         return message.info('Please fill in transmitted for!', 2);

      } else if (formRefType === 'form-resubmit-multi-type' && !refNewVersionResubmitSuffix) {
         return message.info('Please fill in version!', 2);

      } else if (!isSubmitOrResubmitForm && !consultantReplyStatus && (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page=qrm' || pageSheetTypeName === 'page-rfc')) {
         return message.info('Please fill in reply status!', 2);

      } else if (!isSubmitOrResubmitForm && (pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') && (
         (Object.keys(formReplyUpload).length === 0 && Object.keys(filesPdfDrawing).length > 0) ||
         (Object.keys(formReplyUpload).length === 0 && !isFormEditting)
      )) {
         return message.info('Please upload reply form!', 2);
      };





      let outputConsultantsToReply = [];
      if (isSubmitOrResubmitForm) {
         outputConsultantsToReply = [...listConsultantMustReply];
      };


      const fileFormReplyUpload = Object.values(formReplyUpload)[0];
      if (fileFormReplyUpload && fileFormReplyUpload.type !== 'application/pdf') {
         return message.info('Reply form must be in PDF format!', 2);
      };


      let totalSize = 0;
      if (filesPdfDrawing) {
         Object.values(filesPdfDrawing).forEach(file => {
            totalSize += file['size'];
         });
         if (totalSize > 500 * 1024 * 1000) {
            return message.info('Total size of attachments should not exceed 500MB!', 2);
         };
      };



      let generalNoteInfo;
      let generalNoteReply;
      const listGeneralDataOutput = (listGeneralData || []).filter(x => (x['submission'] || '').trim());
      if (listGeneralDataOutput.length > 0) {
         if (formRefType === 'form-reply-multi-type') {
            const foundNotRepliedYet = listGeneralDataOutput.find(x => !((x['reply'] || '').trim()));
            if (foundNotRepliedYet) {
               return message.info('Please all items in reply general notes!', 2);
            } else {
               generalNoteReply = [];
               listGeneralDataOutput.forEach(item => {
                  generalNoteReply.push({
                     id: item['id'],
                     reply: item['reply']
                  });
               });
            };
         } else {
            generalNoteInfo = {
               companiesToReply: [listConsultantMustReply[0]],
               listGeneralNotes: listGeneralDataOutput
            };
         };
      };


      if ((pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') && isSubmitOrResubmitForm) {
         if (Object.values(filesPdfDrawing).length === 0 && !isFormEditting) {
            return message.info('Please upload files to submit!', 2);
         };
      };



      getSheetRows({ loading: true });

      onClickApplySendFormToSignature(typeButton, {
         type: formRefType,
         isFormEditting,
         filesPdfDrawing: Object.values(filesPdfDrawing),
         formReplyUpload: Object.values(formReplyUpload),
         dwgsImportFromRFA: dwgsImportFromRFA.map(x => ({ ...x })),
         refToSave, refToSaveVersion,
         recipient: {
            to: withNoEmailSent ? [] : [...new Set(listRecipientTo)],
            cc: withNoEmailSent ? [] : [...new Set(listRecipientCc)]
         },
         listConsultantMustReply: outputConsultantsToReply,
         requestedBy, signaturedBy, recipientName,
         dateConversation, timeConversation,
         conversationAmong,
         isCostImplication, isTimeExtension,
         emailTextTitle: textEmailTitle,
         description,
         dateReplyForSubmitForm,
         consultantReplyStatus,
         contractSpecification,
         proposedSpecification,
         submissionType,
         herewithForDt,
         transmittedForDt,

         withNoEmailSent,
         consultantNameToReplyByBothSideActionUser,
         dateSendThisForm,
         contractDrawingNo,

         dataDmsDrawingLink,
         arrayIdsDmsDrawingLinkPrevious,
         linkedFormTypeName,
         linkedPreviousFormTypeNameForEditCase,
         generalNoteInfo,
         generalNoteReply,

         formsToLinkQrOrQrm,

         listTagForDtForm
      });
   };


   const [dataInputDmsTable, setDataInputDmsTable] = useState({});
   const [fileNameToLinkDms, setFileNameToLinkDms] = useState(null);

   const openDmsDrawingCurrentAndHistoryTable = async (fileName) => {
      try {
         setFileNameToLinkDms(fileName);
         setTablePickDrawingRfaSubmitted('dms');
         if (_.isEmpty(dataInputDmsTable)) {
            const dataFetched = await fetchAllDrawingsMultiFormToLinkDt({ linkedFormTypeName, token, projectId, email });
            setDataInputDmsTable(dataFetched);
         };
      } catch (err) {
         message.warn('Network Error!')
         console.log(err);
      };
   };


   const setIssueForConstructionRevision = (rowFile, revisionText) => {
      if (revisionText) {
         if (!rowFile['Type'] && !rowFile['File Name']) { // drawings / form submitted 
            const newDwgsImportFromRFA = dwgsImportFromRFA.map(x => ({ ...x }));
            const foundDwg = newDwgsImportFromRFA.find(x => x['id'] === rowFile['id']);
            if (foundDwg) {
               foundDwg['IFC Rev'] = revisionText;
               setDwgsImportFromRFA(newDwgsImportFromRFA);
            };
         } else if (rowFile['Type'] && rowFile['File Name']) { // files upload from computer
            const dwgDmsToLink = dataDmsDrawingLink[rowFile['File Name']];
            if (dwgDmsToLink) {
               const dataDmsDrawingLinkClone = { ...dataDmsDrawingLink };
               let dwgDmsToLinkClone = { ...dwgDmsToLink };

               dataDmsDrawingLinkClone[rowFile['File Name']] = {
                  ...dwgDmsToLinkClone,
                  'IFC Rev': revisionText
               };
               setDataDmsDrawingLink(dataDmsDrawingLinkClone);
            };
         };
      };
   };


   const [loadingAutoSearch, setLoadingAutoSearch] = useState(false);
   const onClickAutoSearchDrawing = async () => {
      try {
         setLoadingAutoSearch(true);

         let rowsAllOutputAllVersionToSearch = [];
         if (_.isEmpty(dataInputDmsTable)) {
            const dataFetched = await fetchAllDrawingsMultiFormToLinkDt({ linkedFormTypeName, token, projectId, email });
            setDataInputDmsTable(dataFetched);

            rowsAllOutputAllVersionToSearch = dataFetched['rowsAllOutputAllVersion'];
         } else {
            const delayFakeFnc = (ms) => new Promise(res => setTimeout(res, ms));
            await delayFakeFnc(350);

            const { rowsAllOutputAllVersion } = dataInputDmsTable;
            rowsAllOutputAllVersionToSearch = rowsAllOutputAllVersion;
         };

         let outputFileAutoSearch = {};

         for (const fileName in filesPdfDrawing) {
            const dwgsSameNameFound = linkedFormTypeName === 'rfa'

               ? rowsAllOutputAllVersionToSearch.filter(dwg => {
                  const dwgNumber = dwg['Drawing Number'];
                  return dwgNumber && fileName.includes(dwgNumber);
               }).sort((a, b) => a['Rev'] > b['Rev'] ? 1 : -1)

               : rowsAllOutputAllVersionToSearch.filter(dwg => {
                  const refNumber = dwg[`${linkedFormTypeName}Ref`];
                  const parts = refNumber.split('/');
                  let isIncludeAllPart = true;
                  parts.forEach(part => {
                     if (!fileName.includes(part)) {
                        isIncludeAllPart = false;
                     };
                  });
                  return isIncludeAllPart;
               }).sort((a, b) => a['revision'] > b['revision'] ? 1 : -1);

            if (dwgsSameNameFound.length > 0) {
               outputFileAutoSearch[fileName] = dwgsSameNameFound[dwgsSameNameFound.length - 1]; // Get the latest version
            };
         };

         if (!(_.isEmpty(outputFileAutoSearch))) {
            setDataDmsDrawingLink({
               ...dataDmsDrawingLink,
               ...outputFileAutoSearch
            });
         };

         setLoadingAutoSearch(false);

      } catch (err) {
         message.warn('Network Error!')
         console.log(err);
      };
   };



   const generateColumnsListDwgRef = (headers, nosColumnFixed) => {
      const buttonRemoveDrawing = (
         isFormEditting &&
         Object.keys(filesPdfDrawing).length === 0 &&
         dwgsImportFromRFA.length === 0
      ) ? [] : [
         {
            key: 'action', dataKey: 'action', title: '',
            width: 40,
            frozen: Column.FrozenDirection.RIGHT,
            cellRenderer: ( // MUST RETURN LIKE THIS, NOT ({ rowData }) => (), otherwise dataDmsDrawingLink can not update - DON'T KNOW WHY
               <CellRemoveDrawing
                  onClickRemoveDwgBtn={onClickRemoveDwgBtn}
                  dataDmsDrawingLink={dataDmsDrawingLink} // MUST INCLUDE dataDmsDrawingLink HERE EVEN NO USE LATER TO GET LATEST dataDmsDrawingLink - DON'T KNOW WHY
               />
            )
         }
      ];

      return [
         {
            key: 'index', dataKey: 'index', title: '', width: 40,
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer: ({ rowIndex }) => <div>{rowIndex + 1}</div>
         },
         ...headers.map((column, index) => ({
            key: column, dataKey: column, title: column,
            resizable: true,
            frozen: index < nosColumnFixed ? Column.FrozenDirection.LEFT : undefined,
            width: getHeaderWidthDwgRef(column),
            cellRenderer: (
               <CellForTableUploadFile
                  openDmsDrawingCurrentAndHistoryTable={openDmsDrawingCurrentAndHistoryTable}
                  dataInputDmsTable={dataInputDmsTable}
                  setIssueForConstructionRevision={setIssueForConstructionRevision}
                  linkedFormTypeName={linkedFormTypeName}
                  dwgsImportFromRFA={dwgsImportFromRFA} // MUST INCLUDE dwgsImportFromRFA HERE EVEN NO USE LATER TO GET LATEST dwgsImportFromRFA - DON'T KNOW WHY =>> otherwise when remove drawing, IFC Rev will reset
               />
            ),
         })),
         ...buttonRemoveDrawing,
      ];
   };

   const onClickRemoveDwgBtn = debounceFnc((rowData) => {
      if (rowData['Type'] === 'Drawing') {
         let fileListStateRef = refUpload.current.state.fileList;
         let obj = {};
         fileListStateRef.forEach(file => {
            if (file.name !== rowData['File Name']) {
               obj[file.name] = file;
            } else {
               fileListStateRef = fileListStateRef.filter(x => x.uid !== file.uid);
               refUpload.current.state.fileList = fileListStateRef;
            };
         });
         setFilesPdfDrawing(obj);

         const thisFileAttachDmsDrawing = rowData['File Name'];
         if (thisFileAttachDmsDrawing) {
            const newDataDmsLink = { ...dataDmsDrawingLink };
            delete newDataDmsLink[thisFileAttachDmsDrawing];
            setDataDmsDrawingLink(newDataDmsLink);
         };
      } else if (rowData['Type'] === 'Form') {
         setFormReplyUpload({});

      } else if (!rowData['Type']) {
         setDwgsImportFromRFA(dwgsImportFromRFA.filter(x => x.id !== rowData.id));
      };
      setNosColumnFixed(2);
      setNosColumnFixed(1);
   }, 1);



   const checkButtonShownUploadFormReply = formRefType === 'form-reply-multi-type' && (pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc');
   const checkButtonShownRfaLink = (formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') && (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-qr' || (pageSheetTypeName === 'page-dt' && linkedFormTypeName === 'rfa'));
   const checkButtonShownAutoSearchLink = (formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') && pageSheetTypeName === 'page-dt' && (Object.keys(filesPdfDrawing || {}).length > 0 && linkedFormTypeName !== 'upload-only');
   let arrayButtonLinkMultiForm = [];
   if ((formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type')) {
      if (pageSheetTypeName === 'page-qrm') {
         arrayButtonLinkMultiForm = ['rfam'];
      } else if (pageSheetTypeName === 'page-dt') { // FIX CONDITION HERE AGAIN
         arrayButtonLinkMultiForm = [linkedFormTypeName];
         arrayButtonLinkMultiForm = arrayButtonLinkMultiForm.filter(x => x !== 'rfa' && x !== 'upload-only');
      };
   };

   


   return (
      <>
         {/* {formRefType === 'form-submit-multi-type' && (
            <PrintPdf
               pdfContent={{
                  refNumberText: `${refType.toUpperCase()}/${projectNameShort}/${tradeForFirstTimeSubmit}/${refNumberSuffixFirstTimeSubmit}`,
                  listRecipientTo: [...new Set(listRecipientTo)], listRecipientCc: [...new Set(listRecipientCc)], isCostImplication, isTimeExtension,
                  requestedBy, signaturedBy, conversationAmong, emailTextTitle: textEmailTitle, dateConversation, timeConversation, description, dateReplyForSubmitForm,
                  filesPdfDrawing: Object.values(filesPdfDrawing),
                  dwgsImportFromRFA: dwgsImportFromRFA.map(x => ({ ...x })),
                  projectName, listConsultantMustReply,
                  contractSpecification,
                  proposedSpecification,
                  submissionType,
                  herewithForDt,
                  transmittedForDt,
                  pageSheetTypeName, 
                  recipientName,
                  companies, contractDrawingNo
               }}
            />
         )} */}



         <div style={{ background: 'white', width: '100%', padding: 10, color: 'black' }}>
            <div style={{ padding: 20, paddingRight: 10, borderBottom: `1px solid ${colorType.grey4}` }}>
               <div style={{ display: 'flex', marginBottom: 10 }}>
                  <div style={{ marginRight: 10, fontWeight: 'bold' }}>{refType.toUpperCase()} Number</div>
                  {formRefType === 'form-submit-multi-type' ? (
                     <>
                        <div>{`${refType.toUpperCase()}/${projectNameShort}/`}</div>
                        <SelectTradeStyled
                           style={{ width: 120 }}
                           showSearch
                           optionFilterProp='children'
                           onChange={(value) => {
                              setTradeForFirstTimeSubmit(value);
                           }}
                           filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                           suffixIcon={<div></div>}
                           value={tradeForFirstTimeSubmit}
                        >
                           {disciplineForm.map(trade => (
                              <Select.Option key={trade} value={trade}>{trade}</Select.Option>
                           ))}
                        </SelectTradeStyled>


                        <div style={{ marginLeft: 10 }}>/</div>
                        {tradeForFirstTimeSubmit ? (
                           <InputStyled
                              style={{ width: 60, marginRight: 120 }}
                              onChange={(e) => setRefNumberSuffixFirstTimeSubmit(e.target.value)}
                              onBlur={onBlurInputRefNameCreateNew}
                              value={refNumberSuffixFirstTimeSubmit}
                           />
                        ) : (
                           <div style={{ marginRight: 120, transform: 'translateY(8px)', color: colorType.grey1 }}>
                              <Tooltip title='Ref number automatically filled in after selecting trade'>{'____'}</Tooltip>
                           </div>
                        )}
                     </>
                  ) : formRefType === 'form-resubmit-multi-type' ? (
                     <>
                        <div style={{ marginRight: 2 }}>{currentRefData[refKey]}</div>
                        <InputStyled
                           style={{ width: 50, marginRight: 120 }}
                           onChange={(e) => setRefNewVersionResubmitSuffix(e.target.value)}
                           onBlur={onBlurInputRefNameCreateNew}
                           value={refNewVersionResubmitSuffix}
                        />
                     </>

                  ) : formRefType === 'form-reply-multi-type' ? (
                     <div>{currentRefData[refKey] + (currentRefData.revision === '0' ? '' : currentRefData.revision)}</div>

                  ) : null}



                  {((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent) && (
                     <div style={{ display: 'flex', marginRight: 40 }}>
                        <div style={{ marginLeft: 75, marginRight: 10, fontWeight: 'bold' }}>Date Submission</div>
                        <DatePickerStyled
                           value={dateSendThisForm}
                           format={'DD/MM/YY'}
                           onChange={(e) => setDateSendThisForm(e)}
                        />
                     </div>
                  )}

                  {formRefType === 'form-reply-multi-type' && consultantNameToReplyByBothSideActionUser && (
                     <div style={{ marginLeft: 20 }}>Company reply: <span style={{ fontWeight: 'bold' }}>{consultantNameToReplyByBothSideActionUser}</span></div>
                  )}


                  {formRefType !== 'form-reply-multi-type' && pageSheetTypeName !== 'page-mm' && (
                     <>
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Date Reply</div>
                        <DatePickerStyled
                           value={dateReplyForSubmitForm}
                           format={'DD/MM/YY'}
                           onChange={(e) => setDateReplyForSubmitForm(e)}
                        />
                     </>
                  )}
               </div>


               {!((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent && formRefType === 'form-reply-multi-type') &&
                  !((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent && pageSheetTypeName === 'page-mm') && (

                     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold' }}>{'To'}</div>
                        <div style={{ width: '95%' }}>
                           <SelectRecipientStyled
                              mode='tags'
                              placeholder={formRefType === 'form-submit-multi-type' ? 'Please pick the trade first to get email list of previous submission...' : 'Please select...'}
                              value={listRecipientTo}

                              onChange={(list) => {

                                 const newItemJustAdded = list.find(x => !listRecipientTo.find(i => i === x));
                                 if (newItemJustAdded) {
                                    if (!validateEmailInput(newItemJustAdded) && !listGroup.find(tag => tag === newItemJustAdded)) {
                                       return message.warning('Please choose an available group email or key in an email address!');
                                    };
                                 };


                                 const isCompanyGroupFound = list.find(x => checkIfMatchWithInputCompanyFormat(x, listConsultants));
                                 if (!isCompanyGroupFound && formRefType !== 'form-reply-multi-type') {
                                    if (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') {
                                       message.warning('You must include lead consultant!');
                                    } else if (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt') {
                                       message.warning('You must include consultants!');
                                    } else if (pageSheetTypeName === 'page-rfc') {
                                       message.warning('You must include recipients!');
                                    };
                                 };


                                 const itemJustRemoved = listRecipientTo.find(x => !list.find(it => it === x));
                                 if (formRefType === 'form-submit-multi-type') {
                                    if (
                                       itemJustRemoved &&
                                       listConsultantMustReply.find(x => x === extractConsultantName(itemJustRemoved)) &&
                                       !list.find(tg => extractConsultantName(tg) && extractConsultantName(tg) === extractConsultantName(itemJustRemoved))
                                    ) {
                                       setListConsultantMustReply(listConsultantMustReply.filter(x => x !== extractConsultantName(itemJustRemoved)));
                                    };

                                 } else if (formRefType === 'form-resubmit-multi-type') {
                                    const consultantLeadFromPreviousSubmission = listConsultantMustReply[0];
                                    if (
                                       itemJustRemoved &&
                                       listConsultantMustReply.find(x => x === extractConsultantName(itemJustRemoved)) &&
                                       consultantLeadFromPreviousSubmission !== extractConsultantName(itemJustRemoved) &&
                                       !list.find(tg => extractConsultantName(tg) && extractConsultantName(tg) === extractConsultantName(itemJustRemoved))
                                    ) {
                                       setListConsultantMustReply(listConsultantMustReply.filter(x => x !== extractConsultantName(itemJustRemoved)));
                                    };
                                 };

                                 setListRecipientTo([...new Set(list)]);

                                 let companyNameToCheck, isRemoveTag;
                                 if (list.length === listRecipientTo.length + 1) { // ADD
                                    companyNameToCheck = list.find(x => !listRecipientTo.find(item => item === x));
                                    isRemoveTag = false;
                                 } else if (list.length === listRecipientTo.length - 1) { // REMOVE
                                    companyNameToCheck = listRecipientTo.find(x => !list.find(item => item === x));
                                    isRemoveTag = true;
                                 };

                                 onClickTagRecipientTo(companyNameToCheck, isRemoveTag);
                              }}
                           >
                              {listRecipient.map(cm => {
                                 const isLeadConsultant = listConsultantMustReply[0] && extractConsultantName(cm) === listConsultantMustReply[0];
                                 const isLeadConsultantStyled = (isLeadConsultant && (
                                    pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' ||
                                    pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
                                 )) ? {
                                    background: colorType.primary,
                                    fontWeight: 'bold',
                                    color: 'white'
                                 } : {};
                                 const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', ' ') : cm;

                                 return (
                                    <Option key={cm}>
                                       <div
                                          style={{ background: 'transparent', fontWeight: 'normal', color: 'black', padding: '0 5px', ...isLeadConsultantStyled }}
                                          onClick={() => onClickTagRecipientTo(cm, false)}
                                       >
                                          {textShown}
                                       </div>
                                    </Option>
                                 )
                              })}
                           </SelectRecipientStyled>

                           {formRefType !== 'form-reply-multi-type' && (
                              <div style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                                 {pageSheetTypeName !== 'page-mm' && (
                                    <div style={{ marginRight: 8 }}>
                                       {(pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') ? 'Lead consultant :'
                                          : (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-rfc') ? 'Received By :'
                                             : 'n/a'
                                       }
                                    </div>
                                 )}
                                 {pageSheetTypeName !== 'page-mm' && (
                                    <div style={{ fontWeight: 'bold', marginRight: 10 }}>
                                       {(pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') ? (listConsultantMustReply[0] || '')
                                          : (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt') ? (listConsultantMustReply.sort().join(', ') || '')
                                             : 'n/a'
                                       }
                                    </div>
                                 )}


                                 {formRefType === 'form-submit-multi-type' && pageSheetTypeName !== 'page-mm' && (
                                    <div style={{ fontSize: 11, color: 'grey', fontStyle: 'italic', transform: 'translateY(3px)' }}>(Click on tag to change lead consultant)</div>
                                 )}

                              </div>
                           )}
                        </div>
                     </div>
                  )}




               {!((isBothSideActionUser || isSubconCanSubmit) && withNoEmailSent) && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                     <div style={{ transform: 'translateY(5px)', fontWeight: 'bold' }}>CC</div>
                     <div style={{ width: '95%' }}>
                        <SelectRecipientStyled
                           mode='tags'
                           placeholder='Please select...'
                           value={listRecipientCc}
                           onChange={(list) => {

                              const newItemJustAdded = list.find(x => !listRecipientCc.find(i => i === x));
                              if (newItemJustAdded) {
                                 if (!validateEmailInput(newItemJustAdded) && !listGroup.find(tag => tag === newItemJustAdded)) {
                                    return message.warning('Please choose an available group email or key in an email address!');
                                 };
                              };
                              setListRecipientCc([...new Set(list)]);
                           }}
                        >
                           {listRecipient.map(cm => {
                              const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', '_') : cm;
                              return (
                                 <Option key={cm}>
                                    <div style={{
                                       background: 'transparent',
                                       fontWeight: 'normal',
                                       color: 'black',
                                       padding: '0 5px'
                                    }}>{textShown}</div>
                                 </Option>
                              )
                           })}
                        </SelectRecipientStyled>
                     </div>
                  </div>
               )}



               {formRefType !== 'form-reply-multi-type' && pageSheetTypeName !== 'page-mm' && (
                  <div style={{ display: 'flex' }}>
                     <div style={{ display: 'flex', marginBottom: 5, marginRight: 50 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Requested by</div>
                        <InputStyled
                           style={{ width: 200, marginBottom: 10, borderRadius: 0 }}
                           onChange={(e) => setRequestedBy(e.target.value)}
                           value={requestedBy}
                        />
                     </div>
                     {(pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') && (
                        <>
                           <div style={{ display: 'flex', marginBottom: 5, marginRight: 50 }}>
                              <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Signature</div>
                              <SelectTradeStyled
                                 style={{ width: 200 }}
                                 showSearch
                                 optionFilterProp='children'
                                 onChange={(value) => setSignaturedBy(value)}
                                 filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 }
                                 suffixIcon={<div></div>}
                                 value={signaturedBy}
                              >
                                 {listUser.map((email, i) => (
                                    <Select.Option key={i} value={email}>{email}</Select.Option>
                                 ))}
                              </SelectTradeStyled>
                           </div>
                           <div style={{ display: 'flex', marginBottom: 5 }}>
                              <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Recipient name</div>
                              <InputStyled
                                 style={{ width: 200, marginBottom: 10, borderRadius: 0 }}
                                 onChange={(e) => setRecipientName(e.target.value)}
                                 value={recipientName}
                              />
                           </div>
                        </>
                     )}
                  </div>
               )}



               {!(isBothSideActionUser && withNoEmailSent && formRefType === 'form-reply-multi-type') && (
                  <div style={{ display: 'flex', marginBottom: 20 }}>
                     <div style={{ width: 65, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold' }}>Subject</div>
                     <InputStyled
                        style={{ width: '90%', marginBottom: 10, borderRadius: 0 }}
                        onChange={(e) => setTextEmailTitle(e.target.value)}
                        value={textEmailTitle}
                     />
                  </div>
               )}




               {formRefType !== 'form-reply-multi-type' && (pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-rfc') && (
                  <div style={{ display: 'flex' }}>

                     <div style={{ display: 'flex', marginBottom: 5, marginRight: 100 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Herewith</div>
                        <SelectTradeStyled
                           style={{ width: 200 }}
                           showSearch
                           optionFilterProp='children'
                           onChange={(value) => setHerewithForDt(value)}
                           filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                           suffixIcon={<div></div>}
                           value={herewithForDt}
                        >
                           {(pageSheetTypeName === 'page-dt' ? [
                              'Drawings', 'CD', 'Calculations', 'Method Statement', 'Document', 'Programme',
                              'Specifications', 'Part Prints/Sketches', 'Catalogues', 'Test Results', 'Correspondence', 'Others',
                           ] : [ // CHUA_FIX_DC => DONE
                              'Drawings', 'Sketches', 'Calculations', 'Specifications', 'Test Report', 'Others'
                           ]).map((typeFile, i) => (
                              <Select.Option key={i} value={typeFile}>{typeFile}</Select.Option>
                           ))}
                        </SelectTradeStyled>
                     </div>


                     {pageSheetTypeName === 'page-dt' && (
                        <div style={{ display: 'flex', marginBottom: 5 }}>
                           <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Transmitted For</div>
                           <SelectTradeStyled
                              style={{ width: 200 }}
                              showSearch
                              optionFilterProp='children'
                              onChange={(value) => setTransmittedForDt(value)}
                              filterOption={(input, option) =>
                                 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              suffixIcon={<div></div>}
                              value={transmittedForDt}
                           >
                              {['Information / Action', 'Comments / Approval', 'Construction', 'Record'].map((typeFile, i) => (
                                 <Select.Option key={i} value={typeFile}>{typeFile}</Select.Option>
                              ))}
                           </SelectTradeStyled>
                        </div>
                     )}

                  </div>
               )}



               {formRefType !== 'form-reply-multi-type' && pageSheetTypeName === 'page-rfam' &&
                  (
                     <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Submission Type</div>
                        <SelectTradeStyled
                           style={{ width: 150, marginRight: 100 }}
                           showSearch
                           optionFilterProp='children'
                           onChange={(value) => setSubmissionType(value)}
                           filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                           suffixIcon={<div></div>}
                           value={submissionType}
                        >
                           {['New Submittal', 'Alternative', 'Resubmittal'].map(typeSubmit => (
                              <Select.Option key={typeSubmit} value={typeSubmit}>{typeSubmit}</Select.Option>
                           ))}
                        </SelectTradeStyled>

                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Contract Drawing No. (if applicable)</div>
                        <InputStyled
                           style={{ width: 150 }}
                           onChange={(e) => setContractDrawingNo(e.target.value)}
                           value={contractDrawingNo}
                        />
                     </div>
                  )}



               {formRefType !== 'form-reply-multi-type' && pageSheetTypeName === 'page-cvi' &&
                  (
                     <div style={{ display: 'flex', marginBottom: 20 }}>
                        <div style={{ width: 150, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold' }}>Conversation Among</div>
                        <InputStyled
                           style={{ width: '90%', marginBottom: 10, borderRadius: 0 }}
                           onChange={(e) => setConversationAmong(e.target.value)}
                           value={conversationAmong}
                        />
                     </div>
                  )}



               {formRefType !== 'form-reply-multi-type' && (pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-mm') && (
                  <>
                     <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', marginRight: 50 }}>
                           <div style={{ marginRight: 10, fontWeight: 'bold' }}>Date</div>
                           <DatePickerStyled
                              value={dateConversation}
                              format={'DD/MM/YY'}
                              onChange={(e) => setDateConversation(e)}
                           />
                        </div>

                        <div style={{ display: 'flex' }}>
                           <div style={{ marginRight: 10, fontWeight: 'bold' }}>Time</div>
                           <TimePickerStyled
                              defaultValue={moment('12:08', 'HH:mm')} format={'HH:mm'}
                              value={timeConversation}
                              onChange={(e) => setTimeConversation(e)}
                           />
                        </div>
                     </div>
                  </>
               )}


               <br />

               <div style={{ display: 'flex', marginBottom: 20 }}>
                  <div style={{ width: 90, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold', marginBottom: 10 }}>Description</div>
                  <TextAreaStyled
                     style={{ width: '85%', marginBottom: 10, borderRadius: 0 }}
                     rows={5}
                     onChange={(e) => setDescription(e.target.value)}
                     value={description}
                     placeholder='Write details...'
                  />
               </div>


               {pageSheetTypeName === 'page-dt' && (
                  <div style={{ display: 'flex', marginBottom: 20 }}>
                     <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 10 }}>{'Tags'}</div>
                     <div style={{ width: '85%' }}>
                        <SelectRecipientStyled
                           mode='tags'
                           placeholder={'Item Tag...'}
                           value={listTagForDtForm}
                           onChange={(list) => setListTagForDtForm([...new Set(list)])}
                        >
                           {listTagForDtForm.map(itemTag => {
                              return (
                                 <Option key={itemTag}>
                                    <div style={{ background: 'transparent', fontWeight: 'normal', color: 'black', padding: '0 5px' }}>{itemTag}</div>
                                 </Option>
                              )
                           })}
                        </SelectRecipientStyled>
                     </div>
                  </div>
               )}




               {
                  formRefType !== 'form-reply-multi-type' &&
                  pageSheetTypeName === 'page-rfam' &&
                  (
                     <>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                           <div style={{ width: 90, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold', marginBottom: 10 }}>Contract Specification</div>
                           <TextAreaStyled
                              style={{ width: '85%', marginBottom: 10, borderRadius: 0 }}
                              rows={5}
                              onChange={(e) => setContractSpecification(e.target.value)}
                              value={contractSpecification}
                              placeholder='Write contract specification...'
                           />
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                           <div style={{ width: 90, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold', marginBottom: 10 }}>Proposed Specification</div>
                           <TextAreaStyled
                              style={{ width: '85%', marginBottom: 10, borderRadius: 0 }}
                              rows={5}
                              onChange={(e) => setProposedSpecification(e.target.value)}
                              value={proposedSpecification}
                              placeholder='Write proposed specification...'
                           />
                        </div>
                     </>
                  )}




               {
                  formRefType !== 'form-reply-multi-type' &&
                  pageSheetTypeName === 'page-cvi' &&
                  (
                     <>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                           <div style={{ marginRight: 30 }}>
                              <div style={{ marginBottom: 10 }}>
                                 <CheckboxStyled
                                    onChange={() => setIsCostImplication(true)}
                                    checked={isCostImplication}
                                 >Variation with cost implication</CheckboxStyled>
                              </div>
                              <div>
                                 <CheckboxStyled
                                    onChange={() => setIsTimeExtension(true)}
                                    checked={isTimeExtension}
                                 >With time extension</CheckboxStyled>
                              </div>
                           </div>
                           <div>
                              <div style={{ marginBottom: 10 }}>
                                 <CheckboxStyled
                                    onChange={() => setIsCostImplication(false)}
                                    checked={!isCostImplication}
                                 >With no cost implication</CheckboxStyled>
                              </div>
                              <div>
                                 <CheckboxStyled
                                    onChange={() => setIsTimeExtension(false)}
                                    checked={!isTimeExtension}
                                 >With no time extension</CheckboxStyled>
                              </div>
                           </div>
                        </div>
                     </>
                  )}


               {
                  formRefType === 'form-reply-multi-type' &&
                  (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') &&
                  (
                     <div style={{ display: 'flex', marginBottom: 15 }}>
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Status</div>
                        <SelectTradeStyled
                           style={{ width: 400 }}
                           showSearch
                           optionFilterProp='children'
                           onChange={(value) => {
                              let statusOutput = value;
                              for (const stt in consultantStatusObj) {
                                 if (consultantStatusObj[stt] === statusOutput) {
                                    statusOutput = stt;
                                 };
                              };
                              setConsultantReplyStatus(statusOutput);
                           }}
                           filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           }
                           suffixIcon={<div></div>}
                           value={consultantStatusObj[consultantReplyStatus]}
                        >
                           {consultantStatus.map(status => (
                              <Select.Option key={status} value={status}>{status}</Select.Option>
                           ))}
                        </SelectTradeStyled>
                     </div>
                  )}

               {listGeneralData && pageSheetTypeName !== 'page-mm' && (
                  <ListRequirementForm
                     listGeneralData={listGeneralData}
                     setListGeneralData={setListGeneralData}
                     formRefType={formRefType}
                     refType={refType}
                  />
               )}

               <br />


               {formsToLinkQrOrQrm.length > 0 && (
                  <div>
                     <div style={{ fontWeight: 'bold' }}>List Form {refType === 'qr' ? 'RFA' : 'RFAM'} To Link:</div>
                     {formsToLinkQrOrQrm.map((form, i) => (
                        <div key={i} style={{ display: 'flex', padding: 5 }}>
                           <div style={{ marginRight: 10 }}>{(i + 1) < 10 ? ('0' + (i + 1)) : (i + 1)}) {form}</div>
                           <Tooltip title='Remove'>
                              <Icon
                                 type='close'
                                 style={{ transform: 'translateY(4px)' }}
                                 onClick={() => {
                                    setFormsToLinkQrOrQrm(formsToLinkQrOrQrm.filter(x => x !== form));
                                 }}
                              />
                           </Tooltip>
                        </div>
                     ))}
                  </div>
               )}

               <br />

               <div style={{ marginBottom: 35, display: 'flex' }}>
                  {(formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') && pageSheetTypeName === 'page-dt' && ['upload-only', 'rfa', 'rfam', 'rfi', 'cvi'].map((tabName, i) => (
                     <Button
                        key={tabName}
                        style={{
                           borderRadius: 0,
                           paddingLeft: 25,
                           paddingRight: 25,
                           border: `1px solid ${colorType.grey3}`,
                           borderBottom: 'none',
                           borderLeft: i > 0 ? 'none' : `1px solid ${colorType.grey3}`,
                           background: linkedFormTypeName === tabName ? colorType.primary : 'white',
                           color: linkedFormTypeName === tabName ? 'white' : colorType.primary,
                           fontWeight: linkedFormTypeName === tabName ? 'bold' : 'normal',
                           boxShadow: 'none'
                        }}
                        onClick={() => {
                           if (linkedFormTypeName !== tabName) {
                              setLinkedFormTypeName(tabName);
                           };
                        }}
                     >
                        {tabName === 'upload-only' ? 'Documents not in DMS' : tabName === 'rfa' ? 'DMS' : tabName.toUpperCase()}
                     </Button>
                  ))}
                  <div style={{
                     width: '90%',
                     borderBottom: `1px solid ${colorType.grey3}`
                  }}></div>
               </div>

               <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>

                     {checkButtonShownUploadFormReply && (
                        <Upload
                           name='file' accept='application/pdf' multiple={false} showUploadList={false}
                           headers={{ authorization: 'authorization-text' }}
                           beforeUpload={() => { return false }}
                           onChange={onChangeUploadFormReply}
                        >
                           <ButtonStyle
                              marginRight={5}
                              name='Upload Reply Form'
                           />
                        </Upload>
                     )}

                     <Upload
                        name='file' accept='application/pdf' multiple={true} showUploadList={false}
                        headers={{ authorization: 'authorization-text' }}
                        beforeUpload={() => { return false }}
                        onChange={onChangeUploadPdfDrawing}
                        ref={refUpload}
                     >
                        <ButtonStyle
                           marginRight={5}
                           name='Upload Documents'
                        />
                     </Upload>


                     {checkButtonShownRfaLink && (
                        <ButtonStyle
                           marginRight={10}
                           name='Link Drawings DMS'
                           onClick={() => setTablePickDrawingRfaSubmitted('rfa')}
                        />
                     )}


                     {arrayButtonLinkMultiForm.map(type => (
                        <ButtonStyle
                           key={type}
                           marginRight={10}
                           name={`Link Submitted ${type.toUpperCase()} Form`}
                           onClick={() => setTablePickDrawingRfaSubmitted(type)}
                        />
                     ))}
                  </div>

                  {checkButtonShownAutoSearchLink && (
                     <ButtonAutoSearch
                        onClickAutoSearchDrawing={onClickAutoSearchDrawing}
                        loadingAutoSearch={loadingAutoSearch}
                        linkedFormTypeName={linkedFormTypeName}
                     />
                  )}
               </div>




               {(dataInputForTable.length > 0) && (
                  <div style={{
                     width: window.innerWidth * 0.9 - 80,
                     height: dataInputForTable.length * 28 + 80
                  }}>
                     <TableStyled
                        fixed
                        columns={generateColumnsListDwgRef(headersDwgRef({ pageSheetTypeName, isFormEditting, filesPdfDrawing, formRefType, linkedFormTypeName, dwgsImportFromRFA }), nosColumnFixed)}
                        data={dataInputForTable}
                        rowHeight={28}
                     />
                  </div>
               )}


            </div>


            <div style={{
               padding: 20,
               display: 'flex',
               flexDirection: 'row-reverse',
               justifyContent: 'space-between'
            }}>
               <div style={{ display: 'flex' }}>
                  <ButtonStyle
                     onClick={() => setModalConfirmsubmitOrCancel('cancel')}
                     name='Cancel'
                  />

                  {(formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type') && (
                     <>
                        {isFormEditting ? (
                           <ButtonStyle
                              onClick={() => setModalConfirmsubmitOrCancel(getTypeActionName(formRefType, withNoEmailSent, currentRefData, refType, company))}
                              name='Update Form'
                              background={colorType.primary}
                              colorText={'white'}
                              marginLeft={10}
                           />
                        ) : (
                           <>
                              {(
                                 (pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') || withNoEmailSent // CHUA_FIX_DC => DONE
                              ) && (
                                    <ButtonStyle
                                       onClick={() => setModalConfirmsubmitOrCancel('action-multiform-download')}
                                       name='Create Form & Download Pdf'
                                       background={colorType.primary}
                                       colorText={'white'}
                                       marginLeft={10}
                                    />
                                 )}


                              {
                                 !withNoEmailSent &&
                                 pageSheetTypeName !== 'page-mm' &&
                                 pageSheetTypeName !== 'page-qr' &&
                                 pageSheetTypeName !== 'page-qrm' &&
                                 pageSheetTypeName !== 'page-rfc' && (
                                    <ButtonStyle
                                       onClick={() => setModalConfirmsubmitOrCancel('submit-request-signature')}
                                       name='Create Form & Email For Signature'
                                       background={colorType.primary}
                                       colorText={'white'}
                                       marginLeft={10}
                                    />
                                 )}


                              {!withNoEmailSent && (
                                 <ButtonStyle
                                    onClick={() => setModalConfirmsubmitOrCancel(
                                       refType === 'mm' ? 'submit-meeting-minutes'
                                          : (refType === 'qr' || refType === 'qrm') ? 'submit-qr-or-qrm'
                                             : refType === 'rfc' ? 'submit-rfc'
                                                : 'submit-directly-to-consultants'
                                    )}
                                    name={pageSheetTypeName === 'page-rfc' ? 'Email This Form' : 'Email To Consultants'}
                                    background={colorType.primary}
                                    colorText={'white'}
                                    marginLeft={10}
                                 />
                              )}
                           </>
                        )}
                     </>
                  )}

                  {formRefType === 'form-reply-multi-type' && (
                     <>
                        <ButtonStyle
                           onClick={() => setModalConfirmsubmitOrCancel(getTypeActionName(formRefType, withNoEmailSent, currentRefData, refType, company))}
                           name='Submit'
                           background={colorType.primary}
                           colorText={'white'}
                           marginLeft={10}
                        />
                     </>
                  )}

               </div>


               <div style={{ display: 'flex' }}>

                  {isButtonDeleteFormShown && (
                     <IconTable type='delete' onClick={() => {
                        setPanelConfirmChange(true);
                        setPanelUpdateForm('delete-form');
                     }} />
                  )}
                  {isButtonForwardEmailShown && <IconTable type='arrow-right' onClick={() => setPanelUpdateForm('forward-email')} />}
               </div>



            </div>
         </div>


         {panelUpdateForm && (panelUpdateForm !== 'delete-form') && (
            <ModalStyled
               title={'Forward Email'}
               visible={panelUpdateForm ? true : false} footer={null} destroyOnClose={true} centered={true}
               onOk={() => setPanelUpdateForm(null)} onCancel={() => setPanelUpdateForm(null)}
               width={window.innerWidth * 0.6}
            >
               <div style={{ padding: 30, paddingLeft: 50, paddingRight: 50 }}>
                  <div>
                     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 5 }}>To</div>
                        <SelectRecipientStyled
                           style={{ width: window.innerWidth * 0.5 }}
                           mode='tags' placeholder={'Select Recepient To...'}
                           onChange={(list) => setListEmailForwardTo(list)}
                        >
                           {[...listUser, ...listGroupOutput].map(cm => {
                              const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', ' ') : cm;
                              return (
                                 <Option key={cm}>{textShown}</Option>
                              );
                           })}
                        </SelectRecipientStyled>
                     </div>

                     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 5 }}>Cc</div>
                        <SelectRecipientStyled
                           style={{ width: window.innerWidth * 0.5 }}
                           mode='tags' placeholder={'Select Recepient Cc...'}
                           onChange={(list) => setListEmailForwardCc(list)}
                        >
                           {[...listUser, ...listGroupOutput].map(cm => {
                              const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', ' ') : cm;
                              return (
                                 <Option key={cm}>{textShown}</Option>
                              );
                           })}
                        </SelectRecipientStyled>

                     </div>
                  </div>

                  <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                     <ButtonGroupComp
                        onClickCancel={() => {
                           setPanelUpdateForm(null);
                           setListEmailForwardTo([]);
                           setListEmailForwardCc([]);
                        }}
                        onClickApply={() => setPanelConfirmChange(true)}
                        newTextBtnApply={'Forward Email'}
                     />
                  </div>
               </div>

            </ModalStyled>
         )}

         {panelConfirmChange && (
            <ModalStyled
               title={'Confirmation'} visible={panelConfirmChange}
               footer={null} destroyOnClose={true} centered={true}
            >
               <div style={{ padding: 20 }}>{`Are you sure to ${panelUpdateForm === 'delete-form' ? 'delete the form' : 'forward email'} ?`}</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonGroupComp
                     onClickCancel={() => setPanelConfirmChange(false)}
                     newTextBtnApply={'Yes'}
                     onClickApply={() => {
                        if (panelUpdateForm === 'delete-form') {
                           getSheetRows({ loading: true });
                           submissionMultiFormUpdateOrForwardEmail({ currentRefData, panelUpdateForm });

                        } else if (panelUpdateForm === 'forward-email') {
                           if (!(listEmailForwardTo.length > 0)) {
                              return message.info('Please include email to forward!', 2);
                           };
                           const listCompanyReplyToAdd = listEmailForwardTo
                              .filter(x => extractConsultantName(x))
                              .map(x => extractConsultantName(x));

                           const currentEmailTo = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo') || [];
                           const emailGroupToAdd = listEmailForwardTo.filter(x => {
                              return extractConsultantName(x) && !currentEmailTo.find(em => em === x);
                           });


                           getSheetRows({ loading: true });

                           submissionMultiFormUpdateOrForwardEmail({
                              listEmailForwardTo,
                              listEmailForwardCc,
                              currentRefData,
                              panelUpdateForm,
                              listCompanyReplyToAdd,
                              emailGroupToAdd
                           });
                        };

                     }}
                  />
               </div>
            </ModalStyled>
         )}





         {tablePickDrawingRfaSubmitted && (
            <ModalStyled
               title={tablePickDrawingRfaSubmitted === 'dms' ? 'Select DMS Drawings To Link' : `Select ${tablePickDrawingRfaSubmitted.toUpperCase()}`}
               visible={tablePickDrawingRfaSubmitted === null ? false : true}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.85}
               onOk={() => setTablePickDrawingRfaSubmitted(null)}
               onCancel={() => setTablePickDrawingRfaSubmitted(null)}
            >
               {tablePickDrawingRfaSubmitted === 'dms' ? (
                  <TableDrawingDmsToIssueForConstruction
                     activityType={'link-new-dt-dms'}
                     stateProject={stateProject}
                     dataInputDmsTable={dataInputDmsTable}
                     dataDmsDrawingLink={dataDmsDrawingLink}
                     onClickCancelModalPickDrawingToIssue={() => setTablePickDrawingRfaSubmitted(null)}
                     onClickApplyModalPickDrawingToIssue={(dwgToLink) => {
                        setDataDmsDrawingLink({
                           ...dataDmsDrawingLink,
                           [fileNameToLinkDms]: dwgToLink
                        });
                        setTablePickDrawingRfaSubmitted(null);
                     }}
                  />
               ) : (
                  <TableDrawingRfaForMultiForm
                     onClickCancelModalPickDrawing={() => setTablePickDrawingRfaSubmitted(null)}
                     onClickApplyModalPickRfaDrawings={(dwgsRfaToAdd) => {
                        setDwgsImportFromRFA(dwgsRfaToAdd);
                        setTablePickDrawingRfaSubmitted(null);
                     }}
                     dwgsImportFromRFA={dwgsImportFromRFA}
                     stateProject={stateProject}
                     refTypeTable={tablePickDrawingRfaSubmitted}
                     onClickApplyModalPickRfaOrRfamLinkToQrOrQrm={(formsToLink) => {
                        setFormsToLinkQrOrQrm(formsToLink);
                        setTablePickDrawingRfaSubmitted(null);
                     }}
                  />
               )}
            </ModalStyled>
         )}


         {modalConfirmsubmitOrCancel && formRefType && (
            <ModalStyled
               title={'Confirmation'}
               visible={modalConfirmsubmitOrCancel !== null ? true : false}
               footer={null}
               destroyOnClose={true}
               centered={true}
            >
               <ConfirmSubmitOrCancelModal
                  typeConfirm={modalConfirmsubmitOrCancel}
                  formRefType={formRefType}
                  refData={formRefType.includes('form-submit-multi-')
                     ? `${refType.toUpperCase()}/${projectNameShort}/${tradeForFirstTimeSubmit}/${refNumberSuffixFirstTimeSubmit}`
                     : formRefType.includes('form-resubmit-multi-')
                        ? `${currentRefData[refKey]}${refNewVersionResubmitSuffix}`
                        : formRefType.includes('form-reply-multi-')
                           ? `${currentRefData[refKey]}` : null}
                  onClickCancelConfirmModal={() => setModalConfirmsubmitOrCancel(null)}
                  onClickApplyConfirmModal={(typeConfirm) => {
                     if (typeConfirm === 'cancel') {
                        setModalConfirmsubmitOrCancel(null);
                        onClickCancelModal();
                     } else {
                        onClickApplyDoneFormRef(typeConfirm);
                     };
                  }}
                  withNoEmailSent={withNoEmailSent}
                  currentRefData={currentRefData}
                  refType={refType}
               />
            </ModalStyled>
         )}

      </>
   );
};

export default PanelAddNewMultiForm;



const getInputForTable = ({ filesPdfDrawing, dwgsImportFromRFA, formReplyUpload, dataDmsDrawingLink }) => {
   let output = [];
   if (formReplyUpload) {
      for (const pdfDrawing in formReplyUpload) {
         output.push({
            id: mongoObjectId(),
            'Type': 'Form',
            'File Name': pdfDrawing
         });
      };
   };

   if (filesPdfDrawing) {
      for (const pdfDrawing in filesPdfDrawing) {
         let dataLinkDms = {};
         const infoDrawingDmsLinked = dataDmsDrawingLink[pdfDrawing];
         if (infoDrawingDmsLinked) {
            dataLinkDms = {
               'Link Drawing From DMS': infoDrawingDmsLinked,
            };
         };

         output.push({
            id: mongoObjectId(),
            'Type': 'Drawing',
            'File Name': pdfDrawing,
            ...dataLinkDms
         });
      };
   };

   if (dwgsImportFromRFA && dwgsImportFromRFA.length > 0) {
      dwgsImportFromRFA.forEach(row => {
         output.push(row);
      });
   };

   return output;
};

const CellRemoveDrawing = (props) => {
   const { onClickRemoveDwgBtn, rowData } = props;

   return (
      <Tooltip title='Remove File'>
         <Icon type='close' onClick={() => onClickRemoveDwgBtn(rowData)} />
      </Tooltip>
   );
};



const ConfirmSubmitOrCancelModal = ({ typeConfirm, formRefType, refData, onClickCancelConfirmModal, onClickApplyConfirmModal }) => {

   return (
      <div style={{ padding: 20, width: '100%' }}>
         {typeConfirm === 'submit-request-signature' ? (
            <div>Are you sure to submit the <span style={{ fontWeight: 'bold' }}>{refData}</span>?</div>

         ) : typeConfirm === 'submit-signed-off-final' ? (
            <div>Are you sure to submit the <span style={{ fontWeight: 'bold' }}>{refData}</span>?</div>

         ) : (typeConfirm === 'submit-qr-or-qrm' || typeConfirm === 'submit-rfc') ? (
            <div>Are you sure to submit the <span style={{ fontWeight: 'bold' }}>{refData}</span>?</div>

         ) : typeConfirm === 'submit-directly-to-consultants' ? (
            <div>Are you sure to submit the <span style={{ fontWeight: 'bold' }}>{refData}</span>?</div>

         ) : typeConfirm === 'action-multiform-download' ? (
            <div>Are you sure to download Pdf form ?</div>

         ) : typeConfirm === 'action-update-form' ? (
            <div>Are you sure to update Pdf form ?</div>

         ) : typeConfirm === 'reply-signed-off' ? (
            <div>Are you sure to reply the <span style={{ fontWeight: 'bold' }}>{refData}</span>?</div>

         ) : typeConfirm === 'cancel' ? (
            <div>Are you sure to cancel the {formRefType === 'form-reply-multi-type' ? 'response' : 'submission'}?</div>
         ) : ''}

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelConfirmModal}
               onClickApply={() => onClickApplyConfirmModal(typeConfirm)}
               newTextBtnApply={'Yes'}
            />
         </div>
      </div>
   );
};


const getTypeActionName = (formRefType, withNoEmailSent, currentRefData, refType, company) => {
   if (formRefType === 'form-reply-multi-type') {
      if (withNoEmailSent) {
         return 'action-update-form';
      } else {
         const dateNoSendEmailReply = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'dateSendNoEmail', company);
         const dateReply = getInfoValueFromRefDataForm(currentRefData, 'reply', refType, 'date', company);
         const momentCreatedAt = dateNoSendEmailReply || dateReply;

         if (momentCreatedAt) {
            const duration = moment.duration(moment(new Date()).diff(momentCreatedAt)).asMinutes();
            if (EDIT_DURATION_MIN - duration > 0) { // EDIT_DURATION_MIN not over yet =>>>>>>>>>>>>>>>>>>>> DO NOT SEND ANY EMAIL
               return 'action-update-form';
            };
         };
         return (refType === 'qr' || refType === 'qrm') ? 'reply-qr-or-qrm' : refType === 'rfc' ? 'reply-rfc' : 'reply-signed-off';
      };


   } else {
      if (withNoEmailSent) {
         return 'action-multiform-download';
      } else {

         const dateNoSendEmailSubmission = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'dateSendNoEmail');
         const dateSubmission = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'date');
         const momentCreatedAt = dateNoSendEmailSubmission || dateSubmission;

         if (momentCreatedAt) {
            const duration = moment.duration(moment(new Date()).diff(momentCreatedAt)).asMinutes();
            if (EDIT_DURATION_MIN - duration > 0) { // EDIT_DURATION_MIN not over yet =>>>>>>>>>>>>>>>>>>>> DO NOT SEND ANY EMAIL
               return 'action-update-form';
            };
         };

         if (refType === 'mm') return 'submit-meeting-minutes';
         if (refType === 'qr' || refType === 'qrm') return 'submit-qr-or-qrm';
         if (refType === 'rfc') return 'submit-rfc';

         const isSignedOffAlreadySubmitted = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkSignedOffFormSubmit');

         if (!isSignedOffAlreadySubmitted) {
            return 'action-update-form';
         } else {
            return 'submit-signed-off-final';
         };
      };
   };
};


const ModalStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;


export const getHeaderWidthDwgRef = (header) => {
   if (header === 'Type') return 85;
   else if (header === 'File Name') return 450;
   else if (header === 'Link Drawing From DMS') return 1000;
   else if (header === 'RFA Ref') return 200;
   else if (header === 'RFAM Ref') return 200;
   else if (header === 'CVI Ref') return 200;
   else if (header === 'RFI Ref') return 200;
   else if (header === 'Dwg Rev') return 50;
   else if (header === 'Form Rev') return 60;
   else if (header === 'IFC Rev') return 50;
   else if (header === 'Drawing Number') return 250;
   else return 50;
};



const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      border: none;
      background: ${colorType.primary}
   };
`;

const TableStyled = styled(Table)`
   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .BaseTable__row-cell-text {
      color: black
   };
   .BaseTable__table .BaseTable__body {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   };
   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };
   .BaseTable__header-row {
      background: ${colorType.grey1};
   };
   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;
const InputStyled = styled(Input)`
   transform: translateY(-5px);
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   }
`;
const TextAreaStyled = styled(TextArea)`
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   &:focus {
      outline: none;
      box-shadow: none;
   };
   white-space: pre-wrap;
`;


const SelectRecipientStyled = styled(Select)`
   width: 100%;
   .ant-select-selection__choice {
      margin-right: 5px;
      border: 1px solid ${colorType.grey1}
   }
   .ant-select-selection__choice__remove {
      
   }

   .ant-select-selection {
      border-radius: 0;
      width: 100%;
      background: transparent;

      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      }
   };

`;

const TimePickerStyled = styled(TimePicker)`
   transform: translateY(-5px);
   .ant-time-picker-input {
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
      width: 110px;
   };
   .anticon {
      transform: translateX(-10px);
   }
`;


const DatePickerStyled = styled(DatePicker)`
   transform: translateY(-5px);
   .ant-calendar-picker-input {
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
      width: 110px;
   };
`;


const SelectTradeStyled = styled(Select)`
   transform: translateY(-5px);
   width: 60px;
   cursor: alias;

   .ant-select-selection__rendered {
      padding: 0;
      margin: 0;
      margin-left: 10px;
   };
   .ant-select-selection {
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
   }
`;




const headersDwgRef = ({ pageSheetTypeName, isFormEditting, filesPdfDrawing, formRefType, linkedFormTypeName, dwgsImportFromRFA }) => {

   let forDtOnly = [];
   if (pageSheetTypeName === 'page-dt' && (formRefType === 'form-submit-multi-type' || formRefType === 'form-resubmit-multi-type')) {
      if (!isFormEditting || (isFormEditting && (Object.keys(filesPdfDrawing).length > 0 || dwgsImportFromRFA.length > 0))) {

         forDtOnly = linkedFormTypeName === 'rfa' ? [
            'IFC Rev',
            'Dwg Rev',
            'RFA Ref',
            'Link Drawing From DMS'
         ] : linkedFormTypeName === 'upload-only' ? [] : [
            'IFC Rev',
            'Form Rev',
            'Link Drawing From DMS'
         ];
      };
   };

   return [
      'Type',
      'File Name',
      ...forDtOnly
   ];
};




const ButtonAutoSearch = ({ onClickAutoSearchDrawing, loadingAutoSearch, linkedFormTypeName }) => {
   return (
      <Button
         style={{
            borderRadius: 0,
            marginRight: 28
         }}
         disabled={loadingAutoSearch}
         loading={loadingAutoSearch}
         onClick={onClickAutoSearchDrawing}
      >
         {linkedFormTypeName === 'rfa' ? 'Auto Search DMS Drawings' : `Auto Search ${linkedFormTypeName.toUpperCase()} Form`}
      </Button>
   );
};


const CellForTableUploadFile = (props) => {

   const { cellData, rowData, openDmsDrawingCurrentAndHistoryTable, dataInputDmsTable, column: columnData, setIssueForConstructionRevision, linkedFormTypeName } = props;

   let column = columnData['key'];

   if (column === 'Link Drawing From DMS' && rowData['Type']) {
      return rowData['Type'] === 'Drawing' ? (
         <CellLinkDrawingDms
            rowData={rowData}
            openDmsDrawingCurrentAndHistoryTable={openDmsDrawingCurrentAndHistoryTable}
            linkedFormTypeName={linkedFormTypeName}
         />
      ) : (
         <div style={{ background: colorType.grey4 }}></div>
      );
   } else if (column === 'IFC Rev') {
      return (
         <CellRevisionForDtForm
            rowData={rowData}
            setIssueForConstructionRevision={setIssueForConstructionRevision}
            linkedFormTypeName={linkedFormTypeName}
         />
      );
   };


   let cellText;
   if (!rowData['Type'] && !rowData['File Name']) { // not FILE UPLOAD
      if (column === 'Type') {
         if (rowData['rfamRef']) {
            cellText = 'RFAM';
         } else if (rowData['rfiRef']) {
            cellText = 'RFI';
         } else if (rowData['cviRef']) {
            cellText = 'CVI';
         } else if (rowData['rfaNumber']) {
            cellText = 'RFA';
         };
      } else if (column === 'File Name') {
         if (rowData['checkIfEditForm']) {
            cellText = rowData['Drawing Number'] + ' - ' + rowData['companySubmitThisDwg'];
         } else {
            if (rowData[`${linkedFormTypeName}Ref`]) {
               cellText = (
                  <span>
                     <span>{getRefStringWithVersion(rowData, linkedFormTypeName)} - Consultants: </span>
                     <span style={{ fontWeight: 'bold' }}>{(rowData.consultantsSelected || []).join(' | ')}</span>
                  </span>
               );
            } else if (rowData['rfaNumber']) {
               cellText = (
                  <span>
                     <span>{rowData['Drawing Number']} - Consultants: </span>
                     <span style={{ fontWeight: 'bold' }}>{(rowData.consultantsSelected || []).join(' | ')}</span>
                  </span>
               );
            };
         };
      } else if (column === 'Dwg Rev') {
         cellText = rowData['Rev'] || '';
      } else if (column === 'Form Rev') {
         cellText = rowData['revision'] || '';
      } else if (column === `${linkedFormTypeName.toUpperCase()} Ref`) {
         cellText = getRefStringWithVersion(rowData, linkedFormTypeName);
      };
   } else { // FILE UPLOAD
      if ((column === 'Dwg Rev' && rowData['Link Drawing From DMS'])) {
         cellText = rowData['Link Drawing From DMS']['Rev'] || '';
      } else if ((column === 'Form Rev' && rowData['Link Drawing From DMS'])) {
         cellText = rowData['Link Drawing From DMS']['revision'] || '';
      } else if ((column === 'RFA Ref' && rowData['Link Drawing From DMS'])) {
         cellText = rowData['Link Drawing From DMS']['RFA Ref'] || '';
      };
   };

   return (
      <div style={{
         textOverflow: 'ellipsis',
         overflow: 'hidden',
         whiteSpace: 'nowrap',
      }}>
         {cellText || cellData}
      </div>
   );
};



export const CellRevisionForDtForm = ({ rowData, setIssueForConstructionRevision, linkedFormTypeName }) => {

   let revisionInit = '';
   if (rowData['Link Drawing From DMS']) {
      const ifcRev = (rowData['Link Drawing From DMS'] || {})['IFC Rev'];
      const drawingRev = linkedFormTypeName === 'rfa'
         ? (rowData['Link Drawing From DMS'] || {})['Rev']
         : (rowData['Link Drawing From DMS'] || {})['revision'];

      revisionInit = ifcRev || drawingRev || '0';

   } else if (rowData['rfaNumber'] && linkedFormTypeName === 'rfa') {
      revisionInit = rowData['IFC Rev'] || rowData['Rev'] || '0';

   } else if (rowData[`${linkedFormTypeName}Ref`] && linkedFormTypeName && linkedFormTypeName !== 'rfa') {
      revisionInit = rowData['IFC Rev'] || rowData['revision'] || '0';
   };

   const [value, setValue] = useState(revisionInit);


   return (
      <Tooltip title='Edit Revision Number'>
         <input
            style={{
               outline: 'none', border: 'none', borderRadius: 0, width: '80%',
               background: 'transparent'
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onBlur={(e) => {
               if (!((e.target.value || '').trim())) {
                  message.warn('IFC Version can not be empty!');
                  setIssueForConstructionRevision(rowData, revisionInit);
                  setValue(revisionInit);
               } else {
                  setIssueForConstructionRevision(rowData, (e.target.value || '').trim());
               };
            }}
            disabled={!rowData['Link Drawing From DMS'] && !rowData['rfaNumber'] && !rowData[`${linkedFormTypeName}Ref`]} // only (file already link with DMS drawing || file link in RFA... system) can edit IFC revision =>> file just upload only can not edit IFC revision
         />
      </Tooltip>
   );
};



const CellLinkDrawingDms = ({ rowData, openDmsDrawingCurrentAndHistoryTable, linkedFormTypeName }) => {

   const dwgLink = rowData['Link Drawing From DMS'];

   return (
      <div style={{ display: 'flex' }}>
         <div style={{ marginRight: 10 }}>
            <Tooltip title='Link Drawing From DMS'>
               <Icon type='tag' onClick={() => openDmsDrawingCurrentAndHistoryTable(rowData['File Name'])} />
            </Tooltip>
         </div>
         <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
         }}>
            {dwgLink ? (
               <>
                  {linkedFormTypeName === 'rfa' ? (
                     <span>{dwgLink['Drawing Number']} - {dwgLink['Drawing Name']}</span>
                  ) : (
                     <span>{getRefStringWithVersion(dwgLink, linkedFormTypeName)} - {getInfoValueFromRefDataForm(dwgLink, 'submission', linkedFormTypeName, 'emailTitle')}</span>
                  )}
               </>
            ) : null}
         </div>
      </div>
   );
};


export const fetchAllDrawingsMultiFormToLinkDt = async ({ linkedFormTypeName, token, projectId, email }) => {
   try {
      if (linkedFormTypeName === 'rfa') {

         // NEED TO FETCH ALL DATA TO LINK DMS DRAWINGS (DWG WHICH NO RFA)
         const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isToFetchAllData: true }); // TEST_APPY_TAB_VIEW

         const { dataDmsFetched: { rows, publicSettings }, dataRowHistoryDmsFetched } = res.data;

         const { drawingTypeTree } = publicSettings;
         const rowsAllOutput = getOutputRowsAllSorted(drawingTypeTree, rows);

         let rowsAllOutputAllVersion = [...rowsAllOutput];

         rowsAllOutput.forEach(rowCurrent => {
            if (rowCurrent['rfaNumber']) {
               const rowsPrevious = dataRowHistoryDmsFetched.filter(r => r['row'] === rowCurrent['id']).sort((a, b) => a['Rev'] > b['Rev'] ? 1 : -1);
               rowsPrevious.forEach(r => {
                  r['_parentRow'] = rowCurrent['_parentRow'];
               });

               let currentIndex;
               rowsAllOutputAllVersion.forEach((row, i) => {
                  if (row['id'] === rowCurrent['id']) {
                     currentIndex = i;
                  };
               });
               const part1 = rowsAllOutputAllVersion.filter((x, i) => i <= currentIndex);
               const part2 = rowsAllOutputAllVersion.filter((x, i) => i > currentIndex);

               rowsAllOutputAllVersion = [
                  ...part1,
                  ...rowsPrevious,
                  ...part2
               ];
            };
         });
         return {
            drawingTypeTree,
            rowsAllOutputAllVersion,
            linkedFormTypeName
         };
      } else {

         let res = await apiFetchDataMultiByDisciplineForm(linkedFormTypeName, { token, projectId, email, isToFetchAllData: true });

         const { dataMultiFormFetched: rowsAllOutputAllVersion } = res.data;

         const { treeView } = getDataForMultiFormSheet(rowsAllOutputAllVersion, `page-${linkedFormTypeName}`);
         return {
            drawingTypeTree: treeView,
            rowsAllOutputAllVersion,
            linkedFormTypeName
         };
      };

   } catch (err) {
      message.warn('Network Error!')
      console.log(err);
   }
};