import { PROJECT_DETAIL, FOLDER_DETAIL,ITEM_DETAIL,APP_REDIRECT} from "../../types";



const projectDetail = data => ({
  type: PROJECT_DETAIL,
  data
});

export const storeProjectDetail = data => dispatch => {
  dispatch(projectDetail(data))
}

const folderDetail = data => ({
  type: FOLDER_DETAIL,
  data
});

export const storeFolderDetail = data => dispatch => {
  dispatch(folderDetail(data))
}

const itemDetail = data => ({
  type: ITEM_DETAIL,
  data
});

export const storeItemDetail = data => dispatch => {
  dispatch(itemDetail(data))
}

export const appRedirect = (data) => ({
  type: APP_REDIRECT,
  data
});

export const setRedirectData = data => dispatch =>
  dispatch(appRedirect(data));