import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import { message, Icon, Typography, Collapse, List, Card } from 'antd';
import { Modal, Button, InputPicker } from 'rsuite';
import { Tooltip } from '@material-ui/core';
import { sortProjectName } from '../../components/module_BimApp/function/PageFunction'
import _ from 'lodash'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import stc from 'string-to-color'
const { Text } = Typography
const { Panel } = Collapse;
class ProjectControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  
      data: [],
      loading: false,
      projects: [],
      openAddAdmin: false,
      projectIdCurrent: ''
    }

  }
  componentWillMount = () => {
    axios.get(`/api/users/getusers`)
      .then(res => {
        this.setState({ data: res.data })
        this.props.handleLoading(false)

      })
      .catch(err => {
        this.props.handleLoading(false)
        console.log(err)
      })

    axios.get(`/api/projects/get-all-project`, { params: { token: this.props.userInfo.token, email: this.props.userInfo.email } })
      .then(res => {
        res.data.sort(sortProjectName)
        this.setState({ projects: res.data })
      })
      .catch(res => {
        this.setState({ loading: false, error: true })
      })
  }





  handleCloseAddAdminPanel = () => {
    this.setState({ openAddAdmin: false })
  }
  removeMemberInAdminList=(e)=>{
    axios.post("/api/projects/remove-admin-for-project", { projectId: e.props.projectId,
       member: e.props.item.email, token: this.props.userInfo.token })
    .then(res => {
      message.success('Remove member success')
 
    })
    .catch(() => {
      message.error('Add member failed')

    })
  }
  render() {
    return (
      <>
    
        <Collapse accordion  >
          {this.state.projects.map((i, k) =>
            <Panel header={i.projectName} key={k} extra={
              <Icon
                type='plus'
                onClick={event => {
                  // If you don't want click extra trigger collapse, you can prevent this:
                  event.stopPropagation();
                  this.setState({ openAddAdmin: true, projectIdCurrent: i.project.projectId })
                }}
              />}>
              <List
                grid={{
                  gutter: 8,
                  xs: 3,
                  sm: 4,
                  md: 5,
                  lg: 5,
                  xl: 5,
                  xxl: 5,
                }}
                dataSource={i.members}
                renderItem={(item, index) => (
                  <MenuProvider id="admin_member_menu" animation={animation.zoom} data={{ item: item , projectId:i.project.projectId }}  >
                    <List.Item>
                      <Card className='card-group-user' style={{ borderColor: '#666666' }}
                        bodyStyle={{ padding: 0, overflowX: 'hidden' }} >
                        <div style={{
                          width: 32, height: 32, fontSize: 16
                          , backgroundColor: stc(item.email.slice(0, 1)), color: 'white', textAlign: 'center', display: 'inline-block', lineHeight: '32px'
                        }}>
                          {item.email.slice(0, 1).toLocaleUpperCase()}
                        </div>
                        <Tooltip placement='top' title={item.email}>
                          <Text strong ellipsis style={{ position: 'absolute', width: 'calc(100% - 37px)', lineHeight: '32px', marginLeft: 5 }}
                            ellipsis={true}>{item.email} </Text>
                        </Tooltip>

                      </Card>
                    </List.Item>
                  </MenuProvider>

                )}
              />
            </Panel>
          )}
        </Collapse>

        <Menu id='admin_member_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.removeMemberInAdminList}>
            <IconFont className="fas fa-trash" />Remove member
            </Item>
        </Menu>
        {this.state.openAddAdmin &&
          <SetAdminForProject
            open={this.state.openAddAdmin}
            close={this.handleCloseAddAdminPanel}
            data={this.state.data}
            projectId={this.state.projectIdCurrent}
            token={this.props.userInfo.token}
          />
        }
      </>

    );
  }
}

ProjectControl.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email,
    email: state.user.email,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(ProjectControl)

function SetAdminForProject(props) {
  const [user, setUser] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    props.close(null)
  }
  useEffect(() => {
    let temp = []
    _.forEach(props.data, v => {
      temp.push({ label: v.email, text: v.email, value: v.email })
    })
    setData(temp)
  }, [])

  const handleAddNewGroup = () => {
    axios.post("/api/projects/add-admin-for-project", { projectId: props.projectId, member: user, token: props.token })
      .then(res => {
        message.success('Add member success')
        props.close(null)
        setLoading(false)
      })
      .catch(() => {
        message.error('Add member failed')
        props.close(null)
        setLoading(false)
      })
  }
  const handleChangeUser = (value) => {
    setUser(value)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' backdrop='static'>
      <Modal.Header>
        <Modal.Title>Set Admin For Project</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <InputPicker
          data={data} size='sm' cleanable={false}
          placeholder="Select Type" onChange={handleChangeUser}
          block
          renderValue={(value) => {
            return (
              <div style={{ color: 'black' }}>
                {value}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleAddNewGroup} disabled={user.length === 0} loading={loading}> Add</Button>
        <Button appearance="default" onClick={handleClose} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}