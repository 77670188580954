import React, { useState } from 'react';
import { colorType } from '../../../constants';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';




const PanelConfirmDeleteFiles = ({ rowsSelected, onClickCancel, onClickApply }) => {


   return (
      <>
         <div style={{ padding: 20, color: 'black' }}>
            <div style={{ fontWeight: 'bold' }}>Are you sure to delete following files ?</div>
            <div style={{ padding: 5, maxHeight: window.innerHeight * 0.65, overflowY: 'scroll' }}>
               {rowsSelected.map((row, i) => (
                  <div key={i}>{i + 1}) {row['fileName']}</div>
               ))}
            </div>
         </div>

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
            <ButtonGroupComp
               onClickCancel={onClickCancel}
               onClickApply={onClickApply}
               newTextBtnApply={'Yes'}
            />
         </div>
      </>
   );
};

export default PanelConfirmDeleteFiles;
