import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import { IconButton, Icon, ButtonGroup, Loader } from 'rsuite';
import { Typography, Icon as IconAntd, message, Select, Tooltip, Row, Col } from 'antd';
import _ from 'lodash'
import axios from 'axios'
import Draggable from 'react-draggable';
import {
  getAllElementdbIdsOneModel, convertUnits
} from '../../../function/ForgeFunction'
import {
  formatNumber, convertHexColorToVector4, getRandomColor
} from '../../../function/TableFunction'
import { ControlGroup, Tree, Classes, Button as BlueprintButton, ButtonGroup as BlueprintButtonGroup } from "@blueprintjs/core";
import { Iconsvg } from '../../../../../image'
import { Scrollbars } from 'react-custom-scrollbars';
import { trackingUserUsing } from '../../../function/AdminFunction'
const { Text } = Typography;
const { Option } = Select;


const renderView = ({ style, ...props }) => {

  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
let tempItem = []
let nodeColor = null
let unit = ''
function RebarFilterDock(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [dataFilter, setDataFilter] = useState([])
  useEffect(() => {
    trackingUserUsing(props.email,props.projectId,'BIM App','Rebar Filter')
    tempItem = []
    setLoading(true)
    props.viewer.setModelUnits('mm')
    props.viewer.model.getProperties(props.viewer.model.getData().instanceTree.getRootId(), (modelAProperty) => {
      let check = false
      unit = ''
      _.forEach(modelAProperty.properties, property => {
        if (property.displayName === "IFC Application Name") {
          if (property.displayValue.includes('Tekla Structures')) {
            check = true
          } 
        }else if (property.displayName === "Unit") {
          unit = property.displayValue.toLowerCase()
        }    
      })
      if (!check) {
        message.warning('This file is not export from Tekla')
        $('#splitForgeViewer').height('calc(100% - 53.78px)')
        props.viewer.resize()
        props.onChangeDockDisplay('rebarPanel', false)
      } else {

        let allDbIds = getAllElementdbIdsOneModel(props.viewer)
        let temp = []
        _.forEach(allDbIds, id => {
          temp.push(checkParentForTekla(props.viewer, id))
        })
        temp = temp.concat(allDbIds)
        temp = _.uniq(temp)
        listElementTable(props.viewer, temp);
      }
    });

  }, [])
  useEffect(() => {
    $("#color-picker-rebar-filter").change(e => {
      let color = convertHexColorToVector4(e.target.value)
      _.forEach(nodeColor.item, v => {
        props.viewer.setThemingColor(v.id, color, props.viewer.impl.model, true);
        nodeColor.color = e.target.value
        nodeColor.label = generateNodeLabel(nodeColor)
      })
      setDataFilter(tempItem.slice(0))
    })
  }, [])
  useEffect(() => {
    tempItem = dataFilter
  }, [dataFilter])
  const listElementTable = (viewer, dbIds) => {
    let dataRebarTable = [];
    let count = dbIds.length;
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, (modelAProperty) => {
        let temp = {}
        let check = false
        let category = ''
        _.forEach(modelAProperty.properties, property => {
          if (property.displayCategory === "Element" && property.displayName === "Category" &&
            (property.displayValue === "Structural Rebar")) {
            category = property.displayValue
            check = true
            return false
          } else if (property.displayCategory === "Element" && property.displayName === "Category" &&
            (property.displayValue === "Specialty Equipment")) {
            category = property.displayValue
            check = true
            return false
          }
        })
        if (check) {
          temp.id = modelAdbId
          if (category === "Structural Rebar") {
            let weight =0
            let totalWeight =0
            _.forEach(modelAProperty.properties, property => {
              let displayValue =  property.displayValue
              if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                displayValue = convertUnits(property.units, 'mm', 1, property.displayValue, property.type, null)
              if (property.displayName === "a") {
                temp.a = formatNumber(displayValue)
              } else if (property.displayName === "b") {
                temp.b = formatNumber(displayValue)
              } else if (property.displayName === "c") {
                temp.c = formatNumber(displayValue)
              } else if (property.displayName === "d") {
                temp.d = formatNumber(displayValue)
              } else if (property.displayName === "e") {
                temp.e = formatNumber(displayValue)
              } else if (property.displayName === "f") {
                temp.f = formatNumber(displayValue)
              } else if (property.displayCategory === "Element" && property.displayName === "Name") {
                temp.name = displayValue === '' ? 'Unknown' : displayValue
              } else if (property.displayName === "Grade") {
                temp.grade = displayValue
              } else if (property.displayName === "Size") {
                temp.size = displayValue
              } else if (property.displayName === "Number of bars in group" || property.displayName === "Total Number") {
                temp.totalNumber = displayValue
              } else if (property.displayName === "Shape") {
                temp.shape = displayValue
              } else if (property.displayName === "Total Weight" || property.displayName.includes('Weight total')) {
                let split = displayValue.split(' ')
                totalWeight = _.toNumber(split[0])
              } else if (property.displayName === "Weight") {
                let split = displayValue.split(' ')
                weight = _.toNumber(split[0])
              } else if (property.displayName === "Length") {
                temp.length = displayValue
              }
            })
            temp.totalWeight = totalWeight!==0?totalWeight:weight
          } else if (category === 'Specialty Equipment') {
            _.forEach(modelAProperty.properties, property => {
              let displayValue =  property.displayValue
              if (property.units !== '' && property.units !== null && property.units !== undefined && (property.type === 2 || property.type === 3))
                displayValue = convertUnits(property.units, 'mm', 1, property.displayValue, property.type, null)
              if (property.displayCategory === "Element" && property.displayName === "Name") {
                temp.name = displayValue
              } else if (property.displayCategory === "Element" && (property.displayName === "IfcDescription" || property.displayName === 'ObjectTypeOverride')) {
                temp.size = displayValue
                temp.totalNumber = 1
              }
            })
          }
          temp.name = !temp.name ? 'Unknown' : temp.name
          dataRebarTable.push(temp)
        }
        count--;
        if (count === 0) {
          setLoading(false)
          setData(dataRebarTable)
          let models = props.viewer.impl.modelQueue().getModels()
          _.forEach(models, model => {
            props.viewer.clearThemingColors(model)
          })
          let temp = {}
          _.forEach(dataRebarTable, v => {
            let displayName = v['name']
            if (!displayName) return
            if (!temp[displayName])
              temp[displayName] = []
            temp[displayName].push(v)
          })
          tempItem = []
          _.forEach(temp, (v, k) => {
            let temp = {}
            temp.text = k
            temp.item = v
            temp.color = ''
            temp.open = true
            temp.icon = generateNodeIcon(temp)
            temp.label = generateNodeLabel(temp)
            temp.secondaryLabel = generateNodeSecondLabel(temp)
            tempItem.push(temp)
          })
          setLoading(false)
          setDataFilter(tempItem)

        }
      });
    });
  }
  const checkParentForTekla = (viewer, id) => {
    let instanceTree = viewer.model.getData().instanceTree;
    let temp = []
    let queue = [];
    queue.push(id);
    while (queue.length > 0) {
      let node = queue.shift();
      let idChild = instanceTree.getNodeParentId(node)
      if (idChild) {
        queue.push(idChild);
        temp.push(idChild)
      }
      else {
        return temp[1]
      }
    }
  }

  const handelChangeFilter = (e) => {
    let models = props.viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      props.viewer.clearThemingColors(model)
    })
    let temp = {}
    _.forEach(data, v => {
      let displayName = v[e.target.value]
      if (!displayName) return
      if (!temp[displayName])
        temp[displayName] = []
      temp[displayName].push(v)
    })
    tempItem = []
    _.forEach(temp, (v, k) => {
      let temp = {}
      temp.text = k
      temp.item = v
      temp.color = ''
      temp.open = true
      temp.icon = generateNodeIcon(temp)
      temp.label = generateNodeLabel(temp)
      temp.secondaryLabel = generateNodeSecondLabel(temp)
      tempItem.push(temp)
    })
    setDataFilter(tempItem)
  }
  const handleSelectObjects = (node) => {
    let temp = []
    _.forEach(node.item, v => {
      temp.push(v.id)
    })
    props.viewer.select(temp);
  }
  const handleShowHideObjects = (node) => {
    node.open = !node.open
    node.icon = generateNodeIcon(node)
    _.forEach(node.item, v => {
      if (node.open)
        props.viewer.show(v.id, props.viewer.impl.model)
      else {
        props.viewer.hide(v.id, props.viewer.impl.model)
      }

    })
    setDataFilter(tempItem.slice(0))
  }
  const generateNodeIcon = (node) => {
    return (
      <ButtonGroup  >
        <IconButton size="xs" onClick={handleSelectObjects.bind(this, node)} icon={
          <Icon icon={'mouse-pointer'} />} />
        <IconButton size="xs" color={!node.open && 'red'} onClick={handleShowHideObjects.bind(this, node)} icon={
          <Icon icon={node.open ? 'eye' : 'eye-slash'} />} />
      </ButtonGroup>
    )
  }
  const generateNodeLabel = (node) => {
    return (
      <>
        <IconButton size="xs" onClick={handleChangeColorMode.bind(this, node)} style={{ backgroundColor: node.color !== '' && node.color }}
          icon={
            node.color === "" ?
              <IconAntd component={Iconsvg} style={{ top: 4, left: 4, position: 'absolute' }} /> :
              <Icon />} />
        <Text strong >  {' '}  {node.text} </Text>
      </>
    )
  }
  const generateNodeSecondLabel = (node) => {
    let totalNumber = 0
    let totalWeight = 0
    _.forEach(node.item, v => {
      totalNumber = v.totalNumber + totalNumber
      if (node.text !== 'COUPLER')
        totalWeight = v.totalWeight + totalWeight
    })


    return (
      <div style={{ width: 200 }}>
        <Row gutter={2}>
          <Col span={12}>{`${totalNumber} nos`}</Col>
          <Col span={12}>{node.text !== 'COUPLER' ? `${totalWeight} kgs` : ' '}</Col>

        </Row>
      </div>
    )
  }
  const handleChangeColorMode = (node, e) => {
    $("#color-picker-rebar-filter").css('top', e.clientY - 53.78)
    $("#color-picker-rebar-filter").css('left', e.clientX - 40)
    setTimeout(() => {
      $("#color-picker-rebar-filter").click();
      nodeColor = node
    }, 1);

  }
  const handleResetColor = () => {
    let models = props.viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      props.viewer.clearThemingColors(model)
    })
    _.forEach(dataFilter, v => {
      v.color = ''
      v.label = generateNodeLabel(v)
    })
    setDataFilter(dataFilter.slice(0))
  }
  const handleRandomColor = () => {
    _.forEach(dataFilter, v => {
      v.color = getRandomColor();
      v.label = generateNodeLabel(v)
      let color = convertHexColorToVector4(v.color)
      _.forEach(v.item, i => {
        props.viewer.setThemingColor(i.id, color, props.viewer.impl.model, true);
      })
    })
    setDataFilter(dataFilter.slice(0))
  }
  const handleCloseDock = () => {
    props.viewer.showAll()
    let models = props.viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      props.viewer.clearThemingColors(model)
    })
    props.onChangeDockDisplay('rebarFilterDock', false)
  }
  const handleShowAll = () => {
    props.viewer.showAll();
    _.forEach(tempItem, v => {
      v.open = true
      v.icon = generateNodeIcon(v)
    })
    setDataFilter(tempItem.slice(0))
  }
  const handleDoubleGroupRebar = (node) => {
    _.forEach(dataFilter, v => {
      if (v.text === node.text) return
      v.open = false
      v.icon = generateNodeIcon(v)
    })
    node.open = true
    node.icon = generateNodeIcon(node)
    let temp = []
    _.forEach(node.item, v => {
      temp.push(v.id)
    })
    setDataFilter(tempItem.slice(0))
    props.viewer.isolate(temp, props.viewer.impl.model)
    props.viewer.fitToView(temp)
  }
  return (
    <Draggable
      axis="both" bounds='body'
      handle=".custom-dock-panel-title"
      defaultPosition={{ x: 40, y: 30 }}
      scale={1}
    >
      <div className='custom-forge-dock' style={{ display: props.openDock ? 'inline' : 'none', height: 400, width: 450, backgroundColor: '#ffffff00' }}
        id='custom-rebar-filter-dock' >
        <div className='custom-dock-panel-title'>Group Filter</div>
        <div className='custom-dock-panel-close' onClick={handleCloseDock}>x</div>
        <div className='custom-dock-panel-body' style={{ backgroundColor: '#ffffff00' }}>
          <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
            <Scrollbars
              renderView={renderView}
              autoHide autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}>
              {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
              <ControlGroup fill={true} vertical={false}>
                <div class="bp3-select .modifier">
                  <select onChange={handelChangeFilter} >
                    <option selected value="name">Type</option>
                    <option value="size">Size</option>
                    <option value="shape">Shape</option>
                  </select>
                </div>
                <Tooltip title='Colorize'>
                  <BlueprintButton icon={
                    <Icon icon='paint-brush' />
                  } onClick={handleRandomColor} />
                </Tooltip>
                <Tooltip title='Reset Color'>
                  <BlueprintButton icon='reset' onClick={handleResetColor} />
                </Tooltip>
                <Tooltip title='Show All'>
                  <BlueprintButton icon='eye-on' onClick={handleShowAll} />
                </Tooltip>

              </ControlGroup>

              <Tree
                contents={dataFilter}
                onNodeDoubleClick={handleDoubleGroupRebar}
              />
            </Scrollbars>

          </div>
        </div>
        <div className='custom-dock-panel-footer' />
      </div>
    </Draggable>


  );
}



export default RebarFilterDock