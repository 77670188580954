import { message } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colorType, imgLink } from '../../../constants';
import PanelCalendar from '../generalComponents/PanelCalendar';
import { isColumnWithReplyData } from './CellRFA';
import { getCompanyNameFnc, getTradeNameFnc, getTreeFlattenOfNodeInArray } from './FormDrawingTypeOrder';




const Cell = (props) => {

   let {
      cellData, rowData, column, columns, rowIndex, columnIndex,
      onRightClickCell, stateCell, getCellModifiedTemp, setCellActive,
      stateRow, getSheetRows, stateProject,

      setPosition, getCurrentDOMCell
   } = props;


   const { cellActive, cellsModifiedTemp, cellAppliedAction, tempCopiedText } = stateCell;
   const { drawingTypeTree, rowsAll, modeGroup, rowsSelected, rowsSelectedToMove, modeFilter, rowsFileAll } = stateRow;

   let columnKeyToPutFolderName = (rowData.treeLevel || rowData._rowLevel < 1) && columns[1].key === column.key && column.key;


   if (
      (column.key.includes('(A)') || column.key.includes('(T)') || column.key === 'Construction Issuance Date' || column.key === 'Construction Start') &&
      cellData && cellData.length === 10 && cellData.includes('-')
   ) {
      cellData = moment(cellData, 'YYYY-MM-DD').format('DD/MM/YY');
   };

   const { roleTradeCompany, projectIsAppliedRfaView, company, pageSheetTypeName } = stateProject.allDataOneSheet;


   let info = '';
   if (rowData.treeLevel && column.key === columnKeyToPutFolderName) {
      const node = drawingTypeTree.find(x => x.id === rowData.id);
      if (node) {
         const branches = getTreeFlattenOfNodeInArray(drawingTypeTree, node);
         const branchesWithDrawing = branches.filter(x => !branches.find(y => y.parentId === x.id));

         let rowsArr = [];
         branchesWithDrawing.forEach(brch => {
            rowsArr = [...rowsArr, ...rowsAll.filter(r => r._parentRow === brch.id)];
         });
         modeFilter.forEach(filter => {
            if (filter.id) {
               rowsArr = rowsArr.filter(r => r[filter.header] === filter.value);
            };
         });
         let obj = {};
         rowsArr.forEach(row => {
            if (!row['Status'] || row['Status'] === 'INFO') {
               obj['Not Started'] = (obj['Not Started'] || 0) + 1;
            } else {
               obj[row['Status']] = (obj[row['Status']] || 0) + 1;
            }
         });

         let str = '';
         Object.keys(obj).forEach((stt, i) => {
            let code;
            let init = i === 0 ? '' : ' + ';

            if (stt === 'Not Started') code = 'NS';
            if (stt === '1st cut of model in-progress') code = 'MIP';
            if (stt === '1st cut of drawing in-progress') code = 'DIP';
            if (stt === 'Pending design') code = 'PD';
            if (stt === 'Consultant reviewing') code = 'CR';
            if (stt === 'Reject and resubmit') code = 'RR';
            if (stt === 'Approved with comments, to Resubmit') code = 'AR';
            if (stt === 'Revise In-Progress') code = 'RP';
            if (stt === 'Approved with Comment, no submission Required') code = 'AC';
            if (stt === 'Approved for Construction') code = 'AP';
            if (stt === 'INFO') code = 'NS';

            str += `${init}${obj[stt]} ${code}`;

         });
         let end = rowsArr.length === 0 ? '' : ' : ';
         info = ` - (${rowsArr.length} Drawings${end}${str})`;
      };

   };


   const isLockedColumn = columnLocked(roleTradeCompany, rowData, modeGroup, column.key, projectIsAppliedRfaView, pageSheetTypeName);
   const isLockedRow = rowLocked(roleTradeCompany, rowData, modeGroup, drawingTypeTree);

   const inputRef = useRef();
   const panelRef = useRef();
   const buttonRef = useRef();
   const cellRef = useRef();

   const [valueInput, setValueInput] = useState({ current: cellData || '', init: cellData || '' });

   const [btnShown, setBtnShown] = useState(false);
   const [panelData, setPanelData] = useState(false);


   const [inputRender, setInputRender] = useState(false);

   const cellDataTypeBtn = checkCellDataFormat(column.key);

   const isActive = cellActive && cellActive.rowIndex === rowIndex && cellActive.columnIndex === columnIndex;



   const cellEditDone = (value) => {
      if (!rowData.treeLevel) {

         let cellDrgTypeFormatArrText = [];
         if (column.key === 'Drg Type') {
            const parentNode = drawingTypeTree.find(x => x.id === rowData._parentRow);
            if (parentNode) {
               const tradeName = getTradeNameFnc(parentNode, drawingTypeTree);
               if (tradeName.includes('(SUBCON)')) {
                  cellDrgTypeFormatArrText = ['Other'];
               } else {
                  cellDrgTypeFormatArrText = cellDrgTypeFormat[tradeName];
               };
            };
         };

         if (
            (cellDataTypeBtn === 'cell-type-date' && !(moment(value, 'DD/MM/YY').format('DD/MM/YY') === value) && value !== '') ||
            (column.key === 'Status' && columnStatusValueArray.indexOf(value) === -1 && value !== '') ||
            (column.key === 'Use For' && cellUseForFormat.indexOf(value) === -1 && value !== '') ||
            ((column.key === 'Model Progress' || column.key === 'Drawing Progress') && cellProgressFormatData.indexOf(value) === -1 && value !== '') ||
            (column.key === 'Drg Type' && cellDrgTypeFormatArrText.indexOf(value) === -1 && value !== '')
         ) {

            setValueInput({ ...valueInput, current: valueInput.init });
            message.info('Data input should be in correct format', 1);

         } else {

            setValueInput({ ...valueInput, current: value }); // must-have => delete or key in emtry string...

            getCellModifiedTemp({ [getCellTempId(rowData, column.key)]: value });

            if (pageSheetTypeName === 'page-spreadsheet') {

               let row = rowsAll.find(r => r.id === rowData.id);
               row[column.key] = value;
               getSheetRows({ rowsAll });

            } else if (pageSheetTypeName === 'page-authorities-submission') {
               let row = rowsFileAll.find(r => r.id === rowData.id);
               row[column.key] = value;
               getSheetRows({ rowsFileAll });
            };

         };
      };
   };



   const onClick = () => {
      if (rowsSelected.length > 0 || rowsSelectedToMove.length > 0) {
         getSheetRows({ rowsSelected: [], rowsSelectedToMove: [] });
      };
      if (isLockedColumn || isLockedRow) return;

      setBtnShown(true);
      if (!inputRender) { // single click just highlight cell, not activate
         setPosition({ cell: cellRef.current.parentElement, rowIndex, columnIndex });
      };
   };
   const onDoubleClick = () => {

      if ((isLockedColumn || isLockedRow) && pageSheetTypeName === 'page-spreadsheet') return;

      setInputRender(true);
      setBtnShown(false);
      getCurrentDOMCell();
   };
   const onMouseLeave = () => {
      if (btnShown) setBtnShown(false);
   };
   const onMouseDown = (e) => {
      if (pageSheetTypeName === 'page-spreadsheet') {
         if (e.button === 2) { // check mouse RIGHT CLICK ...
            onRightClickCell(e, props);
         } else {
            if (isLockedColumn || isLockedRow) return;
         };
      };
   };




   const onChange = (e) => {
      setValueInput({ ...valueInput, current: e.target.value });
   };
   const onBlur = () => {

      let isDuplicatedValue = false;
      const headerName = column['key'];

      if (headerName === 'Drawing Number' || headerName === 'Drawing Name') {
         rowsAll
            .filter(r => r['id'] !== rowData['id'])
            .forEach(row => {
               const valueThisHeader = row[headerName] || '';
               if (valueThisHeader.trim() !== '' && valueThisHeader.trim() === valueInput.current.trim()) {
                  isDuplicatedValue = true;
               };
         });
      };

      if (isDuplicatedValue) {
         message.warn(`This ${headerName} has already existed, please choose the new one!`);
         cellEditDone('');
      } else {
         cellEditDone(valueInput.current);
      };


      if (inputRender) setInputRender(false);
      if (btnShown) setBtnShown(false);
      if (panelData) setPanelData(false);
   };
   const onKeyDown = (e) => { // ENTER to hide input after finishing typing ...
      if (
         e.key === 'Enter' &&
         isActive && inputRender &&
         !isLockedColumn && !isLockedRow
      ) {
         inputRef.current.blur();
         setCellActive(null);
      };
   };





   useEffect(() => {
      document.addEventListener('click', EventClickToHidePanelAndInput);
      return () => document.removeEventListener('click', EventClickToHidePanelAndInput);
   }, []);
   const EventClickToHidePanelAndInput = (e) => {
      if (!buttonRef.current && panelRef.current) {
         setPanelData(false);
      };
   };

   useEffect(() => { // after keydown ENTER to show input ...
      if (!inputRender && isActive && !isLockedColumn && !isLockedRow) {
         setInputRender(true);
      };
   }, [cellActive]);

   useEffect(() => { // FOCUS right after press ENTER...
      if (inputRender) {
         inputRef.current.focus();
      };
   }, [inputRender]);

   useEffect(() => { // Hide Button after pick on PANEL (setBtnShown fasle in pickDataSelect doesn't work)
      if (btnShown) {
         setBtnShown(false);
      };
   }, [valueInput]);

   useEffect(() => {
      if (
         !inputRender &&
         cellAppliedAction &&
         cellAppliedAction.currentDOMCell.rowIndex === rowIndex &&
         cellAppliedAction.currentDOMCell.columnIndex === columnIndex &&
         !isLockedColumn && !isLockedRow
      ) {
         const { e } = cellAppliedAction;
         if (e.key === 'Delete') {
            cellEditDone('');
         } else if (e.key === 'v' && e.ctrlKey) {
            cellEditDone(tempCopiedText);
         };
      };
   }, [cellAppliedAction]);






   const pickDataSelect = (type, value) => {
      setBtnShown(false);
      setPanelData(false);
      setInputRender(false);
      if (type === 'text') {
         cellEditDone(value);
      } else if (type === 'date') {
         cellEditDone(moment(value).format('DD/MM/YY'));
      } else if (type === 'div') {
         cellEditDone(value);
      };
   };



   const columnStatusValueArray = projectIsAppliedRfaView ? [
      'Not Started',
      '1st cut of model in-progress',
      '1st cut of drawing in-progress',
      'Pending design',
   ] : [
      'Not Started',
      '1st cut of model in-progress',
      '1st cut of drawing in-progress',
      'Pending design',


      'Consultant reviewing',
      'Reject and resubmit',
      'Approved with comments, to Resubmit',
      'Revise In-Progress',
      'Approved with Comment, no submission Required',
      'Approved for Construction',
   ];


   return (
      <div
         ref={cellRef}
         onClick={onClick}
         onDoubleClick={onDoubleClick}
         onMouseLeave={onMouseLeave}
         onMouseDown={onMouseDown}
         style={{
            width: '100%', height: '100%', paddingLeft: 5, paddingTop: 5, color: 'black',
            position: 'relative'
         }}
      >
         {inputRender ? (
            <input
               value={valueInput.current}
               onChange={onChange}
               onBlur={onBlur}
               onKeyDown={onKeyDown}
               ref={inputRef}
               style={{
                  width: '100%', height: '100%',
                  padding: 0, paddingRight: 30, marginTop: -5,
                  outline: 'none', border: 'none',
                  background: 'transparent'
               }}
            />

         ) : (
            <div
               style={{
                  textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                  overflow: (column.key === columnKeyToPutFolderName && rowData.treeLevel) ? 'visible' : 'hidden',
                  marginRight: 30,
               }}
            >{
                  (columnKeyToPutFolderName && (
                     <>
                        <span style={{ fontWeight: 'bold' }}>{rowData.title}</span>
                        <span>{info}</span>
                     </>
                  )) ||
                  ((column.key === 'Model Progress' || column.key === 'Drawing Progress') && <BtnProgress type={cellData} />) ||
                  cellsModifiedTemp[getCellTempId(rowData, column.key)] ||
                  cellData
               }</div>
         )}



         {btnShown && columnWithButton(column.key) && (
            <div
               style={{
                  cursor: 'pointer', position: 'absolute',
                  right: 4, top: 5, height: 17, width: 17,
                  backgroundSize: 17,
                  backgroundImage: cellDataTypeBtn === 'cell-type-date' ? `url(${imgLink.btnDate})`
                     : (cellDataTypeBtn === 'cell-type-dropdown' || cellDataTypeBtn === 'cell-type-progress') ? `url(${imgLink.btnText})`
                        : null
               }}
               onMouseDown={(e) => {
                  e.stopPropagation();
                  setPanelData(!panelData);
               }}
               ref={buttonRef}
            />
         )}

         {panelData && (
            <div style={{
               position: 'absolute', background: 'white', top: 30, left: 0, zIndex: 999,
               padding: '3px 5px 3px 7px',
               boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
               minWidth: column.width,
               maxHeight: 400, overflowY: 'scroll',
            }}
               ref={panelRef}
            >
               {cellDataTypeBtn === 'cell-type-date' ? (
                  <PanelCalendar pickDate={(item) => pickDataSelect('date', item)} />

               ) : cellDataTypeBtn === 'cell-type-progress' ? ['Quarter', 'Half', 'Third Quarter', 'Full'].map(item => (
                  <SelectStyled key={item}>
                     <BtnProgress
                        type={item}
                        onMouseDown={(e) => {
                           e.stopPropagation();
                           pickDataSelect('div', item);
                        }}
                     />
                  </SelectStyled>

               )) : getColumnsValue(rowsAll, column.key, { rowData, drawingTypeTree }, columnStatusValueArray).map(item => {
                  return (
                     <SelectStyled
                        key={item}
                        onMouseDown={(e) => {
                           e.stopPropagation();
                           pickDataSelect('text', item);
                        }}
                     >{item}</SelectStyled>
                  );
               })}
            </div>
         )}
      </div>

   );
};

export default Cell;



const SelectStyled = styled.div`
   padding: 4px;
   &:hover {
      background-color: ${colorType.grey4};
      cursor: pointer;
   };
   transition: 0.2s;
`;


const getCellTempId = (rowData, header) => `${rowData['id']}~#&&#~${header}`;


const BtnProgress = ({ type, onMouseDown }) => {
   const img = type === 'Empty' ? imgLink.btnProgress0 :
      type === 'Quarter' ? imgLink.btnProgress1 :
         type === 'Half' ? imgLink.btnProgress2 :
            type === 'Third Quarter' ? imgLink.btnProgress3 :
               type === 'Full' ? imgLink.btnProgress4 :
                  null;
   return (
      <div style={{ display: 'flex', textAlign: 'center', width: '100%' }} onMouseDown={onMouseDown}>
         <div style={{
            cursor: 'pointer',
            height: 20,
            width: 20,
            backgroundImage: `url(${img})`,
            backgroundSize: 20,
            padding: 0
         }}
         />
      </div>
   );
};

const cellProgressFormatData = [
   'Empty', 'Quarter', 'Half', 'Third Quarter', 'Full'
];




const checkCellDataFormat = (header) => {
   if (header.includes('(A)') || header.includes('(T)') || header === 'Construction Issuance Date' || header === 'Construction Start') {
      return 'cell-type-date';
   } else if (header === 'Index' || header === 'Drawing Number' || header === 'Drawing Name') {
      return 'cell-type-none';
   } else if (header === 'Drawing Progress' || header === 'Model Progress') {
      return 'cell-type-progress';
   } else {
      return 'cell-type-dropdown';
   };
};


const columnWithButton = (headerId) => {
   return headerId !== 'Index' && headerId !== 'Drawing Number' && headerId !== 'Drawing Name';
};

const getColumnsValue = (rows, headerKey, drgTypeCheckData, columnStatusValueArray) => {

   if (headerKey === 'Status') return columnStatusValueArray;
   if (headerKey === 'Use For') return cellUseForFormat;


   if (headerKey === 'Drg Type') {
      const { rowData, drawingTypeTree } = drgTypeCheckData;
      const parentNode = drawingTypeTree.find(x => x.id === rowData._parentRow);
      const tradeName = getTradeNameFnc(parentNode, drawingTypeTree);

      if (tradeName.includes('(SUBCON)')) {
         return ['Other'];
      } else {
         return cellDrgTypeFormat[tradeName];
      };
   };

   let valueArr = [];
   rows.filter(r => r._rowLevel === 1).forEach(row => {
      valueArr.push(row[headerKey]);
   });
   valueArr = [...new Set(valueArr)].filter(e => e);
   valueArr.sort((a, b) => a > b ? 1 : (b > a ? -1 : 0));

   return valueArr;
};




const cellUseForFormat = [
   'Coordination',
   'Issue for Construction',
   'Request for Approval',
   'Request for Confirmation',
];

const cellDrgTypeFormat = {
   'ARCHI': [
      'Key plan',
      'Wall setting out',
      'Column wall setting out',
      'Tile layout & detail',
      'Reflected celing plan',
      'Finishing layout',
      'Door layout',
      'Core layout & detail',
      'Toilet',
      'Edeck layout & detail',
      'Staircase layout & detail',
      'Surface drain',
      'Lift lobby/ corridor',
      'Material schedule',
      'Other'
   ],
   'C&S': [
      'CBP',
      'Piling layout',
      'Rebar shop drawing',
      'Temporary work'
   ],
   'M&E': [
      'CSD',
      'Penetration drawing',
      'M&E trade shop drawing'
   ],
   'PRECAST': [
      'Precast layout',
      'Precast detail',
      'Precast shop drawing'
   ],
};

const columnsLockedModeller = [
   'Model Start (T)',
   'Model Finish (T)',
   'Drawing Start (T)',
   'Drawing Finish (T)',
   'Drg To Consultant (T)',
   'Consultant Reply (T)',
   'Get Approval (T)',
   'Construction Issuance Date',
   'Construction Start',
];



export const columnLocked = (roleTradeCompany, rowData, modeGroup, column, projectIsAppliedRfaView, pageSheetTypeName) => {
   if (
      (projectIsAppliedRfaView && pageSheetTypeName === 'page-spreadsheet' && (
         column === 'RFA Ref' ||
         column === 'Drg To Consultant (A)' ||
         column === 'Rev' ||
         column === 'Consultant Reply (T)' ||
         column === 'Consultant Reply (A)' ||
         column === 'Issue For Construction' ||
         column === 'IFC Rev' ||
         column === 'IFC Date'
      )) ||
      (isColumnWithReplyData(column)) ||
      (rowData && !rowData._rowLevel) || // lock drawing type ...
      (modeGroup && modeGroup.length > 0) ||
      (roleTradeCompany.role === 'Modeller' && columnsLockedModeller.includes(column)) ||
      (roleTradeCompany.role === 'View-Only User') ||
      (roleTradeCompany.role === 'Production' && column !== 'Construction Start')
   ) {
      return true;
   } else {
      return false;
   };
};
export const rowLocked = (roleTradeCompany, rowData, modeGroup, drawingTypeTree) => {
   if (!rowData._rowLevel || rowData._rowLevel < 1) return true;

   if (modeGroup.length > 0) return true;

   if (roleTradeCompany.role === 'Document Controller') return false;

   const drawingTypeTreeClone = drawingTypeTree.map(x => ({ ...x }));
   const dwgType = drawingTypeTreeClone.find(x => x.id === rowData._parentRow);
   let companyName;
   if (dwgType.treeLevel >= 1) {
      companyName = getCompanyNameFnc(dwgType, drawingTypeTreeClone);
   };

   if (roleTradeCompany.role === 'Production' && companyName === 'Woh Hup Private Ltd') return false;

   let tradeName;
   if (companyName === 'Woh Hup Private Ltd' && dwgType.treeLevel >= 2) {
      tradeName = getTradeNameFnc(dwgType, drawingTypeTreeClone);
      return companyName !== roleTradeCompany.company || tradeName !== roleTradeCompany.trade;
   } else {
      return companyName !== roleTradeCompany.company;
   };
};



const getExtraInfoForRowHeader = (rowsAll, drawingTypeTree, node, modeFilter) => {
   const branches = getTreeFlattenOfNodeInArray(drawingTypeTree, node);
   const branchesWithDrawing = branches.filter(x => !branches.find(y => y.parentId === x.id));

   let rowsArr = [];

   branchesWithDrawing.forEach(brch => {
      rowsArr = [...rowsArr, ...rowsAll.filter(r => r._parentRow === brch.id)];
   });
   modeFilter.forEach(filter => {
      if (filter.id) {
         rowsArr = rowsArr.filter(r => r[filter.header] === filter.value);
      };
   });
   let obj = {};
   rowsArr.forEach(row => {
      if (!row['Status'] || row['Status'] === 'INFO') {
         obj['Not Started'] = (obj['Not Started'] || 0) + 1;
      } else {
         obj[row['Status']] = (obj[row['Status']] || 0) + 1;
      }
   });

   let str = '';
   Object.keys(obj).forEach((stt, i) => {
      let code;
      let init = i === 0 ? '' : ' + ';

      if (stt === 'Not Started') code = 'NS';
      if (stt === '1st cut of model in-progress') code = 'MIP';
      if (stt === '1st cut of drawing in-progress') code = 'DIP';
      if (stt === 'Pending design') code = 'PD';
      if (stt === 'Consultant reviewing') code = 'CR';
      if (stt === 'Reject and resubmit') code = 'RR';
      if (stt === 'Approved with comments, to Resubmit') code = 'AR';
      if (stt === 'Revise In-Progress') code = 'RP';
      if (stt === 'Approved with Comment, no submission Required') code = 'AC';
      if (stt === 'Approved for Construction') code = 'AP';
      if (stt === 'INFO') code = 'NS';

      str += `${init}${obj[stt]} ${code}`;

   });
   let end = rowsArr.length === 0 ? '' : ' : ';
   return ` - (${rowsArr.length} Drawings${end}${str})`;
};
