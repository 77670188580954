import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, IconButton, Modal, SelectPicker, Button, Input, ButtonGroup } from 'rsuite';
import { Typography, Icon as IconAntd, Tooltip } from 'antd';
import $ from 'jquery';

import _ from 'lodash'
import axios from "axios";
// import '../../scss/SideBar.scss'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import { Tree } from "@blueprintjs/core";
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction'
import {
  unloadForgeExtension, getForgeToken, showErrorLoadDocument, setupForgeExtensionBeforeLoaded,

} from '../../../components/module_BimApp/function/ForgeFunction'
import { createProjectDataTable } from '../../../components/module_BimApp/function/ExcelFunction'
import { Scrollbars } from 'react-custom-scrollbars';
import {Iconsvg} from '../../../image'
const { Text, Title } = Typography
const Autodesk = window.Autodesk;


let ext = ''
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}

class TreeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openPanel: false,
      data: [],
      viewCurrent: null,
      document: null,
      isModelLoaded: false,
      modelLoaded: null,
      testData: [
        {
          id: props.projectId,
          hasCaret: true,
          icon: "diagram-tree",
          label: props.projectName,
          type: 'all'
        }
      ],
      viewer: null,
      nodeInformation: null
    }


  }
  componentWillMount = () => {
    // this.props.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, e => {
    //   this.checkModelLoaded(e)
    // })
    // this.state.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, e => {
    //   this.checkModelLoaded(e)
    // })
  }

  checkModelLoaded = (e) => {
    if(!e.model.myData) return
    if (e.model.myData.loadOptions.itemId === this.props.itemId) {
      if (e.model.is2d() && ext === 'rvt') {
        this.props.onDisplayPanelChange('treeViewPanel', false)
        this.props.onDisabledBtnChange('btnTreeview', true)
      } else if (e.model.is3d()) {
        this.props.onDisabledBtnChange('btnTreeview', false)
        let models = this.state.viewer.impl.modelQueue().getModels()
        let nodes = this.getAllChildNode(this.state.testData[0].childNodes)
        _.forEach(nodes, (node, k) => {
          if (node.itemId !== this.props.itemId) {
            node.open = false
          }
          node.color = ""
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        })
      }
    } else {
      let models = this.state.viewer.impl.modelQueue().getModels()
      let nodes = this.getAllChildNode(this.state.testData[0].childNodes)
      _.forEach(nodes, (node, k) => {
        let index = _.findIndex(models, e => { return e.myData.loadOptions.modelNameOverride === node.text })
        if (index >= 0) {
          node.open = true
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        } else {
          node.open = false
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        }
      })
    }
  }
  getAllChildNode(root, check = true) {
    var temp = [];
    var queue = [];
    _.forEach(root, item => {
      queue.push(item);
    })
    while (queue.length > 0) {
      var node = queue.shift();
      if (node.childNodes) {
        let childNode = []
        _.forEach(node.childNodes, (v, k) => {
          childNode.push(v)
        })
        queue = queue.concat(childNode)
      } else {
        if (check) {
          if (node.type === 'item')
            temp.push(node);
        } else {
          if (node.type === 'item' || node.type === 'no support')
            temp.push(node);
        }

      }
    }
    return temp
  }

  componentDidMount = () => {
    $("#color-picker-model").change(e => {
      let color = convertHexColorToVector4(e.target.value)
      let node = this.state.nodeColor
      let models = this.state.viewer.impl.modelQueue().getModels()
      _.forEach(models, v => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          this.state.viewer.setThemingColor(v.getRootId(), color, v, true);
          node.color = e.target.value
          node.label = this.generateNodeLabel(node)
          return false;
        }
      })
      this.setState({ testData: this.state.testData })
    })
  }
  //#region //! load model
  handleOpenView = (node) => {
    if (this.state.viewer === null) {
      this.props.handleShow3DView(true)
      getForgeToken().then(token => {
        var options = {
          env: 'AutodeskProduction2',
          api: 'streamingV2',
          accessToken: token.access_token,
        };
        Autodesk.Viewing.Initializer(options, () => {
          var config3d = {
            loaderExtensions: { svf: "Autodesk.MemoryLimited" },
            extensions: [
              "Autodesk.AEC.LevelsExtension",
              "Autodesk.AEC.Minimap3DExtension",
              "Autodesk.AEC.Hypermodeling",
            ],
          };
          let viewerLocal = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d);
          viewerLocal.start();
          this.setState({ viewer: viewerLocal }, () => {
            node.status = "pending"
            node.isMain = true
            node.icon = this.generateNodeIcon(node)
            this.setState({ isModelLoaded: false, testData: this.state.testData }, () => {
              Autodesk.Viewing.Document.load('urn:' + node.objectId, this.onDocumentLoadSuccess.bind(this, this.state.viewer, node), this.onDocumentLoadFailure.bind(this, node));
            })
          })
        });
      })
      return
    }
    if (node.status === "pending") {
      if (node.isMain) {
        this.state.viewer.tearDown()
        this.state.viewer.finish()
        this.props.handleShow3DView(false)
        let nodes = this.getAllChildNode(this.state.testData[0].childNodes)
        _.forEach(nodes, (node, k) => {
          if (node.type === 'no translate') return
          node.type = 'item'
          node.open = false
          node.color = ""
          node.isMain = false
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        })
        ext = ''
        this.setState({ testData: this.state.testData, viewer: null })
        return
      }
      const models = this.state.viewer.impl.modelQueue().getModels();
      _.forEach(models, (v, k) => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          //this.state.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, true))
          this.state.viewer.unloadModel(v)
          this.uploadNodeModel(node, true)
          return false;
        }
      })

    } else if (node.open) {
      if (node.isMain) {
        this.state.viewer.tearDown()
        this.state.viewer.finish()
        this.props.handleShow3DView(false)
        let nodes = this.getAllChildNode(this.state.testData[0].childNodes, false)
        _.forEach(nodes, (node, k) => {
          if (node.type === 'no translate') return
          node.type = 'item'
          node.open = false
          node.color = ""
          node.isMain = false
          node.icon = this.generateNodeIcon(node)
          node.label = this.generateNodeLabel(node)
        })
        ext = ''
        this.setState({ testData: this.state.testData, viewer: null })
        return
      }
      node.status = "pending"
      node.icon = this.generateNodeIcon(node)
      this.setState({ isModelLoaded: true, testData: this.state.testData, viewer: null }, () => {
        let objectId = node.objectId;
        Autodesk.Viewing.Document.load('urn:' + objectId,
          this._onDocumentLoadSuccess.bind(this, node),
          this._onDocumentLoadFailure.bind(this, node));
      })
    }
    else if (!node.open) {
      node.status = "pending"
      node.icon = this.generateNodeIcon(node)
      this.setState({ isModelLoaded: false, testData: this.state.testData }, () => {
        var objectId = node.objectId;
        Autodesk.Viewing.Document.load('urn:' + objectId, this._onDocumentLoadSuccess.bind(this, node),
          this._onDocumentLoadFailure.bind(this, node));
      })
    }
  }
  onDocumentLoadSuccess = (viewer, node, doc) => {
    try {
      doc.downloadAecModelData()
      let viewables = doc.getRoot().getDefaultGeometry();
      let loadOptions = {
        applyRefPoint: true,
        modelNameOverride: node.text,
        isAEC: true,
        itemId: node.itemId,
        guid: viewables.data.guid,
        viewableID: viewables.data.viewableID,
        version: node.version,
      };
      viewer.loadDocumentNode(doc, viewables, loadOptions).then(i => {
        unloadForgeExtension(viewer)
        setupForgeExtensionBeforeLoaded(viewer, this)
        let split = node.text.split('.')
        ext = split[split.length - 1].toLowerCase()
        let nodes = this.getAllChildNode(this.state.testData[0].childNodes, false)
        _.forEach(nodes, (node, k) => {
          if (node.type === 'no translate') return
          let split = node.text.split('.')
          let extension = split[split.length - 1].toLowerCase()
          node.type = extension === ext ? 'item' : 'no support'
          node.label = this.generateNodeLabel(node)
          node.icon = this.generateNodeIcon(node)
          node.secondaryLabel = this.generateNodeSecondLabel(node)
        })
        node.status = ""
        node.open = true
        node.icon = this.generateNodeIcon(node)
        this.setState({ loading: false, testData: this.state.testData })
      });
    } catch { }
  }
  onDocumentLoadFailure = (node, viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
    this.uploadNodeModel(node, false)
    this.props.handleShow3DView(false)
    this.setState({ viewer: null })
  }
  uploadNodeModel = (node, check, svfUrl, loadOptions) => {
    //this.state.viewer.removeEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this))
    if (check) {
      node.status = ""
      node.open = false
      node.color = ""
      node.icon = this.generateNodeIcon(node)
      this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
    } else {
      node.status = "pending"
      node.color = ""
      node.icon = this.generateNodeIcon(node)
      this.state.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, node), this._onLoadModelError.bind(this, node))
      this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
    }
  }

  _onDocumentLoadFailure = (node, viewerErrorCode) => {
    node.open = false
    node.status = ""
    node.isMain = false
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  }
  _onDocumentLoadSuccess = (node, doc) => {
    let temp = []
    let models = this.state.viewer.impl.modelQueue().getModels()
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    _.forEach(view3d, v => {
      if (v.data.size >= 0)
        temp.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v })
    })
    if (temp.length === 1) {
      if (node.open) {
        _.forEach(models, (v, k) => {
          if (v.myData.loadOptions.modelNameOverride === node.text) {
            // this.state.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, true))
            this.state.viewer.unloadModel(v)
            this.uploadNodeModel(node, true)
            return false;
          }
        })
      } else {
        let svfUrl = doc.getViewablePath(temp[0].obj);
        let loadOptions = {
          globalOffset: this.state.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: node.text,
          isAEC: true,
          guid: temp[0].obj.data.guid,
          viewableID: temp[0].obj.data.viewableID,
          itemId: node.itemId,
          version: node.version,
          acmSessionId: doc.acmSessionId
        };
        this.state.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, node), this._onLoadModelError.bind(this, node))
        this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false })
      }
    } else {
      if (node.open) {
        _.forEach(models, v => {
          if (v.myData.loadOptions.modelNameOverride === node.text) {
            let guid = v.myData.loadOptions.guid
            this.setState({ data: temp, openPanel: true, document: doc, viewCurrent: guid, modelLoaded: v, nodeCurrent: node })
            return false;
          }
        })
      }
      else
        this.setState({ data: temp, openPanel: true, document: doc, nodeCurrent: node })
    }
  };
  _onLoadModelSuccess = (node, modelCurrent) => {
    node.status = ""
    node.open = true
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  };
  _onLoadModelError = (node, viewerErrorCode) => {
    node.status = ""
    node.icon = this.generateNodeIcon(node)
    this.setState({ loading: false, testData: this.state.testData })
  }
  //#endregion


  handleClosePanel = (data, check) => {
    if (data === null) {
      let node = this.state.nodeCurrent
      node.status = ""
      node.icon = (
        <ButtonGroup >
          <IconButton size="xs" onClick={this.handleOpenView.bind(this, node)} color={node.open && "blue"} icon={
            <Icon icon={node.status === "pending" ? "spinner" : !node.open ? "eye-slash" : "eye"} spin={node.status === 'pending' ? true : false} />} />
        </ButtonGroup>
      )
      this.setState({ openPanel: false, testData: this.state.testData })
    } else if (!check) {
      this.state.viewer.unloadModel(data.model)
      this.uploadNodeModel(data.node, true)
      // this.props.listModel.splice(data.key, 1);
    } else if (check) {
      if (data.node.open) {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.state.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId
        };
        //this.state.viewer.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, this.uploadNodeModel.bind(this, node, false))
        this.state.viewer.unloadModel(this.state.modelLoaded)
        this.uploadNodeModel(data.node, false, svfUrl, loadOptions)
      } else {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.state.viewer.impl.model.myData.globalOffset,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId
        };
        this.state.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess.bind(this, data.node), this._onLoadModelError.bind(this, data.node))
        this.setState({ openPanel: false, viewCurrent: null, modelLoaded: null, loading: false, testData: this.state.testData })
      }
    }
  }


  //#region tree

  handleNodeCollapse = (node, e) => {
    node.isExpanded = false;
    this.setState({ testData: this.state.testData })
  }
  handleChangeColorMode = (node, e) => {
    $("#color-picker-model").css('top', e.clientY - 53.78)
    $("#color-picker-model").css('left', e.clientX - 40)
    setTimeout(() => {
      $("#color-picker-model").click();
      this.setState({ nodeColor: node })
    }, 1);

  }
  handelClearColorModel = (e) => {
    let node = e.props.node
    let models = this.state.viewer.impl.modelQueue().getModels()
    _.forEach(models, v => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        node.color = "";
        node.label = this.generateNodeLabel(node)
        this.state.viewer.clearThemingColors(v)
        return false;
      }
    })
    this.setState({ testData: this.state.testData })
  }
  handleNodeExpand = (node, e) => {
    if (!node.isLoaded) {
      node.secondaryLabel = (
        <Icon icon="spinner" spin />
      )
      node.disabled = true
      this.setState({ testData: this.state.testData })
      axios.get("/api/versions/project-tree", { params: { id: node.id, type: node.type, role: this.props.userRoleCurrent, token: this.props.userInfo.token } })
        .then(res => {
          res.data.sort(this.sortLabel)
          _.forEach(res.data, v => {
            if (v.type === "item" && ext !== '') {
              let split = v.text.split('.')
              let extension = split[split.length - 1].toLowerCase()
              v.type = extension === 'fbx' ? 'item' : extension === ext ? 'item' : 'no support'
            }
            if (v.type === "project") {
              v.label = this.generateNodeLabel(v)
            } else if (v.type === "item") {
              v.status = ""
              v.color = ""
              v.open = false
              v.label = this.generateNodeLabel(v)
              v.secondaryLabel = this.generateNodeSecondLabel(v)
              v.icon = this.generateNodeIcon(v)
            } else if (v.type === 'no translate') {
              v.label = this.generateNodeLabel(v)
              v.icon = this.generateNodeIcon(v)
              v.secondaryLabel = this.generateNodeSecondLabel(v)
            } else if (v.type === 'no support') {
              v.label = this.generateNodeLabel(v)
              v.icon = this.generateNodeIcon(v)
              v.secondaryLabel = this.generateNodeSecondLabel(v)
            }
          })
          node.secondaryLabel = (<Icon icon="refresh" />)
          node.childNodes = res.data
          node.isExpanded = true;
          node.disabled = false
          node.isLoaded = true
          this.setState({ testData: this.state.testData }, () => {
            // let models = this.state.viewer.impl.modelQueue().getModels()
            // let nodes = this.getAllChildNode(this.state.testData[0].childNodes)
            // _.forEach(nodes, (node, k) => {
            //   let index = _.findIndex(models, e => { return e.myData.loadOptions.modelNameOverride === node.text })
            //   if (index >= 0) {
            //     node.open = true
            //     node.icon = this.generateNodeIcon(node)
            //     node.label = this.generateNodeLabel(node)
            //   } else {
            //     node.open = false
            //     node.icon = this.generateNodeIcon(node)
            //     node.label = this.generateNodeLabel(node)
            //   }
            // })
            // this.setState({ testData: this.state.testData })
          })
        })
        .catch(err => {
          node.disabled = false
          node.secondaryLabel = ''
        })
    } else {
      node.isExpanded = true;
      this.setState({ testData: this.state.testData })
    }
  }
  generateNodeIcon = (node) => {
    return (
      node.type === 'item' ?
        <ButtonGroup  >
          <IconButton size="xs" onClick={this.handleOpenView.bind(this, node)} color={node.isMain ? 'orange' : node.open && "blue"} disabled={node.itemId === this.props.itemId} icon={
            <Icon icon={node.status === "pending" ? "spinner" : !node.open ? "eye-slash" : "eye"} spin={node.status === 'pending' ? true : false} />} />
        </ButtonGroup>
        :
        <ButtonGroup  >
          <IconButton size="xs" icon={<Icon icon={"lock"} />} disabled={true} />
        </ButtonGroup>
    )
  }
  generateNodeLabel = (node) => {
    return (
      node.type === 'project' ?
        <MenuProvider id="treeview_project_menu" animation={animation.zoom} data={{ node: node }}>
          <Text  >  {' '}  {node.text} </Text>
        </MenuProvider>
        :
        (node.type === 'item' ? <MenuProvider id="treeview_menu" animation={animation.zoom} data={{ node: node }}>
          <IconButton size="xs" onClick={this.handleChangeColorMode.bind(this, node)} style={{ backgroundColor: node.color !== '' && node.color }}
            icon={
              node.color === "" ?
                <IconAntd component={Iconsvg} style={{ top: 4, left: 4, position: 'absolute' }} /> :
                <Icon />} />
          <Text  >  {' '}  {node.text} </Text>
        </MenuProvider>
          :
          <>
            <IconButton size="xs" disabled={true}
              icon={<Icon icon={"lock"} />} />
            <Text style={{ color: node.type === 'no translate' && "orange" }} >  {' '}  {node.text} </Text>
          </>)
    )
  }
  generateNodeSecondLabel = (node) => {
    return (
      <Tooltip title={node.text} placement='top'>
        <Text strong>V{node.version}</Text>
      </Tooltip>
    )
  }
  sortLabel = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
  //#endregion
  handleInformation = (e) => {
    let nodeParent = e.props.node
    // if (nodeParent.type === 'project' || nodeParent.type === 'item') {
    //   this.setState({ nodeInformation: nodeParent })
    // }
    // window.alert(`Id: ${nodeParent.type === 'project' ? nodeParent.id : nodeParent.itemId}`)
    let data = [[nodeParent.type === 'project' ? 'Project' : 'File', nodeParent.text, nodeParent.type === 'project' ? nodeParent.id : nodeParent.itemId]]
    let nodes = this.getAllChildNode(nodeParent.childNodes, false)
    _.forEach(nodes, (node, k) => {
      if (node.type === 'no translate') return
      let split = node.text.split('.')
      let extension = split[split.length - 1].toLowerCase()
      if (extension.toLowerCase() === 'rvt') {
        data.push([node.type === 'project' ? 'Project' : 'File', node.text, node.type === 'project' ? node.id : node.itemId])
      }
    })
    if(data.length!==0)
    createProjectDataTable(data)
  }
  render() {
    return (
      <>

        <div style={{ padding: '0px 0px 0px 0px', width: '100%', height: '100%' }}>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            <Tree
              contents={this.state.testData}
              onNodeCollapse={this.handleNodeCollapse}
              onNodeExpand={this.handleNodeExpand}
            // className={Classes.ELEVATION_0}
            />
          </Scrollbars>
        </div>


        <Menu id='treeview_project_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handleInformation}>
            <IconFont className="fas fa-info" />Create Project Table
                </Item>
        </Menu>

        <Menu id='treeview_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handelClearColorModel}>
            <IconFont className="fas fa-eraser" />Reset Color
                </Item>
          {/* <Item onClick={this.handleInformation}>
            <IconFont className="fas fa-info" />Get Id
                </Item> */}
        </Menu>


        {this.state.openPanel &&
          <SelectViewPanel
            open={this.state.openPanel}
            close={this.handleClosePanel}
            data={this.state.data}
            viewCurrent={this.state.viewCurrent}
            viewer={this.state.viewer}
            isModelLoaded={this.state.isModelLoaded}
            nodeCurrent={this.state.nodeCurrent}
            modelLoaded={this.state.modelLoaded}
          />}
        {this.state.nodeInformation &&
          <Modal show={true} onHide={() => { this.setState({ nodeInformation: null }) }} size='xs' overflow={true} backdrop="static">
            <Modal.Header>
              <Modal.Title>ID</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <Input value={this.state.nodeInformation.type === 'project' ? this.state.nodeInformation.id : this.state.nodeInformation.itemId} />
            </Modal.Body>

          </Modal>}
      </>
    );
  }
}
TreeView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(TreeView)


function SelectViewPanel(props) {

  const [viewableSelected, setViewableSelected] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    props.close(null)
  }
  const handleChangeView = (value, e) => {
    _.forEach(props.data, v => {
      if (v.value === value) {
        setViewableSelected(v.obj)
        return false
      }
    })
  }
  const handleUnloadModel = () => {
    setLoading(true)
    let models = props.viewer.impl.modelQueue().getModels()
    let node = props.nodeCurrent
    _.forEach(models, (v, k) => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        props.close({ key: k, node: props.nodeCurrent, model: v }, false)
        setLoading(false)
        return false;
      }
    })
  }
  const handleViewable = () => {
    props.close({ viewableSelected: viewableSelected, node: props.nodeCurrent, model: props.modelLoaded }, true)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='xs' overflow={true} backdrop="static">
      <Modal.Header>
        <Modal.Title>List Viewables</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <SelectPicker
          data={props.data}
          style={{ width: '100%' }}
          defaultValue={props.viewCurrent}
          groupBy="group"
          placeholder="Select View"
          cleanable={false}
          onChange={handleChangeView}
          renderMenuItem={(label, item) => {
            return (
              <div>
                <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
              </div>
            );
          }}
          renderValue={(value, item) => {
            return (
              <div>
                <span style={{ color: '#000000' }}>
                  <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} />{item.group === '3D' ? " View3D" : " Sheet"} :
              </span>{' '}
                {item.label}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {props.isModelLoaded &&
          <Button onClick={handleUnloadModel} color="red" loading={loading}>
            Unload
            </Button>}
        <Button onClick={handleViewable} appearance="primary" loading={loading}
          disabled={viewableSelected === null}>
          Load
            </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
            </Button>
      </Modal.Footer>
    </Modal>
  )

}


