import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { Func_Export_QM_Data } from "../../function/func_export_to_excel";

const TableQMWorks = ({ data }) => {
   var lastMonth = dayjs().subtract("1", "month").format("MMM");
   const [checkHighFail, setCheckHighFail] = useState(true);
   let highFailData = [];
   if (data !== undefined) {
      highFailData = data.filter((e) => e.percent >= 8);
   }

   const [columnDefs] = useState([
      { field: "date", headerName: "Date", width: 110, filter: true, sortable: true },
      { field: "project_code", headerName: "Project", width: 110, filter: true, sortable: true },
      { field: "Foreman", headerName: "Foreman", width: 225, filter: true, sortable: true },
      { field: "WorkType", headerName: "Work Type", width: 250, filter: true, sortable: true },
      { field: "Unit", headerName: "Unit", width: 125, filter: true, sortable: true },
      { field: "Worker", headerName: "Worker", width: 250, filter: true, sortable: true },
      { field: "Activity", headerName: "Activity", width: 275, filter: true, sortable: true },
      { field: "Hours", headerName: "Hours", width: 100, filter: true, sortable: true },
   ]);

   return (
      <div style={{ minHeight: "25vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Data get from Jotform
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_QM_Data(data)}
            >
               <Icon type="download" />
            </Button>
            {/* <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Items {">"} 8%
            </Checkbox> */}
         </h5>
         <div className="ag-theme-alpine" style={{ height: "25vh", width: "100%" }}>
            <AgGridReact rowData={data} columnDefs={columnDefs}></AgGridReact>
         </div>
      </div>
   );
};

export default TableQMWorks;
