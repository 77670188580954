import { USER_LOGGED_IN, USER_LOGGED_OUT } from '../../types';
import api from '../../api';
import { socket } from '../../components/module_BimApp/function/AdminFunction'

export const userLoggedIn = user => ({
  type: USER_LOGGED_IN,
  user
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const login = credentials => dispatch =>
  api.user.login(credentials).then(user => {
    localStorage.bookwormJWT = user.token;
    dispatch(userLoggedIn(user));
  });

  export const loginAzure = credentials => dispatch =>
  api.user.loginAzure(credentials).then(user => {
    localStorage.bookwormJWT = user.token;
    dispatch(userLoggedIn(user));
  });

export const logout = (user) => dispatch => {
  localStorage.removeItem("hello");
  localStorage.removeItem("bookwormJWT");
  localStorage.removeItem("userWh5Dapp");
  dispatch(userLoggedOut());
  socket.emit('leave', user.email);
  // if( window.hello){
  //   try{
  //     window.hello('aad').logout();
  //   }catch{}
  // }
};

export const confirm = token => dispatch =>
  api.user.confirm(token).then(user => {
    localStorage.bookwormJWT = user.token;
    dispatch(userLoggedIn(user));
  });

export const resetPasswordRequest = ({ email }) => () =>
  api.user.resetPasswordRequest(email);

export const validateToken = token => () => api.user.validateToken(token);

export const resetPassword = data => () => api.user.resetPassword(data);