import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox } from 'rsuite';

import { Table, Pagination, Dropdown, Image } from 'semantic-ui-react'
import { Popconfirm } from 'antd';
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,

} from 'react-table'
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Zoom from 'react-medium-image-zoom'
function TableListScheduleSaved({ columns, data,
  getRowProps,
  getCellProps,
  handleDeleteScheduleSaved,
  handleShareScheduleSaved,
  handleOpenScheduleSaved,
  email,
  version,
  loadingBtn
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter,
    }),
    []
  )
  function ListValueFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id, filter },
  }) {
    const [colorIcon, setColorIcon] = React.useState('#bfbfbf');
    const [list, setList] = React.useState(filterValue);
    const data = React.useMemo(() => {
      let dataNeed = []
      let rowValue = []
      preFilteredRows.forEach(row => {
        rowValue.push(row.values[id])
      })
      let intersection = _.uniq(rowValue);
      intersection.sort();
      intersection.forEach(v => {
        dataNeed.push({ label: v, value: v })
      })
      return dataNeed
    }, [id, preFilteredRows])

    const handleFilter = (value) => {
      try {
        let temp = []
        if (value !== null || value !== undefined) {
          value.forEach(v => {
            temp.push(v)
          })
          setList(temp)
        } else {
          setList([])
        }
        setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
        setFilter(temp)

      } catch{ }
    }
    const handleCheckAll = (value, checked) => {
      try {
        let temp = []
        if (checked)
          data.forEach(v => {
            temp.push(v.value)
          })
        setList(temp)
        setColorIcon(temp.length === 0 ? '#bfbfbf' : 'black')
        setFilter(temp)

      } catch{ }

    }
    React.useEffect(() => {
      if (filterValue.length === 2)
        setColorIcon((filterValue[0] !== undefined || filterValue[1] !== undefined) ? 'black' : '#bfbfbf')
      else if (filterValue.length === 0)
        setColorIcon('#bfbfbf')
    });
    return (
      <div>
        <Whisper placement='bottomRight' trigger="click" speaker={
          <Popover >
            <Tooltip title="Hold Ctrl Key to Search or Pick multi-value" placement="bottom">
              <CheckPicker defaultValue={filter === 'includes' ? list : []} data={data} style={{ width: '200px' }} onChange={handleFilter} size="xs"
                renderExtraFooter={() => (
                  <div style={{ padding: '10px 2px', borderTop: '1px solid #e5e5e5' }}>
                    <Checkbox inline
                      indeterminate={(filter === 'includes' ? list : []).length < data.length && (filter === 'includes' ? list : []).length > 0}
                      checked={(filter === 'includes' ? list : []).length === data.length}
                      onChange={handleCheckAll}
                      style={{ color: 'blue' }}>
                      Check all
                        </Checkbox>
                  </div>
                )} />
            </Tooltip>
            <br />
            <Button appearance="link" onClick={() => {
              filterValue = []
              setList([])
              setFilter(undefined)
            }}>Reset</Button>
          </Popover>
        }>
          <Icon icon="filter" style={{ right: 0, top: 0, position: 'absolute', padding: 5, color: colorIcon }} />
        </Whisper>

      </div>
    )
  }


  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },

    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'scheduleImg',
            groupByBoundary: true,
            collapse: true,
            minWidth: 100,
            width: 100,
            maxWidth: 100,
            Header: ({ }) => (
              <Table.HeaderCell>
                Image
            </Table.HeaderCell>
            ),
            Cell: ({ row }) => (
              row.original !== undefined &&
              <Zoom>
                <Image src={row.original.blobUrl} rounded size='small' />
              </Zoom>
            ),
          },
          ...columns,
        ]
      })
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'infoSchedule',
            groupByBoundary: true,
            collapse: true,
            minWidth: 100,
            width: 100,
            maxWidth: 100,
            Header: ({ }) => (
              <Table.HeaderCell>
                Tools
              </Table.HeaderCell>
            ),
            Cell: ({ row }) => (
              row.original !== undefined &&
              <ButtonGroup>
                <Tooltip title="Open Schedule" placement="top">
                  <IconButton size="xs" icon={<Icon icon="file-o" />} color={row.original.version !== version && 'red'}
                    onClick={() => openScheduleSaved(row.original)} disabled={row.original.version !== version}
                  ></IconButton>
                </Tooltip>
                <Tooltip title="Share Schedule" placement="top">
                  <IconButton size="xs" icon={<Icon icon="share" />} color={row.original.share && 'blue'}
                    disabled={email === 'admin@wohhup.com' ? false : row.original.userCreated !== email}
                    onClick={() => shareScheduleSaved(row.original)}  ></IconButton>
                </Tooltip>

                <Popconfirm placement="right" title={'Are you sure to delete this view?'} value={row.original} zIndex={10000}
                  onConfirm={() => deleteScheduleSaved(row.original)} okText="Yes" cancelText="No">
                  <Tooltip title="Delete Schedule" placement="top">
                    <IconButton size="xs" icon={<Icon icon="trash-o" />} loading={loadingBtn}
                      disabled={email === 'admin@wohhup.com' ? false : row.original.userCreated !== email}></IconButton>
                  </Tooltip>
                </Popconfirm>

              </ButtonGroup >
            ),
          },
          ...columns,
        ]
      })

    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      groupBy, pageIndex, pageSize
    }
  } = instance

  const columnCount = columns.length
  const deleteScheduleSaved = (value) => {
    handleDeleteScheduleSaved(value)
  }
  const shareScheduleSaved = (value) => {
    handleShareScheduleSaved(value)
  }
  const openScheduleSaved = (value) => {
    handleOpenScheduleSaved(value)
  }

  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { value }) => {
    setPageSize(value)
  }

  return (
    <div>

      <div style={{ float: 'right' }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />


      </div>


      <div style={{ top: 35, overflow: 'auto', bottom: 0, borderBottomStyle: 'solid', borderTopStyle: 'solid', borderWidth: 'thin', width: '100%' }}>
        <Table celled selectable
          id="tableListScheduleSaved" {...getTableProps()} >
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center',
                    width: column.id === 'infoSchedule' ? 90 : 'auto'
                  }}>
                    {!column.isGrouped && (column.id === 'groupSchedule' && column.toggleGroupBy())}
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{ textAlign: 'center', verticalAlign: 'middle' }} >
                        {cell.isGrouped ? (
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.subRows.length !== 0 &&
                                ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                  (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                              }
                            </span>{' '}
                            {cell.render('Cell')}

                          </>
                        ) : cell.isAggregated ? (

                          cell.render('Aggregated')
                        ) : cell.isRepeatedValue ? null : (
                          cell.render('Cell')

                        )}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>

        </Table>

      </div>

    </div>
  )
}

export default TableListScheduleSaved