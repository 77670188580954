export const cellActiveStyle = {
   background: "#B0C4DE",
   border: "2px solid grey",
};

export const colorType = {
   yellow: "#fff200",
   dark: "#1e272e",
   grey0: "#ecf0f1",
   grey1: "#bdc3c7",
   grey2: "#636e72",
   grey3: "#dfe4ea",
   grey3a: "#c1cbd7",
   grey4: "#f1f2f6",
   red: "#b33939",
   green: "#009432",
   blue: "#0984e3",
   orange: "#cc8e35",
   purple: "#303952",
   tableBorderColor: "#A9A9A9",
   cellHighlighted: "#B0C4DE",
   primary: "#34495e",
   secondary: "#46607a",
   lockedCell: "#FDFFE6",
   greenDark: "#006400",
   orange2: "#ff6600",
   rose: "#e84118",
   black: "black",
   pp: "#9b59b6",
   pp2: "#bf95d0",

   grey9: "#b3cccc",
   hyperlink: "#0645AD",
};

export const dimension = {
   tableBorder: "1px",
   headerHeight: 50,
   innerTableHeight: 800,
   cellIndexWidth: "45px",
   bodyTableRowHeight: 28,
   navBarHeight: "53.78px",
};

export const imgLink = {
   btnText:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnText.png",
   btnDate:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnDate.png",
   btnFileUpload:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnFileUpload.png",
   btnProgress0:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnProgress0.png",
   btnProgress1:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnProgress1.png",
   btnProgress2:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnProgress2.png",
   btnProgress3:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnProgress3.png",
   btnProgress4:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/btnProgress4.png",
   logoWohhup:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/logoWohhup.png",
   imgCheckTrue:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/imgCheckTrue.png",
   imgCheckFalse:
      "https://rawcdn.githack.com/Woh-Hup/Resource/fe0c187755d47b5b513dc227d69cee3acf538b16/image/dms/imgCheckFalse.png",
};

const colorTextCode = {
   yellow: "#fff200",
   dark: "#1e272e",
   grey0: "#ecf0f1",
   grey1: "#bdc3c7",
   grey2: "#636e72",
   grey3: "#dfe4ea",
   grey4: "#f1f2f6",
   red: "#b33939",
   green: "#90EE90",
   greenDark: "#006400",
   blue: "#0984e3",
   orange: "#cc8e35",
   orange2: "#ff6600",
   purple: "#303952",
   rose: "#e84118",
   black: "black",
   pp: "#9b59b6",
   pp2: "#bf95d0",
   grey9: "#b3cccc",
   yellowGold: "#DAA520",
};
export const arrayTextStatusQrQrm = [
   "Reviewed And No Further Comments",
   "Reviewed With Comments No Submission Required",
   "Reviewed With Comments Resubmission Is Required",
   "For Record Only",
];
export const arrayTextStatusRfc = [
   "Approved for Construction",
   "Approved with Comment, no submission Required",
   "Received For Info",
   "Reject and resubmit",
];
export const pieChartColors2 = {
   "Approved, no resubmission required": colorType.greenDark,
   "Approved with comments, no resubmission required": colorType.green,
   "Approved with comments, resubmission required": colorType.orange2,

   "Rejected, to resubmit": colorType.red,
   "Consultant reviewing": colorType.blue,
   "Pending design": colorType.grey9,
   "Revision in progress": colorType.yellow,

   "1st cut of drawing in-progress": colorType.pp2,
   "1st cut of model in-progress": colorType.pp,
   "Not Started": colorType.orange,

   // color resubmit chart
   "Approved in previous version but need resubmit": colorType.orange2,

   "Consultant review and reply": colorType.blue,
   "Create update drawing": colorType.orange,
   "Create update model": colorType.green,
};
export const colorTextRow = {
   Overdue: colorTextCode.red,
   "Due in the next 1 - 3 days": "#cc9900",
   "Due in the next 4 - 14 days": colorTextCode.grey4,

   "Approved with Comment, no submission Required": colorTextCode.green,
   "Approved for Construction": colorTextCode.greenDark,
   "Approved with comments, to Resubmit": colorTextCode.orange2,
   "Reject and resubmit": colorTextCode.red,
   "Revise In-Progress": colorTextCode.yellow,

   "Consultant reviewing": colorTextCode.blue,

   "Not Started": colorTextCode.orange,

   "1st cut of drawing in-progress": colorTextCode.pp2,
   "1st cut of model in-progress": colorTextCode.pp,
   "Pending design": colorTextCode.grey9,

   replied: colorTextCode.green,
   Replied: colorTextCode.green,
   Total: colorTextCode.green,

   "Information / Action": colorTextCode.greenDark,
   "Comments / Approval": colorType.pp2,
   Construction: colorType.pp,
   Record: colorTextCode.green,

   ARCHI: colorTextCode.green,
   "C&S": colorTextCode.greenDark,
   "M&E": colorTextCode.orange,
   PRECAST: colorTextCode.grey4,

   "Project Progress Meeting": colorType.pp2,
   "Technical Meeting": colorType.pp,
   "ICE Meeting": colorTextCode.green,
   "PROJECT PROGRESS MEETING": colorType.pp2,
   "TECHNICAL MEETING": colorType.pp,
   "ICE MEETING": colorTextCode.green,

   "Reviewed And No Further Comments": colorTextCode.greenDark,
   "Reviewed With Comments No Submission Required": colorTextCode.green,
   "Reviewed With Comments Resubmission Is Required": colorTextCode.orange2,
   "For Record Only": colorTextCode.yellowGold,
   "Received For Info": colorTextCode.yellowGold,
};



export const tradeArrayForm = ["ARCHI", "C&S", "M&E", "PRECAST"];
export const tradeArrayMeetingMinutesForm = ["PROJECT PROGRESS MEETING", "TECHNICAL MEETING", "ICE MEETING"];

export const EDIT_DURATION_MIN = window.location.origin.includes("https://idd.wohhup.com")
   ? 15
   : window.location.origin.includes("https://test.bql-app.com")
      ? 5
      : 0.5;

export const versionTextArray = [
   "A",
   "B",
   "C",
   "D",
   "E",
   "F",
   "G",
   "H",
   "I",
   "J",
   "K",
   "L",
   "M",
   "N",
   "O",
   "P",
   "Q",
   "R",
   "S",
   "T",
   "U",
   "V", "W", "X", "Y", "Z"
];







export const disciplineType = [
   { key: 'ARC', name: 'Architectural' },
   { key: 'C&S', name: 'Civil & Structure' },
   { key: 'MEP', name: 'Mechanical / Electrical / Plumbing' },
   { key: 'FAC', name: 'Facade' },
   { key: 'PC', name: 'Precast' },
   { key: 'G', name: 'General' },
   { key: 'SG', name: 'Signage' },

   { key: 'LDS', name: 'Landscape' },
   { key: 'ID', name: 'Interior Design' },
   { key: 'ATH', name: 'Authorities' },
   { key: 'TNC', name: 'Testing & Commissioning' },


   { key: 'PRO', name: 'Project Progress Meeting' },
   { key: 'TEC', name: 'Technical Meeting' },
   { key: 'ICE', name: 'ICE Meeting' },

   { key: 'ERI',name: 'Employers Representative Instruction'},
   { key: 'LR', name: 'Letter' },
   { key: 'SM', name: 'Site Memo' },
];







export const offsetHeight = 99.78;
export const sideBarWidth = 55;
export const navBarDmsHeight = 46;

////////////////////////////////////////////// DASHBOARD CONSTANTS



export const sizeType = {
   xs: 576,
   md: 768,
   lg: 992,
   xl: 1200,
};

export const chartWidth =
   window.innerWidth >= sizeType.xl
      ? (window.innerWidth - 160) / 4
      : window.innerWidth >= sizeType.md
         ? (window.innerWidth - 80) / 2
         : window.innerWidth - 100;

export const colorExcel = {
   primary: "33396F",
   grey: "CCCEDA",
   greyDarker: "BABBC3",
   white: "FFFFFF",

   // https://www.hexcolortool.com/

   "Reject and resubmit": "E52E00",

   "Approved with comments, to Resubmit": "FFA200",
   "Reviewed With Comments Resubmission Is Required": "FFA200",

   "Approved with Comment, no submission Required": "A6FFBB",
   "Reviewed With Comments No Submission Required": "A6FFBB",

   "Approved for Construction": "138B13",
   "Reviewed And No Further Comments": "138B13",

   "For Record Only": "8b7113",
   "Received For Info": "8b7113",

   replied: "138B13",

   Overdue: "ff0000",
   "Due in the next 1 - 3 days": "cc9900",
   // 'Due in the next 4 - 14 days': 'f1f2f6',
};

export const arrayStatusDrawingText = [
   "Approved for Construction",
   "Approved with Comment, no submission Required",
   "Approved with comments, to Resubmit",
   "Reject and resubmit",
   "Consultant reviewing",
];
