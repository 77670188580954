
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Typography } from 'antd'
import axios from "axios";
import { getForgeToken} from '../../../components/module_BimApp/function/ForgeFunction'
import _ from 'lodash'
import { checkWohhupUser} from '../../../components/module_BimApp/function/AdminFunction'
import { Intent, Position, Toaster, ProgressBar } from "@blueprintjs/core";
import ForgeViewer from '../../pages/BimApp/ForgeViewer'
const Autodesk = window.Autodesk;
const { Text, Paragraph } = Typography
let viewerLocal = null
let interval = null
function RfaModelPage(props) {
  const [viewer, setViewer] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isTranslate, setIsTranslate] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)
  const [displayPanel, setDisplayPanel] = useState({
    issuePanel: false
  })
  const [displayDock, setDisplayDock] = useState({
    summaryPPVCDock: false,
    pieChartPPVCDock: false,
    progressDock: false,
    summaryPrecastDock: false,
    pieChartPrecastDock: false,
    progressPrecastDock: false,
    statusInstallationDock: false,
    ppvcInformationDock: false,
  })
  const [data, setData] = useState({
    projectId: '',
    itemId: '',
    issueKey: '',
    userCreatedProject: '',
    objectId: '',
    typeProject: '',
    projectKey: '',
    itemDisplayName: '',
    projectName: '',
    version: 1,
    typeFile: '',
    folderSmartSheetId: '',
    smartSheetId: '',
    manningScheduleId: ''
  })
  const [modelLoaded, setModelLoaded] = useState(true)
  const toaster = useRef()
  useEffect(e => {
    setLoading(true)
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    try {
      let params = window.location.pathname.split('/')
      let itemId = params[params.length - 1]
      axios.post("/api/rfa/get-model", { itemId, token: props.userInfo.token })
        .then(res => {
          let { bucketKey, objectId, projectId, itemId, issueKey, userCreatedProject, typeProject,
            projectKey, itemDisplayName, itemName, projectName, version, typeFile, folderSmartSheetId, smartSheetId, manningScheduleId } = res.data
          setData({
            objectId, projectId, itemId, issueKey, userCreatedProject, typeProject,
            projectKey, itemDisplayName, itemName, projectName, version, typeFile, folderSmartSheetId, smartSheetId, manningScheduleId
          })
          getForgeToken()
            .then(token => {
              axios.get('https://developer.api.autodesk.com/modelderivative/v2/designdata/' + objectId + '/manifest', {
                headers: {
                  'Authorization': `Bearer ${token.access_token}`,
                }
              })
                .then(res => {
                  if (res.data.status === 'success') {
                    axios.post("/api/forge/modelderivative/complete-jobs", { itemId  ,objectName:objectId})
                      .then(res => {
                        setLoading(false)
                        setIsTranslate(true)
                        // launchViewer(objectId);
                      })
                      .catch(err => { })
                  }
                  else {
                    toaster.current.show({
                      timeout: 0,
                      icon: 'swap-horizontal',
                      intent: Intent.NONE,
                      message: 'The translation job still running: ' + res.data.progress + '. Please try again in a moment.',
                    }, "progress")
                  }
                })
                .catch(err => {
                  axios.post("/api/forge/modelderivative/jobs", { itemId, 'bucketKey': bucketKey, 'objectName': objectId })
                    .then(res => {
                      toaster.current.show({
                        timeout: 0,
                        icon: 'swap-horizontal',
                        intent: Intent.NONE,
                        message: 'Starting translation',
                      }, "progress")
                    })
                    .catch(err => {

                    })
                })
            })
        })
        .catch(err => {

        })
    } catch { }
  }, [])




  return (
    <>
      {loading && <div style={{ position: "fixed", height: '100%', width: "100%", zIndex: 200, background: '#dddddd' }}>
        <ProgressBar intent='primary' value={null} />
      </div>}

      {isTranslate && <ForgeViewer
        objectId={data.objectId}
        itemId={data.itemId}
        itemName={data.itemName}
        itemDisplayName={data.itemDisplayName}
        projectId={data.projectId}
        projectName={data.projectName}
        folderName={null}
        version={data.version}
        typeFile={data.typeFile}
        projectKey={data.projectKey}
        issueKey={data.issueKey}
        folderSmartSheetId={data.folderSmartSheetId}
        typeProject={data.typeProject}
        smartSheetId={data.smartSheetId}
        objectKey={data.objectKey}
        userCreatedProject={data.userCreatedProject}
        manningScheduleId={data.manningScheduleId}
        useFunction={{saveView:[], coordination:['issue',], precast:[]}}
        isWohhupUser ={checkWohhupUser(props.userInfo.email)}
        isInternalFunction ={false}
      />}


      <Toaster ref={toaster} position={Position.TOP} canEscapeKeyClear={false} />
    </>
  );
}

RfaModelPage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email,
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(RfaModelPage)