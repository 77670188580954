import axios from "axios";

export const uploadFileByPutLink = async (key, typeFile, typeBucketName, blob) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data2 = await axios({
                method: 'get',
                url: '/api/issue/get-put-url',
                params: {
                    key, typeFile ,typeBucketName
                }
            })
            await axios.put(data2.data, blob, {
                headers: {
                    "Content-Type": typeFile,
                },
            })
            resolve()
        } catch {
            reject()
        }
    });
}
export const updateTableAsync = (api, data) => {
    return new Promise(async resolve => {
        await api.applyTransactionAsync(data, () => {
            resolve()
        });

    })
}