import { GET_SHEET_ROWS, OVERWRITE_ROW_REDUCER } from '../../types';




const stateInit = null;

export default function (state = stateInit, action) {
   const { type, payload } = action;

   switch (type) {

      case GET_SHEET_ROWS:
         return {
            ...state,
            ...payload
         };

      case OVERWRITE_ROW_REDUCER:
         return stateInit;

      default:
         return state;
   };
};


