import axios from 'axios';
import moment from 'moment';
import _ from 'lodash'
import {
    getAllElementdbIdsOneModel
  } from '../../../function/ForgeFunction';


export function getDataModelForChart (nextProps) {
    return new Promise(resolve => {
        let dbIds = getAllElementdbIdsOneModel(nextProps.viewer)
        var listTempCategory = []
        var listTempCategoryMS = []
        var count = dbIds.length;
        dbIds.forEach((modelAdbId) => {
          nextProps.viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
            var tempValueMark = ''
            var tempValueVolume = 0
            var tempValueMarkMS = ''
            var check1 = false; var check2 = false
            for (var i in modelAProperty.properties) {
              if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
                tempValueMark = modelAProperty.properties[i].displayValue
              } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
                tempValueMarkMS = modelAProperty.properties[i].displayValue
              } else if (modelAProperty.properties[i].displayName === 'Volume') {
                tempValueVolume = modelAProperty.properties[i].displayValue
              }
              if (i === (modelAProperty.properties.length - 1).toString()) {
                if (tempValueMark === '' && tempValueMarkMS === '') {
                  if (!listTempCategory['not assign'])
                    listTempCategory['not assign'] = []
                  listTempCategory['not assign'].push({ id: modelAdbId, volume: parseFloat(tempValueVolume.toFixed(2)) })
                }
              }
              if (tempValueMark !== '' && tempValueVolume !== 0 && !check1) {
                if (!listTempCategory[tempValueMark])
                  listTempCategory[tempValueMark] = []
                listTempCategory[tempValueMark].push({ id: modelAdbId, volume: parseFloat(tempValueVolume.toFixed(2)) })
                check1 = true
              }
              if (tempValueVolume !== 0 && tempValueMarkMS !== '' && !check2) {
                if (!listTempCategoryMS[tempValueMarkMS])
                  listTempCategoryMS[tempValueMarkMS] = []
                listTempCategoryMS[tempValueMarkMS].push({ id: modelAdbId, volume: parseFloat(tempValueVolume.toFixed(2)) })
                check2 = true
              }
              if (check1 && check2)
                break
            }
            count--;
            if (count === 0) {
              var listCombine = _.assignIn(listTempCategory, listTempCategoryMS);
              resolve(listCombine)            
            }
          })
        })
    })
}

export async function generateColumnChart( listMark,sheetSaved){
    try{
        return new Promise(resolve => {
            axios.post(`/api/smartsheet/getsheets`,{sheetSavedCurrent: sheetSaved})
            .then(res=>{
                let tempRow=[]
                res.data.forEach(item=>{
                    tempRow = tempRow.concat(item.rows)
                })
                var tempDataSheet =tempRow
                var groups = []
                var groups1 = []
                for(var i =0; i<tempDataSheet.length; i++) {
                    let temp1 ={id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value, 
                        start:tempDataSheet[i].cells[1].value, end:tempDataSheet[i].cells[2].value,
                        nestedGroups: [], parentId: tempDataSheet[i].parentId}
                    let temp2 ={id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value, 
                            start1:tempDataSheet[i].cells[3].value, end1:tempDataSheet[i].cells[4].value,
                            nestedGroups: [], parentId: tempDataSheet[i].parentId}

                    for(var j =0; j<tempDataSheet.length; j++) {
                        if(i!==j){
                            if(tempDataSheet[i].id ===tempDataSheet[j].parentId){
                                temp1.nestedGroups.push(tempDataSheet[j].id)
                            }
                        }
                    }
                    if(temp1.nestedGroups.length ===0) {          
                        if(temp1.parentId === null || temp1.parentId ===undefined){
                           
                        } else{
                            let check = moment(temp1.end); 
                            let month = check.format('MM')
                            let year = check.format('YY')
                            if(!groups[`${month}-${year}`])
                                groups[`${month}-${year}`] =[]
                            groups[`${month}-${year}`].push(temp1)  
                            
                            ////

                            
                            if(temp2.end1 !== null&& temp2.end1 !== undefined){
                                let check = moment(temp2.end1); 
                                let month = check.format('MM')
                                let year = check.format('YY')
                                if(!groups1[`${month}-${year}`])
                                groups1[`${month}-${year}`] =[]
                                groups1[`${month}-${year}`].push(temp2)  
                            }
                        }    
                    }          
                }
                let temp1 = []
                for(var key in groups){
                    let id =[]
                    let volume = 0
                    groups[key].forEach(item =>{          
                        if(listMark[item.content]){
                            listMark[item.content].forEach(item=>{
                                id.push(item.id)
                                volume= volume + item.volume
                            })  
                            // temp1.push()
                        }  
                    })  
                    temp1.push({time: key,id:id,volume:volume})                          
                }

                temp1.sort(compareTime)
                /////////////////
                let temp2 = []
                // eslint-disable-next-line
                for(var key in groups1){ 
                    let id =[]
                    let volume = 0
                    groups1[key].forEach(item =>{          
                        if(listMark[item.content]){
                            listMark[item.content].forEach(item=>{
                                id.push(item.id)
                                volume= volume + item.volume
                            })  
                            // temp2.push()
                        }  
                    })  
                    temp2.push({time: key,id:id,volume:volume})                          
                }
                temp2.sort(compareTime)

                var tempObj1 = _.mapKeys(temp1, function(value, key) {                   
                      return value.time ;
                });
                var tempObj2 = _.mapKeys(temp2, function(value, key) {                   
                      return value.time ;
                });
                
                var combine= _.concat(temp1, temp2)
                var keys= _.mapKeys(combine, function(value, key) {                   
                      return value.time ;
                });
                keys= _.union(_.keys(keys))
                var listNeed=[]
                keys.forEach(key=>{
                    let item1 = null
                    let item2 = null
                    if(tempObj1[key])
                        item1= tempObj1[key]
                    if(tempObj2[key])
                        item2= tempObj2[key]
                    if(item1 !==null && item2 !==null)
                        listNeed.push({time: key,id1:item1.id, volume1: item1.volume, id2: item2.id, volume2: item2.volume})
                    else if(item1 !==null && item2 ===null)
                        listNeed.push({time: key,id1:item1.id, volume1: item1.volume, id2: [], volume2: 0})
                    else if(item1 ===null && item2 !==null)
                        listNeed.push({time: key,id1:[], volume1:0, id2: item2.id, volume2: item2.volume})
                })
              
                ///
                var id =[]
                var volume = 0
                listMark['not assign'].forEach(item=>{
                    id.push(item.id)
                    volume= volume + item.volume
                })  
                listNeed.push({time: 'not assign',id1:id, volume1:volume, id2: id, volume2: volume})
                resolve(listNeed)
            })
        
            .catch(err=>{})
        })
    }
    catch{} 
}

function compareTime( a, b ) {
    var tempA = moment(a.time,'MM-YY').valueOf()
    var tempB = moment(b.time,'MM-YY').valueOf()
    if ( tempA < tempB ){
      return -1;
    }
    if ( tempA > tempB ){
      return 1;
    }
    return 0;
  }