import React from 'react';
import { Card, Tabs, Result, Button } from 'antd';
import LevelAndZone from './Tabs/TabLevelZone';
import BlockAndUnit from './Tabs/TabBlockUnit';
import CostCode from './Tabs/TabCostCode';
import WorkingTime from './Tabs/TabWorkingTime';
import Supervisor from './Tabs/TabSupervisor';
import Worker from './Tabs/TabWorker';
const { TabPane } = Tabs;

class SetupWorker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className=' h-100'>
        {this.props.permissionType === 1 ? (
          <div className='card-box' style={{ width: '100%', height: '100%' , overflow: 'scroll' }}>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='Worker list' key='1'>
                <Worker permissionType={this.props.permissionType} />
              </TabPane>
              <TabPane tab='Supervisor list' key='2'>
                <Supervisor />
              </TabPane>
              <TabPane tab='Cost code' key='3'>
                <CostCode />
              </TabPane>
              <TabPane tab='Block-Level-Zone' key='4'>
                <LevelAndZone />
              </TabPane>

              <TabPane tab='Unit' key='5'>
                <BlockAndUnit />
              </TabPane>

              <TabPane tab='Work Time' key='6'>
                <WorkingTime />
              </TabPane>
            </Tabs>
            ,
          </div>
        ) : (
          <Result
            status='403'
            title='403'
            subTitle='Sorry, you are not authorized to access this page.'
            extra={
              <Button type='primary' href='/'>
                Back Home
              </Button>
            }
          />
        )}
      </div>
    );
  }
}

export default SetupWorker;
