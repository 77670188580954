export const constants = {
    dayFormat: 'Do MMM YYYY',
    weekFormat: 'w MMM YYYY',
    monthFormat: 'MMM YYYY',
    yearFormat: 'YYYY',
    day:'Do',
    week:'w',
    month :'MMM',
    year:'YYYY',
    dayMonth:'Do MMM',
    defaultColumns: ['userId','id', 'client', 'lead', 'name', 'status', 'scope', 'updatedBy', 'updatedAt', 'jobTitle', 'department']
}