import React, { useState, useEffect } from "react";

import { getAvatar } from "../../api/employee";

import _ from "lodash";

import { Avatar } from "antd";

function AvatarEmployee(props) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (props.imageId) {
      getImage();
    }
  }, [props.imageId]);

  async function getImage() {
    let res = await getAvatar({ key: props.imageId, time: 60 });
    setImage(res);
  }

  return (
    <div style={{ textAlign: "center" }}>
      {image ? <Avatar shape="square" size={32} src={image} /> : "No Avatar"}
    </div>
  );
}

export default AvatarEmployee;
