import React from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { getRefStringWithVersion } from '../../../utils';
import { columnLocked, rowLocked } from './Cell';
import { getInfoValueFromRefDataForm } from './CellForm';
import { getCompanyNameFnc, getTradeNameFnc } from './FormDrawingTypeOrder';



const PanelFunctionUploadFile = (props) => {

   const { buttonPanelFunction, panelType, stateRow, stateProject } = props;

   const { roleTradeCompany, projectIsAppliedRfaView, pageSheetTypeName } = stateProject.allDataOneSheet;

   const { rowsSelectedToMove, rowsSelected, drawingTypeTree, modeGroup, rfaData } = stateRow;

   const { rowData, column } = panelType.cellProps;


   const listButton = [
      'Delete Files'
   ];


   const onClickBtn = (btn) => {
      buttonPanelFunction(btn);
   };

   return (
      <div
         style={{
            border: `1px solid ${colorType.grey1}`,
            background: 'white',
         }}
      >
         {listButton.map(btn => (
            <Container
               key={btn}
               onMouseDown={() => onClickBtn(btn)}
               style={{  }}
            >
               {btn}
            </Container>

         ))}
      </div>
   );
};

export default PanelFunctionUploadFile;

const Container = styled.div`
   padding: 7px;
   padding-left: 25px;
   
   cursor: pointer;
   color: black;
   &:hover {
      background: ${colorType.grey0};
   };

`;

