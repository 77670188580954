import React from 'react';
import _ from 'lodash'
import $ from 'jquery';
import axios from "axios";
import { WHLogoGif } from '../../../image'
import {
   Button_WH_Main_3D,
   Button_WH_Main_2D,
   Button_WH_DWF,
   MenuContext_WH,
   TransformExtension,
   ElevationExtension
} from '../dock_forge'


import { convertHexColorToVector4 } from '../function/TableFunction'

const THREE = window.THREE;
const Autodesk = window.Autodesk

//#region general
export function getAllChildTreeNode(root) {
   var temp = [];
   var queue = [];
   _.forEach(root, item => {
      queue.push(item);
   })
   while (queue.length > 0) {
      var node = queue.shift();
      if (node.childNodes) {
         let childNode = []
         _.forEach(node.childNodes, (v, k) => {
            childNode.push(v)
         })
         queue = queue.concat(childNode)
      } else {
         if (node.type === 'item')
            temp.push(node);
      }
   }
   return temp
}
export function getElementdbIdsFromModel(model) {
   try {
      var instanceTree = model.getData().instanceTree;
      var temp = [];
      if (!instanceTree) {
         return temp;
      }
      var queue = [];
      queue.push(instanceTree.getRootId());
      while (queue.length > 0) {
         var node = queue.shift();
         if (instanceTree.getChildCount(node) !== 0) {
            instanceTree.enumNodeChildren(node, function (childrenIds) {
               queue.push(childrenIds);
            });
         }
         else {
            temp.push(node);
         }
      }
      return temp;
   } catch { }

}
export function getIndividualIdOneModel(viewer, id) {
   try {
      var instanceTree = viewer.impl.model.getData().instanceTree;
      var temp = [];
      if (!instanceTree) {
         return temp;
      }
      var queue = [];
      queue.push(id);
      while (queue.length > 0) {
         var node = queue.shift();
         if (instanceTree.getChildCount(node) !== 0) {
            instanceTree.enumNodeChildren(node, function (childrenIds) {
               queue.push(childrenIds);
            });
         }
         else {
            temp.push(node);
         }
      }
      return temp;
   } catch { }

}
export function getAllElementdbIdsOneModel(viewer) {
   try {
      var instanceTree = viewer.impl.model.getData().instanceTree;
      var temp = [];
      if (!instanceTree) {
         return temp;
      }
      var queue = [];
      queue.push(instanceTree.getRootId());
      while (queue.length > 0) {
         var node = queue.shift();
         if (instanceTree.getChildCount(node) !== 0) {
            instanceTree.enumNodeChildren(node, function (childrenIds) {
               queue.push(childrenIds);
            });
         }
         else {
            temp.push(node);
         }
      }
      return temp;
   } catch { }

}
export function getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, condition) {
   let temp = [];
   if (!instanceTree) {
      return temp;
   }
   let queue = [];
   queue.push(instanceTree.getRootId());
   while (queue.length > 0) {
      var node = queue.shift();
      if (instanceTree.getChildCount(node) !== 0) {
         instanceTree.enumNodeChildren(node, function (childrenIds) {
            queue.push(childrenIds);
         });
      }
      else {
         if (condition === 'all')
            temp.push(node);
         else
            if (viewer.isNodeVisible(node, model))
               temp.push(node);
      }
   }
   return temp;
}
export function getChildNodeOneModel(viewer, array) {
   var instanceTree = viewer.impl.model.getData().instanceTree;
   var temp = [];
   if (!instanceTree) {
      return temp;
   }
   var queue = array;
   while (queue.length > 0) {
      var node = queue.shift();
      if (instanceTree.getChildCount(node) !== 0) {
         instanceTree.enumNodeChildren(node, function (childrenIds) {
            queue.push(childrenIds);
         });
      }
      else {
         temp.push(node);
      }
   }
   return temp;
}
export function getChildNodeMultiModel(model, array) {
   var instanceTree = model.getData().instanceTree;
   var temp = [];
   if (!instanceTree) {
      return temp;
   }
   var queue = array;
   while (queue.length > 0) {
      var node = queue.shift();
      if (instanceTree.getChildCount(node) !== 0) {
         instanceTree.enumNodeChildren(node, function (childrenIds) {
            queue.push(childrenIds);
         });
      }
      else {
         temp.push(node);
      }
   }
   return temp;
}
export function replaceSpinner() {
   var spinners = document.getElementsByClassName("spinner");
   if (spinners.length === 0) return;
   var spinner = spinners[0];
   spinner.classList.remove("spinner");
   spinner.innerHTML = `<img src=${WHLogoGif} style="left: calc(50% - 32px);top: calc(50% - 32px);z-index: 5; position: absolute;"></img>`;
}
export function getForgeToken(callback) {
   return axios.get('/api/forge/oauth/token').then(res => {
      return res.data;
   });
}
export function getForgeTokenInternal(callback) {
   return axios.get('/api/forge/oauth/token-internal').then(res => {
      return res.data;
   });
}
export function refreshForgeToken3Legged(token) {
   return new Promise((resolve, reject) => {
      axios.post('/api/forge/oauth/refresh_token_3leg', { token })
         .then(res => {
            resolve(res.data);
         })
         .catch(err => {
            reject()
         })
   })

}
export const getExternalId = (item) => {
   return new Promise(resolve => {
      let temp = []
      let count = item.selection.length
      _.forEach(item.selection, id => {
         item.model.getProperties(id, (modelAProperty) => {
            temp.push(modelAProperty.externalId)
            count--
            if (count === 0) {
               resolve(temp)
            }
         })
      })
   })
}
export const checkExternalId = (item, model, guid) => {
   return new Promise(resolve => {
      let temp = []
      let count = item.length
      _.forEach(item, id => {
         model.getProperties(id, (modelAProperty) => {
            if (guid.includes(modelAProperty.externalId))
               temp.push(id)
            count--
            if (count === 0) {
               resolve(temp)
            }
         })
      })
   })
}
//#endregion

//#region extension
export function unloadForgeExtension(viewer, check = false) {
   viewer.loadExtension(MenuContext_WH)
   viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, function (e) {
      if (e.extensionId === 'Autodesk.Measure') {
         var measureExtension = viewer.getExtension('Autodesk.Measure')
         measureExtension.setUnits('m')
      }
      else if (e.extensionId === 'Autodesk.Explode') {
         viewer.unloadExtension('Autodesk.Explode')
      } else if (e.extensionId === 'Autodesk.DefaultTools.NavTools') {
         const settingsTools1 = viewer.toolbar.getControl('navTools')
         settingsTools1.removeControl('toolbar-cameraSubmenuTool');
         const settingsTools2 = viewer.toolbar.getControl('modelTools')
         settingsTools2.removeControl('toolbar-explodeTool');

         settingsTools1.removeControl('toolbar-orbitTools');

         settingsTools1.removeControl('toolbar-panTool');

         settingsTools1.removeControl('toolbar-zoomTool');
      } else if (e.extensionId === 'Autodesk.PropertiesManager') {
         if (check) {
            viewer.unloadExtension('Autodesk.PropertiesManager')
         }
      }

   });
}
export function unloadForgeExtension2(viewer, check = false) {
   viewer.loadExtension(MenuContext_WH)
   viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, function (e) {
      if (e.extensionId === 'Autodesk.Measure') {
         var measureExtension = viewer.getExtension('Autodesk.Measure')
         measureExtension.setUnits('m')
      }
      else if (e.extensionId === 'Autodesk.Explode') {
         viewer.unloadExtension('Autodesk.Explode')
      } else if (e.extensionId === 'Autodesk.DefaultTools.NavTools') {
         const settingsTools1 = viewer.toolbar.getControl('navTools')
         settingsTools1.removeControl('toolbar-cameraSubmenuTool');
         const settingsTools2 = viewer.toolbar.getControl('modelTools')
         settingsTools2.removeControl('toolbar-explodeTool');

         settingsTools1.removeControl('toolbar-orbitTools');

         settingsTools1.removeControl('toolbar-panTool');

         settingsTools1.removeControl('toolbar-zoomTool'); 
         settingsTools1.removeControl('toolbar-bimWalkTool');
      }else if (e.extensionId === 'Autodesk.BimWalk') {
         viewer.unloadExtension('Autodesk.BimWalk')
      }

   });
}
export function setupForgeExtensionBeforeLoaded(viewer, _this) {
   viewer.loadExtension(Button_WH_Main_3D).then(e => {
      let btn = $('#btn-docbrowser')
      btn.click(() => {
         let temp = _this.state.openCustomDockForge
         _.forEach(temp, (v, k) => {
            if (k === 'documentDockMain') {
               temp[k] = !temp[k]
            } else {
               temp[k] = temp[k]
            }
         })
         _this.setState({ openCustomDockForge: temp }, () => {
            setActiveDock(btn, _this.state.openCustomDockForge.documentDockMain)
         })
      })

      let btn1 = $('#btn-markup2d')
      btn1.click(() => {
         let temp = _this.state.openCustomDockForge
         _.forEach(temp, (v, k) => {
            if (k === 'markUp2d') {
               temp[k] = !temp[k]
            } else {
               temp[k] = temp[k]
            }
         })
         _this.setState({ openCustomDockForge: temp }, () => {
            setActiveDock(btn1, _this.state.openCustomDockForge.markUp2d)
         })
      })

   })
}
export function setupForgeExtensionBeforeLoadedIssuePage(viewer, cb, value) {
   viewer.loadExtension(Button_WH_Main_3D).then(e => {
      let btn1 = $('#btn-markup2d')
      btn1.click(() => {
         cb(!value)
         setActiveDock(btn1, value)
      })

   })
}
export function setupForgeExtensionBeforeLoadedSub(viewer, _this) {
   viewer.loadExtension(Button_WH_Main_2D).then(e => {
      let btn = $('#btn-docbrowser-sub')
      btn.click(() => {
         let temp = _this.state.openCustomDockForge
         _.forEach(temp, (v, k) => {
            if (k === 'documentDockSub') {
               temp[k] = !temp[k]
            } else {
               temp[k] = temp[k]
            }
         })
         _this.setState({ openCustomDockForge: temp }, () => {
            setActiveDock(btn, _this.state.openCustomDockForge.documentDockSub)
         })
      })

   })
}
function setActiveDock(button, value) {
   if (value) {
      button.removeClass("inactive")
      button.addClass("active")
   }
   else {
      button.removeClass("active")
      button.addClass("inactive")
   }
}
export async function setupForgeExtensionAfterLoaded(viewer, _this, check = false) {
   // viewer.setTheme("light-theme");
   // viewer.loadExtension("Autodesk.DataVisualization")
   //    .then(async (ext) => {
   //       // ext.registerSurfaceShadingColors("CO₂", [0x0f2027, 0x203a43, 0x2c5364]);
   //       const devices = new Autodesk.DataVisualization.Device('1', 0, 0, 0, 'humidity')
   //       const room = new Autodesk.DataVisualization.Room('123', 'test', THREE.Box3(new THREE.Vector3(-1,-1,-1), (new THREE.Vector3(1,1,1))))
   //       const info = new Autodesk.DataVisualization.ModelStructureInfo(viewer.impl.model);
   //       const levelRoomsMap = await info.getLevelRoomsMap();

   //       const shadingData = await info.generateSurfaceShadingData([devices]);
   //       ext.setupSurfaceShading(viewer.impl.model, shadingData);
   //       console.log(levelRoomsMap)
   //    })


   viewer.setGhosting(false)
   viewer.setGroundShadow(false)
   viewer.setReverseZoomDirection(true)
   viewer.loadExtension('Autodesk.Viewing.MemoryLimitedDebug')
   viewer.loadExtension('Autodesk.Snapping')
   // viewer.loadExtension(MenuContext_WH)
   viewer.loadExtension('Autodesk.ViewCubeUi').then(function (item) { item.showTriad(true) });
   viewer.loadExtension('Autodesk.Edit2D')
   viewer.loadExtension(Button_WH_DWF)
   viewer.loadExtension('Autodesk.BoxSelection')
   if (viewer.impl.model.is3d()) viewer.loadExtension('Autodesk.Hyperlink')
   !check && viewer.impl.model.is3d() && viewer.loadExtension(ElevationExtension)
   !check && viewer.loadExtension("Autodesk.VisualClusters")
   // !check && viewer.loadExtension('Autodesk.Viewing.MarkupsGui')
   !check && viewer.loadExtension('Autodesk.Viewing.MarkupsCore')
   // viewer.loadExtension('Autodesk.AEC.SheetSyncExtension', { targetViewer: viewer }) //!note bimwalk trigger
   // // .then(res=>{
   // //    console.log(res)
   // // })
   viewer.loadExtension("Autodesk.AEC.Hypermodeling")
   // viewer.loadExtension('Autodesk.DocumentBrowser')
   if (_this) {
      if (_this.props.userRole === 'admin' || _this.props.permission.roleEngineer) {
         viewer.impl.model.is3d() && viewer.loadExtension(TransformExtension)
      }
   }
   if (viewer.impl.model.is3d()) viewer.setEnvMapBackground(18)
   // if (viewer.impl.model.myData.loadOptions.fileExt !== 'rvt') {
   //    try {
   //       viewer.unloadExtension('Autodesk.AEC.LevelsExtension')
   //    } catch { }
   // }
}
export function showErrorLoadDocument(id, text, viewerErrorCode) {
   var viewerError = document.createElement('div');
   viewerError.id = 'viewer-error';
   viewerError.innerHTML = `<span>${text}</span>`;
   document.getElementById(id).appendChild(viewerError);
   console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
}
//#endregion

//#region function
const ModelUnits = {
   METER: 'm',
   CENTIMETER: 'cm',
   MILLIMETER: 'mm',
   FOOT: 'ft',
   INCH: 'in',
   POINT: 'pt'
};
const UnitScales = {
   [ModelUnits.METER]: 1.0,
   [ModelUnits.CENTIMETER]: 0.01,
   [ModelUnits.MILLIMETER]: 0.001,
   [ModelUnits.FOOT]: 0.3048,
   [ModelUnits.INCH]: 0.0254,
   [ModelUnits.POINT]: 0.0254 / 72 //A typographical point is 1/72 of an international inch
};
export function fixUnitString(unit) {
   unit = unit?.toLowerCase();
   //Why are translators not using standard strings for those?!?!?!?
   switch (unit) {
      case 'meter':
      case 'meters':
      case 'm':
         return ModelUnits.METER;
      case 'foot':
      case 'feet':
      case 'ft':
         return ModelUnits.FOOT;
      case 'feet and inches':
      case 'inch':
      case 'inches':
      case 'in':
         return ModelUnits.INCH;
      case 'centimeter':
      case 'centimeters':
      case 'cm':
         return ModelUnits.CENTIMETER;
      case 'millimeter':
      case 'millimeters':
      case 'mm':
         return ModelUnits.MILLIMETER;
      case 'point':
      case 'points':
      case 'pt':
         return ModelUnits.POINT;
      default:
         return unit;
   }
}
export function convertUnits(fromUnits, toUnits, calibrationFactor, d, type, dpi) {

   fromUnits = fixUnitString(fromUnits);
   toUnits = fixUnitString(toUnits);

   calibrationFactor = calibrationFactor ? calibrationFactor : 1;
   dpi = dpi || 72;

   if (fromUnits === toUnits && calibrationFactor === 1)
      return d;

   const M = ModelUnits;
   const U = UnitScales;

   var toFactor = 1;
   switch (toUnits) {
      case M.MILLIMETER: toFactor = 1 / U[M.MILLIMETER]; break;
      case M.CENTIMETER: toFactor = 1 / U[M.CENTIMETER]; break;
      case M.METER: toFactor = 1; break;
      case M.INCH: toFactor = 1 / U[M.INCH]; break;
      case M.FOOT: toFactor = 1 / U[M.FOOT]; break;
      case "ft-and-fractional-in": toFactor = 1 / U[M.FOOT]; break;
      case "ft-and-decimal-in": toFactor = 1 / U[M.FOOT]; break;
      case "decimal-in": toFactor = 1 / U[M.INCH]; break;
      case "decimal-ft": toFactor = 1 / U[M.FOOT]; break;
      case "fractional-in": toFactor = 1 / U[M.INCH]; break;
      case "m-and-cm": toFactor = 1; break;
      case M.POINT: toFactor = 1 / U[M.INCH] * dpi; break;
   }

   var fromFactor = 1;
   switch (fromUnits) {
      case M.MILLIMETER: fromFactor = U[M.MILLIMETER]; break;
      case M.CENTIMETER: fromFactor = U[M.CENTIMETER]; break;
      case M.METER: fromFactor = U[M.METER]; break;
      case M.INCH: fromFactor = U[M.INCH]; break;
      case M.FOOT: fromFactor = U[M.FOOT]; break;
      case "ft-and-fractional-in": fromFactor = U[M.FOOT]; break;
      case "ft-and-decimal-in": fromFactor = U[M.FOOT]; break;
      case "decimal-in": fromFactor = U[M.INCH]; break;
      case "decimal-ft": fromFactor = U[M.FOOT]; break;
      case "fractional-in": fromFactor = U[M.INCH]; break;
      case "m-and-cm": fromFactor = 1; break;
      case M.POINT: fromFactor = U[M.INCH] / dpi; break;
   }

   if (type === "square") {
      return d ? (d * Math.pow(toFactor * fromFactor * calibrationFactor, 2)) : 0;
   } else if (type === "cube") {
      return d ? (d * Math.pow(toFactor * fromFactor * calibrationFactor, 3)) : 0;
   }
   return d ? (d * toFactor * fromFactor * calibrationFactor) : 0;
}
export function SelectionWindow(viewer, id, cb) {

   var listTempModel = {}
   //Forge Viewer
   var _viewer = viewer;
   //bounding sphere of this model
   var _boundingSphere = null;
   //container DIV of the viewer
   var _container = _viewer.canvas.parentElement;
   //start point of select window
   var _mouseStart = new THREE.Vector3(0, 0, -10);
   //end point of select window
   var _mouseEnd = new THREE.Vector3(0, 0, -10);
   //is selecting window running
   var _running = false;
   //rectangle lines of select window
   var _lineGeom = null;
   var _rectGroup = null;
   //material for rectangle lines of select window
   var _materialLine = null;

   var holdCtrl = false
   //start point of select window
   var tempMouseStart = new THREE.Vector3(0, 0, -10);
   //end point of select window
   var tempMouseEnd = new THREE.Vector3(0, 0, -10);
   //when extension is loaded
   try {
      $(document).bind('keydown', onKeyDown);
      _viewer.impl.invalidate(true);
      init()
   } catch { }

   var _boundingBoxInfo
   function init() {
      var model = _viewer.impl.model;
      _boundingSphere = model.getBoundingBox().getBoundingSphere();


      _boundingBoxInfo = [];
      const models = _viewer.impl.modelQueue().getModels();
      _.forEach(models, (element, i) => {
         listTempModel[element.myData.loadOptions.modelNameOverride] = element
         var fragList = element.getFragmentList();
         //boxes array 
         var boxes = fragList.fragments.boxes;
         //map from frag to dbid
         var fragid2dbid = fragList.fragments.fragId2dbId;

         var index = 0;
         for (var step = 0; step < fragid2dbid.length; step++) {
            index = step * 6;
            var thisBox = new THREE.Box3(
               new THREE.Vector3(boxes[index], boxes[index + 1], boxes[index + 2]),
               new THREE.Vector3(boxes[index + 3], boxes[index + 4], boxes[index + 5])
            );


            _boundingBoxInfo.push({ bbox: thisBox, dbId: fragid2dbid[step], model: element.myData.loadOptions.modelNameOverride });
         }

      });
      _materialLine = new THREE.LineBasicMaterial({
         color: new THREE.Color(0xFF0000),
         transparent: true,
         depthWrite: false,
         depthTest: true,
         linewidth: 10,
         opacity: 1.0
      });
      onKeyUp()
   }

   //when key up
   function onKeyUp() {

      //lock navigation to fix the camera
      _viewer.navigation.setIsLocked(true);

      //start to monitor mouse down
      _container.addEventListener('mousedown', onMouseDown);

      //get current camera
      var canvas = _viewer.canvas;
      var canvasWidth = canvas.clientWidth;
      var canvasHeight = canvas.clientHeight;

      var camera = new THREE.OrthographicCamera(
         0, canvasWidth, 0, canvasHeight, 1, 1000)

      //create overlay scene for selection window
      _viewer.impl.createOverlayScene(
         "testWindow",
         _materialLine, _materialLine, camera
      );

      holdCtrl = false
      return true;
   }

   function unload() {
      //unlock current navigation
      _viewer.navigation.setIsLocked(false);

      //remove mouse events
      _container.removeEventListener('mousedown', onMouseDown);
      _container.removeEventListener('mouseup', onMouseUp);
      _container.removeEventListener('mousemove', onMouseMove);

      _running = false;

      //remove the Overlay Scene
      _viewer.impl.removeOverlayScene(
         "testWindow");

      $(document).unbind('keyup', onKeyUp);
      $(document).unbind('keydown', onKeyDown);
      if (cb !== null && id === 'table')
         cb(false)
      else if (cb !== null && id === 'btn') {
         cb.container.classList.add('inactive')
         cb.container.classList.remove('active')
      }
   }
   function onKeyDown(evt) {
      if (evt.keyCode === 17) {
         if (!evt.repeat)
            holdCtrl = true
      }
      return true;
   }

   function onMouseMove(evt) {

      if (evt.which == 3) {
         return false
      }

      if (_running) {
         let tempMouse = getClientCanvas(evt)
         tempMouseEnd.x = tempMouse.x;
         tempMouseEnd.y = tempMouse.y;
         //get mouse points 
         _mouseEnd.x = evt.clientX;
         _mouseEnd.y = evt.clientY;


         //update rectange lines
         _lineGeom.vertices[1].x = tempMouseStart.x;
         _lineGeom.vertices[1].y = tempMouseEnd.y;
         _lineGeom.vertices[2] = tempMouseEnd.clone();
         _lineGeom.vertices[3].x = tempMouseEnd.x;
         _lineGeom.vertices[3].y = tempMouseStart.y;
         _lineGeom.vertices[4] = _lineGeom.vertices[0];

         _lineGeom.verticesNeedUpdate = true;
         _viewer.impl.invalidate(true, true, true);
         _viewer.impl.sceneUpdated(true)
      }
   }

   function onMouseUp(evt) {
      //var viewport = _viewer.impl.clientToViewport(evt.clientX, evt.clientY); 

      if (evt.which == 3) {
         return false
      }
      if (_running) {

         let tempMouse = getClientCanvas(evt)
         tempMouseEnd.x = tempMouse.x;
         tempMouseEnd.y = tempMouse.y;
         //get mouse points 
         _mouseEnd.x = evt.clientX;
         _mouseEnd.y = evt.clientY;

         //remove the overlay of one time rectangle
         _viewer.impl.removeOverlay("testWindow", _rectGroup);
         _running = false;

         //remove mouse event
         _container.removeEventListener('mouseup', onMouseUp);
         _container.removeEventListener('mousemove', onMouseMove);

         //get box within the area of select window, or partially intersected. 
         var intersect = true
         if (_mouseEnd.x > _mouseStart.x)
            intersect = false

         let tempSelect = []
         var ids = compute({ clientX: _mouseStart.x, clientY: _mouseStart.y },
            { clientX: _mouseEnd.x, clientY: _mouseEnd.y },
            intersect); // true:  partially intersected.  false: inside the area only

         for (var key in listTempModel) {
            if (ids[key] !== undefined)
               tempSelect.push({ model: listTempModel[key], ids: ids[key] })
            else
               tempSelect.push({ model: listTempModel[key], ids: [] })
         }
         if (Object.keys(ids).length !== 0) {
            if (Object.keys(listTempModel).length === 1) {
               var tempIds = _viewer.getSelection().concat(tempSelect[0].ids);
               var uniqIds = _.uniq(tempIds)
               var tempNodeVisible = []
               _.forEach(uniqIds, id => {
                  if (_viewer.isNodeVisible(id, tempSelect[0].model)) {
                     tempNodeVisible.push(id)
                  }
               })
               _viewer.select(tempNodeVisible);
            }

            else {
               var DBids = _viewer.impl.selector.getAggregateSelection()
               if (DBids.length !== 0) {
                  _.forEach(tempSelect, (item, key) => {
                     if (DBids[key] !== undefined) {
                        if (item.model.myData.loadOptions.modelNameOverride === DBids[key].model.myData.loadOptions.modelNameOverride) {
                           _.forEach(DBids[key].selection, id => {
                              item.ids.splice(1, 0, id)
                           })
                        }
                     }

                  })
               }
               var tempNodeVisible = []
               _.forEach(tempSelect, (item, key) => {
                  let tempVisible = []
                  _.forEach(item.ids, (id) => {
                     if (_viewer.isNodeVisible(id, item.model)) {
                        tempVisible.push(id)
                     }
                  })
                  tempNodeVisible.push({ model: item.model, ids: tempVisible })
               })

               _viewer.impl.selector.setAggregateSelection(tempNodeVisible)
            }
         }

      }
      unload()
   }

   function onMouseDown(evt) {

      if (evt.which == 3) {
         return false
      }
      if (!holdCtrl) {
         _viewer.clearSelection();
      }


      //var viewport = _viewer.impl.clientToViewport(evt.clientX, evt.clientY);  

      let tempMouse = getClientCanvas(evt)
      tempMouseStart.x = tempMouse.x;
      tempMouseStart.y = tempMouse.y;

      //get mouse points 
      _mouseStart.x = evt.clientX;
      _mouseStart.y = evt.clientY;
      _running = true;

      //build the rectangle lines of select window
      if (_rectGroup === null) {
         _lineGeom = new THREE.Geometry();

         _lineGeom.vertices.push(
            tempMouseStart.clone(),
            tempMouseStart.clone(),
            tempMouseStart.clone(),
            tempMouseStart.clone(),
            tempMouseStart.clone());

         // add geom to group
         var line_mesh = new THREE.Line(_lineGeom, _materialLine, THREE.LineStrip);

         _rectGroup = new THREE.Group();
         _rectGroup.add(line_mesh);
      }
      else {
         _lineGeom.vertices[0] = tempMouseStart.clone();
         _lineGeom.vertices[1] = tempMouseStart.clone();
         _lineGeom.vertices[2] = tempMouseStart.clone();
         _lineGeom.vertices[3] = tempMouseStart.clone();
         _lineGeom.vertices[4] = tempMouseStart.clone();

         _lineGeom.verticesNeedUpdate = true;
      }

      _viewer.impl.addOverlay("testWindow", _rectGroup);
      _viewer.impl.invalidate(true, true, true);
      _viewer.impl.sceneUpdated(true)
      //start to mornitor the mouse events
      _container.addEventListener('mouseup', onMouseUp);
      _container.addEventListener('mousemove', onMouseMove);
   }

   //prepare the range of select window and filter out those objects
   function compute(pointer1, pointer2, partialSelect) {

      // build 4 rays to project the 4 corners
      // of the selection window

      var xMin = Math.min(pointer1.clientX, pointer2.clientX)
      var xMax = Math.max(pointer1.clientX, pointer2.clientX)

      var yMin = Math.min(pointer1.clientY, pointer2.clientY)
      var yMax = Math.max(pointer1.clientY, pointer2.clientY)

      var ray1 = pointerToRay({
         clientX: xMin,
         clientY: yMin
      })

      var ray2 = pointerToRay({
         clientX: xMax,
         clientY: yMin
      })

      var ray3 = pointerToRay({
         clientX: xMax,
         clientY: yMax
      })

      var ray4 = pointerToRay({
         clientX: xMin,
         clientY: yMax
      })

      // first we compute the top of the pyramid
      var top = new THREE.Vector3(0, 0, 0)

      top.add(ray1.origin)
      top.add(ray2.origin)
      top.add(ray3.origin)
      top.add(ray4.origin)

      top.multiplyScalar(0.25)

      // we use the bounding sphere to determine
      // the height of the pyramid
      var { center, radius } = _boundingSphere

      // compute distance from pyramid top to center
      // of bounding sphere

      var dist = new THREE.Vector3(
         top.x - center.x,
         top.y - center.y,
         top.z - center.z)

      // compute height of the pyramid:
      // to make sure we go far enough,
      // we add the radius of the bounding sphere

      var height = radius + dist.length()

      // compute the length of the side edges

      var angle = ray1.direction.angleTo(
         ray2.direction)

      var length = height / Math.cos(angle * 0.5)

      // compute bottom vertices

      var v1 = new THREE.Vector3(
         ray1.origin.x + ray1.direction.x * length,
         ray1.origin.y + ray1.direction.y * length,
         ray1.origin.z + ray1.direction.z * length)

      var v2 = new THREE.Vector3(
         ray2.origin.x + ray2.direction.x * length,
         ray2.origin.y + ray2.direction.y * length,
         ray2.origin.z + ray2.direction.z * length)

      var v3 = new THREE.Vector3(
         ray3.origin.x + ray3.direction.x * length,
         ray3.origin.y + ray3.direction.y * length,
         ray3.origin.z + ray3.direction.z * length)

      var v4 = new THREE.Vector3(
         ray4.origin.x + ray4.direction.x * length,
         ray4.origin.y + ray4.direction.y * length,
         ray4.origin.z + ray4.direction.z * length)

      // create planes

      var plane1 = new THREE.Plane()
      var plane2 = new THREE.Plane()
      var plane3 = new THREE.Plane()
      var plane4 = new THREE.Plane()
      var plane5 = new THREE.Plane()

      plane1.setFromCoplanarPoints(top, v1, v2)
      plane2.setFromCoplanarPoints(top, v2, v3)
      plane3.setFromCoplanarPoints(top, v3, v4)
      plane4.setFromCoplanarPoints(top, v4, v1)
      plane5.setFromCoplanarPoints(v3, v2, v1)

      var planes = [
         plane1,
         plane2,
         plane3,
         plane4,
         plane5
      ]

      var vertices = [
         v1, v2, v3, v4, top
      ]

      // filter all bounding boxes to determine
      // if inside, outside or intersect

      var result = filterBoundingBoxes(
         planes, vertices, partialSelect)

      // all inside bboxes need to be part of the selection
      let temp = []
      result.inside.map((bboxInfo) => {
         if (!temp[bboxInfo.model])
            temp[bboxInfo.model] = []
         temp[bboxInfo.model].push(bboxInfo.dbId)
      })


      // if partialSelect = true
      // we need to return the intersect bboxes

      if (partialSelect) {
         result.intersect.map((bboxInfo) => {
            if (!temp[bboxInfo.model])
               temp[bboxInfo.model] = []
            temp[bboxInfo.model].push(bboxInfo.dbId)
         })
      }

      return temp
   }

   //rays of the corners of select window
   function pointerToRay(pointer) {

      var camera = _viewer.navigation.getCamera();
      var pointerVector = new THREE.Vector3()
      var rayCaster = new THREE.Raycaster()
      var pointerDir = new THREE.Vector3()
      var domElement = _viewer.canvas

      var rect = domElement.getBoundingClientRect()

      var x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
      var y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1

      if (camera.isPerspective) {

         pointerVector.set(x, y, 0.5)

         pointerVector.unproject(camera)

         rayCaster.set(camera.position,
            pointerVector.sub(
               camera.position).normalize())

      } else {

         pointerVector.set(x, y, -15)

         pointerVector.unproject(camera)

         pointerDir.set(0, 0, -1)

         rayCaster.set(pointerVector,
            pointerDir.transformDirection(
               camera.matrixWorld))
      }

      return rayCaster.ray
   }

   //filter out those objects in the range of select window
   function filterBoundingBoxes(planes, vertices, partialSelect) {

      var intersect = []
      var outside = []
      var inside = []

      var triangles = [
         { a: vertices[0], b: vertices[1], c: vertices[2] },
         { a: vertices[0], b: vertices[2], c: vertices[3] },
         { a: vertices[1], b: vertices[0], c: vertices[4] },
         { a: vertices[2], b: vertices[1], c: vertices[4] },
         { a: vertices[3], b: vertices[2], c: vertices[4] },
         { a: vertices[0], b: vertices[3], c: vertices[4] }
      ]

      for (let bboxInfo of _boundingBoxInfo) {

         // if bounding box inside, then we can be sure
         // the mesh is inside too

         if (containsBox(planes, bboxInfo.bbox)) {
            inside.push(bboxInfo)
         } else if (partialSelect) {

            //reconstructed by using AABBCollision lib.
            if (boxIntersectVertex(bboxInfo.bbox, triangles))
               intersect.push(bboxInfo)
            else
               outside.push(bboxInfo)

         } else {
            outside.push(bboxInfo)
         }
      }

      return {
         intersect,
         outside,
         inside
      }
   }


   //get those boxes which are included in the
   //range of select window
   function containsBox(planes, box) {

      var { min, max } = box

      var vertices = [
         new THREE.Vector3(min.x, min.y, min.z),
         new THREE.Vector3(min.x, min.y, max.z),
         new THREE.Vector3(min.x, max.y, max.z),
         new THREE.Vector3(max.x, max.y, max.z),
         new THREE.Vector3(max.x, max.y, min.z),
         new THREE.Vector3(max.x, min.y, min.z),
         new THREE.Vector3(min.x, max.y, min.z),
         new THREE.Vector3(max.x, min.y, max.z)
      ]

      for (let vertex of vertices) {

         for (let plane of planes) {

            if (plane.distanceToPoint(vertex) < 0) {

               return false
            }
         }
      }

      return true
   }

   //get those boxes which are initersected with the
   //range of select window (triangles)
   function boxIntersectVertex(box, triangles) {
      for (var index in triangles) {
         var t = triangles[index];
         if (isIntersectionTriangleAABB(t.a, t.b, t.c, box))
            return true;
      }
      return false;
   }

   function getClientCanvas(evt) {
      // const context = _container.getContext('2d');
      // The x and y offset of the canvas from the edge of the page
      const rect = _container.getBoundingClientRect();
      var X = evt.clientX - rect.x;
      var Y = evt.clientY - rect.y;
      return ({ x: X, y: Y })
   }

   function isIntersectionTriangleAABB(a, b, c, aabb) {

      var p0, p1, p2, r;

      // Compute box center and extents of AABoundingBox (if not already given in that format)
      var center = new THREE.Vector3().addVectors(aabb.max, aabb.min).multiplyScalar(0.5),
         extents = new THREE.Vector3().subVectors(aabb.max, center);

      // Translate triangle as conceptually moving AABB to origin
      var v0 = new THREE.Vector3().subVectors(a, center),
         v1 = new THREE.Vector3().subVectors(b, center),
         v2 = new THREE.Vector3().subVectors(c, center);

      // Compute edge vectors for triangle
      var f0 = new THREE.Vector3().subVectors(v1, v0),
         f1 = new THREE.Vector3().subVectors(v2, v1),
         f2 = new THREE.Vector3().subVectors(v0, v2);

      // Test axes a00..a22 (category 3)
      var a00 = new THREE.Vector3(0, -f0.z, f0.y),
         a01 = new THREE.Vector3(0, -f1.z, f1.y),
         a02 = new THREE.Vector3(0, -f2.z, f2.y),
         a10 = new THREE.Vector3(f0.z, 0, -f0.x),
         a11 = new THREE.Vector3(f1.z, 0, -f1.x),
         a12 = new THREE.Vector3(f2.z, 0, -f2.x),
         a20 = new THREE.Vector3(-f0.y, f0.x, 0),
         a21 = new THREE.Vector3(-f1.y, f1.x, 0),
         a22 = new THREE.Vector3(-f2.y, f2.x, 0);

      // Test axis a00
      p0 = v0.dot(a00);
      p1 = v1.dot(a00);
      p2 = v2.dot(a00);
      r = extents.y * Math.abs(f0.z) + extents.z * Math.abs(f0.y);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a01
      p0 = v0.dot(a01);
      p1 = v1.dot(a01);
      p2 = v2.dot(a01);
      r = extents.y * Math.abs(f1.z) + extents.z * Math.abs(f1.y);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a02
      p0 = v0.dot(a02);
      p1 = v1.dot(a02);
      p2 = v2.dot(a02);
      r = extents.y * Math.abs(f2.z) + extents.z * Math.abs(f2.y);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a10
      p0 = v0.dot(a10);
      p1 = v1.dot(a10);
      p2 = v2.dot(a10);
      r = extents.x * Math.abs(f0.z) + extents.z * Math.abs(f0.x);
      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a11
      p0 = v0.dot(a11);
      p1 = v1.dot(a11);
      p2 = v2.dot(a11);
      r = extents.x * Math.abs(f1.z) + extents.z * Math.abs(f1.x);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a12
      p0 = v0.dot(a12);
      p1 = v1.dot(a12);
      p2 = v2.dot(a12);
      r = extents.x * Math.abs(f2.z) + extents.z * Math.abs(f2.x);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a20
      p0 = v0.dot(a20);
      p1 = v1.dot(a20);
      p2 = v2.dot(a20);
      r = extents.x * Math.abs(f0.y) + extents.y * Math.abs(f0.x);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a21
      p0 = v0.dot(a21);
      p1 = v1.dot(a21);
      p2 = v2.dot(a21);
      r = extents.x * Math.abs(f1.y) + extents.y * Math.abs(f1.x);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test axis a22
      p0 = v0.dot(a22);
      p1 = v1.dot(a22);
      p2 = v2.dot(a22);
      r = extents.x * Math.abs(f2.y) + extents.y * Math.abs(f2.x);

      if (Math.max(-Math.max(p0, p1, p2), Math.min(p0, p1, p2)) > r) {

         return false; // Axis is a separating axis

      }

      // Test the three axes corresponding to the face normals of AABB b (category 1).
      // Exit if...
      // ... [-extents.x, extents.x] and [min(v0.x,v1.x,v2.x), max(v0.x,v1.x,v2.x)] do not overlap
      if (Math.max(v0.x, v1.x, v2.x) < -extents.x || Math.min(v0.x, v1.x, v2.x) > extents.x) {

         return false;

      }
      // ... [-extents.y, extents.y] and [min(v0.y,v1.y,v2.y), max(v0.y,v1.y,v2.y)] do not overlap
      if (Math.max(v0.y, v1.y, v2.y) < -extents.y || Math.min(v0.y, v1.y, v2.y) > extents.y) {

         return false;

      }
      // ... [-extents.z, extents.z] and [min(v0.z,v1.z,v2.z), max(v0.z,v1.z,v2.z)] do not overlap
      if (Math.max(v0.z, v1.z, v2.z) < -extents.z || Math.min(v0.z, v1.z, v2.z) > extents.z) {

         return false;

      }

      // Test separating axis corresponding to triangle face normal (category 2)
      // Face Normal is -ve as Triangle is clockwise winding (and XNA uses -z for into screen)
      var plane = new THREE.Plane();
      plane.normal = new THREE.Vector3().copy(f1).cross(f0).normalize();
      plane.constant = plane.normal.dot(a);

      return isIntersectionAABBPlane(aabb, plane);

   }

   function isIntersectionAABBPlane(aabb, Plane) {

      var center = new THREE.Vector3().addVectors(aabb.max, aabb.min).multiplyScalar(0.5),
         extents = new THREE.Vector3().subVectors(aabb.max, center);

      var r = extents.x * Math.abs(Plane.normal.x) + extents.y * Math.abs(Plane.normal.y) + extents.z * Math.abs(Plane.normal.z);
      var s = Plane.normal.dot(center) - Plane.constant;

      return Math.abs(s) <= r;

   }
}
export function onSelectionRelative(e, viewer2D, viewer) {
   try {
      var currSelection = e.target.getSelection();
      if (currSelection.length === 0) {
         viewer2D.clearSelection();
         return
      }
      else {
         viewer2D.select(currSelection);
         // var tree = viewer.model.getData().instanceTree;
         // let nodeData = {};
         // let selectedNode = e.nodeArray[0];
         // nodeData.ID = selectedNode;
         // nodeData.Name = findNodeNameById(viewer, selectedNode, tree);
         // nodeData.Parent = findNodeNameById(viewer, tree.getNodeParentId(selectedNode), tree);
         // let transMat = getFragmentWorldMatrixByNodeId(viewer, viewer.model, e.nodeArray[0], tree).matrix[0];

         // // continue if it has transformation Matrix (meaning it is not a "group node")
         // if (transMat) {
         //    nodeData.position = transMat.getPosition();
         // } else {
         //    nodeData.position = new THREE.Vector3();
         // }
         // console.log(nodeData);
         return
      }
   }
   catch { }
}
function findNodeNameById(viewer, nodeId, tree) {
   return tree.getNodeName(nodeId);
}
export function interactiveOtherViewer(check, viewer, viewer2D) {
   if (check) {
      viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onStateChanged)
      viewer.addEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, onStateChanged)
      viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, onStateChanged)
      viewer.addEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, onStateChanged)
      viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, onStateChanged)
      viewer.addEventListener(Autodesk.Viewing.SHOW_EVENT, onStateChanged)

   } else {
      viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onStateChanged)
      viewer.removeEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, onStateChanged)
      viewer.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, onStateChanged)
      viewer.removeEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, onStateChanged)
      viewer.removeEventListener(Autodesk.Viewing.HIDE_EVENT, onStateChanged)
      viewer.removeEventListener(Autodesk.Viewing.SHOW_EVENT, onStateChanged)

   }

   function onStateChanged(e) {
      viewer2D.restoreState(viewer.getState(), null, false);
   }
}
export const handleSetColorElement = (elements, viewer, viewPort, markup = null, viewPage = null) => {
   let models = viewer.impl.modelQueue().getModels()
   _.forEach(models, model => {
      viewer.clearThemingColors(model)
   })
   let isAllLoaded = viewer.isLoadDone({ onlyModels: models })
   if (isAllLoaded) {

      let count = models.length
      let tempElement = {}
      _.forEach(models, async model => {
         let instanceTree = model.getData().instanceTree;
         let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
         count--
         let count1 = temp.length
         _.forEach(temp, id => {
            model.getProperties(id, (modelAProperty) => {
               tempElement[modelAProperty.externalId] = id
               count1--
               if (count1 === 0) {
                  _.forEach(elements, async el => {
                     let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                     if (index >= 0) {
                        if (tempElement[el.guid]) {
                           let color = convertHexColorToVector4(el.color)
                           viewer.setThemingColor(tempElement[el.guid], color, models[index])
                        }
                     }
                  })
                  viewer.restoreState(viewPort, null, markup ? true : false)
                  if (markup) {
                     let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                     markupCore.show();
                     markupCore.loadMarkups(markup, 'saveView')
                     markupCore.enterEditMode('saveView')
                     let temp = viewPage.state.openCustomDockForge
                     _.forEach(temp, (v, k) => {
                        if (k === 'markUp2dEdit') {
                           temp[k] = !temp[k]
                        } else {
                           temp[k] = temp[k]
                        }
                     })
                     viewPage.setState({ openCustomDockForge: temp })
                  }
               }
            })
         })
      })
   } else {
      viewer.waitForLoadDone({ onlyModels: models })
         .then(res => {
            let count = models.length
            let tempElement = {}
            _.forEach(models, async model => {
               let instanceTree = model.getData().instanceTree;
               let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
               count--
               let count1 = temp.length
               _.forEach(temp, id => {
                  model.getProperties(id, (modelAProperty) => {
                     tempElement[modelAProperty.externalId] = id
                     count1--
                     if (count1 === 0) {
                        _.forEach(elements, async el => {
                           let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                           if (index >= 0) {
                              if (tempElement[el.guid]) {
                                 let color = convertHexColorToVector4(el.color)
                                 viewer.setThemingColor(tempElement[el.guid], color, models[index])
                              }
                           }
                        })
                        viewer.restoreState(viewPort, null, markup ? true : false)
                        if (markup) {
                           let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                           markupCore.show();
                           markupCore.loadMarkups(markup, 'saveView')
                           markupCore.enterEditMode('saveView')
                           let temp = viewPage.state.openCustomDockForge
                           _.forEach(temp, (v, k) => {
                              if (k === 'markUp2dEdit') {
                                 temp[k] = !temp[k]
                              } else {
                                 temp[k] = temp[k]
                              }
                           })
                           viewPage.setState({ openCustomDockForge: temp })
                        }
                     }
                  })
               })
            })
         })
   }
}
export function printSheet(viewer) {
   let onDone = async (blob, imageWidth, imageHeight) => {
      let res = await fetch("http://localhost:3000/printpage.html")
      let content = await res.text()
      // keep height even to avoid extra empty page in print dialog
      imageHeight = imageHeight % 2 ? imageHeight + 1 : imageHeight
      content = content
         .replace(new RegExp("%IMG_WIDTH%", "g"), imageWidth)
         .replace(new RegExp("%IMG_HEIGHT%", "g"), imageHeight)
         .replace("%IMG_SRC%", blob)

      let w = window.open('', '')
      if (w) {
         w.document.open()
         w.document.write(content)
         w.document.close()
      } else {
         console.info("Could not open a new window")
      }
   }

   const canvasBounds = viewer.impl.getCanvasBoundingClientRect()
   const originalWidth = canvasBounds.width
   const originalHeight = canvasBounds.height
   let widthInPixels
   let pageWidth = viewer.model.getMetadata('page_dimensions', 'page_width')
   const pageUnits = viewer.model.getMetadata('page_dimensions', 'page_units')

   // no page_units => raster
   if (pageUnits) {
      const widthInInch = Autodesk.Viewing.Private.convertUnits(pageUnits.toLowerCase(), 'in', 1, pageWidth)
      const DPI = 150

      widthInPixels = Math.floor(widthInInch * DPI)
   } else {
      widthInPixels = pageWidth
   }

   const canvasRatio = originalHeight / originalWidth
   const width = widthInPixels
   const height = Math.floor(widthInPixels * canvasRatio)

   const screenshotOptions = {
      fullPage: true,
      margin: 0,
   }

   Autodesk.Viewing.ScreenShot.getScreenShotWithBounds(viewer, width, height, onDone, screenshotOptions)
}

//#endregion

//#region testing
export function getLeafFragIds(model, leafId) {
   const instanceTree = model.getData().instanceTree;
   const fragIds = [];

   instanceTree.enumNodeFragments(leafId, function (fragId) {
      fragIds.push(fragId);


   });

   return fragIds;
}
export function getComponentGeometry(viewer, dbId) {

   const fragIds = this.getLeafFragIds(viewer.model, dbId);

   let matrixWorld = null;

   const meshes = fragIds.map(function (fragId) {

      const renderProxy = viewer.impl.getRenderProxy(viewer.model, fragId);

      const geometry = renderProxy.geometry;
      const attributes = geometry.attributes;
      const positions = geometry.vb ? geometry.vb : attributes.position.array;

      const indices = attributes.index.array || geometry.ib;
      const stride = geometry.vb ? geometry.vbstride : 3;
      const offsets = geometry.offsets;

      matrixWorld = matrixWorld || renderProxy.matrixWorld.elements;

      return {
         positions,
         indices,
         offsets,
         stride
      };
   });

   return {
      matrixWorld,
      meshes
   };
}
export function getFragmentWorldMatrixByNodeId(viewer, model, dbId, tree) {
   let result = {
      fragId: [],
      matrix: [],
   };
   let seconds_pivot = new THREE.Mesh(
      new THREE.BoxGeometry(10, 10, 10),
      new THREE.MeshBasicMaterial({ color: 0xff0000 }));
   if (viewer.overlays.hasScene('custom-scene')) {

      viewer.overlays.removeScene('custom-scene');
   }

   viewer.overlays.addScene('custom-scene');
   viewer.overlays.addMesh(seconds_pivot, 'custom-scene')
   tree.enumNodeFragments(dbId, function (frag) {

      let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
      let matrix = new THREE.Matrix4();

      let renderProxy = viewer.impl.getRenderProxy(
         viewer.model,
         frag);

      fragProxy.getAnimTransform();
      let matrix1 = renderProxy.matrixWorld;

      console.log(matrix1.getPosition())
      fragProxy.getWorldMatrix(matrix);

      let pin_position = matrix.getPosition().clone();
      seconds_pivot.position.x = pin_position.x;
      seconds_pivot.position.y = pin_position.y;
      seconds_pivot.position.z = pin_position.z;

      result.fragId.push(frag);
      result.matrix.push(matrix);
   });
   return result;
}
export function getVolume(viewer, dbId) {
   var volume = 0;
   var it = viewer.model.getData().instanceTree;

   it.enumNodeFragments(dbId, function (fragId) {
      getVertices(viewer, fragId, (p1, p2, p3) => {
         volume += getTriangleVolume(p1, p2, p3)
      })
   }, true);

   return volume;
}

export function getSurfaceArea(viewer, dbId) {
   var area = 0;
   var it = viewer.model.getData().instanceTree;

   it.enumNodeFragments(dbId, function (fragId) {
      getVertices(viewer, fragId, (p1, p2, p3) => {
         area += getTriangleArea(p1, p2, p3)
      })
   }, true);

   return area;
}

function getTriangleArea(p1, p2, p3) {
   var tr = new THREE.Triangle(p1, p2, p3);
   return tr.area();
}

function getTriangleVolume(p1, p2, p3) {
   return p1.dot(p2.cross(p3)) / 6.0;
}

function getVertices(viewer, fragId, callback) {
   var fragProxy = viewer.impl.getFragmentProxy(
      viewer.model,
      fragId);

   var renderProxy = viewer.impl.getRenderProxy(
      viewer.model,
      fragId);

   fragProxy.updateAnimTransform();

   var matrix = new THREE.Matrix4();
   fragProxy.getWorldMatrix(matrix);

   var geometry = renderProxy.geometry;

   var attributes = geometry.attributes;

   var vA = new THREE.Vector3();
   var vB = new THREE.Vector3();
   var vC = new THREE.Vector3();

   if (attributes.index !== undefined) {

      var indices = attributes.index.array || geometry.ib;
      var positions = geometry.vb ? geometry.vb : attributes.position.array;
      var stride = geometry.vb ? geometry.vbstride : 3;
      var offsets = geometry.offsets;

      if (!offsets || offsets.length === 0) {

         offsets = [{ start: 0, count: indices.length, index: 0 }];
      }

      for (var oi = 0, ol = offsets.length; oi < ol; ++oi) {

         var start = offsets[oi].start;
         var count = offsets[oi].count;
         var index = offsets[oi].index;

         for (var i = start, il = start + count; i < il; i += 3) {

            var a = index + indices[i];
            var b = index + indices[i + 1];
            var c = index + indices[i + 2];

            vA.fromArray(positions, a * stride);
            vB.fromArray(positions, b * stride);
            vC.fromArray(positions, c * stride);

            vA.applyMatrix4(matrix);
            vB.applyMatrix4(matrix);
            vC.applyMatrix4(matrix);

            callback(vA, vB, vC)
         }
      }
   }
   else {
      var positions = geometry.vb ? geometry.vb : attributes.position.array;
      var stride = geometry.vb ? geometry.vbstride : 3;

      for (var i = 0, j = 0, il = positions.length; i < il; i += 3, j += 9) {

         var a = i;
         var b = i + 1;
         var c = i + 2;

         vA.fromArray(positions, a * stride);
         vB.fromArray(positions, b * stride);
         vC.fromArray(positions, c * stride);

         vA.applyMatrix4(matrix);
         vB.applyMatrix4(matrix);
         vC.applyMatrix4(matrix);

         callback(vA, vB, vC)
      }
   }
}
//#endregion

//#region not use
export function onSelectionRelative1(e, viewer1, viewer2) {
   try {
      var currSelection = e.target.getSelection();
      if (this.state.viewerCurrent2D.clientContainer.id === e.target.clientContainer.id) {
         if (currSelection.length === 0) {
            this.state.viewerCurrent.clearSelection();
            return
         }
         else {
            this.state.viewerCurrent.select(currSelection);
            return
         }
      }
      else {
         if (currSelection.length === 0) {
            this.state.viewerCurrent2D.clearSelection();
            return
         }
         else {
            this.state.viewerCurrent2D.select(currSelection);
            return
         }
      }
   }
   catch { }
}
export function getMetadata(node, name) {
   return new Promise(resolve => {
      var dic = [];
      axios.get(`/api/forge/modelderivative/metadatas/${node}/${name}`)
         .then(res => {
            //  console.log(res)
            var tempObj = getAllObject(res.data.object)
            _.forEach(tempObj, (v, k) => {
               var temp = _.find(res.data.properties, function (o) { return o.objectid === v.objectid });
               let tempNeedData = _.values(temp.properties)
               let tempMasterId = ''
               _.forEach(tempNeedData, (v1, k1) => {
                  _.forEach(v1, (v2, k2) => {
                     if (k2 === 'MASTER SCHEDULE ID') {
                        tempMasterId = v2
                     }
                     if (tempMasterId !== '') {
                        if (!dic[tempMasterId])
                           dic[tempMasterId] = []
                        dic[tempMasterId].push(temp.objectid)
                        return false
                     }
                  })
               })
            })
            //  console.log(dic)
            resolve(dic)
         })
         .catch(err => {
            console.log(err)
         })
   })
}
export const getAllObject = (data) => {
   var temp = [];
   var queue = [];
   _.forEach(data, (v, k) => {
      queue.push(v);
   })

   while (queue.length > 0) {
      var node = queue.shift();
      if (node.objects !== undefined) {
         if (node.objects.length !== 0) {
            let childNode = []
            _.forEach(node.objects, (v, k) => {
               childNode.push(v)
            })
            queue = queue.concat(childNode)
         } else {
            temp.push(node);
         }
      } else {
         temp.push(node);
      }

   }
   return temp
}
  // handlePlanning = () => {
  //     var _this = this
  //     _this.state.viewerCurrent.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded1);
  //     if (planningPanel === null) {
  //         planningPanel = new PlanningPanel(_this.state.viewerCurrent, { id: 'planningExtensionPanel', title: 'Planning' })
  //         planningPanel.addEventListener(planningPanel.closer, "click", function () {
  //             _this.state.viewerCurrent.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded1);
  //         })

  //     }
  //     getPlanning();


  //     function getPlanning() {
  //         axios.post('/api/versions/getplanning', { itemId: _this.props.location.state.itemId })
  //             .then(res => {
  //                 planningPanel.setVisible(true, _this.state.viewerCurrent, res.data);
  //                 message.success('Get planning successful.');
  //             })
  //             .catch(err => {
  //                 message.error('Get planning failed.');
  //             })
  //     }

  //     function onSelectionBinded1() {
  //         var currSelection = _this.state.viewerCurrent.getSelection();
  //         if (currSelection.length !== 0) {
  //             planningPanel.changeDate(currSelection[0]);

  //         }
  //     }
  // }
  //#endregion