import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import ConfirmEmailMessage from '../../messages/ConfirmEmailMessage'
// import { fetchProjects } from "../../actions/project";
// import {excelData} from "../../actions/excel";
import _ from 'lodash'
// import CardProjects from '../cards/CardProjects'
// import { allProjectsSelector } from "../../reducers/projects";
import {ButtonMenuProject}  from '../../module_BimApp/buttons';
import axios from "axios";
import { Empty, BackTop, message } from 'antd';
import { Icon, IconButton, ButtonGroup, Loader } from 'rsuite';
import { Card, Image, Dropdown } from 'semantic-ui-react'
import {WHLogoGif} from '../../../image'
import LazyLoad from 'react-lazyload';
import {
  sortProjectName, sortItemCreatedDate, sortItemUpdatedDate,
  sortDescendingProjectName, sortDescendingItemCreatedDate, sortDescendingItemUpdatedDate, storageUser
} from '../../../components/module_BimApp/function/PageFunction'
import {TableListProject} from '../../module_BimApp/tables'
import {DialogSettingProject} from '../../module_BimApp/dialog'
import { trackingUser } from '../../../components/module_BimApp/function/AdminFunction'
import ResultError from '../../messages/ResultError'
import {ColumnProject} from '../../module_BimApp/columns'
import {CardProjects} from '../../module_BimApp/cards'
// const CardProjects = React.lazy(() =>
//   import('../../cards/CardProjects')
// )

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      isListCard: true,
      loadingImg: false,
      imgProject: null,
      displayDialogSettingProject: false,
      userCreatedProject: '',
      sharePointId: '',
      issueKey: '',
      trimbleKey:'',
      projectId: '',
      useRfa:false,
      projectDisplayName:'',
      projectName:'',

      error: false,
      sortByValue: 'Name'
    }
  }
  componentDidCatch = () => {
    this.setState({ error: true })
  }
  componentDidMount() {
    try {
      trackingUser(this.props.userInfo.email)
    } catch { }
  }
  componentWillMount() {
    // window.Office.initialize = (e) => {
    //   excelData({excelPage: true})
    //   this.props.history.push("/excel")
    // };
    if ($('#topNavigation').is(":hidden")) {
      $('#topNavigation').show()
    }
    axios.get(`/api/projects`, { params: { token: this.props.userInfo.token, email: this.props.userInfo.email } })
      .then(res => {
        if (res.data.user !== null)
          storageUser(res.data.user)
        _.forEach(res.data.project, v => {
          v.uploadDate =v.uploadDate// moment(v.uploadDate).format('DD-MM-YYYY HH:mm')
          v.createdDate =v.createdDate// moment(v.createdDate).format('DD-MM-YYYY HH:mm')
        })
        res.data.project.sort(sortProjectName)
        this.setState({
          projects: res.data.project,
          loading: false
        })
      })
      .catch(res => {
        this.setState({ loading: false, error: true })
      })

  }


  changeProject = data => {
    // var temp = this.state.projects
    // temp.push(data)
    // this.setState({
    //     projects: temp,
    //     loading : "ui active inverted dimmer"
    //   },function(){ 
    //     this.setState({loading :"ui inverted dimmer"})
    //   })
  }
  //#region sort
  handleSortBy = (e, { value }) => {
    let temp = this.state.projects
    if (this.state.iconUpDOwn) {
      if (value === 'Name') {
        temp.sort(sortProjectName)
      } else if (value === 'Created Date') {
        temp.sort(sortItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortItemUpdatedDate)
      }
    } else {
      if (value === 'Name') {
        temp.sort(sortDescendingProjectName)
      } else if (value === 'Created Date') {
        temp.sort(sortDescendingItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortDescendingItemUpdatedDate)
      }
    }
    this.setState({ projects: temp, sortByValue: value })
  }
  handleUpDown = () => {
    let temp = this.state.projects
    this.setState({ iconUpDOwn: !this.state.iconUpDOwn }, () => {
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortProjectName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingProjectName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        }
      }
      this.setState({ projects: temp })
    })
  }
  //#endregion 

  handleOpenControlSettingPanel = (data) => {
    this.setState({
      displayDialogSettingProject: true, projectId: data.projectId,
      sharePointId: data.sharePointId, userCreatedProject: data.userCreatedProject, imgProject: data.imgProject, issueKey: data.issueKey, trimbleKey: data.trimbleKey,
      typeProject: data.typeProject,useRfa:data.useRfa ,projectDisplayName:data.projectDisplayName,projectName:data.projectName
    })
  }
  handleCloseSettingPanel = () => {
    this.setState({ displayDialogSettingProject: false })
  }
  handleUploadImg = (value) => {
    this.setState({ loadingImg: true })
    let data = new FormData()
    data.append('file', value.file)
    data.append('projectId', value.projectId)
    axios.post(`/api/projects/up-img-project`, data, {
      onUploadProgress: ProgressEvent => {
        // setLoad(
        //     (ProgressEvent.loaded / ProgressEvent.total*100),
        // )
      },
    })
      .then(res => {
        let temp = this.state.projects.slice()
        _.forEach(temp, (v, k) => {
          if (v.project.projectId === value.projectId) {
            let tempProject = v
            tempProject.image = res.data
            temp.splice(k, 1)
            temp.splice(k, 0, tempProject)
            return false
          }
        })
        this.setState({ loadingImg: false, projects: temp, imgProject: res.data })
        message.success(`Upload image successful`)

      })
      .catch(err => {
        this.setState({ loadingImg: false })
        message.error(`Upload image failed`)
      })
  }
  handleDeleteProject(value) {
    axios.delete(`/api/projects/deleteproject`, { data: { projectId: value.projectId } })
      .then(res => {
        window.location.reload()
      })
      .catch(err => {
        message.error(err.response.data.errors)
      })
  }
  handleDisplayCard = () => {
    this.setState({ isListCard: !this.state.isListCard })
  }
  render() {
    var { isConfirmed, isAdmin, userInfo } = this.props;
    let sortByOptions = [
      { key: 'Name', text: 'Name', value: 'Name', },
      { key: 'Created Date', text: 'Created Date', value: 'Created Date', },
      { key: 'Updated Date', text: 'Updated Date', value: 'Updated Date', },
    ]

    return (
      <React.Suspense fallback={
        <Loader center content="Loading..." speed="fast" size="md" vertical />
      }>
        {!this.state.error && (isConfirmed && isAdmin) ? <ButtonMenuProject key={userInfo.user} user={userInfo}
          changeProject={this.changeProject} /> : <div></div>}
        {this.state.error
          ? <ResultError />
          : !isConfirmed ? <ConfirmEmailMessage /> : (
            this.state.loading ?
              <Image src={WHLogoGif} style={{ position: 'absolute', top: 'calc(50% - 32px)', left: 'calc(50% - 32px)' }} />
              :
              (this.state.projects.length === 0 ?
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ top: 'calc(50% - 20px)', position: 'absolute', left: 'calc(50% - 32px)' }} /> :
                this.state.isListCard ?
                  <div>
                    <div style={{ marginTop: 10, marginLeft: 25 }}>
                      Sort by{' '}
                      <Dropdown
                        inline onChange={this.handleSortBy}
                        options={sortByOptions} value={this.state.sortByValue}
                        defaultValue={sortByOptions[0].value}
                      />
                      <IconButton onClick={this.handleUpDown} size='xs' icon={<Icon icon={this.state.iconUpDOwn ? 'arrow-down2' : 'arrow-up2'} />} />
                      <ButtonGroup size="md" style={{ float: 'right', paddingRight: 25 }}>
                        <IconButton icon={<Icon icon="list" />} active={!this.state.isListCard} onClick={this.handleDisplayCard} />
                        <IconButton icon={<Icon icon="th" />} active={this.state.isListCard} onClick={this.handleDisplayCard} />
                      </ButtonGroup>
                    </div>
                    <LazyLoad >
                      <Card.Group key={userInfo.email} style={{ margin: "5px" }}>
                        {this.state.projects.map(item =>
                          <CardProjects key={item.project} project={item.project}
                            image={item.image} userCreatedProject={item.userCreated}
                            isadmin={isAdmin} sharePointId={item.sharePointId} issueKey={item.issueKey} typeProject={item.typeProject} trimbleKey={item.trimbleKey} 
                            changeProject={this.changeProject} useRfa={item.useRfa}  projectDisplayName={item.projectDisplayName}
                            handleOpenControlSettingPanel={this.handleOpenControlSettingPanel}
                            handleDeleteProject={this.handleDeleteProject}
                            loadingImg={this.state.loadingImg}
                            history={this.props.history}
                          />
                        )}
                      </Card.Group>
                    </LazyLoad>
                  </div>
                  :
                  <div>
                    <div style={{ marginTop: 10, marginLeft: 25 }}>
                      <ButtonGroup size="md" style={{ float: 'right', paddingRight: 25 }}>
                        <IconButton icon={<Icon icon="list" />} active={!this.state.isListCard} onClick={this.handleDisplayCard} />
                        <IconButton icon={<Icon icon="th" />} active={this.state.isListCard} onClick={this.handleDisplayCard} />
                      </ButtonGroup>
                    </div>

                    <TableListProject
                      columns={ColumnProject(this)}
                      data={this.state.projects}
                      getRowProps={row => ({})} getCellProps={rows => ({})}
                      email={userInfo.email}
                      isAdmin={isAdmin} permission={userInfo.permission}
                      handleDeleteProject={this.handleDeleteProject}
                      handleOpenControlSettingPanel={this.handleOpenControlSettingPanel}
                    />
                  </div>
              )
          )}
        <BackTop style={{ bottom: 80, right: 30 }} />
        {this.state.displayDialogSettingProject &&
          <DialogSettingProject onShow={this.state.displayDialogSettingProject} onClose={this.handleCloseSettingPanel}
            handleUploadImg={this.handleUploadImg} isAdmin={isAdmin}
            loadingImg={this.state.loadingImg}
            imgProject={this.state.imgProject}
            userCreatedProject={this.state.userCreatedProject}
            sharePointId={this.state.sharePointId} issueKey={this.state.issueKey} typeProject={this.state.typeProject} projectId={this.state.projectId}
            typeProject={this.state.typeProject} trimbleKey={this.state.trimbleKey} useRfa={this.state.useRfa} 
            projectDisplayName ={this.state.projectDisplayName} projectName ={this.state.projectName}
          />}
      </React.Suspense>
    );
  }
}

DashboardPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  excelData: PropTypes.func.isRequired,
  // projects:   PropTypes.arrayOf(PropTypes.object).isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    // projects:allProjectsSelector(state),
    userInfo: state.user,

  }
}
export default connect(mapStateToProps)(DashboardPage)

// const columnsListItem = [
//   {
//     Header: 'Project Name', accessor: 'projectName', id: 'projectName', units: false, filter: "includes",
//     Cell: ({ cell: { row, value } }) => (
//       <div style={{ overflow: 'hidden' }}>
//         <Link to={{
//           pathname: `/projectdetail`,
//           state: {
//             userCreatedProject: row.original.userCreated,
//             projectId: row.original.project.projectId,
//             projectName: row.original.project.projectName,
//             sharePointId: row.original.sharePointId,
//             issueKey: row.original.issueKey,
//             trimbleKey: row.original.trimbleKey,
//             typeProject: row.original.typeProject
//           }
//         }}>
//           <Label style={{ float: 'left',  marginRight:5 }} size='mini' color='green'>
//             <Icon size='lg' icon='project' > Project</Icon>
//           </Label>
//           <div style={{ color: 'black', verticalAlign: 'middle', display: 'inline' }}>
//             {value === undefined ? row.original.project.projectName : value}</div>
//         </Link>
//       </div>
//     )
//   },
//   { Header: 'Folders', accessor: 'folderCount', id: 'folderCount', units: false, filter: "includes" },
//   { Header: 'Created Date', accessor: 'createdDate', id: 'createdDate', units: false, filter: "includes" },
//   { Header: 'Updated Date', accessor: 'uploadDate', id: 'uploadDate', units: false, filter: "includes" },
//   { Header: 'Created by', accessor: 'userCreated', id: 'userCreated', units: false, filter: "includes", },
// ];
