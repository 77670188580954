import { APPLY_ACTION_ON_CELL, COPY_TEMP_DATA, GET_CELL_MODIFIED_TEMP, OVERWRITE_CELLS_MODIFIED, OVERWRITE_CELL_REDUCER, SET_CELL_ACTIVE } from '../../types';


export const getCellModifiedTemp = (data) => dispatch => {
   dispatch({
      type: GET_CELL_MODIFIED_TEMP,
      payload: data
   });
};
export const overwriteCellsModified = (data) => dispatch => {
   dispatch({
      type: OVERWRITE_CELLS_MODIFIED,
      payload: data
   });
};
export const setCellActive = (data) => dispatch => {
   dispatch({
      type: SET_CELL_ACTIVE,
      payload: data
   });
};
export const copyTempData = (data) => dispatch => {
   dispatch({
      type: COPY_TEMP_DATA,
      payload: data
   });
};
export const applyActionOnCell = (data) => dispatch => {
   dispatch({
      type: APPLY_ACTION_ON_CELL,
      payload: data
   });
};
export const overwriteCellReducer = () => dispatch => {
   dispatch({
      type: OVERWRITE_CELL_REDUCER
   });
};









