import { FETCH_DATA_ONE_SHEET, OVERWRITE_PROJECT_REDUCER, SET_USER_DATA } from '../../types';



export const fetchDataOneSheet = (data) => dispatch => {
   dispatch({
      type: FETCH_DATA_ONE_SHEET,
      payload: data
   });
};

export const setUserData = (data) => dispatch => {
   dispatch({
      type: SET_USER_DATA,
      payload: data
   });
};
export const overwriteProjectReducer = () => dispatch => {
   dispatch({
      type: OVERWRITE_PROJECT_REDUCER
   });
};



