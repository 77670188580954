import React from 'react'
import axios from "axios";
import _ from 'lodash'
import {
  getAllElementdbIdsOneModel
} from '../../../function/ForgeFunction'
import Draggable from 'react-draggable';
import { Empty, Typography, message, Popconfirm } from 'antd';
import { Table } from 'semantic-ui-react'
import { Loader, IconButton, Icon, Whisper, Popover, Button, DatePicker, Input, ButtonGroup } from 'rsuite';

import moment from 'moment';
import { convertHexColorToVector4 } from '../../../function/TableFunction'
const { Paragraph } = Typography;
const Autodesk = window.Autodesk;


export default class StatusInstallation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      description: 'Please pick a object',
      newCode: '',
      localPPVCSaved: [],
      date: null,
      isEdit: false
    };
    this.moduleData = null
    this.modelData = {
      dbIds: [],
      data: []
    }
  }

  componentWillMount = () => {
    this.getModules()
  }
  componentWillUnmount = () => {
    try {
      this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
    } catch { }
  }

  getModules = () => {
    this.setState({ loading: true })
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          this.moduleData = res.data.result;
          this.getModelData();
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }
  getModelData = () => {
    this.modelData.dbIds = getAllElementdbIdsOneModel(this.props.viewer);
    if (this.modelData.dbIds && this.modelData.dbIds.length > 0) {
      let count = this.modelData.dbIds.length
      _.forEach(this.modelData.dbIds, id => {
        this.props.viewer.model.getProperties(id, (modelAProperty) => {
          _.forEach(modelAProperty.properties, (v) => {
            if(this.props.projectKey==='pdd'){
              if (v.displayName === 'PCAPP-Module Code') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
              }
            }else{
              if (v.displayName === 'Mark') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
                return false
              }
            }
       
          })
          count--
          if (count === 0) {

            axios.get('/api/customproperty/get-custom-ppvc', { params: { projectKey: this.props.projectKey } })
              .then(async res => {
                this.combineData(res.data.ppvc)
              })
              .catch(() => {
                message.error('Sync file failed')
                this.setState({ loading: false, date: null, newCode: '' })
              })

          }
        })

      })

    }
  }
  combineData = (ppvc) => {
    let temp = []
    this.props.viewer.clearThemingColors(this.props.viewer.model)
    let color = convertHexColorToVector4("#722ed1")
    _.forEach(this.moduleData, item => {
      let modelItem = _.find(this.modelData.data, x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        let index = _.findIndex(ppvc, (o) => { return o.code.toLowerCase() === item.code.toLowerCase() })
        item.dbId = modelItem.dbId;
        if (index < 0) {
          temp.push({ id: modelItem.dbId, code: item.code, newCode: '', isModify: false, date: null })
        } else {
          temp.push({ id: modelItem.dbId, code: item.code, newCode: ppvc[index].newCode, isModify: false, date: ppvc[index].date })
          if (ppvc[index].newCode !== '')
            this.props.viewer.setThemingColor(modelItem.dbId, color)
        }
      } else {
        item.dbId = ''
        temp.push({ id: '', code: item.code, newCode: '', isModify: false, date: null })
      }
    })
    try {
      this.setState({ localPPVCSaved: [], date: null, newCode: '', isEdit: false }, () => {
        this.setState({ loading: false, localPPVCSaved: temp }, () => {
          let currSelection = this.props.viewer.getSelection()
          this.handlePPVCShowCode(currSelection)
        })
      })
      this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
    } catch { }
  }
  onSelectPPVC = () => {
    try {
      var currSelection = this.props.viewer.getSelection()
      if (currSelection.length === 0) {
        this.props.viewer.clearSelection();
        this.setState({ data: [], description: `Please pick a object` })
        return
      }
      else {
        this.handlePPVCShowCode(currSelection)
        return
      }
    }
    catch { }
  }
  handlePPVCShowCode = (currSelection) => {
    let tempData = []
    _.forEach(currSelection, v => {
      let temp = _.find(this.state.localPPVCSaved, (o) => o.id === v)
      if (temp) {
        let tempItem = temp
        _.forEach(tempItem.actions, action => {
          if (action.completedDate) {
            let completedDate = new Date(action.completedDate);
            if (action.actionName.includes('Delivering to') ||
              (action.actionName.includes('Move into') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              action.actionName.includes('Ready for Delivery') ||
              (action.actionName.includes('Received Delivery') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase())) {
              tempItem.castingCompleted = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
            }
            else if (action.actionName.includes('Received Delivery at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()
              || action.actionName.includes('Move into at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
              tempItem.deliveredToSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
            }
            else if (action.actionName.includes('Module Installed on') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
              tempItem.installedOnSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
            }
          }
        })
        tempData.push(tempItem)
      }
    })
    this.setState({ data: tempData })
  }
  handleCloseDock = () => {
    let comfirm = true
    if (this.state.isEdit)
      comfirm = window.confirm('You still not sync file')
    if (comfirm) {
      this.props.onChangeDockDisplay('statusInstallationDock', false)
      try {
        this.props.viewer.clearThemingColors()
        this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
      } catch { }
    }
  }
  handleChangeValuePPVCCode = (value) => {
    this.setState({ newCode: value })
  }
  handleSavePPVC = (k) => {
    let temp = this.state.localPPVCSaved
    let index = _.findIndex(temp, o => { return o.code === this.state.newCode })
    if (index >= 0) {
      let index = _.findIndex(temp, o => { return o.newCode === this.state.newCode })
      if (index < 0) {
        let index = _.findIndex(temp, o => { return o.code === this.state.data[k].code })
        if (index >= 0) {
          temp[index].newCode = this.state.newCode
          temp[index].date = this.state.date
          temp[index].isModify = true
        }
        this.setState({ localPPVCSaved: temp, loading: false, isEdit: true })
      } else {
        message.warn('This code had been taken')
      }
    } else {
      message.warn('This code is not existing')
    }
  }
  handlePPVCDate = (k, date) => {
    let temp = this.state.localPPVCSaved
    let index = _.findIndex(temp, o => { return o.code === this.state.data[k].code })
    if (index >= 0) {
      temp[index].date = moment(date).format('DD-MM-YYYY')
      temp[index].isModify = true
    }
    this.setState({ localPPVCSaved: temp, loading: false, isEdit: true })
    // this.setState({ date: moment(date).format('DD-MM-YYYY') })
  }
  handleSyncPPVC = () => {
    let index = _.findIndex(this.state.data, o => { return o.date === null || o.newCode === '' })
    if (index >= 0) {
      message.warning('Please, key in data')
      return
    }
    this.setState({ loading: true })
    axios.post('/api/customproperty/save-custom-ppvc', { projectKey: this.props.projectKey, ppvc: this.state.localPPVCSaved })
      .then(async res => {
        this.combineData(res.data.ppvc)
      })
      .catch(() => {
        message.error('Sync file failed')
        this.setState({ loading: false, date: null, newCode: '', data: [], isEdit: false })
      })
  }
  handleRemoveCode = (k) => {
    if (!window.confirm('Are you want to delete?')) return
    let temp = this.state.localPPVCSaved
    let index = _.findIndex(temp, o => { return o.code === this.state.data[k].code })
    if (index >= 0) {
      temp[index].newCode = ''
      temp[index].date = null
      temp[index].isModify = true
    }
    this.setState({ localPPVCSaved: temp, isEdit: true, loading: true }, () => {
      axios.post('/api/customproperty/save-custom-ppvc', { projectKey: this.props.projectKey, ppvc: this.state.localPPVCSaved })
        .then(async res => {
          this.combineData(res.data.ppvc)
        })
        .catch(() => {
          message.error('Sync file failed')
          this.setState({ loading: false, date: null, newCode: '', data: [], isEdit: false })
        })
    })
  }
  render() {
    let { data } = this.state
    return (
      <>
        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 40, y: 30 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 450, width: 450 }}
            id='custom-ppvc-status-installation' >
            <div className='custom-dock-panel-title'>Status Installation</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
            <div className='custom-dock-panel-body' style={{ backgroundColor: '#ffffff00' }}>
              <div style={{ width: "100%", height: 'calc(100% - 37px)', overflow: "auto", position: 'absolute' }}>
                {this.state.loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
                  this.state.data.length === 0 ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                      <span style={{ color: 'black' }}>
                        {this.state.description}
                      </span>
                    } /> :
                    <div >
                      <Table celled selectable verticalAlign='middle' textAlign='center' size='small'>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              style={{ position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', backgroundColor: 'white', zIndex: 10 }}  >
                              {'PPVC Code'}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', backgroundColor: 'white', zIndex: 10 }}  >
                              {'PPVC Code Replace'}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', backgroundColor: 'white', zIndex: 10 }} >
                              {'Date'}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {this.state.data.length !== 0 ?

                            this.state.data.map((v, k) => (
                              <Table.Row >
                                <Table.Cell>
                                  <ButtonGroup style={{ float: 'left', display: 'inline', marginRight: 2 }}>
                                    <IconButton icon={< Icon icon="cloud" />} size='xs' color={v.isModify && 'blue'}
                                    />
                                    <IconButton icon={< Icon icon='trash-o' />} size='xs' color={'red'}
                                      onClick={this.handleRemoveCode.bind(this, k)} />
                                  </ButtonGroup>

                                  <Paragraph className='custom-cell-ppvc-status-installation'
                                    title={data.code} ellipsis style={{ fontSize: 'larger', marginBottom: '0px !important', color: 'black' }}>{v.code}</Paragraph>
                                </Table.Cell>
                                <Table.Cell>
                                  <div>
                                    {/* <InputGroup size='xs'  >
                                      <Input onBlur={this.handleChangeValuePPVCCode} defaultValue={v.newCode}  style={{
                                        color: v.code !== v.newCode ? 'red' : 'black'
                                      }} />
                                      <InputGroup.Button onClick={this.handleSavePPVC.bind(this, k)}size='xs'>
                                        <Icon icon="edit" />
                                      </InputGroup.Button>
                                    </InputGroup> */}
                                    <Paragraph className='custom-cell-ppvc-status-installation'
                                      title={data.code} ellipsis style={{
                                        fontSize: 'larger', marginBottom: '0px !important', display: 'inline',
                                        color: v.code !== v.newCode ? 'red' : 'black'
                                      }}>
                                      {v.newCode}</Paragraph>
                                    <Whisper trigger="click" placement={'rightStart'}
                                      speaker={
                                        <Popover title={'Edit PPVC Code'} className={'popover-edit-ppvc-code'} trigger="click">
                                          <Input placeholder="New Code" onChange={this.handleChangeValuePPVCCode}
                                          />
                                          <IconButton icon={< Icon icon="plus" />} style={{ float: 'right' }}
                                            onClick={this.handleSavePPVC.bind(this, k)}
                                            size='md' display={this.state.newCode === ''} />
                                        </Popover>
                                      }>
                                      <IconButton icon={< Icon icon="edit" />} style={{ float: 'right', top: 0, marginRight: 2, }} size='xs' />
                                    </Whisper>

                                  </div>
                                </Table.Cell>
                                <Table.Cell>
                                  <DatePicker className='custom-cell-ppvc-status-installation-date'
                                    onChange={this.handlePPVCDate.bind(this, k)}
                                    format='DD-MM-YYYY' appearance="subtle" size='xs' placement="rightStart" toggleComponentClass={Button}
                                    value={(v.date !== null && v.date) ? moment(v.date, 'DD-MM-YYYY') : null}
                                    cleanable={false} block />
                                </Table.Cell>
                              </Table.Row>
                            ))
                            : <div></div>
                          }

                        </Table.Body>
                      </Table>
                    </div>
                }

              </div>
              <div style={{ bottom: 0, position: 'absolute', left: 'calc(50% - 35px)' }}>
                <Popconfirm title="Are you sure want to sync ?"
                  onConfirm={this.handleSyncPPVC} okText="Yes" cancelText="No" placement='topRight' >
                  <Button disabled={!this.state.isEdit} appearance="primary">Update</Button>
                </Popconfirm>

              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable >

      </>

    )
  }
}