import { val } from "objection";
import React, { useState, useCallback, useEffect } from "react";

import {
   ComposedChart,
   Bar,
   LabelList,
   Cell,
   Line,
   ResponsiveContainer,
   XAxis,
   YAxis,
   Tooltip,
   Label,
   CartesianGrid,
   BarChart,
} from "recharts";

const ChartSxFPerProject = ({ current_project, data, sxfSelected }) => {
   const [perProjectData, setPerProjectData] = useState([]);
   useEffect(() => {
      {
         const temp_data = [];
         if (data !== undefined) {
            const d = data.find((e) => e.name === sxfSelected);
            if (d !== undefined) {
               const list_d = Object.entries(d);

               list_d.forEach((e) => {
                  const count = parseFloat(e[1]);
                  if (e[0].includes("_count") && count > 0) {
                     let item = new Object();
                     item.projectName = e[0].substring(0, 3);
                     item.count = count;
                     temp_data.push(item);
                  }
               });
               setPerProjectData(temp_data);
            }
         }
      }
   }, [sxfSelected, current_project]);

   return (
      <div>
         <h5 style={{ textAlign: "left" }}>
            {sxfSelected !== undefined ? `No of Observation for ${sxfSelected}` : ` By Project`}
         </h5>
         <ResponsiveContainer width="100%" minHeight={"20vh"}>
            <BarChart
               data={perProjectData}
               margin={{
                  top: 5,
                  right: 5,
                  left: 10,
                  bottom: 0,
               }}
            >
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <Tooltip cursor={false} />
               <XAxis
                  dataKey="projectName"
                  tickLine={false}
                  angle={-90}
                  height={30}
                  dx={-10}
                  dy={8}
                  interval={0}
                  // minTickGap={-200}
               ></XAxis>
               <Label
                  value="Fail items (%)"
                  // offset={0}
                  position="top"
                  style={{
                     fontWeight: 700,
                     fontSize: "16px",
                  }}
               />
               <YAxis type="number" domain={[0, "dataMax + 10"]} hide={true} />

               <Bar dataKey="count" fill="#82ca2d">
                  <LabelList dataKey="count" position="top" fontSize="11px" fill="black" />
               </Bar>
            </BarChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartSxFPerProject;
