import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover } from 'rsuite';
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip';
import { Popconfirm } from 'antd';
import {
  exportTableToExcel, colorSetWithElementId
} from '../function/TableFunction'
import ReactTable from './ReactTable'



function TableSchedule(props) {

  const [groupByLocal, setGroupByLocal] = React.useState(0)
  const [rowsLocal, setRowsLocal] = React.useState([])
  const [expandLevel, setExpandLevel] = React.useState({ level: -1 })
  const [triggerCollapse, setTriggerCollapse] = React.useState({ trigger: false })

  const handleIsolateRows = () => {
    let temp = []
    if (!props.activeButtonIsolateRows) {
      let dBids = props.viewer.impl.selector.getAggregateSelection()
      if (dBids.length !== 0) {
        _.forEach(dBids, v => {
          _.forEach(v.selection, id => {
            temp.push(id + '//' + v.model.myData.loadOptions.modelNameOverride)
          })
        })
        props.onIsolateRows(temp);
      }
    } else {
      props.onIsolateRows(temp);
    }
  }
  const handleExpandLocal = (level) => {
    setExpandLevel({ level })
  }
  const handleCollapseLocal = () => {
    setTriggerCollapse({ trigger: true })
  }
  return (
    <div>
      <ButtonGroup style={{ position: 'absolute', zIndex: '1' }}>
        <Tooltip title="Colorize" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="paint-brush" />} onClick={() => colorSetWithElementId('tableDataGeneral', rowsLocal, groupByLocal, props.viewer, props.tempListModel)}></IconButton>
        </Tooltip>
        <Popconfirm placement="bottom" title={'Are you sure to export this schedule?'} onConfirm={() => exportTableToExcel('tableDataGeneral')} okText="Yes" cancelText="No">
          <Tooltip title="Export" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="export" />} />
          </Tooltip>
        </Popconfirm>
        <Whisper placement='bottomLeft' trigger="click" speaker={
          <Popover trigger="click">
            <ButtonGroup>
              <Button size="sm" onClick={handleExpandLocal.bind(this, 0)} disabled={groupByLocal.length < 1} >1</Button>
              <Button size="sm" onClick={handleExpandLocal.bind(this, 1)} disabled={groupByLocal.length < 2}>2</Button>
              <Button size="sm" onClick={handleExpandLocal.bind(this, 2)} disabled={groupByLocal.length < 3}>3</Button>
            </ButtonGroup>
          </Popover>
        }>
          <Tooltip title="Expand" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="minus-square-o" />}  ></IconButton>
          </Tooltip>
        </Whisper>

        <Tooltip title="Collapse" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="plus-square-o" />} onClick={handleCollapseLocal} ></IconButton>
        </Tooltip>
        <Tooltip title="Isolate objected selected in schedule table" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="link" />} color={props.activeButtonIsolateRows && 'violet'} onClick={() => handleIsolateRows()}></IconButton>
        </Tooltip>

      </ButtonGroup>

      <ReactTable
        {...props}
        setGroupByLocal={setGroupByLocal}
        expandLevel={expandLevel}
        setRowsLocal={setRowsLocal}
        triggerCollapse={triggerCollapse}
        haveColor={true}
        haveSelection={true}
        id={'tableDataGeneral'}
      />
    </div>
  )
}

export default TableSchedule