/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Upload, message } from "antd";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Excel from "exceljs";
import axios from "axios";
import dayjs from "dayjs";

const { Dragger } = Upload;

const ImportExcelConcDO = ({ tempData, generalState, setGeneralState, setTempData }) => {
   const [listFiles, setlistFiles] = useState([]);
   const wb = new Excel.Workbook();
   const reader = new FileReader();

   const func_Import_Conc_DO = async () => {
      const file = listFiles[0].originFileObj;
      const temp = [];
      const projectId = localStorage.getItem("fp_project_id");
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
         const buffer = reader.result;
         wb.xlsx.load(buffer).then((workbook) => {
            var DOsheet = workbook.getWorksheet(1);
            const no_of_row = DOsheet.actualRowCount;
            if (!no_of_row || no_of_row < 1) {
               return "Nothing to process.";
            }
            DOsheet.eachRow((row, rowIndex) => {
               var do_no = "";
               if (typeof row.values[3] === "undefined") {
                  do_no = "";
               } else {
                  do_no = row.values[3];
               }
               if (rowIndex > 6 && do_no !== "") {
                  const key = `${projectId}_${"conc_do"}_${do_no}.pdf`;
                  let entity = {
                     id: uuidv4(),
                     do_number: do_no,
                     do_date: dayjs(row.values[`1`]).format("DD-MM-YYYY"),
                     volume: row.values[19],
                     grade: row.values[22],
                     supplier: checkSupplierName("ALLIANCE"),
                     product: row.values[18],
                     pour_name: row.values[34],
                     order_by: row.values[42],
                     remark: row.values[40],
                     checks: "ok",
                     s3_key: key.toLowerCase(),
                     project_id: projectId,
                  };

                  if (
                     typeof row.values[2] == "undefined" ||
                     typeof row.values[3] == "undefined" ||
                     typeof row.values[4] == "undefined" ||
                     typeof row.values[5] == "undefined"
                  ) {
                     entity.checks = "missing info";
                     temp.push(entity);
                  } else {
                     // check if element has already exist on server
                     if (typeof row.values[2] != "undefined") {
                        temp.push(entity);
                     }
                  }
               }
            });

            // get the list of DO no
            let DOs = temp.map((a) => a.do_number);
            // check if element exist
            axios
               .post("/api/do_inv/common/check_exist_multi", {
                  list_DO: JSON.stringify(DOs),
                  current: "conc_do",
                  projectId: projectId,
               })
               .then((res) => {
                  if (typeof res.data.data !== "undefined" && res.data.message !== "There is new DO!") {
                     let founded_do = res.data.data.items.map((a) => a.do_number);
                     //map back to list tempdata
                     temp.forEach((element) => {
                        if (founded_do.includes(String(element.do_number))) {
                           element.checks = "exist";
                        }
                     });
                     //dispatch to list.
                     setTempData(temp);
                     message.info(
                        "There is DO already exist in the system, it will be ignored and not import to system"
                     );
                  } else {
                     setTempData(temp);
                  }
               });
         });
      };
   };
   const funct_Apply_Conc_DO = async () => {
      // apply data to RDS
      try {
         let filtterd_array = _.cloneDeep(tempData);
         filtterd_array = filtterd_array.filter((a) => a.checks === "ok");
         if (filtterd_array.length !== tempData.length) {
            if (
               window.confirm("There are items already exist, exist item will not save. Save for non-exist only?") ==
               true
            ) {
               if (filtterd_array.length > 0) {
                  await axios.post("/api/do_inv/common/multi", {
                     filtterd_array: filtterd_array,
                     current: "conc_do",
                  });
                  message.success("Non-existing items has saved to the system.");
               } else {
                  message.warn("No item to process.");
               }
            }
         } else {
            await axios.post("/api/do_inv/common/multi", {
               filtterd_array: filtterd_array,
               current: "conc_do",
            });
            message.success("Data has saved to the system.");
         }
      } catch (error) {
         console.log("error: ", error);
      }
   };
   const checkSupplierName = (original_name) => {
      if (typeof original_name != "undefined") {
         var new_name = original_name.toUpperCase();
         if (new_name.includes("ALLIA")) {
            new_name = "ALLIANCE";
         }
         if (new_name.includes("PAN")) {
            new_name = "PAN UNITED";
         }
         if (new_name.includes("ISLAND")) {
            new_name = "ISLAND";
         }
         return new_name;
      }
   };

   const handleProcess = async (e) => {
      e.preventDefault();

      var msg = "";
      if (listFiles.length > 0) {
         msg = func_Import_Conc_DO();

         if (msg !== "") {
            // alert(msg);
         }
      } else {
         alert("Ay oh, select file first lah!");
      }
   };

   const handleApply = async (e) => {
      e.preventDefault();
      if (tempData.length > 0) {
         funct_Apply_Conc_DO();
      } else {
         alert("Ay oh, nothing to save!");
      }
   };

   const props = {
      multiple: true,
      onChange(info) {
         let listFiles = [...info.fileList];

         setlistFiles(listFiles);
      },

      onRemove: (file) => {
         const index = listFiles.indexOf(file);
         const newFileList = listFiles.slice();
         newFileList.splice(index, 1);

         return setlistFiles(newFileList);
      },
      beforeUpload: (file) => {
         setlistFiles([...listFiles, file]);
         return false;
      },
      listFiles,
   };

   return (
      <div className="extract-data-form">
         <h6 className="text-ontop-menu"> Get DO data in by import</h6>
         <h5 className="text-ontop-menu">
            <span style={{ color: "blue", fontWeight: "bold" }}> Excel </span>
            file.
         </h5>

         <Dragger {...props} maxCount={1} accept=".xlsx">
            <p className="ant-upload-drag-icon">{/* <InboxOutlined /> */}</p>
            <p className="ant-upload-text">Click or drag file here to upload</p>
            <p className="ant-upload-hint">Accepted .xlsx format.</p>
         </Dragger>

         <div className="extract-data-footer">
            {generalState < 10 ? (
               <Button
                  type="primary"
                  block
                  onClick={(event) => {
                     handleProcess(event);
                  }}
               >
                  Processing
               </Button>
            ) : (
               <Button type="contained" block disabled>
                  Processed
               </Button>
            )}
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={(event) => {
                  handleApply(event);
                  // dispatch(fp_shownRighCol_changeStatus(21));
               }}
            >
               Save Data To System
            </Button>
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={() => {
                  setGeneralState(0);
                  // dispatch(fp_tempDataClear());
                  setTempData([]);
               }}
            >
               Close
            </Button>
         </div>
      </div>
   );
};

export default ImportExcelConcDO;
