import { MANPOWER_ITEMS,MANPOWER_ITEMS_LOADING } from '../../types';

const manpower_data = data => ({
  type: MANPOWER_ITEMS,
  data
});

const manpower_loading = data => ({
  type: MANPOWER_ITEMS_LOADING,
  data
});




export const setManpowerData = data => dispatch => {
  dispatch(manpower_data(data))
}

export const setManpowerLoading = data => dispatch => {
  dispatch(manpower_loading(data))
}

