import React from 'react';
import { Button, Icon, message, notification } from 'antd';
import _ from 'lodash';
import { v4 } from 'uuid';
import axios from 'axios';
import { constants } from '../../moudle_Manpower/constants';
import { timelineData } from '../../moudle_Manpower/function/generateTimeline';
export default function getContextMenuItems(e, props) {
  let params = props;
  var result = [
    {
      name: 'Add rows',
      action: function () {
        let node = params.node;
        if (node) {
          if (node.group) {
            message.warning('Cannot add row in here');
            return;
          }
          let parentNodes = getAllNode(node);
          addRow(params, 1, parentNodes, e, props);
        } else {
          addRow(params, 1, null, e, props);
        }
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    },
    {
      name: 'Delete rows',
      action: function () {
        console.log('params', params);
        console.log('e', e);
        deleteRow(params, e);
      },
      icon: () => {
        return <Icon type='minus' />;
      },
    },
  ];
  if (e.platform === '5D') {
    result.push({
      name: 'Cell history',
      action: function () {
        // console.log(params.api.getSideBar())
        e.setDataEntryOpenHistory({
          openHistoryDialog: { isOpen: true, data: params.node.data },
        });
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });

    //casting report
    result.push({
      name: 'Casting Report',
      action: async function () {
        console.log('run casting report', params.node.data);
        console.log('getContextMenuItems', props);
        let row = params.node.data;
        //get selected Rows
        let selectedPourName = [];
        selectedPourName.push(row['Pour name']);

        let table = e.table;
        let itemIds = JSON.parse(row.itemIds);
        let resList = [];
        itemIds.forEach((v) => {
          let split = v.split(' ');
          const response = axios.post('/api/items/get-item-version', {
            itemId: split[0],
            version: split[1],
            viewGuid: split[2],
          });
          resList.push(response);
        });

        let res = await Promise.all(resList);

        let temp = [];
        _.forEach(res, (v) => {
          let data = v.data;
          let index = _.findIndex(data.revisions, (o) => {
            return o.version === data.versionCurrent;
          });
          if (index >= 0) {
            data.objectId = data.revisions[index].objectId;
            temp.push(data);
          }
        });

        //get first model
        let first = temp[0];
        // let currentRevision = first.revisions.find(x => x.version === first.versionCurrent);
        let currentRevision = first.revisions[first.revisions.length - 1];

        let inputRvtCurrent = `https://developer.api.autodesk.com/oss/v2/buckets/${currentRevision.bucketKey.toLowerCase()}/objects/${
          currentRevision.objectKey
        }`;

        e.setShowCastingReport();

        let dt = {
          selectedPourName,
          current: {
            view2d: row['2D View Name'],
            view3d: row['3D View Name'],
            pourName: row['Pour name'],
            castingDate: row['Actual date finish'],
            formworkAreaDesign: row['Fwk Area Design (m2)'],
            formworkAreaActual: row['Fwk Area Actual (m2)'],
            leanConcreteAreaDesign: row["Lean Con'c Area (m2)"],
            leanConcreteAreaActual: row["Lean Con'c Area Actual (m2)"],
            rebarWeightActual: row['Rebar Weight Actual (kg)'],
            rebarWeightDesign: row['Rebar Weight Design (kg)'],
            concreteVolumeDesign: row["Con'c Volume Design (m3)"],
            concreteVolumeActual: row["Con'c Volume Actual (m3)"],
            status: row['Status'],
            id: row['id'],
            planDateStart: row['Plan date start'],
            planDateFinish: row['Plan date finish'],
            actualDateFinish: row['Actual date finish'],
            actualDateStart: row['Actual date start'],
            castingReportNumber: row.castingReportNumber,
          },
          all: table.map((x) => ({
            view2d: x['2D View Name'],
            view3d: x['3D View Name'],
            pourName: x['Pour name'],
            formworkAreaDesign: x['Fwk Area Design (m2)'],
            formworkAreaActual: x['Fwk Area Actual (m2)'],
            leanConcreteAreaDesign: x["Lean Con'c Area (m2)"],
            leanConcreteAreaActual: x["Lean Con'c Area Actual (m2)"],
            rebarWeightActual: x['Rebar Weight Actual (kg)'],
            rebarWeightDesign: x['Rebar Weight Design (kg)'],
            concreteVolumnDesign: x["Con'c Volume Design (m3)"],
            concreteVolumnActual: x["Con'c Volume Actual (m3)"],
            status: x['Status'],
            id: x['id'],
            planDateStart: x['Plan date start'],
            planDateFinish: x['Plan date finish'],
            actualDateFinish: x['Actual date finish'],
            actualDateStart: x['Actual date start'],
            castingReportNumber: x.castingReportNumber,
          })),
          inputRvtCurrent,
        };

        e.setExportReportData(dt);
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });

    //casting report history

    result.push({
      name: 'Casting Report History',
      action: async function () {
        let row = params.node.data;
        console.log('row', row);
        if (row) {
          e.setShowCastingReportHistory();

          if (_.isArray(row.castingReportHistory)) {
            e.setCurrentCastingReportHistory(
              row.castingReportHistory.reverse()
            );
          } else {
            e.setCurrentCastingReportHistory([]);
          }
        }
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });

    //remove casting report

    result.push({
      name: 'Remove Casting Report',
      action: async function () {
        let row = params.node.data;
        let tempData = _.clone(e.table);
        let rowInTemple = tempData.find((x) => x.id == row.id);

        if (rowInTemple) {
          rowInTemple.castingReportNumber = null;
          rowInTemple['Casting report'] = null;
          console.log('rowInTemple', rowInTemple);
          console.log('tempData', tempData);
          e.setDataEntry([rowInTemple]);
        }

        axios
          .put('/api/casting-report/remove-casting-report/' + row.id)
          .then((r) => {
            notification.success({
              message: 'Info!',
              description: 'Remove Casting Report Successfully !',
              duration: 5,
            });
          });
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });

    // open model

    result.push({
      name: 'Open model',
      action: async function () {
        let row = params.node.data;
        e.handleOpenModelInRow(row);
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });

    //select model elements
    result.push({
      name: 'Select Elements in Model',
      action: async function () {
        let row = params.node.data;
        console.log('select obj', row);
        e.handleSelectObjectInRow([row]);
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });
  } else if (e.platform === 'manpower') {
    result.push({
      name: 'Edit multi-value',
      action: function () {
        e.setManpowerData({
          openEditMultiValuesDialog: { isOpen: true, node: params.node },
        });
      },
      icon: () => {
        return <Icon type='plus' />;
      },
    });
  }

  result.push('separator', 'copy', 'chartRange', 'export');

  if (params.platform === '5D') {
    if (params.node.data.compareObject) {
      let data = params.node.data.compareObject[params.column.colId];
      if (data) {
        result.splice(0, 0, 'separator');
        result.splice(0, 0, {
          name: `Old Value: ${data.value}`,
        });
      }
    }
  }
  return result;
}

function createRow(params, parentNodes, obj, props) {
  console.log('params', params);
  console.log('obj', obj);
  let columns = params.api.getColumnDefs();
  let data = { id: v4() };
  if (obj.platform === '5D') {
    console.log('props', props);
    data['projectId'] = obj.projectId;
    data['sheetId'] = obj.sheetId;
  } else {
  }
  _.forEach(columns, (v) => {
    if (v.type === 'numericColumn') {
      data[v.field] = 0;
    } else {
      data[v.field] = '';
    }
  });
  if (parentNodes) {
    _.forEach(parentNodes, (item) => {
      _.forEach(item, (v, k) => {
        data[k] = v;
      });
    });
  }
  return data;
}
function addRow(params, num, parentNodes, obj, props) {
  let cellRange = params.api.getCellRanges();
  let number = 1;
  if (obj.platform === '5D') {
    let text = window.prompt('How many rows you want to add', '1');
    let parse = parseFloat(text);
    if (_.isNumber(parse)) {
      number = parse;
    } else {
      return;
    }
  }

  let add = [];
  let start = 1;
  while (start <= number) {
    add.push(createRow(params, parentNodes, obj, props));
    start++;
  }
  if (obj.platform === 'manpower') {
    obj.setManpowerData({ loading: true });
    let data = {};
    _.forEach(constants.defaultColumns, (v) => {
      data['updatedBy'] = props.userInfo.email;
      if (v === 'id') data[v] = v4();
      else data[v] = '';
    });
    obj.setManpowerData({ loading: true });
    axios
      .post('/api/manpower/add-user', { data, token: obj.token })
      .then((res) => {
        let currentData = res.data.user;
        let newData = {
          userId: currentData.userId,
          id: currentData.id,
          client: currentData.client,
          lead: currentData.lead,
          status: currentData.status,
          name: currentData.name,
          scope: currentData.scope,
          updatedBy: currentData.updatedBy,
          updatedAt: currentData.updatedAt,
          jobTitle: currentData.jobTitle,
          department: currentData.department,
          timelines: JSON.stringify(res.data.timelines),
        };
        timelineData(newData, res.data.timelines, props.typeTimeline);
        params.api.applyTransactionAsync(
          {
            add: [newData],
            addIndex:
              cellRange.length === 0
                ? null
                : cellRange[0].endRow.rowIndex + num,
          },
          (e) => {
            obj.setManpowerData({ loading: false });
          }
        );
      })
      .catch((err) => {
        obj.setManpowerData({ loading: false });
      });
  } else {
    params.api.applyTransactionAsync(
      {
        add,
        addIndex:
          cellRange.length === 0 ? null : cellRange[0].endRow.rowIndex + num,
      },
      () => {
        // obj.setManpowerData({ loading: false });
      }
    );
  }
}

function deleteRow(params, obj) {
  if (!window.confirm('Are you want to delete?')) return;
  if (obj.platform === 'manpower') {
    obj.setManpowerData({ loading: true });
    let cells = params.api.getCellRanges();
    let listIndex = [];
    let listItem = [];
    _.forEach(cells, (cell) => {
      let start = cell.startRow.rowIndex;
      while (start <= cell.endRow.rowIndex) {
        let item = params.api.rowModel.getRow(start);
        listItem.push(item.data);
        listIndex.push(item.data.id);
        start++;
      }
    });
    axios
      .delete('/api/manpower/delete-users', {
        data: { token: obj.token, userIds: listIndex },
      })
      .then((res) => {
        params.api.applyTransactionAsync({ remove: listItem });
        obj.setManpowerData({ loading: false });
      })
      .catch((err) => {
        obj.setManpowerData({ loading: false });
      });
  } else {
    let selectedData = params.api.getSelectedRows();
    console.log('selectedData', selectedData);
    selectedData.forEach((item) => {
      item.row_status = 'delete';
      window.historySheet.push(item);
    });

    params.api.applyTransactionAsync({ remove: selectedData });
  }
}
function getAllNode(node) {
  try {
    var temp = [];
    var queue = [];
    queue.push(node);
    while (queue.length > 0) {
      var node = queue.shift();
      if (node.parent && node.parent.id !== 'ROOT_NODE_ID') {
        temp.push({ [node.parent.field]: node.parent.key });
        queue.push(node.parent);
      }
    }
    return temp;
  } catch {}
}
