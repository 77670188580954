import React, { useRef, useState, useEffect, useCallback, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReact } from "ag-grid-react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Button, Modal, Loader, InputPicker, Input, DatePicker, InputNumber, DateRangePicker } from "rsuite";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { CellContextmenu, LoadingScreen } from "../../module_DataEntry/customCell";
import ManPowerToolbar from "../../moudle_Manpower/ManPowerToolbar";
import _ from "lodash";
import { setManpowerData, setManpowerLoading } from "../../../actions/app/manpower";
import DialogEditMultiValues from "../../moudle_Manpower/dialog/DialogEditMultiValues";
import { constants } from "../../moudle_Manpower/constants";
import { timelineCalc, timelineData } from "../../moudle_Manpower/function/generateTimeline";
import { v4 } from "uuid";
import DialogEditValue from "../../moudle_Manpower/dialog/DialogEditValue";
import axios from "axios";
import CellManPowerWeek from "../../moudle_Manpower/customCell/CellManPowerWeek";
import CellInputPicker from "../../moudle_Manpower/customCell/CellInputPicker";
import CellManPowerMonth from "../../moudle_Manpower/customCell/CellManPowerMonth";
import CellAutoComplete from "../../moudle_Manpower/customCell/CellAutoComplete";
import { message } from "antd";
let check = false;
let gridApi = null;
function ManPower(props) {
   const gridRef = useRef();
   const defaultCol = [
      {
         field: "userId",
         width: 110,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         editable: true,
         sort: "asc",
         pinned: 'left'
      },
      {
         field: "client",
         width: 90,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         cellEditorSelector: cellEditorSelector,
         pinned: 'left'
      },
      {
         field: "lead",
         width: 90,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         cellEditorSelector: cellEditorSelector,
         pinned: 'left'
      },
      {
         field: "status",
         width: 80,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         cellEditorSelector: cellEditorSelector,
         pinned: 'left'
      },
      { field: "name", width: 80, enablePivot: true, lockVisible: true, lockPosition: true, pinned: 'left' },
      { field: "scope", width: 80, enablePivot: true, lockVisible: true, lockPosition: true, pinned: 'left' },
      {
         field: "updatedBy",
         width: 110,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         editable: false,
         suppressPaste: true,
         pinned: 'left', 
         hide: true
      },
      {
         field: "updatedAt",
         width: 110,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         editable: false,
         suppressPaste: true,
         pinned: 'left',
         hide: true
      },
      {
         field: "jobTitle",
         width: 120,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         cellEditorSelector: cellEditorSelector,
         pinned: 'left'
      },
      {
         field: "department",
         width: 120,
         enablePivot: true,
         lockVisible: true,
         lockPosition: true,
         cellEditorSelector: cellEditorSelector,
         pinned: 'left'
      },
   ];
   const [columnDefs, setColumnDefs] = useState([]);
   const [isOpenEditCell, setIsOpenEditCell] = useState();
   const [isOpenEditCellId, setIsOpenEditCellId] = useState();
   const [currentCell, setCurrentCell] = useState();
   const [listValues, setListValues] = useState({
      status: [
         { label: "Active", key: "Active", value: "Active" },
         { label: "Resigned", key: "Resigned", value: "Resigned" },
         { label: "Pipeline", key: "Pipeline", value: "Pipeline" },
         { label: "", key: "", value: "" },
      ],
      jobTitle: [
         { label: "Architectural Coordinator", key: "Architectural Coordinator", value: "Architectural Coordinator" },
         {
            label: "Principal Architectural Coordinator",
            key: "Principal Architectural Coordinator",
            value: "Principal Architectural Coordinator",
         },
         { label: "Facade Coordinator", key: "Facade Coordinator", value: "Facade Coordinator" },
         { label: "Senior BIM Modeller", key: "Senior BIM Modeller", value: "Senior BIM Modeller" },
         { label: "BIM Specialist", key: "BIM Specialist", value: "BIM Specialist" },
         { label: "BIM Modeller", key: "BIM Modeller", value: "BIM Modeller" },
      ],
      department: [
         { label: "Archi Technical", key: "Archi Technical", value: "Archi Technical" },
         { label: "Innovation", key: "Innovation", value: "Innovation" },
         { label: "Innovation - Archi", key: "Innovation - Archi", value: "Innovation - Archi" },
      ],
   });
   useEffect(() => {
      props.setManpowerData({ loading: true });
      window.timelineDateLocal = null;
      window.typeTimelineLocal = null;
      loadData();
      window.onbeforeunload = () => {
         window.onbeforeunload = null;
         if (props.userInfo) {
            let columnState = JSON.stringify(gridApi.columnApi.getColumnState());
            let data = {
               email: props.userInfo.email,
               token: props.userInfo.token,
               setting: {
                  timeline: window.timelineDateLocal ? window.timelineDateLocal : null,
                  typeTimeline: window.typeTimelineLocal ? window.typeTimelineLocal : "week",
                  columnState,
               },
            };
            axios
               .post("/api/manpower/save-user-setting", { ...data })
               .then((res) => {
                  console.log("save setting");
               })
               .catch((err) => {
                  console.log(err);
                  message.warning("Cannot save user setting");
               });
         }
         // return "Any string value here forces a dialog box to \n" +
         // "appear before closing the window.";
      };
      return () => {
         if (props.userInfo) {
            let columnState = JSON.stringify(gridApi.columnApi.getColumnState());
            let data = {
               email: props.userInfo.email,
               token: props.userInfo.token,
               setting: {
                  timeline: window.timelineDateLocal ? window.timelineDateLocal : null,
                  typeTimeline: window.typeTimelineLocal ? window.typeTimelineLocal : "week",
                  columnState,
               },
            };
            window.onbeforeunload = null;
            axios
               .post("/api/manpower/save-user-setting", { ...data })
               .then((res) => {
                  console.log("save setting");
               })
               .catch((err) => {
                  console.log(err);
                  message.warning("Cannot save user setting");
               });
         }
      };
   }, []);

   const loadData = async () => {
      try {
         let settingRes = await axios.get("/api/manpower/get-user-setting", {
            params: { token: props.userInfo.token },
         });
         let setting = settingRes.data;
         let typeTimeline = setting.typeTimeline ? setting.typeTimeline : "week";
         let timeline = setting.timeline
            ? setting.timeline
            : [moment(Date.now()).startOf("month"), moment(Date.now()).endOf("month")];
         let start = timeline[0] ? moment(new Date(timeline[0])) : moment(Date.now()).startOf("month");
         let end = timeline[1] ? moment(new Date(timeline[1])) : moment(Date.now()).endOf("month");
         let format =
            typeTimeline === "month"
               ? constants.monthFormat
               : typeTimeline === "year"
                  ? constants.yearFormat
                  : typeTimeline === "day"
                     ? constants.dayFormat
                     : constants.weekFormat;
         let groupFormat =
            typeTimeline === "month"
               ? constants.yearFormat
               : typeTimeline === "year"
                  ? constants.yearFormat
                  : typeTimeline === "day"
                     ? constants.monthFormat
                     : constants.monthFormat;
         let temp = timelineCalc(start, end, typeTimeline, format, groupFormat);
         let timelineDate = [start.toDate(), end.toDate()];
         props.setManpowerData({
            timelineDate,
            typeTimeline,
         });
         setColumnDefs(defaultCol.concat(temp).slice(0));
         let columnState = setting.columnState ? JSON.parse(setting.columnState) : null;
         let tempColumnState = [];
         if (columnState !== null) {
            _.forEach(columnState, (v) => {
               if (constants.defaultColumns.includes(v.colId)) {
                  if(props.isAdmin){
                     v.hide = false
                  }else{
                     v.hide = true
                  }
                  v.pinned = v.pinned ?v.pinned :'left'
                  tempColumnState.push(v);
               }
            });
         }
         console.log(tempColumnState)
         if (gridApi) gridApi.columnApi.setColumnState(tempColumnState);

         let res = await axios.get("/api/manpower", { params: { token: props.userInfo.token } });
         let data = [];
         let initTimeline = {
            startDateTotal: null,
            endDateTotal: null,
         };
         let client = [];
         let lead = [];
         let jobTitle = [];
         let department = [];
         _.forEach(res.data, (i) => {
            if (!client.includes(i.client)) client.push(i.client);
            if (!lead.includes(i.client)) lead.push(i.lead);
            if (!jobTitle.includes(i.client)) jobTitle.push(i.jobTitle);
            if (!department.includes(i.department)) department.push(i.department);
            let newData = {
               userId: i.userId ? i.userId : "",
               id: i.id,
               client: i.client,
               lead: i.lead,
               status: i.status,
               name: i.name,
               scope: i.scope,
               updatedBy: i.updatedBy,
               updatedAt: i.updatedAt ? moment(Date.now(i.updatedAt)).format("DD/MM/YY HH:mm") : "",
               jobTitle: i.jobTitle,
               department: i.department,
               timelines: JSON.stringify(i.timelines),
            };
            timelineData(newData, i.timelines, typeTimeline, initTimeline, true);
            data.push(newData);
         });
         props.setManpowerData({
            data,
            loading: false,
            client,
            lead,
            jobTitle,
            department,
         });
         check = true;
      } catch {
         props.setManpowerData({ loading: false });
      }
   };
   useEffect(() => {
      // let data = localStorage.getItem('whapp-mdp')
      // if (data !== typeof (Object)) {
      //   data = {}
      // }
      // data.timelineDateLocal = props.timelineDate
      // data.typeTimelineLocal = props.typeTimeline
      // localStorage.setItem('whapp-mdp', JSON.stringify(data))
      window.timelineDateLocal = props.timelineDate;
      window.typeTimelineLocal = props.typeTimeline;
   }, [props.timelineDate, props.typeTimeline]);
   const displayedColumnsChanged = (e) => {
      // let data = localStorage.getItem('whapp-mdp')
      // if (data !== typeof (Object)) {
      //   let tempData = JSON.parse(data)
      //   tempData.columnState = JSON.stringify(tempData)
      // }
      // localStorage.setItem('whapp-mdp', data)
   };
   const onGridReady = (params) => {
      // this.gridApi = params.api;
      // this.gridColumnApi = params.columnApi;
      gridApi = params;
      props.setManpowerData({ gridApi: { api: params.api, columnApi: params.columnApi } });
   }
   //  useEffect(() => {
   //     if (gridRef.current) {
   //        gridApi = gridRef.current;
   //        props.setManpowerData({ gridApi: gridRef.current });
   //     }
   //  }, [gridRef.current]);
   useEffect(() => {
      if (props.gridApi) {
         props.gridApi.columnApi.applyColumnState({
            state: [
               { colId: "client", pinned: "left" },
               { colId: "lead", pinned: "left" },
               { colId: "status", pinned: "left" },
               { colId: "name", pinned: "left" },
               { colId: "scope", pinned: "left" },
               { colId: "updatedBy", pinned: "left" },
               { colId: "updatedAt", pinned: "left" },
               { colId: "jobTitle", pinned: "left" },
               { colId: "department", pinned: "left" },
            ],
         });
      }
   }, [props.gridApi]);

   useEffect(() => {
      if (props.gridApi) {
         try {
            if (props.manpowerLoading) props.gridApi.api.showLoadingOverlay();
            else props.gridApi.api.hideOverlay();
         } catch { }
      }
   }, [props.manpowerLoading, props.gridApi]);

   useEffect(() => {
      if (!check) return;
      if (props.timelineDate.length !== 0 && defaultCol.length !== 0) {
         if (props.gridApi) props.setManpowerData({ loading: true });
         props.gridApi.api.clearRangeSelection();
         let temp = timelineCalc(
            moment(props.timelineDate[0]),
            moment(props.timelineDate[1]),
            props.typeTimeline,
            props.manpower.format,
            props.manpower.groupFormat
         );
         setColumnDefs(defaultCol.concat(temp).slice(0));

         let rowData = [];
         props.gridApi.api.forEachLeafNode((node) => rowData.push(node.data));
         let tempData = rowData.length !== 0 ? rowData : props.manpowerData;
         let data = [];
         _.forEach(tempData, (i) => {
            let newData = {
               userId: i.userId,
               id: i.id,
               client: i.client,
               lead: i.lead,
               status: i.status,
               name: i.name,
               scope: i.scope,
               updatedBy: i.updatedBy,
               updatedAt: i.updatedAt,
               jobTitle: i.jobTitle,
               department: i.department,
               timelines: i.timelines,
            };
            timelineData(newData, JSON.parse(i.timelines), props.typeTimeline);
            data.push(newData);
         });
         props.setManpowerData({ data, loading: false });
      }
   }, [props.timelineDate, props.typeTimeline]);

   const onCellDoubleClicked = (e) => {
      // if (e.column.colDef.field === 'userId') {
      //   setCurrentCell(e)
      //   setIsOpenEditCellId(true)
      // }
   };
   const onCellValueChanged = (e) => {
      if (e.column.colId !== "updatedAt" && e.column.colId !== "updatedBy") {
         e.node.data["updatedAt"] = moment(Date.now()).format("DD/MM/YY HH:mm");
         e.node.data["updatedBy"] = props.userInfo.email;
         e.node.data["isUpdate"] = true;
         e.node.setData(e.node.data);
         // e.node.setDataValue('updatedAt', moment(Date.now()).format('DD/MM/YY HH:mm'))
         // e.node.setDataValue('updatedBy', props.userInfo.email)
      }
      if (["client", "lead", "jobTitle", "department"].includes(e.column.colId)) {
         let temp = props.manpower[e.column.colId].slice(0);
         if (!temp.includes(e.newValue)) {
            temp.push(e.newValue);
         }
         props.setManpowerData({
            [e.column.colId]: temp,
         });
      }
   };
   const callbackDialog = () => {
      setIsOpenEditCell(false);
      setIsOpenEditCellId(false);
   };
   function cellEditorSelector(params) {
      if (["status"].includes(params.column.colId)) {
         return { component: "manCellInputPicker" };
      } else if (["client", "lead", "jobTitle", "department"].includes(params.column.colId)) {
         return { component: "manCellAutoComplete" };
      }
      return undefined;
   }
   const defaultColDef = useMemo(
      () => ({
         sortable: true,
         filter: true,
         minWidth: 25,
         editable: true,
         resizable: true,
         enableRowGroup: true,
         suppressMovable: true,
      }),
      []
   );
   return (
      <>
         <div style={{ height: "calc(100% - 96px)", width: "100%", position: "absolute" }}>
            <ManPowerToolbar />
            <AgGridReact
               ref={gridRef}
               className="ag-theme-balham"
               reactUi="true"
               multiSortKey="ctrl"
               onDisplayedColumnsChanged={displayedColumnsChanged}
               onSortChanged={displayedColumnsChanged}
               // statusBar={statusBar}
               // rowDragManaged={true}
               rowData={props.manpowerData}
               modules={AllModules}
               columnDefs={columnDefs}
               defaultColDef={defaultColDef}
               // autoGroupColumnDef={autoGroupColumnDef}
               rowGroupPanelShow="always"
               enableRangeSelection="true"
               loadingOverlayComponentFramework={LoadingScreen}
               // rowSelection="multiple"
               frameworkComponents={{
                  manPowerCellWeek: CellManPowerWeek,
                  manPowerCellMonth: CellManPowerMonth,
                  manCellInputPicker: CellInputPicker.bind(this, listValues),
                  manCellAutoComplete: CellAutoComplete,
               }}
               fillOperation={(e) => {
                  if (e.column.colDef.isDialogEdit) {
                     e.rowNode.setDataValue(e.column.colId, e.initialValues[0]);
                  }
                  return e.values[e.values.length - 1];
               }}
               // enableFillHandle
               allowContextMenuWithControlKey={true}
               getContextMenuItems={CellContextmenu.bind(
                  this,
                  { setManpowerData: props.setManpowerData, token: props.userInfo.token, platform: "manpower" },
                  props
               )}
               // onCellValueChanged={onCellValueChanged}
               // onRangeSelectionChanged={onRangeSelectionChanged}
               groupDisplayType="groupRows"
               onCellDoubleClicked={onCellDoubleClicked}
               onCellValueChanged={onCellValueChanged}
               onGridReady={onGridReady}

            />
         </div>
         {isOpenEditCell && <DialogEditValue cell={currentCell} callbackDialog={callbackDialog} />}
         {props.openEditMultiValuesDialog.isOpen && <DialogEditMultiValues />}
         {isOpenEditCellId && <DialogEditValue cell={currentCell} callbackDialog={callbackDialog} />}
      </>
   );
}
ManPower.propTypes = {
   history: PropTypes.shape({
      push: PropTypes.func.isRequired,
   }).isRequired,
   isAdmin: PropTypes.bool.isRequired,
   isConfirmed: PropTypes.bool.isRequired,
   userInfo: PropTypes.object.isRequired,
   userRole: PropTypes.string.isRequired,
   setManpowerData: PropTypes.func.isRequired,
   setManpowerLoading: PropTypes.func.isRequired,
   manpowerLoading: PropTypes.bool.isRequired,
   manpowerData: PropTypes.func.isRequired,
   selectedNode: PropTypes.object.isRequired,
   gridApi: PropTypes.object.isRequired,
   typeTimeline: PropTypes.object.isRequired,
   openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
   return {
      isAdmin: state.user.email === "admin@wohhup.com",
      isConfirmed: !!state.user.confirmed,
      userInfo: state.user,
      userRole: state.user.role,
      manpowerLoading: state.manpower.loading,
      manpowerData: state.manpower.data,
      gridApi: state.manpower.gridApi,
      selectedNode: state.manpower.selectedNode,
      timelineDate: state.manpower.timelineDate,
      typeTimeline: state.manpower.typeTimeline,
      openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
      manpower: state.manpower,
   };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(ManPower);
