/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Upload, Progress, Divider, message } from "antd";
import axios from "axios";
import _ from "lodash";

const FormData = require("form-data");

const { Dragger } = Upload;

const ExtractDataConcInv = ({ tempData, generalState, setGeneralState, setTempData }) => {
   const [listFiles, setlistFiles] = useState([]);
   const [percent, setPercent] = useState(0);
   const [status, setStatus] = useState();

   const verify_Data = (listDO) => {
      var listTemp = [];

      var temp = _.groupBy(listDO, (o) => o.do_number.length);

      const countDOlLength = Object.values(temp);
      if (countDOlLength.length > 1) {
         for (let i = 0; i < countDOlLength.length - 1; i++) {
            const dos = countDOlLength[i];
            dos.forEach((e) => {
               var checks = "do name ";
               if (isNaN(e.unit_price)) {
                  checks = checks + "unit price ";
               }

               if (isNaN(e.amount_claim)) {
                  checks = checks + "amount ";
               }

               if (isNaN(e.volume)) {
                  checks = checks + "volume";
               }
               e.checks = checks;
               listTemp.push(e);
            });
         }
         const lastDOs = countDOlLength[countDOlLength.length - 1];
         if (lastDOs !== undefined) {
            lastDOs.forEach((e) => {
               var checks = "";
               if (isNaN(e.unit_price)) {
                  checks = checks + "unit price ";
               }

               if (isNaN(e.amount_claim)) {
                  checks = checks + "amount ";
               }

               if (isNaN(e.volume)) {
                  checks = checks + "volume";
               }

               if (checks.length < 1) {
                  checks = "ok";
               }
               e.checks = checks;
               listTemp.push(e);
            });
         }
      } else {
         listDO.forEach((e) => {
            var checks = "";
            if (isNaN(e.unit_price)) {
               checks = checks + "unit price ";
            } else {
               if (e.unit_price < 0.5) {
                  checks = checks + "unit price ";
               }
            }

            if (isNaN(e.amount_claim)) {
               checks = checks + "amount ";
            } else {
               if (e.amount_claim < 0.5) {
                  checks = checks + "amount ";
               }
            }

            if (isNaN(e.volume)) {
               checks = checks + "volume";
            } else {
               if (e.volume < 0.05) {
                  checks = checks + "volume";
               }
            }

            if (checks.length < 1) {
               checks = "ok";
            }
            e.checks = checks;
            listTemp.push(e);
         });
      }
      return listTemp;
   };

   const func_Process_Conc_Inv = async () => {
      const projectId = localStorage.getItem("fp_project_id");
      var progress = 10;
      const increase = 90 / listFiles.length;
      setStatus("active");
      setPercent(10);
      const files = listFiles.map(async (file) => {
         let key_file = file.name;

         let formData = new FormData();
         return new Promise((data, err1) => {
            formData.append("form_file", file.originFileObj, key_file);
            let url = "/api/do_inv/formparser/concinv";
            axios
               .post(url, formData, {
                  headers: {
                     "content-type": "multipart/form-data",
                  },
               })
               .then((re) => {
                  let listDOs = verify_Data(re.data.data.Items);

                  //CHECKING IF DO ALREADY EXIST IN THE SYSTEM
                  // get the list of DO no
                  let DOs = listDOs.map((a) => a.do_number);
                  // check if element exist
                  axios
                     .post("/api/do_inv/common/check_exist_multi", {
                        list_DO: JSON.stringify(DOs),
                        current: "conc_inv",
                        projectId: projectId,
                     })
                     .then((res) => {
                        if (typeof res.data.data.items !== "undefined" && res.data.message !== "There is new DO!") {
                           let founded_do = res.data.data.items.map((a) => a.do_number);
                           //map back to list tempdata
                           listDOs.forEach((element) => {
                              if (founded_do.includes(String(element.do_number))) {
                                 if (element.checks === "ok") {
                                    element.checks = "exist";
                                 } else {
                                    element.checks = element.checks + " exist";
                                 }
                              }
                           });
                        }
                        //update state and redux data
                        setTempData(listDOs);
                     });

                  progress = progress + increase;
                  setPercent(progress);
                  if (Math.abs(100 - progress) < 1) {
                     setStatus("success");
                  }
               });
         });
      });
      await Promise.all(files);
   };
   const func_Apply_Conc_Inv = async () => {
      // apply data to RDS
      const uploadEnt = await axios.post("/api/do_inv/common/multi", { data: tempData, current: "conc_inv" });

      // upload file to S3
      const files = listFiles.map(async (file) => {
         return new Promise((data, err1) => {
            axios
               .get("/api/do_inv/s3/putlink", {
                  params: { fileName: file.name, formType: "concinv" },
               })
               .then((res) => {
                  const signedrequesturl = res.data.signedRequest;
                  axios
                     .put(signedrequesturl, file.originFileObj, {
                        headers: {
                           "Content-Type": "application/pdf",
                        },
                     })
                     .then((result) => {})
                     .catch((err) => {
                        console.log("got error", err);
                     });
               });
         });
      });

      let resulting_files = await Promise.all(files);
   };

   const handleProcess = async (e) => {
      e.preventDefault();

      var msg = "";
      if (listFiles.length > 0) {
         msg = func_Process_Conc_Inv();

         if (msg !== "") {
            // alert(msg);
         }
      } else {
         alert("Ay oh, select file first lah!");
      }
   };

   const handleApply = async (e) => {
      e.preventDefault();

      if (tempData.length > 0) {
         const checkIfDataCorrect = tempData.find((e) => {
            e.checks.includes("amount") || e.checks.includes("unit") || e.checks.includes("volume");
         });

         if (checkIfDataCorrect !== undefined) {
            message.error("Cannot save, you have to correct all data first.");
         } else {
            func_Apply_Conc_Inv();
            setGeneralState(21);
         }
      } else {
         alert("Ay oh, nothing to save!");
      }
   };

   const handleClose = () => {
      setGeneralState(0);
      setTempData([]);
   };

   const props = {
      multiple: true,
      onChange(info) {
         let listFiles = [...info.fileList];

         setlistFiles(listFiles);
      },

      onRemove: (file) => {
         const index = listFiles.indexOf(file);
         const newFileList = listFiles.slice();
         newFileList.splice(index, 1);

         return setlistFiles(newFileList);
      },
      beforeUpload: (file) => {
         setlistFiles([...listFiles, file]);
         return false;
      },
      listFiles,
   };

   return (
      <div className="extract-data-form">
         <h5 className="text-ontop-menu"> Con'c Invoice Pdf</h5>

         <Dragger {...props}>
            <p className="ant-upload-drag-icon">{/* <InboxOutlined /> */}</p>
            <p className="ant-upload-text">Click or drag file here to upload</p>
            <p className="ant-upload-hint">Accepted .pdf format.</p>
         </Dragger>

         <div className="extract-data-footer">
            <a style={{ color: "#D5E2F0" }}>Progress</a>
            <Progress percent={percent} showInfo={false} status={status} />
            <br />
            <Divider />

            {generalState < 2 ? (
               <Button
                  type="primary"
                  block
                  onClick={(event) => {
                     handleProcess(event);
                  }}
               >
                  Processing
               </Button>
            ) : (
               <Button type="contained" block disabled>
                  Processed
               </Button>
            )}
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={(event) => {
                  handleApply(event);
               }}
            >
               Save Data To System
            </Button>
            <br />

            <br />
            <Button block type="default" onClick={() => handleClose()}>
               Close
            </Button>
         </div>
      </div>
   );
};

export default ExtractDataConcInv;
