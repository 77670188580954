import React from 'react';
import { Pie, defaults } from 'react-chartjs-2';
import _ from 'lodash'
import 'chartjs-plugin-datalabels';
import axios from "axios";
import moment from 'moment'
import Draggable from 'react-draggable';
import { Empty, Tag, message } from 'antd';
import { Loader } from 'rsuite';
import { getAllElementdbIdsOneModel }from '../../../function/ForgeFunction';
import { convertHexColorToVector4 } from '../../../function/TableFunction'
import {handleModulePPVCAndPrecast} from '../../../function/ProgressFunction'
import { trackingUserUsing } from '../../../function/AdminFunction'
defaults.global.defaultFontColor = '#fff'

class PieChartPPVC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeValue: {
        min: null,
        max: null,
      },
      openDock: false,
      loading: false,
      dataPPVC: [],
      generatedData: null
    };
    this.smartSheetData = []
    this.moduleData = null
    this.modelData = {
      dbIds: [],
      data: []
    }

    this.option1 = {
      legend: {
        position: 'bottom',
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let total = meta.total;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat((currentValue / total * 100).toFixed(1));
            let label = data.labels[tooltipItem.index]
            let tooltipText = [`Total: ${currentValue}/${total} (${percentage}%)`]
            let temp = {}
            let tempData = {}
            if (label !== 'Balance') {
              _.forEach(this.state.dataPPVC, v => {
                let code = v.mark.split('-')[0]
                if (v.infoData.status !== "" && v.modifiedDate !== null) {
                  if (this.state.timeValue.min.valueOf() <= v.modifiedDate.getTime() && this.state.timeValue.max.valueOf() >= v.modifiedDate.getTime()) {
                    if (v.status.includes(label)) {
                      if (!temp[code])
                        temp[code] = []
                      temp[code].push(v.dbId)
                    }
                  }
                }
                if (!tempData[code])
                  tempData[code] = 0
                tempData[code] = tempData[code] + 1
              })
              _.forEach(temp, (v, k) => {
                let percentage = parseFloat((v.length / total * 100).toFixed(1));
                tooltipText.push(`${k}: ${v.length}/${tempData[k]} (${percentage}%)`)
              })
            }
            return tooltipText
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    }
  }
  componentWillMount = () => {
    trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'PieChart PPVC')
    this.getModules()
  }
  getModules = () => {
    this.setState({ loading: true, openDock: this.props.openDock })
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          this.moduleData = res.data.result;
          this.getModelData()
          //this.getSmartSheetData()
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }
  getSmartSheetData = () => {
    axios.post("/api/smartsheet/getsheet", { smartSheetId: this.props.smartSheetId })
      .then(res => {
        this.smartSheetData = []
        _.forEach(res.data, row => {
          let rowContent = {
            fromDate: null,
            toDate: null,
            castingValue: 0,
            fitOutDelivered: 0,
            fitOutCompleted: 0,
            siteDelivered: 0,
            siteCompleted: 0,
          }
          if (row.cells[3] && row.cells[4]) {
            let fromDateString = row.cells[3].value
            let toDateString = row.cells[4].value
            if (fromDateString && fromDateString.length > 0) {
              let fromDate = moment(fromDateString, 'YYYY-MM-DD').toDate()
              if (toDateString && toDateString.length > 0) {
                let toDate = moment(toDateString, 'YYYY-MM-DD').toDate()
                rowContent.fromDate = fromDate;
                rowContent.toDate = toDate;

                let valueString = row.cells[5].value;
                if (valueString) {
                  rowContent.castingValue = valueString;
                }
                valueString = row.cells[6].value;
                if (valueString) {
                  rowContent.fitOutDelivered = valueString;
                }
                valueString = row.cells[7].value;
                if (valueString) {
                  rowContent.fitOutCompleted = valueString;
                }
                valueString = row.cells[8].value;
                if (valueString) {
                  rowContent.siteDelivered = valueString;
                }
                valueString = row.cells[9].value;
                if (valueString) {
                  rowContent.siteCompleted = valueString;
                }
                this.smartSheetData.push(rowContent);
              }
            }
          }
        })
        this.getModelData()
      })
  }
  getModelData = async () => {
    this.modelData.dbIds = getAllElementdbIdsOneModel(this.props.viewer);
    if (this.modelData.dbIds && this.modelData.dbIds.length > 0) {
      let count = this.modelData.dbIds.length
      _.forEach(this.modelData.dbIds, id => {
        this.props.viewer.model.getProperties(id, (modelAProperty) => {
          _.forEach(modelAProperty.properties, (v, k) => {
            if (v.displayName === 'Mark') {
              if (v.displayValue !== '')
                this.modelData.data.push({ dbId: id, mark: v.displayValue });
            }
          })
          count--
          if (count === 0) {
            this.combineData()
          }
        })

      })
    }
  }
  combineData = () => {
    for (const item of this.moduleData) {
      let modelItem = this.modelData.data.find(x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        item.dbId = modelItem.dbId;
      } else {
        item.dbId = ''
      }
    };
    this.analyzeMainData();
  }
  analyzeMainData = () => {
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(this.moduleData, this.props.typeProject, this.props.projectKey);
    this.setState(
      {
        loading: false,
        timeValue: {
          min: moment(actionMinDate.getTime()).startOf('months'),
          max: moment(actionMaxDate.getTime()).endOf('months'),
        },
        dataPPVC: dataPPVC
      }
      , () => {
        this.generatePieChartData()
      })
  }
  componentWillReceiveProps = (nextProps) => {

  }

  generatePieChartData = () => {
    let allDataCount = this.state.dataPPVC.length;
    let castedCount = 0
    let deliveredCount = 0
    let installedCount = 0
    let deliveredToFitoutCount = 0
    let completedCount = 0


    let colorCasted = convertHexColorToVector4("#1890ff")
    let colorDelivered = convertHexColorToVector4("#f39c12")
    let colorInstalled = convertHexColorToVector4("#722ed1")
    let colorDeliveredToFitout = convertHexColorToVector4("#52c41a")
    let colorCompletedFitout = convertHexColorToVector4("#fadb14")

    _.forEach(this.state.dataPPVC, v => {
      if (v.status.includes('Installed on Site')) {

        installedCount++
      }
      if (v.status.includes('Delivered to Site')) {

        deliveredCount++
      }
      if (v.status.includes('Fit Out Completed')) {

        completedCount++
      }
      if (v.status.includes('Delivered to Fit-out Yard')) {

        deliveredToFitoutCount++
      }
      if (v.status.includes('Casting Completed')) {

        castedCount++
      }
      if (v.infoData.status === 'Casting Completed')
        this.props.viewer.setThemingColor(v.dbId, colorCasted)
      else if (v.infoData.status === 'Delivered to Fit-out Yard')
        this.props.viewer.setThemingColor(v.dbId, colorDeliveredToFitout)
      else if (v.infoData.status === 'Fit Out Completed')
        this.props.viewer.setThemingColor(v.dbId, colorCompletedFitout)
      else if (v.infoData.status === 'Delivered to Site')
        this.props.viewer.setThemingColor(v.dbId, colorDelivered)
      else if (v.infoData.status === 'Installed on Site')
        this.props.viewer.setThemingColor(v.dbId, colorInstalled)
    })

    let pieChartData = {
      casted: {
        data: {
          labels: [
            'Casting Completed',
            'Balance',
          ],
          datasets: [{
            data: [castedCount, allDataCount - castedCount],
            backgroundColor: [
              '#1890ff',
              '#999999'
            ],
            hoverBackgroundColor: [
              '#1890ff',
              '#999999'
            ]
          }]
        }
      },

      deliveredToFitoutYard: {
        data: {
          labels: [
            'Delivered to Fit-out Yard',
            'Balance',
          ],
          datasets: [{
            data: [deliveredToFitoutCount, allDataCount - deliveredToFitoutCount],
            backgroundColor: [
              '#52c41a',
              '#999999'
            ],
            hoverBackgroundColor: [
              '#52c41a',
              '#999999'
            ]
          }]
        }
      },

      completedToFitout: {
        data: {
          labels: [
            'Fit Out Completed',
            'Balance',
          ],
          datasets: [{
            data: [completedCount, allDataCount - completedCount],
            backgroundColor: [
              '#fadb14',
              '#999999'
            ],
            hoverBackgroundColor: [
              '#fadb14',
              '#999999'
            ]
          }]
        }
      },

      delivered: {
        data: {
          labels: [
            'Delivered to Site',
            'Balance',
          ],
          datasets: [{
            data: [deliveredCount, allDataCount - deliveredCount],
            backgroundColor: [
              '#fa8c16',
              '#999999'
            ],
            hoverBackgroundColor: [
              '#fa8c16',
              '#999999'
            ]
          }]
        }
      },
      installed: {
        data: {
          labels: [
            'Installed on Site',
            'Balance',
          ],
          datasets: [{
            data: [installedCount, allDataCount - installedCount],
            backgroundColor: [
              '#722ed1',
              '#999999'
            ],
            hoverBackgroundColor: [
              '#722ed1',
              '#999999'
            ]
          }]
        }
      }
    }
    this.setState({ generatedData: pieChartData, loading: false })
  }

  showElementsByChartClick = (status, label, isPieChart = false, color) => {
    if (label !== 'Balance') {
      let temp = []
      _.forEach(this.state.dataPPVC, v => {
        if (v.infoData.status !== "" && v.modifiedDate !== null) {
          if (this.state.timeValue.min.valueOf() <= v.modifiedDate.getTime() && this.state.timeValue.max.valueOf() >= v.modifiedDate.getTime()) {
            if (v.infoData.status === label)
              temp.push(v.dbId)
          }
        }
      })
      this.props.viewer.select(temp)
    }
  }
  handleSelectChart = (value, e) => {
    try {
      let status = e[0]._model.label;
      if (!value)
        status = e[0]._model.datasetLabel
      let label = e[0]._model.label;
      let color = e[0]._model.backgroundColor;
      this.showElementsByChartClick(status, label, value, color);
    }
    catch { }
  }
  handleCloseDockPieChart = () => {
    this.props.viewer.showAll()
    // this.props.viewer.clearThemingColors(this.props.viewer.model)
    this.props.onChangeDockDisplay('pieChartPPVCDock', false)
  }


  render() {
    return (
      <>

        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 60, y: 0 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.state.openDock ? 'inline' : 'none', height: '80%', width: 350, resize: 'vertical' }}
            id='custom-progress-piechart' >
            <div className='custom-dock-panel-title' title='Progress Update-to-date (Casting Completed)'>Progress Update-to-date (Casting Completed)</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDockPieChart}>x</div>
            <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
              <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                {this.state.dataPPVC.length === 0 ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
                  this.state.generatedData === null ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                    <span style={{ color: 'white' }}>
                      No data
                  </span>
                  } /> :
                    <div>
                      <div style={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Tag color="blue" style={{ fontSize: 'medium' }}>
                          Casting Completed: {this.state.generatedData.casted.data.datasets[0].data[0]}/{this.state.dataPPVC.length}
                        </Tag>
                      </div>
                      <br />
                      <Pie data={this.state.generatedData.casted.data} options={this.option1}
                        getElementAtEvent={this.handleSelectChart.bind(this, true)} height={120} />
                      <hr style={{ marginBottom: 10, marginTop: 10 }} />

                      <div style={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Tag color="green" style={{ fontSize: 'medium' }}>
                          Delivered to Fit-out Yard: {this.state.generatedData.deliveredToFitoutYard.data.datasets[0].data[0]}/{this.state.dataPPVC.length}
                        </Tag>
                      </div>
                      <br />
                      <Pie data={this.state.generatedData.deliveredToFitoutYard.data} options={this.option1}
                        getElementAtEvent={this.handleSelectChart.bind(this, true)} height={120} />
                      <hr style={{ marginBottom: 10, marginTop: 10 }} />

                      <div style={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Tag color="yellow" style={{ fontSize: 'medium' }}>
                          Fit Out Completed: {this.state.generatedData.completedToFitout.data.datasets[0].data[0]}/{this.state.dataPPVC.length}
                        </Tag>
                      </div>
                      <br />
                      <Pie data={this.state.generatedData.completedToFitout.data} options={this.option1}
                        getElementAtEvent={this.handleSelectChart.bind(this, true)} height={120} />
                      <hr style={{ marginBottom: 10, marginTop: 10 }} />

                      <div style={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Tag color="orange" style={{ fontSize: 'medium' }}>
                          Delivered to Site: {this.state.generatedData.delivered.data.datasets[0].data[0]}/{this.state.dataPPVC.length}
                        </Tag>
                      </div>
                      <br />
                      <Pie data={this.state.generatedData.delivered.data} options={this.option1}
                        getElementAtEvent={this.handleSelectChart.bind(this, true)} height={120} />
                      <hr style={{ marginBottom: 10, marginTop: 10 }} />

                      <div style={{ textAlign: '-webkit-center', width: '100%' }}>
                        <Tag color="purple" style={{ fontSize: 'medium' }}>
                          Installed on Site: {this.state.generatedData.installed.data.datasets[0].data[0]}/{this.state.dataPPVC.length}
                        </Tag>
                      </div>
                      <br />
                      <Pie data={this.state.generatedData.installed.data} options={this.option1}
                        getElementAtEvent={this.handleSelectChart.bind(this, true)} height={120} />
                      <hr style={{ marginBottom: 10, marginTop: 10 }} />


                    </div>
                }
              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable>


      </>
    )

  }



}
export default PieChartPPVC;