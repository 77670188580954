import React, { useState, useEffect } from "react";
import { LineChart, Line, ResponsiveContainer, LabelList, XAxis, YAxis, Legend, CartesianGrid } from "recharts";

const ChartAllSubPerMonth_WithHolding = ({ values, current_project, subSelected }) => {
   const [cp, setCP] = useState();
   useEffect(() => {
      {
         if (subSelected !== "All Sub") {
            setCP(`${subSelected}_withholding`);
         } else {
            setCP(`withholding_amount`);
         }
      }
   }, [current_project, subSelected]);

   return (
      <div>
         <h6>Total withholding amount by Sub-con/ by month </h6>
         <ResponsiveContainer width="100%" minHeight={"17vh"}>
            <LineChart data={values} margin={{ top: 15, right: 5, left: 5, bottom: 20 }}>
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <XAxis dataKey="name" />
               <YAxis />

               <Line connectNulls type="monotone" dataKey={cp} stroke="#8884d8" strokeWidth="2">
                  <LabelList dataKey={cp} position="top" />
               </Line>
            </LineChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartAllSubPerMonth_WithHolding;
