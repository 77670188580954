import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Row, Col, Radio, DatePicker, message } from "antd";
import _ from "lodash";
import ChartSubPerMonth from "./violation/ChartSubPerMonth";
import ChartSubPerProject from "./violation/ChartSubPerProject";
import ChartSubSummary from "./violation/ChartSubSummary";
import ChartAllSubPerMonth_Count from "./violation/ChartAllSubPerMonth_Count";
import ChartAllSubPerMonth_Demerit from "./violation/ChartAllSubPerMonth_Demerit";
import ChartAllSubPerMonth_WithHolding from "./violation/ChartAllSubPerMonth_WithHolding";
import TableSubSummary from "./violation/TableSubSummary";

import listColor from "../function/listColor";
const { RangePicker } = DatePicker;
const ViolationReport = (current) => {
   const [originData, setOriginData] = useState();
   const [tempData, setTempData] = useState();
   const [countItems, setCountItems] = useState();
   const [countByMonth, setCountByMonth] = useState();
   const [listProject, setListProject] = useState([]);
   const [listSub, setlistSub] = useState();
   const [lineLabels, setLineLables] = useState();
   const [subSelected, setSubSelected] = useState();
   const [currentProject, setCurrentProject] = useState();
   const [current_sub, setCurrenSub] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [static_report, setStatic] = useState();

   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            await axios.post("/api/qaqc/getdata/jotform-violation");
            message.success("Latest data from Jotform has been loaded!");

            const res = await axios.get("/api/qaqc/dashboard/violation");
            const temp_d = res.data.data;
            setOriginData(temp_d);
            setTempData(temp_d);
            setCurrentProject("ALL PROJECTS");
            setCurrenSub("All Sub");

            let projects = [...new Set(temp_d.map((obj) => obj.project))];
            projects.sort();
            projects.splice(0, 0, "ALL PROJECTS");
            setListProject(projects);

            Digest_Data_All_Project(temp_d, "ALL PROJECTS", "All Sub");
            get_Static_for_Dashboard(temp_d);
         };

         fetchdata();
      }
   }, []);

   const getShortNameOfTrade = (originalName) => {
      let short_name = "";
      const list_word = originalName.split(" ");
      if (list_word.length > 1) {
         for (let i = 0; i < list_word.length; i++) {
            const e = list_word[i];
            if (e !== "/" && e !== "/") {
               if (i === 0) {
                  short_name = e.substring(0, 4) + "-";
               } else {
                  if (i < 4) {
                     short_name = short_name + e.substring(0, 1);
                  }
               }
            }
         }
      }

      if (list_word.length === 1) {
         if (originalName.length > 5) {
            short_name = originalName.substring(0, 4);
         }
      }

      return short_name;
   };

   const Digest_Data_All_Project = (temp_d, current_project, current_discipline) => {
      temp_d.forEach((e) => {
         e.YYYYMM = dayjs(e.created_at).format("YYYYMM");
         e.MMYY = dayjs(e.created_at).format("MM/YY");
      });

      //GET DATA FOR TABLE AND BAR CHART
      var group_sub = _.groupBy(temp_d, "subcontractor");

      let list_count_by_sub = [];
      _.map(group_sub, (value, key) => {
         let t = new Object();
         t.name = value[0].subcontractor;
         if (t.name !== null) {
            t.short_name = getShortNameOfTrade(t.name);
         } else {
            t.short_name = "NA";
         }

         const list_sub = temp_d.filter((e) => e.subcontractor === value[0].subcontractor);

         t.total = list_sub.length;
         t.demerit_point = list_sub.reduce((e, { demerit_point }) => e + demerit_point, 0);
         t.withholding_amount = list_sub.reduce((e, { withholding_amount }) => e + withholding_amount, 0);

         //get list of all project from data
         let projects = [...new Set(temp_d.map((obj) => obj.project))];
         //get percent for each project
         projects.forEach((p) => {
            const list_sub_project = list_sub.filter((e) => e.project === p);

            const total_project = list_sub_project.length;
            const project_count_name = `${p}_count`;
            t[project_count_name] = total_project;

            const demerit_point_project = list_sub_project.reduce((e, { demerit_point }) => e + demerit_point, 0);
            const project_demerit_name = `${p}_demerit_point`;
            t[project_demerit_name] = demerit_point_project;

            const withholding_amount_project = list_sub_project.reduce(
               (e, { withholding_amount }) => e + withholding_amount,
               0
            );
            const project_withholding_amount_name = `${p}_withholding_amount`;
            t[project_withholding_amount_name] = withholding_amount_project;
         });
         // }
         list_count_by_sub.push(t);
      });
      list_count_by_sub = list_count_by_sub.filter((e) => e.total > 0);
      list_count_by_sub = _.sortBy(list_count_by_sub, ["name"]);

      // get list sub
      const list_sub = list_count_by_sub.map((item) => item.name);

      //GET DATA FOR LINE CHART
      var groups = _.groupBy(temp_d, "YYYYMM");
      groups = _.sortBy(groups, "YYYYMM");
      let list_count_by_months = [];
      _.map(groups, (value, key) => {
         let t = new Object();
         t.name = value[0].MMYY;
         const per_month = temp_d.filter((e) => e.MMYY === value[0].MMYY);
         t.All = per_month.length;
         t.demerit_point = per_month.reduce((e, { demerit_point }) => e + demerit_point, 0);
         t.withholding_amount = per_month.reduce((e, { withholding_amount }) => e + withholding_amount, 0);
         //then group by trade
         var per_sub = _.groupBy(per_month, "subcontractor");
         _.map(per_sub, (val, key) => {
            const filler_list_sub_con = per_month.filter((e) => e.subcontractor === key);
            const demerit_per_sub = filler_list_sub_con.reduce((e, { demerit_point }) => e + demerit_point, 0);
            const withholding_per_sub = filler_list_sub_con.reduce(
               (e, { withholding_amount }) => e + withholding_amount,
               0
            );
            //count for each sub
            const key_count = `${key}_count`;
            t[key_count] = val.length;
            //demerit for each sub
            const key_demerit = `${key}_demerit`;
            t[key_demerit] = demerit_per_sub;
            //withholding for each sub
            const key_withholding = `${key}_withholding`;
            t[key_withholding] = withholding_per_sub;
         });
         //FILL 0 for all sub not have in this month
         const list_sub_this_month = per_month.map((item) => item.subcontractor);
         list_sub.forEach((e) => {
            const find_item = list_sub_this_month.find((f) => f === e);
            if (find_item === undefined) {
               //count for each sub
               const key_count = `${e}_count`;
               t[key_count] = 0;
               //demerit for each sub
               const key_demerit = `${e}_demerit`;
               t[key_demerit] = 0;
               //withholding for each sub
               const key_withholding = `${e}_withholding`;
               t[key_withholding] = 0;
            }
         });

         list_count_by_months.push(t);
      });

      //LIST TRADE
      list_sub.sort();
      list_sub.splice(0, 0, "All Sub");
      let line_labels = [];
      list_sub.map((item, index) => {
         const t = { key: item, color: listColor[index] };
         line_labels.push(t);
      });

      setCountItems(list_count_by_sub);
      setCountByMonth(list_count_by_months);
      setlistSub(list_sub);
      setLineLables(line_labels);
      setSubSelected(list_sub[0]);

      return;
   };

   const get_Static_for_Dashboard = (temp_d) => {
      let stt = new Object();
      stt.no_of_item = temp_d.length;
      stt.total_demerit_point = temp_d.reduce((e, { demerit_point }) => e + demerit_point, 0);
      stt.total_withholding_amount = temp_d.reduce((e, { withholding_amount }) => e + withholding_amount, 0);
      setStatic(stt);
   };

   const handleChangeProject = (e) => {
      const current_project = e.target.value;
      setCurrentProject(current_project);
      let data = [];
      if (current_project === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === current_project);
      }

      if (current_sub !== "All Sub") {
         data = data.filter((e) => e.subcontractor === current_sub);
      }

      if (date_start !== undefined && date_start !== "" && date_end !== undefined && date_end !== "") {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_project);
      get_Static_for_Dashboard(data);
   };

   const handleChangeSub = (e) => {
      const current_sub = e.target.value;
      setCurrenSub(current_sub);
      let data = [];

      if (currentProject === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === currentProject);
      }

      if (current_sub !== "All Sub") {
         data = data.filter((e) => e.subcontractor === current_sub);
      }
      if (date_start !== undefined && date_start !== "" && date_end !== undefined && date_end !== "") {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_sub);
      get_Static_for_Dashboard(data);
   };

   const handleChangeDate = (dateString) => {
      set_date_start(dateString[0]);
      set_date_end(dateString[1]);
      let data = [];
      if (currentProject === "ALL PROJECTS") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === currentProject);
      }

      if (current_sub !== "All Sub") {
         data = data.filter((e) => e.discipline === current_sub);
      }

      if (dateString[0] !== undefined && dateString[0] !== "" && dateString[1] !== undefined && dateString[1] !== "") {
         data = data.filter((e) => e.created_at >= dateString[0] && e.created_at <= dateString[1]);
      }
      setTempData(data);
      Digest_Data_All_Project(data, currentProject);
      get_Static_for_Dashboard(data);
   };

   return (
      <div style={{ margin: "10px 15px", height: "100%" }}>
         <Row>
            <Col span={17} style={{ left: "-10px" }}>
               <Row gutter={10}>
                  <Radio.Group defaultValue="ALL PROJECTS" buttonStyle="solid" onChange={(e) => handleChangeProject(e)}>
                     {listProject !== undefined
                        ? listProject.map((p) => (
                             <Radio.Button value={p} key={p}>
                                {p.toUpperCase()}
                             </Radio.Button>
                          ))
                        : null}
                  </Radio.Group>

                  <RangePicker
                     size={"default"}
                     style={{ float: "right", marginRight: 7, width: 220 }}
                     onChange={(date, dateString) => handleChangeDate(dateString)}
                  />
               </Row>
               <Row>
                  <ChartSubSummary
                     current_project={currentProject}
                     projects={listProject}
                     data={countItems}
                     subSelected={subSelected}
                     setSubSelected={setSubSelected}
                  />
               </Row>

               <Row>
                  {countByMonth !== undefined ? (
                     <ChartAllSubPerMonth_Count
                        values={countByMonth}
                        current_project={currentProject}
                        subSelected={subSelected}
                     />
                  ) : null}
               </Row>

               <Row>
                  {countByMonth !== undefined ? (
                     <ChartAllSubPerMonth_Demerit
                        values={countByMonth}
                        current_project={currentProject}
                        subSelected={subSelected}
                     />
                  ) : null}
               </Row>

               <Row>
                  {countByMonth !== undefined ? (
                     <ChartAllSubPerMonth_WithHolding
                        values={countByMonth}
                        current_project={currentProject}
                        subSelected={subSelected}
                     />
                  ) : null}
               </Row>

               {/* <Row>
                  {countByMonth !== undefined && lineLabels !== undefined ? (
                     <ChartSubPerMonth values={countByMonth} labels={lineLabels} subSelected={subSelected} />
                  ) : null}
               </Row> */}
            </Col>
            <Col
               span={7}
               style={{
                  top: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  height: "100%",
               }}
            >
               <Row>
                  <Col span={8}>
                     <h6
                        style={{
                           color: "blue",
                           fontWeight: "bold",
                           fontSize: "32px",
                           marginLeft: "5px",
                        }}
                     >
                        {`${static_report !== undefined ? static_report.no_of_item : null} `}
                     </h6>
                     <p>violation reports</p>
                  </Col>

                  <Col span={8}>
                     <h6
                        style={{
                           color: "blue",
                           top: "2px",
                           fontWeight: "bold",
                           fontSize: "32px",
                           marginLeft: "5px",
                        }}
                     >
                        {`${static_report !== undefined ? static_report.total_demerit_point : null} `}
                     </h6>
                     <p>demerit points</p>
                  </Col>

                  <Col span={8}>
                     <h6
                        style={{
                           color: "blue",
                           fontWeight: "bold",
                           fontSize: "32px",
                           top: "5px",
                           marginLeft: "5px",
                        }}
                     >
                        {`${static_report !== undefined ? static_report.total_withholding_amount : null} `}$
                     </h6>
                     <p>withholding</p>
                  </Col>
               </Row>

               <br />
               <TableSubSummary data={countItems} setSubSelected={setSubSelected} />

               <br />
               {currentProject === "ALL PROJECTS" ? (
                  <ChartSubPerProject
                     current_project={currentProject}
                     projects={listProject}
                     data={countItems}
                     subSelected={subSelected}
                     setSubSelected={setSubSelected}
                  />
               ) : null}
            </Col>
         </Row>
      </div>
   );
};

export default ViolationReport;
