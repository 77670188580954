import React from 'react';
import _ from 'lodash'
import moment from 'moment';
import {
  formatNumber, getAllDetailScheduleID, getAllStatusTask, hexToB, hexToR, hexToG
} from './TableFunction'
import {
  getAllElementdbIdsOneModel
} from './ForgeFunction';
import { Tooltip } from 'antd';
const THREE = window.THREE
export function reportPile(tempDataSheet, viewer, listTempDS, checkHighlightElement, startReport1, endReport1) {
  return new Promise(resolve => {
    let elementInDuration = new THREE.Vector4(21 / 255, 110 / 255, 255 / 255, 1)
    let elementNotDuration = new THREE.Vector4(138 / 255, 180 / 255, 248 / 255, 1)
    var groups = []
    for (var i = 0; i < tempDataSheet.length; i++) {
      let temp1 = {
        id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value,
        start: tempDataSheet[i].cells[3].value, end: tempDataSheet[i].cells[4].value,
        nestedGroups: [], parentId: tempDataSheet[i].parentId
      }
      for (var j = 0; j < tempDataSheet.length; j++) {
        if (i !== j) {
          if (tempDataSheet[i].id === tempDataSheet[j].parentId) {
            temp1.nestedGroups.push(tempDataSheet[j].id)
          }
        }
      }
      if (temp1.nestedGroups.length === 0) {
        if (temp1.parentId === null || temp1.parentId === undefined) {

        }
        else if (temp1.start !== undefined && temp1.end !== undefined) {
          let startReport = moment(startReport1, 'DD-MM-YYYY').startOf('day').valueOf()
          let endReport = moment(endReport1, 'DD-MM-YYYY').endOf('day').valueOf()
          let startItem = moment(temp1.start).startOf('day').valueOf()
          let endItem = moment(temp1.end).endOf('day').valueOf()
          if (startReport <= startItem && endItem <= endReport)
            groups.push(temp1)
        }
      }
    }
    let data = []
    let total = 0
    if (checkHighlightElement) {
      _.forEach(listTempDS, item => {
        _.forEach(item, v => {
          viewer.setThemingColor(v.id, elementNotDuration)
        })

      })
    }
    _.forEach(groups, (v, k) => {
      if (listTempDS[v.content]) {
        let tempSize = {}
        _.forEach(listTempDS[v.content], item => {
          if (item.name === 'PILE') {
            if (!tempSize[item.size])
              tempSize[item.size] = []
            tempSize[item.size].push({ id: item.id })
            if (checkHighlightElement) {
              viewer.setThemingColor(item.id, elementInDuration);
            }
          }

        })
        _.forEach(tempSize, (v1, k1) => {
          data.push({ id: v.content, size: k1, start: v.start, end: v.end, count: v1.length, dbids: v1 })
          total = total + v1.length
        })
      }
    })

    resolve({ data: data, total: total })
  })

}

export function reportConcrete(tempDataSheet, viewer, listTempDS, checkHighlightElement, startReport1, endReport1) {
  return new Promise(resolve => {
    let elementInDuration = new THREE.Vector4(21 / 255, 110 / 255, 255 / 255, 1)
    let elementNotDuration = new THREE.Vector4(138 / 255, 180 / 255, 248 / 255, 1)
    var groups = []
    for (var i = 0; i < tempDataSheet.length; i++) {
      let temp1 = {
        id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value,
        start: tempDataSheet[i].cells[3].value, end: tempDataSheet[i].cells[4].value,
        conVolDesign: tempDataSheet[i].cells[6].value, conVolOrder: tempDataSheet[i].cells[7].value,
        rebarWeightDesign: tempDataSheet[i].cells[8].value, rebarWeightOrder: tempDataSheet[i].cells[9].value,
        nestedGroups: [], parentId: tempDataSheet[i].parentId
      }
      for (var j = 0; j < tempDataSheet.length; j++) {
        if (i !== j) {
          if (tempDataSheet[i].id === tempDataSheet[j].parentId) {
            temp1.nestedGroups.push(tempDataSheet[j].id)
          }
        }
      }
      if (temp1.nestedGroups.length === 0) {
        if (temp1.parentId === null || temp1.parentId === undefined) {

        }
        else if (temp1.start !== undefined && temp1.end !== undefined) {
          let startReport = moment(startReport1, 'DD-MM-YYYY').startOf('day').valueOf()
          let endReport = moment(endReport1, 'DD-MM-YYYY').endOf('day').valueOf()
          let startItem = moment(temp1.start).startOf('day').valueOf()
          let endItem = moment(temp1.end).endOf('day').valueOf()
          if (startReport <= startItem && endItem <= endReport)
            groups.push(temp1)
        }
      }
    }
    let data = []
    if (checkHighlightElement) {
      _.forEach(listTempDS, item => {
        _.forEach(item, v => {
          viewer.setThemingColor(v.id, elementNotDuration)
        })

      })
    }
    let total = { totalConDesign: 0, totalConOrder: 0, totalRebarDesign: 0, totalRebarOrder: 0 }
    let tempData = {}
    _.forEach(groups, (v, k) => {
      if (listTempDS[v.content]) {
        data.push({
          id: v.content, start: v.start, end: v.end, conVolDesign: v.conVolDesign, conVolOrder: v.conVolOrder,
          rebarWeightDesign: v.rebarWeightDesign, rebarWeightOrder: v.rebarWeightOrder, dbids: listTempDS[v.content]
        })
        let tempValue1 = v.conVolDesign === undefined ? 0 : v.conVolDesign
        let tempValue2 = v.conVolOrder === undefined ? 0 : v.conVolOrder
        let tempValue3 = v.rebarWeightDesign === undefined ? 0 : v.rebarWeightDesign
        let tempValue4 = v.rebarWeightOrder === undefined ? 0 : v.rebarWeightOrder
        total.totalConDesign = total.totalConDesign + tempValue1
        total.totalConOrder = total.totalConOrder + tempValue2
        total.totalRebarDesign = total.totalRebarDesign + tempValue3
        total.totalRebarOrder = total.totalRebarOrder + tempValue4


        _.forEach(listTempDS[v.content], item => {

          if (checkHighlightElement) {
            viewer.setThemingColor(item.id, elementInDuration);
          }

        })
        // _.forEach(tempData, (v1, k1) => {

        // })
      }
    })
    resolve({ data: data, total: total })
  })

}

export function extractDataFromSmartSheet(tempDataSheet) {
  return new Promise(resolve => {
    var groups = []
    for (var i = 0; i < tempDataSheet.length; i++) {
      let temp1 = {
        id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value,
        startPlan: tempDataSheet[i].cells[1].value, endPlan: tempDataSheet[i].cells[2].value,
        start: tempDataSheet[i].cells[3].value, end: tempDataSheet[i].cells[4].value,
        status: tempDataSheet[i].cells[5].value,
        nestedGroups: [], parentId: tempDataSheet[i].parentId
      }
      for (var j = 0; j < tempDataSheet.length; j++) {
        if (i !== j) {
          if (tempDataSheet[i].id === tempDataSheet[j].parentId) {
            temp1.nestedGroups.push(tempDataSheet[j].id)
          }
        }
      }
      if (temp1.nestedGroups.length === 0) {
        // let index = _.findIndex(array, function (o) { return o.name === temp1.status })
        // if (index >= 0)
        groups.push(temp1)
      }
    }
    resolve(groups)
  })

}
export function sliceString(text, number = 30) {
  let combine = number < text.length ? text.slice(0, number) + ' ...' : text.slice(0, number)
  return combine;
}

export function getAndGroupElementLengthMethod(viewer, sheetData, statusOptions, contractType = 'LTA Civil Project') {
  return new Promise(resolve => {
    let tempData = {}
    let dbIds = getAllElementdbIdsOneModel(viewer)
    let count = dbIds.length
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
        let tempDetailid = ''
        let tempMasterId = ''
        let tempCode5D = ''
        let area = 0
        let volume = 0
        let length = 0
        for (let i in modelAProperty.properties) {
          if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
            tempDetailid = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
            tempMasterId = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Area') {
            area = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Volume') {
            volume = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Length') {
            length = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === '5D_CODE (PREFIX)' && contractType === 'Building Project' && tempCode5D === '') {
            tempCode5D = modelAProperty.properties[i].displayValue
          }
          if (i === (modelAProperty.properties.length - 1).toString()) {
            if (contractType === 'Building Project') {
              if (tempDetailid !== '' && tempMasterId !== '' && tempCode5D !== '') {
                let status = ''
                let found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, code5D: tempCode5D, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume ,length})
                }
              }
            } else {
              if (tempDetailid !== '' && tempMasterId !== '' && length !== 0) {
                let status = ''
                let found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume ,length})
                }
              }
            }
          }
        }
        count--;
        if (count === 0) {
          resolve(calculateLengthStatus(viewer, tempData, statusOptions, contractType))
        }
      })
    })
  })

}
export function calculateLengthStatus(viewer, data, statusOptions, contractType) {
  let tempNeedData = []
  _.forEach(data, (obj, k) => {
    let tempTotalLength = 0
    let temp = {}
    let count = obj.length
    _.forEach(obj, (v) => {
      if (!temp[v.detailID])
        temp[v.detailID] = {
          ids: [], code5D: '', masterID: '', detailID: '', status: '', percentLength: 0, totalArea: 0, totalVolume: 0, totalLength:0
        }
      temp[v.detailID].ids.push(v.id)
      temp[v.detailID].totalLength = v.length
      temp[v.detailID].totalArea = v.area
      temp[v.detailID].totalVolume = temp[v.detailID].totalVolume + v.volume
      temp[v.detailID].masterID = v.masterID
      temp[v.detailID].detailID = v.detailID
      temp[v.detailID].code5D = v.code5D
      if (v.status === undefined)
        temp[v.detailID].status = 'No Status'
      else
        temp[v.detailID].status = v.status
      count--
    })

    if (count === 0) {
      _.forEach(temp, v => {
        tempTotalLength = tempTotalLength + v.totalLength
      })
      _.forEach(temp, v => {
        // let accumulativeRate = 0
        let totalPercent = 0
        let check = true
        let color = ''
        let currentArea = 0
        _.forEach(statusOptions, r => {
          if (v.status !== 'No Status') {
            if (check) {
              color = r.color
              // accumulativeRate = accumulativeRate + r.value
              v[r.name] = contractType === 'LTA Civil Project' ? v.totalLength / tempTotalLength * r.value : v.totalLength //!
            } else {
              v[r.name] = 0
            }
            totalPercent = totalPercent + v[r.name]
            if (r.name === v.status) {
              check = false
            }
            if (currentArea === 0)
              currentArea = v.totalArea
          } else {
            v[r.name] = 0
            totalPercent = 0
            currentArea = 0
          }
        })
        // v.areaCurrent = v.totalArea * accumulativeRate / 100
        v.percentLength = contractType === 'LTA Civil Project' ? v.totalLength / tempTotalLength * 100 : currentArea
        v.percent = totalPercent
        if (v.status !== 'No Status') {
          let R = hexToR(color) / 255;
          let G = hexToG(color) / 255;
          let B = hexToB(color) / 255;
          let colorV4 = new THREE.Vector4(R, G, B, 1)
          _.forEach(v.ids, id => {
            viewer.setThemingColor(id, colorV4)
          })
        }
      })
    }
    let val = _.values(temp)
    tempNeedData.push(val)
  })
  let val = _.flattenDeep(tempNeedData)
  return val
}

export function getAndGroupElementAreaMethod(viewer, sheetData, statusOptions, contractType = 'LTA Civil Project') {
  return new Promise(resolve => {
    let tempData = {}
    let dbIds = getAllElementdbIdsOneModel(viewer)
    let count = dbIds.length
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
        let tempDetailid = ''
        let tempMasterId = ''
        let tempCode5D = ''
        let area = 0
        let volume = 0
        let length = 0
        for (let i in modelAProperty.properties) {
          if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
            tempDetailid = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
            tempMasterId = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Area') {
            area = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Volume') {
            volume = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Length') {
            length = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === '5D_CODE (PREFIX)' && contractType === 'Building Project' && tempCode5D === '') {
            tempCode5D = modelAProperty.properties[i].displayValue
          }
          if (i === (modelAProperty.properties.length - 1).toString()) {
            if (contractType === 'Building Project') {
              if (tempDetailid !== '' && tempMasterId !== '' && tempCode5D !== '') {
                let status = ''
                let found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, code5D: tempCode5D, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            } else {
              if (tempDetailid !== '' && tempMasterId !== '' && area !== 0) {
                let status = ''
                let found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            }
          }
        }
        count--;
        if (count === 0) {
          resolve(calculateAreaStatus(viewer, tempData, statusOptions, contractType))
        }
      })
    })
  })

}
export function calculateAreaStatus(viewer, data, statusOptions, contractType) {
  let tempNeedData = []
  _.forEach(data, (obj, k) => {
    let tempTotalArea = 0
    let temp = {}
    let count = obj.length
    _.forEach(obj, (v) => {
      if (!temp[v.detailID])
        temp[v.detailID] = {
          ids: [], code5D: '', masterID: '', detailID: '', status: '', percentArea: 0, totalArea: 0, totalVolume: 0
        }
      temp[v.detailID].ids.push(v.id)
      temp[v.detailID].totalArea = v.area
      temp[v.detailID].totalVolume = temp[v.detailID].totalVolume + v.volume
      temp[v.detailID].masterID = v.masterID
      temp[v.detailID].detailID = v.detailID
      temp[v.detailID].code5D = v.code5D
      if (v.status === undefined)
        temp[v.detailID].status = 'No Status'
      else
        temp[v.detailID].status = v.status
      count--
    })

    if (count === 0) {
      _.forEach(temp, v => {
        tempTotalArea = tempTotalArea + v.totalArea
      })
      _.forEach(temp, v => {
        // let accumulativeRate = 0
        let totalPercent = 0
        let check = true
        let color = ''
        let currentArea = 0
        _.forEach(statusOptions, r => {
          if (v.status !== 'No Status') {
            if (check) {
              color = r.color
              // accumulativeRate = accumulativeRate + r.value
              v[r.name] = contractType === 'LTA Civil Project' ? v.totalArea / tempTotalArea * r.value : v.totalArea //!
            } else {
              v[r.name] = 0
            }
            totalPercent = totalPercent + v[r.name]
            if (r.name === v.status) {
              check = false
            }
            if (currentArea === 0)
              currentArea = v.totalArea
          } else {
            v[r.name] = 0
            totalPercent = 0
            currentArea = 0
          }
        })
        // v.areaCurrent = v.totalArea * accumulativeRate / 100
        v.percentArea = contractType === 'LTA Civil Project' ? v.totalArea / tempTotalArea * 100 : currentArea
        v.percent = totalPercent
        if (v.status !== 'No Status') {
          let R = hexToR(color) / 255;
          let G = hexToG(color) / 255;
          let B = hexToB(color) / 255;
          let colorV4 = new THREE.Vector4(R, G, B, 1)
          _.forEach(v.ids, id => {
            viewer.setThemingColor(id, colorV4)
          })
        }
      })
    }
    let val = _.values(temp)
    tempNeedData.push(val)
  })
  let val = _.flattenDeep(tempNeedData)
  return val
}
export function getAndGroupElementCountMethod(viewer, sheetData, statusOptions, contractType = 'LTA Civil Project') {
  return new Promise(resolve => {
    let temp = []
    let tempData = {}
    let dbIds = getAllElementdbIdsOneModel(viewer)
    let count = dbIds.length
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
        let tempDetailid = ''
        let tempMasterId = ''
        let tempCode5D = ''
        let area = 0
        let volume = 0
        let length = 0
        for (let i in modelAProperty.properties) {
          if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
            tempDetailid = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
            tempMasterId = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Area') {
            area = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Volume') {
            volume = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Length') {
            length = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === '5D_CODE (PREFIX)' && contractType === 'Building Project' && tempCode5D === '') {
            tempCode5D = modelAProperty.properties[i].displayValue
          }
          if (i === (modelAProperty.properties.length - 1).toString()) {
            if (contractType === 'Building Project') {
              if (tempDetailid !== '' && tempMasterId !== '' && tempCode5D !== '') {
                let status = ''
                let found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  temp.push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status })
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, code5D: tempCode5D, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            } else {
              if (tempDetailid !== '' && tempMasterId !== '') {
                let status = ''
                var found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  temp.push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status })
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            }
          }
        }
        count--;
        if (count === 0) {
          resolve(calculateCountStatus(viewer, tempData, statusOptions, contractType))
        }
      })
    })
  })
}
export function calculateCountStatus(viewer, data, statusOptions, contractType) {
  let tempNeedData = []
  _.forEach(data, (obj, k) => {
    let temp = {}
    let count = obj.length
    let total = obj.length
    _.forEach(obj, (v) => {
      if (!temp[v.detailID])
        temp[v.detailID] = {
          ids: [], code5D: '', masterID: '', detailID: '', status: '', totalArea: 0, totalVolume: 0
        }
      temp[v.detailID].ids.push(v.id)
      temp[v.detailID].masterID = v.masterID
      temp[v.detailID].detailID = v.detailID
      temp[v.detailID].totalArea = v.area
      temp[v.detailID].totalVolume = temp[v.detailID].totalVolume + v.volume
      temp[v.detailID].code5D = v.code5D
      if (v.status === undefined)
        temp[v.detailID].status = 'No Status'
      else
        temp[v.detailID].status = v.status
      count--
    })

    if (count === 0) {
      let totalColumn = Object.keys(temp).length
      _.forEach(temp, v => {
        let totalPercent = 0
        let check = true
        let color = ''
        _.forEach(statusOptions, r => {
          if (v.status !== 'No Status') {
            if (check) {
              color = r.color
              v[r.name] = 1 / totalColumn * r.value
            } else {
              v[r.name] = 0
            }
            totalPercent = totalPercent + v[r.name]
            if (r.name === v.status) {
              check = false
            }
          } else {
            v[r.name] = 0
            totalPercent = 0
          }
        })
        v.percentCurrent = 1 / totalColumn * 100
        v.percent = totalPercent
        if (v.status !== 'No Status') {
          let R = hexToR(color) / 255;
          let G = hexToG(color) / 255;
          let B = hexToB(color) / 255;
          let colorV4 = new THREE.Vector4(R, G, B, 1)
          _.forEach(v.ids, id => {
            viewer.setThemingColor(id, colorV4)
          })
        }
      })
    }
    let val = _.values(temp)
    tempNeedData.push(val)
  })
  let val = _.flattenDeep(tempNeedData)
  return val
}
export function getAndGroupElementVolumeMethod(viewer, sheetData, statusOptions, contractType = 'LTA Civil Project') {
  return new Promise(resolve => {
    let tempData = {}
    let dbIds = getAllElementdbIdsOneModel(viewer)
    let count = dbIds.length
    dbIds.forEach((modelAdbId) => {
      viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
        let tempDetailid = ''
        let tempMasterId = ''
        let tempCode5D = ''
        let area = 0
        let volume = 0
        let length = 0
        for (let i in modelAProperty.properties) {
          if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
            tempDetailid = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
            tempMasterId = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Area') {
            area = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Volume') {
            volume = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === 'WH_Length') {
            length = modelAProperty.properties[i].displayValue
          } else if (modelAProperty.properties[i].displayName === '5D_CODE (PREFIX)' && contractType === 'Building Project' && tempCode5D === '') {
            tempCode5D = modelAProperty.properties[i].displayValue
          }
          if (i === (modelAProperty.properties.length - 1).toString()) {
            if (contractType === 'Building Project') {
              if (tempDetailid !== '' && tempMasterId !== '' && volume !== 0 && tempCode5D !== '') {
                let status = ''
                var found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, code5D: tempCode5D, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            } else {
              if (tempDetailid !== '' && tempMasterId !== '' && volume !== 0) {
                let status = ''
                var found = false
                _.forEach(sheetData, v => {
                  if (v.content === tempDetailid) {
                    found = true
                    status = v.status
                    return false;
                  }
                })
                if (found) {
                  if (!tempData[tempMasterId])
                    tempData[tempMasterId] = []
                  tempData[tempMasterId].push({ id: modelAdbId, masterID: tempMasterId, detailID: tempDetailid, status: status, area: area, volume: volume })
                }
              }
            }
          }
        }
        count--;
        if (count === 0) {
          resolve(calculateVolumeStatus(viewer, tempData, statusOptions, contractType))
        }
      })
    })
  })

}
export function calculateVolumeStatus(viewer, data, statusOptions, contractType) {
  let tempNeedData = []
  _.forEach(data, (obj, k) => {
    let tempTotalVolume = 0
    let temp = {}
    let count = obj.length
    _.forEach(obj, (v) => {
      if (!temp[v.detailID])
        temp[v.detailID] = {
          ids: [], code5D: '', masterID: '', detailID: '', status: '', percentArea: 0, totalArea: 0, totalVolume: 0
        }
      temp[v.detailID].ids.push(v.id)
      temp[v.detailID].totalArea = v.area
      temp[v.detailID].totalVolume = temp[v.detailID].totalVolume + v.volume
      temp[v.detailID].masterID = v.masterID
      temp[v.detailID].detailID = v.detailID
      temp[v.detailID].code5D = v.code5D
      if (v.status === undefined)
        temp[v.detailID].status = 'No Status'
      else
        temp[v.detailID].status = v.status
      count--
    })

    if (count === 0) {
      _.forEach(temp, v => {
        tempTotalVolume = tempTotalVolume + v.totalVolume
      })
      _.forEach(temp, v => {
        // let accumulativeRate = 0
        let totalPercent = 0
        let currentVolume = 0
        let check = true
        let color = ''
        _.forEach(statusOptions, r => {
          if (v.status !== 'No Status') {
            if (check) {
              color = r.color
              v[r.name] = contractType === 'LTA Civil Project' ? v.totalVolume / tempTotalVolume * r.value : v.totalVolume
            } else {
              v[r.name] = 0
            }
            totalPercent = totalPercent + v[r.name]
            if (r.name === v.status) {
              check = false
            }
            if (currentVolume === 0)
              currentVolume = v.totalVolume
          } else {
            v[r.name] = 0
            totalPercent = 0
            currentVolume = 0
          }
        })
        v.percentVolume = contractType === 'LTA Civil Project' ? v.totalVolume / tempTotalVolume * 100 : currentVolume
        v.percent = totalPercent
        if (v.status !== 'No Status') {
          let R = hexToR(color) / 255;
          let G = hexToG(color) / 255;
          let B = hexToB(color) / 255;
          let colorV4 = new THREE.Vector4(R, G, B, 1)
          _.forEach(v.ids, id => {
            viewer.setThemingColor(id, colorV4)
          })
        }
      })
    }
    let val = _.values(temp)
    tempNeedData.push(val)
  })
  let val = _.flattenDeep(tempNeedData)
  return val
}
export function sortByLabel(a, b) {
  let nameA = a.label;
  let nameB = b.label;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}