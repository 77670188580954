import React from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import Chart from 'react-google-charts';
import { Typography } from '@material-ui/core'
import Excel from 'exceljs';
import { saveAs } from 'file-saver'
import Draggable from 'react-draggable';
import {formatNumber} from '../../../function/TableFunction'

export default class PlanningPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDay: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      endDay: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
    }

  }
  componentDidMount() {

  }
  componentWillMount() {

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.openDock) {
      // this.setState({ listDataShow: nextProps.listElementId[1], status: 'On-going' })
    }
  }
  componentDidUpdate() {

  }
  handleSelectElement = (id) => {
    this.props.viewer.clearSelection();
    var array = []
    id.forEach(item => {
      array.push(item);
    })
    this.props.viewer.select(array);
  }
  async handleExport() {
    var { listElementRight } = this.props
    var { startDay, endDay } = this.state
    var workbook = new Excel.Workbook();
    var sheet = workbook.addWorksheet('Demo');
    var rows = [['Name', 'Start Day', 'End Day', 'Status', 'Volume']]
    sheet.addRows(rows);
    for (var key in listElementRight) {
      listElementRight[key].forEach(item => {
        var start = moment(startDay, 'DD-MM-YYYY').startOf('day').valueOf();
        var end = moment(endDay, 'DD-MM-YYYY').endOf('day').valueOf();
        var startItem = moment(item.start, 'DD-MM-YYYY').valueOf();
        var endItem = moment(item.end, 'DD-MM-YYYY').valueOf();

        if (start > endItem || end < startItem) {
          let rows = [[item.name, item.start, item.end, 'Not Cast', item.volume]]
          sheet.addRows(rows);
        } else if (end > endItem) {
          let rows = [[item.name, item.start, item.end, 'Casted', item.volume]]
          sheet.addRows(rows);
        } else if (end < endItem) {
          let rows = [[item.name, item.start, item.end, 'On Going', item.volume]]
          sheet.addRows(rows);
        }

      })
    }
    const buf = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buf]), 'Demo.xlsx')

  }
  onChange = (dates) => {
    try {
      this.setState({ startDay: dates[0].format("DD-MM-YYYY") })
      this.setState({ endDay: dates[1].format("DD-MM-YYYY") })
    } catch{ }

  }
  getCalendarContainer = () => {
    return document.getElementById('popupExportTimeline');
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay('compare', false)
  }
  render() {

    const { volumeCasted, volumeActive, listElementLeft, viewer, time, allVolume, volumePPVC
      , volumeCastedCompare, volumeActiveCompare, volumeEarlyCompare, volumeDelayCompare, listElementRight,
      volumeActiveComparePPVC,volumeCastedComparePPVC } = this.props
    const chartEvents = [
      {
        eventName: "select",
        callback({ chartWrapper }) {
          try {
            var temp = chartWrapper.getChart().getSelection()[0].row
            viewer.clearSelection();
            var array = []
            listElementLeft[temp].forEach(item => {
              array.push(item.id);
            })
            var merged = [].concat.apply([], array);
            viewer.select(merged);

          } catch{ }
        }
      }
    ];
    const chartEventsCompare = [
      {
        eventName: "select",
        callback({ chartWrapper }) {
          try {
            var temp = chartWrapper.getChart().getSelection()[0].row
            viewer.clearSelection();
            if (temp === 1 || temp === 2 || temp === 3) {
              let array = []
              var status = ''
              if (temp === 1) status = 'On Going'
              else if (temp === 2) status = 'Early'
              else if (temp === 3) status = 'Delay'
              listElementRight[1].forEach(item => {
                if (item.status === status)
                  array.push(item.id);
              })
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            } else {
              let array = []
              listElementRight[temp].forEach(item => {
                array.push(item.id);
              })
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            }


          } catch{ }
        }
      }
    ];
    var volumeCastedTemp = formatNumber(volumeCasted,0)
    var allVolumeTemp = formatNumber(allVolume,0)
    var allVolumePPVCTemp = formatNumber((allVolume + volumePPVC),0)
    var volumeCastedCompareTemp = formatNumber(volumeCastedCompare,0)
    var volumeCastedCompareCISPPVCTemp = formatNumber(volumeCastedCompare + volumeActiveComparePPVC,0)
    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 400, width: 600 }}
          id='custom-main-planning-summary' >
          <div className='custom-dock-panel-title'>Details</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{ width: "100%", height: '100%', overflow: 'auto', position: 'absolute' }}>
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: '350px', textAlign: '-webkit-center', color: 'red',fontWeight:500 }}> {this.props.displayScheduleL}</div>
                <div style={{ width: '350px', textAlign: '-webkit-center', color: 'red' ,fontWeight:500 }}> {this.props.displayScheduleR}</div>
              </div>
              <div style={{ display: 'flex', width: "100%" }}>
                <Chart
                  width={'350px'}
                  height={'200px'}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Volume', 'm³'],
                    ['Casted', parseFloat(volumeCasted.toFixed(0))],
                    ['On Going', parseFloat(volumeActive.toFixed(0))],
                    ['Not Cast', parseFloat((allVolume - volumeCasted - volumeActive).toFixed(0))],
                  ]}
                  options={{
                    // legend: {position: 'labeled'},
                    title: `Con'c vol casted: ${volumeCastedTemp}m³/${allVolumeTemp}m³ (CIS)`,
                    backgroundColor: 'white',
                    titleTextStyle: {
                      fontSize: 11,
                    },
                    sliceVisibilityThreshold :0,
                    slices: {
                      0: { color: '#0000FF' },
                      1: { color: '#ffa500' },
                      2: { color: 'gray' }
                    }
                  }}
                  chartEvents={chartEvents}
                />
                <Chart
                  width={'350px'}
                  height={'200px'}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['Volume', 'm³'],
                    ['Casted', parseFloat(volumeCastedCompare.toFixed(0))],
                    ['On Going', parseFloat(volumeActiveCompare.toFixed(0))],
                    ['Early', parseFloat(volumeEarlyCompare.toFixed(0))],
                    ['Delay', parseFloat(volumeDelayCompare.toFixed(0))],
                    ['Not Cast', parseFloat((allVolume - volumeCastedCompare - volumeActiveCompare - volumeEarlyCompare - volumeDelayCompare).toFixed(0))],

                  ]}
                  options={{

                    title: `Con'c vol casted: ${volumeCastedCompareTemp}m³/${allVolumeTemp}m³ (CIS)`,
                    backgroundColor: 'white',
                    titleTextStyle: {
                      fontSize: 11,
                    },
                    sliceVisibilityThreshold :0,
                    slices: {
                      0: { color: '#0000FF' },
                      1: { color: '#ffa500' },
                      2: { color: '#0095ff' },
                      3: { color: '#ff0000' },
                      4: { color: 'gray' },

                    }
                  }}
                  chartEvents={chartEventsCompare}
                />
              </div>
              {volumePPVC !== 0 &&
                <div style={{ display: 'flex', width: "100%" }}>
                  <Chart
                    width={'350px'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ['Volume', 'm³'],
                      ['Casted', parseFloat(volumeCasted.toFixed(0)) + parseFloat(volumeCastedComparePPVC.toFixed(0)) ],
                      ['On Going', parseFloat(volumeActive.toFixed(0)) + parseFloat(volumeActiveComparePPVC.toFixed(0))],
                      ['Not Cast', parseFloat((allVolume + volumePPVC - volumeCasted - volumeActive -volumeCastedComparePPVC -volumeActiveComparePPVC).toFixed(0))],
                    ]}
                    options={{

                      title: `Con'c vol casted: ${volumeCastedCompareCISPPVCTemp}m³/${allVolumePPVCTemp}m³ (CIS+PPVC)`,
                      backgroundColor: 'white',
                      titleTextStyle: {
                        fontSize: 11,
                      },
                      sliceVisibilityThreshold :0,
                      slices: {
                        0: { color: '#0000FF' },
                        1: { color: '#ffa500' },
                        2: { color: 'gray' }
                      }
                    }}
                    chartEvents={chartEvents}
                  />
                  <Chart
                    width={'350px'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ['Volume', 'm³'],
                      ['Casted', parseFloat(volumeCastedCompare.toFixed(0)) + parseFloat(volumeCastedComparePPVC.toFixed(0)) ],
                      ['On Going', parseFloat(volumeActiveCompare.toFixed(0))+parseFloat(volumeActiveComparePPVC.toFixed(0))],
                      ['Early', parseFloat(volumeEarlyCompare.toFixed(0))],
                      ['Delay', parseFloat(volumeDelayCompare.toFixed(0))],
                      ['Not Cast', parseFloat((allVolume + volumePPVC - volumeCastedCompare - volumeActiveCompare - 
                        volumeEarlyCompare - volumeDelayCompare  -volumeCastedComparePPVC -volumeActiveComparePPVC).toFixed(0))],

                    ]}
                    options={{

                      title: `Con'c vol casted: ${volumeCastedCompareCISPPVCTemp}m³/${allVolumePPVCTemp}m³ (CIS+PPVC)`,
                      backgroundColor: 'white',
                      titleTextStyle: {
                        fontSize: 11,
                      },
                      sliceVisibilityThreshold :0,
                      slices: {
                        0: { color: '#0000FF' },
                        1: { color: '#ffa500' },
                        2: { color: '#0095ff' },
                        3: { color: '#ff0000' },
                        4: { color: 'gray' },

                      }
                    }}
                    chartEvents={chartEventsCompare}
                  />
                </div>
              }
              <div style={{ padding: "10px" }}>
                <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "15px" }} >
                  {`Current activities as of ${moment(time).format('DD-MM-YYYY')}`}


                </Typography>
                <Table celled selectable verticalAlign='middle' textAlign='center'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Activity</Table.HeaderCell>
                      <Table.HeaderCell>Start Date</Table.HeaderCell>
                      <Table.HeaderCell>End Date</Table.HeaderCell>
                      <Table.HeaderCell>Vol (m³)</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {listElementRight.length !== 0 &&
                      listElementRight[1].length !== 0 ?

                      listElementRight[1].map(item => (
                        <Table.Row onClick={this.handleSelectElement.bind(this, item.id)}>
                          <Table.Cell>{item.name}</Table.Cell>
                          <Table.Cell>{item.start}</Table.Cell>
                          <Table.Cell>{item.end}</Table.Cell>
                          <Table.Cell>{item.volume.toFixed(0)}</Table.Cell>
                          <Table.Cell>{item.status}</Table.Cell>
                        </Table.Row>
                      ))
                      : <div></div>
                    }

                  </Table.Body>
                </Table>
                {/* <Popup id='popupExportTimeline' wide trigger={<Button color='red'
        style={{width: '90%'}} content='Export' />} on='click'>
        <RangePicker
           format={'DD-MM-YYYY'}
            ranges={{Today: [moment(), moment()],'This Month': [moment().startOf('month'), moment().endOf('month')], }}
            value={[moment(this.state.startDay,'DD-MM-YYYY'),moment(this.state.endDay,'DD-MM-YYYY')]}
            onChange={this.onChange}   getCalendarContainer ={this.getCalendarContainer} />
          <Button style={{float: 'right', padding: '10px', marginTop:'5px'}} color='primary' onClick={this.handleExport.bind(this)}>Apply</Button>
         </Popup> */}
              </div>

            </div>
          </div>
          <div className='custom-dock-panel-footer' />
        </div>
      </Draggable>
    )
  }
}