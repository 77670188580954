import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ isAuthenticated,isAdmin, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (isAuthenticated&&isAdmin) ? <Component {...props} /> : <Redirect to="/" />}
  />
);

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
   // isAdmin: state.user.role === "admin"
    isAdmin: state.user.email === "admin@wohhup.com",
  };
}

export default connect(mapStateToProps)(AdminRoute);
