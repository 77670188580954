import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { add, update } from '../../api/costCode';
import {
  Button,
  Input,
  Form,
  notification,
  Switch,
  Select,
  InputNumber,
} from 'antd';

import { DESIGNATIONS } from '../../constant/index';
const { Option } = Select;

function CostCodeForm(props) {
  const { record, isAdd, id } = props;
  const { setVisible, getDatas } = props;
  const projectId = useSelector((state) => state.project.projectId);
  const [trade, setTrade] = useState('');

  const optionTrades = DESIGNATIONS.map((d) => (
    <Option value={d} key={d}>
      {d}
    </Option>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values, projectId };
          if (isAdd) {
            try {
              await add(payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        name: '',
        remark: '',
        description: '',
        type: 'GW',
        isShowOnMobile: true,
      });

      setTrade('GW');
    } else {
      props.form.setFieldsValue({
        name: record.name,
        remark: record.remark,
        description: record.description,
        type: record.type,
        isShowOnMobile: record.isShowOnMobile,
      });

      setTrade(record.type);
    }
  }, [isAdd, record]);

  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Form
        className='ant-form-custom'
        name='control-ref'
        onSubmit={(e) => handleSubmit(e)}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label='Name'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input name!' }],
          })(<Input placeholder='Name' />)}
        </Form.Item>

        <Form.Item label='Remark'>
          {getFieldDecorator('remark', {
            rules: [{ required: false }],
          })(<Input placeholder='Remark' />)}
        </Form.Item>

        <Form.Item label='Description'>
          {getFieldDecorator('description', {
            rules: [],
          })(<Input placeholder='Description' />)}
        </Form.Item>

        <Form.Item label='Trade'>
          {getFieldDecorator('type', {
            rules: [{ required: true, message: 'Please input Trade!' }],
          })(
            <Select
              onChange={(e) => {
                console.log('e', e);
                setTrade(e);
              }}
            >
              {optionTrades}
            </Select>
          )}
        </Form.Item>

        {trade == 'OFF DAY' && (
          <Form.Item label='Hours'>
            {getFieldDecorator('hours', {
              rules: [],
            })(<InputNumber min={0} placeholder='Hours' />)}
          </Form.Item>
        )}

        <Form.Item label='Show On Mobile'>
          {getFieldDecorator(
            'isShowOnMobile',
            { valuePropName: 'checked' },
            {
              rules: [{ required: false }],
            }
          )(<Switch defaultChecked={true} />)}
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'CostCode' })(CostCodeForm);
