import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { convertHexColorToVector4 } from '../../module_BimApp/function/TableFunction';
import {
  Icon,
  SelectPicker,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Row,
  Col,
  Slider,
} from 'rsuite';
import {
  Typography,
  message,
  Card,
  Select,
  DatePicker,
  Checkbox,
  InputNumber,
} from 'antd';

import _ from 'lodash';
import axios from '../../module_Worker/api/axiosClient';

import {
  getForgeToken,
  getAllElementdbIdsOneModel,
  unloadForgeExtension2,
} from '../../../components/module_BimApp/function/ForgeFunction';

import { json } from 'body-parser';
import DialogChooseRevitModel from './DialogChooseRevitModel';
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

async function apiGetDataTable(payload) {
  return await axios.get('/api/dataentry/get-sheet5d-data-for-archi', {
    params: payload,
  });
}

async function apiGetRevitModel(payload) {
  return await axios.get('/api/versions/get-all-5d-revit-models', {
    params: payload,
  });
}

async function apiUpdateStatusArchi(payload) {
  return await axios.post('/api/dataentry/save-archi-status', {
    data: payload,
  });
}

async function getConfigByKey(payload) {
  return await axios.get('/api/worker/config', { params: payload });
}

async function addConfig(payload) {
  return await axios.post('/api/worker/config', payload);
}

const { Text, Title } = Typography;
const Autodesk = window.Autodesk;

class ArchiInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      viewer: null,
      status: [
        { value: 'Incomplete', label: 'Incomplete', color: '#7c806b' },
        { value: 'In Progress', label: 'In Progress', color: '#68a869' },
        { value: 'Completed', label: 'Completed', color: '#66ed68' },
        {
          value: 'Partly Completing',
          label: 'Partly Completing',
          color: '#EE82EE',
        },
      ],
      tableData: [],
      data: [],
      viewName: '',
      selectedViews: [],
      viewCurrent: null,
      document: null,
      dataTarget: null,
      isModelLoaded: false,
      modelLoaded: null,
      testData: [
        {
          id: props.projectId,
          hasCaret: true,
          icon: 'diagram-tree',
          label: props.projectName,
          type: 'project',
        },
      ],
      role: '',
      zones: [],
      allSubZones: [],
      allLevels: [],
      allViewLevels: [],
      allActivities: [],
      selectedActivities: [],
      selectedRooms: [],
      selectedZone: '',
      selectedSubZone: '',
      formInput: null,
      allRevitModels: [],
      selectedRevitModel: null,
      isHasModelAndView: false,
      dialogChooseRevitVisible: false,
    };
    this.treeModel = React.createRef();
  }

  componentDidMount = async () => {
    let res = await apiGetDataTable({
      projectId: this.props.location.state.projectId,
    });

    res = res.map((x) => ({
      ...x,
      Zone: x.Zone ?? '',
      'Sub Zone': x['Sub Zone'] ?? '',
    }));

    this.setState({ tableData: res });

    let locationDatas = res
      .map((x) => ({
        zone: x.Zone,
        subZone: x['Sub Zone'],
        level: x.Level,
        id: `${x.Zone}-${x['Sub Zone']}-${x.Level}`,
      }))
      .filter((x) => x.zone && x.subZone && x.level);

    console.log('locationDatas', locationDatas);

    locationDatas = _.orderBy(locationDatas, ['zone', 'subZone', 'level']);

    let dataDistinct = _.unionBy(locationDatas, (x) => x.id);
    let groupByZone = _.groupBy(dataDistinct, 'zone');
    let zoneDatas = Object.keys(groupByZone).map((zone) => {
      let item = {
        type: 'Zone',
        value: zone,
        label: zone,
        children: [],
      };

      let itemsInThisZone = groupByZone[zone];
      let gBySubZone = _.groupBy(itemsInThisZone, 'subZone');

      Object.keys(gBySubZone).map((subZone) => {
        let subZoneItem = {
          type: 'Sub Zone',
          value: subZone,
          label: subZone,
          children: [],
        };

        let itemsInThisSubZone = gBySubZone[subZone];
        subZoneItem.children = itemsInThisSubZone.map((itemInThisSubZone) => ({
          type: 'Level',
          value: itemInThisSubZone.level,
          label: itemInThisSubZone.level,
        }));

        item.children.push(subZoneItem);
      });

      return item;
    });

    this.setState({
      zones: zoneDatas,
    });

    await this.getRevitModel();

    let setting = await this.getSetting();

    if (setting && setting.selectedRevitModel) {
      this.setState({ selectedRevitModel: setting.selectedRevitModel });
    }

    if (
      setting &&
      setting.selectedZone &&
      setting.selectedSubZone &&
      setting.selectedActivities &&
      setting.selectedLevel &&
      setting.selectedRooms
    ) {
      //set selected zone
      let zone = this.state.zones.find((x) => x.value == setting.selectedZone);
      if (zone) {
        let subZones = zone.children;

        let selectedZone = subZones.find(
          (x) => x.value == setting.selectedSubZone
        );

        if (selectedZone) {
          let levels = selectedZone.children;
          let selectedLevel = levels.find(
            (x) => x.value == setting.selectedLevel
          );
          if (selectedLevel) {
            this.setState({
              selectedZone: setting.selectedZone,
              subZones: zone.children,
              selectedSubZone: setting.selectedSubZone,
              levels,
              selectedLevel: setting.selectedLevel,
            });
          }
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedActivities != prevState.selectedActivities ||
      this.state.selectedRooms != prevState.selectedRooms
    ) {
      let items = this.state.tableData.filter(
        (x) =>
          // x.Level == this.state.selectedLevel &&
          // x.Zone == this.state.selectedZone &&
          // x['Sub Zone'] == this.state.selectedSubZone &&
          _.isArray(this.state.selectedRooms) &&
          _.isArray(this.state.selectedActivities) &&
          this.state.selectedRooms.includes(x['Room Id']) &&
          this.state.selectedActivities.includes(x.Category)
      );

      let groupByActivities = _.groupBy(items, 'Category');

      let input = Object.keys(groupByActivities).map((key) => {
        let itemsInActivities = groupByActivities[key];

        let dateStarts = _.uniq(
          itemsInActivities.map((x) => x['Actual date start'])
        );

        let dateStart = null;
        if (dateStarts.length == 1) {
          dateStart = dateStarts[0];
        }

        let dateFinishs = _.uniq(
          itemsInActivities.map((x) => x['Actual date finish'])
        );
        let dateFinish = null;
        if (dateFinishs.length == 1) {
          dateFinish = dateFinishs[0];
        }

        let statuses = _.uniq(itemsInActivities.map((x) => x.Status));
        let status = null;
        if (statuses.length == 1) {
          status = statuses[0];
        }

        let propgresses = _.uniq(itemsInActivities.map((x) => x.Progress));
        let progress = null;
        if (propgresses.length == 1) {
          progress = propgresses[0];
        }

        return {
          ids: itemsInActivities.map((x) => x.id),
          key,
          data: {
            'Actual date finish': dateFinish,
            'Actual date start': dateStart,
            Status: status,
            Progress: progress,
          },
          isEditStart: dateStart != null,
          isEditEnd: dateFinish != null,
          isEditStatus: status != null,
          isEditProgress: progress != null,
        };
      });

      this.setState({ formInput: input });
    }

    if (this.state.selectedLevel != prevState.selectedLevel) {
      //handle change views

      if (_.isNil(this.state.selectedLevel)) {
        return;
      }

      let viewables = null;
      let allViews = this.state.doc
        .getRoot()
        .search({ type: 'geometry', role: '2d', progress: 'complete' }, true);

      console.log('allViews', allViews);

      viewables = allViews.find(
        (x) => x.data.role == '2d' && x.data.guid == this.state.selectedLevel
      );

      let revitModel = this.state.allRevitModels.find(
        (x) => x.text == this.state.selectedRevitModel
      );

      let loadOptions = this.getLoadOptions(
        revitModel,
        this.state.doc,
        viewables,
        viewables.data.role == '2d'
      );

      this.state.viewer.loadDocumentNode(
        this.state.doc,
        viewables,
        loadOptions
      );

      console.log('this.state.tableData', this.state.tableData);
      let items = this.state.tableData
        // .filter((x) => x.Level == viewables.data.levelName)
        .map((x) => x.Category);

      items = _.uniq(items);
      items.sort();

      let allActivities = items.map((x) => ({ value: x, label: x }));

      this.setState({ allActivities });

      //set change view
    }

    if (this.state.selectedRevitModel != prevState.selectedRevitModel) {
      let revitModel = this.state.allRevitModels.find(
        (x) => x.text == this.state.selectedRevitModel
      );

      if (revitModel) {
        this.setState({ isHasModelAndView: true });
        this.launchViewer(revitModel, '');
      }
    }

    if (
      this.state.tableData != prevState.tableData ||
      this.state.selectedActivities != prevState.selectedActivities ||
      this.state.status != prevState.status
    ) {
      this.handleSetColorForElementByItsStatus();
    }
  }

  async getRevitModel() {
    let files = await apiGetRevitModel({
      id: this.props.location.state.projectId,
    });

    this.setState({ allRevitModels: files });
  }

  getRoomsBySelectedLocation(zone = '', subZone = '', roomNumber = '') {
    console.log(' getRoomsBySelectedLocation zone', zone);
    console.log('getRoomsBySelectedLocation zone', subZone);
    console.log('getRoomsBySelectedLocation roomNumber', roomNumber);
    let items = this.state.tableData.filter(
      // (x) => x.Zone == zone && x['Sub Zone'] == subZone
      (x) => x['Room Id'] == roomNumber
    );

    console.log('items', items);
    console.log('this.state.tableData', this.state.tableData);

    let activities = _.uniq(items.map((x) => x['Room Id'])).map((x) => ({
      value: x,
      label: x,
    }));

    return activities;
  }

  componentWillUnmount = () => {};

  componentWillReceiveProps(nextProps) {}

  launchViewer(revitModel, view2dName) {
    getForgeToken().then((token) => {
      var options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
          extensions: [
            'Autodesk.DocumentBrowser',
            'Autodesk.VisualClusters',
            'Autodesk.AEC.LevelsExtension',
          ],
        };

        let viewer = new Autodesk.Viewing.GuiViewer3D(
          document.getElementById('forgeViewer'),
          config3d
        );

        this.setState({ viewer }, () => {
          viewer.start();
          this.eventSelectElements();
          var documentId = 'urn:' + revitModel.objectId;
          Autodesk.Viewing.Document.load(
            documentId,
            (doc) => this._onDocumentLoadSuccess(revitModel, view2dName, doc),
            this._onDocumentLoadFailure
          );
        });
      });
    });
  }

  _onDocumentLoadFailure = (node, isSaved = false, viewerErrorCode) => {};
  _onDocumentLoadSuccess = (node, view2dName, doc) => {
    unloadForgeExtension2(this.state.viewer);

    let viewables = null;
    let allViews = doc
      .getRoot()
      .search({ type: 'geometry', role: '2d', progress: 'complete' }, true);

    console.log('allViews', allViews);

    viewables = allViews.find(
      (x) => x.data.role == '2d' && x.data.name == view2dName
    );

    let view2ds = allViews.map((x) => ({
      value: x.data.guid,
      label: x.data.name,
      viewName: x.data.name,
    }));

    this.setState({ levels: view2ds });

    if (!viewables) {
      viewables = doc.getRoot().getDefaultGeometry();
    }

    let loadOptions = this.getLoadOptions(
      node,
      doc,
      viewables,
      viewables.data.role == '2d'
    );

    this.state.viewer.loadDocumentNode(doc, viewables, loadOptions);

    this.setState({
      openPanel: false,
      viewCurrent: null,
      modelLoaded: null,
      loading: false,
      doc,
    });
  };

  getLoadOptions(node, doc, viewables, is2d = true) {
    let loadOptions2d = {
      globalOffset: this.state.viewer.impl.model
        ? this.state.viewer.impl.model.myData.globalOffset
        : null,
      applyRefPoint: true,
      modelNameOverride: node.text,
      isAEC: true,
      guid: viewables.data.guid,
      viewableID: viewables.data.viewableID,
      itemId: node.itemId,
      version: node.versionCurrent,
    };

    let loadOptions3d = {
      globalOffset: node.globalOffset,
      applyRefPoint: true,
      modelNameOverride: node.text,
      isAEC: true,
      guid: viewables.data.guid,
      viewableID: viewables.data.viewableID,
      itemId: node.itemId,
      version: node.versionCurrent,
      acmSessionId: doc.acmSessionId,
    };

    return is2d ? loadOptions2d : loadOptions3d;
  }

  timeValue(timeString) {
    if (timeString) {
      return moment(timeString, dateFormat);
    }

    return null;
  }

  handleChangeStartDate(index, dateString, isStart = true) {
    let clone = [...this.state.formInput];
    if (isStart) {
      clone[index].data['Actual date start'] = dateString;
    } else {
      clone[index].data['Actual date finish'] = dateString;
    }

    this.setState({ formInput: clone });
  }

  handleChangeEdit(index, value, type) {
    let clone = [...this.state.formInput];

    if (type == 'Start') {
      clone[index].isEditStart = value;
    }
    if (type == 'End') {
      clone[index].isEditEnd = value;
    }
    if (type == 'Status') {
      clone[index].isEditStatus = value;
    }
    if (type == 'Progress') {
      clone[index].isEditProgress = value;
    }

    this.setState({ formInput: clone });
  }

  handleChangeStatus(index, e, isStatus = true) {
    let clone = [...this.state.formInput];
    if (isStatus) {
      clone[index].data.Status = e;
    } else {
      let progressNumber = parseInt(e);
      clone[index].data.Progress = progressNumber;
      if (progressNumber < 1) {
        clone[index].data.Status = this.state.status[0].value;
      } else if (progressNumber < 100) {
        clone[index].data.Status = this.state.status[1].value;
      } else if (progressNumber == 100) {
        clone[index].data.Status = this.state.status[2].value;
      }
    }

    this.setState({ formInput: clone });
  }

  eventSelectElements() {
    this.state.viewer.removeEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      (e) => {}
    );

    this.state.viewer.addEventListener(
      Autodesk.Viewing.SELECTION_CHANGED_EVENT,
      (e) => {
        let rooms = [];
        if (Array.isArray(e.dbIdArray)) {
          let i = 0;
          for (const dbId of e.dbIdArray) {
            this.state.viewer.model.getProperties(dbId, (ee) => {
              let roomNumberProperty = ee.properties.find(
                (p) => p.displayName === 'ROOM NUMBER'
              );

              if (roomNumberProperty) {
                let currentRooms = this.getRoomsBySelectedLocation(
                  '',
                  '',
                  roomNumberProperty.displayValue
                );

                if (
                  currentRooms.some(
                    (x) => x.value == roomNumberProperty.displayValue
                  )
                ) {
                  rooms = [...rooms, roomNumberProperty.displayValue];

                  rooms = _.uniq(rooms);
                  rooms.sort();
                }
              }

              if (i == e.dbIdArray.length - 1) {
                this.setState({ selectedRooms: rooms });
              }
              i++;
            });
          }
        } else {
          this.setState({ selectedRooms: [] });
        }
      }
    );
  }

  handleSetColorForElementByItsStatus() {
    if (!this.state.viewer) {
      return;
    }
    this.state.viewer.clearThemingColors();
    let colorCompleted = convertHexColorToVector4(this.state.status[2].color);
    let colorPartlyCompleting = convertHexColorToVector4(
      this.state.status[3].color
    );
    let colorInProgress = convertHexColorToVector4(this.state.status[1].color);

    let datas = this.state.tableData.filter((x) =>
      this.state.selectedActivities.some((activity) => activity == x.Item)
    );

    if (datas.length < 1) {
      return;
    }

    let elements = getAllElementdbIdsOneModel(this.state.viewer);
    elements.forEach((dbId) => {
      this.state.viewer.model.getProperties(dbId, (ee) => {
        let roomNumberProperty = ee.properties.find(
          (p) => p.displayName === 'ROOM NUMBER'
        );

        console.log('roomNumberProperty', roomNumberProperty);

        if (roomNumberProperty) {
          let datasInRoom = datas.filter(
            (data) => data['Room Id'] == roomNumberProperty.displayValue
          );

          if (datasInRoom.length > 0) {
            let statuses = datasInRoom
              .map((x) => x.Status)
              .filter((x) => !_.isNil(x) && x != 'Incomplete');
            statuses = _.uniq(statuses);
            if (statuses.length > 1) {
              //partly
              this.state.viewer.setThemingColor(dbId, colorPartlyCompleting);
            } else if (statuses.length == 1) {
              if (statuses[0] == 'Completed') {
                this.state.viewer.setThemingColor(dbId, colorCompleted);
              } else if (statuses[0] == 'In Progress') {
                this.state.viewer.setThemingColor(dbId, colorInProgress);
              }
            }
          }
        }
      });
    });
  }

  async handleSave() {
    let datas = [];
    this.state.formInput.forEach((x) => {
      let dts = x.ids.map((id) => ({
        ...x.data,
        id,
      }));

      datas = [...datas, ...dts];
    });

    let datas2 = datas.map((x) => {
      let dt = {};
      if (x['Actual date finish']) {
        dt['Actual date finish'] = x['Actual date finish'];
      }

      if (x['Actual date start']) {
        dt['Actual date start'] = x['Actual date start'];
      }

      if (x['Status']) {
        dt['Status'] = x['Status'];
      }

      if (x['Progress']) {
        dt['Progress'] = x['Progress'];
      }

      dt.id = x.id;

      return dt;
    });

    await apiUpdateStatusArchi(datas2);

    message.success('Update status successfully');

    await this.saveSetting();

    let res = await apiGetDataTable({
      projectId: this.props.location.state.projectId,
    });

    res = res.map((x) => ({
      ...x,
      Zone: x.Zone ?? '',
      'Sub Zone': x['Sub Zone'] ?? '',
    }));

    this.setState({ tableData: res });
  }

  getKeyConfig() {
    return `ARCHI-STATUS-${this.props.location.state.projectId}-${this.props.userInfo.email}`;
  }

  async saveSetting() {
    let key = this.getKeyConfig();
    let value = {
      selectedZone: this.state.selectedZone,
      selectedSubZone: this.state.selectedSubZone,
      selectedLevel: this.state.selectedLevel,
      selectedActivities: this.state.selectedActivities,
      selectedRooms: this.state.selectedRooms,
      selectedRevitModel: this.state.selectedRevitModel,
    };

    await addConfig({
      key,
      value: JSON.stringify(value),
    });
  }

  async saveSettingRevitModel() {
    let key = this.getKeyConfig();
    let value = {
      selectedRevitModel: this.state.selectedRevitModel,
    };

    await addConfig({
      key,
      value: JSON.stringify(value),
    });
  }

  async getSetting() {
    let key = this.getKeyConfig();
    let res = await getConfigByKey({ key });
    if (res && res.data && res.data.value) {
      return JSON.parse(res.data.value);
    }
    return null;
  }

  render() {
    return (
      <>
        <div id='archi-page'>
          <div className='main-content'>
            <Grid fluid>
              <Row className='show-grid'>
                <Col xs={18}>
                  <div
                    className='flex flex-column'
                    style={{
                      padding: '0px 0px 0px 0px',
                      height: 'calc(100vh - 70px)',
                    }}
                  >
                    <div className='search-bar'>
                      <SelectPicker
                        value={this.state.selectedZone}
                        placeholder='Zone'
                        data={this.state.zones}
                        style={{ width: 150 }}
                        onChange={(e) => {
                          let item = this.state.zones.find((x) => x.value == e);
                          this.setState({
                            subZones: item?.children,
                            selectedZone: e,
                            selectedSubZone: null,
                            selectedLevel: null,
                          });
                        }}
                        className='mr-2'
                      />
                      <SelectPicker
                        value={this.state.selectedSubZone}
                        placeholder='Sub-zones'
                        data={this.state.subZones}
                        style={{ width: 150 }}
                        className='mr-2'
                        onChange={(e) => {
                          let item = this.state.subZones.find(
                            (x) => x.value == e
                          );

                          this.setState({
                            selectedSubZone: e,
                          });

                          console.log('item subzone', item);
                        }}
                      />
                      <SelectPicker
                        value={this.state.selectedLevel}
                        placeholder='Views'
                        data={this.state.levels}
                        style={{ width: 200 }}
                        className='mr-2'
                        onChange={(e) => {
                          this.setState({
                            selectedLevel: e,
                          });
                        }}
                      />

                      {/* {this.state.levels &&
                        this.state.levels.map((level, index) => (
                          <Button
                            onClick={(e) => {
                              this.setState({ selectedLevel: level.value });
                            }}
                            key={index}
                            appearance={
                              level.value == this.state.selectedLevel
                                ? 'primary'
                                : 'ghost'
                            }
                            className='mr-2'
                          >
                            {level.label}
                          </Button>
                        ))} */}
                    </div>

                    <div className='display-bar d-flex flex-row align-items-center mt-2'>
                      <label style={{ width: '200px' }}>
                        Select Item to display :
                      </label>
                      <Select
                        placeholder='Items'
                        mode='multiple'
                        style={{ width: '100%' }}
                        className='ml-2'
                        onChange={(e) => {
                          this.setState({ selectedActivities: e });
                        }}
                      >
                        {_.isArray(this.state.allActivities) &&
                          this.state.allActivities.map((x, index) => (
                            <Option key={index} value={x.value}>
                              {x.label}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div
                      id='forgeViewer'
                      className='mt-2'
                      style={{
                        position: 'relative',
                        height: 'calc(100% - 120px)',
                        width: '100%',
                      }}
                    ></div>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='flex flex-column'>
                    <Scrollbars
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      thumbMinSize={30}
                      universal={true}
                      style={{
                        padding: '0px 0px 0px 0px',
                        height: 'calc(100vh - 120px)',
                      }}
                    >
                      <h2>{this.state.selectedRooms.toString()}</h2>
                      {this.state.formInput?.map((item, index) => (
                        <Card title={item.key}>
                          <div className='d-flex flex-row  align-items-center'>
                            <label className='mr-4' style={{ width: 120 }}>
                              Start
                            </label>
                            <DatePicker
                              className='w-100'
                              format={dateFormat}
                              value={this.timeValue(
                                item.data['Actual date start']
                              )}
                              onChange={(date, dateString) => {
                                this.handleChangeStartDate(index, dateString);
                              }}
                            />
                          </div>

                          <div className='d-flex flex-row  align-items-center mt-2'>
                            <label className='mr-4' style={{ width: 120 }}>
                              End
                            </label>
                            <DatePicker
                              format={dateFormat}
                              className='w-100'
                              value={this.timeValue(
                                item.data['Actual date finish']
                              )}
                              onChange={(date, dateString) => {
                                this.handleChangeStartDate(
                                  index,
                                  dateString,
                                  false
                                );
                              }}
                            />
                          </div>

                          <div className='d-flex flex-row  align-items-center mt-2'>
                            <label className='mr-4' style={{ width: 120 }}>
                              Status
                            </label>
                            <SelectPicker
                              value={item.data.Status}
                              placeholder='Status'
                              data={this.state.status.filter(
                                (x, index) => index < 3
                              )}
                              className='w-100'
                              onChange={(e) => {
                                this.handleChangeStatus(index, e);
                              }}
                            />
                          </div>

                          <div className='d-flex flex-row  align-items-center mt-2'>
                            <label
                              className='mr-4'
                              style={{ width: 120 }}
                              checked={item.isEditProgress}
                              onChange={(e) => {
                                this.handleChangeEdit(
                                  index,
                                  e.target.checked,
                                  'Progress'
                                );
                              }}
                            >
                              Progress
                            </label>
                            <InputNumber
                              onChange={(e) => {
                                this.handleChangeStatus(index, e, false);
                              }}
                              value={item.data.Progress}
                              className='w-100'
                              min={1}
                              max={100}
                            />
                          </div>
                        </Card>
                      ))}
                    </Scrollbars>
                    <ButtonGroup className=' mt-2 float-right'>
                      <Button
                        color='blue'
                        className=' mr-2'
                        onClick={() => this.handleSave()}
                      >
                        <Icon icon='play' /> Save
                      </Button>

                      <Button
                        className=' mr-2'
                        color='blue'
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/for5d/table`,
                            state: {
                              ...this.props.location.state,
                            },
                          });
                        }}
                      >
                        <Icon icon='play' /> Back To Table
                      </Button>

                      <Button
                        color='blue'
                        onClick={() => {
                          this.setState({ dialogChooseRevitVisible: true });
                        }}
                      >
                        <Icon icon='setting' /> Setting
                      </Button>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <DialogChooseRevitModel
          dialogChooseRevitVisible={this.state.dialogChooseRevitVisible}
          allRevitModels={this.state.allRevitModels}
          handleClose={() => {
            this.setState({ dialogChooseRevitVisible: false });
          }}
          selectedRevitModel={this.state.selectedRevitModel}
          handleOk={(selectedRevitModel, status) => {
            this.setState(
              { selectedRevitModel: selectedRevitModel, status },
              () => {
                this.saveSettingRevitModel().then();
              }
            );
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
  };
}
export default connect(mapStateToProps)(ArchiInput);
