import React from 'react';
import { message } from 'antd';
import { Modal, Loader, Button, TagPicker } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import { roles, rolesExternal } from '../../columns'
class DialogSettingFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // roles: {
      //   'roleQS': false,
      //   'roleEngineer': false,
      //   'roleSubCon': false,
      //   'roleClient': false,
      //   'roleViewer': false
      // },
      roles: []
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.onShow) {
      this.setState({ loading: true })
      axios.get(`/api/folders/getpermission?q=${nextProps.folderId}`)
        .then(res => {
          this.setState(prevState => ({
            roles: res.data,
            loading: false
          }))

        })
    }
  }
  handleClosePanel = () => {
    this.props.onClose()
  }

  setRoleFolder = () => {
    axios.post(`/api/folders/setpermission`, {
      folderId: this.props.folderId, setRole: this.state.roles, email: this.props.email
    })
      .then(res => {
        this.props.onClose()
        message.success(`${this.props.folderName} set permission successful.`)
      })
      .catch(err => {
        this.props.onClose()
        message.error(`${this.props.folderName} set permission failed.`)
      })
  }

  handleChange = name => event => {
    let checked = event.target.checked
    this.setState(prevState => ({
      roles: {
        ...prevState.roles,
        [name]: checked
      }
    }))
  }
  handleChangeRole = (value) => {
    this.setState({ roles: value })
  }
  render() {

    return (
      <div>
        <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} size='sm' >
          <Modal.Header>
            <Modal.Title>Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            {/* <Segment color='blue'>
              <FormControlLabel control={
                <Switch checked={this.state.roles.roleQS}
                  onChange={this.handleChange('roleQS')} value="roleQS"></Switch>} label="QS" />
              <FormControlLabel control={
                <Switch label='Engineer' checked={this.state.roles.roleEngineer}
                  onChange={this.handleChange('roleEngineer')} value="roleEngineer"></Switch>} label="Engineer" />
              <FormControlLabel control={
                <Switch label='Sub-Con' checked={this.state.roles.roleSubCon}
                  onChange={this.handleChange('roleSubCon')} value="roleSubCon"></Switch>} label="Sub-Con" />
              <FormControlLabel control={
                <Switch label='Client' checked={this.state.roles.roleClient}
                  onChange={this.handleChange('roleClient')} value="roleClient"></Switch>} label="Client" />
              <FormControlLabel control={
                <Switch label='Viewer' checked={this.state.roles.roleViewer}
                  onChange={this.handleChange('roleViewer')} value="roleViewer"></Switch>} label="Viewer" />
            </Segment> */}
            <TagPicker
              size='md'
              appearance="subtle"
              cleanable={false}
              data={rolesExternal}
              block value={this.state.roles}
              onChange={this.handleChangeRole }
            />
             <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClosePanel} color="ghost">
              Cancel
          </Button>
            <Button onClick={this.setRoleFolder} color="primary">
              Save
          </Button>
          </Modal.Footer>
        </Modal>



      </div>

    );
  }
}


export default DialogSettingFolder