import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { valueFormatterForDouble } from '../common';

function SummaryTable(props) {
  const {
    allHolidays,
    cutOffDate,
    dataTimeSheet,
    currentMonthTimeSheet,
    tableHeight,
  } = props;
  const [rowDatas, setRowDatas] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (_.isArray(dataTimeSheet)) {
      GetDataTable();
    }
  }, [dataTimeSheet]);

  function GetDataTable() {
    let dataForTable = [];

    let dayDatas = [];

    dataTimeSheet.forEach((worker) => {
      if (_.isArray(worker.timeWorkingInDays)) {
        worker.timeWorkingInDays.forEach((dayData) => {
          dayDatas.push(dayData);
        });
      }
    });

    let groupByTrade = _.groupBy(dayDatas, 'occupation');

    let trades = Object.keys(groupByTrade);

    trades.sort();

    let columns = [
      {
        headerName: 'No.',
        field: 'no',
        filter: true,
        width: 80,
      },
      {
        headerName: 'Category',
        field: 'trade',
        filter: true,
        width: 120,
      },
      {
        headerName: 'Total Workers',
        field: 'totalWorker',
        filter: true,
        aggFunc: 'sum',
        width: 150,
      },
      {
        headerName: 'Total Norm Hrs',
        field: 'totalNormalHours',
        aggFunc: 'sum',
        width: 150,
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: 'Total 1.0 Hrs',
        field: 'totalPublicHolidayHours',
        aggFunc: 'sum',
        width: 150,
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: 'Total 1.5 Hrs',
        field: 'total15Hours',
        aggFunc: 'sum',
        width: 150,
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: 'Total 2.0 Hrs',
        field: 'total20Hours',
        aggFunc: 'sum',
        width: 150,
        valueFormatter: valueFormatterForDouble,
      },
      {
        headerName: 'Total Advance',
        field: 'totalAdvance',
        aggFunc: 'sum',
        width: 150,
      },
      {
        headerName: 'Total Allowce',
        field: 'totalAllowce',
        aggFunc: 'sum',
        width: 150,
      },
      {
        headerName: 'Total Levy',
        field: 'totalLevy',
        aggFunc: 'sum',
        width: 150,
      },
      {
        headerName: 'Total Adjustm.',
        field: 'totalAd',
        aggFunc: 'sum',
        width: 150,
      },
    ];

    let startMoment = moment(currentMonthTimeSheet, 'MM-YYYY')
      .subtract(1, 'months')
      .set('date', cutOffDate + 1);

    let currentMonthMoment = moment(currentMonthTimeSheet, 'MM-YYYY');
    let endMoment = currentMonthMoment.set('date', cutOffDate);

    let tempStartMoment = moment(startMoment);

    let columnDays = [];
    while (!tempStartMoment.isAfter(endMoment)) {
      let daystring = tempStartMoment.format('DD-MM-YY');
      columnDays.push(daystring);

      tempStartMoment = tempStartMoment.add(1, 'days');
    }

    trades.forEach((trade, index) => {
      let rowData = {
        no: index + 1,
        trade,
      };

      let itemInGroups = groupByTrade[trade];
      let totalWorker = _.uniq(itemInGroups.map((x) => x.idWorker)).length;

      let totalNormalHours = _.sumBy(itemInGroups, 'rate1');

      let totalPublicHolidayHours = _.sumBy(itemInGroups, 'phWidthRate');

      let total15Hours = _.sumBy(itemInGroups, 'rate15');

      let total20Hours = _.sumBy(itemInGroups, 'rate20');

      rowData.totalWorker = _.round(totalWorker, 1);
      rowData.totalNormalHours = totalNormalHours;
      rowData.totalPublicHolidayHours = totalPublicHolidayHours;
      rowData.total15Hours = total15Hours;
      rowData.total20Hours = total20Hours;
      dataForTable.push(rowData);
    });

    // dataForTable = dataForTable.filter((x) => x.trade != 'OFF DAY');

    setRowDatas(dataForTable);
    setColumnDefs(columns);
  }

  const autoGroupColumnDef = {};

  return (
    <div style={{ height: tableHeight, boxSizing: 'border-box' }}>
      <AgGridReact
        className='ag-theme-balham'
        reactUi='true'
        rowData={rowDatas}
        columnDefs={columnDefs}
        pagination={true}
        autoGroupColumnDef={autoGroupColumnDef}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      ></AgGridReact>
    </div>
  );
}

export default SummaryTable;
