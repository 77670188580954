import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover } from 'rsuite';
import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,

} from 'react-table'
// import './scss/SideBarSelectParameter.scss'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import NearMeIcon from '@material-ui/icons/NearMe';
import { Popconfirm } from 'antd';
import {
  exportTableToExcel, handleCollapse, handleExpand,
  selectMultiModelWithElementId, colorSetWithElementId, colorIndividualWithElementId,ListValueFilter
} from '../function/TableFunction'
import {
  SelectionWindow
} from '../function/ForgeFunction'


function TableScheduleSaved({ columns, data,
  getRowProps,
  getCellProps,
  viewer,
  onActiveTable,
  tempListModel,
  onChangeHeader,
  onIsolateRows,
  activeButtonIsolateRows,
  email,
  itemIdCurrent,
  version,
  groupByScheduleSaved,
}) {
  const [activeButtonWindowSelection, setActiveButtonWindowSelection] = React.useState(false)
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter.bind(this, onChangeHeader),
    }),
    []
  )
 


  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'setcolor',
            groupByBoundary: true,
            collapse: true,
            Footer: '',
            width: 25,
            Header: ({ }) => (
              <></>
            ),
            Cell: ({ row }) => (
              <input type="color" defaultValue={row.values.setcolor} onChange={(e) => {colorIndividualWithElementId(e,row,viewer,tempListModel)}} />
            ),
          },
          ...columns,
        ]
      })
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'selection',
            groupByBoundary: true,
            collapse: true,
            width: 10,
            Footer: '',
            Header: ({ }) => (
              <></>
            ),
            Cell: ({ row }) => (
              <NearMeIcon style={{ color: 'gray', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => { selectMultiModelWithElementId(viewer, row, tempListModel) }} />
            ),
          },
          ...columns,
        ]
      })
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    flatColumns,
    flatRows,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,

    gotoPage,


    setPageSize,

    state: {
      groupBy,
      pageIndex, pageSize
    }
  } = instance

  const columnCount = columns.length
  const setGroup = () => {
    (flatColumns.map(d => {
      try {
        if (d.isGrouped)
          d.toggleGroupBy()
      } catch{ }

    }))
    onActiveTable()
  }
  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { value }) => {
    setPageSize(value)
  }

  const handleWindowSelection = () => {
    setActiveButtonWindowSelection(!activeButtonWindowSelection)
    SelectionWindow(viewer, 'table', setActiveButtonWindowSelection)
  }
  const handleIsolateRows = () => {
    let temp = []
    if (!activeButtonIsolateRows) {
      let DBids = viewer.impl.selector.getAggregateSelection()
      if (DBids.length !== 0) {
        _.forEach(DBids, v => {
          _.forEach(v.selection, id => {
            temp.push(id + '//' + v.model.myData.loadOptions.modelNameOverride)
          })
        })
        onIsolateRows(temp);
      }
    } else {
      onIsolateRows(temp);
    }
  }

  return (
    <div>
      <ButtonGroup style={{ position: 'absolute', zIndex: '1' }}>
        <Tooltip title="Colorize" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="paint-brush" />} onClick={() => colorSetWithElementId('tableScheduleSaved',rows, groupBy, viewer, tempListModel)}></IconButton>
        </Tooltip>
        <Popconfirm placement="bottom" title={'Are you sure to export this schedule?'} onConfirm={() => exportTableToExcel('tableScheduleSaved')} okText="Yes" cancelText="No">
          <Tooltip title="Export" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="export" />}   ></IconButton>
          </Tooltip>
        </Popconfirm>
        <Whisper placement='bottomLeft' trigger="click" speaker={
          <Popover >
            <ButtonGroup>
              <Button size="sm" onClick={() => handleExpand(flatRows, 0, groupBy)} disabled={groupBy.length < 2} >1</Button>
              <Button size="sm" onClick={() => handleExpand(flatRows, 1, groupBy)} disabled={groupBy.length < 3}>2</Button>
              <Button size="sm" onClick={() => handleExpand(flatRows, 2, groupBy)} disabled={groupBy.length < 4}>3</Button>
            </ButtonGroup>
          </Popover>
        }>
          <Tooltip title="Expand" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="minus-square-o" />}  ></IconButton>
          </Tooltip>
        </Whisper>

        <Tooltip title="Collapse" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="plus-square-o" />} onClick={() => handleCollapse(flatRows)} ></IconButton>
        </Tooltip>
        <Tooltip title="Window Selection" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="target" />} color={activeButtonWindowSelection && 'blue'} onClick={() => handleWindowSelection()}></IconButton>
        </Tooltip>
        <Tooltip title="Isolate objected selected in schedule table" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="link" />} color={activeButtonIsolateRows && 'violet'} onClick={() => handleIsolateRows()}></IconButton>
        </Tooltip>
        {/* <Tooltip title="Edit Schedule" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="refresh" />} onClick={() => handleEditSaveSchedule()}></IconButton>
        </Tooltip> */}
      </ButtonGroup>

      <div style={{ float: 'right' }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          // // Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
          // ellipsisItem={true}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />

        <ButtonGroup style={{ right: 0, top: 0, cursor: 'pointer', backgroundColor: '#ffffff00', zIndex: '1', marginLeft: '15px' }}>
          <IconButton size="xs" icon={<Icon icon="close" />} onClick={() => setGroup()}></IconButton>
        </ButtonGroup>
      </div>


      <div style={{ position: 'absolute', top: 35, overflow: 'auto', bottom: 0, borderBottomStyle: 'solid', borderTopStyle: 'solid', borderWidth: 'thin', width: '100%' }}>
        <Table celled selectable
          id="tableScheduleSaved" {...getTableProps()} >
          {/* <Sticky> */}
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
                    width: column.id === 'selection' ? 5 : column.id === 'setcolor' ? 25 : 'auto'
                  }}>
                    {!column.isGrouped && (groupByScheduleSaved.includes(column.id) && column.toggleGroupBy())}
                    {(column.id !== 'selection' && column.id !== 'setcolor') &&
                      <span {...column.getGroupByToggleProps()}  >
                        {column.isGrouped ? <RemoveCircleOutlineIcon style={{ color: 'red', fontSize: '15px', verticalAlign: 'sub' }} />
                          : <AddCircleOutlineIcon style={{ color: 'green', fontSize: '15px', verticalAlign: 'sub' }} />}
                      </span>}
                    {column.Header === 'ElementId' && column.toggleHidden(true)}
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          {/* </Sticky> */}
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      cell.column.id !== 'setcolor' ?
                        <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{ textAlign: cell.column.aggregate === 'sum' ? 'right' : 'left' }} >
                          {cell.isGrouped ? (
                            <>
                              <span {...row.getExpandedToggleProps()}>
                                {row.subRows.length !== 0 &&
                                  ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                    (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                                }
                              </span>{' '}
                              {cell.render('Cell')}

                            </>
                          ) : cell.isAggregated ? (

                            cell.render('Aggregated')
                          ) : cell.isRepeatedValue ? null : (
                            cell.render('Cell')

                          )}
                        </Table.Cell>
                        :
                        (groupBy[groupBy.length - 1] === cell.row.groupByID && groupBy.length !== 0) ?
                          <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{ textAlign: cell.column.aggregate === 'sum' ? 'right' : 'left' }} >
                            {cell.isGrouped ? (
                              <>
                                <span {...row.getExpandedToggleProps()}>
                                  {row.subRows.length !== 0 &&
                                    ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                      (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                                  }
                                </span>{' '}
                                {cell.render('Cell')}

                              </>
                            ) : cell.isAggregated ? (

                              cell.render('Aggregated')
                            ) : cell.isRepeatedValue ? null : (
                              cell.render('Cell')

                            )}
                          </Table.Cell>
                          :
                          <Table.Cell></Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer>
            {footerGroups.map(group => (
              <Table.Row {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  column.id === 'setcolor' ?
                    <Table.HeaderCell style={{ textAlign: 'left', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px', fontWeight: 'bold' }} {...column.getFooterProps()} >
                      Total</Table.HeaderCell>
                    :
                    <Table.HeaderCell style={{ textAlign: 'right', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px' }} {...column.getFooterProps()} >
                      {column.render('Footer')}</Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>
        </Table>

      </div>

    </div>
  )
}

export default TableScheduleSaved