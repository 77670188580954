import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';



const IconSidePanel = (props) => {

   const { type, onClick, isLocked, typeForUploadBtn, totalBtnCount, btnIndex } = props;


   const styledUploadBtn = (type === 'side-dms' || type === 'side-sum' || type === 'side-rfa' || type === 'side-rfam' || type === 'side-rfi'
      || type === 'side-cvi' || type === 'side-dt' || type === 'side-mm'|| type === 'side-eri'  || type === 'side-lr'  || type === 'side-sm' 
      || type === 'side-qr' || type === 'side-qrm' || type === 'side-rfc'
      || type === 'side-cons' || type ==='side-subco'
   )
      ? {}
      : {
         position: 'absolute',
         bottom: (totalBtnCount - 1 - btnIndex) * 30,
      };


      const extraStyled = (type === 'side-dms' || type === 'side-mm'  || type === 'side-eri' || type === 'side-lr' || type === 'side-sm')  ? {
      marginBottom: 20
   } : {};

   return (
      <Tooltip placement={'right'} title={typeForUploadBtn || toolTipBtn(type)}>
         <IconSide
            onClick={onClick}
            style={{
               pointerEvents: isLocked ? 'none' : 'auto',
               background: isLocked ? colorType.grey4 : 'transparent',
               color: isLocked ? colorType.primary : 'white',
               fontWeight: isLocked ? 'bold' : 'normal',
               width: 43,
               ...styledUploadBtn,
               ...extraStyled
            }}
         >{type.slice(5, type.length).toUpperCase()}</IconSide>
      </Tooltip>
   );
};

export default IconSidePanel;


const toolTipBtn = (type) => {
   return type === 'side-dms' ? 'Drawing Management System' :
      type === 'side-rfa' ? 'Request For Approval' :
         type === 'side-rfam' ? 'Request For Approval Of Material' :
            type === 'side-rfi' ? 'Request For Information' :
               type === 'side-cvi' ? 'Confirmation Of Verbal Instruction' :
                  type === 'side-dt' ? 'Document Transmittal' :
                     type === 'side-mm' ? 'Meeting Minutes' :
                        type === 'side-eri' ? 'Employers Representative Instruction' :
                           type === 'side-lr' ? 'Letter' :
                              type === 'side-sm' ? 'Site Memo':
                                 type === 'side-sum' ? 'Chart' :
                                    type === 'side-qr' ? 'QR' :
                                       type === 'side-qrm' ? 'QRM' :
                                          type === 'side-rfc' ? 'RFC' :
                                             type === 'side-cons' ? 'Upload Document' :
                                                type === 'side-subco' ? 'Sub-con Document' :
                                                    '...';
};


const IconSide = styled.div`
   &:hover {
      cursor: pointer;
      background-color: ${colorType.grey1};
      transition: 0.2s;
      border-radius: 5px;
   };
   border: ${props => props.disabled ? '1px solid grey' : '1px solid black'};
   padding: 3px;
   font-size: 11.5px;
   margin: 5px;
   border-radius: 5px;
   text-align: center;
`;