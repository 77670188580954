import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
// import customParseFormat from "dayjs/plugin/customParseFormat";

import { Row, Col, Radio, DatePicker, Tooltip, Tabs } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';

import _ from 'lodash';

import TableGroupByUnit from './qmworks_idd/TableGroupByUnit';
import StaticInfo from './_common_component/StaticInfo';

import CardHeader from './_common_component/CardHeader';
import Toolbar from './_common_component/Toolbar';
import ChartScoreRange from './qmworks_idd/ChartScoreRange';
import TableScoreByInspector from './qmworks_idd/TableScoreByInspector';
import ChartBlockUnitStatus from './qmworks_idd/ChartBlockUnitStatus';
import ChartProductivityRange from './qmworks_idd/ChartProductivityRange';
import CostCodeUnitTableSummary from './CostReport/CostCodeUnitTableSummary';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const QMWorksIdd = (current) => {
  const [originWorkerData, setOriginWorkerData] = useState();
  const [tempWorkerData, setTempWorkerData] = useState();
  const [tempDataHandBlockUnit, setTempDataHandBlockUnit] = useState();
  const [groupbyUnit, setGroupByUnit] = useState();
  const [rangeSelected, setRangeSelected] = useState();
  const [listProject, setListProject] = useState([]);
  const [data_productivity_range, set_data_productivity_range] = useState();
  const [static_report_handover, setStaticHand] = useState();
  const [static_report_bca, setStaticBCA] = useState();

  const [static_report_worker, setStaticWorker] = useState();
  const [inspector_score, setInspectorScore] = useState();
  const [list_score_range, setListCoreRange] = useState();



  const [date_start, set_date_start] = useState('');
  const [date_end, set_date_end] = useState('');
  const [filterMode, setFilterMode] = useState(0);

  const id = 'MTU5MTY3NDI0ODUyMy1IYW5keQ';

  useEffect(() => {
    {
      const fetchdata = async () => {
        const res_hand = await axios.get(
          'https://api.wohhup.com/dashboard/QaQcDigicheck/handover/' + id
        );
        // const res_hand = await axios.get("http://localhost:8001/dashboard/QaQcDigicheck/handover/" + id);
        const temp_hand = res_hand.data.data;
        const res_bca = await axios.get(
          'https://api.wohhup.com/dashboard/QaQcDigicheck/bcainspection/' + id
        );
        const temp_bca = res_bca.data.data;
        const res_worker = await axios.get(
          'https://api.wohhup.com/dashboard/worker/qmworker/' + id
        );

        const temp_worker = res_worker.data.data;
        setOriginWorkerData(temp_worker);
        setTempWorkerData(temp_worker);
        Digest_Data_Other_Data(temp_hand, temp_bca);
        Digest_Worker_Data(temp_worker);
        // get_Worker_Static_for_Dashboard(data_group);
      };
      setRangeSelected('All');
      fetchdata();
    }
  }, []);


  const Digest_Data_Other_Data = (temp_hand, temp_bca) => {
    //BCA inspection
    if (temp_bca !== null) {
      console.log("temp_bca.listScore", temp_bca.listScore)
      setInspectorScore(temp_bca.listInpsector);
      setListCoreRange(temp_bca.listScore);
      setStaticBCA(temp_bca.listStatic);
    }

    if (temp_hand !== null) {
      setTempDataHandBlockUnit(temp_hand.listUnit);

      let list_hand_static_temp = temp_hand.listStatic;
      list_hand_static_temp[0].color = '#82ca2d';
      list_hand_static_temp[0].border = '1px';
      list_hand_static_temp[1].color = 'white';
      list_hand_static_temp[1].border = '3px solid #1f618d';
      list_hand_static_temp[2].color = 'white';
      list_hand_static_temp[2].border = '3px solid #a93226';
      setStaticHand(list_hand_static_temp);
    }
  };

  const Digest_Worker_Data = (temp_worker) => {
    setGroupByUnit(temp_worker.list_unit_info);

    set_data_productivity_range(temp_worker.list_productivity_range);
    setStaticWorker(temp_worker.listStatic);
  };

  const handleChangeDate = (fm, dateString) => {
    var customParseFormat = require('dayjs/plugin/customParseFormat');
    dayjs.extend(customParseFormat);
    var isBetween = require('dayjs/plugin/isBetween');
    dayjs.extend(isBetween);
    var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
    dayjs.extend(isSameOrAfter);

    const now = dayjs();

    const y = now.year();
    const m = now.month();
    const d = now.day();
    let data = _.cloneDeep(originWorkerData);

    switch (fm) {
      case 1:
        // const sd1 = dayjs([y, m + 1, 1]).format('DD/MM/YYYY');
        const sd1 = dayjs(new Date(y, m, 1)).format('DD/MM/YYYY');
        data = data.filter((e) => dayjs(e.dateTime).month() == m);
        set_date_start(sd1);
        set_date_end(null);

        break;
      case 2:
        let last = now.subtract(1, "month")
        let sd2 = dayjs(new Date(last.year(), last.month(), 1)).format('DD/MM/YYYY');
        const de = dayjs(sd2).daysInMonth()
        let ed2 = dayjs(new Date(last.year(), last.month(), de)).format('DD/MM/YYYY');
        // let sd2 = dayjs([y, m, 1]).format('DD/MM/YYYY');
        // const de = dayjs(sd2).daysInMonth();
        // let ed2 = dayjs([y, m, de]).format('DD/MM/YYYY');
        data = data.filter((e) => dayjs(e.dateTime).month() == m - 1);
        set_date_start(sd2);
        set_date_end(ed2);
        break;

      case 4:
        if (
          dateString[0] !== undefined &&
          dateString[0] !== '' &&
          dateString[1] !== undefined &&
          dateString[1] !== ''
        ) {
          data = data.filter((e) =>
            dayjs(e.dateTime, 'DD/MM/YYYY').isBetween(
              dateString[0],
              dateString[1],
              'day',
              '[]'
            )
          );
          set_date_start(dateString[0]);
          set_date_end(dateString[1]);
        }
        break;
    }

    setTempWorkerData(data);
    // const data_group = Digest_Worker_Data(data);
    // get_Worker_Static_for_Dashboard(data_group);

    // <Row>
    //                   <ButtonGroup style={{ float: "left" }}>
    //                      <Radio.Group
    //                         defaultValue={filterMode}
    //                         buttonStyle="solid"
    //                         value={filterMode}
    //                         onChange={(e) => {
    //                            setFilterMode(e.target.value);
    //                         }}
    //                      >
    //                         <div>
    //                            <Tooltip title="Shown data for all time.">
    //                               <Radio.Button
    //                                  value={0}
    //                                  onClick={() => {
    //                                     handleChangeDate(0, null);
    //                                  }}
    //                               >
    //                                  All time
    //                               </Radio.Button>
    //                            </Tooltip>

    //                            <Tooltip title="Shown data for current month">
    //                               <Radio.Button
    //                                  value={1}
    //                                  onClick={() => {
    //                                     handleChangeDate(1, null);
    //                                  }}
    //                               >
    //                                  {dayjs().format("MMM-YY")}
    //                               </Radio.Button>
    //                            </Tooltip>
    //                            <Tooltip title="Shown data of last month">
    //                               <Radio.Button
    //                                  value={2}
    //                                  onClick={() => {
    //                                     handleChangeDate(2, null);
    //                                  }}
    //                               >
    //                                  {dayjs().subtract(1, "month").format("MMM-YY")}
    //                               </Radio.Button>
    //                            </Tooltip>

    //                            <Tooltip title="Shown data for selected months.">
    //                               <Radio.Button value={4} style={{ float: "right", width: 220 }}>
    //                                  Select range of date
    //                                  {filterMode === 4 ? (
    //                                     <RangePicker
    //                                        size={"default"}
    //                                        style={{ float: "right", marginRight: -15, width: 220 }}
    //                                        onChange={(date, dateString) => {
    //                                           handleChangeDate(4, dateString);
    //                                           setFilterMode(4);
    //                                        }}
    //                                     />
    //                                  ) : null}
    //                               </Radio.Button>
    //                            </Tooltip>
    //                         </div>
    //                         {/* </Space> */}
    //                      </Radio.Group>
    //                   </ButtonGroup>
    //                </Row>
  };

  return (
    <div style={{ margin: '0px' }}>
      <br></br>
      <Row>
        <Col span={22} offset={1}>
          <div
            className='qaqc_dashboard_card'
            style={{ padding: '0px', marginBottom: '25px' }}
          >
            <CardHeader headerText='QM Productivity' />
            {static_report_worker !== undefined ? (
              <StaticInfo static_report={static_report_worker} />
            ) : null}

            <br></br>
            <div style={{ padding: '15px' }}>
              {/* <hr></hr> */}
              <Row>
                <Col span={7}>
                  <ChartProductivityRange
                    data={data_productivity_range}
                    rangeSelected={rangeSelected}
                    setRangeSelected={setRangeSelected}
                  />
                </Col>
                <Col span={16} offset={1}>
                  <TableGroupByUnit
                    data={groupbyUnit}
                    rangeSelected={rangeSelected}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={1}></Col>
        <Col span={11}>
          <div
            className='qaqc_dashboard_card'
            style={{ height: '650px', padding: '0px' }}
          >
            <CardHeader headerText='Unit handed over' />
            {static_report_handover !== undefined ? (
              <StaticInfo static_report={static_report_handover} />
            ) : null}
            <br></br>
            <br></br>

            <ChartBlockUnitStatus data={tempDataHandBlockUnit} />
          </div>
        </Col>
        <Col span={11}>
          <div
            className='qaqc_dashboard_card'
            style={{ height: '650px', padding: '0px' }}
          >
            <CardHeader headerText='BCA Inspection' />
            {static_report_bca !== undefined ? (
              <StaticInfo static_report={static_report_bca} />
            ) : null}

            <br></br>
            <ChartScoreRange data={list_score_range} />
            <TableScoreByInspector data={inspector_score} />
          </div>
        </Col>
      </Row>
      <CostCodeUnitTableSummary id={id} />
      <br></br>
    </div>
  );
};

export default QMWorksIdd;
