import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FileFilled, FileOutlined, FolderOutlined } from '@ant-design/icons'

export default function FileLevelCell(props) {

    const cellValue = props?.valueFormatted ? props?.valueFormatted : props?.value;
    useEffect(() => {
        console.log(props)
    }, [])

    return (
        <div  style={{display:'flex', alignItems:'center' , gap:5}} >
          {props.node.level<=2?  <FolderOutlined /> : <FileOutlined/>}
            <span> {cellValue}</span> 
        </div>
    );
};