import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import dayjs from "dayjs";
import { Func_Export_Summary_By_Trade_To_Excel } from "../../function/func_export_to_excel";

const TableTradeSummary = ({ data, setTradeSelected }) => {
   var lastMonth = dayjs().subtract("1", "month").format("MMM");
   const [checkHighFail, setCheckHighFail] = useState(true);
   let highFailData = [];
   if (data !== undefined) {
      highFailData = data.filter((e) => e.percent >= 8);
   }
   const columns = [
      {
         title: "Trade",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 160,
         ellipsis: true,
      },

      {
         title: "no of no",
         dataIndex: "total_no",
         key: "total_no",
         align: "right",
         width: 70,
      },
      {
         title: "no of yes",
         dataIndex: "total_yes",
         key: "total_yes",
         align: "right",
         width: 70,
      },

      {
         title: "% (no/(no+yes)",
         dataIndex: "percent",
         key: "percent",
         align: "right",
         width: 70,
      },
   ];

   return (
      <div style={{ minHeight: "30vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Trade
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_Summary_By_Trade_To_Excel(data, lastMonth)}
            >
               <Icon type="download" />
            </Button>
            <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Items {">"} 8%
            </Checkbox>
         </h5>
         <Table
            dataSource={checkHighFail ? data : highFailData}
            columns={columns}
            size="small"
            scroll={{ y: "25vh" }}
            pagination={false}
            rowClassName={(record, index) => (record.percent >= 8 ? "antd_table_row_red" : null)}
            onRow={(record, rowIndex) => {
               return {
                  onClick: (event) => {
                     // console.log(record.name);
                     setTradeSelected(record.name);
                  }, // click row
               };
            }}
            rowKey="name"
         />
      </div>
   );
};

export default TableTradeSummary;
