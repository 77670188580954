import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import _ from 'lodash'
import axios from "axios";
import { Icon, Sidenav, Nav, IconStack } from 'rsuite';
import { message, Tooltip } from 'antd';
import * as Workspace from 'workspace-api'
// import '../../scss/PageCss.scss'
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
let accessToken = "263295c864cd5c05757b588eca06cbd2"
let refreshToken = "a9807f291868e59358aeab58a38da4f0"
let API
class TrimbleViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewer: null,
      loading: false
    }
  }

  componentDidMount = () => {
    this.setState({ loading: true })
    axios.get(`/api/trimble/oauth/trimble-viewer-refresh-token`, { params: { token: this.props.userInfo.token } })
      .then(resToken => {
        const viewer = $("#trimbleViewer")[0];
        viewer.src =
          `https://3d.connect.trimble.com/?accessToken=${resToken.data.access_token}&refreshToken=${resToken.data.refresh_token}&projectId=CpQ6zqSkP28&modelId=${this.props.trimbleFileId}&versionId=${this.props.trimbleVersionId}`;
        viewer.onload = () => {

          this.connectTrimble(viewer)
        };

        // axios.get(`https://app31.connect.trimble.com/tc/api/2.0/users/me`, {
        //   headers: {
        //     'Authorization': `Bearer ${resToken.data.access_token}`,
        //   }
        // })
        //   .then(sss => {
        //     console.log(sss)

        //   })
        //   .catch(err => {

        //   })
      })
      .catch(err => {
        message.warning(`Can't get token`)
      })
  }

  connectTrimble = async (target) => {
    console.log("Start listener...");
    API = await Workspace.connect(target, async (event, args) => {
      //console.log("Event: ", event, args);

    }, 30000);
    await API.ui.setUI({
      name: 'MenuToolbar',
      state: 'hidden'
    });
    await API.ui.setUI({
      name: 'DetailsPanel.Views',
      state: 'hidden'
    });
    await API.ui.setUI({
      name: 'DetailsPanel.Clashes',
      state: 'hidden'
    });
    await API.ui.setUI({
      name: 'DetailsPanel.ToDos',
      state: 'hidden'
    });

    // let d = await API.ui.getUI()
    // console.log(d)
    this.setState({ viewer: target, loading: false })

  };
  handleShowOnlyRebar =async()=>{
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCSLAB' } })), { visible: false });
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCBUILDINGELEMENTPROXY' } })), { visible: false });
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCBEAM' } })), { visible: false });
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCCOLUMN' } })), { visible: false });
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCWALL' } })), { visible: false });
    await API.viewer.setObjectState(this.toSelector( await API.viewer.getObjects({ parameter: { class: 'IFCWALLSTANDARDCASE' } })), { visible: false });
  }
  handleTogglePanel = async () => {
    let models = await API.viewer.getObjects()


    // let models1 = await API.viewer.getObjects({
    //   parameter: {
    //     // clash:'IFCREINFORCINGBAR',
    //     // properties: {
    //     //  // ['Tekla Reinforcement.Name']: 'B-T1'
    //     //   // ['Reference Object.Common Type'] : 'REINFORCINGBAR'
    //     // }
    //   }
    // })
    let arrayRebar = []
    let arrayRest = { modelObjectIds: [] }
    // _.map(models, async v => {
    //   arrayRest={modelId: v.modelId,objectRuntimeIds:[]}
    //   let temp = v.objects.map(o => [o.id])
    //   // let tt = await API.viewer.convertToObjectIds(v.modelId, temp);
    //   // let tt1 = await API.viewer.convertToObjectRuntimeIds(v.modelId, tt);
    //   let res =await  API.viewer.getObjectProperties(v.modelId, ...temp)
    //   _.forEach(res,o=>{
    //     if(o.class ==='IFCREINFORCINGBAR' || o.class==='MECHANICALFASTENER'){
    //       arrayRebar.push(o)
    //     }else{
    //       _.forEach(o.id,i=>{
    //         arrayRest.objectRuntimeIds.push(i)
    //       })  
    //     }
    //   })   
    //   count --
    //   if(count ==0) {

    //   }
    // })
    let resList = [];
    models.forEach(v => {
      let temp = v.objects.map(o => [o.id])
      const response = API.viewer.getObjectProperties(v.modelId, ...temp)
      resList.push(response);
    });
    let data = await Promise.all(resList);
    _.forEach(data, (v, k) => {
      arrayRest.modelObjectIds.push({ modelId: models[k].modelId, objectRuntimeIds: [] })
      _.forEach(v, (o, k1) => {
        if (o.class === 'IFCREINFORCINGBAR' || o.class === 'MECHANICALFASTENER' || o.class === 'IFCMECHANICALFASTENER') {
          arrayRebar.push(o)
        } else {
          arrayRest.modelObjectIds[k].objectRuntimeIds.push(models[k].objects[k1].id)
          // _.forEach(o.id, i => {
          //   arrayRest.modelObjectIds[k].objectRuntimeIds.push(i)
          // })
        }
      })
      arrayRest.modelObjectIds[k].objectRuntimeIds.sort()
    })
    console.log(arrayRest)
    //  await API.viewer.setObjectState(arrayRest, { visible: false });

    // let selector1= this.toSelector(await this.getHalfObjects());
    // await API.viewer.setObjectState(selector1, { visible : false});
    // var iframe = document.getElementById("trimbleViewer");
    // var elmnt = iframe.contentWindow.document.getElementsByClassName("trimble-workspace-menu-toolbar")[0];
    // elmnt.style.display = "none";
    // $('.trimble-workspace-menu-toolbar').css("display", "none");
  }
  toSelector = (modelObjects) => {
    return { modelObjectIds: modelObjects.map(m => ({ modelId: m.modelId, objectRuntimeIds: m.objects.map(o => o.id) })) };
  }

  half = (target) => {
    return target.splice(0, Math.ceil(target.length - 3000));
  }

  getHalfObjects = async () => {
    return (await API.viewer.getObjects()).map(m => ({ modelId: m.modelId, objects: this.half(m.objects) }));
  }
  render() {
    return (
      <>
        {/* {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />} */}
        <Sidenav style={{
          position: 'absolute', zIndex: '100', height: `calc(100% - 53.78px)`, backgroundColor: 'white', width: '40px',
          borderRight: '1px solid gray'
        }}
          expanded={false}

        >
          <Sidenav.Body>
            <Nav >
              <Tooltip title="Show only rebar" placement="right" overlayStyle={{ zIndex: 10 }} mouseEnterDelay={1} >
                <Nav.Item eventKey="6" style={{ height: '40px', width: '40px' }} disabled={!this.state.viewer}
                  icon={
                    <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents', color: !this.state.modelLoaded && '#636363' }}  >
                      <Icon icon='cubes' stack="1x" style={{ top: 6 }} />
                      <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                    </IconStack>}
                  onClick={this.handleShowOnlyRebar.bind(this)} />
              </Tooltip>

            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <div style={{ position: 'absolute', height: 'calc(100% - 53.79px)', width: 'calc(100% - 40px)', left: 40 }} >

          <iframe
            src={``}
            id="trimbleViewer"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-modals allow-forms allow-same-origin"
            width="100%"
            height="100%" />
        </div>



      </>
    )
  }

}

TrimbleViewer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  }
}
export default connect(mapStateToProps)(TrimbleViewer)