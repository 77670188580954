import React, { useState, useCallback, useEffect } from 'react';
import { Button } from 'antd';
import {
  ComposedChart,
  Bar,
  LabelList,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
} from 'recharts';

const ChartScoreRange = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState();
  const activeItem = '';

  useEffect(() => {
    {
      setActiveIndex(null);
    }
  }, []);

  // const handleClick = useCallback(
  //    (entry, index) => {
  //       setActiveIndex(index);
  //       if (entry !== undefined) {
  //          setRangeSelected(entry.name);
  //       }
  //    },
  //    [setActiveIndex]
  // );

  // const handleClearSelection = () => {
  //    setRangeSelected("All");
  // };

  return (
    <div style={{ marginLeft: '15px', marginRight: '15px', height: '250px' }}>
      <ResponsiveContainer width='100%' minHeight={'250px'}>
        <ComposedChart
          data={data}
          margin={{
            top: 15,
            right: 10,
            left: -25,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke='#e4e8eb' strokeDasharray='3 3' />

          <XAxis
            dataKey='name'
            tickLine={false}
            height={70}
            dx={-10}
            dy={10}
            interval={0}
          ></XAxis>
          <Label
            value='Fail items (%)'
            position='top'
            style={{
              fontWeight: 700,
              fontSize: '16px',
            }}
          />
          <YAxis type='number' domain={[0, 'dataMax + 10']} />

          <Bar dataKey='count'>
            <LabelList
              dataKey='count'
              position='top'
              fontSize='11px'
              fill='black'
            />
            {data
              ? data.map((entry, index) => (
                  <Cell
                    fill='#82ca2d'
                    strokeWidth={0}
                    stroke='Black'
                    cursor='pointer'
                    key={index}
                  />
                ))
              : null}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartScoreRange;
