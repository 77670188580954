import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import { message } from "antd";
import concDOTempColumns from "../../function/DefineColumns/concDOTempColumns";

const TableConcDOTemp = ({ tableRef, generalState, tempData, setTempData }) => {
   const [project_id, set_project_id] = useState();
   useEffect(() => {
      const pid = localStorage.getItem("fp_project_id");
      set_project_id(pid);
   }, [generalState, tempData]);

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 750,
      columns: concDOTempColumns,
      colWidths: [85, 120, 80, 85, 175, 100, 100, 90, 150],
      stretchH: "last",
      currentRowClassName: "currentRow",
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      const parentElement = TD.parentElement;
      if (parentElement === null) {
         return;
      }
      if (row % 2 === 0) {
         Handsontable.dom.addClass(parentElement, "odd");
      }
      if (tableRef.current !== null && row === 0 && col === 0) {
         const hotTable = tableRef.current.hotInstance;
         const temp_data = hotTable.getData();
         for (let i = 0; i < temp_data.length; i++) {
            if (temp_data[i][0] !== null) {
               if (temp_data[i][9] === "exist") {
                  for (let j = 0; j < 11; j++) {
                     hotTable.setCellMeta(i, j, "className", "custom-cell-checks-exist");
                  }
               }
            }
         }
      }
   };

   const onBeforeHotChange = (changes) => {
      if (changes === null) {
         return;
      }
      if (tableRef.current !== null) {
         const hot = tableRef.current.hotInstance;
         changes.forEach(([row, column, oldValue, newValue]) => {
            const r = hot.toPhysicalRow(row);

            tempData[r].change = true;
            tempData[r][column] = newValue;
         });
         setTempData(tempData);
      } else {
         message.warn("Sorry changed data will not able to save!");
      }
   };

   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={tempData}
            afterRenderer={addClassesToRows}
            beforeChange={onBeforeHotChange}
            className="do-invoice-table handsontable_do"
         />
      </div>
   );
};

export default TableConcDOTemp;
