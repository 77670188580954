import React from 'react';
import { search, deletes, add, update } from '../../api/holiday.js';
import {
  Table,
  Divider,
  Input,
  Icon,
  Pagination,
  Modal,
  notification,
  Popconfirm
} from 'antd';
import TopActionBar from '../Common/TopActionBar';
import HolidayAntForm from '../Forms/HolidayForm';
import moment from 'moment';
import _ from 'lodash';
const { Column } = Table;

class HolidayTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      selectedRowKeys: [],
      id: null,
      record: null,
      visible: false,
      isAdd: true,
      levels: [],
      searchRequestLevel: {
        filterBy: '',
        pageIndex: 1,
        pageSize: 10,
        type: this.props.isLevel ? 1 : 2,
      },
      pagingLevel: {
        pageSize: 10,
        showSizeChanger: true,
        showTotal: this.showTotal,
        total: 1,
        showSizeChanger: true,
      },
    };
    this.onChange = this.onChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  showTotal(total) {
    return `Total ${total} items`;
  }

  async onChange(current, pageSize) {
    await this.setState((prevState) => ({
      searchRequestLevel: {
        filterBy: prevState.searchRequestLevel.filterBy,
        pageIndex: current,
        pageSize: pageSize,
        type: this.props.isLevel ? 1 : 2,
      },
    }));

    await this.getDatas();
  }

  async componentDidMount() {
    await this.getDatas();
  }

  async getDatas() {
    let res = await search(this.state.searchRequestLevel);
    await this.setState({
      levels: res.data.items,
    });

    await this.setState({
      pagingLevel: {
        pageSize: res.data.pageSize,
        showSizeChanger: true,
        showTotal: this.showTotal,
        total: res.data.totalCount,
      },
    });
  }

  async handleSearch(e) {
    await this.setState((prevState) => ({
      searchRequestLevel: {
        filterBy: e,
        pageIndex: prevState.searchRequestLevel.pageIndex,
        pageSize: prevState.searchRequestLevel.pageSize,
        isLevel: this.props.isLevel,
      },
    }));

    this.getDatas();
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  async handleDeleteItems(ids = null) {
    if (ids) {
      await deletes(ids);
    } else {
      if (this.state.rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(this.state.rows.map((x) => x.id));
    }

    this.setState({ selectedRowKeys: [] });
    this.setState({ rows: [] });

    await this.getDatas();
  }

  onSelectedRowKeysChange = (selectedRowKeys, rows) => {
    this.setState({ selectedRowKeys });
    this.setState({ rows });
  };

  handleShowDialogEdit(record) {
    this.setState({ visible: true });
    this.setState({ id: record.id });
    this.setState({ isAdd: false });
    this.setState({ record: record });
  }

  handleShowDialogAdd() {
    this.setState({ visible: true });
    this.setState({ isAdd: true });
  }

  setVisible(isVisible) {
    this.setState({ visible: isVisible });
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    return (
      <div>
        <h2>Public Holiday</h2>

        <TopActionBar
          handleSearch={this.handleSearch}
          handleShowDialogAdd={() => this.handleShowDialogAdd()}
          handleDeleteItems={() => this.handleDeleteItems()}
        />

        <Table
          loading={_.isNil(this.state.levels)}
          rowKey={(record) => record.id}
          className='antTable'
          dataSource={this.state.levels}
          bordered
          rowSelection={rowSelection}
          pagination={false}
        >
          <Column title='Holiday' dataIndex='name' key='name' />
          <Column
            title='Date'
            key='date'
            render={(record) => {
              if (record && record.date !== null) {
                return <span>{moment(record.date).format('DD/MM/YYYY')}</span>;
              } else {
                return '';
              }
            }}
          />
          {/* <Column title="Date Type" dataIndex="dateType" key="dateType" /> */}

          <Column
            width='120px'
            title='Action'
            key='action'
            dataIndex='action'
            render={(text, record) => (
              <span>
                <Icon
                  onClick={() => this.handleShowDialogEdit(record)}
                  style={{ fontSize: '20px', color: '#08c' }}
                  type='edit'
                />

                <Divider type='vertical' />
                <Popconfirm
                  title='Are you sure delete this?'
                  onConfirm={() => this.handleDeleteItems([record.id])}
                  okText='Yes'
                  cancelText='No'
                >
                  <Icon
                    style={{ fontSize: '20px', color: '#08c' }}
                    type='delete'
                  />
                </Popconfirm>
              </span>
            )}
          />
        </Table>

        <Pagination
          className='mt-2 float-right'
          onChange={this.onChange}
          onShowSizeChange={this.onChange}
          total={this.state.pagingLevel.total}
          showSizeChanger
        />

        <Modal
          title='Holiday'
          visible={this.state.visible}
          onOk={this.handleAdd}
          onCancel={this.handleCancel}
          footer={null}
        >
          <HolidayAntForm
            isLevel={this.props.isLevel}
            id={this.state.id}
            isAdd={this.state.isAdd}
            record={this.state.record}
            setVisible={() => this.setVisible()}
            getDatas={() => this.getDatas()}
          />
        </Modal>
      </div>
    );
  }
}

export default HolidayTable;
