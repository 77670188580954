import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function TimePieChartPercent(props) {

  return (
    <PieChart width={300} height={300}>
      <Pie
        data={props.dataPieChart}
        cx={150}
        cy={150}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        outerRadius={80}
        fill='#8884d8'
        dataKey='value'
      >
        {props.dataPieChart?.map((e, index) => (
          <Cell key={`cell-${index}`} fill={e.color} />
        ))}
      </Pie>

      <Tooltip />
      <Legend />
    </PieChart>
  );
}
