import {  PROJECT_DETAIL, FOLDER_DETAIL, ITEM_DETAIL, APP_REDIRECT } from "../types";


export function project(state = {}, action = {}) {
  switch (action.type) {
    case PROJECT_DETAIL:
      return { ...state, ...action.data };
    default:
      return state;
  }
}


export function folder(state = {}, action = {}) {
  switch (action.type) {
    case FOLDER_DETAIL:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function item(state = {}, action = {}) {
  switch (action.type) {
    case ITEM_DETAIL:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function redirect(state = {}, action = {}) {
  switch (action.type) {
    case APP_REDIRECT:
      return { ...state, ...action.data };
    default:
      return state;
  }
}