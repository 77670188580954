import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import Chart from 'react-google-charts';
import { Card, List, Col, Row, Typography, DatePicker } from 'antd';
import mapboxgl from 'mapbox-gl';
import _ from 'lodash'
import { Statistic, Flag } from 'semantic-ui-react'
import { Icon, Loader } from 'rsuite';
import { TableUsage, TableAPICall } from '../module_BimApp/tables';
import { ColumnUsage, ColumnAPICall } from '../module_BimApp/columns';
import {
  checkWohhupUser,
} from "../module_BimApp/function/AdminFunction";
import axios from "axios";
const { Text } = Typography;
const { RangePicker } = DatePicker;
let map = null;
let listMarker = []
class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataRealTime: [
        ['time', 'users'],
        [moment().format('HH:mm:ss'), this.props.dataUser.length]
      ],
      dataDevice: [
        ['Device', 'amount'],
        ['PC', 0],
        ['Mobile', 0]
      ],
      lng: 103.813793,
      lat: 1.364053,
      zoom: 10,
      userAction: 'no data',
      projectActive: 'no data',
      folderActive: 'no data',
      itemActive: 'no data',
      functionActive: 'no data',
      columnsUsage: [],
      data: [],
      columnsApiCall: [],
      dataAPICall: [],
      projectsName: {},
      usageDateStart: moment().startOf('day'),
      usageDateEnd: moment().endOf('day'),
      apiCallDateStart: moment().startOf('day'),
      apiCallDateEnd: moment().endOf('day'),
      userPieChart: [],
      totalUser: 0,
      projectUser: []
    }
    this.mapContainer = React.createRef();
  }
  componentWillMount = () => {
    let _this = this
    setInterval(() => {
      let temp = this.state.dataRealTime
      if (_this.state.dataRealTime.length > 9)
        temp.splice(1, 1)
      temp.push([moment().format('HH:mm:ss'), _this.props.dataUser.length])
      _this.setState({ dataRealTime: temp })
    }, 5000);
    setInterval(() => {
      let isMobileCount = 0
      let isPCCount = 0
      _.forEach(_this.props.dataUser, v => {
        if (v.isMobile) isMobileCount = isMobileCount + 1
        else isPCCount = isPCCount + 1
      })
      let temp = [
        ['Device', 'amount'],
        ['PC', isPCCount],
        ['Mobile', isMobileCount]
      ]
      _this.setState({ dataDevice: temp })
    }, 5000);
    this.props.handleLoading(false)
    this.handleUsage()
  }

  handleUsage = async () => {
    let dateId = moment().format('DD-MM-YYYY')
    try {
      let projectsRes = await axios.get("/api/users/all-project-display-name")
      let usageRes = await axios.get("/api/users/record-usage", {
        params: { dateId, start: this.state.usageDateStart.toISOString(), end: this.state.usageDateEnd.toISOString() }
      })
      let dic = {}
      _.forEach(projectsRes.data, v => {
        dic[v.projectId] = v.projectDisplayName
      })
      let temp = []
      _.forEach(usageRes.data, i => {
        _.forEach(i.data, v => {
          if (dic[v.projectId])
            v.projectName = dic[v.projectId]
          else
            v.projectName = 'None'
          temp.push(v)
        })
      })
      let tempApi = []
      let apiCallRes = await axios.get("/api/users/record-api", {
        params: { dateId, start: this.state.apiCallDateStart.toISOString(), end: this.state.apiCallDateEnd.toISOString() }
      })
      _.forEach(apiCallRes.data, i => {
        _.forEach(i.data, v => {
          tempApi.push(v)
        })
      })
      this.setState({ projectsName: dic, columnsUsage: ColumnUsage(), data: temp, columnsApiCall: ColumnAPICall(), dataAPICall: tempApi })
    } catch {

    }
  }
  componentDidMount = () => {

    // mapboxgl.accessToken = 'pk.eyJ1IjoiYmFvcXV5bGFuIiwiYSI6ImNrNnhzY2I3dTBoMnkzZnM2MWdmcndjbngifQ.gRlKi7Q0R5YLenCHp4PuAQ';
    // map = new mapboxgl.Map({
    //   container: this.mapContainer,
    //   style: 'mapbox://styles/mapbox/streets-v11',
    //   center: [this.state.lng, this.state.lat],
    //   zoom: this.state.zoom
    // });
    // map.on('move', () => {
    //   try {
    //     this.setState({
    //       lng: map.getCenter().lng.toFixed(4),
    //       lat: map.getCenter().lat.toFixed(4),
    //       zoom: map.getZoom().toFixed(2)
    //     });
    //   }
    //   catch { }
    // });

    // _.forEach(listMarker, v => {
    //   v.remove()
    // })
    // listMarker = []
    // _.forEach(this.props.dataUser, v => {
    //   if (v.location.length === 2) {
    //     var marker = new mapboxgl.Marker().setLngLat(v.location)
    //       .setPopup(
    //         new mapboxgl.Popup({ offset: 25 }) // add popups
    //           .setHTML(
    //             `<h7 style="margin: auto;"> ${v.email}</h7>`
    //           )
    //       )
    //       .addTo(map);
    //     listMarker.push(marker)
    //   }
    // })

    axios.get(`/api/users/getusers`)
      .then(res => {
        console.log(res.data)
        let tempUser = [
          ['User Type', 'Count'],
          ['Wohhup User', 0],
          ['External User', 0]
        ]
        _.forEach(res.data, v => {
          if (checkWohhupUser(v.email)) {
            tempUser[1][1] += 1
          } else {
            tempUser[2][1] += 1
          }
        })
        this.setState({
          totalUser: res.data.length,
          userPieChart: tempUser
        })
        this.props.handleLoading(false)

      })
      .catch(err => {
        this.props.handleLoading(false)
        console.log(err)
      })


    axios.get(`/api/projects`, { params: { token: this.props.userInfo.token, email: this.props.userInfo.email, isMember: true } })
      .then(res => {
        let temp = []
        _.forEach(res.data.project, item => {
          if(item.members.length!==0){
            let data = { name: item.projectName, members: item.members, 'Wohhup User': 0, 'External User': 0 }
            _.forEach(item.members, v => {
              if (checkWohhupUser(v))
                data['Wohhup User'] += 1
              else
                data['External User'] += 1
  
            })
            temp.push(data)
          }
  
        })
        this.setState({
          projectUser: temp,
        })
      })
      .catch(res => {
        this.props.handleLoading(false)
      })

    this.props.handleLoading(false)
  }
  componentWillReceiveProps = (nextProps) => {
    if (map !== null) {
      _.forEach(listMarker, v => {
        v.remove()
      })
      listMarker = []
      _.forEach(nextProps.dataUser, v => {
        if (v.location.length === 2) {
          var marker = new mapboxgl.Marker().setLngLat(v.location)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `<h7 style="margin: auto;"> ${v.email}</h7>`
                )
            )
            .addTo(map);
          listMarker.push(marker)
        }
      })
    }

  }
  componentWillUnmount = () => {
    map = null
    listMarker = []
  }

  handleUserAction = (data) => {
    this.setState({
      userAction: data.email,
      projectActive: data.projectName !== null ? data.projectName : 'no data',
      folderActive: data.folderName !== null ? data.folderName : 'no data',
      itemActive: data.itemName !== null ? data.itemName : 'no data',
      functionActive: data.functionName !== null ? data.functionName : 'no data',
    })
  }
  onChangeUsageDate = (date, dateString) => {
    this.setState({ usageDateStart: date[0].startOf('day'), usageDateEnd: date[1].endOf('day') }, async () => {
      let dateId = moment().format('DD-MM-YYYY')
      let usageRes = await axios.get("/api/users/record-usage", {
        params: { dateId, start: this.state.usageDateStart.toISOString(), end: this.state.usageDateEnd.toISOString() }
      })
      let temp = []
      _.forEach(usageRes.data, i => {
        _.forEach(i.data, v => {
          if (this.state.projectsName[v.projectId])
            v.projectName = this.state.projectsName[v.projectId]
          else
            v.projectName = 'None'
          temp.push(v)
        })
      })
      this.setState({ data: temp })
    })

  }
  onChangeAPIDate(date, dateString) {
    this.setState({ apiCallDateStart: date[0].startOf('day'), apiCallDateEnd: date[1].endOf('day') }, async () => {
      let dateId = moment().format('DD-MM-YYYY')
      let tempApi = []
      let apiCallRes = await axios.get("/api/users/record-api", {
        params: { dateId, start: this.state.apiCallDateStart.toISOString(), end: this.state.apiCallDateEnd.toISOString() }
      })
      _.forEach(apiCallRes.data, i => {
        _.forEach(i.data, v => {
          tempApi.push(v)
        })
      })
      this.setState({ dataAPICall: tempApi })
    })
  }
  render() {
    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <Card type="inner" style={{ height: '300px', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }} bodyStyle={{ padding: 0 }} >
              <RangePicker onChange={this.onChangeUsageDate} value={[this.state.usageDateStart, this.state.usageDateEnd]} />
              <TableUsage
                columns={this.state.columnsUsage}
                data={this.state.data}
                // defaultPageSize={1000000000}
                isDoubleClick={false}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Card type="inner" style={{ height: '300px', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }} bodyStyle={{ padding: 0 }} >
              <RangePicker onChange={this.onChangeAPIDate} value={[this.state.apiCallDateStart, this.state.apiCallDateEnd]} />
              <TableUsage
                columns={this.state.columnsApiCall}
                data={this.state.dataAPICall}
                // defaultPageSize={1000000000}
                isDoubleClick={false}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16}>
          <Card type="inner" style={{ height: '500px', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }} bodyStyle={{ padding: 0, display: 'flex', flexWrap: 'wrap' }} >
            {this.state.projectUser.map(i => {
              let tempUser = [
                ['User Type', 'Count'],
                ['Wohhup User', i['Wohhup User']],
                ['External User', i['External User']]
              ]
              return (
                <Chart
                  width={'250px'}
                  height={'250px'}
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={tempUser}
                  options={{
                    title: i.name + ` (${i.members.length})`,
                    legend: {
                      position: 'bottom'
                    }
                  }}
                // rootProps={{ 'data-testid': '1' }}
                />
              )
            }
            )}
          </Card>
        </Row>

        <Row gutter={16}>
          <Col span={7}>
            <Card type="inner" style={{ height: '300px', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }} >
              <List bordered
                size="small"
                dataSource={this.props.dataUser}
                renderItem={item => (
                  <List.Item >
                    <List.Item.Meta onClick={this.handleUserAction.bind(this, item)}
                      title={<a>{item.email}</a>}
                      description={<div>
                        <Flag name={item.country} />
                        <Text type="secondary">IP: {item.IP}{'  '}</Text>
                        <div style={{ float: 'right' }}>

                          <Icon style={{ color: item.isMobile ? 'red' : 'blue' }} icon={item.isMobile ? 'mobile' : 'pc'} />{' '}
                          <Icon icon={item.browser === 'edge' ? 'chrome' : item.browser} />{' '}
                          <Icon icon={item.system} />{' '}
                        </div>

                      </div>}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col span={9}>
            <Card type="inner" style={{ height: '300px', margin: 15, right: 0, boxShadow: '3px 4px 13px 0px #bab2b2' }}
              bodyStyle={{ height: '100%' }}>
              <Text strong>
                <Icon icon='user' />{' '}
                User Name:
              </Text>
              <Text type="secondary"> {this.state.userAction}</Text><br />
              <p />
              <Text strong>
                <Icon icon='project' />{' '}
                Project Name:
              </Text>
              <Text type="secondary"> {this.state.projectActive}</Text><br />
              <p />
              <Text strong>
                <Icon icon='folder' />{' '}
                Folder Name:
              </Text>
              <Text type="secondary"> {this.state.folderActive}</Text><br />
              <p />
              <Text strong>
                <Icon icon='file' />{' '}
                Item Name:
              </Text>
              <Text type="secondary"> {this.state.itemActive}</Text><br />
              <p />
              <Text strong>
                <Icon icon='terminal' />{' '}
                Function Name:
              </Text>
              <Text type="secondary"> {this.state.functionActive}</Text><br />
              <p />
            </Card>
          </Col>
          <Col span={8}>
            <Card type="inner" style={{ height: '300px', margin: 15, right: 0, boxShadow: '3px 4px 13px 0px #bab2b2' }}
              bodyStyle={{ height: '100%', padding: 0 }}>

              {/* <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100%' }} >
                <div className='sidebarStyle'>
                  <div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}</div>
                </div></div> */}
              <Chart
                width={'450px'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.state.userPieChart}
                options={{
                  title: `Total user: ${this.state.totalUser}`,
                  legend: {
                    position: 'bottom'
                  }
                }}
              // rootProps={{ 'data-testid': '1' }}
              />

            </Card>
          </Col>

        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Card type="inner" style={{ height: '300px', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }} bodyStyle={{ padding: 1 }} >
              <Chart
                width={'910px'}
                height={'290px'}
                chartType="LineChart"
                loader={<Loader backdrop center content="Loading..." speed="fast" size="md" vertical />}
                data={this.state.dataRealTime}
                options={{
                  legend: { position: 'top', alignment: 'start' },
                  lineWidth: 3,
                  intervals: { style: 'line' },
                  vAxis: {

                    viewWindow: { min: 0 },
                    format: '0',

                  },
                  hAxis: {

                  },
                }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card type="inner" style={{ height: '300px', margin: 15, boxShadow: '3px 4px 13px 0px #bab2b2' }} bodyStyle={{ padding: 0, width: '100%', height: '100%' }}>
              {/* <Chart
                width={'200px'}
                height={'200px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={this.state.dataDevice}
                options={{
                  legend: 'none',
                  pieSliceText: 'label',
                  pieStartAngle: 100,
                  pieHole: 0.7,
                }}
              /> */}
              <Statistic color='green' style={{ position: 'absolute', left: 'calc(50% - 40.5px)', top: 'calc(50% - 82.4px)' }}>
                <Statistic.Value>
                  <Icon icon='peoples' size="5x" />{' '}{this.props.dataUser.length}
                </Statistic.Value>
                <Statistic.Label>Users</Statistic.Label>
              </Statistic>
              <Statistic.Group size={'large'} style={{ position: 'absolute', left: 'calc(50% - 120.5px)', top: 'calc(50% - 10.4px)' }}>
                <Statistic color='blue'>
                  <Statistic.Value>
                    <Icon icon='pc' size="5x" />{' '}{this.state.dataDevice[1][1]}
                  </Statistic.Value>
                  <Statistic.Label>PC</Statistic.Label>
                </Statistic>
                <Statistic color='red'>
                  <Statistic.Value>
                    <Icon icon='mobile' size="5x" />{' '}{this.state.dataDevice[2][1]}
                  </Statistic.Value>
                  <Statistic.Label>Mobile</Statistic.Label>
                </Statistic>
              </Statistic.Group>

            </Card>
          </Col>
        </Row>
      </div>

    );
  }
}

DashBoard.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email,
    email: state.user.email,
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(DashBoard)