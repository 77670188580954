import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

// import ForgeViewerTimeLine from "../BimApp/ForgeViewerTimeLine";

import {
  trackingUser,
  storeHistory,
  checkWohhupUser,
} from "../../../components/module_BimApp/function/AdminFunction";

class TimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    console.log("this.props", this.props);
    try {
      trackingUser(
        this.props.email,
        this.props.location.state.projectName,
        this.props.location.state.folderName,
        this.props.location.state.itemName
      );
      storeHistory(
        this.props.email,
        this.props.location.state.itemId,
        this.props.location.state.itemName
      );
    } catch {}
  }

  render() {
    let location = {
      pathname: "/projects/project/folder/view",
      state: {
        userCreatedProject: "admin@wohhup.com",
        id: "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXR5em1kZTFtenUzbmphM215MTB6eG4wbXRhLzE2MzE3MDcxMTg5NzktU1BSSU5UX1dIXzVEX1NUUlVDVFVSQUwlMjBXT1JLUy5ydnQ=",
        itemId:
          "dGVzdDEwOmZzLmZvbGRlcjo1RDppcy5pdGVtOlNQUklOVF9XSF81RF9TVFJVQ1RVUkFMIFdPUktTLnJ2dA",
        itemName: "SPRINT_WH_5D_STRUCTURAL WORKS.rvt",
        itemDisplayName: "SPRINT_WH_5D_STRUCTURAL WORKS.rvt",
        folderId: "dGVzdDEwOmZzLmZvbGRlcjo1RA",
        folderName: "5D",
        projectId: "MTYzMDE1MzU3NjA3My10ZXN0MTA",
        projectName: "test10",
        sharePointId: "jkm",
        issueKey: "test10",
        trimbleKey: "",
        smartSheetId: "7297101974529924",
        manningScheduleId: "3284660043179908",
        folderSmartSheetId: "8702671377983364",
        version: 4,
        objectKey: "1631707118979-SPRINT_WH_5D_STRUCTURAL WORKS.rvt",
        listAdmin: ["hoanghd218@gmail.com"],
        typeProject: "PPVC/PBU",
        typeFile: "5d",
        isDetail: true,
        isTrimble: false,
        trimbleFileId: "",
        useRfa: true,
      },
      search: "",
      hash: "",
      key: "f7yug3",
    };
    return (

        <div></div>
      // <ForgeViewerTimeLine
      //   objectId={location.state.id}
      //   itemId={location.state.itemId}
      //   itemName={location.state.itemName}
      //   itemDisplayName={location.state.itemDisplayName}
      //   projectId={location.state.projectId}
      //   projectName={location.state.projectName}
      //   folderName={location.state.folderName}
      //   version={location.state.version}
      //   typeFile={location.state.typeFile}
      //   projectKey={location.state.sharePointId}
      //   issueKey={location.state.issueKey}
      //   folderSmartSheetId={location.state.folderSmartSheetId}
      //   typeProject={location.state.typeProject}
      //   smartSheetId={location.state.smartSheetId}
      //   objectKey={location.state.objectKey}
      //   userCreatedProject={location.state.userCreatedProject}
      //   manningScheduleId={location.state.manningScheduleId}
      //   useFunction={{
      //     tools: [],
      //     saveView: [],
      //     coordination: ["issue", "comment"],
      //     quantities: ["quantities", "compare quantities", "status fof claim"],
      //     planing: [
      //       "timeline",
      //       "compare 4d",
      //       "highlight missing information",
      //       "report",
      //     ],
      //     precast: [],
      //     rebar: [],
      //     shareLink: [],
      //   }}
      //   isWohhupUser={checkWohhupUser(this.props.userInfo.email)}
      //   isInternalFunction={true}
      //   lastVersion={location.state.lastVersion}
      // />
    );
  }
}

TimeLine.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    userInfo: state.user,
  };
}
export default connect(mapStateToProps)(TimeLine);
