import React, { useEffect, useState } from 'react';
import { Modal, IconButton, Icon, InputPicker, Input, Button, ButtonGroup, Loader, Divider } from 'rsuite';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { Row, Col, List, Typography, Upload, message, Tooltip } from 'antd';
import { getAllModelsElementdbIdsWithCondition } from '../../function/ForgeFunction'
import { getElementAndParameterForFilter, listElementFilter, getElementAndParameterForFilterDWFX ,listElementFilterDWFX} from '../../function/ScheduleFunction'
import { convertHexColorToVector4 } from '../../function/TableFunction'
import DialogFilter from './DialogFilter'
import { Menu, Item, Separator, MenuProvider, animation, IconFont } from 'react-contexify';
import { Button as BlueprintButton, ControlGroup, InputGroup, Checkbox } from "@blueprintjs/core";
import axios from "axios";
import { v4 } from 'uuid'
import { saveAs } from 'file-saver'


import { DraggableAreasGroup } from 'react-draggable-tags'
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { Text, Title } = Typography
function DialogOverlay(props) {
  const [loading, setLoading] = useState(false)

  const [loadingCategory, setLoadingCategory] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [loadingViewListFilter, setLoadingViewListFilter] = useState(false)
  const [loadingListFilterSet, setLoadingListFilterSet] = useState(false)
  const [category, setCategory] = useState([])
  const [allDbIds, setAllDbIds] = useState([])

  const [listViewFilter, setListViewFilter] = useState([])
  const [listFilterSet, setListFilterSet] = useState([])
  const [listViewFilterSeted, setListViewFilterSeted] = useState([])
  const [listViewFilterImport, setListViewFilterImport] = useState([])
  const [selectedViewFilter, setSelectedViewFilter] = useState(null)
  const [selectedFilterSet, setSelectFilterSet] = useState(null)

  const [displayDialog, setDisplayDialog] = useState(false)
  const [displayDialogEditFilter, setDisplayDialogEditFilter] = useState(false)
  const [displayDialogAddEditFilterSet, setDisplayDialogAddEditFilterSet] = useState(false)
  const [displayDialogExport, setDisplayDialogExport] = useState(false)
  const [displayDialogImport, setDisplayDialogImport] = useState(false)

  useEffect(() => {
    setDisplayDialog(props.openPanel)
    setLoadingViewListFilter(true)
    axios.get("/api/viewfilter/get-view-filter", {
      params: {
        itemId: props.itemId, projectId: props.projectId, email: props.email,
        type: props.viewer.impl.model.myData.loadOptions.fileExt
      }
    })
      .then(res => {
        let temp = []
        _.forEach(res.data, v => {
          v.viewFilter.isSelected = false
          v.viewFilter.isSet = false
          v.viewFilter.isModify = false
          v.viewFilter.isSearch = true
          temp.push(v.viewFilter)
        })
        setListViewFilter(temp)
        setLoadingViewListFilter(false)
      })
      .catch(() => {
        message.error('Get data failed')
        props.onChangeDisplay('dialogOverlay', false)
      })
  }, [])
  useEffect(() => {
    setLoadingListFilterSet(true)
    axios.get("/api/viewfilter/get-view-filter-set", { params: { itemId: props.itemId, projectId: props.projectId, email: props.email } })
      .then(res => {
        let temp = []
        _.forEach(res.data, v => {
          v.value = v.guid
          temp.push(v)
        })
        setListFilterSet(temp)
        setLoadingListFilterSet(false)
      })
      .catch(() => {
        message.error('Get data failed')
        props.onChangeDisplay('dialogOverlay', false)
      })
  }, [])

  useEffect(() => {
    setLoadingCategory(true)
    fetchData()
    async function fetchData() {
      let allDbIds = []
      let tempListModel = {}
      let listModel = props.viewer.impl.modelQueue().getModels();
      _.forEach(listModel, (model) => {
        let instanceTree = model.getData().instanceTree;
        let temp = getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all');
        allDbIds.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
      })
      let count = 0;
      allDbIds.forEach((item) => {
        count = count + item.dbIds.length
        tempListModel[item.modelName] = item.model
      })
      let data = []
      if (props.viewer.impl.model.myData.loadOptions.fileExt === "wfx") {
        data = await getElementAndParameterForFilterDWFX(allDbIds, count)
      } else {
        data = await getElementAndParameterForFilter(allDbIds, count)
      }

      setAllDbIds(allDbIds)
      setLoadingCategory(false)
      setCategory(data.category)
    }
  }, [])


  const handleClosePanel = () => {
    setDisplayDialog(false)
    props.onChangeDisplay('dialogOverlay', false)
  }

  const handleChangeVisibleFilter = (item, e) => {
    item.check = e.target.checked
    setListViewFilterSeted(listViewFilterSeted.slice(0))
  }

  const handleColorFilter = (item, e) => {
    let temp = listViewFilterSeted.slice(0)
    let index = _.findIndex(temp, function (o) { return o.guid === item.guid })
    if (index >= 0) {
      temp[index].color = e.target.value
    }
    setListViewFilterSeted(listViewFilterSeted.slice(0))
  }
  const handleAddFilterSet = (item) => {
    let tempSet = listViewFilterSeted.slice(0)
    let index = _.findIndex(tempSet, function (o) { return o.guid === item.guid })
    if (index < 0) {
      tempSet.push({ id: item.guid, guid: item.guid, content: item.filterName, item: item, color: 'white', check: true })
    } else {
      message.warning('Filter already set')
    }
    item.isSet = true
    setListViewFilterSeted(tempSet)
  }
  const handleRemoveFilterSeted = (check, item) => {
    let tempSet = listViewFilterSeted.slice(0)
    if (!check) {
      let index = _.findIndex(tempSet, function (o) { return o.guid === item.props.item.guid })
      if (index >= 0) {
        tempSet.splice(index, 1)
      }
      let temp = listViewFilter.slice(0)
      let index1 = _.findIndex(temp, function (o) { return o.guid === item.props.item.guid })
      if (index1 >= 0) {
        temp[index1].isSet = false
        setListViewFilter(temp)
      }
    } else {
      let index = _.findIndex(tempSet, function (o) { return o.guid === item.guid })
      if (index >= 0) {
        tempSet.splice(index, 1)
      }
      item.isSet = false
    }
    setListViewFilterSeted(tempSet)
  }

  const handleSaveApplyFilter = () => {
    if (listViewFilterSeted.length !== 0) {
      let temp = listFilterSet.slice(0)
      let index = _.findIndex(temp, e => { return e.guid === selectedFilterSet })
      if (index >= 0) {
        temp[index].listViewFilterSeted = listViewFilterSeted
        axios.post("/api/viewFilter/set-view-filter-set", { itemId: props.itemId, projectId: props.projectId, email: props.email, data: temp[index] })
          .then(async () => {
            handleApplyFilter()
          })
          .catch(() => {
            message.error('Set data failed')
            // props.onChangeDisplay('dialogOverlay', false)
          })
      }
    } else {
      // message.error('Get data failed')
    }
  }
  const handleApplyFilter = () => {
    props.viewer.showAll()
    let listModel = props.viewer.impl.modelQueue().getModels();
    _.forEach(listModel, v => {
      props.viewer.clearThemingColors(v.model)
    })
    let tempListCategory = []
    let tempListParameter = []
    let count = listViewFilterSeted.length
    _.forEach(listViewFilterSeted, async v => {
      _.forEach(v.item.listCondition, v => {
        tempListParameter.push(v.parameter)
      })
      if (v.item.rule === 'AND (All rules must be true)') {
        _.forEach(v.item.category, o => {
          if (o.checked)
            tempListCategory.push(o.label)
        })
      } else {
        // _.forEach(v.listCondition, o => {
        //   tempListCategory.push(o.category)
        // })
        _.forEach(v.item.category, o => {
          if (o.checked)
            tempListCategory.push(o.label)
        })
      }
      console.log(v)

      let color = convertHexColorToVector4(v.color)
      let data = []
      if (props.viewer.impl.model.myData.loadOptions.fileExt === "wfx") {
        data = await  listElementFilterDWFX(props.viewer, allDbIds, tempListCategory, tempListParameter, v.item.listCondition, v.item.rule)
      }else{
         data = await listElementFilter(props.viewer, allDbIds, tempListCategory, tempListParameter, v.item.listCondition, v.item.rule)
      }   
      _.forEach(data, o => {
        if (!v.check) {
          props.viewer.hide(o.id, o.model)
        }
        props.viewer.setThemingColor(o.id, color, o.model)
      })
      count--
      if (count === 0) {
        handleClosePanel()
      }
    })

  }
  //#region edit view filter panel
  const handleEditViewFilter = (check, item) => {
    if (!check) {
      item.props.item.isSelected = true
      setSelectedViewFilter(item.props.item)
    } else {
      item.isSelected = true
      setSelectedViewFilter(item)
    }
    setDisplayDialogEditFilter(true)
    setDisplayDialog(false)
  }

  const handleEditFilter = () => {
    setSelectedViewFilter(null)
    setDisplayDialogEditFilter(true)
    setDisplayDialog(false)
  }

  const handleCloseEditFilter = () => {
    let temp = listViewFilter.slice(0)
    _.forEach(temp, v => {
      v.isSelected = false
    })
    setListViewFilter(temp)
    setDisplayDialogEditFilter(false)
    setDisplayDialog(true)
  }
  const handleSaveFilter = (data) => {
    let temp = listViewFilter.slice(0)
    let index = _.findIndex(temp, e => { return e.guid === data.viewFilter.guid })
    data.viewFilter.isSearch = searchInput === '' ? true : data.viewFilter.filterName.includes(searchInput)
    if (index >= 0) {
      temp.splice(index, 1)
      temp.splice(index, 0, data.viewFilter)
    } else {
      temp.push(data.viewFilter)
    }

    let temp1 = listViewFilterSeted.slice(0)
    let index1 = _.findIndex(temp1, e => { return e.guid === data.viewFilter.guid })
    if (index1 >= 0) {
      temp1[index1].content = data.viewFilter.filterName
    }
    setDisplayDialogEditFilter(false)
    setDisplayDialog(true)
    setListViewFilter(temp)
    setListViewFilterSeted(temp1)
  }
  const handleRemoveViewFilter = (data) => {
    setLoading(true)
    axios.post("/api/viewFilter/delete-view-filter", { itemId: props.itemId, projectId: props.projectId, email: props.email, guid: data.guid })
      .then(res => {
        let temp = listViewFilter.slice(0)
        let index = _.findIndex(temp, e => { return e.guid === res.data.guid })
        if (index >= 0) {
          temp.splice(index, 1)
        }

        let temp1 = listViewFilterSeted.slice(0)
        let index1 = _.findIndex(temp1, e => { return e.guid === res.data.guid })
        if (index1 >= 0) {
          temp1.splice(index1, 1)
        }
        setListViewFilter(temp)
        setListViewFilterSeted(temp1)
        setLoading(false)
      })
      .catch(() => {
        message.error(`Can't delete view filter`)
        setLoading(false)
      })
  }
  //#endregion

  //#region filter set
  const handleChangeFilterSet = (value) => {
    let temp = listFilterSet.slice(0)
    let temp1 = listViewFilter.slice(0)
    let index = _.findIndex(temp, e => { return e.guid === value })
    if (index >= 0) {
      _.forEach(temp1, v => {
        v.isSet = false
      })
      _.forEachRight(temp[index].listViewFilterSeted, (v, k) => {
        let index1 = _.findIndex(temp1, e => { return e.guid === v.guid })
        if (index1 >= 0) {
          temp1[index1].isSet = true
          v.item = temp1[index1]
        } else {
          temp[index].listViewFilterSeted.splice(k, 1)
        }
      })
      setListViewFilter(temp1)
      setListViewFilterSeted(temp[index].listViewFilterSeted)
      setSelectFilterSet(value)
    }

  }
  const handleDisplayAddEditPanel = () => {
    setDisplayDialog(false)
    setDisplayDialogAddEditFilterSet(true)
  }
  const handleCloseFilterSetPanel = () => {
    setDisplayDialog(true)
    setDisplayDialogAddEditFilterSet(false)
  }
  const handleAddNewFilterSet = (data) => {
    let temp = listFilterSet.slice(0)
    let index = _.findIndex(temp, e => { return e.label === data })
    if (index < 0) {
      let guid = v4()
      temp.push({ guid: guid, label: data, text: data, value: guid, listViewFilterSeted: [] })
      setListFilterSet(temp)
      handleCloseFilterSetPanel()
    } else {
      message.warning('Filter Set Name was existing')
    }

  }
  const handleRemoveFilterSet = () => {
    if (!window.confirm('Are you sure to remove this filter set?')) return
    if (selectedFilterSet !== null) {
      axios.post("/api/viewFilter/delete-view-filter-set", { itemId: props.itemId, projectId: props.projectId, email: props.email, guid: selectedFilterSet })
        .then(res => {
          let temp = listViewFilter.slice(0)
          let temp1 = listFilterSet.slice(0)
          let index = _.findIndex(temp1, e => { return e.guid === res.data.guid })
          if (index >= 0) {
            temp1.splice(index, 1)
          }
          _.forEach(temp, v => {
            v.isSet = false
          })
          setSelectFilterSet(null)
          setListViewFilterSeted([])
          setListViewFilter(temp)
          setListFilterSet(temp1)
        })
        .catch(() => {
          message.error(`Can't delete filter set`)
        })

    } else {
      message.warning('You need to pick one view filter')
    }
  }
  //#endregion

  //#region handle search
  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value)
  }
  const handleSearchViewFilter = () => {
    let temp = listViewFilter.slice(0)
    if (searchInput !== '') {
      _.forEach(temp, v => {
        if (v.filterName.includes(searchInput))
          v.isSearch = true
        else
          v.isSearch = false
      })
    } else {
      _.forEach(temp, v => {
        v.isSearch = true
      })
    }
    setListViewFilter(temp)
  }
  //#endregion

  //#region export
  const handleDisplayExportPanel = () => {
    setDisplayDialog(false)
    setDisplayDialogExport(true)
  }
  const handleCloseExportPanel = () => {
    setDisplayDialog(true)
    setDisplayDialogExport(false)
  }

  const handleCloseImportPanel = (check = false) => {
    setDisplayDialog(true)
    setDisplayDialogImport(false)
    if (check)
      props.onChangeDisplay('dialogOverlay', false)
  }
  const handleFilterFileImport = ({ file }) => {
    if (file !== null) {
      let reader = new FileReader();
      reader.onload = function () {
        let content = reader.result;
        let json = JSON.parse(content)
        setListViewFilterImport(json)
        setDisplayDialog(false)
        setDisplayDialogImport(true)
      }
      reader.readAsText(file);
    }
  }
  //#endregion
  return (
    <>
      <Modal show={displayDialog} onHide={handleClosePanel} size='lg' backdrop='static'>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Modal.Header>

          <Title style={{ display: 'inline', marginLeft: '5px' }} level={4} >Overlay</Title>
        </Modal.Header>
        <Divider className='custom-divider' />
        <Modal.Body id='dialog-filter'>
          <Row gutter={8}>
            <Col span={12}>
              <div style={{ marginTop: 5 }}>
                <ControlGroup fill={true} vertical={false}>
                  <BlueprintButton icon="add" intent="none" onClick={handleEditFilter} loading={loadingCategory} />
                  <BlueprintButton icon='export' intent="none" onClick={handleDisplayExportPanel} />
                  <Upload name='file' className='custom-upload-filter'
                    multiple={false} customRequest={handleFilterFileImport}
                    showUploadList={false}
                    accept='.json'
                  >
                    <BlueprintButton icon='import' intent="none" style={{ width: 60, display: 'inline-grid' }} />
                  </Upload>

                  <InputGroup placeholder="Search" onChange={handleChangeSearchInput} value={searchInput} />
                  <BlueprintButton icon="search" intent="primary" onClick={handleSearchViewFilter} />
                </ControlGroup>
              </div>
              <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} >
                <List
                  size="small"
                  loading={loadingViewListFilter}
                  dataSource={listViewFilter}
                  renderItem={(item) => {
                    return (
                      item.isSearch ? <MenuProvider id="filter_menu_overlay" animation={animation.zoom} data={{ item: item, filterName: item.filterName }}>
                        <List.Item style={{ marginBottom: '5px', padding: 0 }}                >
                          <Text strong ellipsis={true} style={{ cursor: 'pointer' }}
                          >{item.isSet ? `🟢 ${item.filterName}` : `🔴 ${item.filterName}`}</Text>
                          <ButtonGroup style={{ position: 'absolute', right: 0 }}>
                            {item.isSet ?
                              <IconButton icon={< Icon icon={'minus-circle'} />} size='xs' color={'red'}
                                onClick={handleRemoveFilterSeted.bind(this, true, item)} disabled={selectedFilterSet === null} />
                              :
                              <IconButton icon={< Icon icon={'plus-circle'} />} size='xs' color={'blue'}
                                onClick={handleAddFilterSet.bind(this, item)} disabled={selectedFilterSet === null} />}
                            <IconButton icon={< Icon icon="edit" />} size='xs' onClick={handleEditViewFilter.bind(this, true, item)}
                              disabled={item.itemId !== props.itemId} loading={loadingCategory} />
                          </ButtonGroup>
                        </List.Item>
                      </MenuProvider>
                        : <></>
                    )
                  }}
                />
              </Segment>
            </Col>
            <Col span={12}>
              <div style={{ width: 'calc(100% - 70px)', display: 'inline-block', marginRight: 5, marginTop: 3 }}>
                <InputPicker
                  data={listFilterSet} cleanable={false} onChange={handleChangeFilterSet} value={selectedFilterSet}
                  block size="sm"
                  renderMenu={menu => {
                    if (loadingListFilterSet) {
                      return (
                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                          <Icon icon="spinner" spin /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                  renderValue={(value, item) => {
                    return (
                      <div style={{ color: 'gray' }} >
                        {item.label}
                      </div>
                    );
                  }} />
              </div>
              <div style={{ display: 'inline' }} >
                <ButtonGroup>
                  <IconButton icon={< Icon icon="plus" />} size='sm' onClick={handleDisplayAddEditPanel} />
                  <IconButton icon={< Icon icon="trash" />} size='sm' color='red' onClick={handleRemoveFilterSet} disabled={selectedFilterSet === null} />
                </ButtonGroup>
              </div>
              <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} >
                {listViewFilterSeted.length !== 0 &&
                  <div className="List">
                 <div className="main-drag">
                      <DraggableArea
                        isList
                        tags={listViewFilterSeted}
                        render={({ tag }) => (
                          <MenuProvider id="filter_set_menu" animation={animation.zoom} data={{ item: tag }}>
                            <div className="row">
                              <Checkbox style={{ fontSize: 'small', marginBottom: 0 }} label={tag.content} onChange={handleChangeVisibleFilter.bind(this, tag)}
                                checked={tag.check} />
                              <input type='color' style={{ position: 'absolute', right: 10, top: 7 }} value={tag.color}
                                onChange={handleColorFilter.bind(this, tag)} />
                            </div>
                          </MenuProvider>

                        )}
                        onChange={(listViewFilterSeted) => setListViewFilterSeted(listViewFilterSeted.slice(0))}
                      />
                    </div>
                  </div>}
              </Segment>


            </Col>
          </Row>
        </Modal.Body>
        <Divider className='custom-divider' />
        <Modal.Footer>
          <Button onClick={handleClosePanel} > Cancel</Button>
          <Button appearance="primary" onClick={handleApplyFilter} disabled={listViewFilterSeted.length === 0}> Apply</Button>
          <Button appearance="primary" onClick={handleSaveApplyFilter} disabled={listViewFilterSeted.length === 0}> Save & Apply</Button>
        </Modal.Footer>
      </Modal>
      {/* <Menu id='filter_menu_overlay' style={{ zIndex: 10000 }}>
        <Item onClick={handleAddFilterSet} disabled={selectedFilterSet === null}>
          <IconFont className="fas fa-bolt" />Set Filter
     </Item>
        <Item onClick={handleEditViewFilter}>
          <IconFont className="fas fa-edit" />Edit Filter
     </Item>
      </Menu> */}

      <Menu id='filter_set_menu' style={{ zIndex: 10000 }}>
        <Item onClick={handleEditViewFilter.bind(this, false)} >
          <IconFont className="fas fa-edit" />Edit Filter
     </Item>
        <Separator />
        <Item onClick={handleRemoveFilterSeted.bind(this, false)}>
          <IconFont className="fas fa-trash" />Remove
     </Item>
      </Menu>
      {displayDialogEditFilter &&
        <DialogFilter
          data={listViewFilter}
          viewer={props.viewer}
          itemId={props.itemId}
          projectId={props.projectId}
          openPanel={displayDialogEditFilter}
          handleClose={handleCloseEditFilter}
          handleSave={handleSaveFilter}
          handleRemoveViewFilter={handleRemoveViewFilter}
          selectedFilter={selectedViewFilter}
          category={category}
        />}

      {displayDialogAddEditFilterSet &&
        <AddEditFilterSet
          openPanel={displayDialogAddEditFilterSet}
          handleAddNewFilterSet={handleAddNewFilterSet}
          close={handleCloseFilterSetPanel}
        />}

      {displayDialogExport &&
        <ExportViewFilter
          data={listViewFilter}
          openPanel={displayDialogExport}
          close={handleCloseExportPanel}
          viewer={props.viewer}
        />}
      {displayDialogImport &&
        <ImportViewFilter
          data={listViewFilterImport}
          dataOld={listViewFilter}
          openPanel={displayDialogImport}
          close={handleCloseImportPanel}
          projectId={props.projectId}
          itemId={props.itemId}
          viewer={props.viewer}
          email={props.email}
        />}
    </>

  )
}

DialogOverlay.propTypes = {
  email: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}
export default connect(mapStateToProps, null)(DialogOverlay)

function AddEditFilterSet(props) {

  const [filterSetName, setFilterSetName] = useState('')
  const handleChangeFilterSetName = (value) => {
    setFilterSetName(value)
  }

  return (
    <Modal show={props.openPanel} onHide={props.close} size='xs' backdrop='static'>
      <Modal.Header>
        <Modal.Title>Add Filter Set</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Input size="sm" block cleanable={false} placeholder='Filter Set Name' onChange={handleChangeFilterSetName} value={filterSetName} />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={props.handleAddNewFilterSet.bind(this, filterSetName)} disabled={filterSetName.length === 0} > Add</Button>
        <Button appearance="default" onClick={props.close} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

function ExportViewFilter(props) {
  const [] = useState(false)
  const [listViewFilter, setListViewFilter] = useState([])
  useEffect(() => {
    let tempListFilter = []
    let tempData = props.data.slice(0)
    _.forEach(tempData, v => {
      v.checked = true
      tempListFilter.push(Object.assign({}, v))
    })
    setListViewFilter(tempListFilter)
  }, [])
  const handleChangeExportViewFilter = (item, e) => {
    item.checked = e.target.checked
    setListViewFilter(listViewFilter.slice(0))
  }
  const handleCheckCategory = (name) => {
    let tempListFilter = listViewFilter.slice(0)
    _.forEach(tempListFilter, item => {
      if (name === 'all') {
        item.checked = true
      } else
        item.checked = false
    })
    setListViewFilter(tempListFilter)
  }
  const handleExport = () => {
    let temp = []
    let tempListFilter = listViewFilter.slice(0)
    _.forEach(tempListFilter, item => {
      if (item.checked) {
        temp.push(item)
      }
    })
    let json = JSON.stringify(temp)
    if (temp.length > 0) {
      const buffer = Buffer.from(json);
      let blob = new Blob([buffer], { type: "application/json" });
      saveAs(blob, v4())
      props.close()
    } else {
      message.warning('You need pick at least one')
    }
  }
  return (
    <Modal show={props.openPanel} onHide={props.close} size='xs' backdrop='static'>
      <Modal.Header>
        <Modal.Title>Export</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <ButtonGroup style={{ marginTop: 0 }} size='sm' justified>
          <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'all')} > Check All</Button>
          <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'none')} > Check None</Button>
        </ButtonGroup>
        <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} >
          <List
            size="small"
            dataSource={listViewFilter}
            renderItem={(item) => {
              return (
                <List.Item style={{ marginBottom: '5px', padding: 0 }} >
                  <Tooltip title={item.filterName} placement='topLeft'>
                    <Checkbox onChange={handleChangeExportViewFilter.bind(this, item)} checked={item.checked}         >
                      <span style={{ verticalAlign: 'middle' }} ellipsis={true}>{item.filterName}</span>
                    </Checkbox>
                  </Tooltip>

                </List.Item>
              )
            }}
          />
        </Segment>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleExport}  > Export</Button>
        <Button appearance="default" onClick={props.close} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

function ImportViewFilter(props) {
  const [loading, setLoading] = useState(false)
  const [listViewFilter, setListViewFilter] = useState([])
  useEffect(() => {
    let tempListFilter = []
    let tempData = props.data.slice(0)
    _.forEach(tempData, v => {
      let index = _.findIndex(props.dataOld, e => { return e.filterName === v.filterName })
      if (index >= 0){
        v.isExist = true
      }else{
        v.isExist = false
      }
      v.checked = true
      tempListFilter.push(Object.assign({}, v))
    })
    setListViewFilter(tempListFilter)
  }, [])
  const handleChangeExportViewFilter = (item, e) => {
    item.checked = item.isExist? true: e.target.checked
    setListViewFilter(listViewFilter.slice(0))
  }
  const handleCheckCategory = (name) => {
    let tempListFilter = listViewFilter.slice(0)
    _.forEach(tempListFilter, item => {
      if (name === 'all') {
        item.checked = true
      } else
        item.checked = false
    })
    setListViewFilter(tempListFilter)
  }
  const handleImport = () => {
    let temp = []
    let tempListFilter = listViewFilter.slice(0)
    _.forEach(tempListFilter, item => {
      if (item.checked && !item.isExist) {
        let index = _.findIndex(props.data, e => { return e.filterName === item.filterName })
        if (index >= 0)
          temp.push(item)
      }
    })
    if (temp.length > 0) {
      setLoading(true)
      axios.post("/api/viewFilter/import-view-filter", {
        projectId: props.projectId, itemId: props.itemId, email: props.email, listData: temp,
        type: props.viewer.impl.model.myData.loadOptions.fileExt
      })
        .then(() => {
          message.success('Import view filters success')
          props.close(true)
          setLoading(false)
        })
        .catch(() => {
          message.error('Import view filters failed')
          setLoading(false)
        })
    } else {
      message.warning('You need pick at least one')
    }
  }
  return (
    <Modal show={props.openPanel} onHide={props.close} size='xs' backdrop='static'>
      {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
      <Modal.Header>
        <Modal.Title>Import</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <ButtonGroup style={{ marginTop: 0 }} size='sm' justified>
          <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'all')} > Check All</Button>
          <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'none')} > Check None</Button>
        </ButtonGroup>
        <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} >
          <List
            size="small"
            dataSource={listViewFilter}
            renderItem={(item) => {
              return (
                <List.Item style={{ marginBottom: '5px', padding: 0 }} >
                  <Tooltip title={item.filterName} placement='topLeft'>
                    <Checkbox onChange={handleChangeExportViewFilter.bind(this, item)} checked={item.isExist? false: item.checked}         >
                      <span style={{ verticalAlign: 'middle' , color: item.isExist&&'red'}} ellipsis={true}>{item.filterName}</span>
                    </Checkbox>
                  </Tooltip>

                </List.Item>
              )
            }}
          />
        </Segment>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleImport} loading={loading}> Import</Button>
        <Button appearance="default" onClick={props.close} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}