import React, { useState, useEffect } from "react";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import rebarInvTempColumns from "../../function/DefineColumns/rebarInvTempColumns";

const TableRebarInvTemp = ({ tableRef, generalState, tempData, setTempData }) => {
  useEffect(() => {
    var tempD = [];
    setTempData(tempD);
  }, [generalState]);

  const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
    if (tableRef.current !== null && row === 0 && col === 0) {
      const hotTable = tableRef.current.hotInstance;

      const temp_data = hotTable.getData();
      const rows = hotTable.countRenderedRows();
      for (let i = 0; i < rows; i++) {
        if (temp_data[i][1].includes("nos")) {
          for (let j = 0; j < 11; j++) {
            hotTable.setCellMeta(i, j, "className", "custom-cell-group-head");
          }
        }
      }
    }
  };

  const onBeforeHotChange = (changes) => {
    changes.forEach(([row, column, oldValue, newValue]) => {
      tempData[row].change = true;
      tempData[row][column] = newValue;
    });
    setTempData(tempData);
    return false;
  };

  const hotSettings = {
    colHeaders: true,
    rowHeaders: true,
    rowHeights: 24,
    height: 750,
    viewportRowRenderingOffset: 10,
    visibleRows: 15,
    columns: rebarInvTempColumns,
    colWidths: [85, 90, 85, 90, 145, 90, 90, 90, 90, 85, 120],
    // stretchH: "last",
    multiColumnSorting: true,
    currentRowClassName: "currentRow",
    dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
    filters: true,
    licenseKey: "non-commercial-and-evaluation",
  };

  return (
    <div>
      <HotTable
        ref={tableRef}
        settings={hotSettings}
        data={tempData}
        afterRenderer={addClassesToRows}
        beforeChange={onBeforeHotChange}
      ></HotTable>
    </div>
  );
};

export default TableRebarInvTemp;
