import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Tooltip,
  message,
  Input as AntInput,
  Typography,
  Divider,
  Badge,
} from 'antd';

import { constants } from './constants'
import _ from 'lodash';
import axios from 'axios';
import {
  IconButton,
  Checkbox,
  Icon,
  Button, DateRangePicker, ButtonGroup
} from 'rsuite';
import { setManpowerData, setManpowerLoading } from '../../actions/app/manpower'
import { timelineData } from './function/generateTimeline';

const { Search } = AntInput;
const { Text } = Typography;



function ManPowerToolbar(props) {
  const [isShowSetupTimeline, setIsShowSetupTimeline] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const handleTypeTimeLine = (type) => {
    let format = type === 'month' ? constants.monthFormat : type === 'year' ? constants.yearFormat : type === 'day' ? constants.dayFormat : constants.weekFormat
    let groupFormat = type === 'month' ? constants.yearFormat : type === 'year' ? constants.yearFormat : type === 'day' ? constants.monthFormat : constants.monthFormat
    props.setManpowerData({ typeTimeline: type, format, groupFormat })
  }
  const handleChangeTimelineDate = (e) => {
    props.setManpowerData({ timelineDate: e })
  }
  const saveData = () => {

    let data = []
    props.gridApi.api.forEachLeafNode(v => {
      let i = v.data
      if (i.isUpdate) {
        console.log(i)
        data.push({
          userId: i.userId, id: i.id, client: i.client, lead: i.lead, status: i.status, name: i.name, scope: i.scope, updatedBy: i.updatedBy,
          updatedAt: i.updatedAt, jobTitle: i.jobTitle, department: i.department
        })
      }
      delete i.isNew
      delete i.isUpdate
    })
    if (data.length !== 0) {
      props.setManpowerData({ loading: true })
      axios.post('/api/manpower/update-user', { data, token: props.userInfo.token })
        .then(() => {
          message.success('Updated data')
          axios.get('/api/manpower', { params: { token: props.userInfo.token } })
            .then(res => {
              let data = []
              let client = []
              let lead = []
              let jobTitle = []
              let department = []
              _.forEach(res.data, i => {
                if (!client.includes(i.client)) client.push(i.client)
                if (!lead.includes(i.client)) lead.push(i.lead)
                if (!jobTitle.includes(i.client)) jobTitle.push(i.jobTitle)
                if (!department.includes(i.department)) department.push(i.department)
                let newData = {
                  userId: i.userId, id: i.id, client: i.client, lead: i.lead, status: i.status, name: i.name, scope: i.scope, updatedBy: i.updatedBy,
                  updatedAt: i.updatedAt, jobTitle: i.jobTitle, department: i.department, timelines: JSON.stringify(i.timelines)
                }
                timelineData(newData, i.timelines, props.typeTimeline)
                data.push(newData)
              })
              props.setManpowerData({
                data, loading: false,
                client, lead, jobTitle, department
              })
              message.success('Reloaded data')
            })
            .catch(err => {
              props.setManpowerData({ loading: false })
              message.warning('Cannot reload data')
            })

        })
        .catch(err => {
          props.setManpowerData({ loading: false })
          message.warning('Cannot update')
        })
    }
  }
  return (
    <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
      <Tooltip placement='bottom' title='Save'>
        <IconButton
          style={{ borderRadius: 0 }}
          size='sm'
          appearance='ghost'
          color=''
          icon={<Icon icon='save' />}
          onClick={saveData}
          loading={props.manpower.loading}
        />
      </Tooltip>


      <Divider type='vertical' />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip placement='top' title='Timeline'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={isSearch ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon icon='search' />}
            onClick={() => setIsSearch(!isSearch)}
            loading={props.manpower.loading}
          />
        </Tooltip>
        {isSearch && <Search
          // loading={this.props.dataEntryLoading}
          placeholder='Search'
          // disabled={this.props.dataEntryData.length === 0}
          // onSearch={(value) => this.props.gridApi.api.setQuickFilter(value)}
          style={{ width: 200, borderRadius: 0 }}
          loading={props.manpower.loading}
        />}
      </div>

      <Divider type='vertical' />
      <div>
        <Tooltip placement='top' title='Timeline'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={isShowSetupTimeline ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon icon='calendar' />}
            onClick={() => setIsShowSetupTimeline(!isShowSetupTimeline)}
            loading={props.manpower.loading}
          />
        </Tooltip>
        {isShowSetupTimeline &&
          <DateRangePicker appearance='subtle' value={props.timelineDate} format='DD/MM/YYYY' loading={props.manpower.loading} // hoverRange={props.typeTimeline} value
            size='sm' ranges={props.timelineDate} onChange={handleChangeTimelineDate} cleanable={false} />}

      </div>
      <Divider type='vertical' />
      <div>
        <Tooltip placement='top' title='Day'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={props.typeTimeline === 'day' ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon>D</Icon>}
            onClick={handleTypeTimeLine.bind(this, 'day')}
            loading={props.manpower.loading}
          />
        </Tooltip>
        <Tooltip placement='top' title='Week'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={props.typeTimeline === 'week' ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon>W</Icon>}
            onClick={handleTypeTimeLine.bind(this, 'week')}
            loading={props.manpower.loading}
          />
        </Tooltip>
        <Tooltip placement='top' title='Month'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={props.typeTimeline === 'month' ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon>M</Icon>}
            onClick={handleTypeTimeLine.bind(this, 'month')}
            loading={props.manpower.loading}
          />
        </Tooltip>
        <Tooltip placement='top' title='Year'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance={props.typeTimeline === 'year' ? 'default' : 'ghost'}
            color='blue'
            icon={<Icon>Y</Icon>}
            onClick={handleTypeTimeLine.bind(this, 'year')}
            loading={props.manpower.loading}
          />
        </Tooltip>
        <Divider type='vertical' />
        <Tooltip placement='top' title='Edit Multi-value'>
        <IconButton
          style={{ borderRadius: 0 }}
          size='sm'
          appearance={props.typeTimeline === 'year' ? 'default' : 'ghost'}
          color='blue'
          icon={<Icon>EM</Icon>}
          onClick={() => {
            const selectedData = props.gridApi.api.getCellRanges();
            if (selectedData.length !== 0) {
              let index = selectedData[0].startRow.rowIndex
              let node = props.gridApi.api.getRowNode(index)
              props.setManpowerData({ openEditMultiValuesDialog: { isOpen: true, node: node } })
            }

          }}
          loading={props.manpower.loading}
        />

        </Tooltip>
        

      </div>
    </div>
  );
}
ManPowerToolbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  timelineDate: PropTypes.object.isRequired,
  manpower: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    typeTimeline: state.manpower.typeTimeline,
    timelineDate: state.manpower.timelineDate,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(ManPowerToolbar);



