import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";

const TableCheckSummary = ({ tradeSelected, data }) => {
   const [dataForTable, setDataForTable] = useState([]);
   const [checkHighFail, setCheckHighFail] = useState(true);
   const [highFail, setHighFail] = useState([]);

   useEffect(() => {
      {
         if (data !== undefined) {
            const checks = [];
            let trade_data = [];
            trade_data = data.filter((e) => e.trade === tradeSelected).map((a) => a.check_list);
            const list_checks = Object.values(trade_data);
            const checks_item = list_checks[0];
            if (checks_item !== null && checks_item !== undefined) {
               for (let [key, value] of Object.entries(checks_item)) {
                  let total_no = 0;
                  let total_yes = 0;
                  let percent = 0;
                  let check_text = false;
                  trade_data.forEach((element) => {
                     if (element !== null && element[key] !== undefined) {
                        let firstWord = key.split(" ")[0];
                        check_text = isNaN(firstWord);
                        const text = element[key].toLowerCase();
                        if (text.includes("no") && check_text == false) {
                           total_no = total_no + 1;
                        }
                        if (text.includes("yes") && check_text == false) {
                           total_yes = total_yes + 1;
                        }
                        const total = total_no + total_yes;

                        if (total > 0) {
                           percent = (100 * total_no) / total;
                        }
                        percent = percent.toLocaleString("en-US", {
                           maximumFractionDigits: 1,
                           miniimumFractionDigits: 1,
                           useGrouping: false,
                        });
                     }
                  });

                  let check;
                  if (check_text == false) {
                     check = {
                        name: key,
                        total_no: total_no,
                        total_yes: total_yes,
                        percent: percent,
                     };
                  } else {
                     check = {
                        name: key,
                        total_no: "",
                        total_yes: "",
                        percent: "",
                     };
                  }

                  checks.push(check);
               }
            }
            setDataForTable(checks);
            const h = checks.filter((e) => e.percent >= 8);
            setHighFail(h);
         }
      }
   }, [tradeSelected]);

   const columns = [
      {
         title: "Trade",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 155,
         ellipsis: true,
      },

      {
         title: "No",
         dataIndex: "total_no",
         key: "total_no",
         align: "right",
         width: 30,
      },
      {
         title: "Yes",
         dataIndex: "total_yes",
         key: "total_yes",
         align: "right",
         width: 30,
      },

      {
         title: "%",
         dataIndex: "percent",
         key: "percent",
         align: "right",
         width: 30,
      },
   ];

   return (
      <div style={{ minHeight: "25vh" }}>
         <h5 style={{ textAlign: "left" }}>
            {tradeSelected !== undefined ? "Detail Checks for Trade: " + tradeSelected : "Trade: "}
            <Checkbox
               style={{ float: "right", marginRight: 1, marginTop: 3 }}
               onClick={(e) => {
                  setCheckHighFail(!checkHighFail);
               }}
            >
               Items {">"} 8%
            </Checkbox>
         </h5>
         <Table
            dataSource={checkHighFail ? dataForTable : highFail}
            columns={columns}
            size="small"
            rowClassName={(record, index) => (record.percent >= 8 ? "antd_table_row_red" : null)}
            pagination={false}
            scroll={{ y: "25vh" }}
            rowKey="name"
         />
      </div>
   );
};

export default TableCheckSummary;
