import { val } from "objection";
import React, { useState, useCallback, useEffect } from "react";

import {
   ComposedChart,
   Bar,
   LabelList,
   Cell,
   Line,
   ResponsiveContainer,
   ReferenceLine,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
   Label,
   CartesianGrid,
   BarChart,
} from "recharts";

const ChartTradePerProject = ({ current_project, data, tradeSelected }) => {
   const [perProjectData, setPerProjectData] = useState([]);
   useEffect(() => {
      {
         const temp_data = [];
         if (data !== undefined) {
            const d = data.find((e) => e.name === tradeSelected);
            if (d !== undefined) {
               const list_d = Object.entries(d);

               list_d.forEach((e) => {
                  const percent = parseFloat(e[1]);
                  if (e[0].includes("_percent") && percent > 0) {
                     let item = new Object();
                     item.projectName = e[0].substring(0, 3);
                     item.percent = percent;
                     temp_data.push(item);
                  }
               });
               setPerProjectData(temp_data);
            }
         }
      }
   }, [tradeSelected, current_project]);

   return (
      <div>
         <h5 style={{ textAlign: "left" }}>
            {tradeSelected !== undefined ? `% By Project For Trade: ${tradeSelected}` : `% By Project`}
         </h5>
         <ResponsiveContainer width="100%" minHeight={"15vh"}>
            <BarChart
               data={perProjectData}
               margin={{
                  top: 5,
                  right: 5,
                  left: 10,
                  bottom: 0,
               }}
            >
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <Tooltip cursor={false} />
               <XAxis
                  dataKey="projectName"
                  tickLine={false}
                  angle={-90}
                  height={30}
                  dx={-10}
                  dy={8}
                  interval={0}
                  // minTickGap={-200}
               ></XAxis>
               <Label
                  value="Fail items (%)"
                  // offset={0}
                  position="top"
                  style={{
                     fontWeight: 700,
                     fontSize: "16px",
                  }}
               />
               <YAxis type="number" domain={[0, "dataMax + 10"]} hide={true} />

               <Bar dataKey="percent">
                  <LabelList dataKey="percent" position="top" fontSize="11px" fill="black" />
                  {data
                     ? perProjectData.map((entry, index) => (
                          <Cell
                             fill={perProjectData[index].percent > 9 ? "#ff8080" : "#82ca2d"}
                             cursor="pointer"
                             key={index}
                          />
                       ))
                     : null}
               </Bar>

               <ReferenceLine
                  y={8}
                  label={{
                     position: "top",
                     value: "8%",
                     fontSize: 12,
                     fontWeight: 700,
                  }}
                  stroke="red"
                  strokeWidth={2}
                  strokeDasharray="3 3"
               />
            </BarChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartTradePerProject;
