// import { BrowserRouter as Router, useRouteMatch } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import _ from "lodash";

import QaQcSummary from "../../module_QaQc/components/_Summary";
import SideMenu from "../../module_QaQc/components/_common_component/SideMenu";
// import GetData from "../../module_QaQc/components/PullData";
import CommonChecks from "../../module_QaQc/components/CommonChecks";
import CriticalChecks from "../../module_QaQc/components/CriticalChecks";
import ReworkReport from "../../module_QaQc/components/ReworkReport";
import ViolationReport from "../../module_QaQc/components/ViolationReport";
import ObservationReport from "../../module_QaQc/components/OservationReport";
import QMWorksJot from "../../module_QaQc/components/QMWorksJot";
import QMWorksIdd from "../../module_QaQc/components/QMWorksIdd";
import UnitSetting from "../../module_QaQc/components/UnitSetting";

function QaQcPage() {
   //  const userRole = useSelector((state) => state.user.role);

   const projectId = useSelector((state) => state.project.projectId);
   let userRole = useSelector((state) => {
      if (state.user.role === "admin") {
         return "admin";
      }

      let contractors = state.user.contractors;
      if (projectId && _.isArray(contractors)) {
         let contractor = contractors.find((x) => x.projectId === projectId);
         if (contractor) {
            return contractor.role;
         }
      }

      return "";
   });

   const listEditAll = ["QAQC", "Project Admin", "admin"];
   const listEditLimit = ["Production", "Planning Engineer"];
   const [data, setData] = useState([]);
   const [tempData, setTempData] = useState([]);
   const [current, setcurrent] = useState("sum");

   const [forceReRender, setForceReRender] = useState(true);

   useEffect(() => {
      localStorage.removeItem("fp_project_id");
      // localStorage.removeItem("fp_user_role");
      localStorage.setItem("fp_project_id", projectId);
      // userRole = "QS";
      let role = "edit_none";
      if (listEditAll.includes(userRole)) {
         role = "edit_all";
      }
      if (listEditLimit.includes(userRole)) {
         role = "edit_limit";
      }
      localStorage.setItem("fp_user_role", role);
   }, []);

   return (
      <div>
         {/* <Row> */}
         {/* <Col span={2} className="qaqc-sidebar"> */}
         <div className="qaqc-sidebar">
            <SideMenu current={current} setcurrent={setcurrent} setData={setData} setTempData={setTempData} />
         </div>
         {/* </Col> */}

         {/* <Col span={22} className="qaqc_main"> */}
         <div className="qaqc-main">
            {current === "sum" ? <QaQcSummary current={current} /> : null}

            {current === "common" ? <CommonChecks current={current} /> : null}
            {current === "critical" ? <CriticalChecks current={current} /> : null}
            {current === "rework" ? <ReworkReport current={current} /> : null}
            {current === "violation" ? <ViolationReport current={current} /> : null}
            {current === "observation" ? <ObservationReport current={current} /> : null}
            {current === "qmjot" ? <QMWorksJot current={current} /> : null}
            {current === "qmidd" ? <QMWorksIdd current={current} /> : null}
            {current === "unitsetting" ? <UnitSetting current={current} /> : null}
         </div>
         {/* </Col> */}
         {/* </Row> */}
      </div>
   );
}

export default QaQcPage;
