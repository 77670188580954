import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LoginForm from "../forms/LoginForm";
import { login } from "../../actions/app/auth";
import ReCAPTCHA from "react-google-recaptcha";
import {socket} from '../../components/module_BimApp/function/AdminFunction'
import { Form } from 'antd';
import {WHLogoText,WH_BimApp_Start} from '../../image'

const WrappedLoginForm = Form.create({ name: 'login_form' })(LoginForm);
class LoginPage extends React.Component {
  submit = data =>
    this.props.login(data).then(() => {
      this.props.history.push("/projects")
      socket.emit("client-send-username", data.email);
    });

  render() {
    return (
      <div style={{
        background: `url(${WH_BimApp_Start})`, backgroundRepeat: 'round', fontFamily: 'cursive'
      }} >
        <div className="container" style={{ height: "100vh" }}>
          <div className="row align-items-center" style={{ height: "100vh" }}>
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div className="card"style={{ width: 350 }}>
                <div className="card-header" style={{ backgroundColor: '#a6d2f838' }}>
                  <div  style={{textAlign:'center'}}>
                    <img style={{ maxWidth: '100px', maxHeight: '90px' }}
                      src={WHLogoText}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <WrappedLoginForm submit={this.submit} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          sitekey="6LdWd8UUAAAAAJX_nA7MWivVcTnnHWNG62UF7wad"
          theme="dark"
          size="invisible"
        />
      </div>

    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};

export default connect(null, { login })(LoginPage);
