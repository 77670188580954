import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Table, Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CardHeader from '../_common_component/CardHeader';
import StaticInfoCostWork from '../_common_component/StaticInfoCostWork';
import CostPieChartByWorkType from './CostPieChartByWorkType';
import ChartCostRange from './ChartCostRange';

const aggFunc = (params) => {
  let total = 0;
  params.values.forEach((value) => (total += value));
  total = _.round(total, 2);
  return total;
};

function CostCodeTableSummary(props) {
  const gridRef = useRef();
  const [datas, setDatas] = useState(null);
  const [unitDatas, setUnitDatas] = useState(null);
  const projectId = useSelector((state) => state.project.projectId);
  const [staticCostWork, setStaticCostWork] = useState([]);
  const [costGroupType, setCostGroupType] = useState(3);
  const [dataCostCodesAll, setDataCostCodesAll] = useState({
    costCodeDatasById: null,
  });
  const [totalRework, setTotalRework] = useState(0);

  const [activeIndex, setActiveIndex] = useState(-1);

  const [unitCostChart, setUnitCostChart] = useState([]);

  const [blockUnitInspecteds, setBlockUnitInspecteds] = useState([]);

  useEffect(() => {
    getCostCode();
  }, []);

  useEffect(() => {
    getDatas();
  }, [dataCostCodesAll, costGroupType, blockUnitInspecteds]);

  const [columnDefs, setColumnDefs] = useState(getColumns());

  function getColumns() {
    if (costGroupType == 3) {
      let ar = [
        {
          field: 'unit',
          headerName: 'Unit',
          width: 125,
          filter: true,
          sortable: true,
        },
        {
          field: 'noOfSample',
          headerName: 'No Of Sample',
          width: 125,
          filter: true,
          sortable: true,
        },
        {
          field: 'totalHours',
          headerName: 'Total Hours',
          width: 125,
          filter: true,
          sortable: true,
        },
        {
          field: 'totalAmount',
          headerName: 'Total Amount',
          width: 125,
          filter: true,
          sortable: true,
        },
      ];

      return ar;
    } else {
      let ar = [
        {
          field: 'costCode',
          headerName: 'Cost Code',
          width: 100,
          filter: true,
          sortable: true,
          rowGroup: costGroupType == 1,
          enableRowGroup: true,
        },
        {
          field: 'description',
          headerName: 'Description',
          filter: true,
          sortable: true,
          rowGroup: costGroupType == 2,
          enableRowGroup: true,
        },
        {
          field: 'totalHours',
          headerName: 'Total Hours',
          width: 125,
          filter: true,
          sortable: true,
          aggFunc,
        },
        {
          field: 'totalAmount',
          headerName: 'Cumulative',
          width: 125,
          filter: true,
          sortable: true,
          aggFunc,
        },
        {
          field: 'current',
          headerName: 'Current',
          width: 125,
          filter: true,
          sortable: true,
          aggFunc,
        },
        {
          field: 'previous',
          headerName: 'Previous',
          width: 125,
          filter: true,
          sortable: true,
          aggFunc,
        },
      ];

      return ar;
    }
  }

  useEffect(() => {
    let ar = getColumns();

    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.setColumnDefs(ar);

      if (costGroupType == 3) {
        gridRef.current.api.setRowData(unitDatas);
      } else {
        gridRef.current.api.setRowData(datas);
      }
    }
  }, [costGroupType, unitDatas, datas]);

  function getCostCode() {
    axios
      .get('https://api.wohhup.com/dashboard/worker/cost-chart/' + props.id)
      .then((resAll) => {
        let costDatas = resAll.data.data.blockUnitCosts;
        let inspectedList = resAll.data.data.blockUnitInspecteds;
        let remark = resAll.data.data.rework;
        setTotalRework(remark?.rework + remark?.reworkScore);
        setDataCostCodesAll(costDatas);
        setBlockUnitInspecteds(inspectedList);
      });
  }

  const getDatas = async () => {
    if (_.isNil(dataCostCodesAll)) {
      await setDatas([]);
      await setUnitDatas([]);
    } else {
      if (!dataCostCodesAll || !_.isArray(dataCostCodesAll.costCodeDatasById)) {
        return;
      }

      let dts = [];
      let unitDts = [];
      dataCostCodesAll.costCodeDatasById.forEach((x, index) => {
        if (x.costDataByBlockUnits) {
          x.costDataByBlockUnits.forEach((c) => {
            let obj = unitDts.find(
              (x) => x.combine == `BLK${c.block}-${c.unit}`
            );
            if (!obj) {
              obj = {
                unit: c.unit,
                block: c.block,
                noOfSample: c.noOfSample,
                id: index,
                totalHours: roundNumber(c.totalHours),
                totalAmount: roundNumber(c.totalAmount),
              };

              obj.combine = `BLK${obj.block}-${obj.unit}`;
              unitDts.push(obj);
            } else {
              obj.totalHours += roundNumber(c.totalHours);
              obj.totalAmount += roundNumber(c.totalAmount);
            }
          });
        }

        let obj = {
          id: index,
          trade: x.trade,
          costCode: x.costCode,
          description: x.description,
          current: roundNumber(x.currentMonthData.amount),
          totalHours: roundNumber(x.totalHours),
          previous: roundNumber(x.previousMonthData.amount),
          totalAmount: roundNumber(x.totalAmount),
        };

        dts.push(obj);
      });

      unitDts = unitDts.filter((x) => blockUnitInspecteds.includes(x.combine));

      let units = [];
      let group = _.groupBy(unitDts, 'unit');
      let keys = Object.keys(group);
      keys.sort();
      keys.map((key) => {
        let items = group[key];
        let first = items[0];

        let obj = {
          unit: key,
          noOfSample: first.noOfSample,
          totalHours: roundNumber(_.sumBy(items, 'totalHours')),
          totalAmount: roundNumber(_.sumBy(items, 'totalAmount')),
        };

        units.push(obj);
      });

      console.log('units', units);
      setDatas(dts);
      setUnitDatas(units);

      // let g = _.groupBy(unitDts, 'unit');
      // console.log('unitDts', unitDts);
      // console.log('g', Object.keys(g).length);
      // console.log('gg', Object.keys(g));
      let range1 = { name: '<=150', count: 0, max: 100, units: [], index: 0 };
      let range2 = {
        name: '151->250',
        count: 0,
        max: 100,
        units: [],
        index: 1,
      };
      let range3 = { name: '251-350', count: 0, max: 100, units: [], index: 2 };
      let range4 = { name: '>350', count: 0, max: 100, units: [], index: 3 };

      let items1 = unitDts.filter((x) => x.totalAmount <= 150);
      range1.units = items1.map((x) => x.unit);
      range1.count = items1.length;

      let items2 = unitDts.filter(
        (x) => x.totalAmount < 250 && x.totalAmount >= 151
      );
      range2.units = items2.map((x) => x.unit);
      range2.count = items2.length;

      let items3 = unitDts.filter(
        (x) => x.totalAmount < 350 && x.totalAmount >= 251
      );
      range3.units = items3.map((x) => x.unit);
      range3.count = items3.length;

      let items4 = unitDts.filter((x) => x.totalAmount >= 350);
      range4.count = items4.length;
      range4.units = items4.map((x) => x.unit);

      let ranges = [range1, range2, range3, range4];

      setUnitCostChart(ranges);

      let totalAmount = roundNumber(
        _.sumBy(dataCostCodesAll.costCodeDatasById, 'totalAmount')
      );

      let previousMonthData = roundNumber(
        _.sumBy(dataCostCodesAll.costCodeDatasById, 'previousMonthData.amount')
      );

      let currentMonthData = roundNumber(
        _.sumBy(dataCostCodesAll.costCodeDatasById, 'currentMonthData.amount')
      );

      let unitAverage = _.mean(unitDts.map((x) => x.totalAmount));
      unitAverage = unitAverage ? (unitAverage + totalRework) : (unitAverage);

      setStaticCostWork([
        {
          value: formatSgd(totalAmount),
          key: 'Total Cumulative',
          border: '1px',
        },
        {
          value: formatSgd(currentMonthData),
          key: 'Current Month',
          border: '1px',
        },
        {
          value: formatSgd(previousMonthData),
          key: 'Previous Month',
          border: '1px',
        },
        {
          value: formatSgd(unitAverage),
          key: 'Average cost per unit',
          border: '1px',
        },
      ]);
    }
  };

  function formatSgd(amount) {
    return new Intl.NumberFormat('en-SG', {
      style: 'currency',
      currency: 'SGD',
    }).format(amount);
  }

  function getDataCostPieChart() {
    if (dataCostCodesAll && dataCostCodesAll.costCodeDatasById) {
      let dts = [];
      dataCostCodesAll.costCodeDatasById.forEach((x, index) => {
        if (x.costDataByBlockUnits) {
          x.costDataByBlockUnits.forEach((c) => {
            let obj = {
              unit: c.unit,
              id: index,
              trade: x.trade,
              costCode: x.costCode,
              description: x.description,
              current: roundNumber(x.currentMonthData.amount),
              totalHours: roundNumber(c.totalHours),
              previous: roundNumber(x.previousMonthData.amount),
              totalAmount: roundNumber(c.totalAmount),
            };

            dts.push(obj);
          });
        } else {
          let obj = {
            unit: 'None',
            id: index,
            trade: x.trade,
            costCode: x.costCode,
            description: x.description,
            current: roundNumber(x.currentMonthData.amount),
            totalHours: roundNumber(x.totalHours),
            previous: roundNumber(x.previousMonthData.amount),
            totalAmount: roundNumber(x.totalAmount),
          };

          dts.push(obj);
        }
      });

      let groupKey = 'unit';
      if (costGroupType == 1) {
        groupKey = 'costCode';
      }
      if (costGroupType == 2) {
        groupKey = 'description';
      }
      if (costGroupType == 3) {
        groupKey = 'unit';
      }

      let dic = _.groupBy(dts, groupKey);

      let keys = Object.keys(dic);
      keys.sort();

      let data = keys.map((key, index) => ({
        color: randomColor(index),
        name: key,
        value: roundNumber(_.sumBy(dic[key], 'totalAmount')),
      }));

      return data;
    }
    return null;
  }

  function randomColor(index) {
    let colors = [
      '#F6F6F6',
      '#35682D',
      '#F54021',
      '#8A9597',
      '#D0D0D0',
      '#DC9D00',
      '#A2231D',
    ];

    if (index < colors.length) {
      return colors[index];
    }

    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  function roundNumber(cash) {
    if (!_.isNumber(cash)) {
      return 0;
    }
    try {
      return Math.round(cash * 10) / 10;
    } catch (error) {
      return 0;
    }
  }

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <Row>
      <Col span={22} offset={1}>
        <div
          className='qaqc_dashboard_card'
          style={{ padding: '0px', marginBottom: '25px' }}
        >
          <CardHeader headerText='QM Cost Report' />
          {staticCostWork !== undefined ? (
            <StaticInfoCostWork static_report={staticCostWork} />
          ) : null}
          <br></br>
          <div style={{ padding: '15px' }}>
            <Row>
              <div className='d-flex flex-row  align-items-start'>
                <div
                  onClick={() => {
                    setCostGroupType(3);
                  }}
                  className='p-1 mr-2 font-weight-bold'
                  style={{
                    backgroundColor: `${costGroupType == 3 ? '#D1D5DB' : ''}`,
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                  }}
                >
                  Group By Unit
                </div>

                <div
                  onClick={() => {
                    setCostGroupType(1);
                  }}
                  className='p-1 mr-2 font-weight-bold'
                  style={{
                    backgroundColor: `${costGroupType == 1 ? '#D1D5DB' : ''}`,
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                  }}
                >
                  Group By Cost Code
                </div>

                <div
                  onClick={() => {
                    setCostGroupType(2);
                  }}
                  className='p-1 mr-2 font-weight-bold'
                  style={{
                    backgroundColor: `${costGroupType == 2 ? '#D1D5DB' : ''}`,
                    borderRadius: '0.25rem',
                    cursor: 'pointer',
                  }}
                >
                  Group By Description
                </div>
              </div>
              <div className='mt-2'>
                <Button
                  size={'small'}
                  onClick={() => {
                    gridRef.current.api.setFilterModel(null);
                    setActiveIndex(-1);
                  }}
                >
                  Clear selection
                </Button>
              </div>
            </Row>
            <Row>
              <Col span={10}>
                <div className='d-flex flex-column  align-items-center w-100'>
                  {costGroupType != 3 && (
                    <div>
                      <CostPieChartByWorkType
                        dataPieChart={getDataCostPieChart()}
                      />
                      <h4>Cost chart by work type</h4>
                    </div>
                  )}

                  {costGroupType == 3 && (
                    <div className='w-100'>
                      <ChartCostRange
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        data={unitCostChart}
                        setSelectedUnits={(units) => {
                          gridRef.current.api.setFilterModel({
                            unit: {
                              filterType: 'set',
                              values: units,
                            },
                          });
                        }}
                      />
                      <h4>No of Unit with Cost range</h4>
                    </div>
                  )}
                </div>
              </Col>
              <Col span={14}>
                <div
                  className='ag-theme-alpine'
                  style={{ height: '43vh', width: '100%' }}
                >
                  <AgGridReact
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    rowData={datas}
                    columnDefs={columnDefs}
                  ></AgGridReact>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default CostCodeTableSummary;
