import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const func_upload_files_to_s3 = async (files, form_type) => {
  // upload file to S3
  if (files == undefined || files.length < 1) {
    return "Nothing to process";
  }
  var tempListFile = [];
  const project_id = localStorage.getItem("fp_project_id");
  const promise_files = files.map(async (file) => {
    const new_file_name = project_id + "_fp_" + file.name;
    //get info to store files list
    let fn = {
      id: uuidv4(),
      form_type: form_type,
      s3_key: new_file_name,
      project_id: project_id,
      checks: "ok",
    };
    tempListFile.push(fn);

    return new Promise(() => {
      axios
        .get("/api/do_inv/s3/putlink", {
          params: { file_name: new_file_name },
        })
        .then((res) => {
          const signedrequesturl = res.data.signedRequest;

          axios
            .put(signedrequesturl, file.originFileObj, {
              headers: {
                "Content-Type": "application/pdf",
              },
            })
            .then((result) => {})
            .catch((err) => {
              console.log("got error", err);
            });
        });
    });
  });
  await Promise.all(promise_files);
  if (tempListFile.length > 0) {
    await axios.post("/api/do_inv/s3rds/multi", tempListFile);
  }

  return "Success";
};

export default func_upload_files_to_s3;
