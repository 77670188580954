/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Upload } from "antd";
import { v4 as uuidv4 } from "uuid";
import Excel from "exceljs";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { message } from "antd";

const { Dragger } = Upload;
var customParseFormat = require("dayjs/plugin/customParseFormat");

const ImportExcelInv = ({ tempData, current, generalState, setGeneralState, setTempData }) => {
   const [listFiles, setlistFiles] = useState([]);
   const wb = new Excel.Workbook();
   const reader = new FileReader();
   dayjs.extend(customParseFormat);

   const getValue = (cell) => {
      var value = cell;
      if (typeof cell === undefined) {
         value = null;
      }

      if (typeof cell === "object" && cell !== null) {
         value = cell.result;
      }
      return value;
   };
   const func_Import_Conc_Inv = async () => {
      const temp = [];
      const projectId = localStorage.getItem("fp_project_id");
      reader.onload = () => {
         const buffer = reader.result;
         wb.xlsx.load(buffer).then((workbook) => {
            var Invsheet = workbook.getWorksheet(1);
            if (Invsheet === undefined) {
               return alert("no sheet file");
            }
            const no_of_row = Invsheet.actualRowCount;
            if (!no_of_row || no_of_row < 1) {
               return "Nothing to process.";
            }
            Invsheet.eachRow((row, rowIndex) => {
               var do_no = "";
               if (typeof row.values[2] === "undefined") {
                  do_no = "";
               } else {
                  do_no = row.values[1].concat(row.values[3]);
               }
               if (rowIndex > 1) {
                  const key = `${projectId}_${"conc_do"}_${do_no}.pdf`;
                  let amtc = 0;
                  if (row.values[24].result !== undefined) {
                     amtc = row.values[24].result;
                  } else {
                     amtc = row.values[24];
                  }

                  let amtg = 0;
                  if (row.values[25].result !== undefined) {
                     amtg = row.values[25].result;
                  } else {
                     amtg = row.values[25];
                  }

                  let entity = {
                     id: uuidv4(),
                     inv_number: row.values[20],
                     do_number: do_no,
                     inv_date: dayjs(row.values[2]).format("DD-MM-YYYY"),
                     do_date: dayjs(row.values[4]).format("DD-MM-YYYY"),
                     product: row.values[7],
                     volume: row.values[8],
                     grade: row.values[6],
                     supplier: "ALLIANCE",
                     unit_price: row.values[21],
                     amount_claim: amtc,
                     amount_gst: amtg,
                     checks: "ok",
                     remark: "",
                     row_type: "do",
                     s3_key: key.toLowerCase(),
                     project_id: localStorage.getItem("fp_project_id"),
                  };

                  if (
                     typeof row.values[2] == "undefined" ||
                     typeof row.values[3] == "undefined" ||
                     typeof row.values[4] == "undefined" ||
                     typeof row.values[5] == "undefined"
                  ) {
                     entity.checks = "missing info";
                  }
                  if (entity.do_date === "Invalid Date" || entity.inv_date === "Invalid Date") {
                     entity.checks = "Invalid date";
                  }
                  temp.push(entity);
               }
            });

            //get the list of DO no
            let DOs = temp.map((a) => a.do_number);
            // check if element exist
            axios
               .post("/api/do_inv/common/check_exist_multi", {
                  list_DO: JSON.stringify(DOs),
                  current: "conc_inv",
                  projectId: projectId,
               })
               .then(async (res) => {
                  if (typeof res.data.data !== "undefined" && res.data.message !== "There is new DO!") {
                     let founded_do = res.data.data.items.map((a) => a.do_number);
                     //map back to list tempdata
                     temp.forEach((element) => {
                        if (founded_do.includes(String(element.do_number))) {
                           element.checks = "exist";
                        }
                     });
                     await setTempData(temp);
                     message.warn(
                        "There is DO already exist in the system, it will be ignored and not import to system",
                        5
                     );
                  } else {
                     await setTempData(temp);
                  }
               });
         });
      };
   };

   const func_Import_Rebar_Inv = async () => {
      const temp = [];
      reader.onload = () => {
         const buffer = reader.result;
         wb.xlsx.load(buffer).then((workbook) => {
            var Invsheet = workbook.getWorksheet(1);
            const no_of_row = Invsheet.actualRowCount;
            if (!no_of_row || no_of_row < 1) {
               return "Nothing to process.";
            }
            Invsheet.eachRow((row, rowIndex) => {
               if (rowIndex > 2) {
                  const projectId = localStorage.getItem("fp_project_id");
                  const key = `${projectId}_${"conc_do"}_${getValue(row.values[3])}.pdf`;

                  let entity = {
                     id: uuidv4(),
                     inv_number: getValue(row.values[1]),
                     inv_date: dayjs(getValue(row.values[2])).format("DD/MM/YYYY"),
                     do_number: getValue(row.values[3]),
                     do_date: dayjs(getValue(row.values[4])).format("DD/MM/YYYY"),
                     product: getValue(row.values[5]),
                     weight_qty: getValue(row.values[6]),
                     supplier: getValue(row.values[7]),
                     unit_price: getValue(row.values[8]),
                     amount_claim: getValue(row.values[9]),
                     checks: "ok",
                     remark: getValue(row.values[10]),
                     // row_type: "do",
                     s3_key: key.toLowerCase(),
                     project_id: projectId,
                  };

                  if (
                     typeof row.values[1] == "undefined" ||
                     typeof row.values[3] == "undefined" ||
                     typeof row.values[5] == "undefined"
                  ) {
                     entity.checks = "missing info";
                     temp.push(entity);
                     // dispatch(fp_addTempData([entity]));
                  } else {
                     // check if element has already exist on server
                     if (typeof row.values[1] != "undefined") {
                        // dispatch(fp_addTempData([entity]));
                        temp.push(entity);
                     }
                  }
               }
            });

            // dispatch(fp_tempDataAddWhole(temp));
            setTempData(temp);
         });
      };
   };

   const func_Apply_Conc_Inv = async () => {
      // apply data to RDS
      try {
         let filtterd_array = _.cloneDeep(tempData);
         const checkMissing = tempData.find((a) => a.checks === "missing info");
         if (checkMissing) {
            window.alert("You must correct missing info first before apply.");
            return;
         }

         const checkInvalidDate = tempData.find((a) => a.checks === "Invalid date");
         if (checkInvalidDate) {
            window.alert("You must correct invalid date before apply.");
            return;
         }

         filtterd_array = filtterd_array.filter((a) => a.checks === "ok");

         if (filtterd_array.length !== tempData.length) {
            if (window.confirm("There is item already exist, it will not saved. Continue?")) {
               if (filtterd_array.length > 0) {
                  await axios.post("/api/do_inv/common/multi", {
                     data: filtterd_array,
                     current: "conc_inv",
                  });
                  message.warn("None exist items has saved to the system.", 5);
               } else {
                  message.warn("No item to process.", 5);
               }
            }
         } else {
            await axios.post("/api/do_inv/common/multi", {
               filtterd_array: filtterd_array,
               current: "conc_inv",
            });
            message.success(`Invoice with ${filtterd_array.length} DO has saved to the system.`, 5);
         }
      } catch (error) {
         console.log("error: ", error);
      }
   };

   const func_Apply_Rebar_Inv = async () => {
      // apply data to RDS
      try {
         let filtterd_array = tempData.filter((a) => a.checks === "ok");

         filtterd_array.forEach((e) => {
            delete filtterd_array.row_type;
         });
         const data_array = Array.from(filtterd_array);
         await axios.post("/api/do_inv/common/multi", {
            data: data_array,
            current: "rebar_inv",
         });
      } catch (error) {
         console.log("error: ", error);
      }
   };

   const checkSupplierName = (original_name) => {
      if (typeof original_name != "undefined") {
         var new_name = original_name.toUpperCase();
         if (new_name.includes("ALLIA")) {
            new_name = "ALLIANCE";
         }
         if (new_name.includes("PAN")) {
            new_name = "PAN UNITED";
         }
         if (new_name.includes("ISLAND")) {
            new_name = "ISLAND";
         }
         return new_name;
      }
   };

   const handleProcess = async (e) => {
      e.preventDefault();

      var msg = "";
      if (listFiles.length > 0) {
         const file = listFiles[0].originFileObj;
         reader.readAsArrayBuffer(file);
         switch (current) {
            case "conc_inv":
               msg = func_Import_Conc_Inv();
               break;
            case "rebar_inv":
               msg = func_Import_Rebar_Inv();
               break;
            default:
               break;
         }

         if (msg !== "") {
            // alert(msg);
         }
      } else {
         alert("Ay oh, select file first lah!");
      }
   };

   const handleApply = async (e) => {
      e.preventDefault();
      if (tempData.length > 0) {
         switch (current) {
            case "conc_inv":
               func_Apply_Conc_Inv();
               break;
            case "rebar_inv":
               func_Apply_Rebar_Inv();
               break;
         }
      } else {
         alert("Ay oh, nothing to save!");
      }
   };

   const props = {
      multiple: true,
      onChange(info) {
         let listFiles = [...info.fileList];

         setlistFiles(listFiles);
         console.log(999, listFiles);
      },

      onRemove: (file) => {
         const index = listFiles.indexOf(file);
         const newFileList = listFiles.slice();
         newFileList.splice(index, 1);

         return setlistFiles(newFileList);
      },
      beforeUpload: (file) => {
         setlistFiles([...listFiles, file]);
         return false;
      },
      listFiles,
   };

   return (
      <div className="extract-data-form">
         <h5 className="text-ontop-menu"> Get invoice data by import</h5>
         <h5 className="text-ontop-menu">
            <span style={{ color: "blue", fontWeight: "bold" }}> Excel </span>
            file.
         </h5>

         <Dragger {...props} maxCount={1} accept=".xlsx">
            <p className="ant-upload-drag-icon">{/* <InboxOutlined /> */}</p>
            <p className="ant-upload-text">Click or drag file here to upload</p>
            <p className="ant-upload-hint">Accepted .xlsx format.</p>
         </Dragger>

         <div className="extract-data-footer">
            {generalState < 10 ? (
               <Button
                  type="primary"
                  block
                  onClick={(event) => {
                     handleProcess(event);
                  }}
               >
                  Processing
               </Button>
            ) : (
               <Button type="contained" block disabled>
                  Processed
               </Button>
            )}
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={(event) => {
                  handleApply(event);
               }}
            >
               Save Data To System
            </Button>
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={() => {
                  setGeneralState(0);
                  // dispatch(fp_tempDataClear());
                  tempData = [];
                  setTempData([]);
               }}
            >
               Close
            </Button>
         </div>
      </div>
   );
};

export default ImportExcelInv;
