
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import XLSX from 'xlsx';
import { mongoObjectId } from '../../../utils';
import { Icon, Tooltip } from 'antd';
import styled from 'styled-components';
import { colorType } from '../../../constants';




const ExcelImport = (props) => {

   const { projectIsAppliedRfaView, headers, getDataFormExcel } = props;


   const inputOnChange = async (e) => {
      const excelFile = e.target.files[0];

      if (excelFile) {

         const { SheetNames, Sheets } = await readFileAsExcelWorkbook(excelFile);


         let excelDataRowsToPreview = [];
         let excelDataTreeToPreview = [];

         SheetNames.forEach(sheetName => {
            const sheetData = Sheets[sheetName];
            const excelRows = XLSX.utils.sheet_to_row_object_array(sheetData);


            const excelSheetId = mongoObjectId();
            const rows = [];

            excelRows.forEach((excelRow, i) => {
               const objData = {};
               for (const keyInit in excelRow) {
                  const key = keyInit.trim();

                  if (headers.find(x => x['text'] === key)) { // Drg Type depend on ARC, STR, MEP => need to reinput later
                     let data = excelRow[keyInit];
                     let isDataInCorrectFormat = false;
                     let isColumnMustBeBlank = false;


                     if (projectIsAppliedRfaView && (
                        key === 'RFA Ref' || key === 'Drg To Consultant (A)' ||
                        key === 'Consultant Reply (T)' || key === 'Consultant Reply (A)' || key === 'Rev'
                     )) {
                        isColumnMustBeBlank = true;
                     };

                     
                     if (key === 'Drg Type') {
                        if (cellDrgTypeFormat.indexOf(data.trim()) === -1) {
                           isColumnMustBeBlank = true;
                        };
                     };


                     if (key === 'Model Progress' || key === 'Drawing Progress') {
                        const arrayProgress = ['Quarter', 'Half', 'Third Quarter', 'Full'];
                        if (arrayProgress.indexOf(data.trim()) !== -1) {
                           isDataInCorrectFormat = true;
                        };

                     } else if (key.includes(' (A)') || key.includes(' (T)') || key === 'Construction Issuance Date' || key === 'Construction Start') {
                        if (Number.isInteger(data) && data >= 10000 && data <= 99999) {
                           data = moment(convertExcelDateToJsDate(data)).format('DD/MM/YY');
                           isDataInCorrectFormat = true;
                        };

                     } else if (key === 'Status') {
                        if (
                           (projectIsAppliedRfaView && arrayStatusProjectIsAppliedRfaView.indexOf(data) !== -1) ||
                           (!projectIsAppliedRfaView && arrayStatusProjectIsNotAppliedRfaView.indexOf(data) !== -1)
                        ) {
                           isDataInCorrectFormat = true;
                        };

                     } else {
                        isDataInCorrectFormat = true;
                     };

                     if (isDataInCorrectFormat && !isColumnMustBeBlank) {
                        objData[key] = data;
                     };
                  };
               };

               if (!(_.isEmpty(objData))) {
                  rows.push({
                     id: mongoObjectId(),
                     _rowLevel: 1,
                     _parentRow: excelSheetId,
                     ...objData
                  });
               };
            });

            rows.forEach((row, i) => {
               row['_preRow'] = i === 0 ? null : rows[i - 1]['id'];
            });



            if (rows.length > 0) {
               excelDataTreeToPreview.push({
                  expanded: true,
                  id: excelSheetId,
                  title: sheetName,
               });
            };
            excelDataRowsToPreview = [...excelDataRowsToPreview, ...rows];
         });

         getDataFormExcel({ excelDataRowsToPreview, excelDataTreeToPreview });

      };
   };

   return (
      <label style={{
         display: 'inline-block',
         cursor: 'pointer',
         display: 'flex',
      }}>
         <div style={{
            display: 'flex',
            border: `1px solid ${colorType.grey2}`,
            paddingTop: 4,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10
         }}>
            <div style={{ marginRight: 5 }}>Select Excel File</div>
            <IconStyled type='upload' />
         </div>

         <input
            style={{ height: 25, fontSize: 8, marginRight: 6, display: 'none' }}
            type='file'
            name='file-input'
            accept='.xlsx, .xls'
            onChange={inputOnChange}
         />
      </label>
   );
}

export default ExcelImport;

const IconStyled = styled(Icon)`
   font-size: 17px;
   color: ${props => props.disabled ? 'grey' : 'black'};
`;



const readFileAsExcelWorkbook = (file) => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
         const data = new Uint8Array(e.target.result);
         try {
            const workbook = XLSX.read(data, { type: 'array' });
            resolve(workbook);
         } catch (err) {
            reject(err);
         };
      };
      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
   });
};



const convertExcelDateToJsDate = (serial) => {
   let utc_days = Math.floor(serial - 25569);
   let utc_value = utc_days * 86400;
   let date_info = new Date(utc_value * 1000);

   let fractional_day = serial - Math.floor(serial) + 0.0000001;

   let total_seconds = Math.floor(86400 * fractional_day);

   let seconds = total_seconds % 60;

   total_seconds -= seconds;

   let hours = Math.floor(total_seconds / (60 * 60));
   let minutes = Math.floor(total_seconds / 60) % 60;

   return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
};


const arrayStatusProjectIsAppliedRfaView = [
   'Not Started',
   '1st cut of model in-progress',
   '1st cut of drawing in-progress',
   'Pending design',
   'Consultant reviewing',
   'Revise In-Progress',
];
const arrayStatusProjectIsNotAppliedRfaView = [
   'Not Started',
   '1st cut of model in-progress',
   '1st cut of drawing in-progress',
   'Pending design',
   'Consultant reviewing',
   'Reject and resubmit',
   'Approved with comments, to Resubmit',
   'Revise In-Progress',
   'Approved with Comment, no submission Required',
   'Approved for Construction'
];



const cellDrgTypeFormat = [
   'Key plan',
   'Wall setting out',
   'Column wall setting out',
   'Tile layout & detail',
   'Reflected celing plan',
   'Finishing layout',
   'Door layout',
   'Core layout & detail',
   'Toilet',
   'Edeck layout & detail',
   'Staircase layout & detail',
   'Surface drain',
   'Lift lobby/ corridor',
   'Material schedule',
   'Other',
   'CBP',
   'Piling layout',
   'Rebar shop drawing',
   'Temporary work',
   'CSD',
   'Penetration drawing',
   'M&E trade shop drawing',
   'Precast layout',
   'Precast detail',
   'Precast shop drawing'
];