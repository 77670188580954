import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";

const TableCheckSummary = ({ tradeSelected, data }) => {
   const [dataForTable, setDataForTable] = useState([]);
   const [checkHighFail, setCheckHighFail] = useState(true);
   const [highFail, setHighFail] = useState([]);

   useEffect(() => {
      {
         if (data !== undefined) {
            const checks = [];
            let trade_data = [];

            trade_data = data.filter((e) => e.trade === tradeSelected).map((a) => a.check_list);
            const list_checks = Object.values(trade_data);
            const checks_item = list_checks[0];
            if (checks_item !== null && checks_item !== undefined) {
               for (let [key, value] of Object.entries(checks_item)) {
                  let total_no = 0;
                  let total_1st = 0;
                  let total_1plus = 0;
                  let percent = 0;
                  trade_data.forEach((element) => {
                     if (element !== null && element[key] !== undefined) {
                        const text = element[key].toLowerCase();
                        if (text.includes("no")) {
                           total_no = total_no + 1;
                        }
                        if (text.includes("1st")) {
                           total_1st = total_1st + 1;
                        }
                        if (!text.includes("1st") && text.toLowerCase().includes("past at")) {
                           total_1plus = total_1plus + 1;
                        }

                        const total = total_no + total_1st + total_1plus;

                        if (total > 0) {
                           percent = (100 * total_1st) / total;
                        }
                        percent = percent.toLocaleString("en-US", {
                           maximumFractionDigits: 1,
                           miniimumFractionDigits: 1,
                           useGrouping: false,
                        });
                     }
                  });
                  const check = {
                     name: key,
                     total_no: total_no,
                     total_1st: total_1st,
                     total_1plus: total_1plus,
                     percent: percent,
                  };
                  checks.push(check);
               }
            }
            setDataForTable(checks);
            const h = checks.filter((e) => e.percent < 92);
            setHighFail(h);
         }
      }
   }, [tradeSelected]);

   const columns = [
      {
         title: "Trade",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 155,
         ellipsis: true,
      },

      {
         title: "No",
         dataIndex: "total_no",
         key: "total_no",
         align: "right",
         width: 30,
      },
      {
         title: "1st",
         dataIndex: "total_1st",
         key: "total_1st",
         align: "right",
         width: 30,
      },
      {
         title: "1plus",
         dataIndex: "total_1plus",
         key: "total_1plus",
         align: "right",
         width: 30,
      },
      {
         title: "%",
         dataIndex: "percent",
         key: "percent",
         align: "right",
         width: 30,
      },
   ];

   return (
      <div style={{ minHeight: "20vh" }}>
         <h6 style={{ textAlign: "left" }}>
            {tradeSelected !== undefined ? "Detail Checks for Trade: " + tradeSelected : "Trade: "}
            <Checkbox
               style={{ float: "right", marginRight: 1, marginTop: 3 }}
               onClick={(e) => {
                  setCheckHighFail(!checkHighFail);
               }}
            >
               Items {"<"} 92%
            </Checkbox>
         </h6>
         <Table
            dataSource={checkHighFail ? dataForTable : highFail}
            columns={columns}
            size="small"
            rowClassName={(record, index) => (record.percent < 92 ? "antd_table_row_red" : null)}
            pagination={false}
            scroll={{ y: "15vh" }}
            rowKey="name"
         />
      </div>
   );
};

export default TableCheckSummary;
