import React from 'react';
import { Radio, Table, Progress } from 'semantic-ui-react'
import { message, Typography,Popover } from 'antd';
import moment from 'moment';
import { Modal, Loader } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import {
  IconButton
} from '@material-ui/core';
import CloudDownloadTwoToneIcon from '@material-ui/icons/CloudDownloadTwoTone';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FeedbackIcon from '@material-ui/icons/Feedback';
class DialogVersionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDownloadPanel: false,
      itemVersion: [],
      percentDownload: 0,
      loading: false,
      item: null,
      lastVersion: 0
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.onShow && this.state.itemVersion.length === 0) {
      this.setState({ loading: true })
      axios.get(`/api/items/versions?q=${nextProps.itemId}`)
        .then(res => {
          var optionVersion = []
          let lastVersion = 0
          res.data.reversion.forEach(item => {
            var timeData = parseFloat(item.objectKey.split("-")[0])
            var timeUpload = moment(timeData).format('DD-MM-YYYY HH:mm')
            optionVersion.push({
              key: item.objectId, text: item.objectKey, value: item.objectId, description: `V${item.version}`, version: item.version, trimbleFileId: item.trimbleFileId, trimbleVersionId: item.trimbleVersionId,
              userUpload: item.userUpload, time: timeUpload, isTrimble: (item.trimbleFileId !== '' && item.trimbleFileId !== undefined) ? true : false,
              status: item.status , note: item.revisionNote
            })
            lastVersion = item.version
          })
          this.setState({ loading: false, itemVersion: optionVersion, item: res.data.item, lastVersion })
        })
    }
  }
  handleClosePanel = () => {
    this.setState({ itemVersion: [] })
    this.props.onClose()
  }
  handleChangeRadio = (e, data) => {
    this.props.onChangeVersion(data)

  }
  deleteVersion = (event) => {
    if (event.currentTarget.id === this.props.objectIdDefault) {
      message.error("Can't delete current version")
    }
    else {
      var r = window.confirm("Are you want to delete version");
      if (r === true) {
        axios.delete(`/api/items/deleteversion`, { data: { itemId: this.props.itemId, objectId: event.currentTarget.id } })
          .then(res => {
            window.location.reload()
          })
      }
    }

  }
  downloadVersion = (event) => {
    var r = window.confirm("Are you want to download version");
    if (r === true) {
      let data = { itemId: this.props.itemId, objectId: event.currentTarget.id, name: this.props.itemName }
      this.props.handleDownloadVersion(data)
    }
  }
  viewVersion = (item) => {
    if (item.status === 'success') {
      this.props.history.push({
        pathname: this.state.item.isTrimble ? `/projects/project/folder/view-trimble` : `/projects/project/folder/view`,
        state: {
          userCreatedProject: this.props.location.state.userCreatedProject,
          id: item.value,
          itemId: this.props.itemId,
          itemName: this.props.itemName,
          itemDisplayName: this.state.item.itemDisplayName,
          folderId: this.props.location.state.folderId,
          folderName: this.props.location.state.folderName,
          projectId: this.props.location.state.projectId,
          projectName: this.props.location.state.projectName,
          sharePointId: this.props.location.state.sharePointId,
          issueKey: this.props.location.state.issueKey,
          trimbleKey: this.props.location.state.trimbleKey,
          smartSheetId: this.state.item.smartSheetId,
          manningScheduleId: this.state.item.manningScheduleId,
          folderSmartSheetId: this.state.item.folderSmartSheetId,
          version: item.version,
          objectKey: item.text,
          listAdmin: this.props.location.state.listAdmin,
          typeProject: this.props.location.state.typeProject,
          typeFile: this.state.item.typeFile,
          isDetail: true,
          isTrimble: this.state.item.isTrimble,
          trimbleFileId: item.trimbleFileId, trimbleVersionId: item.trimbleVersionId,
          lastVersion: this.state.lastVersion,
          useRfa: this.props.location.state.useRfa,
        }
      })
    }

  }
  render() {

    return (
      <div>
        <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} size={this.state.openDownloadPanel ? 'sm' : 'lg'} >
          <Modal.Header>
            <Modal.Title>{this.state.openDownloadPanel ? 'Downloading' : "Version"}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: 'calc(100vh - 200px)' }}>
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical />}
            {this.state.openDownloadPanel ?
              <div>   {this.state.percentDownload}%
                <Progress percent={this.state.percentDownload} inverted indicating />
              </div>
              :
              <Table compact celled >
                <Table.Header>
                  <Table.Row>
                    {/* <Table.HeaderCell /> */}
                    <Table.HeaderCell style={{ width: 30 }}>Version</Table.HeaderCell>
                    <Table.HeaderCell>Note</Table.HeaderCell>
                    <Table.HeaderCell>View</Table.HeaderCell>
                    <Table.HeaderCell>Delete</Table.HeaderCell>
                    <Table.HeaderCell>Download</Table.HeaderCell>
              
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>User Upload</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
             
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.itemVersion.map(item =>
                    <Table.Row>
                      {/* <Table.Cell collapsing style={{ verticalAlign: "middle" }} >
                        <Radio toggle id={item.description}
                          value={item.value}
                          checked={this.props.objectIdDefault === item.value}
                          onChange={this.handleChangeRadio} />
                      </Table.Cell> */}
                      <Table.Cell style={{ verticalAlign: "middle" }}>
                        <Typography collapsing gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "13px" }} >
                          {item.description}
                        </Typography>
                      </Table.Cell>
                      <Table.Cell collapsing style={{ verticalAlign: "middle" }} >
                        <Popover
                          content={<p> {item.note}</p>}
                          title="Revision Note"         
                        >
                          <IconButton id={item.value} value={item.text}  >
                            <FeedbackIcon style={{ fontSize: 25 }} color="primary" /></IconButton>
                        </Popover>

                      </Table.Cell>
                      <Table.Cell collapsing style={{ verticalAlign: "middle" }} >
                        <IconButton onClick={this.viewVersion.bind(this, item)} id={item.value} value={item.text}
                          disabled={this.props.objectIdDefault === item.value} ><VisibilityIcon style={{ fontSize: 25 }}
                            color={(this.props.objectIdDefault !== item.value) ? item.status === 'success' ? 'primary' : 'error' : 'disabled'} /></IconButton>
                      </Table.Cell>
                      <Table.Cell collapsing style={{ verticalAlign: "middle" }} >
                        <IconButton onClick={this.deleteVersion} id={item.value} disabled={this.props.objectIdDefault === item.value || item.isTrimble}
                          value={item.text} ><DeleteOutlineTwoToneIcon style={{ fontSize: 25 }}
                            color={(this.props.objectIdDefault !== item.value && !item.isTrimble) ? "error" : 'disabled'} /></IconButton>
                      </Table.Cell>
                      <Table.Cell collapsing style={{ verticalAlign: "middle" }} >
                        <IconButton onClick={this.downloadVersion} id={item.value} value={item.text}  ><CloudDownloadTwoToneIcon style={{ fontSize: 25 }}
                          color="primary" /></IconButton>
                      </Table.Cell>
                      <Table.Cell style={{ verticalAlign: "middle" }} >
                        <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "13px" }} >
                          {item.text}
                        </Typography>
                      </Table.Cell>
                      <Table.Cell style={{ verticalAlign: "middle" }}>
                        <Typography collapsing gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "13px" }} >
                          {item.userUpload}
                        </Typography>
                      </Table.Cell>
                      <Table.Cell style={{ verticalAlign: "middle" }}>
                        <Typography collapsing gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "13px" }} >
                          {item.time}
                        </Typography>
                      </Table.Cell>
                    
                    </Table.Row>)}
                </Table.Body>
              </Table>}

          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>



      </div>

    );
  }
}


export default DialogVersionItem