import axios from "axios";
import _ from "lodash";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export const func_process_conc_do_data = async (raw_data) => {
  try {
    const processed_data = raw_data;
    processed_data.forEach((e) => {
      e.do_date = dayjs(e.do_date).format("DD-MM-YYYY");
      e.change = false;
    });

    var concvol = processed_data.reduce((acc, curr) => acc + parseFloat(curr.volume), 0);
    let formattedvol = concvol.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    var staticInfo = [
      {
        name: "No of DO:",
        value: processed_data.length,
        color: "#093152",
      },
      { name: "Volume(m3)", value: formattedvol, color: "#093152" },
    ];
    return { processed_data, staticInfo };
  } catch (error) {
    console.log("error at process data: ", error);
  }
};

export const func_process_conc_inv_data = async (raw_data, project_id, listInvoiceName) => {
  try {
    //get distint list of invoice
    var resDistinceInv = await axios.get("/api/do_inv/s3rds/list_form", {
      params: {
        formtype: "conc_inv",
        project_id: project_id,
      },
    });
    const invDistincList = resDistinceInv.data.data.items;
    //transform the array
    var temp1 = _.groupBy(raw_data, "inv_number");
    var processed_data = [];
    for (let n in temp1) {
      let g = temp1[n];
      const vol = g.reduce((acc, curr) => acc + parseFloat(curr.volume), 0);
      const amt = g.reduce((acc, curr) => acc + parseFloat(curr.amount_claim), 0);
      const amtgst = g.reduce((acc, curr) => acc + parseFloat(curr.amount_gst), 0);
      g.map((e) => {
        if (e.do_date !== undefined && e.do_date !== null) {
          e["date"] = dayjs(e.do_date).format("DD-MM-YYYY");
        }
      });

      //get s3_key for invoice
      const key = `${project_id}_${"conc_inv"}_${n}.pdf`;
      let get_s3_key = null;
      if (invDistincList.includes(key.toLowerCase())) {
        get_s3_key = "<button>Open Inv</button>";
      }

      var ent = {
        id: uuidv4(),
        inv_number: n,
        do_number: g.length + "nos",
        date: dayjs(g[0].inv_date).format("DD-MM-YYYY"),
        grade: "",
        volume: vol,
        unit_price: "",
        amount_claim: amt,
        amount_gst: amtgst,
        supplier: [...new Set(g.map((a) => a.supplier))],
        s3_key: null,
        s3_key_check: get_s3_key,
        remark: "",
        row_type: "inv",
        change: false,
      };
      processed_data.push(ent);
      processed_data = processed_data.concat(g);
    }
    var concvol = raw_data.reduce((acc, curr) => acc + parseFloat(curr.volume), 0);
    let formattedvol = concvol.toLocaleString("en-US", {
      maximumFractionDigits: 1,
      useGrouping: true,
    });

    var arr_inv = processed_data.filter((e) => e.row_type === "inv");
    var amount = raw_data.reduce((acc, curr) => acc + parseFloat(curr.amount_gst), 0);
    let formattedamount = amount.toLocaleString("en-US", {
      maximumFractionDigits: 1,
      useGrouping: true,
    });

    var staticInfo = [
      {
        name: "No of Inv:",
        value: arr_inv.length,
        color: "#093152",
      },
      { name: "Volume(m3)", value: formattedvol, color: "#093152" },
      {
        name: "Amount",
        value: formattedamount,
        color: "#093152",
        prefix: "$",
      },
    ];

    return { processed_data, staticInfo };
  } catch (error) {
    console.log("error at process data: ", error);
  }
};

export const func_process_rebar_do_data = async (raw_data) => {
  try {
    const processed_data = raw_data;
    processed_data.forEach((e) => {
      e.do_date = dayjs(e.do_date).format("DD-MM-YYYY");
      e.change = false;
    });

    var rebarweight = raw_data.reduce((acc, curr) => acc + parseFloat(curr.weight_qty), 0);
    let formattedweight = rebarweight.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    var staticInfo = [
      {
        name: "No of DO:",
        value: raw_data.length,
        color: "#093152",
      },
      { name: "Weight(kg)", value: formattedweight, color: "#093152" },
    ];
    return { processed_data, staticInfo };
  } catch (error) {
    console.log("error at process data: ", error);
  }
};

export const func_process_rebar_inv_data = async (raw_data, project_id) => {
  try {
    var processed_data = [];
    raw_data.forEach((e) => {
      e.change = false;
    });

    //get distint list of invoice
    var resDistinceInv = await axios.get("/api/do_inv/s3rds/list_form", {
      params: {
        formtype: "rebar_inv",
        project_id: localStorage.getItem("fp_project_id"),
      },
    });
    const invDistincList = resDistinceInv.data.data.items;
    //transform the array
    var temp1 = _.groupBy(raw_data, "inv_number");
    for (let n in temp1) {
      let g = temp1[n];
      const weight = g.reduce((acc, curr) => acc + parseFloat(curr.total_weight), 0);
      const amt = g.reduce((acc, curr) => acc + curr.amount_claim, 0);
      const amtgst = g.reduce((acc, curr) => acc + curr.amount_gst, 0);
      g.map((e) => {
        if (e.do_date !== undefined && e.do_date !== null) {
          e["date"] = dayjs(e.do_date).format("DD-MM-YYYY");
        }
      });

      var ent = {
        id: uuidv4(),
        inv_number: n,
        do_number: g.length + "nos",
        date: dayjs(g[0].inv_date).format("DD-MM-YYYY"),
        total_weight: weight,
        unit_price: "",
        amount_claim: amt,
        amount_gst: amtgst,
        supplier: [...new Set(g.map((a) => a.supplier))],
        s3_key: invDistincList.includes(n) ? "<button onClick={handleClick()}>Click</button>" : null,
        remark: "",
        row_type: "inv",
        change: false,
      };
      processed_data.push(ent);
      processed_data = processed_data.concat(g);
    }

    var weight = raw_data.reduce((acc, curr) => acc + parseFloat(curr.weight_qty), 0);
    let formattedweight = weight.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      useGrouping: true,
    });

    var amount = raw_data.reduce((acc, curr) => acc + parseFloat(curr.amount_gst), 0);
    let formattedamount = amount.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      useGrouping: true,
    });

    var staticInfo = [
      {
        name: "No of Inv:",
        value: raw_data.length,
        color: "#093152",
      },
      { name: "Weight(kg)", value: formattedweight, color: "#093152" },
      {
        name: "Amount",
        value: formattedamount,
        color: "#093152",
        prefix: "$",
      },
    ];

    return { processed_data, staticInfo };
  } catch (error) {
    console.log("error at process data: ", error);
  }
};
