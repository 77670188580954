import { Modal, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { filterHistoryDataByDateRangeAndCompany, getRefStringWithVersion, mongoObjectId } from '../../../utils';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { getKeyTextForSheet } from '../pageSpreadsheet/PanelSetting';
import ButtonStyle from './ButtonStyle';
import FormFilterActivityHistory from './FormFilterActivityHistory';
import IconTable from './IconTable';
import PanelCalendarDuration from './PanelCalendarDuration';

const { Option } = Select;


const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const TableAllFormActivityHistory = (props) => {

   const { pageSheetTypeName, stateRow, onClickCancelModal } = props;

   const { rowsFormAllInit, rowsRfaAllInit, rfaData } = stateRow;

   const [historyAll, setHistoryAll] = useState(null);
   const [historyAllInit, setHistoryAllInit] = useState(null);


   const refType = getKeyTextForSheet(pageSheetTypeName);

   const rowsAllToCheck = pageSheetTypeName === 'page-rfa'
      ? rowsRfaAllInit.filter(r => r['RFA Ref'])
      : rowsFormAllInit;


   const additionalColumns = pageSheetTypeName === 'page-rfa' ? ['Drawing Number'] : [];

   const headersShown = [
      `${refType.toUpperCase()} Ref`,
      ...additionalColumns,
      'Rev',
      'Status',
      'Action',
      'User',
      'Company',
      'Created At',
   ];


   useEffect(() => {

      let outputArr = [];

      rowsAllToCheck.forEach(row => {

         const objAdditional = pageSheetTypeName === 'page-rfa'
            ? { 'Drawing Number': row['Drawing Number'] }
            : {};

         let rfaInfo;
         if (pageSheetTypeName === 'page-rfa') {
            rfaInfo = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']) || {};
         };


         outputArr.push({
            id: mongoObjectId(),
            [`${refType.toUpperCase()} Ref`]: pageSheetTypeName === 'page-rfa'
               ? getRefStringWithVersion(rfaInfo, refType)
               : getRefStringWithVersion(row, refType),

            'User': pageSheetTypeName === 'page-rfa'
               ? getInfoValueFromRefDataForm(rfaInfo, 'submission', refType, 'user')
               : getInfoValueFromRefDataForm(row, 'submission', refType, 'user'),

            'Status': 'Consultant reviewing',
            'Action': 'Submission',

            'Created At': pageSheetTypeName === 'page-rfa'
               ? (getInfoValueFromRefDataForm(rfaInfo, 'submission', refType, 'dateSendNoEmail') || getInfoValueFromRefDataForm(rfaInfo, 'submission', refType, 'date'))
               : (getInfoValueFromRefDataForm(row, 'submission', refType, 'dateSendNoEmail') || getInfoValueFromRefDataForm(row, 'submission', refType, 'date')),

            'Rev': pageSheetTypeName === 'page-rfa'
               ? rfaInfo['revision']
               : row['revision'],

            'Company': 'Woh Hup Private Ltd',
            ...objAdditional
         });


         const consultantMustReplyArray = pageSheetTypeName === 'page-rfa'
            ? (getInfoValueFromRefDataForm(rfaInfo, 'submission', refType, 'consultantMustReply') || [])
            : (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || []);


         consultantMustReplyArray.forEach(cmp => {
            const statusReply = getInfoValueFromRefDataForm(row, 'reply', refType, 'status', cmp);

            if (statusReply) {
               outputArr.push({
                  id: mongoObjectId(),
                  [`${refType.toUpperCase()} Ref`]: pageSheetTypeName === 'page-rfa'
                     ? getRefStringWithVersion(rfaInfo, refType)
                     : getRefStringWithVersion(row, refType),

                  'User': pageSheetTypeName === 'page-rfa'
                     ? getInfoValueFromRefDataForm(rfaInfo, 'reply', refType, 'user', cmp)
                     : getInfoValueFromRefDataForm(row, 'reply', refType, 'user', cmp),

                  'Status': getInfoValueFromRefDataForm(row, 'reply', refType, 'status', cmp),

                  'Action': 'Reply',

                  'Created At': pageSheetTypeName === 'page-rfa'
                     ? (getInfoValueFromRefDataForm(rfaInfo, 'reply', refType, 'dateSendNoEmail', cmp) || getInfoValueFromRefDataForm(rfaInfo, 'reply', refType, 'date', cmp))
                     : (getInfoValueFromRefDataForm(row, 'reply', refType, 'dateSendNoEmail', cmp) || getInfoValueFromRefDataForm(row, 'reply', refType, 'date', cmp)),

                  'Rev': pageSheetTypeName === 'page-rfa'
                     ? rfaInfo['revision']
                     : row['revision'],

                  'Company': cmp,
                  ...objAdditional
               })
            };
         });
      });


      const newData = filterHistoryDataByDateRangeAndCompany(outputArr, 'All companies', 'Today');
      const listCompanies = [...new Set(outputArr.map(row => row['Company']))].sort();
      setListCompanyButton(listCompanies);
      setHistoryAll(sortDataBeforePrint(newData));

      setHistoryAllInit(outputArr);

   }, []);


   const [dateFilter, setDateFilter] = useState('Today');
   const [listCompanyButton, setListCompanyButton] = useState([]);
   const [companyToFilter, setCompanyToFilter] = useState('All companies');




   const [modalFilter, setModalFilter] = useState(false);

   const applyFilter = (data) => {
      setHistoryAll(data);
      setModalFilter(false);
   };

   const sortDataBeforePrint = (data) => {
      data.sort((b, a) => {
         return new Date(a['Created At']) - new Date(b['Created At']);
      });
      return data;
   };


   const [dateRange, setDateRange] = useState(null);
   const onClick = () => {
      if (!dateRange) return;
      let newDataRange = historyAllInit.filter(r => {
         let dt = moment(r['Created At']);
         return dt <= dateRange[1] && dt >= dateRange[0];
      });
      const newData = filterHistoryDataByDateRangeAndCompany(newDataRange, companyToFilter, 'All');
      setHistoryAll(sortDataBeforePrint(newData));
   };

   const resetDataFilter = () => {
      setDateFilter('All');
      setCompanyToFilter('All companies');
      const newData = filterHistoryDataByDateRangeAndCompany(historyAllInit, 'All companies', 'All');
      setHistoryAll(sortDataBeforePrint(newData));
   };

   const checkDataWithinDays = (dateFilter) => {
      const newData = filterHistoryDataByDateRangeAndCompany(historyAllInit, companyToFilter, dateFilter);
      setHistoryAll(sortDataBeforePrint(newData));
   };


   const [currentWindow, setCurrentWindow] = useState({ width: window.innerWidth, height: window.innerHeight });
   useEffect(() => {
      const handleResize = () => {
         setCurrentWindow({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   });




   return (
      <>
         {historyAll && (
            <div style={{
               background: 'white',
               padding: 24,
               display: 'flex',
               justifyContent: 'center',
               flexDirection: 'column',
            }}>
               <div style={{ display: 'flex', marginBottom: 10 }}>
                  <PanelCalendarDuration pickRangeDate={(e) => setDateRange(e)} />
                  <ButtonStyle
                     onClick={onClick}
                     marginLeft={5}
                     name='Check History'
                  />
               </div>
               <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                     <div style={{ marginRight: 10, display: 'flex' }}>
                        <IconTable type='filter' onClick={() => setModalFilter(true)} isActivityTable={true} />
                        <IconTable type='swap' onClick={resetDataFilter} />
                     </div>

                     {['All', 'Today', 'Last 3 Days', 'Last 7 Days', 'Last 14 Days', 'This Month'].map((btn, i) => (
                        <ButtonStyle
                           key={i}
                           marginRight={5} name={btn}
                           background={dateFilter === btn ? colorType.primary : 'white'}
                           borderColor={dateFilter === btn ? colorType.primary : colorType.grey3}
                           colorText={dateFilter === btn ? 'white' : 'black'}
                           onClick={() => {
                              setDateFilter(btn);
                              checkDataWithinDays(btn);
                           }}
                        />
                     ))}
                  </div>

                  <SelectStyled
                     value={companyToFilter}
                     style={{ marginLeft: 50, width: 200, color: 'black' }}
                     onChange={(cmp) => {
                        setCompanyToFilter(cmp);

                        const newData = filterHistoryDataByDateRangeAndCompany(historyAllInit, cmp, dateFilter);
                        setHistoryAll(sortDataBeforePrint(newData));
                     }}
                  >
                     {listCompanyButton.map(cmp => (
                        <Option key={cmp} value={cmp}>{cmp}</Option>
                     ))}
                  </SelectStyled>

               </div>



               <div style={{
                  width: currentWindow.width * 0.8 - 50,
                  height: currentWindow.height * 0.8 - 100,
                  margin: '0 auto',
                  textAlign: 'center'
               }}>
                  <TableStyled
                     fixed
                     columns={generateColumns(headersShown)}
                     data={historyAll}
                     rowHeight={28}
                  />
               </div>


               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonStyle
                     colorText='white'
                     borderColor={colorType.grey1}
                     background={colorType.primary}
                     onClick={onClickCancelModal}
                     name={'Close'}
                  />
               </div>
            </div>
         )}

         {modalFilter && (
            <ModalStyledSetting
               title='Filter Data Activity History'
               visible={modalFilter}
               footer={null}
               onCancel={() => {
                  setModalFilter(false);
               }}
               destroyOnClose={true}
               centered={true}
            >
               <FormFilterActivityHistory
                  applyFilter={applyFilter}
                  onClickCancelModal={() => setModalFilter(false)}
                  rowsAll={historyAll}
                  headers={headersShown}
               />
            </ModalStyledSetting>
         )}
      </>
   );
};

export default TableAllFormActivityHistory;



const SelectStyled = styled(Select)`
   .ant-select-selection {
      border-radius: 0;
   }
`;


const generateColumns = (headers) => {

   return [
      {
         key: 'index',
         dataKey: 'index',
         title: '',
         width: 70,
         cellRenderer: ({ rowIndex }) => {
            return (
               <span>{rowIndex + 1}</span>
            );
         }
      },
      ...headers.map((column, columnIndex) => ({
         key: column,
         dataKey: column,
         title: column,
         resizable: true,
         width: getHeaderWidth2(column),
         cellRenderer: column === 'Created At'
            ? ({ cellData }) => {
               return (
                  <div style={{ color: 'black' }}>{moment(cellData).format('DD/MM/YY - HH:mm')}</div>
               );
            } : null
      }))
   ];
};


const getHeaderWidth2 = (header) => {
   if (header.includes(' Ref')) return 220;
   if (header === 'Rev') return 45;
   if (header === 'Action') return 100;
   if (header === 'User') return 220;
   if (header === 'Created At') return 700;
   if (header === 'Status') return 250;
   if (header === 'Company') return 150;
   if (header === 'Drawing Number') return 220;
};

const ModalStyledSetting = styled(Modal)`
   .ant-modal-content {
   border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;
const TableStyled = styled(Table)`

   .BaseTable__row-cell-text {
      color: black
   }

   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   }

   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   }
`;






