import React from 'react';
import axios from 'axios'
import { Modal, Loader } from 'rsuite';
import {
  Button, IconButton,
  FormControl, Input, InputLabel, InputAdornment, TextField
} from '@material-ui/core';
import _ from 'lodash'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { message } from 'antd';
class DialogAboutWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      link: {
        linkWeeklyReport: '',
        linkDeliveryVsStatus: '',
        linkSitePhoto:'',
        linkQSReport: '',
        password: '',
      },
      showPassword: false,
    }
  }


  componentWillMount = () => {
    this.setState({ loading: true })

    axios.get("/api/items/get-link-report", { params: { itemId: this.props.itemId ,auth: true } })
      .then(res => {
        this.setState({
          link: res.data.link,
          loading: false
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  handleChangeLinkReport = (name, e) => {
    let value =e.target.value
    this.setState(prevState => {
      let link = Object.assign({}, prevState.link);
      link[name] =value
      return { link };
    })
  }
  handleClosePanel = () => {
    this.props.onChangeDisplay('dialogShareReport', false)
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  }
  handleSaveSettingLink = () => {
    this.setState({ loading: true })
    axios.post("/api/items/set-link-report", {   itemId: this.props.itemId , linkReport : this.state.link})
      .then(() => {
        window.location.reload();
        this.props.onChangeDisplay('dialogShareReport', false)
      })
      .catch(() => {
        message.error('Link report was not saved')
        this.setState({ loading: false })
      })
  }
  render() {
    return (
      <Modal show={this.props.openPanel} onHide={this.handleClosePanel.bind(this)} style={{ paddingTop: '3%' }} size='xs'>
        <Modal.Header>
          <Modal.Title>Setting Link Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}

          <TextField style={{ width: '100%' }} onChange={this.handleChangeLinkReport.bind(this, 'linkWeeklyReport')}
            label="Weekly Report" defaultValue="Weekly Report" value={this.state.link.linkWeeklyReport}
            margin="dense" variant="outlined"
          />
          <TextField style={{ width: '100%' }} onChange={this.handleChangeLinkReport.bind(this, 'linkDeliveryVsStatus')}
            label="Delivery Schudule VS Status" defaultValue="Delivery Schudule VS Status" value={this.state.link.linkDeliveryVsStatus}
            margin="dense" variant="outlined"
          />
                <TextField style={{ width: '100%' }} onChange={this.handleChangeLinkReport.bind(this, 'linkSitePhoto')}
            label="Site Photos/Videos" defaultValue="Site Photos/Videos" value={this.state.link.linkSitePhoto}
            margin="dense" variant="outlined"
          />
          <TextField style={{ width: '100%' }} onChange={this.handleChangeLinkReport.bind(this, 'linkQSReport')}
            label="QS Report" defaultValue="QS Report" value={this.state.link.linkQSReport}
            margin="dense" variant="outlined"
          />
          <FormControl>
            <InputLabel htmlFor="adornment-password">Password</InputLabel>
            <Input
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.link.password}
              onChange={this.handleChangeLinkReport.bind(this,'password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleSaveSettingLink} >Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DialogAboutWeb