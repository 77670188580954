import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { List, message, Typography, Collapse, Tabs } from 'antd';
import { Icon, Divider } from 'rsuite';
import _ from 'lodash'
// import '../scss/SideBar.scss'
import { setupForgeExtensionBeforeLoaded } from '../function/ForgeFunction'
// import '../scss/CustomComponent.scss'
import * as semantic from 'semantic-ui-react';
import { Scrollbars } from 'react-custom-scrollbars';
const { Text, Title, Paragraph } = Typography

const Autodesk = window.Autodesk;
const THREE = window.THREE;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
function SideBarViewable(props) {

  const [loading, setLoading] = useState(false)

  const [data3D, setData3D] = useState([])
  const [data2D, setData2D] = useState([])
  const [document, setDocument] = useState()
  useEffect(() => {
    if (props.displayPanel) {
      setLoading(true)
      let temp3d = []
      let temp2d = []
      let view3d = props.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
      let view2d = props.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)

      // console.log(viewAll)
      _.forEach(view3d, v => {
        if (v.data.size >= 0) {
          if (props.viewer.impl.model.getDocumentNode().data.guid === v.data.guid) {
            temp3d.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v, isSelected: true, url: '' })
          } else {
            temp3d.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v, isSelected: false, url: '' })
          }
        }

      })
      _.forEach(view2d, v => {
        if (props.viewer.impl.model.getDocumentNode().data.guid === v.data.guid) {
          temp2d.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v, isSelected: true, url: '' })
        } else {
          temp2d.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v, isSelected: false, url: '' })
        }
      })
      setLoading(false)
      setData3D(temp3d)
      setData2D(temp2d)
      setDocument(props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument)
    }
  }, [])
  const handleCloseViewPanel = () => {
    props.onDisplayPanelChange('viewablePanel', false)
  }
  const handleViewable = (item) => {
    setLoading(true)
    let temp2d = data2D.slice(0)
    let temp3d = data3D.slice(0)
    _.forEach(temp2d, v => {
      if (v.value === item.value)
        v.isSelected = true
      else
        v.isSelected = false
    })
    _.forEach(temp3d, v => {
      if (v.value === item.value)
        v.isSelected = true
      else
        v.isSelected = false
    })
    var loadOptions = {
      globalOffset: props.viewer.impl.model.myData.globalOffset,
      // applyRefPoint: true,
      modelNameOverride: props.viewer.impl.model.myData.loadOptions.modelNameOverride,
      isAEC: true,
      guid: props.viewer.impl.model.myData.loadOptions.guid,
      viewableID: props.viewer.impl.model.myData.loadOptions.viewableID,
      itemId: props.viewer.impl.model.myData.loadOptions.itemId,
      version: props.viewer.impl.model.myData.loadOptions.version,
      // acmSessionId: document.acmSessionId
    };

    props.viewer.loadDocumentNode(document, item.obj, loadOptions)
      .then(i => {
        setLoading(false)
        setupForgeExtensionBeforeLoaded(props.viewer, props.viewerPage)
        props.viewer.setEnvMapBackground(18)
      })
      .catch(r => {
        setLoading(false)
        props.onChangeViewerModel(false)
      }) //! disable side nav
    props.onChangeViewerModel(true)//! enable side nav

    setData2D(temp2d)
    setData3D(temp3d)
    // ! when status for claim display
    if (props.viewerPage.state.disabledBtn.btnStatusForClaim) {
      message.warning('You need to setup for this view')
      props.onChangeDialogDisplay('dialogStatusForClaim', true)
    }
  }
  const handleChangTab = (key) => {
    if (key === '2') {
      let temp2d = data2D.slice(0)
      let temp3d = data3D.slice(0)
      let count = temp2d.length + temp3d.length
      _.forEach(temp2d, v => {
        Autodesk.Viewing.Thumbnails.getUrlForBubbleNode(v.obj)
          .then((srcUrl) => {
            v.url = srcUrl;
            count--
            if (count === 0) {
              setData2D(temp2d)
              setData3D(temp3d)
            }
          })
          .catch((err) => {
            v.url = ''
            count--
            if (count === 0) {
              setData2D(temp2d)
              setData3D(temp3d)
            }
          });
      })
      _.forEach(temp3d, v => {
        Autodesk.Viewing.Thumbnails.getUrlForBubbleNode(v.obj)
          .then((srcUrl) => {
            v.url = srcUrl;
            count--
            if (count === 0) {
              setData2D(temp2d)
              setData3D(temp3d)
            }
          })
          .catch((err) => {
            v.url = ''
            count--
            if (count === 0) {
              setData2D(temp2d)
              setData3D(temp3d)
            }
          });
      })

    }
  }

  return (
    <>
      <div style={{ width: 300, height: 'calc(100% - 53.78px)', position: 'absolute', left: 40, background: 'white', zIndex: 1, padding: 10 }}>
        <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}>
          <Title level={4} style={{ display: 'contents' }}>   Document Browser</Title>
          <Icon icon='close' size='xs' style={{ float: 'right', cursor:'pointer' }} onClick={handleCloseViewPanel} />
        </div>
        <Divider style={{ margin: '5px 0' }} />
        <div style={{ padding: '5px 0px 0px 0px', width: '100%', height: 'calc(100% - 36px)' }}>
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            <Tabs defaultActiveKey="1" onChange={handleChangTab}>
              <TabPane tab="View" key="1">
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1', '2']}
                >
                  <Panel header="3D" key="1" >
                    {data3D.length !== 0 ? <List
                      dataSource={data3D}
                      renderItem={item => {
                        return (
                          <List.Item style={{ marginBottom: '0px', backgroundColor: item.isSelected && ' lightblue', cursor: 'pointer' }} id='custom-list-viewable'
                            onClick={handleViewable.bind(this, item)}>
                            <Text strong style={{ fontSize: 10 }}>
                              <Icon icon={'coincide'} style={{ marginRight: 3 }} />{item.label}</Text>
                          </List.Item>
                        )
                      }}
                    /> :
                      <Text strong style={{ fontSize: 10 }}>Three are no views in this group</Text>}
                  </Panel>
                  <Panel header="Sheet" key="2" >
                    {data2D.length !== 0 ? <List
                      dataSource={data2D}
                      renderItem={item => {
                        return (
                          <List.Item style={{ marginBottom: '0px', backgroundColor: item.isSelected && ' lightblue', cursor: 'pointer' }} id='custom-list-viewable'
                            onClick={handleViewable.bind(this, item)}>
                            <Text strong style={{ fontSize: 10 }}>
                              <Icon icon={'newspaper-o'} style={{ marginRight: 3 }} />{item.label}</Text>
                          </List.Item>
                        )
                      }}
                    /> :
                      <Text strong style={{ fontSize: 10 }}>Three are no views in this group</Text>}
                  </Panel>
                </Collapse>
              </TabPane>
              <TabPane tab="Thumbnail" key="2">
                <Collapse
                  bordered={true}
                  defaultActiveKey={['1', '2']}
                >
                  <Panel header="3D" key="1" >
                    {data3D.length !== 0 ? <List
                      dataSource={data3D}
                      renderItem={item => {
                        return (
                          <List.Item style={{ marginBottom: '0px', backgroundColor: item.isSelected && ' lightblue', cursor: 'pointer' }} id='custom-list-viewable'
                            onClick={handleViewable.bind(this, item)}>

                            <Text strong style={{ width: "100%", textAlign: '-webkit-center', fontSize: 10 }}>
                              {item.url !== '' && <><semantic.Image src={item.url} style={{ width: 150 }} /></>}
                              <Icon icon={'coincide'} style={{ marginRight: 3 }} />{item.label}</Text>
                          </List.Item>
                        )
                      }}
                    /> :
                      <Text strong style={{ fontSize: 10 }}>Three are no views in this group</Text>}
                  </Panel>
                  <Panel header="Sheet" key="2" >
                    {data2D.length !== 0 ? <List
                      dataSource={data2D}
                      renderItem={item => {
                        return (
                          <List.Item style={{ marginBottom: '0px', backgroundColor: item.isSelected ? ' lightblue' : '', cursor: 'pointer' }} id='custom-list-viewable'
                            onClick={handleViewable.bind(this, item)}>

                            <Text strong style={{ width: "100%", textAlign: '-webkit-center', fontSize: 10 }}>
                              {item.url !== '' && <><semantic.Image src={item.url} style={{ width: 150 }} /></>}
                              <Icon icon={'newspaper-o'} style={{ marginRight: 3 }} />{item.label}</Text>
                          </List.Item>
                        )
                      }}
                    /> :
                      <Text strong style={{ fontSize: 10 }}>Three are no views in this group</Text>}
                  </Panel>
                </Collapse>
              </TabPane>
            </Tabs>
          </Scrollbars>
        </div>

      </div>
    
    </>
  );
}
SideBarViewable.propTypes = {

}

function mapStateToProps(state) {
  return {

  }
}
export default connect(mapStateToProps)(SideBarViewable)


