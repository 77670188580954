import React, { useState, useEffect } from "react";
import {
   LineChart,
   Line,
   ResponsiveContainer,
   ReferenceLine,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
   Label,
   CartesianGrid,
} from "recharts";

const ChartTradePerMonth = ({ values, labels, tradeSelected, setTradeSelected }) => {
   const [barProps, setBarProps] = useState(
      labels.reduce(
         (a, { key }) => {
            a[key] = false;
            return a;
         },
         { hover: null }
      )
   );

   useEffect(() => {
      {
         let sf = null;
         if (tradeSelected !== undefined) {
            const find_sf = labels.find((e) => e.trade_full_name == tradeSelected);
            if (find_sf !== undefined) {
               sf = find_sf.key;
            }
         }
         if (!barProps[sf]) {
            setBarProps({ ...barProps, hover: sf });
         }
      }
   }, [tradeSelected]);

   const handleLegendMouseEnter = (e) => {
      if (!barProps[e.dataKey]) {
         setBarProps({ ...barProps, hover: e.dataKey });
      }
   };

   const handleLegendMouseLeave = (e) => {
      setBarProps({ ...barProps, hover: null });
   };

   const selectBar = (e) => {
      setBarProps({
         ...barProps,
         [e.dataKey]: !barProps[e.dataKey],
         hover: null,
      });
   };

   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div className="custom-tooltip">
               <p className="label">{`${label} : ${payload[0].value}`}</p>
               {/* <p className="intro">{getIntroOfPage(label)}</p> */}
               <p className="desc">Anything you want can be displayed here.</p>
            </div>
         );
      }

      return null;
   };

   const customMouseOver = (e) => {
      return (
         <div className="customMouseOver">
            <p className="label">{` : ${e[0]}`}</p>
         </div>
      );
   };

   const handleClick = (label) => {
      // console.log("   ", label);
      setTradeSelected(label.trade_full_name);
   };

   return (
      <div>
         <h5 left="10px">
            {tradeSelected !== undefined ? `% of fail checks by month for trade: ${tradeSelected}` : ""}
         </h5>
         <ResponsiveContainer width="100%" minHeight={"34vh"}>
            <LineChart data={values} margin={{ top: 25, right: 5, left: 5, bottom: 20 }}>
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <XAxis dataKey="name" />
               <YAxis />
               {/* <Tooltip cursor={false} wrapperStyle={{ display: "none" }} /> */}
               {/* <Legend
                  // onClick={selectBar}
                  // onMouseOver={handleLegendMouseEnter}
                  // onMouseOut={handleLegendMouseLeave}
                  verticalAlign="bottom"
                  height={26}
                  align="left"
                  iconType="rect"
                  iconSize={11}
                  wrapperStyle={{
                     left: 45,
                  }}
                  // formatter={(value, entry) => <span style={{ fontSize: "11" }}>{value.split(" ")[0]}</span>}
               /> */}
               <ReferenceLine
                  y={8}
                  label={{
                     position: "top",
                     value: "8%",
                     fontSize: 12,
                     fontWeight: 700,
                  }}
                  stroke="red"
                  strokeWidth={2}
                  strokeDasharray="3 3"
               />
               {labels.map((label, index) => (
                  <Line
                     // activeDot={{ stroke: "red", strokeWidth: 2, r: 10 }}
                     connectNulls
                     type="monotone"
                     key={index}
                     dataKey={label.key}
                     stroke="#000000"
                     fill="#000000"
                     // stroke={label.color}
                     // fill={label.color}
                     // hide={barProps[label.key] === true}
                     fillOpacity={Number(barProps.hover === label.key || !barProps.hover ? 1 : 0.05)}
                     strokeOpacity={Number(barProps.hover === label.key || !barProps.hover ? 1 : 0.05)}
                     strokeWidth={Number(barProps.hover === label.key ? 2.5 : 6)}
                     onClick={(e) => handleClick(label)}
                  />
               ))}
            </LineChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartTradePerMonth;
