import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Table } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { valueFormatterForDouble } from '../common';

function PointsTable(props) {
  const {
    allHolidays,
    cutOffDate,
    dataTimeSheet,
    currentMonthTimeSheet,
    tableHeight,
  } = props;
  const [rowDatas, setRowDatas] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (_.isArray(dataTimeSheet)) {
      GetDataTable();
    }
  }, [dataTimeSheet]);

  function GetDataTable() {
    let dataForTable = [];

    let dayDatas = [];

    dataTimeSheet.forEach((worker) => {
      if (_.isArray(worker.timeWorkingInDays)) {
        worker.timeWorkingInDays.forEach((dayData) => {
          dayDatas.push(dayData);
        });
      }
    });

    let groupByTrade = _.groupBy(dayDatas, 'occupation');

    let trades = Object.keys(groupByTrade);

    console.log('trades', trades);
    console.log('groupByTrade', groupByTrade);

    let allHolidayDateString = allHolidays.map((x) =>
      moment(x.date).format('DD-MM-YY')
    );

    let colorHomeLeave = '#FF99FF';
    let colorAbsent = '#FF0000';
    let colorCourse = '#FFC000';
    let colorSunday = '#ACB9CA';
    let colorMc = '#00FF00';
    let colorPulicHoliday = '#C6E0B4';

    let offDayColors = [
      {
        name: 'Medical (MC)',
        value: colorMc,
      },
      {
        name: 'Course / Training',
        value: colorCourse,
      },
      {
        name: 'Home Leave',
        value: colorHomeLeave,
      },
      {
        name: 'Absent',
        value: colorAbsent,
      },
    ];

    let columns = [
      {
        headerName: 'Trade',
        field: 'trade',
        rowGroup: true,
        pinned: 'left',
        hide: true,
      },
      {
        headerName: 'Code',
        field: 'code',
        filter: true,
        pinned: 'left',
        width: 100,
      },
      {
        headerName: 'Worker Name',
        field: 'name',
        filter: true,
        pinned: 'left',
        width: 150,
      },
    ];

    let startMoment = moment(currentMonthTimeSheet, 'MM-YYYY')
      .subtract(1, 'months')
      .set('date', cutOffDate + 1);
    let currentMonthMoment = moment(currentMonthTimeSheet, 'MM-YYYY');
    let endMoment = currentMonthMoment.set('date', cutOffDate);

    let tempStartMoment = moment(startMoment);

    let columnDays = [];
    while (!tempStartMoment.isAfter(endMoment)) {
      let daystring = tempStartMoment.format('DD-MM-YY');
      columnDays.push(daystring);

      columns.push({
        aggFunc: 'sum',
        headerName: daystring,
        field: daystring,
        width: 150,
        cellStyle: (params) => {
          try {
            return params.data[`${params.colDef.field}DayType`]
              ? {
                  backgroundColor: params.data[`${params.colDef.field}DayType`],
                }
              : null;
          } catch (error) {
            return null;
          }
        },
        valueFormatter: valueFormatterForDouble,
      });

      tempStartMoment = tempStartMoment.add(1, 'days');
    }

    columns.push({
      headerName: 'POINTS',
      field: 'totalPoints',
      width: 140,
      pinned: 'right',
      aggFunc: 'sum',
      valueFormatter: valueFormatterForDouble,
    });

    columns.push({
      headerName: 'Days',
      field: 'totalDays',
      width: 140,
      pinned: 'right',
      aggFunc: 'sum',
    });

    trades.forEach((trade, index) => {
      let groupByWorkerId = _.groupBy(groupByTrade[trade], 'idWorker');
      Object.keys(groupByWorkerId).forEach((workerId) => {
        let worker = groupByWorkerId[workerId][0];
        let rowData = {
          no: index,
          code: worker.workerEmployeeCode,
          name: worker.workerName,
          trade,
        };

        let totalPoints = 0.0;

        let totalDays = 0;
        columnDays.forEach((columnDay) => {
          let sumPoint = _.sumBy(
            groupByWorkerId[workerId].filter(
              (item) => item.dayYearString == columnDay
            ),
            'point'
          );

          if (sumPoint > 0) {
            totalDays++;
          }
          let shifts = groupByWorkerId[workerId].filter(
            (item) => item.dayYearString == columnDay
          );

          rowData[columnDay] = !_.isNaN(sumPoint) ? sumPoint : 0;
          totalPoints += !_.isNaN(sumPoint) ? sumPoint : 0;

          //check is off day
          let isDayOff = shifts.some((x) => x.isDayOff == true);

          if (isDayOff) {
            rowData[`${columnDay}DayType`] = colorHomeLeave;

            shifts.forEach((shift) => {
              let offDayColor = offDayColors.find(
                (offDayItem) => offDayItem.name == shift.costCodeName
              );
              
              if (offDayColor) {
                rowData[`${columnDay}DayType`] = offDayColor.value;
              }
            });
          }

          //set color sunday
          if (moment(columnDay, 'DD-MM-YY').weekday() === 0) {
            rowData[`${columnDay}DayType`] = colorSunday;
          }

          //set color public holiday
          if (allHolidayDateString.some((m) => columnDay == m)) {
            rowData[`${columnDay}DayType`] = colorPulicHoliday;
          }
        });

        rowData.totalPoints = totalPoints;
        rowData.totalDays = totalDays;
        dataForTable.push(rowData);
      });
    });

    setRowDatas(dataForTable);
    setColumnDefs(columns);
  }

  const autoGroupColumnDef = {
    pinned: 'left',
  };

  return (
    <div
      className='ag-theme-alpine'
      style={{ height: tableHeight, boxSizing: 'border-box' }}
    >
      <AgGridReact
        className='ag-theme-balham'
        reactUi='true'
        rowData={rowDatas}
        columnDefs={columnDefs}
        pagination={true}
        autoGroupColumnDef={autoGroupColumnDef}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      ></AgGridReact>
    </div>
  );
}

export default PointsTable;
