import { EXCEL_ITEMS } from '../../types';

const excel = data => ({
  type: EXCEL_ITEMS,
  data
});

export const excelData = data => dispatch => {
  dispatch(excel(data))
}
