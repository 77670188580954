import moment from 'moment'
import _, { constant } from 'lodash'
import HeaderGroup from '../customCell/HeaderGroup'
import Header from '../customCell/Header'
import { constants } from '../constants'
import axios from 'axios';
import { message } from 'antd'
const weekOfMonth = (m) => m.week() - moment(m).startOf('month').week() + 1

export const timelineCalc = (dateStart, dateEnd, typeTimeline, format, groupFormat) => {
  let startDate = dateStart.clone()
  let endDate = dateEnd.clone()
  // startDate = startDate.startOf(typeTimeline)
  // endDate = endDate.endOf(typeTimeline)
  let group = {}
  if (endDate.isBefore(startDate)) {
    endDate = startDate.clone().add(1, 'day')
  }

  while (startDate.isBefore(endDate)) {
    let groupName = startDate.format(groupFormat)
    if (!group[groupName])
      group[groupName] = []
    let f = startDate.format(format)
    if (f === '1 Dec 2021') {
      console.log('a')
    }
    // if (typeTimeline === 'week') {
    //   let m = startDate.format('MMM YYYY')
    //   let week = startDate.format('Wo')
    //   let matches = week.match(/\d+/g)[0];
    //   let a = startDate.format('DD MMM YYYY')
    //   console.log(a,  f,matches)
    //   f = matches + ' ' + m
    // }
    group[groupName].push(f);
    startDate.add(1, typeTimeline);
  }
  let date = []
  let width = 40
  let cellRenderer = 'manPowerCellWeek'
  _.forEach(group, (v, k) => {
    let temp = { field: k, enablePivot: true, lockVisible: true, editable: false, lockPosition: true, children: [], suppressPaste: true, filter: false, headerGroupComponentFramework: HeaderGroup, } //headerGroupComponentFramework: MyGroupHeader,
    _.forEach(v, (i, index) => {
      temp.children.push({
        field: i, width, lockPosition: true, enableRowGroup: false, filter: false, sortable: false,
        editable: false, isDialogEdit: true, cellRenderer,
        cellStyle: { padding: 3, }, headerComponentFramework: Header
        // , columnGroupShow: index === 0 ? '' : 'open' ,
      })
    })
    date.push(temp)
  })
  return date
}

export const timelineData = (newData, timelines, typeTimeline, initTimeline, init = false) => {
  let array = {}
  _.forEach(timelines, v => {
    let startDate = init ? moment(new Date(v.startDate), 'DD/MM/YYYY') : moment(new Date(v.startDate), 'DD/MM/YYYY')
    let endDate = init ? moment(new Date(v.endDate), 'DD/MM/YYYY') : moment(new Date(v.endDate), 'DD/MM/YYYY')
    if (init) {
      if (!initTimeline.startDateTotal) initTimeline.startDateTotal = startDate.clone()
      if (!initTimeline.endDateTotal) initTimeline.endDateTotal = endDate.clone()
      if (startDate.valueOf() < initTimeline.startDateTotal.valueOf()) initTimeline.startDateTotal = startDate.clone()
      if (endDate.valueOf() > initTimeline.endDateTotal.valueOf()) initTimeline.endDateTotal = endDate.clone()
    }

    if (endDate.isBefore(startDate)) {
      return
    }

    while (startDate.isBefore(endDate)) {
      let formatStart = startDate.format(typeTimeline == 'day' ? constants.dayFormat : constants.weekFormat)
      // let formatStartMonth = startDate.format(constants.monthFormat)
      // let formatStartYear = startDate.format(constants.yearFormat)
      _.forEach(v.data, i => {
        let number = _.toNumber(i.power)
        number = _.isNumber(number) ? number : 0
        if (newData[formatStart]) {
          if (!array[formatStart])
            array[formatStart] = { [i.projectName]: 0 }
          if (!array[formatStart][i.projectName]) {
            array[formatStart][i.projectName] = { count: 0, power: 0 }
            // array[formatStart][i.projectName] = array[formatStart][i.projectName] + number
          }
          array[formatStart][i.projectName].power = array[formatStart][i.projectName].power + number
          array[formatStart][i.projectName].count = array[formatStart][i.projectName].count + 1
        }
        else {
          if (!array[formatStart])
            array[formatStart] = { [i.projectName]: 0 }
          if (!array[formatStart][i.projectName]) {
            array[formatStart][i.projectName] = { count: 0, power: 0 }
            // array[formatStart][i.projectName] = array[formatStart][i.projectName] + number
          }
          array[formatStart][i.projectName].power = array[formatStart][i.projectName].power + number
          array[formatStart][i.projectName].count = array[formatStart][i.projectName].count + 1
        }
      })
      // let number = _.toNumber(v.power)
      // number = _.isNumber(number) ? number : 0
      // if (newData[formatStart]) {
      //   if (!array[formatStart])
      //     array[formatStart] = { [v.projectName]: 0 }
      //   if (!array[formatStart][v.projectName])
      //     array[formatStart][v.projectName] = 0
      //   array[formatStart][v.projectName] = array[formatStart][v.projectName] + number
      //   // let parse = JSON.parse(newData[formatStart])
      //   // newData[formatStart] = JSON.stringify({ projectName: parse.projectName + '_' + v.projectName, power: parse.power + '_' + number })
      // }
      // else {
      //   if (!array[formatStart])
      //     array[formatStart] = { [v.projectName]: 0 }
      //   if (!array[formatStart][v.projectName])
      //     array[formatStart][v.projectName] = 0
      //   array[formatStart][v.projectName] = array[formatStart][v.projectName] + number
      //   // newData[formatStart] = JSON.stringify({ projectName: v.projectName, power: number})
      // }
      startDate.add(1, 'day');
    }
  })
  // let temp = []
  let group = {}
  _.forEach(array, (value, date) => {
    if (typeTimeline === 'week' || typeTimeline === 'day') {
      let projectName = ''
      let power = ''
      _.forEach(value, (v, k) => {
        let tempPower = v.power / v.count
        projectName = projectName === '' ? k : projectName + '_' + k
        power = power === '' ? tempPower : power + '_' + tempPower
      })
      newData[date] = JSON.stringify([{ projectName, power }])
    } else {
      let format = date.split(' ')
      let week = format[0]
      let month = format[1]
      let year = format[2]
      if (typeTimeline === 'month') {
        let newDate = month + ' ' + year
        let monthParse = moment(month, constants.month)
        let weekNumber = _.toNumber(week) - monthParse.startOf('month').week()
        if (!group[newDate])
          group[newDate] = []
        // if (!group[newDate][weekNumber])
        //   group[newDate][weekNumber] = []
        _.forEach(value, (v, k) => {
          group[newDate].push({ projectName: k, power: v.power, count: v.count })
        })
      } else {
        // let newDate =format.format(constants.yearFormat)
        let monthParse = moment(month, constants.month)
        let monthNumber = _.toNumber(monthParse.format('M')) - 1
        if (!group[year])
          group[year] = []
        // if (!group[year][monthNumber])
        //   group[year][monthNumber] = []
        _.forEach(value, (v, k) => {
          group[year].push({ projectName: k, power: v.power, count: v.count })
        })
      }
    }
  })

  _.forEach(group, (value, d) => {
    let temp = []
    let projectName = ''
    let power = ''
    let tempGroup = {}
    _.forEach(value, (i, k) => {
      // projectName = projectName === '' ? i.projectName : projectName + '_' + i.projectName
      // power = power === '' ? i.power : power + '_' + i.power
      if (!tempGroup[i.projectName])
        tempGroup[i.projectName] = { count: 0, power: 0 }
      tempGroup[i.projectName].count = tempGroup[i.projectName].count + i.count
      tempGroup[i.projectName].power = tempGroup[i.projectName].power + i.power
    })
    _.forEach(tempGroup, (i, k) => {
      let tempPower = (i.power / i.count).toFixed(0)
      projectName = projectName === '' ? k : projectName + '_' + k
      power = power === '' ? tempPower : power + '_' + tempPower
    })
    temp.push({ projectName, power: power.toString() })
    newData[d] = JSON.stringify(temp)
  })
  // newData.values = array
  return newData
}



export const saveSetting = (props) => {
  if (props.userInfo) {
    let data = {
      email: props.userInfo.email,
      token: props.userInfo.token,
      setting: {
        timeline: window.timelineDateLocal ? window.timelineDateLocal : null,
        typeTimeline: window.typeTimelineLocal ? window.typeTimelineLocal : 'week'
      }
    }
    axios.post('/api/manpower/save-user-setting', { ...data })
      .then(res => {
        console.log('save setting')
      })
      .catch(err => {
        console.log(err)
        message.warning('Cannot save user setting')
      })
  }
}
// export const timelineData = (newData, timelines, props, initTimeline, init = false) => {
//   let array = {}
//   _.forEach(timelines, v => {
//     let startDate = init ? moment(new Date(v.startDate), 'DD/MM/YYYY') : moment(new Date(v.startDate), 'DD/MM/YYYY')
//     let endDate = init ? moment(new Date(v.endDate), 'DD/MM/YYYY') : moment(new Date(v.endDate), 'DD/MM/YYYY')
//     if (init) {
//       if (!initTimeline.startDateTotal) initTimeline.startDateTotal = startDate.clone()
//       if (!initTimeline.endDateTotal) initTimeline.endDateTotal = endDate.clone()
//       if (startDate.valueOf() < initTimeline.startDateTotal.valueOf()) initTimeline.startDateTotal = startDate.clone()
//       if (endDate.valueOf() > initTimeline.endDateTotal.valueOf()) initTimeline.endDateTotal = endDate.clone()
//     }

//     if (endDate.isBefore(startDate)) {
//       return
//     }
//     while (startDate.isBefore(endDate)) {
//       let formatStart = startDate.format(constants.weekFormat)
//       // let formatStartMonth = startDate.format(constants.monthFormat)
//       // let formatStartYear = startDate.format(constants.yearFormat)
//       let number = _.toNumber(v.power)
//       number = _.isNumber(number) ? number : 0
//       if (newData[formatStart]) {
//         if (!array[formatStart])
//           array[formatStart] = { [v.projectName]: 0 }
//         if (!array[formatStart][v.projectName])
//           array[formatStart][v.projectName] = 0
//         array[formatStart][v.projectName] = array[formatStart][v.projectName] + number
//         // let parse = JSON.parse(newData[formatStart])
//         // newData[formatStart] = JSON.stringify({ projectName: parse.projectName + '_' + v.projectName, power: parse.power + '_' + number })
//       }
//       else {
//         if (!array[formatStart])
//           array[formatStart] = { [v.projectName]: 0 }
//         if (!array[formatStart][v.projectName])
//           array[formatStart][v.projectName] = 0
//         array[formatStart][v.projectName] = array[formatStart][v.projectName] + number
//         // newData[formatStart] = JSON.stringify({ projectName: v.projectName, power: number})
//       }
//       startDate.add(1, 'week');
//     }
//   })
//   let temp = []
//   let group = {}
//   _.forEach(array, (value, date) => {
//     if (props.typeTimeline === 'week') {
//       let projectName = ''
//       let power = ''
//       _.forEach(value, (v, k) => {
//         projectName = projectName === '' ? k : projectName + '_' + k
//         power = power === '' ? v : power + '_' + v
//       })
//       newData[date] = JSON.stringify([{ projectName, power }])
//     } else {
//       let format = date.split(' ')
//       let week = format[0]
//       let month = format[1]
//       let year = format[2]
//       if (props.typeTimeline === 'month') {
//         let newDate = month + ' ' + year
//         let monthParse = moment(month, constants.month)
//         let weekNumber = _.toNumber(week) - monthParse.startOf('month').week()
//         if (!group[newDate])
//           group[newDate] = [[], [], [], [], []]
//         if (!group[newDate][weekNumber])
//           group[newDate][weekNumber] = []
//         _.forEach(value, (v, k) => {
//           group[newDate][weekNumber].push({ projectName: k, power: v })
//         })
//       } else {
//         // let newDate =format.format(constants.yearFormat)
//         let monthParse = moment(month, constants.month)
//         let monthNumber = _.toNumber(monthParse.format('M')) - 1
//         if (!group[year])
//           group[year] = [[], [], [], [], [], [], [], [], [], [], [], []]
//         if (!group[year][monthNumber])
//           group[year][monthNumber] = []
//         _.forEach(value, (v, k) => {
//           group[year][monthNumber].push({ projectName: k, power: v })
//         })
//       }
//     }
//   })

//   _.forEach(group, (value, d) => {
//     let temp = []
//     _.forEach(value, (v) => {

//       let projectName = ''
//       let power = ''
//       let tempGroup = {}
//       _.forEach(v, (i, k) => {
//         // projectName = projectName === '' ? i.projectName : projectName + '_' + i.projectName
//         // power = power === '' ? i.power : power + '_' + i.power
//         if (!tempGroup[i.projectName])
//           tempGroup[i.projectName] = 0
//         tempGroup[i.projectName] = tempGroup[i.projectName] + i.power
//       })
//       _.forEach(tempGroup, (i, k) => {
//         projectName = projectName === '' ? k : projectName + '_' + k
//         power = power === '' ? i : power + '_' + i
//       })
//       temp.push({ projectName, power: power.toString() })

//     })
//     newData[d] = JSON.stringify(temp)
//   })
//   // newData.values = array
//   return newData
// }