import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Row, Col, Radio, Tooltip, message } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import _ from "lodash";
import TableUnits from "./setting/TableUnits";

const UnitSetting = (current) => {
   const [filterMode, setFilterMode] = useState(0);

   return (
      <div style={{ margin: "10px 15px", height: "100%" }}>
         <h3>Units Information</h3>
         {/* <Row style={{ marginTop: "20px" }}></Row> */}

         <Row style={{ marginTop: "15px" }}>
            <TableUnits />
         </Row>

         {/* <Row style={{ marginTop: "15px" }}><Critical_Sum_Project data={failItemsByProjectCT} /></Row> */}
      </div>
   );
};

export default UnitSetting;
