import $ from 'jquery'
import { formatNumber } from '../../function/TableFunction'
import {Forge_Icon_Elevation} from '../../../../image'
import _ from 'lodash'
const ToolName = 'elevation-tool';



const Autodesk = window.Autodesk
let mark = []
// Simple viewer tool for drawing boxes and spheres
class ElevationTool extends Autodesk.Viewing.ToolInterface {
  constructor() {
    super();

    // Hack: delete functions defined *on the instance* of the tool.
    // We want the tool controller to call our class methods instead.
    delete this.register;
    delete this.deregister;
    delete this.activate;
    delete this.deactivate;
    delete this.getPriority;
    delete this.handleMouseMove;
    delete this.handleButtonDown;
    delete this.handleButtonUp;
    delete this.handleSingleClick;

    this.names = [ToolName];
  }

  register() {
    console.log('ElevationTool registered.');
  }

  deregister() {
    this.element.css('cursor', this.cursor)
    console.log('ElevationTool unregistered.');
  }

  activate(name, viewer) {
    mark = []
    this.viewer = viewer;

    console.log('ElevationTool', name, 'activated.');

    this.container = viewer.canvas.parentElement;
    this.rect = this.container.getBoundingClientRect();
    let doc = viewer.impl.model.getDocumentNode()
    let data = doc.getAecModelData()
    let data1 = this.viewer.model.getData()
    this.factor = this.viewer.impl.model.getData().loadOptions.fileExt === 'wfx' ? 304.8 : 1
    //this.angle = new THREE.Vector3(1, 0, 0).angleTo(new THREE.Vector3(data.refPointTransformation[0], data.refPointTransformation[1], data.refPointTransformation[2]))
    this.element = $('.canvas-wrap').children().last();
    this.cursor = this.element.css('cursor')
    this.div = document.createElement('div')
    this.div.style.zIndex = 100
    this.div.style.position = 'absolute'
    this.div.style.display = 'none'
    this.div.style.color = 'red'
    this.div.style.fontSize = '16px'
    this.div.style.textShadow = '0px 0px 20px black'
    this.div.innerHTML = ""
    document.getElementById('forgeViewer').appendChild(this.div);
    document.addEventListener('keyup', this.setupMarkUpElevation, false);
    this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this._updateMarkups);
  }


  deactivate(name) {
    document.removeEventListener('keyup', this.setupMarkUpElevation, false);
    this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this._updateMarkups);
    this.viewer = null;
    this.element.css('cursor', this.cursor)
    console.log('ElevationTool', name, 'deactivated.');
    document.getElementById('forgeViewer').removeChild(this.div);
    _.forEach(mark,v=>{
      document.getElementById('forgeViewer').removeChild(v.div);
    })
    mark = []
  }
  _updateMarkups=(e)=>{
    _.forEach(mark, v=>{
      const pos = this.viewer.worldToClient(v.point)
      v.div.style.top = pos.y  + "px"
      v.div.style.left = pos.x + "px"
    })   
  }
  setupMarkUpElevation =(e)=> {
    let x = e.key;
    if (x == "t" || x == "T") {
      let hitTest = this.viewer.hitTest(this.x, this.y, true);
      if (hitTest !== null) {
        // this.normal = hitTest.face.normal.applyAxisAngle(new THREE.Vector3(0, 0, 1), this.angle)
        let offset = this.viewer.impl.model.getData().loadOptions.fileExt === 'wfx' ?
          this.viewer.model.getData().globalOffset.y + hitTest.point.y
          : this.viewer.model.getData().globalOffset.z + hitTest.point.z
        let d = Autodesk.Viewing.Private.convertUnits(this.viewer.model.getUnitString(), 'm', null, offset || 0);
        let div = document.createElement('div')
        div.id ='custom-markup-elevation'
        div.style.zIndex = 100
        div.style.position = 'absolute'
        div.style.color = 'white'
        div.style.fontSize = '16px'
        div.style.textShadow = '0px 0px 20px black'
        div.style.top = this.y + "px"
        div.style.left = this.x + "px"
        div.style.background= `#1890ff`
        div.innerHTML = formatNumber(d, 3)
        mark.push({div:div, point: hitTest.point})
        document.getElementById('forgeViewer').appendChild(div);
      } 
    }
  }
  getPriority() {
    return 1; // Use any number higher than 0 (the priority of all default tools)
  }

  handleButtonDown(event, button) {
    return false;
  }

  handleButtonUp(event, button) {

    return false;
  }

  handleMouseMove(event) {
    this.x = event.clientX - this.rect.x;
    this.y = event.clientY - this.rect.y;
    var hitTest = this.viewer.hitTest(this.x, this.y, true);
    if (hitTest !== null) {
      // this.normal = hitTest.face.normal.applyAxisAngle(new THREE.Vector3(0, 0, 1), this.angle)
      this.div.style.top = this.y - 5 + "px"
      this.div.style.left = this.x + 30 + "px"
      this.div.style.display = ''
      let offset = this.viewer.impl.model.getData().loadOptions.fileExt === 'wfx' ?
        this.viewer.model.getData().globalOffset.y + hitTest.point.y
        : this.viewer.model.getData().globalOffset.z + hitTest.point.z
      let d = Autodesk.Viewing.Private.convertUnits(this.viewer.model.getUnitString(), 'm', null, offset || 0);
      this.div.innerHTML = formatNumber(d, 3)
      this.element.css('cursor', `url(${Forge_Icon_Elevation}) 16 16, auto`)
      return false
    } else {
      this.div.style.display = 'none'
      this.element.css('cursor', this.cursor)
      return false
    }
  }

  handleSingleClick(event, button) {
    return false;
  }



}

class ElevationToolExtension extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.tool = new ElevationTool();
  }

  load() {
    this.viewer.toolController.registerTool(this.tool);
    return true;
  }

  unload() {
    this.viewer.toolController.deregisterTool(this.tool);
    return true;
  }

  onToolbarCreated(toolbar) {
    const controller = this.viewer.toolController;
    this.button1 = new Autodesk.Viewing.UI.Button('btn-elevation');
    this.button1.onClick = (ev) => {
      if (this.button1.container.classList.contains('active')) {
        controller.deactivateTool(ToolName);
        // this.button1.container.classList.add('inactive');
        // this.button1.container.classList.remove('active');
        this.button1.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
      } else {
        // controller.deactivateTool(SphereDrawToolName);
        controller.activateTool(ToolName);
        // this.button1.container.classList.remove('inactive');
        // this.button1.container.classList.add('active');
        this.button1.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
      }
    };
    this.button1.setToolTip('Elevation Tool');
    this.button1.addClass('fas');
    this.button1.addClass('fa-crosshairs');
    this.button1.addClass('fa-2x');

    this.group = new Autodesk.Viewing.UI.ControlGroup('draw-tool-group');
    this.group.addControl(this.button1);
    // this.group.addControl(this.button2);
    toolbar.addControl(this.group);
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension('ElevationToolExtension', ElevationToolExtension);

export default 'ElevationToolExtension'