import _ from 'lodash'


//#region type file
export function GetElementAndParameterRvt(allDbIds, count) {
  return new Promise(resolve => {
    let listTempCategory = []
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          for (var i in modelAProperty.properties) {
            if (modelAProperty.properties[i].displayName === 'Category') {
              if( modelAProperty.properties[i].displayValue) {
                let nameCategory = modelAProperty.properties[i].displayValue.replace('Revit ','')
                var found = listTempCategory.some(el => el.label === nameCategory)
                if (!found) {
                  let name = modelAProperty.name.split(" [")[0]             
                  listTempCategory.push({
                    label: nameCategory,
                    value: { id: modelAProperty.properties[i].displayValue, property: modelAProperty.properties },
                    checked: false
                  })
                  modelAProperty.properties.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
                  break;
                }
              }             
            }
          }
          count--;
          if (count === 0) {
            listTempCategory.sort(sortLabel);
            resolve(listTempCategory)
          }
        })
      })
    })
  })
}


export function listElementTableRVT(allDbIds, listCategorySelected, listParameterSelected) {
  return new Promise(resolve => {
    let dataGeneralTableGroup = {};
    let storeParameterUnits = {}
    let count = 0
    allDbIds.forEach((item) => {
      count = count + item.dbIds.length
    })
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          for (var i in modelAProperty.properties) {
            if (modelAProperty.properties[i].displayName === "Category") {
              if (listCategorySelected.includes(modelAProperty.properties[i].displayValue)) {
                var categoryName = modelAProperty.properties[i].displayValue;
                var countParameter = modelAProperty.properties.length
                var listParameterNoExist = []

                var name = modelAProperty.name.split(" [")[0]
                if (!dataGeneralTableGroup[modelAdbId + "//" + item.modelName]) {
                  dataGeneralTableGroup[modelAdbId + "//" + item.modelName] = [];
                }
                dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                  category: categoryName, parameter: 'Name', value: name, units: ''
                });
                listParameterNoExist.push('Name')
                for (var j in modelAProperty.properties) {
                  if (listParameterSelected.includes(modelAProperty.properties[j].displayName)) {
                    if (modelAProperty.properties[j].units !== null) {

                      dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                        category: categoryName, parameter: modelAProperty.properties[j].displayName
                        , value: modelAProperty.properties[j].displayValue, units: modelAProperty.properties[j].units
                      });
                      listParameterNoExist.push(modelAProperty.properties[j].displayName)
                    }
                  }
                  storeParameterUnits[modelAProperty.properties[j].displayName] = (modelAProperty.properties[j].units)
                  countParameter--
                  if (countParameter === 0) {
                    _.forEach(listParameterSelected, (v, k) => {
                      if (!listParameterNoExist.includes(v)) {
                        if (!dataGeneralTableGroup[modelAdbId + "//" + item.modelName]) {
                          dataGeneralTableGroup[modelAdbId + "//" + item.modelName] = [];
                        }
                        if (storeParameterUnits[v] === '')
                          dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                            category: categoryName, parameter: v
                            , value: 'N/A', units: storeParameterUnits[v]
                          });
                        else
                          dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                            category: categoryName, parameter: v
                            , value: 0, units: storeParameterUnits[v]
                          });
                      }
                    })
                  }
                }
                break;
              }
            }
          }

          count--;
          if (count === 0) {
            resolve({ dataGeneralTableGroup: dataGeneralTableGroup, storeParameterUnits: storeParameterUnits })
          }
        });
      })
    })
  })

}
export function GetElementAndParameterDWFX(allDbIds, count) {
  return new Promise(resolve => {
    let listTempCategory = []
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          var split = modelAProperty.name.split(" [")
          if(split.length ===2){
            var name = split[0]
            var found = listTempCategory.some(el => el.label === name)
            if (!found) {
              listTempCategory.push({
                label: name,
                value: { id: name, property: modelAProperty.properties }
              })
            }
          }       
          count--;
          if (count === 0) {
            listTempCategory.sort(sortLabel);
            resolve(listTempCategory)
          }
        })
      })


    })
  })

}
export function listElementTableDWFX(allDbIds, listCategorySelected, listParameterSelected) {
  return new Promise(resolve => {
    let dataGeneralTableGroup = {};
    let storeParameterUnits = {}
    let count = 0
    let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3']
    allDbIds.forEach((item) => {
      count = count + item.dbIds.length
    })
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          let name = modelAProperty.name.split(" [")[0]
          if (listCategorySelected.includes(name)) {
            var categoryName = name;
            var countParameter = modelAProperty.properties.length
            var listParameterNoExist = []
            if (!dataGeneralTableGroup[modelAdbId + "//" + item.modelName]) {
              dataGeneralTableGroup[modelAdbId + "//" + item.modelName] = [];
            }
            for (var j in modelAProperty.properties) {
              if (listParameterSelected.includes(modelAProperty.properties[j].displayName)) {
                if (modelAProperty.properties[j].units !== null) {

                  dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                    category: categoryName, parameter: modelAProperty.properties[j].displayName
                    , value: modelAProperty.properties[j].displayValue, units: modelAProperty.properties[j].units
                  });
                  listParameterNoExist.push(modelAProperty.properties[j].displayName)
                  storeParameterUnits[modelAProperty.properties[j].displayName] = (modelAProperty.properties[j].units)
                }
                else {
                  let split = modelAProperty.properties[j].displayValue.split(" ")
                  if (split.length === 2) {
                    if (tempUnit.includes(split[1])) {
                      dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                        category: categoryName, parameter: modelAProperty.properties[j].displayName
                        , value: parseFloat(split[0]), units: split[1]
                      });
                      storeParameterUnits[modelAProperty.properties[j].displayName] = split[1]
                    } else {
                     // if(modelAProperty.properties[j].type === 2 || modelAProperty.properties[j].type === 3)
                      dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                        category: categoryName, parameter: modelAProperty.properties[j].displayName
                        , value: modelAProperty.properties[j].displayValue, units: ''
                      });
                      storeParameterUnits[modelAProperty.properties[j].displayName] = ''
                    }
                  } else {
                    dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
                      category: categoryName, parameter: modelAProperty.properties[j].displayName
                      , value: modelAProperty.properties[j].displayValue, units: ''
                    });
                    storeParameterUnits[modelAProperty.properties[j].displayName] = ''
                  }
                }
              }
              // storeParameterUnits[modelAProperty.properties[j].displayName] = (modelAProperty.properties[j].units)
              countParameter--
              // if (countParameter === 0) {
              //    _.forEach(listParameterSelected, (v, k) => {
              //       if (!listParameterNoExist.includes(v)) {
              //          if (!dataGeneralTableGroup[modelAdbId + "//" + item.modelName]) {
              //             dataGeneralTableGroup[modelAdbId + "//" + item.modelName] = [];
              //          }                           
              //          if (storeParameterUnits[v] === '')
              //             dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
              //                category: categoryName, parameter: v
              //                , value: 'N/A', units: storeParameterUnits[v]
              //             });
              //          else
              //             dataGeneralTableGroup[modelAdbId + "//" + item.modelName].push({
              //                category: categoryName, parameter: v
              //                , value: 0, units: storeParameterUnits[v]
              //             });
              //       }
              //    })
              // }
            }
          }
          count--;
          if (count === 0) {
            resolve({ dataGeneralTableGroup: dataGeneralTableGroup, storeParameterUnits: storeParameterUnits })
          }
        });
      })
    })
  })

}
export function sortLabel(a, b) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}
//#endregion


export function getListParameter(value, split, tempUnit) {
  return new Promise(resolve => {
    var temp = []
    _.forEach(value, parameter => {
      parameter.property.forEach(item => {
        if (item.displayName !== 'Category' && item.displayName !== 'CategoryId' && item.displayName !== 'viewable_in' &&
          item.displayName !== 'parent' && item.displayCategory !== '__internalref__' && item.displayName !== 'label') {
          var found = temp.some(el => el.label === item.displayName)
          if (!found) {
            if (split[split.length - 1].toLowerCase() === 'rvt'||split[split.length - 1].toLowerCase() === 'ifc'|| split[split.length - 1].toLowerCase() === 'nwc'|| split[split.length - 1].toLowerCase() === 'nwd') {
              temp.push({ id: item.displayName, label: item.displayName, value: { id: item.displayName, displayName: item.displayName, units: item.units }, group: item.displayCategory })
            } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
              if (item.units !== null) {
                temp.push({ id: item.displayName, label: item.displayName, value: { id: item.displayName, displayName: item.displayName, units: item.units }, group: item.displayCategory })
              } else {
                let splitValue = item.displayValue.split(" ")
                if (splitValue.length === 2) {
                  if (tempUnit.includes(splitValue[1])) {
                    temp.push({ id: item.displayName, label: item.displayName, value: { id: item.displayName, displayName: item.displayName, units: splitValue[1] }, group: item.displayCategory })
                  } else {
                    temp.push({ id: item.displayName, label: item.displayName, value: { id: item.displayName, displayName: item.displayName, units: '' }, group: item.displayCategory })
                  }
                } else {
                  temp.push({ id: item.displayName, label: item.displayName, value: { id: item.displayName, displayName: item.displayName, units: '' }, group: item.displayCategory })
                }
              }
            }
          }
        }
      })
    })
    resolve(temp)
  })
}

export function getListParameterMultiCategory(value, split, tempUnit) {
  return new Promise(resolve => {
    let temp = []
    _.forEach(value, v => {
      if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
        && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label') {
        if (split[split.length - 1].toLowerCase() === 'rvt') {
          temp.push({
            id: v.displayName, label: v.displayName, value: { id: v.displayName, displayName: v.displayName, units: v.units }, group: v.displayCategory
          })
        } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
          if (v.units !== null) {
            temp.push({
              id: v.displayName, label: v.displayName, value: { id: v.displayName, displayName: v.displayName, units: v.units }, group: v.displayCategory
            })
          } else {
            let splitValue = v.displayValue.split(" ")
            if (splitValue.length === 2) {
              if (tempUnit.includes(splitValue[1])) {
                temp.push({
                  id: v.displayName, label: v.displayName, value: { id: v.displayName, displayName: v.displayName, units: splitValue[1] }, group: v.displayCategory
                })
              } else {
                temp.push({
                  id: v.displayName, label: v.displayName, value: { id: v.displayName, displayName: v.displayName, units: '' }, group: v.displayCategory
                })
              }
            } else {
              temp.push({
                id: v.displayName, label: v.displayName, value: { id: v.displayName, displayName: v.displayName, units: '' }, group: v.displayCategory
              })
            }
          }
        }

      }
    })
    resolve(temp)
  })
}

export function getElementAndParameterForFilter(allDbIds, count) {
  return new Promise(resolve => {
    let listTempCategory = []
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          for (var i in modelAProperty.properties) {
            if (modelAProperty.properties[i].displayName === 'Category') {
              var index = listTempCategory.findIndex(el => el.label === modelAProperty.properties[i].displayValue)
              if (index < 0) {
                var name = modelAProperty.name.split(" [")[0]
                // modelAProperty.properties.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
                let temp = modelAProperty.properties.slice()
                temp.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
                listTempCategory.push({
                  label: modelAProperty.properties[i].displayValue,
                  value: { id: modelAProperty.properties[i].displayValue, property: modelAProperty.properties },
                  checked: false,
                  allObj: [temp]
                })
                // break;
              } else {
                var name = modelAProperty.name.split(" [")[0]
                let temp = modelAProperty.properties.slice()
                temp.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
                listTempCategory[index].allObj.push(modelAProperty.properties)
              }
            }
          }
          count--;
          if (count === 0) {
            // let tempAll = {}
            // let tempDuplicate = []
            let tempProperty = []
            _.forEach(listTempCategory, item => {
              let temp = {}
              _.forEach(item.allObj, (props, key) => {
                _.forEach(props, v => {
                  if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
                    && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label') {
                    if (!temp[v.displayName]) {
                      temp[v.displayName] = []
                    }
                    if (!temp[v.displayName].includes(v.displayValue)) {
                      temp[v.displayName].push(v.displayValue)
                    }
                    let index = _.findIndex(tempProperty, e => { return e.displayName === v.displayName })
                    if (index < 0) {
                      tempProperty.push(v)
                    }
                    // if (key === 0)
                    //   tempDuplicate.push(v.displayName)
                    // if (!tempAll[v.displayName]) {
                    //   tempAll[v.displayName] = []
                    // }
                    // if (!tempAll[v.displayName].includes(v.displayValue)) {
                    //   tempAll[v.displayName].push(v.displayValue)
                    // }
                  }
                })
              })
              item.value.properties = tempProperty
              item.allObj = ''
              item.parameterValue = temp
            })

            // tempDuplicate = _.filter(tempDuplicate, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
            // let tempNeed = {}
            // let tempPropertyNeed = []
            // _.forEach(tempAll, (item, k) => {
            //   if (tempDuplicate.includes(k)) {
            //     tempNeed[k] = item
            //   }
            //   let index = _.findIndex(tempProperty, e => { return e.displayName === k })
            //   if (index >= 0) {
            //     tempPropertyNeed.push(tempProperty[index])
            //   }
            // })
            // let all = {
            //   label: 'All Selected Category',
            //   value: { id: 'All Selected Category', property: tempPropertyNeed },
            //   checked: false,
            //   parameterValue: tempNeed,
            //   allObj: ''
            // }
            listTempCategory.sort(sortLabel);
            console.log(listTempCategory)
            resolve({ category: listTempCategory, all: {} })
          }
        })
      })
    })
  })
}

export function getElementAndParameterForFilterDWFX(allDbIds, count) {
  return new Promise(resolve => {
    let listTempCategory = []
    let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3']
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, async modelAdbId => {
        let categoryName = await checkParent(modelAdbId, item.model)
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          var index = listTempCategory.findIndex(el => el.label === categoryName)
          var name = modelAProperty.name.split(" [")[0]
          let temp = []
          _.forEach(modelAProperty.properties.slice(), v => {
            if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
            && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label'){
              if(v.units !== null || _.isNumber(v.displayValue)){
                v.units = v.units !== null ? v.units : 'm'
              }else{
                let split = v.displayValue.split(" ")
                if (split.length === 2) {
                  if (tempUnit.includes(split[1])) {
                    v.units = split[1]
                  } else {
                    v.units = ''
                  }
                } else {
                  v.units = ''
                }
              }
              temp.push(v)
            }         
          })
          temp.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
          if (index < 0) {
            listTempCategory.push({
              label: categoryName,
              value: { id: categoryName, property: modelAProperty.properties },
              checked: false,
              allObj: [temp]
            })
          } else {
            listTempCategory[index].allObj.push(temp)
          }
          count--;
          if (count === 0) {
            // let tempAll = {}
            // let tempDuplicate = []
            let tempProperty = []
            _.forEach(listTempCategory, item => {
              let temp = {}
              _.forEach(item.allObj, (props, key) => {
                _.forEach(props, v => {
                  if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
                    && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label') {
                    if (!temp[v.displayName]) {
                      temp[v.displayName] = []
                    }
                    if (!temp[v.displayName].includes(v.displayValue)) {
                      temp[v.displayName].push(v.displayValue)
                    }

                    let index = _.findIndex(tempProperty, e => { return e.displayName === v.displayName })
                    if (index < 0) {
                      tempProperty.push(v)
                    }
                    // if (key === 0)
                    //   tempDuplicate.push(v.displayName)
                    // if (!tempAll[v.displayName]) {
                    //   tempAll[v.displayName] = []
                    // }
                    // if (!tempAll[v.displayName].includes(v.displayValue)) {
                    //   tempAll[v.displayName].push(v.displayValue)
                    // }
                  }
                })
              })
              item.value.properties = tempProperty
              item.allObj = ''
              item.parameterValue = temp
            })

            // tempDuplicate = _.filter(tempDuplicate, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
            // let tempNeed = {}
            // let tempPropertyNeed = []
            // _.forEach(tempAll, (item, k) => {
            //   if (tempDuplicate.includes(k)) {
            //     tempNeed[k] = item
            //   }
            //   let index = _.findIndex(tempProperty, e => { return e.displayName === k })
            //   if (index >= 0) {
            //     tempPropertyNeed.push(tempProperty[index])
            //   }
            // })
            // let all = {
            //   label: 'All Selected Category',
            //   value: { id: 'All Selected Category', property: tempPropertyNeed },
            //   checked: false,
            //   parameterValue: tempNeed,
            //   allObj: ''
            // }
            listTempCategory.sort(sortLabel);
            console.log(listTempCategory)
            resolve({ category: listTempCategory, all: {} })
          }
        })
      })
    })
  })
}
const checkParent = (id, model) => {
  return new Promise(resolve => {
    let instanceTree = model.getData().instanceTree;
    let temp = []
    let queue = [];
    queue.push(id);
    while (queue.length > 0) {
      let node = queue.shift();
      let idChild = instanceTree.getNodeParentId(node)
      if (idChild) {
        queue.push(idChild);
        temp.push(idChild)
      }
      else {
        let id = temp[temp.length - 2]
        model.getProperties(id, modelAProperty => {
          _.forEach(modelAProperty.properties, v => {
            if (v.displayName === 'label') {
              var value = v.displayValue.split(" (")[0]
              resolve(value)
              return false
            }
          })
        })
      }
    }

  })

}

export function listElementFilter(viewer, allDbIds, listCategorySelected, listParameterSelected, listCondition, rule) {
  return new Promise(resolve => {
    let data = [];
    let count = 0
    allDbIds.forEach((item) => {
      count = count + item.dbIds.length
    })
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, modelAdbId => {
        item.model.getProperties(modelAdbId, (modelAProperty) => {
          _.forEach(modelAProperty.properties, parameter => {
            if (parameter.displayName === "Category") {
              let categoryName = parameter.displayValue;
              if (!listCategorySelected.includes(categoryName)) return
              let name = modelAProperty.name.split(" [")[0]
              modelAProperty.properties.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
              let checkCondition = false
              if (rule.includes('AND')) {
                checkCondition = checkAndCondition(listCondition, modelAProperty.properties, listParameterSelected)
              } else {
                checkCondition = checkOrCondition(listCondition, modelAProperty.properties, listParameterSelected, categoryName)
              }
              if (checkCondition) {
                data.push({ id: modelAdbId, model: item.model })
              }
              return false
            }
          })
          count--;
          if (count === 0) {
            resolve(data)
          }
        });
      })
    })
  })
}
export function listElementFilterDWFX(viewer, allDbIds, listCategorySelected, listParameterSelected, listCondition, rule) {
  return new Promise(resolve => {
    let data = [];
    let count = 0
    allDbIds.forEach((item) => {
      count = count + item.dbIds.length
    })
    _.forEach(allDbIds, item => {
      _.forEach(item.dbIds, async modelAdbId => {
        let categoryName = await checkParent(modelAdbId, item.model)
        item.model.getProperties(modelAdbId, (modelAProperty) => {         
          if (!listCategorySelected.includes(categoryName)) return
          let name = modelAProperty.name.split(" [")[0]
          modelAProperty.properties.splice(1, 0, { displayName: 'Name', displayValue: name, displayCategory: 'Family Name', units: '' })
          let checkCondition = false
          if (rule.includes('AND')) {
            checkCondition = checkAndCondition(listCondition, modelAProperty.properties, listParameterSelected)
          } else {
            checkCondition = checkOrCondition(listCondition, modelAProperty.properties, listParameterSelected, categoryName)
          }
          if (checkCondition) {
            data.push({ id: modelAdbId, model: item.model })
          }
          count--;
          if (count === 0) {
            resolve(data)
          }
        });
      })
    })
  })

}
function checkAndCondition(listCondition, properties, listParameterSelected) {
  let check = false
  let test = true
  _.forEach(properties, parameter => {
    if (!test) return false
    if (!listParameterSelected.includes(parameter.displayName)) return
    let tempCondition = _.filter(listCondition, e => { return (e.parameter === parameter.displayName) })
    _.forEach(tempCondition, condition => {
      if (condition.formula === 'equals') {
        if (condition.value === parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      } else if (condition.formula === 'does not equals') {
        if (condition.value !== parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'is greater than') {
        if (condition.value < parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'is greater than or equals') {
        if (condition.value <= parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'is less than') {
        if (condition.value > parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'is less than or equals') {
        if (condition.value >= parameter.displayValue) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'contains') {
        if (parameter.displayValue.includes(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'does not contain') {
        if (!parameter.displayValue.includes(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'begins with') {
        if (parameter.displayValue.startsWith(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'does not begin with') {
        if (!parameter.displayValue.startsWith(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'ends with') {
        if (parameter.displayValue.endsWith(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'does not end with') {
        if (!parameter.displayValue.endsWith(condition.value)) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'has a value') {
        if (parameter.displayValue.length !== 0) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
      else if (condition.formula === 'has no value') {
        if (parameter.displayValue.length === 0) {
          check = true
        } else {
          check = false
          test = false
          return false
        }
      }
    })

  })
  return check
}
function checkOrCondition(listCondition, properties, listParameterSelected, category) {
  let check = false
  _.forEach(properties, parameter => {
    if (!listParameterSelected.includes(parameter.displayName)) return
    let tempCondition = _.filter(listCondition, e => { return (e.parameter === parameter.displayName && e.category === 'All Selected Category' ? true : category === e.category) })
    _.forEach(tempCondition, condition => {
      if (condition.formula === 'equals') {
        if (condition.value === parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'does not equals') {
        if (condition.value !== parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'is greater than') {
        if (condition.value < parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'is greater than or equals') {
        if (condition.value <= parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'is less than') {
        if (condition.value > parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'is less than or equals') {
        if (condition.value >= parameter.displayValue) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'contains') {
        if (parameter.displayValue.includes(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'does not contain') {
        if (!parameter.displayValue.includes(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'begins with') {
        if (parameter.displayValue.startsWith(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'does not begin with') {
        if (!parameter.displayValue.startsWith(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'ends with') {
        if (parameter.displayValue.endsWith(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'does not end with') {
        if (!parameter.displayValue.endsWith(condition.value)) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'has a value') {
        if (parameter.displayValue.length !== 0) {
          check = true
          return false
        }
      }
      else if (condition.formula === 'has no value') {
        if (parameter.displayValue.length === 0) {
          check = true
          return false
        }
      }
    })
    if (check)
      return false
  })

  return check
}
