import _ from 'lodash'
import moment from 'moment';
import * as Timeline from "vis-timeline/standalone";
import { message } from 'antd'
import {
  getAllElementdbIdsOneModel
} from './ForgeFunction';
import axios from 'axios'
const timeline = Timeline
const extrude = [
  'DRAIN DIVERSION', 'MINOR SEWER DIVERSION', 'SHEET PILE', 'RC PILE'
]

export function getDataForTimeline(viewer) {
  return new Promise((resolve, reject) => {
    let listTempCategory = []
    let listTempCategoryMS = []
    let listTempCompare = []
    let tempAllVolume = 0
    let dbIds = getAllElementdbIdsOneModel(viewer)
    let count = dbIds.length;
    if (count === 0)
      resolve({
        listMark: [],
        listMarkMS: [],
        listMarkCompare: [],
        allVolume: tempAllVolume
      })
    else {
      _.forEach(dbIds, (modelAdbId) => {
        viewer.model.getProperties(modelAdbId, (modelAProperty) => {
          let tempValueMark = ''
          let tempValueVolume = 0
          let tempWHVolume = 0
          let tempValueMarkMS = ''
          let check = false
          // let check1 = false; let check2 = false; let check3 = false
          for (let i in modelAProperty.properties) {
            if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID' && tempValueMark === '') {
              tempValueMark = modelAProperty.properties[i].displayValue
            } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID' && tempValueMarkMS === '') {
              tempValueMarkMS = modelAProperty.properties[i].displayValue
            } else if (modelAProperty.properties[i].displayName === 'Volume' && modelAProperty.properties[i].displayValue) {
              if (_.isNumber(modelAProperty.properties[i].displayValue)) {
                tempValueVolume = modelAProperty.properties[i].displayValue
                tempAllVolume = tempAllVolume + parseFloat(tempValueVolume)
              }
              // else {
              //   let split = modelAProperty.properties[i].displayValue.split(' ')
              //   let number = parseFloat(split[0])   
              //   if( _.isNumber(number)){               
              //     tempValueVolume = number
              //     tempAllVolume = tempAllVolume + parseFloat(number)
              //   }
              // }
            } else if (modelAProperty.properties[i].displayName === 'WH_GROUP') {
              check = extrude.includes(modelAProperty.properties[i].displayValue)
            } else if (modelAProperty.properties[i].displayName === 'WH_Volume' && modelAProperty.properties[i].displayValue) {
              if (_.isNumber(modelAProperty.properties[i].displayValue)) {
                tempWHVolume = modelAProperty.properties[i].displayValue
              }
            }
            if (i === (modelAProperty.properties.length - 1).toString()) {
              if (tempWHVolume !== 0 && tempValueVolume === 0) {
                tempValueVolume = tempWHVolume
                tempAllVolume = tempAllVolume + parseFloat(tempValueVolume)
              }
              if (tempValueMark !== '') {//&& tempValueVolume !== 0
                if (!listTempCategory[tempValueMark])
                  listTempCategory[tempValueMark] = []
                listTempCategory[tempValueMark].push({ id: modelAdbId, volume: check ? 0 : parseFloat(tempValueVolume.toFixed(2)) })
              }
              if (tempValueMarkMS !== '') { //tempValueVolume !== 0 && 
                if (!listTempCategoryMS[tempValueMarkMS])
                  listTempCategoryMS[tempValueMarkMS] = []
                listTempCategoryMS[tempValueMarkMS].push({ id: modelAdbId, volume: check ? 0 : parseFloat(tempValueVolume.toFixed(2)) })
              }
              if (tempValueMark !== '' && tempValueMarkMS !== '') { // && tempValueVolume !== 0
                if (!listTempCompare[modelAdbId])
                  listTempCompare[modelAdbId] = []
                listTempCompare[modelAdbId].push({ id: modelAdbId, mark1: tempValueMark, mark2: tempValueMarkMS, volume: check ? 0 : parseFloat(tempValueVolume.toFixed(2)) })
              }
              // if (check1 && check2 && check3)
              //   break
            }
          }
          count--;
          if (count === 0) {
            resolve({
              listMark: listTempCategory,
              listMarkMS: listTempCategoryMS,
              listMarkCompare: listTempCompare,
              allVolume: tempAllVolume,
            })
          }
        })

      })
    }
  })
}
export function getDataFromSheetTimeLine(data, index1, index2) {
  return new Promise((resolve, reject) => {
    let tempGroup = [];
    let items = new timeline.DataSet();
    let groups = new timeline.DataSet();
    let tempDataStatus = {}
    let minTimeline = 0
    let maxTimeline = 0
    let tempGroupRemove = []; let temp1 = []; let temp2 = []; let temp3 = []; let tempn = []
    _.forEach(data, sheet => {
      var tempDataTimeline = sheet.rows
      var tempSheet = { id: sheet.id, content: sheet.name, treeLevel: 1, nestedGroups: [], style: "background-color: #EA9999", visible: true, showNested: true }
      for (var i = 0; i < tempDataTimeline.length; i++) {
        var temp = {
          id: tempDataTimeline[i].id, content: tempDataTimeline[i].cells[0].value, cells: tempDataTimeline[i].cells,
          nestedGroups: [], parentId: tempDataTimeline[i].parentId
        }
        for (var j = 0; j < tempDataTimeline.length; j++) {
          if (i !== j) {
            if (tempDataTimeline[i].id === tempDataTimeline[j].parentId) {
              let start = tempDataTimeline[j].cells[index1].value
              let end = tempDataTimeline[j].cells[index2].value
              let content = tempDataTimeline[j].cells[0].value
              if (content !== undefined && content != '')
                temp.nestedGroups.push(tempDataTimeline[j].id)
            }
          }
        }
        if (temp.content !== undefined && temp.content != '') {
          if (temp.nestedGroups.length !== 0) {
            if (temp.parentId === null || temp.parentId === undefined) {
              temp = { id: temp.id, content: temp.content, treeLevel: 2, nestedGroups: temp.nestedGroups, style: "background-color: #F9CB9C", visible: true, showNested: true }
              if (!temp1.includes(temp.id))
                temp1.push(temp.id)
              groups.add(temp)
              tempSheet.nestedGroups.push(temp.id) //*
            } else if (!temp2.includes(temp.parentId)) {
              temp = { id: temp.id, content: temp.content, treeLevel: 3, nestedGroups: temp.nestedGroups, style: "background-color: #B6D7A8", visible: true, showNested: true }
              if (!temp2.includes(temp.id))
                temp2.push(temp.id)
              groups.add(temp)
            } else {
              temp = { id: temp.id, content: temp.content, treeLevel: 4, nestedGroups: temp.nestedGroups, style: "background-color: #9FC5E8", visible: true, showNested: true }
              if (!temp3.includes(temp.id))
                temp3.push(temp.id)
              groups.add(temp)
            }
          } else {
            temp = { id: temp.id, content: temp.content, treeLevel: 5, visible: true, showNested: true, style: "background-color: #F3F3F3" }
            if (!tempn.includes(temp.id))
              tempn.push(temp.id)
            groups.add(temp)
          }
        }
      }
      groups.add(tempSheet)
      _.forEach(tempDataTimeline, item => {
        tempGroup[item.id] = []
        tempGroup[item.id].push(item)
        if (item.cells[index1].value !== '' && item.cells[index1].value !== undefined && item.cells[index2].value !== '' && item.cells[index2].value !== undefined
          && item.cells[0].value !== undefined && item.cells[0].value != '') {
          var start = moment(item.cells[index1].value)
          var end = moment(item.cells[index2].value)
          var taskType = item.cells[5].value === undefined ? 'Concrete' : item.cells[5].value
          var style = "color: #B4A7D6;background-color: #F3F3F3; height: 10px"
          if (temp1.includes(item.id)) {
            style = "color: #F9CB9C; background-color: #F9CB9C; height: 10px"
          } else if (temp2.includes(item.id)) {
            style = "color: #B6D7A8; background-color: #B6D7A8; height: 10px"
          } else if (temp3.includes(item.id)) {
            style = "color: #9FC5E8; background-color: #9FC5E8; height: 10px"
          } else if (tempn.includes(item.id)) {
            if (!tempDataStatus[item.cells[0].value])
              tempDataStatus[item.cells[0].value] = []
            tempDataStatus[item.cells[0].value].push({ start: start, end: end, taskType: taskType, cells: item.cells })
          }
          items.add({
            id: item.id + "//" + item.cells[0].value + "//" + start.format('DD-MM-YYYY') + "//" + end.format('DD-MM-YYYY'),
            content: `${start.format('DD-MM-YYYY')} -> ${end.format('DD-MM-YYYY')}`,
            start: moment(item.cells[1].value, 'YYYY-MM-DD').startOf('day'), end: moment(item.cells[2].value, 'YYYY-MM-DD').endOf('day'), group: item.id,
            style: style, subgroup: item.parentId,
            title: `<span id='tooltipTimeline' style="font-size: smaller;" > ${start.format('DD-MM-YYYY')} -> ${end.format('DD-MM-YYYY')}</span>`
          })
          if (minTimeline > start.startOf('day').valueOf() || minTimeline === 0)
            minTimeline = start.startOf('day').valueOf()
          if (maxTimeline < end.endOf('day').valueOf())
            maxTimeline = end.endOf('day').valueOf()
          if (!tempGroupRemove.includes(item.parentId))
            tempGroupRemove.push(item.parentId)
        }
      })
    })
    resolve({
      items: items,
      groups: groups,
      tempDataStatus: tempDataStatus,
      minTimeline: minTimeline,
      maxTimeline: maxTimeline
    })
  })
}
export function groupElementCompare(list) {

  var tempGroup = []
  for (var key in list) {
    var id = []
    var volume = 0
    for (var key1 in list[key]) {
      id.push(list[key][key1].id)
      volume = volume + list[key][key1].volume
    }
    tempGroup.push({
      name: key, id: id, start: list[key][0].start
      , end: list[key][0].end, volume: volume, status: list[key][0].status
    })
  }

  return tempGroup
}
export function timelineFocus(e, timelinepanel, _this) {
  try {
    let check = true
    for (var key in e.event.target.parentElement.classList) {
      if (e.event.target.parentElement.classList[key] === 'testTimeline') {
        _this.setState(prevState => ({
          statusBar: {
            ...prevState.statusBar,
            openPanel: true
          }
        }))
        check = false
        break
      }
    }
    if (check) {
      let vGroups = timelinepanel.getVisibleGroups()
      let vItems = vGroups.reduce((res, groupId) => {
        let group = timelinepanel.itemSet.groups[groupId]
        if (group.items) {
          res = res.concat(Object.keys(group.items))
        }
        return res
      }, [])
      timelinepanel.focus(vItems)
    }
  } catch { }
}
export function timelineSelect(viewer, properties, _this) {
  try {
    var temp = properties.items[0].split("//")
    if (_this.state.displaySetting.displaySchedule !== "Master Schedule") {
      if (_this.state.listMark[temp[1]]) {
        viewer.clearSelection();
        let array = []
        _this.state.listMark[temp[1]].forEach(item => {
          array.push(item.id);
        })
        viewer.select(array);
      }
    } else {
      if (_this.state.listMarkMS[temp[1]]) {
        viewer.clearSelection();
        let array = []
        _this.state.listMarkMS[temp[1]].forEach(item => {
          array.push(item.id);
        })
        viewer.select(array);
      }
    }

  } catch { }
}
export function timelineChange(e, listSheetResponse, _this) {
  if (_this.state.playSetting.playTwoView === false)
    _this.overrideColorTimeline(e.time, _this.props.viewer)
  else {
    var dataL = _this.generateDataPlay(listSheetResponse[_this.state.playSetting.displayScheduleL]
      , _this.state.playSetting.indexStartL, _this.state.playSetting.indexEndL)
    var dataR = _this.generateDataPlay(listSheetResponse[_this.state.playSetting.displayScheduleR]
      , _this.state.playSetting.indexStartR, _this.state.playSetting.indexEndR)
    _this.overrideColorLeft(e.time, _this.props.viewer, dataL, _this.state.playSetting.displayScheduleL)
    _this.overrideColorRight(e.time, _this.props.viewer2D, dataL, dataR, _this.state.playSetting.displayScheduleR)
  }
}
export function showAllGroups(groups) {
  groups.forEach(function (group) {
    if (group.treeLevel === 4) {
      groups.update({
        id: group.id,
        showNested: true
      })
    }
    else if (group.treeLevel === 5) {
      groups.update({
        id: group.id,
        visible: true
      })
    } else if (group.treeLevel === 2) {
      groups.update({
        id: group.id,
        visible: true,
        showNested: true
      })
    } else if (group.treeLevel === 1) {
      groups.update({
        id: group.id,
        visible: true,
        showNested: true
      })
    } else if (group.treeLevel === 3) {
      groups.update({
        id: group.id,
        showNested: true
      })
    }
  })
}
export function hideAllGroups(groups) {
  groups.forEach(function (group) {
    if (group.treeLevel === 4) {
      groups.update({
        id: group.id,
        showNested: false
      })
    }
    else if (group.treeLevel === 5) {
      groups.update({
        id: group.id,
        visible: false
      })
    }
  })
}
export function matchStatusStorage(data, _this) {
  axios.get(`/api/versions/get-status-timeline?q=${_this.props.itemId}`)
    .then(res => {
      data.splice(0, 0, 'No Status')
      if (res.data.length !== 0) {
        let temp = []
        let count = res.data.length
        _.forEach(data, v => {
          let index = _.findIndex(res.data, function (o) { return o.id === v })
          if (index >= 0) {
            temp.push({ id: res.data[index].id, content: res.data[index].content, color: res.data[index].color, sort: res.data[index].sort, check: true })
          } else {
            temp.push({ id: v, content: v, color: "#999999", sort: count + 1, check: true })
            count++
          }
        })
        temp.sort(sortStatus)
        _this.setState({ statusDS: temp, tempStatusDS: temp, loadingStatus: false })
      } else {
        let temp = []
        _.forEach(data, (v, k) => {
          temp.push({ id: v, content: v, color: "#999999", sort: k, check: true })
        })
        _this.setState({ statusDS: temp, tempStatusDS: temp, loadingStatus: false })
      }
    })
}
export function loadStatusTimeline(folderSmartSheetId, sheetSaved, _this) {
  _this.setState({ loadingStatus: true })
  return new Promise(resolve => {
    axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: folderSmartSheetId })
      .then(res => {
        let temp = []
        res.data.forEach(item => {
          if (!item.name.includes('Master Schedule')) {
            _.forEach(sheetSaved, v => {
              if (v.id === item.id) {
                temp.push({ id: item.id, name: item.name })
              }
            })
          }
        })
        axios.post(`/api/smartsheet/get-columns-status`, { listSheet: temp })
          .then(res => {
            let data = _.uniq(res.data)
            data.splice(0, 0, 'No Status')
            axios.get(`/api/versions/get-status-timeline?q=${_this.props.itemId}`)
              .then(res => {
                if (res.data.length !== 0) {
                  let temp = []
                  let count = res.data.length
                  _.forEach(data, v => {
                    let index = _.findIndex(res.data, function (o) { return o.id === v })
                    if (index >= 0) {
                      temp.push({ id: res.data[index].id, content: res.data[index].content, color: res.data[index].color, sort: res.data[index].sort, check: true })
                    } else {
                      temp.push({ id: v, content: v, color: "#999999", sort: count + 1, check: true })
                      count++
                    }
                  })
                  temp.sort(sortStatus)
                  _this.setState({ statusDS: temp, tempStatusDS: temp, loadingStatus: false })
                } else {
                  let temp = []
                  _.forEach(data, (v, k) => {
                    temp.push({ id: v, content: v, color: "#999999", sort: k, check: true })
                  })
                  _this.setState({ statusDS: temp, tempStatusDS: temp, loadingStatus: false })
                }
                resolve()
              })
          })
          .catch(err => { message.error(`Can't get status`) })
      })
  })

}

export function getColumnsStatus(list, _this) {
  _this.setState({ loadingStatus: true })
  axios.post(`/api/smartsheet/get-columns-status`, { listSheet: list })
    .then(res => {
      let uniq = _.uniq(res.data)
      matchStatusStorage(uniq, _this)
    })
    .catch(err => { message.error(`Can't get status`) })
}
function sortStatus(a, b) {

  if (a.sort < b.sort) {
    return -1;
  }
  if (a.sort > b.sort) {
    return 1;
  }
  return 0;
}