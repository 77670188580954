import React from 'react'
import axios from "axios";
import $ from 'jquery'
import _ from 'lodash'
import {
  getAllElementdbIdsOneModel
} from '../../function/ForgeFunction'
import Draggable from 'react-draggable';
import { Empty, Row, Col, Typography, message } from 'antd';
import { Loader } from 'rsuite';
import { formatNumber } from '../../function/TableFunction';
import moment from 'moment';
const { Paragraph } = Typography;
const Autodesk = window.Autodesk;

export default class PPVCInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      description: 'Please pick a object'
    };
    this.modelData = {
      dbIds: [],
      data: []
    }
    this.moduleData = null
    this.container = this.props.viewer.canvas.parentElement;
    this.rect = this.container.getBoundingClientRect();
    this.div = null
  }

  componentWillMount = () => {
    this.getModules()
    this.element = $('.canvas-wrap').children().last();
    this.cursor = this.element.css('cursor')
    this.div = document.createElement('div')
    this.div.style.zIndex = 100
    this.div.style.position = 'absolute'
    this.div.style.display = 'none'
    this.div.style.color = 'red'
    this.div.style.fontSize = '12px'
    this.div.innerHTML = ""
    document.getElementById('forgeViewer').appendChild(this.div);
  }
  componentWillUnmount = () => {
    this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
    this.props.viewer.canvas.removeEventListener('mousemove', this.onHoverPPVC);
    document.getElementById('forgeViewer').removeChild(this.div);
  }
  // componentWillReceiveProps = (nextProps) => {
  //   if (nextProps.openDock && onceTime) {
  //     onceTime = false
  //     this.setState({ loading: true, data: null }, () => {
  //       this.getModelData();
  //     })
  //   } else if (!nextProps.openDock && !onceTime) {
  //     try {
  //       onceTime = true

  //     } catch{ }
  //   }
  // }
  getModules = () => {
    this.setState({ loading: true })
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          this.moduleData = res.data.result;
          this.getModelData();
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }
  getModelData = () => {
    this.modelData.dbIds = getAllElementdbIdsOneModel(this.props.viewer);
    if (this.modelData.dbIds && this.modelData.dbIds.length > 0) {
      let count = this.modelData.dbIds.length
      _.forEach(this.modelData.dbIds, id => {
        this.props.viewer.model.getProperties(id, (modelAProperty) => {
          _.forEach(modelAProperty.properties, v => {
            if (this.props.projectKey === 'pdd') {
              if (v.displayName === 'PCAPP-Module Code') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
              }
            } else {
              if (v.displayName === 'Mark') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
                return false
              }
            }

          })
          count--
          if (count === 0) {
            this.combineData()
          }
        })

      })

    }
  }
  combineData = () => {
    for (const item of this.moduleData) {
      let modelItem = this.modelData.data.find(x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        item.dbId = modelItem.dbId;
      } else {
        item.dbId = ''
      }
    }
    try {
      this.setState({ loading: false })
      let currSelection = this.props.viewer.getSelection()
      this.showDataPPVC(currSelection)
      this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
      this.props.viewer.canvas.addEventListener('mousemove', this.onHoverPPVC);
    } catch { }
  }
  onHoverPPVC = (event) => {
    this.x = event.clientX - this.rect.x;
    this.y = event.clientY - this.rect.y;
    var hitTest = this.props.viewer.hitTest(this.x, this.y, true);
    if (hitTest !== null) {
      try {
        let temp = _.find(this.moduleData, (o) => o.dbId === hitTest.dbId)
        if (temp) {
          this.div.style.top = this.y - 5 + "px"
          this.div.style.left = this.x + 30 + "px"
          this.div.style.display = 'block'
          this.div.style.backgroundColor = 'white'
          this.div.innerHTML = `<span>ID: ${temp.code}</span><br/> <span>Unit: ${temp.unit}</span><br/> <span>Unit Type: ${temp.unitType}</span>`
        } else {
          this.div.style.display = 'none'
        }
      }
      catch { this.div.style.display = 'none' }
    } else {
      this.div.style.display = 'none'
    }
  }
  onSelectPPVC = (e) => {
    try {
      var currSelection = e.target.getSelection();
      if (currSelection.length === 0) {
        this.props.viewer.clearSelection();
        this.setState({ data: null, description: `Please pick a object` })
        return
      }
      else {
        this.showDataPPVC(currSelection)
        return
      }
    }
    catch { }
  }

  showDataPPVC = (currSelection) => {
    let item = _.find(this.moduleData, (o) => o.dbId === currSelection[0])
    if (item) {
      let tempData = item
      // _.forEach(temp.actions, action => {
      //   if (action.completedDate) {
      //     let completedDate = new Date(action.completedDate);
      //     if (action.actionName === 'Casting Completed') {
      //       tempData.castingCompleted = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
      //     }
      //     else if (action.actionName.includes('Received Delivery at') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) {
      //       tempData.deliveredToFitoutYard = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
      //     }
      //     else if (action.actionName.includes('Ready for Delivery from') && action.actionName.includes('(AFO)')) {
      //       tempData.completedFitout = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
      //     }
      //     else if (action.actionName.includes('Received Delivery at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
      //       tempData.deliveredToSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
      //     }
      //     else if (action.actionName.includes('Module Installed on') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {
      //       tempData.installedOnSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
      //     }
      //   }
      // })
      let check= true
      let checkDeliveryFitOut = true
      let currentSiteCode = ''
      let temp = {
        dbId: item.dbId,
        mark: item.code,
        modifiedDate: null,
        status: [],
        listTime: [],
        infoData: {
          name: item.code,
          length: item.length ? item.length : "N/A",
          height: item.height ? item.height : "N/A",
          width: item.width ? item.width : "N/A",
          volume: item.volume ? item.volume : "N/A",
          carcassWeight: item.carcassWeight ? item.carcassWeight : "N/A",
          ppvcWeight: item.ppvcWeight ? item.ppvcWeight : "N/A",
          status: ''
        }
      }
      _.forEach(tempData.actions, action => {
        if (action.completedDate) {
          let completedDate = new Date(action.completedDate)
          if (this.props.typeProject === 'PPVC/PBU') {
            if (action.actionName.includes('Start Cast at')) {
              currentSiteCode = action.currentSiteCode
            }
            if (action.actionName === 'Casting Completed') {
              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Casting Completed'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Casting Completed')
              tempData.castingCompleted = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
            else if (action.actionName.includes('Received Delivery at') && checkDeliveryFitOut && currentSiteCode !== action.currentSiteCode) {//&&projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Fit-out Yard' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Delivered to Fit-out Yard'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Delivered to Fit-out Yard')
              tempData.deliveredToFitoutYard = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
              checkDeliveryFitOut = false
            }
            else if (action.actionName.includes('Ready for Delivery from') && currentSiteCode !== action.currentSiteCode) {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Fit Out Completed' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Fit Out Completed'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Fit Out Completed')
              tempData.completedFitout = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
            else if (action.actionName.includes('Received Delivery at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase()) {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Delivered to Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Delivered to Site')
              tempData.deliveredToSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
            else if (action.actionName.includes('Module Installed on')) {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Installed on Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Installed on Site')
              tempData.installedOnSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
          } else if (this.props.typeProject === 'PreCast') {
            if ((action.actionName.includes('Delivering to') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Move into') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Ready for Delivery') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()) ||
              (action.actionName.includes('Received Delivery') && this.props.projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase())
              || action.actionName === 'Casting Completed') {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Casting Completed' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Casting Completed' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Casting Completed'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Casting Completed')
              tempData.castingCompleted = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
            else if ((action.actionName.includes('Received Delivery at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())
              || (action.actionName.includes('Move into at') && this.props.projectKey.toLowerCase() === action.currentSiteCode.toLowerCase())) {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Delivered to Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Delivered to Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Delivered to Site')
              tempData.deliveredToSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
            else if (action.actionName.includes('Module Installed on')) {

              let index = _.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' })
              if (index >= 0) {
                temp.listTime[index].time = completedDate.getTime()
              } else {
                temp.listTime.push({ time: completedDate.getTime(), status: 'Installed on Site' })
              }
              temp.modifiedDate = completedDate
              temp.infoData.status = 'Installed on Site'
              temp.infoData.date = completedDate ? moment(completedDate).format('DD/MM/YYYY') : "N/A";
              temp.status.push('Installed on Site')
              tempData.installedOnSite = moment(completedDate).format('DD/MM/YYYY HH:mm:ss')
              check = false
            }
          }

        }
      })
      if (temp.modifiedDate !== null) {
        if (_.findIndex(temp.listTime, o => { return o.status === 'Installed on Site' }) >= 0) {
          let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' })
          if (index < 0) {
            temp.status.splice(temp.listTime[temp.listTime.length - 2], 0, 'Delivered to Site')
            temp.listTime.splice(temp.listTime[temp.listTime.length - 2], 0, { time: temp.listTime[temp.listTime.length - 1].time, status: 'Delivered to Site' })
            tempData.installedOnSite = moment(temp.listTime[temp.listTime.length - 1].time).format('DD/MM/YYYY HH:mm:ss')
          }
        }
        if (this.props.typeProject === 'PPVC/PBU') {
          if (_.findIndex(temp.listTime, o => { return o.status === 'Delivered to Site' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' })
            if (index < 0) {
              temp.status.splice(temp.listTime[temp.listTime.length - 3], 0, 'Fit Out Completed')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 3], 0, { time: temp.listTime[temp.listTime.length - 2].time, status: 'Fit Out Completed' })
              tempData.completedFitout = moment(temp.listTime[temp.listTime.length - 2].time).format('DD/MM/YYYY HH:mm:ss')
            }
          }

          if (_.findIndex(temp.listTime, o => { return o.status === 'Fit Out Completed' }) >= 0) {
            let index = _.findIndex(temp.listTime, o => { return o.status === 'Delivered to Fit-out Yard' })
            if (index < 0) {  
              temp.status.splice(temp.listTime[temp.listTime.length - 4], 0, 'Delivered to Fit-out Yard')
              temp.listTime.splice(temp.listTime[temp.listTime.length - 4], 0, { time: temp.listTime[temp.listTime.length - 3].time, status: 'Delivered to Fit-out Yard' })
              tempData.deliveredToFitoutYard = moment(temp.listTime[temp.listTime.length - 3].time).format('DD/MM/YYYY HH:mm:ss')
            }
          }
        }

      }
      this.setState({ data: tempData })
      // console.log(tempData)
    } else {
      this.setState({ data: null, description: `Information for this object is not currently available` })
    }
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay('ppvcInformationDock', false)
    try {
      this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectPPVC);
    } catch { }
  }
  render() {
    let { data } = this.state
    return (
      <>
        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 40, y: 30 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 550, width: 350, zIndex: 10001 }}
            id='custom-ppvc-information' >
            <div className='custom-dock-panel-title'>PPVC Information</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
            <div className='custom-dock-panel-body'>
              <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                {this.state.loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
                  this.state.data === null ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                    <span style={{ color: 'black' }}>
                      {this.state.description}
                    </span>
                  } /> :
                    <div>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'PPVC Number'} ellipsis >{'PPVC Number'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>  <Paragraph title={data.code} ellipsis >{data.code}</Paragraph> </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Drawing'} ellipsis >{'Drawing'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}> <Paragraph title={data.drawing} ellipsis >
                          <a href={data.drawing} target="_blank">Click to view</a>
                        </Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Volume'} ellipsis >{'Volume'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.volume) ? formatNumber(data.volume) : data.volume} ellipsis  >
                            {_.isNumber(data.volume) ? formatNumber(data.volume) : data.volume}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Carcass Weight'} ellipsis >{'Carcass Weight'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.carcassWeight) ? formatNumber(data.carcassWeight) : data.carcassWeight} ellipsis  >
                            {_.isNumber(data.carcassWeight) ? formatNumber(data.carcassWeight) : data.carcassWeight}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'PPVC Weight'} ellipsis >{'PPVC Weight'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.ppvcWeight) ? formatNumber(data.ppvcWeight) : data.ppvcWeight} ellipsis  >
                            {_.isNumber(data.ppvcWeight) ? formatNumber(data.ppvcWeight) : data.ppvcWeight}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Casting Completed'} ellipsis >{'Casting Completed'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}> <Paragraph title={data.castingCompleted} ellipsis >{data.castingCompleted}</Paragraph> </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Delivered to Fir-out Yard'} ellipsis >{'Delivered to Fir-out Yard'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={data.deliveredToFitoutYard} ellipsis >{data.deliveredToFitoutYard}</Paragraph> </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Completed Fit-out'} ellipsis >{'Completed Fit-out'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={data.completedFitout} ellipsis >{data.completedFitout}</Paragraph> </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Delivered to Site'} ellipsis >{'Delivered to Site'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={data.deliveredToSite} ellipsis >{data.deliveredToSite}</Paragraph> </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Installed on Site'} ellipsis >{'Installed on Site'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={data.installedOnSite} ellipsis >{data.installedOnSite}</Paragraph> </Col>
                      </Row>

                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Block'} ellipsis >{'Block'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.block) ? formatNumber(data.block) : data.block} ellipsis  >
                            {_.isNumber(data.block) ? formatNumber(data.block) : data.block}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Floor'} ellipsis >{'Floor'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.floor) ? formatNumber(data.floor) : data.floor} ellipsis  >
                            {_.isNumber(data.floor) ? formatNumber(data.floor) : data.floor}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Unit'} ellipsis >{'Unit'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.unit) ? formatNumber(data.unit) : data.unit} ellipsis  >
                            {_.isNumber(data.unit) ? formatNumber(data.unit) : data.unit}</Paragraph>
                        </Col>
                      </Row>
                      <Row align="middle">
                        <Col style={{ paddingLeft: 2, top: 5, backgroundColor: '#e6e6e6' }} span={8}><Paragraph title={'Unit Type'} ellipsis >{'Unit Type'}</Paragraph></Col>
                        <Col style={{ paddingLeft: 2, top: 5 }} span={16}>
                          <Paragraph title={_.isNumber(data.unitType) ? formatNumber(data.unitType) : data.unitType} ellipsis  >
                            {_.isNumber(data.unitType) ? formatNumber(data.unitType) : data.unitType}</Paragraph>
                        </Col>
                      </Row>
                    </div>
                }
              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable >
      </>

    )
  }
}