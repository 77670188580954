import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { Table } from 'antd';
import { useSelector } from 'react-redux';

function WorkerTimeSheetTable(props) {
  const projectId = useSelector((state) => state.project.projectId);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    setDatas(props.datas);
  }, [props.datas]);

  function renderCash(cash) {
    return (
      <div>
        <b>$</b>
        <p className='float-right'>{Math.round(cash * 10) / 10}</p>
      </div>
    );
  }

  function renderHours(h) {
    return <p>{Math.round(h * 10) / 10}</p>;
  }

  function getCategoriesFilter() {
    if (_.isArray(datas)) {
      let categories = datas.map((x) => ({
        text: x.designation ? x.designation : '',
        value: x.designation ? x.designation : '',
      }));
      let uniq = _.orderBy(_.uniqBy(categories, 'value'), 'text');

      return uniq;
    }
  }

  function getWorkerNamesFilter() {
    if (_.isArray(datas)) {
      let items = datas.map((x) => ({
        text: x.name ? x.name : '',
        value: x.name ? x.name : '',
      }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  function getSalariesFilter() {
    if (_.isArray(datas)) {
      let items = datas.map((x) => ({
        text: x.rateDaily ? x.rateDaily : 0,
        value: x.rateDaily ? x.rateDaily : 0,
      }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: '',
      filters: getWorkerNamesFilter(),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      width: 120,
    },

    // {
    //   title: "EMP-Code",
    //   dataIndex: "employeeCode",
    //   key: "employeeCode",
    //   className: "",
    //   width: 90,
    // },
    {
      title: 'Category',
      dataIndex: 'designation',
      key: 'designation',
      filters: getCategoriesFilter(),
      onFilter: (value, record) => record.designation.indexOf(value) === 0,
      width: 80,
    },
    // {
    //   title: "Salary",
    //   dataIndex: "rateDaily",
    //   key: "rateDaily",
    //   render: renderCash,
    //   filters: getSalariesFilter(),
    //   onFilter: (value, record) => record.rateDaily === value,
    //   width: 80,
    // },
    // {
    //   title: "S$/HR",
    //   dataIndex: "rateHr",
    //   key: "rateHr",
    //   render: renderCash,
    //   sorter: (a, b) => a.rateHr - b.rateHr,
    //   width: 80,
    // },
    {
      title: 'NT \n(hours)',
      children: [
        {
          title: 'Total Hours',
          dataIndex: 'nt.totalHours',
          key: 'nt.totalHours',
          width: 70,
          sorter: (a, b) => a.totalHours - b.totalHours,
          render: renderHours,
        },
        {
          title: 'Amount',
          dataIndex: 'nt.amount',
          key: 'nt.amount',
          width: 90,
          render: renderCash,
          sorter: (a, b) => a.amount - b.amount,
        },
      ],
    },
    {
      title: 'OT \n(hours w-rate)',
      children: [
        {
          title: 'Total Hours',
          dataIndex: 'ot.totalHoursNumberWithRate',
          key: 'ot.totalHoursNumberWithRate',
          width: 70,
          sorter: (a, b) => a.totalHours - b.totalHours,
          render: renderHours,
        },
        {
          title: 'Amount',
          dataIndex: 'ot.amount',
          key: 'ot.amount',
          width: 90,
          render: renderCash,
          sorter: (a, b) => a.amount - b.amount,
        },
      ],
    },
    {
      title: 'ICP',
      children: [
        {
          title: 'Total points',
          dataIndex: 'icp.points',
          key: 'icp.points',
          width: 70,
          sorter: (a, b) => a.points - b.points,
          render: renderHours,
        },
        {
          title: 'Amount',
          dataIndex: 'icp.amount',
          key: 'icp.amount',
          width: 90,
          render: renderCash,
          sorter: (a, b) => a.amount - b.amount,
        },
      ],
    },
    // {
    //   title: 'BP',
    //   children: [
    //     {
    //       title: 'Total points',
    //       dataIndex: 'bp.totalHours',
    //       key: 'bp.totalHours',
    //       width: 90,
    //       sorter: (a, b) => a.points - b.points,
    //       render: renderHours,
    //     },
    //     {
    //       title: 'Amount',
    //       dataIndex: 'bp.amount',
    //       key: 'bp.amount',
    //       width: 90,
    //       render: renderCash,
    //       sorter: (a, b) => a.amount - b.amount,
    //     },
    //   ],
    // },
    {
      title: 'Total amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 90,
      render: renderCash,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Levy',
      dataIndex: 'levy',
      key: 'levy',
      width: 90,
      render: renderCash,
      sorter: (a, b) => a.levy - b.levy,
    },
    {
      title: 'Total amount w/Levy',
      dataIndex: 'totalAmountWithLevy',
      key: 'totalAmountWithLevy',
      width: 100,
      render: renderCash,
      sorter: (a, b) => a.totalAmountWithLevy - b.totalAmountWithLevy,
    },
  ];

  return (
    <div>
      <Table
        loading={_.isNil(datas)}
        scroll={{ x: 1700, y: 500 }}
        className='antTable'
        dataSource={props.datas}
        columns={columns}
        bordered
        rowKey={(record) => record.idWorker}
        size='small'
        // pagination={false}
      ></Table>
    </div>
  );
}

export default WorkerTimeSheetTable;
