import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { arrayStatusDrawingText, arrayTextStatusQrQrm, arrayTextStatusRfc, colorTextRow, colorType, imgLink } from '../../../constants';
import robotoBold from '../../../constants/Roboto-Bold.ttf';
import robotoRegular from '../../../constants/Roboto-Regular.ttf';
import { checkIfStringContainNumberOnly, getDataToExportReport, getDisciplineOfRefString, getTotalStatusInRfa, sortTotalStatusRfaOrder, getOverdueRfaInfo } from '../../../utils';
import { getTreeFlattenOfNodeInArray } from '../pageSpreadsheet/FormDrawingTypeOrder';
import { getKeyTextForSheet } from '../pageSpreadsheet/PanelSetting';
import { getDrawingStatusByConsultant, getHeadersForm, headersConsultantWithNumber } from './ComponentDmsOverall';



Font.register({ family: 'Roboto-Regular', fonts: [{ src: robotoRegular }] });
Font.register({ family: 'Roboto-Bold', fonts: [{ src: robotoBold }] });

const fontStyles = StyleSheet.create({
   fontRegular: { fontFamily: 'Roboto-Regular' },
   fontBold: { fontFamily: 'Roboto-Bold' },
});





const ExportPdfDataView = ({ stateProject, stateRow, isIncludeDrawingDetail, allDataTableInfo }) => {

   const { refStatisticOverdue: refStatistics, refStatisticRepliedStatus, drawingTypeTreeTable, rowsTable } = allDataTableInfo;

   const { allDataOneSheet: { pageSheetTypeName, projectName, companies, publicSettings: { overdueLeadConsultantOnly } } } = stateProject;

   const { drawingTypeTree, modeFilter, rfaData } = stateRow;

   const refType = getKeyTextForSheet(pageSheetTypeName);


   const headersShown = (pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc')
      ? [
         ...getHeadersForm(pageSheetTypeName).filter(hd => {
            let arrayIgnore = ['IFC Rev', 'IFC Date'];
            if (pageSheetTypeName === 'page-rfa' && !isIncludeDrawingDetail) {
               arrayIgnore = [...arrayIgnore, 'Drawing Number', 'Drawing Name'];
            };
            return arrayIgnore.indexOf(hd) === -1;
         }),

      ] : (pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr'  || pageSheetTypeName === 'page-sm') ? [
         ...getHeadersForm(pageSheetTypeName).filter(hd => hd !== 'IFC Rev' && hd !== 'IFC Date' && hd !== 'Issue For Construction' && hd !== 'Tags'),
      ]
         : getHeadersForm(pageSheetTypeName).filter(hd => hd !== 'IFC Rev' && hd !== 'IFC Date' && hd !== 'Issue For Construction' && hd !== 'Subject');


   if (pageSheetTypeName === 'page-rfa' && !isIncludeDrawingDetail) {
      headersShown.splice(2, 0, 'Description');
   };


   const {
      output, arrayIndexOfDwgTypeTree, statusAllCellObject, arrayIndexOfDwgDarkerTypeTree,
      sumStatusOutput, dueDrawingOutput
   } = getDataToExportReport({
      drawingTypeTree: drawingTypeTreeTable,
      rowsCurrentInView: rowsTable,
      modeFilter, isIncludeDrawingDetail, pageSheetTypeName,
      rfaData, headersShown, companies,
      overdueLeadConsultantOnly
   });


   const rowCountFirstPage = 47;
   const rowCountNextPage = 54;
   const marginBottomHeaderForm = 7;
   const marginTopTablePageOne = 5;


   const statusArray = pageSheetTypeName === 'page-rfi' ? ['replied', 'Consultant reviewing'] : (pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') ? [...arrayTextStatusQrQrm, 'Consultant reviewing']
      : pageSheetTypeName === 'page-rfc' ? [...arrayTextStatusRfc, 'Consultant reviewing']
         : arrayStatusDrawingText;


   const dataInputExport = splitTableToPages(output.length, rowCountFirstPage, rowCountNextPage);


   return (
      <Document>
         <Page size='A3' style={{ fontSize: 9, padding: 10 }} orientation='landscape' wrap>
            <View style={{ border: '1px solid black', padding: 0, width: '100%', position: 'relative' }}>
               <View>
                  <View style={{
                     flexDirection: 'row', justifyContent: 'space-between',
                     paddingRight: 10, paddingLeft: 10, paddingTop: 10,
                     marginBottom: 13
                  }}>
                     <Image src={imgLink.logoWohhup} style={{ width: 90, height: 25, marginRight: 6 }} />
                     <Text style={{ fontSize: 10, ...fontStyles.fontBold, textDecoration: 'underline' }}>{`${refType.toUpperCase()} REPORT`}</Text>
                  </View>

                  <View style={{ flexDirection: 'row', paddingLeft: 10, fontSize: 10, marginBottom: 5 }}>
                     <Text>Project :</Text>
                     <Text style={{ ...fontStyles.fontBold, marginLeft: 5, marginRight: 20 }}>{projectName}</Text>
                     <Text>Date: {moment().format('DD/MM/YY')}</Text>
                  </View>

                  <View style={{ height: 12, flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, marginBottom: marginBottomHeaderForm }}>

                     {(pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') && (
                        <View style={{ flexDirection: 'row' }}>
                           {Object.keys(refStatistics['Overall']).map((item, i) => {
                              return (
                                 <View style={{ flexDirection: 'row', marginRight: 15 }} key={i}>
                                    <Text style={{ marginRight: 3, fontSize: 10, ...fontStyles.fontBold }}>{refStatistics['Overall'][item]}</Text>
                                    <Text style={{
                                       fontSize: 10,
                                       color: item === 'Replied' ? colorType.grey2
                                          : item === 'Due in the next 4 - 14 days' ? 'black'
                                             : item === 'Overdue' ? 'red'
                                                : colorTextRow[item],
                                    }}>{item}</Text>
                                 </View>
                              )
                           })}
                        </View>
                     )}

                     {(pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') && (
                        <View style={{ flexDirection: 'row' }}>
                           {statusArray.map(btn => (
                              <View style={{ flexDirection: 'row', marginRight: 15 }} key={btn}>
                                 <View style={{
                                    width: 8, height: 8, backgroundColor: btn === 'Consultant reviewing' ? 'white' : colorTextRowPdf[btn],
                                    marginRight: 2, marginTop: 1,
                                    border: btn === 'Consultant reviewing' ? '1px solid grey' : 'none'
                                 }} />
                                 <Text style={{ marginRight: 3, fontSize: 10, ...fontStyles.fontBold }}>{refStatisticRepliedStatus[btn]}</Text>
                                 <Text style={{ fontSize: 10 }}>
                                    {btn === 'Approved with Comment, no submission Required' ? 'AC'
                                       : btn === 'Approved with comments, to Resubmit' ? 'AC, R'
                                          : btn === 'Approved for Construction' ? 'AP'
                                             : btn === 'Consultant reviewing' ? 'UR'
                                                : btn === 'Reviewed And No Further Comments' ? 'RA'
                                                   : btn === 'Reviewed With Comments No Submission Required' ? 'RC'
                                                      : btn === 'For Record Only' ? 'RO'
                                                         : btn === 'Received For Info' ? 'RI'
                                                            : btn === 'replied' ? 'RA'
                                                               : 'RR'}
                                 </Text>
                              </View>
                           ))}
                        </View>
                     )}


                  </View>
               </View>



               <View>

                  {dataInputExport.map((range, rangeIndex) => {

                     const indentInfo = {};
                     const statusBoxInfo = {};
                     const dueInfo = {
                        'Overdue': [],
                        'Due in the next 1 - 3 days': [],
                        'Due in the next 4 - 14 days': []
                     };

                     const refName = `${refType.toUpperCase()} Ref`;


                     let tableBodyData = [
                        ...output
                           .filter((row, index) => index >= range[0] && index <= range[1])
                           .map((row, i) => {

                              const newData = {};
                              headersShown.forEach(hd => {
                                 newData[hd] = row[hd];
                              });

                              if (row['treeLevel']) {
                                 indentInfo[i] = row['treeLevel'];

                                 if (row[refName] && sumStatusOutput[row[refName]]) {
                                    statusBoxInfo[i] = sumStatusOutput[row[refName]];
                                 };
                                 if (dueDrawingOutput['Overdue'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Overdue'].push(i);
                                 } else if (dueDrawingOutput['Due in the next 1 - 3 days'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Due in the next 1 - 3 days'].push(i);
                                 } else if (dueDrawingOutput['Due in the next 4 - 14 days'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Due in the next 4 - 14 days'].push(i);
                                 };

                              } else {

                                 const parentFound = refType === 'rfa'
                                    ? drawingTypeTree.find(node => {

                                       const rfaRef = row[refName];
                                       const lastDigit = rfaRef[rfaRef.length - 1];
                                       const rfaNumber = checkIfStringContainNumberOnly(lastDigit)
                                          ? rfaRef
                                          : rfaRef.slice(0, rfaRef.length - 1);

                                       return node.id.includes(rfaNumber);
                                    })
                                    : drawingTypeTree.find(node => {
                                       return node.id === getDisciplineOfRefString(row[refName]);
                                    });


                                 if (parentFound) {
                                    indentInfo[i] = parentFound['treeLevel'] + 1;
                                 };

                                 if (dueDrawingOutput['Overdue'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Overdue'].push(i);
                                 } else if (dueDrawingOutput['Due in the next 1 - 3 days'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Due in the next 1 - 3 days'].push(i);
                                 } else if (dueDrawingOutput['Due in the next 4 - 14 days'].indexOf(row[refName]) !== -1) {
                                    dueInfo['Due in the next 4 - 14 days'].push(i);
                                 };
                              };

                              const result = [range[0] + i + 1, ...Object.values(newData)];

                              return result;
                           })
                     ];

                     if (rangeIndex === 0) {
                        tableBodyData.unshift(['', ...headersShown]);
                     };

                     return (
                        <TableDrawings
                           marginTopTablePageOne={marginTopTablePageOne}
                           key={rangeIndex}
                           col={['2.5%', ...headersShown.map(hd => getHeaderWidthByPage(hd, refType))]}
                           children={tableBodyData}
                           arrayIndexOfDwgTypeTree={arrayIndexOfDwgTypeTree}
                           arrayIndexOfDwgDarkerTypeTree={arrayIndexOfDwgDarkerTypeTree}
                           statusAllCellObject={statusAllCellObject}
                           fontStyles={fontStyles}
                           headersConsultantWithNumber={headersConsultantWithNumber}
                           headersShown={headersShown}
                           indentInfo={indentInfo}
                           pageIndex={rangeIndex}
                           range={range}
                           statusBoxInfo={statusBoxInfo}
                           dueInfo={dueInfo}
                           pageSheetTypeName={pageSheetTypeName}
                           isIncludeDrawingDetail={isIncludeDrawingDetail}
                           totalPageCount={dataInputExport.length}
                           rowCountNextPage={rowCountNextPage}
                           rowCountFirstPage={rowCountFirstPage}
                        />
                     )
                  })}
               </View>


            </View>
         </Page>
      </Document>
   );
};


export default ExportPdfDataView;




const TableDrawings = ({
   children, col, fontStyles, arrayIndexOfDwgTypeTree, arrayIndexOfDwgDarkerTypeTree, statusAllCellObject, rowCountNextPage, rowCountFirstPage,
   marginTopTablePageOne, headersShown, indentInfo, pageIndex, range, dueInfo, pageSheetTypeName, isIncludeDrawingDetail, totalPageCount
}) => {


   const dueDateColumnIndex = headersShown.indexOf('Due Date');

   const rowHeight = 15;
   const rowCount = children.length;
   const rowBlankLeftCount = rowCountNextPage - rowCount;

   return (
      <View style={{
         width: '100%',
         borderTop: '1px solid black',
         flexDirection: 'column',
         marginVertical: 1,
         borderCollapse: 'collapse',
         fontSize: '8px',
         marginTop: marginTopTablePageOne,
         paddingBottom: 0,
         marginBottom: (pageIndex === totalPageCount - 1 && totalPageCount === 1) ? ((rowCountFirstPage - rowCount) * rowHeight) // There is only one page
            : (pageIndex === totalPageCount - 1) ? (rowBlankLeftCount * rowHeight) : 5,
      }}>
         {children.map((row, rowIndex) => {


            const ind = row[0] || 0;

            const textBold = (ind === 0 || arrayIndexOfDwgTypeTree.indexOf(ind - 1) !== -1 || arrayIndexOfDwgDarkerTypeTree.indexOf(ind - 1) !== -1)
               ? { ...fontStyles.fontBold }
               : { ...fontStyles.fontRegular };

            return (
               <View key={ind} style={{
                  flexDirection: 'row',
               }}>
                  {row.map((cell, j) => {
                     let colorStatus;

                     const statusDataRow = statusAllCellObject[ind - 1];


                     let isCellRfaHeader;
                     if (statusDataRow) {
                        const headerName = headersShown[j - 1];
                        const statusDataCell = statusDataRow[headerName];

                        if (statusDataCell) {
                           if ((typeof statusDataCell === 'object') && !_.isEmpty(statusDataCell)) {
                              isCellRfaHeader = statusDataCell;
                           } else if (typeof statusDataCell === 'string') {
                              colorStatus = colorTextRowPdf[statusDataCell];
                           };
                        };
                     };

                     let indent = 3;
                     let space = 6;
                     let dueText = 'Replied';
                     let isWidthExpand = false;
                     let isWidthReduce = false;


                     if (j === 1) {
                        if (pageIndex === 0 && indentInfo[rowIndex - 1]) { // first page index 0 is HEADER
                           indent = (indentInfo[rowIndex - 1] - 2) * space + 3;
                        } else if (pageIndex > 0 && indentInfo[rowIndex]) {
                           indent = (indentInfo[rowIndex] - 2) * space + 3;
                        };
                     } else if (
                        j === 3 && pageSheetTypeName === 'page-rfa' &&
                        arrayIndexOfDwgTypeTree.indexOf(ind - 1) !== -1 && cell
                        && isIncludeDrawingDetail
                     ) {
                        isWidthExpand = true;
                     } else if (
                        j - 1 === 3 && pageSheetTypeName === 'page-rfa' &&
                        arrayIndexOfDwgTypeTree.indexOf(ind - 1) !== -1 && row[j - 1]
                        && isIncludeDrawingDetail
                     ) {
                        isWidthReduce = true;
                     } else if (j === dueDateColumnIndex + 1) {
                        if (
                           (pageIndex === 0 && dueInfo['Overdue'].indexOf(rowIndex - 1) !== -1) ||
                           (pageIndex > 0 && dueInfo['Overdue'].indexOf(rowIndex) !== -1)
                        ) {
                           dueText = 'Overdue';
                        } else if (
                           (pageIndex === 0 && dueInfo['Due in the next 1 - 3 days'].indexOf(rowIndex - 1) !== -1) ||
                           (pageIndex > 0 && dueInfo['Due in the next 1 - 3 days'].indexOf(rowIndex) !== -1)
                        ) {
                           dueText = 'Due in the next 1 - 3 days';
                        } else if (
                           (pageIndex === 0 && dueInfo['Due in the next 4 - 14 days'].indexOf(rowIndex - 1) !== -1) ||
                           (pageIndex > 0 && dueInfo['Due in the next 4 - 14 days'].indexOf(rowIndex) !== -1)
                        ) {
                           dueText = 'Due in the next 4 - 14 days';
                        };
                     };


                     const cellWidth = isWidthExpand ? `${parseInt(col[j].slice(0, col[j].length - 1)) + parseInt(col[j + 1].slice(0, col[j + 1].length - 1))}%`
                        : isWidthReduce ? 0
                           : col[j];


                     return isCellRfaHeader ? (
                        <View key={j} style={{
                           borderRight: j !== row.length - 1 && '1px solid black',
                           borderBottom: '1px solid black',
                           width: col[j],
                           flexDirection: 'row',
                           height: ind === 0 ? 25 : rowHeight,
                        }}>
                           <View style={{
                              width: isCellRfaHeader['company'] ? 30 : 0,
                              backgroundColor: colorType.grey3,
                              textAlign: 'center',
                              paddingTop: 3,
                              height: '100%',
                              ...fontStyles.fontBold,
                           }}>
                              <Text>{isCellRfaHeader['company']}</Text>
                           </View>

                           {sortTotalStatusRfaOrder(isCellRfaHeader['statusInfo'] || {}).map((stt, i) => {
                              return (
                                 <View
                                    key={i}
                                    style={{
                                       width: `${100 * isCellRfaHeader['statusInfo'][stt] / getTotalStatusInRfa(isCellRfaHeader['statusInfo'])}%`,
                                       backgroundColor: stt === 'Consultant reviewing' ? 'white' : colorTextRowPdf[stt],
                                       textAlign: 'center',
                                       paddingTop: 3,
                                       height: '100%',
                                       ...fontStyles.fontBold,
                                    }}
                                 >
                                    <Text>{isCellRfaHeader['statusInfo'][stt]}</Text>
                                 </View>
                              );
                           })}

                        </View>
                     ) : (

                        <View key={j} style={{
                           borderRight: (j !== row.length - 1 && !isWidthReduce) && '1px solid black',
                           borderBottom: '1px solid black',
                           alignContent: 'center',

                           width: cellWidth,
                           height: ind === 0 ? 25 : rowHeight,
                           paddingLeft: isWidthReduce ? 0 : indent,
                           justifyContent: ind === 0 ? 'none' : 'center',

                           backgroundColor: ind === 0 ? colorType.primary
                              : arrayIndexOfDwgTypeTree.indexOf(ind - 1) !== -1 ? colorType.grey3
                                 : arrayIndexOfDwgDarkerTypeTree.indexOf(ind - 1) !== -1 ? colorType.grey1
                                    : (colorStatus || 'transparent'),

                           color: ind === 0 ? 'white'
                              : (j === dueDateColumnIndex + 1 && dueText === 'Overdue') ? 'red'
                                 : (j === dueDateColumnIndex + 1 && dueText === 'Due in the next 1 - 3 days') ? colorTextRow[dueText]
                                    : (j === dueDateColumnIndex + 1 && dueText === 'Due in the next 4 - 14 days') ? 'black'
                                       : (j === dueDateColumnIndex + 1 && dueText === 'Replied') ? colorType.grey2
                                          : 'black',
                           ...(j !== 0 ? textBold : {}),
                           overflow: 'hidden',
                           flexWrap: 'nowrap',
                           textOverflow: 'ellipsis',
                        }}>
                           <Text
                              style={{}}
                           >{cell}</Text>
                        </View>
                     );
                  })}
               </View>
            );
         })}
      </View>
   );
};



const colorTextRowPdf = {
   'Approved with Comment, no submission Required': '#98FB98',
   'Reviewed With Comments No Submission Required': '#98FB98',


   'Approved for Construction': '#32CD32',
   'Reviewed And No Further Comments': '#32CD32',

   'For Record Only': '#8b7113',
   'Received For Info': '#8b7113',

   'replied': '#98FB98',


   'Approved with comments, to Resubmit': '#FFA500',
   'Reviewed With Comments Resubmission Is Required': '#FFA500',



   'Reject and resubmit': '#FA8072',
   'Consultant reviewing': 'white',

   'Overdue': '#FFB6C1',
   'Due in 3 days': '#FFFACD',
};



const splitTableToPages = (totalLength, rowCountFirstPage, rowCountNextPage) => {
   const numFloat = (totalLength - rowCountFirstPage) / rowCountNextPage
   const nosOfNextPages = Math.floor(numFloat) < numFloat ? Math.floor(numFloat) + 1 : Math.floor(numFloat);

   const output = [
      [0, rowCountFirstPage - 1]
   ];


   [...Array(nosOfNextPages).keys()].forEach(num => {

      output.push([
         rowCountFirstPage + num * rowCountNextPage,
         rowCountFirstPage + (num + 1) * rowCountNextPage - 1
      ]);
   });

   return output;
};



const getHeaderWidthByPage = (header, refType) => {
   if (refType === 'rfa') {
      if (header === 'RFA Ref') return '13%';
      else if (header === 'Rev') return '2%';

      else if (header === 'Drawing Number') return '11%';
      else if (header === 'Drawing Name') return '10%';
      else if (header === 'Description') return '21%';


      else if (header === 'Requested By') return '4%';
      else if (header === 'Submission Date') return '4.5%';
      else if (header === 'Due Date') return '4%';
      else if (header === 'Issue For Construction') return '7%';
      else if (header.includes('Consultant (')) return '6%';

   } else if (refType === 'rfam') {
      if (header === 'RFAM Ref') return '13.5%';
      else if (header === 'Description') return '12%';
      else if (header === 'Contract Specification') return '5%';
      else if (header === 'Proposed Specification') return '8%';
      else if (header === 'Requested By') return '4%';
      else if (header === 'Submission Date') return '4%';
      else if (header === 'Due Date') return '4%';
      else if (header === 'Submission Type') return '5%';
      else if (header.includes('Consultant (')) return '6%';

   } else if (refType === 'rfi') {
      if (header === 'RFI Ref') return '13.5%';
      else if (header === 'Subject') return '17%';
      else if (header === 'Description') return '12%';
      else if (header === 'Requested By') return '5%';
      else if (header === 'Submission Date') return '4%';
      else if (header === 'Due Date') return '4%';
      else if (header.includes('Consultant (')) return '6%';

   } else if (refType === 'dt') {
      if (header === 'DT Ref') return '13.5%';
      else if (header === 'Subject') return '20%';
      else if (header === 'Transmitted For') return '10%';
      else if (header === 'Attachment Type') return '7%';
      else if (header === 'Description') return '17%';
      else if (header === 'Requested By') return '7%';
      else if (header === 'Submission Date') return '5%';
      else if (header === 'Received By') return '18%';

   } else if (refType === 'cvi') {
      if (header === 'CVI Ref') return '13.5%';
      else if (header === 'Description') return '22%';
      else if (header === 'Requested By') return '7%';
      else if (header === 'Conversation Among') return '15%';
      else if (header === 'Submission Date') return '5%';
      else if (header === 'Conversation Date') return '6%';
      else if (header === 'Cost Implication') return '6%';
      else if (header === 'Time Extension') return '6%';
      else if (header === 'Received By') return '17%';

   } else if (refType === 'mm') {
      if (header === 'MM Ref') return '13.5%';
      else if (header === 'Subject') return '30%';
      else if (header === 'Description') return '42%';
      else if (header === 'Submission Date') return '6%';
      else if (header === 'Meeting Date') return '6%';
   }else if (refType === 'eri') {
      if (header === 'ERI Ref') return '13.5%';
      else if (header === 'Subject') return '30%';
      else if (header === 'Description') return '42%';
      else if (header === 'Submission Date') return '6%';
      else if (header === 'Meeting Date') return '6%';
   }else if (refType === 'lr') {
      if (header === 'LR Ref') return '13.5%';
      else if (header === 'Subject') return '30%';
      else if (header === 'Description') return '42%';
      else if (header === 'Submission Date') return '6%';
      else if (header === 'Meeting Date') return '6%';
   }else if (refType === 'sm') {
      if (header === 'SM Ref') return '13.5%';
      else if (header === 'Subject') return '30%';
      else if (header === 'Description') return '42%';
      else if (header === 'Submission Date') return '6%';
      else if (header === 'Meeting Date') return '6%';
   } else if (refType === 'qr') {
      if (header === 'QR Ref') return '15.5%';
      else if (header === 'Description') return '19%';
      else if (header === 'Requested By') return '5%';
      else if (header === 'RFA Info') return '8%';
      else if (header === 'Submission Date') return '4%';
      else if (header === 'Due Date') return '4%';
      else if (header.includes('Consultant (')) return '6%';

   } else if (refType === 'qrm') {
      if (header === 'QRM Ref') return '15.5%';
      else if (header === 'Description') return '19%';
      else if (header === 'Requested By') return '5%';
      else if (header === 'RFAM Info') return '8%';
      else if (header === 'Submission Date') return '4%';
      else if (header === 'Due Date') return '4%';
      else if (header.includes('Consultant (')) return '6%';

   } else if (refType === 'rfc') {
      if (header === 'RFC Ref') return '15.5%';
      else if (header === 'Description') return '19%';
      else if (header === 'Requested By') return '5%';
      else if (header === 'Attachment Type') return '8%';
      else if (header === 'Submission Date') return '4%';
      else if (header === 'Due Date') return '4%';
      else if (header.includes('Consultant (')) return '6%';
   }
};