import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Row, Col, Radio, DatePicker, Tooltip, message } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import _ from "lodash";
import ChartTradePerMonth from "./common_check/ChartTradePerMonth";
import ChartTradePerProject from "./common_check/ChartTradePerProject";
import ChartTradeSummary from "./common_check/ChartTradeSummary";
import TableTradeSummary from "./common_check/TableTradeSummary";
import TableCheckSummary from "./common_check/TableCheckSummary";
import listColor from "../function/listColor";
const { RangePicker } = DatePicker;
const CommonChecks = (current) => {
   const [originData, setOriginData] = useState();
   const [tempData, setTempData] = useState();
   const [failItems, setFailItems] = useState();
   const [failByMonth, setFailByMonth] = useState();
   const [listProject, setListProject] = useState([]);
   const [listDiscipline, setListDiscipline] = useState();
   const [listTrade, setListTrade] = useState();
   const [lineLabels, setLineLables] = useState();
   const [tradeSelected, setTradeSelected] = useState();
   const [currentProject, setCurrentProject] = useState();
   const [currentDiscipline, setCurrenDiscipline] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [filterMode, setFilterMode] = useState(0);
   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            // await axios.post("/api/qaqc/getdata/jotform-common");
            // message.success("Latest data from Jotform has been loaded!");

            const res = await axios.get("/api/qaqc/dashboard/commoncheck");
            const temp_d = res.data.data;
            setOriginData(temp_d);
            setTempData(temp_d);
            setCurrentProject("All Projects");
            setCurrenDiscipline("All Trades");

            let projects = [...new Set(temp_d.map((obj) => obj.project))];
            projects.sort();
            projects.splice(0, 0, "All Projects");
            setListProject(projects);

            let disciplines = [...new Set(temp_d.map((obj) => obj.discipline))];
            disciplines.sort();
            disciplines.splice(0, 0, "All Trades");
            setListDiscipline(disciplines);

            Digest_Data_All_Project(temp_d, "All Projects", "All Trades");
         };

         fetchdata();
      }
   }, []);

   const getShortNameOfTrade = (originalName) => {
      let short_name = "";
      const list_word = originalName.split(" ");
      if (list_word.length > 1) {
         for (let i = 0; i < list_word.length; i++) {
            const e = list_word[i];
            if (e !== "/" && e !== "/") {
               if (i === 0) {
                  short_name = e.substring(0, 4) + "-";
               } else {
                  if (i < 4) {
                     short_name = short_name + e.substring(0, 1);
                  }
               }
            }
         }
      }

      if (list_word.length === 1) {
         if (originalName.length > 5) {
            short_name = originalName.substring(0, 4);
         }
      }

      return short_name;
   };

   const Digest_Data_All_Project = (temp_d, current_project, current_discipline) => {
      temp_d.forEach((e) => {
         e.YYYYMM = dayjs(e.created_at).format("YYYYMM");
         e.MMYY = dayjs(e.created_at).format("MM/YY");
      });

      //GET DATA FOR TABLE AND BAR CHART
      var group_trade = _.groupBy(temp_d, "trade");

      let list_fail_by_trade = [];
      _.map(group_trade, (value, key) => {
         let t = new Object();
         t.discipline = value[0].discipline;
         t.name = value[0].trade;
         // t.short_name = getShortNameOfTrade(value[0].trade);
         t.short_name = value[0].shortform;

         const list_trade = temp_d.filter((e) => e.trade === value[0].trade);

         t.total_no = list_trade.reduce((e, { no_of_no }) => e + no_of_no, 0);
         t.total_yes = list_trade.reduce((e, { no_of_yes }) => e + no_of_yes, 0);
         t.total = t.total_no + t.total_yes;

         // //get prevous month name
         // var lastMonth = dayjs().subtract("1", "month").format("MM/YY");

         // const list_trade_current_month = list_trade.filter((e) => e.MMYY === lastMonth);

         // t.total_no_current_month = list_trade_current_month.reduce((e, { no_of_no }) => e + no_of_no, 0);
         // t.total_yes_current_month = list_trade_current_month.reduce((e, { no_of_yes }) => e + no_of_yes, 0);
         // t.total_current_month = t.total_no_current_month + t.total_yes_current_month;

         let percent = (100 * t.total_no) / t.total;
         t.percent = percent.toLocaleString("en-US", {
            maximumFractionDigits: 1,
            miniimumFractionDigits: 1,
            useGrouping: false,
         });
         // let percent_current = (100 * t.total_no_current_month) / t.total_current_month;
         // t.percent_current = percent_current.toLocaleString("en-US", {
         //    maximumFractionDigits: 1,
         //    miniimumFractionDigits: 1,
         //    useGrouping: false,
         // });

         // if (current_project === "All Projects") {
         //get list of all project from data
         let projects = [...new Set(temp_d.map((obj) => obj.project))];
         //get percent for each project
         projects.forEach((p) => {
            const list_trade_project = list_trade.filter((e) => e.project === p);

            const total_no_project = list_trade_project.reduce((e, { no_of_no }) => e + no_of_no, 0);
            const total_yes_project = list_trade_project.reduce((e, { no_of_yes }) => e + no_of_yes, 0);
            const total_project = total_no_project + total_yes_project;

            let percent_project = (100 * total_no_project) / total_project;
            const project_percent_name = `${p}_percent`;
            if (percent_project > 0) {
               t[project_percent_name] = percent_project.toLocaleString("en-US", {
                  maximumFractionDigits: 1,
                  miniimumFractionDigits: 1,
                  useGrouping: false,
               });
            } else {
               t[project_percent_name] = 0;
            }
         });
         // }
         if (t.name != "" && t.name != undefined) {
            list_fail_by_trade.push(t);
         }
      });
      list_fail_by_trade = list_fail_by_trade.filter((e) => e.total > 5);
      list_fail_by_trade = _.sortBy(list_fail_by_trade, ["discipline", "name"]);

      //GET DATA FOR LINE CHART
      var groups = _.groupBy(temp_d, "YYYYMM");
      groups = _.sortBy(groups, "YYYYMM");
      let list_fail_by_months = [];
      _.map(groups, (value, key) => {
         let t = new Object();
         t.name = value[0].MMYY;
         const per_month = temp_d.filter((e) => e.MMYY === value[0].MMYY);

         //then group by trade
         var per_trade = _.groupBy(per_month, "shortform");
         _.map(per_trade, (val, key) => {
            let count_no = val.reduce((e, { no_of_no }) => e + no_of_no, 0);
            let count_yes = val.reduce((e, { no_of_yes }) => e + no_of_yes, 0);
            let total = count_no + count_yes;
            if (total > 0) {
               t[key] = (100 * count_no) / total;
            } else {
               t[key] = 0;
            }
         });

         list_fail_by_months.push(t);
      });

      //LIST TRADE
      const list_trade = list_fail_by_trade.map((item) => item.name);
      // let line_labels = [];
      // list_trade.map((item, index) => {
      //    const t = { key: item, color: listColor[index] };
      //    line_labels.push(t);
      // });

      // const list_trade = list_fail_by_trade.map((item) => item.name);
      let line_labels = [];
      list_fail_by_trade.map((item, index) => {
         const t = { key: item.short_name, trade_full_name: item.name, color: listColor[index] };
         line_labels.push(t);
      });

      setFailItems(list_fail_by_trade);
      setFailByMonth(list_fail_by_months);
      setListTrade(list_trade);
      setLineLables(line_labels);
      setTradeSelected(list_trade[0]);

      return;
   };

   const handleChangeProject = (e) => {
      const current_project = e.target.value;
      setCurrentProject(current_project);
      let data = [];
      if (current_project === "All Projects") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === current_project);
      }

      if (currentDiscipline !== "All Trades") {
         data = data.filter((e) => e.discipline === currentDiscipline);
      }

      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end !== null
      ) {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end == null
      ) {
         data = data.filter((e) => e.created_at >= date_start);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_project);
   };

   const handleChangeDiscipline = (e) => {
      const current_discipline = e.target.value;
      setCurrenDiscipline(current_discipline);
      let data = [];

      if (currentProject === "All Projects") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === currentProject);
      }

      if (current_discipline !== "All Trades") {
         data = data.filter((e) => e.discipline === current_discipline);
      }
      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end !== null
      ) {
         data = data.filter((e) => e.created_at >= date_start && e.created_at <= date_end);
      }
      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end == null
      ) {
         data = data.filter((e) => e.created_at >= date_start);
      }
      setTempData(data);
      Digest_Data_All_Project(data, current_discipline);
   };

   const handleChangeDate = (fm, dateString) => {
      var customParseFormat = require("dayjs/plugin/customParseFormat");
      dayjs.extend(customParseFormat);
      var isBetween = require("dayjs/plugin/isBetween");
      dayjs.extend(isBetween);
      var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
      dayjs.extend(isSameOrAfter);

      const now = dayjs();
      const y = now.year();
      const m = now.month();
      const d = now.day();
      let data = [];
      if (currentProject === "All Projects") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project === currentProject);
      }

      if (currentDiscipline !== "All Trades") {
         data = data.filter((e) => e.discipline === currentDiscipline);
      }

      switch (fm) {
         case 1:
            // const sd1 = dayjs([y, m + 1, 1]).format("YYYY-MM-DD");
            const sd1 = dayjs(new Date(y, m, 1)).format('YYYY-MM-DD');
            data = data.filter((e) => e.month == m);
            set_date_start(sd1);
            set_date_end(null);

            break;
         case 2:
            let last = now.subtract(1, "month")
            // let sd2 = dayjs(new Date(last.year(), last.month(), 1)).format("YYYY-MM-DD")
            // const de = dayjs(sd2).daysInMonth()
            // let ed2 = dayjs(new Date(last.year(), last.month(), de)).format("YYYY-MM-DD")
            // // data = data.filter((e) => e.created_at >= sd2 && e.created_at <= ed2);
            // console.log('data',data)
            // data = data.filter((e) => e.month == m - 1);
            // set_date_start(sd2);
            // set_date_end(ed2);


            let sd2 = last.startOf('month').format('YYYY-MM-DD');
            let ed2 = last.endOf('month').format('YYYY-MM-DD');
            let month = last.format('MM/YY');
        
            data = data.filter((e) => e.MMYY == month);
    
            set_date_start(sd2);
            set_date_end(ed2);
            break;
         // case 3:
         //    let sd3 = now.subtract(3, "month").format("YYYY-MM-DD");
         //    data = data.filter((e) => dayjs(e.created_at, "DD/MM/YYYY").isSameOrAfter(sd3, "day"));
         //    set_date_start(sd3);
         //    set_date_end(null);
         //    break;

         case 4:
            if (
               dateString[0] !== undefined &&
               dateString[0] !== "" &&
               dateString[1] !== undefined &&
               dateString[1] !== ""
            ) {
               data = data.filter((e) =>
                  dayjs(e.created_at, "DD/MM/YYYY").isBetween(dateString[0], dateString[1], "day", "[]")
               );

               set_date_start(dateString[0]);
               set_date_end(dateString[1]);
            }
            break;
      }

      setTempData(data);
      Digest_Data_All_Project(data, currentProject);
   };

   return (
      <div style={{ margin: "10px 15px", height: "100%" }}>
         <Row>
            <Col span={17} style={{ left: "-10px" }}>
               <div >
                  <Row gutter={10}>
                     <Radio.Group
                        defaultValue="All Projects"
                        buttonStyle="solid"
                        onChange={(e) => handleChangeProject(e)}
                     >
                        {listProject !== undefined
                           ? listProject.map((p) => (
                                <Radio.Button value={p} key={p}>
                                   {p.toUpperCase()}
                                </Radio.Button>
                             ))
                           : null}
                     </Radio.Group>
                  </Row>
                  <Row gutter={10} style={{ marginTop: "5px" }}>
                     <Radio.Group
                        defaultValue="All Trades"
                        buttonStyle="solid"
                        onChange={(e) => handleChangeDiscipline(e)}
                     >
                        {listDiscipline !== undefined
                           ? listDiscipline.map((p) => (
                                <Radio.Button value={p} key={p}>
                                   {p}
                                </Radio.Button>
                             ))
                           : null}
                     </Radio.Group>

                     <ButtonGroup style={{ float: "right", marginRight: 7 }}>
                        <Radio.Group
                           defaultValue={filterMode}
                           buttonStyle="solid"
                           value={filterMode}
                           onChange={(e) => {
                              setFilterMode(e.target.value);
                           }}
                        >
                           <div>
                              <Tooltip title="Shown data for all time.">
                                 <Radio.Button
                                    value={0}
                                    onClick={() => {
                                       handleChangeDate(0, null);
                                    }}
                                 >
                                    All time
                                 </Radio.Button>
                              </Tooltip>

                              <Tooltip title="Shown data for current month">
                                 <Radio.Button
                                    value={1}
                                    onClick={() => {
                                       handleChangeDate(1, null);
                                    }}
                                 >
                                    {dayjs().format("MMM-YY")}
                                 </Radio.Button>
                              </Tooltip>
                              <Tooltip title="Shown data of last month">
                                 <Radio.Button
                                    value={2}
                                    onClick={() => {
                                       handleChangeDate(2, null);
                                    }}
                                 >
                                    {dayjs().subtract(1, "month").format("MMM-YY")}
                                 </Radio.Button>
                              </Tooltip>

                              {/* <Tooltip title="Shown DO data last 3 months.">
                              <Radio.Button
                                 value={3}
                                 onClick={() => {
                                    handleChangeDate(3, null);
                                 }}
                              >
                                 these 3 mths
                              </Radio.Button>
                           </Tooltip> */}

                              <Tooltip title="Shown DO for selected months.">
                                 <Radio.Button value={4} style={{ float: "right", width: 220 }}>
                                    Select range of date
                                    {filterMode === 4 ? (
                                       <RangePicker
                                          size={"default"}
                                          style={{ float: "right", marginRight: -15, width: 220 }}
                                          onChange={(date, dateString) => {
                                             handleChangeDate(4, dateString);
                                             setFilterMode(4);
                                          }}
                                       />
                                    ) : null}
                                 </Radio.Button>
                              </Tooltip>
                           </div>
                           {/* </Space> */}
                        </Radio.Group>
                     </ButtonGroup>
                  </Row>
               </div>
               <br></br>
               <Row className="qaqc_dashboard_card">
                  <h3>% of checks are fails by trade</h3>
                  <ChartTradeSummary
                     current_project={currentProject}
                     projects={listProject}
                     data={failItems}
                     tradeSelected={tradeSelected}
                     setTradeSelected={setTradeSelected}
                  />
               </Row>
               <br></br>
               <Row className="qaqc_dashboard_card">
                  {failByMonth !== undefined && lineLabels !== undefined ? (
                     <ChartTradePerMonth
                        values={failByMonth}
                        labels={lineLabels}
                        tradeSelected={tradeSelected}
                        setTradeSelected={setTradeSelected}
                     />
                  ) : null}
               </Row>
            </Col>
            <Col
               span={7}
               style={{
                  top: "0px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  height: "100%",
               }}
            >
               <div className="qaqc_dashboard_card">
                  <TableTradeSummary data={failItems} setTradeSelected={setTradeSelected} />
               </div>
               <br />
               <div className="qaqc_dashboard_card">
                  <TableCheckSummary tradeSelected={tradeSelected} data={tempData} />
               </div>
               <br />
               <div className="qaqc_dashboard_card">
                  {currentProject === "All Projects" ? (
                     <ChartTradePerProject
                        current_project={currentProject}
                        projects={listProject}
                        data={failItems}
                        tradeSelected={tradeSelected}
                        setTradeSelected={setTradeSelected}
                     />
                  ) : null}
               </div>
            </Col>
         </Row>
      </div>
   );
};

export default CommonChecks;
