import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../../actions/app/auth';
import {
  message,
  Button,
  Card,
  Divider,
  Typography,
  Row,
  Col,
  Result,
  Popover,
  Empty,
  Progress,
  Modal,
  DatePicker,
  Icon as IconAntd,
  Tooltip,
} from 'antd';
import {
  Icon,
  IconButton,
  ButtonGroup as ButtonGroupRs,
  Button as ButtonRs,
} from 'rsuite';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import RGL, { WidthProvider } from 'react-grid-layout';
import moment from 'moment';
import { ButtonMenuProjectDetail } from '../../module_BimApp/buttons';
import DashboardDrawingProjectCombine from '../DMSApp/DashboardDrawingProjectCombine';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipChart,
  ResponsiveContainer,
  ReferenceLine,
  Brush,
  Line,
  LineChart,
} from 'recharts';
import MaterialTable from 'material-table';
import { handleModulePPVCAndPrecast } from '../../../components/module_BimApp/function/ProgressFunction';
import { storageUser } from '../../../components/module_BimApp/function/PageFunction';
import { checkWohhupUser } from '../../../components/module_BimApp/function/AdminFunction';
// import { roles } from '../../../components/module_BimApp/columns/Role'
import { storeProjectDetail } from '../../../actions/app/app';
import PickModel from '../5D/PickModel';
const { RangePicker } = DatePicker;
const ButtonGroup = Button.Group;
const { Text } = Typography;
const ReactGridLayout = WidthProvider(RGL);
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className='box' style={{ ...style, ...viewStyle }} {...props} />;
};
class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAccessGantt: false,
      isAccessDashboardDrawing: false,
      isAccessBimApp: false,
      isAccessDataEntry: false,
      isAccessWorkerAllocation: false,
      isAccessQAQC: false,
      listAdmin: [],
      layout: [
        { i: 'd', x: 0, y: 0, w: 22, h: 6 },
        { i: 'a', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'b', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'c', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },

        { i: 'e', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'f', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'g', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'h', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'k', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'l', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
      ],
      oldLayout: [
        { i: 'd', x: 0, y: 0, w: 22, h: 6 },
        { i: 'a', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'b', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'c', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'e', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'f', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'g', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'h', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'k', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
        { i: 'l', x: 22, y: 4, w: 2, h: 0.5, isResizable: false },
      ],
      heightDOM: 700,
      dataPieChartIssue: {},
      dataPieChartComment: {},
      dataPPVC_Precast: {},
      dataPPVC_PrecastBar: [],
      dataPPVC: [],
      format: 'DD/MM/YY',
      showTablePPVC: false,
      tableDetail: [],
      tableDetailFilterDate: [],
      tableAll: false,
      isDataDMSExisting: true,
      role: '',
      isOpenPickModel: false,
      currentTypeFile: '',
      loadingUpdate: false,
    };
  }
  componentWillMount = () => {
    window.time = {
      startPrecastAPI: 0,
      endPrecastAPI: 0,
      startPrecastLogic: 0,
      endPrecastLogic: 0,
      startDMSAPI: 0,
      endDMSAPI: 0,
      startDMSLogic: 0,
      endDMSLogic: 0,
      startIssue: 0,
      endIssue: 0,
      startComment: 0,
      endComment: 0,
    };
    this.props.storeProjectDetail({
      projectId: this.props.location.state.projectId,
      projectName: this.props.location.state.projectName,
      userCreatedProject: this.props.location.state.userCreatedProject,
      sharePointId: this.props.location.state.sharePointId,
      issueKey: this.props.location.state.issueKey,
      trimbleKey: this.props.location.state.trimbleKey,
      typeProject: this.props.location.state.typeProject,
      useRfa: this.props.location.state.useRfa,
      isDetail: true,
    });
    axios
      .post('/api/auth/refresh_token', {
        token: this.props.userInfo.token,
        email: this.props.userInfo.email,
        user: this.props.userInfo,
      })
      .then(async (res) => {
        storageUser(res.data.user);

        let result = await axios
          .get(`/api/projects/permission-for-role`, {
            params: {
              projectId: this.props.location.state.projectId,
              token: this.props.userInfo.token,
            },
          })
          .catch((err) => { });
        // let temp = []
        // _.forEach(roles, v => {
        //   let index = _.findIndex(result.data, i => { return i.role === v.label })
        //   if (index >= 0) {
        //     temp.push(result.data[index])
        //   } else {
        //     temp.push({ role: v.label })
        //   }
        // })
        let index = _.findIndex(res.data.user.contractors, (v) => {
          return v.projectId === this.props.location.state.projectId;
        });
        if (index >= 0) {
          let role = res.data.user.contractors[index].role;
          let index1 = _.findIndex(result.data, (i) => {
            return i.role === role;
          });
          let acceptBimApp = checkWohhupUser(res.data.user.email)
            ? true
            : index1 >= 0
              ? result.data[index1].isAccessBimApp
                ? result.data[index1].isAccessBimApp
                : false
              : false;
          let acceptDMS =
            index1 >= 0
              ? result.data[index1].isAccessDashboardDrawing
                ? result.data[index1].isAccessDashboardDrawing
                : false
              : false;
          let acceptDataEntry =
            index1 >= 0
              ? result.data[index1].isAccessDataEntry
                ? result.data[index1].isAccessDataEntry
                : false
              : false;
          let acceptCIP =
            index1 >= 0
              ? result.data[index1].isAccessGantt
                ? result.data[index1].isAccessGantt
                : false
              : false;
          let acceptWorkerAllocation =
            index1 >= 0
              ? result.data[index1].isAccessWorkerAllocation
                ? result.data[index1].isAccessWorkerAllocation
                : false
              : false;
          let acceptQAQC =
            index1 >= 0
              ? result.data[index1].isAccessQAQC
                ? result.data[index1].isAccessQAQC
                : false
              : false;
          let layout = [
            { i: 'd', x: 0, y: 0, w: 22, h: 6 },
            acceptBimApp && {
              i: 'a',
              x: 22,
              y: 2,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDMS) && {
              i: 'b',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptCIP) && {
              i: 'c',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDataEntry) && {
              i: 'e',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            acceptBimApp && {
              i: 'f',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptWorkerAllocation) && {
              i: 'g',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDataEntry) && {
              i: 'h',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptQAQC) && {
              i: 'k',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
          ];
          let oldLayout = [
            { i: 'd', x: 0, y: 0, w: 22, h: 6 },
            acceptBimApp && {
              i: 'a',
              x: 22,
              y: 2,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDMS) && {
              i: 'b',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptCIP) && {
              i: 'c',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDataEntry) && {
              i: 'e',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            acceptBimApp && {
              i: 'f',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptWorkerAllocation) && {
              i: 'g',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptDataEntry) && {
              i: 'h',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            (this.props.isAdmin || acceptQAQC) && {
              i: 'k',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
          ];
          this.setState({
            role,
            isAccessGantt: acceptCIP,
            isAccessDashboardDrawing: acceptDMS,
            isAccessBimApp: acceptBimApp,
            isAccessDataEntry: acceptDataEntry,
            isAccessWorkerAllocation: acceptWorkerAllocation,
            isAccessQAQC: acceptQAQC,
            layout: _.remove(layout, (v) => {
              return v !== false;
            }),
            oldLayout: _.remove(oldLayout, (v) => {
              return v !== false;
            }),
          });
        } else {
          let acceptBimApp = checkWohhupUser(res.data.user.email)
            ? true
            : false;
          let layout = [
            { i: 'd', x: 0, y: 0, w: 22, h: 6 },
            acceptBimApp && {
              i: 'a',
              x: 22,
              y: 2,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'b',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'c',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'e',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            acceptBimApp && {
              i: 'f',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'g',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'h',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'k',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            {
              i: 'l',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
          ];
          let oldLayout = [
            { i: 'd', x: 0, y: 0, w: 22, h: 6 },
            acceptBimApp && {
              i: 'a',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'b',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'c',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'e',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            acceptBimApp && {
              i: 'f',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'g',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'h',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
            this.props.isAdmin && {
              i: 'k',
              x: 22,
              y: 4,
              w: 2,
              h: 0.5,
              isResizable: false,
            },
          ];
          let temp1 = _.remove(layout, (v) => {
            return v !== false;
          });
          let temp2 = _.remove(oldLayout, (v) => {
            return v !== false;
          });
          this.setState({
            isAccessBimApp: acceptBimApp,
            layout: temp1,
            oldLayout: temp2,
          });
        }
        try {
          this.setUpPieChartIssue();
          this.setUpPieChartComment();
          if (
            this.props.location.state.typeProject !== 'None' &&
            this.props.location.state.sharePointId !== ''
          ) {
            this.setUpPrecastChart();
          } else {
            if (
              this.props.location.state.typeProject === 'None' &&
              this.props.location.state.sharePointId !== ''
            )
              message.warning(
                'Please, select type of project if you want to see PPVC/Precast DashBoard'
              );
          }
        } catch { }
      })
      .catch(() => {
        message.warning(`Cannot get user data`);
      });
    axios
      .post(`/api/projects/get-information-project`, {
        projectId: this.props.location.state.projectId,
        token: this.props.userInfo.token,
      })
      .then((res) => {
        let temp = [];
        _.forEach(res.data.listAdmin, (v) => {
          temp.push(v.email);
        });
        this.setState({ listAdmin: temp });
      })
      .catch(() => { });
  };
  setUpPrecastChart = (isUpdate) => {
    this.setState({ loadingUpdate: true });
    window.time.startPrecastAPI = Date.now();
    if (this.props.location.state.typeProject === 'PPVC/PBU') {
      axios
        .get('/api/wohhup/getModules', {
          params: {
            siteCode: this.props.location.state.sharePointId,
            isUpdate,
          },
        })
        .then((res) => {
          if (res && res.data.isSuccessful) {
            window.time.endPrecastAPI = Date.now();
            console.log(
              `Start: ${new Date(
                window.time.startPrecastAPI
              )} | End: ${new Date(
                window.time.endPrecastAPI
              )} | Precast API Time: `,
              window.time.endPrecastAPI - window.time.startPrecastAPI
            );
            this.moduleData = res.data.result;
            this.setupPieChartPPVC();
            this.setState({
              precastUpdateTime: moment(res.data.cacheTime).format(
                'DD-MM-YYYY HH:mm'
              ),
            });
          }
        })
        .catch(() => { });
    } else {
      axios
        .get('/api/wohhup/getModules', {
          params: {
            siteCode: this.props.location.state.sharePointId,
            isUpdate,
          },
        })
        .then((res) => {
          if (res && res.data.isSuccessful) {
            window.time.endPrecastAPI = Date.now();
            console.log(
              `Start: ${new Date(
                window.time.startPrecastAPI
              )} | End: ${new Date(
                window.time.endPrecastAPI
              )} | Precast API Time: `,
              window.time.endPrecastAPI - window.time.startPrecastAPI
            );
            this.moduleData = res.data.result;
            this.setState({
              precastUpdateTime: moment(res.data.cacheTime).format(
                'DD-MM-YYYY HH:mm'
              ),
            });
            axios
              .get('/api/customproperty/get-custom-ppvc', {
                params: { projectKey: this.props.location.state.sharePointId },
              })
              .then(async (res) => {
                this.combineData(res.data.ppvc);
              })
              .catch(() => {
                message.error('Sync file failed');
              });
          }
        })
        .catch(() => { });
    }
  };
  combineData = (ppvc) => {
    _.forEach(this.moduleData, (item) => {
      let index = _.findIndex(ppvc, (o) => {
        return o.code.toLowerCase() === item.code.toLowerCase();
      });
      if (index >= 0) {
        if (ppvc[index].newCode !== '' && ppvc[index].date) {
          item.actions.push({
            actionName: `Module Installed on ${this.props.location.state.sharePointId}`,
            completedDate: moment(ppvc[index].date, 'DD-MM-YYYY').toDate(),
            currentSiteCode: this.props.location.state.sharePointId,
            currentSiteStorage: null,
          });
        }
      }
    });
    this.setupPieChartPPVC();
  };
  setUpPieChartIssue = () => {
    window.time.startIssue = Date.now();
    axios
      .get('/api/issue/get-dashboard-issue', {
        params: { projectId: this.props.location.state.projectId },
      })
      .then((res) => {
        let projectIssue = [0, 0, 0, 0];
        let issueType = [0, 0, 0, 0];
        let check = false;
        _.forEach(res.data, (v) => {
          check = true;
          let index =
            v.status === 'Open'
              ? 0
              : v.status === 'In progress'
                ? 1
                : v.status === 'Waiting'
                  ? 2
                  : 3;
          projectIssue[index] = projectIssue[index] + 1;
          index =
            v.priority === 'Very urgent'
              ? 0
              : v.priority === 'Urgent'
                ? 1
                : v.priority === 'Normal'
                  ? 2
                  : 3;
          issueType[index] = issueType[index] + 1;
        });

        if (!check) return;
        let total =
          projectIssue[0] + projectIssue[1] + projectIssue[2] + projectIssue[3];
        let tempDataProjectIssue = [
          {
            name: 'Open',
            value: Math.ceil(((projectIssue[0] / total) * 100).toFixed(1)),
            color: '#8E7CC3',
            string: `Open: ${projectIssue[0] === 0 ? 0 : projectIssue[0]
              }/${total} (${parseFloat(
                ((projectIssue[0] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'In progress',
            value: Math.ceil(((projectIssue[1] / total) * 100).toFixed(1)),
            color: '#6FA8DC',
            string: `In progress: ${projectIssue[1] === 0 ? 0 : projectIssue[1]
              }/${total} (${parseFloat(
                ((projectIssue[1] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Waiting',
            value: Math.ceil(((projectIssue[2] / total) * 100).toFixed(1)),
            color: '#93C47D',
            string: `Waiting: ${projectIssue[2] === 0 ? 0 : projectIssue[2]
              }/${total} (${parseFloat(
                ((projectIssue[2] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Close',
            value: Math.ceil(((projectIssue[3] / total) * 100).toFixed(1)),
            color: '#FFD966',
            string: `Close: ${projectIssue[3] === 0 ? 0 : projectIssue[3]
              }/${total} (${parseFloat(
                ((projectIssue[3] / total) * 100).toFixed(1)
              )}%)`,
          },
        ];
        total = issueType[0] + issueType[1] + issueType[2] + issueType[3];
        let tempDataTypeIssue = [
          {
            name: 'Very urgent',
            value: Math.ceil(((issueType[0] / total) * 100).toFixed(1)),
            color: 'red',
            string: `Very urgent: ${issueType[0] === 0 ? 0 : issueType[0]
              }/${total} (${parseFloat(
                ((issueType[0] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Urgent',
            value: Math.ceil(((issueType[1] / total) * 100).toFixed(1)),
            color: 'orange',
            string: `Urgent: ${issueType[1] === 0 ? 0 : issueType[1]
              }/${total} (${parseFloat(
                ((issueType[1] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Normal',
            value: Math.ceil(((issueType[2] / total) * 100).toFixed(1)),
            color: '#f4d90b',
            string: `Normal: ${issueType[2] === 0 ? 0 : issueType[2]
              }/${total} (${parseFloat(
                ((issueType[2] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Low',
            value: Math.ceil(((issueType[3] / total) * 100).toFixed(1)),
            color: 'blue',
            string: `Low: ${issueType[3] === 0 ? 0 : issueType[3]
              }/${total} (${parseFloat(
                ((issueType[3] / total) * 100).toFixed(1)
              )}%)`,
          },
        ];
        window.time.endIssue = Date.now();
        console.log(
          `Start: ${new Date(window.time.startIssue)} | End: ${new Date(
            window.time.endIssue
          )} | Issue Time: `,
          window.time.endIssue - window.time.startIssue
        );
        this.setState({
          dataPieChartIssue: {
            projectIssue: tempDataProjectIssue,
            issueType: tempDataTypeIssue,
          },
        });
      })
      .catch(() => {
        message.warning(`Can't get data`);
      });
  };
  setUpPieChartComment = () => {
    window.time.startComment = Date.now();
    axios
      .get('/api/comment/get-dashboard-issue', {
        params: { projectId: this.props.location.state.projectId },
      })
      .then((res) => {
        let projectComment = [0, 0, 0, 0];
        let commentType = [0, 0, 0, 0];
        let check = false;
        _.forEach(res.data, (v) => {
          check = true;
          let index =
            v.status === 'Open'
              ? 0
              : v.status === 'In progress'
                ? 1
                : v.status === 'Waiting'
                  ? 2
                  : 3;
          projectComment[index] = projectComment[index] + 1;
          index =
            v.priority === 'Very urgent'
              ? 0
              : v.priority === 'Urgent'
                ? 1
                : v.priority === 'Normal'
                  ? 2
                  : 3;
          commentType[index] = commentType[index] + 1;
        });
        if (!check) return;
        let total =
          projectComment[0] +
          projectComment[1] +
          projectComment[2] +
          projectComment[3];
        let tempDataProjectComment = [
          {
            name: 'Open',
            value: Math.ceil(((projectComment[0] / total) * 100).toFixed(1)),
            color: '#8E7CC3',
            string: `Open: ${projectComment[0] === 0 ? 0 : projectComment[0]
              }/${total} (${parseFloat(
                ((projectComment[0] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'In progress',
            value: Math.ceil(((projectComment[1] / total) * 100).toFixed(1)),
            color: '#6FA8DC',
            string: `In progress: ${projectComment[1] === 0 ? 0 : projectComment[1]
              }/${total} (${parseFloat(
                ((projectComment[1] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Waiting',
            value: Math.ceil(((projectComment[2] / total) * 100).toFixed(1)),
            color: '#93C47D',
            string: `Waiting: ${projectComment[2] === 0 ? 0 : projectComment[2]
              }/${total} (${parseFloat(
                ((projectComment[2] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Close',
            value: Math.ceil(((projectComment[3] / total) * 100).toFixed(1)),
            color: '#FFD966',
            string: `Close: ${projectComment[3] === 0 ? 0 : projectComment[3]
              }/${total} (${parseFloat(
                ((projectComment[3] / total) * 100).toFixed(1)
              )}%)`,
          },
        ];
        total =
          commentType[0] + commentType[1] + commentType[2] + commentType[3];
        let tempDataTypeIssue = [
          {
            name: 'Very urgent',
            value: Math.ceil(((commentType[0] / total) * 100).toFixed(1)),
            color: 'red',
            string: `Very urgent: ${commentType[0] === 0 ? 0 : commentType[0]
              }/${total} (${parseFloat(
                ((commentType[0] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Urgent',
            value: Math.ceil(((commentType[1] / total) * 100).toFixed(1)),
            color: 'orange',
            string: `Urgent: ${commentType[1] === 0 ? 0 : commentType[1]
              }/${total} (${parseFloat(
                ((commentType[1] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Normal',
            value: Math.ceil(((commentType[2] / total) * 100).toFixed(1)),
            color: '#f4d90b',
            string: `Normal: ${commentType[2] === 0 ? 0 : commentType[2]
              }/${total} (${parseFloat(
                ((commentType[2] / total) * 100).toFixed(1)
              )}%)`,
          },
          {
            name: 'Low',
            value: Math.ceil(((commentType[3] / total) * 100).toFixed(1)),
            color: 'blue',
            string: `Low: ${commentType[3] === 0 ? 0 : commentType[3]
              }/${total} (${parseFloat(
                ((commentType[3] / total) * 100).toFixed(1)
              )}%)`,
          },
        ];
        window.time.endComment = Date.now();
        console.log(
          `Start: ${new Date(window.time.startComment)} | End: ${new Date(
            window.time.endComment
          )} | Comment Time: `,
          window.time.endComment - window.time.startComment
        );
        this.setState({
          dataPieChartComment: {
            projectComment: tempDataProjectComment,
            commentType: tempDataTypeIssue,
          },
        });
      })
      .catch(() => {
        message.warning(`Can't get data`);
      });
  };
  setupPieChartPPVC = () => {
    window.time.startPrecastLogic = Date.now();
    // let actionMinDate = null;
    // let actionMaxDate = null;
    // let dataPPVC = []
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(
      this.moduleData,
      this.props.location.state.typeProject,
      this.props.location.state.sharePointId
    );
    this.setState(
      {
        loading: false,
        timeValue: {
          min: moment(actionMinDate.getTime()).startOf('months'),
          max: moment(actionMaxDate.getTime()).endOf('months'),
        },
        dataPPVC: dataPPVC,
      },
      () => {
        let tempPPVC =
          this.props.location.state.typeProject === 'PPVC/PBU'
            ? {
              'Installed on Site': [],
              'Delivered to Site': [],
              'Fit Out Completed': [],
              'Delivered to Fit-out Yard': [],
              'Casting Completed': [],
            }
            : {
              'Installed on Site': [],
              'Delivered to Site': [],
              'Casting Completed': [],
            };
        let tempData =
          this.props.location.state.typeProject === 'PPVC/PBU'
            ? {
              'Installed on Site': {},
              'Delivered to Site': {},
              'Fit Out Completed': {},
              'Delivered to Fit-out Yard': {},
              'Casting Completed': {},
            }
            : {
              'Installed on Site': {},
              'Delivered to Site': {},
              'Casting Completed': {},
            };
        let countAll =
          this.props.location.state.typeProject === 'PPVC/PBU'
            ? {
              'Installed on Site': 0,
              'Delivered to Site': 0,
              'Fit Out Completed': 0,
              'Delivered to Fit-out Yard': 0,
              'Casting Completed': 0,
            }
            : {
              'Installed on Site': 0,
              'Delivered to Site': 0,
              'Casting Completed': 0,
            };
        let color =
          this.props.location.state.typeProject === 'PPVC/PBU'
            ? {
              'Installed on Site': '#722ed1',
              'Delivered to Site': '#fa8c16',
              'Fit Out Completed': '#fadb14',
              'Delivered to Fit-out Yard': '#52c41a',
              'Casting Completed': '#1890ff',
            }
            : {
              'Installed on Site': '#722ed1',
              'Delivered to Site': '#fa8c16',
              'Casting Completed': '#1890ff',
            };
        let countPart = {};
        let tempBarChart = {};
        let allDataCount = dataPPVC.length;
        _.forEach(this.state.dataPPVC, (v) => {
          let code = v.mark.split('-')[0];
          if (v.infoData.status !== '' && v.modifiedDate !== null) {
            _.forEach(v.listTime, (list) => {
              // let timeee =this.state.timeValue.min.valueOf()
              if (
                this.state.timeValue.min.valueOf() <= list.time &&
                this.state.timeValue.max.valueOf() >= list.time
              ) {
                let time = moment(list.time).format(this.state.format);
                if (!tempBarChart[time])
                  tempBarChart[time] =
                    this.props.location.state.typeProject === 'PPVC/PBU'
                      ? {
                        'Casting Completed': 0,
                        'Delivered to Fit-out Yard': 0,
                        'Fit Out Completed': 0,
                        'Delivered to Site': 0,
                        'Installed on Site': 0,
                        date: list.time,
                      }
                      : {
                        'Casting Completed': 0,
                        'Delivered to Site': 0,
                        'Installed on Site': 0,
                        date: list.time,
                      };
                if (list.status === 'Casting Completed') {
                  tempBarChart[time]['Casting Completed']++;
                }
                if (
                  this.props.location.state.typeProject === 'PPVC/PBU' &&
                  list.status === 'Delivered to Fit-out Yard'
                ) {
                  tempBarChart[time]['Delivered to Fit-out Yard']++;
                }
                if (
                  this.props.location.state.typeProject === 'PPVC/PBU' &&
                  list.status === 'Fit Out Completed'
                ) {
                  tempBarChart[time]['Fit Out Completed']++;
                }
                if (list.status === 'Delivered to Site') {
                  tempBarChart[time]['Delivered to Site']++;
                }
                if (list.status === 'Installed on Site') {
                  tempBarChart[time]['Installed on Site']++;
                }
              }
            });
          }
          if (!countPart[code]) countPart[code] = 0;
          countPart[code] = countPart[code] + 1;
          if (v.status.includes('Installed on Site')) {
            countAll['Installed on Site']++;

            if (!tempData['Installed on Site'][code])
              tempData['Installed on Site'][code] = 0;
            tempData['Installed on Site'][code] =
              tempData['Installed on Site'][code] + 1;
          }
          if (v.status.includes('Delivered to Site')) {
            countAll['Delivered to Site']++;
            if (!tempData['Delivered to Site'][code])
              tempData['Delivered to Site'][code] = 0;
            tempData['Delivered to Site'][code] =
              tempData['Delivered to Site'][code] + 1;
          }
          if (
            this.props.location.state.typeProject === 'PPVC/PBU' &&
            v.status.includes('Fit Out Completed')
          ) {
            countAll['Fit Out Completed']++;
            if (!tempData['Fit Out Completed'][code])
              tempData['Fit Out Completed'][code] = 0;
            tempData['Fit Out Completed'][code] =
              tempData['Fit Out Completed'][code] + 1;
          }
          if (
            this.props.location.state.typeProject === 'PPVC/PBU' &&
            v.status.includes('Delivered to Fit-out Yard')
          ) {
            countAll['Delivered to Fit-out Yard']++;
            if (!tempData['Delivered to Fit-out Yard'][code])
              tempData['Delivered to Fit-out Yard'][code] = 0;
            tempData['Delivered to Fit-out Yard'][code] =
              tempData['Delivered to Fit-out Yard'][code] + 1;
          }
          if (v.status.includes('Casting Completed')) {
            countAll['Casting Completed']++;
            if (!tempData['Casting Completed'][code])
              tempData['Casting Completed'][code] = 0;
            tempData['Casting Completed'][code] =
              tempData['Casting Completed'][code] + 1;
          }
        });
        _.forEach(tempData, (item, k) => {
          let percentage = parseFloat(
            ((countAll[k] / allDataCount) * 100).toFixed(1)
          );
          tempPPVC[k].push({
            name: k,
            value: percentage,
            color: color[k],
            string: `${k}: ${countAll[k]}/${allDataCount} (${percentage}%)`,
          });
          // _.forEach(item, (v, name) => {
          //   let percentage = parseFloat((v / countPart[name] * 100).toFixed(1));
          //   tempPPVC[k].push({ name: name, value: percentage, color: color[k], string: `${name}: ${v}/${countPart[name]} (${percentage}%)` })
          // })
        });
        let tempBar = [];
        _.forEach(tempBarChart, (v, k) => {
          tempBar.push(
            this.props.location.state.typeProject === 'PPVC/PBU'
              ? {
                time: k,
                'Casting Completed': v['Casting Completed'],
                'Delivered to Fit-out Yard': v['Delivered to Fit-out Yard'],
                'Fit Out Completed': v['Fit Out Completed'],
                'Delivered to Site': v['Delivered to Site'],
                'Installed on Site': v['Installed on Site'],
                date: v.date,
              }
              : {
                time: k,
                'Casting Completed': v['Casting Completed'],
                'Delivered to Site': v['Delivered to Site'],
                'Installed on Site': v['Installed on Site'],
                date: v.date,
              }
          );
        });
        tempBar.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        });
        let tempAccumulative =
          this.props.location.state.typeProject === 'PPVC/PBU'
            ? {
              'Casting Completed Accumulative': 0,
              'Delivered to Fit-out Yard Accumulative': 0,
              'Fit Out Completed Accumulative': 0,
              'Delivered to Site Accumulative': 0,
              'Installed on Site Accumulative': 0,
            }
            : {
              'Casting Completed Accumulative': 0,
              'Delivered to Site Accumulative': 0,
              'Installed on Site Accumulative': 0,
            };
        _.forEach(tempBar, (v) => {
          tempAccumulative['Casting Completed Accumulative'] =
            tempAccumulative['Casting Completed Accumulative'] +
            v['Casting Completed'];
          if (this.props.location.state.typeProject === 'PPVC/PBU') {
            tempAccumulative['Delivered to Fit-out Yard Accumulative'] =
              tempAccumulative['Delivered to Fit-out Yard Accumulative'] +
              v['Delivered to Fit-out Yard'];
            tempAccumulative['Fit Out Completed Accumulative'] =
              tempAccumulative['Fit Out Completed Accumulative'] +
              v['Fit Out Completed'];
          }
          tempAccumulative['Delivered to Site Accumulative'] =
            tempAccumulative['Delivered to Site Accumulative'] +
            v['Delivered to Site'];
          tempAccumulative['Installed on Site Accumulative'] =
            tempAccumulative['Installed on Site Accumulative'] +
            v['Installed on Site'];

          v['Casting Completed Accumulative'] =
            tempAccumulative['Casting Completed Accumulative'];
          if (this.props.location.state.typeProject === 'PPVC/PBU') {
            v['Delivered to Fit-out Yard Accumulative'] =
              tempAccumulative['Delivered to Fit-out Yard Accumulative'];
            v['Fit Out Completed Accumulative'] =
              tempAccumulative['Fit Out Completed Accumulative'];
          }
          v['Delivered to Site Accumulative'] =
            tempAccumulative['Delivered to Site Accumulative'];
          v['Installed on Site Accumulative'] =
            tempAccumulative['Installed on Site Accumulative'];
        });

        window.time.endPrecastLogic = Date.now();
        console.log(
          `Start: ${new Date(window.time.startPrecastLogic)} | End: ${new Date(
            window.time.endPrecastLogic
          )} | Precast Logic Time: `,
          window.time.endPrecastLogic - window.time.startPrecastLogic
        );
        this.setState({
          dataPPVC_Precast: tempPPVC,
          dataPPVC_PrecastBar: tempBar,
          fixDataPPVC_PrecastBar: tempBar,
          loadingUpdate: false,
        });
      }
    );
  };
  handleChartPPVC = (value) => {
    this.setState({ format: value }, () => {
      let temp = {};
      _.forEach(this.state.fixDataPPVC_PrecastBar, (v) => {
        let time = moment(v.date).format(value);
        if (!temp[time])
          temp[time] =
            this.props.location.state.typeProject === 'PPVC/PBU'
              ? {
                'Casting Completed': 0,
                'Delivered to Fit-out Yard': 0,
                'Fit Out Completed': 0,
                'Delivered to Site': 0,
                'Installed on Site': 0,
                'Casting Completed Accumulative': 0,
                'Delivered to Fit-out Yard Accumulative': 0,
                'Fit Out Completed Accumulative': 0,
                'Delivered to Site Accumulative': 0,
                'Installed on Site Accumulative': 0,
                time: time,
                date: v.date,
              }
              : {
                'Casting Completed': 0,
                'Delivered to Site': 0,
                'Installed on Site': 0,
                'Casting Completed Accumulative': 0,
                'Delivered to Site Accumulative': 0,
                'Installed on Site Accumulative': 0,
                time: time,
                date: v.date,
              };
        temp[time]['Casting Completed'] =
          temp[time]['Casting Completed'] + v['Casting Completed'];
        if (this.props.location.state.typeProject === 'PPVC/PBU') {
          temp[time]['Delivered to Fit-out Yard'] =
            temp[time]['Delivered to Fit-out Yard'] +
            v['Delivered to Fit-out Yard'];
          temp[time]['Fit Out Completed'] =
            temp[time]['Fit Out Completed'] + v['Fit Out Completed'];
        }
        temp[time]['Delivered to Site'] =
          temp[time]['Delivered to Site'] + v['Delivered to Site'];
        temp[time]['Installed on Site'] =
          temp[time]['Installed on Site'] + v['Installed on Site'];
      });
      let tempBar = _.values(temp);
      let tempAccumulative =
        this.props.location.state.typeProject === 'PPVC/PBU'
          ? {
            'Casting Completed Accumulative': 0,
            'Delivered to Fit-out Yard Accumulative': 0,
            'Fit Out Completed Accumulative': 0,
            'Delivered to Site Accumulative': 0,
            'Installed on Site Accumulative': 0,
          }
          : {
            'Casting Completed Accumulative': 0,
            'Delivered to Site Accumulative': 0,
            'Installed on Site Accumulative': 0,
          };
      _.forEach(tempBar, (v) => {
        tempAccumulative['Casting Completed Accumulative'] =
          tempAccumulative['Casting Completed Accumulative'] +
          v['Casting Completed'];
        if (this.props.location.state.typeProject === 'PPVC/PBU') {
          tempAccumulative['Delivered to Fit-out Yard Accumulative'] =
            tempAccumulative['Delivered to Fit-out Yard Accumulative'] +
            v['Delivered to Fit-out Yard'];
          tempAccumulative['Fit Out Completed Accumulative'] =
            tempAccumulative['Fit Out Completed Accumulative'] +
            v['Fit Out Completed'];
        }
        tempAccumulative['Delivered to Site Accumulative'] =
          tempAccumulative['Delivered to Site Accumulative'] +
          v['Delivered to Site'];
        tempAccumulative['Installed on Site Accumulative'] =
          tempAccumulative['Installed on Site Accumulative'] +
          v['Installed on Site'];

        v['Casting Completed Accumulative'] =
          tempAccumulative['Casting Completed Accumulative'];
        if (this.props.location.state.typeProject === 'PPVC/PBU') {
          v['Delivered to Fit-out Yard Accumulative'] =
            tempAccumulative['Delivered to Fit-out Yard Accumulative'];
          v['Fit Out Completed Accumulative'] =
            tempAccumulative['Fit Out Completed Accumulative'];
        }
        v['Delivered to Site Accumulative'] =
          tempAccumulative['Delivered to Site Accumulative'];
        v['Installed on Site Accumulative'] =
          tempAccumulative['Installed on Site Accumulative'];
      });

      this.setState({ dataPPVC_PrecastBar: tempBar });
    });
  };
  componentDidMount = () => {
    // let el = document.getElementById('grid_project_detail')
    // let temp = this.state.layout.slice(0)
    // _.forEach(temp, v => {
    //   if (v.i === 'd')
    //     v.h = Math.floor(el.clientHeight / 150)
    // })
    // this.setState({ layout: temp, heightDOM: el.clientHeight }, () => {
    //   if (this.gridRef) {
    //     this.gridRef.setState({ layout: JSON.parse(JSON.stringify(this.state.layout)) });
    //   }
    // })
    // console.log(el.clientHeight)
  };
  handleGoHome = () => {
    this.props.history.push('/');
  };

  onLayoutChange = (layout) => {
    // this.setState({ layout: layout })
  };
  handleExpand = (key, check) => {
    let index = _.findIndex(this.state.layout, (v) => {
      return v.i == key;
    });
    if (index >= 0) {
      this.state.layout[index] = check
        ? {
          i: key,
          x: 0,
          y: 0,
          w: this.state.oldLayout[index].w,
          h: this.state.oldLayout[index].h,
        }
        : {
          i: key,
          x: 0,
          y: 0,
          w: 24,
          h: Math.floor(this.state.heightDOM / 150),
        };
      this.setState({ layout: this.state.layout }, () => {
        if (this.gridRef) {
          this.gridRef.setState({
            layout: JSON.parse(JSON.stringify(this.state.layout)),
          });
        }
      });
    }
  };
  handleClickBar = (status, e) => {
    let temp = [];
    _.forEach(this.state.dataPPVC, (v) => {
      let time = moment(e.time, this.state.format).format('DD-MM-YYYY');
      let index = _.findIndex(v.listTime, (o) => {
        return (
          moment(o.time).format('DD-MM-YYYY') === time && o.status === status
        );
      });
      if (index >= 0) {
        temp.push(v.infoData);
      }
    });
    this.setState({
      showTablePPVC: true,
      tableDetail: temp,
      tableDetailFilterDate: temp,
      tableAll: false,
    });
  };
  handleClickLine = (status, e) => {
    let temp = [];
    _.forEach(this.state.dataPPVC, (v) => {
      let time = moment(e.payload.time, this.state.format).valueOf();
      let index = _.findIndex(v.listTime, (o) => {
        return o.time <= time && o.status === status;
      });
      if (index >= 0) {
        temp.push(v.infoData);
      }
    });
    console.log(temp);
    this.setState({
      showTablePPVC: true,
      tableDetail: temp,
      tableDetailFilterDate: temp,
      tableAll: false,
    });
  };
  handleClickProgressPPVC = (status, e) => {
    let temp = [];
    _.forEach(this.state.dataPPVC, (v) => {
      if (v.status.includes(status)) {
        temp.push(v.infoData);
      }
    });

    this.setState({
      showTablePPVC: true,
      tableDetail: temp,
      tableDetailFilterDate: temp,
      tableAll: true,
    });
  };
  handleRangeDatePPVC = (date) => {
    if (date.length !== 0) {
      let temp = [];
      _.forEach(this.state.tableDetail, (v) => {
        let time = moment(v.date, 'DD/MM/YYYY').valueOf();
        if (date[0].valueOf() <= time && time <= date[1].valueOf()) {
          temp.push(v);
        }
      });
      this.setState({ tableDetailFilterDate: temp });
    } else {
      this.setState({ tableDetailFilterDate: this.state.tableDetail });
    }
  };
  handleDataDMS = (value) => {
    this.setState({ isDataDMSExisting: value });
  };
  handleOpenPickModel = (p) => {
    this.setState({ currentTypeFile: p, isOpenPickModel: true });
  };
  handleIddV2 = (type) => {
    axios
      .post('/api/auth/redirect_token', {
        token: this.props.userInfo.token,
      })
      .then(res => {
        window.location.href=
        `${window.location.host.includes('idd.wohhup.com')?
        `https://iddv2.wohhup.com`: window.location.host.includes('test.bql-app.com')?'https://whidd.com':'http://localhost:3000'}/idd_redirect?token=${res.data.token}&type=${type}&projectId=${this.props.location.state.projectId}`
      })
      .catch(err => {
        console.log(err)
      })
  }
  render() {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            height: 'calc(100% - 53.78px)',
            width: '100%',
          }}
          id='grid_project_detail'
        >
          <Scrollbars
            renderView={renderView}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}
          >
            <ReactGridLayout
              layout={this.state.layout}
              cols={24}
              isBounded={false}
              isDraggable
              ref={(gridLayout) => {
                this.gridRef = gridLayout;
              }}
              draggableHandle='.card-detail-project'
              draggableCancel='.myDragCancel'
              onLayoutChange={this.onLayoutChange}
            >
              {this.state.layout.map((i, k) => (
                <div
                  style={{ border: '1px solid gray' }}
                  key={i.i}
                  data-grid={{
                    x: i.x,
                    y: i.y,
                    w: i.w,
                    h: i.h,
                  }}
                >
                  {i.i === 'd' && (
                    <>
                      <Card
                        className='card-detail-project'
                        headStyle={{
                          backgroundColor: '#a1bbc3',
                          minHeight: 30,
                        }}
                        type='inner'
                        title='DASHBOARD'
                        extra={
                          <IconButton
                            className='myDragCancel'
                            icon={
                              <Icon
                                icon={
                                  i.w === 24 &&
                                    i.h === Math.floor(this.state.heightDOM / 150)
                                    ? 'compress'
                                    : 'expand'
                                }
                              />
                            }
                            size='xs'
                            onClick={this.handleExpand.bind(
                              this,
                              'd',
                              i.w === 24 &&
                              i.h === Math.floor(this.state.heightDOM / 150)
                            )}
                          />
                        }
                        hoverable
                        bodyStyle={{
                          height: 'calc(100% - 30.8px)',
                          width: '100%',
                          position: 'fixed',
                          padding: 5,
                          overflow: 'auto',
                        }}
                      >
                        <div
                          className='myDragCancel'
                          style={{ height: '100%', width: '100%' }}
                        >
                          <Scrollbars
                            renderView={renderView}
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            thumbMinSize={30}
                            universal={true}
                          >
                            {Object.keys(this.state.dataPPVC_Precast).length !==
                              0 && (
                                <>
                                  <div
                                    style={{ color: 'black', marginBottom: 5 }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 'medium',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      PPVC/PRECAST
                                    </span>
                                  </div>
                                  {Object.keys(this.state.dataPPVC_Precast)
                                    .length !== 0 ? (
                                    <>
                                      <Col xs={24} md={4} xl={4}>
                                        <Row gutter={[8, 8]}>
                                          <div>
                                            {Object.keys(
                                              this.state.dataPPVC_Precast
                                            ).map((k) => (
                                              <>
                                                {/* <div style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold', }}>{k}</div> */}
                                                {this.state.dataPPVC_Precast[
                                                  k
                                                ].map((v) => (
                                                  <div
                                                    style={{
                                                      margin: 5,
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={this.handleClickProgressPPVC.bind(
                                                      this,
                                                      k
                                                    )}
                                                  >
                                                    <span>{v.string}</span>
                                                    <Progress
                                                      trailColor='#eee'
                                                      strokeColor={v.color}
                                                      style={{ paddingBottom: 6 }}
                                                      percent={v.value}
                                                      showInfo={false}
                                                    />
                                                  </div>
                                                ))}
                                              </>
                                            ))}
                                          </div>
                                        </Row>
                                        <Divider
                                          style={{ backgroundColor: 'black' }}
                                        />
                                        <Row>
                                          <div
                                            style={{ textAlignLast: 'center' }}
                                          >
                                            {/* {this.state.precastUpdateTime && <div> Updated: {this.state.precastUpdateTime}  
                                          <IconButton loading={this.state.loadingUpdate} size='xs' icon={<Icon icon='refresh' />} onClick={()=>this.setUpPrecastChart(true)} />
                                          </div>} */}
                                            <ButtonGroup>
                                              <Button
                                                type={
                                                  this.state.format === 'DD/MM/YY'
                                                    ? 'primary'
                                                    : ''
                                                }
                                                onClick={this.handleChartPPVC.bind(
                                                  this,
                                                  'DD/MM/YY'
                                                )}
                                              >
                                                Day
                                              </Button>
                                              <Button
                                                type={
                                                  this.state.format === 'MM/YY'
                                                    ? 'primary'
                                                    : ''
                                                }
                                                onClick={this.handleChartPPVC.bind(
                                                  this,
                                                  'MM/YY'
                                                )}
                                              >
                                                Month
                                              </Button>
                                              <Button
                                                type={
                                                  this.state.format === 'YYYY'
                                                    ? 'primary'
                                                    : ''
                                                }
                                                onClick={this.handleChartPPVC.bind(
                                                  this,
                                                  'YYYY'
                                                )}
                                              >
                                                Year
                                              </Button>
                                            </ButtonGroup>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col xs={24} md={20} xl={20}>
                                        <Row>
                                          {this.state.dataPPVC_PrecastBar
                                            .legend !== 0 && (
                                              <div
                                                style={{
                                                  width: '100%',
                                                  height: 300,
                                                  marginBottom: 10,
                                                }}
                                              >
                                                <ResponsiveContainer>
                                                  <BarChart
                                                    syncId='anyId'
                                                    data={
                                                      this.state.dataPPVC_PrecastBar
                                                    }
                                                    margin={{
                                                      top: 5,
                                                      right: 5,
                                                      left: 5,
                                                      bottom: 5,
                                                    }}
                                                  // barGap={50}
                                                  // barCategoryGap={1}
                                                  >
                                                    <CartesianGrid strokeDasharray='3 3' />
                                                    <XAxis dataKey='time' />
                                                    <YAxis />
                                                    <TooltipChart />
                                                    {/* <Legend /> */}
                                                    <ReferenceLine
                                                      y={0}
                                                      stroke='#000'
                                                    />
                                                    <Brush
                                                      dataKey='time'
                                                      height={30}
                                                      stroke='#8884d8'
                                                    />
                                                    <Bar
                                                      dataKey='Casting Completed'
                                                      fill='#1890ff'
                                                      onClick={this.handleClickBar.bind(
                                                        this,
                                                        'Casting Completed'
                                                      )}
                                                    />
                                                    {this.props.location.state
                                                      .typeProject ===
                                                      'PPVC/PBU' && (
                                                        <Bar
                                                          dataKey='Delivered to Fit-out Yard'
                                                          fill='#52c41a'
                                                          onClick={this.handleClickBar.bind(
                                                            this,
                                                            'Delivered to Fit-out Yard'
                                                          )}
                                                        />
                                                      )}
                                                    {this.props.location.state
                                                      .typeProject ===
                                                      'PPVC/PBU' && (
                                                        <Bar
                                                          dataKey='Fit Out Completed'
                                                          fill='#fadb14'
                                                          onClick={this.handleClickBar.bind(
                                                            this,
                                                            'Fit Out Completed'
                                                          )}
                                                        />
                                                      )}
                                                    <Bar
                                                      dataKey='Delivered to Site'
                                                      fill='#fa8c16'
                                                      onClick={this.handleClickBar.bind(
                                                        this,
                                                        'Delivered to Site'
                                                      )}
                                                    />
                                                    <Bar
                                                      dataKey='Installed on Site'
                                                      fill='#722ed1'
                                                      onClick={this.handleClickBar.bind(
                                                        this,
                                                        'Installed on Site'
                                                      )}
                                                    />
                                                  </BarChart>
                                                </ResponsiveContainer>
                                              </div>
                                            )}
                                        </Row>
                                        <Row>
                                          {this.state.dataPPVC_PrecastBar
                                            .legend !== 0 && (
                                              <div
                                                style={{
                                                  width: '100%',
                                                  height: 300,
                                                  marginBottom: 10,
                                                }}
                                              >
                                                <ResponsiveContainer>
                                                  <LineChart
                                                    syncId='anyId'
                                                    data={
                                                      this.state.dataPPVC_PrecastBar
                                                    }
                                                    margin={{
                                                      top: 5,
                                                      right: 5,
                                                      left: 5,
                                                      bottom: 5,
                                                    }}
                                                  >
                                                    <CartesianGrid strokeDasharray='3 3' />
                                                    <XAxis dataKey='time' />
                                                    <YAxis />
                                                    <TooltipChart />
                                                    {/* <Legend /> */}
                                                    <ReferenceLine
                                                      y={0}
                                                      stroke='#000'
                                                    />
                                                    {/* <Brush dataKey='time' height={30} stroke="#8884d8" /> */}
                                                    <Line
                                                      dataKey='Casting Completed Accumulative'
                                                      stroke='#1890ff'
                                                      dot={<></>}
                                                      activeDot={{
                                                        onClick:
                                                          this.handleClickLine.bind(
                                                            this,
                                                            'Casting Completed'
                                                          ),
                                                      }}
                                                    />
                                                    {this.props.location.state
                                                      .typeProject ===
                                                      'PPVC/PBU' && (
                                                        <Line
                                                          dataKey='Delivered to Fit-out Yard Accumulative'
                                                          stroke='#52c41a'
                                                          dot={<></>}
                                                          activeDot={{
                                                            onClick:
                                                              this.handleClickLine.bind(
                                                                this,
                                                                'Delivered to Fit-out Yard'
                                                              ),
                                                          }}
                                                        />
                                                      )}
                                                    {this.props.location.state
                                                      .typeProject ===
                                                      'PPVC/PBU' && (
                                                        <Line
                                                          dataKey='Fit Out Completed Accumulative'
                                                          stroke='#fadb14'
                                                          dot={<></>}
                                                          activeDot={{
                                                            onClick:
                                                              this.handleClickLine.bind(
                                                                this,
                                                                'Fit Out Completed'
                                                              ),
                                                          }}
                                                        />
                                                      )}
                                                    <Line
                                                      dataKey='Delivered to Site Accumulative'
                                                      stroke='#fa8c16'
                                                      dot={<></>}
                                                      activeDot={{
                                                        onClick:
                                                          this.handleClickLine.bind(
                                                            this,
                                                            'Delivered to Site'
                                                          ),
                                                      }}
                                                    />
                                                    <Line
                                                      dataKey='Installed on Site Accumulative'
                                                      stroke='#722ed1'
                                                      dot={<></>}
                                                      activeDot={{
                                                        onClick:
                                                          this.handleClickLine.bind(
                                                            this,
                                                            'Installed on Site'
                                                          ),
                                                      }}
                                                    />
                                                  </LineChart>
                                                </ResponsiveContainer>
                                              </div>
                                            )}
                                        </Row>
                                      </Col>
                                    </>
                                  ) : (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      description='No data for PPVC/Precast'
                                    />
                                  )}
                                  <Divider
                                    style={{
                                      backgroundColor: 'black',
                                      marginBottom: 5,
                                    }}
                                  />
                                </>
                              )}
                            {this.state.isDataDMSExisting &&
                              checkWohhupUser(this.props.userInfo.email) && (
                                <>
                                  <div
                                    style={{ color: 'black', marginBottom: 5 }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 'medium',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      DRAWING
                                    </span>
                                    <DashboardDrawingProjectCombine
                                      projectId={
                                        this.props.location.state.projectId
                                      }
                                      projectName={
                                        this.props.location.state.projectName
                                      }
                                      callback={this.handleDataDMS}
                                    />
                                  </div>
                                  <Divider
                                    style={{
                                      backgroundColor: 'black',
                                      marginBottom: 5,
                                    }}
                                  />
                                </>
                              )}

                            {(this.state.dataPieChartIssue.issueType ||
                              this.state.dataPieChartIssue.projectIssue ||
                              this.state.dataPieChartComment.projectComment ||
                              this.state.dataPieChartComment.commentType) && (
                                <>
                                  <div
                                    style={{ color: 'black', marginBottom: 5 }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 'medium',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      BIM APP
                                    </span>
                                  </div>
                                  <Row gutter={[8, 8]}>
                                    <Col xs={24} md={10} xl={6}>
                                      {this.state.dataPieChartIssue
                                        .projectIssue ? (
                                        <>
                                          <div
                                            style={{
                                              fontSize: '18px',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Design Issue Status
                                          </div>
                                          {this.state.dataPieChartIssue.projectIssue.map(
                                            (v) => (
                                              <div style={{ margin: 5 }}>
                                                <span>{v.string}</span>
                                                <Progress
                                                  trailColor='#eee'
                                                  strokeColor={v.color}
                                                  style={{ paddingBottom: 6 }}
                                                  percent={v.value}
                                                  showInfo={false}
                                                />
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description='No data for Project Issue'
                                        />
                                      )}
                                    </Col>
                                    <Col xs={24} md={10} xl={6}>
                                      {this.state.dataPieChartIssue.issueType ? (
                                        <>
                                          <div
                                            style={{
                                              fontSize: '18px',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Design Issue Type
                                          </div>
                                          {this.state.dataPieChartIssue.issueType.map(
                                            (v) => (
                                              <div style={{ margin: 5 }}>
                                                <span>{v.string}</span>
                                                <Progress
                                                  trailColor='#eee'
                                                  strokeColor={v.color}
                                                  style={{ paddingBottom: 6 }}
                                                  percent={v.value}
                                                  showInfo={false}
                                                />
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description='No data for Issue Type'
                                        />
                                      )}
                                    </Col>
                                    <Col xs={24} md={10} xl={6}>
                                      {this.state.dataPieChartComment
                                        .projectComment ? (
                                        <>
                                          <div
                                            style={{
                                              fontSize: '18px',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Design Comment Status
                                          </div>
                                          {this.state.dataPieChartComment.projectComment.map(
                                            (v) => (
                                              <div style={{ margin: 5 }}>
                                                <span>{v.string}</span>
                                                <Progress
                                                  trailColor='#eee'
                                                  strokeColor={v.color}
                                                  style={{ paddingBottom: 6 }}
                                                  percent={v.value}
                                                  showInfo={false}
                                                />
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description='No data for Project Comment'
                                        />
                                      )}
                                    </Col>
                                    <Col xs={24} md={10} xl={6}>
                                      {this.state.dataPieChartComment
                                        .commentType ? (
                                        <>
                                          <div
                                            style={{
                                              fontSize: '18px',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Design Comment Type
                                          </div>
                                          {this.state.dataPieChartComment.commentType.map(
                                            (v) => (
                                              <div style={{ margin: 5 }}>
                                                <span>{v.string}</span>
                                                <Progress
                                                  trailColor='#eee'
                                                  strokeColor={v.color}
                                                  style={{ paddingBottom: 6 }}
                                                  percent={v.value}
                                                  showInfo={false}
                                                />
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description='No data for Comment Type'
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              )}
                          </Scrollbars>
                        </div>
                      </Card>
                    </>
                  )}
                  {i.i === 'a' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app to view, review, extract quantity from model and Bim 4D,5D'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 15,
                          }}
                          type='inner'
                          title='BIM APP'
                          // extra={<IconButton icon={<Icon icon={(i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)) ? 'compress' : 'expand'} />} size='xs'
                          //   onClick={this.handleExpand.bind(this, 'a', (i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)))} />}
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessBimApp || this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  //        top: 'calc(50% - 35px)',
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/projects/project/`,
                                  state: {
                                    userCreatedProject:
                                      this.props.location.state
                                        .userCreatedProject,
                                    projectId:
                                      this.props.location.state.projectId,
                                    projectName:
                                      this.props.location.state.projectName,
                                    sharePointId:
                                      this.props.location.state.sharePointId,
                                    issueKey:
                                      this.props.location.state.issueKey,
                                    trimbleKey:
                                      this.props.location.state.trimbleKey,
                                    typeProject:
                                      this.props.location.state.typeProject,
                                    useRfa: this.props.location.state.useRfa,
                                    isDetail: true,
                                  },
                                }}
                                style={{
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                }}
                              >
                                <Icon icon='cube' size='2x' />
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'b' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app to record and manage, stored and search document'
                        overlayStyle={{ zIndex: 999 }}
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 15,
                          }}
                          type='inner'
                          title='DMS'
                          // extra={<IconButton icon={<Icon icon={(i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)) ? 'compress' : 'expand'} />} size='xs'
                          //   onClick={this.handleExpand.bind(this, 'c', (i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)))} />}
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessDashboardDrawing ||
                              this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Popover
                                placement='leftTop'
                                title={'DMS'}
                                overlayStyle={{ zIndex: 1000 }}
                                content={
                                  <>
                                    {this.state.role !== 'Consultant' &&
                                      this.state.role !== 'Client' && (
                                        <>
                                          <Button
                                            block
                                            ghost
                                            style={{
                                              marginBottom: 3,
                                              width: 200,
                                            }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: `/dms/spreadsheet`,
                                                state: {
                                                  userCreatedProject:
                                                    this.props.location.state
                                                      .userCreatedProject,
                                                  projectId:
                                                    this.props.location.state
                                                      .projectId,
                                                  projectName:
                                                    this.props.location.state
                                                      .projectName,
                                                  sharePointId:
                                                    this.props.location.state
                                                      .sharePointId,
                                                  issueKey:
                                                    this.props.location.state
                                                      .issueKey,
                                                  trimbleKey:
                                                    this.props.location.state
                                                      .trimbleKey,
                                                  typeProject:
                                                    this.props.location.state
                                                      .typeProject,
                                                  useRfa:
                                                    this.props.location.state
                                                      .useRfa,
                                                  isDetail: true,
                                                },
                                              });
                                            }}
                                            type={'primary'}
                                          >
                                            Drawing Management
                                          </Button>
                                          <br />
                                        </>
                                      )}

                                    {this.props.location.state.useRfa && //this.props.location.state.projectName.toLowerCase().includes('test')
                                      [
                                        'sum',
                                        'rfa',
                                        'rfam',
                                        'rfi',
                                        'cvi',
                                        'dt',
                                        'mm',
                                        'eri',
                                        'lr',
                                        'sm',
                                        'qr',
                                        'qrm',
                                        'rfc',
                                        'authorities',
                                      ].map((p) => (
                                        <>
                                          <Button
                                            block
                                            ghost
                                            type={'primary'}
                                            style={{ width: 200 }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: `/dms/${p === 'authorities'
                                                  ? 'authorities-submission'
                                                  : p
                                                  }`,
                                                state: {
                                                  userCreatedProject:
                                                    this.props.location.state
                                                      .userCreatedProject,
                                                  projectId:
                                                    this.props.location.state
                                                      .projectId,
                                                  projectName:
                                                    this.props.location.state
                                                      .projectName,
                                                  sharePointId:
                                                    this.props.location.state
                                                      .sharePointId,
                                                  issueKey:
                                                    this.props.location.state
                                                      .issueKey,
                                                  trimbleKey:
                                                    this.props.location.state
                                                      .trimbleKey,
                                                  typeProject:
                                                    this.props.location.state
                                                      .typeProject,
                                                  useRfa:
                                                    this.props.location.state
                                                      .useRfa,
                                                  isDetail: true,
                                                },
                                              });
                                            }}
                                          >
                                            {p.toUpperCase()}
                                          </Button>
                                          <br />
                                        </>
                                      ))}
                                  </>
                                }
                                trigger='click'
                              >
                                <Link
                                  style={{
                                    left: 'calc(50% - 15px)',
                                    position: 'absolute',
                                  }}
                                >
                                  <Icon icon='table' size='2x' />
                                </Link>
                              </Popover>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'c' && (
                    <>
                      <Tooltip
                        placement='left'
                        title='Coordination Interface Programe for Civil project'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 15,
                          }}
                          type='inner'
                          title='CIP (BETA)'
                          // extra={(this.state.isAccessGantt || this.props.isAdmin) &&
                          //   <IconButton icon={<Icon icon={(i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)) ? 'compress' : 'expand'} />} size='xs'
                          //     onClick={this.handleExpand.bind(this, 'b', (i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)))} />}
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {true ? ( //!(this.state.isAccessGantt || this.props.isAdmin )
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/gantt/${this.props.location.state.projectId}`,
                                  state: {
                                    userCreatedProject:
                                      this.props.location.state
                                        .userCreatedProject,
                                    projectId:
                                      this.props.location.state.projectId,
                                    projectName:
                                      this.props.location.state.projectName,
                                    sharePointId:
                                      this.props.location.state.sharePointId,
                                    issueKey:
                                      this.props.location.state.issueKey,
                                    trimbleKey:
                                      this.props.location.state.trimbleKey,
                                    typeProject:
                                      this.props.location.state.typeProject,
                                    useRfa: this.props.location.state.useRfa,
                                    isDetail: true,
                                  },
                                }}
                                style={{
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                }}
                              >
                                <Icon icon='tasks' size='2x' />
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'e' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app use to key in data'
                        overlayStyle={{ zIndex: 999 }}
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='DATA ENTRY(BETA)'
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessDataEntry || this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Popover
                                placement='left'
                                title={'DATA ENTRY'}
                                overlayStyle={{ zIndex: 1000 }}
                                content={
                                  <>
                                    {['do-invoice'].map((p) => (
                                      <>
                                        <Button
                                          block
                                          ghost
                                          type={'primary'}
                                          style={{ width: 200 }}
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: `/dataentry/${p}`,
                                              state: {
                                                userCreatedProject:
                                                  this.props.location.state
                                                    .userCreatedProject,
                                                projectId:
                                                  this.props.location.state
                                                    .projectId,
                                                projectName:
                                                  this.props.location.state
                                                    .projectName,
                                                sharePointId:
                                                  this.props.location.state
                                                    .sharePointId,
                                                issueKey:
                                                  this.props.location.state
                                                    .issueKey,
                                                trimbleKey:
                                                  this.props.location.state
                                                    .trimbleKey,
                                                typeProject:
                                                  this.props.location.state
                                                    .typeProject,
                                                useRfa:
                                                  this.props.location.state
                                                    .useRfa,
                                                isDetail: true,
                                              },
                                            });
                                          }}
                                        >
                                          {p === 'for5d'
                                            ? 'For 5D'
                                            : p.toUpperCase()}
                                        </Button>
                                        <br />
                                      </>
                                    ))}
                                  </>
                                }
                                trigger='click'
                              >
                                <Link
                                  style={{
                                    left: 'calc(50% - 15px)',
                                    position: 'absolute',
                                  }}
                                >
                                  <Icon icon='edit' size='2x' />
                                </Link>
                              </Popover>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'f' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app use to view and reply issue/comment'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='COORDINATION'
                          hoverable
                          bodyStyle={{
                            height: 'calc(100% - 30.8px)',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessBimApp || this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/issueapp`,
                                  state: {
                                    userCreatedProject:
                                      this.props.location.state
                                        .userCreatedProject,
                                    projectId:
                                      this.props.location.state.projectId,
                                    projectName:
                                      this.props.location.state.projectName,
                                    sharePointId:
                                      this.props.location.state.sharePointId,
                                    issueKey:
                                      this.props.location.state.issueKey,
                                    trimbleKey:
                                      this.props.location.state.trimbleKey,
                                    typeProject:
                                      this.props.location.state.typeProject,
                                    useRfa: this.props.location.state.useRfa,
                                    isDetail: true,
                                  },
                                }}
                                style={{
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'g' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app to record and manage worker activity and time sheet'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='Worker (Beta)'
                          // extra={<IconButton icon={<Icon icon={(i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)) ? 'compress' : 'expand'} />} size='xs'
                          //   onClick={this.handleExpand.bind(this, 'a', (i.w === 24 && i.h === Math.floor(this.state.heightDOM / 150)))} />}
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessWorkerAllocation ||
                              this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/worker/setup`,
                                  state: {
                                    userCreatedProject:
                                      this.props.location.state
                                        .userCreatedProject,
                                    projectId:
                                      this.props.location.state.projectId,
                                    projectName:
                                      this.props.location.state.projectName,
                                    sharePointId:
                                      this.props.location.state.sharePointId,
                                    issueKey:
                                      this.props.location.state.issueKey,
                                    trimbleKey:
                                      this.props.location.state.trimbleKey,
                                    typeProject:
                                      this.props.location.state.typeProject,
                                    useRfa: this.props.location.state.useRfa,
                                    isDetail: true,
                                  },
                                }}
                                style={{
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                }}
                              >
                                <Icon icon='group' size='2x' />
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'h' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app use for 5D'
                        overlayStyle={{ zIndex: 999 }}
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='5D (BETA)'
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessDataEntry || this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Popover
                                placement='left'
                                title={'5D'}
                                overlayStyle={{ zIndex: 1000 }}
                                content={
                                  <>
                                    {[
                                      'table',
                                      'progress-report',
                                      'quantity-tracking',
                                      'library',
                                      'table-forcast',
                                    ].map((p) => (
                                      <>
                                        <Button
                                          block
                                          ghost
                                          type={'primary'}
                                          style={{ width: 200 }}
                                          onClick={() => {
                                            this.props.history.push({
                                              pathname: `/for5d/${p}`,
                                              state: {
                                                userCreatedProject:
                                                  this.props.location.state
                                                    .userCreatedProject,
                                                projectId:
                                                  this.props.location.state
                                                    .projectId,
                                                projectName:
                                                  this.props.location.state
                                                    .projectName,
                                                sharePointId:
                                                  this.props.location.state
                                                    .sharePointId,
                                                issueKey:
                                                  this.props.location.state
                                                    .issueKey,
                                                trimbleKey:
                                                  this.props.location.state
                                                    .trimbleKey,
                                                typeProject:
                                                  this.props.location.state
                                                    .typeProject,
                                                useRfa:
                                                  this.props.location.state
                                                    .useRfa,
                                                isDetail: true,
                                              },
                                            });
                                          }}
                                        >
                                          {p.toUpperCase()}
                                        </Button>
                                        <br />
                                      </>
                                    ))}
                                    {this.props.location.state.typeProject ===
                                      'PPVC/PBU' && (
                                        <>
                                          <Button
                                            block
                                            ghost
                                            type={'primary'}
                                            style={{ width: 200 }}
                                            onClick={this.handleOpenPickModel.bind(
                                              this,
                                              'ppvc'
                                            )}
                                          >
                                            PPVC
                                          </Button>
                                          <br />
                                        </>
                                      )}
                                    {this.props.location.state.typeProject ===
                                      'Precast' && (
                                        <>
                                          <Button
                                            block
                                            ghost
                                            type={'primary'}
                                            style={{ width: 200 }}
                                            onClick={this.handleOpenPickModel.bind(
                                              this,
                                              'precast'
                                            )}
                                          >
                                            Precast
                                          </Button>
                                          <br />
                                        </>
                                      )}
                                    {['5d'].map((p) => (
                                      <>
                                        <Button
                                          block
                                          ghost
                                          type={'primary'}
                                          style={{ width: 200 }}
                                          onClick={this.handleOpenPickModel.bind(
                                            this,
                                            p
                                          )}
                                        >
                                          {p.toUpperCase()}
                                        </Button>
                                        <br />
                                      </>
                                    ))}
                                  </>
                                }
                                trigger='click'
                              >
                                <Link
                                  style={{
                                    left: 'calc(50% - 15px)',
                                    position: 'absolute',
                                  }}
                                >
                                  <Icon size='2x'>5D</Icon>
                                </Link>
                              </Popover>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'k' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                        title='The app to record and manage worker activity and time sheet'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='QA/QC (Beta)'
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                          >
                            {!(
                              this.state.isAccessQAQC || this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/qaqc`,
                                  state: {
                                    userCreatedProject:
                                      this.props.location.state
                                        .userCreatedProject,
                                    projectId:
                                      this.props.location.state.projectId,
                                    projectName:
                                      this.props.location.state.projectName,
                                    sharePointId:
                                      this.props.location.state.sharePointId,
                                    issueKey:
                                      this.props.location.state.issueKey,
                                    trimbleKey:
                                      this.props.location.state.trimbleKey,
                                    typeProject:
                                      this.props.location.state.typeProject,
                                    useRfa: this.props.location.state.useRfa,
                                    isDetail: true,
                                  },
                                }}
                                style={{
                                  left: 'calc(50% - 43px)',
                                  position: 'absolute',
                                }}
                              >
                                <Icon size='2x'>QA/QC</Icon>
                              </Link>
                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                  {i.i === 'l' && (
                    <>
                      <Tooltip
                        placement='leftBottom'
                      // title='The app to record and manage worker activity and time sheet'
                      >
                        <Card
                          className='card-detail-project'
                          headStyle={{
                            backgroundColor: '#a1bbc3',
                            minHeight: 30,
                          }}
                          type='inner'
                          title='Digicheck (Beta)'
                          hoverable
                          bodyStyle={{
                            height: '100%',
                            width: '100%',
                            position: 'fixed',
                            padding: 5,
                            overflow: 'hidden',
                          }}
                          style={{ cursor: 'default' }}
                        >
                          <div
                            className='myDragCancel'
                            style={{ height: '100%', width: '100%' }}
                            onClick={this.handleIddV2.bind(this, 'digicheck')}
                          >
                            {!(

                              this.props.isAdmin
                            ) ? (
                              <div
                                style={{
                                  top: 'calc(50% - 30px)',
                                  left: 'calc(50% - 15px)',
                                  position: 'absolute',
                                  color: 'orange',
                                }}
                              >
                                <Icon icon='exclamation-triangle' size='2x' />
                              </div>
                            ) : (
                              <div  >
                                <Icon style={{ color: '#223ffe' }} size='2x' >Digicheck</Icon>
                              </div>

                            )}
                          </div>
                        </Card>
                      </Tooltip>
                    </>
                  )}
                </div>
              ))}
            </ReactGridLayout>
          </Scrollbars>
        </div>

        {this.props.isAdmin ||
          this.state.listAdmin.includes(this.props.userInfo.email) ? (
          <ButtonMenuProjectDetail
            key={this.props.location.state.projectId}
            project={this.props.location.state}
            userCreatedProject={this.props.location.state.userCreatedProject}
            email={this.props.userInfo.email}
          />
        ) : (
          <Popover
            placement='leftBottom'
            content={this.state.listAdmin.map((i) => (
              <Text>{i}</Text>
            ))}
            title='Project Admin'
          >
            <Fab
              aria-label={'Upload File'}
              style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}
            >
              <ContactSupportIcon />
            </Fab>
          </Popover>
        )}

        {this.state.showTablePPVC && (
          <Modal
            title={this.props.location.state.typeProject}
            visible={true}
            onCancel={() => this.setState({ showTablePPVC: false })}
            footer={<></>}
            width={'60%'}
          >
            <MaterialTable
              title={
                this.state.tableAll && (
                  <RangePicker
                    onChange={this.handleRangeDatePPVC}
                    format={'DD/MM/YYYY'}
                  />
                )
              }
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Length', field: 'length' },
                { title: 'Height', field: 'height' },
                { title: 'Width', field: 'width' },
                { title: 'Volume', field: 'volume' },
                { title: 'Tekla Volume', field: 'teklaVolume' },
                { title: 'Rebar Weight', field: 'rebarWeightKg' },
                // { title: 'Status', field: 'status' },
                // { title: 'Date', field: 'date' },
                { title: 'Site Name', field: 'siteName' },
                { title: 'Casting Date', field: 'castingDate' },
                { title: 'Delivery Date', field: 'deliveryDate' },
                { title: 'Install Date', field: 'installDate' },
              ]}
              data={this.state.tableDetailFilterDate}
              options={{
                exportButton: true,
                exportAllData: true,
                pageSize: 10,
                pageSizeOptions: [10],
                showTitle: true,
              }}
            />
          </Modal>
        )}
        {this.state.isOpenPickModel && (
          <PickModel
            projectId={this.props.location.state.projectId}
            role={this.state.role}
            userInfo={this.props.userInfo}
            typeFile={this.state.currentTypeFile}
            state={{
              userCreatedProject: this.props.location.state.userCreatedProject,
              projectId: this.props.location.state.projectId,
              projectName: this.props.location.state.projectName,
              sharePointId: this.props.location.state.sharePointId,
              issueKey: this.props.location.state.issueKey,
              trimbleKey: this.props.location.state.trimbleKey,
              typeProject: this.props.location.state.typeProject,
              useRfa: this.props.location.state.useRfa,
              isDetail: true,
            }}
            history={this.props.history}
            handleClose={() => {
              this.setState({ isOpenPickModel: false });
            }}
          />
        )}
      </>
    );
  }
}

// const HomePage = () =>

ProjectDetail.propTypes = {
  userInfo: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  storeProjectDetail: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    userInfo: state.user,
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
  };
}

export default connect(mapStateToProps, {
  logout: actions.logout,
  storeProjectDetail: storeProjectDetail,
})(ProjectDetail);
