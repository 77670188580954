import React, { useState } from "react";
import { Button, Upload, Progress, Divider, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
const FormData = require("form-data");
const { Dragger } = Upload;

const ExtractDataConcDO = ({ current, generalState, setGeneralState, setTempData }) => {
   const [listFiles, setlistFiles] = useState([]);
   const [percent, setPercent] = useState(0);
   const [temp, setTemp] = useState([]);
   const [s3rds, setS3Rds] = useState([]);
   const [status, setStatus] = useState();
   dayjs.extend(customParseFormat);
   function parseDate(input) {
      // trimes and remove multiple spaces and split by expected characters
      var d = null;
      var format = /\u2010/;
      if (format.test(input)) {
         var parts = input.split(/[\u2010]/g);
         const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
         const day = Number(parts[0]);
         const month = Number(months.indexOf(parts[1].toLowerCase())) + 1;
         const year = Number(parts[2]);
         d = new dayjs([year, month, day]).format("DD-MM-YYYY");
      }
      return d; // Note: months are 0-based
   }

   const func_Process_Conc_DO = async () => {
      const projectId = localStorage.getItem("fp_project_id");
      const increase = 90 / listFiles.length;
      let lists3 = [];
      var no_of_file_exist = 0;
      setStatus("active");
      var progress = 10;
      setPercent(10);

      const files = listFiles.map(async (file) => {
         let key_file = file.name;
         let formData = new FormData();
         return new Promise((data, err1) => {
            formData.append("form_file", file.originFileObj, key_file);
            let url = "/api/do_inv/formparser/concdo";

            axios
               .post(url, formData, {
                  headers: {
                     "content-type": "multipart/form-data",
                  },
               })
               .then((re) => {
                  let rec = re.data.output.fields;
                  // check if element has already exist on server

                  axios
                     .get("/api/do_inv/common/checkexist", {
                        params: {
                           do_number: rec["Do Number"].value,
                           current: "conc_do",
                        },
                     })
                     .then(async (res1) => {
                        //create new entity and save back to tempData
                        let entity = {
                           id: uuidv4(),
                           do_number: rec["Do Number"].value,
                           grade: "",
                           product: rec["Grade"].value,
                           volume: rec["Concrete Volume"].value,
                           supplier: checkSupplierName(rec["Supplier"].value),
                           remark: rec["Remark"].value,
                           project_id: localStorage.getItem("fp_project_id"),
                           checks: res1.data.data.code,
                           filename: file.name,
                        };
                        if (res1.data.data.code === "exist") {
                           no_of_file_exist = no_of_file_exist + 1;
                        }

                        const try_get_do_date = parseDate(rec["Delivery Date"].value);
                        entity.do_date = try_get_do_date;
                        temp.push(entity);

                        //list rds
                        if (res1.data.data.code !== "exist") {
                           const fn = (projectId + "_conc_do_" + rec["Do Number"].value + ".pdf").toLowerCase();
                           let r = {
                              id: uuidv4(),
                              form_type: current,
                              s3_key: fn,
                              project_id: projectId,
                              checks: "ok",
                           };
                           lists3.push(r);
                           setS3Rds(lists3);
                        }

                        progress = progress + increase;
                        setPercent(progress);
                        setTemp(temp);

                        if (Math.abs(100 - progress) < 1) {
                           setStatus("success");
                           if (no_of_file_exist > 0) {
                              message.success("Processing data has finished!", 3);
                              message.warn(
                                 `There are ${no_of_file_exist} DO has already exist, it won't import to system`,
                                 4
                              );
                           } else {
                              message.success("Processing data has finished!");
                           }
                        }
                        if (temp.length === files.length) {
                           await setTempData(temp);
                        }
                     });
               });
         });
      });

      await Promise.all([files]);
      return;
   };
   const func_Apply_Conc_DO = async () => {
      // apply data to RDS
      const project_id = localStorage.getItem("fp_project_id");
      const increase = 90 / temp.length;
      var progress = 10;
      var conc_do_list = [];
      temp.forEach((e) => {
         if (e.do_number !== undefined && e.do_number !== "" && e.checks !== "exist") {
            let conc_do = {
               id: uuidv4(),
               project_id: project_id,
               do_number: e.do_number,
               do_date: e.do_date,
               volume: e.volume,
               pour_name: e.pour_name,
               order_by: e.order_by,
               product: e.product,
               supplier: e.supplier,
               s3_key: (project_id + "_conc_do_" + e.do_number + ".pdf").toLowerCase(),
               remark: e.remark,
            };
            conc_do_list.push(conc_do);
         }
      });

      const res = await axios.post("/api/do_inv/common/multi", {
         filtterd_array: conc_do_list,
         current: "conc_do",
      });

      //upload to S3 RDS
      await axios.post("/api/do_inv/s3rds/multi", s3rds);

      // upload file to S3
      const files = listFiles.map(async (file) => {
         const do_number_of_this_file = temp.find((e) => e.filename === file.name).do_number;
         const pj_fille_name = project_id + "_" + "conc_do" + "_" + do_number_of_this_file + ".pdf";
         const key = `${project_id}/${"conc_do"}/${pj_fille_name}`;
         return new Promise((data, err1) => {
            axios
               .get("/api/do_inv/s3/putlink", {
                  params: { fileName: key.toLowerCase(), formType: "conc_do" },
               })
               .then((res) => {
                  const signedrequesturl = res.data.signedRequest;
                  axios
                     .put(signedrequesturl, file.originFileObj, {
                        headers: {
                           "Content-Type": "application/pdf",
                        },
                     })
                     .then((result) => {
                        progress = progress + increase;
                        setPercent(progress);
                        if (progress === 100) {
                           message.success("File has uploaded.");
                        }
                     })
                     .catch((err) => {
                        console.log(err);
                     });
               });
         });
      });

      let resulting_files = await Promise.all(files);
   };

   const checkSupplierName = (original_name) => {
      if (typeof original_name !== "undefined") {
         var new_name = original_name.toUpperCase();
         if (new_name.includes("ALLIA")) {
            new_name = "ALLIANCE";
         }
         if (new_name.includes("PAN")) {
            new_name = "PAN UNITED";
         }
         if (new_name.includes("ISLAND")) {
            new_name = "ISLAND";
         }
         return new_name;
      }
   };

   const handleProcess = async (e) => {
      e.preventDefault();

      var msg = "";
      if (listFiles.length > 0) {
         switch (current) {
            case "conc_do":
               msg = func_Process_Conc_DO();
               break;
         }

         if (msg !== "") {
            // alert(msg);
         }
      } else {
         alert("Ay oh, select file first lah!");
      }
   };

   // parse a date time that can contains spaces, dashes, slashes, colons

   const handleApply = async (e) => {
      e.preventDefault();

      if (temp.length > 0) {
         switch (current) {
            case "conc_do":
               func_Apply_Conc_DO();
               break;
            case "conc_inv":
               break;
         }
      } else {
         alert("Ay oh, nothing to save!");
      }
   };

   const handleClose = () => {
      setGeneralState(0);
      setTempData([]);
   };

   const props = {
      multiple: true,
      onChange(info) {
         let listFiles = [...info.fileList];

         setlistFiles(listFiles);
      },

      onRemove: (file) => {
         const index = listFiles.indexOf(file);
         const newFileList = listFiles.slice();
         newFileList.splice(index, 1);

         return setlistFiles(newFileList);
      },
      beforeUpload: (file) => {
         setlistFiles([...listFiles, file]);
         return false;
      },
      listFiles,
   };

   return (
      <div className="extract-data-form">
         <h6 className="text-ontop-menu"> Get DO data in by import</h6>
         <h5 className="text-ontop-menu">
            <span style={{ color: "blue", fontWeight: "bold" }}> pdf</span> file.
         </h5>

         <Dragger {...props} fileList={listFiles} accept=".pdf" style={{ maxHeight: "150px" }}>
            <p className="ant-upload-drag-icon">
               <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file here to upload</p>
            <p className="ant-upload-hint">Accepted .pdf format.</p>
         </Dragger>

         <div className="extract-data-footer">
            <a style={{ color: "black" }}>Progress</a>
            <Progress percent={percent} showInfo={false} status={status} />
            <br />
            <Divider />

            {generalState < 2 ? (
               <Button
                  type="primary"
                  block
                  onClick={(event) => {
                     handleProcess(event);
                  }}
               >
                  Processing
               </Button>
            ) : (
               <Button type="contained" block disabled>
                  Processed
               </Button>
            )}
            <br />
            <br />
            <Button
               block
               type="default"
               onClick={(event) => {
                  handleApply(event);
                  // setGeneralState(21);
               }}
            >
               Save Data To System
            </Button>
            <br />

            <br />
            <Button block type="default" onClick={() => handleClose()}>
               Close
            </Button>
         </div>
      </div>
   );
};

export default ExtractDataConcDO;
