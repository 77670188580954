import { BrowserRouter as Router, Switch, Route, useRouteMatch, Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import React, { Component, useEffect, useState } from "react";
import { Layout, Menu, Icon, Button } from "antd";
import SetupWorker from "../../module_Worker/Setup/Setup";
import DashBoard from "../../module_Worker/DashBoard/DashBoard";
import DailyWorkerAllocation from "../../module_Worker/Daily/DailyWorkerAllocation";
import _ from "lodash";
import { storeProjectDetail } from "../../../actions/app/app";
import { trackingUserUsing } from "../../module_BimApp/function/AdminFunction";
const { Content, Sider } = Layout;

function WorkerPage(props) {
   const [collapsed, setCollapsed] = useState(true);
   const projectId = useSelector((state) => state.project.projectId);
   const role = useSelector((state) => getRole(state));
   const [permissionType, setPermissionType] = useState(3);

   let location = useLocation();

   useEffect(() => {
      trackingUserUsing(props.userInfo.email, props.location.state.projectId, "Worker", "None");
      props.storeProjectDetail({
         projectId: props.location.state.projectId,
         projectName: props.location.state.projectName,
         userCreatedProject: props.location.state.userCreatedProject,
         sharePointId: props.location.state.sharePointId,
         issueKey: props.location.state.issueKey,
         trimbleKey: props.location.state.trimbleKey,
         typeProject: props.location.state.typeProject,
         useRfa: props.location.state.useRfa,
         isDetail: true,
      });
   }, []);

   useEffect(() => {
      getPermissionType();
   }, [role]);

   function getActiveMenu() {
      if (location.pathname === "/worker/dashboard") {
         return ["1"];
      }
      if (location.pathname === "/worker/daily") {
         return ["2"];
      }

      if (location.pathname === "/worker/setup") {
         return ["3"];
      }
   }

   function getRole(state) {
      let projectId = state.project.projectId;
      let contractors = state.user.contractors;
      if (projectId && _.isArray(contractors)) {
         let contractor = contractors.find((x) => x.projectId === projectId);
         if (contractor) {
            return contractor.role;
         }
      }

      return "";
   }

   function getPermissionType() {
      //1 : full permission
      //2 : can view daily and dashboard hide money
      if (_.isNil(role) || _.isEmpty(role)) {
         setPermissionType(1);
         return;
      }

      if (role === "QS" || role.toLowerCase().includes("admin")) {
         setPermissionType(1);
         return;
      } else if (role === "Production" || role.toLowerCase().includes("manager")) {
         setPermissionType(2);
         return;
      }

      setPermissionType(1);
   }

   let { path, url } = useRouteMatch();
   const handleFoldMenu = () => {
      setCollapsed(!collapsed);
   };
   const handleMenu = (link) => {
      props.history.push({
         pathname: link,
         state: {
            userCreatedProject: props.location.state.userCreatedProject,
            projectId: props.location.state.projectId,
            projectName: props.location.state.projectName,
            sharePointId: props.location.state.sharePointId,
            issueKey: props.location.state.issueKey,
            trimbleKey: props.location.state.trimbleKey,
            typeProject: props.location.state.typeProject,
            useRfa: props.location.state.useRfa,
            isDetail: true,
         },
      });
   };

   function renderItem() {
      let ar = [
         <Menu.Item key="2" onClick={handleMenu.bind(this, "/worker/daily")}>
            <Icon type="form" />
            <span style={{ verticalAlign: "middle" }}>Daily</span>
         </Menu.Item>,
      ];

      if (permissionType == 1) {
         ar.push(
            <Menu.Item key="3" onClick={handleMenu.bind(this, "/worker/setup")}>
               <Icon type="setting" />
               <span style={{ verticalAlign: "middle" }}>Setup</span>
            </Menu.Item>
         );
      }
      return ar;
   }

   return (
      <div>
         <Layout style={{ height: "calc(100vh - 53.78px)" }}>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: "#fff" }}>
               <Menu mode="vertical" defaultSelectedKeys={getActiveMenu()} style={{ height: "100%" }}>
                  {permissionType < 3 && (
                     <Menu.Item key="1" onClick={handleMenu.bind(this, "/worker/dashboard")}>
                        <Icon type="dashboard" />
                        <span style={{ verticalAlign: "middle" }}>DashBoard</span>
                     </Menu.Item>
                  )}

                  {renderItem()}
                  <Menu.Item
                     key="end"
                     style={{ position: "absolute", bottom: 0, width: "100%" }}
                     onClick={handleFoldMenu}
                  >
                     <Icon type={collapsed ? "menu-unfold" : "menu-fold"} />
                     <span style={{ verticalAlign: "middle" }}>Collapse</span>
                  </Menu.Item>
               </Menu>
            </Sider>
            <Layout style={{ background: "#F1F1F1", padding: "10px" }}>
               <Switch>
                  <Route path={`${path}/dashboard`}>
                     <DashBoard permissionType={permissionType} />
                  </Route>

                  <Route path={`${path}/setup`}>
                     <SetupWorker permissionType={permissionType} />
                  </Route>
                  <Route path={`${path}/daily`}>
                     <DailyWorkerAllocation permissionType={permissionType} />
                  </Route>
               </Switch>
            </Layout>
         </Layout>
      </div>
   );
}

WorkerPage.propTypes = {
   userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
   return {
      isAdmin: state.user.email === "admin@wohhup.com",
      userInfo: state.user,
   };
}
export default connect(mapStateToProps, { storeProjectDetail })(WorkerPage);
