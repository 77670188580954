import axios from './axiosClient';

async function getConfigByKey(payload) {
  return await axios.get('/api/worker/config', { params: payload });
}

async function addConfig(payload) {
  return await axios.post('/api/worker/config', payload);
}

async function getDashboardDataByKeyAndProject(payload) {
  return await axios.get('/api/worker/dashboard-data', { params: payload });
}

export { getConfigByKey, addConfig, getDashboardDataByKeyAndProject };
