import React, { useState, useEffect } from "react";
import axios from "../../module_Worker/api/axiosClient";
import { Table, Icon, Modal, Button } from "antd";
import _ from "lodash";
const { Column } = Table;

async function apiGetLinkDownloadByKey(payload) {
  return await axios.get("/api/casting-report/get-file-by-key", {
    params: payload,
  });
}

function CastingReportHistory(props) {
  const [rows, setRows] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.showCastingReportHistory > 0) {
      console.log(
        "props.currentCastingReportHistory",
        props.currentCastingReportHistory
      );
      setVisible(true);
    }
  }, [props.showCastingReportHistory]);

  useEffect(() => {
    console.log(
      "props.currentCastingReportHistory",
      props.currentCastingReportHistory
    );
  }, [props.currentCastingReportHistory]);

  async function handleDownload(record) {
    let res = await apiGetLinkDownloadByKey({ key: record.keyS3, time: 60 });
    window.open(res);
  }
  async function handleCancel() {
    setVisible(false);
  }
  return (
    <div>
      <Modal
        title="Casting Report History"
        visible={visible}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <Table
          dataSource={props.currentCastingReportHistory}
          bordered
          className="antTable"
          rowKey={(record) => record.id}
        >
          <Column title="Email" dataIndex="email" key="email" />

          <Column
            width="120px"
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Button
                  icon="download"
                  onClick={() => handleDownload(record)}
                  type="primary"
                >
                  {record.reportNumber ?? ""}
                </Button>
              </span>
            )}
          />

          <Column title="Created At" dataIndex="createdAt" key="createdAt" />
        </Table>
      </Modal>
    </div>
  );
}

export default CastingReportHistory;
