import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { add, update } from '../../api/workingTime';
import {
  TimePicker,
  Button,
  Input,
  Form,
  notification,
  Select,
  InputNumber,
  Radio,
} from 'antd';

const { Option } = Select;

function WorkingTimeForm(props) {
  const { record, isAdd, id } = props;
  const { setVisible, getDatas } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = {
            ...values,
            start: values.start.format('HH:mm'),
            end: values.end.format('HH:mm'),
          };

          if (isAdd) {
            try {
              await add(payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        name: '',
        remark: '',
        dateType: 2,
        start: moment('08:00', 'HH:mm'),
        end: moment('17:00', 'HH:mm'),
        rate: 1,
        point: 0,
      });
    } else {
      props.form.setFieldsValue({
        name: record.name,
        remark: record.remark,
        dateType: record.dateType,
        start: moment(record.start, 'HH:mm'),
        end: moment(record.end, 'HH:mm'),
        rate: record.rate,
        point: record.point,
      });
    }
  }, [isAdd, record]);

  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Form
        className='ant-form-custom'
        name='control-ref'
        onSubmit={(e) => handleSubmit(e)}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label='Date Type'>
          {getFieldDecorator('dateType', {
            rules: [{ required: true, message: 'Please input date type!' }],
          })(
            <Select initialValue={1}>
              <Option value={1}>Normal Day</Option>
              <Option value={2}>Sunday</Option>
              <Option value={3}>Public Holiday</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label='Time Type'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input time type!' }],
          })(<Input placeholder='Name' />)}
        </Form.Item>

        <Form.Item label='Time Start'>
          {getFieldDecorator('start', {
            rules: [{ required: true, message: 'Please input time start!' }],
          })(
            <TimePicker
              className='w-100'
              initialValue={moment('08:00', 'HH:mm')}
              format='HH:mm'
            />
          )}
        </Form.Item>

        <Form.Item label='Time End'>
          {getFieldDecorator('end', {
            rules: [{ required: true, message: 'Please input time end!' }],
          })(
            <TimePicker
              className='w-100'
              initialValue={moment('17:00', 'HH:mm')}
              format='HH:mm'
            />
          )}
        </Form.Item>

        <Form.Item label='Rate'>
          {getFieldDecorator('rate', {
            rules: [
              { required: true, message: 'Please input rate  & rate > 0!' },
            ],
          })(
            <InputNumber
              className='w-100'
              step={0.1}
              min={0.001}
              max={50}
              placeholder='rate'
            />
          )}
        </Form.Item>

        <Form.Item label='Point'>
          {getFieldDecorator('point', {
            rules: [{ required: false, message: 'Please input point !' }],
          })(
            <InputNumber
              className='w-100'
              step={0.1}
              min={0.001}
              max={10}
              placeholder='point'
            />
          )}
        </Form.Item>

        <Form.Item label='Remark'>
          {getFieldDecorator('remark', {
            rules: [],
          })(<Input placeholder='Remark' />)}
        </Form.Item>

        <Form.Item label='Radio.Group'>
          {getFieldDecorator('shiftType')(
            <Radio.Group>
              <Radio value={0}>Day Shift</Radio>
              <Radio value={1}>Night Shift</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'WorkingTimeAntForm' })(WorkingTimeForm);
