import React, { useState, useEffect } from "react";

import { add, update } from "../../api/holiday";
import { Button, Input, Form, notification, DatePicker, Radio } from "antd";
import moment from "moment";

function HolidayForm(props) {
  const { record, isAdd, id, } = props;
  const { setVisible, getDatas } = props;

  async function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        let payload = { ...values };
        if (isAdd) {
          try {
            await add(payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            notification["error"]({
              message: "Error",
              description: error?.response?.data?.message,
            });
          }
        } else {
          try {
            await update(id, payload);
            await setVisible(false);
            await getDatas();
          } catch (error) {
            
            notification["error"]({
              message: "Error",
              description: error?.response?.data?.message,
            });
          }
        }
      }
    });
  }

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        name: "",
        date: moment(),
      });
    } else {
      props.form.setFieldsValue({
        name: record.name,
        date: record.date ? moment(record.date) : null,
      });
    }
  }, [isAdd, record]);

  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Form
        className="ant-form-custom"
        onSubmit={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label="Name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please input name!" }],
          })(<Input placeholder="Name" />)}
        </Form.Item>

        <Form.Item label="Date">
          {getFieldDecorator("date", {
            rules: [{ required: true, message: "Please input holiday date!" }],
          })(<DatePicker className="w-100" />)}
        </Form.Item>

        {/* <Form.Item label="Range">
          {getFieldDecorator("rangeType", {
            rules: [{ required: true, message: "Please input range type!" }],
          })(
            <Radio.Group>
              <Radio value={true}>This year</Radio>
              <Radio value={false}>All year</Radio>
            </Radio.Group>
          )}
        </Form.Item> */}

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: "HolidayAntForm" })(HolidayForm);
