import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import Split from 'react-split';
import { Tooltip, message, Button, Result, Avatar } from 'antd';

import { Menu, Item, IconFont } from 'react-contexify';
import { v4 } from 'uuid';

import _ from 'lodash';
import axios from 'axios';
import { Loader, IconButton, Icon } from 'rsuite';

import {
  socket,
  checkWohhupUser,
} from '../../module_BimApp/function/AdminFunction';

import {
  getElementdbIdsFromModel,
  getForgeToken,
  getForgeTokenInternal,
} from '../../module_BimApp/function/ForgeFunction';
import { convertHexColorToVector4 } from '../../module_BimApp/function/TableFunction';
import TreeModel from './DataEntryTreeModel';
import CastingReport from '../Report/CastingReport';
import CastingReportHistory from '../Report/CastingReportHistory';
import DoNumberList from '../Report/DoNumberList';
import { getLastNode } from '../../module_BimApp/function/DataEntryFunction';
import stc from 'string-to-color';
import {
  DialogHistory,
  DialogAddColumnSheet,
  DialogTemplateSheet,
  DialogSettingColumns,
} from '../../module_DataEntry/dialog';
import { TableModelQuantity } from '../../module_DataEntry/table';
import DataEntryToolbar from './DataEntryToolbar';
import DataGrid from './DataGrid';
import { setDataEntryOpenHistory } from '../../../actions/app/dataentry';
import DataEntrySidebar from './DataEntrySidebar';

const Autodesk = window.Autodesk;

let ongoingColor = convertHexColorToVector4('#80ff80');
let completeColor = convertHexColorToVector4('#93C47D');
let fwColor = convertHexColorToVector4('#02FBFB');
let leanConcrete = convertHexColorToVector4('#ffff80');
const statusColor = {
  CASTED: completeColor,
  CAST: completeColor,
  REBAR: ongoingColor,
  FORMWORK: fwColor,
  'LEAN CONCRETE': leanConcrete,
  'On-going': ongoingColor,
};
class DataEntryPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      leftOpen: true,
      headerSidebar: 'Browser',
      headerMain: '',
      listSheet: null,
      loading: false,
      dataUser: {},
      dialogSettingColumn: false,
      sheetId: null,
      sheetName: '',
      currentSheet: null,
      selectSheet: null,

      error: false,
      errorCode: '500',
      viewerCurrent: null,
      isViewer: false,
      dataTable: [],
      columnTableForge: [],
      displayTreeView: false,
      displayNewSheet: false,
      mainParameter: '',
      mainName: '',
      mainIndex: 0,
      allParameter: [],
      itemIds: [],
      listUserUseSheet: [],
      showCastingReport: 0,
      showCastingReportHistory: 0,
      showDoNumberList: 0,
      exportReportData: {},
      currentCastingReportHistory: [],
      currentDoDataList: [],

      isCollapse: false,
    };
    this.split1 = React.createRef();
    this.split2 = React.createRef();
    this.treeModel = React.createRef();
    this.hotTable = React.createRef();
  }
  componentWillMount = () => {
    socket.on(
      'realtime-dataentry-changedata-for5d',
      this.handleRealtimeDataEntryChangeData
    );
    socket.on(
      'realtime-dataentry-entersheet-for5d',
      this.handleRealtimeDataEntryEnterSheet
    );
  };
  componentWillUnmount = () => {
    socket.removeListener(
      'realtime-dataentry-changedata-for5d',
      this.handleRealtimeDataEntryChangeData
    );
    socket.removeListener(
      'realtime-dataentry-entersheet-for5d',
      this.handleRealtimeDataEntryEnterSheet
    );
  };
  handleRealtimeDataEntryChangeData = (data) => {
    try {
      if (
        this.state.selectSheet.id === data.item.sheetId &&
        data.email !== this.props.userInfo.email &&
        this.props.location.state.projectId === data.item.projectId
      ) {
        message.info({
          content: `User: ${data.email} is editing table`,
          key: 'dataEntryFor5D',
          duration: 2,
        });
      }
    } catch {
      console.log('realtime-dataentry-changedata-for5d');
    }
  };

  handleRealtimeDataEntryEnterSheet = (data) => {
    try {
      if (
        this.state.selectSheet.id === data.item.sheetId &&
        data.email !== this.props.userInfo.email &&
        this.props.location.state.projectId === data.item.projectId
      ) {
        let temp = [];
        let nameExtend = [];
        this.state.listUserUseSheet.push(data.email);
        _.forEach(this.state.listUserUseSheet, (v1, k) => {
          let index = _.findIndex(temp, (o) => {
            return o.name === data.email;
          });
          if (index < 0) {
            let split = v1.split('_');
            if (k <= 5) {
              if (split.length > 1) {
                temp.push({
                  shortCut:
                    split[0].charAt(0).toUpperCase() +
                    split[1].charAt(0).toUpperCase(),
                  name: v1,
                });
              } else {
                temp.push({ shortCut: v1.charAt(0).toUpperCase(), name: v1 });
              }
            } else {
              nameExtend.push(v1);
            }
          }
        });
        if (nameExtend.length !== 0) {
          temp.push({ shortCut: '...', name: nameExtend.join(', ') });
        }
        this.setState({ listUserUseSheet: temp });
      }
    } catch {
      console.log('realtime-dataentry-entersheet-for5d');
    }
  };
  componentDidMount = () => {
    console.log('this.props', this.props);
    this.setState({ loading: true });
    axios
      .post('/api/users/get-me-information', {
        token: this.props.userInfo.token,
      })
      .then((res) => {
        if (res.data.user.email !== this.props.userInfo.email) {
          this.setState({ loading: false, error: true, errorCode: '500' });
          return;
        }
        let index = _.findIndex(res.data.userInfo.contractors, (v) => {
          return v.projectId === this.props.location.state.projectId;
        });
        if (res.data.user.email !== 'admin@wohhup.com') {
          // if (index < 0 || !res.data.userInfo.contractors[index].isAccessDataEntry) {
          //   this.setState({ loading: false, error: true, errorCode: '400' })
          //   return
          // }
        }
        let role = undefined;
        let company = undefined;
        if (index >= 0) {
          role = res.data.userInfo.contractors[index].role;
          company = res.data.userInfo.contractors[index].company;
        }

        axios
          .get(`/api/dataentry/get-project-tables`, {
            params: {
              projectId: this.props.location.state.projectId,
              token: this.props.userInfo.token,
            },
          })
          .then((res2) => {
            let temp = [];
            _.forEach(res2.data, (v) => {
              if (v.type === 'for5d') {
                v.active = false;
                // v.name="Archi"
                temp.push(v);
              }
            });
            this.setState({ listSheet: temp }, () => {
              axios
                .post(`/api/projects/get-information-project`, {
                  projectId: this.props.location.state.projectId,
                  token: this.props.userInfo.token,
                })
                .then(() => {
                  this.setState({
                    dataUser: {
                      email: res.data.user.email,
                      role:
                        res.data.user.role === 'admin'
                          ? 'Document Controller'
                          : role
                          ? role
                          : 'viewer',
                      userName: res.data.userInfo.userName,
                      company:
                        res.data.user.role === 'admin'
                          ? 'Woh Hup Private Ltd'
                          : checkWohhupUser(res.data.user.email)
                          ? 'Woh Hup Private Ltd'
                          : company,
                    },
                    loading: false,
                  });
                })
                .catch(() => {
                  this.setState({ loading: false });
                });
            });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };
  toggleSidebar = () => {
    this.setState({ leftOpen: !this.state.leftOpen });
  };

  handleFunctionSidebar = (value) => {
    this.setState({ headerSidebar: value });
  };
  handleSheetOpen = (item) => {
    this.setState(
      {
        selectSheet: item,
        isViewer: false,
        listUserUseSheet: [],
        groupColumns: [],
        leftOpen: false,
      },
      async () => {
        try {
          message.destroy();
          this.state.viewerCurrent.tearDown();
          this.state.viewerCurrent.finish();
          $('#forgeViewer').empty();
        } catch {}
        if (this.split1.current) this.split1.current.split.setSizes([100, 0]);
        window.historySheet = [];
        let mainParameter = '';
        let mainName = '';
        let mainIndex = '';
        let columnMapping = {};
        _.forEach(item.columns, (v, k) => {
          columnMapping[v.name] = v.type;
          if (v.isMatching) {
            mainParameter = v.parameter;
            mainName = v.name;
            mainIndex = k;
          }
        });
        let temp = this.state.listSheet.splice(0);
        _.forEach(temp, (v) => {
          v.active = false;
        });
        item.active = true;

        this.setState({
          headerMain: item.name,
          listSheet: temp,
          sheetName: item.name,
          sheetId: item.id,
          mainParameter,
          mainName,
          mainIndex,
          leftOpen: false,
          columnMapping: columnMapping,
        });
      }
    );
  };

  handleSettingColumn = (e) => {
    if (
      this.props.isAdmin ||
      this.state.dataUser.role === 'Document Controller'
    )
      this.setState({
        dialogSettingColumn: !this.state.dialogSettingColumn,
        currentSheet: e.props.item,
      });
  };
  handleCloseSettingColumn = () => {
    this.setState({ dialogSettingColumn: false });
  };

  //#region
  launchViewer = () => {
    getForgeToken().then((token) => {
      var options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
          extensions: [
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            // "Autodesk.AEC.Hypermodeling",
          ],
        };
        let viewer = new Autodesk.Viewing.GuiViewer3D(
          document.getElementById('forgeViewer'),
          config3d
        );
        viewer.start();
        this.setState({ viewerCurrent: viewer });
      });
    });
  };

  handleForgeViewer = () => {
    this.setState(
      {
        isViewer: !this.state.isViewer,
        dataTable: [],
      },
      () => {
        try {
          message.destroy();
          this.state.viewerCurrent.tearDown();
          this.state.viewerCurrent.finish();
          $('#forgeViewer').empty();
          this.setState({ viewerCurrent: null, isCollapse: true });
        } catch {}
        if (!this.state.isViewer) {
          this.split1.current.split.setSizes([100, 0]);
        } else {
          this.split1.current.split.setSizes([60, 40]);
          this.launchViewer();
          this.setState({ displayTreeView: true, isCollapse: true });
        }
      }
    );
  };
  dragSplit = () => {
    try {
      this.state.viewerCurrent.resize();
    } catch {}
  };

  //#endregion

  //#region sidebar control
  handleNewSheet = () => {
    if (this.props.isAdmin) this.setState({ displayNewSheet: true });
  };
  handleCloseNewSheet = (data) => {
    if (data) {
      this.setState({ displayNewSheet: false, listSheet: data });
    } else {
      this.setState({ displayNewSheet: false });
    }
  };

  handleOpenTreeModel = () => {
    this.setState({ displayTreeView: true });
  };
  handleCloseTreeView = () => {
    this.setState({ displayTreeView: false });
  };

  handleAddColumnSheet = (e) => {
    if (this.props.isAdmin) {
      this.setState({
        displayAddColumnSheet: true,
        currentSheet: e.props.item,
      });
    }
  };
  handleCloseColumnSheet = () => {
    this.setState({ displayAddColumnSheet: false });
  };

  handleDeleteSheet = (e) => {
    if (this.props.isAdmin) {
      let idx = e.props.idx;
      let item = e.props.item;
      if (!window.confirm('Are you want to delete it')) return;
      this.setState({ loading: true });
      axios
        .delete(`/api/dataentry/delete-sheet5d`, {
          data: {
            projectId: this.props.location.state.projectId,
            sheetId: item.id,
            sheetName: item.name,
            token: this.props.userInfo.token,
          },
        })
        .then(() => {
          let temp = this.state.listSheet;
          temp.splice(idx, 1);
          this.setState({
            listSheet: temp,
            loading: false,
            columns: [],
          });
        })
        .catch(() => {
          message.warning(`Can't delete sheet`);
          this.setState({ loading: false });
        });
    }
  };
  handleCollapse = (value) => {
    this.setState({ isCollapse: value }, () => {
      if (this.split2.current) {
        if (this.state.isCollapse) {
          this.split2.current.split.setSizes([100, 0]);
        } else {
          this.split2.current.split.setSizes([50, 50]);
        }
      }
      try {
        this.state.viewerCurrent.resize();
      } catch {}
    });
  };
  //#endregion

  handleModelData = () => {
    return new Promise((resolve, reject) => {
      let parameter = [];
      let listCheckTotal = {};
      let columnTableForge = [
        //field: v.name, enableRowGroup: true, enablePivot: true, editable: true, hide: false, initialWidth: v.width, resizable: true, headerTooltip: v.name, filter: 'agMultiColumnFilter'
      ];
      _.forEach(this.state.selectSheet.columns, (v) => {
        if (v.parameter !== '' && v.parameter) {
          let data = {
            field: v.parameter,
            lockVisible: true,
          };
          if (v.type === 'numeric') {
            data.aggFunc = 'sum';
          }
          if (v.isMatching) {
            // data.headerCheckboxSelection = true
            // data.headerCheckboxSelectionFilteredOnly = true
            data.checkboxSelection = true;
            data.enableRowGroup = true;
            data.rowGroup = true;
            data.isMatching = true;
            data.lockPosition = true;
            data.hide = true;
            columnTableForge.splice(1, 0, data);
          } else {
            columnTableForge.push(data);
          }
          parameter.push(v.parameter);
          listCheckTotal[v.parameter] = v.type === 'numeric';
        }
      });
      if (parameter.length !== 0) {
        let tempModels = [];
        let count = 0;
        let models = this.state.viewerCurrent.impl.modelQueue().getModels();
        _.forEach(models, (v) => {
          let dbIds = getElementdbIdsFromModel(v);
          count += dbIds.length;
          tempModels.push({ model: v, dbIds });
        });
        let temp = [];
        let allParameter = [];
        _.forEach(tempModels, (v) => {
          _.forEach(v.dbIds, (id) => {
            v.model.getProperties(id, (modelAProperty) => {
              let data = {
                id: id,
                itemId:
                  v.model.myData.loadOptions.itemId +
                  ' ' +
                  v.model.myData.loadOptions.version +
                  ' ' +
                  v.model.myData.loadOptions.guid,
                externalId: modelAProperty.externalId,
              };
              _.forEach(parameter, (p) => {
                data[p] = p === 'checkBox' ? false : '';
              });
              _.forEach(modelAProperty.properties, (o) => {
                if (parameter.includes(o.displayName)) {
                  data[o.displayName] = _.isNumber(o.displayValue)
                    ? +o.displayValue.toFixed(2)
                    : o.displayValue;
                }
                if (allParameter.includes(o.displayName)) {
                  allParameter.push(o.displayName);
                }
              });
              temp.push(data);
              count--;
              if (count === 0) {
                if (this.state.mainParameter !== null) {
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );
                  console.log('columnTableForge', columnTableForge);
                  this.setState(
                    {
                      dataTable: tempData,
                      allDataTable: tempData,
                      columnTableForge,
                      allParameter,
                    },
                    () => {
                      resolve();
                    }
                  );
                }
              }
            });
          });
        });
      } else {
        reject();
        message.warning('Please, setup parameter');
      }
    });
  };
  handleForgeData = (data, mainParameter, listCheckTotal) => {
    if (this.state.selectSheet?.name === 'Archi') {
      let temp = [];
      data.forEach((x) => {
        if (!_.isEmpty(x['ROOM NUMBER'])) {
          if (!_.isEmpty(x['5D_CODE_BASE'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_BASE'],
            });
          }

          if (!_.isEmpty(x['5D_CODE_MIDLE'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_MIDLE'],
            });
          }

          if (!_.isEmpty(x['5D_CODE_FINAL'])) {
            temp.push({
              ...x,
              CODE: x['5D_CODE_FINAL'],
            });
          }
        }
      });

      data = temp.map((x) => ({
        ...x,
        Item:
          x.CODE +
          x['ROOM NUMBER'] +
          x['WH_Zone'] +
          x['WH_Sub Zone'] +
          x['WH_Level'],
        Volume: _.isNumber(x.Volume) ? x.Volume : 0.0,
        Area: _.isNumber(x.Area) ? x.Area : 0.0,
        Length: _.isNumber(x.Length) ? x.Length : 0.0,
        Count: 1,
      }));

      // mainParameter = "combineCodeRoomNumber";
    }

    let t = {};
    _.forEach(data, (v) => {
      if (v[mainParameter] === '') return;
      if (!t[v[mainParameter]]) t[v[mainParameter]] = [];
      t[v[mainParameter]].push(v);
    });
    // let t = groupArray(data, mainParameter);
    console.log('t', t);
    let tempData = [];
    _.forEach(t, (v) => {
      let tempTotal = { id: v4(), itemIds: [] };
      _.forEach(v, (i1) => {
        _.forEach(i1, (i2, k2) => {
          if (['externalId', 'id'].includes(k2)) return;
          if (k2 === 'itemId') {
            if (!tempTotal.itemIds.includes(i2)) tempTotal.itemIds.push(i2);
          } else if (_.isNumber(i2)) {
            if (listCheckTotal[k2]) {
              if (!tempTotal[k2]) tempTotal[k2] = 0;
              tempTotal[k2] = tempTotal[k2] + i2;
              tempTotal[k2] = +tempTotal[k2].toFixed(2);
            } else {
              if (!tempTotal[k2]) tempTotal[k2] = i2;
              if (tempTotal[k2] !== 'var')
                tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
            }
          } else {
            if (!tempTotal[k2]) tempTotal[k2] = i2;
            if (tempTotal[k2] !== 'var')
              tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
          }
        });
      });
      tempTotal.children = v;
      tempData.push(tempTotal);
    });
    return tempData;
  };

  handleShowElement = () => {
    //!note for hoang
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    let listCheckTotal = {};
    let parameter = [];
    _.forEach(this.state.selectSheet.columns, (v) => {
      if (v.parameter !== '' && v.parameter) {
        parameter.push(v.parameter);
        listCheckTotal[v.parameter] = v.type === 'numeric';
      }
    });
    if (parameter.length !== 0) {
      let tempModels = [];
      let count = 0;
      _.forEach(models, (v) => {
        let dbIds = v.selector.getSelection();
        count += dbIds.length;
        tempModels.push({ model: v, dbIds });
      });
      let temp = [];
      let allParameter = [];
      if (count === 0) {
        this.setState({ dataTable: this.state.allDataTable });
      }

      _.forEach(tempModels, (v) => {
        _.forEach(v.dbIds, (id) => {
          v.model.getProperties(id, (modelAProperty) => {
            let data = {
              id: id,
              itemId:
                v.model.myData.loadOptions.itemId +
                ' ' +
                v.model.myData.loadOptions.version +
                ' ' +
                v.model.myData.loadOptions.guid,
              externalId: modelAProperty.externalId,
            };
            _.forEach(parameter, (p) => {
              data[p] = p === 'checkBox' ? false : '';
            });
            _.forEach(modelAProperty.properties, (o) => {
              if (parameter.includes(o.displayName)) {
                data[o.displayName] = _.isNumber(o.displayValue)
                  ? +o.displayValue.toFixed(2)
                  : o.displayValue;
              }
              if (allParameter.includes(o.displayName)) {
                allParameter.push(o.displayName);
              }
            });
            temp.push(data);
            count--;
            if (count === 0) {
              if (temp.length !== 0) {
                if (this.state.mainParameter !== null) {
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );
                  this.setState({ dataTable: tempData });
                  console.log('tempData', tempData);
                }
              }
            }
          });
        });
      });
    }
  };

  handleColorStatus = async (value, showMessage = true) => {
    console.log('handleColorStatus');
    if (!this.state.viewerCurrent) {
      if (showMessage) {
        message.warning('Please, open model');
      }

      return;
    }
    if (this.state.dataTable.length === 0) {
      // message.warning('Please, reload data')
      // return
      await this.handleModelData();
    }
    if (this.state.dataTable.length === 0) {
      message.warning('Please, check sheet setup');
      return;
    }
    // let mainParameter = null
    // let mainName = null
    // _.forEach(this.state.selectSheet.columns, v => {
    //   if (v.parameter) {
    //     if (v.isMatching) {
    //       mainParameter = v.parameter
    //       mainName = v.name
    //     }

    //   }
    // })
    let tempModel = {};
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      if (value === null) this.state.viewerCurrent.clearThemingColors(v);
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });
    let data = value === null ? this.props.dataEntryData : value;
    let clone = data.slice(0);
    _.forEach(clone, (v) => {
      if (v.Status !== '' && v.Status !== undefined && v.Status !== null) {
        let index = _.findIndex(this.state.allDataTable, (o) => {
          return o[this.state.mainParameter] === v[this.state.mainName];
        });
        if (index >= 0) {
          let t = {};
          _.forEach(this.state.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          // let t = groupArray(this.state.allDataTable[index].children, 'itemId')
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              let color = statusColor[v.Status];
              if (color)
                _.forEach(i, (el) => {
                  this.state.viewerCurrent.setThemingColor(
                    el.id,
                    color,
                    tempModel[k]
                  );
                });
            }
          });
        }
      }
    });
  };
  handleOpenModelInRow = async (row) => {
    console.log('handleOpenModelInRow', row);
    let clone = row;
    let resList = [];
    let globalOffset = JSON.parse(clone.globalOffset);
    let itemIds = JSON.parse(clone.itemIds);
    itemIds.forEach((v) => {
      let split = v.split(' ');
      const response = axios.post('/api/items/get-item-version', {
        itemId: split[0],
        version: split[1],
        viewGuid: split[2],
      });
      resList.push(response);
    });
    let res = await Promise.all(resList);
    let temp = [];
    _.forEach(res, (v) => {
      let data = v.data;
      let index = _.findIndex(data.revisions, (o) => {
        return o.version === data.versionCurrent;
      });
      if (index >= 0) {
        data.objectId = data.revisions[index].objectId;
        data.globalOffset = globalOffset;
        temp.push(data);
      }
    });

    this.setState({ itemIds: temp });
  };

  handleSelectObjectInRow = (rows, check) => {
    if (this.state.dataTable.length === 0) {
      message.warning('Please, reload data');
      return;
    }

    let tempIds = {};
    let tempModel = {};
    let models = this.state.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });

    if (this.state.selectSheet?.name === 'Archi') {
      _.forEach(rows, (v) => {
        let index = _.findIndex(this.state.allDataTable, (o) => {
          if (!_.isEmpty(o['5D_CODE_BASE'])) {
            let uniqCode =
              o['5D_CODE_BASE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_MIDLE'])) {
            let uniqCode =
              o['5D_CODE_MIDLE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_FINAL'])) {
            let uniqCode =
              o['5D_CODE_FINAL'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          return false;
        });

        console.log('index', index);

        if (index >= 0) {
          let t = {};
          _.forEach(this.state.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              if (!tempIds[k]) tempIds[k] = [];
              _.forEach(i, (el) => {
                tempIds[k].push(el.id);
              });
            }
          });
        }
      });
    } else {
      _.forEach(rows, (v) => {
        if (v.Status !== '' && v.Status !== undefined) {
          let index = _.findIndex(this.state.allDataTable, (o) => {
            return o[this.state.mainParameter] === v[this.state.mainName];
          });
          if (index >= 0) {
            let t = {};
            _.forEach(this.state.allDataTable[index].children, (j) => {
              if (!t[j.itemId]) t[j.itemId] = [];
              t[j.itemId].push(j);
            });
            _.forEach(t, (i, k) => {
              if (tempModel[k]) {
                if (!tempIds[k]) tempIds[k] = [];
                _.forEach(i, (el) => {
                  tempIds[k].push(el.id);
                  // tempId.push({ model: models[index], ids: externalId })
                  // this.state.viewerCurrent.select(el.id, tempModel[k])
                });
              }
            });
          }
        }
      });
    }
    let selection = [];
    this.state.viewerCurrent.showAll();
    this.state.viewerCurrent.clearSelection();
    _.forEach(tempIds, (v, k) => {
      if (check) {
        if (this.state.isIsolate) {
          this.state.viewerCurrent.isolate(v, tempModel[k]);
        }
      }
      selection.push({ model: tempModel[k], ids: v });
    });
    if (!this.state.isIsolate) {
      this.state.viewerCurrent.setAggregateSelection(selection);
    }
  };

  callbackRemoveDataTable = () => {
    this.setState({ dataTable: [] });
  };
  callbackLoadDone = () => {
    this.handleColorStatus(null);
  };

  render() {
    let leftOpen = this.state.leftOpen ? 'open' : 'closed';
    return (
      <div id='data-entry'>
        {!this.state.error ? (
          this.state.listSheet ? (
            this.state.dataUser !== null && (
              <div id='layout-dataentry'>
                <div id='left-dataentry' className={leftOpen}>
                  <div className='icon' onClick={this.toggleSidebar}>
                    &equiv;
                  </div>
                  <div className={`sidebar ${leftOpen}`}>
                    <div className='header'>
                      <h4 className='title'>{this.state.headerSidebar}</h4>
                      <div
                        className='icon arrowclose'
                        onClick={this.toggleSidebar}
                      >
                        &lsaquo;
                      </div>
                    </div>
                    <div className='content'>
                      <DataEntrySidebar
                        handleFunctionSidebar={this.handleFunctionSidebar}
                        headerSidebar={this.state.headerSidebar}
                        listSheet={this.state.listSheet}
                        handleSheetOpen={this.handleSheetOpen}
                      />
                    </div>
                  </div>
                </div>

                <div id='main-dataentry'>
                  <div className='header'>
                    <div style={{ marginTop: 5, marginLeft: 5, float: 'left' }}>
                      {this.state.listUserUseSheet.map((v) => (
                        <Tooltip title={v.name} placement='bottom'>
                          <Avatar
                            style={{
                              verticalAlign: 'middle',
                              fontSize: 12,
                              backgroundColor:
                                v.shortCut === '...' ? 'red' : stc(v.name),
                            }}
                            size='small'
                          >
                            {v.shortCut}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </div>

                    <h4 className={`title ${'left-' + leftOpen}`}>
                      {this.state.headerMain}
                      {this.state.headerMain == 'Archi' && [
                        <span
                          role='button'
                          className='mr-2 poi'
                          onClick={() => {
                            this.props.history.push({
                              pathname: `/for5d/archi-input`,
                              state: {
                                ...this.props.location.state,
                              },
                            });
                          }}
                        >
                          -&gt; Switch to Status view
                        </span>,
                        '   |   ',

                        <span
                          role='button'
                          className='ml-2'
                          onClick={() => {
                            this.props.history.push({
                              pathname: `/for5d/archi-summary`,
                              state: {
                                ...this.props.location.state,
                              },
                            });
                          }}
                        >
                          Summary
                        </span>,
                      ]}
                    </h4>
                    {this.state.sheetId && (
                      <IconButton
                        size='xs'
                        color={!this.state.isViewer ? 'blue' : 'red'}
                        icon={
                          this.state.isViewer ? (
                            <Icon icon='close' />
                          ) : (
                            <Icon>3D</Icon>
                          )
                        }
                        onClick={this.handleForgeViewer}
                        style={{
                          marginTop: 5,
                          marginRight: 5,
                          float: 'right',
                          borderRadius: 0,
                        }}
                      />
                    )}
                  </div>
                  <div className='content'>
                    {this.state.loading && (
                      <Loader
                        backdrop
                        center
                        content='Loading...'
                        speed='fast'
                        size='md'
                        vertical
                        style={{ zIndex: 10000 }}
                      />
                    )}
                    <Split
                      ref={this.split1}
                      id='splitForgeViewer'
                      className='splitForgeViewer'
                      minSize={[600, 0]}
                      sizes={[100, 0]}
                      gutterSize={2}
                      gutterAlign='center'
                      direction='horizontal'
                      cursor='col-resize'
                      style={{
                        height: 'calc(100% - 50px)',
                        width: '100%',
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                      }}
                      onDrag={this.dragSplit}
                    >
                      <div
                        id='content3D'
                        style={{ height: '100%', width: '100%' }}
                      >
                        <div style={{ padding: 5 }}>
                          <DataEntryToolbar
                            handleModelData={this.handleModelData}
                            handleColorStatus={this.handleColorStatus}
                            groupColumns={this.state.groupColumns}
                            projectId={this.props.location.state.projectId}
                            selectSheet={this.state.selectSheet}
                            viewerCurrent={this.state.viewerCurrent}
                            dataTable={this.state.dataTable}
                            allDataTable={this.state.allDataTable}
                            mainParameter={this.state.mainParameter}
                            mainName={this.state.mainName}
                            statusColor={statusColor}
                            handleForgeData={this.handleForgeData}
                          />
                        </div>
                        <div style={{ height: 'calc(100% - 46px)' }}>
                          <DataGrid
                            handleOpenModelInRow={this.handleOpenModelInRow}
                            handleSelectObjectInRow={
                              this.handleSelectObjectInRow
                            }
                            handleColorStatus={this.handleColorStatus}
                            selectSheet={this.state.selectSheet}
                            projectId={this.props.location.state.projectId}
                            dataUser={this.state.dataUser}
                            viewerCurrent={this.state.viewerCurrent}
                            setShowCastingReport={() => {
                              this.setState({
                                showCastingReport:
                                  this.state.showCastingReport + 1,
                              });
                            }}
                            setExportReportData={(e) => {
                              this.setState({
                                exportReportData: e,
                              });
                            }}
                            setShowCastingReportHistory={() => {
                              this.setState({
                                showCastingReportHistory:
                                  this.state.showCastingReportHistory + 1,
                              });
                            }}
                            setCurrentCastingReportHistory={(e) => {
                              this.setState({
                                currentCastingReportHistory: e,
                              });
                            }}
                            setShowDoNumberList={() => {
                              this.setState({
                                showDoNumberList:
                                  this.state.showDoNumberList + 1,
                              });
                            }}
                            setCurrentDoDataList={(e) => {
                              this.setState({
                                currentDoDataList: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        id='content2D'
                        style={{
                          height: '100%',
                          width: '100%',
                          zIndex: 1000,
                          backgroundColor: 'white',
                        }}
                      >
                        {this.state.isViewer && (
                          <>
                            <Split
                              ref={this.split2}
                              minSize={[300, 0]}
                              sizes={[100, 0]}
                              gutterSize={5}
                              gutterAlign='center'
                              direction='vertical'
                              cursor='col-resize'
                              style={{
                                height: '100%',
                                width: '100%',
                                justifyItems: 'center',
                                alignItems: 'center',
                                borderTop: '2px #d8d8d8 solid',
                              }}
                              onDrag={this.dragSplit}
                            >
                              <div style={{ height: '100%', width: '100%' }}>
                                <div
                                  id='forgeViewer'
                                  style={{
                                    position: 'relative',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <IconButton
                                    style={{
                                      zIndex: '102',
                                      float: 'left',
                                      top: '0',
                                      borderRadius: 0,
                                    }}
                                    size='sm'
                                    appearance='ghost'
                                    color='blue'
                                    icon={<Icon icon='detail' />}
                                    onClick={this.handleOpenTreeModel}
                                  />
                                  <TreeModel
                                    ref={this.treeModel}
                                    open={this.state.displayTreeView}
                                    close={this.handleCloseTreeView}
                                    viewer={this.state.viewerCurrent}
                                    projectId={
                                      this.props.location.state.projectId
                                    }
                                    projectName={
                                      this.props.location.state.projectName
                                    }
                                    userInfo={this.props.userInfo}
                                    itemIds={this.state.itemIds}
                                    callbackRemoveDataTable={
                                      this.callbackRemoveDataTable
                                    }
                                    callbackLoadDone={this.callbackLoadDone}
                                  />
                                  {this.state.isCollapse && (
                                    <IconButton
                                      style={{
                                        zIndex: '102',
                                        bottom: 0,
                                        borderRadius: 0,
                                        position: 'absolute',
                                        right: 0,
                                      }}
                                      size='sm'
                                      appearance='ghost'
                                      color='blue'
                                      icon={<Icon icon='plus' />}
                                      onClick={this.handleCollapse.bind(
                                        this,
                                        false
                                      )}
                                    />
                                  )}
                                </div>
                              </div>

                              <div style={{ height: '100%', width: '100%' }}>
                                <TableModelQuantity
                                  handleCollapse={this.handleCollapse}
                                  dataTable={this.state.dataTable}
                                  handleShowElement={this.handleShowElement}
                                  handleModelData={this.handleModelData}
                                  columns={this.state.columnTableForge}
                                  mainParameter={this.state.mainParameter}
                                  viewerCurrent={this.state.viewerCurrent}
                                  mainName={this.state.mainName}
                                  projectId={
                                    this.props.location.state.projectId
                                  }
                                  selectSheet={this.state.selectSheet}
                                />
                              </div>
                            </Split>
                          </>
                        )}
                      </div>
                    </Split>
                    :
                    <div
                      style={{
                        position: 'relative',
                        top: 'calc(50% - 30px)',
                        textAlignLast: 'center',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <Loader
              center
              content='Loading...'
              speed='fast'
              size='md'
              backdrop
              vertical
              style={{ zIndex: 100000 }}
            />
          )
        ) : (
          <Result
            status={this.state.errorCode === '400' ? 'warning' : 'error'}
            title={this.state.errorCode}
            subTitle={
              this.state.errorCode === '400' ? (
                'Please, contact admin project to access.'
              ) : (
                <>
                  <p>
                    Sorry, we are not able to connect to server at moment.
                    Please try again later.
                  </p>
                  <p>
                    You can contact to our web admin at
                    phan_manhquyet@wohhup.com for feedback or any urgent issue.
                    He is also can reach at number +6596969942.
                  </p>
                </>
              )
            }
            extra={[
              <Button
                type='primary'
                key='console'
                onClick={() => this.props.history.push('/')}
              >
                Back Home
              </Button>,
            ]}
          ></Result>
        )}

        <Menu id='data_entry_file_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handleSettingColumn}>
            <IconFont className='fas fa-edit' />
            Setting Columns
          </Item>
          {this.props.isAdmin && (
            <Item onClick={this.handleAddColumnSheet}>
              <IconFont className='fas fa-plus' />
              Add Columns
            </Item>
          )}
          {this.props.isAdmin && (
            <Item onClick={this.handleAddColumnSheet}>
              <IconFont className='fas fa-plus' />
              Add Columns from template
            </Item>
          )}
          {this.props.isAdmin && (
            <Item onClick={this.handleDeleteSheet}>
              <IconFont className='fas fa-trash' />
              Delete Sheet
            </Item>
          )}
        </Menu>

        {this.props.isAdmin && (
          <Menu id='data_entry_side_menu' style={{ zIndex: 10000 }}>
            <Item onClick={this.handleNewSheet}>
              <IconFont className='fas fa-plus-square' />
              Add Sheet
            </Item>
          </Menu>
        )}

        {this.props.openHistoryDialog.isOpen && (
          <DialogHistory
            close={() => {
              this.props.setDataEntryOpenHistory({
                openHistoryDialog: { isOpen: false, data: null },
              });
            }}
            open={this.props.openHistoryDialog.isOpen}
            cellDataCurrent={this.props.openHistoryDialog.data}
            columns={this.state.selectSheet.columns}
          />
        )}

        {this.state.dialogSettingColumn && (
          <DialogSettingColumns
            close={this.handleCloseSettingColumn}
            open={this.state.dialogSettingColumn}
            sheet={this.state.currentSheet}
            token={this.props.userInfo.token}
            projectId={this.props.location.state.projectId}
            isAdmin={this.props.isAdmin}
          />
        )}
        {this.state.displayNewSheet && (
          <DialogTemplateSheet
            close={this.handleCloseNewSheet}
            open={this.state.displayNewSheet}
            token={this.props.userInfo.token}
            listSheet={this.state.listSheet}
            projectId={this.props.location.state.projectId}
          />
        )}
        {this.state.displayAddColumnSheet && (
          <DialogAddColumnSheet
            close={this.handleCloseColumnSheet}
            open={this.state.displayAddColumnSheet}
            token={this.props.userInfo.token}
            sheet={this.state.currentSheet}
            projectId={this.props.location.state.projectId}
          />
        )}
        <CastingReport
          email={this.props.userInfo.email}
          showCastingReport={this.state.showCastingReport}
          reportData={this.state.exportReportData}
          projectName={this.props.location.state.projectName}
          projectId={this.props.location.state.projectId}
        />

        <CastingReportHistory
          showCastingReportHistory={this.state.showCastingReportHistory}
          currentCastingReportHistory={this.state.currentCastingReportHistory}
        />

        <DoNumberList
          showDoNumberList={this.state.showDoNumberList}
          currentDoDataList={this.state.currentDoDataList}
        />
      </div>
    );
  }
}

DataEntryPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  openHistoryDialog: PropTypes.bool.isRequired,
  setDataEntryOpenHistory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    dataEntryData: state.dataentry.data,
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    openHistoryDialog: state.dataentry.openHistoryDialog,
  };
}
export default connect(mapStateToProps, {
  setDataEntryOpenHistory: setDataEntryOpenHistory,
})(DataEntryPage);
