import $ from 'jquery';
import _ from 'lodash'
import { convertHexColorToVector4 } from '../function/TableFunction'
import {
  getLeafFragIds, getChildNodeMultiModel, getAllElementdbIdsOneModel
} from '../function/ForgeFunction'
const Autodesk = window.Autodesk;
const THREE = window.THREE;

// const viewer = null
class MenuContextWH extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.container = viewer.canvas.parentElement;
    this.onBuildingContextMenuItem = this.onBuildingContextMenuItem.bind(this)
    this.x = 0
    this.y = 0
    this.container.addEventListener('mousedown', this.onMouseDown);
    this.element = []
    this.isRebar =  window.typeFile==='rebar'
    this.viewer =viewer
    $("#color-picker").change(e => {
      var currSelection = viewer.impl.selector.getAggregateSelection()
      let color = convertHexColorToVector4(e.target.value)
      _.forEach(currSelection, item => {
        _.forEach(item.selection, id => {
          viewer.setThemingColor(id, color, item.model, true);
        })

      })
    })
 
  
  }


  get menuId() {
    return 'MenuContext';
  }



  onBuildingContextMenuItem(menu, status) {
    if (this.isRebar) {
      let index = _.findIndex(menu, o => { return o.title === 'Show All Objects' })
      if (index >= 0)
        menu.splice(index, 1)
    }
    if (!status.hasSelected) {
      menu.push({
        title: 'Pop out',
        target: () => {

          this.viewer.clearSelection();
          this.viewer.loadExtension('Autodesk.Viewing.Popout').then(function (e) {
            e.popoutToBlank()
          })
        }
      });

      !this.isRebar && menu.push({
        title: 'Reset Colors',
        target: () => {
          const models = this.viewer.impl.modelQueue().getModels();
          models.forEach(element => {
            this.viewer.clearThemingColors(element);
          });

        }
      })

    } else if (status.hasSelected) {
      !this.isRebar && menu.push({
        title: 'Change Color',
        target: () => {
          $("#color-picker").css('top', status.canvasY)
          $("#color-picker").css('left', status.canvasX)
          setTimeout(() => {
            $("#color-picker").click();
          }, 1);

        }
      });
      menu.push({
        title: 'Reset Transform',
        target: () => {
          let _selection = this.viewer.getAggregateSelection()
          _.forEach(_selection, sel => {
            let childNode = getChildNodeMultiModel(sel.model, sel.selection)
            _.forEach(childNode, v => {
              let fragIds = getLeafFragIds(sel.model, v)
              _.forEach(fragIds, fragId => {
                let fragProxy = this.viewer.impl.getFragmentProxy(sel.model, fragId)
                fragProxy.position = null
                fragProxy.updateAnimTransform()
              })
            })
          })
          this.viewer.impl.sceneUpdated(true)
        }
      });
    }
    menu.push({
      title: 'Reset All Transform',
      target: () => {
        let fragIdsArray = []

        let fragCount = this.viewer.impl.model.getFragmentList().fragments.fragId2dbId.length

        for (let fragId = 0; fragId < fragCount; ++fragId) {

          fragIdsArray.push(fragId)
        }
        fragIdsArray.forEach((fragId) => {
          let fragProxy = this.viewer.impl.getFragmentProxy(this.viewer.impl.model, fragId)
          fragProxy.position = null
          fragProxy.updateAnimTransform()
        })
        this.viewer.impl.sceneUpdated(true)

      }
    });
    // menu.push({
    //   title: 'Section Face',
    //   target: async () => {
    //     // let doc = this.viewer.impl.model.getDocumentNode()
    //     // let data = doc.getAecModelData()
    //     let data =this.viewer.impl.model.getData().placementWithOffset
    //     let angle = new THREE.Vector3(1, 0, 0).angleTo(new THREE.Vector3(data.elements[0], data.elements[1], data.elements[2]))
    //     let ext = this.viewer.getExtension('Autodesk.Section')
    //     var hitTest = this.viewer.hitTest(this.x, this.y, true);
    //     if (hitTest !== null) {
    //       let temp = hitTest.face.normal.applyAxisAngle(this.viewer.impl.model.getData().loadOptions.fileExt === 'wfx' 
    //       ?new THREE.Vector3(0, 1, 0):new THREE.Vector3(0, 0, 1), angle)
    //       ext.setSectionPlane(temp, hitTest.point)
    //     }
    //     // this.viewer.setCutPlanes([new THREE.Vector4(hitTest.face.a,hitTest.face.b,hitTest.face.c,hitTest.distance)])

    //     let lookAtVector1 = new THREE.Vector3(0, 0, 0);
    //     this.viewer.getCamera().getWorldDirection(lookAtVector1)
    //     // console.log(lookAtVector1)
    //     // console.log(hitTest)
    //   }
    // })
    menu.push({
      title: 'Clear Section',
      target: async () => {
        let section = this.viewer.getExtension('Autodesk.Section')
        section.activate()
        section.deactivate();
      }
    })
    this.isRebar&&  menu.push({
      title: 'Only Show Rebar',
      target: async () => {
        if(this.isRebar){
          let allDbIds = getAllElementdbIdsOneModel(this.viewer)
          _.forEach(allDbIds, modelAdbId => {
            this.viewer.hide(modelAdbId, this.viewer.model)
          })
          let temp = []
          _.forEach(allDbIds, id => {
            temp.push(this.checkParentForTekla(this.viewer, id))
          })
          temp = temp.concat(allDbIds)
          _.forEach(temp, modelAdbId => {
            this.viewer.model.getProperties(modelAdbId, (modelAProperty) => {
  
              _.forEach(modelAProperty.properties, property => {
                if (property.displayCategory === "Element" && property.displayName === "Category" &&
                  (property.displayValue === "Structural Rebar")) {
                  this.viewer.show(modelAdbId, this.viewer.model)
                  return false
                } else if (property.displayCategory === "Item" && property.displayName === "Type" &&
                  (property.displayValue === "Specialty Equipment")) {
  
                  this.viewer.show(modelAdbId, this.viewer.model)
                  return false
                }else if (property.displayCategory === "Item" && property.displayName === "Type" &&
                (property.displayValue === "Structural Rebar")) {
                this.viewer.show(modelAdbId, this.viewer.model)
                return false
              }
              })
  
            });
          })
        }
      
      }
    })
  }
  onMouseDown = (e) => {
    // this.container.removeEventListener('mousedown', this.onMouseDown);
    // console.log(this.viewer.getCutPlanes())

    if (e.which == 3) {
      const rect = this.container.getBoundingClientRect();
      this.x = e.clientX - rect.x;
      this.y = e.clientY - rect.y;
      // console.log( this.viewer.impl.intersectGround( this.x,  this.y))
      // let ext22 = this.viewer.getExtension('Autodesk.Viewing.MarkupsGui')
      // console.log(ext22)
      // ext22.core.enterEditMode()

    }
    // ext2.leaveEditMode()
  }

  load() {
    // Add my owned menu items
    this.viewer.registerContextMenuCallback(
      this.menuId,
      this.onBuildingContextMenuItem
    );

    return true;
  }

  unload() {
    // Remove all menu items added from this extension
    this.viewer.unregisterContextMenuCallback(this.menuId);

    return true;
  }
  checkParentForTekla(viewer, id) {
    let instanceTree = viewer.model.getData().instanceTree;
    let temp = []
    let queue = [];
    queue.push(id);
    while (queue.length > 0) {
      let node = queue.shift();
      let idChild = instanceTree.getNodeParentId(node)
      if (idChild) {
        queue.push(idChild);
        temp.push(idChild)
      }
      else {
        return temp[1]
      }
    }
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension('MenuContextWH', MenuContextWH);
export default 'MenuContextWH'