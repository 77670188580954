import React from 'react';
import { Tooltip, Typography } from 'antd';
import { Icon, SelectPicker } from 'rsuite';
import _ from 'lodash'
import { roles } from './Role'
import { checkWohhupUser } from '../function/AdminFunction'
const { Text } = Typography


export default (cbAccess, isAdmin) => {
  return ([

    {
      Header: 'Role', accessor: 'role', id: 'role', units: false, filter: "includes", alignLocal: 'left', widthLocal: 300, unableGroup: true,
    },
    {
      Header: () => {
        return (
          <Tooltip title={'Upload'} mouseEnterDelay={2}>
            Upload
            {/* <Icon icon='upload' style={{ color: 'black' }} /> */}
          </Tooltip>
        )
      }, accessor: 'isAccessUpload', id: 'isAccessUpload', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
      Cell: ({ cell: { row, value } }) => {
        return (
          <input type='checkbox'
            onChange={cbAccess.bind(this, row, 'isAccessUpload')}
            checked={value} />
        )
      }
    },
    {
      Header: () => {
        return (
          <Tooltip title={'Bim App'} mouseEnterDelay={2}>
            Bim App
            {/* <Icon icon='cube' style={{ color: 'red' }} /> */}
          </Tooltip>
        )
      }, accessor: 'isAccessBimApp', id: 'isAccessBimApp', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
      Cell: ({ cell: { row, value } }) => {
        return (
          row.original.role.includes('WH') ? <input type='checkbox' checked={true}
            disabled={true} />
            :
            <input type='checkbox' checked={value} 
           onChange={ cbAccess.bind(this, row, 'isAccessBimApp') }
      />
        )
    }
    },
{
  Header: () => {
    return (
      <Tooltip title={'Data Entry'} mouseEnterDelay={2}>
        Data Entry
        {/* <Icon icon='table' style={{ color: 'purple' }} /> */}
      </Tooltip>
    )
  }, accessor: 'isAccessDataEntry', id: 'isAccessDataEntry', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    Cell: ({ cell: { row, value } }) => {
      return (
        <input type='checkbox'
          onChange={cbAccess.bind(this, row, 'isAccessDataEntry')}
          checked={value} />
      )
    }
},
{
  Header: () => {
    return (
      <Tooltip title={'Worker Allocation'} mouseEnterDelay={2}>
        Worker Allocation
        {/* <Icon icon='group' style={{ color: 'orange' }} /> */}
      </Tooltip>
    )
  }, accessor: 'isAccessWorkerAllocation', id: 'isAccessWorkerAllocation', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    Cell: ({ cell: { row, value } }) => {
      return (
        <input type='checkbox'
          onChange={cbAccess.bind(this, row, 'isAccessWorkerAllocation')}
          // disabled={!isAdmin}
          checked={value} />
      )
    }
},
{
  Header: () => {
    return (
      <Tooltip title={'Drawing Management System'} mouseEnterDelay={2}>
        DMS
        {/* <Icon icon='dashboard' style={{ color: 'blue' }} /> */}
      </Tooltip>
    )
  }, accessor: 'isAccessDashboardDrawing', id: 'isAccessDashboardDrawing', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    Cell: ({ cell: { row, value } }) => {
      return (
        <input type='checkbox'
          onChange={cbAccess.bind(this, row, 'isAccessDashboardDrawing')}
          checked={value} />
      )
    }
},
{
  Header: () => {
    return (
      <Tooltip title={'QA/QC'} mouseEnterDelay={2}>
        QA/QC
        {/* <Icon icon='dashboard' style={{ color: 'blue' }} /> */}
      </Tooltip>
    )
  }, accessor: 'isAccessQAQC', id: 'isAccessQAQC', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    Cell: ({ cell: { row, value } }) => {
      return (
        <input type='checkbox'
          onChange={cbAccess.bind(this, row, 'isAccessQAQC')}
          checked={value} />
      )
    }
},
    // {
    //   Header: () => {
    //     return (
    //       <Tooltip title={'Civil-CIP'} mouseEnterDelay={2}>
    //         <Icon icon='charts-line' style={{ color: 'green' }} />
    //       </Tooltip>
    //     )
    //   }, accessor: 'gantt', id: 'gantt', units: false, filter: "includes", widthLocal: 40, unableGroup: true, isNoFilter: true,
    //   Cell: ({ cell: { row, value } }) => {
    //     return (
    //       <input type='checkbox' onChange={cbAccess.bind(this, row, 'isAccessGantt')} defaultChecked={value} />
    //     )
    //   }
    // },
  ])
}