import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";

import _ from "lodash";

const TableScoreByInspector = ({ data }) => {
   var data_sub = [];

   const columns = [
      {
         title: "Assessor Name",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 125,
         ellipsis: true,
         sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
         title: "as Assessor 1",
         children: [
            {
               title: "nos unit",
               dataIndex: "count1",
               key: "count1",
               align: "right",
               width: 75,
               sorter: (a, b) => a.count - b.count,
            },
            {
               title: "avg score",
               dataIndex: "avgScore1",
               key: "avgScore1",
               align: "right",
               width: 75,
               sorter: (a, b) => a.avgScore - b.avgScore,
            },
         ],
      },

      {
         title: "as Assessor 2",
         children: [
            {
               title: "nos unit",
               dataIndex: "count2",
               key: "count1",
               align: "right",
               width: 75,
               sorter: (a, b) => a.count - b.count,
            },
            {
               title: "avg score",
               dataIndex: "avgScore2",
               key: "avgScore2",
               align: "right",
               width: 75,
               sorter: (a, b) => a.avgScore - b.avgScore,
            },
         ],
      },

      {
         title: "Total",
         children: [
            {
               title: "nos unit",
               dataIndex: "count",
               key: "count",
               align: "right",
               width: 75,
               sorter: (a, b) => a.count - b.count,
            },
            {
               title: "avg Score",
               dataIndex: "avgScore",
               key: "avgScore",
               align: "right",
               width: 75,
               sorter: (a, b) => a.avgScore - b.avgScore,
            },
         ],
      },
   ];

   return (
      <div style={{ minHeight: "130px", marginLeft: "15px", marginRight: "15px" }}>
         <h5 style={{ textAlign: "left" }}>
            Unit assessed by:
            <Button type="default" size="small" style={{ float: "right", margin: 0 }}>
               <Icon type="download" />
            </Button>
         </h5>
         {data != undefined ? (
            <Table
               dataSource={data}
               columns={columns}
               size="small"
               scroll={{ y: "130px" }}
               pagination={false}
               rowKey="name"
               bordered
            />
         ) : null}
      </div>
   );
};

export default TableScoreByInspector;
