import React, {
  Component,
  dangerouslySetInnerHTML,
  memo,
  useMemo,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import $ from 'jquery-ui'
import _ from 'lodash';

import axios from 'axios';
import { message, Button } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {
  CellDatePicker,
  CellButton,
  CellContextmenu,
  LoadingScreen,
  CellButtonCastingReport,
  CellButtonDoNumber,
} from '../../module_DataEntry/customCell';
import moment from 'moment';
import {
  setDataEntryOpenHistory,
  setDataEntry,
} from '../../../actions/app/dataentry';

function DataGrid(props) {
  //  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {}, [props]);

  const onGridReady = (params) => {
    props.setDataEntry({
      gridApi: { api: params.api, columnApi: params.columnApi },
    });
  };

  function ButtonCastingReport() {
    return <Button>Cas</Button>;
  }
  //  useEffect(() => {
  //     if (gridRef.current) {
  //        props.setDataEntry({ gridApi: gridRef.current });
  //     }
  //  }, []);
  useEffect(() => {
    if (props.selectSheet && props.gridApi) {
      props.gridApi.api.showLoadingOverlay();
      window.historySheet = [];
      axios
        .get(`/api/dataentry/get-sheet5d-data`, {
          params: {
            projectId: props.projectId,
            sheetId: props.selectSheet.id,
            sheetName: props.selectSheet.name,
            token: props.userInfo.token,
          },
        })
        .then((res) => {
          // res.data[0]['Casting report'] = 'dsdsd' //!note
          let columns = [];
          _.forEach(props.selectSheet.columns, (v, k) => {
            let data = {
              field: v.name,
              enableRowGroup: true,
              enablePivot: true,
              editable: true,
              hide: false,
              initialWidth: v.width,
              resizable: true,
              headerTooltip: v.name,
              filter: 'agMultiColumnFilter',
              cellStyle: cellStyle,
            };
            if (k == 0) data.rowDrag = true;
            if (v.roleCanEdit.includes(props.dataUser.role)) {
              data.editable = false;
            }
            if (
              !v.roleCanView.includes(props.dataUser.role) ||
              [
                `Subcon / COL`,
                'Subcon / Toe',
                'Subcon / Blind bore Length (m)',
                'Subcon / Penetration Length (m)',
                'Subcon / Socket Length (m)',
                `Subcon / Data Cast`,
                'Subcon / Status',
              ].includes(v.name)
            ) {
              data.hide = true;
              data.enablePivot = false;
              data.lockVisible = true;
            }
            if (props.isAdmin) {
              data.editable = true;
            }

            data.editable = true;
            if (v.name === 'Casting report') {
              data.editable = false;
              data.cellRenderer = CellButtonCastingReport;
              data.cellClass = 'vAlign';
            } else if (v.name === 'Do No.') {
              data.editable = false;
              data.cellRenderer = (e) => CellButtonDoNumber(e, props);
              // data.cellRenderer =CellButtonDoNumber;
              data.cellClass = 'vAlign';
            } else if (v.type === 'dropdown') {
              data.cellEditor = 'agSelectCellEditor';
              data.cellEditorParams = { values: v.valueArray };
            } else if (v.type === 'date') {
              data.cellEditor = 'datePicker';
              data.cellClassRules = { currencyCell: 'typeof x == "date"' };
              data.valueParser = dateParser;
              data.filter = 'agDateColumnFilter';
              data.filterParams = {
                // provide comparator function
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  const dateAsString = cellValue;
                  if (dateAsString == null) {
                    return 0;
                  }
                  // In the example application, dates are stored as dd/mm/yyyy
                  // We create a Date object for comparison against the filter date
                  const dateParts = dateAsString.split('/');
                  const year = Number(dateParts[2]);
                  const month = Number(dateParts[1]) - 1;
                  const day = Number(dateParts[0]);

                  const cellDate = new Date(year, month, day);

                  // Now that both parameters are Date objects, we can compare
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                  } else if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                  }
                  return 0;
                },
              };
            } else if (v.type === 'numeric') {
              data.type = 'numericColumn';
              data.cellClassRules = { currencyCell: 'typeof x == "number"' };
              data.enableValue = true;
              data.valueParser = numberParser;
              data.aggFunc = 'sum';
              data.filter = 'agNumberColumnFilter';

              data.valueGetter = setValueForCellNumber;
            }
            columns.push(data);
          });
          columns.push(
            {
              field: 'externalIds',
              hide: true,
              width: 80,
              enablePivot: false,
              lockVisible: true,
              editable: false,
              suppressMovable: true,
            },
            {
              field: 'itemIds',
              hide: true,
              width: 80,
              enablePivot: false,
              lockVisible: true,
              editable: false,
              suppressMovable: true,
            },
            {
              field: 'globalOffset',
              hide: true,
              width: 80,
              enablePivot: false,
              lockVisible: true,
              editable: false,
              suppressMovable: true,
            }
          );
          setColumnDefs(columns);

          props.setDataEntry({ data: res.data });
          props.gridApi.api.hideOverlay();
        });
      // .catch(() => {
      //   message.warning(`Can't get sheet data`);
      //   gridRef.current.api.hideOverlay()
      // });
    }
  }, [props.selectSheet, props.gridApi]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      minWidth: 100,
      // flex: 1,
    }),
    []
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: ['columns', 'filters'],
    }),
    []
  );
  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { key: 'myStatusPanel', statusPanelFramework: MyStatusPanel },
      ],
    }),
    []
  );

  const onCellValueChanged = (e) => {
    if (e.oldValue === e.newValue) return;
    let copy = e.data;
    let index = _.findIndex(window.historySheet, (o) => {
      return o.id === copy.id;
    });
    if (index >= 0) {
      copy.row_status = window.historySheet[index].row_status
        ? window.historySheet[index].row_status
        : 'update';
      window.historySheet.splice(index, 1, copy);
    } else {
      copy.row_status = 'update';
      window.historySheet.push(copy);
    }

    props.handleColorStatus(props.dataEntryData, false); //!update color
  };
  const onRangeSelectionChanged = (e) => {
    if (props.isCorrespond && props.viewerCurrent) {
      let check = [];
      let temp = [];
      _.forEach(props.gridApi.api.getSelectedNodes(), (rowNode) => {
        if (rowNode.group) {
          _.forEach(rowNode.allLeafChildren, (v) => {
            if (!check.includes(v.data.id)) {
              check.push(v.data.id);
              temp.push(v.data);
            }
          });
        } else {
          if (!check.includes(rowNode.data.id)) {
            check.push(rowNode.data.id);
            temp.push(rowNode.data);
          }
        }
      });

      props.setDataEntry({ selectedNode: temp });
    }
  };
  useEffect(() => {
    if (props.gridApi) {
      try {
        if (props.dataEntryLoading) props.gridApi.api.showLoadingOverlay();
        else props.gridApi.api.hideOverlay();
      } catch {}
    }
  }, [props.dataEntryLoading, props.gridApi]);

  return (
    <AgGridReact
      headerHeight={80}
      className='ag-theme-balham'
      reactUi='true'
      sideBar={sideBar}
      rowDragManaged={true}
      components={{ datePicker: CellDatePicker(), cellButton: CellButton }}
      modules={AllModules}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowGroupPanelShow='always'
      enableRangeSelection='true'
      rowSelection='multiple'
      rowMultiSelectWithClick={true}
      rowData={props.dataEntryData}
      loadingOverlayComponentFramework={LoadingScreen}
      allowContextMenuWithControlKey={true}
      getContextMenuItems={CellContextmenu.bind(this, {
        projectId: props.projectId,
        sheetId: props.selectSheet?.id,
        handleOpenModelInRow: props.handleOpenModelInRow,
        handleSelectObjectInRow: props.handleSelectObjectInRow,
        handleStoreDeleteRowsInHistorySheet:
          props.handleStoreDeleteRowsInHistorySheet,
        setDataEntryOpenHistory: props.setDataEntryOpenHistory,
        platform: '5D',
        setShowCastingReport: props.setShowCastingReport,
        setExportReportData: props.setExportReportData,
        setShowCastingReportHistory: props.setShowCastingReportHistory,
        setCurrentCastingReportHistory: props.setCurrentCastingReportHistory,
        table: props.dataEntryData,
        setDataEntry: (e) => {
          props.setDataEntry({ data: e });
        },
      })}
      onCellValueChanged={onCellValueChanged}
      onRangeSelectionChanged={onRangeSelectionChanged}
      groupDisplayType='multipleColumns'
      onGridReady={onGridReady}
    />
  );
}

DataGrid.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  excelPage: PropTypes.bool.isRequired,
  dataEntryData: PropTypes.object.isRequired,
  dataEntryLoading: PropTypes.bool.isRequired,
  setDataEntryOpenHistory: PropTypes.func.isRequired,
  setDataEntry: PropTypes.func.isRequired,
  isCorrespond: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    dataEntryData: state.dataentry.data,
    dataEntryLoading: state.dataentry.loading,
    isCorrespond: state.dataentry.isCorrespond,
    gridApi: state.dataentry.gridApi,
  };
}
export default connect(mapStateToProps, {
  setDataEntryOpenHistory: setDataEntryOpenHistory,
  setDataEntry: setDataEntry,
})(DataGrid);

const MyToolPanel = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      sampleToolPanelMethod() {
        setValue((value) => value + 1);
      },
    };
  });

  return (
    <div className='my-tool-panel'>
      <div>Sample Tool Panel</div>
      <div className='my-tool-panel-value'>{value}</div>
    </div>
  );
});

const MyStatusPanel = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      sampleStatusPanelMethod() {
        setValue((value) => value + 1);
      },
    };
  });

  return (
    <div className='my-status-panel'>
      <span>Sample Status Panel</span>
      <span className='my-status-panel-value'>{value}</span>
    </div>
  );
});

function numberParser(params) {
  return sharedNumberParser(params.newValue);
}

function sharedNumberParser(value) {
  if (value === null || value === undefined || value === '') {
    return 0;
  }
  let v = parseFloat(value);
  if (_.isNumber(v) && !_.isNaN(v)) return v;
  else return 0;
}

function dateParser(params) {
  let value = params.newValue;
  if (value === null || value === undefined || value === '') {
    return '';
  }
  if (_.isDate(value)) return value;
  else return '';
}
function setValueForCellNumber(params) {
  let value = params.data[params.colDef.field];
  if (value === null || value === undefined || value === '') {
    return 0;
  } else {
    let v = parseFloat(value);
    if (_.isNumber(v) && !_.isNaN(v)) return v;
    else return 0;
  }
}

function setValueForCellDate(params) {
  let value = params.data[params.colDef.field];
  if (value === null || value === undefined) {
    return '';
  } else {
    try {
      // if (moment(value, "DD/MM/YYYY", true).isValid()) return value
      return '';
    } catch {
      return '';
    }
  }
}

function cellStyle(params) {
  if (!params.node.group) {
    if (params.data.compareObject) {
      let data = params.data.compareObject[params.column.colId];
      if (data) {
        if (data.status === 'down') {
          return { backgroundColor: '#ff5e5e' };
        } else if (data.status === 'up') {
          return { backgroundColor: '#5eff6c' };
        }
        if (data.status === 'change') {
          return { backgroundColor: '#ffc75e' };
        }
      }
    }
  }
}
