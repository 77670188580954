import axios from "axios";
import queryString from "query-string";

const axiosClient = axios.create({
  //  baseURL: 'http://3.141.1.120:8081/',
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  try {
    if (
      config.method == "get" ||
      (config.data != undefined && config.data instanceof FormData)
    ) {
      config.params = { ...config.params, token: getLocalToken() };
    } else {
      config.data = { ...config.data, token: getLocalToken() };
    }
  } catch (error) {
    config.params = { ...config.params, token: getLocalToken() };
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

getLocalToken();

function getLocalToken() {
  const token = window.localStorage.getItem("bookwormJWT");
  return token;
}

export default axiosClient;
