import React from 'react';
import { Checkbox, Modal, TagPicker, Button } from 'rsuite';
import { Input } from 'semantic-ui-react'
import $ from 'jquery';

import _ from 'lodash'
import {TableCFA} from '../tables'
import Dock from 'react-dock'
import {
  getSubRowsTotal, getSubRowsTotalCFA, getSubRowsCountWithOutElementId, formatNumber
} from '../function/TableFunction'


class BottomBarCFA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPanelCFA: [],
      columnsPanelCFA: [],
      skipPageReset: false,
      originalData: [],
      calcAvSlab: {},

      heightDock: 500,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,

      openPanelEditType: false,
      listLevelGroup: [],
      indeterminate: false,
      checkAll: false,
      levelSelected: [],
      oldTypeValue: '',
      newTypeValue: '',
      listGuidElement:{}
    };
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.displayPanel) {
      this.setState({
        maxForgeViewer: $('#splitForgeViewer').height(),
        modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock
      }, () => {
        $('#splitForgeViewer').height(this.state.modifyForgeViewer)
        nextProps.viewer.resize()
      })
    }
  }
  componentDidMount = () => {
    this.setState({
      columnsPanelCFA: [
        {
          Header: 'WH_Zone',
          accessor: 'WH_Zone',
          id: 'WH_Zone',
          aggregate: 'count',
          canEdit: false,
          units: false,
          filter: "includes",
          Aggregated: ({ cell: { row } }) => (
            `${getSubRowsCountWithOutElementId(row.subRows)}`
          )
        },
        {
          Header: 'WH_Level',
          accessor: 'WH_Level',
          id: 'WH_Level',
          aggregate: 'count',
          canEdit: false,
          units: false,
          filter: "includes",
          Aggregated: ({ cell: { row } }) => (
            `${getSubRowsCountWithOutElementId(row.subRows)}`
          )
        },
        {
          Header: 'WH_Group',
          accessor: 'WH_Group',
          id: 'WH_Group',
          aggregate: 'count',
          canEdit: false,
          units: false,
          filter: "includes",
          Aggregated: ({ cell: { row } }) => (
            `${getSubRowsCountWithOutElementId(row.subRows)}`
          )
        },
        {
          Header: 'Type Name',
          accessor: 'TypeName',
          id: 'TypeName',
          aggregate: 'average',
          canEdit: true,
          units: false,
          filter: "includes",
          Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`,
        },
        {
          Header: 'Volume',
          accessor: 'Volume',
          id: 'Volume',
          aggregate: 'sum',
          canEdit: false,
          units: true,
          filter: "between",
          Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`,
          Footer: info => {
            const total = React.useMemo(
              () => getSubRowsTotal(info.flatRows, info.column.id, 'TypeName', 'CFA'),
              [info.rows]
            )

            return <>{total.toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</>
          },
        },
        {
          Header: 'CFA',
          accessor: 'CFA',
          id: 'CFA',
          aggregate: 'average',
          canEdit: false,
          units: true,
          filter: "between",
          Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`,
          Footer: info => {
            const total = React.useMemo(
              () => getSubRowsTotalCFA(info.flatRows, info.column.id),
              [info.rows]
            )

            return <>{total.toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</>
          },
        },
        {
          Header: 'Average Slab',
          accessor: 'Average Slab',
          id: 'Average Slab',
          aggregate: 'average',
          canEdit: false,
          units: true,
          filter: "between",
          Aggregated: ({ cell: { value } }) => `${formatNumber(value)}`,
        
        }],
    })

  }
  handleUpdateData = (nodes, columnId,value) => {
    let tempData = this.state.dataPanelCFA
    _.forEach(nodes,node=>{
      tempData[node.id][columnId] = parseFloat(value)
      tempData[node.id]['Volume'] = tempData[node.id]['TypeName'] * tempData[node.id]['Area']
    })

  

    let temp = {}
    _.forEach(tempData, v => {
      if (!temp[v.WH_Level + v.WH_Zone])
        temp[v.WH_Level + v.WH_Zone] = 0
      temp[v.WH_Level + v.WH_Zone] = (temp[v.WH_Level + v.WH_Zone] + v.Volume)

    })
    _.forEach(temp, (v, k) => {
      _.forEach(tempData, v1 => {
        if (k === v1.WH_Level + v1.WH_Zone) {
          v1['Average Slab'] = Math.round((v / v1.CFA + Number.EPSILON) * 100) / 100
        }
      })
    })
    this.setState({ dataPanelCFA: [], columnsPanelCFA: this.state.columnsPanelCFA, skipPageReset: true }, () => {
      this.setState({ dataPanelCFA: tempData })
    })
  }
  handleReadData = (data) => {
    var _this =this
    let temp = {}
    let listLevelGroup = []
    _.forEach(data, v => {
      if (!temp[v.WH_Level + v.WH_Zone]) {
        temp[v.WH_Level + v.WH_Zone] = 0
        listLevelGroup.push({ label: v.WH_Level, value: { level: v.WH_Level, zone: v.WH_Zone }, group: v.WH_Zone })
      }
      temp[v.WH_Level + v.WH_Zone] = (temp[v.WH_Level + v.WH_Zone] + v.Volume)

    })
    _.forEach(temp, (v, k) => {
      _.forEach(data, v1 => {
        if (k === v1.WH_Level + v1.WH_Zone) {
          v1['Average Slab'] = Math.round((v / v1.CFA + Number.EPSILON) * 100) / 100
        }
      })
    })

    let tempModelWithDbId =[]
    let count = 0
    let tempListGuidElement={}
    const models = this.props.viewer.impl.modelQueue().getModels();
    _.forEach(models, (model) => {
      let instanceTree = model.getData().instanceTree;
      let temp = this.getAllModelsElementdbIds(instanceTree);
      count = count + temp.length
      tempModelWithDbId.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride})
    })
    _.forEach(tempModelWithDbId,v=>{
      _.forEach(v.dbIds, (modelAdbId) => {
        v.model.getProperties(modelAdbId, function (modelAProperty) {
          var name = modelAProperty.name.split(" [")[0]
          tempListGuidElement[modelAProperty.externalId] = { 'dbId': modelAdbId, name: name, modelName:tempModelWithDbId.modelName, model: tempModelWithDbId.model};
          count--;
          if (count === 0) {
            _this.setState({ dataPanelCFA: data, listLevelGroup: listLevelGroup,listGuidElement:tempListGuidElement })
          }
        })
      })
    }) 
  }
  getAllModelsElementdbIds = (instanceTree) => {
    let temp = [];
    if (!instanceTree) {
      return temp;
    }
    let queue = [];
    queue.push(instanceTree.getRootId());
    while (queue.length > 0) {
      var node = queue.shift();
      if (instanceTree.getChildCount(node) !== 0) {
        instanceTree.enumNodeChildren(node, function (childrenIds) {
          queue.push(childrenIds);
        });
      }
      else {
        temp.push(node);
      }
    }
    return temp;
  }
  onChangeHeader = (id, text) => {
    let temp = []
    _.forEach(this.state.columnsPanelCFA, v => {
      if (id == v.id) {
        v.filter = text
        temp.push(v)
      } else {
        temp.push(v)
      }
    })
    this.setState({ columnsPanelCFA: temp })
  }
  onActiveTable = () => {
    if (this.props.displayPanel) {
      $('#splitForgeViewer').height(this.state.maxForgeViewer)
      this.props.onDisplayPanelChange('cfaPanel', false)
      this.props.viewer.resize()
    }
  }
  sizeChange = (e) => {
    if (this.props.displayPanel) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
      })
    }
  }
  handleClosePanelEditType = () => {
    this.setState({ openPanelEditType: false })
  }
  handleOpenPanelEditType = (row, value) => {
    this.setState({ openPanelEditType: true, oldTypeValue: value })
  }
  handleChangeLevel = (value) => {
    if (value === null) {
      this.setState({
        levelSelected: [],
        indeterminate: false
      })
    } else {
      this.setState({
        levelSelected: value,
        indeterminate: value.length > 0 && value.length < this.state.listLevelGroup.length,
        checkAll: value.length === this.state.listLevelGroup.length
      })
    }
  }
  handleCheckAll(checked) {
    const nextValue = checked ? this.state.listLevelGroup : [];
    let temp = []
    _.forEach(nextValue, v => {
      temp.push(v.value)
    })

    this.setState({
      levelSelected: temp,
      indeterminate: false,
      checkAll: checked
    });

  }
  onChangeTypeValue = (e) => {
    this.setState({ newTypeValue: e.target.value })
  }
  handleChangeTypeFollowLevel=()=>{
    let temp=[]
    let tempData=this.state.dataPanelCFA
    _.forEach(this.state.levelSelected,v=>{
      temp.push(v.level+v.zone+this.state.oldTypeValue)
    })
    _.forEach(tempData,v=>{
      if(_.includes(temp,v.WH_Level+v.WH_Zone+v.TypeName)){
        v.TypeName = this.state.newTypeValue
      }
    })
    this.setState({ dataPanelCFA: [] }, () => {
      this.setState({ dataPanelCFA: tempData ,openPanelEditType: false})
    })
  }
  render() {

    return (
      <div>
        <Dock position={'bottom'} isVisible={this.props.displayPanel} dimMode={'none'} fluid={false} zIndex={200} size={this.state.heightDock}
          onSizeChange={this.sizeChange} >
          <TableCFA columns={this.state.columnsPanelCFA} data={this.state.dataPanelCFA}
            viewer={this.props.viewer}
            getRowProps={() => ({})}
            getCellProps={() => ({})}
            onActiveTable={this.onActiveTable}
            onChangeHeader={this.onChangeHeader}
            handleUpdateData={this.handleUpdateData}
            skipPageReset={this.state.skipPageReset}
            handleReadData={this.handleReadData}
            handleOpenPanelEditType={this.handleOpenPanelEditType}
            listGuidElement={this.state.listGuidElement}
          />
        </Dock>
        <Modal show={this.state.openPanelEditType} onHide={this.handleClosePanelEditType} style={{ paddingTop: '3%', width: '31%' }} backdrop="static">
          <Modal.Header>
            <Modal.Title>Edit Type</Modal.Title>
          </Modal.Header>
          <Modal.Body >

            <Input size='small' disabled={true} defaultValue={this.state.oldTypeValue}  label={'Old'}>
            </Input>
            <Input style={{ float: 'right'  }} type="number" size='small'  label={'New'}   labelPosition='right'
              defaultValue={this.state.oldTypeValue} value={this.state.newTypeValue} onChange={this.onChangeTypeValue} >
            </Input>
            <p></p>
            <TagPicker data={this.state.listLevelGroup} style={{ width: '100%' }} size="xs" placeholder="Select Level"
              onChange={this.handleChangeLevel} groupBy="group" value={this.state.levelSelected}
              renderExtraFooter={() => (
                <div style={{ padding: '10px 2px', borderTop: '1px solid #e5e5e5' }}>
                  <Checkbox inline indeterminate={this.state.indeterminate} checked={this.state.checkAll} onChange={this.handleCheckAll.bind(this)} style={{ color: 'blue' }}>
                    Check all
                  </Checkbox>
                </div>
              )} />
          </Modal.Body>
          <Modal.Footer>
            <Button color='blue' on loading={this.state.lodingBtnVersion} disabled={this.state.newTypeValue === ''||this.state.levelSelected.length ===0}
            onClick={this.handleChangeTypeFollowLevel}>
              Apply
            </Button>
            <Button onClick={this.handleClosePanelEditType} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default (BottomBarCFA)

