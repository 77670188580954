import React, { useState, useEffect } from 'react';

import {
  searchWorker,
  deletes,
  addMulti,
  addAvatar,
  getAvatar,
} from '../../api/employee';
import { search as searchNationality } from '../../api/nationality';
import { apiGetDataOccupations } from '../../api/dash-board';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import {
  Table,
  Divider,
  Icon,
  Pagination,
  Modal,
  notification,
  Row,
  Col,
  Avatar,
  Tabs,
  Input,
  Button,
  Upload,
  Popconfirm,
} from 'antd';
import WorkerForm from '../Forms/WorkerForm';
import LevyForm from '../Forms/LevyForm';
import PayRateForm from '../Forms/PayRateForm';
import TopActionBar from '../Common/TopActionBar';
import AvatarEmployee from '../Common/AvatarEmployee';
import moment from 'moment';

const { TabPane } = Tabs;

function Worker(props) {
  const [fileExcel, setFileExcel] = useState(null);
  const [intRefeshAvatar, setIntRefreshAvatar] = useState(1);
  const [searchText, setSearchText] = useState();
  const [searchInput, setSearchInput] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const [rows, setRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [id, setId] = useState(null);
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleLevyPayrate, setVisibleLevyPayrate] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const [nationalities, setNationalities] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);

  const [occupations, setOccupations] = useState([]);

  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10,
    showAll: false,
    projectId: projectId,
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    total: 1,
  });

  useEffect(() => {
    getDatas();
  }, [searchRequest]);

  useEffect(() => {
    const getNationality = async () => {
      let res = await searchNationality({ pageSize: 1000 });
      await setNationalities(res.data?.items);
    };

    getNationality();

    apiGetDataOccupations().then((res) => {
      setOccupations(res.data);
    });
  }, []);

  const getDatas = async () => {
    let res = await searchWorker(searchRequest);
    if (_.isNil(res.data?.items)) {
      await setDatas([]);
    } else {
      await setDatas(_.orderBy(res.data?.items, 'name'));
    }

    await setPagination({
      pageSize: res.data.pageSize,
      showSizeChanger: true,
      total: res.data.totalCount,
    });

    setIntRefreshAvatar(intRefeshAvatar + 1);
  };

  const onChange = async (current, pageSize) => {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  };

  const handleSearch = async (e, isShowResigned) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
      isShowResigned,
    });
  };

  const handleSearchResigned = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      showAll: e.target.checked,
    });
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }
    setSelectedRowKeys([]);
    await getDatas();
  };

  const onSelectedRowKeysChange = (selectedRowKeys, rows) => {
    setRows(rows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleShowDialogEdit = (record) => {
    setId(record.id);
    setRecord(record);
    setIsAdd(false);
    setVisible(true);
  };

  const handleShowDialogLevy = (record) => {
    setId(record.id);
    setRecord(record);
    setVisibleLevyPayrate(true);
  };

  function handleShowDialogAdd() {
    setIsAdd(true);
    setVisible(true);
  }

  function getFilters(key) {
    if (_.isArray(datas)) {
      let items = datas
        .filter((x) => x)
        .map((x) => ({
          text: x[key],
          value: x[key],
        }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  function getColumnSearchProps(dataIndex) {
    let a = {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearchByKey(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearchByKey(selectedKeys, confirm, dataIndex)}
            icon='search'
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type='search'
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex] &&
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ?? text.toString()}
          />
        ) : (
          text
        ),
    };

    return a;
  }

  function handleSearchByKey(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  function handleImport(file) {
    setFileExcel(file);
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;

      let workbook = await wb.xlsx.load(buffer);
      let datas = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex != 1) {
            let e = {
              employeeCode: row.values[1],
              employerCode: row.values[2],
              name: row.values[3],
              workPermitFinNo: row.values[4],
              company: row.values[5],
              phoneNumber: row.values[6],
              dateOnInSiteJoin: row.values[7],
              designation: row.values[8],
              nationality: row.values[9],
              age: row.values[9],
              termination: row.values[10],
              employeeType: 2,
            };

            if (e.employeeCode) {
              datas.push(e);
            }
          }
        });
      });

      try {
        if (!_.isArray(datas) || datas.length < 1) {
          notification['error']({
            message: 'Import Error',
            description: 'There is no data in the excel !',
          });
        }

        let res = await addMulti({
          projectId,
          employees: datas,
        });

        notification['success']({
          message: 'Import',
          description: 'Import worker successfully!',
        });

        await getDatas();
      } catch (error) {
        notification['error']({
          message: 'Import Error',
          description: error.response.message,
        });
      }
    };

    return false;
  }

  let columns = [
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      className: '',
      filters: getFilters('employeeCode'),
      onFilter: (value, record) => record.employeeCode.indexOf(value) === 0,
      width: 70,
    },
    // {
    //   title: 'Employer Code',
    //   dataIndex: 'employerCode',
    //   key: 'employerCode',
    //   width: 75,
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      ...getColumnSearchProps('name'),
    },
    // {
    //   title: 'Work Permit Fin No',
    //   dataIndex: 'workPermitFinNo',
    //   key: 'workPermitFinNo',
    //   width: 80,
    // },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      filters: getFilters('company'),
      onFilter: (value, record) => record.company.indexOf(value) === 0,
      width: 90,
    },
    // {
    //   title: 'Phone Number',
    //   dataIndex: 'phoneNumber',
    //   key: 'phoneNumber',
    //   width: 90,
    //   ...getColumnSearchProps('phoneNumber'),
    // },

    {
      title: 'Occupation',
      dataIndex: 'occupation',
      key: 'occupation',
      width: 90,
    },

    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      filters: getFilters('designation'),
      onFilter: (value, record) => record.designation.indexOf(value) === 0,
      width: 90,
    },
    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
      filters: getFilters('nationality'),
      onFilter: (value, record) => record.nationality.indexOf(value) === 0,
      width: 90,
    },
    {
      title: 'Transfer In Date',
      key: 'dateOfInSiteJoint',
      width: 70,
      render: (record) => {
        if (record && record.dateOfInSiteJoint !== null) {
          return (
            <span>{moment(record.dateOfInSiteJoint).format('DD/MM/YYYY')}</span>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: 'Transfer out date',
      key: 'termination',
      render: (record) => {
        if (record && record.termination !== null) {
          return <span>{moment(record.termination).format('DD/MM/YYYY')}</span>;
        } else {
          return '';
        }
      },
      width: 80,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (record) => (
        <span>
          {record.termination && moment(record.termination).isBefore(moment)
            ? 'Resigned'
            : 'Active'}
        </span>
      ),
      width: 60,
    },
    {
      title: 'Picture',
      key: 'picture',
      render: (record) => <AvatarEmployee imageId={record.avatar} />,
      width: 80,
    },
    {
      title: 'Last Month',
      key: 'lastMonth',
      children: [
        {
          title: () => <span className='text-center'>Pay Rate</span>,
          key: 'lastPayRate',
          render: (record) => <span>${record?.previousPayRate?.rate}</span>,
          width: 60,
        },
        {
          title: 'Levy',
          key: 'lastLevy',
          render: (record) => <span>${record?.previosLevy?.levy}</span>,
          width: 60,
        },
      ],
    },
    {
      title: 'Current Month',
      key: 'currentMonth',
      children: [
        {
          title: () => <span>Pay Rate</span>,
          key: 'currentPayRate',
          render: (record) => (
            <span
              className={record.isPayRateSame ? '' : 'bg-warning text-dark'}
            >
              ${record?.currentPayRate?.rate}
            </span>
          ),
          width: 60,
        },
        {
          title: 'Levy',
          key: 'currentLevy',
          render: (record) => (
            <span
              className={
                record.isLevySame ? 'w-100' : 'bg-warning w-100 text-dark'
              }
            >
              ${record?.currentLevy?.levy}
            </span>
          ),
          width: 60,
        },
      ],
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (record) => (
        <span>
          <Icon
            onClick={() => handleShowDialogEdit(record)}
            style={{ fontSize: '20px', color: '#08c' }}
            type='edit'
          />

          {props.permissionType === 1 && [
            <Divider type='vertical' />,
            <Icon
              onClick={() => handleShowDialogLevy(record)}
              style={{ fontSize: '20px', color: '#08c' }}
              type='pay-circle'
            />,
          ]}

          <Divider type='vertical' />

          <Popconfirm
            title='Are you sure delete this worker?'
            onConfirm={() => handleDeleteItems([record.id])}
            okText='Yes'
            cancelText='No'
          >
            <Icon style={{ fontSize: '20px', color: '#08c' }} type='delete' />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const isTerminated = (record) => {
    if (record.termination) {
      let terminationDate = new Date(record.termination);
      var now = Date.now();
      if (now > terminationDate) {
        return true;
      }
    }

    return false;
  };

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <div>
            <div className='d-flex flex-row  mb-2'>
              <TopActionBar
                className='mr-2'
                onChangeShowResigned={(e) => handleSearchResigned(e)}
                handleSearch={handleSearch}
                handleShowDialogAdd={handleShowDialogAdd}
                // handleDeleteItems={handleDeleteItems}
                handleImport={handleImport}
              />
              {/* <Upload
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                beforeUpload={handleImport}
              >
                <Button>
                  <Icon type='upload' /> Import
                </Button>
              </Upload> */}
            </div>

            <Table
              loading={_.isNil(datas)}
              rowClassName={(record, index) =>
                isTerminated(record) ? 'text-warning' : ''
              }
              className='antTable'
              columns={columns}
              dataSource={datas}
              rowKey={(record) => record.id}
              bordered
              // scroll={{ x: 2000, y: 500 }}
              scroll={{ x: 1800 }}
              pagination={false}
            ></Table>

            <Pagination
              className='mt-2 float-right'
              onChange={onChange}
              onShowSizeChange={onChange}
              total={pagination.total}
              showSizeChanger
            />

            <Modal
              width={900}
              title='Worker'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <WorkerForm
                occupations={occupations}
                nationalities={nationalities}
                id={id}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>

            <Modal
              visible={visibleLevyPayrate}
              onCancel={() => setVisibleLevyPayrate(false)}
              footer={null}
              title={null}
            >
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Levy' key='1'>
                  <LevyForm idEmployee={id} />
                </TabPane>
                <TabPane tab='Pay Rate' key='2'>
                  <PayRateForm idEmployee={id} />
                </TabPane>
              </Tabs>
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Worker;
