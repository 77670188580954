import React from 'react';
import { Icon, TagPicker,Tag } from 'rsuite';
import { message } from 'antd';
import _ from 'lodash'
import Draggable from 'react-draggable';


class DialogHyperModeling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listViewToLink: [],
      listViewSelectedToLink:[],
      loading: false,
      hyperModeling: null
    }
  }


  componentWillMount = () => {
    if(this.props.viewer.impl.model.is3d()){
      let extension =this.props.viewer.getExtension('Autodesk.AEC.Hypermodeling')
      this.setState({ loading: true , hyperModeling : extension},()=>{
        let temp = []
        _.forEach(this.props.aecModelData.levels, (item, k) => {
          var list = this.state.hyperModeling.getAvailableSheetsForLevel(k)
          if (list.length !== 0) {
            _.forEach(list, (v,key )=> {
              temp.push({ label: v.node.data.name, value:k+'_'+key, group: item.name ,index:{level:k, sheet:key} })
            })
          }
        })
        this.setState({ listViewToLink: temp, loading: false })
      })
     
    
    }else{
      message.warn('Just use for view 3D')
    }
 
    // hypermodeling.loadSheetFromLevel(k, 1)
  }
  componentWillUnmount = () => {
  }


  handleCloseDock = () => {
    this.props.onChangeDockDisplay('hyperModelingDock', false)
    this.state.hyperModeling.unloadAll()
  }
  handleChangeLinkView = (value) => {
    this.state.hyperModeling.unloadAll()
    if(value ===null)
    value =[]
    _.forEach(value,v=>{
      let split = v.split('_')
      this.state.hyperModeling.loadSheetFromLevel(split[0],split[1])
    })
    this.setState({listViewSelectedToLink:value})
  }
  handleClearAllLink=()=>{
    this.state.hyperModeling.unloadAll()
  }
  render() {
    return (
      this.props.viewer.impl.model.is3d()&&
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 100, width: 200 ,backgroundColor:'white'}}
          id='custom-precast-summary-dock' >
          <div className='custom-dock-panel-title'>Link View 2D</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
            <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
              <TagPicker data={this.state.listViewToLink} style={{ width: '100% ' }} size="sm" placeholder="View 2D"
                onChange={this.handleChangeLinkView} cleanable={true} groupBy="group" onClean={this.handleClearAllLink}
                value={this.state.listViewSelectedToLink} maxHeight={320} placement='rightStart'
                renderMenu={menu => {
                  if (this.state.loading) {
                    return (
                      <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Icon icon="spinner" spin /> Loading...
                    </p>
                    );
                  }
                  return menu;
                }}
                renderMenuItem={(label) => {
                  return (
                    <div >
                      <i className={"rs-icon rs-icon-newspaper-o"} /> {label}
                    </div>
                  );
                }}
                renderMenuGroup={(label, item) => {
                  return (
                    <div>
                      <i className={"rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
                </div>
                  );
                }}
              //   renderValue={(value, item) => {
              //     return (
              //       <div >
              //         <span >
              //           <i className={"rs-icon rs-icon-newspaper-o"} /> :
              // </span>{' '}
              //         {item.label}
              //       </div>
              //     );
              //   }} 
                renderValue={(values, items, tags) => {
                  return items.map((tag, index) => (
                    <Tag >
                          <Icon icon={'newspaper-o'} /> {tag.label}
                      </Tag> 
                  ));
                }}
                />

            </div>
          </div>
          <div className='custom-dock-panel-footer' />
        </div>
      </Draggable>
              

    );
  }
}


export default DialogHyperModeling