import React, { useEffect, useState } from 'react';
import { Modal, IconButton, Icon, Input, Button, ButtonGroup, Loader, Divider, SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { Row, Col, List, Typography, message, Tooltip } from 'antd';
import { formatNumber } from '../../function/TableFunction'
import { MenuProvider, animation } from 'react-contexify';
import { Checkbox } from "@blueprintjs/core";
import axios from "axios";
import { v4 } from 'uuid'


const { Text } = Typography

function compare(a, b) {
  let nameA = a;
  let nameB = b;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

const optionRule = [
  { text: 'AND (All rules must be true)', label: 'AND (All rules must be true)', value: 'AND (All rules must be true)', },
  { text: 'OR (Any rules may be true)', label: 'OR (Any rules may be true)', value: 'OR (Any rules may be true)', },
]
const optionsConditionNumber = [
  { label: 'equals', text: 'equals', value: 'equals', isNumber: true },
  { label: 'does not equals', text: 'does not equals', value: 'does not equals', isNumber: true },
  { label: 'is greater than', text: 'is greater than', value: 'is greater than', isNumber: true },
  { label: 'is greater than or equals', text: 'is greater than or equals', value: 'is greater than or equals', isNumber: true },
  { label: 'is less than', text: 'is less than', value: 'is less than', isNumber: true },
  { label: 'is less than or equals', text: 'is less than or equals', value: 'is less than or equals', isNumber: true },
]
const optionsConditionText = [
  { label: 'equals', text: 'equals', value: 'equals', isNumber: true },
  { label: 'does not equals', text: 'does not equals', value: 'does not equals', isNumber: true },
  { label: 'contains', text: 'contains', value: 'contains', },
  { label: 'does not contain', text: 'does not contain', value: 'does not contain', },
  { label: 'begins with', text: 'begins with', value: 'begins with', },
  { label: 'does not begin with', text: 'does not begin with', value: 'does not begin with', },
  { label: 'ends with', text: 'ends with', value: 'ends with', },
  { label: 'does not end with', text: 'does not end with', value: 'does not end with', },
  { label: 'has a value', text: 'has a value', value: 'has a value', isNumber: true },
  { label: 'has no value', text: 'has no value', value: 'has no value', isNumber: true },
]

function DialogFilter(props) {
  const [openPanel, setOpenPanel] = useState(false)
  const [openPanelAddFilter, setOpenPanelAddFilter] = useState(false)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [combineParameter, setCombineParameter] = useState([])
  const [listCondition, setListCondition] = useState([])
  const [rule, setRule] = useState(optionRule[0].value)
  const [disableAddCondition, setDisableAddCondition] = useState(false)


  const [listFilter, setListFilter] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [titlePanel, setTitlePanel] = useState('')
  const [selectedFilterEdit, setSelectedFilterEdit] = useState(null)

  useEffect(() => {
    let tempListFilter = []
    let tempData = props.data.slice(0)
    _.forEach(tempData, v => {
      if (v.itemId === props.itemId) {
        tempListFilter.push(Object.assign({}, v))
      }
    })
    setListFilter(tempListFilter)
    let tempFilter = props.selectedFilter === null ? null : Object.assign({}, props.selectedFilter)
    let tempCategory = props.category.slice(0)
    if (tempFilter) {
      _.forEach(tempCategory, v => {
        let index = _.findIndex(tempFilter.category, e => { return e.label === v.label })
        if (index < 0) {
          v.checked = false
        } else {
          v.checked = tempFilter.category[index].checked
        }
      })
      setCategory(tempCategory)
      setSelectedFilter(tempFilter)
      setRule(tempFilter.rule)
      setListCondition(tempFilter.listCondition)
    } else {
      _.forEach(tempCategory, v => {
        v.checked = false
      })
      setSelectedFilter(tempFilter)
      setCategory(tempCategory)
      setRule('AND (All rules must be true)')
      setListCondition([])
    }
    setOpenPanel(props.openPanel)
  }, [])

  useEffect(() => {
    let tempCombine = []
    let combineCategory = []
    let tempArrayParameter = []
    let check = true
    _.forEach(category, item => {
      if (!item.checked) return
      check = false
      combineCategory.push({ label: item.label, text: item.label, value: item.label })
      _.forEach(item.value.property, v => {
        if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
          && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label') {
          tempCombine.push({ label: v.displayName, text: v.displayName, value: v.displayName, units: v.units })
          tempArrayParameter.push(v.displayName)
        }
      })
    })
    if (combineCategory.length > 1) {
      let intersection = _.filter(tempArrayParameter, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
      let resolveParameter = []
      _.forEach(tempCombine, v => {
        if (intersection.includes(v.label)) {
          let index = _.findIndex(resolveParameter, e => { return e.label === v.label })
          if (index < 0)
            resolveParameter.push({ label: v.label, text: v.label, value: v.label, units: v.units })
        }
      })
      setCombineParameter(resolveParameter)
    } else {
      let intersection = _.uniq(tempArrayParameter)
      let resolveParameter = []
      _.forEach(tempCombine, v => {
        if (intersection.includes(v.label)) {
          let index = _.findIndex(resolveParameter, e => { return e.label === v.label })
          if (index < 0)
            resolveParameter.push({ label: v.label, text: v.label, value: v.label, units: v.units })
        }
      })
      setCombineParameter(resolveParameter)
    }
    combineCategory.splice(0, 0, { label: 'All Selected Category', text: 'All Selected Category', value: 'All Selected Category' })
    setSelectedCategories(combineCategory)
    setDisableAddCondition(check)
  }, [category])

  const handleClosePanel = () => {
    // if (selectedFilter) {
    //   let temp =  Object.assign({}, selectedFilter)
    //   temp.isSelected = false
    //   temp.isModify = false
    //   setSelectedFilter(temp)
    //   setCategory([])
    //   setListCondition([])
    // }
    props.handleClose()
  }

  //#region //!handle view filter
  const handleChangeRule = (value) => {
    let temp = Object.assign({}, selectedFilter)
    _.forEach(temp.listCondition, v => {
      v.category = 'All Selected Category'
      v.parameter = ''
      v.formula = ''
      v.value = ''
      v.listParameter = []
      v.listFormula = []
      v.isNumber = false
    })
    temp.rule = value
    temp.isModify = true
    setSelectedFilter(temp)
    setRule(value)
  }

  const handleChangeCategory = (index, item, e) => {
    let tempCategory = category.slice(0)
    let tempSelectedFilter = Object.assign({}, selectedFilter)
    tempCategory[index].checked = e.target.checked
    _.forEach(tempCategory, v => {
      let index = _.findIndex(tempSelectedFilter.category, e => { return e.label === v.label })
      if (index < 0) {
        tempSelectedFilter.category.push({ label: v.label, checked: v.checked })
      } else {
        tempSelectedFilter.category[index].checked = v.checked
      }
    })
    _.forEach(tempSelectedFilter.listCondition, v => {
      v.category='All Selected Category'
      v.parameter = ''
      v.formula = []
      v.value = ''
    })
    tempSelectedFilter.isModify = true
    setSelectedFilter(tempSelectedFilter)
    setCategory(tempCategory)
  }

  const handleCheckCategory = (name) => {
    let tempCategory = category.slice(0)
    let tempSelectedFilter = Object.assign({}, selectedFilter)
    _.forEach(tempCategory, item => {
      if (name === 'all') {
        item.checked = true
      } else
        item.checked = false
    })
    _.forEach(tempCategory, v => {
      let index = _.findIndex(tempSelectedFilter.category, e => { return e.label === v.label })
      if (index < 0) {
        tempSelectedFilter.category.push({ label: v.label, checked: v.checked })
      } else {
        tempSelectedFilter.category[index].checked = v.checked
      }
    })
    _.forEach(tempSelectedFilter.listCondition, v => {
      v.category='All Selected Category'
      v.parameter = ''
      v.formula = []
      v.value = ''
    })
    tempSelectedFilter.isModify = true
    setSelectedFilter(tempSelectedFilter)
    setCategory(tempCategory)
  }

  const handleAddCondition = () => {
    let temp = listCondition.slice(0)
    let tempSelectedFilter = Object.assign({}, selectedFilter)
    temp.push({ category: 'All Selected Category', parameter: '', formula: '', value: '', listParameter: [], listFormula: [], isNumber: false })
    tempSelectedFilter.listCondition = temp
    tempSelectedFilter.isModify = true
    setSelectedFilter(tempSelectedFilter)
    setListCondition(temp)
  }
  const handleRemoveCondition = (index) => {
    let temp = listCondition.slice();
    let tempSelectedFilter = Object.assign({}, selectedFilter)
    temp.splice(index, 1);
    tempSelectedFilter.listCondition = temp
    tempSelectedFilter.isModify = true
    setSelectedFilter(tempSelectedFilter)
    setListCondition(temp)
  }

  //#endregion

  //#region //! control filter item
  const handleChangeSubCategory = (index, value) => {
    let temp = listCondition.slice(0);
    temp[index]['category'] = value
    let tempParameter = []
    _.forEach(category, item => {
      if (item.label !== value) return
      _.forEach(item.value.property, v => {
        if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in'
          && v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label') {
          tempParameter.push({ label: v.displayName, text: v.displayName, value: v.displayName })
        }
      })
      return false
    })
    temp[index]['listParameter'] = tempParameter
    temp[index].parameter = ''
    temp[index].formula = []
    temp[index].value = ''
    setListCondition(temp)
  }
  const handleChangeSubParameter = (index, item, value) => {
    let temp = listCondition.slice();
    let tempValue = []
    _.forEach(category, cat => {
      if (item.category === 'All Selected Category') {
        if (cat.checked) {
          let result = _.find(cat.value.property, e => { return e.displayName === value })
          if (result.units === '') {
            temp[index]['isNumber'] = false
            temp[index]['listFormula'] = optionsConditionText
            tempValue = cat.parameterValue[value] && tempValue.concat(cat.parameterValue[value])
          } else {
            temp[index]['isNumber'] = true
            temp[index]['listFormula'] = optionsConditionNumber
            tempValue = cat.parameterValue[value] && tempValue.concat(cat.parameterValue[value])
          }
          // return false
        }
      } else {
        if (cat.checked && cat.label === item.category) {
          let result = _.find(cat.value.property, e => { return e.displayName === value })
          if (result.units === '') {
            temp[index]['isNumber'] = false
            temp[index]['listFormula'] = optionsConditionText
            tempValue = cat.parameterValue[value] && tempValue.concat(cat.parameterValue[value])
          } else {
            temp[index]['isNumber'] = true
            temp[index]['listFormula'] = optionsConditionNumber
            tempValue = cat.parameterValue[value] && tempValue.concat(cat.parameterValue[value])
          }
          // return false
        }
      }

    })

    tempValue = _.uniq(tempValue)
    let tempInputValue = []
    _.forEach(tempValue, v => {
      tempInputValue.push({ label: v, text: v, value: v })
    })
    temp[index]['parameterValue'] = tempInputValue
    temp[index]['formula'] = 'equals'
    temp[index]['parameter'] = value

    setListCondition(temp)
  }
  const handleChangeSubFormula = (index, value) => {
    let temp = listCondition.slice();
    temp[index]['formula'] = value
    setListCondition(temp)
  }
  const handleChangeSubValue = (index, item, value) => {
    let temp = listCondition.slice();
    if (item.isNumber)
      value = _.toNumber(value)
    temp[index]['value'] = value
    setListCondition(temp)
  }
  //#endregion

  //#region action

  const handleSaveFilter = () => {
    let temp = Object.assign({}, selectedFilter);
    let check = temp.listCondition.length === 0 ? false : true
    _.forEach(temp.listCondition, v => {
      if (v.parameter === '' || v.formula === '' || v.value === '') {
        check = false
        return false
      }
    })
    if (check) {
      setLoading(true)
      temp.isModify = false
      temp.isSelected = false
      temp.type =props.viewer.impl.model.myData.loadOptions.fileExt
      axios.post("/api/viewFilter/set-view-filter", { projectId: props.projectId, itemId: props.itemId, email: props.email, data: temp ,
        type:props.viewer.impl.model.myData.loadOptions.fileExt})
        .then(res => {
          message.success('Save data success')
          props.handleSave(res.data)
          setLoading(false)
          console.log(listFilter)
        })
        .catch(() => {
          message.error('Save data failed')
          setLoading(false)
        })
    } else {
      message.warning('You need key in all input')
    }


  }
  //#endregion

  //#region //! panel add remove
  const handleAddFilter = () => {
    setOpenPanel(false)
    setOpenPanelAddFilter(true)
    setTitlePanel('Add Filter')
  }
  const handleClosePanelAddFilter = () => {
    setSelectedFilterEdit(null)
    setOpenPanel(true)
    setOpenPanelAddFilter(false)
  }

  const handleAddNewFilter = (filterName) => {
    let index = _.findIndex(listFilter, e => { return e.filterName === filterName })
    if (index < 0) {
      let temp = listFilter.slice();
      let tempCategory = category.slice()
      let obj = {
        guid: v4(), filterName: filterName, category: [], listCondition: [], rule: 'AND (All rules must be true)', isModify: true,
        itemId: props.itemId, projectId: props.projectId
      }
      temp.push(obj)
      _.forEach(tempCategory, v => {
        v.checked = false
      })
      setCategory(tempCategory)
      setListFilter(temp)
      // setSelectedFilter(null)
      // setListCondition(obj.listCondition)
      // setRule(obj.rule)
      handleClosePanelAddFilter()

    } else {
      message.warning('Filter name is exist')
    }
  }
  const handleRenameFilter = (item, filterName) => {
    let temp = listFilter.slice()
    let index = _.findIndex(listFilter, e => { return e.filterName === filterName })
    if (index < 0) {
      let index = _.findIndex(listFilter, e => { return e.guid === item.guid })
      if (index >= 0) {
        temp[index].filterName = filterName
        temp[index].isModify = true
        setListFilter(temp)
        handleClosePanelAddFilter()
      }
    } else {
      message.warning('Filter name is exist')
    }
  }
  const handleRemoveExistFilter = (item) => {
    if (!window.confirm('Are you want to delete?')) return
    props.handleRemoveViewFilter(item)
    props.handleClose()
  }
  const handleRenameExistFilter = (item) => {
    setOpenPanel(false)
    setOpenPanelAddFilter(true)
    setSelectedFilterEdit(item)
    setTitlePanel('Rename Filter')
  }
  //#endregion

  const handleSelectFilter = (item, guid) => {
    let temp = category.slice()
    _.forEach(temp, v => {
      let index = _.findIndex(item.category, e => { return e.label === v.label })
      if (index < 0) {
        v.checked = false
      } else {
        v.checked = item.category[index].checked
      }
    })
    _.forEach(listFilter, (v) => {
      if (v.guid === guid) {
        v.isSelected = true
      } else {
        v.isSelected = false
      }
    })
    setRule(item.rule)
    setCategory(temp)
    setSelectedFilter(item)
    setListCondition(item.listCondition)
  }
  const contentRule = (key, item) => {
    return (
      <Segment style={{ width: '100%' }} color={rule.includes('AND') ? 'green' : 'blue'}>
        <Row gutter={[8, 8]}>
          <Col span={6} >
            <SelectPicker
              data={selectedCategories}
              size="xs" block cleanable={false} placeholder='Category' disabled={rule.includes('AND')}
              onChange={handleChangeSubCategory.bind(this, key)}
              value={item.category}
              renderValue={(value) => {
                return (
                  <div style={{ color: 'black' }} >
                    {value}
                  </div>
                )
              }} />
          </Col>
          <Col span={18} >
            <SelectPicker
              data={item.category === 'All Selected Category' ? combineParameter : item.listParameter}
              size="xs" block cleanable={false} placeholder='Select Parameter' onChange={handleChangeSubParameter.bind(this, key, item)}
              value={item.parameter}
              renderValue={(value) => {
                return (
                  <div style={{ color: 'black' }} >
                    {value}
                  </div>
                )
              }} sort={() => {
                return (a, b) => {
                  return compare(a.value, b.value);
                }
              }} />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={6} >
            <SelectPicker
              data={item.listFormula}
              size="xs" block cleanable={false} placeholder='Condition' onChange={handleChangeSubFormula.bind(this, key)}
              value={item.formula} searchable={false} disabled={item.parameter === ''}
              renderValue={(value) => {
                return (
                  <div style={{ color: 'black' }} >
                    {value}
                  </div>
                )
              }} />
          </Col>
          <Col span={18} >
            {(item.formula === 'equals' || item.formula === 'does not equals') ?
              <SelectPicker
                style={{ width: 'calc(100% - 40px)', display: 'inline-block', marginRight: 5 }}
                data={item.parameterValue}
                size="xs" block cleanable={false} placeholder='Value' onChange={handleChangeSubValue.bind(this, key, item)}
                value={item.value} disabled={item.parameter === ''}
                renderMenuItem={(label, item) => {
                  return (
                    <div style={{ color: 'black' }} >
                        {item.isNumber?formatNumber(label, 3):label }
                    </div>
                  );
                }}
                renderValue={(value, item) => {
                  return (
                    <div style={{ color: 'black' }} >
                      {item.isNumber?formatNumber(value, 3):value }
                    </div>
                  )
                }}
                sort={() => {
                  return (a, b) => {
                    return compare(a.value, b.value);
                  }
                }} /> :
              item.isNumber ?
                <Input
                  type='number'
                  style={{ width: 'calc(100% - 40px)', display: 'inline-block', marginRight: 5 }}
                  size="xs" block cleanable={false} placeholder='Value' onChange={handleChangeSubValue.bind(this, key, item)} step={0.001}
                  value={item.value} disabled={item.parameter === ''} />
                :
                <Input
                  style={{ width: 'calc(100% - 40px)', display: 'inline-block', marginRight: 5 }}
                  size="xs" block cleanable={false} placeholder='Value' onChange={handleChangeSubValue.bind(this, key, item)}
                  value={item.value} disabled={item.parameter === ''} />
            }
            <IconButton icon={< Icon icon="minus" />}
              color='red' size='xs'
              onClick={handleRemoveCondition.bind(this, key)}
              renderValue={(value) => {
                return (
                  <div style={{ color: 'black' }} >
                    {value}
                  </div>
                )
              }} />
          </Col>
        </Row>
      </Segment>
    )
  }
  return (
    <>
      <Modal show={openPanel} onHide={handleClosePanel} size='xxl' backdrop='static' >
        <Modal.Header>
          <Modal.Title>Add & Edit Filter</Modal.Title>
        </Modal.Header>
        <Divider className='custom-divider' />
        <Modal.Body id='dialog-filter'>
          {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
          <Row gutter={8}>
            <Col span={5}>
              <ButtonGroup style={{ marginTop: 0 }} size='sm' justified>
                <Button appearance="ghost" onClick={handleAddFilter.bind(this, 'add')}> Add</Button>
              </ButtonGroup>

              <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} >
                <List
                  size="small"
                  dataSource={listFilter}
                  renderItem={(item) =>
                    <MenuProvider id="filter_menu" animation={animation.zoom} data={{ item: item, filterName: item.filterName }}>
                      <List.Item style={{ marginBottom: '5px', padding: 0, backgroundColor: item.isSelected && "#c6e0f8" }}
                        onClick={handleSelectFilter.bind(this, item, item.guid)}>
                        <Text strong ellipsis={true} style={{ cursor: 'pointer' }}
                        >{item.isModify ? `❌ ${item.filterName}` : `✔️ ${item.filterName}`}</Text>
                        <ButtonGroup style={{position:'absolute', right:0}}>
                          <IconButton icon={< Icon icon={'terminal'} />} size='xs' onClick={handleRenameExistFilter.bind(this, item)}
                          />
                          <IconButton icon={< Icon icon="trash" />} size='xs' color='red' onClick={handleRemoveExistFilter.bind(this, item)}
                          />
                        </ButtonGroup>
                      </List.Item>
                    </MenuProvider>
                  }
                />
              </Segment>
            </Col>
            <Col span={5}>
              <ButtonGroup style={{ marginTop: 0 }} size='sm' justified>
                <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'all')} disabled={selectedFilter === null}> Check All</Button>
                <Button appearance="ghost" onClick={handleCheckCategory.bind(this, 'none')} disabled={selectedFilter === null}> Check None</Button>
              </ButtonGroup>

              <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}  >

                <List
                  size="small"
                  dataSource={category}
                  renderItem={(item, index) =>
                    <List.Item style={{ marginBottom: '5px', padding: 0 }} >
                      <Tooltip title={item.label} placement='topLeft'>
                        <Checkbox onChange={handleChangeCategory.bind(this, index, item)} checked={item.checked}
                          disabled={selectedFilter === null}>
                          <span style={{ verticalAlign: 'middle' }} ellipsis={true}>{item.label}</span>
                        </Checkbox>
                      </Tooltip>

                    </List.Item>


                  }
                />
              </Segment>
            </Col>
            <Col span={14}>
              <>
                <>
                  <div style={{ width: 'calc(100% - 40px)', display: 'inline-block', marginRight: 5, }}>
                    <SelectPicker
                      data={optionRule} cleanable={false} disabled={selectedFilter === null} searchable={false}
                      block size="sm" value={rule}
                      onChange={handleChangeRule}
                      renderValue={(value) => {
                        return (
                          <div style={{ color: rule.includes('AND') ? 'green' : 'blue' }} >
                            {value}
                          </div>
                        );
                      }} />
                  </div>
                  <div style={{ display: 'inline' }} >
                    <IconButton icon={< Icon icon="plus" />} color={rule.includes('AND') ? 'green' : 'blue'} size='sm'
                      onClick={handleAddCondition} disabled={selectedFilter === null || disableAddCondition} />
                  </div>
                  <Segment style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }} disabled={selectedFilter === null}
                    color={rule.includes('AND') ? 'green' : 'blue'}>
                    <List
                      dataSource={listCondition}
                      renderItem={(item, key) => (
                        <List.Item>
                          {contentRule(key, item)}
                        </List.Item>

                      )}
                    />
                  </Segment>
                </>
              </>
            </Col>
          </Row>
          {/* <Menu id='filter_menu' style={{ zIndex: 10000 }}>
            <Item onClick={handleApplyFilter}>
              <IconFont className="fas fa-bolt" />Set View Filter
            </Item>
            <Separator />
            <Item onClick={handleRemoveExistFilter}>
              <IconFont className="fas fa-trash" />Delete
            </Item>
            <Item onClick={handleRenameExistFilter}>
              <IconFont className="fas fa-edit" />Rename
            </Item>
          </Menu> */}
        </Modal.Body>
        <Divider className='custom-divider' />
        <Modal.Footer>
          <Button onClick={handleClosePanel}  > Cancel</Button>
          <Button appearance="primary" onClick={handleSaveFilter} disabled={selectedFilter === null} > Save</Button>
        </Modal.Footer>
      </Modal>

      {openPanelAddFilter &&
        <AddNewFilter
          open={openPanelAddFilter}
          close={handleClosePanelAddFilter}
          title={titlePanel}
          handleAddNewFilter={handleAddNewFilter}
          handleRenameFilter={handleRenameFilter}
          selectedFilterEdit={selectedFilterEdit}

        />}


    </>
  );
}

DialogFilter.propTypes = {
  email: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}
export default connect(mapStateToProps, null)(DialogFilter)


function AddNewFilter(props) {

  const [filterName, setFilterName] = useState(props.selectedFilterEdit ? props.selectedFilterEdit.filterName : '')
  const handleChangeFilterName = (value) => {
    setFilterName(value)
  }

  return (
    <Modal show={props.open} onHide={props.close} size='xs' backdrop='static'>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Input size="sm" block cleanable={false} placeholder='Filter Name' onChange={handleChangeFilterName} value={filterName} />
      </Modal.Body>
      <Modal.Footer>

        {props.title === 'Add Filter' ?
          <Button appearance="primary" onClick={props.handleAddNewFilter.bind(this, filterName)} disabled={filterName.length === 0} > Add</Button> :
          <Button appearance="primary" onClick={props.handleRenameFilter.bind(this, props.selectedFilterEdit, filterName)} disabled={filterName.length === 0} > Rename</Button>}
        <Button appearance="default" onClick={props.close} > Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}