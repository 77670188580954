import { Button } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';



const ButtonStyle = ({
   colorText,
   marginRight,
   marginLeft,
   borderColor,
   borderOverwritten,
   marginBottom,
   background,
   name,
   width,
   onClick,
   disabled,
   stateRow
}) => {

   let loading;
   if (stateRow) { // Dashboard doesn't query to get stateRow
      loading = stateRow.loading;
   };

   const [isClicked, setIsClicked] = useState(false);


   const arrayButtonUpload = [
      'Choose PDF File',
      'Upload Reply Form',
      'Upload Documents',
      'Upload Signed Off Cover Form',
      'Upload 3D / CAD',
      'Choose Files'
   ];


   const arrayButtonToLockAfterClick = [
      'Send Email',
      'Apply',
      'Acknowledge',
      'Yes',
      'Export To PDF',
      'Download Drawings',
      'Save',
   ];


   const arrayButtonLoadingAfterClick = [
      'Yes',
      'Acknowledge',
      'Export To PDF',
      'Save',
   ];


   return (
      <Button
         style={{
            borderRadius: 0,
            background: background,
            color: colorText,
            border: 'none',
            marginRight,
            marginBottom,
            marginLeft,
            border: `1px solid ${borderOverwritten ? borderColor : (borderColor || background)}`,
            width
         }}

         disabled={disabled || (loading && arrayButtonLoadingAfterClick.indexOf(name) !== -1) || (!loading && isClicked && arrayButtonToLockAfterClick.indexOf(name) !== -1)}
         loading={loading && arrayButtonLoadingAfterClick.indexOf(name) !== -1}

         onClick={() => {
            if (arrayButtonToLockAfterClick.indexOf(name) !== -1) {
               setIsClicked(true);
            };
            if (arrayButtonUpload.indexOf(name) === -1) {
               onClick();
            };
         }}
      >
         {name}
      </Button>
   );
};


const mapStateToProps = (state) => ({
   stateRow: state.dms_row,
});


export default connect(mapStateToProps, null)(ButtonStyle);
