import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Table, message, Typography, Popconfirm, Tag } from "antd";

const { Link, Text } = Typography;
var customParseFormat = require("dayjs/plugin/customParseFormat");

const TableConcCompare = ({ current, filterMode, generalState, setStatistic }) => {
   const [data, setdata] = useState([]);
   // const current = useSelector((state) => state.fp_currentFormReducer.value);
   // const shownPanel = useSelector((state) => state.fp_generalStateReducer.value);
   // const filterMode = useSelector(
   //   (state) => state.fp_currentFilterReducer.value
   // );
   // const query_builder = useSelector(
   //   (state) => state.fp_filterQueryReducer.value
   // );

   useEffect(() => {
      //make sure working on concrete DO form
      if (current.includes("conc") && generalState === 10) {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            var res_do = await axios.get("/api/do_inv/concdo/date_range", {
               params: { currentFilterMode: filterMode, project_id: project_id },
            });
            const data_do = res_do.data.data.items;

            var res_inv = await axios.get("/api/do_inv/concinv/date_range", {
               params: { currentFilterMode: filterMode, project_id: project_id },
            });
            const data_inv = res_inv.data.data.items;

            const temp = [];
            //carry on compare
            data_do.forEach((d) => {
               let inv = data_inv.find((e) => e.do_number === d.do_number);
               if (typeof inv !== "undefined") {
                  var cp = [];
                  if (inv.volume !== d.volume) {
                     cp.push("volume");
                  }
                  if (inv.grade !== d.grade) {
                     cp.push("grade");
                  }

                  if (inv.do_date !== d.do_date) {
                     cp.push("date");
                  }

                  if (cp.length > 0) {
                     d.different = cp;
                     d.formtype = "DO";
                     inv.different = cp;
                     inv.formtype = "INV";
                     temp.push(inv);
                     temp.push(d);
                  }
               }
            });
            setdata(temp);
            if (temp.length < 1) {
               message.warn("No discrepancy found for the duration selected!");
            }

            setStatistic([]);
         };
         fetchdata();
      }
   }, [filterMode, generalState]);

   const handleChange = async (record) => {
      try {
         const do_no = record.do_number;
         const newData = [...data];
         //find the index of DO
         let indexDO = data.findIndex((item) => item.do_number === do_no && item.formtype === "DO");
         //find the index of invoice
         let indexINV = data.findIndex((item) => item.do_number === do_no && item.formtype === "INV");

         if (record.formtype === "INV") {
            //update DO
            let record_DO = data[indexDO];
            record = {
               ...record,
               do_date: dayjs(record_DO.do_date).toISOString(),
               volume: record_DO.volume,
               grade: record_DO.grade,
            };
            delete record.formtype;
            delete record.different;
            await axios.put("/api/do_inv/common/update_for_compare", record);
         } else {
            //update INV
            let record_INV = data[indexINV];
            record = {
               ...record,
               do_date: dayjs(record_INV.do_date).toISOString(),
               volume: record_INV.volume,
               grade: record_INV.grade,
            };
            delete record.formtype;
            delete record.different;
            await axios.put("/api/do_inv/common/", { entity: record, current: "conc_do" });
         }
         if (indexDO > indexINV) {
            newData.splice(indexDO, 1);
            newData.splice(indexINV, 1);
         } else {
            newData.splice(indexINV, 1);
            newData.splice(indexDO, 1);
         }

         setdata(newData);
         message.success("Data has updated.");
         // }
      } catch (errInfo) {}
   };

   const columns = [
      {
         key: "do_number",
         dataIndex: "do_number",
         title: "DO Number",
         width: 125,
         render: (do_number, record) => {
            return record.formtype === "INV" ? do_number : null;
         },
      },

      {
         key: "formtype",
         dataIndex: "formtype",
         title: "Form Type",
         width: 100,
      },

      {
         key: "do_date",
         dataIndex: "do_date",
         title: "Date Delivery",
         render: (do_date) => {
            dayjs.extend(customParseFormat);
            const d = dayjs(do_date).format("DD/MM/YY");
            return <p>{d}</p>;
         },
         width: 125,
         editable: true,
      },

      {
         key: "volume",
         dataIndex: "volume",
         title: "Volume",
         width: 100,
         editable: true,
      },
      {
         key: "grade",
         dataIndex: "grade",
         title: "Grade",
         sortable: true,
         width: 200,
         ellipsis: true,
         editable: true,
      },

      {
         key: "different",
         dataIndex: "different",
         title: "Different In",
         sortable: true,
         width: 200,
         ellipsis: true,
         render: (different, record) =>
            record.formtype === "INV" ? (
               <>
                  {different.map((tag) => {
                     let color = "volcano";

                     if (tag === "grade") {
                        color = "geekblue";
                     }
                     if (tag === "date") {
                        color = "purple";
                     }

                     return (
                        <Tag color={color} key={tag}>
                           {tag}
                        </Tag>
                     );
                  })}
               </>
            ) : null,
      },

      {
         key: "s3_key",
         dataIndex: "s3_key",
         title: "Pdf file",
         width: 80,
         ellipsis: true,
         render: (text, record) =>
            text !== null && text !== "" && typeof text !== "undefined" && record.formtype !== "DO" ? (
               <Link
                  onClick={() => {
                     axios
                        .get("/api/do_inv/s3/getlink", {
                           params: { file_name: text + ".pdf" },
                        })
                        .then((res) => {
                           const signedrequesturl = res.data.signedRequest;
                           window.open(signedrequesturl);
                        });
                  }}
               >
                  Open
               </Link>
            ) : (
               { text }
            ),
      },

      {
         title: "Action",
         dataIndex: "Action",

         width: 100,
         render: (_, record) => {
            return record.formtype === "INV" ? (
               <>
                  <Popconfirm
                     placement="topLeft"
                     title="Sure change to same with DO?"
                     onConfirm={() => handleChange(record)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <a href="javascript:;">Same as DO</a>
                  </Popconfirm>
               </>
            ) : (
               <>
                  <Popconfirm
                     placement="topLeft"
                     title="Sure change to same with INV?"
                     okText="Yes"
                     cancelText="No"
                     onConfirm={() => handleChange(record)}
                  >
                     <a href="javascript:;">Same as INV</a>
                  </Popconfirm>
               </>
            );
         },
      },
   ];

   return (
      <div>
         <Table
            rowKey={(record) => record.id}
            dataSource={data}
            columns={columns}
            size="small"
            // rowClassName="editable-row"
            scroll={{ y: "83vh" }}
            rowClassName={(record, index) =>
               record.formtype === "INV" ? "antd-table-row-background-compare-inv" : null
            }
            pagination={{
               pageSize: 20,
            }}
         />
      </div>
   );
};

export default TableConcCompare;
