import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { Button, Card, Elevation } from "@blueprintjs/core";
import moment from 'moment'
import { constants } from '../constants'
import { Divider } from 'rsuite';
import { Typography } from 'antd'
const { Text } = Typography
function CellManPowerMonth(props) {
  const [values, setValues] = useState([])
  const [divide, setDivide] = useState('100%')
  const [divideCol, setDivideCol] = useState('100%')
  useEffect(() => {
    try {
      if (props.node) {
        if (props.value && !props.node.group) {

          let temp = []
          let group = {}
          let parse = JSON.parse(props.value)
          let rowHeight = props.node.rowHeight
          let newRowHeight = 35
          let countRow = 0
          _.forEach(parse, (value, d) => {
            let children = []
            if (value.projectName) {
              let split1 = value.projectName.split('_')
              let split2 = value.power.toString().split('_')
              _.forEach(split1, (i, k) => {
                children.push({ projectName: split1[k], power: split2[k] })
              })
            } else {
              children.push({ projectName: '', power: '' })
            }
            countRow = countRow < children.length ? children.length : countRow
            newRowHeight = 35 * countRow
            newRowHeight = rowHeight < newRowHeight ? newRowHeight : rowHeight
            temp.push(children)
          })

          setDivideCol(`${100 / parse.length}%`)
          setDivide(`${100 / countRow === 0 ? 1 : countRow}%`)
          setValues(temp)

          props.node.setRowHeight(newRowHeight)
          props.api.onRowHeightChanged()
          console.log(props)
        }

      }
    } catch (e) {
      console.log(e)
    }
  }, [props.value])


  return (
    <React.Suspense fallback={null}>
      {(props.typeTimeline === 'month' || props.typeTimeline === 'year') &&
        <div style={{ display: 'flex', height: '100%' }}>


          {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '50%' }}>

     <div style={{ height: divide, width: '100%', padding: 2,height: '100%' }} >
       <div style={{ width: '100%', height: '100%', padding: 0, borderRadius: 3, boxShadow: '0px 0px 0px 1px' }}  >
         <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: 'calc(100% - 5px)', overflow: 'hidden' }} >
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <Text strong>projectName</Text>
           </div>
           <div style={{ backgroundColor: 'black', color: 'white', height: '100%', display: 'flex', alignItems: 'center' }} >
             {`->`}
           </div>
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <span>power</span>
           </div>
         </div>
       </div>
     </div>
     <div style={{ height: divide, width: '100%', padding: 2,height: '100%' }} >
       <div style={{ width: '100%', height: '100%', padding: 0, borderRadius: 3, boxShadow: '0px 0px 0px 1px' }}  >
         <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: 'calc(100% - 5px)', overflow: 'hidden' }} >
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <Text strong>projectName</Text>
           </div>
           <div style={{ backgroundColor: 'black', color: 'white', height: '100%', display: 'flex', alignItems: 'center' }} >
             {`->`}
           </div>
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <span>power</span>
           </div>
         </div>
       </div>
     </div>

   </div>

   <div style={{ width: 2, backgroundColor: 'gray' }} />

   <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '50%' }}>

     <div style={{ height: divide, width: '100%', padding: 2,height: '100%' }} >
       <div style={{ width: '100%', height: '100%', padding: 0, borderRadius: 3, boxShadow: '0px 0px 0px 1px' }}  >
         <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: 'calc(100% - 5px)', overflow: 'hidden' }} >
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <Text strong>projectName</Text>
           </div>
           <div style={{ backgroundColor: 'black', color: 'white', height: '100%', display: 'flex', alignItems: 'center' }} >
             {`->`}
           </div>
           <div style={{ width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
             <span>power</span>
           </div>
         </div>
       </div>
     </div>
   </div> */}


          {values.map((i, k) =>
            <>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: divideCol }}>
                {i.map(v =>
                  <>

                    <div style={{ height: divide, width: '100%', padding: 2, height: '100%', }} >
                      <div style={{ width: '100%', height: '100%', padding: 0, borderRadius: 3, boxShadow: '0px 0px 0px 1px' }}  >
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%', overflow: 'hidden' }} >
                          <div style={{ width: 'calc(50% - 10px)', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
                            <Text strong>{v.projectName}</Text>
                          </div>
                          <div style={{
                            width: 20, backgroundColor: '#2196f3', color: 'white', height: 20, display: 'flex', alignItems: 'center'
                            , alignContent: 'center', justifyContent: 'center', borderRadius: '50%'
                          }} >
                            {k + 1}
                          </div>
                          <div style={{ width: 'calc(50% - 10px)', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
                            <span>{v.power}</span>
                          </div>
                        </div>
                      </div>
                    </div>


                  </>
                )}
              </div>
              {values.length - 1 !== k && <div style={{ width: 2, backgroundColor: '#2196f3' }} />}
            </>
          )}
        </div>

      }


    </React.Suspense>
  )
}

CellManPowerMonth.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(CellManPowerMonth);






