import React from 'react';
// import {  Checkbox, Dropdown, Divider, Input } from 'semantic-ui-react'
import { Modal } from 'rsuite';
import $ from 'jquery';
import _ from 'lodash'
import axios from 'axios'
import {TableListScheduleSaved,TableScheduleSaved} from '../../module_BimApp/tables'

import { message } from 'antd';
import Dock from 'react-dock'
import {
  ColumnSchedule,
} from '../../module_BimApp/columns'
import {
  getAllModelsElementdbIdsWithCondition
} from '../function/ForgeFunction'
import {
  GetElementAndParameterRvt, listElementTableRVT,
  listElementTableDWFX, GetElementAndParameterDWFX} from '../function/ScheduleFunction'

let tempListModel = {}
let listParameterSelectedId = []
let dataGeneralTable = []
let dataGeneralTableGroup = {}
let storeParameterUnits = {}
let listParameterSelected = []
let groupByScheudle = null
let viewPoint = null
let allDbIds = []

class DialogScheduleSaved extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPanel: this.props.openPanel,
      data: [],
      loadingBtnDelete: false,
      displayScheduleSavedPanel: false,
      dataScheduleSaved: [],
      columnsScheduleSaved: [],
      groupByScheduleSaved: [],
      activeButtonIsolateRows: false,
      listFullData: [],

      heightDock: 500,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,

    }
  }

  componentWillUnmount = () => {
    tempListModel = {}
    dataGeneralTable = []
    dataGeneralTableGroup = {}
    storeParameterUnits = {}
    listParameterSelectedId = []
    listParameterSelected = []
    groupByScheudle = null
    viewPoint = null
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.openPanel) {
      if (nextProps.heightNavigation !== this.props.heightNavigation)
        this.setState({
          maxForgeViewer: this.state.maxForgeViewer + (nextProps.heightNavigation === '0px' ? 53.78 : -53.78),
        }, () => {
          $('#splitForgeViewer').height(this.state.maxForgeViewer - this.state.heightDock)
        });
      axios.get(`/api/versions/getschedulesaved?q=${this.props.itemId}`)
        .then(res => {
          if (this.props.email === 'admin@wohhup.com') {
            this.setState({ data: res.data, openPanel: true })
          } else {
            let temp = []
            _.forEach(res.data, v => {
              if (v.share === true) {
                temp.push(v)
              } else if (v.userCreated === this.props.email) {
                temp.push(v)
              }
            })
            this.setState({ data: temp, openPanel: true })
          }
        })
        .catch(() => { })
    } else if (this.state.displayScheduleSavedPanel == true) {
      if (nextProps.heightNavigation !== this.props.heightNavigation)
        this.setState({
          maxForgeViewer: this.state.maxForgeViewer + (nextProps.heightNavigation === '0px' ? 53.78 : -53.78),
        }, () => {
          $('#splitForgeViewer').height(this.state.maxForgeViewer - this.state.heightDock)
        });
    }

  }

  handleClosePanel = () => {
    this.setState({ openPanel: false })
    this.props.onChangeDisplay('dialogSaveSchedule', false)
  }
  handleDeleteScheduleSaved = (value) => {
    this.setState({ loadingBtnDelete: true })
    axios.post(`/api/versions/deleteschedulesaved`, { itemId: value.itemId, sheduleId: value.id })
      .then(res => {
        if (this.props.email === 'admin@wohhup.com') {
          this.setState({ data: res.data, loadingBtnDelete: false })
        } else {
          let temp = []
          _.forEach(res.data, v => {
            if (v.share === true) {
              temp.push(v)
            } else if (v.userCreated === this.props.email) {
              temp.push(v)
            }
          })
          this.setState({ data: temp, loadingBtnDelete: false })
        }
        message.success(`Storage schedule successful.`);
      })
      .catch(() => {
        this.setState({ data: [], loadingBtnDelete: false })
        message.error(`Storage schedule failed.`);
      })
  }
  handleShareScheduleSaved = (value) => {
    axios.post(`/api/versions/shareschedulesaved`, { itemId: value.itemId, sheduleId: value.id })
      .then(res => {
        if (this.props.email === 'admin@wohhup.com') {
          this.setState({ data: res.data })
        } else {
          let temp = []
          _.forEach(res.data, v => {
            if (v.share === true) {
              temp.push(v)
            } else if (v.userCreated === this.props.email) {
              temp.push(v)
            }
          })
          this.setState({ data: temp })
        }
        message.success(`Share schedule successful.`);
      })
      .catch(() => {
        message.error(`Share schedule failed.`);
      })

  }
  handleOpenScheduleSaved = async (value) => {
    let node = this.props.viewer.impl.model.getDocumentNode()
    if (value.guid === node.data.guid) {
      let table = JSON.parse(value.table)
      table.tempListModel = {}
      allDbIds = []
      const models = this.props.viewer.impl.modelQueue().getModels();
      _.forEach(models, (model) => {
        let instanceTree = model.getData().instanceTree;
        let temp = getAllModelsElementdbIdsWithCondition(instanceTree, this.props.viewer, model, 'all');
        allDbIds.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
      })
      this.handleGetAllCategoryAndParameter()
      groupByScheudle = table.groupBy
      viewPoint = table.viewPoint
      listParameterSelected = table.parameterSelected
      await this.listElementTable(allDbIds, this.generateTable, table.category, table.parameter)

    } else {
      alert('Schedule saved in other view')
    }
  }
  listElementTable = async (allDbIds, callback, tempCategoryId, listParameterSelectedId) => {
    let split = this.props.itemName.split(".")
    let tempData = null
    if (split[split.length - 1].toLowerCase() === 'rvt') {
      tempData = await listElementTableRVT(allDbIds, tempCategoryId, listParameterSelectedId)
    } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
      tempData = await listElementTableDWFX(allDbIds, tempCategoryId, listParameterSelectedId)
    }
    dataGeneralTableGroup = tempData.dataGeneralTableGroup
    storeParameterUnits = tempData.storeParameterUnits
    callback();
  }
  generateTable = () => {
    try {
      var _this = this
      var listDataTemp = []
      if (Object.keys(dataGeneralTableGroup).length === 0) {
        message.error('You have to least one parameter');
        return
      }
      dataGeneralTable = [];
      var tempToMatchMissingParameter = null
      for (let key in dataGeneralTableGroup) {
        if (listParameterSelectedId.length === dataGeneralTableGroup[key].length) {
          tempToMatchMissingParameter = dataGeneralTableGroup[key]
          break
        }
      }
      for (let key in dataGeneralTableGroup) {
        _.forEach(dataGeneralTableGroup[key], (v) => {
          if (v.units === undefined) {
            if (storeParameterUnits[v.parameter] === '') {
              v.value = 'N/A'
              v.units = storeParameterUnits[v.parameter]
            } else {
              v.value = 0
              v.units = storeParameterUnits[v.parameter]
            }
          }
        })
        if (listParameterSelectedId.length > dataGeneralTableGroup[key].length) {
          for (let i in tempToMatchMissingParameter) {
            if (tempToMatchMissingParameter[i].parameter !== dataGeneralTableGroup[key][i]) {
              if (tempToMatchMissingParameter[i].units === '') {
                let tempValue = {
                  category: tempToMatchMissingParameter[i].category, parameter: tempToMatchMissingParameter[i].parameter
                  , value: '', units: ''
                };
                dataGeneralTableGroup[key].splice(i, 0, tempValue)
              } else {
                let tempValue = {
                  category: tempToMatchMissingParameter[i].category, parameter: tempToMatchMissingParameter[i].parameter
                  , value: 0, units: tempToMatchMissingParameter[i].units
                };
                dataGeneralTableGroup[key].splice(i, 0, tempValue)
              }

            }
          }
        }
      }
      for (var key in dataGeneralTableGroup) {
        listDataTemp[key] = []
        for (var i in dataGeneralTableGroup[key]) {
          if (dataGeneralTableGroup[key][i].units !== '') {
            var valueRound = parseFloat(dataGeneralTableGroup[key][i].value).toFixed(2)
            listDataTemp[key].push({
              "ElementId": key, "Category": dataGeneralTableGroup[key][i].category,
              [dataGeneralTableGroup[key][i].parameter.toString()]: parseFloat(valueRound)
            })
          }
          else
            listDataTemp[key].push({
              "ElementId": key, "Category": dataGeneralTableGroup[key][i].category,
              [dataGeneralTableGroup[key][i].parameter.toString()]: dataGeneralTableGroup[key][i].value
            })
        }
      }
      for (let key in listDataTemp) {
        let temp = {}
        temp = listDataTemp[key].reduce(((r, c) => Object.assign(r, c)), {})
        dataGeneralTable.push(temp)
      }
      let listParameterSort = [{ displayName: 'ElementId' }, { displayName: 'Category' }]
      listParameterSelected.forEach(parameter => {
        listParameterSort.push(parameter)
      })
      var columns = ColumnSchedule(listParameterSort)

      this.setState({
        displayScheduleSavedPanel: true,
        columnsScheduleSaved: columns,
        dataScheduleSaved: dataGeneralTable,
        listFullData: dataGeneralTable,
        groupByScheduleSaved: groupByScheudle,
        maxForgeViewer: $('#splitForgeViewer').height(),
        modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock
      },
        () => {
          $('#splitForgeViewer').height(this.state.modifyForgeViewer)
          this.props.viewer.resize()
          this.props.viewer.restoreState(viewPoint)
        })
      this.handleClosePanel()
      _.forEach(tempListModel, (v) => {
        _this.props.viewer.clearThemingColors(v);
      })
    }
    catch{ }
  }
  handleGetAllCategoryAndParameter = async () => {
    let count = 0;
    allDbIds.forEach((item) => {
      count = count + item.dbIds.length
      tempListModel[item.modelName] = item.model
    })
    let split = this.props.itemName.split(".")
    if (split[split.length - 1].toLowerCase() === 'rvt') {
      let listTempCategory = await GetElementAndParameterRvt(allDbIds, count)
      this.setState({ loadingCategory: false, listCategory: listTempCategory })
    } else if (split[split.length - 1].toLowerCase() === 'dwfx') {
      let listTempCategory = await GetElementAndParameterDWFX(allDbIds, count)
      this.setState({ loadingCategory: false, listCategory: listTempCategory })
    }
  }

  onActiveTable = () => {
    this.setState({ displayScheduleSavedPanel: false }, () => {
      $('#splitForgeViewer').height(this.state.maxForgeViewer)
      this.props.viewer.resize()
    })

  }
  sizeChange = (e) => {
    if (this.props.displayScheduleSavedPanel) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
      })
    }
  }
  onChangeHeader = (id, text) => {
    let temp = []
    _.forEach(this.state.columnsScheduleSaved, v => {
      if (id == v.id) {
        v.filter = text
        temp.push(v)
      } else {
        temp.push(v)
      }
    })
    this.setState({ columnsScheduleSaved: temp })
  }
  onIsolateRows = (data) => {
    let temp = []
    if (!this.state.activeButtonIsolateRows) {
      _.forEach(this.state.dataScheduleSaved, v => {
        if (data.includes(v.ElementId))
          temp.push(v)
      })
      this.setState({ dataScheduleSaved: temp, activeButtonIsolateRows: true })
    } else {
      this.setState({ dataScheduleSaved: this.state.listFullData, activeButtonIsolateRows: false })
    }

  }
  render() {

    const columns = [
      {
        Header: 'Group',
        accessor: 'groupSchedule',
        id: 'groupSchedule',
        units: false,
        filter: "includes",
      },
      {
        Header: 'Schedule Name',
        accessor: 'scheduleName',
        id: 'scheduleName',
        units: false,
        filter: "includes",
      },
      {
        Header: 'Version',
        accessor: 'version',
        id: 'version',
        units: false,
        filter: "includes",
        minWidth: 62.4,
        width: 62.4,
        maxWidth: 62.4,
      },
      {
        Header: 'Created by',
        accessor: 'userCreated',
        id: 'userCreated',
        units: false,
        filter: "includes",
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        id: 'dateCreated',
        units: false,
        filter: "includes",
      },
    ];
    return (
      <div>
        <Modal show={this.state.openPanel} onHide={this.handleClosePanel.bind(this)} size='md' overflow={true}>
          <Modal.Header>
            <Modal.Title>Schedules Saved</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <TableListScheduleSaved
              columns={columns}
              data={this.state.data}
              viewer={this.props.viewer}
              getRowProps={() => ({})}
              getCellProps={() => ({})}
              handleDeleteScheduleSaved={this.handleDeleteScheduleSaved}
              handleShareScheduleSaved={this.handleShareScheduleSaved}
              handleOpenScheduleSaved={this.handleOpenScheduleSaved}
              email={this.props.email}
              loadingBtn={this.state.loadingBtnDelete}
              version={this.props.version}
            />
          </Modal.Body>
        </Modal>
        <Dock position={'bottom'} isVisible={this.state.displayScheduleSavedPanel} dimMode={'none'} fluid={false} zIndex={200} size={this.state.heightDock}
          onSizeChange={this.sizeChange}>
          <TableScheduleSaved columns={this.state.columnsScheduleSaved} data={this.state.dataScheduleSaved}
            viewer={this.props.viewer}
            tempListModel={tempListModel}
            getRowProps={() => ({})}
            getCellProps={() => ({})}
            groupByScheduleSaved={this.state.groupByScheduleSaved}
            onActiveTable={this.onActiveTable}
            onChangeHeader={this.onChangeHeader}
            onIsolateRows={this.onIsolateRows}
            activeButtonIsolateRows={this.state.activeButtonIsolateRows}
          // email={this.props.email}
          // itemIdCurrent={this.props.itemId}
          // version={this.props.version}
          />
        </Dock>
      </div>

    );
  }
}

export default DialogScheduleSaved
