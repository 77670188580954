import { Icon, Input, message, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiFetchDataMultiForm, apiUpdateOrCreateRows, apiUpdateRowsHistory, apiUpdateOrCreateRowsMultiForm, apiFetchDataMultiByDisciplineForm } from '../../../api';
import ButtonGroupComp from './ButtonGroupComp';
import styled from 'styled-components';
import TableDrawingDmsToIssueForConstruction from './TableDrawingDmsToIssueForConstruction';
import ButtonStyle from './ButtonStyle';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import { colorType } from '../../../constants';
import { getRefStringWithVersion } from '../../../utils';
import { CellRevisionForDtForm, fetchAllDrawingsMultiFormToLinkDt, getHeaderWidthDwgRef } from './PanelAddNewMultiForm';



const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const PanelLinkOldDtToDms = ({ stateProject, commandAction, getSheetRows, rowData, onClickCancel, setLoading, linkedFormTypeName }) => {

   const { email, token, projectId, company } = stateProject.allDataOneSheet;

   const [dataDms, setDataDms] = useState(null);

   const [modalDmsViewShown, setModalDmsViewShown] = useState(false);

   const [listDrawingToMigrate, setListDrawingToMigrate] = useState([]);

   const [listCurrentFilesLinkInDt, setListCurrentFilesLinkInDt] = useState([]);

   const [outputLinkToDwg, setOutputLinkToDwg] = useState({});

   const [panelConfirmMigrate, setPanelConfirmMigrate] = useState(false);

   useEffect(() => {

      const fetchData = async () => {
         try {
            if (!window.dms_tempAllFormDataForDtMigrate || (window.dms_tempAllFormDataForDtMigrate && window.dms_tempAllFormDataForDtMigrate['linkedFormTypeName'] !== linkedFormTypeName)) {
               const dataFetched = await fetchAllDrawingsMultiFormToLinkDt({ linkedFormTypeName, token, projectId, email });
               window.dms_tempAllFormDataForDtMigrate = { linkedFormTypeName, data: dataFetched };
               setDataDms(dataFetched);
            } else {
               setDataDms(window.dms_tempAllFormDataForDtMigrate['data']);
            };
         } catch (err) {
            console.log('ERROR...', err);
         };
      };
      fetchData();
   }, []);



   useEffect(() => {
      const formDt = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'linkSignedOffFormSubmit');
      let output = [];
      if (formDt) {
         output = [
            ...output,
            {
               type: 'Form',
               fileLink: formDt
            }
         ];
      };

      const linkDrawingsRfa = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'linkDrawingsRfa') || [];
      if (linkDrawingsRfa.length > 0) {
         output = [
            ...output,
            ...linkDrawingsRfa.map(lk => {
               return {
                  type: 'RFA',
                  fileLink: lk
               };
            })
         ];
      };

      const linkAttachments = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'linkDrawings') || [];
      if (linkAttachments.length > 0) {
         output = [
            ...output,
            ...linkAttachments.map(lk => {
               return {
                  type: 'Attachment',
                  fileLink: lk
               };
            })
         ];
      };

      setListCurrentFilesLinkInDt(output);

   }, []);


   const setFileLinkToDrawings = (drawingIdToLink, fileLink) => {
      setOutputLinkToDwg({
         ...outputLinkToDwg,
         [drawingIdToLink]: fileLink
      })
   };

   const [rowVersion, setRowVersion] = useState({});
   const setMigrateIssueForConstructionRevision = (rowData, versionText) => {
      setRowVersion({
         ...rowVersion,
         [rowData['id']]: versionText
      });
   };


   const generateColumnsMigrateDwg = () => {

      const arrayHeaders = linkedFormTypeName === 'rfa' ? [
         'Drawing Number', 'RFA Ref', 'Dwg Rev', 'IFC Rev', 'File To Link'
      ] : [
         `${linkedFormTypeName.toUpperCase()} Ref`, 'Form Rev', 'IFC Rev', 'File To Link'
      ];

      return [
         {
            key: 'index', dataKey: 'index', title: '', width: 40,
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer: ({ rowIndex }) => <div>{rowIndex + 1}</div>
         },
         ...arrayHeaders.map((column, index) => ({
            key: column, dataKey: column, title: column,
            resizable: true,
            frozen: index < 1 ? Column.FrozenDirection.LEFT : undefined,
            width: column.includes(' Ref') ? 300 : column === 'File To Link' ? 1000 : getHeaderWidthDwgRef(column),
            cellRenderer: column === 'File To Link' ? ( // MUST RETURN LIKE THIS, NOT ({ rowData }) => (), otherwise outputLinkToDwg can not update - DON'T KNOW WHY
               <CellSelectFileInDt
                  listCurrentFilesLinkInDt={listCurrentFilesLinkInDt}
                  setFileLinkToDrawings={setFileLinkToDrawings}
                  outputLinkToDwg={outputLinkToDwg} // MUST INCLUDE outputLinkToDwg HERE EVEN NO USE LATER TO GET LATEST outputLinkToDwg - DON'T KNOW WHY
               />
            ) : column === 'IFC Rev' ? (
               <CellRevisionForDtForm
                  linkedFormTypeName={linkedFormTypeName}
                  setIssueForConstructionRevision={setMigrateIssueForConstructionRevision}
                  rowVersion={rowVersion} // MUST INCLUDE rowVersion HERE EVEN NO USE LATER TO GET LATEST rowVersion - DON'T KNOW WHY
               />
            ) : ({ rowData }) => getCellMigrateDtInfo({ rowData, column, linkedFormTypeName }),
         })),
      ];
   };


   const onClickMigrateDtToDmsDrawings = async () => {
      try {
         setLoading(true);

         const refText = getRefStringWithVersion(rowData, 'dt');
         const refType = 'dt';
         const dateIssue = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'date');
         const listIdIssueConstructionPrevious = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'listDrawingIssueForConstruction') || [];
         const linkedFormTypeNamePrevious = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkedFormTypeName');

         const arrayIdToUpdate = Object.keys(outputLinkToDwg);

         const listIdToRollback = listIdIssueConstructionPrevious.filter(id => arrayIdToUpdate.indexOf(id) === -1);

         let rowCurrentToRollback = [];
         let rowHistoryToRollback = [];
         let rowMultiFormToRollback = [];

         const objDataUpdateRollback = {
            [`submission-${refType}-issueForConstruction-${company}`]: '',
            [`submission-${refType}-drawingLinkIssueForConstruction-${company}`]: [],
            [`submission-${refType}-ifcVersion-${company}`]: '',
            [`submission-${refType}-date-${company}`]: '',
         };

         if (listIdToRollback.length > 0) {
            if (
               !linkedFormTypeNamePrevious || // all dms drawing link DT before (OLD DB)
               linkedFormTypeNamePrevious === 'rfa'
            ) {
               rowCurrentToRollback = listIdToRollback.map(id => ({ _id: id, data: objDataUpdateRollback }));
               rowHistoryToRollback = listIdToRollback.map(id => ({ _id: id, history: objDataUpdateRollback }));
            } else {
               rowMultiFormToRollback = listIdToRollback.map(id => ({ _id: id, data: objDataUpdateRollback }));
            };
         };

         let rowCurrentToUpdate = [];
         let rowHistoryToUpdate = [];
         let rowMultiFormToUpdate = [];
         if (arrayIdToUpdate.length > 0 && linkedFormTypeName) {
            arrayIdToUpdate.forEach(id => {

               const objDataUpdate = {
                  [`submission-${refType}-issueForConstruction-${company}`]: refText,
                  [`submission-${refType}-drawingLinkIssueForConstruction-${company}`]: [outputLinkToDwg[id]],
                  [`submission-${refType}-ifcVersion-${company}`]: rowVersion[id],
                  [`submission-${refType}-date-${company}`]: dateIssue,
               };
               if (linkedFormTypeName === 'rfa') {
                  rowCurrentToUpdate.push({ _id: id, data: objDataUpdate });
                  rowHistoryToUpdate.push({ _id: id, history: objDataUpdate });
               } else {
                  rowMultiFormToUpdate.push({ _id: id, data: objDataUpdate });
               };
            });
         };

         const rowCurrentToUpdateOrRollback = [...rowCurrentToRollback, ...rowCurrentToUpdate];
         const rowHistoryToUpdateOrRollback = [...rowHistoryToRollback, ...rowHistoryToUpdate];
         

         if (rowCurrentToUpdateOrRollback.length > 0) {
            await apiUpdateOrCreateRows({ token, projectId, rows: rowCurrentToUpdateOrRollback, isOnlyUpdateRowsAlreadyExisted: true });
         };
         if (rowHistoryToUpdateOrRollback.length > 0) {
            await apiUpdateRowsHistory({ token, projectId, rowsHistory: rowHistoryToUpdateOrRollback });
         };
         if (rowMultiFormToRollback.length > 0) {
            await apiUpdateOrCreateRowsMultiForm(linkedFormTypeNamePrevious, { token, projectId, rows: rowMultiFormToRollback });
         };
         if (rowMultiFormToUpdate.length > 0) {
            await apiUpdateOrCreateRowsMultiForm(linkedFormTypeName, { token, projectId, rows: rowMultiFormToUpdate });
         };

         const rowDtToUpdate = {
            _id: rowData['id'],
            data: {
               [`submission-dt-listDrawingIssueForConstruction-Woh Hup Private Ltd`]: Object.keys(outputLinkToDwg),
               [`submission-dt-linkedFormTypeName-Woh Hup Private Ltd`]: linkedFormTypeName
            }
         };
         await apiUpdateOrCreateRowsMultiForm('dt', { token, projectId, rows: [rowDtToUpdate] });

         message.success('Submitted Successfully', 2);

         const res = await apiFetchDataMultiByDisciplineForm('dt', { token, projectId, email });
         commandAction({ type: 'reload-data-view-multi-form', data: res.data });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };



   return dataDms ? (
      <div>

         <ButtonStyle
            name={'Link Drawing DMS'}
            onClick={() => setModalDmsViewShown(true)}
         />


         {(listDrawingToMigrate.length > 0) && (
            <div style={{
               marginTop: 20,
               width: window.innerWidth * 0.9 - 80,
               height: listDrawingToMigrate.length * 28 + 80
            }}>
               <TableStyled
                  fixed
                  columns={generateColumnsMigrateDwg()}
                  data={listDrawingToMigrate}
                  rowHeight={28}
               />
            </div>
         )}


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancel}
               onClickApply={() => {
                  setPanelConfirmMigrate(true);
               }}
               newTextBtnApply={'Migrate'}
            />
         </div>



         {modalDmsViewShown && (
            <ModalStyled
               title={'Select DMS Drawings To Link'}
               visible={modalDmsViewShown}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.85}
            >
               <TableDrawingDmsToIssueForConstruction
                  activityType={'migrate-old-dt-dms'}
                  stateProject={stateProject}
                  dataInputDmsTable={dataDms}
                  listDrawingToMigrate={listDrawingToMigrate}
                  onClickCancelModalPickDrawingToIssue={() => setModalDmsViewShown(false)}
                  onClickApplyModalPickDrawingToIssue={(arrayDrawingsToLink) => {
                     setListDrawingToMigrate(arrayDrawingsToLink);
                     let output = {};
                     let versionRowObj = {};
                     arrayDrawingsToLink.forEach(row => {
                        if (outputLinkToDwg[row['id']]) {
                           output[row['id']] = outputLinkToDwg[row['id']]
                        };
                        if (!rowVersion[row['id']]) { // maintain all IFC rev of existing picked drawings
                           versionRowObj[row['id']] = linkedFormTypeName === 'rfa' ? row['Rev'] : row['revision'];
                        };
                     });

                     setOutputLinkToDwg(output);

                     setRowVersion({ ...rowVersion, ...versionRowObj });

                     setModalDmsViewShown(false);
                  }}
               />
            </ModalStyled>
         )}


         {panelConfirmMigrate && (
            <ModalStyled
               title={'Confirmation'} visible={panelConfirmMigrate}
               footer={null} destroyOnClose={true} centered={true}
            >
               <div style={{ padding: 20 }}>{`Are you sure to migrate DT to DMS drawings ?`}</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonGroupComp
                     onClickCancel={() => setPanelConfirmMigrate(false)}
                     newTextBtnApply={'Yes'}
                     onClickApply={onClickMigrateDtToDmsDrawings}
                  />
               </div>
            </ModalStyled>
         )}



      </div>
   ) : (
      <div style={{ height: 124, display: 'flex', justifyContent: 'center', paddingTop: 35 }}>
         <Icon type='loading' style={{ fontSize: 30 }} />
      </div>
   );
};

export default PanelLinkOldDtToDms;



const ModalStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;

const TableStyled = styled(Table)`
   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .BaseTable__row-cell-text {
      color: black
   };
   .BaseTable__table .BaseTable__body {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   };
   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };
   .BaseTable__header-row {
      background: ${colorType.grey1};
   };
   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;


const CellSelectFileInDt = ({ listCurrentFilesLinkInDt, rowData, setFileLinkToDrawings }) => {

   const [value, setValue] = useState('No File Link');

   return (
      <SelectStyled
         placeholder='Select File...'
         onChange={(fileText) => {

            const fileFound = listCurrentFilesLinkInDt.find(x => getFileText(x) === fileText);
            if (fileFound) {
               setFileLinkToDrawings(rowData['id'], fileFound['fileLink']);
            };

            setValue(fileText);
         }}
         value={value}
      >
         {listCurrentFilesLinkInDt.map((file, i) => {
            const fileText = getFileText(file);
            return (
               <Select.Option key={i} value={fileText}>
                  {fileText}
               </Select.Option>
            );
         })}
      </SelectStyled>
   );
};


const SelectStyled = styled(Select)`
   width: 100%;
   outline: none;
   .ant-select-selection {
      outline: none;
      border-radius: 0;
      border: none;
      width: 100%;
      background: transparent;
   };
   .ant-select-selection__rendered {
      outline: none;
   };
`;


const getFileText = (file) => {
   const { fileLink, type } = file;
   const allParts = fileLink.split('/');
   const fileName = allParts[allParts.length - 1] || '';

   let extraData = '';
   if (type === 'RFA') {
      const companyName = (allParts[8] || '').replaceAll('_', ' ');
      extraData = ` - (${companyName})`;
   };

   return `(${type}) ${fileName}${extraData}`;
};

const getCellMigrateDtInfo = ({ rowData, column, linkedFormTypeName }) => {
   if (column === 'Drawing Number' || column === 'RFA Ref') return rowData[column];
   if (column === 'Dwg Rev') return rowData['Rev'];
   if (column === `${linkedFormTypeName.toUpperCase()} Ref`) return getRefStringWithVersion(rowData, linkedFormTypeName);
   if (column === 'Form Rev') return rowData['revision'];
};