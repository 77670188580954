import { MANPOWER_ITEMS, MANPOWER_ITEMS_LOADING } from "../types";
import { constants } from "../components/moudle_Manpower/constants";

const init = {
  gridApi: null,
  selectedNode: [],
  data: [],
  loading: false,
  typeTimeline:'week',
  timelineDate:[],
  openEditMultiValuesDialog: {isOpen: false, data:null},
  format:constants.weekFormat,
  groupFormat:constants.monthFormat,
  client:[],
  lead:[],
  jobTitle:[],
  department:[]
}

export function manpower(state = init, action = {}) {
  switch (action.type) {
    case MANPOWER_ITEMS:
      return { ...state, ...action.data };
    case MANPOWER_ITEMS_LOADING:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
