import React from 'react';
import axios from 'axios';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { excelData } from './actions/app/excel';
import { Client } from '@microsoft/microsoft-graph-client';

import UserRoute from './routes/UserRoute.js';
import GuestRoute from './routes/GuestRoute.js';
import AdminRoute from './routes/AdminRoute';

import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';
// import SignupPage from "./components/pages/SignupPage";
import DashboardPage from './components/pages/BimApp/DashBoardPage';

// import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
// import ResetPasswordPage from "./components/pages/ResetPasswordPage";
// import ConfirmationPage from "./components/pages/ConfirmationPage.js";
import TopNavigation from './components/navigation/TopNavigation';
import ProjectPage from './components/pages/BimApp/ProjectPage';
import FolderPage from './components/pages/BimApp/FolderPage';
import ViewerPage from './components/pages/BimApp/ViewerPage';
import IssuePage from './components/pages/BimApp/IssuePage';
import ShareViewPage from './components/pages/BimApp/ShareViewPage';
import CommentPage from './components/pages/BimApp/CommentPage';
import AdminPage from './components/pages/AdminPage';
import NoPage from './components/pages/NoPage';

// import ExcelPage from "./components/pages/Excel/ExcelPage";
// import ProjectDetail from "./components/pages/ProjectDetail";

// import QrCodeScanner from './components/navigation/QrCodeScanner';
// import SheetPage from './components/pages/SheetPage';

// import PrecastInfoPage from './components/pages/QRCode/PrecastInfoPage';
// import QRCodeScanPage from './components/pages/QRCode/QRCodeScanPage';
// import Monitoring from './components/pages/Monitoring/Monitoring';
// import Chart from './components/pages/Monitoring/Chart';
// import PPVCDashBoard from './components/pages/Dashboard/index';

import history from './history';
import LoadViewPage from './components/pages/BimApp/LoadViewPage.js';
import { storageUser } from './components/module_BimApp/function/PageFunction';
import { socket } from './components/module_BimApp/function/AdminFunction';
//import SheetDashBoardPage from './components/pages/GanttChartPage/SheetDashBoardPage'
// import ReactGA from 'react-ga';
// const trackingId = "UA-158810794-1"; //
// ReactGA.initialize(trackingId);

import ProjectDetail from './components/pages/BimApp/ProjectDetail';
// import GanttPage from './components/pages/GanttChartPage/GanttPage'
import DashboardDrawing from './components/pages/DMSApp/DashboardDrawing';
import DMSTable from './components/pages/DMSApp/DMSPage';
import DMSForgeViewerPage from './components/pages/DMSApp/DMSForgeViewerPage';

import ExcelPage from './components/pages/Excel/ExcelPage';

// const DataEntrySheet = React.lazy(() => import("./components/pages/DataEntry/DataEntrySheet"))
// const DataEntryPage = React.lazy(() => import("./components/pages/DataEntry/DataEntrySheet"))
import DataEntryFor5D from './components/pages/DataEntry/DataEntryFor5D';
import DataEntryFor5DV11 from './components/pages/DataEntry/DataEntryFor5DV11';
import ConcreteForecast5d from './components/pages/DataEntry/ConcreteForecast5d/ConcreteForecast5d';
// import Data from './components/pages/DataEntry/DataGrid'
import ProgressReport from './components/pages/Report/ProgressReport';

import QuantityTracking from './components/pages/QuantityTracking/QuantityTracking';
import ArchiInput from './components/pages/ArchiInput/ArchiInput';
import ArchiSummary from './components/pages/ArchiInput/ArchiSummary';
import ArchiLibrary from './components/pages/Archi5D/ArchiLibrary';
import TimeLine from './components/pages/TimeLine/TimeLine';
import GanttSchedule from './components/pages/TimeLine/GanttSchedule';
// const QRCodePage = React.lazy(() =>
//    import("./components/pages/DataEntry/QRCodePage")
// );
import IssueApp from './components/pages/CoordinationApp/CoordinationApp';
import TrimblePage from './components/pages/TrimbleConnect/TrimblePage';
import RfaModelPage from './components/pages/DMSApp/RfaModelPage';
import WorkerPage from './components/pages/Worker/WorkerPage';
import DoInvPage from './components/pages/DoInv/DoInvPage';
import QaQcPage from './components/pages/QaQc/QaQcPage';
import ViewerPage5D from './components/pages/5D/ViewerPage5D.js';

import ManPower from './components/pages/Testing/ManPower';
import TestPDFViewer from './components/pdf/TestPDFViewer';
import RedirectPage from './components/pages/RedirectPage';

// const DashboardDrawingProject = React.lazy(() => import('./components/pages/DashboardLayout/DashboardDrawingProject'))
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: window.location.pathname,
      //  checkPathName: false
    };
  }

  componentWillMount = async () => {
    socket.on('realtime-user-data', this.handleUpdateUserData);
    // axios.post("/api/auth/new_token", { token: this.props.token, email: this.props.email })
    // .then(res => {
    //   storageUser(res.data.user)
    // })
    // .catch(() => {
    //   // this.props.logout(this.props.email)
    // })
    //     console.log(window.Office)
    //     window.Office.onReadyInternal((e)=>{

    //     })

    // window.Office.onReady()
    // if (window.Office.context === undefined) {
    //   window.Office.onReady = (e) => {
    //     if (e.host) {
    //       this.props.history.push("/excel")
    //       return
    //     }
    //   }
    // }
    if (window.hello('aad').getAuthResponse()) {
      //!note
      window.client = Client.init({
        authProvider: (done) => {
          done(null, window.hello('aad').getAuthResponse().access_token);
        },
      });
    }
    window.Office.initialize = (e) => {
      this.props.history.replace('/excel-page');
      this.props.excelData({ excelPage: true });
    };
  };
  componentWillUnmount = () => {
    socket.removeListener('realtime-user-data', this.handleUpdateUserData);
  };
  handleUpdateUserData = (data) => {
    if (data.email === this.props.email) storageUser(data.user);
  };
  handleChangeUrl = (value) => {
    this.props.history.push(value);
  };

  componentDidUpdate = (old, state) => {
    if (state.pathName !== window.location.pathname)
      this.setState({ pathName: window.location.pathname });
  };
  render() {
    const { location, isAuthenticated } = this.props;
    return (
      <React.Suspense fallback={null}>
        {isAuthenticated && (
          <TopNavigation
            history={history}
            handleChangeUrl={this.handleChangeUrl}
            pathName={this.state.pathName}
          />
        )}
        <Switch>
          <UserRoute
            location={location}
            path='/testing'
            exact
            component={ManPower}
            history={history}
          />

          <AdminRoute
            location={location}
            path='/adminpage'
            exact
            component={AdminPage}
            history={history}
          />
          {/* 
               <Route
                  location={location}
                  path="/adminpage2"
                  exact
                  component={AdminPage}
                  history={history}
               /> */}

          <Route
            location={location}
            path='/'
            exact
            component={HomePage}
            history={history}
          />
          {/* <Route
                  location={location}
                  path="/confirmation/:token"
                  exact
                  component={ConfirmationPage}
                  history={history}
               /> */}
          <Route
            location={location}
            path='/shareview/:token'
            exact
            component={ShareViewPage}
          />

          <GuestRoute
            location={location}
            path='/login-admin'
            exact
            component={LoginPage}
          />
          {/* <GuestRoute
                  location={location}
                  path="/forgot_password"
                  exact
                  component={ForgotPasswordPage}
               />
               <GuestRoute
                  location={location}
                  path="/reset_password/:token"
                  exact
                  component={ResetPasswordPage}
               /> */}
          {/* <GuestRoute location={location} path='/signup-hidden' exact component={SignupPage} /> */}

          <UserRoute
            location={location}
            path='/projects'
            exact
            component={DashboardPage}
            history={history}
          />
          <UserRoute
            location={location}
            path='/projects/project'
            exact
            component={ProjectPage}
          />
          <UserRoute
            location={location}
            path='/projects/project/folder'
            exact
            component={FolderPage}
          />
          <UserRoute
            location={location}
            path='/projects/project/folder/view'
            exact
            component={ViewerPage}
          />
          <UserRoute location={location} path='/issue' component={IssuePage} />
          <UserRoute
            location={location}
            path='/view'
            component={LoadViewPage}
          />
          <UserRoute
            location={location}
            path='/comment'
            component={CommentPage}
          />
          <UserRoute
            location={location}
            path='/rfa/:id'
            component={RfaModelPage}
          />
          {/* <UserRoute location={location} path='/test' component={SignaturePDF} /> */}
          {/* <UserRoute location={location} path='/projects/project/folder/compareview' exact component={ComparePage} /> */}
          {/* <UserRoute location = {location} path='/sheet' exact component={SheetPage}/> */}
          {/* <UserRoute location = {location} path='/qrscanner' exact component={QrCodeScanner}/> */}

          <UserRoute
            location={location}
            path='/excel-page'
            exact
            component={ExcelPage}
            history={history}
          />
          <UserRoute
            location={location}
            path='/drawing-dashboard'
            exact
            component={DashboardDrawing}
            history={history}
          />
          {/* <UserRoute
                  location={location}
                  path="/gantt/:projectId"
                  exact
                  component={GanttPage}
                  history={history}
               /> */}
          <UserRoute
            location={location}
            path='/projectdetail'
            exact
            component={ProjectDetail}
            history={history}
          />

          <UserRoute
            location={location}
            path='/dms'
            component={DMSTable}
            history={history}
          />
          <UserRoute
            location={location}
            exact
            path='/dms-viewer/:id'
            component={DMSForgeViewerPage}
            history={history}
          />

          {/* <UserRoute location={location} path='/dataentry/general' exact component={DataEntrySheet} history={history} /> */}
          {/* <UserRoute
                  location={location}
                  path="/for5d/table"
                  exact
                  component={DataEntryFor5D}
                  history={history}
               /> */}
          <UserRoute
            location={location}
            path='/for5d/table'
            exact
            component={DataEntryFor5DV11}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/table-forcast'
            exact
            component={ConcreteForecast5d}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/progress-report'
            exact
            component={ProgressReport}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/quantity-tracking'
            exact
            component={QuantityTracking}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/time-line'
            exact
            component={TimeLine}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/schedule'
            exact
            component={GanttSchedule}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/library'
            exact
            component={ArchiLibrary}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/viewer'
            exact
            component={ViewerPage5D}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/archi-input'
            exact
            component={ArchiInput}
            history={history}
          />
          <UserRoute
            location={location}
            path='/for5d/archi-summary'
            exact
            component={ArchiSummary}
            history={history}
          />
          <UserRoute
            location={location}
            path='/dataentry/do-invoice'
            component={DoInvPage}
            history={history}
          />

          <UserRoute
            location={location}
            path='/qaqc'
            component={QaQcPage}
            history={history}
          />
          {/* <UserRoute location={location} path='/dataentry/datacode' exact component={QRCodePage} history={history} /> */}
          {/* <UserRoute location={location} path='/dataentry' exact component={DataEntryPage} history={history} /> */}

          <UserRoute
            location={location}
            path='/worker'
            component={WorkerPage}
            history={history}
          />

          <UserRoute
            location={location}
            path='/issueapp'
            exact
            component={IssueApp}
            history={history}
          />
          <UserRoute
            location={location}
            path='/projects/project/folder/view-trimble'
            exact
            component={TrimblePage}
            history={history}
          />
          <UserRoute
            location={location}
            path='/pdf'
            exact
            component={TestPDFViewer}
            history={history}
          />
          <Route
            location={location}
            path='/redirect/:type/:url'
            exact
            component={RedirectPage}
            history={history}
          />
          <Route path='*' component={NoPage} history={history} />
        </Switch>
      </React.Suspense>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  excelData: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.email,
    email: state.user.email,
    token: state.user.token,
  };
}
export default connect(mapStateToProps, { excelData: excelData })(App);

{
  /* <UserRoute location={location} path='/viewDrawing/:project/:mark' exact component={PrecastInfoPage} />
        <UserRoute location={location} path='/PC/:project/scanQRCode/:moduleCode' exact component={QRCodeScanPage} />
        <UserRoute location={location} path='/PC/:project/scanQRCode' exact component={QRCodeScanPage} />
        <UserRoute location={location} path='/PC' exact component={Monitoring} />
        <UserRoute location={location} path='/PC/:project' exact component={Monitoring} />
        <UserRoute location={location} path='/PC/:project/chart' exact component={Chart} />
        <UserRoute location={location} path='/ppvcdb' exact component={PPVCDashBoard} />
        <UserRoute location={location} path='/ppvcdb/:project' exact component={PPVCDashBoard} /> */
}
