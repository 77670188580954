import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  IconButton,
  Modal,
  SelectPicker,
  Button,
  ButtonGroup,
  Divider,
} from 'rsuite';
import { Typography, message, Tooltip } from 'antd';

import _ from 'lodash';
import axios from 'axios';
import { MenuProvider, animation } from 'react-contexify';
import { Tree } from '@blueprintjs/core';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  getAllChildTreeNode,
  unloadForgeExtension2,
} from '../../../components/module_BimApp/function/ForgeFunction';
const { Text, Title } = Typography;
const Autodesk = window.Autodesk;

const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className='box' style={{ ...style, ...viewStyle }} {...props} />;
};

class SideBarPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openPanel: false,
      data: [],
      viewCurrent: null,
      document: null,
      dataTarget: null,
      isModelLoaded: false,
      modelLoaded: null,
      testData: [
        {
          id: props.projectId,
          hasCaret: true,
          icon: 'diagram-tree',
          label: props.projectName,
          type: 'project',
        },
      ],
      role: '',
    };
    this.treeModel = React.createRef();
    this.handleGetTreeNode = this.handleGetTreeNode.bind(this);
  }

  componentDidMount = () => {
    let role = '';
    if (
      this.props.userInfo.email === 'admin@wohhup.com'
    ) {
      role = 'admin';
    } else {
      let index = _.findIndex(this.props.userInfo.contractors, (v) => {
        return v.projectId === this.props.projectId;
      });
      if (index >= 0) {
        role = this.props.userInfo.contractors[index].role;
        this.setState({ role });
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.viewer) {
      if (this.props.itemIds !== nextProps.itemIds) {
        const models = this.props.viewer.impl.modelQueue().getModels();
        _.forEach(models, (v) => {
          this.props.viewer.unloadModel(v);
        });
        let treeNode = this.handleGetTreeNode();
        _.forEach(treeNode, (node) => {
          node.status = '';
          node.open = false;
          node.color = '';
          node.icon = this.generateNodeIcon(node);
        });
        this.setState(
          {
            openPanel: false,
            viewCurrent: null,
            modelLoaded: null,
            loading: false,
            testData: this.state.testData,
          },
          () => {
            _.forEach(nextProps.itemIds, (v) => {
              let index = _.findIndex(treeNode, (o) => {
                return o.type === 'item' && o.itemId == v.itemId;
              });
              if (index >= 0) {
                treeNode[index].globalOffset = v.globalOffset;
                Autodesk.Viewing.Document.load(
                  'urn:' + v.objectId,
                  this._onDocumentLoadSuccess.bind(this, treeNode[index], true),
                  this._onDocumentLoadFailure.bind(this, treeNode[index], true)
                );
              } else {
                v.isNotTreeNode = true;
                Autodesk.Viewing.Document.load(
                  'urn:' + v.objectId,
                  this._onDocumentLoadSuccess.bind(this, v, true),
                  this._onDocumentLoadFailure.bind(this, v, true)
                );
              }
            });
          }
        );
      }
    }
  }
  //#region //! load model
  handleOpenView = (node) => {
    const models = this.props.viewer.impl.modelQueue().getModels();
    if (models.length !== 0) {
      let split1 = node.text.split('.');
      let extension1 = split1[split1.length - 1].toLowerCase();
      let split2 = models[0].myData.loadOptions.modelNameOverride.split('.');
      let extension2 = split2[split2.length - 1].toLowerCase();
      if (extension1 !== extension2) {
        message.warning(`Only load mode same format`);
        return;
      }
    }
    if (node.status === 'pending') {
      _.forEach(models, (v, k) => {
        if (v.myData.loadOptions.modelNameOverride === node.text) {
          this.props.viewer.unloadModel(v);
          this.uploadNodeModel(node, true);
          return false;
        }
      });
    } else if (node.open) {
      node.status = 'pending';
      node.icon = this.generateNodeIcon(node);
      this.setState(
        { isModelLoaded: true, testData: this.state.testData },
        () => {
          Autodesk.Viewing.Document.load(
            'urn:' + node.objectId,
            this._onDocumentLoadSuccess.bind(this, node, false),
            this._onDocumentLoadFailure.bind(this, node, false)
          );
        }
      );
    } else if (!node.open) {
      node.status = 'pending';
      node.icon = this.generateNodeIcon(node);
      this.setState(
        { isModelLoaded: false, testData: this.state.testData },
        () => {
          Autodesk.Viewing.Document.load(
            'urn:' + node.objectId,
            this._onDocumentLoadSuccess.bind(this, node, false),
            this._onDocumentLoadFailure.bind(this, node, false)
          );
        }
      );
    }
  };
  uploadNodeModel = (node, check, svfUrl, loadOptions) => {
    if (check) {
      node.status = '';
      node.open = false;
      node.color = '';
      node.icon = this.generateNodeIcon(node);
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
        testData: this.state.testData,
      });
    } else {
      node.status = 'pending';
      node.color = '';
      node.icon = this.generateNodeIcon(node);
      this.props.viewer.loadModel(
        svfUrl,
        loadOptions,
        this._onLoadModelSuccess.bind(this, node, false),
        this._onLoadModelError.bind(this, node, false)
      );
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
        testData: this.state.testData,
      });
    }
  };
  _onDocumentLoadFailure = (node, isSaved = false, viewerErrorCode) => {
    if (!isSaved) {
      node.status = '';
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  _onDocumentLoadSuccess = (node, isSaved = false, doc) => {
    this.props.callbackRemoveDataTable();
    unloadForgeExtension2(this.props.viewer);

    if (isSaved) {
      var viewables = null;
      let view3d = doc
        .getRoot()
        .search({ type: 'geometry', role: '3d', progress: 'complete' }, true);
      let index = _.findIndex(view3d, (e) => {
        return e.data.guid === node.viewGuid;
      });
      if (index >= 0) {
        viewables = view3d[index];
      } else {
        viewables = doc.getRoot().getDefaultGeometry();
      }
      let svfUrl = doc.getViewablePath(viewables);
      let loadOptions = {
        globalOffset: node.globalOffset,
        applyRefPoint: true,
        modelNameOverride: node.text,
        isAEC: true,
        guid: viewables.data.guid,
        viewableID: viewables.data.viewableID,
        itemId: node.itemId,
        version: node.versionCurrent,
        acmSessionId: doc.acmSessionId,
      };
      this.props.viewer.loadModel(
        svfUrl,
        loadOptions,
        this._onLoadModelSuccess.bind(this, node, node.isNotTreeNode),
        this._onLoadModelError.bind(this, node, node.isNotTreeNode)
      );
      this.setState({
        openPanel: false,
        viewCurrent: null,
        modelLoaded: null,
        loading: false,
      });
    } else {
      let temp = [];
      let models = this.props.viewer.impl.modelQueue().getModels();
      let view3d = doc
        .getRoot()
        .search({ type: 'geometry', role: '3d', progress: 'complete' }, true);
      _.forEach(view3d, (v) => {
        temp.push({
          label: v.data.name,
          value: v.data.guid,
          group: v.data.role.toUpperCase(),
          obj: v,
        });
      });
      if (temp.length === 1) {
        if (node.open) {
          _.forEach(models, (v, k) => {
            if (v.myData.loadOptions.modelNameOverride === node.text) {
              this.props.viewer.unloadModel(v);
              this.uploadNodeModel(node, true);
              return false;
            }
          });
        } else {
          let svfUrl = doc.getViewablePath(temp[0].obj);
          let loadOptions = {
            globalOffset: this.props.viewer.impl.model
              ? this.props.viewer.impl.model.myData.globalOffset
              : null,
            applyRefPoint: true,
            modelNameOverride: node.text,
            isAEC: true,
            guid: temp[0].obj.data.guid,
            viewableID: temp[0].obj.data.viewableID,
            itemId: node.itemId,
            version: node.version,
            acmSessionId: doc.acmSessionId,
          };
          this.props.viewer.loadModel(
            svfUrl,
            loadOptions,
            this._onLoadModelSuccess.bind(this, node, false),
            this._onLoadModelError.bind(this, node, false)
          );
          this.setState({
            openPanel: false,
            viewCurrent: null,
            modelLoaded: null,
            loading: false,
          });
        }
      } else {
        if (node.open) {
          _.forEach(models, (v) => {
            if (v.myData.loadOptions.modelNameOverride === node.text) {
              let guid = v.myData.loadOptions.guid;
              this.setState({
                data: temp,
                openPanel: true,
                document: doc,
                viewCurrent: guid,
                modelLoaded: v,
                nodeCurrent: node,
              });
              return false;
            }
          });
        } else
          this.setState({
            data: temp,
            openPanel: true,
            document: doc,
            nodeCurrent: node,
          });
      }
    }
  };
  _onLoadModelSuccess = (node, isSaved, modelCurrent) => {
    this.props.viewer
      .waitForLoadDone({
        onlyModels: this.props.viewer.impl.modelQueue().getModels(),
      })
      .then((res) => {
        console.log('done');
        let settings = document.getElementById('toolbar-levelsTool');
        if (settings) settings.style.display = 'none';
        this.props.callbackLoadDone();

        // this.props.viewer.removeEventListener(
        //   Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        //   (e) => {})

        // this.props.viewer.addEventListener(
        //   Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        //   (e) => {
        //     console.log("e", e);

        //     let externalIds = [];

        //     if (Array.isArray(e.dbIdArray)) {
        //       for (const dbId of e.dbIdArray) {
        //         this.props.viewer.model.getProperties(dbId, (ee) => {
        //           externalIds.push(ee.externalId);

        //           if (dbId == e.dbIdArray[e.dbIdArray.length - 1]) {
        //             this.props.highLightRowsRelatedToSelectedElements(
        //               externalIds
        //             );
        //           }
        //         });
        //       }
        //     }
        //     else{
        //       this.props.highLightRowsRelatedToSelectedElements(
        //         []
        //       );
        //     }
        //   }
        // );
      });
    if (!isSaved) {
      node.status = '';
      node.open = true;
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  _onLoadModelError = (node, isSaved, viewerErrorCode) => {
    if (!isSaved) {
      node.status = '';
      node.icon = this.generateNodeIcon(node);
    }
    this.setState({ loading: false, testData: this.state.testData });
  };
  //#endregion

  handleCloseSidebar = (e) => {
    this.props.close();
  };

  //#region tree
  handleNodeCollapse = (node, e) => {
    node.isExpanded = false;
    this.setState({ testData: this.state.testData });
  };
  handleNodeExpand = (node, e) => {
    if (!this.props.viewer) return;
    if (!node.isLoaded) {
      node.secondaryLabel = <Icon icon='spinner' spin />;
      node.disabled = true;
      this.setState({ testData: this.state.testData });
      const models = this.props.viewer.impl.modelQueue().getModels();

      console.log('this.state.role', this.state.role);
      axios
        .get('/api/versions/project-tree', {
          params: {
            id: node.id,
            type: node.type,
            role: this.state.role,
            token: this.props.userInfo.token,
            isFilter: true,
          },
        })
        .then((res) => {
          res.data.sort(this.sortLabel);
          _.forEach(res.data, (v) => {
            if (v.typeFile !== '5d') return;
            let extension = '';
            if (v.type === 'item') {
              let split = v.text.split('.');
              extension = split[split.length - 1].toLowerCase();
              //v.type = extension === 'fbx' ? 'item' : extension === ext ? 'item' : 'no support'
            }
            if (v.type === 'item') {
              let index = _.findIndex(models, (o) => {
                return o.myData.loadOptions.itemId === v.itemId;
              });
              v.status = '';
              v.color = '';
              v.open = index >= 0 ? true : false;
              v.label = this.generateNodeLabel(v);
              v.icon = this.generateNodeIcon(v);
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            } else if (v.type === 'no translate') {
              v.label = (
                <>
                  <Text style={{ color: 'orange' }}> {v.text} </Text>
                </>
              );
              v.icon = (
                <ButtonGroup>
                  <IconButton
                    size='xs'
                    icon={<Icon icon={'lock'} />}
                    disabled={true}
                  />
                </ButtonGroup>
              );
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            } else if (v.type === 'no support') {
              v.label = (
                <>
                  <IconButton
                    size='xs'
                    disabled={true}
                    icon={<Icon icon={'lock'} />}
                  />
                  <Text> {v.text} </Text>
                </>
              );
              v.icon = (
                <ButtonGroup>
                  <IconButton
                    size='xs'
                    icon={<Icon icon={'lock'} />}
                    disabled={true}
                  />
                </ButtonGroup>
              );
              v.secondaryLabel = (
                <Tooltip title={v.text} placement='top'>
                  <Text strong>V{v.version}</Text>
                </Tooltip>
              );
            }
          });
          node.secondaryLabel = <Icon icon='refresh' />;
          node.childNodes = res.data;
          node.isExpanded = true;
          node.disabled = false;
          node.isLoaded = true;
          this.setState({ testData: this.state.testData });
        })
        .catch((err) => {
          node.disabled = false;
          node.secondaryLabel = '';
        });
    } else {
      node.isExpanded = true;
      this.setState({ testData: this.state.testData });
    }
  };
  generateNodeIcon = (node) => {
    return (
      <ButtonGroup>
        <IconButton
          size='xs'
          onClick={this.handleOpenView.bind(this, node)}
          color={node.open && 'blue'}
          disabled={node.itemId === this.props.itemId}
          icon={
            <Icon
              icon={
                node.status === 'pending'
                  ? 'spinner'
                  : !node.open
                  ? 'eye-slash'
                  : 'eye'
              }
              spin={node.status === 'pending' ? true : false}
            />
          }
        />
      </ButtonGroup>
    );
  };
  generateNodeLabel = (node) => {
    return (
      <MenuProvider
        id='treeview_menu'
        animation={animation.zoom}
        data={{ node: node }}
      >
        <Text> {node.text} </Text>
      </MenuProvider>
    );
  };
  sortLabel = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };
  //#endregion
  handleClosePanel = (data, check) => {
    if (data === null) {
      let node = this.state.nodeCurrent;
      node.status = '';
      node.icon = (
        <ButtonGroup>
          <IconButton
            size='xs'
            onClick={this.handleOpenView.bind(this, node)}
            color={node.open && 'blue'}
            icon={
              <Icon
                icon={
                  node.status === 'pending'
                    ? 'spinner'
                    : !node.open
                    ? 'eye-slash'
                    : 'eye'
                }
                spin={node.status === 'pending' ? true : false}
              />
            }
          />
        </ButtonGroup>
      );
      this.setState({ openPanel: false, testData: this.state.testData });
    } else if (!check) {
      this.props.viewer.unloadModel(data.model);
      this.uploadNodeModel(data.node, true);
    } else if (check) {
      if (data.node.open) {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model
            ? this.props.viewer.impl.model.myData.globalOffset
            : null,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId,
        };
        this.props.viewer.unloadModel(this.state.modelLoaded);
        this.uploadNodeModel(data.node, false, svfUrl, loadOptions);
      } else {
        let svfUrl = this.state.document.getViewablePath(data.viewableSelected);
        let loadOptions = {
          globalOffset: this.props.viewer.impl.model
            ? this.props.viewer.impl.model.myData.globalOffset
            : null,
          applyRefPoint: true,
          modelNameOverride: data.node.text,
          isAEC: true,
          guid: data.viewableSelected.data.guid,
          viewableID: data.viewableSelected.data.viewableID,
          itemId: data.node.itemId,
          version: data.node.version,
          acmSessionId: this.state.document.acmSessionId,
        };
        this.props.viewer.loadModel(
          svfUrl,
          loadOptions,
          this._onLoadModelSuccess.bind(this, data.node, false),
          this._onLoadModelError.bind(this, data.node, false)
        );
        this.setState({
          openPanel: false,
          viewCurrent: null,
          modelLoaded: null,
          loading: false,
          testData: this.state.testData,
        });
      }
    }
  };
  handleGetTreeNode = () => {
    return getAllChildTreeNode(this.state.testData);
  };
  render() {
    return (
      <>
        <div
          id='dataentry-treemodel'
          style={{
            width: 400,
            height: '100%',
            position: 'absolute',
            background: 'white',
            zIndex: 103,
            padding: 10,
            display: this.props.open ? 'block' : 'none',
          }}
        >
          <div
            style={{ padding: '5px 0px 0px 0px', width: '100%', height: 35 }}
          >
            <Title level={4} style={{ display: 'contents' }}>
              Tree View
            </Title>
            <Icon
              icon='close'
              size='xs'
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={this.handleCloseSidebar}
            />
          </div>
          <Divider style={{ margin: '5px 0' }} />
          <div
            style={{
              padding: '0px 0px 0px 0px',
              width: '100%',
              height: 'calc(100% - 36px)',
            }}
          >
            <Scrollbars
              renderView={renderView}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}
            >
              <Tree
                ref={this.treeModel}
                contents={this.state.testData}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              />
            </Scrollbars>
          </div>
        </div>

        {this.state.openPanel && (
          <SelectViewPanel
            open={this.state.openPanel}
            close={this.handleClosePanel}
            data={this.state.data}
            viewCurrent={this.state.viewCurrent}
            viewer={this.props.viewer}
            isModelLoaded={this.state.isModelLoaded}
            nodeCurrent={this.state.nodeCurrent}
            modelLoaded={this.state.modelLoaded}
          />
        )}
      </>
    );
  }
}
SideBarPanel.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
  };
}
export default SideBarPanel;

function SelectViewPanel(props) {
  const [viewableSelected, setViewableSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.close(null);
  };
  const handleChangeView = (value, e) => {
    _.forEach(props.data, (v) => {
      if (v.value === value) {
        setViewableSelected(v.obj);
        return false;
      }
    });
  };
  const handleUnloadModel = () => {
    setLoading(true);
    let models = props.viewer.impl.modelQueue().getModels();
    let node = props.nodeCurrent;
    _.forEach(models, (v, k) => {
      if (v.myData.loadOptions.modelNameOverride === node.text) {
        props.close({ key: k, node: props.nodeCurrent, model: v }, false);
        setLoading(false);
        return false;
      }
    });
  };
  const handleViewable = () => {
    props.close(
      {
        viewableSelected: viewableSelected,
        node: props.nodeCurrent,
        model: props.modelLoaded,
      },
      true
    );
  };
  return (
    <Modal
      show={props.open}
      onHide={handleClose}
      size='xs'
      overflow={true}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>List Viewables</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectPicker
          data={props.data}
          style={{ width: '100%' }}
          defaultValue={props.viewCurrent}
          groupBy='group'
          placeholder='Select View'
          cleanable={false}
          onChange={handleChangeView}
          renderMenuItem={(label, item) => {
            return (
              <div>
                <i
                  className={
                    item.group === '3D'
                      ? 'rs-icon rs-icon-coincide'
                      : 'rs-icon rs-icon-newspaper-o'
                  }
                />{' '}
                {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                <i
                  className={
                    label === '3D'
                      ? 'rs-icon rs-icon-coincide'
                      : 'rs-icon rs-icon-newspaper-o'
                  }
                />{' '}
                {label} - ({item.children.length})
              </div>
            );
          }}
          renderValue={(value, item) => {
            return (
              <div>
                <span style={{ color: '#000000' }}>
                  <i
                    className={
                      item.group === '3D'
                        ? 'rs-icon rs-icon-coincide'
                        : 'rs-icon rs-icon-newspaper-o'
                    }
                  />
                  {item.group === '3D' ? ' View3D' : ' Sheet'} :
                </span>{' '}
                {item.label}
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {props.isModelLoaded && (
          <Button onClick={handleUnloadModel} color='red' loading={loading}>
            Unload
          </Button>
        )}
        <Button
          onClick={handleViewable}
          appearance='primary'
          loading={loading}
          disabled={viewableSelected === null}
        >
          Load
        </Button>
        <Button onClick={handleClose} appearance='subtle'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
