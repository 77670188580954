import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Note,
} from '@react-pdf/renderer';
import React, { useState, useEffect } from 'react';
import { imgLink } from '../../module_DMS/constants';
import robotoBold from '../../module_DMS/constants/Roboto-Bold.ttf';
import robotoRegular from '../../module_DMS/constants/Roboto-Regular.ttf';
import _ from 'lodash';
import moment from 'moment';

Font.register({ family: 'Roboto-Regular', fonts: [{ src: robotoRegular }] });
Font.register({ family: 'Roboto-Bold', fonts: [{ src: robotoBold }] });

const fontStyles = StyleSheet.create({
  fontRegular: { fontFamily: 'Roboto-Regular' },
  fontBold: { fontFamily: 'Roboto-Bold' },
});

const flexStyles = StyleSheet.create({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    // flex: 1,
  },
  fontBold: { fontFamily: 'Roboto-Bold' },
});

const ExportCastingReportPdf = ({ pdfContent }) => {
  const {
    note,
    projectName,
    reportNumber,
    pourName,
    engineer,
    castingDate,
    pourLocation,
    pourDescription,
    png3d,
    png2d,
    dataConcreteDO,
    concreteVolumeDesign,
  } = pdfContent;

  function getChildrenConcretes() {
    let all = [];
    let numberPerPage = 34;
    if (Array.isArray(dataConcreteDO)) {
      let dos = dataConcreteDO.map((x, index) => ({
        ...x,
        no: index + 1,
      }));

      let n = dos.length / numberPerPage;

      for (let i = 0; i < n; i++) {
        let ar = [
          ['No.', 'DO No', 'Date ', 'Product', 'Volume', 'Order By', 'Remark'],
        ];

        let data = dos
          .slice(numberPerPage * i, numberPerPage * (i + 1))
          .map((x) => [
            x.no,
            x.doNumber,
            moment(x.date).format('DD-MM-YYYY'),
            x.product,
            x.volume,
            x.orderBy,
            x.remark,
          ]);

        ar = ar.concat(data);

        all.push(ar);
      }

      let totalRow = ['', '', '', 'Total :', totalConcreteActual(), '', ''];

      if (all.length > 0) {
        all[all.length - 1].push(totalRow);
      }

      return all;
    }
    return [
      [['S. No', 'DO No', 'Date ', 'Product', 'Volume', 'Order By', 'Remark']],
    ];
  }

  function getChildrenSummary() {
    let concreteActual = totalConcreteActual();

    let deltaConcrete = _.round(
      Math.abs(concreteVolumeDesign - concreteActual),
      2
    );

    let ar = [
      ['Item', 'Design ', 'Actual', 'Wastage (m3)', 'Wastage (%)'],
      [
        'Concrete Volume (m3)',
        concreteVolumeDesign,
        concreteActual,
        deltaConcrete,
        percentDelta(deltaConcrete, concreteVolumeDesign),
      ],
    ];

    return ar;
  }

  function percentDelta(deltaConcrete, concreteVolumeDesign) {
    return _.round((deltaConcrete * 100) / concreteVolumeDesign, 2);
  }

  function totalConcreteActual() {
    if (Array.isArray(dataConcreteDO)) {
      return _.sumBy(dataConcreteDO, 'quantity');
    }
    return 0;
  }

  function getTotalPage() {
    return 1 + getChildrenConcretes().length;
  }

  return (
    <Document>
      <Page size='A4' style={{ fontSize: 9, padding: 12 }} pageNumber={1}>
        <View
          style={{
            // border: '1px solid black',
            padding: 0,
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <Text
            style={{
              fontSize: 8,
              color: 'red',
              position: 'absolute',
              bottom: 0,
            }}
          >
            This is computer auto generated report, no signature required.
          </Text>

          <Text
            style={{
              fontSize: 10,
              position: 'absolute',
              bottom: 0,
              right: 10,
            }}
          >
            Page 1/{getTotalPage()}
          </Text>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 10,
            }}
          >
            <Image
              src={imgLink.logoWohhup}
              style={{ width: 90, height: 25, marginRight: 6 }}
            />

            <Text
              style={{
                fontSize: 13,
                ...fontStyles.fontBold,
              }}
            >
              Project : {projectName}
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'column',
              paddingRight: 10,
              paddingLeft: 10,
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontSize: 13,
                ...fontStyles.fontBold,
                textDecoration: 'none',
              }}
            >
              CASTING REPORT NO: {reportNumber}
            </Text>

            <Text
              style={{
                marginTop: 10,
                fontSize: 13,
                ...fontStyles.fontRegular,
                textDecoration: 'none',
              }}
            >
              Pour name : {pourName}
            </Text>

            <Text
              style={{
                marginTop: 6,
                fontSize: 13,
                ...fontStyles.fontRegular,
                textDecoration: 'none',
              }}
            >
              Pour description : {pourDescription}
            </Text>

            <Text
              style={{
                marginTop: 6,
                fontSize: 13,
                ...fontStyles.fontRegular,
                textDecoration: 'none',
              }}
            >
              Pour location : {pourLocation}
            </Text>

            <Text
              style={{
                marginTop: 6,
                fontSize: 13,
                ...fontStyles.fontRegular,
                textDecoration: 'none',
              }}
            >
              Casting date : {castingDate}
            </Text>

            <Text
              style={{
                marginTop: 6,
                fontSize: 13,
                ...fontStyles.fontRegular,
                textDecoration: 'none',
              }}
            >
              Engineer in Charge : {engineer}
            </Text>
          </View>

          <View
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontSize: 13,
                marginBottom: 10,
                ...fontStyles.fontBold,
                textDecoration: 'none',
              }}
            >
              Summary quantity
            </Text>

            <PdfTable
              th={true}
              col={['40%', '15%', '15%', '15%', '15%']}
              children={getChildrenSummary()}
            />
          </View>

          <View
            style={{
              flexDirection: 'column',
              height: '70%',
              width: '100%',
              marginTop: 0,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                height: '100px',
                width: '100%',
              }}
            >
              <View style={{ flex: 1, border: '0px solid black' }}>
                <Text
                  style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    textAlign: 'left',
                    marginLeft: 10,
                    marginTop: 2,
                  }}
                >
                  Notes / Explanation of wastage :
                </Text>

                <Text
                  style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    textAlign: 'left',
                    padding: 6,
                  }}
                >
                  {note}
                </Text>
              </View>
              <View style={{ flex: 1, border: '0px solid black' }}>
                <Text
                  style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    textAlign: 'center',
                    color: 'red',
                  }}
                >
                  The 3D model image can view only after report is generated.
                </Text>
                <Image
                  src={`data:image/png;base64,${png3d}`}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />

                <Text
                  style={{
                    fontSize: 13,
                    textDecoration: 'none',
                    textAlign: 'center',
                  }}
                >
                  Status 3d isolated
                </Text>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  marginTop:20,
                  fontSize: 13,
                  textDecoration: 'none',
                  textAlign: 'center',
                  color: 'red',
                }}
              >
                Status layout can view only after report is generated.
              </Text>
              <Image
                src={`data:image/png;base64,${png2d}`}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />

              <Text
                style={{
                  fontSize: 13,
                  textDecoration: 'none',
                  textAlign: 'center',
                }}
              >
                Status 2d
              </Text>
            </View>
          </View>
        </View>
      </Page>

      {getChildrenConcretes().map((children, index) => (
        <Page size='A4' style={{ fontSize: 9, padding: 12 }}>
          <View
            style={{
              // border: '1px solid black',
              padding: 0,
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            <Text
              style={{
                fontSize: 8,
                color: 'red',
                position: 'absolute',
                bottom: 0,
              }}
            >
              This is computer auto generated report, no signature required.
            </Text>

            <Text
              style={{
                fontSize: 10,
                position: 'absolute',
                bottom: 0,
                right: 10,
              }}
            >
              Page {index + 2}/{getTotalPage()}
            </Text>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 10,
                paddingLeft: 10,
                paddingTop: 10,
              }}
            >
              <Image
                src={imgLink.logoWohhup}
                style={{ width: 90, height: 25, marginRight: 6 }}
              />

              <Text
                style={{
                  fontSize: 13,
                  ...fontStyles.fontBold,
                  textDecoration: 'underline',
                }}
              >
                Project : {projectName}
              </Text>
            </View>

            <View
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  ...fontStyles.fontBold,
                  textDecoration: 'none',
                }}
              >
                CASTING REPORT NO: {reportNumber}
              </Text>
              <Text
                style={{
                  fontSize: 13,
                  marginBottom: 10,
                  ...fontStyles.fontBold,
                  textDecoration: 'none',
                  textAlign: 'center',
                }}
              >
                Concrete Delivery Order Information
              </Text>

              <PdfTable
                th={true}
                col={['6%', '15%', '15%', '29%', '10%', '15%', '15%']}
                children={children}
              />
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ExportCastingReportPdf;

const stylesTable = StyleSheet.create({
  em: {
    fontStyle: 'bold',
  },
  table: {
    width: '100%',
    borderLeft: '1px solid black',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 2,
    borderCollapse: 'collapse',
    fontSize: '10px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
});

const PdfTable = ({ children, col, th }) => {
  return (
    <View style={stylesTable.table}>
      {children.map((row, ind) => {
        return (
          <View
            key={ind}
            style={[
              stylesTable.tableRow,
              th && ind === 0 ? stylesTable.em : {},
              { fontWeight: 'bold' },
              stylesTable.em,
            ]}
          >
            {row.map((cell, j) => {
              return (
                <View
                  key={j}
                  style={[
                    stylesTable.cell,
                    {
                      width: col[j],
                      height: 20,
                      padding: 1,
                      paddingLeft: 5,
                      justifyContent: 'center',
                      backgroundColor: ind === 0 ? '#E6F7FF' : '',
                    },
                  ]}
                >
                  {typeof cell === 'number' ? (
                    <Text
                      wrap={true}
                      style={{ textAlign: 'right', fontWeight: 'bold' }}
                    >
                      {cell}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontSize: 10,
                        fontWeight: 'bold',
                      }}
                      wrap={true}
                    >
                      {cell}
                    </Text>
                  )}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};
