import { Icon, Input, message, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';




const PanelAddAuthorities = ({ stateProject, onClickCancelModal, onClickApply }) => {

   const { allDataOneSheet: { publicSettings } } = stateProject;

   const [currentArray, setCurrentArray] = useState(publicSettings.authoritiesArray || []);
   const [authorityName, setAuthorityName] = useState('');


   const addThisAuthority = () => {
      if (!authorityName) {
         return message.warn('Please key-in authority name!');
      };
      
      const findSameName = currentArray.find(x => {
         let nameToCreate = authorityName.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '');
         let nameX = x.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '');
         return nameToCreate === nameX;
      });

      if (findSameName) {
         return message.warn('This authority name has already existed, please choose a new one!');
      };

      const newNameToSave = authorityName.replace(/[^a-zA-Z ]/g, '').toUpperCase();

      setCurrentArray([newNameToSave, ...currentArray]);
      setAuthorityName('');
   };


   const removeThisAuthority = (item) => {
      if (currentArray.indexOf(item) !== -1) {
         setCurrentArray(currentArray.filter(x => x !== item));
      };
   };


   return (
      <div style={{ padding: 10, color: 'black' }}>
         <div style={{ display: 'flex', marginBottom: 15 }}>

            <InputStyled
               style={{ width: 200, marginRight: 10 }}
               onChange={(e) => setAuthorityName(e.target.value)}
               value={authorityName}
            />
            <ButtonStyle
               marginRight={5}
               name='Add Authority'
               onClick={addThisAuthority}
            />

         </div>

         <div style={{
            width: '100%', marginBottom: 15, borderBottom: `1px solid ${colorType.grey4}`,
            overflowY: currentArray && 'auto', whiteSpace: 'pre-wrap', height: window.innerHeight * 0.5,
         }}>
            {currentArray ? (
               <>
                  {currentArray.map(item => (
                     <div key={item} style={{ display: 'flex', marginBottom: 6 }}>

                        <Tooltip title='Remove This Authority'>
                           <IconStyled>
                              <Icon
                                 type='delete'
                                 style={{ transform: 'translate(0, -6px)', color: colorType.grey2, fontSize: 12 }}
                                 onClick={() => removeThisAuthority(item)}
                              />
                           </IconStyled>
                        </Tooltip>
                        <div style={{ marginLeft: 8 }}>{item}</div>
                     </div>
                  ))}
               </>
            ) : (
               <div style={{ height: window.innerHeight * 0.5 }}></div>
            )}

         </div>


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => onClickApply({ authoritiesArray: currentArray.sort() })}
               newTextBtnApply={'Save'}
            />
         </div>

      </div>

   );
};

export default PanelAddAuthorities;



const InputStyled = styled(Input)`
   color: black;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;

const IconStyled = styled.div`
   margin-left: 7px;
   width: 18px; 
   height: 18px; 
   border: 1px solid ${colorType.grey1};
   text-align: center;
   &:hover {
      background-color: ${colorType.grey4};
      cursor: pointer;
   };
`;