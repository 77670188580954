import React from 'react';
import { message, Modal, Row, Col, Input as AntInput, Typography } from 'antd';

import _ from 'lodash';
import axios from 'axios';
import {
  Input,
  IconButton,
  Checkbox,
  InputPicker,
  Icon,
  CheckPicker,
} from 'rsuite';
import { DraggableAreasGroup } from 'react-draggable-tags';

import { roles } from '../../module_BimApp/columns';

const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { TextArea } = AntInput;
const { Text } = Typography;

export default class DialogSettingColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      columnName: '',
      columnType: '',
      roleCanView: [],
      roleCanEdit: [],
      openSetting: props.open,
      valueArray: '',
      item: null,
      loading: false,
      indeterminate: false,
      checkAll: false,
    };
  }

  componentDidMount = () => {
    let temp = Object.assign({}, this.props.sheet);
    this.setState({ columns: temp.columns ? temp.columns.slice(0) : [] });
  };
  handleClose = () => {
    this.props.close();
  };
  handleChangeColumnName = (value) => {
    this.setState({ columnName: value });
  };
  handleChangeColumnType = (value) => {
    this.setState({ columnType: value });
  };
  handleChangeRoleCanView = (value) => {
    this.setState({ roleCanView: value });
  };
  handleChangeRoleCanEdit = (value) => {
    this.setState({ roleCanEdit: value });
  };
  handleAddColumn = () => {
    if (!this.state.columnName || !this.state.columnType) {
      message.warning(`Please, key in all field`);
      return;
    }
    let temp = this.state.columns;
    let index = _.findIndex(temp, (v) => {
      return v.id === this.state.columnName;
    });
    if (index >= 0) {
      message.warning('Column name is existing');
    } else {
      temp.push({
        id: this.state.columnName,
        name: this.state.columnName,
        type: this.state.columnType,
        disabled: true,
        roleCanEdit: this.state.roleCanEdit,
        roleCanView: this.state.roleCanView,
        valueArray: [],
      });
      this.setState({
        columns: temp,
        columnName: '',
        columnType: '',
        roleCanEdit: [],
        roleCanView: [],
      });
    }
  };
  handleEditColumn = (item) => {
    let temp = this.state.columns;
    item.disabled = !item.disabled;
    this.setState({ columns: temp });
  };
  handleDeleteColumn = (item) => {
    if (!window.confirm('Are you want to delete it')) return;
    let temp = this.state.columns;
    let index = _.findIndex(temp, (v) => {
      return v.name === item.name;
    });
    if (index >= 0) {
      temp.splice(index, 1);
      this.setState({ columns: temp });
    }
  };
  handleEditColumnName = (item, value) => {
    let temp = this.state.columns;
    item.name = value;
    this.setState({ columns: temp });
  };
  handleEditColumnParameter = (item, value) => {
    let temp = this.state.columns;
    item.parameter = value;
    this.setState({ columns: temp });
  };
  handleEditColumnWidth = (item, value) => {
    let temp = this.state.columns;
    item.width = value;
    this.setState({ columns: temp });
  };
  handleEditColumnIsTotal = (item) => {
    let temp = this.state.columns;
    item.isTotal = !item.isTotal;
    this.setState({ columns: temp });
  };
  handleEditColumnType = (item, value) => {
    let temp = this.state.columns;
    item.type = value;
    this.setState({ columns: temp });
  };
  handleEditRoleCanView = (item, value) => {
    let temp = this.state.columns;
    item.roleCanView = value;
    this.setState({
      columns: temp,
      indeterminate:
        item['roleCanView'].length > 0 &&
        item['roleCanView'].length < roles.length,
      checkAll: item['roleCanView'].length === roles.length,
    });
  };
  handleEditRoleCanEdit = (item, value) => {
    let temp = this.state.columns;
    item.roleCanEdit = value;
    this.setState({
      columns: temp,
      indeterminate:
        item['roleCanEdit'].length > 0 &&
        item['roleCanEdit'].length < roles.length,
      checkAll: item['roleCanEdit'].length === roles.length,
    });
  };
  handleOpenDropDown = (tag) => {
    let value = tag.valueArray ? tag.valueArray.join('\n') : '';
    this.setState({ openDropDown: true, item: tag, valueArray: value });
  };
  handleChangeTextArea = (e) => {
    this.setState({ valueArray: e.target.value });
  };
  handleChangeDropDownValue = () => {
    let data = this.state.valueArray.split(/\n/);
    let temp = [];
    _.forEach(data, (v) => {
      if (!temp.includes(v) && v !== '') temp.push(v);
    });
    this.state.item.valueArray = temp;
    this.setState({ openDropDown: false, valueArray: '' });
  };
  handleOk = () => {
    this.setState({ loading: true });
    let temp = Object.assign({}, this.props.sheet);
    temp.columns = this.state.columns;
    axios
      .post(`/api/dataentry/edit-project-sheet`, {
        projectId: this.props.projectId,
        sheet: temp,
        token: this.props.token,
      })
      .then(() => {
        this.props.sheet.columns = this.state.columns;
        this.props.close();
      })
      .catch(() => {
        message.warning(`Can't save sheet`);
        this.setState({ loading: false });
      });
  };
  handleMatchingParameter = (item) => {
    let temp = this.state.columns;
    item.isMatching = !item.isMatching;
    this.setState({ columns: temp });
  };
  handleCheckAll = (tag, role, value, checked) => {
    const nextValue = checked ? roles : [];
    let temp = this.state.columns;
    let tempRoles = [];
    _.forEach(nextValue, (v) => {
      tempRoles.push(v.value);
    });
    tag[role] = tempRoles;
    this.setState({ columns: temp, checkAll: checked, indeterminate: false });
  };
  handleOpenCheckbox = (tag, role) => {
    this.setState({
      indeterminate: tag[role].length > 0 && tag[role].length < roles.length,
      checkAll: tag[role].length === roles.length,
    });
  };
  render() {
    return (
      <>
        <Modal
          title={'Setting Column'}
          visible={this.state.openSetting}
          onCancel={this.handleClose}
          onOk={this.handleOk}
          width={'95%'}
          confirmLoading={this.state.loading}
        >
          <Row gutter={[8, 8]}>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong>Name</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong> Column Type</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong> Parameter</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={3}>
              <Text strong> Role can view</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={3}>
              <Text strong>Role can edit</Text>
            </Col>
            <Col style={{ textAlign: 'center' }} span={2}>
              <Text strong>Width</Text>
            </Col>
            {/* <Col style={{ textAlign: 'center' }} span={1}>
                <Text strong>isTotal</Text>
              </Col> */}
            <Col style={{ textAlign: 'center' }} span={4}>
              <Text strong>Options</Text>
            </Col>
          </Row>
          {this.props.isAdmin ? (
            <div className='List'>
              <div className='main-drag'>
                <DraggableArea
                  onChange={(columns) => this.setState({ columns })}
                  isList
                  tags={this.state.columns}
                  render={({ tag }) => (
                    <div className='row'>
                      <Col span={4}>
                        <Input
                          block
                          value={tag.name}
                          appearance='default'
                          size='sm'
                          onChange={this.handleEditColumnName.bind(this, tag)}
                          disabled={true}
                        />
                      </Col>
                      <Col span={4}>
                        <InputPicker
                          block
                          placeholder='New Contract Name'
                          appearance='default'
                          size='sm'
                          disabled={this.props.isAdmin ? false : tag.disabled}
                          cleanable={false}
                          value={tag.type}
                          onChange={this.handleEditColumnType.bind(this, tag)}
                          data={[
                            { key: 'Text', label: 'Text', value: 'text' },
                            {
                              key: 'Number',
                              label: 'Number',
                              value: 'numeric',
                            },
                            { key: 'Date', label: 'Date', value: 'date' },
                            {
                              key: 'CheckBox',
                              label: 'CheckBox',
                              value: 'checkbox',
                            },
                            {
                              key: 'DropDown',
                              label: 'DropDown',
                              value: 'dropdown',
                            },
                          ]}
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          block
                          value={tag.parameter}
                          appearance='default'
                          size='sm'
                          placeholder='Parameter'
                          onChange={this.handleEditColumnParameter.bind(
                            this,
                            tag
                          )}
                          disabled={tag.disabled}
                        />
                      </Col>
                      <Col span={3}>
                        <CheckPicker
                          block
                          placeholder='Role Can View'
                          appearance='default'
                          size='sm'
                          value={tag.roleCanView}
                          onEnter={this.handleOpenCheckbox.bind(
                            this,
                            tag,
                            'roleCanView'
                          )}
                          disabled={tag.disabled}
                          cleanable={false}
                          onChange={this.handleEditRoleCanView.bind(this, tag)}
                          data={roles}
                          renderExtraFooter={() => (
                            <div
                              style={{
                                padding: '10px 2px',
                                borderTop: '1px solid #e5e5e5',
                              }}
                            >
                              <Checkbox
                                inline
                                indeterminate={this.state.indeterminate}
                                checked={this.state.checkAll}
                                onChange={this.handleCheckAll.bind(
                                  this,
                                  tag,
                                  'roleCanView'
                                )}
                                style={{ color: 'blue' }}
                              >
                                Check all
                              </Checkbox>
                            </div>
                          )}
                        />
                      </Col>
                      <Col span={3}>
                        <CheckPicker
                          block
                          placeholder='Role Can Edit'
                          appearance='default'
                          size='sm'
                          value={tag.roleCanEdit}
                          onEnter={this.handleOpenCheckbox.bind(
                            this,
                            tag,
                            'roleCanEdit'
                          )}
                          disabled={tag.disabled}
                          cleanable={false}
                          onChange={this.handleEditRoleCanEdit.bind(this, tag)}
                          data={roles}
                          renderExtraFooter={() => (
                            <div
                              style={{
                                padding: '10px 2px',
                                borderTop: '1px solid #e5e5e5',
                              }}
                            >
                              <Checkbox
                                inline
                                indeterminate={this.state.indeterminate}
                                checked={this.state.checkAll}
                                onChange={this.handleCheckAll.bind(
                                  this,
                                  tag,
                                  'roleCanEdit'
                                )}
                                style={{ color: 'blue' }}
                              >
                                Check all
                              </Checkbox>
                            </div>
                          )}
                        />
                      </Col>
                      <Col span={2}>
                        <Input
                          block
                          value={tag.width}
                          appearance='default'
                          size='sm'
                          placeholder='Width'
                          onChange={this.handleEditColumnWidth.bind(this, tag)}
                          disabled={tag.disabled}
                        />
                      </Col>
                      {/* <Col span={1}>
                      <Checkbox size="sm" onChange={this.handleEditColumnIsTotal.bind(this, tag)} checked={tag.isTotal}
                        disabled={tag.disabled} />
                    </Col> */}
                      <Col span={1}>
                        {tag.type === 'dropdown' && (
                          <IconButton
                            size='sm'
                            onClick={this.handleOpenDropDown.bind(this, tag)}
                            color={
                              tag.valueArray &&
                              tag.valueArray.length !== 0 &&
                              'blue'
                            }
                            disabled={tag.disabled || tag.type !== 'dropdown'}
                            icon={<Icon icon='task' />}
                          />
                        )}
                      </Col>
                      <Col span={1}>
                        <Checkbox
                          size='sm'
                          onChange={this.handleMatchingParameter.bind(
                            this,
                            tag
                          )}
                          checked={tag.isMatching}
                          disabled={tag.disabled}
                        />
                      </Col>
                      <Col span={1}>
                        <IconButton
                          size='sm'
                          onClick={this.handleEditColumn.bind(this, tag)}
                          color={!tag.disabled && 'blue'}
                          icon={
                            <Icon
                              icon={`${!tag.disabled ? 'check' : 'edit'}`}
                            />
                          }
                        />
                      </Col>

                      <Col span={1}>
                        <IconButton
                          size='sm'
                          onClick={this.handleDeleteColumn.bind(this, tag)}
                          color={'red'}
                          icon={<Icon icon='trash' />}
                        />
                      </Col>
                    </div>
                  )}
                ></DraggableArea>
              </div>
            </div>
          ) : (
            this.state.columns.map((tag) => (
              <Row gutter={[8]}>
                <Col span={4}>
                  <Input
                    block
                    value={tag.name}
                    appearance='default'
                    size='sm'
                    onChange={this.handleEditColumnName.bind(this, tag)}
                    disabled={true}
                  />
                </Col>
                <Col span={4}>
                  <InputPicker
                    block
                    placeholder='New Contract Name'
                    appearance='default'
                    size='sm'
                    disabled={this.props.isAdmin ? false : tag.disabled}
                    cleanable={false}
                    value={tag.type}
                    onChange={this.handleEditColumnType.bind(this, tag)}
                    data={[
                      { key: 'Text', label: 'Text', value: 'text' },
                      { key: 'Number', label: 'Number', value: 'numeric' },
                      { key: 'Date', label: 'Date', value: 'date' },
                      { key: 'CheckBox', label: 'CheckBox', value: 'checkbox' },
                      { key: 'DropDown', label: 'DropDown', value: 'dropdown' },
                    ]}
                  />
                </Col>
                <Col span={4}>
                  <Input
                    block
                    value={tag.parameter}
                    appearance='default'
                    size='sm'
                    placeholder='Parameter'
                    onChange={this.handleEditColumnParameter.bind(this, tag)}
                    disabled={tag.disabled}
                  />
                </Col>
                <Col span={3}>
                  <CheckPicker
                    block
                    placeholder='Role Can View'
                    appearance='default'
                    size='sm'
                    value={tag.roleCanView}
                    onEnter={this.handleOpenCheckbox.bind(
                      this,
                      tag,
                      'roleCanView'
                    )}
                    disabled={tag.disabled}
                    cleanable={false}
                    onChange={this.handleEditRoleCanView.bind(this, tag)}
                    data={roles}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          padding: '10px 2px',
                          borderTop: '1px solid #e5e5e5',
                        }}
                      >
                        <Checkbox
                          inline
                          indeterminate={this.state.indeterminate}
                          checked={this.state.checkAll}
                          onChange={this.handleCheckAll.bind(
                            this,
                            tag,
                            'roleCanView'
                          )}
                          style={{ color: 'blue' }}
                        >
                          Check all
                        </Checkbox>
                      </div>
                    )}
                  />
                </Col>
                <Col span={3}>
                  <CheckPicker
                    block
                    placeholder='Role Can Edit'
                    appearance='default'
                    size='sm'
                    value={tag.roleCanEdit}
                    onEnter={this.handleOpenCheckbox.bind(
                      this,
                      tag,
                      'roleCanEdit'
                    )}
                    disabled={tag.disabled}
                    cleanable={false}
                    onChange={this.handleEditRoleCanEdit.bind(this, tag)}
                    data={roles}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          padding: '10px 2px',
                          borderTop: '1px solid #e5e5e5',
                        }}
                      >
                        <Checkbox
                          inline
                          indeterminate={this.state.indeterminate}
                          checked={this.state.checkAll}
                          onChange={this.handleCheckAll.bind(
                            this,
                            tag,
                            'roleCanEdit'
                          )}
                          style={{ color: 'blue' }}
                        >
                          Check all
                        </Checkbox>
                      </div>
                    )}
                  />
                </Col>
                <Col span={2}>
                  <Input
                    block
                    value={tag.width}
                    appearance='default'
                    size='sm'
                    placeholder='Width'
                    onChange={this.handleEditColumnWidth.bind(this, tag)}
                    disabled={tag.disabled}
                  />
                </Col>
                {/* <Col span={1}>
                  <Checkbox size="sm" onChange={this.handleEditColumnIsTotal.bind(this, tag)} checked={tag.isTotal}
                    disabled={tag.disabled} />
                </Col> */}
                <Col span={1}>
                  {tag.type === 'dropdown' && (
                    <IconButton
                      size='sm'
                      onClick={this.handleOpenDropDown.bind(this, tag)}
                      color={
                        tag.valueArray && tag.valueArray.length !== 0 && 'blue'
                      }
                      disabled={tag.disabled || tag.type !== 'dropdown'}
                      icon={<Icon icon='task' />}
                    />
                  )}
                </Col>
                <Col span={1}>
                  <Checkbox
                    size='sm'
                    onChange={this.handleMatchingParameter.bind(this, tag)}
                    checked={tag.isMatching}
                    disabled={tag.disabled}
                  />
                </Col>
                <Col span={1}>
                  <IconButton
                    size='sm'
                    onClick={this.handleEditColumn.bind(this, tag)}
                    color={!tag.disabled && 'blue'}
                    icon={<Icon icon={`${!tag.disabled ? 'check' : 'edit'}`} />}
                  />
                </Col>

                <Col span={1}>
                  <IconButton
                    size='sm'
                    onClick={this.handleDeleteColumn.bind(this, tag)}
                    color={'red'}
                    icon={<Icon icon='trash' />}
                  />
                </Col>
              </Row>
            ))
          )}

          {/* <div className="List">
             <div className="main-drag">
                <DraggableArea
                  isList
                  tags={this.state.columns}
                  render={({ tag }) => (
                    <div className='row' >
              
                    </div>
                  )}
                // onChange={(columns) => this.setState({ columns })}
                />
              </div>
            </div> */}
        </Modal>

        <Modal
          title={'Setting Column'}
          visible={this.state.openDropDown}
          onCancel={() => {
            this.setState({ openDropDown: false, valueArray: '' });
          }}
          onOk={this.handleChangeDropDownValue}
          width={'50%'}
          confirmLoading={false}
        >
          <TextArea
            placeholder='Key in value for dropdown'
            value={this.state.valueArray}
            autoSize={{ minRows: 2, maxRows: 100 }}
            onChange={this.handleChangeTextArea}
          />
        </Modal>
      </>
    );
  }
}
