import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Upload, Icon } from 'antd'
import _ from 'lodash';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { setPDFData } from '../../../actions/app/pdf'
const ButtonGroup = Button.Group;


const ImageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"   width="14" height="14"><path class="cls-1" d="M19.89,2H4.33A2.23,2.23,0,0,0,2.11,4.22V19.78A2.23,2.23,0,0,0,4.33,22H19.89a2.22,2.22,0,0,0,2.22-2.22V4.22A2.22,2.22,0,0,0,19.89,2ZM4.33,19.78V4.22H19.89V19.78Z"></path><polygon class="cls-1" points="13.94 8.13 18.78 15.87 11.52 15.87 9.1 15.87 5.45 15.87 8.48 11.43 10.26 14.02 13.94 8.13"></polygon></svg>
)
function InsertBar(props) {

    const handleFile = (info) => {
        if (info.file !== null) {
            if (info.file.percent < 101) {
                return;
            }
            if (info.file.percent === undefined) {
                const blob = new Blob([info.file],{type:info.file.type })
                props.setPDFData({ image: URL.createObjectURL(blob), feature: props.pdfViewer.feature !== 'stamp' ? 'stamp' : '' })
                // var reader = new FileReader();
                // reader.onload = async function (e) {
                //     var data = e.target.result;
                //     const blob = new Blob([new Uint8Array(data)], {type: info.file.type });
                //     props.setPDFData({ image: URL.createObjectURL(blob), feature: props.pdfViewer.feature !== 'stamp' ? 'stamp' : '' })
                // };
                // reader.readAsText(info.file);
            }

        }


    }
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    return (
        <>
            {props.pdfViewer.feature === 'stamp' ?
                <Button size='small' className={`idd-app-pdf-feature feature active`} onClick={() => {
                    props.setPDFData({ image: null, feature: '' })
                }} >
                       <Icon component={
                    ImageIcon
                } />
                </Button>
                :
                <Upload multiple={false}
                    showUploadList={false} beforeUpload={beforeUpload} accept='.jpg,.png'
                    customRequest={handleFile}>
                    <Button size='small'  className={`idd-app-pdf-feature feature`} >
                    <Icon component={
                    ImageIcon
                } />
                    </Button>
                </Upload>
            }



        </>
    )
}

InsertBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(InsertBar)