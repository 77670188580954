import React, { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import { constants } from '../constants'
import _ from 'lodash'
const Header = memo((props) => {
  const refButton = useRef(null);
  const [value, setValue] = useState('')
  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };
  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={() => onMenuClicked()}
      >
        <i className={`fa fa-bars`}></i>
      </div>
    );
  }
  useEffect(() => {
    let name = props.displayName
    if (props.typeTimeline === 'month') {
      name = moment(props.displayName, constants.monthFormat).format(constants.month)
    } else if (props.typeTimeline === 'week') {
      let week = props.displayName.split(' ')
      let parse = moment(week[1], constants.month)
      let firstWeek = parse.clone().startOf('month').week()
      //  let  weekNumber= moment(props.displayName, constants.weekFormat).week()
      // name = _.toNumber(week[0]) - firstWeek+1
       name = props.displayName.match(/\d+/g)[0];
     
      // if(name ===-47){
      //   console.log('a')
      // }

    } else if (props.typeTimeline === 'day') {
      let dae = moment(props.displayName, constants.dayFormat)
      name = dae.format(constants.dayMonth)
    }
    setValue(name)
          // setValue( props.displayName)
  }, [props.displayName])

  return (
    <>


      <span style={{ width: 'inherit', textAlignLast: 'center' }}>

        {value !== '' && value}
      </span>
      {/* {menu} */}
    </>

  );
});


Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, null)(Header);