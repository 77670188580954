import axios from "./axiosClient";

async function searchWorker(payload) {
  return await axios.get("/api/worker/employee/worker", { params: payload });
}

async function searchSupervisor(payload) {
  return await axios.get("/api/worker/employee/supervisor", {
    params: payload,
  });
}

async function deletes(payload) {
  return await axios.delete("/api/worker/employee", { data: { data: payload } });
}

async function add(payload) {
  return await axios.post("/api/worker/employee", payload);
}

async function addMulti(payload) {
  return await axios.post("/api/worker/employee/add-multi", payload);
}

async function update(id, payload) {
  return await axios.put(`/api/worker/employee/${id}`, payload);
}

async function updateStatus(id, payload) {
  return await axios.put(`/api/worker/employee/${id}/status`, payload);
}

async function addAvatar(payload) {
  let form_data = new FormData();

  for (let key in payload.data) {
    form_data.append(key, payload.data[key]);
  }
  if (payload.file !== null) {
    form_data.append("file", payload.file);
  }

  return await axios.post("/api/helper/set-avatar-worker", form_data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

async function getAvatar(payload) {
  return await axios.get("/api/helper/get-avatar-worker", { params: payload });
}

export {
  searchWorker,
  searchSupervisor,
  deletes,
  add,
  update,
  updateStatus,
  addMulti,
  addAvatar,
  getAvatar,
};
