export const roles = [
    { key: 'WH Archi Manager', label: 'WH Archi Manager', value: 'WH Archi Manager' },
    { key: 'WH Archi Coordinator', label: 'WH Archi Coordinator', value: 'WH Archi Coordinator' },
    { key: 'WH Archi Modeller', label: 'WH Archi Modeller', value: 'WH Archi Modeller' },
    { key: 'WH C&S Manager', label: 'WH C&S Manager', value: 'WH C&S Manager' },
    { key: 'WH C&S Design Engineer', label: 'WH C&S Design Engineer', value: 'WH C&S Design Engineer' },
    { key: 'WH C&S Modeller', label: 'WH C&S Modeller', value: 'WH C&S Modeller' },
    { key: 'WH M&E Manager', label: 'WH M&E Manager', value: 'WH M&E Manager' },
    { key: 'WH M&E Coordinator', label: 'WH M&E Coordinator', value: 'WH M&E Coordinator' },
    { key: 'WH M&E Modeller', label: 'WH M&E Modeller', value: 'WH M&E Modeller' },
    { key: 'WH PRECAST Manager', label: 'WH PRECAST Manager', value: 'WH PRECAST Manager' },
    { key: 'WH PRECAST Coordinator', label: 'WH PRECAST Coordinator', value: 'WH PRECAST Coordinator' },
    { key: 'WH PRECAST Modeller', label: 'WH PRECAST Modeller', value: 'WH PRECAST Modeller' },
    { key: 'Document Controller', label: 'Document Controller', value: 'Document Controller' },
    { key: 'Planning Engineer', label: 'Planning Engineer', value: 'Planning Engineer' },
    { key: 'QS', label: 'QS', value: 'QS' },
    { key: 'QAQC', label: 'QAQC', value: 'QAQC' },
    { key: 'Safety', label: 'Safety', value: 'Safety' },
    { key: 'Production', label: 'Production', value: 'Production' },
    { key: 'Project Manager', label: 'Project Manager', value: 'Project Manager' },
    { key: 'Corporate Manager', label: 'Corporate Manager', value: 'Corporate Manager' },
    { key: 'Sub-Con', label: 'Sub-Con', value: 'Sub-Con' },
    { key: 'Consultant', label: 'Consultant', value: 'Consultant' },
    { key: 'Client', label: 'Client', value: 'Client' },
    { key: 'Outsource', label: 'Outsource', value: 'Outsource' },
    { key: 'Site Admin', label: 'Site Admin', value: 'Site Admin' },
    { key: 'RE/RTO', label: 'RE/RTO', value: 'RE/RTO' },
    { key: 'Corporate Director', label: 'Corporate Director', value: 'Corporate Director' },
  ]
  export const rolesExternal = [
    // { key: 'Document Controller', label: 'Document Controller', value: 'Document Controller' },
    { key: 'Sub-Con', label: 'Sub-Con', value: 'Sub-Con' },
    { key: 'Consultant', label: 'Consultant', value: 'Consultant' },
    { key: 'Client', label: 'Client', value: 'Client' },
    { key: 'Outsource', label: 'Outsource', value: 'Outsource' }
  ]
  