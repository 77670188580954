import { Icon, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import styled from 'styled-components';
import { apiFetchDataProjectByDiscipline } from '../../../api';
import { colorType } from '../../../constants';
import { getHeaderWidth } from '../../../utils';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import { convertFlattenArraytoTree1 } from './FormDrawingTypeOrder';
import { checkIfDrawingRepliedYet } from './PanelAddNewRFA';


const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const TableDrawingRFA = ({
   onClickCancelModalPickDrawing, onClickApplyModalPickDrawing,
   stateProject, stateRow,
   dwgsToRemoveFromSubmittedRfa, dwgsToAddNewRFA,
}) => {

   const dwgsToAddNewOrRemoveFromRfa = [
      ...(dwgsToAddNewRFA || []),
      ...(dwgsToRemoveFromSubmittedRfa || [])
   ];
   
   const { publicSettings, token, projectId, email } = stateProject.allDataOneSheet;
   const { headers } = publicSettings;


   const [rowsTableInput, setRowsTableInput] = useState(null);


   useEffect(() => {

      const fetchData = async () => {
         try {

            let data;
            if (!window.dms_tempDmsDataRfaSubmission) {
               let res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isToFetchAllData: true }); // TEST_APPY_TAB_VIEW
               window.dms_tempDmsDataRfaSubmission = res.data;
               data = res.data;
            } else {
               data = window.dms_tempDmsDataRfaSubmission;
            };

            const { dataDmsFetched: { rows, publicSettings: { drawingTypeTree: drawingTypeTreeDmsView } } } = data;

            let rowsList = rows.filter(r => {
               return r['Drawing Number'] && !r.rfaNumber &&
                  !dwgsToAddNewOrRemoveFromRfa.find(x => x.id === r.id); // add more => prevent duplicate
            });

            const allRowsLeft = [...dwgsToAddNewOrRemoveFromRfa, ...rowsList];

            let dataOutput = [];
            drawingTypeTreeDmsView.forEach(item => {
               let newItem = { ...item };
               const rowChildren = allRowsLeft.filter(row => row._parentRow === newItem.id);
               if (rowChildren.length > 0) {
                  newItem.children = rowChildren;
               };
               dataOutput.push(newItem);
            });

            setRowsTableInput(convertFlattenArraytoTree1(dataOutput));

            setExpandedRows(drawingTypeTreeDmsView.map(x => x.id));

         } catch (err) {
            console.log('ERROR...', err);
         };
      };
      fetchData();
   }, []);

   useEffect(() => {





   }, []);


   const [selectedIdRows, setSelectedIdRows] = useState(
      dwgsToAddNewOrRemoveFromRfa
         .filter(x => !dwgsToRemoveFromSubmittedRfa.find(row => row.id === x.id)) // in case DELETE drawing from submitted Rfa
         .map(x => x.id)
   );


   const generateColumnsRFA = (headers) => {
      return [
         {
            key: 'index',
            dataKey: 'index',
            title: '',
            width: 50,
            cellRenderer: ({ rowIndex }) => <div>{rowIndex + 1}</div>
         },
         ...headers.map(column => ({
            key: column,
            dataKey: column,
            title: column,
            resizable: true,
            width: getHeaderWidth(column),
            cellRenderer: (props) => {
               const { rowData } = props;
               return (
                  <div style={{ color: 'black', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                     {(rowData['treeLevel'] && column === 'Drawing Number') ? rowData['title'] : rowData[column]}
                  </div>
               );
            }
         }))
      ];
   };
   const rowClassName = ({ rowData }) => {
      if (selectedIdRows.indexOf(rowData.id) !== -1) {
         return 'row-selected-rfa';
      } else if (rowData['treeLevel']) {
         return 'row-tree-parent';
      };
   };


   const rowEventHandlers = {
      onClick: (props) => {
         const { rowKey, rowData } = props;

         if (!rowData['treeLevel']) {
            if (checkIfDrawingRepliedYet(rowData)) {
               return message.warn('You can not remove this drawing from Rfa as it has been replied by consultant!', 2);
            };
            if (selectedIdRows.indexOf(rowKey) === -1) {
               setSelectedIdRows([...selectedIdRows, rowKey]);
            } else {
               setSelectedIdRows(selectedIdRows.filter(id => id !== rowKey));
            };
         };
      },
   };

   const ExpandIcon = (props, i) => {
      const { expanding, expandable, onExpand, depth } = props;
      const indent = (depth * 17).toString() + 'px';

      return (
         <div
            style={{
               marginLeft: indent, paddingLeft: expandable ? 10 : 13 + 10,
               paddingRight: 3, background: 'transparent',
            }}
         >
            {expandable && (
               <Icon
                  type={expanding ? 'plus-square' : 'minus-square'}
                  style={{ color: 'black', transform: 'translate(0, -4px)' }}
                  onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     onExpand(expanding);
                  }}
               />
            )}
         </div>
      );
   };


   // const [expandedRows, setExpandedRows] = useState(drawingTypeTreeDmsView.map(x => x.id));
   const [expandedRows, setExpandedRows] = useState([]);

   const onRowExpand = (props) => {
      const { rowKey, expanded } = props;
      let arr = [...expandedRows];
      if (expanded) {
         arr.push(rowKey);
      } else {
         arr = arr.filter(id => id !== rowKey);
      };
      setExpandedRows(arr);
   };

   return rowsTableInput ? (
      <div style={{
         width: '100%',
         height: window.innerHeight * 0.85 - 20,
         margin: '0 auto',
         padding: 10,
         textAlign: 'center',
      }}>

         <div style={{ width: '100%', height: window.innerHeight * 0.8 - 100 }}>
            <TableStyled
               fixed
               columns={generateColumnsRFA(headers.map(hd => hd.text))}
               data={rowsTableInput}
               rowHeight={28}
               rowEventHandlers={rowEventHandlers}
               rowClassName={rowClassName}
               expandColumnKey={'Drawing Number'}
               expandedRowKeys={expandedRows}
               onRowExpand={onRowExpand}
               expandIconProps={(props) => {
                  const { expanded } = props;
                  return ({ expanding: !expanded });
               }}
               components={{ ExpandIcon }}
            />
         </div>
         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModalPickDrawing}
               onClickApply={() => {
                  if (selectedIdRows.length === 0) {
                     return message.info('Please select drawings to submit!', 2);
                  } else {
                     
                     const data = window.dms_tempDmsDataRfaSubmission;
                     const { dataDmsFetched: { rows } } = data;
                     const rowsFound = rows.filter(r => selectedIdRows.indexOf(r['id']) !== -1);
                     onClickApplyModalPickDrawing(rowsFound);
                  };
               }}
            />
         </div>
      </div>
   ) : (
      <div style={{ height: 124, display: 'flex', justifyContent: 'center', paddingTop: 35 }}>
         <Icon type='loading' style={{ fontSize: 30 }} />
      </div>
   );
};

export default TableDrawingRFA;





const TableStyled = styled(Table)`
   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .row-tree-parent {
      background-color: ${colorType.grey3};
      font-weight: bold;
   };
   .row-with-rfa-locked {
      background-color: ${colorType.lockedCell}
   };
   .BaseTable__row-cell-text {
      color: black
   };
   .BaseTable__table .BaseTable__body {
      /* -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; */
   };
   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };
   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;
