import React, { useState } from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Form, Icon, Input, Button } from 'antd';
import {checkWohhupUser} from '../module_BimApp/function/AdminFunction'
function LoginForm(props) {


  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)


  const onSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true)
        props
          .submit(values)
          .catch(err => {
            setLoading(false)
            setErrors(err.response.data.errors)
          });
      }
    });
  };

  const validateWohhupEmail = (rule, value, callback) => {
    if (value === 'admin@wohhup.com') {// ||!checkWohhupUser(value)
      callback();
    } else if (checkWohhupUser(value)) {
      callback(`Can't login with wohhup account`);
    }
  }


  return (
    <Form onSubmit={onSubmit} loading={loading}>
      {errors.global && (
        <Message negative>
          <Message.Header>Something went wrong</Message.Header>
          <p>{errors.global}</p>
        </Message>
      )}
      <Form.Item >
        {props.form.getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
            {
              validator: validateWohhupEmail,
            },
          ],
        })(
          <Input
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />,
        )}
      </Form.Item>

      <Form.Item hasFeedback>
        {props.form.getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Please input your password!',
            },
          ],
        })(<Input.Password
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Password" />)}
      </Form.Item>


      <Button type='ghost' block htmlType="submit" loading={loading} >Login</Button>
      <small className="form-text text-center">
        {/* <Link to="/signup">Sign up</Link> if you don't have an account or   */}
        <Link to="/"> return Home</Link><br />
        {/* <Link to="/forgot_password">Forgot Password?</Link> */}
      </small>
    </Form>
  );
}

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default LoginForm;
