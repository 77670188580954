import React, { PureComponent, useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';

const renderLabel = (props) => {
  const { x, y, width, height, value } = props;

  const radius = 10;
  return (
    <text
      x={x + width / 2}
      y={y - radius}
      textAnchor='middle'
      dominantBaseline='middle '
      fill='#0039e6'
      position='left'
      fontWeight='bold'
      fontSize='12px'
    >
      {numberWithCommas(value.toFixed(0))}
    </text>
  );
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default function TimeStackedBarChart(props) {
  const ref = useRef(null);
  useEffect(() => {}, [ref.current]);

  return (
    <div style={{ width: '100%' }} className='overflow-auto' ref={ref}>
      <ResponsiveContainer width={1020} height={268}>
        <BarChart
          onClick={(e) => {
            console.log('e', e);
            try {
              let dts = _.flatten(
                e.activePayload.map((x) => x.payload.forgeData)
              );

              console.log('dts', dts);
              props.handleSetSelectRowsAgGrid(dts);
              props.handleSelectObjectInRows(dts);
            } catch (error) {}
          }}
          data={props.dataConcrete}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='key' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='NA' stackId='a' fill='#d0d4c3' />

          <Bar dataKey='Cast' stackId='a' fill='#FFBB28' />
          <Bar dataKey='Forecast' stackId='a' fill='#0088FE'>
            <LabelList content={renderLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
