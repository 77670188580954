import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Button, Icon, IconButton, ButtonGroup, Drawer, Input, TagPicker, Divider, Loader,Tag } from 'rsuite';
import { Typography, message, Tooltip, } from 'antd';
import _ from 'lodash'
import * as semantic from 'semantic-ui-react';
import Zoom from 'react-medium-image-zoom'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import { getPublicUrl } from '../../function/AdminFunction'
import { getAllModelsElementdbIdsWithCondition } from '../../function/ForgeFunction'
import { convertVector4ColorToHex } from '../../function/TableFunction'
import * as antd from 'antd'
import moment from 'moment';
const { TextArea, } = antd.Input;
const { Text, Paragraph } = Typography

export default function DialogDetailViewpoint(props) {
  const [loading, setLoading] = useState(false)
  const [loadingMember, setLoadingMember] = useState(false)
  const [itemDetail, setItemDetail] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [listMember, setListMember] = useState([])
  const [listShare, setListShare] = useState([])
  const [isEditImg, setIsEditImg] = useState(false)
  const [imgViewpoint, setImgViewpoint] = useState('')

  const [disableTitle, setDisableTitle] = useState(true)
  const [title, setTitle] = useState('')
  const [tempTitle, setTempTitle] = useState('')
  const [disableDescription, setDisableDescription] = useState(true)
  const [description, setDescription] = useState('')
  const [tempDescription, setTempDescription] = useState('')

  // useEffect((e) => {
  //   socket.on('realtime-group-viewpoint', realTimeViewpoint)
  //   return () => {
  //     socket.removeListener('realtime-group-viewpoint', realTimeViewpoint)
  //   }
  // })

  useEffect(() => {
    setLoading(true)
    axios.get("/api/viewpoint/get-viewpoint", { params: { viewpointId: props.viewpoint.viewpointId } })
      .then(res => {
        let index = _.findIndex(res.data.viewpoints, e => { return e.identify === props.itemDetail.identify })
        if (index >= 0) {
          let data = res.data.viewpoints[index]
          setTitle(data.title)
          setDescription(data.description)
          setLoading(false)
          setItemDetail(data)
          getPublicUrl(data.img)
            .then(res => {
              setImgViewpoint(res)
            })
            .catch(() => {
              setLoading(false)
            })
          axios.get(`/api/projects/members?q=${props.projectId}`)
            .then(res => {
              let temp = []
              res.data.members.sort()
              res.data.members.splice(0, 0, props.userCreatedProject)
              _.forEach(res.data.members, v => {
                if (v !== props.email || v !== data.userCreated)
                  temp.push({
                    label: v,
                    value: v,
                    role: 'user'
                  })
              })
              // _.forEach(res.data.groupUser, v => {
              //   temp.push({
              //     label: v.name,
              //     value: v.name,
              //     role: 'group'
              //   })
              // })
              let tempListAssign = []
              _.forEach(data.listShare, v => {
                let index = _.findIndex(temp, e => { return e.label === v })
                if (index >= 0)
                  tempListAssign.push(v)
              })
              setListMember(temp)
              setListShare(tempListAssign)    
              setLoadingMember(false)
            })
            .catch(() => {
              setLoadingMember(false)
            })
        } else {
          props.handleClose(null)
        }

      })
      .catch(() => {
        props.handleClose(null)
        setLoading(false)
      })
  }, [])
  const handleCloseViewPanel = () => {
    props.handleClose(null)
  }

  const handleDisableTitle = () => {
    setTempTitle(title)
    setDisableTitle(!disableTitle)
  }
  const handleRefreshTitle = () => {
    if (!window.confirm('Are you sure to change title')) return
    setTitle(itemDetail.title)
  }
  const handleConfirmTitle = () => {
    if (itemDetail.title !== tempTitle) {
      if (!window.confirm('Are you sure to title description')) return
      setTitle(tempTitle)
      setDisabled(false)
    }
    setDisableTitle(true)
  }
  const handleCloseEditTitle = () => {
    setDisableTitle(true)
  }
  const handleChangeTitle = (value) => {
    setTempTitle(value)
  }

  const handleDisableDescription = () => {
    setTempDescription(description)
    setDisableDescription(!disableDescription)
  }
  const handleConfirmDescription = () => {
    if (itemDetail.description !== tempDescription) {
      if (!window.confirm('Are you sure to change description')) return
      setDescription(tempDescription)
      setDisabled(false)
    }
    setDisableDescription(true)
  }
  const handleRefreshDescription = () => {
    if (!window.confirm('Are you sure to refresh description')) return
    setDescription(itemDetail.description)
  }
  const handleChangeDescription = (e) => {
    setTempDescription(e.target.value)
  }
  const handleCloseEditDescription = () => {
    setDisableDescription(true)
  }

  const handleModifyAssigne = (value) => {
    if (value === null)
      setListShare([])
    else
      setListShare(value)
    setDisabled(false)
  }
  const handleModifyInfoViewPoint = () => {
    if (!window.confirm('Are you sure to update this viewpoint?')) return
    setLoading(true)
    let data = {
      identify: itemDetail.identify,
      title: title,
      description: description,
      listShare: listShare,
      userModified: props.email,
      markup:itemDetail.markup
    }
    axios.post(`/api/viewpoint/update-viewpoint`, { projectId: props.projectId, viewpointId: props.viewpoint.viewpointId, data: data, isEditImg: isEditImg })
      .then(res => {
        setLoading(false)
        setIsEditImg(false)
        props.handleClose(res.data)
        message.success(`Modified viewpoint.`);
      })
      .catch(() => {
        setLoading(false)
        // setIsEditImg(false)
        message.error(`Can't modified viewpoint.`);
      })
  }

  const handleChangeImg = () => {
    if (!window.confirm('Are you sure to update image?')) return
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let markupGeneratedForLeaflet = null
    try {
      markupGeneratedForLeaflet = markupCore.generateData()
    } catch { }
    let data = {}
    let node = props.viewer.impl.model.getDocumentNode()
    data.identify = itemDetail.identify
    data.guid = node.data.guid
    data.viewableID = node.data.viewableID
    data.viewPort = props.viewer.getState()
    data.userModified = props.email
    const models = props.viewer.impl.modelQueue().getModels()
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        data.mainModel = {
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        }
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    data.otherModel = tempOtherModel

    let count = models.length
    let count1 = 0
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count1 = count1 + temp.length
      _.forEach(temp, id => {
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            data.attachElementColor = tempElement
            let screenshot = new Image();
            screenshot.src = props.viewer.canvas.toDataURL("image/png");
            screenshot.onload = () => {
              let canvas = document.getElementById('snapshot');
              canvas.width = props.viewer.canvas.clientWidth;
              canvas.height = props.viewer.canvas.clientHeight;
              let ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

              try {
                let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                markupCore.renderToCanvas(ctx, () => {
                  data.img = canvas.toDataURL()
                  data.markup =  markupGeneratedForLeaflet
                  handleUpdateViewpoint(data)
                });
              } catch{
                data.img = screenshot.src
                data.markup =  markupGeneratedForLeaflet
                handleUpdateViewpoint(data)
              }
            }
          }
        })
      })
      count--
    })
  }
  const handleUpdateViewpoint = (data) => {
    axios.post(`/api/viewpoint/update-viewpoint`, { projectId: props.projectId, viewpointId: props.viewpoint.viewpointId, data: data, isEditImg: true })
      .then(res => {
        let index = _.findIndex(res.data.viewpoints, e => { return e.identify === props.itemDetail.identify })
        if (index >= 0) {
          let data = res.data.viewpoints[index]
          setTitle(data.title)
          setDescription(data.description)
          setLoading(false)
          setItemDetail(data)
          getPublicUrl(data.img)
            .then(res => {
              setImgViewpoint(res)
            })
            .catch(() => {
              setLoading(false)
            })
          axios.get(`/api/projects/members?q=${props.projectId}`)
            .then(res => {
              let temp = []
              res.data.members.sort()
              res.data.members.splice(0, 0, props.userCreatedProject)
              _.forEach(res.data.members, v => {
                if (v !== props.email || v !== data.userCreated)
                  temp.push({
                    label: v,
                    value: v,
                    role: 'user'
                  })
              })
              let tempListAssign = []
              _.forEach(data.listShare, v => {
                let index = _.findIndex(temp, e => { return e.label === v })
                if (index >= 0)
                  tempListAssign.push(v)
              })
              setListShare(tempListAssign)
              setListMember(temp)
              setLoadingMember(false)
            })
            .catch(() => {
              setLoadingMember(false)
            })
        } else {
          props.handleClose(null)
        }
        message.success(`Updated viewpoint`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't update new viewpoint`);
      })
  }
  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleCloseViewPanel} keyboard={false}
        placement='left' size='sm' className='drawerBimAppPanel'>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Drawer.Header  >
          <Drawer.Title>
            View Detail
            </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body >
          {imgViewpoint !== '' &&
            <div style={{ position: 'relative', height: 'fit-content', width: 'fit-content' }}>
              <MenuProvider id={itemDetail.mainModel.itemId === props.itemId ? "info_item_viewpoint_menu" : "info_other_viewpoint_menu"}
                animation={animation.zoom} data={{ viewpoint: props.viewpoint, item: itemDetail }}>
                <Zoom>
                  <semantic.Image src={imgViewpoint} rounded />
                </Zoom>
              </MenuProvider>
              {props.itemId === itemDetail.mainModel.itemId &&
                <IconButton style={{ top: 0, position: 'absolute', display: 'block' }} disabled={props.email !== itemDetail.userCreated}
                  size="xs" icon={<Icon icon="edit" />}
                  onClick={handleChangeImg} />}
            </div>
          }

          <Text strong> Title:{' '}</Text>
          {props.email === itemDetail.userCreated &&
            <ButtonGroup>
              {disableTitle &&
                <>
                  <IconButton size="xs" icon={<Icon icon={"edit"} />} onClick={handleDisableTitle} />
                  <IconButton size="xs" icon={<Icon icon='refresh' />} onClick={handleRefreshTitle} disabled={itemDetail.title === title} />
                </>
              }
              {!disableTitle &&
                <>
                  <IconButton size="xs" icon={<Icon icon={'check'} />} color={'blue'} onClick={handleConfirmTitle} />
                  <IconButton size="xs" icon={<Icon icon='close' />} color={'red'} onClick={handleCloseEditTitle} />
                </>
              }
            </ButtonGroup>
          }
          {disableTitle ?
            <p style={{ whiteSpace: 'pre-wrap' }}>{title} </p>
            :
            <Input value={tempTitle} size='sm' style={{ marginBottom: '1em' }} onChange={handleChangeTitle} />
          }
          <Text strong> Description:{' '}</Text>
          {props.email === itemDetail.userCreated &&
            <ButtonGroup>
              {disableDescription &&
                <>
                  <IconButton size="xs" icon={<Icon icon={"edit"} />} onClick={handleDisableDescription} />
                  <IconButton size="xs" icon={<Icon icon='refresh' />} onClick={handleRefreshDescription} disabled={itemDetail.description === description} />
                </>
              }
              {!disableDescription &&
                <>
                  <IconButton size="xs" icon={<Icon icon={'check'} />} color={'blue'} onClick={handleConfirmDescription} />
                  <IconButton size="xs" icon={<Icon icon='close' />} color={'red'} onClick={handleCloseEditDescription} />
                </>
              }
            </ButtonGroup>
          }
          {disableDescription ?
            <p style={{ whiteSpace: 'pre-wrap' }}>{description} </p>
            :
            <TextArea value={tempDescription} autoSize size='small' style={{ marginBottom: '1em' }} onChange={handleChangeDescription} />
          }
          <Text strong> Created</Text>
          <Tooltip title={moment(itemDetail.dateCreated).format('DD-MM-YYYY HH:mm')}>
            <Paragraph >{`${moment(itemDetail.dateCreated).format('DD-MM-YYYY')} by ${itemDetail.userCreated}`} </Paragraph>
          </Tooltip>


          <Text strong> Modified</Text>
          <Tooltip title={moment(itemDetail.dateModified).format('DD-MM-YYYY HH:mm')}>
            <Paragraph >{`${moment(itemDetail.dateModified).format('DD-MM-YYYY')} by ${itemDetail.userModified}`} </Paragraph>
          </Tooltip>


          <Text strong> Share</Text>
          <TagPicker value={listShare} data={listMember} onChange={handleModifyAssigne}
            style={{ width: '100%', marginBottom: '1em' }} placement='rightEnd' size="sm" groupBy='role'
            renderMenu={menu => {
              if (loadingMember) {
                return (
                  <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Icon icon="spinner" spin /> Loading...
                  </p>
                );
              }
              return menu;
            }}
            renderMenuItem={(label, item) => {
              return (
                <div>
                  {/* <i className={item.role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} */}
                  <Icon icon={item.role === 'user'?'user':'group'} /> {label}
                </div>
              );
            }}
            renderMenuGroup={(label, item) => {
              return (
                <div>
                  {/* <i className={item.children[0].role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} - (
                  {item.children.length}) */}
                  <Icon icon={item.children[0].role === 'user'?'user':'group'} />  {label} - (
                  {item.children.length})
                </div>
              );
            }}
            renderValue={(values, items, tags) => {
              return items.map((tag, index) => (
                <Tag style={{ color: tag.role === 'user' ? '#8ab4f8' : '#ff7171' }}>
                      <Icon icon={tag.role === 'user'?'user':'group'} /> {tag.label}
                  </Tag> 
              ));
            }} />

        </Drawer.Body>
        <Divider />
        <Drawer.Footer>
          <Button appearance="primary" onClick={handleModifyInfoViewPoint} loading={loading} disabled={disabled}>
            Save
        </Button>
          <Button appearance="subtle" onClick={handleCloseViewPanel}>
            Cancel
        </Button>
        </Drawer.Footer>
      </Drawer>

      <Menu id='info_item_viewpoint_menu' style={{ zIndex: 10000 }}>
        <Item onClick={props.restoreSaveView.bind(this, true)}>
          <IconFont className="fas fa-check" />Load View
                  </Item>
        {!props.newTab && <Item onClick={props.restoreSaveView.bind(this, false)}>
          <IconFont className="fas fa-check-double" />Load View With Original Version
                  </Item>}
      </Menu>
      {!props.newTab && <Menu id='info_other_viewpoint_menu' style={{ zIndex: 10000 }}>
        <Item onClick={props.handleOpenViewpoint.bind(this, true)}>
          <IconFont className="fas fa-check" />Load View
                </Item>
        <Item onClick={props.handleOpenViewpoint.bind(this, false)}>
          <IconFont className="fas fa-check-double" />Load View With Original Version
                </Item>
      </Menu>}

    </>
  )
}