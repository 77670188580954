import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon, Select, InputNumber } from 'antd'
import _ from 'lodash';

import { setPDFData } from '../../../../actions/app/pdf'
const { Option } = Select;
function InputColor(props) {

    return (
        <>
            {/* <Select size='small' defaultValue="lucy" style={{ width: 120 }} onChange={handleChange}>
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="disabled" disabled>
                    Disabled
                </Option>
                <Option value="Yiminghe">yiminghe</Option>
            </Select> */}
            <InputNumber  style={{width:50, backgroundColor:'#e7ebee'}} size='small' min={1} max={10} value={props.pdfViewer.strokeWidth} onChange={(e) => {
                props.setPDFData({strokeWidth : e})
            }} />
        </>
    )
}

InputColor.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(InputColor)