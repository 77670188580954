import React from 'react';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { getRefStringWithVersion } from '../../../utils';
import { columnLocked, rowLocked } from './Cell';
import { getInfoValueFromRefDataForm } from './CellForm';
import { getCompanyNameFnc, getTradeNameFnc } from './FormDrawingTypeOrder';



const PanelFunction = (props) => {

   const { buttonPanelFunction, panelType, stateRow, stateProject } = props;

   const { roleTradeCompany, projectIsAppliedRfaView, pageSheetTypeName } = stateProject.allDataOneSheet;

   const { rowsSelectedToMove, rowsSelected, drawingTypeTree, modeGroup, rfaData } = stateRow;

   const { rowData, column } = panelType.cellProps;

   const isLockedColumn = columnLocked(roleTradeCompany, rowData, modeGroup, column.key, projectIsAppliedRfaView, pageSheetTypeName);

   const isLockedRow = rowLocked(roleTradeCompany, rowData, modeGroup, drawingTypeTree);


   let isLockedByCompanyOrTrade = false;


   const listButton = (
      pageSheetTypeName === 'page-spreadsheet' &&
      rowData._rowLevel && rowData._rowLevel === 1 && // prevent Group Function
      !isLockedColumn && !isLockedRow
   ) ? [
      'View Drawing Revision',
      'Create New Drawing Revision',
      'Date Automation',
      'View Cell History',
      'Duplicate Drawings',
      'Insert Drawings Below',
      'Insert Drawings Above',
      'Move Drawings',
      'Paste Drawings',
      'Delete Drawing'

   ] : (
      pageSheetTypeName === 'page-spreadsheet' &&
      rowData._rowLevel && rowData._rowLevel === 1 &&
      (isLockedColumn || isLockedRow)
   ) ? [
      'View Drawing Revision',
      'View Cell History',

   ] : (rowData.treeLevel) ? [
      'Paste Drawings',
      'Insert Drawings By Type'

   ] : [];


   const onClickBtn = (btn) => {
      buttonPanelFunction(btn);
   };


   return (
      <div
         style={{
            border: `1px solid ${colorType.grey1}`,
            background: 'white',
         }}
      >
         {listButton.map(btn => (
            <Container
               key={btn}
               onMouseDown={() => onClickBtn(btn)}
               style={disabledBtn(
                  props,
                  btn,
                  rowsSelectedToMove,
                  roleTradeCompany,
                  isLockedByCompanyOrTrade,
                  rowsSelected,
                  drawingTypeTree,
                  projectIsAppliedRfaView,
                  pageSheetTypeName,
                  rfaData
               )}
            >
               {btn}
            </Container>

         ))}
      </div>
   );
};

export default PanelFunction;

const Container = styled.div`
   padding: 7px;
   padding-left: 25px;
   
   cursor: pointer;
   color: black;
   &:hover {
      background: ${colorType.grey0};
   };

`;


const disabledBtn = (
   { panelType },
   btn,
   rowsSelectedToMove,
   roleTradeCompany,
   isLockedByCompanyOrTrade,
   rowsSelected,
   drawingTypeTree,
   projectIsAppliedRfaView,
   pageSheetTypeName,
   rfaData
) => {
   const { rowData } = panelType.cellProps;
   const { _rowLevel, treeLevel, id, rfaNumber } = rowData;


   const issueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction');



   let consultantLeadReply;
   if (projectIsAppliedRfaView) {
      const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rowData['RFA Ref']);
      if (rfaFound) {
         const consultantLeadName = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];
         if (consultantLeadName) {
            consultantLeadReply = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'status', consultantLeadName);
         };
      };
   };


   if (
      (projectIsAppliedRfaView && (!consultantLeadReply || roleTradeCompany.role !== 'Document Controller') && btn === 'Create New Drawing Revision') ||

      (rowsSelectedToMove.length === 0 && btn === 'Paste Drawings') ||
      (_rowLevel === 1 && roleTradeCompany.role === 'Modeller' && btnLocked_1.indexOf(btn) !== -1) ||

      (_rowLevel === 1 && rowsSelected.length > 0 && rowsSelectedToMove.length === 0 && btn !== 'Move Drawings' && btn !== 'Date Automation') ||
      (_rowLevel === 1 && rowsSelected.length > 0 && rowsSelectedToMove.length > 0 && btn !== 'Paste Drawings') ||
      (_rowLevel === 1 && (roleTradeCompany.role === 'View-Only User' || roleTradeCompany.role === 'Production') && btnLocked_2.indexOf(btn) !== -1) ||

      (_rowLevel === 1 && (rfaNumber || issueForConstruction) && btn === 'Delete Drawing') ||

      (pageSheetTypeName === 'page-spreadsheet' && (
         (treeLevel === 1 && rowData.title === 'Woh Hup Private Ltd' && (btn === 'Paste Drawings' || btn === 'Insert Drawings By Type')) ||
         (treeLevel === 2 && rowData.title.includes('(SUBCON)') && (btn === 'Paste Drawings' || btn === 'Insert Drawings By Type')) ||
         (treeLevel > 1 && drawingTypeTree.find(x => x.parentId === id) && (btn === 'Paste Drawings' || btn === 'Insert Drawings By Type'))
      )) ||


      (treeLevel > 1 && !drawingTypeTree.find(x => x.parentId === id) && rowsSelectedToMove.length === 0 && btn === 'Paste Drawings') ||
      (treeLevel >= 1 && isLockedByCompanyOrTrade)

   ) {
      return {
         pointerEvents: 'none',
         color: 'grey'
      };
   };
};


const btnLocked_1 = [
   'Create New Drawing Revision',
   'Date Automation',
];

const btnLocked_2 = [
   'Create New Drawing Revision',
   'Date Automation',
   'Duplicate Drawings',
   'Insert Drawings Below',
   'Insert Drawings Above',
   'Move Drawings',
   'Paste Drawings',
   'Delete Drawing',
   'Insert Drawings By Type'
];


export const getPanelPosition = ({ x: clickX, y: clickY }) => {
   const screenW = window.innerWidth;
   const screenH = window.innerHeight;

   const right = (screenW - clickX) < 200;
   const left = clickX < 200;
   const top = clickY < 200;
   const bottom = (screenH - clickY) < 200;

   return {
      x: right && top ? clickX - 250 :
         right && bottom ? clickX - 250 :
            left && bottom ? clickX :
               left && top ? clickX :
                  right ? clickX - 250 : clickX,
      y: right && top ? clickY :
         right && bottom ? clickY - 300 :
            left && bottom ? clickY - 300 :
               left && top ? clickY :
                  bottom ? clickY - 300 : clickY
   };
};