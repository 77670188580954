import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { message } from "antd";

export const func_add_new_conc_do = async (hotData) => {
   const project_id = localStorage.getItem("fp_project_id");
   var conc_do_list = [];
   hotData.forEach((e) => {
      if (e.do_number !== undefined && e.do_number !== "" && e.product !== undefined) {
         let conc_do = {
            id: uuidv4(),
            project_id: project_id,
            do_number: e.do_number,
            do_date: dayjs(e.do_date, "DD-MM-YYYY").format("DD-MM-YYYY"),
            volume: e.volume,
            pour_name: e.pour_name,
            order_by: e.order_by,
            product: e.product,

            supplier: e.supplier,
            s3_key: (project_id + "_conc_do_" + e.do_number + ".pdf").toLowerCase(),
            remark: e.remark,
         };
         conc_do_list.push(conc_do);
      }
   });
   if (conc_do_list.length > 0) {
      const res = await axios.post("/api/do_inv/common/multi", {
         filtterd_array: conc_do_list,
         current: "conc_do",
      });
      if (res.data.data.code === 0) {
         message.success("New DO has saved to database.");
         return "success";
      } else {
         message.warning("Not succesefull when save data.");
         return "notsuccess";
      }
   }
};

export const func_add_new_conc_inv = async (hotData) => {
   const project_id = localStorage.getItem("fp_project_id");
   var conc_do_list = [];
   hotData.forEach((e) => {
      if (
         e.inv_number !== undefined &&
         e.inv_number !== "" &&
         e.do_number !== undefined &&
         e.do_number !== "" &&
         e.product !== undefined
      ) {
         let conc_do = {
            id: uuidv4(),
            project_id: project_id,
            inv_number: e.inv_number,
            inv_date: e.inv_date,
            do_number: e.do_number,
            do_date: e.do_date,
            volume: e.volume,
            pour_name: e.pour_name,

            product: e.product,

            supplier: e.supplier,
            unit_price: e.unit_price,
            amount_claim: e.amount_claim,
            amount_gst: e.amount_gst,
            s3_key: (project_id + "_conc_do_" + e.do_number + ".pdf").toLowerCase(),
            remark: e.remark,
         };
         conc_do_list.push(conc_do);
      }
   });
   if (conc_do_list.length > 0) {
      const res = await axios.post("/api/do_inv/common/multi", {
         filtterd_array: conc_do_list,
         current: "conc_inv",
      });
      if (res.data.data.code === 0) {
         message.success("New DO has saved to database.");
         return "success";
      } else {
         message.warning("Not succesefull when save data.");
         return "notsuccess";
      }
   }
};

export const func_add_new_rebar_do = async (hotData) => {
   const project_id = localStorage.getItem("fp_project_id");
   var rebar_do_list = [];
   hotData.forEach((e) => {
      if (e.do_number !== undefined && e.do_number !== "" && e.product !== undefined) {
         let rebar_do = {
            id: uuidv4(),
            project_id: project_id,
            do_number: e.do_number,
            do_date: new Date(e.do_date),

            product: e.product,
            weight_qty: e.weight_qty,
            unit: e.unit,

            amount_claim: e.amount_claim,
            s3_key: (project_id + "_rebar_do_" + e.inv_number + ".pdf").toLowerCase(),
            remark: e.remark,
         };
         rebar_do_list.push(rebar_do);
      }
   });
   if (rebar_do_list.length > 0) {
      const res = await axios.post("/api/do_inv/common/multi", {
         filtterd_array: rebar_do_list,
         current: "rebar_do",
      });
      if (res.data.data.code === 0) {
         message.success("New DO has saved to database.");
         return "success";
      } else {
         message.warning("Not succesefull when save data.");
         return "notsuccess";
      }
   }
};

export const func_add_new_rebar_inv = async (hotData) => {
   const project_id = localStorage.getItem("fp_project_id");
   var rebar_do_list = [];
   hotData.forEach((e) => {
      if (
         e.inv_number !== undefined &&
         e.inv_numbe !== "" &&
         e.do_number !== undefined &&
         e.do_number !== "" &&
         e.product !== undefined
      ) {
         let rebar_do = {
            id: uuidv4(),
            project_id: project_id,
            inv_number: e.inv_number,
            inv_date: e.inv_date,
            do_number: e.do_number,
            do_date: e.do_date,

            product: e.product,
            weight_qty: e.weight_qty,
            unit: e.unit,

            supplier: e.supplier,
            unit_price: e.unit_price,
            amount_claim: e.amount_claim,
            amount_gst: e.amount_gst,
            s3_key: (project_id + "_rebar_do_" + e.inv_number + ".pdf").toLowerCase(),
            remark: e.remark,
         };
         rebar_do_list.push(rebar_do);
      }
   });
   if (rebar_do_list.length > 0) {
      const res = await axios.post("/api/do_inv/common/multi", {
         filtterd_array: rebar_do_list,
         current: "rebar_inv",
      });
      if (res.data.data.code === 0) {
         message.success("New DO has saved to database.");
         return "success";
      } else {
         message.warning("Not succesefull when save data.");
         return "notsuccess";
      }
   }
};
