import _ from 'lodash';
import moment from 'moment';
import { arrayStatusDrawingText, arrayTextStatusQrQrm, arrayTextStatusRfc, tradeArrayForm, tradeArrayMeetingMinutesForm } from '../constants';
import { headersConsultantWithNumber } from '../dms/layouts/generalComponents/ComponentDmsOverall';
import { getConsultantReplyFormData, getInfoValueFromRefDataForm } from '../dms/layouts/pageSpreadsheet/CellForm';
import { getConsultantReplyData, isColumnWithReplyData } from '../dms/layouts/pageSpreadsheet/CellRFA';
import { getTradeNameFnc, getTreeFlattenOfNodeInArray } from '../dms/layouts/pageSpreadsheet/FormDrawingTypeOrder';
import { getKeyTextForSheet } from '../dms/layouts/pageSpreadsheet/PanelSetting';


export const statusOrderArray = [
   'Approved for Construction',
   'Approved with Comment, no submission Required',
   'Approved with comments, to Resubmit',
   'Reject and resubmit',
   'Consultant reviewing'
];


export const colorTypeStatus = {
   yellow: '#fff200',
   dark: '#1e272e',
   grey0: '#ecf0f1',
   grey1: '#bdc3c7',
   grey2: '#636e72',
   grey3: '#dfe4ea',
   grey4: '#f1f2f6',
   red: '#FA8072',
   green: '#009432',
   blue: '#0984e3',
   orange: '#cc8e35',
   purple: '#303952',
   rose: '#e84118',
   black: 'black',
   orange2: '#f1c40f',
   pp: '#9b59b6',
   pp2: '#10ac84',
};


export const randomColorRangeStatus = {
   'Approved with comments, to Resubmit': colorTypeStatus.purple,
   'Approved with Comment, no submission Required': colorTypeStatus.blue,
   'Approved for Construction': colorTypeStatus.green,
   'Consultant reviewing': colorTypeStatus.grey2,
   'Not Started': colorTypeStatus.orange,
   'Revise In-Progress': colorTypeStatus.yellow,
   '1st cut of drawing in-progress': colorTypeStatus.pp2,
   '1st cut of model in-progress': colorTypeStatus.pp,
   'Pending design': colorTypeStatus.orange2,
   'Reject and resubmit': colorTypeStatus.red,
   'Consultant review and reply': colorTypeStatus.blue,
   'Create update drawing': colorTypeStatus.orange,
   'Create update model': colorTypeStatus.green,
};






export const getRandomColor = () => {
   let letters = '0123456789ABCDEF';
   let color = '#';
   for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
   };
   return color;
};


export const randomColorRange = [
   '#FFDEAD',
   '#98FB98',
   '#e74c3c',
   '#9b59b6',
   '#1abc9c',
   '#95a5a6'
];

export const genId = (xxx) => {
   let arr = [];
   for (let i = 0; i < xxx; i++) {
      arr.push(mongoObjectId());
   };
   return arr;
};

export const debounceFnc = (func, delay) => {
   let timeout;
   return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
         timeout = null;
         func.apply(context, args);
      }, delay);
   };
};




export const mongoObjectId = () => {
   var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
   return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
      return (Math.random() * 16 | 0).toString(16);
   }).toLowerCase();
};



const getHeaderKey = (headers, headerText) => {
   if (!headers) return;
   return headers.find(hd => hd.text === headerText).key;
};

export const replaceBreakLine = (str) => {
   if (!str) return '';
   let splitted = str.match(/[^\r\n]+/g);
   let output = '';
   splitted.forEach(txt => {
      output += txt + '\n';
   });
   return output;
};


const _newParent = (level, iddd) => {
   return {
      id: iddd,
      _rowLevel: level,
      count: 0,
      children: []
   };
};
export const groupByHeaders = (data, headers) => {
   let res = [];
   let _map = {};
   // push all rowIds have children in here
   let parentIdsArr = [];
   let _mapParentIds = {};
   for (let item of data) {
      let _prevLevelKey = '';
      let _prevLevelParent = null;
      for (let i = 0, level = headers.length * (-1) + 1; i < headers.length; i++, level++) {
         let arrayParent = i == 0 ? res : _prevLevelParent.children;

         let header = headers[i];
         let value = (item[header] || '').trim();

         let levelKey = `${_prevLevelKey}_._${value}`;
         let levelParentIndex = _map[levelKey];
         let levelParent = arrayParent[levelParentIndex];

         if (!levelParent) {

            let iddd = levelKey + value;
            levelParent = _newParent(level, iddd);

            _map[levelKey] = arrayParent.length;
            arrayParent.push(levelParent);
         };

         levelParent.count++;


         levelParent.title = `${item[header] || 'No data'}: (${levelParent.count} nos)`;


         if (!_mapParentIds[levelParent.id]) { // levelParent id not yet been push to parentIdsArr
            parentIdsArr.push(levelParent.id);
            _mapParentIds[levelParent.id] = true;
         };

         if (i == headers.length - 1) {
            levelParent.children.push(item);
         } else {
            _prevLevelKey = levelKey;
            _prevLevelParent = levelParent;
         };
      };
   };
   return {
      rows: res,
      expandedRows: parentIdsArr
   };
};



export const extractCellInfo = (key) => {
   const idexOfSplitDigit = key.indexOf('~#&&#~');
   return {
      rowId: key.slice(0, idexOfSplitDigit),
      headerName: key.slice(idexOfSplitDigit + 6, key.length)
   }
};
export const convertCellTempToHistory = (
   cellsModifiedTemp,
   stateProject,
   isOutputDataText
) => {
   const { email, publicSettings } = stateProject.allDataOneSheet;
   const cellsHistoryData = Object.keys(cellsModifiedTemp).map(key => {
      const { rowId, headerName } = extractCellInfo(key);
      const dataOut = {
         rowId,
         headerKey: isOutputDataText ? headerName : getHeaderKey(publicSettings.headers, headerName),
         history: {
            text: cellsModifiedTemp[key],
            email,
            createdAt: new Date(),
         }
      };
      return dataOut;
   });
   return cellsHistoryData;
};



export const convertHistoryData = (data) => {
   let arr = [];
   data.forEach(ch => {
      const { histories, headerKey, row } = ch;
      histories.forEach(dt => {
         arr.push({
            ...dt,
            headerKey, row
         });
      });
   });
   return arr
};

export const convertDrawingVersionToHistory = (rowsHistory, stateProject) => {

   const { publicSettings } = stateProject.allDataOneSheet;

   const rowsHistoryOutput = rowsHistory.map(rowsH => {
      let obj = {};

      Object.keys(rowsH).forEach(key => {
         if (key === 'rfaNumber' || key.includes('reply-') || key.includes('submission-')) { // SAVE ALL rfa & dt info
            obj[key] = rowsH[key];
         };
      });

      publicSettings.headers.forEach(hd => {
         if (rowsH[hd.text]) obj = { ...obj || {}, [hd.key]: rowsH[hd.text] };
      });

      return {
         row: rowsH.id,
         history: obj,
      };
   });

   return rowsHistoryOutput;
};


export const getHeaderUploadViewWidth = (header) => {
   if (header === 'File Name') return 650;
   else if (header === 'Date') return 110;
   else if (header === 'Size') return 85;
   else if (header === 'Uploaded By') return 250;
   else return 200;
};

export const getHeaderWidthForRFAView = (header, pageSheetTypeName) => {



   if (pageSheetTypeName === 'page-mm' && header === 'Description') return 700;
   if (pageSheetTypeName === 'page-eri' && header === 'Description') return 700;
   if (pageSheetTypeName === 'page-lr' && header === 'Description') return 700;
   if (pageSheetTypeName === 'page-sm' && header === 'Description') return 700;
   if ((pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt') && header === 'Received By') return 800;
   if ((pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') && header === 'Description') return 350;
   if ((pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') && header === 'Requested By') return 130;

   if (header === 'RFA Ref') return 470;
   else if (
      header === 'RFAM Ref' || header === 'RFI Ref' || header === 'CVI Ref' ||
      header === 'DT Ref' || header === 'MM Ref' || header === 'RFC Ref'
   ) {
      return 500;

   } else if (header === 'QR Ref' || header === 'QRM Ref') {
      return 400;

   } else if (header === 'RFAM Info' || header === 'RFA Info') {
      return 200;

   } else if (
      (
         pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' ||
         pageSheetTypeName === 'page-dt' || pageSheetTypeName === 'page-mm' ||
         pageSheetTypeName === 'page-eri' || pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm'
      ) && header === 'Description'
   ) {
      return 280;

   } else if (header === 'Drawing Number') return 220;
   else if (header === 'Drawing Name') return 300;
   else if (header === 'Due Date') return 70;
   else if (header === 'Submission Date') return 80;
   else if (header === 'Rev') return 40;
   else if (header === 'Requested By') return 80;
   else if (header === 'Consultant') return 200;
   else if (header === 'Subject') return 200;

   return getHeaderWidth(header);
};
export const getHeaderWidth = (header) => {

   if (header === 'RFA Ref') return 200;
   else if (
      header === 'Block/Zone' ||
      header === 'Level' || header === 'Unit/CJ' ||
      header === 'Use For' ||
      header === 'Coordinator In Charge' || header === 'Modeller' ||
      header === 'Construction Start'
   ) return 120;
   else if (header === 'Model Progress' || header === 'Drawing Progress') return 80;
   else if (header === 'Drg Type') return 180;
   else if (header === 'Construction Issuance Date') return 120;

   else if (
      header === 'Drg To Consultant (A)' ||
      header === 'Drg To Consultant (T)' ||
      header === 'Get Approval (A)' ||
      header === 'Get Approval (T)'
   ) return 120;

   else if (header.includes('(A)') || header.includes('(T)')) return 90;
   else if (header === 'Issue For Construction') return 135;
   else if (header === 'IFC Rev') return 35;
   else if (header === 'IFC Date') return 70;

   else if (header === 'Rev') return 60;
   else if (header === 'Status') return 280;
   else if (header === 'Remark') return 400;
   else if (header === 'Drawing Number') return 450;
   else if (header === 'Drawing Name') return 450;

   else if (isColumnWithReplyData(header)) return 180;

   else if (header === 'Cost Implication') return 80;
   else if (header === 'Time Extension') return 70;
   else if (header === 'Description') return 100;
   else if (header === 'Signatured By') return 170;
   else if (header === 'Conversation Among') return 180;
   else if (header === 'Conversation Date') return 150;
   else if (header === 'Received By') return 400;
   else if (header === 'Contract Specification') return 90;
   else if (header === 'Proposed Specification') return 200;
   else if (header === 'Submission Type') return 80;
   else if (header === 'Attachment Type') return 100;
   else if (header === 'Transmitted For') return 130;
   else if (header === 'Tags') return 600;


   else if (header === 'RFAM Ref') return 400;


   else return 300;

};

export const addZero = (num) => {
   if (num < 10) return '0' + num;
   return num;
};




export const getActionName = (type) => {
   if (type === 'filter-ICON') return 'Create New Filter';
   if (type === 'reorderColumn-ICON') return 'Columns Layout';
   if (type === 'group-ICON') return 'Group Data';
   if (type === 'sort-ICON') return 'Sort Data';
   if (type === 'swap-ICON-1') return 'Quit Grouping Mode';
   if (type === 'swap-ICON-2') return 'Clear Filter/Sort/Search';
   if (type === 'addDrawingType-ICON') return 'Drawing Type Organization';
   if (type === 'addUploadFolder-ICON') return 'Folder Tree';
   if (type === 'color-cell-history-ICON') return 'Check Data Changed';
   if (type === 'View Cell History') return 'Cell History';
   if (type === 'Delete Drawing') return 'Delete Drawing';
   if (type === 'Delete Files') return 'Delete Files';
   if (type === 'colorized-ICON') return 'Drawing Colorization';
   if (type === 'viewTemplate-ICON') return 'View Template';
   if (type === 'history-ICON') return 'Drawing History';
   if (type === 'View Drawing Revision') return 'Drawing Revision';

   if (type === 'form-submit-RFA') return 'Add New RFA';
   if (type === 'form-resubmit-RFA') return 'Resubmit RFA';
   if (type === 'form-reply-RFA') return 'Reply RFA';
   if (type === 'option-email-or-not-for-admin') return 'Choose Admin Action';

   if (type === 'form-submit-multi-type') return 'Add New Form';
   if (type === 'form-resubmit-multi-type') return 'Resubmit Form';
   if (type === 'form-reply-multi-type') return 'Reply Form';

   if (type === 'export-data') return 'Export Data Report';
   if (type === 'goToViewDMS-ICON') return 'Go To DMS View';
   if (type === 'goToViewRFA-ICON') return 'Go To RFA View';
   if (type === 'acknowledge-form') return 'Acknowledge Form';
   if (type === 'acknowledge-or-reply-form') return 'Acknowledge Or Reply Form';
   if (type === 'add-discipline-ICON') return 'Add Form Discipline';
   if (type === 'add-authorities-ICON') return 'Add Authorities';
   if (type === 'project-settings-ICON') return 'Project Settings';
   if (type === 'import-excel-ICON') return 'Import Data From Excel';


   if (type === 'Date Automation') return 'Date Automation';

   if (type && (type.includes('Insert Drawings') || type === 'Duplicate Drawings')) return 'Nos Of Drawings';

   else return '';
};




export const ExcelDateToJSDate = (serial) => {
   let utc_days = Math.floor(serial - 25569);
   let utc_value = utc_days * 86400;
   let date_info = new Date(utc_value * 1000);

   let fractional_day = serial - Math.floor(serial) + 0.0000001;

   let total_seconds = Math.floor(86400 * fractional_day);

   let seconds = total_seconds % 60;

   total_seconds -= seconds;

   let hours = Math.floor(total_seconds / (60 * 60));
   let minutes = Math.floor(total_seconds / 60) % 60;

   return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
};



export const sortRfaDrawingNumber = (a, b) => {

   if (a['RFA Ref'] > b['RFA Ref']) return -1;
   if (a['RFA Ref'] < b['RFA Ref']) return 1;

   if (a['Drawing Number'] > b['Drawing Number']) return 1;
   if (a['Drawing Number'] < b['Drawing Number']) return -1;

};


export const compareDatesForm = (date) => {
   if (!date) return 1;
   else {
      const delta = moment(date).diff(moment(), 'minutes') / (60 * 24);
      return delta;
   };
};





export const validateEmailInput = (email) => {
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
};




export const getUserRoleTradeCompany = (role, company) => {

   const roleArray = [
      'Document Controller',

      'WH Archi Coordinator',
      'WH C&S Design Engineer',
      'WH M&E Coordinator',
      'WH PRECAST Coordinator',

      'WH Archi Modeller',
      'WH C&S Modeller',
      'WH M&E Modeller',
      'WH PRECAST Modeller',

      'Production',

      'WH Archi Manager',
      'WH C&S Manager',
      'WH M&E Manager',
      'WH PRECAST Manager',

      'Planning Engineer',
      'QS',
      'Project Manager',
      'Corporate Manager',
      'QAQC',
      'Safety',
      'Client',

      'Sub-Con',
      'Consultant',
      'RE/RTO'
   ];


   if (
      !role || !company || roleArray.indexOf(role) === -1 ||
      role === 'WH Archi Manager' || role === 'WH C&S Manager' || role === 'WH M&E Manager' || role === 'WH PRECAST Manager' ||
      role === 'Planning Engineer' || role === 'QS' || role === 'Project Manager' || role === 'Corporate Manager' ||
      role === 'QAQC' || role === 'Safety' || role === 'RE/RTO'
   ) {
      return { role: 'View-Only User', trade: null, company: null };
   };

   if (role === 'WH Archi Coordinator') return { role: 'Coordinator', trade: 'ARCHI', company };
   if (role === 'WH C&S Design Engineer') return { role: 'Coordinator', trade: 'C&S', company };
   if (role === 'WH M&E Coordinator') return { role: 'Coordinator', trade: 'M&E', company };
   if (role === 'WH PRECAST Coordinator') return { role: 'Coordinator', trade: 'PRECAST', company };

   if (role === 'WH Archi Modeller') return { role: 'Modeller', trade: 'ARCHI', company };
   if (role === 'WH C&S Modeller') return { role: 'Modeller', trade: 'C&S', company };
   if (role === 'WH M&E Modeller') return { role: 'Modeller', trade: 'M&E', company };
   if (role === 'WH PRECAST Modeller') return { role: 'Modeller', trade: 'PRECAST', company };

   return { role, trade: null, company };
};


export const getUserAndProjectInfoInit = (projectData, userData) => {

   const { projectName, projectId } = projectData;
   const { contractors, email } = userData;
   let role;
   if (contractors) {
      const projectFound = contractors.find(p => p.projectId === projectId);
      if (projectFound) {
         role = projectFound.role
      };
   };

   return {
      projectName,
      projectId,
      role,
      email,
      isAdmin: email === 'admin@wohhup.com'
   };
};


export const arrayTradeWithNoSubTrade = ['ARCHI', 'C&S', 'PRECAST'];

export const checkIfItIsRowLevelRfaHeader = (rowData) => {
   return Array.isArray(rowData.btn) && rowData.btn.length > 0;
};

export const getSubMepFromDmsTree = (dmsTree) => {
   return dmsTree.filter(x => {
      return tradeArrayForm.indexOf(x.title) === -1 &&
         x.treeLevel === 3 &&
         getTradeNameFnc(x, dmsTree).includes('M&E');
   });
};



////////////////////////////////// KEY EVENTS ===============================>>>


export const CLASS_ROW_PREFIX = 'row-index';
export const CLASS_COL_PREFIX = 'col-index';


const clamp = (num, min, max) => {
   return num < min ? min : num > max ? max : num;
};

const MINIMUM_NAVIGATE_DELAY = 1000 / 20; // Max navigate 20 cells/1s
let lastNavigateAt = 0;
let lastNavigateDirection = '';
const setLastNavigate = (direction) => {
   lastNavigateAt = Date.now();
   lastNavigateDirection = direction;
};
const tooFastNavigate = (direction) => {
   return direction === lastNavigateDirection && Date.now() - MINIMUM_NAVIGATE_DELAY < lastNavigateAt;
};
const onlyOneCellInRange = (selectRange) => {
   return selectRange.from && (!selectRange.to || (
      selectRange.from.rowIndex === selectRange.to.rowIndex
      && selectRange.from.columnIndex === selectRange.to.columnIndex
   ));
};


export const createKeyEvents = ({
   maxRowIndex,
   maxColumnIndex,
   cellActive,
   setCellActive,
   selectRange,
   setSelectRangeFrom,
   setSelectRangeTo
}) => {
   return (e) => {
      if (cellActive) {
         if (e.key === 'Escape') {
            setCellActive(null);
         };
         return;
      };

      if (selectRange.from) {

         e.preventDefault();

         if (e.ctrlKey) {

            // console.log('press CTRL');

         } else if (e.key === 'Enter' && onlyOneCellInRange(selectRange)) {
            setCellActive(selectRange.from);

         } else if (e.key === 'ArrowUp') {

            if (tooFastNavigate('up')) return;
            else {
               setSelectRangeTo(null);
               setSelectRangeFrom({
                  rowIndex: clamp(selectRange.from.rowIndex - 1, 0, maxRowIndex),
                  columnIndex: selectRange.from.columnIndex,
               }, 'up');
               setLastNavigate('up');
            };
         } else if (e.key === 'ArrowRight') {

            if (tooFastNavigate('right')) return;
            else {
               setSelectRangeTo(null);
               setSelectRangeFrom({
                  rowIndex: selectRange.from.rowIndex,
                  columnIndex: clamp(selectRange.from.columnIndex + 1, 1, maxColumnIndex),
               }, 'right');
               setLastNavigate('right');
            }
         } else if (e.key === 'ArrowDown') {

            if (tooFastNavigate('down')) return;
            else {
               setSelectRangeTo(null);
               setSelectRangeFrom({
                  rowIndex: clamp(selectRange.from.rowIndex + 1, 0, maxRowIndex),
                  columnIndex: selectRange.from.columnIndex,
               }, 'down');
               setLastNavigate('down');
            };
         } else if (e.key === 'ArrowLeft') {
            if (tooFastNavigate('left')) return;
            else {
               setSelectRangeTo(null);
               setSelectRangeFrom({
                  rowIndex: selectRange.from.rowIndex,
                  columnIndex: clamp(selectRange.from.columnIndex - 1, 1, maxColumnIndex),
               }, 'left');
               setLastNavigate('left');
            }
         };

      } else {
         return;
      };
   };
};





export const inRangeSelect = (rowIndex, columnIndex, selectRange) => {
   if (!selectRange.from) return false;
   if (
      selectRange.from.rowIndex === rowIndex &&
      selectRange.from.columnIndex === columnIndex
   ) {
      return true;
   };

   if (!selectRange.to) return false;
   return (
      isInRange(rowIndex, selectRange.from.rowIndex, selectRange.to.rowIndex) &&
      isInRange(
         columnIndex,
         selectRange.from.columnIndex,
         selectRange.to.columnIndex
      )
   );
};

const isInRange = (num, from, to) => {
   return (num - from) * (num - to) <= 0;
};

export const getRowAndColIndex = (element) => {
   const res = { rowIndex: -1, colIndex: -1 }
   for (const cls of element.classList) {
      if (cls.startsWith(CLASS_ROW_PREFIX)) {
         res.rowIndex = parseInt(cls.substring(CLASS_ROW_PREFIX.length + 1));
      };
      if (cls.startsWith(CLASS_COL_PREFIX)) {
         res.colIndex = parseInt(cls.substring(CLASS_COL_PREFIX.length + 1));
      };
   };
   return res;
};

export const getCellTarget = (event) => {
   let cellTarget = event.target;
   do {
      if (!cellTarget || (cellTarget.classList && cellTarget.classList.contains('BaseTable__row-cell'))) break;
   } while (cellTarget = cellTarget.parentNode);
   return cellTarget;
};

export const getColumnIndex = (cellTarget) => {
   let columnIndex = 0;
   // column index = index of cellTarget in parent row
   while ((cellTarget = cellTarget.previousSibling) != null) ++columnIndex;
   return columnIndex;
};

export const calculateLeftNavigate = (columnIndex, tableScrollLeft) => {
   let scrollLeft = null;
   const { cellHeader, frozenWidth, cellLeft } = getCellHeader(columnIndex);
   if (cellHeader && !isFrozen(cellHeader)) {
      if (tableScrollLeft > cellLeft - frozenWidth) {
         scrollLeft = cellLeft - frozenWidth;
      };
   };
   return scrollLeft;
};

export const calculateRightNavigate = (columnIndex, tableScrollLeft) => {
   let scrollLeft = null;
   const { cellHeader, frozenWidth, cellLeft } = getCellHeader(columnIndex);
   if (cellHeader && !isFrozen(cellHeader)) {
      const { left: cellX, width: cellWidth } = cellHeader.getBoundingClientRect();
      if (cellX + cellWidth > window.innerWidth) {
         scrollLeft = tableScrollLeft + (cellX + cellWidth - window.innerWidth);
      } else if (tableScrollLeft > cellLeft - frozenWidth) {
         scrollLeft = cellLeft - frozenWidth;
      };
   };
   return scrollLeft;
};

const getCellHeader = (columnIndex) => {
   const headerRow = document.querySelector('.BaseTable__header-row');
   if (headerRow) {
      const rowChildren = headerRow.childNodes || [];
      let frozenWidth = 0;
      let cellLeft = 0;
      for (let i = 0; i < rowChildren.length; i++) {
         const child = rowChildren[i];
         if (isFrozen(child)) {
            frozenWidth += child.offsetWidth;
         };
         if (i === columnIndex) {
            break;
         } else {
            cellLeft += child.offsetWidth;
         };
      };

      return { cellHeader: rowChildren[columnIndex], frozenWidth, cellLeft };
   };
   return { cellHeader: null };
};

const isFrozen = (cellHeader) => {
   return cellHeader && cellHeader.className.includes('BaseTable__header-cell-placeholder');
};

export const addRowIdAndColumnId = (range, rows, headers) => {
   if (range instanceof Object) {
      range.rowId = rows[range.rowIndex].id;
      range.columnKey = headers[range.columnIndex - 1]?.key;
      range.columnText = headers[range.columnIndex - 1]?.text;
   };
};

export const checkIfMiddleMouseClick = (e) => {
   return e && (e.button === 1 || e.button & 1 === 1);
};

export const checkCellOutOfView = (box, container) => {
   const { left: boxX, top: boxY, width: boxW, height: boxH } = box.getBoundingClientRect();
   const { left: containerX, top: containerYWithHeader, width: containerW, height: containerHWithHeader } = container.getBoundingClientRect();

   const headerRow = document.querySelector('.BaseTable__header');
   const { height: headerHeight } = headerRow.getBoundingClientRect();

   const containerY = containerYWithHeader + headerHeight;
   const containerH = containerHWithHeader - headerHeight;

   const buffer = 5;
   const buffScroll = 20;

   if (boxY - buffer < containerY) { // box out top
      return {
         shouldScrollY: boxY - buffScroll - containerY,
         shouldScrollX: 0
      };
   };
   if (boxY + boxH + buffer > containerY + containerH) { // box out bottom
      return {
         shouldScrollY: (boxY + boxH + buffScroll) - (containerY + containerH),
         shouldScrollX: 0
      };
   };
   if (boxX - buffer < containerX) { // box out left
      return {
         shouldScrollY: 0,
         shouldScrollX: boxX - buffScroll - containerX
      };
   };
   if (boxX + boxW + buffer > containerX + containerW) { // box out right
      return {
         shouldScrollY: 0,
         shouldScrollX: (boxX + boxW + buffScroll) - (containerX + containerW)
      };
   };
   return {
      shouldScrollY: 0,
      shouldScrollX: 0
   };
};









































//////////////////////// DASHBOARD


export const inputStackData = [
   'Approved, no resubmission required',
   'Approved with comments, no resubmission required',
   'Approved with comments, resubmission required',
   'Rejected, to resubmit',
   'Consultant reviewing',
   'Pending design',
   'Revision in progress',
   '1st cut of drawing in-progress',
   '1st cut of model in-progress',
   'Not Started',
];
const inputStackResubmit = [
   'Approved in previous version but need resubmit',
   'Rejected, to resubmit',
];

export const converToInputStack = (data) => {
   let output = [];
   data.forEach(item => {
      let arr = { ...item };
      delete arr.name;
      Object.keys(arr).forEach(stt => {
         if (inputStackData.indexOf(stt) !== -1 || inputStackResubmit.indexOf(stt) !== -1) {
            output = [...output, stt];
         };
      });
   });
   return [...new Set(output)];
};
export const sortStatusOrder = (data) => {
   const statusArr = [...data];
   let arr = [];
   inputStackData.forEach(element => {
      statusArr.forEach(e => {
         if (element === e) arr.push(element);
      });
   });
   if (arr.length === 0) return statusArr;
   return arr;
};
export const checkDiffDates = (dateInput1, dateInput2) => {
   let date1 = dateInput1;
   let date2 = dateInput2;
   if (dateInput1 && dateInput1.length === 8 && dateInput1.includes('/')) date1 = moment(dateInput1, 'DD/MM/YY').format('YYYY-MM-DD');
   if (dateInput2 && dateInput2.length === 8 && dateInput2.includes('/')) date2 = moment(dateInput2, 'DD/MM/YY').format('YYYY-MM-DD');

   if (date1 && date2) {
      return moment(date1).diff(moment(date2), 'days');
   } else if (date1 && !date2) {
      return moment(date1).diff(moment(), 'days');
   };
};
const randomInteger = (min, max) => {
   return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const createDummyRecords = () => {
   let categoryArr = [
      'Drawing Approved For Construction',
      'Drawing Approved With Comments To Resubmit',
      'Drawing Late For Approval',
      'Drawing Late For Submission',
      'Drawing Late For Construction',
   ];

   let recordArray = {};

   categoryArr.forEach(cate => {
      let arr = {};
      if (cate === 'Drawing Late For Approval' || cate === 'Drawing Late For Submission') {
         for (let i = 0; i < 100; i++) {
            if (i % 3 == 0) {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = 0;
            } else {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = randomInteger(0, 1);
            };
         };

      } else if (cate === 'Drawing Late For Construction') {

         for (let i = 0; i < 100; i++) {
            if (i % 3 == 0) {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = 0;
            } else {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = randomInteger(0, 2);
            };
         };

      } else {

         for (let i = 0; i < 100; i++) {
            if (i % 3 == 0) {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = 1;
            } else {
               arr[moment(new Date(2020, 6, 21)).add(i, 'day')._d] = randomInteger(1, 4);
            };
         };
      };
      recordArray[cate] = arr;
   });
   return recordArray;
};
const getRandomInt = (min, max) => {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};
export const recordDataToChartDaily = (data, category, month) => {
   let arr = [];
   Object.keys(data[category]).forEach((item, i) => {

      const addNos = i % 3 == 0 ? -1
         : i % 2 == 0 ? 1
            : i % 5 == 0 ? 2 : 0;

      const date = moment(item).add(-1, 'day');
      if (date.format('MM/YY') === month) {
         arr.push({
            date: date.format('DD'),
            value: data[category][item],
            target: data[category][item] + addNos
         });
      };
   });
   return arr;
};
export const recordDataToChartWeekly = (data, category) => {
   let arr = [];
   Object.keys(data[category]).forEach((item, i) => {

      const addNos = i % 3 == 0 ? -1
         : i % 5 == 0 ? 1
            : i % 7 == 0 ? 2 : 0;

      const date = moment(item).add(-1, 'day');
      arr.push({
         week: date.format('W'),
         month: date.format('MM'),
         year: date.format('YY'),
         value: data[category][item],
         target: data[category][item] + addNos
      });
   });

   let groups = {};
   for (let i = 0; i < arr.length; i++) {
      let weekName = `W${arr[i].week} ${arr[i].month}/${arr[i].year}`;
      groups[weekName] = {};
   };


   for (let i = 0; i < arr.length; i++) {
      let weekName = `W${arr[i].week} ${arr[i].month}/${arr[i].year}`;
      groups[weekName].value = [...groups[weekName].value || [], arr[i].value];
      groups[weekName].target = [...groups[weekName].target || [], arr[i].target];
   };

   let arrOutput = [];
   for (let week in groups) {
      arrOutput.push({
         week,
         value: groups[week].value.reduce((a, b) => a + b, 0),
         target: groups[week].target.reduce((a, b) => a + b, 0),
      });
   };
   return arrOutput;
};
export const recordDataToChartMonthly = (data, category) => {
   let arr = [];
   Object.keys(data[category]).forEach((item, i) => {

      const addNos = i % 4 == 0 ? -4
         : i % 3 == 0 ? 3
            : i % 2 == 0 ? 2 : 0;

      const date = moment(item).add(-1, 'day');
      arr.push({
         week: date.format('W'),
         month: date.format('MM'),
         year: date.format('YY'),
         value: data[category][item],
         target: data[category][item] + getRandomInt(-3, 3)
      });
   });
   let groups = {};
   for (let i = 0; i < arr.length; i++) {
      let monthName = `${arr[i].month}/${arr[i].year}`;
      groups[monthName] = {};
   };

   for (let i = 0; i < arr.length; i++) {
      let monthName = `${arr[i].month}/${arr[i].year}`;
      groups[monthName].value = [...groups[monthName].value || [], arr[i].value];
      groups[monthName].target = [...groups[monthName].target || [], arr[i].target];
   };

   let arrOutput = [];
   for (let month in groups) {
      arrOutput.push({
         month,
         value: groups[month].value.reduce((a, b) => a + b, 0),
         target: groups[month].target.reduce((a, b) => a + b, 0),
      });
   };
   return arrOutput;
};


const getUniqueValueByColumns = (rows, header) => {
   let valueArr = [];
   rows.forEach(row => valueArr.push(row[header]));
   return [...new Set(valueArr)];
};
const countDrawingsByColumnAndStatus = (rows, column) => {
   let valueArray = getUniqueValueByColumns(rows, column).sort();

   let arrCount = [];
   let objDrawings = {};
   valueArray.forEach(columnValue => {
      if (columnValue !== 'NOT ASSIGNED') {
         let rowsFilter = rows.filter(r => r[column] === columnValue);
         let obj = {};
         let objDwgs = {};
         rowsFilter.forEach(r => {
            obj[r.Status] = (obj[r.Status] || 0) + 1;
            objDwgs[r.Status] = [...objDwgs[r.Status] || [], r];
         });
         obj.name = columnValue;
         arrCount.push(obj);
         objDrawings[columnValue] = objDwgs;
      };
   });

   let rowsFilterNA = rows.filter(r => r[column] === 'NOT ASSIGNED');
   let objNA = {};
   let objDwgsNA = {};
   rowsFilterNA.forEach(r => {
      objNA[r.Status] = (objNA[r.Status] || 0) + 1;
      objDwgsNA[r.Status] = [...objDwgsNA[r.Status] || [], r];
   });
   objNA.name = 'NOT ASSIGNED';

   arrCount.unshift(objNA);
   objDrawings['NOT ASSIGNED'] = objDwgsNA;


   return { arrCount, objDrawings };
};




export const convertDataFromDB = (data, dataRowHistories, projectsArray) => {

   let output = {
      projectSplit: [],
   };

   const arrComparison = ['OVERALL', 'WH - ARCHI', 'WH - C&S', 'WH - M&E', 'WH - PRECAST', 'SUBCON'].map(item => ({
      name: item,
      data: []
   }));


   data.forEach(projectData => {

      let { publicSettings: { headers, drawingTypeTree }, rows: rowsAllInProject, _id } = projectData;

      rowsAllInProject = rowsAllInProject.filter(x => x['Drawing Number'] || x['Drawing Name']);

      rowsAllInProject.forEach(r => {
         if (!r.Rev) r.Rev = '0';
         r.Rev = r.Rev.toUpperCase();

         if (!r.Status) r.Status = 'Not Started';

         if (r.Status === 'Revise In-Progress') {
            r.Status = 'Revision in progress';
         } else if (r.Status === 'Reject and resubmit') {
            r.Status = 'Rejected, to resubmit';
         } else if (r.Status === 'Approved with comments, to Resubmit') {
            r.Status = 'Approved with comments, resubmission required';
         } else if (r.Status === 'Approved with Comment, no submission Required') {
            r.Status = 'Approved with comments, no resubmission required';
         } else if (r.Status === 'Approved for Construction') {
            r.Status = 'Approved, no resubmission required';
         };


         if (!r['Modeller']) r['Modeller'] = 'Not assigned';
         r['Modeller'] = r['Modeller'].toUpperCase();

         if (!r['Coordinator In Charge']) r['Coordinator In Charge'] = 'Not assigned';
         r['Coordinator In Charge'] = r['Coordinator In Charge'].toUpperCase();
      });

      const headersArrayText = headers.map(x => x.text);
      const projectName = projectsArray.find(dt => dt.id === _id).name;


      const historiesThisProject = dataRowHistories.find(x => x.projectId === _id).histories || [];

      const dataRowHistoriesThisProject = converHistoryData(historiesThisProject, headers);
      const dataInfoOverAll = convertToInputDataForChart(rowsAllInProject, dataRowHistoriesThisProject, headersArrayText);
      let projectOutput = [{ panel: 'OVERALL', dataInfo: dataInfoOverAll }];

      const found = arrComparison.find(x => x.name === 'OVERALL');

      if (rowsAllInProject.length > 0) {
         found.data.push({
            projectName,
            projectId: _id,
            compareDrawingStatus: dataInfoOverAll.pieDrawingStatusCount,

            compareDrawingsLateSubmission: dataInfoOverAll.drawingsLateSubmission.length,
            compareDrawingsLateApproval: dataInfoOverAll.drawingsLateApproval.length,
            compareDrawingsLateStart: dataInfoOverAll.drawingsLateStart.length,
            compareDrawingsLateConstruction: dataInfoOverAll.drawingsLateConstruction.length,
         });
      };


      let objTradeStatus = {};
      let arrTradeCount = [];
      const wohhupNode = drawingTypeTree.find(x => x.treeLevel === 1 && x.title === 'Woh Hup Private Ltd');
      if (wohhupNode) {

         tradeArrayForm.forEach(trade => {
            const tradeNode = drawingTypeTree.find(x => {
               return x.treeLevel === 2 && x.title === trade && x.parentId === wohhupNode.id;
            });
            if (tradeNode) {
               const allNodesUnderThisTrade = getTreeFlattenOfNodeInArray(drawingTypeTree, tradeNode);
               const allIdsNode = [...new Set(allNodesUnderThisTrade.map(x => x.id))];
               const rowsInThisTrade = rowsAllInProject.filter(x => allIdsNode.find(id => id === x._parentRow));


               const rowsHistoriesThisTrade = dataRowHistoriesThisProject.filter(r => rowsInThisTrade.find(x => x._id === r.row));

               const dataInfoThisTrade = convertToInputDataForChart(rowsInThisTrade, rowsHistoriesThisTrade, headersArrayText);

               projectOutput.push({
                  panel: 'WH - ' + trade,
                  dataInfo: dataInfoThisTrade
               });

               const foundTrade = arrComparison.find(x => x.name === 'WH - ' + trade);

               if (foundTrade && rowsAllInProject.length > 0) {
                  foundTrade.data.push({
                     projectName,
                     projectId: _id,
                     compareDrawingStatus: dataInfoThisTrade.pieDrawingStatusCount,

                     compareDrawingsLateSubmission: dataInfoThisTrade.drawingsLateSubmission.length,
                     compareDrawingsLateApproval: dataInfoThisTrade.drawingsLateApproval.length,
                     compareDrawingsLateStart: dataInfoThisTrade.drawingsLateStart.length,
                     compareDrawingsLateConstruction: dataInfoThisTrade.drawingsLateConstruction.length,
                  });
               };

               const { objCount: objCountTrade, objDrawings: rowsTradeSplitStatus } = splitRowsStatusByTrade(rowsInThisTrade, 'WH - ' + trade);
               objTradeStatus['WH - ' + trade] = rowsTradeSplitStatus;
               arrTradeCount.push(objCountTrade);
            };
         });
      };


      const subconGroup = drawingTypeTree.filter(x => x.treeLevel === 1 && x.title === 'SUBCON');
      let allIsSubconAndUnder = [];
      subconGroup.forEach(sb => {
         const allNodesUnderThisSubcon = getTreeFlattenOfNodeInArray(drawingTypeTree, sb);
         const allIdsNode = [...new Set(allNodesUnderThisSubcon.map(x => x.id))];
         allIsSubconAndUnder = [...allIsSubconAndUnder, ...allIdsNode];
      });
      allIsSubconAndUnder = [...new Set(allIsSubconAndUnder)];
      const rowsOfSubcon = rowsAllInProject.filter(x => allIsSubconAndUnder.find(id => id === x._parentRow));
      const rowsHistoriesSubcon = dataRowHistoriesThisProject.filter(r => rowsOfSubcon.find(x => x._id === r.row));
      const dataInfoSubcon = convertToInputDataForChart(rowsOfSubcon, rowsHistoriesSubcon, headersArrayText);
      projectOutput.push({
         panel: 'SUBCON',
         dataInfo: dataInfoSubcon
      });

      const foundSubcon = arrComparison.find(x => x.name === 'SUBCON');

      if (foundSubcon && rowsAllInProject.length > 0) {
         foundSubcon.data.push({
            projectName,
            projectId: _id,
            compareDrawingStatus: dataInfoSubcon.pieDrawingStatusCount,

            compareDrawingsLateSubmission: dataInfoSubcon.drawingsLateSubmission.length,
            compareDrawingsLateApproval: dataInfoSubcon.drawingsLateApproval.length,
            compareDrawingsLateStart: dataInfoSubcon.drawingsLateStart.length,
            compareDrawingsLateConstruction: dataInfoSubcon.drawingsLateConstruction.length,
         });
      };


      const { objCount: objCountSubcon, objDrawings: rowsSubconSplitStatus } = splitRowsStatusByTrade(rowsOfSubcon, 'SUBCON');
      objTradeStatus['SUBCON'] = rowsSubconSplitStatus;
      arrTradeCount.push(objCountSubcon);

      const overAllObj = projectOutput.find(x => x.panel === 'OVERALL');
      overAllObj.dataInfo['Bar Drawing Trade'] = objTradeStatus;
      overAllObj.dataInfo['barDrawingTradeCount'] = arrTradeCount;


      output.projectSplit.push({
         projectId: _id,
         projectName,
         dataProject: projectOutput
      });
   });

   output.projectComparison = arrComparison;
   return output;
};

const convertToInputDataForChart = (rows, rowsHistory, headers) => {

   let inputStack = getUniqueValueByColumns(rows, 'Status');

   const { arrCount: barDrawingRevCount, objDrawings: barDrawingRevDrawings } = countDrawingsByRevAndStatus(rows);
   let { arrCount: barDrawingModellerCount, objDrawings: barDrawingModellerDrawings } = countDrawingsByColumnAndStatus(rows, 'Modeller');
   let { arrCount: barDrawingCoordinatorCount, objDrawings: barDrawingCoordinatorDrawings } = countDrawingsByColumnAndStatus(rows, 'Coordinator In Charge');

   let itemNoData1 = barDrawingModellerCount.filter(x => x.name === 'Not assigned');
   let itemRest1 = barDrawingModellerCount.filter(x => x.name !== 'Not assigned');
   barDrawingModellerCount = [...itemNoData1, ...itemRest1];

   let itemNoData2 = barDrawingCoordinatorCount.filter(x => x.name === 'Not assigned');
   let itemRest2 = barDrawingCoordinatorCount.filter(x => x.name !== 'Not assigned');
   barDrawingCoordinatorCount = [...itemNoData2, ...itemRest2];



   let pieDrawingStatusCount = {};
   let pieDrawingStatusDrawings = {};
   inputStack.forEach(stt => {
      let rowArr = rows.filter(r => r.Status === stt);
      rowArr.forEach(r => {
         pieDrawingStatusCount[stt] = (pieDrawingStatusCount[stt] || 0) + 1;
      });
      pieDrawingStatusDrawings[stt] = rowArr;
   });


   const drawingsLateSubmission = getDrawingLateNow1(rows, 'drawingsLateSubmission');
   const drawingsLateApproval = getDrawingLateNow1(rows, 'drawingsLateApproval');
   const drawingsLateStart = getDrawingLateNow1(rows, 'drawingsLateStart');
   const drawingsLateConstruction = getDrawingLateNow1(rows, 'drawingsLateConstruction');

   const { rowsToSubmitTarget: rowsToSubmitTargetWeek, rowsToSubmitActual: rowsToSubmitActualWeek } = getDrawingsToSubmitBy(rows, 'week');
   const { rowsToSubmitTarget: rowsToSubmitTargetMonth, rowsToSubmitActual: rowsToSubmitActualMonth } = getDrawingsToSubmitBy(rows, 'month');



   const revArray = ['0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];
   const allDwgsToResubmit = rows.filter(x => {
      return x['Status'] === 'Approved with comments, resubmission required' || x['Status'] === 'Rejected, to resubmit';
   });


   let objData = {};
   allDwgsToResubmit.forEach(r => {
      const columnIndex = revArray.indexOf(r['Rev'] || '0') + 1;
      objData[columnIndex] = [...objData[columnIndex] || [], r];
   });


   let barDrawingResubmitDrawings = {};
   let barDrawingResubmitCount = [];

   Object.keys(objData).forEach(cl => {
      const rows = objData[cl];
      let rejectToResubmit = [];
      let approvedPreviousVersion = [];
      rows.forEach(row => {
         const histories = rowsHistory.filter(r => r.row === row.id);
         const found = histories.find(x => x['Status'] === 'Approved, no resubmission required' || x['Status'] === 'Approved with comments, no resubmission required');
         if (found) {
            approvedPreviousVersion.push(row);
         } else {
            rejectToResubmit.push(row);
         };
      });
      barDrawingResubmitCount.push({
         'Approved in previous version but need resubmit': approvedPreviousVersion.length,
         'Rejected, to resubmit': rejectToResubmit.length,
         name: cl
      });
      barDrawingResubmitDrawings[cl] = {
         'Approved in previous version but need resubmit': approvedPreviousVersion,
         'Rejected, to resubmit': rejectToResubmit
      };
   });


   return {
      rows,
      headers,

      'Bar Drawing Rev': barDrawingRevDrawings,
      barDrawingRevCount,
      'Bar Drawing Modeller': barDrawingModellerDrawings,
      barDrawingModellerCount,
      'Bar Drawing Coordinator': barDrawingCoordinatorDrawings,
      barDrawingCoordinatorCount,
      'Bar Drawing Resubmit': barDrawingResubmitDrawings,
      barDrawingResubmitCount,


      'Pie Drawing Status': pieDrawingStatusDrawings,
      pieDrawingStatusCount,


      drawingsLateSubmission,
      drawingsLateApproval,
      drawingsLateStart,
      drawingsLateConstruction,
      rowsToSubmitTargetWeek,
      rowsToSubmitActualWeek,
      rowsToSubmitTargetMonth,
      rowsToSubmitActualMonth,
   };
};

const countDrawingsByRevAndStatus = (rows) => {
   let valueArray = getUniqueValueByColumns(rows, 'Rev').sort();
   valueArray.unshift('NS');

   let arrCount = [];
   let objDrawings = {};
   valueArray.forEach(columnValue => {
      let obj = {};
      let objDwgs = {};
      let rowsFilter;
      if (columnValue !== 'NS') {
         rowsFilter = rows.filter(r => {
            return r['Rev'] === columnValue &&
               r.Status !== 'Not Started' &&
               r.Status !== '1st cut of model in-progress' &&
               r.Status !== '1st cut of drawing in-progress';
         });
      } else {
         rowsFilter = rows.filter(r => {
            return r.Status === 'Not Started' ||
               r.Status === '1st cut of model in-progress' ||
               r.Status === '1st cut of drawing in-progress';
         });
      };
      rowsFilter.forEach(r => {
         obj[r.Status] = (obj[r.Status] || 0) + 1;
         objDwgs[r.Status] = [...objDwgs[r.Status] || [], r];
      });
      obj.name = columnValue;
      arrCount.push(obj);
      objDrawings[columnValue] = objDwgs;
   });
   return { arrCount, objDrawings };
};

const converHistoryData = (rowsHistory, headers) => {
   return rowsHistory.map(rowH => {
      let obj = {
         row: rowH.row
      };
      const { history } = rowH;
      if (history) {
         headers.forEach(hd => {
            if (history[hd.key]) obj[hd.text] = history[hd.key];
         });
      };
      return obj;
   });
};

const splitRowsStatusByTrade = (rows, title) => {
   const statusArray = [...new Set(rows.map(x => x['Status']))];
   let obj = {};
   let objCount = {};
   statusArray.forEach(stt => {
      const rowsFound = rows.filter(r => r['Status'] === stt);
      obj[stt] = rowsFound;
      objCount[stt] = rowsFound.length;
   });
   return {
      objDrawings: obj,
      objCount: { ...objCount, name: title }
   }
};

export const getRandomIntInclusive = (min, max) => {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min + 1) + min);
};

const getDrawingLateNow1 = (drawings, type) => {
   const conditionArray1 = [
      'Approved, no resubmission required',
      'Approved with comments, no resubmission required',
      'Consultant reviewing'
   ];
   const conditionArray2 = [
      'Approved, no resubmission required',
      'Approved with comments, no resubmission required',
   ];

   let rowsLateOutput;



   if (type === 'drawingsLateStart') {
      rowsLateOutput = drawings.filter(r => {
         return conditionArray1.indexOf(r.Status) === -1 &&
            r['Drawing Start (T)'] && checkDiffDates(r['Drawing Start (T)']) < 0 &&
            (
               !r['Drawing Start (A)'] ||
               (r['Drawing Start (A)'] && r['Drawing Start (T)'] && checkDiffDates(r['Drawing Start (A)'], r['Drawing Start (T)']) > 0)
            );
      });
   } else if (type === 'drawingsLateSubmission') {
      rowsLateOutput = drawings.filter(r => {
         return conditionArray1.indexOf(r.Status) === -1 &&
            r['Drg To Consultant (T)'] &&
            checkDiffDates(r['Drg To Consultant (T)']) < 0 &&
            !r['Drg To Consultant (A)'];
      });
   } else if (type === 'drawingsLateApproval') {
      rowsLateOutput = drawings.filter(r => {
         return conditionArray2.indexOf(r.Status) === -1 &&
            r['Get Approval (T)'] && checkDiffDates(r['Get Approval (T)']) < 0 &&
            (
               !r['Get Approval (A)'] ||
               (r['Get Approval (A)'] && r['Get Approval (T)'] && checkDiffDates(r['Get Approval (A)'], r['Get Approval (T)']) > 0)
            );
      });
   } else if (type === 'drawingsLateConstruction') {
      rowsLateOutput = drawings.filter(r => {
         return conditionArray2.indexOf(r.Status) === -1 &&
            r['Construction Start'] &&
            checkDiffDates(r['Construction Start']) < 0 &&
            (
               !r['Drg To Consultant (A)'] ||
               !r['Get Approval (A)'] ||
               (r['Get Approval (A)'] && r['Construction Start'] && checkDiffDates(r['Get Approval (A)'], r['Construction Start']) > 0)
            );
      });
   };

   return rowsLateOutput;
};

export const getfirstAndLastDayOf = (duration) => {

   const firstday = moment(moment().startOf(duration).toDate()).format('DD/MM/YY');
   const lastday = moment(moment().endOf(duration).toDate()).format('DD/MM/YY');

   return { firstday, lastday };
};

const getDrawingsToSubmitBy = (rows, duration) => {
   let firstday, lastday;
   if (duration === 'week') {
      const { firstday: first, lastday: last } = getfirstAndLastDayOf('week');
      firstday = first;
      lastday = last;
   } else if (duration === 'month') {
      const { firstday: first, lastday: last } = getfirstAndLastDayOf('month');
      firstday = first;
      lastday = last;
   };

   const rowsToSubmitTarget = rows.filter(r => {
      return r['Drg To Consultant (T)'] &&
         checkDiffDates(r['Drg To Consultant (T)'], firstday) >= 0 &&
         checkDiffDates(r['Drg To Consultant (T)'], lastday) <= 0;
   });

   const rowsToSubmitActual = rowsToSubmitTarget.filter(r => {
      return r['Drg To Consultant (A)'] &&
         checkDiffDates(r['Drg To Consultant (A)'], firstday) >= 0 &&
         checkDiffDates(r['Drg To Consultant (A)'], lastday) <= 0;
   });

   return {
      rowsToSubmitTarget,
      rowsToSubmitActual
   };
};





export const configExcelWidth = (hd) => {
   return hd === 'RFA Ref' ? 40
      : hd === 'Rev' ? 10
         : hd === 'Drawing Number' ? 30
            : hd === 'Drawing Name' ? 80
               : 25;
};



const getRowOutputObj = (headersShown, row, rfaData) => {
   let rowObj = {};
   headersShown.forEach(hd => {
      if (hd === 'Issue For Construction') {
         rowObj[hd] = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'issueForConstruction') || '';
      } else if (hd === 'IFC Rev') {
         rowObj[hd] = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'ifcVersion') || '';
      } else if (hd === 'IFC Date') {
         const date = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'date');
         rowObj[hd] = date ? moment(date).format('DD/MM/YY') : '';
      } else if (row[hd]) {
         rowObj[hd] = row[hd];
      };
   });

   const rfaFound = rfaData.find(x => x['rfaRef'] === row['rfaNumber'] && getRefStringWithVersion(x, 'rfa') === row['RFA Ref']);
   if (rfaFound) {
      const consultantMustReply = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [];
      consultantMustReply.forEach((cmp, i) => {
         const status = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', cmp);
         const date = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'date', cmp);
         rowObj[`Consultant (${i + 1})`] = status ? `${cmp} - (${moment(date).format('DD/MM/YY')})` : cmp;
         rowObj[`status (${i + 1})`] = status || '';
      });
   }

   return rowObj;
};



export const getDataToExportReport = ({
   rowsCurrentInView: rowsToExport,
   drawingTypeTree, isIncludeDrawingDetail, pageSheetTypeName, headersShown, rfaData, companies, isForExcelExport, overdueLeadConsultantOnly
}) => {


   const refType = getKeyTextForSheet(pageSheetTypeName);
   let output = [];
   let arrayIndexOfDwgTypeTree = [];
   let arrayIndexOfDwgDarkerTypeTree = [];
   let statusAllCellObject = {};
   let sumStatusOutput = {};
   let dueDrawingOutput = {
      'Overdue': [],
      'Due in the next 1 - 3 days': [],
      'Due in the next 4 - 14 days': [],
   };


   let drawingTypeTreeSorted = [];
   drawingTypeTree.forEach(node => {
      let itemClone = { ...node };
      const checkResult = checkIfThereIsRowInsideTreeNode(itemClone, drawingTypeTree, rowsToExport, pageSheetTypeName);
      if (checkResult) {
         drawingTypeTreeSorted.push(itemClone);
      };
   });
   drawingTypeTreeSorted = drawingTypeTreeSorted.sort((a, b) => a.treeLevel - b.treeLevel);
   drawingTypeTreeSorted = sortDrawingTypeTreeForExportFormat(drawingTypeTreeSorted);


   if (pageSheetTypeName === 'page-spreadsheet') {

      if (drawingTypeTreeSorted.length > 0) {

         drawingTypeTreeSorted.forEach(node => {
            let itemClone = { ...node };
            let obj = {};
            headersShown.forEach(hd => {
               obj[hd] = '';
            });
            obj['Drawing Number'] = itemClone.title;
            obj.treeLevel = itemClone.treeLevel;

            output.push(obj);
            const rowChildren = rowsToExport.filter(x => x._parentRow === itemClone.id);

            rowChildren.forEach(row => {
               const rowObj = getRowOutputObj(headersShown, row, rfaData);
               output.push(rowObj);
            });
         });
      } else {
         rowsToExport.forEach(row => {
            const rowObj = getRowOutputObj(headersShown, row, rfaData);
            output.push(rowObj);
         });
      };

      output.forEach((row, i) => {
         if (row.treeLevel) {
            arrayIndexOfDwgTypeTree.push(i);
            [
               'Consultant (1)',
               'Consultant (2)',
               'Consultant (3)',
               'Consultant (4)',
               'Consultant (5)',
               'Consultant (6)',
               'Consultant (7)',
            ].forEach(item => {
               row[item] = '';
            });
         } else {
            for (const key in row) {
               if (headersConsultantWithNumber.indexOf(key) !== -1) {
                  const consultantNumber = key.slice(12, key.length - 1);
                  const headerIndex = headersShown.length + parseInt(consultantNumber) - 2;

                  if (row[`status (${consultantNumber})`]) {
                     statusAllCellObject[i] = {
                        ...statusAllCellObject[i] || {},
                        [key]: row[`status (${consultantNumber})`],
                        [headerIndex]: row[`status (${consultantNumber})`]
                     };
                  };
               };
            };
         };
      });


   } else if (pageSheetTypeName === 'page-rfa') {

      drawingTypeTreeSorted.forEach(node => {
         let itemClone = { ...node };

         let obj = {};
         headersShown.forEach(hd => {
            obj[hd] = '';
         });
         obj['RFA Ref'] = itemClone.title;
         obj.treeLevel = itemClone.treeLevel;

         if (itemClone['btn'] && itemClone['btn'].length > 0) {
            const btn = node.btn.sort();
            const refWithVersion = node.rfaNumber + (btn[btn.length - 1] === '0' ? '' : btn[btn.length - 1]);
            obj['RFA Ref'] = refWithVersion;

            let rowsChildren = rowsToExport.filter(r => r.rfaNumber === node.rfaNumber);
            const oneRowInLatestVersionRfa = rowsChildren.find(r => {
               return r['RFA Ref'] === refWithVersion;
            }) || {};

            const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === refWithVersion) || {};
            const consultantMustReply = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [];
            const consultantLead = consultantMustReply[0];
            const isNotRepliedYet = checkFormIfRepliedOrNot({ refType: 'rfa', row: oneRowInLatestVersionRfa, overdueLeadConsultantOnly, rfaData: rfaFound });

            if (isNotRepliedYet) {
               obj.isAlreadyReplied = false;
               obj['Due Date'] = moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'due')).format('DD/MM/YY');
               obj['Requested By'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'requestedBy');
               obj['Submission Date'] = moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'date')).format('DD/MM/YY');
               obj['Drawing Number'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'emailTitle');
               if (!isIncludeDrawingDetail) {
                  obj['Description'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'emailTitle');
               };
            } else {
               obj.isAlreadyReplied = true;
               obj['Due Date'] = moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'due')).format('DD/MM/YY');
               obj['Requested By'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'requestedBy');
               obj['Submission Date'] = moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'date')).format('DD/MM/YY');
               obj['Drawing Number'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'emailTitle');
               if (!isIncludeDrawingDetail) {
                  obj['Description'] = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'emailTitle');
               };
            };

            headersConsultantWithNumber.forEach(hdCon => {
               const objStatus = {};
               const numberOfConsultant = hdCon.slice(10, hdCon.length);

               let replyCompanyShortName;
               rowsChildren.forEach(r => {
                  const { replyStatus, replyCompany } = getConsultantReplyData(rfaFound, r, hdCon);

                  if (!objStatus['statusInfo']) {
                     objStatus['statusInfo'] = {};
                  };
                  if (replyCompany) {

                     const compFound = companies.find(cm => cm['company'] === replyCompany) || {};

                     replyCompanyShortName = compFound['shortName'] || getCompanyNameShort(replyCompany);
                     replyCompanyShortName = replyCompanyShortName.slice(0, 3);

                     objStatus['company'] = replyCompanyShortName;
                     if (replyStatus) {
                        objStatus['statusInfo'][replyStatus] = (objStatus['statusInfo'][replyStatus] || 0) + 1;
                     } else {
                        objStatus['statusInfo']['Consultant reviewing'] = (objStatus['statusInfo']['Consultant reviewing'] || 0) + 1;
                     };
                  };
               });
               obj[`status${numberOfConsultant}`] = objStatus;

               const { replyDate: replyDateHeaderRow } = getConsultantReplyData(rfaFound, oneRowInLatestVersionRfa, hdCon);
               obj[hdCon] = replyDateHeaderRow ? `${replyCompanyShortName} - ${replyDateHeaderRow}` : replyCompanyShortName;
            });

            output.push(obj);

            rowsChildren = _.sortBy(rowsChildren, ['Rev', 'Drawing Number']);


            let sumStatusInThisRef = {}

            rowsChildren.forEach(row => {
               const isNotRepliedYet = checkFormIfRepliedOrNot({ refType: 'rfa', row, overdueLeadConsultantOnly, rfaData: rfaFound });
               if (consultantLead) {

                  if (isNotRepliedYet) {
                     const dueDate = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'due');
                     const nosOfDate = compareDatesForm(dueDate);

                     if (nosOfDate < 0) {
                        if (dueDrawingOutput['Overdue'].indexOf(row['RFA Ref']) === -1) {
                           dueDrawingOutput['Overdue'].push(row['RFA Ref']);
                        };
                     } else if (nosOfDate >= 0 && nosOfDate < 3) {
                        if (dueDrawingOutput['Due in the next 1 - 3 days'].indexOf(row['RFA Ref']) === -1) {
                           dueDrawingOutput['Due in the next 1 - 3 days'].push(row['RFA Ref']);
                        };
                     } else if (nosOfDate >= 3) {
                        if (dueDrawingOutput['Due in the next 4 - 14 days'].indexOf(row['RFA Ref']) === -1) {
                           dueDrawingOutput['Due in the next 4 - 14 days'].push(row['RFA Ref']);
                        };
                     };
                  };

                  let objConsultant = {};

                  headersConsultantWithNumber.forEach(hdCon => {
                     const { replyStatus, replyCompany, replyDate } = getConsultantReplyData(rfaFound, row, hdCon);
                     const numberOfConsultant = hdCon.slice(10, hdCon.length);
                     if (replyCompany) {

                        const compFound = companies.find(cm => cm['company'] === replyCompany) || {};
                        const companyNameDisplay = compFound['shortName'] || replyCompany;

                        objConsultant = {
                           ...objConsultant,
                           [hdCon]: replyStatus ? `${companyNameDisplay} - (${replyDate})` : companyNameDisplay,
                           [`status${numberOfConsultant}`]: replyStatus
                        }
                     };
                  });


                  if (isIncludeDrawingDetail) {

                     let dtRef = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'issueForConstruction') || '';
                     const allParts = dtRef.split('/');
                     let lengthDeduce = 0;
                     if (allParts[0] && allParts[1]) {
                        lengthDeduce = (allParts[0] + '/' + allParts[1] + '/').length;
                     };

                     const dateIFC = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'date');

                     output.push({
                        'RFA Ref': row['RFA Ref'],
                        'Rev': row['Rev'],
                        'Drawing Number': row['Drawing Number'],
                        'Drawing Name': row['Drawing Name'],
                        'Requested By': getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'requestedBy'),
                        'Submission Date': row['Drg To Consultant (A)'],
                        'Due Date': row['Consultant Reply (T)'],
                        'Issue For Construction': isForExcelExport ? dtRef : dtRef.slice(lengthDeduce, dtRef.length),
                        'IFC Date': dateIFC ? moment(dateIFC).format('DD/MM/YY') : '',
                        'IFC Rev': getInfoValueFromRefDataForm(row, 'submission', 'dt', 'ifcVersion') || '',
                        ...objConsultant,
                     });
                  };
               };
            });

            let sumStatusInThisRefOutput = [];

            statusOrderArray.forEach(stt => {
               if (sumStatusInThisRef[stt]) {
                  sumStatusInThisRefOutput.push({
                     name: stt,
                     count: sumStatusInThisRef[stt],
                     percentage: `${sumStatusInThisRef[stt] / rowsChildren.length * 100}%`
                  });
               };
            });

            sumStatusOutput[refWithVersion] = sumStatusInThisRefOutput;

         } else {
            output.push(obj);
         };
      });

      const dueDateColumnIndex = headersShown.indexOf('Due Date');

      output.forEach((row, i) => {

         for (const key in row) {
            if (headersConsultantWithNumber.indexOf(key) !== -1) {
               const consultantNumber = key.slice(12, key.length - 1);
               const headerIndex = headersShown.indexOf(key);

               const objExcel = row.treeLevel
                  ? {}
                  : { [headerIndex]: row[`status (${consultantNumber})`] }

               if (row[`status (${consultantNumber})`]) {
                  statusAllCellObject[i] = {
                     ...statusAllCellObject[i] || {},
                     [key]: row[`status (${consultantNumber})`],
                     ...objExcel
                  };
               };
            };
         };


         if (row.treeLevel) {
            if (row['RFA Ref'].includes('/')) {
               arrayIndexOfDwgTypeTree.push(i);
            } else {
               arrayIndexOfDwgDarkerTypeTree.push(i);
            };


            if (row['Due Date'] && !row.isAlreadyReplied) {
               const dueDate = row['Due Date'];
               const nosOfDate = compareDatesForm(moment(dueDate, 'DD/MM/YY'));
               if (nosOfDate < 0) {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Overdue'
                  };
               } else if (nosOfDate >= 0 && nosOfDate < 3) {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Due in the next 1 - 3 days'
                  };
               } else {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Due in the next 4 - 14 days'
                  };
               };
            };

         } else {
            if (!checkIfStringContainsDate(row['Consultant (1)'])) {
               const dueDate = row['Due Date'];
               const nosOfDate = compareDatesForm(moment(dueDate, 'DD/MM/YY'));

               if (nosOfDate < 0) {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Overdue'
                  };
               } else if (nosOfDate >= 0 && nosOfDate < 3) {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Due in the next 1 - 3 days'
                  };
               } else {
                  statusAllCellObject[i] = {
                     ...(statusAllCellObject[i] || {}),
                     [dueDateColumnIndex]: 'Due in the next 4 - 14 days'
                  };
               };
            };
         };

         headersConsultantWithNumber.forEach(hd => {
            const consultantNumber = hd.slice(12, hd.length - 1);
            delete row[`status (${consultantNumber})`];
         });
      });
   } else {

      drawingTypeTreeSorted.forEach(node => {
         let itemClone = { ...node };

         let obj = {};
         headersShown.forEach(hd => {
            obj[hd] = '';
         });
         obj[`${refType.toUpperCase()} Ref`] = itemClone.title;
         obj.treeLevel = itemClone.treeLevel;

         output.push(obj);

         const rowsChildren = rowsToExport.filter(r => {
            if (pageSheetTypeName === 'page-rfc') {
               const nodeId = itemClone.id;
               const nodeIdParts = nodeId.split('/');
               if (nodeIdParts.length >= 2) {
                  const part1 = nodeIdParts[0];
                  const part2 = nodeIdParts[1];
                  const partRemaining = nodeId.slice(part1.length + 1 + part2.length + 1, nodeId.length);
                  const nodeIdFixed = partRemaining ? `${part2}/${part1}/${partRemaining}` : `${part2}/${part1}`;
                  return getDisciplineOfRefString(r[`${refType}Ref`]) === nodeIdFixed;
               };
            } else {
               return getDisciplineOfRefString(r[`${refType}Ref`]) === itemClone.id;
            };
         });


         rowsChildren.forEach(row => {

            if (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') {

               const isNotRepliedYet = checkFormIfRepliedOrNot({ refType, row, overdueLeadConsultantOnly });
               if (isNotRepliedYet) {
                  const dueDate = getInfoValueFromRefDataForm(row, 'submission', refType, 'due');
                  const nosOfDate = compareDatesForm(dueDate);

                  if (nosOfDate < 0) {
                     if (dueDrawingOutput['Overdue'].indexOf(getRefStringWithVersion(row, refType)) === -1) {
                        dueDrawingOutput['Overdue'].push(getRefStringWithVersion(row, refType));
                     };
                  } else if (nosOfDate >= 0 && nosOfDate < 3) {
                     if (dueDrawingOutput['Due in the next 1 - 3 days'].indexOf(getRefStringWithVersion(row, refType)) === -1) {
                        dueDrawingOutput['Due in the next 1 - 3 days'].push(getRefStringWithVersion(row, refType));
                     };
                  } else if (nosOfDate >= 3) {
                     if (dueDrawingOutput['Due in the next 4 - 14 days'].indexOf(getRefStringWithVersion(row, refType)) === -1) {
                        dueDrawingOutput['Due in the next 4 - 14 days'].push(getRefStringWithVersion(row, refType));
                     };
                  };
               };
            };


            if (isIncludeDrawingDetail) {
               let objChildren = {
                  [`${refType.toUpperCase()} Ref`]: getRefStringWithVersion(row, refType),
               };

               headersShown.forEach(hd => {
                  if (hd !== `${refType.toUpperCase()} Ref`) {

                     const data = getInfoValueFromRefDataForm(row, 'submission', refType, getItemKeyNameForm(hd));

                     if (hd === 'IFC Date') {
                        const dateIFC = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'date');
                        objChildren[hd] = dateIFC ? moment(dateIFC).format('DD/MM/YY') : '';

                     } else if (hd.includes(' Date')) {
                        objChildren[hd] = moment(data).format('DD/MM/YY');
                     } else if (hd === 'Received By') {
                        objChildren[hd] = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || []).join(' | ');
                     } else if (hd === 'Tags') {
                        objChildren[hd] = (getInfoValueFromRefDataForm(row, 'submission', refType, 'listTagForDtForm') || []).join(' | ');
                     } else if (hd === 'RFA Info' || hd === 'RFAM Info') {
                        objChildren[hd] = (getInfoValueFromRefDataForm(row, 'submission', refType, getItemKeyNameForm(hd)) || []).join(' | ');

                     } else if (hd === 'IFC Rev') {
                        objChildren[hd] = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'ifcVersion') || '';
                     } else if (hd === 'Issue For Construction') {
                        objChildren[hd] = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'issueForConstruction') || '';

                     } else {
                        objChildren[hd] = data;
                     };
                  };
               });


               let objConsultant = {};

               if (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') {
                  headersConsultantWithNumber.forEach(hdCon => {
                     const { replyStatus, replyCompany, replyDate } = getConsultantReplyFormData(row, hdCon, refType);
                     const numberOfConsultant = hdCon.slice(10, hdCon.length);

                     if (replyCompany) {

                        const compFound = companies.find(cm => cm['company'] === replyCompany) || {};
                        const companyNameDisplay = compFound['shortName'] || replyCompany;

                        objConsultant = {
                           ...objConsultant,
                           [hdCon]: replyStatus ? `${companyNameDisplay} - (${replyDate})` : companyNameDisplay,
                           [`status${numberOfConsultant}`]: replyStatus
                        };
                     };
                  });
               };

               output.push({
                  ...objChildren,
                  ...objConsultant
               });

            };
         });
      });

      const dueDateColumnIndex = headersShown.indexOf('Due Date');


      output.forEach((row, i) => {
         if (row.treeLevel) {
            arrayIndexOfDwgTypeTree.push(i);
         } else {
            if (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') {

               if (row['Due Date'] && !row['Consultant (1)']) {
                  const dueDate = row['Due Date'];
                  const nosOfDate = compareDatesForm(moment(dueDate, 'DD/MM/YY'));

                  if (nosOfDate < 0) {
                     statusAllCellObject[i] = {
                        ...(statusAllCellObject[i] || {}),
                        [dueDateColumnIndex]: 'Overdue'
                     };
                  } else if (nosOfDate >= 0 && nosOfDate < 3) {
                     statusAllCellObject[i] = {
                        ...(statusAllCellObject[i] || {}),
                        [dueDateColumnIndex]: 'Due in the next 1 - 3 days'
                     };
                  } else {
                     statusAllCellObject[i] = {
                        ...(statusAllCellObject[i] || {}),
                        [dueDateColumnIndex]: 'Due in the next 4 - 14 days'
                     };
                  };
               };

               for (const key in row) {
                  if (headersConsultantWithNumber.indexOf(key) !== -1) {
                     const consultantNumber = key.slice(12, key.length - 1);
                     const headerIndex = headersShown.indexOf(key);
                     if (row[`status (${consultantNumber})`]) {
                        statusAllCellObject[i] = {
                           ...statusAllCellObject[i] || {},
                           [headerIndex]: row[`status (${consultantNumber})`],
                           [key]: row[`status (${consultantNumber})`],
                        };
                     };
                  } else if (key === 'Due Date' && !checkIfStringContainsDate(row['Consultant (1)'])) {

                     const dueDate = row['Due Date'];

                     const nosOfDate = compareDatesForm(moment(dueDate, 'DD/MM/YY'));

                     if (nosOfDate < 0) {
                        statusAllCellObject[i] = {
                           ...(statusAllCellObject[i] || {}),
                           [dueDateColumnIndex]: 'Overdue'
                        };
                     } else if (nosOfDate >= 0 && nosOfDate < 3) {
                        statusAllCellObject[i] = {
                           ...(statusAllCellObject[i] || {}),
                           [dueDateColumnIndex]: 'Due in the next 1 - 3 days'
                        };
                     } else {
                        statusAllCellObject[i] = {
                           ...(statusAllCellObject[i] || {}),
                           [dueDateColumnIndex]: 'Due in the next 4 - 14 days'
                        };
                     };
                  };
               };
               headersConsultantWithNumber.forEach(hd => {
                  const consultantNumber = hd.slice(12, hd.length - 1);
                  delete row[`status (${consultantNumber})`];
               });
            };

         };
      });
   };


   return {
      output,
      arrayIndexOfDwgTypeTree,
      arrayIndexOfDwgDarkerTypeTree,
      statusAllCellObject,
      sumStatusOutput,
      dueDrawingOutput
   };
};

export const checkFormIfRepliedOrNot = ({ refType, row, overdueLeadConsultantOnly, rfaData }) => {
   let isNotRepliedYet = false;
   if (refType === 'rfa') {
      if (overdueLeadConsultantOnly) {
         const leadConsultant = (getInfoValueFromRefDataForm(rfaData, 'submission', 'rfa', 'consultantMustReply') || [])[0];
         if (!getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', leadConsultant)) {
            isNotRepliedYet = true;
         };
      } else {
         const consultantMustReplyArray = getInfoValueFromRefDataForm(rfaData, 'submission', 'rfa', 'consultantMustReply') || [];
         consultantMustReplyArray.forEach(cmp => {
            if (!getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', cmp)) {
               isNotRepliedYet = true;
            };
         });
      };
   } else {
      if (overdueLeadConsultantOnly) {
         const leadConsultant = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [])[0];
         if (!getInfoValueFromRefDataForm(row, 'reply', refType, 'status', leadConsultant)) {
            isNotRepliedYet = true;
         };
      } else {
         const consultantMustReplyArray = getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [];
         consultantMustReplyArray.forEach(cmp => {
            if (!getInfoValueFromRefDataForm(row, 'reply', refType, 'status', cmp)) {
               isNotRepliedYet = true;
            };
         });
      };
   };
   return isNotRepliedYet;
};





export const filterRowsOutput = ({ modeFilter, rowsCurrentInView: rowsCurrentInViewInput, pageSheetTypeName, rfaData, overdueLeadConsultantOnly }) => {


   if (!(modeFilter.length > 0)) return rowsCurrentInViewInput;

   const refType = getKeyTextForSheet(pageSheetTypeName);

   let filterObj = {};

   modeFilter.forEach(filter => {
      if (filter.header) {
         filterObj[filter.header] = [...filterObj[filter.header] || [], filter.value];
      };
   });

   if (pageSheetTypeName === 'page-spreadsheet') {

      Object.keys(filterObj).forEach(header => {
         rowsCurrentInViewInput = rowsCurrentInViewInput.filter(r => filterObj[header].indexOf(r[header]) !== -1);
      });
      return rowsCurrentInViewInput;

   } else {



      // GET ALL LATEST VERSION =>>>>>>>>>>>> FILTER ONLY APPLY FOR LATEST VERSION
      // let rowsCurrentInView = [];
      // if (pageSheetTypeName === 'page-rfa') {
      //    const allRfaNumber = [...new Set(rowsCurrentInViewInput.filter(x => x['rfaNumber']).map(x => x['rfaNumber']))];
      //    allRfaNumber.forEach(rfaNumber => {
      //       const dwgsThisRfaNumber = rowsCurrentInViewInput.filter(r => r['rfaNumber'] === rfaNumber).sort((a, b) => a['RFA Ref'] - b['RFA Ref']);

      //       const allRefText = [...new Set(dwgsThisRfaNumber.map(x => x['RFA Ref']))].sort();

      //       const allDwgsLatestRevsionRfa = dwgsThisRfaNumber.filter(x => x['RFA Ref'] === allRefText[allRefText.length - 1]);
      //       rowsCurrentInView = [...rowsCurrentInView, ...allDwgsLatestRevsionRfa];
      //    });
      // } else {
      //    const allRefNumber = [...new Set(rowsCurrentInViewInput.filter(x => x[`${refType}Ref`]).map(x => x[`${refType}Ref`]))];
      //    allRefNumber.forEach(refNumber => {
      //       const dwgsThisRefNumber = rowsCurrentInViewInput.filter(r => r[`${refType}Ref`] === refNumber).sort((a, b) => a['revision'] - b['revision']);
      //       const allRefText = [...new Set(dwgsThisRefNumber.map(x => x['revision']))].sort();

      //       const allDwgsLatestRevsionRef = dwgsThisRefNumber.filter(x => x['revision'] === allRefText[allRefText.length - 1]);
      //       rowsCurrentInView = [...rowsCurrentInView, ...allDwgsLatestRevsionRef];
      //    });
      // };



      let rowsCurrentInView = [...rowsCurrentInViewInput];

      Object.keys(filterObj).forEach(header => {

         if (isColumnWithReplyData(header)) {

            const arrayCompanyFilter = filterObj[header];

            rowsCurrentInView = rowsCurrentInView.filter(r => {
               if (pageSheetTypeName === 'page-rfa') {
                  const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
                  if (rfaFound) {
                     const { replyCompany } = getConsultantReplyData(rfaFound, r, header);
                     return arrayCompanyFilter.indexOf(replyCompany) !== -1;
                  };
               } else {
                  const { replyCompany } = getConsultantReplyFormData(r, header, refType);
                  return arrayCompanyFilter.indexOf(replyCompany) !== -1;
               };
            });

         } else if (header === `Overdue ${refType.toUpperCase()}`) {

            let outputDrawingsAfterFilter = [];

            filterObj[header].forEach(filterData => {

               // STEP 1: GET ALL LATEST VERSION
               let outputAllLatestVersion = [];
               if (pageSheetTypeName === 'page-rfa') {
                  const allSubmittedRfa = [...new Set(rowsCurrentInView.map(r => r['rfaNumber']).filter(x => x))];
                  allSubmittedRfa.forEach(rfaNumber => {
                     let allDwgs = rowsCurrentInView.filter(dwg => dwg['rfaNumber'] === rfaNumber);
                     const allRfaRef = [...new Set(allDwgs.map(x => x['RFA Ref'] || '').filter(x => x))].sort();
                     const rfaRef = allRfaRef[allRfaRef.length - 1]; // GET LATEST VERSION
                     let allDwgsLatestVersion = rowsCurrentInView.filter(dwg => dwg['RFA Ref'] === rfaRef);

                     outputAllLatestVersion = [
                        ...outputAllLatestVersion,
                        ...allDwgsLatestVersion
                     ];
                  });
               } else {
                  const allSubmittedRef = [...new Set(rowsCurrentInView.map(r => r[`${refType}Ref`]).filter(x => x))];
                  allSubmittedRef.forEach(refNumber => {
                     let allDwgs = rowsCurrentInView.filter(dwg => dwg[`${refType}Ref`] === refNumber);
                     const allRefWithVersion = [...new Set(allDwgs.map(x => getRefStringWithVersion(x, refType)).filter(x => x))].sort();
                     const refLatest = allRefWithVersion[allRefWithVersion.length - 1]; // GET LATEST VERSION
                     let allDwgsLatestVersion = rowsCurrentInView.filter(dwg => getRefStringWithVersion(dwg, refType) === refLatest);

                     outputAllLatestVersion = [
                        ...outputAllLatestVersion,
                        ...allDwgsLatestVersion
                     ];
                  });
               };



               // STEP 2: FILTER LATEST VERSION
               outputDrawingsAfterFilter = [
                  ...outputDrawingsAfterFilter,
                  ...outputAllLatestVersion
                     .filter(r => {

                        let rfaFound;
                        let isNotRepliedYet = false;

                        if (pageSheetTypeName === 'page-rfa') {
                           rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
                           isNotRepliedYet = checkFormIfRepliedOrNot({ refType, row: r, overdueLeadConsultantOnly, rfaData: rfaFound });
                        } else {
                           isNotRepliedYet = checkFormIfRepliedOrNot({ refType, row: r, overdueLeadConsultantOnly });
                        };

                        let nosOfDate;

                        if (!isNotRepliedYet) {
                           return filterData === 'Replied'; // get current, not history
                        } else {
                           nosOfDate = pageSheetTypeName === 'page-rfa'
                              ? compareDatesForm(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'due'))
                              : compareDatesForm(getInfoValueFromRefDataForm(r, 'submission', refType, 'due'));

                           return filterData === 'Overdue' ? nosOfDate < 0
                              : (filterData === 'Due in the next 1 - 3 days') ? (nosOfDate >= 0 && nosOfDate < 3)
                                 : (filterData === 'Due in the next 4 - 14 days') ? (nosOfDate >= 3)
                                    : false;
                        };
                     })];

            });

            rowsCurrentInView = outputDrawingsAfterFilter;


         } else if (header === 'Requested By') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               let rfaFound;
               if (pageSheetTypeName === 'page-rfa') {
                  rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
               };

               const requestedBy = pageSheetTypeName === 'page-rfa'
                  ? getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'requestedBy')
                  : getInfoValueFromRefDataForm(r, 'submission', refType, 'requestedBy');

               return filterObj[header].indexOf(requestedBy) !== -1;
            });
         } else if (header === 'Signatured By') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const signaturedBy = getInfoValueFromRefDataForm(r, 'submission', refType, 'signaturedBy')
               return filterObj[header].indexOf(signaturedBy) !== -1;
            });
         } else if (header === 'Submission Type') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const submissionType = getInfoValueFromRefDataForm(r, 'submission', refType, 'submissionType')
               return filterObj[header].indexOf(submissionType) !== -1;
            });
         } else if (header === 'Submission Date') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               let rfaFound;
               if (pageSheetTypeName === 'page-rfa') {
                  rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
               };

               const submissionDate = pageSheetTypeName === 'page-rfa'
                  ? moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'date')).format('DD/MM/YY')
                  : moment(getInfoValueFromRefDataForm(r, 'submission', refType, 'date')).format('DD/MM/YY');
               return filterObj[header].indexOf(submissionDate) !== -1;
            });
         } else if (header === 'Conversation Date') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {

               const date = getInfoValueFromRefDataForm(r, 'submission', refType, 'dateConversation');
               const dateConversation = date ? moment(date).format('DD/MM/YY') : '';
               return filterObj[header].indexOf(dateConversation) !== -1;
            });
         } else if (header === 'Due Date') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               let rfaFound;
               if (pageSheetTypeName === 'page-rfa') {
                  rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
               };

               const dueDate = pageSheetTypeName === 'page-rfa'
                  ? moment(getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'due')).format('DD/MM/YY')
                  : moment(getInfoValueFromRefDataForm(r, 'submission', refType, 'due')).format('DD/MM/YY');

               return filterObj[header].indexOf(dueDate) !== -1;

            });

         } else if (header === 'Activity By Date') {

            rowsCurrentInView = rowsCurrentInView.filter(r => {
               return getDrawingsFilterByDateRange(r, filterObj[header], refType, rfaData);
            });

         } else if (header === 'Cost Implication') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const isCostImplication = getInfoValueFromRefDataForm(r, 'submission', refType, 'isCostImplication') ? 'True' : 'False';
               return filterObj[header].indexOf(isCostImplication) !== -1;
            });
         } else if (header === 'Time Extension') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const isTimeExtension = getInfoValueFromRefDataForm(r, 'submission', refType, 'isTimeExtension') ? 'True' : 'False';
               return filterObj[header].indexOf(isTimeExtension) !== -1;
            });
         } else if (header === 'Attachment Type') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const herewithForDt = getInfoValueFromRefDataForm(r, 'submission', refType, 'herewithForDt');
               return filterObj[header].indexOf(herewithForDt) !== -1;
            });
         } else if (header === 'Transmitted For') {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const transmittedForDt = getInfoValueFromRefDataForm(r, 'submission', refType, 'transmittedForDt');
               return filterObj[header].indexOf(transmittedForDt) !== -1;
            });
         } else if (header === 'Status' && (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc')) {
            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const consultantArray = getInfoValueFromRefDataForm(r, 'submission', refType, 'consultantMustReply') || [];
               const consultantLead = consultantArray[0];
               const replyStatus = getInfoValueFromRefDataForm(r, 'reply', refType, 'status', consultantLead);
               if (filterObj[header].indexOf('Consultant reviewing') !== -1) {
                  return !replyStatus;
               } else {
                  return filterObj[header].indexOf(replyStatus) !== -1;
               };
            });

         } else if (header === 'Status' && pageSheetTypeName === 'page-rfa') {

            rowsCurrentInView = rowsCurrentInView.filter(r => {
               const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
               const consultantLead = (getInfoValueFromRefDataForm(rfaFound, 'submission', refType, 'consultantMustReply') || [])[0];
               if (consultantLead) {
                  const replyStatus = getInfoValueFromRefDataForm(r, 'reply', refType, 'status', consultantLead);
                  if (filterObj[header].indexOf('Consultant reviewing') !== -1) {
                     return !replyStatus;
                  } else {
                     return filterObj[header].indexOf(replyStatus) !== -1;
                  };
               };
            });
         } else {
            rowsCurrentInView = rowsCurrentInView.filter(r => filterObj[header].indexOf(r[header]) !== -1);
         };
      });


      // PUSH PREVIOUS VERSION BACK
      // const allRefFilteredText = [...new Set(rowsCurrentInView.map(x => x[`${refType}Ref`]))];
      // const rowsOutput = rowsCurrentInViewInput.filter(r => allRefFilteredText.indexOf(r[`${refType}Ref`]) !== -1);
      // return rowsOutput;



      return rowsCurrentInView;
   };
};



export const checkIfThereIsRowInsideTreeNode = (node, tree, rowsAll, pageSheetTypeName) => {

   const key = pageSheetTypeName === 'page-spreadsheet' ? '_parentRow' :
      pageSheetTypeName === 'page-rfa' ? 'rfaNumber' : 'parentId';


   const rowChildArray = rowsAll.filter(row => row[key] === node.id);
   const nodeChildArray = tree.filter(nd => nd.parentId === node.id);


   if (rowChildArray.length > 0) {
      return true;
   } else if (nodeChildArray.length > 0) {
      let checkResult = false;
      nodeChildArray.forEach(nodeChild => {
         if (checkIfThereIsRowInsideTreeNode(nodeChild, tree, rowsAll, pageSheetTypeName)) {
            checkResult = true;
         };
      });
      return checkResult;
   };
};


export const getOutputRowsWithTreeNodeFinal = (drawingTypeTree, rowsAllFinalMultiForm, pageSheetTypeName) => {

   let dataOutputFinal = [];

   drawingTypeTree.forEach(item => {
      let itemClone = { ...item };

      let checkResult;
      if (pageSheetTypeName !== 'page-authorities-submission') {
         checkResult = checkIfThereIsRowInsideTreeNode(itemClone, drawingTypeTree, rowsAllFinalMultiForm, pageSheetTypeName);
      };

      if (checkResult || pageSheetTypeName === 'page-authorities-submission') {
         const rowChildren = pageSheetTypeName === 'page-rfa'
            ? rowsAllFinalMultiForm.filter(row => row['rfaNumber'] === itemClone.id)
            : rowsAllFinalMultiForm.filter(row => row['parentId'] === itemClone.id);

         if (rowChildren.length > 0) {
            itemClone.children = rowChildren;
         };
         dataOutputFinal.push(itemClone);
      };
   });

   return dataOutputFinal;
};

export const getOutputRowsWithTreeNodeFinalPM = (drawingTypeTree, rowsAllFinalMultiForm, pageSheetTypeName) => {
   let dataOutputFinal = [];

   drawingTypeTree.forEach(item => {
      let itemClone = { ...item };

         const rowChildren = rowsAllFinalMultiForm.filter(row => row['parentId'] === itemClone.id);
         if (rowChildren.length > 0) {
            itemClone.children = rowChildren;
         };
         dataOutputFinal.push(itemClone);

   });

   return dataOutputFinal;
};

const sortDrawingTypeTreeForExportFormat = (treeArray) => {

   let output = [];

   const sortTree = (node) => {
      const arrayNodeChildren = treeArray.filter(x => x.parentId === node.id);
      if (!output.find(x => x.id === node.id)) {
         output.push(node);
      };
      if (arrayNodeChildren.length > 0) {
         arrayNodeChildren.forEach(nodeItem => {
            sortTree(nodeItem);
         });
      };
   };

   treeArray.forEach(node => {
      sortTree(node);
   });

   return output;
};



export const checkIfStringContainNumberOnly = (string) => {
   return /^[0-9]+$/.test(string);
};


export const getDisciplineOfRefString = (str) => {
   const allParts = str.split('/');
   let discp = '';
   allParts.forEach((item, i) => {
      if (i >= 2 && i < allParts.length - 1) {
         discp += (i === 2 ? `${item}` : `/${item}`);
      };
   });
   return discp;
};
export const getDisciplineStringRfc = (str) => {
   const allParts = str.split('/');
   let discp = '';

   const itemTrade = allParts[2];
   const itemCompany = allParts[3];

   const newParts = [
      ...allParts.filter((x, i) => i <= 1),
      itemCompany,
      itemTrade,
      ...allParts.filter((x, i) => i >= 4),
   ];

   newParts.forEach((item, i) => {
      if (i >= 2 && i < newParts.length - 1) {
         discp += (i === 2 ? `${item}` : `/${item}`);
      };
   });

   return discp;
};

export const getRefStringWithVersion = (obj, refType) => {
   return obj['revision'] === '0' ? obj[`${refType}Ref`] : obj[`${refType}Ref`] + obj['revision'];
};



export const getItemKeyNameForm = (str) => {
   if (str === 'Description') return 'description';
   if (str === 'Requested By') return 'requestedBy';
   if (str === 'Signatured By') return 'signaturedBy';
   if (str === 'Contract Specification') return 'contractSpecification';
   if (str === 'Proposed Specification') return 'proposedSpecification';
   if (str === 'Submission Type') return 'submissionType';
   if (str === 'Submission Date') return 'date';
   if (str === 'Due Date') return 'due';
   if (str === 'Subject') return 'emailTitle';
   if (str === 'Transmitted For') return 'transmittedForDt';
   if (str === 'Attachment Type') return 'herewithForDt';
   if (str === 'Conversation Among') return 'conversationAmong';
   if (str === 'Conversation Date') return 'dateConversation';
   if (str === 'Cost Implication') return 'isCostImplication';
   if (str === 'Time Extension') return 'isTimeExtension';
   if (str === 'RFA Info') return 'listForm';
   if (str === 'RFAM Info') return 'listForm';
};


const checkIfStringContainsDate = (string) => {
   if (!string) return false;
   const last8Digit = string.slice(string.length - 9, string.length - 1);
   const allParts = last8Digit.split('/');

   return last8Digit[2] === '/' &&
      last8Digit[5] === '/' &&
      allParts[0] && checkIfStringContainNumberOnly(allParts[0]) &&
      allParts[1] && checkIfStringContainNumberOnly(allParts[1]) &&
      allParts[2] && checkIfStringContainNumberOnly(allParts[2]);
};

export const getCompanyNameShort = (name) => {

   let str = name.trim().replace(/[^a-zA-Z0-9 ]/g, ''); // keep number and letter only

   const parts = str.split(' ');
   if (parts.length >= 3) {
      return parts[0].slice(0, 1).toUpperCase() + parts[1].slice(0, 1).toUpperCase() + parts[2].slice(0, 1).toUpperCase();
   } else if (parts.length === 2) {
      if (parts[0].length === 1) {
         return parts[0].slice(0, 1).toUpperCase() + parts[1].slice(0, 2).toUpperCase();
      } else {
         return parts[0].slice(0, 2).toUpperCase() + parts[1].slice(0, 1).toUpperCase();
      };
   } else if (parts.length === 1) {
      return str.slice(0, 3).toUpperCase();
   };
};

export const getTotalStatusInRfa = (obj) => {
   let output = 0;
   Object.keys(obj).forEach(stt => {
      output += obj[stt];
   });
   return output;
};

export const sortTotalStatusRfaOrder = (obj) => {
   let output = [];
   statusOrderArray.forEach(stt => {
      if (obj[stt]) {
         output.push(stt)
      };
   });
   return output;
};


export const convertCompanyNameFolderS3 = (name) => {
   let str = name.replace(/[^a-zA-Z0-9 ]/g, ''); // keep number and letter only
   return str.split(' ').join('_');
};


export const removeSecondPart = (str) => {
   let parts = str.split('/');
   parts = parts.filter((x, i) => i !== 1);
   let output = '';
   parts.forEach((part, i) => {
      output += (i === 0 ? `${part}` : `/${part}`);
   });
   return output;
};


export const sortDataBeforePrint = (data) => {
   data.sort((b, a) => {
      return new Date(a['Created At']) - new Date(b['Created At']);
   });
   data.forEach((dt, i) => {
      dt.index = i + 1;
   });
   return data;
};
export const sortDataByCreatedAt = (data) => {
   return data.sort((a, b) => {
      return new Date(a['createdAt']) - new Date(b['createdAt']);
   });
};





export const checkIfThereIsEmailFailure = (email, formDataInfo, refType) => {
   let isThisFormFailedToSendEmail = false;
   for (const key in formDataInfo) {
      if (key.includes('-emailFailToSend-') && formDataInfo[key]) {
         const parts = key.split('-');
         const companyOfThisSubmitReply = parts[parts.length - 1];
         const type = parts[0];
         const userWhoCreateThis = formDataInfo[`${type}-${refType}-user-${companyOfThisSubmitReply}`];
         if (email === userWhoCreateThis) {
            return true;
         };
      };
   };
   return isThisFormFailedToSendEmail;
};




export const getDrawingsFilterByDateRange = (r, dateRangeText, refType, rfaData) => {
   let dateRange;
   if (dateRangeText.indexOf('All') !== -1) {
      return true;
   } else if (dateRangeText.indexOf('Today') !== -1) {
      dateRange = 0;
   } else if (dateRangeText.indexOf('3 Days') !== -1) {
      dateRange = 2;
   } else if (dateRangeText.indexOf('7 Days') !== -1) {
      dateRange = 6;
   } else if (dateRangeText.indexOf('14 Days') !== -1) {
      dateRange = 13;
   } else if (dateRangeText.indexOf('This Month') !== -1) {
      const startOfMonth = moment().startOf('month');
      dateRange = moment().diff(startOfMonth, 'days') - 1;
   };

   if (refType === 'rfa') {
      let rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === r['RFA Ref']) || {};
      for (const key in rfaFound) {
         if (key.includes(`${refType}-date-`) && rfaFound[key]) {
            let dateBefore = moment(moment().format('DD/MM/YY'), 'DD/MM/YY').subtract(dateRange, 'days');
            if (moment(rfaFound[key]) >= dateBefore) {
               return true;
            };
         };
      };
   } else {
      for (const key in r) {
         if (key.includes(`${refType}-date-`) && r[key]) {
            let dateBefore = moment(moment().format('DD/MM/YY'), 'DD/MM/YY').subtract(dateRange, 'days');
            if (moment(r[key]) >= dateBefore) {
               return true;
            };
         };
      };
   };
};




export const filterHistoryDataByDateRangeAndCompany = (historyAllInit, company, dateRange) => {
   const nos = dateRange === 'All' ? 'All'
      : dateRange === 'Today' ? -1
         : dateRange === 'Last 3 Days' ? -3
            : dateRange === 'Last 7 Days' ? -7
               : dateRange === 'Last 14 Days' ? -14
                  : -31;

   return historyAllInit
      .filter(r => {
         if (nos === 'All') return true;
         let today = moment(moment().format('DD/MM/YY'), 'DD/MM/YY').add(1, 'days');
         let dateBefore = moment(moment().format('DD/MM/YY'), 'DD/MM/YY').subtract(- 1 - nos, 'days');
         let dt = moment(r['Created At']);
         return dt <= today && dt >= dateBefore;
      })
      .filter(r => r['Company'] === company || company === 'All companies');
};





export const trimAndFilterAllCompanySameShortName = (companiesInput) => {
   let companies = companiesInput.map(x => {
      return {
         company: (x['company'] || '').trim(),
         fullName: (x['fullName'] || '').trim(),
         shortName: (x['shortName'] || '').trim(),
         companyType: x['companyType'],
         trade: x['trade'],
      };
   });

   let output = [];
   companies.forEach(cmp => {
      let isShortNameDuplicate = false;
      if (cmp['shortName']) {
         const compFilter = companies.filter(x => x['shortName'].toLowerCase() === cmp['shortName'].toLowerCase());
         if (compFilter.length > 1) isShortNameDuplicate = true;
      };

      if (!cmp['shortName'] || !isShortNameDuplicate) {
         output.push(cmp);
      };

   });
   return output;
};









export const generateShortUid = () => {
   let firstPart = (Math.random() * 46656) | 0;
   let secondPart = (Math.random() * 46656) | 0;
   firstPart = ('000' + firstPart.toString(36)).slice(-3);
   secondPart = ('000' + secondPart.toString(36)).slice(-3);
   return firstPart + secondPart;
};


export const checkCompanyNameFromIddIfNotTrim = (rowsData, pageSheetTypeName) => {
   let arrayCompanyName = [];
   rowsData.forEach(row => {
      for (const key in row) {
         if (key.includes('submission-') || key.includes('reply-')) {
            const parts = key.split('-');
            const companyName = parts[parts.length - 1];
            arrayCompanyName.push(companyName);
         };
      };
   });

   arrayCompanyName = [...new Set(arrayCompanyName)].filter(x => x);
   const final = arrayCompanyName.map(item => {
      return {
         name: item,
         isNoSpace: item.length === item.trim().length,
         length: item.length
      };
   });
};



export const convertTradeName = (name) => {
   if (name === 'ARC') return 'ARCHI';
   if (name === 'MEP') return 'M&E';
   if (name === 'C&S') return 'C&S';
   if (name === 'PC') return 'PRECAST';
   if (name === 'PRO') return 'PROJECT PROGRESS MEETING';
   if (name === 'TEC') return 'TECHNICAL MEETING';
   if (name === 'ICE') return 'ICE MEETING';
   if (name === 'ERI') return 'Employers Representative Instruction';
   if (name === 'LR') return 'LETTER';
   if (name === 'SM') return 'SITE MEMO';
   if (name) return 'MISC';
};



export const getOverdueRfaInfo = ({ rowsAllFinalMultiForm: rowsInput, rfaData, overdueLeadConsultantOnly }) => {

   const allTradeAndOverall = ['Overall', ...tradeArrayForm, 'MISC'];
   let refStatisticOverdue = {};
   allTradeAndOverall.forEach(item => {
      refStatisticOverdue[item] = {
         'Overdue': 0,
         'Due in the next 1 - 3 days': 0,
         'Due in the next 4 - 14 days': 0,
         'Replied': 0
      };
   });


   let rowsToCheckStatistic = rowsInput.filter(x => x['rfaNumber']);


   const allSubmittedRfa = [...new Set(rowsToCheckStatistic.map(r => r['rfaNumber']))];

   allSubmittedRfa.forEach(rfaNumber => {

      let allDwgs = rowsToCheckStatistic.filter(dwg => dwg['rfaNumber'] === rfaNumber);
      const allRfaRef = [...new Set(allDwgs.map(x => x['RFA Ref'] || '').filter(x => x))].sort();

      if (allRfaRef.length > 0) {

         const rfaRef = allRfaRef[allRfaRef.length - 1]; // GET LATEST VERSION

         const disciplineLevel1 = rfaRef.split('/')[2];
         const disciplineLevel1Name = convertTradeName(disciplineLevel1);

         if (disciplineLevel1Name) {

            const oneRowInRFA = allDwgs.find(dwg => dwg['RFA Ref'] === rfaRef) || {};
            const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rfaRef);

            if (rfaFound) {
               const isNotRepliedYet = checkFormIfRepliedOrNot({ refType: 'rfa', row: oneRowInRFA, overdueLeadConsultantOnly, rfaData: rfaFound });
               if (!isNotRepliedYet) {
                  refStatisticOverdue[disciplineLevel1Name]['Replied']++;
                  refStatisticOverdue['Overall']['Replied']++;
               } else {
                  const nosOfDate = compareDatesForm(moment(oneRowInRFA['Consultant Reply (T)'], 'DD/MM/YY'));
                  if (nosOfDate >= 0 && nosOfDate < 3) {
                     refStatisticOverdue[disciplineLevel1Name]['Due in the next 1 - 3 days']++;
                     refStatisticOverdue['Overall']['Due in the next 1 - 3 days']++;
                  } else if (nosOfDate < 0) {
                     refStatisticOverdue[disciplineLevel1Name]['Overdue']++;
                     refStatisticOverdue['Overall']['Overdue']++;
                  } else {
                     refStatisticOverdue[disciplineLevel1Name]['Due in the next 4 - 14 days']++;
                     refStatisticOverdue['Overall']['Due in the next 4 - 14 days']++;
                  };
               };
            };
         };
      };
   });
   return refStatisticOverdue;
};




export const getOverdueMultiFormInfo = ({ rowsAllFinalMultiForm: rows, pageSheetTypeName, overdueLeadConsultantOnly }) => {

   const allTradeAndOverall = ['Overall', ...(pageSheetTypeName === 'page-mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm), 'MISC'];
   let refStatisticOverdue = {};
   allTradeAndOverall.forEach(item => {
      refStatisticOverdue[item] = {
         'Overdue': 0,
         'Due in the next 1 - 3 days': 0,
         'Due in the next 4 - 14 days': 0,
         'Replied': 0
      };
   });
   const refType = getKeyTextForSheet(pageSheetTypeName);

   const allSubmittedRef = [...new Set(rows.map(r => r[`${refType}Ref`]).filter(x => x))].sort();

   allSubmittedRef.forEach(refText => {

      let allDwgs = rows.filter(row => row[`${refType}Ref`] === refText);
      const btnTextArray = [...new Set(allDwgs.map(x => x['revision']))].sort();


      const disciplineLevel1 = refText.split('/')[2];
      const disciplineLevel1Name = convertTradeName(disciplineLevel1);
      if (disciplineLevel1Name) {
         
         if (pageSheetTypeName !== 'page-mm') {
            const latestVersion = btnTextArray[btnTextArray.length - 1];
            const rowFound = allDwgs.find(x => x.revision === latestVersion);
            if (rowFound) {

               const isNotRepliedYet = checkFormIfRepliedOrNot({ refType, row: rowFound, overdueLeadConsultantOnly });
               if (!isNotRepliedYet) {
                  refStatisticOverdue[disciplineLevel1Name]['Replied']++;
                  refStatisticOverdue['Overall']['Replied']++;
               } else {

                  const dueDate = getInfoValueFromRefDataForm(rowFound, 'submission', refType, 'due');
                  const nosOfDate = compareDatesForm(dueDate);

                  if (nosOfDate < 0) {
                     refStatisticOverdue[disciplineLevel1Name]['Overdue']++;
                     refStatisticOverdue['Overall']['Overdue']++;

                  } else if (nosOfDate >= 0 && nosOfDate < 3) {
                     refStatisticOverdue[disciplineLevel1Name]['Due in the next 1 - 3 days']++;
                     refStatisticOverdue['Overall']['Due in the next 1 - 3 days']++;
                  } else {
                     refStatisticOverdue[disciplineLevel1Name]['Due in the next 4 - 14 days']++;
                     refStatisticOverdue['Overall']['Due in the next 4 - 14 days']++;
                  };
               };
            };
         } else {
            if (refStatisticOverdue[disciplineLevel1Name]) {
               refStatisticOverdue[disciplineLevel1Name]['Replied']++;
            };
            refStatisticOverdue['Overall']['Replied']++;
         };
      };
   });

   return refStatisticOverdue;
};




export const findSubmissionCompanyInRow = (row) => {
   for (const key in row) {
      if (key.includes('submission-')) {
         const parts = key.split('-');
         const company = parts[3];
         if (company) {
            return company
         };
      };
   };
};


export const getCompanyNameFromLink = (dwgLink) => {
   const type = dwgLink.includes('/submit/') ? 'submit' : dwgLink.includes('/reply/') ? 'reply' : '';
   if (type) {
      const parts = dwgLink.split('/');
      let indexType;
      parts.forEach((part, i) => {
         if (part === type) {
            indexType = i;
         };
      });
      if (indexType) {
         return parts[indexType + 1].replaceAll('_', ' ');
      };
   };
};



export const getDataToUpdateRowInDtFormLink = ({ id, refType, company, dwgsImportFromRFA, refNumberTextInfo, dateSendThisForm, dataDmsDrawingLink, arrayFilesPdfUploadLink, linkedFormTypeName, typeRow }) => {
   let arrayDwgLinks = [];
   let ifcVersionObj = {};
   for (const file in dataDmsDrawingLink) {
      const row = dataDmsDrawingLink[file];
      if (row['id'] === id) {
         const linkFound = arrayFilesPdfUploadLink.find(link => {
            const allParts = link.split('/');
            const fileNameThisLink = allParts[allParts.length - 1];
            return fileNameThisLink === file;
         });
         if (linkFound) {
            arrayDwgLinks.push(linkFound);
         };

         if (row['IFC Rev'] ||
            (row['Rev'] && linkedFormTypeName === 'rfa') ||
            (row['revision'] && linkedFormTypeName && linkedFormTypeName !== 'rfa')
         ) {
            ifcVersionObj = {
               [`submission-${refType}-ifcVersion-${company}`]: row['IFC Rev'] || (linkedFormTypeName === 'rfa' ? row['Rev'] : row['revision']),
               [`submission-${refType}-date-${company}`]: dateSendThisForm || new Date(),
            };
         };
      };
   };
   const foundDwgInRfa = dwgsImportFromRFA.find(dwg => dwg['id'] === id);

   if (foundDwgInRfa) {
      arrayDwgLinks = [
         ...arrayDwgLinks,
         ...getDrawingLinksFromCmpPicked(foundDwgInRfa, linkedFormTypeName)
      ];
      ifcVersionObj = {
         [`submission-${refType}-ifcVersion-${company}`]: foundDwgInRfa['IFC Rev'] || (linkedFormTypeName === 'rfa' ? foundDwgInRfa['Rev'] : foundDwgInRfa['revision']),
         [`submission-${refType}-date-${company}`]: dateSendThisForm || new Date(),
      };
   };

   const dataField = (typeRow === 'rfa-current' || typeRow === 'multi-form') ? 'data'
      : typeRow === 'rfa-history' ? 'history'
         : '';

   return {
      '_id': id,
      [dataField]: {
         [`submission-${refType}-issueForConstruction-${company}`]: refNumberTextInfo,
         [`submission-${refType}-drawingLinkIssueForConstruction-${company}`]: arrayDwgLinks,
         ...ifcVersionObj
      }
   };
};

export const getDrawingLinksFromCmpPicked = (row, refType) => {

   let outputLinks = [];

   const consultantsSelected = row['consultantsSelected'] || [];

   consultantsSelected.forEach(cmp => {
      if (cmp === 'Submission') {
         if (refType === 'rfa') {
            const linksSubmitted = getInfoValueFromRefDataForm(row, 'submission', refType, 'drawing');
            if (linksSubmitted) {
               outputLinks.push(linksSubmitted);
            };
         } else {
            const linkSignedOffFormSubmit = getInfoValueFromRefDataForm(row, 'submission', refType, 'linkSignedOffFormSubmit');
            if (linkSignedOffFormSubmit) {
               outputLinks.push(linkSignedOffFormSubmit);
            };
            const linkDrawings = getInfoValueFromRefDataForm(row, 'submission', refType, 'linkDrawings') || [];
            if (linkDrawings.length > 0) {
               outputLinks = [...outputLinks, ...linkDrawings];
            };
         };

      } else {
         if (refType === 'rfa') {
            const linksReplied = getInfoValueFromRefDataForm(row, 'reply', refType, 'drawing', cmp);
            if (linksReplied) {
               outputLinks.push(linksReplied);
            };
         } else {
            const linkFormReply = getInfoValueFromRefDataForm(row, 'reply', refType, 'linkFormReply', cmp);
            if (linkFormReply) {
               outputLinks.push(linkFormReply);
            };
            const linkDocumentsReply = getInfoValueFromRefDataForm(row, 'reply', refType, 'linkDocumentsReply', cmp) || [];
            if (linkDocumentsReply.length > 0) {
               outputLinks = [...outputLinks, ...linkDocumentsReply];
            };
         };
      };
   });
   return outputLinks;
};



export const getTabArrayNameForPage = (disciplineForm, refType) => {

   if (refType === 'rfc') return [];

   let output = [];
   disciplineForm.forEach(text => {
      const parts = text.split('/');
      const dis = parts[0];
      output.push(dis);
   });

   output = [...new Set(output)];

   const arrayMm = ['PRO', 'TEC', 'ICE'];

   if (refType === 'mm') {
      return output.filter(x => arrayMm.indexOf(x) !== -1);
   } else {
      return output.filter(x => arrayMm.indexOf(x) === -1);
   };
};



export const getMessageEmailFailed = (arrayFailed, refType) => {
   return {
      message: 'Email failed to send out',
      placement: 'bottomRight',
      bottom: 5,
      description: `These ${arrayFailed.length} ${refType.toUpperCase()} below failed to send email, please refresh the page and click mail button at RFA cell to resend: \n ${arrayFailed.join(', ')}`,
      style: {
         width: 600,
         marginLeft: -210,
         color: 'black',
         fontWeight: 'bold'
      },
      duration: 0,
   };
};


export const getListFormEmailFailed = (rows, email, refType) => {
   let output = [];
   rows.forEach(row => {
      if (checkIfThereIsEmailFailure(email, row, refType)) {
         const refFailed = getRefStringWithVersion(row, refType);
         output.push(refFailed);
      };
   });
   return output;
};



export const getConsultantStatusReviewByCompany = ({ arrayStatusDrawingText, refType, currentRefData, company, companyReviewStatus }) => {

   let consultantStatus = (refType === 'qr' || refType === 'qrm') ? arrayTextStatusQrQrm
      : refType === 'rfc' ? arrayTextStatusRfc
         : arrayStatusDrawingText.filter(x => x !== 'Consultant reviewing')

   let consultantStatusObj = {};
   consultantStatus.forEach(stt => {
      consultantStatusObj[stt] = stt;
   });

   const consultantMustReplyArray = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply') || [];
   const isThisCompanyLeadConsultant = company === consultantMustReplyArray[0];

   if (companyReviewStatus) {

      // ONLY LEAD CONSULTANT HAVE REVIEW STATUS
      // if (companyReviewStatus[company] && isThisCompanyLeadConsultant) {

      // NO ONLY LEAD CONSULTANT BUT ALSO SECONDARY CONSULTANT HAVE REVIEW STATUS
      if (companyReviewStatus[company]) {

         consultantStatusObj = companyReviewStatus[company][refType] || {};
      } else if (companyReviewStatus['Default Review Status']) {
         if (refType !== 'rfc') {
            consultantStatusObj = companyReviewStatus['Default Review Status'][refType] || {};
         };
      };
      consultantStatus = Object.values(consultantStatusObj);
   };

   return { consultantStatus, consultantStatusObj };
};



export const trimListGroupSpace = (listGroup) => {
   return listGroup.map(grp => {
      let str = grp;
      const replacedString = str.replace('_%$%_', 'zzzzzz');
      const parts = replacedString.split('zzzzzz')
      if (parts.length === 1) {
         return parts[0];
      } else if (parts.length === 2) {
         return parts[0].trim() + '_%$%_' + parts[1];
      };
   });
};