import React, { useState, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip, message } from "antd";
import _ from "lodash";
import { IconButton, Icon, ButtonGroup } from "rsuite";
// import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReact } from "ag-grid-react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { LoadingScreen } from "../customCell/LoadingScreen";
import { v4 } from "uuid";
import { setDataEntry, setDataEntryLoading } from "../../../actions/app/dataentry";

function TableHistory(props) {
   const gridRef = useRef();
   const [rowData, setRowData] = useState([]);
   const [columns, setColumns] = useState([]);
   const [autoGroupColumnDef, setAutoGroupColumnDef] = useState({});
   useEffect(() => {
      setRowData(props.data);
   }, [props.data]);
   useEffect(() => {
      if (props.columns) {
         let temp = [
            {
               field: "user",
               enableRowGroup: true,
               initialWidth: 100,
               resizable: true,
               headerTooltip: "User",
               filter: "agMultiColumnFilter",
            },
            {
               field: "updatedAt",
               enableRowGroup: true,
               initialWidth: 100,
               resizable: true,
               headerTooltip: "Updated At",
               filter: "agMultiColumnFilter",
            },
         ];
         _.forEach(props.columns, (v, k) => {
            let data = {
               field: v.name,
               enableRowGroup: true,
               initialWidth: v.width,
               resizable: true,
               headerTooltip: v.name,
               filter: "agMultiColumnFilter",
            };
            temp.push(data);
         });
         setColumns(temp);
      }
   }, [props.columns]);
   const defaultColDef = useMemo(
      () => ({
         sortable: true,
         filter: true,
         minWidth: 100,
      }),
      []
   );

   return (
      <AgGridReact
         ref={gridRef}
         className="ag-theme-balham"
         animateRows="true"
         reactUi="true"
         modules={AllModules}
         columnDefs={columns}
         defaultColDef={defaultColDef}
         autoGroupColumnDef={autoGroupColumnDef}
         rowGroupPanelShow="always"
         enableRangeSelection="true"
         rowData={rowData}
         rowSelection="multiple"
         columnHoverHighlight={true}
         loadingOverlayComponentFramework={LoadingScreen}
         allowContextMenuWithControlKey={true}
         groupDisplayType="multipleColumns"
         // rowGroupPanelShow='never'
      />
   );
}
TableHistory.propTypes = {
   history: PropTypes.shape({
      push: PropTypes.func.isRequired,
   }).isRequired,
   isAdmin: PropTypes.bool.isRequired,
   isConfirmed: PropTypes.bool.isRequired,
   userInfo: PropTypes.object.isRequired,
   userRole: PropTypes.string.isRequired,
   excelPage: PropTypes.bool.isRequired,
   dataEntryData: PropTypes.object.isRequired,
   dataEntryLoading: PropTypes.bool.isRequired,
   setDataEntry: PropTypes.func.isRequired,
   setDataEntryLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
   return {
      // isAdmin: state.user.role === "admin",
      isAdmin: state.user.email === "admin@wohhup.com",
      isConfirmed: !!state.user.confirmed,
      userInfo: state.user,
      userRole: state.user.role,
      dataEntryData: state.dataentry.data,
      dataEntryLoading: state.dataentry.loading,
   };
}
export default connect(mapStateToProps, { setDataEntry: setDataEntry, setDataEntryLoading: setDataEntryLoading })(
   TableHistory
);
