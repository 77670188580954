import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { connect, useSelector } from 'react-redux';
import { Modal, Select, List, Typography } from 'antd';

const { Text, Title } = Typography;

const { Option } = Select;
function DialogChooseRevitModel(props) {
  const [currentRevitModel, setCurrentRevitModel] = useState(null);
  const [statusOptions, setStatusOptions] = useState([
    { value: 'Incomplete', label: 'Incomplete', color: '#7c806b' },
    { value: 'In Progress', label: 'In Progress', color: '#68a869' },
    { value: 'Completed', label: 'Completed', color: '#66ed68' },
    { value: 'Partly Completing', label: 'Partly Completing', color: '#EE82EE' },
  ]);

  useEffect(() => {
    setCurrentRevitModel(props.selectedRevitModel);
  }, [props.dialogChooseRevitVisible]);

  return (
    <div>
      <Modal
        title='Setting'
        visible={props.dialogChooseRevitVisible}
        onOk={() => {
          props.handleOk(currentRevitModel, statusOptions);
          props.handleClose();
        }}
        onCancel={(e) => {
          props.handleClose();
        }}
      >
        <div className=' d-flex flex-column'>
          <label style={{ width: 100 }}>Revit model :</label>
          <Select
            placeholder='Items'
            style={{ width: '100%' }}
            value={currentRevitModel}
            onChange={(e) => {
              setCurrentRevitModel(e);
            }}
          >
            {_.isArray(props.allRevitModels) &&
              props.allRevitModels.map((x, index) => (
                <Option key={index} value={x.text}>
                  {x.text}
                </Option>
              ))}
          </Select>
        </div>
        <div className=' d-flex flex-column mt-2'>
          <label style={{ width: 100 }}>Setting colors:</label>

          <List
            bordered
            style={{ overflow: 'auto' }}
            dataSource={statusOptions}
            renderItem={(item) => (
              <List.Item>
                <Text strong>{item.label}</Text>
                <input
                  type='color'
                  style={{ position: 'absolute', right: 10 }}
                  value={item.color}
                  onChange={(e) => {
                    let temp = [...statusOptions];
                    let selected = temp.find((x) => x.label == item.label);
                    selected.color = e.target.value;

                    setStatusOptions(temp);
                  }}
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </div>
  );
}

export default DialogChooseRevitModel;
