import { EXCEL_ITEMS } from "../types";

const init = {
  excelPage: false,
}

export  function excel(state = init, action = {}) {
  switch (action.type) {
    case EXCEL_ITEMS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
