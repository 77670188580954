import React from 'react';
import { Typography } from 'antd';
import { Icon } from 'rsuite';
import _ from 'lodash'
import { Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { handleReactTableFilterDate } from '../function/TableFunction'
import moment from 'moment';

const { Text } = Typography
export default  (_this) => {
  return ([
    {
      Header: 'Email', accessor: 'email', id: 'email', units: false, filter: "includes",
    },
    { Header: 'Project Name', accessor: 'projectName', id: 'projectName ', units: false, filter: "includes" },
    { Header: 'Platform', accessor: 'platform', id: 'platform ', units: false, filter: "includes" },
    // { Header: 'Platform', accessor: 'platform', id: 'platform ', units: false, filter: "includes" },
    { Header: 'Feature', accessor: 'functionName', id: 'functionName ', units: false, filter: "includes" },
    {
      Header: 'Last Time', accessor: 'lastTime', id: 'createdDate', units: false, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY HH:mm')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },

  ])
}