import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon } from 'antd'
import _ from 'lodash';

import { setPDFData } from '../../../../actions/app/pdf'

function InputColor(props) {

    return (
        <>
           <Button size='small' className='idd-app-pdf-feature feature' >
                <input type='color' value={props.pdfViewer.color} id='input-color' onChangeCapture={(e) => {
                    console.log(e.target.value)
                    props.setPDFData({color:e.target.value})
                }} />
            </Button>

        </>
    )
}

InputColor.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(InputColor)