import React from "react";
import { Modal } from "antd";

import { HotTable } from "@handsontable/react";
import { func_add_new_rebar_do } from "../../function/func_add_new_do_inv";
import rebarDOTempColumns from "../../function/DefineColumns/rebarDOTempColumns";

const RebarDoInput = ({ visible, onCreate, onCancel }) => {
  var hotData = [[]];
  return (
    <div>
      <Modal
        visible={visible}
        title="Key in rebar do data"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        style={{ pading: "0px" }}
        width={1000}
        onOk={() => {
          func_add_new_rebar_do(hotData);
          hotData = [];
          onCreate();
        }}
      >
        <HotTable
          data={hotData}
          colHeaders={true}
          rowHeaders={true}
          height={500}
          columns={rebarDOTempColumns}
          minRows={20}
          minCols={11}
          colWidths={[100, 100, 200, 100, 120, 250]}
          contextMenu={["row_above", "row_below", "remove_row"]}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </Modal>
    </div>
  );
};

export default RebarDoInput;
