/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { connect } from 'react-redux';
import _ from 'lodash'
import ShareIcon from '@material-ui/icons/Share';
import Assignment from '@material-ui/icons/Assignment';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Label } from 'semantic-ui-react';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Typography } from 'antd';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';


import { DialogUsersControl, NotificationControl ,DialogPermission} from '../../module_BimApp/dialog'

const styles = theme => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 100,

  },
});
const { Text } = Typography
const resultRenderer = ({ email }) => <Label content={email} />

resultRenderer.propTypes = {
  email: PropTypes.string,
}




class ButtonMenuProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderName: '',
      open: false,
      hidden: false,
      openDialogAdd: false,
      openDialogShare: false,
      openDialogPermission: false,
      initialState: { isLoading: false, results: [], value: '' },
      source: [],
      members: [],
      addButtonDisable: true,
      nameGroup: '',
      openAddRenamePanel: false,
      addRenameGroup: '',
      groupUser: [],
      loadingGroup: false,
      detailGroup: [],
      openAddMemberToGroupPanel: false,
      openAddMemberToProjectPanel: false,
      openSettingNotificationPanel: false,
      openAddNewCompanyGroup: false,

      openSettingTradePanel: false,
      openSettingUserPanel: false,

      loading: false
    }
  }
  //#region //!setting button
  handleClick = () => { this.setState({ open: (open => !open) }) };
  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({ open: true })
    }
  };
  handleClose = () => { this.setState({ open: false }) };
  //#endregion



  //#region //!setting dialog share 
  handleCloseDialogShare = () => { this.setState({ openDialogShare: false }) };

  handleClickOpenDialogShare = () => {
    this.setState({ loadingGroup: true })
    axios.get(`/api/projects/members?q=${this.props.project.projectId}`)
      .then(res => {
        res.data.members.sort()
        res.data.members.splice(0, 0, this.props.userCreatedProject)
        _.forEach(res.data.groupUser, v => {
          v.click = false
        })
        this.setState({
          members: res.data.members,
          groupUser: res.data.groupUser,
          openDialogShare: true,
          loadingGroup: false
        })
      })
  }
  handlePickGroup = (index, item) => {
    let temp = this.state.groupUser.slice()
    _.forEach(temp, v => {
      v.click = false
    })
    temp[index].click = true
    this.setState({ nameGroup: item.name, groupUser: temp, detailGroup: item.members })
  }
  //#endregion

  handleClickOpenDialogSettingNotification = () => {
    this.setState({ openSettingNotificationPanel: true })
  }
  handleCloseDialogSettingNotification = () => {
    this.setState({ openSettingNotificationPanel: false })
  }


  
  handleClickOpenDialogPermission = () => {
    // this.setState({ loadingGroup: true })
    // axios.get(`/api/projects/members?q=${this.props.project.projectId}`)
    //   .then(res => {
    //     res.data.members.sort()
    //     res.data.members.splice(0, 0, this.props.userCreatedProject)
    //     _.forEach(res.data.groupUser, v => {
    //       v.click = false
    //     })
    //     this.setState({
    //       members: res.data.members,
    //       groupUser: res.data.groupUser,
    //       openDialogShare: true,
    //       loadingGroup: false
    //     })
    //   })
      this.setState({ openDialogPermission: true })
  }
  handleCloseDialogPermission = () => { this.setState({ openDialogPermission: false }) };
  render() {
    const { classes } = this.props;
    const actions = [
      { icon: <Assignment />, name: 'Assign Permission', click: this.handleClickOpenDialogPermission },
      { icon: <ShareIcon />, name: 'Share Project', click: this.handleClickOpenDialogShare },
      { icon: <NotificationImportantOutlinedIcon />, name: 'Setting Notification', click: this.handleClickOpenDialogSettingNotification },
    ];



    return (
      <>
        <SpeedDial
          ButtonProps={{ color: "inherit" }}
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={this.state.hidden}
          icon={<MenuIcon />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onFocus={this.handleOpen}
          open={this.state.open}>

          {actions.map(action =>
            <SpeedDialAction
              key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen onClick={action.click}
            />
          )}
        </SpeedDial>



        {this.state.openDialogShare &&
          <DialogUsersControl
            open={this.state.openDialogShare}
            close={this.handleCloseDialogShare}
            projectId={this.props.project.projectId}
            userCreatedProject={this.props.userCreatedProject}
            project={this.props.project}
          />}
        {this.state.openDialogPermission &&
          <DialogPermission
            open={this.state.openDialogPermission}
            close={this.handleCloseDialogPermission}
            projectId={this.props.project.projectId}
            userCreatedProject={this.props.userCreatedProject}
            project={this.props.project}
          />}

        {this.state.openSettingNotificationPanel &&
          <NotificationControl
            open={this.state.openSettingNotificationPanel}
            handleClose={this.handleCloseDialogSettingNotification}
            projectId={this.props.project.projectId}
          />
        }


      </>
    );
  }
}

ButtonMenuProjectDetail.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}

export default withStyles(styles)(connect(mapStateToProps, null)(ButtonMenuProjectDetail));


