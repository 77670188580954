import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';
import _ from 'lodash'
import ConfirmEmailMessage from '../../messages/ConfirmEmailMessage'
import LazyLoad from 'react-lazyload';
import axios from "axios";
import { Empty, BackTop, message, Typography, Tooltip, Popover, Input } from 'antd';
import { Icon, IconButton, ButtonGroup, Loader } from 'rsuite';
import { Card, Image, Dropdown } from 'semantic-ui-react'
import { ButtonMenuFolder } from '../../module_BimApp/buttons';
import { WHLogoGif } from '../../../image'
import { TableListFolder } from '../../module_BimApp/tables'
import {
  sortFolderName, sortItemCreatedDate, sortItemUpdatedDate,
  sortDescendingFolderName, sortDescendingItemCreatedDate, sortDescendingItemUpdatedDate
} from '../../../components/module_BimApp/function/PageFunction'
import { DialogSettingFolder } from '../../module_BimApp/dialog'
import ResultError from '../../messages/ResultError'
import { trackingUser, socket, checkWohhupUser } from '../../../components/module_BimApp/function/AdminFunction'
import { SidebarViewPoint } from '../../module_BimApp/sidebar'
import { ColumnFolder } from '../../module_BimApp/columns'
import { CardFolders } from '../../module_BimApp/cards'
// const CardFolders = React.lazy(() =>
//      import ('../../cards/CardFolders')
// )
const { Text } = Typography
let sortByOptions = [
  { key: 'Name', text: 'Name', value: 'Name', },
  { key: 'Created Date', text: 'Created Date', value: 'Created Date', },
  { key: 'Updated Date', text: 'Updated Date', value: 'Updated Date', },
  { key: 'Status', text: 'Status', value: 'Status', },
]
const { Search } = Input;
class ProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      folders: [],
      searchFolders: [],
      permission: false,
      sortByValue: 'Name',

      displayDialogSettingFolder: false,
      folderId: '',
      folderName: '',
      isListCard: false,
      iconUpDOwn: true,
      error: false,

      isDisplayName: true,
      saveIssue: null,
      listSaveIssue: [],
      allListSaveIssue: [],
      loadingIssue: false,
      displayTableIssue: false,
      displayInfoIssue: false,
      pageCurrent: 0,
      disableLoadMore: false,
      loadingMore: false,
      displayFindIssuePanel: false,

      isAssignToMe: false,

      displaySidebarViewpoint: false,

      displayTableComment: false,
      displayInfoComment: false,
      listSaveComment: [],
      allListSaveComment: [],
      isAssignToMeComment: false,
      saveComment: null,
      showStatus: 'All',

      listAdmin: [],
      roleUploader: false,
      currentRole: ''
    }
  }
  componentDidCatch = () => {
    this.setState({ error: true })
  }
  componentWillMount() {
    // if (!navigator.onLine) {
    //   return this.restoreStateFromLocalStorage();
    // }
    if ($('#topNavigation').is(":hidden")) {
      $('#topNavigation').show()
    }
    let index = _.findIndex(this.props.userInfo.contractors, v => { return v.projectId === this.props.location.state.projectId })
    let role = ''
    if (index >= 0) {
      role = this.props.userInfo.contractors[index].role
      this.setState({
        roleUploader: this.props.userInfo.contractors[index].isAccessUpload ? this.props.userInfo.contractors[index].isAccessUpload : false,
        currentRole: role
      })
    }
    axios.post(`/api/folders`, {
      projectId: this.props.location.state.projectId, role,
      permission: this.props.userInfo.permission, email: this.props.userInfo.email
    })
      .then(res => {
        let temp = []
        let tempFolder = []
        res.data.sort(sortFolderName)
        _.forEach(res.data, v => {
          // if(v.folderName !=='rfa-folder-model'){

          // }
          v.uploadDate = v.uploadDate//moment(v.uploadDate).format('DD-MM-YYYY HH:mm')
          v.createdDate = v.createdDate//moment(v.createdDate).format('DD-MM-YYYY HH:mm')
          temp = v.listAdmin
          if (checkWohhupUser(this.props.userInfo.email)) {
            tempFolder.push(v)
          } else if (v.permission) {//|| this.state.roleUploader
            tempFolder.push(v)
          }
        })
        let tempEmail = []
        _.forEach(temp, v => {
          tempEmail.push(v.email)
        })

        this.setState({
          folders: this.props.userInfo.email === 'admin@wohhup.com' ? res.data : tempFolder,
          searchFolders: this.props.userInfo.email === 'admin@wohhup.com' ? res.data : tempFolder,
          loading: false,
          listAdmin: tempEmail
        }, () => {
          //  this.saveStateToLocalStorage()
        })
      })
      .catch(res => {
        this.setState({ loading: false })
      })

    socket.on('realtime-folder', this.handleRealtimeFolder)
  }
  componentDidMount = () => {
    try {
      trackingUser(this.props.userInfo.email, this.props.location.state.projectName)
    } catch { }

  };
  // saveStateToLocalStorage = () => {
  //   localStorage.setItem(`${this.props.userInfo.email}-${this.props.location.state.projectId}-folders`, JSON.stringify(this.state));
  // };
  // restoreStateFromLocalStorage = () => {
  //   const state = JSON.parse(localStorage.getItem(`${this.props.userInfo.email}-${this.props.location.state.projectId}-folders`));
  //   this.setState(state);
  // };

  componentWillUnmount = () => {

    socket.removeListener('realtime-folder', this.handleRealtimeFolder)
  }
  //#region handle socket

  handleRealtimeFolder = (data) => {
    try {
      if (data.projectId === this.props.location.state.projectId) {
        if (data.users.includes(this.props.userInfo.email)) {
          let permissionCurrent = false
          if (data.status === 'add-folder' || data.status === 'set-permission') {
            if (this.props.role === 'admin') permissionCurrent = true
            else if (checkWohhupUser(this.props.userInfo.email)) permissionCurrent = true
            else {
              if (data.permission.includes(this.state.currentRole)) permissionCurrent = true
            }
          }
          if (data.status === 'add-folder') {
            if (data.user !== this.props.userInfo.email) {
              // if (!permissionCurrent) return
              // let temp = this.state.folders.slice(0)
              // let index = _.findIndex(temp, e => { return e.folder.folderId === data.item.folder.folderId })
              // if (index <= 0) {
              //   temp.push(data.item)
              //   if (this.state.iconUpDOwn) {
              //     if (this.state.sortByValue === 'Name') {
              //       temp.sort(sortFolderName)
              //     } else if (this.state.sortByValue === 'Created Date') {
              //       temp.sort(sortItemCreatedDate)
              //     } else if (this.state.sortByValue === 'Updated Date') {
              //       temp.sort(sortItemUpdatedDate)
              //     }
              //   } else {
              //     if (this.state.sortByValue === 'Name') {
              //       temp.sort(sortDescendingFolderName)
              //     } else if (this.state.sortByValue === 'Created Date') {
              //       temp.sort(sortDescendingItemCreatedDate)
              //     } else if (this.state.sortByValue === 'Updated Date') {
              //       temp.sort(sortDescendingItemUpdatedDate)
              //     }
              //   }
              //   data.item.uploadDate = data.item.uploadDate
              //   data.item.createdDate = data.item.createdDate
              //   data.item.permission = permissionCurrent
              //   this.setState({ folders: temp })
              //   message.success(`Added folder ${data.item.folderName}`)
              // }
              this.handleAddFolder(this.state.folders, 'folders', data, permissionCurrent)
              this.handleAddFolder(this.state.searchFolders, 'searchFolders', data, permissionCurrent, true)
            }
          } else if (data.status === 'delete-folder') {
            if (data.user !== this.props.userInfo.email) {
              // let temp = this.state.folders.slice(0)
              // let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
              // if (index >= 0) {
              //   temp.splice(index, 1)
              //   this.setState({ folders: temp })
              //   message.success(`Deleted folder ${data.folderName}`)
              // }
              this.handleDeleteFolder(this.state.folders, 'folders', data)
              this.handleDeleteFolder(this.state.searchFolders, 'searchFolders', data, true)
            }
          } else if (data.status === 'set-permission') {
            if (data.user !== this.props.userInfo.email) {
              // let temp = this.state.folders.slice(0)
              // let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
              // if (index >= 0) {
              //   if (!permissionCurrent) {
              //     temp.splice(index, 1)
              //   }
              //   this.setState({ folders: temp })
              //   message.success(`${temp[index].folderName} set permission successful.`)
              // } else if (permissionCurrent) {
              //   temp.push(data.item)
              //   if (this.state.iconUpDOwn) {
              //     if (this.state.sortByValue === 'Name') {
              //       temp.sort(sortFolderName)
              //     } else if (this.state.sortByValue === 'Created Date') {
              //       temp.sort(sortItemCreatedDate)
              //     } else if (this.state.sortByValue === 'Updated Date') {
              //       temp.sort(sortItemUpdatedDate)
              //     }
              //   } else {
              //     if (this.state.sortByValue === 'Name') {
              //       temp.sort(sortDescendingFolderName)
              //     } else if (this.state.sortByValue === 'Created Date') {
              //       temp.sort(sortDescendingItemCreatedDate)
              //     } else if (this.state.sortByValue === 'Updated Date') {
              //       temp.sort(sortDescendingItemUpdatedDate)
              //     }
              //   }
              //   data.item.permission = permissionCurrent
              //   this.setState({ folders: temp })
              //   message.success(`${temp[index].folderName} set permission successful.`)
              // }

              this.handleSetPermission(this.state.folders, 'folders', data, permissionCurrent)
              this.handleSetPermission(this.state.searchFolders, 'searchFolders', data, permissionCurrent, true)
            }
          }
        }
      }
    } catch {
      console.log('realtime-folder-err')
    }

  }
  handleAddFolder = (folders, setFolder, data, permissionCurrent, isNotification = false) => {
    if (!permissionCurrent) return
    let temp = folders.slice(0)
    let index = _.findIndex(temp, e => { return e.folder.folderId === data.item.folder.folderId })
    if (index <= 0) {
      temp.push(data.item)
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        }
      }
      data.item.uploadDate = data.item.uploadDate
      data.item.createdDate = data.item.createdDate
      data.item.permission = permissionCurrent
      this.setState({ [setFolder]: temp })
      if (isNotification)
        message.success(`Added folder ${data.item.folderName}`)
    }
  }
  handleDeleteFolder = (folders, setFolder, data, isNotification = false) => {
    let temp = folders.slice(0)
    let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
    if (index >= 0) {
      temp.splice(index, 1)
      this.setState({ [setFolder]: temp })
      if (isNotification)
        message.success(`Deleted folder ${data.folderName}`)
    }
  }
  handleSetPermission = (folders, setFolder, data, permissionCurrent, isNotification = false) => {
    let temp = folders.slice(0)
    let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
    if (index >= 0) {
      if (!permissionCurrent) {
        temp.splice(index, 1)
      }
      this.setState({ [setFolder]: temp })
      if (isNotification)
        message.success(`${temp[index].folderName} set permission successful.`)
    } else if (permissionCurrent) {
      temp.push(data.item)
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        }
      }
      data.item.permission = permissionCurrent
      this.setState({ [setFolder]: temp })
      if (isNotification)
        message.success(`${temp[index].folderName} set permission successful.`)
    }
  }
  //#endregion

  //#region sort
  handleSortBy = (e, { value }) => {
    let temp = this.state.folders
    if (this.state.iconUpDOwn) {
      if (value === 'Name') {
        temp.sort(sortFolderName)
      } else if (value === 'Created Date') {
        temp.sort(sortItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortItemUpdatedDate)
      }
    } else {
      if (value === 'Name') {
        temp.sort(sortDescendingFolderName)
      } else if (value === 'Created Date') {
        temp.sort(sortDescendingItemCreatedDate)
      } else if (value === 'Updated Date') {
        temp.sort(sortDescendingItemUpdatedDate)
      }
    }
    this.setState({ folders: temp, sortByValue: value })
  }
  handleUpDown = () => {
    let temp = this.state.folders
    this.setState({ iconUpDOwn: !this.state.iconUpDOwn }, () => {
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        }
      }
      this.setState({ folders: temp })
    })
  }
  //#endregion 

  //#region control folder
  handleDeleteFolder = (data) => {
    axios.delete(`/api/folders/deletefolder`, {
      data: { projectId: data.projectId, folderId: data.folderId, folderName: data.folderName, email: data.email }
    })
      .then(res => {
        // let temp = this.state.folders.slice(0)
        // let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
        // if (index >= 0) {
        //   temp.splice(index, 1)
        //   this.setState({ folders: temp })
        // }
        // message.success(`Deleted folder ${data.folderName}`)
        this.handleCallbackDelete(data,this.state.folders, 'folders',)
        this.handleCallbackDelete(data,this.state.searchFolders, 'searchFolders', true)
      })
      .catch(err => {
        message.error(err.message)
      })
  }
  handleCallbackDelete = (data, folders, setFolders, isNotification) => {
    let temp = folders.slice(0)
    let index = _.findIndex(temp, e => { return e.folder.folderId === data.folderId })
    if (index >= 0) {
      temp.splice(index, 1)
      this.setState({ [setFolders]: temp })
    }
    if(isNotification)
    message.success(`Deleted folder ${data.folderName}`)
  }
  handleCallbackAddFolder = (data) => {
    // let temp = this.state.folders.slice(0)
    // let index = _.findIndex(temp, e => { return e.folder.folderId === data.folder.folderId })
    // if (index <= 0) {
    //   temp.push(data)
    //   if (this.state.iconUpDOwn) {
    //     if (this.state.sortByValue === 'Name') {
    //       temp.sort(sortFolderName)
    //     } else if (this.state.sortByValue === 'Created Date') {
    //       temp.sort(sortItemCreatedDate)
    //     } else if (this.state.sortByValue === 'Updated Date') {
    //       temp.sort(sortItemUpdatedDate)
    //     }
    //   } else {
    //     if (this.state.sortByValue === 'Name') {
    //       temp.sort(sortDescendingFolderName)
    //     } else if (this.state.sortByValue === 'Created Date') {
    //       temp.sort(sortDescendingItemCreatedDate)
    //     } else if (this.state.sortByValue === 'Updated Date') {
    //       temp.sort(sortDescendingItemUpdatedDate)
    //     }
    //   }
    //   data.uploadDate = data.uploadDate
    //   data.createdDate = data.createdDate
    //   this.setState({ folders: temp })
    //   message.success(`Added folder ${data.folderName}`)
    // }
    this.handleCallbackAdd(data,this.state.folders, 'folders',)
    this.handleCallbackAdd(data,this.state.searchFolders, 'searchFolders', true)
  }
  handleCallbackAdd = (data, folders, setFolders, isNotification) => {
    let temp = folders.slice(0)
    let index = _.findIndex(temp, e => { return e.folder.folderId === data.folder.folderId })
    if (index <= 0) {
      temp.push(data)
      if (this.state.iconUpDOwn) {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortItemUpdatedDate)
        }
      } else {
        if (this.state.sortByValue === 'Name') {
          temp.sort(sortDescendingFolderName)
        } else if (this.state.sortByValue === 'Created Date') {
          temp.sort(sortDescendingItemCreatedDate)
        } else if (this.state.sortByValue === 'Updated Date') {
          temp.sort(sortDescendingItemUpdatedDate)
        }
      }
      data.uploadDate = data.uploadDate
      data.createdDate = data.createdDate
      this.setState({ [setFolders]: temp })
      if (isNotification)
        message.success(`Added folder ${data.folderName}`)
    }
  }
  handleClickOpenSetRolePanel = (data) => {
    this.setState({ displayDialogSettingFolder: true, folderId: data.folderId, folderName: data.folderName })
  }
  handleCloseSettingPanel = () => {
    this.setState({ displayDialogSettingFolder: false })
  }
  handleDisplayCard = () => {
    this.setState({ isListCard: !this.state.isListCard })
  }
  //#endregion

  //#region sidebar viewpoint
  handleTableViewPoint = () => {
    if (!this.state.displaySidebarViewpoint) {
      this.setState({
        displayTableIssue: false,
        displayInfoIssue: false,
        displaySidebarViewpoint: true,
      })
    } else {
      this.setState({
        displayTableIssue: false,
        displayInfoIssue: false,
        displaySidebarViewpoint: false,
      })
    }

  }
  callbackDisplayPanel = () => {
    this.setState({ displaySidebarViewpoint: false })
  }
  //#endregion

  handleSearch = (value) => {
    if (value) {
      let temp = []
      _.forEach(this.state.folders, v => {
        if (v.folderName.includes(value)) {
          temp.push(v)
        }
      })
      this.setState({ searchFolders: temp })
    } else {
      this.setState({ searchFolders: this.state.folders })
    }
  }
  render() {
    var { isConfirmed, isAdmin, userInfo } = this.props;
    const tools = () => {
      return (
        <ButtonGroup size="md" style={{ float: 'right', paddingRight: 25 }}>

          <Search
            placeholder="Search"
            onSearch={this.handleSearch}
            style={{ width: 200, float: 'left' }}
          />
          <Tooltip title={'Show Views'}>
            <IconButton icon={<Icon icon="camera" />} active={this.state.displaySidebarViewpoint} color={this.state.displaySidebarViewpoint && 'red'}
              loading={this.state.loadingIssue} onClick={this.handleTableViewPoint} />
          </Tooltip>
          <Tooltip title={this.state.isDisplayName ? 'Show Display Name' : 'Show File Name'}>
            <IconButton icon={<Icon icon={this.state.isDisplayName ? "file" : 'file-o'} />} color={this.state.isDisplayName && 'blue'} onClick={this.handleDisplayName} />
          </Tooltip>
          <Tooltip title={'Show List'}>
            <IconButton icon={<Icon icon="list" />} active={!this.state.isListCard} onClick={this.handleDisplayCard} />
          </Tooltip>
          <Tooltip title={'Show Card'}>
            <IconButton icon={<Icon icon="th" />} active={this.state.isListCard} onClick={this.handleDisplayCard} />
          </Tooltip>
          <Popover placement='leftTop' content={
            this.state.listAdmin.map(i =>
              <Text>{i}</Text>)
          } title="Project Admin">
            <IconButton icon={<Icon icon='question-circle' />} />
          </Popover>

        </ButtonGroup>)
    }


    return (
      <React.Suspense fallback={
        <Loader center content="Loading..." speed="fast" size="md" vertical />
      }>

        {!this.state.error && (isConfirmed && (isAdmin || this.state.listAdmin.includes(this.props.userInfo.email))) ?// ||this.state.roleUploader
          <ButtonMenuFolder key={this.props.location.state.projectId}
            project={this.props.location.state} userCreatedProject={this.props.location.state.userCreatedProject} email={userInfo.email}
            endPoint={localStorage.getItem('endPoint')} handleCallbackAddFolder={this.handleCallbackAddFolder}
            permission={this.props.userInfo.permission} role={this.props.role} />
          : <div></div>}
        {this.state.error
          ? <ResultError />
          : <div>
            {!isConfirmed ? <ConfirmEmailMessage /> : (
              this.state.loading ?
                <Image src={WHLogoGif} style={{ position: 'absolute', top: 'calc(50% - 32px)', left: 'calc(50% - 32px)' }} />
                :
                (this.state.folders.length === 0 ?
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ top: 'calc(50% - 20px)', position: 'absolute', left: 'calc(50% - 32px)' }} /> :
                  this.state.isListCard ?
                    <div>
                      <div style={{ marginTop: 10, marginLeft: 25 }}>
                        Sort by{' '}
                        <Dropdown
                          inline onChange={this.handleSortBy}
                          options={sortByOptions} value={this.state.sortByValue}
                          defaultValue={sortByOptions[0].value}
                        />
                        <IconButton onClick={this.handleUpDown} size='xs' icon={<Icon icon={this.state.iconUpDOwn ? 'arrow-down2' : 'arrow-up2'} />} />
                        {tools()}
                      </div>
                      <LazyLoad >
                        <Card.Group style={{ margin: "5px" }}>
                          {this.state.searchFolders.map(item =>
                            <CardFolders key={item.folder.folderId} folder={item.folder} permission={item.permission}
                              project={this.props.location.state} isadmin={isAdmin} email={userInfo.email}
                              userCreatedProject={this.props.location.state.userCreatedProject}
                              isProjectAdmin={this.state.listAdmin.includes(this.props.userInfo.email)}
                              listAdmin={this.state.listAdmin}

                              handleDeleteFolder={this.handleDeleteFolder}
                              handleClickOpenSetRolePanel={this.handleClickOpenSetRolePanel} />
                          )}
                        </Card.Group>
                      </LazyLoad>
                    </div>
                    :
                    <div>

                      <div style={{ marginTop: 10, marginLeft: 25 }}>

                        {tools()}
                      </div>
                      <TableListFolder
                        columns={ColumnFolder(this)}
                        data={this.state.searchFolders}
                        getRowProps={row => ({})} getCellProps={rows => ({})}
                        email={userInfo.email}
                        projectId={this.props.location.state.projectId}
                        permission={userInfo.permission} isAdmin={isAdmin}
                        userCreatedProject={this.props.location.state.userCreatedProject}
                        handleDeleteFolder={this.handleDeleteFolder}
                        handleClickOpenSetRolePanel={this.handleClickOpenSetRolePanel}
                        roleUploader={this.state.roleUploader}
                        isProjectAdmin={this.state.listAdmin.includes(this.props.userInfo.email)}
                      />
                    </div>
                )
            )}
          </div>
        }





        {this.state.displaySidebarViewpoint &&
          <SidebarViewPoint displayPanel={this.state.displaySidebarViewpoint} viewer={null}
            onDisplayPanelChange={this.callbackDisplayPanel} itemId={null} projectId={this.props.location.state.projectId}
            email={this.props.userInfo.email} userCreatedProject={this.props.location.state.userCreatedProject} version={null}
            viewerPage={null} userRole={this.props.userRole} isProject={true}
          />}
        <BackTop style={{ bottom: 80, right: 30 }} />
        <DialogSettingFolder onShow={this.state.displayDialogSettingFolder} onClose={this.handleCloseSettingPanel}
          folderId={this.state.folderId} folderName={this.state.folderName} email={this.props.userInfo.email} />

      </React.Suspense>
    );
  }
}

ProjectPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === "admin@wohhup.com",
    role: state.user.role,
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(ProjectPage)

