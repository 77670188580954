import React from "react";
import { Modal } from "antd";
import { HotTable } from "@handsontable/react";
import rebarInvTempColumns from "../../function/DefineColumns/rebarInvTempColumns";
import { func_add_new_rebar_inv } from "../../function/func_add_new_do_inv";

const RebarInvInput = ({ visible, onCreate, onCancel }) => {
  var hotData = [[]];
  return (
    <Modal
      visible={visible}
      title="Key in rebar invoice data"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      style={{ pading: "0px" }}
      width={1300}
      onOk={() => {
        func_add_new_rebar_inv(hotData);
        hotData = [];
        onCreate();
      }}
    >
      <HotTable
        data={hotData}
        colHeaders={true}
        rowHeaders={true}
        height={500}
        columns={rebarInvTempColumns}
        minRows={20}
        minCols={12}
        colWidths={[85, 90, 85, 90, 170, 90, 90, 90, 90, 100, 85, 140]}
        contextMenu={["row_above", "row_below", "remove_row"]}
        licenseKey="non-commercial-and-evaluation"
      ></HotTable>
    </Modal>
  );
};

export default RebarInvInput;
