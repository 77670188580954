import { PDF_VIEWER } from '../../types';

const pdf_data = data => ({
  type: PDF_VIEWER,
  data
});






export const setPDFData = data => dispatch => {
  dispatch(pdf_data(data))
}


