import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'rsuite'
import TextField from '@material-ui/core/TextField';
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { message } from 'antd'
import axios from 'axios';
import { timelineData } from '../function/generateTimeline';
function DialogEditValue(props) {
    const [userId, setUserId] = useState('')
    useEffect(() => {
      if (props.cell.value) {
        setUserId(props.cell.value)

      }
    }, [])
    const handleOk = () => {
      if(userId!==''){
        axios.post('/api/manpower/update-user-id', {id:props.cell.data.id, oldUserId: props.cell.value, newUserId:userId, token: props.userInfo.token})
        .then(res => {
          let currentData = res.data.user
          let newData = {
            userId:   currentData.userId,  id: currentData.id, client: currentData.client, lead: currentData.lead, status: currentData.status, name: currentData.name, scope: currentData.scope, updatedBy: currentData.updatedBy,
            updatedAt: currentData.updatedAt, jobTitle: currentData.jobTitle, department: currentData.department, timelines: JSON.stringify(res.data.timelines)
          }
          timelineData(newData, res.data.timelines, props.typeTimeline)
          props.cell.node.setData(newData)

          props.setManpowerData({ loading: false })
          props.callbackDialog()
        })
        .catch(err => {
          props.setManpowerData({ loading: false })
          message.warning('Cannot update')
        })
        // props.cell.node.setDataValue(props.cell.column.colId, JSON.stringify({ projectName, power }))// JSON.stringify(`${projectName}_${power}`)
      }
    }
    const handleChangeUserId = (e) => {
      setUserId(e.target.value)
    }

    return (
      <>
        <Modal show={true} onHide={props.callbackDialog} size='xs' style={{ top: 60 }} backdrop >
          <Modal.Header>
            <Modal.Title>Edit Cell</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <TextField onChange={handleChangeUserId} autoFocus margin="dense" id="name" label="User Id" type="text" value={userId} fullWidth />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.callbackDialog} color="ghost"> Cancel</Button>
            <Button onClick={handleOk} color="primary" >OK</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
  DialogEditValue.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(DialogEditValue);







