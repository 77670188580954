import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FileFilled, FileOutlined } from '@ant-design/icons'

export default function FileCell(props) {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

   
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 , marginLeft: `${props.node.level * 40}px ` }} >
            <FileOutlined />
            <span> {cellValue}</span>
        </div>
    );
};