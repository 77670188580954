import React, { useEffect, useState } from 'react';
import { Typography, message, Select, Modal, List, Checkbox, Popover, Upload } from 'antd';
import axios from 'axios'
import _ from 'lodash'
const { Text } = Typography

export default function SetupObject(props) {
  const [items, setItems] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    axios.get("/api/items/get-item-by-type", { params: { projectId: props.projectId, typeFile: props.typeFile, role: props.role, token: props.userInfo.token } })
      .then(res => {
        setItems(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }, [])
  const handleOk = () => {
    props.handleClose()
  }
  const handleCancel = () => {
    props.handleClose()
  }
  const handleClickItem = (item) => {
    let state = JSON.parse(JSON.stringify(props.state))
    state.id = item.objectId
    state.itemId = item.itemId
    state.itemName = item.itemName
    state.itemDisplayName = item.itemDisplayName
    state.version = item.version
    state.typeFile = item.typeFile
    state.objectKey = item.objectKey
    state.userInfo = props.userInfo
    state.folderSmartSheetId = props.folderSmartSheetId
    state.smartSheetId = props.smartSheetId
    props.history.push({
      pathname: `/for5d/viewer`,
      state
    })
  }
  return (
    <Modal title="Pick Model" visible={true} onOk={handleOk} onCancel={handleCancel} footer={<></>}>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={items}
        bordered={true}
        renderItem={item => (
          <List.Item onClick={handleClickItem.bind(this, item)}>
            <Text> {item.itemName}</Text>
          </List.Item>
        )}
      />
    </Modal>
  )
}