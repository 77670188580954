import React from "react";
import axios from "axios";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import { Modal, InputNumber, Form, Input, DatePicker } from "antd";
import { func_add_new_conc_do } from "../../function/func_add_new_do_inv";
import concDOTempColumns from "../../function/DefineColumns/concDOTempColumns";

const ConcDoInput = ({ visible, onCreate, onCancel }) => {
   var hotData = [[]];

   return (
      <Modal
         visible={visible}
         title="Key in Concrete DO data"
         okText="Create"
         cancelText="Cancel"
         onCancel={onCancel}
         style={{ pading: "0px" }}
         width={1275}
         onOk={() => {
            func_add_new_conc_do(hotData);
            hotData = [];
            onCreate();
         }}
      >
         <HotTable
            data={hotData}
            colHeaders={true}
            rowHeaders={true}
            height={500}
            columns={concDOTempColumns}
            minRows={20}
            minCols={12}
            currentRowClassName={"currentRow"}
            colWidths={[100, 100, 90, 90, 190, 100, 115, 90, 90, 75, 135]}
            contextMenu={["row_above", "row_below", "remove_row"]}
            className="do-invoice-table handsontable_do"
            licenseKey="non-commercial-and-evaluation"
         ></HotTable>
      </Modal>
   );
};

export default ConcDoInput;
