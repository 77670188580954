import React, { useState, useEffect } from 'react';

import { getAvatar } from '../../api/employee';

import _ from 'lodash';

import { Avatar, Modal, Button } from 'antd';

function AvatarKeyPlan(props) {
  const [image, setImage] = useState(null);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    if (props.imageId) {
      getImage();
    }
  }, [props.imageId]);

  async function getImage() {
    let res = await getAvatar({ key: props.imageId, time: 60 });
    setImage(res);
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {image ? (
        <img
          id='myImg'
          onClick={() => setIsModal(true)}
          src={image}
          style={{ height: '150px' }}
        />
      ) : (
        'No Key Plan'
      )}

      <div
        id='myModal'
        class='modal'
        style={{ display: isModal ? 'block' : '' }}
      >
        <Button onClick={() => setIsModal(false)} className="float-right">
          &times;
        </Button>
        <img class='modal-content' src={image} id='img01' />
      </div>
    </div>
  );
}

export default AvatarKeyPlan;
