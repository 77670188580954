const concDOTempColumns = [
   {
      type: "text",
      data: "do_number",
      title: "DO No",
   },

   {
      type: "date",
      data: "do_date",
      title: "Do Date",
      dateFormat: "DD-MM-YYYY",
      datePickerConfig: {
         dateFormat: "DD-MM-YYYY",
      },
   },

   {
      type: "numeric",
      data: "volume",
      title: "Volume",
   },

   {
      type: "text",
      data: "grade",
      title: "Grade",
   },

   {
      type: "text",
      data: "product",
      title: "Product",
   },
   {
      // type: "text",
      title: "Supplier",
      data: "supplier",
      editor: "select",
      selectOptions: ["ALLIANCE", "PAN U", "ISLAND"],
   },

   {
      title: "Use for",
      data: "use_for",
      type: "dropdown",
      source: ["Permanent work", "Temporary work", "Back charge"],
   },

   {
      type: "text",
      data: "pour_name",
      title: "Pour Name",
   },
   {
      type: "text",
      data: "order_by",
      title: "Order by",
   },

   {
      type: "text",
      data: "checks",
      title: "Checks",
      readOnly: true,
   },
   {
      type: "text",
      data: "remark",
      title: "Remark",
   },
];

export default concDOTempColumns;
