import { Button, Result } from 'antd';
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Loader } from 'rsuite';
import { storeProjectDetail } from '../../../actions/app/app';
import ComponentNavigation from '../../module_DMS/dms/layouts/generalComponents/ComponentNavigation';
import {checkWohhupUser,trackingUserUsing} from '../../module_BimApp/function/AdminFunction'


const DMSApp = (props) => {
  const { path, url } = useRouteMatch();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState('500')
  const [loadSheet, setLoadSheet] = useState(false)
  const [dataUser, setDataUser] = useState(null)
  const [companies, setCompanies] = useState(null)
  const [listUser, setListUser] = useState([])
  const [listGroup, setListGroup] = useState([])
  const [useRfa, setUseRfa] = useState(false)
  const [mode, setMode] = useState('')
  useEffect(() => {
    trackingUserUsing(props.userInfo.email, props.location.state.projectId,'DMS','None')
    setLoading(true)
    props.storeProjectDetail({
      projectId: props.location.state.projectId,
      projectName: props.location.state.projectName,
      userCreatedProject: props.location.state.userCreatedProject,
      sharePointId: props.location.state.sharePointId,
      issueKey: props.location.state.issueKey,
      trimbleKey: props.location.state.trimbleKey,
      typeProject: props.location.state.typeProject,
      useRfa: props.location.state.useRfa,
      isDetail: true
    })
    if (!props.location.state) {
      props.history.push('/')
      return
    }
    if (!props.location.state.projectId) {
      props.history.push('/')
      return
    }
    axios.post('/api/users/get-me-information', { token: props.userInfo.token })
      .then(async res => {
        if (res.data.user.email !== props.userInfo.email) {
          setLoading(false)
          setError(true)
          setErrorCode('500')
          return
        }
        let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === props.location.state.projectId })
        if (res.data.user.email !== 'admin@wohhup.com') {
          if (index < 0 || !res.data.userInfo.contractors[index].isAccessDashboardDrawing) {
            // setLoading(false)
            // setError(true)
            // setErrorCode('400')
            // return
          }
        }
        let role = undefined
        let company = undefined
        if (index >= 0) {
          role = res.data.userInfo.contractors[index].role
          company = res.data.userInfo.contractors[index].company
        }
        let res1 = await axios.get(`/api/projects/members?q=${props.location.state.projectId}`)
          .catch(() => { })
        let tempUser = []
        res1.data.members.sort()
        res1.data.members.splice(0, 0, props.location.state.userCreatedProject)
        _.forEach(res1.data.members, v => {
          if (v !== 'admin@wohhup.com') {
            tempUser.push(v)
          }
        })
        let tempGroup = []
        _.forEach(res1.data.groupUser, v => {
          tempGroup.push(v.name)
        })
        setListUser(tempUser)
        setListGroup(tempGroup)
        axios.post(`/api/projects/get-information-project`, { projectId: props.location.state.projectId, token: props.userInfo.token })
          .then(res1 => {
            setUseRfa(res1.data.useRfa)
            setDataUser({
              email: res.data.user.email,
              role: res.data.user.role === 'admin' ? 'Document Controller' : role ? role : 'viewer', userName: res.data.userInfo.userName,
              company: res.data.user.role === 'admin' ? 'Woh Hup Private Ltd' : checkWohhupUser(res.data.user.email) ? 'Woh Hup Private Ltd' : company
            })
            let companies = []
            _.forEach(res1.data.listContractor, v => {
              if (v.company === 'Woh Hup Private Ltd') {
                companies.push({ company: v.company, companyType: v.companyType, fullName: v.fullName , shortName: v.shortName })
              } else {
                companies.push({ company: v.company, companyType: v.companyType, trade: v.isARC ? 'ARCHI' : v.isSTR ? 'C&S' : v.isMEP ? 'M&E' : v.isPrecast && 'Precast', fullName: v.fullName  , shortName: v.shortName })
              }

            })
            setCompanies(companies)
            setLoadSheet(true)
          })
          .catch(() => {
            setLoading(false)
          })

      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setMode(window.location.pathname)
  }, [])

  return (

    <>
      {!error ?
        loadSheet ?
          dataUser !== null &&
          <Switch>

            {/* <Route location={location} path='/' exact component={HomePage} history={history} /> */}

            {['spreadsheet', 'rfa', 'rfam', 'rfi', 'cvi', 'dt', 'mm','eri', 'lr', 'sm', 'sum', 'qr', 'qrm', 'rfc', 'cons','subco', 'authorities-submission'].map(routeName => (
              <Route exact path={`${path}/${routeName}`} key={routeName}>
                <ComponentNavigation
                  history={props.history}
                  // email={dataUser.email}
                  // projectId={props.location.state.projectId}
                  // projectName={props.location.state.projectName}
                  // role={dataUser.role}
                  // isAdmin={props.isAdmin}
                  // username={dataUser.userName}
                  token={props.userInfo.token}

                  company={dataUser.company}
                  companies={companies}
                  listUser={listUser}
                  listGroup={listGroup}
                  projectIsAppliedRfaView={useRfa}
                  projectName={props.location.state.projectName}
                  projectNameShort={props.location.state.issueKey}
                  localState={props.location.state}
                  pageSheetTypeName={`page-${routeName}`}
                />
              </Route>
            ))}

    
          </Switch>
          :
          <Loader center content="Loading..." speed="fast" size="md" backdrop vertical style={{ zIndex: 100000 }} />
        :
        <Result
          status={errorCode === '400' ? "warning" : 'error'}
          title={errorCode}
          subTitle={errorCode === '400' ? "Please, contact admin project to access." :       <>
          <p>Sorry, we are not able to connect to server at moment. Please try again later.</p>
          <p>You can contact to our web admin at phan_manhquyet@wohhup.com for feedback or any urgent issue. He is also can reach at number +6596969942.</p>
        </>}
          extra={[
            <Button type="primary" key="console" onClick={() => props.history.push("/")}>Back Home</Button>,

          ]}
        >
        </Result>}
    </>
  );
};


DMSApp.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  storeProjectDetail: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    userInfo: state.user,
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
  };
}
export default connect(mapStateToProps,{storeProjectDetail})(DMSApp)
