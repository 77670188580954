import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Row, Col, Input, Modal, Upload, Button, message, Form, Tooltip, Divider } from 'antd';
import _ from 'lodash';
import axios from 'axios';
import { v4 } from 'uuid';
import { AgGridReact } from 'ag-grid-react';


const { Option } = Select;

const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';
const getRouteToNode = (rowNode) => {
    if (!rowNode.parent) {
        return [];
    }
    return [
        ...getRouteToNode(rowNode.parent),
        rowNode.key ? rowNode.key : rowNode.data.employeeName,
    ];
};
export function ModalEditSubUpD({ file, setIsLoading, gridApi, ...props }) {


    const [permission, setPermission] = useState({
        permissionView: file.data?.permissionView,
        permissionCreateFolder: file.data?.permissionCreateFolder,
        permissionUpload: file.data?.permissionUpload,
    })

    const [consultants, setConsultants] = useState([])
    const [folderName, setFolderName] = useState(file.data.name)
    useEffect(() => {
        if (props.listGroup) {
            let tempGroupCompany = []
            let tempGroup = []
            let tempUser = []
            _.forEach(props.listGroup, v => {
                let rep = v.split('_%$%_')
                if (rep.length === 2) {
                    let item1 = rep[0].replace(/^H|d$/g, "")
                    let item2 = rep[1].replace(/^H|d$/g, "")
                    tempGroupCompany.push({ id: v, name: `${item1}: ${item2}` })
                } else {
                    let item1 = rep[0].replace(/^H|d$/g, "")
                    tempGroup.push({ id: v, name: `${item1}` })
                }
            })
            _.forEach(props.listUser, v => {
                tempUser.push({ id: v, name: v })
            })
            tempGroupCompany.sort((optionA, optionB) => (optionA.name ?? '').toLowerCase().localeCompare((optionB.name ?? '').toLowerCase()))
            tempGroup.sort((optionA, optionB) => (optionA.name ?? '').toLowerCase().localeCompare((optionB.name ?? '').toLowerCase()))
            tempUser.sort((optionA, optionB) => (optionA.name ?? '').toLowerCase().localeCompare((optionB.name ?? '').toLowerCase()))
            setConsultants([
                { name: "Email", list: tempUser },
                { name: "Group", list: tempGroup },
                { name: "Company Group", list:  tempGroupCompany }
            ])
        }

    }, [])


    const handleOk = async (e) => {
        if (!folderName) return
        try {
            setIsLoading(true)
            let res = await axios({
                url: '/api/subcon-upload-file',
                method: 'PUT',
                data: {
                    token: props.token,
                    projectId: props.storeInitProject.projectId,
                    name: folderName,
                    _id: file.data._id,
                    ...permission
                }
            })
            if (res.data) {
                const route = getRouteToNode(file)
                gridApi.api.applyServerSideTransaction({
                    route: route.slice(0, route.length - 1),
                    update: [res.data]
                })
                handleCancel()
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }




    const handleCancel = () => {
        props.setClose()
    }


    const handleSelectConsultant = (type, e) => {

        setPermission({ ...permission, [type]: e })
    }


    const handleFolderName = (e) => {

        setFolderName(e.target.value)
    }

    return (
        <Modal title="Upload File" visible={true} onOk={handleOk} onCancel={handleCancel} width={'40%'}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }} >
                <div style={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}   >
                    <span style={{ fontWeight: 'bold', width: 200 }}>Folder Name</span>
                    <Input placeholder='Folder Name' value={folderName} onChange={handleFolderName} />

                </div>

                {file?.level == 1 &&
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}   >
                            <span style={{ fontWeight: 'bold', width: 200 }}>Permission View</span>
                            <Select showSearch placeholder='Permission View' value={permission.permissionView} style={{ width: '100%' }}
                                onChange={handleSelectConsultant.bind(this, 'permissionView')}
                                filterOption={(input, option) =>
                                    (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
                                }
                                mode='multiple'
                            >
                                {consultants.map(v =>
                                    <Select.OptGroup label={v?.name}>
                                        {v?.list.map(i =>
                                            <Select.Option key={i?.id} value={i.id} >{i?.name}</Select.Option>
                                        )}
                                    </Select.OptGroup>
                                )}
                            </Select>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}   >
                            <span style={{ fontWeight: 'bold', width: 200 }}>Permission Create Folder</span>
                            <Select showSearch placeholder='Permission Create Folder' value={permission.permissionCreateFolder} style={{ width: '100%' }}
                                onChange={handleSelectConsultant.bind(this, 'permissionCreateFolder')}
                                filterOption={(input, option) =>
                                    (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
                                }
                                mode='multiple'
                            >
                                {consultants.map(v =>
                                    <Select.OptGroup label={v?.name}>
                                        {v?.list.map(i =>
                                            <Select.Option key={i?.id} value={i.id} >{i?.name}</Select.Option>
                                        )}
                                    </Select.OptGroup>
                                )}

                            </Select>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}   >
                            <span style={{ fontWeight: 'bold', width: 200 }}>Permission Upload </span>
                            <Select showSearch placeholder='Permission Upload' value={permission.permissionUpload} style={{ width: '100%' }}
                                onChange={handleSelectConsultant.bind(this, 'permissionUpload')}
                                filterOption={(input, option) =>
                                    (option.key?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA.key ?? '').toLowerCase().localeCompare((optionB.key ?? '').toLowerCase())
                                }
                                mode='multiple'
                            >
                                {consultants.map(v =>
                                    <Select.OptGroup label={v?.name}>
                                        {v?.list.map(i =>
                                            <Select.Option key={i?.id} value={i.id} >{i?.name}</Select.Option>
                                        )}
                                    </Select.OptGroup>
                                )}

                            </Select>

                        </div>
                    </>
                }
            </div>
        </Modal>
    );
}


const SelectRender = (consultants) => {
    return (
        <>
            {consultants.map(v =>
                <Select.OptGroup label={v?.name}>
                    {v?.list.map(i =>
                        <Select.Option key={i?.id} value={i.id} >{i?.name}</Select.Option>
                    )}
                </Select.OptGroup>
            )}
        </>
    )
}