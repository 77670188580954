import React from 'react';
import { IconButton, Modal, Icon } from 'rsuite';
import _ from 'lodash'
import { Typography } from 'antd';
import { Button, Checkbox, Dropdown, } from 'semantic-ui-react'
const { Text } = Typography;

const DialogUnitHandedOver = ((props) => {
    const {
        show, close, data
    } = props;
    let notAcceptedItemsComponent = (notAcceptedItems) => {
        if (notAcceptedItems) {
            if (notAcceptedItems.length) {
                return <>
                    <Text strong>  Not accepted items: </Text><br />
                    <div>
                        {_.map(notAcceptedItems, e => {
                            return <div style={{ marginLeft: 10 }}>{e}</div>
                        })}
                    </div>
                </>
            } else {
                return <>
                    <Text strong>  Not accepted items: None </Text><br />
                </>
            }
        }

    }
    return (
        <Modal show={show} onHide={close} size='xs' className='unit-modal'
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <Modal.Header>
                <Modal.Title >Unit detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Text strong>Unit: {data.unit}</Text><br />
                <Text>
                    Handed over date: {data.handedOverDate}<br />
                    QM representative: {data.qMRepresentative}<br />
                    Archi representative: {data.archiRepresentative}<br />
                    <br />
                    {notAcceptedItemsComponent(data.notAcceptedItems)}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} color="blue">
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    );
})

export default DialogUnitHandedOver