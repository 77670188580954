import React, { useState, useEffect } from 'react';
import { search, add, deletes, update } from '../../api/workingTime';
import { getConfigByKey, addConfig } from '../../api/config';

import { CONFIG } from '../../constant/index';
import {
  Table,
  Divider,
  Input,
  Icon,
  Pagination,
  Modal,
  notification,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  Button,
  Checkbox,
} from 'antd';
import WorkingTimeForm from '../Forms/WorkingTimeForm';
import TopActionBar from '../Common/TopActionBar';
import HolidayTable from './HolidayTable';
import _ from 'lodash';
const { Column } = Table;

function WorkingTime(props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rows, setRows] = useState([]);
  const [id, setId] = useState(null);
  const [record, setRecord] = useState(null);
  const [cutOffDate, setCutOffDate] = useState(20);
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10,
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    total: 1,
  });

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getDatas();
  }, [searchRequest]);

  const getDatas = async () => {
    let res = await search(searchRequest);

    if (res.data?.items) {
      let nightDatas = _.orderBy(
        res.data?.items.filter((x) => x.shiftType == 1),
        ['dateType', 'start'],
        'asc'
      );

      let dayDatas = _.orderBy(
        res.data?.items.filter((x) => x.shiftType != 1),
        ['dateType', 'start'],
        'asc'
      );

      let dts = [...dayDatas, ...nightDatas];
      await setDatas(dts);
    } else {
      await setDatas([]);
    }

    await setPagination({
      pageSize: res.data.pageSize,
      showSizeChanger: true,
      total: res.data.totalCount,
    });
  };

  const getConfig = async () => {
    let res = await getConfigByKey({ key: CONFIG.CUT_OFF_DATE });
    if (res.data && res.data.value) {
      await setCutOffDate(res.data.value);
    } else {
      await addConfigCutOffDate(20);
    }
  };

  async function addConfigCutOffDate(value) {
    await addConfig({ key: CONFIG.CUT_OFF_DATE, value: value });
    notification['success']({
      message: 'Success',
      description: 'Update successfully.',
    });
  }

  const onChange = async (current, pageSize) => {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }

    await getDatas();
  };

  const onSelectedRowKeysChange = (selectedRowKeys, rows) => {
    setRows(rows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleShowDialogEdit = (record) => {
    setVisible(true);
    setId(record.id);
    setRecord(record);
    setIsAdd(false);
  };

  function handleShowDialogAdd() {
    setIsAdd(true);

    setVisible(true);
  }

  function getDateType(intDateType) {
    let vl = '';
    switch (intDateType) {
      case 1:
        vl = 'Normal Day';
        break;
      case 2:
        vl = 'Sunday';
        break;
      case 3:
        vl = 'Public Holiday';
        break;
      default:
        vl = 'Unknown';
    }
    return vl;
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  function onChangeCutOffDate(value) {
    setCutOffDate(value);
  }

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <div>
            <h2>Cut Off Date</h2>
            <div className='d-flex flex-row align-items-center mb-4'>
              <label className='mr-2'>Cut-Off Date :</label>

              <InputNumber
                min={1}
                max={31}
                value={cutOffDate}
                onChange={onChangeCutOffDate}
                className='mr-2'
              />

              <Button
                onClick={() => addConfigCutOffDate(cutOffDate)}
                type='primary'
              >
                Save
              </Button>
            </div>
          </div>
        </Col>
      </Row>



      <Row gutter={16}>
        <Col>
          <div>
            <h2>Pay rate</h2>

            <TopActionBar
              handleSearch={handleSearch}
              handleShowDialogAdd={handleShowDialogAdd}
            // handleDeleteItems={handleDeleteItems}
            />

            <Table
              loading={_.isNil(datas)}
              dataSource={datas}
              bordered
              className='antTable'
              rowKey={(record) => record.id}
              rowSelection={rowSelection}
              pagination={false}
            >
              <Column
                width='120px'
                title='Date Type'
                key='dateType'
                dataIndex='dateType'
                render={(text, record) => (
                  <span>{getDateType(record.dateType)}</span>
                )}
              />
                    <Column
                title='Shift Type'
                key='shiftType'
                width='120px'
                render={(text, record) => (
                  <p>{record.shiftType == 1 ? 'Night Shift' : 'Day Shift'}</p>
                )}
              />
              <Column title='Time Type' dataIndex='name' key='name' />
              <Column title='Time Start' dataIndex='start' key='start' />
              <Column title='Time End' dataIndex='end' key='end' />
              <Column title='Rate' dataIndex='rate' key='rate' />
              <Column title='Remark' dataIndex='remark' key='remark' />
        
              <Column
                width='120px'
                title='Action'
                key='action'
                render={(text, record) => (
                  <span>
                    <Icon
                      onClick={() => handleShowDialogEdit(record)}
                      style={{ fontSize: '20px', color: '#08c' }}
                      type='edit'
                    />

                    <Divider type='vertical' />

                    <Popconfirm
                      title='Are you sure delete this?'
                      onConfirm={() => handleDeleteItems([record.id])}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Icon
                        style={{ fontSize: '20px', color: '#08c' }}
                        type='delete'
                      />
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>

            <Modal
              title='Working Time Type'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <WorkingTimeForm
                id={id}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>
          </div>
        </Col>
      </Row>

      <Row gutter={16} className="mt-2">
        <Col>
          <HolidayTable />
        </Col>
      </Row>
    </div>
  );
}

export default WorkingTime;
