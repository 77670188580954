import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { DatePicker, Button } from "antd";

import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { valueFormatterForDouble } from "../common";
const { MonthPicker } = DatePicker;
const dateFormat = "MMM-YYYY";

const defaultColumn = [
   {
      headerName: "No.",
      field: "no",
      // filter: true,
      pinned: "left",
      width: 40,
   },
   {
      headerName: "Supervisor",
      field: "supervisor",
      filter: true,
      // rowGroup: true,
      pinned: "left",
      width: 120,
   },
   {
      headerName: "Worker Name",
      field: "name",
      // filter: true,
      pinned: "left",
      width: 175,
   },
   {
      headerName: "ECode",
      field: "code",
      // filter: true,
      pinned: "left",
      width: 90,
   },

   {
      headerName: "T-card",
      field: "timecard",
      pinned: "left",
      cellRenderer: (params) => {
         return (
            <button
               onClick={(field) => {
                  console.log("params:", params);
                  const splitText = params.value.split("__");
                  if (splitText[0] !== "null") {
                     axios
                        .get("/api/worker/worker-shift/gets3url", {
                           params: { file_name: splitText[0], bucket_name: splitText[1] },
                        })
                        .then((res) => {
                           const signedrequesturl = res.data.signedRequest;
                           window.open(signedrequesturl);
                        });
                  }
               }}
            >
               {params.value ? (!params.value.includes("null") ? "View" : null) : null}
            </button>
         );
      },

      width: 80,
   },
];

function TimeCardTable(props) {
   const gridRef = useRef();
   const { projectId, cutOffDate } = props;
   const [month, setMonth] = useState(moment().format(dateFormat));
   const [rowDatas, setRowDatas] = useState([]);
   const [data, setData] = useState([]);

   const [columnDefs, setColumnDefs] = useState();

   useEffect(() => {
      GetDataTable();
   }, [month]);

   function getCellStyle(params) {
      return _.has(params.data, `${params.colDef.field}DayType`) && params.data[`${params.colDef.field}DayType`]
         ? {
              backgroundColor: params.data[`${params.colDef.field}DayType`],
              //   "border-right": params.colDef.field === "eh" ? "1px solid" : "1px solid",
           }
         : null;
   }

   const onChange = (date, dateString) => {
      setMonth(dateString);
      console.log("dateString", dateString);
      console.log(date, dateString);
   };

   const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf("month");
   };

   const onBtExport = useCallback(() => {
      gridRef.current.api.exportDataAsExcel();
   }, []);

   async function GetDataTable() {
      const res = await axios.get(`/api/worker/worker-shift/${projectId}/get-workers-timecard-with-hubble-by-month`, {
         params: {
            date: month,
         },
      });
      const data_worker = res.data.data;
      setData(data_worker);
      //get payload- temporary to worker out worker timecard Sept
      const list_id = data_worker.map((x) => x.id);
      const list_Ecode = data_worker.map((x) => x.eCode);
      const payload = {
         month: moment(month, "MMM-YYYY").format("MM-YYYY"),
         projectId: projectId,
         ids: list_id,
         eCodes: list_Ecode,
      };

      console.log("worker timecard pdf payload (temp): ", payload);

      let dataForTable = [];

      let columns = [...defaultColumn];

      let startMoment = moment(month, "MMM-YYYY")
         .subtract(1, "months")
         .set("date", cutOffDate + 1);

      let colorHomeLeave = "#FF99FF";
      let colorAbsent = "#FF0000";
      let colorCourse = "#FFC000";
      let colorMc = "#00FF00";

      let offDayColors = [
         {
            name: "Medical (MC)",
            value: colorMc,
         },
         {
            name: "Course / Training",
            value: colorCourse,
         },
         {
            name: "Home Leave",
            value: colorHomeLeave,
         },
         {
            name: "Absent",
            value: colorAbsent,
         },
      ];

      let isFirstAddColumns = false;
      data_worker.forEach((worker, index) => {
         let rowData = {
            no: index + 1,
            code: worker.eCode,
            name: worker.workerName,
            supervisor: worker.supervisorName,
            timecard: worker.s3Key + "__" + worker.bucket,
         };

         let startMomentTemp = moment(startMoment);

         worker.dateInfo.forEach((x, dayIndex) => {
            let rowColumnByDayPrefix = `D${startMomentTemp.format("YYYYMMDD")}`;

            if (!isFirstAddColumns) {
               columns.push({
                  headerName: startMomentTemp.format("DD-MM-YYYY"),
                  children: [
                     {
                        headerName: "S",
                        field: `${rowColumnByDayPrefix}s`,
                        width: 60,
                        cellStyle: getCellStyle,
                        // aggFunc: "sum",
                        // valueFormatter: valueFormatterForDouble,
                     },

                     {
                        headerName: "SH",
                        field: `${rowColumnByDayPrefix}sh`,
                        width: 60,
                        cellStyle: getCellStyle,
                        // aggFunc: "sum",
                        // valueFormatter: valueFormatterForDouble,
                     },
                     {
                        headerName: "E",
                        field: `${rowColumnByDayPrefix}e`,
                        width: 60,
                        cellStyle: getCellStyle,
                        // aggFunc: "sum",
                        // valueFormatter: valueFormatterForDouble,
                     },
                     {
                        headerName: "EH",
                        field: `${rowColumnByDayPrefix}eh`,
                        width: 60,
                        cellStyle: getCellStyle,
                        // aggFunc: "sum",
                        // valueFormatter: valueFormatterForDouble,
                     },
                  ],
               });
            }

            rowData[`${rowColumnByDayPrefix}s`] = x.timeStart;
            let startH = moment(x.HubbleStart).format("HH:mm");
            if (startH === "Invalid date") {
               startH = "-:-";
            }
            rowData[`${rowColumnByDayPrefix}sh`] = startH;

            rowData[`${rowColumnByDayPrefix}e`] = x.timeEnd;
            let endH = moment(x.HubbleEnd).format("HH:mm");
            if (endH === "Invalid date") {
               endH = "-:-";
            }
            rowData[`${rowColumnByDayPrefix}eh`] = endH;

            if (x.timeStartDiscrepancy) {
               rowData[`${rowColumnByDayPrefix}sDayType`] = "#FC7250";
               rowData[`${rowColumnByDayPrefix}shDayType`] = "#FC7250";
            }

            if (x.timeEndDiscrepancy) {
               rowData[`${rowColumnByDayPrefix}eDayType`] = "#FC7250";
               rowData[`${rowColumnByDayPrefix}ehDayType`] = "#FC7250";
            }

            if (x.isDayOff) {
               rowData[`${rowColumnByDayPrefix}sDayType`] = colorHomeLeave;
               rowData[`${rowColumnByDayPrefix}shDayType`] = colorHomeLeave;
               rowData[`${rowColumnByDayPrefix}eDayType`] = colorHomeLeave;
               rowData[`${rowColumnByDayPrefix}ehDayType`] = colorHomeLeave;
            }

            if (x.isOffDayHasToPay) {
               console.log(" x.shiftTrades isOffDayHasToPay", x.shiftTrades);

               let isMc = x.offDayType == "Medical (MC)";

               let isTraining = x.offDayType == "Course / Training";
               if (isMc) {
                  rowData[`${rowColumnByDayPrefix}sDayType`] = colorMc;
                  rowData[`${rowColumnByDayPrefix}shDayType`] = colorMc;
                  rowData[`${rowColumnByDayPrefix}eDayType`] = colorMc;
                  rowData[`${rowColumnByDayPrefix}ehDayType`] = colorMc;
               }

               if (isTraining) {
                  rowData[`${rowColumnByDayPrefix}sDayType`] = colorCourse;
                  rowData[`${rowColumnByDayPrefix}shDayType`] = colorCourse;
                  rowData[`${rowColumnByDayPrefix}eDayType`] = colorCourse;
                  rowData[`${rowColumnByDayPrefix}ehDayType`] = colorCourse;
               }
            }

            //check is sunday
            if (startMomentTemp.isoWeekday() === 7) {
               rowData[`${rowColumnByDayPrefix}sDayType`] = "#acb9ca";
               rowData[`${rowColumnByDayPrefix}shDayType`] = "#acb9ca";
               rowData[`${rowColumnByDayPrefix}eDayType`] = "#acb9ca";
               rowData[`${rowColumnByDayPrefix}ehDayType`] = "#acb9ca";
            }

            //public holiday
            if (x.dayType == 3) {
               rowData[`${rowColumnByDayPrefix}s`] = 0;
               rowData[`${rowColumnByDayPrefix}sh`] = x.phWidthRate;
               rowData[`${rowColumnByDayPrefix}sDayType`] = "#c6e0b4";
               rowData[`${rowColumnByDayPrefix}shDayType`] = "#c6e0b4";
               rowData[`${rowColumnByDayPrefix}eDayType`] = "#c6e0b4";
               rowData[`${rowColumnByDayPrefix}ehDayType`] = "#c6e0b4";
            }

            startMomentTemp = startMomentTemp.add(1, "days");
         });

         isFirstAddColumns = true;

         dataForTable.push(rowData);
      });

      setRowDatas(dataForTable);

      console.log("dataForTable wages", dataForTable);
      setColumnDefs(columns);
   }

   return (
      <div>
         <div style={{ display: "flex", flexDirection: "row", height: "55px" }}>
            <h5 style={{ marginTop: "12px", paddingRight: "13px" }}>Select month: </h5>
            <MonthPicker
               format="MMM-YYYY"
               size="medium"
               picker="month"
               defaultValue={moment(moment(), "MMM-YYYY")}
               style={{ marginTop: "10px", textAlign: "center", width: "150px" }}
               disabledDate={disabledDate}
               onChange={onChange}
            />
            <Button style={{ margin: "11px" }} onClick={onBtExport}>
               Export to Excel
            </Button>
         </div>

         <div className="ag-theme-alpine" style={{ height: "80vh", boxSizing: "border-box" }}>
            <AgGridReact
               ref={gridRef}
               className="ag-theme-balham"
               reactUi="true"
               rowData={rowDatas}
               columnDefs={columnDefs}
               pagination={true}
               groupIncludeFooter={true}
               groupIncludeTotalFooter={true}
            ></AgGridReact>
         </div>
      </div>
   );
}

export default TimeCardTable;
