import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';

import _ from 'lodash'
// import '../../scss/SideBar.scss'
import TreeView from './TreeView'
import Split from 'react-split'
import { Icon, IconButton } from 'rsuite';
import { ResizeSensor } from "@blueprintjs/core";
import { Modal, Input, Button, Divider, Table } from 'antd';
import { paseValueCIP, shareDataGantt, extractInfo, arrangeSchedule, pushExcelToServer,calculateQTT } from '../../../components/module_BimApp/function/ExcelFunction'
import ButtonGroup from 'antd/lib/button/button-group';
import { socket } from '../../../components/module_BimApp/function/AdminFunction'

const { TextArea } = Input;
function ExcelPage(props) {
  const [showView3D, setShowView3D] = useState(false)
  const [showValueDialog, setShowValueDialog] = useState(false)
  const [loadingExtract, setLoadingExtract] = useState(false)
  const [loadingArrange, setLoadingArrange] = useState(false)

  useEffect(() => {
    window.shareDataForge=[]
    socket.on('realtime-excel', handleExcelData)
    return () => {
      socket.removeListener('realtime-excel', handleExcelData)
    }
  }, [])
  const handleExcelData = (data) => {
    if (data.email === props.userInfo.email) {
      window.shareDataForge.push(data.data)
      window.excelFileCount--
      if (window.excelFileCount === 0) {
        calculateQTT(window.shareDataForge)
      }
    }
   
  }
  const handleGetForgeData = () => {
    pushExcelToServer(props.userInfo.email)
  }
  useEffect(() => {
    if (!props.excelPage) {
      props.history.push('/')
    }
  }, [props.excelPage])
  useEffect(() => {
    if (!showView3D) {
      $('#content3D').hide();
      $('#contentGantt').width('100%');
      $('.gutter-horizontal').hide();
    } else {
      $('#content3D').show();
      $('#contentGantt').width('80%');
      $('.gutter-horizontal').show();
    }
  }, [showView3D])
  const handleClose3DView = () => {
    setShowView3D(false)
  }
  const handleShow3DView = (value) => {
    setShowView3D(value)
  }
  const handleResize = () => {
    try {
      if (window.NOP_VIEWER)
        window.NOP_VIEWER.resize()
    } catch { }
  }
  const handleCloseValueDialog = () => {
    setShowValueDialog(false)
  }
  const handleGenerateGantt = () => {
    shareDataGantt(props.userInfo.token)
  }
  const handleExtractInfo = () => {
    setLoadingExtract(true)
    extractInfo(setLoadingExtract)
  }
  const handleArrangeSchedule = () => {
    setLoadingArrange(true)
    arrangeSchedule(setLoadingArrange)
  }
  // useEffect(() => {
  //   if(loadingExtract){
  //     async function run() {
  //       await extractInfo()
  //       setLoadingExtract(false)
  //     }
  //     run()
  //   }  
  // }, [loadingExtract])
  return (
    <>
      <div style={{ marginLeft: 5, marginTop: 2 }} >
        <ButtonGroup>
          <Button onClick={() => { setShowValueDialog(true) }}>Check Value</Button>
          <Button loading={loadingExtract} onClick={handleExtractInfo}>Extract</Button>
          <Button onClick={handleGenerateGantt}>Gantt</Button>
          <Button loading={loadingArrange} onClick={handleArrangeSchedule}>Arrange</Button>
          <Button loading={loadingArrange} onClick={handleGetForgeData}>Excel</Button>
        </ButtonGroup>


      </div>
      <Divider style={{ backgroundColor: 'black', marginBottom: 2, marginTop: 2 }} />
      <div style={{ width: '100%', height: 'calc(100% - 53.78px)', position: 'absolute', backgroundColor: 'white' }} >
        <Split className='splitForgeViewer'
          sizes={[50, 50]} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
          style={{
            height: `100%`,
            width: "100%", display: 'flex', justifyItems: 'center', alignItems: 'center'
          }}
        >
          <div id='contentGantt' style={{ height: '100%', width: "100%" }}>
            <TreeView displayPanel={true} projectName={props.userInfo.email}
              projectId={'#'} userRoleCurrent={props.userRole}
              onDisplayPanelChange={() => { }} itemId={''} itemName={''}
              objectId={''} onDisabledBtnChange={() => { }} handleShow3DView={handleShow3DView} />
          </div>
          <div id='content3D' style={{ height: '100%', width: "100%" }}>
            {showView3D &&
              <ResizeSensor onResize={handleResize}>
                <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }} >
                  {/* <IconButton style={{ zIndex: '10', float: "right", backgroundColor: "red", top: '0', color: 'white', borderRadius: 0 }} size='sm'
                    onClick={handleClose3DView}
                    icon={<Icon icon="close" />} /> */}
                </div>
              </ResizeSensor>
            }

          </div>
        </Split>

      </div>
      <input id="color-picker-model" type='color' style={{ opacity: 0, position: 'absolute', top: 0 }} />
      {showValueDialog &&
        <ShowValueDialog
          close={handleCloseValueDialog}
        />}
    </>

  );
}
ExcelPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  excelPage: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  }
}
export default connect(mapStateToProps)(ExcelPage)


function ShowValueDialog(props) {
  const [value, setValue] = useState([])
  useEffect(() => {
    async function load() {
      await paseValueCIP()

      setValue(window.cipParseValue)
    }
    load()
  }, [])

  return (
    <>
      <Modal
        title="Data"
        visible={true}
        onCancel={props.close}
        width={'100%'}
        footer={<></>}
        bodyStyle={{ overflowX: 'auto' }}
      >
        {(value) &&
          <Table columns={[
            {
              title: 'Installation',
              dataIndex: 'installationKey',
              key: 'installationKey',
            },
            {
              title: 'Material',
              dataIndex: 'materialKey',
              key: 'materialKey',
            },
            {
              title: 'Unit',
              dataIndex: 'unitKey',
              key: 'unitKey',
            },
            {
              title: 'Connection Type',
              dataIndex: 'connectionTypeKey',
              key: 'connectionTypeKey',
            },
            {
              title: 'Method',
              dataIndex: 'methodKey',
              key: 'methodKey',
            },
            {
              title: 'Lookup',
              dataIndex: 'lookupKey',
              key: 'lookupKey',
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
            },
            {
              title: 'Productivity',
              dataIndex: 'factor',
              key: 'factor',
            }]} dataSource={value} />}
      </Modal>

    </>
  )
}

