import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { connect } from 'react-redux';
import _ from 'lodash'

import { message, Button, List, Divider, Typography, Row, Col, Modal } from 'antd';
import {
  Icon, Input, Loader, Avatar, InputPicker, Button as ButtonRs, Modal as ModalRs
} from 'rsuite';
import { Segment } from 'semantic-ui-react';
import {TableSetupRole} from '../../tables'
import { ColumnSetupRole } from '../../columns'
import { Scrollbars } from 'react-custom-scrollbars';
import { renderView ,checkWohhupUser} from '../../function/AdminFunction'
import validator from 'validator';
import CompanyControl from './CompanyControl'
const { Text } = Typography
let groupLocal = { name: '', members: [] }
let listUserLocal = null
function DialogUsersControl(props) {
  const [listUser, setListUser] = useState([])
  const [listGroup, setListGroup] = useState([])
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(false)
  const [group, setGroup] = useState({ name: '', members: [] })
  const [columns, setColumns] = useState([])
  const [openAddMemberToProjectPanel, setOpenAddMemberToProjectPanel] = useState(false)
  const [openAddRenamePanel, setOpenAddRenamePanel] = useState(false)
  const [openAddNewCompanyGroup, setOpenAddNewCompanyGroup] = useState(false)
  const [openAddMemberToGroupPanel, setOpenAddMemberToGroupPanel] = useState(false)
  const [openUserDetail, setOpenUserDetail] = useState(false)
  const [userDetail, setUserDetail] = useState(false)
  const [isUserControl, setIsUserControl] = useState(true)
  useEffect(() => {
    if (isUserControl) {
      setLoading(true)
      axios.post(`/api/projects/get-information-project`, { projectId: props.projectId, token: props.userInfo.token })
        .then(res => {
          let temp = res.data.listContractor.slice(0)
          _.forEach(res.data.listContractor, v => {
            v.key = v.company
            v.label = v.company
            v.value = v.company
          })
          setColumns(ColumnSetupRole(temp, handleChangeTrade, handleChangeContractor, handleChangeRole, handleChangeAccessGantt, props.isAdmin))
        })
      axios.get(`/api/projects/members?q=${props.projectId}`)
        .then(res => {
          res.data.members.sort()
          res.data.members.splice(0, 0, props.userCreatedProject)
          _.forEach(res.data.groupUser, v => {
            v.click = false
          })
          res.data.groupUser.splice(0, 0, { name: 'All project users', members: res.data.members, click: true })
          setListGroup(res.data.groupUser)
          handleUserInformation(res.data.members, null, true)
          setMembers(res.data.members)
        })
        .catch(err => {
          handleClose()
        })
    }

  }, [isUserControl])
  const setControl = (value) => {
    setIsUserControl(value)
  }
  useEffect(() => {
    listUserLocal = listUser
  }, [listUser])
  useEffect(() => {
    groupLocal = group
  }, [group])
  const handleUserInformation = (members, name, updateList) => {
    let temp = []
    setLoading(true)
    axios.post('/api/users/get-users-information', { token: props.userInfo.token })
      .then(res => {
        _.forEach(res.data, v => {
          if (members.includes(v.email)) {
            let contractor = ""
            let company = ""
            let trade = []
            let role = ''
            let gantt = false
            let dashboardDrawing = false
            let dataEntry = false
            let bimApp = false
            let upload = false
            let workerAllocation= false
            let index = _.findIndex(v.contractors, v => { return v.projectId === props.projectId })
            if (index >= 0) {
              contractor = v.contractors[index].contractor ? v.contractors[index].contractor : ''
              gantt = v.contractors[index].isAccessGantt
              upload = v.contractors[index].isAccessUpload
              role = v.contractors[index].role ? v.contractors[index].role : ''
              dashboardDrawing = v.contractors[index].isAccessDashboardDrawing
              bimApp = v.contractors[index].isAccessBimApp
              dataEntry = v.contractors[index].isAccessDataEntry
              workerAllocation = v.contractors[index].isAccessWorkerAllocation
              company = v.contractors[index].company
            }
            let index1 = _.findIndex(v.trades, v => { return v.projectId === props.projectId })
            if (index1 >= 0) {
              trade = _.isArray(v.trades[index1].trade) ? v.trades[index1].trade : []
            }
            temp.push({
              userName: v.userName, email: v.email, role: role, gantt, upload, dashboardDrawing, bimApp,dataEntry,
              contractor: checkWohhupUser(v.email)  ? contractor : contractor, trade, workerAllocation,
              company:  checkWohhupUser(v.email)  ? "Woh Hup Private Ltd" : company
            })
          }
        })
        setLoading(false)
        if (updateList)
          setListUser(temp)

        setGroup({ name: name ? name : 'All project users', members: temp })

      })
      .catch(err => {
        setLoading(false)
      })
  }
  const handleClose = () => {
    props.close()
  }
  const handleGroup = (value) => {
    let temp = listGroup.slice(0)
    _.forEach(temp, v => { v.click = false })
    value.click = true
    setListGroup(temp)
    let tempUsers = []
    _.forEach(listUser, v => {
      if (value.name === 'All project users') {
        if (value.members.includes(v.email))
          tempUsers.push(v)
      } else {
        let index = _.findIndex(value.members, o => { return o.name === v.email })
        if (index >= 0) {
          tempUsers.push(v)
        }
      }
    })
    setGroup({ name: value.name, members: tempUsers })
    setOpenUserDetail(false)
  }
  //#region handle users
  const handleChangeContractor = (row, list, value) => {
    let index = _.findIndex(list, v => { return v.value === value })
    if (index >= 0) {
      setLoading(true)
      let contractName = list[index].contractor
      axios.post('/api/users/set-user-contractor-project', { email: row.original.email, contractor: contractName, company: value, projectId: props.projectId, token: props.userInfo.token })
        .then(res => {
          let temp = listUserLocal.slice(0)
          let index = _.findIndex(temp, v => { return v.email === row.original.email })
          if (index >= 0) {
            temp[index].company = value
            temp[index].contractor = contractName
          }
          setListUser(temp)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
    }

  }
  const handleChangeTrade = (row, value) => {
    // setLoading(true)
    // axios.post('/api/users/set-user-trade-project', { email: row.original.email, trade: value, projectId: props.projectId, token: props.userInfo.token })
    //   .then(res => {
    //     let temp = listUserLocal.slice(0)
    //     let index = _.findIndex(temp, v => { return v.email === row.original.email })
    //     if (index >= 0) {
    //       temp[index].trade = value
    //     }
    //     setListUser(temp)
    //     setLoading(false)
    //   })
    //   .catch(err => {
    //     setLoading(false)
    //   })
  }
  const handleChangeRole = (row, value) => {
    setLoading(true)
    axios.post('/api/users/set-user-role-project', { email: row.original.email, role: value, projectId: props.projectId, token: props.userInfo.token })
      .then(res => {
        let temp = listUserLocal.slice(0)
        let index = _.findIndex(temp, v => { return v.email === row.original.email })
        if (index >= 0) {
          temp[index].role = value
        }
        // let tempGroup = Object.assign({}, groupLocal)
        // let index1 = _.findIndex(tempGroup.members, v => { return v.email === row.original.email })
        // if (index1 >= 0) {
        //   temp[index1].role = value
        // }
        setListUser(temp)
        // setGroup(tempGroup)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  const handleChangeAccessGantt = (row, name, e) => {
    setLoading(true)
    let checked = e.target.checked
    axios.post('/api/users/set-access-project', { email: row.original.email, check: checked, projectId: props.projectId, token: props.userInfo.token, name: name })
      .then(res => {
        let temp = listUserLocal.slice(0)
        let index = _.findIndex(temp, v => { return v.email === row.original.email })
        if (index >= 0) {
          temp[index][name] = checked
          temp[index].upload = checked
        }
        setListUser(temp)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
      })
  }
  //#endregion

  //#region 
  const handleAddMemberToProject = () => {
    setOpenAddMemberToProjectPanel(true)
  }
  const handleCloseAddMemberToProject = (data) => {
    if (data) {
      let listGroupTemp = listGroup.splice(0)
      let index = listGroupTemp.findIndex(o => { return o.name === 'All project users' })
      if (index >= 0) {
        listGroupTemp[index].members = data
      }
      setListGroup(listGroupTemp)
      handleUserInformation(data, group.name, true)
    }
    setOpenAddMemberToProjectPanel(false)
  }
  const handleDeleteGroup = () => {
    if (!window.confirm('Are you want to delete?')) return
    setLoading(true)
    axios.post("/api/projects/delete-group", { projectId: props.projectId, groupName: group.name })
      .then(res => {
        _.forEach(res.data, v => {
          v.click = false
        })
        res.data.splice(0, 0, { name: 'All project users', members: members, click: true })
        setListGroup(res.data)
        setGroup({ name: 'All project users', members: listUser })
        message.success('Delete group success')
        setLoading(false)
      })
      .catch(() => {
        message.error('Delete group failed')
        setLoading(false)
      })
  }
  const handleAddMemberToGroup = () => {
    setOpenAddMemberToGroupPanel(true)
  }
  const handleCloseAddMemberToGroup = (data) => {
    if (data) {
      let temp = listGroup.slice(0)
      let index = _.findIndex(temp, v => { return v.name === group.name })
      if (index >= 0) {
        temp[index].members = data
      }
      let tempMembers = []
      _.forEach(data, v => { tempMembers.push(v.name) })
      setListGroup(temp)
      handleUserInformation(tempMembers, group.name)
    }
    setOpenAddMemberToGroupPanel(false)
  }
  //#endregion
  const removeMemberInProject = (email) => {
    if (!window.confirm('Are you sure to remove user ?')) return
    setLoading(true)
    axios.post(`/api/projects/remove-member`, {
      email: props.userInfo.email,
      memberName: email,
      project: props.project
    })
      .then(res => {
        res.data.members.sort()
        res.data.members.splice(0, 0, props.userCreatedProject)
        res.data.groupUser.splice(0, 0, { name: 'All project users', members: res.data.members, click: true })
        setListGroup(res.data.groupUser)
        handleUserInformation(res.data.members, null, true)
        setMembers(res.data.members)
        setLoading(false)
        message.success('Remove member success')
      })
      .catch(() => {
        setLoading(false)
        message.error('Remove member failed')
      })
  }
  const removeMemberInGroup = (email, name) => {
    if (!window.confirm('Are you sure to remove user ?')) return
    setLoading(true)
    axios.post("/api/projects/remove-member-to-group", { projectId: props.project.projectId, groupName: (name ? name : group.name), user: email })
      .then(res => {
        let temp = listGroup.slice(0)
        let index = _.findIndex(temp, v => { return v.name === (name ? name : group.name) })
        if (index >= 0) {
          temp[index].members = res.data
        }
        let tempMembers = []
        _.forEach(res.data, v => { tempMembers.push(v.name) })
        setListGroup(temp)
        setLoading(false)
        if (group.name === name) {
          handleUserInformation(tempMembers, group.name)
          setOpenUserDetail(false)
        } else {
          setUserDetail(userDetail)
        }
        message.success('Remove member success')
      })
      .catch(() => {
        setLoading(false)
        message.error('Remove member failed')
      })
  }
  const handleRemoveMemberInGroup = (e) => {
    removeMemberInGroup(e.email)
  }
  const handleCloseAddCompanyGroup = (data) => {
    if (data) {
      data.splice(0, 0, { name: 'All project users', members: members, click: true })
      setGroup({ name: 'All project users', members: listUser })
      setListGroup(data)
    }
    setOpenAddNewCompanyGroup(false)
  }
  const handleNewCompanyGroup = () => {
    setOpenAddNewCompanyGroup(true)
  }
  const handleNewGroup = () => {
    setOpenAddRenamePanel(true)
  }
  const handleCloseAddRenameGroupPanel = (data) => {
    if (data) {
      data.splice(0, 0, { name: 'All project users', members: members, click: true })
      setGroup({ name: 'All project users', members: listUser })
      setListGroup(data)
    }
    setOpenAddRenamePanel(false)
  }
  const callbackDoubleClick = (row) => {
    if (row.original) {
      setUserDetail(row.original)
      setOpenUserDetail(row.original.email === userDetail.email ? !openUserDetail : true)
    }

  }

  return (
    <>
      <Modal
        title={
          <Button.Group >
            <Button type={isUserControl ? 'primary' : 'default'} onClick={setControl.bind(this, true)}  >User Control </Button>
            <Button type={!isUserControl ? 'primary' : 'default'} onClick={setControl.bind(this, false)} >  Company Control  </Button>
          </Button.Group>
        }
        visible={props.open}
        onCancel={handleClose}
        centered={true}
        bodyStyle={{ padding: 5 }}
        width='100%'
        footer={[
          <></>
        ]}
      >
        {loading && <Loader center content="Loading..." speed="fast" size="md" backdrop vertical style={{ zIndex: 100000 }} />}
        {isUserControl ?
          <Row align="middle" gutter={[8, 8]}>
            <Col span={6} style={{ height: 'calc(100vh - 200px)' }}>
              <div style={{ height: 35 }}>
                <Text strong style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'large' }}>Groups</Text>
                <Button.Group style={{ float: 'right', right: 0 }}>
                  <Button ghost type="primary" onClick={handleNewGroup}>            New Group     </Button>
                  <Button ghost type="primary" onClick={handleNewCompanyGroup}>   New Company Group </Button>
                </Button.Group>
              </div>
              <Segment color='red' style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
                <Scrollbars
                  renderView={renderView}
                  autoHide autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={30}
                  universal={true}>
                  <List
                    itemLayout="horizontal"
                    dataSource={listGroup}
                    renderItem={item => (
                      <List.Item style={{ padding: '5px 0', cursor: 'pointer', backgroundColor: item.click ? '#d3e8fa' : '' }} onClick={handleGroup.bind(this, item)}>
                        <List.Item.Meta
                          avatar={
                            <Avatar style={{ backgroundColor: item.name === 'All project users' ? '#ff8118' : item.name.includes('_%$%_') ? '#1890ff' : '#e518ff' }}>
                              <Icon icon={item.name === 'All project users' ? 'user' : item.name.includes('_%$%_') ? 'building-o' : 'group'} />
                            </Avatar>}
                          title={item.name.includes('_%$%_') ? item.name.replace('_%$%_', '_') : item.name}
                          description={item.members ? item.members.length + ' Users' : '0 Users'}
                        />
                      </List.Item>
                    )}
                  />
                </Scrollbars>

              </Segment>
            </Col>
            <Col span={openUserDetail ? 14 : 18}  >

              <div style={{ height: 35 }}>
                <Text strong style={{ verticalAlign: '-webkit-baseline-middle', fontSize: 'large' }}>
                  {group.name.includes('_%$%_') ? group.name.replace('_%$%_', '_') : group.name}</Text>
                <Button.Group style={{ float: 'right', right: 0 }}>
                  {group.name === 'All project users' ?
                    <>
                      <Button ghost type="primary" onClick={handleAddMemberToProject}> Add user to project </Button>
                    </>
                    :
                    <>
                      <Button ghost type="primary" onClick={handleAddMemberToGroup.bind(this, group)} disabled={group.name === ''} >Add user to group </Button>
                      <Button ghost type='danger' onClick={handleDeleteGroup.bind(this, group)} disabled={group.name === ''} >  Delete Group  </Button>
                    </>
                  }
                </Button.Group>
              </div>
              <Segment color='red' style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
                <Scrollbars
                  renderView={renderView}
                  autoHide autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={30}
                  universal={true}>
                  <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                    <TableSetupRole
                      columns={columns}
                      data={group.members}
                      defaultPageSize={1000000000}
                      typeTable='userControl'
                      userCreatedProject={props.userCreatedProject}
                      removeMemberInGroup={handleRemoveMemberInGroup}
                      disabledButton={group.name === 'All project users'}
                      isDoubleClick={true}
                      callbackDoubleClick={callbackDoubleClick}
                    />
                  </div>
                </Scrollbars>


              </Segment>

            </Col>
            {openUserDetail && <Col span={4}  >
              <Segment color='blue' style={{ height: 'calc(100vh - 150px)', overflow: 'auto' }}>
                <Scrollbars
                  renderView={renderView}
                  autoHide autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={30}
                  universal={true}>
                  <DetailUser
                    userDetail={userDetail}
                    groupUser={listGroup}
                    removeMemberInProject={removeMemberInProject}
                    removeMemberInGroup={removeMemberInGroup}
                    userCreatedProject={props.userCreatedProject} />
                </Scrollbars>


              </Segment>
            </Col>}
          </Row>
          :
          <>
            <Segment color='red' style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
              <Scrollbars
                renderView={renderView}
                autoHide autoHideTimeout={1000}
                autoHideDuration={200}
                thumbMinSize={30}
                universal={true}>
                <CompanyControl
                  close={handleClose}
                  projectId={props.project.projectId} />
              </Scrollbars>
            </Segment>


          </>
        }
      </Modal>

      {openAddMemberToGroupPanel &&
        <AddMemberToGroupPanel
          open={openAddMemberToGroupPanel}
          projectId={props.project.projectId}
          handleClose={handleCloseAddMemberToGroup}
          name={group.name}
          groupMembers={group.members}
          members={members}
        // groupUser={this.state.groupUser}
        />}
      {openAddMemberToProjectPanel &&
        <AddMemberToProjectPanel
          open={openAddMemberToProjectPanel}
          projectId={props.projectId}
          handleClose={handleCloseAddMemberToProject}
          userCreatedProject={props.userCreatedProject}
          email={props.userInfo.email}
          members={members}
        />}
      {openAddRenamePanel &&
        <NewRenameGroupPanel
          open={openAddRenamePanel}
          projectId={props.projectId}
          handleClose={handleCloseAddRenameGroupPanel}
          name={''}
        />}
      {openAddNewCompanyGroup &&
        <NewCompanyGroupPanel
          open={openAddNewCompanyGroup}
          projectId={props.projectId}
          handleClose={handleCloseAddCompanyGroup}
          name={''}
          token={props.userInfo.token}
        />}
    </>
  )
}
DialogUsersControl.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user
  }
}
export default connect(mapStateToProps)(DialogUsersControl)

function DetailUser(props) {
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState({
    userName: '',
    title: '',
    company: '',
    department: '',
    phone: '',
    imgUrl: ''
  })

  const [group, setGroup] = useState([])
  useEffect(() => {
    setLoading(true)
    axios.post("/api/users/get-user-information", { email: props.userDetail.email })
      .then(res => {
        let temp = []
        _.forEach(props.groupUser, v => {
          let index = _.findIndex(v.members, e => { return e.name === props.userDetail.email })
          if (index >= 0)
            temp.push(v.name)
        })
        setUserInfo(res.data)
        setGroup(temp)
        setLoading(false)
      })
      .catch(() => {
        message.warning(`Can't get user information`)
        setLoading(false)
      })
  }, [props.userDetail, props.groupUser])

  const handleRemoveUserInProject = () => {
    if (group.length === 0 && props.userCreatedProject !== props.userDetail.email && props.userDetail.email !== 'admin@wohhup.com') {
      props.removeMemberInProject(props.userDetail.email)
    } else {
      message.warning('You need remove all group of user')
    }
  }
  const handleRemoveUserInGroup = (groupName) => {
    props.removeMemberInGroup(props.userDetail.email, groupName)
  }

  return (
    <>
      {loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
        <>
          <div style={{ textAlignLast: 'center' }}>
            <Avatar size={90} src={userInfo.imgUrl} shape='square' />
          </div>
          <div style={{ marginBottom: 5, textAlignLast: 'center' }}>
            <Text strong >{props.userDetail.userName ? props.userDetail.userName : 'None'} </Text>
          </div>
          <div style={{ marginBottom: 5, textAlignLast: 'center' }}>
            <Text style={{ marginBottom: 5 }}>{props.userDetail.company ? props.userDetail.company : 'None'} </Text>
          </div>
          <div style={{ marginBottom: 5, textAlignLast: 'center' }}>
            <Text style={{ color: '#1890ff' }} >{props.userDetail.email} </Text>
          </div>
          <Divider className='custom-divider' style={{ margin: '10px 0px', backgroundColor: 'gray' }} />
          <div style={{ height: 'calc(100% - 195px)' }}>
            <div style={{ marginBottom: 5 }}>
              <Text strong> Group: </Text>
            </div>
            <Scrollbars
              renderView={renderView}
              autoHide autoHideTimeout={1000}
              autoHideDuration={200}
              thumbMinSize={30}
              universal={true}>
              <List
                size='small'
                loading={loading}
                dataSource={group}
                renderItem={(item) => (
                  <List.Item style={{ padding: '5px 0' }} >
                    <List.Item.Meta
                      avatar={
                        <Avatar style={{ backgroundColor: item === 'All project users' ? '#ff8118' : item.includes('_%$%_') ? '#1890ff' : '#e518ff' }}>
                          <Icon icon={item === 'All project users' ? 'user' : item.includes('_%$%_') ? 'building-o' : 'group'} />
                        </Avatar>}
                      title={item}
                      description={
                        <a style={{ color: 'red', cursor: 'pointer' }} onClick={handleRemoveUserInGroup.bind(this, item)} >  Remove   </a>
                      }
                    />
                  </List.Item>
                )}
              />
            </Scrollbars>
          </div>
          <Divider className='custom-divider' style={{ margin: '10px 0px', backgroundColor: 'gray' }} />
          <div style={{ position: 'absolute', width: '100%', textAlignLast: 'center' }}>
            {(group.length === 0 && props.userCreatedProject !== props.userDetail.email && props.userDetail.email !== 'admin@wohhup.com') ?
              <Button ghost type='link' style={{ color: 'red' }} onClick={handleRemoveUserInProject} >  Remove from project  </Button> :
              <Button ghost type='link' style={{ color: 'gray' }}  >  Remove from project  </Button>}
          </div>
        </>
      }
    </>
  )
}

function NewRenameGroupPanel(props) {
  const [groupName, setGroupName] = useState(props.name)
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    props.handleClose(null)
  }
  const handleAddNewGroup = () => {
    if (groupName.length !== 0 && !groupName.includes('_%$%_') && groupName !== 'All project users') {
      if (!validator.isEmail(groupName)) {
        setLoading(true)
        axios.post("/api/projects/add-group", { projectId: props.projectId, groupName: groupName })
          .then(res => {
            _.forEach(res.data, v => {
              v.click = false
            })
            message.success('Save group success')
            props.handleClose(res.data)
            setLoading(false)
          })
          .catch(() => {
            message.error('Save group failed')
            props.handleClose(null)
            setLoading(false)
          })
      } else {
        message.warning(`Can't add group with this name`)
      }

    }
  }
 
  const handleChangeGroupName = (value) => {
    setGroupName(value)
  }
  return (
    <ModalRs show={props.open} onHide={handleClose} size='xs' backdrop='static'>
      <ModalRs.Header>
        <ModalRs.Title>{props.name.length === 0 ? 'Add Group' : 'Rename Group'}</ModalRs.Title>
      </ModalRs.Header>
      <ModalRs.Body >
        <Input size="sm" block cleanable={false} placeholder='Group Name' onChange={handleChangeGroupName} value={groupName} />
      </ModalRs.Body>
      <ModalRs.Footer>
          <ButtonRs appearance="primary" onClick={handleAddNewGroup} disabled={groupName.length === 0} loading={loading}> Add</ButtonRs> 
        <ButtonRs appearance="default" onClick={handleClose} > Cancel</ButtonRs>
      </ModalRs.Footer>
    </ModalRs>
  )
}
function NewCompanyGroupPanel(props) {
  const [groupName, setGroupName] = useState(props.name)
  const [suffix, setSuffix] = useState('')
  const [listCompany, setListCompany] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    axios.post(`/api/projects/get-information-project`, { projectId: props.projectId, token: props.token })
      .then(res => {
        let temp = []
        _.forEach(res.data.listContractor, v => {
          temp.push({ key: v.company, label: v.company, value: v.company })
        })

        setListCompany(temp)
        setLoading(false)
      })
      .catch(() => {
        handleClose(null)
      })
  }, [])
  const handleClose = () => {
    props.handleClose(null)
  }
  const handleAddNewGroup = () => {
    if (groupName.length !== 0 && suffix.length !== 0) {
      if (!validator.isEmail(groupName)) {
        setLoading(true)
        axios.post("/api/projects/add-group", { projectId: props.projectId, groupName: `${groupName}_%$%_${suffix}` })
          .then(res => {
            _.forEach(res.data, v => {
              v.click = false
            })
            message.success('Save group success')
            props.handleClose(res.data)
            setLoading(false)
          })
          .catch(() => {
            message.error('Save group failed')
            props.handleClose(null)
            setLoading(false)
          })
      } else {
        message.warning(`Can't add group with this name`)
      }

    }
  }

  const handleChangeGroupName = (value) => {
    setSuffix(value)
  }
  const handleChangeCompany = (value) => {
    setGroupName(value)
  }
  return (
    <ModalRs show={props.open} onHide={handleClose} size='xs' backdrop='static' >
      <ModalRs.Header>
        <ModalRs.Title>{props.name.length === 0 ? 'Add Group' : 'Rename Group'}</ModalRs.Title>
      </ModalRs.Header>
      <ModalRs.Body >
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <div>
          <InputPicker
            data={listCompany} size='sm' cleanable={false}
            placeholder="Select Company" onChange={handleChangeCompany}
            block
            renderValue={(value) => {
              return (
                <div style={{ color: 'black' }}>
                  {value}
                </div>
              );
            }}
          />
          <br />
          <Row gutter={[8]} style={{ marginLeft: 0, marginRight: 0 }} >
            <Col span={12}>
              <Input size="sm" block cleanable={false} placeholder='Company Name' disabled value={groupName} />
            </Col>
            <Col span={12}>
              <Input size="sm" block cleanable={false} placeholder='Suffix' onChange={handleChangeGroupName} value={suffix} />
            </Col>
          </Row>
        </div>

      </ModalRs.Body>
      <ModalRs.Footer>
        <ButtonRs appearance="primary" onClick={handleAddNewGroup} disabled={groupName.length === 0 || suffix.length === 0} loading={loading}> Add</ButtonRs>
        <ButtonRs appearance="default" onClick={handleClose} > Cancel</ButtonRs>
      </ModalRs.Footer>
    </ModalRs>
  )
}
function AddMemberToGroupPanel(props) {
  const [user, setUser] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    props.handleClose(null)
  }
  useEffect(() => {
    let temp = []
    _.forEach(props.members, v => {
      let index = _.findIndex(props.groupMembers, e => { return e.email === v })
      if (index < 0)
        temp.push({ label: v, text: v, value: v })
    })
    setData(temp)
  }, [])
  const handleAddNewGroup = () => {
    let index = _.findIndex(props.groupMembers, e => { return e.email === user })
    if (index < 0) {
      // let check = false
      // _.forEach(props.groupUser, v => {
      //   let index = _.findIndex(v.members, e => { return e.name === user })
      //   if (index >= 0) {
      //     check = true
      //     return false
      //   }
      // })
      // if (check)
      //   if (!window.confirm('User added to another group. Are you want to add?')) return
      setLoading(true)
      axios.post("/api/projects/add-member-to-group", { projectId: props.projectId, groupName: props.name, user: user })
        .then(res => {
          // _.forEach(res.data, v => {
          //   if (v.name === props.name)
          //     v.click = true
          //   else
          //     v.click = false
          // })
          message.success('Add member success')

          props.handleClose(res.data)
          setLoading(false)
        })
        .catch(() => {
          message.error('Add member failed')
          props.handleClose(null)
          setLoading(false)
        })
    } else {
      message.success('Member was existing')
    }
  }
  const handleChangeUser = (value) => {
    setUser(value)
  }
  return (
    <ModalRs show={props.open} onHide={handleClose} size='xs' backdrop='static'>
      <ModalRs.Header>
        <ModalRs.Title>Add member to {props.groupName}</ModalRs.Title>
      </ModalRs.Header>
      <ModalRs.Body >
        <InputPicker
          data={data} size='sm' cleanable={false}
          placeholder="Select Type" onChange={handleChangeUser}
          block
          renderValue={(value) => {
            return (
              <div style={{ color: 'black' }}>
                {value}
              </div>
            );
          }}
        />
      </ModalRs.Body>
      <ModalRs.Footer>
        <ButtonRs appearance="primary" onClick={handleAddNewGroup} disabled={user.length === 0} loading={loading}> Add</ButtonRs>
        <ButtonRs appearance="default" onClick={handleClose} > Cancel</ButtonRs>
      </ModalRs.Footer>
    </ModalRs>
  )
}
function AddMemberToProjectPanel(props) {
  const [user, setUser] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    props.handleClose(null)
  }
  useEffect(() => {
    let temp = []
    setLoading(true)
    axios.get(`/api/projects/search-user`)
      .then(res => {
        _.forEach(res.data, v => {
          if (v.email !== props.userCreatedProject && !props.members.includes(v.email))
            temp.push({ label: v.email, text: v.email, value: v.email })
        })
        setData(temp)
        setLoading(false)
      })
      .catch(() => {
        props.handleClose(null)
        message.success('Get members failed')
      })
  }, [])
  const addMember = () => {
    if (props.userCreatedProject !== undefined) {
      if (!props.members.includes(user)) {
        axios.post(`/api/projects/add-member`, {
          email: props.email,
          memberName: user,
          projectId: props.projectId
        })
          .then(res => {
            res.data.sort()
            res.data.splice(0, 0, props.userCreatedProject);
            props.handleClose(res.data)
          })
      } else {
        message.warning('User already in project')
      }
    }
  }
  const handleChangeUser = (value) => {
    if (value === null)
      value = []
    setUser(value)
  }
  return (
    <ModalRs show={props.open} onHide={handleClose} size='xs' backdrop='static'>
      <ModalRs.Header>
        <ModalRs.Title>Add member to Project</ModalRs.Title>
      </ModalRs.Header>
      <ModalRs.Body >
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 100000 }} />}
        <InputPicker
          data={data} size='sm' cleanable={false}
          placeholder="Select User" onChange={handleChangeUser}
          block
          renderValue={(value) => {
            return (
              <div style={{ color: 'black' }}>
                {value}
              </div>
            );
          }}
        />
      </ModalRs.Body>
      <ModalRs.Footer>
        <ButtonRs appearance="primary" onClick={addMember} disabled={user.length === 0} loading={loading}> Add</ButtonRs>
        <ButtonRs appearance="default" onClick={handleClose} > Cancel</ButtonRs>
      </ModalRs.Footer>
    </ModalRs>
  )
}
