import { DatePicker, Icon, Input, message, Modal, Select, Tooltip, Upload } from 'antd';
import { props } from 'bluebird';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { mongoObjectId, getHeaderWidth, debounceFnc } from '../../../utils';
import ExcelImport from './ExcelImport';
import ButtonGroupComp from './ButtonGroupComp';

const { Option } = Select;
const { TextArea } = Input;



const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};

const PanelImportExcelData = ({ onClickCancelModal, onClickApply, stateRow, stateProject }) => {

   const { allDataOneSheet: { publicSettings, projectIsAppliedRfaView } } = stateProject;
   const { drawingTypeTree } = stateRow;

   const { headers } = publicSettings;

   const arrayOmit = ['ARCHI (SUBCON)', 'C&S (SUBCON)', 'M&E (SUBCON)', 'PRECAST (SUBCON)'];

   const drawingTypeTreeTextArray = [...new Set(drawingTypeTree.filter(node => node['treeLevel'] > 1).map(node => node['title']))].filter(x => arrayOmit.indexOf(x) === -1);


   const [dataImportFromExcel, setDataImportFromExcel] = useState({
      excelDataTreeToPreview: [],
      excelDataRowsToPreview: []
   });

   const [nodeToInsert, setNodeToInsert] = useState(drawingTypeTreeTextArray[0]);


   const [expandedRows, setExpandedRows] = useState([]);
   const onRowExpand = (props) => {
      const { rowKey, expanded } = props;
      let arr = [...expandedRows];
      if (expanded) {
         arr.push(rowKey);
      } else {
         arr = arr.filter(id => id !== rowKey);
      };
      setExpandedRows(arr);
   };
   const ExpandIcon = (props, i) => {
      const { expanding, expandable, onExpand, depth } = props;
      const indent = (depth * 17).toString() + 'px';

      return (
         <div
            style={{
               marginLeft: indent, paddingLeft: expandable ? 10 : 13 + 10,
               paddingRight: 3, background: 'transparent',
            }}
         >
            {expandable && (
               <Icon
                  type={expanding ? 'plus-square' : 'minus-square'}
                  style={{ color: 'black', transform: 'translate(0, -4px)' }}
                  onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     onExpand(expanding);
                  }}
               />
            )}
         </div>
      );
   };



   const rowClassName = (props) => {
      const { rowData } = props;
      if (Array.isArray(rowData.children) && rowData.children.length > 0) {
         return 'row-drawing-type';
      };
   };


   const generateColumns = (headers) => {
      return [
         {
            key: 'action',
            dataKey: 'action',
            title: '',
            width: 50,
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer: ( // MUST RETURN LIKE THIS, NOT ({ rowData }) => (), otherwise dataImportFromExcel can not update - DON'T KNOW WHY
               <CellRemoveRow
                  onClickRemoveExcelRow={onClickRemoveExcelRow}
                  dataImportFromExcel={dataImportFromExcel} // MUST INCLUDE dataImportFromExcel HERE EVEN NO USE LATER TO GET LATEST dataImportFromExcel - DON'T KNOW WHY
               />
            ),
         },
         ...headers.map((columnObj, columnIndex) => {
            const { text: column } = columnObj;
            return {
               key: column,
               dataKey: column,
               title: column,
               resizable: true,
               width: getHeaderWidth(column),
               cellRenderer: (props) => {
                  const { rowData } = props;
                  return (
                     <div style={{
                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                        paddingLeft: 3,
                        color: 'black'
                     }}>
                        {(Array.isArray(rowData['children']) && column === 'Drawing Number') ? rowData['title'] : rowData[column]}
                     </div>
                  );
               },
            };
         })
      ];
   };


   const onClickRemoveExcelRow = ({ rowId, parentId }) => {

      const { excelDataRowsToPreview, excelDataTreeToPreview } = dataImportFromExcel;

      if (parentId) {
         const excelDataRowsToPreviewNew = excelDataRowsToPreview.filter(row => row['id'] !== rowId);
         setDataImportFromExcel({
            excelDataTreeToPreview,
            excelDataRowsToPreview: excelDataRowsToPreviewNew
         });
      } else {
         const excelDataTreeToPreviewNew = excelDataTreeToPreview.filter(node => node['id'] !== rowId);
         const excelDataRowsToPreviewNew = excelDataRowsToPreview.filter(row => row['_parentRow'] !== rowId);
         setDataImportFromExcel({
            excelDataTreeToPreview: excelDataTreeToPreviewNew,
            excelDataRowsToPreview: excelDataRowsToPreviewNew
         });
      };
   };





   return (
      <div style={{
         background: 'white',
         width: '100%',
         padding: 20,
         color: 'black',
      }}>
         <div style={{ display: 'flex', marginBottom: 20 }}>
            <ExcelImport
               headers={headers}
               projectIsAppliedRfaView={projectIsAppliedRfaView}
               getDataFormExcel={({ excelDataRowsToPreview, excelDataTreeToPreview }) => {
                  setDataImportFromExcel({ excelDataRowsToPreview, excelDataTreeToPreview });
                  setExpandedRows(excelDataTreeToPreview.map(x => x['id']));
               }}
            />

            <div style={{ display: 'flex', marginLeft: 100 }}>
               <div style={{ marginRight: 5 }}>Parent Folder</div>
               <SelectStyled
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                     let stringArray = input.split(/(\s+)/).filter(str => str !== ' ');
                     let isFound = true;
                     stringArray.forEach(str => {
                        if (option.props.children.toLowerCase().indexOf(str.toLowerCase()) === -1) {
                           isFound = false;
                        };
                     });
                     return isFound;
                  }}
                  value={nodeToInsert}
                  style={{ width: 200, color: 'black' }}
                  onChange={(node) => {
                     setNodeToInsert(node);
                  }}
               >
                  {drawingTypeTreeTextArray.map(nodeText => (
                     <Option key={nodeText} value={nodeText}>{nodeText}</Option>
                  ))}
               </SelectStyled>
            </div>

         </div>


         {dataImportFromExcel && (
            <div style={{
               width: window.innerWidth * 0.9 - 50,
               height: window.innerHeight * 0.8 - 100,
               margin: '0 auto',
               textAlign: 'center'
            }}>
               <TableStyled
                  fixed
                  columns={generateColumns(headers)}
                  data={getDataForTable(dataImportFromExcel)}
                  rowHeight={28}
                  expandedRowKeys={expandedRows}
                  expandColumnKey={'Drawing Number'}
                  onRowExpand={onRowExpand}
                  expandIconProps={(props) => {
                     const { expanded } = props;
                     return ({ expanding: !expanded });
                  }}
                  components={{ ExpandIcon }}
                  rowClassName={rowClassName}
               />
            </div>
         )}


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}`, marginTop: 20 }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => {
                  if (nodeToInsert) {
                     onClickApply({ ...dataImportFromExcel, nodeToInsert });
                  } else {
                     message.info('Please specify folder to import!');
                  };
               }}
               newTextBtnApply={'Import'}
            />
         </div>

      </div>
   );
};
export default PanelImportExcelData;



const CellRemoveRow = ({ rowData, onClickRemoveExcelRow }) => {
   return (
      <Tooltip title='Remove Row'>
         <Icon type='close' onClick={() => onClickRemoveExcelRow({
            rowId: rowData['id'],
            parentId: rowData['_parentRow']
         })} />
      </Tooltip>
   );
};


const SelectStyled = styled(Select)`
   .ant-select-selection {
      border-radius: 0;
   }
`;


const TableStyled = styled(Table)`

   .row-drawing-type {
      background-color: ${colorType.grey3};
      font-weight: bold
   }

   .BaseTable__row-cell-text {
      color: black
   }

   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   }

   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   }
`;



const getDataForTable = ({ excelDataRowsToPreview, excelDataTreeToPreview }) => {

   let output = [];
   excelDataTreeToPreview.forEach(node => {
      const rowsOfThisNode = excelDataRowsToPreview.filter(row => row['_parentRow'] === node['id']);
      const rowsOfThisNodeClone = rowsOfThisNode.map(x => ({ ...x }));
      output.push({
         ...node,
         children: rowsOfThisNodeClone
      });
   });
   return output;
};



