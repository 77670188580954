import React from 'react'
import $ from 'jquery';
import axios from 'axios'
import { Table, Button, Popup } from 'semantic-ui-react'
import _ from 'lodash'
import { DatePicker } from 'antd';
import moment from 'moment';
import Chart from 'react-google-charts';
import { Typography } from '@material-ui/core'
import Excel from 'exceljs';
import { saveAs } from 'file-saver'
import Draggable from 'react-draggable';
import { IconButton, Icon, Popover, Whisper, TagPicker } from 'rsuite';
import {formatNumber} from '../../../function/TableFunction'

const { RangePicker } = DatePicker;
let onceTime = true
export default class PlanningPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDay: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      endDay: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      listDataShow: [],
      status: 'On-going',
      dataColumns: [],
      loadingColumns: false,
      columnsSelected: null,
      columnsDisplay: ['Activity', 'Start Date', 'End Date', 'Vol (m³)']
    }

  }
  componentDidMount() {

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.openDock && onceTime) {
      onceTime = false
      this.setState({ loadingColumns: true })
      let temp = []
      _.forEach(nextProps.listSheetSelected, item => {
        _.forEach(item.columns, (v, k) => {
          if (k > 4) {
            temp.push({ label: v.title, value: { title: v.title, id: v.id }, group: item.name })

          }
        })
      })
      let tempData = []
      _.forEach(nextProps.listElementId[1], item => {
        let temp = { id: item.id, name: item.name, start: item.start, end: item.end, volume: item.volume.toFixed(0) }
        _.forEach(item.cells, v => {
          let index = _.find(this.state.columnsSelected, function (o) { return o.id === v.columnId })
          if (index !== null && index !== undefined) {
            temp[index.title] = v.value
          }
        })
        tempData.push(temp)
      })
      this.setState({ listDataShow: tempData, status: 'On-going', dataColumns: temp, loadingColumns: false })
    } else if (nextProps.openDock) {
      let tempData = []
      _.forEach(nextProps.listElementId[1], item => {
        let temp = { id: item.id, name: item.name, start: item.start, end: item.end, volume: item.volume.toFixed(0) }
        _.forEach(item.cells, v => {
          let index = _.find(this.state.columnsSelected, function (o) { return o.id === v.columnId })
          if (index !== null && index !== undefined) {
            temp[index.title] = v.value
          }
        })
        tempData.push(temp)
      })
      this.setState({ listDataShow: tempData, status: 'On-going' })
    }
    else if (!nextProps.openDock && !onceTime) {
      onceTime = true
    }
  }
  componentWillUnmount() {
    onceTime = true
  }
  handleSelectElement = (id, e) => {
    this.props.viewer.clearSelection();
    var array = []
    id.forEach(item => {
      array.push(item);
    })
    this.props.viewer.select(array);
  }
  async handleExport() {
    var { listElementId } = this.props
    var { startDay, endDay } = this.state
    var workbook = new Excel.Workbook();
    var sheet = workbook.addWorksheet('Demo');
    var rows = [['Name', 'Start Day', 'End Day', 'Status', 'Volume']]
    sheet.addRows(rows);
    for (var key in listElementId) {
      listElementId[key].forEach(item => {
        var start = moment(startDay, 'DD-MM-YYYY').startOf('day').valueOf();
        var end = moment(endDay, 'DD-MM-YYYY').endOf('day').valueOf();
        var startItem = moment(item.start, 'DD-MM-YYYY').valueOf();
        var endItem = moment(item.end, 'DD-MM-YYYY').valueOf();

        if (start > endItem || end < startItem) {
          let rows = [[item.name, item.start, item.end, 'Not Cast', item.volume]]
          sheet.addRows(rows);
        } else if (end > endItem) {
          let rows = [[item.name, item.start, item.end, 'Casted', item.volume]]
          sheet.addRows(rows);
        } else if (end < endItem) {
          let rows = [[item.name, item.start, item.end, 'On Going', item.volume]]
          sheet.addRows(rows);
        }

      })
    }
    const buf = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buf]), 'Report.xlsx')

  }
  onChange = (dates, dateStrings) => {
    try {
      this.setState({ startDay: dates[0].format("DD-MM-YYYY") })
      this.setState({ endDay: dates[1].format("DD-MM-YYYY") })
    } catch{ }

  }
  getCalendarContainer = () => {
    return document.getElementById('popupExportTimeline');
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay('summary', false)
  }
  handleColumnsSelect = (value, e) => {
    this.setState({  columnsSelected: value},()=>{
      this.handleDataShow(this.state.status ==-'On-going' ?1:this.state.status ==-'Casted'?0:2)
    })
  }
  handleDataShow =(key)=>{
      // ]
      let temp = ['Activity', 'Start Date', 'End Date', 'Vol (m³)']
      _.forEach(this.state.columnsSelected, v => {
        temp.push(v.title)
      })
      let tempData = []
      _.forEach(this.props.listElementId[key], item => {
        let temp = { id: item.id, name: item.name, start: item.start, end: item.end, volume: item.volume.toFixed(0) }
        _.forEach(item.cells, v => {
          let index = _.find(this.state.columnsSelected, function (o) { return o.id === v.columnId })
          if (index !== null && index !== undefined) {
            temp[index.title] = v.value
          }
        })
        tempData.push(temp)
      })
      this.setState({ columnsDisplay: temp, listDataShow: tempData })
  }
  render() {
    var _this = this;
    const { volumeCasted, volumeActive, listElementId, viewer, time, allVolume } = this.props
    const chartEvents = [
      {
        eventName: "select",
        callback({ chartWrapper }) {
          try {
            var temp = chartWrapper.getChart().getSelection()[0].row
            viewer.clearSelection();
            var array = []
            listElementId[temp].forEach(item => {
              array.push(item.id);
            })
            var merged = [].concat.apply([], array);
            viewer.select(merged);

            _this.handleDataShow(temp)
            // _this.setState({ listDataShow: listElementId[temp] })
            if (temp === 0) _this.setState({ status: 'Casted' })
            else if (temp === 1) _this.setState({ status: 'On-going' })
            else if (temp === 2) _this.setState({ status: 'Not Cast' })

          } catch{ }
        }
      }
    ];
    var volumeCastedTemp = formatNumber(volumeCasted,0)
    var allVolumeTemp = formatNumber(allVolume,0)

    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 400, width: 400 }}
          id='custom-main-planning-summary' >
          <div className='custom-dock-panel-title'>Details</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{ width: "100%", height: '100%', overflow: 'auto', position: 'absolute' }}>
              <Chart
                width={'400px'}
                height={'200px'}
                chartType="PieChart"
                getChartWrapper={chartWrapper => {
                  this.chartWrapper = chartWrapper
                  chartWrapper.draw();
                }}
                loader={<div>Loading Chart</div>}
                data={[
                  ['Volume', 'm³'],
                  ['Casted', parseFloat(volumeCasted.toFixed(0))],
                  ['On Going', parseFloat(volumeActive.toFixed(0))],
                  ['Not Cast', parseFloat((allVolume - volumeCasted - volumeActive).toFixed(0))],
                ]}
                options={{

                  title: `Con'c vol casted: ${volumeCastedTemp}m³/${allVolumeTemp}m³`,
                  backgroundColor: 'white',
                  titleTextStyle: {
                    fontSize: 12,
                  },
                  sliceVisibilityThreshold :0,
                  slices: {
                    0: { color: '#0000FF' },
                    1: { color: '#ffa500' },
                    2: { color: 'gray' }
                  }
                }}
                chartEvents={chartEvents}
              />
              <div style={{ padding: "10px" }}>
                <Typography gutterBottom variant="h6" component="h2" style={{ maxHeight: "54px", fontSize: "15px", display: 'inline' }} >
                  {`${this.state.status} activities as of ${moment(time).format('DD-MM-YYYY')}`}
                </Typography>
                <Whisper placement={'top'} trigger="click" speaker={
                  <Popover style={{ zIndex: 10000 }}>
                    <TagPicker data={this.state.dataColumns} style={{ width: '400px ' }} size="xs" placeholder="Columns" onChange={this.handleColumnsSelect}
                      value={this.state.columnsSelected} maxHeight={500} placement='rightStart' groupBy="group"
                      renderMenu={menu => {
                        if (this.state.loadingColumns) {
                          return (
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                              <Icon icon="spinner" spin /> Loading...
                            </p>
                          );
                        }
                        return menu;
                      }} />
                  </Popover>
                }>
                  <IconButton style={{ float: 'right' }} size="xs" icon={<Icon icon="list" />} />
                </Whisper>

                {/* <Table dataSource={this.state.listDataShow} columns={this.state.columnsSelected} /> */}
                <Table celled selectable verticalAlign='middle' textAlign='center'>
                  <Table.Header>
                    <Table.Row>
                      {this.state.columnsDisplay.map(item =>
                        <Table.HeaderCell>{item}</Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.listDataShow.length !== 0 ?

                      this.state.listDataShow.map(item => (
                        <Table.Row onClick={this.handleSelectElement.bind(this, item.id)}>
                          {Object.keys(item).map((v,k) => 
                            (v!=='id' && v!=='cells') &&
                            <Table.Cell>{_.isNumber(item[v])?item[v].toFixed(0):item[v]}</Table.Cell>
                          )}

                        </Table.Row>

                        //   <List.Item  style={{cursor: 'pointer'}}>
                        //   <List.Content floated='right' value={item} style={{color:'black'}}>
                        //   <Tooltip placement="top" title={`[${item.start} -> ${item.end}]-${item.volume}m³`} >
                        //     <Icon name='info' />
                        //   </Tooltip>          
                        //   </List.Content>           
                        //   <List.Content onClick={this.handleSelectElement.bind(this,item.id)} value={item.id} style={{color:'black'}} >{item.name}</List.Content>
                        // </List.Item> 
                      ))
                      : <div></div>
                    }

                  </Table.Body>
                </Table>
                <Popup id='popupExportTimeline' wide trigger={<Button color='red'
                  style={{ width: '100%' }} content='Export' />} on='click'>
                  <RangePicker
                    format={'DD-MM-YYYY'}
                    ranges={{ Today: [moment(), moment()], 'This Month': [moment().startOf('month'), moment().endOf('month')], }}
                    value={[moment(this.state.startDay, 'DD-MM-YYYY'), moment(this.state.endDay, 'DD-MM-YYYY')]}
                    onChange={this.onChange} getCalendarContainer={this.getCalendarContainer} />
                  <Button style={{ float: 'right', padding: '10px', marginTop: '5px' }} color='primary' onClick={this.handleExport.bind(this)}>Apply</Button>
                </Popup>
              </div>

            </div>

          </div>
          <div className='custom-dock-panel-footer' />
        </div>
      </Draggable>


    )
  }
}