import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

import { apiGetSummaryCostCode } from '../../api/dash-board';
import { Table, Pagination, Icon, Input, Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
const { Column } = Table;

function CostCodeTableSummary(props) {
  const [datas, setDatas] = useState(null);
  const [searchText, setSearchText] = useState();
  const [searchInput, setSearchInput] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const projectId = useSelector((state) => state.project.projectId);
  useEffect(() => {
    console.log(' props.groupType', props.groupType);
    getDatas();
  }, [props.datas, props.groupType]);

  useEffect(() => {
    if (props.exportExcel > 0) {
      exportSummaryByCostDetail();
    }
  }, [props.exportExcel]);

  const getDatas = async () => {
    if (_.isNil(props.datas)) {
      await setDatas([]);
    } else {
      let dts = props.datas.map((x,index) => ({
        id:index,
        ...x,
        currentMonthData: roundNumber(x.currentMonthData.amount),
        totalManMonth: roundNumber(x.totalManMonth),
        previousMonthData: roundNumber(x.previousMonthData.amount),
        totalAmount: roundNumber(x.totalAmount),
      }));

      console.log('dts',dts)
      let groupKey = props.groupType == 2 ? 'description' : 'costCode';
      let dic = _.groupBy(dts, groupKey);

      console.log('dts', dts);
      let keys = Object.keys(dic);
      keys.sort();
      console.log("keys",keys)
      let group = keys.map((key) => ({
        id:key,
        key: key,
        costCode: props.groupType == 2 ? '' :key,
        description: props.groupType == 2 ? key : '',
        totalAmount: roundNumber(_.sumBy(dic[key], 'totalAmount')),
        totalManMonth: roundNumber(_.sumBy(dic[key], 'totalManMonth')),
        previousMonthData: roundNumber(_.sumBy(dic[key], 'previousMonthData')),
        currentMonthData: roundNumber(_.sumBy(dic[key], 'currentMonthData')),
        isGroup: true,
        children: dic[key],
      }));

      await setDatas(group);
    }
  };

  function roundNumber(cash) {
    if (!_.isNumber(cash)) {
      return 0;
    }
    try {
      return Math.round(cash * 10) / 10;
    } catch (error) {
      return 0;
    }
  }

  function renderCash(cash) {
    return (
      <div>
        <b>$</b>
        <p className='float-right'>{cash}</p>
      </div>
    );
  }

  function renderHours(h) {
    return <p>{Math.round(h * 10) / 10}</p>;
  }

  function getCurrentMonthStringTitle(type, isString = false) {
    let currMonthName = moment().format('MMM');
    let prevMonthName = moment().subtract(1, 'month').format('MMM');
    let year = moment().format('YY');

    let text = 'Cumulative';
    let textEnd = `(End ${currMonthName}-${year})`;
    if (type === 2) {
      text = 'Current';
      textEnd = `(${currMonthName}-${year})`;
      if (props.dateData) {
        textEnd =
          props.dateData.startCurrentMonth +
          '->' +
          props.dateData.endCurrentMonth;
      }
    } else if (type === 3) {
      text = 'Previous';
      textEnd = `(End ${prevMonthName}-${year})`;
      if (props.dateData) {
        textEnd =
          props.dateData.startPrevMonth + '->' + props.dateData.endPrevMonth;
      }
    }

    if (isString) {
      return text + ' ' + textEnd;
    }

    return (
      <p className='text-center'>
        {text}
        <br />
        {textEnd}
      </p>
    );
  }

  function getCostCodeFilter() {
    try {
      if (_.isArray(datas)) {
        let items = datas.map((x) => ({
          text: x.costCode ? x.costCode : '',
          value: x.costCode ? x.costCode : '',
        }));

        let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

        return uniq;
      }

      return [];
    } catch (error) {}
  }

  function getColumnSearchProps(dataIndex) {
    let a = {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon='search'
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type='search'
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex && text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };

    return a;
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  async function exportSummaryByCostDetail() {
    const workbook = new ExcelJS.Workbook();
    let excelName = 'Summary by cost code';
    const worksheet = workbook.addWorksheet('Summary by cost code');

    let c1 = getCurrentMonthStringTitle(1, true);
    let c2 = getCurrentMonthStringTitle(2, true);
    let c3 = getCurrentMonthStringTitle(3, true);
    worksheet.columns = [
      { header: 'Cost Code', key: 'costCode' },
      { header: 'Description', key: 'description' },
      { header: 'Man-Mth', key: 'totalManMonth' },
      { header: c1, key: 'totalAmount' },
      { header: c2, key: 'currentMonthData' },
      { header: c3, key: 'previousMonthData' },
    ];

    worksheet.properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false,
    };

    let rowCount = 0;
    let dataExcel = datas;

    dataExcel.forEach((r) => {
      const newRows = worksheet.addRow(r);
      rowCount++;
      if (r.children && _.isArray(r.children)) {
        r.children.forEach((c) => {
          let sRow = worksheet.addRow(c);
          sRow.outlineLevel = 1;
          rowCount++;
        });
      }
    });

    let borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    for (let i = 1; i <= rowCount + 1; i++) {
      for (let j = 1; j < 7; j++) {
        worksheet.getCell(i, j).border = borderStyles;
      }
    }

    worksheet.columns.forEach(function (column, i) {
      if (i !== 0) {
        var maxLength = 0;
        column['eachCell']({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      }
    });

    worksheet.columns[0].width = 20;
    worksheet.columns[1].width = 20;

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + '.xlsx');
  }

  let columns = [
    {
      title: 'Cost Code',
      dataIndex: 'costCode',
      key: 'costCode',
      fixed: 'left',
      width: 150,
      filters: getCostCodeFilter(),
      onFilter: (value, record) =>
        value && record.costCode.indexOf(value) === 0,
      ...getColumnSearchProps('costCode'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Man-Mth',
      dataIndex: 'totalManMonth',
      key: 'totalManMonth',
      render: renderHours,
      width: 130,
    },

    {
      title: getCurrentMonthStringTitle(1),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: renderCash,
      width: 130,
    },
    {
      title: getCurrentMonthStringTitle(2),
      dataIndex: 'currentMonthData',
      key: 'currentMonthData',
      render: renderCash,
      width: 130,
    },
    {
      title: getCurrentMonthStringTitle(3),
      dataIndex: 'previousMonthData',
      key: 'previousMonthData',
      render: renderCash,
      width: 130,
    },
  ];

  return (
    <div>
      <Table
        loading={_.isNil(datas)}
        className='antTable'
        dataSource={datas}
        columns={columns}
        size='small'
        bordered
        rowKey={(record) => record.id}
        scroll={{ y: 300 }}
      ></Table>
    </div>
  );
}

export default CostCodeTableSummary;
