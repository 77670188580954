import React, { useState } from "react";
import { Modal, DatePicker, Spin, Button, message, Space } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";

const { RangePicker } = DatePicker;

const ExportBatchConcDo = ({ visible, onCreate, onCancel }) => {
   const [listS3Key, setListS3Key] = useState([]);
   const projectId = useSelector((state) => state.project.projectId);
   const [project_id, set_project_id] = useState(projectId);
   const [item_no_file, set_item_no_file] = useState(0);
   const [downloadDone, setDownloadDone] = useState(false);

   const handleClickDate = async (dateString) => {
      console.log("start get list");
      const sd = dateString[0];
      const ed = dateString[1];
      var res = await axios.get("/api/do_inv/common/get_s3key_from_date_range", {
         params: {
            startDate: sd,
            endDate: ed,
            project_id: project_id,
            current: "conc_do",
         },
      });
      set_item_no_file(res.data.data.item_no_file);

      if (res.data.data.items != undefined) {
         const temp_d = res.data.data.items;
         setListS3Key(temp_d);
      }
   };

   const downloadFile = async () => {
      const token = localStorage.getItem("bookwormJWT");
      const folder = `${project_id.toLowerCase()}/conc_do/`;

      //test
      // var res = await axios.post(
      //    "https://9cl2yvuwo0.execute-api.ap-southeast-1.amazonaws.com/dev/do-inv_get_batch_files",
      //    {
      //       token: token,
      //       folder: folder,
      //       files: listS3Key,
      //    }
      //    // {
      //    //    headers: { "Content-Type": "application/json" },
      //    // }
      // );

      var res = await axios.post(
         "https://1xy4pauoff.execute-api.ap-southeast-1.amazonaws.com/prod/concdo/getbatchfiles",
         {
            token: token,
            folder: folder,
            files: listS3Key,
         }
      );
      console.log(res);
      setDownloadDone(false);
      if (res.data !== undefined) {
         window.open(res.data);
      } else {
         message.error("Export file is not success");
      }
   };
   const handleClickDownload = () => {
      // if (listS3Key.length < 251 && listS3Key.length > 0) {
      setDownloadDone(true);

      downloadFile();

      // }
   };

   return (
      <div>
         <Modal
            visible={visible}
            title="Download pdf files"
            okText="Close"
            cancelText="Cancel"
            onCancel={onCancel}
            style={{ pading: "0px" }}
            width={600}
            onOk={onCreate}
            footer={[
               <Button key="submit" type="primary" onClick={onCancel}>
                  Close
               </Button>,
            ]}
         >
            <p>Select the date range: </p>
            <RangePicker
               onChange={(date, dateString) => {
                  handleClickDate(dateString);
               }}
            />

            <br></br>
            <br></br>
            {listS3Key.length == 0 ? <p>There is no item to download, please select the date range.</p> : null}

            {listS3Key.length > 0 && listS3Key.length < 251 ? (
               <p>
                  There are total <a style={{ fontWeight: "bold" }}> {listS3Key.length}</a> items
               </p>
            ) : null}
            {listS3Key.length > 250 ? (
               <p style={{ color: "red" }}>
                  You are select <a style={{ fontWeight: "bold" }}> {listS3Key.length}</a> items. Allow max at 250 files
                  only. Please re-select date range.
               </p>
            ) : null}

            {item_no_file > 0 ? (
               <p style={{ color: "DarkOrange" }}>
                  Please note <a style={{ fontWeight: "bold" }}> {item_no_file}</a> DO got not pdf files.
               </p>
            ) : null}
            <button
               type="primary"
               onClick={() => handleClickDownload()}
               disabled={listS3Key.length > 0 && listS3Key.length < 251 ? false : true}
            >
               Click here to download
            </button>
            {downloadDone ? <Spin size="large" style={{ marginleft: "10px" }} /> : null}
         </Modal>
      </div>
   );
};

export default ExportBatchConcDo;
