import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { Button, Card, Elevation } from "@blueprintjs/core";
import moment from 'moment'
import { constants } from '../constants'
import { Divider } from 'rsuite';
import { Typography } from 'antd'
const { Text } = Typography
function CellManPowerWeek(props) {
  const [values, setValues] = useState([])
  const [divide, setDivide] = useState('100%')
  useEffect(() => {
    try {
      if (props.node) {
        if (props.value && !props.node.group) {

          let temp = []
          let group = {}
          let parse = JSON.parse(props.value)
          _.forEach(parse, (value, d) => {
            if (value.projectName) {
              let split1 = value.projectName.split('_')
              let split2 = value.power.toString().split('_')
              _.forEach(split1, (i, k) => {
                temp.push({ projectName: split1[k], power: split2[k] })
              })
            }
          })
          setDivide(`${100 / temp.length}%`)
          setValues(temp)
          let rowHeight = props.node.rowHeight
          let newRowHeight = 35 * temp.length
          props.node.setRowHeight(rowHeight < newRowHeight ? newRowHeight : rowHeight)
          props.api.onRowHeightChanged()
          // console.log(props)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [props.value])


  return (
    <React.Suspense fallback={null}>


      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {values.map((i, k) =>
          <>
            <div style={{ height: divide, width: '100%', padding: 0, }} >
              <div style={{ width: '100%', height: '100%', padding: 0,}}  >
                {i.power == '100' ?
                  <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', width: '100%', height: '100%', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center', backgroundColor: '#93C47D' }}>
                    <Text strong>{i.projectName}</Text>
                  </div>
                  :
                  <>
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%', overflow: 'hidden' }} >
                      <div style={{ width: 'calc(100% - 0px)', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
                        <Text strong>{i.projectName}</Text>
                      </div>
                      {/* <div style={{
                        width: 20, backgroundColor: '#2196f3', color: 'white', height: 20, display: 'flex', alignItems: 'center'
                        , alignContent: 'center', justifyContent: 'center', borderRadius: '50%'
                      }} >
                        {`->`}
                      </div> */}
                      {/* <div style={{ width: 'calc(50% - 10px)', textOverflow: 'ellipsis', overflowX: 'hidden', textAlignLast: 'center' }}>
                        <span>{i.power}</span>
                      </div> */}
                    </div>
                  </>
                }

              </div>
            </div>
          </>

        )}
      </div>



      {/* <div style={{ display: 'flex' }}>
        {values.map((i, k) =>
          <>
            <div style={{ display: 'flex', width: divide, paddingRight: 2.5,paddingLeft: 2.5 }}>
              <div style={{ background: i.projectName && 'pink', textAlign: 'center', width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                {i.projectName}
              </div>
              <div style={{ background: i.projectName && 'orange', textAlign: 'center', width: '50%', textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                {i.power}
              </div>
            </div>
          </>

        )}
      </div> */}
    </React.Suspense>
  )
}

CellManPowerWeek.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setManpowerData: PropTypes.func.isRequired,
  setManpowerLoading: PropTypes.func.isRequired,
  manpowerLoading: PropTypes.bool.isRequired,
  manpowerData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
  typeTimeline: PropTypes.object.isRequired,
  openEditMultiValuesDialog: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    manpowerLoading: state.manpower.loading,
    manpowerData: state.manpower.data,
    gridApi: state.manpower.gridApi,
    selectedNode: state.manpower.selectedNode,
    timelineDate: state.manpower.timelineDate,
    typeTimeline: state.manpower.typeTimeline,
    openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
    manpower: state.manpower
  };
}
export default connect(mapStateToProps, { setManpowerData, setManpowerLoading })(CellManPowerWeek);






