/* eslint-disable */

import { printSheet } from '../function/ForgeFunction'
import {IconSelectCategory,IconSelectCategoryActive,IconSelectModel,IconSelectModelActive} from '../../../image'

const Autodesk = window.Autodesk;
const THREE = window.THREE;

const iconTest = () => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="30" height="30"
    viewBox="0 0 172 172"
    style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="none" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" stroke="none" stroke-width="1"></path><g stroke="none" stroke-width="1"><path d="M78.32307,104.3696l-29.58973,24.5788v-116.4268l82.61733,80.324l-37.8916,4.92493l24.3896,58.0844l-15.12453,6.61627z" fill="#ffffff"></path><path d="M51.6,19.30413l73.50133,71.46027l-28.2768,3.67507l-7.45907,0.96893l2.91253,6.93733l21.83827,52.0128l-9.87853,4.32293l-21.7752,-51.858l-2.99853,-7.138l-5.9512,4.94787l-21.9128,18.20333v-103.53253M45.86667,5.73333v129.32107l31.30973,-26.01213l24.0284,57.2244l20.382,-8.92107l-24.0284,-57.2244l40.0416,-5.20013l-91.73333,-89.18773z" fill="#000000"></path></g><g fill="#ffffff" stroke="none" stroke-width="1"><path d="M115.775,12.67h7.27l13.94,34.81l13.95,-34.81h7.29v42.66h-5.62v-16.61l0.52,-17.93l-14,34.54h-4.31l-13.97,-34.45l0.56,17.84v16.61h-5.63z"></path></g><path d="M105.775,65.33v-62.66h62.45v62.66z" fill="#ff0000" stroke="#50e3c2" stroke-width="3" opacity="0"></path></g></svg>
)

var viewer = null;
let button1 = null;
let button2 = null
let button3 = null
let button4 = null
let measureExt = false
let bimExt = false
let eleExt = false
let secPlanExt = false
class WHExtension_DWF extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options)
    this.container = viewer.canvas.parentElement;
    this.rect = this.container.getBoundingClientRect();
    Autodesk.Viewing.Extension.call(this, viewer, options);
  }
  load() {
    document.addEventListener('keyup', this.setupHotKey, false);
    document.addEventListener('mousemove', this.handleMouseMove1)
    return true;
  };
  unload() {
    document.removeEventListener('keyup', this.setupHotKey, false);
    document.removeEventListener('mousemove', this.handleMouseMove1)
    if (this.subToolbar) {
      this.viewer.toolbar.removeControl(this.subToolbar);
      this.subToolbar = null;
      viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBind);
    }
  };
  handleMouseMove1 = (event) => {
    try {
      this.x = event.clientX - this.rect.x;
      this.y = event.clientY - this.rect.y;
    } catch { }
  }
  setupHotKey = (e) => {
    try {
      if (!viewer) return
      if (e.target.nodeName !== 'BODY') return
      let x = e.key;
      if (x == "m" || x == "M") {
        let extension = viewer.getExtension('Autodesk.Measure')
        if (!measureExt) {
          measureExt = true
          extension.activate('distance')
        }
        else {
          measureExt = false
          extension.deactivate()
        }
      } else if (x == "b" || x == "B") {
        let extension = viewer.getExtension('Autodesk.BimWalk')
        if (!bimExt) {
          bimExt = true
          extension.activate()
        }
        else {
          bimExt = false
          extension.deactivate()
        }
      } else if (x == "s" || x == "S") {
        let hitTest = viewer.hitTest(this.x, this.y, true);
        let extension1 = viewer.getExtension('Autodesk.BimWalk')
        let check = extension1? extension1.activeStatus: false
        if(!check){
          let extension = viewer.getExtension('Autodesk.Section')
          if (!hitTest) {
            secPlanExt = false
            extension.deactivate()
            return
          }
          if (!secPlanExt) {
            secPlanExt = true
            // extension.activate('x')
            extension.setSectionPlane(hitTest.face.normal, hitTest.point)
          }
          else {
            secPlanExt = false
            extension.deactivate()
          }
        }
        
      } else if (x == "e" || x == "E") {
        let extension = viewer.getExtension('ElevationToolExtension')
        if (!eleExt) {
          eleExt = true
          extension.activate('', viewer)
        }
        else {
          eleExt = false
          extension.deactivate('')
        }
      }
    } catch { }
  }
  onToolbarCreated = function (toolbar) {
    viewer = this.viewer;



    button1 = new Autodesk.Viewing.UI.Button('btn-select-model');
    button1.addClass('btn-select-model');
    // button1.addClass('fas');
    // button1.addClass('fa-mouse-pointer');
    // button1.addClass('fa-2x');
    button1.container.style.backgroundImage = `url(${IconSelectModel})`
    button1.container.style.backgroundPosition = 'center'
    button1.container.style.backgroundRepeat = 'no-repeat'
    button1.container.style.backgroundSize = 'contain'
    button1.container.style.inner
    button1.setToolTip('Select model');
    button1.onClick = function (e) {
      button2.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
      button2.container.style.backgroundImage = `url(${IconSelectCategory})`
      if (button1.container.classList.contains('inactive')) {
        button1.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
        button1.container.style.backgroundImage = `url(${IconSelectModelActive})`
      } else {
        button1.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
        button1.container.style.backgroundImage = `url(${IconSelectModel})`
      }
    };

    button2 = new Autodesk.Viewing.UI.Button('btn-select-category');
    button2.addClass('btn-select-category');
    // button2.addClass('fas');
    // button2.addClass('fa-mouse-pointer');
    // button2.addClass('fa-2x');
    button2.container.style.backgroundImage = `url(${IconSelectCategory})`
    button2.container.style.backgroundPosition = 'center'
    button2.container.style.backgroundRepeat = 'no-repeat'
    button2.container.style.backgroundSize = 'contain'
    button2.setToolTip('Select Category');
    button2.onClick = function (e) {
      button1.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
      button1.container.style.backgroundImage = `url(${IconSelectModel})`
      if (button2.container.classList.contains('inactive')) {
        button2.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
        button2.container.style.backgroundImage = `url(${IconSelectCategoryActive})`
      } else {
        button2.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
        button2.container.style.backgroundImage = `url(${IconSelectCategory})`
      }
    };

    button4 = new Autodesk.Viewing.UI.Button('btn-select-rebar');
    button4.addClass('btn-select-rebar');
    button4.addClass('fas');
    button4.addClass('fa-mouse-pointer');
    button4.addClass('fa-2x');
    // button2.container.style.backgroundImage = `url(${IconSelectCategory})`
    // button2.container.style.backgroundPosition = 'center'
    // button2.container.style.backgroundRepeat = 'no-repeat'
    // button2.container.style.backgroundSize = 'contain'
    button4.setToolTip('Select Rebar');
    button4.onClick = function (e) {
      if (button4.container.classList.contains('inactive')) {
        button4.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
      } else {
        button4.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        // viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
      }
    };

    button3 = new Autodesk.Viewing.UI.Button('btn-print');
    button3.onClick = function (e) {
      // button3.container.classList.remove('inactive');
      // button3.container.classList.add('active');
      // SelectionWindow(viewer, 'btn', button3)
      printSheet(viewer)
    };
    button3.addClass('btn-print');
    button3.addClass('fas');
    button3.addClass('fa-print');
    button3.addClass('fa-2x');
    button3.setToolTip('Print');
    // SubToolbar
    this.subToolbar = new Autodesk.Viewing.UI.RadioButtonGroup('wh-custom-toolbar-dwf');
   
    if (viewer.impl.model.is3d()) {
    
      this.subToolbar.addControl(button1);
      this.subToolbar.addControl(button2);
      // let ext = viewer.impl.model.myData.loadOptions.modelNameOverride.split('.')
      // if (ext[ext.length - 1].toLowerCase() === 'ifc') {
      //   this.subToolbar.addControl(button4);
      // }
    }else{
      this.subToolbar.addControl(button3);
    }
    viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBind);
    viewer.toolbar.addControl(this.subToolbar);
  };


}
const onSelectionBind = () => {
  if (button1.getState() === Autodesk.Viewing.UI.Button.State.ACTIVE || button2.getState() === Autodesk.Viewing.UI.Button.State.ACTIVE) {
    let ids = viewer.getSelection()
    let parent = checkParent(ids[0])
    viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBind);
    viewer.select(parent)
    viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBind);
  }
}


const checkParent = (id) => {
  let instanceTree = viewer.model.getData().instanceTree;
  let temp = []
  let queue = [];
  queue.push(id);
  while (queue.length > 0) {
    let node = queue.shift();
    let idChild = instanceTree.getNodeParentId(node)
    if (idChild) {
      queue.push(idChild);
      temp.push(idChild)
    }
    else {
      if (button1.getState() === Autodesk.Viewing.UI.Button.State.ACTIVE)
        return node
      else if (button2.getState() === Autodesk.Viewing.UI.Button.State.ACTIVE)
        return temp[temp.length - 2]
    }
  }
}

const buttonActive = (button, isActive) => {
  if (button.container.classList.contains('inactive')) {
    button.container.classList.remove('inactive');
    // button2.container.style.color= ''
    button.container.classList.add('active');
  } else {
    button.container.classList.add('inactive');
    // button2.container.style.color= '#6fffc3'
    button.container.classList.remove('active');
  }
}

const setActiveDockAndShowAll = (button, dock) => {
  if (dock.isVisible()) {
    button.container.classList.remove('inactive');
    button.container.classList.add('active');
    allRebardbId.forEach(item => {
      viewer.show(item)
    })
  }
  else {
    button.container.classList.remove('active');
    button.container.classList.add('inactive');
  }
}
const setActiveDockAndEventSelect = function (button, dock) {
  if (dock.isVisible()) {
    button.container.classList.remove('inactive');
    button.container.classList.add('active');
    viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
  }
  else {
    button.container.classList.remove('active');
    button.container.classList.add('inactive');
    viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
  }
}
function setActiveDock(button, dock) {
  if (dock.isVisible()) {
    button.container.classList.remove('inactive');
    button.container.classList.add('active');
  }
  else {
    button.container.classList.remove('active');
    button.container.classList.add('inactive');
  }
}



Autodesk.Viewing.theExtensionManager.registerExtension('WHExtension_DWF', WHExtension_DWF);
export default 'WHExtension_DWF'