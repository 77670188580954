import React from "react";
import axios from "axios";
import Handsontable from "handsontable";
import { v4 as uuidv4 } from "uuid";
import { HotTable } from "@handsontable/react";
import { Modal, message, Form, Input, DatePicker } from "antd";

// import concDOTempColumns from "../../function/DefineColumns/concDOTempColumns";

const TableInputUnits = ({ visible, onCreate, onCancel, data }) => {
   var hotData = [[]];

   const func_add_new_units = async (hotData) => {
      var unit_list = [];
      let already_exist = "";
      hotData.forEach((e) => {
         let check_if_exist = data.find((c) => c.block == e.block && c.unit_name == e.unit_name);
         if (check_if_exist || check_if_exist != undefined) {
            already_exist = already_exist + "_" + e.unit_name;
         } else {
            if (e.unit_name !== undefined && e.unit_name !== "" && e.no_of_sample !== undefined) {
               let unit = {
                  id: uuidv4(),
                  project_code: e.project_code.trim(),
                  block: e.block.trim(),
                  level: e.level.trim(),
                  unit_name: e.unit_name.trim(),
                  no_of_sample: e.no_of_sample,
                  unit_id: e.project_code + "_" + e.unit_name,
                  remark: e.remark.trim(),
               };
               unit_list.push(unit);
            }
         }
      });
      if (already_exist != "") {
         message.warning(`Got unit already exist, not save for unit name: ${already_exist}`, 10);
      }

      if (unit_list.length > 0) {
         const res = await axios.post("/api/qaqc/dashboard/addunits", {
            data: unit_list,
         });
         if (res.data.data.code === 200) {
            message.success("New DO has saved to database.");
            return "success";
         } else {
            message.warning("Not succesefull when save data.");
            return "not success";
         }
      }
   };

   const columns = [
      {
         title: "Project",
         data: "project_code",
         type: "text",
      },
      {
         title: "Block/Tower",
         data: "block",
         type: "text",
      },

      {
         title: "Level",
         data: "level",
         type: "text",
      },
      {
         title: "Unit Name",
         data: "unit_name",
         type: "text",
      },

      {
         title: "No of sampe",
         data: "no_of_sample",
         type: "numeric",
         strict: true,
         allowInvalid: false,
      },

      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   return (
      <Modal
         visible={visible}
         title="Key in Concrete DO data"
         okText="Create"
         cancelText="Cancel"
         onCancel={onCancel}
         style={{ pading: "0px" }}
         width={815}
         onOk={() => {
            func_add_new_units(hotData);
            hotData = [];
            onCreate();
         }}
      >
         <HotTable
            data={hotData}
            colHeaders={true}
            rowHeaders={true}
            height={500}
            columns={columns}
            minRows={20}
            minCols={6}
            currentRowClassName={"currentRow"}
            colWidths={[100, 100, 100, 100, 100, 200]}
            contextMenu={["row_above", "row_below", "remove_row"]}
            className="do-invoice-table handsontable_do"
            licenseKey="non-commercial-and-evaluation"
         ></HotTable>
      </Modal>
   );
};

export default TableInputUnits;
