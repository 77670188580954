import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import Split from 'react-split';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import ConcreteStackedBarChart from './ConcreteStackedBarChart';
import {
  Tooltip,
  message,
  Button,
  Result,
  Avatar,
  DatePicker,
  Select,
  Radio,
} from 'antd';

import { v4 } from 'uuid';

import _ from 'lodash';
import axios from 'axios';
import { Loader, IconButton, Icon } from 'rsuite';

import {
  getElementdbIdsFromModel,
  getForgeToken,
  getForgeTokenInternal,
} from '../../../module_BimApp/function/ForgeFunction';
import { convertHexColorToVector4 } from '../../../module_BimApp/function/TableFunction';
import TreeModel from '../DataEntryTreeModel';
import stc from 'string-to-color';
import { TableModelQuantity } from '../../../module_DataEntry/table';
import DataEntryToolbar from '../DataEntryToolbar';
import { setDataEntryOpenHistory } from '../../../../actions/app/dataentry';
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

const Autodesk = window.Autodesk;

let ongoingColor = convertHexColorToVector4('#0088FE');
let naColor = convertHexColorToVector4('#d0d4c3');
let completeColor = convertHexColorToVector4('#FFBB28');
let fwColor = convertHexColorToVector4('#02FBFB');
let leanConcrete = convertHexColorToVector4('#ffff80');
const statusColor = {
  CASTED: completeColor,
  CAST: completeColor,
  REBAR: ongoingColor,
  FORMWORK: fwColor,
  'LEAN CONCRETE': leanConcrete,
  'On-going': ongoingColor,
};
class DataEntryPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterType: 'day',
      headerSidebar: 'Browser',
      headerMain: 'Forecast',
      listSheet: [],
      loading: false,
      dataUser: {},
      dialogSettingColumn: false,
      sheetId: null,
      sheetName: '',
      currentSheet: null,
      selectSheet: null,
      selectedMonthMoment: moment(),
      error: false,
      errorCode: '500',
      viewerCurrent: null,
      isViewer: true,
      dataTable: [],
      columnTableForge: [],
      displayTreeView: true,
      displayNewSheet: false,
      mainParameter: '',
      mainName: '',
      mainIndex: 0,
      allParameter: [],
      itemIds: [],
      isCollapse: false,
      rowData: [],
      columnDefs: [
        {
          headerName: 'Pour Name',
          field: 'pourName',
          filter: true,
        },
        {
          headerName: 'Plan Start Date',
          field: 'planStartDate',
        },
        {
          headerName: 'Actual Date Start-End',
          field: 'dateActualStartEnd',
        },
        {
          headerName: 'CJ Name',
          field: 'cjName',
        },
        {
          headerName: 'Volume',
          field: 'volume',
          width: 100,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 100,
        },
      ],
    };
    this.split1 = React.createRef();
    this.split2 = React.createRef();
    this.treeModel = React.createRef();
    this.gridRef = React.createRef();
  }

  componentDidMount = () => {
    this.getSheetData();

    try {
      message.destroy();
      this.state.viewerCurrent.tearDown();
      this.state.viewerCurrent.finish();
      $('#forgeViewer').empty();
      this.setState({ viewerCurrent: null, isCollapse: true });
    } catch {}
    if (!this.state.isViewer) {
      this.split1.current.split.setSizes([100, 0]);
    } else {
      this.split1.current.split.setSizes([55, 45]);
      this.launchViewer();
      this.setState({ displayTreeView: true, isCollapse: true });
    }
  };

  async getSheetData() {
    let res = await axios.get(`/api/dataentry/get-project-tables`, {
      params: {
        projectId: this.props.location.state.projectId,
        token: this.props.userInfo.token,
      },
    });

    let sheet = res.data.find((x) => x.name == 'Slab 5D');
    this.setState({ selectSheet: sheet });
    if (sheet) {
      let resSheet = await axios.get(`/api/dataentry/get-sheet5d-data`, {
        params: {
          projectId: this.props.location.state.projectId,
          sheetId: sheet.id,
          sheetName: sheet.name,
          token: this.props.userInfo.token,
        },
      });

      console.log('resSheet', resSheet);

      let temp = [];
      let startDay = moment().startOf('month');
      let endDay = moment().add(2, 'M').endOf('month');

      let tempRowData = [];

      _.forEach(resSheet.data, (v) => {
        console.log('start', v['Plan date start']);
        if (v['Plan date start']) {
          let date = moment(v['Plan date start'], 'DD/MM/YYYY');

          if (date.isSameOrAfter(startDay) && date.isSameOrBefore(endDay)) {
            v.active = false;
            v.momentFinish = v['Actual date finish']
              ? moment(v['Actual date finish'], 'DD/MM/YYYY')
              : null;
            v.momentPlanStart = v['Plan date start']
              ? moment(v['Plan date start'], 'DD/MM/YYYY')
              : null;

            console.log('v.status ', v.status);
            v.isCast = v.Status == 'CASTED';

            v.isForecast = !v.isCast;

            temp.push(v);

            tempRowData.push({
              id: v.id,
              ...v,
              pourName: v['Pour name'],
              cjName: v['CJ NAME'],
              planStartDate: v['Plan date start'],
              dateActualStartEnd: this.getDateStartEndString(
                v['Actual date start'],
                v['Actual date finish']
              ),
              status: v.Status,
              volume: v["Con'c Volume Design (m3)"],
              volumeActual: v["Con'c Volume Actual (m3)"],
            });
          }
        }
      });

      this.setState({ listSheet: temp });
      this.setState({ rowData: tempRowData });
    }
  }

  getDateStartEndString(start, end) {
    let s = 'x';
    let e = 'x';

    if (start) {
      s = start;
    }

    if (end) {
      e = end;
    }

    return `${s}-> ${e}`;
  }

  launchViewer = () => {
    getForgeToken().then((token) => {
      var options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        var config3d = {
          loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
          extensions: [
            // "Autodesk.AEC.LevelsExtension",
            'Autodesk.AEC.Minimap3DExtension',
            // "Autodesk.AEC.Hypermodeling",
          ],
        };
        let viewer = new Autodesk.Viewing.GuiViewer3D(
          document.getElementById('forgeViewer'),
          config3d
        );
        viewer.start();
        this.setState({ viewerCurrent: viewer });
      });
    });
  };

  handleForgeViewer = () => {
    this.setState(
      {
        isViewer: !this.state.isViewer,
        dataTable: [],
      },
      () => {
        try {
          message.destroy();
          this.state.viewerCurrent.tearDown();
          this.state.viewerCurrent.finish();
          $('#forgeViewer').empty();
          this.setState({ viewerCurrent: null, isCollapse: true });
        } catch {}
        if (!this.state.isViewer) {
          this.split1.current.split.setSizes([100, 0]);
        } else {
          this.split1.current.split.setSizes([60, 40]);
          this.launchViewer();
          this.setState({ displayTreeView: true, isCollapse: true });
        }
      }
    );
  };
  dragSplit = () => {
    try {
      this.state.viewerCurrent.resize();
    } catch {}
  };

  handleOpenTreeModel = () => {
    this.setState({ displayTreeView: true });
  };
  handleCloseTreeView = () => {
    this.setState({ displayTreeView: false });
  };

  handleAddColumnSheet = (e) => {
    if (this.props.isAdmin) {
      this.setState({
        displayAddColumnSheet: true,
        currentSheet: e.props.item,
      });
    }
  };
  handleCloseColumnSheet = () => {
    this.setState({ displayAddColumnSheet: false });
  };

  handleDeleteSheet = (e) => {
    if (this.props.isAdmin) {
      let idx = e.props.idx;
      let item = e.props.item;
      if (!window.confirm('Are you want to delete it')) return;
      this.setState({ loading: true });
      axios
        .delete(`/api/dataentry/delete-sheet5d`, {
          data: {
            projectId: this.props.location.state.projectId,
            sheetId: item.id,
            sheetName: item.name,
            token: this.props.userInfo.token,
          },
        })
        .then(() => {
          let temp = this.state.listSheet;
          temp.splice(idx, 1);
          this.setState({
            listSheet: temp,
            loading: false,
            columns: [],
          });
        })
        .catch(() => {
          message.warning(`Can't delete sheet`);
          this.setState({ loading: false });
        });
    }
  };
  handleCollapse = (value) => {
    this.setState({ isCollapse: value }, () => {
      if (this.split2.current) {
        if (this.state.isCollapse) {
          this.split2.current.split.setSizes([100, 0]);
        } else {
          this.split2.current.split.setSizes([50, 50]);
        }
      }
      try {
        this.state.viewerCurrent.resize();
      } catch {}
    });
  };

  handleModelData = () => {
    return new Promise((resolve, reject) => {
      let parameter = [];
      let listCheckTotal = {};
      let columnTableForge = [];
      _.forEach(this.state.selectSheet.columns, (v) => {
        if (v.parameter !== '' && v.parameter) {
          let data = {
            field: v.parameter,
            lockVisible: true,
          };
          if (v.type === 'numeric') {
            data.aggFunc = 'sum';
          }
          if (v.isMatching) {
            // data.headerCheckboxSelection = true
            // data.headerCheckboxSelectionFilteredOnly = true
            data.checkboxSelection = true;
            data.enableRowGroup = true;
            data.rowGroup = true;
            data.isMatching = true;
            data.lockPosition = true;
            data.hide = true;
            columnTableForge.splice(1, 0, data);
          } else {
            columnTableForge.push(data);
          }
          parameter.push(v.parameter);
          listCheckTotal[v.parameter] = v.type === 'numeric';
        }
      });
      if (parameter.length !== 0) {
        let tempModels = [];
        let count = 0;
        let models = this.state.viewerCurrent.impl.modelQueue().getModels();
        _.forEach(models, (v) => {
          let dbIds = getElementdbIdsFromModel(v);
          count += dbIds.length;
          tempModels.push({ model: v, dbIds });
        });
        let temp = [];
        let allParameter = [];
        _.forEach(tempModels, (v) => {
          _.forEach(v.dbIds, (id) => {
            v.model.getProperties(id, (modelAProperty) => {
              let data = {
                id: id,
                itemId:
                  v.model.myData.loadOptions.itemId +
                  ' ' +
                  v.model.myData.loadOptions.version +
                  ' ' +
                  v.model.myData.loadOptions.guid,
                externalId: modelAProperty.externalId,
              };
              _.forEach(parameter, (p) => {
                data[p] = p === 'checkBox' ? false : '';
              });
              _.forEach(modelAProperty.properties, (o) => {
                if (parameter.includes(o.displayName)) {
                  data[o.displayName] = _.isNumber(o.displayValue)
                    ? +o.displayValue.toFixed(2)
                    : o.displayValue;
                }
                if (allParameter.includes(o.displayName)) {
                  allParameter.push(o.displayName);
                }
              });
              temp.push(data);
              count--;
              if (count === 0) {
                if (this.state.mainParameter !== null) {
                  let tempData = this.handleForgeData(
                    temp,
                    this.state.mainParameter,
                    listCheckTotal
                  );

                  this.setState(
                    {
                      dataTable: tempData,
                      allDataTable: tempData,
                      columnTableForge,
                      allParameter,
                    },
                    () => {
                      resolve();
                    }
                  );
                }
              }
            });
          });
        });
      } else {
        reject();
        message.warning('Please, setup parameter');
      }
    });
  };
  handleForgeData = (data, mainParameter, listCheckTotal) => {
    console.log('data', data);
    let t = {};
    _.forEach(data, (v) => {
      if (v[mainParameter] === '') return;
      if (!t[v[mainParameter]]) t[v[mainParameter]] = [];
      t[v[mainParameter]].push(v);
    });

    let tempData = [];
    _.forEach(t, (v) => {
      let tempTotal = { id: v4(), itemIds: [] };
      _.forEach(v, (i1) => {
        _.forEach(i1, (i2, k2) => {
          if (['externalId', 'id'].includes(k2)) return;
          if (k2 === 'itemId') {
            if (!tempTotal.itemIds.includes(i2)) tempTotal.itemIds.push(i2);
          } else if (_.isNumber(i2)) {
            if (listCheckTotal[k2]) {
              if (!tempTotal[k2]) tempTotal[k2] = 0;
              tempTotal[k2] = tempTotal[k2] + i2;
              tempTotal[k2] = +tempTotal[k2].toFixed(2);
            } else {
              if (!tempTotal[k2]) tempTotal[k2] = i2;
              if (tempTotal[k2] !== 'var')
                tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
            }
          } else {
            if (!tempTotal[k2]) tempTotal[k2] = i2;
            if (tempTotal[k2] !== 'var')
              tempTotal[k2] = tempTotal[k2] === i2 ? i2 : 'var';
          }
        });
      });
      tempTotal.children = v;
      tempData.push(tempTotal);
    });
    return tempData;
  };

  handleColorStatus = async (value) => {
    if (!this.state.viewerCurrent) {
      message.warning('Please, open model');
      return;
    }

    if (this.state.dataTable.length === 0) {
      await this.handleModelData();
    }

    if (this.state.dataTable.length === 0) {
      message.warning('Please, check sheet setup');
      return;
    }

    this.state.viewerCurrent.clearThemingColors(
      this.state.viewerCurrent.impl.model
    );
    let revitElements = this.state.allDataTable[0].children;

    for (const row of this.state.rowData) {
      let modelData = revitElements.find(
        (o) => o['POUR_NAME'] === row['Pour name']
      );

      if (modelData) {
        if (row.isCast) {
          this.state.viewerCurrent.setThemingColor(
            modelData.id,
            completeColor,
            this.state.viewerCurrent.impl.model
          );
        } else if (row.isForecast) {
          this.state.viewerCurrent.setThemingColor(
            modelData.id,
            ongoingColor,
            this.state.viewerCurrent.impl.model
          );
        } else {
          this.state.viewerCurrent.setThemingColor(
            modelData.id,
            naColor,
            this.state.viewerCurrent.impl.model
          );
        }
      }
    }
  };
  handleOpenModelInRow = async (row) => {
    console.log('show model');
    let clone = row;
    let resList = [];
    let globalOffset = JSON.parse(clone.globalOffset);
    let itemIds = JSON.parse(clone.itemIds);
    itemIds.forEach((v) => {
      let split = v.split(' ');
      const response = axios.post('/api/items/get-item-version', {
        itemId: split[0],
        version: split[1],
        viewGuid: split[2],
      });
      resList.push(response);
    });
    let res = await Promise.all(resList);
    let temp = [];
    _.forEach(res, (v) => {
      let data = v.data;
      let index = _.findIndex(data.revisions, (o) => {
        return o.version === data.versionCurrent;
      });
      if (index >= 0) {
        data.objectId = data.revisions[index].objectId;
        data.globalOffset = globalOffset;
        temp.push(data);
      }
    });

    this.setState({ itemIds: temp });
  };

  handleSelectObjectInRows = (rows, check = false) => {
    console.log('rows', rows);
    if (!this.state.viewerCurrent) {
      return;
    }
    let tempIds = [];

    _.forEach(rows, (v) => {
      if (v.Status !== '' && v.Status !== undefined) {
        let modelData = this.state.allDataTable[0].children.find(
          (o) => o['POUR_NAME'] === v['Pour name']
        );

        tempIds.push(modelData.id);
      }
    });

    let selection = [];
    this.state.viewerCurrent.showAll();
    this.state.viewerCurrent.clearSelection();

    selection.push({
      model: this.state.viewerCurrent.impl.model,
      ids: tempIds,
    });

    this.state.viewerCurrent.setAggregateSelection(selection);

    //filter grid
  };

  callbackRemoveDataTable = () => {
    this.setState({ dataTable: [] });
  };
  callbackLoadDone = () => {
    this.handleColorStatus(null);
  };

  getStackedData() {
    if (this.state.filterType == 'day') {
      let filteredData = this.state.rowData.filter(
        (x) =>
          x.momentPlanStart.format('MM-YY') ==
          this.state.selectedMonthMoment.format('MM-YY')
      );

      let groupByStartDate = _.groupBy(
        filteredData,
        (x) => x['Plan date start']
      );

      let datas = Object.keys(groupByStartDate).map((key) => {
        let itemFinished = [];
        let itemPending = [];
        let itemNa = [];

        groupByStartDate[key].forEach((x) => {
          if (x.isCast == true) {
            itemFinished.push(x);
          } else if (x.isForecast) {
            itemPending.push(x);
          } else {
            itemNa.push(x);
          }
        });

        let concreteVolume = _.sumBy(
          itemPending,
          (x) => x["Con'c Volume Design (m3)"]
        );
        let concreteVolumeCasted = _.sumBy(
          itemFinished,
          (x) => x["Con'c Volume Actual (m3)"]
        );

        let concreteVolumeNA = _.sumBy(
          itemNa,
          (x) => x["Con'c Volume Actual (m3)"]
        );

        let item = {
          key,
          Forecast: _.round(concreteVolume, 2),
          Cast: _.round(concreteVolumeCasted, 2),
          NA: _.round(concreteVolumeNA, 2),
          forgeData: groupByStartDate[key],
        };

        return item;
      });

      return datas;
    }

    console.log('this.state.rowData', this.state.rowData);
    if (this.state.filterType == 'month') {
      let filteredData = this.state.rowData;

      let groupByStartDate = _.groupBy(filteredData, (x) =>
        x.momentPlanStart.format('MM-YY')
      );

      let datas = Object.keys(groupByStartDate).map((key) => {
        let itemFinished = [];
        let itemPending = [];
        let itemNa = [];
        groupByStartDate[key].forEach((x) => {
          if (x.isCast == true) {
            itemFinished.push(x);
          } else if (x.isForecast) {
            itemPending.push(x);
          } else {
            itemNa.push(x);
          }
        });

        let concreteVolume = _.sumBy(
          itemPending,
          (x) => x["Con'c Volume Design (m3)"]
        );

        console.log('itemFinished', itemFinished);
        let concreteVolumeCasted = _.sumBy(
          itemFinished,
          (x) => x["Con'c Volume Actual (m3)"]
        );

        let concreteVolumeNA = _.sumBy(
          itemNa,
          (x) => x["Con'c Volume Actual (m3)"]
        );
        let item = {
          key,
          Forecast: _.round(concreteVolume, 2),
          Cast: _.round(concreteVolumeCasted, 2),
          NA: _.round(concreteVolumeNA, 2),
          forgeData: groupByStartDate[key],
        };

        return item;
      });

      return datas;
    }

    return [];
  }

  disabledDate(current) {
    return (
      current &&
      (current < moment().add(-1, 'month').endOf('day') ||
        current > moment().add(2, 'month').endOf('day'))
    );
  }

  getContextMenuItems = (params) => {
    return [
      {
        name: 'Open Model',
        action: () => {
          console.log(params.node.data);
          this.handleOpenModelInRow(params.node.data);
        },
      },
      {
        name: 'Select Objects',
        action: () => {
          this.handleSelectObjectInRows(params.api.getSelectedRows());
        },
      },
    ];
  };

  render() {
    return (
      <div id='data-entry'>
        <div id='layout-dataentry'>
          <div id='main-dataentry'>
            <div className='header'>
              <IconButton
                size='xs'
                color={!this.state.isViewer ? 'blue' : 'red'}
                icon={
                  this.state.isViewer ? <Icon icon='close' /> : <Icon>3D</Icon>
                }
                onClick={this.handleForgeViewer}
                style={{
                  marginTop: 5,
                  marginRight: 5,
                  float: 'right',
                  borderRadius: 0,
                }}
              />
            </div>
            <div className='content'>
              {this.state.loading && (
                <Loader
                  backdrop
                  center
                  content='Loading...'
                  speed='fast'
                  size='md'
                  vertical
                  style={{ zIndex: 10000 }}
                />
              )}
              <Split
                ref={this.split1}
                id='splitForgeViewer'
                className='splitForgeViewer'
                minSize={[600, 0]}
                sizes={[100, 0]}
                gutterSize={2}
                gutterAlign='center'
                direction='horizontal'
                cursor='col-resize'
                style={{
                  height: 'calc(100% - 50px)',
                  width: '100%',
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                }}
                onDrag={this.dragSplit}
              >
                <div id='content3D' style={{ height: '100%', width: '100%' }}>
                  <div
                    style={{ height: 'calc(100% - 46px)' }}
                    className=' flex flex-row  ml-2'
                  >
                    <div className='mt-2'>
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({ filterType: e.target.value });
                        }}
                        value={this.state.filterType}
                        buttonStyle='solid'
                      >
                        <Radio.Button value='day'>Day</Radio.Button>
                        <Radio.Button value='month'>Month</Radio.Button>
                      </Radio.Group>

                      {this.state.filterType === 'day' && (
                        <MonthPicker
                          allowClear={false}
                          disabledDate={this.disabledDate}
                          className='ml-2'
                          value={this.state.selectedMonthMoment}
                          format='MM-YYYY'
                          onChange={(e) => {
                            this.setState({ selectedMonthMoment: e });
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <h2>Concrete Volume Record and Forecast</h2>

                      <ConcreteStackedBarChart
                        handleSelectObjectInRows={this.handleSelectObjectInRows}
                        handleSetSelectRowsAgGrid={(rows) => {
                          let hardcodedFilter = {
                            pourName: {
                              type: 'set',
                              values: rows.map((x) => x['Pour name']),
                            },
                          };

                          this.gridRef.current.api.setFilterModel(
                            hardcodedFilter
                          );
                        }}
                        dataConcrete={this.getStackedData()}
                      />
                    </div>

                    <Button
                      onClick={() => {
                        this.gridRef.current.api.setFilterModel(null);
                      }}
                    >
                      Clear Filter
                    </Button>
                    <div
                      className='ag-theme-alpine mt-2'
                      style={{ height: 400, width: 1020 }}
                    >
                      <AgGridReact
                        ref={this.gridRef}
                        getContextMenuItems={this.getContextMenuItems}
                        rowData={this.state.rowData}
                        columnDefs={this.state.columnDefs}
                        enableRangeSelection='true'
                        rowSelection='multiple'
                        rowMultiSelectWithClick={true}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
                <div
                  id='content2D'
                  style={{
                    height: '100%',
                    width: '100%',
                    zIndex: 1000,
                    backgroundColor: 'white',
                  }}
                >
                  {this.state.isViewer && (
                    <div style={{ height: '100%', width: '100%' }}>
                      <div
                        id='forgeViewer'
                        style={{
                          position: 'relative',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <IconButton
                          style={{
                            zIndex: '102',
                            float: 'left',
                            top: '0',
                            borderRadius: 0,
                          }}
                          size='sm'
                          appearance='ghost'
                          color='blue'
                          icon={<Icon icon='detail' />}
                          onClick={this.handleOpenTreeModel}
                        />
                        <TreeModel
                          ref={this.treeModel}
                          open={this.state.displayTreeView}
                          close={this.handleCloseTreeView}
                          viewer={this.state.viewerCurrent}
                          projectId={this.props.location.state.projectId}
                          projectName={this.props.location.state.projectName}
                          userInfo={this.props.userInfo}
                          itemIds={this.state.itemIds}
                          callbackRemoveDataTable={this.callbackRemoveDataTable}
                          callbackLoadDone={this.callbackLoadDone}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Split>
              :
              <div
                style={{
                  position: 'relative',
                  top: 'calc(50% - 30px)',
                  textAlignLast: 'center',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    dataEntryData: state.dataentry.data,
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  };
}
export default connect(mapStateToProps, {
  setDataEntryOpenHistory: setDataEntryOpenHistory,
})(DataEntryPage);
