import React from 'react';
import $ from 'jquery';
import { List, Icon, Typography, Progress } from 'antd';
import _ from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

const { Text } = Typography;
class ModalDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 50,
      heightModal: 0,
      data: [],
      completed:0,
      downloading:0
    }
  }
  componentDidMount = () => {
    $('#download-modal-bottom').hide()
    // let dsda=['test1','tes2','test3']
    // let temp= {}
    // _.forEach(dsda,(v,k)=>{
    //   temp[v] =10
    // })
    // this.setState({data:temp})
    // console.log(this.state.data)
  }
  componentWillReceiveProps = (nextProps) => {
    if ($('#download-modal-bottom').is(":visible")){
      let temp =[]
      let completed =0
      let downloading=0
      _.forEach(nextProps.listDownload, (v, k) => {
        temp.push({name:k,percent:v})
        if(v===100)
          completed= completed+1
        else
          downloading= downloading+1
      })
      this.setState({ data:temp,completed:completed,downloading:downloading})
    }
  
  }
  handleClosePanel = () => {
    $('#download-modal-bottom').hide(1000)
  }
  handleExpand = () => {
    this.setState({ heightModal: this.state.heightModal === 0 ? 270 : 0 })
  }
  render() {

    return (
      <div id='download-modal-bottom' style={{ position: 'fixed', zIndex: 10, left: 35, bottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <div style={{ marginTop: 10 }}>
          <div style={{ border: '1px solid #dae1e6', backgroundColor: '#fff', borderRadius: 3, width: 345, fontSize: 13, color: '#222', zIndex: 1 }}>
            <div id='title-modal-bottom' style={{ fontSize: 15, height: 55, padding: '16px 15px', backgroundColor: '#edf0f2' }}>
              <div style={{ display: 'inline-block' }}>Download</div>
              <div style={{ display: 'inline-block', float: 'right' }}>
                {this.state.heightModal === 0 ?
                  <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={this.handleExpand} /> :
                  <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={this.handleExpand} />}
                <CloseIcon style={{ cursor: 'pointer' }} onClick={this.handleClosePanel} />
              </div>
            </div>
            <div id='summary-modal-bottom' style={{ padding: '13px 15px', boderBottom: '1px solid #dae1e6', display: 'flex' }}>
              <div style={{ flex: 'auto' }}>
                <span>{this.state.downloading} downloading / {this.state.completed} completed</span>
              </div>
            </div>
            <div id='content-modal-bottom' style={{ height: this.state.heightModal, overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}>
              <List style={{ borderLeft: 0, borderRight: 0 }}
                size="small"
                bordered
                dataSource={this.state.data}
                renderItem={item =>
                  <List.Item >
                    <List.Item.Meta
                      title={
                        <div>
                          {item.percent === 100 ?
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                            : <Icon type="loading" />}
                          <Text style={{ verticalAlign: 'middle' }} strong>{' '}{item.name}</Text>
                        </div>
                      }
                      description={
                        item.percent !== 100 &&
                        <Progress percent={item.percent} />
                      }
                    />
                  </List.Item>
                }
              />
            </div>
          </div>
        </div>
      </div>

    );
  }
}


export default ModalDownload