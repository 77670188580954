import { Badge, Card, Col, Icon, Row, Tabs } from 'antd';
import { curveCardinal } from 'd3-shape';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Area, AreaChart, Brush, CartesianGrid, Cell, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { apiFetchDataMultiForm, apiFetchDataThisProject, apiFetchPublicSettingThisProject, apiFetchRowHistoryThisProject, apiUpdateSettingPublic } from '../../../api';
import { colorTextRow, colorType, navBarDmsHeight, offsetHeight, sideBarWidth, tradeArrayForm, tradeArrayMeetingMinutesForm } from '../../../constants';
import { getOverdueMultiFormInfo, getOverdueRfaInfo, getRefStringWithVersion, statusOrderArray } from '../../../utils';
import { getDrawingStatusByConsultant, getInputDataInitially } from '../generalComponents/ComponentDmsOverall';
import { getInfoValueFromRefDataForm } from '../pageSpreadsheet/CellForm';
import { convertTradeCodeInverted } from '../pageSpreadsheet/PanelAddNewRFA';




const { TabPane } = Tabs;


const cardinal = curveCardinal.tension(0.2);

const statusOutstandingArray = [
   'Overdue',
   'Due in the next 1 - 3 days',
   'Due in the next 4 - 14 days',
   'Replied'
];



const meetingFolderArray = [
   'Project Progress Meeting',
   'Technical Meeting',
   'ICE Meeting',
];

const transmittedForArray = ['Information / Action', 'Comments / Approval', 'Construction', 'Record'];


const PageChart = (props) => {

   const { infoFromStore, currentWindow, token } = props;
   const { projectName, projectId, email } = infoFromStore;

   const [dataRefOutstanding, setDataRefOutstanding] = useState(null);

   const mounted = useRef(false);
   useEffect(() => {
      mounted.current = true;
      return () => mounted.current = false;
   });


   // SAVE CURRENT DATA CHART TO SERVER
   // useEffect(() => {
   //    const fetchAllFormData = async () => {
   //       try {
   //          const monthTocheck = moment().format('MM/YY');
   //          const resPublicSettings = await apiFetchPublicSettingThisProject({ token, projectId });

   //          let chartHistoryData = (resPublicSettings.data[0] || {})['chartHistoryData'];
   //          if (
   //             !chartHistoryData ||
   //             (chartHistoryData && chartHistoryData['monthToSaveData'] !== monthTocheck)
   //          ) {
   //             const dashboardInputHistory = await fetchDataFromServer({ projectId, email, token, isDataForChart: 'history', overdueLeadConsultantOnly });
   //             chartHistoryData = {
   //                monthToSaveData: monthTocheck,
   //                dataHistory: dashboardInputHistory
   //             };
   //             await apiUpdateSettingPublic({ token, projectId, email, publicSettings: { chartHistoryData } });
   //          };

   //          const dashboardInputCurrent = await fetchDataFromServer({ projectId, email, token, isDataForChart: 'current', overdueLeadConsultantOnly });
   //          const outputData = combineDataChart(dashboardInputCurrent, chartHistoryData.dataHistory);

   //          if (mounted.current) {
   //             setDataRefOutstanding(outputData);
   //          };

   //       } catch (err) {
   //          console.log(err);
   //       };
   //    };
   //    fetchAllFormData();

   //    return () => {
   //       setDataRefOutstanding(null);
   //    };
   // }, []);




   useEffect(() => {

      const fetchAllFormData = async () => {
         try {

            let dashboardInput = {};

            let [res, resRowHistory, resRfa] = await Promise.all([
               apiFetchDataThisProject({ token, projectId, email, isNoNeedSortRows: true }),
               apiFetchRowHistoryThisProject({ token, projectId }),
               apiFetchDataMultiForm('rfa', { token, projectId, email })
            ]);

            const { rowsAll, rowsRfaAllInit, rfaData } = getInputDataInitially({
               sheetData: res.data,
               rowsHistoryData: resRowHistory.data,
               rfaData: resRfa.data
            }, 'page-rfa');

            const { publicSettings: { overdueLeadConsultantOnly } } = res.data;

            const refStatistics = getOverdueRfaInfo({ rowsAllFinalMultiForm: rowsRfaAllInit, rfaData, overdueLeadConsultantOnly });

            let objDwgStatus = { 'Overall': {} };

            statusOrderArray.forEach(stt => {
               const dwgThisStatus = rowsAll.filter(row => getDrawingStatusByConsultant(row, 'page-spreadsheet', rfaData) === stt);
               objDwgStatus['Overall'][stt] = dwgThisStatus.length;
               tradeArrayForm.forEach(trade => {
                  objDwgStatus[trade] = objDwgStatus[trade] || {};
                  const dwgThisTradeAndStatus = dwgThisStatus.filter(row => {
                     return convertTradeCodeInverted(row.rfaNumber.split('/')[2]) === trade;
                  });
                  objDwgStatus[trade][stt] = dwgThisTradeAndStatus.length;
               });
            });



            let objDwgSubmissionAndReplyByDay = { 'Overall': {} };
            let objDwgSubmissionAndReplyByMonth = { 'Overall': {} };


            const allRfaSubmitted = [...new Set(rowsRfaAllInit.filter(r => r['RFA Ref']).map(r => r['RFA Ref']))];

            allRfaSubmitted.forEach(rfa => {
               const row = rowsRfaAllInit.find(r => r['RFA Ref'] === rfa);
               if (row) {
                  const trade = convertTradeCodeInverted(row.rfaNumber.split('/')[2]);

                  objDwgSubmissionAndReplyByDay[trade] = objDwgSubmissionAndReplyByDay[trade] || {};
                  objDwgSubmissionAndReplyByMonth[trade] = objDwgSubmissionAndReplyByMonth[trade] || {};

                  const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rfa);
                  if (rfaFound) {
                     const dateSubmission = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'date');
                     const consultantLead = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];

                     if (dateSubmission) {
                        const dateString = moment(dateSubmission).format('DD/MM/YY');
                        objDwgSubmissionAndReplyByDay['Overall'][dateString] = objDwgSubmissionAndReplyByDay['Overall'][dateString] || {};
                        objDwgSubmissionAndReplyByDay['Overall'][dateString]['Submission'] = (objDwgSubmissionAndReplyByDay['Overall'][dateString]['Submission'] || 0) + 1;

                        objDwgSubmissionAndReplyByDay[trade][dateString] = objDwgSubmissionAndReplyByDay[trade][dateString] || {};
                        objDwgSubmissionAndReplyByDay[trade][dateString]['Submission'] = (objDwgSubmissionAndReplyByDay[trade][dateString]['Submission'] || 0) + 1;


                        const monthString = moment(dateSubmission).format('MM/YY');
                        objDwgSubmissionAndReplyByMonth['Overall'][monthString] = objDwgSubmissionAndReplyByMonth['Overall'][monthString] || {};
                        objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Submission'] = (objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Submission'] || 0) + 1;

                        objDwgSubmissionAndReplyByMonth[trade][monthString] = objDwgSubmissionAndReplyByMonth[trade][monthString] || {};
                        objDwgSubmissionAndReplyByMonth[trade][monthString]['Submission'] = (objDwgSubmissionAndReplyByMonth[trade][monthString]['Submission'] || 0) + 1;
                     };

                     if (consultantLead) {
                        const dateReply = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'date', consultantLead);
                        if (dateReply) {
                           const dateString = moment(dateReply).format('DD/MM/YY');
                           objDwgSubmissionAndReplyByDay['Overall'][dateString] = objDwgSubmissionAndReplyByDay['Overall'][dateString] || {};
                           objDwgSubmissionAndReplyByDay['Overall'][dateString]['Reply'] = (objDwgSubmissionAndReplyByDay['Overall'][dateString]['Reply'] || 0) + 1;


                           objDwgSubmissionAndReplyByDay[trade][dateString] = objDwgSubmissionAndReplyByDay[trade][dateString] || {};
                           objDwgSubmissionAndReplyByDay[trade][dateString]['Reply'] = (objDwgSubmissionAndReplyByDay[trade][dateString]['Reply'] || 0) + 1;


                           const monthString = moment(dateSubmission).format('MM/YY');
                           objDwgSubmissionAndReplyByMonth['Overall'][monthString] = objDwgSubmissionAndReplyByMonth['Overall'][monthString] || {};
                           objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Reply'] = (objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Reply'] || 0) + 1;


                           objDwgSubmissionAndReplyByMonth[trade][monthString] = objDwgSubmissionAndReplyByMonth[trade][monthString] || {};
                           objDwgSubmissionAndReplyByMonth[trade][monthString]['Reply'] = (objDwgSubmissionAndReplyByMonth[trade][monthString]['Reply'] || 0) + 1;
                        };
                     };
                  };
               };
            });



            dashboardInput['rfa'] = {
               refStatistics,
               drawingStatus: objDwgStatus,
               objDwgSubmissionAndReplyByDay,
               objDwgSubmissionAndReplyByMonth
            };




            const fetchDataMultiForm = async () => {
               const formTypes = ['rfam', 'rfi', 'cvi', 'dt', 'mm'];
               try {
                  await Promise.all(formTypes.map(async (refType, i) => {

                     const resForm = await apiFetchDataMultiForm(refType, { token, projectId, email });

                     const rowsFormAllData = resForm.data;

                     let rowsFormAll = [];
                     const listRef = [... new Set(rowsFormAllData.map(x => x[`${refType}Ref`]))];
                     listRef.forEach(ref => {
                        const rowsThisRef = rowsFormAllData.filter(r => r[`${refType}Ref`] === ref);
                        const arrayVersion = [...new Set(rowsThisRef.map(x => x.revision))];
                        const latestVersion = arrayVersion.sort()[arrayVersion.length - 1];
                        const rowFound = rowsThisRef.find(x => x.revision === latestVersion);
                        rowsFormAll.push(rowFound);
                     });

                     const refStatistics = getOverdueMultiFormInfo({ rowsAllFinalMultiForm: rowsFormAll, pageSheetTypeName: `page-${refType}`, overdueLeadConsultantOnly });

                     let objDwgStatus = { 'Overall': {} };


                     (refType === 'rfam' ? statusOrderArray : ['replied']).forEach(stt => {

                        const dwgThisStatus = rowsFormAll.filter(row => {
                           return getDrawingStatusByConsultant(row, `page-${refType}`, null) === stt;
                        });

                        objDwgStatus['Overall'][stt] = dwgThisStatus.length;

                        (refType === 'mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm).forEach(trade => {

                           objDwgStatus[trade] = objDwgStatus[trade] || {};
                           const dwgThisTradeAndStatus = dwgThisStatus.filter(row => {
                              return convertTradeCodeInverted(row[`${refType}Ref`].split('/')[2]) === trade;
                           });
                           objDwgStatus[trade][stt] = dwgThisTradeAndStatus.length;
                        });
                     });


                     let objTransmittedForDt = { 'Overall': {} };
                     if (refType === 'dt') {
                        rowsFormAll.forEach(dwg => {
                           const trade = convertTradeCodeInverted(dwg[`${refType}Ref`].split('/')[2]);

                           objTransmittedForDt[trade] = objTransmittedForDt[trade] || {};

                           const transmitted = getInfoValueFromRefDataForm(dwg, 'submission', 'dt', 'transmittedForDt');

                           if (transmitted) {
                              objTransmittedForDt['Overall'][transmitted] = (objTransmittedForDt['Overall'][transmitted] || 0) + 1;
                              objTransmittedForDt[trade][transmitted] = (objTransmittedForDt[trade][transmitted] || 0) + 1;
                           };
                        });
                     };

                     const obj = refType === 'dt' ? { objTransmittedForDt } : {};


                     dashboardInput[refType] = {
                        refStatistics,
                        drawingStatus: objDwgStatus,
                        ...obj
                     };

                  }));
               } catch (err) {
                  console.log(err);
               };
            };
            await fetchDataMultiForm();

            if (mounted.current) {
               setDataRefOutstanding(dashboardInput);
            };

         } catch (err) {
            console.log(err);
         };
      };
      fetchAllFormData();


      return () => {
         setDataRefOutstanding(null);
      };
   }, []);



   return (
      <div style={{
         position: 'relative'
      }}>
         <div style={{
            width: currentWindow.width,
            height: navBarDmsHeight,
            padding: 7, paddingRight: 0, paddingLeft: 40,
            background: colorType.grey4,
            display: 'flex', justifyContent: 'space-between'
         }}>
            <div style={{ fontSize: 25, color: colorType.primary, marginTop: -5, paddingTop: 0 }}>Summary Data</div>
            <div style={{ position: 'absolute', display: 'flex', right: 15 }}>
               <div style={{ fontSize: 25, color: colorType.primary, marginTop: -5, paddingTop: 0 }}>{projectName}</div>
            </div>
         </div>

         <div style={{
            marginLeft: sideBarWidth,
            height: currentWindow.height - offsetHeight,
            overflowY: 'scroll',
            width: currentWindow.width - sideBarWidth,
         }}>


            <Row>
               <Col span={24}>
                  <ChartCard type={'rfa'}>
                     {dataRefOutstanding ? <PanelChart type='rfa' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
            </Row>
            <Row>
               <Col span={17}>
                  <ChartCard type={'rfam'}>
                     {dataRefOutstanding ? <PanelChart type='rfam' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
               <Col span={7}>
                  <ChartCard type={'rfi'}>
                     {dataRefOutstanding ? <PanelChart type='rfi' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
            </Row>

            <Row>
               <Col span={6}>
                  <ChartCard type={'cvi'}>
                     {dataRefOutstanding ? <PanelChart type='cvi' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
               <Col span={12}>
                  <ChartCard type={'dt'}>
                     {dataRefOutstanding ? <PanelChart type='dt' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
               <Col span={6}>
                  <ChartCard type={'mm'}>
                     {dataRefOutstanding ? <PanelChart type='mm' input={dataRefOutstanding} currentWindow={currentWindow} /> : <LoadingPanel />}
                  </ChartCard>
               </Col>
            </Row>


         </div>
      </div>
   );
};

export default PageChart;



const getPanelName = (type) => {
   return type === 'rfa' ? 'Request For Approval'
      : type === 'rfam' ? 'Request For Approval Of Material'
         : type === 'rfi' ? 'Request For Information'
            : type === 'cvi' ? 'Confirmation Of Verbal Instruction'
               : type === 'dt' ? 'Document Transmittal'
                  : 'Meeting Minutes'
};

const LoadingPanel = () => {
   return (
      <div style={{ width: '100%', height: 435, textAlign: 'center', paddingTop: 180 }}>
         <Icon type='loading' style={{ fontSize: 45 }} />
      </div>
   );
};




const PanelChart = ({ input, type, currentWindow }) => {

   const data = input[type];

   const [timeUnit, setTimeUnit] = useState('Month');

   const [dataTimeline, setDataTimeline] = useState(data['objDwgSubmissionAndReplyByMonth'] || []);


   const [tradeType, setTradeType] = useState('Overall');


   return (
      <div>
         <div style={{ display: 'flex', marginBottom: 20 }}>
            {(type === 'rfa' || type === 'rfam' || type === 'rfi') && ['Overall', ...tradeArrayForm].map(trade => (
               <div
                  key={trade}
                  style={{
                     marginRight: 10, cursor: 'pointer',
                     userSelect: 'none',
                     background: tradeType === trade ? colorType.primary : 'transparent',
                     display: 'flex',
                     padding: 5, paddingRight: 10, paddingLeft: 10,
                     color: tradeType === trade ? 'white' : 'black',
                     fontWeight: 'bold',
                     border: tradeType === trade ? 'none' : `1px solid ${colorType.grey4}`
                  }}
                  onClick={() => setTradeType(trade)}
               >
                  {trade}
               </div>
            ))}
         </div>
         <div style={{ display: 'flex' }}>

            {type === 'rfa' && (
               <TabsStyled type='card' style={{ marginLeft: 20 }}>
                  <TabPane tab={'Submission & Reply'} key={1}>

                     <div style={{ display: 'flex', marginBottom: 10 }}>
                        <ButtonChart btnText={'Day'} timeUnit={timeUnit} onClick={() => {
                           setTimeUnit('Day');
                           setDataTimeline(data['objDwgSubmissionAndReplyByDay'] || []);
                        }} />
                        <ButtonChart btnText={'Month'} timeUnit={timeUnit} onClick={() => {
                           setTimeUnit('Month');
                           setDataTimeline(data['objDwgSubmissionAndReplyByMonth'] || []);
                        }} />

                        <div style={{ marginLeft: 200 }}>Submission</div>
                        <div style={{ height: 14, width: 14, background: '#8884d8', marginLeft: 7, marginTop: 5 }}></div>
                        <div style={{ marginLeft: 35 }}>Reply</div>
                        <div style={{ height: 14, width: 14, background: '#82ca9d', marginLeft: 7, marginTop: 5 }}></div>
                     </div>

                     {dataTimeline[tradeType] ? (
                        <AreaChart
                           style={{ transform: 'translateX(-30px)' }}
                           width={currentWindow.width * 0.34}
                           height={300}
                           data={Object.keys(dataTimeline[tradeType]).sort((a, b) => {
                              a = a.split('/').reverse().join('');
                              b = b.split('/').reverse().join('');
                              return a > b ? 1 : a < b ? -1 : 0;
                           }).map((item, i) => {
                              return {
                                 name: item,
                                 Submission: dataTimeline[tradeType][item]['Submission'] || 0,
                                 Reply: dataTimeline[tradeType][item]['Reply'] || 0,
                                 drag: i + 1
                              };
                           })}
                           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                           <CartesianGrid strokeDasharray='3 3' />
                           <XAxis dataKey='name' />
                           <YAxis />
                           <Tooltip />
                           <Area type='monotone' dataKey='Submission' stroke='#8884d8' fill='#8884d8' fillOpacity={0.3} />
                           <Area type={cardinal} dataKey='Reply' stroke='#82ca9d' fill='#82ca9d' fillOpacity={0.3} />
                           <Brush dataKey='drag' height={30} stroke='#8884d8' />
                        </AreaChart>
                     ) : (
                        <div style={{
                           width: currentWindow.width * 0.35, height: 300,
                           textAlign: 'center',
                           fontSize: 25, verticalAlign: 'middle',
                           paddingTop: 100
                        }}>No Data</div>
                     )}




                  </TabPane>
               </TabsStyled>
            )}

            {((type === 'rfa' || type === 'rfam' || type === 'dt')
               ? ['chart-1', 'chart-2']
               : ['chart-1']).map((chart, i) => {
                  return (
                     <ChartComp
                        key={i}
                        chartType={chart}
                        data={data}
                        type={type}
                        currentWindow={currentWindow}
                        tradeType={tradeType}
                     />
                  );
               })}
         </div>
      </div>
   );
};



const ChartComp = ({ chartType, data: dataInput, type, currentWindow, tradeType }) => {

   let data;

   if (type === 'rfa') {
      if (chartType === 'chart-1') {
         data = dataInput['refStatistics'];
      } else {
         data = dataInput['drawingStatus'];
      };
   } else if (type === 'rfam') {
      if (chartType === 'chart-1') {
         const dataOverdue = dataInput['refStatistics'];

         data = {};
         ['Overall', ...tradeArrayForm].forEach(trade => {
            data[trade] = {};
            data[trade]['Overdue'] = dataOverdue[trade]['Overdue'];
            data[trade]['Due in the next 1 - 3 days'] = dataOverdue[trade]['Due in the next 1 - 3 days'];
            data[trade]['Due in the next 4 - 14 days'] = dataOverdue[trade]['Due in the next 4 - 14 days'];
            data[trade]['Replied'] = dataOverdue[trade]['Replied'];
         });
      } else {
         const dataStatus = dataInput['drawingStatus'];
         data = {};
         ['Overall', ...tradeArrayForm].forEach(trade => {
            data[trade] = {};
            data[trade]['Approved with Comment, no submission Required'] = dataStatus[trade]['Approved with Comment, no submission Required'];
            data[trade]['Approved for Construction'] = dataStatus[trade]['Approved for Construction'];
            data[trade]['Approved with comments, to Resubmit'] = dataStatus[trade]['Approved with comments, to Resubmit'];
            data[trade]['Reject and resubmit'] = dataStatus[trade]['Reject and resubmit'];
         });
      };


   } else if (type === 'rfi') {
      const dataOverdue = dataInput['refStatistics'];
      const dataStatus = dataInput['drawingStatus'];
      data = {};
      ['Overall', ...tradeArrayForm].forEach(trade => {
         data[trade] = {};
         data[trade]['Overdue'] = dataOverdue[trade]['Overdue'];
         data[trade]['Due in the next 1 - 3 days'] = dataOverdue[trade]['Due in the next 1 - 3 days'];
         data[trade]['Due in the next 4 - 14 days'] = dataOverdue[trade]['Due in the next 4 - 14 days'];

         data[trade]['Replied'] = dataStatus[trade]['replied'];

      });
   } else if (type === 'dt') {
      if (chartType === 'chart-1') {
         data = dataInput['refStatistics'];
      } else {
         data = dataInput['objTransmittedForDt'];
      };
   } else {
      data = dataInput['refStatistics'];
   };


   return (
      <TabsStyled type='card' style={{ marginLeft: 30 }}>
         {(chartType === 'chart-1'
            ? ((type === 'cvi' || type == 'dt' || type === 'mm') ? ['Submission'] : ['Ref Status'])
            : (type == 'dt' ? ['Transmitted'] : type === 'rfam' ? ['Ref Replied'] : ['Drawing Status'])).map(tabName => {


               let isThereNoData = true;
               Object.keys(data[tradeType]).forEach(item => {
                  if (data[tradeType][item] > 0) {
                     isThereNoData = false
                  };
               });



               let tabNameText = '';
               if ((type === 'rfa' || type === 'rfam' || type === 'rfi') && tabName === 'Ref Status') {

                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `${type.toUpperCase()} Status (${sum})`;

               } else if (type === 'rfa' && tabName === 'Drawing Status') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `Drawing Status (${sum})`;

               } else if (type === 'rfam' && tabName === 'Ref Replied') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `RFAM Replied (${sum})`;

               } else if (tabName === 'Submission') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `${type.toUpperCase()} Submission (${sum})`;
               } else if (tabName === 'Transmitted') {
                  tabNameText = `${type.toUpperCase()} Transmitted`;
               };


               const arrayLegendAndPortion = (type === 'rfa' && tabName === 'Ref Status') ? statusOutstandingArray
                  : (type === 'rfam' && tabName === 'Ref Status') ? statusOutstandingArray
                     : (type === 'rfam' && tabName === 'Ref Replied') ? statusOrderArray.filter(x => x !== 'Consultant reviewing')
                        : (type === 'rfi' && tabName === 'Ref Status') ? statusOutstandingArray
                           : ((type === 'cvi' || type === 'dt') && tabName === 'Submission') ? tradeArrayForm
                              : (type === 'mm' && tabName === 'Submission') ? meetingFolderArray
                                 : (type === 'dt' && tabName === 'Transmitted') ? transmittedForArray
                                    : tabName === 'Drawing Status' ? ((type === 'rfi' || type === 'cvi' || type === 'dt') ? ['replied'] : statusOrderArray)
                                       : [];

               const chartWidth = (type === 'rfa' || type === 'rfam' || type === 'rfi') ? currentWindow.width * 0.25 : currentWindow.width * 0.2


               let outputTradeBreakdown = {};
               if (
                  (tradeType === 'Overall' && (type === 'rfa' || type === 'rfam' || type === 'rfi')) ||
                  (type === 'dt' && tabName === 'Transmitted')
               ) {
                  if (type === 'dt') {
                     arrayLegendAndPortion.forEach(item => {
                        outputTradeBreakdown[item] = {};
                        tradeArrayForm.forEach(trade => {
                           if (data[trade]) {
                              outputTradeBreakdown[item][trade] = data[trade][item];
                           };
                        });
                     });
                  } else {
                     arrayLegendAndPortion.forEach(item => {
                        outputTradeBreakdown[item] = {};
                        tradeArrayForm.forEach(trade => {
                           outputTradeBreakdown[item][trade] = data[trade][item];
                        });
                     });
                  };
               };


               return (
                  <TabPane
                     tab={tabNameText}
                     key={tabName}
                  >

                     {isThereNoData ? (
                        <div style={{
                           width: chartWidth, height: 250,
                           textAlign: 'center',
                           fontSize: 25, verticalAlign: 'middle',
                           paddingTop: 100
                        }}>{`No ${type.toUpperCase()} Status`}</div>
                     ) : (
                        <PieChart width={chartWidth} height={250} style={{
                           // margin: '0 auto' 
                        }}>
                           <Pie
                              // https://github.com/recharts/recharts/issues/490

                              data={getInputDataForChart(data, tradeType, tabName, type)}
                              cx={'50%'} cy={'50%'}
                              dataKey='value'
                              outerRadius={90}

                              labelLine={(props) => {

                                 const { cx, cy, midAngle, innerRadius, outerRadius, value, stroke, startAngle, endAngle } = props;
                                 const RADIAN = Math.PI / 180;
                                 let radius1 = 20 + innerRadius + (outerRadius - innerRadius);
                                 let radius2 = innerRadius + (outerRadius - innerRadius);
                                 let x2 = cx + radius1 * Math.cos(-midAngle * RADIAN);
                                 let y2 = cy + radius1 * Math.sin(-midAngle * RADIAN);
                                 let x1 = cx + radius2 * Math.cos(-midAngle * RADIAN);
                                 let y1 = cy + radius2 * Math.sin(-midAngle * RADIAN);
                                 if (value <= 0) return null;
                                 return (
                                    <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} strokeWidth={1}></line>
                                 );
                              }}

                              label={(props) => {
                                 const { outerRadius, innerRadius, midAngle, cy, cx, value, name } = props;
                                 const RADIAN = Math.PI / 180;
                                 let radius = 20 + innerRadius + (outerRadius - innerRadius);
                                 let x = cx + radius * Math.cos(-midAngle * RADIAN);
                                 let y = cy + radius * Math.sin(-midAngle * RADIAN);
                                 if (value <= 0) return null;



                                 return (
                                    <g>
                                       <text
                                          x={x} y={y}
                                          fill='#000' fontWeight='300'
                                          fontSize='13px'
                                          textAnchor={x > cx ? 'start' : 'end'}
                                          dominantBaseline='central'
                                       >
                                          <tspan fontWeight='bold'>{value}</tspan>
                                       </text>
                                    </g>
                                 );
                              }}
                           >
                              {arrayLegendAndPortion.map(stt => {
                                 return (
                                    <Cell
                                       key={`cell-${stt}`}
                                       fill={stt === 'Consultant reviewing' ? colorType.grey4 : colorTextRow[stt]}
                                    />
                                 );
                              })}
                           </Pie>
                        </PieChart>
                     )}



                     <div style={{ textAlign: 'center' }}>
                        <>
                           {arrayLegendAndPortion.map(item => (
                              <div key={item} style={{
                                 display: 'flex',
                                 width: '100%',
                                 textOverflow: 'ellipsis',
                                 overflow: 'hidden',
                                 whiteSpace: 'nowrap',
                              }}>
                                 <StyledBadge
                                    key={item}
                                    size='small'
                                    color={item === 'Consultant reviewing' ? colorType.grey4 : colorTextRow[item]}
                                    text={item}
                                 />
                                 <div style={{
                                    marginLeft: 5,
                                    fontWeight: 'bold',
                                    color: 'black'
                                 }}>{breakdownByTrade(outputTradeBreakdown[item] || {})}</div>
                              </div>
                           ))}
                        </>
                     </div>

                  </TabPane>
               );
            })}
      </TabsStyled>
   );
};



const getTotalCountInObj = (obj) => {
   let result = 0;
   for (const key in obj) {
      result += (obj[key] || 0);
   };
   return result;
};




const getInputDataForChart = (data, tradeType, tabName, type) => {


   let output = [];

   if (tabName === 'Ref Status') {
      statusOutstandingArray.forEach(item => {
         output.push({
            name: item,
            value: data[tradeType][item]
         });
      });

   } else if (tabName === 'Ref Replied') {
      statusOrderArray.filter(x => x !== 'Consultant reviewing').forEach(status => {
         output.push({
            name: status,
            value: data[tradeType][status]
         });
      });

   } else if (tabName === 'Drawing Status') {
      (type === 'rfa' ? statusOrderArray : []).forEach(status => {
         output.push({
            name: status,
            value: data[tradeType][status]
         });
      });
   } else if (tabName === 'Submission') {
      (type === 'mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm).forEach(trade => {
         if (trade !== 'Overall') {
            output.push({
               name: trade,
               value: getTotalCountInObj(data[trade])
            });
         };
      });
   } else if (tabName === 'Transmitted') {
      transmittedForArray.forEach(method => {
         output.push({
            name: method,
            value: data[tradeType][method]
         });
      });
   };

   return output;
};



const TabsStyled = styled(Tabs)`
   .ant-tabs-top-bar {
      margin-bottom: 5px;
   }
`;

const ChartCard = ({ children, type }) => {

   return (
      <Card
         title={getPanelName(type) || 'No title'}

         style={{
            margin: 10,
            marginBottom: 10,
            boxShadow: '5px 15px 24px 5px #d2dae2',
            border: 'none',
            paddingBottom: 10,
            borderRadius: 20, overflow: 'hidden',
         }}

         bodyStyle={{
            margin: 'auto', padding: 0, height:
               type === 'rfa' ? 500
                  : (type === 'rfam' || type === 'rfi') ? 520
                     : 420
         }}

         headStyle={{
            backgroundColor: colorType.primary,
            color: 'white',
            lineHeight: '15px'
         }}
      >
         <div style={{ margin: '10px 15px' }}>
            {children}
         </div>
      </Card>
   );
};




const StyledBadge = styled(Badge)`
   .ant-badge-status-dot {
      width: 15px;
      height: 15px;
      border-radius: 0;
   }
`;






const ButtonChart = ({ btnText, timeUnit, onClick }) => {

   return (
      <div
         style={{
            cursor: 'pointer',
            userSelect: 'none',
            background: timeUnit === btnText ? colorType.primary : 'transparent',
            border: timeUnit === btnText ? 'none' : `1px solid ${colorType.grey4}`,
            display: 'flex',
            padding: 2, paddingRight: 7, paddingLeft: 7,
            color: timeUnit === btnText ? 'white' : 'black'
         }}
         onClick={onClick}
      >
         {btnText}
      </div>
   );
};

const breakdownByTrade = (objOutput) => {
   let str = '';
   let total = 0;
   tradeArrayForm.forEach(trade => {
      if (objOutput[trade] > 0) {
         total += objOutput[trade];
         str += `${objOutput[trade]} ${trade} + `;
      };
   });
   if (str[str.length - 2] === '+') {
      str = str.slice(0, str.length - 3);
   };
   str = `${total} - (${str})`;
   return total === 0 ? '' : str;
};





const fetchDataFromServer = async ({ projectId, email, token, isDataForChart, overdueLeadConsultantOnly }) => {

   try {
      let dashboardInput = {};

      let [res, resRowHistory, resRfa] = await Promise.all([
         apiFetchDataThisProject({ token, projectId, email, isNoNeedSortRows: true }),
         apiFetchRowHistoryThisProject({ token, projectId, isDataForChart }),
         apiFetchDataMultiForm('rfa', { token, projectId, email, isDataForChart })
      ]);

      const { objDwgStatus, objDwgSubmissionAndReplyByDay, objDwgSubmissionAndReplyByMonth, refStatistics } = formatDataChartForRfa({
         sheetData: res.data,
         rowsHistoryData: resRowHistory.data,
         rfaData: resRfa.data
      });

      dashboardInput['rfa'] = {
         refStatistics,
         drawingStatus: objDwgStatus,
         objDwgSubmissionAndReplyByDay,
         objDwgSubmissionAndReplyByMonth
      };

      await Promise.all(['rfam', 'rfi', 'cvi', 'dt', 'mm'].map(async (refType, i) => {
         const resForm = await apiFetchDataMultiForm(refType, { token, projectId, email, isDataForChart });
         const { objDwgStatus, refStatistics, obj } = formatDataChartForMultiForm({ inputData: resForm.data, refType, overdueLeadConsultantOnly });

         dashboardInput[refType] = {
            refStatistics,
            drawingStatus: objDwgStatus,
            ...obj
         };
      }));

      return dashboardInput;

   } catch (err) {
      console.log(err);
   };
};









const formatDataChartForRfa = ({ sheetData, rowsHistoryData, rfaData }) => {

   const { refStatistics, rowsAll, rowsRfaAllInit } = getInputDataInitially({ sheetData, rowsHistoryData, rfaData }, 'page-rfa');

   let objDwgStatus = { 'Overall': {} };
   let objDwgSubmissionAndReplyByDay = { 'Overall': {} };
   let objDwgSubmissionAndReplyByMonth = { 'Overall': {} };

   statusOrderArray.forEach(stt => {
      const dwgThisStatus = rowsAll.filter(row => getDrawingStatusByConsultant(row, 'page-spreadsheet', rfaData) === stt);
      objDwgStatus['Overall'][stt] = dwgThisStatus.length;
      tradeArrayForm.forEach(trade => {
         objDwgStatus[trade] = objDwgStatus[trade] || {};
         const dwgThisTradeAndStatus = dwgThisStatus.filter(row => {
            return convertTradeCodeInverted(row.rfaNumber.split('/')[2]) === trade;
         });
         objDwgStatus[trade][stt] = dwgThisTradeAndStatus.length;
      });
   });

   const allRfaSubmitted = [...new Set(rowsRfaAllInit.filter(r => r['RFA Ref']).map(r => r['RFA Ref']))];

   allRfaSubmitted.forEach(rfa => {
      const row = rowsRfaAllInit.find(r => r['RFA Ref'] === rfa);
      if (row) {
         const trade = convertTradeCodeInverted(row.rfaNumber.split('/')[2]);

         objDwgSubmissionAndReplyByDay[trade] = objDwgSubmissionAndReplyByDay[trade] || {};
         objDwgSubmissionAndReplyByMonth[trade] = objDwgSubmissionAndReplyByMonth[trade] || {};

         const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rfa);
         if (rfaFound) {
            const dateSubmission = getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'date');
            const consultantLead = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];

            if (dateSubmission) {
               const dateString = moment(dateSubmission).format('DD/MM/YY');
               objDwgSubmissionAndReplyByDay['Overall'][dateString] = objDwgSubmissionAndReplyByDay['Overall'][dateString] || {};
               objDwgSubmissionAndReplyByDay['Overall'][dateString]['Submission'] = (objDwgSubmissionAndReplyByDay['Overall'][dateString]['Submission'] || 0) + 1;

               objDwgSubmissionAndReplyByDay[trade][dateString] = objDwgSubmissionAndReplyByDay[trade][dateString] || {};
               objDwgSubmissionAndReplyByDay[trade][dateString]['Submission'] = (objDwgSubmissionAndReplyByDay[trade][dateString]['Submission'] || 0) + 1;


               const monthString = moment(dateSubmission).format('MM/YY');
               objDwgSubmissionAndReplyByMonth['Overall'][monthString] = objDwgSubmissionAndReplyByMonth['Overall'][monthString] || {};
               objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Submission'] = (objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Submission'] || 0) + 1;

               objDwgSubmissionAndReplyByMonth[trade][monthString] = objDwgSubmissionAndReplyByMonth[trade][monthString] || {};
               objDwgSubmissionAndReplyByMonth[trade][monthString]['Submission'] = (objDwgSubmissionAndReplyByMonth[trade][monthString]['Submission'] || 0) + 1;
            };

            if (consultantLead) {
               const dateReply = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'date', consultantLead);
               if (dateReply) {
                  const dateString = moment(dateReply).format('DD/MM/YY');
                  objDwgSubmissionAndReplyByDay['Overall'][dateString] = objDwgSubmissionAndReplyByDay['Overall'][dateString] || {};
                  objDwgSubmissionAndReplyByDay['Overall'][dateString]['Reply'] = (objDwgSubmissionAndReplyByDay['Overall'][dateString]['Reply'] || 0) + 1;


                  objDwgSubmissionAndReplyByDay[trade][dateString] = objDwgSubmissionAndReplyByDay[trade][dateString] || {};
                  objDwgSubmissionAndReplyByDay[trade][dateString]['Reply'] = (objDwgSubmissionAndReplyByDay[trade][dateString]['Reply'] || 0) + 1;


                  const monthString = moment(dateSubmission).format('MM/YY');
                  objDwgSubmissionAndReplyByMonth['Overall'][monthString] = objDwgSubmissionAndReplyByMonth['Overall'][monthString] || {};
                  objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Reply'] = (objDwgSubmissionAndReplyByMonth['Overall'][monthString]['Reply'] || 0) + 1;


                  objDwgSubmissionAndReplyByMonth[trade][monthString] = objDwgSubmissionAndReplyByMonth[trade][monthString] || {};
                  objDwgSubmissionAndReplyByMonth[trade][monthString]['Reply'] = (objDwgSubmissionAndReplyByMonth[trade][monthString]['Reply'] || 0) + 1;
               };
            };
         };
      };
   });

   return {
      objDwgStatus, objDwgSubmissionAndReplyByDay, objDwgSubmissionAndReplyByMonth, refStatistics
   };
};




const formatDataChartForMultiForm = ({ inputData: rowsFormAll, refType, overdueLeadConsultantOnly }) => {


   const refStatistics = getOverdueMultiFormInfo({ rowsAllFinalMultiForm: rowsFormAll, pageSheetTypeName: `page-${refType}`, overdueLeadConsultantOnly });

   let objDwgStatus = { 'Overall': {} };

   (refType === 'rfam' ? statusOrderArray : ['replied']).forEach(stt => {
      const dwgThisStatus = rowsFormAll.filter(row => getDrawingStatusByConsultant(row, `page-${refType}`, null) === stt);

      objDwgStatus['Overall'][stt] = dwgThisStatus.length;

      (refType === 'mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm).forEach(trade => {
         objDwgStatus[trade] = objDwgStatus[trade] || {};
         const dwgThisTradeAndStatus = dwgThisStatus.filter(row => {
            return convertTradeCodeInverted(row[`${refType}Ref`].split('/')[2]) === trade;
         });
         objDwgStatus[trade][stt] = dwgThisTradeAndStatus.length;
      });
   });


   let objTransmittedForDt = { 'Overall': {} };
   if (refType === 'dt') {
      rowsFormAll.forEach(dwg => {
         const trade = convertTradeCodeInverted(dwg[`${refType}Ref`].split('/')[2]);

         objTransmittedForDt[trade] = objTransmittedForDt[trade] || {};

         const transmitted = getInfoValueFromRefDataForm(dwg, 'submission', 'dt', 'transmittedForDt');

         if (transmitted) {
            objTransmittedForDt['Overall'][transmitted] = (objTransmittedForDt['Overall'][transmitted] || 0) + 1;
            objTransmittedForDt[trade][transmitted] = (objTransmittedForDt[trade][transmitted] || 0) + 1;
         };
      });
   };

   const obj = refType === 'dt' ? { objTransmittedForDt } : {}

   return { objDwgStatus, refStatistics, obj }
};





const combineDataChart = (dataTestCurrent, dataTestHistory) => {

   const arrayForms = ['dt', 'cvi', 'rfi', 'rfam', 'rfa'];
   const disciplineArray = ['Overall', 'ARCHI', 'C&S', 'M&E', 'PRECAST'];
   const disciplineMeetingArray = ['Overall', 'PROJECT PROGRESS MEETING', 'TECHNICAL MEETING', 'ICE MEETING'];
   const statusOverdueArray = ['Overdue', 'Due in the next 1 - 3 days', 'Due in the next 4 - 14 days', 'Replied'];
   const statusRepliedArray = [
      'Approved for Construction',
      'Approved with Comment, no submission Required',
      'Approved with comments, to Resubmit',
      'Reject and resubmit',
      'Consultant reviewing'
   ];

   let objOutput = {};

   const objTransmittedForDtCurrentOverall = Object.keys(((dataTestCurrent['dt'] || {})['objTransmittedForDt'] || {})['Overall'] || {});
   const objTransmittedForDtHistoryOverall = Object.keys(((dataTestHistory['dt'] || {})['objTransmittedForDt'] || {})['Overall'] || {});

   const objTransmittedForDtItems = [...new Set([
      ...objTransmittedForDtCurrentOverall,
      ...objTransmittedForDtHistoryOverall
   ])];



   ['dt', 'cvi', 'rfi', 'rfam', 'rfa', 'mm'].forEach(refType => {
      objOutput[refType] = {};
      ['refStatistics', 'drawingStatus'].forEach(item => {

         objOutput[refType][item] = {};

         if (item === 'drawingStatus') {
            let arrayDis = refType === 'mm' ? disciplineMeetingArray : disciplineArray;
            arrayDis.forEach(dis => {
               objOutput[refType][item][dis] = {};
               if (refType === 'mm' || refType === 'cvi' || refType === 'rfi' || refType === 'mm' || refType === 'dt') {
                  objOutput[refType][item][dis]['replied'] = 0;
               } else {
                  statusRepliedArray.forEach(stt => {
                     objOutput[refType][item][dis][stt] = 0;
                  });
               }
            });
         } else {
            disciplineArray.forEach(dis => {
               objOutput[refType][item][dis] = {}
               statusOverdueArray.forEach(stt => {
                  objOutput[refType][item][dis][stt] = 0;
               });
            });
         };
      });

      if (refType === 'dt') {
         objOutput[refType]['objTransmittedForDt'] = {};
         disciplineArray.forEach(dis => {
            objOutput[refType]['objTransmittedForDt'][dis] = {};
         });
      };
      if (refType === 'rfa') {
         ['objDwgSubmissionAndReplyByDay', 'objDwgSubmissionAndReplyByMonth'].forEach(item => {
            objOutput[refType][item] = {};
            disciplineArray.forEach(dis => {
               objOutput[refType][item][dis] = {};
            });
         });

      };
   });


   arrayForms.forEach(refType => {
      disciplineArray.forEach(discpl => {
         statusOverdueArray.forEach(stt => {
            objOutput[refType]['refStatistics'][discpl][stt] = ((((dataTestCurrent[refType] || {})['refStatistics'] || {})[discpl] || {})[stt] || 0) + ((((dataTestHistory[refType] || {})['refStatistics'] || {})[discpl] || {})[stt] || 0);
         });

         if (refType === 'dt' || refType === 'rfi' || refType === 'cvi') {
            objOutput[refType]['drawingStatus'][discpl]['replied'] = ((((dataTestCurrent[refType] || {})['drawingStatus'] || {})[discpl] || {})['replied'] || 0) + ((((dataTestHistory[refType] || {})['drawingStatus'] || {})[discpl] || {})['replied'] || 0);

         } else if (refType === 'rfam' || refType === 'rfa') {
            statusRepliedArray.forEach(stt => {
               objOutput[refType]['drawingStatus'][discpl][stt] = ((((dataTestCurrent[refType] || {})['drawingStatus'] || {})[discpl] || {})[stt] || 0) + ((((dataTestHistory[refType] || {})['drawingStatus'] || {})[discpl] || {})[stt] || 0)
            });
         };

         if (refType === 'dt') {
            objTransmittedForDtItems.forEach(item => {
               objOutput['dt']['objTransmittedForDt'][discpl][item] = ((((dataTestCurrent['dt'] || {})['objTransmittedForDt'] || {})[discpl] || {})[item] || 0) + ((((dataTestHistory['dt'] || {})['objTransmittedForDt'] || {})[discpl] || {})[item] || 0);
            });
         };

         if (refType === 'rfa') {
            objOutput['rfa']['objDwgSubmissionAndReplyByDay'][discpl] = {
               ...(((dataTestCurrent['rfa'] || {})['objDwgSubmissionAndReplyByDay'] || {})[discpl] || {}),
               ...(((dataTestHistory['rfa'] || {})['objDwgSubmissionAndReplyByDay'] || {})[discpl] || {})
            };
            objOutput['rfa']['objDwgSubmissionAndReplyByMonth'][discpl] = {
               ...(((dataTestCurrent['rfa'] || {})['objDwgSubmissionAndReplyByMonth'] || {})[discpl] || {}),
               ...(((dataTestHistory['rfa'] || {})['objDwgSubmissionAndReplyByMonth'] || {})[discpl] || {})
            };
         }
      });
   });

   disciplineMeetingArray.forEach(discpl => {
      objOutput['mm']['drawingStatus'][discpl]['replied'] = ((((dataTestCurrent['mm'] || {})['drawingStatus'] || {})[discpl] || {})['replied'] || 0) + ((((dataTestHistory['mm'] || {})['drawingStatus'] || {})[discpl] || {})['replied'] || 0);
   });

   return objOutput;
};






