import React from 'react';
import { Image, Segment } from 'semantic-ui-react'
import { message, Icon, Upload, Row, Col, Checkbox } from 'antd';
import { Modal, Loader, Button, RadioGroup, Radio } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import {
  TextField
} from '@material-ui/core';
import { getPublicUrl } from '../../function/AdminFunction'

class DialogSettingProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sharePointId: props.sharePointId,
      issueKey: props.issueKey,
      trimbleKey: props.trimbleKey,
      imgProject: '',
      typeProject: props.typeProject ? props.typeProject : 'None',
      useRfa: props.useRfa,
      projectDisplayName: props.projectDisplayName !== '' ? props.projectDisplayName : props.projectName
    }
  }

  componentDidMount = () => {
    if (this.props.imgProject !== '') {
      getPublicUrl(this.props.imgProject)
        .then(res => {
          this.setState({ imgProject: res })
        })
        .catch(err => {
          this.setState({ imgProject: '' })
        })
    }
  }
  componentWillReceiveProps = (nextProps, oldProps) => {
    if (!nextProps.loadingImg) {
      if (nextProps.loadingImg !== oldProps.loadingImg) {
        getPublicUrl(this.props.imgProject)
          .then(res => {
            this.setState({ imgProject: res })
          })
          .catch(err => {
            this.setState({ imgProject: '' })
          })
      }
    }
  }
  handleClosePanel = () => {
    this.props.onClose()
  }
  handleSaveSetting = () => {
    this.setState({ loading: true })
    if (this.state.projectDisplayName !== '') {
      axios.post(`/api/projects/savesettings`, {
        projectId: this.props.projectId,
        sharePointIdCurrent: this.state.sharePointId,
        issueKey: this.state.issueKey,
        typeProject: this.state.typeProject,
        trimbleKey: this.state.trimbleKey,
        useRfa: this.state.useRfa,
        projectDisplayName: this.state.projectDisplayName,
      })
        .then(res => {
          this.setState({ loading: false })
          message.success(`Save settings successful`)
          window.location.reload()
          this.props.onClose()

        })
        .catch(err => {
          this.setState({ loading: false })
          message.error(`Save settings failed`)
          window.location.reload()
          this.props.onClose()
        })
    }
  }
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  changeSharePointId = (e) => {
    this.setState({ sharePointId: e.target.value })
  }
  changeIssueKey = (e) => {
    this.setState({ issueKey: e.target.value })
  }
  changeTrimbleKey = (e) => {
    this.setState({ trimbleKey: e.target.value })
  }
  handlePPVC_PreCast = (value) => {
    this.setState({ typeProject: value })
  }
  handleDMSRfa = (e) => {
    this.setState({ useRfa: e.target.checked })
  }
  changeDisplayName = (e) => {
    this.setState({ projectDisplayName: e.target.value })
  }
  render() {
    const parameterUpload = {
      name: "avatar",
      listType: "picture-card",
      showUploadList: false,
      customRequest: ({ onSuccess, onError, file }) => {
        if (file !== null) {
          this.props.handleUploadImg({ file: file, projectId: this.props.projectId })
        }
      },
    }
    return (
      <div>
        <Modal show={this.props.onShow} onHide={this.handleClosePanel.bind(this)} size='sm' >
          <Modal.Header>
            <Modal.Title>Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {(this.state.loading || this.props.loadingImg) && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <Segment color='blue'>
              <Row gutter={8}>
                <Col span={6} >
                  <Upload {...parameterUpload} beforeUpload={this.beforeUpload} accept='.png,.jpg' disabled={this.props.loadingImg}>
                    {this.state.imgProject !== '' ? <Image src={this.state.imgProject} style={{ backgroundSize: "contain" }} />
                      : <div>
                        <Icon type={this.props.loadingImg ? 'loading' : 'plus'} />
                        <div className="ant-upload-text">Upload</div>
                      </div>}
                  </Upload>
                </Col>
                <Col span={18} >
                  <TextField style={{ width: '100%' }} label="User Created" value={this.props.userCreatedProject}
                    defaultValue="User Created" margin="dense" variant="outlined" disabled />
                  <TextField style={{ width: '100%' }} label="Display Name"
                    value={this.state.projectDisplayName}
                    margin="dense" variant="outlined" onChange={this.changeDisplayName.bind(this)} disabled={!this.props.isAdmin} />
                  <TextField style={{ width: '100%' }} label="PPVC Key" value={this.state.sharePointId}
                    margin="dense" variant="outlined" onChange={this.changeSharePointId.bind(this)} disabled={!this.props.isAdmin} />
                  <TextField style={{ width: '100%' }} label="Issue Key" value={this.state.issueKey}
                    margin="dense" variant="outlined" onChange={this.changeIssueKey.bind(this)} disabled={!this.props.isAdmin} />
                  <TextField style={{ width: '100%' }} label="Trimble Key" value={this.state.trimbleKey}
                    margin="dense" variant="outlined" onChange={this.changeTrimbleKey.bind(this)} disabled={!this.props.isAdmin} />
                  <RadioGroup onChange={this.handlePPVC_PreCast} value={this.state.typeProject} inline>
                    <Radio value="PPVC/PBU">PPVC/PBU</Radio>
                    <Radio value="PreCast">PreCast</Radio>
                    <Radio value="None">None</Radio>
                  </RadioGroup>
                  <br />
                  <Checkbox onChange={this.handleDMSRfa} checked={this.state.useRfa} >Use RFA</Checkbox>
                </Col>

              </Row>
            </Segment>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClosePanel} color="ghost" >
              Cancel
          </Button>
            <Button onClick={this.handleSaveSetting} color="primary" disabled={(this.state.loading || this.props.loadingImg)} >
              Save
          </Button>
          </Modal.Footer>
        </Modal>



      </div>

    );
  }
}


export default DialogSettingProject