import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Row, Button, Col, Tag, DatePicker } from 'antd';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import _ from 'lodash';
import { getAllShiftsByAllocations } from '../../api/workerallocation';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
const { Column } = Table;

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

function ShiftDataTable(props) {
  const [exportData, setExportDatas] = useState([]);
  const [rows, setRows] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);
  const [time, setTime] = useState([moment(), moment()]);

  useEffect(() => {
    getDatas();
  }, [props.date, time, projectId]);

  function onChange(date, dateString) {
    setTime(date);
  }

  function getMinMaxTime(list, isMin = true) {
    if (_.isArray(list)) {
      let ordered = _.orderBy(
        list.map((x) => ({
          h: x,
          hours: moment(x, 'HH:mm:ss').hours(),
        })),
        'hours'
      );

      if (isMin) {
        return ordered[0].h;
      }

      return ordered[ordered.length - 1].h;
    }
  }

  async function getDatas() {
    let res = await getAllShiftsByAllocations({
      projectId,
      start: time[0].format('DD-MM-YYYY'),
      end: time[1].format('DD-MM-YYYY'),
    });

    await setGroupData([]);

    if (_.isArray(res.data) && res.data.length > 0) {
      res.data = res.data.filter(
        (x) => x.isSubmit == true && x.isDayOff != true
      );
      setRows(res.data);
      let dic = _.groupBy(res.data, 'idWorker');
      let group = Object.keys(dic).map((key, index) => ({
        name: dic[key][0].name,
        supervisor: dic[key][0].supervisor,
        key: uuidv4(),
        isGroup: true,
        nt: _.sumBy(dic[key], 'nt').toFixed(2),
        ot: _.sumBy(dic[key], 'ot').toFixed(2),
        bp: _.sumBy(dic[key], 'bp').toFixed(2),
        icp: _.sumBy(dic[key], 'icp').toFixed(2),
        start: getMinMaxTime(dic[key].map((x) => x.start)),
        end: getMinMaxTime(dic[key].map((x) => x.end, false)),
        children: _.orderBy(dic[key], 'date').map((x) => ({
          ...x,
          isGroup: false,
          key: uuidv4(),
        })),
      }));

      group = _.orderBy(group, 'key');

      await setGroupData(group);
    } else {
      await setGroupData([]);
    }

    setRows(res.data);
  }

  function renderRound2Number(h) {
    return h && <p>{Math.round(h * 100) / 100}</p>;
  }
  function renderGroupKey(e, key) {
    return e.isGroup && <label>{e.name}</label>;
  }

  function round2Number(number) {
    try {
      return parseFloat(number.toFixed(2));
    } catch (error) {
      return 0;
    }
  }

  async function exportExcel() {
    const workbook = new ExcelJS.Workbook();
    let excelName = 'Worker shifts';
    const worksheet = workbook.addWorksheet('Daily record');

    worksheet.columns = [
      { header: 'Worker', key: 'name' },
      { header: 'Supervisor', key: 'supervisor' },
      { header: 'Level', key: 'level' },
      { header: 'Zone', key: 'zone' },
      { header: 'Date', key: 'date' },
      { header: 'Start', key: 'start' },
      { header: 'End', key: 'end' },
      { header: 'Cost Code', key: 'costCode' },
      { header: 'Activity', key: 'description' },
      { header: 'NT \n(hours)', key: 'nt' },
      { header: 'OT \n(hours-no rate)', key: 'ot' },
      { header: 'ICP \n(points)', key: 'icp' },
      { header: 'BP \n(points)', key: 'bp' },
      { header: 'Remark', key: 'remark' },
    ];

    let dataExcel = [];
    groupData.forEach((g) => {
      let mainRow = {
        name: g.name,
        supervisor: g.supervisor,
        nt: round2Number(g.nt),
        ot: round2Number(g.ot),
        bp: round2Number(g.bp),
        icp: round2Number(g.icp),
        start: g.start,
        remark: g.remark,
        end: g.end,
        costCode: g.costCode,
        isChildren: false,
      };
      dataExcel.push(mainRow);

      g.children.forEach((c) => {
        let cRow = {
          supervisor: c.supervisor,
          nt: round2Number(c.nt),
          ot: round2Number(c.ot),
          bp: round2Number(c.bp),
          icp: round2Number(c.icp),
          start: c.start,
          end: c.end,
          costCode: c.costCode,
          description: c.description,
          zone: c.zone,
          level: c.level,
          date: c.date,
          isChildren: true,
          remark: c.remark,
        };

        dataExcel.push(cRow);
      });
    });

    worksheet.properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false,
    };

    dataExcel.forEach((r) => {
      const newRows = worksheet.addRow(r);

      if (r.isChildren) {
        newRows.outlineLevel = 1;
      }
    });

    let borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    for (let i = 1; i <= dataExcel.length + 1; i++) {
      for (let j = 1; j < 15; j++) {
        worksheet.getCell(i, j).border = borderStyles;
      }
    }

    worksheet.columns.forEach(function (column, i) {
      if (i !== 0) {
        var maxLength = 0;
        column['eachCell']({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      }
    });

    worksheet.columns[0].width = 20;
    worksheet.columns[1].width = 20;
    worksheet.columns[2].width = 20;
    worksheet.columns[3].width = 20;
    worksheet.columns[8].width = 50;
    worksheet.columns[13].width = 50;
    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + '.xlsx');
  }

  let columns = [
    {
      title: 'Worker',
      key: 'name',
      render: (e) => renderGroupKey(e, 'name'),
    },
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      key: 'supervisor',
      width: 120,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: 100,
    },
    {
      title: 'Zone',
      dataIndex: 'zone',
      key: 'zone',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      width: 100,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      width: 100,
    },
    {
      title: 'Cost Code',
      dataIndex: 'costCode',
      key: 'costCode',
      width: 100,
    },
    {
      title: 'Activity',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'NT \n(hours)',
      dataIndex: 'nt',
      key: 'nt',
      render: renderRound2Number,
      width: 80,
    },
    {
      title: 'OT \n(hours-no rate)',
      dataIndex: 'ot',
      key: 'ot',
      render: renderRound2Number,
      width: 120,
    },
    {
      title: 'ICP \n(points)',
      dataIndex: 'icpPoints',
      key: 'icpPoints',
      render: renderRound2Number,
      width: 100,
    },
    // {
    //   title: 'BP \n(points)',
    //   dataIndex: 'bpPoints',
    //   key: 'bpPoints',
    //   render: renderRound2Number,
    //   width: 80,
    // },
  ];

  function getSum(type = 'nt') {
    return Math.round(_.sumBy(rows, type) * 10) / 10;
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center  mb-4 mb-2 '>
        <h4 className='mb-4 mt-4'>Daily Record</h4>
        <div className=' d-flex justify-content-start align-items-center '>
          {/* <Button onClick={getDatas} type='primary' className='mr-2'>
            Search :
          </Button> */}

          <Button onClick={exportExcel} type='primary' className='mr-2'>
            Export Excel
          </Button>

          <span className=' text-center mr-1'>Search : </span>
          <RangePicker value={time} format={'DD-MM-YYYY'} onChange={onChange} />
        </div>
      </div>

      <Table
        className='antTable'
        dataSource={groupData}
        bordered
        rowKey={(record) => record.key}
        columns={columns}
        scroll={{ x: 1700, y: 500 }}
        pagination={false}
      ></Table>

      <div className='d-flex flex-row mt-2 justify-content-end'>
        <Tag color='#0088FE'>NT : {getSum('nt')}</Tag>
        <Tag color='#00C49F'>OT : {getSum('ot')} </Tag>
        {/*<Tag color='#FFBB28'>BP : {getSum('bp')} </Tag>*/}
        <Tag color='#FF8042'>ICP : {getSum('icp')} </Tag>
      </div>
    </div>
  );
}

export default ShiftDataTable;
