import React from 'react';
import stc from 'string-to-color'
import { Tooltip, Avatar, Typography } from 'antd';
import { Icon } from 'rsuite';
import { sliceString } from '../function/DialogFunction'
import _ from 'lodash'
import moment from 'moment';
import { MenuProvider, animation } from 'react-contexify';

const { Text } = Typography
const handleViewState = (data) => {
  // if (data.viewport )
  //   window.NOP_VIEWER.restoreState(data.viewport)
}

export default  () => {
  return ([
    {
      Header: '', accessor: 'priority', id: 'priority', units: false, aggregate: 'count', filter: "includes",  widthLocal: 40,
      Cell: ({ cell: { row, value } }) => {
        return (
          (!_.isNumber(value)) &&
          <>
            <MenuProvider id="comment_menu" animation={animation.zoom} data={{ item: row.original }}>
              <Icon icon='exclamation-triangle' style={{
                color: value === 'Very urgent' ? 'red' :
                  value === 'Urgent' ? 'orange' : value === 'Normal' ? '#f4d90b' : 'blue'
              }} />
              {/* <Text strong >{' '}{value?sliceString(value, 6):''}</Text> */}
            </MenuProvider>

          </>
        )
      },
    },
    {
      Header: 'Description', accessor: 'descriptionViewPoint', id: 'descriptionViewPoint', units: false, aggregate: 'count', filter: "includes",
      Cell: ({ cell: { row, value } }) => {
        return (
          !row.isGrouped &&
          <Tooltip title={(
            <Text style={{ whiteSpace: 'pre-wrap', color: 'white' }}> {value}</Text>
          )} >
            <Text onDoubleClick={handleViewState.bind(this, row.original)} strong>{sliceString(value)}</Text>
          </Tooltip>
        )
      }
    },
    { Header: 'Status', accessor: 'status', id: 'status', units: false, filter: "includes", widthLocal: 40},
    {
      Header: 'By', accessor: 'userCreated', id: 'userCreated', units: false, aggregate: 'count', filter: "includes",
      minWidthLocal: 25, maxWidthLocal: 45, widthLocal:45,
      Cell: ({ cell: { value } }) => {
        let shortCut = ''
        let split = value.split('_')
        if (split.length > 1) {
          shortCut = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase()
        } else {
          shortCut = value.charAt(0).toUpperCase()
        }
        return (
          !_.isNumber(value) &&
          <Tooltip title={value} >
            <Avatar style={{ verticalAlign: 'middle', fontSize: 10, backgroundColor: stc(value) }} size="small">
              {shortCut}
            </Avatar>
          </Tooltip>
        )
      }
    },
    {
      Header: 'To', accessor: 'listAssignViewPoint', id: 'listAssignViewPoint', units: false, aggregate: 'count', filter: "includes",
      minWidthLocal: 40, maxWidthLocal: 200, whiteSpace:'break-spaces',
      Cell: ({ cell: { value } }) => {
        let temp = []
        _.forEach(value, v1 => {
          let index = _.findIndex(temp, o => { return o.name === v1 })
          if (index < 0) {
            let split = v1.split('_')
            if (split.length > 1) {
              temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1 })
            } else {
              temp.push({ shortCut: v1.charAt(0).toUpperCase(), name: v1 })
            }
          }

        })
        return (<div>
          {temp.map(v =>
            <Tooltip title={v.name}>
              <Avatar style={{ verticalAlign: 'middle', fontSize: 12, backgroundColor: stc(v.name) }} size="small">
                {v.shortCut}
              </Avatar>
            </Tooltip>

          )}
        </div>)
      }
    },
 

  
    {
      Header: 'Due date', accessor: 'dateViewPoint', id: 'dateViewPoint', units: false, filter: "between",widthLocal: 100,
      Cell: ({ cell: {row,value } }) => {
        let time = moment(value, 'DD-MM-YYYY')
        let check = time < moment(Date.now())
        return (
          <Tooltip title={(
            <Text style={{ whiteSpace: 'pre-wrap', color: 'white' }}> {moment(value).format('DD-MM-YYYY HH:mm')}</Text>
          )} >
            <Text strong style={{ color: row.original.status ==='Close'?'green':check ? 'red' : 'green' }}>{moment(value).format('DD-MM-YYYY')}</Text>
          </Tooltip>

        )
      }
    },
  ])
}