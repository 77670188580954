import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index },
  total
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * total).toFixed(1)}$`}
    </text>
  );
};

export default function TimePieChartCost(props) {
  function getTotal() {
    if (_.isArray(props.dataPieChart) && props.dataPieChart.length > 0) {
      return _.sumBy(props.dataPieChart, 'value');
    }

    return 0.0;
  }

  return (
    <PieChart width={300} height={300}>
      <Pie
        data={props.dataPieChart}
        cx={150}
        cy={150}
        labelLine={true}
        // label={(e) => renderCustomizedLabel(e, getTotal())}
        label
        outerRadius={80}
        fill='#8884d8'
        dataKey='value'
      >
        {props.dataPieChart?.map((e, index) => (
          <Cell key={`cell-${index}`} fill={e.color} />
        ))}
      </Pie>

      <Tooltip content={(e) => CustomTooltip(e, getTotal())} />
      <Legend />
    </PieChart>
  );
}
const CustomTooltip = ({ active, payload, label }, total) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].name} : ${(
          (payload[0].value * 100) /
          total
        ).toFixed(1)}%`}</p>
      </div>
    );
  }

  return null;
};
