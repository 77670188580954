/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { connect } from 'react-redux';
import _ from 'lodash'
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Label, Segment } from 'semantic-ui-react';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { message, Row, Col, List, Card, Typography, Avatar, Empty, Tooltip, Popover, Collapse } from 'antd';
import { Modal, Divider, Input, InputPicker, Loader, Checkbox, DatePicker, Button } from 'rsuite';
import * as rsuite from 'rsuite';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';

import moment from 'moment'



const styles = theme => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 100,

  },
});
const { Text } = Typography
const { Panel } = Collapse;
const resultRenderer = ({ email }) => <Label content={email} />

resultRenderer.propTypes = {
  email: PropTypes.string,
}




class ButtonMenuFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderName: '',
      open: false,
      hidden: false,
      openDialogAdd: false,
      openSettingNotificationPanel: false,
      openSettingUserPanel: false,
      loading: false
    }
  }
  //#region //!setting button
  handleClick = () => { this.setState({ open: (open => !open) }) };
  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({ open: true })
    }
  };
  handleClose = () => { this.setState({ open: false }) };
  //#endregion

  //#region //!setting dialog add folder
  handleCloseDialogAdd = () => { this.setState({ openDialogAdd: false }) };
  handleClickOpenDialogAdd = () => { this.setState({ openDialogAdd: true }) }
  _onChange = (e) => { this.setState({ folderName: e.target.value }) }
  createFolder = () => {
    this.setState({ loading: true })
    axios.post(`/api/folders/add-folder`, {
      folder: { folderName: this.state.folderName },
      project: this.props.project,
      email: this.props.email,
      permission: this.props.permission,
      role: this.props.role
    })
      .then(res => {
        this.props.handleCallbackAddFolder(res.data)
        this.setState({ openDialogAdd: false, loading: false })
      })
      .catch(err => (
        message.error(err.response.data.errors.folderId),
        this.setState({ openDialogAdd: false, loading: false })
      ))
  };
  //#endregion





  //#region notification
  handleClickOpenDialogSettingNotification = () => {
    this.setState({ openSettingNotificationPanel: true })
  }
  handleCloseDialogSettingNotification = () => {
    this.setState({ openSettingNotificationPanel: false })
  }
  //#endregion



  render() {
    const { classes } = this.props;
    const actions = [
      { icon: <AddIcon />, name: 'Add Folder', click: this.handleClickOpenDialogAdd },
      // { icon: <NotificationImportantOutlinedIcon />, name: 'Setting Notification', click: this.handleClickOpenDialogSettingNotification },
    ];



    return (
      <>
        <SpeedDial
          ButtonProps={{ color: "inherit" }}
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          hidden={this.state.hidden}
          icon={<MenuIcon />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onFocus={this.handleOpen}
          open={this.state.open}>

          {actions.map(action =>
            <SpeedDialAction
              key={action.name} icon={action.icon} tooltipTitle={action.name} tooltipOpen onClick={action.click}
            />
          )}
        </SpeedDial>

        {/* <Dialog fullWidth={true} maxWidth={'xs'} fullHeight={true} maxHeight={'xl'}
          open={this.state.openDialogAdd} onClose={this.handleCloseDialogAdd} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create Folder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Input your folder name
                  </DialogContentText>
         
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialogAdd} color="primary"> Cancel </Button>
            <Button onClick={this.createFolder} color="primary"> OK</Button>
          </DialogActions>
        </Dialog> */}

        <Modal show={this.state.openDialogAdd} onHide={this.handleCloseDialogAdd} size='xs' style={{ top: 60 }}>
          <Modal.Header>
            <Modal.Title>Create Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loading && <Loader center backdrop content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
            <TextField onChange={this._onChange} autoFocus margin="dense" id="name" label="Folder Name" type="text" ref='inputText' fullWidth />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseDialogAdd} color="ghost"> Cancel</Button>
            <Button onClick={this.createFolder} color="primary" loading={this.state.loading} disabled={this.state.folderName === ''}>OK</Button>
          </Modal.Footer>
        </Modal>

        {this.state.openSettingNotificationPanel &&
          <NotificationSettingPanel
            open={this.state.openSettingNotificationPanel}
            handleClose={this.handleCloseDialogSettingNotification}
            projectId={this.props.project.projectId}
          />
        }
   
      
 
      </>
    );
  }
}

ButtonMenuFolder.propTypes = {
  // createFolder: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(null, null)(ButtonMenuFolder));



function NotificationSettingPanel(props) {

  const [commentData, setCommentData] = useState([
    {
      name: 'Added',
      instant: false,
      digest: false
    },
    {
      name: 'Updated',
      instant: false,
      digest: false
    },
    {
      name: 'Deleted',
      instant: false,
      digest: false
    },
  ])
  const [issueData, setIssueData] = useState([
    {
      name: 'Added',
      instant: false,
      digest: false
    },
    {
      name: 'Updated',
      instant: false,
      digest: false
    },
    {
      name: 'Deleted',
      instant: false,
      digest: false
    },
    {
      name: 'Attach File',
      instant: false,
      digest: false
    },
    {
      name: 'Remove Attach File',
      instant: false,
      digest: false
    },
  ])
  const [time] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const [dailyTime, setDailyTime] = useState()
  useEffect(() => {
    setLoading(true)
    axios.get("/api/projects/get-setting-notification", { params: { projectId: props.projectId } })
      .then(res => {
        if (Object.keys(res.data.notificationSetting).length !== 0) {
          if (res.data.notificationSetting.issue) {
            let temp = issueData.slice()
            _.forEach(temp, v => {
              let index = _.findIndex(res.data.notificationSetting.issue, e => { return e.name === v.name })
              if (index >= 0) {
                v.instant = res.data.notificationSetting.issue[index].instant
                v.digest = res.data.notificationSetting.issue[index].digest
              }
            })
            setIssueData(temp)
          }
          if (res.data.notificationSetting.issue) {
            let temp = commentData.slice()
            _.forEach(temp, v => {
              let index = _.findIndex(res.data.notificationSetting.comment, e => { return e.name === v.name })
              if (index >= 0) {
                v.instant = res.data.notificationSetting.comment[index].instant
                v.digest = res.data.notificationSetting.comment[index].digest
              }
            })
            setCommentData(temp)
          }
          if (res.data.reportDailyTime !== '') {
            let split = res.data.reportDailyTime.split(" ")
            let time = moment(`${split[1]}:${split[0]}`, 'HH:mm')
            setDailyTime(moment(time, 'HH:mm'))
          } else {
            let time = moment('18:30', 'HH:mm')
            setDailyTime(time)
          }
        }
        setLoading(false)
      })
      .catch(() => {
        props.handleClose(null)
        message.warning(`Can't get setting`)
        setLoading(false)
      })
  }, [])


  const handleClose = () => {
    props.handleClose(null)
  }
  const handleChange = (name, key, index) => {
    if (name === 'Issue') {
      let temp = issueData.slice()
      temp[index][key] = !temp[index][key]
      setIssueData(temp)
    } else if (name === 'Comment') {
      let temp = commentData.slice()
      temp[index][key] = !temp[index][key]
      setCommentData(temp)
    }
  }
  const handleSaveSetting = () => {
    const temp = {
      comment: commentData,
      issue: issueData
    }
    let time = moment(dailyTime).format('HH:mm')
    let split = time.split(':')
    let schedule = `${split[1]} ${split[0]} * * *`
    axios.post(`/api/projects/save-setting-notification`, { projectId: props.projectId, data: temp, dailyTime: schedule })
      .then(() => {
        props.handleClose(null)
      })
      .catch(() => {
        message.error(`Can't save setting`)
      })
  }
  const handleChangeDailyReport = (date) => {
    setDailyTime(date)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='sm' backdrop='static' className='dialog-share-project'>
      <Modal.Header>
        <Modal.Title>Setting Notification</Modal.Title>
      </Modal.Header>
      <Divider className='custom-divider' />
      <Modal.Body >
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <div style={{ marginBottom: 5 }}>
          <Row align="middle" gutter={8}>
            <Col span={18} >
              <Text strong style={{ paddingLeft: 10 }}> Activity Type</Text>
            </Col>
            <Col span={3}  >
              <Text strong > Instant</Text>
            </Col>
            <Col span={3}  >
              <Text strong > Digest</Text>
            </Col>
          </Row>
        </div>

        <Collapse   >
          <Panel header="Comment" key="1" className='custom-setting-notification-panel'>
            {commentData.map((v, k) =>
              <Row align="middle" gutter={8}>
                <Col span={18} >
                  <Text strong style={{ paddingTop: 7, position: 'absolute' }}> {v.name}</Text>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Instant' placement='top'>
                    <Checkbox checked={v.instant} onChange={handleChange.bind(this, 'Comment', 'instant', k)} />
                  </Tooltip>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Digest' placement='top'>
                    <Checkbox checked={v.digest} onChange={handleChange.bind(this, 'Comment', 'digest', k)} />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Panel>
          <Panel header="Issue" key="2" className='custom-setting-notification-panel'>
            {issueData.map((v, k) =>
              <Row align="middle" gutter={8}>
                <Col span={18} >
                  <Text strong style={{ paddingTop: 7, position: 'absolute' }}> {v.name}</Text>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Instant' placement='top'>
                    <Checkbox checked={v.instant} onChange={handleChange.bind(this, 'Issue', 'instant', k)} />
                  </Tooltip>
                </Col>
                <Col span={3}  >
                  <Tooltip title='	Digest' placement='top'>
                    <Checkbox checked={v.digest} onChange={handleChange.bind(this, 'Issue', 'digest', k)} />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
        <div style={{ marginTop: 5 }}>
          <Text strong> Daily digest time{' '}</Text>
          <DatePicker format="HH:mm" cleanable={false} value={time} size="sm" ranges={[]} onChange={handleChangeDailyReport} value={dailyTime} />
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Divider className='custom-divider' />
        <rsuite.Button appearance="primary" loading={loading} onClick={handleSaveSetting}> Save</rsuite.Button>
        <rsuite.Button appearance="default" onClick={handleClose} > Cancel</rsuite.Button>
      </Modal.Footer>
    </Modal>
  )
}
