import React from 'react'
import { isMobile, isMobileOnly } from "react-device-detect";
import socketIOClient from "socket.io-client";
import axios from "axios";
import $ from 'jquery'
import _ from 'lodash'
import hello from 'hellojs';
import decode from 'jwt-decode';
import worker from 'workerize-loader!../../../workers/customModelworker'; // eslint-disable-line import/no-webpack-loader-syntax
import base64url from "base64url";
export const workerInstance = worker()
export const socket = (window.location.origin.includes('https://idd.wohhup.com') || window.location.origin.includes('https://m-idd.wohhup.com')) ?
  socketIOClient(window.location.origin, { transports: ['websocket'] }) : socketIOClient(window.location.origin)

export const bucketS3 = (window.location.origin.includes('https://idd.wohhup.com') || window.location.origin.includes('https://m-idd.wohhup.com')) ?
  'https://bimapp1.s3.ap-southeast-1.amazonaws.com' : 'https://bqltest.s3.ap-southeast-1.amazonaws.com'
export function imageAvatarUrlS3(email) {
  let id = base64url(email)
  return `${bucketS3}/userInfo/${id}/avatar.png`
}

export function getPublicUrl(url, time = 300 * 5, type) {
  return new Promise((resolve, reject) => {
    axios.get("/api/issue/get-public-url", { params: { key: url, expire: time, type } })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        reject('')
      })
  })

}

export const limit = 20

export function sortIssue(a, b) {
  let temp1 = _.toNumber(a.split('-')[1])
  let temp2 = _.toNumber(b.split('-')[1])
  if (temp1 < temp2) {
    return -1;
  }
  if (temp1 > temp2) {
    return 1;
  }
  return 0;
}



export function trackingUser(email, projectName = null, folderName = null, itemName = null, functionName = null) {
  try {
    socket.on('message', function (message) {
      localStorage.setItem('whapp5d-socketid', message)
    });
    $.getJSON('https://ipapi.co/json/', function (data) {
      socket.emit("client-send-username", {
        email: email, isMobile: isMobile, projectName: projectName, folderName: folderName, itemName: itemName, functionName: functionName,
        browser: browserDetection(), country: data.country.toLowerCase(), IP: data.ip, system: getOperatorSystem(),
        location: []
      });
    })
      .fail(function () {
        socket.emit("client-send-username", {
          email: email, isMobile: isMobile, projectName: projectName, folderName: folderName, itemName: itemName, functionName: functionName,
          browser: browserDetection(), country: '', IP: '0.0.0.0', system: getOperatorSystem(),
          location: []
        });
      })

  } catch { }
}

export function trackingUserUsing(email, projectId, platform, functionName = 'None') {
  try {
    let data = {
      email: email, isMobile: isMobile, projectId, functionName, platform,
      browser: browserDetection(), system: getOperatorSystem(),
    }
    axios.post("/api/users/record-usage", { data })
  } catch { }
}

export function browserDetection() {
  var ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i)
  let browser = ''
  if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
    browser = "msie";
  }
  else {
    browser = ua[1].toLowerCase();
  }
  return browser
}
export function getOperatorSystem() {
  let OSName = "question";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "ios";
  // if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "linux";
  return OSName
}
export function storeHistory(email, itemId, itemName) {
  axios.post("/api/versions/store-history", {
    itemId: itemId,
    itemName: itemName,
    email: email
  })
}

export function initMicrosoft() {
  window.hello = hello.init({
    aad: {
      name: 'Azure Active Directory',
      oauth: {
        version: 2,
        auth:
          'https://login.microsoftonline.com/wohhup.com/oauth2/v2.0/authorize',
      },
      form: false,
    },
  });

}
export function handleErrAuthMicrosoft(err, token) {
  // This sample just redirects to the login function when the token is expired.
  // Production apps should implement more robust token management.
  if (
    err.statusCode === 401 &&
    err.message === 'Access token has expired.'
  ) {
    localStorage.removeItem('hello');
    if (token) {
      const payload = decode(token)
      localStorage.removeItem("userWh5Dapp");
      localStorage.removeItem('bookwormJWT')
      socket.emit('leave', payload.email);
    }
  }
};

export function handleLoginMs() {
  window.hello.init(
    {
      aad: (window.location.origin.includes('http://localhost:3000') || window.location.origin.includes('https://test.bql-app.com') || window.location.origin.includes('https://m-test.bql-app.com')) ?
        '6094dea1-c853-4296-92d3-177b8844e1e2' :
        'ad6859d7-4dbe-4854-b00a-c83dcd7d73aa',
    },
    {
      redirect_uri: process.env.PUBLIC_URL,
      scope: 'user.read',
    }
  )
  window.hello.login(
    'aad',
    {
      display: 'popup',
      scope: 'user.read',
    },
    () => {
      window.location.href = window.location.origin
      // window.location.origin.includes('http://localhost:3000') ? 'http://localhost:3000/' :
      //   window.location.origin.includes('https://test.bql-app.com') ? 'https://test.bql-app.com/' : 'https://idd.wohhup.com/';
    }
  );

}

export const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}

export const controlUrl = () => {

  if (window.location.hostname !== 'localhost') {
    if (
      isMobileOnly &&
      window.location.hostname !== 'm-idd.wohhup.com'
    ) {
      window.location.href = `https://m-idd.wohhup.com`
    } else if (
      !isMobileOnly &&
      window.location.hostname !== 'idd.wohhup.com'
    ) {
      window.location.href = `https://idd.wohhup.com/`
    }
  }
}

export const checkWohhupUser = (email) => {
  const mail = [
    '@wohhup.com',
    '@wohhup.co.id',
    '@wohhup.com.vn'
  ]
  let check = false
  _.forEach(mail, v => {
    if (email.includes(v)) {
      check = true
      return false
    }
  })
  return check
}