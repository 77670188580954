import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { search, add, deletes, update } from '../../api/block';
import { search as searchLevel } from '../../api/level';
import { search as searchUnitType } from '../../api/unitType';
import {
  Table,
  Divider,
  Icon,
  Modal,
  notification,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import BlockUnitForm from '../Forms/BlockUnitForm';
import TopActionBar from '../Common/TopActionBar';
import TableUnitType from './TableUnitType';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';

const { Column } = Table;

TabBlockUnit.propTypes = {
  onSubmit: PropTypes.func,
};

TabBlockUnit.defaultProps = {
  onSubmit: null,
};

function TabBlockUnit(props) {
  const [rows, setRows] = useState([]);
  const [levels, setLevels] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [id, setId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [datas, setDatas] = useState(null);
  const projectId = useSelector((state) => state.project.projectId);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 10000,
  });

  useEffect(() => {
    getDatas();
  }, [searchRequest]);

  useEffect(() => {
    getLevels();
    getUnitType();
  }, []);

  const getDatas = async () => {
    let request = { ...searchRequest, projectId };
    let res = await search(request);

    if (_.isNil(res.data?.items)) {
      setDatas([]);
    } else {
      let sort = _.orderBy(res.data.items, [
        'blockName',
        'levelName',
        'unitName',
      ]);
      setDatas(sort);
    }
  };

  async function getLevels() {
    let levelRes = await searchLevel({
      filterBy: '',
      pageIndex: 1,
      pageSize: 10000,
      projectId,
    });

    if (_.isArray(levelRes.data.items)) {
      setLevels(levelRes.data.items.filter((x) => x.type == 1));
    } else {
      setLevels([]);
    }
  }

  async function getUnitType() {
    let levelRes = await searchUnitType({
      filterBy: '',
      pageIndex: 1,
      pageSize: 10000,
      projectId,
    });

    if (_.isArray(levelRes.data.items)) {
      setUnitTypes(levelRes.data.items);
    } else {
      setUnitTypes([]);
    }
  }

  async function onChange(current, pageSize) {
    await setSearchRequest({
      ...searchRequest,
      pageIndex: current,
      pageSize: pageSize,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values };
          if (isAdd) {
            try {
              await add(payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleDeleteItems = async (ids = null) => {
    if (ids) {
      await deletes(ids);
    } else {
      if (rows.length < 1) {
        notification['error']({
          message: 'Error',
          description: 'Please select at least one item.',
        });
      }
      await deletes(rows.map((x) => x.id));
    }
    await getDatas();
  };

  const handleShowDialogEdit = (record) => {
    setId(record.id);
    if (record.type == 1) {
      setParentId(null);
    } else {
      setParentId(record.parentId);
    }
    setRecord(record);
    setIsAdd(false);
    setVisible(true);
  };

  function handleShowDialogAdd(parentId = null) {
    setParentId(parentId);
    setIsAdd(true);
    setVisible(true);
  }

  function getFilters(key) {
    if (_.isArray(datas)) {
      let items = datas
        .filter((x) => x[key])
        .map((x) => ({
          text: x[key],
          value: x[key],
        }));

      let uniq = _.orderBy(_.uniqBy(items, 'value'), 'text');

      return uniq;
    }
  }

  return (
    <div>
      <Row gutter={16}>
        <Col lg={12} xs={12}>
          <div>
            <h2>Table Block Unit</h2>
            <div className='d-flex flex-row  mb-2'>
              <TopActionBar
                className='mr-2'
                handleSearch={handleSearch}
                handleShowDialogAdd={() => handleShowDialogAdd(null)}
                // handleDeleteItems={handleDeleteItems}
              />
              ,
            </div>

            <Table
              loading={_.isNil(datas)}
              className='antTable'
              dataSource={datas}
              bordered
              rowKey={(record) => record.id}
              // rowSelection={rowSelection}
              scroll={{ y: 500 }}
              // pagination={false}
            >
              <Column
                title='Block name'
                dataIndex='blockName'
                key='blockName'
                width='150px'
                filters={getFilters('blockName')}
                onFilter={(value, record) =>
                  record.blockName.indexOf(value) === 0
                }
              />
              <Column
                title='Level'
                dataIndex='levelName'
                key='levelName'
                width='150px'
                filters={getFilters('levelName')}
                onFilter={(value, record) =>
                  record.levelName.indexOf(value) === 0
                }
              />

              <Column
                title='Unit Name'
                dataIndex='unitName'
                key='unitName'
                filters={getFilters('unitName')}
                onFilter={(value, record) =>
                  record.unitName.indexOf(value) === 0
                }
              />
              <Column
                title='Unit Type'
                dataIndex='unitType'
                key='unitType'
                filters={getFilters('unitType')}
                onFilter={(value, record) =>
                  record.unitType && record.unitType.indexOf(value) === 0
                }
              />

              <Column
                title='No of Sample'
                dataIndex='noOfSample'
                key='noOfSample'
              />
              <Column title='Remark' dataIndex='remark' key='remark' />

              <Column
                width='150px'
                title='Action'
                key='action'
                render={(text, record) => (
                  <span>
                    <Icon
                      onClick={() => handleShowDialogAdd(record.id)}
                      style={{ fontSize: '20px', color: '#08c' }}
                      type='plus'
                    />

                    <Divider type='vertical' />
                    <Icon
                      onClick={() => handleShowDialogEdit(record)}
                      style={{ fontSize: '20px', color: '#08c' }}
                      type='edit'
                    />

                    <Divider type='vertical' />

                    <Popconfirm
                      title='Are you sure delete this?'
                      onConfirm={() => handleDeleteItems([record.id])}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Icon
                        style={{ fontSize: '20px', color: '#08c' }}
                        type='delete'
                      />
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
          </div>
        </Col>

        <Col lg={12} xs={12}>
          <div>
            <h2 className='mb-2'>Table Unit Type</h2>
            <TableUnitType projectId={projectId} />
            <Modal
              title='Block Unit'
              visible={visible}
              onCancel={() => handleCancel()}
              footer={null}
            >
              <BlockUnitForm
                levels={levels}
                unitTypes={unitTypes}
                id={id}
                isAdd={isAdd}
                record={record}
                setVisible={setVisible}
                getDatas={getDatas}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TabBlockUnit;
