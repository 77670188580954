import React, { useState, useCallback, useEffect } from "react";

import {
   ComposedChart,
   Bar,
   LabelList,
   Cell,
   Line,
   ResponsiveContainer,
   ReferenceLine,
   XAxis,
   YAxis,
   Tooltip,
   Legend,
   Label,
   CartesianGrid,
} from "recharts";

const Critical_Sum_Project = ({ data }) => {
   const [activeIndex, setActiveIndex] = useState();
   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div style={{ backgroundColor: "rgba(255, 255, 0, 0.35)" }}>
               <p style={{ fontWeight: "bold", color: "blue" }}>{payload[0].payload["name"]}</p>

               <p
                  style={{ color: "blue" }}
               >{`All_No:${payload[0].payload["total_no"]}/${payload[0].payload["total"]}`}</p>
            </div>
         );
      }

      return null;
   };

   return (
      <div>
         <h4>Critical Check</h4>
         <ResponsiveContainer width="100%" minHeight={"25vh"}>
            <ComposedChart
               data={data}
               margin={{
                  top: 15,
                  right: 5,
                  left: 5,
                  bottom: 20,
               }}
            >
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <Tooltip cursor={false} content={<CustomTooltip />} />
               <XAxis
                  dataKey="project"
                  tickLine={false}
                  angle={-90}
                  height={30}
                  dx={-10}
                  dy={15}
                  interval={0}
                  // minTickGap={-200}
               ></XAxis>
               <Label
                  value="Fail items (%)"
                  // offset={0}
                  position="top"
                  style={{
                     fontWeight: 700,
                     fontSize: "16px",
                  }}
               />
               <YAxis type="number" domain={[0, 100]} />

               <Bar dataKey="percent">
                  <LabelList dataKey="percent" position="top" fontSize="11px" fill="black" />
                  {data
                     ? data.map((entry, index) => (
                          <Cell
                             fill={data[index].percent < 92 ? "#ff8080" : "#82ca2d"}
                             strokeWidth={0}
                             stroke="Black"
                             cursor="pointer"
                             key={index}
                          />
                       ))
                     : null}
               </Bar>

               <ReferenceLine
                  y={92}
                  label={{
                     position: "top",
                     value: "92%",
                     fontSize: 12,
                     fontWeight: 700,
                  }}
                  stroke="red"
                  strokeWidth={2}
                  strokeDasharray="3 3"
               />
            </ComposedChart>
         </ResponsiveContainer>
      </div>
   );
};

export default Critical_Sum_Project;
