
const Autodesk = window.Autodesk;

export default class CustomProperties extends Autodesk.Viewing.UI.PropertyPanel {

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  constructor (viewer, options) {

    super (viewer.container, options.id, options.title, {
      addFooter: false,
      viewer
    })

    
  }


  initialize () {

    super.initialize()

    this.viewer = this.options.viewer

    this.footer = this.createFooter()

    this.container.appendChild(
        this.footer)
  }

}

