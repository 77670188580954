import React, { useState, useEffect } from "react";
import axios from "axios";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import { func_process_rebar_do_data } from "../../function/func_process_data";

var customParseFormat = require("dayjs/plugin/customParseFormat");
const TableRebarDO = ({
   tableRef,
   data,
   filterMode,
   generalState,
   setData,
   setStatistic,
}) => {
   const [user_role, set_user_role] = useState();
   const [project_id, set_project_id] = useState();

   useEffect(() => {
      const role = localStorage.getItem("fp_user_role");
      set_user_role(role);
      const pid = localStorage.getItem("fp_project_id");
      set_project_id(pid);

      const fetchdata = async () => {
         var res = await axios.get(
            "/api/do_inv/common/get_data_by_date_range",
            {
               params: {
                  currentFilterMode: filterMode,
                  project_id: pid,
                  current: "rebar_do",
               },
            }
         );
         const dt = res.data.data.items;
         const res_f = await func_process_rebar_do_data(dt);
         setData(res_f.processed_data);
         setStatistic(res_f.staticInfo);
      };
      fetchdata();
   }, [filterMode, generalState]);

   const columns = [
      {
         title: "Do No",
         data: "do_number",
         type: "text",
      },
      {
         title: "DO Date",
         data: "do_date",
         type: "date",
         dateFormat: "YYYY-MM-DD",
         datePickerConfig: {
            dateFormat: "YYYY-MM-DD",
         },
      },
      {
         title: "PO No",
         data: "po_number",
         type: "text",
      },
      {
         title: "Pour Name",
         data: "pour_name",
         type: "text",
      },
      {
         title: "Product",
         data: "product",
         type: "text",
      },
      {
         title: "Weight/Qty",
         data: "weight_qty",
         type: "numeric",
      },
      {
         title: "Unit",
         data: "unit",
         type: "text",
      },

      {
         title: "Supplier",
         data: "supplier",
         editor: "select",
         selectOptions: ["NASTEEL", "SUPERBEND", "LEE MESH"],
      },

      {
         title: "Pdf file",
         data: "s3_key_check",
         renderer: "html",
         readOnly: true,
      },
      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      height: 750,
      rowHeights: 24,
      columns: columns,
      colWidths: [100, 100, 100, 200, 125, 120, 90, 125, 75, 175],
      stretchH: "last",
      // multiColumnSorting: true,
      currentRowClassName: "currentRow",
      dropdownMenu: [
         "filter_by_condition",
         "filter_by_value",
         "filter_action_bar",
      ],
      filters: true,
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      const parentElement = TD.parentElement;
      if (parentElement === null) {
         return;
      }
      // Add class to odd TRs
      if (row % 2 === 0) {
         Handsontable.dom.addClass(parentElement, "odd");
      }
   };

   const handleOnCellMouseUp = (evens, coors, TD) => {
      if (
         tableRef.current !== null &&
         coors.col === 8 &&
         TD.innerText !== "" &&
         TD.innerText !== null
      ) {
         const temp_data = tableRef.current.hotInstance.getData();
         const file_name_seach =
            project_id + "_rebar_do_" + temp_data[coors.row][0] + ".pdf";
         axios
            .get("/api/do_inv/s3/getlink", {
               params: { file_name: file_name_seach.toLowerCase() },
            })
            .then((res) => {
               const signedrequesturl = res.data.signedRequest;
               window.open(signedrequesturl);
            });
      }
   };

   const onBeforeHotChange = (changes) => {
      //dispatch(fp_dataUpdate(changes));

      changes.forEach(([row, column, oldValue, newValue]) => {
         data[row].change = true;
         data[row][column] = newValue;
      });
      setData(data);
   };

   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={data}
            // afterRenderer={addClassesToRows}
            afterOnCellMouseUp={handleOnCellMouseUp}
            beforeChange={onBeforeHotChange}
            className="do-invoice-table handsontable_do"
         />
      </div>
   );
};

export default TableRebarDO;
