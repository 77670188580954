import { Icon, message, Tooltip } from 'antd';
import Axios from 'axios';
import React, { useState } from 'react';


const ButtonAdminUploadData = ({ stateProject }) => {


   const token = stateProject.allDataOneSheet && stateProject.allDataOneSheet.token;

   const [file, setFile] = useState('');

   const handleChange = e => {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = e => {
         setFile(JSON.parse(e.target.result));
         // setFile(getDataSumangAndHandy(JSON.parse(e.target.result)));
      };
   };


   const uploadCurrentDataToServer = async () => {

      try {

         await Promise.all([
            Axios.post(`/api/row/history/save-all-data-row-history`, { token, dataToSave: file.rowHistories }),
            // Axios.post(`/api/cell/history/save-all-data-cell-history`, { token, dataToSave: file.cellHistories }),
            Axios.post(`/api/sheet/save-all-data-settings`, { token, dataToSave: file.settings }),
            Axios.post(`/api/sheet/save-all-data-rows`, { token, dataToSave: file.rows }),

            Axios.post(`/api/row-multi-form/save-all-data-to-server/rfa`, { token, dataToSave: file.rowsRfa }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/rfam`, { token, dataToSave: file.rowsRfam }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/rfi`, { token, dataToSave: file.rowsRfi }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/cvi`, { token, dataToSave: file.rowsCvi }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/dt`, { token, dataToSave: file.rowsDt }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/mm`, { token, dataToSave: file.rowsMm }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/qr`, { token, dataToSave: file.rowsQr }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/qrm`, { token, dataToSave: file.rowsQrm }),
            Axios.post(`/api/row-multi-form/save-all-data-to-server/rfc`, { token, dataToSave: file.rowsRfc }),
         ]);

         message.info('DONE...');
      } catch (err) {
         console.log(err);
      };
   };


   return (
      <>
         <Tooltip title='Upload Data All'>
            {file ? (
               <Icon type='upload' onClick={uploadCurrentDataToServer} style={{ marginRight: 10 }} />
            ) : (
               <label style={{
                  border: '1px solid black',
                  display: 'inline-block',
                  width: 20,
                  height: 20,
                  padding: 3,
                  margin: 3,
                  cursor: 'pointer'
               }}>
                  <input
                     style={{ height: 25, fontSize: 8, marginRight: 6, display: 'none' }}
                     type='file'
                     onChange={handleChange}
                  />
                  <div style={{ transform: 'translateX(-2px) translateY(-5px)' }}>(1)</div>
               </label>
            )}
         </Tooltip>

      </>
   );
};

export default ButtonAdminUploadData;


