import React from 'react'

export default function CellButton(params) {
    if (params){
        if (params.value !== null && params.value !== undefined && params.value !== '') {
            return `<button class="truncated" onclick="(function(){    
                window.open(${ params.value })
                return false;
            })();return false;" type='button'> Open : ${params.data.castingReportNumber}</button>`
        }
        // 
    }
    return '';
}