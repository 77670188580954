import React from 'react';
import { Input, Icon, Checkbox, Button, Upload } from 'antd';

const { Search } = Input;

function TopActionBar(props) {
  const {
    handleSearch,
    handleShowDialogAdd,
    handleDeleteItems,
    handleImport,
    onChangeShowResigned,
  } = props;
  return (
    <div>
      <div className='d-flex flex-row justify-content-start mb-2 align-items-center'>
        <Search
          style={{ maxWidth: 300 }}
          className='mr-2'
          placeholder='Search'
          onSearch={handleSearch}
        />

        {/*<Icon onClick={handleShowDialogAdd} style={{ fontSize: '30px', color: '#08c' }} type="plus-circle" className="mr-2" />*/}

        {/* {handleDeleteItems && (
          <Button
            className='mr-2'
            onClick={() => handleDeleteItems()}
            type='danger'
          >
            Remove
          </Button>
        )} */}

        {handleImport && (
          <Upload
            className='mr-2'
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            beforeUpload={handleImport}
          >
            <Button>
              <Icon type='upload' /> Import Xlsx
            </Button>
          </Upload>
        )}

        {onChangeShowResigned && (
          <Checkbox
            onChange={(e) => onChangeShowResigned(e)}
            style={{ width: '280px' }}
          >
            Show Resigned Workers
          </Checkbox>
        )}

        <Button
          onClick={handleShowDialogAdd}
          type='primary'
          className='mr-2 ml-2'
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default TopActionBar;
