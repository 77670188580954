import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { valueFormatterForDouble } from '../common';

function PointsTable(props) {
  const {
    allHolidays,
    cutOffDate,
    dataTimeSheet,
    currentMonthTimeSheet,
    tableHeight,
  } = props;
  const [rowDatas, setRowDatas] = useState([]);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (_.isArray(dataTimeSheet)) {
      GetDataTable();
    }
  }, [dataTimeSheet]);

  function GetDataTable() {
    let dataForTable = [];

    let allHolidayDateString = allHolidays.map((x) =>
      moment(x.date).format('DD-MM-YY')
    );

    let colorHomeLeave = '#FF99FF';
    let colorAbsent = '#FF0000';
    let colorCourse = '#FFC000';
    let colorSunday = '#ACB9CA';
    let colorMc = '#00FF00';
    let colorPulicHoliday = '#C6E0B4';

    let offDayColors = [
      {
        name: 'Medical (MC)',
        value: colorMc,
      },
      {
        name: 'Course / Training',
        value: colorCourse,
      },
      {
        name: 'Home Leave',
        value: colorHomeLeave,
      },
      {
        name: 'Absent',
        value: colorAbsent,
      },
    ];

    function getCellStyle(params) {
      return _.has(params.data, `${params.colDef.field}DayType`) &&
        params.data[`${params.colDef.field}DayType`]
        ? {
            backgroundColor: params.data[`${params.colDef.field}DayType`],
          }
        : null;
    }

    let columns = [
      {
        headerName: 'No.',
        field: 'no',
        filter: true,
        pinned: 'left',
        width: 80,
      },
      {
        headerName: 'Code',
        field: 'code',
        filter: true,
        pinned: 'left',
        width: 100,
      },
      {
        headerName: 'Worker Name',
        field: 'name',
        filter: true,
        pinned: 'left',
        width: 150,
      },
    ];

    let startMoment = moment(currentMonthTimeSheet, 'MM-YYYY')
      .subtract(1, 'months')
      .set('date', cutOffDate + 1);

    let currentMonthMoment = moment(currentMonthTimeSheet, 'MM-YYYY');
    let endMoment = currentMonthMoment.set('date', cutOffDate);

    let tempStartMoment = moment(startMoment);

    let columnDays = [];
    while (!tempStartMoment.isAfter(endMoment)) {
      let daystring = tempStartMoment.format('DD-MM-YY');
      columnDays.push(daystring);

      columns.push({
        headerName: daystring,
        field: daystring,
        width: 120,

        cellStyle: getCellStyle,
        valueFormatter: valueFormatterForDouble,
      });

      tempStartMoment = tempStartMoment.add(1, 'days');
    }

    columns.push({
      headerName: 'POINTS',
      field: 'totalPoints',
      width: 120,
      pinned: 'right',
      aggFunc: 'sum',
      valueFormatter: valueFormatterForDouble,
    });

    dataTimeSheet.forEach((worker, index) => {
      let rowData = {
        no: index,
        code: worker.employeeCode,
        name: worker.name,
      };

      let totalPoints = 0.0;

      columnDays.forEach((columnDay) => {
        let x = worker.timeWorkingInDays.find(
          (x) => x.dayYearString == columnDay
        );

        if (x) {
          rowData[columnDay] = x.point;
          totalPoints += x.point;

          //set color off day
          //check is off day
          if (x.isDayOff) {
            rowData[`${columnDay}DayType`] = colorHomeLeave;

            x.shiftTrades.forEach((shift) => {
              let offDayColor = offDayColors.find(
                (offDayItem) => offDayItem.name == shift.costCodeName
              );

              if (offDayColor) {
                //set color

                rowData[`${columnDay}DayType`] = offDayColor.value;
              }
            });
          }

          //set color sunday
          if (moment(columnDay, 'DD-MM-YY').weekday() === 0) {
            rowData[`${columnDay}DayType`] = colorSunday;
          }

          //set color public holiday
          if (allHolidayDateString.some((m) => x.dayYearString == m)) {
            rowData[`${columnDay}DayType`] = colorPulicHoliday;
          }
        }
      });

      rowData.totalPoints = totalPoints;

      dataForTable.push(rowData);
    });

    dataForTable.forEach((x) => {});

    setRowDatas(dataForTable);
    setColumnDefs(columns);
  }

  return (
    <div
      className='ag-theme-alpine'
      style={{ height: 600, boxSizing: 'border-box' }}
    >
      <AgGridReact
        className='ag-theme-balham'
        reactUi='true'
        rowData={rowDatas}
        columnDefs={columnDefs}
        pagination={true}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
      ></AgGridReact>
    </div>
  );
}

export default PointsTable;
