import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Tooltip,
  message,
  Input as AntInput,
  Typography,
  Divider,
  Badge,
} from 'antd';

import _ from 'lodash';
import axios from 'axios';
import { IconButton, Checkbox, Icon, Button } from 'rsuite';
import {
  setDataEntry,
  setDataEntryLoading,
} from '../../../actions/app/dataentry';
import { getForgeTokenInternal } from '../../module_BimApp/function/ForgeFunction';
import { getLastNode } from '../../module_BimApp/function/DataEntryFunction';

async function apiGetDOConcrete(payload) {
  return await axios.post('/api/do_inv/common/get-concrete-in-do', payload);
}

const { Search } = AntInput;
const { Text } = Typography;

let checkInitGrid = true;
class DataEntryToolbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowLegend: false,
    };
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.selectedNode.length !== 0) {
      let temp1 = JSON.stringify(nextProps.selectedNode);
      let temp2 = JSON.stringify(this.props.selectedNode);
      if (temp1 !== temp2) {
        this.handleCorrespondSelect(nextProps.selectedNode);
      }
    } else {
      if (this.props.viewerCurrent) {
        try {
          this.props.viewerCurrent.clearSelection();
        } catch {}
      }
    }
  };
  handleColorStatus = async (value) => {
    if (!this.props.viewerCurrent) {
      message.warning('Please, open model');
      return;
    }
    if (this.props.dataTable.length === 0) {
      // message.warning('Please, reload data')
      // return
      await this.props.handleModelData();
    }
    if (this.props.dataTable.length === 0) {
      message.warning('Please, check sheet setup');
      return;
    }

    let tempModel = {};
    let models = this.props.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      if (value === null) this.props.viewerCurrent.clearThemingColors(v);
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });
    let data = value === null ? this.props.dataEntryData : value;
    let clone = data.slice(0);
    _.forEach(clone, (v) => {
      if (v.Status !== '' && v.Status !== undefined && v.Status !== null) {
        let index = _.findIndex(this.props.allDataTable, (o) => {
          return o[this.props.mainParameter] === v[this.props.mainName];
        });
        if (index >= 0) {
          let t = {};
          _.forEach(this.props.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          // let t = groupArray(this.state.allDataTable[index].children, 'itemId')
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              let color = this.props.statusColor[v.Status];
              if (color)
                _.forEach(i, (el) => {
                  this.props.viewerCurrent.setThemingColor(
                    el.id,
                    color,
                    tempModel[k]
                  );
                });
            }
          });
        }
      }
    });
  };
  handleSaveData = () => {
    this.props.setDataEntryLoading({ loading: true });
    axios
      .get(`/api/dataentry/get-sheet5d-data`, {
        params: {
          projectId: this.props.projectId,
          sheetId: this.props.selectSheet.id,
          sheetName: this.props.selectSheet.name,
          token: this.props.userInfo.token,
        },
      })
      .then((res) => {
        let temp = { add: [], update: [], idsToDelete: [] };

        _.forEach(window.historySheet, (v) => {
          if (this.props.selectSheet.name === 'Pile, Dwall') {
            if (
              v['Zone'] === '' ||
              v['Zone'] === undefined ||
              v['Pile marking'] === '' ||
              v['Pile marking'] === undefined ||
              v.isAutoGroup
            )
              return;
          } else if (this.props.selectSheet.name === 'Slab 5D') {
            if (
              v['Pour name'] === '' ||
              v['Pour name'] === undefined ||
              v.isAutoGroup
            )
              return;
          }
          let index = _.findIndex(res.data, (o) => {
            return o.id === v.id;
          });
          delete v.__children;
          delete v.sheetId;
          delete v.castingReportHistory;
          if (!v.createdAt) {
            v.createdAt = Date.now();
            v.updatedAt = Date.now();
          }
          let copy = Object.assign({}, v);
          copy.user = this.props.userInfo.email;
          copy.updatedAt = Date.now();
          delete copy.row_status;
          delete copy.compareObject;
          delete copy.itemIds;
          delete copy.externalIds;
          delete copy.row_history;
          delete copy.globalOffset;
          delete copy.index;
          delete copy._id;
          delete copy.id;
          delete copy.parentId;
          delete copy.__v;
          if (!v.row_history) v.row_history = [];
          if (index >= 0) {
            if (v.row_status === 'add' || v.row_status === 'update') {
              delete v.row_status;
              let tempData = {};
              _.forEach(copy, (o, k) => {
                if (!res.data[index][k] || res.data[index][k] !== o) {
                  if (res.data[index][k] === undefined && o === '') return;
                  tempData[k] = o;
                }
              });
              if (v.row_history.length >= 1000) {
                v.row_history.splice(v.row_history.length - 1, 1);
              }
              v.row_history.push(tempData);
              temp.update.push(v);
            } else if (v.row_status === 'delete') {
              console.log('v', v);
              temp.idsToDelete.push(v.id);
            }
          } else {
            if (v.row_status === 'add' || v.row_status === 'update') {
              delete v.row_status;
              delete v['Do No.'];
              delete v[`Con'c Volume Design (m3) by Engineer`];
              v.row_history.push(copy);
              temp.add.push(v);
            }
          }
        });

        console.log('tool bar', temp);
        axios
          .post(`/api/dataentry/save-sheet5d-data`, {
            projectId: this.props.projectId,
            sheet: this.props.selectSheet,
            data: temp,
            token: this.props.userInfo.token,
          })
          .then((res) => {
            window.historySheet = [];
            message.success(`Save sheet data`);
            this.props.setDataEntry({ data: res.data });
            this.props.setDataEntryLoading({ loading: false });
          })
          .catch(() => {
            message.warning(`Can't save sheet data`);
            this.props.setDataEntryLoading({ loading: false });
          });
      })
      .catch(() => {
        message.warning(`Can't get sheet data`);
        this.props.setDataEntryLoading({ loading: false });
      });
  };
  async handleGetActualConcreteVolume() {
    this.props.setDataEntryLoading({ loading: true });

    axios
      .post(`/api/dataentry/save-sheet5d-concrete-do`, {
        projectId: this.props.projectId,
        sheet: this.props.selectSheet,
        token: this.props.userInfo.token,
      })
      .then((res) => {
        window.historySheet = [];
        message.success(`Save sheet data`);
        this.props.setDataEntry({ data: res.data });
        this.props.setDataEntryLoading({ loading: false });
      })
      .catch(() => {
        message.warning(`Can't save sheet data`);
        this.props.setDataEntryLoading({ loading: false });
      });
  }
  handleCorrespond = (e, checked) => {
    this.props.setDataEntry({ isCorrespond: checked });
  };
  handleIsolate = (e, checked) => {
    this.props.setDataEntry({ isIsolate: checked });
  };
  handleCorrespondSelect = (array) => {
    if (this.props.isCorrespond) {
      if (!this.props.viewerCurrent) return;
      let models = this.props.viewerCurrent.impl.modelQueue().getModels();
      if (models.length === 0) return;
      this.handleSelectObjectInRow(array, true);
    }
  };
  handleSelectObjectInRow = (rows, check) => {
    if (this.props.dataTable.length === 0) {
      message.warning('Please, reload data');
      return;
    }
    let tempIds = {};
    let tempModel = {};
    let models = this.props.viewerCurrent.impl.modelQueue().getModels();
    _.forEach(models, (v) => {
      tempModel[
        v.myData.loadOptions.itemId +
          ' ' +
          v.myData.loadOptions.version +
          ' ' +
          v.myData.loadOptions.guid
      ] = v;
    });
    if (this.props.selectSheet?.name === 'Archi') {
      _.forEach(rows, (v) => {
        console.log('v', v);
        let index = _.findIndex(this.props.allDataTable, (o) => {
          if (!_.isEmpty(o['5D_CODE_BASE'])) {
            let uniqCode =
              o['5D_CODE_BASE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_MIDLE'])) {
            let uniqCode =
              o['5D_CODE_MIDLE'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          if (!_.isEmpty(o['5D_CODE_FINAL'])) {
            let uniqCode =
              o['5D_CODE_FINAL'] +
              o['ROOM NUMBER'] +
              o['WH_Zone'] +
              o['WH_Sub Zone'] +
              o['WH_Level'];
            if (uniqCode === v.Item) {
              return true;
            }
          }

          return false;
        });

        if (index >= 0) {
          let t = {};
          _.forEach(this.props.allDataTable[index].children, (j) => {
            if (!t[j.itemId]) t[j.itemId] = [];
            t[j.itemId].push(j);
          });
          _.forEach(t, (i, k) => {
            if (tempModel[k]) {
              if (!tempIds[k]) tempIds[k] = [];
              _.forEach(i, (el) => {
                tempIds[k].push(el.id);
              });
            }
          });
        }
      });
    } else {
      _.forEach(rows, (v) => {
        if (v.Status !== '' && v.Status !== undefined) {
          let index = _.findIndex(this.props.allDataTable, (o) => {
            return o[this.props.mainParameter] === v[this.props.mainName];
          });
          if (index >= 0) {
            let t = {};
            _.forEach(this.props.allDataTable[index].children, (j) => {
              if (!t[j.itemId]) t[j.itemId] = [];
              t[j.itemId].push(j);
            });
            _.forEach(t, (i, k) => {
              if (tempModel[k]) {
                if (!tempIds[k]) tempIds[k] = [];
                _.forEach(i, (el) => {
                  tempIds[k].push(el.id);
                  // tempId.push({ model: models[index], ids: externalId })
                  // this.props.viewerCurrent.select(el.id, tempModel[k])
                });
              }
            });
          }
        }
      });
    }

    // if (models.length === 1)
    let selection = [];
    this.props.viewerCurrent.showAll();
    this.props.viewerCurrent.clearSelection();
    _.forEach(tempIds, (v, k) => {
      if (check) {
        if (this.props.isIsolate) {
          this.props.viewerCurrent.isolate(v, tempModel[k]);
        }
      }
      selection.push({ model: tempModel[k], ids: v });
    });
    if (!this.props.isIsolate) {
      this.props.viewerCurrent.setAggregateSelection(selection);
    }
  };

  handleCompareVersion = () => {
    this.props.setDataEntryLoading({ loading: true });
    let tempUnit = ['mm', 'm2', 'm3', 'm²', 'm³', 'm^2', 'm^3'];
    let parameter = [];
    let listCheckTotal = {};
    let tempMatching = {};
    let tempMatchingIndex = {};
    _.forEach(this.props.selectSheet.columns, (v, k) => {
      if (v.parameter !== '' && v.parameter) {
        parameter.push(v.parameter);
        listCheckTotal[v.parameter] = v.type === 'numeric';
        tempMatching[v.parameter] = v.name;
        tempMatchingIndex[v.parameter] = k;
      }
    });
    let clone = this.props.dataEntryData.slice(0);
    let temp = [];
    _.forEach(clone, (v) => {
      if (v.itemIds && v.itemIds !== '') {
        let parse = JSON.parse(v.itemIds);
        _.forEach(parse, (o) => {
          let split = o.split(' ');
          let index = _.findIndex(temp, (i) => {
            return i.itemId === split[0];
          });
          if (index < 0) {
            temp.push({ itemId: split[0], version: split[1], guid: split[2] });
          }
        });
      }
    });
    axios
      .post(`/api/dataentry/get-items`, {
        projectId: this.props.projectId,
        data: temp,
        token: this.props.userInfo.token,
      })
      .then((res) => {
        getForgeTokenInternal().then(async (token) => {
          let getList = [];
          let count = res.data.length;
          _.map(res.data, async (v) => {
            try {
              let url = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata`;
              const config = {
                headers: {
                  Authorization: 'Bearer ' + token.access_token,
                },
              };
              let response = await axios.get(url, config);
              if (response.data.data.metadata.length !== 0) {
                let index1 = _.findIndex(response.data.data.metadata, (o) => {
                  return o.guid === v.guid;
                });
                let guid = '';
                if (index1 >= 0) {
                  guid = v.guid;
                } else {
                  guid = response.data.data.metadata[0].guid;
                }
                let url1 = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata/${guid}?forceget=true`;
                let response1 = await axios.get(url1, config);
                let result = getLastNode(response1.data.data.objects);

                let url2 = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${v.newRevision.objectId}/metadata/${guid}/properties?forceget=true`;
                let response2 = await axios.get(url2, config);
                _.forEach(response2.data.data.collection, (v) => {
                  if (result.includes(v.objectid)) getList.push(v);
                });
                count--;
                if (count === 0) {
                  let temp = [];
                  _.forEach(getList, (i) => {
                    let data = {};
                    _.forEach(i.properties, (e) => {
                      _.forEach(e, (o, k) => {
                        if (parameter.includes(k)) {
                          let split = o.split(' ');
                          let value = o;
                          if (split.length === 2) {
                            if (tempUnit.includes(split[1]))
                              value = parseFloat(split[0]);
                          }
                          let num = _.toNumber(value);
                          data[k] = listCheckTotal[k]
                            ? _.isNumber(num)
                              ? +num.toFixed(2)
                              : +value
                            : value;
                        }
                      });
                    });
                    if (Object.keys(data).length !== 0) temp.push(data);
                  });
                  let tempData = this.props.handleForgeData(
                    temp,
                    this.props.mainParameter,
                    listCheckTotal
                  );
                  this.props.setDataEntryLoading({ loading: false });
                  this.props.gridApi.api.forEachNode((rowNode) => {
                    let v = Object.assign({}, rowNode.data);
                    v.compareObject = {};
                    if (
                      v.itemIds !== '' &&
                      v.itemIds !== undefined &&
                      v.itemIds !== null
                    ) {
                      let index = _.findIndex(tempData, (o) => {
                        return (
                          o[this.props.mainParameter] ===
                          v[tempMatching[this.props.mainParameter]]
                        );
                      });
                      if (index >= 0) {
                        let newData = tempData[index];
                        _.forEach(parameter, (i) => {
                          if (newData[i] !== v[tempMatching[i]]) {
                            // v.compareValue = newData[i]
                            if (_.isNumber(newData[i])) {
                              if (newData[i] < v[tempMatching[i]]) {
                                // v.compareStatus = 'down'
                                v.compareObject[tempMatching[i]] = {
                                  status: 'up',
                                  value: newData[i],
                                };
                              } else {
                                // v.compareStatus = 'up'
                                v.compareObject[tempMatching[i]] = {
                                  status: 'down',
                                  value: newData[i],
                                };
                              }
                            } else {
                              // v.compareStatus = 'change'
                              v.compareObject[tempMatching[i]] = {
                                status: 'change',
                                value: newData[i],
                              };
                            }
                            rowNode.setData(v);
                          }
                        });
                      }
                    }
                  });
                  this.setState({ isShowLegend: true });
                  message.success(`Compare sheet data`);
                }
              }
            } catch (err) {
              this.props.setDataEntryLoading({ loading: false });
              console.log(err);
            }
          });
        });
      })
      .catch(() => {
        message.warning(`Can't save sheet data`);
        this.props.setDataEntryLoading({ loading: false });
      });
  };
  handleCloseComparison = () => {
    this.props.gridApi.api.forEachNode((rowNode) => {
      let v = Object.assign({}, rowNode.data);
      v.compareObject = {};
      rowNode.setData(v);
    });
    this.setState({ isShowLegend: false });
  };
  render() {
    return (
      <div>
        <Tooltip placement='bottom' title='Save'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance='ghost'
            color=''
            icon={<Icon icon='save' />}
            loading={this.props.dataEntryLoading}
            onClick={this.handleSaveData}
            // disabled={this.props.dataEntryData.length === 0}
          />
        </Tooltip>

        <Tooltip placement='bottom' title='Get Actual Concrete Volume'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance='ghost'
            color=''
            icon={<Icon icon='down' />}
            loading={this.props.dataEntryLoading}
            onClick={() => this.handleGetActualConcreteVolume()}
            // disabled={this.props.dataEntryData.length === 0}
          />
        </Tooltip>

        <Tooltip placement='bottom' title='Export table to excel'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance='ghost'
            color=''
            icon={<Icon icon='file-excel-o' />}
            loading={this.props.dataEntryLoading}
            onClick={() => {
              this.props.gridApi.api.exportDataAsExcel();
            }}
            disabled={this.props.dataEntryData.length === 0}
          />
        </Tooltip>

        <Divider type='vertical' />
        <Tooltip placement='top' title='Colorize Status'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance='ghost'
            color=''
            icon={<Icon icon='paint-brush' />}
            loading={this.props.dataEntryLoading}
            onClick={this.handleColorStatus.bind(this, null)}
            disabled={this.props.dataEntryData.length === 0}
          />
        </Tooltip>
        <Tooltip placement='top' title='Compare Version'>
          <IconButton
            style={{ borderRadius: 0 }}
            size='sm'
            appearance='ghost'
            color='blue'
            icon={<Icon icon='compass' />}
            loading={this.props.dataEntryLoading}
            onClick={this.handleCompareVersion}
            disabled={this.props.dataEntryData.length === 0}
          />
        </Tooltip>

        <Divider type='vertical' />
        <Search
          loading={this.props.dataEntryLoading}
          placeholder='Search'
          disabled={this.props.dataEntryData.length === 0}
          onSearch={(value) => this.props.gridApi.api.setQuickFilter(value)}
          style={{ width: 200, top: 3, borderRadius: 0 }}
        />
        <Divider type='vertical' />
        <Checkbox
          inline
          checked={this.props.isCorrespond}
          disabled={this.props.dataEntryData.length === 0}
          onChange={this.handleCorrespond.bind(this)}
        >
          Correspond with 3D
        </Checkbox>
        <Checkbox
          inline
          checked={this.props.isIsolate}
          disabled={
            this.props.dataEntryData.length === 0
              ? true
              : this.props.isCorrespond
              ? false
              : true
          }
          onChange={this.handleIsolate}
        >
          Isolate selected object
        </Checkbox>

        {this.state.isShowLegend && (
          <div
            style={{
              display: 'inline',
              float: 'right',
              position: 'relative',
              top: 5,
              right: 2,
            }}
          >
            <Badge color='#ffc75e' text='Text value change' />
            <Divider type='vertical' />
            <Badge color='#5eff6c' text='Number increase' />
            <Divider type='vertical' />
            <Badge color='#ff5e5e' text='Number reduce' />
            <Tooltip placement='top' title='Close Comparison'>
              <IconButton
                style={{ borderRadius: 0, marginLeft: 5 }}
                size='sm'
                appearance='ghost'
                color=''
                icon={<Icon icon='close' />}
                loading={this.props.dataEntryLoading}
                onClick={this.handleCloseComparison.bind(this)}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

DataEntryToolbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  excelPage: PropTypes.bool.isRequired,
  setDataEntry: PropTypes.func.isRequired,
  setDataEntryLoading: PropTypes.func.isRequired,
  dataEntryLoading: PropTypes.bool.isRequired,
  dataEntryData: PropTypes.func.isRequired,
  selectedNode: PropTypes.object.isRequired,
  gridApi: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    dataEntryLoading: state.dataentry.loading,
    dataEntryData: state.dataentry.data,
    gridApi: state.dataentry.gridApi,
    selectedNode: state.dataentry.selectedNode,
    isCorrespond: state.dataentry.isCorrespond,
    isIsolate: state.dataentry.isIsolate,
  };
}
export default connect(mapStateToProps, {
  setDataEntry: setDataEntry,
  setDataEntryLoading,
})(DataEntryToolbar);
