import React from 'react';
import $ from 'jquery';

import axios from 'axios'
import { Button, Dropdown, Header, Radio, Form, Checkbox } from 'semantic-ui-react'
import { Popover, Whisper, Icon, DatePicker } from 'rsuite';
import { message, Tooltip, Tabs, Col, Row } from 'antd'
import moment from 'moment';
import {
  PlanningSummary,
  PlanningCompare
} from '../dock_forge'

import Dock from 'react-dock'
import { Modal, TagPicker, Loader } from 'rsuite';
import _ from 'lodash'
import {
  convertHexColorToVector4, formatNumber
} from '../function/TableFunction'
import {
  timelineFocus, getDataForTimeline, getDataFromSheetTimeLine, groupElementCompare, timelineChange
  , timelineSelect, showAllGroups, hideAllGroups, loadStatusTimeline, getColumnsStatus
} from '../function/TimeLineFunction'
import * as Timeline from "vis-timeline/standalone";

import { DraggableAreasGroup } from 'react-draggable-tags'
import {
  getAllElementdbIdsOneModel,
} from '../function/ForgeFunction'
import {handleModulePPVCAndPrecast} from '../function/ProgressFunction'
const { TabPane } = Tabs;
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();

const THREE = window.THREE
const timeline = Timeline
const Autodesk = window.Autodesk



class BottomBarTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listMark: [],
      listMarkMS: [],
      listMarkCompare: [],

      openTable: false,
      uploading: false,
      volumeCasted: 0,
      volumeNoCast: 0,
      volumeActive: 0,
      allVolume: 0,

      volumeCastedCompare: 0,
      volumeNoCastCompare: 0,
      volumeEarlyCompare: 0,
      volumeDelayCompare: 0,
      volumeActiveCompare: 0,
      allVolumeCompare: 0,
      volumePPVC: 0,
      volumeCastedComparePPVC: 0,
      volumeActiveComparePPVC: 0,

      listElementId: [],
      listElementLeft: [],
      listElementRight: [],
      position: 'bottom',
      resizeDock: true,

      timeCurrent: new Date(),
      minisizePanel: false,

      getListSheet: {
        openPanel: false,
        settingLoading: false,

        listSheetDS: [],
        listSheetMS: [],

        sheetSelectedDS: [],
        sheetSelectedMS: [],

        saveSheetsSelected: false,
        sheetSaved: [],
        sheetSelected: []
      },

      playTimeline: 'play',
      playSetting: {
        displayScheduleL: 'Detail Schedule',
        displayScheduleR: 'Master Schedule',
        optionDisplayColumnDS: [],
        optionDisplayColumnMS: [],
        playTwoView: false,
        indexStartL: 3,
        indexEndL: 4,
        indexStartR: 1,
        indexEndR: 2,
        compareDate: 'Start Date',
      },
      displaySetting: {
        displaySchedule: 'Master Schedule',
        optionDisplayColumnDS: [],
        optionDisplayColumnMS: [],
        indexStartDS: 0,
        indexEndDS: 0,
        indexStartMS: 0,
        indexEndMS: 0,
        defaultStartDS: 1,
        defaultEndDS: 2
      },
      statusBar: {
        openPanel: false,
        statusBarDate: new Date(),
      },


      checkSynchronize: false,
      geometry2DLoading: false,
      displayNonProgress: false,
      openDockSummaryPanel: false,
      openDockComparePanel: false,

      statusDS: [],
      tempStatusDS: [],
      loadingStatus: false,

      heightDock: 0,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,

      isHaveSheetSave: true,
      allDbIds: [],
    };
    // this.overrideColorTimeline = this.overrideColorTimeline.bind(this)
    this.timelinepanel = null
    this.tempDataStatus = {}
    this.tempPlay = 'play'
    this.maxTimeline = 0
    this.minTimeline = 0
    this.items = null
    this.groups = null
    this.listSheetResponse = {
      ['Master Schedule']: [],
      ['Detail Schedule']: []
    }
    this.disHeight = 0
    this.onceTime2D = false
    this.colorDelay = new THREE.Vector4(255 / 255, 0, 0, 1) //red
    this.colorEarly = new THREE.Vector4(0 / 255, 149 / 255, 255 / 255, 1) //blue
    this.colorOngoing = new THREE.Vector4(255 / 255, 165 / 255, 0, 1) //#ffa500
  }


  componentDidMount = async () => {
    window.onresize = this.changeSizeScreen
    this.setState({ uploading: true, allDbIds: getAllElementdbIdsOneModel(this.props.viewer) })
    axios.post(`/api/items/getsheetsaved`, { itemId: this.props.itemId })
      .then(res => {
        this.setState(prevState => ({
          getListSheet: {
            ...prevState.getListSheet,
            sheetSaved: res.data,
          },
          maxForgeViewer: $('#splitForgeViewer').height(),
          modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock
        }), async () => {
          $('#splitForgeViewer').height(this.state.modifyForgeViewer)
          this.props.viewer.resize()
          this.handleSync()
          let result = await getDataForTimeline(this.props.viewer)
          this.setState({
            listMark: result.listMark,
            listMarkMS: result.listMarkMS,
            listMarkCompare: result.listMarkCompare,
            allVolume: result.allVolume,
          })
          // this.props.onDisabledBtnChange('btn4DComparison', false)
          this.handlePlayOneView()
        })
      })
      .catch(err => {
        console.log(err)
        message.error(`Get sheet failed`)
      })
    if (this.props.projectKey !== '') {
      axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
        .then(res => {
          if (res && res.data.isSuccessful) {
            this.moduleData = res.data.result;
            this.getModelData();
            let tempVolume = 0
            _.forEach(res.data.result, v => {
              tempVolume += v.volume
            })
            this.setState({ volumePPVC: tempVolume })
          }
          else {
            message.warning("Can't get data from apps.wohhup.com")
          }
        })
    }
  }
  getModelData = () => {
    let temp = []
    if (this.state.allDbIds.length > 0) {
      let count = this.state.allDbIds.length
      _.forEach(this.state.allDbIds, id => {
        this.props.viewer.model.getProperties(id, (modelAProperty) => {
          _.forEach(modelAProperty.properties, (v) => {
            if (this.props.projectKey === 'pdd') {
              if (v.displayName === 'PCAPP-Module Code') {
                if (v.displayValue !== '')
                  temp.push({ dbId: id, mark: v.displayValue });
              }
            } else{
              if (v.displayName === 'Mark') {
                if (v.displayValue !== '') {
                  temp.push({ dbId: id, mark: v.displayValue })
                }
                return false
              }
            }
          
          })
          count--
          if (count === 0) {
            this.combineData(temp)
          }
        })

      })
    }
  }
  combineData = (data) => {
    for (const item of this.moduleData) {
      let modelItem = data.find(x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        item.dbId = modelItem.dbId;
      } else {
        item.dbId = ''
      }
    }
    this.analyzeMainData();
  }
  analyzeMainData = () => {
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(this.moduleData, this.props.typeProject, this.props.projectKey);
    this.dataPPVC = dataPPVC
    this.dataPPVC = this.dataPPVC.sort(function (a, b) {
      return a.modifiedDate - b.modifiedDate;
    });
    this.showElementByDateRange(Date.now())

  }
  showElementByDateRange = (e) => {
    let models = this.props.viewer.impl.modelQueue().getModels()
    // this.props.viewer.showAll()
    // this.props.viewer.clearThemingColors(this.props.viewer.model)
    // if (!this.state.showAllElement) {
    //   _.forEach(this.state.allDbIds, v => {
    //     this.props.viewer.hide(v)
    //   })
    // }
    let volumeCastedComparePPVC = 0
    let volumeActiveComparePPVC = 0
    let colorCasted = convertHexColorToVector4("#1890ff")
    let colorDelivered = convertHexColorToVector4("#f39c12")
    let colorInstalled = convertHexColorToVector4("#722ed1")
    let colorDeliveredToFitout = convertHexColorToVector4("#52c41a")
    let colorCompletedFitout = convertHexColorToVector4("#fadb14")
    let currentTime = moment(e)
    _.forEach(this.dataPPVC, v => {
      if (v.infoData.status !== "" && v.modifiedDate !== null) {
        _.forEach(v.listTime, list => {
          if (currentTime.valueOf() >= list.time) {
            if (list.status === 'Casting Completed') {
              this.props.viewer.setThemingColor(v.dbId, colorCasted)
              this.props.viewer.show(v.dbId)
              if (this.props.viewer2D) {
                this.props.viewer2D.setThemingColor(v.dbId, colorCasted, models[1])
                this.props.viewer2D.show(v.dbId, models[1])
              }

            } else if (list.status === 'Delivered to Fit-out Yard') {
              this.props.viewer.setThemingColor(v.dbId, colorDeliveredToFitout)
              this.props.viewer.show(v.dbId)
              if (this.props.viewer2D) {
                this.props.viewer2D.setThemingColor(v.dbId, colorDeliveredToFitout, models[1])
                this.props.viewer2D.show(v.dbId, models[1])
              }

            } else if (list.status === 'Fit Out Completed') {
              this.props.viewer.setThemingColor(v.dbId, colorCompletedFitout)
              this.props.viewer.show(v.dbId)
              if (this.props.viewer2D) {
                this.props.viewer2D.setThemingColor(v.dbId, colorCompletedFitout, models[1])
                this.props.viewer2D.show(v.dbId, models[1])
              }

            } else if (list.status === 'Delivered to Site') {
              this.props.viewer.setThemingColor(v.dbId, colorDelivered)
              this.props.viewer.show(v.dbId)
              if (this.props.viewer2D) {
                this.props.viewer2D.setThemingColor(v.dbId, colorDelivered, models[1])
                this.props.viewer2D.show(v.dbId, models[1])
              }

            } else if (list.status === 'Installed on Site') {
              this.props.viewer.setThemingColor(v.dbId, colorInstalled)
              this.props.viewer.show(v.dbId)
              if (this.props.viewer2D) {
                this.props.viewer2D.setThemingColor(v.dbId, colorInstalled, models[1])
                this.props.viewer2D.show(v.dbId, models[1])
              }

            }
          } else {
            // this.props.viewer.hide(v.dbId)
          }
        })
        if (currentTime.valueOf() >= moment(v.infoData.date, 'DD-MM-YYYY').valueOf()) {
          if (v.infoData.status === 'Installed on Site') {
            if (_.isNumber(v.infoData.volume))
              volumeCastedComparePPVC = volumeCastedComparePPVC + v.infoData.volume
          } else {
            if (_.isNumber(v.infoData.volume))
              volumeActiveComparePPVC = volumeActiveComparePPVC + v.infoData.volume
          }
        } else {
          if (_.isNumber(v.infoData.volume))
            volumeActiveComparePPVC = volumeActiveComparePPVC + v.infoData.volume
        }
      }
    })
    this.setState({
      volumeCastedComparePPVC: volumeCastedComparePPVC, volumeActiveComparePPVC: volumeActiveComparePPVC
    })
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.viewer2D !== null && this.onceTime2D === false) {
      this.onceTime2D = true
      this.handlePlaySetting()
      this.setState({ geometry2DLoading: true })
      nextProps.viewer2D.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (e) => {
        this.setState({ geometry2DLoading: false })
        this.handlePlayTwoView()
      });
    }
  }

  componentWillUnmount = () => {
    try {
      window.onresize = function () { }
      if (this.timelinepanel !== null) {
        this.timelinepanel.destroy();
      }
      this.props.viewer.clearThemingColors(this.props.viewer.impl.model);
      $('#dateTimeline4DL').hide()
      $('#dateTimeline4DR').hide()
      $('#showScheduleType3D').hide()
      $('#showScheduleType2D').hide()
      $('#showVolumePer3D').hide()
      $('#showVolumePer2D').hide()
      // this.props.onDisabledBtnChange('btn4DComparison', true)
      $('#splitForgeViewer').height('calc(100% - 53.78px)')
      this.props.viewer.resize()
      //! remove sync view
      this.props.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onStateChanged)
      this.props.viewer.removeEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.onStateChanged)
      this.props.viewer.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onStateChanged)
      this.props.viewer.removeEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onStateChanged)
      this.props.viewer.removeEventListener(Autodesk.Viewing.HIDE_EVENT, this.onStateChanged)
      this.props.viewer.removeEventListener(Autodesk.Viewing.SHOW_EVENT, this.onStateChanged)
    } catch { }
  }

  changeSizeScreen = () => {
    let _this = this
    var maxHeight = window.screen.height,
      maxWidth = window.screen.width,
      curHeight = window.innerHeight,
      curWidth = window.innerWidth;

    if (maxWidth === curWidth && maxHeight === curHeight) {
      if (_this.props.displayPanel) {
        this.disHeight = maxHeight - 53.78 - _this.state.maxForgeViewer
        _this.setState({
          maxForgeViewer: maxHeight - 53.78,
          modifyForgeViewer: $('#splitForgeViewer').height($('#splitForgeViewer').height() + this.disHeight)
        }, () => {
          $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
          _this.props.viewer.resize()
          if (_this.props.viewer2D !== null)
            _this.props.viewer2D.resize()
        })
      }
    } else {
      if (_this.props.displayPanel) {
        _this.setState({
          maxForgeViewer: curHeight - 53.78,
          modifyForgeViewer: $('#splitForgeViewer').height($('#splitForgeViewer').height() - this.disHeight)
        }, () => {
          $('#splitForgeViewer').height(_this.state.modifyForgeViewer)
          _this.props.viewer.resize()
          if (_this.props.viewer2D !== null)
            _this.props.viewer2D.resize()
        })
      }

    }
  }

  //#region // !play 1 view
  handlePlayOneView = () => {
    // this.setState({ resizeDock:true,position: 'bottom', heightDock: 300})
    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        openPanel: false,
        playTwoView: false
      }
    }))
  }
  generateTimeline = async (data, index1, index2) => {
    try {
      var _this = this
      if (this.timelinepanel !== null)
        this.timelinepanel.destroy();
      let result = await getDataFromSheetTimeLine(data, index1, index2)
      this.items = result.items
      this.groups = result.groups
      this.tempDataStatus = result.tempDataStatus
      this.minTimeline = result.minTimeline
      this.maxTimeline = result.maxTimeline

      if (this.props.viewer2D !== null) {
        this.handlePlaySetting()
        this.handlePlayTwoView()
      }
      else
        this.overrideColorTimeline(new Date(), _this.props.viewer)
      var container = document.getElementById('timeline-content');
      var options = {
        width: '100%', height: '100%', horizontalScroll: true, zoomKey: 'ctrlKey', verticalScroll: true, stack: false,
        stackSubgroups: false, tooltip: { followMouse: true, overflowMethod: 'cap' }
      };
      this.timelinepanel = new timeline.Timeline(container, this.items, this.groups, options);
      this.timelinepanel.addCustomTime(new Date(), 'testTimeline')
      this.timelinepanel.on('select', function (properties) { timelineSelect(_this.props.viewer, properties, _this) });
      this.timelinepanel.on('timechanged', (e) => {
        timelineChange(e, this.listSheetResponse, this)
        this.showElementByDateRange(e.time)
      });
      this.timelinepanel.on('doubleClick', function (e) { timelineFocus(e, _this.timelinepanel, _this) });
    } catch { }
  }
  overrideColorTimeline = (time, viewer) => {
    if (!this.state.displayNonProgress)
      viewer.hide(this.state.allDbIds)
    this.setState({ volumeCasted: 0, volumeNoCast: 0, volumeActive: 0, })
    var totalCasted = 0; var totalNoCast = 0; var totalActive = 0
    var elementCasted = []; var elementNoCast = []; var elementActive = []
    viewer.clearThemingColors(this.props.viewer.impl.model);
    var data = this.state.listMark
    // ! conver color
    let tempColorConvert = []
    _.forEach(this.state.statusDS, v => {
      tempColorConvert.push({ name: v.content, color: convertHexColorToVector4(v.color), check: v.check })
    })

    if (this.state.displaySetting.displaySchedule === "Master Schedule")
      data = this.state.listMarkMS
    for (var key in data) {
      if (this.tempDataStatus[key]) {
        var start = null;
        var end = null;
        var taskType = 'Concrete'
        _.forEach(this.tempDataStatus[key], (v, k) => {
          start = v.start.startOf('day').valueOf()
          end = v.end.endOf('day').valueOf()
          taskType = v.taskType
          if (end > time.valueOf())
            return false
        })

        var id = []
        var volume = 0
        data[key].forEach(item => {
          id.push(item.id)
          volume = volume + item.volume
        })
        let result = _.find(tempColorConvert, function (o) { return o.name === taskType });
        if (result === undefined)
          result = _.find(tempColorConvert, function (o) { return o.name === 'No Status' });
        let isOverrideColor = result.check
        let colorOngoing = result.color
        let statusCompletedName = tempColorConvert[tempColorConvert.length - 1].name
        let isOverrideColorCompleted = tempColorConvert[tempColorConvert.length - 1].check
        let colorCompleted = tempColorConvert[tempColorConvert.length - 1].color
        let colorFrontCompleted = tempColorConvert[tempColorConvert.length - 2].color
        if (start < time.valueOf() && end > time.valueOf()) {
          if (isOverrideColor) {
            if (taskType === statusCompletedName) {
              _.forEach(id, item => {
                viewer.setThemingColor(item, colorFrontCompleted);
              })
            } else {
              _.forEach(id, item => {
                viewer.setThemingColor(item, colorOngoing);
              })
            }
          }
          elementActive.push({
            name: key, id: id, start: this.tempDataStatus[key][0].start.format('DD-MM-YYYY')
            , end: this.tempDataStatus[key][0].end.format('DD-MM-YYYY'), volume: volume, cells: this.tempDataStatus[key][0].cells
          })
          totalActive = totalActive + volume
          viewer.show(id)
        } else if (end <= time.valueOf()) {
          if (isOverrideColorCompleted)
            _.forEach(id, item => {
              viewer.setThemingColor(item, colorCompleted);
            })
          totalCasted = totalCasted + volume
          elementCasted.push({
            name: key, id: id, start: this.tempDataStatus[key][0].start.format('DD-MM-YYYY')
            , end: this.tempDataStatus[key][0].end.format('DD-MM-YYYY'), volume: volume, cells: this.tempDataStatus[key][0].cells
          })
          viewer.show(id)
        } else if (end > time.valueOf()) {
          totalNoCast = totalNoCast + volume
          elementNoCast.push({
            name: key, id: id, start: this.tempDataStatus[key][0].start.format('DD-MM-YYYY')
            , end: this.tempDataStatus[key][0].end.format('DD-MM-YYYY'), volume: volume, cells: this.tempDataStatus[key][0].cells
          })

        }
      }
    }
    this.setState({
      volumeCasted: parseFloat(totalCasted.toFixed(2)),
      volumeNoCast: parseFloat(totalNoCast.toFixed(2)),
      volumeActive: parseFloat(totalActive.toFixed(2)),
      listElementId: [elementCasted, elementActive, elementNoCast],
      timeCurrent: time
    }, () => {
      $('#dateTimeline4DR').show()
      $('#dateTimeline4DR').text(moment(this.state.timeCurrent).format('DD-MM-YYYY'))
      $('#showScheduleType3D').show()
      $('#showScheduleType3D').text(this.state.isHaveSheetSave ? this.state.displaySetting.displaySchedule : '')
      this.props.handleView2D()
    })
  }
  async animationTimeLine() {
    var _this = this
    _this.setState({ playTimeline: _this.state.playTimeline === 'play' ? 'pause' : 'play' })
    this.tempPlay = _this.state.playTimeline === 'play' ? 'pause' : 'play'
    while (this.tempPlay === 'pause') {
      var time = this.timelinepanel.getCustomTime('testTimeline')
      time.setDate(time.getDate() + 10);
      this.timelinepanel.setCustomTime(time, 'testTimeline')
      _this.overrideColorTimeline(time, _this.props.viewer)
      if (this.maxTimeline < moment(time).valueOf()) {
        this.timelinepanel.setCustomTime(moment(this.maxTimeline).toDate(), 'testTimeline')
        _this.overrideColorTimeline(moment(this.maxTimeline).toDate(), _this.props.viewer)
        _this.setState({ playTimeline: 'play' })
        this.tempPlay = 'play'
      }
      await timer(2000);
    }
    function timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    }
  }
  //#endregion

  //#region // !play 2 view
  handlePlayTwoView = () => {
    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        playTwoView: true
      }
    }))
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        openPanel: false,
      }
    }))


    if (this.props.viewer2D !== null) {
      var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
        , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
      var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
        , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)

      // var time = this.timelinepanel.getCustomTime('testTimeline')
      let time = moment()
      this.overrideColorLeft(time, this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
      this.overrideColorRight(time, this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)
      this.showElementByDateRange(Date.now())
    }


  }
  async animationTimeLineTwoView() {
    var _this = this
    var dataL = _this.generateDataPlay(this.listSheetResponse[_this.state.playSetting.displayScheduleL]
      , _this.state.playSetting.indexStartL, _this.state.playSetting.indexEndL)
    var dataR = _this.generateDataPlay(this.listSheetResponse[_this.state.playSetting.displayScheduleR]
      , _this.state.playSetting.indexStartR, _this.state.playSetting.indexEndR)
    _this.setState({ playTimeline: _this.state.playTimeline === 'play' ? 'pause' : 'play' })
    this.tempPlay = _this.state.playTimeline === 'play' ? 'pause' : 'play'
    while (this.tempPlay === 'pause') {
      var time = this.timelinepanel.getCustomTime('testTimeline')
      time.setDate(time.getDate() + 10);
      this.timelinepanel.setCustomTime(time, 'testTimeline')
      _this.overrideColorLeft(time, _this.props.viewer, dataL, _this.state.playSetting.displayScheduleL)
      _this.overrideColorRight(time, _this.props.viewer2D, dataL, dataR, _this.state.playSetting.displayScheduleR)
      if (this.maxTimeline < moment(time).valueOf()) {
        this.timelinepanel.setCustomTime(moment(this.maxTimeline).toDate(), 'testTimeline')
        _this.overrideColorLeft(moment(this.maxTimeline).toDate(), _this.props.viewer, dataL, _this.state.playSetting.displayScheduleL)
        _this.overrideColorRight(moment(this.maxTimeline).toDate(), _this.props.viewer2D, dataL, dataR, _this.state.playSetting.displayScheduleR)
        _this.setState({ playTimeline: 'play' })
        this.tempPlay = 'play'
      }
      await timer(2000);
    }
    function timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    }
  }
  generateDataPlay = (data, index1, index2) => {
    var tempGroup = []
    var tempDataStatusPlay = {}
    var temp3 = []
    data.forEach(sheet => {
      var tempDataTimeline = sheet.rows

      for (var i = 0; i < tempDataTimeline.length; i++) {
        var temp = { id: tempDataTimeline[i].id, content: tempDataTimeline[i].cells[0].value, nestedGroups: [], parentId: tempDataTimeline[i].parentId }
        for (var j = 0; j < tempDataTimeline.length; j++) {
          if (i !== j) {
            if (tempDataTimeline[i].id === tempDataTimeline[j].parentId) {
              temp.nestedGroups.push(tempDataTimeline[j].id)
            }
          }
        }
        if (temp.nestedGroups.length === 0) {
          if (!temp3.includes(temp.id))
            temp3.push(temp.id)

        }
        tempDataTimeline.forEach(item => {
          tempGroup[item.id] = []
          tempGroup[item.id].push(item)
          let start = null
          let end = null
          if (item.cells[index1].value !== null && item.cells[index2].value !== undefined) {
            start = moment(item.cells[index1].value)
            end = moment(item.cells[index2].value)
          }
          let taskType = item.cells[5].value === undefined ? 'Concrete' : item.cells[5].value
          if (temp3.includes(item.id)) {
            if (!tempDataStatusPlay[item.cells[0].value])
              tempDataStatusPlay[item.cells[0].value] = []
            tempDataStatusPlay[item.cells[0].value].push({ start: start, end: end, taskType: taskType })
          }
        })

      }
    })
    return tempDataStatusPlay
  }
  overrideColorLeft = (time, viewer, tempData, listName) => {
    if (!this.state.displayNonProgress)
      viewer.hide(this.state.allDbIds)
    this.setState({ volumeCasted: 0, volumeNoCast: 0, volumeActive: 0, })
    var totalCasted = 0; var totalNoCast = 0; var totalActive = 0
    var elementCasted = []; var elementNoCast = []; var elementActive = []
    viewer.clearThemingColors(viewer.impl.model);
    // ! conver color
    let tempColorConvert = []
    _.forEach(this.state.statusDS, (v, k) => {
      if (k === 0 || k === this.state.statusDS.length - 1) {
        tempColorConvert.push({ name: v.content, color: convertHexColorToVector4(v.color), check: v.check })
      } else {
        tempColorConvert.push({ name: v.content, color: convertHexColorToVector4(this.state.statusDS[this.state.statusDS.length - 2].color), check: v.check })
      }
    })
    var data = this.state.listMark
    if (listName === "Master Schedule")
      data = this.state.listMarkMS
    for (var key in data) {
      if (tempData[key]) {
        var start = null;
        var end = null;
        var taskType = 'Concrete'
        _.forEach(tempData[key], (v, k) => {
          if (v.start === null || v.end === null) return
          start = v.start.startOf('day').valueOf()
          end = v.end.endOf('day').valueOf()
          taskType = v.taskType
          if (end > time.valueOf())
            return false
        })
        var id = []
        var volume = 0
        data[key].forEach(item => {
          id.push(item.id)
          volume = volume + item.volume
        })
        let result = _.find(tempColorConvert, function (o) { return o.name === taskType });
        if (result === undefined)
          result = _.find(tempColorConvert, function (o) { return o.name === 'No Status' });
        let isOverrideColor = result.check
        let colorOngoing = result.color
        let statusCompletedName = tempColorConvert[tempColorConvert.length - 1].name
        let isOverrideColorCompleted = tempColorConvert[tempColorConvert.length - 1].check
        let colorCompleted = tempColorConvert[tempColorConvert.length - 1].color
        let colorFrontCompleted = tempColorConvert[tempColorConvert.length - 2].color
        if (start !== null && end !== null) {
          if (start <= time.valueOf() && end >= time.valueOf()) {
            if (isOverrideColor) {
              if (taskType === statusCompletedName) {
                _.forEach(id, item => {
                  viewer.setThemingColor(item, colorFrontCompleted);
                })
              } else {
                _.forEach(id, item => {
                  viewer.setThemingColor(item, colorOngoing);
                })
              }
            }
            totalActive = totalActive + volume
            viewer.show(id)
          } else if (end < time.valueOf()) {
            if (isOverrideColorCompleted)
              id.forEach(item => {
                viewer.setThemingColor(item, colorCompleted);
              })
            totalCasted = totalCasted + volume
            elementCasted.push({
              name: key, id: id, start: tempData[key][0].start.format('DD-MM-YYYY')
              , end: tempData[key][0].end.format('DD-MM-YYYY'), volume: volume
            })
            viewer.show(id)
          } else if (end > time.valueOf()) {
            totalNoCast = totalNoCast + volume
            elementNoCast.push({
              name: key, id: id, start: tempData[key][0].start.format('DD-MM-YYYY')
              , end: tempData[key][0].end.format('DD-MM-YYYY'), volume: volume
            })
          }
        }
      }
    }
    this.setState({
      volumeCasted: parseFloat(totalCasted.toFixed(2)),
      volumeNoCast: parseFloat(totalNoCast.toFixed(2)),
      volumeActive: parseFloat(totalActive.toFixed(2)),
      listElementLeft: [elementCasted, elementActive, elementNoCast],
    }, () => {
      $('#showVolumePer3D').show()
      $('#showVolumePer3D').text(`${formatNumber(this.state.volumeCasted * 100 / (this.state.allVolume + this.state.volumePPVC), 2)} % of Structural Phase completed​`)
    })


  }
  overrideColorRight = (time, viewer, tempData1, tempData2, listName) => {
    if (!this.state.displayNonProgress)
      viewer.hide(this.state.allDbIds)
    this.setState({
      volumeCastedCompare: 0, volumeNoCastCompare: 0, volumeActiveCompare: 0,
      volumeEarlyCompare: 0, volumeDelayCompare: 0
    })
    var totalCasted = 0; var totalNoCast = 0; var totalActive = 0; var totalEarly = 0; var totalDelay = 0
    var elementCasted = []; var elementNoCast = []; var elementActive = [];
    var tempElementCast = {}; var tempElementNoCast = {}; var tempElementActive = []; var tempElementEarly = {}; var tempElementDelay = {};
    // ! conver color
    let tempColorConvert = []
    _.forEach(this.state.statusDS, (v, k) => {
      if (k === 0 || k === this.state.statusDS.length - 1) {
        tempColorConvert.push({ name: v.content, color: convertHexColorToVector4(v.color), check: v.check })
      } else {
        tempColorConvert.push({ name: v.content, color: convertHexColorToVector4(this.state.statusDS[this.state.statusDS.length - 2].color), check: v.check })
      }
    })
    let colorCompleted = tempColorConvert[tempColorConvert.length - 1].color
    viewer.clearThemingColors(viewer.impl.model);
    var data = this.state.listMark
    if (listName === "Master Schedule") {
      data = this.state.listMarkMS
    }
    if (this.state.playSetting.displayScheduleR === this.state.playSetting.displayScheduleL) {
      for (var key in data) {
        if (tempData1[key] && tempData2[key]) {

          let start1 = tempData1[key][0].start.startOf('day').valueOf()
          let end1 = tempData1[key][tempData1[key].length - 1].end.endOf('day').valueOf()

          let start2 = tempData2[key][0].start.startOf('day').valueOf()
          let end2 = tempData2[key][tempData2[key].length - 1].end.endOf('day').valueOf()

          var volume = 0
          var id = []
          data[key].forEach(item => {
            id.push(item.id)
            volume = volume + item.volume
          })
          if (start1 < time.valueOf() && end1 > time.valueOf()) {
            if (this.state.playSetting.compareDate === 'Start Date') {
              if (start1 > start2) {
                id.forEach(item => { viewer.setThemingColor(item, this.colorDelay); })
                totalDelay = totalDelay + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'Delay'
                })
                viewer.show(id)
              } else if (start1 < start2) {
                id.forEach(item => { viewer.setThemingColor(item, this.colorEarly); })
                totalEarly = totalEarly + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'Early'
                })
                viewer.show(id)
              } else {
                id.forEach(item => { viewer.setThemingColor(item, this.colorOngoing); })
                totalActive = totalActive + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'OnGoing'
                })
                viewer.show(id)
              }
            } else {
              if (end1 < end2) {
                id.forEach(item => { viewer.setThemingColor(item, this.colorDelay); })
                totalDelay = totalDelay + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'Delay'
                })
                viewer.show(id)
              } else if (end1 > end2) {
                id.forEach(item => { viewer.setThemingColor(item, this.colorEarly); })
                totalEarly = totalEarly + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'Early'
                })
                viewer.show(id)
              } else {
                id.forEach(item => { viewer.setThemingColor(item, this.colorOngoing); })
                totalActive = totalActive + volume
                elementActive.push({
                  name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
                  , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume, status: 'OnGoing'
                })
                viewer.show(id)
              }
            }
          } else if (end1 < time.valueOf()) {
            id.forEach(item => { viewer.setThemingColor(item, colorCompleted); })
            totalCasted = totalCasted + volume
            elementCasted.push({
              name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
              , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume
            })
            viewer.show(id)
          } else if (end1 > time.valueOf()) {
            totalNoCast = totalNoCast + volume
            elementNoCast.push({
              name: key, id: id, start: tempData2[key][0].start.format('DD-MM-YYYY')
              , end: tempData2[key][0].end.format('DD-MM-YYYY'), volume: volume
            })
          }
        }
      }
    } else {
      for (var keyCompare in this.state.listMarkCompare) {
        var item = this.state.listMarkCompare[keyCompare][0]
        let start1 = null; var end1 = null; var start2 = null; var end2 = null;
        if (tempData1[item.mark1]) {
          if (tempData1[item.mark1][0].start !== null && tempData1[item.mark1][0].end !== null) {
            start1 = tempData1[item.mark1][0].start.startOf('day').valueOf()
            end1 = tempData1[item.mark1][0].end.endOf('day').valueOf()
          }
        }
        if (tempData2[item.mark2]) {
          if (tempData2[item.mark2][0].start !== null && tempData2[item.mark2][0].end !== null) {
            start2 = tempData2[item.mark2][0].start.startOf('day').valueOf()
            end2 = tempData2[item.mark2][0].end.endOf('day').valueOf()
          }
        }
        if (start1 !== null && end1 !== null && start2 !== null && end2 !== null) {
          if (start1 < time.valueOf() && end1 > time.valueOf()) {
            if (this.state.playSetting.compareDate === 'Start Date') {
              if (start1 > start2) {
                viewer.setThemingColor(item.id, this.colorDelay);
                totalDelay = totalDelay + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'Delay'
                })
                viewer.show(item.id)
              } else if (start1 < start2) {
                viewer.setThemingColor(item.id, this.colorEarly);
                totalEarly = totalEarly + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'Early'
                })
                viewer.show(item.id)
              } else {
                viewer.setThemingColor(item.id, this.colorOngoing);
                totalActive = totalActive + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'On Going'
                })
                viewer.show(item.id)
              }
            } else {
              if (end1 < end2) {
                viewer.setThemingColor(item.id, this.colorDelay);
                totalDelay = totalDelay + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'Delay'
                })
                viewer.show(item.id)
              } else if (end1 > end2) {
                viewer.setThemingColor(item.id, this.colorEarly);
                totalEarly = totalEarly + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'Early'
                })
                viewer.show(item.id)
              } else {
                viewer.setThemingColor(item.id, this.colorOngoing);
                totalActive = totalActive + item.volume
                if (!tempElementActive[item.mark2])
                  tempElementActive[item.mark2] = []
                tempElementActive[item.mark2].push({
                  name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
                  , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume, status: 'On Going'
                })
                viewer.show(item.id)
              }
            }
          } else if (end1 < time.valueOf()) {
            viewer.setThemingColor(item.id, colorCompleted);
            totalCasted = totalCasted + item.volume
            if (!tempElementCast[item.mark2])
              tempElementCast[item.mark2] = []
            tempElementCast[item.mark2].push({
              name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
              , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume
            })
            viewer.show(item.id)
          } else if (end1 > time.valueOf()) {
            totalNoCast = totalNoCast + item.volume
            if (!tempElementNoCast[item.mark2])
              tempElementNoCast[item.mark2] = []
            tempElementNoCast[item.mark2].push({
              name: item.mark2, id: item.id, start: tempData2[item.mark2][0].start.format('DD-MM-YYYY')
              , end: tempData2[item.mark2][0].end.format('DD-MM-YYYY'), volume: item.volume
            })
          }
        } else if (start1 === null && end1 === null) {
          if (start2 < time.valueOf() && end2 > time.valueOf()) {
            viewer.setThemingColor(item.id, this.colorDelay);
            totalDelay = totalDelay + item.volume
            if (!tempElementActive[item.mark2])
              tempElementActive[item.mark2] = []
            tempElementActive[item.mark2].push({
              name: item.mark2, id: item.id, start: ''
              , end: '', volume: item.volume, status: 'Delay'
            })
            viewer.show(item.id)
          } else if (start2 < time.valueOf() && this.state.playSetting.compareDate === 'Start Date') {
            viewer.setThemingColor(item.id, this.colorDelay);
            totalDelay = totalDelay + item.volume
            if (!tempElementActive[item.mark2])
              tempElementActive[item.mark2] = []
            tempElementActive[item.mark2].push({
              name: item.mark2, id: item.id, start: ''
              , end: '', volume: item.volume, status: 'Delay'
            })
            viewer.show(item.id)
          } else if (end2 < time.valueOf() && this.state.playSetting.compareDate === 'End Date') {
            viewer.setThemingColor(item.id, this.colorDelay);
            totalDelay = totalDelay + item.volume
            if (!tempElementActive[item.mark2])
              tempElementActive[item.mark2] = []
            tempElementActive[item.mark2].push({
              name: item.mark2, id: item.id, start: ''
              , end: '', volume: item.volume, status: 'Delay'
            })
            viewer.show(item.id)
          }
        }
      }
    }
    var elementActive = groupElementCompare(tempElementActive)
    var elementNoCast = groupElementCompare(tempElementNoCast)
    var elementCasted = groupElementCompare(tempElementCast)

    this.setState({
      volumeCastedCompare: parseFloat(totalCasted.toFixed(2)),
      volumeNoCastCompare: parseFloat(totalNoCast.toFixed(2)),
      volumeActiveCompare: parseFloat(totalActive.toFixed(2)),
      volumeEarlyCompare: parseFloat(totalEarly.toFixed(2)),
      volumeDelayCompare: parseFloat(totalDelay.toFixed(2)),
      listElementRight: [elementCasted, elementActive, elementNoCast],
      timeCurrent: time
    }, () => {
      $('#dateTimeline4DR').show()
      $('#dateTimeline4DR').text(moment(this.state.timeCurrent).format('DD-MM-YYYY'))
      $('#dateTimeline4DL').show()
      $('#dateTimeline4DL').text(moment(this.state.timeCurrent).format('DD-MM-YYYY'))

      $('#showScheduleType3D').show()
      $('#showScheduleType3D').text(this.state.playSetting.displayScheduleL)
      $('#showScheduleType2D').show()
      $('#showScheduleType2D').text(this.state.playSetting.displayScheduleR)


      $('#showVolumePer2D').show()
      $('#showVolumePer2D').text(`${formatNumber(this.state.volumeCastedCompare * 100 / (this.state.allVolume + this.state.volumePPVC), 2)} % of Structural Phase completed​`)
    })
  }

  //#endregion

  //#region // ?sync
  handleSync = () => {
    this.setState({ uploading: true })
    axios.post(`/api/smartsheet/getsheets`, { sheetSavedCurrent: this.state.getListSheet.sheetSaved })
      .then(async res => {
        this.listSheetResponse['Master Schedule'] = []
        this.listSheetResponse['Detail Schedule'] = []
        _.forEach(res.data, item => {
          if (item.name.includes("Master Schedule"))
            this.listSheetResponse['Master Schedule'].push(item)
          else
            this.listSheetResponse['Detail Schedule'].push(item)
        })
        await loadStatusTimeline(this.props.folderSmartSheetId, this.state.getListSheet.sheetSaved, this)

        if (false) { //this.listSheetResponse['Detail Schedule'].length !== 0
          this.generateTimeline(this.listSheetResponse['Detail Schedule'], 1, 2)
          this.setState(prevState => ({
            displaySetting: {
              ...prevState.displaySetting,
              displaySchedule: 'Detail Schedule',
              indexStartDS: 1,
              indexEndDS: 2
            }
          }))
        }
        else {
          this.generateTimeline(this.listSheetResponse['Master Schedule'], 1, 2)
          this.setState(prevState => ({
            displaySetting: {
              ...prevState.displaySetting,
              displaySchedule: 'Master Schedule',
              indexStartMS: 1,
              indexEndMS: 2
            }
          }))
        }

        this.setState({ uploading: false, isHaveSheetSave: res.data.length !== 0 })
        message.success(`Sync data successful.`);

      })
      .catch(err => {
        this.setState({ uploading: false })
        this.setState(prevState => ({
          displaySetting: {
            ...prevState.displaySetting,
            displaySchedule: 'Detail Schedule',
          }
        }))
        message.error(`Sync data failed.`);
      })
  }
  //#endregion

  //#region // ?status bar
  handleClosePanelStatusBar() {
    this.setState(prevState => ({
      statusBar: {
        ...prevState.statusBar,
        openPanel: false,
      }
    }))
  }
  handleGoToStart = () => {
    this.setState({ uploading: true })
    this.timelinepanel.setCustomTime(this.minTimeline, 'testTimeline')
    if (this.state.playSetting.playTwoView === false) {
      this.overrideColorTimeline(this.minTimeline, this.props.viewer)
    } else {
      var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
        , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
      var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
        , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)
      this.overrideColorLeft(this.minTimeline, this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
      this.overrideColorRight(this.minTimeline, this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)
    }
    this.handleClosePanelStatusBar()
    this.setState({ uploading: false })
  }
  handleGoToCurrentDate = () => {
    this.setState({ uploading: true })
    this.timelinepanel.setCustomTime(new Date(), 'testTimeline')
    if (this.state.playSetting.playTwoView === false) {
      this.overrideColorTimeline(new Date(), this.props.viewer)
    } else {
      var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
        , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
      var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
        , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)
      this.overrideColorLeft(new Date(), this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
      this.overrideColorRight(new Date(), this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)
    }
    this.handleClosePanelStatusBar()
    this.setState({ uploading: false })
  }
  onChangeDateStatusBar = (dates, dateStrings) => {
    try {
      this.setState(prevState => ({
        statusBar: {
          ...prevState.statusBar,
          statusBarDate: dates,
        }
      }), () => {
        this.setState({ uploading: true })
        this.handleGoSelectedDate()
      })
    } catch { }

  }
  handleGoSelectedDate = () => {
    this.timelinepanel.setCustomTime(this.state.statusBar.statusBarDate, 'testTimeline')
    if (this.state.playSetting.playTwoView === false) {
      this.overrideColorTimeline(this.state.statusBar.statusBarDate, this.props.viewer)
    } else {
      var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
        , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
      var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
        , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)
      this.overrideColorLeft(this.state.statusBar.statusBarDate, this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
      this.overrideColorRight(this.state.statusBar.statusBarDate, this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)
    }
    this.handleClosePanelStatusBar()
    this.setState({ uploading: false })
  }
  //#endregion

  //#region // ?setting panel
  handleSettingPanel = () => {
    this.handleDisplaySetting()
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        openPanel: true,
      }
    }))
  }
  handleChangeTab = (key) => {
    if (key === '1') {
      this.handleDisplaySetting()
    } else if (key === '2') {
      this.handlePlaySetting()
    } else if (key === '3') {
      this.handleSettingSheet()
    }
  }
  //#endregion

  //#region // ?tab Default Schedule
  handleDisplaySetting = () => {
    var tempDS = []
    var tempMS = []
    var tempInputMS = []
    var tempInputDS = []

    this.listSheetResponse['Master Schedule'].forEach(item => {
      item.columns.forEach(column => {
        if (column.title.toLowerCase().includes('date')) {
          if (!tempMS[column.title]) {
            tempMS[column.title] = { key: column.id, text: column.title, value: column.index };
            tempInputMS.push({ key: column.id, text: column.title, value: column.index })
          }
        }
      })

    })
    this.listSheetResponse['Detail Schedule'].forEach(item => {
      item.columns.forEach(column => {
        if (column.title.toLowerCase().includes('date')) {
          if (!tempDS[column.title]) {
            tempDS[column.title] = { key: column.id, text: column.title, value: column.index }
            tempInputDS.push({ key: column.id, text: column.title, value: column.index })
          }
        }
      })

    })
    this.setState(prevState => ({
      displaySetting: {
        ...prevState.displaySetting,

        optionDisplayColumnDS: tempInputDS,
        optionDisplayColumnMS: tempInputMS,
        indexStartDS: 1,
        indexEndDS: 2,
        indexStartMS: 1,
        indexEndMS: 2
      }
    }))
  }
  handleSaveSettingDisplay = () => {
    this.props.viewer.show(this.state.allDbIds)

    if (this.state.displaySetting.displaySchedule === "Master Schedule")
      this.generateTimeline(this.listSheetResponse[this.state.displaySetting.displaySchedule]
        , this.state.displaySetting.indexStartMS, this.state.displaySetting.indexEndMS)
    else
      this.generateTimeline(this.listSheetResponse[this.state.displaySetting.displaySchedule]
        , this.state.displaySetting.indexStartDS, this.state.displaySetting.indexEndDS)

    if (this.props.viewer2D !== null) {
      var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
        , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
      var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
        , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)

      var time = this.timelinepanel.getCustomTime('testTimeline')
      this.overrideColorLeft(time, this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
      this.overrideColorRight(time, this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)
    }

    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        openPanel: false,
      }
    }))


  }
  handleChangeSchedule = (e, { value }) => {
    this.setState(prevState => ({
      displaySetting: {
        ...prevState.displaySetting,
        displaySchedule: value
      }
    }))

  }

  //#endregion

  //#region // ?tab 4d comparison
  handlePlaySetting = () => {
    var tempDS = []
    var tempMS = []
    var tempInputMS = []
    var tempInputDS = []
    this.listSheetResponse['Master Schedule'].forEach(item => {
      item.columns.forEach(column => {
        if (column.title.toLowerCase().includes('date')) {
          if (!tempMS[column.title]) {
            tempMS[column.title] = { key: column.id, text: column.title, value: column.index };
            tempInputMS.push({ key: column.id, text: column.title, value: column.index })
          }
        }
      })

    })
    this.listSheetResponse['Detail Schedule'].forEach(item => {
      item.columns.forEach(column => {
        if (column.title.toLowerCase().includes('date')) {
          if (!tempDS[column.title]) {
            tempDS[column.title] = { key: column.id, text: column.title, value: column.index }
            tempInputDS.push({ key: column.id, text: column.title, value: column.index })
          }
        }
      })

    })
    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        optionDisplayColumnDS: tempInputDS,
        optionDisplayColumnMS: tempInputMS,
      }
    }))

  }
  handleClosePanePlaySetting() {
    // this.setState({ getListSheet: {openPanel: false} });
    this.setState(prevState => ({
      playSetting: {
        ...prevState.getListSheet,
        openPanel: false,
      }
    }))
  }
  handleChangeColumnL = (e, { value }) => {

    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        displayScheduleL: value
      }
    }))
  }
  handleChangeColumnR = (e, { value }) => {

    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        displayScheduleR: value
      }
    }))
  }
  handleChangeCompareDate = (e, { value }) => {

    this.setState(prevState => ({
      playSetting: {
        ...prevState.playSetting,
        compareDate: value
      }
    }))
  }
  //#endregion

  //#region // ?tab schedule to load
  handleSettingSheet = () => {
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        listSheetDS: [],
        listSheetMS: [],
        settingLoading: true
      }
    }))
    axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: this.props.folderSmartSheetId })
      .then(res => {
        var tempListMS = []; var tempListDS = []
        var tempSavedDS = []; var tempSavedMS = []

        res.data.forEach(item => {
          if (item.name.includes('Master Schedule')) {
            tempListMS.push({ label: item.name, value: { id: item.id, name: item.name } })
            for (var key in this.state.getListSheet.sheetSaved) {
              if (this.state.getListSheet.sheetSaved[key].id === item.id) {
                tempSavedMS.push({ id: item.id, name: item.name })
              }
            }
          } else {
            tempListDS.push({ label: item.name, value: { id: item.id, name: item.name } })
            for (var key in this.state.getListSheet.sheetSaved) {
              if (this.state.getListSheet.sheetSaved[key].id === item.id) {
                tempSavedDS.push({ id: item.id, name: item.name })
              }
            }
          }
        })
        getColumnsStatus(tempSavedDS, this)



        this.setState(prevState => ({
          getListSheet: {
            ...prevState.getListSheet,
            openPanel: true,
            listSheetDS: tempListDS,
            listSheetMS: tempListMS,
            sheetSelectedDS: tempSavedDS,
            sheetSelectedMS: tempSavedMS,
            settingLoading: false
          }
        }))
        message.success(`Sync data successful.`);
      })
      .catch(err => {
        console.log(err)
        this.setState(prevState => ({
          getListSheet: {
            ...prevState.getListSheet,
            settingLoading: false
          }
        }))
        message.error(`Sync data failed.`);
      })
  }
  handleClosePanelListSheet() {
    this.setState({ statusDS: this.state.tempStatusDS })
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        openPanel: false,
      }
    }))
  }
  handleSaveSettingSheet = () => {
    try {
      this.setState(prevState => ({
        getListSheet: {
          ...prevState.getListSheet,
          settingLoading: true
        },
      }))
      // TODO: testing
      var combine = this.state.getListSheet.sheetSelectedDS.concat(this.state.getListSheet.sheetSelectedMS)
      axios.post(`/api/items/savesheets`, { itemId: this.props.itemId, sheetSavedCurrent: combine })
        .then(res => {
          axios.post(`/api/versions/save-status-timeline`, { itemId: this.props.itemId, status: this.state.statusDS })
            .then(res => { })
            .catch(err => message.error(`Save status failed.`))

          this.setState(prevState => ({
            getListSheet: {
              ...prevState.getListSheet,
              openPanel: false,
              sheetSaved: combine
            }
          }))
          this.handleGetSheets(combine)
        }).catch(err => {
          console.log(err)
          this.setState(prevState => ({
            getListSheet: {
              ...prevState.getListSheet,
              openPanel: false,
              settingLoading: false
            }
          }))
          message.error(`Save sheets failed.`)
        })
    }
    catch { }
  }
  handleChangeSheetDS = (value, e) => {
    if (value === null)
      value = []
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        sheetSelectedDS: value,
      }
    }))
    if (value.length !== 0) {
      getColumnsStatus(value, this)
    } else {
      this.setState({ statusDS: [] })

    }
  }
  handleChangeSheetMS = (value, e) => {
    if (value === null)
      value = []
    this.setState(prevState => ({
      getListSheet: {
        ...prevState.getListSheet,
        sheetSelectedMS: value,
      }
    }))
  }
  handleGetSheets = (combine) => {
    axios.post(`/api/smartsheet/getsheets`, { sheetSavedCurrent: combine })
      .then(res => {
        this.listSheetResponse['Master Schedule'] = []
        this.listSheetResponse['Detail Schedule'] = []
        res.data.forEach(item => {
          if (item.name.includes("Master Schedule"))
            this.listSheetResponse['Master Schedule'].push(item)
          else
            this.listSheetResponse['Detail Schedule'].push(item)

        })
        if (this.listSheetResponse['Detail Schedule'].length !== 0) {
          this.generateTimeline(this.listSheetResponse['Detail Schedule'], 1, 2)
          this.setState(prevState => ({
            displaySetting: {
              ...prevState.displaySetting,
              displaySchedule: 'Detail Schedule',
              indexStartDS: 1,
              indexEndDS: 2
            }
          }))
        }
        else {
          this.generateTimeline(this.listSheetResponse['Master Schedule'], 1, 2)
          this.setState(prevState => ({
            displaySetting: {
              ...prevState.displaySetting,
              displaySchedule: 'Master Schedule',
              indexStartMS: 1,
              indexEndMS: 2
            }
          }))
        }
        this.handleDisplaySetting();
        this.setState(prevState => ({
          getListSheet: {
            ...prevState.getListSheet,
            settingLoading: false
          },
          isHaveSheetSave: res.data.length !== 0
        }))
        message.success(`Sync data successful.`);
      })
      .catch(err => {
        console.log(err)
        this.setState(prevState => ({
          displaySetting: {
            ...prevState.displaySetting,
            displaySchedule: 'Detail Schedule',
          }
        }))
        this.setState(prevState => ({
          getListSheet: {
            ...prevState.getListSheet,
            settingLoading: false
          }
        }))
        message.error(`Sync data failed.`);
      })
  }
  //#endregion

  //#region // !general
  sizeChange = (e, da) => {
    if (this.props.displayPanel) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
        if (this.props.viewer2D !== null)
          this.props.viewer2D.resize()
      })
    }
  }
  handleChangeSynchronize = (e, { checked }) => {
    var _this = this
    this.setState({ checkSynchronize: checked, }, () => {
      if (this.state.checkSynchronize) {
        this.props.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.addEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onStateChanged)
        this.props.viewer.addEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, this.onStateChanged)
        this.props.viewer.addEventListener(Autodesk.Viewing.SHOW_EVENT, this.onStateChanged)

      } else {
        this.props.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.removeEventListener(Autodesk.Viewing.EXPLODE_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onStateChanged)
        this.props.viewer.removeEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onStateChanged)
        this.props.viewer.removeEventListener(Autodesk.Viewing.HIDE_EVENT, this.onStateChanged)
        this.props.viewer.removeEventListener(Autodesk.Viewing.SHOW_EVENT, this.onStateChanged)

      }
      this.setState(prevState => ({
        getListSheet: {
          ...prevState.getListSheet,
          openPanel: false,
        }
      }))
    })
  }
  handleDisplayNonProgress = (e, { checked }) => {
    var _this = this
    this.setState({ displayNonProgress: checked }, () => {
      var time = this.timelinepanel.getCustomTime('testTimeline')
      this.props.viewer.show(this.state.allDbIds)
      if (this.state.playSetting.playTwoView === false)
        this.overrideColorTimeline(time, this.props.viewer)
      else {
        this.props.viewer.show(this.state.allDbIds)
        this.props.viewer2D.show(this.state.allDbIds)
        var dataL = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleL]
          , this.state.playSetting.indexStartL, this.state.playSetting.indexEndL)
        var dataR = this.generateDataPlay(this.listSheetResponse[this.state.playSetting.displayScheduleR]
          , this.state.playSetting.indexStartR, this.state.playSetting.indexEndR)

        // this.overrideColorTimeline(time,this.props.viewer)
        this.overrideColorLeft(time, this.props.viewer, dataL, this.state.playSetting.displayScheduleL)
        this.overrideColorRight(time, this.props.viewer2D, dataL, dataR, this.state.playSetting.displayScheduleR)

      }
    })
  }
  onStateChanged = (e) => {
    this.props.viewer2D.restoreState(this.props.viewer.getState(), null, false);
  }
  handleSummaryPanel = () => {
    this.setState({ openDockSummaryPanel: true })
  }
  handleComparePanel = () => {
    this.setState({ openDockComparePanel: true })
  }
  onChangeDockDisplay = (name, value) => {
    if (name === 'summary')
      this.setState({ openDockSummaryPanel: value })
    else
      this.setState({ openDockComparePanel: value })
  }
  handleColorStatus = (item, e) => {
    let temp = this.state.statusDS.slice(0)
    let index = _.findIndex(this.state.statusDS, function (o) { return o.id === item.id })
    temp.splice(index, 1)
    temp.splice(index, 0, { id: item.id, content: item.content, color: e.target.value, check: item.check })
    this.setState({ statusDS: [] }, () => {
      this.setState({ statusDS: temp })
    })
  }
  handleStatusDisplay = (item, e, { checked }) => {
    let temp = this.state.statusDS.slice(0)
    let index = _.findIndex(this.state.statusDS, function (o) { return o.id === item.id })
    temp.splice(index, 1)
    temp.splice(index, 0, { id: item.id, content: item.content, color: item.color, check: checked })
    this.setState({ statusDS: [] }, () => {
      this.setState({ statusDS: temp })
    })
  }
  //#endregion
  handleToogleTimeline = (name) => {
    if (name === 'Maximize') {
      this.setState({ minisizePanel: false, heightDock: 500 })
      $('#splitForgeViewer').height($('#splitForgeViewer').height() - 500)
      this.props.viewer.resize()
      if (this.props.viewer2D !== null)
        this.props.viewer2D.resize()
    } else {
      this.setState({ minisizePanel: true, heightDock: 0 })
      $('#splitForgeViewer').height(this.state.maxForgeViewer - 0)
      this.props.viewer.resize()
      if (this.props.viewer2D !== null)
        this.props.viewer2D.resize()
    }
  }

  render() {
    const { uploading, position, heightDock, playTimeline, minisizePanel,
      getListSheet, playSetting, displaySetting, statusBar } = this.state;
    return (
      <div >
        {(uploading || Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) &&
          <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Button.Group size='small' style={{
          right: '0px', bottom: '0px', position: 'absolute', zIndex: '2',
          display: (this.props.displayPanel ? 'block' : 'none')
        }} vertical>
          <Tooltip placement="left" title='Maximize' >
            <Button positive icon="expand" loading={uploading}
              disabled={(Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) ? true : false}
              onClick={this.handleToogleTimeline.bind(this, 'Maximize')}></Button>
          </Tooltip>

          <Tooltip placement="left" title='Summary'>
            <Button color={playSetting.playTwoView ? 'red' : 'blue'} loading={uploading}
              onClick={playSetting.playTwoView ? this.handleComparePanel : this.handleSummaryPanel} icon="pie chart"
              disabled={(Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) ? true : false} >
            </Button>
          </Tooltip>

          {/* <Tooltip placement="top" title={playTimeline === 'play' ? 'Play' : 'Pause'}>
            <Button color={playSetting.playTwoView ? 'red' : 'blue'}
              disabled={Object.keys(this.tempDataStatus).length === 0 ? true : false}
              onClick={playSetting.playTwoView ? this.animationTimeLineTwoView.bind(this) : this.animationTimeLine.bind(this)}
              icon={playTimeline === 'play' ? 'play' : 'pause'} ></Button>
          </Tooltip> */}
          <Tooltip placement="left" title='Settings'>
            <Button secondary icon="setting" onClick={this.handleSettingPanel}
              disabled={(this.state.geometry2DLoading) ? true : false} loading={uploading} >
            </Button>
          </Tooltip>
          <Tooltip placement="left" title='Sync'>
            <Button secondary onClick={this.handleSync} loading={uploading} icon="sync" ></Button>
          </Tooltip>
        </Button.Group>
        <Dock position={position} isVisible={this.props.displayPanel} dimMode={'none'} onSizeChange={this.sizeChange} fluid={false} zIndex={200}
          size={heightDock}>
          {uploading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
          {!this.state.isHaveSheetSave &&
            <div style={{ width: '100%', height: '100%', zIndex: 1, display: 'flex', position: 'absolute', backgroundColor: 'white', zIndex: 2 }}>
              <span style={{ color: 'black', margin: 'auto', textAlign: 'center', fontSize: 'larger' }}>You need setup smartsheet</span>
            </div>}
          <Button.Group size='small' style={{ right: '0px', position: 'absolute', zIndex: '3', display: (minisizePanel ? 'none' : 'block') }} vertical={position === 'right' ? true : false}>
            <Whisper placement={position === 'bottom' ? 'top' : 'left'} trigger="click" speaker={
              <Popover >
                <Button.Group size='small'>
                  <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Collapse' >
                    <Button positive icon="add" disabled={Object.keys(this.tempDataStatus).length === 0 ? true : false}
                      onClick={() => hideAllGroups(this.groups)}  ></Button>
                  </Tooltip>

                  <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Expand' >
                    <Button positive icon="minus" disabled={Object.keys(this.tempDataStatus).length === 0 ? true : false}
                      onClick={() => showAllGroups(this.groups)}></Button>
                  </Tooltip>
                </Button.Group>
              </Popover>
            }>
              <Button icon="ellipsis horizontal" color='violet'></Button>
            </Whisper>

            {/* <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title={position === 'bottom' ? 'Bottom' : 'Right'} >
              <Button positive onClick={() => (position === 'bottom' ? this.setState({ position: 'right', heightDock: 450 }) : this.setState({ position: 'bottom', heightDock: 250 }))}
                icon="exchange" active={this.state.resizeDock} >
              </Button>
            </Tooltip> */}
            <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Minimize' >
              <Button positive icon="compress"
                disabled={(Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) ? true : false}
                onClick={this.handleToogleTimeline.bind(this, 'Minimize')} ></Button>
            </Tooltip>
            <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Summary'>
              <Button color={playSetting.playTwoView ? 'red' : 'blue'} loading={uploading}
                onClick={playSetting.playTwoView ? this.handleComparePanel : this.handleSummaryPanel} icon="pie chart"
                disabled={(Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) ? true : false} >
              </Button>
            </Tooltip>

            {/* <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title={playTimeline === 'play' ? 'Play' : 'Pause'}>
              <Button color={playSetting.playTwoView ? 'red' : 'blue'}
                disabled={(Object.keys(this.tempDataStatus).length === 0 || this.state.geometry2DLoading) ? true : false}
                onClick={playSetting.playTwoView ? this.animationTimeLineTwoView.bind(this) : this.animationTimeLine.bind(this)}
                icon={playTimeline === 'play' ? 'play' : 'pause'} ></Button>
            </Tooltip> */}

            <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Settings'>
              <Button secondary icon="setting" onClick={this.handleSettingPanel} loading={uploading}
                disabled={(this.state.geometry2DLoading) ? true : false} >
              </Button>
            </Tooltip>

            <Tooltip placement={position === 'bottom' ? 'top' : 'left'} title='Sync'>
              <Button secondary onClick={this.handleSync} loading={uploading} icon="sync" ></Button>
            </Tooltip>
          </Button.Group>
          <div id='timeline-content' style={{ height: '100%' }}>  </div>

        </Dock>

        <Modal show={getListSheet.openPanel} onHide={this.handleClosePanelListSheet.bind(this)} style={{ paddingTop: '3%' }} backdrop="static">
          <Modal.Header>
            <Modal.Title>Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingBottom: '0px', marginTop: '0px' }} id="modal-body-setting-timeline">
            <Tabs defaultActiveKey="1" onChange={this.handleChangeTab} style={{ overflowY: 'auto' }}>
              <TabPane tab="4D Comparison" key="2">
                <Row gutter={16}>
                  <Col span={12} >
                    <Form.Field>
                      <Radio label='Detail Schedule' value='Detail Schedule' checked={playSetting.displayScheduleL === 'Detail Schedule'}
                        onChange={this.handleChangeColumnL} disabled={this.listSheetResponse['Detail Schedule'].length === 0 ? true : false} />
                    </Form.Field>
                    <Form.Field>
                      <Radio label='Master Schedule' value='Master Schedule' checked={playSetting.displayScheduleL === 'Master Schedule'}
                        onChange={this.handleChangeColumnL} disabled={this.listSheetResponse['Master Schedule'].length === 0 ? true : false} />
                    </Form.Field>
                    <Dropdown placeholder='Start Column' fluid selection value={playSetting.indexStartL}
                      options={playSetting.displayScheduleL === 'Master Schedule' ? playSetting.optionDisplayColumnMS : playSetting.optionDisplayColumnDS}
                      onChange={((e, { value }) => {
                        if (value % 2 === 0)
                          value = value - 1
                        this.setState(prevState => ({
                          playSetting: {
                            ...prevState.playSetting,
                            indexStartL: value,
                            indexEndL: value + 1
                          }
                        }))
                      })} />
                    <p />
                    <Dropdown placeholder='End Column' fluid selection value={playSetting.indexEndL} disabled={true}
                      options={playSetting.displayScheduleL === 'Master Schedule' ? playSetting.optionDisplayColumnMS : playSetting.optionDisplayColumnDS}
                    />
                    <p />
                    <Form.Field>
                      <Radio label='Compare Start Date' value='Start Date' checked={playSetting.compareDate === 'Start Date'}
                        onChange={this.handleChangeCompareDate}
                        disabled={(this.props.viewer2D === null) ? true : false} />
                    </Form.Field>
                  </Col>
                  <Col span={12} >
                    <Form.Field>
                      <Radio label='Detail Schedule' value='Detail Schedule' checked={playSetting.displayScheduleR === 'Detail Schedule'}
                        onChange={this.handleChangeColumnR}
                        disabled={(this.listSheetResponse['Detail Schedule'].length === 0 || this.props.viewer2D === null) ? true : false} />
                    </Form.Field>
                    <Form.Field>
                      <Radio label='Master Schedule' value='Master Schedule' checked={playSetting.displayScheduleR === 'Master Schedule'}
                        onChange={this.handleChangeColumnR}
                        disabled={(this.listSheetResponse['Master Schedule'].length === 0 || this.props.viewer2D === null) ? true : false} />
                    </Form.Field>
                    <Dropdown placeholder='Start Column' fluid selection value={playSetting.indexStartR}
                      disabled={this.props.viewer2D === null ? true : false}
                      options={playSetting.displayScheduleR === 'Master Schedule' ? playSetting.optionDisplayColumnMS : playSetting.optionDisplayColumnDS}
                      onChange={((e, { value }) => {
                        if (value % 2 === 0)
                          value = value - 1
                        this.setState(prevState => ({
                          playSetting: {
                            ...prevState.playSetting,
                            indexStartR: value,
                            indexEndR: value + 1
                          }
                        }))
                      })} />
                    <p />
                    <Dropdown placeholder='End Column' fluid selection value={playSetting.indexEndR}
                      disabled={true}
                      options={playSetting.displayScheduleR === 'Master Schedule' ? playSetting.optionDisplayColumnMS : playSetting.optionDisplayColumnDS}
                    />
                    <p />
                    <Form.Field>
                      <Radio label='Compare End Date' value='End Date' checked={playSetting.compareDate === 'End Date'}
                        onChange={this.handleChangeCompareDate}
                        disabled={(this.props.viewer2D === null) ? true : false} />
                    </Form.Field>
                  </Col>
                </Row>
                <p />
                <Checkbox label='Synchronize View' disabled={(this.props.viewer2D === null) ? true : false} checked={this.state.checkSynchronize}
                  onChange={this.handleChangeSynchronize} />
                <div style={{ marginTop: '25px', float: 'right' }} >
                  <Button onClick={this.handlePlayTwoView.bind(this)} color='blue'
                    disabled={this.props.viewer2D === null} loading={this.state.getListSheet.saveSheetsSelected}  >
                    Save</Button>
                  <Button onClick={this.handleClosePanelListSheet.bind(this)} color='red' style={{ marginLeft: '5px' }}>Cancel</Button>

                </div>
              </TabPane>
            </Tabs>
          </Modal.Body>
        </Modal>
        <Modal show={statusBar.openPanel} onHide={this.handleClosePanelStatusBar.bind(this)} style={{ paddingTop: '3%' }} size="xs">
          <Modal.Header>
            <Modal.Title>Status Bar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row >
              <Col span={24} >
                <Button color="blue" onClick={this.handleGoToStart.bind(this)} fluid>Go To Start</Button>
                <p />
                <Button color="red" onClick={this.handleGoToCurrentDate.bind(this)} fluid>Go To Current Date</Button>
                <p />
                <DatePicker placeholder='Go to date' onChange={this.onChangeDateStatusBar.bind(this)} format={'DD-MM-YYYY'} block cleanable={false}
                  value={moment(statusBar.statusBarDate, 'DD-MM-YYYY')} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <div style={{ zIndex: '1', position: 'fixed', top: this.props.heightNavigation === '0px' ? '21.22px' : '75px', left: '45px', display: this.props.displayPanel ? "block" : "none" }}>
          {this.state.loadingStatus ?
            <div>
              <Icon icon="spinner" spin size="1x" />
            </div>
            :
            <div>
              <Checkbox label='Display non-progress element' checked={this.state.displayNonProgress}
                style={{ fontSize: 'small' }} onChange={this.handleDisplayNonProgress} />
              {this.state.statusDS.length !== 0 && this.state.statusDS.map((v, k) =>
                v.check &&
                ((k === this.state.statusDS.length - 2) ?
                  <Header style={{ fontSize: 'small', margin: 0 }}>
                    <Icon icon='circle' style={{ paddingRight: '10px', color: v.color }}></Icon>
                    OnGoing</Header> :
                  (k === this.state.statusDS.length - 1) && <Header style={{ fontSize: 'small', margin: 0 }}>
                    <Icon icon='circle' style={{ paddingRight: '10px', color: v.color }}></Icon>
                    Casted</Header>))

              }
            </div>
          }
        </div>
        {this.state.openDockSummaryPanel &&
          <PlanningSummary openDock={this.state.openDockSummaryPanel} onChangeDockDisplay={this.onChangeDockDisplay} viewer={this.props.viewer}
            volumeCasted={this.state.volumeCasted} volumeNoCast={this.state.volumeNoCast}
            volumeActive={this.state.volumeActive} listElementId={this.state.listElementId} timeCurrent={this.state.timeCurrent} allVolume={this.state.allVolume}
            listSheetSelected={this.listSheetResponse['Detail Schedule']}
          />
        }
        {this.state.openDockComparePanel &&
          <PlanningCompare openDock={this.state.openDockComparePanel} onChangeDockDisplay={this.onChangeDockDisplay} viewer={this.props.viewer}
            volumeCasted={this.state.volumeCasted} volumeNoCast={this.state.volumeNoCast}
            volumeActive={this.state.volumeActive} listElementLeft={this.state.listElementLeft} timeCurrent={this.state.timeCurrent} allVolume={this.state.allVolume}
            volumeCastedCompare={this.state.volumeCastedCompare} volumeNoCastCompare={this.state.volumeNoCastCompare} volumeActiveCompare={this.state.volumeActiveCompare}
            volumeEarlyCompare={this.state.volumeEarlyCompare} volumeDelayCompare={this.state.volumeDelayCompare} listElementRight={this.state.listElementRight}
            volumePPVC={this.state.volumePPVC} displayScheduleL={this.state.playSetting.displayScheduleL} displayScheduleR={this.state.playSetting.displayScheduleR}
            volumeCastedComparePPVC={this.state.volumeCastedComparePPVC} volumeActiveComparePPVC={this.state.volumeActiveComparePPVC}
          />
        }

      </div>
    );
  }
}

export default (BottomBarTimeline)
