import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from "axios";
import { List, message, Comment, Avatar, Typography, Tooltip, Upload, Popover, Icon as IconAntd, Select } from 'antd';
import * as antd from 'antd'
import {
  Button, Icon, IconButton, Drawer, Input, TagPicker, Loader, InputPicker, Divider, InputNumber, ButtonGroup, CheckboxGroup,
  Checkbox, RadioGroup, Radio,Tag
} from 'rsuite';
import * as sematic from 'semantic-ui-react'
import _ from 'lodash'
import moment from 'moment';
import { getPublicUrl, socket,checkWohhupUser } from '../../function/AdminFunction'
import Zoom from 'react-medium-image-zoom'

import { getAllModelsElementdbIdsWithCondition, checkExternalId, getExternalId } from '../../function/ForgeFunction'
import { Menu, Item, MenuProvider, animation, IconFont } from 'react-contexify';
import { convertVector4ColorToHex } from '../../function/TableFunction'
import base64url from 'base64url'
import { v4 as uuidv4 } from 'uuid';
import { Position, Toaster } from "@blueprintjs/core";
import { Scrollbars } from 'react-custom-scrollbars';
const { TextArea } = antd.Input;
const { Text, Paragraph } = Typography
const { Option } = Select;

const statusOptions = [
  { label: 'Open', text: 'Open', value: 'Open' },
  { label: 'In progress', text: 'In progress', value: 'In progress' },
  { label: 'Waiting', text: 'Waiting', value: 'Waiting' },
  { label: 'Close', text: 'Close', value: 'Close' }
]
const propsUpload = {
  name: 'file',
  multiple: false,
  showUploadList: true,

};
const priorityOptions = [
  { label: 'Very urgent', text: 'Very urgent', value: 'Very urgent', color: 'red' },
  { label: 'Urgent', text: 'Urgent', value: 'Urgent', color: 'orange' },
  { label: 'Normal', text: 'Normal', value: 'Normal', color: '#f4d90b' },
  { label: 'Low', text: 'Low', value: 'Low', color: 'blue' },
]
const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
function IssueInformation(props, { setDisabled = () => { } }) {
  const [loading, setLoading] = useState(false)
  const [loadingMember, setLoadingMember] = useState(false)
  const [loadingAttach, setLoadingAttach] = useState(false)
  const [loadingAttachView, setLoadingAttachView] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [listMember, setListMember] = useState([])
  const [valueComment, setValueComment] = useState('')
  const [listAssignViewPoint, setListAssignViewPoint] = useState([])
  const [status, setStatus] = useState('')
  const [percent, setPercent] = useState('')
  const [priority, setPriority] = useState('')
  const [comments, setComments] = useState([])
  const [attachFile, setAttachFile] = useState([])
  const [viewPoint, setViewPoint] = useState({})
  const [imgIssue, setImgIssue] = useState('')
  const [isEditImg, setIsEditImg] = useState(false)
  const [attachElement, setAttachElement] = useState([])
  const [discipline, setDiscipline] = useState([])


  const [disableDescription, setDisableDescription] = useState(true)
  const [description, setDescription] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [disableTitle, setDisableTitle] = useState(true)
  const [title, setTitle] = useState('')
  const [tempTitle, setTempTitle] = useState('')
  const [attachView, setAttachView] = useState([])
  const [commentFile, setCommentFile] = useState([])
  const [descriptionImageMore, setDescriptionImageMore] = useState('')
  const [displayDescriptionImageMore, setDisplayDescriptionImageMore] = useState(false)
  const [imageMoreComment, setImageMoreComment] = useState([])
  const [shareWith, setShareWith] = useState([])
  const [isViewOnly, setIsViewOnly] = useState(true)
  const [checkItemExisting, setCheckItemExisting] = useState(false)
  const [tags, setTags] = useState([])
  const toaster = useRef()

  useEffect(() => {
    if (props.trigger) {
      handleModifyInfoViewPoint()
    }
  }, [props.trigger])

  useEffect(() => {
    if (props.triggerCancel) {
      handleCloseDialog()
    }
  }, [props.triggerCancel])

  useEffect(() => {
    socket.on('realtime-comment', realtimeComment)
    if (!props.newTab) {
      try {
        axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
      } catch { }
    }
    return () => {
      socket.removeListener('realtime-comment', realtimeComment)
    }
  }, [])

  const realtimeComment = (data) => {
    if (data.id === props.viewPoint.id) {
      if (data.item.length === 0)
        setComments(data.item)
      else
        getCommentImageUrl(data.item)
    }
    // props.handleNewComment(data)
  }
  const handleCloseDialog = () => {
    setLoading(true)
    if (props.newTab) {
      axios.get("/api/issue/get-issue", { params: { label: props.viewPoint.id } })
        .then(res => {
          axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
            .then(() => {
              props.handleClose(null)
            })
            .catch(() => {
              props.handleClose(null)
            })
        })
        .catch(() => {
          axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
            .then(() => {
              props.handleClose(null)
            })
            .catch(() => {
              props.handleClose(null)
            })

        })
    } else {
      let temp = props.listSaveView.slice(0)
      axios.get("/api/issue/get-issue", { params: { label: props.viewPoint.id } })
        .then(res => {
          let index = _.findIndex(temp, e => { return e.id === props.viewPoint.id })
          if (index >= 0) {
            temp[index] = res.data.issue
            axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
              .then(() => {
                props.handleClose(temp)
              })
              .catch(() => {
                props.handleClose(temp)
              })
          }
        })
        .catch(() => {
          axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
            .then(() => {
              props.handleClose(null)
            })
            .catch(() => {
              props.handleClose(null)
            })

        })
    }
  }

  useEffect(() => {
    setLoading(true)
    setLoadingMember(true)
    axios.post('/api/users/get-me-information', { token: props.userInfo.token })
      .then(async res => {
        if (res.data.user.email !== props.userInfo.email) return
        let company = null
        let companyType = null
        if (res.data.userInfo.contractors.length !== 0) {
          let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === props.projectId })
          if (res.data.user.email !== 'admin@wohhup.com') {
            company = res.data.userInfo.contractors[index].company
          }
          let detailCompanies = await axios.post(`/api/projects/get-information-project`, { projectId: props.projectId, token: props.userInfo.token })
          let index1 = _.findIndex(detailCompanies.data.listContractor, v => { return v.company === company })
          if (index1 >= 0) {
            companyType = detailCompanies.data.listContractor[index1].companyType
          }
        }

        axios.get("/api/issue/get-issue", { params: { label: props.viewPoint.id } })
          .then(res => {
            let issue = res.data.issue
            issue.shareWith = issue.shareWith ? (_.isArray(issue.shareWith) ? issue.shareWith : ['Wohhup']) : ['Wohhup']
            if (props.userInfo.email === 'admin@wohhup.com') {
              setIsViewOnly(false)
            } else if ( checkWohhupUser( props.userInfo.email)) {
              if (issue.shareWith.includes('Wohhup'))
                setIsViewOnly(false)
            } else if (issue.shareWith.includes(companyType)) {
              setIsViewOnly(false)
              // if (props.userInfo.email.includes('@wohhup.com')) {
              //   setIsViewOnly(false)
              // } else if (companyType === 'Sub-con') {
              //   setIsViewOnly(false)
              // }
            }

            setStatus(issue.status)
            setPercent(issue.percent)
            setPriority(issue.priority)

            setAttachFile(issue.attachFile)
            // setImgIssue(issue.img)
            setDescription(issue.descriptionViewPoint)
            setTitle(issue.titleViewPoint)
            setViewPoint(issue)
            setAttachElement(issue.attachElement ? issue.attachElement : [])
            setDiscipline(issue.discipline ? issue.discipline : [])
            setLoading(false)
            getAttachViewImageUrl(issue.attachView)
            getCommentImageUrl(issue.comments)
            setShareWith(issue.shareWith ? (_.isArray(issue.shareWith) ? issue.shareWith : ['Wohhup']) : ['Wohhup'])
            setTags(issue.tags ? issue.tags : [])
            getPublicUrl(issue.img)
              .then(res => {
                setImgIssue(res)
              })
              .catch(err => {
                setImgIssue(err)
              })
            axios.post('/api/issue/check-item', { token: props.userInfo.token, itemId: issue.mainModel.itemId })
              .then(res1 => {
                setCheckItemExisting(res1.data)

              })

            axios.get(`/api/projects/members?q=${props.projectId}`)
              .then(res => {
                let temp = []
                res.data.members.sort()
                res.data.members.splice(0, 0, props.userCreatedProject)
                _.forEach(res.data.members, v => {
                  if (v !== props.email || v !== issue.userCreated)
                    temp.push({
                      label: v,
                      value: v,
                      role: 'user'
                    })
                })
                _.forEach(res.data.groupUser, v => {
                  temp.push({
                    label: v.name,
                    value: v.name,
                    role: 'group'
                  })
                })
                let tempListAssign = []
                _.forEach(issue.listAssignViewPoint, v => {
                  let index = _.findIndex(temp, e => { return e.label === v })
                  if (index >= 0)
                    tempListAssign.push(v)
                })
                setListMember(temp)
                setListAssignViewPoint(tempListAssign)
    
                setLoadingMember(false)
              })
              .catch(() => {
                setLoadingMember(false)
              })
          })
          .catch(() => {
            setLoading(false)
            props.handleClose(null)
            message.warning(`Can't get issue`)
          })

      })
      .catch(() => {

      })


  }, [])


  const getAttachViewImageUrl = (attachView) => {
    let temp = attachView.slice()
    let count = temp.length
    _.forEach(temp, comment => {
      getPublicUrl(comment.img)
        .then(res => {
          comment.img = res
          count--
          if (count === 0) {
            setAttachView(temp)
          }
        })
        .catch(err => {
          comment.img = err
          count--
          if (count === 0) {
            setAttachView(temp)
          }
        })

    })

  }

  const getCommentImageUrl = (comments) => {
    let temp = comments.slice()
    let count = temp.length
    _.forEach(temp, comment => {
      if (comment.avatar === '') {
        comment.avatar = ''
        count--
        if (count === 0) {
          setComments(temp)
        }
      } else {
        getPublicUrl(comment.avatar)
          .then(res => {
            comment.avatar = res
            count--
            if (count === 0) {
              setComments(temp)
            }
          })
          .catch(err => {
            comment.avatar = err
            count--
            if (count === 0) {
              setComments(temp)
            }
          })

      }

    })

  }

  const handleModifyInfoViewPoint = () => {
    if (shareWith.length === 0) {
      message.warning('You have to share issue with at least one company type')
      props.handleClose(props.listSaveView)
      return
    }
    setLoading(true)
    let temp = []
    var today = moment(Date.now()).format('DD-MM-YYYY')
    let tempData = null
    if (!props.newTab) {
      _.forEach(props.listSaveView, v => {
        if (v.id === props.viewPoint.id) {
          v.titleViewPoint = title
          v.descriptionViewPoint = description
          v.listAssignViewPoint = listAssignViewPoint
          v.status = status
          v.userModified = props.email
          v.dateModified = today
          v.comments = comments
          v.percent = percent
          v.priority = priority
          v.img = imgIssue
          v.attachElement = attachElement
          v.discipline = discipline
          v.shareWith = shareWith
          v.tags = tags
          tempData = v
        }
        temp.push(v)
      })
    } else {
      viewPoint.titleViewPoint = title
      viewPoint.descriptionViewPoint = description
      viewPoint.listAssignViewPoint = listAssignViewPoint
      viewPoint.status = status
      viewPoint.userModified = props.email
      viewPoint.dateModified = today
      viewPoint.comments = comments
      viewPoint.percent = percent
      viewPoint.priority = priority
      viewPoint.img = imgIssue
      viewPoint.attachElement = attachElement
      viewPoint.discipline = discipline
      viewPoint.shareWith = shareWith
      viewPoint.tags = tags
    }
    let path = `${window.location.origin}`
    axios.post(`/api/issue/update-issue`, { projectId: props.projectId, label: viewPoint.id, issue: !props.newTab ? tempData : viewPoint, isEditImg: isEditImg, baseUrl: path })
      .then(res => {
        setLoading(false)
        setIsEditImg(false)
        if (!props.newTab) {
          let index = _.findIndex(temp, e => { return e.id === props.viewPoint.id })
          if (index >= 0) {
            temp[index] = res.data
            axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email })
              .then(() => {
                props.handleClose(temp)
              })
              .catch(() => {
                props.handleClose(temp)
              })
            // props.handleClose(temp)
          }
        }

        message.success(`Modified issue.`);
      })
      .catch(() => {
        setLoading(false)
        // setIsEditImg(false)
        message.error(`Can't modified issue.`);
      })
  }
  const handleCustomRequest = ({ file }) => {
    if (file !== null) {
      let path = `${window.location.origin}`
      let data = new FormData()
      data.append('file', file)
      data.append('label', props.viewPoint.id)
      data.append('baseUrl', path)
      data.append('email', props.email)
      if (data !== undefined || data !== null) {
        setLoadingAttach(true)
        axios.post(`/api/issue/attach-file-to-issue`, data, { timeout: 60 * 10 * 1000 })
          .then(res => {
            setAttachFile(res.data)
            setLoadingAttach(false)
          })
          .catch(() => {
            setLoadingAttach(false)
            message.error('Attach file was failed')
          })
      }

    }
  }
  const handleRemoveFile = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setLoadingAttach(true)
    let path = `${window.location.origin}`
    axios.post(`/api/issue/remove-file-from-issue`, { label: viewPoint.id, fileName: file.name, email: props.email, baseUrl: path })
      .then(res => {
        setAttachFile(res.data)
        setLoadingAttach(false)
      })
      .catch(() => {
        setLoadingAttach(false)
        message.error('Remove file was failed')
      })
  }
  const beforeUpload = (file) => {
    const isImgIssue = file.name === `${viewPoint.id}.png`
    if (isImgIssue) {
      message.warning('Please change name your image');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.warning('Image must smaller than 20MB!');
    }
    return isLt2M && !isImgIssue;
  }
  const handleModifyAssigne = (value) => {
    if (value === null)
      setListAssignViewPoint([])
    else
      setListAssignViewPoint(value)
    setDisabled(false)
  }
  const handleModifyStatus = (value) => {
    setStatus(value)
    setDisabled(false)
  }
  const handleModifyPriority = (value) => {
    setPriority(value)
    setDisabled(false)
  }
  const handleModifyPercent = (value) => {
    setPercent(_.toNumber(value))
    setDisabled(false)
  }
  const handleChangeComment = (e) => {
    setValueComment(e.target.value)
  }
  const handleGetAttachElement = () => {
    const models = props.viewer.impl.modelQueue().getModels()
    let tempSelection = []
    let count = attachElement.length
    _.forEach(attachElement, async model => {
      let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === model.itemId })
      if (index >= 0) {
        let instanceTree = models[index].getData().instanceTree;
        let tempIds = getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, models[index], 'all');
        let externalId = await checkExternalId(tempIds, models[index], model.guid)
        tempSelection.push({ model: models[index], ids: externalId })
      }
      count--
      if (count === 0) {
        props.viewer.impl.selector.setAggregateSelection(tempSelection)
      }
    })
    // props.viewer.impl.selector.setAggregateSelection()
  }
  const handleSetAttachElement = () => {
    if (attachElement.length !== 0)
      if (!window.confirm('Are you sure to override elements?')) return
    let temp = []
    var currSelection = props.viewer.impl.selector.getAggregateSelection()
    let count = currSelection.length
    _.forEach(currSelection, async item => {
      let guid = await getExternalId(item)
      temp.push({ itemId: item.model.myData.loadOptions.itemId, guid: guid })
      count--
      if (count === 0) {
        setAttachElement(temp)
        setDisabled(false)
      }
    })


  }

  const handleChangeDiscipline = (value) => {
    setDiscipline(value)
    setDisabled(false)
  }


  //#region //!title
  const handleDisableTitle = () => {
    setTempTitle(title)
    setDisableTitle(!disableTitle)
  }
  const handleConfirmTitle = () => {
    if (viewPoint.titleViewPoint !== tempTitle) {
      if (!window.confirm('Are you sure to title description')) return
      setTitle(tempTitle)
      setDisabled(false)
    }
    setDisableTitle(true)
  }
  const handleRefreshTitle = () => {
    if (!window.confirm('Are you sure to change title')) return
    setTitle(viewPoint.titleViewPoint)
  }
  const handleChangeTitle = (value) => {
    setTempTitle(value)
  }
  const handleCloseEditTitle = () => {
    setDisableTitle(true)
  }
  //#endregion

  //#region //!description
  const handleDisableDescription = () => {
    setTempDescription(description)
    setDisableDescription(!disableDescription)
  }
  const handleConfirmDescription = () => {
    if (viewPoint.descriptionViewPoint !== tempDescription) {
      if (!window.confirm('Are you sure to change description')) return
      setDescription(tempDescription)
      setDisabled(false)
    }
    setDisableDescription(true)
  }
  const handleRefreshDescription = () => {
    if (!window.confirm('Are you sure to refresh description')) return
    setDescription(viewPoint.descriptionViewPoint)
  }
  const handleChangeDescription = (e) => {
    setTempDescription(e.target.value)
  }
  const handleCloseEditDescription = () => {
    setDisableDescription(true)
  }
  //#endregion

  //#region //! comment
  const handleEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].edit = true
      temp[index].tempContent = temp[index].content
      //  console.log(moment(temp[index].datetime, 'lll').add(1, 'hours'))
      //  console.log( moment(Date.now()))
    }
    setComments(temp)

  }
  const handleConfirmEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      if (temp[index].tempContent.length === 0) return
      if (temp[index].tempContent !== temp[index].content) {
        if (!window.confirm('Are you sure to change comment')) return
        setSubmitting(true)
        var comment = {
          author: temp[index].author,
          content: temp[index].tempContent,
          datetime: temp[index].datetime,
          avatar: `userInfo/${base64url(temp[index].author)}/avatar.png`,
          type: temp[index].type,
          files: temp[index].files,
          guid: temp[index].guid,
          images: temp[index].images
        }
        let path = `${window.location.origin}`
        axios.post(`/api/issue/update-comment-issue`, { projectId: props.projectId, label: viewPoint.id, comment: comment, baseUrl: path, email: props.email })
          .then(res => {
            getCommentImageUrl(res.data)
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
            message.error(`Can't edit comment`);
          })
      } else {
        temp[index].edit = false
        setComments(temp)
      }
    }
  }

  const handleAddComment = () => {
    let path = `${window.location.origin}`
    if (commentFile.length === 0) {
      if (!valueComment) {
        return;
      }
      var comment = {
        author: props.email,
        content: valueComment,
        datetime: 0,
        avatar: `userInfo/${base64url(props.email)}/avatar.png`,
        type: 'string',
        files: [],
        guid: "",
        images: imageMoreComment,
      }
      setSubmitting(true)
      axios.post(`/api/issue/add-comment-issue`, {
        projectId: props.projectId, label: viewPoint.id, comment: comment, baseUrl: path,
        token: props.userInfo.token
      })
        .then(res => {
          axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email }).catch(() => { })
          getCommentImageUrl(res.data)
          setValueComment('')
          setCommentFile([])
          setImageMoreComment([])
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't add comment`);
        })
    } else {
      var comment = {
        author: props.email,
        content: valueComment,
        datetime: 0,
        avatar: `userInfo/${base64url(props.email)}/avatar.png`,
        type: 'link',
        files: [],
        guid: "",
        images: imageMoreComment,
      }
      let formData = new FormData()
      _.forEach(commentFile, f => {
        formData.append('files', f.file)
      })
      formData.append('label', props.viewPoint.id)
      formData.append('projectId', props.projectId)
      formData.append('baseUrl', path)
      formData.append('comment', JSON.stringify(comment))
      formData.append('token', props.userInfo.token)
      if (formData !== undefined || formData !== null) {
        setSubmitting(true)
        axios.post(`/api/issue/add-comment-issue-file`, formData, { timeout: 60 * 10 * 1000 })
          .then(res => {
            axios.post("/api/issue/set-issue-unread", { label: props.viewPoint.id, email: props.email }).catch(() => { })
            getCommentImageUrl(res.data)
            setValueComment('')
            setCommentFile([])
            setImageMoreComment([])
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
            message.error(`Can't add comment`);
          })
      }
    }

  }

  const handleLinkFileComment = ({ file }) => {
    if (file !== null) {
      setSubmitting(true)
      let temp = commentFile.slice()
      let index = _.findIndex(temp, e => { return e.name === file.name })
      if (index < 0) {
        temp.push({ uid: temp.length + 1, name: file.name, url: '', status: 'done', file: file })
      } else {
        temp[index].file = file
      }
      setCommentFile(temp)
      setSubmitting(false)
    }
  }

  const handleRemoveLinkFileComment = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setSubmitting(true)
    let temp = commentFile.slice()
    let index = _.findIndex(temp, e => { return e.name === file.name })
    if (index >= 0)
      temp.splice(index, 1)
    setCommentFile(temp)
    setSubmitting(false)
  }

  const handleCloseEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].edit = false
    }
    setComments(temp)
  }

  const handleChangeEditComment = (guid, e) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].tempContent = e.target.value
    }
    setComments(temp)
  }
  const handleRemoveCommentFile = (guid, fileName) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      let path = `${window.location.origin}`
      axios.post(`/api/issue/remove-attach-comment-issue`, { projectId: props.projectId, label: viewPoint.id, comment: temp[index], baseUrl: path, email: props.email, fileName: fileName })
        .then(res => {
          getCommentImageUrl(res.data)
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't remove attach file`);
        })
    }
    setComments(temp)
  }
  const handleRemoveComment = (guid) => {
    if (!window.confirm('Are you sure to remove this comment?')) return
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      let path = `${window.location.origin}`
      axios.post(`/api/issue/delete-comment-issue`, { projectId: props.projectId, label: viewPoint.id, comment: temp[index], baseUrl: path, email: props.email })
        .then(res => {
          if (res.data.length === 0) {
            setComments(res.data)
          } else {
            getCommentImageUrl(res.data)
          }
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't delete comment`);
        })
    }
  }
  //#endregion
  const handleDownload = (file) => {
    getPublicUrl(file.url, 1000 * 5)
      .then(res => {
        let link = document.createElement('a');
        link.href = res;
        link.target = "_blank"
        link.click();
      })
  }
  const handleDownloadComment = (url) => {
    getPublicUrl(url, 1000 * 5)
      .then(res => {
        let link = document.createElement('a');
        link.href = res;
        link.target = "_blank"
        link.click();
      })
  }
  const handleScreenShot = () => {
    setLoadingAttachView(true)
    setDisplayDescriptionImageMore(false)
    let guid = uuidv4()
    let path = `${window.location.origin}`
    let data = {}
    let node = props.viewer.impl.model.getDocumentNode()
    const models = props.viewer.impl.modelQueue().getModels()
    data.identify = guid
    data.guid = node.data.guid
    data.viewableID = node.data.viewableID
    data.viewName = node.data.name
    data.userCreated = props.email
    data.viewPort = props.viewer.getState()
    data.description = descriptionImageMore
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        data.mainModel = {
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        }
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    data.otherModel = tempOtherModel

    let count = models.length
    let count1 = 0
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count1 = count1 + temp.length
      _.forEach(temp, id => {
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            data.attachElementColor = tempElement
            let screenshot = new Image();
            screenshot.src = props.viewer.canvas.toDataURL("image/png");
            screenshot.onload = () => {
              let canvas = document.getElementById('snapshot');
              canvas.width = props.viewer.canvas.clientWidth;
              canvas.height = props.viewer.canvas.clientHeight;
              let ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

              try {
                let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                markupCore.renderToCanvas(ctx, () => {
                  data.img = canvas.toDataURL()
                  axios.post(`/api/issue/attach-view-to-issue`, { label: viewPoint.id, data: data, baseUrl: path, email: props.email })
                    .then(res => {
                      if (res.data.length === 0) {
                        setAttachView(res.data)
                      } else {
                        getAttachViewImageUrl(res.data)
                      }
                      setLoadingAttachView(false)
                      setDescriptionImageMore('')
                    })
                    .catch(() => {
                      setLoadingAttachView(false)
                      message.error(`Can't attach view`);
                    })
                });
              } catch {
                data.img = screenshot.src
                axios.post(`/api/issue/attach-view-to-issue`, { label: viewPoint.id, data: data, baseUrl: path, email: props.email })
                  .then(res => {
                    if (res.data.length === 0) {
                      setAttachView(res.data)
                    } else {
                      getAttachViewImageUrl(res.data)
                    }
                    setLoadingAttachView(false)
                    setDescriptionImageMore('')
                  })
                  .catch(() => {
                    message.error(`Can't attach view`);
                    setLoadingAttachView(false)
                  })
              }

            }
          }
        })
      })
      count--
    })
  }
  const handleDeleteAttachView = (e) => {
    let temp = attachView.slice()
    let index = _.findIndex(temp, v => { return v.guid === e.props.item.guid })
    if (index >= 0) {
      if (temp[index].userCreated === props.email) {
        let path = `${window.location.origin}`
        axios.post(`/api/issue/remove-view-from-issue`, { label: viewPoint.id, data: e.props.item, baseUrl: path, email: props.email })
          .then(res => {
            if (res.data.length === 0) {
              setAttachView(res.data)
            } else {
              getAttachViewImageUrl(res.data)
            }
          })
          .catch(() => {
            message.error(`Can't delete view`);
          })
      } else {
        message.warning(`You can't delete this view, because you wasn't created it`)
      }
    }
  }
  // const handleInfoImageMore = (e) => {
  //   let item = e.props.item
  //   toaster.current.show({
  //     timeout: 0,
  //     icon: "info-sign",
  //     message: (
  //       <>
  //         <Text strong >
  //           {`User: `}
  //         </Text>
  //         <Text  >
  //           {item.userCreated}
  //         </Text>
  //         <br />
  //         <Text strong>
  //           {`Description:`}
  //         </Text>
  //         <br />
  //         <Text style={{ whiteSpace: 'pre-wrap' }}>
  //           {`${item.description}`}
  //         </Text>
  //       </>
  //     )
  //   }, item.identify)
  // }

  const handleScreenShotComment = () => {
    let guid = uuidv4()
    let data = {}
    let tempData = imageMoreComment.slice()
    let node = props.viewer.impl.model.getDocumentNode()
    const models = props.viewer.impl.modelQueue().getModels()
    data.identify = guid
    data.guid = node.data.guid
    data.viewableID = node.data.viewableID
    data.viewName = node.data.name
    data.viewPort = props.viewer.getState()
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        data.mainModel = {
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        }
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    data.otherModel = tempOtherModel

    let count = models.length
    let count1 = 0
    let tempElement = []
    _.forEach(models, async model => {
      let instanceTree = model.getData().instanceTree;
      let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, props.viewer, model, 'all')
      count1 = count1 + temp.length
      _.forEach(temp, id => {
        // let isHide = instanceTree.isNodeHidden(id)
        model.getProperties(id, (modelAProperty) => {
          instanceTree.enumNodeFragments(id, (frag) => {
            let renderProxy = props.viewer.impl.getRenderProxy(model, frag)
            if (renderProxy.themingColor) {
              let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
              let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
              if (index < 0)
                tempElement.push({
                  itemId: model.myData.loadOptions.itemId, guid: modelAProperty.externalId, color: colorCurrent
                })
            }
          })
          count1--
          if (count1 === 0 && count === 0) {
            data.attachElementColor = tempElement
            let screenshot = new Image();
            screenshot.src = props.viewer.canvas.toDataURL("image/png");
            screenshot.onload = () => {
              let canvas = document.getElementById('snapshot');
              canvas.width = props.viewer.canvas.clientWidth;
              canvas.height = props.viewer.canvas.clientHeight;
              let ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

              try {
                let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
                markupCore.renderToCanvas(ctx, () => {
                  data.img = canvas.toDataURL()
                  tempData.push(data)
                  setImageMoreComment(tempData)

                });
              } catch {
                data.img = screenshot.src
                tempData.push(data)
                setImageMoreComment(tempData)
              }
            }
          }
        })
      })
      count--
    })
  }
  const handleDeleteAttachViewComment = (e) => {
    let temp = imageMoreComment.slice()
    let index = _.findIndex(temp, v => { return v.guid === e.props.item.guid })
    if (index >= 0) {
      temp.splice(index, 1)
      setImageMoreComment(temp)
    }
  }
  const handleDescriptionImageMore = (e) => {
    setDescriptionImageMore(e.target.value)
  }
  const handleChangeImageMorePanel = (visible) => {
    setDisplayDescriptionImageMore(visible)
  }
  const handleChangeShareWith = (value) => {
    setShareWith(value)
  }
  const handleChangeTags = (value) => {
    setTags(value)
  }
  return (
    <>
      <Scrollbars
        renderView={renderView}
        autoHide autoHideTimeout={1000}
        autoHideDuration={200}
        thumbMinSize={30}
        universal={true}>

        <Tooltip title={'Right click to select version of model to view'}  >
          <div style={{ position: 'relative', height: '180px', width: 'fit-content' }}>
            {(viewPoint.mainModel) &&
              <MenuProvider id={viewPoint.mainModel.itemId === props.itemId ? 'info_issue_menu' : 'info_other_issue_menu'}
                animation={animation.zoom} data={{ item: viewPoint }}>
                <Zoom transitionDuration={0} zoomMargin={0} portalEl={document.getElementById('zoom-issue-image')}>
                  {imgIssue !== '' && <sematic.Image src={imgIssue} />}
                </Zoom>
              </MenuProvider>}


            {/* {!props.isProject &&
                <IconButton style={{ top: 0, position: 'absolute', display: 'block' }} disabled={props.email !== viewPoint.userCreated}
                size="xs" icon={<Icon icon="edit" />}
                 onClick={handleChangeImg} />} */}
          </div>
        </Tooltip>

        <Divider className='custom-divider' />
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Title:{' '}</Text>
        {props.email === viewPoint.userCreated &&
          <ButtonGroup style={{ float: 'right' }}>
            {disableTitle &&
              <>
                <IconButton size="xs" icon={<Icon icon={"edit"} />} onClick={handleDisableTitle} />
                <IconButton size="xs" icon={<Icon icon='refresh' />} onClick={handleRefreshTitle} disabled={viewPoint.titleViewPoint === title} />
              </>
            }
            {!disableTitle &&
              <>
                <IconButton size="xs" icon={<Icon icon={'check'} />} color={'blue'} onClick={handleConfirmTitle} />
                <IconButton size="xs" icon={<Icon icon='close' />} color={'red'} onClick={handleCloseEditTitle} />
              </>
            }
          </ButtonGroup>
        }
        {disableTitle ?
          <p style={{ whiteSpace: 'pre-wrap' }}>{title} </p>
          :
          <Input value={tempTitle} size='sm' style={{ marginBottom: '1em' }} onChange={handleChangeTitle} />
        }

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Description:{' '}</Text>
        {props.email === viewPoint.userCreated &&
          <ButtonGroup style={{ float: 'right' }}>
            {disableDescription &&
              <>
                <IconButton size="xs" icon={<Icon icon={"edit"} />} onClick={handleDisableDescription} />
                <IconButton size="xs" icon={<Icon icon='refresh' />} onClick={handleRefreshDescription} disabled={viewPoint.descriptionViewPoint === description} />
              </>
            }
            {!disableDescription &&
              <>
                <IconButton size="xs" icon={<Icon icon={'check'} />} color={'blue'} onClick={handleConfirmDescription} />
                <IconButton size="xs" icon={<Icon icon='close' />} color={'red'} onClick={handleCloseEditDescription} />
              </>
            }
          </ButtonGroup>
        }
        {disableDescription ?
          <p style={{ whiteSpace: 'pre-wrap' }}>{description} </p>
          :
          <TextArea value={tempDescription} autoSize size='small' style={{ marginBottom: '1em' }} onChange={handleChangeDescription} />
        }

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}>Discipline</Text>
        <CheckboxGroup
          inline
          value={discipline}
          onChange={handleChangeDiscipline}
          style={{ marginBottom: '1em' }}

        >
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={"Archi"}>Archi</Checkbox>
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={"C&S"}>{`C&S`}</Checkbox>
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={'M&E'}>{`M&E`}</Checkbox>
        </CheckboxGroup>

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Due date</Text>
        <Paragraph >{moment(viewPoint.dateViewPoint).format('DD-MM-YYYY')} </Paragraph>

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}>Share with</Text>
        <br />
        <CheckboxGroup
          inline
          value={shareWith}
          onChange={handleChangeShareWith}
          style={{ marginBottom: '1em' }}
        >
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={"Wohhup"}>Wohhup</Checkbox>
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={"Sub-con"}>{`Sub-con`}</Checkbox>
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={'Consultant'}>{`Consultant`}</Checkbox>
          <Checkbox disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value={'Client'}>{`Client`}</Checkbox>
          <Checkbox disabled defaultChecked={true}>Assignee</Checkbox>
        </CheckboxGroup>
        {/* <RadioGroup inline
          onChange={handleChangeShareWith}
          value={shareWith}
          style={{ marginBottom: '1em' }}>
          <Radio disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value="All">All</Radio>
          <Radio disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value="Wohhup">Wohhup</Radio>
          <Radio disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly} value="Wohhup & Sub-con">Wohhup & Sub-con</Radio>
        </RadioGroup>
        <br /> */}

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Assignees</Text>
        <TagPicker value={listAssignViewPoint} data={listMember} onChange={handleModifyAssigne}
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly}
          style={{ width: '100%', marginBottom: '1em' }} placement={props.isCoordinationApp ? 'leftEnd' : 'rightEnd'} size="sm" groupBy='role'
          renderMenu={menu => {
            if (loadingMember) {
              return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                  <Icon icon="spinner" spin /> Loading...
                </p>
              );
            }
            return menu;
          }}
          renderMenuItem={(label, item) => {
            return (
              <div>
                {/* <i className={item.role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} */}
                <Icon icon={item.role === 'user'?'user':'group'} /> {label}
              </div>
            );
          }}
          renderMenuGroup={(label, item) => {
            return (
              <div>
                {/* <i className={item.children[0].role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} - (
                {item.children.length}) */}
                <Icon icon={item.children[0].role === 'user'?'user':'group'} />  {label} - (
                {item.children.length})
              </div>
            );
          }}
          renderValue={(values, items, tags) => {
            return items.map((tag, index) => (
              <Tag style={{ color: tag.role === 'user' ? '#8ab4f8' : '#ff7171' }}>
                    <Icon icon={tag.role === 'user'?'user':'group'} /> {tag.label}
                </Tag> 
            ));
          }}
           />

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Status</Text>
        <InputPicker
          data={statusOptions} size='sm' cleanable={false}
          placeholder="Select Status" onChange={handleModifyStatus}
          value={status} placement={props.isCoordinationApp ? 'leftEnd' : 'rightEnd'}
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly}
          style={{ marginBottom: '1em' }} block
          renderValue={(value) => {
            return (
              <div style={{ color: 'gray' }}>
                {value}
              </div>
            );
          }}
        />
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Completion %</Text>
        <InputNumber postfix="%" size="sm" appearance="subtle" onChange={handleModifyPercent} style={{ marginBottom: '1em' }}
          step={1} min={0} max={100}
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : listAssignViewPoint.includes(props.email) ? false : isViewOnly}
          value={percent} />

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Type</Text>
        <Paragraph >{viewPoint.type} </Paragraph>
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}>Priority</Text>
        <InputPicker
          data={priorityOptions} size='sm' cleanable={false}
          placeholder="Select Priority" onChange={handleModifyPriority}
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly}
          value={priority} placement={props.isCoordinationApp ? 'leftEnd' : 'rightEnd'}
          style={{ marginBottom: '1em' }} block
          renderMenuItem={(label, item) => {
            return (
              <div >
                <i className="rs-icon rs-icon-exclamation-triangle" style={{ color: item.color }} /> {label}
              </div>
            );
          }}
          renderValue={(value, item) => {
            return (
              <div style={{ color: 'gray' }} >
                {/* <i className="rs-icon rs-icon-exclamation-triangle" color={item?item.color:} /> {value} */}
                <Icon icon={'exclamation-triangle'} style={{color:item?item.color:''}} /> {value}
              </div>
            );
          }}
        />
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}>Tags</Text>
        <Select mode="tags" style={{ width: '100%', marginBottom: '1em' }} placeholder="Tags" onChange={handleChangeTags} value={tags}
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : props.email !== viewPoint.userCreated || isViewOnly}>
          {props.tagsOption && props.tagsOption.map((tag, k) =>
            <Option key={tag.toString()}>{tag.toString()}</Option>)}
        </Select>

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Created</Text>
        <Tooltip title={moment(viewPoint.dateCreated).format('DD-MM-YYYY HH:mm')}>
          <Paragraph >{`${moment(viewPoint.dateCreated).format('DD-MM-YYYY')} by ${viewPoint.userCreated}`} </Paragraph>
        </Tooltip>


        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Modified</Text>
        <Tooltip title={moment(viewPoint.dateModified).format('DD-MM-YYYY HH:mm')}>
          <Paragraph >{`${moment(viewPoint.dateModified).format('DD-MM-YYYY')} by ${viewPoint.userModified}`} </Paragraph>
        </Tooltip>

        <Divider className='custom-divider' />
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Attachments</Text>
        <Upload {...propsUpload} className='upload-attachments'
          disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : isViewOnly}
          customRequest={handleCustomRequest} fileList={attachFile} onRemove={handleRemoveFile} beforeUpload={beforeUpload} onPreview={handleDownload}>
          <Button appearance="ghost" block loading={loadingAttach}>
            <Icon icon="link" /> Upload
          </Button>
        </Upload>

        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Attach View</Text>
        {!props.isProject &&
          <Popover visible={displayDescriptionImageMore} onVisibleChange={handleChangeImageMorePanel}
            content={
              <TextArea size='small' autoSize={{ minRows: 3 }} style={{ width: 400 }} onChange={handleDescriptionImageMore} value={descriptionImageMore}
                disabled={loadingAttachView} />
            }
            title={
              <>
                <Text strong>Description</Text>
                <IconButton style={{ float: 'right' }} size="xs" loading={loadingAttachView}
                  icon={<Icon icon="plus-circle" />} color='blue' onClick={handleScreenShot}></IconButton>
              </>
            } trigger="click">
            <Button appearance="ghost" size='sm' block style={{ marginBottom: 5 }} loading={loadingAttachView}
              disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : listAssignViewPoint.includes(props.email) ? false : isViewOnly}>
              <Icon icon="camera" /> Screenshot
                 </Button>
          </Popover>
        }
        {attachView.length !== 0 ?
          <div style={{ padding: 7 }}>
            <List
              grid={{ gutter: [8, 8], column: 3 }}
              dataSource={attachView}
              renderItem={item => (

                <Tooltip mouseEnterDelay={2} overlayClassName='test' title={(
                  <>
                    <Text strong style={{ color: 'white' }}>
                      {`User: `}
                    </Text>
                    <Text style={{ color: 'white' }} >
                      {item.userCreated}
                    </Text>
                    <br />
                    <Text strong style={{ color: 'white' }}>
                      {`Description:`}
                    </Text>
                    <br />
                    <Text style={{ whiteSpace: 'pre-line', color: 'white' }}>
                      {`${item.description}`}
                    </Text>
                  </>
                )}>
                  <List.Item style={{ marginBottom: '0px' }}>
                    <MenuProvider id="info_attachView_menu" animation={animation.zoom} data={{ item: item }}>
                      <Zoom wrapStyle={{ border: '1px #000000 solid', borderRadius: '2px' }}>
                        <sematic.Image style={{ width: '100%' }} src={item.img} rounded />
                      </Zoom>
                    </MenuProvider>
                  </List.Item>
                </Tooltip>


              )}
            />
          </div> :
          <Paragraph>No Data</Paragraph>
        }

        {!props.isProject &&
          <>
            <Text style={{ marginTop: '1em', color: '#1890ff', fontWeight: 'bold' }} strong>{`Attach Element (${attachElement.length})`}</Text>
            <ButtonGroup justified style={{ marginBottom: '1em' }}>
              <Button appearance="ghost" size='sm' onClick={handleSetAttachElement}
                disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : listAssignViewPoint.includes(props.email) ? false : isViewOnly}>
                <Icon icon="upload2" /> Set
            </Button>
              <Button appearance="ghost" size='sm' disabled={attachElement.length === 0} onClick={handleGetAttachElement}
                disabled={(props.email === viewPoint.userCreated || props.isAdmin) ? false : listAssignViewPoint.includes(props.email) ? false : isViewOnly}>
                <Icon icon="download2" /> Get
            </Button>
            </ButtonGroup>
          </>
        }

        <Divider className='custom-divider' />
        <Text strong style={{ color: '#1890ff', fontWeight: 'bold' }}> Comment</Text>
        {comments.length > 0 &&
          <List
            dataSource={comments}
            size='small'
            split={true}
            // header={`${props.viewPoint.comments.length} ${props.viewPoint.comments.length > 1 ? 'replies' : 'reply'}`}
            renderItem={item => {
              let avatar = ''
              let content = ''
              if (item.type == 'system') {
                try {
                  let arrayOfLines = item.content.match(/[^\r\n]+/g)
                  avatar = arrayOfLines[0].split(' ')[1]
                  arrayOfLines.splice(0, 1)
                  content = arrayOfLines.join('\r\n')
                  console.log(arrayOfLines)
                } catch { }
              }
              return (
                <>
                  <Comment className={item.type === 'system' ? 'custom-comment-issue-system' : item.author === props.email ? 'custom-comment-issue-me' : 'custom-comment-issue'}
                    author={(item.type === 'system' && avatar !== '') ? avatar : item.author}
                    avatar={item.type === 'system' ?
                      <Avatar style={{ backgroundColor: '#EA9999' }} icon={<IconAntd type="setting" style={{ top: -5, position: 'inherit' }} />}
                      /> : item.avatar}
                    content={
                      <>
                        {item.edit ?
                          <TextArea value={item.tempContent} autoSize size='small' style={{ marginBottom: '1em' }} onChange={handleChangeEditComment.bind(this, item.guid)} />
                          : item.content !== '' && <p style={{ color: 'black', marginBottom: 5 }}>
                            {(item.type === 'system' && content !== '') ? content : item.content}</p>}
                        {item.files.map(v =>
                          <div>
                            <Icon icon="paperclip" />
                            <Text ellipsis={true} style={{ width: 'calc(100% - 20px)', color: 'blue', textDecoration: 'underline', display: 'contents', cursor: 'pointer' }}>
                              <a onClick={handleDownloadComment.bind(this, v.url)}>
                                {' '}   {v.name}
                              </a>
                            </Text>
                            {(item.author === props.email && item.files.length > 1 && moment(item.datetime).add(0.5, 'hours') > moment(Date.now())) &&
                              <Icon icon="trash2" style={{ float: 'right', marginRight: 5, cursor: 'pointer', position: 'relative', top: 5 }}
                                onClick={handleRemoveCommentFile.bind(this, item.guid, v.name)} />}
                          </div>

                        )}
                        {(item.images && item.images.length !== 0) &&
                          <div style={{ padding: 7 }}>
                            <List
                              grid={{ gutter: [8, 8], column: 3 }}
                              dataSource={item.images}
                              renderItem={item => (
                                <List.Item style={{ marginBottom: '0px' }}>
                                  <MenuProvider id="info_attachView_comment_menu" animation={animation.zoom} data={{ item: item }}>
                                    <Zoom wrapStyle={{ border: '1px #000000 solid', borderRadius: '2px' }}>
                                      <sematic.Image style={{ width: '100%' }} src={item.img} rounded />
                                    </Zoom>
                                  </MenuProvider>
                                </List.Item>
                              )}
                            />
                          </div>}
                      </>}

                    datetime={
                      <div style={{ height: 17.4, position: 'relative', bottom: 1 }}>
                        {moment(item.datetime).format('lll')}
                        {moment(item.datetime).add(0.5, 'hours') > moment(Date.now()) &&
                          <>
                            {item.author === props.email &&
                              <>
                                {item.edit ?
                                  <ButtonGroup>
                                    <IconButton size="xs" icon={<Icon icon={'check'} />} color={'blue'} onClick={handleConfirmEditComment.bind(this, item.guid)} loading={submitting} />
                                    <IconButton size="xs" icon={<Icon icon='close' />} color={'red'} onClick={handleCloseEditComment.bind(this, item.guid)} loading={submitting} />
                                  </ButtonGroup>
                                  :
                                  <ButtonGroup style={{ marginLeft: 2 }}>
                                    <IconButton size="xs" icon={<Icon icon={"edit"} />} onClick={handleEditComment.bind(this, item.guid)} loading={submitting} />
                                    <IconButton size="xs" icon={<Icon icon={"trash"} />} color='red' onClick={handleRemoveComment.bind(this, item.guid)} loading={submitting} />
                                  </ButtonGroup>
                                }
                              </>}
                          </>
                        }
                      </div>
                    }
                  />
                  <Divider className='divide-list-comment-issue' />
                </>
              )
            }}
          />}
        <Comment className='comment-input'
          avatar={
            <Avatar src={''} />}
          content={
            <>
              <TextArea rows={3} onChange={handleChangeComment} value={valueComment} style={{ marginBottom: 5 }} autoSize={{ minRows: 3 }} />
              <ButtonGroup>
                <IconButton color='blue' icon={<Icon icon="send" />} loading={submitting}
                  onClick={handleAddComment} disabled={valueComment === ''} />
                {!props.isProject && <IconButton icon={<Icon icon="camera" />} loading={submitting} onClick={handleScreenShotComment} />}
                <Upload name='file' className='custom-upload-file-comment'
                  multiple={false}
                  showUploadList={true} fileList={commentFile}
                  customRequest={handleLinkFileComment} beforeUpload={beforeUpload} onRemove={handleRemoveLinkFileComment}>
                  <IconButton icon={<Icon icon="link" />} loading={submitting} />
                </Upload>
              </ButtonGroup>
              {imageMoreComment.length !== 0 &&
                <div style={{ padding: 7 }}>
                  <List
                    grid={{ gutter: [8, 8], column: 3 }}
                    dataSource={imageMoreComment}
                    renderItem={item => (
                      <List.Item style={{ marginBottom: '0px' }}>
                        <MenuProvider id="attachView_comment_menu" animation={animation.zoom} data={{ item: item }} >
                          <Zoom wrapStyle={{ border: '1px #000000 solid', borderRadius: '2px' }}>
                            <sematic.Image style={{ width: '100%' }} src={item.img} rounded />
                          </Zoom>
                        </MenuProvider>
                      </List.Item>
                    )}
                  />
                </div>}
              {/* <Button htmlType="submit" loading={submitting} onClick={handleAddComment} appearance="primary">
                Add Comment
              </Button> */}
            </>
            // <InputGroup >
            //   <Input componentClass="textarea" style={{ width: '100%' }}
            //     onChange={handleChangeComment} value={valueComment} />
            //   <InputGroup.Button loading={submitting} onClick={handleAddComment}>
            //     <Icon icon="plus-square-o" />
            //   </InputGroup.Button>
            // </InputGroup>
          }
        />
      </Scrollbars>


      {!props.isProject &&
        <Menu id='info_issue_menu' style={{ zIndex: 10000 }} >
          <Item onClick={props.restoreSaveView.bind(this, true)} >
            <IconFont className="fas fa-check" />View Issue
                </Item>
          {!props.newTab &&
            <Item onClick={props.restoreSaveView.bind(this, false)}>
              <IconFont className="fas fa-check-double" />View Issue With Original Version
           </Item>}
        </Menu>}

      {props.isProject && <Menu id='info_other_issue_menu' style={{ zIndex: 10000 }}>
        <Item onClick={props.handleOpenIssue.bind(this, true)} disabled={!checkItemExisting}>
          <IconFont className="fas fa-check" />View Issue
                </Item>
        {!props.newTab && <Item onClick={props.handleOpenIssue.bind(this, false)} disabled={!checkItemExisting}>
          <IconFont className="fas fa-check-double" />View Issue With Original Version
                </Item>}
      </Menu>}

      <Menu id='info_attachView_menu' style={{ zIndex: 10000 }}>
        {!props.isProject && <Item onClick={props.restoreSaveView.bind(this, true)} >
          <IconFont className="fas fa-check" />View Issue
                </Item>}
        {(!props.newTab && !props.isProject) && <Item onClick={props.restoreSaveView.bind(this, false)} >
          <IconFont className="fas fa-check-double" />View Issue With Original Version
           </Item>}
        {/* <Item onClick={handleInfoImageMore}>
              <IconFont className="fas fa-info-circle" />Description
                  </Item> */}
        <Item onClick={handleDeleteAttachView}>
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
      <Menu id='attachView_comment_menu' style={{ zIndex: 10000 }}>
        <Item onClick={handleDeleteAttachViewComment}>
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
      <Menu id='info_attachView_comment_menu' style={{ zIndex: 10000 }}>
        {!props.isProject && <Item onClick={props.restoreSaveView.bind(this, true)} >
          <IconFont className="fas fa-check" />View Issue
                </Item>}
        {(!props.newTab && !props.isProject) && <Item onClick={props.restoreSaveView.bind(this, false)} >
          <IconFont className="fas fa-check-double" />View Issue With Original Version
           </Item>}
      </Menu>

      <Toaster ref={toaster} position={Position.TOP} canEscapeKeyClear={true} />
    </>

  )
}

IssueInformation.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  }
}
export default connect(mapStateToProps)(IssueInformation)