import axios from 'axios';
import moment from 'moment';
import _ from 'lodash'
import {
    getAllElementdbIdsOneModel
} from '../../../function/ForgeFunction';

export function getDataModelForChart(nextProps) {
    return new Promise(resolve => {
        let dbIds = getAllElementdbIdsOneModel(nextProps.viewer)
        var listTempCategory = []
        var listTempCategoryMS = []
        var count = dbIds.length;
        dbIds.forEach((modelAdbId) => {
            nextProps.viewer.model.getProperties(modelAdbId, async (modelAProperty) => {
                var tempValueMark = ''
                var tempValueVolume = 0
                var tempValueMarkMS = ''
                var tempCategory = ''
                var tempWhGroup = ''
                var check1 = false; var check2 = false
                for (var i in modelAProperty.properties) {
                    if (modelAProperty.properties[i].displayName === 'DETAIL SCHEDULE ID') {
                        tempValueMark = modelAProperty.properties[i].displayValue
                    } else if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
                        tempValueMarkMS = modelAProperty.properties[i].displayValue
                    } else if (modelAProperty.properties[i].displayName === 'Volume') {
                        tempValueVolume = modelAProperty.properties[i].displayValue
                    } else if (modelAProperty.properties[i].displayName === 'Original Category') {
                        tempCategory = modelAProperty.properties[i].displayValue
                    } else if (modelAProperty.properties[i].displayName === 'WH_Group') {
                        tempWhGroup = modelAProperty.properties[i].displayValue
                    }
                    if (i === (modelAProperty.properties.length - 1).toString()) {
                        if (tempWhGroup === '')
                            tempWhGroup = tempCategory
                    }
                    if (tempValueMark !== '' && tempValueVolume !== 0 && !check1 && tempCategory !== '' && tempWhGroup !== '') {
                        if (!listTempCategory[tempValueMark])
                            listTempCategory[tempValueMark] = []
                        listTempCategory[tempValueMark].push({ id: modelAdbId, category: tempWhGroup, volume: parseFloat(tempValueVolume.toFixed(2)) })
                        check1 = true
                    }
                    if (tempValueVolume !== 0 && tempValueMarkMS !== '' && !check2 && tempCategory !== '' && tempWhGroup !== '') {
                        if (!listTempCategoryMS[tempValueMarkMS])
                            listTempCategoryMS[tempValueMarkMS] = []
                        listTempCategoryMS[tempValueMarkMS].push({ id: modelAdbId, category: tempWhGroup, volume: parseFloat(tempValueVolume.toFixed(2)) })
                        check2 = true
                    }
                    if (check1 && check2)
                        break
                }
                count--;
                if (count === 0) {
                    var listCombine = _.assignIn(listTempCategory, listTempCategoryMS);
                    resolve(listCombine)                  
                }
            })
        })
    })
}

export async function generateColumnChart(listMark, listSheetId) {
    try {
        return new Promise(resolve => {
            axios.post(`/api/smartsheet/getmanschedule`, { listSheetId: listSheetId })
                .then(res => {
                    var tempDataSheet = null;
                    var tempDataRate = null;
                    res.data.forEach(item => {
                        if (item.name !== 'Productivity Rate') tempDataSheet = item.rows
                        else tempDataRate = item.rows
                    })

                    var groupToRate = []
                    var tempNameRate = ''
                    for (let i = 0; i < tempDataRate.length; i++) {
                        var temp = {
                            id: tempDataRate[i].id, content: tempDataRate[i].cells[0].value, genetal: tempDataRate[i].cells[1].value,
                            lean: tempDataRate[i].cells[2].value, formwork: tempDataRate[i].cells[3].value, rebar: tempDataRate[i].cells[4].value, concreting: tempDataRate[i].cells[5].value,
                            nestedGroups: [], parentId: tempDataRate[i].parentId
                        }
                        for (let j = 0; j < tempDataRate.length; j++) {
                            if (i !== j) {
                                if (tempDataRate[i].id === tempDataRate[j].parentId) {
                                    temp.nestedGroups.push(tempDataRate[j].id)
                                }
                            }
                        }
                        if (temp.nestedGroups.length !== 0) {
                            if (temp.parentId === null || temp.parentId === undefined) {
                                tempNameRate = temp.content
                            } else {
                                groupToRate[tempNameRate + '_' + temp.content] = []
                                tempNameRate = tempNameRate + '_' + temp.content
                            }
                        } else {
                            groupToRate[tempNameRate].push(temp)
                        }
                    }
                    ///////
                    var groups = []
                    for (let i = 0; i < tempDataSheet.length; i++) {
                        let temp1 = {
                            id: tempDataSheet[i].id, content: tempDataSheet[i].cells[0].value, start: tempDataSheet[i].cells[1].value, end: tempDataSheet[i].cells[2].value,
                            nestedGroups: [], parentId: tempDataSheet[i].parentId
                        }

                        for (let j = 0; j < tempDataSheet.length; j++) {
                            if (i !== j) {
                                if (tempDataSheet[i].id === tempDataSheet[j].parentId) {
                                    temp1.nestedGroups.push(tempDataSheet[j].id)
                                }
                            }
                        }
                        if (temp1.nestedGroups.length === 0) {
                            if (temp1.parentId === null || temp1.parentId === undefined) {
                            } else {
                                var dateStart = moment(temp1.start).format('DD-MM-YYYY')
                                var dateEnd = moment(temp1.end).format('DD-MM-YYYY')

                                if (!groups[`${dateStart}//${dateEnd}`]) {
                                    groups[`${dateStart}//${dateEnd}`] = []
                                }
                                groups[`${dateStart}//${dateEnd}`].push(temp1)

                            }
                        }
                    }
                    let temp1 = []
                    for (var key in groups) {
                        groups[key].forEach(item => {
                            if (listMark[item.content]) {
                                var groupCategory = _.groupBy(listMark[item.content], 'category');
                                for (var category in groupCategory) {
                                    var id = []
                                    var volume = 0
                                    groupCategory[category].forEach(item => {
                                        id.push(item.id)
                                        volume = volume + item.volume
                                    })

                                    var newCategory = ''
                                    if (category.includes('SLAB') || category.includes('Floors') || category.includes('Generic Models') || category.includes('PLINTH')) {
                                        newCategory = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][0].content
                                    } else if (category.includes('WALL') || category.includes('Walls')) {
                                        newCategory = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][1].content
                                    } else if (category.includes('COLUMN') || category.includes('Columns')) {
                                        newCategory = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][2].content
                                    } else if (category.includes('Framing')) {
                                        newCategory = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][3].content
                                    }
                                    if (newCategory !== '') {
                                        if (!temp1[newCategory])
                                            temp1[newCategory] = []
                                        temp1[newCategory].push({ time: key, id: id, volume: volume })
                                    }
                                }
                            }
                        })
                        // temp1.push({time: key,id:id,volume:volume})                          
                    }
                    var temp2 = []
                    // groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'].forEach(rateName=>{                  

                    // })


                    for (var key in temp1) {
                        var rate = 0;
                        var typeName = ''
                        if (key === groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][0].content) {
                            rate = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][0].concreting
                            typeName = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][0].content
                        } else if (key === groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][1].content) {
                            rate = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][1].concreting
                            typeName = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][1].content
                        } else if (key === groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][2].content) {
                            rate = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][2].concreting
                            typeName = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][2].content
                        } else if (key === groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][3].content) {
                            rate = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][3].concreting
                            typeName = groupToRate['CIVIL PROJECT PRODUCTIVITY_STRUCTURAL WORK'][3].content
                        }
                        let tempMonth = {}
                        var listKeyDate = []
                        temp1[key].forEach(item => {
                            var split = item.time.split('//')
                            var start = moment(split[0], 'DD-MM-YYYY')
                            var end = moment(split[1], 'DD-MM-YYYY')
                            var ms = end.diff(start)
                            var d = moment.duration(ms)._data.days + 1;

                            var tempDate = moment(split[0], 'DD-MM-YYYY')
                            let totalDay = 0
                            for (let i = 1; i <= d; i++) {
                                let newDate = tempDate.add(1, 'd')
                                if (newDate.isoWeekday() !== 6 && newDate.isoWeekday() !== 7) {
                                    totalDay++
                                }
                            }
                            var workerOneDay = item.volume / rate
                            var wokerNeed = workerOneDay / totalDay

                            tempDate = moment(split[0], 'DD-MM-YYYY')
                            for (let i = 1; i <= d; i++) {
                                let newDate = tempDate.add(1, 'd')
                                if (newDate.isoWeekday() !== 6 && newDate.isoWeekday() !== 7) {
                                    var month = moment(newDate, 'DD-MM-YYYY').format('MM')
                                    var year = moment(newDate, 'DD-MM-YYYY').format('YY')
                                    if (!tempMonth[month + '-' + year]) {
                                        tempMonth[month + '-' + year] = { time: month + '-' + year, [typeName]: 0 }
                                    }
                                    if (!_.includes(listKeyDate, month + '-' + year))
                                        listKeyDate.push(month + '-' + year)
                                    tempMonth[month + '-' + year][typeName] = tempMonth[month + '-' + year][typeName] + wokerNeed
                                }
                            }
                        })

                        // temp2[key] = tempMonth;
                        for (let date in tempMonth) {
                            temp2.push(tempMonth[date])
                        }
                    }

                    // for(let date in listKeyDate){
                    //     for(let cat in temp2){
                    //         temp2[cat].forEach(item=>{
                    //             if(!item[date]){
                    //                 item[date]= item[date]
                    //             }
                    //         })
                    //     }
                    // }
                    var header = _.keys(temp1)
                    var groupBy = _.groupBy(temp2, 'time')

                    var listCombine = []
                    for (let key in groupBy) {
                        let temp = groupBy[key].reduce(((r, c) => Object.assign(r, c)), {})
                        listCombine.push(temp)
                    }
                    for (let key in header) {
                        listCombine.forEach(item => {
                            if (!item[header[key]]) {
                                item[header[key]] = 0
                            } else {
                                item[header[key]] = Math.round(item[header[key]])
                            }
                        })
                    }

                    var newList = []
                    var tempHeader = header.slice()
                    tempHeader.splice(0, 0, 'time')
                    listCombine.forEach(item => {
                        newList.push(_.pick(item, tempHeader))
                    })



                    ///////////////
                    resolve({ head: header, data: newList })
                })
                .catch(err => { })
        })
    }
    catch{ }
}

function compareTime(a, b) {
    var tempA = moment(a.time, 'MM-YY').valueOf()
    var tempB = moment(b.time, 'MM-YY').valueOf()
    if (tempA < tempB) {
        return -1;
    }
    if (tempA > tempB) {
        return 1;
    }
    return 0;
}