import React, { useState, useEffect } from 'react'
import { SelectPicker, Icon } from 'rsuite';
import _ from 'lodash'
// import '../../scss/FogreCustom.scss'
import Draggable from 'react-draggable';
import { Typography, Tooltip } from 'antd';
import { IconButton, InputPicker, Divider, } from 'rsuite';
import {arrowImg ,circleImg,cloudImg,rectangularImg,freehandImg,textImg} from '../../../../image'

const { Text } = Typography
const Autodesk = window.Autodesk

const strokeOptions = [
  { label: 'Thin', value: 0.5 },
  { label: 'Normal', value: 1 },
  { label: 'Thick', value: 1.5 },
  { label: 'Very Thick', value: 2 },
]
const opacityOptions = [
  { label: '0', value: 0 },
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '0.75', value: 0.75 },
  { label: '1', value: 1 },
]
const fontFamilyOptions = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Arial Black', value: 'Arial Black' },
  { label: 'Arial Narrow', value: 'Arial Narrow' },
  { label: 'Century Gothic', value: 'Century Gothic' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Lucida Console', value: 'Lucida Console' },
  { label: 'Tahoma', value: 'Tahoma' },
  { label: 'Verdana', value: 'Verdana' },
]
const fontSizeOptions = [
  { label: 'Small', value: 1 },
  { label: 'Medium', value: 1.5 },
  { label: 'Large', value: 2 },
  { label: 'Very Large', value: 2.5 },
]
const fontStyleOptions = [
  { label: 'Normal', value: 'Normal' },
  { label: 'Italic', value: 'Italic' },
]
const fontWeightOptions = [
  { label: 'Normal', value: 'Normal' },
  { label: 'Bold', value: 'Bold' },
]
const colorOptions = [
  { label: '#ff0000', value: '#ff0000' },
  { label: '#00ff00', value: '#00ff00' },
  { label: '#0000ff', value: '#0000ff' },
  { label: '#ffff00', value: '#ffff00' },
  { label: '#ffffff', value: '#ffffff' },
  { label: '#000000', value: '#000000' },
]
let factor = 1
export default function MarkUp2D(props) {

  const [stroke, setStroke] = useState(1)
  const [color, setColor] = useState('#ff0000')
  const [opacity, setOpacity] = useState(1)
  const [ext, setExt] = useState()
  const [type, setType] = useState('arrow')

  const [colorFill, setColorFill] = useState('#ffffff')
  const [opacityFill, setOpacityFill] = useState(0)

  const [label, setLabel] = useState({
    family: 'Arial',
    size: 1.5,
    weight: 'Normal',
    style: 'Normal'
  })

  const [markUp, setMarkUp] = useState()
  const [more, setMore] = useState(false)
  const [navigation, setNavigation] = useState(false)
  useEffect(e => {
    let markupCore= props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    setExt(markupCore)
    factor = props.viewer.impl.model.is2d() ? 2 : props.viewer.impl.model.getData().loadOptions.fileExt === 'wfx' ? 304.8 : 1
    // setStroke(0.05 * factor)
    return()=>{
      markupCore.unloadMarkupsAllLayers()
      markupCore.leaveEditMode()
    }
  }, [])
  useEffect(e => {
    if (ext) {

 
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth()
      })
      // console.log(ext.getStrokeWidth())
      ext.addEventListener(Autodesk.Viewing.Extensions.Markups.Core.EVENT_MARKUP_SELECTED, onMarkupSelected);
    }

  }, [ext])
  useEffect(e => {
    if (ext)
      ext.allowNavigation(navigation)
  }, [navigation])
  const handleCloseDock = () => {
    ext.leaveEditMode()
    ext.hide()
    ext.removeEventListener(Autodesk.Viewing.Extensions.Markups.Core.EVENT_MARKUP_SELECTED, onMarkupSelected);
    props.onChangeDockDisplay('markUp2dEdit', false)
  }

  const onMarkupSelected = (e) => {
    console.log(e)
    setMarkUp(e.target)
    setType(e.target.type)
    setColor(e.target.style['stroke-color'])
    setOpacity(e.target.style['stroke-opacity'])
    if (e.target.type === 'rectangle' || e.target.type === 'ellipse' || e.target.type === 'cloud' || e.target.type === 'dimension') {
      setStroke(e.target.style['stroke-width'] / ext.getStrokeWidth())
    }
    if (e.target.type === 'rectangle' || e.target.type === 'ellipse' || e.target.type === 'cloud' || e.target.type === 'label'|| e.target.type === 'dimension') {
      setColorFill(e.target.style['fill-color'])
      setOpacityFill(e.target.style['fill-opacity'])
    }
    if (e.target.type === 'label' || e.target.type === 'dimension') {
      setLabel({
        ...label,
        'family': e.target.style['font-family'],
        'size': e.target.style['font-size'] / ext.getFontWidth(),
        'style': e.target.style['font-style'],
        'weight': e.target.style['font-weight']
      })
    }
    ext.setStyle(e.target.style)
  }
  const handleMarkType = (name) => {
    setType(name)
    let cloud = ''
    if (name === 'arrow')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(ext)
    else if (name === 'label')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeText(ext)
    else if (name === 'rectangle')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeRectangle(ext)
    else if (name === 'ellipse')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle(ext)
    else if (name === 'cloud')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud(ext)
    else if (name === 'freehand')
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeFreehand(ext)
    else if (name = 'dimension') {
      cloud = new Autodesk.Viewing.Extensions.Markups.Core.EditModeDimension(ext)
    }
    ext.changeEditMode(cloud)
    if (name === 'arrow' || name === 'freehand') {
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth() * stroke
      })
    } else if (name === 'rectangle' || name === 'ellipse' || name === 'cloud') {
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth() * stroke,
        'fill-color': colorFill,
        'fill-opacity': opacityFill
      })
    } else if (name === 'label') {
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth() * stroke,
        'fill-color': colorFill,
        'fill-opacity': opacityFill,
        'font-family': label.family,
        'font-size': ext.getFontWidth() * label.size,
        'font-style': label.style,
        'font-weight': label.weight,
      })
    } else if (name === 'dimension') {
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth() * stroke,
        'font-family': label.family,
        'font-size': ext.getFontWidth() * label.size,
        'font-style': label.style,
        'font-weight': label.weight,
      })
    }

  }
  const handleBehaviour = (name) => {
    if (name === 'undo')
      ext.undo()
    else if (name === 'redo')
      ext.redo()
    else if (name === 'delete') {
      if (markUp)
        ext.deleteMarkup(markUp)
    }
    else if (name === 'clear')
      ext.clear()
    else if (name === 'navigation') {
      setNavigation(!navigation)
      ext.setStyle({
        'stroke-color': color,
        'stroke-opacity': opacity,
        'stroke-width': ext.getStrokeWidth() * stroke,
        'fill-color': colorFill,
        'fill-opacity': opacityFill,
        'font-family': label.family,
        'font-size': ext.getFontWidth() * label.size,
        'font-style': label.style,
        'font-weight': label.weight
      })
    }
  }

  const handleChangeColor = (value) => {
    setColor(value)
    ext.setStyle({
      'stroke-color': value,
      'stroke-opacity': opacity,
      'stroke-width': ext.getStrokeWidth() * stroke,
      'fill-color': colorFill,
      'fill-opacity': opacityFill,
      'font-family': label.family,
      'font-size': ext.getFontWidth() * label.size,
      'font-style': label.style,
      'font-weight': label.weight
    })
  }
  const handleStroke = (value) => {
    setStroke(value)
    ext.setStyle({
      'stroke-color': color,
      'stroke-opacity': opacity,
      'stroke-width': ext.getStrokeWidth() * value,
      'fill-color': colorFill,
      'fill-opacity': opacityFill,
      'font-family': label.family,
      'font-size': ext.getFontWidth() * label.size,
      'font-style': label.style,
      'font-weight': label.weight
    })
  }
  const handleOpacity = (value) => {
    setOpacity(value)
    ext.setStyle({
      'stroke-color': color,
      'stroke-opacity': value,
      'stroke-width': ext.getStrokeWidth() * stroke,
      'fill-color': colorFill,
      'fill-opacity': opacityFill,
      'font-family': label.family,
      'font-size': ext.getFontWidth() * label.size,
      'font-style': label.style,
      'font-weight': label.weight
    })
  }
  const handleChangeColorFill = (value) => {
    setColorFill(value)
    ext.setStyle({
      'stroke-color': color,
      'stroke-opacity': opacity,
      'stroke-width': ext.getStrokeWidth() * stroke,
      'fill-color': value,
      'fill-opacity': opacityFill,
      'font-family': label.family,
      'font-size': ext.getFontWidth() * label.size,
      'font-style': label.style,
      'font-weight': label.weight
    })
  }
  const handleOpacityFill = (value) => {
    setOpacityFill(value)
    ext.setStyle({
      'stroke-color': color,
      'stroke-opacity': opacity,
      'stroke-width': ext.getStrokeWidth() * stroke,
      'fill-color': colorFill,
      'fill-opacity': value,
      'font-family': label.family,
      'font-size': ext.getFontWidth() * label.size,
      'font-style': label.style,
      'font-weight': label.weight
    })
  }
  const handleText = (name, value) => {
    if (name === 'size') {
      setLabel({
        ...label,
        ['size']: value
      })
    } else {
      setLabel({
        ...label,
        [name]: value
      })
    }

    ext.setStyle({
      'stroke-color': color,
      'stroke-opacity': opacity,
      'stroke-width': ext.getStrokeWidth() * stroke,
      'fill-color': colorFill,
      'fill-opacity': opacityFill,
      'font-family': name === 'family' ? value : label.family,
      'font-size': name === 'size' ? (ext.getFontWidth() * value) :
        (ext.getFontWidth() * label.size),
      'font-style': name === 'style' ? value : label.style,
      'font-weight': name === 'weight' ? value : label.weight,
    })
  }
  const handelMoreOptions = () => {
    setMore(!more)
  }
  return (
    <Draggable
      axis="both" bounds='body'
      handle=".custom-dock-panel-title"
      defaultPosition={{ x: 0, y: 0 }}
      scale={1}
    >
      <div className='custom-forge-dock' style={{
        display: props.openDock ? 'inline' : 'none', minWidth: 120, width: 120,
        height: type === 'label' ? (480 + (more && 150)) : type === 'dimension' ? (430 + (more && 150)) :
          (type === 'rectangle' || type === 'cloud' || type === 'ellipse') ? 450 : 350, right: 0, resize: 'vertical'
      }} id='custom-markUp2dEdit' >
        <div className='custom-dock-panel-title'>Markup</div>
        <div className='custom-dock-panel-close' onClick={handleCloseDock}>x</div>
        <div className='custom-dock-panel-body' style={{ backgroundColor: '#ffffff05' }}>
          <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute', padding: 5 }}>
            <div style={{ marginBottom: 7 }} >
              <Tooltip title='Undo' placement='top'>
                <IconButton appearance='primary' icon={<Icon icon='undo' />} onClick={handleBehaviour.bind(this, 'undo')} style={{ marginRight: 5 }} size="xs" disabled={navigation} />
              </Tooltip>
              <Tooltip title='Redo' placement='top'>
                <IconButton appearance='primary' icon={<Icon icon='repeat' />} onClick={handleBehaviour.bind(this, 'redo')} style={{ marginRight: 5 }} size="xs" disabled={navigation} />
              </Tooltip>
              <Tooltip title='Delete Markup' placement='top'>
                <IconButton appearance='primary' icon={<Icon icon='trash' />} onClick={handleBehaviour.bind(this, 'delete')} style={{ marginRight: 5 }} size="xs" disabled={navigation} />
              </Tooltip>
              <Tooltip title='Clear All' placement='top'>
                <IconButton appearance='primary' icon={<Icon icon='eraser' />} onClick={handleBehaviour.bind(this, 'clear')} style={{ marginRight: 5 }} size="xs" disabled={navigation} />
              </Tooltip>
              <Tooltip title='Navigation' placement='top'>
                <IconButton appearance='primary' icon={<Icon icon='hand-stop-o' />} onClick={handleBehaviour.bind(this, 'navigation')} style={{ marginRight: 5 }} size="xs"
                  color={navigation && 'red'} />
              </Tooltip>

            </div>



            <div style={{ marginBottom: 7 }}>
              <IconButton appearance='primary' color={type === 'arrow' && 'violet'} onClick={handleMarkType.bind(this, 'arrow')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${arrowImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'label' && 'violet'} onClick={handleMarkType.bind(this, 'label')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${textImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'rectangle' && 'violet'} onClick={handleMarkType.bind(this, 'rectangle')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${rectangularImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'ellipse' && 'violet'} onClick={handleMarkType.bind(this, 'ellipse')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${circleImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'cloud' && 'violet'} onClick={handleMarkType.bind(this, 'cloud')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${cloudImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'freehand' && 'violet'} onClick={handleMarkType.bind(this, 'freehand')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon style={{ backgroundImage: `url(${freehandImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />} size="xs" />
              <IconButton appearance='primary' color={type === 'dimension' && 'violet'} onClick={handleMarkType.bind(this, 'dimension')} style={{ marginRight: 5 }} disabled={navigation}
                icon={<Icon icon='expand' />} size="xs" />
            </div>

            {(type === 'label' || type == 'dimension') &&
              <>
                <Divider style={{ marginBottom: 7, marginTop: 7, backgroundColor: 'black' }} />
                <Text strong style={{ color: 'black' }}> Font Size</Text>
                {/* <InputNumber step={0.01} min={0.01} onChange={handleText.bind(this, 'size')} value={label.size}
                  style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                  size='xs' cleanable={false} placement='leftEnd' disabled={navigation} /> */}
                <SelectPicker data={fontSizeOptions} searchable={false} size='xs' style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                  value={label.size} placement='leftEnd' onChange={handleText.bind(this, 'size')} cleanable={false} />
                <IconButton icon={<Icon icon={"more"} />} onClick={handelMoreOptions} color={more && 'blue'} appearance='default' size='xs' style={{ marginLeft: 5 }}
                  disabled={navigation} />
                <br />
                {more &&
                  <>
                    <Text strong style={{ color: 'black' }}> Font Family</Text>
                    <InputPicker data={fontFamilyOptions} onChange={handleText.bind(this, 'family')} value={label.family} style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                      size='xs' cleanable={false} placement='leftEnd' disabled={navigation}
                      renderMenuItem={(label, item) => {
                        return (
                          <div style={{ fontFamily: `${label}` }}>
                            {label}
                          </div>
                        );
                      }}
                      renderValue={(value, item, selectedElement) => {
                        return (
                          <div style={{ fontFamily: `${value}` }}>
                            {value}
                          </div>
                        );
                      }}
                    /><br />
                    <Text strong style={{ color: 'black' }}> Font Style</Text>
                    <InputPicker data={fontStyleOptions} onChange={handleText.bind(this, 'style')} value={label.style} style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                      size='xs' cleanable={false} placement='leftEnd' disabled={navigation} /><br />
                    <Text strong style={{ color: 'black' }}> Font Weight</Text>
                    <InputPicker data={fontWeightOptions} onChange={handleText.bind(this, 'weight')} value={label.weight}
                      style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                      size='xs' cleanable={false} placement='leftEnd' disabled={navigation} /><br />
                  </>

                }
              </>
            }
            <Divider style={{ marginBottom: 7, marginTop: 7, backgroundColor: 'black' }} />
            {type !== 'label' &&
              <>
                <Text strong style={{ color: 'black' }}> Weight</Text>
                {/* <InputNumber step={0.01} min={0.01} onChange={handleStroke} value={stroke} placement='leftEnd'
                  style={{ marginLeft: 5, marginBottom: 5, width: 100 }} size='xs' cleanable={false} disabled={navigation} /> */}
                <SelectPicker data={strokeOptions} searchable={false} size='xs' style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                  value={stroke} placement='leftEnd' onChange={handleStroke} cleanable={false} disabled={navigation} />
              </>
            }
            <InputPicker data={colorOptions} onChange={handleChangeColor} value={color} placement='left'
              style={{ marginLeft: 5, marginBottom: 5, width: 70 }} size='xs' cleanable={false} disabled={navigation}
              renderMenuItem={(label, item) => {
                return (
                  <IconButton
                    style={{ backgroundColor: label, color: 'white', border: '1px black solid' }} size='xs' />
                );
              }}
              renderValue={(value, item, selectedElement) => {
                return (
                  <>
                    <div className="rs-icon rs-icon-square"
                      style={{ color: value, backgroundColor: value, width: '100%', border: value === '#ffffff' ? '1px gray solid' : '1px black solid' }} />
                  </>
                );
              }}
            />
            <br />


            {/* <div style={{ marginBottom: 7 }}>
              <IconButton icon={<Icon icon={color === '#ff0000' && 'check'} />} onClick={handleChangeColor.bind(this, '#ff0000')}
                style={{ backgroundColor: '#ff0000', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
              <IconButton icon={<Icon icon={color === '#00ff00' && 'check'} />} onClick={handleChangeColor.bind(this, '#00ff00')}
                style={{ backgroundColor: '#00ff00', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
              <IconButton icon={<Icon icon={color === '#0000ff' && 'check'} />} onClick={handleChangeColor.bind(this, '#0000ff')}
                style={{ backgroundColor: '#0000ff', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
              <IconButton icon={<Icon icon={color === '#ffff00' && 'check'} />} onClick={handleChangeColor.bind(this, '#ffff00')}
                style={{ backgroundColor: '#ffff00', color: 'black', marginRight: 5, border: '1px black solid' }} size='sm' />
              <IconButton icon={<Icon icon={color === '#ffffff' && 'check'} />} onClick={handleChangeColor.bind(this, '#ffffff')}
                style={{ backgroundColor: '#ffffff', color: 'black', marginRight: 5, border: '1px black solid' }} size='sm' />
              <IconButton icon={<Icon icon={color === '#000000' && 'check'} />} onClick={handleChangeColor.bind(this, '#000000')}
                style={{ backgroundColor: '#000000', color: 'white', marginRight: 5, border: '1px gray solid' }} size='sm' />
            </div> */}

            <Text strong style={{ color: 'black' }}> Opacity</Text>
            <InputPicker data={opacityOptions} onChange={handleOpacity} value={opacity} placement='leftEnd'
              style={{ marginLeft: 5, marginBottom: 5, width: 100 }} size='xs' cleanable={false} disabled={navigation} />
            {(type === 'rectangle' || type === 'cloud' || type === 'ellipse' || type === 'label') &&
              <>
                <Divider style={{ marginBottom: 7, marginTop: 7, backgroundColor: 'black' }} />
                <Text strong style={{ color: 'black' }}> Fill</Text>
                <InputPicker data={colorOptions} onChange={handleChangeColorFill} value={colorFill} placement='left'
                  style={{ marginLeft: 5, marginBottom: 5, width: 70 }} size='xs' cleanable={false} disabled={navigation}
                  renderMenuItem={(label, item) => {
                    return (
                      <IconButton
                        style={{ backgroundColor: label, color: 'white', border: '1px black solid' }} size='xs' />
                    );
                  }}
                  renderValue={(value, item, selectedElement) => {
                    return (
                      <>
                        <div className="rs-icon rs-icon-square"
                          style={{ color: value, backgroundColor: value, width: '100%', border: value === '#ffffff' ? '1px gray solid' : '1px black solid' }} />
                      </>
                    );
                  }}
                />
                <br />
                {/* <div style={{ marginBottom: 7 }}>
                  <IconButton icon={<Icon icon={colorFill === '#ff0000' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#ff0000')}
                    style={{ backgroundColor: '#ff0000', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
                  <IconButton icon={<Icon icon={colorFill === '#00ff00' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#00ff00')}
                    style={{ backgroundColor: '#00ff00', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
                  <IconButton icon={<Icon icon={colorFill === '#0000ff' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#0000ff')}
                    style={{ backgroundColor: '#0000ff', color: 'white', marginRight: 5, border: '1px black solid' }} size='sm' />
                  <IconButton icon={<Icon icon={colorFill === '#ffff00' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#ffff00')}
                    style={{ backgroundColor: '#ffff00', color: 'black', marginRight: 5, border: '1px black solid' }} size='sm' />
                  <IconButton icon={<Icon icon={colorFill === '#ffffff' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#ffffff')}
                    style={{ backgroundColor: '#ffffff', color: 'black', marginRight: 5, border: '1px black solid' }} size='sm' />
                  <IconButton icon={<Icon icon={colorFill === '#000000' && 'check'} />} onClick={handleChangeColorFill.bind(this, '#000000')}
                    style={{ backgroundColor: '#000000', color: 'white', marginRight: 5, border: '1px gray solid' }} size='sm' />
                </div> */}
                <Text strong style={{ color: 'black' }}> Opacity</Text>
                <InputPicker data={opacityOptions} onChange={handleOpacityFill} value={opacityFill} placement='leftEnd'
                  style={{ marginLeft: 5, marginBottom: 5, width: 100 }} size='xs' cleanable={false} disabled={navigation} />
              </>
            }

          </div>
        </div>
        <div className='custom-dock-panel-footer' />
      </div>
    </Draggable>

  )
}