import { DATAENTRY_ITEMS, DATAENTRY_ITEMS_LOADING,DATAENTRY_ITEMS_OPEN_HISTORY } from "../types";

const init = {
  gridApi: null,
  selectedNode: [],
  isCorrespond: false,
  isIsolate: false,
  data: [],
  loading: false,
  openHistoryDialog: {isOpen: false, data:null},
  exportReportdata:null,
  currentCastingReportHistory:null,
  showCastingReport: 0,
  showCastingReportHistory: 0,

}

export function dataentry(state = init, action = {}) {
  switch (action.type) {
    case DATAENTRY_ITEMS:
      return { ...state, ...action.data };
    case DATAENTRY_ITEMS_LOADING:
      return { ...state, ...action.data };
    case DATAENTRY_ITEMS_OPEN_HISTORY:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
