import React from 'react';
import {
  message,
  Modal,
} from 'antd';


import _ from 'lodash';
import axios from 'axios';
import {
  InputPicker,
} from 'rsuite';


import {pipeDwall, slab5D, archi5D} from '../../pages/DataEntry/DataEntryTemplate'


export default class DialogTemplateSheet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        template: '',
        loading: false,
      };
    }
  
    handleClose = () => {
      this.props.close();
    };
  
    handleOk = () => {
      if (this.state.template === '') {
        message.warning('Please, choose a template');
        return;
      }
      let tempColumns = [];
      if (this.state.template === 'Pile, Dwall') {
        tempColumns = pipeDwall;
      } else if (this.state.template === 'Slab 5D') {
        tempColumns = slab5D;
      } else if (this.state.template === 'Archi') {
        tempColumns = archi5D;
      }
      _.forEach(tempColumns, (v) => {
        v.id = v.name;
      });
      let name = this.state.template;
      let sheet = {
        id: this.props.projectId + '_for5D_' + this.state.template,
        name: name,
        type: 'for5d',
        columns: tempColumns,
      };
      let temp = this.props.listSheet;
      let index = _.findIndex(temp, (o) => {
        return o.name === name;
      });
      if (index >= 0) {
        message.warning('File is existing');
      } else {
        this.setState({ loading: true });
        axios
          .post(`/api/dataentry/set-project-sheet`, {
            projectId: this.props.projectId,
            sheet,
            token: this.props.token,
          })
          .then((res1) => {
            temp.push(sheet);
            this.props.close(temp);
          })
          .catch(() => {
            message.warning(`Can't save sheet`);
            this.setState({ loading: false });
          });
      }
    };
    handleChangeTemplate = (value) => {
      this.setState({ template: value });
    };
  
    render() {
      return (
        <>
          <Modal
            title={'Template Sheet'}
            visible={true}
            onCancel={this.handleClose}
            onOk={this.handleOk}
            width={'30%'}
            confirmLoading={this.state.loading}
          >
            <InputPicker
              block
              placeholder='Template Sheet'
              appearance='default'
              size='sm'
              value={this.state.template}
              cleanable={false}
              data={[
                {
                  key: 'Pile, Dwall',
                  label: 'Pile, Dwall',
                  value: 'Pile, Dwall',
                },
                { key: 'Slab 5D', label: 'Slab 5D', value: 'Slab 5D' },
                { key: 'Archi', label: 'Archi', value: 'Archi' },
              ]}
              onChange={this.handleChangeTemplate}
            />
            <br />
          </Modal>
        </>
      );
    }
  }