import { Icon, Input, message, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { DraggableArea } from 'react-draggable-tags';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import styled from 'styled-components';
import { apiFetchPublicSettingThisProject } from '../../../api';
import { colorType } from '../../../constants';
import { mongoObjectId } from '../../../utils';
import ButtonColumnTag from '../generalComponents/ButtonColumnTag';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import { addProjectHeaderToTree, flattenAllTreeChildNode1, getTreeFlattenOfNodeInArray, updateChildrenNode } from './FormDrawingTypeOrder';



const FormFolderUploadOrganize = ({ onClickCancelModal, applyFolderUploadOrganize, stateProject, stateRow }) => {



   const { projectId, token, authoritiesType: authoritiesTypeInput, pageUploadType } = stateProject.allDataOneSheet;

   const { rowsFileAllInit } = stateRow;

   const authoritiesTypeUpperCase = authoritiesTypeInput.toUpperCase();


   const [input, setInput] = useState([]);

   const [modalTitle, setModalTitle] = useState(null);
   const [itemNode, setItemNode] = useState(null);


   const [panelConfirmSave, setPanelConfirmSave] = useState(false);

   const [folderDeletedIds, setFolderDeletedIds] = useState([]);



   useEffect(() => {

      const fetchProjectSettings = async () => {
         try {
            const res = await apiFetchPublicSettingThisProject({ token, projectId });
            const projectSetting = res.data[0];

            const folderTreeFormUpload = projectSetting.folderTreeFormUpload || {};
            const folderTreeThisTypeUpload = folderTreeFormUpload[pageUploadType] || [];
            setInput(addProjectHeaderToTree(folderTreeThisTypeUpload, authoritiesTypeUpperCase));

         } catch (err) {
            console.log(err);
         };
      };
      fetchProjectSettings();
   }, []);


   const addFolderBelow = (node) => {
      node.children.push({
         title: 'New Folder',
         id: mongoObjectId(),
         parentId: node.id,
         treeLevel: node.treeLevel + 1,
         expanded: true,
         children: []
      });
      setInput(addProjectHeaderToTree(flattenAllTreeChildNode1(input[0].children), authoritiesTypeUpperCase));
   };

   const deleteFolder = (node) => {

      const currentTreeFlattened = flattenAllTreeChildNode1(input[0].children);

      const childrenInTree = currentTreeFlattened.filter(x => x.parentId === node.id);
      const childrenInRowsAll = rowsFileAllInit.filter(x => x.parentId === node.id);

      if (childrenInTree.length > 0 || childrenInRowsAll.length > 0) {
         return message.warn('Please delete all folders and files inside before deleting this folder!', 3);
      } else {
         setItemNode(node);
         setModalTitle('Delete Folder');
      };
   };

   const editFolderName = (node) => {
      setItemNode(node);
      setModalTitle('Edit Folder Name');
   };
   const confirmAction = (modalTitle, text) => {
      if (modalTitle === 'Delete Folder') {
         let idsToDelete = flattenAllTreeChildNode1(itemNode.children).map(x => x.id);
         idsToDelete.push(itemNode.id);

         let currentNodeFlatten = flattenAllTreeChildNode1(input[0].children);
         let outputArray = currentNodeFlatten.filter(x => idsToDelete.indexOf(x.id) === -1);
         setInput(addProjectHeaderToTree(outputArray, authoritiesTypeUpperCase));

         setFolderDeletedIds([...folderDeletedIds, itemNode.id]);

      } else if (modalTitle === 'Edit Folder Name') {
         itemNode.title = text;
         setInput(addProjectHeaderToTree(flattenAllTreeChildNode1(input[0].children), authoritiesTypeUpperCase));
      };
      setModalTitle(null);
   };


   return (
      <Container>
         <PanelStyled>
            <SortableTreeStyled
               treeData={input}
               onChange={treeData => setInput(treeData)}
               isVirtualized={false}
               onMoveNode={({ nextParentNode, node }) => {
                  updateChildrenNode([node], nextParentNode.treeLevel + 1 - node.treeLevel);
               }}
               canDrag={(props) => {
                  return true;
               }}
               canDrop={(props) => {
                  return true;
               }}
               generateNodeProps={(props) => {
                  const { node } = props;

                  return ({
                     buttons: node.treeLevel === 0 ? [
                        <IconBtn type='plus' onClick={() => addFolderBelow(node)} />,
                        // <IconBtn type={isEyeShownType} onClick={() => isolateView(node)} color={isEyeShownColor} />
                     ] : [
                        <IconBtn type='plus' onClick={() => addFolderBelow(node)} />,
                        <IconBtn type='edit' onClick={() => editFolderName(node)} />,
                        <IconBtn type='delete' onClick={() => deleteFolder(node)} />,
                        // <IconBtn type={isEyeShownType} onClick={() => isolateView(node)} color={isEyeShownColor} />
                     ]
                  });
               }}
            />
         </PanelStyled>


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => setPanelConfirmSave(true)} // remove authoritiesTypeUpperCase item before apply...
            />
         </div>


         {modalTitle && (
            <ModalStyled
               title={modalTitle}
               visible={modalTitle !== null ? true : false}
               footer={null}
               onCancel={() => setModalTitle(null)}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.6}
            >
               <ConfirmOrEditNameModal
                  modalTitle={modalTitle}
                  confirmAction={confirmAction}
                  itemNode={itemNode}
                  input={input}
                  onClickCancel={() => setModalTitle(null)}
               />
            </ModalStyled>
         )}


         {panelConfirmSave && (
            <ModalStyled
               title={'Confirmation'} visible={panelConfirmSave}
               footer={null} destroyOnClose={true} centered={true}
            >
               <div style={{ padding: 20 }}>{`Are you sure to save the folder tree ?`}</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonGroupComp
                     onClickCancel={() => setPanelConfirmSave(false)}
                     newTextBtnApply={'Yes'}
                     onClickApply={() => applyFolderUploadOrganize(input[0].children, folderDeletedIds)}
                  />
               </div>
            </ModalStyled>
         )}

      </Container>
   );
};

export default FormFolderUploadOrganize;


const SortableTreeStyled = styled(SortableTree)`
   .rst__node {
      height: 45px !important;
   }
   .rst__rowContents {
      min-width: fit-content;
      background-color: transparent !important;
   }
`;
const IconStyle = styled(Icon)`
   font-size: 14px;
   margin: 2px;
   padding: 4px;
   &:hover {
      background-color: ${colorType.grey0};
   };
`;
const ModalStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;
const Container = styled.div`
   max-height: ${`${window.innerHeight * 0.8}` + 'px'};
   width: 100%;
   display: flex;
   flex-direction: column;
`;
const PanelStyled = styled.div`
   width: 100%;
   float: right;
   overflow-x: hidden;
   border-bottom: 1px solid ${colorType.grey4};
`;

const IconBtn = ({ type, onClick, color }) => {
   const text = type === 'plus' ? 'Add Sub Folder'
      : type === 'delete' ? 'Delete Folder'
         : type === 'edit' ? 'Edit Name'
            : type === 'eye' ? 'Isolate View'
               : type === 'eye-invisible' ? 'Isolate View'
                  : 'Merge All Sub Folder'

   return (
      <Tooltip title={text}>
         <IconStyle type={type} onClick={onClick} style={{ color: color || 'black' }} />
      </Tooltip>
   );
};


const ConfirmOrEditNameModal = ({ modalTitle, confirmAction, itemNode, onClickCancel }) => {

   const [value, setValue] = useState(null);
   useEffect(() => {
      setValue(itemNode.title || '');
   }, []);


   const onClickApplyModal = () => {
      if (modalTitle === 'Delete Folder') {
         confirmAction(modalTitle);
      } else if (modalTitle === 'Edit Folder Name') {
         if (!value) {
            return message.warn('Please key in a valid name!', 2)
         } else {
            confirmAction(modalTitle, value);
         };
      };
   };

   return (
      <div style={{ padding: 20, width: '100%', maxHeight: window.innerHeight * 0.7 }}>
         {modalTitle === 'Delete Folder' ? (
            <div style={{ color: 'black', marginBottom: 10 }}>Are you sure to delete the folder <span style={{ fontWeight: 'bold' }}>{itemNode.title}</span>?</div>
         ) : (
            <Input
               placeholder='Enter new folder name...'
               style={{ width: '100%', marginBottom: 10 }}
               value={value}
               onChange={(e) => setValue(e.target.value)}
            />
         )}

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancel}
               onClickApply={onClickApplyModal}
               newTextBtnApply={'OK'}
            />
         </div>
      </div>
   );
};











