
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Icon, Sidenav, Nav, Dropdown, IconStack } from 'rsuite';
import { message, Tooltip, Typography } from 'antd'
import Split from 'react-split'
import axios from "axios";
import {
  unloadForgeExtension, setupForgeExtensionBeforeLoadedIssuePage,
  getAllModelsElementdbIdsWithCondition
} from '../../../components/module_BimApp/function/ForgeFunction'
import _ from 'lodash'

import {SideBarSelectParameter} from '../../module_BimApp/sidebar'

import {DialogIssueInformation} from '../../module_BimApp/dialog'
import {Markup2D} from '../../module_BimApp/dock_forge'
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction'
import base64url from 'base64url'
import { Intent, Position, Toaster, } from "@blueprintjs/core";
import IssueViewer from '../CoordinationApp/IssueViewer'
const Autodesk = window.Autodesk;
const { Text, Paragraph } = Typography

let documentId = ''
let projectId = ''
let userCreatedProject = ''
let label = ''
let check = false
let mainLoadOptions = {}
let count = 0
let elementColor = null
let viewPort = null
let version
function IssuePage(props) {
  const [viewer, setViewer] = useState(null)
  const [objectId, setObjectId] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [issue, setIssue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [displayInfoViewpoint, setDisplayInfoViewpoint] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)
  const [displayPanel, setDisplayPanel] = useState({
    selectParameterPanel: false
  })
  const [disabledBtn, setDisabledBtn] = useState({
    btnSelectParameter: false
  })
  const [modelLoaded, setModelLoaded] = useState(false)
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [versionModel, setVersionModel] = useState('')
  const toaster = useRef()
  useEffect(e => {
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    let params = base64url.decode(window.location.pathname.split('/')[2])
    let temp = params.split('/')
    projectId = temp[0]
    userCreatedProject = temp[1]
    label = temp[2]
    check = temp[3] === 'true' ? true : false
    setLoading(true)
    setModelLoaded(true)
    axios.post("/api/issue/get-issue-from-project", { projectId: projectId, label: label, email: props.email })
      .then(res => {
        setIssue(res.data.issue)
        setLoading(false)
      })
      .catch(err => {

      })
  }, [])
  useEffect(e => {
    if (issue) {
      axios.post(`/api/issue/get-model`, { item: issue.mainModel, isLastVersion: check })
        .then(res => {
          if (res.data.status === 'success') {
            mainLoadOptions = {
              applyRefPoint: true,
              modelNameOverride: res.data.name,
              isAEC: true,
              guid: res.data.guid,
              viewableID: res.data.viewableID,
              itemId: res.data.itemId,
              version: res.data.version
            };
            version = res.data.version
            setItemId(res.data.itemId)
            setVersionModel(res.data.version)
            setItemName(res.data.name)
            if (issue.mainModel.version !== res.data.version) {
              let path = base64url(`${projectId}/${userCreatedProject}/${label}/${false}`)
              toaster.current.show({
                timeout: 0,
                action: {
                  href: `${window.location.origin}/issue/${path}`,
                  text: <strong>Reload</strong>,
                },
                icon: 'warning-sign',
                intent: Intent.WARNING,
                message: (
                  <Text strong style={{ color: 'white' }}>
                    {`Issue created at version ${issue.mainModel.version}. Are you want to load original version?`}
                  </Text>
                )
              })
            }
            setObjectId(res.data.objectId)
          }
          else {
            message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
          }
        })
        .catch(err => {
          message.error(`Can't load model.`);
        })
    }
  }, [issue])


  const _onDocumentLoadFailure = (viewerErrorCode) => {
    console.log(viewerErrorCode)
  }
  const _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      viewer.loadModel(svfUrl, loadOptions, _onLoadModelSuccess, () => { })
    }
  }
  const _onLoadModelSuccess = () => {
    if (count === 0) {
      handleSetColorElement(elementColor)
    }
  }

  const handleSetColorElement = (elements) => {
    let models = viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      viewer.clearThemingColors(model)
    })
    let isAllLoaded = viewer.isLoadDone({ onlyModels: models })
    if (isAllLoaded) {
      setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
      let countModel = models.length
      let tempElement = {}
      _.forEach(models, async model => {
        let instanceTree = model.getData().instanceTree;
        let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
        countModel--
        let count1 = temp.length
        _.forEach(temp, id => {
          model.getProperties(id, (modelAProperty) => {
            tempElement[modelAProperty.externalId] = id
            count1--
            if (count1 === 0) {
              _.forEach(elements, async el => {
                let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                if (index >= 0) {
                  if (tempElement[el.guid]) {
                    let color = convertHexColorToVector4(el.color)
                    viewer.setThemingColor(tempElement[el.guid], color, models[index])
                  }
                }
              })
              setModelLoaded(false)
              viewer.restoreState(viewPort)
              setDisplayInfoViewpoint(true)
            }
          })
        })
      })
    } else {
      viewer.waitForLoadDone({ onlyModels: models })
        .then(res => {
          setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
          let countModel = models.length
          let tempElement = {}
          _.forEach(models, async model => {
            let instanceTree = model.getData().instanceTree;
            let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
            countModel--
            let count1 = temp.length
            _.forEach(temp, id => {
              model.getProperties(id, (modelAProperty) => {
                tempElement[modelAProperty.externalId] = id
                count1--
                if (count1 === 0) {
                  _.forEach(elements, async el => {
                    let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                    if (index >= 0) {
                      if (tempElement[el.guid]) {
                        let color = convertHexColorToVector4(el.color)
                        viewer.setThemingColor(tempElement[el.guid], color, models[index])
                      }
                    }
                  })
                  setModelLoaded(false)
                  viewer.restoreState(viewPort)
                  setDisplayInfoViewpoint(true)
                }
              })
            })
          })
        })
    }
  }

  const dragSplit = () => {

  }
  const handleInfoViewPoint = () => {
    setDisplayInfoViewpoint(true)
  }
  const handleCloseInfoViewPointPanel = () => {
    setDisplayInfoViewpoint(false)
  }
  const restoreSaveView = (check, e) => {
    try {
      let section = viewer.getExtension('Autodesk.Section')
      section.activate()
      section.deactivate();
    } catch { }
    let data = e.props.item
    if (data.mainModel.version !== version) {
      toaster.current.show({
        timeout: 0,
        icon: 'warning-sign',
        intent: Intent.WARNING,
        message: (
          <Text strong style={{ color: 'white' }}>
            {`Issue created at version ${data.mainModel.version}`}
          </Text>
        )
      })
    }
    let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let node = viewer.impl.model.getDocumentNode()
    let loadOptions = {
      globalOffset: globalOffset,
      applyRefPoint: true,
      modelNameOverride: data.mainModel.name,
      isAEC: true,
      guid: data.mainModel.guid,
      viewableID: data.mainModel.viewableID,
      itemId: data.mainModel.itemId,
      version: data.mainModel.version,
      acmSessionId: document.acmSessionId
    }
    if (data.guid === node.data.guid) {
      // this.props.viewer.restoreState(v.viewPort)  
      handleOtherModel(data, check)
      return false
    } else {
      let viewables = document.getRoot().search({ 'type': 'geometry', 'guid': data.guid }, true)
      if (viewables.length === 0)
        viewables = document.getRoot().search({ 'type': 'geometry', 'viewableID': data.viewableID }, true)
      if (viewables.length === 0 && data.viewName)
        viewables = document.getRoot().search({ 'type': 'geometry', 'name': data.viewName }, true)
      if (viewables.length !== 0) {
        viewer.loadDocumentNode(document, viewables[0], loadOptions)
          .then(i => {
            unloadForgeExtension(viewer, false)
            handleOtherModel(data, check)
          })
          .catch(r => {
            message.warning(`Load model failed`)
          })
      } else {
        handleOtherModel(data, check)
        message.warning(`View isn't existing in file, use current view`)
      }
      return false
    }
  }

  const handleOtherModel = (data, check) => {
    viewPort = data.viewPort
    elementColor = data.attachElementColor
    count = data.otherModel.length
    if (data.otherModel.length > 0) {
      const models = viewer.impl.modelQueue().getModels()
      let tempModeled = []
      _.forEach(models, v => {
        if (v.myData.urn !== viewer.impl.model.myData.urn) {
          let index = _.findIndex(data.otherModel, e => { return e.itemId === v.myData.loadOptions.itemId && e.version === v.myData.loadOptions.version && e.guid === v.myData.loadOptions.guid })
          if (index < 0)
            viewer.unloadModel(v)
          else
            tempModeled.push(index)
        }
      })
      _.forEach(data.otherModel, (v, k) => {
        if (tempModeled.includes(k)) {
          count--
          if (count === 0) {
            handleSetColorElement(data.attachElementColor)
          }
          return
        }
        axios.post(`/api/issue/get-model`, { item: v, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                viewableID: res.data.viewableID,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              var documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), _onDocumentLoadFailure);
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(err => {
            count--
            message.error(`Can't load model.`);
          })
      })
    } else {
      handleSetColorElement(data.attachElementColor)
    }
  }
  const handleChangeMarkup2DPanel = () => {
    setMarkUp2d(!markUp2d)
  }
  const callbackDisplayPanel = (name, value) => {
    let temp = Object.assign({}, displayPanel);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    setDisplayPanel(temp)
  }
  const callbackDisableBtn = (name, value) => {
    let temp = Object.assign({}, disabledBtn);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    setDisabledBtn(temp)
  }
  const handleTogglePanel = (name, functionName) => {
    let temp = Object.assign({}, displayPanel);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    setDisplayPanel(temp)

  }
  const handleOpenDialogIssueInformation = (viewer, globalOffset) => {
    setViewer(viewer)
    setGlobalOffset(globalOffset)
    setDisplayInfoViewpoint(true)
  }
  return (
    <div>
           <div id='zoom-issue-image' />
      <Sidenav style={{ position: 'absolute', zIndex: '100', height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`, backgroundColor: 'white', width: '40px' }}
        expanded={false}
      >
        <Sidenav.Body>
          <Nav >
            <Tooltip title="Issue" placement="right">
              <Nav.Item eventKey="1" style={{ height: '40px', width: '40px' }}
                icon={<IconStack style={{
                  left: '11px', top: '10px', height: '40px', display: 'contents', color:
                    !modelLoaded && '#636363'
                }}  >
                  <Icon icon="file-o" stack="1x" style={{ fontSize: 18, top: 5.5 }} />
                  <Icon icon="exclamation-triangle" stack="1x" style={{ fontSize: 10, top: 7 }} />
                  <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                </IconStack>}
                disabled={modelLoaded} onClick={handleInfoViewPoint} />
            </Tooltip>
          </Nav>
          <Tooltip title="Quantities" placement="right" overlayStyle={{ zIndex: 10 }}>
            <Dropdown placement="rightStart" eventKey="3" style={{ height: '40px', width: '40px' }} disabled={modelLoaded}
              icon={
                <IconStack style={{ display: 'contents' }}  >
                  <Icon icon="usd" stack="1x" style={{ top: 6, left: -5 }} />
                  <Icon icon="usd" stack="1x" style={{ top: 6, left: 4 }} />
                  <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                </IconStack>
              }>
              <Dropdown.Item eventKey="3-1" icon={<Icon icon="slack" />}
                onClick={handleTogglePanel.bind(this, 'selectParameterPanel', 'Extract Quantities')}
                disabled={disabledBtn.btnSelectParameter} >Extract Quantities</Dropdown.Item>
            </Dropdown>
          </Tooltip>
        </Sidenav.Body>
      </Sidenav>
      <Split id='splitForgeViewer'
        sizes={[50, 50]} minSize={100} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
        style={{
          position: "absolute", height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`,
          width: "calc(100% - 40px)", display: 'flex', justifyItems: 'center', alignItems: 'center', left: '40px'
        }} onDrag={dragSplit}
      >
        <div id='content3D' style={{ height: '100%', width: "100%" }}>
          <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
          <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
          {objectId &&
            <IssueViewer {...props}
              issue={issue}
              handleOpenDialogIssueInformation={handleOpenDialogIssueInformation}
              objectId={objectId}
              mainLoadOptions={mainLoadOptions}
              handleChangeMarkup2DPanel={handleChangeMarkup2DPanel}
              markUp2d={markUp2d}
              setModelLoaded={setModelLoaded}
            />}
        </div>
        <div id='content2D' style={{ height: '100%', width: "100%" }}>
          <div id="forgeViewer2D" style={{ position: "relative", height: '100%', width: "100%" }}></div>
        </div>
      </Split>

      <SideBarSelectParameter key={'4'} displayPanel={displayPanel.selectParameterPanel}
        viewer={viewer} onDisplayPanelChange={callbackDisplayPanel}
        heightNavigation={'53.78px'}
        email={props.email} itemId={itemId} onDisabledBtnChange={callbackDisableBtn}
        itemName={itemName} version={versionModel} />



      {displayInfoViewpoint &&
        <DialogIssueInformation
          open={displayInfoViewpoint}
          handleClose={handleCloseInfoViewPointPanel}
          viewer={viewer}
          viewPoint={issue}
          email={props.email}
          projectId={projectId}
          userCreatedProject={userCreatedProject}
          isProject={false}
          newTab={true}
          itemId={itemId}
          restoreSaveView={restoreSaveView}
        />
      }
      {markUp2d && <Markup2D openDock={markUp2d} viewer={viewer}
        onChangeDockDisplay={handleChangeMarkup2DPanel} />}

      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />


    </div>
  );
}

IssuePage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(IssuePage)