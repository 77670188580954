import React from 'react';
import { sliceString } from '../function/DialogFunction'
import _ from 'lodash'
import {
    formatNumber, getAllDetailScheduleID, getAllStatusTask, hexToB, hexToR, hexToG
} from '../function/TableFunction'

export default  (statusOptions, contractType = 'LTA Civil Project') => {
    let columns = []
    _.forEach(statusOptions, v => {
        if (contractType == 'Building Project') {
            if (v === 'code5D') {
                columns.push({
                    Header: '5D Code', accessor: 'code5D', id: 'code5D', aggregate: 'count', units: false, filter: "includes", width: 50,
                    Aggregated: ({ cell: { row, value } }) => {
                        return <span title={value}> {value}</span>
                    }
                })
            }
        }
        if (v === 'masterID') {
            if (contractType == 'Building Project') return
            columns.push({
                Header: 'Master Schedule Id', accessor: 'masterID', id: 'masterID', aggregate: 'count', units: false, filter: "includes",
                Aggregated: ({ cell: { row, value } }) => {
                    if (contractType != 'Building Project') {
                        const temp = []
                        _.forEach(row.subRows, v => {
                            temp.push(v.original.masterID)
                        })
                        let combine = temp.join(' / ')
                        let combine1 = sliceString(combine)
                        return <span title={combine}> {combine1}</span>
                    } else
                        return <span title={value}> {value}</span>
                }
            })
        }
        else if (v === 'detailID') {
            if (contractType == 'Building Project') return
            columns.push({
                Header: 'Detail Schedule ID', accessor: 'detailID', id: 'detailID', aggregate: 'count', units: false, filter: "includes",
                Aggregated: ({ cell: { row, value } }) => {
                    if (contractType != 'Building Project') {
                        const temp = []
                        _.forEach(row.subRows, v => {
                            temp.push(v.original.detailID)
                        })
                        let combine = temp.join(' / ')
                        let combine1 = sliceString(combine)
                        return <span title={combine}> {combine1}</span>
                    } else
                        return <span title={value}> {value}</span>
                }
            })
        }
        else if (v === 'status') {
            columns.push({
                Header: 'Status', accessor: 'status', id: 'status', aggregate: 'count', units: false, filter: "includes", 
                Aggregated: ({ cell: { row, value } }) => {
                    if (contractType != 'Building Project') {
                        const combine = getAllStatusTask(row)
                        let combine1 = sliceString(combine)
                        return <span title={combine}> {combine1}</span>
                    }
                    else
                        return <span title={value}> {value}</span>
                }
            })
        }
        else if (v === 'percentArea') {
            columns.push({
                Header: 'Area', accessor: 'percentArea', id: 'percentArea', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
            })
        }
        else if (v === 'percentLength') {
            columns.push({
                Header: 'Length', accessor: 'percentLength', id: 'percentLength', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
            })
        }
        else if (v === 'percentVolume') {
            columns.push({
                Header: 'Volume', accessor: 'percentVolume', id: 'percentVolume', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
            })
        }
        else if (v === 'percent') {
            if (contractType == 'Building Project') return
            columns.push({
                Header: 'Total Percentage', accessor: 'percent', id: 'percent', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => `${formatNumber(value)}%`,
                Aggregated: ({ cell: { value } }) => `${formatNumber(value)}%`,
            })
        } else if (v === 'totalArea') {

            columns.push({
                Header: 'Total Area', accessor: 'totalArea', id: 'totalArea', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}` : `${formatNumber(value)}`,
            })
        } else if (v === 'totalVolume') {

            columns.push({
                Header: 'Total Volume', accessor: 'totalVolume', id: 'totalVolume', aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}` : `${formatNumber(value)}`,
            })
        } else if (v !== 'code5D') {
            columns.push({
                Header: v, accessor: v, id: v, aggregate: 'sum', units: false, filter: "includes", unableGroup: true,
                Cell: ({ cell: { value } }) => contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`,
                Aggregated: ({ cell: { value, row } }) => {
                    return <>{contractType != 'Building Project' ? `${formatNumber(value)}%` : `${formatNumber(value)}`}</>
                }
            })
        }
    })

    return columns
}
