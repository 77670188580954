import { Drawer, Button, Icon } from 'antd';
import React, { useState, useEffect } from 'react';
import { DraggableArea } from 'react-draggable-tags';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import styled from 'styled-components';
import { colorType } from '../../../constants';
import { mongoObjectId } from '../../../utils';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import { convertFlattenArraytoTree1, getTreeFlattenOfNodeInArray } from '../pageSpreadsheet/FormDrawingTypeOrder';
import { getKeyTextForSheet } from '../pageSpreadsheet/PanelSetting';



const PanelTreeQuickFilter = ({ onCloseDrawer, rightDrawerVisible, stateRow, stateProject, pageTabDiscipline }) => {


   const { pageSheetTypeName } = stateProject.allDataOneSheet;
   const refType = getKeyTextForSheet(pageSheetTypeName);

   const { drawingTypeTree, treeNodeIdFilterView } = stateRow;

   const treeNodeFilter = drawingTypeTree.filter(x => !x['rfaNumber']);

   const [input, setInput] = useState([]);

   useEffect(() => {
      const dataInput = getNodeTreeQuickFilter(treeNodeFilter, refType, pageTabDiscipline === 'ALL');
      setInput(dataInput);
   }, []);

   const [nodeFilterId, setNodeFilterId] = useState(treeNodeIdFilterView);
   const [arrayFilterId, setArrayFilterId] = useState([]);

   useEffect(() => {
      let nodeArrayOutput;
      if (nodeFilterId) {
         const nodeFound = treeNodeFilter.find(x => x.id === nodeFilterId); // To get rid of 'children' key
         if (nodeFound) {
            const nodeArray = getTreeFlattenOfNodeInArray(treeNodeFilter, nodeFound);
            nodeArrayOutput = nodeArray.map(x => x['id']);
         };
      };

      if (nodeArrayOutput) {
         setArrayFilterId(nodeArrayOutput);
      } else {
         setArrayFilterId([]);
      };
   }, [nodeFilterId]);



   return (
      <DrawerStyled
         title={pageSheetTypeName === 'page-spreadsheet' ? 'DMS Tree' : `${refType.toUpperCase()} Tree`}
         placement='right'
         closable={false}
         onClose={() => onCloseDrawer(nodeFilterId)}
         visible={rightDrawerVisible}
         width={pageSheetTypeName === 'page-spreadsheet' ? 550 : 250}
      >
         {input.length > 0 && <SortableTreeStyled
            treeData={input}
            isVirtualized={false}
            canDrag={(props) => false}
            canDrop={(props) => false}
            onChange={treeData => { }}
            generateNodeProps={(props) => {
               const { node } = props;
               const { treeLevel } = node;

               const isSelected = arrayFilterId.indexOf(node.id) !== -1;
               return ({
                  onClick: () => {
                     if (treeLevel === 1) return;
                     if (treeLevel === 2) {
                        setNodeFilterId(null);
                     } else {
                        setNodeFilterId(node.id);
                     };
                  },
                  style: {
                     backgroundColor: (!nodeFilterId || isSelected) ? colorType.grey1 : 'white',
                     cursor: 'pointer'
                  }
               });
            }}
         />}
      </DrawerStyled>
   );
};

export default PanelTreeQuickFilter;




const DrawerStyled = styled(Drawer)`
   .ant-drawer-body {
      padding: 13px;
      padding-left: 0;
      margin-left: 1px
   };

`;

const SortableTreeStyled = styled(SortableTree)`
   .rst__node {
      height: 36px !important;
   }
   .rst__rowWrapper {
      padding: 1px 10px 10px 0;
   }
   .rst__collapseButton {
      display: none;
   }
   .rst__rowContents {
      min-width: 	0;
      background-color: transparent !important;
   }
   .rst__rowLabel {
      padding-right: 6px;
   }
`;


const getNodeTreeQuickFilter = (tree, refType, isWithProjectName) => {
   const treeClone = tree.map(x => ({ ...x }));
   return isWithProjectName ? [{
      title: refType.toUpperCase(),
      id: 'node-project-level-sheet-id',
      treeLevel: 1,
      expanded: true,
      children: convertFlattenArraytoTree1(treeClone.map(x => ({ ...x })))
   }] : convertFlattenArraytoTree1(treeClone.map(x => ({ ...x })));
};



