import { pdf } from '@react-pdf/renderer';
import { message } from 'antd';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getCellModifiedTemp, overwriteCellsModified } from '../../../../../actions/dms/dms_cell';
import { getSheetRows } from '../../../../../actions/dms/dms_row';
import { apiClientGetKeyUpload, apiClientUploadFile, apiCreateOrUpdateRowsUploadFile, apiCreateRowsHistory, apiDeleteFileS3, apiDeleteMultiFormRows, apiDeleteRows, apiDeleteRowsHistory, apiDeleteRowsUploadFile, apiFetchAllRowsFileThisType, apiFetchCellHistoryByIds, apiFetchCellHistoryThisProject, apiFetchDataMultiByDisciplineForm, apiFetchDataMultiForm, apiFetchDataProjectByDiscipline, apiFetchDataThisProject, apiFetchPublicSettingThisProject, apiFetchRowsById, apiSendEmail, apiSendEmailDevTest,apiSendEmailPM ,apiUpdateOrCreateCellHistory, apiUpdateOrCreateRows, apiUpdateOrCreateRowsMultiForm, apiUpdateRowsHistory, apiUpdateSettingPublic, apiUpdateSettingUser, apiUploadFileBlob, apiUploadFiles, apiUploadFilesRfa } from '../../../api';
import { colorExcel, colorType, EDIT_DURATION_MIN, tradeArrayForm, tradeArrayMeetingMinutesForm } from '../../../constants';
import { checkIfStringContainNumberOnly, compareDatesForm, configExcelWidth, convertCellTempToHistory, convertCompanyNameFolderS3, convertDrawingVersionToHistory, convertTradeName, debounceFnc, extractCellInfo, genId, getDataToExportReport, getDrawingLinksFromCmpPicked, getDataToUpdateRowInDtFormLink, getDisciplineOfRefString, getDisciplineStringRfc, getRefStringWithVersion, mongoObjectId, removeSecondPart, sortDataByCreatedAt } from '../../../utils';
// import { classify } from '../../../utils/classify';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import { getHeadersForm, getOutputRowsAllSorted, headersConsultantWithNumber } from '../generalComponents/ComponentDmsOverall';
import ExportPdf from '../generalComponents/ExportPdf';
import ExportPdfDataView from '../generalComponents/ExportPdfDataView';
import FormFilter from '../generalComponents/FormFilter';
import FormGroup from '../generalComponents/FormGroup';
import FormSort from '../generalComponents/FormSort';
import PanelAddNewMultiForm from '../generalComponents/PanelAddNewMultiForm';
import PanelAddNewMultiFormPM from '../generalComponents/PanelAddNewMultiFormPM';
import PanelConfirm from '../generalComponents/PanelConfirm';
import PanelConfirmResetMode from '../generalComponents/PanelConfirmResetMode';
import PanelExportPdfAndExcel from '../generalComponents/PanelExportPdfAndExcel';
import PanelImportExcelData from '../generalComponents/PanelImportExcelData';
import PanelPickNumber from '../generalComponents/PanelPickNumber';
import ReorderColumnForm from '../generalComponents/ReorderColumnForm';
import TableAllFormActivityHistory from '../generalComponents/TableAllFormActivityHistory';
import { getListOfStringFolder } from '../generalComponents/ViewTemplateSelect';
import { getInfoValueFromRefDataForm } from './CellForm';
import ColorizedForm from './ColorizedForm';
import FormCellColorizedCheck from './FormCellColorizedCheck';
import FormDateAutomation from './FormDateAutomation';
import FormDrawingTypeOrder, { compareCurrentTreeAndTreeFromDB, flattenAllTreeChildNode1, getTreeFlattenOfNodeInArray } from './FormDrawingTypeOrder';
import FormFolderUploadOrganize from './FormFolderUploadOrganize';
import PanelAddAuthorities from './PanelAddAuthorities';
import PanelAddFormDiscipline from './PanelAddFormDiscipline';
import PanelAddNewRFA, { versionArray } from './PanelAddNewRFA';
import PanelConfirmDeleteFiles from './PanelConfirmDeleteFiles';
import PanelProjectSettings from './PanelProjectSettings';
import TableActivityHistory from './TableActivityHistory';
import TableCellHistory from './TableCellHistory';
import TableDrawingDetail from './TableDrawingDetail';



export const getFileNameFromLinkResponse = (link) => /[^/]*$/.exec(link)[0];


const PanelSetting = (props) => {

   const { stateRow, getSheetRows, stateProject, stateCell, getCellModifiedTemp, overwriteCellsModified, allDataTableInfo } = props;

   const { projectIsAppliedRfaView, companies, pageSheetTypeName, isBothSideActionUser, projectName } = stateProject.allDataOneSheet;
   const { rowsSelected } = stateRow;
   const {
      panelType, panelSettingType, commandAction, onClickCancelModal, setLoading, buttonPanelFunction
   } = props;


   const refType = getKeyTextForSheet(pageSheetTypeName);




   const applyReorderColumns = (data) => commandAction({ type: 'reorder-columns', data });

   const applyFilter = (filter) => {
      commandAction({ type: 'filter-by-columns', data: { modeFilter: filter } });
   };
   const applyResetMode = (modeReset) => {
      const modeResetObj = {};
      modeReset.forEach(type => {
         if (type.header === 'Filter' && type.mode === 'hidden') modeResetObj.modeFilter = [];
         if (type.header === 'Sort' && type.mode === 'hidden') modeResetObj.modeSort = {};
         if (type.header === 'Search' && type.mode === 'hidden') modeResetObj.modeSearch = {};
      });
      return commandAction({
         type: 'reset-filter-sort',
         data: {
            rowsAll: stateRow.rowsAll,
            ...modeResetObj
         }
      });
   };
   const applyQuitGroupingMode = () => {
      return commandAction({
         type: 'reset-filter-sort',
         data: { modeGroup: [], modeSearch: {} }
      });
   };


   const applyGroup = (data) => commandAction({ type: 'group-columns', data: { modeGroup: data } });

   const applyColorization = (data) => commandAction({ type: 'drawing-colorized', data });

   const setCellHistoryArr = debounceFnc((data) => commandAction({ type: 'highlight-cell-history', data }), 1);

   const applySort = (data) => commandAction({ type: 'sort-data', data: { modeSort: data } });

   const applyViewTemplate = (name) => {

      if (!name) {
         return message.warn('Please key in an valid name!');
      };

      let { allDataOneSheet: { publicSettings: { headers } }, userData: { headersShown, headersHidden, nosColumnFixed, colorization } } = stateProject;
      const { viewTemplateNodeId, viewTemplates, modeFilter, modeSort, drawingTypeTree } = stateRow;

      const allCurrentTemplate = getListOfStringFolder(drawingTypeTree, viewTemplates);

      const foundSameName = allCurrentTemplate.find(x => x.text === name);

      if (foundSameName) {
         return message.warn('This template has already existed, please choose a new name!');
      };

      headersShown = headersShown.map(hd => headers.find(x => x.text === hd).key);
      headersHidden = headersHidden.map(hd => headers.find(x => x.text === hd).key);


      commandAction({
         type: 'add-view-templates',
         data: {
            viewTemplates: [
               ...viewTemplates.filter(x => x.name),
               {
                  id: mongoObjectId(),
                  name,
                  headersShown,
                  headersHidden,
                  nosColumnFixed,
                  colorization,
                  viewTemplateNodeId,
                  modeFilter,
                  modeSort
               }
            ]
         }
      });
   };


   const onClickInsertRow = (nosOfRows) => {

      let { rowsAll, idRowsNew, rowsUpdatePreRowOrParentRow } = stateRow;

      const idsArr = genId(nosOfRows);
      idRowsNew = [...idRowsNew, ...idsArr];

      let newRows = [];
      let rowBelow;
      if (panelSettingType === 'Insert Drawings Below') {
         let rowAbove = panelType.cellProps.rowData;
         newRows = idsArr.map((id, i) => {
            return ({
               id, _rowLevel: 1,
               _parentRow: rowAbove._parentRow,
               _preRow: i === 0 ? rowAbove.id : idsArr[i - 1]
            });
         });
         rowBelow = rowsAll.find(r => r._preRow === rowAbove.id);
         if (rowBelow) {
            rowBelow._preRow = idsArr[idsArr.length - 1];
         };

      } else if (panelSettingType === 'Insert Drawings Above') {
         rowBelow = rowsAll.find(r => r.id === panelType.cellProps.rowData.id);

         newRows = idsArr.map((id, i) => {
            return ({
               id, _rowLevel: 1,
               _parentRow: rowBelow._parentRow,
               _preRow: i === 0 ? rowBelow._preRow : idsArr[i - 1]
            });
         });
         rowBelow._preRow = idsArr[idsArr.length - 1];
      };

      if (rowBelow) {
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelow);
      };
      newRows.forEach(row => {
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
      });
      rowsAll = [...rowsAll, ...newRows];

      const rowsOutput = _processRowsChainNoGroupFnc1([...rowsAll]);
      commandAction({
         type: 'insert-drawings',
         data: {
            rowsAll: rowsOutput,
            rowsUpdatePreRowOrParentRow,
            idRowsNew
         }
      });
   };
   const onClickFolderInsertSubRows = (nosOfRows) => {
      let { rowsAll, idRowsNew, rowsUpdatePreRowOrParentRow } = stateRow;

      let idsArr = genId(nosOfRows);
      idRowsNew = [...idRowsNew, ...idsArr];

      let newRows = idsArr.map((id, i) => {
         return ({
            id, _rowLevel: 1,
            _parentRow: panelType.cellProps.rowData.id,
            _preRow: i === 0 ? null : idsArr[i - 1]
         });
      });
      newRows.forEach(row => {
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
      });
      let rowBelow = rowsAll.find(r => r._parentRow === panelType.cellProps.rowData.id && r._preRow === null);
      if (rowBelow) {
         rowBelow._preRow = idsArr[idsArr.length - 1];
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelow);
      };
      rowsAll = [...rowsAll, ...newRows];

      const rowsOutput = _processRowsChainNoGroupFnc1([...rowsAll]);
      commandAction({
         type: 'insert-drawings-by-folder',
         data: {
            rowsAll: rowsOutput,
            rowsUpdatePreRowOrParentRow,
            idRowsNew
         }
      });
   };
   const onClickDuplicateRows = (nosOfRows) => {
      let { rowsAll, idRowsNew, rowsUpdatePreRowOrParentRow } = stateRow;
      const { headers } = stateProject.allDataOneSheet.publicSettings;

      let idsArr = genId(nosOfRows);
      idRowsNew = [...idRowsNew, ...idsArr];

      const rowAbove = panelType.cellProps.rowData;

      const rowAboveClone = { ...rowAbove };

      let arrIgnore = ['RFA Ref', 'Drg To Consultant (A)', 'Consultant Reply (T)', 'Consultant Reply (A)', 'Status', 'Rev'];
      if (projectIsAppliedRfaView) {
         Object.keys(rowAbove).forEach(key => {
            if (key.includes('submission-') || key.includes('reply-') || key === 'rfaNumber' || arrIgnore.indexOf(key) !== -1) {
               delete rowAboveClone[key];
            };
         });
      };

      const newRows = idsArr.map((id, i) => {
         return {
            ...rowAboveClone, id,
            _preRow: i === 0 ? rowAboveClone.id : idsArr[i - 1]
         };
      });

      const rowBelow = rowsAll.find(r => r._preRow === rowAboveClone.id);
      if (rowBelow) {
         rowBelow._preRow = idsArr[idsArr.length - 1];
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelow);
      };

      let cellsModifiedTempObj = {};
      newRows.forEach(row => {
         headers.forEach(hd => {
            if (row[hd.text]) {
               cellsModifiedTempObj[`${row.id}~#&&#~${hd.text}`] = row[hd.text];
            };
         });
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
      });
      overwriteCellsModified({ ...stateCell.cellsModifiedTemp, ...cellsModifiedTempObj });
      rowsAll = [...rowsAll, ...newRows];

      const rowsOutput = _processRowsChainNoGroupFnc1([...rowsAll]);
      commandAction({
         type: 'duplicate-drawings',
         data: {
            rowsAll: rowsOutput,
            rowsUpdatePreRowOrParentRow,
            idRowsNew
         }
      });
   };
   const deleteDrawing = () => {
      let { rowsAll, idRowsNew, rowsUpdatePreRowOrParentRow, rowsDeleted } = stateRow;
      const { cellsModifiedTemp } = stateCell;
      const rowId = panelType.cellProps.rowData.id;

      let rowBelow = rowsAll.find(r => r._preRow === rowId);
      if (rowBelow) {
         rowBelow._preRow = panelType.cellProps.rowData._preRow;
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, rowBelow);
      };

      if (rowId in rowsUpdatePreRowOrParentRow) delete rowsUpdatePreRowOrParentRow[rowId];
      rowsAll = rowsAll.filter(r => r.id !== rowId);

      if (idRowsNew.indexOf(rowId) === -1) {
         rowsDeleted = [...rowsDeleted, panelType.cellProps.rowData];
      } else {
         idRowsNew.splice(idRowsNew.indexOf(rowId), 1);
      };

      Object.keys(cellsModifiedTemp).forEach(key => {
         const { rowId: rowIdExtract } = extractCellInfo(key);
         if (rowIdExtract === rowId) {  // deleted cells modified temporary...
            delete cellsModifiedTemp[key];
         };
      });
      overwriteCellsModified({ ...cellsModifiedTemp });

      const rowsOutput = _processRowsChainNoGroupFnc1([...rowsAll]);

      commandAction({
         type: 'delete-drawing',
         data: {
            rowsAll: rowsOutput,
            rowsUpdatePreRowOrParentRow,
            rowsDeleted,
            idRowsNew,
         }
      });
   };


   // const applyFolderOrganize = (drawingTypeTreeNew, mergeList, nodeIsolated) => {
   const applyFolderOrganize = (drawingTypeTreeNew, mergeList) => {

      let {
         rowsAll, rowsDeleted, idRowsNew, rowsUpdatePreRowOrParentRow,
         drawingsTypeDeleted, drawingsTypeNewIds, drawingTypeTree, viewTemplateNodeId
      } = stateRow;

      const { cellsModifiedTemp } = stateCell;

      mergeList.forEach(parentNodeId => {
         const node = drawingTypeTree.find(x => x.id === parentNodeId);
         const treeBranchToMerge = getTreeFlattenOfNodeInArray(drawingTypeTree, node);

         const treeBranchChildren = treeBranchToMerge.filter(x => x.id !== parentNodeId);
         let arr = [];
         treeBranchChildren.forEach(node => {
            const rowsChildren = rowsAll.filter(r => r._parentRow === node.id);
            arr = [...arr, ...rowsChildren];
         });
         arr.forEach((row, i) => {
            row._parentRow = parentNodeId;
            row._preRow = i === 0 ? null : arr[i - 1].id;
            updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
         });
      });


      let drawingTypeTreeUpdate = flattenAllTreeChildNode1(drawingTypeTreeNew);
      drawingTypeTreeUpdate.forEach(item => {
         delete item.children;
      });


      drawingTypeTree.forEach(tr => {
         if (!drawingTypeTreeUpdate.find(x => x.id === tr.id)) {
            if (drawingsTypeNewIds.indexOf(tr.id) !== -1) {
               drawingsTypeNewIds = drawingsTypeNewIds.filter(id => id !== tr.id);
            } else {
               drawingsTypeDeleted = [...drawingsTypeDeleted, tr];
            };
         };
      });
      drawingTypeTreeUpdate.forEach(tr => {
         if (!drawingTypeTree.find(x => x.id === tr.id)) {
            drawingsTypeNewIds.push(tr.id);
         };
      });


      let allDrawingsParentId = [...new Set(rowsAll.map(x => x._parentRow))];
      allDrawingsParentId.forEach(drawingParentId => {
         const rowsChildren = rowsAll.filter(row => row._parentRow === drawingParentId);

         if (!drawingTypeTreeUpdate.find(x => x.id === drawingParentId) && !mergeList.find(item => item.id === drawingParentId)) {
            rowsChildren.forEach(rrr => {
               if (idRowsNew.indexOf(rrr.id) === -1) {
                  rowsDeleted = [...rowsDeleted, rrr];
               } else {
                  idRowsNew.splice(idRowsNew.indexOf(rrr.id), 1);
               };

               Object.keys(cellsModifiedTemp).forEach(key => {
                  const { rowId: rowIdExtract } = extractCellInfo(key);
                  if (rowIdExtract === rrr.id) {  // deleted cells modified temporary...
                     delete cellsModifiedTemp[key];
                  };
               });
               if (rrr.id in rowsUpdatePreRowOrParentRow) delete rowsUpdatePreRowOrParentRow[rrr.id];
            });
            rowsAll = rowsAll.filter(r => r._parentRow !== drawingParentId);
         } else if (!drawingTypeTreeUpdate.find(x => x.id === drawingParentId) && mergeList.find(item => item.id === drawingParentId)) {


         } else {
            if (drawingTypeTreeUpdate.find(x => x.parentId === drawingParentId)) {
               // some folders are added below drawing parent => add new type to contain
               const nodeParentPrevious = drawingTypeTreeUpdate.find(x => x.id === drawingParentId);
               const allTreeLevel = [...new Set(drawingTypeTreeUpdate.map(x => x.treeLevel))];

               let arrayNodes = [nodeParentPrevious];

               for (let i = nodeParentPrevious.treeLevel; i <= allTreeLevel.length; i++) {
                  let arrFilter = drawingTypeTreeUpdate.filter(x => x.treeLevel === allTreeLevel[i] && arrayNodes.find(dt => dt.id === x.parentId));
                  arrayNodes = [...arrayNodes, ...arrFilter];
               };
               const lowestLevel = Math.max(...arrayNodes.map(x => x.treeLevel));
               const dwgTypeParentToShiftDrawings = arrayNodes.find(x => x.treeLevel === lowestLevel);

               rowsChildren.forEach(r => {
                  r._parentRow = dwgTypeParentToShiftDrawings.id;
                  updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, r);
               });
            };
         };
      });

      overwriteCellsModified({ ...cellsModifiedTemp });

      const rowsOutput = getOutputRowsAllSorted(drawingTypeTreeUpdate, rowsAll);

      // let templateObj = {}; // TEMP_HIDE_TEMPLATE
      // if (viewTemplateNodeId !== nodeIsolated) {
      //    templateObj.viewTemplateNodeId = nodeIsolated;
      // };

      commandAction({
         type: 'drawing-folder-organization',
         data: {
            rowsAll: rowsOutput,
            rowsDeleted,
            drawingTypeTree: drawingTypeTreeUpdate,
            rowsUpdatePreRowOrParentRow,
            drawingsTypeDeleted,
            drawingsTypeNewIds,
            idRowsNew,
            // ...templateObj
         }
      });
   };



   const applyFolderUploadOrganize = async (folderTreeNew, folderDeletedIds) => {

      setLoading(true);

      const { projectId, token, authoritiesType, email, pageUploadType } = stateProject.allDataOneSheet;

      try {
         getSheetRows({ loading: true });

         const resPublicSettingsDB = await apiFetchPublicSettingThisProject({ token, projectId });
         const projectSetting = resPublicSettingsDB.data[0];
         let folderTreeFormUpload = projectSetting.folderTreeFormUpload || {};

         let folderTreeCurrentInDB = folderTreeFormUpload[pageUploadType] || [];

         let folderTreeUpdate = flattenAllTreeChildNode1(folderTreeNew);
         folderTreeUpdate.forEach(item => {
            delete item.children;
         });


         const newNodesAddedByOther = folderTreeCurrentInDB.filter(nd => {
            return !folderTreeUpdate.find(x => x.id === nd.id) && !folderDeletedIds.find(id => id === nd.id);
         });

         folderTreeFormUpload[pageUploadType] = [
            ...folderTreeUpdate,
            ...newNodesAddedByOther
         ];

         await apiUpdateSettingPublic({ token, projectId, email, publicSettings: { folderTreeFormUpload } });

         message.success('Save Project Discipline Successfully', 2);

         let [resSettings, res] = await Promise.all([
            apiFetchPublicSettingThisProject({ token, projectId }),
            apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
         ]);
         commandAction({
            type: 'reload-data-view-upload-files',
            data: {
               rowsAllUploadFiles: res.data,
               publicSettings: resSettings.data
            }
         });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };


   const applyDateAutomation = (rowsToAutomation, dataUpdate) => {

      let { rowsAll } = stateRow;
      const rowsUpdate = rowsAll.filter(x => rowsToAutomation.find(r => r.id === x.id));

      rowsUpdate.forEach(r => {
         Object.keys(dataUpdate).forEach(key => {
            const cellTempId = `${r.id}~#&&#~${key}`;
            getCellModifiedTemp({ [cellTempId]: dataUpdate[key] });
            r[key] = dataUpdate[key];
         });
      });

      commandAction({
         type: 'drawing-data-automation',
         data: { rowsAll }
      });
   };
   const createNewDrawingRevision = () => {

      const { roleTradeCompany: { company } } = stateProject.allDataOneSheet;

      const arrHeadersGoBlank = [
         // 'Model Start (T)', 'Model Start (A)', 'Model Finish (T)', 'Model Finish (A)', =>>> Remain in all drawings version
         'Drawing Start (T)', 'Drawing Start (A)',
         'Drawing Finish (T)', 'Drawing Finish (A)', 'Drg To Consultant (T)', 'Drg To Consultant (A)', 'Consultant Reply (T)',
         'Consultant Reply (A)', 'Get Approval (T)', 'Get Approval (A)', 'Construction Issuance Date', 'Construction Start', 'Rev', 'Status',
         'RFA Ref'
      ];

      let { rowsAll } = stateRow;
      const rowId = panelType.cellProps.rowData.id;
      let row = rowsAll.find(r => r.id === rowId);
      let rowOldVersiontoSave = { ...row };

      arrHeadersGoBlank.forEach(hd => {
         const cellTempId = `${rowId}~#&&#~${hd}`;
         getCellModifiedTemp({ [cellTempId]: '' });
         row[hd] = '';
      });


      let objForRFA = {};
      if (projectIsAppliedRfaView) {
         let rowsUpdateSubmissionOrReplyForNewDrawingRev = stateRow.rowsUpdateSubmissionOrReplyForNewDrawingRev;
         Object.keys(row).forEach(key => {
            if (key.includes('reply-') || key.includes('submission-')) {
               row[key] = '';
            };
         });
         if (rowsUpdateSubmissionOrReplyForNewDrawingRev.indexOf(rowId) === -1) {
            rowsUpdateSubmissionOrReplyForNewDrawingRev = [...rowsUpdateSubmissionOrReplyForNewDrawingRev, rowId];
            objForRFA = { rowsUpdateSubmissionOrReplyForNewDrawingRev };
         };
      };


      commandAction({
         type: 'create-new-drawing-revisions',
         data: {
            rowsAll,
            rowsVersionsToSave: [...stateRow.rowsVersionsToSave || [], rowOldVersiontoSave],
            ...objForRFA
         }
      });
   };






   const saveDataToServerAndReloadData = async () => {

      const { projectId, token, email, pageUploadType } = stateProject.allDataOneSheet;

      if (pageSheetTypeName !== 'page-spreadsheet' && pageSheetTypeName !== 'page-authorities-submission') {
         onClickCancelModal();
         return;
      };

      try {
         if (pageSheetTypeName === 'page-spreadsheet') {

            await saveDataToServer(stateCell, stateRow, stateProject, commandAction, setLoading);

            let res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isIncludedRfaFetched: true }); // TEST_APPY_TAB_VIEW

            commandAction({ type: 'reload-data-from-server', data: res.data });

         } else if (pageSheetTypeName === 'page-authorities-submission') {

            setLoading(true);
            const { cellsModifiedTemp } = stateCell;

            const rowsOutput = Object.keys(cellsModifiedTemp).map(cellData => {
               const { rowId, headerName } = extractCellInfo(cellData);
               return {
                  '_id': rowId,
                  data: {
                     [headerName]: cellsModifiedTemp[cellData]
                  }
               }
            });

            await apiCreateOrUpdateRowsUploadFile({ token, projectId, rows: rowsOutput });

            message.success('Save Data Successfully', 2);

            let [resSettings, res] = await Promise.all([
               apiFetchPublicSettingThisProject({ token, projectId }),
               apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
            ]);

            commandAction({
               type: 'reload-data-view-upload-files',
               data: {
                  rowsAllUploadFiles: res.data,
                  publicSettings: resSettings.data
               }
            });

         };

      } catch (err) {
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };


   const onClickApplyAddNewRFA = async (dataRfaForm) => {

      setLoading(true); // To Reload the page...
      const {
         filesPDF, filesDWFX, type, dwgsToAddNewRFA, rfaToSave, rfaVersionToSave,
         recipient, emailTextTitle, emailTextAdditionalNotes, listConsultantMustReply, requestedBy, dateReplyForsubmitForm, isFormEditting,
         withNoEmailSent, consultantNameToReplyByBothSideActionUser,
         dateSendThisForm, dwgsToRemoveFromSubmittedRfa,

         generalNoteInfo,
         generalNoteReply,
         isFormEditTimeOver
      } = dataRfaForm;


      const { currentRefToAddNewOrReplyOrEdit } = stateRow;
      const { currentRefData } = currentRefToAddNewOrReplyOrEdit;
      const { email, projectId, projectName, token, publicSettings, roleTradeCompany: { company: companyUser }, isBothSideActionUser } = stateProject.allDataOneSheet;
      const { headers } = publicSettings;

      const rfaRefData = rfaVersionToSave === '0' ? rfaToSave : (rfaToSave + rfaVersionToSave);

      const company = (type === 'form-reply-RFA' && isBothSideActionUser && consultantNameToReplyByBothSideActionUser)
         ? consultantNameToReplyByBothSideActionUser
         : companyUser;

      try {

         const resDB = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW
         const { dataDmsFetched: { rows }, dataRowHistoryDmsFetched: rowsHistoryDataDB, dataRfaFetched } = resDB.data;


         const rowsRfaAllInitFromDB = [...rows.filter(x => x['rfaNumber']), ...rowsHistoryDataDB];

         let isSubmitActionAllowed = true;
         if (type === 'form-submit-RFA' || type === 'form-resubmit-RFA') {
            if (
               !isFormEditting ||
               (isFormEditting && currentRefData && rfaRefData !== getRefStringWithVersion(currentRefData, 'rfa'))
            ) {
               const allRfaRefAlreadySubmitted = [...new Set(rowsRfaAllInitFromDB.filter(x => x['RFA Ref']).map(x => x['RFA Ref']))];
               if (allRfaRefAlreadySubmitted.indexOf(rfaRefData) !== -1) {
                  isSubmitActionAllowed = false;
               };
            };

            if (!isFormEditting) {
               if (type === 'form-submit-RFA') {
                  const rowWithRfaFound = dwgsToAddNewRFA.find(row => rowsRfaAllInitFromDB.find(x => x.id === row.id));
                  if (rowWithRfaFound) {
                     isSubmitActionAllowed = false;
                  };
               } else if (type === 'form-resubmit-RFA') {
                  dwgsToAddNewRFA.forEach(r => { // already resubmitted by others.
                     const rowWithRfaFound = rowsRfaAllInitFromDB.find(row => row.id === r.id);
                     if (rowWithRfaFound) {
                        if (getInfoValueFromRefDataForm(rowWithRfaFound, 'submission', 'rfa', 'drawing')) {
                           isSubmitActionAllowed = false;
                        };
                     };
                  });
               };
            };
         } else if (type === 'form-reply-RFA') {

            dwgsToAddNewRFA.forEach(rowToReply => { // in case DC create new version and consultant reply at the same time (drawing data moved to history, not current)
               const rowFound = rowsRfaAllInitFromDB.find(r => r.id === rowToReply.id);
               if (rowFound && rowFound['RFA Ref'] !== getRefStringWithVersion(currentRefData, 'rfa')) {
                  isSubmitActionAllowed = false;
               };
            });
         };

         if (!isSubmitActionAllowed) {
            getSheetRows({ loading: false });
            message.error('Please refresh the RFA View, This RFA/drawings are already submitted by others or DC is resubmitting', 3);
            return;
         };



         let rfaInSameRefNeedToChangeDisciplineAndNumber = [];
         let drawingsInSameRefNeedToChangeDisciplineAndNumber = [];
         if (type === 'form-submit-RFA' && isFormEditting) {
            const rfaFoundSameIdInDb = dataRfaFetched.find(r => r.id === currentRefData.id) || {};
            if (rfaFoundSameIdInDb[`${refType}Ref`] !== rfaToSave) {
               rfaInSameRefNeedToChangeDisciplineAndNumber = dataRfaFetched.filter(x => {
                  return x.id !== currentRefData.id && x['rfaRef'] === currentRefData['rfaRef']
               });
               drawingsInSameRefNeedToChangeDisciplineAndNumber = rowsRfaAllInitFromDB.filter(x => {
                  return !dwgsToAddNewRFA.find(r => r.id === x.id) && x['rfaNumber'] === currentRefData['rfaRef'];
               });
            };
         };



         const typeText = type === 'form-reply-RFA' ? 'reply' : 'submission';

         const filesToUpload = [...filesPDF, ...filesDWFX];


         if (filesToUpload.length > 0) {

            let rfaUpload = type === 'form-reply-RFA' ? currentRefData['rfaRef'] : rfaToSave;
            rfaUpload = removeSecondPart(rfaUpload);

            const rfaNumberUpload = type === 'form-reply-RFA' ? currentRefData['revision'] : rfaVersionToSave;
            const typeUpload = (type.includes('submit') ? 'submit' : 'reply') + `/${convertCompanyNameFolderS3(company)}`;

            const arrayFileName = await Promise.all(filesToUpload.map(async (file, i) => {

               const key = `drawingPlatform/${projectId}/${rfaUpload}/${rfaNumberUpload}/${typeUpload}/${file.name}`;
               const getKeyRes = await apiClientGetKeyUpload({ fileKey: key });
               const signedrequesturl = getKeyRes.data.signedRequest;

               await apiClientUploadFile({ signedrequesturl, file });

               return {
                  fileName: file.name,
                  fileLink: key,
               };
            }));

            dwgsToAddNewRFA.forEach(r => {
               const filePdfFound = arrayFileName.find(fl => fl.fileName === r[`${typeText}-rfa-drawing-${company}`]);
               if (filePdfFound) {
                  r[`${typeText}-rfa-drawing-${company}`] = filePdfFound.fileLink;
               };

               if (typeText === 'submission') {
                  const fileDwfxFound = arrayFileName.find(fl => fl.fileName === r[`submission-rfa-dwfxName-${company}`]);
                  if (fileDwfxFound) {
                     r[`submission-rfa-dwfxLink-${company}`] = fileDwfxFound.fileLink;
                  };
               };
            });
         };



         let rowsToUpdate = [];
         dwgsToAddNewRFA.forEach((r, i) => {
            const saveToRowOrRowHistory = r.row ? 'history' : 'data';
            let rowOutput = {
               _id: r.id,
               [saveToRowOrRowHistory]: {}
            };
            const dataToSaveObj = {};
            if (type === 'form-submit-RFA' || type === 'form-resubmit-RFA') {

               headers.forEach(hd => {
                  if (hd.text === 'RFA Ref') {
                     dataToSaveObj[hd.key] = rfaRefData;
                  } else if (hd.text === 'Status') {
                     if (
                        r['Status'] !== 'Reject and resubmit' &&
                        r['Status'] !== 'Approved with comments, to Resubmit' &&
                        r['Status'] !== 'Approved with Comment, no submission Required' &&
                        r['Status'] !== 'Approved for Construction'
                     ) {
                        // in case add drawing to submitted RFA, some dwgs already got status must maintain 
                        dataToSaveObj[hd.key] = 'Consultant reviewing';
                     };
                  } else if (hd.text === 'Drg To Consultant (A)') {
                     if (isBothSideActionUser && withNoEmailSent) {
                        dataToSaveObj[hd.key] = (moment(dateSendThisForm).format('DD/MM/YY'));
                     } else {
                        if (isFormEditting) {
                           const date = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'date');
                           dataToSaveObj[hd.key] = moment(date).format('DD/MM/YY');
                        } else {
                           dataToSaveObj[hd.key] = moment(new Date()).format('DD/MM/YY');
                        };
                     };
                  } else if (hd.text === 'Consultant Reply (T)') {
                     dataToSaveObj[hd.key] = dateReplyForsubmitForm;
                  } else if (hd.text === 'Rev') {
                     dataToSaveObj[hd.key] = r['Rev'];
                  };
               });


               dataToSaveObj['rfaNumber'] = rfaToSave;

               if (filesPDF.length > 0) {
                  dataToSaveObj[`submission-rfa-drawing-${company}`] = getInfoValueFromRefDataForm(r, 'submission', 'rfa', 'drawing');

               };
               if (filesDWFX.length > 0) {
                  const dwfxNameText = getInfoValueFromRefDataForm(r, 'submission', 'rfa', 'dwfxName');
                  dataToSaveObj[`submission-rfa-dwfxName-${company}`] = dwfxNameText;
                  dataToSaveObj[`submission-rfa-dwfxLink-${company}`] = dwfxNameText ? getInfoValueFromRefDataForm(r, 'submission', 'rfa', 'dwfxLink') : '';
               };

            } else if (type === 'form-reply-RFA') {


               const consultantLeadName = (getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'consultantMustReply') || [])[0];
               const leadStatus = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'status', consultantLeadName);

               if (consultantLeadName === company) {
                  headers.forEach(hd => {
                     if (hd.text === 'Consultant Reply (A)') {
                        if (isBothSideActionUser && withNoEmailSent) {
                           dataToSaveObj[hd.key] = moment(dateSendThisForm).format('DD/MM/YY');
                        } else {
                           // always update latest date when edit (in case there are additional drawings from submission side)
                           dataToSaveObj[hd.key] = moment(new Date()).format('DD/MM/YY');
                        };
                     } else if (hd.text === 'Status') {
                        dataToSaveObj[hd.key] = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'status', company);
                     };
                  });
               } else if (leadStatus) { // in case a non-lead reply before edit become lead afterwards (DC reorder consultant list)
                  headers.forEach(hd => {
                     if (hd.text === 'Consultant Reply (A)') {
                        dataToSaveObj[hd.key] = moment(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'date', consultantLeadName)).format('DD/MM/YY');
                     } else if (hd.text === 'Status') {
                        dataToSaveObj[hd.key] = leadStatus;
                     };
                  });
               };

               const comment = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'comment', company);
               if (comment) {
                  dataToSaveObj[`reply-rfa-comment-${company}`] = comment;
               };

               if (filesPDF.length > 0) {
                  dataToSaveObj[`reply-rfa-drawing-${company}`] = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'drawing', company);
                  // dataToSaveObj[`reply-rfa-drawingFileType-${company}`] = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'drawingFileType', company);
               };
               dataToSaveObj[`reply-rfa-status-${company}`] = getInfoValueFromRefDataForm(r, 'reply', 'rfa', 'status', company);

               if (isBothSideActionUser && withNoEmailSent) {
                  dataToSaveObj[`reply-rfa-date-${company}`] = dateSendThisForm;
               } else {
                  dataToSaveObj[`reply-rfa-date-${company}`] = new Date();
               };
            };
            rowOutput[saveToRowOrRowHistory] = dataToSaveObj;
            rowsToUpdate.push(rowOutput);
         });



         if (dwgsToRemoveFromSubmittedRfa.length > 0 && (type === 'form-submit-RFA' || type === 'form-resubmit-RFA')) {
            dwgsToRemoveFromSubmittedRfa.forEach(r => {
               let rowOutput = {
                  _id: r.id,
                  data: {}
               };
               headers.forEach(hd => {
                  const hdText = hd.text;
                  if (
                     hdText === 'RFA Ref' || hdText === 'Status' || hdText === 'Drg To Consultant (A)' ||
                     hdText === 'Consultant Reply (T)' || hdText === 'Rev'
                  ) {
                     rowOutput['data'][hd.key] = '';
                  };
               });

               if (type === 'form-submit-RFA') {
                  rowOutput['data']['rfaNumber'] = '';
               };

               for (const key in r) {
                  if (key.includes('reply-rfa-') || key.includes('submission-rfa-')) {
                     rowOutput['data'][key] = '';
                  };
               };
               rowsToUpdate.push(rowOutput);
            });
         };



         let rfaRow;
         if (type === 'form-submit-RFA' || type === 'form-resubmit-RFA') {
            let dataToSaveObj = {};

            if (isBothSideActionUser && withNoEmailSent) {
               dataToSaveObj[`submission-rfa-date-${company}`] = dateSendThisForm;

               dataToSaveObj[`submission-rfa-dateSendNoEmail-${company}`] = isFormEditting
                  ? getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'dateSendNoEmail')
                  : new Date();
            } else {
               dataToSaveObj[`submission-rfa-date-${company}`] = isFormEditting
                  ? getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'date')
                  : new Date();
            };

            if (generalNoteInfo) {
               dataToSaveObj[`submission-rfa-generalNoteInfo-${company}`] = generalNoteInfo;
            };

            rfaRow = {
               _id: isFormEditting ? currentRefData.id : mongoObjectId(),
               data: {
                  [`submission-rfa-emailCc-${company}`]: recipient.cc,
                  [`submission-rfa-emailTo-${company}`]: recipient.to,
                  [`submission-rfa-emailTitle-${company}`]: emailTextTitle,
                  [`submission-rfa-emailAdditionalNotes-${company}`]: emailTextAdditionalNotes,
                  [`submission-rfa-consultantMustReply-${company}`]: listConsultantMustReply,
                  [`submission-rfa-requestedBy-${company}`]: requestedBy,
                  [`submission-rfa-user-${company}`]: email,
                  [`submission-rfa-due-${company}`]: moment(dateReplyForsubmitForm, 'DD/MM/YY'),
                  ...dataToSaveObj
               },
               rfaRef: rfaToSave,
               revision: rfaVersionToSave
            };
         } else if (type === 'form-reply-RFA') {
            let dataToSaveObj = {};
            if (isBothSideActionUser && withNoEmailSent) {
               dataToSaveObj[`reply-rfa-date-${company}`] = dateSendThisForm;

               dataToSaveObj[`reply-rfa-dateSendNoEmail-${company}`] = isFormEditting
                  ? getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'dateSendNoEmail', company)
                  : new Date();
            } else {
               dataToSaveObj[`reply-rfa-date-${company}`] = new Date();
            };

            if (generalNoteReply) {
               dataToSaveObj[`reply-rfa-generalNoteReply-${company}`] = generalNoteReply;
            };

            rfaRow = {
               _id: currentRefData.id,
               data: {
                  [`reply-rfa-emailCc-${company}`]: recipient.cc,
                  [`reply-rfa-emailTo-${company}`]: recipient.to,
                  [`reply-rfa-emailTitle-${company}`]: emailTextTitle,
                  [`reply-rfa-emailAdditionalNotes-${company}`]: emailTextAdditionalNotes,
                  // [`reply-rfa-user-${company}`]: email,
                  ...dataToSaveObj
               },
            };

            if (!isFormEditting) { // in case DC edit form reply which initially created by consultant (fix issue DC Review Status Settings wrong status)
               rfaRow['data'][`reply-rfa-user-${company}`] = email;
            };
         };




         let rfaSameRefNeedToUpdateDis = [];
         if (rfaInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            rfaSameRefNeedToUpdateDis = rfaInSameRefNeedToChangeDisciplineAndNumber.map(x => ({
               _id: x.id,
               'rfaRef': rfaToSave
            }));
         };
         let drawingsSameRefNeedToUpdateDis = [];
         if (drawingsInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            const rfaRefKey = (headers.find(hd => hd.text === 'RFA Ref') || {})['key'];
            drawingsSameRefNeedToUpdateDis = drawingsInSameRefNeedToChangeDisciplineAndNumber.map(x => {

               const dataType = x.row ? 'history' : 'data';
               const previousRfaRef = x['RFA Ref'];
               const lastDigit = previousRfaRef[previousRfaRef.length - 1];
               const newRfaRef = checkIfStringContainNumberOnly(lastDigit) ? rfaToSave : (rfaToSave + lastDigit);

               let objForRowAlreadySubmitted = {};
               if (x['RFA Ref']) {
                  objForRowAlreadySubmitted = {
                     [rfaRefKey]: newRfaRef
                  };
               };

               return {
                  _id: x.id,
                  [dataType]: {
                     'rfaNumber': rfaToSave,
                     ...objForRowAlreadySubmitted
                  }
               };
            });
         };



         const rowsToUpdateFinalRow = [
            ...rowsToUpdate.filter(r => r['data']),
            ...drawingsSameRefNeedToUpdateDis.filter(x => x['data'])
         ];
         const rowsToUpdateFinalRowHistory = [
            ...rowsToUpdate.filter(r => r['history']),
            ...drawingsSameRefNeedToUpdateDis.filter(x => x['history'])
         ];


         if (rowsToUpdateFinalRow.length > 0) {
            // let cellHistoriesToSave = [];
            // rowsToUpdateFinalRow.forEach(row => {
            //    if (row.data[`reply-rfa-status-${company}`]) {
            //       cellHistoriesToSave.push({
            //          rowId: row._id, headerKey: company,
            //          history: {
            //             text: company + '__' +
            //                row.data[`reply-rfa-status-${company}`],
            //             email, createdAt: new Date()
            //          }
            //       });
            //    };
            //    headers.forEach(hd => {
            //       if (row.data[hd.key]) {
            //          cellHistoriesToSave.push({
            //             rowId: row._id, headerKey: hd.key,
            //             history: { email, text: row.data[hd.key], createdAt: new Date() }
            //          });
            //       };
            //    });
            // });
            // await apiUpdateOrCreateCellHistory({ token, projectId, cellsHistory: cellHistoriesToSave });
            await apiUpdateOrCreateRows({ token, projectId, rows: rowsToUpdateFinalRow });
         };
         if (rowsToUpdateFinalRowHistory.length > 0) {
            await apiUpdateRowsHistory({ token, projectId, rowsHistory: rowsToUpdateFinalRowHistory });
         };

         if (rfaRow) {
            await apiUpdateOrCreateRowsMultiForm('rfa', { token, projectId, rows: [rfaRow, ...rfaSameRefNeedToUpdateDis] });
         };



         if (!withNoEmailSent) {
            if (!isFormEditting || (isFormEditting && filesPDF.length > 0 && isFormEditTimeOver)) {

               // add more data to pass to email
               let rfaRowData = rfaRow['data'];


               // FIX BUG new REV (with no generalNoteInfo) automatically duplicates generalNoteInfo of previous Submission)
               if (type === 'form-submit-RFA' || type === 'form-resubmit-RFA') {
                  rfaRowData = { ...rfaRowData, [`submission-rfa-generalNoteInfo-${company}`]: rfaRowData[`submission-rfa-generalNoteInfo-${company}`] };
               } else if (type === 'form-reply-RFA') {
                  rfaRowData = { ...rfaRowData, [`reply-rfa-generalNoteReply-${company}`]: rfaRowData[`reply-rfa-generalNoteReply-${company}`] };
               };
               

               let objData = {};
               for (const key in currentRefData) {
                  if (key.includes('submission-rfa-') || key.includes('reply-rfa-')) {
                     objData[key] = currentRefData[key];
                  };
               };
               rfaRow['data'] = { ...objData, ...rfaRowData };

               const rowsToSendEmail = rowsToUpdate.filter(r => !dwgsToRemoveFromSubmittedRfa.find(x => x.id === r._id));
               const data = {
                  projectId, company, projectName,
                  formSubmitType: 'rfa',
                  type: type === 'form-reply-RFA' ? 'reply' : 'submit',
                  rowIds: rowsToSendEmail.map(row => row._id),
                  emailSender: email,
                  isFormEditting,
                  infoRfaForm: rfaRow,
                  currentRefData // NEXT TIME GET ID FROM THIS ONE TO GET LATEST DATA
               };

               // const emailText = await apiSendEmailDevTest({ token, data });
               // console.log('emailText RFA first submission', emailText.data, emailText.data.emailContent);

               await apiSendEmail({
                  token, data,
                  momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
                  isInstant: isFormEditting,
               });
            };
         };


         message.success('Submitted Successfully', 2);


         // UPDATE UI
         let { dataDmsFetched, dataRowHistoryDmsFetched: rowsHistoryDB } = resDB.data;
         let { rows: rowsDB } = dataDmsFetched;
         let rfaDataDB = dataRfaFetched;


         let idsRowFirstTimeSubmitRfa = [];
         let rowsCurrentToUpdateTempState = [];
         [...rowsToUpdateFinalRow, ...rowsToUpdateFinalRowHistory].forEach(row => {
            const dataToUpdate = row['history'] || row['data'] || {};
            let rowFound = row['history'] ? rowsHistoryDB.find(r => r['id'] === row['_id'])
               : row['data'] ? rowsDB.find(r => r['id'] === row['_id'])
                  : null;

            if (rowFound) {
               for (const key in dataToUpdate) {
                  const headerFound = headers.find(hd => hd['key'] === key);
                  if (headerFound) {
                     rowFound[headerFound['text']] = dataToUpdate[key];
                  } else {
                     rowFound[key] = dataToUpdate[key];
                  };
               };
               if (!rowFound['row']) { // row which update submitted RFA
                  rowsCurrentToUpdateTempState.push(rowFound);
               };
            } else if (!rowFound && row['data']) { // row first time submit RFA => can not find in rowsDB
               idsRowFirstTimeSubmitRfa.push(row['_id']);
            };
         });



         if (idsRowFirstTimeSubmitRfa.length > 0) { // add rows first time to stateRow => rerender page
            const resRowsNewRfa = await apiFetchRowsById({ token, projectId, email, rowIds: idsRowFirstTimeSubmitRfa });

            if (resRowsNewRfa.data.length > 0) {
               dataDmsFetched['rows'] = [
                  ...(dataDmsFetched['rows'] || []),
                  ...(resRowsNewRfa.data)
               ];
               rowsCurrentToUpdateTempState = [...rowsCurrentToUpdateTempState, ...resRowsNewRfa.data];
            };
         };




         [rfaRow, ...rfaSameRefNeedToUpdateDis].forEach(rowRfa => {
            const rowRfaFound = rfaDataDB.find(r => rowRfa['_id'] === r['id']);
            if (rowRfaFound) {
               // MUST CHECK CONDITION =>>> IN REPLY rfaRef & revision will be always undefined
               if (rowRfa['rfaRef']) rowRfaFound['rfaRef'] = rowRfa['rfaRef'];
               if (rowRfa['revision']) rowRfaFound['revision'] = rowRfa['revision'];

               const { data: rowDataUpdate } = rowRfa;
               for (const key in rowDataUpdate) {
                  rowRfaFound[key] = rowDataUpdate[key];
               };

            } else {
               let objRfaToAdd = {
                  'id': rowRfa['_id']
               };
               if (rowRfa['rfaRef']) objRfaToAdd['rfaRef'] = rowRfa['rfaRef'];
               if (rowRfa['revision']) objRfaToAdd['revision'] = rowRfa['revision'];

               objRfaToAdd = {
                  ...objRfaToAdd,
                  ...(rowRfa['data'] || {})
               };
               rfaDataDB.push(objRfaToAdd);
            };
         });

         if (window.dms_tempDmsDataRfaSubmission) { // for REPLY SIDE => null
            const { dataDmsFetched: { rows: rowsTemp } } = window.dms_tempDmsDataRfaSubmission;
            rowsCurrentToUpdateTempState.forEach(r => {
               const rowsFound = rowsTemp.find(x => x['id'] === r['id']);
               if (rowsFound) {
                  for (const key in r) {
                     rowsFound[key] = r[key];
                  };
               };
            });
         };


         commandAction({
            type: 'reload-data-view-rfa',
            data: {
               dataDmsFetched,
               dataRowHistoryDmsFetched: rowsHistoryDB,
               dataRfaFetched: rfaDataDB
            }
         });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };





   const submissionRfaFormUpdateOrForwardEmail = async (dataInput) => {

      setLoading(true); // To Reload the page...
      const { currentRefData, panelUpdateForm, rowsInThisRfaToUpdate } = dataInput;

      try {

         const { email, projectId, token, publicSettings } = stateProject.allDataOneSheet;
         const { headers } = publicSettings;
         const { rowsRfaAllInit } = stateRow;

         if (panelUpdateForm === 'delete-form-rfa') {

            const rowsHistoryIdToDelete = [];

            const rowToUpdate = rowsInThisRfaToUpdate.filter(x => !x.row).map(r => {
               let rowOutput = {
                  _id: r.id,
                  data: {}
               };

               const allRowWithThisPreviousVersion = rowsRfaAllInit.filter(dwg => dwg['row'] === r.id);

               const allRfaPreviously = [... new Set(allRowWithThisPreviousVersion.map(x => x['RFA Ref']))].map(rfaText => {
                  if (rfaText === currentRefData['rfaRef']) {
                     return '0';
                  } else {
                     return rfaText.slice(currentRefData['rfaRef'].length, rfaText.length);
                  };
               }).sort();


               const previousVersion = allRfaPreviously[allRfaPreviously.length - 1];

               if (previousVersion) {
                  const rowWithThisPreviousVersion = rowsRfaAllInit.find(dwg => {
                     return (
                        previousVersion === '0'
                           ? dwg['RFA Ref'] === currentRefData['rfaRef']
                           : dwg['RFA Ref'] === currentRefData['rfaRef'] + previousVersion
                     ) && dwg['row'] === r.id;
                  });
                  for (const key in rowWithThisPreviousVersion) {
                     const hdFound = headers.find(hd => hd.text === key);
                     if (hdFound) {
                        rowOutput['data'][hdFound.key] = rowWithThisPreviousVersion[key];
                     } else if (key.includes('reply-rfa-') || key.includes('submission-rfa-')) {
                        rowOutput['data'][key] = rowWithThisPreviousVersion[key];
                     };
                  };
                  rowsHistoryIdToDelete.push(rowWithThisPreviousVersion.id);
               } else {
                  // version A is the first version, there is no 0 => unable to find
                  rowOutput['data']['rfaNumber'] = '';
                  headers.forEach(hd => {
                     const hdText = hd.text;
                     if (
                        hdText === 'RFA Ref' || hdText === 'Status' || hdText === 'Drg To Consultant (A)' ||
                        hdText === 'Consultant Reply (T)' || hdText === 'Consultant Reply (A)' || hdText === 'Rev'
                     ) {
                        rowOutput['data'][hd.key] = '';
                     };
                  });

                  for (const key in r) {
                     if (key.includes('reply-rfa-') || key.includes('submission-rfa-')) {
                        rowOutput['data'][key] = '';
                     };
                  };
               };


               return rowOutput;
            });

            await apiDeleteMultiFormRows('rfa', { token, projectId, email, rowIdsArray: [currentRefData.id] });
            if (rowToUpdate.length > 0) {
               await apiUpdateOrCreateRows({ token, projectId, rows: rowToUpdate });
            };
            if (rowsHistoryIdToDelete.length > 0) {
               await apiDeleteRowsHistory({ token, projectId, email, rowsHistoryIdsArray: rowsHistoryIdToDelete });
            };
         };


         message.success('Deleted RFA Successfully', 2);


         const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW

         commandAction({ type: 'reload-data-view-rfa', data: res.data });


      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };

   const submissionMultiFormUpdateOrForwardEmail = async (dataInput) => {
      setLoading(true); // To Reload the page...

      const { listEmailForwardTo, listEmailForwardCc, currentRefData, panelUpdateForm, listCompanyReplyToAdd, emailGroupToAdd } = dataInput;

      try {
         const { email, projectId, projectName, companies, token, roleTradeCompany: { company } } = stateProject.allDataOneSheet;

         if (panelUpdateForm === 'forward-email') {

            if (listCompanyReplyToAdd.length > 0) {
               let listConsultantMustReply = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'consultantMustReply');
               let emailToList = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'emailTo');

               listConsultantMustReply = [...new Set([...listConsultantMustReply, ...listCompanyReplyToAdd])];
               emailToList = [...new Set([...emailToList, ...emailGroupToAdd])];

               const rowOutput = {
                  _id: currentRefData.id,
                  data: {
                     [`submission-${refType}-consultantMustReply-${company}`]: listConsultantMustReply,
                     [`submission-${refType}-emailTo-${company}`]: emailToList
                  }
               };
               await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput] });
            };

            const data = {
               projectId, company, projectName,
               formSubmitType: refType,
               type: 'forward-email-to-subcon',
               rowIds: [currentRefData.id],
               emailSender: email,
               isFormEditting: false,
               listEmailForward: {
                  to: listEmailForwardTo,
                  cc: listEmailForwardCc
               }
            };

            // const emailText = await apiSendEmailDevTest({ token, data });
            // console.log('EMAIL FORWARD.....', emailText.data.emailContent);

            await apiSendEmail({
               token, data,
               momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
               isInstant: true
            });

         } else if (panelUpdateForm === 'delete-form') {

            if (refType === 'dt') {

               const listDrawingIssueForConstruction = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'listDrawingIssueForConstruction');
               const linkedFormTypeNamePrevious = getInfoValueFromRefDataForm(currentRefData, 'submission', refType, 'linkedFormTypeName');

               let dwgsCurrentInDmsToUpdateRollback = [];
               let dwgsHistoryInDmsToUpdateRollback = [];
               let dwgsMultiFormToUpdateRollback = [];

               if (listDrawingIssueForConstruction.length > 0) {
                  const dataObjToUpdateRollback = {
                     [`submission-${refType}-issueForConstruction-${company}`]: '',
                     [`submission-${refType}-drawingLinkIssueForConstruction-${company}`]: [],
                     [`submission-${refType}-ifcVersion-${company}`]: '',
                     [`submission-${refType}-date-${company}`]: '',
                  };

                  if (linkedFormTypeNamePrevious === 'rfa' || !linkedFormTypeNamePrevious) {
                     dwgsCurrentInDmsToUpdateRollback = listDrawingIssueForConstruction.map(id => ({ '_id': id, data: dataObjToUpdateRollback }));
                     dwgsHistoryInDmsToUpdateRollback = listDrawingIssueForConstruction.map(id => ({ '_id': id, history: dataObjToUpdateRollback }));
                  } else {
                     dwgsMultiFormToUpdateRollback = listDrawingIssueForConstruction.map(id => ({ '_id': id, data: dataObjToUpdateRollback }));
                  };

                  if (dwgsCurrentInDmsToUpdateRollback.length > 0) {
                     await apiUpdateOrCreateRows({ token, projectId, rows: dwgsCurrentInDmsToUpdateRollback, isOnlyUpdateRowsAlreadyExisted: true });
                  };
                  if (dwgsHistoryInDmsToUpdateRollback.length > 0) {
                     await apiUpdateRowsHistory({ token, projectId, rowsHistory: dwgsHistoryInDmsToUpdateRollback });
                  };
                  if (dwgsMultiFormToUpdateRollback.length > 0) {
                     await apiUpdateOrCreateRowsMultiForm(linkedFormTypeNamePrevious, { token, projectId, rows: dwgsMultiFormToUpdateRollback });
                  };
               };
            };
            let rowIdsArray = [currentRefData.id];
            if(refType === 'eri' || refType === 'lr' || refType === 'sm')
            {
               await apiDeleteMultiFormRows('pm', { token, projectId, email, rowIdsArray });
            }else
            {
               await apiDeleteMultiFormRows(refType, { token, projectId, email, rowIdsArray });
            }

         };

         message.success(
            panelUpdateForm === 'forward-email' ? 'Forward Email Successfully'
               : panelUpdateForm === 'delete-form' ? 'Delete Form Successfully'
                  : '', 2);

         const res = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email }) // TEST_APPY_TAB_VIEW

         commandAction({ type: 'reload-data-view-multi-form', data: res.data });


      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };


   const onClickApplySendFormToSignature = async (typeButton, dataForm) => {

      setLoading(true); // To Reload the page...
      const {
         filesPdfDrawing, formReplyUpload, type, dwgsImportFromRFA, refToSave, refToSaveVersion,
         recipient, emailTextTitle, description, listConsultantMustReply, requestedBy, recipientName, signaturedBy, dateReplyForSubmitForm, isFormEditting,
         withNoEmailSent, consultantNameToReplyByBothSideActionUser,
         dateSendThisForm,
         dateConversation, timeConversation,
         conversationAmong,
         isCostImplication, isTimeExtension,
         consultantReplyStatus,


         contractSpecification,
         proposedSpecification,
         submissionType,
         herewithForDt,
         transmittedForDt,
         contractDrawingNo,

         generalNoteInfo,
         generalNoteReply,


         // FOR DT
         dataDmsDrawingLink,
         arrayIdsDmsDrawingLinkPrevious,
         linkedFormTypeName,
         linkedPreviousFormTypeNameForEditCase,
         listTagForDtForm,

         // QR/QRM
         formsToLinkQrOrQrm

      } = dataForm;


      let listIdDrawingDmsToLink = [];
      let listDrawingsToReferCheckLater = [];
      let listIdDrawingsRollbackNoLongerIssue = [];

      if (pageSheetTypeName === 'page-dt') {

         listDrawingsToReferCheckLater = [
            ...dwgsImportFromRFA,
            ...(Object.values(dataDmsDrawingLink).filter(x => x)) // remove UNDEFINED
         ];

         listIdDrawingDmsToLink = dwgsImportFromRFA.map(row => row['id']).filter(x => x);

         listIdDrawingDmsToLink = [
            ...listIdDrawingDmsToLink,
            ...listDrawingsToReferCheckLater.map(row => row['id']) // remove UNDEFINED
         ];

         listIdDrawingDmsToLink = [...new Set(listIdDrawingDmsToLink)];

         if (arrayIdsDmsDrawingLinkPrevious.length > 0 && !(dwgsImportFromRFA.length === 0 && filesPdfDrawing.length === 0)) { // in case select new dwgs to link and remove all after that
            listIdDrawingsRollbackNoLongerIssue = arrayIdsDmsDrawingLinkPrevious.filter(id => listIdDrawingDmsToLink.indexOf(id) === -1);
         };
      };


      const { currentRefToAddNewOrReplyOrEdit: { currentRefData } } = stateRow;

      const { email, projectId, projectName, companies, token, roleTradeCompany: { company: companyUser } } = stateProject.allDataOneSheet;

      const refType = getKeyTextForSheet(pageSheetTypeName);
      const refKey = refType + 'Ref';
      const refNumberTextInfo = refToSaveVersion === '0' ? refToSave : refToSave + refToSaveVersion;


      let company = (type === 'form-reply-multi-type' && isBothSideActionUser && consultantNameToReplyByBothSideActionUser)
         ? consultantNameToReplyByBothSideActionUser
         : companyUser;

      if (pageSheetTypeName === 'page-rfc' && (type === 'form-submit-multi-type' || type === 'form-resubmit-multi-type')) {
         const parts = refNumberTextInfo.split('/');
         const companyShortName = parts[3];
         const subconFound = companies.find(x => x['shortName'] === companyShortName);
         if (subconFound) {
            company = subconFound['company'];
         } else {
            message.error('Please go to admin page to add short name info!');
            return;
         };
      };


      let formReplyFileName;
      if (formReplyUpload[0]) {
         formReplyFileName = formReplyUpload[0].name;
      };


      try {

         // CHECK SERVER MAKE SURE NO DUPLICATE

         const resDB = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });

         const { dataMultiFormFetched: rowsFormAllInit } = resDB.data;

         let isSubmitActionAllowed = true;
         const rowFoundToCheck = rowsFormAllInit.find(r => getRefStringWithVersion(r, refType) === refNumberTextInfo);

         if (type === 'form-reply-multi-type') {
            if (!isFormEditting && rowFoundToCheck && getInfoValueFromRefDataForm(rowFoundToCheck, 'reply', refType, 'status', company)) {
               isSubmitActionAllowed = false;
            };
         } else {
            if (!isFormEditting && rowFoundToCheck) {
               isSubmitActionAllowed = false;
            };
         };
         if (!isSubmitActionAllowed) {
            getSheetRows({ loading: false });
            message.error(`This ${refType.toUpperCase()} are already submitted / replied by others, please reload the ${refType.toUpperCase()} View`);
            return;
         };


         let rowsInSameRefNeedToChangeDisciplineAndNumber = [];
         if (type === 'form-submit-multi-type' && isFormEditting) {
            const rowFoundSameIdInDb = rowsFormAllInit.find(r => r.id === currentRefData.id) || {};
            if (rowFoundSameIdInDb[`${refType}Ref`] !== refToSave) {
               rowsInSameRefNeedToChangeDisciplineAndNumber = rowsFormAllInit.filter(row => {
                  return row.id !== currentRefData.id && row[`${refType}Ref`] === currentRefData[`${refType}Ref`];
               });
            };
         };


         let linkFormPdfNoSignature, filePdfBlobOutput, pdfFilesToUpload;

         if (type === 'form-submit-multi-type' || type === 'form-resubmit-multi-type') {

            const outputPdf = (
               <ExportPdf
                  pdfContent={{
                     refNumberText: refNumberTextInfo,
                     isCostImplication, isTimeExtension, projectName, listConsultantMustReply,
                     listRecipientTo: recipient.to, listRecipientCc: recipient.cc,
                     requestedBy, signaturedBy, recipientName, conversationAmong, emailTextTitle,
                     dateConversation, timeConversation, description, dateReplyForSubmitForm,
                     filesPdfDrawing: Object.values(filesPdfDrawing), companies, contractDrawingNo,
                     dwgsImportFromRFA: dwgsImportFromRFA.map(x => ({ ...x })),
                     contractSpecification, proposedSpecification, submissionType, herewithForDt, transmittedForDt, pageSheetTypeName,
                     isNoSignatureRequired: typeButton === 'submit-directly-to-consultants'
                  }}
               />
            );

            filePdfBlobOutput = await pdf(outputPdf).toBlob();
            if (filePdfBlobOutput) {

               let pathData = `${refToSave}/${refToSaveVersion}/submit/${convertCompanyNameFolderS3(company)}/formNoSignature`;
               pathData = removeSecondPart(pathData);

               let fileName = `${refToSave}/${refToSaveVersion}_FormCoverToSign.pdf`.split('/').join('_');


               linkFormPdfNoSignature = `drawingPlatform/${projectId}/${pathData}/${fileName}`;
               const getKeyRes = await apiClientGetKeyUpload({ fileKey: linkFormPdfNoSignature });
               const signedrequesturl = getKeyRes.data.signedRequest;
               await apiClientUploadFile({ signedrequesturl, file: { type: 'application/pdf', originFileObj: filePdfBlobOutput } });
            };

            pdfFilesToUpload = filesPdfDrawing;
         } else if (type === 'form-reply-multi-type') {
            pdfFilesToUpload = (refType !== 'qr' && refType !== 'qrm' && refType !== 'rfc') // these form requires no reply form
               ? (formReplyUpload[0] ? [formReplyUpload[0], ...filesPdfDrawing] : [])
               : filesPdfDrawing
         };


         let arrayFilesPdfUploadLink = [];


         if (pdfFilesToUpload.length > 0) {

            const typeFolder = type.includes('form-reply-multi-') ? 'reply' : 'submit';

            let path = type.includes('form-reply-multi-')
               ? `${currentRefData[`${refType}Ref`]}/${currentRefData['revision']}/${typeFolder}/${convertCompanyNameFolderS3(company)}`
               : `${refToSave}/${refToSaveVersion}/${typeFolder}/${convertCompanyNameFolderS3(company)}/attachmentSubmit`;

            path = removeSecondPart(path);



            arrayFilesPdfUploadLink = await Promise.all(pdfFilesToUpload.map(async (file, i) => {
               const key = `drawingPlatform/${projectId}/${path}/${file.name}`;
               const getKeyRes = await apiClientGetKeyUpload({ fileKey: key });
               const signedrequesturl = getKeyRes.data.signedRequest;
               await apiClientUploadFile({ signedrequesturl, file });
               return key;
            }));
         };

         let linkRfaDrawingsUploaded = [];
         let linkRfamAttached = [];
         let linkRfiAttached = [];
         let linkCviAttached = [];
         if (dwgsImportFromRFA.length > 0) {

            dwgsImportFromRFA
               .filter(row => row['rfaNumber'])
               .forEach(row => {
                  linkRfaDrawingsUploaded = [
                     ...linkRfaDrawingsUploaded,
                     ...getDrawingLinksFromCmpPicked(row, 'rfa')
                  ];
               });


            ['rfam', 'rfi', 'cvi'].forEach(refType => {
               dwgsImportFromRFA
                  .filter(row => row[`${refType}Ref`])
                  .forEach(row => {
                     const consultantsSelected = row['consultantsSelected'] || [];
                     consultantsSelected.forEach(cmp => {
                        if (cmp === 'Submission') {
                           const formSubmitted = getInfoValueFromRefDataForm(row, 'submission', refType, 'linkSignedOffFormSubmit');
                           if (formSubmitted) {
                              if (refType === 'rfam') {
                                 linkRfamAttached.push(formSubmitted);
                              } else if (refType === 'rfi') {
                                 linkRfiAttached.push(formSubmitted);
                              } else if (refType === 'cvi') {
                                 linkCviAttached.push(formSubmitted);
                              };
                           };
                        } else {
                           const formReplied = getInfoValueFromRefDataForm(row, 'reply', refType, 'linkFormReply', cmp);
                           const docAttached = getInfoValueFromRefDataForm(row, 'reply', refType, 'linkDocumentsReply', cmp) || [];
                           if (formReplied) {
                              if (refType === 'rfam') {
                                 linkRfamAttached.push(formReplied);
                              } else if (refType === 'rfi') {
                                 linkRfiAttached.push(formReplied);
                              } else if (refType === 'cvi') {
                                 linkCviAttached.push(formReplied);
                              };
                           };
                           if (docAttached.length > 0) {
                              if (formReplied) {
                                 if (refType === 'rfam') {
                                    linkRfamAttached = [...linkRfamAttached, ...docAttached];
                                 } else if (refType === 'rfi') {
                                    linkRfiAttached = [...linkRfiAttached, ...docAttached];
                                 } else if (refType === 'cvi') {
                                    linkCviAttached = [...linkCviAttached, ...docAttached];
                                 };
                              };
                           };
                        };
                     });
                  });
            });
         };

         linkRfaDrawingsUploaded = [...new Set(linkRfaDrawingsUploaded)];
         linkRfamAttached = [...new Set(linkRfamAttached)];
         linkRfiAttached = [...new Set(linkRfiAttached)];
         linkCviAttached = [...new Set(linkCviAttached)];


         let rowOutput, rowFound;
         if (isFormEditting || type === 'form-reply-multi-type') {
            rowFound = rowsFormAllInit.find(x => {
               return x[refKey] === currentRefData[refKey] &&
                  x['revision'] === currentRefData['revision'];
            });
         };

         if (type === 'form-submit-multi-type' || type === 'form-resubmit-multi-type') {

            rowOutput = {
               _id: rowFound ? rowFound.id : mongoObjectId(),
               data: {}
            };

            rowOutput[refKey] = refToSave;
            rowOutput['revision'] = refToSaveVersion;


            rowOutput.data[`submission-${refType}-emailTo-${company}`] = recipient.to;
            rowOutput.data[`submission-${refType}-emailCc-${company}`] = recipient.cc;
            rowOutput.data[`submission-${refType}-emailTitle-${company}`] = emailTextTitle;
            rowOutput.data[`submission-${refType}-description-${company}`] = description;
            // MM also needs consultantMustReply = [];
            rowOutput.data[`submission-${refType}-consultantMustReply-${company}`] = pageSheetTypeName === 'page-mm' ? [] : listConsultantMustReply;
            rowOutput.data[`submission-${refType}-user-${company}`] = email;


            if (pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') {

               if (!(isFormEditting && arrayFilesPdfUploadLink.length === 0 && linkRfaDrawingsUploaded.length === 0 &&
                  linkRfamAttached.length === 0 && linkRfiAttached.length === 0 && linkCviAttached.length === 0
               )) {

                  rowOutput.data[`submission-${refType}-linkDrawings-${company}`] = arrayFilesPdfUploadLink;
                  rowOutput.data[`submission-${refType}-linkDrawingsRfa-${company}`] = linkRfaDrawingsUploaded;
                  rowOutput.data[`submission-${refType}-linkRfamAttached-${company}`] = linkRfamAttached;
                  rowOutput.data[`submission-${refType}-linkRfiAttached-${company}`] = linkRfiAttached;
                  rowOutput.data[`submission-${refType}-linkCviAttached-${company}`] = linkCviAttached;

                  if (pageSheetTypeName === 'page-dt') {
                     rowOutput.data[`submission-${refType}-listDrawingIssueForConstruction-${company}`] = listIdDrawingDmsToLink.length > 0 ? listIdDrawingDmsToLink : [];
                     rowOutput.data[`submission-${refType}-linkedFormTypeName-${company}`] = listIdDrawingDmsToLink.length > 0 ? linkedFormTypeName : '';
                  };
               };

            } else {
               if (!(isFormEditting && arrayFilesPdfUploadLink.length === 0)) {
                  rowOutput.data[`submission-${refType}-linkDrawings-${company}`] = arrayFilesPdfUploadLink;
               };
            };



            if (linkFormPdfNoSignature) {
               rowOutput.data[`submission-${refType}-linkFormNoSignature-${company}`] = linkFormPdfNoSignature;

               if (
                  typeButton === 'submit-directly-to-consultants' || typeButton === 'submit-qr-or-qrm' || typeButton === 'submit-rfc' ||
                  pageSheetTypeName === 'page-mm' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc'
               ) {
                  rowOutput.data[`submission-${refType}-linkSignedOffFormSubmit-${company}`] = linkFormPdfNoSignature;
               };
            };


            if (pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') {
               rowOutput.data[`submission-${refType}-signaturedBy-${company}`] = signaturedBy;
               rowOutput.data[`submission-${refType}-recipientName-${company}`] = recipientName;
            };

            if (pageSheetTypeName !== 'page-mm') {
               rowOutput.data[`submission-${refType}-requestedBy-${company}`] = requestedBy;
               rowOutput.data[`submission-${refType}-due-${company}`] = dateReplyForSubmitForm;
            };

            if (pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm') {
               if (formsToLinkQrOrQrm.length > 0) {
                  rowOutput.data[`submission-${refType}-listForm-${company}`] = [...new Set(formsToLinkQrOrQrm)];
               };
            };


            if (pageSheetTypeName === 'page-mm') {
               if (dateConversation) rowOutput.data[`submission-${refType}-dateConversation-${company}`] = dateConversation;
               if (timeConversation) rowOutput.data[`submission-${refType}-timeConversation-${company}`] = timeConversation;
            };


            if (pageSheetTypeName === 'page-rfam') {
               if (contractSpecification) rowOutput.data[`submission-${refType}-contractSpecification-${company}`] = contractSpecification;
               if (proposedSpecification) rowOutput.data[`submission-${refType}-proposedSpecification-${company}`] = proposedSpecification;
               if (submissionType) rowOutput.data[`submission-${refType}-submissionType-${company}`] = submissionType;
               if (contractDrawingNo) rowOutput.data[`submission-${refType}-contractDrawingNo-${company}`] = contractDrawingNo;
            };


            if (pageSheetTypeName === 'page-cvi') {
               rowOutput.data[`submission-${refType}-isCostImplication-${company}`] = isCostImplication;
               rowOutput.data[`submission-${refType}-isTimeExtension-${company}`] = isTimeExtension;
               if (dateConversation) rowOutput.data[`submission-${refType}-dateConversation-${company}`] = dateConversation;
               if (timeConversation) rowOutput.data[`submission-${refType}-timeConversation-${company}`] = timeConversation;
               if (conversationAmong) rowOutput.data[`submission-${refType}-conversationAmong-${company}`] = conversationAmong;
            };

            if (pageSheetTypeName === 'page-dt') {
               if (herewithForDt) rowOutput.data[`submission-${refType}-herewithForDt-${company}`] = herewithForDt;
               if (transmittedForDt) rowOutput.data[`submission-${refType}-transmittedForDt-${company}`] = transmittedForDt;
               if (listTagForDtForm && listTagForDtForm.length > 0) rowOutput.data[`submission-${refType}-listTagForDtForm-${company}`] = listTagForDtForm;
            };
            if (pageSheetTypeName === 'page-rfc') {
               if (herewithForDt) rowOutput.data[`submission-${refType}-herewithForDt-${company}`] = herewithForDt;
            };




            if (isBothSideActionUser && withNoEmailSent) {
               rowOutput.data[`submission-${refType}-date-${company}`] = dateSendThisForm;
               if (!isFormEditting) {
                  rowOutput.data[`submission-${refType}-dateSendNoEmail-${company}`] = new Date();
               };
            } else {
               if (!isFormEditting) {
                  rowOutput.data[`submission-${refType}-date-${company}`] = new Date();
               };
            };


            if (generalNoteInfo) {
               rowOutput.data[`submission-${refType}-generalNoteInfo-${company}`] = generalNoteInfo;
            };

         } else if (type === 'form-reply-multi-type') {

            rowOutput = {
               _id: rowFound.id,
               data: {}
            };

            rowOutput.data[`reply-${refType}-emailTo-${company}`] = recipient.to;
            rowOutput.data[`reply-${refType}-emailCc-${company}`] = recipient.cc;
            rowOutput.data[`reply-${refType}-emailTitle-${company}`] = emailTextTitle;
            rowOutput.data[`reply-${refType}-description-${company}`] = description;

            if (!isFormEditting) { // in case DC edit form reply which initially created by consultant (fix issue DC Review Status Settings wrong status)
               rowOutput.data[`reply-${refType}-user-${company}`] = email;
            };

            if (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') {
               rowOutput.data[`reply-${refType}-status-${company}`] = consultantReplyStatus;
            } else if (pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt') {
               rowOutput.data[`reply-${refType}-status-${company}`] = 'replied';
            };

            if (isBothSideActionUser && withNoEmailSent) {
               rowOutput.data[`reply-${refType}-date-${company}`] = dateSendThisForm || new Date();
               if (!isFormEditting) {
                  rowOutput.data[`reply-${refType}-dateSendNoEmail-${company}`] = new Date();
               };
            } else {
               if (!isFormEditting) {
                  rowOutput.data[`reply-${refType}-date-${company}`] = new Date();
               };
            };



            if (!(isFormEditting && arrayFilesPdfUploadLink.length === 0)) {
               rowOutput.data[`reply-${refType}-linkFormReply-${company}`] = arrayFilesPdfUploadLink.find(link => getFileNameFromLinkResponse(link) === formReplyFileName);
               rowOutput.data[`reply-${refType}-linkDocumentsReply-${company}`] = arrayFilesPdfUploadLink.filter(link => getFileNameFromLinkResponse(link) !== formReplyFileName);
            };



            if (generalNoteReply) {
               rowOutput.data[`reply-${refType}-generalNoteReply-${company}`] = generalNoteReply;
            };
         };

         let rowsInSameRefNeedToUpdateRefText = []; // incase update discipline with row has many revisions.
         if (rowsInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            rowsInSameRefNeedToUpdateRefText = rowsInSameRefNeedToChangeDisciplineAndNumber.map(row => ({
               _id: row.id,
               data: {
                  [`${refType}Ref`]: refToSave
               }
            }));
         };


         console.log('Debug CVI...', { rowOutput, rowsInSameRefNeedToUpdateRefText });
         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput, ...rowsInSameRefNeedToUpdateRefText] });
         console.log('Debug CVI...', 'DONE SAVE ROWS');

         if (pageSheetTypeName === 'page-dt' && (listIdDrawingDmsToLink.length > 0 || listIdDrawingsRollbackNoLongerIssue.length > 0)) { // if edit DT form and remove all link, add no new link =>>> listIdDrawingDmsToLink.length === 0 && listIdDrawingsRollbackNoLongerIssue.length > 0

            let dwgsCurrentInDmsToUpdateRollback = [];
            let dwgsHistoryInDmsToUpdateRollback = [];
            let dwgsMultiFormToUpdateRollback = [];

            if (listIdDrawingsRollbackNoLongerIssue.length > 0) {
               const dataObjToUpdateRollback = {
                  [`submission-${refType}-issueForConstruction-${company}`]: '',
                  [`submission-${refType}-drawingLinkIssueForConstruction-${company}`]: [],
                  [`submission-${refType}-ifcVersion-${company}`]: '',
                  [`submission-${refType}-date-${company}`]: '',
               };
               listIdDrawingsRollbackNoLongerIssue.forEach(id => {
                  if (linkedPreviousFormTypeNameForEditCase === 'rfa' || !linkedPreviousFormTypeNameForEditCase) { // !linkedPreviousFormTypeNameForEditCase =>>> all dwg linked previously (no IFC Rev, no IFC Date)
                     // no need to distiguish current or history, later DB can not find => no update
                     dwgsHistoryInDmsToUpdateRollback.push({ '_id': id, history: dataObjToUpdateRollback });
                     dwgsCurrentInDmsToUpdateRollback.push({ '_id': id, data: dataObjToUpdateRollback });
                  } else {
                     dwgsMultiFormToUpdateRollback.push({ '_id': id, data: dataObjToUpdateRollback });
                  };
               });
            };


            let dwgsCurrentInDmsToUpdate = [];
            let dwgsHistoryInDmsToUpdate = [];
            let dwgsMultiFormToUpdate = [];

            const objDataToUpdateRowInDtFormLink = {
               refType, company, dwgsImportFromRFA, dataDmsDrawingLink, refNumberTextInfo, dateSendThisForm, arrayFilesPdfUploadLink, linkedFormTypeName
            };

            if (linkedFormTypeName === 'rfa') {

               dwgsCurrentInDmsToUpdate = listIdDrawingDmsToLink
                  .filter(id => {
                     const rowFound = listDrawingsToReferCheckLater.find(r => r['id'] === id);
                     return rowFound && !rowFound['row'];
                  })
                  .map(id => getDataToUpdateRowInDtFormLink({ id, ...objDataToUpdateRowInDtFormLink, typeRow: 'rfa-current' }));

               dwgsHistoryInDmsToUpdate = listIdDrawingDmsToLink
                  .filter(id => {
                     const rowFound = listDrawingsToReferCheckLater.find(r => r['id'] === id);
                     return rowFound && rowFound['row'];
                  })
                  .map(id => getDataToUpdateRowInDtFormLink({ id, ...objDataToUpdateRowInDtFormLink, typeRow: 'rfa-history' }));

            } else { // rfam, rfi, cvi, dt

               dwgsMultiFormToUpdate = listIdDrawingDmsToLink
                  .filter(id => listDrawingsToReferCheckLater.find(r => r['id'] === id))
                  .map(id => getDataToUpdateRowInDtFormLink({ id, ...objDataToUpdateRowInDtFormLink, typeRow: 'multi-form' }));
            };

            dwgsCurrentInDmsToUpdate = [...dwgsCurrentInDmsToUpdate, ...dwgsCurrentInDmsToUpdateRollback];
            if (dwgsCurrentInDmsToUpdate.length > 0) {
               await apiUpdateOrCreateRows({ token, projectId, rows: dwgsCurrentInDmsToUpdate, isOnlyUpdateRowsAlreadyExisted: true });
            };

            dwgsHistoryInDmsToUpdate = [...dwgsHistoryInDmsToUpdate, ...dwgsHistoryInDmsToUpdateRollback];
            if (dwgsHistoryInDmsToUpdate.length > 0) {
               await apiUpdateRowsHistory({ token, projectId, rowsHistory: dwgsHistoryInDmsToUpdate });
            };

            if (dwgsMultiFormToUpdate.length > 0) {
               await apiUpdateOrCreateRowsMultiForm(linkedFormTypeName, { token, projectId, rows: dwgsMultiFormToUpdate });
               console.log('debug CVI apiUpdateOrCreateRowsMultiForm 001');
            };

            if (dwgsMultiFormToUpdateRollback.length > 0) {
               await apiUpdateOrCreateRowsMultiForm(linkedPreviousFormTypeNameForEditCase, { token, projectId, rows: dwgsMultiFormToUpdateRollback });
               console.log('debug CVI apiUpdateOrCreateRowsMultiForm 002');
            };
         };









         const isReuploadFile = filesPdfDrawing.length > 0 || formReplyUpload.length > 0 || dwgsImportFromRFA.length > 0;

         if (typeButton === 'action-multiform-download' && (type === 'form-submit-multi-type' || type === 'form-resubmit-multi-type')) {
            const csvURL = window.URL.createObjectURL(filePdfBlobOutput);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `${refNumberTextInfo}_cover_form.pdf`);
            tempLink.click();

         } else if (typeButton !== 'action-multiform-download' && typeButton !== 'action-update-form') {
            if (!isFormEditting || (isFormEditting && isReuploadFile)) { // only trigger email first time submit or edit submit with file reuploaded
               const data = {
                  projectId, company, projectName,
                  formSubmitType: refType,
                  type: typeButton,
                  rowIds: [rowOutput._id],
                  emailSender: email,
                  isFormEditting
               };

               console.log('send-Email-Info', data);
               
               await apiSendEmail({
                  token, data,
                  momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
                  isInstant: isFormEditting
               });
               // const emailText = await apiSendEmailDevTest({ token, data });
               // console.log('emailText---FIRST TIME', emailText.data.emailContent);
            };
         };

         message.success('Submitted Successfully', 2);


         // UPDATE UI
         const { dataMultiFormFetched: rowsFormAllInitDB } = resDB.data;
         [rowOutput, ...rowsInSameRefNeedToUpdateRefText].forEach(row => {
            const rowFoundInDB = rowsFormAllInitDB.find(r => r['id'] === row['_id']);
            if (rowFoundInDB) {
               const dataUpdate = row['data'] || {};
               for (const key in dataUpdate) {
                  if (dataUpdate[key]) {
                     rowFoundInDB[key] = dataUpdate[key];
                  };
               };

               if (row['revision']) rowFoundInDB['revision'] = row['revision'];
               if (row[`${refType}Ref`]) rowFoundInDB[`${refType}Ref`] = row[`${refType}Ref`];

            } else {
               const objRowToAdd = {
                  'id': row['_id'],
                  ...(row['data'] || {})
               };
               if (row['revision']) objRowToAdd['revision'] = row['revision'];
               if (row[`${refType}Ref`]) objRowToAdd[`${refType}Ref`] = row[`${refType}Ref`];

               rowsFormAllInitDB.push(objRowToAdd);
            };
         });


         commandAction({
            type: 'reload-data-view-multi-form',
            data: {
               ...resDB.data,
               dataMultiFormFetched: rowsFormAllInitDB,
            }
         });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };

   const onClickApplySendFormToSignatureERI = async (typeButton, dataForm) => {

      setLoading(true); // To Reload the page...
      const {
         type,
         isFormEditting,
         dateSendThisForm,
         dateResponseForSubmitForm,
         dateAcknowledgeSendForSubmitForm,
         tradeForStatusPM,
         listRecipientTo,
         listRecipientCc,
         subject,
         description,
         refToSave,

      } = dataForm;

      const { currentRefToAddNewOrReplyOrEdit: { currentRefData } } = stateRow;
      const { email, projectId, projectName, companies, token, roleTradeCompany: { company: companyUser } } = stateProject.allDataOneSheet;
      const refType = 'pm';
      const refKey = 'pm' + 'Ref';
      let company =  companyUser;
      
      const refNumberTextInfo = refToSave;
      try
      {
         const resDB = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });
         const { dataMultiFormFetched: rowsFormAllInit } = resDB.data;
         let isSubmitActionAllowed = true;
         const rowFoundToCheck = rowsFormAllInit.find(r => getRefStringWithVersion(r, refType) === refNumberTextInfo);
         if(!isFormEditting && rowFoundToCheck)
         {
            isSubmitActionAllowed = false;
         }
         if (!isSubmitActionAllowed) {
            getSheetRows({ loading: false });
            message.error(`This ${refType.toUpperCase()} are already submitted / replied by others, please reload the ${refType.toUpperCase()} View`);
            return;
         };
         let rowsInSameRefNeedToChangeDisciplineAndNumber = [];
         if (type === 'form-submit-multi-type' && isFormEditting) {
            const rowFoundSameIdInDb = rowsFormAllInit.find(r => r.id === currentRefData.id) || {};
            const refSame = rowFoundSameIdInDb[`${refType}Ref`];
            if ( refSame !== refToSave) {
               rowsInSameRefNeedToChangeDisciplineAndNumber = rowsFormAllInit.filter(row => 
                row[`${refType}Ref`] === currentRefData[`${refType}Ref`]
               );
            };
         }
      
         let rowOutput, rowFound;
         if (isFormEditting) {
            rowFound = rowsFormAllInit.find(x => {
               return x[refKey] === currentRefData[refKey] &&
                  x['revision'] === currentRefData['revision'];
            });
         };
         if(type === 'form-submit-multi-type')
         {

            rowOutput = {
               _id: rowFound ? rowFound.id : mongoObjectId(),
               data: {}
            };

            rowOutput[refKey] = refToSave;
            rowOutput['revision'] = '0';
            rowOutput.data[`submission-${'pm'}-dateSendThisForm-${company}`] = dateSendThisForm;
            rowOutput.data[`submission-${'pm'}-dateResponseForSubmitForm-${company}`] = dateResponseForSubmitForm;
            rowOutput.data[`submission-${'pm'}-dateAcknowledgeSendForSubmitForm-${company}`] = dateAcknowledgeSendForSubmitForm;
            rowOutput.data[`submission-${'pm'}-tradeForStatusPM-${company}`] = tradeForStatusPM;
            rowOutput.data[`submission-${'pm'}-emailTo-${company}`] = listRecipientTo;
            rowOutput.data[`submission-${'pm'}-emailCc-${company}`] = listRecipientCc;
            rowOutput.data[`submission-${'pm'}-subject-${company}`] = subject;
            rowOutput.data[`submission-${'pm'}-description-${company}`] = description;
            rowOutput.data[`submission-${'pm'}-refToSave-${company}`] = refToSave;

         }
         let rowsInSameRefNeedToUpdateRefText = []; // incase update discipline with row has many revisions.
         if (rowsInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            rowsInSameRefNeedToUpdateRefText = rowsInSameRefNeedToChangeDisciplineAndNumber.map(row => ({
               _id: row.id,
               data: {
                  [`${refType}Ref`]: refToSave
               }
            }));
         };


         console.log('Debug CVI...', { rowOutput, rowsInSameRefNeedToUpdateRefText });
         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput, ...rowsInSameRefNeedToUpdateRefText] });
         console.log('Debug CVI...', 'DONE SAVE ROWS');

         if (!isFormEditting) { // only trigger email first time submit or edit submit with file reuploaded
            const data = {
               projectId, company, projectName,
               formSubmitType: 'eri',
               type: typeButton,
               rowIds: [rowOutput._id],
               emailSender: email,
               isFormEditting
            };
            const emailText = await apiSendEmailPM({ token, data });
            console.log('emailText', emailText);
         }


         message.success('Submitted Successfully', 2);
         const { dataMultiFormFetched: rowsFormAllInitDB } = resDB.data;
         [rowOutput, ...rowsInSameRefNeedToUpdateRefText].forEach(row => {
            const rowFoundInDB = rowsFormAllInitDB.find(r => r['id'] === row['_id']);
            if (rowFoundInDB) {
               const dataUpdate = row['data'] || {};
               for (const key in dataUpdate) {
                  if (dataUpdate[key]) {
                     rowFoundInDB[key] = dataUpdate[key];
                  };
               };

               if (row['revision']) rowFoundInDB['revision'] = row['revision'];
               if (row[`${refType}Ref`]) rowFoundInDB[`${refType}Ref`] = row[`${refType}Ref`];

            } else {
               const objRowToAdd = {
                  'id': row['_id'],
                  ...(row['data'] || {})
               };
               if (row['revision']) objRowToAdd['revision'] = row['revision'];
               let ref = row[`${refType}Ref`];
               if (row[`${refType}Ref`]) objRowToAdd[`${refType}Ref`] = ref;

               rowsFormAllInitDB.push(objRowToAdd);
            };
         });
         commandAction({
            type: 'reload-data-view-multi-form',
            data: {
               ...resDB.data,
               dataMultiFormFetched: rowsFormAllInitDB,
            }
         });
    
      } catch (err) {
      getSheetRows({ loading: false });
      commandAction({ type: 'save-data-failure' });
      console.log(err);
      };
   };
   const onClickApplySendFormToSignatureLR = async (typeButton, dataForm) => {
      setLoading(true); // To Reload the page...
      const {
         type,
         isFormEditting,
         dateSendThisForm,
         whoref,
         listRecipientTo,
         listRecipientCc,
         subject,
         remark,
         description,
         refToSave,
      } = dataForm;
      const { currentRefToAddNewOrReplyOrEdit: { currentRefData } } = stateRow;
      const { email, projectId, projectName, companies, token, roleTradeCompany: { company: companyUser } } = stateProject.allDataOneSheet;
      const refType = 'pm';
      const refKey = 'pm' + 'Ref';
      let company =  companyUser;
      const refNumberTextInfo = refToSave;
      try
      {
         const resDB = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });
         const { dataMultiFormFetched: rowsFormAllInit } = resDB.data;
         let isSubmitActionAllowed = true;
         const rowFoundToCheck = rowsFormAllInit.find(r => getRefStringWithVersion(r, refType) === refNumberTextInfo);
         if(!isFormEditting && rowFoundToCheck)
         {
            isSubmitActionAllowed = false;
         }
         if (!isSubmitActionAllowed) {
            getSheetRows({ loading: false });
            message.error(`This ${refType.toUpperCase()} are already submitted / replied by others, please reload the ${refType.toUpperCase()} View`);
            return;
         };
         let rowsInSameRefNeedToChangeDisciplineAndNumber = [];
         if (type === 'form-submit-multi-type' && isFormEditting) {
            const rowFoundSameIdInDb = rowsFormAllInit.find(r => r.id === currentRefData.id) || {};
            const refSame = rowFoundSameIdInDb[`${refType}Ref`];
            if ( refSame !== refToSave) {
               rowsInSameRefNeedToChangeDisciplineAndNumber = rowsFormAllInit.filter(row => 
                row[`${refType}Ref`] === currentRefData[`${refType}Ref`]
               );
            };
         }
      
         let rowOutput, rowFound;
         if (isFormEditting) {
            rowFound = rowsFormAllInit.find(x => {
               return x[refKey] === currentRefData[refKey] &&
                  x['revision'] === currentRefData['revision'];
            });
         };
      
         if(type === 'form-submit-multi-type')
         {
            rowOutput = {
               _id: rowFound ? rowFound.id : mongoObjectId(),
               data: {}
            };

            rowOutput[refKey] = refToSave;
            rowOutput['revision'] = '0';
            rowOutput.data[`submission-${'pm'}-dateSendThisForm-${company}`] = dateSendThisForm;
            rowOutput.data[`submission-${'pm'}-whoref-${company}`] = whoref;
            rowOutput.data[`submission-${'pm'}-emailTo-${company}`] = listRecipientTo;
            rowOutput.data[`submission-${'pm'}-emailCc-${company}`] = listRecipientCc;
            rowOutput.data[`submission-${'pm'}-subject-${company}`] = subject;
            rowOutput.data[`submission-${'pm'}-description-${company}`] = description;
            rowOutput.data[`submission-${'pm'}-remark-${company}`] = remark;
        

         }
         let rowsInSameRefNeedToUpdateRefText = []; // incase update discipline with row has many revisions.
         if (rowsInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            rowsInSameRefNeedToUpdateRefText = rowsInSameRefNeedToChangeDisciplineAndNumber.map(row => ({
               _id: row.id,
               data: {
                  [`${refType}Ref`]: refToSave
               }
            }));
         };


         console.log('Debug CVI...', { rowOutput, rowsInSameRefNeedToUpdateRefText });
         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput, ...rowsInSameRefNeedToUpdateRefText] });
         console.log('Debug CVI...', 'DONE SAVE ROWS');


         if (!isFormEditting) { // only trigger email first time submit or edit submit with file reuploaded
            const data = {
               projectId, company, projectName,
               formSubmitType: 'lr',
               type: typeButton,
               rowIds: [rowOutput._id],
               emailSender: email,
               isFormEditting
            };

            const emailText = await apiSendEmailPM({ token, data });
            console.log('emailText', emailText);
         };

         message.success('Submitted Successfully', 2);
         const { dataMultiFormFetched: rowsFormAllInitDB } = resDB.data;
         [rowOutput, ...rowsInSameRefNeedToUpdateRefText].forEach(row => {
            const rowFoundInDB = rowsFormAllInitDB.find(r => r['id'] === row['_id']);
            if (rowFoundInDB) {
               const dataUpdate = row['data'] || {};
               for (const key in dataUpdate) {
                  if (dataUpdate[key]) {
                     rowFoundInDB[key] = dataUpdate[key];
                  };
               };

               if (row['revision']) rowFoundInDB['revision'] = row['revision'];
               if (row[`${refType}Ref`]) rowFoundInDB[`${refType}Ref`] = row[`${refType}Ref`];

            } else {
               const objRowToAdd = {
                  'id': row['_id'],
                  ...(row['data'] || {})
               };
               if (row['revision']) objRowToAdd['revision'] = row['revision'];
               if (row[`${refType}Ref`]) objRowToAdd[`${refType}Ref`] = row[`${refType}Ref`];

               rowsFormAllInitDB.push(objRowToAdd);
            };
         });
         commandAction({
            type: 'reload-data-view-multi-form',
            data: {
               ...resDB.data,
               dataMultiFormFetched: rowsFormAllInitDB,
            }
         });
    
      } catch (err) {
      getSheetRows({ loading: false });
      commandAction({ type: 'save-data-failure' });
      console.log(err);
      };
   };

   const onClickApplySendFormToSignatureSM = async (typeButton, dataForm) => {

      setLoading(true); // To Reload the page...
      const {
         type,
         isFormEditting,
         dateSendThisForm,
         dateAcknowledgeSendForSubmitForm,
         tradeForStatusPM,
         trade,
         raised,
         listRecipientTo,
         listRecipientCc,
         subject,
         description,
         refToSave,

      } = dataForm;
      const { currentRefToAddNewOrReplyOrEdit: { currentRefData } } = stateRow;
      const { email, projectId, projectName, companies, token, roleTradeCompany: { company: companyUser } } = stateProject.allDataOneSheet;
      const refType = 'pm';
      const refKey = 'pm' + 'Ref';
      let company =  companyUser;
      const refNumberTextInfo = refToSave;
      try
      {
         const resDB = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });
         const { dataMultiFormFetched: rowsFormAllInit } = resDB.data;
         let isSubmitActionAllowed = true;
         const rowFoundToCheck = rowsFormAllInit.find(r => getRefStringWithVersion(r, refType) === refNumberTextInfo);
         if(!isFormEditting && rowFoundToCheck)
         {
            isSubmitActionAllowed = false;
         }
         if (!isSubmitActionAllowed) {
            getSheetRows({ loading: false });
            message.error(`This ${refType.toUpperCase()} are already submitted / replied by others, please reload the ${refType.toUpperCase()} View`);
            return;
         };
         let rowsInSameRefNeedToChangeDisciplineAndNumber = [];
         if (type === 'form-submit-multi-type' && isFormEditting) {
            const rowFoundSameIdInDb = rowsFormAllInit.find(r => r.id === currentRefData.id) || {};
            const refSame = rowFoundSameIdInDb[`${refType}Ref`];
            if ( refSame !== refToSave) {
               rowsInSameRefNeedToChangeDisciplineAndNumber = rowsFormAllInit.filter(row => 
                row[`${refType}Ref`] === currentRefData[`${refType}Ref`]
               );
            };
         }
      
         let rowOutput, rowFound;
         if (isFormEditting) {
            rowFound = rowsFormAllInit.find(x => {
               return x[refKey] === currentRefData[refKey] &&
                  x['revision'] === currentRefData['revision'];
            });
         };
      
         if(type === 'form-submit-multi-type')
         {

            rowOutput = {
               _id: rowFound ? rowFound.id : mongoObjectId(),
               data: {}
            };
            rowOutput[refKey] = refToSave;
            rowOutput['revision'] = '0';
            rowOutput.data[`submission-${'pm'}-dateSendThisForm-${company}`] = dateSendThisForm;
            rowOutput.data[`submission-${'pm'}-dateAcknowledgeSendForSubmitForm-${company}`] = dateAcknowledgeSendForSubmitForm;
            rowOutput.data[`submission-${'pm'}-tradeForStatusPM-${company}`] = tradeForStatusPM;
            rowOutput.data[`submission-${'pm'}-trade-${company}`] = trade;
            rowOutput.data[`submission-${'pm'}-raised-${company}`] = raised;
            rowOutput.data[`submission-${'pm'}-emailTo-${company}`] = listRecipientTo;
            rowOutput.data[`submission-${'pm'}-emailCc-${company}`] = listRecipientCc;
            rowOutput.data[`submission-${'pm'}-subject-${company}`] = subject;
            rowOutput.data[`submission-${'pm'}-description-${company}`] = description;
         }
         let rowsInSameRefNeedToUpdateRefText = []; // incase update discipline with row has many revisions.
         if (rowsInSameRefNeedToChangeDisciplineAndNumber.length > 0) {
            rowsInSameRefNeedToUpdateRefText = rowsInSameRefNeedToChangeDisciplineAndNumber.map(row => ({
               _id: row.id,
               data: {
                  [`${refType}Ref`]: refToSave
               }
            }));
         };


         console.log('Debug CVI...', { rowOutput, rowsInSameRefNeedToUpdateRefText });
         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput, ...rowsInSameRefNeedToUpdateRefText] });
         console.log('Debug CVI...', 'DONE SAVE ROWS');

            if (!isFormEditting) { // only trigger email first time submit or edit submit with file reuploaded
            const data = {
               projectId, company, projectName,
               formSubmitType: 'sm',
               type: typeButton,
               rowIds: [rowOutput._id],
               emailSender: email,
               isFormEditting
            };

            const emailText = await apiSendEmailPM({ token, data });
            console.log('emailText', emailText);
         };


         message.success('Submitted Successfully', 2);
         const { dataMultiFormFetched: rowsFormAllInitDB } = resDB.data;
         [rowOutput, ...rowsInSameRefNeedToUpdateRefText].forEach(row => {
            const rowFoundInDB = rowsFormAllInitDB.find(r => r['id'] === row['_id']);
            if (rowFoundInDB) {
               const dataUpdate = row['data'] || {};
               for (const key in dataUpdate) {
                  if (dataUpdate[key]) {
                     rowFoundInDB[key] = dataUpdate[key];
                  };
               };

               if (row['revision']) rowFoundInDB['revision'] = row['revision'];
               if (row[`${refType}Ref`]) rowFoundInDB[`${refType}Ref`] = row[`${refType}Ref`];

            } else {
               const objRowToAdd = {
                  'id': row['_id'],
                  ...(row['data'] || {})
               };
               if (row['revision']) objRowToAdd['revision'] = row['revision'];
               if (row[`${refType}Ref`]) objRowToAdd[`${refType}Ref`] = row[`${refType}Ref`];

               rowsFormAllInitDB.push(objRowToAdd);
            };
         });
         commandAction({
            type: 'reload-data-view-multi-form',
            data: {
               ...resDB.data,
               dataMultiFormFetched: rowsFormAllInitDB,
            }
         });
    
      } catch (err) {
      getSheetRows({ loading: false });
      commandAction({ type: 'save-data-failure' });
      console.log(err);
      };
   };


   const onClickAcknowledgeForm = async () => {

      setLoading(true);
      try {

         const { email, company, projectId, token } = stateProject.allDataOneSheet;
         const { currentRefToAddNewOrReplyOrEdit } = stateRow;

         const { currentRefData } = currentRefToAddNewOrReplyOrEdit;

         const refType = getKeyTextForSheet(pageSheetTypeName);

         let rowOutput = { _id: currentRefData.id };

         rowOutput.data = {
            [`reply-${refType}-acknowledge-${company}`]: true,
            [`reply-${refType}-user-${company}`]: email,
            [`reply-${refType}-date-${company}`]: new Date()
         };

         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput] });

         message.success('Acknowledged Successfully', 2);

         const res = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });

         commandAction({ type: 'reload-data-view-multi-form', data: res.data });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };


   const onClickApplyAdminActionMultiType = (isNoEmailSent) => {

      const currentRefToAddNewOrReplyOrEdit = stateRow.currentRefToAddNewOrReplyOrEdit || {};
      const { tempRefData, tempConsultantToReply } = currentRefToAddNewOrReplyOrEdit;

      let formRefType;
      let currentRefData = null;
      let objConsultantNameToReply = {};
      if (tempConsultantToReply) {
         formRefType = pageSheetTypeName === 'page-rfa' ? 'form-reply-RFA' : 'form-reply-multi-type';
         currentRefData = tempRefData;
         objConsultantNameToReply = {
            consultantNameToReplyByBothSideActionUser: tempConsultantToReply
         };
      } else {
         if (tempRefData) {
            formRefType = pageSheetTypeName === 'page-rfa' ? 'form-resubmit-RFA' : 'form-resubmit-multi-type';
            currentRefData = tempRefData;
         } else {
            formRefType = pageSheetTypeName === 'page-rfa' ? 'form-submit-RFA' : 'form-submit-multi-type';
         };
      };


      buttonPanelFunction(formRefType);
      getSheetRows({
         currentRefToAddNewOrReplyOrEdit: {
            currentRefData,
            formRefType,
            isFormEditting: false,
            ...objConsultantNameToReply,
            withNoEmailSent: isNoEmailSent,
         },
      });
   };



   const exportDataToCsv = async (onClickCancelModal, isIncludeDrawingDetail) => {

      const { pageSheetTypeName, publicSettings: { headers, overdueLeadConsultantOnly }, companies } = stateProject.allDataOneSheet;

      const { drawingTypeTreeTable, rowsTable } = allDataTableInfo;

      const { rfaData } = stateRow;

      let headerArrayForTableDms = headers.map(hd => hd.text);
      if (pageSheetTypeName === 'page-spreadsheet') {
         const indexOfConstructionIssuanceDate = headerArrayForTableDms.indexOf('Construction Issuance Date');
         if (indexOfConstructionIssuanceDate !== -1) {
            headerArrayForTableDms.splice(indexOfConstructionIssuanceDate + 1, 0, 'Issue For Construction', 'IFC Rev', 'IFC Date'); // TEMP_FIX_ADD_ISSUSE_FOR_CONSTRUCTION_COLUMN
         };
      };

      const headersShown = pageSheetTypeName === 'page-spreadsheet' ? headerArrayForTableDms
         : (pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc')
            ? [
               ...getHeadersForm(pageSheetTypeName).filter(hd => {
                  if (pageSheetTypeName === 'page-rfa' && !isIncludeDrawingDetail) {
                     return hd !== 'Drawing Number' && hd !== 'Drawing Name';
                  } else {
                     return true;
                  };
               }),
            ]
            : getHeadersForm(pageSheetTypeName);

      if (pageSheetTypeName === 'page-rfa' && !isIncludeDrawingDetail) {
         headersShown.splice(2, 0, 'Description');
      };


      const { output, arrayIndexOfDwgTypeTree, arrayIndexOfDwgDarkerTypeTree, statusAllCellObject } = getDataToExportReport({
         drawingTypeTree: drawingTypeTreeTable,
         rowsCurrentInView: rowsTable,
         headersShown, isIncludeDrawingDetail, rfaData,
         pageSheetTypeName, companies, isForExcelExport: true, overdueLeadConsultantOnly
      });


      const headersFormat = pageSheetTypeName === 'page-spreadsheet' ? [
         ...headersShown.filter(hd => hd !== 'Drawing'),
         'Consultant (1)',
         'Consultant (2)',
         'Consultant (3)',
         'Consultant (4)',
         'Consultant (5)',
         'Consultant (6)',
         'Consultant (7)',
      ] : headersShown;


      try {
         message.info('Exporting to CSV, please wait ...!', 2);

         const workbook = new ExcelJS.Workbook();

         let worksheet = workbook.addWorksheet(projectName);

         worksheet.columns = headersFormat.map(header => ({
            header: header, key: header,
            width: configExcelWidth(header)
         }));

         worksheet.addRows(output);


         arrayIndexOfDwgTypeTree.forEach(index => {
            const parentRow = worksheet.getRow(index + 2);
            parentRow.eachCell({ includeEmpty: true }, (cell) => {
               cell.font = { bold: true, size: 12 };
               cell.fill = {
                  type: 'pattern', pattern: 'solid',
                  fgColor: { argb: colorExcel.grey },
               };
            });
         });
         arrayIndexOfDwgDarkerTypeTree.forEach(index => {
            const parentRow = worksheet.getRow(index + 2);
            parentRow.eachCell({ includeEmpty: true }, (cell) => {
               cell.font = { bold: true, size: 13 };
               cell.fill = {
                  type: 'pattern', pattern: 'solid',
                  fgColor: { argb: colorExcel.greyDarker },
               };
            });
         });


         Object.keys(statusAllCellObject).forEach(rowIndex => {

            const statusInfo = statusAllCellObject[rowIndex];

            const row = worksheet.getRow(parseInt(rowIndex) + 2);

            row.eachCell({ includeEmpty: true }, (cell, columnIndex) => {

               if (statusInfo[columnIndex - 1]) {

                  if (columnIndex - 1 === headersFormat.indexOf('Due Date')) {
                     cell.font = {
                        bold: true,
                        color: { argb: colorExcel[statusInfo[columnIndex - 1]] }
                     };
                  } else {
                     cell.fill = {
                        type: 'pattern', pattern: 'solid',
                        fgColor: { argb: colorExcel[statusInfo[columnIndex - 1]] },
                     };
                  };
               };
            });
         });

         const headerRow = worksheet.getRow(1);
         headerRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = {
               bold: true,
               color: { argb: colorExcel.white },
               size: 14,
            };
            cell.fill = {
               type: 'pattern', pattern: 'solid',
               fgColor: { argb: colorExcel.primary },
            };
         });



         const buffer = await workbook.xlsx.writeBuffer();
         FileSaver.saveAs(new Blob([buffer]), `${projectName}_${refType.toUpperCase()}_${moment().format('DD/MM/YY')}.xlsx`);

         onClickCancelModal();

      } catch (err) {
         console.log('Error exporting CSV report', err);
         onClickCancelModal();
      };
   };



   const exportDataToPdf = async (onClickCancelModal, isIncludeDrawingDetail) => {

      try {
         message.info('Exporting to PDF, please wait ...!', 2);
         getSheetRows({ loading: true });

         const filePdfBlobOutput = await pdf(
            <ExportPdfDataView
               stateProject={stateProject}
               stateRow={stateRow}
               isIncludeDrawingDetail={isIncludeDrawingDetail}
               allDataTableInfo={allDataTableInfo}
            />
         ).toBlob();

         const csvURL = window.URL.createObjectURL(filePdfBlobOutput);
         let tempLink = document.createElement('a');
         tempLink.href = csvURL;
         tempLink.setAttribute('download', `${projectName}_${refType.toUpperCase()}_${moment().format('DD/MM/YY')}.pdf`);
         tempLink.click();

         onClickCancelModal();
         getSheetRows({ loading: false });
      } catch (err) {
         console.log('Error exporting PDF report', err);
         onClickCancelModal();
      }
   };



   const onClickSavePublicProjectSettings = async (objSetting) => {
      setLoading(true);

      try {
         const { email, projectId, token } = stateProject.allDataOneSheet;
         getSheetRows({ loading: true });

         await apiUpdateSettingPublic({ token, projectId, email, publicSettings: { ...objSetting } });

         message.success('Save Project Settings Successfully', 2);

         const res = await apiFetchDataProjectByDiscipline({ token, projectId, email, isNoNeedSortRows: true, isRfaPage: true }); // TEST_APPY_TAB_VIEW

         commandAction({ type: 'reload-data-view-rfa', data: res.data });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };




   const deleteUploadedFiles = async () => {

      setLoading(true);

      const { token, projectId, email, pageUploadType } = stateProject.allDataOneSheet;
      const { rowsSelected, rowsFileAllInit } = stateRow;

      let rowsToDelete = [];
      rowsSelected.forEach(row => {
         const rowsSameNameSameParent = rowsFileAllInit.filter(r => {
            return r['parentId'] === row['parentId'] && r['fileName'] === row['fileName'];
         });
         rowsToDelete = [...rowsToDelete, ...rowsSameNameSameParent];
      });

      try {
         await Promise.all(rowsToDelete.map(async (file, i) => {
            const { fileKey } = file;
            await apiDeleteFileS3({ token, fileKey });
         }));

         await apiDeleteRowsUploadFile({ token, projectId, email, rowIdsArray: rowsToDelete.map(r => r.id) });

         message.success('Deleted Successfully', 2);

         let [resSettings, res] = await Promise.all([
            apiFetchPublicSettingThisProject({ token, projectId }),
            apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
         ]);
         commandAction({
            type: 'reload-data-view-upload-files',
            data: {
               rowsAllUploadFiles: res.data,
               publicSettings: resSettings.data
            }
         });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };




   const onClickApplyImportExcelToDms = ({ excelDataRowsToPreview, excelDataTreeToPreview, nodeToInsert }) => {

      const { rowsAll, drawingTypeTree, idRowsNew, drawingsTypeNewIds, rowsUpdatePreRowOrParentRow } = stateRow;
      const { allDataOneSheet: { publicSettings: { headers } } } = stateProject;

      let indexThisNode;
      let isThereDrawingInsideThisFolder = false;
      const nodeFound = drawingTypeTree.find((node, i) => {
         if (node['title'] === nodeToInsert) {
            indexThisNode = i;
            if (rowsAll.find(row => row['_parentRow'] === node['id'])) {
               isThereDrawingInsideThisFolder = true;
            };
            return true;
         };
      });


      let newFolderForExistingRowInsideThisParentNode = [];
      let newIdFolderForExistingRowInsideThisParentNode;

      if (isThereDrawingInsideThisFolder) {
         newIdFolderForExistingRowInsideThisParentNode = mongoObjectId();

         newFolderForExistingRowInsideThisParentNode = [{
            expanded: true,
            id: newIdFolderForExistingRowInsideThisParentNode,
            title: 'New Drawing Type',
            treeLevel: nodeFound['treeLevel'] + 1,
            parentId: nodeFound['id']
         }];
         const rowsExistingInsideThisFolder = rowsAll.filter(row => row['_parentRow'] === nodeFound['id']);

         rowsExistingInsideThisFolder.forEach(row => {
            row['_parentRow'] = newIdFolderForExistingRowInsideThisParentNode;
            updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
         });
      };


      excelDataTreeToPreview.forEach(node => {
         node['treeLevel'] = nodeFound['treeLevel'] + 1;
         node['parentId'] = nodeFound['id'];
      });

      const drawingsTypeNewIdsToAdd = [
         ...drawingsTypeNewIds,
         ...excelDataTreeToPreview.map(node => node['id']),
         ...(newIdFolderForExistingRowInsideThisParentNode ? [newIdFolderForExistingRowInsideThisParentNode] : [])
      ];
      const idRowsNewToAdd = [...idRowsNew, ...excelDataRowsToPreview.map(row => row['id'])];


      const part1 = drawingTypeTree.filter((x, i) => i <= indexThisNode);
      const part2 = drawingTypeTree.filter((x, i) => i > indexThisNode);
      const drawingTypeTreeNew = [
         ...part1,
         ...excelDataTreeToPreview,
         ...newFolderForExistingRowInsideThisParentNode,
         ...part2
      ];



      let cellsModifiedTempObj = {};
      excelDataRowsToPreview.forEach(row => {
         headers.forEach(hd => {
            if (row[hd.text]) {
               cellsModifiedTempObj[`${row.id}~#&&#~${hd.text}`] = row[hd.text];
            };
         });
         updatePreRowParentRowToState(rowsUpdatePreRowOrParentRow, row);
      });

      overwriteCellsModified({ ...stateCell.cellsModifiedTemp, ...cellsModifiedTempObj });


      commandAction({
         type: 'drawing-folder-organization',
         data: {
            rowsAll: [...rowsAll, ...excelDataRowsToPreview],
            drawingTypeTree: drawingTypeTreeNew,
            drawingsTypeNewIds: drawingsTypeNewIdsToAdd,
            idRowsNew: idRowsNewToAdd,
            rowsUpdatePreRowOrParentRow
         }
      });
   };


   return (
      <>
         {panelSettingType === 'save-ICON' && (
            <PanelConfirm
               onClickCancel={onClickCancelModal}
               onClickApply={saveDataToServerAndReloadData}
               content='Do you want to save ?'
            />
         )}

         {panelSettingType === 'filter-ICON' && (
            <FormFilter
               applyFilter={applyFilter}
               onClickCancelModal={onClickCancelModal}
               stateRow={stateRow}
               stateProject={stateProject}
               pageSheetTypeName={pageSheetTypeName}
            />
         )}

         {panelSettingType === 'swap-ICON-1' && (
            <PanelConfirm
               onClickCancel={onClickCancelModal}
               onClickApply={applyQuitGroupingMode}
               content='Do you want to quit grouping mode ?'
            />
         )}
         {panelSettingType === 'swap-ICON-2' && (
            <PanelConfirmResetMode
               onClickCancelModal={onClickCancelModal}
               applyResetMode={applyResetMode}
               modeFilter={stateRow.modeFilter}
               modeSort={stateRow.modeSort}
               modeSearch={stateRow.modeSearch}
               pageSheetTypeName={pageSheetTypeName}
            />
         )}


         {panelSettingType === 'reorderColumn-ICON' && (
            <ReorderColumnForm
               applyReorderColumns={applyReorderColumns}
               onClickCancelModal={onClickCancelModal}
               stateProject={stateProject}
            />
         )}

         {panelSettingType === 'viewTemplate-ICON' && (
            <PanelConfirm
               onClickApply={applyViewTemplate} onClickCancel={onClickCancelModal}
               content='Do you want to save a new view template ?'
            />
         )}

         {panelSettingType === 'sort-ICON' && (
            <FormSort
               applySort={applySort}
               onClickCancel={onClickCancelModal}
               headers={stateProject.userData.headersShown}
               modeSort={stateRow.modeSort}
            />
         )}


         {panelSettingType === 'group-ICON' && (
            <FormGroup
               applyGroup={applyGroup}
               onClickCancelModal={onClickCancelModal}
               headers={stateProject.userData.headersShown}
            />
         )}


         {(panelSettingType === 'Insert Drawings Below' || panelSettingType === 'Insert Drawings Above') && (
            <PanelPickNumber
               onClickCancelModal={onClickCancelModal}
               onClickApply={onClickInsertRow}
            />
         )}

         {panelSettingType === 'Duplicate Drawings' && (
            <PanelPickNumber
               onClickCancelModal={onClickCancelModal}
               onClickApply={onClickDuplicateRows}
            />
         )}

         {panelSettingType === 'history-ICON' && (
            <>
               {pageSheetTypeName === 'page-spreadsheet' ? (
                  <TableActivityHistory
                     stateRow={stateRow}
                     stateProject={stateProject}
                     onClickCancelModal={onClickCancelModal}
                  />
               ) : (
                  <TableAllFormActivityHistory
                     pageSheetTypeName={pageSheetTypeName}
                     stateRow={stateRow}
                     onClickCancelModal={onClickCancelModal}
                  />
               )}
            </>
         )}

         {panelSettingType === 'color-cell-history-ICON' && (
            <FormCellColorizedCheck
               setCellHistoryArr={setCellHistoryArr}
               stateProject={stateProject}
               onClickCancelModal={onClickCancelModal}
            />
         )}


         {panelSettingType === 'Date Automation' && (
            <FormDateAutomation
               applyDateAutomation={applyDateAutomation}
               onClickCancel={onClickCancelModal}
               rowsToAutomation={rowsSelected.length > 0 ? rowsSelected : [panelType.cellProps.rowData]}
            />
         )}

         {panelSettingType === 'Create New Drawing Revision' && (
            <PanelConfirm
               onClickCancel={onClickCancelModal}
               onClickApply={createNewDrawingRevision}
               content={`Are you sure to create a new revision of this drawing: ${panelType.cellProps.rowData['Drawing Number'] || ' '} - ${panelType.cellProps.rowData['Drawing Name'] || ' '} ?`}
            />
         )}

         {panelSettingType === 'View Drawing Revision' && (
            <TableDrawingDetail
               {...panelType.cellProps}
               onClickCancelModal={onClickCancelModal}
               onClickApply={onClickCancelModal}
               stateProject={stateProject}
               stateRow={stateRow}
            />
         )}

         {panelSettingType === 'addDrawingType-ICON' && pageSheetTypeName === 'page-spreadsheet' && (
            <FormDrawingTypeOrder
               applyFolderOrganize={applyFolderOrganize}
               onClickCancelModal={onClickCancelModal}
               stateProject={stateProject}
               stateRow={stateRow}
            />
         )}

         {panelSettingType === 'addUploadFolder-ICON' && (
            <FormFolderUploadOrganize
               applyFolderUploadOrganize={applyFolderUploadOrganize}
               onClickCancelModal={onClickCancelModal}
               stateProject={stateProject}
               stateRow={stateRow}
            />
         )}


         {panelSettingType === 'View Cell History' && (
            <TableCellHistory
               {...panelType.cellProps}
               stateProject={stateProject}
            />
         )}





         {pageSheetTypeName === 'page-spreadsheet' && panelSettingType === 'Delete Drawing' && (
            <PanelConfirm
               onClickCancel={onClickCancelModal}
               onClickApply={deleteDrawing}
               content={`Are you sure to delete the: ${panelType.cellProps.rowData['Drawing Number'] || ' '} - ${panelType.cellProps.rowData['Drawing Name'] || ' '} ?`}
            />
         )}


         {pageSheetTypeName === 'page-authorities-submission' && panelSettingType === 'Delete Files' && (
            <PanelConfirmDeleteFiles
               onClickCancel={onClickCancelModal}
               onClickApply={deleteUploadedFiles}
               rowsSelected={rowsSelected}
            />
         )}




         {panelSettingType === 'colorized-ICON' && (
            <ColorizedForm
               applyColorization={applyColorization}
               onClickCancelModal={onClickCancelModal}
               stateRow={stateRow}
               stateProject={stateProject}
            />
         )}

         {panelSettingType === 'Insert Drawings By Type' && (
            <PanelPickNumber onClickCancelModal={onClickCancelModal} onClickApply={onClickFolderInsertSubRows} />
         )}


         {(
            panelSettingType === 'form-submit-RFA' ||
            panelSettingType === 'form-resubmit-RFA' ||
            panelSettingType === 'form-reply-RFA'
         ) && (
               <PanelAddNewRFA
                  onClickCancelModal={onClickCancelModal}
                  onClickApplyAddNewRFA={onClickApplyAddNewRFA}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  getSheetRows={getSheetRows}
                  submissionRfaFormUpdateOrForwardEmail={submissionRfaFormUpdateOrForwardEmail}
               />
            )}


         {(panelSettingType === 'option-email-or-not-for-admin' && pageSheetTypeName !== 'page-eri'  && pageSheetTypeName !== 'page-lr'  && pageSheetTypeName !== 'page-sm' ) && (
            <PanelConfirm
               onClickCancel={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(true);
               }}
               onClickApply={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(false);
               }}
               newTextBtnApply='Send Email'
               newTextBtnCancel={`Update ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} Without Sending Email`}
               content={`Do you want to submit/reply ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} and send an email ? (Not sending email option allows you to migrate ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} drawings already submitted previously)`}
            />
         )}

         {(panelSettingType === 'option-email-or-not-for-admin' && pageSheetTypeName === 'page-eri') && (
            <PanelConfirm
               onClickCancel={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(true);
               }}
               onClickApply={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(false);
               }}
               newTextBtnApply='Send Email'
               newTextBtnCancel={`Update ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} Without Sending Email`}
               content={`Do you want to submit/reply ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} and send an email ? (Not sending email option allows you to migrate ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} drawings already submitted previously)`}
            />
         )}
         {(panelSettingType === 'option-email-or-not-for-admin' && pageSheetTypeName === 'page-lr') && (
            <PanelConfirm
               onClickCancel={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(true);
               }}
               onClickApply={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(false);
               }}
               newTextBtnApply='Send Email'
               newTextBtnCancel={`Update ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} Without Sending Email`}
               content={`Do you want to submit/reply ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} and send an email ? (Not sending email option allows you to migrate ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} drawings already submitted previously)`}
            />
         )}
         {(panelSettingType === 'option-email-or-not-for-admin' && pageSheetTypeName === 'page-sm') && (
            <PanelConfirm
               onClickCancel={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(true);
               }}
               onClickApply={() => {
                  onClickCancelModal();
                  onClickApplyAdminActionMultiType(false);
               }}
               newTextBtnApply='Send Email'
               newTextBtnCancel={`Update ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} Without Sending Email`}
               content={`Do you want to submit/reply ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} and send an email ? (Not sending email option allows you to migrate ${pageSheetTypeName.slice(5, pageSheetTypeName.length).toUpperCase()} drawings already submitted previously)`}
            />
         )}


         {(
            panelSettingType === 'form-submit-multi-type' ||
            panelSettingType === 'form-resubmit-multi-type' ||
            panelSettingType === 'form-reply-multi-type'
         ) && (
               (pageSheetTypeName === 'page-eri' ||pageSheetTypeName === 'page-lr' || pageSheetTypeName === 'page-sm') ?
               (<PanelAddNewMultiFormPM
                  onClickCancelModal={onClickCancelModal}
                  onClickApplySendFormToSignature={onClickApplySendFormToSignature}
                  onClickApplySendFormToSignatureERI={onClickApplySendFormToSignatureERI}
                  onClickApplySendFormToSignatureLR={onClickApplySendFormToSignatureLR}
                  onClickApplySendFormToSignatureSM={onClickApplySendFormToSignatureSM}
                  submissionMultiFormUpdateOrForwardEmail={submissionMultiFormUpdateOrForwardEmail}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  getSheetRows={getSheetRows}
               />): (<PanelAddNewMultiForm
                  onClickCancelModal={onClickCancelModal}
                  onClickApplySendFormToSignature={onClickApplySendFormToSignature}
                  submissionMultiFormUpdateOrForwardEmail={submissionMultiFormUpdateOrForwardEmail}
                  stateRow={stateRow}
                  stateProject={stateProject}
                  getSheetRows={getSheetRows}
               />)
              
            )}



         {(panelSettingType === 'acknowledge-form' || panelSettingType === 'acknowledge-or-reply-form') && (
            <PanelConfirm
               onClickCancel={onClickCancelModal}
               onClickApply={onClickAcknowledgeForm}
               newTextBtnApply={'Acknowledge'}
               content={panelSettingType === 'acknowledge-form' ? 'Do you want to acknowledge this form ?' : 'Do you want to acknowledge or reply this form ?'}

               onClickApplyAdditional01={() => {
                  buttonPanelFunction('form-reply-multi-type');
               }}
               newTextBtnApplyAdditional01={panelSettingType === 'acknowledge-or-reply-form' && 'Reply'}
            />
         )}


         {panelSettingType === 'export-data' && (
            <PanelExportPdfAndExcel
               onClickCancelModal={onClickCancelModal}
               exportDataToPdf={exportDataToPdf}
               exportDataToCsv={exportDataToCsv}
               pageSheetTypeName={pageSheetTypeName}
            />
         )}


         {panelSettingType === 'project-settings-ICON' && (
            <PanelProjectSettings
               stateProject={stateProject}
               onClickCancelModal={onClickCancelModal}
               onClickApply={onClickSavePublicProjectSettings}
            />
         )}

         {panelSettingType === 'import-excel-ICON' && (
            <PanelImportExcelData
               stateProject={stateProject}
               stateRow={stateRow}
               onClickCancelModal={onClickCancelModal}
               onClickApply={onClickApplyImportExcelToDms}
            />
         )}


      </>
   );
};


const mapStateToProps = (state) => ({
   stateCell: state.dms_cell,
   stateRow: state.dms_row,
   stateProject: state.dms_project,
});


export default connect(mapStateToProps, {
   getCellModifiedTemp, overwriteCellsModified,
   getSheetRows,
})(PanelSetting);








export const _processRowsChainNoGroupFnc1 = (rows) => {
   let rowsProcessed = [];

   if (!(rows instanceof Array) || !rows.length) {
      return rowsProcessed;
   };

   let firstRowIndex = rows.findIndex((row) => !row._preRow);
   while (firstRowIndex >= 0) {
      let preRow = rows.splice(firstRowIndex, 1)[0];
      while (preRow) {
         rowsProcessed.push(preRow);

         let nextRowIndex = rows.findIndex((row) => String(row._preRow) == String(preRow.id));
         if (nextRowIndex >= 0) preRow = rows.splice(nextRowIndex, 1)[0];
         else preRow = null;
      };
      firstRowIndex = rows.findIndex((row) => !row._preRow);
   };
   _processRowsLossHeadFnc1(rows, rowsProcessed);
   return rowsProcessed;
};
const _processRowsLossHeadFnc1 = (rows, rowsProcessed) => {
   if (!rows.length) return;

   let firstRowIndex = rows.findIndex((r) => _filterRowLossPreRowFnc(r, rows));
   while (firstRowIndex >= 0) {
      let preRow = rows.splice(firstRowIndex, 1)[0];
      while (preRow) {
         rowsProcessed.push(preRow);

         let nextRowIndex = rows.findIndex((row) => String(row._preRow) == String(preRow.id));
         if (nextRowIndex >= 0) preRow = rows.splice(nextRowIndex, 1)[0];
         else preRow = null;
      };
      firstRowIndex = rows.findIndex((r) => _filterRowLossPreRowFnc(r, rows));
   };
};
export const _processChainRowsSplitGroupFnc2 = (rows) => {
   let rowsProcessed = [];

   if (!(rows instanceof Array) || !rows.length) return rowsProcessed;

   let firstRowIndex = rows.findIndex((row) => !row._preRow);
   while (firstRowIndex >= 0) {
      let preRow = rows.splice(firstRowIndex, 1)[0];
      let chain = [];
      while (preRow) {
         chain.push(preRow);
         let nextRowIndex = rows.findIndex((row) => String(row._preRow) == String(preRow.id));

         if (nextRowIndex >= 0) preRow = rows.splice(nextRowIndex, 1)[0];
         else preRow = null;
      };
      rowsProcessed.push(chain);
      firstRowIndex = rows.findIndex((row) => !row._preRow);
   };
   _processChainRowsLossHeadFnc2(rows, rowsProcessed);
   return rowsProcessed;
};
const _processChainRowsLossHeadFnc2 = (rows, rowsProcessed) => {
   if (!rows.length) return;

   let firstRowIndex = rows.findIndex((r) => _filterRowLossPreRowFnc(r, rows));
   while (firstRowIndex >= 0) {
      let preRow = rows.splice(firstRowIndex, 1)[0];
      let chain = [];
      while (preRow) {
         chain.push(preRow);

         let nextRowIndex = rows.findIndex((row) => String(row._preRow) == String(preRow.id));
         if (nextRowIndex >= 0) preRow = rows.splice(nextRowIndex, 1)[0];
         else preRow = null;
      };
      rowsProcessed.push(chain);
      firstRowIndex = rows.findIndex((r) => _filterRowLossPreRowFnc(r, rows));
   };
};
const _filterRowLossPreRowFnc = (row, rows) => {
   return rows.every(r => String(row._preRow) != String(r.id));
};
export const updatePreRowParentRowToState = (objState, row) => {
   objState[row.id] = {
      id: row.id,
      _preRow: row._preRow,
      _parentRow: row._parentRow,
   };
};



const getPartsCombine = (index, parts) => {
   let output = '';
   for (let i = 2; i <= index; i++) {
      output += `/${parts[i]}`;
   };
   if (output[0] === '/') {
      output = output.slice(1, output.length);
   };
   return output;
};



export const getDataForRfaSheet = ({ rowsAllAndHistory }) => {


   let rowsWithRFACurrentAndHistory = rowsAllAndHistory.filter(x => x.rfaNumber);

   let treeViewRfaOutput = [];
   const allSubmittedRfa = [...new Set(rowsWithRFACurrentAndHistory.map(r => r['rfaNumber']))];

   allSubmittedRfa.forEach(rfaNumber => {
      const allParts = rfaNumber.split('/');

      allParts.forEach((part, i) => {
         if (i >= 2 && i < allParts.length - 1) {
            const nodeId = getPartsCombine(i, allParts);
            const allPartsNodeId = nodeId.split('/');
            if (!treeViewRfaOutput.find(x => x.id === nodeId)) {
               treeViewRfaOutput.push({
                  id: nodeId,
                  title: allPartsNodeId[allPartsNodeId.length - 1],
                  parentId: i === 2 ? 'PROJECT_ID' : nodeId.slice(0, nodeId.length - part.length - 1),
                  treeLevel: i,
                  expanded: true,
               });
            };
         };
      });

      let allDwgs = rowsWithRFACurrentAndHistory.filter(dwg => dwg['rfaNumber'] === rfaNumber);
      const allRfaRef = [...new Set(allDwgs.map(x => x['RFA Ref'] || '').filter(x => x))].sort();

      if (allRfaRef.length > 0) {
         let btnTextArray = allRfaRef.map(rfa => {
            return rfa.slice(rfaNumber.length, rfa.length) || '0';
         });
         const nodeToAdd = {
            id: rfaNumber,
            'rfaNumber': rfaNumber,
            'btn': [...new Set(btnTextArray)].sort(),
            treeLevel: allParts.length - 1,
            expanded: true,
            parentId: getPartsCombine(allParts.length - 2, allParts)
         };
         treeViewRfaOutput.push(nodeToAdd);
      };
   });

   treeViewRfaOutput = treeViewRfaOutput.sort((a, b) => a.id > b.id ? 1 : -1);

   rowsWithRFACurrentAndHistory = _.sortBy(rowsWithRFACurrentAndHistory, ['Rev', 'Drawing Number']);

   return {
      rowsDataRFA: rowsWithRFACurrentAndHistory,
      treeViewRFA: treeViewRfaOutput,
   };
};





export const getDataForMultiFormSheet = (rows, pageSheetTypeName) => {

   const refType = getKeyTextForSheet(pageSheetTypeName);

   let rowsOutput = [];
   let treeViewRefOutput = [];

   const allSubmittedRef = [...new Set(rows.map(r => r[`${refType}Ref`]).filter(x => x))].sort();

   allSubmittedRef.forEach(refText => {
      let allParts = refText.split('/');

      if (refType === 'rfc') {
         const itemTrade = allParts[2];
         const itemCompany = allParts[3];
         allParts = [
            ...allParts.filter((x, i) => i <= 1),
            itemCompany,
            itemTrade,
            ...allParts.filter((x, i) => i >= 4),
         ];
      };

      allParts.forEach((part, i) => {
         if (i >= 2 && i < allParts.length - 1) {
            const nodeId = getPartsCombine(i, allParts);
            const allPartsNodeId = nodeId.split('/');
            if (!treeViewRefOutput.find(x => x.id === nodeId)) {
               treeViewRefOutput.push({
                  id: nodeId,
                  treeLevel: i,
                  expanded: true,
                  title: allPartsNodeId[allPartsNodeId.length - 1],
                  parentId: i === 2 ? 'PROJECT_ID' : nodeId.slice(0, nodeId.length - part.length - 1)
               });
            };
         };
      });


      let allDwgs = rows.filter(row => row[`${refType}Ref`] === refText);

      const btnTextArray = [...new Set(allDwgs.map(x => x['revision']))].sort();
      allDwgs.forEach(row => {
         row.btn = btnTextArray;
         row.parentId = refType === 'rfc' ? getDisciplineStringRfc(refText) : getDisciplineOfRefString(refText);
      });
      rowsOutput = [...rowsOutput, ...allDwgs];
   });

   treeViewRefOutput = treeViewRefOutput.sort((a, b) => a.id > b.id ? 1 : -1);

   return {
      rowsData: rowsOutput,
      treeView: treeViewRefOutput
   };
};

export const getDataForMultiFormSheetPM = (rows, pageSheetTypeName) => {

   const refType = 'pm';

   let rowsOutput = [];
   let treeViewRefOutput = [];

   const allSubmittedRef = [...new Set(rows.map(r => r[`${refType}Ref`]).filter(x => x))].sort();

   allSubmittedRef.forEach(refText => {
      let allParts = refText.split('/');
      allParts.forEach((part, i) => {
         if (i >= 2 && i < allParts.length - 1) {
            const nodeId = getPartsCombine(i, allParts);
            const allPartsNodeId = nodeId.split('/');
            if (!treeViewRefOutput.find(x => x.id === nodeId)) {
               treeViewRefOutput.push({
                  id: nodeId,
                  treeLevel: i,
                  expanded: true,
                  title: allPartsNodeId[allPartsNodeId.length - 1],
                  parentId: i === 2 ? 'PROJECT_ID' : nodeId.slice(0, nodeId.length - part.length - 1)
               });
            };
         };
      });


      let allDwgs = rows.filter(row => row[`${refType}Ref`] === refText);

      const btnTextArray = [...new Set(allDwgs.map(x => x['revision']))].sort();
      allDwgs.forEach(row => {
         row.btn = btnTextArray;
         row.parentId =  getDisciplineOfRefString(refText);
      });
      rowsOutput = [...rowsOutput, ...allDwgs];
   });

   treeViewRefOutput = treeViewRefOutput.sort((a, b) => a.id > b.id ? 1 : -1);

   return {
      rowsData: rowsOutput,
      treeView: treeViewRefOutput
   };
};



export const getDataForRowsUploadFile = (data) => {

   const { rowsFile, folderTree } = data;

   let rowsData = [];
   let rowsDataInit = [];

   folderTree.forEach(node => {
      const rowsChildrenFound = rowsFile.filter(r => r['parentId'] === node.id);

      const allFileNameThisChildren = [...new Set(rowsChildrenFound.map(r => r['fileName']))];

      allFileNameThisChildren.forEach(fileName => {

         const filesUnderThisName = sortDataByCreatedAt(rowsChildrenFound.filter(x => x['fileName'] === fileName));
         const versionCount = filesUnderThisName.length;
         const btnVersionArray = Array.from(Array(versionCount).keys());

         filesUnderThisName.forEach((row, i) => {
            row['btn'] = btnVersionArray;
            row['version'] = i;

            const rowData = row['data'] || {};

            for (const key in rowData) {
               row[key] = rowData[key];
            };
            delete row['data'];
         });

         rowsData = [...rowsData, filesUnderThisName[filesUnderThisName.length - 1]].sort((a, b) => (a['fileName'] > b['fileName'] ? 1 : -1));
         rowsDataInit = [...rowsDataInit, ...filesUnderThisName].sort((a, b) => (a['fileName'] > b['fileName'] ? 1 : -1));
      });
   });

   return {
      rowsData,
      rowsDataInit,
      treeView: folderTree,
   };
};



export const getKeyTextForSheet = (pageSheetTypeName) => {
   return pageSheetTypeName === 'page-rfa' ? 'rfa'
      : pageSheetTypeName === 'page-rfam' ? 'rfam'
         : pageSheetTypeName === 'page-cvi' ? 'cvi'
            : pageSheetTypeName === 'page-rfi' ? 'rfi'
               : pageSheetTypeName === 'page-dt' ? 'dt'
                  : pageSheetTypeName === 'page-mm' ? 'mm'
                     : pageSheetTypeName === 'page-eri' ? 'eri'
                        : pageSheetTypeName === 'page-lr' ? 'lr'
                           : pageSheetTypeName === 'page-sm' ? 'sm'
                              : pageSheetTypeName === 'page-doc' ? 'doc'
                                 : pageSheetTypeName === 'page-qr' ? 'qr'
                                    : pageSheetTypeName === 'page-qrm' ? 'qrm'
                                       : pageSheetTypeName === 'page-rfc' ? 'rfc'
                                          : 'n/a';
};



export const saveDataToServer = async (stateCell, stateRow, stateProject, commandAction, setLoading) => {

   const { email, projectId, token, role, projectName, projectIsAppliedRfaView, publicSettings, company } = stateProject.allDataOneSheet;
   const { headersShown, headersHidden, nosColumnFixed, colorization } = stateProject.userData;
   const { headers } = publicSettings;


   let { cellsModifiedTemp } = stateCell;
   let {
      rowsVersionsToSave,
      rowsUpdatePreRowOrParentRow,
      drawingTypeTreeInit,
      drawingTypeTree,
      drawingsTypeDeleted,
      rowsDeleted,

      rowsAll,
      rowsUpdateSubmissionOrReplyForNewDrawingRev,

      // viewTemplateNodeId,
      // viewTemplates,
      modeFilter,
      modeSort,
   } = stateRow;


   // check new version reply go blank
   const rowsGetNewRev = rowsAll.filter(r => rowsUpdateSubmissionOrReplyForNewDrawingRev.indexOf(r.id) !== -1);


   try {
      setLoading(true);
      commandAction({ type: '' });


      const arrayRowId = [... new Set(Object.keys(cellsModifiedTemp).map(key => {
         const parts = key.split('~') || [];
         return parts[0];
      }).filter(x => x))];

      let cellsHistoryFromDB = [];
      if (arrayRowId.length > 0) {
         const resCellsHistory = await apiFetchCellHistoryByIds({ token, projectId, arrayRowId });
         cellsHistoryFromDB = resCellsHistory.data;
      };

      let resDB = await apiFetchDataProjectByDiscipline({ token, projectId, email });


      let { publicSettings: publicSettingsFromDB, rows: rowsFromDBInit } = resDB.data['dataDmsFetched'];
      let { drawingTypeTree: drawingTypeTreeFromDB, activityRecorded: activityRecordedFromDB } = publicSettingsFromDB;

      const headerKeyDrawingNumber = headers.find(hd => hd.text === 'Drawing Number').key;
      const headerKeyDrawingName = headers.find(hd => hd.text === 'Drawing Name').key;

      let rowsFromDB = rowsFromDBInit.map(row => ({ ...row }));

      let {
         needToSaveTree,
         treeDBModifiedToSave,
         nodesToAddToDB,
         nodesToRemoveFromDB
      } = compareCurrentTreeAndTreeFromDB(
         drawingTypeTreeInit,
         drawingTypeTree,
         drawingsTypeDeleted,
         drawingTypeTreeFromDB,
         activityRecordedFromDB.filter(x => x.action === 'Delete Drawing Type'),
      );
      let activityRecordedArr = [];


      // check if row or its parents deleted by other users
      const rowsUpdatePreRowOrParentRowArray = Object.values(rowsUpdatePreRowOrParentRow)
         .filter(row => !activityRecordedFromDB.find(r => r.id === row.id && r.action === 'Delete Drawing') &&
            !activityRecordedFromDB.find(r => r.id === row._parentRow && r.action === 'Delete Drawing Type'));


      if (rowsUpdatePreRowOrParentRowArray.length > 0) {

         let arrID = [];
         rowsFromDB.forEach(r => { // take out temporarily all rowsUpdatePreRowOrParentRowArray from DB
            if (rowsUpdatePreRowOrParentRowArray.find(row => row.id === r.id)) {
               arrID.push(r.id);
               const rowBelow = rowsFromDB.find(rrr => rrr._preRow === r.id);
               if (rowBelow) rowBelow._preRow = r._preRow;
            };
         });
         rowsFromDB = rowsFromDB.filter(r => arrID.indexOf(r.id) === -1);



         const rowsInOldParent = rowsUpdatePreRowOrParentRowArray.filter(r => {
            return treeDBModifiedToSave.find(tr => tr.id === r._parentRow && !treeDBModifiedToSave.find(x => x.parentId === tr.id));
         });
         const rowsInOldParentDivertBranches = rowsUpdatePreRowOrParentRowArray.filter(r => {
            return treeDBModifiedToSave.find(tr => tr.id === r._parentRow && treeDBModifiedToSave.find(x => x.parentId === tr.id));
         });
         const rowsInNewParent = rowsUpdatePreRowOrParentRowArray.filter(r => {
            return !treeDBModifiedToSave.find(tr => tr.id === r._parentRow);
         });



         const rowsInOldParentOutput = _processChainRowsSplitGroupFnc2([...rowsInOldParent]);
         rowsInOldParentOutput.forEach(arrChain => {
            const rowFirst = arrChain[0];
            const parentRowInDB = treeDBModifiedToSave.find(tr => tr.id === rowFirst._parentRow);
            const rowAbove = rowsFromDB.find(r => r.id === rowFirst._preRow);
            if (rowAbove) {
               if (rowAbove._parentRow !== rowFirst._parentRow) { // rowAbove move to other parent by other user
                  const lastRowInParent = rowsFromDB.find(r => r._parentRow === parentRowInDB.id && !rowsFromDB.find(x => x._preRow === r.id));
                  rowFirst._preRow = lastRowInParent ? lastRowInParent.id : null;
               } else { // rowAbove is still in the same parent
                  const rowBelowRowAbove = rowsFromDB.find(r => r._preRow === rowAbove.id);
                  if (rowBelowRowAbove) rowBelowRowAbove._preRow = arrChain[arrChain.length - 1].id;
                  rowFirst._preRow = rowAbove.id;
               };
            } else {
               if (rowFirst._preRow === null) {
                  const firstRowInParent = rowsFromDB.find(r => r._parentRow === parentRowInDB.id && r._preRow === null);
                  if (firstRowInParent) { // if firstRowInParent undefined means Drawing type has 0 drawing currently...
                     firstRowInParent._preRow = arrChain[arrChain.length - 1].id;
                  };
               } else {
                  const lastRowInParent = rowsFromDB.find(r => r._parentRow === parentRowInDB.id && !rowsFromDB.find(x => x._preRow === r.id));
                  rowFirst._preRow = lastRowInParent ? lastRowInParent.id : null;
               };
            };
            rowsFromDB = [...rowsFromDB, ...arrChain];
         });



         let idsOldParentDivertBranches = [...new Set(rowsInOldParentDivertBranches.map(r => r._parentRow))];
         idsOldParentDivertBranches.forEach(idP => {
            let arrInput = rowsInOldParentDivertBranches.filter(r => r._parentRow === idP);
            let rowsChildren = _processRowsChainNoGroupFnc1([...arrInput]);

            const treeNode = treeDBModifiedToSave.find(x => x.id === idP);
            const newIdParent = mongoObjectId();
            treeDBModifiedToSave.push({
               title: 'New Drawing Type',
               id: newIdParent,
               parentId: treeNode.id,
               treeLevel: treeNode.treeLevel + 1,
               expanded: true,
            });
            needToSaveTree = true;

            activityRecordedArr.push({
               id: newIdParent,
               email,
               createdAt: new Date(),
               action: 'Create Drawing Type',
               [headerKeyDrawingNumber]: 'New Drawing Type',
            });

            rowsChildren.forEach((r, i) => {
               r._preRow = i === 0 ? null : rowsChildren[i - 1].id;
               r._parentRow = newIdParent;
            });
            rowsFromDB = [...rowsFromDB, ...rowsChildren];
         });



         let idsNewParentArray = [...new Set(rowsInNewParent.map(r => r._parentRow))];
         idsNewParentArray.forEach(idP => {
            let arrInput = rowsInNewParent.filter(r => r._parentRow === idP);
            let rowsChildren = _processRowsChainNoGroupFnc1([...arrInput]);
            rowsChildren.forEach((r, i) => {
               r._preRow = i === 0 ? null : rowsChildren[i - 1].id;
            });
            rowsFromDB = [...rowsFromDB, ...rowsChildren];
         });
      };


      // SAVE CELL HISTORY
      let objCellHistory = {};
      cellsHistoryFromDB.map(cell => {
         const headerFound = headers.find(hd => hd.key === cell.headerKey);
         if (headerFound) {
            const headerText = headerFound.text;
            if (cell.histories.length > 0) {
               const latestHistoryText = cell.histories[cell.histories.length - 1].text;
               objCellHistory[`${cell.row}-${headerText}`] = latestHistoryText;
            };
         };
      });


      Object.keys(cellsModifiedTemp).forEach(key => {
         if (objCellHistory[key] && objCellHistory[key] === cellsModifiedTemp[key]) {
            delete cellsModifiedTemp[key];
         } else {
            let rowId = extractCellInfo(key).rowId;
            if (activityRecordedFromDB.find(x => x.id === rowId && x.action === 'Delete Drawing')) {
               delete cellsModifiedTemp[key];
            };
         };
      });


      if (Object.keys(cellsModifiedTemp).length > 0) {
         await apiUpdateOrCreateCellHistory({ token, projectId, cellsHistory: convertCellTempToHistory(cellsModifiedTemp, stateProject) });
      };

      // SAVE DRAWINGS NEW VERSION
      rowsVersionsToSave = rowsVersionsToSave.filter(row => !activityRecordedFromDB.find(r => r.id === row.id && r.action === 'Delete Drawing'));
      if (rowsVersionsToSave.length > 0) {
         await apiCreateRowsHistory({ token, projectId, email, rowsHistory: convertDrawingVersionToHistory(rowsVersionsToSave, stateProject) });
      };

      // DELETE ROWS
      let rowDeletedFinal = [];
      rowsDeleted.forEach(row => { // some rows already deleted by previous user => no need to delete anymore
         const rowInDB = rowsFromDB.find(r => r.id === row.id);
         if (
            rowInDB &&
            !rowInDB['rfaNumber'] && !row['rfaNumber'] // Make sure drawing in RFA can not be deleted
         ) {
            const rowBelow = rowsFromDB.find(r => r._preRow === rowInDB.id);
            if (rowBelow) {
               rowBelow._preRow = rowInDB._preRow;
            };
            rowsFromDB = rowsFromDB.filter(r => r.id !== rowInDB.id); // FIXEDDDDDDDDDDDDDDDDDDD
            rowDeletedFinal.push(row);
         };
      });




      if (nodesToRemoveFromDB.length > 0) {
         nodesToRemoveFromDB.forEach(fd => {
            activityRecordedArr.push({
               id: fd.id, email, createdAt: new Date(), action: 'Delete Drawing Type',
               [headerKeyDrawingNumber]: fd.title,
            });
         });
      };
      if (nodesToAddToDB.length > 0) {
         nodesToAddToDB.forEach(fd => {
            activityRecordedArr.push({
               id: fd.id, email, createdAt: new Date(), action: 'Create Drawing Type',
               [headerKeyDrawingNumber]: fd.title,
            });
         });
      };

      // SAVE PUBLIC SETTINGS RECORDED
      activityRecordedArr.forEach(rc => {
         const newRowsAddedByPreviousUserButParentDeletedByCurrentUser = rowsFromDB.filter(e => {
            return e._parentRow === rc.id &&
               rc.action === 'Delete Drawing Type' &&
               !rowDeletedFinal.find(x => x.id === e.id);
         });
         rowDeletedFinal = [...rowDeletedFinal, ...newRowsAddedByPreviousUserButParentDeletedByCurrentUser];
      });

      rowDeletedFinal.forEach(r => {
         activityRecordedArr.push({
            id: r.id, email, createdAt: new Date(), action: 'Delete Drawing',
            [headerKeyDrawingNumber]: r['Drawing Number'],
            [headerKeyDrawingName]: r['Drawing Name'],
         });
      });


      rowsFromDB = rowsFromDB.filter(r => !rowDeletedFinal.find(x => x.id === r.id));

      // DELETE ...
      if (rowDeletedFinal.length > 0) {
         await apiDeleteRows({ token, projectId, email, rowIdsArray: rowDeletedFinal.map(r => r.id) });
      };


      treeDBModifiedToSave.forEach(tr => {
         headers.forEach(hd => {
            if (hd.text in tr) {
               tr[hd.key] = tr[hd.text];
               delete tr[hd.text];
            };
         });
      });


      let publicSettingsUpdated = { projectName };
      if (needToSaveTree) {
         publicSettingsUpdated = { ...publicSettingsUpdated, drawingTypeTree: treeDBModifiedToSave };
      };
      if (activityRecordedArr.length > 0) {
         publicSettingsUpdated = { ...publicSettingsUpdated, activityRecorded: [...activityRecordedFromDB, ...activityRecordedArr] };
      };


      await apiUpdateSettingPublic({ token, projectId, email, publicSettings: publicSettingsUpdated });

      const userSettingsUpdated = {
         headersShown: headersShown.map(hd => headers.find(h => h.text === hd).key),
         headersHidden: headersHidden.map(hd => headers.find(h => h.text === hd).key),
         // nosColumnFixed, colorization, role, viewTemplateNodeId, viewTemplates, modeFilter, modeSort
         nosColumnFixed, colorization, role, modeFilter, modeSort
      };

      await apiUpdateSettingUser({ token, projectId, email, userSettings: userSettingsUpdated });


      // FILTER FINAL ROW TO UPDATE......
      let rowsToUpdateFinal = [];
      rowsFromDB.map(row => {

         Object.keys(cellsModifiedTemp).forEach(key => {
            const { rowId, headerName } = extractCellInfo(key);
            if (rowId === row.id) row[headerName] = cellsModifiedTemp[key];
         });

         let rowOutput;
         const found = rowsFromDBInit.find(r => r.id === row.id);
         if (found) {
            let toUpdate = false;
            Object.keys(row).forEach(key => {
               if (found[key] !== row[key]) toUpdate = true;
            });
            if (toUpdate) rowOutput = { ...row };
         } else {
            rowOutput = { ...row };
         };

         if (rowOutput) {
            let rowToSave = { _id: rowOutput.id, parentRow: rowOutput._parentRow, preRow: rowOutput._preRow };
            headers.forEach(hd => {
               if (rowOutput[hd.text] || rowOutput[hd.text] === '') {
                  rowToSave.data = { ...rowToSave.data || {}, [hd.key]: rowOutput[hd.text] };
               };
            });

            const rowFoundInRowsGetNewRev = rowsGetNewRev.find(x => x.id === rowToSave._id);
            if (rowFoundInRowsGetNewRev) {
               Object.keys(rowFoundInRowsGetNewRev).forEach(key => {
                  if (key.includes('submission-dt-drawingLinkIssueForConstruction-')) {
                     rowToSave.data = { ...(rowToSave.data || {}), [key]: [] };
                  } else if (key.includes('reply-') || key.includes('submission-')) { // submission- contains dt also
                     rowToSave.data = { ...(rowToSave.data || {}), [key]: '' };
                  };
               });
            };
            rowsToUpdateFinal.push(rowToSave);
         };
      });


      if (rowsToUpdateFinal.length > 0) {
         await apiUpdateOrCreateRows({ token, projectId, rows: rowsToUpdateFinal });
      };
      commandAction({ type: 'save-data-successfully' });

   } catch (err) {
      getSheetRows({ loading: false });
      commandAction({ type: 'save-data-failure' });
      console.log(err);
   };
};






