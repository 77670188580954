import React, { useState, useEffect } from "react";
import { Table, Button, Icon, Checkbox } from "antd";

import { Func_Export_QM_Unit } from "../../function/func_export_to_excel";
import DialogProductivityUnitDetail from "../../../module_BimApp/dialog/DialogProductivityUnitDetail";
import _ from 'lodash'
import { format } from 'date-fns';

const TableGroupByUnit = ({ data, rangeSelected }) => {
   const [temp_data, setTempData] = useState();
   const [checkHighFail, setCheckHighFail] = useState(true);
   const [highFailData, setHighFailData] = useState();
   const [show, setShow] = useState(false);
   const [productivityData, setProductivityData] = useState({})

   useEffect(() => {
      {
         let temp_d = [];
         if (data !== undefined) {
            if (rangeSelected == "All") {
               temp_d = data;
            }

            if (rangeSelected == "<0.79") {
               temp_d = data.filter((e) => e.msQR !== "NA" && e.msQR < 0.8);
            }
            if (rangeSelected == "0.80-0.89") {
               temp_d = data.filter((e) => e.msQR != "NA" && e.msQR >= 0.8 && e.msQR < 0.9);
            }

            if (rangeSelected == "0.90-0.99") {
               temp_d = data.filter((e) => e.msQR != "NA" && e.msQR >= 0.9 && e.msQR < 1);
            }

            if (rangeSelected == ">1") {
               temp_d = data.filter((e) => e.msQR != "NA" && e.msQR >= 1);
            }

            setTempData(temp_d);
            const temp_high_data = temp_d.filter((e) => e.msQR >= 0.8);
            setHighFailData(temp_high_data);
         }
      }
   }, [data, rangeSelected]);

   const columns = [
      {
         title: "Unit",
         dataIndex: "unit",
         key: "unit",
         align: "left",
         width: 100,
      },
      {
         title: "QM (hrs)",
         dataIndex: "qmwork",
         key: "qmwork",
         align: "right",
         width: 90,
      },
      {
         title: "Recon (hrs)",
         dataIndex: "reconwork",
         key: "reconwork",
         align: "right",
         width: 90,
      },
      {
         title: "Backcharge (hrs)",
         dataIndex: "bcwork",
         key: "bcwork",
         align: "right",
         width: 100,
      },
      {
         title: "Other (hrs)",
         dataIndex: "otherwork",
         key: "otherwork",
         align: "right",
         width: 90,
      },
      {
         title: "Total Hours",
         dataIndex: "total",
         key: "total",
         align: "right",
         width: 90,
      },
      {
         title: "No of sample",
         dataIndex: "noOfSample",
         key: "noOfSample",
         align: "right",
         width: 125,
      },
      {
         title: "Man-day /Sample",
         dataIndex: "ms",
         key: "ms",
         align: "right",
         // width: 125,
      },
      {
         title: "M/S (QM+Recon)",
         dataIndex: "msQR",
         key: "msQR",
         align: "right",
      },
   ];
   const detailUnit = (e) => {
      _.map(e.listUnitDetail, f => f.date = format(new Date(f.date), 'dd MMM yyyy'))
      setProductivityData({
         total: e.total,
         unit: e.unitName,
         block: e.blockName,
         listUnitDetail: e.listUnitDetail
      })
      setShow(true)
   }
   const closeUnit = (e) => {
      setShow(false);
   }
   return (
      <div style={{ minHeight: "500px" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Unit
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_QM_Unit(data)}
            >
               <Icon type="download" />
            </Button>
            <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Productivity {">"} 0.8
            </Checkbox>
         </h5>

         <div style={{ height: "450px", width: "100%" }}>
            <Table
               dataSource={checkHighFail ? temp_data : highFailData}
               columns={columns}
               // size="small"
               // scroll={{ y: "37.5vh" }}

               // pagination={false}
               rowClassName={(record, index) => (record.ms > 0.8 ? "antd_table_row_red" : null)}
               rowKey="Unit"
               onRowClick={e => detailUnit(e)}
            />
         </div>
         <DialogProductivityUnitDetail show={show} close={closeUnit} data={productivityData} />
      </div>
   );
};

export default TableGroupByUnit;
