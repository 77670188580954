import { Checkbox, Icon, Input, message, Select, Tooltip, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiFetchPublicSettingThisProject } from '../../../api';
import { arrayStatusDrawingText, arrayTextStatusQrQrm, colorTextRow, colorType, disciplineType } from '../../../constants';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';


const arrayTypeStatus = ['rfa', 'rfam', 'qr', 'qrm'];
const arrayStatusAllObj = {
   'rfa': arrayStatusDrawingText.filter(stt => stt !== 'Consultant reviewing'),
   'rfam': arrayStatusDrawingText.filter(stt => stt !== 'Consultant reviewing'),
   'qr': arrayTextStatusQrQrm,
   'qrm': arrayTextStatusQrQrm,
};


const { Option } = Select;

const PanelProjectSettings = ({ stateProject, onClickCancelModal, onClickApply }) => {

   const { allDataOneSheet: { projectId, token, companies } } = stateProject;

   const [isLoading, setIsLoading] = useState(false);
   const [currentArrayAuthorities, setCurrentArrayAuthorities] = useState([]);
   const [currentEmailList, setCurrentEmailList] = useState([]);
   const [disArrayForm, setDisArrayForm] = useState([]);
   const [isCheckedOverdueLead, setIsCheckedOverdueLead] = useState();
   const [isReviewStatusShown, setIsReviewStatusShown] = useState();

   const [companyReviewStatus, setCompanyReviewStatus] = useState();


   useEffect(() => {
      const fetchData = async () => {
         try {
            setIsLoading(true);

            const res = await apiFetchPublicSettingThisProject({ token, projectId });
            const publicSettings = res.data[0];
            setDisArrayForm((publicSettings.disciplineForm || []).sort());
            setCurrentArrayAuthorities(publicSettings.authoritiesArray || []);
            setCurrentEmailList(publicSettings.emailListDefaultCc || []);
            setIsCheckedOverdueLead(publicSettings.overdueLeadConsultantOnly);

            setCompanyReviewStatus(publicSettings.companyReviewStatus || {
               'Default Review Status': getInitStatusObjAllType({ arrayTypeStatus, arrayStatusAllObj })
            });

            setIsLoading(false);
         } catch (err) {
            console.log(err);
         };
      };
      fetchData();
   }, []);


   const applyReviewStatus = (reviewStatusValue) => {
      if (reviewStatusValue) {
         const { company, reviewStatus } = reviewStatusValue;

         // check if new status differs from default status
         let isToSaveNewStatusChange = false;
         for (const type in reviewStatus) {
            const arrayStatusThisType = reviewStatus[type];
            for (const status in arrayStatusThisType) {
               if (status !== arrayStatusThisType[status].trim()) {
                  isToSaveNewStatusChange = true;
               };
            };
         };

         if (isToSaveNewStatusChange) {
            const newCompanyReviewStatus = { ...companyReviewStatus };
            newCompanyReviewStatus[company] = reviewStatus;
            setCompanyReviewStatus(newCompanyReviewStatus);
         } else {
            message.info('There is no new review status created!');
         };
      };
      setIsReviewStatusShown(false);
   };



   return !isLoading ? (
      <div style={{ padding: 10, color: 'black' }}>

         <div style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'space-between'
         }}>
            <div>
               <ProjectSettings
                  isCheckedOverdueLead={isCheckedOverdueLead}
                  setIsCheckedOverdueLead={setIsCheckedOverdueLead}
               />
               <PanelReviewStatusSettings
                  addOrEditReviewStatus={(company) => setIsReviewStatusShown(company)}
                  removeReviewStatus={(company) => {
                     const companyReviewStatusUpdated = { ...companyReviewStatus };
                     delete companyReviewStatusUpdated[company];
                     setCompanyReviewStatus(companyReviewStatusUpdated);
                  }}
                  companyReviewStatus={companyReviewStatus}
               />
               <PanelEmailListToCc 
                  currentEmailList={currentEmailList}
                  setCurrentEmailList={setCurrentEmailList}
               />
            </div>


            <PanelAddAuthorities
               setCurrentArrayAuthorities={setCurrentArrayAuthorities}
               currentArrayAuthorities={currentArrayAuthorities}
            />

            <PanelAddFormDiscipline
               setDisArrayForm={setDisArrayForm}
               disArrayForm={disArrayForm}
            />
         </div>

         {isReviewStatusShown && (
            <ModalReviewStatusStyled
               title={isReviewStatusShown === 'new-review-status' ? 'Create New Status Group' : 'Edit Status Value'} visible={isReviewStatusShown ? true : false}
               footer={null} destroyOnClose={true} centered={true}
               width={window.innerWidth * 0.8}
            >
               <PanelCompanyReviewStatusAddOrEdit
                  companies={companies}
                  applyReviewStatus={applyReviewStatus}
                  isReviewStatusShown={isReviewStatusShown}
                  companyReviewStatus={companyReviewStatus}
               />
            </ModalReviewStatusStyled>
         )}


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelModal}
               onClickApply={() => onClickApply({
                  authoritiesArray: currentArrayAuthorities.sort(),
                  disciplineForm: disArrayForm,
                  overdueLeadConsultantOnly: isCheckedOverdueLead,
                  companyReviewStatus,
                  emailListDefaultCc: currentEmailList
               })}
               newTextBtnApply={'Save'}
            />
         </div>

      </div>

   ) : (
      <div style={{ height: 124, display: 'flex', justifyContent: 'center', paddingTop: 35 }}>
         <Icon type='loading' style={{ fontSize: 30 }} />
      </div>
   );
};

export default PanelProjectSettings;











const ProjectSettings = ({ isCheckedOverdueLead, setIsCheckedOverdueLead }) => {

   const onChangeBox = () => {
      setIsCheckedOverdueLead(!isCheckedOverdueLead);
   };

   return (
      <div style={{ width: 250 }}>
         <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Overdue Mode</div>
         <CheckboxStyled
            onChange={onChangeBox}
            checked={isCheckedOverdueLead || false}
         >
            Overdue For Lead Consultant Only
         </CheckboxStyled>
      </div>
   );
};


const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      border: none;
      background: ${colorType.primary}
   }
`;


const PanelAddAuthorities = ({ currentArrayAuthorities, setCurrentArrayAuthorities }) => {


   const [authorityName, setAuthorityName] = useState('');


   const removeThisAuthority = (item) => {
      if (currentArrayAuthorities.indexOf(item) !== -1) {
         setCurrentArrayAuthorities(currentArrayAuthorities.filter(x => x !== item));
      };
   };

   const addThisAuthority = () => {
      if (!authorityName) {
         return message.warn('Please key-in authority name!');
      };

      const findSameName = currentArrayAuthorities.find(x => {
         let nameToCreate = authorityName.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '');
         let nameX = x.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/\s/g, '');
         return nameToCreate === nameX;
      });

      if (findSameName) {
         return message.warn('This authority name has already existed, please choose a new one!');
      };
      const newNameToSave = authorityName.replace(/[^a-zA-Z ]/g, '').toUpperCase();
      setCurrentArrayAuthorities([newNameToSave, ...currentArrayAuthorities]);
      setAuthorityName('');
   };

   return (
      <div>
         <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Project Authority</div>
         <div style={{ display: 'flex', marginBottom: 15 }}>

            <InputStyled
               style={{ width: 100, marginRight: 10 }}
               onChange={(e) => setAuthorityName(e.target.value)}
               value={authorityName}
            />
            <ButtonStyle
               marginRight={5}
               name='Add Authority'
               onClick={addThisAuthority}
            />

         </div>

         <div style={{
            width: '100%', marginBottom: 15,
            overflowY: currentArrayAuthorities && 'auto', whiteSpace: 'pre-wrap', height: window.innerHeight * 0.5,
         }}>
            {currentArrayAuthorities ? (
               <>
                  {currentArrayAuthorities.map(item => (
                     <div key={item} style={{ display: 'flex', marginBottom: 6 }}>

                        <Tooltip title='Remove This Authority'>
                           <IconStyled>
                              <Icon
                                 type='delete'
                                 style={{ transform: 'translate(0, -6px)', color: colorType.grey2, fontSize: 12 }}
                                 onClick={() => removeThisAuthority(item)}
                              />
                           </IconStyled>
                        </Tooltip>
                        <div style={{ marginLeft: 8 }}>{item}</div>
                     </div>
                  ))}
               </>
            ) : (
               <div style={{ height: window.innerHeight * 0.5 }}></div>
            )}

         </div>
      </div>
   );
};


const PanelAddFormDiscipline = ({ setDisArrayForm, disArrayForm }) => {

   const [disciplinePicked, setDisciplinePicked] = useState();
   const [suffixDiscipline, setSuffixDiscipline] = useState('');

   const addThisDiscipline = () => {
      if (!disciplinePicked || (!suffixDiscipline && disciplinePicked.toUpperCase !== 'ERI' && disciplinePicked.toUpperCase !== 'LR' && disciplinePicked.toUpperCase !== 'SM')) {
         return message.warn('Please key-in discipline and sub-discipline!');
      };

      let suffix = suffixDiscipline.replace(/ /g, '');

      const suffixParts = suffix.split('/');
      let isCorrectFormat = true;
      suffixParts.forEach(part => {
         const foundDuplicated = disciplineType.find(dis => dis.key.toLowerCase() === part.toLowerCase());
         if (foundDuplicated) {
            isCorrectFormat = false;
         };
      });
      if (!isCorrectFormat) {
         return message.warn('Sub-discipline can not be named as discipline!');
      };

      const allParts = suffix.toUpperCase().split('/').filter(x => x);

      let outputSuffixDiscipline = ''
      allParts.forEach((part, i) => {
         outputSuffixDiscipline += (i === 0 ? `${part}` : `/${part}`);
      });
      const newDisciplineToAdd = null;
      if(disciplinePicked.toUpperCase === 'ERI' || disciplinePicked.toUpperCase === 'LR' || disciplinePicked.toUpperCase === 'SM')
      {
         newDisciplineToAdd = disciplinePicked
      }else 
      {
         newDisciplineToAdd  = disciplinePicked + '/' + outputSuffixDiscipline;
      }
     
      if (disArrayForm.indexOf(newDisciplineToAdd) !== -1) {
         return message.warn('This discipline has been already existed, please choose a new one!');
      };

      setDisArrayForm([newDisciplineToAdd, ...disArrayForm]);
      setSuffixDiscipline('');
   };


   const removeThisDiscipline = (item) => {
      if (disArrayForm.indexOf(item) !== -1) {
         setDisArrayForm(disArrayForm.filter(x => x !== item));
      };
   };

   return (
      <div style={{ color: 'black' }}>
         <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Form Discipline</div>
         <div style={{ display: 'flex', marginBottom: 15 }}>
            <SelectStyled
               defaultValue='Select Discipline...'
               value={disciplinePicked}
               style={{ marginRight: 5, width: 80 }}
               onChange={(value) => setDisciplinePicked(value)}
            >
               {disciplineType.map(item => (
                  <Option key={item.key} value={item.key}>{item.key}</Option>
               ))}
            </SelectStyled>
            <div style={{ marginRight: 5, paddingTop: 6 }}>/</div>
            <InputStyled
               style={{ width: 130, marginRight: 10 }}
               onChange={(e) => setSuffixDiscipline(e.target.value)}
               value={suffixDiscipline}
            />
            <ButtonStyle
               marginRight={5}
               name='Add Discipline'
               onClick={addThisDiscipline}
            />
         </div>

         <div style={{
            width: '100%', marginBottom: 15,
            overflowY: disArrayForm && 'auto', whiteSpace: 'pre-wrap', height: window.innerHeight * 0.5,
         }}>
            {disArrayForm.map(item => (
               <div key={item} style={{ display: 'flex', marginBottom: 6 }}>

                  <Tooltip title='Remove This Discipline'>
                     <IconStyled>
                        <Icon
                           type='delete'
                           style={{ transform: 'translate(0, -6px)', color: colorType.grey2, fontSize: 12 }}
                           onClick={() => removeThisDiscipline(item)}
                        />
                     </IconStyled>
                  </Tooltip>
                  <div style={{ marginLeft: 8 }}>{item}</div>
               </div>
            ))}
         </div>
      </div>
   );
};

const PanelReviewStatusSettings = ({ addOrEditReviewStatus, removeReviewStatus, companyReviewStatus }) => {

   return (
      <div style={{ color: 'black', marginTop: 50 }}>

         <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Review Status Settings</div>

         {companyReviewStatus && Object.keys(companyReviewStatus).map(cmp => {
            return (
               <div key={cmp} style={{
                  display: 'flex', width: '100%', justifyContent: 'space-between',
                  background: colorType.grey4, padding: 8, marginBottom: 10
               }}>
                  <div style={{  }}>{cmp}</div>
                  <div style={{ display: 'flex', transform: 'translateY(2px)' }}>

                     {cmp !== 'Default Review Status' && (
                        <Tooltip title='Delete Review Status'>
                           <Icon
                              type={'close'}
                              style={{ fontSize: 16 }}
                              onClick={() => removeReviewStatus(cmp)}
                           />
                        </Tooltip>
                     )}

                     <Tooltip title='Edit Review Status'>
                        <Icon
                           type={'edit'}
                           style={{ fontSize: 16, marginLeft: 5 }}
                           onClick={() => addOrEditReviewStatus(cmp)}
                        />
                     </Tooltip>

                  </div>
               </div>
            );
         })}

         <div style={{ marginTop: 15 }}>
            <ButtonStyle
               width={'100%'}
               name='Create New Group'
               onClick={() => addOrEditReviewStatus('new-review-status')}
               background={colorType.primary}
               colorText={'white'}
            />
         </div>


      </div>
   );
};

const PanelEmailListToCc = ({ currentEmailList, setCurrentEmailList }) => {

   console.log(currentEmailList)
   const [emailText, setEmailText] = useState('');

   const removeThisEmail = (item) => {
      if (currentEmailList.indexOf(item) !== -1) {
         setCurrentEmailList(currentEmailList.filter(x => x !== item));
      };
   };

   const addThisEmail = () => {
      if (!emailText) {
         return message.warn('Please key-in email to cc!');
      };
      setCurrentEmailList([...new Set([emailText.trim(), ...currentEmailList])]);
      setEmailText('');
   };

   return (
      <div style={{ marginTop: 55 }}>
         <div style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>List Email Cc</div>
         <div style={{ display: 'flex', marginBottom: 15 }}>

            <InputStyled
               style={{ width: 100, marginRight: 10 }}
               onChange={(e) => setEmailText(e.target.value)}
               value={emailText}
            />
            <ButtonStyle
               marginRight={5}
               name='Add Email'
               onClick={addThisEmail}
            />

         </div>

         <div style={{
            width: '100%', marginBottom: 15,
            overflowY: currentEmailList && 'auto', whiteSpace: 'pre-wrap', height: window.innerHeight * 0.5,
         }}>
            {currentEmailList ? (
               <>
                  {currentEmailList.map(item => (
                     <div key={item} style={{ display: 'flex', marginBottom: 6 }}>

                        <Tooltip title='Remove This Email'>
                           <IconStyled>
                              <Icon
                                 type='delete'
                                 style={{ transform: 'translate(0, -6px)', color: colorType.grey2, fontSize: 12 }}
                                 onClick={() => removeThisEmail(item)}
                              />
                           </IconStyled>
                        </Tooltip>
                        <div style={{ marginLeft: 8 }}>{item}</div>
                     </div>
                  ))}
               </>
            ) : (
               <div style={{ height: window.innerHeight * 0.5 }}></div>
            )}

         </div>
      </div>
   );
};


const getInitStatusObjAllType = ({ arrayTypeStatus, arrayStatusAllObj }) => {
   const objStatusInit = {};
   arrayTypeStatus.forEach(type => {
      const statusThisType = arrayStatusAllObj[type];
      const obj = {};
      statusThisType.forEach(stt => {
         obj[stt] = stt;
      });
      objStatusInit[type] = obj;
   });
   return objStatusInit;
};


const PanelCompanyReviewStatusAddOrEdit = ({ companies, applyReviewStatus, isReviewStatusShown, companyReviewStatus }) => {

   const borderColorTable = `1px solid ${colorType.grey3}`;

   const [statusObj, setStatusObj] = useState();
   const [companySelected, setCompanySelected] = useState();

   useEffect(() => {
      if (
         (isReviewStatusShown === 'new-review-status') ||
         (isReviewStatusShown === 'Default Review Status' && !companyReviewStatus[isReviewStatusShown])
      ) {
         const objStatusInit = getInitStatusObjAllType({ arrayTypeStatus, arrayStatusAllObj });
         setStatusObj(objStatusInit);

      } else if (
         (isReviewStatusShown && isReviewStatusShown !== 'new-review-status' && isReviewStatusShown !== 'Default Review Status') ||
         (isReviewStatusShown === 'Default Review Status' && companyReviewStatus[isReviewStatusShown])
      ) {
         setStatusObj(companyReviewStatus[isReviewStatusShown]);
      };
   }, []);

   const companiesArrayName = companies.filter(x => x['companyType'] === 'Consultant').map(x => x['company']);


   return (
      <div style={{ padding: 25, color: 'black' }}>

         {isReviewStatusShown === 'new-review-status' && <div style={{ display: 'flex', marginBottom: 15 }}>
            <div style={{ marginRight: 15, fontSize: 16, fontWeight: 'bold' }}>Company Name</div>
            <SelectStyled
               defaultValue='Select Company...'
               value={companySelected}
               style={{ marginRight: 5, width: 250 }}
               onChange={(cmp) => setCompanySelected(cmp)}
            >
               {companiesArrayName.map(item => (
                  <Option key={item} value={item}>{item}</Option>
               ))}
            </SelectStyled>
         </div>}


         {statusObj && arrayTypeStatus.map(type => {
            return (
               <div key={type} style={{ marginBottom: 23, width: '100%' }}>

                  <div style={{ fontSize: 18, fontWeight: 'bold' }}>{type.toUpperCase()}</div>
                  <table style={{ marginTop: 0, width: '97%' }}>
                     <thead>
                        <tr style={{ border: borderColorTable, background: colorType.grey4 }}>
                           <th style={{ borderRight: borderColorTable, fontSize: 14, padding: 6, width: 380 }}>Original Status</th>
                           <th style={{ borderRight: borderColorTable, fontSize: 14, padding: 6 }}>New Status</th>
                        </tr>
                     </thead>
                     <tbody>
                        {arrayStatusAllObj[type].map((statusText, i) => {
                           return (
                              <tr key={i} style={{ border: borderColorTable }}>
                                 <td style={{ borderRight: borderColorTable, fontSize: 14, padding: 6, background: colorTextRow[statusText] }}>{statusText}</td>
                                 <td style={{ borderRight: borderColorTable }}>
                                    <InputReviewStatusStyled
                                       style={{ fontSize: 14, padding: 6 }}
                                       onChange={(e) => {
                                          const newStatusObj = { ...statusObj };
                                          newStatusObj[type][statusText] = e.target.value;
                                          setStatusObj(newStatusObj);
                                       }}
                                       value={statusObj[type][statusText]}
                                    />
                                 </td>
                              </tr>
                           );
                        })}
                     </tbody>
                  </table>
               </div>
            );
         })}


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
            <ButtonGroupComp
               onClickCancel={() => applyReviewStatus(null)}
               onClickApply={() => {
                  if (!companySelected && isReviewStatusShown === 'new-review-status') {
                     message.warn('Please select company to add review status!');
                  } else {
                     let isAbleToCreateNew = true;
                     Object.keys(statusObj).forEach(type => {
                        const statusObjThisType = statusObj[type];
                        const arrayStatus = [];
                        for (const stt in statusObjThisType) {
                           arrayStatus.push(statusObjThisType[stt].trim());
                        };
                        const arrayStatusRemoveDuplicated = [...new Set(arrayStatus)];
                        if (arrayStatusRemoveDuplicated.length < arrayStatus.length) {
                           isAbleToCreateNew = false;
                        };
                     });

                     if (isAbleToCreateNew) {
                        applyReviewStatus({
                           company: companySelected || isReviewStatusShown,
                           reviewStatus: statusObj
                        });
                     } else {
                        message.warn('Can not create same status value!');
                     };
                  };
               }}
               newTextBtnApply={isReviewStatusShown === 'new-review-status' ? 'Add Review Status' : 'Update Status Value'}
            />
         </div>

      </div>
   );
};

const ModalReviewStatusStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   };
   .ant-modal-close {
      display: none;
   };
   .ant-modal-header {
      padding: 10px;
   };
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   };
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   };
`;

const InputStyled = styled(Input)`
   color: black;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;
const InputReviewStatusStyled = styled(Input)`
   color: black;
   border-radius: 0;
   border: none;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;

const IconStyled = styled.div`
   margin-left: 7px;
   width: 18px; 
   height: 18px; 
   border: 1px solid ${colorType.grey1};
   text-align: center;
   &:hover {
      background-color: ${colorType.grey4};
      cursor: pointer;
   };
`;

const SelectStyled = styled(Select)`
   .ant-select-selection {
      border-radius: 0;
   };
`;

