import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { HotTable } from "@handsontable/react";
import { message } from "antd";
import Handsontable from "handsontable";
import _ from "lodash";
import { func_process_conc_do_data } from "../../function/func_process_data";
var customParseFormat = require("dayjs/plugin/customParseFormat");

const TableConcDO = ({
   tableRef,
   data,
   filter_data,
   filterMode,
   generalState,
   forceReRender,
   setData,
   setFilterData,
   setStatistic,
}) => {
   const [user_role, set_user_role] = useState();
   const projectId = useSelector((state) => state.project.projectId);
   const [project_id, set_project_id] = useState(projectId);

   useEffect(() => {
      const role = localStorage.getItem("fp_user_role");
      set_user_role(role);
      // set_project_id(projectId);
      //make sure working on concrete DO form
      const fetchdata = async () => {
         var res = await axios.get("/api/do_inv/common/get_data_by_date_range", {
            params: {
               currentFilterMode: filterMode,
               project_id: project_id,
               current: "conc_do",
            },
         });
         const temp_d = res.data.data.items;
         const res_f = await func_process_conc_do_data(temp_d);
         setData(res_f.processed_data);
         setStatistic(res_f.staticInfo);
      };
      fetchdata();
   }, [filterMode, generalState, forceReRender]);

   const columns = [
      {
         title: "Do No",
         data: "do_number",
         type: "text",
      },
      {
         title: "Delivery Date",
         data: "do_date",
         type: "date",
         dateFormat: "DD-MM-YYYY",
         datePickerConfig: {
            dateFormat: "DD-MM-YYYY",
         },
      },
      {
         title: "Volume",
         data: "volume",
         type: "numeric",
         strict: true,
         allowInvalid: false,
         numericFormat: {
            pattern: "0,0.0",
         },
      },
      {
         title: "Grade",
         data: "grade",
         type: "text",
      },

      {
         title: "Product",
         data: "product",
         type: "text",
         wordWrap: false,
      },

      {
         title: "Supplier",
         data: "supplier",
         type: "dropdown",
         source: ["ALLIANCE", "PAN U", "ISLAND"],
      },

      {
         title: "Use for",
         data: "use_for",
         type: "dropdown",
         source: ["Permanent work", "Temporary work", "Back charge"],
      },

      {
         title: "Pour Name",
         data: "pour_name",
         type: "text",
      },

      {
         title: "Order by",
         data: "order_by",
         type: "text",
      },

      {
         title: "Pdf file",
         data: "s3_key_check",
         renderer: "html",
         readOnly: true,
      },
      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 815,
      columns: columns,
      colWidths: [100, 100, 90, 90, 240, 100, 125, 125, 125, 90, 150],
      stretchH: "last",
      currentRowClassName: "currentRow",
      dropdownMenu: [
         "filter_by_condition",
         "filter_operators",
         "filter_by_condition2",
         "filter_by_value",
         "filter_action_bar",
      ],
      filters: true,
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      const parentElement = TD.parentElement;

      if (parentElement === null) {
         return;
      }
      // Add class to odd TRs
      if (row % 2 === 0) {
         Handsontable.dom.addClass(parentElement, "odd");
      }
   };

   const handleOnCellMouseUp = (evens, coors, TD) => {
      if (tableRef.current !== null && coors.col === 9 && TD.innerText !== "" && TD.innerText !== null) {
         const temp_data = tableRef.current.hotInstance.getData();
         const file_name_seach = project_id + "_conc_do_" + temp_data[coors.row][0] + ".pdf";
         const key = `${project_id}/${"conc_do"}/${file_name_seach}`;
         // const file_name_seach = "SP3_conc_do_AQ39053726.pdf";

         axios
            .get("/api/do_inv/s3/getlink", {
               params: { file_name: key.toLowerCase() },
            })
            .then((res) => {
               const signedrequesturl = res.data.signedRequest;
               window.open(signedrequesturl);
            });
      }
   };

   const onAfterHotChange = (changes) => {
      if (changes === null) {
         return;
      }

      if (tableRef.current !== null) {
         const hot = tableRef.current.hotInstance;
         changes.forEach(([row, column, oldValue, newValue]) => {
            // console.log(row);
            const r = hot.toPhysicalRow(row);
            data[r].change = true;
            data[r][column] = newValue;
         });
         setData(data);
      } else {
         message.warn("Sorry changed data will not able to save!");
      }
   };

   // const handleAfterFilter = () => {
   //    if (tableRef.current !== null) {
   //       const hot = tableRef.current.hotInstance;
   //       if (hot !== undefined) {
   //          const data_filter = hot.getData();
   //          setFilterData(data_filter);
   //          // console.log(data_filter.length);
   //       }
   //    }
   // };

   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={data}
            // afterRenderer={addClassesToRows}
            afterOnCellMouseUp={handleOnCellMouseUp}
            afterChange={onAfterHotChange}
            // afterFilter={handleAfterFilter}
            className="do-invoice-table handsontable_do"
         />
      </div>
   );
};

export default TableConcDO;
