import React, { useState, useEffect, useRef } from 'react'
import PDFViewer from './PDFViewer'
import sample from './sample.pdf'
import { saveAs } from 'file-saver';
export default function TestPDFViewer() {
    const ref = useRef()
    const handleSave = (data) => {
        saveAs(new Blob([data]), 'download.pdf')
    }
    const exportMarkUp = (data) => {
        saveAs(new Blob([data]), 'markup.json')
    }
    const onLoaded =()=>{
        console.log('done')
    }
    useEffect(() => {
        if (ref.current) {
            console.log(ref.current)
        }
    }, [ref.current])
    return (
        <div style={{ width: '100%', height: 'calc(100% - 55.78px)', position: 'absolute' }}>
            <PDFViewer
                ref={ref}
                url={sample}//'https://pdf-lib.js.org/assets/with_update_sections.pdf'
                save={handleSave}
                exportMarkUp={exportMarkUp}
                onLoaded={onLoaded}
            />
        </div>

    )
}