import { Icon, message, Modal, Tooltip, Upload, Checkbox } from 'antd';
import Axios from 'axios';
import base64url from 'base64url';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiClientGetKeyUpload, apiClientUploadFile, apiFetchDataHistoryRowsById, apiFetchDataMultiByDisciplineForm, apiFetchDataMultiForm, apiFetchOneDataMultiForm, apiFetchPublicSettingThisProject, apiFetchPublicUrl, apiSendEmail, apiSendEmailDevTest, apiUpdateOrCreateRowsMultiForm, apiUploadFiles } from '../../../api';
import { colorTextRow, colorType, EDIT_DURATION_MIN, versionTextArray } from '../../../constants';
import { checkFormIfRepliedOrNot, compareDatesForm, convertCompanyNameFolderS3, findSubmissionCompanyInRow, getRefStringWithVersion, removeSecondPart, replaceBreakLine } from '../../../utils';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';
import PanelLinkOldDtToDms from '../generalComponents/PanelLinkOldDtToDms';
import { convertReplyOrSubmissionDate, EmailContentBox, isColumnWithReplyData, ListDrawingsIssueForConstruction } from './CellRFA';
import { getFileNameFromLinkResponse, getKeyTextForSheet } from './PanelSetting';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';



const preLink = window.location.origin.includes('https://idd.wohhup.com') ? 'https://idd.wohhup.com/dms-viewer/'
   : window.location.origin.includes('https://test.bql-app.com') ? 'https://test.bql-app.com/dms-viewer/'
      : 'http://localhost:3000/dms-viewer/';


const CellForm = (props) => {


   const { rowData, column, buttonPanelFunction, stateRow, getSheetRows, stateProject, commandAction, setLoading, cellData } = props;


   const {
      token, projectId, projectName, roleTradeCompany, companies, email, projectIsAppliedRfaView,
      pageSheetTypeName, isBothSideActionUser, isSubconCanSubmit, publicSettings
   } = stateProject.allDataOneSheet;

   const { overdueLeadConsultantOnly } = publicSettings;

   const { company } = roleTradeCompany;

   let userSubconHasPermission = false;
   if (!rowData['treeLevel'] && isSubconCanSubmit && pageSheetTypeName === 'page-rfc') {
      const rfcRef = rowData['rfcRef'] || '';
      const rfcRefParts = rfcRef.split('/');
      const companyShortNameThisRef = rfcRefParts[3];
      const companyFoundThisUser = companies.find(x => x['company'] === company) || {};
      if (companyFoundThisUser['shortName'] === companyShortNameThisRef) {
         userSubconHasPermission = true;
      };
   };


   const expandedColumn = pageSheetTypeName === 'page-rfam' ? 'RFAM Ref'
      : pageSheetTypeName === 'page-rfi' ? 'RFI Ref'
         : pageSheetTypeName === 'page-cvi' ? 'CVI Ref'
            : pageSheetTypeName === 'page-dt' ? 'DT Ref'
               : pageSheetTypeName === 'page-mm' ? 'MM Ref'
                  : pageSheetTypeName === 'page-qr' ? 'QR Ref'
                     : pageSheetTypeName === 'page-qrm' ? 'QRM Ref'
                        : pageSheetTypeName === 'page-rfc' ? 'RFC Ref'
                           : 'n/a';

   const [activeBtn, setActiveBtn] = useState(null);

   const [modalContentText, setModalContentText] = useState(null);

   const [arrayButtonCell, setArrayButtonCell] = useState([]);
   const [arrayButtonCellSubmitAndReply, setArrayButtonCellSubmitAndReply] = useState([]);


   const [btnShown, setBtnShown] = useState(false);

   const { rowsFormAllInit, rowsFormAll } = stateRow;




   const refType = getKeyTextForSheet(pageSheetTypeName);
   const refKey = refType + 'Ref';


   const [replyStatus, setReplyStatus] = useState(null);
   const [replyCompany, setReplyCompany] = useState(null);
   const [replyDate, setReplyDate] = useState(null);

   const [modalListDrawingAttached, setModalListDrawingAttached] = useState(null);

   const [modalIssueForConstructionDrawings, setModalIssueForConstructionDrawings] = useState(false);

   const [overdueCount, setOverdueCount] = useState();


   const [panelUploadSignedOffFormShown, setPanelUploadSignedOffFormShown] = useState(false);
   const [fileSignedOffFormPdf, setFileSignedOffFormPdf] = useState(null);
   const onChangeUploadSignedOffForm = (info) => {
      if (info.fileList) {
         let output = {};
         info.fileList.forEach(file => {
            output = { [file.name]: file };
         });
         setFileSignedOffFormPdf(output);
      };
   };


   const [modalConfirmResendFailedEmail, setModalConfirmResendFailedEmail] = useState(false);
   const [modalEmailContent, setModalEmailContent] = useState(false);
   const [modalLinkOldDtToDms, setModalLinkOldDtToDms] = useState(null);
   const [modalSelectTypeToLinkOldDt, setModalSelectTypeToLinkOldDt] = useState(false);

   useEffect(() => {

      if (!rowData.treeLevel) {

         if (column.key === expandedColumn) {

            let cellButtonArr = ['btn-email-content'];
            let cellButtonArrSubmitAndReply = [];
            const consultantMustReplyArray = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'consultantMustReply');

            const isEditTimeLeadConsultantReplyIsOverMultiForm = checkIfEditTimeIsOverMultiForm(rowData, consultantMustReplyArray[0], EDIT_DURATION_MIN, refType, 'check-if-reply-edit-is-over');
            const isUserTheRefCreator = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'user') === email;
            const isSignedOffAlreadySubmitted = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkSignedOffFormSubmit');
            const isEditTimeSubmissionIsOverMultiForm = checkIfEditTimeIsOverMultiForm(rowData, null, EDIT_DURATION_MIN, refType, 'check-if-submission-edit-is-over');


            if (isBothSideActionUser || userSubconHasPermission) {
               if (
                  (getInfoValueFromRefDataForm(rowData, 'reply', refType, 'status', consultantMustReplyArray[0]) && isEditTimeLeadConsultantReplyIsOverMultiForm <= 0) ||
                  ((pageSheetTypeName === 'page-cvi' || pageSheetTypeName === 'page-dt') && isSignedOffAlreadySubmitted)
               ) {
                  const versionTextIndex = versionTextArray.indexOf(rowData.revision);
                  const versionTextNext = versionTextArray[versionTextIndex + 1];
                  const rowVersionNext = rowsFormAllInit.find(r => r[refKey] === rowData[refKey] && r.revision === versionTextNext);
                  if (!rowVersionNext) {
                     cellButtonArrSubmitAndReply = [...cellButtonArrSubmitAndReply, 'btn-resubmit'];
                  };
               };

               // if (isUserTheRefCreator) { // user who did not create form also can edit, just in case DC resign or change project
                  if (pageSheetTypeName !== 'page-mm' && pageSheetTypeName !== 'page-qr' && pageSheetTypeName !== 'page-qrm' && pageSheetTypeName !== 'page-rfc') {
                     if (isEditTimeSubmissionIsOverMultiForm <= 0) {
                        cellButtonArrSubmitAndReply = [...cellButtonArrSubmitAndReply, 'btn-submitSignedOffToConsultant'];
                     } else {
                        setEditTimeLeft(isEditTimeSubmissionIsOverMultiForm);
                     };
                  };

                  cellButtonArr = [...cellButtonArr, 'btn-edit'];


                  const emailFailToSend = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'emailFailToSend');
                  if (emailFailToSend) {
                     cellButtonArrSubmitAndReply = [...cellButtonArrSubmitAndReply, 'btn-resend-email'];
                  };
               // };

               if (pageSheetTypeName === 'page-dt' && isSignedOffAlreadySubmitted) {
                  cellButtonArr = [...cellButtonArr, 'btn-migrate-old-dt'];
               };
            };

            if (
               (getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkFormNoSignature') && isUserTheRefCreator) ||
               (getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkFormNoSignature') && pageSheetTypeName === 'page-mm') ||
               getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkSignedOffFormSubmit')
            ) {
               cellButtonArr = [...cellButtonArr, 'btn-linkSubmissionFiles'];
            };



            setArrayButtonCell([...new Set(cellButtonArr)]);
            setArrayButtonCellSubmitAndReply([...new Set(cellButtonArrSubmitAndReply)]);


         } else if (isColumnWithReplyData(column.key)) {

            let cellButtonArr = [];
            let cellButtonArrSubmitAndReply = [];
            const { replyStatus: replyStatusData, replyCompany: replyCompanyData, replyDate: replyDateData } = getConsultantReplyFormData(rowData, column.key, refType);
            setReplyStatus(replyStatusData);
            setReplyCompany(replyCompanyData);
            setReplyDate(convertReplyOrSubmissionDate(replyDateData));


            const isEditTimeReplyIsOverMultiForm = checkIfEditTimeIsOverMultiForm(rowData, replyCompanyData, EDIT_DURATION_MIN, refType, 'check-if-reply-edit-is-over');
            const isUserTheRefCreator = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'user', replyCompanyData) === email;
            const isSignedOffAlreadySubmitted = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkSignedOffFormSubmit');
            const isThisRefAlreadyReplied = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'status', replyCompanyData);
            const isRepliedFormUploaded = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'linkFormReply', replyCompanyData);



            if ((roleTradeCompany.role === 'Consultant' && replyCompanyData === company) || (isBothSideActionUser && replyCompanyData)) {
               if (
                  isSignedOffAlreadySubmitted &&
                  ((!isThisRefAlreadyReplied && (pageSheetTypeName === 'page-rfam' || pageSheetTypeName === 'page-rfi' || pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc')))
               ) {
                  cellButtonArrSubmitAndReply = [...cellButtonArrSubmitAndReply, 'btn-reply'];
               };

               if (
                  (
                     isRepliedFormUploaded ||
                     ((pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') && isThisRefAlreadyReplied)
                  ) && 
                  // isUserTheRefCreator &&
                  // (roleTradeCompany.role === 'Consultant' || isBothSideActionUser)

                  (
                     // (roleTradeCompany.role === 'Consultant' && isUserTheRefCreator) || // user who did not create form also can edit, just in case staff resign or change project
                     roleTradeCompany.role === 'Consultant' ||
                     isBothSideActionUser
                  )
               ) {
                  cellButtonArr = [...cellButtonArr, 'btn-edit'];
                  setEditTimeLeft(isEditTimeReplyIsOverMultiForm);


                  const emailFailToSend = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'emailFailToSend', replyCompanyData);
                  if (emailFailToSend) {
                     cellButtonArrSubmitAndReply = [...cellButtonArrSubmitAndReply, 'btn-resend-email'];
                  };
               };
            };


            if (
               (
                  isRepliedFormUploaded ||
                  ((pageSheetTypeName === 'page-qr' || pageSheetTypeName === 'page-qrm' || pageSheetTypeName === 'page-rfc') && isThisRefAlreadyReplied)
               ) && (isEditTimeReplyIsOverMultiForm <= 0 || isUserTheRefCreator)) { // !isEditTimeReplyIsOverMultiForm && isUserTheRefCreator 
               cellButtonArr = [...cellButtonArr, 'btn-linkReplyFiles'];
            };
            setArrayButtonCell([...new Set(cellButtonArr)]);
            setArrayButtonCellSubmitAndReply([...new Set(cellButtonArrSubmitAndReply)]);


         } else if (isTextContentCell(column.key)) {
            const textContentCell = getInfoValueFromRefDataForm(
               rowData, 'submission', refType,
               column.key === 'Description' ? 'description' :
                  column.key === 'Contract Specification' ? 'contractSpecification' :
                     column.key === 'Proposed Specification' ? 'proposedSpecification' :
                        (column.key === 'RFAM Info' || column.key === 'RFA Info') ? 'listForm' :
                           'conversationAmong'
            );
            if (textContentCell) {
               setArrayButtonCell(['btn-textContent']);
            };
         } else if (column.key === 'Due Date') {

            const isNotRepliedYet = checkFormIfRepliedOrNot({ refType, row: rowData, overdueLeadConsultantOnly });
            
            if (isNotRepliedYet) {
               const compare = compareDatesForm(getInfoValueFromRefDataForm(rowData, 'submission', refType, 'due'));
               setOverdueCount(compare);
            };

         } else if (column.key === 'Issue For Construction') {
            const issueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'issueForConstruction');
            if (issueForConstruction) {
               const drawingLinkIssueForConstruction = getInfoValueFromRefDataForm(rowData, 'submission', 'dt', 'drawingLinkIssueForConstruction') || [];
               if (drawingLinkIssueForConstruction.length > 0) {
                  setArrayButtonCell(['btn-issue-dt']);
               };
            };
         };
      };

   }, [activeBtn]);


   const [editTimeLeft, setEditTimeLeft] = useState(null);
   useEffect(() => {
      if (editTimeLeft > 0) {
         const timer = setTimeout(() => {
            if (column.key === expandedColumn) {
               if (pageSheetTypeName !== 'page-mm') {
                  setArrayButtonCellSubmitAndReply([...arrayButtonCellSubmitAndReply, 'btn-submitSignedOffToConsultant']);
               };
            };
         }, editTimeLeft * 60 * 1000);
         return () => clearTimeout(timer);
      };
   }, [editTimeLeft]);










   const onClickRefDrawing = (btn) => {
      const rowsNotThisRef = rowsFormAll.filter(r => r[refKey] !== rowData[refKey]);

      let rowsThisRefFiltered = rowsFormAllInit.filter(r => {
         return r.revision === btn && r[refKey] === rowData[refKey];
      });

      const rowsToDisplay = [...rowsNotThisRef, ...rowsThisRefFiltered].sort((a, b) => (a[refKey] > b[refKey] ? 1 : -1));

      setActiveBtn(btn);
      getSheetRows({ rowsFormAll: rowsToDisplay });
   };




   const openDrawingFromList = async (dwgLink, fileType) => {
      try {
         const extension = (/(?:\.([^.]+))?$/).exec(dwgLink)[1]; // extension pdf, dwfx, dwf

         let linkToOpen = '';
         if (
            pageSheetTypeName === 'page-dt' &&
            (fileType === 'file' || fileType === 'dwg-rfa') &&
            getInfoValueFromRefDataForm(rowData, 'submission', refType, 'herewithForDt') === 'Drawings'
         ) {
            linkToOpen = preLink + base64url(dwgLink);
         } else {
            const res = await apiFetchPublicUrl({ key: dwgLink, expire: 1000 });
            linkToOpen = res.data;
         };
         window.open(linkToOpen, '_blank');
      } catch (err) {
         console.log(err);
      };
   };



   const onClickCellButton = async (btnName, companyCellTagCviOrDt) => {
      try {
         if (btnName === 'btn-linkSubmissionFiles') {
            const linkFormSubmission = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkSignedOffFormSubmit') ||
               getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkFormNoSignature');

            const dwgsLinkList = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkDrawings') || [];
            const dwgsRfaLinkList = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkDrawingsRfa') || [];

            let linkRfamAttached = [];
            let linkRfiAttached = [];
            let linkCviAttached = [];

            if (refType === 'dt') {
               linkRfamAttached = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkRfamAttached') || [];
               linkRfiAttached = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkRfiAttached') || [];
               linkCviAttached = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkCviAttached') || [];
            };


            setModalListDrawingAttached([
               { link: linkFormSubmission, type: 'form' },
               ...[
                  ...dwgsLinkList.map(link => ({ link, type: 'file' })),
                  ...dwgsRfaLinkList.map(link => ({ link, type: 'dwg-rfa' })),
                  ...linkRfamAttached.map(link => ({ link, type: 'dwg-rfam' })),
                  ...linkRfiAttached.map(link => ({ link, type: 'dwg-rfi' })),
                  ...linkCviAttached.map(link => ({ link, type: 'dwg-cvi' })),
               ].sort((a, b) => (/[^/]*$/.exec(a.link)[0] > /[^/]*$/.exec(b.link)[0] ? 1 : -1))
            ]);

         } else if (btnName === 'btn-linkReplyFiles') {

            let linkFormReply = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'linkFormReply', companyCellTagCviOrDt || replyCompany);
            let linkDocumentsReply = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'linkDocumentsReply', companyCellTagCviOrDt || replyCompany) || [];

            setModalListDrawingAttached([
               { link: linkFormReply, type: 'form' },
               ...linkDocumentsReply
                  .map(link => ({ link, type: 'file' }))
                  .sort((a, b) => (/[^/]*$/.exec(a)[0] > /[^/]*$/.exec(b)[0] ? 1 : -1))
            ]);

         } else if (btnName === 'btn-submitSignedOffToConsultant') {
            setPanelUploadSignedOffFormShown(true);

         } else if (btnName === 'btn-issue-dt') {
            setModalIssueForConstructionDrawings(true);

         } else if (btnName === 'btn-edit') {

            let typeFormBtn = '';
            let objConsultantNameToReplyByBothSideActionUser = {};

            if (companyCellTagCviOrDt) {
               typeFormBtn = 'form-reply-multi-type';
               if (isBothSideActionUser) {
                  objConsultantNameToReplyByBothSideActionUser = {
                     consultantNameToReplyByBothSideActionUser: companyCellTagCviOrDt
                  };
               };
            } else {
               if (isColumnWithReplyData(column.key)) {
                  typeFormBtn = 'form-reply-multi-type';
                  if (isBothSideActionUser) {
                     objConsultantNameToReplyByBothSideActionUser = {
                        consultantNameToReplyByBothSideActionUser: replyCompany
                     };
                  };
               } else {
                  if (rowData.revision !== '0') {
                     typeFormBtn = 'form-resubmit-multi-type';
                  } else {
                     typeFormBtn = 'form-submit-multi-type';
                  };
               };
            };

            const dataSendNoEmail = (typeFormBtn === 'form-submit-multi-type' || typeFormBtn === 'form-resubmit-multi-type')
               ? getInfoValueFromRefDataForm(rowData, 'submission', refType, 'dateSendNoEmail')
               : (typeFormBtn === 'form-reply-multi-type' && !companyCellTagCviOrDt)
                  ? getInfoValueFromRefDataForm(rowData, 'reply', refType, 'dateSendNoEmail', replyCompany)
                  : (typeFormBtn === 'form-reply-multi-type' && companyCellTagCviOrDt)
                     ? getInfoValueFromRefDataForm(rowData, 'reply', refType, 'dateSendNoEmail', companyCellTagCviOrDt)
                     : null;

            buttonPanelFunction(typeFormBtn);
            getSheetRows({
               currentRefToAddNewOrReplyOrEdit: {
                  currentRefData: rowData,
                  formRefType: typeFormBtn,
                  isFormEditting: true,
                  withNoEmailSent: dataSendNoEmail ? true : false,
                  ...objConsultantNameToReplyByBothSideActionUser
               },
            });

         } else if (btnName === 'btn-resubmit') {
            if (isBothSideActionUser || userSubconHasPermission) {
               buttonPanelFunction('option-email-or-not-for-admin');
               getSheetRows({
                  currentRefToAddNewOrReplyOrEdit: {
                     tempRefData: rowData,
                  },
               });
            };
         } else if (btnName === 'btn-reply') {
            if (companyCellTagCviOrDt) {

               if (pageSheetTypeName === 'page-dt') {
                  buttonPanelFunction('acknowledge-form');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        currentRefData: rowData,
                        formRefType: 'form-reply-multi-type',
                        isFormEditting: false,
                     },
                  });
               } else if (pageSheetTypeName === 'page-cvi' && !isBothSideActionUser) {
                  buttonPanelFunction('acknowledge-or-reply-form');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        currentRefData: rowData,
                        formRefType: 'form-reply-multi-type',
                        isFormEditting: false,
                     },
                  });
               } else if (pageSheetTypeName === 'page-cvi' && isBothSideActionUser) {
                  buttonPanelFunction('option-email-or-not-for-admin');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        tempRefData: rowData,
                        tempConsultantToReply: companyCellTagCviOrDt
                     },
                  });
               };

            } else {
               if (isBothSideActionUser) {
                  buttonPanelFunction('option-email-or-not-for-admin');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        tempRefData: rowData,
                        tempConsultantToReply: replyCompany,
                     },
                  });
               } else {
                  buttonPanelFunction('form-reply-multi-type');
                  getSheetRows({
                     currentRefToAddNewOrReplyOrEdit: {
                        currentRefData: rowData,
                        formRefType: 'form-reply-multi-type',
                        isFormEditting: false
                     },
                  });
               };
            };

         } else if (btnName === 'btn-textContent') {
            const textContentCell = getInfoValueFromRefDataForm(rowData, 'submission', refType,
               column.key === 'Description' ? 'description' :
                  column.key === 'Contract Specification' ? 'contractSpecification' :
                     column.key === 'Proposed Specification' ? 'proposedSpecification' :
                        (column.key === 'RFAM Info' || column.key === 'RFA Info') ? 'listForm' :
                           'conversationAmong'
            );
            setModalContentText(textContentCell);
         } else if (btnName === 'btn-resend-email') {
            setModalConfirmResendFailedEmail(true);
         } else if (btnName === 'btn-email-content') {
            setModalEmailContent(true);

         } else if (btnName === 'btn-migrate-old-dt') {
            setModalSelectTypeToLinkOldDt(true);
         };
      } catch (err) {
         console.log(err);
      };
   };






   const submitSignedOffFormSendEmail = async () => {

      if (!fileSignedOffFormPdf || Object.values(fileSignedOffFormPdf).length === 0) {
         return message.warn('Please upload pdf signed off form!', 3)
      };

      try {
         setLoading(true);

         let path = `${rowData[`${refType}Ref`]}/${rowData.revision}/submit/${convertCompanyNameFolderS3(company)}/formSignedOff`;
         path = removeSecondPart(path);


         const file = Object.values(fileSignedOffFormPdf)[0] || {};

         const linkFormPdfSignedOffFormSubmit = `drawingPlatform/${projectId}/${path}/${file.name}`;
         const getKeyRes = await apiClientGetKeyUpload({ fileKey: linkFormPdfSignedOffFormSubmit });
         const signedrequesturl = getKeyRes.data.signedRequest;

         await apiClientUploadFile({ signedrequesturl, file });

         const isDateSendNoEmail = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'dateSendNoEmail');

         let rowOutput = { _id: rowData.id, data: {} };

         rowOutput.data[`submission-${refType}-linkSignedOffFormSubmit-${company}`] = linkFormPdfSignedOffFormSubmit;
         if (!isDateSendNoEmail) {
            rowOutput.data[`submission-${refType}-date-${company}`] = new Date();
         };

         await apiUpdateOrCreateRowsMultiForm(refType, { token, projectId, rows: [rowOutput] });

         const isUploadRevisedSignedForm = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'linkSignedOffFormSubmit') ? true : false;

         if (!isDateSendNoEmail) {
            const data = {
               projectId, company, projectName,
               formSubmitType: refType,
               type: 'submit-signed-off-final',
               rowIds: [rowData.id],
               emailSender: email,
               isFormEditting: isUploadRevisedSignedForm
            };

            // const emailText = await apiSendEmailDevTest({ token, data });
            // console.log('FINALLLL ---- emailText===MULTIFORM', emailText.data.emailContent);

            await apiSendEmail({
               token, data,
               momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
               isInstant: true
            });

         };

         message.success('Submitted Successfully', 2);

         const res = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });
         commandAction({ type: 'reload-data-view-multi-form', data: res.data });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };




   const confirmResendFailedEmail = async () => {


      try {

         const thisFormInfo = await apiFetchOneDataMultiForm(refType, { token, projectId, rowId: rowData['id'] });

         const { data: infoRefFromDB } = thisFormInfo.data;

         const emailFailToSendStatus = replyCompany ? getInfoValueFromRefDataForm(infoRefFromDB, 'reply', refType, 'emailFailToSend', replyCompany)
            : getInfoValueFromRefDataForm(infoRefFromDB, 'submission', refType, 'emailFailToSend');


         if (!emailFailToSendStatus) {
            message.warn(`This ${refType.toUpperCase()} has no pending email to send`, 2);
         } else {
            setLoading(true); // To Reload the page...
            setModalConfirmResendFailedEmail(false);

            const data = {
               company: replyCompany || findSubmissionCompanyInRow(rowData),
               projectId, projectName,
               formSubmitType: refType,
               type: emailFailToSendStatus,
               rowIds: [rowData['id']],
               emailSender: email,
               isFormEditting: false,
            };

            // const emailText = await apiSendEmailDevTest({ token, data });
            // console.log('RESEND ---- emailText===MULTIFORM', emailText.data.emailContent);

            await apiSendEmail({
               token, data,
               momentToTriggerEmail: moment().add(moment.duration(EDIT_DURATION_MIN, 'minutes')),
               isInstant: true,
            });


            const res = await apiFetchDataMultiByDisciplineForm(refType, { token, projectId, email });

            commandAction({ type: 'reload-data-view-multi-form', data: res.data });
         };





      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };






   const onClickDownloadAttachment = (listNotDownloadLink, modalListDrawingAttached) => {

      message.info('Downloading zip file, please wait ...!', 2);

      const arrayLinkItemToDownload = modalListDrawingAttached.filter(file => listNotDownloadLink.indexOf(file.link) === -1);

      const zipFilename = `${getRefStringWithVersion(rowData, refType)}.zip`;

      if (arrayLinkItemToDownload.length > 0) {
         const downloadZipFile = () => {
            const zip = new JSZip();
            let count = 0;

            arrayLinkItemToDownload.forEach(async ({ link: dwgLink, type: folder }) => {

               try {
                  const res = await apiFetchPublicUrl({ key: dwgLink, expire: 1000 });
                  const publicLink = res.data;
                  const fileName = getFileNameFromLinkResponse(dwgLink);

                  const file = await JSZipUtils.getBinaryContent(publicLink);
                  zip.file(`${folder}/${fileName}`, file, { binary: true, createFolders: true });
                  count++;
                  if (count === arrayLinkItemToDownload.length) {
                     zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, zipFilename);
                     });
                  };
               } catch (err) {
                  message.warn('There might be out-of-date downloads link in this RFA, please try download drawing one by one !', 2);
                  console.log(err);
               };
            });
         };
         downloadZipFile();
      } else {
         message.info('There is no drawings to download!', 2);
      };

   };






   const widthCellForContentText = !isTextContentCell(column.key) ? {} : {
      width: column.width - 30
   };



   return (
      <div
         style={{
            width: '100%', height: '100%',
            position: 'relative',
            display: 'flex',
            padding: 3, paddingLeft: 5, color: 'black',
            color: ((isColumnWithReplyData(column.key)) && replyStatus)
               ? 'white'
               : (column.key === 'Due Date' && overdueCount < 0) ? 'red'
                  : (column.key === 'Due Date' && overdueCount >= 0 && overdueCount < 3) ? colorTextRow['Due in the next 1 - 3 days']
                     : (column.key === 'Due Date' && overdueCount >= 3) ? 'black'
                        : 'black',

            fontWeight: (column.key === 'Due Date' && overdueCount) && 'bold',

            background:
               (isColumnWithReplyData(column.key) && replyStatus) ? colorTextRow[replyStatus]
                  : 'transparent',

         }}
         onMouseOver={() => {
            if (!btnShown) setBtnShown(true);
         }}
         onMouseLeave={() => {
            if (btnShown) setBtnShown(false);
         }}
      >
         {(rowData.treeLevel && column.key === expandedColumn) ? (
            <div style={{ color: 'black', fontWeight: 'bold' }}>{rowData.title}</div>
         ) : !rowData.treeLevel ? (
            <div style={{
               display: 'flex',
               overflow: 'hidden',
            }}>
               <span
                  style={{
                     ...widthCellForContentText,
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
                  }}
               >
                  {getCellFormData(
                     rowData, column.key, refType, replyCompany, replyStatus, replyDate,
                     onClickCellButton, roleTradeCompany, pageSheetTypeName, email, overdueCount, isBothSideActionUser, companies
                  )}
               </span>

               {(column.key === expandedColumn && pageSheetTypeName !== 'page-mm') && (
                  <div style={{
                     position: 'absolute', left: rowData[`${refType}Ref`].length * 9, top: 4,
                     display: 'flex',
                  }}>
                     {rowData['btn'].map(btn => (
                        <ButtonForm
                           key={btn}
                           onClick={() => onClickRefDrawing(btn)}
                           isActive={btn === rowData.revision}
                        >{btn}</ButtonForm>
                     ))}
                  </div>
               )}

            </div>
         ) : ''}


         {btnShown && !rowData.treeLevel && arrayButtonCell.map((btn, i) => (
            <Tooltip key={i} placement='top' title={getTooltipText(btn)}>
               <Icon
                  type={getButtonType(btn)}
                  style={{
                     cursor: 'pointer', fontSize: 16,
                     position: 'absolute',
                     right: getOffsetRight(i, arrayButtonCellSubmitAndReply.length),
                     top: 6
                  }}
                  onClick={() => onClickCellButton(btn)}
               />
            </Tooltip>
         ))}

         {!rowData.treeLevel && arrayButtonCellSubmitAndReply.map((btn, i) => (
            <Tooltip key={i} placement='top' title={getTooltipText(btn)}>
               <Icon
                  type={getButtonType(btn)}
                  style={{
                     cursor: 'pointer', fontSize: 16, position: 'absolute', top: 6,

                     right: i === 0 ? 5 : i === 1 ? 27 : 49
                  }}
                  onClick={() => onClickCellButton(btn)}
               />
            </Tooltip>
         ))}


         {modalListDrawingAttached && (
            <ModalStyled
               title={'Documents List'}
               visible={modalListDrawingAttached !== null ? true : false}
               footer={null}
               destroyOnClose={true}
               centered={true}
               onCancel={() => {
                  setModalListDrawingAttached(null);
                  setBtnShown(false);
               }}

            >
               <DocumentListPanel
                  columnKey={column.key}
                  expandedColumn={expandedColumn}
                  modalListDrawingAttached={modalListDrawingAttached}
                  refType={refType}
                  openDrawingFromList={openDrawingFromList}
                  setModalListDrawingAttached={setModalListDrawingAttached}
                  setBtnShown={setBtnShown}
                  onClickDownloadAttachment={onClickDownloadAttachment}
               />

            </ModalStyled>
         )}



         {modalContentText && (
            <ModalStyled
               title={column.key}
               visible={modalContentText !== null ? true : false}
               footer={null}
               onCancel={() => {
                  setModalContentText(null);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width='50%'
            >
               <div style={{ overflowY: 'auto', whiteSpace: 'pre-wrap', maxHeight: 600 }}>
                  {replaceBreakLine(
                     (column.key === 'RFA Info' || column.key === 'RFAM Info')
                        ? (modalContentText || []).join('\n')
                        : modalContentText
                  )}
               </div>
            </ModalStyled>
         )}




         {panelUploadSignedOffFormShown && (
            <ModalStyled
               title={'Submit Signed Off Cover Form'}
               visible={panelUploadSignedOffFormShown}
               footer={null}
               onCancel={() => {
                  setPanelUploadSignedOffFormShown(false);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.6}
            >
               <div style={{ background: 'white', height: 120 }}>
                  <div style={{
                     marginBottom: 20,
                     display: 'flex',
                     textOverflow: 'ellipsis',
                     overflow: 'hidden',
                     whiteSpace: 'nowrap',
                     // borderBottom: '1px solid #D8D8D8'
                  }}>
                     <Upload
                        name='file' accept='application/pdf' multiple={false} showUploadList={false}
                        headers={{ authorization: 'authorization-text' }}
                        beforeUpload={() => { return false }}
                        onChange={onChangeUploadSignedOffForm}
                     >
                        <ButtonStyle
                           marginRight={5}
                           name='Upload Signed Off Cover Form'
                        />
                     </Upload>
                     <div style={{
                        marginLeft: 15
                     }}>{fileSignedOffFormPdf && Object.values(fileSignedOffFormPdf)[0].name}</div>
                  </div>


                  <div style={{ marginTop: 10, padding: 10, float: 'right' }}>
                     <ButtonGroupComp
                        onClickApply={submitSignedOffFormSendEmail}
                        onClickCancel={() => {
                           setPanelUploadSignedOffFormShown(false);
                           setBtnShown(false);
                        }}
                        newTextBtnApply={`Submit ${refType.toUpperCase()}`}
                     />
                  </div>
               </div>
            </ModalStyled>
         )}


         {modalConfirmResendFailedEmail && (
            <ModalStyled
               title={'Resend Failed Email'}
               visible={modalConfirmResendFailedEmail}
               footer={null}
               onCancel={() => {
                  setModalConfirmResendFailedEmail(false);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width={'60%'}
            >
               <div style={{ color: 'black' }}>Are you sure to resend failed email?</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey3}`, marginTop: 10 }}>
                  <ButtonGroupComp
                     onClickCancel={() => setModalConfirmResendFailedEmail(false)}
                     onClickApply={() => confirmResendFailedEmail()}
                     newTextBtnApply='Resend Email'
                  />
               </div>
            </ModalStyled>
         )}


         {modalEmailContent && (
            <ModalStyled
               title={'Email Content'}
               visible={modalEmailContent}
               footer={null}
               onCancel={() => {
                  setModalEmailContent(false);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
               width={'60%'}
            >
               <EmailContentBox rowData={rowData} refType={refType} />
            </ModalStyled>
         )}



         {modalLinkOldDtToDms && (
            <ModalStyled
               title={'Migrate DT To DMS'}
               visible={modalLinkOldDtToDms ? true : false}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.9}
            >
               <PanelLinkOldDtToDms
                  stateProject={stateProject}
                  stateRow={stateRow}
                  getSheetRows={getSheetRows}
                  rowData={rowData}
                  commandAction={commandAction}
                  setLoading={setLoading}
                  linkedFormTypeName={modalLinkOldDtToDms}
                  onClickCancel={() => {
                     setModalLinkOldDtToDms(null);
                     setBtnShown(false);
                  }}
               />
            </ModalStyled>
         )}

         {modalSelectTypeToLinkOldDt && (
            <ModalStyled
               title={'Form Type To Link DT'}
               visible={modalSelectTypeToLinkOldDt}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={330}
               onCancel={() => {
                  setModalSelectTypeToLinkOldDt(false);
                  setBtnShown(false);
               }}
            >
               <PanelSelectTypeToLink
                  OnClickSelectTypeToLink={(typeLink) => {
                     setModalSelectTypeToLinkOldDt(false);
                     setBtnShown(false);
                     setModalLinkOldDtToDms(typeLink);
                  }}
               />
            </ModalStyled>
         )}



         {modalIssueForConstructionDrawings && (
            <ModalStyled
               title={'Drawings Issue For Construction'}
               visible={modalIssueForConstructionDrawings}
               footer={null}
               onCancel={() => {
                  setModalIssueForConstructionDrawings(false);
                  setBtnShown(false);
               }}
               destroyOnClose={true}
               centered={true}
            >
               <ListDrawingsIssueForConstruction
                  rowData={rowData}
                  stateProject={stateProject}
               />
            </ModalStyled>
         )}


      </div>
   );
};

export default CellForm;



const ButtonForm = styled.div`
   &:hover {
      cursor: pointer;
   };
   border-radius: 0;
   border: 1px solid grey;
   background: ${props => props.isActive ? colorType.yellow : colorType.grey4};
   min-width: 24px;
   margin-right: 3px;
   
   text-align: center;
   transition: 0.3s;
`;

const ModalStyled = styled(Modal)`
   
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 20px;
      justify-content: center;
   }
`;


const FileLinkName = styled.div`
   &:hover {
      background-color: #f1f2f6;
   };
   transition: 0.3s;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
`;

const getTooltipText = (btnName, pageSheetTypeName) => {
   let result = 'No Tooltip';
   if (btnName === 'btn-linkSubmissionFiles') {
      result = 'Open Submission Documents';
   } else if (btnName === 'btn-submitSignedOffToConsultant') {
      result = 'Submit To Consultants';
   } else if (btnName === 'btn-edit') {
      result = 'Edit Form';
   } else if (btnName === 'btn-migrate-old-dt') {
      result = 'Link DT To DMS';
   } else if (btnName === 'btn-email-content') {
      result = 'View Email Content';
   } else if (btnName === 'btn-resend-email') {
      result = 'Resend Email';
   } else if (btnName === 'btn-resubmit') {
      result = 'Resubmit Form';
   } else if (btnName === 'btn-issue-dt') {
      result = 'Open Drawings Issue For Construction';
   } else if (btnName === 'btn-reply' && pageSheetTypeName === 'page-cvi') {
      result = 'Reply Or Acknowledge Form';
   } else if (btnName === 'btn-reply' && pageSheetTypeName === 'page-dt') {
      result = 'Acknowledge Form';
   } else if (btnName === 'btn-reply' && pageSheetTypeName !== 'page-cvi' && pageSheetTypeName !== 'page-dt') {
      result = 'Reply';
   } else if (btnName === 'btn-linkReplyFiles') {
      result = 'Open Reply Form';
   } else if (btnName === 'btn-textContent') {
      result = 'See Content';
   };
   return result;
};

const getOffsetRight = (index, nosOfBtnResubmitAndUpload) => {
   return 5 + nosOfBtnResubmitAndUpload * 22 + index * 22;
};
const getButtonType = (btnName) => {
   let result = 'xxx';
   if (btnName === 'btn-linkSubmissionFiles') {
      result = 'file';
   } else if (btnName === 'btn-submitSignedOffToConsultant') {
      result = 'vertical-align-top';
   } else if (btnName === 'btn-edit') {
      result = 'edit';
   } else if (btnName === 'btn-migrate-old-dt') {
      result = 'link';
   } else if (btnName === 'btn-email-content') {
      result = 'project';
   } else if (btnName === 'btn-resubmit') {
      result = 'plus-square';
   } else if (btnName === 'btn-reply') {
      result = 'form';
   } else if (btnName === 'btn-issue-dt') {
      result = 'file';
   } else if (btnName === 'btn-resend-email') {
      result = 'mail';
   } else if (btnName === 'btn-linkReplyFiles') {
      result = 'file';
   } else if (btnName === 'btn-textContent') {
      result = 'file';
   };
   return result;
};

const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      border: none;
      background: ${colorType.primary}
   }
`;



export const checkIfEditTimeIsOverMultiForm = (rowData, replyCompany, editTimeAllowed, refType, type) => {

   if (type === 'check-if-submission-edit-is-over') {

      const dateNoSendEmailSubmission = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'dateSendNoEmail');
      const dateSubmission = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'date');
      const date = dateNoSendEmailSubmission || dateSubmission;

      if (!date) return -1;

      const duration = moment.duration(moment(new Date()).diff(date)).asMinutes();
      return editTimeAllowed - duration;

   } else if (type === 'check-if-reply-edit-is-over') {
      const dateNoSendEmailReply = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'dateSendNoEmail', replyCompany);
      const dateReply = getInfoValueFromRefDataForm(rowData, 'reply', refType, 'date', replyCompany);
      const date = dateNoSendEmailReply || dateReply;

      if (!date) return -1;

      const duration = moment.duration(moment(new Date()).diff(date)).asMinutes();
      return editTimeAllowed - duration;

   };
};



const getCellFormData = (row, header, refType, replyCompany, replyStatus, replyDate, onClickCellButton, { company, role }, pageSheetTypeName, email, overdueCount, isBothSideActionUser, companies) => {

   if (
      header === 'RFAM Ref' ||
      header === 'RFI Ref' ||
      header === 'CVI Ref' ||
      header === 'DT Ref' ||
      header === 'MM Ref' ||
      header === 'QR Ref' ||
      header === 'QRM Ref' ||
      header === 'RFC Ref'
   ) {
      return row.revision === '0' ? row[refType + 'Ref'] : row[refType + 'Ref'] + row.revision;

   } else if (header === 'Description') return getInfoValueFromRefDataForm(row, 'submission', refType, 'description');
   else if (header === 'Subject') return getInfoValueFromRefDataForm(row, 'submission', refType, 'emailTitle');
   else if (header === 'Requested By') return getInfoValueFromRefDataForm(row, 'submission', refType, 'requestedBy');
   else if (header === 'Submission Date') {
      const dateSubmission = getInfoValueFromRefDataForm(row, 'submission', refType, 'date');
      
      return moment(dateSubmission).format('DD/MM/YY');

   } else if (header === 'Meeting Date' || header === 'Conversation Date') {
      const dateSubmission = getInfoValueFromRefDataForm(row, 'submission', refType, 'dateConversation');
      const timeSubmission = getInfoValueFromRefDataForm(row, 'submission', refType, 'timeConversation');
      return `${moment(dateSubmission).format('DD/MM/YY')} -  ${moment(timeSubmission).format('HH:mm')}`;

   } else if (header === 'Due Date') {
      const dateDue = getInfoValueFromRefDataForm(row, 'submission', refType, 'due');
      if (dateDue) {
         return moment(dateDue).format('DD/MM/YY');
      } else {
         return '';
      };


   } else if (header === 'Conversation Among') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'conversationAmong');
   } else if (header === 'Cost Implication') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'isCostImplication')
         ? <Icon type='check' />
         : null;
   } else if (header === 'Time Extension') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'isTimeExtension')
         ? <Icon type='check' />
         : null;
   } else if (header === 'Signatured By') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'signaturedBy');
   } else if (header === 'Contract Specification') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'contractSpecification');
   } else if (header === 'Proposed Specification') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'proposedSpecification');
   } else if (header === 'Submission Type') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'submissionType');
   } else if (header === 'Attachment Type') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'herewithForDt');
   } else if (header === 'Transmitted For') {
      return getInfoValueFromRefDataForm(row, 'submission', refType, 'transmittedForDt');

   } else if (header === 'Tags') {

      const tagsData = getInfoValueFromRefDataForm(row, 'submission', refType, 'listTagForDtForm') || [];

      return <div style={{ display: 'flex' }}>
         {tagsData.map(tg => (
            <div
               key={tg}
               style={{
                  marginRight: 5, paddingLeft: 4, paddingRight: 4,
                  border: `1px solid ${colorType.grey1}`,
                  display: 'flex',
               }}
            >{tg}</div>
         ))}
      </div>;

   } else if (header === 'Received By') {

      const consultantMustReply = getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply');

      return (
         <div style={{ display: 'flex' }}>
            {consultantMustReply.map((cmp, i) => {

               let iconTagsArray = [];

               const isEditTimeReplyIsOverMultiForm = checkIfEditTimeIsOverMultiForm(row, cmp, EDIT_DURATION_MIN, refType, 'check-if-reply-edit-is-over');
               const isUserTheRefCreator = getInfoValueFromRefDataForm(row, 'reply', refType, 'user', cmp) === email;
               const isSignedOffAlreadySubmitted = getInfoValueFromRefDataForm(row, 'submission', refType, 'linkSignedOffFormSubmit');

               const isThisRefAlreadyAcknowledged = getInfoValueFromRefDataForm(row, 'reply', refType, 'acknowledge', cmp);
               const isThisRefAlreadyReplied = getInfoValueFromRefDataForm(row, 'reply', refType, 'status', cmp);

               const isRepliedFormUploaded = getInfoValueFromRefDataForm(row, 'reply', refType, 'linkFormReply', cmp);

               const dateReply = getInfoValueFromRefDataForm(row, 'reply', refType, 'date', cmp);


               const isAllowedToReplyByRole = role === 'Consultant' ||
                  (pageSheetTypeName === 'page-dt' && (role.toLowerCase() === 'sub-con' || role === 'Client'))


               if (((cmp === company && isAllowedToReplyByRole) || isBothSideActionUser) && isSignedOffAlreadySubmitted) {
                  iconTagsArray = [...iconTagsArray, 'btn-reply'];
               };


               // if (isRepliedFormUploaded && isUserTheRefCreator && // user who did not create form also can edit, just in case DC resign or change project
               if (isRepliedFormUploaded &&
                  ((cmp === company && isAllowedToReplyByRole) || isBothSideActionUser)
               ) {
                  iconTagsArray = [...iconTagsArray, 'btn-edit'];
               };

               if (
                  isRepliedFormUploaded && (isEditTimeReplyIsOverMultiForm <= 0 || isUserTheRefCreator)) {
                  iconTagsArray = [...iconTagsArray, 'btn-linkReplyFiles'];
               };

               const cmpFound = companies.find(cm => cm['company'] === cmp) || {};
               const nameDisplay = cmpFound['shortName'] || cmp;

               return (
                  <div
                     key={i}
                     style={{
                        marginRight: 5, paddingLeft: 4, paddingRight: 4,
                        background: (isThisRefAlreadyAcknowledged || isThisRefAlreadyReplied) ? colorType.yellow : 'white',
                        fontWeight: (isThisRefAlreadyAcknowledged || isThisRefAlreadyReplied) ? 'bold' : 'normal',
                        border: `1px solid ${colorType.grey1}`,
                        display: 'flex',
                     }}
                  >
                     <div>{nameDisplay}{dateReply ? ` - ${moment(dateReply).format('DD/MM/YY')}` : ''}</div>
                     {iconTagsArray.map((icon, index) => (
                        <Tooltip key={index} placement='top' title={getTooltipText(icon, pageSheetTypeName)}>
                           <Icon
                              type={getButtonType(icon)}
                              style={{
                                 cursor: 'pointer', fontSize: 16,
                                 marginTop: 1,
                                 marginLeft: index === 0 ? 10 : 5
                              }}
                              onClick={() => onClickCellButton(icon, cmp)}
                           />
                        </Tooltip>
                     ))}
                  </div>
               );
            })}
         </div>
      );
   } else if (isColumnWithReplyData(header)) {

      const cmpFound = companies.find(cmp => cmp['company'] === replyCompany) || {};
      const nameDisplay = cmpFound['shortName'] || replyCompany;


      return replyStatus ? (
         <>
            <span style={{ fontWeight: 'bold' }}>{nameDisplay}</span>
            <span>{` - (${replyDate})`}</span>
         </>
      ) : nameDisplay;


   } else if (header === 'RFA Info' || header === 'RFAM Info') {
      return (getInfoValueFromRefDataForm(row, 'submission', refType, 'listForm') || []).join(', ');

   } else if (header === 'Issue For Construction') {
      const dtRef = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'issueForConstruction') || '';
      const allParts = dtRef.split('/');

      let lengthDeduce = 0;
      if (allParts[0] && allParts[1]) {
         lengthDeduce = (allParts[0] + '/' + allParts[1] + '/').length;
      };
      return (
         <span style={{ float: 'right', marginRight: 23 }}>{dtRef.slice(lengthDeduce, dtRef.length)}</span>
      );
   } else if (header === 'IFC Rev') {
      return getInfoValueFromRefDataForm(row, 'submission', 'dt', 'ifcVersion');
   } else if (header === 'IFC Date') {
      const dateIssuance = getInfoValueFromRefDataForm(row, 'submission', 'dt', 'date');
      return dateIssuance ? moment(dateIssuance).format('DD/MM/YY') : '';
   };
   return 'xxx-xx';
};






export const getConsultantReplyFormData = (rowData, header, refType) => {
   let replyStatus, replyCompany, replyDate;

   let listConsultantMustReply = getInfoValueFromRefDataForm(rowData, 'submission', refType, 'consultantMustReply');
   if (!listConsultantMustReply || listConsultantMustReply.length === 0) return { replyStatus, replyCompany, replyDate };

   const listArrayLead = listConsultantMustReply.filter((x, i) => i === 0);
   const listArrayRemaining = listConsultantMustReply.filter((x, i) => i > 0);
   listConsultantMustReply = [...listArrayLead, ...listArrayRemaining.sort()];

   const consultantHeaderNumber = parseInt(header.slice(12, header.length - 1));

   const consultantNameOfThisCell = listConsultantMustReply[consultantHeaderNumber - 1];

   return {
      replyStatus: rowData[`reply-${refType}-status-${consultantNameOfThisCell}`],
      replyCompany: consultantNameOfThisCell,
      replyDate: convertReplyOrSubmissionDate(rowData[`reply-${refType}-date-${consultantNameOfThisCell}`])
   };
};


export const getInfoValueFromRefDataForm = (obj, typeSubmit, typeForm, info, company) => {
   for (const key in obj) {
      if (key.includes(
         typeSubmit === 'reply'
            ? `${typeSubmit}-${typeForm}-${info}-${company}`
            : `${typeSubmit}-${typeForm}-${info}-` // must have '-' ending 
      )) {
         return obj[key];
      };
   };
};



const isTextContentCell = (header) => {
   return header === 'Description' ||
      header === 'Contract Specification' ||
      header === 'Proposed Specification' ||
      header === 'Conversation Among' ||
      header === 'RFA Info' ||
      header === 'RFAM Info'
};



const DocumentListPanel = ({ columnKey, expandedColumn, modalListDrawingAttached, refType, openDrawingFromList, setModalListDrawingAttached, setBtnShown, onClickDownloadAttachment }) => {

   const [listNotDownloadLink, setListNotDownloadLink] = useState([]);

   return (
      <>
         <div style={{
            width: '100%',
            maxHeight: window.innerHeight * 0.7,
            overflowY: 'scroll'
         }}>
            {(
               (refType === 'qr' || refType === 'qrm')
                  ? ['file']
                  : (refType === 'rfc' && columnKey === expandedColumn) ? ['form', 'file']
                     : (refType === 'rfc' && isColumnWithReplyData(columnKey)) ? ['file']
                        : ['form', 'file', 'dwg-rfa', 'dwg-rfam', 'dwg-rfi', 'dwg-cvi']
            ).map(type => {

               const filesFilter = modalListDrawingAttached.filter(x => x.type === type);

               return filesFilter.length > 0 ? (
                  <div key={type} style={{ marginBottom: 15 }}>
                     <div style={{ fontWeight: 'bold' }}>{
                        type === 'form' ? 'Form' : type === 'file' ? 'Attachments' : type === 'dwg-rfa' ? 'RFA'
                           : type === 'dwg-rfam' ? 'RFAM' : type === 'dwg-rfi' ? 'RFI' : type === 'dwg-cvi' ? 'CVI' : ''
                     }</div>

                     {filesFilter.map(({ link: dwgLink, type }) => {
                        const fileName = /[^/]*$/.exec(dwgLink)[0];
                        return (
                           // <div key={dwgLink} style={{ display: 'flex', justifyContent: 'space-between' }}>
                           //    <FileLinkName
                           //       key={dwgLink}
                           //       onClick={() => openDrawingFromList(dwgLink, type)}
                           //       style={{ cursor: 'pointer', margin: 5, padding: 5, color: colorType.hyperlink }}
                           //    >{fileName}</FileLinkName>

                           //    <CheckboxStyled
                           //       style={{ transform: 'translateY(7px)', paddingRight: 15 }}
                           //       onChange={() => {
                           //          if (listNotDownloadLink.indexOf(dwgLink) !== -1) {
                           //             const newListNotDownloadLink = listNotDownloadLink.filter(x => x !== dwgLink);
                           //             setListNotDownloadLink(newListNotDownloadLink);
                           //          } else {
                           //             const newListNotDownloadLink = [...listNotDownloadLink, dwgLink];
                           //             setListNotDownloadLink(newListNotDownloadLink);
                           //          };
                           //       }}
                           //       checked={listNotDownloadLink.indexOf(dwgLink) === -1}
                           //    />
                           // </div>

                           <FileLinkName
                              key={dwgLink}
                              onClick={() => openDrawingFromList(dwgLink, type)}
                              style={{ cursor: 'pointer', margin: 5, padding: 5, color: colorType.hyperlink }}
                           >{fileName}</FileLinkName>
                        );
                     })}
                  </div>
               ) : null;
            })}

         </div>

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', marginTop: 15, borderTop: '1px solid #D8D8D8' }}>
            <ButtonStyle
               name={'Close'}
               onClick={() => {
                  setModalListDrawingAttached(null);
                  setBtnShown(false);
               }}
            />
            <ButtonStyle
               marginRight={15}
               background={colorType.primary}
               colorText={'white'}
               name={'Download'}
               onClick={() => {
                  onClickDownloadAttachment(listNotDownloadLink, modalListDrawingAttached);
               }}
            />
         </div>
      </>
   );
};



const PanelSelectTypeToLink = ({ OnClickSelectTypeToLink }) => {
   return (
      <div style={{ display: 'flex' }}>
         {['rfa', 'rfam', 'rfi', 'cvi'].map(item => (
            <ButtonStyle
               key={item}
               name={item === 'rfa' ? 'DMS' : item.toUpperCase()}
               onClick={() => OnClickSelectTypeToLink(item)}
               marginRight={15}
            />
         ))}
      </div>
   );
};