import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'


import ForgeViewer from '../../pages/BimApp/ForgeViewer'


import { trackingUser, storeHistory, checkWohhupUser } from '../../module_BimApp/function/AdminFunction'

// import listElementTable, { generateTable } from '../../js/Rebar/RebarSchedulePanel/ScheduleRebarFunction';
///
const func ={
  'precast': {precast: [] },
  'ppvc':{precast:[]},
  '5d':{   planing: ['timeline', 'compare 4d', 'report']},
}
class ViewerPage5D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount() {
    try {
      trackingUser(this.props.email, this.props.location.state.projectName, this.props.location.state.folderName, this.props.location.state.itemName)
      storeHistory(this.props.email, this.props.location.state.itemId, this.props.location.state.itemName)
    } catch { }
  }

  render() {
    return (
      <ForgeViewer
        objectId={this.props.location.state.id}
        itemId={this.props.location.state.itemId}
        itemName={this.props.location.state.itemName}
        itemDisplayName={this.props.location.state.itemDisplayName}
        projectId={this.props.location.state.projectId}
        projectName={this.props.location.state.projectName}
        folderName={this.props.location.state.folderName}
        version={this.props.location.state.version}
        typeFile={this.props.location.state.typeFile}
        projectKey={this.props.location.state.sharePointId}
        issueKey={this.props.location.state.issueKey}
        folderSmartSheetId={this.props.location.state.folderSmartSheetId}
        typeProject={this.props.location.state.typeProject}
        smartSheetId={this.props.location.state.smartSheetId}
        objectKey={this.props.location.state.objectKey}
        userCreatedProject={this.props.location.state.userCreatedProject}
        manningScheduleId={this.props.location.state.manningScheduleId}
        useFunction={func[this.props.location.state.typeFile]}
        isWohhupUser={false}
        isInternalFunction={false}
        openFunction={['progressDock']}
        isTesting={false}
        // lastVersion={this.props.location.state.lastVersion}
      />

    )
  }

}

ViewerPage5D.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(ViewerPage5D)