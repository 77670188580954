import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from 'lodash'
import base64url from 'base64url';
import { getPublicUrl } from '../../module_BimApp/function/AdminFunction'
import path from 'path';
import { Button } from 'antd';
import $ from 'jquery';
import { saveAs } from 'file-saver'
const Autodesk = window.Autodesk;

const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';



const DMSForgeViewer = (props) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (props.match.params.id) {//
      setLoading(true)
      let key = base64url.decode(props.match.params.id)
      let split = path.basename(key).split('.');
      let extenrsion = split[split.length - 1]
      if (extenrsion.toLowerCase() === 'pdf' || extenrsion.toLowerCase() === 'dwf' || extenrsion.toLowerCase() === 'dwfx') {
        getPublicUrl(key, 10000, typeBucketName)
          .then(res => {
            Autodesk.Viewing.Initializer({ env: 'Local' }, async () => {
              const viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'));
              viewer.start();
              viewer.loadExtension('Autodesk.DocumentBrowser')
              let isDWF = extenrsion.toLowerCase() === 'pdf' ? 'Autodesk.PDF' : 'Autodesk.DWF'
              viewer.loadExtension(isDWF).then(async (e) => {
                let url = res
                if (isDWF === 'Autodesk.DWF') {
                  var xhr = new XMLHttpRequest();
                  xhr.open('GET', res, true);
                  xhr.responseType = 'blob';
                  xhr.onload = async function (e) {
                    if (this.status == 200) {
                      var myBlob = this.response;
                      const url1 = window.URL.createObjectURL(myBlob);
                      url = res//+'?type=.dwf'
                      // let ddd =await myBlob.arrayBuffer()
                      viewer.loadModel(url1 + '#.dwf', {}, (e) => {
                        try {
                          viewer.setReverseZoomDirection(true)
                          let view3d = e.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
                          let view2d = e.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)
                          let viewAll = view3d.concat(view2d)
                          if (viewAll.length > 1) {
                            let documentBrowser = viewer.getExtension('Autodesk.DocumentBrowser')
                            documentBrowser.ui.togglePanel(true)
                            documentBrowser.ui.panel.container.style.top = 0
                            documentBrowser.ui.panel.container.style.left = 'unset'
                            documentBrowser.ui.panel.container.style.right = '0px'
                            documentBrowser.ui.panel.container.style.width = '200px'
                          }
                        } catch { }
                      });
                    }
                  };
                  xhr.send();
                } else {
                  viewer.loadModel(url, {}, (e) => {
                    try {
                      viewer.setReverseZoomDirection(true)
                      let view3d = e.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
                      let view2d = e.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)
                      let viewAll = view3d.concat(view2d)
                      if (viewAll.length > 1) {
                        let documentBrowser = viewer.getExtension('Autodesk.DocumentBrowser')
                        documentBrowser.ui.togglePanel(true)
                        documentBrowser.ui.panel.container.style.top = 0
                        documentBrowser.ui.panel.container.style.left = 'unset'
                        documentBrowser.ui.panel.container.style.right = '0px'
                        documentBrowser.ui.panel.container.style.width = '200px'
                      }
                    } catch { }
                  });
                }

              });
              setLoading(false)
            });
          })
          .catch(err => {
            setLoading(false)
          })
      }
    }
  }, [])
  const handleDownload = () => {
    if (props.match.params.id) {
      let key = base64url.decode(props.match.params.id)
      let split = key.split('/')
      let fileName = split[split.length - 1]
      getPublicUrl(key, 10000, typeBucketName)
        .then(res => {
          // let link = document.createElement('a');
          // link.href = res;
          // link.target = "_blank"
          // link.download="FileName.pdf"
          // link.click();
          saveAs(res,fileName)
        })
        .catch(err => {

        })
    }
  }

  return (
    <>
      <div style={{ position: 'absolute', zIndex: '100', height: `calc(100% - 53.78px)`, backgroundColor: 'white', width: '100%' }}>
        <div style={{ position: 'absolute', zIndex: 200, display: 'flex' }}>
          <Button onClick={handleDownload} type='ghost'  > Download</Button>
        </div>

        <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }} />
      </div>
    </>
  );
};


DMSForgeViewer.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    userInfo: state.user,
    isAdmin: state.user.email === "admin@wohhup.com",
  };
}
export default connect(mapStateToProps)(DMSForgeViewer)