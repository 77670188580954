import React, { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
const Header = memo((props) => {

    const [expanded, setExpanded] = useState();
    const { columnGroup } = props;
    const expandable = columnGroup.isExpandable();
    const originalColumnGroup = columnGroup.getOriginalColumnGroup();
  
    const onExpandClicked = useCallback(() => props.setExpanded(!columnGroup.isExpanded()), []);
  
    // useEffect(() => {
    //   const listener = () => {
    //     setExpanded(columnGroup.isExpanded());
    //   };
    //   listener();
    //   originalColumnGroup.addEventListener('expandedChanged', listener);
    //   return () => originalColumnGroup.removeEventListener('expandedChanged', listener);
    // }, []);
  
    const showExpandJsx = () => (
      <button onClick={onExpandClicked} className="my-expand">
        {expanded ? '<' : '>'}
      </button>
    );
//     useEffect(()=>{

//      if( props.typeTimeline ==='month' ){
// moment(props.displayName)
//      }
//     },[props.displayName])
  
    return (
      <span className="idd-group-header" style={{ width:'inherit', textAlignLast:'center'}}>
        {props.displayName}
        {/* {expandable && showExpandJsx()} */}
      </span>
    );
  });


  Header.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    setManpowerData: PropTypes.func.isRequired,
    setManpowerLoading: PropTypes.func.isRequired,
    manpowerLoading: PropTypes.bool.isRequired,
    manpowerData: PropTypes.func.isRequired,
    selectedNode: PropTypes.object.isRequired,
    gridApi: PropTypes.object.isRequired,
    typeTimeline: PropTypes.object.isRequired,
    openEditMultiValuesDialog: PropTypes.object.isRequired,
  };
  
  function mapStateToProps(state) {
    return {
      isAdmin: state.user.email === 'admin@wohhup.com',
      isConfirmed: !!state.user.confirmed,
      userInfo: state.user,
      userRole: state.user.role,
      manpowerLoading: state.manpower.loading,
      manpowerData: state.manpower.data,
      gridApi: state.manpower.gridApi,
      selectedNode: state.manpower.selectedNode,
      timelineDate: state.manpower.timelineDate,
      typeTimeline: state.manpower.typeTimeline,
      openEditMultiValuesDialog: state.manpower.openEditMultiValuesDialog,
      manpower: state.manpower
    };
  }
  export default connect(mapStateToProps, null)(Header);