import React from 'react';
import { Dropdown, Divider, Input, Checkbox } from 'semantic-ui-react'
import { Modal, SelectPicker, Button, IconButton, Icon, Loader } from 'rsuite';
import _ from 'lodash'
import axios from 'axios'
import $ from 'jquery';
import {TableCompare} from '../../module_BimApp/tables'
import Dock from 'react-dock'
import { message, Typography } from 'antd';
import { getFragmentWorldMatrixByNodeId } from '../function/ForgeFunction'
import { getSubRowsCount, getSubRowsTotal, formatNumber, getSubRowsDifferentTotal } from '../function/TableFunction'
import {CompareDock} from '../../module_BimApp/dock_forge'
import moment from 'moment'

const THREE = window.THREE
const Autodesk = window.Autodesk;
let viewerOverlap;
let colorCompare = new THREE.Vector4(210 / 255, 210 / 255, 210 / 255, 1) //red
let red = new THREE.Vector4(1, 0, 0, 1); //#ff0000
let green = new THREE.Vector4(0, 0.5, 0, 1);//#007f00
let orange = new THREE.Vector4(1, 0.6, 0.2, 1); //#ff9933
let listA = {};
let listB = {};
let modelA = null;
let modelB = null;
let removed = [];
let added = [];
let modified = [];
let unChange = []
let storeParameterUnits = {}
let dataGeneralTableGroup = {}
let tempListModel = {}
let allDbIds = []
class DialogCompare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPanel: false,
      openPanelListViewable: false,
      listVersion: [],
      defaultId: null,
      versionCompareId: null,

      listViewable: [],
      viewableCurrent: null,

      columnsPanelSchedule: [],
      dataPanelSchedule: [],
      viewableSelected: null,
      listParameterSelected: [{ displayName: 'Volume', units: 'm^3' }, { displayName: 'Area', units: 'm^2' },],
      displayTableCompare: false,
      listFullData: [],
      heightDock: 500,
      maxForgeViewer: 500,
      modifyForgeViewer: 0,

      loadingBtnVersion: false,
      loadingBtnCompare: false,
      loading: false,

      activeButtonDock: false,
      dataCompareParameter: [],
      displayStatus: {
        new: true,
        changed: true,
        deleted: true,
        unChange: false,
        changedPosition: true
      }
    }
  }
  componentWillUnmount = () => {
    listA = {}
    listB = {}
    modelA = null;
    modelB = null;
    removed = []
    added = []
    modified = []
    storeParameterUnits = {}
    dataGeneralTableGroup = {}
    allDbIds = []
  }
  componentWillMount = () => {
    this.setState({ loading: true })
    axios.get(`/api/items/versions?q=${this.props.itemId}`)
      .then(res => {
        let optionVersion = []
        let defaultId = ''
        _.forEach(res.data.reversion, item => {
          if (item.status === 'success') {
            let date = moment(item.date).format('DD-MM-YYYY')
            let split = item.objectKey.split('-')[0]
            let name = item.objectKey.replace(split + '-', '')
            if (this.props.id === item.objectId)
              defaultId = date + '-' + name + '-v' + item.version
            else
              optionVersion.push({ key: item.objectId, text: date + '-' + name + '-v' + item.version, value: item.objectId })
          }
        })
        this.setState({ listVersion: optionVersion, defaultId: defaultId, openPanel: this.props.openPanel, loading: false })
      })
  }

  handleClosePanel = () => {
    // this.setState({ openPanel: false, versionCompareId: null })
    this.props.onChangeDisplay('dialogCompareQuantities', false)
  }

  handleChangeVersion = (e, { value }) => {
    this.setState({ versionCompareId: value })
  }

  handleSelectViewable = () => {
    this.setState({ loadingBtnVersion: true })
    const models = this.props.viewer.impl.modelQueue().getModels();
    if (models.length === 1) {
      Autodesk.Viewing.Document.load('urn:' + this.state.versionCompareId, this._onDocumentLoadSuccess, this._onDocumentLoadFailure);
    } else {
      message.warning('Please remove other models')
    }
  }
  _onDocumentLoadFailure = (viewerErrorCode) => {
    console.log(viewerErrorCode)
  }
  _onDocumentLoadSuccess = (doc) => {
    let temp = []
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true)
    let guidMain = this.props.viewer.impl.model.getDocumentNode().data.guid
    let viewableSelected = null
    _.forEach(view3d, v => {
      if (v.data.guid === guidMain)
        viewableSelected = v
      temp.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v })
    })
    this.setState({
      listViewable: temp, document: doc, openPanelListViewable: true, versionCompareId: null,
      openPanel: false, viewableCurrent: guidMain, viewableSelected: viewableSelected, loadingBtnVersion: false
    })
    // this.props.onChangeDisplay('dialogCompareQuantities', false)
  };

  handleClosePanelListViewable = () => {
    this.setState({ openPanelListViewable: false, viewableSelected: null })
  }
  handleChangeView = (value) => {
    _.forEach(this.state.listViewable, v => {
      if (v.value === value) {
        this.setState({ viewableSelected: v.obj })
        return false
      }
    })
  }
  handleCompareVersion = () => {
    this.setState({ loadingBtnCompare: true })
    let svfUrl = this.state.document.getViewablePath(this.state.viewableSelected);
    let loadOptions = {
      globalOffset: this.props.viewer.impl.model.myData.globalOffset,
      // applyRefPoint: true,
      modelNameOverride: 'Compare Model',
      isAEC: true,
      guid: this.state.viewableSelected.data.guid,
      acmSessionId: this.state.document.acmSessionId
    };
    // this.props.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (e) => {
    //   this.props.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (e) => { })
    //   // const models = this.props.viewer.impl.modelQueue().getModels();
    //   // if (models.length === 2) {



    //   // }
    // })

    this.props.viewer.loadModel(svfUrl, loadOptions, this._onLoadModelSuccess, this._onLoadModelError)

  }
  _onLoadModelSuccess = () => {
    let models = this.props.viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      this.props.viewer.clearThemingColors(model)
    })
    let isAllLoaded = this.props.viewer.isLoadDone({ onlyModels: models })
    if (isAllLoaded) {
      this.compareFile(models)
    } else {
      this.props.viewer.waitForLoadDone({ onlyModels: models })
        .then(() => {
          this.compareFile(models)
        })
    }
  };
  _onLoadModelError = () => {
    this.setState({ openPanelListViewable: false, viewableSelected: null, listViewable: [], viewableCurrent: null })
    $('#btn-docbrowser').show();
    message.error('Comparison was failed')
  }

  compareFile = (models) => {
    this.props.viewer.setGhosting(true)
    allDbIds = []
    listA = {};
    listB = {};
    tempListModel = {}
    _.forEach(models, (model) => {
      let instanceTree = model.getData().instanceTree;
      let temp = this.getAllModelsElementdbIds(instanceTree, viewerOverlap,
        model, model.myData.loadOptions.modelNameOverride);
      if (model.myData.loadOptions.modelNameOverride !== 'Compare Model') {
        allDbIds.splice(0, 0, { dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
        modelA = model
      }
      else {
        allDbIds.push({ dbIds: temp, model: model, modelName: model.myData.loadOptions.modelNameOverride })
        modelB = model
      }
      tempListModel[model.myData.loadOptions.modelNameOverride] = model
    })
    let checkOnce = true
    var count = allDbIds[0].dbIds.length + allDbIds[1].dbIds.length;
    _.forEach(allDbIds[0].dbIds, (modelAdbId) => {
      allDbIds[0].model.getProperties(modelAdbId, (modelAProperty) => {
        let temp = {}
        var name = modelAProperty.name.split(" [")[0]
        _.forEach(modelAProperty.properties, (v) => {
          if (v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in' &&
            v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label')
            temp[v.displayName] = v
        })
        listA[modelAProperty.externalId] = { 'dbId': modelAdbId, name: name, 'properties': temp, modelName: allDbIds[0].modelName };
        count--;
        if (count === 0 && checkOnce) {
          checkOnce = false
          this.getParameterModify()
        }
      })
    })
    _.forEach(allDbIds[1].dbIds, (modelBdbId) => {
      allDbIds[1].model.getProperties(modelBdbId, (modelBProperty) => {
        let temp = {}
        var name = modelBProperty.name.split(" [")[0]
        _.forEach(modelBProperty.properties, (v) => {
          if (v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in' &&
            v.displayName !== 'parent' && v.displayCategory !== '__internalref__' && v.displayName !== 'label')
            temp[v.displayName] = v
        })
        listB[modelBProperty.externalId] = { 'dbId': modelBdbId, name: name, 'properties': temp, modelName: allDbIds[1].modelName };
        count--;
        if (count === 0 && checkOnce) {
          checkOnce = false
          this.getParameterModify()
        }
      })
    })
    this.setState({ openPanelListViewable: false, viewableSelected: null, listViewable: [], viewableCurrent: null })
    $('#btn-docbrowser').hide();
  }

  getParameterModify = () => {
    let temp = []
    for (var extId in listA) {
      if (typeof listB[extId] === 'undefined') continue; // removed dbId
      var propsA = listA[extId].properties;
      var propsB = listB[extId].properties;
      _.forEach(propsA, (v, k) => {
        if (v.displayName !== 'Category' && v.displayName !== 'CategoryId' && v.displayName !== 'viewable_in' &&
          v.displayName !== 'parent' && v.displayName !== '__internalref__' && v.displayName !== 'label') {
          if (propsB[k]) {
            if (propsA[k].displayValue !== propsB[k].displayValue) {
              var found = temp.some(el => el.label === propsB[k].displayName)
              if (!found) {
                temp.push({ label: propsB[k].displayName, value: { displayName: propsB[k].displayName, units: propsB[k].units } })
              }
            }
          }
          else {
            var found = temp.some(el => el.label === propsA[k].displayName)
            if (!found) {
              temp.push({ label: propsA[k].displayName, value: { displayName: propsA[k].displayName, units: propsA[k].units } })
            }

          }
        }
      })

    }
    this.compareVersion(this.props.viewer)
  }
  getAllModelsElementdbIds = (instanceTree, viewer, versionCompareName) => {
    let temp = [];
    if (!instanceTree) {
      return temp;
    }
    let queue = [];
    queue.push(instanceTree.getRootId());
    while (queue.length > 0) {
      var node = queue.shift();
      if (instanceTree.getChildCount(node) !== 0) {
        instanceTree.enumNodeChildren(node, (childrenIds) => {
          queue.push(childrenIds);
        });
      }
      else {
        if (versionCompareName === 'Comapre Model') {
          viewer.setThemingColor(node, colorCompare)
        }
        temp.push(node);
      }
    }
    return temp;
  }

  compareVersion = (viewer) => {
    dataGeneralTableGroup = {}
    storeParameterUnits = {}
    modified = []
    added = []
    removed = []
    unChange = []
    // listParameter = []
    for (var extIdA in listA) {
      var dbIdA = listA[extIdA].dbId;
      viewer.impl.visibilityManager.hide(dbIdA, modelA);
      if (listB[extIdA] !== undefined) {
        unChange.push({ id: dbIdA, model: modelA })
        continue
      };
      added.push({ id: dbIdA, properties: listA[extIdA].properties })
      viewer.impl.visibilityManager.show(dbIdA, modelA);
      viewer.setThemingColor(dbIdA, green, modelA);
      this.groupParameterNewDeleted(dbIdA, listA[extIdA].name, listA[extIdA].properties, listA[extIdA].modelName, 'New')
    }
    for (var extIdB in listB) {
      var dbIdB = listB[extIdB].dbId
      viewer.impl.visibilityManager.hide(dbIdB, modelB);
      if (listA[extIdB] !== undefined) {
        unChange.push({ id: dbIdB, model: modelB })
        continue;
      }
      removed.push({ id: dbIdB, properties: listB[extIdB].properties })
      viewer.impl.visibilityManager.show(dbIdB, modelB);
      viewer.setThemingColor(dbIdB, red, modelB);
      this.groupParameterNewDeleted(dbIdB, listB[extIdB].name, listB[extIdB].properties, listB[extIdB].modelName, 'Deleted')

    }
    for (var extId in listA) {
      if (typeof listB[extId] === 'undefined') continue; // removed dbId
      var dbIdA = listA[extId].dbId; // should be the same as listB[extId]
      var dbIdB = listB[extId].dbId;
      var propsA = listA[extId].properties;
      var propsB = listB[extId].properties;
      _.forEach(propsA, (v, k) => {
        if (propsB[k]) {
          if (propsA[k].displayValue !== propsB[k].displayValue) {
            viewer.setThemingColor(dbIdA, orange, modelA);
            modified.push({ id: dbIdA, newProperties: propsA, oldProperties: propsB })
            viewer.impl.visibilityManager.show(dbIdA, modelA);
            this.groupParameterModify(dbIdA, listA[extIdA].name, propsA, propsB, listA[extIdA].modelName)
            // modified.push(this.comparePosition(dbIdA, dbIdB, propsA, propsB, true))

            let index = _.findIndex(unChange, e => { return e.id === dbIdA && modelA.myData.loadOptions.modelNameOverride === e.model.myData.loadOptions.modelNameOverride })
            if (index >= 0) {
              unChange.splice(index, 1)
            }
            return false;
          }
        } else {
          viewer.setThemingColor(dbIdA, orange, modelA);
          modified.push({ id: dbIdA, newProperties: propsA, oldProperties: propsB })
          this.groupParameterModify(dbIdA, listA[extIdA].name, propsA, propsB, listA[extIdA].modelName)

          let index = _.findIndex(unChange, e => { return e.id === dbIdA && modelA.myData.loadOptions.modelNameOverride === e.model.myData.loadOptions.modelNameOverride })
          if (index >= 0) {
            unChange.splice(index, 1)
          }
          // modified.push(this.comparePosition(dbIdA, dbIdB, propsA, propsB, true))
          return false;
        }
      })
      // let changePosition = this.comparePosition(dbIdA, dbIdB, propsA, propsB, false)
      // if (changePosition !== null) {
      //   viewer.setThemingColor(dbIdA, orange, modelA);
      //   modified.push(changePosition)
      // }

    }
    this.generateTable()
  }
  //!test
  comparePosition = (dbIdA, dbIdB, propsA, propsB, check) => {
    let temp = null
    let positionA = null
    let positionB = null
    var transMatA = getFragmentWorldMatrixByNodeId(this.props.viewer, modelA, dbIdA).matrix[0];
    var transMatB = getFragmentWorldMatrixByNodeId(this.props.viewer, modelB, dbIdB).matrix[0];
    if (transMatA) {
      positionA = transMatA.getPosition().clone();
    } else {
      positionA = new THREE.Vector3();
    }
    if (transMatB) {
      positionB = transMatB.getPosition().clone();
    } else {
      positionB = new THREE.Vector3();
    }
    positionB.x = positionB.x -this.props.viewer.impl.model.myData.globalOffset.x
    positionB.y = positionB.y - this.props.viewer.impl.model.myData.globalOffset.y
    positionB.z = positionB.z - this.props.viewer.impl.model.myData.globalOffset.z
    if (check) {
      if (positionA.x !== positionB.x || positionA.y !== positionB.y || positionA.z !== positionB.z) {
        temp = { id: dbIdA, newProperties: propsA, oldProperties: propsB, newPosition: positionA, oldPosition: positionB, status: 'property and postion' }
      } else {
        temp = { id: dbIdA, newProperties: propsA, oldProperties: propsB, newPosition: positionA, oldPosition: positionB, status: 'property' }
      }
    } else {
      if (positionA.x !== positionB.x || positionA.y !== positionB.y || positionA.z !== positionB.z) {
        temp = { id: dbIdA, newProperties: propsA, oldProperties: propsB, newPosition: positionA, oldPosition: positionB, status: 'position' }
      }
    }

    return temp
  }
  groupParameterModify = (id, name, propsA, propsB, modelName) => {
    let listCategorySelected = []
    let tempParameter = []
    _.forEach(this.state.listParameterSelected, v => {
      tempParameter.push(v.displayName)
    })
    for (var i in propsA) {
      if (propsB[i]) {
        if (propsA[i].displayName === "Category") {
          if (!listCategorySelected.includes(propsA[i].displayValue)) {
            var categoryName = propsA[i].displayValue;
            var countParameter = Object.keys(propsA).length
            var listParameterNoExist = []

            if (!dataGeneralTableGroup[id + "//" + modelName]) {
              dataGeneralTableGroup[id + "//" + modelName] = [];
            }
            dataGeneralTableGroup[id + "//" + modelName].push({
              category: categoryName, parameter: 'Name', value: name, units: ''
            });
            listParameterNoExist.push('Name')
            dataGeneralTableGroup[id + "//" + modelName].push({
              category: categoryName, parameter: 'Compare Status', value: 'Modify', units: ''
            });
            listParameterNoExist.push('Compare Status')
            for (var j in propsA) {
              if (tempParameter.includes(propsA[j].displayName)) {
                if (propsA[j].units !== null) {
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: propsA[j].displayName + ' Current'
                    , value: propsA[j].displayValue, units: propsA[j].units
                  });
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: propsB[j].displayName + ' Old'
                    , value: propsB[j].displayValue, units: propsB[j].units
                  });
                  listParameterNoExist.push(propsA[j].displayName)
                }
              }
              storeParameterUnits[propsA[j].displayName] = (propsA[j].units)
              countParameter--
              if (countParameter === 0) {
                _.forEach(tempParameter, (v) => {
                  if (!listParameterNoExist.includes(v)) {
                    if (!dataGeneralTableGroup[id + "//" + modelName]) {
                      dataGeneralTableGroup[id + "//" + modelName] = [];
                    }
                    if (storeParameterUnits[v] === '') {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 'N/A', units: storeParameterUnits[v]
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 'N/A', units: storeParameterUnits[v]
                      });
                    }
                    else {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 0, units: storeParameterUnits[v]
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 0, units: storeParameterUnits[v]
                      });
                    }

                  }
                })
              }
            }
            break;
          }
        }

      }
    }
  }
  groupParameterNewDeleted = (id, name, properties, modelName, status) => {
    let listCategorySelected = []
    let tempParameter = []
    _.forEach(this.state.listParameterSelected, v => {
      tempParameter.push(v.displayName)
    })
    for (var i in properties) {
      if (properties[i].displayName === "Category") {
        if (!listCategorySelected.includes(properties[i].displayValue)) {
          var categoryName = properties[i].displayValue;
          var countParameter = Object.keys(properties).length
          var listParameterNoExist = []

          if (!dataGeneralTableGroup[id + "//" + modelName]) {
            dataGeneralTableGroup[id + "//" + modelName] = [];
          }
          dataGeneralTableGroup[id + "//" + modelName].push({
            category: categoryName, parameter: 'Name', value: name, units: ''
          });
          listParameterNoExist.push('Name')
          dataGeneralTableGroup[id + "//" + modelName].push({
            category: categoryName, parameter: 'Compare Status', value: status, units: ''
          });
          listParameterNoExist.push('Compare Status')
          for (var j in properties) {
            if (tempParameter.includes(properties[j].displayName)) {
              if (properties[j].units !== null) {
                if (status === 'New') {
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: properties[j].displayName + ' Current'
                    , value: properties[j].displayValue, units: properties[j].units, color: 'green'
                  });
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: properties[j].displayName + ' Old'
                    , value: properties[j].units === '' ? '' : 0, units: properties[j].units === '' ? '' : properties[j].units, color: 'green'
                  });
                } else if (status === 'Deleted') {
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: properties[j].displayName + ' Current'
                    , value: properties[j].units === '' ? '' : 0, units: properties[j].units === '' ? '' : properties[j].units, color: 'red'
                  });
                  dataGeneralTableGroup[id + "//" + modelName].push({
                    category: categoryName, parameter: properties[j].displayName + ' Old'
                    , value: properties[j].displayValue, units: properties[j].units, color: 'red'
                  });
                }
                listParameterNoExist.push(properties[j].displayName)
              }
            }
            storeParameterUnits[properties[j].displayName] = (properties[j].units)
            countParameter--
            if (countParameter === 0) {
              _.forEach(tempParameter, (v) => {
                if (!listParameterNoExist.includes(v)) {
                  if (!dataGeneralTableGroup[id + "//" + modelName]) {
                    dataGeneralTableGroup[id + "//" + modelName] = [];
                  }
                  if (status === 'New') {
                    if (storeParameterUnits[v] === '') {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 'N/A', units: storeParameterUnits[v], color: 'green'
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 'N/A', units: storeParameterUnits[v], color: 'green'
                      });
                    }
                    else {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 0, units: storeParameterUnits[v], color: 'green'
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 0, units: storeParameterUnits[v], color: 'green'
                      });
                    }
                  } else if (status === 'Deleted') {
                    if (storeParameterUnits[v] === '') {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 'N/A', units: storeParameterUnits[v], color: 'red'
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 'N/A', units: storeParameterUnits[v], color: 'red'
                      });
                    }
                    else {
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Current'
                        , value: 0, units: storeParameterUnits[v], color: 'red'
                      });
                      dataGeneralTableGroup[id + "//" + modelName].push({
                        category: categoryName, parameter: v + ' Old'
                        , value: 0, units: storeParameterUnits[v], color: 'red'
                      });
                    }
                  }

                }
              })
            }
          }
          break;
        }
      }
    }
  }
  generateTable = () => {
    let dataGeneralTable = [];
    let listParameterSelectedId = []
    var tempToMatchMissingParameter = null
    for (let key in dataGeneralTableGroup) {
      if (listParameterSelectedId.length === dataGeneralTableGroup[key].length) {
        tempToMatchMissingParameter = dataGeneralTableGroup[key]
        break
      }
    }
    for (let key in dataGeneralTableGroup) {
      _.forEach(dataGeneralTableGroup[key], (v) => {
        if (v.units === undefined) {
          if (storeParameterUnits[v.parameter] === '') {
            v.value = 'N/A'
            v.units = storeParameterUnits[v.parameter]
          } else {
            v.value = 0
            v.units = storeParameterUnits[v.parameter]
          }
        }
      })

      if (listParameterSelectedId.length > dataGeneralTableGroup[key].length) {
        for (let i in tempToMatchMissingParameter) {
          if (tempToMatchMissingParameter[i].parameter !== dataGeneralTableGroup[key][i]) {
            if (tempToMatchMissingParameter[i].units === '') {
              let tempValue = {
                category: tempToMatchMissingParameter[i].category, parameter: tempToMatchMissingParameter[i].parameter
                , value: '', units: '', color: tempToMatchMissingParameter[i].color
              };
              dataGeneralTableGroup[key].splice(i, 0, tempValue)
            } else {
              let tempValue = {
                category: tempToMatchMissingParameter[i].category, parameter: tempToMatchMissingParameter[i].parameter
                , value: 0, units: tempToMatchMissingParameter[i].units, color: tempToMatchMissingParameter[i].color
              };
              dataGeneralTableGroup[key].splice(i, 0, tempValue)
            }

          }
        }
      }
    }

    var listDataTemp = []

    for (var key in dataGeneralTableGroup) {
      listDataTemp[key] = []
      for (var i in dataGeneralTableGroup[key]) {


        if (dataGeneralTableGroup[key][i].units !== '') {
          var valueRound = parseFloat(dataGeneralTableGroup[key][i].value).toFixed(2)
          listDataTemp[key].push({
            "ElementId": key, "Category": dataGeneralTableGroup[key][i].category,
            [dataGeneralTableGroup[key][i].parameter.toString()]: parseFloat(valueRound), color: dataGeneralTableGroup[key][i].color
          })
        }
        else
          listDataTemp[key].push({
            "ElementId": key, "Category": dataGeneralTableGroup[key][i].category,
            [dataGeneralTableGroup[key][i].parameter.toString()]: dataGeneralTableGroup[key][i].value, color: dataGeneralTableGroup[key][i].color
          })
      }
    }
    for (let key in listDataTemp) {
      let temp = {}
      temp = listDataTemp[key].reduce(((r, c) => Object.assign(r, c)), {})
      dataGeneralTable.push(temp)
    }

    // !columns

    let listParameterSort = [{ displayName: 'ElementId' }, { displayName: 'Compare Status' }, { displayName: 'Category' }]
    this.state.listParameterSelected.forEach(parameter => {
      listParameterSort.push(parameter)
    })
    var columns = []
    for (let i in listParameterSort) {
      if (listParameterSort[i].displayName === 'ElementId')
        columns.push({
          Header: listParameterSort[i].displayName, accessor: listParameterSort[i].displayName,
          id: listParameterSort[i].displayName,
          aggregate: 'count',
          Aggregated: ({ cell: { value } }) => `${value}`
        },
        )
      else if (listParameterSort[i].displayName === 'Category')
        columns.push({
          Header: listParameterSort[i].displayName, accessor: listParameterSort[i].displayName,
          id: listParameterSort[i].displayName,
          units: false,
          aggregate: 'count',
          filter: "includes",
          Aggregated: ({ cell: { row } }) => (
            `${getSubRowsCount(row.subRows)}`
          )
        })
      else if (listParameterSort[i].displayName === 'Compare Status')
        columns.push({
          Header: listParameterSort[i].displayName, accessor: listParameterSort[i].displayName,
          id: listParameterSort[i].displayName,
          units: false,
          aggregate: 'count',
          filter: "includes",
          Aggregated: ({ cell: { row } }) => (
            `${getSubRowsCount(row.subRows)}`
          )
        })
      else {
        if (listParameterSort[i].units === '') {
          columns.push(
            {
              Header: listParameterSort[i].displayName,
              columns: [
                {
                  Header: listParameterSort[i].displayName + ' Old', accessor: listParameterSort[i].displayName + ' Old',
                  id: listParameterSort[i].displayName + ' Old',
                  units: false,
                  aggregate: 'count',
                  filter: "includes",
                  Aggregated: ({ cell: { row } }) => {
                    return (
                      <div style={{ color: row.groupByVal === 'New' ? 'green' : row.groupByVal === 'Deleted' && 'red' }}>
                        {`${getSubRowsCount(row.subRows)}`}
                      </div>
                    )
                  },
                },
                {
                  Header: listParameterSort[i].displayName + ' Current', accessor: listParameterSort[i].displayName + ' Current',
                  id: listParameterSort[i].displayName + ' Current',
                  units: false,
                  aggregate: 'count',
                  filter: "includes",
                  Aggregated: ({ cell: { row } }) => {
                    return (
                      <div style={{ color: row.groupByVal === 'New' ? 'green' : row.groupByVal === 'Deleted' && 'red' }}>
                        {`${getSubRowsCount(row.subRows)}`}
                      </div>
                    )
                  },
                },
                {
                  Header: 'Change', accessor: 'Change',
                  id: 'Change',
                  units: false,
                  aggregate: 'count',
                  filter: "includes",
                  // Cell: ({ cell: { row, value } }) => {
                  //   return (
                  //     (!_.isNumber(value)) &&
                  //     <>
                  //       <Icon icon='exclamation-triangle' style={{
                  //         color: value === 'Very urgent' ? 'red' :
                  //           value === 'Urgent' ? 'orange' : value === 'Normal' ? '#f4d90b' : 'blue'
                  //       }} />
                  //       <Text strong >{' '}{sliceString(value, 6)}</Text>
                  //     </>
                  //   )
                  // },
                  // Aggregated: ({ cell: { row } }) => (
                  //   `${getSubRowsCount(row.subRows)}`
                  // )
                }
              ]
            })
        } else {
          columns.push(
            {
              Header: `${listParameterSort[i].displayName} (${listParameterSort[i].units})`,
              columns: [
                {
                  Header: `${listParameterSort[i].displayName} Old`, accessor: listParameterSort[i].displayName + ' Old',
                  id: listParameterSort[i].displayName + ' Old',
                  units: true,
                  aggregate: 'sum',
                  filter: 'between',
                  Cell: ({ cell: { row, value } }) => {
                    let check = row.values[listParameterSort[i].displayName + ' Current'] >= value
                    return (
                      <div style={{ color: row.original.color ? row.original.color : check ? 'red' : 'green' }}>
                        {`${formatNumber(value)}`}
                      </div>
                    )
                  },
                  Footer: info => {
                    const total = React.useMemo(
                      () => getSubRowsTotal(info.flatRows, info.column.id),
                      [info.rows]
                    )
                    return (
                      <div> {formatNumber(total)}</div>
                    )
                  },
                  Aggregated: ({ cell: { row, value } }) => {
                    let check = row.values[listParameterSort[i].displayName + ' Current'] >= value
                    return (
                      <div style={{ color: row.id.includes('Compare Status:New') ? 'green' : row.id.includes('Compare Status:Deleted') ? 'red' : check ? 'red' : 'green' }}>
                        {`${formatNumber(value)}`}
                      </div>
                    )
                  },
                },
                {
                  Header: `${listParameterSort[i].displayName} Current`, accessor: listParameterSort[i].displayName + ' Current',
                  id: listParameterSort[i].displayName + ' Current',
                  units: true,
                  aggregate: 'sum',
                  filter: 'between',
                  Cell: ({ cell: { row, value } }) => {
                    let check = value <= row.values[listParameterSort[i].displayName + ' Old']
                    return (
                      <div style={{ color: row.original.color ? row.original.color : check ? 'red' : 'green' }}>
                        {`${formatNumber(value)}`}
                      </div>
                    )
                  },
                  Footer: info => {
                    const total = React.useMemo(
                      () => getSubRowsTotal(info.flatRows, info.column.id),
                      [info.rows]
                    )
                    return <> {formatNumber(total)}</>
                  },
                  Aggregated: ({ cell: { row, value } }) => {
                    let check = value <= row.values[listParameterSort[i].displayName + ' Old']
                    return (
                      <div style={{ color: row.id.includes('Compare Status:New') ? 'green' : row.id.includes('Compare Status:Deleted') ? 'red' : check ? 'red' : 'green' }}>
                        {`${formatNumber(value)}`}
                      </div>
                    )
                  },
                },
                {
                  Header: 'Different', accessor: listParameterSort[i].displayName + '_Different',
                  id: listParameterSort[i].displayName + '_Different',
                  units: false,
                  aggregate: 'sum',
                  filter: "between",
                  Cell: ({ cell: { row } }) => {
                    let temp = row.values[listParameterSort[i].displayName + ' Current'] - row.values[listParameterSort[i].displayName + ' Old']
                    row.values[listParameterSort[i].displayName + '_Different'] = temp
                    return (
                      <div style={{ color: temp < 0 ? 'red' : 'green' }}>
                        {`${formatNumber(temp)}`}
                      </div>
                    )
                  },
                  Footer: info => {
                    const total = React.useMemo(
                      () => getSubRowsDifferentTotal(info.flatRows, info.column.id),
                      [info.rows]
                    )

                    return <> {formatNumber(total)}</>
                  },
                  Aggregated: ({ cell: { row } }) => {
                    let temp = row.values[listParameterSort[i].displayName + ' Current'] - row.values[listParameterSort[i].displayName + ' Old']
                    row.values[listParameterSort[i].displayName + '_Different'] = temp
                    return (
                      <div style={{ color: temp < 0 ? 'red' : 'green' }}>
                        {`${formatNumber(temp)}`}
                      </div>
                    )
                  },
                }
              ]
            }
          )
        }
      }

    }

    this.setState({
      columnsPanelSchedule: columns,
      dataPanelSchedule: dataGeneralTable,
      listFullData: dataGeneralTable,
      displayTableCompare: true,
      loadingBtnCompare: false,
      viewableSelected: null,
      maxForgeViewer: $('#splitForgeViewer').height(),
      modifyForgeViewer: $('#splitForgeViewer').height() - this.state.heightDock
    }, () => {
      $('#splitForgeViewer').height(this.state.modifyForgeViewer)
      // this.props.viewer.resize()
      this.props.onChangeDisplayComparisonModel(true)
    })

  }
  //#region // ?table
  onChangeHeader = (id, text) => {
    let temp = []
    _.forEach(this.state.columnsPanelSchedule, v => {
      if (id == v.id) {
        v.filter = text
        temp.push(v)
      } else {
        temp.push(v)
      }
    })
    this.setState({ columnsPanelSchedule: temp })
  }
  onIsolateRows = (data) => {
    let temp = []
    if (!this.state.activeButtonIsolateRows) {
      _.forEach(this.state.dataPanelSchedule, v => {
        if (data.includes(v.ElementId))
          temp.push(v)
      })
      this.setState({ dataPanelSchedule: temp, activeButtonIsolateRows: true })
    } else {
      this.setState({ dataPanelSchedule: this.state.listFullData, activeButtonIsolateRows: false })
    }

  }
  //#endregion

  //#region  // ?general
  handleCloseCompareVersion = () => {
    const models = this.props.viewer.impl.modelQueue().getModels();
    this.props.viewer.showAll()
    _.forEach(models, (v) => {
      this.props.viewer.clearThemingColors(v);
      if (v.myData.loadOptions.modelNameOverride === 'Compare Model') {
        this.props.viewer.unloadModel(v)
      }
    })
    this.setState({
      displayTableCompare: false, activeButtonDock: false,
    }, () => {
      this.props.onChangeDisplay('dialogCompareQuantities', false)
      this.props.onChangeDisplayComparisonModel(false)
      $('#splitForgeViewer').height(this.state.maxForgeViewer)
      this.props.viewer.resize()
      $('#btn-docbrowser').show();
      this.props.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionBinded);
    })
  }
  sizeChange = (e) => {
    if (this.state.displayTableCompare) {
      this.setState({
        heightDock: e,
        modifyForgeViewer: this.state.maxForgeViewer - e
      }, () => {
        $('#splitForgeViewer').height(this.state.maxForgeViewer - e)
        this.props.viewer.resize()
      })
    }
  }
  onChangeDockDisplay = (value) => {
    this.setState({ activeButtonDock: value }, () => {
      if (this.state.activeButtonDock)
        this.props.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionBinded)
      else
        this.props.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionBinded);
    })
  }
  handleOpenDock = () => {
    this.setState({ activeButtonDock: !this.state.activeButtonDock }, () => {
      if (this.state.activeButtonDock)
        this.props.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionBinded)
      else
        this.props.viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionBinded);
    })
  }
  onSelectionBinded = () => {
    let temp = []
    var currSelection = this.props.viewer.getSelection();
    if (currSelection.length !== 0) {
      this.props.viewer.model.getProperties(currSelection[0], async (modelAProperty) => {
        let extId = modelAProperty.externalId
        if (listB[extId] !== undefined) {
          let check = false
          var dbIdA = listA[extId].dbId
          var propsA = listA[extId].properties
          var propsB = listB[extId].properties
          _.forEach(propsA, (v, k) => {
            if (propsB[k]) {
              if (propsA[k].displayValue !== propsB[k].displayValue) {
                temp.push({ id: dbIdA, element: modelAProperty.name, name: propsA[k].displayName, newProperties: propsA[k].displayValue, oldProperties: propsB[k].displayValue, status: 'changed' })
                check = true
              }
            } else {
              temp.push({ id: dbIdA, element: modelAProperty.name, name: propsA[k].displayName, newProperties: propsA[k].displayValue, oldProperties: '', status: 'new' })
              check = true
            }
          })
          // temp.push(this.comparePosition(dbIdA, dbIdB, check))
        }
        this.setState({ dataCompareParameter: temp })
      })

    } else {
      this.setState({ dataCompareParameter: [] })
    }

  }
  handleDisplayStatus = (name, e, { checked }) => {
    this.setState(prevState => ({
      displayStatus: {
        ...prevState.displayStatus,
        [name]: checked,
      }
    }), () => {
      this.props.viewer.clearThemingColors(modelA)
      this.props.viewer.clearThemingColors(modelB)
      if (this.state.displayStatus.new)
        _.forEach(added, v => {
          this.props.viewer.impl.visibilityManager.show(v.id, modelA);
          this.props.viewer.setThemingColor(v.id, green, modelA);
        })
      else
        _.forEach(added, v => {
          this.props.viewer.impl.visibilityManager.hide(v.id, modelA);
        })

      if (this.state.displayStatus.deleted)
        _.forEach(removed, v => {
          this.props.viewer.impl.visibilityManager.show(v.id, modelB);
          this.props.viewer.setThemingColor(v.id, red, modelB);
        })
      else
        _.forEach(removed, v => {
          this.props.viewer.impl.visibilityManager.hide(v.id, modelB);
        })

      if (this.state.displayStatus.changed)
        _.forEach(modified, v => {
          this.props.viewer.impl.visibilityManager.show(v.id, modelA);
          this.props.viewer.setThemingColor(v.id, orange, modelA);
        })
      else
        _.forEach(modified, v => {
          this.props.viewer.impl.visibilityManager.hide(v.id, modelA);
        })

      if (this.state.displayStatus.unChange)
        _.forEach(unChange, v => {
          this.props.viewer.impl.visibilityManager.show(v.id, v.model);
        })
      else
        _.forEach(unChange, v => {
          this.props.viewer.impl.visibilityManager.hide(v.id, v.model);
        })
      //!test
      // _.forEach(modified, v => {
      //   if (this.state.displayStatus.changed) {
      //     if (v.status === 'property') {
      //       this.props.viewer.impl.visibilityManager.show(v.id, modelA);
      //       this.props.viewer.setThemingColor(v.id, orange, modelA);
      //     }
      //   } else {
      //     if (v.status === 'property')
      //       this.props.viewer.impl.visibilityManager.hide(v.id, modelA);
      //   }

      //   if (this.state.displayStatus.changedPosition) {
      //     if (v.status === 'postion') {
      //       this.props.viewer.impl.visibilityManager.show(v.id, modelA);
      //       this.props.viewer.setThemingColor(v.id, orange, modelA);
      //     }
      //   } else {
      //     if (v.status === 'postion')
      //       this.props.viewer.impl.visibilityManager.hide(v.id, modelA);
      //   }

      //   if (this.state.displayStatus.changedPosition || this.state.displayStatus.changed) {
      //     if (v.status === 'property and postion') {
      //       this.props.viewer.impl.visibilityManager.show(v.id, modelA);
      //       this.props.viewer.setThemingColor(v.id, orange, modelA);
      //     }
      //   } else {
      //     if (v.status === 'property and postion')
      //       this.props.viewer.impl.visibilityManager.hide(v.id, modelA);
      //   }
      // })
    })
  }
  //#endregion



  render() {

    return (
      <div>
        <Modal show={this.state.openPanel} onHide={this.handleClosePanel.bind(this)} style={{ paddingTop: '3%' }} backdrop="static">
          <Modal.Header>
            <Modal.Title>Compare Version</Modal.Title>
          </Modal.Header>
          <Modal.Body id='modalBodyCompareVersion'>
            {this.state.loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical />}
            <Input value={this.state.defaultId} style={{ width: '100%' }} disabled={true} />
            <Divider horizontal>VS</Divider>
            <Dropdown
              placeholder='Select Version'
              fluid
              selection
              onChange={this.handleChangeVersion}
              options={this.state.listVersion}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button color='blue' onClick={this.handleSelectViewable} disabled={this.state.versionCompareId === null} loading={this.state.loadingBtnVersion}>
              Apply
            </Button>
            <Button onClick={this.handleClosePanel.bind(this)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.openPanelListViewable} onHide={this.handleClosePanelListViewable.bind(this)} size='xs' overflow={true} backdrop="static">
          <Modal.Header>
            <Modal.Title>List Viewables</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <SelectPicker
              data={this.state.listViewable}
              style={{ width: '100%' }}
              defaultValue={this.state.viewableCurrent}
              groupBy="group"
              placeholder="Select View"
              cleanable={false}
              onChange={this.handleChangeView}
              renderMenuItem={(label, item) => {
                return (
                  <div>
                    <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
                  </div>
                );
              }}
              renderMenuGroup={(label, item) => {
                return (
                  <div>
                    <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
                  </div>
                );
              }}
              renderValue={(value, item) => {
                return (
                  <div>
                    <span style={{ color: '#000000' }}>
                      <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} />{item.group === '3D' ? " View3D" : " Sheet"} :
              </span>{' '}
                    {item.label}
                  </div>
                );
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} onClick={this.handleCompareVersion.bind(this)} appearance="primary"
              disabled={this.state.viewableSelected === null} loading={this.state.loadingBtnCompare}>
              Compare
            </Button>
            <Button onClick={this.close} onClick={this.handleClosePanelListViewable.bind(this)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Dock position={'bottom'} isVisible={this.state.displayTableCompare} dimMode={'none'} fluid={false} zIndex={200} size={this.state.heightDock}
          onSizeChange={this.sizeChange} >
          <TableCompare columns={this.state.columnsPanelSchedule} data={this.state.dataPanelSchedule}
            viewer={this.props.viewer}
            tempListModel={tempListModel}
            getRowProps={() => ({})}
            getCellProps={() => ({})}
            onChangeHeader={this.onChangeHeader}
            onIsolateRows={this.onIsolateRows}
            activeButtonIsolateRows={this.state.activeButtonIsolateRows}
            activeButtonDock={this.state.activeButtonDock}
            handleOpenDock={this.handleOpenDock}
          />
        </Dock>
        {this.state.displayTableCompare &&
          <IconButton style={{ zIndex: '10', float: "right", backgroundColor: "red", top: '0', color: 'white' }} size='sm' onClick={this.handleCloseCompareVersion}
            icon={<Icon icon="close" />} ></IconButton>}
        <div style={{
          zIndex: '1', position: 'fixed', top: this.props.heightNavigation === '0px' ? '0px' : '53.78px', left: '45px',
          display: this.state.displayTableCompare ? "block" : 'none'
        }}>

          <Checkbox label={'New'} id='custom-checkbox-new'
            checked={this.state.displayStatus.new}
            style={{ fontSize: 'small' }} onChange={this.handleDisplayStatus.bind(this, 'new')} />
          <br />
          <Checkbox label={'Changed'} id='custom-checkbox-changed'
            checked={this.state.displayStatus.changed}
            style={{ fontSize: 'small' }} onChange={this.handleDisplayStatus.bind(this, 'changed')} />
          <br />
          {/* <Checkbox label={'Changed: Position'} id='custom-checkbox-changed-position'
            checked={this.state.displayStatus.changedPosition}
            style={{ fontSize: 'small' }} onChange={this.handleDisplayStatus.bind(this, 'changedPosition')} />
          <br /> */}
          <Checkbox label={'Deleted'} id='custom-checkbox-deleted'
            checked={this.state.displayStatus.deleted}
            style={{ fontSize: 'small' }} onChange={this.handleDisplayStatus.bind(this, 'deleted')} />

          <br />
          <Checkbox label={'UnChange'} id='custom-checkbox'
            checked={this.state.displayStatus.unChange}
            style={{ fontSize: 'small' }} onChange={this.handleDisplayStatus.bind(this, 'unChange')} />
        </div>


        <CompareDock viewer={this.props.viewer} openDock={this.state.activeButtonDock} onChangeDockDisplay={this.onChangeDockDisplay}
          activeButtonDock={this.state.activeButtonDock} data={this.state.dataCompareParameter} />
      </div>

    );
  }
}


export default DialogCompare