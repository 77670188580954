import React, { useState, useEffect } from "react";
import { LineChart, Line, ResponsiveContainer, LabelList, XAxis, YAxis, Legend, CartesianGrid } from "recharts";

const ChartAllSxFPerMonth = ({ values }) => {
   return (
      <div>
         <h6>Total SxF By Month </h6>
         <ResponsiveContainer width="100%" minHeight={"17vh"}>
            <LineChart data={values} margin={{ top: 15, right: 5, left: 5, bottom: 20 }}>
               <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
               <XAxis dataKey="name" />
               <YAxis />

               <Line connectNulls type="monotone" dataKey="All" stroke="#8884d8" strokeWidth="2">
                  <LabelList dataKey="All" position="top" />
               </Line>
            </LineChart>
         </ResponsiveContainer>
      </div>
   );
};

export default ChartAllSxFPerMonth;
