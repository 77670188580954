import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import {
  Table,
  Icon,
  Pagination,
  notification,
  DatePicker,
  Button,
  Input,
  InputNumber,
  Form,
  Divider,
  Tag,
  Checkbox,
  Popconfirm,
  TimePicker,
  Result,
  Switch,
} from 'antd';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import _ from 'lodash';
import {
  getAllShiftsByAllocations,
  updateShiftsToWorker,
  setSummits,
  updateIsWorkOverLunch,
  updateIsDayOff,
} from '../../api/workerallocation';
import { search } from '../../api/costCode';
import moment from 'moment';
import { Select } from 'antd';

const { Option } = Select;

const { Column } = Table;

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const format = 'HH:mm';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'select') {
      let opt = Array.isArray(this.props.costCodes)
        ? this.props.costCodes.map((d) => (
            <Option value={d.id} key={d.name}>
              {d.name + '-' + d.description}
            </Option>
          ))
        : [];
      return <Select className='w-100'>{opt}</Select>;
    }
    if (this.props.inputType === 'time') {
      return (
        <TimePicker allowClear={false} format={format} className='w-100' />
      );
    }

    if (this.props.inputType === 'select-engineer') {
      let optEngineers = Array.isArray(this.props.supervisors)
        ? this.props.supervisors.map((d) => (
            <Option value={d.id} key={d.name}>
              {d.name}
            </Option>
          ))
        : [];
      return <Select className='w-100'>{optEngineers}</Select>;
    }

    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue:
                this.props.inputType === 'time'
                  ? moment(record[dataIndex], format)
                  : record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

function ShiftDataTable(props) {
  const [rows, setRows] = useState([]);
  const [isWorkerOverLunch, setIsWorkOverLunch] = useState(false);
  const [isDayOff, setIsDayOff] = useState(false);
  const [costCodes, setCostCodes] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [time, setTime] = useState([
    moment(props.date, 'YYYY-MM-DD'),
    moment(props.date, 'YYYY-MM-DD'),
  ]);

  function isEditing(record) {
    return record.id === editingKey;
  }

  useEffect(() => {
    getDatas();
  }, [props.idAllocations, props.date, time]);

  useEffect(() => {
    getCostCodes();
  }, [props.projectId]);

  useEffect(() => {
    setTime([
      moment(props.date, 'YYYY-MM-DD'),
      moment(props.date, 'YYYY-MM-DD'),
    ]);
  }, [props.date]);

  function onChange(date, dateString) {
    setTime(date);
  }

  function getMinMaxTime(list, isMin = true) {
    if (_.isArray(list)) {
      let ordered = _.orderBy(
        list.map((x) => ({
          h: x,
          hours: moment(x, 'HH:mm:ss').hours(),
        })),
        'hours'
      );

      if (isMin) {
        return ordered[0].h;
      }

      return ordered[ordered.length - 1].h;
    }
  }

  async function getCostCodes() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 10000,
      projectId: props.projectId,
    };

    let res = await search(payload);
    let datas = res.data.items;

    setCostCodes(datas);
  }

  function isAllSubmitted() {
    return (
      groupData &&
      Array.isArray(groupData) &&
      groupData.length > 0 &&
      groupData.every((x) => x.isSubmit)
    );
  }

  async function getDatas() {
    let res = await getAllShiftsByAllocations({
      idWorkers: props.idWorkers,
      start: time[0].format('DD-MM-YYYY'),
      end: time[1].format('DD-MM-YYYY'),
    });

    if (_.isArray(res.data) && res.data.length > 0) {
      setRows(res.data);
      let dic = _.groupBy(res.data, 'idWorker');
      let group = Object.keys(dic).map((key, index) => ({
        name: dic[key][0].name,
        supervisor: dic[key][0].supervisor,
        idSupervisor: dic[key][0].idSupervisor,
        idAllocation: dic[key][0].idAllocation,
        isSubmit: dic[key][0].isSubmit,
        key: key + index,
        isGroup: true,
        nt: _.sumBy(dic[key], 'nt'),
        ot: _.sumBy(dic[key], 'ot'),
        bp: _.sumBy(dic[key], 'bp'),
        icp: _.sumBy(dic[key], 'icp'),
        start: getMinMaxTime(dic[key].map((x) => x.start)),
        end: getMinMaxTime(dic[key].map((x) => x.end, false)),
        costCode: dic[key].map((x) => x.costCode).toString(),
        children: _.orderBy(dic[key], ['date', 'start']).map((x, subIndex) => ({
          ...x,
          isGroup: false,
          key: key + 'c' + index + 's' + subIndex,
        })),
      }));

      group = _.orderBy(group, 'key');

      await setGroupData(group);
    } else {
      await setGroupData([]);
    }

    setRows(res.data);
  }

  function renderRound2Number(h) {
    return h && <p>{Math.round(h * 10) / 10}</p>;
  }
  function renderGroupKey(e, key) {
    return e.isGroup && <label>{e.name}</label>;
  }

  async function handleUpdate(form, key) {
    form.validateFields(async (error, row) => {
      if (error) {
        return;
      }

      try {
        let element = rows.find((x) => x.id == editingKey);
        if (element) {
          let payload = {
            start: row.start.format(format),
            end: row.end.format(format),
            idAllocation: element.idAllocation,
            idLevel: element.idLevel,
            idZone: element.idZone,
            idSupervisor: row.idSupervisor,
            idTask: row.idTask,
            action: 2,
            id: element.id,
          };

          if (element.idSupervisor !== row.idSupervisor) {
            props.performReload();
          }

          await updateShiftsToWorker([payload]);
          setEditingKey('');
          await getDatas();
        }
      } catch (error) {
        notification['error']({
          message: 'Error',
          description: error?.response?.data?.message,
        });
      }
    });
  }

  function edit(record) {
    setEditingKey(record.id);
    setIsWorkOverLunch(record.isWorkOverLunch);
  }

  function cancel() {
    setEditingKey('');
  }

  async function confirmSubmit() {
    props.performReload();
    let isSubmit = !isAllSubmitted();
    await setSummits(props.idAllocations, isSubmit);
    console.log('groupData', groupData);
    await getDatas();
  }

  function onChangeOverLunch(checked, record) {
    setIsWorkOverLunch(checked);
    //cal api

    updateIsWorkOverLunch({
      id: record.idAllocation,
      isWorkOverLunch: checked,
    }).then((r) => {});
  }
  function onChangeDayOff(checked, record) {
    setIsDayOff(checked);
    //cal api

    updateIsDayOff({
      id: record.idAllocation,
      isDayOff: checked,
    }).then((r) => {});
  }

  let columns = [
    {
      title: 'Worker',
      key: 'name',
      render: (text, record) => renderGroupKey(record, 'name'),
      width: 200,
    },
    {
      title: 'Supervisor',
      key: 'idSupervisor',
      dataIndex: 'idSupervisor',
      render: (text, record) => record.supervisor,
      inputType: 'select-engineer',
      editable: true,
      width: 150,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      width: 100,
      editable: true,
      inputType: 'time',
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      width: 100,
      editable: true,
      inputType: 'time',
      render: (text, record) => {
        let s = record.end;
        s += record.endDate == 'tomorrow' ? ' tmr' : '';
        return s;
      },
    },
    {
      title: 'Over Lunch',
      dataIndex: 'isWorkOverLunch',
      key: 'isWorkOverLunch',
      render: (text, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <Switch
              checked={isWorkerOverLunch}
              onChange={(e) => onChangeOverLunch(e, record)}
            />
          );
        } else {
          if (!record.isGroup) {
            return record.isWorkOverLunch === true ? 'Yes' : 'No';
          }
        }
      },
      width: 100,
    },
    {
      title: 'Day Off',
      dataIndex: 'isDayOff',
      key: 'isDayOff',
      render: (text, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <Switch
              checked={isDayOff}
              onChange={(e) => onChangeDayOff(e, record)}
            />
          );
        } else {
          if (!record.isGroup) {
            return record.isDayOff === true ? 'Yes' : 'No';
          }
        }
      },
      width: 100,
    },
    {
      title: 'Cost Code',
      dataIndex: 'idTask',
      key: 'costCode',
      editable: true,
      inputType: 'select',
      render: (text, record) => record.costCode,
    },
    {
      title: 'Activity',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'NT',
      dataIndex: 'nt',
      key: 'nt',
      render: renderRound2Number,
      width: 60,
    },
    {
      title: 'OT',
      dataIndex: 'ot',
      key: 'ot',
      render: renderRound2Number,
      width: 60,
    },
    {
      title: 'ICP (hrs)',
      dataIndex: 'icp',
      key: 'icp',
      render: renderRound2Number,
      width: 60,
    },
    // {
    //   title: "BP",
    //   dataIndex: "bp",
    //   key: "bp",
    //   render: renderRound2Number,
    //   width: 60,
    // }
    // ,
    {
      title: 'Action',
      width: '100px',
      dataIndex: 'action',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <EditableContext.Consumer>
              {(form) => (
                <div>
                  <Icon
                    disabled={editingKey !== ''}
                    onClick={() => handleUpdate(form, record.id)}
                    style={{ fontSize: '20px', color: '#08c' }}
                    type='check'
                  />
                  <Divider type='vertical' />
                  <Icon
                    disabled={editingKey !== ''}
                    onClick={() => cancel(record.id)}
                    style={{ fontSize: '20px', color: '#08c' }}
                    type='close'
                  />
                </div>
              )}
            </EditableContext.Consumer>
          </span>
        ) : (
          !record.isGroup && (
            <span>
              <Icon
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{ fontSize: '20px', color: '#08c' }}
                type='edit'
              />
            </span>
          )
        );
      },
    },
  ];

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const aColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        costCodes,
        supervisors: props.supervisors,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  function getSum(type = 'nt') {
    return Math.round(_.sumBy(rows, type) * 10) / 10;
  }

  return (
    <div>
      <div className='mb-4 d-flex justify-content-between align-items-center '>
        <div className='d-flex justify-content-start align-items-center '>
          <Button onClick={getDatas} type='primary' className='mr-2'>
            Search :
          </Button>

          <RangePicker value={time} format={'DD-MM-YYYY'} onChange={onChange} />
        </div>

        <div>
          <Popconfirm
            placement='topLeft'
            title={
              !isAllSubmitted()
                ? 'Are you sure to Submit this task?'
                : 'Are you sure to Unsubmit this task?'
            }
            onConfirm={confirmSubmit}
            okText='Yes'
            cancelText='No'
          >
            <Checkbox checked={isAllSubmitted()}>
              Is this form submitted?
            </Checkbox>
          </Popconfirm>
        </div>
      </div>

      <EditableContext.Provider value={props.form}>
        {_.isArray(groupData) && groupData.length > 0 ? (
          <Table
            components={components}
            className='antTable'
            dataSource={groupData}
            rowClassName='editable-row'
            bordered
            rowKey='key'
            columns={aColumns}
            scroll={{ x: 1000, y: 500 }}
            pagination={false}
          ></Table>
        ) : (
          <Result
            status='warning'
            title='Warning: there is not record at all in this report.'
          />
        )}
      </EditableContext.Provider>

      <div className='d-flex flex-row mt-2 justify-content-end'>
        <Tag color='#0088FE'>NT : {getSum('nt')}</Tag>
        <Tag color='#00C49F'>OT : {getSum('ot')} </Tag>
        <Tag color='#FFBB28'>BP : {getSum('bp')} </Tag>
        <Tag color='#FF8042'>ICP : {getSum('icp')} </Tag>
      </div>
    </div>
  );
}

const ShiftDataTableForm = Form.create()(ShiftDataTable);

export default ShiftDataTableForm;
