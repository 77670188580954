import React from 'react';
import { Button, Typography } from 'antd';
import { Icon } from 'rsuite';
import _ from 'lodash'
import { Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import moment from 'moment';
import { handleReactTableFilterDate } from '../function/TableFunction'
const { Text } = Typography
export default (_this, handleOpenControlVersionPanel) => {
  return ([

    {
      Header: 'Item Name', accessor: _this.state.isDisplayName ? 'itemDisplayName' : 'itemName', id: _this.state.isDisplayName ? 'itemDisplayName' : 'itemName', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => (
        row.original.status === 'success' ?
          <div style={{ overflow: 'hidden' }}>
            <Link to={{
              pathname: row.original.isTrimble ? `/projects/project/folder/view-trimble` : `/projects/project/folder/view`,
              state: {
                userCreatedProject: _this.props.location.state.userCreatedProject,
                id: row.original.item.objectIdDefault,
                itemId: row.original.item.itemId,
                itemName: row.original.item.itemName,
                itemDisplayName: row.original.itemDisplayName,
                folderId: _this.props.location.state.folderId,
                folderName: _this.props.location.state.folderName,
                projectId: _this.props.location.state.projectId,
                projectName: _this.props.location.state.projectName,
                sharePointId: _this.props.location.state.sharePointId,
                issueKey: _this.props.location.state.issueKey,
                trimbleKey: _this.props.location.state.trimbleKey,
                smartSheetId: row.original.smartSheetId,
                manningScheduleId: row.original.manningScheduleId,
                folderSmartSheetId: row.original.folderSmartSheetId,
                version: row.original.version,
                objectKey: row.original.objectKey,
                listAdmin: _this.props.location.state.listAdmin,
                typeProject: _this.props.location.state.typeProject,
                typeFile: row.original.typeFile,
                isDetail: true,
                isTrimble: row.original.isTrimble,
                trimbleFileId: row.original.trimbleFileId, trimbleVersionId: row.original.trimbleVersionId,
                useRfa: _this.props.location.state.useRfa,
              }
            }}>
              <Label style={{ float: 'left', marginRight: 5 }} size='mini' color={'green'}>
                <Icon size='lg' icon={row.original.isTrimble?'cubes': 'file'} > Item</Icon>
              </Label>
              <div style={{ color: 'black', verticalAlign: 'middle', display: 'inline' }}>
                {value === undefined ? row.original.item.itemName : value}</div>
            </Link>
          </div>

          :
          <div style={{ overflow: 'hidden' }}>
            <Label style={{ float: 'left', marginRight: 5 }} size='mini' color={row.original.status === 'no translate' ? 'orange' : row.original.status === 'in progress' ? 'blue' : "red"}  >
              <Icon size='lg' icon={row.original.isTrimble?'cubes': 'file'} > Item</Icon>
            </Label>
            <div style={{ color: 'black', verticalAlign: 'middle', display: 'inline' }}>
              {value === undefined ? row.original.item.itemName : value}</div>
          </div>
      )
    },
    {
      Header: 'Version', accessor: 'version', id: 'version', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => {
        let data = {
          itemId: row.original.item.itemId, objectIdDefault: row.original.item.objectIdDefault,
          itemName: row.original.item.itemName
        }
        return (
          <Label circular size='mini' onClick={handleOpenControlVersionPanel.bind(this,data)} style={{cursor:'pointer'}}
            color={row.original.status === 'no translate' ? 'orange' :
              row.original.status === 'success' ? 'green' :
                row.original.status === 'in progress' ? 'blue' : "red"}
          >{value}</Label>
        )
      }
    },
    {
      Header: 'Type', accessor: 'typeFile', id: 'typeFile', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => (
        <span>{value ? value.toUpperCase() : ''}</span>
      )
    },
    {
      Header: 'First Uploaded', accessor: 'createdDate', id: 'createdDate', units: false, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    {
      Header: 'Last Uploaded', accessor: 'uploadDate', id: 'uploadDate', units: false, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    // {
    //   Header: 'Status', accessor: 'status', id: 'Status', units: false, filter: "includes",
    //   Cell: ({ cell: { value } }) => (
    //     <Label size='mini'
    //       color={value === 'no translate' ? 'orange' :
    //         value === 'success' ? 'green' :
    //           value === 'in progress' ? 'blue' : "red"}
    //     >{value}</Label>
    //   )
    // },
    { Header: 'Upload by', accessor: 'userUpload', id: 'userUpload', units: false, filter: "includes", },
  ])
}