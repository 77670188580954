import React, { useState } from "react";
import { Table, Button, Icon, Checkbox } from "antd";
import dayjs from "dayjs";
import { Func_Export_Critical_Summary_By_Trade_To_Excel } from "../../function/func_export_to_excel";

const TableTradeSummary = ({ data, setTradeSelected }) => {
   var lastMonth = dayjs().subtract("1", "month").format("MMM");
   const [checkHighFail, setCheckHighFail] = useState(true);
   let highFailData = [];
   if (data !== undefined) {
      highFailData = data.filter((e) => e.percent < 92);
   }
   const columns = [
      {
         title: "Trade",
         dataIndex: "name",
         key: "name",
         align: "left",
         width: 160,
         ellipsis: true,
      },
      // {
      //    title: `${lastMonth}_no`,
      //    dataIndex: "total_no_current_month",
      //    key: "total_no_current_month",
      //    align: "right",
      //    width: 45,
      // },
      // {
      //    title: `${lastMonth}_1st`,
      //    dataIndex: "total_1st_current_month",
      //    key: "total_1st_current_month",
      //    align: "right",
      //    width: 50,
      // },
      // {
      //    title: `${lastMonth}_1plus`,
      //    dataIndex: "total_1plus_current_month",
      //    key: "total_1plus_current_month",
      //    align: "right",
      //    width: 50,
      // },
      // {
      //    title: `${lastMonth}_%`,
      //    dataIndex: "percent_current",
      //    key: "percent_current",
      //    align: "right",
      //    width: 45,
      // },

      {
         title: "no",
         dataIndex: "total_no",
         key: "total_no",
         align: "right",
         width: 40,
      },
      {
         title: "pass at 1st time",
         dataIndex: "total_1st",
         key: "total_1st",
         align: "right",
         width: 80,
      },
      {
         title: "pass at >1 time",
         dataIndex: "total_1plus",
         key: "total_1plus",
         align: "right",
         width: 80,
      },
      {
         title: "%",
         dataIndex: "percent",
         key: "percent",
         align: "right",
         width: 40,
      },
   ];

   return (
      <div style={{ minHeight: "40vh" }}>
         <h5 style={{ textAlign: "left" }}>
            Summary By Trade
            <Button
               type="default"
               size="small"
               style={{ float: "right", margin: 0 }}
               onClick={(e) => Func_Export_Critical_Summary_By_Trade_To_Excel(data, lastMonth)}
            >
               <Icon type="download" />
            </Button>
            <Checkbox
               style={{ float: "right", marginRight: 15, marginTop: 3 }}
               onClick={(e) => setCheckHighFail(!checkHighFail)}
            >
               Items {"<"} 92%
            </Checkbox>
         </h5>
         <Table
            dataSource={checkHighFail ? data : highFailData}
            columns={columns}
            size="small"
            scroll={{ y: "35vh" }}
            pagination={false}
            rowClassName={(record, index) => (record.percent < 92 ? "antd_table_row_red" : null)}
            onRow={(record, rowIndex) => {
               return {
                  onClick: (event) => {
                     // console.log(record.name);
                     setTradeSelected(record.name);
                  }, // click row
               };
            }}
            rowKey="name"
         />
      </div>
   );
};

export default TableTradeSummary;
