
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Icon, Loader, IconButton } from 'rsuite';
import { message, Typography } from 'antd'
import axios from "axios";
import _ from 'lodash'
import Dock from 'react-dock'
import { ColumnComment } from '../../module_BimApp/columns'
import {TableComment} from '../../module_BimApp/tables'

import {DialogCommentInformation} from '../../module_BimApp/dialog'
import {Markup2D} from '../../module_BimApp/dock_forge'
import base64url from 'base64url'
import { Intent, Position, Toaster, } from "@blueprintjs/core";
import CommentViewer from './CommentViewer'
const Autodesk = window.Autodesk;
const { Text } = Typography

let projectId = ''
let userCreatedProject = ''
let label = ''
let mainLoadOptions = {}
let version
let viewerLocal = null
function CommentAppViewer(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [issue, setIssue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)

  const [disabledBtn, setDisabledBtn] = useState(false)
  const [modelLoaded, setModelLoaded] = useState(false)
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [versionModel, setVersionModel] = useState('')
  const [displayPanel, setDisplayPanel] = useState(false)
  const [displayInfoComment, setDisplayInfoComment] = useState(false)
  const [listComment, setListComment] = useState([])
  const [allListComment, setAllListComment] = useState([])
  const [guid, setGuid] = useState()
  const [isAssignToMe, setIsAssignToMe] = useState(false)
  const [comment, setComment] = useState()

  const [showStatus, setShowStatus] = useState('All')
  const [listVersion, setListVersion] = useState([])
  const [objectId, setObjectId] = useState(null)

  const toaster = useRef()

  useEffect(e => {
    if (props.issue) {
      axios.post(`/api/comment/get-model`, { item: props.issue.mainModel, isLastVersion: props.check })
        .then(res => {
          if (res.data.status === 'success') {
            mainLoadOptions = {
              applyRefPoint: true,
              modelNameOverride: res.data.name,
              isAEC: true,
              guid: res.data.guid,
              viewableID: res.data.viewableID,
              itemId: res.data.itemId,
              version: res.data.version
            };
            version = res.data.version
            setItemId(res.data.itemId)
            setVersionModel(res.data.version)
            setItemName(res.data.name)
            if (props.issue.mainModel.version !== res.data.version) {
              let path = base64url(`${projectId}/${userCreatedProject}/${label}/${false}`)
              toaster.current.show({
                timeout: 0,
                action: {
                  href: `${window.location.origin}/issue/${path}`,
                  text: <strong>Reload</strong>,
                },
                icon: 'warning-sign',
                intent: Intent.WARNING,
                message: (
                  <Text strong style={{ color: 'white' }}>
                    {`Issue created at version ${props.issue.mainModel.version}. Are you want to load original version?`}
                  </Text>
                )
              })
            }
            if (props.check) {
              setObjectId(res.data.objectId)
            } else {
              setObjectId(props.issue.mainModel.urn)
            }
          }
          else {
            message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
          }
        })
        .catch(err => {
          message.error(`Can't load model.`);
        })
    }
  }, [])

  const loadMarkUpExt = (e) => {
    if (e.extensionId === 'Autodesk.Viewing.MarkupsCore') {
      viewerLocal.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, loadMarkUpExt)
      let markupCore = viewerLocal.getExtension('Autodesk.Viewing.MarkupsCore')
      setLoading(loading)
      setDisplayPanel(true)
      setGuid(viewerLocal.impl.model.getDocumentNode().data.guid)
      axios.get("/api/comment/get-comment-unread", { params: { email: props.email } })
        .then(res => {
          let unReadData = res.data
          axios.get("/api/comment/get-item-comments", {
            params: {
              itemId: itemId, projectId: projectId
            }
          })
            .then(res => {
              if (res.data.length !== 0) {
                markupCore.show();
                let temp = []
                let tempListVersion = []
                _.forEach(res.data, v => {
                  let data = v.comment
                  if (!data.priority) {
                    data.priority = 'Low'
                  }
                  tempListVersion.push(data.mainModel.version)
                  if (viewerLocal.impl.model.getDocumentNode().data.guid !== data.guid) return
                  data.checked = true
                  let index = _.findIndex(unReadData, e => { return e.id === data.id })
                  if (index >= 0) {
                    data.unReadIssue = !unReadData[index].issueTime ? true : data.dateModified > unReadData[index].issueTime
                    data.unReadComment = !unReadData[index].commentTime ? (data.comments.length === 0 ? false : true) :
                      (data.comments.length === 0 ? false : data.comments[data.comments.length - 1].datetime > unReadData[index].commentTime)
                  } else {
                    data.unReadIssue = true
                    data.unReadComment = data.comments.length === 0 ? false : true
                  }
                  temp.push(data)
                  markupCore.loadMarkups(data.markup, data.id)
                })
                tempListVersion = _.uniq(tempListVersion)
                setListVersion(tempListVersion.sort())
                setListComment(temp)
                setAllListComment(temp)
                setLoading(false)
              } else {
                setListComment([])
                setAllListComment([])
                setLoading(false)
              }
            })
            .catch(() => {

            })
        })
        .catch(() => {
          setListComment([])
          setAllListComment([])
          setLoading(false)
        })
    }

  }



  const handleCloseInfoViewPointPanel = (data) => {
    if (data) {
      setListComment(data)
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    } else {
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    }
  }

  const handleChangeMarkup2DPanel = () => {
    setMarkUp2d(!markUp2d)
  }

  const handleCloseViewPanel = () => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    markupCore.hide();
    _.forEach(listComment, v => {
      markupCore.unloadMarkups(v.id)
    })
    let btn = $('#btn-markup2d')
    btn.removeClass("active")
    setDisplayPanel(false)
  }
  const handleInfoViewPointPanel = (item) => {
    setComment(item)
    setDisplayInfoComment(true)
    setDisplayPanel(false)
  }
  const handleDeleteViewPoint = (item) => {
    if (!window.confirm('Are you sure to delete this comment?')) return
    setLoading(true)
    let temp1 = []
    let tempData = item.id
    _.forEach(listComment, (v) => {
      if (v.id !== item.id) {
        temp1.push(v)
      }
    })
    let temp2 = []
    _.forEach(allListComment, (v) => {
      if (v.id !== item.id) {
        temp2.push(v)
      }
    })
    axios.post(`/api/comment/delete-comment`, { id: tempData, email: props.email })
      .then(() => {
        setListComment(temp1)
        setAllListComment(temp2)
        setLoading(false)
        message.success(`Deleted viewpoint.`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't delete viewpoint.`);
      })

  }
  const handleChangeMarkup = (item, e) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let temp = []
    item.checked = e
    _.forEach(listComment, v => {
      if (v.checked) {
        temp.push(v)
      }
    })
    if (temp.length === 0) {
      markupCore.hide();
    } else {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        } else {
          markupCore.unloadMarkups(v.id)
        }

      })
    }
    setListComment(listComment.slice(0))
  }

  useEffect(() => {
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = [props.email]
        let tempNeed = []
        _.forEach(res.data.groupUser, v => {
          _.forEach(v.members, member => {
            if (member.name === props.email) {
              temp.push(v.name)
            }
          })
        })
        _.forEach(allListComment, v => {
          let checkAssign = false
          let checkStatus = false
          if (isAssignToMe) {
            _.forEach(v.listAssignViewPoint, user => {
              if (temp.includes(user)) {
                checkAssign = true
                return false
              }
            })
          } else {
            checkAssign = true
          }
          if (showStatus === 'All') {
            checkStatus = true
          } else {
            if (showStatus !== 'Close') {
              if (v.status.toLowerCase() !== 'close') {
                checkStatus = true
              }
            } else {
              if (v.status.toLowerCase() === showStatus.toLowerCase()) {
                checkStatus = true
              }
            }
          }
          if (checkAssign && checkStatus) {
            tempNeed.push(v)
          }
        })
        setListComment(tempNeed)
      })
      .catch(() => {
        setIsAssignToMe(false)
      })
  }, [isAssignToMe, showStatus])

  const handleAllCheck = (check) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    _.forEach(listComment, v => {
      if (v.checked)
        markupCore.unloadMarkups(v.id)
      if (v.guid === viewer.impl.model.getDocumentNode().data.guid) {
        v.checked = check
      }
    })
    if (check) {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    } else {
      markupCore.hide()
    }

    setListComment(listComment.slice(0))
  }
  const handleVersionMarkUp = (version) => {
    let markupCore = viewer.getExtension('Autodesk.Viewing.MarkupsCore')

    _.forEach(listComment, v => {
      if (v.checked) {
        v.checked = false
        markupCore.unloadMarkups(v.id)
      }
      if (v.guid === viewer.impl.model.getDocumentNode().data.guid && version.includes(v.mainModel.version)) {
        v.checked = true
      }

    })
    if (version.length === 0) {
      markupCore.hide()
    } else {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    }

    setListComment(listComment.slice(0))
  }
  const handleShowStatus = () => {
    setShowStatus(showStatus !== 'All' ? showStatus !== 'Close' ? 'Close' : 'All' : 'Open')
  }
  const handleSearch = (value) => {
    if (value === '') {
      setListComment(allListComment.slice(0))
    } else {
      let temp = []
      _.forEach(allListComment, v => {
        if (v.descriptionViewPoint.includes(value)) {
          temp.push(v)
        }
      })
      setListComment(temp)
    }
  }
  const handleCloseViewer = () => {
    props.close()
  }
  const handleOpenDialogCommentInformation = (viewer, globalOffset, e) => {
    setViewer(viewer)
    viewerLocal = viewer
    setGlobalOffset(globalOffset)
    loadMarkUpExt(e)
  }
  const handleOpenDialogCommentInformationBtn = (viewer, globalOffset, e) => {
    setDisplayPanel(true)
  }
  const handleAssignToMe = () => {
    setIsAssignToMe(!isAssignToMe)
  }
  
  return (
    <>
      <IconButton style={{ zIndex: '102', float: "right", backgroundColor: "red", top: '0', color: 'white', borderRadius: 0 }} size='sm' onClick={handleCloseViewer}
        icon={<Icon icon="close" />} ></IconButton>
      <IconButton style={{ zIndex: '102', float: "left", top: '0', color: 'white', borderRadius: 0 }} size='sm' onClick={handleOpenDialogCommentInformationBtn} color='blue'
        icon={<Icon icon='detail' />} ></IconButton>
      <div style={{ position: 'absolute', zIndex: 101, width: '100%', height: 'calc(100% - 53.78px)', backgroundColor: 'lightgray' }} >
        {objectId &&
          <CommentViewer {...props}
            handleOpenDialogCommentInformation={handleOpenDialogCommentInformation}
            objectId={objectId}
            mainLoadOptions={mainLoadOptions}
            handleChangeMarkup2DPanel={handleChangeMarkup2DPanel}
            markUp2d={markUp2d}
          />}
      </div>

      {displayPanel && <Dock position={'left'} isVisible={displayPanel} dimMode={'none'} fluid={false} zIndex={200} defaultSize={650}>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <TableComment
          columns={ColumnComment()} data={listComment}


          handleInfoViewPoint={handleInfoViewPointPanel}
          onActiveTable={handleCloseViewPanel}
          handleDeleteViewPoint={handleDeleteViewPoint}
          handleChangeMarkup={handleChangeMarkup}
          handleAssignToMe={handleAssignToMe}
          handleAllCheck={handleAllCheck}
          isAssignToMe={isAssignToMe}

          viewer={viewer}
          isProject={false}
          newTab={true}

          itemId={props.itemId}
          guid={guid}

          showStatus={showStatus}
          handleShowStatus={handleShowStatus}
          handleVersionMarkUp={handleVersionMarkUp}
          listVersion={listVersion}
          handleSearch={handleSearch}
        />

      </Dock>}

      {displayInfoComment &&
        <DialogCommentInformation
          listSaveView={listComment}
          open={displayInfoComment}
          handleClose={handleCloseInfoViewPointPanel}
          viewer={viewer}
          viewPoint={comment}
          email={props.email}
          projectId={props.projectId}
          userCreatedProject={props.userCreatedProject}
          // handleAttachFile={this.handleAttachFile}
          itemId={itemId}
          isProject={false}
          newTab={true}

        />
      }
      {markUp2d && <Markup2D openDock={markUp2d} viewer={viewer}
        onChangeDockDisplay={handleChangeMarkup2DPanel} />}

      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />


    </>
  );
}

CommentAppViewer.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(CommentAppViewer)