/* eslint-disable */
import $ from 'jquery';
import axios from 'axios';
import { message } from 'antd';
import printJS from 'print-js'
import CustomPropertiesLeft from './ShareViewExtensions/CustomPropertiesLeft'
import _ from 'lodash'
const Autodesk = window.Autodesk;



var viewer = null;
let customPanel = null

class WHShareExtensionLeft extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options)
        Autodesk.Viewing.Extension.call(this, viewer, options);

    }


    load() {
        return true;
    };
    unload() {
        if (this.subToolbar) {
            this.viewer.toolbar.removeControl(this.subToolbar);
            this.subToolbar = null;
            customPanel = null
        }

    };

    onToolbarCreated = function (toolbar) {
        viewer = this.viewer;
        viewer.setGhosting(false);
        //#region btnfilter
        // comboRebar.setToolTip('Rebar Tools');
    
        let button1 = new Autodesk.Viewing.UI.Button('btn-customproperties-left');
        button1.onClick = function (e) {
            viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
            if (customPanel === null) {
                customPanel = new CustomPropertiesLeft(viewer, { id: 'sharePropertyLeftExtensionPanel', title: 'Custom Propeties' })
                customPanel.addEventListener(customPanel.closer, "click", function () {
                    setActiveDock(button1, customPanel);
                    viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
                })
            }
            customPanel.setVisible(!customPanel.isVisible());
            setActiveDock(button1, customPanel);

        };
        button1.addClass('btn-customproperties-left');
        button1.addClass('fas');
        button1.addClass('fa-info');
        button1.addClass('fa-2x');
        button1.setToolTip('Share Property');

        this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('wh-custom-toolbar-share');
        this.subToolbar.addControl(button1);
        viewer.toolbar.addControl(this.subToolbar);


        function setActiveDock(button, dock) {
            if (dock.isVisible()) {
                button.container.classList.remove('inactive');
                button.container.classList.add('active');
            }
            else {
                button.container.classList.remove('active');
                button.container.classList.add('inactive');
                customPanel =null
                viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, onSelectionBinded);
            }
        }
        function onSelectionBinded() {
            var currSelection = viewer.getSelection();
            if (currSelection.length !== 0) {
                customPanel.removeAllProperties()
                viewer.model.getProperties(currSelection[0], function (modelAProperty) {
                    _.forEach(modelAProperty.properties, property => {
                        if (property.displayName === "MASTER SCHEDULE ID") {
                            customPanel.setTitle(modelAProperty.name)
                            customPanel.addProperty(property.displayName, property.displayValue, 'Custom Properties');
                            return false
                        }
                    })

                })
            }
        }
    };


}




Autodesk.Viewing.theExtensionManager.registerExtension('WHShareExtensionLeft', WHShareExtensionLeft);
export default 'WHShareExtensionLeft'