import React from 'react';
import {
  message,
  Modal,
  Col,
} from 'antd';


import _ from 'lodash';
import axios from 'axios';
import {
  Input,
  InputPicker,
} from 'rsuite';



export default class DialogAddColumnSheet extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        columnName: '',
        columnType: '',
        loading: false,
      };
    }
  
    handleClose = () => {
      this.props.close();
    };
  
    handleOk = () => {
      if (this.state.columnName === '' || this.state.columnType === '') {
        return;
      }
      this.setState({ loading: true });
      axios
        .post(`/api/dataentry/add-column-project-sheet`, {
          projectId: this.props.projectId,
          sheet: this.props.sheet,
          token: this.props.token,
          columnName: this.state.columnName,
          columnType: this.state.columnType,
        })
        .then((res1) => {
          this.props.close();
        })
        .catch(() => {
          message.warning(`Can't save column sheet`);
          this.setState({ loading: false });
        });
    };
    handleChangeColumnType = (value) => {
      this.setState({ columnType: value });
    };
    handleChangeColumnName = (value) => {
      this.setState({ columnName: value });
    };
    render() {
      return (
        <>
          <Modal
            title={'Add Column Sheet'}
            visible={this.props.open}
            onCancel={this.handleClose}
            onOk={this.handleOk}
            width={'30%'}
            confirmLoading={this.state.loading}
          >
            <Col span={12}>
              <Input
                block
                value={this.state.columnName}
                appearance='default'
                size='sm'
                placeholder='Column Name'
                onChange={this.handleChangeColumnName.bind(this)}
              />
            </Col>
            <Col span={12}>
              <InputPicker
                block
                placeholder='Column Type'
                appearance='default'
                size='sm'
                cleanable={false}
                value={this.state.columnType}
                onChange={this.handleChangeColumnType.bind(this)}
                data={[
                  { key: 'Text', label: 'Text', value: 'text' },
                  { key: 'Number', label: 'Number', value: 'numeric' },
                  { key: 'Date', label: 'Date', value: 'date' },
                  { key: 'CheckBox', label: 'CheckBox', value: 'checkbox' },
                  { key: 'DropDown', label: 'DropDown', value: 'dropdown' },
                ]}
              />
            </Col>
            <br />
          </Modal>
        </>
      );
    }
  }
  