import React from 'react';
import { IconButton, ButtonGroup, Icon } from 'rsuite';

import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from './ReactTable'
function TableIssue(props) {




  const showInfoViewpoint = (value) => {
    props.handleInfoViewPoint(value)
  }



  return (
    <div>
      <ButtonGroup style={{ position: 'absolute', zIndex: '1' }}>
        {!props.isProject &&
          <>
            <Tooltip title="New Viewpoint" placement="bottom">
              <IconButton size="sm" icon={<Icon icon="plus-square" />} onClick={() => props.handleNewViewPointPanel()} ></IconButton>
            </Tooltip>
            <Tooltip title="Switch Side Bar" placement="bottom">
              <IconButton size="sm" icon={<Icon icon="exchange" />} onClick={() => props.handleSwitchTable()} ></IconButton>
            </Tooltip>
          </>
        }
     
        <Tooltip title="Assign to me" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="user-circle-o" />} onClick={() => props.handleAssignToMe()} color={props.isAssignToMe && 'blue'} ></IconButton>
        </Tooltip>
      {props.handleGroupTag&&  <Tooltip title="Group Tag" placement="bottom">
          <IconButton size="sm" icon={<Icon icon='object-group' />} onClick={() => props.handleGroupTag()} color={props.isGroupTag && 'blue'} ></IconButton>
        </Tooltip>}
      </ButtonGroup>


      <ReactTable
        {...props}
        haveFooter={false}
        id={'tableIssue'}
        showInfoViewpoint={props.isCoordinationApp ? null : showInfoViewpoint}
        haveCompareTime={true}
        defaultPageSize={20}
      />
    </div>
  )
}

export default TableIssue