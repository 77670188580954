import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader, Drawer, Divider } from 'rsuite';
import { message, Typography, Tag, Empty } from 'antd'
import _ from 'lodash'
import axios from "axios";
import { Pie } from 'react-chartjs-2';
const { Title } = Typography

// defaults.global.defaultFontColor = '#000000'



const opt = {
  legend: {
    "position": "bottom",
    "labels": {
      "fontColor": "black"
    }
  }
}
function SidebarDashboardIssue(props) {
  const [data, setData] = useState({})
  const [dataComment, setDataComment] = useState({})
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    props.handleClose(null)
  }
  useEffect(() => {
    setLoading(true)
    axios.get("/api/issue/get-dashboard-issue", { params: { projectId: props.projectId } })
      .then(res => {
        let projectIssue = [0, 0, 0, 0]
        let issueType = [0, 0, 0, 0]
        _.forEach(res.data, v => {
          let index = v.status === 'Open' ? 0 : v.status === 'In progress' ? 1 : v.status === 'Waiting' ? 2 : 3
          projectIssue[index] = projectIssue[index] + 1
          index = v.priority === 'Very urgent' ? 0 : v.priority === "Urgent" ? 1 : v.priority === 'Normal' ? 2 : 3
          issueType[index] = issueType[index] + 1
        })

        const pieChartData = {
          projectIssue: {
            labels: [
              'Open', 'In progress', 'Waiting', 'Close'
            ],
            datasets: [{
              data: projectIssue,
              backgroundColor: [
                '#8E7CC3',
                '#6FA8DC',
                '#93C47D',
                '#FFD966'
              ],
              hoverBackgroundColor: [
                '#8E7CC3',
                '#6FA8DC',
                '#93C47D',
                '#FFD966'
              ],
            }]
          },
          issueType: {
            labels: [
              'Very urgent', 'Urgent', 'Normal', 'Low'
            ],
            datasets: [{
              data: issueType,
              backgroundColor: [
                'red',
                'orange',
                '#f4d90b',
                'blue'
              ],
              hoverBackgroundColor: [
                'red',
                'orange',
                '#f4d90b',
                'blue'
              ],
            }]
          },

        }
        setData(pieChartData)
        setLoading(false)
      })
      .catch(() => {
        message.warning(`Can't get data`)
        setLoading(false)
      })
  }, [])
  useEffect(() => {
    setLoading(true)
    axios.get("/api/comment/get-dashboard-issue", { params: { projectId: props.projectId } })
      .then(res => {
        let projectComment = [0, 0, 0, 0]
        let commentType = [0, 0, 0, 0]
        _.forEach(res.data, v => {
          let index = v.status === 'Open' ? 0 : v.status === 'In progress' ? 1 : v.status === 'Waiting' ? 2 : 3
          projectComment[index] = projectComment[index] + 1
          index = v.priority === 'Very urgent' ? 0 : v.priority === "Urgent" ? 1 : v.priority === 'Normal' ? 2 : 3
          commentType[index] = commentType[index] + 1
        })

        const pieChartData = {
          projectComment: {
            labels: [
              'Open', 'In progress', 'Waiting', 'Close'
            ],
            datasets: [{
              data: projectComment,
              backgroundColor: [
                '#8E7CC3',
                '#6FA8DC',
                '#93C47D',
                '#FFD966'
              ],
              hoverBackgroundColor: [
                '#8E7CC3',
                '#6FA8DC',
                '#93C47D',
                '#FFD966'
              ],
            }]
          },
          commentType: {
            labels: [
              'Very urgent', 'Urgent', 'Normal', 'Low'
            ],
            datasets: [{
              data: commentType,
              backgroundColor: [
                'red',
                'orange',
                '#f4d90b',
                'blue'
              ],
              hoverBackgroundColor: [
                'red',
                'orange',
                '#f4d90b',
                'blue'
              ],
            }]
          },

        }
        setDataComment(pieChartData)
        setLoading(false)
      })
      .catch(() => {
        message.warning(`Can't get data`)
        setLoading(false)
      })
  }, [])
  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleClose} keyboard={false}
        placement='left' size='xs' className='drawerBimAppPanel'>
        <Drawer.Header>
          <Drawer.Title>
            <Title style={{ display: 'inline', marginLeft: '5px' }} level={4} >Dashboard Issue</Title>
          </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
          {Object.keys(data).length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            <>
              <div style={{ textAlignLast: 'center' }}>
                <Tag style={{ fontSize: 'medium', color: 'white', backgroundColor: 'black' }}>
                  Project Issue
            </Tag>
              </div>
              <Pie data={data.projectIssue}
                height={190}
                options={opt} />
              <Divider />
              <div style={{ textAlignLast: 'center' }}>
                <Tag style={{ fontSize: 'medium', color: 'white', backgroundColor: 'black' }}>
                  Issue Type
            </Tag>
              </div>
              <Pie data={data.issueType}
                height={190}
                options={opt} />
            </>}
          {Object.keys(data).length === 0 ?
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
            <>
              <div style={{ textAlignLast: 'center' }}>
                <Tag style={{ fontSize: 'medium', color: 'white', backgroundColor: 'black' }}>
                  Project Comment
            </Tag>
              </div>
              <Pie data={dataComment.projectComment}
                height={190}
                options={opt} />
              <Divider />
              <div style={{ textAlignLast: 'center' }}>
                <Tag style={{ fontSize: 'medium', color: 'white', backgroundColor: 'black' }}>
                Comment Type
            </Tag>
              </div>
              <Pie data={dataComment.commentType}
                height={190}
                options={opt} />
            </>}
        </Drawer.Body>
        <Divider />
        <Drawer.Footer style={{ marginBottom: 5 }}>

        </Drawer.Footer>
      </Drawer>

    </>
  )
}

SidebarDashboardIssue.propTypes = {
  email: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}
export default connect(mapStateToProps, null)(SidebarDashboardIssue)