import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, message } from 'antd';
import _ from 'lodash';
import { IconButton, Icon, ButtonGroup } from 'rsuite';
// import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReact } from 'ag-grid-react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { LoadingScreen } from '../customCell/LoadingScreen';
import { v4 } from 'uuid';
import {
  setDataEntry,
  setDataEntryLoading,
} from '../../../actions/app/dataentry';

function DataEntryTable(props) {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [autoGroupColumnDef, setAutoGroupColumnDef] = useState({});
  useEffect(() => {
    setRowData(props.dataTable);
  }, [props.dataTable]);

  useEffect(() => {
    console.log('columns', props.columns);
    _.forEach(props.columns, (v) => {
      if (v.isMatching) {
        setAutoGroupColumnDef({
          cellRendererParams: {
            suppressCount: true,
            checkbox: true,
          },
          field: v.field,
          width: 150,
          headerCheckboxSelection: true,
          lockPosition: true,
        });
        return false;
      }
    });
  }, [props.columns]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      minWidth: 100,
      resizable: true 
    }),
    []
  );

  const handleAssign = () => {
    if (!window.confirm('Are you want to override data')) return;
    if (rowData.length === 0) {
      message.warning('Please, reload data');
      return;
    }
    props.setDataEntryLoading({ loading: true });
    let allData = [];
    gridRef.current.api.forEachNode((rowNode) => {
      if (rowNode.group && rowNode.selected) {
        _.forEach(rowNode.allLeafChildren, (v) => {
          allData.push(v.data);
        });
      }
    });
    let tempMatching = {};
    _.forEach(props.selectSheet.columns, (v) => {
      if (v.parameter) {
        tempMatching[v.parameter] = v.name;
      }
    });
    if (props.mainParameter) {
      let data = props.dataEntryData;
      let tempData = [];
      let clone = data.slice(0);
      _.forEach(allData, (v) => {
        let matching = v[props.mainParameter];
        let externalIds = [];
        let itemIds = [];
        let globalOffset = props.viewerCurrent.impl.model
          ? JSON.stringify(props.viewerCurrent.impl.model.myData.globalOffset)
          : null;
        let index = _.findIndex(clone, (o) => {
          return o[props.mainName] == matching;
        });
        _.forEach(v.children, (i) => {
          externalIds.push(i.externalId);
          if (!itemIds.includes(i.itemId)) itemIds.push(i.itemId);
        });
        if (index >= 0) {
          let data = clone[index];
          _.forEach(v, (i, k) => {
            if (tempMatching[k]) data[tempMatching[k]] = i;
          });
          data.externalIds = JSON.stringify(externalIds);
          data.itemIds = JSON.stringify(itemIds);
          data.globalOffset = globalOffset;
          tempData.push(data);
          let copy = Object.assign({}, data);
          copy.row_status = 'update';
          window.historySheet.push(copy);
        } else {
          let data = {
            id: v4(),
            itemIds: JSON.stringify(itemIds),
            externalIds: JSON.stringify(externalIds),
            globalOffset,
            projectId: props.projectId,
            sheetId: props.selectSheet.id,
          };
          _.forEach(v, (i, k) => {
            if (tempMatching[k]) data[tempMatching[k]] = i;
          });
          clone.push(data);
          tempData.push(data);
          let copy = Object.assign({}, data);
          copy.row_status = 'add';
          window.historySheet.push(copy);
        }
      });
      props.setDataEntry({ data: clone });
      props.setDataEntryLoading({ loading: false });
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ margin: 2 }}>
        <ButtonGroup>
          <Tooltip placement="bottom" title="Reload Data">
            <IconButton
              color="blue"
              appearance="ghost"
              style={{ borderRadius: 0 }}
              size="sm"
              icon={<Icon icon="reload" />}
              onClick={props.handleModelData}
              loading={props.dataEntryLoading}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Assign Data">
            <IconButton
              color="blue"
              appearance="ghost"
              style={{ borderRadius: 0 }}
              size="sm"
              icon={<Icon icon="file-upload" />}
              onClick={handleAssign}
              loading={props.dataEntryLoading}
            />
          </Tooltip>

          {rowData.length !== 0 && (
            <>
              <Tooltip placement="bottom" title="Show Element">
                <IconButton
                  color="blue"
                  style={{ borderRadius: 0 }}
                  appearance="ghost"
                  size="sm"
                  icon={<Icon icon="link" />}
                  onClick={props.handleShowElement}
                  loading={props.dataEntryLoading}
                />
              </Tooltip>
            </>
          )}
        </ButtonGroup>
        <ButtonGroup style={{ float: 'right' }}>
          <Tooltip placement="bottom" title="Collapse">
            <IconButton
              color="red"
              appearance="ghost"
              style={{ borderRadius: 0 }}
              size="sm"
              icon={<Icon icon="minus" />}
              onClick={props.handleCollapse.bind(this, true)}
              loading={props.dataEntryLoading}
            />
          </Tooltip>
        </ButtonGroup>
      </div>
      <div
        style={{
          height: 'calc(100% - 40px)',
          width: '100%',
        }}
      >
        <AgGridReact
          ref={gridRef}
          className="ag-theme-balham"
          animateRows="true"
          reactUi="true"
          rowDragManaged={true}
          modules={AllModules}
          columnDefs={props.columns}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          rowGroupPanelShow="always"
          enableRangeSelection="true"
          rowData={rowData}
          rowSelection="multiple"
          columnHoverHighlight={true}
          loadingOverlayComponentFramework={LoadingScreen}
          allowContextMenuWithControlKey={true}
          // showOpenedGroup = {true}
          groupDisplayType="multipleColumns"
        />
      </div>
    </div>
  );
}
DataEntryTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  excelPage: PropTypes.bool.isRequired,
  dataEntryData: PropTypes.object.isRequired,
  dataEntryLoading: PropTypes.bool.isRequired,
  setDataEntry: PropTypes.func.isRequired,
  setDataEntryLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    dataEntryData: state.dataentry.data,
    dataEntryLoading: state.dataentry.loading,
  };
}
export default connect(mapStateToProps, {
  setDataEntry: setDataEntry,
  setDataEntryLoading: setDataEntryLoading,
})(DataEntryTable);
