import React, { useState, useEffect } from "react";
import axios from "../../module_Worker/api/axiosClient";
import _ from "lodash";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { connect, useSelector } from "react-redux";
import { Button, Table, Upload, notification, Spin } from "antd";

import { Icon } from "rsuite";

import { HotTable } from "@handsontable/react";

// API
async function apiPostDataImport(payload, projectId) {
  return await axios.post("/api/quantity-tracking/data", {
    data: payload,
    projectId: projectId,
  });
}

async function apiGetData(projectId) {
  return await axios.get(
    "/api/quantity-tracking/get-data-by-project-id/" + projectId
  );
}

async function apiReloadActual(projectId) {
  return await axios.put(
    "/api/quantity-tracking/reload-actual-data/" + projectId
  );
}

async function apiSaveData(payload) {
  return await axios.put("/api/quantity-tracking/save-data", payload);
}

let columns = [
  { data: "block" },
  { data: "level" },
  { data: "description" },

  { data: "budgeData.formwork", type: "numeric", width: 80 },
  { data: "budgeData.concrete", type: "numeric", width: 80 },
  { data: "budgeData.rebar", type: "numeric" , width: 80 },
  { data: "budgeData.ratio", type: "numeric" , width: 80 },
  { data: "budgeData.mesh", type: "numeric" , width: 80 },

  { data: "bcaData.formwork", type: "numeric" , width: 80 },
  { data: "bcaData.concrete", type: "numeric" , width: 80 },
  { data: "bcaData.rebar", type: "numeric", width: 80 },
  { data: "bcaData.ratio", type: "numeric" , width: 80 },
  { data: "bcaData.mesh", type: "numeric" , width: 80 },

  { data: "bimData.formwork", type: "numeric" , width: 80 },
  { data: "bimData.concrete", type: "numeric" , width: 80 },
  { data: "bimData.rebar", type: "numeric", width: 80 },
  { data: "bimData.ratio", type: "numeric", width: 80 },
  { data: "bimData.mesh", type: "numeric", width: 80 },

  { data: "actualData.formwork", type: "numeric" , width: 80 },
  { data: "actualData.concrete", type: "numeric", width: 80 },
  { data: "actualData.rebar", type: "numeric" , width: 80 },
  { data: "actualData.ratio", type: "numeric", width: 80 },
  { data: "actualData.mesh", type: "numeric" , width: 80 },

  { data: "actualDoData.formwork", type: "numeric", width: 80 },
  { data: "actualDoData.concrete", type: "numeric", width: 80 },
  { data: "actualDoData.rebar", type: "numeric" , width: 80 },
  { data: "actualDoData.ratio", type: "numeric", width: 80 },
  { data: "actualDoData.mesh", type: "numeric" , width: 80 },

  { data: "differenceData.formwork", type: "numeric", readOnly: true , width: 80 },
  { data: "differenceData.concrete", type: "numeric", readOnly: true , width: 80 },
  { data: "differenceData.rebar", type: "numeric", readOnly: true , width: 80 },
  { data: "differenceData.ratio", type: "numeric", readOnly: true , width: 80 },
  { data: "differenceData.mesh", type: "numeric", readOnly: true , width: 80 },
];

function QuantityTracking(props) {
  const [fileExcel, setFileExcel] = useState(null);
  const [hotData, setHotData] = useState([]);
  const [removeRowIds, setRemoveRowIds] = useState([]);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [loadingTip, setLoadingTip] = useState("");
  const hotTableRef = React.createRef();

  const [columnHeaders, setColumnHeaders] = useState([
    [
      "",
      "",
      "",
      { label: "Budget", colspan: 5 },
      { label: "BCA submission", colspan: 5 },
      { label: "BIM", colspan: 5 },
      { label: "Actual", colspan: 5 },
      { label: "Actual Do", colspan: 5 },
      { label: "Differences", colspan: 5 },
    ],
    [
      "Area/Block",
      "Level",
      "Description",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",

      "Formwork",
      "Concrete",
      "Rebar",
      "Ratio",
      "Mesh",
    ],
  ]);

  const [dataScheme, setDataScheme] = useState({
    block: "block",
    level: "level",
    description: "description",
    budgeData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
    bcaData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
    bimData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
    actualData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
    actualDoData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
    differenceData: {
      formwork: null,
      concreteVolume: null,
      rebarQuantity: null,
      ratio: null,
      mesh: null,
    },
  });

  const contextMenuHotTable = {
    items: {
      row_above: {
        name: "Insert rows above",
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt("How many rows you want to add", "1");
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: uuidv4(),
                    projectId: props.location.state.projectId,
                    isAdd: true,
                  };
                  let copy = Object.assign({}, item);
                  copy.row_status = "add";
                  temp.splice(selection[selection.length - 1].end.row, 0, item);
                  start++;
                }
                setHotData(temp);
              }
            }
          }
        },
      },
      row_below: {
        name: "Insert rows below",
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            let text = window.prompt("How many rows you want to add", "1");
            if (text) {
              let number = parseFloat(text);
              if (_.isNumber(number)) {
                let temp = hotData.slice(0);
                let start = 1;
                while (start <= number) {
                  let item = {
                    id: uuidv4(),
                    projectId: props.location.state.projectId,
                    isAdd: true,
                  };

                  let copy = Object.assign({}, item);
                  copy.row_status = "add";
                  temp.splice(
                    selection[selection.length - 1].end.row + start,
                    0,
                    item
                  );
                  start++;
                }

                setHotData(temp);
              }
            }
          }
        },
      },
      remove_row: {
        name: "Remove rows",
        disabled: () => {
          return false;
        },
        callback: (key, selection, clickEvent) => {
          if (selection.length !== 0) {
            if (!window.confirm("Are you want to delete it")) return;

            let indexs = [];
            _.forEach(selection, (v) => {
              let start = v.start.row;
              while (start <= v.end.row) {
                indexs.push(start);
                start++;
              }
            });

            let temp = hotData.slice(0);

            let tempData = temp
              .slice(0)
              .filter((x, index) => !indexs.includes(index));

            let removeIds = temp
              .slice(0)
              .filter((x, index) => indexs.includes(index))
              .map((x) => x.id);

            setRemoveRowIds([...removeRowIds, ...removeIds]);

            setHotData(tempData);
          }
        },
      },
    },
  };

  useEffect(() => {
    getData();
  }, []);

  function handleImport(file) {
    setFileExcel(file);
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const buffer = reader.result;
      let workbook = await wb.xlsx.load(buffer);
      let datas = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex != 1) {
            let block = row.values[1];
            let level = row.values[2];
            let description = row.values[3];

            let obj = {
              block,
              level,
              description,
            };

            let dataQuantity = ["budgeData", "bcaData", "bimData"];

            dataQuantity.forEach((x, index) => {
              let formwork = _.isNumber(row.values[index * 5 + 4])
                ? row.values[index * 5 + 4]
                : 0;
              let concrete = _.isNumber(row.values[index * 5 + 5])
                ? row.values[index * 5 + 5]
                : 0;
              let rebar = _.isNumber(row.values[index * 5 + 6])
                ? row.values[index * 5 + 6]
                : 0;
              let ratio = _.isNumber(row.values[index * 5 + 7])
                ? row.values[index * 5 + 7]
                : 0;
              let mesh = _.isNumber(row.values[index * 5 + 8])
                ? row.values[index * 5 + 8]
                : 0;

              if (
                !_.isEmpty(level) &&
                !_.isEmpty(description) &&
                (concrete > 0 ||
                  formwork > 0 ||
                  ratio > 0 ||
                  mesh > 0 ||
                  rebar > 0)
              ) {
                obj[x] = {
                  formwork,
                  concrete,
                  rebar,
                  ratio,
                  mesh,
                };
              }
            });

            if (Object.keys(obj).length > 3) {
              datas.push(obj);
            }
          }
        });
      });

      if (!_.isArray(datas) || datas.length < 1) {
        notification["error"]({
          message: "Import Error",
          description: "There is no data in the excel !",
        });
      }

      try {
        await apiPostDataImport(datas, props.location.state.projectId);
        await getData();
      } catch (error) {
        // notification['error']({
        //   message: 'Import Error',
        //   description: error.response.message,
        // });
      }
    };

    return false;
  }

  async function getData() {
    setIsLoadingSpin(true);
    setLoadingTip("Refreshing table...");

    let res = await apiGetData(props.location.state.projectId);
    res = res.map((x) => ({
      ...x,
      isParrent: false,
      block: x.block ?? "None",
      rowType: 0,
      differenceData: {
        formwork:
          x.actualDoData.formwork > 0
            ? x.actualDoData.formwork - x.budgeData.formwork
            : null,
        concrete:
          x.actualDoData.concrete > 0
            ? x.actualDoData.concrete - x.budgeData.concrete
            : null,
        ratio:
          x.actualDoData.ratio > 0
            ? x.actualDoData.ratio - x.budgeData.ratio
            : null,
        rebar:
          x.actualDoData.rebar > 0
            ? x.actualDoData.rebar - x.budgeData.rebar
            : null,
        mesh:
          x.actualDoData.mesh > 0
            ? x.actualDoData.mesh - x.budgeData.mesh
            : null,
      },
    }));

    let list = [];
    let gByBlock = _.groupBy(res, "block");
    let keyBlocks = Object.keys(gByBlock);
    keyBlocks.sort();

    keyBlocks.forEach((keyBlock) => {
      let itemsInBlock = gByBlock[keyBlock];

      list.push({
        rowType: 1,
        isParrent: true,
        block: keyBlock,
        bcaData: {
          formwork: _.sumBy(itemsInBlock, "bcaData.formwork"),
          concrete: _.sumBy(itemsInBlock, "bcaData.concrete"),
          ratio: _.sumBy(itemsInBlock, "bcaData.ratio"),
          rebar: _.sumBy(itemsInBlock, "bcaData.rebar"),
          mesh: _.sumBy(itemsInBlock, "bcaData.mesh"),
        },
        budgeData: {
          formwork: _.sumBy(itemsInBlock, "budgeData.formwork"),
          concrete: _.sumBy(itemsInBlock, "budgeData.concrete"),
          ratio: _.sumBy(itemsInBlock, "budgeData.ratio"),
          rebar: _.sumBy(itemsInBlock, "budgeData.rebar"),
          mesh: _.sumBy(itemsInBlock, "budgeData.mesh"),
        },
        bimData: {
          formwork: _.sumBy(itemsInBlock, "bimData.formwork"),
          concrete: _.sumBy(itemsInBlock, "bimData.concrete"),
          ratio: _.sumBy(itemsInBlock, "bimData.ratio"),
          rebar: _.sumBy(itemsInBlock, "bimData.rebar"),
          mesh: _.sumBy(itemsInBlock, "bimData.mesh"),
        },
        actualData: {
          formwork: _.sumBy(itemsInBlock, "actualData.formwork"),
          concrete: _.sumBy(itemsInBlock, "actualData.concrete"),
          ratio: _.sumBy(itemsInBlock, "actualData.ratio"),
          rebar: _.sumBy(itemsInBlock, "actualData.rebar"),
          mesh: _.sumBy(itemsInBlock, "actualData.mesh"),
        },
        actualDoData: {
          formwork: _.sumBy(itemsInBlock, "actualDoData.formwork"),
          concrete: _.sumBy(itemsInBlock, "actualDoData.concrete"),
          ratio: _.sumBy(itemsInBlock, "actualDoData.ratio"),
          rebar: _.sumBy(itemsInBlock, "actualDoData.rebar"),
          mesh: _.sumBy(itemsInBlock, "actualDoData.mesh"),
        },
        differenceData: {
          formwork: _.sumBy(itemsInBlock, "actualDoData.formwork"),
          concrete: _.sumBy(itemsInBlock, "actualDoData.concrete"),
          ratio: _.sumBy(itemsInBlock, "actualDoData.ratio"),
          rebar: _.sumBy(itemsInBlock, "actualDoData.rebar"),
          mesh: _.sumBy(itemsInBlock, "actualDoData.mesh"),
        },
      });

      let gByLevel = _.groupBy(itemsInBlock, "level");
      let keys = Object.keys(gByLevel);
      keys.sort();

      keys.forEach((key) => {
        list.push({
          rowType: 2,
          isParrent: true,
          level: key,
          block: keyBlock,
          bcaData: {
            formwork: _.sumBy(gByLevel[key], "bcaData.formwork"),
            concrete: _.sumBy(gByLevel[key], "bcaData.concrete"),
            ratio: _.sumBy(gByLevel[key], "bcaData.ratio"),
            rebar: _.sumBy(gByLevel[key], "bcaData.rebar"),
            mesh: _.sumBy(gByLevel[key], "bcaData.mesh"),
          },
          budgeData: {
            formwork: _.sumBy(gByLevel[key], "budgeData.formwork"),
            concrete: _.sumBy(gByLevel[key], "budgeData.concrete"),
            ratio: _.sumBy(gByLevel[key], "budgeData.ratio"),
            rebar: _.sumBy(gByLevel[key], "budgeData.rebar"),
            mesh: _.sumBy(gByLevel[key], "budgeData.mesh"),
          },
          bimData: {
            formwork: _.sumBy(gByLevel[key], "bimData.formwork"),
            concrete: _.sumBy(gByLevel[key], "bimData.concrete"),
            ratio: _.sumBy(gByLevel[key], "bimData.ratio"),
            rebar: _.sumBy(gByLevel[key], "bimData.rebar"),
            mesh: _.sumBy(gByLevel[key], "bimData.mesh"),
          },
          actualData: {
            formwork: _.sumBy(gByLevel[key], "actualData.formwork"),
            concrete: _.sumBy(gByLevel[key], "actualData.concrete"),
            ratio: _.sumBy(gByLevel[key], "actualData.ratio"),
            rebar: _.sumBy(gByLevel[key], "actualData.rebar"),
            mesh: _.sumBy(gByLevel[key], "actualData.mesh"),
          },
          actualDoData: {
            formwork: _.sumBy(gByLevel[key], "actualDoData.formwork"),
            concrete: _.sumBy(gByLevel[key], "actualDoData.concrete"),
            ratio: _.sumBy(gByLevel[key], "actualDoData.ratio"),
            rebar: _.sumBy(gByLevel[key], "actualDoData.rebar"),
            mesh: _.sumBy(gByLevel[key], "actualDoData.mesh"),
          },
        });

        list = list.concat(gByLevel[key]);
      });
    });

    setHotData(list);
    setIsLoadingSpin(false);
    setLoadingTip("");
  }

  async function handleReloadActual() {
    setIsLoadingSpin(true);
    setLoadingTip("Get Actual Data...");
    await apiReloadActual(props.location.state.projectId);
    await getData();
  }

  async function handleSave() {
    let payload = {
      removeRowIds,
      add: hotData.filter((x) => x.isAdd == true && x.isParrent != true),
      update: hotData.filter((x) => x.isAdd != true && x.isParrent != true),
    };
    payload.add.forEach((x) => {
      delete x["isParrent"];
      delete x["rowType"];
      delete x["differenceData"];
    });

    payload.update.forEach((x) => {
      delete x["isParrent"];
      delete x["rowType"];
      delete x["differenceData"];
    });

    setIsLoadingSpin(true);
    setLoadingTip("Save Table...");
    await apiSaveData(payload);

    await getData();

    setIsLoadingSpin(false);
  }

  async function handleExport() {
    const workbook = new ExcelJS.Workbook();
    let excelName = "Tracking Concrete Rebar Quantity";
    const worksheet = workbook.addWorksheet("Tracking");

    let borderStyles = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    let row7 = worksheet.getRow(7);
    let row6 = worksheet.getRow(6);
    row7.height = 30;

    worksheet.getCell("A2").value =
      "Project : " + props.location.state.projectName;
    worksheet.getCell("A7").value = "Area/Block";
    worksheet.getCell("B7").value = "Item";
    worksheet.getCell("C7").value = "Description";

    let row8 = worksheet.getRow(8);
    let lastColumn = 12;

    let quantityTypes = ["Budge", "BCA", "BIM", "Actual", "Actual DO"];
    for (let index = 0; index < 5; index++) {
      let indexColumnStart = 3 + 5 * index + 1;

      let startCell = row6.getCell(indexColumnStart)._address;
      let endCell = row6.getCell(indexColumnStart + 4)._address;

      worksheet.mergeCells(`${startCell}:${endCell}`);
      worksheet.getCell(startCell).value = quantityTypes[index];
      worksheet.getCell(startCell).alignment = { horizontal: "center" };
      worksheet.getCell(startCell).border = borderStyles;

      row7.getCell(indexColumnStart).value = "Formwork";
      row8.getCell(indexColumnStart).value = "m2";
      indexColumnStart++;

      row7.getCell(indexColumnStart).value = "Concrete";
      row8.getCell(indexColumnStart).value = "m3";
      indexColumnStart++;

      row7.getCell(indexColumnStart).value = "Rebar";
      row8.getCell(indexColumnStart).value = "kg";
      indexColumnStart++;

      row7.getCell(indexColumnStart).value = "Ratio";
      row8.getCell(indexColumnStart).value = "kg/m3";
      indexColumnStart++;

      row7.getCell(indexColumnStart).value = "Mesh";
      row8.getCell(indexColumnStart).value = "kg";

      lastColumn = indexColumnStart;
    }

    let i = 9;

    let gByLevel = _.groupBy(
      hotData.filter((x) => !x.isParrent),
      "level"
    );

    let quantityProperties = [
      "budgeData",
      "bcaData",
      "bimData",
      "actualData",
      "actualDoData",
    ];
    //set data

    let keys = Object.keys(gByLevel);
    keys.sort();
    keys.forEach((key) => {
      let items = gByLevel[key];

      let iStart = i;
      items.forEach((item) => {
        let row = worksheet.getRow(i);
        let columnStart = 4;

        worksheet.getCell(`A${i}`).value = item.block;
        worksheet.getCell(`B${i}`).value = item.level;
        worksheet.getCell(`C${i}`).value = item.description;

        row.getCell(columnStart).value = item.budgeData.formwork;
        columnStart++;
        row.getCell(columnStart).value = item.budgeData.concrete;
        columnStart++;
        row.getCell(columnStart).value = item.budgeData.rebar;
        columnStart++;
        row.getCell(columnStart).value = item.budgeData.ratio;
        columnStart++;
        row.getCell(columnStart).value = item.budgeData.mesh;
        columnStart++;

        row.getCell(columnStart).value = item.bcaData.formwork;
        columnStart++;
        row.getCell(columnStart).value = item.bcaData.concrete;
        columnStart++;
        row.getCell(columnStart).value = item.bcaData.rebar;
        columnStart++;
        row.getCell(columnStart).value = item.bcaData.ratio;
        columnStart++;
        row.getCell(columnStart).value = item.bcaData.mesh;
        columnStart++;

        row.getCell(columnStart).value = item.bimData.formwork;
        columnStart++;
        row.getCell(columnStart).value = item.bimData.concrete;
        columnStart++;
        row.getCell(columnStart).value = item.bimData.rebar;
        columnStart++;
        row.getCell(columnStart).value = item.bimData.ratio;
        columnStart++;
        row.getCell(columnStart).value = item.bimData.mesh;
        columnStart++;

        row.getCell(columnStart).value = item.actualData.formwork;
        columnStart++;
        row.getCell(columnStart).value = item.actualData.concrete;
        columnStart++;
        row.getCell(columnStart).value = item.actualData.rebar;
        columnStart++;
        row.getCell(columnStart).value = item.actualData.ratio;
        columnStart++;
        row.getCell(columnStart).value = item.actualData.mesh;
        columnStart++;

        row.getCell(columnStart).value = item.actualDoData.formwork;
        columnStart++;
        row.getCell(columnStart).value = item.actualDoData.concrete;
        columnStart++;
        row.getCell(columnStart).value = item.actualDoData.rebar;
        columnStart++;
        row.getCell(columnStart).value = item.actualDoData.ratio;
        columnStart++;
        row.getCell(columnStart).value = item.actualDoData.mesh;

        i++;
      });

      //Summary row
      worksheet.getCell(`C${i}`).value = "Sub-Total";
      quantityProperties.forEach((data, index) => {
        worksheet.getCell(i, 4 + index * 5).value = _.sumBy(
          items,
          `${data}.formwork`
        );

        let startIndexs = [4, 5, 6, 7, 8];
        startIndexs.forEach((startI) => {
          let startCellAddress = worksheet.getCell(
            iStart,
            startI + index * 5
          )._address;
          let endCellAddress = worksheet.getCell(
            i - 1,
            startI + index * 5
          )._address;

          worksheet.getCell(i, startI + index * 5).value = {
            formula: `SUM(${startCellAddress}:${endCellAddress})`,
            result: 7,
          };
        });
      });

      for (var columnIndex = 1; columnIndex <= lastColumn; columnIndex++) {
        worksheet.getRow(i).getCell(columnIndex).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "c5d0e0" },
        };
      }

      i++;
    });

    //Grand total

    let dataFiltered = hotData.filter((x) => !x.isParrent);
    worksheet.getCell(`C${i}`).value = "Grand Total Qty";

    quantityProperties.forEach((data, index) => {
      let startIndexs = [4, 5, 6, 7, 8];
      startIndexs.forEach((startI) => {
        let startCellAddress = worksheet.getCell(
          9,
          startI + index * 5
        )._address;
        let endCellAddress = worksheet.getCell(
          i - 1,
          startI + index * 5
        )._address;

        worksheet.getCell(i, startI + index * 5).value = {
          formula: `SUM(${startCellAddress}:${endCellAddress})/2`,
          result: 7,
        };
      });
    });

    for (var columnIndex = 1; columnIndex <= lastColumn; columnIndex++) {
      worksheet.getRow(i).getCell(columnIndex).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC500" },
      };
    }

    for (let index = 7; index <= i; index++) {
      for (let j = 1; j <= lastColumn; j++) {
        worksheet.getCell(index, j).border = borderStyles;
      }
    }

    //set column width
    worksheet.getColumn("A").width = 20;
    worksheet.getColumn("B").width = 20;
    worksheet.getColumn("C").width = 30;

    for (let j = 4; j <= lastColumn; j++) {
      worksheet.getColumn(j).width = 10;
    }

    for (let columnIndex = 1; columnIndex <= lastColumn; columnIndex++) {
      worksheet.getColumn(columnIndex).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
    }

    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), excelName + ".xlsx");
  }

  const hotSettings = {
    colHeaders: true,
    rowHeaders: true,
    rowHeights: 24,
    rowHeaderWidth: 40,
    height: 750,
    columns: columns,
    nestedHeaders: columnHeaders,
    stretchH: "all",
    // multiColumnSorting: true,
    currentRowClassName: "currentRow",
    dropdownMenu: [
      "filter_by_condition",
      "filter_by_value",
      "filter_action_bar",
    ],
    filters: true,
    search: true,
    licenseKey: "non-commercial-and-evaluation",
  };

  return (
    <div id="quantity-tracking">
      <div className="mb-2 mt-2 ml-2">
        <Upload
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          beforeUpload={handleImport}
          showUploadList={false}
          className="mr-2"
        >
          <Button type="primary">
            <Icon type="upload" /> Import
          </Button>
        </Upload>
        <Button type="primary" className="mr-2" onClick={handleExport}>
          Export
        </Button>

        <Button type="primary" className="mr-2" onClick={handleSave}>
          Save
        </Button>

        <Button type="primary" className="mr-2" onClick={handleReloadActual}>
          Reload Actual
        </Button>
      </div>

      <Spin tip={loadingTip} size="large" spinning={isLoadingSpin}>
        <HotTable
          ref={hotTableRef}
          data={hotData}
          settings={hotSettings}
          dataSchema={dataScheme}
          contextMenu={contextMenuHotTable}
          className="quantiy-tracking-table"
          cells={(row, col, prop) => {
            const cellProperties = {};
            if (hotData[row] && hotData[row].rowType == 1) {
              cellProperties.readOnly = true;
              cellProperties.className = "cell-group2";
            } else if (hotData[row] && hotData[row].rowType == 2) {
              cellProperties.readOnly = true;
              cellProperties.className = "cell-group1";
            } else {
              // cellProperties.className = "custom-cell-inv-no";
            }

            return cellProperties;
          }}
        />
      </Spin>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    excelPage: state.excel.excelPage,
  };
}

export default connect(mapStateToProps)(QuantityTracking);
