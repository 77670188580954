import React from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox } from 'rsuite';

import { Table, Pagination, Dropdown, Input } from 'semantic-ui-react'
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,

} from 'react-table'
// import './scss/SideBarSelectParameter.scss'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Tooltip from '@material-ui/core/Tooltip';
import NearMeIcon from '@material-ui/icons/NearMe';
import { Popconfirm, message, Upload } from 'antd';
import {
  getSubRowsTableWithoutElementId, exportTableToExcel, handleCollapse, handleExpand,ListValueFilter,EditableCell
} from '../function/TableFunction'




function TableCFA({ columns, data,
  getRowProps,
  getCellProps,
  viewer,
  onActiveTable,
  listGuidElement,
  onChangeHeader,
  skipPageReset,
  handleUpdateData,
  handleReadData,
  handleOpenPanelEditType
}) {

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter.bind(this, onChangeHeader),
      Cell: EditableCell.bind(this,handleOpenPanelEditType),
    }),
    []
  )
 

  // function EditableCell({
  //   cell: { value: initialValue },
  //   row,
  //   column: { id, canEdit },
  //   handleUpdateData, // This is a custom function that we supplied to our table instance
  // }) {
  //   // We need to keep and update the state of the cell normally
  //   const [value, setValue] = React.useState(initialValue)

  //   const onChange = e => {
  //     setValue(e.target.value)
  //   }

  //   // We'll only update the external data when the input is blurred
  //   const onBlur = () => {
  //     // if(parseFloat(e.target.value) !== parseFloat(value))
  //     var nodes = getSubRowsTableWithoutElementId(row.subRows)
  //     // handleUpdateData(row.index, id, )
  //     handleUpdateData(nodes, id, value)
  //   }
  //   const changeFollowLevel = () => {
  //     handleOpenPanelEditType(row, value)
  //   }


  //   React.useEffect(() => {
  //     setValue(initialValue)
  //   }, [initialValue])

  //   return canEdit ? <div style={{ display: 'inline' }}>
  //     <Input type="number" transparent size='small' value={value} onChange={onChange} onBlur={onBlur} />
  //     <IconButton style={{ float: 'right' }} size="xs" icon={<Icon icon="edit" />} onClick={changeFollowLevel}></IconButton>
  //   </div>
  //     : <Input transparent size='small' disabled={true} value={value} onChange={onChange} onBlur={onBlur} />
  // }


  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },
      handleUpdateData,
      autoResetPage: !skipPageReset,
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'selection',
            groupByBoundary: true,
            collapse: true,
            width: 10,
            Footer: '',
            Header: ({ }) => (
              <></>
            ),
            Cell: ({ row }) => (
              <NearMeIcon style={{ color: 'gray', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => {
                  showType(row)
                }} />
            ),
          },
          ...columns,
        ]
      })
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    flatColumns,
    flatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      groupBy,
      pageIndex, pageSize
    }
  } = instance

  const columnCount = columns.length
  const showType = (row) => {
    viewer.clearSelection()
    let tempId = []
    if (row.subRows.length !== 0) {
      var nodes = getSubRowsTableWithoutElementId(row.subRows)
      _.forEach(nodes, node => {
        if (listGuidElement[node.original.guid]) {
          tempId.push(listGuidElement[node.original.guid].dbId)
        }
      })
    } else {
      if (listGuidElement[row.original.guid]) {
        tempId.push(listGuidElement[row.original.guid].dbId)
      }
    }
    viewer.select(tempId);
  }
  const setGroup = () => {
    (flatColumns.map(d => {
      try {
        if (d.isGrouped)
          d.toggleGroupBy()
      } catch{ }

    }))
    onActiveTable()
  }
  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { value }) => {

    setPageSize(value)
  }

  function beforeUpload(file) {
    let name = file.name.split('.');
    const isRvtorIFC = name[name.length - 1] === 'json';
    if (!isRvtorIFC) {
      message.error('You can only upload json file!');
    }
    return isRvtorIFC;
  }
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: async ({ file }) => {
      if (file !== null) {
        var reader = new FileReader();
        reader.onload = function () {
          var text = JSON.parse(reader.result);
          handleReadData(text)

        }

        reader.readAsText(file);
      }
    },
  };
  const groupByScheduleSaved = ['WH_Zone', 'WH_Level', 'WH_Group', 'TypeName']

  return (
    <div>
      <ButtonGroup style={{ position: 'absolute', zIndex: '1' }}>
        <Popconfirm placement="bottom" title={'Are you sure to export this schedule?'} onConfirm={() => exportTableToExcel('tableCFA')} okText="Yes" cancelText="No">
          <Tooltip title="Export" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="export" />}   ></IconButton>
          </Tooltip>
        </Popconfirm>
        <Whisper placement='bottomLeft' trigger="click" speaker={
          <Popover >
            <ButtonGroup>
              <Button size="sm" onClick={() => handleExpand(flatRows, 0, groupBy)} disabled={groupBy.length < 2} >1</Button>
              <Button size="sm" onClick={() => handleExpand(flatRows, 1, groupBy)} disabled={groupBy.length < 3}>2</Button>
              <Button size="sm" onClick={() => handleExpand(flatRows, 2, groupBy)} disabled={groupBy.length < 4}>3</Button>
            </ButtonGroup>
          </Popover>
        }>
          <Tooltip title="Expand" placement="bottom">
            <IconButton size="sm" icon={<Icon icon="minus-square-o" />}  ></IconButton>
          </Tooltip>
        </Whisper>
        <Tooltip title="Collapse" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="plus-square-o" />} onClick={() => handleCollapse(flatRows)} ></IconButton>
        </Tooltip>
      </ButtonGroup>

      <div style={{ float: 'right' }}>
        <Pagination style={{ display: data.length !== 0 ? 'inline-block' : 'none' }}
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>

        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle', display: data.length !== 0 ? 'inline-block' : 'none' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />
        <Upload {...props} beforeUpload={beforeUpload} >
          <Button>
            <Icon icon="file-upload" /> Open *.json
           </Button>
        </Upload>
        <ButtonGroup style={{ right: 0, top: 0, cursor: 'pointer', backgroundColor: '#ffffff00', zIndex: '1', marginLeft: '15px' }}>
          <IconButton size="xs" icon={<Icon icon="close" />} onClick={() => setGroup()}></IconButton>
        </ButtonGroup>
      </div>


      <div style={{ position: 'absolute', top: 35, overflow: 'auto', bottom: 0, borderBottomStyle: 'solid', borderTopStyle: 'solid', borderWidth: 'thin', width: '100%' }}>
        <Table celled selectable id="tableCFA" {...getTableProps()} >
          {/* <Sticky> */}
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
                    width: column.id === 'selection' ? 5 : column.id === 'setcolor' ? 25 : 'auto'
                  }}>
                    {!column.isGrouped && (groupByScheduleSaved.includes(column.id) && column.toggleGroupBy())}
                    {/* {(column.id !== 'selection' && column.id !== 'setcolor') &&
                      <span {...column.getGroupByToggleProps()}  >
                        {column.isGrouped ? <RemoveCircleOutlineIcon style={{ color: 'red', fontSize: '15px', verticalAlign: 'sub' }} />
                          : <AddCircleOutlineIcon style={{ color: 'green', fontSize: '15px', verticalAlign: 'sub' }} />}
                      </span>} */}
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          {/* </Sticky> */}
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{}} >
                        {cell.isGrouped ? (
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.subRows.length !== 0 &&
                                ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                  (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                              }
                            </span>{' '}
                            {cell.render('Cell')}

                          </>
                        ) : cell.isAggregated ? (

                          cell.render('Aggregated')
                        ) : cell.isRepeatedValue ? null : (
                          cell.render('Cell')

                        )}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer>
            {footerGroups.map(group => (
              <Table.Row {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  column.id === 'selection' ?
                    <Table.HeaderCell style={{ textAlign: 'left', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px', fontWeight: 'bold' }} {...column.getFooterProps()} >
                    </Table.HeaderCell>
                    :
                    <Table.HeaderCell style={{ textAlign: 'right', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px' }} {...column.getFooterProps()} >
                      {column.render('Footer')}</Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>
        </Table>
      </div>

    </div>
  )
}

export default TableCFA