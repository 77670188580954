import React, { useState, useEffect } from 'react';
import { Icon, Button, Select, Input, DatePicker, Badge, Tooltip } from 'antd';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { getSubmitStatusForRangeDays } from '../../api/workerallocation';
const { Option } = Select;

function Calendar(props) {
  const { onChange } = props;
  const [days, setDays] = useState([]);
  const [allDaysOfMonth, setAllDayOfMonth] = useState([1, 2]);
  const [currentDay, setCurrentDay] = useState(moment());
  const [today, setToday] = useState(moment());
  const [submitData, setSubmitData] = useState([]);
  const projectId = useSelector((state) => state.project.projectId);

  useEffect(() => {
    let days14 = getISOWeekDates2(moment(), 0);
    setDays(days14);
    checkDaySubmit();
  }, []);

  useEffect(() => {
    onChange(currentDay);
  }, [currentDay]);

  useEffect(() => {
    checkDaySubmit();
  }, [days, props.reload]);

  async function checkDaySubmit() {
    if (_.isArray(days) && days.length > 0) {
      let start = days[0];
      let end = days[days.length - 1];

      let dt = await getSubmitStatusForRangeDays({
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
        projectId,
      });

      await setSubmitData(dt.data);
    }
  }

  function getCurrentDayInfo(type = 1) {
    if (type === 1 && currentDay) {
      return currentDay.date();
    }

    if (type === 2 && currentDay) {
      return currentDay.month() + 1;
    }

    if (type === 3 && currentDay) {
      return currentDay.year();
    }
  }

  function nextPrevDays(isNext = true) {
    if (isNext) {
      let days14 = getISOWeekDates2(days[days.length - 1], 1);

      setDays(days14);
    } else {
      let days14 = getISOWeekDates2(days[0], -1);

      setDays(days14);
    }
  }

  function getISOWeekDates2(dayMoment, type = 1) {
    let dates = [];
    if (type === 1) {
      for (let i = 1; i < 15; i++) {
        dates.push(moment(dayMoment).add(i, 'day'));
      }
    }
    if (type === -1) {
      for (let i = 1; i < 15; i++) {
        dates.push(moment(dayMoment).add(i * -1, 'day'));
      }

      dates.reverse();
    }

    if (type === 0) {
      for (let i = -7; i < 7; i++) {
        dates.push(moment(dayMoment).add(i, 'day'));
      }
    }

    return dates;
  }

  function getMonthRangeName() {
    if (_.isArray(days) && days.length > 0) {
      let first = days[0];
      let last = days[days.length - 1];
      if (first.format('MMMM YYYY') === last.format('MMMM YYYY')) {
        return first.format('MMMM YYYY');
      } else {
        return first.format('MMMM YYYY') + ' - ' + last.format('MMMM YYYY');
      }
    }
  }

  function handleDateBoxClick(x) {
    if (x.isAfter(moment())) {
      return;
    }
    setCurrentDay(x);
  }

  function getStyleDateBox(date, isNotSubmit) {
    let style = {};
    if (date.isSame(currentDay, 'day')) {
      style = { background: '#f5dcdc', border: '3px solid black' };
    } else {
      style = { background: '#f8f9ff' };
    }

    if (isNotSubmit) {
      style = { ...style, color: 'red' };
    }

    if (date.isSame(today, 'day')) {
      style = { ...style, border: '1px solid #1890ff', color: '#1890ff' };

      if (date.isSame(currentDay, 'day')) {
        style = { ...style, border: '3px solid #1890ff', color: '#1890ff' };
      }
    }

    if (date.isAfter(today, 'day')) {
      style = { ...style, cursor: 'not-allowed', background: 'lightgrey' };
    }

    return style;
  }

  function checkIsNotSubmit(date) {
    if (_.isArray(submitData)) {
      let item = submitData.find((x) => date.format("DD-MM-YYYY") == x.date);
      return item;
    }

    return null;
  }

  function onChangeDatePicker(date, dateString) {
    let days14 = getISOWeekDates2(date, 0);
    setCurrentDay(date);
    setDays(days14);
  }

  const dateFormat = 'DD/MM/YYYY';

  function generateDayBox() {
    return days.map((x, index) => {
      let item = checkIsNotSubmit(x);
      return (
        <div
          className='text-center'
          key={index}
          onClick={() => handleDateBoxClick(x)}
        >
          {item ? (
            <Tooltip
              placement='bottom'
              title={item.supervisorNotSubmits.toString()}
            >
              <span className='dateString text-uppercase'>
                {x.format('ddd')}
              </span>
              <div className='dayBox' style={getStyleDateBox(x, true)}>
                <span>{x.format('DD')}</span>
              </div>
            </Tooltip>
          ) : (
            <div>
              <span className='dateString text-uppercase'>
                {x.format('ddd')}
              </span>
              <div className='dayBox' style={getStyleDateBox(x)}>
                <span>{x.format('DD')}</span>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  function disabledEndDate(endValue) {
    if (!endValue || !currentDay) {
      return false;
    }
    return endValue.valueOf() > currentDay.valueOf();
  }

  return (
    <div style={{ maxWidth: '800px' }}>
      <div className='d-flex mb-2 flex-row justify-content-between'>
        <span className='text-center font-weight-bold'>
          {getMonthRangeName()}
        </span>
        <DatePicker
          disabledDate={disabledEndDate}
          value={currentDay}
          format={dateFormat}
          allowClear={false}
          onChange={onChangeDatePicker}
        />
      </div>
      <div className='d-flex mb-2 flex-row justify-content-between align-items-end workerCalender'>
        <Icon
          onClick={() => nextPrevDays(false)}
          className='mb-2 mr-2'
          type='left'
          style={{ fontSize: '20px', color: '#08c' }}
        />
        {generateDayBox()}
        <Icon
          onClick={() => nextPrevDays(true)}
          className='mb-2'
          style={{ fontSize: '20px', color: '#08c' }}
          height='50px'
          type='right'
        />
      </div>
    </div>
  );
}

export default Calendar;
