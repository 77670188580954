import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon } from 'antd'
import _ from 'lodash';
import { PDFDocument, StandardFonts, rgb, } from 'pdf-lib'
import { setPDFData } from '../../../actions/app/pdf'
import InputColor from './input/InputColor'
import InputSize from './input/InputSize'
const TextIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
        <path d="M19.39,4.77V7.64A.43.43,0,0,1,19,8h-.82a.41.41,0,0,1-.4-.41V6.51H13.34V18h1.54a.41.41,0,0,1,.41.41v.81a.41.41,0,0,1-.41.41H9.14a.41.41,0,0,1-.41-.41v-.81A.41.41,0,0,1,9.14,18h1.54V6.51H6.27V7.64A.41.41,0,0,1,5.86,8H5A.43.43,0,0,1,4.6,7.6V4.77A.41.41,0,0,1,5,4.36H19A.39.39,0,0,1,19.39,4.77Z"></path></svg>
);
const FreeHandIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14"><path class="cls-1" d="M18.91,19.66a1.13,1.13,0,0,1-1.35,1.82,5.82,5.82,0,0,0-2.09-1c-3.22-.85-4.57-.29-6.82.63l-.55.23A7.42,7.42,0,0,1,5.42,22a3,3,0,0,1-2-.69,2.38,2.38,0,0,1-.87-1.66,1.81,1.81,0,0,1,.59-1.36,9.16,9.16,0,0,1,.93-.78,1.13,1.13,0,0,1,1.58.23,1.14,1.14,0,0,1-.23,1.59,2.93,2.93,0,0,0-.42.33c.27.13.84.17,2.25-.4L7.8,19a10.81,10.81,0,0,1,8.25-.72A7.89,7.89,0,0,1,18.91,19.66Z"></path><path fill="default" class="cls-1" d="M21.45,5.77a2.09,2.09,0,0,1-.62,1.51l-9.16,9.16c-.09.08-.26.26-.44.26,0,0-4.18.9-4.42.89a1,1,0,0,1-.72-.13.67.67,0,0,1-.16-.73l.8-4.53c0-.18.08-.27.26-.45L13,5.8l3.2-3.2a2.2,2.2,0,0,1,3,0l1.66,1.66A2.34,2.34,0,0,1,21.45,5.77ZM16.21,9.06,14.37,7.22,8.59,13l1.84,1.84Zm3.2-3.2L17.57,4,15.88,5.71l1.84,1.84Z"></path></svg>
)
function AnnotateBar(props) {



    return (
        <>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'freehand' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'freehand' ? 'freehand' : '' })
            }} >
                <Icon component={
                    FreeHandIcon
                } />
            </Button>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'text' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'text' ? 'text' : '' })
            }} >
                <Icon component={
                    TextIcon
                } />
            </Button>
            <div style={{ backgroundColor: 'lightgray', width: 1, marginLeft: 5, marginRight: 5 }} />
            <InputColor />
            <InputSize />
        </>
    )
}

AnnotateBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(AnnotateBar)