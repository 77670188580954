import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { HotTable } from "@handsontable/react";
import { func_process_conc_inv_data } from "../../function/func_process_data";
import { message } from "antd";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const TableConcInv = ({ tableRef, data, filterMode, generalState, forceReRender, setData, setStatistic }) => {
   const [project_id, set_project_id] = useState();
   const [user_role, set_user_role] = useState();

   useEffect(() => {
      const role = localStorage.getItem("fp_user_role");
      set_user_role(role);
      const pid = localStorage.getItem("fp_project_id");
      set_project_id(pid);

      const fetchdata = async () => {
         // const getlistInv = await axios.get("/api/do_inv/s3rds/list_invoice", {
         //   params: { project_id: pid },
         // });
         // const listInvoiceName = getlistInv.data.data.items;

         const res = await axios.get("/api/do_inv/common/get_data_by_date_range", {
            params: {
               currentFilterMode: filterMode,
               project_id: pid,
               current: "conc_inv",
            },
         });
         const dt = res.data.data.items;
         const res_f = await func_process_conc_inv_data(dt, pid);
         if (res_f !== undefined) {
            setData(res_f.processed_data);
            setStatistic(res_f.staticInfo);
         }
      };
      fetchdata();
   }, [filterMode, generalState, forceReRender]);

   const columns = [
      {
         title: "Inv No",
         data: "inv_number",
         type: "text",
      },
      {
         title: "Do No",
         data: "do_number",
         type: "text",
      },
      {
         title: "Date",
         data: "date",
         type: "date",
         dateFormat: "DD-MM-YYYY",
         datePickerConfig: {
            dateFormat: "DD-MM-YYYY",
         },
      },
      {
         title: "Volume",
         data: "volume",
         type: "numeric",
         strict: true,
         allowInvalid: false,
         numericFormat: {
            pattern: "0,0.0",
         },
      },

      {
         title: "Product",
         data: "product",
         type: "text",
         wordWrap: false,
      },

      {
         title: "Supplier",
         data: "supplier",
         type: "dropdown",
         source: ["ALLIANCE", "PAN U", "ISLAND"],
      },
      {
         title: "Unit price",
         data: "unit_price",
         type: "numeric",
         strict: true,
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },

      {
         title: "Amount claim",
         data: "amount_claim",
         type: "numeric",
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },

      {
         title: "Amt+ 7% GST",
         data: "amount_gst",
         type: "numeric",
         allowInvalid: false,
         numericFormat: {
            pattern: "$ 0,0.0",
         },
      },
      {
         title: "Pour Name",
         data: "pour_name",
         type: "text",
      },
      {
         title: "Pdf file",
         data: "s3_key_check",
         renderer: "html",
         readOnly: true,
      },
      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 750,
      columns: columns,
      colWidths: [85, 85, 100, 80, 200, 110, 90, 100, 110, 90, 90, 160],
      stretchH: "last",
      // multiColumnSorting: true,
      currentRowClassName: "currentRow",
      dropdownMenu: ["filter_by_condition", "filter_by_value", "filter_action_bar"],
      filters: true,
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      if (tableRef.current !== null && row === 0 && col === 0) {
         const hotTable = tableRef.current.hotInstance;
         const temp_data = hotTable.getData();
         // const rows = hotTable.countRenderedRows();
         for (let i = 0; i < temp_data.length; i++) {
            if (temp_data[i][1] !== null) {
               if (temp_data[i][1].includes("nos")) {
                  for (let j = 0; j < 12; j++) {
                     if (j !== 2) {
                        hotTable.setCellMeta(i, j, "readOnly", true);
                     }
                     hotTable.setCellMeta(i, j, "className", "custom-cell-group-head");
                  }
               } else {
                  hotTable.setCellMeta(i, 0, "className", "custom-cell-inv-no");
               }
            }
         }

         //format the first cell
         var cell = hotTable.getCell(0, 0);
         cell.readOnly = true;
         cell.className = "custom-cell-group-head";
      }
   };

   const handleOnCellMouseUp = (evens, coors, TD) => {
      if (tableRef.current !== null && coors.col === 10 && TD.innerText !== "" && TD.innerText !== null) {
         const temp_data = tableRef.current.hotInstance.getData();
         let key = "";
         if (temp_data[coors.row][1].includes("nos")) {
            const file_name_seach = project_id + "_conc_inv_" + temp_data[coors.row][0] + ".pdf";
            key = `${project_id}/${"conc_inv"}/${file_name_seach}`;
         } else {
            const file_name_seach = project_id + "_conc_do_" + temp_data[coors.row][1] + ".pdf";
            key = `${project_id}/${"conc_do"}/${file_name_seach}`;
         }
         axios
            .get("/api/do_inv/s3/getlink", {
               params: { file_name: key.toLowerCase() },
            })
            .then((res) => {
               const signedrequesturl = res.data.signedRequest;
               window.open(signedrequesturl);
            });
      }
   };

   const onAfterHotChange = (changes) => {
      if (changes === null) {
         return;
      }
      changes.forEach(([row, column, oldValue, newValue]) => {
         if (column === "date") {
            const hotTable = tableRef.current.hotInstance;
            const cellprop = hotTable.getCellMeta(row, 2);
            if (cellprop.className === "custom-cell-group-head") {
               const inv_name = hotTable.getDataAtCell(row, 0);
               data.forEach((e, index) => {
                  if (e.inv_number === inv_name && e.row_type !== "inv") {
                     const new_info = [index, "inv_date", e.inv_date, dayjs(newValue).format("DD-MM-YYYY")];
                     changes.push(new_info);
                  }
               });
            } else {
               const new_info = [row, "do_date", oldValue, dayjs(newValue).format("DD-MM-YYYY")];
               changes.push(new_info);
            }
         }
      });
      const newData = _.cloneDeep(data);
      if (tableRef.current !== null) {
         const hot = tableRef.current.hotInstance;
         changes.forEach(([row, column, oldValue, newValue]) => {
            const r = hot.toPhysicalRow(row);
            newData[r].change = true;
            newData[r][column] = newValue;
         });
         setData(newData);
      } else {
         message.warn("Sorry changed data will not able to save!");
      }

      return false;
   };

   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={data}
            afterRenderer={addClassesToRows}
            afterOnCellMouseUp={handleOnCellMouseUp}
            afterChange={onAfterHotChange}
            className="do-invoice-table"
         />
      </div>
   );
};

export default TableConcInv;
