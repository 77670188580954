import React, { useEffect, useState } from "react";
import { Menu, Statistic, Space } from "antd";

const SideMenu = ({ current, setcurrent, setData, setTempData }) => {
   useEffect(() => {
      setcurrent("sum");
      // setStaticData(getdata);
   }, []);

   const HandleClick = (e) => {
      if (current !== e.key) {
         setData([]);
         setTempData([]);
      }

      setcurrent(e.key);
   };

   return (
      <div>
         <h6 style={{ color: "yellow", textAlign: "center" }}>QAQC Dashboard</h6>
         <Menu defaultSelectedKeys={["sum"]} onClick={(e) => HandleClick(e)} theme="dark" className="left-menu">
            <Menu.Item key="sum">Summary</Menu.Item>
            <Menu.Item key="common">Common</Menu.Item>
            <Menu.Item key="critical">Critical</Menu.Item>
            {/* <Menu.Item key="rework">Rework</Menu.Item> */}
            {/* <Menu.Item key="violation">Violation</Menu.Item> */}
            <Menu.Item key="observation">Observation</Menu.Item>
            <Menu.Item key="qmjot">QM (Jot)</Menu.Item>
            <Menu.Item key="qmidd">Handy</Menu.Item>
            <Menu.Item key="unitsetting">Units Setting</Menu.Item>
         </Menu>
      </div>
   );
};

export default SideMenu;
