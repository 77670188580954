import TranslateTool from './Viewing.Tool.Translate'
import RotateTool from './Viewing.Tool.Rotate'
import ScaleTool from './Viewing.Tool.Scale'
import ExtensionBase from '../utils/Viewer.ExtensionBase'
import ViewerToolkit from '../utils/Viewer.Toolkit'

const Autodesk = window.Autodesk
const THREE = window.THREE
class TransformExtension extends ExtensionBase {

  /////////////////////////////////////////////////////////////////
  // Class constructor
  //
  /////////////////////////////////////////////////////////////////
  constructor (viewer, options) {

    super (viewer, options)

    this.translateTool = new TranslateTool(viewer)

    this._viewer.toolController.registerTool(
      this.translateTool)

    this.rotateTool = new RotateTool(viewer)

    this._viewer.toolController.registerTool(
      this.rotateTool)

    this.scaleTool = new ScaleTool(viewer)

      this._viewer.toolController.registerTool(
        this.scaleTool)
    
    this.translateTool.on('transform.translate.complete', (data) => {

      console.log( 'broadcast transform of '
        + data.externalId
        + ': ' + data.translation.x.toFixed( 2 )
        + ','+ data.translation.y.toFixed( 2 )
        + ','+ data.translation.z.toFixed( 2 ) );

      
    })    
  }

  /////////////////////////////////////////////////////////////////
  // Extension Id
  //
  /////////////////////////////////////////////////////////////////
  static get ExtensionId () {

    return 'Viewing.Extension.Transform'
  }

  /////////////////////////////////////////////////////////////////
  // Load callback
  //
  /////////////////////////////////////////////////////////////////
  load () {

    this._txControl = ViewerToolkit.createButton(
      'toolbar-translate',
      'fas fa-arrows-alt',
      'Translate Tool', () => {

        var txTool = this.translateTool.getName()
        var rxTool = this.rotateTool.getName()
        var sxTool = this.scaleTool.getName()
        if (this.translateTool.active) {

          this._viewer.toolController.deactivateTool(txTool)
          this._txControl.container.classList.remove('active')
          this._comboCtrl.container.classList.remove('active')

        } else {

          this._viewer.toolController.activateTool(txTool)
          this._txControl.container.classList.add('active')

          this._viewer.toolController.deactivateTool(rxTool)
          this._viewer.toolController.deactivateTool(sxTool)
          this._rxControl.container.classList.remove('active')
          this._sxControl.container.classList.remove('active')

          this._comboCtrl.container.classList.add('active')
        }
      })

    this._rxControl = ViewerToolkit.createButton(
      'toolbar-rotate',
      'fa fa-refresh',
      'Rotate Tool', () => {

        var txTool = this.translateTool.getName()
        var rxTool = this.rotateTool.getName()
        var sxTool = this.scaleTool.getName()
        if (this.rotateTool.active) {

          this._viewer.toolController.deactivateTool(rxTool)
          this._rxControl.container.classList.remove('active')
          this._comboCtrl.container.classList.remove('active')

        } else {

          this._viewer.toolController.activateTool(rxTool)
          this._rxControl.container.classList.add('active')

          this._viewer.toolController.deactivateTool(txTool)
          this._viewer.toolController.deactivateTool(sxTool)
          this._txControl.container.classList.remove('active')
          this._sxControl.container.classList.remove('active')

          this._comboCtrl.container.classList.add('active')
        }
      })
      this._sxControl = ViewerToolkit.createButton(
        'toolbar-scale',
        'fas fa-expand-arrows-alt',
        'Scale Tool', () => {
  
          var txTool = this.translateTool.getName()
          var rxTool = this.rotateTool.getName()
          var sxTool = this.scaleTool.getName()
          if (this.scaleTool.active) {
  
            this._viewer.toolController.deactivateTool(sxTool)
            this._sxControl.container.classList.remove('active')
            this._comboCtrl.container.classList.remove('active')
  
          } else {
  
            this._viewer.toolController.activateTool(sxTool)
            this._sxControl.container.classList.add('active')
  
            this._viewer.toolController.deactivateTool(txTool)
            this._viewer.toolController.deactivateTool(rxTool)
            this._txControl.container.classList.remove('active')
            this._rxControl.container.classList.remove('active')
  
            this._comboCtrl.container.classList.add('active')
          }
        })
  
    this.parentControl = this._options.parentControl

    if (!this.parentControl) {

      var viewerToolbar = this._viewer.getToolbar(true)

      this.parentControl = new Autodesk.Viewing.UI.ControlGroup(
        'transform')

      viewerToolbar.addControl(this.parentControl)
    }

    this._comboCtrl = new Autodesk.Viewing.UI.ComboButton(
      'transform-combo')

    this._comboCtrl.setToolTip('Transform Tools')

    this._comboCtrl.icon.style.fontSize = '24px'
    this._comboCtrl.icon.style.transform = 'rotateY(180Deg)'

    this._comboCtrl.icon.className =
      'fas fa-wrench'

    this._comboCtrl.addControl(this._txControl)
    this._comboCtrl.addControl(this._rxControl)
    // this._comboCtrl.addControl(this._sxControl)
    var openCombo = this._comboCtrl.onClick

    this._comboCtrl.onClick = (e) => {

      if(this._comboCtrl.container.classList.contains('active')) {

        this._txControl.container.classList.remove('active')
        this._rxControl.container.classList.remove('active')
        this._sxControl.container.classList.remove('active')

        this._comboCtrl.container.classList.remove('active')

        var txTool = this.translateTool.getName()
        var rxTool = this.rotateTool.getName()
        var sxTool = this.rotateTool.getName()

        this._viewer.toolController.deactivateTool(txTool)
        this._viewer.toolController.deactivateTool(rxTool)
        this._viewer.toolController.deactivateTool(sxTool)
      } else {

        openCombo()
      }
    }

    this.parentControl.addControl(this._comboCtrl)

    console.log('Viewing.Extension.Transform loaded')

    return true
  }

  /////////////////////////////////////////////////////////////////
  // Unload callback
  //
  /////////////////////////////////////////////////////////////////
  unload () {

    this.parentControl.removeControl(
      this._comboCtrl)

    this._viewer.toolController.deactivateTool(
      this.translateTool.getName())

    this._viewer.toolController.deactivateTool(
      this.rotateTool.getName())

      this._viewer.toolController.deactivateTool(
        this.scaleTool.getName())

    console.log('Viewing.Extension.Transform unloaded')
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  TransformExtension.ExtensionId,
  TransformExtension)

  export default 'Viewing.Extension.Transform'