import React from 'react';
import _ from 'lodash'
// import DateTimeToolkit from '../AutodeskExtensions/Viewer/DateTimeToolkit';

import axios from "axios";
import "react-input-range/lib/css/index.css";

// import '../../../scss/FogreCustom.scss'
import Draggable from 'react-draggable';
import { Empty, Row, Col, message, Tag, Form, Typography } from 'antd';
import { Loader, Modal, IconButton, ButtonGroup, Icon, DatePicker, DateRangePicker } from 'rsuite';
import { Button } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
//!
import { convertHexColorToVector4 } from '../../../function/TableFunction'
import { getAllElementdbIdsOneModel } from '../../../function/ForgeFunction'
import { timelineChangeProgress, setDateByPanel, setDateStartEndByPanel,handleModulePPVCAndPrecast } from '../../../function/ProgressFunction'
import { trackingUserUsing } from '../../../function/AdminFunction'
import PPVCTable from '../PPVCTable'
import moment from 'moment'

import { DraggableAreasGroup } from 'react-draggable-tags'
import * as Timeline from "vis-timeline/standalone";


const timeline = Timeline
const group = new DraggableAreasGroup();
const DraggableArea = group.addArea();
const { Text } = Typography;
const THREE = window.THREE;


const {
  allowedRange,
} = DateRangePicker;

class SummaryPPVC extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      actionMinDate: null,
      actionMaxDate: null,
      timeValue: {
        min: null,
        max: null,
      },
      levelValue: 0,
      openDock: false,

      loading: false,
      isShowPlan: false,
      viewBy: 'date',
      instantData: null,
      instantDataSheet: null,
      openSettingPanel: false,
      loadingSetting: false,
      levels: [],
      tempLevels: [],
      openPortalEnd: false,
      openPortalStart: false,
      openPieChart: true,
      openDateControl: false,
      detail: false,
      isShowList: false,
      tableDetail: []
    };
    this.smartSheetData = []

    this.targetPlaneMesh = null
    this.timelinepanel = null
    this.groups = null
    this.items = null
    this.moduleData = null
    this.modelData = {
      dbIds: [],
      data: []
    }
    this.currentData = {
      castingCompletedData: [],
      deliveredToFitouYardData: [],
      completedFitoutData: [],
      devliveredToSiteData: [],
      installOnSiteData: [],

      castingCompletedDataTargetCount: 0,
      deliveredToFitouYardDataTargetCount: 0,
      completedFitoutTargetCount: 0,
      devliveredToSiteDataTargetCount: 0,
      installOnSiteDataTargetCount: 0
    };
    this.dataPPVC = []
  }
  componentWillMount = () => {
    trackingUserUsing(this.props.email, this.props.projectId, 'BIM App', 'Summary Precast')
    this.getModules()
  }

  componentWillUnmount = () => {
    try {
      // this.props.viewer.clearThemingColors(this.props.viewer.model)
      if (this.props.viewer && this.props.viewer.overlays.hasScene('target-Plane')) {
        this.props.viewer.overlays.removeScene('target-Plane');
      }
    }
    catch { }
  }
  getLevelDataFromDB = () => {
    axios.get(`/api/versions/get-level-date?q=${this.props.itemId}`)
      .then(res => {
        if (res.data.length !== 0) {
          let temp = []
          let count = res.data.length
          _.forEach(this.props.aecModelData.levels, (v, k) => {
            let index = _.findIndex(res.data, function (o) { return o.id === v.guid })
            if (index >= 0) {
              temp.push({
                id: v.guid, content: v.name, date: res.data[index].date,
                sort: res.data[index].sort, elevation: v.elevation
              })
            } else {
              temp.push({ id: v.guid, content: v.name, date: null, sort: count + 1, elevation: v.elevation })
              count++
            }
          })
          temp.sort(sortLevel)
          this.setState({ levels: temp, tempLevels: temp, loadingSetting: false })
        } else {
          let temp = []
          _.forEach(this.props.aecModelData.levels, item => {
            temp.push({ id: item.guid, content: item.name, date: null, elevation: item.elevation })
          })
          this.setState({ levels: temp, tempLevels: temp, loadingSetting: false })
        }
      })
      .catch(err => this.setState({ loadingSetting: false }))
    function sortLevel(a, b) {

      if (a.sort < b.sort) {
        return -1;
      }
      if (a.sort > b.sort) {
        return 1;
      }
      return 0;
    }
  }
  handleCloseDock = () => {
    this.props.viewer.showAll()
    // this.props.viewer.clearThemingColors(this.props.viewer.model)
    this.props.onChangeDockDisplay('summaryPrecastDock', false)
    if (this.props.viewer && this.props.viewer.overlays.hasScene('target-Plane')) {
      this.props.viewer.overlays.removeScene('target-Plane');
    }
  }
  //#region //? Get Smart Sheet Data
  getSmartSheetData = () => {
    axios.post("/api/smartsheet/getsheet", { smartSheetId: this.props.smartSheetId })
      .then(res => {
        this.smartSheetData = []
        _.forEach(res.data, row => {
          let rowContent = {
            fromDate: null,
            toDate: null,
            castingValue: 0,
            fitOutDelivered: 0,
            fitOutCompleted: 0,
            siteDelivered: 0,
            siteCompleted: 0,
          }
          if (row.cells[3] && row.cells[4]) {
            let fromDateString = row.cells[3].value
            let toDateString = row.cells[4].value
            if (fromDateString && fromDateString.length > 0) {
              let fromDate = moment(fromDateString, 'YYYY-MM-DD').toDate()
              if (toDateString && toDateString.length > 0) {
                let toDate = moment(toDateString, 'YYYY-MM-DD').toDate()
                rowContent.fromDate = fromDate;
                rowContent.toDate = toDate;

                let valueString = row.cells[5].value;
                if (valueString) {
                  rowContent.castingValue = valueString;
                }
                valueString = row.cells[6].value;
                if (valueString) {
                  rowContent.fitOutDelivered = valueString;
                }
                valueString = row.cells[7].value;
                if (valueString) {
                  rowContent.fitOutCompleted = valueString;
                }
                valueString = row.cells[8].value;
                if (valueString) {
                  rowContent.siteDelivered = valueString;
                }
                valueString = row.cells[9].value;
                if (valueString) {
                  rowContent.siteCompleted = valueString;
                }
                this.smartSheetData.push(rowContent);
              }
            }
          }
        })
        this.getModelData()
        if (this.props.aecModelData)
          this.getLevelDataFromDB()
      })
  }
  //#endregion
  //#region //? Get Data
  getModules = () => {
    this.setState({ loading: true, openDock: this.props.openDock })
    axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
      .then(res => {
        if (res && res.data.isSuccessful) {
          this.moduleData = res.data.result;
          this.getModelData()
          if (this.props.aecModelData)
            this.getLevelDataFromDB()
          // this.getSmartSheetData()
        }
        else {
          message.warning("Can't get data from apps.wohhup.com")
        }
      })
  }

  getModelData = async () => {
    this.modelData.dbIds = getAllElementdbIdsOneModel(this.props.viewer);
    if (this.modelData.dbIds && this.modelData.dbIds.length > 0) {
      let count = this.modelData.dbIds.length
      _.forEach(this.modelData.dbIds, id => {
        this.props.viewer.model.getProperties(id, (modelAProperty) => {
          _.forEach(modelAProperty.properties, (v, k) => {
            if (this.props.projectKey === 'pdd') {
              if (v.displayName === 'PCAPP-Module Code') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
              }
            } else {
              if (v.displayName === 'Mark') {
                if (v.displayValue !== '')
                  this.modelData.data.push({ dbId: id, mark: v.displayValue });
              }
            }

          })
          count--
          if (count === 0) {
            axios.get('/api/customproperty/get-custom-ppvc', { params: { projectKey: this.props.projectKey } })
              .then(async res => {
                this.combineData(res.data.ppvc)
              })
              .catch(err => {
                message.error('Sync file failed')
                this.setState({ loading: false, date: null, newCode: '' })
              })
          }
        })

      })
    }
  }
  //#endregion
  //#region //? Analyze Module Data
  combineData = (ppvc) => {
    _.forEach(this.moduleData, item => {
      let modelItem = this.modelData.data.find(x => x.mark.toLowerCase() === item.code.toLowerCase());
      if (modelItem) {
        item.dbId = modelItem.dbId;
      } else {
        item.dbId = ''
      }
      let index = _.findIndex(ppvc, (o) => { return o.code.toLowerCase() === item.code.toLowerCase() })
      if (index >= 0) {
        if (ppvc[index].newCode !== '' && ppvc[index].date) {
          item.actions.push({
            actionName: `Module Installed on ${this.props.projectKey}`,
            completedDate: moment(ppvc[index].date, 'DD-MM-YYYY').toDate(),
            currentSiteCode: this.props.projectKey,
            currentSiteStorage: null
          })
        }
      }
    });
    this.analyzeMainData();
  }
  analyzeMainData = () => {
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(this.moduleData, this.props.typeProject, this.props.projectKey);
    this.dataPPVC = dataPPVC

    this.setState(
      {
        loading: false,
        actionMinDate: new Date(actionMinDate.getTime()),
        actionMaxDate: new Date(actionMaxDate.getTime()),
        timeValue: {
          min: moment(actionMinDate.getTime()).startOf('months'),
          max: moment(actionMaxDate.getTime()).endOf('months'),
        }
      }
      , () => {
        this.generateData()
        this.generateTimeline()
      })
  }
  generateData = () => {
    //! actual
    let tempData = {
      'Casting Completed': {},
      'Delivered to Site': {},
      'Installed on Site': {},
    }
    _.forEach(this.dataPPVC, v => {
      if (v.infoData.status !== "" && v.modifiedDate !== null) {
        _.forEach(v.listTime, list => {
          if (this.state.timeValue.min.valueOf() <= list.time && this.state.timeValue.max.valueOf() >= list.time) {
            let code = v.mark.split('-')[0]
            if (list.status === 'Casting Completed') {
              if (!tempData['Casting Completed'][code])
                tempData['Casting Completed'][code] = []
              tempData['Casting Completed'][code].push(v)
            }
            else if (list.status === ('Delivered to Site')) {
              if (!tempData['Delivered to Site'][code])
                tempData['Delivered to Site'][code] = []
              tempData['Delivered to Site'][code].push(v)
            }
            else if (list.status === ('Installed on Site')) {
              if (!tempData['Installed on Site'][code])
                tempData['Installed on Site'][code] = []
              tempData['Installed on Site'][code].push(v)
            }

          }
        })

      }
    })
    // console.log(_.values(tempData['Casting Completed']))
    //! target
    // let min = moment(new Date(this.state.timeValue.min)).startOf('days').valueOf()
    // let max = moment(new Date(this.state.timeValue.max)).endOf('days').valueOf()
    // let items = _.map(this.smartSheetData, (item) => {
    //   if (item.fromDate.getTime() >= min && item.toDate.getTime() <= max)
    //     return item
    // })
    // items = _.without(items, undefined)

    // let temp = {
    //   'Casting Completed': 0,
    //   'Delivered to Site': 0,
    //   'Installed on Site': 0,
    // }

    // items.forEach(it => {
    //   temp['Casting Completed'] += it.castingValue;
    //   temp['Delivered to Site'] += it.siteDelivered;
    //   temp['Installed on Site'] += it.siteCompleted;
    // });
    this.setState({
      instantData: tempData
      // , instantDataSheet: temp 
    }, () => {
      this.showModelColor()
    })

  }

  //#endregion
  //#region //? Show Model Color
  showModelColor = () => {
    this.props.viewer.clearThemingColors(this.props.viewer.model)
    if (this.state.instantData) {
      let colorCasted = convertHexColorToVector4("#1890ff")
      let colorDelivered = convertHexColorToVector4("#f39c12")
      let colorInstalled = convertHexColorToVector4("#722ed1")
      _.forEach(this.state.instantData, item => {
        _.forEach(_.flatMapDeep(item), v => {
          _.forEach(v.listTime, list => {
            if (this.state.timeValue.min.valueOf() <= list.time && this.state.timeValue.max.valueOf() >= list.time) {
              if (list.status === ('Casting Completed'))
                this.props.viewer.setThemingColor(v.dbId, colorCasted)
              if (list.status === ('Delivered to Site'))
                this.props.viewer.setThemingColor(v.dbId, colorDelivered)
              if (list.status === ('Installed on Site'))
                this.props.viewer.setThemingColor(v.dbId, colorInstalled)
            }
          })

        })
      })


    }
  }
  //#endregion
  //#region //!generate timeline
  generateTimeline = () => {
    let _this = this
    if (this.timelinepanel !== null)
      this.timelinepanel.destroy();
    var container = document.getElementById('timeline-summary-ppvc');
    this.groups = new timeline.DataSet();
    this.items = new timeline.DataSet([
      {
        id: 1,
        start: moment(new Date(0, 1, 1)).startOf('month'),
        end: moment(this.state.actionMinDate).startOf('month'),
        type: "background",
        className: "custom-background-start",
      },
      {
        id: 2,
        start: moment(this.state.actionMaxDate).endOf('month'),
        end: moment(new Date(2500, 1, 1)).startOf('month'),
        type: "background",
        className: "custom-background-end"
      }
    ]);
    var options = {
      width: '100%', height: '100%', zoomKey: 'ctrlKey',
      min: moment(this.state.actionMinDate).startOf('month').subtract(1, 'months'), // lower limit of visible range
      max: new Date(2500, 1, 1), // upper limit of visible range 
      zoomMin: 1000 * 60 * 60 * 24 * 17,
      // zoomMax: 1000 * 60 * 60 * 24 * 31 * 12 *2
    };
    this.timelinepanel = new timeline.Timeline(container, this.items, this.groups, options);
    this.timelinepanel.on('rangechanged', moveTimelineToCurrentDate);
    function moveTimelineToCurrentDate(e) {
      if (!e.byUser) {
        _this.timelinepanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
          moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
        _this.timelinepanel.off('rangechanged', moveTimelineToCurrentDate)
      }
    }
    this.timelinepanel.addCustomTime(moment(this.state.actionMinDate).startOf('month'), 'custom-timeline-start-summary-ppvc')
    this.timelinepanel.addCustomTime(moment(this.state.actionMaxDate).endOf('month'), 'custom-timeline-end-summary-ppvc')
    this.timelinepanel.on('timechange', (e) => {
      timelineChangeProgress(this.timelinepanel, this.items, e, this, 'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc', null, 'summaryPPVC')
    })
    this.timelinepanel.on('doubleClick', function (e) {
      let check = true
      if (e.customTime === 'custom-timeline-start-summary-ppvc') {
        _this.setState({ openPortalStart: true, openPortalEnd: false, openDock: false, openPieChart: false })
        // _this.props.onChangeDockDisplay('summaryPrecastDock', false)
        check = false
      } else if (e.customTime === 'custom-timeline-end-summary-ppvc') {
        _this.setState({ openPortalStart: false, openPortalEnd: true, openDock: false, openPieChart: false })
        // _this.props.onChangeDockDisplay('summaryPrecastDock', false)
        check = false
      }
      if (check)
        _this.timelinepanel.setWindow(moment(_this.state.actionMinDate).startOf('month').subtract(1, 'months'),
          moment(_this.state.actionMaxDate).endOf('month').add(1, 'months'))
    });

  }
  //#endregion
  //#region //? Draw Target Plane
  handleDrawPlane = () => {
    this.setState({ isShowPlan: !this.state.isShowPlan }, () => {
      this.drawingPlane()
    })
  }
  drawingPlane = () => {
    if (!this.state.isShowPlan) {
      if (this.props.viewer.overlays.hasScene('target-Plane')) {
        this.props.viewer.overlays.removeScene('target-Plane');
      }
    } else {
      let tempItem = _.findLast(this.state.levels, (o) => { return moment(o.date).valueOf() <= moment().valueOf() })
      console.log(tempItem)
      if (tempItem) {
        // const sb = this.props.viewer.getExtension('Autodesk.AEC.LevelsExtension')
        // console.log(sb)
        // let exPoint =  _.find(sb.floorSelector._floors,(o)=>o.guid ===tempItem.id)

        // let basePoint = exPoint.zMin
        const box3 = this.props.viewer.model.getBoundingBox()
        let basePoint = this.props.aecModelData.refPointTransformation[11] + tempItem.elevation - this.props.viewer.impl.model.myData.globalOffset.z


        let centerPointX = (box3.min.x + box3.max.x) / 2
        let centerPointY = (box3.min.y + box3.max.y) / 2
        let centerPointZ = basePoint
        let width = box3.max.x - box3.min.x;
        let height = box3.max.y - box3.min.y;
        var geometry = new THREE.BoxGeometry(width, height, 0.1);
        var material = new THREE.MeshPhongMaterial({
          color: 0xff0000,
          opacity: 0.5,
          transparent: true,
        });
        this.targetPlaneMesh = new THREE.Mesh(geometry, material);
        this.targetPlaneMesh.position.set(centerPointX, centerPointY, centerPointZ);
        if (!this.props.viewer.overlays.hasScene('target-Plane')) {
          this.props.viewer.overlays.addScene('target-Plane');
        }

        this.props.viewer.overlays.addMesh(this.targetPlaneMesh, 'target-Plane');
      }
    }
  }
  //#endregion
  //!
  //#region //? handle date panel
  handleClosePortal = () => {
    this.setState({ openPortalStart: false, openPortalEnd: false, openDock: true, openPieChart: true, openDateControl: false })
    // this.props.onChangeDockDisplay('summaryPrecastDock', true)
  }
  handleDateTimeline = (name, date, dateString) => {
    setDateByPanel(this.timelinepanel, this.items, name, date, this, 'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc', 'summaryPPVC')
    this.handleClosePortal()
  }
  disabledMinDate = (current) => {
    let temp1 = moment(this.state.actionMinDate.getTime()).startOf('months').startOf('day')
    return current < temp1
  }
  disabledMaxDate = (current) => {
    let temp1 = moment(this.state.actionMaxDate.getTime()).endOf('months').endOf("day")
    return current > temp1
  }
  //#endregion
  //#region //? setting panel
  handleCloseSettingPanel = () => {
    this.setState({ openSettingPanel: false, loadingSetting: false, levels: [], openDock: true, openPieChart: true }, () => {
      this.setState({ levels: this.state.tempLevels })
    })
    // this.props.onChangeDockDisplay('summaryPrecastDock', true)
  }
  handleApplySetting = () => {
    let temp = []
    _.forEach(this.state.levels, (v, k) => {
      temp.push({ id: v.id, content: v.content, date: v.date, sort: k, elevation: v.elevation })
    })
    this.setState({ levels: [] }, () => {
      this.setState({ levels: temp }, () => {
        axios.post(`/api/versions/save-level-date`, { itemId: this.props.itemId, level: temp })
          .then(res => {
            this.setState({ openSettingPanel: false, loadingSetting: false, openDock: true })
            // this.props.onChangeDockDisplay('summaryPrecastDock', true)
            if (this.props.viewer.overlays.hasScene('target-Plane')) {
              this.props.viewer.overlays.removeScene('target-Plane');
            }
            this.drawingPlane()
          })
          .catch(err => message.error(`Save level failed.`))
      })
    })
  }
  handleOpenSettingPanel = () => {
    // this.props.onChangeDockDisplay('summaryPrecastDock', false)
    this.setState({ openSettingPanel: true, loadingSetting: true, openDock: false, openPieChart: false }, () => {
      this.getLevelDataFromDB()
    })
  }
  handleDateSetting = (item, e) => {
    let temp = this.state.levels.slice(0)
    let index = _.findIndex(this.state.levels, function (o) { return o.id === item.id })
    temp.splice(index, 1)
    temp.splice(index, 0, { id: item.id, content: item.content, date: e, sort: item.sort, elevation: item.elevation })
    this.setState({ levels: [] }, () => {
      this.setState({ levels: temp })
    })
  }
  //#endregion
  handlePieChart = () => {
    this.setState({ openPieChart: !this.state.openPieChart })
  }
  handleCloseDockPieChart = () => {
    this.setState({ openPieChart: false })
  }
  handleDateControl = () => {
    this.setState({ openDock: false, openPieChart: false, openDateControl: true })
  }
  handleDate = (name) => {
    console.log(moment(this.state.actionMaxDate).format('DD-MM-YYYY'))
    console.log(moment())
    if (name === 'Yesterday progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinepanel, this.items,
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"), this,
          'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc')
      } else
        message.warning('Out range')
    } else if (name === '7 days progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinepanel, this.items,
          moment().subtract(7, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"), this,
          'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc')
      } else
        message.warning('Out range')
    } else if (name === 'This month progress') {
      if (moment() <= moment(this.state.actionMaxDate).endOf('months').endOf('day') &&
        moment() >= moment(this.state.actionMinDate).startOf('months').startOf('day')) {
        setDateStartEndByPanel(this.timelinepanel, this.items,
          moment().startOf('months').startOf("day"),
          moment().endOf('months').endOf("day"), this,
          'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc')
      } else
        message.warning('Out range')
    }
    this.handleClosePortal()
  }
  handleRangeDate = (value) => {
    setDateStartEndByPanel(this.timelinepanel, this.items,
      moment(value[0]).startOf("day"),
      moment(value[1]).endOf("day"), this,
      'custom-timeline-start-summary-ppvc', 'custom-timeline-end-summary-ppvc')
    this.handleClosePortal()
  }
  handleDetail = () => {
    this.setState({ detail: !this.state.detail })
  }
  handleTable = (data) => {
    let temp = []
    _.forEach(data, v => {
      temp.push(v.infoData)
    })
    this.setState({ isShowList: true, tableDetail: temp })
  }
  handleCloseDockTable = () => {
    this.setState({ isShowList: false })
  }
  render() {
    return (
      <>
        {/* {(this.state.openPieChart && this.dataPPVC.length !== 0) &&
          <PieChartPPVC
            dataPPVC={this.dataPPVC}
            openPieChart={this.state.openPieChart}
            viewer={this.props.viewer}
            handleCloseDockPieChart={this.handleCloseDockPieChart}
            timeValue={this.state.timeValue}
          />} */}
        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 420, y: 0 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.state.openDock ? 'inline' : 'none', height: 230, width: '70%' }}
            id='custom-precast-summary-dock' >
            <div className='custom-dock-panel-title'>Summary</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
            <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
              <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                {this.state.loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} /> :
                  !(this.state.instantData !== null) ?
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                      <span style={{ color: 'white' }}>
                        No data
                    </span>} />
                    :
                    <div style={{ height: 'calc(100% - 100px)' }}  >
                      <Form style={{
                        color: "white", padding: '15px 10px 10px 10px', backgroundColor: "rgba(97, 97, 97, 0.075)",
                        position: 'fixed', width: '100%', margin: 0, height: '100%'
                      }}>
                        <Row gutter={[8, 8]} type="flex" justify="space-between">
                          <Col span={2} >#   </Col>
                          <Col span={8} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="blue" style={{ fontSize: 'medium' }}>
                              Casting Completed
                        </Tag>
                          </Col>
                          <Col span={7} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="orange" style={{ fontSize: 'medium' }}>
                              Delivered to Site
                      </Tag>
                          </Col>
                          <Col span={7} style={{ textAlign: '-webkit-center', overflowX: 'hidden' }}>
                            <Tag color="purple" style={{ fontSize: 'medium' }}>
                              Installed on Site
                      </Tag>
                          </Col>
                        </Row>
                        {/* <Row gutter={[8, 8]}>
                          <Col span={2} >Target</Col>
                          {Object.keys(this.state.instantDataSheet).map((key, i) =>
                            <Col span={(key === 'Casting Completed' ) ? 8 : 7} style={{ textAlign: '-webkit-center' }}>
                              {this.state.instantDataSheet[key]}
                            </Col>
                          )}
                      
                        </Row> */}
                        <Row gutter={[8, 8]}>
                          <Col span={2} >Actual</Col>
                          {Object.keys(this.state.instantData).map((key, i) =>
                            <Col span={(key === 'Casting Completed') ? 8 : 7} style={{ textAlign: '-webkit-center' }}
                              onClick={this.handleTable.bind(this, _.flatMapDeep(this.state.instantData[key]))}>
                              {this.state.detail ?
                                _.values(this.state.instantData[key]).length === 0 ?
                                  0 :
                                  _.values(this.state.instantData[key]).map((v, k) =>
                                    <Text title={v[0].mark.split('-')[0]} style={{ color: 'white' }}>{k !== 0 && '-'}{v.length}</Text>
                                  )
                                :
                                _.flatMapDeep(this.state.instantData[key]).length}
                            </Col>
                          )}
                          {/* <Col span={5} style={{ textAlign: '-webkit-center' }}>{this.state.instantData.castingCompletedData.length}  </Col>
                          <Col span={5} style={{ textAlign: '-webkit-center' }}> {this.state.instantData.deliveredToFitouYardData.length}  </Col>
                          <Col span={4} style={{ textAlign: '-webkit-center' }}>{this.state.instantData.completedFitoutData.length}</Col>
                          <Col span={4} style={{ textAlign: '-webkit-center' }}>{this.state.instantData.devliveredToSiteData.length}</Col>
                          <Col span={4} style={{ textAlign: '-webkit-center' }}>{this.state.instantData.installOnSiteData.length}</Col> */}
                        </Row>
                      </Form>
                    </div>}
                <div id='timeline-summary-ppvc' style={{
                  backgroundColor: 'white',
                  color: "#000", position: 'absolute !important', width: '100%', bottom: 0, margin: 0, height: 100
                }}>
                  <ButtonGroup style={{ right: '10px', position: 'absolute', zIndex: '1' }}>
                    <Tooltip title={'Detail'} placement="top">
                      <IconButton icon={<Icon icon={'detail'} />} size="xs" color={this.state.detail && 'blue'}
                        onClick={this.handleDetail} />
                    </Tooltip>
                    <Tooltip title={'Set Date'} placement="top">
                      <IconButton icon={<Icon icon={'table'} />} size="xs"
                        onClick={this.handleDateControl} />
                    </Tooltip>
                    {/* <Tooltip title={'Pie Chart'} placement="top">
                      <IconButton icon={<Icon icon={'pie-chart'} style={{ backgroundColor: this.state.openPieChart && '#7dff7d' }} />} size="xs"
                        onClick={this.handlePieChart} />
                    </Tooltip> */}
                    {this.props.aecModelData &&
                      <>
                        <Tooltip title={this.state.isShowPlan ? 'Hide Target Plane' : 'Show Target Plane'} placement="top">
                          <IconButton size="xs" icon={<Icon icon={this.state.isShowPlan ? 'eye-slash' : 'eye'} />}
                            onClick={this.handleDrawPlane} color={this.state.isShowPlan ? 'red' : 'blue'}></IconButton>
                        </Tooltip>
                        {(this.props.userRole === 'admin' || this.props.permission.roleEngineer) &&
                          <Tooltip title={'Setting target date'} placement="top">
                            <IconButton size="xs" icon={<Icon icon="cog" />}
                              onClick={this.handleOpenSettingPanel} color='black'></IconButton>
                          </Tooltip>}
                      </>
                    }
                  </ButtonGroup>
                </div>
              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable>
        {this.state.isShowList && <PPVCTable tableDetail={this.state.tableDetail}
          openDock={this.state.isShowList} handleCloseDock={this.handleCloseDockTable}
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Length', field: 'length' },
            { title: 'Height', field: 'height' },
            { title: 'Width', field: 'width' },
            { title: 'Volume', field: 'volume' },
            { title: 'Tekla Volume', field: 'teklaVolume' },
            { title: 'Rebar Weight', field: 'rebarWeightKg' },
            // { title: 'Status', field: 'status' },
            // { title: 'Date', field: 'date' },
            { title: 'Site Name', field: 'siteName' },
            { title: 'Casting Date', field: 'castingDate' },
            { title: 'Delivery Date', field: 'deliveryDate' },
            { title: 'Install Date', field: 'installDate' },
          ]} />}
        <Modal backdrop="static" show={this.state.openSettingPanel} onHide={this.handleCloseSettingPanel.bind(this)} style={{}}>
          <Modal.Header>
            <Modal.Title>Setting target date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {this.state.loadingSetting ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical /> :
              <div className="List">
               <div className="main-drag">
                  <DraggableArea
                    isList
                    tags={this.state.levels}
                    render={({ tag }) => (
                      <div className="row">
                        <Text strong style={{ color: moment(tag.date).valueOf() <= moment().valueOf() && 'red' }}>{tag.content}</Text>
                        <DatePicker style={{ position: 'absolute', right: 10, }} placeholder="Pick Date"
                          size='xs' onChange={this.handleDateSetting.bind(this, tag)}
                          format={'DD-MM-YYYY'} allowClear={false} value={tag.date !== null ? moment(tag.date) : null} />
                      </div>
                    )}
                    onChange={(levels) => this.setState({ levels })}
                  />
                </div>
              </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleApplySetting.bind(this)} color='blue' loading={this.state.loadingSetting}>
              Apply
            </Button>
            <Button onClick={this.handleCloseSettingPanel.bind(this)} color='red'>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" show={this.state.openPortalStart} onHide={this.handleClosePortal} size='xs'>
          <Modal.Header>
            <Modal.Title>Set start date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <DatePicker placeholder="Start Date" size='xs' onChange={this.handleDateTimeline.bind(this, 'min')} format={'DD-MM-YYYY'}
              value={moment(new Date(this.state.timeValue.min), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
              disabledDate={this.disabledMinDate} showToday={false} />
          </Modal.Body>
        </Modal>
        <Modal backdrop="static" show={this.state.openPortalEnd} onHide={this.handleClosePortal} size='xs'>
          <Modal.Header>
            <Modal.Title>Set end date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <DatePicker placeholder="End Date" size='xs' onChange={this.handleDateTimeline.bind(this, 'max')} format={'DD-MM-YYYY'}
              value={moment(new Date(this.state.timeValue.max), 'DD-MM-YYYY')} style={{ width: '100%' }} cleanable={false}
              disabledDate={this.disabledMaxDate} showToday={false} />
          </Modal.Body>
        </Modal>
        <Modal backdrop="static" show={this.state.openDateControl} onHide={this.handleClosePortal} size='xs'>
          <Modal.Header>
            <Modal.Title>Set date</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Button color='blue' fluid onClick={this.handleDate.bind(this, 'Yesterday progress')}>
              Yesterday progress
            </Button> <br />
            <Button color='blue' fluid onClick={this.handleDate.bind(this, '7 days progress')}>
              7 days progress
            </Button> <br />
            <Button color='blue' fluid onClick={this.handleDate.bind(this, 'This month progress')}>
              This month progress
            </Button> <br />
            <DateRangePicker placeholder="Range Date" size='small' onChange={this.handleRangeDate} format={'DD-MM-YYYY'}
              value={[moment(new Date(this.state.timeValue.min), 'DD-MM-YYYY').startOf('month'),
              moment(new Date(this.state.timeValue.max), 'DD-MM-YYYY').endOf('month')]}
              // disabledDate={ allowedRange(moment(new Date(this.state.actionMinDate), 'DD-MM-YYYY').startOf('month').startOf('day').format('DD-MM-YYYY'),
              // moment(new Date(this.state.actionMaxDate), 'DD-MM-YYYY').endOf('month').endOf('day').format('DD-MM-YYYY'))}
              style={{ width: '100%' }} cleanable={false}
              showToday={false} />
          </Modal.Body>
        </Modal>
      </>
    )
  }


}
export default SummaryPPVC;