
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { Icon, Sidenav, Nav, Dropdown, IconStack } from 'rsuite';
import { message, Tooltip, Typography } from 'antd'
import Split from 'react-split'
import axios from "axios";
import {
  unloadForgeExtension, setupForgeExtensionAfterLoaded, showErrorLoadDocument, setupForgeExtensionBeforeLoadedIssuePage,
  getForgeToken, replaceSpinner, getAllModelsElementdbIdsWithCondition
} from '../../../components/module_BimApp/function/ForgeFunction'
import _ from 'lodash'
import {DialogDetailView} from '../../module_BimApp/dialog'

import {SideBarSelectParameter} from '../../module_BimApp/sidebar'

import {Markup2D} from '../../module_BimApp/dock_forge'
import { convertHexColorToVector4 } from '../../../components/module_BimApp/function/TableFunction'
import base64url from 'base64url'
import { Intent, Position, Toaster, } from "@blueprintjs/core";
const Autodesk = window.Autodesk;
const { Text, Paragraph } = Typography

let documentId = ''
let projectId = ''
let userCreatedProject = ''
let viewpointId = ''
let identify = ''
let check = false
let mainLoadOptions = {}
let count = 0
let elementColor = null
let viewPort = null
let version
function LoadViewPage(props) {
  const [viewer, setViewer] = useState(null)
  const [globalOffset, setGlobalOffset] = useState(null)
  const [group, setGroup] = useState([])
  const [loading, setLoading] = useState(false)
  const [displayDetailViewpoint, setDisplayDetailViewpoint] = useState(false)
  const [markUp2d, setMarkUp2d] = useState(false)

  const [viewpoint, setViewpoint] = useState('')
  const [itemDetail, setItemDetail] = useState(null)

  const [displayPanel, setDisplayPanel] = useState({
    selectParameterPanel: false,
    viewPointPanel: false
  })
  const [disabledBtn, setDisabledBtn] = useState({
    btnSelectParameter: false
  })
  const [modelLoaded, setModelLoaded] = useState(false)
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [versionModel, setVersionModel] = useState('')

  const toaster = useRef()
  useEffect(e => {
    $('#content2D').hide();
    $('#content3D').width('100%');
    $('.gutter-horizontal').hide();
    let params = base64url.decode(window.location.pathname.split('/')[2])
    let temp = params.split('/')
    projectId = temp[0]
    userCreatedProject = temp[1]
    viewpointId = temp[2]
    identify = temp[3]
    check = temp[4] === 'true' ? true : false
    setLoading(true)
    axios.post("/api/viewpoint/get-view-from-project", { projectId: projectId, viewpointId: viewpointId, email: props.email })
      .then(res => {
        let index = _.findIndex(res.data.viewpoints, e => { return e.identify === identify })
        if (index >= 0) {
          setViewpoint(res.data)
          setItemDetail(res.data.viewpoints[index])
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }, [])
  useEffect(e => {
    if (itemDetail) {
      axios.post(`/api/issue/get-model`, { item: itemDetail.mainModel, isLastVersion: check })
        .then(res => {
          if (res.data.status === 'success') {
            mainLoadOptions = {
              applyRefPoint: true,
              modelNameOverride: res.data.name,
              isAEC: true,
              guid: res.data.guid,
              viewableID: res.data.viewableID,
              itemId: res.data.itemId,
              version: res.data.version,
              viewName: res.data.viewName
            };
            version = res.data.version
            setItemId(res.data.itemId)
            setVersionModel(res.data.version)
            setItemName(res.data.name)
            if (itemDetail.mainModel.version !== res.data.version) {
              let path = base64url(`${projectId}/${userCreatedProject}/${viewpointId}/${identify}/${false}`)
              toaster.current.show({
                timeout: 0,
                action: {
                  href: `${window.location.origin}/view/${path}`,
                  text: <strong>Reload</strong>,
                },
                icon: 'warning-sign',
                intent: Intent.WARNING,
                message: (
                  <Text strong style={{ color: 'white' }}>
                    {`View created at version ${itemDetail.mainModel.version}. Are you want to load original version?`}
                  </Text>
                )
              })
            }
            if (check) {
              launchViewer(res.data.objectId)
            } else {
              launchViewer(itemDetail.mainModel.urn)
            }
          }
          else {
            message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
          }
        })
        .catch(err => {
          message.error(`Can't load model.`);
        })
    }
  }, [itemDetail])

  useEffect(e => {
    if (viewer)
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess.bind(this, viewer), onDocumentLoadFailure);
  }, [viewer])

  useEffect(e => {
    if (!globalOffset || !viewer) return
    elementColor = itemDetail.attachElementColor
    viewPort = itemDetail.viewPort
    count = itemDetail.otherModel.length
    if (itemDetail.otherModel.length > 0) {
      _.forEach(itemDetail.otherModel, (v, k) => {
        axios.post(`/api/issue/get-model`, { item: itemDetail.mainModel, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), _onDocumentLoadFailure);
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(err => {
            message.error(`Can't load model.`);
            count--
          })
      })
    } else {
      handleSetColorElement(itemDetail.attachElementColor)
    }
  }, [globalOffset])

  const launchViewer = (urn) => {
    getForgeToken().then(token => {
      let options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      let config3d = {
        extensions: [
          "Autodesk.AEC.LevelsExtension",
          "Autodesk.AEC.Minimap3DExtension",
        ]
      };
      Autodesk.Viewing.Initializer(options, () => {
        let viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'), config3d)
        viewer.start();
        setViewer(viewer)
        documentId = 'urn:' + urn;
      });
    })
  }
  const onDocumentLoadSuccess = (viewer, doc) => {
    setModelLoaded(true)
    doc.downloadAecModelData()
    let viewables = doc.getRoot().search({ 'type': 'geometry', 'guid': mainLoadOptions.guid }, true)
    if (viewables.length === 0)
      viewables = doc.getRoot().search({ 'type': 'geometry', 'viewableID': mainLoadOptions.viewableID }, true)
    if (viewables.length === 0 && mainLoadOptions.viewName)
      viewables = doc.getRoot().search({ 'type': 'geometry', 'name': mainLoadOptions.viewName }, true)
    if (viewables.length === 0) {
      viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '3d' }, true);
      message.warning(`View isn't existing in file, load default view`)
    }
    if (viewables.length !== 0) {
      viewer.loadDocumentNode(doc, viewables[0], mainLoadOptions)
        .then(i => {
          unloadForgeExtension(viewer, false)
          viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, geometryLoaded)
        })
        .catch(err => {
          showErrorLoadDocument('forgeViewer', 'Load model failed', 0)
        })
    } else {
      showErrorLoadDocument('forgeViewer', `File isn't contain any view`, 0)
    }
  }
  const geometryLoaded = () => {
    viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, geometryLoaded)
    setupForgeExtensionAfterLoaded(viewer, null, false)
    setGlobalOffset(viewer.impl.model.myData.globalOffset)
  }

  const onDocumentLoadFailure = (viewerErrorCode) => {
    showErrorLoadDocument('forgeViewer', 'Could not load document', viewerErrorCode)
  }
  const _onDocumentLoadFailure = (viewerErrorCode) => {
    console.log(viewerErrorCode)
  }
  const _onDocumentLoadSuccess = (loadOptions, doc) => {
    let view3d = doc.getRoot().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let index = _.findIndex(view3d, e => { return e.data.guid === loadOptions.guid })
    if (index >= 0) {
      let svfUrl = doc.getViewablePath(view3d[index]);
      viewer.loadModel(svfUrl, loadOptions, _onLoadModelSuccess, _onLoadModelError)
    }
  }
  const _onLoadModelSuccess = () => {
    if (count === 0) {
      handleSetColorElement(elementColor)
    }
  }
  const _onLoadModelError = () => {

  }
  const handleSetColorElement = (elements) => {
    let models = viewer.impl.modelQueue().getModels()
    _.forEach(models, model => {
      viewer.clearThemingColors(model)
    })
    let isAllLoaded = viewer.isLoadDone({ onlyModels: models })
    if (isAllLoaded) {
      setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
      let countModel = models.length
      let tempElement = {}
      _.forEach(models, async model => {
        let instanceTree = model.getData().instanceTree;
        let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
        countModel--
        let count1 = temp.length
        _.forEach(temp, id => {
          model.getProperties(id, (modelAProperty) => {
            tempElement[modelAProperty.externalId] = id
            count1--
            if (count1 === 0) {
              _.forEach(elements, async el => {
                let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                if (index >= 0) {
                  if (tempElement[el.guid]) {
                    let color = convertHexColorToVector4(el.color)
                    viewer.setThemingColor(tempElement[el.guid], color, models[index])
                  }
                }
              })
              viewer.restoreState(viewPort)
              setModelLoaded(false)
            }
          })
        })
      })
    } else {
      viewer.waitForLoadDone({ onlyModels: models })
        .then(res => {
          setupForgeExtensionBeforeLoadedIssuePage(viewer, setMarkUp2d, markUp2d)
          let countModel = models.length
          let tempElement = {}
          _.forEach(models, async model => {
            let instanceTree = model.getData().instanceTree;
            let temp = await getAllModelsElementdbIdsWithCondition(instanceTree, viewer, model, 'all')
            countModel--
            let count1 = temp.length
            _.forEach(temp, id => {
              model.getProperties(id, (modelAProperty) => {
                tempElement[modelAProperty.externalId] = id
                count1--
                if (count1 === 0) {
                  _.forEach(elements, async el => {
                    let index = _.findIndex(models, e => { return e.myData.loadOptions.itemId === el.itemId })
                    if (index >= 0) {
                      if (tempElement[el.guid]) {
                        let color = convertHexColorToVector4(el.color)
                        viewer.setThemingColor(tempElement[el.guid], color, models[index])
                      }
                    }
                  })
                  viewer.restoreState(viewPort)
                  setModelLoaded(false)
                }
              })
            })
          })
        })
    }
  }

  const dragSplit = () => {

  }

  const restoreSaveView = (check, e) => {
    try {
      let section = viewer.getExtension('Autodesk.Section')
      section.activate()
      section.deactivate();
    } catch { }
    let data = e.props.item
    if (data.mainModel.version !== version) {
      toaster.current.show({
        timeout: 2000,
        icon: 'warning-sign',
        intent: Intent.WARNING,
        message: (
          <Text strong style={{ color: 'white' }}>
            {`View created at version ${data.mainModel.version}`}
          </Text>
        )
      })
    }
    let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let node = viewer.impl.model.getDocumentNode()
    let loadOptions = {
      globalOffset: globalOffset,
      applyRefPoint: true,
      modelNameOverride: data.mainModel.name,
      isAEC: true,
      guid: data.mainModel.guid,
      viewableID: data.mainModel.viewableID,
      itemId: data.mainModel.itemId,
      version: data.mainModel.version,
      acmSessionId: document.acmSessionId
    }
    if (data.guid === node.data.guid) {
      // this.props.viewer.restoreState(v.viewPort)  
      handleOtherModel(data, check)
      return false
    } else {
      let viewables = document.getRoot().search({ 'type': 'geometry', 'guid': data.guid }, true)
      if (viewables.length === 0)
        viewables = document.getRoot().search({ 'type': 'geometry', 'viewableID': data.viewableID }, true)
      if (viewables.length === 0 && data.viewName)
        viewables = document.getRoot().search({ 'type': 'geometry', 'name': data.viewName }, true)
      if (viewables.length !== 0) {
        viewer.loadDocumentNode(document, viewables[0], loadOptions)
          .then(i => {
            unloadForgeExtension(viewer, false)
            handleOtherModel(data, check)
          })
          .catch(r => {
            message.warning(`Load model failed`)
          })
      } else {
        handleOtherModel(data, check)
        message.warning(`View isn't existing in file, use current view`)
      }
      return false
    }
  }

  const handleOtherModel = (data, check) => {
    viewPort = data.viewPort
    elementColor = data.attachElementColor
    count = data.otherModel.length
    if (data.otherModel.length > 0) {
      const models = viewer.impl.modelQueue().getModels()
      let tempModeled = []
      _.forEach(models, v => {
        if (v.myData.urn !== viewer.impl.model.myData.urn) {
          let index = _.findIndex(data.otherModel, e => { return e.itemId === v.myData.loadOptions.itemId && e.version === v.myData.loadOptions.version && e.guid === v.myData.loadOptions.guid })
          if (index < 0)
            viewer.unloadModel(v)
          else
            tempModeled.push(index)
        }
      })
      _.forEach(data.otherModel, (v, k) => {
        if (tempModeled.includes(k)) {
          count--
          if (count === 0) {
            handleSetColorElement(data.attachElementColor)
          }
          return
        }
        axios.post(`/api/issue/get-model`, { item: itemDetail.mainModel, isLastVersion: check })
          .then(res => {
            if (res.data.status === 'success') {
              let document = viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
              let loadOptions = {
                globalOffset: globalOffset,
                applyRefPoint: true,
                modelNameOverride: res.data.name,
                isAEC: true,
                guid: res.data.guid,
                viewableID: res.data.viewableID,
                itemId: res.data.itemId,
                version: res.data.version,
                acmSessionId: document.acmSessionId
              };
              var documentId = 'urn:' + res.data.objectId
              Autodesk.Viewing.Document.load(documentId, _onDocumentLoadSuccess.bind(this, loadOptions), _onDocumentLoadFailure);
              message.success(`Load model ${res.data.name} version ${res.data.version}.`);
              count--
            } else {
              message.success(`Model ${res.data.name} version ${res.data.version} isn't translate.`);
              count--
            }
          })
          .catch(err => {
            count--
            message.error(`Can't load model.`);
          })
      })
    } else {
      handleSetColorElement(data.attachElementColor)
    }
  }
  const handleChangeMarkup2DPanel = () => {
    setMarkUp2d(!markUp2d)
  }

  const handleCloseDetailViewPoint = (data) => {
    if (data) {
      let tempData = group.slice(0)
      let index = _.findIndex(tempData, e => { return e.viewpointId === data.viewpointId })
      if (index >= 0) {
        tempData[index] = data
        let temp = tempData[index]
        // controlViewpoint(tempData, temp)
      }
    }
    setDisplayDetailViewpoint(false)
  }
  const handleDeleteViewPoint = (e) => {
    if (!window.confirm('Are you sure to delete this viewpoint?')) return
    axios.post(`/api/viewpoint/delete-viewpoint`, { viewpointId: e.props.viewpoint.viewpointId, identify: e.props.item.identify })
      .then(res => {
        let tempData = group.slice(0)
        let index = _.findIndex(tempData, v => { return v.viewpointId === res.data.viewpointId })
        if (index >= 0) {
          tempData[index] = res.data
          let temp = tempData[index]
          // controlViewpoint(tempData, temp)
        }
      })
      .catch(err => {
        message.error(`Can't delete viewpoint.`);
      })
  }
  const handleDetailVIewPanel = () => {
    setDisplayDetailViewpoint(true)
  }
  // const controlViewpoint = (tempData, temp) => {
  //   setLoadingCard(true)
  //   let count = temp.viewpoints.length
  //   if (count === 0) {
  //     setLoadingCard(false)
  //     return
  //   }
  //   let list = []
  //   _.forEach(temp.viewpoints, item => {
  //     if (temp.userCreated !== props.email) {
  //       if (!item.listShare.includes(props.email)) {
  //         count--
  //         return
  //       }
  //     }
  //     getPublicUrl(item.img)
  //       .then(res => {
  //         item.img = res
  //         list.push(item)
  //         count--
  //         if (count === 0) {
  //           temp.viewpoints = list
  //           setGroup(tempData)
  //           setLoadingCard(false)
  //         }
  //       })
  //       .catch(err => {
  //         item.img = err
  //         list.push(item)
  //         count--
  //         if (count === 0) {
  //           temp.viewpoints = list
  //           setGroup(tempData)
  //           setLoadingCard(false)
  //         }
  //       })
  //   })
  // }

  const callbackDisplayPanel = (name, value) => {
    let temp = Object.assign({}, displayPanel);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    setDisplayPanel(temp)
  }
  const callbackDisableBtn = (name, value) => {
    let temp = Object.assign({}, disabledBtn);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = value
        return false
      }
    })
    setDisabledBtn(temp)
  }
  const handleTogglePanel = (name, functionName) => {
    let temp = Object.assign({}, displayPanel);
    _.forEach(temp, (v, k) => {
      if (k === name) {
        temp[k] = !temp[k]
      } else {
        temp[k] = false
      }
    })
    setDisplayPanel(temp)

  }
  return (
    <div>
      <Sidenav style={{ position: 'absolute', zIndex: '100', height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`, backgroundColor: 'white', width: '40px' }}
        expanded={false}
      >
        <Sidenav.Body>
          <Nav >
            <Tooltip title="View Point" placement="right" overlayStyle={{ zIndex: 10 }}>
              <Nav.Item eventKey="6" style={{ height: '40px', width: '40px' }} disabled={modelLoaded}
                icon={
                  <IconStack style={{ left: '11px', top: '10px', height: '40px', display: 'contents', color: !modelLoaded && '#636363' }}  >
                    <Icon icon="camera" stack="1x" style={{ top: 6 }} />
                    <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                  </IconStack>}
                onClick={handleDetailVIewPanel} />
            </Tooltip>
          </Nav>
          <Tooltip title="Quantities" placement="right" overlayStyle={{ zIndex: 10 }}>
            <Dropdown placement="rightStart" eventKey="3" style={{ height: '40px', width: '40px' }} disabled={modelLoaded}
              icon={
                <IconStack style={{ display: 'contents' }}  >
                  <Icon icon="usd" stack="1x" style={{ top: 6, left: -5 }} />
                  <Icon icon="usd" stack="1x" style={{ top: 6, left: 4 }} />
                  <Icon icon="square-o" stack="2x" style={{ top: 7 }} />
                </IconStack>
              }>
              <Dropdown.Item eventKey="3-1" icon={<Icon icon="slack" />}
                onClick={handleTogglePanel.bind(this, 'selectParameterPanel', 'Extract Quantities')}
                disabled={disabledBtn.btnSelectParameter} >Extract Quantities</Dropdown.Item>
            </Dropdown>
          </Tooltip>
        </Sidenav.Body>
      </Sidenav>
      <Split id='splitForgeViewer'
        sizes={[50, 50]} minSize={100} gutterSize={2} gutterAlign="center" direction="horizontal" cursor="col-resize"
        style={{
          position: "absolute", height: `calc(100% - ${props.isAuthenticated ? '53.78px' : '0px'})`,
          width: "calc(100% - 40px)", display: 'flex', justifyItems: 'center', alignItems: 'center', left: '40px'
        }} onDrag={dragSplit}
      >
        <div id='content3D' style={{ height: '100%', width: "100%" }}>
          <input id="color-picker" type='color' style={{ opacity: 0, position: 'absolute' }} />
          <canvas id="snapshot" style={{ position: "absolute" }}></canvas>
          <div id="forgeViewer" style={{ position: "relative", height: '100%', width: "100%" }}>  </div>
        </div>
        <div id='content2D' style={{ height: '100%', width: "100%" }}>
          <div id="forgeViewer2D" style={{ position: "relative", height: '100%', width: "100%" }}></div>
        </div>
      </Split>

      <SideBarSelectParameter key={'4'} displayPanel={displayPanel.selectParameterPanel}
        viewer={viewer} onDisplayPanelChange={callbackDisplayPanel}
        heightNavigation={'53.78px'}
        email={props.email} itemId={itemId} onDisabledBtnChange={callbackDisableBtn}
        itemName={itemName} version={versionModel} />


      {displayDetailViewpoint &&
        <DialogDetailView
          projectId={projectId}
          itemId={itemDetail.mainModel.itemId}
          open={displayDetailViewpoint}
          handleClose={handleCloseDetailViewPoint}
          email={props.email}
          userCreatedProject={userCreatedProject}
          viewer={viewer}
          itemDetail={itemDetail}
          viewpoint={viewpoint}
          restoreSaveView={restoreSaveView}
          handleDeleteViewPoint={handleDeleteViewPoint}
          newTab={true}
        />}
      {markUp2d && <Markup2D openDock={markUp2d} viewer={viewer}
        onChangeDockDisplay={handleChangeMarkup2DPanel} />}

      <Toaster ref={toaster} position={Position.TOP_RIGHT} canEscapeKeyClear={false} />


    </div>
  );
}

LoadViewPage.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  permission: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    userRole: state.user.role,
    permission: state.user.permission,
    email: state.user.email,
    isAuthenticated: !!state.user.email
  }
}
export default connect(mapStateToProps)(LoadViewPage)