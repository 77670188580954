import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon } from 'antd'
import _ from 'lodash';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { setPDFData } from '../../../actions/app/pdf'
import InputColor from './input/InputColor'
import InputSize from './input/InputSize'
const ButtonGroup = Button.Group;

const RecIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" heigh="14" viewBox="0 0 24 24"  >
        <path d="M19.3,3.8H4.7c-1,0-1.8,0.8-1.8,1.8v12.8c0,1,0.8,1.8,1.8,1.8h14.7c1,0,1.8-0.8,1.8-1.8V5.6 C21.2,4.6,20.3,3.7,19.3,3.8z M5.1,18V6h13.8v12H5.1z"></path>
        {/* <rect fill="none" x="5.1" y="6" width="13.8" height="12"></rect> */}
    </svg>
);
const CirIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" heigh="14" viewBox="0 0 24 24" >
        <path class="st0" d="M12,2.8c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2C21.2,6.9,17.1,2.8,12,2.8z M12,18.8 c-3.8,0-6.9-3.1-6.9-6.9c0-3.8,3.1-6.9,6.9-6.9c3.8,0,6.9,3.1,6.9,6.9c0,0,0,0,0,0C18.9,15.8,15.8,18.9,12,18.8L12,18.8z"></path><path fill="none" d="M12,18.8c-3.8,0-6.9-3.1-6.9-6.9c0-3.8,3.1-6.9,6.9-6.9c3.8,0,6.9,3.1,6.9,6.9c0,0,0,0,0,0 C18.9,15.8,15.8,18.9,12,18.8L12,18.8z"></path>
    </svg>
);
const LineIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" heigh="14" viewBox="0 0 24 24"><rect class="cls-1" x="3" y="10.75" width="18" height="2.5" rx="0.6" transform="translate(-4.97 12) rotate(-45)"></rect></svg>
)
const LineArrowIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" heigh="14" viewBox="0 0 24 24"><path class="cls-1" d="M14.66,7.57,5.09,17.14a.61.61,0,0,0,0,.85l.92.92a.61.61,0,0,0,.85,0l9.57-9.57L18.06,11a.6.6,0,0,0,1-.42v-5a.6.6,0,0,0-.6-.6h-5a.6.6,0,0,0-.42,1Z"></path></svg>
)
const PolyLineIcon =()=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" heigh="14" viewBox="0 0 24 24"><path class="cls-1" d="M22.89,7.06l-.64-.64a.8.8,0,0,0-1.13,0l-6.77,6.77L10.23,9.07A1.66,1.66,0,0,0,9,8.58a1.69,1.69,0,0,0-1.2.49L1.11,15.8a.83.83,0,0,0-.23.57.79.79,0,0,0,.23.57l.64.64a.81.81,0,0,0,1.13,0L9,11.42l4.12,4.12a1.69,1.69,0,0,0,2.39,0L22.89,8.2a.83.83,0,0,0,.23-.57A.79.79,0,0,0,22.89,7.06Z"></path></svg>
)
function ShapeBar(props) {


    return (
        <>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'rectangular' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'rectangular' ? 'rectangular' : '' })
            }} >
                <Icon component={
                    RecIcon
                } />
            </Button>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'circle' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'circle' ? 'circle' : '' })
            }} >
                <Icon component={
                    CirIcon
                } />
            </Button>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'line' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'line' ? 'line' : '' })
            }} >
                <Icon component={LineIcon} />
            </Button>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'polyline' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'polyline' ? 'polyline' : '' })
            }} >
                <Icon component={PolyLineIcon} />
            </Button>
            <Button size='small' className={`idd-app-pdf-feature feature ${props.pdfViewer.feature === 'arrow' ? 'active' : ''}`} onClick={() => {
                props.setPDFData({ feature: props.pdfViewer.feature !== 'arrow' ? 'arrow' : '' })
            }} >
                <Icon component={LineArrowIcon} />
            </Button>
            <div style={{ backgroundColor: 'lightgray', width: 1, marginLeft: 5, marginRight: 5 }} />
            <InputColor />
            <InputSize />
        </>
    )
}

ShapeBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    userInfo: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    pdfViewer: PropTypes.object.isRequired,
    setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isAdmin: state.user.email === 'admin@wohhup.com',
        isConfirmed: !!state.user.confirmed,
        userInfo: state.user,
        userRole: state.user.role,
        pdfViewer: state.pdfViewer
    };
}

export default connect(mapStateToProps, { setPDFData })(ShapeBar)