import React from 'react';
import $ from 'jquery';
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Empty, message, Tag, Input, List, Typography } from 'antd';
import { Dropdown, Header, Progress } from 'semantic-ui-react'
import { Drawer, Loader, Icon, Button, IconButton, ButtonGroup, CheckPicker, Checkbox } from 'rsuite';
import _ from 'lodash'
import { socket,trackingUserUsing } from '../function/AdminFunction'
// import '../scss/SideBar.scss'
import { getAllElementdbIdsOneModel } from '../function/ForgeFunction'
import { convertVector4ColorToHex } from '../function/TableFunction'
import { handleModulePPVCAndPrecast } from '../function/ProgressFunction'
import moment from 'moment'
import {
  extractDataFromSmartSheet
} from '../function/DialogFunction';

const { Text } = Typography;
const { TextArea } = Input;
var exporting = true
let onceTime = true
let workingItem = null
let tempLinkDownload = ''
class SidebarDesignAutomation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingRefresh: false,
      loadingBtnStatus: false,
      listtActivities: [],
      listElementOverride: [],
      addInSelected: null,
      preview: false,

      disbaleExecuteWorkItem: false,
      disbaleCancelWorkItem: true,
      progressBarExecute: 0,
      statusText: '',
      linkDownload: '#',
      showLinkDownload: 'none',
      loadingBtn: false,
      reportText: '',
      listViewToExport: [],
      loadingView: false,
      listViewSelected: [],
      statusOptions: [],
      listSheetId: [],
      sheetReportId: null,
      workingItemState: null,
      paperSize: 'A1',
      contractType: 'LTA Civil Project',
      loadingSaveSetting: false
    };

  }
  componentDidMount = () => {

    socket.on('Workitem-Notification', this.handleRealtimeWorkitem)
  }
  handleRealtimeWorkitem = (data) => {
    if (workingItem === null || data.WorkitemId !== workingItem) {
      return
    } else if (data.WorkitemId === workingItem) {
      let status = data.Status.toLowerCase()
      if (status === 'completed' || status === 'failed' || status === 'cancelled') {
        workingItem = null;
      }
      this.updateStatus(status, data.ExtraInfo, data.Report);
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.displayPanel && onceTime === true) {
      trackingUserUsing(this.props.email,this.props.projectId,'BIM App','Design Automation')
      onceTime = false
      this.setState({ loading: true, loadingView: true })
      axios.post(`/api/items/get-setting`, { itemId: this.props.itemId, type: 'DesignAutomation' })
        .then(res => {
          this.setState({
            listViewSelected: res.data.listViewSelected ? res.data.listViewSelected : [],
            contractType: res.data.contractType ? res.data.contractType : '',
            paperSize: res.data.paperSize ? res.data.paperSize : 'A1',
            sheetReportId: res.data.sheetReportId ? res.data.sheetReportId : null,
            addInSelected: res.data.addInSelected ? res.data.addInSelected : null
          }, () => {
            this.setupSetting(nextProps)
          })
        })
        .catch(err => {
          this.setupSetting(nextProps)
        })
    }
    else if (!nextProps.displayPanel && onceTime === false) {
      onceTime = true
    }
  }
  setupSetting = (nextProps) => {
    this.getAllSmartSheet(nextProps)
    this.getAllView2DToExport(nextProps)
    this.getAddin(nextProps)
  }
  componentWillUnmount = () => {
    onceTime = true
    workingItem = null
    tempLinkDownload = ''
    socket.removeListener('Workitem-Notification', this.handleRealtimeWorkitem)
  }
  getAddin = (nextProps) => {
    axios.get('/api/forge/designautomation/list-activities')
      .then(res => {
        let temp = []
        nextProps.viewer.model.getProperties(nextProps.viewer.model.getRootId(), (modelAProperty) => {
          let version = '2018'
          _.forEach(modelAProperty.properties, v => {
            if (v.displayName === 'Revit Version') {
              version = v.displayValue.toString()
              return false
            }
          })

          let check = false
          _.forEach(res.data, (v, k) => {
            if (v.includes(version))
              temp.push({ key: k, text: v, value: v })
            if (v === this.state.addInSelected && this.state.addInSelected) {
              check = true
            }
          })
          this.setState({ listtActivities: temp, loading: false, addInSelected: check ? this.state.addInSelected : null })
        })
      })
      .catch(err => {
        message.error("Failed to get activities.")
        this.setState({ loading: false })
      })
  }
  getAllSmartSheet = (nextProps) => {
    if(this.props.isTesting){
      axios.get(`/api/dataentry/get-project-tables`, { params: { projectId: this.props.projectId, token: this.props.userInfo.token } })
      .then(res2 => {
        let tempListSheet = []
        let check = false
        _.forEach(res2.data, item => {
          if (item.type === 'for5d') {
            item.active = false
            tempListSheet.push({ key: item.name, text: item.name, value: item.id , item })
            if (item.id === this.state.sheetReportId && this.state.name !== null) {
              check = true
            }
          }    
        })    
        this.setState({ listSheetId: tempListSheet, sheetReportId: check ? this.state.sheetReportId : null }, () => {
          if (this.state.sheetReportId) {
            this.setupStatusOption()
          }
        })   
      })
      .catch(() => {
       
      })
    }else{
      axios.post(`/api/smartsheet/getfolder`, { folderSmartSheetId: nextProps.folderSmartSheetId })
      .then(res => {
        var tempListSheet = []
        let check = false
        res.data.forEach(item => {
          tempListSheet.push({ key: item.name, text: item.name, value: item.id })
          if (item.id === this.state.sheetReportId && this.state.name !== null) {
            check = true
          }
        })
        this.setState({ listSheetId: tempListSheet, sheetReportId: check ? this.state.sheetReportId : null }, () => {
          if (this.state.sheetReportId) {
            this.setupStatusOption()
          }
        })
      })
      .catch(err => { })
    }
  
  }
  getAllView2DToExport = (nextProps) => {
    let view2d = nextProps.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)
    let view3d = nextProps.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '3d', 'progress': 'complete' }, true)
    let view = view2d.concat(view3d)
    let temp = []
    let tempSelected = []
    _.forEach(view, v => {
      // if (v.data.size > 0)
      temp.push({ label: v.data.name, value: { viewableID: v.data.viewableID, name: v.data.name }, group: v.data.role.toUpperCase(), obj: v })
      // if(v.data.viewableID)
    })
    this.setState({ listViewToExport: temp, loadingView: false })
  }

  handleChangeSheetReport = (e, { value }) => {
    this.setState({ sheetReportId: value, loadingRefresh: true }, () => {
      this.setupStatusOption()
    })
  }
  setupStatusOption = () => {
    if(this.props.isTesting){
      axios.get(`/api/versions/get-status-percent?q=${this.props.itemId}`)
      .then(res=>{
        let index = _.findIndex(this.state.listSheetId, function (o) { return o.id === this.state.sheetReportId })
        if(index>=0){
          // let data =this.state.listSheetId[index].Status
        }
        console.log(res.data)
      })
      .catch(err=>{
        console.log(err)
      })
    }else{
      axios.post(`/api/smartsheet/get-column-status`, { smartSheetId: this.state.sheetReportId })
      .then(res => {
        this.matchStatusStorage(res.data)
      })
      .catch(err => { })
    }
 
  }
  matchStatusStorage = (data) => {
 
    let _this = this
    axios.get(`/api/versions/get-status-percent?q=${this.props.itemId}`)
      .then(res => {
        if (res.data.length !== 0) {
          let index = _.findIndex(res.data, function (o) { return o.smartSheetId === _this.state.sheetReportId })
          if (index >= 0) {
            let tempStatus = JSON.parse(res.data[index].status)
            let temp = [{ name: 'No Status', value: 0, color: "#ffffff", checked: true }]
            _.forEach(data, v => {
              let index = _.findIndex(tempStatus, function (o) { return o.name === v })
              if (index >= 0)
                temp.push({ name: v, value: tempStatus[index].value, color: tempStatus[index].color, checked: true })
              else
                temp.push({ name: v, value: 0, color: "#999999", checked: true })
            })
            this.setState({ statusOptions: temp })
          } else {
            let temp = [{ name: 'No Status', value: 0, color: "#ffffff", checked: true }]
            _.forEach(data, v => {
              temp.push({ name: v, value: 0, color: "#999999", checked: true })
            })
            this.setState({ statusOptions: temp })
          }
        } else {
          let temp = [{ name: 'No Status', value: 0, color: "#ffffff", checked: true }]
          _.forEach(data, v => {
            temp.push({ name: v, value: 0, color: "#999999", checked: true })
          })
          this.setState({ statusOptions: temp })
        }
      })
  }


  //#region //!design automation 
  startWorkitem = async () => {
    this.setState({ showLinkDownload: 'none', loadingBtn: true })
    tempLinkDownload = ''
    const fileName = this.props.objectKey
    const fileNameParams = fileName.split('.');
    if (fileNameParams[fileNameParams.length - 1].toLowerCase() !== "rvt") {
      message.error('Add-in just use for Revit file');
      return;
    }
    let displayName = this.props.itemDisplayName.includes('.rvt') ? this.props.itemDisplayName.split('.')[0] : this.props.itemDisplayName
    let inputRvtCurrent = `https://developer.api.autodesk.com/oss/v2/buckets/${this.props.projectId.toLowerCase()}/objects/${this.props.objectKey}`
    let listViewableID = []
    _.forEach(this.state.listViewSelected, v => {
      listViewableID.push(v.viewableID)
    })
    if (this.state.contractType !== 'Precast Project') {
      if (this.props.isTesting) {
        axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.sheetReportId })
          .then(res => {

          })
          .catch(err => {
            console.log(err)
          })
      } else {
        axios.post(`/api/smartsheet/getsheet`, { smartSheetId: this.state.sheetReportId })
        .then(async res => {
          var sheetData = await extractDataFromSmartSheet(res.data)
          let tempData = []
          if (this.state.contractType === 'LTA Civil Project') {
            _.forEach(sheetData, v => {
              if (v.content !== undefined && v.status !== undefined) {
                tempData.push({
                  content: v.content, status: v.status,
                  startPlan: moment(v.startPlan).format('DD-MM-YYYY'), endPlan: moment(v.endPlan).format('DD-MM-YYYY'),
                  startActual: moment(v.start).format('DD-MM-YYYY'), endActual: moment(v.end).format('DD-MM-YYYY')
                })
              }
            })
          } else {
            let temp = {}
            _.forEach(this.state.statusOptions, (v, k) => {
              if (k === 0 && v.name.check) {
                temp[v.name] = 0
              }
              else
                temp[v.name] = this.state.statusOptions.length - k
            })
            _.forEach(sheetData, v => {
              if (v.content !== undefined && v.status !== undefined) {
                tempData.push({
                  content: v.content, status: temp[v.status],
                  startPlan: moment(v.startPlan).format('DD-MM-YYYY'), endPlan: moment(v.endPlan).format('DD-MM-YYYY'),
                  startActual: moment(v.start).format('DD-MM-YYYY'), endActual: moment(v.end).format('DD-MM-YYYY')
                })
              }
            })
          }


          // console.log(tempData)
          let inputJson = {
            viewableID: listViewableID,
            listData: tempData,
            status: this.state.statusOptions,
            paper: this.state.paperSize,
            contractType: this.state.contractType,
            projectKey: this.props.projectKey
          };

          try {
            let res = null;
            if (exporting) {
              this.updateStatus('started');
              res = await this.exportDWF(inputRvtCurrent, inputJson, displayName);
            }
            workingItem = res.WorkitemId;
            tempLinkDownload = res.ExtraInfo
            this.setState({ workingItemState: res.WorkitemId })
            this.updateStatus(res.Status);
          } catch (err) {
            message.error('Failed to start add-in')
            this.updateStatus('failed');
          }
        })
      }
    } else {
      if (!this.props.projectKey) {
        message.warning('Please key in PPVC code')
        return
      }
      axios.get("/api/wohhup/getModules", { params: { siteCode: this.props.projectKey } })
        .then(res => {
          if (res && res.data.isSuccessful) {
            let data = res.data.result
            if (this.props.typeProject === 'PreCast') {
              axios.get('/api/customproperty/get-custom-ppvc', { params: { projectKey: this.props.projectKey } })
                .then(async res => {
                  this.combineData(res.data.ppvc, data)
                  this.getModelData(data, listViewableID, inputRvtCurrent, displayName)
                })
                .catch(err => {
                  message.error('Sync file failed')
                  this.setState({ loadingBtn: false })
                })
            } else if (this.props.typeProject === 'PPVC/PBU') {
              this.getModelData(data, listViewableID, inputRvtCurrent, displayName)
            }
          }
          else {
            message.warning("Can't get data from apps.wohhup.com")
            this.setState({ loadingBtn: false })
          }
        })
    }
  }
  combineData = (ppvc, data) => {
    _.forEach(data, item => {
      let index = _.findIndex(ppvc, (o) => { return o.code.toLowerCase() === item.code.toLowerCase() })
      if (index >= 0) {
        if (ppvc[index].newCode !== '') {
          item.actions.push({
            actionName: `Module Installed on ${this.props.projectKey}`,
            completedDate: moment(ppvc[index].date, 'DD-MM-YYYY').toDate(),
            currentSiteCode: this.props.projectKey,
            currentSiteStorage: null
          })
        }
      }
    });

  }
  getModelData = async (data, listViewableID, inputRvtCurrent, displayName) => {
    let { tempData } = handleModulePPVCAndPrecast(data, this.props.typeProject, this.props.projectKey);
    let inputJson = {
      viewableID: listViewableID,
      listData: tempData,
      status: [{
        "name": "Casting Completed",
        "value": 0,
        "color": "#1890ff"
      },
      {
        "name": "Delivered to Site",
        "value": 20,
        "color": "#f39c12"
      },
      {
        "name": "Installed on Site",
        "value": 20,
        "color": "#722ed1"
      },],
      paper: this.state.paperSize,
      contractType: this.state.contractType,
      projectKey: this.props.projectKey
    };

    try {
      let res = null;
      if (exporting) {
        this.updateStatus('started');
        res = await this.exportDWF(inputRvtCurrent, inputJson, displayName);
      }
      workingItem = res.WorkitemId;
      tempLinkDownload = res.ExtraInfo
      this.setState({ workingItemState: res.WorkitemId })
      this.updateStatus(res.Status);
    } catch (err) {
      message.error('Failed to start add-in')
      this.updateStatus('failed');
    }
  }
  exportDWF = async (inputRvtCurrent, inputJsonCurrent, displayName) => {
    const res = await axios.post('/api/forge/da4revit/v1/revit/export-dwf', {
      inputRvt: inputRvtCurrent,
      appBundleName: this.state.addInSelected,
      inputJson: JSON.stringify(inputJsonCurrent),
      itemDisplayName: displayName
    })
    return res.data;

  }
  cancelWorkitem = (workitemId) => {
    let def = $.Deferred();

    if (workitemId === null || workitemId === '') {
      def.reject("parameters are not correct.");
      return def.promise();
    }

    $.ajax({
      url: '/api/forge/da4revit/v1/revit/' + encodeURIComponent(workitemId),
      type: "delete",
      dataType: "json",
      success: function (res) {
        def.resolve(res);
      },
      error: function (err) {
        def.reject(err);
      }
    });
    return def.promise();
  }
  updateStatus = (status, extraInfo = '', report) => {
    switch (status) {
      case "started":
        this.setState({
          progressBarExecute: 20,
          statusText: `Step ${exporting ? "1/3" : "1/4"}: Uploading input parameters`,
          disbaleExecuteWorkItem: true,
          disbaleCancelWorkItem: true,
          loadingBtnStatus: false,
          reportText: ''
        })
        break;
      case "pending":
        this.setState({
          progressBarExecute: exporting ? 60 : 40,
          statusText: `Step ${exporting ? "2/3" : "2/4"}: Running Design Automation`,
          disbaleExecuteWorkItem: true,
          disbaleCancelWorkItem: false,
          loadingBtnStatus: false
        })
        break;
      case "newversion":
        this.setState({
          progressBarExecute: 80,
          statusText: `Step 3/4: Creating a new version`,
          disbaleExecuteWorkItem: true,
          disbaleCancelWorkItem: true,
          reportText: report,
          loadingBtnStatus: false
        })
        break;
      case "completed":
        this.setState({
          progressBarExecute: 100,
          showLinkDownload: 'block',
          linkDownload: extraInfo,
          statusText: `Completed`,
          disbaleExecuteWorkItem: false,
          disbaleCancelWorkItem: true,
          loadingBtn: false,
          reportText: report,
          workingItemState: null,
          loadingBtnStatus: false
        }, () => {
          tempLinkDownload = ''
        })
        break;
      case "failed":
        this.setState({
          progressBarExecute: 0,
          statusText: `Failed to process`,
          disbaleExecuteWorkItem: false,
          disbaleCancelWorkItem: true,
          loadingBtn: false,
          reportText: report,
          workingItemState: null,
          loadingBtnStatus: false
        })
        break;
      case "cancelled":
        this.setState({
          progressBarExecute: 0,
          statusText: `The operation is cancelled`,
          disbaleExecuteWorkItem: false,
          disbaleCancelWorkItem: true,
          loadingBtn: false,
          reportText: report,
          workingItemState: null,
          loadingBtnStatus: false
        })
        break;
    }
  }
  handleCancelWorkItem = async () => {
    if (workingItem != null) {
      try {
        await this.cancelWorkitem(workingItem);
        console.log('The job is cancelled');
      } catch (err) {
        console.log('Failed to cancel the job');
      }
    }
  }
  handleStatusWorkItem = () => {
    this.setState({ loadingBtnStatus: true })
    axios.get(`/api/forge/da4revit/v1/revit/${encodeURIComponent(this.state.workingItemState)}`)
      .then(res => {
        this.updateStatus(res.data.Status, res.data.Status === 'completed' ? tempLinkDownload : '', res.data.Report);
        message.info(res.data.Status)
      })
      .catch(err => message.error('Error when get status'))
  }
  //#endregion

  handleCloseViewPanel = () => {
    onceTime = true
    this.props.onDisplayPanelChange('designAutomationPanel', false)
  }
  handleChangeAddin = (e, data) => {
    this.setState({ addInSelected: data.value })
  }

  handleColorStatus = (item, e) => {
    let index = _.findIndex(this.state.statusOptions, function (o) { return o.name === item.name })
    let temp = this.state.statusOptions
    temp.splice(index, 1)
    temp.splice(index, 0, { name: item.name, value: item.value, color: e.target.value, checked: item.checked })
    this.setState({ statusOptions: temp })
  }

  //#region //! unuse
  handleReloadElement = () => {
    let _this = this
    this.setState({ loadingRefresh: true })
    let temp = getAllElementdbIdsOneModel(this.props.viewer);
    var instanceTree = this.props.viewer.impl.model.getData().instanceTree;
    let tempElement = []
    let count = temp.length
    _.forEach(temp, id => {
      let isHide = instanceTree.isNodeHidden(id)
      this.props.viewer.model.getProperties(id, (modelAProperty) => {
        instanceTree.enumNodeFragments(id, function (frag) {
          let renderProxy = _this.props.viewer.impl.getRenderProxy(_this.props.viewer.model, frag)
          if (renderProxy.themingColor) {
            let colorCurrent = convertVector4ColorToHex(renderProxy.themingColor)
            let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
            if (index < 0)
              tempElement.push({
                id: id, guid: modelAProperty.externalId, color: colorCurrent, show: !isHide
              })
          }
        })
        count--
        if (count === 0) {
          console.log(tempElement)
          _this.setState({ listElementOverride: tempElement, loadingRefresh: false })
        }
      })
    })
  }
  handleClickTag = (k, e) => {
    this.props.viewer.select(k)
  }
  handlePreviewElement = () => {
    this.setState({ preview: !this.state.preview })
  }
  //#endregion

  //#region  // ? select view 2d
  handleViewChecked = (value, e) => {
    if (value === null)
      value = []
    this.setState({ listViewSelected: value })
  }
  handleClearAllViewChecked = () => {
    this.setState({ listViewSelected: [] })
  }
  //#endregion
  handleChangePaperSize = (e, data) => {
    this.setState({ paperSize: data.value })
  }
  handleChangeContractType = (e, data) => {
    this.setState({ contractType: data.value })
  }
  handleSaveSetting = () => {
    this.setState({ loadingSaveSetting: true })
    const tempData = {
      listViewSelected: this.state.listViewSelected ? this.state.listViewSelected : [],
      contractType: this.state.contractType ? this.state.contractType : '',
      paperSize: this.state.paperSize ? this.state.paperSize : 'A1',
      sheetReportId: this.state.sheetReportId ? this.state.sheetReportId : null,
      addInSelected: this.state.addInSelected ? this.state.addInSelected : null
    }
    console.log(tempData)
    axios.post(`/api/items/save-setting`, { itemId: this.props.itemId, data: tempData, type: 'DesignAutomation' })
      .then(res => {
        this.setState({ loadingSaveSetting: false })
        message.success('Saved')
      })
      .catch(err => {
        this.setState({ loadingSaveSetting: false })
        message.warning('Error')
      })

  }
  handleCheckStatus = (item, e) => {
    let index = _.findIndex(this.state.statusOptions, function (o) { return o.name === item.name })
    let temp = this.state.statusOptions
    temp.splice(index, 1)
    temp.splice(index, 0, { name: item.name, value: item.value, color: item.value, checked: e.target.checked })
    this.setState({ statusOptions: temp })
  }
  render() {
    const contractTypeOpt = [
      { key: 'LTA Civil Project', text: 'LTA Civil Project', value: 'LTA Civil Project', },
      { key: 'Building Project', text: 'Building Project', value: 'Building Project', },
      { key: 'Precast Project', text: 'Precast Project', value: 'Precast Project', },
    ]
    const paperSize = [
      { key: 'A0', text: 'A0', value: 'A0', },
      { key: 'A1', text: 'A1', value: 'A1', },
      { key: 'A2', text: 'A2', value: 'A2', },
      { key: 'A3', text: 'A3', value: 'A3', },
      { key: 'A4', text: 'A4', value: 'A4', },
    ]
    return (
      <div >
        <Drawer backdrop={false} show={this.props.displayPanel} onHide={this.handleCloseViewPanel} keyboard={false}
          placement='right' size='xs'>

          <Drawer.Header>
            <Drawer.Title>
              <IconButton icon={<Icon icon='save' />} size='sm' color='blue' onClick={this.handleSaveSetting} disabled={this.state.loading}
                loading={this.state.loadingSaveSetting} />
              Generate Progress Report

            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body id='addInDrawer'>
            {this.state.loading ? <Loader backdrop center content="Loading..." speed="fast" size="md" vertical /> :
              this.state.listtActivities.length === 0 ?
                <Empty />
                :
                <div>
                  <Dropdown onChange={this.handleChangeContractType}
                    placeholder='Contract Type'
                    fluid style={{ marginBottom: 5 }}
                    selection value={this.state.contractType}
                    options={contractTypeOpt}
                  />
                  <Dropdown onChange={this.handleChangeAddin}
                    placeholder='Add-in'
                    fluid style={{ marginBottom: 5 }}
                    selection value={this.state.addInSelected}
                    options={this.state.listtActivities}
                  />
                  <Dropdown onChange={this.handleChangePaperSize}
                    placeholder='Paper Size' value={this.state.paperSize}
                    fluid style={{ marginBottom: 5 }} selection
                    options={paperSize} defaultValue={this.state.paperSize}
                  />
                  <CheckPicker data={this.state.listViewToExport} style={{ width: '100%', marginTop: 5, marginBottom: 5 }} placeholder="View"
                    onChange={this.handleViewChecked} cleanable={true} groupBy="group" onClean={this.handleClearAllViewChecked}
                    value={this.state.listViewSelected} maxHeight={320} placement='leftStart'
                    renderMenu={menu => {
                      if (this.state.loading) {
                        return (
                          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                            <Icon icon="spinner" spin /> Loading...
                          </p>
                        );
                      }
                      return menu;
                    }}
                    renderMenuItem={(label, item) => {
                      return (
                        <div >
                          <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
                        </div>
                      );
                    }}
                    renderMenuGroup={(label, item) => {
                      return (
                        <div>
                          <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
                        </div>
                      );
                    }}
                    renderValue={(value, item) => {
                      let temp = []
                      _.forEach(value, v => {
                        temp.push(v.name)
                      })
                      return (
                        <span>
                          <span style={{ color: '#575757' }}>
                            <i className="rs-icon rs-icon-newspaper-o" />
                          </span>{' '}
                          {temp.join(' , ')}
                        </span>
                      )

                    }} />
                  {this.state.contractType !== 'Precast Project' &&
                    <>
                      <Dropdown
                        placeholder='Smartsheet Report' fluid selection
                        onChange={this.handleChangeSheetReport} value={this.state.sheetReportId}
                        options={this.state.listSheetId}
                      />

                      {this.state.statusOptions.length !== 0 &&
                        < List size="small" bordered style={{ overflow: 'auto' }}
                          dataSource={this.state.statusOptions}
                          renderItem={item =>
                            <List.Item>

                              <Text strong>
                                <input type='checkbox' checked={item.checked} onChange={this.handleCheckStatus.bind(this, item)} />
                                {item.name}</Text>
                              <input type='color' style={{ position: 'absolute', right: 10 }} value={item.color}
                                onChange={this.handleColorStatus.bind(this, item)} />
                            </List.Item>
                          }
                        />
                      }
                    </>}

                  <div style={{ textAlignLast: 'center' }}>
                    {this.state.progressBarExecute !== 0 &&
                      <Progress id='configProgressBar' percent={this.state.progressBarExecute} indicating style={{ width: '95%', left: '2.5%', marginBottom: 5 }} />
                    }

                    <Header as='h5' style={{ marginTop: 0 }}>{this.state.statusText}</Header>
                    {this.state.statusText === 'Completed' &&
                      <a href={this.state.linkDownload} target="_blank">
                        <Header style={{ display: this.state.showLinkDownload }} as='h5' color='blue' >DOWNLOAD</Header>
                      </a>}
                  </div>
                  {/* <ButtonGroup>
                    <IconButton icon={<Icon icon="retention" />} color={this.state.preview && 'red'} onClick={this.handlePreviewElement} />
                    <IconButton icon={<Icon icon="reload" />} onClick={this.handleReloadElement} loading={this.state.loadingRefresh} />
                  </ButtonGroup> */}
                  <br />
                  {this.state.preview && this.state.listElementOverride.length !== 0 &&
                    this.state.listElementOverride.map((v, k) =>
                      <Tag style={{ marginBottom: 8 }} color={v.color} onClick={this.handleClickTag.bind(this, v.id)}>{v.id} </Tag>
                    )
                  }
                  {(!this.state.preview && this.state.reportText !== '' && this.state.statusText !== 'Completed') &&
                    <div style={{ height: 'calc(100% - 200px)', position: 'absolute', width: '100%', color: 'black' }}>
                      <TextArea style={{ color: 'black' }}
                        value={this.state.reportText} disabled={true}
                        onChange={this.onChange} autoSize
                        placeholder="Log"
                      />
                    </div>
                  }


                </div>
            }
          </Drawer.Body>
          <Drawer.Footer style={{ marginBottom: 5 }}>
            <ButtonGroup>
              {(this.state.workingItemState !== null) &&
                <Button color="green" onClick={this.handleStatusWorkItem} disabled={this.state.workingItemState === null} loading={this.state.loadingBtnStatus} >
                  <Icon icon="check" /> Status
                </Button>}
              {this.state.contractType !== 'Precast Project' ?
                <Button color="blue" onClick={this.startWorkitem} disabled={this.state.addInSelected === null || this.state.sheetReportId === null
                  || this.state.listViewSelected.length === 0 || this.state.statusOptions.length === 0}
                  loading={this.state.loadingBtn} >
                  <Icon icon="play" /> Execute
                </Button>
                : <Button color="blue" onClick={this.startWorkitem} disabled={this.state.addInSelected === null
                  || this.state.listViewSelected.length === 0}
                  loading={this.state.loadingBtn} >
                  <Icon icon="play" /> Execute
                </Button>}
              <Button color="red" disabled={this.state.disbaleCancelWorkItem} onClick={this.handleCancelWorkItem} >
                <Icon icon="pause" /> Cancel
              </Button>
            </ButtonGroup>
          </Drawer.Footer>
        </Drawer>

      </div>

    );
  }
}
SidebarDesignAutomation.propTypes = {
  email: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
    userInfo: state.user,
  }
}
export default connect(mapStateToProps)(SidebarDesignAutomation)


