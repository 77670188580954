import { GET_SHEET_ROWS, OVERWRITE_ROW_REDUCER } from '../../types';


export const getSheetRows = (data) => dispatch => {
   dispatch({
      type: GET_SHEET_ROWS,
      payload: data
   });
};
export const overwriteRowReducer = () => dispatch => {
   dispatch({
      type: OVERWRITE_ROW_REDUCER
   });
};



