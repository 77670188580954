import React, { useState, useEffect } from 'react';

import { getAvatar } from '../api/employee';

import _ from 'lodash';

import { Avatar, Tooltip } from 'antd';

function AvatarEmployee(props) {
  const [image, setImage] = useState(null);

  function getShortName(name = '') {
    let shortName = '';
    let fs = name.split(' ');
    let i = 0;
    fs.forEach((x) => {
      if (i > 0) {
        shortName += '.' + x.charAt(0);
      } else {
        shortName += x.charAt(0);
      }
      i++;
    });

    return shortName;
  }

  useEffect(() => {
    if (props.imageId) {
      getImage();
    }
  }, [props.imageId]);

  async function getImage() {
    try {
      let res = await getAvatar({ key: props.imageId, time: 60 });
      setImage(res);
    } catch (error) {
      setImage(null);
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Tooltip placement='bottom' title={props.workerName}>
        {image ? (
          <Avatar shape='square' size={64} src={image} />
        ) : (
          <Avatar size={64}>{getShortName(props.workerName)}</Avatar>
        )}
      </Tooltip>
    </div>
  );
}

export default AvatarEmployee;
