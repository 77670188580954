import React from "react";
import Excel from "exceljs";
import * as fs from "file-saver";
import dayjs from "dayjs";
import _ from "lodash";
import { format } from 'date-fns';

function numToSheetColumn(num) {
   var s = "",
      t;

   while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = ((num - t) / 26) | 0;
   }
   return s || undefined;
}

const formatHeader = (ws, arrayCol, row, rowHeight, iStart, iEnd, fontName, fontSize, horAlightment, fillColor) => {
   for (let i = iStart; i < iEnd + 1; i++) {
      const col_name = numToSheetColumn(i);
      ws.getColumn(col_name).width = arrayCol[i - iStart].width;
      ws.getRow(row).height = rowHeight;
      const cell_name = col_name + row;
      var cell = ws.getCell(cell_name);
      cell.value = arrayCol[i - iStart].value;
      cell.font = {
         name: fontName,
         size: fontSize,
      };
      cell.alignment = {
         vertical: "middle",
         horizontal: horAlightment,
         wrapText: true,
      };
      cell.border = {
         top: { style: "thin" },
         left: { style: "thin" },
         bottom: { style: "thin" },
         right: { style: "thin" },
      };
      cell.fill = {
         type: "pattern",
         pattern: "solid",
         fgColor: { argb: fillColor },
         bgColor: { argb: fillColor },
      };
   }
};

const formatRow = (ws, arrayCell, row, rowHeight, iStart, iEnd, fontName, fontSize, bold, fillColor, hairBorder) => {
   for (let i = iStart; i < iEnd + 1; i++) {
      const col_name = numToSheetColumn(i);
      ws.getRow(row).height = rowHeight;
      const cell_name = col_name + row;
      const j = i - iStart;
      var cell = ws.getCell(cell_name);
      cell.value = arrayCell[j].value;
      cell.font = {
         name: fontName,
         size: fontSize,
         bold: bold,
      };
      cell.alignment = {
         vertical: "middle",
         horizontal: arrayCell[j].hor_alight,
         wrapText: true,
      };
      if (hairBorder) {
         cell.border = {
            top: { style: "hair" },
            left: { style: "thin" },
            bottom: { style: "hair" },
            right: { style: "thin" },
         };
      } else {
         cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
         };
      }

      if (arrayCell[j].numFmt !== undefined) {
         cell.numFmt = arrayCell[j].numFmt;
      }
      if (fillColor !== "na") {
         cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: fillColor },
            bgColor: { argb: fillColor },
         };
      }
   }
};

export const Func_Export_Summary_By_Trade_To_Excel = async (data, lastMonth) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("summary by trade");

   worksheet.columns = [
      { header: "Trade", key: "name", width: 50 },
      { header: `${lastMonth}_no`, key: "total_no_current_month", width: 25 },
      { header: `${lastMonth}_yes`, key: "total_yes_current_month", width: 25 },
      { header: `${lastMonth}_%`, key: "percent_current", width: 25 },
      { header: "total_no", key: "total_no", width: 25 },
      { header: "total_yes", key: "total_yes", width: 20 },
      { header: "%", key: "percent", width: 25 },
   ];

   data.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Summary_by_Trade.xlsx");
   });
};

export const Func_Export_Critical_Summary_By_Trade_To_Excel = async (data, lastMonth) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("critical_sum");

   worksheet.columns = [
      { header: "Trade", key: "name", width: 50 },
      { header: `${lastMonth}_no`, key: "total_no_current_month", width: 15 },
      { header: `${lastMonth}_1st`, key: "total_1st_current_month", width: 15 },
      { header: `${lastMonth}_1st+`, key: "total_1plus_currentt_month", width: 15 },
      { header: `${lastMonth}_%`, key: "percent_current", width: 15 },
      { header: "total_no", key: "total_no", width: 15 },
      { header: "total_1st", key: "total_1st", width: 15 },
      { header: "total_1st+", key: "total_1plus", width: 15 },
      { header: "%", key: "percent", width: 15 },
   ];

   data.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Summary_by_Trade.xlsx");
   });
};

export const Func_Export_Violation_Summary_By_Sub_To_Excel = async (data, lastMonth) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("summary by trade");

   worksheet.columns = [
      { header: "Trade", key: "name", width: 50 },
      { header: "total_nos", key: "total", width: 25 },
      { header: "demerit point", key: "demerit_point", width: 20 },
      { header: "withholding_amount", key: "withholding_amount", width: 20 },
      { header: "%", key: "percent", width: 25 },
   ];

   data.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Summary_by_Trade.xlsx");
   });
};

export const Func_Export_QM_Unit = async (data) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("summary by unit");

   worksheet.columns = [
      { header: "Unit", key: "unit", width: 15 },
      { header: "QM work", key: "qmwork", width: 15 },
      { header: "Reconditioning (Final)", key: "reconwork", width: 20 },
      { header: "Work to Backcharge", key: "bcwork", width: 25 },
      { header: "Other work", key: "otherwork", width: 25 },
      { header: "Total Hours", key: "total", width: 25 },
      { header: "No of sample", key: "no_of_sample", width: 30 },
      { header: "Man-day/Sample", key: "ms", width: 30 },
      { header: "Man-day/sample (QM+Recon)", key: "msQR", width: 35 },
   ];

   data.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "QM_By_Unit.xlsx");
   });
};

export const Func_Export_QM_Data = async (data) => {
   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("summary by unit");

   worksheet.columns = [
      { header: "date", key: "date", width: 30 },
      { header: "Foreman", key: "Foreman", width: 75 },
      { header: "Work ype", key: "WorkType", width: 50 },
      { header: "Unit", key: "Unit", width: 25 },
      { header: "Worker", key: "Worker", width: 50 },
      { header: "Activity", key: "Activity", width: 75 },
      { header: "Hours: ", key: "Hours", width: 25 },
   ];

   data.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "QM_Data.xlsx");
   });
};
export const Func_Export_Unit_Handed_Over = async (exportType, data) => {
   let unwindedData = []
   switch (exportType) {
      case 'current':
         _.forEach(data, e => {
            if (new Date(e.date).getYear() === new Date().getYear() && new Date(e.date).getMonth() === new Date().getMonth()) {
               if (!e.bcaInspected) {
                  let listItemsNotAcceptted = e.listItemsNotAcceptted ? e.listItemsNotAcceptted : []
                  if (listItemsNotAcceptted.length > 0) {
                     _.forEach(listItemsNotAcceptted, f => {
                        unwindedData.push({
                           handedOverDate: format(new Date(e.date), 'dd MMM yyyy'),
                           qmRepresentative: e.qmRepresentative,
                           archiRepresentative: e.archiRepresentative,
                           block: e.block,
                           unitName: e.level + '-' + e.unit,
                           notAccepted: f,
                           comment: e.comment
                        })
                     })
                  }
               }
            }
         })
         break;
      case 'all':
         _.forEach(data, e => {
            if (!e.bcaInspected) {
               let listItemsNotAcceptted = e.listItemsNotAcceptted ? e.listItemsNotAcceptted : []
               if (listItemsNotAcceptted.length > 0) {
                  _.forEach(listItemsNotAcceptted, f => {
                     unwindedData.push({
                        handedOverDate: format(new Date(e.date), 'dd MMM yyyy'),
                        qmRepresentative: e.qmRepresentative,
                        archiRepresentative: e.archiRepresentative,
                        block: e.block,
                        unitName: e.level + '-' + e.unit,
                        notAccepted: f,
                        comment: e.comment
                     })
                  })
               }
            }

         })
         break;
   }

   const workbook = new Excel.Workbook();
   const worksheet = workbook.addWorksheet("summary by unit");

   worksheet.columns = [
      { header: "Handed over date", key: "handedOverDate", width: 25 },
      { header: "QM representative", key: "qmRepresentative", width: 25 },
      { header: "Archi representative", key: "archiRepresentative", width: 25 },
      { header: "Block", key: "block", width: 10 },
      { header: "Unit name", key: "unitName", width: 15 },
      { header: "Not accepted item", key: "notAccepted", width: 70 },
      { header: "Comment", key: "comment", width: 70 },
   ];

   unwindedData.forEach((d) => {
      let row = worksheet.addRow(d);
   });

   workbook.xlsx.writeBuffer().then((unwindedData) => {
      let blob = new Blob([unwindedData], {
         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "Unit_Handed_Over_Data.xlsx");
   });
};
