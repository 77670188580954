import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { message } from 'antd';
import { Button, Icon, IconButton, Drawer, Loader, Divider } from 'rsuite';
import _ from 'lodash'
import moment from 'moment';
import { getPublicUrl, socket } from '../../function/AdminFunction'
import base64url from 'base64url'
import { Position, Toaster } from "@blueprintjs/core";



const propsUpload = {
  name: 'file',
  multiple: false,
  showUploadList: true,

};
const statusOptions = [
  { label: 'Open', text: 'Open', value: 'Open' },
  { label: 'In progress', text: 'In progress', value: 'In progress' },
  { label: 'Waiting', text: 'Waiting', value: 'Waiting' },
  { label: 'Close', text: 'Close', value: 'Close' }
]
const priorityOptions = [
  { label: 'Very urgent', text: 'Very urgent', value: 'Very urgent', color: 'red' },
  { label: 'Urgent', text: 'Urgent', value: 'Urgent', color: 'orange' },
  { label: 'Normal', text: 'Normal', value: 'Normal', color: '#f4d90b' },
  { label: 'Low', text: 'Low', value: 'Low', color: 'blue' },
]
export default function DialogIssueInformation(props) {
  const [loading, setLoading] = useState(false)
  const [loadingMember, setLoadingMember] = useState(false)
  const [loadingAttach, setLoadingAttach] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [listMember, setListMember] = useState([])
  const [valueComment, setValueComment] = useState('')
  const [listAssignViewPoint, setListAssignViewPoint] = useState([])
  const [comments, setComments] = useState([])
  const [attachFile, setAttachFile] = useState([])
  const [viewPoint, setViewPoint] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [disableDescription, setDisableDescription] = useState(true)
  const [description, setDescription] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [commentFile, setCommentFile] = useState([])
  const [imageMoreComment, setImageMoreComment] = useState([])
  const [status, setStatus] = useState('')
  const [priority, setPriority] = useState('')
  const toaster = useRef()

  const handleClose = () => {
    setLoading(true)
    let temp = props.listSaveView.slice(0)
    axios.get("/api/comment/get-comment", { params: { id: props.viewPoint.id } })
      .then(res => {
        let index = _.findIndex(temp, e => { return e.id === props.viewPoint.id })
        if (index >= 0) {
          temp[index] = res.data.comment
          temp[index].checked = props.viewPoint.checked
          axios.post("/api/comment/set-comment-unread", { id: props.viewPoint.id, email: props.email })
            .then(() => {
              props.handleClose(temp)
            })
            .catch(() => {
              props.handleClose(temp)
            })
        }
      })
      .catch(() => {
        axios.post("/api/comment/set-comment-unread", { id: props.viewPoint.id, email: props.email })
          .then(() => {
            props.handleClose(null)
          })
          .catch(() => {
            props.handleClose(null)
          })
      })
  }
  useEffect(() => {
    setLoading(true)
    setLoadingMember(true)
    axios.get("/api/comment/get-comment", { params: { id: props.viewPoint.id } })
      .then(res => {
        let issue = res.data.comment
        setStatus(issue.status)
        setAttachFile(issue.attachFile)
        setDescription(issue.descriptionViewPoint)
        setViewPoint(issue)
        setLoading(false)
        getCommentImageUrl(issue.comments)
        setPriority(issue.priority ? issue.priority : 'Low')

        axios.get(`/api/projects/members?q=${props.projectId}`)
          .then(res => {
            let temp = []
            res.data.members.sort()
            res.data.members.splice(0, 0, props.userCreatedProject)
            _.forEach(res.data.members, v => {
              if (v !== props.email || v !== issue.userCreated)
                temp.push({
                  label: v,
                  value: v,
                  role: 'user'
                })
            })
            _.forEach(res.data.groupUser, v => {
              temp.push({
                label: v.name,
                value: v.name,
                role: 'group'
              })
            })
            let tempListAssign = []
            _.forEach(issue.listAssignViewPoint, v => {
              let index = _.findIndex(temp, e => { return e.label === v })
              if (index >= 0)
                tempListAssign.push(v)
            })
            setListAssignViewPoint(tempListAssign)
            setListMember(temp)
            setLoadingMember(false)
          })
          .catch(() => {
            setLoadingMember(false)
          })
      })
      .catch(() => {
        setLoading(false)
        props.handleClose(null)
        message.warning(`Can't get issue`)
      })
  }, [])

  useState(() => {
    socket.on('realtime-cp-comment-comment', (data) => {
      if (data.id === props.viewPoint.id) {
        if (data.item.length === 0)
          setComments(data.item)
        else
          getCommentImageUrl(data.item)
      }
      // props.handleNewComment(data)
    })
  }, [])



  const getCommentImageUrl = (comments) => {
    let temp = comments.slice()
    let count = temp.length
    _.forEach(temp, comment => {
      if(comment.avatar ===''){
        comment.avatar = ''
          count--
          if (count === 0) {
            setComments(temp)
          }
      }else{
        getPublicUrl(comment.avatar)
        .then(res => {
          comment.avatar = res
          count--
          if (count === 0) {
            setComments(temp)
          }
        })
        .catch(err => {
          comment.avatar = err
          count--
          if (count === 0) {
            setComments(temp)
          }
        })
      }    
    })

  }

  const handleModifyInfoViewPoint = () => {
    setLoading(true)
    let temp = []
    var today = moment(Date.now()).format('DD-MM-YYYY')
    let tempData = null
    if (!props.newTab) {
      _.forEach(props.listSaveView, v => {
        if (v.id === props.viewPoint.id) {
          v.descriptionViewPoint = description
          v.listAssignViewPoint = listAssignViewPoint
          v.userModified = props.email
          v.dateModified = today
          v.status = status
          v.comments = comments
          v.priority = priority
          tempData = v
        }
        temp.push(v)
      })
    } else {
      viewPoint.descriptionViewPoint = description
      viewPoint.listAssignViewPoint = listAssignViewPoint
      viewPoint.userModified = props.email
      viewPoint.dateModified = today
      viewPoint.status = status
      viewPoint.comments = comments
      viewPoint.priority = priority
    }
    let path = `${window.location.origin}`
    axios.post(`/api/comment/update-comment`, { projectId: props.projectId, id: viewPoint.id, comment: !props.newTab ? tempData : viewPoint, baseUrl: path })
      .then(res => {
        setLoading(false)
        if (!props.newTab) {
          let index = _.findIndex(temp, e => { return e.id === props.viewPoint.id })
          if (index >= 0) {
            temp[index] = res.data
            axios.post("/api/comment/set-comment-unread", { id: props.viewPoint.id, email: props.email })
              .then(() => {
                props.handleClose(temp)
              })
              .catch(() => {
                props.handleClose(temp)
              })
            // props.handleClose(temp)
          }
        }

        message.success(`Modified comment.`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't modified issue.`);
      })
  }
  const handleCustomRequest = ({ file }) => {
    if (file !== null) {
      let path = `${window.location.origin}`
      let data = new FormData()
      data.append('file', file)
      data.append('id', props.viewPoint.id)
      data.append('baseUrl', path)
      data.append('email', props.email)
      if (data !== undefined || data !== null) {
        setLoadingAttach(true)
        axios.post(`/api/comment/attach-file-to-comment-cp`, data, { timeout: 60 * 10 * 1000 })
          .then(res => {
            setAttachFile(res.data)
            setLoadingAttach(false)
          })
          .catch(() => {
            setLoadingAttach(false)
            message.error('Attach file was failed')
          })
      }

    }
  }
  const handleRemoveFile = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setLoadingAttach(true)
    let path = `${window.location.origin}`
    axios.post(`/api/comment/remove-file-from-comment-cp`, { id: viewPoint.id, fileName: file.name, email: props.email, baseUrl: path })
      .then(res => {
        setAttachFile(res.data)
        setLoadingAttach(false)
      })
      .catch(() => {
        setLoadingAttach(false)
        message.error('Remove file was failed')
      })
  }
  const beforeUpload = (file) => {
    const isImgIssue = file.name === `${viewPoint.id}.png`
    if (isImgIssue) {
      message.warning('Please change name your image');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.warning('Image must smaller than 20MB!');
    }
    return isLt2M && !isImgIssue;
  }
  const handleModifyAssigne = (value) => {
    if (value === null)
      setListAssignViewPoint([])
    else
      setListAssignViewPoint(value)
    setDisabled(false)
  }

  const handleChangeComment = (e) => {
    setValueComment(e.target.value)
  }






  //#region //!description
  const handleDisableDescription = () => {
    setTempDescription(description)
    setDisableDescription(!disableDescription)
  }
  const handleConfirmDescription = () => {
    if (viewPoint.descriptionViewPoint !== tempDescription) {
      if (!window.confirm('Are you sure to change description')) return
      setDescription(tempDescription)
      setDisabled(false)
    }
    setDisableDescription(true)
  }
  const handleRefreshDescription = () => {
    if (!window.confirm('Are you sure to refresh description')) return
    setDescription(viewPoint.descriptionViewPoint)
  }
  const handleChangeDescription = (e) => {
    setTempDescription(e.target.value)
  }
  const handleCloseEditDescription = () => {
    setDisableDescription(true)
  }
  //#endregion

  //#region //! comment
  const handleEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].edit = true
      temp[index].tempContent = temp[index].content
      //  console.log(moment(temp[index].datetime, 'lll').add(1, 'hours'))
      //  console.log( moment(Date.now()))
    }
    setComments(temp)

  }
  const handleConfirmEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      if (temp[index].tempContent.length === 0) return
      if (temp[index].tempContent !== temp[index].content) {
        if (!window.confirm('Are you sure to change comment')) return
        setSubmitting(true)
        var comment = {
          author: temp[index].author,
          content: temp[index].tempContent,
          datetime: temp[index].datetime,
          avatar: `userInfo/${base64url(temp[index].author)}/avatar.png`,
          type: temp[index].type,
          files: temp[index].files,
          guid: temp[index].guid,
          images: temp[index].images
        }
        let path = `${window.location.origin}`
        axios.post(`/api/comment/update-comment-comment-cp`, { projectId: props.projectId, id: viewPoint.id, comment: comment, baseUrl: path, email: props.email })
          .then(res => {
            getCommentImageUrl(res.data)
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
            message.error(`Can't edit comment`);
          })
      } else {
        temp[index].edit = false
        setComments(temp)
      }
    }
  }

  const handleAddComment = () => {
    let path = `${window.location.origin}`
    if (commentFile.length === 0) {
      if (!valueComment) {
        return;
      }
      var comment = {
        author: props.email,
        content: valueComment,
        datetime: 0,
        avatar: `userInfo/${base64url(props.email)}/avatar.png`,
        type: 'string',
        files: [],
        guid: "",
        images: imageMoreComment,
      }
      setSubmitting(true)
      axios.post(`/api/comment/add-comment-comment-cp`, { projectId: props.projectId, itemId: props.itemId, id: viewPoint.id, comment: comment, baseUrl: path })
        .then(res => {
          getCommentImageUrl(res.data)
          setValueComment('')
          setCommentFile([])
          setImageMoreComment([])
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't add comment`);
        })
    } else {
      var comment = {
        author: props.email,
        content: valueComment,
        datetime: 0,
        avatar: `userInfo/${base64url(props.email)}/avatar.png`,
        type: 'link',
        files: [],
        guid: "",
        images: imageMoreComment,
      }
      let formData = new FormData()
      _.forEach(commentFile, f => {
        formData.append('files', f.file)
      })
      formData.append('id', props.viewPoint.id)
      formData.append('projectId', props.projectId)
      formData.append('itemId', props.itemId)
      formData.append('baseUrl', path)
      formData.append('comment', JSON.stringify(comment))
      if (formData !== undefined || formData !== null) {
        setSubmitting(true)
        axios.post(`/api/comment/add-comment-comment-cp-file`, formData, { timeout: 60 * 10 * 1000 })
          .then(res => {
            getCommentImageUrl(res.data)
            setValueComment('')
            setCommentFile([])
            setImageMoreComment([])
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
            message.error(`Can't add comment`);
          })
      }
    }

  }

  const handleLinkFileComment = ({ file }) => {
    if (file !== null) {
      setSubmitting(true)
      let temp = commentFile.slice()
      let index = _.findIndex(temp, e => { return e.name === file.name })
      if (index < 0) {
        temp.push({ uid: temp.length + 1, name: file.name, url: '', status: 'done', file: file })
      } else {
        temp[index].file = file
      }
      setCommentFile(temp)
      setSubmitting(false)
    }
  }

  const handleRemoveLinkFileComment = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setSubmitting(true)
    let temp = commentFile.slice()
    let index = _.findIndex(temp, e => { return e.name === file.name })
    if (index >= 0)
      temp.splice(index, 1)
    setCommentFile(temp)
    setSubmitting(false)
  }

  const handleCloseEditComment = (guid) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].edit = false
    }
    setComments(temp)
  }

  const handleChangeEditComment = (guid, e) => {
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      temp[index].tempContent = e.target.value
    }
    setComments(temp)
  }
  const handleRemoveCommentFile = (guid, fileName) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      let path = `${window.location.origin}`
      axios.post(`/api/comment/remove-attach-comment-comment-cp`, { projectId: props.projectId, id: viewPoint.id, comment: temp[index], baseUrl: path, email: props.email, fileName: fileName })
        .then(res => {
          getCommentImageUrl(res.data)
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't remove attach file`);
        })
    }
    setComments(temp)
  }
  const handleRemoveComment = (guid) => {
    if (!window.confirm('Are you sure to remove this comment?')) return
    let temp = comments.slice()
    let index = _.findIndex(temp, e => { return e.guid === guid })
    if (index >= 0) {
      let path = `${window.location.origin}`
      axios.post(`/api/comment/delete-comment-comment-cp`, { projectId: props.projectId, id: viewPoint.id, comment: temp[index], baseUrl: path, email: props.email })
        .then(res => {
          if (res.data.length === 0) {
            setComments(res.data)
          } else {
            getCommentImageUrl(res.data)
          }
          setSubmitting(false)
        })
        .catch(() => {
          setSubmitting(false)
          message.error(`Can't delete comment`);
        })
    }
  }
  //#endregion
  const handleDownload = (file) => {
    getPublicUrl(file.url, 1000 * 5)
      .then(res => {
        let link = document.createElement('a');
        link.href = res;
        link.target = "_blank"
        link.click();
      })
  }
  const handleDownloadComment = (url) => {
    getPublicUrl(url, 1000 * 5)
      .then(res => {
        let link = document.createElement('a');
        link.href = res;
        link.target = "_blank"
        link.click();
      })
  }
  const handleModifyStatus = (value) => {
    setStatus(value)
    setDisabled(false)
  }

  const handleModifyPriority = (value) => {
    setPriority(value)
    setDisabled(false)
  }

  const handleRestore = (item) => {
    if (props.viewer.impl.model.getDocumentNode().data.guid !== item.guid)
      props.restoreSaveView(item)
  }

  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleClose} keyboard={false}
        placement='left' size='xs' className='drawerBimAppPanel'>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Drawer.Header>
          <Drawer.Title>
            {!props.isProject && <IconButton size="xs" icon={<Icon icon={'folder-open'} />} onClick={handleRestore.bind(this, props.viewPoint)}
          style={{marginRight:5}}  />}
            Comment

          </Drawer.Title>
        </Drawer.Header>
        <Divider className='custom-divider' />
        <Drawer.Body >


     

        </Drawer.Body>
        <Divider className='custom-divider' />
        <Drawer.Footer style={{ marginBottom: 5 }}>
          <Button appearance="primary" onClick={handleModifyInfoViewPoint} loading={loading} disabled={disabled}>
            Save
      </Button>
          <Button appearance="subtle" onClick={handleClose}>
            Cancel
      </Button>
        </Drawer.Footer>
      </Drawer >

      {/* {!props.isProject &&
        <Menu id='info_issue_menu' style={{ zIndex: 10000 }} >
          <Item onClick={props.restoreSaveView.bind(this)}>
            <IconFont className="fas fa-check" />View Issue
                </Item>
        </Menu>} */}
      <Toaster ref={toaster} position={Position.TOP} canEscapeKeyClear={true} />
    </>

  )
}