import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/app/auth";
import { Result, Button, Typography, Icon } from 'antd';
const { Paragraph, Text } = Typography;

class ResultError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  handleRefresh = () => {
    window.location.reload(true)
  }
  handleReLogin = () => {
    this.props.logout(this.props.user)
  }
  render() {
    return (
      <Result
        status="error"
        title="500"
        subTitle={
          <>
            <p>Sorry, we are not able to connect to server at moment. Please try again later.</p>
            <p>You can contact to our web admin at phan_manhquyet@wohhup.com for feedback or any urgent issue. He is also can reach at number +6596969942.</p>
          </>
        }
        extra={[
          <Button type="primary" key="console" onClick={this.handleRefresh}>Refresh</Button>,
          <Button onClick={this.handleReLogin}>Re-login</Button>,
        ]}
      >

      </Result>

    );
  }
}
ResultError.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  logout: PropTypes.func.isRequired,
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { logout: actions.logout })(ResultError)