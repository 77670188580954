import React, { useState, useEffect } from 'react';
import axios from "axios";
import _ from 'lodash'
import { message, Row, Col, Typography, Tooltip, Collapse } from 'antd';
import { Modal, Divider, Loader, Checkbox, DatePicker } from 'rsuite';
import * as rsuite from 'rsuite';
import moment from 'moment'


const { Text } = Typography
const { Panel } = Collapse;


export default function NotificationSettingPanel(props) {

  const [commentData, setCommentData] = useState([
    {
      name: 'Added',
      instant: false,
      digest: false
    },
    {
      name: 'Updated',
      instant: false,
      digest: false
    },
    {
      name: 'Deleted',
      instant: false,
      digest: false
    },
  ])
  const [issueData, setIssueData] = useState([
    {
      name: 'Added',
      instant: false,
      digest: false
    },
    {
      name: 'Updated',
      instant: false,
      digest: false
    },
    {
      name: 'Deleted',
      instant: false,
      digest: false
    },
    {
      name: 'Attach File',
      instant: false,
      digest: false
    },
    {
      name: 'Remove Attach File',
      instant: false,
      digest: false
    },
  ])
  const [time] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const [dailyTime, setDailyTime] = useState()
  useEffect(() => {
    setLoading(true)
    axios.get("/api/projects/get-setting-notification", { params: { projectId: props.projectId } })
      .then(res => {
        if (Object.keys(res.data.notificationSetting).length !== 0) {
          if (res.data.notificationSetting.issue) {
            let temp = issueData.slice()
            _.forEach(temp, v => {
              let index = _.findIndex(res.data.notificationSetting.issue, e => { return e.name === v.name })
              if (index >= 0) {
                v.instant = res.data.notificationSetting.issue[index].instant
                v.digest = res.data.notificationSetting.issue[index].digest
              }
            })
            setIssueData(temp)
          }
          if (res.data.notificationSetting.issue) {
            let temp = commentData.slice()
            _.forEach(temp, v => {
              let index = _.findIndex(res.data.notificationSetting.comment, e => { return e.name === v.name })
              if (index >= 0) {
                v.instant = res.data.notificationSetting.comment[index].instant
                v.digest = res.data.notificationSetting.comment[index].digest
              }
            })
            setCommentData(temp)
          }
          if (res.data.reportDailyTime !== '') {
            let split = res.data.reportDailyTime.split(" ")
            let time = moment(`${split[1]}:${split[0]}`, 'HH:mm')
            setDailyTime(moment(time, 'HH:mm'))
          } else {
            let time = moment('18:30', 'HH:mm')
            setDailyTime(time)
          }
        }
        setLoading(false)
      })
      .catch(() => {
        props.handleClose(null)
        message.warning(`Can't get setting`)
        setLoading(false)
      })
  }, [])


  const handleClose = () => {
    props.handleClose(null)
  }
  const handleChange = (name, key, index) => {
    if (name === 'Issue') {
      let temp = issueData.slice()
      temp[index][key] = !temp[index][key]
      setIssueData(temp)
    } else if (name === 'Comment') {
      let temp = commentData.slice()
      temp[index][key] = !temp[index][key]
      setCommentData(temp)
    }
  }
  const handleSaveSetting = () => {
    const temp = {
      comment: commentData,
      issue: issueData
    }
    let time = moment(dailyTime).format('HH:mm')
    let split = time.split(':')
    let schedule = `${split[1]} ${split[0]} * * *`
    axios.post(`/api/projects/save-setting-notification`, { projectId: props.projectId, data: temp, dailyTime: schedule })
      .then(() => {
        props.handleClose(null)
      })
      .catch(() => {
        message.error(`Can't save setting`)
      })
  }
  const handleChangeDailyReport = (date) => {
    setDailyTime(date)
  }
  return (
    <Modal show={props.open} onHide={handleClose} size='sm' backdrop='static' className='dialog-share-project'>
      <Modal.Header>
        <Modal.Title>Setting Notification</Modal.Title>
      </Modal.Header>
      <Divider className='custom-divider' />
      <Modal.Body >
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <div style={{ marginBottom: 5 }}>
          <Row align="middle" gutter={8}>
            <Col span={18} >
              <Text strong style={{ paddingLeft: 10 }}> Activity Type</Text>
            </Col>
            <Col span={3}  >
              <Text strong > Instant</Text>
            </Col>
            <Col span={3}  >
              <Text strong > Digest</Text>
            </Col>
          </Row>
        </div>

        <Collapse   >
          <Panel header="Comment" key="1" className='custom-setting-notification-panel'>
            {commentData.map((v, k) =>
              <Row align="middle" gutter={8}>
                <Col span={18} >
                  <Text strong style={{ paddingTop: 7, position: 'absolute' }}> {v.name}</Text>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Instant' placement='top'>
                    <Checkbox checked={v.instant} onChange={handleChange.bind(this, 'Comment', 'instant', k)} />
                  </Tooltip>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Digest' placement='top'>
                    <Checkbox checked={v.digest} onChange={handleChange.bind(this, 'Comment', 'digest', k)} />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Panel>
          <Panel header="Issue" key="2" className='custom-setting-notification-panel'>
            {issueData.map((v, k) =>
              <Row align="middle" gutter={8}>
                <Col span={18} >
                  <Text strong style={{ paddingTop: 7, position: 'absolute' }}> {v.name}</Text>
                </Col>
                <Col span={3}  >
                  <Tooltip title='Instant' placement='top'>
                    <Checkbox checked={v.instant} onChange={handleChange.bind(this, 'Issue', 'instant', k)} />
                  </Tooltip>
                </Col>
                <Col span={3}  >
                  <Tooltip title='	Digest' placement='top'>
                    <Checkbox checked={v.digest} onChange={handleChange.bind(this, 'Issue', 'digest', k)} />
                  </Tooltip>
                </Col>
              </Row>
            )}
          </Panel>
        </Collapse>
        <div style={{ marginTop: 5 }}>
          <Text strong> Daily digest time{' '}</Text>
          <DatePicker format="HH:mm" cleanable={false} value={time} size="sm" ranges={[]} onChange={handleChangeDailyReport} value={dailyTime} />
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Divider className='custom-divider' />
        <rsuite.Button appearance="primary" loading={loading} onClick={handleSaveSetting}> Save</rsuite.Button>
        <rsuite.Button appearance="default" onClick={handleClose} > Cancel</rsuite.Button>
      </Modal.Footer>
    </Modal>
  )
}
