import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { colorType, navBarDmsHeight, offsetHeight, sideBarWidth } from '../../../constants';
import { getUserAndProjectInfoInit } from '../../../utils';
import PageChart from '../pageChart/PageChart';
import ComponentDmsOverall from './ComponentDmsOverall';
import IconSidePanel from './IconSidePanel';
import { apiFetchPublicSettingThisProject } from '../../../api';
import { ConUploadDocumentPage } from '../../subpages/ConUploadDocument';
import { SubUploadDocumentPage } from '../../subpages/subcon/SubUploadDocument';






const ComponentNavigation = (props) => {


   const { projectIsAppliedRfaView, history, localState, pageSheetTypeName, storeInitProject, storeInitUser } = props;

   let { email, role, projectId, projectName, isAdmin } = getUserAndProjectInfoInit(storeInitProject, storeInitUser);

   const infoFromStore = { email, role, projectId, projectName, isAdmin };

   const { token } = storeInitUser;


   const [uploadTypeArray, setUploadTypeArray] = useState([]);


   useEffect(() => {
      const fetchProjectSetting = async () => {
         try {
            let res = await apiFetchPublicSettingThisProject({ token, projectId });

            const projectSetting = res.data[0];

            const btnAuthoritiesArray = (projectSetting.authoritiesArray || []).sort();

            setUploadTypeArray(btnAuthoritiesArray);

         } catch (err) {
            console.log(err);
         };
      };
      fetchProjectSetting();
   }, []);




   const switchToOtherPage = async (buttonPanelName) => {

      const routeSuffix = buttonPanelName.slice(5, buttonPanelName.length);

      if (routeSuffix === 'dms') {
         history.push({
            pathname: '/dms/spreadsheet',
            state: localState
         });
      } else if (
         routeSuffix === 'sum' || routeSuffix === 'rfa' || routeSuffix === 'rfam' ||
         routeSuffix === 'rfi' || routeSuffix === 'cvi' || routeSuffix === 'dt' || routeSuffix === 'mm' ||
         routeSuffix === 'eri' || routeSuffix === 'lr' ||   routeSuffix === 'sm' ||
         routeSuffix === 'qr' || routeSuffix === 'qrm' || routeSuffix === 'rfc' || routeSuffix === 'cons' || routeSuffix === 'subco'
      ) {

         history.push({
            pathname: `/dms/${routeSuffix}`,
            state: localState
         });
      } else {


         history.push({
            pathname: `/dms/authorities-submission`,
            state: {
               ...localState,
               authoritiesType: uploadTypeArray.find(x => `side-${getUploadNameShort(x)}` === buttonPanelName)
            },
         });
      };
   };



   const [currentWindow, setCurrentWindow] = useState({ width: window.innerWidth, height: window.innerHeight });
   useEffect(() => {
      const handleResize = () => {
         setCurrentWindow({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   });


   const authoritiesType = (pageSheetTypeName === 'page-authorities-submission') && (localState.authoritiesType || uploadTypeArray[0]);



   const arrayAllButton = ['side-dms', 'side-sum', 'side-rfa', 'side-rfam', 'side-rfi', 'side-cvi', 'side-dt', 'side-qr', 'side-qrm', 'side-mm',  'side-eri', 'side-lr', 'side-sm', 'side-cons','side-subco', 'side-rfc', ...uploadTypeArray.map(item => `side-${getUploadNameShort(item)}`)];

   const btnArray = (role !== 'Consultant' && role !== 'Client')
      ? arrayAllButton
      : arrayAllButton.filter(x => x !== 'side-dms')


   return (!authoritiesType && uploadTypeArray.length === 0 && pageSheetTypeName === 'page-authorities-submission') ? (
      <div></div>
   ) : (
      <div style={{ display: 'flex' }}>
         {projectIsAppliedRfaView && (
            <div style={{
               position: 'absolute',
               marginTop: navBarDmsHeight,
               width: sideBarWidth,
               height: currentWindow.height - offsetHeight,
               zIndex: 99,
               background: colorType.primary,
               borderRight: '1px solid #DCDCDC'
            }}>
               {btnArray.map((btnType, i) => {

                  const refType = pageSheetTypeName.slice(5, pageSheetTypeName.length);

                  return (
                     <IconSidePanel
                        key={i}
                        totalBtnCount={btnArray.length}
                        btnIndex={i}
                        type={btnType}
                        typeForUploadBtn={uploadTypeArray.find(x => `side-${getUploadNameShort(x)}` === btnType)}
                        onClick={() => switchToOtherPage(btnType)}
                        isLocked={
                           refType === btnType.slice(5, btnType.length) ||
                           (refType === 'spreadsheet' && btnType.slice(5, btnType.length) === 'dms') ||
                           (pageSheetTypeName === 'page-authorities-submission' && btnType.slice(5, btnType.length) === getUploadNameShort(authoritiesType))
                        }
                     />
                  )
               })}
            </div>
         )}

         {pageSheetTypeName === 'page-sum' ? (
            <PageChart
               {...props}
               infoFromStore={infoFromStore}
               currentWindow={currentWindow}
            />
         ) :
            pageSheetTypeName === 'page-cons' ?
               <ConUploadDocumentPage
                  {...props}
                  infoFromStore={infoFromStore}
                  currentWindow={currentWindow}
                  uploadTypeArray={uploadTypeArray}
               />
               :    pageSheetTypeName === 'page-subco' ?
               <SubUploadDocumentPage
                  {...props}
                  infoFromStore={infoFromStore}
                  currentWindow={currentWindow}
                  uploadTypeArray={uploadTypeArray}
               />
               :
               <ComponentDmsOverall
                  {...props}
                  infoFromStore={infoFromStore}
                  currentWindow={currentWindow}
                  uploadTypeArray={uploadTypeArray}
               />
         }


      </div>
   );
};


const mapStateToProps = (state) => {
   return {
      storeInitProject: state.project,
      storeInitUser: state.user,
   };
};


export default connect(mapStateToProps)(ComponentNavigation);



const getUploadNameShort = (authoritiesType) => {

   if (!authoritiesType) return;

   const parts = authoritiesType.split(' ');
   if (parts.length === 1) {
      return authoritiesType.slice(0, 4);
   } else if (parts.length === 2) {
      const part1 = parts[0];
      const part2 = parts[1];
      return part1.slice(0, 2) + part2.slice(0, 2);
   } else if (parts.length >= 3) {
      const part1 = parts[0];
      const part2 = parts[1];
      const part3 = parts[2];
      return part1.slice(0, 2) + part2.slice(0, 1) + part3.slice(0, 1);
   };
};