import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TableIssue,TableComment} from '../../module_BimApp/tables'

import _ from 'lodash'
import axios from "axios";
import { ColumnIssue,ColumnComment } from '../../module_BimApp/columns'
import { Scrollbars } from 'react-custom-scrollbars';
import { ButtonGroup, Icon, IconButton, Loader } from 'rsuite'
import { Tooltip, message, Typography, Button } from 'antd'
import { Menu, Item, Separator, IconFont } from 'react-contexify';
import base64url from 'base64url'
import { socket,checkWohhupUser } from '../../../components/module_BimApp/function/AdminFunction'
import { sortIssue } from '../../../components/module_BimApp/function/PageFunction'

import IssueAppViewer from './IssueAppViewer'
import CommentAppViewer from './CommentAppViewer'
import { IssueInformation, DialogCommentInformation } from '../../module_BimApp/dialog'

const { Text } = Typography

const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
const renderView1 = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 0,
  };
  return (
    <div
      className="box"
      style={{ ...style, ...viewStyle }}
      {...props} />
  );
}
class IssuePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      listSaveIssue: [],
      allListSaveIssue: [],
      loadingIssue: false,

      isAssignToMe: false,

      isIssue: true,
      openIssueViewer: false,
      issue: null,
      openCommentViewer: false,
      showStatus: 'All',

      displayInfoComment: false,
      displayInfoIssue: false,
      trigger: false,
      saveIssue: null,
      triggerCancel: false,
      checkItemExisting: false,
      tagsOption: [],
      isGroupTag: false,
      companyType: ''
    }
  }
  componentWillUnmount = () => {
    socket.removeListener('realtime-issue', this.handleRealtimeIssue)
    socket.removeListener('realtime-delete-issue', this.handleRealtimeDeleteIssue)
    socket.removeListener('realtime-comment', this.handleNewComment)
  }
  componentWillMount = () => {
    socket.on('realtime-issue', this.handleRealtimeIssue)
    socket.on('realtime-delete-issue', this.handleRealtimeDeleteIssue)
    socket.on('realtime-comment', this.handleNewComment)
  }
  componentDidMount = () => {
    axios.post('/api/users/get-me-information', { token: this.props.userInfo.token })
      .then(async res => {
        if (res.data.user.email !== this.props.userInfo.email) return
        let company = null
        let companyType = null
        if (res.data.userInfo.contractors.length !== 0) {
          let index = _.findIndex(res.data.userInfo.contractors, v => { return v.projectId === this.props.location.state.projectId })
          if (res.data.user.email !== 'admin@wohhup.com') {
            company = res.data.userInfo.contractors[index].company
            companyType = res.data.userInfo.contractors[index].role ? res.data.userInfo.contractors[index].role : ''
          }
          // let detailCompanies = await axios.post(`/api/projects/get-information-project`, {
          //   projectId: this.props.location.state.projectId,
          //   token: this.props.userInfo.token
          // })
          // let index1 = _.findIndex(detailCompanies.data.listContractor, v => { return v.company === company })
          // if (index1 >= 0) {
          //   companyType = detailCompanies.data.listContractor[index1].companyType
          // }
        }
        this.setState({ companyType }, () => {
          this.handleLoadIssue(true)
        })

      })
      .catch(err => {
        console.log(err)
      })

  }
  handleRealtimeIssue = (data) => {
    try {
      if (this.state.isIssue) {
        if (data.item.issue.userModified === this.props.userInfo.email) return
        let check = false
        data.item.issue.shareWith = data.item.issue.shareWith ? (_.isArray(data.item.issue.shareWith) ? data.item.issue.shareWith : ['Wohhup']) : ['Wohhup']
        if (this.props.userInfo.email === 'admin@wohhup.com') {
          check = true
        } else if (data.item.issue.listAssignViewPoint.includes(this.props.userInfo.email)) {
          check = true
        } else if ( checkWohhupUser(this.props.userInfo.email)) {
          if (data.item.issue.shareWith.includes('Wohhup'))
            check = true
        } else if (data.item.issue.shareWith.includes(this.state.companyType)) {
          check = true
        }
        let tempAllListSaveView = this.state.allListSaveIssue.slice(0)
        let tempListSaveView = this.state.listSaveIssue.slice(0)
        let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.issue.id })
        if (index >= 0) {
          if (check)
            tempAllListSaveView.splice(index, 1, data.item.issue)
          else
            tempAllListSaveView.splice(index, 1)
        } else {
          tempAllListSaveView.push(data.item.issue)
        }
        index = _.findIndex(tempListSaveView, e => { return e.id === data.item.issue.id })
        if (index >= 0) {
          if (check)
            tempListSaveView.splice(index, 1, data.item.issue)
          else
            tempListSaveView.splice(index, 1)
        } else {
          tempListSaveView.push(data.item.issue)
        }
        // data.item.issue.unReadComment = data.item.issue.comments.length === 0 ? false : true
        data.item.issue.unReadIssue = true
        data.item.issue.tags = data.item.issue.tags ? data.item.issue.tags : []
        this.setState({
          listSaveIssue: tempListSaveView.sort(sortIssue),
          allListSaveIssue: tempAllListSaveView.sort(sortIssue),
          tagsOption: _.uniq(this.state.tagsOption.concat(data.item.issue.tags ? data.item.issue.tags : []))
        })
      }
    } catch {
      console.log('realtime-issue-err')
    }
  }
  handleRealtimeDeleteIssue = (data) => {
    try {
      if (this.state.isIssue) {
        let tempAllListSaveView = this.state.allListSaveIssue.slice(0)
        let tempListSaveView = this.state.listSaveIssue.slice(0)
        let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.issue.id })
        if (index >= 0) {
          tempAllListSaveView.splice(index, 1)
        }
        index = _.findIndex(tempListSaveView, e => { return e.id === data.item.issue.id })
        if (index >= 0) {
          tempListSaveView.splice(index, 1)
        }
        this.setState({
          listSaveIssue: tempListSaveView,
          allListSaveIssue: tempAllListSaveView,
        })
      }
    } catch {
      console.log('realtime-delete-issue-err')
    }
  }

  handleNewComment = (data) => {
    if (data.user === this.props.userInfo.email) return
    let tempAllListSaveView = this.state.allListSaveIssue.slice(0)
    let tempListSaveView = this.state.listSaveIssue.slice(0)
    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempAllListSaveView[index].unReadComment = true
      tempAllListSaveView[index].comments = data.item
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempListSaveView[index].unReadComment = true
      tempListSaveView[index].comments = data.item
    }
    this.setState({
      listSaveIssue: tempListSaveView,
      allListSaveIssue: tempAllListSaveView,
    })
  }
  handleToggleIssue = (checked) => {
    if (checked) {
      this.handleLoadIssue(checked)
    } else {
      this.handleLoadComment(checked)
    }
  }
  handleAssignToMe = () => {
    this.setState({ isAssignToMe: !this.state.isAssignToMe, isGroupTag: false }, () => {
      if (this.state.isAssignToMe) {
        axios.get(`/api/projects/members?q=${this.props.location.state.projectId}`)
          .then(res => {
            let temp = [this.props.userInfo.email]
            let tempListSaveView = []
            let tempList = this.state.allListSaveIssue.slice(0)
            _.forEach(res.data.groupUser, v => {
              _.forEach(v.members, member => {
                if (member.name === this.props.userInfo.email) {
                  temp.push(v.name)
                }
              })
            })
            _.forEach(tempList, v => {
              _.forEach(v.listAssignViewPoint, user => {
                if (temp.includes(user)) {
                  tempListSaveView.push(v)
                }
              })
            })
            this.setState({ listSaveIssue: tempListSaveView })
          })
          .catch(() => {
            this.setState({ isAssignToMe: false })
          })
      } else {
        this.setState({ listSaveIssue: this.state.allListSaveIssue })
      }
    })
  }
  handleGroupTag = () => {
    this.setState({ isGroupTag: !this.state.isGroupTag, isAssignToMe: false }, () => {
      if (this.state.isGroupTag) {
        let tempListSaveView = []
        let tempList = this.state.allListSaveIssue.slice(0)
        _.forEach(tempList, v => {
          if (v.tags.length !== 0) {
            _.forEach(v.tags, tag => {
              let clone = JSON.parse(JSON.stringify(v));
              clone.tags = [tag]
              tempListSaveView.push(clone)
            })
          } else {
            tempListSaveView.push(v)
          }
        })

        this.setState({ listSaveIssue: tempListSaveView })
      } else {
        this.setState({ listSaveIssue: this.state.allListSaveIssue })
      }
    })
  }
  //#region issue
  handleLoadIssue = (checked) => {
    this.setState({ loadingIssue: true })
    axios.get("/api/issue/get-issue-unread", { params: { email: this.props.userInfo.email } })
      .then(res => {
        let unReadData = res.data
        axios.get("/api/issue/get-project-issues", { params: { projectId: this.props.location.state.projectId } })
          .then(res => {
            if (res.data.length !== 0) {
              let temp = []
              let tagsOption = []
              _.forEach(res.data, v => {
                if (v.delete) return
                let data = v.issue
                let index = _.findIndex(unReadData, e => { return e.id === data.id })
                if (index >= 0) {
                  data.unReadIssue = !unReadData[index].issueTime ? true : data.dateModified > unReadData[index].issueTime
                  data.unReadComment = !unReadData[index].commentTime ? (data.comments.length === 0 ? false : true) :
                    (data.comments.length === 0 ? false : data.comments[data.comments.length - 1].datetime > unReadData[index].commentTime)
                } else {
                  data.unReadIssue = true
                  data.unReadComment = data.comments.length === 0 ? false : true
                }
                data.shareWith = data.shareWith ? (_.isArray(data.shareWith) ? data.shareWith : ['Wohhup']) : ['Wohhup']
                data.tags = data.tags ? data.tags : []
                if (this.props.userInfo.email === 'admin@wohhup.com') {
                  temp.push(data)
                } else if (data.listAssignViewPoint.includes(this.props.userInfo.email)) {
                  temp.push(data)
                } else if (checkWohhupUser(this.props.userInfo.email)) {
                  if (data.shareWith.includes('Wohhup'))
                    temp.push(data)
                } else if (data.shareWith.includes(this.state.companyType)) {
                  temp.push(data)
                }
                tagsOption = tagsOption.concat(data.tags ? data.tags : [])
              })
              this.setState({
                listSaveIssue: temp,
                allListSaveIssue: temp,
                loadingIssue: false,
                isIssue: checked,
                isAssignToMe: false,
                showStatus: 'All',
                displayInfoIssue: false,
                displayInfoComment: false,
                tagsOption: _.uniq(tagsOption)
              })
            } else {
              this.setState({
                listSaveIssue: [],
                allListSaveIssue: [],
                loadingIssue: false,
                isIssue: checked,
                isAssignToMe: false,
                showStatus: 'All',
                displayInfoIssue: false,
                displayInfoComment: false
              })
            }
          })
          .catch(() => {
            this.setState({
              listSaveIssue: [],
              allListSaveIssue: [],
              loadingIssue: false,
              isIssue: checked,
              isAssignToMe: false,
              showStatus: 'All',
              displayInfoIssue: false,
              displayInfoComment: false
            })
          })
      })

  }
  handleOpenIssueViewer = (row) => {
    this.setState({ openIssueViewer: true, issue: this.state.saveIssue, displayInfoIssue: false, trigger: false, })
  }
  handleCloseIssueViewer = (row) => {
    this.setState({ openIssueViewer: false, issue: null })
  }
  handleOpenIssue = (check, e) => {
    axios.post('/api/issue/check-item', { token: this.props.userInfo.token, itemId: e.props.item.mainModel.itemId })
      .then(res => {
        if (res.data) {
          let path = base64url(`${this.props.location.state.projectId}/${this.props.location.state.userCreatedProject}/${e.props.item.id}/${check}`)
          window.open(`${window.location.origin}/issue/${path}`)
        } else {
          message.warning(`Model was deleted`)
        }
      })
      .catch(err => {
        message.warning(`Can't open issue`)
      })

  }
  handleDeleteViewPoint = (e) => {
    if (!window.confirm('Are you sure to delete this view?')) return
    this.setState({ loadingTableViewPoint: true })
    let temp1 = []
    let tempData = e.props.item.id
    _.forEach(this.state.listSaveIssue, (v) => {
      if (v.id !== e.props.item.id) {
        temp1.push(v)
      }
    })
    let temp2 = []
    _.forEach(this.state.allListSaveIssue, (v) => {
      if (v.id !== e.props.item.id) {
        temp2.push(v)
      }
    })

    axios.post(`/api/issue/delete-issue`, { label: tempData, email: this.props.userInfo.email })
      .then(() => {
        this.setState({
          listSaveIssue: temp1, allListSaveIssue: temp2,
          loadingTableViewPoint: false
        })
        message.success(`Deleted viewpoint.`);
      })
      .catch(() => {
        this.setState({ loadingTableViewPoint: false })
        message.error(`Can't delete viewpoint.`);
      })

  }
  handleInfoViewPoint = (value) => {
    if (value.original) {
      if (this.state.saveIssue === null || value.original.id !== this.state.saveIssue.id) {
        axios.post('/api/issue/check-item', { token: this.props.userInfo.token, itemId: value.original.mainModel.itemId })
          .then(res => {
            this.setState({ displayInfoIssue: true, saveIssue: null }, () => {
              this.setState({ saveIssue: value.original, checkItemExisting: res.data })
            })
          })

      }
    }


  }
  handleCloseInfoViewPointPanel = (data) => {
    if (data) {
      this.setState({ displayInfoIssue: false, saveIssue: null, trigger: false, loadingIssue: false, listSaveIssue: data, triggerCancel: false })
    }
    else {
      this.setState({ displayInfoIssue: false, saveIssue: null, trigger: false, loadingIssue: false, triggerCancel: false })
    }

  }
  handleTriggerCloseIssue = () => {
    this.setState({ triggerCancel: true })
  }
  handleSaveIssue = () => {
    this.setState({ trigger: true, loadingIssue: true })
  }
  //#endregion

  //#region comment
  handleLoadComment = (checked) => {
    this.setState({ loadingIssue: true })
    axios.get("/api/comment/get-project-comments", { params: { projectId: this.props.location.state.projectId } })
      .then(res => {
        if (res.data.length !== 0) {
          let temp = []
          _.forEach(res.data, v => {
            let data = v.comment
            temp.push(data)
          })
          this.setState({
            listSaveComment: temp,
            allListSaveComment: temp,
            loadingIssue: false,
            isIssue: checked,
            isAssignToMe: false,
            showStatus: 'All',
            displayInfoIssue: false,
            displayInfoComment: false
          })
        } else {
          this.setState({
            listSaveComment: [],
            allListSaveComment: [],
            loadingIssue: false,
            isIssue: checked,
            isAssignToMe: false,
            showStatus: 'All',
            displayInfoIssue: false,
            displayInfoComment: false
          })
        }
      })
      .catch(() => {
        this.setState({
          listSaveComment: [],
          allListSaveComment: [],
          loadingIssue: false,
          isIssue: checked,
          isAssignToMe: false,
          showStatus: 'All',
          displayInfoIssue: false,
          displayInfoComment: false
        })
      })
  }
  handleOpenCommentViewer = (row) => {
    this.setState({ openCommentViewer: true, issue: row.original })
  }
  handleCloseCommentViewer = (row) => {
    this.setState({ openCommentViewer: false })
  }
  handleOpenComment = (check, e) => {
    let path = base64url(`${this.props.location.state.projectId}/${this.props.location.state.userCreatedProject}/${e.props.item.id}/${check}`)
    window.open(`${window.location.origin}/comment/${path}`)
  }
  handleSearch = (value) => {
    if (value === '') {
      this.setState({ listSaveComment: this.state.allListSaveComment })
    } else {
      let temp = []
      _.forEach(this.state.allListSaveComment, v => {
        if (v.descriptionViewPoint.includes(value)) {
          temp.push(v)
        }
      })
      this.setState({ listSaveComment: temp })
    }
  }
  handleShowStatus = () => {
    this.setState({ showStatus: this.state.showStatus !== 'All' ? this.state.showStatus !== 'Close' ? 'Close' : 'All' : 'Open' }, () => {
      this.handleFilterComment()
    })
  }
  handleInfoComment = (value) => {
    this.setState({
      displayInfoComment: true, saveComment: value
    })
  }
  handleCloseInfoComment = () => {
    this.setState({ displayInfoComment: false })
  }

  //#endregion


  render() {
    return (
      <>
        <div id='zoom-issue-image' />
        {this.state.loadingIssue && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        {this.state.openIssueViewer &&
          <IssueAppViewer
            close={this.handleCloseIssueViewer}
            issue={this.state.issue}
            projectId={this.props.location.state.projectId}
            userCreatedProject={this.props.location.state.userCreatedProject}
            check={true}
            listSaveView={this.state.listSaveIssue}
          />
        }
        {this.state.openCommentViewer &&
          <CommentAppViewer
            close={this.handleCloseCommentViewer}
            issue={this.state.issue}
            projectId={this.props.location.state.projectId}
            userCreatedProject={this.props.location.state.userCreatedProject}
            check={false}
            listSaveView={this.state.listSaveComment}
          />
        }
        <div style={{ position: 'absolute', height: 'calc(100% - 53.78px)', width: `calc(100% - ${(this.state.displayInfoIssue || this.state.displayInfoComment) ? '400px' : '0px'})` }} >
          <Scrollbars
            renderView={renderView}
            autoHide autoHideTimeout={1000}
            autoHideDuration={200}
            thumbMinSize={30}
            universal={true}>
            {/* <ButtonGroup size="md" style={{ position: 'absolute', float: 'right', right: '50%' }}>
              <Tooltip title={'Show Comments'}>
                <IconButton icon={<Icon icon="comment" />} color={!this.state.isIssue && 'blue'} onClick={this.handleToggleIssue.bind(this, false)}
                />
              </Tooltip>
              <Tooltip title={'Show Issues'}>
                <IconButton icon={<Icon icon="remind" />} color={this.state.isIssue && 'blue'} onClick={this.handleToggleIssue.bind(this, true)}
                />
              </Tooltip>
            </ButtonGroup> */}
            {this.state.isIssue ?
              <>
                <TableIssue
                  columns={ColumnIssue(null, true)} data={this.state.listSaveIssue}
                  handleAssignToMe={this.handleAssignToMe}
                  isAssignToMe={this.state.isAssignToMe}
                  isProject={true}
                  isClick={true}
                  callbackClick={this.handleInfoViewPoint}
                  isCoordinationApp={true}
                  typeTable={'issue'}
                  isSearchGlobal={true}
                  handleGroupTag={this.handleGroupTag}
                  isGroupTag={this.state.isGroupTag}
                />
              </>
              :
              <>
                <TableComment
                  columns={ColumnComment()} data={this.state.listSaveComment}
                  handleInfoViewPoint={this.handleInfoComment}
                  handleAssignToMe={this.handleAssignToMe}
                  isAssignToMe={this.state.isAssignToMe}
                  isProject={true}


                  showStatus={this.state.showStatus}
                  handleSearch={this.handleSearch}
                  handleShowStatus={this.handleShowStatus}
                  isDoubleClick={true}
                  callbackDoubleClick={this.handleOpenCommentViewer}

                />
                {this.state.displayInfoComment &&
                  <DialogCommentInformation
                    open={this.state.displayInfoComment}
                    handleClose={this.handleCloseInfoComment}

                    viewPoint={this.state.saveComment}
                    email={this.props.userInfo.email}
                    projectId={this.props.location.state.projectId}
                    userCreatedProject={this.props.location.state.userCreatedProject}
                    listSaveView={this.state.listSaveComment}
                    // handleAttachFile={this.handleAttachFile
                    isProject={true}
                    newTab={false}

                  />
                }

              </>

            }
          </Scrollbars>
        </div>
        {(this.state.displayInfoIssue || this.state.displayInfoComment) &&
          <div style={{ position: 'absolute', height: 'calc(100% - 53.78px)', width: '400px', right: 0, borderLeft: '1px solid gray', padding: 5 }} >
            {(this.state.saveIssue) &&
              <div style={{ height: 'calc(100% - 80px)' }}>
                <div style={{ borderBottom: '1px solid gray', paddingBottom: 5, marginBottom: 5 }}>
                  <Icon icon='exclamation-triangle' style={{
                    color: this.state.saveIssue.priority === 'Very urgent' ? 'red' :
                      this.state.saveIssue.priority === 'Urgent' ? 'orange' : this.state.saveIssue.priority === 'Normal' ? '#f4d90b' : 'blue'
                  }} />
                  <Tooltip title={this.state.saveIssue.id} placement="right" style={{ zIndex: 120000 }}>
                    <Text strong>{' '}{this.state.saveIssue.id}</Text>
                  </Tooltip>
                  <Tooltip title={this.state.checkItemExisting ? '' : 'The model used for this issue was deleted. You cannot view that model, however you still can comment on this issue.'} placement="left" style={{ zIndex: 120000 }}>
                    <button style={{ float: 'right', borderRadius: 5, backgroundColor: this.state.checkItemExisting ? 'cornflowerblue' : 'red', color: 'white' }}
                      onClick={this.handleOpenIssueViewer} disabled={!this.state.checkItemExisting} >
                      Open
                    </button>
                  </Tooltip>

                </div>
                <Scrollbars
                  renderView={renderView1}
                  autoHide autoHideTimeout={1000}
                  autoHideDuration={200}
                  thumbMinSize={30}
                  universal={true}>
                  <IssueInformation
                    open={this.state.displayInfoIssue}
                    handleClose={this.handleCloseInfoViewPointPanel}
                    viewPoint={this.state.saveIssue}
                    email={this.props.userInfo.email}
                    projectId={this.props.location.state.projectId}
                    userCreatedProject={this.props.location.state.userCreatedProject}
                    listSaveView={this.state.listSaveIssue}
                    isProject={true}
                    newTab={false}
                    handleOpenIssue={this.handleOpenIssue}
                    setDisabled={() => { }}
                    trigger={this.state.trigger}
                    isCoordinationApp={true}
                    triggerCancel={this.state.triggerCancel}
                    tagsOption={this.state.tagsOption}
                  />
                </Scrollbars>
                <div style={{ borderTop: '1px solid gray', paddingTop: 5, marginTop: 5 }}>
                  <Button type='primary' onClick={this.handleSaveIssue} >
                    Save
                  </Button>
                  <Button onClick={this.handleTriggerCloseIssue.bind(this, null)}>
                    Cancel
                  </Button>
                </div>
              </div>}

          </div>}
        <Menu id='issue_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handleOpenIssue.bind(this, true)}>
            <IconFont className="fas fa-check" />View Issue
          </Item>
          <Item onClick={this.handleOpenIssue.bind(this, false)}>
            <IconFont className="fas fa-check-double" />View Issue With Original Version
          </Item>
          <Separator />
          {this.props.userInfo.role === 'admin' &&
            <Item onClick={this.handleDeleteViewPoint}>
              <IconFont className="fas fa-trash" />Delete
            </Item>
          }
        </Menu>
        <Menu id='comment_menu' style={{ zIndex: 10000 }}>
          <Item onClick={this.handleOpenComment.bind(this, true)}>
            <IconFont className="fas fa-check" />View Comment
          </Item>
          <Item onClick={this.handleOpenComment.bind(this, false)}>
            <IconFont className="fas fa-check-double" />View Comment With Original Version
          </Item>
        </Menu>



      </>
    )
  }

}

IssuePage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === "admin@wohhup.com",
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
  }
}
export default connect(mapStateToProps)(IssuePage)