import React, { useEffect, useState, PureComponent } from "react";
import axios from "axios";
import { Card, Row, Col, Table } from "antd";
import {
   BarChart,
   Bar,
   Cell,
   LabelList,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer,
} from "recharts";

import dayjs from "dayjs";
import _, { method } from "lodash";
import Label from "recharts/lib/component/Label";

const Dashboard = (current) => {
   const initial = [{ sum_by_supplier: "" }];
   const [dataC, setDataC] = useState(initial);
   const [dataConcAmt, setDataConcAmt] = useState();
   const [dataConcVol, setDataConcVol] = useState();
   const [qttByProduct, setqttByProduct] = useState();

   const [dataR, setDataR] = useState(initial);
   const [dataRebarAmt, setDataRebarAmt] = useState();
   const [dataRebarWeight, setDataRebarWeight] = useState();

   //  useEffect(() => {
   //     const fetchdata = async () => {
   //        try {
   //           const token =
   //              "eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzE4NDk4MTQsInZlbmRvcl9jb21wYW55X3VpZCI6IndvaGh1cCIsInVpZCI6IjY3YTM0YjU0LWM0MDYtNDkxYi1iNWI2LWIxZGM0YTIzOTIxOSIsImV4cCI6MTYzMTg0OTgxNH0.N6mSTk8WrfApCxjW-BE0AD8lUUjhf2pBh6U9h5P9sR8";
   //           const result_1stPage = await axios({
   //              method: "get",
   //              url: "https://platform.hubble.sg/api/vendors/attendances",
   //              params: {
   //                 start_date: "2021-09-01",
   //                 end_date: "2021-09-02",
   //                 page: 1,
   //              },
   //              headers: { Authorization: `Bearer ${token}` },
   //           });
   //           console.log(result_1stPage);
   //           const totalPages = result_1stPage.data.total_pages;
   //           let attendance_data = [];
   //           attendance_data = attendance_data.concat(
   //              result_1stPage.data.attendances
   //           );
   //           console.log("Nos of pages:", totalPages);
   //           const RunningPage = Array.from({ length: 5 }, (_, i) => i + 1);

   //           RunningPage.map(async (i) => {
   //              return new Promise(() => {
   //                 axios({
   //                    method: "get",
   //                    url: "https://platform.hubble.sg/api/vendors/attendances",
   //                    params: {
   //                       // project_ids:
   //                       //    "f2c1773d-8d38-4640-8a76-6852c80af5c2,cb2d5f06-e31a-4543-b00e-9d4d072fcae8",
   //                       start_date: "2021-09-14",
   //                       end_date: "2021-09-14",
   //                       page: i,
   //                    },
   //                    headers: { Authorization: `Bearer ${token}` },
   //                 }).then((res) => {
   //                    if (res.data.attendances !== undefined) {
   //                       attendance_data = attendance_data.concat(
   //                          res.data.attendances
   //                       );
   //                       if (i === 5) {
   //                          console.log("worker data:", attendance_data);
   //                          const projectlist = Array.from(
   //                             new Set(attendance_data.map((s) => s.project.id))
   //                          ).map((id) => {
   //                             return {
   //                                id: id,
   //                                code: attendance_data.find(
   //                                   (s) => s.project.id === id
   //                                ).project.code,
   //                                p_name: attendance_data.find(
   //                                   (s) => s.project.id === id
   //                                ).project.name,
   //                             };
   //                          });

   //                          console.log("Project List:", projectlist);
   //                       }
   //                    }
   //                 });
   //              });
   //           });
   //           let res = await Promise.all();
   //        } catch (error) {
   //           console.log(error);
   //        }
   //     };

   //     fetchdata();
   //  }, [current]);

   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            const res = await axios.get("/api/do_inv/dashboard/conc", {
               params: { project_id: project_id },
            });
            setDataC(res.data.data);
            //get conc vol/ amt
            let temp_d1 = res.data.data.sum_qtt_by_month;
            var r1 = getDataForBarChartVol(temp_d1);
            setDataConcVol(r1);

            let temp_d2 = res.data.data.sum_amt_by_month;
            var r2 = getDataForBarChartAmt(temp_d2);
            setDataConcAmt(r2);

            //get info about product
            let qtt_p_table_data = [];
            let qtt_p_vol = res.data.data.sum_vol_by_product;
            let qtt_p_amt = res.data.data.sum_amt_by_product;
            let qtt_pm = res.data.data.sum_by_product_this_month;
            qtt_p_vol.forEach((e) => {
               e.totalVol = e.sum.toLocaleString("en-US", {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
                  useGrouping: true,
               });

               e.totalAmt = 0.0;
               const foundamt = qtt_p_amt.find((f) => f.product.toUpperCase() === e.product.toUpperCase());
               if (foundamt) {
                  e.totalAmt = foundamt.sum.toLocaleString("en-US", {
                     maximumFractionDigits: 1,
                     minimumFractionDigits: 1,
                     useGrouping: true,
                  });
               }

               e.qttCurrentMonth = 0.0;
               const found = qtt_pm.find((f) => f.product.toUpperCase() === e.product.toUpperCase());
               if (found) {
                  e.qttCurrentMonth = found.sum.toLocaleString("en-US", {
                     maximumFractionDigits: 1,
                     minimumFractionDigits: 1,
                     useGrouping: true,
                  });
               }

               qtt_p_table_data.push(e);
            });

            setqttByProduct(qtt_p_table_data);
         };

         fetchdata();
      }
   }, [current]);

   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            const res = await axios.get("/api/do_inv/dashboard/rebar", {
               params: { project_id: project_id },
            });
            setDataR(res.data.data);

            //Need to update this
            let temp_d1 = res.data.data.sum_qtt_by_month;

            var r1 = getDataForBarChartVol(temp_d1);
            setDataRebarWeight(r1);

            let temp_d2 = res.data.data.sum_amt_by_month;
            var r2 = getDataForBarChartAmt(temp_d2);
            setDataRebarAmt(r2);
         };

         fetchdata();
      }
   }, [current]);

   const getDataForBarChartVol = (temp_d) => {
      temp_d.forEach((e) => {
         e.YYYYMM = dayjs(e.do_date).format("YYYYMM");
         e.MMYY = dayjs(e.do_date).format("MM/YY");
      });

      var groups = _.groupBy(temp_d, "YYYYMM");
      groups = _.sortBy(groups, "YYYYMM");
      let list_conc_vol_by_month = [];
      _.map(groups, (value, key) => {
         let t = new Object();
         t.name = value[0].MMYY;
         const list_per = temp_d.filter((e) => e.use_for === "Permanent work" && e.MMYY === value[0].MMYY);
         t.permanent_work = list_per.reduce((total, o) => (total = total + parseFloat(o.sum)), 0);

         const list_temp = temp_d.filter((e) => e.use_for === "Temporary work" && e.MMYY === value[0].MMYY);
         t.temporary_work = list_temp.reduce((total, o) => (total = total + parseFloat(o.sum)), 0);

         const list_bc = temp_d.filter((e) => e.use_for === "Back charge" && e.MMYY === value[0].MMYY);
         t.back_charge = list_bc.reduce((total, o) => (total = total + parseFloat(o.sum)), 0);

         const list_na = temp_d.filter(
            (e) =>
               e.use_for !== "Permanent work" &&
               e.use_for !== "Temporary work" &&
               e.use_for !== "Back charge" &&
               e.MMYY === value[0].MMYY
         );
         t.no_info = list_na.reduce((total, o) => (total = total + parseFloat(o.sum)), 0);

         list_conc_vol_by_month.push(t);
      });
      return list_conc_vol_by_month;
   };

   const getDataForBarChartAmt = (temp_d) => {
      temp_d.forEach((e) => {
         e.YYYYMM = dayjs(e.inv_date).format("YYYYMM");
         e.MMYY = dayjs(e.inv_date).format("MM/YY");
      });
      var groups = _.groupBy(temp_d, "YYYYMM");
      groups = _.sortBy(groups, "YYYYMM");
      var r = _.map(groups, (value, key) => {
         return {
            name: value[0].MMYY,
            amount: _.reduce(
               value,
               (total, o) => {
                  return total + o.sum;
               },
               0
            ),
         };
      });

      const currentmonth = dayjs().format("MM/YY");
      const found = r.find((e) => e.name === currentmonth);
      if (!found) {
         const dummyMonth = { name: currentmonth, amount: 0 };
         r.push(dummyMonth);
      }
      return r;
   };

   const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
      let formatValue = value.toLocaleString("en-US", {
         maximumFractionDigits: 1,
         minimumFractionDigits: 1,
         useGrouping: true,
      });
      return (
         <text
            x={x + width / 2}
            y={y}
            fill="#0039e6"
            position="left"
            textAnchor="middle"
            fontWeight="bold"
            fontSize="12px"
            dy={-6}
         >{`${formatValue}`}</text>
      );
   };

   const columns = [
      {
         title: "Product",
         dataIndex: "product",
         key: "product",
         ellipsis: true,
         width: 95,
      },
      {
         title: `${dayjs().format("MMM")} (m3)`,
         dataIndex: "qttCurrentMonth",
         key: "qttCurrentMonth",
         align: "right",
         width: 55,
      },
      {
         title: "Total(m3)",
         dataIndex: "totalVol",
         key: "totalVol",
         align: "right",
         width: 65,
      },
      {
         title: "Total($)",
         dataIndex: "totalAmt",
         key: "totalAmt",
         align: "right",
         width: 70,
      },
   ];

   return (
      <div className="dashboar-chart">
         <Card
            title="Concrete (invoice data)"
            style={{
               top: 10,
               left: 5,
               margin: 0,
               boxShadow: "5px 15px 24px 5px #D2DAE2",
               border: "none",
               paddingBottom: 20,
               marginBottom: 20,
               borderRadius: 10,
               overflow: "hidden",
               // height: 1 && window.innerHeight * 0.9,
            }}
            bodyStyle={{
               margin: "auto",
               padding: 0,
            }}
            headStyle={{
               backgroundColor: "#34495e",
               color: "white",
               lineHeight: "15px",
            }}
         >
            <div style={{ margin: "10px 15px" }}>
               <Row>
                  <Col span={19} style={{ left: "-10px" }}>
                     <Row gutter={10} style={{ background: "#eceff3", borderRadius: "2px" }}>
                        <Col span={4}>
                           <p>Cummulative volume:</p>
                           <h6>
                              <span
                                 style={{
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "32px",
                                 }}
                              >{`${dataC.total_volume} `}</span>
                              m3
                           </h6>
                           {/* <p>Cummulative</p> */}
                        </Col>
                        <Col span={4}>
                           <p>Cummulative amount:</p>
                           <h6>
                              <span
                                 style={{
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "32px",
                                 }}
                              >{`$${dataC.total_amount} `}</span>
                              sgd
                           </h6>
                        </Col>

                        <Col span={8}></Col>
                        <Col span={4}>
                           <p>Current month volume:</p>
                           <h6>
                              <span
                                 style={{
                                    color: "blue",
                                    fontWeight: "bold",
                                    fontSize: "32px",
                                 }}
                              >{`${dataC.total_volume_this_month} `}</span>
                              m3
                           </h6>
                        </Col>
                     </Row>

                     <Row>
                        <ResponsiveContainer width="100%" height={280}>
                           <BarChart
                              data={dataConcVol}
                              margin={{
                                 top: 25,
                                 right: 5,
                                 left: 5,
                                 bottom: 20,
                              }}
                           >
                              <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                                 <Label
                                    value="Volume (m3)"
                                    offset={0}
                                    position="bottom"
                                    style={{
                                       fontWeight: 700,
                                       fontSize: "16px",
                                    }}
                                 />
                              </XAxis>
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={25} />
                              <Bar dataKey="permanent_work" stackId="a" fill="#ff9933">
                                 <LabelList dataKey="permanent_work" position="center" fontSize="11px" />
                              </Bar>
                              <Bar dataKey="temporary_work" stackId="a" fill="#82ca2d">
                                 <LabelList dataKey="temporary_work" position="center" fontSize="11px" />
                              </Bar>

                              <Bar dataKey="back_charge" stackId="a" fill="#008080">
                                 <LabelList dataKey="back_charge" position="center" fontSize="11px" />
                              </Bar>

                              <Bar dataKey="no_info" stackId="a" fill="#8882d8" label={renderCustomBarLabel}></Bar>
                           </BarChart>
                        </ResponsiveContainer>
                     </Row>
                     <Row>
                        <ResponsiveContainer width="100%" height={280}>
                           <BarChart
                              data={dataConcAmt}
                              margin={{
                                 top: 15,
                                 right: 5,
                                 left: 5,
                                 bottom: 20,
                              }}
                           >
                              <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
                              <XAxis dataKey="name">
                                 <Label
                                    value="Pay amount ($)"
                                    offset={0}
                                    position="bottom"
                                    style={{
                                       fontWeight: 700,
                                       fontSize: "16px",
                                    }}
                                 />
                              </XAxis>
                              <YAxis />
                              {/* <Legend /> */}

                              <Bar dataKey="amount" stackId="a" fill="#80aaff" label={renderCustomBarLabel} />
                           </BarChart>
                        </ResponsiveContainer>
                     </Row>
                  </Col>
                  <Col
                     span={5}
                     style={{
                        top: "0px",
                        marginLeft: "0px",
                        marginRight: "0px",
                     }}
                  >
                     <h5 style={{ textAlign: "center" }}>Summary By Product</h5>
                     <Table
                        dataSource={qttByProduct}
                        columns={columns}
                        pagination={{ pageSize: 50 }}
                        scroll={{ y: 600 }}
                     />
                  </Col>
               </Row>
            </div>
         </Card>

         <Card
            title="Rebar (invoice data)"
            style={{
               top: 10,
               left: 5,
               margin: 0,
               boxShadow: "5px 15px 24px 5px #D2DAE2",
               border: "none",
               paddingBottom: 20,
               marginBottom: 20,
               borderRadius: 10,
               overflow: "hidden",
               // height: 1 && window.innerHeight * 0.9,
            }}
            bodyStyle={{
               margin: "auto",
               padding: 0,
            }}
            headStyle={{
               backgroundColor: "#34495e",
               color: "white",
               lineHeight: "15px",
            }}
         >
            <div style={{ margin: "10px 15px" }}>
               <Row gutter={10} style={{ background: "#eceff3", borderRadius: "2px" }}>
                  <Col span={4}>
                     <p>Cummulative weight:</p>
                     <h6>
                        <span
                           style={{
                              color: "blue",
                              fontWeight: "bold",
                              fontSize: "32px",
                           }}
                        >
                           {dataR.total_volume}
                        </span>
                        kg
                     </h6>
                     {/* <p>Cummulative</p> */}
                  </Col>
                  <Col span={4}>
                     <p>Cummulative amount:</p>
                     <h6>
                        $
                        <span
                           style={{
                              color: "blue",
                              fontWeight: "bold",
                              fontSize: "32px",
                           }}
                        >
                           {dataR.total_amount}
                        </span>
                     </h6>
                  </Col>

                  <Col span={4}>
                     <p>Current month weight:</p>
                     <h6>
                        <span
                           style={{
                              color: "blue",
                              fontWeight: "bold",
                              fontSize: "32px",
                           }}
                        >
                           {dataR.total_weight_this_month}
                        </span>
                        kg
                     </h6>
                     {/* <p>Cummulative</p> */}
                  </Col>
                  <Col span={4}>
                     <p>Current month amount:</p>
                     <h6>
                        $
                        <span
                           style={{
                              color: "blue",
                              fontWeight: "bold",
                              fontSize: "32px",
                           }}
                        >
                           {dataR.total_amount_this_month}
                        </span>
                     </h6>
                  </Col>
               </Row>
               <Row>
                  <ResponsiveContainer width="100%" height={280}>
                     <BarChart
                        data={dataRebarWeight}
                        margin={{
                           top: 25,
                           right: 5,
                           left: 5,
                           bottom: 20,
                        }}
                     >
                        <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                           <Label
                              value="Rebar (kg)"
                              offset={0}
                              position="bottom"
                              style={{ fontWeight: 700, fontSize: "16px" }}
                           />
                        </XAxis>
                        <YAxis />
                        {/* <Legend /> */}

                        <Bar dataKey="amount" stackId="a" fill="#8884d8" label={renderCustomBarLabel} />
                     </BarChart>
                  </ResponsiveContainer>
               </Row>
               <Row>
                  <ResponsiveContainer width="100%" height={280}>
                     <BarChart
                        data={dataRebarAmt}
                        margin={{
                           top: 25,
                           right: 5,
                           left: 5,
                           bottom: 20,
                        }}
                     >
                        <CartesianGrid stroke="#e4e8eb" strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                           <Label
                              value="Amount Claim ($)"
                              offset={0}
                              position="bottom"
                              style={{ fontWeight: 700, fontSize: "16px" }}
                           />
                        </XAxis>
                        <YAxis />
                        {/* <Legend /> */}

                        <Bar dataKey="amount" stackId="a" fill="#80aaff" label={renderCustomBarLabel} />
                     </BarChart>
                  </ResponsiveContainer>
               </Row>
            </div>
         </Card>
      </div>
   );
};

export default Dashboard;
