import { APPLY_ACTION_ON_CELL, COPY_TEMP_DATA, GET_CELL_MODIFIED_TEMP, OVERWRITE_CELLS_MODIFIED, OVERWRITE_CELL_REDUCER, SET_CELL_ACTIVE } from '../../types';


const stateInit = {
   cellsModifiedTemp: {},
   cellActive: null,
   tempCopiedText: null,
   cellAppliedAction: null
};


export default function (state = stateInit, action) {
   const { type, payload } = action;

   switch (type) {
      case GET_CELL_MODIFIED_TEMP:
         return {
            ...state,
            cellsModifiedTemp: {
               ...state.cellsModifiedTemp,
               ...payload
            }
         };

      case OVERWRITE_CELLS_MODIFIED:
         return {
            ...state,
            cellsModifiedTemp: payload
         };

      case SET_CELL_ACTIVE:
         return {
            ...state,
            cellActive: payload
         };

      case COPY_TEMP_DATA:
         return {
            ...state,
            tempCopiedText: payload
         };

      case APPLY_ACTION_ON_CELL:
         return {
            ...state,
            cellAppliedAction: payload
         };

      case OVERWRITE_CELL_REDUCER:
         return stateInit;

      default:
         return state;
   };
};

