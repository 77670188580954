import React, { useState, useEffect, useMemo, memo, useCallback } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../../../scss/module_DoInv/do_inv_aggrid.scss";
// import { Button, Radio, Input, Tooltip, Popconfirm, DatePicker, message, Icon } from "antd";
import TablePO_status from "./TablePO_status";

import _, { reduce } from "lodash";
import { func_process_conc_do_data } from "../../function/func_process_data";
var customParseFormat = require("dayjs/plugin/customParseFormat");

const RenderMe = (p) => {
   console.log(p);
   return (
      <div>
         {p.node.allChildrenCount == null ? (
            <button
               style={{
                  background: "green",
                  display: "inline-block",
                  height: "30px",
                  lineHeight: "30px",
                  boxSizing: "border-box",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                  borderRadius: "2px",
               }}
            >
               QSA
            </button>
         ) : null}
      </div>
   );
};

const TablePO = ({
   tableRef,
   data,
   filter_data,
   filterMode,
   generalState,
   forceReRender,
   setData,
   setFilterData,
   setStatistic,
}) => {
   const [user_role, set_user_role] = useState();
   const [rowData, setRowData] = useState([]);
   const projectId = useSelector((state) => state.project.projectId);
   const [project_id, set_project_id] = useState(projectId);

   useEffect(() => {
      //    //    const role = localStorage.getItem("fp_user_role");
      //    //    set_user_role(role);
      //    //    // set_project_id(projectId);
      //    //    //make sure working on concrete DO form
      //    //    const fetchdata = async () => {
      //    //       var res = await axios.get("/api/do_inv/common/get_data_by_date_range", {
      //    //          params: {
      //    //             currentFilterMode: filterMode,
      //    //             project_id: project_id,
      //    //             current: "conc_do",
      //    //          },
      //    //       });
      //    //       const temp_d = res.data.data.items;
      //    //       const res_f = await func_process_conc_do_data(temp_d);
      //    //       setData(res_f.processed_data);
      //    //       setStatistic(res_f.staticInfo);
      //    //    };
      //    //    fetchdata();

      fetch("https://www.ag-grid.com/example-assets/row-data.json")
         .then((result) => result.json())
         .then((rowData) => setRowData(rowData));
   }, [filterMode, generalState, forceReRender]);

   const [columnDefs] = useState([
      { headerName: "make", field: "make", filter: true, rowGroup: true },
      { headerName: "model", field: "model", filter: true },
      { headerName: "price", field: "price", aggFunc: "sum" },
      { headerName: "status", field: "status", cellRenderer: RenderMe },
   ]);

   const defaultColDef = useMemo(() => {
      return {
         resizable: true,
         // sortable: true,
         // filter: true,
         headerClass: "ag-header-wh",
      };
   }, []);

   const gridOptions = {
      suppressAggFuncInHeader: true,
   };
   return (
      <div className="ag-theme-alpine" style={{ height: 1000, width: "100%" }}>
         <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            // frameworkComponents={{
            //    manPowerCellWeek: CellManPowerWeek,
            //    manPowerCellMonth: CellManPowerMonth,
            //    manCellInputPicker: CellInputPicker.bind(this, listValues),
            //    manCellAutoComplete: CellAutoComplete
            //  }}
            gridOptions={gridOptions}
         ></AgGridReact>
      </div>
   );
};

export default TablePO;
