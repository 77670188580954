import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions/app/auth";
import { Container, Row, Col } from "reactstrap";

import { WH_BimApp_Start } from '../../image'
import { handleErrAuthMicrosoft, socket } from '../../components/module_BimApp/function/AdminFunction'
import decode from 'jwt-decode';
import { userLoggedIn } from "../../actions/app/auth";
import { store } from '../../store'
import axios from "axios";
import { Client } from '@microsoft/microsoft-graph-client';
import { message, Button, Popover } from "antd";
import { ButtonGroup, Icon } from 'rsuite'
// import { logPageView } from '../../Function/PageFuction'
// import {Cover} from '../cover/index'
import { Loader, IconButton } from 'rsuite';
import { handleLoginMs, checkWohhupUser } from '../../components/module_BimApp/function/AdminFunction'
import { isMobile } from "react-device-detect";


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  componentWillMount = () => {

    if (this.props.user.token) {
      const payload = decode(this.props.user.token)
      if (payload.email === 'admin@wohhup.com' || !checkWohhupUser(payload.email)) {
        this.setState({ loading: false })
        // if(this.props.user.permission.roleDashboard){
        //   this.props.history.push("/drawing-dashboard")
        //   return
        // }
        this.props.history.push("/projects")
        return
      }
    }



    if (!localStorage.userWh5Dapp && window.hello('aad').getAuthResponse()) {
      window.client = Client.init({ //!note
        authProvider: done => {
          done(null, window.hello('aad').getAuthResponse().access_token);
          // this.props.history.push("/projects")
        }
      });
      window.client
        .api('/me')
        .get((err, res) => {
          if (!err) {
            let idd_redirect_type = localStorage.getItem('idd_redirect_type')
            let idd_redirect_url = localStorage.getItem('idd_redirect_url')
            // var domain = res.mail.substring(res.mail.lastIndexOf("@") + 1);
            if (res.mail === 'admin@wohhup.com') {
              message.warning("You have to use wohhup mail")
              localStorage.removeItem('hello')
              this.setState({ loading: false })
            } else {

              let userInfo = {}
              userInfo.userName = res.displayName
              userInfo.title = res.jobTitle
              userInfo.company = 'Woh Hup Private Ltd'
              userInfo.phone = res.mobilePhone === null ? '' : res.mobilePhone
              axios.post('/api/auth/microsoft-account', { email: res.mail.toLowerCase(), user: userInfo })
                .then(async user => {
                  if (idd_redirect_type && idd_redirect_url) {
                    let res = await axios.get("/api/auth/redirect_token", { params: { token: user.data.user.token } })
                    localStorage.removeItem('idd_redirect_type')
                    localStorage.removeItem('idd_redirect_url')
                    window.location.replace(`${idd_redirect_url}/idd_redirect/${res.data.token}`)
                    return
                  }
                  this.props.history.push("/projects")
                  localStorage.setItem('userWh5Dapp', JSON.stringify(res))
                  socket.emit("client-send-username", res.mail);
                  localStorage.bookwormJWT = user.data.user.token;
                  store.dispatch(userLoggedIn(user.data.user));
                  this.setState({ loading: false })
                })
                .catch(err => {
                  localStorage.removeItem('hello')
                  this.setState({ loading: false })
                })
            }
          } else {
            handleErrAuthMicrosoft(err, this.props.user.token)
            this.setState({ loading: false })
          }
        })
    } else if (localStorage.userWh5Dapp && window.hello('aad').getAuthResponse()) {
      this.props.history.push("/projects")
    }
    else {
      // this.props.history.push("/projects")
      this.setState({ loading: false })
    }
  }

  render() {
    const { isAuthenticated, logout } = this.props
    return (
      <>
        {/* <Cover /> */}
        {this.state.loading ? <Loader center content="Loading..." speed="fast" size="md" vertical backdrop={true} style={{ zIndex: 1000 }} /> :
          <>
            {/* <div class="container">
              <div id="clouds">
                <div class="cloud1"></div>
                <div class="cloud2"></div>
                <div class="cloud3"></div>
              </div>
              <div id="clouds2">
                <div class="cloud1"></div>
                <div class="cloud2"></div>
                <div class="cloud3"></div>
              </div>

            </div> */}
            <Container
              fluid
              style={{
                height: isAuthenticated ? "calc(100vh - 53.78px)" : '100vh',//
                color: "white",
                // background: "linear-gradient(to right, rgb(112, 109, 77), rgb(91, 87, 72))",
                //backgroundColor: '#ffffff00',
                position: "fixed",
                // backgroundImage: `url(${WH_BimApp_Start})`,
                backgroundPosition: 'inherit',
                backgroundRepeat: 'round',
                objectFit: 'fill',
                padding: 0
                // backgroundSize:'cover'
                // display: 'none',
              }}
            >
              <img src={WH_BimApp_Start} style={{ width: '100%', height: '100%', objectFit: isMobile ? 'cover' : 'fill' }} />

              <div>
                {isAuthenticated ?

                  <Link to="/projects"
                    style={{ marginLeft: 3, color: 'black', verticalAlign: 'middle', position: 'absolute', top: 1, right: 5 }}   >
                    <Button type='dashed' style={{ float: 'right', top: 10 }} shape="round" size='large'>
                      Dashboard
                    </Button>
                  </Link>

                  :
                  <>
                    <div style={{ marginLeft: 3, color: 'black', verticalAlign: 'middle', position: 'absolute', top: 1, right: 5 }}>
                      <Link
                        onClick={handleLoginMs}
                      >
                        {/* <Button type='dashed' style={{ float: 'right', top: 10 }} shape="round" size='large'>                     Sign in     </Button> */}
                        <IconButton style={{ float: 'right', top: 10 }} icon={<Icon icon="sign-in" />} color='blue' >Sign in</IconButton>
                      </Link>
                      {/* <IconButton style={{ float: 'right', top: 10, marginRight: 5 }} icon={<Icon icon="sign-in" />} color='blue'
                      onClick={() => { this.props.history.push('/login') }}      >External Sign in</IconButton> */}
                    </div>


                  </>}
                <Popover content={
                  < >
                    <p style={{ textShadow: '0 0 black' }}>This app developed by Woh Hup (Private) LTD.</p>
                    <p>
                      For more information about Woh Hup, visit this <a href='https://www.wohhup.com/'
                        target="_blank" rel="noopener noreferrer" > website </a>
                    </p>
                  </>}
                  trigger="hover" placement='topLeft'>
                  <Icon icon="question2" style={{ left: 5, bottom: 20, position: 'absolute' }} size='3x' />
                </Popover>
              </div>

            </Container >
          </>
        }
      </>

    );
  }
}

// const HomePage = () => 

HomePage.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    user: state.user,
    redirect: state.redirect
  };
}

export default connect(mapStateToProps, { logout: actions.logout })(HomePage);
