import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Icon, Popover, InputNumber } from 'antd'
import _ from 'lodash';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { setPDFData } from '../../../actions/app/pdf'
import InputColor from './input/InputColor'
import InputSize from './input/InputSize'
const ButtonGroup = Button.Group;


function ContextMenu(props) {
  const [width, setWidth] = useState()
  const [color, setColor] = useState()
  useEffect(() => {
    if (props.activeObject.length !== 0) {
      setWidth(props.activeObject[0].strokeWidth)
      setColor(props.activeObject[0].stroke)
    }
  }, [props.activeObject])

  const content = (
    props.activeObject.length !== 0 && <div style={{ display: 'flex', flexDirection: 'row' }}  >
      <Button size='small' className='idd-app-pdf-feature feature' >
        <input type='color' id='input-color' value={color} onChange={(e) => {
          props.activeObject.forEach(v => {
            if(v.type ==='group'){
              v._objects[0].set('stroke', props.pdfViewer.color)
              // v._objects[1].set('stroke', props.pdfViewer.color)
              v._objects[1].set('fill', props.pdfViewer.color)
            }else{
              v.set('stroke', e.target.value)
              v.canvas.renderAll()
            }
          })
          setColor(e.target.value)
        }} />
      </Button>
      <InputNumber style={{ width: 50, backgroundColor: 'white' }} size='small' min={1} max={10} value={width} onChange={(e) => {
        props.activeObject.forEach(v => {
          if(v.type ==='group'){
            v._objects[0].set('strokeWidth', props.pdfViewer.strokeWidth)
            v._objects[1].set('width', props.pdfViewer.strokeWidth * 5)
            v._objects[1].set('height', props.pdfViewer.strokeWidth * 5 + 5)
          }else{
            v.set('strokeWidth', e)
            v.canvas.renderAll()
          }
        })
        setWidth(e)
      }} />
    </div>
  );

  return (
    <>
      <Popover placement="topLeft" content={content} visible={props.visible} >
        <div style={{ position: 'absolute', left: props.left, top: props.top }} ></div>
      </Popover>
    </>
  )
}

ContextMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  pdfViewer: PropTypes.object.isRequired,
  setPDFData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    pdfViewer: state.pdfViewer
  };
}

export default connect(mapStateToProps, { setPDFData })(ContextMenu)