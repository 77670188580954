import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
// import customParseFormat from "dayjs/plugin/customParseFormat";

import { Row, Col, Radio, DatePicker, Tooltip, Tabs } from "antd";
import ButtonGroup from "antd/lib/button/button-group";

import _ from "lodash";

import TableQMWorks from "./qmworks_jot/TableQMWorks";
import TableGroupByUnit from "./qmworks_jot/TableGroupByUnit";
import StaticInfo from "./_common_component/StaticInfo";
import ChartProductivityRange from "./qmworks_jot/ChartProductivityRange";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const QMWorksJot = (current) => {
   const [originData, setOriginData] = useState();
   const [tempData, setTempData] = useState();
   const [groupbyUnit, setGroupByUnit] = useState();
   const [listProject, setListProject] = useState([]);
   const [currentProject, setCurrentProject] = useState();
   const [rangeSelected, setRangeSelected] = useState();
   const [data_productivity_range, set_data_productivity_range] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [filterMode, setFilterMode] = useState(0);
   const [static_report, setStatic] = useState();
   useEffect(() => {
      {
         const fetchdata = async () => {
            const res = await axios.get("/api/qaqc/dashboard/qm");
            const temp_d = res.data.data;
            setOriginData(temp_d);
            setTempData(temp_d);
            setCurrentProject("All Projects");

            let projects = [...new Set(temp_d.map((obj) => obj.project_code))];
            projects.sort();
            projects.splice(0, 0, "All Projects");
            setListProject(projects);

            const data_group = Digest_Data_All_Project(temp_d);
            get_Static_for_Dashboard(data_group);
         };

         fetchdata();
         setRangeSelected("All");
      }
   }, []);

   const Digest_Data_All_Project = (temp_d) => {
      temp_d.forEach((e) => {
         e.YYYYMM = dayjs(e.created_at).format("YYYYMM");
         e.MMYY = dayjs(e.created_at).format("MM/YY");
         e.date = dayjs(e.created_at).format("DD/MM/YYYY");
      });

      //GET DATA FOR TABLE AND BAR CHART
      var group_unit = _.groupBy(temp_d, "Unit");

      let list_group_by_unit = [];
      _.map(group_unit, (value, key) => {
         let t = new Object();
         t.Unit = value[0].Unit;
         t.project_code = value[0].project_code;
         t.no_of_sample = 0;
         if (value[0].no_of_sample !== null) {
            t.no_of_sample = value[0].no_of_sample;
         }

         const list_qm = value.filter((e) => e.WorkType === "QM Work");
         t.qmwork = list_qm.reduce((e, { Hours }) => e + Hours, 0);
         const list_recon = value.filter((e) => e.WorkType === "Reconditioning (Final)");
         t.reconwork = list_recon.reduce((e, { Hours }) => e + Hours, 0);
         const list_bc = value.filter((e) => e.WorkType === "Work to Backcharge");
         t.bcwork = list_bc.reduce((e, { Hours }) => e + Hours, 0);
         const list_other = value.filter((e) => e.WorkType === "Other Work");
         t.otherwork = list_other.reduce((e, { Hours }) => e + Hours, 0);

         t.total = t.qmwork + t.reconwork + t.bcwork + t.otherwork;
         if (t.no_of_sample != undefined && t.no_of_sample != 0) {
            t.ms = Math.round((t.total / t.no_of_sample / 11) * 100) / 100;
            t.msQR = Math.round(((t.qmwork + t.reconwork) / t.no_of_sample / 11) * 100) / 100;
         } else {
            t.ms = "NA";
            t.msQR = "NA";
         }

         list_group_by_unit.push(t);
      });
      list_group_by_unit = _.sortBy(list_group_by_unit, ["Unit"]);
      setGroupByUnit(list_group_by_unit);

      //GET DATA FOR PRODUCTIVITY CHART
      let list_p_range = [
         {
            name: "<0.75",
            count: 0,
         },
         {
            name: "0.75-1.0",
            count: 0,
         },
         {
            name: "1.0-1.25",
            count: 0,
         },
         {
            name: "1.25-1.5",
            count: 0,
         },
         {
            name: ">=1.5",
            count: 0,
         },
      ];

      list_group_by_unit.forEach((e) => {
         if (e.ms != "NA") {
            if (e.ms < 0.75) {
               list_p_range[0].count = list_p_range[0].count + 1;
            }
            if (e.ms >= 0.75 && e.ms < 1) {
               list_p_range[1].count = list_p_range[1].count + 1;
            }
            if (e.ms >= 1 && e.ms < 1.25) {
               list_p_range[2].count = list_p_range[2].count + 1;
            }
            if (e.ms >= 1.25 && e.ms < 1.5) {
               list_p_range[3].count = list_p_range[3].count + 1;
            }
            if (e.ms >= 1.5) {
               list_p_range[4].count = list_p_range[4].count + 1;
            }
         }
      });
      set_data_productivity_range(list_p_range);
      return list_group_by_unit;
   };

   const handleChangeProject = (e) => {
      const current_project = e.target.value;
      setCurrentProject(current_project);
      let data = [];
      if (current_project === "All Projects") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project_code === current_project);
      }

      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end !== null
      ) {
         data = data.filter((e) => e.date >= date_start && e.date <= date_end);
      }
      if (
         date_start !== undefined &&
         date_start !== "" &&
         date_end !== undefined &&
         date_end !== "" &&
         date_end == null
      ) {
         data = data.filter((e) => e.date >= date_start);
      }
      setTempData(data);
      const data_group = Digest_Data_All_Project(data);
      get_Static_for_Dashboard(data_group);
   };

   const handleChangeDate = (fm, dateString) => {
      var customParseFormat = require("dayjs/plugin/customParseFormat");
      dayjs.extend(customParseFormat);
      var isBetween = require("dayjs/plugin/isBetween");
      dayjs.extend(isBetween);
      var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
      dayjs.extend(isSameOrAfter);

      const now = dayjs();

      const y = now.year();
      const m = now.month();
      const d = now.day();
      let data = [];
      if (currentProject === "All Projects") {
         data = _.cloneDeep(originData);
      } else {
         data = _.cloneDeep(originData).filter((e) => e.project_code === currentProject);
      }

      switch (fm) {
         case 1:
            // const sd1 = dayjs([y, m + 1, 1]).format("DD/MM/YYYY");
            const sd1 = dayjs(new Date(y, m, 1)).format('DD/MM/YYYY');
            data = data.filter((e) => e.month == m);
            set_date_start(sd1);
            set_date_end(null);

            break;
         case 2:
            // let sd2 = dayjs([y, m, 1]).format("DD/MM/YYYY");
            // const de = dayjs(sd2).daysInMonth();
            // let ed2 = dayjs([y, m, de]).format("DD/MM/YYYY");

            let last = now.subtract(1, "month")
            let sd2 = dayjs(new Date(last.year(), last.month(), 1)).format('DD/MM/YYYY');
            const de = dayjs(sd2).daysInMonth()
            let ed2 = dayjs(new Date(last.year(), last.month(), de)).format('DD/MM/YYYY');
            // data = data.filter((e) => e.created_at >= sd2 && e.created_at <= ed2);
            data = data.filter((e) => e.month == m - 1);
            set_date_start(sd2);
            set_date_end(ed2);
            break;
         // case 3:
         //    let sd3 = now.subtract(3, "month").format("DD/MM/YYYY");
         //    data = data.filter((e) => dayjs(e.created_at, "DD/MM/YYYY").isSameOrAfter(sd3, "day"));
         //    set_date_start(sd3);
         //    set_date_end(null);
         //    break;

         case 4:
            if (
               dateString[0] !== undefined &&
               dateString[0] !== "" &&
               dateString[1] !== undefined &&
               dateString[1] !== ""
            ) {
               data = data.filter((e) =>
                  dayjs(e.date, "DD/MM/YYYY").isBetween(dateString[0], dateString[1], "day", "[]")
               );
               set_date_start(dateString[0]);
               set_date_end(dateString[1]);
            }
            break;
      }

      setTempData(data);
      const data_group = Digest_Data_All_Project(data);
      get_Static_for_Dashboard(data_group);
   };

   const get_Static_for_Dashboard = (data_group) => {
      if (data_group == undefined) {
         return;
      }
      let static_info = [];
      let total_hrs = data_group.reduce((e, { total }) => e + total, 0);
      let total_sample = data_group.reduce((e, { no_of_sample }) => e + no_of_sample, 0);

      let productivity = "NA";
      if (total_sample != undefined && total_sample > 0) {
         productivity = total_hrs / total_sample / 11;
      }

      let s = new Object();
      s.key = "man-day/sample";
      s.value = productivity.toLocaleString("en-US", {
         maximumFractionDigits: 1,
         minimumFractionDigits: 1,
         useGrouping: true,
      });
      static_info.push(s);

      s = new Object();
      s.key = "units";

      s.value = data_group.length.toLocaleString("en-US", {
         maximumFractionDigits: 0,
         minimumFractionDigits: 0,
         useGrouping: true,
      });
      static_info.push(s);

      s = new Object();
      s.key = "samples";
      s.value = total_sample.toLocaleString("en-US", {
         maximumFractionDigits: 0,
         minimumFractionDigits: 0,
         useGrouping: true,
      });
      static_info.push(s);

      s = new Object();
      s.key = "man-days";
      s.value = (total_hrs / 11).toLocaleString("en-US", {
         maximumFractionDigits: 0,
         minimumFractionDigits: 0,
         useGrouping: true,
      });
      static_info.push(s);

      setStatic(static_info);
   };

   const onTabChange = (key) => {
      console.log(key);
   };

   return (
      <div style={{ margin: "10px 10px" }}>
         <Row gutter={10} className="qaqc_dashboard_card_body">
            {/* <Col span={17} style={{ left: "5px" }}> */}
            <Radio.Group defaultValue="All Projects" buttonStyle="solid" onChange={(e) => handleChangeProject(e)}>
               {listProject !== undefined
                  ? listProject.map((p) => (
                     <Radio.Button value={p} key={p}>
                        {p.toUpperCase()}
                     </Radio.Button>
                  ))
                  : null}
            </Radio.Group>

            <ButtonGroup style={{ float: "right" }}>
               <Radio.Group
                  defaultValue={filterMode}
                  buttonStyle="solid"
                  value={filterMode}
                  onChange={(e) => {
                     setFilterMode(e.target.value);
                  }}
               >
                  <div>
                     <Tooltip title="Shown data for all time.">
                        <Radio.Button
                           value={0}
                           onClick={() => {
                              handleChangeDate(0, null);
                           }}
                        >
                           All time
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown data for current month">
                        <Radio.Button
                           value={1}
                           onClick={() => {
                              handleChangeDate(1, null);
                           }}
                        >
                           {dayjs().format("MMM-YY")}
                        </Radio.Button>
                     </Tooltip>
                     <Tooltip title="Shown data of last month">
                        <Radio.Button
                           value={2}
                           onClick={() => {
                              handleChangeDate(2, null);
                           }}
                        >
                           {dayjs().subtract(1, "month").format("MMM-YY")}
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO for selected months.">
                        <Radio.Button value={4} style={{ float: "right", width: 220 }}>
                           Select range of date
                           {filterMode === 4 ? (
                              <RangePicker
                                 size={"default"}
                                 style={{ float: "right", marginRight: -15, width: 220 }}
                                 onChange={(date, dateString) => {
                                    handleChangeDate(4, dateString);
                                    setFilterMode(4);
                                 }}
                              />
                           ) : null}
                        </Radio.Button>
                     </Tooltip>
                  </div>
                  {/* </Space> */}
               </Radio.Group>
            </ButtonGroup>
         </Row>
         <br></br>
         <Row className="qaqc_dashboard_card_body">
            <Tabs defaultActiveKey="1" onChange={onTabChange}>
               <TabPane tab="Productivity" key="1">
                  <Row>
                     <Col span={7} className="qaqc_dashboard_card" style={{ height: "46vh" }}>
                        <StaticInfo static_report={static_report} />

                        <br></br>
                        <br></br>

                        <ChartProductivityRange
                           current_project={currentProject}
                           projects={listProject}
                           data={data_productivity_range}
                           rangeSelected={rangeSelected}
                           setRangeSelected={setRangeSelected}
                        />
                     </Col>
                     <Col span={16} style={{ left: "55px" }} className="qaqc_dashboard_card">
                        <Row>
                           {groupbyUnit !== undefined ? (
                              <TableGroupByUnit data={groupbyUnit} rangeSelected={rangeSelected} />
                           ) : null}
                        </Row>
                     </Col>
                  </Row>
                  <br></br>
                  <Row className="qaqc_dashboard_card">
                     {tempData !== undefined ? <TableQMWorks data={tempData} /> : null}
                  </Row>
               </TabPane>
               <TabPane tab="Cost Allocation" key="2">
                  To shown cost allocation for worker
               </TabPane>
            </Tabs>
         </Row>
      </div>
   );
};

export default QMWorksJot;
