
import React from 'react'
import WH_logo from '../../../image/WH_Logo.gif'
//"https://d1yk6z6emsz7qy.cloudfront.net/static/images/loading.gif"
export const LoadingScreen = () => {
    return (
      <div className='my-loading-overlay'>
        <img src={'https://rawcdn.githack.com/Woh-Hup/Resource/4b3a28a661462923d43e74b136dde3bdfa4cc042/image/WH_Logo.gif'} />
        Loading...
      </div>
    )
  };
  