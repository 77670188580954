import React, { useState, useEffect } from 'react';
import { add, update, addAvatar, getAvatar } from '../../api/employee';
import Resizer from 'react-image-file-resizer';
import { connect, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Form,
  notification,
  InputNumber,
  Select,
  Upload,
  Icon,
} from 'antd';
const { Option } = Select;
const _ = require('lodash');

function SupervisorForm(props) {
  const { record, isAdd, id, nationalities, emails } = props;
  const { setVisible, getDatas } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [fileSmall, setFileSmall] = useState(null);
  const projectId = useSelector((state) => state.project.projectId);
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values, employeeType: 1, projectId };

          if (file) {
            try {
              let res = await addAvatar({
                file: file,
                data: {
                  id,
                  projectId,
                  fileName: file.name,
                },
              });
              let avatarId = res;
              payload = { ...payload, avatar: avatarId };
            } catch (error) {}
          }

          if (fileSmall) {
            try {
              let res = await addAvatar({
                file: fileSmall,
                data: {
                  id,
                  projectId,
                  fileName: '32x32-' + fileSmall.name,
                },
              });
              let avatarId = res;
              payload = { ...payload, picture: avatarId };
            } catch (error) {}
          }

          if (isAdd) {
            try {
              await add(payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        name: '',
        employerCode: '',
        age: 25,
        designation: 'Supervisor',
        nationality: 'Singapore',
      });
      setImageUrl('');
      setFile(null);
      setFileSmall(null);
    } else {
      props.form.setFieldsValue({
        name: record.name,
        employerCode: record.employerCode,
        age: record.age,
        designation: record.designation,
        nationality: record.nationality,
        email: record.email,
        bossEmail: record.bossEmail,
      });
    }
    getImageFromAvatarId(record);
    setFile(null);
    setFileSmall(null);
  }, [isAdd, record]);

  async function getImageFromAvatarId(record) {
    try {
      if (_.isNil(record.avatar)) {
        setImageUrl('');
      } else {
        let res = await getAvatar({ key: record.avatar, time: 60 });

        setImageUrl(res);
      }
    } catch (error) {}
  }

  const { getFieldDecorator } = props.form;

  const uploadButton = (
    <div>
      <Icon type={'plus'} />
      <div className='ant-upload-text'>Upload</div>
    </div>
  );

  const emailOptions = emails.map((d) => (
    <Option value={d} key={d}>
      {d}
    </Option>
  ));

  async function handleImport(file) {
    setFile(file);
    getImageLink(file);

    let newFile = await resizeImage(file);
    setFileSmall(newFile);
    return false;
  }

  async function getImageLink(file) {
    let src = null;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }

    setImageUrl(src);
  }

  function resizeImage(file) {
    if (file) {
      try {
        let extension = file.name.split('.').pop();
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            32,
            32,
            extension,
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      } catch (error) {}
    }
  }

  return (
    <div>
      <Form
        className='ant-form-custom'
        name='control-ref'
        onSubmit={(e) => handleSubmit(e)}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label='Name'>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input name!' }],
          })(<Input placeholder='Name' />)}
        </Form.Item>

        <Form.Item label='Employer Code'>
          {getFieldDecorator('employerCode', {
            rules: [{ required: true, message: 'Please input Employer Code!' }],
          })(<Input placeholder='Employer Code' />)}
        </Form.Item>

        <Form.Item label='Email'>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please select email!' }],
          })(
            <Select showSearch optionFilterProp='value' filterOption={true}>
              {emailOptions}
            </Select>
          )}
        </Form.Item>

        <Form.Item label='Report to'>
          {getFieldDecorator('bossEmail', {
            rules: [{ required: false, message: 'Please select Boss Email!' }],
          })(<Select showSearch>{emailOptions}</Select>)}
        </Form.Item>

        <Form.Item label='Nationality'>
          {getFieldDecorator('nationality', {
            rules: [{ required: false }],
          })(
            <Select defaultValue='Singapore' style={{ width: '100%' }}>
              <Option value='Indian'>Indian</Option>
              <Option value='Myanmar'>Myanmar</Option>
              <Option value='Bangladesh'>Bangladesh</Option>
              <Option value='Thailand'>Thailand</Option>
              <Option value='China'>China</Option>
              <Option value='Paskistan'>Paskistan</Option>
              <Option value='Malaysia'>Malaysia</Option>
              <Option value='Singapore'>Singapore</Option>
              <Option value='Other'>Other</Option>
            </Select>
          )}
        </Form.Item>

        {/* <Form.Item label='Age'>
          {getFieldDecorator('age', {
            rules: [],
          })(
            <InputNumber
              className='w-100'
              min={1}
              max={100}
              initialValue={25}
            />
          )}
        </Form.Item> */}

        <Form.Item label='Designation'>
          {getFieldDecorator('designation', {
            rules: [{ required: false }],
          })(<Input placeholder='Designation' />)}
        </Form.Item>

        <Form.Item label='Avatar'>
          {/* <ImgCrop rotate> */}
          <Upload
            name='avatar'
            listType='picture-card'
            className='avatar-uploader float-right'
            showUploadList={false}
            accept='image/*'
            beforeUpload={handleImport}
          >
            {imageUrl ? (
              <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
          {/* </ImgCrop> */}
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'SupervisorAntForm' })(SupervisorForm);
