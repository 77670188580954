import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import withBreadcrumbs from "react-router-breadcrumbs-hoc";


const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

let check = true

const PureBreadcrumbs = ({ breadcrumbs }) => {
  const classes = useStyles();
  var breadcrumbsNew = []
  check = true
  for (var i = 0; i < breadcrumbs.length; i++) {
    if (i !== 0) {
      if (breadcrumbs[i].location.state &&breadcrumbs[i].location.state.isDetail && check) {
        breadcrumbsNew.push({ nameBc: `${breadcrumbs[i].location.state.projectName} (Dashboard)`, pathnameBc: '/projectdetail', stateBc: breadcrumbs[i].location.state })
        check = false
      }
      else if (breadcrumbs[i].match.url === "/projects/project") {
        breadcrumbsNew.push({ nameBc: breadcrumbs[i].location.state.projectName, pathnameBc: breadcrumbs[i].match.url, stateBc: breadcrumbs[i].location.state })
      }
      else if (breadcrumbs[i].match.url === "/projects/project/folder") {
        breadcrumbsNew.push({ nameBc: breadcrumbs[i].location.state.folderName, pathnameBc: breadcrumbs[i].match.url, stateBc: breadcrumbs[i].location.state })
      }
      else if (breadcrumbs[i].match.url === "/projects/project/folder/view") {
        breadcrumbsNew.push({
          nameBc: breadcrumbs[i].location.state.itemName === breadcrumbs[i].location.state.itemDisplayName ?
            breadcrumbs[i].location.state.itemName : `${breadcrumbs[i].location.state.itemDisplayName} (${breadcrumbs[i].location.state.itemName})`,
          pathnameBc: breadcrumbs[i].match.url, stateBc: breadcrumbs[i].location.state
        })
      }
      else if (breadcrumbs[i].match.url === "/projects/project/folder/view-trimble") {
        breadcrumbsNew.push({
          nameBc: breadcrumbs[i].location.state.itemName === breadcrumbs[i].location.state.itemDisplayName ?
            breadcrumbs[i].location.state.itemName : `${breadcrumbs[i].location.state.itemDisplayName} (${breadcrumbs[i].location.state.itemName})`,
          pathnameBc: breadcrumbs[i].match.url, stateBc: breadcrumbs[i].location.state
        })
      }
      else if (breadcrumbs[i].match.url === "/projects/project/folder/compareview") {
        breadcrumbsNew.push({ nameBc: breadcrumbs[i].location.state.compareName, pathnameBc: breadcrumbs[i].match.url, stateBc: breadcrumbs[i].location.state })
      }
    }
  }
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbsNew.map(({ nameBc, pathnameBc, stateBc }, index) => (
            <Link style={{ color: "#000000", cursor: "pointer" }} key={pathnameBc} to={{
              pathname: pathnameBc,
              state: stateBc
            }}>
              {nameBc}
            </Link>

          ))}
        </Breadcrumbs>
      </Paper>
    </div>
  )
};

export default withBreadcrumbs()(PureBreadcrumbs);