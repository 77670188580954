import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FileFilled, FileOutlined, FolderOutlined } from '@ant-design/icons'
import base64url from 'base64url';
import { message } from 'antd';

export default function FileBrowserCell(props) {

    // const cellValue = props?.valueFormatted ? props?.valueFormatted : props?.value;
    // useEffect(() => {
    //     console.log(props)
    // }, [])
    const handleView = async () => {
        try {
            if(!(props.role === 'Document Controller' || props.isAdmin)){
                if(!props.node.canView){
                    message.warning('You have not permission to view this file')
                    return
                }
            }
         
            let key = `${props.pathDir}/${props.node.parent.data._id}/${props.node.data.versionId}/${props.node.data.name}`
            console.log(key)
            let linkToOpen = `${window.location.origin}/dms-viewer/${base64url(key)}`;
            window.open(linkToOpen, '_blank');
        } catch (ex) {
            message.warning(ex.message)
            props.setIsLoading(false)
        }

    }
    return (
        < >
            {props.data?.isFolder ?
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }} >
                    <FolderOutlined />
                    <span> {props.data?.name}</span>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }} onDoubleClick={handleView}>
                    <FileOutlined />
                    <span> {props.data?.name}</span>
                </div>

            }

        </>
    );
};