import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import Chart from 'react-google-charts';
import { Card, List, Col, Row, Typography, DatePicker, Input, Empty, Avatar, Tooltip } from 'antd';
import _ from 'lodash'
import { Icon, Button, Modal, Loader } from 'rsuite';
import axios from "axios";
import {TableHistory} from '../module_BimApp/tables';
import stc from 'string-to-color'
const { RangePicker } = DatePicker;
const { Text } = Typography;
class UsersControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataHistory: [],
      activeBtnToday: true,
      activeBtn7Days: false,
      activeBtn4Weeks: false,
      openInfoPanel: false,
      dataInforItem: [],
      startDate: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
      endDate: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),

      dataChart: [[{ type: 'date', id: 'Date' }, { type: 'number', id: 'View' }]]
    }

  }
  componentWillMount = () => {
    let today = moment().format('DD-MM-YYYY')
    axios.post(`/api/versions/get-history`, { startDate: today, endDate: null })
      .then(res => {
        let temp = []
        if (res.data !== null) {
          _.forEach(res.data.items, v => {
            let id = atob(v.itemId)
            let splitProjectName = id.split(':fs.folder:')
            let splitFolderName = splitProjectName[1].split(':is.item:')
            let tempUsers = []
            _.forEach(v.users, v => {
              tempUsers.push({ email: v.email, view: v.view, name: v.email.split('@')[0] })
            })
            temp.push({
              projectName: splitProjectName[0], folderName: splitFolderName[0], itemName: v.itemName, view: v.view, function: v.function, users: tempUsers
            })
          })
        }
        this.setState({ dataHistory: temp })
        this.props.handleLoading(false)
      })
    let startYear = moment().startOf('year').format('DD-MM-YYYY')
    let endYear = moment().endOf('year').format('DD-MM-YYYY')
    axios.post(`/api/versions/get-history`, { startDate: startYear, endDate: endYear })
      .then(res => {
        let temp = [[{ type: 'date', id: 'Date' }, { type: 'number', id: 'View' }]]
        if (res.data !== null) {
          _.forEach(res.data, v => {
            let totalView = 0
            _.forEach(v.items, i => {
              totalView = totalView + i.view
            })
            let tempDate = moment(v.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            temp.push([new Date(tempDate), totalView])
          })
          this.setState({ dataChart: temp, thisYear: moment() })
        }
      })
  }

  handleOpenControlInformationPanel = (data) => {

    this.setState({ openInfoPanel: true, dataInforItem: data })
  }
  handleCloseInfoPanel = () => {
    this.setState({ openInfoPanel: false })
  }
  onChangeDate = (dates) => {
    this.setState({
      startDate: dates[0].format("DD-MM-YYYY"),
      endDate: dates[1].format("DD-MM-YYYY")
    })
  }
  handleHistoryToday = () => {
    let today = moment().format('DD-MM-YYYY')
    axios.post(`/api/versions/get-history`, { startDate: today, endDate: null })
      .then(res => {
        let temp = []
        if (res.data !== null) {
          _.forEach(res.data.items, v => {
            let id = atob(v.itemId)
            let splitProjectName = id.split(':fs.folder:')
            let splitFolderName = splitProjectName[1].split(':is.item:')
            let tempUsers = []
            _.forEach(v.users, v => {
              tempUsers.push({ email: v.email, view: v.view, name: v.email.split('@')[0] })
            })
            temp.push({
              id: v.itemId, projectName: splitProjectName[0], folderName: splitFolderName[0], itemName: v.itemName, view: v.view, function: v.function, users: tempUsers
            })
          })
        }
        this.setState({ dataHistory: temp, activeBtnToday: true })
      })
  }
  handleRangeDateHistory = () => {
    axios.post(`/api/versions/get-history`, { startDate: this.state.startDate, endDate: this.state.endDate })
      .then(res => {
        let temp = []
        _.forEach(res.data, h => {
          _.forEach(h.items, v => {
            let id = atob(v.itemId)
            let splitProjectName = id.split(':fs.folder:')
            let splitFolderName = splitProjectName[1].split(':is.item:')
            let index = _.findIndex(temp, function (o) { return o.id === v.itemId })
            if (index >= 0) {
              let tempView = temp[index].view + v.view
              let tempFunction = temp[index].function + v.function
              let combineUsers = temp[index].users.concat(v.users)
              let group = _.groupBy(combineUsers, 'email')
              let tempUsers = []
              _.forEach(group, (g, k) => {
                let count = 0
                _.forEach(g, v => {
                  count = count + v.view
                })
                let split = k.split('@')
                tempUsers.push({ email: k, view: count, name: split[0] })
              })
              temp.splice(index, 1);
              temp.splice(index, 0, {
                id: v.itemId, projectName: splitProjectName[0], folderName: splitFolderName[0], itemName: v.itemName,
                view: tempView, function: tempFunction, users: tempUsers
              });
            } else {
              let tempUsers = []
              _.forEach(v.users, v => {
                tempUsers.push({ email: v.email, view: v.view, name: v.email.split('@')[0] })
              })
              temp.push({
                id: v.itemId, projectName: splitProjectName[0], folderName: splitFolderName[0], itemName: v.itemName, view: v.view, function: v.function, users: tempUsers
              })
            }
          })
        })
        this.setState({ dataHistory: temp, activeBtnToday: false })
      })

  }
  handleHistoryYear = (value) => {
    let startYear = value.startOf('year').format('DD-MM-YYYY')
    let endYear = value.endOf('year').format('DD-MM-YYYY')
    axios.post(`/api/versions/get-history`, { startDate: startYear, endDate: endYear })
      .then(res => {
        let temp = [[{ type: 'date', id: 'Date' }, { type: 'number', id: 'View' }]]
        if (res.data !== null) {
          _.forEach(res.data, v => {
            let totalView = 0
            _.forEach(v.items, i => {
              totalView = totalView + i.view
            })
            let tempDate = moment(v.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            temp.push([new Date(tempDate), totalView])
          })
          this.setState({ dataChart: temp, thisYear: value })
        }

      })
  }
  render() {
    const columnsListItem = [
      { Header: 'Project Name', accessor: 'projectName', id: 'projectName', units: false, filter: "includes", aggregate: 'count', },
      { Header: 'Folder Name', accessor: 'folderName', id: 'folderName', units: false, filter: "includes", aggregate: 'count', },
      { Header: 'Item Name', accessor: 'itemName', id: 'itemName', units: false, filter: "includes", aggregate: 'count', },
      {
        Header: 'Users', accessor: 'users', id: 'users', units: false, filter: "includes", aggregate: 'count',
        Cell: ({ cell: { value } }) => {
          let temp = []
          _.forEach(value, v1 => {
            let index = _.findIndex(temp, o => { return o.name === v1 })
            if (index < 0) {
              let split = v1.name.split('_')
              if (split.length > 1) {
                temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1.name })
              } else {
                temp.push({ shortCut: v1.name.charAt(0).toUpperCase(), name: v1.name })
              }
            }
          })
          return (<div>
            {temp.map(v =>
              <Tooltip title={v.name}>
                <Avatar style={{ verticalAlign: 'middle', fontSize: 12, backgroundColor: stc(v.name) }} size="small">
                  {v.shortCut}
                </Avatar>
              </Tooltip>
            )}
          </div>)
        },
        Aggregated: ({ cell: { row, value } }) => {
          let temp = []
          if (row.groupByID === 'projectName')
            _.forEach(row.subRows, v => {
              _.forEach(v.subRows, v1 => {
                _.forEach(v1.original.users, v2 => {
                  let index = _.findIndex(temp, o => { return o.name === v2.name })
                  if (index < 0) {
                    let split = v2.name.split('_')
                    if (split.length > 1) {
                      temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v2.name })
                    } else {
                      temp.push({ shortCut: v2.name.charAt(0).toUpperCase(), name: v2.name })
                    }
                  }
                })
              })
            })
          else if (row.groupByID === 'folderName')
            _.forEach(row.subRows, v => {
              _.forEach(v.original.users, v1 => {
                let index = _.findIndex(temp, o => { return o.name === v1.name })
                if (index < 0) {
                  let split = v1.name.split('_')
                  if (split.length > 1) {
                    temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1.name })
                  } else {
                    temp.push({ shortCut: v1.name.charAt(0).toUpperCase(), name: v1.name })
                  }
                }
              })
            })
          else if (row.groupByID === 'itemName')
            _.forEach(row.original.users, v1 => {
              let index = _.findIndex(temp, o => { return o.name === v1.name })
              if (index < 0) {
                let split = v1.name.split('_')
                if (split.length > 1) {
                  temp.push({ shortCut: split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase(), name: v1.name })
                } else {
                  temp.push({ shortCut: v1.name.charAt(0).toUpperCase(), name: v1.name })
                }
              }
            })
          temp = row.groupByID === 'users' ? value : temp
          return (<div>
            {temp.map(v =>
              <Tooltip title={v.name}>
                <Avatar style={{ verticalAlign: 'middle', fontSize: 12, backgroundColor: stc(v.name) }} size="small">
                  {v.shortCut}
                </Avatar>
              </Tooltip>
            )}
          </div>)
        },
      },
      {
        Header: 'Views', accessor: 'view', id: 'view', units: false, filter: "includes", aggregate: 'count',
        Header: () => {
          return <Icon icon="eye" />
        },
        Aggregated: ({ cell: { row } }) => {
          let temp = 0
          if (row.groupByID === 'projectName')
            _.forEach(row.subRows, v => {
              _.forEach(v.subRows, v1 => {
                _.forEach(v1.original.users, v2 => {
                  temp = temp + v2.view

                })
              })
            })
          else if (row.groupByID === 'folderName')
            _.forEach(row.subRows, v => {
              _.forEach(v.original.users, v1 => {
                temp = temp + v1.view
              })
            })
          return <> {temp}</>
        },
        Footer: info => {
          const total = React.useMemo(
            () => {
              let total = 0
              _.forEach(info.flatRows, item => {
                if (item.original)
                  _.forEach(item.original.users, v => {
                    total = total + v.view
                  })
              })
              return total
            },
            [info.rows]
          )
          return <> {total}</>
        },
      },
      // { Header: 'Function', accessor: 'function', id: 'function', units: false, filter: "includes" },
    ];
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Button style={{ marginLeft: 15 }} color='blue' active={this.state.activeBtnToday} onClick={this.handleHistoryToday}>Today</Button>
        <RangePicker size='large' style={{ marginTop: 10, marginLeft: 15 }} format={'DD-MM-YYYY'} showTime={{ format: 'HH:mm' }}
          value={[moment(this.state.startDate, 'DD-MM-YYYY'), moment(this.state.endDate, 'DD-MM-YYYY')]}
          onChange={this.onChangeDate} onOk={this.handleRangeDateHistory} />

        <Row gutter={[16, 32]}>
          <Col span={24} style={{ position: 'fixed', height: 'calc(100% - 300px)', width: 'calc(100% - 90px' }}>
            <Card type="inner" style={{ height: '100%', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }}
              bodyStyle={{ padding: 0, position: 'absolute', width: '100%', height: '100%' }} >
              <TableHistory
                columns={columnsListItem}
                data={this.state.dataHistory}
                getRowProps={() => ({})} getCellProps={() => ({})}
                handleOpenControlInformationPanel={this.handleOpenControlInformationPanel}
              />
            </Card>
          </Col>

        </Row>
        {/* <div style={{ marginLeft: 15, bottom: 200, position: 'absolute' }}>
         
        </div> */}

        <Row gutter={[16, 32]}>
          <Col span={18} style={{ bottom: 0, position: 'fixed', height: '220px' }}>
            <Card type="inner" style={{ height: '100%', margin: 15, overflow: 'auto', boxShadow: '3px 4px 13px 0px #bab2b2' }}
              bodyStyle={{ padding: 0, position: 'absolute', width: '100%', height: '100%' }} >
              {this.state.dataChart.length === 1 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
                <Chart
                  height={'calc(100% - 10px)'}
                  chartType="Calendar"
                  loader={<Loader backdrop center content="Loading..." speed="fast" size="md" vertical />}
                  data={this.state.dataChart}
                  options={{
                  }}
                />}
            </Card>
          </Col>
          <Col span={4} style={{ bottom: 20, right: 0, position: 'fixed', height: '200px' }}>
            <DatePicker size='large' mode='year' format={'YYYY'} showTime={{ format: 'HH:mm' }} style={{ marginTop: 20 }} allowClear={false}
              value={this.state.thisYear} onChange={this.handleHistoryYear} />
          </Col>
        </Row>
        <Modal backdrop="static" show={this.state.openInfoPanel} onHide={this.handleCloseInfoPanel.bind(this)} style={{ paddingTop: '3%' }}>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            < List size="small" bordered bordered style={{ overflow: 'auto' }}
              dataSource={this.state.dataInforItem}
              renderItem={item =>
                <List.Item>
                  <Text strong>{item.email}</Text>

                  <Input style={{ width: 100, position: 'absolute', right: 0 }} addonAfter={<Icon icon="eye" />} disabled={true} value={item.view} />
                </List.Item>
              }
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={this.handleCloseInfoPanel.bind(this)} color='red'>
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>

    );
  }
}

UsersControl.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    isConfirmed: !!state.user.confirmed,
    isAuthenticated: !!state.user.email,
    email: state.user.email,
  }
}
export default connect(mapStateToProps)(UsersControl)