import React, { useState, useEffect } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import $ from 'jquery';
import { message, Typography, Upload } from 'antd';
import * as antd from 'antd'
import { Button, Icon, Drawer, DatePicker, TagPicker, Divider, Loader, InputPicker, Tag } from 'rsuite';
import _ from 'lodash'
import moment from 'moment';
import { Menu, Item, Separator, IconFont } from 'react-contexify';
import Dock from 'react-dock'
import { ColumnComment } from '../columns'
import {TableComment} from '../tables'
import { setupForgeExtensionBeforeLoaded } from '../function/ForgeFunction'
import { socket,trackingUserUsing } from '../function/AdminFunction'
import {DialogCommentInformation} from '../dialog'


const { TextArea, } = antd.Input;
const { Text, Title } = Typography


let markUpSelected = null
const Autodesk = window.Autodesk;
function SideBarCommentPanel(props) {

  const [loading, setLoading] = useState(false)
  const [displayPanel, setDisplayPanel] = useState(false)
  const [displayNewCommentPanel, setDisplayNewCommentPanel] = useState(false)
  const [listComment, setListComment] = useState([])
  const [allListComment, setAllListComment] = useState([])
  const [displayInfoComment, setDisplayInfoComment] = useState(false)
  const [guid, setGuid] = useState()
  const [comment, setComment] = useState()
  const [isAssignToMe, setIsAssignToMe] = useState(false)
  const [isShowInView, setIsShowInView] = useState(false)
  const [showStatus, setShowStatus] = useState('All')
  const [listVersion, setListVersion] = useState([])

  useEffect(() => {
    trackingUserUsing(props.email,props.projectId,'BIM App','Comment')
    setLoading(loading)
    setDisplayPanel(true)
    setGuid(props.viewer.impl.model.getDocumentNode().data.guid)
    axios.get("/api/comment/get-comment-unread", { params: { email: props.email } })
      .then(res => {
        let unReadData = res.data
        axios.get("/api/comment/get-item-comments", {
          params: {
            itemId: props.itemId, projectId: props.projectId
          }
        })
          .then(res => {
            if (res.data.length !== 0) {
              let temp = []
              let tempListVersion = []
              _.forEach(res.data, v => {
                let data = v.comment
                if (!data.priority) {
                  data.priority = 'Low'
                }
                tempListVersion.push(data.mainModel.version)
                data.checked = false
                let index = _.findIndex(unReadData, e => { return e.id === data.id })
                if (index >= 0) {
                  data.unReadIssue = !unReadData[index].issueTime ? true : data.dateModified > unReadData[index].issueTime
                  data.unReadComment = !unReadData[index].commentTime ? (data.comments.length === 0 ? false : true) :
                    (data.comments.length === 0 ? false : data.comments[data.comments.length - 1].datetime > unReadData[index].commentTime)
                } else {
                  data.unReadIssue = true
                  data.unReadComment = data.comments.length === 0 ? false : true
                }
                temp.push(data)
              })
              tempListVersion = _.uniq(tempListVersion)
              setListVersion(tempListVersion.sort())
              setListComment(temp)
              setAllListComment(temp)
              setLoading(false)
            } else {
              setListComment([])
              setAllListComment([])
              setLoading(false)
            }
          })
          .catch(() => {
            props.onDisplayPanelChange('commentPanel', false)
          })
      })
      .catch(() => {
        setListComment([])
        setAllListComment([])
        setLoading(false)
      })

  }, [])

  useEffect(() => {
    socket.on('realtime-cp-comment', handleRealtimeIssue)
    socket.on('realtime-delete-cp-comment', handleRealtimeDeleteIssue)
    socket.on('realtime-cp-comment-comment', handleNewComment)
    return () => {
      socket.removeListener('realtime-cp-comment', handleRealtimeIssue)
      socket.removeListener('realtime-delete-cp-comment', handleRealtimeDeleteIssue)
      socket.removeListener('realtime-cp-comment-comment', handleNewComment)
    }
  })
  //#region handle socket
  const handleRealtimeIssue = (data) => {
    let tempAllListSaveView = allListComment.slice(0)
    let tempListSaveView = listComment.slice(0)
    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.comment.id })
    if (index >= 0) {
      tempAllListSaveView.splice(index, 1, data.item.comment)
    } else {
      data.item.comment.unReadIssue = true
      data.item.comment.unReadComment = data.item.comment.comments.length === 0 ? false : true
      tempAllListSaveView.splice(0, 0, data.item.comment)
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.item.comment.id })
    if (index >= 0) {
      tempListSaveView.splice(index, 1, data.item.comment)
    } else {
      data.item.comment.unReadIssue = true
      data.item.comment.unReadComment = data.item.comment.comments.length === 0 ? false : true
      tempListSaveView.splice(0, 0, data.item.comment)
    }
    setListComment(tempListSaveView)
    setAllListComment(tempAllListSaveView)
  }

  const handleRealtimeDeleteIssue = (data) => {
    let tempAllListSaveView = allListComment.slice(0)
    let tempListSaveView = listComment.slice(0)

    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.item.comment.id })
    if (index >= 0) {
      tempAllListSaveView.splice(index, 1)
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.item.comment.id })
    if (index >= 0) {
      tempListSaveView.splice(index, 1)
    }
    setListComment(tempListSaveView)
    setAllListComment(tempAllListSaveView)
  }
  const handleNewComment = (data) => {
    let tempAllListSaveView = allListComment.slice(0)
    let tempListSaveView = listComment.slice(0)
    let index = _.findIndex(tempAllListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempAllListSaveView[index].unReadComment = true
      tempAllListSaveView[index].comments = data.item
    }
    index = _.findIndex(tempListSaveView, e => { return e.id === data.id })
    if (index >= 0) {
      tempListSaveView[index].unReadComment = true
      tempListSaveView[index].comments = data.item
    }
    setListComment(tempListSaveView)
    setAllListComment(tempAllListSaveView)
  }
  //#endregion

  const handleNewCommentCP = () => {
    setDisplayNewCommentPanel(true)
    setDisplayPanel(false)
  }
  const handleCloseViewPanel = () => {
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    markupCore.hide();
    _.forEach(listComment, v => {
      markupCore.unloadMarkups(v.id)
    })
    let btn = $('#btn-markup2d')
    btn.removeClass("active")
    props.onDisplayPanelChange('commentPanel', false)
    props.onChangeDockDisplay('markUp2d', false)
  }
  const handleCloseNewViewPointPanel = (data) => {
    if (data) {
      let clone = Object.assign({}, data);
      clone.dateViewPoint = moment(clone.dateViewPoint).format('DD-MM-YYYY')
      clone.dateModified = moment(clone.dateModified).format('DD-MM-YYYY')

      let tempListSaveView = listComment.slice(0)
      let tempAllSaveView = allListComment.slice(0)
      tempListSaveView.splice(0, 0, data)
      tempAllSaveView.splice(0, 0, data)
      setListComment(tempListSaveView)
      setAllListComment(tempAllSaveView)
      setDisplayNewCommentPanel(false)
      setDisplayPanel(true)
    } else {
      setDisplayNewCommentPanel(false)
      setDisplayPanel(true)
    }
  }
  const handleChangeMarkup = (item, e) => {
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let temp = []
    item.checked = e
    _.forEach(listComment, v => {
      if (v.checked) {
        temp.push(v)
      }
    })
    if (temp.length === 0) {
      markupCore.hide();
    } else {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        } else {
          markupCore.unloadMarkups(v.id)
        }

      })
    }
    setListComment(listComment.slice(0))

    // _.forEach(listComment, v => {
    //   if (v.checked) {
    //     markupCore.show();
    //     return false
    //   }
    // })
    // if (e.target.checked) {
    //   markupCore.loadMarkups(item.markup, item.id)
    // } else {


    // }
    // setListComment(listComment.slice(0))
  }
  const restoreSaveView = (item) => {
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    let document = props.viewer.impl.model.getDocumentNode().getRootNode().lmvDocument
    let view2d = props.viewer.impl.model.getDocumentNode().getRootNode().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true)
    let view = null
    _.forEach(view2d, v => {
      if (v.data.guid === item.guid) {
        view = v
      }
    })
    _.forEach(listComment, v => {
      if (v.guid === item.guid) {
        v.checked = true
      } else {
        v.checked = false
      }
      markupCore.unloadMarkups(v.id)
    })
    if (view !== null) {
      setLoading(true)
      var loadOptions = {
        globalOffset: props.viewer.impl.model.myData.globalOffset,
        // applyRefPoint: true,
        modelNameOverride: props.viewer.impl.model.myData.loadOptions.modelNameOverride,
        isAEC: true,
        guid: props.viewer.impl.model.myData.loadOptions.guid,
        viewableID: props.viewer.impl.model.myData.loadOptions.viewableID,
        itemId: props.viewer.impl.model.myData.loadOptions.itemId,
        version: props.viewer.impl.model.myData.loadOptions.version,
      }
      props.viewer.loadDocumentNode(document, view, loadOptions)
        .then(() => {
          markUpSelected = listComment
          props.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, openMarkUp)
          setLoading(false)
          setGuid(props.viewer.impl.model.getDocumentNode().data.guid)
          setListComment(listComment.slice(0))
          setupForgeExtensionBeforeLoaded(props.viewer, props.viewerPage)
          props.viewer.setEnvMapBackground(18)

        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      message.warning(`View isn't existing in file`)
    }
  }
  const openMarkUp = () => {
    props.viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, openMarkUp)
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
    markupCore.show();
    _.forEach(markUpSelected, v => {
      if (v.checked) {
        markupCore.loadMarkups(v.markup, v.id)
      } else {
        markupCore.unloadMarkups(v.id)
      }

    })
  }
  const handleCloseInfoViewPointPanel = (data) => {
    if (data) {
      setListComment(data)
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    } else {
      setDisplayInfoComment(false)
      setDisplayPanel(true)
    }
  }
  const handleInfoViewPointPanel = (item) => {
    setComment(item)
    setDisplayInfoComment(true)
    setDisplayPanel(false)
  }
  const handleDeleteViewPoint = (item) => {
    if (!window.confirm('Are you sure to delete this comment?')) return
    setLoading(true)
    let temp1 = []
    let tempData = item.id
    _.forEach(listComment, (v) => {
      if (v.id !== item.id) {
        temp1.push(v)
      }
    })
    let temp2 = []
    _.forEach(allListComment, (v) => {
      if (v.id !== item.id) {
        temp2.push(v)
      }
    })
    axios.post(`/api/comment/delete-comment`, { id: tempData, email: props.email })
      .then(() => {
        setListComment(temp1)
        setAllListComment(temp2)
        setLoading(false)
        message.success(`Deleted viewpoint.`);
      })
      .catch(() => {
        setLoading(false)
        message.error(`Can't delete viewpoint.`);
      })

  }
  const handleAssignToMe = () => {
    setIsAssignToMe(!isAssignToMe)
  }
  const handleShowInView = () => {
    setIsShowInView(!isShowInView)
  }
  const handleShowStatus = () => {
    setShowStatus(showStatus !== 'All' ? showStatus !== 'Close' ? 'Close' : 'All' : 'Open')
  }
  const handleVersionMarkUp = (version) => {
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')

    _.forEach(listComment, v => {
      if (v.checked) {
        v.checked = false
        markupCore.unloadMarkups(v.id)
      }
      if (v.guid === props.viewer.impl.model.getDocumentNode().data.guid &&  version.includes(v.mainModel.version)) {
        v.checked = true
      }

    })
    if(version.length===0){
      markupCore.hide()
    }else{
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    }

    setListComment(listComment.slice(0))
  }

  useEffect(() => {
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = [props.email]
        let tempNeed =[]
        _.forEach(res.data.groupUser, v => {
          _.forEach(v.members, member => {
            if (member.name === props.email) {
              temp.push(v.name)
            }
          })
        })
        _.forEach(allListComment, v => {
          let checkAssign = false
          let checkShowInView = false
          let checkStatus =false
          if(isAssignToMe){
            _.forEach(v.listAssignViewPoint, user => {
              if (temp.includes(user)) {
                checkAssign = true
               return false
              }
            })
          }else{
            checkAssign = true
          }
          if (isShowInView) {
            if (v.guid === props.viewer.impl.model.getDocumentNode().data.guid) {
              checkShowInView = true
            }
          }else{
            checkShowInView = true
          }
          if(showStatus ==='All'){
            checkStatus= true
          }else{
            if (showStatus !== 'Close') {
              if (v.status.toLowerCase() !== 'close') {
                checkStatus= true
              }
            } else {
              if (v.status.toLowerCase() === showStatus.toLowerCase()) {
                checkStatus= true
              }
            }
          }
          if(checkAssign && checkShowInView && checkStatus){
            tempNeed.push(v)
          }
        })
        setListComment(tempNeed)
      })
      .catch(() => {
        setIsAssignToMe(false)
      })
  }, [isAssignToMe, isShowInView, showStatus])
  const handleAllCheck = (check) => {
    let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')

    _.forEach(listComment, v => {
      if (v.checked)
        markupCore.unloadMarkups(v.id)
      if (v.guid === props.viewer.impl.model.getDocumentNode().data.guid) {
        v.checked = check
      }
    })
    if (check) {
      markupCore.show();
      _.forEach(listComment, v => {
        if (v.checked) {
          markupCore.loadMarkups(v.markup, v.id)
        }
      })
    } else {
      markupCore.hide()
    }

    setListComment(listComment.slice(0))
  }
  const handleSearch = (value) => {
    if (value === '') {
      setListComment(allListComment.slice(0))
    } else {
      let temp = []
      _.forEach(listComment, v => {
        if (v.descriptionViewPoint.includes(value)) {
          temp.push(v)
        }
      })
      setListComment(temp)
    }
  }
  return (
    <>
      <Dock position={'left'} isVisible={displayPanel} dimMode={'none'} fluid={false} zIndex={200} defaultSize={650}>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <TableComment
          columns={ColumnComment()} data={listComment}
          getRowProps={() => ({})} getCellProps={() => ({})}
          handleNewCommentCP={handleNewCommentCP}
          handleInfoViewPoint={handleInfoViewPointPanel}
          onActiveTable={handleCloseViewPanel}
          restoreSaveView={restoreSaveView}
          handleDeleteViewPoint={handleDeleteViewPoint}
          handleChangeMarkup={handleChangeMarkup}
          handleAllCheck={handleAllCheck}
          handleAssignToMe={handleAssignToMe}
          handleShowInView={handleShowInView}
          isAssignToMe={isAssignToMe}
          isShowInView={isShowInView}
          viewer={props.viewer}
          isProject={false}
          newTab={false}

          itemId={props.itemId}
          guid={guid}

          showStatus={showStatus}
          handleShowStatus={handleShowStatus}
          handleVersionMarkUp={handleVersionMarkUp}
          listVersion={listVersion}
          handleSearch={handleSearch}
        />

      </Dock>
      {/* <Drawer backdrop={false} show={displayPanel} onHide={handleCloseViewPanel} keyboard={false}
        placement='left' size='sm' className='drawerBimAppPanel'>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Drawer.Header  >
          <Drawer.Title>
            <Title style={{ display: 'inline', marginLeft: '5px' }} level={4} >Comment</Title>
            <ButtonGroup style={{ float: "left", marginBottom: '5px' }}>
              <Tooltip title="New Comment">
                <IconButton size="xs" loading={loading} onClick={handleNewCommentCP} disabled={!props.viewer.impl.model || !props.viewer.impl.model.is2d()}
                  icon={<Icon icon="plus-square" />} ></IconButton>
              </Tooltip>
            </ButtonGroup>
          </Drawer.Title>

        </Drawer.Header>
        <Divider />
        <Drawer.Body >

          <div style={{ overflow: 'auto' }}>
            <List
              loading={loading}
              bordered
              size="small"
              dataSource={listComment}
              renderItem={item => {
                let today = moment(Date.now())
                let dateViewPoint = moment(item.dateViewPoint)
                let dateModified = moment(item.dateModified)
                return (
                  <MenuProvider id='cp_comment_menu' animation={animation.zoom} data={{ item: item }}>
                    <List.Item key={item.id} style={{
                      cursor: 'pointer', backgroundColor: item.status === "Close" ? '#f5fff5' : dateViewPoint < today && '#ffe8ed',
                      borderLeft: item.unReadIssue && '2px black solid'
                    }}>
                      <>
                        <List.Item.Meta style={{ overflow: 'hidden', height: 50 }}
                          value={item.id}
                          onDoubleClick={handleInfoViewPointPanel.bind(this, item, item.mainModel.itemId === props.itemId ? false : true)}
                          title={
                            <>
                              <Text code disabled={item.mainModel.itemId} style={{ color: item.mainModel.itemId !== props.itemId ? '#c6ccd2' : 'black', marginRight: 3 }}>Description:</Text>
                              <Tooltip title={(
                                <Text style={{ whiteSpace: 'pre-wrap', color: 'white' }}> {item.descriptionViewPoint}</Text>
                              )} placement="right" mouseEnterDelay={2}>
                                <Text type="secondary" style={{
                                  position: 'absolute', width: 'calc(100% - 170px)',
                                  color: item.mainModel.itemId !== props.itemId ? '#c6ccd2' : 'gray'
                                }} ellipsis={true}>
                                  {` ${item.descriptionViewPoint}`}</Text>
                              </Tooltip>

                            </>
                          }
                          description={
                            <>
                              <Icon style={{ color: item.unReadComment && 'red' }} icon='reply'>{' '}{`(${item.comments.length})`}</Icon>
                              <Divider vertical className='custom-divider-issue' />
                              <Icon icon='link' style={{ transform: 'translateY(1px)' }}>{' '}{`(${item.attachFile.length})`}</Icon>
                              <Divider vertical className='custom-divider-issue' />
                              <Tooltip title={dateModified.format('DD-MM-YYYY HH:mm')} placement='top'>
                                <Icon icon='clock-o' style={{ fontSize: 16, transform: 'translateY(2px)' }}>
                                  {' '}{dateModified.format('DD-MM-YYYY') === today.format('DD-MM-YYYY')
                                    ? dateModified.format('HH:mm') : dateModified.format('DD-MM-YYYY')}</Icon>
                              </Tooltip>
                            </>

                          }

                        />
                        <Divider className='divide-vertical-list-view-point' vertical />
                      </>

                      <Checkbox style={{ textAlignLast: 'center' }} checked={item.checked} disabled={guid !== item.guid}
                        onChange={handleChangeMarkup.bind(this, item)}></Checkbox>

                    </List.Item>
                    <Divider className='divide-list-view-point' />
                  </MenuProvider>
                )
              }
              }
            >

            </List>

          </div>
        </Drawer.Body>
        <Divider />
      </Drawer> */}

      {displayNewCommentPanel &&
        <NewViewPointPanel
          open={displayNewCommentPanel}
          handleClose={handleCloseNewViewPointPanel}
          viewer={props.viewer}
          itemId={props.itemId}
          listSaveView={listComment}
          email={props.email}
          version={props.version}
          projectId={props.projectId}
          userCreatedProject={props.userCreatedProject}
          projectKey={props.projectKey}
        />}
      {displayInfoComment &&
        <DialogCommentInformation
          open={displayInfoComment}
          handleClose={handleCloseInfoViewPointPanel}
          viewer={props.viewer}
          viewPoint={comment}
          email={props.email}
          projectId={props.projectId}
          userCreatedProject={props.userCreatedProject}
          listSaveView={listComment}
          // handleAttachFile={this.handleAttachFile}
          itemId={props.itemId}
          isProject={false}
          newTab={false}
          restoreSaveView={restoreSaveView}
          guid={guid}
        />
      }
      <Menu id='cp_comment_menu' style={{ zIndex: 10000 }}>
        <Item onClick={restoreSaveView.bind(this)}>
          <IconFont className="fas fa-check-double" />Load View
                </Item>
        <Separator />
        <Item onClick={handleDeleteViewPoint} >
          <IconFont className="fas fa-trash" />Delete
                  </Item>
      </Menu>
    </>

  );
}
SideBarCommentPanel.propTypes = {

}

function mapStateToProps() {
  return {

  }
}
export default connect(mapStateToProps)(SideBarCommentPanel)

const priorityOptions = [
  { label: 'Very urgent', text: 'Very urgent', value: 'Very urgent', color: 'red' },
  { label: 'Urgent', text: 'Urgent', value: 'Urgent', color: 'orange' },
  { label: 'Normal', text: 'Normal', value: 'Normal', color: '#f4d90b' },
  { label: 'Low', text: 'Low', value: 'Low', color: 'blue' },
]

function NewViewPointPanel(props) {
  const [loading, setLoading] = useState(false)
  const [dateViewPoint, setDateViewPoint] = useState(moment(Date.now()).add(14, "days"))
  const [descriptionViewPoint, setDescriptionViewPoint] = useState('')
  const [listAssignViewPoint, setListAssignViewPoint] = useState([])
  const [listMember, setListMember] = useState([])
  const [mainModel, setMainModel] = useState({})
  const [otherModel, setOtherModel] = useState([])

  const [priority, setPriority] = useState('Very urgent')
  const [attachFile, setAttachFile] = useState([])
  const [loadingAttach, setLoadingAttach] = useState(false)

  useEffect(() => {
    setLoading(true)
    handleModelCapture()
    //? member
    axios.get(`/api/projects/members?q=${props.projectId}`)
      .then(res => {
        let temp = []
        res.data.members.sort()
        res.data.members.splice(0, 0, props.userCreatedProject)
        _.forEach(res.data.members, v => {
          if (v !== props.email)
            temp.push({
              label: v,
              value: v,
              role: 'user'
            })
        })
        _.forEach(res.data.groupUser, v => {
          temp.push({
            label: v.name,
            value: v.name,
            role: 'group'
          })

        })
        setListMember(temp)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const handleModelCapture = () => {
    const models = props.viewer.impl.modelQueue().getModels()
    let tempOtherModel = []
    _.forEach(models, v => {
      if (v.myData.loadOptions.itemId === props.itemId) {
        setMainModel({
          itemId: v.myData.loadOptions.itemId, guid: props.viewer.impl.model.getDocumentNode().data.guid, urn: props.viewer.impl.model.myData.urn,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      } else {
        tempOtherModel.push({
          itemId: v.myData.loadOptions.itemId, guid: v.myData.loadOptions.guid,
          version: v.myData.loadOptions.version, name: v.myData.loadOptions.modelNameOverride
        })
      }
    })
    setOtherModel(tempOtherModel)
  }
  const handleClose = () => {
    props.handleClose(null)
  }

  const handleDescriptionChange = (e) => {
    setDescriptionViewPoint(e.target.value)
  }
  const handleDateChange = (date) => {
    setDateViewPoint(date)
  }
  const handleAssignChange = (value) => {
    setListAssignViewPoint(value)
  }

  const handleSaveViewPoint = () => {
    try {
      let markupCore = props.viewer.getExtension('Autodesk.Viewing.MarkupsCore')
      let markupGeneratedForLeaflet = markupCore.generateData()
      if (listAssignViewPoint.length === 0)
        if (!window.confirm(`Issue aren't assign anyone. Are you sure to continue ?`)) return
      if (descriptionViewPoint !== '' && dateViewPoint !== null) {
        setLoading(true)
        handleModelCapture()
        let date = moment(dateViewPoint).valueOf()
        let today = moment(Date.now()).format('DD-MM-YYYY')
        let node = props.viewer.impl.model.getDocumentNode()
        let data = {
          id: '',
          descriptionViewPoint: descriptionViewPoint,
          dateViewPoint: date,
          listAssignViewPoint: listAssignViewPoint,
          status: 'Open',
          priority: priority,
          userCreated: props.email,
          dateCreated: today,
          dateModified: today,
          userModified: props.email,
          mainModel: mainModel,
          otherModel: otherModel,
          guid: node.data.guid,
          viewableID: node.data.viewableID,
          attachFile: [],
          comments: [],
          camera: { globalOffset: props.viewer.model.getData().globalOffset, up: props.viewer.getCamera().up },
          markup: markupGeneratedForLeaflet,
          viewport: props.viewer.getState()
        }
        let path = `${window.location.origin}`
        if (attachFile.length === 0) {
          axios.post(`/api/comment/save-new-comment`, {
            projectId: props.projectId, itemId: props.itemId, projectKey: props.projectKey, comment: data,
            baseUrl: path
          })
            .then(res => {
              if (listAssignViewPoint.includes(props.email)) {
                setLoading(false)
                props.handleClose(res.data, true)
              }
              else {
                props.handleClose(res.data, false)
                setLoading(false)
              }

            })
            .catch(() => {
              setLoading(false)
              message.error(`Can't create new issue.`);
            })
        } else {
          let formData = new FormData()
          _.forEach(attachFile, f => {
            formData.append('files', f.file)
          })
          formData.append('projectId', props.projectId)
          formData.append('itemId', props.itemId)
          formData.append('projectKey', props.projectKey)
          formData.append('comment', JSON.stringify(data))
          formData.append('baseUrl', path)
          axios.post(`/api/comment/save-new-comment-with-files`, formData, { timeout: 60 * 10 * 1000 })
            .then(res => {
              if (listAssignViewPoint.includes(props.email)) {
                setLoading(false)
                props.handleClose(res.data, true)
              }
              else {
                props.handleClose(res.data, false)
                setLoading(false)
              }
              message.success(`Create new issue.`);
            })
            .catch(() => {
              setLoading(false)
              message.error(`Can't create new issue.`);
            })
        }
      }
      else {
        setLoading(false)
        message.error(`You need input`);
      }
    } catch {
      message.warning('You need to markup')
    }

  }



  const handleCustomRequest = ({ file }) => {
    if (file !== null) {
      setLoadingAttach(true)
      let temp = attachFile.slice()
      let index = _.findIndex(temp, e => { return e.name === file.name })
      if (index < 0) {
        temp.push({ uid: temp.length + 1, name: file.name, url: '', status: 'done', file: file })
      } else {
        temp[index].file = file
      }
      setAttachFile(temp)
      setLoadingAttach(false)
    }
  }
  const handleRemoveFile = (file) => {
    if (!window.confirm('Are you sure to remove this file?')) return
    setLoadingAttach(true)
    let temp = attachFile.slice()
    let index = _.findIndex(temp, e => { return e.name === file.name })
    if (index >= 0)
      temp.splice(index, 1)
    setAttachFile(temp)
    setLoadingAttach(false)
  }
  const beforeUpload = (file) => {
    const isImgIssue = file.name.toLowerCase().includes(props.projectKey.toLowerCase())
    if (isImgIssue) {
      message.warning('Please change name your image');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.warning('Image must smaller than 20MB!');
    }
    return isLt2M && !isImgIssue;
  }


  const handlePriority = (value) => {
    setPriority(value)
  }


  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleClose} keyboard={false}
        placement='left' size='xs' className='drawerBimAppPanel'>
        <Drawer.Header>
          <Drawer.Title>
            <Title style={{ display: 'inline', marginLeft: '5px' }} level={4} >New Issue</Title>
          </Drawer.Title>
        </Drawer.Header>
        <Divider />
        <Drawer.Body>

          <div style={{ position: 'relative' }}>

            <Text strong>Description (required)</Text>
            <TextArea onChange={handleDescriptionChange} size='small' style={{ marginBottom: '1em' }} autoSize={{ minRows: 3 }} />

            <Text strong>Due date</Text>
            <DatePicker style={{ width: '100%', marginBottom: '1em' }} format="DD-MM-YYYY" onChange={handleDateChange} size="sm" placement='rightEnd'
              value={dateViewPoint} cleanable={false}
              ranges={[
                {
                  label: '1D',
                  value: moment(Date.now()).add(1, 'day')
                },
                {
                  label: '1W',
                  value: moment(Date.now()).add(1, 'week')
                },
                {
                  label: '2W',
                  value: moment(Date.now()).add(2, 'weeks')
                },
                {
                  label: '4W',
                  value: moment(Date.now()).add(4, 'weeks')
                },
              ]} />

            <Text strong>Priority</Text>
            <InputPicker
              data={priorityOptions} size='sm' cleanable={false}
              placeholder="Select Priority" onChange={handlePriority}
              value={priority} placement='rightEnd'
              style={{ marginBottom: '1em' }} block
              renderMenuItem={(label, item) => {
                return (
                  <div >
                    <i className="rs-icon rs-icon-exclamation-triangle" style={{ color: item.color }} /> {label}
                  </div>
                );
              }}
              renderValue={(value, item) => {
                return (
                  <div >
                    <i className="rs-icon rs-icon-exclamation-triangle" style={{ color: item.color }} /> {value}
                  </div>
                );
              }}
            />

            <Text strong>Assignees</Text>
            <TagPicker data={listMember} style={{ width: '100%', marginBottom: '1em' }} onChange={handleAssignChange} placement='rightEnd' size="sm" groupBy="role"
              renderMenu={menu => {
                if (loading) {
                  return (
                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                      <Icon icon="spinner" spin /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
              renderMenuItem={(label, item) => {
                return (
                  <div>
                    {/* <i className={item.role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} */}
                    <Icon icon={item.role === 'user'?'user':'group'} /> {label}
                  </div>
                );
              }}
              renderMenuGroup={(label, item) => {
                return (
                  <div>
                    {/* <i className={item.children[0].role === 'user' ? "rs-icon rs-icon-user" : "rs-icon rs-icon-group"} /> {label} - (
                    {item.children.length}) */}
                    <Icon icon={item.children[0].role === 'user'?'user':'group'} />  {label} - (
                    {item.children.length})
                  </div>
                );
              }}
              renderValue={(values, items, tags) => {
                return items.map((tag, index) => (
                  <Tag style={{ color: tag.role === 'user' ? '#8ab4f8' : '#ff7171' }}>
                        <Icon icon={tag.role === 'user'?'user':'group'} /> {tag.label}
                    </Tag> 
                ));
              }}
            />

            <Text strong> Attachments</Text>
            <Upload name='file'
              multiple={false}
              showUploadList={true}
              className='upload-attachments'
              customRequest={handleCustomRequest} fileList={attachFile} onRemove={handleRemoveFile} beforeUpload={beforeUpload} >
              <Button appearance="ghost" block loading={loadingAttach}>
                <Icon icon="link" /> Upload
          </Button>
            </Upload>


          </div>

        </Drawer.Body>
        <Divider />
        <Drawer.Footer style={{ marginBottom: 5 }}>
          <Button onClick={handleSaveViewPoint} appearance="primary" loading={loading} >
            Create
    </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
    </Button>
        </Drawer.Footer>
      </Drawer>


    </>
  )
}

