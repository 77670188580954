
const Autodesk = window.Autodesk;

export default class CustomProperties extends Autodesk.Viewing.UI.PropertyPanel {

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  constructor(viewer, options) {

    super(viewer.container, options.id, options.title, {
      addFooter: false,
      viewer
    })

    this.container.style.height = "100%";
    this.container.style.width = "auto";
    this.container.style.resize = "both";
  }


  initialize() {

    super.initialize()
    let input = document.createElement("INPUT");
    input.id ='check-box-custom-properties'
    input.setAttribute("type", "checkbox")
    input.checked = true
    input.style.height='50px'
    input.style.width ='15px'
    input.style.top ='0px'
    input.style.right ='35px'
    input.style.zIndex =1
    input.style.position = 'absolute'

    this.container.appendChild(input)
 

    this.viewer = this.options.viewer

    this.footer = this.createFooter()

    this.container.appendChild(
      this.footer)
  }

}

