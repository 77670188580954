import React, { useState, useEffect } from "react";
import axios from "axios";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import { message } from "antd";

import concInvTempColumns from "../../function/DefineColumns/concInvTempColumns";

const TableConcInvTemp = ({ tableRef, generalState, tempData, setTempData }) => {
   useEffect(() => {
      if (tempData.length > 0) {
         tempData.map(async (e) => {
            var checks = "";
            const res1 = await axios.get("/api/do_inv/common/checkexist", {
               params: { do_number: e.do_number, current: "conc_inv" },
            });

            if (res1.data.data.code !== undefined) {
               checks = checks + res1.data.data.code;
            }

            if (isNaN(e.unit_price)) {
               checks = checks + "unit price ";
            } else {
               if (e.unit_price < 0.5) {
                  checks = checks + "unit price ";
               }
            }

            if (isNaN(e.amount_claim)) {
               checks = checks + "amount ";
            } else {
               if (escape.amount_claim < 0.5) {
                  checks = checks + "amount ";
               }
            }

            if (isNaN(e.volume)) {
               checks = checks + "volume";
            } else {
               if (e.volume < 0.05) {
                  checks = checks + "volume";
               }
            }

            if (checks.length < 1) {
               checks = "ok";
            }
            e.checks = checks;
         });
      }
   }, [generalState]);

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 750,
      columns: concInvTempColumns,
      colWidths: [85, 100, 80, 100, 90, 100, 175, 90, 90, 90, 90, 100, 175],
      stretchH: "last",
      currentRowClassName: "currentRow",
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      const parentElement = TD.parentElement;
      if (parentElement === null) {
         return;
      }

      if (tableRef !== undefined && row === 0 && col === 0) {
         if (tableRef.current !== null) {
            const hotTable = tableRef.current.hotInstance;
            const temp_data = hotTable.getData();
            for (let i = 0; i < temp_data.length; i++) {
               if (temp_data[i][0] !== null) {
                  if (temp_data[i][11].includes("exist")) {
                     for (let j = 0; j < 11; j++) {
                        hotTable.setCellMeta(i, j, "className", "custom-cell-checks-exist");
                     }
                  }
                  if (temp_data[i][11] !== "ok" && !temp_data[i][11].includes("exist")) {
                     for (let j = 0; j < 12; j++) {
                        hotTable.setCellMeta(i, j, "className", "custom-cell-checks-issue");
                     }
                  }
               }
            }
         }
      }
   };

   const onBeforeHotChange = (changes) => {
      if (changes === null) {
         return;
      }

      if (tableRef.current !== null) {
         const hot = tableRef.current.hotInstance;
         changes.forEach(([row, column, oldValue, newValue]) => {
            const r = hot.toPhysicalRow(row);
            tempData[r].change = true;
            tempData[r][column] = newValue;
         });
         setTempData(tempData);
      } else {
         message.warn("Sorry changed data will not able to save!");
      }
   };
   return (
      <div>
         <HotTable
            ref={tableRef}
            settings={hotSettings}
            data={tempData}
            afterRenderer={addClassesToRows}
            beforeChange={onBeforeHotChange}
            className="do-invoice-table handsontable_do"
         />
      </div>
   );
};

export default TableConcInvTemp;
