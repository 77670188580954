import React, { useEffect, useState } from 'react';
import { Button, IconButton, ButtonGroup, Icon, Whisper, Popover, CheckPicker, Checkbox, DatePicker } from 'rsuite';
import { Input } from 'antd';

import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import _, { split } from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,

} from 'react-table'
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import moment from 'moment'
import { ListValueFilter,MinMaxFilter} from '../function/TableFunction'
const { Search } = Input;
function TableComment({ columns, data,


  onActiveTable,
  handleInfoViewPoint,
  handleNewCommentCP,
  restoreSaveView,
  isProject,
  handleChangeMarkup,
  handleAssignToMe,
  handleShowInView,
  handleShowStatus,
  isShowInView,
  isAssignToMe,
  handleDeleteViewPoint,
  handleAllCheck,
  showStatus,
  itemId,
  guid,
  viewer,
  newTab,
  handleVersionMarkUp,
  listVersion,
  handleSearch,
  isDoubleClick,
  callbackDoubleClick, onChangeHeader
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter.bind(this, onChangeHeader),
      MinMaxFilter: MinMaxFilter
    }),
    []
  )
  const [version, setVersion] = useState([])
  const [checkTotal, setCheckTotal] = useState(false)
  useEffect(() => {
    if (viewer && !isProject) {
      setVersion(listVersion)
    }
  }, [viewer, listVersion])


  const showMarkup = (item, e, checked) => {

    handleChangeMarkup(item, e.target.checked)
  }
  const handleCheckTotal = (e) => {
    setCheckTotal(e.target.checked)

  }
  useEffect(() => {
    if (!isProject && viewer)
      handleAllCheck(checkTotal)
  }, [checkTotal])
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },

    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      {
        !isProject &&
          hooks.flatColumns.push(columns => {
            return [
              {
                id: 'showMarkup',
                groupByBoundary: true,
                collapse: true,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                Header: ({ }) => (
                  <>
                    {/* <ButtonGroup >
                      <Tooltip title="Check All" placement="bottom">
                        <IconButton size="xs" icon={<Icon icon='check' />} onClick={handleAllCheck.bind(this, true)} />
                      </Tooltip>
                      <Tooltip title="Check None" placement="bottom">
                        <IconButton size="xs" icon={<Icon icon='close' />} onClick={handleAllCheck.bind(this, false)} />
                      </Tooltip>
                    </ButtonGroup> */}
                    <input type='checkbox' disabled={!viewer.impl.model || !viewer.impl.model.is2d()} checked={checkTotal}
                      onChange={handleCheckTotal} />
                  </>
                ),
                Cell: ({ row }) => (
                  <>
                    {!row.isGrouped &&
                      <input type='checkbox' size="xs" checked={row.original.checked} disabled={guid !== row.original.guid}
                        onChange={showMarkup.bind(this, row.original)} />
                    }

                  </>
                ),
              },
              ...columns,
            ]
          })
      }
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'infoViewPoint',
            groupByBoundary: true,
            collapse: true,
            minWidth: 15000,
            width: 100,
            maxWidth: 100,
            Header: ({ }) => (
              <></>
            ),
            Cell: ({ row }) => (
              <>
                {(!row.isGrouped) &&
                  <ButtonGroup>
                    <IconButton size="xs" icon={<Icon icon="info" />} onClick={() =>
                      handleInfoViewPoint(row.original, row.original.mainModel.itemId === itemId ? false : true)}  ></IconButton>
                    {!isProject &&
                      <>
                        {!newTab && <IconButton size="xs" icon={<Icon icon='folder-open' />} disabled={guid === row.original.guid} onClick={() =>
                          restoreSaveView(row.original)}  ></IconButton>}
                        <IconButton size="xs" icon={<Icon icon='trash' />} onClick={() =>
                          handleDeleteViewPoint(row.original)}  ></IconButton>
                      </>}
                  </ButtonGroup>}
              </>
            ),
          },
          ...columns,
        ]
      })

    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      pageIndex, pageSize, groupBy
    }
  } = instance
  const columnCount = columns.length



  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { value }) => {
    setPageSize(value)
  }
  const handleVersion = (i) => {
    let temp = version.slice(0)
    let index = _.findIndex(temp, v => { return v === i })
    if (index >= 0) {
      temp.splice(index, 1)
    } else {
      temp.push(i)
    }
    temp = temp.sort()
    setVersion(temp)
    handleVersionMarkUp(temp)
  }
  const handleDoubleClick = (row) => {
    if (isDoubleClick) {
      callbackDoubleClick(row)
    }
  }
  return (
    <div>
      <ButtonGroup style={{ position: 'absolute', zIndex: '1' }}>
        {(!isProject && !newTab) &&
          <>
            <Tooltip title="Create new comment" placement="bottom">
              <IconButton size="sm" icon={<Icon icon="plus-square" />} onClick={() => handleNewCommentCP()}
                disabled={!viewer.impl.model || !viewer.impl.model.is2d()} ></IconButton>
            </Tooltip>
          </>
        }
        <Tooltip title="Shown only comments assign to me" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="user-circle-o" />} onClick={() => handleAssignToMe()} color={isAssignToMe && 'blue'} ></IconButton>
        </Tooltip>
        {(!isProject && !newTab) && <Tooltip title="Shown only comments in this drawing" placement="bottom">
          <IconButton size="sm" icon={<Icon icon='views-authorize' />} onClick={() => handleShowInView()} color={isShowInView && 'blue'} ></IconButton>
        </Tooltip>}
        <Tooltip title="Shown only comments with status" placement="bottom">
          <Button size="sm" onClick={() => handleShowStatus()}  >{showStatus}</Button>
        </Tooltip>
        {(!isProject && !newTab) && <Whisper placement='bottomLeft' trigger="click" speaker={
          <Popover trigger="click">
            <ButtonGroup>
              {listVersion.map(i =>
                <Button size="sm" onClick={handleVersion.bind(this, i)} disabled={!viewer.impl.model || !viewer.impl.model.is2d()}
                  color={version.includes(i) && 'blue'} >{i}</Button>
              )}
            </ButtonGroup>
          </Popover>
        }>
          <Tooltip title="Shown only comments on specific version" placement="bottom">
            <Button size="sm"   >V</Button>
          </Tooltip>
        </Whisper>}
        <Search
          placeholder="Search Description"
          onSearch={value => handleSearch(value)}
          style={{ width: 200, marginLeft: 5 }}
        />
        {/* <Tooltip title="Find Issue" placement="bottom">
          <IconButton size="sm" icon={<Icon icon="search" />} onClick={() => handleFindIssuePanel()} ></IconButton>
        </Tooltip> */}
      </ButtonGroup>

      {/* <div style={{ left: 65, position:'absolute' }}>
              <ControlGroup fill={true} vertical={false}>
                <HTMLSelect options={["All", "Label", "Title", "Description", "Assigned to me"]}  />
                <InputGroup placeholder="Search" />
                <BlueprintButton icon="search" intent="primary" 
                  />
              </ControlGroup>
            </div> */}
      <div style={{ float: 'right' }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />

     {onActiveTable&&   <ButtonGroup style={{ right: 0, top: 0, cursor: 'pointer', backgroundColor: '#ffffff00', zIndex: '1', marginLeft: '15px' }}>
          <IconButton size="xs" icon={<Icon icon="close" />} onClick={() => onActiveTable()}></IconButton>
        </ButtonGroup>}
      </div>


      <div style={{ position: 'absolute', top: 35, overflow: 'auto', bottom: 0, borderBottomStyle: 'solid', borderTopStyle: 'solid', borderWidth: 'thin', width: '100%' }}>
        <Table celled selectable
          id="tableViewPoint" {...getTableProps()} >
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center', zIndex:100,
                    minWidth: (column.id === 'listAssignViewPoint') ? 40 : column.id === 'infoViewPoint' && 10,
                    maxWidth: (column.id === 'listAssignViewPoint') ? 200 : column.id === 'infoViewPoint' && 100,
                    width: column.id === 'showMarkup' ? 40 : column.id === 'infoViewPoint' ? isProject?30 :90:column.id === 'listAssignViewPoint'?200:
                      (column.id === 'id') ? 80 :
                        (column.id === 'userCreated' || column.id === 'status' || column.id === 'priority') ? 25 : (column.id === 'dateModified' || column.id === 'dateViewPoint') ? 100 :
                          'auto'
                  }}>
                    {/* {!column.isGrouped && ((column.id === 'userCreated') && column.toggleGroupBy())} */}
                    {/* {(column.id !== 'infoViewPoint' && column.id !== 'showMarkup' &&column.id !== 'titleViewPoint' && column.id !== 'descriptionViewPoint' && column.id !== 'id') &&
                      <span {...column.getGroupByToggleProps()}  >
                        {column.isGrouped ? <RemoveCircleOutlineIcon style={{ color: 'red', fontSize: '15px', verticalAlign: 'sub' }} />
                          : <AddCircleOutlineIcon style={{ color: 'green', fontSize: '15px', verticalAlign: 'sub' }} />}
                      </span>} */}
                    {/* {column.render('Header')} */}
                    {!column.isGrouped ?
                      <span {...column.getSortByToggleProps()}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownwardOutlinedIcon />
                            : <ArrowUpwardOutlinedIcon />
                          : ''}
                        {column.render('Header')}
                      </span> :
                      column.render('Header')}
                    <div>{(column.canFilter && column.id !== 'descriptionViewPoint' &&
                      column.id !== 'listAssignViewPoint') ? column.id === 'dateViewPoint' ? column.render('MinMaxFilter') : column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps())} onDoubleClick={handleDoubleClick.bind(this, row)}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps())} style={{
                        textAlign: cell.column.id === 'descriptionViewPoint' ?'left':'center', verticalAlign: 'middle',
                        whiteSpace: cell.column.id === 'listAssignViewPoint' && 'break-spaces'
                      }} >
                        {cell.isGrouped ? (
                          <>
                            <span {...row.getExpandedToggleProps()}>
                              {row.subRows.length !== 0 &&
                                ((groupBy[groupBy.length - 1] !== cell.column.id || columnCount - groupBy.length > 1) &&
                                  (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" style={{ float: 'left' }} /> :
                                    <KeyboardArrowRightIcon fontSize="small" style={{ float: 'left' }} />))
                              }
                            </span>{' '}
                            {cell.render('Cell')}

                          </>
                        ) : cell.isAggregated ? (

                          cell.render('Aggregated')
                        ) : cell.isRepeatedValue ? null : (
                          cell.render('Cell')

                        )}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>

        </Table>

      </div>
      {/* {(!disableLoadMore && data.length !== 0) &&
        <div
          style={{
            position: 'absolute',
            marginTop: 6,
            marginBottom: 6,
            lineHeight: '32px',
            left: 'calc(50% - 44.5px)',
            bottom: 0
          }}
        >
          <Button appearance="ghost" loading={loadingMore} onClick={handleLoadMore} size='xs'>loading more</Button>
        </div>} */}
    </div>
  )
}

export default TableComment