import { Icon } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiFetchCellHistoryThisProject } from '../../../api';
import { colorType } from '../../../constants';
import { convertHistoryData } from '../../../utils';
import ButtonStyle from '../generalComponents/ButtonStyle';
import PanelCalendarDuration from '../generalComponents/PanelCalendarDuration';




const FormCellColorizedCheck = ({ setCellHistoryArr, stateProject, onClickCancelModal }) => {

   const [historyData, setHistoryData] = useState(null);

   const { projectId, token } = stateProject.allDataOneSheet;
   const headersArr = stateProject.allDataOneSheet.publicSettings.headers;

   useEffect(() => {
      const fetchProjectHistory = async () => {
         try {
            const res = await apiFetchCellHistoryThisProject({ token, projectId });
            setHistoryData(res.data);
         } catch (err) {
            console.log(err);
         };
      };
      fetchProjectHistory();

      return () => setHistoryData(null);
   }, []);





   const [dateRange, setDateRange] = useState(null);
   const onClickCheck = ({ start, end }) => {
      let filterCells = convertHistoryData(historyData).filter(cell => {
         let createdAt = moment(cell.createdAt).toDate();
         return createdAt >= start && createdAt <= end;
      });

      let cellArr = [];
      filterCells.forEach(ch => {
         const { row: rowId, headerKey } = ch;
         let headerFound = headersArr.find(hd => hd.key === headerKey);
         if (headerFound) {
            cellArr.push({
               rowId,
               header: headerFound.text
            });
         };
      });

      let unique = cellArr.reduce((res, itm) => {
         let result = res.find(item => JSON.stringify(item) == JSON.stringify(itm));
         if (!result) return res.concat(itm);
         return res;
      }, []);

      setCellHistoryArr(unique);
   };
   const checkCellWithinDates = (nos) => {
      const addDays = (date, days) => {
         let result = new Date(date);
         result.setDate(result.getDate() + days);
         return result;
      };
      let today = new Date();
      let dateBefore = addDays(today, nos);
      return {
         start: dateBefore,
         end: today
      };
   };


   return (
      <div style={{ padding: 15 }}>
         {historyData ? (
            <>
               <div style={{ display: 'flex', marginBottom: 10 }}>
                  <PanelCalendarDuration pickRangeDate={(e) => setDateRange(e)} />
                  <ButtonStyle
                     onClick={() => dateRange && onClickCheck({ start: dateRange[0], end: dateRange[1] })}
                     marginLeft={5}
                     name='Check Data Changed'
                  />
               </div>
               <div style={{ display: 'flex', marginBottom: 20, width: '100%' }}>
                  <ButtonStyle marginRight={5} name='Today'
                     onClick={() => onClickCheck(checkCellWithinDates(-1))}
                  />
                  <ButtonStyle marginRight={5} name='Last 3 Days'
                     onClick={() => onClickCheck(checkCellWithinDates(-3))}
                  />
                  <ButtonStyle marginRight={5} name='Last 7 Days'
                     onClick={() => onClickCheck(checkCellWithinDates(-7))}
                  />
                  <ButtonStyle marginRight={5} name='Last 14 Days'
                     onClick={() => onClickCheck(checkCellWithinDates(-14))}
                  />
                  <ButtonStyle marginRight={5} name='This Month'
                     onClick={() => onClickCheck(checkCellWithinDates(-31))}
                  />
               </div>

               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonStyle
                     colorText='white'
                     borderColor={colorType.grey1}
                     background={colorType.primary}
                     onClick={onClickCancelModal}
                     name={'Close'}
                  />
               </div>
            </>
         ) : (
            <div style={{ height: 124, display: 'flex', justifyContent: 'center', paddingTop: 35 }}>
               <Icon type='loading' style={{ fontSize: 30 }} />
            </div>
         )}

      </div>
   );
};

export default FormCellColorizedCheck;

