import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Segment } from 'semantic-ui-react'
import { Modal, Divider, Input, Button, Loader } from 'rsuite';
import _ from 'lodash'
import { Typography, Row, Col, Upload, message, Icon, Button as ButtonAnt } from 'antd';
import axios from 'axios'
import decode from 'jwt-decode';
import { storageUser } from '../function/PageFunction'
import { refreshForgeToken3Legged } from '../function/ForgeFunction'
import { checkWohhupUser } from '../function/AdminFunction'
import * as actions from "../../../actions/app/auth";
const { Text } = Typography;


function DialogUser(props) {
  const [userInfo, setUserInfo] = useState({
    userName: '',
    title: '',
    department: '',
    company: '',
    phone: '',
  })
  const [userAutodesk, setUserAutodesk] = useState(null)
  const [imgUrl, setImgUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [autodeskLoading, setAutodeskLoading] = useState(true)
  useEffect((e) => {
    setLoading(true)
    let payload = decode(props.user.token)
    axios.post('/api/users/get-me-information', { token: props.user.token, email: props.user.email })
      .then(res => {
        localStorage.bookwormJWT = res.data.user.token
        payload = decode(res.data.user.token)
        setEmail(payload.email)
        storageUser(res.data.user)
        setUserInfo(res.data.userInfo)
        try {
          window.client
            .api('/me/photo/$value')
            .get((err1, res1) => {
              if (err1) return
              const url = window.URL || window.webkitURL;
              const blobUrl = url.createObjectURL(res1);
              setImgUrl(blobUrl)
            })
        } catch { }
        //setImgUrl(res.data.userInfo.imgUrl)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        props.logout(payload.email)
      })
    if (checkWohhupUser(props.user.email)) {
      setAutodeskLoading(true)
      refreshForgeToken3Legged(props.user.token)
        .then(res => {
          if (res) {
            axios.get('/api/forge/user/profile', { params: { email: props.user.email } })
              .then(res => {
                setUserAutodesk(res.data)
                setAutodeskLoading(false)
              })
              .catch(err => {
                setAutodeskLoading(false)

              })
          } else {
            setAutodeskLoading(false)
          }

        })

    }

  }, [])
  const handleClosePanel = () => {
    props.closeDialog(false)
  }
  const handleUpdateUserInfo = () => {

  }
  const parameterUpload = {
    name: "avatar",
    listType: "picture-card",
    showUploadList: false,
    customRequest: ({ onSuccess, onError, file }) => {
      if (file !== null) {
        setLoading(true)
        let data = new FormData()
        data.append('file', file)
        data.append('email', email)
        axios.post(`/api/users/upload-me-avatar`, data, {
          onUploadProgress: ProgressEvent => {
            // setLoad(
            //     (ProgressEvent.loaded / ProgressEvent.total*100),
            // )
          },
        })
          .then(res => {
            setLoading(false)
            window.location.reload(true)
          })
          .catch(err => {
            setLoading(false)
            message.error(`Upload avatar failed`)
          })
      }
    },

  }
  const handleConnectAutodesk = () => {
    axios.get('/api/forge/oauth/url')
      .then(res => {
        window.location.href = res.data;
      })
      .catch(err => { })
  }
  const handleDisconnectAutodesk = () => {
    setAutodeskLoading(true)
    axios.post('/api/forge/oauth/logout', { email: props.user.email })
      .then(res => {
        setUserAutodesk(null)
        setAutodeskLoading(false)
      })
      .catch(err => {
        setAutodeskLoading(false)
      })
  }
  return (
    <Modal show={props.onShow} onHide={handleClosePanel.bind(this)} size='md' className='dialog-share-project' backdrop={'static'}>
      <Modal.Header>
        <Modal.Title>User Information</Modal.Title>
      </Modal.Header>
      <Divider className='custom-divider' />
      <Modal.Body>
        {/* {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />} */}
        <Segment color='blue' loading={loading} >
          <Row align="middle" gutter={8}>
            <Col span={4} >
              {checkWohhupUser (props.user.email) ?
                imgUrl && <img src={imgUrl} alt="avatar" style={{ width: '100%' }} /> :
                <Upload {...parameterUpload} accept='.png,.jpg' >
                  {imgUrl ? <img src={imgUrl} alt="avatar" style={{ width: '100%' }} /> :
                    <div>
                      <Icon type={loading ? 'loading' : 'plus'} />
                      <div className="ant-upload-text">Upload</div>
                    </div>}
                </Upload>
              }


            </Col>
            <Col span={20} >
              <Text strong style={{ marginRight: 5 }}>User Name</Text>
              <Input placeholder={'User Name'} style={{ display: 'inline', marginBottom: 7 }} block size='sm' value={userInfo.userName} /> <br />
              <Text strong style={{ marginRight: 5 }}>Title</Text>
              <Input placeholder={'Title'} style={{ display: 'inline', marginBottom: 7 }} block size='sm' value={userInfo.title} /> <br />
              <Text strong style={{ marginRight: 5 }}>Department</Text>
              <Input placeholder={'Department'} style={{ display: 'inline', marginBottom: 7 }} block size='sm' value={userInfo.department} /> <br />
              <Text strong style={{ marginRight: 5 }}>Company</Text>
              <Input placeholder={'Company'} style={{ display: 'inline', marginBottom: 7 }} block size='sm' value={userInfo.company} /> <br />
              <Text strong style={{ marginRight: 5 }}>Phone</Text>
              <Input placeholder={'Phone'} style={{ display: 'inline', marginBottom: 7 }} block size='sm' value={userInfo.phone} />
            </Col>
          </Row>
        </Segment>
        {checkWohhupUser (props.user.email) &&
          <Segment color='red' loading={autodeskLoading} >
            <Row align="middle" gutter={8}>
              <Col span={4} >
                {userAutodesk && <img src={userAutodesk.picture} alt="avatar" style={{ width: '100%' }} />}
              </Col>
              <Col span={20} >
                <Text strong style={{ marginRight: 5 }}>Autodesk User Mail</Text>
                <Input placeholder={'User Mail'} style={{ display: 'inline', marginBottom: 7 }} block size='sm'
                  value={userAutodesk ? userAutodesk.email : ''} disabled /> <br />
                <Text strong style={{ marginRight: 5 }}>Autodesk User Name</Text>
                <Input placeholder={'User Name'} style={{ display: 'inline', marginBottom: 7 }} block size='sm'
                  value={userAutodesk ? userAutodesk.name : ''} disabled /> <br />
                <ButtonAnt.Group style={{ width: '100%' }} >
                  <ButtonAnt type="primary" onClick={handleConnectAutodesk} disabled={userAutodesk !== null} >
                    <Icon type='login' style={{ transform: 'translateY(-3px)' }} />
                    Connect
                  </ButtonAnt>
                  <ButtonAnt type="danger" onClick={handleDisconnectAutodesk} disabled={userAutodesk === null} >
                    <Icon type='logout' style={{ transform: 'translateY(-3px)' }} />
                    Disconnect
                  </ButtonAnt>
                </ButtonAnt.Group>
              </Col>
            </Row>
          </Segment>}
      </Modal.Body>
      <Divider className='custom-divider' />
      <Modal.Footer>
        <Button onClick={handleClosePanel} loading={loading} color="default"> Cancel </Button>
        <Button onClick={handleUpdateUserInfo} loading={loading} color="primary"> Update</Button>
      </Modal.Footer>
    </Modal>
  );
}
DialogUser.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  logout: PropTypes.func.isRequired,
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { logout: actions.logout })(DialogUser)
