import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import IconTable from '../layouts/generalComponents/IconTable';
import { ModalFileVersionUpD } from './modal/FileVersion.UpD';
import { Button, Tag, message } from 'antd';
import axios from 'axios';
import { Loader } from 'rsuite';
import { ModalUploadFileUpD } from './modal/UploadFile.UpD';
import { updateTableAsync } from '../../functions/GeneralFunction';
import base64url from 'base64url';
import { getPublicUrl } from '../../../module_BimApp/function/AdminFunction';
import { getUserAndProjectInfoInit } from '../../utils';
import DateCell from './cell/DateCell';
import FileBrowserCell from './cell/FileBrowserCell';
import FileCell from './cell/FileCell';
import FileLevelCell from './cell/FileLevelCell';


const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';

export function ConUploadDocumentPage(props) {
    const { projectIsAppliedRfaView, history, localState, pageSheetTypeName, storeInitProject, storeInitUser } = props;

    const [isOpen, setIsOpen] = useState()
    const [files, setFiles] = useState()
    const [isLoading, setIsLoading] = useState()
    const [file, setFile] = useState()
    const [gridApi, setGridApi] = useState()
    const pathDir = `dmsConsul/${props.storeInitProject.projectId}`
    const { email, role, projectId, projectName, isAdmin } = getUserAndProjectInfoInit(storeInitProject, storeInitUser);

    useEffect(() => {


        if (!props.storeInitProject.projectId) {
            props.history.push({
                pathname: '/dms/spreadsheet',
            })
            return
        }
        run()
    }, [])

    const run = async () => {
        try {
            setIsLoading(true)
            let res = await axios({
                url: '/api/consultant-upload-file',
                method: 'GET',
                params: {
                    token: props.token,
                    projectId: props.storeInitProject.projectId
                }
            })
            if (res.data) {
                setFiles(res.data)
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }

    }
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: false,
            sortable: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            filter: 'agSetColumnFilter',
        };
    }, []);
    console.log(props)
    const columnDefs = [
        { field: 'consultantName', headerName: 'Consultant', width: 100, rowGroup: true, hide: true, },
        { field: 'group', headerName: 'Group', width: 100, rowGroup: true, hide: true },
        { field: 'folderName', headerName: 'Folder Name', width: 100, rowGroup: true, hide: true },
        {
            field: 'fileName', headerName: 'Files', width: 100,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: FileCell,
                    }
                }
                return undefined;
            }
        },
        {
            field: 'currentVersion', headerName: 'Version', minWidth: 100, maxWidth: 100,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleVersionCell,
                    }
                }
                return undefined;
            },
            cellStyle: { textAlign: 'center' }
        },
        {
            field: 'updatedAt', headerName: 'Date', width: 100, maxWidth: 150,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: DateCell,
                    }
                }
                return undefined;
            }
        },
        {
            field: 'updatedBy', headerName: 'By', width: 100, maxWidth: 180

        },
        {
            headerName: 'Action',
            field: 'action',
            minWidth: 200, maxWidth: 200,
            // lockVisible: !(user.isAdmin || roleForAddEditModule.includes(role)),
            lockPosition: 'right',
            suppressMovable: true,
            // hide: !(user.isAdmin || roleForAddEditModule.includes(role)),
            // suppressToolPanel: !(user.isAdmin || roleForAddEditModule.includes(role)),
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                if (!isGroup) {
                    return {
                        component: handleAction,
                    }
                }
                return undefined;
            }
        },
    ]
    const handleVersionCell = (params) => {
        return (
            <div style={{ width: '100%' }}>
                <Tag onClick={handleVersion.bind(this, params.data)} >{params.data.currentVersion}</Tag>
            </div>
        )
    }
    const handleAction = (params) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, alignItems: 'center', height: '100%' }}>
                <Button size='small' type='primary' onClick={handleView.bind(this, params.data)} icon='eye' className='dsm-button-icon'></Button>
                <Button size='small' type='primary' onClick={handleDownload.bind(this, params.data)} icon='download' className='dsm-button-icon' ></Button>
                {(role === 'Document Controller' || role === 'Consultant' || isAdmin) &&
                    <Button size='small' type='danger' icon='delete' onClick={handleDelete.bind(this, params.data)} className='dsm-button-icon'
                    ></Button>}
            </div>
        )
    }
    const handleDownload = async (e) => {
        try {
            setIsLoading(true)
            let res = await axios({
                url: '/api/consultant-upload-file/get-all-version',
                method: 'GET',
                params: {
                    token: props.token,
                    fileId: e.fileId
                }
            })
            if (res.data) {
                let key = `${pathDir}/${e.fileId}/${res.data[0].versionId}/${e.fileName}`
                let url = await getPublicUrl(key, 10000, typeBucketName)
                window.open(url, '_blank');
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }
    const handleView = async (e) => {
        try {
            setIsLoading(true)
            let res = await axios({
                url: '/api/consultant-upload-file/get-all-version',
                method: 'GET',
                params: {
                    token: props.token,
                    fileId: e.fileId
                }
            })
            if (res.data) {
                let key = `${pathDir}/${e.fileId}/${res.data[0].versionId}/${e.fileName}`
                console.log(key)
                let linkToOpen = `${window.location.origin}/dms-viewer/${base64url(key)}`;
                window.open(linkToOpen, '_blank');
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }

    }
    const handleOpen = () => {
        setIsOpen(!isOpen)
    }
    const handleVersion = (e) => {
        setFile(e)
    }
    const handleDelete = async (e) => {
        try {
            if (!window.confirm('Are you want to delete?')) return
            setIsLoading(true)
            let res = await axios({
                url: '/api/consultant-upload-file',
                method: 'delete',
                params: {
                    token: props.token,
                    versionId: e.versionId,
                    fileId: e.fileId,
                    projectId: props.storeInitProject.projectId
                }
            })
            if (res.data) {
                // window.location.reload()
                await updateTableAsync(gridApi.api, { remove: [e] })
                gridApi.api.redrawRows()
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }
    const onGridReady = useCallback((params) => {
        setGridApi(params)
    }, []);
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: 'Files',
            minWidth: 330,
            cellRendererParams: {
                // checkbox: true,
                suppressCount: true,
                innerRenderer: FileLevelCell,
            },
        };
    }, []);
    const groupRowRendererParams = useMemo(() => {
        return {
            suppressCount: true,
            innerRenderer: FileLevelCell,
        };
    })
    return (
        <>
            {isLoading &&
                <div style={{ zIndex: 100000, position: 'absolute', width: '100%', height: '-webkit-fill-available', textAlign: 'center' }} >
                    <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />
                </div>

            }
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 55px)' }}>
                <div style={{ height: '50px', width: '100%', display: 'flex', backgroundColor: '#f1f2f6', alignItems: 'center', padding: '0px 5px' }} >
                    {(role === 'Document Controller' || role === 'Consultant' || isAdmin)
                        &&
                        <IconTable type='plus' onClick={handleOpen} tooltipTitle='Add File' />
                    }
                </div>
                <div className='dms ag-theme-alpine' style={{
                    height: '100%',
                    width: 'calc(100% - 55px)',
                    left: 55,
                    position: 'sticky'
                }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={files}
                        columnDefs={columnDefs}
                        // autoGroupColumnDef={autoGroupColumnDef}
                        groupRowRendererParams={groupRowRendererParams}
                        // headerHeight={25}
                        rowHeight={35}
                        onGridReady={onGridReady}
                        groupDisplayType='groupRows'
                        groupDefaultExpanded={3}
                        suppressContextMenu={true}
                    ></AgGridReact>
                </div>
            </div>
            {isOpen &&
                <ModalUploadFileUpD
                    {...props}
                    setClose={setIsOpen}
                    pathDir={pathDir}
                    setIsLoading={setIsLoading}
                    files={files}
                />
            }
            {file &&
                <ModalFileVersionUpD
                    {...props}
                    setClose={setFile}
                    pathDir={pathDir}
                    file={file}
                    setIsLoading={setIsLoading}
                />
            }
        </>


    )
}