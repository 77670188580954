import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { HotTable } from "@handsontable/react";
import { message, Button, Icon, Tooltip } from "antd";
import Handsontable from "handsontable";
import _ from "lodash";

import { Func_Export_QM_Data } from "../../function/func_export_to_excel";
import TableInputUnits from "./TableInputUnits";

var customParseFormat = require("dayjs/plugin/customParseFormat");

const TableUnits = () => {
   const [user_role, set_user_role] = useState();
   const [visibleInputUnit, setVisibleInputUnit] = useState(false);
   const [forceReRender, setForceReRender] = useState(false);
   const [data, setData] = useState();
   const tableRef = useRef(null);

   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            const res = await axios.get("/api/qaqc/dashboard/unitsetting");
            const temp_d_cm = res.data.data;
            setData(temp_d_cm);
         };

         fetchdata();
      }
   }, [forceReRender]);

   const columns = [
      {
         title: "Project",
         data: "project_code",
         type: "text",
      },
      {
         title: "Block/Tower",
         data: "block",
         type: "text",
      },

      {
         title: "Level",
         data: "level",
         type: "text",
      },
      {
         title: "Unit Name",
         data: "unit_name",
         type: "text",
      },

      {
         title: "No of sampe",
         data: "no_of_sample",
         type: "numeric",
         strict: true,
         allowInvalid: false,
      },

      {
         title: "Remark",
         data: "remark",
         type: "text",
      },
   ];

   const hotSettings = {
      colHeaders: true,
      rowHeaders: true,
      rowHeights: 24,
      height: 815,
      columns: columns,
      colWidths: [150, 150, 150, 150, 150, 300],
      stretchH: "last",
      currentRowClassName: "currentRow",
      dropdownMenu: [
         "filter_by_condition",
         "filter_operators",
         "filter_by_condition2",
         "filter_by_value",
         "filter_action_bar",
      ],
      filters: true,
      search: true,
      licenseKey: "non-commercial-and-evaluation",
   };

   const addClassesToRows = (TD, row, col, prop, value, cellProperties) => {
      const parentElement = TD.parentElement;

      if (parentElement === null) {
         return;
      }
      // Add class to odd TRs
      if (row % 2 === 0) {
         Handsontable.dom.addClass(parentElement, "odd");
      }
   };

   const onAfterHotChange = (changes) => {
      if (changes === null) {
         return;
      }

      if (tableRef.current !== null) {
         const hot = tableRef.current.hotInstance;
         changes.forEach(([row, column, oldValue, newValue]) => {
            // console.log(row);
            const r = hot.toPhysicalRow(row);
            data[r].change = true;
            data[r][column] = newValue;
         });
         setData(data);
      } else {
         message.warn("Sorry changed data will not able to save!");
      }
   };

   const saveData = async () => {
      //update those changed data
      let got_delete_row = false;
      var dt = _.cloneDeep(data);
      var datat = dt.filter((item) => item.change === true);

      var datas = datat.filter((item) => item.inv_number !== null && item.do_number !== null);
      //remove unnessary properties
      datas.forEach((e) => {
         e.unit_id = e.project_code + "_" + e.unit_name;
      });
      if (datas != undefined && datas.length > 0) {
         await axios.put("/api/qaqc/dashboard/updateunit", {
            data: datas,
         });
         //filter item change and set the change to false
         datas.forEach((e) => {
            data.find((e1) => {
               if (e1.id == e.id) {
                  e1.change = false;
               }
            });
         });
         // setData(save_data);
         message.success("Data has saved");
      }

      //delete those remove data
      var datar = datat.filter((item) => item.unit_name === null);
      //remove unnessary properties
      if (datar != undefined && datar.length > 0) {
         const data_array = Array.from(datar);
         const array_id = data_array.map((e) => e.id);
         if (array_id !== undefined || array_id.length > 0) {
            await axios.delete("/api/qaqc/dashboard/delete_units", {
               data: { array_id: array_id },
            });

            //remove item from data list
            const array_removed = [];
            array_id.forEach((e) => {
               data.find((e1, index) => {
                  if (e1.id == e) {
                     array_removed.push(index);
                  }
               });
            });
            if (array_removed.length > 0) {
               while (array_removed.length) {
                  data.splice(array_removed.pop(), 1);
               }
               // setData(data);
            }
            got_delete_row = true;
            message.info("There is item was removed from list.");
            message.success("Data has saved");
         }
      }

      // return { save_data, got_delete_row };

      if (dt !== undefined) {
         setData(data);
         if (dt.got_delete_row) {
            setForceReRender(!forceReRender);
         }
      }
   };

   return (
      <div>
         <div>
            <div style={{ marginBottom: "2px" }}>
               <Tooltip title="New Unit by key in.">
                  {/* <Button type="default" size="medium"> */}
                  <Button type="default" size="default" onClick={() => setVisibleInputUnit(true)}>
                     <Icon type="plus" />
                  </Button>
               </Tooltip>

               <Tooltip title="Save data.">
                  <Button
                     type="default"
                     size="default"
                     onClick={() => {
                        saveData();
                     }}
                  >
                     <Icon type="save" />
                  </Button>
               </Tooltip>

               <Tooltip title="Download data.">
                  <Button
                     type="default"
                     size="default"
                     // style={{ float: "right", margin: 0 }}
                     onClick={(e) => Func_Export_QM_Data(data)}
                  >
                     <Icon type="download" />
                  </Button>
               </Tooltip>
            </div>

            <HotTable
               ref={tableRef}
               settings={hotSettings}
               data={data}
               // afterRenderer={addClassesToRows}
               // afterOnCellMouseUp={handleOnCellMouseUp}
               afterChange={onAfterHotChange}
               // afterFilter={handleAfterFilter}
               className="do-invoice-table handsontable_do"
            />
         </div>

         <div className="model-input">
            <TableInputUnits
               visible={visibleInputUnit}
               onCreate={() => {
                  setVisibleInputUnit(false);
                  setForceReRender(!forceReRender);
               }}
               onCancel={() => {
                  setVisibleInputUnit(false);
               }}
               data={data}
            />
         </div>
      </div>
   );
};

export default TableUnits;
