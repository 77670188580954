import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, List, Icon as AntIcon } from 'antd';

import { MenuProvider, animation } from 'react-contexify';

import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import { setDataEntryOpenHistory } from '../../../actions/app/dataentry';

const renderView = ({ style, ...props }) => {
  const viewStyle = {
    paddingRight: 5,
  };
  return <div className='box' style={{ ...style, ...viewStyle }} {...props} />;
};

function DataEntrySidebar(props) {
  return (
    <>
      <div
        style={{
          background: '#003059',
          height: '100%',
          width: '42px',
          position: 'absolute',
        }}
      >
        <Tooltip placement='right' title='Folder'>
          <div
            className={`icon function ${
              props.headerSidebar === 'Browser' && 'active'
            }`}
            onClick={props.handleFunctionSidebar.bind(this, 'Browser')}
          >
            &#128449;
          </div>
        </Tooltip>
        <Tooltip placement='right' title='Recent'>
          <div
            className={`icon function ${
              props.headerSidebar === 'Recent Files' && 'active'
            }`}
            onClick={props.handleFunctionSidebar.bind(this, 'Recent Files')}
          >
            &#128343;
          </div>
        </Tooltip>
        <Tooltip placement='right' title='Bookmark'>
          <div
            className={`icon function ${
              props.headerSidebar === 'Bookmark' && 'active'
            }`}
            onClick={props.handleFunctionSidebar.bind(this, 'Bookmark')}
          >
            &#128970;
          </div>
        </Tooltip>
      </div>
      <Scrollbars
        renderView={renderView}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbMinSize={30}
        universal={true}
      >
        {props.headerSidebar === 'Browser' && (
          <MenuProvider id={'data_entry_side_menu'} animation={animation.zoom}>
            <div
              style={{
                left: '42px',
                position: 'relative',
                width: 'calc(100% - 45px)',
                height: '100vh',
              }}
            >
              <List
                size='small'
                dataSource={props.listSheet}
                renderItem={(item, idx) => (
                  <div className={`list-sheet ${item.active && 'active'}`}>
                    <MenuProvider
                      id={'data_entry_file_menu'}
                      animation={animation.zoom}
                      data={{ item: item, idx: idx }}
                    >
                      <List.Item
                        style={{
                          color: 'white',
                          wordBreak: 'unset',
                          whiteSpace: 'nowrap',
                          borderBottom: 'none',
                        }}
                        onClick={props.handleSheetOpen.bind(this, item)}
                      >
                        <AntIcon type='file' />
                        <div
                          style={{
                            width: 'calc(100% - 40px)',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.name}
                        </div>
                        <AntIcon type='more' />
                      </List.Item>
                    </MenuProvider>
                  </div>
                )}
              />
            </div>
          </MenuProvider>
        )}
      </Scrollbars>
    </>
  );
}

DataEntrySidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  userInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  openHistoryDialog: PropTypes.bool.isRequired,
  setDataEntryOpenHistory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    // isAdmin: state.user.role === "admin",
    isAdmin: state.user.email === 'admin@wohhup.com',
    isConfirmed: !!state.user.confirmed,
    userInfo: state.user,
    userRole: state.user.role,
    openHistoryDialog: state.dataentry.openHistoryDialog,
  };
}
export default connect(mapStateToProps, {
  setDataEntryOpenHistory: setDataEntryOpenHistory,
})(DataEntrySidebar);
