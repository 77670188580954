import React, { useEffect } from 'react';
import { IconButton, ButtonGroup, Icon, Divider } from 'rsuite';
import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import { Input as InputAnt } from 'antd';
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,
  useGlobalFilter} from 'react-table'
// import './scss/SideBarSelectParameter.scss'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import NearMeIcon from '@material-ui/icons/NearMe';
import {
  handleCollapse, handleExpand,
  selectMultiModelWithElementId, colorIndividualWithElementId, selectModelArrayIdWithoutElementId,
  ListValueFilter, MinMaxFilter, GlobalFilter, fuzzyTextFilterFn
} from '../function/TableFunction'

const { Search } = InputAnt;


function TableSchedule({
  columns, data,
  viewer,
  onActiveTable,
  tempListModel,
  onChangeHeader,
  setGroupByLocal,
  expandLevel,
  triggerCollapse,
  setRowsLocal,
  haveColor,
  haveSelection,
  id,
  showInfoViewpoint,
  haveFooter = true,
  haveCompareTime,
  isDoubleClick,
  callbackDoubleClick,
  isClick,
  callbackClick,
  typeTable,
  isSearchGlobal,
  isGroupTag,
  handleGroupStatus, triggerUnGroup, defaultPageSize = 10, userCreatedProject,removeMemberInGroup,disabledButton
}) {

  const defaultColumn = React.useMemo(
    () => ({

      Filter: ListValueFilter.bind(this, onChangeHeader),
      MinMaxFilter: MinMaxFilter
    }),
    []
  )
  // function ListValueFilter1(props) {
  //   useEffect(() => {
  //     console.log(props)
  //   }, [])
  //   return (
  //     <div></div>
  //   )
  // }

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        console.log(id);
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: defaultPageSize ? defaultPageSize : 10, groupBy: isGroupTag ? ['tags'] : [] },
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,

    hooks => {
      haveColor && hooks.flatColumns.push(columns => {
        return [
          {
            id: 'setcolor',
            disableResizing: true,
            groupByBoundary: true,
            collapse: true,
            widthLocal: 25,
            unableGroup: true,
            Footer: ({ }) => (
              <div style={{ width: 15, maxWidth: 15, minWidth: 15 }} ></div>
            ),

            Header: ({ }) => (
              <div style={{ width: 30, maxWidth: 30, minWidth: 30 }} ></div>
            ),
            Cell: ({ row }) => (
              <input type="color" defaultValue={row.values.setcolor} onChange={(e) => { colorIndividualWithElementId(e, row, viewer, tempListModel) }} />

            ),
          },
          ...columns,
        ]
      })
      haveSelection && hooks.flatColumns.push(columns => {
        return [
          {
            id: 'selection',
            disableResizing: true,
            groupByBoundary: true,
            collapse: true,
            widthLocal: 5,
            unableGroup: true,
            Footer: ({ }) => (
              <div style={{ width: 15, maxWidth: 15, minWidth: 15 }} ></div>
            ),
            Header: ({ }) => (
              <div style={{ width: 15, maxWidth: 15, minWidth: 15 }} ></div>
            ),
            Cell: ({ row }) => (
              <NearMeIcon style={{ color: 'gray', fontSize: '15px', cursor: 'pointer' }}
                onClick={() => { typeTable === 'statusForClaim' ? selectModelArrayIdWithoutElementId(viewer, row) : selectMultiModelWithElementId(viewer, row, tempListModel) }} />
            ),
          },
          ...columns,
        ]
      })
      typeTable == 'issue' && hooks.flatColumns.push(columns => {
        return [
          {
            id: 'infoStatus',
            groupByBoundary: true,
            collapse: true,
            minWidthLocal: 90,
            widthLocal: 90,
            maxWidthLocal: 90,
            unableGroup: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>Rep/Attach</>
            ),
            Cell: ({ row }) => {
              // let today = moment(Date.now())
              // let dateViewPoint = moment(row.original.dateViewPoint,'DD-MM-YYYY')
              // let dateModified = moment(row.original.dateModified,'DD-MM-YYYY')
              return (
                !row.isGrouped &&
                <>
                  <Icon style={{ fontWeight: row.original.unReadComment && '800' }} icon='reply'>{' '}{`(${row.original.comments.length})`}</Icon>
                  <Divider vertical className='custom-divider-issue' />
                  <Icon icon='link' style={{ transform: 'translateY(1px)' }}>{' '}{`(${row.original.attachFile.length})`}</Icon>
                  {/* <Tooltip title={dateModified.format('DD-MM-YYYY HH:mm')} placement='top'>
                    <Icon icon='clock-o' style={{ fontSize: 16, transform: 'translateY(2px)' }}>
                      {' '}{dateModified.format('DD-MM-YYYY') === today.format('DD-MM-YYYY')
                        ? dateModified.format('HH:mm') : dateModified.format('DD-MM-YYYY')}</Icon>
                  </Tooltip> */}
                </>
              )
            }
          },
          ...columns,
        ]
      })
      showInfoViewpoint && hooks.flatColumns.push(columns => {
        return [
          {
            id: 'infoViewPoint',
            groupByBoundary: true,
            collapse: true,
            minWidthLocal: 30,
            widthLocal: 30,
            maxWidthLocal: 30,
            unableGroup: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <></>
            ),
            Cell: ({ row }) => (
              <>
                {!row.isGrouped &&
                  <ButtonGroup>
                    <IconButton size="xs" icon={<Icon icon="info" />} onClick={() => showInfoViewpoint(row.original)}  ></IconButton>

                  </ButtonGroup>}
              </>
            ),
          },
          ...columns,
        ]
      })
      typeTable == 'userControl' && hooks.flatColumns.push(columns => {
        return [
          {
            id: 'removeUser',
            groupByBoundary: true,
            collapse: true,
            minWidthLocal: 42,
            widthLocal: 42,
            maxWidthLocal: 42,
            unableGroup: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <></>
            ),
            Cell: ({ row }) => {
              return (
               ( !row.isGrouped  &&  !disabledButton)&&
                <>
                <IconButton size="xs" color='red' icon={<Icon icon='user-times' />} onClick={removeMemberInGroup.bind(this,row.original)}  ></IconButton>
                </>
              )
            }
          },
          ...columns,
        ]
      })
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    flatColumns,
    flatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state,
    state: {
      groupBy, pageIndex, pageSize,
    }
  } = instance

  useEffect(() => {
    if (!expandLevel) return
    if (expandLevel.level >= 0) {
      handleExpand(flatRows, expandLevel.level, groupBy)
    }
  }, [expandLevel])
  useEffect(() => {
    if (!triggerCollapse) return
    if (triggerCollapse.trigger)
      handleCollapse(flatRows)
  }, [triggerCollapse])
  useEffect(() => {
    if (!triggerUnGroup) return
    if (triggerUnGroup.trigger) {
      (flatColumns.map(d => {
        try {
          if (d.isGrouped)
            d.toggleGroupBy()
        } catch { }

      }))
      if (handleGroupStatus())
        handleGroupStatus()
    }
  }, [triggerUnGroup])
  useEffect(() => {
    if (!setRowsLocal) return
    setRowsLocal(rows)
  }, [rows])
  useEffect(() => {
    if (!setGroupByLocal) return
    setGroupByLocal(groupBy)
  }, [groupBy])
  const setGroup = () => {
    (flatColumns.map(d => {
      try {
        if (d.isGrouped)
          d.toggleGroupBy()
      } catch { }

    }))
    onActiveTable()
  }

  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { value }) => {
    setPageSize(value)
  }
  const handleDoubleClick = (row) => {
    if (isDoubleClick) {
      callbackDoubleClick(row)
    }
  }
  const handleClick = (row) => {
    if (isClick) {
      callbackClick(row)
    }
  }

  useEffect(() => {
    console.log(visibleColumns)
  }, [visibleColumns])
  return (
    <>
      {defaultPageSize !== 1000000000 &&
        <div style={{ float: 'right' }}>
          <Pagination
            activePage={pageIndex + 1}
            boundaryRange={1}
            onPageChange={handlePaginationChange}
            size='mini'
            totalPages={pageOptions.length}
            siblingRange={0}
            firstItem={canPreviousPage ? undefined : null}
            lastItem={canNextPage ? undefined : null}
            prevItem={canPreviousPage ? undefined : null}
            nextItem={canNextPage ? undefined : null}
          >

          </Pagination>
          <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle' }}
            onChange={handleChange}
            options={[
              { key: typeTable === 'issue' ? '20' : '10', value: typeTable === 'issue' ? 20 : 10, text: typeTable === 'issue' ? 'Show 20' : 'Show 10' },
              { key: '50', value: 50, text: 'Show 50' },
              { key: '100', value: 100, text: 'Show 100' },
              { key: '1000', value: 1000, text: 'Show 1000' },
              { key: 'All', value: 1000000000, text: 'Show All' },
            ]} />

          {onActiveTable && <ButtonGroup style={{ right: 0, top: 0, cursor: 'pointer', backgroundColor: '#ffffff00', zIndex: '1', marginLeft: '15px' }}>
            <IconButton size="xs" icon={<Icon icon="close" />} onClick={() => setGroup()}></IconButton>
          </ButtonGroup>}
        </div>}


      <div style={{
        position: 'absolute', top: defaultPageSize === 1000000000 ? 0 : 35, overflow: 'auto', bottom: 0,
        borderBottomStyle: 'solid', borderTopStyle: 'solid', borderWidth: 'thin', width: '100%'
      }}>
        <Table celled selectable id={id} {...getTableProps()} >
          {/* <Sticky> */}
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center', zIndex: 1,
                    width: column.widthLocal ? column.widthLocal : 'auto',
                    maxWidth: column.maxWidthLocal && column.maxWidthLocal,
                    minWidth: column.minWidthLocal && column.minWidthLocal
                  }}>
                    {(!column.unableGroup) &&
                      <span {...column.getGroupByToggleProps()}  >
                        {column.isGrouped ?
                          <RemoveCircleOutlineIcon style={{ color: 'red', fontSize: '15px', verticalAlign: 'sub' }} />
                          : <AddCircleOutlineIcon style={{ color: 'green', fontSize: '15px', verticalAlign: 'sub' }} />}
                      </span>}
                    {column.Header === 'ElementId' && column.toggleHidden(true)}
                    {typeTable === 'statusForClaim' ?
                      column.isGrouped ?
                        <span {...column.getSortByToggleProps()}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <ArrowDownwardOutlinedIcon />
                              : <ArrowUpwardOutlinedIcon />
                            : ''}
                          {column.render('Header')}
                        </span> :
                        column.render('Header')
                      :
                      column.isSortLocal ? (<span {...column.getSortByToggleProps()}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownwardOutlinedIcon />
                            : <ArrowUpwardOutlinedIcon />
                          : ''}
                        {column.render('Header')}
                      </span>) : column.render('Header')
                    }
                    {(column.canFilter && !column.isNoFilter) ? column.render('Filter') : null}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
            {isSearchGlobal && <Table.Row>
              <Table.HeaderCell
                colSpan={typeTable === 'issue' && 14}
                style={{
                  textAlign: "left", padding: 2, position: 'sticky', top: 41, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
                  zIndex: 1
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </Table.HeaderCell>
            </Table.Row>}
          </Table.Header>

          {/* </Sticky> */}
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              let dateViewPoint = null
              let today = null
              if (haveCompareTime) {
                today = Date.now()
                dateViewPoint = !row.isGrouped && row.original.dateViewPoint
              }
              return (
                <Table.Row {...(row.getRowProps())} onDoubleClick={handleDoubleClick.bind(this, row)} onClick={handleClick.bind(this, row)}
                  className={dateViewPoint && !row.isGrouped && (row.original.status === "Close" ? 'table-issue-row-close' : dateViewPoint < today && 'table-issue-row-late')}>
                  {row.cells.map(cell => {
                    return (
                      cell.column.id !== 'setcolor' ?
                        <Table.Cell {...(cell.getCellProps())}
                          style={{
                            textAlign: cell.column.aggregate === 'sum' ? 'right' : cell.column.alignLocal ? cell.column.alignLocal : 'center',
                            verticalAlign: 'middle', whiteSpace: cell.column.whiteSpace && cell.column.whiteSpace, overflowX: 'hidden', textOverflow: 'ellipsis'
                          }} >
                          {cell.isGrouped ? (
                            <>
                              <span {...row.getExpandedToggleProps()}>
                                {row.subRows.length !== 0 &&
                                  ((groupBy[groupBy.length - 1] !== cell.column.id || columns.length - groupBy.length > 1) &&
                                    (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                                }
                              </span>{' '}
                              {cell.render('Cell')}

                            </>
                          ) : cell.isAggregated ? (

                            cell.render('Aggregated')
                          ) : cell.isRepeatedValue ? null : (
                            cell.render('Cell')

                          )}
                        </Table.Cell>
                        :
                        (groupBy[groupBy.length - 1] === cell.row.groupByID && groupBy.length !== 0) ?
                          <Table.Cell {...(cell.getCellProps())}
                            style={{
                              textAlign: cell.column.aggregate === 'sum' ? 'right' : cell.column.alignLocal ? cell.column.alignLocal : 'center',
                              verticalAlign: 'middle', whiteSpace: cell.column.whiteSpace && cell.column.whiteSpace
                            }} >
                            {cell.isGrouped ? (
                              <>
                                <span {...row.getExpandedToggleProps()}>
                                  {row.subRows.length !== 0 &&
                                    ((groupBy[groupBy.length - 1] !== cell.column.id || columns.length - groupBy.length > 1) &&
                                      (row.isExpanded ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />))
                                  }
                                </span>{' '}
                                {cell.render('Cell')}

                              </>
                            ) : cell.isAggregated ? (

                              cell.render('Aggregated')
                            ) : cell.isRepeatedValue ? null : (
                              cell.render('Cell')

                            )}
                          </Table.Cell>
                          :
                          <Table.Cell></Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
          {haveFooter && <Table.Footer>
            {footerGroups.map(group => (
              <Table.Row {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  column.id === 'setcolor' ?
                    <Table.HeaderCell style={{ textAlign: 'left', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px', fontWeight: 'bold' }} {...column.getFooterProps()} >
                      Total</Table.HeaderCell>
                    :
                    <Table.HeaderCell style={{ textAlign: 'right', position: 'sticky', bottom: 0, boxShadow: 'rgba(0, 0, 0, 0.4) 0px -1px 0px 0px' }} {...column.getFooterProps()} >
                      {column.render('Footer')}</Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>}
        </Table>

      </div>

    </>
  )
}


export default TableSchedule