import React from 'react';
import { IconButton, ButtonGroup, Icon } from 'rsuite';
import { Table, Pagination, Dropdown } from 'semantic-ui-react'
import { Popconfirm } from 'antd';
import _ from 'lodash'
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  usePagination,
} from 'react-table'
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { ListValueFilter } from '../function/TableFunction'
function TableListFolder({ columns, data,
  getRowProps,
  getCellProps,
  email,
  projectId,
  isAdmin, permission,
  handleClickOpenSetRolePanel,
  handleDeleteFolder,
  isProjectAdmin, onChangeHeader
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ListValueFilter.bind(this, onChangeHeader),
    }),
    []
  )

  const instance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: 50 },
    },
    useFilters,
    useGroupBy,
    useExpanded,
    useSortBy,
    useRowSelect,
    usePagination,
    hooks => {
      hooks.flatColumns.push(columns => {
        return [
          {
            id: 'functionItem',
            groupByBoundary: true,
            collapse: true,
            minWidth: 110,
            width: 110,
            maxWidth: 110,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div></div>
            ),
            Cell: ({ row }) => (

              <ButtonGroup>
                <Popconfirm placement="right" title={'Are you sure to delete this item?'} value={row.original}
                  onConfirm={() => deleteFolder(row.original)} okText="Yes" cancelText="No">
                  <Tooltip title="Delete Item" placement="top">
                    <IconButton size="xs" icon={<Icon icon="trash-o" />} disabled={(!isAdmin && !isProjectAdmin)} ></IconButton>
                  </Tooltip>
                </Popconfirm>                         
                <Tooltip title="Allow access" placement="top">
                  <IconButton size="xs" icon={<Icon icon="cog" />} disabled={(!isAdmin && !isProjectAdmin)}
                    onClick={() => showOpenControlSettingPanel(row.original)}  ></IconButton>
                </Tooltip>
              </ButtonGroup>
            ),
          },
          ...columns,
        ]
      })

    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {
      pageIndex, pageSize
    }
  } = instance

  const showOpenControlSettingPanel = (value) => {
    let data = { folderId: value.folder.folderId ,folderName:value.folder.folderName}
    handleClickOpenSetRolePanel(data)
  }
  const deleteFolder = (value) => {
    let data = {
      projectId: projectId, folderId: value.folder.folderId, folderName: value.folder.folderName, email: email
    }    
    handleDeleteFolder(data)
  }
  const handlePaginationChange = (e, { activePage }) => {
    gotoPage(activePage - 1)
  }
  const handleChange = (e, { name, value }) => {
    setPageSize(value)
  }
  return (
    <div>

      <div style={{ paddingLeft: 25 }}>
        <Pagination
          activePage={pageIndex + 1}
          boundaryRange={1}
          onPageChange={handlePaginationChange}
          size='mini'
          totalPages={pageOptions.length}
          siblingRange={0}
          firstItem={canPreviousPage ? undefined : null}
          lastItem={canNextPage ? undefined : null}
          prevItem={canPreviousPage ? undefined : null}
          nextItem={canNextPage ? undefined : null}
        >

        </Pagination>
        <Dropdown defaultValue={pageSize} style={{ verticalAlign: 'middle', display: data.length !== 0 ? 'inline-block' : 'none' }}
          onChange={handleChange}
          options={[
            { key: '10', value: 10, text: 'Show 10' },
            { key: '50', value: 50, text: 'Show 50' },
            { key: '100', value: 100, text: 'Show 100' },
            { key: '1000', value: 1000, text: 'Show 1000' },
            { key: 'All', value: 1000000000, text: 'Show All' },
          ]} />
      </div>
      <div style={{ position: 'absolute', top: 105, overflow: 'auto', bottom: 0, width: '100%' }}>
        <Table celled selectable 
          {...getTableProps()} >
          <Table.Header >
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell {...column.getHeaderProps()} style={{
                    zIndex: 100,
                    position: 'sticky', top: 0, boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)', textAlign: '-webkit-center',
                    width: column.id === 'functionItem' ? 70 :
                      column.id === 'itemCount' ? 50 :
                        (column.id === 'createdDate' || column.id === 'uploadDate') ? 100 :
                          column.id === 'permission' ? 100 :
                            column.id === 'userCreated' ? 300 : 'auto'

                  }}>
                    <span {...column.getSortByToggleProps()}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDownwardOutlinedIcon />
                          : <ArrowUpwardOutlinedIcon />
                        : ''}
                      {column.render('Header')}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <Table.Row {...(row.getRowProps(), getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...(cell.getCellProps(), getCellProps(cell))} style={{ textAlign: cell.column.id === 'folderName' ? 'left' : 'center', verticalAlign: 'middle' }} >
                        {cell.column.id === 'Status'
                          ? cell.render('Aggregated')
                          : cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    </div>
  )
}

export default TableListFolder