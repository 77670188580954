import React, { PureComponent, useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';

const renderLabel = (props) => {
  const { x, y, width, height, value } = props;

  const radius = 10;
  return (
    <text
      x={x + width / 2}
      y={y - radius}
      textAnchor='middle'
      dominantBaseline='middle '
      fill='#0039e6'
      position='left'
      fontWeight='bold'
      fontSize='12px'
    >
      {numberWithCommas(value.toFixed(0))}
    </text>
  );
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  console.log('value', value);
  let formatValue = value.toLocaleString('en-US', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    useGrouping: true,
  });
  return (
    <text
      x={x + width / 2}
      y={y}
      fill='#0039e6'
      position='left'
      textAnchor='middle'
      fontWeight='bold'
      fontSize='12px'
      dy={-6}
    >{`${formatValue}`}</text>
  );
};

export default function TimeStackedBarChart(props) {
  const ref = useRef(null);
  useEffect(() => {
  }, [ref.current]);

  function getKey() {
    if (props.timeTypeForBarChart == 'month') {
      return 'monthString';
    }

    if (props.timeTypeForBarChart == 'year') {
      return 'year';
    }

    if (props.timeTypeForBarChart == 'day') {
      return 'dayStringShort';
    }

    return 'monthString';
  }

  return (
    <div style={{ width: '100%' }} className='overflow-auto' ref={ref}>
      <ResponsiveContainer width={1400} height={268}>
        <BarChart
          data={props.dataStackedPileChart}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey={getKey()} />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey='ot' stackId='a' fill='#00C49F' />
          <Bar dataKey='icp' stackId='a' fill='#FFBB28' />
          {/*<Bar dataKey='bp' stackId='a' fill='#FF8042'></Bar>*/}
          <Bar dataKey='nt' stackId='a' fill='#0088FE'>
            <LabelList content={renderLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
