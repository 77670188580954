import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Calendar from './Calendar/Calendar';
import ShiftDataTableForm from './Tables/ShiftDataTable';
import AvatarEmployee from './AvatarEmployee';
import _ from 'lodash';
import {
  search,
  searchAllSupervisor,
  setSupervisorForWorker,
  setTradeForWorker,
  setMultiSupervisorForWorker,
  getAllShiftsByAllocations,
} from '../api/workerallocation';
import {
  Table,
  Input,
  Icon,
  Modal,
  Empty,
  notification,
  Avatar,
  Row,
  Col,
  Card,
  Select,
  Button,
  Radio,
  Popover,
  Result,
  Checkbox,
} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect, useSelector } from 'react-redux';
import { DESIGNATIONS } from '../constant/index';

const { Option } = Select;
const { Search } = Input;
const { Column } = Table;

const grid = 4;

const getItemStyle = (isDragging, draggableStyle, isViewModeFull = true) =>
  isViewModeFull
    ? {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : '#F8F8FF',
        ...draggableStyle,
        borderRadius: '4px',
        boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
      }
    : {
        userSelect: 'none',
        margin: `4px`,
        ...draggableStyle,
      };

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '' : '',
  padding: grid,
  width: 250,
});

function DailyWorkerAllocation(props) {
  const [id, setId] = useState(null);
  const [record, setRecord] = useState(null);
  const [idAllocations, setIdAllocations] = useState([]);
  const [idWorkers, setIdWorkers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [viewModeFull, setViewModeFull] = useState(false);
  const [datas, setDatas] = useState([]);
  const [dataGroupBySupervisor, setDataGroupBySupervisor] = useState([]);
  const [dataGroupBySupervisorDd, setDd] = useState([]);
  const [supervisors, setSupervisor] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const optionTrades = DESIGNATIONS.map((d) => (
    <Option value={d} key={d}>
      {d}
    </Option>
  ));

  const projectId = useSelector((state) => state.project.projectId);
  const [searchRequest, setSearchRequest] = useState({
    filterBy: '',
    pageIndex: 1,
    pageSize: 1000,
    isShowOnlyWorkersNotAssign: false,
    date: selectedDate,
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    total: 1,
  });

  const onDragEnd = (result) => {
    if (!isCanArrangeWorker()) {
      return;
    }
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      //sap xep thu tu
    } else {
      let item = datas.find((x) => x.id == result.draggableId);

      let dataGroup = _.cloneDeep(dataGroupBySupervisorDd);
      if (item) {
        //Remove from source
        let objectSourceOfGroup = dataGroup.find(
          (x) => x.supervisor.id == result.source.droppableId
        );

        objectSourceOfGroup.children = objectSourceOfGroup.children.filter(
          (x) => x.id != result.draggableId
        );
        //Add to destination
        let objectDestinationOfGroup = dataGroup.find(
          (x) => x.supervisor.id == result.destination.droppableId
        );

        objectDestinationOfGroup.children.push(item);

        setDd(dataGroup);
      }
    }
  };

  useEffect(() => {
    getDatas();
  }, [searchRequest, projectId]);

  useEffect(() => {
    getAllSupervisor();
  }, [projectId]);

  const getDatas = async () => {
    if (!projectId) {
      return;
    }
    let request = { ...searchRequest, projectId };
    let res = await search(request);
    await setDatas(res.data?.items);
    if (_.isArray(res.data?.items)) {
      let dic = _.groupBy(res.data?.items, 'idSupervisor');
      let group = Object.keys(dic).map((key, index) => ({
        supervisor: { id: key, name: dic[key][0].supervisorName, key: key },
        name:
          (key !== 'null' ? dic[key][0].supervisorName : 'Day Off') +
          `  (${dic[key].length})`,
        key: key,
        countWorker: dic[key].length,
        isSubmit: dic[key].every((s) => s.isSubmit === true),
        children: dic[key].map((x) => ({
          ...x,
          name: x.workerName,
          key: 'c-' + x.id,
        })),
      }));

      group = _.orderBy(group, 'supervisor.name');

      setDataGroupBySupervisor(group);
      setDd(_.cloneDeep(group));
    }

    await setPagination({
      pageSize: res.data.pageSize,
      showSizeChanger: true,
      total: res.data.totalCount,
    });
  };

  const getAllSupervisor = async () => {
    let res = await searchAllSupervisor({ ...searchRequest, projectId });

    await setSupervisor(res.data?.items);
  };

  const handleSearch = async (e) => {
    await setSearchRequest({
      ...searchRequest,
      filterBy: e,
    });

    await getDatas();
  };

  const handleCancel = (e) => {
    setVisible(false);
    getDatas();
    setReload(false);
  };

  const content = (item) => item.workerName;

  function getShortName(name = '') {
    let shortName = '';
    let fs = name.split(' ');
    let i = 0;
    fs.forEach((x) => {
      if (i > 0) {
        shortName += '.' + x.charAt(0);
      } else {
        shortName += x.charAt(0);
      }
      i++;
    });

    return shortName;
  }

  const workerArrange = dataGroupBySupervisorDd.map((x) => (
    <div
      key={x.supervisor.id}
      className='mr-3 mt-3 '
      style={{ background: '#efeeee' }}
    >
      <Droppable droppableId={`${x.supervisor.id}`}>
        {(provided, snapshot) => (
          <div
            className='text-center'
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div
              onClick={() => {
                console.log('x', x);
                console.log('dataGroupBySupervisorDd', dataGroupBySupervisorDd);
              }}
              onDoubleClick={() => {
                handleDoubleClickShowWorkerShiftDetail(
                  x.children.map((x) => x.idWorker)
                );

                setIdAllocations(x.children.map((x) => x.id));
              }}
              className=' border-bottom font-weight-bold '
              style={{
                color: x.isSubmit || x.supervisor.id === 'null' ? '' : 'red',
                background: 'lightblue',
                lineHeight: '40px',
                cursor: 'pointer',
              }}
            >
              {x.supervisor.id === 'null' ? 'Day Off' : x.supervisor.name}
              -({x.children.length})
            </div>
            <div
              style={{ maxHeight: '300px' }}
              className={
                'overflow-auto ' + (!viewModeFull ? 'd-flex flex-wrap' : '')
              }
            >
              {x.children.map((item, index) => (
                <Draggable
                  key={`${item.id}`}
                  draggableId={`${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        viewModeFull
                      )}
                      onDoubleClick={() => {
                        handleDoubleClickShowWorkerShiftDetail([item.idWorker]);
                        setIdAllocations([item.id]);
                      }}
                    >
                      {viewModeFull ? (
                        content(item)
                      ) : (
                        <Popover content={content(item)}>
                          {
                            <AvatarEmployee
                              imageId={item.avatar}
                              workerName={item.workerName}
                            />
                          }
                        </Popover>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  ));
  async function onChangeSupervisor(val, id) {
    await setSupervisorForWorker(id, { idSupervisor: val });
    await getDatas();
  }

  async function onChangeTrade(val, id) {
    await setTradeForWorker(id, { trade: val });
    await getDatas();
  }

  function onSearchSupervisor(val) {}

  async function handleSetMultiSupervisorsForWorkers() {
    let payload = [];
    for (let i = 0; i < dataGroupBySupervisorDd.length; i++) {
      let item = dataGroupBySupervisorDd[i];
      for (let j = 0; j < item.children.length; j++) {
        let worker = item.children[j];
        let pl = {
          id: worker.id,
          idSupervisor:
            item.supervisor.id === 'null' ? null : parseInt(item.supervisor.id),
        };
        payload.push(pl);
      }
    }

    await setMultiSupervisorForWorker(payload);
    await getDatas();
  }

  function handleCalendarChangeDate(e) {
    setSelectedDate(e.format('YYYY-MM-DD'));
    setSearchRequest({ ...searchRequest, date: e.format('YYYY-MM-DD') });
  }

  function handleDoubleClickShowWorkerShiftDetail(idWorkers) {
    console.log('idWorkers', idWorkers);
    setIdWorkers(idWorkers);
    setVisible(true);
  }

  function isCanArrangeWorker() {
    return (
      // selectedDate === moment().format('YYYY-MM-DD') &&
      props.permissionType == 1
    );
  }

  function onChangeCheckBoxOnlyNotAssign(e) {
    setSearchRequest({
      ...searchRequest,
      isShowOnlyWorkersNotAssign: e.target.checked,
    });
  }

  return (
    <div>
      {props.permissionType < 3 ? (
        <Row gutter={16}>
          <h2>
            Daily Worker Allocation {selectedDate != null ? selectedDate : ''}
          </h2>

          <Col xxl={9} xl={24} className='mb-2'>
            <div className='card-box' style={{ width: '100%' }}>
              <div>
                <div className='mb-2'>
                  <Calendar
                    reload={reload}
                    onChange={handleCalendarChangeDate}
                  />
                </div>

                <div className='d-flex flex-row justify-content-start mb-2 align-items-center'>
                  <Search
                    style={{ maxWidth: 300 }}
                    className='mr-2'
                    placeholder='Search'
                    onSearch={handleSearch}
                  />
                  <Checkbox onChange={onChangeCheckBoxOnlyNotAssign}>
                    Only Not Assign
                  </Checkbox>
                </div>

                <Table
                  className='antTable'
                  dataSource={dataGroupBySupervisor}
                  bordered
                  pagination={false}
                  scroll={{ y: 500 }}
                >
                  <Column title='Name' dataIndex='name' key='name' />
                  <Column
                    width='100px'
                    title='Employer Code'
                    dataIndex='employerCode'
                    key='employerCode'
                  />
                  <Column
                    width='100px'
                    title='Trade'
                    key='trade'
                    render={(text, record) =>
                      record.idWorker && (
                        <Select
                          value={record.trade}
                          showSearch
                          onChange={(e) => onChangeTrade(e, record.id)}
                          style={{ width: '100%' }}
                          placeholder='Select Trade'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {optionTrades}
                        </Select>
                      )
                    }
                  />
                  <Column
                    title='Supervisor'
                    key='supervisorName'
                    render={(text, record) =>
                      record.idWorker && (
                        <Select
                          disabled={!isCanArrangeWorker()}
                          value={record.idSupervisor}
                          showSearch
                          style={{ width: '100%' }}
                          placeholder='Select a Supervisor'
                          optionFilterProp='children'
                          onChange={(e) => onChangeSupervisor(e, record.id)}
                          onSearch={onSearchSupervisor}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option key={-1} value={null}>
                            Day Off
                          </Option>
                          {supervisors.map((x) => (
                            <Option key={x.id} value={x.id}>
                              {x.name}
                            </Option>
                          ))}
                        </Select>
                      )
                    }
                  />
                </Table>
              </div>
            </div>
          </Col>

          <Col xxl={15} xl={24}>
            <div className='card-box' style={{ width: '100%' }}>
              <div className='d-flex flex-row-reverse'>
                <Radio.Group
                  defaultValue='Full'
                  buttonStyle='solid'
                  value={viewModeFull}
                  onChange={(e) => setViewModeFull(e.target.value)}
                >
                  <Radio.Button value={true}>Name</Radio.Button>
                  <Radio.Button value={false}>Avatar</Radio.Button>
                </Radio.Group>
              </div>
              {dataGroupBySupervisorDd.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Row gutter={16}>
                    <div className='d-flex flex-wrap '>{workerArrange}</div>

                    <Button
                      disabled={!isCanArrangeWorker()}
                      onClick={handleSetMultiSupervisorsForWorkers}
                      className=' mt-2'
                      type='primary'
                    >
                      Save
                    </Button>
                  </Row>
                </DragDropContext>
              ) : (
                <Empty />
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button type='primary' href='/'>
              Back Home
            </Button>
          }
        />
      )}

      <Modal
        width='80%'
        title='Worker Shift Detail'
        visible={visible}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <ShiftDataTableForm
          performReload={() => setReload(true)}
          projectId={projectId}
          date={selectedDate}
          idAllocations={idAllocations}
          idWorkers={idWorkers}
          supervisors={supervisors}
        />
      </Modal>
    </div>
  );
}

export default DailyWorkerAllocation;
