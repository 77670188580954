import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import {  message,  Typography, Tooltip} from 'antd';
import { Button, Icon,  Drawer,  Loader,  Divider} from 'rsuite';
import _ from 'lodash'

import { Position, Toaster } from "@blueprintjs/core";
import IssueInformation from './IssueInformation'
const { Text } = Typography


export default function DialogIssueInformation(props) {
  const [loading, setLoading] = useState(false) 
  const [viewPoint, setViewPoint] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [trigger, setTrigger] = useState(false)
  const [triggerCancel, setTriggerCancel] = useState(false)
  const toaster = useRef()
  
  useEffect(() => {
    setLoading(true)
    axios.get("/api/issue/get-issue", { params: { label: props.viewPoint.id } })
      .then(res => {
        let issue = res.data.issue
        setViewPoint(issue)    
        setLoading(false)   
      })
      .catch(() => {
        setLoading(false)
        props.handleClose(null)
        message.warning(`Can't get issue`)
      })
  }, [])

  const handleModifyInfoViewPoint = () => {
    setLoading(true)
    setTrigger(true)
  }
  const handleCloseDialog = () => {
    setLoading(true)
    setTriggerCancel(true)
  }
  return (
    <>
      <Drawer backdrop={false} show={props.open} onHide={handleCloseDialog} keyboard={false}
        placement='left' size='xs' className='drawerBimAppPanel'>
        {loading && <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />}
        <Drawer.Header>
          <Drawer.Title>
            <Icon icon='exclamation-triangle' style={{
              color: viewPoint.priority === 'Very urgent' ? 'red' :
                viewPoint.priority === 'Urgent' ? 'orange' : viewPoint.priority === 'Normal' ? '#f4d90b' : 'blue'
            }} />
            <Tooltip title={viewPoint.id} placement="right" style={{ zIndex: 120000 }}>
              <Text strong>{' '}{viewPoint.id}</Text>
            </Tooltip>

          </Drawer.Title>
        </Drawer.Header>
        <Divider className='custom-divider' />
        <Drawer.Body >
          <IssueInformation {...props} setDisabled={setDisabled} trigger={trigger} triggerCancel={triggerCancel}/>
        </Drawer.Body>
        <Divider className='custom-divider' />
        <Drawer.Footer style={{ marginBottom: 5 }}>
          <Button appearance="primary" onClick={handleModifyInfoViewPoint} loading={loading} >
            Save
      </Button>
          <Button appearance="subtle" onClick={handleCloseDialog}>
            Cancel
      </Button>
        </Drawer.Footer>
      </Drawer >
      <Toaster ref={toaster} position={Position.TOP} canEscapeKeyClear={true} />
    </>

  )
}