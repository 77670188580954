import React from 'react'
import { Loader, Dropdown } from 'semantic-ui-react'
import Chart from 'react-google-charts';
import {generateColumnChart,getDataModelForChart} from './ConcreteHistogramFunction'
import _ from 'lodash'
import axios from 'axios'
import Draggable from 'react-draggable';

var listData = null;
let listSheetResponse = []
export default class ConcreteHistogramContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listMark: [],
      data: [],
      sheetSaved: [],
      selectSheetType: 'Master Schedule'
    }

  }

  componentDidMount() {

  }
  componentWillUnmount() {
    listSheetResponse = []
    listData = null

  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.openDock) {
      this.setState({data:[]})
      axios.post(`/api/items/getsheetsaved`, { itemId: this.props.itemId })
        .then(async res => {
          this.setState({ sheetSaved: res.data })
          listSheetResponse['Master Schedule'] = []
          listSheetResponse['Detail Schedule'] = []
          res.data.forEach(item => {
            if (item.name.includes("Master Schedule"))
              listSheetResponse['Master Schedule'].push(item)
            else
              listSheetResponse['Detail Schedule'].push(item)       
          })
          let listCombine = await getDataModelForChart(nextProps)
          this.setState({ listMark: listCombine },async ()=>{
            listData = await generateColumnChart(listCombine, listSheetResponse['Master Schedule'])
            let temp = []
            temp.push(['Time', 'Plan Volume', 'Actual Volume', 'Plan Accumulative', 'Actual Accumulative'])
            var total1 = 0
            var total2 = 0
            listData.forEach(item => {
              total1 = total1 + parseFloat(item.volume1.toFixed(0))
              total2 = total2 + parseFloat(item.volume2.toFixed(0))
              temp.push([item.time, parseFloat(item.volume1.toFixed(0)), parseFloat(item.volume2.toFixed(0)), total1, total2])
            })
            this.setState({ data: temp })
          })      
        })
        .catch(er=>{})

        var domEl = document.getElementById('custom-dock-conrete-histogram')
        new ResizeObserver(() => {
          try {
            this.chartWrapper.draw();
          } catch{ }
        }).observe(domEl);    
    }else{
      this.setState({selectSheetType:'Master Schedule'})
    }
  }

  handleSheet = async (e, { value }) => {
    axios.post(`/api/items/getsheetsaved`, { itemId: this.props.itemId })
      .then(res => {
        this.setState({ sheetSaved: res.data })
        listSheetResponse['Master Schedule'] = []
        listSheetResponse['Detail Schedule'] = []
        res.data.forEach(item => {
          if (item.name.includes("Master Schedule"))
            listSheetResponse['Master Schedule'].push(item)
          else
            listSheetResponse['Detail Schedule'].push(item)
        })
        this.setState({ data: [], selectSheetType: value }, async function () {
          listData = await generateColumnChart(this.state.listMark,listSheetResponse[value])
          let temp = []
          temp.push(['Time', 'Plan Volume', 'Actual Volume', 'Plan Accumulative', 'Actual Accumulative'])
          var total1 = 0
          var total2 = 0
          listData.forEach(item => {
            total1 = total1 + parseFloat(item.volume1.toFixed(0))
            total2 = total2 + parseFloat(item.volume2.toFixed(0))
            temp.push([item.time, parseFloat(item.volume1.toFixed(0)), parseFloat(item.volume2.toFixed(0)), total1, total2])
          })
          this.setState({ data: temp ,selectSheetType:value})
        })
      })
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay('concreteHistogramDock', false)
  }
  render() {
    const { data } = this.state
    const { viewer } = this.props
    const chartEvents = [
      {
        eventName: "select",
        callback({ chartWrapper }) {
          try {
            var row = chartWrapper.getChart().getSelection()[0].row
            var column = chartWrapper.getChart().getSelection()[0].column
            viewer.clearSelection();
            if (column === 1) {
              viewer.select(listData[row].id1);
            } else if (column === 2) {
              let array = []
              for (var i = 0; i <= row; i++) {
                array.push(listData[i].id1);
              }
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            } else if (column === 3) {
              viewer.select(listData[row].id2);
            } else if (column === 4) {
              let array = []
              for (var i = 0; i <= row; i++) {
                array.push(listData[i].id2);
              }
              let merged = [].concat.apply([], array);
              viewer.select(merged);
            }

          } catch{ }
        }
      }
    ];

    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 350, width: 350 }}
          id='custom-dock-conrete-histogram' >
          <div className='custom-dock-panel-title'>Concrete Histogram</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>

              <Dropdown style={{ minHeight: 'fit-content', right: 0, position: 'absolute', zIndex: 11 }} placeholder='Select' selection
                onChange={this.handleSheet} defaultValue={'Master Schedule'} value={this.state.selectSheetType}
                options={
                  [{ key: 'Master Schedule', text: 'Master Schedule', value: 'Master Schedule', },
                  { key: 'Detail Schedule', text: 'Detail Schedule', value: 'Detail Schedule', }]}
              />
              {data.length === 0
                ? <Loader style={{ top: "45%" }} active inline='centered' >Loading</Loader> :
                listSheetResponse[this.state.selectSheetType].length === 0 ?
                  <div style={{ width: '100%', height: '100%', zIndex: 10, display: 'flex', position: 'absolute' }}>
                    <span style={{ color: 'black', margin: 'auto', textAlign: 'center', fontSize: 'larger' }}>{this.state.selectSheetType} still not setup</span>
                  </div>

                  :
                  <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
                    <Chart
                      width={'99%'}
                      height={'99%'}
                      chartType="ComboChart"
                      getChartWrapper={chartWrapper => {
                        this.chartWrapper = chartWrapper
                        chartWrapper.draw();
                      }}
                      loader={<div>Loading Chart</div>}
                      data={data}
                      options={{
                        dataOpacity: 0.3,
                        // isStacked : true,
                        pointSize: 7,
                        backgroundColor: 'white',
                        vAxis: {
                          gridlines: {
                            count: 0
                          },
                          textStyle: {
                            fontSize: 10
                          }
                        },
                        hAxis: {
                          slantedText: true, slantedTextAngle: 45,
                          textStyle: {
                            fontSize: 10
                          }
                        },
                        seriesType: 'bars',
                        series: {
                          0: { targetAxisIndex: 1 },
                          1: { targetAxisIndex: 1 },
                          2: { type: 'line' },
                          3: { type: 'line' }
                        },
                        colors: ['#0000ff', '#000000', '#0000ff', '#000000'],
                        bar: { groupWidth: '50%' },

                      }}
                      chartEvents={chartEvents}
                    />
                  </div>
              }
            </div>
          </div>
          <div className='custom-dock-panel-footer'/>
        </div>
      </Draggable>

    )
  }
}