import React from 'react'
import { SelectPicker } from 'rsuite';
import _ from 'lodash'
import {
  unloadForgeExtension, getAllElementdbIdsOneModel, getForgeToken,
  replaceSpinner
} from '../../function/ForgeFunction'
import $ from 'jquery';
import Draggable from 'react-draggable';
import { message } from 'antd';
const Autodesk = window.Autodesk;
let onceTime = true
let viewer = null
export default class View2DStatusContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      viewerCurrent: null,
      viewAll: [],
      document: null,
      viewSelected: null,
      data2DView: []
    };

  }

  componentWillMount = () => {

  }
  componentWillUnmount = () => {
    try {
      onceTime = true
      this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionRelative);
    }
    catch { }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.openDock && onceTime) {
      onceTime = false
      let _this = this
      this.launchViewer(nextProps.viewer.impl.model.myData.urn)
      var domEl = document.getElementById('custom-main-view2Dstatus')
      new ResizeObserver(() => {
        try {
          _this.state.viewerCurrent.resize()
        } catch { }
      }).observe(domEl);
    } else if (!nextProps.openDock && !onceTime) {
      try {
        onceTime = true
        this.props.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionRelative);
        viewer = null
        this.state.viewerCurrent.tearDown()
        this.state.viewerCurrent.finish()
        $("#fogre2DForStatus").empty();
      } catch { }
    }
  }
  handleReloadElement = () => {
    let _this = this
    let temp = getAllElementdbIdsOneModel(this.props.viewer);
    var instanceTree = this.props.viewer.impl.model.getData().instanceTree;
    let tempElement = []
    let count = temp.length
    _.forEach(temp, id => {
      this.props.viewer.model.getProperties(id, (modelAProperty) => {
        instanceTree.enumNodeFragments(id, function (frag) {
          let renderProxy = _this.props.viewer.impl.getRenderProxy(_this.props.viewer.model, frag)
          if (renderProxy.themingColor) {
            let index = _.findIndex(tempElement, (o) => { return o.guid === modelAProperty.externalId })
            if (index < 0) {
              _this.state.viewerCurrent.setThemingColor(id, renderProxy.themingColor)

            }

          }
        })
        count--
        if (count === 0) {

        }
      })
    })
  }
  handleChangeView = (value) => {
    _.forEach(this.state.viewAll, v => {
      if (v.data.guid === value) {
        this.state.viewerCurrent.loadDocumentNode(this.state.document, v, {})
        this.setState({ viewSelected: v.data.guid })
        return false
      }
    })

  }
  launchViewer = (urn) => {
    let _this = this
    getForgeToken().then(token => {
      var options = {
        //env: 'AutodeskProduction',
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        accessToken: token.access_token,
      };
      Autodesk.Viewing.Initializer(options, () => {
        viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('fogre2DForStatus'));
        viewer.start();
        var documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
      });
    })
    function onDocumentLoadSuccess(doc) {
      _this.setState({ viewerCurrent: viewer })
      let viewables = doc.getRoot().search({ 'type': 'geometry', 'role': '2d', 'progress': 'complete' }, true);
      if (viewables.length !== 0) {
        _this.state.viewerCurrent.loadDocumentNode(doc, viewables[0], {})
          .then(() => {
            unloadForgeExtension(_this.state.viewerCurrent)

            _this.state.viewerCurrent.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
              var temp = [];
              _this.handleReloadElement()
              let dbIds = getAllElementdbIdsOneModel(_this.state.viewerCurrent)
              let count = dbIds.length
              dbIds.forEach((id) => {
                viewer.model.getProperties(id, async (modelAProperty) => {
                  var tempMasterId = ''
                  _.forEach(modelAProperty.properties, v => {
                    if (v.displayName === 'MASTER SCHEDULE ID') {
                      tempMasterId = v.displayValue
                    }
                    if (tempMasterId !== '') {
                      if (!temp[tempMasterId])
                        temp[tempMasterId] = []
                      temp[tempMasterId].push(id)
                      return false
                    }
                  })
                  count--;
                  if (count === 0) {
                    if (Object.keys(temp).length !== 0) {
                      message.info('Get data view 2d completed')
                      _this.setState({ data2DView: temp }, () => {
                        _this.props.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, _this.onSelectionRelative);
                      })
                    }
                    else {
                      message.warning(`Can't data view 2d completed`)
                    }
                  }
                })
              })
            });

            // _this.state.viewerCurrent.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, _this.onSelectionRelative);
            // if (_this.props.dataView2D !== null)
          });
        replaceSpinner();
        let temp = []
        _.forEach(viewables, v => {
          if (v.data.size > 0)
            temp.push({ label: v.data.name, value: v.data.guid, group: v.data.role.toUpperCase(), obj: v })
        })
        _this.setState({
          data: temp,
          document: doc,
          viewAll: viewables,
          viewSelected: viewables[0].data.guid
        })
      } else {
        let viewerError = document.createElement('div');
        viewerError.style.zIndex = 1000
        viewerError.style.width = '100%'
        viewerError.style.height = '100%'
        viewerError.style.position = 'absolute'
        viewerError.style.textAlign = '-webkit-center'
        viewerError.innerHTML = '<span style="color: black">File not found sheet</span>';
        document.getElementById('fogre2DForStatus').appendChild(viewerError);
      }
    }
    function onDocumentLoadFailure(viewerErrorCode) {
      var viewerError = document.createElement('div');
      viewerError.style.zIndex = 1000
      viewerError.style.width = '100%'
      viewerError.style.height = '100%'
      viewerError.style.position = 'absolute'
      viewerError.style.textAlign = '-webkit-center'
      viewerError.innerHTML = '<span style="color: black">Could not load document</span>';
      document.getElementById('fogre2DForStatus').appendChild(viewerError);
      console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
    }
  }
  onSelectionRelative = (e) => {
    try {
      let tempMasterId = ''
      var currSelection = e.target.getSelection();
      if (currSelection.length === 0) {
        this.state.viewerCurrent.clearSelection();
        return
      }
      else {
        this.props.viewer.model.getProperties(currSelection[0], async (modelAProperty) => {
          for (var i in modelAProperty.properties) {
            if (modelAProperty.properties[i].displayName === 'MASTER SCHEDULE ID') {
              tempMasterId = modelAProperty.properties[i].displayValue
            }
            if (tempMasterId !== '') {
              if (this.state.data2DView[tempMasterId])
                this.state.viewerCurrent.select(this.state.data2DView[tempMasterId]);
              else
                this.state.viewerCurrent.clearSelection();
              return false
            }
          }
        })
        return
      }
    }
    catch { }
  }
  handleCloseDock = () => {
    this.props.onChangeDockDisplay(false)
  }
  render() {
    return (
      <Draggable
        axis="both" bounds='body'
        handle=".custom-dock-panel-title"
        defaultPosition={{ x: 40, y: 30 }}
        scale={1}
      >
        <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 350, width: 350 }}
          id='custom-main-view2Dstatus' >
          <div className='custom-dock-panel-title'>View2D For Status</div>
          <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
          <div className='custom-dock-panel-body'>
            <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
              <SelectPicker
                data={this.state.data}
                style={{ width: '100%' }}
                value={this.state.viewSelected}
                groupBy="group"
                placeholder="Select View"
                cleanable={false}
                onChange={this.handleChangeView}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label}
                    </div>
                  );
                }}
                renderMenuGroup={(label, item) => {
                  return (
                    <div>
                      <i className={label === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} /> {label} - ({item.children.length})
                    </div>
                  );
                }}
                renderValue={(value, item) => {
                  return (
                    <div>
                      <span style={{ color: '#000000' }}>
                        <i className={item.group === '3D' ? "rs-icon rs-icon-coincide" : "rs-icon rs-icon-newspaper-o"} />{item.group === '3D' ? " View3D" : " Sheet"} :
              </span>{' '}
                      {item.label}
                    </div>
                  );
                }}
              />
              <div id='fogre2DForStatus' style={{ position: 'absolute', width: '100%', height: 'calc(100% - 36px)', backgroundColor: 'white' }}></div>
            </div>
          </div>
          <div className='custom-dock-panel-footer' />
        </div>
      </Draggable>

    )
  }
}