import React from 'react';
import _ from 'lodash'
import 'chartjs-plugin-datalabels';

import Draggable from 'react-draggable';
import { Empty } from 'antd';

import MaterialTable from 'material-table';

//!

class PPVCTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableDetail: []
    }

  }
  componentWillMount = () => {
    this.setState({ tableDetail: this.props.tableDetail })
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({ tableDetail: nextProps.tableDetail })
  }

  handleCloseDock = () => {
    this.props.handleCloseDock()
  }


  render() {
    return (
      <div>

        <Draggable
          axis="both" bounds='body'
          handle=".custom-dock-panel-title"
          defaultPosition={{ x: 40, y: 30 }}
          scale={1}
        >
          <div className='custom-forge-dock' style={{ display: this.props.openDock ? 'inline' : 'none', height: 500, width: '60%' }}
            id='custom-progress-pclist' >
            <div className='custom-dock-panel-title'>PC List</div>
            <div className='custom-dock-panel-close' onClick={this.handleCloseDock}>x</div>
            <div className='custom-dock-panel-body' style={{ backgroundColor: '#00000059' }}>
              <div style={{ width: "100%", height: '100%', overflow: "auto", position: 'absolute' }}>
                {this.state.tableDetail.length !== 0 ?
                  <MaterialTable
                    title="PC List"
                    columns={this.props.columns}
                    data={this.state.tableDetail}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      pageSize: 10,
                      pageSizeOptions: [10],
                      showTitle: false,
                    }}
                  /> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                    <span style={{ color: 'white' }}>
                      No data
                  </span>} />}
              </div>
            </div>
            <div className='custom-dock-panel-footer' />
          </div>
        </Draggable>
      </div>
    )

  }



}
export default PPVCTable;