import React from 'react';
import { Button } from 'antd';
import axios from '../../module_Worker/api/axiosClient';

async function apiGetLinkDownloadByKey(payload) {
  return await axios.get('/api/casting-report/get-file-by-key', {
    params: payload,
  });
}

export default function CellButtonCastingReport(params) {
  if (params) {
    if (
      params.value !== null &&
      params.value !== undefined &&
      params.value !== ''
    ) {
      return (
        <Button
          onClick={() => {
            let key = params.node.data.castingReportHistory[0].keyS3;
            apiGetLinkDownloadByKey({ key: key, time: 60 }).then((res) => {
              window.open(res);
            });
          }}
          type='link'
        >
          {params.data.castingReportNumber}
        </Button>
      );
    }
    //
  }
  return '';
}
