import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Row, Col, Radio, DatePicker, Tooltip, message } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import _ from "lodash";

import Common_Sum_Project from "./_summary/Common_Sum_Project";
import Critical_Sum_Project from "./_summary/Critical_Sum_Project";

const { RangePicker } = DatePicker;
const QaQcSummary = (current) => {
   const [originDataCM, setOriginDataCM] = useState();
   const [originDataCT, setOriginDataCT] = useState();
   const [failItemsByProjectCM, setFailItemsByProjectCM] = useState();
   const [failItemsByProjectCT, setFailItemsByProjectCT] = useState();

   const [listProject, setListProject] = useState([]);

   const [currentProject, setCurrentProject] = useState();
   const [date_start, set_date_start] = useState("");
   const [date_end, set_date_end] = useState("");
   const [filterMode, setFilterMode] = useState(0);

   useEffect(() => {
      {
         const project_id = localStorage.getItem("fp_project_id");
         const fetchdata = async () => {
            // await axios.post("/api/qaqc/getdata/jotform-common");
            // message.success("Latest data from Jotform has been loaded!");

            const res = await axios.get("/api/qaqc/dashboard/summary");
            const temp_d_cm = res.data.data.data_cm;
            setOriginDataCM(temp_d_cm);
            Digest_Data_CM_All_Project(temp_d_cm);

            const temp_d_ct = res.data.data.data_ct;
            setOriginDataCT(temp_d_ct);
            Digest_Data_CT_All_Project(temp_d_ct);
            // let projects = [...new Set(temp_d_cm.map((obj) => obj.project))];
            // projects.sort();
            // projects.splice(0, 0, "All Projects");
            // setListProject(projects);
         };

         fetchdata();
      }
   }, []);

   const Digest_Data_CM_All_Project = (temp_d_cm) => {
      var group_project = _.groupBy(temp_d_cm, "project");

      //common check
      let list_fail_by_project = [];
      _.map(group_project, (value, key) => {
         let t = new Object();
         t.project = value[0].project;

         const list_pjt = temp_d_cm.filter((e) => e.project === value[0].project);

         t.total_no = list_pjt.reduce((e, { no_of_no }) => e + no_of_no, 0);
         t.total_yes = list_pjt.reduce((e, { no_of_yes }) => e + no_of_yes, 0);
         t.total = t.total_no + t.total_yes;

         let percent = (100 * t.total_no) / t.total;
         t.percent = percent.toLocaleString("en-US", {
            maximumFractionDigits: 1,
            miniimumFractionDigits: 1,
            useGrouping: false,
         });

         list_fail_by_project.push(t);
      });
      list_fail_by_project = _.sortBy(list_fail_by_project, ["project"]);
      setFailItemsByProjectCM(list_fail_by_project);

      return;
   };

   const Digest_Data_CT_All_Project = (temp_d_ct) => {
      var group_project = _.groupBy(temp_d_ct, "project");

      //common check
      let list_fail_by_project = [];
      _.map(group_project, (value, key) => {
         let t = new Object();
         t.project = value[0].project;

         const list_pjt = temp_d_ct.filter((e) => e.project === value[0].project);

         t.total_no = list_pjt.reduce((e, { no_of_no }) => e + no_of_no, 0);
         t.total_1st = list_pjt.reduce((e, { no_of_1st }) => e + no_of_1st, 0);
         t.total_1plus = list_pjt.reduce((e, { no_of_1plus }) => e + no_of_1plus, 0);
         t.total = t.total_no + t.total_1st + t.total_1plus;

         let percent = (100 * t.total_1st) / t.total;
         t.percent = percent.toLocaleString("en-US", {
            maximumFractionDigits: 1,
            miniimumFractionDigits: 1,
            useGrouping: false,
         });
         list_fail_by_project.push(t);
      });
      list_fail_by_project = _.sortBy(list_fail_by_project, ["project"]);
      setFailItemsByProjectCT(list_fail_by_project);

      return;
   };

   const handleChangeDate = (fm, dateString) => {
      const now = dayjs();
      const y = now.year();
      const m = now.month();
      const d = now.day();
      let data_cm = _.cloneDeep(originDataCM);
      let data_ct = _.cloneDeep(originDataCT);

      switch (fm) {
         case 1:
            const sd1 = dayjs(new Date(y, m, 1)).format('YYYY-MM-DD');
            data_cm = data_cm.filter((e) => e.created_at >= sd1);
            data_ct = data_ct.filter((e) => e.created_at >= sd1);
            set_date_start(sd1);
            set_date_end(null);

            break;
         case 2:
            let last = now.subtract(1, "month")
            let sd2 = dayjs(new Date(last.year(), last.month(), 1)).format("YYYY-MM-DD")
            const de = dayjs(sd2).daysInMonth()
            let ed2 = dayjs(new Date(last.year(), last.month(), de)).format("YYYY-MM-DD")
            data_cm = data_cm.filter((e) => e.created_at >= sd2 && e.created_at <= ed2);
            data_ct = data_ct.filter((e) => e.created_at >= sd2 && e.created_at <= ed2);
            set_date_start(sd2);
            set_date_end(ed2);
            break;
         case 3:
            let sd3 = now.subtract(3, "month").format("YYYY-MM-DD");
            data_cm = data_cm.filter((e) => e.created_at >= sd3);
            data_ct = data_ct.filter((e) => e.created_at >= sd3);
            set_date_start(sd3);
            set_date_end(null);
            break;

         case 4:
            if (
               dateString[0] !== undefined &&
               dateString[0] !== "" &&
               dateString[1] !== undefined &&
               dateString[1] !== ""
            ) {
               data_cm = data_cm.filter((e) => e.created_at >= dateString[0] && e.created_at <= dateString[1]);
               data_ct = data_ct.filter((e) => e.created_at >= dateString[0] && e.created_at <= dateString[1]);
               set_date_start(dateString[0]);
               set_date_end(dateString[1]);
            }
            break;
      }

      // setTempData(data);
      Digest_Data_CM_All_Project(data_cm);
      Digest_Data_CT_All_Project(data_ct);
   };

   return (
      <div style={{ margin: "10px 15px", height: "100%" }}>
         <Row style={{ marginTop: "20px" }} className="qaqc_dashboard_card">
            <ButtonGroup>
               <Radio.Group
                  defaultValue={filterMode}
                  buttonStyle="solid"
                  value={filterMode}
                  onChange={(e) => {
                     setFilterMode(e.target.value);
                  }}
               >
                  <div>
                     <Tooltip title="Shown data for all time.">
                        <Radio.Button
                           value={0}
                           onClick={() => {
                              handleChangeDate(0, null);
                           }}
                        >
                           All time
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown data for current month">
                        <Radio.Button
                           value={1}
                           onClick={() => {
                              handleChangeDate(1, null);
                           }}
                        >
                           {dayjs().format("MMM-YY")}
                        </Radio.Button>
                     </Tooltip>
                     <Tooltip title="Shown data of last month">
                        <Radio.Button
                           value={2}
                           onClick={() => {
                              handleChangeDate(2, null);
                           }}
                        >
                           {dayjs().subtract(1, "month").format("MMM-YY")}
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO data last 3 months.">
                        <Radio.Button
                           value={3}
                           onClick={() => {
                              handleChangeDate(3, null);
                           }}
                        >
                           these 3 mths
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO for selected months.">
                        <Radio.Button value={4} style={{ float: "right", width: 220 }}>
                           Select range of date
                           {filterMode === 4 ? (
                              <RangePicker
                                 size={"default"}
                                 style={{ float: "right", marginRight: -15, width: 220 }}
                                 onChange={(date, dateString) => {
                                    handleChangeDate(4, dateString);
                                    setFilterMode(4);
                                 }}
                              />
                           ) : null}
                        </Radio.Button>
                     </Tooltip>
                  </div>
                  {/* </Space> */}
               </Radio.Group>
            </ButtonGroup>
         </Row>

         <Row style={{ marginTop: "15px" }} className="qaqc_dashboard_card">
            <Common_Sum_Project data={failItemsByProjectCM} />
         </Row>

         <Row style={{ marginTop: "15px" }} className="qaqc_dashboard_card">
            <Critical_Sum_Project data={failItemsByProjectCT} />
         </Row>
      </div>
   );
};

export default QaQcSummary;
