import React, { useEffect, useRef, useState } from 'react'
import BaseTable, { AutoResizer ,SortOrder} from 'react-base-table'
import { Tooltip, Typography } from 'antd';
import _ from 'lodash'
import styled from 'styled-components'
import { IconButton, ButtonGroup, ButtonToolbar, Icon, Checkbox } from 'rsuite';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
const { Text } = Typography


const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`




const headerRenderer = (props) => {

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#182a3d', color: 'white', alignItems: 'center' }}>
      {props.column.title}
    </div>
  )
}



function DataEntryTable(props) {
  const [columns, setColumns] = useState([])
  const [data, setData] = useState(props.data)
  const [sortState, setSortState] = useState({})
  const hotTable = useRef()
  const handleClickRow = (e) => {
    // let models = props.viewer.impl.modelQueue().getModels()
    // let temp = []
    // if (e.rowData.children) {
    //   _.forEach(e.rowData.children, v => {
    //     temp.push(v.id)
    //   })
    //   let index = _.findIndex(models, v => { return v.myData.urn === e.rowData.modelUrn })
    //   if (index >= 0) {
    //     props.viewer.isolate(temp, models[index])
    //     props.viewer.fitToView(temp, models[index])
    //   }

    // } else {
    //   let index = _.findIndex(models, v => { return v.myData.urn === e.rowData.modelUrn })
    //   if (index >= 0) {
    //     props.viewer.isolate(e.rowData.id, models[index])
    //     props.viewer.fitToView(e.rowData.id, models[index])
    //   }

    // }
  }
  const rowEventHandlers = {
    onClick: handleClickRow.bind(this),
  }
  const onColumnSort = ({ key, order }) => {
    setSortState( {
      ...sortState,
      [key]: sortState[key] === SortOrder.DESC ? null : order,
    })
    setData(data.reverse())
  }
  // useEffect(() => {
  //   if (hotTable.current &&props.data.length!==0) {
  //     let plugin = hotTable.current.hotInstance.getPlugin('collapsibleColumns')
  //     plugin.collapseAll()
  //   }
  // }, [hotTable,props.data])
  // useEffect(() => {
  //   if (hotTable.current) {
  //     let container = document.getElementById('hot-data-forge')
  //     Handsontable.Dom.addEvent(container, 'mousedown', function (event) {
  //       if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
  //         event.stopPropagation();
  //       }
  //     });

  //     Handsontable.Dom.addEvent(container, 'mouseup', function (event) {
  //       if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
  //         let temp = data.slice(0)
  //         _.forEach(temp, v => {
  //           v.checkBox = !event.target.checked
  //         })
  //         setData(temp)
  //       }
  //     });
  //   }

  // },[hotTable])
  useEffect(() => {
console.log(props.data)
console.log(props.columns)
if(props.columns.length>=1){
  props.data.sort(sorting)
}
    setData(props.data)
  }, [props.data])
  useEffect(() => {
    // let temp = []
    // _.forEach(props.columns, v => {
    //   if (v.title === 'checkBox')
    //     temp.push({ title: `<input type='checkbox' class='checker' id='hotdata-checker' />`, data: v.title, type: 'checkbox', className: 'checkbox-table', readOnly: false, width: 40 })
    //   else
    //     temp.push({ title: v.title, data: v.title, type: 'text', className: 'truncated', readOnly: true, width: 50 })
    // })
    setColumns(props.columns)
  }, [props.columns])
const sorting =(a,b)=>{
  if (a[props.columns[1].key] < b[props.columns[1].key]) {
    return -1;
  }
  if (a[props.columns[1].key] > b[props.columns[1].key]) {
    return 1;
  }
  return 0;
}
  const TableHeaderCell = (column) => {
    return (
      column.columnIndex == 0 ?
        <>
          <ButtonGroup>
            <IconButton style={{ borderRadius: 0 }} appearance='ghost' icon={<Icon icon='check' />} size='xs' onClick={handleCheckAllNone.bind(this, true)} />
            <IconButton style={{ borderRadius: 0 }} appearance='ghost' icon={<Icon icon='close' />} size='xs' onClick={handleCheckAllNone.bind(this, false)} />
          </ButtonGroup>

        </>
        // <input className={column.className} type="checkbox" onChange={handleCheckAll.bind(this, column.column)} checked={column.column.checkBox} /> 
        //<Text className={column.className}></Text>
        :
        <Text className={column.className}>{column.column.title}</Text>

    )
  }
  const TableCell = (cell) => {

    return (
      cell.columnIndex == 0 ?
        //   <input type="checkbox" checked={cell.cellData} onChange={handleChangeSelectRow.bind(this, cell)} />
        cell.rowData.children?
        <Checkbox checked={cell.cellData} onChange={handleChangeSelectRow.bind(this, cell)} />:<></>
        :
        <Tooltip title={cell.cellData}>
          <Text className={cell.className}>{cell.cellData}</Text>
        </Tooltip>

    )
  }
  const handleCheckAllNone = (e) => {
    let temp = data.slice(0)
    _.forEach(temp, v => {
      v.checkBox = e
    })
    setData(temp)
  }
  const handleChangeSelectRow = (cell) => {
    let temp = data.slice(0)
    temp[cell.rowIndex].checkBox = !temp[cell.rowIndex].checkBox
    setData(temp)
  }
  return (

    // <HotTable
    //   id='hot-data-forge'
    //   ref={hotTable}
    //   data={data}
    //   dropdownMenu={['filter_by_value', 'filter_by_condition', 'filter_action_bar']}
    //   filters={true}
    //   height='100%'
    //   width='100%'
    //   licenseKey='non-commercial-and-evaluation'
    //   colHeaders={(index) => {
    //     return `sss`
    //   }}
    //   columns={columns}
    //   rowHeaders={true}
    //   colWidths={(index) => {
    //     let w = columns[index].width
    //     return w
    //   }}

    //   rowHeights={23}
    //   manualColumnResize={true}

    //   // bindRowsWithHeaders={true}
    //   fillHandle={false}
    //   nestedRows={true}
    //   afterChange={(change, source) => {
    //     if (source === 'loadData') {
    //       if (hotTable.current && props.data.length !== 0) {
    //         let plugin = hotTable.current.hotInstance.getPlugin('nestedRows')
    //         plugin.dataManager.rewriteCache();
    //         plugin.collapsingUI.collapseAll();
    //       }

    //     }

    //   }}
    //   beforeOnCellMouseDown={(event, coords, TD, controller) => {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       event.stopPropagation();
    //     }
    //   }}
    //   beforeOnCellMouseUp={(event, coords, TD, controller) => {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       document.getElementById('hotdata-checker').checked = !event.target.checked
    //       let temp = data.slice(0)
    //       _.forEach(temp, v => {
    //         v.checkBox = !event.target.checked
    //       })
    //       setData(temp)
    //     }
    //   }}
    // />

    <AutoResizer>
      {({ width, height }) => (
        <BaseTable
          fixed
          expandColumnKey={props.mainParameter}
          width={width}
          height={height}
          columns={columns}
          data={data}
          components={{ TableCell, TableHeaderCell }}
          rowEventHandlers={rowEventHandlers}
          // sortState={sortState}
          // onColumnSort={onColumnSort}
        />
      )}
    </AutoResizer>


  )
}

export default DataEntryTable
