import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import IconTable from '../../layouts/generalComponents/IconTable';
import { Button, Icon, Tag, message } from 'antd';
import axios from 'axios';
import { Loader } from 'rsuite';

import { updateTableAsync } from '../../../functions/GeneralFunction';
import base64url from 'base64url';
import { getPublicUrl } from '../../../../module_BimApp/function/AdminFunction';
import { getUserAndProjectInfoInit } from '../../../utils';
import DateCell from '../cell/DateCell';
import FileBrowserCell from '../cell/FileBrowserCell';
import { ModalUploadFileSubUpD } from './modal/UploadFile.SubUpD';
import _ from 'lodash'
import SizeCell from '../cell/SizeCell';
import { ModalEditSubUpD } from './modal/Edit.SubUpD';
import { ModalFileVersionSubUpD } from './modal/FileVersion.SubUpD';



const typeBucketName = window.location.origin.includes('https://idd.wohhup.com') ? 'idd-dms' : 'wh-idd-dms';

// const processData = (data) => {
//     const flattenedData = [];
//     const flattenRowRecursive = (row, parentPath) => {
//         const dataPath = [...parentPath, row.name];
//         flattenedData.push({ ...row, dataPath });
//         if (row.children) {
//             row.children.forEach((underling) => {
//                 if (_.isObject(underling))
//                     flattenRowRecursive(underling, dataPath)
//             });
//         }
//     };
//     data.forEach((row) => flattenRowRecursive(row, []));
//     console.log(flattenedData)
//     return flattenedData;
// };
const getRouteToNode = (rowNode) => {
    if (!rowNode.parent) {
        return [];
    }
    return [
        ...getRouteToNode(rowNode.parent),
        rowNode.key ? rowNode.key : rowNode.data.employeeName,
    ];
};
export function SubUploadDocumentPage(props) {
    const { projectIsAppliedRfaView, history, localState, pageSheetTypeName, storeInitProject, storeInitUser } = props;

    const [isOpen, setIsOpen] = useState()
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [file, setFile] = useState()
    const [fileAdd, setFileAdd] = useState()
    const [fileEdit, setFileEdit] = useState()
    const [gridApi, setGridApi] = useState()
    const pathDir = `dmsSubCon/${props.storeInitProject.projectId}`
    const { email, role, projectId, projectName, isAdmin } = getUserAndProjectInfoInit(storeInitProject, storeInitUser);
    const [userInGroup, setUserInGroup] = useState()
    useEffect(() => {
        if (!props.storeInitProject.projectId) {
            props.history.push({
                pathname: '/dms/spreadsheet',
            })
            return
        }
        run()
    }, [])

    const run = async () => {
        try {
            setIsLoading(true)
            let res = await axios({
                url: '/api/projects/members',
                method: 'GET',
                params: {
                    // token: props.token,
                    q: props.storeInitProject.projectId
                }
            })
            if (res.data) {
                let temp = []
                res.data.groupUser.forEach(v => {
                    if (v.members.includes(email)) {
                        temp.push(v)
                    }
                })
                setUserInGroup(temp)
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: false,
            sortable: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            filter: 'agSetColumnFilter',
        };
    }, []);
    console.log(props)
    const columnDefs = [
        {
            field: 'size', headerName: 'Size', width: 100, maxWidth: 100,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                const isFolder = params.data.isFolder
                if (!isGroup && !isFolder) {
                    return {
                        component: SizeCell,
                 
                    }
                }
                return undefined;
            },
        },
        {
            field: 'currentVersion', headerName: 'Version', minWidth: 100, maxWidth: 100,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                const isFolder = params.data.isFolder
                if (!isGroup && !isFolder) {
                    return {
                        component: handleVersionCell,
                    }
                }
                return undefined;
            },
            cellStyle: { textAlign: 'center' }
        },

        {
            field: 'updatedAt', headerName: 'Date', width: 100, maxWidth: 150,
            cellRendererSelector: params => {
                const isGroup = params.node.group;
                const isFolder = params.data.isFolder
                if (!isGroup && !isFolder) {
                    return {
                        component: DateCell,
                    }
                }
                return undefined;
            }
        },

        {
            field: 'updatedBy', headerName: 'By', width: 100, maxWidth: 180
        },
        {
            headerName: 'Action',
            field: 'action',
            minWidth: 220, maxWidth: 220,
            // lockVisible: !(user.isAdmin || roleForAddEditModule.includes(role)),
            lockPosition: 'right',
            suppressMovable: true,
            // hide: !(user.isAdmin || roleForAddEditModule.includes(role)),
            // suppressToolPanel: !(user.isAdmin || roleForAddEditModule.includes(role)),
            cellRendererSelector: params => {
                return {
                    component: ActionButton,
                    params: { userInGroup, handleEdit, handleDownload, handleView, handleAddFile, handleAddFolder, handleDelete, isAdmin, role, email }
                }
            },
            // cellStyle:{
            //     textAlign:'right'
            // }
        },
    ]
    const handleVersionCell = (params) => {
        return (
            <div style={{ width: '100%' }}>
                <Tag onClick={handleVersion.bind(this, params.node)} >{params.data.currentVersion}</Tag>
            </div>
        )
    }

    const handleEdit = (e) => {
        setFileEdit(e)
    }

    const handleDownload = async (e) => {
        try {
            let key = `${pathDir}/${e.parent.data._id}/${e.data.versionId}/${e.data.name}`
            let url = await getPublicUrl(key, 10000, typeBucketName)
            window.open(url, '_blank');
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }

    const handleView = async (e) => {
        try {
            let key = `${pathDir}/${e.parent.data._id}/${e.data.versionId}/${e.data.name}`
            console.log(key)
            let linkToOpen = `${window.location.origin}/dms-viewer/${base64url(key)}`;
            window.open(linkToOpen, '_blank');
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }

    }
    const handleAddFile = (e) => {
        setFileAdd(e.data)
    }
    const handleAddFolder = async (node) => {
        try {
            let name = window.prompt(`Please input folder name`)
            if (!name) return
            setIsLoading(true)
            let res = await axios({
                url: '/api/subcon-upload-file',
                method: 'POST',
                data: {
                    token: props.token,
                    projectId,
                    name,
                    parentId: node ? node.data._id : null
                }
            })
            if (res.data) {
                const route = node ? getRouteToNode(node) : []
                gridApi.api.applyServerSideTransaction({
                    route: node ? route : [],
                    add: [res.data]
                })
            }
            setIsLoading(false)
        } catch (ex) {
            console.log(ex)
            message.warning(ex?.response?.data)
            setIsLoading(false)
        }
    }
    const handleVersion = (e) => {
        setFile(e)
    }
    const handleDelete = async (e) => {
        try {
            if (!window.confirm('Are you want to delete?')) return
            setIsLoading(true)
            let res = await axios({
                url: '/api/subcon-upload-file',
                method: 'delete',
                params: {
                    token: props.token,
                    _id: e.data._id,
                    projectId: props.storeInitProject.projectId
                }
            })
            if (res.data) {
                debugger
                const route = getRouteToNode(e)
                gridApi.api.applyServerSideTransaction({
                    route: route.slice(0, route.length - 1),
                    remove: [e.data]
                })
            }
            setIsLoading(false)
        } catch (ex) {
            message.warning(ex.message)
            setIsLoading(false)
        }
    }

    const runRequest = (_id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios({
                    url: '/api/subcon-upload-file/getChildById',
                    method: 'GET',
                    params: {
                        token: props.token,
                        projectId: props.storeInitProject.projectId,
                        _id
                    }
                })
                if (res.data)
                    resolve(res.data)
                else {
                    reject()
                }
            } catch {
                reject()
            }

        })
    }
    const onGridReady = useCallback((params) => {
        setGridApi(params)
        var datasource = {
            getRows: async (params) => {
                // console.log('ServerSideDatasource.getRows: params = ', params);
                const request = params.request;
                const groupKeys = params.parentNode.level === -1 ? [undefined] : params.request.groupKeys
                for (let index in groupKeys) {

                }
                try {
                    if (groupKeys.length !== 0) {
                        let _id = groupKeys[groupKeys.length - 1]
                        const allRows = await runRequest(_id)


                        const doingInfinite = request.startRow != null && request.endRow != null;
                        const result = doingInfinite
                            ? {
                                rowData: allRows.slice(request.startRow, request.endRow),
                                rowCount: allRows.length,
                            }
                            : { rowData: allRows };
                        console.log(result);
                        params.success(result);
                    }

                } catch {
                    params.fail()
                }
            },
        };
        params.api.setServerSideDatasource(datasource);
    }, []);
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: 'Files',
            field: 'name',
            // minWidth: 330,
            cellRendererParams: {
                // checkbox: true,
                suppressCount: true,
                innerRenderer: FileBrowserCell,
                innerRendererParams:{setIsLoading, pathDir, role, isAdmin}
            },
        };
    }, []);

    const getDataPath = useMemo(() => {
        return (data) => data.dataPath;
    }, []);
    const getRowId = useMemo(() => {
        return (params) => params.data._id;
    }, []);
    const isServerSideGroup = useCallback((dataItem) => {
        return dataItem.isFolder;
    }, []);
    const getServerSideGroupKey = useCallback((dataItem) => {
        // specify which group key to use
        return dataItem._id;
    }, []);
    const isServerSideGroupOpenByDefault = useCallback((params) => {
        // open first two levels by default
        return params.rowNode.level < 2;
    }, []);
    return (
        <>
            {isLoading &&
                <div style={{ zIndex: 100000, position: 'absolute', width: '100%', height: '-webkit-fill-available', textAlign: 'center' }} >
                    <Loader backdrop center content="Loading..." speed="fast" size="md" vertical style={{ zIndex: 1000 }} />
                </div>

            }
            {userInGroup &&
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 55px)' }}>
                    <div style={{ height: '50px', width: '100%', display: 'flex', backgroundColor: '#f1f2f6', alignItems: 'center', padding: '0px 5px' }} >
                        {(role === 'Document Controller' || role === 'Consultant' || isAdmin)
                            &&
                            <IconTable type='plus' onClick={handleAddFolder.bind(this, null, null)} tooltipTitle='Add File' />
                        }
                    </div>
                    <div className='dms ag-theme-alpine' style={{
                        height: '100%',
                        width: 'calc(100% - 55px)',
                        left: 55,
                        position: 'sticky'
                    }}>
                        <AgGridReact
                            defaultColDef={defaultColDef}
                            // rowData={files}
                            columnDefs={columnDefs}
                            autoGroupColumnDef={autoGroupColumnDef}
                            animateRows={true}
                            treeData={true}
                            rowHeight={35}
                            onGridReady={onGridReady}
                            suppressContextMenu={true}
                            getDataPath={getDataPath}
                            getRowId={getRowId}
                            masterDetail={true}
                            isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                            isServerSideGroup={isServerSideGroup}
                            getServerSideGroupKey={getServerSideGroupKey}
                            rowModelType={'serverSide'}
                        ></AgGridReact>
                    </div>
                </div>
            }
            {fileEdit &&
                <ModalEditSubUpD
                    {...props}
                    setClose={setFileEdit}
                    pathDir={pathDir}
                    setIsLoading={setIsLoading}
                    files={files}
                    file={fileEdit}
                    gridApi={gridApi}
                />
            }
            {fileAdd &&
                <ModalUploadFileSubUpD
                    {...props}
                    setClose={setFileAdd}
                    pathDir={pathDir}
                    setIsLoading={setIsLoading}
                    files={files}
                    file={fileAdd}
                />
            }
            {file &&
                <ModalFileVersionSubUpD
                    {...props}
                    setClose={setFile}
                    pathDir={pathDir}
                    file={file}
                    setIsLoading={setIsLoading}
                    
                />
            }
        </>


    )
}


const ActionButton = ({ userInGroup, handleEdit, handleDownload, handleView, handleAddFile, handleAddFolder, handleDelete, isAdmin, role, email, ...params }) => {
    const [canView, setCanView] = useState()
    const [canCreate, setCanCreate] = useState()
    const [canUpload, setCanUpload] = useState()
    useEffect(() => {
        if (params.node.level === 1) {
            // params.node.canView = true
            _.forEach(params.data.permissionView, v => {
                if (userInGroup.includes(v)) {
                    setCanView(true)
                    params.node.canView = true
                    return false
                }
                if (v === email) {
                    setCanView(true)
                    params.node.canView = true
                    return false
                }
            })
            _.forEach(params.data.permissionCreateFolder, v => {
                if (userInGroup.includes(v)) {
                    setCanCreate(true)
                    params.node.canCreate = true
                    return false
                }
                if (v === email) {
                    setCanCreate(true)
                    params.node.canCreate = true
                    return false
                }
            })
            _.forEach(params.data.permissionUpload, v => {
                if (userInGroup.includes(v)) {
                    setCanUpload(true)
                    params.node.canUpload = true
                    return false
                }
                if (v === email) {
                    setCanUpload(true)
                    params.node.canUpload = true
                    return false
                }
            })
        }
        else if (params.node.level > 1) {
            let split = params.data.path.split('/')
            let data = params.api.getRowNode(split[2])
            setCanCreate(data.canCreate)
            setCanView(data.canView)
            setCanUpload(data.canUpload)
        }
    }, [])
    return (
        params.data.isFolder ?
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 5, alignItems: 'center', height: '100%' }}>
                {params.node.level < 2 ?
                    ((role === 'Document Controller' || isAdmin) &&
                        <>
                            <Button size='small' type='primary' onClick={handleAddFolder.bind(this, params.node)} icon='folder-add' className='dsm-button-icon'></Button>
                        </>)
                    :
                    ((role === 'Document Controller' || isAdmin || canCreate) &&
                        <>
                            <Button size='small' type='primary' onClick={handleAddFolder.bind(this, params.node)} icon='folder-add' className='dsm-button-icon'></Button>
                        </>)
                }
                {params.node.level > 1
                    &&
                    (role === 'Document Controller' || isAdmin || canUpload)
                    && <Button size='small' type='primary' onClick={handleAddFile.bind(this, params.node)} icon='file-add' className='dsm-button-icon'></Button>
                }
                {(role === 'Document Controller' || isAdmin) &&
                    <>
                        <Button size='small' type='primary' onClick={handleEdit.bind(this, params.node)} icon='edit' className='dsm-button-icon'></Button>
                    </>
                }

                {(role === 'Document Controller' || isAdmin) &&
                    <Button size='small' type='danger' onClick={handleDelete.bind(this, params.node)} icon='delete' className='dsm-button-icon'
                    ></Button>}
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 5, alignItems: 'center', height: '100%' }}>
                {/* {(role === 'Document Controller' || isAdmin || canView) &&
                    <Button size='small' type='primary' onClick={handleView.bind(this, params.node)} icon='eye' className='dsm-button-icon'></Button>} */}
                {(role === 'Document Controller' || isAdmin || canView) &&
                    <Button size='small' type='primary' onClick={handleDownload.bind(this, params.node)} icon='download' className='dsm-button-icon'></Button>}
                {(role === 'Document Controller' || isAdmin) &&
                    <Button size='small' type='danger' onClick={handleDelete.bind(this, params.node)} icon='delete' className='dsm-button-icon'
                    ></Button>}
            </div>
    )
}