import React from 'react';
import { Typography } from 'antd';
import { Icon } from 'rsuite';
import _ from 'lodash'
import { Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { handleReactTableFilterDate } from '../function/TableFunction'
import moment from 'moment';

const { Text } = Typography
export default  (_this) => {
  return ([
    {
      Header: 'Project Name', accessor: 'projectName', id: 'projectName', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => (
        <div style={{ overflow: 'hidden' }}>
          <Link to={{
            pathname: `/projectdetail`,
            state: {
              userCreatedProject: row.original.userCreated,
              projectId: row.original.project.projectId,
              projectName: row.original.project.projectName,
              sharePointId: row.original.sharePointId,
              issueKey: row.original.issueKey,
              trimbleKey: row.original.trimbleKey,
              typeProject: row.original.typeProject,
              useRfa:row.original.useRfa,
            }
          }}>
            <Label style={{ float: 'left', marginRight: 5 }} size='mini' color='green'>
              <Icon size='lg' icon='project' > Project</Icon>
            </Label>
            <div style={{ color: 'black', verticalAlign: 'middle', display: 'inline' }}>
              {value === undefined ? row.original.project.projectName : value}</div>
          </Link>
        </div>
      )
    },
    { Header: 'Folders', accessor: 'folderCount', id: 'folderCount', units: false, filter: "includes" },
    {
      Header: 'Created Date', accessor: 'createdDate', id: 'createdDate', units: false, type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    {
      Header: 'Updated Date', accessor: 'uploadDate', id: 'uploadDate', units: false,  type: 'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    { Header: 'Created by', accessor: 'userCreated', id: 'userCreated', units: false, filter: "includes", },
  ])
}