import moment from 'moment'
// import ReactGA from 'react-ga';
import { userLoggedIn } from "../../../actions/app/auth";

import { createStore, applyMiddleware } from "redux";
import rootReducer from "../../../rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export function sortIssue(a, b) {
    return b.id.localeCompare(a.id, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
}
export function storageUser(user) {
    localStorage.bookwormJWT = user.token
    store.dispatch(userLoggedIn(user))
}

export function sortItemName(a, b) {

    if (a.item.itemName < b.item.itemName) {
        return -1;
    }
    if (a.item.itemName > b.item.itemName) {
        return 1;
    }
    return 0;
}

export function sortFolderName(a, b) {

    // if (a.folderName < b.folderName) {
    //     return -1;
    // }
    // if (a.folderName > b.folderName) {
    //     return 1;
    // }
    // return 0;
    return a.folderName.localeCompare(b.folderName, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
}

export function sortProjectName(a, b) {

    if (a.projectName < b.projectName) {
        return -1;
    }
    if (a.projectName > b.projectName) {
        return 1;
    }
    return 0;
}

export function sortItemCreatedDate(a, b) {
    let aDate = moment(a.createdDate)
    let bDate = moment(b.createdDate)
    if (aDate < bDate) {
        return -1;
    }
    if (aDate > bDate) {
        return 1;
    }
    return 0;
}
export function sortItemUpdatedDate(a, b) {
    let aDate = moment(a.uploadDate)
    let bDate = moment(b.uploadDate)
    if (aDate < bDate) {
        return -1;
    }
    if (aDate > bDate) {
        return 1;
    }
    return 0;
}

export function sortItemStatus(a, b) {
    let aStatus = a.status
    let bStatus = b.status
    if (aStatus < bStatus) {
        return -1;
    }
    if (aStatus > bStatus) {
        return 1;
    }
    return 0;
}

export function sortDescendingItemName(a, b) {

    if (a.item.itemName < b.item.itemName) {
        return 1;
    }
    if (a.item.itemName > b.item.itemName) {
        return -1;
    }
    return 0;
}
export function sortDescendingFolderName(a, b) {

    // if (a.folderName < b.folderName) {
    //     return 1;
    // }
    // if (a.folderName > b.folderName) {
    //     return -1;
    // }
    // return 0;
    return b.folderName.localeCompare(a.folderName, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
}
export function sortDescendingProjectName(a, b) {

    if (a.projectName < b.projectName) {
        return 1;
    }
    if (a.projectName > b.projectName) {
        return -1;
    }
    return 0;
}
export function sortDescendingItemCreatedDate(a, b) {
    let aDate = moment(a.createdDate)
    let bDate = moment(b.createdDate)
    if (aDate < bDate) {
        return 1;
    }
    if (aDate > bDate) {
        return -1;
    }
    return 0;
}
export function sortDescendingItemUpdatedDate(a, b) {
    let aDate = moment(a.uploadDate)
    let bDate = moment(b.uploadDate)
    if (aDate < bDate) {
        return 1;
    }
    if (aDate > bDate) {
        return -1;
    }
    return 0;
}

export function sortDescendingItemStatus(a, b) {
    let aStatus = a.status
    let bStatus = b.status
    if (aStatus < bStatus) {
        return 1;
    }
    if (aStatus > bStatus) {
        return -1;
    }
    return 0;
}


// export function logPageView() {
//     ReactGA.set({ page: window.location.pathname }); // Update the user's current page
//     ReactGA.pageview(window.location.pathname);// Record a pageview for the given page
// }