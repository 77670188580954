import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { setManpowerData, setManpowerLoading } from '../../../actions/app/manpower'
import { InputPicker, AutoComplete } from 'rsuite'
import { Select } from 'antd';

const { Option } = Select;
function CellInputPicker(listValues, props) {
  const [data, setData] = useState(props.value)

  useEffect(() => {
    setData(props.value)
  }, [])

  const handleChangeValue = (e) => {
    props.node.setDataValue(props.column.colId, e)
    props.api.stopEditing()

  }
  function getValue() {
    console.log(data)
    return data
  }

  function isPopup() {
    return true;
  }
  return (
    <div
      style={{ width: '200px', position: 'fixed',  paddingLeft: 1 , paddingTop:1 }}
    >
      <Select
      id='mdp-select-custom'
        showSearch
    
        style={{ width: 200 ,height: 25}}
        // placeholder="Select a person"
        // optionFilterProp="children"
        onChange={handleChangeValue}
        value={data}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {listValues[props.column.colId] &&
          listValues[props.column.colId].map(i => 
            <Option value={i.value}>{i.value}</Option>
          )}

      </Select>
      {/* <InputPicker appearance='default' data={listValues[props.column.colId]} block size="sm" cleanable={false} value={data} onChange={handleChangeValue} /> */}

    </div>
  )
}

export default CellInputPicker






