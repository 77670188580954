import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { add, update, addAvatar, getAvatar } from '../../api/employee';
import ImgCrop from 'antd-img-crop';
import Resizer from 'react-image-file-resizer';
import {
  Button,
  Input,
  Form,
  notification,
  InputNumber,
  Select,
  Upload,
  Icon,
  DatePicker,
  Col,
  Row,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { DESIGNATIONS, OCCUPATIONS } from '../../constant/index';
const { Option } = Select;
const _ = require('lodash');

function WorkerForm(props) {
  const { record, isAdd, id, nationalities, occupations } = props;
  const { setVisible, getDatas } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [fileSmall, setFileSmall] = useState(null);
  const projectId = useSelector((state) => state.project.projectId);

  const optionTrades = DESIGNATIONS.map((d) => (
    <Option value={d} key={d}>
      {d}
    </Option>
  ));

  const optionOccupation = occupations.map((d) => (
    <Option value={d.name} key={d.name}>
      {d.name}
    </Option>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      props.form.validateFields(async (err, values) => {
        if (!err) {
          let payload = { ...values, employeeType: 2, projectId };
          //update image then get image key first
          if (file) {
            try {
              let res = await addAvatar({
                file: file,
                data: {
                  id,
                  projectId,
                  fileName: file.name,
                },
              });
              let avatarId = res;
              payload = { ...payload, avatar: avatarId };
            } catch (error) {}
          }

          if (fileSmall) {
            try {
              let res = await addAvatar({
                file: fileSmall,
                data: {
                  id,
                  projectId,
                  fileName: '32x32-' + fileSmall.name,
                },
              });
              let avatarId = res;
              payload = { ...payload, picture: avatarId };
            } catch (error) {}
          }

          if (isAdd) {
            try {
              await add(payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          } else {
            try {
              await update(id, payload);
              await setVisible(false);
              await getDatas();
            } catch (error) {
              notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
              });
            }
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (isAdd) {
      props.form.setFieldsValue({
        name: '',
        // employerCode: '',
        employeeCode: '',
        age: 25,
        designation: 'GW',
        occupation: 'GENERAL WORKERS',
        nationality: 'Singapore',
        termination: null,
        company: 'WH',
        phoneNumber: '',
        dateOnInSiteJoin: '',
        workPermitFinNo: '',
        avatar: '',
      });
      setImageUrl('');
      setFile(null);
      setFileSmall(null);
    } else {
      props.form.setFieldsValue({
        name: record.name,
        avatar: record.avatar,
        avatarPath: record.avatarPath,
        employeeCode: record.employeeCode,
        // employerCode: record.employerCode,
        age: record.age,
        designation: record.designation,
        occupation: record.occupation,
        nationality: record.nationality,
        company: record.company,
        phoneNumber: record.phoneNumber,
        dateOnInSiteJoin: record.dateOnInSiteJoin,
        workPermitFinNo: record.workPermitFinNo,
        termination: record.termination ? moment(record.termination) : null,
        dateOfInSiteJoint: record.dateOfInSiteJoint
          ? moment(record.dateOfInSiteJoint)
          : null,
      });

      getImageFromAvatarId(record);
      setFile(null);
      setFileSmall(null);
    }
  }, [isAdd, record]);

  async function getImageFromAvatarId(record) {
    if (_.isNil(record.avatar)) {
      setImageUrl('');
    } else {
      let res = await getAvatar({ key: record.avatar, time: 60 });

      setImageUrl(res);
    }
  }

  const { getFieldDecorator } = props.form;

  const uploadButton = (
    <div>
      <Icon type={'plus'} />
      <div className='ant-upload-text'>Upload</div>
    </div>
  );

  const nationalityOptions = nationalities.map((d) => (
    <Option value={d.id} key={d.id}>
      {d.name}
    </Option>
  ));

  async function handleImport(file) {
    setFile(file);
    getImageLink(file);

    let newFile = await resizeImage(file);
    setFileSmall(newFile);
    return false;
  }

  async function getImageLink(file) {
    let src = null;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }

    setImageUrl(src);
  }

  function resizeImage(file) {
    if (file) {
      try {
        let extension = file.name.split('.').pop();
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            32,
            32,
            extension,
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      } catch (error) {}
    }
  }

  return (
    <div>
      <Form
        className='ant-form-custom'
        name='control-ref'
        onSubmit={(e) => handleSubmit(e)}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row>
          <Col span={12}>
            <Form.Item label='Employee Code'>
              {getFieldDecorator('employeeCode', {
                rules: [
                  { required: true, message: 'Please input EmployeeCode!' },
                ],
              })(<Input placeholder='Employee Code' />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='Name'>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input name!' }],
              })(<Input placeholder='Name' />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='Company'>
              {getFieldDecorator('company', {
                rules: [{ required: false }],
              })(<Input placeholder='Company' />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Designation'>
              {getFieldDecorator('designation', {
                rules: [
                  { required: false, message: 'Please input Designation!' },
                ],
              })(<Select>{optionTrades}</Select>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Occupation'>
              {getFieldDecorator('occupation', {
                rules: [
                  { required: true, message: 'Please input Occupation!' },
                ],
              })(<Select>{optionOccupation}</Select>)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Nationality'>
              {getFieldDecorator('nationality', {
                rules: [{ required: false }],
              })(
                <Select defaultValue='Singapore' style={{ width: '100%' }}>
                  <Option value='Indian'>Indian</Option>
                  <Option value='Myanmar'>Myanmar</Option>
                  <Option value='Bangladesh'>Bangladesh</Option>
                  <Option value='Thailand'>Thailand</Option>
                  <Option value='China'>China</Option>
                  <Option value='Paskistan'>Paskistan</Option>
                  <Option value='Malaysia'>Malaysia</Option>
                  <Option value='Singapore'>Singapore</Option>
                  <Option value='Other'>Other</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Transfer In Date'>
              {getFieldDecorator('dateOfInSiteJoint', {
                rules: [],
              })(<DatePicker format='DD/MM/YYYY' />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='Transfer out date'>
              {getFieldDecorator('termination', {
                rules: [],
              })(<DatePicker format='DD/MM/YYYY' />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='Avatar'>
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader float-right'
                showUploadList={false}
                accept='image/*'
                beforeUpload={handleImport}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Form.create({ name: 'WorkerAntForm' })(WorkerForm);
