import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ConcDoInput from "./FormInputSingleEntity/ConcDoInput";
import ConcInvInput from "./FormInputSingleEntity/ConcInvInput";
import RebarDoInput from "./FormInputSingleEntity/RebarDoInput";
import RebarInvInput from "./FormInputSingleEntity/RebarInvInput";
import ExportBatchConcDo from "./ExportFile/export_batch_conc_do";
import _ from "lodash";
import { Button, Radio, Input, Tooltip, Popconfirm, DatePicker, message, Icon } from "antd";

import * as exportExcel from "../function/func_export_to_excel";
import * as downloadPdf from "../function/func_download_pdf";
import { func_save_data } from "../function/func_save_data";
import ButtonGroup from "antd/lib/button/button-group";
const { Search } = Input;
const { MonthPicker } = DatePicker;

function ButtonsOnTop({
   tableRef,
   data,
   filter_data,
   current,
   filterMode,
   generalState,
   forceReRender,
   setFilterMode,
   setShownAdvanceFilter,
   setGeneralState,
   setData,
   setForceReRender,
}) {
   const listEditAll = ["QS", "Project Admin", "admin"];
   const listEditLimit = ["Production"];

   const projectId = useSelector((state) => state.project.projectId);
   let get_user_role = useSelector((state) => {
      if (state.user.role === "admin") {
         return "admin";
      }

      let contractors = state.user.contractors;
      if (projectId && _.isArray(contractors)) {
         let contractor = contractors.find((x) => x.projectId === projectId);
         if (contractor) {
            return contractor.role;
         }
      }

      return "";
   });
   const [visibleConcDoInput, setVisibleConcDoInput] = useState(false);
   const [visibleConcInvInput, setVisibleConcInvInput] = useState(false);
   const [visibleRebarDoInput, setVisibleRebarDoInput] = useState(false);
   const [visibleRebarInvInput, setVisibleRebarInvInput] = useState(false);
   const [visibleModelExportBatchFile, setVisibleModelExportBatchFile] = useState(false);

   const [user_role, set_user_role] = useState();

   useEffect(() => {
      let role = "edit_none";
      if (listEditAll.includes(get_user_role)) {
         role = "edit_all";
      }
      if (listEditLimit.includes(get_user_role)) {
         role = "edit_limit";
      }

      // role = "edit_all";
      // const role = localStorage.getItem("fp_user_role");
      set_user_role(role);
   }, []);

   const saveData = async () => {
      const dt = await func_save_data(current, data);
      if (dt !== undefined) {
         setData(dt.save_data);
         if (dt.got_delete_row || current.includes("inv")) {
            setForceReRender(!forceReRender);
         }
      }
   };

   const showModal = () => {
      switch (current) {
         case "conc_do":
            return setVisibleConcDoInput(true);
         case "conc_inv":
            return setVisibleConcInvInput(true);
         case "rebar_do":
            return setVisibleRebarDoInput(true);
         case "rebar_inv":
            return setVisibleRebarInvInput(true);
         default:
            message.warn("This function is working for DO only, not for Invoice.");
      }
   };
   const handleOnSeach = (value) => {
      if (tableRef.current !== null) {
         const search = tableRef.current.hotInstance.getPlugin("search");
         const queryResult = search.query(value);
         const result = tableRef.current.hotInstance.getPlugin("search").setSearchResultClass("search-result-custom");
         tableRef.current.hotInstance.render();
      }
   };

   const handleClickFilter = (n) => {
      setFilterMode(n);
   };

   const handleClickShownAdvanceFilter = () => {
      setShownAdvanceFilter(true);
   };

   const handleGeneralState = (n) => {
      setGeneralState(n);
   };

   const handleExportDataToExcel = () => {
      switch (current) {
         case "conc_do":
            exportExcel.Func_Export_Conc_DO_To_Excel(data, filter_data);
            message.success("Data has exported to Excel file!");
            break;
         case "conc_inv":
            exportExcel.Func_Export_Conc_Inv_To_Excel(data);
            message.success("Data has exported to Excel file!");
            break;
         case "rebar_do":
            exportExcel.Func_Export_Rebar_DO_To_Excel(data);
            message.success("Data has exported to Excel file!");
            break;
         case "rebar_inv":
            exportExcel.Func_Export_Rebar_Inv_To_Excel(data);
            message.success("Data has exported to Excel file!");
            break;

         default:
            break;
      }
   };

   const handleExportPdfScanForm = () => {
      switch (current) {
         case "conc_do":
            downloadPdf.Func_Download_Pdf_Conc_Do(projectId, data, filter_data);

            break;
         // case "conc_inv":
         //    exportExcel.Func_Export_Conc_Inv_To_Excel(data);
         //    message.success("Data has exported to Excel file!");
         //    break;
         // case "rebar_do":
         //    exportExcel.Func_Export_Rebar_DO_To_Excel(data);
         //    message.success("Data has exported to Excel file!");
         //    break;
         // case "rebar_inv":
         //    exportExcel.Func_Export_Rebar_Inv_To_Excel(data);
         //    message.success("Data has exported to Excel file!");
         //    break;

         default:
            break;
      }
   };
   //general state setup
   //1 new data by import pdf

   //2 new data by upload excel
   //10 compare mode

   //5 new data by key in
   //32 upload pdf

   return (
      <div>
         <div className="button-on-top">
            <div>
               <Search
                  placeholder="input search text"
                  onSearch={handleOnSeach}
                  allowClear
                  enterButton="Search"
                  style={{ width: 225, marginLeft: "1px" }}
               />

               <ButtonGroup style={{ marginLeft: "8px" }}>
                  {user_role === "edit_all" || (user_role === "edit_limit" && current.includes("do")) ? (
                     <>
                        <Tooltip title="New DO by key in.">
                           <Button
                              type="default"
                              size="middle"
                              onClick={showModal}
                              disabled={generalState === 0 ? false : true}
                           >
                              <Icon type="plus" />
                           </Button>
                        </Tooltip>

                        <Tooltip title="New DOs by extract data from pdf.">
                           <Button
                              type="default"
                              size="middle"
                              disabled={generalState === 0 || generalState === 31 ? false : true}
                              onClick={() => {
                                 if (current !== "rebar_do") {
                                    handleGeneralState(1);
                                 } else {
                                    message.warn("under developement, stay tune");
                                 }
                              }}
                           >
                              <Icon type="scan" />
                           </Button>
                        </Tooltip>
                        <Tooltip title="New DOs by upload Excel file.">
                           <Button
                              color="default"
                              disabled={generalState === 0 || generalState === 31 ? false : true}
                              onClick={() => {
                                 if (current !== "rebar_do") {
                                    handleGeneralState(2);
                                 } else {
                                    message.warn("under developement, stay tune");
                                 }
                              }}
                           >
                              <Icon type="upload" />
                              xlsx
                           </Button>
                        </Tooltip>
                        <Tooltip title="Upload pdf file.">
                           <Button
                              color="default"
                              disabled={generalState === 0 || generalState === 31 ? false : true}
                              onClick={() => handleGeneralState(32)}
                           >
                              <Icon type="cloud-upload" />
                              pdf
                           </Button>
                        </Tooltip>

                        <Tooltip placement="top" title="Check discrepancy between DO vs Inv">
                           <Popconfirm
                              title={"You want to compare data?"}
                              onConfirm={() => handleGeneralState(10)}
                              okText="Yes"
                              cancelText="No"
                           >
                              <Button type="default" size="middle" disabled={generalState === 0 ? false : true}>
                                 <Icon type="swap" />
                              </Button>
                           </Popconfirm>
                        </Tooltip>
                     </>
                  ) : null}
                  <Tooltip title="Export data to Excel.">
                     <Popconfirm
                        title="Are you sure to export file to Excel?"
                        // onConfirm={() => handleGeneralState(31)}
                        onConfirm={() => handleExportDataToExcel()}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                     >
                        <Button color="default" disabled={generalState === 0 || generalState === 31 ? false : true}>
                           <Icon type="cloud-download" />
                           xlsx
                        </Button>
                     </Popconfirm>
                  </Tooltip>

                  <Tooltip title="Export pdf scan form.">
                     {/* <Popconfirm
                        title="Pdf of current list will be downloaded, Are you sure?"
                        // onConfirm={() => handleGeneralState(31)}
                        onConfirm={() => handleExportPdfScanForm()}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                     > */}
                     <Button
                        color="default"
                        disabled={generalState === 0 || generalState === 31 ? false : true}
                        onClick={() => setVisibleModelExportBatchFile(true)}
                     >
                        <Icon type="cloud-download" />
                        pdf
                     </Button>
                     {/* </Popconfirm> */}
                  </Tooltip>

                  {user_role === "edit_all" || (user_role === "edit_limit" && current.includes("do")) ? (
                     <Tooltip title="Save data.">
                        <Button
                           color="default"
                           disabled={generalState === 0 || generalState === 31 ? false : true}
                           onClick={() => {
                              saveData();
                           }}
                        >
                           <Icon type="save" />
                        </Button>
                     </Tooltip>
                  ) : null}
               </ButtonGroup>
            </div>
            <ButtonGroup>
               <Radio.Group defaultValue={filterMode} buttonStyle="solid">
                  <div>
                     <Tooltip title="Shown DO data last 1 week.">
                        <Radio.Button
                           value="2"
                           onClick={() => {
                              handleClickFilter(2);
                           }}
                        >
                           last 1 week
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO data last 1 month">
                        <Radio.Button
                           value="3"
                           onClick={() => {
                              // setFilterMode(3);
                              handleClickFilter(3);
                           }}
                        >
                           this month
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO data last 3 months.">
                        <Radio.Button
                           value="4"
                           onClick={() => {
                              handleClickFilter(4);
                           }}
                        >
                           these 3 mths
                        </Radio.Button>
                     </Tooltip>

                     <Tooltip title="Shown DO for selected months.">
                        <Radio.Button value="5">
                           <MonthPicker
                              size="small"
                              picker="month"
                              placeholder="Select month"
                              bordered={false}
                              style={{ width: 125 }}
                              format={"MM_YYYY"}
                              onChange={(date, dateString) => {
                                 handleClickFilter("5_" + dateString);
                              }}
                           />
                        </Radio.Button>
                     </Tooltip>

                     {/* <Tooltip title="Shown all DO data.">
                    <Radio.Button
                      value="0"
                      onClick={() => {
                        handleClickFilter(0);
                      }}
                    >
                      All
                    </Radio.Button>
                  </Tooltip> */}

                     <Tooltip title="Advance filter data.">
                        <Radio.Button
                           value="10"
                           onClick={() => {
                              handleClickShownAdvanceFilter();
                           }}
                        >
                           Filter
                        </Radio.Button>
                     </Tooltip>
                  </div>
                  {/* </Space> */}
               </Radio.Group>
            </ButtonGroup>
         </div>

         <div className="model-input">
            <ConcDoInput
               visible={visibleConcDoInput}
               onCreate={() => {
                  setVisibleConcDoInput(false);
                  setForceReRender(!forceReRender);
               }}
               onCancel={() => {
                  setVisibleConcDoInput(false);
               }}
            />

            <ConcInvInput
               visible={visibleConcInvInput}
               onCreate={() => {
                  setVisibleConcInvInput(false);
                  setForceReRender(!forceReRender);
               }}
               onCancel={() => {
                  setVisibleConcInvInput(false);
               }}
            />

            <RebarDoInput
               visible={visibleRebarDoInput}
               onCreate={() => setVisibleRebarDoInput(false)}
               onCancel={() => {
                  setVisibleRebarDoInput(false);
               }}
            />

            <RebarInvInput
               visible={visibleRebarInvInput}
               onCreate={() => setVisibleRebarInvInput(false)}
               onCancel={() => {
                  setVisibleRebarInvInput(false);
               }}
            />
            <ExportBatchConcDo
               visible={visibleModelExportBatchFile}
               onCreate={() => setVisibleModelExportBatchFile(false)}
               onCancel={() => {
                  setVisibleModelExportBatchFile(false);
               }}
            />
         </div>
      </div>
   );
}

export default ButtonsOnTop;
