import { DatePicker, Icon, Input, message, Modal, Select, Tooltip, Upload, Checkbox } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import styled from 'styled-components';
import { arrayStatusDrawingText, colorType, EDIT_DURATION_MIN } from '../../../constants';
import { checkIfStringContainNumberOnly, debounceFnc, generateShortUid, getConsultantStatusReviewByCompany, getDisciplineOfRefString, getRefStringWithVersion, validateEmailInput } from '../../../utils';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';
import IconTable from '../generalComponents/IconTable';
import ListRequirementForm from '../generalComponents/ListRequirementForm';
import { getInfoValueFromRefDataForm } from './CellForm';
import { getTradeNameFnc } from './FormDrawingTypeOrder';
import TableDrawingRFA from './TableDrawingRFA';


const { Option } = Select;
const { TextArea } = Input;


export const versionArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'];

const Table = (props) => {
   return (
      <AutoResizer>
         {({ width, height }) => {
            return (
               <BaseTable
                  {...props}
                  width={width}
                  height={height}
               />
            );
         }}
      </AutoResizer>
   );
};


const PanelAddNewRFA = ({ onClickCancelModal, onClickApplyAddNewRFA, stateRow, getSheetRows, stateProject, submissionRfaFormUpdateOrForwardEmail }) => {


   const {
      roleTradeCompany: { company: companyUser }, companies, listUser,
      listGroup: listGroupOutput, projectNameShort: projectNameShortText, isBothSideActionUser,
      publicSettings
   } = stateProject.allDataOneSheet;

   const currentTab = window.dms_disciplineTabThisPage;

   const disciplineForm = (publicSettings.disciplineForm || []).filter(dis => {
      const disParts = dis.split('/');
      const firstPartDis = disParts[0];

      if (firstPartDis !== currentTab && currentTab !== 'ALL') return false;

      return firstPartDis !== 'TEC' && firstPartDis !== 'ICE' && firstPartDis !== 'PRO';
   }).sort();


   const projectNameShort = projectNameShortText || 'NO-PROJECT-NAME';

   const { rowsAll, loading, currentRefToAddNewOrReplyOrEdit, rowsRfaAllInit, rfaData } = stateRow;

   const {
      currentRefData, formRefType, isFormEditting,
      withNoEmailSent, consultantNameToReplyByBothSideActionUser,
   } = currentRefToAddNewOrReplyOrEdit || {};



   const company = (formRefType === 'form-reply-RFA' && isBothSideActionUser && consultantNameToReplyByBothSideActionUser)
      ? consultantNameToReplyByBothSideActionUser
      : companyUser;

   const listConsultants = companies.filter(x => x.companyType === 'Consultant');


   let listGroup = (isBothSideActionUser && withNoEmailSent)
      ? getGroupCompanyForBothSideActionUserSubmitWithoutEmail(listGroupOutput, listConsultants)
      : listGroupOutput;


   let listRecipient = (isBothSideActionUser && withNoEmailSent)
      ? getGroupCompanyForBothSideActionUserSubmitWithoutEmail(listGroupOutput, listConsultants)
      : [...listUser, ...listGroupOutput];


   listGroup = listGroup.filter(x => !x.includes('Woh Hup Private Ltd_%$%_'));
   listRecipient = listRecipient.filter(x => !x.includes('Woh Hup Private Ltd_%$%_'));


   const companyReviewStatus = publicSettings.companyReviewStatus;
   let consultantStatusReviewByCompanyObj = {};
   if (formRefType === 'form-reply-RFA' && currentRefData) {
      consultantStatusReviewByCompanyObj = getConsultantStatusReviewByCompany({ arrayStatusDrawingText, refType: 'rfa', currentRefData, company, companyReviewStatus });
   };

   const [rfaNumberSuffixFirstTimeSubmit, setRfaNumberSuffixFirstTimeSubmit] = useState('');
   const [rfaNewVersionResubmitSuffix, setRfaNewVersionResubmitSuffix] = useState('');
   const [tradeOfRfaForFirstTimeSubmit, setTradeOfRfaForFirstTimeSubmit] = useState(null);



   const [dateReplyForSubmitForm, setDateReplyForSubmitForm] = useState(null);

   const [tablePickDrawingRFA, setTablePickDrawingRFA] = useState(false);

   const [dwgsToAddNewRFA, setDwgsToAddNewRFA] = useState(null);
   const [dwgsToAddNewRfaInit, setDwgsToAddNewRfaInit] = useState([]);
   const [dwgsToRemoveFromSubmittedRfa, setDwgsToRemoveFromSubmittedRfa] = useState([]);

   const [dwgIdToAddComment, setIdToDwgAddComment] = useState(null);
   const [nosColumnFixed, setNosColumnFixed] = useState(1);
   const [commentText, setCommentText] = useState('');

   const [filesPDF, setFilesPDF] = useState(null);
   const [filesDWFX, setFilesDWFX] = useState(null);
   const [listRecipientTo, setListRecipientTo] = useState([]);
   const [listRecipientCc, setListRecipientCc] = useState([]);

   const [listConsultantMustReply, setListConsultantMustReply] = useState([]);
   const [requestedBy, setRequestedBy] = useState('');

   const [textEmailTitle, setTextEmailTitle] = useState('');

   const [textEmailAdditionalNotes, setTextEmailAdditionalNotes] = useState('');

   const [modalConfirmsubmitOrCancel, setModalConfirmsubmitOrCancel] = useState(null);

   const [dateSendThisForm, setDateSendThisForm] = useState(null);

   const [isCheckedSameStatus, setIsCheckedSameStatus] = useState(false);
   const [isCheckedSameComment, setIsCheckedSameComment] = useState(false);


   const [listGeneralData, setListGeneralData] = useState(formRefType === 'form-reply-RFA' ? null : [
      {
         id: generateShortUid(),
         submission: '',
      },
   ]);


   // Bottom left button
   const [panelConfirmChange, setPanelConfirmChange] = useState(false);

   let isButtonDeleteFormShown = false;
   if (isFormEditting && formRefType !== 'form-reply-RFA') {
      const allRfaThisNumber = rfaData.filter(rfa => rfa['rfaRef'] === currentRefData['rfaRef']);
      const allRevSubmitted = [...new Set(allRfaThisNumber.map(x => x['revision']))].sort();
      let isThisLastSubmission;
      if (currentRefData['revision'] === allRevSubmitted[allRevSubmitted.length - 1]) {
         isThisLastSubmission = true;
      };

      // let isNoRepliedYet = true;
      // for (const key in currentRefData) {
      //    if (key.includes(`reply-rfa-date`) && currentRefData[key]) { // date show that consultant already reply
      //       isNoRepliedYet = false;
      //    };
      // };
      // if (isNoRepliedYet && isThisLastSubmission) {

      // make sure there is no row already created new version in DMS and about to create new RFA
      const rowAboutToSubmitNextVersion = rowsRfaAllInit.find(dwg => dwg['rfaNumber'] === currentRefData['rfaRef'] && !dwg['RFA Ref']);

      if (isThisLastSubmission && !rowAboutToSubmitNextVersion) {
         isButtonDeleteFormShown = true;
      };
   };



   useEffect(() => {

      if (formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') {

         if (formRefType === 'form-submit-RFA' && !isFormEditting) {

            setDateReplyForSubmitForm(moment(moment().add(14, 'days').format('DD/MM/YY'), 'DD/MM/YY'));
            if (isBothSideActionUser && withNoEmailSent) {
               setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
            };

         } else {

            const listConsultantMustReply = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'consultantMustReply') || [];

            if (isFormEditting) {
               const rowsInThisRefToEdit = rowsRfaAllInit.filter(dwg => getRefStringWithVersion(currentRefData, 'rfa') === dwg['RFA Ref']);
               rowsInThisRefToEdit.forEach(r => {
                  r[`submission-rfa-drawing-${company}`] = /[^/]*$/.exec(r[`submission-rfa-drawing-${company}`] || '')[0];
               });

               const rowsInThisRefToEditClone = rowsInThisRefToEdit.map(x => ({ ...x }));
               setDwgsToAddNewRFA(rowsInThisRefToEditClone);
               setDwgsToAddNewRfaInit(rowsInThisRefToEditClone);

               const dateSendNoEmail = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'dateSendNoEmail');
               const emailToArr = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTo') || [];
               if (dateSendNoEmail || !(emailToArr.length > 0)) {
                  setListRecipientTo(listConsultantMustReply.map(cmp => `${cmp}_%$%_`));
                  setDateSendThisForm(moment(moment(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'date')).format('DD/MM/YY'), 'DD/MM/YY'));
               } else {
                  setListRecipientTo([...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTo') || [])]);
               };

               const dueDate = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'due');
               setDateReplyForSubmitForm(moment(moment(dueDate).format('DD/MM/YY'), 'DD/MM/YY'));
               setTextEmailAdditionalNotes(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailAdditionalNotes') || '');


               const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'generalNoteInfo');
               if (generalNoteInfoData) {
                  const { listGeneralNotes } = generalNoteInfoData;
                  setListGeneralData(listGeneralNotes);
               };


            } else {
               const dwgsToResubmit = rowsRfaAllInit.filter(dwg => dwg.rfaNumber === currentRefData['rfaRef'] && !dwg['RFA Ref']);

               const dwgsToResubmitClone = dwgsToResubmit.map(x => ({ ...x }));
               setDwgsToAddNewRFA(dwgsToResubmitClone);
               setDwgsToAddNewRfaInit(dwgsToResubmitClone);

               if (isBothSideActionUser && withNoEmailSent) {
                  setListRecipientTo(listConsultantMustReply.map(cmp => `${cmp}_%$%_`));
                  setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
               } else {
                  const listEmailToPreviousVersion = [...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTo') || [])];
                  if (listEmailToPreviousVersion.length === 0) { // previous submission is migrate =>>> no email TO list
                     const listEmailToAdd = listGroup.filter(item => {
                        return listConsultantMustReply.find(cmp => item.includes(`${cmp}_%$%_`));
                     });
                     setListRecipientTo(listEmailToAdd);
                  } else {
                     setListRecipientTo(listEmailToPreviousVersion);
                  };
               };
               setDateReplyForSubmitForm(moment(moment().add(14, 'days').format('DD/MM/YY'), 'DD/MM/YY'));
            };


            setListRecipientCc([...new Set(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailCc') || [])]);
            setTextEmailTitle(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTitle') || '');

            setListConsultantMustReply(listConsultantMustReply);
            setRequestedBy(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'requestedBy') || '');


            const { rfaRef, revision } = currentRefData;

            if (formRefType === 'form-submit-RFA' && isFormEditting) {

               setTradeOfRfaForFirstTimeSubmit(getDisciplineOfRefString(rfaRef));
               const refParts = rfaRef.split('/');
               setRfaNumberSuffixFirstTimeSubmit(refParts[refParts.length - 1]);

            } else if (formRefType === 'form-resubmit-RFA' && isFormEditting) {

               setTradeOfRfaForFirstTimeSubmit(getDisciplineOfRefString(rfaRef));
               setRfaNewVersionResubmitSuffix(revision);

            } else if (formRefType === 'form-resubmit-RFA' && !isFormEditting) {

               const versionTextAlreadySubmitArr = rfaData
                  .filter(rfa => rfa['rfaRef'] === rfaRef)
                  .map(rfa => rfa['revision'])
                  .filter(ver => ver && ver !== '0');

               const versionLeft = versionArray.filter(x => versionTextAlreadySubmitArr.indexOf(x) === -1);
               setRfaNewVersionResubmitSuffix(versionLeft[0]);

               setTradeOfRfaForFirstTimeSubmit(getDisciplineOfRefString(currentRefData['rfaRef']));
            };
         };
      } else if (formRefType === 'form-reply-RFA') {

         if (!isFormEditting) {

            const dwgsInThisRfaNotReplyYet = rowsRfaAllInit.filter(dwg => {
               return dwg['RFA Ref'] === getRefStringWithVersion(currentRefData, 'rfa') &&
                  !getInfoValueFromRefDataForm(dwg, 'reply', 'rfa', 'status', company);
            });

            const dwgsInThisRfaNotReplyYetClone = dwgsInThisRfaNotReplyYet.map(x => ({ ...x }));
            setDwgsToAddNewRFA(dwgsInThisRfaNotReplyYetClone);

            setListRecipientTo([getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'user')]);
            setListRecipientCc([...new Set([
               ...(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTo') || []),
               ...(getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailCc') || []),
            ])]);
            setTextEmailTitle('Reply - ' + getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'emailTitle') || '');

            if (isBothSideActionUser && withNoEmailSent) {
               setDateSendThisForm(moment(moment().format('DD/MM/YY'), 'DD/MM/YY'));
            };

            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes, companiesToReply } = generalNoteInfoData;
               if (companiesToReply.indexOf(company) !== -1) {
                  const listGeneralNotesReply = listGeneralNotes.map(x => ({ ...x, reply: '' }));
                  setListGeneralData(listGeneralNotesReply);
               };
            };

         } else {

            const dwgsToEditReply = rowsRfaAllInit.filter(dwg => dwg['RFA Ref'] === getRefStringWithVersion(currentRefData, 'rfa'));
            dwgsToEditReply.forEach(r => {
               r[`reply-rfa-drawing-${company}`] = /[^/]*$/.exec(r[`reply-rfa-drawing-${company}`])[0];
            });

            const dwgsToEditReplyClone = dwgsToEditReply.map(x => ({ ...x }));
            setDwgsToAddNewRFA(dwgsToEditReplyClone);

            setListRecipientTo([...new Set(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailTo', company) || [])]);
            setListRecipientCc([...new Set(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailCc', company) || [])]);
            setTextEmailTitle(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailTitle', company) || '');
            setTextEmailAdditionalNotes(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailAdditionalNotes', company) || '');

            const dateSendNoEmail = getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'dateSendNoEmail', company);
            const emailToArr = getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailTo', company) || [];
            if (dateSendNoEmail || !(emailToArr.length > 0)) {
               setDateSendThisForm(moment(moment(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'date', company)), 'DD/MM/YY'));
            } else {
               setTextEmailTitle(getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'emailTitle', company) || '');
            };


            const generalNoteInfoData = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'generalNoteInfo');
            if (generalNoteInfoData) {
               const { listGeneralNotes, companiesToReply } = generalNoteInfoData;
               if (companiesToReply.indexOf(company) !== -1) {
                  const generalNoteReplyData = getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'generalNoteReply', company) || [];

                  const listGeneralNotesReply = listGeneralNotes.map(x => {
                     const replyFound = generalNoteReplyData.find(rp => rp.id === x.id) || {};
                     return { ...x, reply: replyFound['reply'] || '' };
                  });
                  setListGeneralData(listGeneralNotesReply);
               };
            };
         };
      };
   }, []);


   useEffect(() => {
      if (tradeOfRfaForFirstTimeSubmit && formRefType === 'form-submit-RFA' && !isFormEditting) {
         let filterRfas = rfaData.filter(x => {
            return x['rfaRef'] && getDisciplineOfRefString(x['rfaRef']) === tradeOfRfaForFirstTimeSubmit;
         });
         const suggestedNumber = getSuggestedNewRefNumber(filterRfas, 'rfa');
         setRfaNumberSuffixFirstTimeSubmit(suggestedNumber);
      };
   }, [tradeOfRfaForFirstTimeSubmit]);


   useEffect(() => {
      if (!loading) setModalConfirmsubmitOrCancel(null);
   }, [loading]);


   useEffect(() => {
      if (filesDWFX && Object.keys(filesDWFX).length > 0) {
         const dwgsToAddNewRfaClone = dwgsToAddNewRFA.map(x => ({ ...x }));
         let isFileDWFXsameNameFound = false;
         Object.keys(filesDWFX).forEach(key => {
            const fileDwfxName = key.slice(0, key.length - 5);
            dwgsToAddNewRfaClone.forEach(row => {
               if (row['Drawing Number'] && (
                  row['Drawing Number'].includes(fileDwfxName) ||
                  fileDwfxName.includes(row['Drawing Number'])
               )) {
                  row[`submission-rfa-dwfxName-${company}`] = key;
                  isFileDWFXsameNameFound = true;
               };
            });
         });

         if (!isFileDWFXsameNameFound) {
            const first3Dfile = Object.keys(filesDWFX)[0];
            dwgsToAddNewRfaClone.forEach(row => {
               row[`submission-rfa-dwfxName-${company}`] = first3Dfile;
            });
         };
         setDwgsToAddNewRFA(dwgsToAddNewRfaClone);
      };
   }, [filesDWFX]);


   useEffect(() => {
      if (filesPDF && Object.keys(filesPDF).length > 0) {
         const type = formRefType === 'form-reply-RFA' ? 'reply' : 'submission';
         const dwgsToAddNewRfaClone = dwgsToAddNewRFA.map(x => ({ ...x }));
         Object.keys(filesPDF).forEach(key => {
            const filePdfName = key.slice(0, key.length - 4);
            dwgsToAddNewRfaClone.forEach(row => {
               if (row['Drawing Number'] && (row['Drawing Number'].includes(filePdfName) || filePdfName.includes(row['Drawing Number']))) {
                  row[`${type}-rfa-drawing-${company}`] = key;
               };
            });
         });
         setDwgsToAddNewRFA(dwgsToAddNewRfaClone);
      };
   }, [filesPDF]);






   const onClickApplyModalPickDrawing = (dwgs) => {

      if (!dwgs || dwgs.length === 0) return;

      setDwgsToAddNewRFA(dwgs);
      setDwgsToRemoveFromSubmittedRfa(
         dwgsToAddNewRfaInit.filter(row => !dwgs.find(r => r['id'] === row['id']))
      );

      const dwgFound = dwgs.find(x => x['Coordinator In Charge']) || {};
      setRequestedBy(dwgFound['Coordinator In Charge']);
      setTablePickDrawingRFA(false);
   };

   const setRevisionDwg = (id, rev) => {
      const row = dwgsToAddNewRFA.find(x => x.id === id);
      row['Rev'] = rev;
      setDwgsToAddNewRFA([...dwgsToAddNewRFA]);
   };


   const removeDrawingToAddRFA = debounceFnc((id) => {
      setDwgsToAddNewRFA(dwgsToAddNewRFA.filter(x => x.id !== id));
      setDwgsToRemoveFromSubmittedRfa([
         ...dwgsToRemoveFromSubmittedRfa,
         dwgsToAddNewRFA.find(x => x.id === id)
      ]);
      setNosColumnFixed(2);
      setNosColumnFixed(1);
   }, 1);



   const onBlurInputRfaNameCreateNew = () => {
      const arr = [...new Set(rowsRfaAllInit.map(x => (x['RFA Ref'] || '')))];

      if (formRefType === 'form-submit-RFA') {
         if (rfaNumberSuffixFirstTimeSubmit.length !== 4 || !checkIfStringContainNumberOnly(rfaNumberSuffixFirstTimeSubmit)) {
            message.info('Please key in 4 digits number only!');
            setRfaNumberSuffixFirstTimeSubmit('');
         } else {
            const newRfaToRaiseFirstSubmit = `RFA/${projectNameShort}/${tradeOfRfaForFirstTimeSubmit}/${rfaNumberSuffixFirstTimeSubmit}`;
            if (!isFormEditting) {
               if (arr.indexOf(newRfaToRaiseFirstSubmit) !== -1) {
                  message.info('This RFA number has already existed, please choose a new number!');
                  setRfaNumberSuffixFirstTimeSubmit('');
               };
            } else {
               const currentRfa = getRefStringWithVersion(currentRefData, 'rfa');
               const arrFilter = arr.filter(x => x !== currentRfa);
               if (arrFilter.indexOf(newRfaToRaiseFirstSubmit) !== -1) {
                  message.info('This RFA number has already existed, please choose a new number!');
                  setRfaNumberSuffixFirstTimeSubmit('');
               };
            };
         };
      } else if (formRefType === 'form-resubmit-RFA') {
         if (versionArray.indexOf(rfaNewVersionResubmitSuffix) === -1) {
            message.info('Please key in letter only!');
            setRfaNewVersionResubmitSuffix('');
         } else {
            const newRfaToRaiseResubmit = `${currentRefData['rfaRef']}${rfaNewVersionResubmitSuffix}`;
            if (
               (!isFormEditting && arr.indexOf(newRfaToRaiseResubmit) !== -1) ||
               (isFormEditting && arr.indexOf(newRfaToRaiseResubmit) !== -1 && newRfaToRaiseResubmit !== getRefStringWithVersion(currentRefData, 'rfa'))
            ) {
               message.info('This RFA number has already existed, please choose a new number!');
               setRfaNewVersionResubmitSuffix('');
            };
         };
      };
   };

   const applyAddCommentToDrawing = () => {
      if (isCheckedSameComment) {
         dwgsToAddNewRFA.forEach(r => {
            r[`reply-rfa-comment-${company}`] = commentText;
         });
      } else {
         const row = dwgsToAddNewRFA.find(x => x.id === dwgIdToAddComment);
         row[`reply-rfa-comment-${company}`] = commentText;
      };

      setDwgsToAddNewRFA([...dwgsToAddNewRFA.map(dwg => ({ ...dwg }))]);
      setIdToDwgAddComment(null);
      setCommentText('');
   };

   const onChangeUploadFilePDF = (info) => {
      if (info.fileList) {
         let output = {};
         info.fileList.forEach(file => {
            output = { ...output, [file.name]: file };
         });
         setFilesPDF(output);
      };
      const dwgsToAddNewRFAClone = dwgsToAddNewRFA.map(x => ({ ...x }));
      dwgsToAddNewRFAClone.forEach(r => {
         r[`${formRefType === 'form-reply-RFA' ? 'reply' : 'submission'}-rfa-drawing-${company}`] = '';
      });
      setDwgsToAddNewRFA(dwgsToAddNewRFAClone);
   };

   const onChangeUploadFileDWFX = (info) => {
      if (info.fileList) {
         let output = {};
         let canUploadFile = true;
         info.fileList.forEach(file => {

            const fileName = file.name;
            const splitName = fileName.split('.');
            const fileExtension = splitName[splitName.length - 1];

            const arrayExtensionAllowed = ['dwf', 'dwfx', 'dwg', 'nwf', 'nwd', 'nwc', 'ifc'];

            if (file.size > 1024 * 1000 * 100) {
               canUploadFile = false;
            } else {
               if (arrayExtensionAllowed.indexOf(fileExtension.toLowerCase()) !== -1) {
                  output = { ...output, [file.name]: file };
               };
            };
         });

         if (!canUploadFile) {
            message.warn('File size should be less than 100MB!');
         } else {
            setFilesDWFX(output);
         };
      };
   };

   const onClickTagRecipientTo = (email, isRemoveTag) => {
      if (formRefType !== 'form-reply-RFA') {
         let outputListConsultantMustReply = [...listConsultantMustReply];

         const consultantName = extractConsultantName(email);
         const originConsultant = listConsultants.find(x => x.company === consultantName);
         outputListConsultantMustReply = outputListConsultantMustReply.filter(x => x !== consultantName);
         if (originConsultant && !isRemoveTag) {
            outputListConsultantMustReply.unshift(originConsultant.company);
         };
         setListConsultantMustReply(outputListConsultantMustReply);
      };
   };

   const onClickApplyDoneFormRFA = () => {


      if (!dwgsToAddNewRFA || dwgsToAddNewRFA.length === 0) {
         return message.info('Please insert drawings to submit!', 2);
      };

      let isAllDataInRowFilledIn = true;
      let listFilePdf;
      if (formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') {
         dwgsToAddNewRFA.forEach(r => {
            if (
               !(r['Rev'] || '').trim() || !r[`submission-rfa-drawing-${company}`] ||
               r[`submission-rfa-drawing-${company}`] === 'No PDF file' ||
               r[`submission-rfa-drawing-${company}`] === 'undefined'
            ) {
               isAllDataInRowFilledIn = false;
            };
         });
         listFilePdf = dwgsToAddNewRFA.map(r => r[`submission-rfa-drawing-${company}`]);
      } else if (formRefType === 'form-reply-RFA') {
         dwgsToAddNewRFA.forEach(r => {
            if (
               !r[`reply-rfa-status-${company}`] || !r[`reply-rfa-drawing-${company}`] ||
               r[`reply-rfa-drawing-${company}`] === 'No PDF file' ||
               r[`reply-rfa-drawing-${company}`] === 'undefined'
            ) {
               isAllDataInRowFilledIn = false;
            };
         });
         listFilePdf = dwgsToAddNewRFA.map(r => r[`reply-rfa-drawing-${company}`]);
      };

      const dwgPdfFile = [...new Set(listFilePdf)];



      let rfaVersionToSave, rfaToSave;

      if (formRefType === 'form-submit-RFA') {
         if (!tradeOfRfaForFirstTimeSubmit) return message.info('Please fill in discipline!', 2);
         if (!rfaNumberSuffixFirstTimeSubmit) return message.info('Please fill in ref number!', 2);
         rfaVersionToSave = '0';
         rfaToSave = `RFA/${projectNameShort}/${tradeOfRfaForFirstTimeSubmit}/${rfaNumberSuffixFirstTimeSubmit}`;
      } else if (formRefType === 'form-resubmit-RFA') { // resubmission
         rfaVersionToSave = rfaNewVersionResubmitSuffix;
         rfaToSave = currentRefData['rfaRef'];
      };


      if (projectNameShort === 'NO-PROJECT-NAME') {
         return message.info('Please update project abbreviation name for RFA number!', 2);
      } else if (!isAllDataInRowFilledIn) {
         return message.info('Please fill in all necessary info for all drawings!', 2);
      } else if (dwgsToAddNewRFA.length !== dwgPdfFile.length) {
         return message.info('Different drawings can not attach same Pdf file!', 2);
      } else if (!filesPDF && !isFormEditting) {
         return message.info('Please choose file pdf!', 2);
      } else if (
         ((formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') && !(textEmailTitle || '').trim()) ||
         (formRefType === 'form-reply-RFA' && !(textEmailTitle || '').trim() && !withNoEmailSent)
      ) {
         return message.info('Please fill in email title!', 2);

      } else if ((!listRecipientTo || listRecipientTo.length === 0) && !withNoEmailSent) {
         return message.info('Please fill in recipient!', 2);

      } else if ((formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') && !dateReplyForSubmitForm) {
         return message.info('Please fill in expected reply date!', 2);

      } else if (withNoEmailSent && !dateSendThisForm) {
         return message.info('Please fill in submission date!', 2);

      } else if ((formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') && listConsultantMustReply.length === 0) {
         return message.info('Please fill in consultant lead', 2);

      } else if ((formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA') && !(requestedBy || '').trim()) {
         return message.info('Please fill in person requested', 2);

      } else if ((!rfaToSave || !rfaVersionToSave) && formRefType !== 'form-reply-RFA') {
         return message.info('Please fill in necessary info!', 2);
      };


      const filesPDFOutput = {};
      filesPDF && Object.keys(filesPDF).forEach(key => {
         let fileFound;
         if (formRefType === 'form-reply-RFA') {
            fileFound = dwgsToAddNewRFA.find(x => x[`reply-rfa-drawing-${company}`] === key);
         } else {
            fileFound = dwgsToAddNewRFA.find(x => x[`submission-rfa-drawing-${company}`] === key);
         };
         if (fileFound) {
            filesPDFOutput[key] = filesPDF[key];
         };
      });

      const filesDWFXOutput = {};
      filesDWFX && Object.keys(filesDWFX).forEach(key => {
         const found = dwgsToAddNewRFA.find(x => x[`submission-rfa-dwfxName-${company}`] === key);
         if (found && !filesDWFXOutput[key]) {
            filesDWFXOutput[key] = filesDWFX[key];
         };
      });

      const filePdfUpload = Object.values(filesPDFOutput);

      if (filePdfUpload.find(({ name }) => {
         const nameParts = name.split('.');
         const fileExtension = nameParts[nameParts.length - 1];
         return fileExtension !== 'pdf';
      })
      ) {
         return message.info('Drawing files must be in PDF format!', 2);
      };

      if (filesDWFXOutput) {
         let totalSize = 0;
         Object.values(filesDWFXOutput).forEach(file => {
            totalSize += file['size'];
         });
         if (totalSize > 500 * 1024 * 1000) {
            return message.info('Total size of 3D files should not exceed 500MB!', 2);
         };
      };


      let generalNoteInfo;
      let generalNoteReply;
      const listGeneralDataOutput = (listGeneralData || []).filter(x => (x['submission'] || '').trim());
      if (listGeneralDataOutput.length > 0) {
         if (formRefType === 'form-reply-RFA') {
            const foundNotRepliedYet = listGeneralDataOutput.find(x => !(x['reply'] || '').trim());
            if (foundNotRepliedYet) {
               return message.info('Please all items in reply general notes!', 2);
            } else {
               generalNoteReply = [];
               listGeneralDataOutput.forEach(item => {
                  generalNoteReply.push({
                     id: item['id'],
                     reply: item['reply']
                  });
               });
            };
         } else {
            generalNoteInfo = {
               companiesToReply: [listConsultantMustReply[0]],
               listGeneralNotes: listGeneralDataOutput
            };
         };
      };

      let isFormEditTimeOver;
      if (isFormEditting) {
         let dateNoSendEmailSubmissionOrReply, dateSubmissionOrReply;

         if (formRefType === 'form-reply-RFA') {
            dateNoSendEmailSubmissionOrReply = getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'dateSendNoEmail', company);
            dateSubmissionOrReply = getInfoValueFromRefDataForm(currentRefData, 'reply', 'rfa', 'date', company);
         } else {
            dateNoSendEmailSubmissionOrReply = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'dateSendNoEmail');
            dateSubmissionOrReply = getInfoValueFromRefDataForm(currentRefData, 'submission', 'rfa', 'date');
         };
         const momentCreatedAt = dateNoSendEmailSubmissionOrReply || dateSubmissionOrReply;
         if (momentCreatedAt) {
            const duration = moment.duration(moment(new Date()).diff(momentCreatedAt)).asMinutes();
            isFormEditTimeOver = EDIT_DURATION_MIN < duration; // EDIT_DURATION_MIN not over yet =>>>>>>>>>>>>>>>>>>>> DO NOT SEND ANY EMAIL
         };
      };


      getSheetRows({ loading: true });

      onClickApplyAddNewRFA({
         type: formRefType,
         isFormEditting,
         filesPDF: (filesPDFOutput && Object.values(filesPDFOutput)) || [],
         filesDWFX: (filesDWFXOutput && Object.values(filesDWFXOutput)) || [],
         dwgsToAddNewRFA: dwgsToAddNewRFA.map(x => ({ ...x })),
         rfaToSave, rfaVersionToSave,
         recipient: {
            to: withNoEmailSent ? [] : [...new Set(listRecipientTo)],
            cc: withNoEmailSent ? [] : [...new Set(listRecipientCc)]
         },
         listConsultantMustReply: formRefType === 'form-reply-RFA' ? [] : [...listConsultantMustReply],
         requestedBy: formRefType === 'form-reply-RFA' ? '' : requestedBy,
         emailTextTitle: textEmailTitle,
         emailTextAdditionalNotes: textEmailAdditionalNotes || '',
         dateReplyForsubmitForm: dateReplyForSubmitForm && dateReplyForSubmitForm.format('DD/MM/YY'),
         dateSendThisForm,

         withNoEmailSent,
         consultantNameToReplyByBothSideActionUser,
         dwgsToRemoveFromSubmittedRfa,



         generalNoteInfo,
         generalNoteReply,
         isFormEditTimeOver

      });
   };

   const generateColumnsListDwgRFA = (headers, nosColumnFixed) => {

      const buttonRemoveDrawing =
         (formRefType === 'form-submit-RFA' || formRefType === 'form-resubmit-RFA')
            ? [
               {
                  key: 'action',
                  dataKey: 'action',
                  title: '',
                  width: 40,
                  frozen: Column.FrozenDirection.RIGHT,
                  cellRenderer: (
                     <CellRemoveDrawing
                        removeDrawingToAddRFA={removeDrawingToAddRFA}
                     />
                  )
               }
            ]
            : [];

      return [
         {
            key: 'index',
            dataKey: 'index',
            title: '',
            width: 40,
            frozen: Column.FrozenDirection.LEFT,
            cellRenderer: ({ rowIndex }) => <div>{rowIndex + 1}</div>
         },
         ...headers.map((column, index) => ({
            key: column,
            dataKey: column,
            title: column,
            resizable: true,
            frozen: index < nosColumnFixed ? Column.FrozenDirection.LEFT : undefined,
            width: getHeaderWidthDwgRFA(column),
            cellRenderer: (column === 'Rev' && formRefType !== 'form-reply-RFA') ? (
               <CellInputRevision
                  setRevisionDwg={setRevisionDwg}
                  rowsThisRFAWithRev={rowsRfaAllInit.filter(dwg => {
                     return currentRefData && dwg.rfaNumber === currentRefData['rfaRef'] && dwg['Rev'];
                  })}
                  isFirstSubmission={formRefType === 'form-submit-RFA'}
               />
            ) : (column === 'Comment' && formRefType === 'form-reply-RFA') ? (
               <CellAddCommentDrawing
                  onClickCommentBtn={(id) => setIdToDwgAddComment(id)}
                  company={company}
               />

            ) : (column === 'Status' && formRefType === 'form-reply-RFA') ? (
               <CellSelectStatus
                  dwgsToAddNewRFA={dwgsToAddNewRFA}
                  setDwgsToAddNewRFA={setDwgsToAddNewRFA}
                  company={company}
                  consultantStatusReviewByCompanyObj={consultantStatusReviewByCompanyObj}
                  isCheckedSameStatus={isCheckedSameStatus}
               />

            ) : (column === 'File PDF') ? (
               <CellSelectDrawingFile
                  dwgsToAddNewRFA={dwgsToAddNewRFA}
                  setDwgsToAddNewRFA={setDwgsToAddNewRFA}
                  filesPDF={filesPDF}
                  company={company}
                  formRefType={formRefType}
               />
            ) : (column === '3D Model') ? (
               <CellSelect3DFile
                  dwgsToAddNewRFA={dwgsToAddNewRFA}
                  setDwgsToAddNewRFA={setDwgsToAddNewRFA}
                  filesDWFX={filesDWFX}
                  company={company}
               />
            ) : null
         })),
         ...buttonRemoveDrawing,
      ];
   };



   const onClickPickTrade = (value) => {

      setTradeOfRfaForFirstTimeSubmit(value);

      const allRefSubmittedThisTrade = rfaData
         .filter(item => item['rfaRef'] && getDisciplineOfRefString(item['rfaRef']) === value)
         .sort((b, a) => (a['rfaRef'] > b['rfaRef'] ? 1 : -1));

      const itemFoundTo = allRefSubmittedThisTrade.find(rfa => {
         const emailToArray = getInfoValueFromRefDataForm(rfa, 'submission', 'rfa', 'emailTo') || [];
         return emailToArray.length > 0;
      }) || {};

      const consultantMustReplyArray = getInfoValueFromRefDataForm(itemFoundTo, 'submission', 'rfa', 'consultantMustReply') || [];

      if (isBothSideActionUser && withNoEmailSent) {
         setListRecipientTo(consultantMustReplyArray.map(cmp => `${cmp}_%$%_`));
         setListConsultantMustReply(consultantMustReplyArray);
      } else {
         setListRecipientTo(getInfoValueFromRefDataForm(itemFoundTo, 'submission', 'rfa', 'emailTo') || []);
         setListConsultantMustReply(consultantMustReplyArray);
      };


      const itemFoundCc = allRefSubmittedThisTrade.find(rfa => {
         const emailCcArray = getInfoValueFromRefDataForm(rfa, 'submission', 'rfa', 'emailCc') || [];
         return emailCcArray.length > 0;
      }) || {};

      setListRecipientCc(getInfoValueFromRefDataForm(itemFoundCc, 'submission', 'rfa', 'emailCc') || []);
   };




   return (
      <>
         <div style={{
            background: 'white',
            width: '100%',
            padding: 10,
            color: 'black',
         }}>
            <div style={{
               padding: 20,
               paddingRight: 10,
               borderBottom: `1px solid ${colorType.grey4}`,
            }}>
               <div style={{ display: 'flex', marginBottom: 10 }}>
                  <div style={{ marginRight: 10, fontWeight: 'bold' }}>RFA Number</div>
                  {formRefType === 'form-submit-RFA' ? (
                     <>
                        <div style={{ marginRight: 2 }}>{`RFA/${projectNameShort}/`}</div>
                        <div style={{ display: 'flex', transform: 'translateY(-4px)' }}>
                           <SelectTradeStyled
                              showSearch
                              optionFilterProp='children'
                              onChange={(value) => onClickPickTrade(value)}
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              value={tradeOfRfaForFirstTimeSubmit}
                              suffixIcon={<div></div>}
                           >
                              {disciplineForm.map(trade => (
                                 <Select.Option key={trade} value={trade}>{trade}</Select.Option>
                              ))}
                           </SelectTradeStyled>
                           <div style={{ marginLeft: 10, transform: 'translateY(4px)' }}>/</div>

                           <InputStyled
                              style={{ width: 60, marginRight: 120 }}
                              onChange={(e) => setRfaNumberSuffixFirstTimeSubmit(e.target.value)}
                              onBlur={onBlurInputRfaNameCreateNew}
                              value={rfaNumberSuffixFirstTimeSubmit}
                           />
                        </div>

                     </>
                  ) : formRefType === 'form-resubmit-RFA' ? (
                     <>
                        <div style={{ marginRight: 2 }}>{currentRefData['rfaRef']}</div>
                        <InputStyled
                           style={{ width: 50, marginBottom: 10, borderRadius: 0, marginRight: 120, transform: 'translateY(-5px)' }}
                           onChange={(e) => setRfaNewVersionResubmitSuffix(e.target.value)}
                           onBlur={onBlurInputRfaNameCreateNew}
                           value={rfaNewVersionResubmitSuffix}
                        />
                     </>

                  ) : formRefType === 'form-reply-RFA' ? (
                     <div>{getRefStringWithVersion(currentRefData, 'rfa')}</div>

                  ) : null}


                  {(isBothSideActionUser && withNoEmailSent) && (
                     <div style={{ display: 'flex', marginRight: 40, marginLeft: 50 }}>
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Date Submission</div>
                        <DatePickerStyled
                           value={dateSendThisForm}
                           format={'DD/MM/YY'}
                           onChange={(e) => setDateSendThisForm(e)}
                        />
                     </div>
                  )}

                  {formRefType !== 'form-reply-RFA' && (
                     <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 10, fontWeight: 'bold' }}>Date Reply</div>
                        <DatePickerStyled
                           value={dateReplyForSubmitForm}
                           format={'DD/MM/YY'}
                           onChange={(e) => setDateReplyForSubmitForm(e)}
                        />
                     </div>
                  )}

                  {formRefType === 'form-reply-RFA' && consultantNameToReplyByBothSideActionUser && (
                     <div style={{ marginLeft: 20 }}>Company reply: <span style={{ fontWeight: 'bold' }}>{consultantNameToReplyByBothSideActionUser}</span></div>
                  )}

               </div>



               {!(isBothSideActionUser && withNoEmailSent && formRefType === 'form-reply-RFA') && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                     <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 5 }}>{withNoEmailSent ? 'Consultants' : 'To'}</div>
                     <div style={{ width: '95%' }}>
                        <SelectRecipientStyled
                           mode='tags'
                           placeholder={formRefType === 'form-submit-RFA' ? 'Please pick drawings first to get email list of previous submission...' : 'Please select...'}
                           value={listRecipientTo}

                           onChange={(list) => {

                              const newItemJustAdded = list.find(x => !listRecipientTo.find(i => i === x));
                              if (newItemJustAdded) {
                                 if (!validateEmailInput(newItemJustAdded) && !listGroup.find(tag => tag === newItemJustAdded)) {
                                    return message.warning('Please choose an available group email or key in an email address!');
                                 };
                              };

                              const isCompanyGroupFound = list.find(x => checkIfMatchWithInputCompanyFormat(x, listConsultants));
                              if (!isCompanyGroupFound && formRefType !== 'form-reply-RFA') {
                                 message.warning('You must include lead consultant!');
                              };

                              if (formRefType === 'form-submit-RFA') {
                                 const itemJustRemoved = listRecipientTo.find(x => !list.find(it => it === x));
                                 if (
                                    itemJustRemoved &&
                                    listConsultantMustReply.find(x => x === extractConsultantName(itemJustRemoved)) &&
                                    !list.find(tg => extractConsultantName(tg) && extractConsultantName(tg) === extractConsultantName(itemJustRemoved))
                                 ) {
                                    setListConsultantMustReply(listConsultantMustReply.filter(x => x !== extractConsultantName(itemJustRemoved)));
                                 };

                              } else if (formRefType === 'form-resubmit-RFA') {
                                 const consultantLeadFromPreviousSubmission = listConsultantMustReply[0];
                                 const itemJustRemoved = listRecipientTo.find(x => !list.find(it => it === x));
                                 if (
                                    itemJustRemoved &&
                                    listConsultantMustReply.find(x => x === extractConsultantName(itemJustRemoved)) &&
                                    consultantLeadFromPreviousSubmission !== extractConsultantName(itemJustRemoved) &&
                                    !list.find(tg => extractConsultantName(tg) && extractConsultantName(tg) === extractConsultantName(itemJustRemoved))
                                 ) {
                                    setListConsultantMustReply(listConsultantMustReply.filter(x => x !== extractConsultantName(itemJustRemoved)));
                                 };
                              };

                              setListRecipientTo([...new Set(list)]);

                              let companyNameToCheck, isRemoveTag;
                              if (list.length === listRecipientTo.length + 1) {
                                 companyNameToCheck = list.find(x => !listRecipientTo.find(item => item === x));
                                 isRemoveTag = false;
                              } else if (list.length === listRecipientTo.length - 1) {
                                 companyNameToCheck = listRecipientTo.find(x => !list.find(item => item === x));
                                 isRemoveTag = true;
                              };
                              onClickTagRecipientTo(companyNameToCheck, isRemoveTag);
                           }}
                        >
                           {listRecipient.map(cm => {
                              const isLeadConsultant = listConsultantMustReply[0] && extractConsultantName(cm) === listConsultantMustReply[0];
                              const isLeadConsultantStyled = isLeadConsultant ? {
                                 background: colorType.primary,
                                 fontWeight: 'bold',
                                 color: 'white'
                              } : {};
                              const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', ' ') : cm;

                              return (
                                 <Option key={cm}>
                                    <div
                                       style={{ background: 'transparent', fontWeight: 'normal', color: 'black', padding: '0 5px', ...isLeadConsultantStyled }}
                                       onClick={() => onClickTagRecipientTo(cm, false)}
                                    >
                                       {textShown}
                                    </div>
                                 </Option>
                              )
                           })}
                        </SelectRecipientStyled>

                        {formRefType !== 'form-reply-RFA' && (
                           <div style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                              <div style={{ fontWeight: 'bold', marginRight: 10 }}>{listConsultantMustReply.join(', ')}</div>
                              {formRefType === 'form-submit-RFA' && (
                                 <div style={{ fontSize: 11, color: 'grey', fontStyle: 'italic', transform: 'translateY(3px)' }}>(Click on tag to change lead consultant)</div>
                              )}
                           </div>
                        )}
                     </div>
                  </div>
               )}



               {!(isBothSideActionUser && withNoEmailSent) && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                     <div style={{ transform: 'translateY(5px)', fontWeight: 'bold' }}>CC</div>
                     <div style={{ width: '95%' }}>
                        <SelectRecipientStyled
                           mode='tags'
                           placeholder={formRefType === 'form-submit-RFA' ? 'Please pick drawings first to get email list of previous submission...' : 'Please select...'}
                           value={listRecipientCc}
                           onChange={(list) => {
                              const newItemJustAdded = list.find(x => !listRecipientCc.find(i => i === x));
                              if (newItemJustAdded) {
                                 if (!validateEmailInput(newItemJustAdded) && !listGroup.find(tag => tag === newItemJustAdded)) {
                                    return message.warning('Please choose an available group email or key in an email address!');
                                 };
                              };
                              setListRecipientCc([...new Set(list)]);
                           }}
                        >
                           {listRecipient.map(cm => {
                              const textShown = extractConsultantName(cm) ? cm.replace('_%$%_', '_') : cm;
                              return (
                                 <Option key={cm}>
                                    <div style={{
                                       background: 'transparent',
                                       fontWeight: 'normal',
                                       color: 'black',
                                       padding: '0 5px'
                                    }}>{textShown}</div>
                                 </Option>
                              )
                           })}
                        </SelectRecipientStyled>
                     </div>
                  </div>
               )}



               {formRefType !== 'form-reply-RFA' && (
                  <>
                     <div style={{ display: 'flex', marginBottom: 5 }}>
                        <div style={{ transform: 'translateY(5px)', fontWeight: 'bold', marginRight: 15 }}>Requested by</div>
                        <InputStyled
                           style={{ width: 250, marginBottom: 10, borderRadius: 0 }}
                           onChange={(e) => setRequestedBy(e.target.value)}
                           value={requestedBy}
                           disabled={dwgsToAddNewRFA ? false : true}
                        />
                     </div>
                  </>
               )}

               {!(isBothSideActionUser && withNoEmailSent && formRefType === 'form-reply-RFA') && (
                  <div style={{ display: 'flex', marginBottom: 20 }}>
                     <div style={{ width: 65, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold' }}>Subject</div>
                     <InputStyled
                        style={{
                           width: '90%',
                           marginBottom: 10,
                           borderRadius: 0,
                        }}
                        onChange={(e) => setTextEmailTitle(e.target.value)}
                        value={textEmailTitle}
                     />
                  </div>
               )}

               {!withNoEmailSent && (
                  <>
                     <div style={{ paddingLeft: 100 }}>
                        <div style={{ paddingLeft: 0 }}>Dear All,</div>
                        {formRefType === 'form-reply-RFA' ? (
                           <div>
                              <span style={{ fontWeight: 'bold' }}>{company}</span> has replied this RFA, the drawings included in this RFA are in the list below.
                              Please review and update as per comments.
                           </div>
                        ) : (
                           <div>
                              <span style={{ fontWeight: 'bold' }}>{company}</span> has submitted <span style={{ fontWeight: 'bold' }}>
                                 {formRefType === 'form-resubmit-RFA'
                                    ? `${currentRefData['rfaRef']}${rfaNewVersionResubmitSuffix}`
                                    : `RFA/${projectNameShort}/${tradeOfRfaForFirstTimeSubmit || '____'}/${rfaNumberSuffixFirstTimeSubmit}` // __CHECK
                                 }
                              </span> for you to review, the drawings included in this RFA are in the list below.
                              Please review and reply to us by <span style={{ fontWeight: 'bold' }}>{dateReplyForSubmitForm ? dateReplyForSubmitForm.format('DD/MM/YY') : ''}.</span>
                           </div>
                        )}
                     </div>
                     <br />
                  </>
               )}

               <div style={{ display: 'flex', marginBottom: 20 }}>
                  <div style={{ width: 70, marginRight: 20, transform: 'translateY(5px)', fontWeight: 'bold', marginBottom: 10 }}>Email Content : </div>
                  <TextAreaStyled
                     style={{
                        width: '90%',
                        marginBottom: 10,
                        borderRadius: 0,
                     }}
                     rows={3}
                     onChange={(e) => setTextEmailAdditionalNotes(e.target.value)}
                     value={textEmailAdditionalNotes}
                     placeholder='Write note...'
                  />
               </div>

               {listGeneralData && (
                  <ListRequirementForm
                     listGeneralData={listGeneralData}
                     setListGeneralData={setListGeneralData}
                     formRefType={formRefType}
                     refType={'rfa'}
                  />
               )}



               <div style={{ display: 'flex', marginBottom: 5, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex'}}>
                     {formRefType !== 'form-reply-RFA' && (
                        <ButtonStyle
                           marginRight={10}
                           name='Add Drawing To List'
                           onClick={() => setTablePickDrawingRFA(true)}
                        />
                     )}

                     <Upload
                        name='file' accept='application/pdf' multiple={true}
                        headers={{ authorization: 'authorization-text' }}
                        showUploadList={false}
                        beforeUpload={() => {
                           return false;
                        }}
                        onChange={onChangeUploadFilePDF}
                     >
                        <ButtonStyle
                           marginRight={5}
                           name='Choose PDF File'
                           disabled={!dwgsToAddNewRFA || dwgsToAddNewRFA.length === 0}
                        />
                     </Upload>

                     {formRefType !== 'form-reply-RFA' && (
                        <Upload
                           name='file' accept='.dwfx' multiple={true}
                           headers={{ authorization: 'authorization-text' }}
                           showUploadList={false}
                           onChange={onChangeUploadFileDWFX}
                           beforeUpload={() => {
                              return false;
                           }}
                        >
                           <ButtonStyle
                              marginRight={5}
                              name='Upload 3D / CAD'
                              disabled={!dwgsToAddNewRFA || dwgsToAddNewRFA.length === 0}
                           />
                        </Upload>
                     )}


                     <div style={{ marginLeft: 5 }}>
                        {formRefType !== 'form-reply-RFA' ? (
                           <>
                              {filesPDF ? `${Object.keys(filesPDF).length} PDF files has been chosen ` : 'No PDF files has been chosen '}
                              / {filesDWFX ? `${Object.keys(filesDWFX).length} 3D models has been chosen.` : 'No 3D model has been chosen.'}
                           </>
                        ) : (
                           <>
                              {filesPDF ? `${Object.keys(filesPDF).length} PDF files has been chosen ` : 'No PDF files has been chosen '}
                           </>
                        )}
                     </div>
                  </div>

                  {formRefType === 'form-reply-RFA' && (
                     <div style={{ display: 'flex', marginRight: 28 }}>

                        <Tooltip title='All drawings have same status!'>
                           <div style={{ display: 'flex', marginRight: 20 }}>
                              <div style={{ marginRight: 5 }}>Status</div>
                              <CheckboxStyled
                                 onChange={() => setIsCheckedSameStatus(!isCheckedSameStatus)}
                                 checked={isCheckedSameStatus}
                              ></CheckboxStyled>
                           </div>
                        </Tooltip>

                        <Tooltip title='All drawings have same comment!'>
                           <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: 5 }}>Comment</div>
                              <CheckboxStyled
                                 onChange={() => setIsCheckedSameComment(!isCheckedSameComment)}
                                 checked={isCheckedSameComment}
                              ></CheckboxStyled>
                           </div>
                        </Tooltip>

                     </div>
                  )}

               </div>


               {dwgsToAddNewRFA && (
                  <div style={{
                     width: window.innerWidth * 0.9 - 80,
                     height: dwgsToAddNewRFA.length * 28 + 80
                  }}>
                     <TableStyled
                        fixed
                        columns={generateColumnsListDwgRFA(headersDwgRFA(formRefType), nosColumnFixed)}
                        data={dwgsToAddNewRFA}
                        rowHeight={28}
                     />
                  </div>
               )}
            </div>

            <div style={{
               padding: 20,
               display: 'flex',
               flexDirection: 'row-reverse',
               justifyContent: 'space-between'
            }}>

               <ButtonGroupComp
                  onClickCancel={() => setModalConfirmsubmitOrCancel('cancel')}
                  onClickApply={() => setModalConfirmsubmitOrCancel('ok')}
                  newTextBtnApply={'Submit'}
               />


               <div style={{ display: 'flex' }}>
                  {isButtonDeleteFormShown && <IconTable type='delete' onClick={() => setPanelConfirmChange(true)} />}
               </div>

            </div>
         </div>


         {panelConfirmChange && (
            <ModalConfirmStyled
               title={'Confirmation'} visible={panelConfirmChange}
               footer={null} destroyOnClose={true} centered={true}
            >
               <div style={{ padding: 20 }}>{`Are you sure to delete the form ?`}</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonGroupComp
                     onClickCancel={() => setPanelConfirmChange(false)}
                     onClickApply={() => {
                        const rowsInThisRfaToUpdate = rowsRfaAllInit.filter(dwg => getRefStringWithVersion(currentRefData, 'rfa') === dwg['RFA Ref']);

                        submissionRfaFormUpdateOrForwardEmail({
                           currentRefData,
                           rowsInThisRfaToUpdate,
                           panelUpdateForm: 'delete-form-rfa',
                        });

                     }}
                     newTextBtnApply={'Yes'}
                  />
               </div>
            </ModalConfirmStyled>
         )}










         {tablePickDrawingRFA && (
            <ModalPickDrawingRFAStyled
               title={'Select Drawings For New RFA'}
               visible={tablePickDrawingRFA}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.85}
               onOk={() => setTablePickDrawingRFA(false)}
               onCancel={() => setTablePickDrawingRFA(false)}
            >
               <TableDrawingRFA
                  onClickCancelModalPickDrawing={() => setTablePickDrawingRFA(false)}
                  onClickApplyModalPickDrawing={onClickApplyModalPickDrawing}

                  dwgsToAddNewRFA={dwgsToAddNewRFA}
                  dwgsToRemoveFromSubmittedRfa={dwgsToRemoveFromSubmittedRfa}

                  stateProject={stateProject}
                  stateRow={stateRow}
               />
            </ModalPickDrawingRFAStyled>
         )}


         {dwgIdToAddComment && (
            <ModalPickDrawingRFAStyled
               title={'Add Drawing Comment'}
               visible={dwgIdToAddComment ? true : false}
               footer={null}
               destroyOnClose={true}
               centered={true}
               width={window.innerWidth * 0.5}
               onCancel={() => setIdToDwgAddComment(null)}
            >
               <div style={{ width: '100%', padding: 10 }}>
                  <TextAreaStyled
                     rows={4}
                     onChange={(e) => setCommentText(e.target.value)}
                     defaultValue={dwgsToAddNewRFA.find(x => x.id === dwgIdToAddComment)[`reply-rfa-comment-${company}`] || ''}
                  />
                  <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
                     <ButtonGroupComp
                        onClickCancel={() => setIdToDwgAddComment(null)}
                        onClickApply={applyAddCommentToDrawing}
                     />
                  </div>
               </div>
            </ModalPickDrawingRFAStyled>
         )}


         {modalConfirmsubmitOrCancel && (
            <ModalConfirmStyled
               title={modalConfirmsubmitOrCancel === 'ok' ? 'Confirm Submission' : 'Cancel Submission'}
               visible={modalConfirmsubmitOrCancel !== null ? true : false}
               footer={null}
               destroyOnClose={true}
               centered={true}
            >
               <ConfirmSubmitOrCancelModal
                  typeConfirm={modalConfirmsubmitOrCancel}
                  formRefType={formRefType}
                  rfaRef={
                     formRefType === 'form-submit-RFA' ? `RFA/${projectNameShort}/${tradeOfRfaForFirstTimeSubmit || '____'}/${rfaNumberSuffixFirstTimeSubmit}`
                        : formRefType === 'form-resubmit-RFA' ? `${currentRefData['rfaRef']}${rfaNewVersionResubmitSuffix}`
                           : formRefType === 'form-reply-RFA' ? `${getRefStringWithVersion(currentRefData, 'rfa')}`
                              : null}
                  onClickCancelConfirmModal={() => setModalConfirmsubmitOrCancel(null)}
                  onClickApplyConfirmModal={(confirmFinal) => {
                     if (confirmFinal === 'Cancel Action Form') {
                        setModalConfirmsubmitOrCancel(null);
                        onClickCancelModal();
                     } else if (confirmFinal === 'Submit') {
                        onClickApplyDoneFormRFA();
                     };
                  }}
               />
            </ModalConfirmStyled>
         )}
      </>
   );
};
export default PanelAddNewRFA;


const converFileNameUndefined = (fileName) => {
   if (!fileName || fileName === 'undefined') return 'No PDF file';
   return /[^/]*$/.exec(fileName)[0];
};

const CellSelectDrawingFile = ({ filesPDF, rowData, dwgsToAddNewRFA, setDwgsToAddNewRFA, company, formRefType }) => {

   const pdfFileName = formRefType === 'form-reply-RFA'
      ? getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'drawing', company)
      : getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'drawing');

   const [value, setValue] = useState(converFileNameUndefined(pdfFileName));

   useEffect(() => {
      setValue(converFileNameUndefined(pdfFileName));
   }, [pdfFileName]);

   const onChangeFileAttached = (fileName, dwgId) => {
      const row = dwgsToAddNewRFA.find(x => x.id === dwgId);
      row[`${formRefType === 'form-reply-RFA' ? 'reply' : 'submission'}-rfa-drawing-${company}`] = fileName;
      setDwgsToAddNewRFA([...dwgsToAddNewRFA.map(dwg => ({ ...dwg }))]);
   };


   return (
      <SelectStyled
         placeholder='Select File...'
         onChange={(fileName) => {
            onChangeFileAttached(fileName, rowData.id);
            setValue(fileName);
         }}
         value={value}
      >
         {((filesPDF && Object.keys(filesPDF)) || []).map((fileName, i) => {
            return (
               <Select.Option key={fileName} value={fileName}>{fileName}</Select.Option>
            )
         })}
      </SelectStyled>
   );
};
const CellSelect3DFile = ({ filesDWFX, rowData, dwgsToAddNewRFA, setDwgsToAddNewRFA, company }) => {

   const dwfxFileName = getInfoValueFromRefDataForm(rowData, 'submission', 'rfa', 'dwfxName');
   const [value, setValue] = useState(dwfxFileName || 'No 3D model');

   useEffect(() => {
      if (dwfxFileName && dwfxFileName !== 'No 3D model') {
         setValue(dwfxFileName);
      };
   }, [dwfxFileName]);

   const onChangeFileAttached = (fileName, dwgId) => {
      const row = dwgsToAddNewRFA.find(x => x.id === dwgId);
      row[`submission-rfa-dwfxName-${company}`] = fileName === 'No 3D model' ? '' : fileName;
      setDwgsToAddNewRFA([...dwgsToAddNewRFA.map(dwg => ({ ...dwg }))]);
   };

   return (
      <SelectStyled
         onChange={(fileName) => {
            onChangeFileAttached(fileName, rowData.id);
            setValue(fileName);
         }}
         value={value}
      >
         {(filesDWFX && [...Object.keys(filesDWFX), 'No 3D model'] || ['No 3D model']).map((fileName, i) => (
            <Select.Option key={fileName} value={fileName}>{fileName}</Select.Option>
         ))}
      </SelectStyled>
   );
};
const CellSelectStatus = ({ rowData, dwgsToAddNewRFA, setDwgsToAddNewRFA, company, consultantStatusReviewByCompanyObj, isCheckedSameStatus }) => {

   const { consultantStatus, consultantStatusObj } = consultantStatusReviewByCompanyObj;

   let statusData = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'status', company);
   statusData = consultantStatusObj[statusData] || statusData;


   const [valueStatus, setValueStatus] = useState(statusData || '');
   useEffect(() => {
      if (statusData) {
         setValueStatus(statusData);
      } else {
         setValueStatus('');
      };
   }, [statusData]);


   const onChangeStatusReply = (status, dwgId) => {
      if (isCheckedSameStatus) {
         dwgsToAddNewRFA.forEach(r => {
            r[`reply-rfa-status-${company}`] = status;
         });
      } else {
         const row = dwgsToAddNewRFA.find(x => x.id === dwgId);
         row[`reply-rfa-status-${company}`] = status;
      };
      setDwgsToAddNewRFA([...dwgsToAddNewRFA.map(dwg => ({ ...dwg }))]);
   };

   return (
      <SelectStyled
         placeholder='Select Status...'
         onChange={(status) => {
            let statusOutput = status;
            for (const stt in consultantStatusObj) {
               if (consultantStatusObj[stt] === statusOutput) {
                  statusOutput = stt;
               };
            };
            onChangeStatusReply(statusOutput, rowData.id)
         }}
         value={valueStatus}
      >
         {consultantStatus.map(stt => (
            <Select.Option key={stt} value={stt}>{stt}</Select.Option>
         ))}
      </SelectStyled>
   );
};
const CellAddCommentDrawing = (props) => {
   const { onClickCommentBtn, rowData, company } = props;

   const commentText = getInfoValueFromRefDataForm(rowData, 'reply', 'rfa', 'comment', company) || '';

   return (
      <div style={{
         display: 'flex',
         width: '100%',
         textOverflow: 'ellipsis',
         overflow: 'hidden',
         whiteSpace: 'nowrap',
      }}>
         <Tooltip title='Add Comment For This Drawing'>
            <Icon
               type='form'
               onClick={() => onClickCommentBtn(rowData.id)}
               style={{ marginRight: 5 }}
            />
         </Tooltip>
         <div style={{ marginLeft: 3 }}>{commentText}</div>
      </div>
   );
};
const CellInputRevision = (props) => {

   const { setRevisionDwg, rowData, rowsThisRFAWithRev, isFirstSubmission } = props;
   const allRevsExisting = [...new Set(
      rowsThisRFAWithRev
         .filter(dwg => {
            return dwg.row === rowData.id // edit row current => check duplicate in row history
               || dwg.id === rowData.row // edit row history => check duplicate in row current
         })
         .map(x => x['Rev'])
   )];

   // const [value, setValue] = useState(isFirstSubmission ? '0' : rowData['Rev'] ? rowData['Rev'] : ''); // FIX_PSR_REV_IS_NUMBER
   const [value, setValue] = useState(rowData['Rev'] || '');



   // useEffect(() => { // FIX_PSR_REV_IS_NUMBER
   //    if (isFirstSubmission) {
   //       setRevisionDwg(rowData.id, '0');
   //    };
   // }, []);

   return (
      <Tooltip title='Edit Revision Name'>
         <input
            style={{
               outline: 'none',
               border: 'none',
               borderRadius: 0,
               background: 'transparent',
               width: '80%'
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onBlur={(e) => {
               const allRevsExistingLowerCase = allRevsExisting.map(rev => rev.toLowerCase())
               if (allRevsExistingLowerCase.indexOf((e.target.value || '').trim().toLowerCase()) !== -1) {
                  message.info('This Rev has already existed, please choose a new letter!');
                  setValue('');
                  setRevisionDwg(rowData.id, '');


                  // } else if (['0', ...versionArray].indexOf(e.target.value) === -1) { // FIX_PSR_REV_IS_NUMBER

                  //    message.info('Please key in letter only!');
                  //    setValue('');
                  //    setRevisionDwg(rowData.id, '');

               } else {
                  setValue((e.target.value || '').trim());
                  setRevisionDwg(rowData.id, (e.target.value || '').trim());
               };
            }}


         // disabled={isFirstSubmission} // FIX_PSR_REV_IS_NUMBER


         />
      </Tooltip>
   );
};


export const checkIfDrawingRepliedYet = (rowData) => {
   let isReplied = false;
   for (const key in rowData) {
      if (key.includes('reply-rfa-status-') && rowData[key]) {
         isReplied = true;
      };
   };
   return isReplied;
};
const CellRemoveDrawing = (props) => {

   const { removeDrawingToAddRFA, rowData } = props;

   const onClickRemoveDwgBtn = () => {
      if (checkIfDrawingRepliedYet(rowData)) {
         return message.warn('You can not remove this drawing from Rfa as it has been replied by consultant!', 2);
      } else {
         removeDrawingToAddRFA(rowData.id);
      };
   };

   return (
      <Tooltip title='Remove Drawing'>
         <Icon type='close' onClick={onClickRemoveDwgBtn} />
      </Tooltip>
   );
};
const ConfirmSubmitOrCancelModal = ({ typeConfirm, formRefType, rfaRef, onClickCancelConfirmModal, onClickApplyConfirmModal }) => {

   return (
      <div style={{ padding: 20, width: '100%' }}>
         {typeConfirm === 'ok' ? (
            <div>Are you sure to {formRefType === 'form-reply-RFA' ? 'reply' : 'submit'} the <span style={{ fontWeight: 'bold' }}>{rfaRef}</span>?</div>
         ) : typeConfirm === 'cancel' ? (
            <div>Are you sure to cancel the {formRefType === 'form-reply-RFA' ? 'response' : 'submission'}?</div>
         ) : null}

         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse' }}>
            <ButtonGroupComp
               onClickCancel={onClickCancelConfirmModal}
               onClickApply={() => onClickApplyConfirmModal(typeConfirm === 'ok' ? 'Submit' : 'Cancel Action Form')}
               newTextBtnApply={'Yes'}
            />
         </div>
      </div>
   );
};
const getHeaderWidthDwgRFA = (header) => {
   if (header === 'File PDF') return 350;
   else if (header === 'Rev') return 50;
   else if (header === 'Status') return 350;
   else if (header === 'Drawing Number') return 250;
   else if (header === 'Drawing Name') return 420;
   else if (header === 'Comment') return 200;
   else if (header === 'Coordinator In Charge') return 200;
   else return 200;
};
const ModalConfirmStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   };
   .ant-modal-close {
      display: none;
   };
   .ant-modal-header {
      padding: 10px;
   };
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   };
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   };
`;
const ModalPickDrawingRFAStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   };
   .ant-modal-close {
      display: none;
   };
   .ant-modal-header {
      padding: 10px;
   };
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   };
   .ant-modal-body {
      padding: 0;
      display: flex;
      justify-content: center;
   };
`;
const TableStyled = styled(Table)`
   .row-selected-rfa {
      background-color: ${colorType.cellHighlighted};
   };
   .BaseTable__row-cell-text {
      color: black
   };
   .BaseTable__table .BaseTable__body {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
   };
   .BaseTable__header-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      background: ${colorType.grey1};
      color: black
   };
   .BaseTable__header-row {
      background: ${colorType.grey1};
   };
   .BaseTable__row-cell {
      padding: 10px;
      border-right: 1px solid #DCDCDC;
      overflow: visible !important;
   };
`;
const InputStyled = styled(Input)`
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   border-radius: 0;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;
const TextAreaStyled = styled(TextArea)`
   color: black;
   border-top: none;
   border-right: none;
   border-left: none;
   &:focus {
      outline: none;
      box-shadow: none;
   };
`;
const SelectStyled = styled(Select)`
   width: 100%;
   outline: none;
   .ant-select-selection {
      outline: none;
      border-radius: 0;
      border: none;
      width: 100%;
      background: transparent;
   };
   .ant-select-selection__rendered {
      outline: none;
   };
`;
const SelectRecipientStyled = styled(Select)`
   width: 100%;
   .ant-select-selection__choice {
      margin-right: 5px;
      border: 1px solid ${colorType.grey1}
   };
   .ant-select-selection {
      border-radius: 0;
      width: 100%;
      background: transparent;

      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
   };
   .ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-selected {
      padding: 0;
   };
`;
const DatePickerStyled = styled(DatePicker)`
   transform: translateY(-5px);
   .ant-calendar-picker-input {
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
      width: 110px;
   };
`;

const CheckboxStyled = styled(Checkbox)`
   .ant-checkbox-inner {
      border-radius: 0;
      border: none;
      background: ${colorType.primary}
   }
`;

const SelectTradeStyled = styled(Select)`
   /* transform: translateY(-5px); */
   width: 120px;
   cursor: alias;

   .ant-select-selection__rendered {
      padding: 0;
      margin: 0;
      margin-left: 10px;
   };
   .ant-select-selection {
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      outline: none;
      box-shadow: none;
      &:focus {
         outline: none;
         box-shadow: none;
      };
   }
`;

const headersDwgRFA = (formRefType) => {
   return formRefType === 'form-reply-RFA' ? [
      'Drawing Number',
      'Drawing Name',
      'Rev',
      'Status',
      'File PDF',
      'Comment',
   ] : [
      'Drawing Number',
      'Drawing Name',
      'Coordinator In Charge',
      'Rev',
      'File PDF',
      '3D Model'
   ];
};
export const findTradeOfDrawing = (row, dwgTypeTree) => {
   let output;
   const parentNode = dwgTypeTree.find(x => x.id === row._parentRow);
   if (parentNode) {
      output = getTradeNameFnc(parentNode, dwgTypeTree);
   };
   return output;
};


export const convertTradeCodeInverted = (trade) => {
   if (trade === 'ARC') return 'ARCHI';
   if (trade === 'C&S') return 'C&S';
   if (trade === 'MEP') return 'M&E';
   if (trade === 'PC') return 'PRECAST';
   if (trade === 'PRO') return 'PROJECT PROGRESS MEETING';
   if (trade === 'TEC') return 'TECHNICAL MEETING';
   if (trade === 'ICE') return 'ICE MEETING';
   if (trade === 'ERI') return 'EMPLOYERS REPRESENTATIVE INSTRUCTION';
   if (trade === 'LR') return 'LETTER';
   if (trade === 'SM') return 'SITE MEMO';
};

const extractConsultantName = (name) => {
   const indexOfSplitString = name.indexOf('_%$%_');
   return name.slice(0, indexOfSplitString === -1 ? -99999 : indexOfSplitString);
};
const checkIfMatchWithInputCompanyFormat = (item, listConsultants) => {
   let result = false;
   listConsultants.forEach(cm => {
      if (cm.company === extractConsultantName(item)) {
         result = true;
      };
   });
   return result;
};

export const getGroupCompanyForBothSideActionUserSubmitWithoutEmail = (listGroup, listConsultants) => {
   let output = [];
   listConsultants.forEach(cmp => {
      if (listGroup.find(x => x.includes(`${cmp.company}_%$%_`))) {
         output.push(`${cmp.company}_%$%_`);
      };
   });
   return [...new Set(output)];
};


export const getSuggestedNewRefNumber = (allRefs, refType) => {


   if(refType === 'eri')
   {
      refType = 'pm'
      let Refs = allRefs.filter(x => x['pmRef'].includes('ERI'));
      let refNumberExtracted = [...new Set(Refs.map(x => /[^/]*$/.exec(x[`${refType}Ref`])[0]))];
      refNumberExtracted = refNumberExtracted
         .filter(x => x.length === 4 && parseInt(x) > 0)
         .map(x => parseInt(x));
   
      if (refNumberExtracted.length > 0) {
         const lastNumber = Math.max(...refNumberExtracted);
         const suggestedNewRefNumber = lastNumber + 1;
         const suggestedNewRefNumberConverted = suggestedNewRefNumber.toString();
         const suggestedNewRefNumberString =
            suggestedNewRefNumberConverted.length === 4 ? suggestedNewRefNumberConverted
               : suggestedNewRefNumberConverted.length === 3 ? '0' + suggestedNewRefNumberConverted
                  : suggestedNewRefNumberConverted.length === 2 ? '00' + suggestedNewRefNumberConverted
                     : '000' + suggestedNewRefNumberConverted;
   
         return suggestedNewRefNumberString;
   
      } else {
         return '0001';
      };
   }
   else if(refType === 'lr')
   {
      refType = 'pm'
      let Refs = allRefs.filter(x => x['pmRef'].includes('LR'));
      let refNumberExtracted =  [...new Set(Refs.map(x => /[^/]*$/.exec(x[`${refType}Ref`])[0]))];;
      refNumberExtracted = refNumberExtracted
         .filter(x => x.length === 4 && parseInt(x) > 0)
         .map(x => parseInt(x));
   
      if (refNumberExtracted.length > 0) {
         const lastNumber = Math.max(...refNumberExtracted);
         const suggestedNewRefNumber = lastNumber + 1;
         const suggestedNewRefNumberConverted = suggestedNewRefNumber.toString();
         const suggestedNewRefNumberString =
            suggestedNewRefNumberConverted.length === 4 ? suggestedNewRefNumberConverted
               : suggestedNewRefNumberConverted.length === 3 ? '0' + suggestedNewRefNumberConverted
                  : suggestedNewRefNumberConverted.length === 2 ? '00' + suggestedNewRefNumberConverted
                     : '000' + suggestedNewRefNumberConverted;
   
         return suggestedNewRefNumberString;
   
      } else {
         return '0001';
      };
   }
   else if(refType === 'sm')
   {
      refType = 'pm'
      let Refs = allRefs.filter(x => x['pmRef'].includes('SM'));
      let refNumberExtracted =  [...new Set(Refs.map(x => /[^/]*$/.exec(x[`${refType}Ref`])[0]))];;
      refNumberExtracted = refNumberExtracted
         .filter(x => x.length === 4 && parseInt(x) > 0)
         .map(x => parseInt(x));
   
      if (refNumberExtracted.length > 0) {
         const lastNumber = Math.max(...refNumberExtracted);
         const suggestedNewRefNumber = lastNumber + 1;
         const suggestedNewRefNumberConverted = suggestedNewRefNumber.toString();
         const suggestedNewRefNumberString =
            suggestedNewRefNumberConverted.length === 4 ? suggestedNewRefNumberConverted
               : suggestedNewRefNumberConverted.length === 3 ? '0' + suggestedNewRefNumberConverted
                  : suggestedNewRefNumberConverted.length === 2 ? '00' + suggestedNewRefNumberConverted
                     : '000' + suggestedNewRefNumberConverted;
   
         return suggestedNewRefNumberString;
   
      } else {
         return '0001';
      };
   }
   else {
      let refNumberExtracted = [...new Set(allRefs.map(x => /[^/]*$/.exec(x[`${refType}Ref`])[0]))];
      refNumberExtracted = refNumberExtracted
         .filter(x => x.length === 4 && parseInt(x) > 0)
         .map(x => parseInt(x));
   
      if (refNumberExtracted.length > 0) {
         const lastNumber = Math.max(...refNumberExtracted);
         const suggestedNewRefNumber = lastNumber + 1;
         const suggestedNewRefNumberConverted = suggestedNewRefNumber.toString();
         const suggestedNewRefNumberString =
            suggestedNewRefNumberConverted.length === 4 ? suggestedNewRefNumberConverted
               : suggestedNewRefNumberConverted.length === 3 ? '0' + suggestedNewRefNumberConverted
                  : suggestedNewRefNumberConverted.length === 2 ? '00' + suggestedNewRefNumberConverted
                     : '000' + suggestedNewRefNumberConverted;
   
         return suggestedNewRefNumberString;
   
      } else {
         return '0001';
      };
   }

};