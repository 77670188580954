import { Icon, message, Modal, Tooltip, Upload } from 'antd';
import Axios from 'axios';
import base64url from 'base64url';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiClientGetKeyUpload, apiClientUploadFile, apiCreateOrUpdateRowsUploadFile, apiDeleteFileS3, apiDeleteRowsUploadFile, apiFetchAllRowsFileThisType, apiFetchPublicSettingThisProject, apiFetchPublicUrl, apiUploadFiles } from '../../../api';
import { colorType } from '../../../constants';
import { generateShortUid, sortDataByCreatedAt } from '../../../utils';
import ButtonGroupComp from '../generalComponents/ButtonGroupComp';
import ButtonStyle from '../generalComponents/ButtonStyle';
import PanelConfirmDeleteFiles from './PanelConfirmDeleteFiles';
import { getFileNameFromLinkResponse } from './PanelSetting';




const CellUploadFile = (props) => {

   const { rowData, column, buttonPanelFunction, stateRow, getSheetRows, stateProject, commandAction, setLoading, cellData, onRightClickCell } = props;

   const {
      token, projectId, projectName, roleTradeCompany, companies, company, email, projectIsAppliedRfaView,
      pageSheetTypeName, isBothSideActionUser, pageUploadType, isUserAbleToUploadFiles

   } = stateProject.allDataOneSheet;


   const { rowsFileAllInit, rowsFileAll } = stateRow;


   const [activeBtn, setActiveBtn] = useState(null);
   const [btnShown, setBtnShown] = useState(false);


   const [formUploadShown, setFormUploadShown] = useState(false);

   const [arrayButtonCell, setArrayButtonCell] = useState([]);

   const [confirmPanelShown, setConfirmPanelShown] = useState(false);


   useEffect(() => {

      if (rowData.treeLevel && column.key === 'File Name') {
         if (isUserAbleToUploadFiles) {
            setArrayButtonCell(['btn-upload-files']);
         };

      } else if (!rowData.treeLevel && column.key === 'File Name') {
         if (isUserAbleToUploadFiles) {
            setArrayButtonCell(['btn-delete-file', 'btn-open-file']);
         } else {
            setArrayButtonCell(['btn-open-file']);
         };

      };

   }, [activeBtn]);



   const comfirmUpload = async (filesObj) => {
      setLoading(true);
      try {

         let arrayFileData = [];
         const filesArrayToUpload = Object.values(filesObj) || [];

         const arrayFilesPdfUploadLink = await Promise.all(filesArrayToUpload.map(async (file, i) => {

            const shortUid = generateShortUid();

            const key = `drawingPlatform/${projectId}/${pageUploadType}/${shortUid}-${file.name}`;
            const getKeyRes = await apiClientGetKeyUpload({ fileKey: key });
            const signedrequesturl = getKeyRes.data.signedRequest;

            await apiClientUploadFile({ signedrequesturl, file });

            arrayFileData.push({
               fileName: file.name,
               size: file.size
            });

            return {
               fileName: `${shortUid}-${file.name}`,
               fileLink: key
            };
         }));






         const rowsUploadFiles = arrayFilesPdfUploadLink.map(({ fileLink, fileName }) => {

            const fileNameNoUid = fileName.slice(7, fileName.length);

            const fileFound = arrayFileData.find(x => x['fileName'] === fileNameNoUid) || {};

            return {
               parentId: rowData.id,
               sheet: projectId,
               fileName: fileNameNoUid,
               fileKey: fileLink,
               pageUploadType,
               email,
               size: fileFound['size']
            };
         });



         await apiCreateOrUpdateRowsUploadFile({ token, projectId, rows: rowsUploadFiles });

         message.success('Uploaded Successfully', 2);

         let [resSettings, res] = await Promise.all([
            apiFetchPublicSettingThisProject({ token, projectId }),
            apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
         ]);

         commandAction({
            type: 'reload-data-view-upload-files',
            data: {
               rowsAllUploadFiles: res.data,
               publicSettings: resSettings.data
            }
         });




      } catch (err) {
         console.log(err);
      }
   };


   const onClickFileVersion = (btn) => {
      const rowsNotThisFile = rowsFileAll.filter(r => r['fileName'] !== rowData['fileName'] || r['parentId'] !== rowData['parentId']);

      let rowsThisRefFiltered = rowsFileAllInit.filter(r => {
         return r['fileName'] === rowData['fileName'] && r['parentId'] === rowData['parentId'];
      });
      rowsThisRefFiltered = sortDataByCreatedAt(rowsThisRefFiltered);

      const rowThisFileVersion = rowsThisRefFiltered.filter((row, i) => i === parseInt(btn));

      const rowsToDisplay = [...rowsNotThisFile, ...rowThisFileVersion].sort((a, b) => (a['fileName'] > b['fileName'] ? 1 : -1));

      setActiveBtn(btn);

      getSheetRows({ rowsFileAll: rowsToDisplay });
   };




   const onClickButtonCell = async (btn) => {
      try {
         if (btn === 'btn-upload-files') {
            setFormUploadShown(true);

         } else if (btn === 'btn-open-file') {
            const fileKey = rowData['fileKey'];
            const res = await apiFetchPublicUrl({ key: fileKey, expire: 1000 });

            window.open(res.data, '_blank');

         } else if (btn === 'btn-delete-file') {
            setConfirmPanelShown(true);
         }

      } catch (err) {
         console.log(err);
      };
   };


   const deleteFileThisVersion = async () => {

      const { token, projectId, email } = stateProject.allDataOneSheet;

      try {
         await apiDeleteFileS3({ token, fileKey: rowData['fileKey'] });

         await apiDeleteRowsUploadFile({ token, projectId, email, rowIdsArray: [rowData['id']] });

         message.success('Deleted Successfully', 2);

         let [resSettings, res] = await Promise.all([
            apiFetchPublicSettingThisProject({ token, projectId }),
            apiFetchAllRowsFileThisType({ token, projectId, pageUploadType })
         ]);

         commandAction({
            type: 'reload-data-view-upload-files',
            data: {
               rowsAllUploadFiles: res.data,
               publicSettings: resSettings.data
            }
         });

      } catch (err) {
         getSheetRows({ loading: false });
         commandAction({ type: 'save-data-failure' });
         console.log(err);
      };
   };

   const styledSize = column.key === 'Size' ? { 'textAlign': 'right', width: '100%' } : {};


   return (
      <div
         style={{
            width: '100%', height: '100%',
            position: 'relative',
            display: 'flex',
            padding: 3, paddingLeft: 5, color: 'black',
            ...styledSize
         }}
         onMouseOver={() => {
            if (!btnShown) setBtnShown(true);
         }}
         onMouseLeave={() => {
            if (btnShown) setBtnShown(false);
         }}
         onClick={() => {
            getSheetRows({ rowsSelected: [] });
         }}
      >
         {(rowData.treeLevel && column.key === 'File Name') ? (
            <div style={{ fontWeight: 'bold' }}>
               {rowData.title}
            </div>
         ) : (
            <div style={{ ...styledSize }}>{getCellUploadFileData(rowData, column.key)}</div>
         )}

         {(!rowData.treeLevel && column.key === 'File Name') && (
            <div style={{ marginLeft: 10, display: 'flex', borderLeft: `1px solid ${colorType.grey4}` }}>
               {rowData['btn'].map(btn => {

                  return (
                     <ButtonVersion
                        style={{ padding: '0 5px' }}
                        key={btn} isActive={btn === rowData['version']}
                        onClick={() => onClickFileVersion(btn)}
                     >{btn}</ButtonVersion>
                  );
               })}
            </div>
         )}

         {btnShown && arrayButtonCell.map((btn, i) => (
            <Tooltip key={i} placement='top' title={getTooltipUploadFileText(btn)}>
               <Icon
                  type={getButtonTypeFileText(btn)}
                  style={{
                     cursor: 'pointer', fontSize: 16,
                     position: 'absolute',
                     right: getButtonOffsetRight(i),
                     top: 6
                  }}
                  onClick={() => onClickButtonCell(btn)}
               />
            </Tooltip>
         ))}


         {formUploadShown && (
            <ModalStyled
               title={'Upload Files'} visible={formUploadShown}
               footer={null} destroyOnClose={true} centered={true}
            >
               <FormUploadFiles
                  onClickCancel={() => setFormUploadShown(false)}
                  comfirmUpload={comfirmUpload}
               />
            </ModalStyled>
         )}


         {confirmPanelShown && (
            <ModalStyled
               title={'Delete File'} visible={confirmPanelShown}
               footer={null} destroyOnClose={true} centered={true}
            >
               <PanelConfirmDeleteFiles
                  onClickCancel={() => {
                     setConfirmPanelShown(false);
                     setBtnShown(false);
                  }}
                  onClickApply={deleteFileThisVersion}
                  rowsSelected={[rowData]}
               />
            </ModalStyled>
         )}

      </div>
   );
};

export default CellUploadFile;





const ModalStyled = styled(Modal)`
   .ant-modal-content {
      border-radius: 0;
   }
   .ant-modal-close {
      display: none;
   }
   .ant-modal-header {
      padding: 10px;
   }
   .ant-modal-title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
   }
   .ant-modal-body {
      padding: 0;
      justify-content: center;
   }
`;


const getCellUploadFileData = (rowData, columnKey) => {
   if (!rowData.treeLevel) {
      if (columnKey === 'File Name') return rowData['fileName'];
      else if (columnKey === 'Date') return moment(rowData['createdAt']).format('HH:mm - DD/MM/YY');
      else if (columnKey === 'Uploaded By') return rowData['email'];
      else if (columnKey === 'Size') {
         const { size } = rowData;
         if (size <= 999999) return Math.floor(size / 1000) + ' KB';
         else if (size > 999999) return Math.floor(size / 1000000) + ' MB';
      };
   };
};

const FormUploadFiles = ({ onClickCancel, comfirmUpload }) => {

   const [panelConfirm, setPanelConfirm] = useState(false);
   const [filesToUpload, setFilesToUpload] = useState({});

   const onChangeUpload = (info) => {
      if (info.fileList) {
         let output = {};
         info.fileList.forEach(file => {
            output = { ...output, [file.name]: file };
         });
         setFilesToUpload(output);
      };
   };

   return (
      <>
         <div style={{ color: 'black', padding: 20, }}>

            <div style={{ marginBottom: 5, fontWeight: 'bold' }}>{`Files To Upload : ${Object.keys(filesToUpload).length}`}</div>

            <div style={{ marginBottom: 15, maxHeight: window.innerHeight * 0.7, overflowY: 'scroll' }}>
               {Object.keys(filesToUpload).map((fileName, i) => (
                  <div style={{ display: 'flex' }} key={i}>
                     <div style={{ width: 30 }}>{i + 1})</div>
                     <div style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                     }}>{fileName}</div>
                  </div>
               ))}
            </div>

            <Upload
               name='file' multiple={true} showUploadList={false}
               headers={{ authorization: 'authorization-text' }}
               beforeUpload={() => { return false }}
               onChange={onChangeUpload}
            >
               <ButtonStyle
                  marginRight={5}
                  name='Choose Files'
               />
            </Upload>
         </div>


         <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
            <ButtonGroupComp
               onClickCancel={onClickCancel}
               newTextBtnApply={'Upload Files'}
               onClickApply={() => setPanelConfirm(true)}
            />
         </div>


         {panelConfirm && (
            <ModalStyled
               title={'Confirmation'} visible={panelConfirm}
               footer={null} destroyOnClose={true} centered={true}
            >
               <div style={{ padding: 20 }}>{`Are you sure to upload files ?`}</div>
               <div style={{ padding: 20, display: 'flex', flexDirection: 'row-reverse', borderTop: `1px solid ${colorType.grey4}` }}>
                  <ButtonGroupComp
                     onClickCancel={() => setPanelConfirm(false)}
                     newTextBtnApply={'Yes'}
                     onClickApply={() => comfirmUpload(filesToUpload)}
                  />
               </div>
            </ModalStyled>
         )}
      </>
   );
};




const ButtonVersion = styled.div`
   &:hover {
      cursor: pointer;
   };
   border-radius: 0;
   color: ${props => props.isActive ? colorType.primary : 'grey'};
   font-weight: ${props => props.isActive ? 'bold' : 'normal'};
   
   text-align: center;
   transition: 0.3s;
`;


const getTooltipUploadFileText = (btn) => {
   if (btn === 'btn-upload-files') return 'Upload Files';
   else if (btn === 'btn-open-file') return 'Open File';
   else if (btn === 'btn-delete-file') return 'Delete File This Version';
};

const getButtonTypeFileText = (btnName) => {
   let result = 'xxx';
   if (btnName === 'btn-upload-files') {
      result = 'upload';
   } else if (btnName === 'btn-open-file') {
      result = 'file';
   } else if (btnName === 'btn-delete-file') {
      result = 'delete';
   };
   return result;
};




const getButtonOffsetRight = (index) => {
   return 5 + index * 22;
};


