import React from 'react';
import {
  Modal,
} from 'antd';
import _ from 'lodash';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import {TableHistory} from '../table'


export default class DialogHistory extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        columns: [],
      };
      this.hotTable = React.createRef();
    }
  
    componentDidMount = () => {
      console.log(this.props.cellDataCurrent);
      let temp = [];
 
      _.forEach(this.props.cellDataCurrent.row_history, (v) => {
        let clone = Object.assign({}, v);
        clone.updatedAt = moment(clone.updatedAt).format('DD/MM/YYYY HH:mm');
        temp.push(clone);
      });
      let columns = [
        {
          title: 'User',
          data: 'user',
          type: 'text',
          className: 'truncated',
          readOnly: true,
        },
        {
          title: this.props.cellDataCurrent.prop,
          data: this.props.cellDataCurrent.prop,
          type: 'text',
          className: 'truncated',
          readOnly: true,
        },
        {
          title: 'Update At',
          data: 'updatedAt',
          type: 'date',
          className: 'truncated',
          readOnly: true,
          width: 120,
        },
      ];
      this.setState({ columns, data: temp });
    };
    handleClose = () => {
      this.props.close();
    };
  
    render() {
      return (
        <>
          <Modal
            title={'Cell History'}
            visible={this.props.open}
            onCancel={this.handleClose}
            onOk={this.handleClose}
            width={'100%'}
            style={{height: '400px'}}
            confirmLoading={this.state.loading}
            footer={false}
            style={{top:10}}
          >
            <div   style={{height: 'calc(100vh - 100px)'}}>
              {/* <HotTable
                id='dataentry_5d'
                ref={this.hotTable}
                data={this.state.data}
                dropdownMenu={[
                  'filter_by_value',
                  'filter_by_condition',
                  'filter_action_bar',
                ]}
                filters={true}
                // height='100%'
                width='100%'
                licenseKey='non-commercial-and-evaluation'
                colHeaders={true}
                rowHeaders={true}
                colWidths={100}
                rowHeights={22}
                manualColumnResize={true}
                columns={this.state.columns}
                bindRowsWithHeaders={true}
                fillHandle={false}
                cells={() => {
                  const cellPrp = {};
                  cellPrp.className = 'cell-readonly-dataentry';
                  return cellPrp;
                }}
              /> */}
              <TableHistory
                data={this.state.data}
                cellDataCurrent={this.props.cellDataCurrent.prop}
                columns={this.props.columns}
              />
            </div>
          </Modal>
        </>
      );
    }
  }