import React from 'react';
import _ from 'lodash'
import { getSubRowsCount, getSubRowsTotal } from '../function/TableFunction'


//#region function
export default(data)=> {
    let columns = []
    for (let i in data) {
        if (data[i].displayName === 'Category')
            columns.push({
                Header: data[i].displayName, accessor: data[i].displayName,
                id: data[i].displayName,
                units: false,
                aggregate: 'count',
                filter: "includes",
                alignLocal:'left',
                Aggregated: ({ cell: { row } }) => (
                    `${getSubRowsCount(row.subRows)}`
                )
            })
        else {
            if (data[i].units === '') {
                columns.push({
                    Header: data[i].displayName, accessor: data[i].displayName,
                    id: data[i].displayName,
                    units: false,
                    aggregate: 'count',
                    filter: "includes",
                    alignLocal:'left',
                    Aggregated: ({ cell: { row } }) => (

                        `${getSubRowsCount(row.subRows)}`
                    )
                })
            } else if (data[i].displayName !== 'ElementId') {
                columns.push({
                    Header: `${data[i].displayName} (${data[i].units})`, accessor: data[i].displayName,
                    id: data[i].displayName,
                    units: true,
                    aggregate: 'sum',
                    filter: 'between',
                    Footer: info => {
                        const total = React.useMemo(
                            () => getSubRowsTotal(info.flatRows, info.column.id),
                            [info.rows]
                        )
                        return <> {total.toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1,')}</>
                    },
                    Aggregated: ({ cell: { value } }) => `${value.toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1,')}`,
                })
            }
        }
    }
    return columns
}