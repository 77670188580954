import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Drawer, Form, Button, InputNumber, Select, DatePicker, Space, Radio, Tooltip } from "antd";
import * as processData from "../function/func_process_data";

const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterDataDrawer = ({ current, shownAdvanceFilter, setShownAdvanceFilter, setData, setStatistic }) => {
  const [shownForm, setShownForm] = useState();
  const [optionSupplier, setOptionSupplier] = useState([]);
  const [valueRadioSupplier, setValueRadioSupplier] = useState(1);
  const [disableSelectSupplier, setdisableSelectSupplier] = useState(true);

  const [optionProduct, setOptionProduct] = useState([]);
  const [valueRadioProduct, setValueRadioProduct] = useState(1);
  const [disableSelectProduct, setdisableSelectProduct] = useState(true);

  const [valueRadioVolumn, setValueRadioVolumn] = useState(1);
  const [disableSelectVolumn, setdisableSelectVolumn] = useState(true);

  //value of the form
  const [inv_date_start, set_inv_date_start] = useState("");
  const [inv_date_end, set_inv_date_end] = useState("");
  const [delivery_date_start, set_delivery_date_start] = useState("");
  const [delivery_date_end, set_delivery_date_end] = useState("");
  const [upload_date_start, set_upload_date_start] = useState("");
  const [upload_date_end, set_upload_date_end] = useState("");
  const [listSupplier, setListSupplier] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [vol_more, set_vol_more] = useState("");
  const [vol_less, set_vol_less] = useState("");

  useEffect(() => {
    if (shownAdvanceFilter) {
      const fetchData = async () => {
        setShownForm(true);
        const res = await axios.get("/api/do_inv/common/info_for_filter_form", {
          params: { project_id: localStorage.getItem("fp_project_id"), current: current },
        });

        let listP = res.data.data.list_products.map((item) => {
          return <Option key={item}> {item}</Option>;
        });
        setOptionProduct(listP);

        let listSuppliers = res.data.data.list_suppliers.map((item) => {
          return <Option key={item}> {item}</Option>;
        });
        setOptionSupplier(listSuppliers);
      };
      fetchData();
    }
  }, [shownAdvanceFilter]);

  const handleApplyFilter = async () => {
    try {
      //build up query for filter
      var query_builder = {};
      if (current.includes("inv")) {
        query_builder["inv_date_start"] = inv_date_start;
        query_builder["inv_date_end"] = inv_date_end;
      }
      query_builder["delivery_date_start"] = delivery_date_start;
      query_builder["delivery_date_end"] = delivery_date_end;
      query_builder["upload_date_start"] = upload_date_start;
      query_builder["upload_date_end"] = upload_date_end;

      query_builder["listSupplier"] = listSupplier;
      query_builder["listProduct"] = listProduct;
      if (current === "conc_do") {
        query_builder["vol_more"] = vol_more;
        query_builder["vol_less"] = vol_less;
      }

      const res = await axios.get("/api/do_inv/common/filter", {
        params: { query_builder: query_builder, project_id: localStorage.getItem("fp_project_id"), current: current },
      });

      //format date type
      if (current === "conc_do") {
        const temp_d = res.data.data.items;
        const res_f = await processData.func_process_conc_do_data(temp_d);
        setData(res_f.processed_data);
        setStatistic(res_f.staticInfo);
      }
      if (current === "conc_inv") {
        const d1 = res.data.data.items;
        const res_f = await processData.func_process_conc_inv_data(d1, localStorage.getItem("fp_project_id"));
        setData(res_f.processed_data);
        setStatistic(res_f.staticInfo);
      }

      if (current === "rebar_do") {
        const temp_d = res.data.data.items;
        const res_f = await processData.func_process_rebar_do_data(temp_d);
        setData(res_f.processed_data);
        setStatistic(res_f.staticInfo);
      }
    } catch (error) {
      console.log("error at fetch data in Filter", error);
    }

    // setQueryBuilder(query_builder);
    // setFilterMode(10);
  };

  const onClose = () => {
    setShownAdvanceFilter(false);
    setShownForm(false);
  };

  return (
    <Drawer
      title="Filter Data"
      placement="right"
      width={325}
      closable={false}
      maskClosable={false}
      onClose={onClose}
      visible={shownForm}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {/* <Space direction="vertical" size={40}> */}
      {current.includes("inv") ? (
        <div>
          <h6>DO Date range:</h6>
          <RangePicker
            onChange={(date, dateString) => {
              set_inv_date_start(dateString[0]);
              set_inv_date_end(dateString[1]);
            }}
          />
        </div>
      ) : null}
      <br></br>
      <div>
        <h6>DO Date range:</h6>
        <RangePicker
          onChange={(date, dateString) => {
            set_delivery_date_start(dateString[0]);
            set_delivery_date_end(dateString[1]);
          }}
        />
      </div>
      <br></br>
      <div>
        <h6>Date uploaded form range:</h6>
        <RangePicker
          onChange={(date, dateString) => {
            set_upload_date_start(dateString[0]);
            set_upload_date_end(dateString[1]);
          }}
        />
      </div>
      <br></br>
      <div>
        <h6>Supplier:</h6>
        <Radio.Group
          onChange={(e) => {
            setValueRadioSupplier(e.target.value);
            setdisableSelectSupplier(!disableSelectSupplier);
          }}
          value={valueRadioSupplier}
        >
          <Radio value={1}>All</Radio>
          <Radio value={2}>Select</Radio>
        </Radio.Group>
        <br></br>
        <br></br>
        <Select
          mode="multiple"
          disabled={disableSelectSupplier ? true : false}
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          // defaultValue={["a10", "c12"]}
          onChange={(value) => {
            setListSupplier(value);
          }}
        >
          {optionSupplier}
        </Select>
      </div>
      <br></br>
      <div>
        <h6>Product:</h6>
        <Radio.Group
          onChange={(e) => {
            setValueRadioProduct(e.target.value);
            setdisableSelectProduct(!disableSelectProduct);
          }}
          value={valueRadioProduct}
        >
          <Radio value={1}>All</Radio>
          <Radio value={2}>Select</Radio>
        </Radio.Group>
        <br></br>
        <br></br>

        <Select
          mode="multiple"
          disabled={disableSelectProduct ? true : false}
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          // defaultValue={["a10", "c12"]}
          onChange={(value) => {
            setListProduct(value);
          }}
        >
          {optionProduct}
        </Select>
      </div>
      <br></br>
      {current === "conc_do" ? (
        <div>
          <h6>Volume:</h6>
          <Radio.Group
            onChange={(e) => {
              setValueRadioVolumn(e.target.value);
              setdisableSelectVolumn(!disableSelectVolumn);
            }}
            value={valueRadioVolumn}
          >
            <Radio value={1}>All</Radio>
            <Radio value={2}>Select</Radio>
          </Radio.Group>
          <br></br>
          <br></br>
          <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
            <Form.Item label="More than:">
              <Tooltip title="can not more than 20m3.">
                <InputNumber min={0} max={20} onChange={(value) => set_vol_more(value)} />
              </Tooltip>
            </Form.Item>
            <Form.Item label="Less than:">
              <Tooltip title="can not more than 20m3.">
                <InputNumber min={0} max={20} onChange={(value) => set_vol_less(value)} />
              </Tooltip>
            </Form.Item>
          </Form>
        </div>
      ) : null}
      {/* </Space> */}
      <div
        style={{
          display: "flex",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "25px 25px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button type="primary" onClick={handleApplyFilter}>
          Apply Filter
        </Button>
        <Button>Clear Filter</Button>
        <Button onClick={onClose} style={{ marginRight: 8 }}>
          Close
        </Button>
      </div>
    </Drawer>
  );
};

export default FilterDataDrawer;
