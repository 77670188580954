import { DATAENTRY_ITEMS,DATAENTRY_ITEMS_LOADING,DATAENTRY_ITEMS_OPEN_HISTORY } from '../../types';

const data_entry = data => ({
  type: DATAENTRY_ITEMS,
  data
});

const data_entry_loading = data => ({
  type: DATAENTRY_ITEMS_LOADING,
  data
});

const data_entry_open_history = data => ({
  type: DATAENTRY_ITEMS_OPEN_HISTORY,
  data
});


export const setDataEntry = data => dispatch => {
  dispatch(data_entry(data))
}

export const setDataEntryLoading = data => dispatch => {
  dispatch(data_entry_loading(data))
}

export const setDataEntryOpenHistory = data => dispatch => {
  dispatch(data_entry_open_history(data))
}
