import React from 'react';
import { Typography } from 'antd';
import { Icon } from 'rsuite';
import _ from 'lodash'
import { Label } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import moment from 'moment';
import { handleReactTableFilterDate } from '../function/TableFunction'
const { Text } = Typography
export default  (_this) => {
  return ([
    {
      Header: 'Folder Name', accessor: 'folderName', id: 'folderName', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => (
        (row.original.permission || _this.state.roleUploader) ?
          <div style={{ overflow: 'hidden' }}>
            <Link to={{
              pathname: `/projects/project/folder`,
              state: {
                userCreatedProject: _this.props.location.state.userCreatedProject,
                folderId: row.original.folder.folderId,
                folderName: row.original.folder.folderName,
                projectId: _this.props.location.state.projectId,
                projectName: _this.props.location.state.projectName,
                sharePointId: _this.props.location.state.sharePointId,
                issueKey: _this.props.location.state.issueKey,
                trimbleKey: _this.props.location.state.trimbleKey,
                listAdmin: _this.state.listAdmin,
                typeProject: _this.props.location.state.typeProject,
                isDetail: true,
                useRfa: _this.props.location.state.useRfa,
              }
            }}>
              <Label style={{ float: 'left', marginRight: 5 }} size='mini' color='green'>
                <Icon size='lg' icon='folder' > Folder</Icon>
              </Label>
              <div style={{ color: 'black', verticalAlign: 'middle', display: 'inline' }}>
                {value === undefined ? row.original.folder.folderName : value}</div>
            </Link>
          </div>
          :
          <div style={{ overflow: 'hidden' }}>
            <Label style={{ float: 'left' }} size='mini' color='gray'>
              <Icon size='lg' icon='folder' > Folder</Icon>
            </Label>
            <div style={{ color: 'gray', verticalAlign: 'middle', display: 'inline' }}>
              {value === undefined ? row.original.folder.folderName : value}</div>
          </div>

      )
    },
    { Header: 'Items', accessor: 'itemCount', id: 'itemCount', units: false, filter: "includes" },
    {
      Header: 'Created Date', accessor: 'createdDate', id: 'createdDate', units: false,type:'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    {
      Header: 'Updated Date', accessor: 'uploadDate', id: 'uploadDate', units: false,type:'date',
      Cell: ({ cell: { row, value } }) => {
        return (
          <Text strong >{moment(value).format('DD-MM-YYYY')}</Text>
        )
      },
      filter: handleReactTableFilterDate
    },
    {
      Header: 'Permission', accessor: 'permission', id: 'permission', units: false, filter: "includes",
      Cell: ({ cell: { row, value } }) => (
        <Label size='mini' color={(value || _this.state.roleUploader) ? 'green' : "gray"}>
          {(value || _this.state.roleUploader) ? 'Accept' : 'Not Accept'}</Label>
      )
    },
    { Header: 'Created by', accessor: 'userCreated', id: 'userCreated', units: false, filter: "includes", },
  ])
}