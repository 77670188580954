import React, { useState, useEffect } from 'react';
import axios from '../../module_Worker/api/axiosClient';
import { Table, Icon, Modal, Button } from 'antd';
import _ from 'lodash';
const { Column } = Table;

async function apiGetLinkDownloadByKey(payload) {
  return await axios.get('/api/casting-report/get-file-by-key', {
    params: payload,
  });
}

function DoNumberList(props) {
  const [rows, setRows] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.showDoNumberList > 0) {
      setVisible(true);
    }

    console.log('props.currentDoDataList', props.currentDoDataList);
  }, [props.showDoNumberList]);

  async function handleDownload(record) {
    let res = await apiGetLinkDownloadByKey({ key: record.keyS3, time: 60 });
    window.open(res);
  }
  async function handleCancel() {
    setVisible(false);
  }
  return (
    <div>
      <Modal
        title='Do List'
        visible={visible}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <Table
          dataSource={props.currentDoDataList}
          bordered
          className='antTable'
          rowKey={(record) => record.key}
        >
          <Column title='Do Number' dataIndex='doNumber' key='doNumber' />
          <Column title='Remark' dataIndex='remark' key='remark' />
          {/* 
          <Column
            width="120px"
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Button
                  icon="download"
                  onClick={() => handleDownload(record)}
                  type="primary"
                >
                  {record.reportNumber ?? ""}
                </Button>
              </span>
            )}
          /> */}
        </Table>
      </Modal>
    </div>
  );
}

export default DoNumberList;
